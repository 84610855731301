import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";

const PieChart = ({data , label,  field}) => {
 
  const dataSet = {
    labels: data?.map((item)=> item.legend),
    datasets: [
      {
        label: label,
        data: data?.map((item)=> item[field]),
     
        hoverOffset: 4,
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(54, 162, 235)',
          'rgb(255, 205, 86)',
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Doughnut
    className="piechart"
    style={{height:"400px !important"}}
      data={dataSet}
      options={{
        responsive: true,
        maintainAspectRatio: false,
     
      }}
    />
  );
};

export default PieChart;
