import React from "react";
// import { Card, CardImg } from "react-bootstrap";
import CardHeader from "react-bootstrap/esm/CardHeader";
import ReactPlayer from "react-player";
import { Card, Dropdown, Popconfirm, Space, message, Tooltip } from "antd";
import {
  EllipsisOutlined,
  EyeOutlined,
  FormOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { RxDotFilled } from "react-icons/rx"


const onClick = ({ key }) => {
  message.info(`Click on item ${key}`);
};

const items = [
  {
    label: "1st menu item",
    key: "1",
  },
  {
    label: "2nd menu item",
    key: "2",
  },
  {
    label: "3rd menu item",
    key: "3",
  },
];

const { Meta } = Card;

const VideoCard = ({
  thumbnail,
  title,
  desc,
  active,
  primary,
  views,
  onClick,
  onViewClick,
  onEditClick,
  onDeleteClick,
}) => {


  return (


    <div className={primary && "flxbximg flex-column"} style={{ maxWidth: "300px" }}>
      {
        primary && <h3 className="text-white">Featured Video</h3>
      }
      <Card
        hoverable
        style={{
          width: "100%",
          maxWidth: "300px",
          boxShadow:
            "2px 1px 7px -1px rgba(0,0,0,0.25), -2px 1px 7px -1px rgba(0,0,0,0.25)",
          background: "#171717",
          borderLeft: "1px solid gray",
          borderTop: ".4px solid lightGray",
          borderRight: "1px solid gray",
          borderBottom: "1px solid gray",
          borderRadius: "15px"

        }}
        className="videoCard"
        cover={
          <img
            src={thumbnail}
            alt={title}
            width={"100%"}
            height={170}
            onClick={onClick}
            style={{ borderRadius: "15px 15px 0 0", objectFit: "cover", borderWidth: "1px 1px 0px 1px", borderColor: "grey", borderStyle: "solid" }}
          />
        }
        actions={[
          <span
            style={{ borderColor: "red", color: "white" }}
            // onClick={onDeleteClick}
            className="videoCardIcon"
          >
            <Popconfirm
              title="Are you sure to delete this task?"
              onConfirm={onDeleteClick}
              okText="Yes"
              cancelText="No"
            >
              <DeleteOutlined key={"delete"} />

            </Popconfirm>
          </span>,

          <div onClick={onEditClick} className="text-white">
            <FormOutlined key={"edit form"} className="videoCardIcon" color="white" />
          </div>,
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              color: "white"
            }}
            onClick={onViewClick}
          >
            <span style={{ marginRight: "4px", color: "white" }}>{views}</span>
            <EyeOutlined key="eye" className="videoCardIcon" color="white" />,
          </div>,
          <Tooltip placement="topLeft" title={active ? "You are Active" : "You are InActive"}>

            <RxDotFilled className="videoCardIcon" size={22} color={active ? "green" : ""} />,
          </Tooltip>
        ]}
      >
        <Meta title={title} description={desc} className="VideoCardText" />

      </Card>


    </div>
  );
};

export default VideoCard;
