import React from "react";
import { useState, useEffect, useRef } from "react";
// import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { BiArrowBack } from "react-icons/bi";
import { MdOutlineArrowForward } from "react-icons/md";
import { DownloadOutlined } from "@ant-design/icons";

import { Col, Row, Anchor, Button, Space, Typography, Drawer } from "antd";
import { Spinner } from "react-bootstrap";
// pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfComponent = ({ pdf, options }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const { Text } = Typography;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offSet) {
    setPageNumber((prevPageNumber) => prevPageNumber + offSet);
  }

  function changePageBack() {
    changePage(-1);
  }

  function changePageNext() {
    changePage(+1);
  }

  const styles = {
    fontSize: "11px",
    color: "black",
    fontWeight: "500",
    fontFamily: 'sansation',
  };
  return (
    <div className="pdf_wrapper">
      <div className="pdf_container">
        <Document
          loading={
            <center className="d-flex justify-content-center align-items-center  ">
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
                variant="primary"
              />
            </center>
          }
          file={pdf}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <center>
            <Page pageNumber={pageNumber} size="A4" />
          </center>
        </Document>
      </div>

      {options && (
        <>
          <Row between="xs" gutter={[20, 20]} className="mt-2">
            <Col>
              <Space direction="horizontal" size={"middle"}>
                <span style={styles}>
                  Country
                  <Text
                    style={{
                      color: "gray",
                      fontSize: "11px",
                      fontFamily: 'sansation',
                    }}
                  >
                    : {options?.country}
                  </Text>
                </span>
                <span style={styles}>
                  State
                  <Text
                    style={{
                      color: "gray",
                      fontSize: "11px",
                      fontFamily: 'sansation',
                    }}
                  >
                    : {options?.state}
                  </Text>
                </span>
                <span style={styles}>
                  City
                  <Text
                    style={{
                      color: "gray",
                      fontSize: "11px",
                      fontFamily: 'sansation',
                    }}
                  >
                    : {options?.city}
                  </Text>
                </span>
              </Space>
            </Col>

            {options?.industries.length > 0 ? (
              <Col>
                <span style={styles}>Industry : </span>

                {options?.industries.map((val, ind) => {
                  return (<>
                    &nbsp;&nbsp;
                    <Text
                      strong
                      style={{
                        color: "gray",
                        fontSize: "11px",
                        fontFamily: 'sansation',
                      }}
                    >
                      {val?.title}
                    </Text>
                  </>
                  );
                })}
              </Col>
            ) : null}
          </Row>
        </>
      )}
      <Space size={"small"} className="cntrlsld">
        <button
          disabled={pageNumber === 1 && true}
          className="addbtnt"
          onClick={changePageBack}
        >
          <BiArrowBack fontSize={"20px"} />
        </button>

        <button
          disabled={pageNumber >= numPages && true}
          className="addbtnt"
          onClick={changePageNext}
        >
          <MdOutlineArrowForward fontSize={"20px"} />
        </button>
      </Space>
      <a href={pdf} download="Pdf" style={{ display: "block", width: "fit-content", marginLeft: "auto" }}>
        <button className="addbtnt">
          <DownloadOutlined style={{ fontSize: "20px" }} />
        </button>
      </a>
    </div>
  );
};

export default PdfComponent;

// {Array.from(new Array(numPages), (el, index) => (
//   <Page key={`page_${index + 1}`} pageNumber={index + 1} />
// ))}
