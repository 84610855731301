import { Row, Space, Typography } from "antd";
import React from "react";

export default function RiskAndDiscloInProfile({ RiskAndDisclosures }) {
  const { Title } = Typography;

  return (
    <div>
      {RiskAndDisclosures ? (
        <p className="textEditorInnerData" dangerouslySetInnerHTML={{ __html: RiskAndDisclosures?.text }} />
      ) : (
        <h6 className="text-white text-center">Data not Found!</h6>
      )}
    </div>
  );
}
