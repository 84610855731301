import React from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import thumbnail from "../../images/no_image.jpg";
import ExploreCards from "../Common/ExploreCards";

const ProductSlider = ({ data }) => {
  const settings = {
    dots: true,
    infinite: data?.companies.length > 3 ? true : false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    arrow: true,
    arrowPosition: "bottom",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const navigate = useNavigate();
  const handleClick = (id) => {
    navigate(`/user/company/portfolio/${id}`);
  };


  console.log(data)


  return (
    <section className="sectionSpacing sldbx" id={`${data?.title}`}>
      <div className="col-12 mx-auto py-1">
        {
          data?.companies.length > 0 ?
            <Slider {...settings}>
              {data?.companies.map((data, index) => {
                return (
                  <ExploreCards
                    isInterested={data?.interested}
                    isFollowed={data?.followed}
                    logo={data?.logo}
                    key={index}
                    id={data?.id}
                    title={data?.company_name}
                    desc={data?.description}
                    total_investment={data?.total_amount ? data?.total_amount : "-"}
                    minimum_investment={data.minimum_investment ? data.minimum_investment : null}
                    state={data.state ? data.state : ""}
                    country={data.country ? data.country : ""}
                    start_date={data.active_schedule_start_date ? data.active_schedule_start_date : ""}
                    end_date={data.active_schedule_end_date ? data.active_schedule_end_date : ""}
                    img={data?.primary_video_thumbnail ? data.primary_video_thumbnail : thumbnail}
                    isLevelTwo={data?.is_level_two_profile}
                    onClick={() => { handleClick(data?.id) }}
                  />
                  // <div className="investorSliderWrapper" >
                  //   <div className={`productSliderCard ${levelTwoState === "none" ? data.is_level_two_profile ? "blrcrd" : "hvreff" : "hvreff"}`}
                  //     onClick={levelTwoState === "none" ? data.is_level_two_profile ? () => { displayWorrningToast("Upgrade to level 2") } : () => { handleClick(data?.id) } : () => { handleClick(data?.id) }}>
                  //     <img
                  //       alt="example"
                  //       src={img}
                  //       className="productSliderImg"
                  //     />
                  //     <div>
                  //       <h5>{data?.company_name}</h5>
                  //       <p>{data?.description}</p>
                  //     </div>
                  //   </div>
                  // </div>
                )
              })}
            </Slider>
            :
            <div className="prdsldwrp">
              {data?.companies.map((data, index) => {
                return (
                  <div className="heighthandle" key={index}>

                    <ExploreCards
                      isInterested={data?.interested}
                      isFollowed={data?.followed}
                      logo={data?.logo}
                      id={data?.id}
                      title={data?.company_name}
                      desc={data?.description}
                      total_investment={data?.total_amount ? data?.total_amount : "-"}
                      minimum_investment={data.minimum_investment ? data.minimum_investment : null}
                      state={data.state ? data.state : ""}
                      country={data.country ? data.country : ""}
                      start_date={data.active_schedule_start_date ? data.active_schedule_start_date : ""}
                      end_date={data.active_schedule_end_date ? data.active_schedule_end_date : ""}
                      img={data?.primary_video_thumbnail ? data.primary_video_thumbnail : thumbnail}
                      isLevelTwo={data?.is_level_two_profile}
                      onClick={() => { handleClick(data?.id) }}
                    />

                  </div>
                )
              })}
            </div>
        }

      </div>
    </section>
  );
};

export default ProductSlider;
