import React from 'react';
import DateTimeDisplay from './DateTimeDisplay';
import UseCountdown from './UseCountDown';
import { useLocation } from 'react-router-dom';

const ExpiredNotice = () => {
    const location = useLocation();

    return (
        <div className="show-counter">
            <div
                className="countdown-link"
            >
                <DateTimeDisplay value="00" type={'Days'} isDanger={false} />
                <DateTimeDisplay value="00" type={'Hours'} isDanger={false} />
                <DateTimeDisplay value="00" type={'Mins'} isDanger={false} />
                <DateTimeDisplay value="00" type={'Seconds'} isDanger={false} />
            </div>
        </div>
    );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
    const location = useLocation();

    return (
        <div className="show-counter">
            <div
                className="countdown-link"
            >
                {location?.pathname !== "/company/dashboard" ? <>
                    <DateTimeDisplay value={days} type={'Days'} isDanger={false} /><span className='text-secondary fs-10 fw-400'>:</span>
                    <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} /><span className='text-secondary fs-10 fw-400'>:</span>
                    <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} /><span className='text-secondary fs-10 fw-400'>:</span>
                    <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
                </>
                    :
                    <>
                        <DateTimeDisplay value={days} type={'Days'} isDanger={false} />
                        <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />
                        <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
                        <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
                    </>
                }
            </div>
        </div>
    );
};

const CountdownTimer = ({ targetDate }) => {
    const [days, hours, minutes, seconds] = UseCountdown(targetDate);

    if (days + hours + minutes + seconds <= 0) {
        return <ExpiredNotice />;
    } else {
        return (
            <ShowCounter
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
            />
        );
    }
};

export default CountdownTimer;


