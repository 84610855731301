import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "react-bootstrap";

import { TextEditor } from "../../../../../component/TextEditor";
import { displayErrorToast, displaySuccessToast, displayWorrningToast } from "../../../../../helper/toast_notification_function";
import { createCompanyQuestionAnswers, createUpdateAnswers, updateCompanyFaqsQuestionAnswer } from "../../../../../api/companyApis/portfolio/FaqApis";

const AddCompanyOwnFaq = ({ isOpen, handleToggle, toggleState, state, data ,id,setName}) => {
  const [formData, setFormData] = useState(null);
  const [btnLoader, setBtnLoader] = useState(false);
  const [question , setQuestion] = useState()
  const [answer ,setAnswer] = useState("")
  const [isDisabled, setIsDisabled] = useState(true);


  const handleChange = (e) => {
    setIsDisabled(false);
   
  };


  const handler = (e) =>{
   
  }
  


  console.log(data , formData , "formdata" ,state)

  const Submit = () => {
    let obj = {question :question , answer : answer}
    console.log(state)
    setBtnLoader(true)
    if (state == "create") {
      setIsDisabled(true);

      createCompanyQuestionAnswers(obj)
        .then((res) => {
          setIsDisabled(false);
          setFormData("");
          toggleState();
          handleToggle();
    setBtnLoader(false)
         setName("")
          displaySuccessToast("Success");
        })
        .catch((error) => {
    setBtnLoader(false)

          setIsDisabled(false);
          if (error.response.status == 422) {
            displayWorrningToast(error.response.data.message[0]);
          } else {
            displayErrorToast(error);
          }
        });
      }else{

        updateCompanyFaqsQuestionAnswer(obj,id).then((res) => {
         if(res.data.success){
          console.log(res.data)
          setIsDisabled(false);
          setFormData("");
          toggleState();
          handleToggle();
    setBtnLoader(false)
    setName("")

          displaySuccessToast("Success");
         }
        }).catch((err) => {
displayErrorToast(err)
        })
      }
  };


  useEffect(() => {

    if(formData){
      setIsDisabled(false)
    }
  },[formData])


  console.log(data , id ,state)
  return (
    <div>
      {/* Modal for Uploading Video Presentation  */}
      <Modal show={isOpen} onHide={handleToggle}>
        <ModalHeader closeButton>{state == "create" ? "Create FAQ" : "Update Faq" }</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup className="mb-2">
              <FormLabel htmlFor="title">Title</FormLabel>
              <FormControl
                type="text"
                name="question"
                id="title"
                defaultValue={state == "update" ? data?.[0]?.question : question}
                onChange={(e) => setQuestion(e.target.value)}
              />
              {/* {formError.presentationTitle && (
                  <p>{formError.presentationTitle}</p>
                )} */}
            </FormGroup>
          


        <TextEditor
        // onClick={submit}
        data={state == "update" ? data?.[0] : answer}
        setData={ setAnswer}
        disabled={true}
        loader={false }
        hideBtn={true}
        handleChange={handleChange}
      />

         {console.log(state == "create" ? (!question && isDisabled == false) : isDisabled)}
          </Form>
        </ModalBody>
        <Modal.Footer>
          <Button
            variant={"primary"}
            disabled={state == "create" ? (!question && isDisabled == false) : isDisabled}
            onClick={Submit}
          >
            {btnLoader ? <Spinner animation="border" size="sm"/> :  "Submit"}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End Modal for Uploading Video Presentation  */}
    </div>
  );
};

export default AddCompanyOwnFaq;
