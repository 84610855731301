import { apiHandle } from "../apiHandler";

export const getTransactionLogsAPi = () => {
  return apiHandle().get("/company/auth/transactionLogs");
};

export const getMinimumDays = () => {
  return apiHandle().get("/company/auth/activeSchedule/minimumDays");
};
export const getBookingTypes = () => {
  return apiHandle().get("/company/auth/bookingType");
};

export const postBookingsApi = (data) => {
  return apiHandle().post("/company/auth/booking", data);
};

export const promoCodeApi = (data) => {
  return apiHandle().post("/company/auth/booking/applyPromoCode", data);
};

export const cancelBooking = (id) => {
  return apiHandle().post(`/company/auth/booking/cancel/${id}`);
};
export const repayTransaction = (id) => {
  return apiHandle().post(`/company/auth/booking/repay/${id}`);
};


