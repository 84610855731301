
import { Form, Formik } from "formik";
import { advancedSchema, investorSchema } from "../../schemas";
import CustomInput from "../../component/Common/CustomInput";
import { useEffect, useState, useMemo } from "react";
import allstates from "../../utility/allstates";
import cities from "../../utility/cities";
import country from "../../utility/country";
import Select from "react-select";
import CustomCheckbox from "../../component/Common/CustomCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { getAllIndustriesHandle } from "../../redux/actions/getAllIndustriesAction";
import { RegisterCompany, RegisterUser } from "../../api/AuthApi";
import { dataCleaning, dataWithFiles } from "../../helper/common_functions";
import { displayErrorToast, displaySuccessToast } from "../../helper/toast_notification_function";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Modal, Spinner } from "react-bootstrap";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import {
    Combobox,
    ComboboxInput,
    ComboboxPopover,
    ComboboxList,
    ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import useWindowDimensions from "../../component/WindowDimensions";


const InvestorSignUpForm = () => {
    // const libraries = ["places"]
    // const { isLoaded, loadError } = useLoadScript({
    //     googleMapsApiKey: process.env.REACT_APP_MAP_API,
    //     libraries
    // });

    // if (loadError) {
    //     console.error("Error loading Google Maps API:", loadError);

    // }

    // if (!isLoaded) {
    //     console.log("Loading...");
    // }
    // else {
    //     console.log("loads")
    // }
    const { width, height } = useWindowDimensions();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [UserSign, SetUserSign] = useState({
        first_name: "",
        last_name: "",
        username: "",
        placeId: "",
        email: "",
        password: "",
        confirmPassword: "",
        captcha: "",
        // date_of_birth: "",
    });

    const [selected, setSelected] = useState(null);

    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
    } = usePlacesAutocomplete();

    const handleSelect = async (address) => {
        const selectedPlace = data.find((place) => place.description === address);

        if (selectedPlace) {
            SetUserSign({ ...UserSign, placeId: selectedPlace.place_id })
        }
        setValue(address, false);
        clearSuggestions();

        // const results = await getGeocode({ address });
        // const { lat, lng } = await getLatLng(results[0]);
        // setSelected({ lat, lng });
    };


    const [userregisterdata, setuserregisterdata] = useState({
        first_name: "",
        last_name: "",
        username: "",
        placeId: UserSign?.placeId,
        email: "",
        password: "",
        confirmPassword: "",
        captcha: UserSign?.captcha,
    })

    const [signenable, setsignenable] = useState(true)

    const checkcaptcha = async (token) => {
        if (token) {
            SetUserSign({ ...UserSign, captcha: token });

            setsignenable(false)
        }
        else {
            setsignenable(true)
        }
    }

    const [send, setsend] = useState(false);
    const [actiontoperf, setactiontoperf] = useState();

    const navigate = useNavigate()
    const onSubmit = (values, actions) => {
        setuserregisterdata({
            first_name: values.firstname,
            last_name: values.lastname,
            username: values.username,
            email: values.email,
            password: values.password,
            confirmPassword: values.confirmpassword,
            placeId: UserSign?.placeId,
            captcha: UserSign?.captcha,
        })
        setsend(true)
        setactiontoperf(actions)
    };

    // const [steptwo, setsteptwo] = useState(false);
    // const [countryid, setCountryid] = useState('231');
    // const [countryname, setcountryname] = useState('');
    // const [statename, setstatename] = useState('');
    // const [stateid, setstateid] = useState('');
    // const [cityname, setcityname] = useState('');
    // let stateopt = [];
    // let cityopt = [];

    // const handleCountry = (e) => {
    //     stateopt = [];
    //     cityopt = [];
    //     setCountryid(e.value)
    //     setcountryname(e.label)
    //     setstatename("")
    //     setstateid("")
    //     setcityname("")
    //     SetUserSign({ ...UserSign, country: e.label, state: "", city: "" })

    // }

    // const handleState = (e) => {
    //     cityopt = [];
    //     setstateid(e.value)
    //     setstatename(e.label)
    //     setcityname("")
    //     SetUserSign({ ...UserSign, state: e.label, city: "" })
    // }

    // const handleCity = (e) => {
    //     setcityname(e.label)
    //     SetUserSign({ ...UserSign, city: e.label })
    // }

    // const options = country.map((data) => {
    //     return (
    //         {
    //             value: data.country_id,
    //             label: data.country_name
    //         }
    //     )
    // })


    // let filter = options.filter((val) => { return val.value == '231' })
    // let obj = {
    //     value: filter.value,
    //     label: filter.label
    // }

    // useEffect(() => {
    //     allstates.map((data) => {
    //         if (data.country_id == countryid) {
    //             return (stateopt.push({ value: data.state_id, label: data.state_name }));
    //         }
    //     })
    // }, [countryid, stateid, cityname, filter])

    // useEffect(() => {
    //     cities.map((data) => {
    //         if (data.state_id == stateid) {
    //             return (cityopt.push({ value: data.city_id, label: data.city_name }));
    //         }
    //     })
    // }, [stateid, cityname])

    useEffect(() => {
        if (send) {
            RegisterUser(dataCleaning(userregisterdata))
                .then((res) => {
                    actiontoperf.resetForm()
                    actiontoperf.setSubmitting(false)
                    displaySuccessToast("Registered Successfully, Check Your Email To Verify Before Logging In");
                    handleShow()
                    setTimeout(() => {
                        navigate("/login");
                    }, 5000);
                    setsend(false)
                })
                .catch((err) => {
                    actiontoperf.setSubmitting(false)
                    displayErrorToast(err);
                    setsend(false)
                });
        }
    }, [userregisterdata])

    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.defer = true;
    //     script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API}&libraries=places`;
    //     document.body.appendChild(script);
    // }, [])


    return (
        <>
            <Formik
                initialValues={{
                    firstname: "",
                    lastname: "",
                    username: "",
                    password: "",
                    address: "",
                    confirmpassword: "",
                    email: "",
                    captcha: ""
                }}
                validationSchema={investorSchema}
                onSubmit={onSubmit}
            >
                {({ isSubmitting, errors, touched, setFieldValue }) => (
                    <Form>
                        <div className="row">
                            <div className="col-lg-6 col-md-12 mt-2">
                                <CustomInput
                                    label="First Name"
                                    name="firstname"
                                    type="text"
                                    placeholder="Enter your First Name"
                                />
                            </div>
                            <div className="col-lg-6 col-md-12 mt-2">
                                <CustomInput
                                    label="Last Name"
                                    name="lastname"
                                    type="text"
                                    placeholder="Enter your Last Name"
                                />
                            </div>
                            <div className="col-lg-6 col-md-12 mt-2">
                                <CustomInput
                                    label="User Name"
                                    name="username"
                                    type="text"
                                    placeholder="Enter your User Name"
                                />
                            </div>
                            <div className="col-lg-6 col-md-12 mt-2">
                                <CustomInput
                                    label="Email Address"
                                    name="email"
                                    type="email"
                                    placeholder="Enter your Email Address"
                                />
                            </div>
                            <div className="col-lg-6 col-md-12 mt-2">
                                <CustomInput
                                    label="Password"
                                    name="password"
                                    type="password"
                                    placeholder="Enter your Password"
                                />
                            </div>
                            <div className="col-lg-6 col-md-12 mt-2">
                                <CustomInput
                                    label="Confirm Password"
                                    name="confirmpassword"
                                    type="password"
                                    placeholder="Confirm Password"
                                />
                            </div>
                            <div className="col-lg-6 col-md-12 mt-2">
                                <label className="frmlabel">Address</label>
                                <div className="places-container">
                                    <Combobox onSelect={(address) => { handleSelect(address); setFieldValue("address", address); }}>
                                        <ComboboxInput
                                            name="address"
                                            value={value}
                                            onChange={(e) => {
                                                setValue(e.target.value);
                                            }}
                                            disabled={!ready}
                                            className={`${touched.address && errors.address ? "input-error" : ""} frminp`}
                                            placeholder="Search an address"
                                        />
                                        <ComboboxPopover>
                                            <ComboboxList>
                                                {status === "OK" &&
                                                    data.map(({ place_id, description }) => (
                                                        <ComboboxOption key={place_id} value={description} />
                                                    ))}
                                            </ComboboxList>
                                        </ComboboxPopover>
                                    </Combobox>
                                </div>
                                <div className="ermsg">
                                    {touched.address && errors.address && errors.address}
                                </div>
                            </div>
                            {/* <div className="col-lg-6 col-md-12 mt-2">
                            <CustomInput
                                label="Address"
                                name="address"
                                type="text"
                                placeholder="Enter Your Address"
                            />
                        </div> */}
                        </div>
                        <div className="col-12 mt-2">
                            <ReCAPTCHA
                                name="captcha"
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                onChange={(token) => { checkcaptcha(token); setFieldValue("captcha", token) }}
                                size={width < 400 ? "compact" : "normal"}
                                style={width < 400 ? { margin: "auto", width: "fit-content" } : {}}
                            />
                            <div className="ermsg">
                                {touched.captcha && errors.captcha && errors.captcha}
                            </div>
                        </div>
                        <button className={`frmsubmit ${signenable ? "" : ""}`} disabled={isSubmitting} type="submit">
                            {
                                isSubmitting ? <Spinner size="md" /> : "Submit"
                            }
                        </button>
                    </Form>
                )}
            </Formik>

            <Modal show={show} onHide={handleClose} backdrop="static" centered closeButton={true}>
                <Modal.Body>
                    <h4 className="text-center mb-3">Hurrah!!!</h4>
                    <p className="text-center">You Registered Successfully. Verification Link has been sent to your email address please check and verify before logging in.</p>

                </Modal.Body>
            </Modal>
        </>
    );
};
export default InvestorSignUpForm;