import { Paper } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { ImProfile } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { UserDataTableApi } from "../../api/companyApis/analyticsApi";
import { displayErrorToast } from "../../helper/toast_notification_function";

// let headers = [
//   { label: "Id", key: "id" },
//   { label: "First Name", key: "first_name" },
//   { label: "Last Name", key: "last_name" },
//   { label: "Profile Views", key: "profile_view_count" },
//   { label: "Video Count", key: "video_view_count" },
//   { label: "Contact", key: "contact_number" },
//   { label: "email", key: "email" },
// ];

const UserDataTable = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);


  const navigate = useNavigate();

  const columns = [
    {
      field: "id",
      headerName: "ID",

      width: 40
    },
    // {
    //   field: "first_name",
    //   headerName: "First name",

    //   flex: 1,
    // },
    // {
    //   field: "last_name",
    //   headerName: "Last name",

    //   flex: 1,
    // },
    {
      field: "fullName",
      headerName: "First Name",
      description: "This column has a value getter and is not sortable.",
      minWidth: 140,

      flex: 1,
      sortable: false,
      valueGetter: (params) =>
        `${params.row.first_name || ""} ${params.row.last_name || ""}`,
    },
    {
      field: "contact_number",
      headerName: "Contact Number",

      flex: 1,
      minWidth: 130
    },
    // {
    //   field: "email",
    //   headerName: "Email",

    //   flex: 1,
    //   minWidth: 180
    // },
    {
      field: "video_view_count",
      headerName: "Video Views",
      minWidth: 100,

      flex: 1,
    },
    {
      field: "profile_view_count",
      headerName: "Profile Views",
      minWidth: 115,

      flex: 1,
    },
    {
      field: "interested",
      headerName: "Interested",

      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <div>
              {params.row?.interested ? (
                <span>Interested</span>
              ) : (
                <span>Not Interested</span>
              )}
            </div>
          </>
        );
      },
    },
    {
      field: "followed",
      headerName: "Followed",

      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <div>
              {params.row.followed ? <span>YES</span> : <span>NO</span>}
            </div>
          </>
        );
      },
    },
    {
      field: "details",
      headerName: "Details",

      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="outline-primary"
              onClick={() => navigate(`/company/user/${params.row?.id}`)}
            >
              <ImProfile />
            </Button>
          </>
        );
      },
    },
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    user_id: false,
    first_name: false,
    last_name: false,
    contact_number: false,
  });




  let appState = localStorage.getItem("IP_accessToken");

  // const paidDownload = () => {
  //   setLoading(true);
  //   UserDownloadPaidCSVApi(true)
  //     .then((res) => {
  //       console.log(res, "uesrpaid")
  //       setLoading(false);
  //       // setPaidData(res.data.data);
  //     })
  //     .catch((error) => console.log("Download Leads", error.response));

  // }


  useEffect(() => {

    UserDataTableApi()
      .then((res) => {
        setLoading(false);
        setData(res.data.data);
      })
      .catch((error) => console.log("user view with profile", error.response));
  }, []);


  const downloadFile = async () => {
    setBtnLoading(true)
    try {
      const token = appState; // Replace with your authentication token
      const headers = { Authorization: `Bearer ${token}`, };


      const response = await axios.get('https://api-staging.investorpresentations.com/company/auth/userWise/merged/paid?download=true', {

        responseType: 'blob',
        headers, // Include the authorization header
      });

      // Create a blob URL for the response data
      const blobUrl = window.URL.createObjectURL(new Blob([response.data]));

      // Create an anchor element to trigger the download
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = 'data.csv'; // Specify the file name
      a.click();
      setBtnLoading(false)

      // Release the blob URL to free up resources
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      setBtnLoading(false)
      displayErrorToast("You have not paid to download leads yet.")
      console.log('Error downloading file:', error);
    }
  };


  if (isLoading) {
    return (
      <>
        <Paper
          className="d-flex align-items-center justify-content-center"
          sx={{ height: 400, width: "100%" }}
        >
          <div className="container">
            <div className="d-flex align-items-center justify-content-center">
              <Spinner animation="border" size="lg" variant="primary" />
            </div>
          </div>
        </Paper>
      </>
    );
  }

  return (
    <div>

      <div className="d-flex justify-content-between align-items-center my-3 investleads user_table_heads">
        <h3 style={{ color: "#fff" }}>Investor Leads</h3>
        <div>
          <Button style={{ background: "linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5)", border: "none", outline: "none", boxShadow: "none" }} onClick={downloadFile} >
            {btnLoading ? <Spinner animation="border" size="sm" /> : <span>Download</span>}<img src="../../images/cloud.svg" alt="img" />
          </Button>

        </div>
      </div>
      <Paper className="tableBg">
        <Box sx={{ height: 550, width: "100%" }}>
          <DataGrid
            rows={data}
            columns={columns}
            getRowId={(row) => row.id}
            rowsPerPageOptions={[10, 20, 30]}
            checkboxSelection
            disableColumnMenu={true}
            disableSelectionOnClick
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
          // experimentalFeatures={{ newEditingApi: true }}
          />
        </Box>
      </Paper>
    </div>
  );
};

export default UserDataTable;



