import axios from "axios";
import Logout from "../component/Logout";
import { isJwtExpired } from "../helper/jwtExpireChecked";
import { displaySuccessToast } from "../helper/toast_notification_function";
import { LogoutFn, LogoutHandle } from "../redux/actions/authActions";
import { logoutUserType } from "../redux/constants/Common_constants";
import store from "../redux/store";


const { dispatch } = store;
// const apiUrl = "http://192.168.0.105:3000";
const apiUrl = process.env.REACT_APP_BASE_URL;
// const apiUrl = "https://api-staging.investorpresentations.com"

export const apiHandle = (isAuth = true, multipart = false) => {
  let aaxios; // veriable for creating axios instance and use intercept

  // condition for enabling multi part of form data in request
  let multipartData = multipart
    ? { "Content-Type": "multipart/form-data", Accept: "application/json" }
    : "";
  // condition for enabling multi part of form data in request
  aaxios = axios.create({
    baseURL: apiUrl,
    headers: {
      ...axios.defaults.headers,
      ...multipartData,
    },
  });

  aaxios.interceptors.request.use(
    async (req) => {
      if (!isAuth) {
        return req;
      }

      let accessToken = await localStorage.getItem("IP_accessToken"),
        refreshToken = await localStorage.getItem("IP_refreshToken"),
        appState = await localStorage.getItem("IP_State");
      // let renewTokenRoute = appState === "USER" ? "/user/renewAccessToken" : "/company/renewAccessToken";
      let renewTokenRoute = "/renewAccessToken"


      if (accessToken) {
        if (refreshToken) {
          if (!isJwtExpired(refreshToken)) {
            if (!isJwtExpired(accessToken)) {
              req.headers.Authorization = `Bearer ${accessToken}`;

              return req;
            }

            const renewApiRes = await axios.post(`${apiUrl}${renewTokenRoute}`,
              {
                refreshToken: refreshToken,
              }
            );
            req.headers.Authorization = `Bearer ${renewApiRes?.data?.data[0]?.NewAccessToken}`;
            await localStorage.setItem(
              "IP_accessToken",
              renewApiRes?.data?.data[0]?.NewAccessToken
            );

            await localStorage.setItem(
              "IP_refreshToken",
              !renewApiRes?.data?.data[0]?.NewRefreshToken
                ? refreshToken
                : renewApiRes?.data?.data[0]?.NewRefreshToken
            );

            return req;
          }
        }
      }

      LogoutFn(dispatch);

      // setTimeout(() => {
      // displaySuccessToast("session expired login agail")

      // }, 3000);

      // window.location.replace("/login")
      // localStorage.clear();
      // dispatch({
      //   type: logoutUserType,
      // });
      // displaySuccessToast("session Expired login again!")
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  aaxios.interceptors.response.use(
    (response) =>
      new Promise((resolve, reject) => {
        resolve(response);
      }),
    (error) => {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  );

  return aaxios;
};
