import { apiHandle } from "../../../apiHandler";

export const createReviewApi = (body) => {
  return apiHandle().post(`/company/auth/reviews`, body);
};

export const updateReviewApi = (id, body) => {
  return apiHandle().put(`/company/auth/reviews/${id}`, body);
};

export const deleteReviewApi = (id) => {
  return apiHandle(true, true).delete(`/company/auth/reviews/${id}`);
};

export const getReviewApi = (id) => {
  return apiHandle().get(`/company/auth/reviews/${id ? id : ""}`);
};
