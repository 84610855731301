import { Image, Popconfirm, Space } from "antd";
import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormGroup,
  InputGroup,
  Spinner
} from "react-bootstrap";

import {
  displayErrorToast,
  displaySuccessToast
} from "../../../../helper/toast_notification_function";
import { companyMediaMentionApi, deleteMediaMentionApi, getCompanyMediaMentionApi } from "../../../../api/companyApis/portfolio/galleryAndMedia/galleryAndMedia";

export default function MediaMentions() {

  const [loading, setLoading] = useState(false);

  const [btnLoader , setBtnLoader] = useState(false)

  const [gallery, setGallery] = useState();
  const [galleryDataFromApi, setGalleryDataFromApi] = useState([]);
  const [galleryBtnDisable, setGalleryBtnDisable] = useState(true);

  const fileInputRef = React.useRef();


  //   submit gallery func
  const submitGallery = () => {
    setBtnLoader(true)
    if (gallery) {
      setGalleryBtnDisable(true);
      let obj = { logos: gallery?.gallery_images };


      companyMediaMentionApi(obj)
        .then((res) => {
          if (res?.data?.success) {
            getGalleryData();
            displaySuccessToast("Success");
    setBtnLoader(false)

            setGalleryBtnDisable(true);
          }
        })
        .catch((err) => {
          setGalleryBtnDisable(false);
          displayErrorToast(err);
    setBtnLoader(false)

        });
    }
  };

  const getGalleryData = () => {
    setLoading(true);
    getCompanyMediaMentionApi()
      .then((res) => {
        if (res?.data?.success) {
          setGalleryDataFromApi(res?.data?.data);
          setLoading(false);
        }
      })
      .catch((Err) => {
        setLoading(false);
        displayErrorToast(Err);
      });
  };

  const imageDltFn = (id) => {
    deleteMediaMentionApi(id)
      .then((res) => {
        if (res?.data?.success) {
          getGalleryData();
          displaySuccessToast("logo deleted!");
        }
      })
      .catch((err) => displayErrorToast(err));
  };

  useEffect(() => {
    getGalleryData();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <>
      <Space
        direction="vertical"
        style={{
          width: "100%",
        }}
        size="large"
      >
        <div>
          <div className="d-flex justify-content-between align-items-center mt-5 mb-2 doc_slide">
            <div>
              <h3>Media Mentions</h3>
              <p>(Aspect Ratio: 1/1 | 100*100px Min.)</p>
            </div>
            <div>
              <button
                onClick={submitGallery}
                className="addbtnt"
                style={{ width: "80px", fontSize: "16px" }}
                disabled={galleryBtnDisable}
              >
                {btnLoader? <Spinner animation="border" size="sm"/> :"submit"}
              </button>
            </div>
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <FormGroup className="mb-2">
              <InputGroup className="mb-3">
                <FormControl
                  type="file"
                  name="gallery_images"
                  id="gallery_images"
                  accept="image/*"
                  multiple
                  ref={fileInputRef}
                  onChange={(e) => {
                    setGalleryBtnDisable(false);
                    setGallery({ [e.target.name]: [...e.target.files] });
                  }}
                />
                {fileInputRef?.current?.value && (
                  <InputGroup.Text>
                    <span
                      onClick={() => {
                        setGallery(null);
                        setGalleryBtnDisable(true);

                        fileInputRef.current.value = "";
                      }}
                    >
                      ✖
                    </span>
                  </InputGroup.Text>
                )}
              </InputGroup>
            </FormGroup>
          </form>

          {/* ==================================  company gallery map && space  =========================================== */}

          <div>
            {galleryDataFromApi.length > 0 ? (
              <div className="imgs_bx row">
                {galleryDataFromApi.map((val, ind) => {
                  return (
                    <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                      <div className="flxbximg">
                        <Image
                          className="imgsh"
                          src={val?.logo}
                          preview={{ src: val.logo }}
                        />
                        <div>
                          <Popconfirm
                            title="Delete the task"
                            description="Are you sure to delete this task?"
                            onConfirm={() => imageDltFn(val?.id)}
                            onCancel={"cancel"}
                            okText="Yes"
                            cancelText="No"
                          >
                            <button className="btntm">
                              <img src="/images/dlt.svg" alt="img1"/>
                            </button>
                          </Popconfirm>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : null}
          </div>
        </div>
      </Space>
    </>
  );
}
