import 'antd/dist/antd.less';
import { useEffect, useLayoutEffect, useState } from "react";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-phone-number-input/style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import "../node_modules/slick-carousel/slick/slick-theme.css";
import "../node_modules/slick-carousel/slick/slick.css";
import DashboardFooter from "./component/Common/DashboardFooter";
import NavbarNew from "./component/Common/NavbarNew";
import PageNotFound from "./component/Common/PageNotFound";
import Sidebar from "./component/dashboard/SideBar";
import TransactionLogs from "./component/payments/TransactionLogs";
import "./index.css";
import About from "./pages/About";
import Contact from "./pages/Contact";
import EmailVerification from "./pages/EmailVerification";
import HomeNew from "./pages/HomeNew";
import LeadGeneration from "./pages/LeadGeneration";
import LoginNew from "./pages/LoginNew";
import Product from "./pages/Product";
import SignUp from "./pages/SignUp";
import Terms from "./pages/Terms";
import CompanyPortfolio from "./pages/company/CompanyPortfolio";
import CompanyProfile from "./pages/company/CompanyProfile";
import PresentationMedia from "./pages/company/PresentationMedia";
import Settings from "./pages/company/Settings";
import UserTables from "./pages/company/UserTables";
import CompanyLiveDataTabs from "./pages/company/companyLiveData/CompanyLiveDataTabs";
import Dashboard from "./pages/company/dashboard/Dashboard";
import { UserDetailsById } from "./pages/company/dashboard/UserDetailsById";
import Portfolio from "./pages/company/portfolio";
import Schedule from './pages/company/schedule';
import Company from "./pages/user/Company";
import CompanyPortFolioForUserSide from "./pages/user/CompanyPortFolioForUserSide";
import UserProfile from "./pages/user/userProfile";
import { SAVE_GETTING_AUTH_STATE } from "./redux/constants/auth_Constants";
import ForgotPassword from './pages/ForgotPassword';
import PasswordVerified from './pages/PasswordVerified';
import EmailForgotPass from './pages/EmailForgotPass';


function App() {
  const dispatch = useDispatch();
  const [scrollTop, setScrollTop] = useState()
  const { appState, isAppStateLoadig, error } = useSelector(
    (state) => state.AppStateReducer
  );

  const checkAppstate = () => {
    let local_State = localStorage.getItem("IP_State");


    if (!appState && local_State) {
      dispatch({
        type: SAVE_GETTING_AUTH_STATE,
        payload: local_State,
      });

    }
    if (!appState && !local_State) {
      // navigate("/login");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  useLayoutEffect(() => checkAppstate(), []);


  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener(
        "scroll",
        () => setScrollTop(window.pageYOffset > 5)
        // setNews(true)
      );
    }
  }, []);





  return (
    <>
      <Router>
        <>
          {appState == null && <AuthRouter />}
          {appState === "USER" && <UserRouter />}
          {appState === "COMPANY" && <CompanyRouter scrollTop={scrollTop} />}

        </>

      </Router>
      <ToastContainer />
    </>
  );
}

export default App;

const AuthRouter = () => {
  return (
    <div style={{ background: "black" }}>
      <NavbarNew />
      <Routes>
        <Route path='*' element={<PageNotFound />} />

        <Route path="/" element={<HomeNew />} />
        <Route path="/about" element={<About />} />
        <Route path="/leadGeneration" element={<LeadGeneration />} />

        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/sidebar" element={<Sidebar />} /> */}

        <Route path="/email-verification/:token" element={<EmailVerification />} />

        <Route path="/forgotPasswordEmail" element={<EmailForgotPass />} />
        {/* <Route path="/ForgetPassword/:token" element={<PasswordVerified />} /> */}
        <Route path="/ForgetPassword/:token" element={<ForgotPassword />} />

        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<LoginNew />} />
        {/* <Route path="/forgotPassword" element={<ForgotPassword />} /> */}

        <Route path="/terms&conditions" element={<Terms />} />
        {/* <Route path="/signUpUser" element={<SignUpUser />} />
        <Route path="/signUpCompany" element={<SignUpCompany />} /> */}
        {/* <Route path="*" element={<LoginNew />} /> */}
      </Routes>
      <DashboardFooter />
      <ToastContainer />
    </div>
  );
};

const CompanyRouter = ({ scrollTop }) => {
  const location = useLocation();
  const locationPathCheck = location.pathname.includes("/company");
  const path = ["/", "/about", "/contact", "/Profile", "/Portfolio", "/leadGeneration", "/terms&conditions"];
  let checkNev = path.find((e) => e === location.pathname);

  return (
    <>
      {!checkNev ? null : <NavbarNew />}

      {!locationPathCheck && (
        <>
          <Routes>
            <Route path='*' element={<PageNotFound />} />

            <Route path="/" element={<HomeNew />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<LoginNew />} />

            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/Portfolio" element={<CompanyPortfolio />} />
            {/* <Route path="/Profile" element={<CompanyProfile />} /> */}
            <Route path="/leadGeneration" element={<LeadGeneration />} />
            <Route path="/terms&conditions" element={<Terms />} />

            <Route
              path="*"
              element={<>
                <Sidebar scrollTop={scrollTop}>
                  <Dashboard />
                </Sidebar>
              </>
              }
            />
          </Routes>
          <DashboardFooter />

        </>
      )}

      {locationPathCheck && (
        <>
          <Sidebar>
            <Routes>
              <Route path="/leadGeneration" element={<LeadGeneration />} />

              <Route path="/company/dashboard" element={<Dashboard />} />
              <Route path="/company/*" element={<Dashboard />} />
              <Route
                path="/company/presentation_videos"
                element={<PresentationMedia />}
              />
              <Route path="/company/payments" element={<CompanyLiveDataTabs />} />
              <Route
                path="/company/Transactionlogs"
                element={<TransactionLogs />}
              />
              <Route path="/company/portfolio" element={<Portfolio />} />
              <Route path="/company/schedule" element={<Schedule />} />
              {/* <Route path="/company/settings" element={<Settings />} /> */}
              <Route path="/company/user_tables" element={<UserTables />} />
              <Route path="/company/profile" element={<CompanyProfile />} />
              <Route path="/company/user/:id" element={<UserDetailsById />} />
              <Route path="/terms&conditions" element={<Terms />} />
            </Routes>
          </Sidebar>

          <ToastContainer />
        </>
      )}
      {/* <Footer /> */}

    </>
  );
};

const UserRouter = () => {
  return (
    <>
      <NavbarNew />
      <Routes>
        <Route path='*' element={<PageNotFound />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<LoginNew />} />
        <Route path="/" element={<HomeNew />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />

        <Route path="/product" element={<Product />} />
        <Route path="/user/profile" element={<UserProfile />} />
        <Route path="/leadGeneration" element={<LeadGeneration />} />
        <Route path="/user/company" element={<Company />} />
        <Route path="/*" element={<Company />} />
        <Route path="/terms&conditions" element={<Terms />} />
        <Route
          path="/user/company/portfolio/:id"
          element={<CompanyPortFolioForUserSide />}
        />
        {/* <Route path="/user/company/:id" element={<CompanyDetails2 />} /> */}
      </Routes>
      <DashboardFooter />
      <ToastContainer />
    </>
  );
};

