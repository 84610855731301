import { Button, Tabs } from "antd";
import React, { useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi";
import { MdOutlineArrowForward } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
  publishPortFolioApi
} from "../../../api/companyApis/portfolio/modulesActiveApi";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../helper/toast_notification_function";
import CompanyCardManagePortfolio from "./CompanyCard/CompanyCardManagePortfolio";
import Dataroom from "./dataroom";
import FAQ from "./documentUpload/faq/Faq";
import FormC from "./formC/FormC";
import Highlights from "./hightlight";
import InvestorPerks from "./investorPerks/InvestorPerks";
import { ManageModules } from "./manageModules/ManageModules";
import MarketLandscape from "./marketLandScape/MarketLandScape";
import OverView from "./overview/OverView";
import Reviews from "./review";
import RisksAndDisclosures from "./riskDisclosures";
import Team from "./team";
import UseOfProceeds from "./useOfProceeds";
import GalleryAndMedia from "./gallery";
import useWindowDimensions from "../../../component/WindowDimensions";
// import FormC from "./formC/FormC";

// let headings ;

const Portfolio = () => {
  const {width} = useWindowDimensions()
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [headings, setHeadings] = useState("slide");
  const [activeTab, setActiveTab] = useState("1");

  const items = [
    // {
    //   key: "1",
    //   id: "tab-1",
    //   label: `Slide`,
    //   children: <Documents />,
    // },
    {
      key: "1",
      id: "tab-1",

      label: `Highlights`,
      children: (
        <>
          <Highlights />
        </>
      ),
    },
    {
      key: "2",
      id: "tab-2",

      label: `Overview`,
      children: (
        <>
          <OverView />
        </>
      ),
    },
    
    {
      key: "3",
      id: "tab-3",

      label: `Team`,
      children: (
        <>
          <Team />
        </>
      ),
    },
    
    {
      id: "tab-4",

      key: "4",
      label: `Gallery & Media`,
      children: <GalleryAndMedia />,
    },
    {
      key: "5",
      id: "tab-5",

      label: `Investor Perks`,
      children: <InvestorPerks />,
    },
    {
      key: "6",
      id: "tab-6",

      label: `Risks & Disclosures`,
      children: <RisksAndDisclosures />,
    },
    {
      key: "7",
      id: "tab-7",

      label: `Market Landscape`,
      children: <MarketLandscape />,
    },
    {
      key: "8",
      id: "tab-8",

      label: `Filing`,
      children: <FormC />,
    },
    {
      key: "9",
      id: "tab-9",

      label: `Data room`,
      children: <Dataroom />,
    },

    {
      key: "10",
      id: "tab-10",

      label: `Reviews`,
      children: <Reviews />,
    },
    {
      key: "11",
      id: "tab-11",

      label: `FAQ`,
      children: <FAQ />,
    },
    {
      key: "12",
      id: "tab-12",

      label: `Use Of Proceeds`,
      children: <UseOfProceeds />,
    },
    {
      key: "13",
      id: "tab-13",

      label: `Company card`,
      children: < CompanyCardManagePortfolio />,
    },
    {
      key: "14",
      id: "tab-14",
      label: `Manage Modules`,
      children: <ManageModules />,
    },
  ];

  const publishPortFolio = () => {
    setLoading(true);
    publishPortFolioApi()
      .then((res) => {
        const msg = res?.data?.message[0];
        setLoading(false);

        displaySuccessToast(msg);
      })
      .catch((err) => {
        displayErrorToast(err);
        setLoading(false);
      });
  };

  const handleTabChange = (activeKey) => {
    setActiveTab(activeKey);
    let head = items.find((e) => e.key == activeKey);
    setHeadings(head?.label);
  };

  const handleClick = (val) => {
    let tab = Number(activeTab);
    if (val == "Previous") {
      let inc = tab - 1;
      let string = inc.toString();
      setActiveTab(string);
      let head = items.find((e) => e.key == inc);
      setHeadings(head?.label);

    } else if (val == "Next") {
      let inc = tab + 1;
      let string = inc.toString();
      setActiveTab(string);
      let head = items.find((e) => e.key == inc);
      setHeadings(head?.label);
    }

  };

  const customTabStyles = {
    border: "none !important",
  };
  const PortfolioTabs = () => {
    return (
      <>
        <div className="vertical-tabs-container">
          <Tabs
            tabPosition={width >= 500 ? "left" : "top"} // Set tabPosition based on screen size            defaultActiveKey="1"
            activeKey={activeTab ? activeTab : "1"}
            items={items}
            onChange={handleTabChange}
            style={customTabStyles}
          />
        </div>


        <div className="next-Pre-btn">

          <button
            disabled={activeTab == 1 && true}
            className="previousBtn"
            onClick={() => handleClick("Previous")}
          >
            <BiArrowBack fontSize={"15px"} /> &nbsp;
            Previous

          </button>


          <button
            disabled={items.length == activeTab && true}
            className="nextBtn"
            onClick={() => handleClick("Next")}
          >
            Next &nbsp;
            <MdOutlineArrowForward fontSize={"15px"} />
          </button>

        </div>
      </>
    );
  };

  // if (loading) {
  //   return (
  //     <Spinner
  //       as="span"
  //       animation="border"
  //       size="lg"
  //       role="status"
  //       aria-hidden="true"
  //       variant="primary"
  //     />
  //   );
  // }
  return (
    <>
      {/* {------------------------PORTFOLIO HEADER SECTION STARTS------------------------} */}
      <div className="topSectionImgWrapper">
        <h1 className="text-white fw-bold">Build Your Digital Booth Space</h1>
        <p className="text-center text-white col-md-10 col-12 descpay">
          This is where you build-out your digital booth, and upload your video presentations, and all the other pertinent information for investors to see. Start with the first tab at the top, and work your way down. If you would like to hide a tab that is not relevant to your on-line presence, simply turn-off that specific tab as you wish!
        </p>
      </div>

      <div className="px-4">
        <div className="portfolioTabsSection">
          <Row className="d-flex justify-content-between pb-lg-5 pt-lg-5 pt-3  px-3 ">
            <Col sm={12} md={6}>
              <h2
                style={{
                  fontFamily: 'sansation',
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                Start The Process Below
              </h2>
              <p className="text-white">
                Remember, there is NO COST to you as you build your digital booth. YOU WILL NOT BE CHARGED until you are 100% satisfied with your on-line presence, and “Go Live” on the site for investors to see.
              </p>
            </Col>
            <Col sm={12} md={6} className="d-flex mt-1 justify-content-md-end gap-3">
              <Button
                disabled={loading}
                type="primary"
                size="large"
                className="mr-4 publishBtn"
                onClick={() => publishPortFolio()}
              >
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                    variant="primary"
                  />
                ) : (
                  "Publish"
                )}
              </Button>
              <Button
                onClick={() => navigate("/Portfolio")}
                size="large"
                className="viewPortfolioBtn"
              >
                View Portfolio
              </Button>
            </Col>
          </Row>
          {/* {-----------PORTFOLIO HEADER SECTION ENDS-------------------} */}

          {/* {--------------TABS SECTION START FORM HERE}--------------------- */}
          <Row className="p-3 px-md-3 p-relative">
            <PortfolioTabs items={items} />
          </Row>
        </div>
      </div>
      {/* {--------------TABS SECTION Ends HERE}--------------------- */}
    </>
  );
};

export default Portfolio;
