import moment from "moment";
import * as React from "react";
import { useEffect, useState, useRef, useMemo } from "react";
import { FaAngleDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  getTransactionLogsHandle
} from "../../redux/actions/creditCardActions";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { MdOutlineCancel } from "react-icons/md";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { Popconfirm } from "antd";
import axios from "axios";
import { cancelBooking, repayTransaction } from "../../api/companyApis/transactionLogsApi";
import { displaySuccessToast } from "../../helper/toast_notification_function";

export default function TransactionLogs() {
  const { TransactionLogs, error } = useSelector(
    (state) => state.getTransactionLogsReducer
  );

  const dispatch = useDispatch();

  const [openIndex, setOpenIndex] = useState(null);

  const handledrop = (index) => {
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  const [amount, setamount] = useState("")
  const [stripePromise, setStripePromise] = useState(loadStripe("pk_test_51O0UOwKOIhqLs8SjhVyKyi8j5EMtmWSCF0apnIKv14O9ITHUyrHpyfCTEf2uHRGcaxsjpzeREMQ1S7YVztpvNy9U00G89xgS9o"));
  const [clientSecret, setClientSecret] = useState("");
  const [show, setShow] = useState(false);
  const [orderid, setorderid] = useState();
  const handleClose = () => {
    setShow(false)
  };
  const handleShow = (id, amnt) => {
    setamount(amnt)
    setorderid(id)
    repayTransaction(id).then((res) => {
      setClientSecret(res?.data?.data?.[0]?.client_secret)
      setShow(true)
    })
  };

  const handleCancelBooking = async (id) => {
    cancelBooking(id).then((res) => {
      displaySuccessToast(res?.data?.message[0])
      getTransactionLogsHandle(dispatch);
    })
  }


  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);


  const [rowData, setRowData] = useState([]);

  const [columnDefs, setcolumnDefs] = useState([
    {
      headerName: '#',
      width: 70,
      valueFormatter: (params) => {
        return `${parseInt(params.node.id) + 1}`;
      },
    },
    {
      headerName: "Amount",
      valueGetter: p => {
        return p.data.amount
      },
      minWidth: 100,
      maxWidth: 120,
      menuTabs: ['filterMenuTab', 'columnsMenuTab'],
    },
    {
      field: "status",
      menuTabs: ['filterMenuTab', 'columnsMenuTab'],
      width: 130,
      maxWidth: 200,
    },
    {
      headerName: "Payment Date",
      field: "created_at",
      menuTabs: ['filterMenuTab', 'columnsMenuTab'],
      width: 150,
      maxWidth: 200,
      cellRenderer: p => {
        return (
          <div>{moment.utc(p.data?.created_at).format("MM/DD/YYYY")}</div>
        )
      }
    },
    {
      field: "transaction_type",
      menuTabs: ['filterMenuTab', 'columnsMenuTab'],
      maxWidth: 200,
      minWidth: 150,
    },

    {
      headerName: "Bookings",
      wrapText: true,
      autoHeight: true,
      minWidth: 240,
      flex: 1,
      menuTabs: ['filterMenuTab', 'columnsMenuTab'],
      cellRenderer: p => {
        return (
          <div>
            {
              p.data?.company_active_schedule !== null &&
              <div className="schedules">
                <h6>Active Schedule</h6>
                <p>{p.data?.company_active_schedule?.number_of_days} days</p>
                <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", gap: "5px" }}>
                  <p>{`${moment.utc(p.data?.company_active_schedule?.start_date).format("MM/DD/YYYY")}`}</p> -
                  <p>{`${moment.utc(p.data?.company_active_schedule?.end_date).format("MM/DD/YYYY")}`}</p>
                </div>
              </div>
            }
            {
              p.data?.company_featured_listing !== null &&
              <div className="schedules">
                <h6>Featured Listing</h6>
                <p>{p.data?.company_featured_listing?.number_of_days} days</p>
                <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", gap: "5px" }}>
                  <p>{`${moment.utc(p.data?.company_featured_listing?.start_date).format("MM/DD/YYYY")}`}</p> -
                  <p>{`${moment.utc(p.data?.company_featured_listing?.end_date).format("MM/DD/YYYY")}`}</p>
                </div>
              </div>
            }
            {
              p.data?.company_download_activity !== null &&
              <div className="schedules">
                <h6>Download Activity</h6>
                <p>{p.data?.company_download_activity?.number_of_days} days</p>
                <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", gap: "5px" }}>
                  <p>{`${moment.utc(p.data?.company_download_activity?.start_date).format("MM/DD/YYYY")}`}</p> -
                  <p>{`${moment.utc(p.data?.company_download_activity?.end_date).format("MM/DD/YYYY")}`}</p>
                </div>
              </div>
            }
          </div>
        )
      }
    },
    {
      headerName: "Action",
      autoHeight: true,
      cellRenderer: p => {
        if (p.data.status == "requires_payment_method") {
          return (
            <div className="actions_pay">
              <button title="Repay" className="repaybtn" onClick={() => { handleShow(p.data.order_id, p.data.amount) }}>
                <FaMoneyCheckDollar size={18} color="#fff" />
              </button>
              <Popconfirm
                title="Are you sure to cancel this booking?"
                onConfirm={() => { handleCancelBooking(p.data.order_id) }}
                okText="Yes"
                cancelText="No"
              >
                <button title="Cancel" className="cancelbtn">
                  <MdOutlineCancel size={18} color="#fff" />
                </button>
              </Popconfirm>
            </div>
          )
        }
      }
    },

  ]);

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
      filter: true,
      menuTabs: true,
    };
  }, []);

  useEffect(() => {
    getTransactionLogsHandle(dispatch);
  }, []);

  useEffect(() => {
    setRowData(TransactionLogs.reverse())
  }, [TransactionLogs])

  console.log(TransactionLogs)
  return (
    <div id="paymentsHistory">
      <div className="topSectionImgWrapper">
        <h1 className="text-white fw-bold">Payment History</h1>
        <p className="text-center text-white col-md-10 col-12 descpay">
          Thank you for being a part of the InvestorPresentations.com community!
          We appriciate your business, and below is a history of your billing
          transactions. Please reach out to our team with any concerns, or visit
          our FAQ, Terms & Conditions, or our Refund Policy for immediate
          answers to basic information.
        </p>
      </div>


      <div className="bgDropBlur mx-md-4 mx-2">
        <div className="PaymentsPage_wrapper">
          <div className="ag-theme-alpine-dark" style={{ height: 600, width: "100%" }}>
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              pagination={true}
              paginationPageSize={10}
              animateRows={true}
            />
          </div>
          {/* <div className="blue_right_Border trans">
            <div className="transactionlogs">
              <div className="head_box">
                <div></div>
                <div>Amount</div>
                <div>Card Number</div>
                <div>Card Title</div>
                <div>Payment Date</div>
                <div>Description</div>
                <div>Status</div>
                <div>Transaction Type</div>
              </div>
              {TransactionLogs?.length !== 0 ?
                (
                  TransactionLogs?.map((data, key) => {
                    return (
                      <div className="trans_row" key={key}>
                        <div className="body_box">
                          <div className={`expandbtn ${openIndex === key ? "open" : ""}`} onClick={() => { handledrop(key) }}><FaAngleDown fontSize={"14px"} /></div>
                          <div>{data.amount}</div>
                          <div>{data.card_number}</div>
                          <div>{data.card_title}</div>
                          <div>{moment.utc(data.created_at).format("MM-DD-YYYY")}</div>
                          <div title={data.description}>{data.description}</div>
                          <div>{data.status}</div>
                          <div>{data.transaction_type}</div>
                        </div>
                        {
                          (data.company_active_schedule != null || data.company_featured_listing != null || data.company_download_activity != null) &&
                          <div className={`booking_dates ${openIndex === key ? "open" : ""}`}>
                            <table style={{ width: "100%" }}>
                              <tr>
                                <th style={{ textAlign: "left" }}>Payment Type</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Number Of Days</th>
                              </tr>
                              {
                                data?.company_active_schedule != null &&
                                <tr>
                                  <td style={{ textAlign: "left" }}>Active Schedule</td>
                                  <td>{moment.utc(
                                    data.company_active_schedule.start_date
                                  ).format("MM-DD-YYYY")}</td>
                                  <td>{moment.utc(
                                    data.company_active_schedule.end_date
                                  ).format("MM-DD-YYYY")}</td>
                                  <td>{data.company_active_schedule.number_of_days}</td>
                                </tr>
                              }
                              {
                                data?.company_featured_listing != null &&
                                <tr>
                                  <td style={{ textAlign: "left" }}>Featured Listing</td>
                                  <td>{moment.utc(
                                    data.company_featured_listing.start_date
                                  ).format("MM-DD-YYYY")}</td>
                                  <td>{moment.utc(
                                    data.company_featured_listing.end_date
                                  ).format("MM-DD-YYYY")}</td>
                                  <td>{data.company_featured_listing.number_of_days}</td>
                                </tr>
                              }
                              {
                                data?.company_download_activity != null &&
                                <tr>
                                  <td style={{ textAlign: "left" }}>Download Leads</td>
                                  <td>{moment.utc(
                                    data.company_download_activity.start_date
                                  ).format("MM-DD-YYYY")}</td>
                                  <td>{moment.utc(
                                    data.company_download_activity.end_date
                                  ).format("MM-DD-YYYY")}</td>
                                  <td>{data.company_download_activity.number_of_days}</td>
                                </tr>
                              }
                            </table>
                          </div>
                        }
                      </div>)
                  })
                )
                : error ? (
                  <div>{error}</div>
                )
                  : (
                    <div>No data found...</div>
                  )}
            </div>
          </div> */}
        </div>
      </div>

      <div>
        {clientSecret && stripePromise && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm show={show} handleClose={handleClose} amount={amount} bookingId={orderid} modalvaluestate={setamount} />
          </Elements>
        )}
      </div>

    </div >
  );
}
