import React from "react";
import UserVideoViewsTable from "../../component/data Table/UserVideoViewsTable";

const UserDetailVideoViewsTable = ({ id }) => {
  return (
    <div style={{
      backgroundColor: "#171717",
      padding: "15px 15px",
      borderRadius: "6px"
    }}>
      <h5 className="text-white">Video Views Table</h5>
      <UserVideoViewsTable id={id} />
    </div>
  );
};

export default UserDetailVideoViewsTable;
