import { Avatar, Rate, Space, Typography } from "antd";
import React from "react";
import { useState } from "react";
import Slider from "react-slick";
import { AntDesignOutlined } from '@ant-design/icons';
import img from "../../../images/paymentBg.jpg"
import { RiDoubleQuotesR } from "react-icons/ri";

const ReviewPortfolio = ({ reviews }) => {
  const { Title } = Typography;
  const [value, setValue] = useState(3);
  const desc = ["terrible", "bad", "normal", "good", "wonderful"];

  const settings = {
    dots: true,
    infinite: reviews?.length > 3 ? true : false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [

      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        },
      },
      {
        breakpoint: 551,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="reviews">

      {reviews?.length > 0 ?
        <Slider {...settings}>
          {reviews?.map((data, index) => {
            return (
              <div className="review-box">
                <div className="ath-box">
                  <h4>{data.post}, {data.company_name}</h4>
                  <span>
                    <RiDoubleQuotesR size={40} color="#00b8ff" />
                  </span>
                </div>

                <p className="review-text mt-2">{data.review_text}</p>


                <div className="reviewer-info">
                  <Avatar
                    size={50}
                    src={img}
                    icon={<AntDesignOutlined />}
                  />
                  <div className="ath-info">
                    <p className="">{data.name}</p>
                    <Rate tooltips={desc} onChange={setValue} value={value} />
                    {/* {value ? (
                    <span className="ant-rate-text">{desc[value - 1]}</span>
                  ) : (
                    ""
                  )} */}
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
        :
        <h6 className="text-white text-center">No Data Found!</h6>
      }
    </div>
  );
};

export default ReviewPortfolio;
