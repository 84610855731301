import { Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Form, FormControl, FormGroup, FormLabel, Row, Spinner } from "react-bootstrap";
import {
  deleteTeamMemberApi,
  getTeamMemberApi,
} from "../../../../api/companyApis/portfolio/team/teamApi";
import { deleteGalleryByIdApi, getGalleryApi } from "../../../../api/companyApis/portfolio/galleryAndMedia/galleryAndMedia";
import AddInvestorPerks from "./AddInvestorPerks";
import { getInvestorPerks, investorPerksDeleteApi, investorPerksUpdateApi } from "../../../../api/companyApis/portfolio/investorPerksApi";

const Table = ({ flag }) => {
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [single, setSingle] = useState(null);

  const handleToggle = () => {
    setVisible((prev) => !prev);
  };


  const onDelete = (id) => {
    investorPerksDeleteApi(id).then((res) => {
      getInvestorPerks()
          .then((res) => {
            setData(res.data.data);
          })
          .catch((error) =>
            console.log("Portfolio gallery media", error.response)
          );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onEdit = (id) => {
    console.log(id)
    if (id) {
      // investorPerksUpdateApi(id)
      getInvestorPerks(id)
        .then((res) => {
          setSingle(res.data?.data[0]);
          console.log(res.data)
          console.log(res.data)
          setVisible(true);
        })
        .catch((error) => console.log("Portfolio investor perks", error.response));
    }
  };

  console.log(single)

  useEffect(() => {
    setLoading(true);
    console.log("check")
    
    getInvestorPerks()
      .then((res) => {
        setLoading(false);
        setData(res.data.data);
        console.log(res.data)
      })
      .catch((error) => console.log("Portfolio team member", error.response));
  }, [flag ,trigger]);

  if (isLoading) {
    return (
      <>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: 400, width: "100%" }}
        >
          <div className="container">
            <div className="d-flex align-items-center justify-content-center">
              <Spinner animation="border" size="lg" variant="primary" />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div>
      {/* <div className="d-flex justify-content-between align-items-center my-3 ">
        <h3>Views With Profile</h3>
        <div>
          <Button variant="outline-primary">
            <CSVLink style={{ color: "inherit" }} data={data} headers={headers}>
              Download
            </CSVLink>
          </Button>
        </div>
      </div> */}

      <AddInvestorPerks
        isOpen={visible}
        handleToggle={handleToggle}
        toggleState={() => setTrigger((prev) => !prev)}
        data={single}
        state={"update"}
      />
      {/* <Paper>
        <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={data}
            columns={columns}
            getRowId={(row) => row.id}
            rowsPerPageOptions={[10, 20, 30]}
            checkboxSelection
            disableSelectionOnClick
            rowHeight={80}
            // experimentalFeatures={{ newEditingApi: true }}
          />
        </Box>
      </Paper> */}
      {
        data?.map((data) => {
          console.log(data)
          return (
            <div className="companyGallery">
              <Row>
                <Col xl={10} lg={10} md={10} sm={12} xs={12}>
                <Form >
                <FormGroup className="mb-2">
                <FormLabel htmlFor="title" >Title</FormLabel>
                <FormControl
              disabled
                  type="text"
                  name="heading"
                  id="title"
                  defaultValue={data?.heading}
                  maxLength={100}
                  onChange={(e) => {
                    // handleChange(e);
                    // handleErrorChange(e);
                  }}
                />
                {/* {formError.presentationTitle && (
                    <p>{formError.presentationTitle}</p>
                  )} */}
              </FormGroup>
              <Form.Group className="mb-3" controlId="description">
                <Form.Label >Description</Form.Label>
              <Form.Control
              disabled
                name="text"
                as="textarea"
                className="form-control textArea"
                rows={5}
                defaultValue={data?.text}
                // onChange={(e) => {
                //   handleChange(e);
                // }}
              />
            </Form.Group>
           
          </Form>
                </Col>
              
               


                <Col xl={2} lg={2} md={2} sm={12} xs={12} className="d-flex justify-content-center align-items-center flex-column">
                  <button className="btntm" onClick={() => onEdit(data.id)}>
                    <img src="/images/edt.svg" />
                  </button>
                  <Popconfirm
                    title="Are you sure to delete this Member?"
                    onConfirm={() => onDelete(data.id)}
                  >
                    <button className="btntm">
                      <img src="/images/dlt.svg" />
                    </button>
                  </Popconfirm>
                </Col>
              </Row>
            </div>
          )
        })
      }
    </div>
  );
};

export default Table;
