import { logoutCompanyType } from "../../constants/Common_constants";
import { GETACTIVESCHEDULEDATA, GETDOWNLOADACTIVITY, GETFEATUREDLISTING, SAVE_Schedule_DATA, START_GETTING_SCHEDULE_DATA } from "../../constants/schedule_Constants";

const INITIAL_STATE = {
    ActiveSchedule: [],
    FeactureSchedule:[],
    DownloadSchedule:[],
    loadingSchedule: false,
};

export const   ScheduleReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case START_GETTING_SCHEDULE_DATA:
      return {
        ...state,
        loadingSchedule: true,
      };
      case GETACTIVESCHEDULEDATA:
        return {
          ...state,
          ActiveSchedule: payload,
        };
        case GETFEATUREDLISTING:
        return {
            ...state,
              FeactureSchedule: payload,
        };
        case GETDOWNLOADACTIVITY:
        return {
                ...state,
            DownloadSchedule: payload,
        };
        case SAVE_Schedule_DATA:
            return {
                    ...state,
                    loadingSchedule: false,
            };

    case logoutCompanyType:
      return {
        ...state,
        ProgressVideoUpload: null,
      };
    default:
      return state;
  }
};
