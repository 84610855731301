import { apiHandle } from "./apiHandler";

export const updateSocialsUrlApi = (body) => {
  return apiHandle().post(`/company/auth/social/url`, body);
};

export const updateSocialsTextApi = (body) => {
  return apiHandle().post(`/company/auth/social/text`, body);
};

export const getSocialsApi = () => {
  return apiHandle().get(`/company/auth/social`);
};
