import React from "react";
import AboutSlider from "../component/AboutSection/AboutSlider";
import ChooseUs from "../component/AboutSection/ChooseUs";
import Header from "../component/Common/Header";
import HowWork_about from "../component/AboutSection/HowWork_about";
import PostingAds from "../component/AboutSection/PostingAds";
import TeamSec from "../component/AboutSection/TeamSec";
import Count from "../component/Common/Counter";
import AboutBanner from "../component/AboutSection/AboutBanner";
import logo from "../images/IpLogo.png";
import abtimg1 from "../images/about1.jpg";
import abtimg2 from "../images/about2.jpg";
import abtimg3 from "../images/about3.jpg";
import { Link } from "react-router-dom";
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";
import { useEffect } from "react";
const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <div className="aboutUsBg">
      <AboutBanner />
      <div className="abtmain">

        {/* <p className="text-center c-fff">
          
        </p> */}
        <h2 className="text-center c-fff">How it works</h2>
        <p className="text-center c-fff">
          InvestorPresentations.com is a digital platform for watching video presentations, on Alternative Investment opportunities! Watch this brief video, to learn more about How It Works.
        </p>
        <video
          className="leadgenVideoCard"
          poster={logo}
          src={
            `${process.env.REACT_APP_HOME_URL}investorpresentations-about-us-video.m4v`
          }
          controls
          style={{ maxWidth: "840px", maxHeight: "480px", margin: "20px auto 40px", display: "flex" }}
          preload="auto"
        ></video>
        <h2 className="text-center c-fff">No Sales, No Pressure, No Travel</h2>
        <p className="text-center c-fff">Visit each companies’ digital booth, watch video presentations, download white papers, learn about the executive team, and see if you would like to connect with the company to learn how to invest!</p>
        <div className="gallry_text">
          <img className="gallry_img border" src={abtimg1} />
          <div className="glry_txt">
            <h2 className="glry_h2 c-fff">Easy Sign-Up, No Bank Account Needed!</h2>
            <p className="glry_p c-fff">
              Accredited and Non-Accredited Investors are encouraged to sign-up and enjoy learning about what is available in the marketplace.
            </p>
          </div>
        </div>
        <div className="gallry_text">
          <div className="glry_txt order-2 order-lg-1">
            <h2 className="glry_h2 c-fff">No Travel, No Hotel, Learn At Your Pace!</h2>
            <p className="glry_p c-fff">
              The days of a live event, full of strangers sitting shoulder-to-shoulder at a hotel are in the past. Let the freedom of the internet provide you with all the information you need, without the strange beds, bad food, overlapping presentation schedules, and missed time with your loved ones. Browse the digital booth spaces at your convenience
            </p>
          </div>
          <img className="gallry_img order-1 order-lg-2  border" src={abtimg2} />
        </div>
        <div className="gallry_text ">
          <img className="gallry_img border" src={abtimg3} />
          <div className="glry_txt">
            <h2 className="glry_h2 c-fff">Take Your Time. This is the place for no High-Pressure Sales Tactics</h2>
            <p className="glry_p c-fff">
              Watch video presentations of investment opportunities, without the high-pressure sales tactics of a slick Business Development person standing in front of you. Start and stop presentations at your convenience, and reach out to the companies on your terms. Sign-Up today!
            </p>
          </div>
        </div>
        <Link to="/signup" className="signup_btn">Sign Up Now</Link>
        <div className="social_media_links">
          <Link to="" className="social_btn"><FaFacebookF size={18} color="#fff" /></Link>
          <Link to="" className="social_btn"><FaTwitter size={18} color="#fff" /></Link>
          <Link to="" className="social_btn"><FaInstagram size={18} color="#fff" /></Link>
        </div>
      </div>
    </div>
  );
};

export default About;
