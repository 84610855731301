import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Public_contact_action } from "../../redux/actions/PublicContactAction";

const Form = () => {
  const dispatch = useDispatch();
  const { isLoading, getAllIndustries, error } = useSelector(
    (state) => state.getAllIndustriesReducer
  );
  const [processLoading, setProcessLoading] = useState(false);
  const [formError, setError] = useState();
  const [FormData, SetFormData] = useState({
    name: "",
    email: "",
    // contact_number: "",
    purpose: "",
    message: "",
  });
  const FormHandler = (e) => {
    const { value, name } = e.target;
    SetFormData({ ...FormData, [name]: value });
  };
  const SubmitData = (e) => {
    e.preventDefault();
    setProcessLoading(true);
    Public_contact_action(FormData, SetFormData, setProcessLoading, setError);
  };

  if (formError) {
    setTimeout(() => {
      setError("");
    }, 3000);
  }

  return (
    <div className="py-5 px-3 ">
      <div className="row justify-content-center align-items-center">

        {/* <div className="col-lg-5 col-md-12 "> */}
        {/* <div className="mb-5 mb-lg-0">
            <div className="support-service br-7 mb-4 mb-xl-0">
              <p>
                <i className="fa fa-phone"></i>
              </p>
              <div className="mx-2">
                <h6 className="text-black">+68 872-627-9735</h6>
                <p className="text-black">Free Support!</p>
              </div>
            </div>
          </div> */}
        {/* <div className=" mb-5 mb-lg-0">
            <div className="support-service align-items-center br-7 mb-4 mb-xl-0">
              <p>
                <i className="fa-solid fa-clock"></i>
              </p>
              <div className="mx-2">
                <h6 className="text-black">Mon-Sat(10:00-19:00)</h6>
                <p className="text-black">Working Hours!</p>
              </div>
            </div>
          </div> */}
        <div>
          {/* <div className="support-service align-items-center br-7 bg-white">
              <p>
                <i className="fa fa-envelope text-white"></i>
              </p>
              <div className="mx-2">
                <h6 className="text-black">yourdomain@gmail.com</h6>
                <p className="text-black">Support us!</p>
              </div>
            </div> */}
        </div>
        {/* </div> */}

        <div className="col-lg-5 col-mdl-12 mx-auto d-block mt-5">
          <div className="wrapper bg-white rounded mt-5">
            <h2 className="fw-bold">Get in <span style={{ color: "#073CB1" }}>touch</span></h2>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
            <div className="card-body">
              <form onSubmit={SubmitData} className="cntct_form">
                <div className="form-group">
                  <input
                    type="text"
                    onChange={FormHandler}
                    value={FormData.name}
                    name="name"
                    className="form-control mt-2 px-2 py-3"
                    id="name"
                    placeholder="Your Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    onChange={FormHandler}
                    value={FormData.email}
                    name="email"
                    className="form-control mt-2 px-2 py-3"
                    id="name1"
                    placeholder="Your Email"
                    required
                  />
                </div>

                {/* <div className="form-group">
                  <input
                    type="tel"
                    onChange={FormHandler}
                    value={FormData.contact_number}
                    name="contact_number"
                    className="form-control mt-2 px-2 py-3"
                    id="email"
                    placeholder="Contact no"
                    required
                  />
                </div> */}
                <div className="form-group mt-2">
                  <select
                    onChange={FormHandler}
                    value={FormData.industry_id}
                    id="purpose"
                    className="form-control px-2 py-3"
                    name="purpose"
                    required
                  >
                    <option value=""> Select Purpose</option>
                    <option value="Billing">Billing</option>
                    <option value="Technical">Technical</option>
                    <option value="Help">Help</option>
                    <option value="Other">Other</option>
                    {/* {getAllIndustries.length !== 0
                      ? getAllIndustries.map((val, ind) => {
                        return (
                          <>
                            <option key={ind} value={val?.id}>
                              {val?.title}
                            </option>
                          </>
                        );
                      })
                      : null} */}
                  </select>
                </div>

                <div className="form-group">
                  <textarea
                    onChange={FormHandler}
                    value={FormData.message}
                    className="form-control mt-2 p-2"
                    name="message"
                    rows="6"
                    placeholder="Message"
                    required
                  ></textarea>
                </div>

                <p
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    textAlign: "left",
                    marginTop: "10px",
                  }}
                >
                  {formError ? formError : ""}
                </p>
                <button
                  type="submit"
                  className="form_btn d-block mx-auto py-2 px-3 mt-3 gradientBtn"
                  disabled={processLoading ? true : false}
                >
                  {!processLoading ? (
                    "Send Message"
                  ) : (
                    <>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}
                      Processing...
                    </>
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
