import React from "react";
import { Button } from "antd";
import { Modal } from "react-bootstrap";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../helper/toast_notification_function";
import { useEffect } from "react";
import {
  updateSocialsUrlApi,
  getSocialsApi,
  updateSocialsTextApi,
} from "../../api/SocialsApis";
import { BiEdit } from "react-icons/bi";
import { TextEditor } from "../../component/TextEditor";

const Socials = () => {
  const [userState, setUserState] = React.useState({
    website_url: "www.text.com",
    facebook_url: "https://www.facebook.com/facebook",
    twitter_url: "https://twitter.com/Twitter",
    linkedin_url: "https://www.linkedin.com/company/linkedin",
    instagram_url: "https://www.instagram.com/instagram/updated",
    text: "",
  });

  const [formData, setformData] = React.useState(null);
  const [text, setText] = React.useState("");

  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);
  const [isDisable, setIsDisable] = React.useState(false);

  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function handleChange(evt) {
    setIsButtonDisabled(false);
    const { name, value } = evt.target;
    setformData({
      ...formData,
      [name]: value,
    });
  }

  const handleGetSocials = () => {
    getSocialsApi()
      .then((res) => {
        if (res.data.data.length) {
          setUserState(res.data.data[0]);
          setText(res.data.data[0]?.text);
        }
        // setUserState()
      })
      .catch((error) => { });
  };

  const submit = () => {
    setIsDisable(true);
    updateSocialsTextApi({ text: text })
      .then((res) => {
        displaySuccessToast("Summery Text Updated successfully");
        setIsDisable(false);
        handleGetSocials();
      })
      .catch((error) => {
        displayErrorToast(error);
      });
  };

  const handleSubmint = (e) => {
    e.preventDefault();
    if (formData) {
      if (userState) {
        setIsButtonDisabled(true);
        updateSocialsUrlApi(formData)
          .then((response) => {
            displaySuccessToast(response.data.message[0]);
            handleGetSocials();
            handleClose();
            setIsButtonDisabled(false);
          })
          .catch((err) => {
            displayErrorToast(err);
            setIsButtonDisabled(false);
          });
      }
    }
  };


  useEffect(() => {
    handleGetSocials();
  }, []);

  return (
    <>
      <div className="head py-2 pt-3 px-3">
        <h3 className="text-white">Social Links</h3>
        <div>
          <Button
            className="profileUpdateBtn"
            variant="primary"
            style={{ fontSize: "small" }}
            onClick={() => handleShow()}
          >
            <BiEdit fontSize="17px" />
          </Button>
        </div>
      </div>
      {/*  ***************** // Body // ***************** */}
      <div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-5">
              <h6 className="mb-0 text-white"> Website Url </h6>
            </div>
            <div className="col-sm-7 text-secondary">
              {userState?.website_url}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-5">
              <h6 className="mb-0 text-white">Facebook Url</h6>
            </div>
            <div className="col-sm-7 text-secondary">
              {userState?.facebook_url}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-5">
              <h6 className="mb-0 text-white">Twitter Url</h6>
            </div>
            <div className="col-sm-7 text-secondary">
              {userState?.twitter_url}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-5">
              <h6 className="mb-0 text-white">Linkedin Url</h6>
            </div>
            <div className="col-sm-7 text-secondary">
              {userState?.linkedin_url}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-5">
              <h6 className="mb-0 text-white">Instagram Url</h6>
            </div>
            <div className="col-sm-7 text-secondary">
              {userState?.instagram_url}
            </div>
          </div>
        </div>
      </div>

      <div className="head pb-2 px-3">
        <h3 className="text-white">Brief Summary</h3>
      </div>
      <div className="px-3">
        <TextEditor
          onClick={submit}
          data={{ text }}
          setData={setText}
          disabled={isDisable}
        />
      </div>

      {/* Modal */}
      <Modal
        show={show}
        onHide={handleClose}
        scrollable={true}
        dialogClassName="my-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="py-md-3">
            {/* ================================= MODAL PRIMARY CONTACT==================================================  */}

            <div className="row">
              <div className="form-group ">
                <label for="FirstName" className="font-weight-bold">
                  Website Url
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="website_url"
                  defaultValue={
                    !userState?.website_url ? "" : userState?.website_url
                  }
                  id="FirstName"
                  onChange={handleChange}
                  placeholder="url here ...."
                />
              </div>

              <div className="form-group ">
                <label for="LastName" className="font-weight-bold">
                  Facebook Url
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="facebook_url"
                  onChange={handleChange}
                  defaultValue={
                    !userState.facebook_url ? "" : userState?.facebook_url
                  }
                  id="facebook_url"
                  placeholder="url here ...."
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="form-group ">
                <label
                  for="primary_contact_tax_identification_number"
                  className="font-weight-bold"
                >
                  Twitter Url
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="twitter_url"
                  defaultValue={
                    !userState?.twitter_url ? "" : userState?.twitter_url
                  }
                  id="twitter_url"
                  onChange={handleChange}
                  placeholder="url here ...."
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="form-group ">
                <label for="primary_contact_email" className="font-weight-bold">
                  Linkedin Url
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="linkedin_url"
                  defaultValue={
                    !userState?.linkedin_url ? "" : userState?.linkedin_url
                  }
                  id="linkedin_url"
                  onChange={handleChange}
                  placeholder="url here ...."
                />
              </div>
            </div>

            <div className="form-group mt-3 ">
              <label for="primary_contact_address" className="font-weight-bold">
                Instagram Url
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                name="instagram_url"
                onChange={handleChange}
                defaultValue={userState?.instagram_url}
                id="instagram_url"
                placeholder="url here ...."
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            disabled={isButtonDisabled}
            variant="primary"
            onClick={handleSubmint}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Socials;
