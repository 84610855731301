import React, { useState } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import { cardActiveSheduleApi } from "../../api/companyApis/CreditCardApis";
import { useEffect } from "react";

const { RangePicker } = DatePicker;

export const DateRange = ({ disablePastDate, dateRange, setDateRange, setDaysBetween, setFinalAmount, paymentPerDay, disabled }) => {

  // console.log(disablePastDate)

  // disable past dates
  const today = moment().subtract(0, 'day')
  const disablePastDt = current => {
    return current.isBefore(today);
  };

  // ======================= FUNCTION FOR MULTIPLE DATE RANGES DISABLED IN DATE RANGE PICKER =============================================

  // const disabledDatess = current => {
  //   // Define your disabled date ranges here
  //   const disabledRanges = [
  //     [moment('2023-07-10'), moment('2023-07-15')],
  //     [moment('2023-08-20'), moment('2023-08-25')],
  //     [moment('2023-08-2'), moment('2023-08-5')],


  //   ];

  //   // Check if the current date falls within any of the disabled ranges
  //   for (const [start, end] of disabledRanges) {
  //     if (current.isBetween(start, end, null, '[]')) {
  //       return true; // Disable the current date
  //     }
  //   }

  //   return false; // Enable the current date
  // };

  // ======================= FUNCTION END=============================================


  useEffect(() => {
    if (dateRange && dateRange.length > 1) {
      let startDate = dateRange[0];
      let endDate = dateRange[1];

      if (startDate && endDate) {
        if (!moment.isMoment(startDate)) startDate = moment(startDate);
        if (!moment.isMoment(endDate)) endDate = moment(endDate);
        let dif = endDate.diff(startDate, "days")


        setDaysBetween(dif + 1)
        setFinalAmount(paymentPerDay * dif)
      }
      //this will return to me the different between two dates with Weekend Days 
    }
  }, [dateRange])

  return (<>

    <RangePicker
      allowClear={true}
      autoFocus={true}
      direction="vertical"
      // size="large"
      picker="date"
      separator="||"
      disabled={disabled}
      // disablePastDt={}
      disabledDate={disablePastDate ? disablePastDt : false}
      value={dateRange && dateRange.map((item) => (item ? moment(item, 'MM-DD-YYYY') : null))}
      //  onChange={(val) => handleOnchange(val)}
      // onOk={onok}
      onChange={(values) => { setDateRange(values?.map(item => { return moment(item).format('MM-DD-YYYY') })) }}
    />


  </>
  );
};





