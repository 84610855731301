import { getCompanyHighlightsApi } from "../../api/companyApis/portfolio/highlight/companyhighlightApi";
import {
  ERROR_COMPANY_HIGHLIGHTS,
  SAVE_COMPANY_HIGHLIGHTS,
} from "../constants/highlights_constants";

export const getCompanyHighlightsHandle = (dispatch) => {
  getCompanyHighlightsApi()
    .then((res) => {
      if (res.data.data.length) {
        const item = res.data.data.map((elem) => {
          return {
            ...elem,
            disabled: false,
          };
        });
        dispatch({
          type: SAVE_COMPANY_HIGHLIGHTS,
          payload: item,
        });
      } else {
        dispatch({
          type: SAVE_COMPANY_HIGHLIGHTS,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        payload: error.response.data.message,
        type: ERROR_COMPANY_HIGHLIGHTS,
      });
    });
};
