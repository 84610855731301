import React, { useState } from "react";
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "react-bootstrap";
import {
  createTeamMemberApi,
  updateTeamMemberApi,
} from "../../../../api/companyApis/portfolio/team/teamApi";
import { dataWithFiles } from "../../../../helper/common_functions";
import {
  displayErrorToast,
  displaySuccessToast,
  displayWorrningToast,
} from "../../../../helper/toast_notification_function";
import { createGalleryApi, updateGalleryApi } from "../../../../api/companyApis/portfolio/galleryAndMedia/galleryAndMedia";

const AddGallery = ({ isOpen, handleToggle, toggleState, state, data }) => {
  const [formData, setFormData] = useState(null);
  const [btnLoader, setBtnLoader] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);

  const imageInputRef = React.useRef();

  const handleChange = (e) => {
    setIsDisabled(false);
    const { name, value } = e.target;
    if (e.target.files) {
      setFormData({ ...formData, [name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };


  const Submit = () => {
    setBtnLoader(true)
    if (!state) {
      setIsDisabled(true);
      createGalleryApi(dataWithFiles(formData))
        .then((res) => {
          imageInputRef.current.value = "";
          setIsDisabled(false);
          setFormData("");
          toggleState();
          handleToggle();
    setBtnLoader(false)

          displaySuccessToast("member successfully inserted");
        })
        .catch((error) => {
    setBtnLoader(false)

          setIsDisabled(false);
          if (error.response.status == 422) {
            displayWorrningToast(error.response.data.message[0]);
          } else {
            displayErrorToast(error);
          }
        });
    } else {
        updateGalleryApi(data?.id, dataWithFiles(formData))
        .then((res) => {
          imageInputRef.current.value = "";
          setIsDisabled(false);
          setBtnLoader(false)
          setFormData("");
          toggleState();
          handleToggle();
          displaySuccessToast("member successfully updated");
        })
        .catch((error) => {
          setIsDisabled(false);
          setBtnLoader(false)
          displayErrorToast(error);
        });
    }
  };

  return (
    <div>
      {/* Modal for Uploading Video Presentation  */}
      <Modal show={isOpen} onHide={handleToggle}>
        <ModalHeader closeButton>Update</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup className="mb-2">
              <FormLabel htmlFor="title">Title</FormLabel>
              <FormControl
                type="text"
                name="heading"
                id="title"
                defaultValue={data?.heading}
                maxLength={100}
                onChange={(e) => {
                  handleChange(e);
                  // handleErrorChange(e);
                }}
              />
              {/* {formError.presentationTitle && (
                  <p>{formError.presentationTitle}</p>
                )} */}
            </FormGroup>
            {/* <FormGroup className="mb-2">
              <FormLabel htmlFor="description">Description</FormLabel>
              <FormControl
                type="text"
                name="description"
                // defaultValue={data?.description}
                id="description"
                maxLength={100}
                onChange={(e) => {
                  handleChange(e);
                  handleErrorChange(e);
                }}
              />
             
            </FormGroup> */}


            <FormGroup className="mb-2">
              <FormLabel htmlFor="picture"><div>Picture (e.g jpg, jpeg)</div><span>(Aspect Ratio: 1/1 | 250*250px Min.)</span></FormLabel>

              <InputGroup className="mb-3">
                <FormControl
                  type="file"
                  name="image"
                  id="picture"
                  multiple={false}
                  accept="image/*"
                  ref={imageInputRef}
                  onChange={(e) => {
                    handleChange(e);
                    // handleErrorChange(e);
                  }}
                />
                {formData?.picture && (
                  <InputGroup.Text>
                    <span
                      onClick={() => {
                        imageInputRef.current.value = "";
                        setFormData({
                          ...formData,
                          picture: null,
                        });
                      }}
                    >
                      ✖
                    </span>
                  </InputGroup.Text>
                )}
              </InputGroup>
              {/* {formError.presentationThumbnail && (
                  <p
                    style={{
                      fontSize: 17,
                      color: "red",
                      margin: "4px 0 4px 0",
                    }}
                  >
                    {formError.presentationThumbnail}
                  </p>
                )} */}
            </FormGroup>

            <Form.Group className="mb-3" controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                name="text"
                as="textarea"
                rows={3}
                defaultValue={data?.text}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </Form.Group>
          </Form>
        </ModalBody>
        <Modal.Footer>
          <Button
            variant={isDisabled ? "secondary" : "primary"}
            disabled={isDisabled}
            onClick={Submit}
          >
            {btnLoader ? <Spinner animation="border" size="sm"/> :  !state ? "Upload" : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End Modal for Uploading Video Presentation  */}
    </div>
  );
};

export default AddGallery;
