import { Col, Row, Space, Switch, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  UpdateModuleStatusById,
  getModuleStatus,
} from "../../../../api/companyApis/portfolio/modulesActiveApi";
import { displayErrorToast } from "../../../../helper/toast_notification_function";

export const ManageModules = ({ moduleStatus }) => {

  const [mode, setMode] = useState();
  const [updatedModuleStatus, setUpdatedModuleStatus] = useState([]);

  const [loading, setLoading] = useState(true);

  const toggle = (value, id) => {
    setLoading(true);
    setMode(value);
    let obj = {
      is_active: value,
    };
    UpdateModuleStatusById(obj, id).then(() =>
      getModuleStatus()
        .then((val) => {
          setLoading(true);
          setUpdatedModuleStatus(val?.data?.data);
          setLoading(false);
        })
        .catch((err) => displayErrorToast(err))
    );
    setLoading(false);
  };

  useEffect(() => {
    getModuleStatus()
      .then((res) => {
        setLoading(true);
        if (res.data.success) {
          setUpdatedModuleStatus(res?.data?.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(true);

        displayErrorToast(err);
        setLoading(false);
      });
  }, []);


  return (
    <div className="bg_black">
      <div className="doc_slide">
        <h3>Manage Modules</h3>
        <p className="mb-3">
          If you do not wish to have a section for your digital booth, simply turn-off the module. You may turn it back on whenever you wish.
        </p>
      </div>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">

          <Spinner
            as="span"
            animation="border"
            size="lg"
            role="status"
            aria-hidden="true"
            variant="primary"
          />
        </div>
      ) : (

        <Space direction="vertical" size={"middle"}>
          <Row gutter={[20, 20]}>
            {updatedModuleStatus?.length !== 0
              ? updatedModuleStatus.map((val, ind) => {
                const { is_active, module, id } = val;

                let newStr = module.replace(/_/g, " ");
                return (
                  <Col span={10}>
                    <div className={`switchOff`}>
                      <Switch
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        defaultChecked={mode ? mode : is_active}
                        onChange={(e) => toggle(e, id)}
                        loading={loading}
                        style={{
                          background:
                            "linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5)",
                        }}
                      />
                      <Typography.Title
                        level={5}
                        style={{
                          fontWeight: "400",
                          fontFamily: 'sansation',
                          marginLeft: "5px",
                          color: "#fff",
                        }}
                      >
                        {newStr}
                      </Typography.Title>
                    </div>
                  </Col>
                );
              })
              : null}
          </Row>
        </Space>
      )}
    </div>
  );
};
