import React, { useState } from "react";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import {
  getRiskAndDisclosuresApi,
  riskAndDisclosuresApi,
} from "../../../../api/companyApis/portfolio/riskAndDiscApi";
import { TextEditor } from "../../../../component/TextEditor";
import {
  displayErrorToast,
  displaySuccessToast,
  displayWorrningToast,
} from "../../../../helper/toast_notification_function";

export default function RisksAndDisclosures() {
  const [data, setData] = useState("");
  const [riskDisclousersData, setRiskDisclousersData] = useState();
  const [loading, setLoading] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const submit = () => {
    //   send data var in api
    setBtnLoader(true)
    let obj = { text: data };

    if (data !== "" || riskDisclousersData) {
      setIsDisable(true);
      riskAndDisclosuresApi(obj)
        .then((res) => {
          if (res.data.success) {
            displaySuccessToast("Success");
    setBtnLoader(false)
    setIsDisable(false);
          }
        })
        .catch((err) => {
          displayErrorToast(err);
    setBtnLoader(false)

          setIsDisable(false);
        });
    } else {
      displayWorrningToast("Please enter the data..!");
    }
  };

  const getData = () => {
    setLoading(true);

    getRiskAndDisclosuresApi()
      .then((res) => {
        if (res?.data?.success) {
          setRiskDisclousersData(res?.data?.data[0]);
          setData(res?.data?.data[0]);
          setLoading(false);
        }
      })
      .catch((Err) => {
        setLoading(false);

        displayErrorToast(Err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner animation="grow" variant="primary" />
      </div>
    );
  }
  return (
    <div className="bg_black">
        <div className="doc_slide">
          <h3>Risk & Disclosures</h3>
        </div>
      <TextEditor
        onClick={submit}
        data={riskDisclousersData}
        setData={setData}
        loader={btnLoader}
      />
    </div>
  );
}
