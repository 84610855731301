import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Link } from "react-router-dom";
import { RemainingLiveDaysApi, getDeshboardApi } from "../../../api/companyApis/analyticsApi";
import CreditCard from "../../../component/Common/CreditCard";
import AllVideoViewsChart from "../../../component/charts/AllVideoViewsChart";
import IntrestedUserChart from "../../../component/charts/IntrestedUserChart";
import MapChart from "../../../component/charts/MapChart";
import MediaViewsChart from "../../../component/charts/MediaViewsChart";
import ViewsChart from "../../../component/charts/ViewsChart";
import CoundCard from "../../../component/dashboard/CoundCard";
import CountdownTimer from "../../../component/dashboard/CountDownTimer";
import moment from "moment";

const Analictics = () => {
  const [data, setData] = useState(null);
  const [isloading, setLoading] = useState(false);
  const [dispdate, setdispdate] = useState(false);
  const [stdate, setstdate] = useState(new Date());
  const [enddate, setenddate] = useState(new Date());
  const [cldrdt, setcldrdt] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  useEffect(() => {
    RemainingLiveDaysApi()
      .then((res) => {
        if (res.data.data[0]) {
          setdispdate(true)
        }
        setstdate(new Date(res.data.data[0].start_date))
        setenddate(new Date(res.data.data[0].end_date))
        setcldrdt([
          {
            startDate: moment(res.data.data[0].start_date, "YYYY-MM-DD").toDate(),
            endDate: moment(res.data.data[0].end_date, "YYYY-MM-DD").toDate(),
            key: 'selection'
          }
        ])
      })
      .catch((error) => console.log(error));

    getDeshboardApi()
      .then((res) => {
        setData(res.data.data[0]);
        localStorage.setItem("IP_Company_isActive", JSON.stringify(res?.data?.data?.[0]?.is_active)
        );

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const countData = [
    {
      color: "#f2b959",
      value: data?.profile_view_count,
      title: "Profile Views",
      imgurl: "/images/prfview.svg"
    },
    {
      color: "#a9dfd8",
      value: data?.presentation_view_count,
      title: "Presentations Views",
      imgurl: "/images/prstview.svg"
    },
    {
      color: "#58aff3",
      value: data?.interested_users_count,
      title: "Interested User",
      imgurl: "/images/intuser.svg"
    },
    {
      color: "#ed909c",
      value: data?.active_booked_days_count,
      title: "Remaining live days",
      imgurl: "/images/rld.svg"
    },
    {
      color: "#84fcf2",
      value: data?.active_days_count,
      title: "Active Days",
      imgurl: "/images/actdays.svg"
    },
  ];

  return (
    <>
      <Row className='my-1 mt-3'>
        {/* <h2 style={{fontFamily:'sansation',fontWeight:"600",color:"white"}}>Company Stats</h2> */}
      </Row>
      <Row>
        {countData.map((val, index) => {
          return (
            <Col className="mt-3 mb-3" key={index}>
              <CoundCard
                isloading={isloading}
                color={val?.color}
                key={index}
                data={val.value}
                title={val.title}
                imgurl={val.imgurl}
              />
            </Col>
          );
        })}
      </Row>
      <>
        <Row>
          <Col xl={8}>
            <Row>
              <Col className="mt-3" lg={6} md={12} sm={12} xs={12}>
                <MediaViewsChart />
              </Col>
              <Col className="mt-3" lg={6} md={12} sm={12} xs={12}>
                <AllVideoViewsChart />
              </Col>
              <Col className="mt-3" lg={6} md={12} sm={12} xs={12}>
                <IntrestedUserChart />
              </Col>
              <Col className="mt-3" lg={6} md={12} sm={12} xs={12}>
                <ViewsChart />
              </Col>
              <Col>
                <MapChart />
              </Col>
              <Col className="mt-3" lg={12} md={12} sm={12} xs={12}>
              <div className="linkbx" style={{ backgroundColor: "#171717", borderRadius: "20px", borderStyle: "solid", borderWidth: "1px", borderColor: "black gray gray black" }}>
                <Row>
                  <Col xl={4} lg={6} md={6} sm={12} className="mt-4">
                    <Link to="/company/presentation_videos" style={{ minHeight: "140px", minWidth: "140px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px", color: "#fff", borderRadius: "20px", background: "linear-gradient(0deg, #151823, #151823), rgba(255, 255, 255, 0.1)", padding: "15px", width: "100%", height: "100%", borderStyle: "solid", borderWidth: "1px", borderColor: "black gray gray black", textAlign: "center" }}>
                      <img src="/images/vidlib.svg" alt="img1" />
                      <p style={{ color: "#fff", fontSize: "14px", fontWeight: "700", marginBottom: "0px" }}>Video Library</p>
                    </Link>
                  </Col>
                  <Col xl={4} lg={6} md={6} sm={12} className="mt-4">
                    <Link to="/company/user_tables" style={{ minHeight: "140px", minWidth: "140px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px", color: "#fff", borderRadius: "20px", background: "linear-gradient(0deg, #151823, #151823), rgba(255, 255, 255, 0.1)", padding: "15px", width: "100%", height: "100%", borderStyle: "solid", borderWidth: "1px", borderColor: "black gray gray black", textAlign: "center" }}>
                      <img src="/images/shop.svg" alt="img2" />
                      <p style={{ color: "#fff", fontSize: "14px", fontWeight: "700", marginBottom: "0px" }}>Investor Leads</p>
                    </Link>
                  </Col>
                  <Col xl={4} lg={6} md={6} sm={12} className="mt-4">
                    <Link to="/Portfolio" style={{ minHeight: "140px", minWidth: "140px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px", color: "#fff", borderRadius: "20px", background: "linear-gradient(0deg, #151823, #151823), rgba(255, 255, 255, 0.1)", padding: "15px", width: "100%", height: "100%", borderStyle: "solid", borderWidth: "1px", borderColor: "black gray gray black", textAlign: "center" }}>
                      <img src="/images/portfolio.svg" alt="img3" />
                      <p style={{ color: "#fff", fontSize: "14px", fontWeight: "700", marginBottom: "0px" }}>View Portfolio</p>
                    </Link>
                  </Col>
                  <Col xl={4} lg={6} md={6} sm={12} className="mt-4">
                    <Link to="/company/Transactionlogs" style={{ minHeight: "140px", minWidth: "140px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px", color: "#fff", borderRadius: "20px", background: "linear-gradient(0deg, #151823, #151823), rgba(255, 255, 255, 0.1)", padding: "15px", width: "100%", height: "100%", borderStyle: "solid", borderWidth: "1px", borderColor: "black gray gray black", textAlign: "center" }}>
                      <img src="/images/payments.svg" alt="img5" />
                      <p style={{ color: "#fff", fontSize: "14px", fontWeight: "700", marginBottom: "0px" }}>Payment History</p>
                    </Link>
                  </Col>
                  <Col xl={4} lg={6} md={6} sm={12} className="mt-4">
                    <Link to="/company/payments" style={{ minHeight: "140px", minWidth: "140px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px", color: "#fff", borderRadius: "20px", background: "linear-gradient(0deg, #151823, #151823), rgba(255, 255, 255, 0.1)", padding: "15px", width: "100%", height: "100%", borderStyle: "solid", borderWidth: "1px", borderColor: "black gray gray black", textAlign: "center" }}>
                      <img src="/images/management.svg" alt="img4" />
                      <p style={{ color: "#fff", fontSize: "14px", fontWeight: "700", marginBottom: "0px" }}>Go Live</p>
                    </Link>
                  </Col>
                  <Col xl={4} lg={6} md={6} sm={12} className="mt-4">
                    <Link to="/company/portfolio" style={{ minHeight: "140px", minWidth: "140px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px", color: "#fff", borderRadius: "20px", background: "linear-gradient(0deg, #151823, #151823), rgba(255, 255, 255, 0.1)", padding: "15px", width: "100%", height: "100%", borderStyle: "solid", borderWidth: "1px", borderColor: "black gray gray black", textAlign: "center" }}>
                      <img src="/images/company.svg" alt="img6" />
                      <p style={{ color: "#fff", fontSize: "14px", fontWeight: "700", marginBottom: "0px" }}>Manage Portfolio</p>
                    </Link>
                  </Col>
                </Row>
              </div>
              </Col>

              <Col className="mt-3" lg={12} md={12} sm={12} xs={12}>
              <div style={{ background: "url('/images/buildbg.jpg'), #1c21cc", backgroundSize: "contain", backgroundPosition: "bottom", backgroundRepeat: "no-repeat", borderRadius: "20px", }}>
                <div className="digibooth" style={{ background: "linear-gradient(0deg, rgba(21, 23, 35, 0.4), rgba(21, 23, 35, 0.3)), rgba(255, 255, 255, 0.1)", borderRadius: "20px", marginTop: "20px", borderStyle: "solid", backdropFilter: "blur(5.78857px)", borderWidth: "1px", borderColor: "black gray gray black", color: "#fff" }}>
                  <h3 style={{ color: "#fff", fontWeight: "700" }}>Build/Edit Your Digital Booth!</h3>
                  <p style={{ color: "#fff", fontWeight: "400" }}>We understand having a great online presence to investors is important, but we also understand it can take time to build out all the proper information needed, to convey to the investor community. Don’t worry! You can sign-in to InvestorPresentations.com at your leisure, and take all the time you need to properly upload all the information you’d like to display. When you are comfortable with your Digital Booth, you have complete flexibility in then choosing the dates in which to be “Live” in our Digital Investor Expo. You have full control over your exposure, and the automatic clock helps to stay compliant, in case you forget to end your campaign. Set it and forget it! </p>
                  <a href="/company/portfolio" style={{ background: "linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5)", color: "#fff", padding: "14px 20px", borderRadius: "18px", display: "block", width: "fit-content" }}>Build/Edit Booth</a>
                </div>
              </div>
              </Col>
            </Row>
          </Col>
          <Col xl={4} className="dashboard_card">

            {/* <CreditCard /> */}
            <Row>
              <Col xl={12} lg={6} md={12} className=" mt-3">
                <div className="remaindays" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", borderRadius: "20px", backgroundColor: "#171717", color: "#fff", width: "100%", height: "100%", borderStyle: "solid", borderWidth: "1px", borderColor: "black gray gray black" }}>
                  <h3>Remaining Live Days</h3>
                  <CountdownTimer targetDate={enddate} />
                </div>
              </Col>
              <Col xl={12} lg={6} md={12} className=" mt-3">
                <div className={`datebx ${dispdate ? "dteblck" : ""}`} style={{ padding: "5px 0 0px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", }}>
                  <h2 style={{ fontSize: "20px", color: "#fff", marginBottom: "0px" }}>Current Dates Selected</h2>
                  <DateRange
                    editableDateInputs={false}
                    moveRangeOnFirstSelection={false}
                    onChange={() => { return false }}
                    ranges={cldrdt}
                  />
                </div>
              </Col>

              <Col sm={12} lg={12} className='mt-4'>
            <div className="chat_box">
              <img src="/images/chats.png" alt="imgg" />
              <div>
                <p>Coming Soon</p>
              </div>
            </div>
          </Col>
            </Row>
          </Col>
        </Row>




        {/* <Row>
            <Col sm={12} lg={8} className='mt-4'>
              <div className="linkbx" style={{ backgroundColor: "#171717", borderRadius: "20px", borderStyle: "solid", borderWidth: "1px", borderColor: "black gray gray black" }}>
                <Row>
                  <Col xl={4} lg={6} md={6} sm={12} className="mt-4">
                    <Link to="/company/presentation_videos" style={{ minHeight: "140px", minWidth: "140px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px", color: "#fff", borderRadius: "20px", background: "linear-gradient(0deg, #151823, #151823), rgba(255, 255, 255, 0.1)", padding: "15px", width: "100%", height: "100%", borderStyle: "solid", borderWidth: "1px", borderColor: "black gray gray black", textAlign: "center" }}>
                      <img src="/images/vidlib.svg" alt="img1" />
                      <p style={{ color: "#fff", fontSize: "14px", fontWeight: "700", marginBottom: "0px" }}>Video Library</p>
                    </Link>
                  </Col>
                  <Col xl={4} lg={6} md={6} sm={12} className="mt-4">
                    <Link to="/company/user_tables" style={{ minHeight: "140px", minWidth: "140px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px", color: "#fff", borderRadius: "20px", background: "linear-gradient(0deg, #151823, #151823), rgba(255, 255, 255, 0.1)", padding: "15px", width: "100%", height: "100%", borderStyle: "solid", borderWidth: "1px", borderColor: "black gray gray black", textAlign: "center" }}>
                      <img src="/images/shop.svg" alt="img2" />
                      <p style={{ color: "#fff", fontSize: "14px", fontWeight: "700", marginBottom: "0px" }}>Investor Leads</p>
                    </Link>
                  </Col>
                  <Col xl={4} lg={6} md={6} sm={12} className="mt-4">
                    <Link to="/Portfolio" style={{ minHeight: "140px", minWidth: "140px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px", color: "#fff", borderRadius: "20px", background: "linear-gradient(0deg, #151823, #151823), rgba(255, 255, 255, 0.1)", padding: "15px", width: "100%", height: "100%", borderStyle: "solid", borderWidth: "1px", borderColor: "black gray gray black", textAlign: "center" }}>
                      <img src="/images/portfolio.svg" alt="img3" />
                      <p style={{ color: "#fff", fontSize: "14px", fontWeight: "700", marginBottom: "0px" }}>View Portfolio</p>
                    </Link>
                  </Col>
                  <Col xl={4} lg={6} md={6} sm={12} className="mt-4">
                    <Link to="/company/Transactionlogs" style={{ minHeight: "140px", minWidth: "140px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px", color: "#fff", borderRadius: "20px", background: "linear-gradient(0deg, #151823, #151823), rgba(255, 255, 255, 0.1)", padding: "15px", width: "100%", height: "100%", borderStyle: "solid", borderWidth: "1px", borderColor: "black gray gray black", textAlign: "center" }}>
                      <img src="/images/payments.svg" alt="img5" />
                      <p style={{ color: "#fff", fontSize: "14px", fontWeight: "700", marginBottom: "0px" }}>Payment History</p>
                    </Link>
                  </Col>
                  <Col xl={4} lg={6} md={6} sm={12} className="mt-4">
                    <Link to="/company/payments" style={{ minHeight: "140px", minWidth: "140px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px", color: "#fff", borderRadius: "20px", background: "linear-gradient(0deg, #151823, #151823), rgba(255, 255, 255, 0.1)", padding: "15px", width: "100%", height: "100%", borderStyle: "solid", borderWidth: "1px", borderColor: "black gray gray black", textAlign: "center" }}>
                      <img src="/images/management.svg" alt="img4" />
                      <p style={{ color: "#fff", fontSize: "14px", fontWeight: "700", marginBottom: "0px" }}>Go Live</p>
                    </Link>
                  </Col>
                  <Col xl={4} lg={6} md={6} sm={12} className="mt-4">
                    <Link to="/company/portfolio" style={{ minHeight: "140px", minWidth: "140px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "10px", color: "#fff", borderRadius: "20px", background: "linear-gradient(0deg, #151823, #151823), rgba(255, 255, 255, 0.1)", padding: "15px", width: "100%", height: "100%", borderStyle: "solid", borderWidth: "1px", borderColor: "black gray gray black", textAlign: "center" }}>
                      <img src="/images/company.svg" alt="img6" />
                      <p style={{ color: "#fff", fontSize: "14px", fontWeight: "700", marginBottom: "0px" }}>Manage Portfolio</p>
                    </Link>
                  </Col>
                </Row>
              </div>


              <div style={{ background: "url('/images/buildbg.jpg'), #1c21cc", backgroundSize: "contain", backgroundPosition: "bottom", backgroundRepeat: "no-repeat", borderRadius: "20px", }}>
                <div className="digibooth" style={{ background: "linear-gradient(0deg, rgba(21, 23, 35, 0.4), rgba(21, 23, 35, 0.3)), rgba(255, 255, 255, 0.1)", borderRadius: "20px", marginTop: "20px", borderStyle: "solid", backdropFilter: "blur(5.78857px)", borderWidth: "1px", borderColor: "black gray gray black", color: "#fff" }}>
                  <h3 style={{ color: "#fff", fontWeight: "700" }}>Build/Edit Your Digital Booth!</h3>
                  <p style={{ color: "#fff", fontWeight: "400" }}>We understand having a great online presence to investors is important, but we also understand it can take time to build out all the proper information needed, to convey to the investor community. Don’t worry! You can sign-in to InvestorPresentations.com at your leisure, and take all the time you need to properly upload all the information you’d like to display. When you are comfortable with your Digital Booth, you have complete flexibility in then choosing the dates in which to be “Live” in our Digital Investor Expo. You have full control over your exposure, and the automatic clock helps to stay compliant, in case you forget to end your campaign. Set it and forget it! </p>
                  <a href="/company/portfolio" style={{ background: "linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5)", color: "#fff", padding: "14px 20px", borderRadius: "18px", display: "block", width: "fit-content" }}>Build/Edit Booth</a>
                </div>
              </div>
            </Col>
          <Col sm={12} lg={8} className='mt-4'>
            <div className="chat_box">
              <img src="/images/chats.png" alt="imgg" />
              <div>
                <p>Coming Soon</p>
              </div>
            </div>
          </Col>
        </Row> */}
      </>
    </>
  );
};

export default Analictics;
