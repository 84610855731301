import React from "react";
import { useState } from "react";
import { sendEmailForgetPassApi } from "../api/ForgetPasswordApis";
import { displayErrorToast, displaySuccessToast } from "../helper/toast_notification_function";

export default function EmailForgotPass() {
  const [email, setEmail] = useState("");
  const [loading, setloading] = useState(false);


  const handleChanger = (e) => {
    const { value } = e.target;
    setEmail(value)
  };

  const submit = (e) => {
    e.preventDefault()
    setloading(true)

    // u will receive a reset email if user with that email exist
    let obj = { email: email }
    sendEmailForgetPassApi(obj).then((res) => {
      if (res?.data?.success) {
        let msg = res?.data?.message[0]
        displaySuccessToast(msg)
        setloading(false)

      }
    }).catch((Err) => {
      setloading(false)
      let msg = Err?.response?.data?.message[0]
      displayErrorToast(msg)
    })

  }
  return (
    <section className="bg-img" style={{ minHeight: "calc(100vh - 105px)" }}>
      <div className="py-5 container">
        <div className="mt-5 py-md-3">
          <div className="container h-100">
            <div className="row justify-content-center align-items-center mt-md-5">
              <div className="col-md-5 forgot-box">
                <h3 className="text-center mb-3 fw-bold">Forgot Password</h3>
                <form>
                  <div className="row ">
                    <div className="input-group w-100 mb-3 mt-3">
                      <div className="form-floating ">
                        <input
                          required
                          type="email"
                          value={email}
                          onChange={handleChanger}
                          className="form-control"
                          name="email"
                          id="Floatingpassword"
                          placeholder="Email address"
                        />

                        <label for="Floatingpassword" className="">
                          Email Address
                        </label>
                      </div>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className={`btn btn-primary  mx-auto d-flex align-self-center form_btn  gradientBtn ${email ? "" : "graylog"
                      }`}
                    onClick={submit}
                    disabled={email ? false : true}
                  >
                    {loading ? "Loading..." : "Send Reset Code"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
