import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useDispatch, useSelector } from "react-redux";
import { UploadFaqFileApi } from "../../../../../api/companyApis/portfolio/documentUpload/faqApi";

import PdfComponent from "../../../../../component/PdfComponent";
import { dataWithFiles } from "../../../../../helper/common_functions";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../../../helper/toast_notification_function";
import {
  getFaqPdfAction
} from "../../../../../redux/actions/getPdfAction";
import { TextEditor } from "../../../../../component/TextEditor";
import Table from "./Table";
import AddFaq from "./AddFaq";
import { AiOutlinePlus } from "react-icons/ai";
import { getAdminFaqsQuestionAnswer } from "../../../../../api/companyApis/portfolio/FaqApis";

const FAQ = () => {
  const fileInputRef = React.useRef();
  const [file, setFile] = useState(null);
  const [isDisable, setIsDisable] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const dispatch = useDispatch();

  const [faqLength, setFaqLength] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isLoading , setIsLoading] = useState(false)
  const [AnsThree, setAnsThree] = useState("");
  const [AnsFour, setAnsFour] = useState("");
  const [AnsFive, setAnsFive] = useState("");


 

  const onSubmit = () => {
    setBtnLoader(true)
    setIsDisable(true);
    UploadFaqFileApi(dataWithFiles(file))
      .then((response) => {
        setFile(null);
        fileInputRef.current.value = "";
        setBtnLoader(false)

        setIsDisable(false);
        displaySuccessToast("File Uploaded");
      })
      .catch((error) => {
        setIsDisable(false);
        setBtnLoader(false)

        displayErrorToast(error.response.data.message[0]);
      });
  };

  


  const Submitfaq = () => {
  


  }


  useEffect(() => {
    // setIsLoading(true)
    getAdminFaqsQuestionAnswer()
      .then((res) => {
        console.log(res.data ,"ppppppppppp")
        let filter = res?.data?.data[0].admin_faq.filter((val) => val.answer == null)
        console.log(filter)
        setFaqLength(res.data.data);
    setIsLoading(false)
        
      })
      .catch((error) => {
        setIsLoading(true)
        displayErrorToast(error) });
  }, []);

  if (isLoading) {
    return (
      <center className="d-flex justify-content-center align-items-center  ">
        <Spinner
          as="span"
          animation="border"
          size="lg"
          role="status"
          aria-hidden="true"
          variant="primary"
        />
      </center>
    );
  }

  return (
    <div className="bg_black doc_slide">
  <div
       
      >
        <div className="doc_slide">
          <h3> Faq</h3>
        </div>
       
      </div>  
      
          <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
    



      </form>
      {/* <AddFaq
        isOpen={visible}
        handleToggle={handleToggle}
        toggleState={() => setLoading((prev) => !prev)}
      /> */}
      <Table flag={isLoading} />
      

    </div>
  );
};

export default FAQ;
