import React from "react";
import { AroundCompany } from "../component/homeNew/AroundCompany";
import InvestorHomeSlider from "../component/homeNew/InvestorHomeSlider";
import HeroSection from "../component/homeNew/HeroSetion";
import HowItWork from "../component/homeNew/HowItWork";
import Questions from "../component/homeNew/Questions";
import RaiseAround from "../component/homeNew/RaiseAround";
import Review from "../component/HomeSection/Review";
import JoinInvestors from "./JoinInvestors";
import { useEffect } from "react";
import ExploreInvestment from "../component/homeNew/ExploreInvestment";

export default function HomeNew() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div style={{background:"black"}}>
      <HeroSection />
      <div className="container">
        <InvestorHomeSlider />
        <ExploreInvestment />
        <HowItWork />
      </div>

        {/* <JoinInvestors />
        <AroundCompany />
        <RaiseAround /> */}
        <Questions />

        {/* <Review /> */}
    </div>
  );
}
