import { Progress } from 'antd'
import React from 'react'

const RoundProgress = ({ value, color, trailColor, legend }) => {
  return (
    <div className=' d-flex flex-column align-items-center align-items-lg-center justify-content-center'>

      <p className='text-white text-center' style={{fontWeight:"bold"}} >{legend ? legend : ""}</p>
      <Progress
      style={{background:"#0B243A" , borderRadius:"100px"}}
        type="circle"
        percent={value}
        strokeWidth={6}
        strokeColor={color}
        trailColor={trailColor ? trailColor : "#fff"}
        format={() => (
          <span
            style={{
              color: "#fff",
              textAlign: "center",
              fontSize: "20px",
              display: "block",
            }}
          >
            {value}%
          </span>
        )}
        textStyle={{ fontSize: "24px", fontWeight: "bold" }}
      />

    </div>
  )
}

export default RoundProgress