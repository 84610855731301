import React, { useState } from "react";
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "react-bootstrap";
import {
  createReviewApi,
  updateReviewApi,
} from "../../../../api/companyApis/portfolio/review/reviewApi";
import {
  displayErrorToast,
  displaySuccessToast,
  displayWorrningToast,
} from "../../../../helper/toast_notification_function";

const AddReview = ({ isOpen, handleToggle, toggleState, state, data }) => {
  const [formData, setFormData] = useState(null);
  const [btnLoader , setBtnLoader] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true);

  const handleChange = (e) => {
    setIsDisabled(false);
    const { name, value } = e.target;
    if (e.target.files) {
      setFormData({ ...formData, [name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const Submit = () => {
    setBtnLoader(true)
    if (!state) {
      setIsDisabled(true);
      createReviewApi(formData)
        .then((res) => {
          setIsDisabled(false);
          setFormData("");
    setBtnLoader(false)

          toggleState();
          handleToggle();
          displaySuccessToast("Review successfully submitted");
        })
        .catch((error) => {
    setBtnLoader(false)

          setIsDisabled(false);
          if (error.response.status == 422) {
            displayWorrningToast(error.response.data.message[0]);
          } else {
            displayErrorToast(error);
          }
        });
    } else {
    setBtnLoader(true)

      updateReviewApi(data?.id, formData)
        .then((res) => {
          setIsDisabled(false);
          setFormData("");
          toggleState();
    setBtnLoader(false)

          handleToggle();
          displaySuccessToast("Review successfully updated");
        })
        .catch((error) => {
    setBtnLoader(false)

          setIsDisabled(false);
          displayErrorToast(error);
        });
    }
  };

  return (
    <div>
      {/* Modal for Uploading Video Presentation  */}
      <Modal show={isOpen} onHide={handleToggle}>
        <ModalHeader closeButton>Insert your Company Review</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup className="mb-2">
              <FormLabel htmlFor="full_name">Name</FormLabel>
              <FormControl
                type="text"
                name="name"
                id="name"
                defaultValue={data?.name}
                maxLength={100}
                onChange={(e) => {
                  handleChange(e);
                  // handleErrorChange(e);
                }}
              />
              {/* {formError.presentationTitle && (
                  <p>{formError.presentationTitle}</p>
                )} */}
            </FormGroup>
            <FormGroup className="mb-2">
              <FormLabel htmlFor="designation">Job Description</FormLabel>
              <FormControl
                type="text"
                name="post"
                required
                defaultValue={data?.post}
                id="post"
                maxLength={100}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </FormGroup>
            <FormGroup className="mb-2">
              <FormLabel htmlFor="company_name">Company Name</FormLabel>
              <FormControl
                type="text"
                name="company_name"
                required
                defaultValue={data?.company_name}
                id="company_name"
                maxLength={100}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </FormGroup>

            <Form.Group className="mb-3" controlId="message">
              <Form.Label>Review</Form.Label>
              <Form.Control
                name="review_text"
                as="textarea"
                rows={3}
                defaultValue={data?.review_text}
                onChange={(e) => {
                  handleChange(e);
                }}
                maxLength={350}
              />
            </Form.Group>
          </Form>
        </ModalBody>
        <Modal.Footer>
          <Button
            variant={isDisabled ? "secondary" : "primary"}
            disabled={isDisabled}
            onClick={() => {
              Submit();
            }}
          >
            {btnLoader ? <Spinner animation="border" size="sm" />:!state ? "Upload" : "Edit"}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End Modal for Uploading Video Presentation  */}
    </div>
  );
};

export default AddReview;
