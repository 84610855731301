import { Row } from "antd";
import React, { useState } from "react";
import TopSection from "../Common/TopSection";
import { useLocation } from "react-router-dom";
import { RiCloseFill } from "react-icons/ri";

function Questions() {

  const [open, setopen] = useState(2)
  const location = useLocation();

  return (
    <div className="questions container py-5">
      <Row>
        <TopSection
          mainHeading="Still Have Questions?"
        />
      </Row>

      <div className="home-faqs">
        {location.pathname == "/" ?
          <div className="faqs-asked">
            <div className={`faq-accordion ${open == 2 ? "act" : ""}`}>
              <h4 onClick={() => { setopen(2) }}>
                Do I need to provide bank information?
                <span className="close-ico">
                  <RiCloseFill size={20} color="#fff" />
                </span>
              </h4>
              <div className="faq-content">
                <p>NO! We do not get involved in the transfer of money. Just connect with a company on your terms and discuss how to best move forward.</p>
              </div>
            </div>

            <div className={`faq-accordion ${open == 3 ? "act" : ""}`}>
              <h4 onClick={() => { setopen(3) }}>
                Are these investment opportunities vetted for risk?
                <span className="close-ico">
                  <RiCloseFill size={20} color="#fff" />
                </span>
              </h4>
              <div className="faq-content">
                <p>NO! Each investors risk tolerance is different. Our platform is to provide access and knowledge of what is available in the marketplace. Please do your own due diligence before investing money with any company.</p>
              </div>
            </div>
          </div> :
          <div className="faqs-asked">
            <div className={`faq-accordion ${open == 2 ? "act" : ""}`}>
              <h4 onClick={() => { setopen(2) }}>
                Is Lead Information Verified?
                <span className="close-ico">
                  <RiCloseFill size={20} color="#fff" />
                </span>
              </h4>
              <div className="faq-content">
                <p>YES! Email verification is done for non-accredited investors, while cell phone data is verified during the Accredited Investors. Please follow all applicable laws (and human decency) while using telephone data.</p>
              </div>
            </div>
            <div className={`faq-accordion ${open == 3 ? "act" : ""}`}>
              <h4 onClick={() => { setopen(3) }}>
                Can I really download leads in real-time?
                <span className="close-ico">
                  <RiCloseFill size={20} color="#fff" />
                </span>
              </h4>
              <div className="faq-content">
                <p>YES! The idea is to give you a digital booth space at a global trade-show. Just like at a face-to-face event, we are scanning the investor badges as they view your offering, and making available to you for download!</p>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default Questions;
