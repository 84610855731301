import { Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import {
  createUseOfProceedsApi,
  getUseOfProceedsApi,
} from "../../../../api/companyApis/useOfProceeds";
import PieChart from "../../../../component/charts/PieChart";
import {
  displayErrorToast,
  displaySuccessToast,
  displayWorrningToast,
} from "../../../../helper/toast_notification_function";

const { Title } = Typography;

const UseOfProceeds = () => {
  const [data, setData] = useState([{ legend: "", y1: 0, y2: 0 }]);
  let totalY1 = data.reduce((a, b) => a + b.y1, 0);
  let totalY2 = data.reduce((a, b) => a + b.y2, 0);
  const [btnLoader, setBtnLoader] = useState(false);

  const filterdArray = React.useMemo(() => {
    return data.filter(
      (item) => item.legend !== "" && item.y1 !== 0 && item !== 0
    );
  }, [data]);

  const onSubmit = () => {
    setBtnLoader(true)
    let body = {
      donut_chart: filterdArray,
    };
    if (totalY1 !== 100 || totalY2 !== 100) {
      displayWorrningToast("Accumulated sum of input set must be equal to 100");
    } else {
      createUseOfProceedsApi(body)
        .then((res) => {
          displaySuccessToast("graph set Submited");
          setBtnLoader(false)

        })
        .catch((error) => {
          displayErrorToast(error);
          setBtnLoader(false)

        });
    }
  };

  useEffect(() => {
    getUseOfProceedsApi()
      .then((re) => {
        if (re.data?.data[0]?.donut_chart) {
          let array = re.data?.data[0].donut_chart;
          if (re.data?.data[0]?.donut_chart?.length < 10) {
            array.push({ legend: "", y1: 0, y2: 0 });
          }
          setData(array);
        }
      })
      .catch((error) => {
        displayErrorToast(error);
      });
  }, []);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    let arr = [...data];

    if (name === "legend") {
      arr[index][name] = value;
      setData(arr);
    } else {
      if (true) {
        arr[index][name] = Number(value);
        setData(arr);
      } else {
        displayWorrningToast("Value should be non negative");
      }
    }
  };

  const addNewRow = (index) => {
    let arr = [...data];

    if (
      arr[index].legend !== "" &&
      data.length == index + 1 &&
      data.length <= 10
    ) {
      arr.push({ legend: "", y1: 0, y2: 0 });
      setData(arr);
    }
  };
  return (
    <div className="bg_black">
      <div className="doc_slide">
        <h3>Use Of Proceeds</h3>
      </div>
      <Row
        justify="end"
        style={{
          padding: "5px",
          marginBottom: "5px",
        }}
      >
        <Col
          lg={12}
          style={{
            justifyContent: "end",
            alignItems: "end",
            display: "flex",
          }}
        >
          <button className="addbtnt" style={{ width: "80px" }} onClick={onSubmit}>{btnLoader ? <Spinner animation="border" size="sm" /> : "Submit"}</button>
        </Col>
      </Row>
      <Row>
        {
          data.length > 0 ? (
            <Table striped bordered hover size="md" className="dataRoomTable">
              <thead className="text-center">
                <th style={{ fontFamily: 'sansation', fontSize: "14px", fontWeight: "600" }}>S.no</th>
                <th style={{ fontFamily: 'sansation', fontSize: "14px", fontWeight: "600" }}>Division Of Resources</th>
                <th style={{ fontFamily: 'sansation', fontSize: "14px", fontWeight: "600" }}>Minmum %</th>
                <th style={{ fontFamily: 'sansation', fontSize: "14px", fontWeight: "600" }}>Maximum %</th>
                {/* <th>Action</th> */}
              </thead>
              <tbody>
                {data?.map((item, index) => {
                  return (
                    <>
                      <tr>
                        <td>
                          <div style={{ color: "#fff", display: "flex", justifyContent: "center", alignItems: "center", height: "38px" }}>
                            {index + 1}
                          </div>
                        </td>
                        <td>
                          <input
                            className="form-control"
                            type="text"
                            id={"Port-legend " + index}
                            name="legend"
                            value={item.legend}
                            onChange={(e) => handleChange(e, index)}
                            onBlur={() => addNewRow(index)}
                          />
                        </td>
                        <td>
                          <input
                            className="form-control"
                            id={"Port-y1 " + index}
                            name="y1"
                            value={item.y1.toString()}
                            onChange={(e) => handleChange(e, index)}
                          />
                        </td>
                        <td>
                          <input
                            className="form-control"
                            id={"Port-y2 " + index}
                            name="y2"
                            value={item.y2.toString()}
                            onChange={(e) => handleChange(e, index)}
                          />
                        </td>
                      </tr>
                    </>
                  )
                })}
                <tr>
                  <td></td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="legend"
                      value="Sum"
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="legend"
                      value={`Total minimum ${totalY1}`}
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      name="legend"
                      value={`Total maximum ${totalY2}`}
                      disabled
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          ) : null
        }
      </Row>

      <Row justify={"space-evenly"} style={{ justifyContent: "space-evenly" }}>
        <Col xxl={10} lg={10} md={6} sm={12} xs={12}>
          <div style={{ width: "100%", height: "300px" }}>
            <PieChart data={filterdArray} label=" Minimum Value" field={"y1"} />
          </div>
        </Col>
        <Col xxl={10} lg={10} md={6} sm={12} xs={12}>
          <div style={{ width: "100%", height: "300px" }}>
            <PieChart data={filterdArray} label=" Maximum Value" field={"y2"} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UseOfProceeds;
