import { Typography } from "antd";
import React from "react";
import file from "../../../images/filling.png"
export default function FilingPortFolio({ filing }) {

  return (
    <div className="">
      <div className="row">
        {filing?.length > 0 && (
          filing.map((val, ind) => {
            return (
              <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
                <div key={ind} className="fillingInViewPortfolio">
                  <img src={file} alt="file-logo" />
                  <div className="filing_cont">
                    <h4>{val?.heading}</h4>
                    <p>{val?.text_area}</p>
                    <a href={val?.pdf} download>Download </a>
                  </div>
                </div>
              </div>
            )
          })
        )}
      </div>
    </div>
  );
}
