import React, { useState, useEffect } from "react"
import { Col, Row } from "react-bootstrap";
import { ComposableMap, Geographies, Geography } from "react-simple-maps"
import { GetInvestorsByCountry, GetInvestorsByState } from "../../api/companyApis/analyticsApi";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip as ReactTooltip } from 'react-tooltip'

export default function MapChart() {
  const clr = () => {
    var x = Math.floor(Math.random() * 256);
    var y = 45 + Math.floor(Math.random() * 256);
    var z = 40 + Math.floor(Math.random() * 256);
    var bgColor = "rgb(" + x + "," + y + "," + z + ")";
    return (bgColor)
  }
  const [countries, setcountries] = useState([]);
  const [usstate, setusstate] = useState([]);
  const countryurl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";
  const geoUrl = "https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json"

  useEffect(() => {
    GetInvestorsByCountry().then((res) => {
      setcountries(res.data.data.map((data) => { if (data.country === "United States") { return { country: 'United States of America', investor_count: data.investor_count } } else { return data } }))
    }).catch((error) => console.log(error, "error"));

    GetInvestorsByState().then((res) => {
      setusstate(res.data.data.map((data) => data))
    }).catch((error) => console.log(error, "error"));

  }, [])

  return (
    <Row>
      <Col lg={6} md={12} sm={12}>
        <div className="mapbox" style={{ width: "100%", backgroundColor: "#171717", borderRadius: "20px", marginTop: "20px", borderStyle: "solid", borderWidth: "1px", borderColor: "black gray gray black" }}>
          <h3 className="mapname" >Investors By State</h3>
          <ComposableMap projectionConfig={{ scale: 800 }} projection="geoAlbersUsa" >
            <Geographies geography={countryurl}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  const { name } = geo.properties;
                  const stateData = usstate.find(
                    (stat) => stat.state === name
                  );
                  return (
                    <Geography key={geo.rsmKey} geography={geo}
                      data-tooltip-html={stateData && `<center>State: ${stateData.state} <br /> Investor Count: ${stateData.investor_count}</center>`}
                      data-tooltip-id="map-tooltip-2"
                      fill={stateData ? `${clr()}` : "#DDD"}
                      stroke="#000000"
                    />
                  );
                })
              }
            </Geographies>
          </ComposableMap>
          <ReactTooltip id="map-tooltip-2" />
        </div>
      </Col>
      <Col lg={6} md={12} sm={12}>
        <div className="mapbox" style={{ width: "100%", position: "relative", backgroundColor: "#171717", borderRadius: "20px", marginTop: "20px", borderStyle: "solid", borderWidth: "1px", borderColor: "black gray gray black" }}>
          <h3 className="mapname" >Investors By Country</h3>
          <ComposableMap >
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  const { name } = geo.properties;
                  const countryData = countries.find(
                    (country) => country.country === name
                  );
                  return (
                    <Geography key={geo.rsmKey} geography={geo}
                      data-tooltip-html={countryData && `<center>Country: ${countryData.country} <br /> Investor Count: ${countryData.investor_count}</center>`}
                      data-tooltip-id="map-tooltip"
                      fill={countryData ? `${clr()}` : "#DDD"}
                      stroke="#000000"
                    />
                  )
                })}
            </Geographies>
          </ComposableMap>
          <ReactTooltip id="map-tooltip" />
        </div>
      </Col>
    </Row>

  )
}

