import React, { useEffect } from 'react'

const Terms = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://app.termly.io/embed-policy.min.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);
    return (
        <>
            <section id="bannerSec">
                <div className="abt_bnr">
                    <div className="heroWrapper">
                        <div className="hero_inner">
                            <h1 className="commonH2">Terms & Conditions</h1>
                            {/* <p className="banner-sec-text text-center">
                                InvestorPresentations.com is just like attending an Alternative Investment Trade-Show, without the hassle and expense of travel.  Visit the digital booths of companies, that may offer investment opportunities your broker is not talking about. Watch video presentations just as if you were sitting in the audience, and download investment offering information to learn more as well. Enjoy educating yourself on Alternative Investment opportunities from all around the world, all from the comfort of your home and office, with no bank account required!
                            </p> */}
                        </div>
                    </div>

                </div>
            </section>
            <div className="container">
                <div
                    name="termly-embed"
                    data-id="96be58e6-426a-4550-8402-cb08ebd37f6d"
                    data-type="iframe"
                ></div>
            </div>
        </>
    )
}

export default Terms