import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  displayWorrningToast
} from "../../helper/toast_notification_function";

import { Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { LoginHandle } from "../../redux/actions/authActions";

import ReCAPTCHA from "react-google-recaptcha";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { FiArrowRight } from "react-icons/fi";
import useWindowDimensions from "../WindowDimensions";

const LoginForm = () => {
  const [processLoading, setProcessLoading] = useState(false);

  const [showPass, setShowPass] = useState(false);
  const [signenable, setsignenable] = useState(true)
  const { width, height } = useWindowDimensions();
  const { error } = useSelector((state) => state.AppStateReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [User, SeTUser] = useState({ loginKey: "", password: "", captcha: "" });

  const handleChanger = (e) => {
    const { name, value } = e.target;
    SeTUser({ ...User, [name]: value });
  };

  const checkcaptcha = async (token) => {
    // const response = await axios.post(
    //   `https://www.google.com/recaptcha/api/siteverify?secret=${process.env.REACT_APP_RECAPTCHA_SECRET_KEY}&response=${token}`
    // )
    console.log(token)
    if (token) {
      SeTUser({ ...User, captcha: token })
      setsignenable(false)
    }
    else {
      setsignenable(true)
    }
  }

  const formHandler = (e) => {
    e.preventDefault();
    if (!User.loginKey || !User.password) {
      displayWorrningToast("Please enter both username and password");
    } else {
      setProcessLoading(true);
      LoginHandle(User, navigate, dispatch, setProcessLoading);
    }
  };

  return (
    <div>
      <form className="py-md-3" onSubmit={formHandler}>
        <div className="form-floating">
          <input
            type="text"
            onChange={handleChanger}
            value={User.loginKey}
            className="form-control login_inp"
            name="loginKey"
            id="exampleInputEmail1"
            placeholder="Email Address or Username"
          />
          <label for="emailAddress" className="font-weight-bold">
            Email address or Username
          </label>
        </div>
        <div className="input-group mt-3">
          <div className="form-floating flex-grow-1">
            <input
              type={!showPass ? "password" : "text"}
              value={User.password}
              onChange={handleChanger}
              className="form-control login_inp"
              name="password"
              id="exampleInputPassword1"
              placeholder="Password"
            />
            <label for="pword" className="font-weight-bold">Password</label>
          </div>
          <span className="input-group-text eyeBtn" id="basic-addon2" onClick={() => setShowPass((prev) => !prev)}>
            {!showPass ? (
              <AiOutlineEye size={24} />
            ) : (
              <AiOutlineEyeInvisible size={24} />
            )}
          </span>
        </div>
        <div className="text-center p-1 ">
          {/* <Link to="*" className="  fw-normal primary">
            Forgot Password?
          </Link> */}
        </div>

        <div className="d-flex justify-content-center">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={checkcaptcha}
            style={{ margin: "15px 0 10px" }}
            className="recap"
            size={width < 400 ? "compact" : "normal"}
          />
        </div>

        <div className="mt-3 text-center">
          <button
            type="submit"
            style={{ border: "2px solid navy" }}
            className={`form_btn  gradientBtn ${signenable ? "graylog" : ""}`}
            disabled={signenable}
          >
            {!processLoading ? (
              <>
                Login
                <FiArrowRight className="rightArrow" />
              </>
            ) : (
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
                Processing...
              </>
            )}
          </button>
        </div>
      </form >

      {/* <div className="pb-1">
        <div className="text-center m-0">
          <span className=" fw-normal text-muted">Can't log in?</span>
        </div>
      </div> */}

      <div className="pb-1">
        <div className="text-center m-0">
          <Link to="/forgotPasswordEmail" className=" fw-normal helpSection">Forgot Password?</Link>
        </div>
      </div>


      <div className=" mt-3 col-12 flex-column gap-3 d-flex justify-content-center align-items-center">
        <h4 className="fw-bold" style={{ color: "black" }}>
          Don't have an account?
        </h4>
        {/* <button className="border-gradient createAccBtn-loginPage " onClick={() => navigate('/signup', { state: { data: "company" } })}>
          Create Company Account
        </button> */}
        <button className="border-gradient createAccBtn-loginPage  " onClick={() => navigate('/signup', { state: { data: "user" } })}>
          Create An Account
        </button>
      </div>
      {/* <div className="text-center ">
          User are
          <Link to="/contact" className=" fw-normal primary helpSection">
            {" "}
            Help Section
          </Link>
        </div> */}
    </div >
  );
};

export default LoginForm;
