import { apiHandle } from "./apiHandler";

// Api for get user profile

export const GetUserProfileApi = () => {
  return apiHandle().get("/user/auth/profile");
};
// api for update user profile
export const UpdateUserProfileApi = (data) => {
  return apiHandle().put("/user/auth/profile", data);
};






//get user level 2 profile api
export const GetUserLeveltwoProfileApi = () => {
  return apiHandle().get("/user/auth/levelTwoProfile");
};
//update user level 2 profile api
export const UpdateUserLeveltwoProfileApi = (data) => {
  return apiHandle(true, true).put("/user/auth/levelTwoProfile", data);
};


// api for update user profile img
export const addUpdateUserprofileImgApi = (data) => {
  return apiHandle().put("/user/auth/profilePicture", data);
};


// api for get user profile img
export const getUserprofileImgApi = () => {
  return apiHandle().get("/user/auth/profilePicture");
}

export const getCompanyProfileImg = () => {
  return apiHandle().get("/company/auth/profile/logo");
}


// api for del user profile img
export const deleteUserProfileImg = () => {
  return apiHandle().delete("/user/auth/profilePicture");
};

export const sendotpuserside = () => {
  return apiHandle().post("/user/auth/sendOtp");
};

export const verifyotpuserside = (OTP) => {
  return apiHandle().post("/user/auth/verifyOtp", OTP);
};