

import { apiHandle } from "./apiHandler";

export const sendEmailForgetPassApi = ( email) => {
  return apiHandle(false).post(`/resetPassword/reset` , email);
};


export const confirmForgetPassword = ( data) => {
    let {token , password , confirm_password} = data

    let obj = {password : password , confirm_password :confirm_password }
    return apiHandle(false).post(`resetPassword/verify/${token}` , obj);
  };
  