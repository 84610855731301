import React, { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { EmailVerify } from '../api/AuthApi'
import { displayErrorToast, displaySuccessToast } from '../helper/toast_notification_function'
import { BsArrowRightShort } from 'react-icons/bs'
import { Button } from 'react-bootstrap'

const EmailVerification = () => {
    const navigate = useNavigate()

    const { token } = useParams()

    useEffect(() => {
        EmailVerify(token).then((res) => {
            displaySuccessToast(res)
        }).catch((res) => {
            displayErrorToast(res);
        })
    }, [])
    return (
        <div className='email_verification_page'>
            <h2>Email Verified!!</h2>
            <Button  onClick={() => navigate('/login')}>Login Now <BsArrowRightShort fontSize={24} style={{fontWeight:'bold'}}  /></Button>
        </div>
    )
}

export default EmailVerification