import { Paper } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

import { Popconfirm } from "antd";
import {
  deleteDataroomApi,
  getDataroomApi,
} from "../../../../api/companyApis/portfolio/dataroom/dataroomApi";
import AddMember from "./FilesForm";

// let headers = [
//   { label: "Id", key: "user_id" },
//   { label: "First Name", key: "first_name" },
//   { label: "Last Name", key: "last_name" },
//   { label: " Views", key: "view_count" },
//   { label: "email", key: "email" },
// ];

const Table = ({ flag}) => {
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [single, setSingle] = useState(null);

  const handleToggle = () => {
    setVisible((prev) => !prev);
  };

  const onDelete = (id) => {
    deleteDataroomApi(id)
      .then((res) => {
        getDataroomApi()
          .then((res) => {
            setData(res.data.data);
          })
          .catch((error) =>
            console.log("Portfolio team member", error.response)
          );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onEdit = (id) => {
    if (id) {
      getDataroomApi(id)
        .then((res) => {
          setSingle(res.data.data[0]);
          setVisible(true);
        })
        .catch((error) => console.log("Portfolio team member", error.response));
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 80,
    },
    {
      field: "filename",
      headerName: "File name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      minWidth: 300
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <button className="btntm" style={{border: "none", marginBottom: "0px"}} onClick={() => onEdit(params.row.id)}>
              <img src="/images/edt.svg" alt="img" />
            </button>
            <Popconfirm
              title="Are you sure to delete this File?"
              onConfirm={() => onDelete(params.row.id)}
            >
              <button className="btntm ms-2" style={{border: "none", marginBottom: "0px"}}>
                <img src="/images/dlt.svg" alt="img"/>
              </button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    getDataroomApi()
      .then((res) => {
        setLoading(false);
        setData(res.data.data);
      })
      .catch((error) => console.log("Portfolio data room", error.response));
  }, [flag, trigger]);

  if (isLoading) {
    return (
      <>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: 400, width: "100%" }}
        >
          <div className="container">
            <div className="d-flex align-items-center justify-content-center">
              <Spinner animation="border" size="lg" variant="primary" />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div>
      <AddMember
        isOpen={visible}
        handleToggle={handleToggle}
        toggleState={() => setTrigger((prev) => !prev)}
        data={single}
        state={"update"}
      />
      <Paper className="tableBg">
        <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={data}
            columns={columns}
            getRowId={(row) => row.id}
            rowsPerPageOptions={[10, 20, 30]}
            checkboxSelection
            disableSelectionOnClick
            rowHeight={70}
          />
        </Box>
      </Paper>
    </div>
  );
};

export default Table;
