import { Paper } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";

import { Button, Popconfirm } from "antd";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import {
  deleteReviewApi,
  getReviewApi,
} from "../../../../api/companyApis/portfolio/review/reviewApi";
import AddReview from "./ReviewForm";

const Table = ({ flag, formData }) => {
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [single, setSingle] = useState(null);

  const handleToggle = () => {
    setVisible((prev) => !prev);
  };

  const onDelete = (id) => {
    deleteReviewApi(id)
      .then((res) => {
        getReviewApi()
          .then((res) => {
            setData(res.data.data);
          })
          .catch((error) =>
            console.log("Portfolio team member", error.response)
          );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onEdit = (id) => {
    if (id) {
      getReviewApi(id)
        .then((res) => {
          setSingle(res.data.data[0]);
          setVisible(true);
        })
        .catch((error) => console.log("Portfolio team member", error.response));
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 80,
    },
    {
      field: "name",
      headerName: "Name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 130,
    },
    {
      field: "post",
      headerName: "Job Description",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 150,
      align: "center",
      headerAlign: 'center',
    },
    {
      field: "company_name",
      headerName: "Company Name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 150,
      align: "center",
      headerAlign: 'center',
    },
    {
      field: "review_text",
      headerName: "Review",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      minWidth: 200,
      flex: 1,
      align: "center",
      headerAlign: 'center',
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <button className="btntm" style={{border: "none", marginBottom: "0px"}} onClick={() => onEdit(params.row.id)}>
              <img src="/images/edt.svg" />
            </button>
            <Popconfirm
              title="Are you sure to delete this Review?"
              onConfirm={() => onDelete(params.row.id)}
            >
              <button className="btntm ms-2" style={{border: "none", marginBottom: "0px"}}>
                <img src="/images/dlt.svg" />
              </button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    getReviewApi()
      .then((res) => {
        setLoading(false);
        setData(res.data.data);
      })
      .catch((error) => console.log("Portfolio data room", error.response));
  }, [flag, trigger]);

  if (isLoading) {
    return (
      <>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: 400, width: "100%" }}
        >
          <div className="container">
            <div className="d-flex align-items-center justify-content-center">
              <Spinner animation="border" size="lg" variant="primary" />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div>
      <AddReview
        isOpen={visible}
        handleToggle={handleToggle}
        toggleState={() => setTrigger((prev) => !prev)}
        data={single}
        state={"update"}
      />
      <Paper className="tableBg">
        <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={data}
            columns={columns}
            getRowId={(row) => row.id}
            rowsPerPageOptions={[10, 20, 30]}
            checkboxSelection
            disableSelectionOnClick
            rowHeight={70}
          />
        </Box>
      </Paper>
    </div>
  );
};

export default Table;
