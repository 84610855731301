import { Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ProgressBar,
  Row,
  Spinner
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyProfileImg } from "../../api/UserProfileApi";
import { publishPortFolioApi } from "../../api/companyApis/portfolio/modulesActiveApi";
import { displayErrorToast, displaySuccessToast } from "../../helper/toast_notification_function";
import placeholderImage from "../../images/placeholderImage.jpg";
import img from "../../images/videoUpload.jpg";
import { getVideosHandle } from "../../redux/actions/CompayVideoActions";
import { PROGRESS_UPLOADING_VIDEO } from "../../redux/constants/presentation_progress_constant";
import { generateFilePath } from "../../utility/GenerateFIlePath";
import { Uploader } from "../../utility/Uploader";
import VideosComponent from "./VideosComponent";
import { FaArrowRightLong } from "react-icons/fa6";
import useWindowDimensions from "../../component/WindowDimensions";

const PresentationMedia = () => {
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(true);
  const {width , height} = useWindowDimensions()

  const iscompayLevelTwo = JSON.parse(
    localStorage.getItem("IP_levelTwoProfileStatus")
  );


  const dispatch = useDispatch();
  let isDisabled = false;
  const { ProgressVideoUpload } = useSelector(
    (state) => state.PresentationProgressReducer
  );
  const { videos } = useSelector(
    (state) => state.videoReducer
  );


  //  const [isVideoOpen, setIsVideoOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [formData, setFormData] = useState(null)
  const [levelTwo, setLevelTwo] = useState()
  const [thumbnailOver , setThumbnailOver] = useState(false)

  const [uploader, setUploader] = useState(undefined);
  const [formError, setFormError] = useState({
    presentationTitle: null,
    presentationVideo: null,
    presentationThumbnail: null,
    isActive: true,
  });

  const imageInputRef = React.useRef();
  const videoInputRef = React.useRef();

  const handleClick = (e) => {

    imageInputRef.current.click();
  }

  const handleClickTwo = () => {
    videoInputRef.current.click();

  }




  const handleValidtion = () => {
    if (
      !formData?.presentationThumbnail ||
      !formData?.presentationTitle ||
      !formData?.presentationVideo
    ) {
      return (isDisabled = true);
    } else if (
      formError?.presentationThumbnail ||
      formError?.presentationTitle ||
      formError?.presentationVideo
    ) {
      return (isDisabled = true);
    } else {
      return (isDisabled = false);
    }
  };

  const handleErrorChange = (e) => {
    const { name } = e.target;
    if (name === "presentationTitle") {
    }
    if (name === "presentationVideo") {
      let fileVideo = e.target.files[0];
      if (fileVideo !== undefined) {
        const fileExtension = fileVideo.type.split("/")[0];
        if (fileExtension !== "video") {
          setFormError({
            ...formError,
            presentationVideo: "file type must be video",
          });
        } else {
          setFormError({
            ...formError,
            presentationVideo: null,
          });
        }
      }
    }

    if (name === "presentationThumbnail") {
      let fileImage = e.target.files[0];
      if (fileImage !== undefined) {
        const fileExtension = fileImage.type.split("/")[0];
        if (fileExtension !== "image") {
          setFormError({
            ...formError,
            presentationThumbnail: "file type must be Image",
          });
        } else {
          setFormError({
            ...formError,
            presentationThumbnail: null,
          });
        }
      }
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (e.target.files) {
      setFormData({ ...formData, [name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleToggle = () => setIsOpen((prev) => !prev);


  useEffect(() => {
    getCompanyProfileImg().then((res) => {
      if (res?.data?.success) {
        setLevelTwo(res?.data?.data?.[0]?.is_level_two_profile)
        console.log(res.data.data[0].is_level_two_profile)
      }
    }).catch((err) => displayErrorToast(err))
  }, [])

  const uploadFile = () => {
    if (formData.presentationVideo) {
      const videoUploaderOptions = {
        fileName: generateFilePath(formData.presentationVideo, "dev-video"),
        file: formData.presentationVideo,
        // chunkSize: 1024 * 1024 * 3,
        setIsOpen,
        title: formData?.presentationTitle,
        thumbnail: formData?.presentationThumbnail,
        isActive: true,
        isLevelTwo: iscompayLevelTwo ? true : formData?.isLevelTwo,
        isPrimary: formData.isPrimary || false,
      };

      let percentage = undefined;
      handleToggle();

      const uploader = new Uploader(videoUploaderOptions);

      setUploader(uploader);

      uploader
        .onProgress(({ percentage: newPercentage }) => {
          // to avoid the same percentage to be logged twice
          if (newPercentage !== percentage) {
            percentage = newPercentage;
            dispatch({
              type: PROGRESS_UPLOADING_VIDEO,
              payload: percentage,
            });
            if (percentage == 100) {
              setDisable(false)

              // getVideosHandle(dispatch);
              setTimeout(function () {
                dispatch({
                  type: PROGRESS_UPLOADING_VIDEO,
                  payload: null,
                });
              }, 3000);
              percentage = undefined;
            }
          }
        })
        .onError((error) => {
          setDisable(true)
          console.error("Uploader ERROR LOG", error);
        });

      uploader.start();
    }
  };

  const onCancel = () => {
    if (uploader) {
      uploader.abort();
    }
  };
  handleValidtion();

  // const handleVideoUrl = (id) => {
  //   getCompanyVideoUrlApi(id)
  //     .then((res) => {
  //       setVideoUrl(res.data.data[0]);
  // handleVideoToggle();
  //     })
  //     .catch((error) => console.log("get video url", error.response));
  // };

  const publishPortFolio = () => {
    setLoading(true);
    publishPortFolioApi()
      .then((res) => {
        const msg = res?.data?.message[0];
        setLoading(false);
        setDisable(true)
        displaySuccessToast(msg);
      })
      .catch((err) => {
        displayErrorToast(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getVideosHandle(dispatch);
  }, [isOpen]);
  return (
    <>
      <div className="topSectionImgWrapper">
        <h1 className="text-white fw-bold">Upload Your Video Presentations</h1>
        <p className="text-center text-white col-md-10 col-12 descpay">
          Below is where you can upload three video presentations for our
          investor community to watch. Due to website and investor data
          constraints, please try not to exceed one hour of video length per
          video upload. If your video presentation is longer than an hour, feel
          free to break up your video in up to three parts, for a combined three
          hours of presentations to be uploaded! If you experience technical
          difficulties, please Contact Us for further assistance.
        </p>
      </div>

      <div className="presentation_dashboard">
        <div style={{ color: "black" }} className="bgDropBlur mx-md-4 mx-2">
          <div className="blue_right_Border">
            <div className="d-flex mb-5 justify-content-md-end gap-3">
              <Button
                disabled={disable}
                type="primary"
                size="large"
                id="myButton"
                className="publishBtn publishbtnportfolio mr-4"
                onClick={() => publishPortFolio()}
              >
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) :
                  <span>Publish My Videos <FaArrowRightLong size={16} /></span>
                }

              </Button>
            </div>
            {!!ProgressVideoUpload && (
              <div className="my-2">
                <h4
                  style={{
                    fontFamily: 'sansation',
                    fontWeight: "bold",
                    color: "white",
                  }}
                  className=" text-center mb-2"
                >
                  Video Uploading...
                </h4>
                <ProgressBar
                  animated
                  now={ProgressVideoUpload}
                  label={`${ProgressVideoUpload}% completed`}
                />
              </div>
            )}

            {!ProgressVideoUpload && (
              <Row className="mb-3 align-items-center">
                <div className="col-12 col-lg-6">
                  <span>
                    <h3
                      style={{
                        fontFamily: 'sansation',
                        fontWeight: "bold",
                        color: "white",
                        fontSize:"3rem",
                        paddingLeft:"2rem"
                      }}
                      className="text-lg-start text-center"
                    >
                      Upload Video
                    </h3>
                  </span>
                </div>

                <div className="col-12 col-lg-6 videoUploadDiv">
                  <Tooltip placement="bottomLeft" title={"Upload Video"}>
                    <button onClick={handleToggle}>
                      <img src={img} alt="upload media" />
                    </button>
                  </Tooltip>
                </div>

                {/* <div
                  className="d-flex justify-content-end"
                  style={{ gap: "2rem", position: "relative", top: "1rem" }}
                >
                  <Button
                    style={{ backgroundColor: "rgb(0, 7, 61)" }}
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                </div> */}
              </Row>
            )}
          </div>
        </div>

        {/* video Detail compoenet  */}

      </div>
      <div className="blue_right_Border p-3" style={{ marginTop: "2rem" }}>
        {videos && <VideosComponent levelTwo={levelTwo} videos={videos} />}

        {/* video Detail compoenet  */}

        {/* Modal for Uploading Video Presentation  */}
        <Modal show={isOpen} onHide={handleToggle}>
          <ModalHeader closeButton><b>Upload Your Video Presentation</b></ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup className="mb-2">
                <FormLabel htmlFor="presentationTitle">
                  Video Headline
                </FormLabel>
                <FormControl
                  type="text"
                  name="presentationTitle"
                  id="presentationTitle"
                  maxLength={100}
                  onChange={(e) => {
                    handleChange(e);
                    handleErrorChange(e);
                  }}
                />
                {formError.presentationTitle && (
                  <p>{formError.presentationTitle}</p>
                )}
              </FormGroup>
              <FormGroup className="mb-2">
                <FormLabel htmlFor="presentationThumbnail">
                  <ul style={{ paddingLeft: "1rem" }}>
                    <li>
                      <h5 style={{ color: "#000" }}>Thumbnail (e.g jpg, jpeg)</h5>
                    </li>
                  </ul>
                  <span className="f-14-mob">(Aspect Ratio: 3/2 | 900*600px Min. )</span>
                </FormLabel>
                <div
                  className={` gap-2 col-12 d-flex  ${
                    !!formData?.presentationThumbnail ? 
                    "justify-content-start"
                    : "justify-content-between"
                    }`}
                >
                  <InputGroup  className={width > 550 ? " mb-3" : "d-none"} style={{ height: "fit-content", marginTop: "5px" }}>
                    <FormControl
                      type="file"
                      name="presentationThumbnail"
                      id="presentationThumbnail"
                      accept="image/*"
                      ref={imageInputRef}
                      onChange={(e) => {
                        handleChange(e);
                        handleErrorChange(e);
                      }}
                    />

                   

                    {formData?.presentationThumbnail && (
                      <InputGroup.Text>
                        <span
                          onClick={() => {
                            imageInputRef.current.value = "";
                            setFormData({
                              ...formData,
                              presentationThumbnail: null,
                            });
                          }}
                        >
                          ✖
                        </span>
                      </InputGroup.Text>
                    )}
                  </InputGroup>

               
                  <div className={`p-relative mt-2 ${width < 550 ? "w-75" : "w-50"}`}>
                    {width < 550 && formData?.presentationThumbnail && (
                      <InputGroup.Text className="position-absolute">
                        <span
                          onClick={() => {
                            imageInputRef.current.value = "";
                            setFormData({
                              ...formData,
                              presentationThumbnail: null,
                            });
                          }}
                        >
                          ✖
                        </span>
                      </InputGroup.Text>
                    )}

                    {!!formData?.presentationThumbnail && (
                                    <div
                                      className="w-100"
                                      style={{ border: "1.3px dashed #0558E2" ,borderRadius:"8px",overflow:"hidden" }}
                                    >
                                      <img
                                        alt="imgg"
                                        style={width < 500 ?{width: "100%"} : {width:"100%" , objectFit:"contain" , height:"100px"}}
                                        src={URL.createObjectURL(
                                          formData.presentationThumbnail
                                        )}
                                      />
                                    </div>
                       )}

                    
                    <div onClick={handleClick}>
                     {!formData?.presentationThumbnail && (
                      <div className="w-100">
                        <img
                          alt="imgg"
                          style={{
                            width: "100%",
                          }}
                          src={placeholderImage}
                        />
                      </div>
                    )}
                    </div>
                  </div>
                </div>

                {formError.presentationThumbnail && (
                  <p
                    style={{
                      fontSize: 17,
                      color: "red",
                      margin: "4px 0 4px 0",
                    }}
                  >
                    {formError.presentationThumbnail}
                  </p>
                )}
              </FormGroup>

              <FormGroup className="mb-2 mt-2">
                <FormLabel htmlFor="presentationVideo">
                  <ul style={{ paddingLeft: "1rem" }}>
                    <li>
                      <h5 style={{ color: "#000" }}>Video (e.g MP4, MOV)</h5>
                    </li>
                  </ul>
                </FormLabel>
               
               <div className="position-relative">
                <div className={`gap-2 col-12 d-flex ${!!formData?.presentationVideo  ? "justify-content-start " : "justify-content-between"} `}>
                  <InputGroup style={{ height: "fit-content", marginTop: "5px" }}  className={width > 550 ? " mb-3" : "d-none"}>
                    <FormControl
                      type="file"
                      name="presentationVideo"
                      id="presentationVideo"
                      accept="video/*"
                      ref={videoInputRef}
                      onChange={(e) => {
                        handleChange(e);
                        handleErrorChange(e);
                      }}
                    />
                    {formData?.presentationVideo && (
                      <InputGroup.Text>
                        <span
                          onClick={() => {
                            videoInputRef.current.value = "";
                            setFormData({
                              ...formData,
                              presentationVideo: null,
                            });
                          }}
                        >
                          ✖
                        </span>
                      </InputGroup.Text>
                    )}
                  </InputGroup>



                  <div className={width < 550 ? "w-75" : "w-50"}  onClick={formData?.presentationVideo ? () => void 0 : handleClickTwo}>
                    {!!formData?.presentationVideo ? (
                      <div
                        className="w-100"
                        style={{ border: "1.3px dashed #0558E2",borderRadius:"8px",overflow:"hidden" ,display:"flex"}}
                      >
                        <video
                          controls
                          style={{
                            width: "100%",

                          }}
                          src={URL.createObjectURL(formData.presentationVideo)}
                        />

                    {formData?.presentationVideo && width < 550 && (
                      <InputGroup.Text className="position-absolute">
                        <span
                          onClick={() => {
                            videoInputRef.current.value = "";
                            setFormData({
                              ...formData,
                              presentationVideo: null,
                            });
                          }}
                        >
                          ✖
                        </span>
                      </InputGroup.Text>
                    )}
                      </div>
                    ) : (
                      <div className="w-100">
                        <img
                          alt="imgg"
                          style={{
                            width: "100%"
                          }}
                          src={placeholderImage}
                        />
                      </div>
                    )}
                  </div>
               
                </div>
               </div>
                {formError.presentationVideo && (
                  <p
                    style={{
                      fontSize: 17,
                      color: "red",
                      margin: "4px 0 4px 0",
                    }}
                  >
                    {formError.presentationVideo}
                  </p>
                )}
              </FormGroup>
              <FormGroup>

                <Form.Check
                  type={"checkbox"}
                  className="f-14-mob"
                  label="Primary Video"
                  id="presentatio-checkbox"
                  value={formData?.isPrimary}
                  onChange={(e) => {
                    setFormData({ ...formData, isPrimary: e.target.checked });
                  }}
                />
              </FormGroup>

              {!levelTwo ? (
                <FormGroup>
                  <Form.Check
                    type={"checkbox"}
                    className="f-14-mob"
                    label="Show Video To Accredited Investors"
                    id="presentatio-checkbox12"
                    value={formData?.isLevelTwo}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        isLevelTwo: e.target.checked,
                      });
                    }}
                  />
                </FormGroup>
              ) : (
                <p style={{ color: "red" }} className="f-14-mob">
                  Note: Video is only visible to Accredited Investors
                </p>
              )}
            </Form>
          </ModalBody>
          <Modal.Footer className="justify-content-center">
            <Button
              variant={isDisabled ? "secondary" : "primary"}
              disabled={isDisabled}
              onClick={() => {
                uploadFile();
              }}
            >
              Upload
            </Button>
          </Modal.Footer>
        </Modal>
        {/* End Modal for Uploading Video Presentation  */}
      </div>
    </>
  );
};

export default PresentationMedia;
