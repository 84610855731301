import { useField } from "formik";
import { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const CustomInput = ({ label, ...props }) => {
    const [field, meta, helpers] = useField(props);
    const handlechange = (e) => {
        helpers.setTouched(true);
        helpers.setValue(e.target.value);
    };
    const [showPass, setShowPass] = useState(false);
    return (
        <>
            <label className="frmlabel">{label}</label>
            {
                (props.name == "password" || props.name == "confirmpassword") ?

                    <div className={`${meta.touched && meta.error ? "input-error" : ""} frmbx`}>
                        <input
                            {...field}
                            name={props.name}
                            placeholder={props.placeholder}
                            type={showPass ? "text" : props.type}
                            autoComplete="off"
                            onChange={(e) => { handlechange(e) }}
                        />
                        <span
                            className="eyebtnfrm"
                            onClick={() => setShowPass((prev) => !prev)}
                        >
                            {!showPass ? (
                                <AiOutlineEye size={20} />
                            ) : (
                                <AiOutlineEyeInvisible size={20} />
                            )}
                        </span>
                    </div>
                    :
                    <input
                        {...field}
                        {...props}
                        className={`${meta.touched && meta.error ? "input-error" : ""} frminp`}
                        autoComplete="off"
                        onChange={(e) => { handlechange(e) }}
                    />
            }
            <div className="ermsg">
                {meta.touched && meta.error && meta.error}
            </div>
        </>
    );
};
export default CustomInput;