import { TextField } from "@mui/material";
import { Avatar, Steps } from 'antd';
import dataUriToBlob from "data-uri-to-blob";
import OTPInput, { ResendOTP } from "otp-input-react";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { AiFillDelete, AiOutlineClose } from "react-icons/ai";
import { MdEditOff } from "react-icons/md";
import { RiImageAddFill } from "react-icons/ri";
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import SignatureCanvas from "react-signature-canvas";
import {
  GetUserLeveltwoProfileApi,
  GetUserProfileApi,
  UpdateUserLeveltwoProfileApi,
  UpdateUserProfileApi,
  addUpdateUserprofileImgApi,
  deleteUserProfileImg,
  getUserprofileImgApi,
  sendotpuserside,
  verifyotpuserside,
} from "../../api/UserProfileApi";
import { dataCleaning, dataWithFiles } from "../../helper/common_functions";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../helper/toast_notification_function";
import { handleGetUserPersonalInfo } from "../../pages/user/Company";
import { ERROR_GETTING_USER_PROFILE, SAVE_GETTING_USER_PROFILE, START_GETTING_USER_PROFILE } from "../../redux/constants/user_getProfile_const";
import allstates from "../../utility/allstates";
import cities from "../../utility/cities";
import country from "../../utility/country";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import useWindowDimensions from "../WindowDimensions";
import { Link } from "react-router-dom";

const UpdateAccountSec = () => {

  const [profile, setProfile] = useState({
    first_name: "",
    last_name: "",
    contact_number: "",
    email: "",
    placeId: "",
  });

  const [selected, setSelected] = useState(null);
  const { width, height } = useWindowDimensions();

  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();
  const {
    ready: ready1,
    value: value1,
    setValue: setValue1,
    suggestions: { status: status1, data: data1 },
    clearSuggestions: clearSuggestions1,
  } = usePlacesAutocomplete();

  const handleSelect = async (address, name) => {
    if (name == "profile") {
      const selectedPlace = data.find((place) => place.description === address);

      if (selectedPlace) {
        setProfile({ ...profile, placeId: selectedPlace.place_id });
      }
      setValue(address, false);
      clearSuggestions();
    }
    else {
      const selectedPlace = data1.find((place) => place.description === address);

      if (selectedPlace) {
        setLevelTwo((prev) => ({
          ...prev,
          place_id: selectedPlace.place_id,
        }));

      }
      setValue1(address, false);
      clearSuggestions1();
    }



    // const results = await getGeocode({ address });
    // const { lat, lng } = await getLatLng(results[0]);
    // setSelected({ lat, lng });
  };
  let dispatch = useDispatch()
  const [modalstep, setModalStep] = useState(0)
  const [signimage, setsignimage] = useState()
  const [profileImg, setProfileImg] = useState(null)
  const [localfile, setLocalFile] = useState();
  const [savebtndis, setsavebtndis] = useState(true);
  const [OTP, setOTP] = useState("");
  const [OTPdata, setOTPdata] = useState({});
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [imgUpdateModal, setImgUpdateModal] = useState(false);
  const [profileover, setprofileover] = useState(false);
  const [loading, setloading] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [AgreeMsg, setAgreeMsg] = useState(false)



  const [countryid, setCountryid] = useState('');
  const [countryname, setcountryname] = useState('');
  const [statename, setstatename] = useState('');
  const [stateid, setstateid] = useState('');
  const [cityname, setcityname] = useState('');
  const [statesdata, setstatesdata] = useState([]);
  const [citiesdata, setcitiesdata] = useState([]);
  let stateopt = [];
  let cityopt = [];

  const { getProfile } = useSelector(
    (state) => state.getUserProfileReducer
  );

  const [levelTwo, setLevelTwo] = useState({
    investor_type: "individual",
    last_two_years_income: false,
    net_worth: false,
    finra_holder: false,
    family_client: false,
    trust_with_total_assets: false,
    bank_investment_advisor_or_company: false,
    employee_benefit_plan: false,
    corp_llc_partner: false,
    accredited_investor: false,
    other_entity_types: false,
    family_office: false,
    all_inputs_are_true: false,
    name_of_investor: "",
    signature: null,
    agree_msg: false,
    name_of_signing_party: "",
    title_of_signing_party: "",
    // address: "",
    // email: "",
    contact_number: "",
    date_signed: new Date().toJSON()
  });

  // const [chkstp, setchkstp] = useState(false)

  const investtype = (e, name) => {
    setLevelTwo((prev) => ({
      ...prev,
      last_two_years_income: false,
      net_worth: false,
      finra_holder: false,
      family_client: false,
      trust_with_total_assets: false,
      bank_investment_advisor_or_company: false,
      employee_benefit_plan: false,
      corp_llc_partner: false,
      accredited_investor: false,
      other_entity_types: false,
      family_office: false,
      all_inputs_are_true: false,
    }));
    Object.keys(levelTwo).forEach(function (key, index) {
      if (name == "investor_type" && key == "investor_type") {
        setLevelTwo((prev) => ({
          ...prev,
          [key]: e.target.value
        }))
      }
      else if (key == name) {
        setLevelTwo((prev) => ({
          ...prev,
          [key]: e.target.checked
        }))
      }
    })
  }


  const [isLevelTwoOpen, setIsLevelTwoOpen] = useState(false);
  const [levelTwoNumber, setlevelTwoNumber] = useState();

  function toggleProfileOpen() {
    setIsProfileOpen((prev) => !prev);
    if (!isProfileOpen) {
      handleGetProfile()
    }
  }

  function toggleUpdateImgModalOpen() {
    setImgUpdateModal((prev) => !prev);
  }

  function toggleLevelTwoOpen() {
    setIsLevelTwoOpen((prev) => !prev);
    setTimeout(() => {
      setModalStep(0)
      setnumbchange(false)
      handleGetProfile()
    }, 700);
  }

  const [otpmodal, setotpmodal] = useState(false);
  const [numbchange, setnumbchange] = useState(false);
  const [otpbar, setotpbar] = useState(true);

  const handleCloseOtpModal = () => {
    setOTP("")
    setotpmodal(false)
  };

  const options = country.map((data) => {
    return (
      {
        value: data.country_id,
        label: data.country_name
      }
    )
  })

  const handleGetProfile = () => {
    GetUserLeveltwoProfileApi()
      .then((res) => {
        setlevelTwoNumber(res.data.data[0].contact_number)
        let dt = res.data.data[0]
        console.log(dt, "dt")
        let fileimage;
        if (dt) {
          fetch(dt.signature).then(async response => {
            const contentType = response.headers.get('content-type')
            const blob = await response.blob()
            fileimage = new File([blob], "signature.jpg", { contentType })
            setLevelTwo({
              investor_type: dt.investor_type,
              last_two_years_income: dt.individual_investor?.last_two_years_income,
              net_worth: dt.individual_investor?.net_worth,
              finra_holder: dt.individual_investor?.finra_holder,
              family_client: dt.entity_investor ? dt.entity_investor.family_client : dt.individual_investor.family_client,
              trust_with_total_assets: dt.entity_investor?.trust_with_total_assets,
              bank_investment_advisor_or_company: dt.entity_investor?.bank_investment_advisor_or_company,
              employee_benefit_plan: dt.entity_investor?.employee_benefit_plan,
              corp_llc_partner: dt.entity_investor?.corp_llc_partner,
              accredited_investor: dt.entity_investor?.accredited_investor,
              other_entity_types: dt.entity_investor?.other_entity_types,
              family_office: dt.entity_investor?.family_office,
              all_inputs_are_true: dt.entity_investor ? dt.entity_investor.all_inputs_are_true : dt.individual_investor.all_inputs_are_true,
              name_of_investor: dt.name_of_investor,
              signature: fileimage,
              name_of_signing_party: dt.name_of_signing_party,
              title_of_signing_party: dt.title_of_signing_party,
              address: dt.address,
              // email: dt.email,
              agree_msg: dt.agree_msg,
              contact_number: dt.contact_number,
              date_signed: dt.updated_at
            })
            setsignimage(dt.signature)
          })

        }
      })
      .catch((err) => {
        console.log("Profile error log", err);
      });

    GetUserProfileApi()
      .then((response) => {
        stateopt = []
        cityopt = []
        setProfile(response.data?.data[0]);
        let filter = options.filter((val) => { return val.label == response.data?.data?.[0]?.country });
        setCountryid(filter[0]?.value);
        setcountryname(filter[0]?.label);
        allstates.map((data) => {
          if (data.country_id == countryid) {
            return (stateopt.push({ value: data.state_id, label: data.state_name }));
          }
        })
        setstatesdata(stateopt)
        let filterstate = stateopt.filter((val) => { return val.label == response.data?.data?.[0]?.state });
        setstateid(filterstate[0]?.value);
        setstatename(filterstate[0]?.label);


      })
      .catch((err) => {
        console.log("Profile error log", err);
      });
  };

  function submitLevelTwo(e) {
    e.preventDefault()

    if (signimage?.length == 0) {
      displayErrorToast("Please do signature..")
    }
    else {
      delete levelTwo.address;
      const updata = dataWithFiles(levelTwo);
      console.log(levelTwo, "check")

      UpdateUserLeveltwoProfileApi(updata)
        .then((res) => {
          if (res.data.success) {
            setModalStep(0);
            setsavebtndis(true)
            handleGetProfile();
            toggleLevelTwoOpen();
            displaySuccessToast("Updated Successfully");
          }
        })
        .catch(async (err) => {
          if (err?.response?.data?.data?.[0]?.key == true) {
            toggleLevelTwoOpen();
            await handleGetProfile();
            sendotp()
          }
          // setModalStep(0);
          // setsavebtndis(true)
          // toggleLevelTwoOpen();
          displayErrorToast(err);
        });
    }
  }

  function submitEditInfo() {
    UpdateUserProfileApi(dataCleaning(profile))
      .then((response) => {
        handleGetProfile();
        toggleProfileOpen();
        displaySuccessToast("Profile Updated");
      })
      .catch((err) => {
        displayErrorToast(err);
      });
  }
  const canvasRef = useRef({});

  const clearSignature = () => {
    if (signimage?.length) {
      setsignimage("")
    }
    else {
      canvasRef.current.clear();
      setsavebtndis(true)
    }
  };
  const upload_check = () => {
    const urlimage = canvasRef.current.toDataURL("image/png");
    const signatureBlob = dataUriToBlob(urlimage);
    const fileimage = new File([signatureBlob], "signature.png", { type: "image/png" })

    setLevelTwo({ ...levelTwo, signature: fileimage });
    setsignimage(URL.createObjectURL(fileimage))
    setsavebtndis(true)
    // if (e.target.files[0].size > 50000) {
    //   alert("File too big! Select File less than 50kb");
    //   e.target.value = "";
    // }
    // else {
    //   setLevelTwo({ ...levelTwo, [e.target.name]: e.target.files[0] });
    //   setsignimage(URL.createObjectURL(e.target.files[0]))
    // }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setModalStep(modalstep + 1)
  };

  console.log(modalstep)

  const handleback = () => {
    setModalStep(modalstep - 1)
  }
  function handleChange(e) {
    setLocalFile(e.target.files[0]);
  }

  const handleGetImg = () => {
    dispatch({
      type: START_GETTING_USER_PROFILE,
    });

    getUserprofileImgApi().then((res) => {
      if (res?.data?.success) {
        dispatch({
          type: SAVE_GETTING_USER_PROFILE,
          payload: res.data?.data ? res.data?.data?.[0] : {},
        });
        setProfileImg(res?.data?.data?.[0]?.profile_picture)
      }
    }).catch((err) => {
      displayErrorToast(err)
      dispatch({
        type: ERROR_GETTING_USER_PROFILE,
        payload: err.response.data.message[0],
      });

    })
  }

  const postImg = () => {
    setBtnLoading(true)
    let obj = { profile_picture: localfile }
    addUpdateUserprofileImgApi(dataWithFiles(obj)).then((res) => {
      if (res?.data?.success) {
        handleGetImg()
        handleGetUserPersonalInfo(dispatch)
        displaySuccessToast("Success")
        setBtnLoading(false)

      }
    }).catch((err) => {
      setBtnLoading(false)
      displayErrorToast(err)
    })

  }


  const updateProfileImg = () => {
    postImg()
    toggleUpdateImgModalOpen()
    handleGetUserPersonalInfo(dispatch)
  }

  const deleteImg = () => {
    deleteUserProfileImg().then((res) => {
      if (res?.data?.success) {
        handleGetImg()
        setLocalFile("")
        displaySuccessToast("Success")
      }
    }).catch((err) => displayErrorToast(err))
  }

  const sendotp = () => {
    setOTP("")
    sendotpuserside().then((res) => {
      setotpmodal(true)
    }).catch(error => displayErrorToast(error))
  }

  const confirmAction = () => {
    // const response = confirm("Are you sure you want to do that?");

    let text;
    if (window.confirm("Are you sure you want to do that?") == true) {
      text = "You pressed OK!";
      handleCloseOtpModal()
    } else {
      text = "You canceled!";
    }


  }


  const verifyotp = () => {
    setloading(true)
    verifyotpuserside(OTPdata).then((res) => {
      handleCloseOtpModal()
      if (res.data.success) {
        displaySuccessToast("Verification Successful")
        handleGetProfile()
        setloading(false)

      }
    }).catch(error => {
      setloading(false)
      displayErrorToast(error)
    })
  }

  const renderButton = (buttonProps) => {
    return <button {...buttonProps} className={`btn btn-md ${buttonProps.disabled ? "btn-secondary" : "btn-primary blbtn"}`}>Resend</button>;
  };
  const renderTime = (remainingtime) => {
    return <span>Try Again in: {remainingtime} Seconds</span>;

  };

  useEffect(() => {
    setOTPdata({ otp_code: OTP })
  }, [OTP])

  useEffect(() => {
    handleGetProfile();
    // handleGetImg()
  }, []);

  useEffect(() => {
    if (levelTwo.all_inputs_are_true) {
      displayErrorToast("Please select any one of the undersigned.")
    }
  }, [levelTwo.all_inputs_are_true])

  const handleCountry = (e) => {
    stateopt = [];
    cityopt = [];
    setCountryid(e.value)
    setcountryname(e.label)
    setstatename("")
    setstateid("")
    setcityname("")
    setProfile({ ...profile, country: e.label, state: "", city: "" })

  }

  const handleState = (e) => {
    cityopt = [];
    setstateid(e.value)
    setstatename(e.label)
    setcityname("")
    setProfile({ ...profile, state: e.label, city: "" })
  }

  const handleCity = (e) => {
    setcityname(e.label)
    setProfile({ ...profile, city: e.label })
  }

  useEffect(() => {
    allstates.map((data) => {
      if (data.country_id == countryid) {
        return (stateopt.push({ value: data.state_id, label: data.state_name }));
      }
    })
    setstatesdata(stateopt)
  }, [countryid, stateid, cityname])

  useEffect(() => {
    cities.map((data) => {
      if (data.state_id == stateid) {
        return (cityopt.push({ value: data.city_id, label: data.city_name }));
      }
    })
    setcitiesdata(cityopt)
  }, [stateid, cityname])





  console.log(levelTwo, levelTwo?.agree_msg == "true" ? true : false)

  return (
    <>
      {
        !profile.is_phone_verified && levelTwoNumber &&
        <div className={`container verify_bar ${!otpbar && "d-none"}`}>
          <div className="">{width > 551 ? (
            <>
              Your Contact Number is not verified. &nbsp;
              <span onClick={sendotp}>Click Here</span>&nbsp;
              to verify your number.
            </>
          )
            :
            (
              <>
                <span onClick={sendotp}>Click Here</span> to verify your number.
              </>
            )
          }

            <div className="close-ic" onClick={() => { setotpbar(false) }}><AiOutlineClose size={`16px`} color="white" /></div>
          </div>
        </div >
      }
      <div className="container pt-5">
        <h1 className={`text-wrap pageMianheading text-white commonH2 text-center text-md-start mt-5 ${!profile.is_phone_verified ? levelTwoNumber ? "" : "pt-5" : "pt-5"}`}>
          Welcome To Investor Presentations.com!
        </h1>
        <p style={{ color: "white" }} className="text-center text-md-start mt-3">
          Below you can upload a profile picture, and upgrade your accreditation status. If you are an Accredited Investor, click the “Upgrade Accreditation” button below, to access investments only available to qualified accredited investors.
        </p>
        <div className="conatiner ">
          {/* card */}
          <div className="userProfileCardDiv">
            {/* image section div */}
            <div className="userProfileDivOne" >
              {getProfile?.profile_picture || profileImg ?
                <div className={`profileImg ${profileover ? "act" : ""}`} onMouseOver={() => { setprofileover(true) }} onMouseLeave={() => { setprofileover(false) }}>


                  <img
                    src={getProfile?.profile_picture || profileImg}
                    alt="media"
                    style={{
                      borderRadius: "15px",
                      background: "white",
                    }}
                  />

                  <div className="icons_box">
                    <MdEditOff color="white" size={`25px`} className="round-button" onClick={toggleUpdateImgModalOpen} />
                    <AiFillDelete size={`25px`} color="red" onClick={deleteImg} className="round-button" />
                  </div>


                </div>

                :
                <div className="profileImg">
                  <label for="getFile1" className="userProfileImgDiv">

                    {localfile ? <img src={URL.createObjectURL(localfile)} style={{ width: "80%" }} alt="profileimg" /> : <RiImageAddFill color="white" size={`35px`} />}

                    <input type="file" accept="image/*" multiple="false" id="getFile1" className="d-none" onChange={handleChange} />
                  </label>

                  {localfile ?
                    <Button onClick={postImg} className="saveBtn">
                      {btnLoading ? <Spinner animation="border" size="sm" /> : "  Save Photo"}
                      {/* <FcOk size={`30px`}  className="round-button" style={{ alignSelf: "center" }} /> */}
                    </Button>
                    :
                    <h6 className="text-white text-center" style={{ fontSize: ".7rem" }}>Upload profile</h6>
                  }
                </div>
              }
              <div className="ms-3 userProfileNameDiv">
                {/* {profile?.level_two_profile_status == "valid" &&
                  <div className="vrfydiv">
                    <p
                      style={{
                        color: "white",
                        marginBottom: "3px",
                      }}
                    >
                      Verified As Accredited
                    </p>

                    <div onClick={toggleLevelTwoOpen}>
                      <MdEditOff color="white" size={16} />
                    </div>
                  </div>
                } */}
                <div style={{ display: "flex", gap: "8px" }}>
                  <h3 style={{ color: "white", margin: "0", textTransform: "capitalize" }}>
                    {`${profile?.first_name} ${profile?.last_name}`}
                  </h3>
                  <Button
                    style={{ background: "transparent", border: "none" }}
                    onClick={toggleProfileOpen}
                  >
                    <MdEditOff color="white" size={22} />
                  </Button>
                </div>
                {
                  profile?.level_two_profile_status !== "valid" ?
                    <p style={{ color: "whitesmoke" }}>Unaccredited Investor</p>
                    :
                    <p style={{ color: "whitesmoke" }}>Accredited Investor</p>
                }
              </div>
            </div>

            {/* Button Section Div */}
            <div
              className="userProfileDivTwo d-flex flex-md-row flex-column"
            >
              <div>
                <Link to={"/product"}>
                  <GradientButton
                    name={"Video Library"}
                    onClick={() => { }}
                  />
                </Link>
              </div>
              <div className="mt-2 mt-md-0">
                {/* <Button
                  style={{
                    background:
                      "linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5)",
                    border: "none",
                    margin: "0px 10px",
                  }}
                >
                  Update Level Two
                </Button> */}
                {profile?.level_two_profile_status !== "valid" ?
                  <GradientButton
                    name={"Upgrade Accreditation"}
                    onClick={toggleLevelTwoOpen}
                  />
                  :
                  <GradientButton
                    name={"Verified As Accredited"}
                    onClick={toggleLevelTwoOpen}
                  />
                }

              </div>
              <>

              </>
            </div>
          </div>
        </div>
        {/* ===================================== modal for edit profile =====================================*/}


        <Modal show={isProfileOpen} onHide={toggleProfileOpen}>
          <Modal.Header closeButton>
            <Modal.Title>Update Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TextField
              sx={{ width: "100%", marginBottom: "1.2rem" }}
              label={"First Name"}
              variant="standard"
              value={profile?.first_name}
              onChange={(e) => {
                setProfile({ ...profile, first_name: e.target.value });
              }}
            />
            <TextField
              sx={{ width: "100%", marginBottom: "1.2rem" }}
              label={"Last Name"}
              variant="standard"
              value={profile?.last_name}
              onChange={(e) => {
                setProfile({ ...profile, last_name: e.target.value });
              }}
            />
            {/* <TextField
              sx={{ width: "100%", marginBottom: "1.2rem" }}
              label={"Contact Number"}
              variant="standard"
              value={profile?.contact_number}
              onChange={(e) => {
                setProfile({ ...profile, contact_number: e.target.value });
              }}
            /> */}
            <TextField
              sx={{ width: "100%", marginBottom: "1.2rem" }}
              type={"email"}
              disabled
              label={"Email"}
              variant="standard"
              value={profile?.email}
              onChange={(e) => {
                setProfile({ ...profile, email: e.target.value });
              }}
            />
            <div className="places-container">
              <Combobox onSelect={(address) => { handleSelect(address, "profile") }}>
                <ComboboxInput
                  name="address"
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                  disabled={!ready}
                  className="form-control"
                  placeholder="Search an address"
                />
                <div style={{ borderWidth: "0 1px 0px 1px", borderStyle: "solid", borderColor: "#ddd" }}>
                  <ComboboxList>
                    {status === "OK" &&
                      data.map(({ place_id, description }) => (
                        <ComboboxOption key={place_id} value={description} style={{ borderBottom: "1px solid #ddd" }} />
                      ))}
                  </ComboboxList>
                </div>
              </Combobox>
            </div>
            <p style={{ fontSize: "14px" }}>
              Current Address: {profile?.address}
            </p>
            {/* <TextField
              sx={{ width: "100%", marginBottom: "1.2rem" }}
              label={"city"}
              variant="standard"
              value={profile?.city}
              onChange={(e) => {
                setProfile({ ...profile, city: e.target.value });
              }}
            /> */}
            {/* <TextField
              sx={{ width: "100%", marginBottom: "1.2rem" }}
              label={"State"}
              variant="standard"
              value={profile?.state}
              onChange={(e) => {
                setProfile({ ...profile, state: e.target.value });
              }}
            /> */}
            {/* <TextField
              sx={{ width: "100%", marginBottom: "1.2rem" }}
              label={"Country"}
              variant="standard"
              value={profile?.country}
              onChange={(e) => {
                setProfile({ ...profile, country: e.target.value });
              }}
            /> */}
          </Modal.Body>
          <Modal.Footer className="mx-auto">
            <GradientButton name={"Save Changes"} onClick={submitEditInfo} />
          </Modal.Footer>
        </Modal>





        {/* OTP VERIFICATION MODAL */}


        <Modal backdrop="static" keyboard={false} show={otpmodal} onHide={handleCloseOtpModal}>
          <Modal.Header closeButton>
            <Modal.Title>Verify Contact</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3 style={{ fontSize: "18px", fontWeight: "400", textAlign: "center", textTransform: "lowercase", lineHeight: "26px" }}>Enter Verification Code Sent On The Following Number: {levelTwoNumber}</h3>
            <div className="otpmodal">
              <OTPInput className="otpinputbox" value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="any" disabled={false} />
              <ResendOTP className="otpresendbox" maxTime={120} onResendClick={sendotp} renderButton={renderButton} renderTime={renderTime} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={confirmAction}>
              Close
            </Button>
            <Button disabled={OTP?.length > 5 ? false : true} variant="primary" className="blbtn" onClick={verifyotp}>
              {loading ? <Spinner animation="border" size="sm" /> : `Verify`}
            </Button>
          </Modal.Footer>
        </Modal>


        {/*===================================== modal for update level two profile ==========================================*/}


        <Modal show={isLevelTwoOpen} onHide={toggleLevelTwoOpen} size="lg" className="stepmodal">
          <Modal.Header style={{ justifyContent: "center", flexDirection: "column" }} closeButton>
            <h4>INVESTOR SUITABILITY QUESTIONNAIRE </h4>
            <Steps
              style={{ width: "100%", alignItems: "center" }}
              size="small"
              current={modalstep}
              items={[
                {
                  title: 'Step 1',
                },
                {
                  title: 'Step 2',
                },
                {
                  title: 'Step 3',
                },
              ]}
            />
          </Modal.Header>
          {
            modalstep == 0 ?
              <>
                <Modal.Body className="updateModalBody" style={{
                  minHeight: "380px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column"
                }}>
                  <p className="updateModalP">
                    This Questionnaire is being distributed to certain individuals and entities which may be offered the opportunity to purchase securities (the <b><i>“Securities”</i></b>) of <b className="text-wrap ">WWW.INVESTORPRESENTATIONS .COM</b>, a Florida limited liability company (the <b><i>“Company”</i></b>). The purpose of this Questionnaire is to assure the Company that all such offers and purchases will meet the standards imposed by the Securities Act of 1933, as amended (the <b><i>“Act”</i></b>), and applicable state securities laws.
                  </p>
                  <p className="updateModalP">
                    All answers will be kept confidential.  However, by signing this Questionnaire, the undersigned agrees that this information may be provided by the Company to its legal and financial advisors, and the Company and such advisors may rely on the information set forth in this Questionnaire for purposes of complying with all applicable securities laws and may present this Questionnaire to such parties as it reasonably deems appropriate if called upon to establish its compliance with such securities laws.  <b>The undersigned represents that the information contained herein is complete and accurate and will notify the Company of any material change in any of such information prior to the undersigned’s investment in the Company.</b>
                  </p>
                  <Form.Label style={{ fontSize: "16px" }}>Select Investor Type:</Form.Label>
                  <Form.Select aria-label="Default select example"
                    value={levelTwo?.investor_type}
                    onChange={(e) => {
                      investtype(e, "investor_type");
                      // setLevelTwo((prev) => ({
                      //   ...prev,
                      //   investor_type: e.target.value,
                      // }));
                    }}
                  >
                    <option value="individual">Individual Investor</option>
                    <option value="entity">Entity Investor</option>
                  </Form.Select>
                </Modal.Body>
                <Modal.Footer>
                  <GradientButton
                    name={"Next"}
                    onClick={() => setModalStep(modalstep + 1)}
                  />
                </Modal.Footer>
              </>
              :
              modalstep == 1 ? levelTwo.investor_type == "individual" ?
                <Form onSubmit={handleSubmit} className="update_form_level_two">
                  <Modal.Body style={{
                    minHeight: "380px",
                  }}>
                    <p className="updateModalP"><b>Accredited Investor Certification</b>.  The undersigned makes one of the following representations regarding its income, net worth, status as a “family client” of a “family office,” and/or certain professional certifications or designations and certain related matters <b><i>and has checked the applicable representation:</i></b></p>
                    <Form.Check
                      type="radio"
                      name="indivi_radio"
                      onChange={(e) => investtype(e, "last_two_years_income")}
                      checked={levelTwo?.last_two_years_income ? true : false}
                      required
                      style={{ marginBottom: "0.6rem", padding: "10px 10px 10px 40px", borderRadius: "10px", backgroundColor: levelTwo?.last_two_years_income ? "#a2e9ff" : "" }}
                      label="The undersigned’s income  during each of the last two years exceeded $200,000 or, if the undersigned is married or has a spousal equivalent , the joint income of the undersigned and the undersigned’s spouse or spousal equivalent, as applicable, during each of the last two years exceed $300,000, and the undersigned reasonably expects the undersigned’s income, from all sources during this year, will exceed $200,000 or, if the undersigned is married or has a spousal equivalent, the joint income of undersigned and the undersigned’s spouse or spousal equivalent, as applicable, from all sources during this year will exceed $300,000."
                    />
                    <Form.Check
                      type="radio"
                      name="indivi_radio"
                      onChange={(e) => investtype(e, "net_worth")}
                      checked={levelTwo?.net_worth ? true : false}
                      required
                      style={{ marginBottom: "0.6rem", padding: "10px 10px 10px 40px", borderRadius: "10px", backgroundColor: levelTwo?.net_worth ? "#a2e9ff" : "" }}
                      label="The undersigned’s net worth,  including the net worth of the undersigned’s spouse or spousal equivalent, as applicable, is in excess of $1,000,000 (excluding the value of the undersigned’s primary residence)."
                    />
                    <Form.Check
                      type="radio"
                      name="indivi_radio"
                      onChange={(e) => investtype(e, "finra_holder")}
                      checked={levelTwo?.finra_holder ? true : false}
                      required
                      style={{ marginBottom: "0.6rem", padding: "10px 10px 10px 40px", borderRadius: "10px", backgroundColor: levelTwo?.finra_holder ? "#a2e9ff" : "" }}
                      label="The undersigned is a holder in good standing of one or more of the following certifications or designations administered by the Financial Industry Regulatory Authority, Inc. (FINRA): the Licensed General Securities Representative (Series 7), Licensed Investment Adviser Representative (Series 65), or Licensed Private Securities Offerings Representative (Series 82)."
                    />
                    <Form.Check
                      type="radio"
                      name="indivi_radio"
                      onChange={(e) => investtype(e, "family_client")}
                      checked={levelTwo?.family_client ? true : false}
                      required
                      style={{ marginBottom: "0.6rem", padding: "10px 10px 10px 40px", borderRadius: "10px", backgroundColor: levelTwo?.family_client ? "#a2e9ff" : "" }}
                      label="`The undersigned is a “family client,” as defined in rule 202(a)(11)(G)-1 under the Investment Advisers Act of 1940, as amended (the “Advisers Act”), of a family office as defined in rule 202(a)(11)(G)-1 under the Advisers Act, (i) with assets under management in excess of $5,000,000, (ii) that is not formed for the specific purpose of acquiring the securities offered, and (iii) whose prospective investment is directed by a person who has such knowledge and experience in financial and business matters that such family office is capable of evaluating the merits and risks of the prospective investment, and whose prospective investment is directed by such family office pursuant to clause (iii) of this sentence.`"
                    />
                    <Form.Check
                      type="radio"
                      name="indivi_radio"
                      onChange={(e) => investtype(e, "all_inputs_are_true")}
                      checked={levelTwo?.all_inputs_are_true ? true : false}
                      required
                      style={{ marginBottom: "0.6rem", padding: "10px 10px 10px 40px", borderRadius: "10px", backgroundColor: levelTwo?.all_inputs_are_true ? "#a2e9ff" : "" }}
                      label="The undersigned cannot make any of the representations set forth above"
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <p style={{ fontSize: "14px" }}><sup>1</sup> For purposes of this Questionnaire, “spousal equivalent” means a cohabitant occupying a relationship generally equivalent to that of a spouse.</p>
                    <div className="w-100" style={width > 500 ? { textAlign: "right" } : { textAlign: "center" }}>

                      <Button
                        type="button"
                        style={{
                          background:
                            "linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5)",
                          border: "none",
                          margin: "0px 10px",
                        }}
                        onClick={handleback}
                      >Back</Button>
                      <Button
                        type="submit"
                        style={{
                          background:
                            "linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5)",
                          border: "none",
                          margin: "0px 10px",
                        }}
                        disabled={levelTwo.all_inputs_are_true}
                      >Next</Button>
                    </div>
                  </Modal.Footer>
                </Form>
                :
                <Form onSubmit={handleSubmit} className="update_form_level_two">
                  <Modal.Body style={{
                    minHeight: "380px",
                  }}>
                    <p className="updateModalP"><b>Accredited Investor Certification</b>.  The undersigned makes one of the following representations regarding its net worth and certain related matters and <b><i>has checked the applicable representation:</i></b></p>
                    <Form.Check
                      type="radio"
                      name="entity_radio"
                      onChange={(e) => investtype(e, "trust_with_total_assets")}
                      checked={levelTwo?.trust_with_total_assets ? true : false}
                      // checked={levelTwo?.trust_with_total_assets}
                      // onChange={(e) => {
                      //   setLevelTwo((prev) => ({
                      //     ...prev,
                      //     trust_with_total_assets: e.target.checked,
                      //   }));
                      // }}
                      required
                      style={{ marginBottom: "0.6rem", padding: "10px 10px 10px 40px", borderRadius: "10px", backgroundColor: levelTwo?.trust_with_total_assets ? "#a2e9ff" : "" }}
                      label="The undersigned is a trust with total assets in excess of $5,000,000 whose purchase is directed by a person with such knowledge and experience in financial and business matters that such person is capable of evaluating the merits and risks of the prospective investment."
                    />
                    <Form.Check
                      type="radio"
                      name="entity_radio"
                      onChange={(e) => investtype(e, "bank_investment_advisor_or_company")}
                      checked={levelTwo?.bank_investment_advisor_or_company ? true : false}
                      required
                      style={{ marginBottom: "0.6rem", padding: "10px 10px 10px 40px", borderRadius: "10px", backgroundColor: levelTwo?.bank_investment_advisor_or_company ? "#a2e9ff" : "" }}
                      label="The undersigned is a bank, an investment adviser registered pursuant to Section 203 of the Advisers Act or registered pursuant to the laws of a state, any investment adviser relying on the exemption from registering with the SEC under Section 203(l) or (m) of the Advisers Act, an insurance company, an investment company registered under the United States Investment Company Act of 1940, as amended, a broker or dealer registered pursuant to Section 15 of the United States Securities Exchange Act of 1934, as amended, a business development company, a Small Business Investment Company licensed by the United States Small Business Administration, a Rural Business Investment Company as defined in Section 384A of the Consolidated Farm and Rural Development Act, as amended, a plan with total assets in excess of $5,000,000 established and maintained by a state for the benefit of its employees, or a private business development company as defined in Section 202(a)(22) of the Advisers Act."
                    />
                    <Form.Check
                      type="radio"
                      name="entity_radio"
                      onChange={(e) => investtype(e, "employee_benefit_plan")}
                      checked={levelTwo?.employee_benefit_plan ? true : false}
                      required
                      style={{ marginBottom: "0.6rem", padding: "10px 10px 10px 40px", borderRadius: "10px", backgroundColor: levelTwo?.employee_benefit_plan ? "#a2e9ff" : "" }}
                      label="The undersigned is an employee benefit plan and either all investment decisions are made by a bank, savings and loan association, insurance company, or registered investment advisor, or the undersigned has total assets in excess of $5,000,000 or, if such plan is a self-directed plan, investment decisions are made solely by persons who are accredited investors."
                    />
                    <Form.Check
                      type="radio"
                      name="entity_radio"
                      onChange={(e) => investtype(e, "corp_llc_partner")}
                      checked={levelTwo?.corp_llc_partner ? true : false}
                      required
                      style={{ marginBottom: "0.6rem", padding: "10px 10px 10px 40px", borderRadius: "10px", backgroundColor: levelTwo?.corp_llc_partner ? "#a2e9ff" : "" }}
                      label="The undersigned is a corporation, limited liability company, partnership, business trust, not formed for the purpose of acquiring the Securities, or an organization described in Section 501(c)(3) of the Internal Revenue Code of 1986, as amended (the “Code”), in each case with total assets in excess of $5,000,000."
                    />
                    <Form.Check
                      type="radio"
                      name="entity_radio"
                      onChange={(e) => investtype(e, "accredited_investor")}
                      checked={levelTwo?.accredited_investor ? true : false}
                      required
                      style={{ marginBottom: "0.6rem", padding: "10px 10px 10px 40px", borderRadius: "10px", backgroundColor: levelTwo?.accredited_investor ? "#a2e9ff" : "" }}
                      label="The undersigned is an entity in which all of the equity owners (in the case of a revocable living trust, its grantor(s)) qualify under any of the above subparagraphs, or, if an individual, each such individual has a net worth,2 either individually or upon a joint basis with such individual’s spouse or spousal equivalent, as applicable, in excess of $1,000,000 (within the meaning of such terms as used in the definition of “accredited investor” contained in Rule 501 under the Act), or has had an individual income1 in excess of $200,000 for each of the two most recent years, or a joint income with such individual’s spouse or spousal equivalent, as applicable, in excess of $300,000 in each of those years, and has a reasonable expectation of reaching the same income level in the current year. "
                    />
                    <Form.Check
                      type="radio"
                      name="entity_radio"
                      onChange={(e) => investtype(e, "other_entity_types")}
                      checked={levelTwo?.other_entity_types ? true : false}
                      required
                      style={{ marginBottom: "0.6rem", padding: "10px 10px 10px 40px", borderRadius: "10px", backgroundColor: levelTwo?.other_entity_types ? "#a2e9ff" : "" }}
                      label="The undersigned is an entity, of a type not listed in any of the paragraphs above, which was not formed for the specific purpose of acquiring the securities offered, owning investments in excess of $5,000,000."
                    />
                    <Form.Check
                      type="radio"
                      name="entity_radio"
                      onChange={(e) => investtype(e, "family_office")}
                      checked={levelTwo?.family_office ? true : false}
                      required
                      style={{ marginBottom: "0.6rem", padding: "10px 10px 10px 40px", borderRadius: "10px", backgroundColor: levelTwo?.family_office ? "#a2e9ff" : "" }}
                      label="The undersigned is a “family office,” as defined in rule 202(a)(11)(G)-1 under the Advisers Act, (i) with assets under management in excess of $5,000,000, (ii) that is not formed for the specific purpose of acquiring the securities offered, and (iii) whose prospective investment is directed by a person who has such knowledge and experience in financial and business matters that such family office is capable of evaluating the merits and risks of the prospective investment."
                    />
                    <Form.Check
                      type="radio"
                      name="entity_radio"
                      onChange={(e) => investtype(e, "family_client")}
                      checked={levelTwo?.family_client ? true : false}
                      required
                      style={{ marginBottom: "0.6rem", padding: "10px 10px 10px 40px", borderRadius: "10px", backgroundColor: levelTwo?.family_client ? "#a2e9ff" : "" }}
                      label="The undersigned is a “family client,” as defined in rule 202(a)(11)(G)-1 under the Advisers Act, of a family office meeting the requirements in the above paragraph and whose prospective investment is directed by such family office pursuant to clause (iii) of the above paragraph."
                    />
                    <Form.Check
                      type="radio"
                      name="entity_radio"
                      onChange={(e) => investtype(e, "all_inputs_are_true")}
                      checked={levelTwo?.all_inputs_are_true ? true : false}
                      required
                      style={{ marginBottom: "0.6rem", padding: "10px 10px 10px 40px", borderRadius: "10px", backgroundColor: levelTwo?.all_inputs_are_true ? "#a2e9ff" : "" }}
                      label="The undersigned cannot make any of the representations set forth above."
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="w-100" style={width > 500 ? { textAlign: "right" } : { textAlign: "center" }}>

                      <Button
                        type="button"
                        style={{
                          background:
                            "linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5)",
                          border: "none",
                          margin: "0px 10px",
                        }}
                        onClick={handleback}
                      >Back</Button>
                      <Button
                        type="submit"
                        style={{
                          background:
                            "linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5)",
                          border: "none",
                          margin: "0px 10px",
                        }}
                        disabled={levelTwo.all_inputs_are_true}
                      >Next</Button>
                    </div>
                  </Modal.Footer>
                </Form>
                :
                modalstep == 2 ?
                  <Form onSubmit={(e) => { e.preventDefault() }}>
                    <Modal.Body style={{
                      minHeight: "380px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column"
                    }}>
                      <p><b>IN WITNESS WHEREOF</b>, the undersigned has executed this Investor Suitability Questionnaire as of the date written below.</p>


                      <Form.Group className="mb-3">
                        <Form.Label>Name of Investor</Form.Label>
                        <Form.Control required
                          value={levelTwo?.name_of_investor}
                          onChange={(e) => {
                            setLevelTwo((prev) => ({
                              ...prev,
                              name_of_investor: e.target.value,
                            }));
                          }}
                          type="text" />
                      </Form.Group>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Signature</Form.Label>
                            <div style={{ border: "1px solid #000", width: "100%", height: "200px", borderRadius: "8px" }}>
                              {
                                signimage ?
                                  <div className="">
                                    <img
                                      style={{
                                        width: "100%",
                                        height: "200px",
                                        objectFit: "contain"
                                      }}
                                      src={signimage}
                                    />
                                  </div> :
                                  <SignatureCanvas
                                    ref={canvasRef}
                                    penColor="black"
                                    canvasProps={{ width: 300, height: 200, className: 'signature-canvas' }}
                                    onEnd={() => { setsavebtndis(false) }}
                                  />
                              }
                            </div>
                            <div className="" style={width > 400 ? { textAlign: "left" } : { textAlign: "center " }}>

                              <button type="button" onClick={clearSignature}
                                className="btn btn-primary signatureBtn"
                                style={{
                                  background:
                                    "linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5)",
                                  border: "none",
                                  margin: "10px 0 0 0"
                                }}
                              >Clear Signature</button>
                              <button type="button" onClick={upload_check} disabled={savebtndis}
                                className="btn btn-primary signatureBtn"
                                style={{
                                  background:
                                    "linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5)",
                                  border: "none",
                                  margin: "10px 0px 0 10px",
                                }}
                              >save Signature</button>
                            </div>
                            {/* <Form.Control required={!signimage} name="signature" onChange={(e) => { upload_check(e) }} type="file" accept="image/jpg,image/jpeg,image/png" /> */}
                            {/* {signimage && (
                              <div className="w-50 mt-3">
                                <img
                                  style={{
                                    width: "100%",
                                    height: "100px",
                                    objectFit: "contain",
                                  }}
                                  src={signimage}
                                />
                              </div>
                            )} */}
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Contact Number <span style={{ fontSize: "14px" }}>(We will send OTP for number verification.)</span></Form.Label>
                            <PhoneInput
                              className="form-control hide_increament_button phnNoInput pb-2 d-flex"
                              placeholder="Enter phone number"
                              value={levelTwo.contact_number}
                              defaultCountry={!levelTwo.contact_number && "US"}
                              countryOptionsOrder={['US', 'CA', 'AU', 'IN']}
                              onChange={(value) => {
                                if (value == levelTwoNumber) {
                                  setnumbchange(false)
                                }
                                else {
                                  setnumbchange(true)
                                }
                                setLevelTwo((prev) => ({
                                  ...prev,
                                  contact_number: value,
                                }));
                              }}
                            />
                          </Form.Group>
                          {!profile.is_phone_verified && !numbchange && <button onClick={sendotp} className="ms-auto d-flex blbtn btn btn-primary btn-md">Verify</button>}
                          {/*
                          <Form.Check
                          type="radio"
                          name="entity_radio"
                          onChange={(e) => investtype(e, "family_office")}
                          checked={levelTwo?.family_office ? true : false}
                          required
                          style={{ marginBottom: "0.6rem", padding: "10px 10px 10px 40px", borderRadius: "10px", backgroundColor: levelTwo?.family_office ? "#a2e9ff" : "" }}
                          label="The undersigned is a “family office,” as defined in rule 202(a)(11)(G)-1 under the Advisers Act, (i) with assets under management in excess of $5,000,000, (ii) that is not formed for the specific purpose of acquiring the securities offered, and (iii) whose prospective investment is directed by a person who has such knowledge and experience in financial and business matters that such family office is capable of evaluating the merits and risks of the prospective investment."
                        /> */}

                          <Form.Check
                            Checked={levelTwo?.agree_msg}
                            type="checkbox"
                            className="agree"
                            name="entity_radio"
                            // defaultChecked={true}
                            // onChange={(e) =>  handlerAgreeMsg()}
                            onChange={(e) => {
                              // console.log(e.target.checked  ,  levelTwo?.agree_msg == "true" ? true : false)
                              setLevelTwo((prev) => ({
                                ...prev,
                                agree_msg: e.target.checked,
                              }));
                            }}
                            required
                            style={{ marginBottom: "0.6rem", padding: "10px 0px 0px 30px" }}
                            label="I Agree” to receive text messages. Just in case there is a law or compliance issue."
                          />
                        </Col>
                      </Row>
                      <Form.Group className="mb-3">
                        <Form.Label>Name of Signing Party (Please Print)</Form.Label>
                        <Form.Control required
                          value={levelTwo?.name_of_signing_party}
                          onChange={(e) => {
                            setLevelTwo((prev) => ({
                              ...prev,
                              name_of_signing_party: e.target.value,
                            }));
                          }} type="text" />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Financial Advisor</Form.Label>
                        <br></br>
                        <Form.Check
                          inline
                          checked={levelTwo.title_of_signing_party == "yes" ? true : false}
                          name="financial_advisor"
                          value="yes"
                          onChange={(e) => {
                            setLevelTwo((prev) => ({
                              ...prev,
                              title_of_signing_party: e.target.value,
                            }));
                          }}
                          label="Yes"
                          type="radio"
                        />
                        <Form.Check
                          inline
                          checked={levelTwo.title_of_signing_party == "no" ? true : false}
                          name="financial_advisor"
                          value="no"
                          onChange={(e) => {
                            setLevelTwo((prev) => ({
                              ...prev,
                              title_of_signing_party: e.target.value,
                            }));
                          }}
                          label="No"
                          type="radio"
                        />
                        <Form.Check
                          inline
                          checked={levelTwo.title_of_signing_party == "self" ? true : false}
                          value="self"
                          name="financial_advisor"
                          onChange={(e) => {
                            setLevelTwo((prev) => ({
                              ...prev,
                              title_of_signing_party: e.target.value,
                            }));
                          }}
                          label="Self"
                          type="radio"
                        />

                        {/* <Form.Control required
                          type="text"
                          value={levelTwo?.title_of_signing_party}
                          onChange={(e) => {
                            setLevelTwo((prev) => ({
                              ...prev,
                              title_of_signing_party: e.target.value,
                            }));
                          }} /> */}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Mailing Address</Form.Label>

                        <div className="places-container">
                          <Combobox onSelect={(address) => { handleSelect(address, "leveltwoform") }}>
                            <ComboboxInput
                              name="address"
                              value={value1}
                              onChange={(e) => {
                                setValue1(e.target.value);
                              }}
                              disabled={!ready1}
                              className="form-control"
                              placeholder="Search an address"
                            />
                            <div style={{ borderWidth: "0 1px 0px 1px", borderStyle: "solid", borderColor: "#ddd" }}>
                              <ComboboxList>
                                {status1 === "OK" &&
                                  data1.map(({ place_id, description }) => (
                                    <ComboboxOption key={place_id} value={description} style={{ borderBottom: "1px solid #ddd" }} />
                                  ))}
                              </ComboboxList>
                            </div>
                          </Combobox>
                        </div>
                        <p style={{ fontSize: "14px" }}>
                          Current Address: {levelTwo?.address}
                        </p>
                        {/* <Form.Control required
                          value={levelTwo?.address}
                          onChange={(e) => {
                            setLevelTwo((prev) => ({
                              ...prev,
                              address: e.target.value,
                            }));
                          }} type="text" /> */}
                      </Form.Group>
                      {/* <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control required
                          value={levelTwo?.email}
                          onChange={(e) => {
                            setLevelTwo((prev) => ({
                              ...prev,
                              email: e.target.value,
                            }));
                          }} type="email" />
                      </Form.Group> */}
                      <Form.Group className="mb-3">
                        <Form.Label>Date Signed</Form.Label>
                        <Form.Control required disabled
                          value={levelTwo?.date_signed}
                          onChange={(e) => {
                            setLevelTwo((prev) => ({
                              ...prev,
                              date_signed: e.target.value,
                            }));
                          }} type="text" />
                      </Form.Group>
                    </Modal.Body>

                    <Modal.Footer>
                      <p style={{ fontSize: "14px" }}>
                        <sup>1</sup> For purposes of this Questionnaire, “income” means adjusted gross income, as reported for federal income tax purposes, increased by the following amounts:  (a) the amount of any tax exempt interest income received, (b) the amount of losses claimed as a limited partner in a limited partnership, (c) any deduction claimed for depletion, (d) amounts contributed to an IRA or Keogh retirement plan, (e) alimony paid, and (f) any amounts by which income from long-term capital gains has been reduced in arriving at adjusted gross income pursuant to the provisions of Section 1202 of the Code.
                      </p>
                      <p style={{ fontSize: "14px" }}>
                        <sup>2</sup> For purposes of this Questionnaire, “net worth” means the excess of total assets, excluding your primary residence, at fair market value over total liabilities, including your mortgage or any other liability secured by your primary residence only if and to the extent that it exceeds the value of your primary residence. Net worth should include the value of any other shares of stock or options held by you and your spouse or spousal equivalent and any personal property owned by you or your spouse or spousal equivalent (e.g. furniture, jewelry, other valuables, etc.).  For the purposes of calculating joint net worth: joint net worth can be the aggregate net worth of you and your spouse or spousal equivalent; assets need not be held jointly to be included in the calculation.
                      </p>

                      <div className="w-100" style={width > 500 ? { textAlign: "right" } : { textAlign: "center" }}>

                        <Button
                          type="button"
                          style={{
                            background:
                              "linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5)",
                            border: "none",
                            margin: "0px 10px",
                          }}
                          onClick={() => { setModalStep(modalstep - 1); }}
                        >Back</Button>
                        <Button
                          type="submit"
                          style={{
                            background:
                              "linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5)",
                            border: "none",
                            margin: "0px 10px",
                          }}
                          onClick={submitLevelTwo}
                        >Apply</Button>
                      </div>
                    </Modal.Footer>
                  </Form> : ""
          }
        </Modal>





        {/*===================================== MOdal for update user profile image =====================================*/}


        <Modal show={imgUpdateModal} onHide={toggleUpdateImgModalOpen}>
          <Modal.Header >
            <Modal.Title>Update profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form action="update" id="edit-form">
              <input className="form-control" type="hidden" name="id" />
              <div className="d-flex flex-column">
                {profileImg && <Avatar src={profileImg} size={150} style={{ alignSelf: "center", boxShadow: "1px 1px 3px 1px black", border: "1px solid black" }} />}

                <input type="file" onChange={handleChange} />
              </div>




            </form>
          </Modal.Body>
          <Modal.Footer>

            <Button variant="primary" onClick={updateProfileImg}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default UpdateAccountSec;

const GradientButton = ({ name, onClick }) => (
  <Button
    style={{
      background: "linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5)",
      border: "none",
      margin: "0px 10px",
    }}
    onClick={onClick}
  >
    {name}
  </Button>
);



