import React, { useEffect, useMemo, useState } from "react";
import { Collapse, Popconfirm, Space, Typography } from "antd";
import moment from "moment";
import Table from "react-bootstrap/Table";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AiFillSave } from "react-icons/ai";
import { FaDownload } from "react-icons/fa";
import { SavedDocs, removeSavedDocs } from "../../../api/CompaniesForUserApis";
import { displayErrorToast, displaySuccessToast } from "../../../helper/toast_notification_function";
import { FaFileCircleMinus } from "react-icons/fa6";
import { RiCloseFill } from "react-icons/ri";

export default function DataRoomInProfile({ dataRoom, saved_files }) {
  const { Panel } = Collapse;
  const { Title, Text } = Typography;
  const [open, setopen] = useState(true);

  const [savedfilearr, setsavedfilearr] = useState()

  const handleSaved = (id) => {
    SavedDocs(id).then((res) => {
      console.log(res)
      if (res?.data?.success) {
        displaySuccessToast("Document Saved")
        setsavedfilearr((ele) => {
          return [...ele, id]
        })
      }
    }).catch((error) => {
      displayErrorToast("Something Bad Happened. Please Try Again")
    })
  }
  const handleUnsaved = (id) => {
    removeSavedDocs(id).then((res) => {
      console.log(res)
      if (res?.data?.success) {
        displaySuccessToast("Document Unsaved")
        setsavedfilearr(savedfilearr.filter((data) => { return data !== id }))
      }
    }).catch((error) => {
      console.log(error)
      displayErrorToast("Something Bad Happened. Please Try Again")
    })
  }

  const [rowData, setRowData] = useState([]);

  const [columnDefs, setcolumnDefs] = useState([
    {
      headerName: 'S.No',
      sortable: true,
      width: 85,
      valueFormatter: (params) => {
        return `${parseInt(params.node.id) + 1}`;
      },
    },
    {
      headerName: "Name",
      field: "filename",
      flex: 1,
      minWidth: 160,
      menuTabs: ['filterMenuTab', 'columnsMenuTab'],
    },
    {
      field: "Last Modified",
      menuTabs: ['filterMenuTab', 'columnsMenuTab'],
      sortable: true,
      minWidth: 140,
      cellRenderer: p => {
        return (
          <div>{moment.utc(p.data?.created_at).format("MM/DD/YYYY")}</div>
        )
      }
    },
    {
      headerName: "Type",
      field: "type",
      menuTabs: ['filterMenuTab', 'columnsMenuTab'],
      width: 150,
      maxWidth: 200,
      cellRenderer: p => {
        return (
          <div>Doc</div>
        )
      }
    },
    (saved_files && {
      headerName: "Actions",
      autoHeight: true,
      cellRenderer: p => {
        console.log(savedfilearr, "porf")
        const isFileSaved = savedfilearr?.includes(p?.data?.id);

        console.log(isFileSaved, "sve")
        return (
          <div className="doc_btns">
            <a href={p?.data?.file} download title="Download" className="dwnlddoc" onClick={() => { }}>
              <FaDownload size={17} color="#fff" />
            </a>
            {isFileSaved ? <Popconfirm
              title="Are you sure to remove from saved documents?"
              onConfirm={() => { handleUnsaved(p?.data?.id) }}
              okText="Yes"
              cancelText="No"
            >
              <button title="Save" className="savedoc">
                <FaFileCircleMinus size={18} color="red" />
              </button>
            </Popconfirm> :
              <Popconfirm
                title="Are you sure to save this document?"
                onConfirm={() => { handleSaved(p?.data?.id) }}
                okText="Yes"
                cancelText="No"
              >
                <button title="Save" className="savedoc">
                  <AiFillSave size={19} color="#fff" />
                </button>
              </Popconfirm>
            }
          </div>
        )
      }
    }),



  ]);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      filter: true,
      menuTabs: true,
    };
  }, []);

  useEffect(() => {
    setsavedfilearr(saved_files)
  }, [saved_files])


  useEffect(() => {
    setRowData(dataRoom)
  }, [dataRoom, savedfilearr])

  return (
    <div className="dataRoomViewPortfolio">

      <div className="faqs-asked">

        <div className={`faq-accordion ${open ? "act" : ""}`}>
          <h4 onClick={() => { setopen(!open) }}>
            Company Data
            <span className="close-ico">
              <RiCloseFill size={20} color="#fff" />
            </span>
          </h4>
          <div className="faq-content">
            <div className="ag-theme-alpine" style={{ height: 400, width: "100%" }}>
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={10}
                animateRows={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
