import { ERROR_GETTING_FAQ_PDF_FILE, SAVE_GETTING_FAQ_PDF_FILE, START_GETTING_FAQ_PDF_FILE } from "../../constants/get_file_constant";

  
  const INITIAL_STATE = {
    faqPdf: [],
    error: null,
    isLoading: false,
  };
  
  export const getFaqPdfReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case START_GETTING_FAQ_PDF_FILE:
        return {
          ...state,
  
          faqPdf: payload,
          error: null,
          isLoading: true,
        };
      case SAVE_GETTING_FAQ_PDF_FILE:
        return {
          ...state,
  
          faqPdf: payload,
          error: null,
          isLoading: false,
        };
      case ERROR_GETTING_FAQ_PDF_FILE:
        return {
          ...state,
  
          faqPdf: null,
          error: payload,
          isLoading: false,
        };
  
      default:
        return state;
    }
  };
  