const cities = [
    {"city_id":"1","city_name":"Bombuflat","state_id":"1"},
    {"city_id":"2","city_name":"Garacharma","state_id":"1"},
    {"city_id":"3","city_name":"Port Blair","state_id":"1"},
    {"city_id":"4","city_name":"Rangat","state_id":"1"},
    {"city_id":"5","city_name":"Addanki","state_id":"2"},
    {"city_id":"6","city_name":"Adivivaram","state_id":"2"},
    {"city_id":"7","city_name":"Adoni","state_id":"2"},
    {"city_id":"8","city_name":"Aganampudi","state_id":"2"},
    {"city_id":"9","city_name":"Ajjaram","state_id":"2"},
    {"city_id":"10","city_name":"Akividu","state_id":"2"},
    {"city_id":"11","city_name":"Akkarampalle","state_id":"2"},
    {"city_id":"12","city_name":"Akkayapalle","state_id":"2"},
    {"city_id":"13","city_name":"Akkireddipalem","state_id":"2"},
    {"city_id":"14","city_name":"Alampur","state_id":"2"},
    {"city_id":"15","city_name":"Amalapuram","state_id":"2"},
    {"city_id":"16","city_name":"Amudalavalasa","state_id":"2"},
    {"city_id":"17","city_name":"Amur","state_id":"2"},
    {"city_id":"18","city_name":"Anakapalle","state_id":"2"},
    {"city_id":"19","city_name":"Anantapur","state_id":"2"},
    {"city_id":"20","city_name":"Andole","state_id":"2"},
    {"city_id":"21","city_name":"Atmakur","state_id":"2"},
    {"city_id":"22","city_name":"Attili","state_id":"2"},
    {"city_id":"23","city_name":"Avanigadda","state_id":"2"},
    {"city_id":"24","city_name":"Badepalli","state_id":"2"},
    {"city_id":"25","city_name":"Badvel","state_id":"2"},
    {"city_id":"26","city_name":"Balapur","state_id":"2"},
    {"city_id":"27","city_name":"Bandarulanka","state_id":"2"},
    {"city_id":"28","city_name":"Banganapalle","state_id":"2"},
    {"city_id":"29","city_name":"Bapatla","state_id":"2"},
    {"city_id":"30","city_name":"Bapulapadu","state_id":"2"},
    {"city_id":"31","city_name":"Belampalli","state_id":"2"},
    {"city_id":"32","city_name":"Bestavaripeta","state_id":"2"},
    {"city_id":"33","city_name":"Betamcherla","state_id":"2"},
    {"city_id":"34","city_name":"Bhattiprolu","state_id":"2"},
    {"city_id":"35","city_name":"Bhimavaram","state_id":"2"},
    {"city_id":"36","city_name":"Bhimunipatnam","state_id":"2"},
    {"city_id":"37","city_name":"Bobbili","state_id":"2"},
    {"city_id":"38","city_name":"Bombuflat","state_id":"2"},
    {"city_id":"39","city_name":"Bommuru","state_id":"2"},
    {"city_id":"40","city_name":"Bugganipalle","state_id":"2"},
    {"city_id":"41","city_name":"Challapalle","state_id":"2"},
    {"city_id":"42","city_name":"Chandur","state_id":"2"},
    {"city_id":"43","city_name":"Chatakonda","state_id":"2"},
    {"city_id":"44","city_name":"Chemmumiahpet","state_id":"2"},
    {"city_id":"45","city_name":"Chidiga","state_id":"2"},
    {"city_id":"46","city_name":"Chilakaluripet","state_id":"2"},
    {"city_id":"47","city_name":"Chimakurthy","state_id":"2"},
    {"city_id":"48","city_name":"Chinagadila","state_id":"2"},
    {"city_id":"49","city_name":"Chinagantyada","state_id":"2"},
    {"city_id":"50","city_name":"Chinnachawk","state_id":"2"},
    {"city_id":"51","city_name":"Chintalavalasa","state_id":"2"},
    {"city_id":"52","city_name":"Chipurupalle","state_id":"2"},
    {"city_id":"53","city_name":"Chirala","state_id":"2"},
    {"city_id":"54","city_name":"Chittoor","state_id":"2"},
    {"city_id":"55","city_name":"Chodavaram","state_id":"2"},
    {"city_id":"56","city_name":"Choutuppal","state_id":"2"},
    {"city_id":"57","city_name":"Chunchupalle","state_id":"2"},
    {"city_id":"58","city_name":"Cuddapah","state_id":"2"},
    {"city_id":"59","city_name":"Cumbum","state_id":"2"},
    {"city_id":"60","city_name":"Darnakal","state_id":"2"},
    {"city_id":"61","city_name":"Dasnapur","state_id":"2"},
    {"city_id":"62","city_name":"Dauleshwaram","state_id":"2"},
    {"city_id":"63","city_name":"Dharmavaram","state_id":"2"},
    {"city_id":"64","city_name":"Dhone","state_id":"2"},
    {"city_id":"65","city_name":"Dommara Nandyal","state_id":"2"},
    {"city_id":"66","city_name":"Dowlaiswaram","state_id":"2"},
    {"city_id":"67","city_name":"East Godavari Dist.","state_id":"2"},
    {"city_id":"68","city_name":"Eddumailaram","state_id":"2"},
    {"city_id":"69","city_name":"Edulapuram","state_id":"2"},
    {"city_id":"70","city_name":"Ekambara kuppam","state_id":"2"},
    {"city_id":"71","city_name":"Eluru","state_id":"2"},
    {"city_id":"72","city_name":"Enikapadu","state_id":"2"},
    {"city_id":"73","city_name":"Fakirtakya","state_id":"2"},
    {"city_id":"74","city_name":"Farrukhnagar","state_id":"2"},
    {"city_id":"75","city_name":"Gaddiannaram","state_id":"2"},
    {"city_id":"76","city_name":"Gajapathinagaram","state_id":"2"},
    {"city_id":"77","city_name":"Gajularega","state_id":"2"},
    {"city_id":"78","city_name":"Gajuvaka","state_id":"2"},
    {"city_id":"79","city_name":"Gannavaram","state_id":"2"},
    {"city_id":"80","city_name":"Garacharma","state_id":"2"},
    {"city_id":"81","city_name":"Garimellapadu","state_id":"2"},
    {"city_id":"82","city_name":"Giddalur","state_id":"2"},
    {"city_id":"83","city_name":"Godavarikhani","state_id":"2"},
    {"city_id":"84","city_name":"Gopalapatnam","state_id":"2"},
    {"city_id":"85","city_name":"Gopalur","state_id":"2"},
    {"city_id":"86","city_name":"Gorrekunta","state_id":"2"},
    {"city_id":"87","city_name":"Gudivada","state_id":"2"},
    {"city_id":"88","city_name":"Gudur","state_id":"2"},
    {"city_id":"89","city_name":"Guntakal","state_id":"2"},
    {"city_id":"90","city_name":"Guntur","state_id":"2"},
    {"city_id":"91","city_name":"Guti","state_id":"2"},
    {"city_id":"92","city_name":"Hindupur","state_id":"2"},
    {"city_id":"93","city_name":"Hukumpeta","state_id":"2"},
    {"city_id":"94","city_name":"Ichchapuram","state_id":"2"},
    {"city_id":"95","city_name":"Isnapur","state_id":"2"},
    {"city_id":"96","city_name":"Jaggayyapeta","state_id":"2"},
    {"city_id":"97","city_name":"Jallaram Kamanpur","state_id":"2"},
    {"city_id":"98","city_name":"Jammalamadugu","state_id":"2"},
    {"city_id":"99","city_name":"Jangampalli","state_id":"2"},
    {"city_id":"100","city_name":"Jarjapupeta","state_id":"2"},
    {"city_id":"101","city_name":"Kadiri","state_id":"2"},
    {"city_id":"102","city_name":"Kaikalur","state_id":"2"},
    {"city_id":"103","city_name":"Kakinada","state_id":"2"},
    {"city_id":"104","city_name":"Kallur","state_id":"2"},
    {"city_id":"105","city_name":"Kalyandurg","state_id":"2"},
    {"city_id":"106","city_name":"Kamalapuram","state_id":"2"},
    {"city_id":"107","city_name":"Kamareddi","state_id":"2"},
    {"city_id":"108","city_name":"Kanapaka","state_id":"2"},
    {"city_id":"109","city_name":"Kanigiri","state_id":"2"},
    {"city_id":"110","city_name":"Kanithi","state_id":"2"},
    {"city_id":"111","city_name":"Kankipadu","state_id":"2"},
    {"city_id":"112","city_name":"Kantabamsuguda","state_id":"2"},
    {"city_id":"113","city_name":"Kanuru","state_id":"2"},
    {"city_id":"114","city_name":"Karnul","state_id":"2"},
    {"city_id":"115","city_name":"Katheru","state_id":"2"},
    {"city_id":"116","city_name":"Kavali","state_id":"2"},
    {"city_id":"117","city_name":"Kazipet","state_id":"2"},
    {"city_id":"118","city_name":"Khanapuram Haveli","state_id":"2"},
    {"city_id":"119","city_name":"Kodar","state_id":"2"},
    {"city_id":"120","city_name":"Kollapur","state_id":"2"},
    {"city_id":"121","city_name":"Kondapalem","state_id":"2"},
    {"city_id":"122","city_name":"Kondapalle","state_id":"2"},
    {"city_id":"123","city_name":"Kondukur","state_id":"2"},
    {"city_id":"124","city_name":"Kosgi","state_id":"2"},
    {"city_id":"125","city_name":"Kothavalasa","state_id":"2"},
    {"city_id":"126","city_name":"Kottapalli","state_id":"2"},
    {"city_id":"127","city_name":"Kovur","state_id":"2"},
    {"city_id":"128","city_name":"Kovurpalle","state_id":"2"},
    {"city_id":"129","city_name":"Kovvur","state_id":"2"},
    {"city_id":"130","city_name":"Krishna","state_id":"2"},
    {"city_id":"131","city_name":"Kuppam","state_id":"2"},
    {"city_id":"132","city_name":"Kurmannapalem","state_id":"2"},
    {"city_id":"133","city_name":"Kurnool","state_id":"2"},
    {"city_id":"134","city_name":"Lakshettipet","state_id":"2"},
    {"city_id":"135","city_name":"Lalbahadur Nagar","state_id":"2"},
    {"city_id":"136","city_name":"Machavaram","state_id":"2"},
    {"city_id":"137","city_name":"Macherla","state_id":"2"},
    {"city_id":"138","city_name":"Machilipatnam","state_id":"2"},
    {"city_id":"139","city_name":"Madanapalle","state_id":"2"},
    {"city_id":"140","city_name":"Madaram","state_id":"2"},
    {"city_id":"141","city_name":"Madhuravada","state_id":"2"},
    {"city_id":"142","city_name":"Madikonda","state_id":"2"},
    {"city_id":"143","city_name":"Madugule","state_id":"2"},
    {"city_id":"144","city_name":"Mahabubnagar","state_id":"2"},
    {"city_id":"145","city_name":"Mahbubabad","state_id":"2"},
    {"city_id":"146","city_name":"Malkajgiri","state_id":"2"},
    {"city_id":"147","city_name":"Mamilapalle","state_id":"2"},
    {"city_id":"148","city_name":"Mancheral","state_id":"2"},
    {"city_id":"149","city_name":"Mandapeta","state_id":"2"},
    {"city_id":"150","city_name":"Mandasa","state_id":"2"},
    {"city_id":"151","city_name":"Mangalagiri","state_id":"2"},
    {"city_id":"152","city_name":"Manthani","state_id":"2"},
    {"city_id":"153","city_name":"Markapur","state_id":"2"},
    {"city_id":"154","city_name":"Marturu","state_id":"2"},
    {"city_id":"155","city_name":"Metpalli","state_id":"2"},
    {"city_id":"156","city_name":"Mindi","state_id":"2"},
    {"city_id":"157","city_name":"Mirpet","state_id":"2"},
    {"city_id":"158","city_name":"Moragudi","state_id":"2"},
    {"city_id":"159","city_name":"Mothugudam","state_id":"2"},
    {"city_id":"160","city_name":"Nagari","state_id":"2"},
    {"city_id":"161","city_name":"Nagireddipalle","state_id":"2"},
    {"city_id":"162","city_name":"Nandigama","state_id":"2"},
    {"city_id":"163","city_name":"Nandikotkur","state_id":"2"},
    {"city_id":"164","city_name":"Nandyal","state_id":"2"},
    {"city_id":"165","city_name":"Narasannapeta","state_id":"2"},
    {"city_id":"166","city_name":"Narasapur","state_id":"2"},
    {"city_id":"167","city_name":"Narasaraopet","state_id":"2"},
    {"city_id":"168","city_name":"Narayanavanam","state_id":"2"},
    {"city_id":"169","city_name":"Narsapur","state_id":"2"},
    {"city_id":"170","city_name":"Narsingi","state_id":"2"},
    {"city_id":"171","city_name":"Narsipatnam","state_id":"2"},
    {"city_id":"172","city_name":"Naspur","state_id":"2"},
    {"city_id":"173","city_name":"Nathayyapalem","state_id":"2"},
    {"city_id":"174","city_name":"Nayudupeta","state_id":"2"},
    {"city_id":"175","city_name":"Nelimaria","state_id":"2"},
    {"city_id":"176","city_name":"Nellore","state_id":"2"},
    {"city_id":"177","city_name":"Nidadavole","state_id":"2"},
    {"city_id":"178","city_name":"Nuzvid","state_id":"2"},
    {"city_id":"179","city_name":"Omerkhan daira","state_id":"2"},
    {"city_id":"180","city_name":"Ongole","state_id":"2"},
    {"city_id":"181","city_name":"Osmania University","state_id":"2"},
    {"city_id":"182","city_name":"Pakala","state_id":"2"},
    {"city_id":"183","city_name":"Palakole","state_id":"2"},
    {"city_id":"184","city_name":"Palakurthi","state_id":"2"},
    {"city_id":"185","city_name":"Palasa","state_id":"2"},
    {"city_id":"186","city_name":"Palempalle","state_id":"2"},
    {"city_id":"187","city_name":"Palkonda","state_id":"2"},
    {"city_id":"188","city_name":"Palmaner","state_id":"2"},
    {"city_id":"189","city_name":"Pamur","state_id":"2"},
    {"city_id":"190","city_name":"Panjim","state_id":"2"},
    {"city_id":"191","city_name":"Papampeta","state_id":"2"},
    {"city_id":"192","city_name":"Parasamba","state_id":"2"},
    {"city_id":"193","city_name":"Parvatipuram","state_id":"2"},
    {"city_id":"194","city_name":"Patancheru","state_id":"2"},
    {"city_id":"195","city_name":"Payakaraopet","state_id":"2"},
    {"city_id":"196","city_name":"Pedagantyada","state_id":"2"},
    {"city_id":"197","city_name":"Pedana","state_id":"2"},
    {"city_id":"198","city_name":"Peddapuram","state_id":"2"},
    {"city_id":"199","city_name":"Pendurthi","state_id":"2"},
    {"city_id":"200","city_name":"Penugonda","state_id":"2"},
    {"city_id":"201","city_name":"Penukonda","state_id":"2"},
    {"city_id":"202","city_name":"Phirangipuram","state_id":"2"},
    {"city_id":"203","city_name":"Pithapuram","state_id":"2"},
    {"city_id":"204","city_name":"Ponnur","state_id":"2"},
    {"city_id":"205","city_name":"Port Blair","state_id":"2"},
    {"city_id":"206","city_name":"Pothinamallayyapalem","state_id":"2"},
    {"city_id":"207","city_name":"Prakasam","state_id":"2"},
    {"city_id":"208","city_name":"Prasadampadu","state_id":"2"},
    {"city_id":"209","city_name":"Prasantinilayam","state_id":"2"},
    {"city_id":"210","city_name":"Proddatur","state_id":"2"},
    {"city_id":"211","city_name":"Pulivendla","state_id":"2"},
    {"city_id":"212","city_name":"Punganuru","state_id":"2"},
    {"city_id":"213","city_name":"Puttur","state_id":"2"},
    {"city_id":"214","city_name":"Qutubullapur","state_id":"2"},
    {"city_id":"215","city_name":"Rajahmundry","state_id":"2"},
    {"city_id":"216","city_name":"Rajamahendri","state_id":"2"},
    {"city_id":"217","city_name":"Rajampet","state_id":"2"},
    {"city_id":"218","city_name":"Rajendranagar","state_id":"2"},
    {"city_id":"219","city_name":"Rajoli","state_id":"2"},
    {"city_id":"220","city_name":"Ramachandrapuram","state_id":"2"},
    {"city_id":"221","city_name":"Ramanayyapeta","state_id":"2"},
    {"city_id":"222","city_name":"Ramapuram","state_id":"2"},
    {"city_id":"223","city_name":"Ramarajupalli","state_id":"2"},
    {"city_id":"224","city_name":"Ramavarappadu","state_id":"2"},
    {"city_id":"225","city_name":"Rameswaram","state_id":"2"},
    {"city_id":"226","city_name":"Rampachodavaram","state_id":"2"},
    {"city_id":"227","city_name":"Ravulapalam","state_id":"2"},
    {"city_id":"228","city_name":"Rayachoti","state_id":"2"},
    {"city_id":"229","city_name":"Rayadrug","state_id":"2"},
    {"city_id":"230","city_name":"Razam","state_id":"2"},
    {"city_id":"231","city_name":"Razole","state_id":"2"},
    {"city_id":"232","city_name":"Renigunta","state_id":"2"},
    {"city_id":"233","city_name":"Repalle","state_id":"2"},
    {"city_id":"234","city_name":"Rishikonda","state_id":"2"},
    {"city_id":"235","city_name":"Salur","state_id":"2"},
    {"city_id":"236","city_name":"Samalkot","state_id":"2"},
    {"city_id":"237","city_name":"Sattenapalle","state_id":"2"},
    {"city_id":"238","city_name":"Seetharampuram","state_id":"2"},
    {"city_id":"239","city_name":"Serilungampalle","state_id":"2"},
    {"city_id":"240","city_name":"Shankarampet","state_id":"2"},
    {"city_id":"241","city_name":"Shar","state_id":"2"},
    {"city_id":"242","city_name":"Singarayakonda","state_id":"2"},
    {"city_id":"243","city_name":"Sirpur","state_id":"2"},
    {"city_id":"244","city_name":"Sirsilla","state_id":"2"},
    {"city_id":"245","city_name":"Sompeta","state_id":"2"},
    {"city_id":"246","city_name":"Sriharikota","state_id":"2"},
    {"city_id":"247","city_name":"Srikakulam","state_id":"2"},
    {"city_id":"248","city_name":"Srikalahasti","state_id":"2"},
    {"city_id":"249","city_name":"Sriramnagar","state_id":"2"},
    {"city_id":"250","city_name":"Sriramsagar","state_id":"2"},
    {"city_id":"251","city_name":"Srisailam","state_id":"2"},
    {"city_id":"252","city_name":"Srisailamgudem Devasthanam","state_id":"2"},
    {"city_id":"253","city_name":"Sulurpeta","state_id":"2"},
    {"city_id":"254","city_name":"Suriapet","state_id":"2"},
    {"city_id":"255","city_name":"Suryaraopet","state_id":"2"},
    {"city_id":"256","city_name":"Tadepalle","state_id":"2"},
    {"city_id":"257","city_name":"Tadepalligudem","state_id":"2"},
    {"city_id":"258","city_name":"Tadpatri","state_id":"2"},
    {"city_id":"259","city_name":"Tallapalle","state_id":"2"},
    {"city_id":"260","city_name":"Tanuku","state_id":"2"},
    {"city_id":"261","city_name":"Tekkali","state_id":"2"},
    {"city_id":"262","city_name":"Tenali","state_id":"2"},
    {"city_id":"263","city_name":"Tigalapahad","state_id":"2"},
    {"city_id":"264","city_name":"Tiruchanur","state_id":"2"},
    {"city_id":"265","city_name":"Tirumala","state_id":"2"},
    {"city_id":"266","city_name":"Tirupati","state_id":"2"},
    {"city_id":"267","city_name":"Tirvuru","state_id":"2"},
    {"city_id":"268","city_name":"Trimulgherry","state_id":"2"},
    {"city_id":"269","city_name":"Tuni","state_id":"2"},
    {"city_id":"270","city_name":"Turangi","state_id":"2"},
    {"city_id":"271","city_name":"Ukkayapalli","state_id":"2"},
    {"city_id":"272","city_name":"Ukkunagaram","state_id":"2"},
    {"city_id":"273","city_name":"Uppal Kalan","state_id":"2"},
    {"city_id":"274","city_name":"Upper Sileru","state_id":"2"},
    {"city_id":"275","city_name":"Uravakonda","state_id":"2"},
    {"city_id":"276","city_name":"Vadlapudi","state_id":"2"},
    {"city_id":"277","city_name":"Vaparala","state_id":"2"},
    {"city_id":"278","city_name":"Vemalwada","state_id":"2"},
    {"city_id":"279","city_name":"Venkatagiri","state_id":"2"},
    {"city_id":"280","city_name":"Venkatapuram","state_id":"2"},
    {"city_id":"281","city_name":"Vepagunta","state_id":"2"},
    {"city_id":"282","city_name":"Vetapalem","state_id":"2"},
    {"city_id":"283","city_name":"Vijayapuri","state_id":"2"},
    {"city_id":"284","city_name":"Vijayapuri South","state_id":"2"},
    {"city_id":"285","city_name":"Vijayawada","state_id":"2"},
    {"city_id":"286","city_name":"Vinukonda","state_id":"2"},
    {"city_id":"287","city_name":"Visakhapatnam","state_id":"2"},
    {"city_id":"288","city_name":"Vizianagaram","state_id":"2"},
    {"city_id":"289","city_name":"Vuyyuru","state_id":"2"},
    {"city_id":"290","city_name":"Wanparti","state_id":"2"},
    {"city_id":"291","city_name":"West Godavari Dist.","state_id":"2"},
    {"city_id":"292","city_name":"Yadagirigutta","state_id":"2"},
    {"city_id":"293","city_name":"Yarada","state_id":"2"},
    {"city_id":"294","city_name":"Yellamanchili","state_id":"2"},
    {"city_id":"295","city_name":"Yemmiganur","state_id":"2"},
    {"city_id":"296","city_name":"Yenamalakudru","state_id":"2"},
    {"city_id":"297","city_name":"Yendada","state_id":"2"},
    {"city_id":"298","city_name":"Yerraguntla","state_id":"2"},
    {"city_id":"299","city_name":"Along","state_id":"3"},
    {"city_id":"300","city_name":"Basar","state_id":"3"},
    {"city_id":"301","city_name":"Bondila","state_id":"3"},
    {"city_id":"302","city_name":"Changlang","state_id":"3"},
    {"city_id":"303","city_name":"Daporijo","state_id":"3"},
    {"city_id":"304","city_name":"Deomali","state_id":"3"},
    {"city_id":"305","city_name":"Itanagar","state_id":"3"},
    {"city_id":"306","city_name":"Jairampur","state_id":"3"},
    {"city_id":"307","city_name":"Khonsa","state_id":"3"},
    {"city_id":"308","city_name":"Naharlagun","state_id":"3"},
    {"city_id":"309","city_name":"Namsai","state_id":"3"},
    {"city_id":"310","city_name":"Pasighat","state_id":"3"},
    {"city_id":"311","city_name":"Roing","state_id":"3"},
    {"city_id":"312","city_name":"Seppa","state_id":"3"},
    {"city_id":"313","city_name":"Tawang","state_id":"3"},
    {"city_id":"314","city_name":"Tezu","state_id":"3"},
    {"city_id":"315","city_name":"Ziro","state_id":"3"},
    {"city_id":"316","city_name":"Abhayapuri","state_id":"4"},
    {"city_id":"317","city_name":"Ambikapur","state_id":"4"},
    {"city_id":"318","city_name":"Amguri","state_id":"4"},
    {"city_id":"319","city_name":"Anand Nagar","state_id":"4"},
    {"city_id":"320","city_name":"Badarpur","state_id":"4"},
    {"city_id":"321","city_name":"Badarpur Railway Town","state_id":"4"},
    {"city_id":"322","city_name":"Bahbari Gaon","state_id":"4"},
    {"city_id":"323","city_name":"Bamun Sualkuchi","state_id":"4"},
    {"city_id":"324","city_name":"Barbari","state_id":"4"},
    {"city_id":"325","city_name":"Barpathar","state_id":"4"},
    {"city_id":"326","city_name":"Barpeta","state_id":"4"},
    {"city_id":"327","city_name":"Barpeta Road","state_id":"4"},
    {"city_id":"328","city_name":"Basugaon","state_id":"4"},
    {"city_id":"329","city_name":"Bihpuria","state_id":"4"},
    {"city_id":"330","city_name":"Bijni","state_id":"4"},
    {"city_id":"331","city_name":"Bilasipara","state_id":"4"},
    {"city_id":"332","city_name":"Biswanath Chariali","state_id":"4"},
    {"city_id":"333","city_name":"Bohori","state_id":"4"},
    {"city_id":"334","city_name":"Bokajan","state_id":"4"},
    {"city_id":"335","city_name":"Bokokhat","state_id":"4"},
    {"city_id":"336","city_name":"Bongaigaon","state_id":"4"},
    {"city_id":"337","city_name":"Bongaigaon Petro-chemical Town","state_id":"4"},
    {"city_id":"338","city_name":"Borgolai","state_id":"4"},
    {"city_id":"339","city_name":"Chabua","state_id":"4"},
    {"city_id":"340","city_name":"Chandrapur Bagicha","state_id":"4"},
    {"city_id":"341","city_name":"Chapar","state_id":"4"},
    {"city_id":"342","city_name":"Chekonidhara","state_id":"4"},
    {"city_id":"343","city_name":"Choto Haibor","state_id":"4"},
    {"city_id":"344","city_name":"Dergaon","state_id":"4"},
    {"city_id":"345","city_name":"Dharapur","state_id":"4"},
    {"city_id":"346","city_name":"Dhekiajuli","state_id":"4"},
    {"city_id":"347","city_name":"Dhemaji","state_id":"4"},
    {"city_id":"348","city_name":"Dhing","state_id":"4"},
    {"city_id":"349","city_name":"Dhubri","state_id":"4"},
    {"city_id":"350","city_name":"Dhuburi","state_id":"4"},
    {"city_id":"351","city_name":"Dibrugarh","state_id":"4"},
    {"city_id":"352","city_name":"Digboi","state_id":"4"},
    {"city_id":"353","city_name":"Digboi Oil Town","state_id":"4"},
    {"city_id":"354","city_name":"Dimaruguri","state_id":"4"},
    {"city_id":"355","city_name":"Diphu","state_id":"4"},
    {"city_id":"356","city_name":"Dispur","state_id":"4"},
    {"city_id":"357","city_name":"Doboka","state_id":"4"},
    {"city_id":"358","city_name":"Dokmoka","state_id":"4"},
    {"city_id":"359","city_name":"Donkamokan","state_id":"4"},
    {"city_id":"360","city_name":"Duliagaon","state_id":"4"},
    {"city_id":"361","city_name":"Duliajan","state_id":"4"},
    {"city_id":"362","city_name":"Duliajan No.1","state_id":"4"},
    {"city_id":"363","city_name":"Dum Duma","state_id":"4"},
    {"city_id":"364","city_name":"Durga Nagar","state_id":"4"},
    {"city_id":"365","city_name":"Gauripur","state_id":"4"},
    {"city_id":"366","city_name":"Goalpara","state_id":"4"},
    {"city_id":"367","city_name":"Gohpur","state_id":"4"},
    {"city_id":"368","city_name":"Golaghat","state_id":"4"},
    {"city_id":"369","city_name":"Golakganj","state_id":"4"},
    {"city_id":"370","city_name":"Gossaigaon","state_id":"4"},
    {"city_id":"371","city_name":"Guwahati","state_id":"4"},
    {"city_id":"372","city_name":"Haflong","state_id":"4"},
    {"city_id":"373","city_name":"Hailakandi","state_id":"4"},
    {"city_id":"374","city_name":"Hamren","state_id":"4"},
    {"city_id":"375","city_name":"Hauli","state_id":"4"},
    {"city_id":"376","city_name":"Hauraghat","state_id":"4"},
    {"city_id":"377","city_name":"Hojai","state_id":"4"},
    {"city_id":"378","city_name":"Jagiroad","state_id":"4"},
    {"city_id":"379","city_name":"Jagiroad Paper Mill","state_id":"4"},
    {"city_id":"380","city_name":"Jogighopa","state_id":"4"},
    {"city_id":"381","city_name":"Jonai Bazar","state_id":"4"},
    {"city_id":"382","city_name":"Jorhat","state_id":"4"},
    {"city_id":"383","city_name":"Kampur Town","state_id":"4"},
    {"city_id":"384","city_name":"Kamrup","state_id":"4"},
    {"city_id":"385","city_name":"Kanakpur","state_id":"4"},
    {"city_id":"386","city_name":"Karimganj","state_id":"4"},
    {"city_id":"387","city_name":"Kharijapikon","state_id":"4"},
    {"city_id":"388","city_name":"Kharupetia","state_id":"4"},
    {"city_id":"389","city_name":"Kochpara","state_id":"4"},
    {"city_id":"390","city_name":"Kokrajhar","state_id":"4"},
    {"city_id":"391","city_name":"Kumar Kaibarta Gaon","state_id":"4"},
    {"city_id":"392","city_name":"Lakhimpur","state_id":"4"},
    {"city_id":"393","city_name":"Lakhipur","state_id":"4"},
    {"city_id":"394","city_name":"Lala","state_id":"4"},
    {"city_id":"395","city_name":"Lanka","state_id":"4"},
    {"city_id":"396","city_name":"Lido Tikok","state_id":"4"},
    {"city_id":"397","city_name":"Lido Town","state_id":"4"},
    {"city_id":"398","city_name":"Lumding","state_id":"4"},
    {"city_id":"399","city_name":"Lumding Railway Colony","state_id":"4"},
    {"city_id":"400","city_name":"Mahur","state_id":"4"},
    {"city_id":"401","city_name":"Maibong","state_id":"4"},
    {"city_id":"402","city_name":"Majgaon","state_id":"4"},
    {"city_id":"403","city_name":"Makum","state_id":"4"},
    {"city_id":"404","city_name":"Mangaldai","state_id":"4"},
    {"city_id":"405","city_name":"Mankachar","state_id":"4"},
    {"city_id":"406","city_name":"Margherita","state_id":"4"},
    {"city_id":"407","city_name":"Mariani","state_id":"4"},
    {"city_id":"408","city_name":"Marigaon","state_id":"4"},
    {"city_id":"409","city_name":"Moran","state_id":"4"},
    {"city_id":"410","city_name":"Moranhat","state_id":"4"},
    {"city_id":"411","city_name":"Nagaon","state_id":"4"},
    {"city_id":"412","city_name":"Naharkatia","state_id":"4"},
    {"city_id":"413","city_name":"Nalbari","state_id":"4"},
    {"city_id":"414","city_name":"Namrup","state_id":"4"},
    {"city_id":"415","city_name":"Naubaisa Gaon","state_id":"4"},
    {"city_id":"416","city_name":"Nazira","state_id":"4"},
    {"city_id":"417","city_name":"New Bongaigaon Railway Colony","state_id":"4"},
    {"city_id":"418","city_name":"Niz-Hajo","state_id":"4"},
    {"city_id":"419","city_name":"North Guwahati","state_id":"4"},
    {"city_id":"420","city_name":"Numaligarh","state_id":"4"},
    {"city_id":"421","city_name":"Palasbari","state_id":"4"},
    {"city_id":"422","city_name":"Panchgram","state_id":"4"},
    {"city_id":"423","city_name":"Pathsala","state_id":"4"},
    {"city_id":"424","city_name":"Raha","state_id":"4"},
    {"city_id":"425","city_name":"Rangapara","state_id":"4"},
    {"city_id":"426","city_name":"Rangia","state_id":"4"},
    {"city_id":"427","city_name":"Salakati","state_id":"4"},
    {"city_id":"428","city_name":"Sapatgram","state_id":"4"},
    {"city_id":"429","city_name":"Sarthebari","state_id":"4"},
    {"city_id":"430","city_name":"Sarupathar","state_id":"4"},
    {"city_id":"431","city_name":"Sarupathar Bengali","state_id":"4"},
    {"city_id":"432","city_name":"Senchoagaon","state_id":"4"},
    {"city_id":"433","city_name":"Sibsagar","state_id":"4"},
    {"city_id":"434","city_name":"Silapathar","state_id":"4"},
    {"city_id":"435","city_name":"Silchar","state_id":"4"},
    {"city_id":"436","city_name":"Silchar Part-X","state_id":"4"},
    {"city_id":"437","city_name":"Sonari","state_id":"4"},
    {"city_id":"438","city_name":"Sorbhog","state_id":"4"},
    {"city_id":"439","city_name":"Sualkuchi","state_id":"4"},
    {"city_id":"440","city_name":"Tangla","state_id":"4"},
    {"city_id":"441","city_name":"Tezpur","state_id":"4"},
    {"city_id":"442","city_name":"Tihu","state_id":"4"},
    {"city_id":"443","city_name":"Tinsukia","state_id":"4"},
    {"city_id":"444","city_name":"Titabor","state_id":"4"},
    {"city_id":"445","city_name":"Udalguri","state_id":"4"},
    {"city_id":"446","city_name":"Umrangso","state_id":"4"},
    {"city_id":"447","city_name":"Uttar Krishnapur Part-I","state_id":"4"},
    {"city_id":"448","city_name":"Amarpur","state_id":"5"},
    {"city_id":"449","city_name":"Ara","state_id":"5"},
    {"city_id":"450","city_name":"Araria","state_id":"5"},
    {"city_id":"451","city_name":"Areraj","state_id":"5"},
    {"city_id":"452","city_name":"Asarganj","state_id":"5"},
    {"city_id":"453","city_name":"Aurangabad","state_id":"5"},
    {"city_id":"454","city_name":"Bagaha","state_id":"5"},
    {"city_id":"455","city_name":"Bahadurganj","state_id":"5"},
    {"city_id":"456","city_name":"Bairgania","state_id":"5"},
    {"city_id":"457","city_name":"Bakhtiyarpur","state_id":"5"},
    {"city_id":"458","city_name":"Banka","state_id":"5"},
    {"city_id":"459","city_name":"Banmankhi","state_id":"5"},
    {"city_id":"460","city_name":"Bar Bigha","state_id":"5"},
    {"city_id":"461","city_name":"Barauli","state_id":"5"},
    {"city_id":"462","city_name":"Barauni Oil Township","state_id":"5"},
    {"city_id":"463","city_name":"Barh","state_id":"5"},
    {"city_id":"464","city_name":"Barhiya","state_id":"5"},
    {"city_id":"465","city_name":"Bariapur","state_id":"5"},
    {"city_id":"466","city_name":"Baruni","state_id":"5"},
    {"city_id":"467","city_name":"Begusarai","state_id":"5"},
    {"city_id":"468","city_name":"Behea","state_id":"5"},
    {"city_id":"469","city_name":"Belsand","state_id":"5"},
    {"city_id":"470","city_name":"Bettiah","state_id":"5"},
    {"city_id":"471","city_name":"Bhabua","state_id":"5"},
    {"city_id":"472","city_name":"Bhagalpur","state_id":"5"},
    {"city_id":"473","city_name":"Bhimnagar","state_id":"5"},
    {"city_id":"474","city_name":"Bhojpur","state_id":"5"},
    {"city_id":"475","city_name":"Bihar","state_id":"5"},
    {"city_id":"476","city_name":"Bihar Sharif","state_id":"5"},
    {"city_id":"477","city_name":"Bihariganj","state_id":"5"},
    {"city_id":"478","city_name":"Bikramganj","state_id":"5"},
    {"city_id":"479","city_name":"Birpur","state_id":"5"},
    {"city_id":"480","city_name":"Bodh Gaya","state_id":"5"},
    {"city_id":"481","city_name":"Buxar","state_id":"5"},
    {"city_id":"482","city_name":"Chakia","state_id":"5"},
    {"city_id":"483","city_name":"Chanpatia","state_id":"5"},
    {"city_id":"484","city_name":"Chhapra","state_id":"5"},
    {"city_id":"485","city_name":"Chhatapur","state_id":"5"},
    {"city_id":"486","city_name":"Colgong","state_id":"5"},
    {"city_id":"487","city_name":"Dalsingh Sarai","state_id":"5"},
    {"city_id":"488","city_name":"Darbhanga","state_id":"5"},
    {"city_id":"489","city_name":"Daudnagar","state_id":"5"},
    {"city_id":"490","city_name":"Dehri","state_id":"5"},
    {"city_id":"491","city_name":"Dhaka","state_id":"5"},
    {"city_id":"492","city_name":"Dighwara","state_id":"5"},
    {"city_id":"493","city_name":"Dinapur","state_id":"5"},
    {"city_id":"494","city_name":"Dinapur Cantonment","state_id":"5"},
    {"city_id":"495","city_name":"Dumra","state_id":"5"},
    {"city_id":"496","city_name":"Dumraon","state_id":"5"},
    {"city_id":"497","city_name":"Fatwa","state_id":"5"},
    {"city_id":"498","city_name":"Forbesganj","state_id":"5"},
    {"city_id":"499","city_name":"Gaya","state_id":"5"},
    {"city_id":"500","city_name":"Gazipur","state_id":"5"},
    {"city_id":"501","city_name":"Ghoghardiha","state_id":"5"},
    {"city_id":"502","city_name":"Gogri Jamalpur","state_id":"5"},
    {"city_id":"503","city_name":"Gopalganj","state_id":"5"},
    {"city_id":"504","city_name":"Habibpur","state_id":"5"},
    {"city_id":"505","city_name":"Hajipur","state_id":"5"},
    {"city_id":"506","city_name":"Hasanpur","state_id":"5"},
    {"city_id":"507","city_name":"Hazaribagh","state_id":"5"},
    {"city_id":"508","city_name":"Hilsa","state_id":"5"},
    {"city_id":"509","city_name":"Hisua","state_id":"5"},
    {"city_id":"510","city_name":"Islampur","state_id":"5"},
    {"city_id":"511","city_name":"Jagdispur","state_id":"5"},
    {"city_id":"512","city_name":"Jahanabad","state_id":"5"},
    {"city_id":"513","city_name":"Jamalpur","state_id":"5"},
    {"city_id":"514","city_name":"Jamhaur","state_id":"5"},
    {"city_id":"515","city_name":"Jamui","state_id":"5"},
    {"city_id":"516","city_name":"Janakpur Road","state_id":"5"},
    {"city_id":"517","city_name":"Janpur","state_id":"5"},
    {"city_id":"518","city_name":"Jaynagar","state_id":"5"},
    {"city_id":"519","city_name":"Jha Jha","state_id":"5"},
    {"city_id":"520","city_name":"Jhanjharpur","state_id":"5"},
    {"city_id":"521","city_name":"Jogbani","state_id":"5"},
    {"city_id":"522","city_name":"Kanti","state_id":"5"},
    {"city_id":"523","city_name":"Kasba","state_id":"5"},
    {"city_id":"524","city_name":"Kataiya","state_id":"5"},
    {"city_id":"525","city_name":"Katihar","state_id":"5"},
    {"city_id":"526","city_name":"Khagaria","state_id":"5"},
    {"city_id":"527","city_name":"Khagaul","state_id":"5"},
    {"city_id":"528","city_name":"Kharagpur","state_id":"5"},
    {"city_id":"529","city_name":"Khusrupur","state_id":"5"},
    {"city_id":"530","city_name":"Kishanganj","state_id":"5"},
    {"city_id":"531","city_name":"Koath","state_id":"5"},
    {"city_id":"532","city_name":"Koilwar","state_id":"5"},
    {"city_id":"533","city_name":"Lakhisarai","state_id":"5"},
    {"city_id":"534","city_name":"Lalganj","state_id":"5"},
    {"city_id":"535","city_name":"Lauthaha","state_id":"5"},
    {"city_id":"536","city_name":"Madhepura","state_id":"5"},
    {"city_id":"537","city_name":"Madhubani","state_id":"5"},
    {"city_id":"538","city_name":"Maharajganj","state_id":"5"},
    {"city_id":"539","city_name":"Mahnar Bazar","state_id":"5"},
    {"city_id":"540","city_name":"Mairwa","state_id":"5"},
    {"city_id":"541","city_name":"Makhdumpur","state_id":"5"},
    {"city_id":"542","city_name":"Maner","state_id":"5"},
    {"city_id":"543","city_name":"Manihari","state_id":"5"},
    {"city_id":"544","city_name":"Marhaura","state_id":"5"},
    {"city_id":"545","city_name":"Masaurhi","state_id":"5"},
    {"city_id":"546","city_name":"Mirganj","state_id":"5"},
    {"city_id":"547","city_name":"Mohiuddinagar","state_id":"5"},
    {"city_id":"548","city_name":"Mokama","state_id":"5"},
    {"city_id":"549","city_name":"Motihari","state_id":"5"},
    {"city_id":"550","city_name":"Motipur","state_id":"5"},
    {"city_id":"551","city_name":"Munger","state_id":"5"},
    {"city_id":"552","city_name":"Murliganj","state_id":"5"},
    {"city_id":"553","city_name":"Muzaffarpur","state_id":"5"},
    {"city_id":"554","city_name":"Nabinagar","state_id":"5"},
    {"city_id":"555","city_name":"Narkatiaganj","state_id":"5"},
    {"city_id":"556","city_name":"Nasriganj","state_id":"5"},
    {"city_id":"557","city_name":"Natwar","state_id":"5"},
    {"city_id":"558","city_name":"Naugachhia","state_id":"5"},
    {"city_id":"559","city_name":"Nawada","state_id":"5"},
    {"city_id":"560","city_name":"Nirmali","state_id":"5"},
    {"city_id":"561","city_name":"Nokha","state_id":"5"},
    {"city_id":"562","city_name":"Paharpur","state_id":"5"},
    {"city_id":"563","city_name":"Patna","state_id":"5"},
    {"city_id":"564","city_name":"Phulwari","state_id":"5"},
    {"city_id":"565","city_name":"Piro","state_id":"5"},
    {"city_id":"566","city_name":"Purnia","state_id":"5"},
    {"city_id":"567","city_name":"Pusa","state_id":"5"},
    {"city_id":"568","city_name":"Rafiganj","state_id":"5"},
    {"city_id":"569","city_name":"Raghunathpur","state_id":"5"},
    {"city_id":"570","city_name":"Rajgir","state_id":"5"},
    {"city_id":"571","city_name":"Ramnagar","state_id":"5"},
    {"city_id":"572","city_name":"Raxaul","state_id":"5"},
    {"city_id":"573","city_name":"Revelganj","state_id":"5"},
    {"city_id":"574","city_name":"Rusera","state_id":"5"},
    {"city_id":"575","city_name":"Sagauli","state_id":"5"},
    {"city_id":"576","city_name":"Saharsa","state_id":"5"},
    {"city_id":"577","city_name":"Samastipur","state_id":"5"},
    {"city_id":"578","city_name":"Sasaram","state_id":"5"},
    {"city_id":"579","city_name":"Shahpur","state_id":"5"},
    {"city_id":"580","city_name":"Shaikhpura","state_id":"5"},
    {"city_id":"581","city_name":"Sherghati","state_id":"5"},
    {"city_id":"582","city_name":"Shivhar","state_id":"5"},
    {"city_id":"583","city_name":"Silao","state_id":"5"},
    {"city_id":"584","city_name":"Sitamarhi","state_id":"5"},
    {"city_id":"585","city_name":"Siwan","state_id":"5"},
    {"city_id":"586","city_name":"Sonepur","state_id":"5"},
    {"city_id":"587","city_name":"Sultanganj","state_id":"5"},
    {"city_id":"588","city_name":"Supaul","state_id":"5"},
    {"city_id":"589","city_name":"Teghra","state_id":"5"},
    {"city_id":"590","city_name":"Tekari","state_id":"5"},
    {"city_id":"591","city_name":"Thakurganj","state_id":"5"},
    {"city_id":"592","city_name":"Vaishali","state_id":"5"},
    {"city_id":"593","city_name":"Waris Aliganj","state_id":"5"},
    {"city_id":"594","city_name":"Chandigarh","state_id":"6"},
    {"city_id":"595","city_name":"Ahiwara","state_id":"7"},
    {"city_id":"596","city_name":"Akaltara","state_id":"7"},
    {"city_id":"597","city_name":"Ambagarh Chauki","state_id":"7"},
    {"city_id":"598","city_name":"Ambikapur","state_id":"7"},
    {"city_id":"599","city_name":"Arang","state_id":"7"},
    {"city_id":"600","city_name":"Bade Bacheli","state_id":"7"},
    {"city_id":"601","city_name":"Bagbahara","state_id":"7"},
    {"city_id":"602","city_name":"Baikunthpur","state_id":"7"},
    {"city_id":"603","city_name":"Balod","state_id":"7"},
    {"city_id":"604","city_name":"Baloda","state_id":"7"},
    {"city_id":"605","city_name":"Baloda Bazar","state_id":"7"},
    {"city_id":"606","city_name":"Banarsi","state_id":"7"},
    {"city_id":"607","city_name":"Basna","state_id":"7"},
    {"city_id":"608","city_name":"Bemetra","state_id":"7"},
    {"city_id":"609","city_name":"Bhanpuri","state_id":"7"},
    {"city_id":"610","city_name":"Bhatapara","state_id":"7"},
    {"city_id":"611","city_name":"Bhatgaon","state_id":"7"},
    {"city_id":"612","city_name":"Bhilai","state_id":"7"},
    {"city_id":"613","city_name":"Bilaspur","state_id":"7"},
    {"city_id":"614","city_name":"Bilha","state_id":"7"},
    {"city_id":"615","city_name":"Birgaon","state_id":"7"},
    {"city_id":"616","city_name":"Bodri","state_id":"7"},
    {"city_id":"617","city_name":"Champa","state_id":"7"},
    {"city_id":"618","city_name":"Charcha","state_id":"7"},
    {"city_id":"619","city_name":"Charoda","state_id":"7"},
    {"city_id":"620","city_name":"Chhuikhadan","state_id":"7"},
    {"city_id":"621","city_name":"Chirmiri","state_id":"7"},
    {"city_id":"622","city_name":"Dantewada","state_id":"7"},
    {"city_id":"623","city_name":"Deori","state_id":"7"},
    {"city_id":"624","city_name":"Dhamdha","state_id":"7"},
    {"city_id":"625","city_name":"Dhamtari","state_id":"7"},
    {"city_id":"626","city_name":"Dharamjaigarh","state_id":"7"},
    {"city_id":"627","city_name":"Dipka","state_id":"7"},
    {"city_id":"628","city_name":"Doman Hill Colliery","state_id":"7"},
    {"city_id":"629","city_name":"Dongargaon","state_id":"7"},
    {"city_id":"630","city_name":"Dongragarh","state_id":"7"},
    {"city_id":"631","city_name":"Durg","state_id":"7"},
    {"city_id":"632","city_name":"Frezarpur","state_id":"7"},
    {"city_id":"633","city_name":"Gandai","state_id":"7"},
    {"city_id":"634","city_name":"Gariaband","state_id":"7"},
    {"city_id":"635","city_name":"Gaurela","state_id":"7"},
    {"city_id":"636","city_name":"Gelhapani","state_id":"7"},
    {"city_id":"637","city_name":"Gharghoda","state_id":"7"},
    {"city_id":"638","city_name":"Gidam","state_id":"7"},
    {"city_id":"639","city_name":"Gobra Nawapara","state_id":"7"},
    {"city_id":"640","city_name":"Gogaon","state_id":"7"},
    {"city_id":"641","city_name":"Hatkachora","state_id":"7"},
    {"city_id":"642","city_name":"Jagdalpur","state_id":"7"},
    {"city_id":"643","city_name":"Jamui","state_id":"7"},
    {"city_id":"644","city_name":"Jashpurnagar","state_id":"7"},
    {"city_id":"645","city_name":"Jhagrakhand","state_id":"7"},
    {"city_id":"646","city_name":"Kanker","state_id":"7"},
    {"city_id":"647","city_name":"Katghora","state_id":"7"},
    {"city_id":"648","city_name":"Kawardha","state_id":"7"},
    {"city_id":"649","city_name":"Khairagarh","state_id":"7"},
    {"city_id":"650","city_name":"Khamhria","state_id":"7"},
    {"city_id":"651","city_name":"Kharod","state_id":"7"},
    {"city_id":"652","city_name":"Kharsia","state_id":"7"},
    {"city_id":"653","city_name":"Khonga Pani","state_id":"7"},
    {"city_id":"654","city_name":"Kirandu","state_id":"7"},
    {"city_id":"655","city_name":"Kirandul","state_id":"7"},
    {"city_id":"656","city_name":"Kohka","state_id":"7"},
    {"city_id":"657","city_name":"Kondagaon","state_id":"7"},
    {"city_id":"658","city_name":"Korba","state_id":"7"},
    {"city_id":"659","city_name":"Korea","state_id":"7"},
    {"city_id":"660","city_name":"Koria Block","state_id":"7"},
    {"city_id":"661","city_name":"Kota","state_id":"7"},
    {"city_id":"662","city_name":"Kumhari","state_id":"7"},
    {"city_id":"663","city_name":"Kumud Katta","state_id":"7"},
    {"city_id":"664","city_name":"Kurasia","state_id":"7"},
    {"city_id":"665","city_name":"Kurud","state_id":"7"},
    {"city_id":"666","city_name":"Lingiyadih","state_id":"7"},
    {"city_id":"667","city_name":"Lormi","state_id":"7"},
    {"city_id":"668","city_name":"Mahasamund","state_id":"7"},
    {"city_id":"669","city_name":"Mahendragarh","state_id":"7"},
    {"city_id":"670","city_name":"Mehmand","state_id":"7"},
    {"city_id":"671","city_name":"Mongra","state_id":"7"},
    {"city_id":"672","city_name":"Mowa","state_id":"7"},
    {"city_id":"673","city_name":"Mungeli","state_id":"7"},
    {"city_id":"674","city_name":"Nailajanjgir","state_id":"7"},
    {"city_id":"675","city_name":"Namna Kalan","state_id":"7"},
    {"city_id":"676","city_name":"Naya Baradwar","state_id":"7"},
    {"city_id":"677","city_name":"Pandariya","state_id":"7"},
    {"city_id":"678","city_name":"Patan","state_id":"7"},
    {"city_id":"679","city_name":"Pathalgaon","state_id":"7"},
    {"city_id":"680","city_name":"Pendra","state_id":"7"},
    {"city_id":"681","city_name":"Phunderdihari","state_id":"7"},
    {"city_id":"682","city_name":"Pithora","state_id":"7"},
    {"city_id":"683","city_name":"Raigarh","state_id":"7"},
    {"city_id":"684","city_name":"Raipur","state_id":"7"},
    {"city_id":"685","city_name":"Rajgamar","state_id":"7"},
    {"city_id":"686","city_name":"Rajhara","state_id":"7"},
    {"city_id":"687","city_name":"Rajnandgaon","state_id":"7"},
    {"city_id":"688","city_name":"Ramanuj Ganj","state_id":"7"},
    {"city_id":"689","city_name":"Ratanpur","state_id":"7"},
    {"city_id":"690","city_name":"Sakti","state_id":"7"},
    {"city_id":"691","city_name":"Saraipali","state_id":"7"},
    {"city_id":"692","city_name":"Sarajpur","state_id":"7"},
    {"city_id":"693","city_name":"Sarangarh","state_id":"7"},
    {"city_id":"694","city_name":"Shivrinarayan","state_id":"7"},
    {"city_id":"695","city_name":"Simga","state_id":"7"},
    {"city_id":"696","city_name":"Sirgiti","state_id":"7"},
    {"city_id":"697","city_name":"Takhatpur","state_id":"7"},
    {"city_id":"698","city_name":"Telgaon","state_id":"7"},
    {"city_id":"699","city_name":"Tildanewra","state_id":"7"},
    {"city_id":"700","city_name":"Urla","state_id":"7"},
    {"city_id":"701","city_name":"Vishrampur","state_id":"7"},
    {"city_id":"702","city_name":"Amli","state_id":"8"},
    {"city_id":"703","city_name":"Silvassa","state_id":"8"},
    {"city_id":"704","city_name":"Daman","state_id":"9"},
    {"city_id":"705","city_name":"Diu","state_id":"9"},
    {"city_id":"706","city_name":"Delhi","state_id":"10"},
    {"city_id":"707","city_name":"New Delhi","state_id":"10"},
    {"city_id":"708","city_name":"Aldona","state_id":"11"},
    {"city_id":"709","city_name":"Altinho","state_id":"11"},
    {"city_id":"710","city_name":"Aquem","state_id":"11"},
    {"city_id":"711","city_name":"Arpora","state_id":"11"},
    {"city_id":"712","city_name":"Bambolim","state_id":"11"},
    {"city_id":"713","city_name":"Bandora","state_id":"11"},
    {"city_id":"714","city_name":"Bardez","state_id":"11"},
    {"city_id":"715","city_name":"Benaulim","state_id":"11"},
    {"city_id":"716","city_name":"Betora","state_id":"11"},
    {"city_id":"717","city_name":"Bicholim","state_id":"11"},
    {"city_id":"718","city_name":"Calapor","state_id":"11"},
    {"city_id":"719","city_name":"Candolim","state_id":"11"},
    {"city_id":"720","city_name":"Caranzalem","state_id":"11"},
    {"city_id":"721","city_name":"Carapur","state_id":"11"},
    {"city_id":"722","city_name":"Chicalim","state_id":"11"},
    {"city_id":"723","city_name":"Chimbel","state_id":"11"},
    {"city_id":"724","city_name":"Chinchinim","state_id":"11"},
    {"city_id":"725","city_name":"Colvale","state_id":"11"},
    {"city_id":"726","city_name":"Corlim","state_id":"11"},
    {"city_id":"727","city_name":"Cortalim","state_id":"11"},
    {"city_id":"728","city_name":"Cuncolim","state_id":"11"},
    {"city_id":"729","city_name":"Curchorem","state_id":"11"},
    {"city_id":"730","city_name":"Curti","state_id":"11"},
    {"city_id":"731","city_name":"Davorlim","state_id":"11"},
    {"city_id":"732","city_name":"Dona Paula","state_id":"11"},
    {"city_id":"733","city_name":"Goa","state_id":"11"},
    {"city_id":"734","city_name":"Guirim","state_id":"11"},
    {"city_id":"735","city_name":"Jua","state_id":"11"},
    {"city_id":"736","city_name":"Kalangat","state_id":"11"},
    {"city_id":"737","city_name":"Kankon","state_id":"11"},
    {"city_id":"738","city_name":"Kundaim","state_id":"11"},
    {"city_id":"739","city_name":"Loutulim","state_id":"11"},
    {"city_id":"740","city_name":"Madgaon","state_id":"11"},
    {"city_id":"741","city_name":"Mapusa","state_id":"11"},
    {"city_id":"742","city_name":"Margao","state_id":"11"},
    {"city_id":"743","city_name":"Margaon","state_id":"11"},
    {"city_id":"744","city_name":"Miramar","state_id":"11"},
    {"city_id":"745","city_name":"Morjim","state_id":"11"},
    {"city_id":"746","city_name":"Mormugao","state_id":"11"},
    {"city_id":"747","city_name":"Navelim","state_id":"11"},
    {"city_id":"748","city_name":"Pale","state_id":"11"},
    {"city_id":"749","city_name":"Panaji","state_id":"11"},
    {"city_id":"750","city_name":"Parcem","state_id":"11"},
    {"city_id":"751","city_name":"Parra","state_id":"11"},
    {"city_id":"752","city_name":"Penha de Franca","state_id":"11"},
    {"city_id":"753","city_name":"Pernem","state_id":"11"},
    {"city_id":"754","city_name":"Pilerne","state_id":"11"},
    {"city_id":"755","city_name":"Pissurlem","state_id":"11"},
    {"city_id":"756","city_name":"Ponda","state_id":"11"},
    {"city_id":"757","city_name":"Porvorim","state_id":"11"},
    {"city_id":"758","city_name":"Quepem","state_id":"11"},
    {"city_id":"759","city_name":"Queula","state_id":"11"},
    {"city_id":"760","city_name":"Raia","state_id":"11"},
    {"city_id":"761","city_name":"Reis Magos","state_id":"11"},
    {"city_id":"762","city_name":"Salcette","state_id":"11"},
    {"city_id":"763","city_name":"Saligao","state_id":"11"},
    {"city_id":"764","city_name":"Sancoale","state_id":"11"},
    {"city_id":"765","city_name":"Sanguem","state_id":"11"},
    {"city_id":"766","city_name":"Sanquelim","state_id":"11"},
    {"city_id":"767","city_name":"Sanvordem","state_id":"11"},
    {"city_id":"768","city_name":"Sao Jose-de-Areal","state_id":"11"},
    {"city_id":"769","city_name":"Sattari","state_id":"11"},
    {"city_id":"770","city_name":"Serula","state_id":"11"},
    {"city_id":"771","city_name":"Sinquerim","state_id":"11"},
    {"city_id":"772","city_name":"Siolim","state_id":"11"},
    {"city_id":"773","city_name":"Taleigao","state_id":"11"},
    {"city_id":"774","city_name":"Tivim","state_id":"11"},
    {"city_id":"775","city_name":"Valpoi","state_id":"11"},
    {"city_id":"776","city_name":"Varca","state_id":"11"},
    {"city_id":"777","city_name":"Vasco","state_id":"11"},
    {"city_id":"778","city_name":"Verna","state_id":"11"},
    {"city_id":"779","city_name":"Abrama","state_id":"12"},
    {"city_id":"780","city_name":"Adalaj","state_id":"12"},
    {"city_id":"781","city_name":"Adityana","state_id":"12"},
    {"city_id":"782","city_name":"Advana","state_id":"12"},
    {"city_id":"783","city_name":"Ahmedabad","state_id":"12"},
    {"city_id":"784","city_name":"Ahwa","state_id":"12"},
    {"city_id":"785","city_name":"Alang","state_id":"12"},
    {"city_id":"786","city_name":"Ambaji","state_id":"12"},
    {"city_id":"787","city_name":"Ambaliyasan","state_id":"12"},
    {"city_id":"788","city_name":"Amod","state_id":"12"},
    {"city_id":"789","city_name":"Amreli","state_id":"12"},
    {"city_id":"790","city_name":"Amroli","state_id":"12"},
    {"city_id":"791","city_name":"Anand","state_id":"12"},
    {"city_id":"792","city_name":"Andada","state_id":"12"},
    {"city_id":"793","city_name":"Anjar","state_id":"12"},
    {"city_id":"794","city_name":"Anklav","state_id":"12"},
    {"city_id":"795","city_name":"Ankleshwar","state_id":"12"},
    {"city_id":"796","city_name":"Anklesvar INA","state_id":"12"},
    {"city_id":"797","city_name":"Antaliya","state_id":"12"},
    {"city_id":"798","city_name":"Arambhada","state_id":"12"},
    {"city_id":"799","city_name":"Asarma","state_id":"12"},
    {"city_id":"800","city_name":"Atul","state_id":"12"},
    {"city_id":"801","city_name":"Babra","state_id":"12"},
    {"city_id":"802","city_name":"Bag-e-Firdosh","state_id":"12"},
    {"city_id":"803","city_name":"Bagasara","state_id":"12"},
    {"city_id":"804","city_name":"Bahadarpar","state_id":"12"},
    {"city_id":"805","city_name":"Bajipura","state_id":"12"},
    {"city_id":"806","city_name":"Bajva","state_id":"12"},
    {"city_id":"807","city_name":"Balasinor","state_id":"12"},
    {"city_id":"808","city_name":"Banaskantha","state_id":"12"},
    {"city_id":"809","city_name":"Bansda","state_id":"12"},
    {"city_id":"810","city_name":"Bantva","state_id":"12"},
    {"city_id":"811","city_name":"Bardoli","state_id":"12"},
    {"city_id":"812","city_name":"Barwala","state_id":"12"},
    {"city_id":"813","city_name":"Bayad","state_id":"12"},
    {"city_id":"814","city_name":"Bechar","state_id":"12"},
    {"city_id":"815","city_name":"Bedi","state_id":"12"},
    {"city_id":"816","city_name":"Beyt","state_id":"12"},
    {"city_id":"817","city_name":"Bhachau","state_id":"12"},
    {"city_id":"818","city_name":"Bhanvad","state_id":"12"},
    {"city_id":"819","city_name":"Bharuch","state_id":"12"},
    {"city_id":"820","city_name":"Bharuch INA","state_id":"12"},
    {"city_id":"821","city_name":"Bhavnagar","state_id":"12"},
    {"city_id":"822","city_name":"Bhayavadar","state_id":"12"},
    {"city_id":"823","city_name":"Bhestan","state_id":"12"},
    {"city_id":"824","city_name":"Bhuj","state_id":"12"},
    {"city_id":"825","city_name":"Bilimora","state_id":"12"},
    {"city_id":"826","city_name":"Bilkha","state_id":"12"},
    {"city_id":"827","city_name":"Billimora","state_id":"12"},
    {"city_id":"828","city_name":"Bodakdev","state_id":"12"},
    {"city_id":"829","city_name":"Bodeli","state_id":"12"},
    {"city_id":"830","city_name":"Bopal","state_id":"12"},
    {"city_id":"831","city_name":"Boria","state_id":"12"},
    {"city_id":"832","city_name":"Boriavi","state_id":"12"},
    {"city_id":"833","city_name":"Borsad","state_id":"12"},
    {"city_id":"834","city_name":"Botad","state_id":"12"},
    {"city_id":"835","city_name":"Cambay","state_id":"12"},
    {"city_id":"836","city_name":"Chaklasi","state_id":"12"},
    {"city_id":"837","city_name":"Chala","state_id":"12"},
    {"city_id":"838","city_name":"Chalala","state_id":"12"},
    {"city_id":"839","city_name":"Chalthan","state_id":"12"},
    {"city_id":"840","city_name":"Chanasma","state_id":"12"},
    {"city_id":"841","city_name":"Chandisar","state_id":"12"},
    {"city_id":"842","city_name":"Chandkheda","state_id":"12"},
    {"city_id":"843","city_name":"Chanod","state_id":"12"},
    {"city_id":"844","city_name":"Chaya","state_id":"12"},
    {"city_id":"845","city_name":"Chenpur","state_id":"12"},
    {"city_id":"846","city_name":"Chhapi","state_id":"12"},
    {"city_id":"847","city_name":"Chhaprabhatha","state_id":"12"},
    {"city_id":"848","city_name":"Chhatral","state_id":"12"},
    {"city_id":"849","city_name":"Chhota Udepur","state_id":"12"},
    {"city_id":"850","city_name":"Chikhli","state_id":"12"},
    {"city_id":"851","city_name":"Chiloda","state_id":"12"},
    {"city_id":"852","city_name":"Chorvad","state_id":"12"},
    {"city_id":"853","city_name":"Chotila","state_id":"12"},
    {"city_id":"854","city_name":"Dabhoi","state_id":"12"},
    {"city_id":"855","city_name":"Dadara","state_id":"12"},
    {"city_id":"856","city_name":"Dahod","state_id":"12"},
    {"city_id":"857","city_name":"Dakor","state_id":"12"},
    {"city_id":"858","city_name":"Damnagar","state_id":"12"},
    {"city_id":"859","city_name":"Deesa","state_id":"12"},
    {"city_id":"860","city_name":"Delvada","state_id":"12"},
    {"city_id":"861","city_name":"Devgadh Baria","state_id":"12"},
    {"city_id":"862","city_name":"Devsar","state_id":"12"},
    {"city_id":"863","city_name":"Dhandhuka","state_id":"12"},
    {"city_id":"864","city_name":"Dhanera","state_id":"12"},
    {"city_id":"865","city_name":"Dhangdhra","state_id":"12"},
    {"city_id":"866","city_name":"Dhansura","state_id":"12"},
    {"city_id":"867","city_name":"Dharampur","state_id":"12"},
    {"city_id":"868","city_name":"Dhari","state_id":"12"},
    {"city_id":"869","city_name":"Dhola","state_id":"12"},
    {"city_id":"870","city_name":"Dholka","state_id":"12"},
    {"city_id":"871","city_name":"Dholka Rural","state_id":"12"},
    {"city_id":"872","city_name":"Dhoraji","state_id":"12"},
    {"city_id":"873","city_name":"Dhrangadhra","state_id":"12"},
    {"city_id":"874","city_name":"Dhrol","state_id":"12"},
    {"city_id":"875","city_name":"Dhuva","state_id":"12"},
    {"city_id":"876","city_name":"Dhuwaran","state_id":"12"},
    {"city_id":"877","city_name":"Digvijaygram","state_id":"12"},
    {"city_id":"878","city_name":"Disa","state_id":"12"},
    {"city_id":"879","city_name":"Dungar","state_id":"12"},
    {"city_id":"880","city_name":"Dungarpur","state_id":"12"},
    {"city_id":"881","city_name":"Dungra","state_id":"12"},
    {"city_id":"882","city_name":"Dwarka","state_id":"12"},
    {"city_id":"883","city_name":"Flelanganj","state_id":"12"},
    {"city_id":"884","city_name":"GSFC Complex","state_id":"12"},
    {"city_id":"885","city_name":"Gadhda","state_id":"12"},
    {"city_id":"886","city_name":"Gandevi","state_id":"12"},
    {"city_id":"887","city_name":"Gandhidham","state_id":"12"},
    {"city_id":"888","city_name":"Gandhinagar","state_id":"12"},
    {"city_id":"889","city_name":"Gariadhar","state_id":"12"},
    {"city_id":"890","city_name":"Ghogha","state_id":"12"},
    {"city_id":"891","city_name":"Godhra","state_id":"12"},
    {"city_id":"892","city_name":"Gondal","state_id":"12"},
    {"city_id":"893","city_name":"Hajira INA","state_id":"12"},
    {"city_id":"894","city_name":"Halol","state_id":"12"},
    {"city_id":"895","city_name":"Halvad","state_id":"12"},
    {"city_id":"896","city_name":"Hansot","state_id":"12"},
    {"city_id":"897","city_name":"Harij","state_id":"12"},
    {"city_id":"898","city_name":"Himatnagar","state_id":"12"},
    {"city_id":"899","city_name":"Ichchhapor","state_id":"12"},
    {"city_id":"900","city_name":"Idar","state_id":"12"},
    {"city_id":"901","city_name":"Jafrabad","state_id":"12"},
    {"city_id":"902","city_name":"Jalalpore","state_id":"12"},
    {"city_id":"903","city_name":"Jambusar","state_id":"12"},
    {"city_id":"904","city_name":"Jamjodhpur","state_id":"12"},
    {"city_id":"905","city_name":"Jamnagar","state_id":"12"},
    {"city_id":"906","city_name":"Jasdan","state_id":"12"},
    {"city_id":"907","city_name":"Jawaharnagar","state_id":"12"},
    {"city_id":"908","city_name":"Jetalsar","state_id":"12"},
    {"city_id":"909","city_name":"Jetpur","state_id":"12"},
    {"city_id":"910","city_name":"Jodiya","state_id":"12"},
    {"city_id":"911","city_name":"Joshipura","state_id":"12"},
    {"city_id":"912","city_name":"Junagadh","state_id":"12"},
    {"city_id":"913","city_name":"Kadi","state_id":"12"},
    {"city_id":"914","city_name":"Kadodara","state_id":"12"},
    {"city_id":"915","city_name":"Kalavad","state_id":"12"},
    {"city_id":"916","city_name":"Kali","state_id":"12"},
    {"city_id":"917","city_name":"Kaliawadi","state_id":"12"},
    {"city_id":"918","city_name":"Kalol","state_id":"12"},
    {"city_id":"919","city_name":"Kalol INA","state_id":"12"},
    {"city_id":"920","city_name":"Kandla","state_id":"12"},
    {"city_id":"921","city_name":"Kanjari","state_id":"12"},
    {"city_id":"922","city_name":"Kanodar","state_id":"12"},
    {"city_id":"923","city_name":"Kapadwanj","state_id":"12"},
    {"city_id":"924","city_name":"Karachiya","state_id":"12"},
    {"city_id":"925","city_name":"Karamsad","state_id":"12"},
    {"city_id":"926","city_name":"Karjan","state_id":"12"},
    {"city_id":"927","city_name":"Kathial","state_id":"12"},
    {"city_id":"928","city_name":"Kathor","state_id":"12"},
    {"city_id":"929","city_name":"Katpar","state_id":"12"},
    {"city_id":"930","city_name":"Kavant","state_id":"12"},
    {"city_id":"931","city_name":"Keshod","state_id":"12"},
    {"city_id":"932","city_name":"Kevadiya","state_id":"12"},
    {"city_id":"933","city_name":"Khambhaliya","state_id":"12"},
    {"city_id":"934","city_name":"Khambhat","state_id":"12"},
    {"city_id":"935","city_name":"Kharaghoda","state_id":"12"},
    {"city_id":"936","city_name":"Khed Brahma","state_id":"12"},
    {"city_id":"937","city_name":"Kheda","state_id":"12"},
    {"city_id":"938","city_name":"Kheralu","state_id":"12"},
    {"city_id":"939","city_name":"Kodinar","state_id":"12"},
    {"city_id":"940","city_name":"Kosamba","state_id":"12"},
    {"city_id":"941","city_name":"Kundla","state_id":"12"},
    {"city_id":"942","city_name":"Kutch","state_id":"12"},
    {"city_id":"943","city_name":"Kutiyana","state_id":"12"},
    {"city_id":"944","city_name":"Lakhtar","state_id":"12"},
    {"city_id":"945","city_name":"Lalpur","state_id":"12"},
    {"city_id":"946","city_name":"Lambha","state_id":"12"},
    {"city_id":"947","city_name":"Lathi","state_id":"12"},
    {"city_id":"948","city_name":"Limbdi","state_id":"12"},
    {"city_id":"949","city_name":"Limla","state_id":"12"},
    {"city_id":"950","city_name":"Lunavada","state_id":"12"},
    {"city_id":"951","city_name":"Madhapar","state_id":"12"},
    {"city_id":"952","city_name":"Maflipur","state_id":"12"},
    {"city_id":"953","city_name":"Mahemdavad","state_id":"12"},
    {"city_id":"954","city_name":"Mahudha","state_id":"12"},
    {"city_id":"955","city_name":"Mahuva","state_id":"12"},
    {"city_id":"956","city_name":"Mahuvar","state_id":"12"},
    {"city_id":"957","city_name":"Makarba","state_id":"12"},
    {"city_id":"958","city_name":"Makarpura","state_id":"12"},
    {"city_id":"959","city_name":"Makassar","state_id":"12"},
    {"city_id":"960","city_name":"Maktampur","state_id":"12"},
    {"city_id":"961","city_name":"Malia","state_id":"12"},
    {"city_id":"962","city_name":"Malpur","state_id":"12"},
    {"city_id":"963","city_name":"Manavadar","state_id":"12"},
    {"city_id":"964","city_name":"Mandal","state_id":"12"},
    {"city_id":"965","city_name":"Mandvi","state_id":"12"},
    {"city_id":"966","city_name":"Mangrol","state_id":"12"},
    {"city_id":"967","city_name":"Mansa","state_id":"12"},
    {"city_id":"968","city_name":"Meghraj","state_id":"12"},
    {"city_id":"969","city_name":"Mehsana","state_id":"12"},
    {"city_id":"970","city_name":"Mendarla","state_id":"12"},
    {"city_id":"971","city_name":"Mithapur","state_id":"12"},
    {"city_id":"972","city_name":"Modasa","state_id":"12"},
    {"city_id":"973","city_name":"Mogravadi","state_id":"12"},
    {"city_id":"974","city_name":"Morbi","state_id":"12"},
    {"city_id":"975","city_name":"Morvi","state_id":"12"},
    {"city_id":"976","city_name":"Mundra","state_id":"12"},
    {"city_id":"977","city_name":"Nadiad","state_id":"12"},
    {"city_id":"978","city_name":"Naliya","state_id":"12"},
    {"city_id":"979","city_name":"Nanakvada","state_id":"12"},
    {"city_id":"980","city_name":"Nandej","state_id":"12"},
    {"city_id":"981","city_name":"Nandesari","state_id":"12"},
    {"city_id":"982","city_name":"Nandesari INA","state_id":"12"},
    {"city_id":"983","city_name":"Naroda","state_id":"12"},
    {"city_id":"984","city_name":"Navagadh","state_id":"12"},
    {"city_id":"985","city_name":"Navagam Ghed","state_id":"12"},
    {"city_id":"986","city_name":"Navsari","state_id":"12"},
    {"city_id":"987","city_name":"Ode","state_id":"12"},
    {"city_id":"988","city_name":"Okaf","state_id":"12"},
    {"city_id":"989","city_name":"Okha","state_id":"12"},
    {"city_id":"990","city_name":"Olpad","state_id":"12"},
    {"city_id":"991","city_name":"Paddhari","state_id":"12"},
    {"city_id":"992","city_name":"Padra","state_id":"12"},
    {"city_id":"993","city_name":"Palanpur","state_id":"12"},
    {"city_id":"994","city_name":"Palej","state_id":"12"},
    {"city_id":"995","city_name":"Pali","state_id":"12"},
    {"city_id":"996","city_name":"Palitana","state_id":"12"},
    {"city_id":"997","city_name":"Paliyad","state_id":"12"},
    {"city_id":"998","city_name":"Pandesara","state_id":"12"},
    {"city_id":"999","city_name":"Panoli","state_id":"12"},
    {"city_id":"1000","city_name":"Pardi","state_id":"12"},
    {"city_id":"1001","city_name":"Parnera","state_id":"12"},
    {"city_id":"1002","city_name":"Parvat","state_id":"12"},
    {"city_id":"1003","city_name":"Patan","state_id":"12"},
    {"city_id":"1004","city_name":"Patdi","state_id":"12"},
    {"city_id":"1005","city_name":"Petlad","state_id":"12"},
    {"city_id":"1006","city_name":"Petrochemical Complex","state_id":"12"},
    {"city_id":"1007","city_name":"Porbandar","state_id":"12"},
    {"city_id":"1008","city_name":"Prantij","state_id":"12"},
    {"city_id":"1009","city_name":"Radhanpur","state_id":"12"},
    {"city_id":"1010","city_name":"Raiya","state_id":"12"},
    {"city_id":"1011","city_name":"Rajkot","state_id":"12"},
    {"city_id":"1012","city_name":"Rajpipla","state_id":"12"},
    {"city_id":"1013","city_name":"Rajula","state_id":"12"},
    {"city_id":"1014","city_name":"Ramod","state_id":"12"},
    {"city_id":"1015","city_name":"Ranavav","state_id":"12"},
    {"city_id":"1016","city_name":"Ranoli","state_id":"12"},
    {"city_id":"1017","city_name":"Rapar","state_id":"12"},
    {"city_id":"1018","city_name":"Sahij","state_id":"12"},
    {"city_id":"1019","city_name":"Salaya","state_id":"12"},
    {"city_id":"1020","city_name":"Sanand","state_id":"12"},
    {"city_id":"1021","city_name":"Sankheda","state_id":"12"},
    {"city_id":"1022","city_name":"Santrampur","state_id":"12"},
    {"city_id":"1023","city_name":"Saribujrang","state_id":"12"},
    {"city_id":"1024","city_name":"Sarigam INA","state_id":"12"},
    {"city_id":"1025","city_name":"Sayan","state_id":"12"},
    {"city_id":"1026","city_name":"Sayla","state_id":"12"},
    {"city_id":"1027","city_name":"Shahpur","state_id":"12"},
    {"city_id":"1028","city_name":"Shahwadi","state_id":"12"},
    {"city_id":"1029","city_name":"Shapar","state_id":"12"},
    {"city_id":"1030","city_name":"Shivrajpur","state_id":"12"},
    {"city_id":"1031","city_name":"Siddhapur","state_id":"12"},
    {"city_id":"1032","city_name":"Sidhpur","state_id":"12"},
    {"city_id":"1033","city_name":"Sihor","state_id":"12"},
    {"city_id":"1034","city_name":"Sika","state_id":"12"},
    {"city_id":"1035","city_name":"Singarva","state_id":"12"},
    {"city_id":"1036","city_name":"Sinor","state_id":"12"},
    {"city_id":"1037","city_name":"Sojitra","state_id":"12"},
    {"city_id":"1038","city_name":"Sola","state_id":"12"},
    {"city_id":"1039","city_name":"Songadh","state_id":"12"},
    {"city_id":"1040","city_name":"Suraj Karadi","state_id":"12"},
    {"city_id":"1041","city_name":"Surat","state_id":"12"},
    {"city_id":"1042","city_name":"Surendranagar","state_id":"12"},
    {"city_id":"1043","city_name":"Talaja","state_id":"12"},
    {"city_id":"1044","city_name":"Talala","state_id":"12"},
    {"city_id":"1045","city_name":"Talod","state_id":"12"},
    {"city_id":"1046","city_name":"Tankara","state_id":"12"},
    {"city_id":"1047","city_name":"Tarsali","state_id":"12"},
    {"city_id":"1048","city_name":"Thangadh","state_id":"12"},
    {"city_id":"1049","city_name":"Tharad","state_id":"12"},
    {"city_id":"1050","city_name":"Thasra","state_id":"12"},
    {"city_id":"1051","city_name":"Udyognagar","state_id":"12"},
    {"city_id":"1052","city_name":"Ukai","state_id":"12"},
    {"city_id":"1053","city_name":"Umbergaon","state_id":"12"},
    {"city_id":"1054","city_name":"Umbergaon INA","state_id":"12"},
    {"city_id":"1055","city_name":"Umrala","state_id":"12"},
    {"city_id":"1056","city_name":"Umreth","state_id":"12"},
    {"city_id":"1057","city_name":"Un","state_id":"12"},
    {"city_id":"1058","city_name":"Una","state_id":"12"},
    {"city_id":"1059","city_name":"Unjha","state_id":"12"},
    {"city_id":"1060","city_name":"Upleta","state_id":"12"},
    {"city_id":"1061","city_name":"Utran","state_id":"12"},
    {"city_id":"1062","city_name":"Uttarsanda","state_id":"12"},
    {"city_id":"1063","city_name":"V.U. Nagar","state_id":"12"},
    {"city_id":"1064","city_name":"V.V. Nagar","state_id":"12"},
    {"city_id":"1065","city_name":"Vadia","state_id":"12"},
    {"city_id":"1066","city_name":"Vadla","state_id":"12"},
    {"city_id":"1067","city_name":"Vadnagar","state_id":"12"},
    {"city_id":"1068","city_name":"Vadodara","state_id":"12"},
    {"city_id":"1069","city_name":"Vaghodia INA","state_id":"12"},
    {"city_id":"1070","city_name":"Valbhipur","state_id":"12"},
    {"city_id":"1071","city_name":"Vallabh Vidyanagar","state_id":"12"},
    {"city_id":"1072","city_name":"Valsad","state_id":"12"},
    {"city_id":"1073","city_name":"Valsad INA","state_id":"12"},
    {"city_id":"1074","city_name":"Vanthali","state_id":"12"},
    {"city_id":"1075","city_name":"Vapi","state_id":"12"},
    {"city_id":"1076","city_name":"Vapi INA","state_id":"12"},
    {"city_id":"1077","city_name":"Vartej","state_id":"12"},
    {"city_id":"1078","city_name":"Vasad","state_id":"12"},
    {"city_id":"1079","city_name":"Vasna Borsad INA","state_id":"12"},
    {"city_id":"1080","city_name":"Vaso","state_id":"12"},
    {"city_id":"1081","city_name":"Veraval","state_id":"12"},
    {"city_id":"1082","city_name":"Vidyanagar","state_id":"12"},
    {"city_id":"1083","city_name":"Vijalpor","state_id":"12"},
    {"city_id":"1084","city_name":"Vijapur","state_id":"12"},
    {"city_id":"1085","city_name":"Vinchhiya","state_id":"12"},
    {"city_id":"1086","city_name":"Vinzol","state_id":"12"},
    {"city_id":"1087","city_name":"Virpur","state_id":"12"},
    {"city_id":"1088","city_name":"Visavadar","state_id":"12"},
    {"city_id":"1089","city_name":"Visnagar","state_id":"12"},
    {"city_id":"1090","city_name":"Vyara","state_id":"12"},
    {"city_id":"1091","city_name":"Wadhwan","state_id":"12"},
    {"city_id":"1092","city_name":"Waghai","state_id":"12"},
    {"city_id":"1093","city_name":"Waghodia","state_id":"12"},
    {"city_id":"1094","city_name":"Wankaner","state_id":"12"},
    {"city_id":"1095","city_name":"Zalod","state_id":"12"},
    {"city_id":"1096","city_name":"Ambala","state_id":"13"},
    {"city_id":"1097","city_name":"Ambala Cantt","state_id":"13"},
    {"city_id":"1098","city_name":"Asan Khurd","state_id":"13"},
    {"city_id":"1099","city_name":"Asandh","state_id":"13"},
    {"city_id":"1100","city_name":"Ateli","state_id":"13"},
    {"city_id":"1101","city_name":"Babiyal","state_id":"13"},
    {"city_id":"1102","city_name":"Bahadurgarh","state_id":"13"},
    {"city_id":"1103","city_name":"Ballabgarh","state_id":"13"},
    {"city_id":"1104","city_name":"Barwala","state_id":"13"},
    {"city_id":"1105","city_name":"Bawal","state_id":"13"},
    {"city_id":"1106","city_name":"Bawani Khera","state_id":"13"},
    {"city_id":"1107","city_name":"Beri","state_id":"13"},
    {"city_id":"1108","city_name":"Bhiwani","state_id":"13"},
    {"city_id":"1109","city_name":"Bilaspur","state_id":"13"},
    {"city_id":"1110","city_name":"Buria","state_id":"13"},
    {"city_id":"1111","city_name":"Charkhi Dadri","state_id":"13"},
    {"city_id":"1112","city_name":"Chhachhrauli","state_id":"13"},
    {"city_id":"1113","city_name":"Chita","state_id":"13"},
    {"city_id":"1114","city_name":"Dabwali","state_id":"13"},
    {"city_id":"1115","city_name":"Dharuhera","state_id":"13"},
    {"city_id":"1116","city_name":"Dundahera","state_id":"13"},
    {"city_id":"1117","city_name":"Ellenabad","state_id":"13"},
    {"city_id":"1118","city_name":"Farakhpur","state_id":"13"},
    {"city_id":"1119","city_name":"Faridabad","state_id":"13"},
    {"city_id":"1120","city_name":"Farrukhnagar","state_id":"13"},
    {"city_id":"1121","city_name":"Fatehabad","state_id":"13"},
    {"city_id":"1122","city_name":"Firozpur Jhirka","state_id":"13"},
    {"city_id":"1123","city_name":"Gannaur","state_id":"13"},
    {"city_id":"1124","city_name":"Ghraunda","state_id":"13"},
    {"city_id":"1125","city_name":"Gohana","state_id":"13"},
    {"city_id":"1126","city_name":"Gurgaon","state_id":"13"},
    {"city_id":"1127","city_name":"Haileymandi","state_id":"13"},
    {"city_id":"1128","city_name":"Hansi","state_id":"13"},
    {"city_id":"1129","city_name":"Hasanpur","state_id":"13"},
    {"city_id":"1130","city_name":"Hathin","state_id":"13"},
    {"city_id":"1131","city_name":"Hisar","state_id":"13"},
    {"city_id":"1132","city_name":"Hissar","state_id":"13"},
    {"city_id":"1133","city_name":"Hodal","state_id":"13"},
    {"city_id":"1134","city_name":"Indri","state_id":"13"},
    {"city_id":"1135","city_name":"Jagadhri","state_id":"13"},
    {"city_id":"1136","city_name":"Jakhal Mandi","state_id":"13"},
    {"city_id":"1137","city_name":"Jhajjar","state_id":"13"},
    {"city_id":"1138","city_name":"Jind","state_id":"13"},
    {"city_id":"1139","city_name":"Julana","state_id":"13"},
    {"city_id":"1140","city_name":"Kaithal","state_id":"13"},
    {"city_id":"1141","city_name":"Kalanur","state_id":"13"},
    {"city_id":"1142","city_name":"Kalanwali","state_id":"13"},
    {"city_id":"1143","city_name":"Kalayat","state_id":"13"},
    {"city_id":"1144","city_name":"Kalka","state_id":"13"},
    {"city_id":"1145","city_name":"Kanina","state_id":"13"},
    {"city_id":"1146","city_name":"Kansepur","state_id":"13"},
    {"city_id":"1147","city_name":"Kardhan","state_id":"13"},
    {"city_id":"1148","city_name":"Karnal","state_id":"13"},
    {"city_id":"1149","city_name":"Kharkhoda","state_id":"13"},
    {"city_id":"1150","city_name":"Kheri Sampla","state_id":"13"},
    {"city_id":"1151","city_name":"Kundli","state_id":"13"},
    {"city_id":"1152","city_name":"Kurukshetra","state_id":"13"},
    {"city_id":"1153","city_name":"Ladrawan","state_id":"13"},
    {"city_id":"1154","city_name":"Ladwa","state_id":"13"},
    {"city_id":"1155","city_name":"Loharu","state_id":"13"},
    {"city_id":"1156","city_name":"Maham","state_id":"13"},
    {"city_id":"1157","city_name":"Mahendragarh","state_id":"13"},
    {"city_id":"1158","city_name":"Mustafabad","state_id":"13"},
    {"city_id":"1159","city_name":"Nagai Chaudhry","state_id":"13"},
    {"city_id":"1160","city_name":"Narayangarh","state_id":"13"},
    {"city_id":"1161","city_name":"Narnaul","state_id":"13"},
    {"city_id":"1162","city_name":"Narnaund","state_id":"13"},
    {"city_id":"1163","city_name":"Narwana","state_id":"13"},
    {"city_id":"1164","city_name":"Nilokheri","state_id":"13"},
    {"city_id":"1165","city_name":"Nuh","state_id":"13"},
    {"city_id":"1166","city_name":"Palwal","state_id":"13"},
    {"city_id":"1167","city_name":"Panchkula","state_id":"13"},
    {"city_id":"1168","city_name":"Panipat","state_id":"13"},
    {"city_id":"1169","city_name":"Panipat Taraf Ansar","state_id":"13"},
    {"city_id":"1170","city_name":"Panipat Taraf Makhdum Zadgan","state_id":"13"},
    {"city_id":"1171","city_name":"Panipat Taraf Rajputan","state_id":"13"},
    {"city_id":"1172","city_name":"Pehowa","state_id":"13"},
    {"city_id":"1173","city_name":"Pinjaur","state_id":"13"},
    {"city_id":"1174","city_name":"Punahana","state_id":"13"},
    {"city_id":"1175","city_name":"Pundri","state_id":"13"},
    {"city_id":"1176","city_name":"Radaur","state_id":"13"},
    {"city_id":"1177","city_name":"Raipur Rani","state_id":"13"},
    {"city_id":"1178","city_name":"Rania","state_id":"13"},
    {"city_id":"1179","city_name":"Ratiya","state_id":"13"},
    {"city_id":"1180","city_name":"Rewari","state_id":"13"},
    {"city_id":"1181","city_name":"Rohtak","state_id":"13"},
    {"city_id":"1182","city_name":"Ropar","state_id":"13"},
    {"city_id":"1183","city_name":"Sadauri","state_id":"13"},
    {"city_id":"1184","city_name":"Safidon","state_id":"13"},
    {"city_id":"1185","city_name":"Samalkha","state_id":"13"},
    {"city_id":"1186","city_name":"Sankhol","state_id":"13"},
    {"city_id":"1187","city_name":"Sasauli","state_id":"13"},
    {"city_id":"1188","city_name":"Shahabad","state_id":"13"},
    {"city_id":"1189","city_name":"Sirsa","state_id":"13"},
    {"city_id":"1190","city_name":"Siwani","state_id":"13"},
    {"city_id":"1191","city_name":"Sohna","state_id":"13"},
    {"city_id":"1192","city_name":"Sonipat","state_id":"13"},
    {"city_id":"1193","city_name":"Sukhrali","state_id":"13"},
    {"city_id":"1194","city_name":"Taoru","state_id":"13"},
    {"city_id":"1195","city_name":"Taraori","state_id":"13"},
    {"city_id":"1196","city_name":"Tauru","state_id":"13"},
    {"city_id":"1197","city_name":"Thanesar","state_id":"13"},
    {"city_id":"1198","city_name":"Tilpat","state_id":"13"},
    {"city_id":"1199","city_name":"Tohana","state_id":"13"},
    {"city_id":"1200","city_name":"Tosham","state_id":"13"},
    {"city_id":"1201","city_name":"Uchana","state_id":"13"},
    {"city_id":"1202","city_name":"Uklana Mandi","state_id":"13"},
    {"city_id":"1203","city_name":"Uncha Siwana","state_id":"13"},
    {"city_id":"1204","city_name":"Yamunanagar","state_id":"13"},
    {"city_id":"1205","city_name":"Arki","state_id":"14"},
    {"city_id":"1206","city_name":"Baddi","state_id":"14"},
    {"city_id":"1207","city_name":"Bakloh","state_id":"14"},
    {"city_id":"1208","city_name":"Banjar","state_id":"14"},
    {"city_id":"1209","city_name":"Bhota","state_id":"14"},
    {"city_id":"1210","city_name":"Bhuntar","state_id":"14"},
    {"city_id":"1211","city_name":"Bilaspur","state_id":"14"},
    {"city_id":"1212","city_name":"Chamba","state_id":"14"},
    {"city_id":"1213","city_name":"Chaupal","state_id":"14"},
    {"city_id":"1214","city_name":"Chuari Khas","state_id":"14"},
    {"city_id":"1215","city_name":"Dagshai","state_id":"14"},
    {"city_id":"1216","city_name":"Dalhousie","state_id":"14"},
    {"city_id":"1217","city_name":"Dalhousie Cantonment","state_id":"14"},
    {"city_id":"1218","city_name":"Damtal","state_id":"14"},
    {"city_id":"1219","city_name":"Daulatpur","state_id":"14"},
    {"city_id":"1220","city_name":"Dera Gopipur","state_id":"14"},
    {"city_id":"1221","city_name":"Dhalli","state_id":"14"},
    {"city_id":"1222","city_name":"Dharamshala","state_id":"14"},
    {"city_id":"1223","city_name":"Gagret","state_id":"14"},
    {"city_id":"1224","city_name":"Ghamarwin","state_id":"14"},
    {"city_id":"1225","city_name":"Hamirpur","state_id":"14"},
    {"city_id":"1226","city_name":"Jawala Mukhi","state_id":"14"},
    {"city_id":"1227","city_name":"Jogindarnagar","state_id":"14"},
    {"city_id":"1228","city_name":"Jubbal","state_id":"14"},
    {"city_id":"1229","city_name":"Jutogh","state_id":"14"},
    {"city_id":"1230","city_name":"Kala Amb","state_id":"14"},
    {"city_id":"1231","city_name":"Kalpa","state_id":"14"},
    {"city_id":"1232","city_name":"Kangra","state_id":"14"},
    {"city_id":"1233","city_name":"Kasauli","state_id":"14"},
    {"city_id":"1234","city_name":"Kot Khai","state_id":"14"},
    {"city_id":"1235","city_name":"Kullu","state_id":"14"},
    {"city_id":"1236","city_name":"Kulu","state_id":"14"},
    {"city_id":"1237","city_name":"Manali","state_id":"14"},
    {"city_id":"1238","city_name":"Mandi","state_id":"14"},
    {"city_id":"1239","city_name":"Mant Khas","state_id":"14"},
    {"city_id":"1240","city_name":"Mehatpur Basdehra","state_id":"14"},
    {"city_id":"1241","city_name":"Nadaun","state_id":"14"},
    {"city_id":"1242","city_name":"Nagrota","state_id":"14"},
    {"city_id":"1243","city_name":"Nahan","state_id":"14"},
    {"city_id":"1244","city_name":"Naina Devi","state_id":"14"},
    {"city_id":"1245","city_name":"Nalagarh","state_id":"14"},
    {"city_id":"1246","city_name":"Narkanda","state_id":"14"},
    {"city_id":"1247","city_name":"Nurpur","state_id":"14"},
    {"city_id":"1248","city_name":"Palampur","state_id":"14"},
    {"city_id":"1249","city_name":"Pandoh","state_id":"14"},
    {"city_id":"1250","city_name":"Paonta Sahib","state_id":"14"},
    {"city_id":"1251","city_name":"Parwanoo","state_id":"14"},
    {"city_id":"1252","city_name":"Parwanu","state_id":"14"},
    {"city_id":"1253","city_name":"Rajgarh","state_id":"14"},
    {"city_id":"1254","city_name":"Rampur","state_id":"14"},
    {"city_id":"1255","city_name":"Rawalsar","state_id":"14"},
    {"city_id":"1256","city_name":"Rohru","state_id":"14"},
    {"city_id":"1257","city_name":"Sabathu","state_id":"14"},
    {"city_id":"1258","city_name":"Santokhgarh","state_id":"14"},
    {"city_id":"1259","city_name":"Sarahan","state_id":"14"},
    {"city_id":"1260","city_name":"Sarka Ghat","state_id":"14"},
    {"city_id":"1261","city_name":"Seoni","state_id":"14"},
    {"city_id":"1262","city_name":"Shimla","state_id":"14"},
    {"city_id":"1263","city_name":"Sirmaur","state_id":"14"},
    {"city_id":"1264","city_name":"Solan","state_id":"14"},
    {"city_id":"1265","city_name":"Solon","state_id":"14"},
    {"city_id":"1266","city_name":"Sundarnagar","state_id":"14"},
    {"city_id":"1267","city_name":"Sundernagar","state_id":"14"},
    {"city_id":"1268","city_name":"Talai","state_id":"14"},
    {"city_id":"1269","city_name":"Theog","state_id":"14"},
    {"city_id":"1270","city_name":"Tira Sujanpur","state_id":"14"},
    {"city_id":"1271","city_name":"Una","state_id":"14"},
    {"city_id":"1272","city_name":"Yol","state_id":"14"},
    {"city_id":"1273","city_name":"Achabal","state_id":"15"},
    {"city_id":"1274","city_name":"Akhnur","state_id":"15"},
    {"city_id":"1275","city_name":"Anantnag","state_id":"15"},
    {"city_id":"1276","city_name":"Arnia","state_id":"15"},
    {"city_id":"1277","city_name":"Awantipora","state_id":"15"},
    {"city_id":"1278","city_name":"Badami Bagh","state_id":"15"},
    {"city_id":"1279","city_name":"Bandipur","state_id":"15"},
    {"city_id":"1280","city_name":"Banihal","state_id":"15"},
    {"city_id":"1281","city_name":"Baramula","state_id":"15"},
    {"city_id":"1282","city_name":"Baramulla","state_id":"15"},
    {"city_id":"1283","city_name":"Bari Brahmana","state_id":"15"},
    {"city_id":"1284","city_name":"Bashohli","state_id":"15"},
    {"city_id":"1285","city_name":"Batote","state_id":"15"},
    {"city_id":"1286","city_name":"Bhaderwah","state_id":"15"},
    {"city_id":"1287","city_name":"Bijbiara","state_id":"15"},
    {"city_id":"1288","city_name":"Billawar","state_id":"15"},
    {"city_id":"1289","city_name":"Birwah","state_id":"15"},
    {"city_id":"1290","city_name":"Bishna","state_id":"15"},
    {"city_id":"1291","city_name":"Budgam","state_id":"15"},
    {"city_id":"1292","city_name":"Charari Sharief","state_id":"15"},
    {"city_id":"1293","city_name":"Chenani","state_id":"15"},
    {"city_id":"1294","city_name":"Doda","state_id":"15"},
    {"city_id":"1295","city_name":"Duru-Verinag","state_id":"15"},
    {"city_id":"1296","city_name":"Gandarbat","state_id":"15"},
    {"city_id":"1297","city_name":"Gho Manhasan","state_id":"15"},
    {"city_id":"1298","city_name":"Gorah Salathian","state_id":"15"},
    {"city_id":"1299","city_name":"Gulmarg","state_id":"15"},
    {"city_id":"1300","city_name":"Hajan","state_id":"15"},
    {"city_id":"1301","city_name":"Handwara","state_id":"15"},
    {"city_id":"1302","city_name":"Hiranagar","state_id":"15"},
    {"city_id":"1303","city_name":"Jammu","state_id":"15"},
    {"city_id":"1304","city_name":"Jammu Cantonment","state_id":"15"},
    {"city_id":"1305","city_name":"Jammu Tawi","state_id":"15"},
    {"city_id":"1306","city_name":"Jourian","state_id":"15"},
    {"city_id":"1307","city_name":"Kargil","state_id":"15"},
    {"city_id":"1308","city_name":"Kathua","state_id":"15"},
    {"city_id":"1309","city_name":"Katra","state_id":"15"},
    {"city_id":"1310","city_name":"Khan Sahib","state_id":"15"},
    {"city_id":"1311","city_name":"Khour","state_id":"15"},
    {"city_id":"1312","city_name":"Khrew","state_id":"15"},
    {"city_id":"1313","city_name":"Kishtwar","state_id":"15"},
    {"city_id":"1314","city_name":"Kud","state_id":"15"},
    {"city_id":"1315","city_name":"Kukernag","state_id":"15"},
    {"city_id":"1316","city_name":"Kulgam","state_id":"15"},
    {"city_id":"1317","city_name":"Kunzer","state_id":"15"},
    {"city_id":"1318","city_name":"Kupwara","state_id":"15"},
    {"city_id":"1319","city_name":"Lakhenpur","state_id":"15"},
    {"city_id":"1320","city_name":"Leh","state_id":"15"},
    {"city_id":"1321","city_name":"Magam","state_id":"15"},
    {"city_id":"1322","city_name":"Mattan","state_id":"15"},
    {"city_id":"1323","city_name":"Naushehra","state_id":"15"},
    {"city_id":"1324","city_name":"Pahalgam","state_id":"15"},
    {"city_id":"1325","city_name":"Pampore","state_id":"15"},
    {"city_id":"1326","city_name":"Parole","state_id":"15"},
    {"city_id":"1327","city_name":"Pattan","state_id":"15"},
    {"city_id":"1328","city_name":"Pulwama","state_id":"15"},
    {"city_id":"1329","city_name":"Punch","state_id":"15"},
    {"city_id":"1330","city_name":"Qazigund","state_id":"15"},
    {"city_id":"1331","city_name":"Rajauri","state_id":"15"},
    {"city_id":"1332","city_name":"Ramban","state_id":"15"},
    {"city_id":"1333","city_name":"Ramgarh","state_id":"15"},
    {"city_id":"1334","city_name":"Ramnagar","state_id":"15"},
    {"city_id":"1335","city_name":"Ranbirsingh Pora","state_id":"15"},
    {"city_id":"1336","city_name":"Reasi","state_id":"15"},
    {"city_id":"1337","city_name":"Rehambal","state_id":"15"},
    {"city_id":"1338","city_name":"Samba","state_id":"15"},
    {"city_id":"1339","city_name":"Shupiyan","state_id":"15"},
    {"city_id":"1340","city_name":"Sopur","state_id":"15"},
    {"city_id":"1341","city_name":"Srinagar","state_id":"15"},
    {"city_id":"1342","city_name":"Sumbal","state_id":"15"},
    {"city_id":"1343","city_name":"Sunderbani","state_id":"15"},
    {"city_id":"1344","city_name":"Talwara","state_id":"15"},
    {"city_id":"1345","city_name":"Thanamandi","state_id":"15"},
    {"city_id":"1346","city_name":"Tral","state_id":"15"},
    {"city_id":"1347","city_name":"Udhampur","state_id":"15"},
    {"city_id":"1348","city_name":"Uri","state_id":"15"},
    {"city_id":"1349","city_name":"Vijaypur","state_id":"15"},
    {"city_id":"1350","city_name":"Adityapur","state_id":"16"},
    {"city_id":"1351","city_name":"Amlabad","state_id":"16"},
    {"city_id":"1352","city_name":"Angarpathar","state_id":"16"},
    {"city_id":"1353","city_name":"Ara","state_id":"16"},
    {"city_id":"1354","city_name":"Babua Kalan","state_id":"16"},
    {"city_id":"1355","city_name":"Bagbahra","state_id":"16"},
    {"city_id":"1356","city_name":"Baliapur","state_id":"16"},
    {"city_id":"1357","city_name":"Baliari","state_id":"16"},
    {"city_id":"1358","city_name":"Balkundra","state_id":"16"},
    {"city_id":"1359","city_name":"Bandhgora","state_id":"16"},
    {"city_id":"1360","city_name":"Barajamda","state_id":"16"},
    {"city_id":"1361","city_name":"Barhi","state_id":"16"},
    {"city_id":"1362","city_name":"Barka Kana","state_id":"16"},
    {"city_id":"1363","city_name":"Barki Saraiya","state_id":"16"},
    {"city_id":"1364","city_name":"Barughutu","state_id":"16"},
    {"city_id":"1365","city_name":"Barwadih","state_id":"16"},
    {"city_id":"1366","city_name":"Basaria","state_id":"16"},
    {"city_id":"1367","city_name":"Basukinath","state_id":"16"},
    {"city_id":"1368","city_name":"Bermo","state_id":"16"},
    {"city_id":"1369","city_name":"Bhagatdih","state_id":"16"},
    {"city_id":"1370","city_name":"Bhaurah","state_id":"16"},
    {"city_id":"1371","city_name":"Bhojudih","state_id":"16"},
    {"city_id":"1372","city_name":"Bhuli","state_id":"16"},
    {"city_id":"1373","city_name":"Bokaro","state_id":"16"},
    {"city_id":"1374","city_name":"Borio Bazar","state_id":"16"},
    {"city_id":"1375","city_name":"Bundu","state_id":"16"},
    {"city_id":"1376","city_name":"Chaibasa","state_id":"16"},
    {"city_id":"1377","city_name":"Chaitudih","state_id":"16"},
    {"city_id":"1378","city_name":"Chakradharpur","state_id":"16"},
    {"city_id":"1379","city_name":"Chakulia","state_id":"16"},
    {"city_id":"1380","city_name":"Chandaur","state_id":"16"},
    {"city_id":"1381","city_name":"Chandil","state_id":"16"},
    {"city_id":"1382","city_name":"Chandrapura","state_id":"16"},
    {"city_id":"1383","city_name":"Chas","state_id":"16"},
    {"city_id":"1384","city_name":"Chatra","state_id":"16"},
    {"city_id":"1385","city_name":"Chhatatanr","state_id":"16"},
    {"city_id":"1386","city_name":"Chhotaputki","state_id":"16"},
    {"city_id":"1387","city_name":"Chiria","state_id":"16"},
    {"city_id":"1388","city_name":"Chirkunda","state_id":"16"},
    {"city_id":"1389","city_name":"Churi","state_id":"16"},
    {"city_id":"1390","city_name":"Daltenganj","state_id":"16"},
    {"city_id":"1391","city_name":"Danguwapasi","state_id":"16"},
    {"city_id":"1392","city_name":"Dari","state_id":"16"},
    {"city_id":"1393","city_name":"Deoghar","state_id":"16"},
    {"city_id":"1394","city_name":"Deorikalan","state_id":"16"},
    {"city_id":"1395","city_name":"Devghar","state_id":"16"},
    {"city_id":"1396","city_name":"Dhanbad","state_id":"16"},
    {"city_id":"1397","city_name":"Dhanwar","state_id":"16"},
    {"city_id":"1398","city_name":"Dhaunsar","state_id":"16"},
    {"city_id":"1399","city_name":"Dugda","state_id":"16"},
    {"city_id":"1400","city_name":"Dumarkunda","state_id":"16"},
    {"city_id":"1401","city_name":"Dumka","state_id":"16"},
    {"city_id":"1402","city_name":"Egarkunr","state_id":"16"},
    {"city_id":"1403","city_name":"Gadhra","state_id":"16"},
    {"city_id":"1404","city_name":"Garwa","state_id":"16"},
    {"city_id":"1405","city_name":"Ghatsila","state_id":"16"},
    {"city_id":"1406","city_name":"Ghorabandha","state_id":"16"},
    {"city_id":"1407","city_name":"Gidi","state_id":"16"},
    {"city_id":"1408","city_name":"Giridih","state_id":"16"},
    {"city_id":"1409","city_name":"Gobindpur","state_id":"16"},
    {"city_id":"1410","city_name":"Godda","state_id":"16"},
    {"city_id":"1411","city_name":"Godhar","state_id":"16"},
    {"city_id":"1412","city_name":"Golphalbari","state_id":"16"},
    {"city_id":"1413","city_name":"Gomoh","state_id":"16"},
    {"city_id":"1414","city_name":"Gua","state_id":"16"},
    {"city_id":"1415","city_name":"Gumia","state_id":"16"},
    {"city_id":"1416","city_name":"Gumla","state_id":"16"},
    {"city_id":"1417","city_name":"Haludbani","state_id":"16"},
    {"city_id":"1418","city_name":"Hazaribag","state_id":"16"},
    {"city_id":"1419","city_name":"Hesla","state_id":"16"},
    {"city_id":"1420","city_name":"Husainabad","state_id":"16"},
    {"city_id":"1421","city_name":"Isri","state_id":"16"},
    {"city_id":"1422","city_name":"Jadugora","state_id":"16"},
    {"city_id":"1423","city_name":"Jagannathpur","state_id":"16"},
    {"city_id":"1424","city_name":"Jamadoba","state_id":"16"},
    {"city_id":"1425","city_name":"Jamshedpur","state_id":"16"},
    {"city_id":"1426","city_name":"Jamtara","state_id":"16"},
    {"city_id":"1427","city_name":"Jarangdih","state_id":"16"},
    {"city_id":"1428","city_name":"Jaridih","state_id":"16"},
    {"city_id":"1429","city_name":"Jasidih","state_id":"16"},
    {"city_id":"1430","city_name":"Jena","state_id":"16"},
    {"city_id":"1431","city_name":"Jharia","state_id":"16"},
    {"city_id":"1432","city_name":"Jharia Khas","state_id":"16"},
    {"city_id":"1433","city_name":"Jhinkpani","state_id":"16"},
    {"city_id":"1434","city_name":"Jhumri Tilaiya","state_id":"16"},
    {"city_id":"1435","city_name":"Jorapokhar","state_id":"16"},
    {"city_id":"1436","city_name":"Jugsalai","state_id":"16"},
    {"city_id":"1437","city_name":"Kailudih","state_id":"16"},
    {"city_id":"1438","city_name":"Kalikapur","state_id":"16"},
    {"city_id":"1439","city_name":"Kandra","state_id":"16"},
    {"city_id":"1440","city_name":"Kanke","state_id":"16"},
    {"city_id":"1441","city_name":"Katras","state_id":"16"},
    {"city_id":"1442","city_name":"Kedla","state_id":"16"},
    {"city_id":"1443","city_name":"Kenduadih","state_id":"16"},
    {"city_id":"1444","city_name":"Kharkhari","state_id":"16"},
    {"city_id":"1445","city_name":"Kharsawan","state_id":"16"},
    {"city_id":"1446","city_name":"Khelari","state_id":"16"},
    {"city_id":"1447","city_name":"Khunti","state_id":"16"},
    {"city_id":"1448","city_name":"Kiri Buru","state_id":"16"},
    {"city_id":"1449","city_name":"Kiriburu","state_id":"16"},
    {"city_id":"1450","city_name":"Kodarma","state_id":"16"},
    {"city_id":"1451","city_name":"Kuju","state_id":"16"},
    {"city_id":"1452","city_name":"Kurpania","state_id":"16"},
    {"city_id":"1453","city_name":"Kustai","state_id":"16"},
    {"city_id":"1454","city_name":"Lakarka","state_id":"16"},
    {"city_id":"1455","city_name":"Lapanga","state_id":"16"},
    {"city_id":"1456","city_name":"Latehar","state_id":"16"},
    {"city_id":"1457","city_name":"Lohardaga","state_id":"16"},
    {"city_id":"1458","city_name":"Loiya","state_id":"16"},
    {"city_id":"1459","city_name":"Loyabad","state_id":"16"},
    {"city_id":"1460","city_name":"Madhupur","state_id":"16"},
    {"city_id":"1461","city_name":"Mahesh Mundi","state_id":"16"},
    {"city_id":"1462","city_name":"Maithon","state_id":"16"},
    {"city_id":"1463","city_name":"Malkera","state_id":"16"},
    {"city_id":"1464","city_name":"Mango","state_id":"16"},
    {"city_id":"1465","city_name":"Manoharpur","state_id":"16"},
    {"city_id":"1466","city_name":"Marma","state_id":"16"},
    {"city_id":"1467","city_name":"Meghahatuburu Forest village","state_id":"16"},
    {"city_id":"1468","city_name":"Mera","state_id":"16"},
    {"city_id":"1469","city_name":"Meru","state_id":"16"},
    {"city_id":"1470","city_name":"Mihijam","state_id":"16"},
    {"city_id":"1471","city_name":"Mugma","state_id":"16"},
    {"city_id":"1472","city_name":"Muri","state_id":"16"},
    {"city_id":"1473","city_name":"Mushabani","state_id":"16"},
    {"city_id":"1474","city_name":"Nagri Kalan","state_id":"16"},
    {"city_id":"1475","city_name":"Netarhat","state_id":"16"},
    {"city_id":"1476","city_name":"Nirsa","state_id":"16"},
    {"city_id":"1477","city_name":"Noamundi","state_id":"16"},
    {"city_id":"1478","city_name":"Okni","state_id":"16"},
    {"city_id":"1479","city_name":"Orla","state_id":"16"},
    {"city_id":"1480","city_name":"Pakaur","state_id":"16"},
    {"city_id":"1481","city_name":"Palamau","state_id":"16"},
    {"city_id":"1482","city_name":"Palawa","state_id":"16"},
    {"city_id":"1483","city_name":"Panchet","state_id":"16"},
    {"city_id":"1484","city_name":"Panrra","state_id":"16"},
    {"city_id":"1485","city_name":"Paratdih","state_id":"16"},
    {"city_id":"1486","city_name":"Pathardih","state_id":"16"},
    {"city_id":"1487","city_name":"Patratu","state_id":"16"},
    {"city_id":"1488","city_name":"Phusro","state_id":"16"},
    {"city_id":"1489","city_name":"Pondar Kanali","state_id":"16"},
    {"city_id":"1490","city_name":"Rajmahal","state_id":"16"},
    {"city_id":"1491","city_name":"Ramgarh","state_id":"16"},
    {"city_id":"1492","city_name":"Ranchi","state_id":"16"},
    {"city_id":"1493","city_name":"Ray","state_id":"16"},
    {"city_id":"1494","city_name":"Rehla","state_id":"16"},
    {"city_id":"1495","city_name":"Religara","state_id":"16"},
    {"city_id":"1496","city_name":"Rohraband","state_id":"16"},
    {"city_id":"1497","city_name":"Sahibganj","state_id":"16"},
    {"city_id":"1498","city_name":"Sahnidih","state_id":"16"},
    {"city_id":"1499","city_name":"Saraidhela","state_id":"16"},
    {"city_id":"1500","city_name":"Saraikela","state_id":"16"},
    {"city_id":"1501","city_name":"Sarjamda","state_id":"16"},
    {"city_id":"1502","city_name":"Saunda","state_id":"16"},
    {"city_id":"1503","city_name":"Sewai","state_id":"16"},
    {"city_id":"1504","city_name":"Sijhua","state_id":"16"},
    {"city_id":"1505","city_name":"Sijua","state_id":"16"},
    {"city_id":"1506","city_name":"Simdega","state_id":"16"},
    {"city_id":"1507","city_name":"Sindari","state_id":"16"},
    {"city_id":"1508","city_name":"Sinduria","state_id":"16"},
    {"city_id":"1509","city_name":"Sini","state_id":"16"},
    {"city_id":"1510","city_name":"Sirka","state_id":"16"},
    {"city_id":"1511","city_name":"Siuliban","state_id":"16"},
    {"city_id":"1512","city_name":"Surubera","state_id":"16"},
    {"city_id":"1513","city_name":"Tati","state_id":"16"},
    {"city_id":"1514","city_name":"Tenudam","state_id":"16"},
    {"city_id":"1515","city_name":"Tisra","state_id":"16"},
    {"city_id":"1516","city_name":"Topa","state_id":"16"},
    {"city_id":"1517","city_name":"Topchanchi","state_id":"16"},
    {"city_id":"1518","city_name":"Adityanagar","state_id":"17"},
    {"city_id":"1519","city_name":"Adityapatna","state_id":"17"},
    {"city_id":"1520","city_name":"Afzalpur","state_id":"17"},
    {"city_id":"1521","city_name":"Ajjampur","state_id":"17"},
    {"city_id":"1522","city_name":"Aland","state_id":"17"},
    {"city_id":"1523","city_name":"Almatti Sitimani","state_id":"17"},
    {"city_id":"1524","city_name":"Alnavar","state_id":"17"},
    {"city_id":"1525","city_name":"Alur","state_id":"17"},
    {"city_id":"1526","city_name":"Ambikanagara","state_id":"17"},
    {"city_id":"1527","city_name":"Anekal","state_id":"17"},
    {"city_id":"1528","city_name":"Ankola","state_id":"17"},
    {"city_id":"1529","city_name":"Annigeri","state_id":"17"},
    {"city_id":"1530","city_name":"Arkalgud","state_id":"17"},
    {"city_id":"1531","city_name":"Arsikere","state_id":"17"},
    {"city_id":"1532","city_name":"Athni","state_id":"17"},
    {"city_id":"1533","city_name":"Aurad","state_id":"17"},
    {"city_id":"1534","city_name":"Badagavettu","state_id":"17"},
    {"city_id":"1535","city_name":"Badami","state_id":"17"},
    {"city_id":"1536","city_name":"Bagalkot","state_id":"17"},
    {"city_id":"1537","city_name":"Bagepalli","state_id":"17"},
    {"city_id":"1538","city_name":"Bailhongal","state_id":"17"},
    {"city_id":"1539","city_name":"Baindur","state_id":"17"},
    {"city_id":"1540","city_name":"Bajala","state_id":"17"},
    {"city_id":"1541","city_name":"Bajpe","state_id":"17"},
    {"city_id":"1542","city_name":"Banavar","state_id":"17"},
    {"city_id":"1543","city_name":"Bangarapet","state_id":"17"},
    {"city_id":"1544","city_name":"Bankapura","state_id":"17"},
    {"city_id":"1545","city_name":"Bannur","state_id":"17"},
    {"city_id":"1546","city_name":"Bantwal","state_id":"17"},
    {"city_id":"1547","city_name":"Basavakalyan","state_id":"17"},
    {"city_id":"1548","city_name":"Basavana Bagevadi","state_id":"17"},
    {"city_id":"1549","city_name":"Belagula","state_id":"17"},
    {"city_id":"1550","city_name":"Belakavadiq","state_id":"17"},
    {"city_id":"1551","city_name":"Belgaum","state_id":"17"},
    {"city_id":"1552","city_name":"Belgaum Cantonment","state_id":"17"},
    {"city_id":"1553","city_name":"Bellary","state_id":"17"},
    {"city_id":"1554","city_name":"Belluru","state_id":"17"},
    {"city_id":"1555","city_name":"Beltangadi","state_id":"17"},
    {"city_id":"1556","city_name":"Belur","state_id":"17"},
    {"city_id":"1557","city_name":"Belvata","state_id":"17"},
    {"city_id":"1558","city_name":"Bengaluru","state_id":"17"},
    {"city_id":"1559","city_name":"Bhadravati","state_id":"17"},
    {"city_id":"1560","city_name":"Bhalki","state_id":"17"},
    {"city_id":"1561","city_name":"Bhatkal","state_id":"17"},
    {"city_id":"1562","city_name":"Bhimarayanagudi","state_id":"17"},
    {"city_id":"1563","city_name":"Bhogadi","state_id":"17"},
    {"city_id":"1564","city_name":"Bidar","state_id":"17"},
    {"city_id":"1565","city_name":"Bijapur","state_id":"17"},
    {"city_id":"1566","city_name":"Bilgi","state_id":"17"},
    {"city_id":"1567","city_name":"Birur","state_id":"17"},
    {"city_id":"1568","city_name":"Bommanahalli","state_id":"17"},
    {"city_id":"1569","city_name":"Bommasandra","state_id":"17"},
    {"city_id":"1570","city_name":"Byadgi","state_id":"17"},
    {"city_id":"1571","city_name":"Byatarayanapura","state_id":"17"},
    {"city_id":"1572","city_name":"Chakranagar Colony","state_id":"17"},
    {"city_id":"1573","city_name":"Challakere","state_id":"17"},
    {"city_id":"1574","city_name":"Chamrajnagar","state_id":"17"},
    {"city_id":"1575","city_name":"Chamundi Betta","state_id":"17"},
    {"city_id":"1576","city_name":"Channagiri","state_id":"17"},
    {"city_id":"1577","city_name":"Channapatna","state_id":"17"},
    {"city_id":"1578","city_name":"Channarayapatna","state_id":"17"},
    {"city_id":"1579","city_name":"Chickballapur","state_id":"17"},
    {"city_id":"1580","city_name":"Chik Ballapur","state_id":"17"},
    {"city_id":"1581","city_name":"Chikkaballapur","state_id":"17"},
    {"city_id":"1582","city_name":"Chikmagalur","state_id":"17"},
    {"city_id":"1583","city_name":"Chiknayakanhalli","state_id":"17"},
    {"city_id":"1584","city_name":"Chikodi","state_id":"17"},
    {"city_id":"1585","city_name":"Chincholi","state_id":"17"},
    {"city_id":"1586","city_name":"Chintamani","state_id":"17"},
    {"city_id":"1587","city_name":"Chitaguppa","state_id":"17"},
    {"city_id":"1588","city_name":"Chitapur","state_id":"17"},
    {"city_id":"1589","city_name":"Chitradurga","state_id":"17"},
    {"city_id":"1590","city_name":"Coorg","state_id":"17"},
    {"city_id":"1591","city_name":"Dandeli","state_id":"17"},
    {"city_id":"1592","city_name":"Dargajogihalli","state_id":"17"},
    {"city_id":"1593","city_name":"Dasarahalli","state_id":"17"},
    {"city_id":"1594","city_name":"Davangere","state_id":"17"},
    {"city_id":"1595","city_name":"Devadurga","state_id":"17"},
    {"city_id":"1596","city_name":"Devagiri","state_id":"17"},
    {"city_id":"1597","city_name":"Devanhalli","state_id":"17"},
    {"city_id":"1598","city_name":"Dharwar","state_id":"17"},
    {"city_id":"1599","city_name":"Dhupdal","state_id":"17"},
    {"city_id":"1600","city_name":"Dod Ballapur","state_id":"17"},
    {"city_id":"1601","city_name":"Donimalai","state_id":"17"},
    {"city_id":"1602","city_name":"Gadag","state_id":"17"},
    {"city_id":"1603","city_name":"Gajendragarh","state_id":"17"},
    {"city_id":"1604","city_name":"Ganeshgudi","state_id":"17"},
    {"city_id":"1605","city_name":"Gangawati","state_id":"17"},
    {"city_id":"1606","city_name":"Gangoli","state_id":"17"},
    {"city_id":"1607","city_name":"Gauribidanur","state_id":"17"},
    {"city_id":"1608","city_name":"Gokak","state_id":"17"},
    {"city_id":"1609","city_name":"Gokak Falls","state_id":"17"},
    {"city_id":"1610","city_name":"Gonikoppal","state_id":"17"},
    {"city_id":"1611","city_name":"Gorur","state_id":"17"},
    {"city_id":"1612","city_name":"Gottikere","state_id":"17"},
    {"city_id":"1613","city_name":"Gubbi","state_id":"17"},
    {"city_id":"1614","city_name":"Gudibanda","state_id":"17"},
    {"city_id":"1615","city_name":"Gulbarga","state_id":"17"},
    {"city_id":"1616","city_name":"Guledgudda","state_id":"17"},
    {"city_id":"1617","city_name":"Gundlupet","state_id":"17"},
    {"city_id":"1618","city_name":"Gurmatkal","state_id":"17"},
    {"city_id":"1619","city_name":"Haliyal","state_id":"17"},
    {"city_id":"1620","city_name":"Hangal","state_id":"17"},
    {"city_id":"1621","city_name":"Harihar","state_id":"17"},
    {"city_id":"1622","city_name":"Harpanahalli","state_id":"17"},
    {"city_id":"1623","city_name":"Hassan","state_id":"17"},
    {"city_id":"1624","city_name":"Hatti","state_id":"17"},
    {"city_id":"1625","city_name":"Hatti Gold Mines","state_id":"17"},
    {"city_id":"1626","city_name":"Haveri","state_id":"17"},
    {"city_id":"1627","city_name":"Hebbagodi","state_id":"17"},
    {"city_id":"1628","city_name":"Hebbalu","state_id":"17"},
    {"city_id":"1629","city_name":"Hebri","state_id":"17"},
    {"city_id":"1630","city_name":"Heggadadevanakote","state_id":"17"},
    {"city_id":"1631","city_name":"Herohalli","state_id":"17"},
    {"city_id":"1632","city_name":"Hidkal","state_id":"17"},
    {"city_id":"1633","city_name":"Hindalgi","state_id":"17"},
    {"city_id":"1634","city_name":"Hirekerur","state_id":"17"},
    {"city_id":"1635","city_name":"Hiriyur","state_id":"17"},
    {"city_id":"1636","city_name":"Holalkere","state_id":"17"},
    {"city_id":"1637","city_name":"Hole Narsipur","state_id":"17"},
    {"city_id":"1638","city_name":"Homnabad","state_id":"17"},
    {"city_id":"1639","city_name":"Honavar","state_id":"17"},
    {"city_id":"1640","city_name":"Honnali","state_id":"17"},
    {"city_id":"1641","city_name":"Hosakote","state_id":"17"},
    {"city_id":"1642","city_name":"Hosanagara","state_id":"17"},
    {"city_id":"1643","city_name":"Hosangadi","state_id":"17"},
    {"city_id":"1644","city_name":"Hosdurga","state_id":"17"},
    {"city_id":"1645","city_name":"Hoskote","state_id":"17"},
    {"city_id":"1646","city_name":"Hospet","state_id":"17"},
    {"city_id":"1647","city_name":"Hubli","state_id":"17"},
    {"city_id":"1648","city_name":"Hukeri","state_id":"17"},
    {"city_id":"1649","city_name":"Hunasagi","state_id":"17"},
    {"city_id":"1650","city_name":"Hunasamaranahalli","state_id":"17"},
    {"city_id":"1651","city_name":"Hungund","state_id":"17"},
    {"city_id":"1652","city_name":"Hunsur","state_id":"17"},
    {"city_id":"1653","city_name":"Huvina Hadagalli","state_id":"17"},
    {"city_id":"1654","city_name":"Ilkal","state_id":"17"},
    {"city_id":"1655","city_name":"Indi","state_id":"17"},
    {"city_id":"1656","city_name":"Jagalur","state_id":"17"},
    {"city_id":"1657","city_name":"Jamkhandi","state_id":"17"},
    {"city_id":"1658","city_name":"Jevargi","state_id":"17"},
    {"city_id":"1659","city_name":"Jog Falls","state_id":"17"},
    {"city_id":"1660","city_name":"Kabini Colony","state_id":"17"},
    {"city_id":"1661","city_name":"Kadur","state_id":"17"},
    {"city_id":"1662","city_name":"Kalghatgi","state_id":"17"},
    {"city_id":"1663","city_name":"Kamalapuram","state_id":"17"},
    {"city_id":"1664","city_name":"Kampli","state_id":"17"},
    {"city_id":"1665","city_name":"Kanakapura","state_id":"17"},
    {"city_id":"1666","city_name":"Kangrali BK","state_id":"17"},
    {"city_id":"1667","city_name":"Kangrali KH","state_id":"17"},
    {"city_id":"1668","city_name":"Kannur","state_id":"17"},
    {"city_id":"1669","city_name":"Karkala","state_id":"17"},
    {"city_id":"1670","city_name":"Karwar","state_id":"17"},
    {"city_id":"1671","city_name":"Kemminja","state_id":"17"},
    {"city_id":"1672","city_name":"Kengeri","state_id":"17"},
    {"city_id":"1673","city_name":"Kerur","state_id":"17"},
    {"city_id":"1674","city_name":"Khanapur","state_id":"17"},
    {"city_id":"1675","city_name":"Kodigenahalli","state_id":"17"},
    {"city_id":"1676","city_name":"Kodiyal","state_id":"17"},
    {"city_id":"1677","city_name":"Kodlipet","state_id":"17"},
    {"city_id":"1678","city_name":"Kolar","state_id":"17"},
    {"city_id":"1679","city_name":"Kollegal","state_id":"17"},
    {"city_id":"1680","city_name":"Konanakunte","state_id":"17"},
    {"city_id":"1681","city_name":"Konanur","state_id":"17"},
    {"city_id":"1682","city_name":"Konnur","state_id":"17"},
    {"city_id":"1683","city_name":"Koppa","state_id":"17"},
    {"city_id":"1684","city_name":"Koppal","state_id":"17"},
    {"city_id":"1685","city_name":"Koratagere","state_id":"17"},
    {"city_id":"1686","city_name":"Kotekara","state_id":"17"},
    {"city_id":"1687","city_name":"Kothnur","state_id":"17"},
    {"city_id":"1688","city_name":"Kotturu","state_id":"17"},
    {"city_id":"1689","city_name":"Krishnapura","state_id":"17"},
    {"city_id":"1690","city_name":"Krishnarajanagar","state_id":"17"},
    {"city_id":"1691","city_name":"Krishnarajapura","state_id":"17"},
    {"city_id":"1692","city_name":"Krishnarajasagara","state_id":"17"},
    {"city_id":"1693","city_name":"Krishnarajpet","state_id":"17"},
    {"city_id":"1694","city_name":"Kudchi","state_id":"17"},
    {"city_id":"1695","city_name":"Kudligi","state_id":"17"},
    {"city_id":"1696","city_name":"Kudremukh","state_id":"17"},
    {"city_id":"1697","city_name":"Kumsi","state_id":"17"},
    {"city_id":"1698","city_name":"Kumta","state_id":"17"},
    {"city_id":"1699","city_name":"Kundapura","state_id":"17"},
    {"city_id":"1700","city_name":"Kundgol","state_id":"17"},
    {"city_id":"1701","city_name":"Kunigal","state_id":"17"},
    {"city_id":"1702","city_name":"Kurgunta","state_id":"17"},
    {"city_id":"1703","city_name":"Kushalnagar","state_id":"17"},
    {"city_id":"1704","city_name":"Kushtagi","state_id":"17"},
    {"city_id":"1705","city_name":"Kyathanahalli","state_id":"17"},
    {"city_id":"1706","city_name":"Lakshmeshwar","state_id":"17"},
    {"city_id":"1707","city_name":"Lingsugur","state_id":"17"},
    {"city_id":"1708","city_name":"Londa","state_id":"17"},
    {"city_id":"1709","city_name":"Maddur","state_id":"17"},
    {"city_id":"1710","city_name":"Madhugiri","state_id":"17"},
    {"city_id":"1711","city_name":"Madikeri","state_id":"17"},
    {"city_id":"1712","city_name":"Magadi","state_id":"17"},
    {"city_id":"1713","city_name":"Magod Falls","state_id":"17"},
    {"city_id":"1714","city_name":"Mahadeswara Hills","state_id":"17"},
    {"city_id":"1715","city_name":"Mahadevapura","state_id":"17"},
    {"city_id":"1716","city_name":"Mahalingpur","state_id":"17"},
    {"city_id":"1717","city_name":"Maisuru","state_id":"17"},
    {"city_id":"1718","city_name":"Maisuru Cantonment","state_id":"17"},
    {"city_id":"1719","city_name":"Malavalli","state_id":"17"},
    {"city_id":"1720","city_name":"Mallar","state_id":"17"},
    {"city_id":"1721","city_name":"Malpe","state_id":"17"},
    {"city_id":"1722","city_name":"Malur","state_id":"17"},
    {"city_id":"1723","city_name":"Manchenahalli","state_id":"17"},
    {"city_id":"1724","city_name":"Mandya","state_id":"17"},
    {"city_id":"1725","city_name":"Mangalore","state_id":"17"},
    {"city_id":"1726","city_name":"Mangaluru","state_id":"17"},
    {"city_id":"1727","city_name":"Manipal","state_id":"17"},
    {"city_id":"1728","city_name":"Manvi","state_id":"17"},
    {"city_id":"1729","city_name":"Maski","state_id":"17"},
    {"city_id":"1730","city_name":"Mastikatte Colony","state_id":"17"},
    {"city_id":"1731","city_name":"Mayakonda","state_id":"17"},
    {"city_id":"1732","city_name":"Melukote","state_id":"17"},
    {"city_id":"1733","city_name":"Molakalmuru","state_id":"17"},
    {"city_id":"1734","city_name":"Mudalgi","state_id":"17"},
    {"city_id":"1735","city_name":"Mudbidri","state_id":"17"},
    {"city_id":"1736","city_name":"Muddebihal","state_id":"17"},
    {"city_id":"1737","city_name":"Mudgal","state_id":"17"},
    {"city_id":"1738","city_name":"Mudhol","state_id":"17"},
    {"city_id":"1739","city_name":"Mudigere","state_id":"17"},
    {"city_id":"1740","city_name":"Mudushedde","state_id":"17"},
    {"city_id":"1741","city_name":"Mulbagal","state_id":"17"},
    {"city_id":"1742","city_name":"Mulgund","state_id":"17"},
    {"city_id":"1743","city_name":"Mulki","state_id":"17"},
    {"city_id":"1744","city_name":"Mulur","state_id":"17"},
    {"city_id":"1745","city_name":"Mundargi","state_id":"17"},
    {"city_id":"1746","city_name":"Mundgod","state_id":"17"},
    {"city_id":"1747","city_name":"Munirabad","state_id":"17"},
    {"city_id":"1748","city_name":"Munnur","state_id":"17"},
    {"city_id":"1749","city_name":"Murudeshwara","state_id":"17"},
    {"city_id":"1750","city_name":"Mysore","state_id":"17"},
    {"city_id":"1751","city_name":"Nagamangala","state_id":"17"},
    {"city_id":"1752","city_name":"Nanjangud","state_id":"17"},
    {"city_id":"1753","city_name":"Naragund","state_id":"17"},
    {"city_id":"1754","city_name":"Narasimharajapura","state_id":"17"},
    {"city_id":"1755","city_name":"Naravi","state_id":"17"},
    {"city_id":"1756","city_name":"Narayanpur","state_id":"17"},
    {"city_id":"1757","city_name":"Naregal","state_id":"17"},
    {"city_id":"1758","city_name":"Navalgund","state_id":"17"},
    {"city_id":"1759","city_name":"Nelmangala","state_id":"17"},
    {"city_id":"1760","city_name":"Nipani","state_id":"17"},
    {"city_id":"1761","city_name":"Nitte","state_id":"17"},
    {"city_id":"1762","city_name":"Nyamati","state_id":"17"},
    {"city_id":"1763","city_name":"Padu","state_id":"17"},
    {"city_id":"1764","city_name":"Pandavapura","state_id":"17"},
    {"city_id":"1765","city_name":"Pattanagere","state_id":"17"},
    {"city_id":"1766","city_name":"Pavagada","state_id":"17"},
    {"city_id":"1767","city_name":"Piriyapatna","state_id":"17"},
    {"city_id":"1768","city_name":"Ponnampet","state_id":"17"},
    {"city_id":"1769","city_name":"Puttur","state_id":"17"},
    {"city_id":"1770","city_name":"Rabkavi","state_id":"17"},
    {"city_id":"1771","city_name":"Raichur","state_id":"17"},
    {"city_id":"1772","city_name":"Ramanagaram","state_id":"17"},
    {"city_id":"1773","city_name":"Ramdurg","state_id":"17"},
    {"city_id":"1774","city_name":"Ranibennur","state_id":"17"},
    {"city_id":"1775","city_name":"Raybag","state_id":"17"},
    {"city_id":"1776","city_name":"Robertsonpet","state_id":"17"},
    {"city_id":"1777","city_name":"Ron","state_id":"17"},
    {"city_id":"1778","city_name":"Sadalgi","state_id":"17"},
    {"city_id":"1779","city_name":"Sagar","state_id":"17"},
    {"city_id":"1780","city_name":"Sakleshpur","state_id":"17"},
    {"city_id":"1781","city_name":"Saligram","state_id":"17"},
    {"city_id":"1782","city_name":"Sandur","state_id":"17"},
    {"city_id":"1783","city_name":"Sanivarsante","state_id":"17"},
    {"city_id":"1784","city_name":"Sankeshwar","state_id":"17"},
    {"city_id":"1785","city_name":"Sargur","state_id":"17"},
    {"city_id":"1786","city_name":"Sathyamangala","state_id":"17"},
    {"city_id":"1787","city_name":"Saundatti Yellamma","state_id":"17"},
    {"city_id":"1788","city_name":"Savanur","state_id":"17"},
    {"city_id":"1789","city_name":"Sedam","state_id":"17"},
    {"city_id":"1790","city_name":"Shahabad","state_id":"17"},
    {"city_id":"1791","city_name":"Shahabad A.C.C.","state_id":"17"},
    {"city_id":"1792","city_name":"Shahapur","state_id":"17"},
    {"city_id":"1793","city_name":"Shahpur","state_id":"17"},
    {"city_id":"1794","city_name":"Shaktinagar","state_id":"17"},
    {"city_id":"1795","city_name":"Shiggaon","state_id":"17"},
    {"city_id":"1796","city_name":"Shikarpur","state_id":"17"},
    {"city_id":"1797","city_name":"Shimoga","state_id":"17"},
    {"city_id":"1798","city_name":"Shirhatti","state_id":"17"},
    {"city_id":"1799","city_name":"Shorapur","state_id":"17"},
    {"city_id":"1800","city_name":"Shravanabelagola","state_id":"17"},
    {"city_id":"1801","city_name":"Shrirangapattana","state_id":"17"},
    {"city_id":"1802","city_name":"Siddapur","state_id":"17"},
    {"city_id":"1803","city_name":"Sidlaghatta","state_id":"17"},
    {"city_id":"1804","city_name":"Sindgi","state_id":"17"},
    {"city_id":"1805","city_name":"Sindhnur","state_id":"17"},
    {"city_id":"1806","city_name":"Sira","state_id":"17"},
    {"city_id":"1807","city_name":"Sirakoppa","state_id":"17"},
    {"city_id":"1808","city_name":"Sirsi","state_id":"17"},
    {"city_id":"1809","city_name":"Siruguppa","state_id":"17"},
    {"city_id":"1810","city_name":"Someshwar","state_id":"17"},
    {"city_id":"1811","city_name":"Somvarpet","state_id":"17"},
    {"city_id":"1812","city_name":"Sorab","state_id":"17"},
    {"city_id":"1813","city_name":"Sringeri","state_id":"17"},
    {"city_id":"1814","city_name":"Srinivaspur","state_id":"17"},
    {"city_id":"1815","city_name":"Sulya","state_id":"17"},
    {"city_id":"1816","city_name":"Suntikopa","state_id":"17"},
    {"city_id":"1817","city_name":"Talikota","state_id":"17"},
    {"city_id":"1818","city_name":"Tarikera","state_id":"17"},
    {"city_id":"1819","city_name":"Tekkalakota","state_id":"17"},
    {"city_id":"1820","city_name":"Terdal","state_id":"17"},
    {"city_id":"1821","city_name":"Thokur","state_id":"17"},
    {"city_id":"1822","city_name":"Thumbe","state_id":"17"},
    {"city_id":"1823","city_name":"Tiptur","state_id":"17"},
    {"city_id":"1824","city_name":"Tirthahalli","state_id":"17"},
    {"city_id":"1825","city_name":"Tirumakudal Narsipur","state_id":"17"},
    {"city_id":"1826","city_name":"Tonse","state_id":"17"},
    {"city_id":"1827","city_name":"Tumkur","state_id":"17"},
    {"city_id":"1828","city_name":"Turuvekere","state_id":"17"},
    {"city_id":"1829","city_name":"Udupi","state_id":"17"},
    {"city_id":"1830","city_name":"Ullal","state_id":"17"},
    {"city_id":"1831","city_name":"Uttarahalli","state_id":"17"},
    {"city_id":"1832","city_name":"Venkatapura","state_id":"17"},
    {"city_id":"1833","city_name":"Vijayapura","state_id":"17"},
    {"city_id":"1834","city_name":"Virarajendrapet","state_id":"17"},
    {"city_id":"1835","city_name":"Wadi","state_id":"17"},
    {"city_id":"1836","city_name":"Wadi A.C.C.","state_id":"17"},
    {"city_id":"1837","city_name":"Yadgir","state_id":"17"},
    {"city_id":"1838","city_name":"Yelahanka","state_id":"17"},
    {"city_id":"1839","city_name":"Yelandur","state_id":"17"},
    {"city_id":"1840","city_name":"Yelbarga","state_id":"17"},
    {"city_id":"1841","city_name":"Yellapur","state_id":"17"},
    {"city_id":"1842","city_name":"Yenagudde","state_id":"17"},
    {"city_id":"1843","city_name":"Adimaly","state_id":"19"},
    {"city_id":"1844","city_name":"Adoor","state_id":"19"},
    {"city_id":"1845","city_name":"Adur","state_id":"19"},
    {"city_id":"1846","city_name":"Akathiyur","state_id":"19"},
    {"city_id":"1847","city_name":"Alangad","state_id":"19"},
    {"city_id":"1848","city_name":"Alappuzha","state_id":"19"},
    {"city_id":"1849","city_name":"Aluva","state_id":"19"},
    {"city_id":"1850","city_name":"Ancharakandy","state_id":"19"},
    {"city_id":"1851","city_name":"Angamaly","state_id":"19"},
    {"city_id":"1852","city_name":"Aroor","state_id":"19"},
    {"city_id":"1853","city_name":"Arukutti","state_id":"19"},
    {"city_id":"1854","city_name":"Attingal","state_id":"19"},
    {"city_id":"1855","city_name":"Avinissery","state_id":"19"},
    {"city_id":"1856","city_name":"Azhikode North","state_id":"19"},
    {"city_id":"1857","city_name":"Azhikode South","state_id":"19"},
    {"city_id":"1858","city_name":"Azhiyur","state_id":"19"},
    {"city_id":"1859","city_name":"Balussery","state_id":"19"},
    {"city_id":"1860","city_name":"Bangramanjeshwar","state_id":"19"},
    {"city_id":"1861","city_name":"Beypur","state_id":"19"},
    {"city_id":"1862","city_name":"Brahmakulam","state_id":"19"},
    {"city_id":"1863","city_name":"Chala","state_id":"19"},
    {"city_id":"1864","city_name":"Chalakudi","state_id":"19"},
    {"city_id":"1865","city_name":"Changanacheri","state_id":"19"},
    {"city_id":"1866","city_name":"Chauwara","state_id":"19"},
    {"city_id":"1867","city_name":"Chavakkad","state_id":"19"},
    {"city_id":"1868","city_name":"Chelakkara","state_id":"19"},
    {"city_id":"1869","city_name":"Chelora","state_id":"19"},
    {"city_id":"1870","city_name":"Chendamangalam","state_id":"19"},
    {"city_id":"1871","city_name":"Chengamanad","state_id":"19"},
    {"city_id":"1872","city_name":"Chengannur","state_id":"19"},
    {"city_id":"1873","city_name":"Cheranallur","state_id":"19"},
    {"city_id":"1874","city_name":"Cheriyakadavu","state_id":"19"},
    {"city_id":"1875","city_name":"Cherthala","state_id":"19"},
    {"city_id":"1876","city_name":"Cherukunnu","state_id":"19"},
    {"city_id":"1877","city_name":"Cheruthazham","state_id":"19"},
    {"city_id":"1878","city_name":"Cheruvannur","state_id":"19"},
    {"city_id":"1879","city_name":"Cheruvattur","state_id":"19"},
    {"city_id":"1880","city_name":"Chevvur","state_id":"19"},
    {"city_id":"1881","city_name":"Chirakkal","state_id":"19"},
    {"city_id":"1882","city_name":"Chittur","state_id":"19"},
    {"city_id":"1883","city_name":"Chockli","state_id":"19"},
    {"city_id":"1884","city_name":"Churnikkara","state_id":"19"},
    {"city_id":"1885","city_name":"Dharmadam","state_id":"19"},
    {"city_id":"1886","city_name":"Edappal","state_id":"19"},
    {"city_id":"1887","city_name":"Edathala","state_id":"19"},
    {"city_id":"1888","city_name":"Elayavur","state_id":"19"},
    {"city_id":"1889","city_name":"Elur","state_id":"19"},
    {"city_id":"1890","city_name":"Eranholi","state_id":"19"},
    {"city_id":"1891","city_name":"Erattupetta","state_id":"19"},
    {"city_id":"1892","city_name":"Ernakulam","state_id":"19"},
    {"city_id":"1893","city_name":"Eruvatti","state_id":"19"},
    {"city_id":"1894","city_name":"Ettumanoor","state_id":"19"},
    {"city_id":"1895","city_name":"Feroke","state_id":"19"},
    {"city_id":"1896","city_name":"Guruvayur","state_id":"19"},
    {"city_id":"1897","city_name":"Haripad","state_id":"19"},
    {"city_id":"1898","city_name":"Hosabettu","state_id":"19"},
    {"city_id":"1899","city_name":"Idukki","state_id":"19"},
    {"city_id":"1900","city_name":"Iringaprom","state_id":"19"},
    {"city_id":"1901","city_name":"Irinjalakuda","state_id":"19"},
    {"city_id":"1902","city_name":"Iriveri","state_id":"19"},
    {"city_id":"1903","city_name":"Kadachira","state_id":"19"},
    {"city_id":"1904","city_name":"Kadalundi","state_id":"19"},
    {"city_id":"1905","city_name":"Kadamakkudy","state_id":"19"},
    {"city_id":"1906","city_name":"Kadirur","state_id":"19"},
    {"city_id":"1907","city_name":"Kadungallur","state_id":"19"},
    {"city_id":"1908","city_name":"Kakkodi","state_id":"19"},
    {"city_id":"1909","city_name":"Kalady","state_id":"19"},
    {"city_id":"1910","city_name":"Kalamassery","state_id":"19"},
    {"city_id":"1911","city_name":"Kalliasseri","state_id":"19"},
    {"city_id":"1912","city_name":"Kalpetta","state_id":"19"},
    {"city_id":"1913","city_name":"Kanhangad","state_id":"19"},
    {"city_id":"1914","city_name":"Kanhirode","state_id":"19"},
    {"city_id":"1915","city_name":"Kanjikkuzhi","state_id":"19"},
    {"city_id":"1916","city_name":"Kanjikode","state_id":"19"},
    {"city_id":"1917","city_name":"Kanjirappalli","state_id":"19"},
    {"city_id":"1918","city_name":"Kannadiparamba","state_id":"19"},
    {"city_id":"1919","city_name":"Kannangad","state_id":"19"},
    {"city_id":"1920","city_name":"Kannapuram","state_id":"19"},
    {"city_id":"1921","city_name":"Kannur","state_id":"19"},
    {"city_id":"1922","city_name":"Kannur Cantonment","state_id":"19"},
    {"city_id":"1923","city_name":"Karunagappally","state_id":"19"},
    {"city_id":"1924","city_name":"Karuvamyhuruthy","state_id":"19"},
    {"city_id":"1925","city_name":"Kasaragod","state_id":"19"},
    {"city_id":"1926","city_name":"Kasargod","state_id":"19"},
    {"city_id":"1927","city_name":"Kattappana","state_id":"19"},
    {"city_id":"1928","city_name":"Kayamkulam","state_id":"19"},
    {"city_id":"1929","city_name":"Kedamangalam","state_id":"19"},
    {"city_id":"1930","city_name":"Kochi","state_id":"19"},
    {"city_id":"1931","city_name":"Kodamthuruthu","state_id":"19"},
    {"city_id":"1932","city_name":"Kodungallur","state_id":"19"},
    {"city_id":"1933","city_name":"Koduvally","state_id":"19"},
    {"city_id":"1934","city_name":"Koduvayur","state_id":"19"},
    {"city_id":"1935","city_name":"Kokkothamangalam","state_id":"19"},
    {"city_id":"1936","city_name":"Kolazhy","state_id":"19"},
    {"city_id":"1937","city_name":"Kollam","state_id":"19"},
    {"city_id":"1938","city_name":"Komalapuram","state_id":"19"},
    {"city_id":"1939","city_name":"Koothattukulam","state_id":"19"},
    {"city_id":"1940","city_name":"Koratty","state_id":"19"},
    {"city_id":"1941","city_name":"Kothamangalam","state_id":"19"},
    {"city_id":"1942","city_name":"Kottarakkara","state_id":"19"},
    {"city_id":"1943","city_name":"Kottayam","state_id":"19"},
    {"city_id":"1944","city_name":"Kottayam Malabar","state_id":"19"},
    {"city_id":"1945","city_name":"Kottuvally","state_id":"19"},
    {"city_id":"1946","city_name":"Koyilandi","state_id":"19"},
    {"city_id":"1947","city_name":"Kozhikode","state_id":"19"},
    {"city_id":"1948","city_name":"Kudappanakunnu","state_id":"19"},
    {"city_id":"1949","city_name":"Kudlu","state_id":"19"},
    {"city_id":"1950","city_name":"Kumarakom","state_id":"19"},
    {"city_id":"1951","city_name":"Kumily","state_id":"19"},
    {"city_id":"1952","city_name":"Kunnamangalam","state_id":"19"},
    {"city_id":"1953","city_name":"Kunnamkulam","state_id":"19"},
    {"city_id":"1954","city_name":"Kurikkad","state_id":"19"},
    {"city_id":"1955","city_name":"Kurkkanchery","state_id":"19"},
    {"city_id":"1956","city_name":"Kuthuparamba","state_id":"19"},
    {"city_id":"1957","city_name":"Kuttakulam","state_id":"19"},
    {"city_id":"1958","city_name":"Kuttikkattur","state_id":"19"},
    {"city_id":"1959","city_name":"Kuttur","state_id":"19"},
    {"city_id":"1960","city_name":"Malappuram","state_id":"19"},
    {"city_id":"1961","city_name":"Mallappally","state_id":"19"},
    {"city_id":"1962","city_name":"Manjeri","state_id":"19"},
    {"city_id":"1963","city_name":"Manjeshwar","state_id":"19"},
    {"city_id":"1964","city_name":"Mannancherry","state_id":"19"},
    {"city_id":"1965","city_name":"Mannar","state_id":"19"},
    {"city_id":"1966","city_name":"Mannarakkat","state_id":"19"},
    {"city_id":"1967","city_name":"Maradu","state_id":"19"},
    {"city_id":"1968","city_name":"Marathakkara","state_id":"19"},
    {"city_id":"1969","city_name":"Marutharod","state_id":"19"},
    {"city_id":"1970","city_name":"Mattannur","state_id":"19"},
    {"city_id":"1971","city_name":"Mavelikara","state_id":"19"},
    {"city_id":"1972","city_name":"Mavilayi","state_id":"19"},
    {"city_id":"1973","city_name":"Mavur","state_id":"19"},
    {"city_id":"1974","city_name":"Methala","state_id":"19"},
    {"city_id":"1975","city_name":"Muhamma","state_id":"19"},
    {"city_id":"1976","city_name":"Mulavukad","state_id":"19"},
    {"city_id":"1977","city_name":"Mundakayam","state_id":"19"},
    {"city_id":"1978","city_name":"Munderi","state_id":"19"},
    {"city_id":"1979","city_name":"Munnar","state_id":"19"},
    {"city_id":"1980","city_name":"Muthakunnam","state_id":"19"},
    {"city_id":"1981","city_name":"Muvattupuzha","state_id":"19"},
    {"city_id":"1982","city_name":"Muzhappilangad","state_id":"19"},
    {"city_id":"1983","city_name":"Nadapuram","state_id":"19"},
    {"city_id":"1984","city_name":"Nadathara","state_id":"19"},
    {"city_id":"1985","city_name":"Narath","state_id":"19"},
    {"city_id":"1986","city_name":"Nattakam","state_id":"19"},
    {"city_id":"1987","city_name":"Nedumangad","state_id":"19"},
    {"city_id":"1988","city_name":"Nenmenikkara","state_id":"19"},
    {"city_id":"1989","city_name":"New Mahe","state_id":"19"},
    {"city_id":"1990","city_name":"Neyyattinkara","state_id":"19"},
    {"city_id":"1991","city_name":"Nileshwar","state_id":"19"},
    {"city_id":"1992","city_name":"Olavanna","state_id":"19"},
    {"city_id":"1993","city_name":"Ottapalam","state_id":"19"},
    {"city_id":"1994","city_name":"Ottappalam","state_id":"19"},
    {"city_id":"1995","city_name":"Paduvilayi","state_id":"19"},
    {"city_id":"1996","city_name":"Palai","state_id":"19"},
    {"city_id":"1997","city_name":"Palakkad","state_id":"19"},
    {"city_id":"1998","city_name":"Palayad","state_id":"19"},
    {"city_id":"1999","city_name":"Palissery","state_id":"19"},
    {"city_id":"2000","city_name":"Pallikkunnu","state_id":"19"},
    {"city_id":"2001","city_name":"Paluvai","state_id":"19"},
    {"city_id":"2002","city_name":"Panniyannur","state_id":"19"},
    {"city_id":"2003","city_name":"Pantalam","state_id":"19"},
    {"city_id":"2004","city_name":"Panthiramkavu","state_id":"19"},
    {"city_id":"2005","city_name":"Panur","state_id":"19"},
    {"city_id":"2006","city_name":"Pappinisseri","state_id":"19"},
    {"city_id":"2007","city_name":"Parassala","state_id":"19"},
    {"city_id":"2008","city_name":"Paravur","state_id":"19"},
    {"city_id":"2009","city_name":"Pathanamthitta","state_id":"19"},
    {"city_id":"2010","city_name":"Pathanapuram","state_id":"19"},
    {"city_id":"2011","city_name":"Pathiriyad","state_id":"19"},
    {"city_id":"2012","city_name":"Pattambi","state_id":"19"},
    {"city_id":"2013","city_name":"Pattiom","state_id":"19"},
    {"city_id":"2014","city_name":"Pavaratty","state_id":"19"},
    {"city_id":"2015","city_name":"Payyannur","state_id":"19"},
    {"city_id":"2016","city_name":"Peermade","state_id":"19"},
    {"city_id":"2017","city_name":"Perakam","state_id":"19"},
    {"city_id":"2018","city_name":"Peralasseri","state_id":"19"},
    {"city_id":"2019","city_name":"Peringathur","state_id":"19"},
    {"city_id":"2020","city_name":"Perinthalmanna","state_id":"19"},
    {"city_id":"2021","city_name":"Perole","state_id":"19"},
    {"city_id":"2022","city_name":"Perumanna","state_id":"19"},
    {"city_id":"2023","city_name":"Perumbaikadu","state_id":"19"},
    {"city_id":"2024","city_name":"Perumbavoor","state_id":"19"},
    {"city_id":"2025","city_name":"Pinarayi","state_id":"19"},
    {"city_id":"2026","city_name":"Piravam","state_id":"19"},
    {"city_id":"2027","city_name":"Ponnani","state_id":"19"},
    {"city_id":"2028","city_name":"Pottore","state_id":"19"},
    {"city_id":"2029","city_name":"Pudukad","state_id":"19"},
    {"city_id":"2030","city_name":"Punalur","state_id":"19"},
    {"city_id":"2031","city_name":"Puranattukara","state_id":"19"},
    {"city_id":"2032","city_name":"Puthunagaram","state_id":"19"},
    {"city_id":"2033","city_name":"Puthuppariyaram","state_id":"19"},
    {"city_id":"2034","city_name":"Puzhathi","state_id":"19"},
    {"city_id":"2035","city_name":"Ramanattukara","state_id":"19"},
    {"city_id":"2036","city_name":"Shoranur","state_id":"19"},
    {"city_id":"2037","city_name":"Sultans Battery","state_id":"19"},
    {"city_id":"2038","city_name":"Sulthan Bathery","state_id":"19"},
    {"city_id":"2039","city_name":"Talipparamba","state_id":"19"},
    {"city_id":"2040","city_name":"Thaikkad","state_id":"19"},
    {"city_id":"2041","city_name":"Thalassery","state_id":"19"},
    {"city_id":"2042","city_name":"Thannirmukkam","state_id":"19"},
    {"city_id":"2043","city_name":"Theyyalingal","state_id":"19"},
    {"city_id":"2044","city_name":"Thiruvalla","state_id":"19"},
    {"city_id":"2045","city_name":"Thiruvananthapuram","state_id":"19"},
    {"city_id":"2046","city_name":"Thiruvankulam","state_id":"19"},
    {"city_id":"2047","city_name":"Thodupuzha","state_id":"19"},
    {"city_id":"2048","city_name":"Thottada","state_id":"19"},
    {"city_id":"2049","city_name":"Thrippunithura","state_id":"19"},
    {"city_id":"2050","city_name":"Thrissur","state_id":"19"},
    {"city_id":"2051","city_name":"Tirur","state_id":"19"},
    {"city_id":"2052","city_name":"Udma","state_id":"19"},
    {"city_id":"2053","city_name":"Vadakara","state_id":"19"},
    {"city_id":"2054","city_name":"Vaikam","state_id":"19"},
    {"city_id":"2055","city_name":"Valapattam","state_id":"19"},
    {"city_id":"2056","city_name":"Vallachira","state_id":"19"},
    {"city_id":"2057","city_name":"Varam","state_id":"19"},
    {"city_id":"2058","city_name":"Varappuzha","state_id":"19"},
    {"city_id":"2059","city_name":"Varkala","state_id":"19"},
    {"city_id":"2060","city_name":"Vayalar","state_id":"19"},
    {"city_id":"2061","city_name":"Vazhakkala","state_id":"19"},
    {"city_id":"2062","city_name":"Venmanad","state_id":"19"},
    {"city_id":"2063","city_name":"Villiappally","state_id":"19"},
    {"city_id":"2064","city_name":"Wayanad","state_id":"19"},
    {"city_id":"2065","city_name":"Agethi","state_id":"20"},
    {"city_id":"2066","city_name":"Amini","state_id":"20"},
    {"city_id":"2067","city_name":"Androth Island","state_id":"20"},
    {"city_id":"2068","city_name":"Kavaratti","state_id":"20"},
    {"city_id":"2069","city_name":"Minicoy","state_id":"20"},
    {"city_id":"2070","city_name":"Agar","state_id":"21"},
    {"city_id":"2071","city_name":"Ajaigarh","state_id":"21"},
    {"city_id":"2072","city_name":"Akoda","state_id":"21"},
    {"city_id":"2073","city_name":"Akodia","state_id":"21"},
    {"city_id":"2074","city_name":"Alampur","state_id":"21"},
    {"city_id":"2075","city_name":"Alirajpur","state_id":"21"},
    {"city_id":"2076","city_name":"Alot","state_id":"21"},
    {"city_id":"2077","city_name":"Amanganj","state_id":"21"},
    {"city_id":"2078","city_name":"Amarkantak","state_id":"21"},
    {"city_id":"2079","city_name":"Amarpatan","state_id":"21"},
    {"city_id":"2080","city_name":"Amarwara","state_id":"21"},
    {"city_id":"2081","city_name":"Ambada","state_id":"21"},
    {"city_id":"2082","city_name":"Ambah","state_id":"21"},
    {"city_id":"2083","city_name":"Amla","state_id":"21"},
    {"city_id":"2084","city_name":"Amlai","state_id":"21"},
    {"city_id":"2085","city_name":"Anjad","state_id":"21"},
    {"city_id":"2086","city_name":"Antri","state_id":"21"},
    {"city_id":"2087","city_name":"Anuppur","state_id":"21"},
    {"city_id":"2088","city_name":"Aron","state_id":"21"},
    {"city_id":"2089","city_name":"Ashoknagar","state_id":"21"},
    {"city_id":"2090","city_name":"Ashta","state_id":"21"},
    {"city_id":"2091","city_name":"Babai","state_id":"21"},
    {"city_id":"2092","city_name":"Bada Malhera","state_id":"21"},
    {"city_id":"2093","city_name":"Badagaon","state_id":"21"},
    {"city_id":"2094","city_name":"Badagoan","state_id":"21"},
    {"city_id":"2095","city_name":"Badarwas","state_id":"21"},
    {"city_id":"2096","city_name":"Badawada","state_id":"21"},
    {"city_id":"2097","city_name":"Badi","state_id":"21"},
    {"city_id":"2098","city_name":"Badkuhi","state_id":"21"},
    {"city_id":"2099","city_name":"Badnagar","state_id":"21"},
    {"city_id":"2100","city_name":"Badnawar","state_id":"21"},
    {"city_id":"2101","city_name":"Badod","state_id":"21"},
    {"city_id":"2102","city_name":"Badoda","state_id":"21"},
    {"city_id":"2103","city_name":"Badra","state_id":"21"},
    {"city_id":"2104","city_name":"Bagh","state_id":"21"},
    {"city_id":"2105","city_name":"Bagli","state_id":"21"},
    {"city_id":"2106","city_name":"Baihar","state_id":"21"},
    {"city_id":"2107","city_name":"Baikunthpur","state_id":"21"},
    {"city_id":"2108","city_name":"Bakswaha","state_id":"21"},
    {"city_id":"2109","city_name":"Balaghat","state_id":"21"},
    {"city_id":"2110","city_name":"Baldeogarh","state_id":"21"},
    {"city_id":"2111","city_name":"Bamaniya","state_id":"21"},
    {"city_id":"2112","city_name":"Bamhani","state_id":"21"},
    {"city_id":"2113","city_name":"Bamor","state_id":"21"},
    {"city_id":"2114","city_name":"Bamora","state_id":"21"},
    {"city_id":"2115","city_name":"Banda","state_id":"21"},
    {"city_id":"2116","city_name":"Bangawan","state_id":"21"},
    {"city_id":"2117","city_name":"Bansatar Kheda","state_id":"21"},
    {"city_id":"2118","city_name":"Baraily","state_id":"21"},
    {"city_id":"2119","city_name":"Barela","state_id":"21"},
    {"city_id":"2120","city_name":"Barghat","state_id":"21"},
    {"city_id":"2121","city_name":"Bargi","state_id":"21"},
    {"city_id":"2122","city_name":"Barhi","state_id":"21"},
    {"city_id":"2123","city_name":"Barigarh","state_id":"21"},
    {"city_id":"2124","city_name":"Barwaha","state_id":"21"},
    {"city_id":"2125","city_name":"Barwani","state_id":"21"},
    {"city_id":"2126","city_name":"Basoda","state_id":"21"},
    {"city_id":"2127","city_name":"Begamganj","state_id":"21"},
    {"city_id":"2128","city_name":"Beohari","state_id":"21"},
    {"city_id":"2129","city_name":"Berasia","state_id":"21"},
    {"city_id":"2130","city_name":"Betma","state_id":"21"},
    {"city_id":"2131","city_name":"Betul","state_id":"21"},
    {"city_id":"2132","city_name":"Betul Bazar","state_id":"21"},
    {"city_id":"2133","city_name":"Bhainsdehi","state_id":"21"},
    {"city_id":"2134","city_name":"Bhamodi","state_id":"21"},
    {"city_id":"2135","city_name":"Bhander","state_id":"21"},
    {"city_id":"2136","city_name":"Bhanpura","state_id":"21"},
    {"city_id":"2137","city_name":"Bharveli","state_id":"21"},
    {"city_id":"2138","city_name":"Bhaurasa","state_id":"21"},
    {"city_id":"2139","city_name":"Bhavra","state_id":"21"},
    {"city_id":"2140","city_name":"Bhedaghat","state_id":"21"},
    {"city_id":"2141","city_name":"Bhikangaon","state_id":"21"},
    {"city_id":"2142","city_name":"Bhilakhedi","state_id":"21"},
    {"city_id":"2143","city_name":"Bhind","state_id":"21"},
    {"city_id":"2144","city_name":"Bhitarwar","state_id":"21"},
    {"city_id":"2145","city_name":"Bhopal","state_id":"21"},
    {"city_id":"2146","city_name":"Bhuibandh","state_id":"21"},
    {"city_id":"2147","city_name":"Biaora","state_id":"21"},
    {"city_id":"2148","city_name":"Bijawar","state_id":"21"},
    {"city_id":"2149","city_name":"Bijeypur","state_id":"21"},
    {"city_id":"2150","city_name":"Bijrauni","state_id":"21"},
    {"city_id":"2151","city_name":"Bijuri","state_id":"21"},
    {"city_id":"2152","city_name":"Bilaua","state_id":"21"},
    {"city_id":"2153","city_name":"Bilpura","state_id":"21"},
    {"city_id":"2154","city_name":"Bina Railway Colony","state_id":"21"},
    {"city_id":"2155","city_name":"Bina-Etawa","state_id":"21"},
    {"city_id":"2156","city_name":"Birsinghpur","state_id":"21"},
    {"city_id":"2157","city_name":"Boda","state_id":"21"},
    {"city_id":"2158","city_name":"Budhni","state_id":"21"},
    {"city_id":"2159","city_name":"Burhanpur","state_id":"21"},
    {"city_id":"2160","city_name":"Burhar","state_id":"21"},
    {"city_id":"2161","city_name":"Chachaura Binaganj","state_id":"21"},
    {"city_id":"2162","city_name":"Chakghat","state_id":"21"},
    {"city_id":"2163","city_name":"Chandameta Butar","state_id":"21"},
    {"city_id":"2164","city_name":"Chanderi","state_id":"21"},
    {"city_id":"2165","city_name":"Chandia","state_id":"21"},
    {"city_id":"2166","city_name":"Chandla","state_id":"21"},
    {"city_id":"2167","city_name":"Chaurai Khas","state_id":"21"},
    {"city_id":"2168","city_name":"Chhatarpur","state_id":"21"},
    {"city_id":"2169","city_name":"Chhindwara","state_id":"21"},
    {"city_id":"2170","city_name":"Chhota Chhindwara","state_id":"21"},
    {"city_id":"2171","city_name":"Chichli","state_id":"21"},
    {"city_id":"2172","city_name":"Chitrakut","state_id":"21"},
    {"city_id":"2173","city_name":"Churhat","state_id":"21"},
    {"city_id":"2174","city_name":"Daboh","state_id":"21"},
    {"city_id":"2175","city_name":"Dabra","state_id":"21"},
    {"city_id":"2176","city_name":"Damoh","state_id":"21"},
    {"city_id":"2177","city_name":"Damua","state_id":"21"},
    {"city_id":"2178","city_name":"Datia","state_id":"21"},
    {"city_id":"2179","city_name":"Deodara","state_id":"21"},
    {"city_id":"2180","city_name":"Deori","state_id":"21"},
    {"city_id":"2181","city_name":"Deori Khas","state_id":"21"},
    {"city_id":"2182","city_name":"Depalpur","state_id":"21"},
    {"city_id":"2183","city_name":"Devendranagar","state_id":"21"},
    {"city_id":"2184","city_name":"Devhara","state_id":"21"},
    {"city_id":"2185","city_name":"Dewas","state_id":"21"},
    {"city_id":"2186","city_name":"Dhamnod","state_id":"21"},
    {"city_id":"2187","city_name":"Dhana","state_id":"21"},
    {"city_id":"2188","city_name":"Dhanpuri","state_id":"21"},
    {"city_id":"2189","city_name":"Dhar","state_id":"21"},
    {"city_id":"2190","city_name":"Dharampuri","state_id":"21"},
    {"city_id":"2191","city_name":"Dighawani","state_id":"21"},
    {"city_id":"2192","city_name":"Diken","state_id":"21"},
    {"city_id":"2193","city_name":"Dindori","state_id":"21"},
    {"city_id":"2194","city_name":"Dola","state_id":"21"},
    {"city_id":"2195","city_name":"Dumar Kachhar","state_id":"21"},
    {"city_id":"2196","city_name":"Dungariya Chhapara","state_id":"21"},
    {"city_id":"2197","city_name":"Gadarwara","state_id":"21"},
    {"city_id":"2198","city_name":"Gairatganj","state_id":"21"},
    {"city_id":"2199","city_name":"Gandhi Sagar Hydel Colony","state_id":"21"},
    {"city_id":"2200","city_name":"Ganjbasoda","state_id":"21"},
    {"city_id":"2201","city_name":"Garhakota","state_id":"21"},
    {"city_id":"2202","city_name":"Garhi Malhara","state_id":"21"},
    {"city_id":"2203","city_name":"Garoth","state_id":"21"},
    {"city_id":"2204","city_name":"Gautapura","state_id":"21"},
    {"city_id":"2205","city_name":"Ghansor","state_id":"21"},
    {"city_id":"2206","city_name":"Ghuwara","state_id":"21"},
    {"city_id":"2207","city_name":"Gogaon","state_id":"21"},
    {"city_id":"2208","city_name":"Gogapur","state_id":"21"},
    {"city_id":"2209","city_name":"Gohad","state_id":"21"},
    {"city_id":"2210","city_name":"Gormi","state_id":"21"},
    {"city_id":"2211","city_name":"Govindgarh","state_id":"21"},
    {"city_id":"2212","city_name":"Guna","state_id":"21"},
    {"city_id":"2213","city_name":"Gurh","state_id":"21"},
    {"city_id":"2214","city_name":"Gwalior","state_id":"21"},
    {"city_id":"2215","city_name":"Hanumana","state_id":"21"},
    {"city_id":"2216","city_name":"Harda","state_id":"21"},
    {"city_id":"2217","city_name":"Harpalpur","state_id":"21"},
    {"city_id":"2218","city_name":"Harrai","state_id":"21"},
    {"city_id":"2219","city_name":"Harsud","state_id":"21"},
    {"city_id":"2220","city_name":"Hatod","state_id":"21"},
    {"city_id":"2221","city_name":"Hatpipalya","state_id":"21"},
    {"city_id":"2222","city_name":"Hatta","state_id":"21"},
    {"city_id":"2223","city_name":"Hindoria","state_id":"21"},
    {"city_id":"2224","city_name":"Hirapur","state_id":"21"},
    {"city_id":"2225","city_name":"Hoshangabad","state_id":"21"},
    {"city_id":"2226","city_name":"Ichhawar","state_id":"21"},
    {"city_id":"2227","city_name":"Iklehra","state_id":"21"},
    {"city_id":"2228","city_name":"Indergarh","state_id":"21"},
    {"city_id":"2229","city_name":"Indore","state_id":"21"},
    {"city_id":"2230","city_name":"Isagarh","state_id":"21"},
    {"city_id":"2231","city_name":"Itarsi","state_id":"21"},
    {"city_id":"2232","city_name":"Jabalpur","state_id":"21"},
    {"city_id":"2233","city_name":"Jabalpur Cantonment","state_id":"21"},
    {"city_id":"2234","city_name":"Jabalpur G.C.F","state_id":"21"},
    {"city_id":"2235","city_name":"Jaisinghnagar","state_id":"21"},
    {"city_id":"2236","city_name":"Jaithari","state_id":"21"},
    {"city_id":"2237","city_name":"Jaitwara","state_id":"21"},
    {"city_id":"2238","city_name":"Jamai","state_id":"21"},
    {"city_id":"2239","city_name":"Jaora","state_id":"21"},
    {"city_id":"2240","city_name":"Jatachhapar","state_id":"21"},
    {"city_id":"2241","city_name":"Jatara","state_id":"21"},
    {"city_id":"2242","city_name":"Jawad","state_id":"21"},
    {"city_id":"2243","city_name":"Jawar","state_id":"21"},
    {"city_id":"2244","city_name":"Jeronkhalsa","state_id":"21"},
    {"city_id":"2245","city_name":"Jhabua","state_id":"21"},
    {"city_id":"2246","city_name":"Jhundpura","state_id":"21"},
    {"city_id":"2247","city_name":"Jiran","state_id":"21"},
    {"city_id":"2248","city_name":"Jirapur","state_id":"21"},
    {"city_id":"2249","city_name":"Jobat","state_id":"21"},
    {"city_id":"2250","city_name":"Joura","state_id":"21"},
    {"city_id":"2251","city_name":"Kailaras","state_id":"21"},
    {"city_id":"2252","city_name":"Kaimur","state_id":"21"},
    {"city_id":"2253","city_name":"Kakarhati","state_id":"21"},
    {"city_id":"2254","city_name":"Kalichhapar","state_id":"21"},
    {"city_id":"2255","city_name":"Kanad","state_id":"21"},
    {"city_id":"2256","city_name":"Kannod","state_id":"21"},
    {"city_id":"2257","city_name":"Kantaphod","state_id":"21"},
    {"city_id":"2258","city_name":"Kareli","state_id":"21"},
    {"city_id":"2259","city_name":"Karera","state_id":"21"},
    {"city_id":"2260","city_name":"Kari","state_id":"21"},
    {"city_id":"2261","city_name":"Karnawad","state_id":"21"},
    {"city_id":"2262","city_name":"Karrapur","state_id":"21"},
    {"city_id":"2263","city_name":"Kasrawad","state_id":"21"},
    {"city_id":"2264","city_name":"Katangi","state_id":"21"},
    {"city_id":"2265","city_name":"Katni","state_id":"21"},
    {"city_id":"2266","city_name":"Kelhauri","state_id":"21"},
    {"city_id":"2267","city_name":"Khachrod","state_id":"21"},
    {"city_id":"2268","city_name":"Khajuraho","state_id":"21"},
    {"city_id":"2269","city_name":"Khamaria","state_id":"21"},
    {"city_id":"2270","city_name":"Khand","state_id":"21"},
    {"city_id":"2271","city_name":"Khandwa","state_id":"21"},
    {"city_id":"2272","city_name":"Khaniyadhana","state_id":"21"},
    {"city_id":"2273","city_name":"Khargapur","state_id":"21"},
    {"city_id":"2274","city_name":"Khargone","state_id":"21"},
    {"city_id":"2275","city_name":"Khategaon","state_id":"21"},
    {"city_id":"2276","city_name":"Khetia","state_id":"21"},
    {"city_id":"2277","city_name":"Khilchipur","state_id":"21"},
    {"city_id":"2278","city_name":"Khirkiya","state_id":"21"},
    {"city_id":"2279","city_name":"Khujner","state_id":"21"},
    {"city_id":"2280","city_name":"Khurai","state_id":"21"},
    {"city_id":"2281","city_name":"Kolaras","state_id":"21"},
    {"city_id":"2282","city_name":"Kotar","state_id":"21"},
    {"city_id":"2283","city_name":"Kothi","state_id":"21"},
    {"city_id":"2284","city_name":"Kotma","state_id":"21"},
    {"city_id":"2285","city_name":"Kukshi","state_id":"21"},
    {"city_id":"2286","city_name":"Kumbhraj","state_id":"21"},
    {"city_id":"2287","city_name":"Kurwai","state_id":"21"},
    {"city_id":"2288","city_name":"Lahar","state_id":"21"},
    {"city_id":"2289","city_name":"Lakhnadon","state_id":"21"},
    {"city_id":"2290","city_name":"Lateri","state_id":"21"},
    {"city_id":"2291","city_name":"Laundi","state_id":"21"},
    {"city_id":"2292","city_name":"Lidhora Khas","state_id":"21"},
    {"city_id":"2293","city_name":"Lodhikheda","state_id":"21"},
    {"city_id":"2294","city_name":"Loharda","state_id":"21"},
    {"city_id":"2295","city_name":"Machalpur","state_id":"21"},
    {"city_id":"2296","city_name":"Madhogarh","state_id":"21"},
    {"city_id":"2297","city_name":"Maharajpur","state_id":"21"},
    {"city_id":"2298","city_name":"Maheshwar","state_id":"21"},
    {"city_id":"2299","city_name":"Mahidpur","state_id":"21"},
    {"city_id":"2300","city_name":"Maihar","state_id":"21"},
    {"city_id":"2301","city_name":"Majholi","state_id":"21"},
    {"city_id":"2302","city_name":"Makronia","state_id":"21"},
    {"city_id":"2303","city_name":"Maksi","state_id":"21"},
    {"city_id":"2304","city_name":"Malaj Khand","state_id":"21"},
    {"city_id":"2305","city_name":"Malanpur","state_id":"21"},
    {"city_id":"2306","city_name":"Malhargarh","state_id":"21"},
    {"city_id":"2307","city_name":"Manasa","state_id":"21"},
    {"city_id":"2308","city_name":"Manawar","state_id":"21"},
    {"city_id":"2309","city_name":"Mandav","state_id":"21"},
    {"city_id":"2310","city_name":"Mandideep","state_id":"21"},
    {"city_id":"2311","city_name":"Mandla","state_id":"21"},
    {"city_id":"2312","city_name":"Mandleshwar","state_id":"21"},
    {"city_id":"2313","city_name":"Mandsaur","state_id":"21"},
    {"city_id":"2314","city_name":"Manegaon","state_id":"21"},
    {"city_id":"2315","city_name":"Mangawan","state_id":"21"},
    {"city_id":"2316","city_name":"Manglaya Sadak","state_id":"21"},
    {"city_id":"2317","city_name":"Manpur","state_id":"21"},
    {"city_id":"2318","city_name":"Mau","state_id":"21"},
    {"city_id":"2319","city_name":"Mauganj","state_id":"21"},
    {"city_id":"2320","city_name":"Meghnagar","state_id":"21"},
    {"city_id":"2321","city_name":"Mehara Gaon","state_id":"21"},
    {"city_id":"2322","city_name":"Mehgaon","state_id":"21"},
    {"city_id":"2323","city_name":"Mhaugaon","state_id":"21"},
    {"city_id":"2324","city_name":"Mhow","state_id":"21"},
    {"city_id":"2325","city_name":"Mihona","state_id":"21"},
    {"city_id":"2326","city_name":"Mohgaon","state_id":"21"},
    {"city_id":"2327","city_name":"Morar","state_id":"21"},
    {"city_id":"2328","city_name":"Morena","state_id":"21"},
    {"city_id":"2329","city_name":"Morwa","state_id":"21"},
    {"city_id":"2330","city_name":"Multai","state_id":"21"},
    {"city_id":"2331","city_name":"Mundi","state_id":"21"},
    {"city_id":"2332","city_name":"Mungaoli","state_id":"21"},
    {"city_id":"2333","city_name":"Murwara","state_id":"21"},
    {"city_id":"2334","city_name":"Nagda","state_id":"21"},
    {"city_id":"2335","city_name":"Nagod","state_id":"21"},
    {"city_id":"2336","city_name":"Nagri","state_id":"21"},
    {"city_id":"2337","city_name":"Naigarhi","state_id":"21"},
    {"city_id":"2338","city_name":"Nainpur","state_id":"21"},
    {"city_id":"2339","city_name":"Nalkheda","state_id":"21"},
    {"city_id":"2340","city_name":"Namli","state_id":"21"},
    {"city_id":"2341","city_name":"Narayangarh","state_id":"21"},
    {"city_id":"2342","city_name":"Narsimhapur","state_id":"21"},
    {"city_id":"2343","city_name":"Narsingarh","state_id":"21"},
    {"city_id":"2344","city_name":"Narsinghpur","state_id":"21"},
    {"city_id":"2345","city_name":"Narwar","state_id":"21"},
    {"city_id":"2346","city_name":"Nasrullaganj","state_id":"21"},
    {"city_id":"2347","city_name":"Naudhia","state_id":"21"},
    {"city_id":"2348","city_name":"Naugaon","state_id":"21"},
    {"city_id":"2349","city_name":"Naurozabad","state_id":"21"},
    {"city_id":"2350","city_name":"Neemuch","state_id":"21"},
    {"city_id":"2351","city_name":"Nepa Nagar","state_id":"21"},
    {"city_id":"2352","city_name":"Neuton Chikhli Kalan","state_id":"21"},
    {"city_id":"2353","city_name":"Nimach","state_id":"21"},
    {"city_id":"2354","city_name":"Niwari","state_id":"21"},
    {"city_id":"2355","city_name":"Obedullaganj","state_id":"21"},
    {"city_id":"2356","city_name":"Omkareshwar","state_id":"21"},
    {"city_id":"2357","city_name":"Orachha","state_id":"21"},
    {"city_id":"2358","city_name":"Ordinance Factory Itarsi","state_id":"21"},
    {"city_id":"2359","city_name":"Pachmarhi","state_id":"21"},
    {"city_id":"2360","city_name":"Pachmarhi Cantonment","state_id":"21"},
    {"city_id":"2361","city_name":"Pachore","state_id":"21"},
    {"city_id":"2362","city_name":"Palchorai","state_id":"21"},
    {"city_id":"2363","city_name":"Palda","state_id":"21"},
    {"city_id":"2364","city_name":"Palera","state_id":"21"},
    {"city_id":"2365","city_name":"Pali","state_id":"21"},
    {"city_id":"2366","city_name":"Panagar","state_id":"21"},
    {"city_id":"2367","city_name":"Panara","state_id":"21"},
    {"city_id":"2368","city_name":"Pandaria","state_id":"21"},
    {"city_id":"2369","city_name":"Pandhana","state_id":"21"},
    {"city_id":"2370","city_name":"Pandhurna","state_id":"21"},
    {"city_id":"2371","city_name":"Panna","state_id":"21"},
    {"city_id":"2372","city_name":"Pansemal","state_id":"21"},
    {"city_id":"2373","city_name":"Parasia","state_id":"21"},
    {"city_id":"2374","city_name":"Pasan","state_id":"21"},
    {"city_id":"2375","city_name":"Patan","state_id":"21"},
    {"city_id":"2376","city_name":"Patharia","state_id":"21"},
    {"city_id":"2377","city_name":"Pawai","state_id":"21"},
    {"city_id":"2378","city_name":"Petlawad","state_id":"21"},
    {"city_id":"2379","city_name":"Phuph Kalan","state_id":"21"},
    {"city_id":"2380","city_name":"Pichhore","state_id":"21"},
    {"city_id":"2381","city_name":"Pipariya","state_id":"21"},
    {"city_id":"2382","city_name":"Pipliya Mandi","state_id":"21"},
    {"city_id":"2383","city_name":"Piploda","state_id":"21"},
    {"city_id":"2384","city_name":"Pithampur","state_id":"21"},
    {"city_id":"2385","city_name":"Polay Kalan","state_id":"21"},
    {"city_id":"2386","city_name":"Porsa","state_id":"21"},
    {"city_id":"2387","city_name":"Prithvipur","state_id":"21"},
    {"city_id":"2388","city_name":"Raghogarh","state_id":"21"},
    {"city_id":"2389","city_name":"Rahatgarh","state_id":"21"},
    {"city_id":"2390","city_name":"Raisen","state_id":"21"},
    {"city_id":"2391","city_name":"Rajakhedi","state_id":"21"},
    {"city_id":"2392","city_name":"Rajgarh","state_id":"21"},
    {"city_id":"2393","city_name":"Rajnagar","state_id":"21"},
    {"city_id":"2394","city_name":"Rajpur","state_id":"21"},
    {"city_id":"2395","city_name":"Rampur Baghelan","state_id":"21"},
    {"city_id":"2396","city_name":"Rampur Naikin","state_id":"21"},
    {"city_id":"2397","city_name":"Rampura","state_id":"21"},
    {"city_id":"2398","city_name":"Ranapur","state_id":"21"},
    {"city_id":"2399","city_name":"Ranipura","state_id":"21"},
    {"city_id":"2400","city_name":"Ratangarh","state_id":"21"},
    {"city_id":"2401","city_name":"Ratlam","state_id":"21"},
    {"city_id":"2402","city_name":"Ratlam Kasba","state_id":"21"},
    {"city_id":"2403","city_name":"Rau","state_id":"21"},
    {"city_id":"2404","city_name":"Rehli","state_id":"21"},
    {"city_id":"2405","city_name":"Rehti","state_id":"21"},
    {"city_id":"2406","city_name":"Rewa","state_id":"21"},
    {"city_id":"2407","city_name":"Sabalgarh","state_id":"21"},
    {"city_id":"2408","city_name":"Sagar","state_id":"21"},
    {"city_id":"2409","city_name":"Sagar Cantonment","state_id":"21"},
    {"city_id":"2410","city_name":"Sailana","state_id":"21"},
    {"city_id":"2411","city_name":"Sanawad","state_id":"21"},
    {"city_id":"2412","city_name":"Sanchi","state_id":"21"},
    {"city_id":"2413","city_name":"Sanwer","state_id":"21"},
    {"city_id":"2414","city_name":"Sarangpur","state_id":"21"},
    {"city_id":"2415","city_name":"Sardarpur","state_id":"21"},
    {"city_id":"2416","city_name":"Sarni","state_id":"21"},
    {"city_id":"2417","city_name":"Satai","state_id":"21"},
    {"city_id":"2418","city_name":"Satna","state_id":"21"},
    {"city_id":"2419","city_name":"Satwas","state_id":"21"},
    {"city_id":"2420","city_name":"Sausar","state_id":"21"},
    {"city_id":"2421","city_name":"Sehore","state_id":"21"},
    {"city_id":"2422","city_name":"Semaria","state_id":"21"},
    {"city_id":"2423","city_name":"Sendhwa","state_id":"21"},
    {"city_id":"2424","city_name":"Seondha","state_id":"21"},
    {"city_id":"2425","city_name":"Seoni","state_id":"21"},
    {"city_id":"2426","city_name":"Seoni Malwa","state_id":"21"},
    {"city_id":"2427","city_name":"Sethia","state_id":"21"},
    {"city_id":"2428","city_name":"Shahdol","state_id":"21"},
    {"city_id":"2429","city_name":"Shahgarh","state_id":"21"},
    {"city_id":"2430","city_name":"Shahpur","state_id":"21"},
    {"city_id":"2431","city_name":"Shahpura","state_id":"21"},
    {"city_id":"2432","city_name":"Shajapur","state_id":"21"},
    {"city_id":"2433","city_name":"Shamgarh","state_id":"21"},
    {"city_id":"2434","city_name":"Sheopur","state_id":"21"},
    {"city_id":"2435","city_name":"Shivpuri","state_id":"21"},
    {"city_id":"2436","city_name":"Shujalpur","state_id":"21"},
    {"city_id":"2437","city_name":"Sidhi","state_id":"21"},
    {"city_id":"2438","city_name":"Sihora","state_id":"21"},
    {"city_id":"2439","city_name":"Singolo","state_id":"21"},
    {"city_id":"2440","city_name":"Singrauli","state_id":"21"},
    {"city_id":"2441","city_name":"Sinhasa","state_id":"21"},
    {"city_id":"2442","city_name":"Sirgora","state_id":"21"},
    {"city_id":"2443","city_name":"Sirmaur","state_id":"21"},
    {"city_id":"2444","city_name":"Sironj","state_id":"21"},
    {"city_id":"2445","city_name":"Sitamau","state_id":"21"},
    {"city_id":"2446","city_name":"Sohagpur","state_id":"21"},
    {"city_id":"2447","city_name":"Sonkatch","state_id":"21"},
    {"city_id":"2448","city_name":"Soyatkalan","state_id":"21"},
    {"city_id":"2449","city_name":"Suhagi","state_id":"21"},
    {"city_id":"2450","city_name":"Sultanpur","state_id":"21"},
    {"city_id":"2451","city_name":"Susner","state_id":"21"},
    {"city_id":"2452","city_name":"Suthaliya","state_id":"21"},
    {"city_id":"2453","city_name":"Tal","state_id":"21"},
    {"city_id":"2454","city_name":"Talen","state_id":"21"},
    {"city_id":"2455","city_name":"Tarana","state_id":"21"},
    {"city_id":"2456","city_name":"Taricharkalan","state_id":"21"},
    {"city_id":"2457","city_name":"Tekanpur","state_id":"21"},
    {"city_id":"2458","city_name":"Tendukheda","state_id":"21"},
    {"city_id":"2459","city_name":"Teonthar","state_id":"21"},
    {"city_id":"2460","city_name":"Thandia","state_id":"21"},
    {"city_id":"2461","city_name":"Tikamgarh","state_id":"21"},
    {"city_id":"2462","city_name":"Timarni","state_id":"21"},
    {"city_id":"2463","city_name":"Tirodi","state_id":"21"},
    {"city_id":"2464","city_name":"Udaipura","state_id":"21"},
    {"city_id":"2465","city_name":"Ujjain","state_id":"21"},
    {"city_id":"2466","city_name":"Ukwa","state_id":"21"},
    {"city_id":"2467","city_name":"Umaria","state_id":"21"},
    {"city_id":"2468","city_name":"Unchahara","state_id":"21"},
    {"city_id":"2469","city_name":"Unhel","state_id":"21"},
    {"city_id":"2470","city_name":"Vehicle Factory Jabalpur","state_id":"21"},
    {"city_id":"2471","city_name":"Vidisha","state_id":"21"},
    {"city_id":"2472","city_name":"Vijayraghavgarh","state_id":"21"},
    {"city_id":"2473","city_name":"Waraseoni","state_id":"21"},
    {"city_id":"2474","city_name":"Achalpur","state_id":"22"},
    {"city_id":"2475","city_name":"Aheri","state_id":"22"},
    {"city_id":"2476","city_name":"Ahmadnagar Cantonment","state_id":"22"},
    {"city_id":"2477","city_name":"Ahmadpur","state_id":"22"},
    {"city_id":"2478","city_name":"Ahmednagar","state_id":"22"},
    {"city_id":"2479","city_name":"Ajra","state_id":"22"},
    {"city_id":"2480","city_name":"Akalkot","state_id":"22"},
    {"city_id":"2481","city_name":"Akkalkuwa","state_id":"22"},
    {"city_id":"2482","city_name":"Akola","state_id":"22"},
    {"city_id":"2483","city_name":"Akot","state_id":"22"},
    {"city_id":"2484","city_name":"Alandi","state_id":"22"},
    {"city_id":"2485","city_name":"Alibag","state_id":"22"},
    {"city_id":"2486","city_name":"Allapalli","state_id":"22"},
    {"city_id":"2487","city_name":"Alore","state_id":"22"},
    {"city_id":"2488","city_name":"Amalner","state_id":"22"},
    {"city_id":"2489","city_name":"Ambad","state_id":"22"},
    {"city_id":"2490","city_name":"Ambajogai","state_id":"22"},
    {"city_id":"2491","city_name":"Ambernath","state_id":"22"},
    {"city_id":"2492","city_name":"Ambivali Tarf Wankhal","state_id":"22"},
    {"city_id":"2493","city_name":"Amgaon","state_id":"22"},
    {"city_id":"2494","city_name":"Amravati","state_id":"22"},
    {"city_id":"2495","city_name":"Anjangaon","state_id":"22"},
    {"city_id":"2496","city_name":"Arvi","state_id":"22"},
    {"city_id":"2497","city_name":"Ashta","state_id":"22"},
    {"city_id":"2498","city_name":"Ashti","state_id":"22"},
    {"city_id":"2499","city_name":"Aurangabad","state_id":"22"},
    {"city_id":"2500","city_name":"Aurangabad Cantonment","state_id":"22"},
    {"city_id":"2501","city_name":"Ausa","state_id":"22"},
    {"city_id":"2502","city_name":"Babhulgaon","state_id":"22"},
    {"city_id":"2503","city_name":"Badlapur","state_id":"22"},
    {"city_id":"2504","city_name":"Balapur","state_id":"22"},
    {"city_id":"2505","city_name":"Ballarpur","state_id":"22"},
    {"city_id":"2506","city_name":"Baramati","state_id":"22"},
    {"city_id":"2507","city_name":"Barshi","state_id":"22"},
    {"city_id":"2508","city_name":"Basmat","state_id":"22"},
    {"city_id":"2509","city_name":"Beed","state_id":"22"},
    {"city_id":"2510","city_name":"Bhadravati","state_id":"22"},
    {"city_id":"2511","city_name":"Bhagur","state_id":"22"},
    {"city_id":"2512","city_name":"Bhandara","state_id":"22"},
    {"city_id":"2513","city_name":"Bhigvan","state_id":"22"},
    {"city_id":"2514","city_name":"Bhingar","state_id":"22"},
    {"city_id":"2515","city_name":"Bhiwandi","state_id":"22"},
    {"city_id":"2516","city_name":"Bhokhardan","state_id":"22"},
    {"city_id":"2517","city_name":"Bhor","state_id":"22"},
    {"city_id":"2518","city_name":"Bhosari","state_id":"22"},
    {"city_id":"2519","city_name":"Bhum","state_id":"22"},
    {"city_id":"2520","city_name":"Bhusawal","state_id":"22"},
    {"city_id":"2521","city_name":"Bid","state_id":"22"},
    {"city_id":"2522","city_name":"Biloli","state_id":"22"},
    {"city_id":"2523","city_name":"Birwadi","state_id":"22"},
    {"city_id":"2524","city_name":"Boisar","state_id":"22"},
    {"city_id":"2525","city_name":"Bop Khel","state_id":"22"},
    {"city_id":"2526","city_name":"Brahmapuri","state_id":"22"},
    {"city_id":"2527","city_name":"Budhgaon","state_id":"22"},
    {"city_id":"2528","city_name":"Buldana","state_id":"22"},
    {"city_id":"2529","city_name":"Buldhana","state_id":"22"},
    {"city_id":"2530","city_name":"Butibori","state_id":"22"},
    {"city_id":"2531","city_name":"Chakan","state_id":"22"},
    {"city_id":"2532","city_name":"Chalisgaon","state_id":"22"},
    {"city_id":"2533","city_name":"Chandrapur","state_id":"22"},
    {"city_id":"2534","city_name":"Chandur","state_id":"22"},
    {"city_id":"2535","city_name":"Chandur Bazar","state_id":"22"},
    {"city_id":"2536","city_name":"Chandvad","state_id":"22"},
    {"city_id":"2537","city_name":"Chicholi","state_id":"22"},
    {"city_id":"2538","city_name":"Chikhala","state_id":"22"},
    {"city_id":"2539","city_name":"Chikhaldara","state_id":"22"},
    {"city_id":"2540","city_name":"Chikhli","state_id":"22"},
    {"city_id":"2541","city_name":"Chinchani","state_id":"22"},
    {"city_id":"2542","city_name":"Chinchwad","state_id":"22"},
    {"city_id":"2543","city_name":"Chiplun","state_id":"22"},
    {"city_id":"2544","city_name":"Chopda","state_id":"22"},
    {"city_id":"2545","city_name":"Dabhol","state_id":"22"},
    {"city_id":"2546","city_name":"Dahance","state_id":"22"},
    {"city_id":"2547","city_name":"Dahanu","state_id":"22"},
    {"city_id":"2548","city_name":"Daharu","state_id":"22"},
    {"city_id":"2549","city_name":"Dapoli Camp","state_id":"22"},
    {"city_id":"2550","city_name":"Darwa","state_id":"22"},
    {"city_id":"2551","city_name":"Daryapur","state_id":"22"},
    {"city_id":"2552","city_name":"Dattapur","state_id":"22"},
    {"city_id":"2553","city_name":"Daund","state_id":"22"},
    {"city_id":"2554","city_name":"Davlameti","state_id":"22"},
    {"city_id":"2555","city_name":"Deglur","state_id":"22"},
    {"city_id":"2556","city_name":"Dehu Road","state_id":"22"},
    {"city_id":"2557","city_name":"Deolali","state_id":"22"},
    {"city_id":"2558","city_name":"Deolali Pravara","state_id":"22"},
    {"city_id":"2559","city_name":"Deoli","state_id":"22"},
    {"city_id":"2560","city_name":"Desaiganj","state_id":"22"},
    {"city_id":"2561","city_name":"Deulgaon Raja","state_id":"22"},
    {"city_id":"2562","city_name":"Dewhadi","state_id":"22"},
    {"city_id":"2563","city_name":"Dharangaon","state_id":"22"},
    {"city_id":"2564","city_name":"Dharmabad","state_id":"22"},
    {"city_id":"2565","city_name":"Dharur","state_id":"22"},
    {"city_id":"2566","city_name":"Dhatau","state_id":"22"},
    {"city_id":"2567","city_name":"Dhule","state_id":"22"},
    {"city_id":"2568","city_name":"Digdoh","state_id":"22"},
    {"city_id":"2569","city_name":"Diglur","state_id":"22"},
    {"city_id":"2570","city_name":"Digras","state_id":"22"},
    {"city_id":"2571","city_name":"Dombivli","state_id":"22"},
    {"city_id":"2572","city_name":"Dondaicha","state_id":"22"},
    {"city_id":"2573","city_name":"Dudhani","state_id":"22"},
    {"city_id":"2574","city_name":"Durgapur","state_id":"22"},
    {"city_id":"2575","city_name":"Dyane","state_id":"22"},
    {"city_id":"2576","city_name":"Edandol","state_id":"22"},
    {"city_id":"2577","city_name":"Eklahare","state_id":"22"},
    {"city_id":"2578","city_name":"Faizpur","state_id":"22"},
    {"city_id":"2579","city_name":"Fekari","state_id":"22"},
    {"city_id":"2580","city_name":"Gadchiroli","state_id":"22"},
    {"city_id":"2581","city_name":"Gadhinghaj","state_id":"22"},
    {"city_id":"2582","city_name":"Gandhi Nagar","state_id":"22"},
    {"city_id":"2583","city_name":"Ganeshpur","state_id":"22"},
    {"city_id":"2584","city_name":"Gangakher","state_id":"22"},
    {"city_id":"2585","city_name":"Gangapur","state_id":"22"},
    {"city_id":"2586","city_name":"Gevrai","state_id":"22"},
    {"city_id":"2587","city_name":"Ghatanji","state_id":"22"},
    {"city_id":"2588","city_name":"Ghoti","state_id":"22"},
    {"city_id":"2589","city_name":"Ghugus","state_id":"22"},
    {"city_id":"2590","city_name":"Ghulewadi","state_id":"22"},
    {"city_id":"2591","city_name":"Godoli","state_id":"22"},
    {"city_id":"2592","city_name":"Gondia","state_id":"22"},
    {"city_id":"2593","city_name":"Guhagar","state_id":"22"},
    {"city_id":"2594","city_name":"Hadgaon","state_id":"22"},
    {"city_id":"2595","city_name":"Harnai Beach","state_id":"22"},
    {"city_id":"2596","city_name":"Hinganghat","state_id":"22"},
    {"city_id":"2597","city_name":"Hingoli","state_id":"22"},
    {"city_id":"2598","city_name":"Hupari","state_id":"22"},
    {"city_id":"2599","city_name":"Ichalkaranji","state_id":"22"},
    {"city_id":"2600","city_name":"Igatpuri","state_id":"22"},
    {"city_id":"2601","city_name":"Indapur","state_id":"22"},
    {"city_id":"2602","city_name":"Jaisinghpur","state_id":"22"},
    {"city_id":"2603","city_name":"Jalgaon","state_id":"22"},
    {"city_id":"2604","city_name":"Jalna","state_id":"22"},
    {"city_id":"2605","city_name":"Jamkhed","state_id":"22"},
    {"city_id":"2606","city_name":"Jawhar","state_id":"22"},
    {"city_id":"2607","city_name":"Jaysingpur","state_id":"22"},
    {"city_id":"2608","city_name":"Jejuri","state_id":"22"},
    {"city_id":"2609","city_name":"Jintur","state_id":"22"},
    {"city_id":"2610","city_name":"Junnar","state_id":"22"},
    {"city_id":"2611","city_name":"Kabnur","state_id":"22"},
    {"city_id":"2612","city_name":"Kagal","state_id":"22"},
    {"city_id":"2613","city_name":"Kalamb","state_id":"22"},
    {"city_id":"2614","city_name":"Kalamnuri","state_id":"22"},
    {"city_id":"2615","city_name":"Kalas","state_id":"22"},
    {"city_id":"2616","city_name":"Kalmeshwar","state_id":"22"},
    {"city_id":"2617","city_name":"Kalundre","state_id":"22"},
    {"city_id":"2618","city_name":"Kalyan","state_id":"22"},
    {"city_id":"2619","city_name":"Kamthi","state_id":"22"},
    {"city_id":"2620","city_name":"Kamthi Cantonment","state_id":"22"},
    {"city_id":"2621","city_name":"Kandari","state_id":"22"},
    {"city_id":"2622","city_name":"Kandhar","state_id":"22"},
    {"city_id":"2623","city_name":"Kandri","state_id":"22"},
    {"city_id":"2624","city_name":"Kandri II","state_id":"22"},
    {"city_id":"2625","city_name":"Kanhan","state_id":"22"},
    {"city_id":"2626","city_name":"Kankavli","state_id":"22"},
    {"city_id":"2627","city_name":"Kannad","state_id":"22"},
    {"city_id":"2628","city_name":"Karad","state_id":"22"},
    {"city_id":"2629","city_name":"Karanja","state_id":"22"},
    {"city_id":"2630","city_name":"Karanje Tarf","state_id":"22"},
    {"city_id":"2631","city_name":"Karivali","state_id":"22"},
    {"city_id":"2632","city_name":"Karjat","state_id":"22"},
    {"city_id":"2633","city_name":"Karmala","state_id":"22"},
    {"city_id":"2634","city_name":"Kasara Budruk","state_id":"22"},
    {"city_id":"2635","city_name":"Katai","state_id":"22"},
    {"city_id":"2636","city_name":"Katkar","state_id":"22"},
    {"city_id":"2637","city_name":"Katol","state_id":"22"},
    {"city_id":"2638","city_name":"Kegaon","state_id":"22"},
    {"city_id":"2639","city_name":"Khadkale","state_id":"22"},
    {"city_id":"2640","city_name":"Khadki","state_id":"22"},
    {"city_id":"2641","city_name":"Khamgaon","state_id":"22"},
    {"city_id":"2642","city_name":"Khapa","state_id":"22"},
    {"city_id":"2643","city_name":"Kharadi","state_id":"22"},
    {"city_id":"2644","city_name":"Kharakvasla","state_id":"22"},
    {"city_id":"2645","city_name":"Khed","state_id":"22"},
    {"city_id":"2646","city_name":"Kherdi","state_id":"22"},
    {"city_id":"2647","city_name":"Khoni","state_id":"22"},
    {"city_id":"2648","city_name":"Khopoli","state_id":"22"},
    {"city_id":"2649","city_name":"Khuldabad","state_id":"22"},
    {"city_id":"2650","city_name":"Kinwat","state_id":"22"},
    {"city_id":"2651","city_name":"Kodoli","state_id":"22"},
    {"city_id":"2652","city_name":"Kolhapur","state_id":"22"},
    {"city_id":"2653","city_name":"Kon","state_id":"22"},
    {"city_id":"2654","city_name":"Kondumal","state_id":"22"},
    {"city_id":"2655","city_name":"Kopargaon","state_id":"22"},
    {"city_id":"2656","city_name":"Kopharad","state_id":"22"},
    {"city_id":"2657","city_name":"Koradi","state_id":"22"},
    {"city_id":"2658","city_name":"Koregaon","state_id":"22"},
    {"city_id":"2659","city_name":"Korochi","state_id":"22"},
    {"city_id":"2660","city_name":"Kudal","state_id":"22"},
    {"city_id":"2661","city_name":"Kundaim","state_id":"22"},
    {"city_id":"2662","city_name":"Kundalwadi","state_id":"22"},
    {"city_id":"2663","city_name":"Kurandvad","state_id":"22"},
    {"city_id":"2664","city_name":"Kurduvadi","state_id":"22"},
    {"city_id":"2665","city_name":"Kusgaon Budruk","state_id":"22"},
    {"city_id":"2666","city_name":"Lanja","state_id":"22"},
    {"city_id":"2667","city_name":"Lasalgaon","state_id":"22"},
    {"city_id":"2668","city_name":"Latur","state_id":"22"},
    {"city_id":"2669","city_name":"Loha","state_id":"22"},
    {"city_id":"2670","city_name":"Lohegaon","state_id":"22"},
    {"city_id":"2671","city_name":"Lonar","state_id":"22"},
    {"city_id":"2672","city_name":"Lonavala","state_id":"22"},
    {"city_id":"2673","city_name":"Madhavnagar","state_id":"22"},
    {"city_id":"2674","city_name":"Mahabaleshwar","state_id":"22"},
    {"city_id":"2675","city_name":"Mahad","state_id":"22"},
    {"city_id":"2676","city_name":"Mahadula","state_id":"22"},
    {"city_id":"2677","city_name":"Maindargi","state_id":"22"},
    {"city_id":"2678","city_name":"Majalgaon","state_id":"22"},
    {"city_id":"2679","city_name":"Malegaon","state_id":"22"},
    {"city_id":"2680","city_name":"Malgaon","state_id":"22"},
    {"city_id":"2681","city_name":"Malkapur","state_id":"22"},
    {"city_id":"2682","city_name":"Malwan","state_id":"22"},
    {"city_id":"2683","city_name":"Manadur","state_id":"22"},
    {"city_id":"2684","city_name":"Manchar","state_id":"22"},
    {"city_id":"2685","city_name":"Mangalvedhe","state_id":"22"},
    {"city_id":"2686","city_name":"Mangrul Pir","state_id":"22"},
    {"city_id":"2687","city_name":"Manmad","state_id":"22"},
    {"city_id":"2688","city_name":"Manor","state_id":"22"},
    {"city_id":"2689","city_name":"Mansar","state_id":"22"},
    {"city_id":"2690","city_name":"Manwath","state_id":"22"},
    {"city_id":"2691","city_name":"Mapuca","state_id":"22"},
    {"city_id":"2692","city_name":"Matheran","state_id":"22"},
    {"city_id":"2693","city_name":"Mehkar","state_id":"22"},
    {"city_id":"2694","city_name":"Mhasla","state_id":"22"},
    {"city_id":"2695","city_name":"Mhaswad","state_id":"22"},
    {"city_id":"2696","city_name":"Mira Bhayandar","state_id":"22"},
    {"city_id":"2697","city_name":"Miraj","state_id":"22"},
    {"city_id":"2698","city_name":"Mohpa","state_id":"22"},
    {"city_id":"2699","city_name":"Mohpada","state_id":"22"},
    {"city_id":"2700","city_name":"Moram","state_id":"22"},
    {"city_id":"2701","city_name":"Morshi","state_id":"22"},
    {"city_id":"2702","city_name":"Mowad","state_id":"22"},
    {"city_id":"2703","city_name":"Mudkhed","state_id":"22"},
    {"city_id":"2704","city_name":"Mukhed","state_id":"22"},
    {"city_id":"2705","city_name":"Mul","state_id":"22"},
    {"city_id":"2706","city_name":"Mulshi","state_id":"22"},
    {"city_id":"2707","city_name":"Mumbai","state_id":"22"},
    {"city_id":"2708","city_name":"Murbad","state_id":"22"},
    {"city_id":"2709","city_name":"Murgud","state_id":"22"},
    {"city_id":"2710","city_name":"Murtijapur","state_id":"22"},
    {"city_id":"2711","city_name":"Murud","state_id":"22"},
    {"city_id":"2712","city_name":"Nachane","state_id":"22"},
    {"city_id":"2713","city_name":"Nagardeole","state_id":"22"},
    {"city_id":"2714","city_name":"Nagothane","state_id":"22"},
    {"city_id":"2715","city_name":"Nagpur","state_id":"22"},
    {"city_id":"2716","city_name":"Nakoda","state_id":"22"},
    {"city_id":"2717","city_name":"Nalasopara","state_id":"22"},
    {"city_id":"2718","city_name":"Naldurg","state_id":"22"},
    {"city_id":"2719","city_name":"Nanded","state_id":"22"},
    {"city_id":"2720","city_name":"Nandgaon","state_id":"22"},
    {"city_id":"2721","city_name":"Nandura","state_id":"22"},
    {"city_id":"2722","city_name":"Nandurbar","state_id":"22"},
    {"city_id":"2723","city_name":"Narkhed","state_id":"22"},
    {"city_id":"2724","city_name":"Nashik","state_id":"22"},
    {"city_id":"2725","city_name":"Navapur","state_id":"22"},
    {"city_id":"2726","city_name":"Navi Mumbai","state_id":"22"},
    {"city_id":"2727","city_name":"Navi Mumbai Panvel","state_id":"22"},
    {"city_id":"2728","city_name":"Neral","state_id":"22"},
    {"city_id":"2729","city_name":"Nigdi","state_id":"22"},
    {"city_id":"2730","city_name":"Nilanga","state_id":"22"},
    {"city_id":"2731","city_name":"Nildoh","state_id":"22"},
    {"city_id":"2732","city_name":"Nimbhore","state_id":"22"},
    {"city_id":"2733","city_name":"Ojhar","state_id":"22"},
    {"city_id":"2734","city_name":"Osmanabad","state_id":"22"},
    {"city_id":"2735","city_name":"Pachgaon","state_id":"22"},
    {"city_id":"2736","city_name":"Pachora","state_id":"22"},
    {"city_id":"2737","city_name":"Padagha","state_id":"22"},
    {"city_id":"2738","city_name":"Paithan","state_id":"22"},
    {"city_id":"2739","city_name":"Palghar","state_id":"22"},
    {"city_id":"2740","city_name":"Pali","state_id":"22"},
    {"city_id":"2741","city_name":"Panchgani","state_id":"22"},
    {"city_id":"2742","city_name":"Pandhakarwada","state_id":"22"},
    {"city_id":"2743","city_name":"Pandharpur","state_id":"22"},
    {"city_id":"2744","city_name":"Panhala","state_id":"22"},
    {"city_id":"2745","city_name":"Panvel","state_id":"22"},
    {"city_id":"2746","city_name":"Paranda","state_id":"22"},
    {"city_id":"2747","city_name":"Parbhani","state_id":"22"},
    {"city_id":"2748","city_name":"Parli","state_id":"22"},
    {"city_id":"2749","city_name":"Parola","state_id":"22"},
    {"city_id":"2750","city_name":"Partur","state_id":"22"},
    {"city_id":"2751","city_name":"Pasthal","state_id":"22"},
    {"city_id":"2752","city_name":"Patan","state_id":"22"},
    {"city_id":"2753","city_name":"Pathardi","state_id":"22"},
    {"city_id":"2754","city_name":"Pathri","state_id":"22"},
    {"city_id":"2755","city_name":"Patur","state_id":"22"},
    {"city_id":"2756","city_name":"Pawni","state_id":"22"},
    {"city_id":"2757","city_name":"Pen","state_id":"22"},
    {"city_id":"2758","city_name":"Pethumri","state_id":"22"},
    {"city_id":"2759","city_name":"Phaltan","state_id":"22"},
    {"city_id":"2760","city_name":"Pimpri","state_id":"22"},
    {"city_id":"2761","city_name":"Poladpur","state_id":"22"},
    {"city_id":"2762","city_name":"Pulgaon","state_id":"22"},
    {"city_id":"2763","city_name":"Pune","state_id":"22"},
    {"city_id":"2764","city_name":"Pune Cantonment","state_id":"22"},
    {"city_id":"2765","city_name":"Purna","state_id":"22"},
    {"city_id":"2766","city_name":"Purushottamnagar","state_id":"22"},
    {"city_id":"2767","city_name":"Pusad","state_id":"22"},
    {"city_id":"2768","city_name":"Rahimatpur","state_id":"22"},
    {"city_id":"2769","city_name":"Rahta Pimplas","state_id":"22"},
    {"city_id":"2770","city_name":"Rahuri","state_id":"22"},
    {"city_id":"2771","city_name":"Raigad","state_id":"22"},
    {"city_id":"2772","city_name":"Rajapur","state_id":"22"},
    {"city_id":"2773","city_name":"Rajgurunagar","state_id":"22"},
    {"city_id":"2774","city_name":"Rajur","state_id":"22"},
    {"city_id":"2775","city_name":"Rajura","state_id":"22"},
    {"city_id":"2776","city_name":"Ramtek","state_id":"22"},
    {"city_id":"2777","city_name":"Ratnagiri","state_id":"22"},
    {"city_id":"2778","city_name":"Ravalgaon","state_id":"22"},
    {"city_id":"2779","city_name":"Raver","state_id":"22"},
    {"city_id":"2780","city_name":"Revadanda","state_id":"22"},
    {"city_id":"2781","city_name":"Risod","state_id":"22"},
    {"city_id":"2782","city_name":"Roha Ashtami","state_id":"22"},
    {"city_id":"2783","city_name":"Sakri","state_id":"22"},
    {"city_id":"2784","city_name":"Sandor","state_id":"22"},
    {"city_id":"2785","city_name":"Sangamner","state_id":"22"},
    {"city_id":"2786","city_name":"Sangli","state_id":"22"},
    {"city_id":"2787","city_name":"Sangole","state_id":"22"},
    {"city_id":"2788","city_name":"Sasti","state_id":"22"},
    {"city_id":"2789","city_name":"Sasvad","state_id":"22"},
    {"city_id":"2790","city_name":"Satana","state_id":"22"},
    {"city_id":"2791","city_name":"Satara","state_id":"22"},
    {"city_id":"2792","city_name":"Savantvadi","state_id":"22"},
    {"city_id":"2793","city_name":"Savda","state_id":"22"},
    {"city_id":"2794","city_name":"Savner","state_id":"22"},
    {"city_id":"2795","city_name":"Sawari Jawharnagar","state_id":"22"},
    {"city_id":"2796","city_name":"Selu","state_id":"22"},
    {"city_id":"2797","city_name":"Shahada","state_id":"22"},
    {"city_id":"2798","city_name":"Shahapur","state_id":"22"},
    {"city_id":"2799","city_name":"Shegaon","state_id":"22"},
    {"city_id":"2800","city_name":"Shelar","state_id":"22"},
    {"city_id":"2801","city_name":"Shendurjana","state_id":"22"},
    {"city_id":"2802","city_name":"Shirdi","state_id":"22"},
    {"city_id":"2803","city_name":"Shirgaon","state_id":"22"},
    {"city_id":"2804","city_name":"Shirpur","state_id":"22"},
    {"city_id":"2805","city_name":"Shirur","state_id":"22"},
    {"city_id":"2806","city_name":"Shirwal","state_id":"22"},
    {"city_id":"2807","city_name":"Shivatkar","state_id":"22"},
    {"city_id":"2808","city_name":"Shrigonda","state_id":"22"},
    {"city_id":"2809","city_name":"Shrirampur","state_id":"22"},
    {"city_id":"2810","city_name":"Shrirampur Rural","state_id":"22"},
    {"city_id":"2811","city_name":"Sillewada","state_id":"22"},
    {"city_id":"2812","city_name":"Sillod","state_id":"22"},
    {"city_id":"2813","city_name":"Sindhudurg","state_id":"22"},
    {"city_id":"2814","city_name":"Sindi","state_id":"22"},
    {"city_id":"2815","city_name":"Sindi Turf Hindnagar","state_id":"22"},
    {"city_id":"2816","city_name":"Sindkhed Raja","state_id":"22"},
    {"city_id":"2817","city_name":"Singnapur","state_id":"22"},
    {"city_id":"2818","city_name":"Sinnar","state_id":"22"},
    {"city_id":"2819","city_name":"Sirur","state_id":"22"},
    {"city_id":"2820","city_name":"Sitasawangi","state_id":"22"},
    {"city_id":"2821","city_name":"Solapur","state_id":"22"},
    {"city_id":"2822","city_name":"Sonai","state_id":"22"},
    {"city_id":"2823","city_name":"Sonegaon","state_id":"22"},
    {"city_id":"2824","city_name":"Soyagaon","state_id":"22"},
    {"city_id":"2825","city_name":"Srivardhan","state_id":"22"},
    {"city_id":"2826","city_name":"Surgana","state_id":"22"},
    {"city_id":"2827","city_name":"Talegaon Dabhade","state_id":"22"},
    {"city_id":"2828","city_name":"Taloda","state_id":"22"},
    {"city_id":"2829","city_name":"Taloja","state_id":"22"},
    {"city_id":"2830","city_name":"Talwade","state_id":"22"},
    {"city_id":"2831","city_name":"Tarapur","state_id":"22"},
    {"city_id":"2832","city_name":"Tasgaon","state_id":"22"},
    {"city_id":"2833","city_name":"Tathavade","state_id":"22"},
    {"city_id":"2834","city_name":"Tekadi","state_id":"22"},
    {"city_id":"2835","city_name":"Telhara","state_id":"22"},
    {"city_id":"2836","city_name":"Thane","state_id":"22"},
    {"city_id":"2837","city_name":"Tirira","state_id":"22"},
    {"city_id":"2838","city_name":"Totaladoh","state_id":"22"},
    {"city_id":"2839","city_name":"Trimbak","state_id":"22"},
    {"city_id":"2840","city_name":"Tuljapur","state_id":"22"},
    {"city_id":"2841","city_name":"Tumsar","state_id":"22"},
    {"city_id":"2842","city_name":"Uchgaon","state_id":"22"},
    {"city_id":"2843","city_name":"Udgir","state_id":"22"},
    {"city_id":"2844","city_name":"Ulhasnagar","state_id":"22"},
    {"city_id":"2845","city_name":"Umarga","state_id":"22"},
    {"city_id":"2846","city_name":"Umarkhed","state_id":"22"},
    {"city_id":"2847","city_name":"Umarsara","state_id":"22"},
    {"city_id":"2848","city_name":"Umbar Pada Nandade","state_id":"22"},
    {"city_id":"2849","city_name":"Umred","state_id":"22"},
    {"city_id":"2850","city_name":"Umri Pragane Balapur","state_id":"22"},
    {"city_id":"2851","city_name":"Uran","state_id":"22"},
    {"city_id":"2852","city_name":"Uran Islampur","state_id":"22"},
    {"city_id":"2853","city_name":"Utekhol","state_id":"22"},
    {"city_id":"2854","city_name":"Vada","state_id":"22"},
    {"city_id":"2855","city_name":"Vadgaon","state_id":"22"},
    {"city_id":"2856","city_name":"Vadgaon Kasba","state_id":"22"},
    {"city_id":"2857","city_name":"Vaijapur","state_id":"22"},
    {"city_id":"2858","city_name":"Vanvadi","state_id":"22"},
    {"city_id":"2859","city_name":"Varangaon","state_id":"22"},
    {"city_id":"2860","city_name":"Vasai","state_id":"22"},
    {"city_id":"2861","city_name":"Vasantnagar","state_id":"22"},
    {"city_id":"2862","city_name":"Vashind","state_id":"22"},
    {"city_id":"2863","city_name":"Vengurla","state_id":"22"},
    {"city_id":"2864","city_name":"Virar","state_id":"22"},
    {"city_id":"2865","city_name":"Visapur","state_id":"22"},
    {"city_id":"2866","city_name":"Vite","state_id":"22"},
    {"city_id":"2867","city_name":"Vithalwadi","state_id":"22"},
    {"city_id":"2868","city_name":"Wadi","state_id":"22"},
    {"city_id":"2869","city_name":"Waghapur","state_id":"22"},
    {"city_id":"2870","city_name":"Wai","state_id":"22"},
    {"city_id":"2871","city_name":"Wajegaon","state_id":"22"},
    {"city_id":"2872","city_name":"Walani","state_id":"22"},
    {"city_id":"2873","city_name":"Wanadongri","state_id":"22"},
    {"city_id":"2874","city_name":"Wani","state_id":"22"},
    {"city_id":"2875","city_name":"Wardha","state_id":"22"},
    {"city_id":"2876","city_name":"Warora","state_id":"22"},
    {"city_id":"2877","city_name":"Warthi","state_id":"22"},
    {"city_id":"2878","city_name":"Warud","state_id":"22"},
    {"city_id":"2879","city_name":"Washim","state_id":"22"},
    {"city_id":"2880","city_name":"Yaval","state_id":"22"},
    {"city_id":"2881","city_name":"Yavatmal","state_id":"22"},
    {"city_id":"2882","city_name":"Yeola","state_id":"22"},
    {"city_id":"2883","city_name":"Yerkheda","state_id":"22"},
    {"city_id":"2884","city_name":"Andro","state_id":"23"},
    {"city_id":"2885","city_name":"Bijoy Govinda","state_id":"23"},
    {"city_id":"2886","city_name":"Bishnupur","state_id":"23"},
    {"city_id":"2887","city_name":"Churachandpur","state_id":"23"},
    {"city_id":"2888","city_name":"Heriok","state_id":"23"},
    {"city_id":"2889","city_name":"Imphal","state_id":"23"},
    {"city_id":"2890","city_name":"Jiribam","state_id":"23"},
    {"city_id":"2891","city_name":"Kakching","state_id":"23"},
    {"city_id":"2892","city_name":"Kakching Khunou","state_id":"23"},
    {"city_id":"2893","city_name":"Khongman","state_id":"23"},
    {"city_id":"2894","city_name":"Kumbi","state_id":"23"},
    {"city_id":"2895","city_name":"Kwakta","state_id":"23"},
    {"city_id":"2896","city_name":"Lamai","state_id":"23"},
    {"city_id":"2897","city_name":"Lamjaotongba","state_id":"23"},
    {"city_id":"2898","city_name":"Lamshang","state_id":"23"},
    {"city_id":"2899","city_name":"Lilong","state_id":"23"},
    {"city_id":"2900","city_name":"Mayang Imphal","state_id":"23"},
    {"city_id":"2901","city_name":"Moirang","state_id":"23"},
    {"city_id":"2902","city_name":"Moreh","state_id":"23"},
    {"city_id":"2903","city_name":"Nambol","state_id":"23"},
    {"city_id":"2904","city_name":"Naoriya Pakhanglakpa","state_id":"23"},
    {"city_id":"2905","city_name":"Ningthoukhong","state_id":"23"},
    {"city_id":"2906","city_name":"Oinam","state_id":"23"},
    {"city_id":"2907","city_name":"Porompat","state_id":"23"},
    {"city_id":"2908","city_name":"Samurou","state_id":"23"},
    {"city_id":"2909","city_name":"Sekmai Bazar","state_id":"23"},
    {"city_id":"2910","city_name":"Senapati","state_id":"23"},
    {"city_id":"2911","city_name":"Sikhong Sekmai","state_id":"23"},
    {"city_id":"2912","city_name":"Sugnu","state_id":"23"},
    {"city_id":"2913","city_name":"Thongkhong Laxmi Bazar","state_id":"23"},
    {"city_id":"2914","city_name":"Thoubal","state_id":"23"},
    {"city_id":"2915","city_name":"Torban","state_id":"23"},
    {"city_id":"2916","city_name":"Wangjing","state_id":"23"},
    {"city_id":"2917","city_name":"Wangoi","state_id":"23"},
    {"city_id":"2918","city_name":"Yairipok","state_id":"23"},
    {"city_id":"2919","city_name":"Baghmara","state_id":"24"},
    {"city_id":"2920","city_name":"Cherrapunji","state_id":"24"},
    {"city_id":"2921","city_name":"Jawai","state_id":"24"},
    {"city_id":"2922","city_name":"Madanrting","state_id":"24"},
    {"city_id":"2923","city_name":"Mairang","state_id":"24"},
    {"city_id":"2924","city_name":"Mawlai","state_id":"24"},
    {"city_id":"2925","city_name":"Nongmynsong","state_id":"24"},
    {"city_id":"2926","city_name":"Nongpoh","state_id":"24"},
    {"city_id":"2927","city_name":"Nongstoin","state_id":"24"},
    {"city_id":"2928","city_name":"Nongthymmai","state_id":"24"},
    {"city_id":"2929","city_name":"Pynthorumkhrah","state_id":"24"},
    {"city_id":"2930","city_name":"Resubelpara","state_id":"24"},
    {"city_id":"2931","city_name":"Shillong","state_id":"24"},
    {"city_id":"2932","city_name":"Shillong Cantonment","state_id":"24"},
    {"city_id":"2933","city_name":"Tura","state_id":"24"},
    {"city_id":"2934","city_name":"Williamnagar","state_id":"24"},
    {"city_id":"2935","city_name":"Aizawl","state_id":"25"},
    {"city_id":"2936","city_name":"Bairabi","state_id":"25"},
    {"city_id":"2937","city_name":"Biate","state_id":"25"},
    {"city_id":"2938","city_name":"Champhai","state_id":"25"},
    {"city_id":"2939","city_name":"Darlawn","state_id":"25"},
    {"city_id":"2940","city_name":"Hnahthial","state_id":"25"},
    {"city_id":"2941","city_name":"Kawnpui","state_id":"25"},
    {"city_id":"2942","city_name":"Khawhai","state_id":"25"},
    {"city_id":"2943","city_name":"Khawzawl","state_id":"25"},
    {"city_id":"2944","city_name":"Kolasib","state_id":"25"},
    {"city_id":"2945","city_name":"Lengpui","state_id":"25"},
    {"city_id":"2946","city_name":"Lunglei","state_id":"25"},
    {"city_id":"2947","city_name":"Mamit","state_id":"25"},
    {"city_id":"2948","city_name":"North Vanlaiphai","state_id":"25"},
    {"city_id":"2949","city_name":"Saiha","state_id":"25"},
    {"city_id":"2950","city_name":"Sairang","state_id":"25"},
    {"city_id":"2951","city_name":"Saitul","state_id":"25"},
    {"city_id":"2952","city_name":"Serchhip","state_id":"25"},
    {"city_id":"2953","city_name":"Thenzawl","state_id":"25"},
    {"city_id":"2954","city_name":"Tlabung","state_id":"25"},
    {"city_id":"2955","city_name":"Vairengte","state_id":"25"},
    {"city_id":"2956","city_name":"Zawlnuam","state_id":"25"},
    {"city_id":"2957","city_name":"Chumukedima","state_id":"26"},
    {"city_id":"2958","city_name":"Dimapur","state_id":"26"},
    {"city_id":"2959","city_name":"Kohima","state_id":"26"},
    {"city_id":"2960","city_name":"Mokokchung","state_id":"26"},
    {"city_id":"2961","city_name":"Mon","state_id":"26"},
    {"city_id":"2962","city_name":"Phek","state_id":"26"},
    {"city_id":"2963","city_name":"Tuensang","state_id":"26"},
    {"city_id":"2964","city_name":"Wokha","state_id":"26"},
    {"city_id":"2965","city_name":"Zunheboto","state_id":"26"},
    {"city_id":"2966","city_name":"Anandapur","state_id":"29"},
    {"city_id":"2967","city_name":"Angul","state_id":"29"},
    {"city_id":"2968","city_name":"Aska","state_id":"29"},
    {"city_id":"2969","city_name":"Athgarh","state_id":"29"},
    {"city_id":"2970","city_name":"Athmallik","state_id":"29"},
    {"city_id":"2971","city_name":"Balagoda","state_id":"29"},
    {"city_id":"2972","city_name":"Balangir","state_id":"29"},
    {"city_id":"2973","city_name":"Balasore","state_id":"29"},
    {"city_id":"2974","city_name":"Baleshwar","state_id":"29"},
    {"city_id":"2975","city_name":"Balimeta","state_id":"29"},
    {"city_id":"2976","city_name":"Balugaon","state_id":"29"},
    {"city_id":"2977","city_name":"Banapur","state_id":"29"},
    {"city_id":"2978","city_name":"Bangura","state_id":"29"},
    {"city_id":"2979","city_name":"Banki","state_id":"29"},
    {"city_id":"2980","city_name":"Banposh","state_id":"29"},
    {"city_id":"2981","city_name":"Barbil","state_id":"29"},
    {"city_id":"2982","city_name":"Bargarh","state_id":"29"},
    {"city_id":"2983","city_name":"Baripada","state_id":"29"},
    {"city_id":"2984","city_name":"Barpali","state_id":"29"},
    {"city_id":"2985","city_name":"Basudebpur","state_id":"29"},
    {"city_id":"2986","city_name":"Baudh","state_id":"29"},
    {"city_id":"2987","city_name":"Belagachhia","state_id":"29"},
    {"city_id":"2988","city_name":"Belaguntha","state_id":"29"},
    {"city_id":"2989","city_name":"Belpahar","state_id":"29"},
    {"city_id":"2990","city_name":"Berhampur","state_id":"29"},
    {"city_id":"2991","city_name":"Bhadrak","state_id":"29"},
    {"city_id":"2992","city_name":"Bhanjanagar","state_id":"29"},
    {"city_id":"2993","city_name":"Bhawanipatna","state_id":"29"},
    {"city_id":"2994","city_name":"Bhuban","state_id":"29"},
    {"city_id":"2995","city_name":"Bhubaneswar","state_id":"29"},
    {"city_id":"2996","city_name":"Binika","state_id":"29"},
    {"city_id":"2997","city_name":"Birmitrapur","state_id":"29"},
    {"city_id":"2998","city_name":"Bishama Katek","state_id":"29"},
    {"city_id":"2999","city_name":"Bolangir","state_id":"29"},
    {"city_id":"3000","city_name":"Brahmapur","state_id":"29"},
    {"city_id":"3001","city_name":"Brajrajnagar","state_id":"29"},
    {"city_id":"3002","city_name":"Buguda","state_id":"29"},
    {"city_id":"3003","city_name":"Burla","state_id":"29"},
    {"city_id":"3004","city_name":"Byasanagar","state_id":"29"},
    {"city_id":"3005","city_name":"Champua","state_id":"29"},
    {"city_id":"3006","city_name":"Chandapur","state_id":"29"},
    {"city_id":"3007","city_name":"Chandbali","state_id":"29"},
    {"city_id":"3008","city_name":"Chandili","state_id":"29"},
    {"city_id":"3009","city_name":"Charibatia","state_id":"29"},
    {"city_id":"3010","city_name":"Chatrapur","state_id":"29"},
    {"city_id":"3011","city_name":"Chikitigarh","state_id":"29"},
    {"city_id":"3012","city_name":"Chitrakonda","state_id":"29"},
    {"city_id":"3013","city_name":"Choudwar","state_id":"29"},
    {"city_id":"3014","city_name":"Cuttack","state_id":"29"},
    {"city_id":"3015","city_name":"Dadhapatna","state_id":"29"},
    {"city_id":"3016","city_name":"Daitari","state_id":"29"},
    {"city_id":"3017","city_name":"Damanjodi","state_id":"29"},
    {"city_id":"3018","city_name":"Deogarh","state_id":"29"},
    {"city_id":"3019","city_name":"Deracolliery","state_id":"29"},
    {"city_id":"3020","city_name":"Dhamanagar","state_id":"29"},
    {"city_id":"3021","city_name":"Dhenkanal","state_id":"29"},
    {"city_id":"3022","city_name":"Digapahandi","state_id":"29"},
    {"city_id":"3023","city_name":"Dungamal","state_id":"29"},
    {"city_id":"3024","city_name":"Fertilizer Corporation of Indi","state_id":"29"},
    {"city_id":"3025","city_name":"Ganjam","state_id":"29"},
    {"city_id":"3026","city_name":"Ghantapada","state_id":"29"},
    {"city_id":"3027","city_name":"Gopalpur","state_id":"29"},
    {"city_id":"3028","city_name":"Gudari","state_id":"29"},
    {"city_id":"3029","city_name":"Gunupur","state_id":"29"},
    {"city_id":"3030","city_name":"Hatibandha","state_id":"29"},
    {"city_id":"3031","city_name":"Hinjilikatu","state_id":"29"},
    {"city_id":"3032","city_name":"Hirakud","state_id":"29"},
    {"city_id":"3033","city_name":"Jagatsinghapur","state_id":"29"},
    {"city_id":"3034","city_name":"Jajpur","state_id":"29"},
    {"city_id":"3035","city_name":"Jalda","state_id":"29"},
    {"city_id":"3036","city_name":"Jaleswar","state_id":"29"},
    {"city_id":"3037","city_name":"Jatni","state_id":"29"},
    {"city_id":"3038","city_name":"Jaypur","state_id":"29"},
    {"city_id":"3039","city_name":"Jeypore","state_id":"29"},
    {"city_id":"3040","city_name":"Jharsuguda","state_id":"29"},
    {"city_id":"3041","city_name":"Jhumpura","state_id":"29"},
    {"city_id":"3042","city_name":"Joda","state_id":"29"},
    {"city_id":"3043","city_name":"Junagarh","state_id":"29"},
    {"city_id":"3044","city_name":"Kamakhyanagar","state_id":"29"},
    {"city_id":"3045","city_name":"Kantabanji","state_id":"29"},
    {"city_id":"3046","city_name":"Kantilo","state_id":"29"},
    {"city_id":"3047","city_name":"Karanja","state_id":"29"},
    {"city_id":"3048","city_name":"Kashinagara","state_id":"29"},
    {"city_id":"3049","city_name":"Kataka","state_id":"29"},
    {"city_id":"3050","city_name":"Kavisuryanagar","state_id":"29"},
    {"city_id":"3051","city_name":"Kendrapara","state_id":"29"},
    {"city_id":"3052","city_name":"Kendujhar","state_id":"29"},
    {"city_id":"3053","city_name":"Keonjhar","state_id":"29"},
    {"city_id":"3054","city_name":"Kesinga","state_id":"29"},
    {"city_id":"3055","city_name":"Khaliapali","state_id":"29"},
    {"city_id":"3056","city_name":"Khalikote","state_id":"29"},
    {"city_id":"3057","city_name":"Khandaparha","state_id":"29"},
    {"city_id":"3058","city_name":"Kharhial","state_id":"29"},
    {"city_id":"3059","city_name":"Kharhial Road","state_id":"29"},
    {"city_id":"3060","city_name":"Khatiguda","state_id":"29"},
    {"city_id":"3061","city_name":"Khurda","state_id":"29"},
    {"city_id":"3062","city_name":"Kochinda","state_id":"29"},
    {"city_id":"3063","city_name":"Kodala","state_id":"29"},
    {"city_id":"3064","city_name":"Konark","state_id":"29"},
    {"city_id":"3065","city_name":"Koraput","state_id":"29"},
    {"city_id":"3066","city_name":"Kotaparh","state_id":"29"},
    {"city_id":"3067","city_name":"Lanjigarh","state_id":"29"},
    {"city_id":"3068","city_name":"Lattikata","state_id":"29"},
    {"city_id":"3069","city_name":"Makundapur","state_id":"29"},
    {"city_id":"3070","city_name":"Malkangiri","state_id":"29"},
    {"city_id":"3071","city_name":"Mukhiguda","state_id":"29"},
    {"city_id":"3072","city_name":"Nabarangpur","state_id":"29"},
    {"city_id":"3073","city_name":"Nalco","state_id":"29"},
    {"city_id":"3074","city_name":"Naurangapur","state_id":"29"},
    {"city_id":"3075","city_name":"Nayagarh","state_id":"29"},
    {"city_id":"3076","city_name":"Nilagiri","state_id":"29"},
    {"city_id":"3077","city_name":"Nimaparha","state_id":"29"},
    {"city_id":"3078","city_name":"Nuapada","state_id":"29"},
    {"city_id":"3079","city_name":"Nuapatna","state_id":"29"},
    {"city_id":"3080","city_name":"OCL Industrialship","state_id":"29"},
    {"city_id":"3081","city_name":"Padampur","state_id":"29"},
    {"city_id":"3082","city_name":"Paradip","state_id":"29"},
    {"city_id":"3083","city_name":"Paradwip","state_id":"29"},
    {"city_id":"3084","city_name":"Parlakimidi","state_id":"29"},
    {"city_id":"3085","city_name":"Patamundai","state_id":"29"},
    {"city_id":"3086","city_name":"Patnagarh","state_id":"29"},
    {"city_id":"3087","city_name":"Phulabani","state_id":"29"},
    {"city_id":"3088","city_name":"Pipili","state_id":"29"},
    {"city_id":"3089","city_name":"Polasara","state_id":"29"},
    {"city_id":"3090","city_name":"Pratapsasan","state_id":"29"},
    {"city_id":"3091","city_name":"Puri","state_id":"29"},
    {"city_id":"3092","city_name":"Purushottampur","state_id":"29"},
    {"city_id":"3093","city_name":"Rairangpur","state_id":"29"},
    {"city_id":"3094","city_name":"Raj Gangpur","state_id":"29"},
    {"city_id":"3095","city_name":"Rambha","state_id":"29"},
    {"city_id":"3096","city_name":"Raurkela","state_id":"29"},
    {"city_id":"3097","city_name":"Raurkela Civil Township","state_id":"29"},
    {"city_id":"3098","city_name":"Rayagada","state_id":"29"},
    {"city_id":"3099","city_name":"Redhakhol","state_id":"29"},
    {"city_id":"3100","city_name":"Remuna","state_id":"29"},
    {"city_id":"3101","city_name":"Rengali","state_id":"29"},
    {"city_id":"3102","city_name":"Rourkela","state_id":"29"},
    {"city_id":"3103","city_name":"Sambalpur","state_id":"29"},
    {"city_id":"3104","city_name":"Sinapali","state_id":"29"},
    {"city_id":"3105","city_name":"Sonepur","state_id":"29"},
    {"city_id":"3106","city_name":"Sorada","state_id":"29"},
    {"city_id":"3107","city_name":"Soro","state_id":"29"},
    {"city_id":"3108","city_name":"Sunabeda","state_id":"29"},
    {"city_id":"3109","city_name":"Sundargarh","state_id":"29"},
    {"city_id":"3110","city_name":"Talcher","state_id":"29"},
    {"city_id":"3111","city_name":"Talcher Thermal Power Station ","state_id":"29"},
    {"city_id":"3112","city_name":"Tarabha","state_id":"29"},
    {"city_id":"3113","city_name":"Tensa","state_id":"29"},
    {"city_id":"3114","city_name":"Titlagarh","state_id":"29"},
    {"city_id":"3115","city_name":"Udala","state_id":"29"},
    {"city_id":"3116","city_name":"Udayagiri","state_id":"29"},
    {"city_id":"3117","city_name":"Umarkot","state_id":"29"},
    {"city_id":"3118","city_name":"Vikrampur","state_id":"29"},
    {"city_id":"3119","city_name":"Ariankuppam","state_id":"31"},
    {"city_id":"3120","city_name":"Karaikal","state_id":"31"},
    {"city_id":"3121","city_name":"Kurumbapet","state_id":"31"},
    {"city_id":"3122","city_name":"Mahe","state_id":"31"},
    {"city_id":"3123","city_name":"Ozhukarai","state_id":"31"},
    {"city_id":"3124","city_name":"Pondicherry","state_id":"31"},
    {"city_id":"3125","city_name":"Villianur","state_id":"31"},
    {"city_id":"3126","city_name":"Yanam","state_id":"31"},
    {"city_id":"3127","city_name":"Abohar","state_id":"32"},
    {"city_id":"3128","city_name":"Adampur","state_id":"32"},
    {"city_id":"3129","city_name":"Ahmedgarh","state_id":"32"},
    {"city_id":"3130","city_name":"Ajnala","state_id":"32"},
    {"city_id":"3131","city_name":"Akalgarh","state_id":"32"},
    {"city_id":"3132","city_name":"Alawalpur","state_id":"32"},
    {"city_id":"3133","city_name":"Amloh","state_id":"32"},
    {"city_id":"3134","city_name":"Amritsar","state_id":"32"},
    {"city_id":"3135","city_name":"Amritsar Cantonment","state_id":"32"},
    {"city_id":"3136","city_name":"Anandpur Sahib","state_id":"32"},
    {"city_id":"3137","city_name":"Badhni Kalan","state_id":"32"},
    {"city_id":"3138","city_name":"Bagh Purana","state_id":"32"},
    {"city_id":"3139","city_name":"Balachaur","state_id":"32"},
    {"city_id":"3140","city_name":"Banaur","state_id":"32"},
    {"city_id":"3141","city_name":"Banga","state_id":"32"},
    {"city_id":"3142","city_name":"Banur","state_id":"32"},
    {"city_id":"3143","city_name":"Baretta","state_id":"32"},
    {"city_id":"3144","city_name":"Bariwala","state_id":"32"},
    {"city_id":"3145","city_name":"Barnala","state_id":"32"},
    {"city_id":"3146","city_name":"Bassi Pathana","state_id":"32"},
    {"city_id":"3147","city_name":"Batala","state_id":"32"},
    {"city_id":"3148","city_name":"Bathinda","state_id":"32"},
    {"city_id":"3149","city_name":"Begowal","state_id":"32"},
    {"city_id":"3150","city_name":"Behrampur","state_id":"32"},
    {"city_id":"3151","city_name":"Bhabat","state_id":"32"},
    {"city_id":"3152","city_name":"Bhadur","state_id":"32"},
    {"city_id":"3153","city_name":"Bhankharpur","state_id":"32"},
    {"city_id":"3154","city_name":"Bharoli Kalan","state_id":"32"},
    {"city_id":"3155","city_name":"Bhawanigarh","state_id":"32"},
    {"city_id":"3156","city_name":"Bhikhi","state_id":"32"},
    {"city_id":"3157","city_name":"Bhikhiwind","state_id":"32"},
    {"city_id":"3158","city_name":"Bhisiana","state_id":"32"},
    {"city_id":"3159","city_name":"Bhogpur","state_id":"32"},
    {"city_id":"3160","city_name":"Bhuch","state_id":"32"},
    {"city_id":"3161","city_name":"Bhulath","state_id":"32"},
    {"city_id":"3162","city_name":"Budha Theh","state_id":"32"},
    {"city_id":"3163","city_name":"Budhlada","state_id":"32"},
    {"city_id":"3164","city_name":"Chima","state_id":"32"},
    {"city_id":"3165","city_name":"Chohal","state_id":"32"},
    {"city_id":"3166","city_name":"Dasuya","state_id":"32"},
    {"city_id":"3167","city_name":"Daulatpur","state_id":"32"},
    {"city_id":"3168","city_name":"Dera Baba Nanak","state_id":"32"},
    {"city_id":"3169","city_name":"Dera Bassi","state_id":"32"},
    {"city_id":"3170","city_name":"Dhanaula","state_id":"32"},
    {"city_id":"3171","city_name":"Dharam Kot","state_id":"32"},
    {"city_id":"3172","city_name":"Dhariwal","state_id":"32"},
    {"city_id":"3173","city_name":"Dhilwan","state_id":"32"},
    {"city_id":"3174","city_name":"Dhuri","state_id":"32"},
    {"city_id":"3175","city_name":"Dinanagar","state_id":"32"},
    {"city_id":"3176","city_name":"Dirba","state_id":"32"},
    {"city_id":"3177","city_name":"Doraha","state_id":"32"},
    {"city_id":"3178","city_name":"Faridkot","state_id":"32"},
    {"city_id":"3179","city_name":"Fateh Nangal","state_id":"32"},
    {"city_id":"3180","city_name":"Fatehgarh Churian","state_id":"32"},
    {"city_id":"3181","city_name":"Fatehgarh Sahib","state_id":"32"},
    {"city_id":"3182","city_name":"Fazilka","state_id":"32"},
    {"city_id":"3183","city_name":"Firozpur","state_id":"32"},
    {"city_id":"3184","city_name":"Firozpur Cantonment","state_id":"32"},
    {"city_id":"3185","city_name":"Gardhiwala","state_id":"32"},
    {"city_id":"3186","city_name":"Garhshankar","state_id":"32"},
    {"city_id":"3187","city_name":"Ghagga","state_id":"32"},
    {"city_id":"3188","city_name":"Ghanaur","state_id":"32"},
    {"city_id":"3189","city_name":"Giddarbaha","state_id":"32"},
    {"city_id":"3190","city_name":"Gobindgarh","state_id":"32"},
    {"city_id":"3191","city_name":"Goniana","state_id":"32"},
    {"city_id":"3192","city_name":"Goraya","state_id":"32"},
    {"city_id":"3193","city_name":"Gurdaspur","state_id":"32"},
    {"city_id":"3194","city_name":"Guru Har Sahai","state_id":"32"},
    {"city_id":"3195","city_name":"Hajipur","state_id":"32"},
    {"city_id":"3196","city_name":"Handiaya","state_id":"32"},
    {"city_id":"3197","city_name":"Hariana","state_id":"32"},
    {"city_id":"3198","city_name":"Hoshiarpur","state_id":"32"},
    {"city_id":"3199","city_name":"Hussainpur","state_id":"32"},
    {"city_id":"3200","city_name":"Jagraon","state_id":"32"},
    {"city_id":"3201","city_name":"Jaitu","state_id":"32"},
    {"city_id":"3202","city_name":"Jalalabad","state_id":"32"},
    {"city_id":"3203","city_name":"Jalandhar","state_id":"32"},
    {"city_id":"3204","city_name":"Jalandhar Cantonment","state_id":"32"},
    {"city_id":"3205","city_name":"Jandiala","state_id":"32"},
    {"city_id":"3206","city_name":"Jugial","state_id":"32"},
    {"city_id":"3207","city_name":"Kalanaur","state_id":"32"},
    {"city_id":"3208","city_name":"Kapurthala","state_id":"32"},
    {"city_id":"3209","city_name":"Karoran","state_id":"32"},
    {"city_id":"3210","city_name":"Kartarpur","state_id":"32"},
    {"city_id":"3211","city_name":"Khamanon","state_id":"32"},
    {"city_id":"3212","city_name":"Khanauri","state_id":"32"},
    {"city_id":"3213","city_name":"Khanna","state_id":"32"},
    {"city_id":"3214","city_name":"Kharar","state_id":"32"},
    {"city_id":"3215","city_name":"Khem Karan","state_id":"32"},
    {"city_id":"3216","city_name":"Kot Fatta","state_id":"32"},
    {"city_id":"3217","city_name":"Kot Isa Khan","state_id":"32"},
    {"city_id":"3218","city_name":"Kot Kapura","state_id":"32"},
    {"city_id":"3219","city_name":"Kotkapura","state_id":"32"},
    {"city_id":"3220","city_name":"Kurali","state_id":"32"},
    {"city_id":"3221","city_name":"Lalru","state_id":"32"},
    {"city_id":"3222","city_name":"Lehra Gaga","state_id":"32"},
    {"city_id":"3223","city_name":"Lodhian Khas","state_id":"32"},
    {"city_id":"3224","city_name":"Longowal","state_id":"32"},
    {"city_id":"3225","city_name":"Ludhiana","state_id":"32"},
    {"city_id":"3226","city_name":"Machhiwara","state_id":"32"},
    {"city_id":"3227","city_name":"Mahilpur","state_id":"32"},
    {"city_id":"3228","city_name":"Majitha","state_id":"32"},
    {"city_id":"3229","city_name":"Makhu","state_id":"32"},
    {"city_id":"3230","city_name":"Malaut","state_id":"32"},
    {"city_id":"3231","city_name":"Malerkotla","state_id":"32"},
    {"city_id":"3232","city_name":"Maloud","state_id":"32"},
    {"city_id":"3233","city_name":"Mandi Gobindgarh","state_id":"32"},
    {"city_id":"3234","city_name":"Mansa","state_id":"32"},
    {"city_id":"3235","city_name":"Maur","state_id":"32"},
    {"city_id":"3236","city_name":"Moga","state_id":"32"},
    {"city_id":"3237","city_name":"Mohali","state_id":"32"},
    {"city_id":"3238","city_name":"Moonak","state_id":"32"},
    {"city_id":"3239","city_name":"Morinda","state_id":"32"},
    {"city_id":"3240","city_name":"Mukerian","state_id":"32"},
    {"city_id":"3241","city_name":"Muktsar","state_id":"32"},
    {"city_id":"3242","city_name":"Mullanpur Dakha","state_id":"32"},
    {"city_id":"3243","city_name":"Mullanpur Garibdas","state_id":"32"},
    {"city_id":"3244","city_name":"Munak","state_id":"32"},
    {"city_id":"3245","city_name":"Muradpura","state_id":"32"},
    {"city_id":"3246","city_name":"Nabha","state_id":"32"},
    {"city_id":"3247","city_name":"Nakodar","state_id":"32"},
    {"city_id":"3248","city_name":"Nangal","state_id":"32"},
    {"city_id":"3249","city_name":"Nawashahr","state_id":"32"},
    {"city_id":"3250","city_name":"Naya Nangal","state_id":"32"},
    {"city_id":"3251","city_name":"Nehon","state_id":"32"},
    {"city_id":"3252","city_name":"Nurmahal","state_id":"32"},
    {"city_id":"3253","city_name":"Pathankot","state_id":"32"},
    {"city_id":"3254","city_name":"Patiala","state_id":"32"},
    {"city_id":"3255","city_name":"Patti","state_id":"32"},
    {"city_id":"3256","city_name":"Pattran","state_id":"32"},
    {"city_id":"3257","city_name":"Payal","state_id":"32"},
    {"city_id":"3258","city_name":"Phagwara","state_id":"32"},
    {"city_id":"3259","city_name":"Phillaur","state_id":"32"},
    {"city_id":"3260","city_name":"Qadian","state_id":"32"},
    {"city_id":"3261","city_name":"Rahon","state_id":"32"},
    {"city_id":"3262","city_name":"Raikot","state_id":"32"},
    {"city_id":"3263","city_name":"Raja Sansi","state_id":"32"},
    {"city_id":"3264","city_name":"Rajpura","state_id":"32"},
    {"city_id":"3265","city_name":"Ram Das","state_id":"32"},
    {"city_id":"3266","city_name":"Raman","state_id":"32"},
    {"city_id":"3267","city_name":"Rampura","state_id":"32"},
    {"city_id":"3268","city_name":"Rayya","state_id":"32"},
    {"city_id":"3269","city_name":"Rupnagar","state_id":"32"},
    {"city_id":"3270","city_name":"Rurki Kasba","state_id":"32"},
    {"city_id":"3271","city_name":"Sahnewal","state_id":"32"},
    {"city_id":"3272","city_name":"Samana","state_id":"32"},
    {"city_id":"3273","city_name":"Samrala","state_id":"32"},
    {"city_id":"3274","city_name":"Sanaur","state_id":"32"},
    {"city_id":"3275","city_name":"Sangat","state_id":"32"},
    {"city_id":"3276","city_name":"Sangrur","state_id":"32"},
    {"city_id":"3277","city_name":"Sansarpur","state_id":"32"},
    {"city_id":"3278","city_name":"Sardulgarh","state_id":"32"},
    {"city_id":"3279","city_name":"Shahkot","state_id":"32"},
    {"city_id":"3280","city_name":"Sham Churasi","state_id":"32"},
    {"city_id":"3281","city_name":"Shekhpura","state_id":"32"},
    {"city_id":"3282","city_name":"Sirhind","state_id":"32"},
    {"city_id":"3283","city_name":"Sri Hargobindpur","state_id":"32"},
    {"city_id":"3284","city_name":"Sujanpur","state_id":"32"},
    {"city_id":"3285","city_name":"Sultanpur Lodhi","state_id":"32"},
    {"city_id":"3286","city_name":"Sunam","state_id":"32"},
    {"city_id":"3287","city_name":"Talwandi Bhai","state_id":"32"},
    {"city_id":"3288","city_name":"Talwara","state_id":"32"},
    {"city_id":"3289","city_name":"Tappa","state_id":"32"},
    {"city_id":"3290","city_name":"Tarn Taran","state_id":"32"},
    {"city_id":"3291","city_name":"Urmar Tanda","state_id":"32"},
    {"city_id":"3292","city_name":"Zira","state_id":"32"},
    {"city_id":"3293","city_name":"Zirakpur","state_id":"32"},
    {"city_id":"3294","city_name":"Abu Road","state_id":"33"},
    {"city_id":"3295","city_name":"Ajmer","state_id":"33"},
    {"city_id":"3296","city_name":"Aklera","state_id":"33"},
    {"city_id":"3297","city_name":"Alwar","state_id":"33"},
    {"city_id":"3298","city_name":"Amet","state_id":"33"},
    {"city_id":"3299","city_name":"Antah","state_id":"33"},
    {"city_id":"3300","city_name":"Anupgarh","state_id":"33"},
    {"city_id":"3301","city_name":"Asind","state_id":"33"},
    {"city_id":"3302","city_name":"Bagar","state_id":"33"},
    {"city_id":"3303","city_name":"Bagru","state_id":"33"},
    {"city_id":"3304","city_name":"Bahror","state_id":"33"},
    {"city_id":"3305","city_name":"Bakani","state_id":"33"},
    {"city_id":"3306","city_name":"Bali","state_id":"33"},
    {"city_id":"3307","city_name":"Balotra","state_id":"33"},
    {"city_id":"3308","city_name":"Bandikui","state_id":"33"},
    {"city_id":"3309","city_name":"Banswara","state_id":"33"},
    {"city_id":"3310","city_name":"Baran","state_id":"33"},
    {"city_id":"3311","city_name":"Bari","state_id":"33"},
    {"city_id":"3312","city_name":"Bari Sadri","state_id":"33"},
    {"city_id":"3313","city_name":"Barmer","state_id":"33"},
    {"city_id":"3314","city_name":"Basi","state_id":"33"},
    {"city_id":"3315","city_name":"Basni Belima","state_id":"33"},
    {"city_id":"3316","city_name":"Baswa","state_id":"33"},
    {"city_id":"3317","city_name":"Bayana","state_id":"33"},
    {"city_id":"3318","city_name":"Beawar","state_id":"33"},
    {"city_id":"3319","city_name":"Begun","state_id":"33"},
    {"city_id":"3320","city_name":"Bhadasar","state_id":"33"},
    {"city_id":"3321","city_name":"Bhadra","state_id":"33"},
    {"city_id":"3322","city_name":"Bhalariya","state_id":"33"},
    {"city_id":"3323","city_name":"Bharatpur","state_id":"33"},
    {"city_id":"3324","city_name":"Bhasawar","state_id":"33"},
    {"city_id":"3325","city_name":"Bhawani Mandi","state_id":"33"},
    {"city_id":"3326","city_name":"Bhawri","state_id":"33"},
    {"city_id":"3327","city_name":"Bhilwara","state_id":"33"},
    {"city_id":"3328","city_name":"Bhindar","state_id":"33"},
    {"city_id":"3329","city_name":"Bhinmal","state_id":"33"},
    {"city_id":"3330","city_name":"Bhiwadi","state_id":"33"},
    {"city_id":"3331","city_name":"Bijoliya Kalan","state_id":"33"},
    {"city_id":"3332","city_name":"Bikaner","state_id":"33"},
    {"city_id":"3333","city_name":"Bilara","state_id":"33"},
    {"city_id":"3334","city_name":"Bissau","state_id":"33"},
    {"city_id":"3335","city_name":"Borkhera","state_id":"33"},
    {"city_id":"3336","city_name":"Budhpura","state_id":"33"},
    {"city_id":"3337","city_name":"Bundi","state_id":"33"},
    {"city_id":"3338","city_name":"Chatsu","state_id":"33"},
    {"city_id":"3339","city_name":"Chechat","state_id":"33"},
    {"city_id":"3340","city_name":"Chhabra","state_id":"33"},
    {"city_id":"3341","city_name":"Chhapar","state_id":"33"},
    {"city_id":"3342","city_name":"Chhipa Barod","state_id":"33"},
    {"city_id":"3343","city_name":"Chhoti Sadri","state_id":"33"},
    {"city_id":"3344","city_name":"Chirawa","state_id":"33"},
    {"city_id":"3345","city_name":"Chittaurgarh","state_id":"33"},
    {"city_id":"3346","city_name":"Chittorgarh","state_id":"33"},
    {"city_id":"3347","city_name":"Chomun","state_id":"33"},
    {"city_id":"3348","city_name":"Churu","state_id":"33"},
    {"city_id":"3349","city_name":"Daosa","state_id":"33"},
    {"city_id":"3350","city_name":"Dariba","state_id":"33"},
    {"city_id":"3351","city_name":"Dausa","state_id":"33"},
    {"city_id":"3352","city_name":"Deoli","state_id":"33"},
    {"city_id":"3353","city_name":"Deshnok","state_id":"33"},
    {"city_id":"3354","city_name":"Devgarh","state_id":"33"},
    {"city_id":"3355","city_name":"Devli","state_id":"33"},
    {"city_id":"3356","city_name":"Dhariawad","state_id":"33"},
    {"city_id":"3357","city_name":"Dhaulpur","state_id":"33"},
    {"city_id":"3358","city_name":"Dholpur","state_id":"33"},
    {"city_id":"3359","city_name":"Didwana","state_id":"33"},
    {"city_id":"3360","city_name":"Dig","state_id":"33"},
    {"city_id":"3361","city_name":"Dungargarh","state_id":"33"},
    {"city_id":"3362","city_name":"Dungarpur","state_id":"33"},
    {"city_id":"3363","city_name":"Falna","state_id":"33"},
    {"city_id":"3364","city_name":"Fatehnagar","state_id":"33"},
    {"city_id":"3365","city_name":"Fatehpur","state_id":"33"},
    {"city_id":"3366","city_name":"Gajsinghpur","state_id":"33"},
    {"city_id":"3367","city_name":"Galiakot","state_id":"33"},
    {"city_id":"3368","city_name":"Ganganagar","state_id":"33"},
    {"city_id":"3369","city_name":"Gangapur","state_id":"33"},
    {"city_id":"3370","city_name":"Goredi Chancha","state_id":"33"},
    {"city_id":"3371","city_name":"Gothra","state_id":"33"},
    {"city_id":"3372","city_name":"Govindgarh","state_id":"33"},
    {"city_id":"3373","city_name":"Gulabpura","state_id":"33"},
    {"city_id":"3374","city_name":"Hanumangarh","state_id":"33"},
    {"city_id":"3375","city_name":"Hindaun","state_id":"33"},
    {"city_id":"3376","city_name":"Indragarh","state_id":"33"},
    {"city_id":"3377","city_name":"Jahazpur","state_id":"33"},
    {"city_id":"3378","city_name":"Jaipur","state_id":"33"},
    {"city_id":"3379","city_name":"Jaisalmer","state_id":"33"},
    {"city_id":"3380","city_name":"Jaiselmer","state_id":"33"},
    {"city_id":"3381","city_name":"Jaitaran","state_id":"33"},
    {"city_id":"3382","city_name":"Jalore","state_id":"33"},
    {"city_id":"3383","city_name":"Jhalawar","state_id":"33"},
    {"city_id":"3384","city_name":"Jhalrapatan","state_id":"33"},
    {"city_id":"3385","city_name":"Jhunjhunun","state_id":"33"},
    {"city_id":"3386","city_name":"Jobner","state_id":"33"},
    {"city_id":"3387","city_name":"Jodhpur","state_id":"33"},
    {"city_id":"3388","city_name":"Kaithun","state_id":"33"},
    {"city_id":"3389","city_name":"Kaman","state_id":"33"},
    {"city_id":"3390","city_name":"Kankroli","state_id":"33"},
    {"city_id":"3391","city_name":"Kanor","state_id":"33"},
    {"city_id":"3392","city_name":"Kapasan","state_id":"33"},
    {"city_id":"3393","city_name":"Kaprain","state_id":"33"},
    {"city_id":"3394","city_name":"Karanpura","state_id":"33"},
    {"city_id":"3395","city_name":"Karauli","state_id":"33"},
    {"city_id":"3396","city_name":"Kekri","state_id":"33"},
    {"city_id":"3397","city_name":"Keshorai Patan","state_id":"33"},
    {"city_id":"3398","city_name":"Kesrisinghpur","state_id":"33"},
    {"city_id":"3399","city_name":"Khairthal","state_id":"33"},
    {"city_id":"3400","city_name":"Khandela","state_id":"33"},
    {"city_id":"3401","city_name":"Khanpur","state_id":"33"},
    {"city_id":"3402","city_name":"Kherli","state_id":"33"},
    {"city_id":"3403","city_name":"Kherliganj","state_id":"33"},
    {"city_id":"3404","city_name":"Kherwara Chhaoni","state_id":"33"},
    {"city_id":"3405","city_name":"Khetri","state_id":"33"},
    {"city_id":"3406","city_name":"Kiranipura","state_id":"33"},
    {"city_id":"3407","city_name":"Kishangarh","state_id":"33"},
    {"city_id":"3408","city_name":"Kishangarh Ranwal","state_id":"33"},
    {"city_id":"3409","city_name":"Kolvi Rajendrapura","state_id":"33"},
    {"city_id":"3410","city_name":"Kot Putli","state_id":"33"},
    {"city_id":"3411","city_name":"Kota","state_id":"33"},
    {"city_id":"3412","city_name":"Kuchaman","state_id":"33"},
    {"city_id":"3413","city_name":"Kuchera","state_id":"33"},
    {"city_id":"3414","city_name":"Kumbhalgarh","state_id":"33"},
    {"city_id":"3415","city_name":"Kumbhkot","state_id":"33"},
    {"city_id":"3416","city_name":"Kumher","state_id":"33"},
    {"city_id":"3417","city_name":"Kushalgarh","state_id":"33"},
    {"city_id":"3418","city_name":"Lachhmangarh","state_id":"33"},
    {"city_id":"3419","city_name":"Ladnun","state_id":"33"},
    {"city_id":"3420","city_name":"Lakheri","state_id":"33"},
    {"city_id":"3421","city_name":"Lalsot","state_id":"33"},
    {"city_id":"3422","city_name":"Losal","state_id":"33"},
    {"city_id":"3423","city_name":"Madanganj","state_id":"33"},
    {"city_id":"3424","city_name":"Mahu Kalan","state_id":"33"},
    {"city_id":"3425","city_name":"Mahwa","state_id":"33"},
    {"city_id":"3426","city_name":"Makrana","state_id":"33"},
    {"city_id":"3427","city_name":"Malpura","state_id":"33"},
    {"city_id":"3428","city_name":"Mandal","state_id":"33"},
    {"city_id":"3429","city_name":"Mandalgarh","state_id":"33"},
    {"city_id":"3430","city_name":"Mandawar","state_id":"33"},
    {"city_id":"3431","city_name":"Mandwa","state_id":"33"},
    {"city_id":"3432","city_name":"Mangrol","state_id":"33"},
    {"city_id":"3433","city_name":"Manohar Thana","state_id":"33"},
    {"city_id":"3434","city_name":"Manoharpur","state_id":"33"},
    {"city_id":"3435","city_name":"Marwar","state_id":"33"},
    {"city_id":"3436","city_name":"Merta","state_id":"33"},
    {"city_id":"3437","city_name":"Modak","state_id":"33"},
    {"city_id":"3438","city_name":"Mount Abu","state_id":"33"},
    {"city_id":"3439","city_name":"Mukandgarh","state_id":"33"},
    {"city_id":"3440","city_name":"Mundwa","state_id":"33"},
    {"city_id":"3441","city_name":"Nadbai","state_id":"33"},
    {"city_id":"3442","city_name":"Naenwa","state_id":"33"},
    {"city_id":"3443","city_name":"Nagar","state_id":"33"},
    {"city_id":"3444","city_name":"Nagaur","state_id":"33"},
    {"city_id":"3445","city_name":"Napasar","state_id":"33"},
    {"city_id":"3446","city_name":"Naraina","state_id":"33"},
    {"city_id":"3447","city_name":"Nasirabad","state_id":"33"},
    {"city_id":"3448","city_name":"Nathdwara","state_id":"33"},
    {"city_id":"3449","city_name":"Nawa","state_id":"33"},
    {"city_id":"3450","city_name":"Nawalgarh","state_id":"33"},
    {"city_id":"3451","city_name":"Neem Ka Thana","state_id":"33"},
    {"city_id":"3452","city_name":"Neemrana","state_id":"33"},
    {"city_id":"3453","city_name":"Newa Talai","state_id":"33"},
    {"city_id":"3454","city_name":"Nimaj","state_id":"33"},
    {"city_id":"3455","city_name":"Nimbahera","state_id":"33"},
    {"city_id":"3456","city_name":"Niwai","state_id":"33"},
    {"city_id":"3457","city_name":"Nohar","state_id":"33"},
    {"city_id":"3458","city_name":"Nokha","state_id":"33"},
    {"city_id":"3459","city_name":"One SGM","state_id":"33"},
    {"city_id":"3460","city_name":"Padampur","state_id":"33"},
    {"city_id":"3461","city_name":"Pali","state_id":"33"},
    {"city_id":"3462","city_name":"Partapur","state_id":"33"},
    {"city_id":"3463","city_name":"Parvatsar","state_id":"33"},
    {"city_id":"3464","city_name":"Pasoond","state_id":"33"},
    {"city_id":"3465","city_name":"Phalna","state_id":"33"},
    {"city_id":"3466","city_name":"Phalodi","state_id":"33"},
    {"city_id":"3467","city_name":"Phulera","state_id":"33"},
    {"city_id":"3468","city_name":"Pilani","state_id":"33"},
    {"city_id":"3469","city_name":"Pilibanga","state_id":"33"},
    {"city_id":"3470","city_name":"Pindwara","state_id":"33"},
    {"city_id":"3471","city_name":"Pipalia Kalan","state_id":"33"},
    {"city_id":"3472","city_name":"Pipar","state_id":"33"},
    {"city_id":"3473","city_name":"Pirawa","state_id":"33"},
    {"city_id":"3474","city_name":"Pokaran","state_id":"33"},
    {"city_id":"3475","city_name":"Pratapgarh","state_id":"33"},
    {"city_id":"3476","city_name":"Pushkar","state_id":"33"},
    {"city_id":"3477","city_name":"Raipur","state_id":"33"},
    {"city_id":"3478","city_name":"Raisinghnagar","state_id":"33"},
    {"city_id":"3479","city_name":"Rajakhera","state_id":"33"},
    {"city_id":"3480","city_name":"Rajaldesar","state_id":"33"},
    {"city_id":"3481","city_name":"Rajgarh","state_id":"33"},
    {"city_id":"3482","city_name":"Rajsamand","state_id":"33"},
    {"city_id":"3483","city_name":"Ramganj Mandi","state_id":"33"},
    {"city_id":"3484","city_name":"Ramgarh","state_id":"33"},
    {"city_id":"3485","city_name":"Rani","state_id":"33"},
    {"city_id":"3486","city_name":"Raniwara","state_id":"33"},
    {"city_id":"3487","city_name":"Ratan Nagar","state_id":"33"},
    {"city_id":"3488","city_name":"Ratangarh","state_id":"33"},
    {"city_id":"3489","city_name":"Rawatbhata","state_id":"33"},
    {"city_id":"3490","city_name":"Rawatsar","state_id":"33"},
    {"city_id":"3491","city_name":"Rikhabdev","state_id":"33"},
    {"city_id":"3492","city_name":"Ringas","state_id":"33"},
    {"city_id":"3493","city_name":"Sadri","state_id":"33"},
    {"city_id":"3494","city_name":"Sadulshahar","state_id":"33"},
    {"city_id":"3495","city_name":"Sagwara","state_id":"33"},
    {"city_id":"3496","city_name":"Salumbar","state_id":"33"},
    {"city_id":"3497","city_name":"Sambhar","state_id":"33"},
    {"city_id":"3498","city_name":"Samdari","state_id":"33"},
    {"city_id":"3499","city_name":"Sanchor","state_id":"33"},
    {"city_id":"3500","city_name":"Sangariya","state_id":"33"},
    {"city_id":"3501","city_name":"Sangod","state_id":"33"},
    {"city_id":"3502","city_name":"Sardarshahr","state_id":"33"},
    {"city_id":"3503","city_name":"Sarwar","state_id":"33"},
    {"city_id":"3504","city_name":"Satal Kheri","state_id":"33"},
    {"city_id":"3505","city_name":"Sawai Madhopur","state_id":"33"},
    {"city_id":"3506","city_name":"Sewan Kalan","state_id":"33"},
    {"city_id":"3507","city_name":"Shahpura","state_id":"33"},
    {"city_id":"3508","city_name":"Sheoganj","state_id":"33"},
    {"city_id":"3509","city_name":"Sikar","state_id":"33"},
    {"city_id":"3510","city_name":"Sirohi","state_id":"33"},
    {"city_id":"3511","city_name":"Siwana","state_id":"33"},
    {"city_id":"3512","city_name":"Sogariya","state_id":"33"},
    {"city_id":"3513","city_name":"Sojat","state_id":"33"},
    {"city_id":"3514","city_name":"Sojat Road","state_id":"33"},
    {"city_id":"3515","city_name":"Sri Madhopur","state_id":"33"},
    {"city_id":"3516","city_name":"Sriganganagar","state_id":"33"},
    {"city_id":"3517","city_name":"Sujangarh","state_id":"33"},
    {"city_id":"3518","city_name":"Suket","state_id":"33"},
    {"city_id":"3519","city_name":"Sumerpur","state_id":"33"},
    {"city_id":"3520","city_name":"Sunel","state_id":"33"},
    {"city_id":"3521","city_name":"Surajgarh","state_id":"33"},
    {"city_id":"3522","city_name":"Suratgarh","state_id":"33"},
    {"city_id":"3523","city_name":"Swaroopganj","state_id":"33"},
    {"city_id":"3524","city_name":"Takhatgarh","state_id":"33"},
    {"city_id":"3525","city_name":"Taranagar","state_id":"33"},
    {"city_id":"3526","city_name":"Three STR","state_id":"33"},
    {"city_id":"3527","city_name":"Tijara","state_id":"33"},
    {"city_id":"3528","city_name":"Toda Bhim","state_id":"33"},
    {"city_id":"3529","city_name":"Toda Raisingh","state_id":"33"},
    {"city_id":"3530","city_name":"Todra","state_id":"33"},
    {"city_id":"3531","city_name":"Tonk","state_id":"33"},
    {"city_id":"3532","city_name":"Udaipur","state_id":"33"},
    {"city_id":"3533","city_name":"Udpura","state_id":"33"},
    {"city_id":"3534","city_name":"Uniara","state_id":"33"},
    {"city_id":"3535","city_name":"Vanasthali","state_id":"33"},
    {"city_id":"3536","city_name":"Vidyavihar","state_id":"33"},
    {"city_id":"3537","city_name":"Vijainagar","state_id":"33"},
    {"city_id":"3538","city_name":"Viratnagar","state_id":"33"},
    {"city_id":"3539","city_name":"Wer","state_id":"33"},
    {"city_id":"3540","city_name":"Gangtok","state_id":"34"},
    {"city_id":"3541","city_name":"Gezing","state_id":"34"},
    {"city_id":"3542","city_name":"Jorethang","state_id":"34"},
    {"city_id":"3543","city_name":"Mangan","state_id":"34"},
    {"city_id":"3544","city_name":"Namchi","state_id":"34"},
    {"city_id":"3545","city_name":"Naya Bazar","state_id":"34"},
    {"city_id":"3546","city_name":"No City","state_id":"34"},
    {"city_id":"3547","city_name":"Rangpo","state_id":"34"},
    {"city_id":"3548","city_name":"Sikkim","state_id":"34"},
    {"city_id":"3549","city_name":"Singtam","state_id":"34"},
    {"city_id":"3550","city_name":"Upper Tadong","state_id":"34"},
    {"city_id":"3551","city_name":"Abiramam","state_id":"35"},
    {"city_id":"3552","city_name":"Achampudur","state_id":"35"},
    {"city_id":"3553","city_name":"Acharapakkam","state_id":"35"},
    {"city_id":"3554","city_name":"Acharipallam","state_id":"35"},
    {"city_id":"3555","city_name":"Achipatti","state_id":"35"},
    {"city_id":"3556","city_name":"Adikaratti","state_id":"35"},
    {"city_id":"3557","city_name":"Adiramapattinam","state_id":"35"},
    {"city_id":"3558","city_name":"Aduturai","state_id":"35"},
    {"city_id":"3559","city_name":"Adyar","state_id":"35"},
    {"city_id":"3560","city_name":"Agaram","state_id":"35"},
    {"city_id":"3561","city_name":"Agasthiswaram","state_id":"35"},
    {"city_id":"3562","city_name":"Akkaraipettai","state_id":"35"},
    {"city_id":"3563","city_name":"Alagappapuram","state_id":"35"},
    {"city_id":"3564","city_name":"Alagapuri","state_id":"35"},
    {"city_id":"3565","city_name":"Alampalayam","state_id":"35"},
    {"city_id":"3566","city_name":"Alandur","state_id":"35"},
    {"city_id":"3567","city_name":"Alanganallur","state_id":"35"},
    {"city_id":"3568","city_name":"Alangayam","state_id":"35"},
    {"city_id":"3569","city_name":"Alangudi","state_id":"35"},
    {"city_id":"3570","city_name":"Alangulam","state_id":"35"},
    {"city_id":"3571","city_name":"Alanthurai","state_id":"35"},
    {"city_id":"3572","city_name":"Alapakkam","state_id":"35"},
    {"city_id":"3573","city_name":"Allapuram","state_id":"35"},
    {"city_id":"3574","city_name":"Alur","state_id":"35"},
    {"city_id":"3575","city_name":"Alwar Tirunagari","state_id":"35"},
    {"city_id":"3576","city_name":"Alwarkurichi","state_id":"35"},
    {"city_id":"3577","city_name":"Ambasamudram","state_id":"35"},
    {"city_id":"3578","city_name":"Ambur","state_id":"35"},
    {"city_id":"3579","city_name":"Ammainaickanur","state_id":"35"},
    {"city_id":"3580","city_name":"Ammaparikuppam","state_id":"35"},
    {"city_id":"3581","city_name":"Ammapettai","state_id":"35"},
    {"city_id":"3582","city_name":"Ammavarikuppam","state_id":"35"},
    {"city_id":"3583","city_name":"Ammur","state_id":"35"},
    {"city_id":"3584","city_name":"Anaimalai","state_id":"35"},
    {"city_id":"3585","city_name":"Anaiyur","state_id":"35"},
    {"city_id":"3586","city_name":"Anakaputhur","state_id":"35"},
    {"city_id":"3587","city_name":"Ananthapuram","state_id":"35"},
    {"city_id":"3588","city_name":"Andanappettai","state_id":"35"},
    {"city_id":"3589","city_name":"Andipalayam","state_id":"35"},
    {"city_id":"3590","city_name":"Andippatti","state_id":"35"},
    {"city_id":"3591","city_name":"Anjugramam","state_id":"35"},
    {"city_id":"3592","city_name":"Annamalainagar","state_id":"35"},
    {"city_id":"3593","city_name":"Annavasal","state_id":"35"},
    {"city_id":"3594","city_name":"Annur","state_id":"35"},
    {"city_id":"3595","city_name":"Anthiyur","state_id":"35"},
    {"city_id":"3596","city_name":"Appakudal","state_id":"35"},
    {"city_id":"3597","city_name":"Arachalur","state_id":"35"},
    {"city_id":"3598","city_name":"Arakandanallur","state_id":"35"},
    {"city_id":"3599","city_name":"Arakonam","state_id":"35"},
    {"city_id":"3600","city_name":"Aralvaimozhi","state_id":"35"},
    {"city_id":"3601","city_name":"Arani","state_id":"35"},
    {"city_id":"3602","city_name":"Arani Road","state_id":"35"},
    {"city_id":"3603","city_name":"Arantangi","state_id":"35"},
    {"city_id":"3604","city_name":"Arasiramani","state_id":"35"},
    {"city_id":"3605","city_name":"Aravakurichi","state_id":"35"},
    {"city_id":"3606","city_name":"Aravankadu","state_id":"35"},
    {"city_id":"3607","city_name":"Arcot","state_id":"35"},
    {"city_id":"3608","city_name":"Arimalam","state_id":"35"},
    {"city_id":"3609","city_name":"Ariyalur","state_id":"35"},
    {"city_id":"3610","city_name":"Ariyappampalayam","state_id":"35"},
    {"city_id":"3611","city_name":"Ariyur","state_id":"35"},
    {"city_id":"3612","city_name":"Arni","state_id":"35"},
    {"city_id":"3613","city_name":"Arulmigu Thirumuruganpundi","state_id":"35"},
    {"city_id":"3614","city_name":"Arumanai","state_id":"35"},
    {"city_id":"3615","city_name":"Arumbavur","state_id":"35"},
    {"city_id":"3616","city_name":"Arumuganeri","state_id":"35"},
    {"city_id":"3617","city_name":"Aruppukkottai","state_id":"35"},
    {"city_id":"3618","city_name":"Ashokapuram","state_id":"35"},
    {"city_id":"3619","city_name":"Athani","state_id":"35"},
    {"city_id":"3620","city_name":"Athanur","state_id":"35"},
    {"city_id":"3621","city_name":"Athimarapatti","state_id":"35"},
    {"city_id":"3622","city_name":"Athipattu","state_id":"35"},
    {"city_id":"3623","city_name":"Athur","state_id":"35"},
    {"city_id":"3624","city_name":"Attayyampatti","state_id":"35"},
    {"city_id":"3625","city_name":"Attur","state_id":"35"},
    {"city_id":"3626","city_name":"Auroville","state_id":"35"},
    {"city_id":"3627","city_name":"Avadattur","state_id":"35"},
    {"city_id":"3628","city_name":"Avadi","state_id":"35"},
    {"city_id":"3629","city_name":"Avalpundurai","state_id":"35"},
    {"city_id":"3630","city_name":"Avaniapuram","state_id":"35"},
    {"city_id":"3631","city_name":"Avinashi","state_id":"35"},
    {"city_id":"3632","city_name":"Ayakudi","state_id":"35"},
    {"city_id":"3633","city_name":"Ayanadaippu","state_id":"35"},
    {"city_id":"3634","city_name":"Aygudi","state_id":"35"},
    {"city_id":"3635","city_name":"Ayothiapattinam","state_id":"35"},
    {"city_id":"3636","city_name":"Ayyalur","state_id":"35"},
    {"city_id":"3637","city_name":"Ayyampalayam","state_id":"35"},
    {"city_id":"3638","city_name":"Ayyampettai","state_id":"35"},
    {"city_id":"3639","city_name":"Azhagiapandiapuram","state_id":"35"},
    {"city_id":"3640","city_name":"Balakrishnampatti","state_id":"35"},
    {"city_id":"3641","city_name":"Balakrishnapuram","state_id":"35"},
    {"city_id":"3642","city_name":"Balapallam","state_id":"35"},
    {"city_id":"3643","city_name":"Balasamudram","state_id":"35"},
    {"city_id":"3644","city_name":"Bargur","state_id":"35"},
    {"city_id":"3645","city_name":"Belur","state_id":"35"},
    {"city_id":"3646","city_name":"Berhatty","state_id":"35"},
    {"city_id":"3647","city_name":"Bhavani","state_id":"35"},
    {"city_id":"3648","city_name":"Bhawanisagar","state_id":"35"},
    {"city_id":"3649","city_name":"Bhuvanagiri","state_id":"35"},
    {"city_id":"3650","city_name":"Bikketti","state_id":"35"},
    {"city_id":"3651","city_name":"Bodinayakkanur","state_id":"35"},
    {"city_id":"3652","city_name":"Brahmana Periya Agraharam","state_id":"35"},
    {"city_id":"3653","city_name":"Buthapandi","state_id":"35"},
    {"city_id":"3654","city_name":"Buthipuram","state_id":"35"},
    {"city_id":"3655","city_name":"Chatrapatti","state_id":"35"},
    {"city_id":"3656","city_name":"Chembarambakkam","state_id":"35"},
    {"city_id":"3657","city_name":"Chengalpattu","state_id":"35"},
    {"city_id":"3658","city_name":"Chengam","state_id":"35"},
    {"city_id":"3659","city_name":"Chennai","state_id":"35"},
    {"city_id":"3660","city_name":"Chennasamudram","state_id":"35"},
    {"city_id":"3661","city_name":"Chennimalai","state_id":"35"},
    {"city_id":"3662","city_name":"Cheranmadevi","state_id":"35"},
    {"city_id":"3663","city_name":"Cheruvanki","state_id":"35"},
    {"city_id":"3664","city_name":"Chetpet","state_id":"35"},
    {"city_id":"3665","city_name":"Chettiarpatti","state_id":"35"},
    {"city_id":"3666","city_name":"Chettipalaiyam","state_id":"35"},
    {"city_id":"3667","city_name":"Chettipalayam Cantonment","state_id":"35"},
    {"city_id":"3668","city_name":"Chettithangal","state_id":"35"},
    {"city_id":"3669","city_name":"Cheyur","state_id":"35"},
    {"city_id":"3670","city_name":"Cheyyar","state_id":"35"},
    {"city_id":"3671","city_name":"Chidambaram","state_id":"35"},
    {"city_id":"3672","city_name":"Chinalapatti","state_id":"35"},
    {"city_id":"3673","city_name":"Chinna Anuppanadi","state_id":"35"},
    {"city_id":"3674","city_name":"Chinna Salem","state_id":"35"},
    {"city_id":"3675","city_name":"Chinnakkampalayam","state_id":"35"},
    {"city_id":"3676","city_name":"Chinnammanur","state_id":"35"},
    {"city_id":"3677","city_name":"Chinnampalaiyam","state_id":"35"},
    {"city_id":"3678","city_name":"Chinnasekkadu","state_id":"35"},
    {"city_id":"3679","city_name":"Chinnavedampatti","state_id":"35"},
    {"city_id":"3680","city_name":"Chitlapakkam","state_id":"35"},
    {"city_id":"3681","city_name":"Chittodu","state_id":"35"},
    {"city_id":"3682","city_name":"Cholapuram","state_id":"35"},
    {"city_id":"3683","city_name":"Coimbatore","state_id":"35"},
    {"city_id":"3684","city_name":"Coonoor","state_id":"35"},
    {"city_id":"3685","city_name":"Courtalam","state_id":"35"},
    {"city_id":"3686","city_name":"Cuddalore","state_id":"35"},
    {"city_id":"3687","city_name":"Dalavaipatti","state_id":"35"},
    {"city_id":"3688","city_name":"Darasuram","state_id":"35"},
    {"city_id":"3689","city_name":"Denkanikottai","state_id":"35"},
    {"city_id":"3690","city_name":"Desur","state_id":"35"},
    {"city_id":"3691","city_name":"Devadanapatti","state_id":"35"},
    {"city_id":"3692","city_name":"Devakkottai","state_id":"35"},
    {"city_id":"3693","city_name":"Devakottai","state_id":"35"},
    {"city_id":"3694","city_name":"Devanangurichi","state_id":"35"},
    {"city_id":"3695","city_name":"Devarshola","state_id":"35"},
    {"city_id":"3696","city_name":"Devasthanam","state_id":"35"},
    {"city_id":"3697","city_name":"Dhalavoipuram","state_id":"35"},
    {"city_id":"3698","city_name":"Dhali","state_id":"35"},
    {"city_id":"3699","city_name":"Dhaliyur","state_id":"35"},
    {"city_id":"3700","city_name":"Dharapadavedu","state_id":"35"},
    {"city_id":"3701","city_name":"Dharapuram","state_id":"35"},
    {"city_id":"3702","city_name":"Dharmapuri","state_id":"35"},
    {"city_id":"3703","city_name":"Dindigul","state_id":"35"},
    {"city_id":"3704","city_name":"Dusi","state_id":"35"},
    {"city_id":"3705","city_name":"Edaganasalai","state_id":"35"},
    {"city_id":"3706","city_name":"Edaikodu","state_id":"35"},
    {"city_id":"3707","city_name":"Edakalinadu","state_id":"35"},
    {"city_id":"3708","city_name":"Elathur","state_id":"35"},
    {"city_id":"3709","city_name":"Elayirampannai","state_id":"35"},
    {"city_id":"3710","city_name":"Elumalai","state_id":"35"},
    {"city_id":"3711","city_name":"Eral","state_id":"35"},
    {"city_id":"3712","city_name":"Eraniel","state_id":"35"},
    {"city_id":"3713","city_name":"Eriodu","state_id":"35"},
    {"city_id":"3714","city_name":"Erode","state_id":"35"},
    {"city_id":"3715","city_name":"Erumaipatti","state_id":"35"},
    {"city_id":"3716","city_name":"Eruvadi","state_id":"35"},
    {"city_id":"3717","city_name":"Ethapur","state_id":"35"},
    {"city_id":"3718","city_name":"Ettaiyapuram","state_id":"35"},
    {"city_id":"3719","city_name":"Ettimadai","state_id":"35"},
    {"city_id":"3720","city_name":"Ezhudesam","state_id":"35"},
    {"city_id":"3721","city_name":"Ganapathipuram","state_id":"35"},
    {"city_id":"3722","city_name":"Gandhi Nagar","state_id":"35"},
    {"city_id":"3723","city_name":"Gangaikondan","state_id":"35"},
    {"city_id":"3724","city_name":"Gangavalli","state_id":"35"},
    {"city_id":"3725","city_name":"Ganguvarpatti","state_id":"35"},
    {"city_id":"3726","city_name":"Gingi","state_id":"35"},
    {"city_id":"3727","city_name":"Gopalasamudram","state_id":"35"},
    {"city_id":"3728","city_name":"Gopichettipalaiyam","state_id":"35"},
    {"city_id":"3729","city_name":"Gudalur","state_id":"35"},
    {"city_id":"3730","city_name":"Gudiyattam","state_id":"35"},
    {"city_id":"3731","city_name":"Guduvanchery","state_id":"35"},
    {"city_id":"3732","city_name":"Gummidipoondi","state_id":"35"},
    {"city_id":"3733","city_name":"Hanumanthampatti","state_id":"35"},
    {"city_id":"3734","city_name":"Harur","state_id":"35"},
    {"city_id":"3735","city_name":"Harveypatti","state_id":"35"},
    {"city_id":"3736","city_name":"Highways","state_id":"35"},
    {"city_id":"3737","city_name":"Hosur","state_id":"35"},
    {"city_id":"3738","city_name":"Hubbathala","state_id":"35"},
    {"city_id":"3739","city_name":"Huligal","state_id":"35"},
    {"city_id":"3740","city_name":"Idappadi","state_id":"35"},
    {"city_id":"3741","city_name":"Idikarai","state_id":"35"},
    {"city_id":"3742","city_name":"Ilampillai","state_id":"35"},
    {"city_id":"3743","city_name":"Ilanji","state_id":"35"},
    {"city_id":"3744","city_name":"Iluppaiyurani","state_id":"35"},
    {"city_id":"3745","city_name":"Iluppur","state_id":"35"},
    {"city_id":"3746","city_name":"Inam Karur","state_id":"35"},
    {"city_id":"3747","city_name":"Injambakkam","state_id":"35"},
    {"city_id":"3748","city_name":"Irugur","state_id":"35"},
    {"city_id":"3749","city_name":"Jaffrabad","state_id":"35"},
    {"city_id":"3750","city_name":"Jagathala","state_id":"35"},
    {"city_id":"3751","city_name":"Jalakandapuram","state_id":"35"},
    {"city_id":"3752","city_name":"Jalladiampet","state_id":"35"},
    {"city_id":"3753","city_name":"Jambai","state_id":"35"},
    {"city_id":"3754","city_name":"Jayankondam","state_id":"35"},
    {"city_id":"3755","city_name":"Jolarpet","state_id":"35"},
    {"city_id":"3756","city_name":"Kadambur","state_id":"35"},
    {"city_id":"3757","city_name":"Kadathur","state_id":"35"},
    {"city_id":"3758","city_name":"Kadayal","state_id":"35"},
    {"city_id":"3759","city_name":"Kadayampatti","state_id":"35"},
    {"city_id":"3760","city_name":"Kadayanallur","state_id":"35"},
    {"city_id":"3761","city_name":"Kadiapatti","state_id":"35"},
    {"city_id":"3762","city_name":"Kalakkad","state_id":"35"},
    {"city_id":"3763","city_name":"Kalambur","state_id":"35"},
    {"city_id":"3764","city_name":"Kalapatti","state_id":"35"},
    {"city_id":"3765","city_name":"Kalappanaickenpatti","state_id":"35"},
    {"city_id":"3766","city_name":"Kalavai","state_id":"35"},
    {"city_id":"3767","city_name":"Kalinjur","state_id":"35"},
    {"city_id":"3768","city_name":"Kaliyakkavilai","state_id":"35"},
    {"city_id":"3769","city_name":"Kallakkurichi","state_id":"35"},
    {"city_id":"3770","city_name":"Kallakudi","state_id":"35"},
    {"city_id":"3771","city_name":"Kallidaikurichchi","state_id":"35"},
    {"city_id":"3772","city_name":"Kallukuttam","state_id":"35"},
    {"city_id":"3773","city_name":"Kallupatti","state_id":"35"},
    {"city_id":"3774","city_name":"Kalpakkam","state_id":"35"},
    {"city_id":"3775","city_name":"Kalugumalai","state_id":"35"},
    {"city_id":"3776","city_name":"Kamayagoundanpatti","state_id":"35"},
    {"city_id":"3777","city_name":"Kambainallur","state_id":"35"},
    {"city_id":"3778","city_name":"Kambam","state_id":"35"},
    {"city_id":"3779","city_name":"Kamuthi","state_id":"35"},
    {"city_id":"3780","city_name":"Kanadukathan","state_id":"35"},
    {"city_id":"3781","city_name":"Kanakkampalayam","state_id":"35"},
    {"city_id":"3782","city_name":"Kanam","state_id":"35"},
    {"city_id":"3783","city_name":"Kanchipuram","state_id":"35"},
    {"city_id":"3784","city_name":"Kandanur","state_id":"35"},
    {"city_id":"3785","city_name":"Kangayam","state_id":"35"},
    {"city_id":"3786","city_name":"Kangayampalayam","state_id":"35"},
    {"city_id":"3787","city_name":"Kangeyanallur","state_id":"35"},
    {"city_id":"3788","city_name":"Kaniyur","state_id":"35"},
    {"city_id":"3789","city_name":"Kanjikoil","state_id":"35"},
    {"city_id":"3790","city_name":"Kannadendal","state_id":"35"},
    {"city_id":"3791","city_name":"Kannamangalam","state_id":"35"},
    {"city_id":"3792","city_name":"Kannampalayam","state_id":"35"},
    {"city_id":"3793","city_name":"Kannankurichi","state_id":"35"},
    {"city_id":"3794","city_name":"Kannapalaiyam","state_id":"35"},
    {"city_id":"3795","city_name":"Kannivadi","state_id":"35"},
    {"city_id":"3796","city_name":"Kanyakumari","state_id":"35"},
    {"city_id":"3797","city_name":"Kappiyarai","state_id":"35"},
    {"city_id":"3798","city_name":"Karaikkudi","state_id":"35"},
    {"city_id":"3799","city_name":"Karamadai","state_id":"35"},
    {"city_id":"3800","city_name":"Karambakkam","state_id":"35"},
    {"city_id":"3801","city_name":"Karambakkudi","state_id":"35"},
    {"city_id":"3802","city_name":"Kariamangalam","state_id":"35"},
    {"city_id":"3803","city_name":"Kariapatti","state_id":"35"},
    {"city_id":"3804","city_name":"Karugampattur","state_id":"35"},
    {"city_id":"3805","city_name":"Karumandi Chellipalayam","state_id":"35"},
    {"city_id":"3806","city_name":"Karumathampatti","state_id":"35"},
    {"city_id":"3807","city_name":"Karumbakkam","state_id":"35"},
    {"city_id":"3808","city_name":"Karungal","state_id":"35"},
    {"city_id":"3809","city_name":"Karunguzhi","state_id":"35"},
    {"city_id":"3810","city_name":"Karuppur","state_id":"35"},
    {"city_id":"3811","city_name":"Karur","state_id":"35"},
    {"city_id":"3812","city_name":"Kasipalaiyam","state_id":"35"},
    {"city_id":"3813","city_name":"Kasipalayam G","state_id":"35"},
    {"city_id":"3814","city_name":"Kathirvedu","state_id":"35"},
    {"city_id":"3815","city_name":"Kathujuganapalli","state_id":"35"},
    {"city_id":"3816","city_name":"Katpadi","state_id":"35"},
    {"city_id":"3817","city_name":"Kattivakkam","state_id":"35"},
    {"city_id":"3818","city_name":"Kattumannarkoil","state_id":"35"},
    {"city_id":"3819","city_name":"Kattupakkam","state_id":"35"},
    {"city_id":"3820","city_name":"Kattuputhur","state_id":"35"},
    {"city_id":"3821","city_name":"Kaveripakkam","state_id":"35"},
    {"city_id":"3822","city_name":"Kaveripattinam","state_id":"35"},
    {"city_id":"3823","city_name":"Kavundampalaiyam","state_id":"35"},
    {"city_id":"3824","city_name":"Kavundampalayam","state_id":"35"},
    {"city_id":"3825","city_name":"Kayalpattinam","state_id":"35"},
    {"city_id":"3826","city_name":"Kayattar","state_id":"35"},
    {"city_id":"3827","city_name":"Kelamangalam","state_id":"35"},
    {"city_id":"3828","city_name":"Kelambakkam","state_id":"35"},
    {"city_id":"3829","city_name":"Kembainaickenpalayam","state_id":"35"},
    {"city_id":"3830","city_name":"Kethi","state_id":"35"},
    {"city_id":"3831","city_name":"Kilakarai","state_id":"35"},
    {"city_id":"3832","city_name":"Kilampadi","state_id":"35"},
    {"city_id":"3833","city_name":"Kilkulam","state_id":"35"},
    {"city_id":"3834","city_name":"Kilkunda","state_id":"35"},
    {"city_id":"3835","city_name":"Killiyur","state_id":"35"},
    {"city_id":"3836","city_name":"Killlai","state_id":"35"},
    {"city_id":"3837","city_name":"Kilpennathur","state_id":"35"},
    {"city_id":"3838","city_name":"Kilvelur","state_id":"35"},
    {"city_id":"3839","city_name":"Kinathukadavu","state_id":"35"},
    {"city_id":"3840","city_name":"Kiramangalam","state_id":"35"},
    {"city_id":"3841","city_name":"Kiranur","state_id":"35"},
    {"city_id":"3842","city_name":"Kiripatti","state_id":"35"},
    {"city_id":"3843","city_name":"Kizhapavur","state_id":"35"},
    {"city_id":"3844","city_name":"Kmarasamipatti","state_id":"35"},
    {"city_id":"3845","city_name":"Kochadai","state_id":"35"},
    {"city_id":"3846","city_name":"Kodaikanal","state_id":"35"},
    {"city_id":"3847","city_name":"Kodambakkam","state_id":"35"},
    {"city_id":"3848","city_name":"Kodavasal","state_id":"35"},
    {"city_id":"3849","city_name":"Kodumudi","state_id":"35"},
    {"city_id":"3850","city_name":"Kolachal","state_id":"35"},
    {"city_id":"3851","city_name":"Kolappalur","state_id":"35"},
    {"city_id":"3852","city_name":"Kolathupalayam","state_id":"35"},
    {"city_id":"3853","city_name":"Kolathur","state_id":"35"},
    {"city_id":"3854","city_name":"Kollankodu","state_id":"35"},
    {"city_id":"3855","city_name":"Kollankoil","state_id":"35"},
    {"city_id":"3856","city_name":"Komaralingam","state_id":"35"},
    {"city_id":"3857","city_name":"Komarapalayam","state_id":"35"},
    {"city_id":"3858","city_name":"Kombai","state_id":"35"},
    {"city_id":"3859","city_name":"Konakkarai","state_id":"35"},
    {"city_id":"3860","city_name":"Konavattam","state_id":"35"},
    {"city_id":"3861","city_name":"Kondalampatti","state_id":"35"},
    {"city_id":"3862","city_name":"Konganapuram","state_id":"35"},
    {"city_id":"3863","city_name":"Koradacheri","state_id":"35"},
    {"city_id":"3864","city_name":"Korampallam","state_id":"35"},
    {"city_id":"3865","city_name":"Kotagiri","state_id":"35"},
    {"city_id":"3866","city_name":"Kothinallur","state_id":"35"},
    {"city_id":"3867","city_name":"Kottaiyur","state_id":"35"},
    {"city_id":"3868","city_name":"Kottakuppam","state_id":"35"},
    {"city_id":"3869","city_name":"Kottaram","state_id":"35"},
    {"city_id":"3870","city_name":"Kottivakkam","state_id":"35"},
    {"city_id":"3871","city_name":"Kottur","state_id":"35"},
    {"city_id":"3872","city_name":"Kovilpatti","state_id":"35"},
    {"city_id":"3873","city_name":"Koyampattur","state_id":"35"},
    {"city_id":"3874","city_name":"Krishnagiri","state_id":"35"},
    {"city_id":"3875","city_name":"Krishnarayapuram","state_id":"35"},
    {"city_id":"3876","city_name":"Krishnasamudram","state_id":"35"},
    {"city_id":"3877","city_name":"Kuchanur","state_id":"35"},
    {"city_id":"3878","city_name":"Kuhalur","state_id":"35"},
    {"city_id":"3879","city_name":"Kulasekarappattinam","state_id":"35"},
    {"city_id":"3880","city_name":"Kulasekarapuram","state_id":"35"},
    {"city_id":"3881","city_name":"Kulithalai","state_id":"35"},
    {"city_id":"3882","city_name":"Kumarapalaiyam","state_id":"35"},
    {"city_id":"3883","city_name":"Kumarapalayam","state_id":"35"},
    {"city_id":"3884","city_name":"Kumarapuram","state_id":"35"},
    {"city_id":"3885","city_name":"Kumbakonam","state_id":"35"},
    {"city_id":"3886","city_name":"Kundrathur","state_id":"35"},
    {"city_id":"3887","city_name":"Kuniyamuthur","state_id":"35"},
    {"city_id":"3888","city_name":"Kunnathur","state_id":"35"},
    {"city_id":"3889","city_name":"Kunur","state_id":"35"},
    {"city_id":"3890","city_name":"Kuraikundu","state_id":"35"},
    {"city_id":"3891","city_name":"Kurichi","state_id":"35"},
    {"city_id":"3892","city_name":"Kurinjippadi","state_id":"35"},
    {"city_id":"3893","city_name":"Kurudampalaiyam","state_id":"35"},
    {"city_id":"3894","city_name":"Kurumbalur","state_id":"35"},
    {"city_id":"3895","city_name":"Kuthalam","state_id":"35"},
    {"city_id":"3896","city_name":"Kuthappar","state_id":"35"},
    {"city_id":"3897","city_name":"Kuttalam","state_id":"35"},
    {"city_id":"3898","city_name":"Kuttanallur","state_id":"35"},
    {"city_id":"3899","city_name":"Kuzhithurai","state_id":"35"},
    {"city_id":"3900","city_name":"Labbaikudikadu","state_id":"35"},
    {"city_id":"3901","city_name":"Lakkampatti","state_id":"35"},
    {"city_id":"3902","city_name":"Lalgudi","state_id":"35"},
    {"city_id":"3903","city_name":"Lalpet","state_id":"35"},
    {"city_id":"3904","city_name":"Llayangudi","state_id":"35"},
    {"city_id":"3905","city_name":"Madambakkam","state_id":"35"},
    {"city_id":"3906","city_name":"Madanur","state_id":"35"},
    {"city_id":"3907","city_name":"Madathukulam","state_id":"35"},
    {"city_id":"3908","city_name":"Madhavaram","state_id":"35"},
    {"city_id":"3909","city_name":"Madippakkam","state_id":"35"},
    {"city_id":"3910","city_name":"Madukkarai","state_id":"35"},
    {"city_id":"3911","city_name":"Madukkur","state_id":"35"},
    {"city_id":"3912","city_name":"Madurai","state_id":"35"},
    {"city_id":"3913","city_name":"Maduranthakam","state_id":"35"},
    {"city_id":"3914","city_name":"Maduravoyal","state_id":"35"},
    {"city_id":"3915","city_name":"Mahabalipuram","state_id":"35"},
    {"city_id":"3916","city_name":"Makkinanpatti","state_id":"35"},
    {"city_id":"3917","city_name":"Mallamuppampatti","state_id":"35"},
    {"city_id":"3918","city_name":"Mallankinaru","state_id":"35"},
    {"city_id":"3919","city_name":"Mallapuram","state_id":"35"},
    {"city_id":"3920","city_name":"Mallasamudram","state_id":"35"},
    {"city_id":"3921","city_name":"Mallur","state_id":"35"},
    {"city_id":"3922","city_name":"Mamallapuram","state_id":"35"},
    {"city_id":"3923","city_name":"Mamsapuram","state_id":"35"},
    {"city_id":"3924","city_name":"Manachanallur","state_id":"35"},
    {"city_id":"3925","city_name":"Manali","state_id":"35"},
    {"city_id":"3926","city_name":"Manalmedu","state_id":"35"},
    {"city_id":"3927","city_name":"Manalurpet","state_id":"35"},
    {"city_id":"3928","city_name":"Manamadurai","state_id":"35"},
    {"city_id":"3929","city_name":"Manapakkam","state_id":"35"},
    {"city_id":"3930","city_name":"Manapparai","state_id":"35"},
    {"city_id":"3931","city_name":"Manavalakurichi","state_id":"35"},
    {"city_id":"3932","city_name":"Mandaikadu","state_id":"35"},
    {"city_id":"3933","city_name":"Mandapam","state_id":"35"},
    {"city_id":"3934","city_name":"Mangadu","state_id":"35"},
    {"city_id":"3935","city_name":"Mangalam","state_id":"35"},
    {"city_id":"3936","city_name":"Mangalampet","state_id":"35"},
    {"city_id":"3937","city_name":"Manimutharu","state_id":"35"},
    {"city_id":"3938","city_name":"Mannargudi","state_id":"35"},
    {"city_id":"3939","city_name":"Mappilaiurani","state_id":"35"},
    {"city_id":"3940","city_name":"Maraimalai Nagar","state_id":"35"},
    {"city_id":"3941","city_name":"Marakkanam","state_id":"35"},
    {"city_id":"3942","city_name":"Maramangalathupatti","state_id":"35"},
    {"city_id":"3943","city_name":"Marandahalli","state_id":"35"},
    {"city_id":"3944","city_name":"Markayankottai","state_id":"35"},
    {"city_id":"3945","city_name":"Marudur","state_id":"35"},
    {"city_id":"3946","city_name":"Marungur","state_id":"35"},
    {"city_id":"3947","city_name":"Masinigudi","state_id":"35"},
    {"city_id":"3948","city_name":"Mathigiri","state_id":"35"},
    {"city_id":"3949","city_name":"Mattur","state_id":"35"},
    {"city_id":"3950","city_name":"Mayiladuthurai","state_id":"35"},
    {"city_id":"3951","city_name":"Mecheri","state_id":"35"},
    {"city_id":"3952","city_name":"Melacheval","state_id":"35"},
    {"city_id":"3953","city_name":"Melachokkanathapuram","state_id":"35"},
    {"city_id":"3954","city_name":"Melagaram","state_id":"35"},
    {"city_id":"3955","city_name":"Melamadai","state_id":"35"},
    {"city_id":"3956","city_name":"Melamaiyur","state_id":"35"},
    {"city_id":"3957","city_name":"Melanattam","state_id":"35"},
    {"city_id":"3958","city_name":"Melathiruppanthuruthi","state_id":"35"},
    {"city_id":"3959","city_name":"Melattur","state_id":"35"},
    {"city_id":"3960","city_name":"Melmananbedu","state_id":"35"},
    {"city_id":"3961","city_name":"Melpattampakkam","state_id":"35"},
    {"city_id":"3962","city_name":"Melur","state_id":"35"},
    {"city_id":"3963","city_name":"Melvisharam","state_id":"35"},
    {"city_id":"3964","city_name":"Mettupalayam","state_id":"35"},
    {"city_id":"3965","city_name":"Mettur","state_id":"35"},
    {"city_id":"3966","city_name":"Meyyanur","state_id":"35"},
    {"city_id":"3967","city_name":"Milavittan","state_id":"35"},
    {"city_id":"3968","city_name":"Minakshipuram","state_id":"35"},
    {"city_id":"3969","city_name":"Minambakkam","state_id":"35"},
    {"city_id":"3970","city_name":"Minjur","state_id":"35"},
    {"city_id":"3971","city_name":"Modakurichi","state_id":"35"},
    {"city_id":"3972","city_name":"Mohanur","state_id":"35"},
    {"city_id":"3973","city_name":"Mopperipalayam","state_id":"35"},
    {"city_id":"3974","city_name":"Mudalur","state_id":"35"},
    {"city_id":"3975","city_name":"Mudichur","state_id":"35"},
    {"city_id":"3976","city_name":"Mudukulathur","state_id":"35"},
    {"city_id":"3977","city_name":"Mukasipidariyur","state_id":"35"},
    {"city_id":"3978","city_name":"Mukkudal","state_id":"35"},
    {"city_id":"3979","city_name":"Mulagumudu","state_id":"35"},
    {"city_id":"3980","city_name":"Mulakaraipatti","state_id":"35"},
    {"city_id":"3981","city_name":"Mulanur","state_id":"35"},
    {"city_id":"3982","city_name":"Mullakkadu","state_id":"35"},
    {"city_id":"3983","city_name":"Muruganpalayam","state_id":"35"},
    {"city_id":"3984","city_name":"Musiri","state_id":"35"},
    {"city_id":"3985","city_name":"Muthupet","state_id":"35"},
    {"city_id":"3986","city_name":"Muthur","state_id":"35"},
    {"city_id":"3987","city_name":"Muttayyapuram","state_id":"35"},
    {"city_id":"3988","city_name":"Muttupet","state_id":"35"},
    {"city_id":"3989","city_name":"Muvarasampettai","state_id":"35"},
    {"city_id":"3990","city_name":"Myladi","state_id":"35"},
    {"city_id":"3991","city_name":"Mylapore","state_id":"35"},
    {"city_id":"3992","city_name":"Nadukkuthagai","state_id":"35"},
    {"city_id":"3993","city_name":"Naduvattam","state_id":"35"},
    {"city_id":"3994","city_name":"Nagapattinam","state_id":"35"},
    {"city_id":"3995","city_name":"Nagavakulam","state_id":"35"},
    {"city_id":"3996","city_name":"Nagercoil","state_id":"35"},
    {"city_id":"3997","city_name":"Nagojanahalli","state_id":"35"},
    {"city_id":"3998","city_name":"Nallampatti","state_id":"35"},
    {"city_id":"3999","city_name":"Nallur","state_id":"35"},
    {"city_id":"4000","city_name":"Namagiripettai","state_id":"35"},
    {"city_id":"4001","city_name":"Namakkal","state_id":"35"},
    {"city_id":"4002","city_name":"Nambiyur","state_id":"35"},
    {"city_id":"4003","city_name":"Nambutalai","state_id":"35"},
    {"city_id":"4004","city_name":"Nandambakkam","state_id":"35"},
    {"city_id":"4005","city_name":"Nandivaram","state_id":"35"},
    {"city_id":"4006","city_name":"Nangavalli","state_id":"35"},
    {"city_id":"4007","city_name":"Nangavaram","state_id":"35"},
    {"city_id":"4008","city_name":"Nanguneri","state_id":"35"},
    {"city_id":"4009","city_name":"Nanjikottai","state_id":"35"},
    {"city_id":"4010","city_name":"Nannilam","state_id":"35"},
    {"city_id":"4011","city_name":"Naranammalpuram","state_id":"35"},
    {"city_id":"4012","city_name":"Naranapuram","state_id":"35"},
    {"city_id":"4013","city_name":"Narasimhanaickenpalayam","state_id":"35"},
    {"city_id":"4014","city_name":"Narasingapuram","state_id":"35"},
    {"city_id":"4015","city_name":"Narasojipatti","state_id":"35"},
    {"city_id":"4016","city_name":"Naravarikuppam","state_id":"35"},
    {"city_id":"4017","city_name":"Nasiyanur","state_id":"35"},
    {"city_id":"4018","city_name":"Natham","state_id":"35"},
    {"city_id":"4019","city_name":"Nathampannai","state_id":"35"},
    {"city_id":"4020","city_name":"Natrampalli","state_id":"35"},
    {"city_id":"4021","city_name":"Nattam","state_id":"35"},
    {"city_id":"4022","city_name":"Nattapettai","state_id":"35"},
    {"city_id":"4023","city_name":"Nattarasankottai","state_id":"35"},
    {"city_id":"4024","city_name":"Navalpattu","state_id":"35"},
    {"city_id":"4025","city_name":"Nazarethpettai","state_id":"35"},
    {"city_id":"4026","city_name":"Nazerath","state_id":"35"},
    {"city_id":"4027","city_name":"Neikkarapatti","state_id":"35"},
    {"city_id":"4028","city_name":"Neiyyur","state_id":"35"},
    {"city_id":"4029","city_name":"Nellikkuppam","state_id":"35"},
    {"city_id":"4030","city_name":"Nelliyalam","state_id":"35"},
    {"city_id":"4031","city_name":"Nemili","state_id":"35"},
    {"city_id":"4032","city_name":"Nemilicheri","state_id":"35"},
    {"city_id":"4033","city_name":"Neripperichal","state_id":"35"},
    {"city_id":"4034","city_name":"Nerkunram","state_id":"35"},
    {"city_id":"4035","city_name":"Nerkuppai","state_id":"35"},
    {"city_id":"4036","city_name":"Nerunjipettai","state_id":"35"},
    {"city_id":"4037","city_name":"Neykkarappatti","state_id":"35"},
    {"city_id":"4038","city_name":"Neyveli","state_id":"35"},
    {"city_id":"4039","city_name":"Nidamangalam","state_id":"35"},
    {"city_id":"4040","city_name":"Nilagiri","state_id":"35"},
    {"city_id":"4041","city_name":"Nilakkottai","state_id":"35"},
    {"city_id":"4042","city_name":"Nilankarai","state_id":"35"},
    {"city_id":"4043","city_name":"Odaipatti","state_id":"35"},
    {"city_id":"4044","city_name":"Odaiyakulam","state_id":"35"},
    {"city_id":"4045","city_name":"Oddanchatram","state_id":"35"},
    {"city_id":"4046","city_name":"Odugathur","state_id":"35"},
    {"city_id":"4047","city_name":"Oggiyamduraipakkam","state_id":"35"},
    {"city_id":"4048","city_name":"Olagadam","state_id":"35"},
    {"city_id":"4049","city_name":"Omalur","state_id":"35"},
    {"city_id":"4050","city_name":"Ooty","state_id":"35"},
    {"city_id":"4051","city_name":"Orathanadu","state_id":"35"},
    {"city_id":"4052","city_name":"Othakadai","state_id":"35"},
    {"city_id":"4053","city_name":"Othakalmandapam","state_id":"35"},
    {"city_id":"4054","city_name":"Ottapparai","state_id":"35"},
    {"city_id":"4055","city_name":"Pacode","state_id":"35"},
    {"city_id":"4056","city_name":"Padaividu","state_id":"35"},
    {"city_id":"4057","city_name":"Padianallur","state_id":"35"},
    {"city_id":"4058","city_name":"Padirikuppam","state_id":"35"},
    {"city_id":"4059","city_name":"Padmanabhapuram","state_id":"35"},
    {"city_id":"4060","city_name":"Padririvedu","state_id":"35"},
    {"city_id":"4061","city_name":"Palaganangudy","state_id":"35"},
    {"city_id":"4062","city_name":"Palaimpatti","state_id":"35"},
    {"city_id":"4063","city_name":"Palakkodu","state_id":"35"},
    {"city_id":"4064","city_name":"Palamedu","state_id":"35"},
    {"city_id":"4065","city_name":"Palani","state_id":"35"},
    {"city_id":"4066","city_name":"Palani Chettipatti","state_id":"35"},
    {"city_id":"4067","city_name":"Palavakkam","state_id":"35"},
    {"city_id":"4068","city_name":"Palavansathu","state_id":"35"},
    {"city_id":"4069","city_name":"Palayakayal","state_id":"35"},
    {"city_id":"4070","city_name":"Palayam","state_id":"35"},
    {"city_id":"4071","city_name":"Palayamkottai","state_id":"35"},
    {"city_id":"4072","city_name":"Palladam","state_id":"35"},
    {"city_id":"4073","city_name":"Pallapalayam","state_id":"35"},
    {"city_id":"4074","city_name":"Pallapatti","state_id":"35"},
    {"city_id":"4075","city_name":"Pallattur","state_id":"35"},
    {"city_id":"4076","city_name":"Pallavaram","state_id":"35"},
    {"city_id":"4077","city_name":"Pallikaranai","state_id":"35"},
    {"city_id":"4078","city_name":"Pallikonda","state_id":"35"},
    {"city_id":"4079","city_name":"Pallipalaiyam","state_id":"35"},
    {"city_id":"4080","city_name":"Pallipalaiyam Agraharam","state_id":"35"},
    {"city_id":"4081","city_name":"Pallipattu","state_id":"35"},
    {"city_id":"4082","city_name":"Pammal","state_id":"35"},
    {"city_id":"4083","city_name":"Panagudi","state_id":"35"},
    {"city_id":"4084","city_name":"Panaimarathupatti","state_id":"35"},
    {"city_id":"4085","city_name":"Panapakkam","state_id":"35"},
    {"city_id":"4086","city_name":"Panboli","state_id":"35"},
    {"city_id":"4087","city_name":"Pandamangalam","state_id":"35"},
    {"city_id":"4088","city_name":"Pannaikadu","state_id":"35"},
    {"city_id":"4089","city_name":"Pannaipuram","state_id":"35"},
    {"city_id":"4090","city_name":"Pannuratti","state_id":"35"},
    {"city_id":"4091","city_name":"Panruti","state_id":"35"},
    {"city_id":"4092","city_name":"Papanasam","state_id":"35"},
    {"city_id":"4093","city_name":"Pappankurichi","state_id":"35"},
    {"city_id":"4094","city_name":"Papparapatti","state_id":"35"},
    {"city_id":"4095","city_name":"Pappireddipatti","state_id":"35"},
    {"city_id":"4096","city_name":"Paramakkudi","state_id":"35"},
    {"city_id":"4097","city_name":"Paramankurichi","state_id":"35"},
    {"city_id":"4098","city_name":"Paramathi","state_id":"35"},
    {"city_id":"4099","city_name":"Parangippettai","state_id":"35"},
    {"city_id":"4100","city_name":"Paravai","state_id":"35"},
    {"city_id":"4101","city_name":"Pasur","state_id":"35"},
    {"city_id":"4102","city_name":"Pathamadai","state_id":"35"},
    {"city_id":"4103","city_name":"Pattinam","state_id":"35"},
    {"city_id":"4104","city_name":"Pattiviranpatti","state_id":"35"},
    {"city_id":"4105","city_name":"Pattukkottai","state_id":"35"},
    {"city_id":"4106","city_name":"Pazhugal","state_id":"35"},
    {"city_id":"4107","city_name":"Pennadam","state_id":"35"},
    {"city_id":"4108","city_name":"Pennagaram","state_id":"35"},
    {"city_id":"4109","city_name":"Pennathur","state_id":"35"},
    {"city_id":"4110","city_name":"Peraiyur","state_id":"35"},
    {"city_id":"4111","city_name":"Peralam","state_id":"35"},
    {"city_id":"4112","city_name":"Perambalur","state_id":"35"},
    {"city_id":"4113","city_name":"Peranamallur","state_id":"35"},
    {"city_id":"4114","city_name":"Peravurani","state_id":"35"},
    {"city_id":"4115","city_name":"Periyakodiveri","state_id":"35"},
    {"city_id":"4116","city_name":"Periyakulam","state_id":"35"},
    {"city_id":"4117","city_name":"Periyanayakkanpalaiyam","state_id":"35"},
    {"city_id":"4118","city_name":"Periyanegamam","state_id":"35"},
    {"city_id":"4119","city_name":"Periyapatti","state_id":"35"},
    {"city_id":"4120","city_name":"Periyasemur","state_id":"35"},
    {"city_id":"4121","city_name":"Pernambut","state_id":"35"},
    {"city_id":"4122","city_name":"Perumagalur","state_id":"35"},
    {"city_id":"4123","city_name":"Perumandi","state_id":"35"},
    {"city_id":"4124","city_name":"Perumuchi","state_id":"35"},
    {"city_id":"4125","city_name":"Perundurai","state_id":"35"},
    {"city_id":"4126","city_name":"Perungalathur","state_id":"35"},
    {"city_id":"4127","city_name":"Perungudi","state_id":"35"},
    {"city_id":"4128","city_name":"Perungulam","state_id":"35"},
    {"city_id":"4129","city_name":"Perur","state_id":"35"},
    {"city_id":"4130","city_name":"Perur Chettipalaiyam","state_id":"35"},
    {"city_id":"4131","city_name":"Pethampalayam","state_id":"35"},
    {"city_id":"4132","city_name":"Pethanaickenpalayam","state_id":"35"},
    {"city_id":"4133","city_name":"Pillanallur","state_id":"35"},
    {"city_id":"4134","city_name":"Pirkankaranai","state_id":"35"},
    {"city_id":"4135","city_name":"Polichalur","state_id":"35"},
    {"city_id":"4136","city_name":"Pollachi","state_id":"35"},
    {"city_id":"4137","city_name":"Polur","state_id":"35"},
    {"city_id":"4138","city_name":"Ponmani","state_id":"35"},
    {"city_id":"4139","city_name":"Ponnamaravathi","state_id":"35"},
    {"city_id":"4140","city_name":"Ponnampatti","state_id":"35"},
    {"city_id":"4141","city_name":"Ponneri","state_id":"35"},
    {"city_id":"4142","city_name":"Porur","state_id":"35"},
    {"city_id":"4143","city_name":"Pothanur","state_id":"35"},
    {"city_id":"4144","city_name":"Pothatturpettai","state_id":"35"},
    {"city_id":"4145","city_name":"Pudukadai","state_id":"35"},
    {"city_id":"4146","city_name":"Pudukkottai Cantonment","state_id":"35"},
    {"city_id":"4147","city_name":"Pudukottai","state_id":"35"},
    {"city_id":"4148","city_name":"Pudupalaiyam Aghraharam","state_id":"35"},
    {"city_id":"4149","city_name":"Pudupalayam","state_id":"35"},
    {"city_id":"4150","city_name":"Pudupatti","state_id":"35"},
    {"city_id":"4151","city_name":"Pudupattinam","state_id":"35"},
    {"city_id":"4152","city_name":"Pudur","state_id":"35"},
    {"city_id":"4153","city_name":"Puduvayal","state_id":"35"},
    {"city_id":"4154","city_name":"Pulambadi","state_id":"35"},
    {"city_id":"4155","city_name":"Pulampatti","state_id":"35"},
    {"city_id":"4156","city_name":"Puliyampatti","state_id":"35"},
    {"city_id":"4157","city_name":"Puliyankudi","state_id":"35"},
    {"city_id":"4158","city_name":"Puliyur","state_id":"35"},
    {"city_id":"4159","city_name":"Pullampadi","state_id":"35"},
    {"city_id":"4160","city_name":"Puluvapatti","state_id":"35"},
    {"city_id":"4161","city_name":"Punamalli","state_id":"35"},
    {"city_id":"4162","city_name":"Punjai Puliyampatti","state_id":"35"},
    {"city_id":"4163","city_name":"Punjai Thottakurichi","state_id":"35"},
    {"city_id":"4164","city_name":"Punjaipugalur","state_id":"35"},
    {"city_id":"4165","city_name":"Puthalam","state_id":"35"},
    {"city_id":"4166","city_name":"Putteri","state_id":"35"},
    {"city_id":"4167","city_name":"Puvalur","state_id":"35"},
    {"city_id":"4168","city_name":"Puzhal","state_id":"35"},
    {"city_id":"4169","city_name":"Puzhithivakkam","state_id":"35"},
    {"city_id":"4170","city_name":"Rajapalayam","state_id":"35"},
    {"city_id":"4171","city_name":"Ramanathapuram","state_id":"35"},
    {"city_id":"4172","city_name":"Ramapuram","state_id":"35"},
    {"city_id":"4173","city_name":"Rameswaram","state_id":"35"},
    {"city_id":"4174","city_name":"Ranipet","state_id":"35"},
    {"city_id":"4175","city_name":"Rasipuram","state_id":"35"},
    {"city_id":"4176","city_name":"Rayagiri","state_id":"35"},
    {"city_id":"4177","city_name":"Rithapuram","state_id":"35"},
    {"city_id":"4178","city_name":"Rosalpatti","state_id":"35"},
    {"city_id":"4179","city_name":"Rudravathi","state_id":"35"},
    {"city_id":"4180","city_name":"Sadayankuppam","state_id":"35"},
    {"city_id":"4181","city_name":"Saint Thomas Mount","state_id":"35"},
    {"city_id":"4182","city_name":"Salangapalayam","state_id":"35"},
    {"city_id":"4183","city_name":"Salem","state_id":"35"},
    {"city_id":"4184","city_name":"Samalapuram","state_id":"35"},
    {"city_id":"4185","city_name":"Samathur","state_id":"35"},
    {"city_id":"4186","city_name":"Sambavar Vadagarai","state_id":"35"},
    {"city_id":"4187","city_name":"Sankaramanallur","state_id":"35"},
    {"city_id":"4188","city_name":"Sankarankoil","state_id":"35"},
    {"city_id":"4189","city_name":"Sankarapuram","state_id":"35"},
    {"city_id":"4190","city_name":"Sankari","state_id":"35"},
    {"city_id":"4191","city_name":"Sankarnagar","state_id":"35"},
    {"city_id":"4192","city_name":"Saravanampatti","state_id":"35"},
    {"city_id":"4193","city_name":"Sarcarsamakulam","state_id":"35"},
    {"city_id":"4194","city_name":"Sathiyavijayanagaram","state_id":"35"},
    {"city_id":"4195","city_name":"Sathuvachari","state_id":"35"},
    {"city_id":"4196","city_name":"Sathyamangalam","state_id":"35"},
    {"city_id":"4197","city_name":"Sattankulam","state_id":"35"},
    {"city_id":"4198","city_name":"Sattur","state_id":"35"},
    {"city_id":"4199","city_name":"Sayalgudi","state_id":"35"},
    {"city_id":"4200","city_name":"Sayapuram","state_id":"35"},
    {"city_id":"4201","city_name":"Seithur","state_id":"35"},
    {"city_id":"4202","city_name":"Sembakkam","state_id":"35"},
    {"city_id":"4203","city_name":"Semmipalayam","state_id":"35"},
    {"city_id":"4204","city_name":"Sennirkuppam","state_id":"35"},
    {"city_id":"4205","city_name":"Senthamangalam","state_id":"35"},
    {"city_id":"4206","city_name":"Sentharapatti","state_id":"35"},
    {"city_id":"4207","city_name":"Senur","state_id":"35"},
    {"city_id":"4208","city_name":"Sethiathoppu","state_id":"35"},
    {"city_id":"4209","city_name":"Sevilimedu","state_id":"35"},
    {"city_id":"4210","city_name":"Sevugampatti","state_id":"35"},
    {"city_id":"4211","city_name":"Shenbakkam","state_id":"35"},
    {"city_id":"4212","city_name":"Shencottai","state_id":"35"},
    {"city_id":"4213","city_name":"Shenkottai","state_id":"35"},
    {"city_id":"4214","city_name":"Sholavandan","state_id":"35"},
    {"city_id":"4215","city_name":"Sholinganallur","state_id":"35"},
    {"city_id":"4216","city_name":"Sholingur","state_id":"35"},
    {"city_id":"4217","city_name":"Sholur","state_id":"35"},
    {"city_id":"4218","city_name":"Sikkarayapuram","state_id":"35"},
    {"city_id":"4219","city_name":"Singampuneri","state_id":"35"},
    {"city_id":"4220","city_name":"Singanallur","state_id":"35"},
    {"city_id":"4221","city_name":"Singaperumalkoil","state_id":"35"},
    {"city_id":"4222","city_name":"Sirapalli","state_id":"35"},
    {"city_id":"4223","city_name":"Sirkali","state_id":"35"},
    {"city_id":"4224","city_name":"Sirugamani","state_id":"35"},
    {"city_id":"4225","city_name":"Sirumugai","state_id":"35"},
    {"city_id":"4226","city_name":"Sithayankottai","state_id":"35"},
    {"city_id":"4227","city_name":"Sithurajapuram","state_id":"35"},
    {"city_id":"4228","city_name":"Sivaganga","state_id":"35"},
    {"city_id":"4229","city_name":"Sivagiri","state_id":"35"},
    {"city_id":"4230","city_name":"Sivakasi","state_id":"35"},
    {"city_id":"4231","city_name":"Sivanthipuram","state_id":"35"},
    {"city_id":"4232","city_name":"Sivur","state_id":"35"},
    {"city_id":"4233","city_name":"Soranjeri","state_id":"35"},
    {"city_id":"4234","city_name":"South Kannanur","state_id":"35"},
    {"city_id":"4235","city_name":"South Kodikulam","state_id":"35"},
    {"city_id":"4236","city_name":"Srimushnam","state_id":"35"},
    {"city_id":"4237","city_name":"Sriperumpudur","state_id":"35"},
    {"city_id":"4238","city_name":"Sriramapuram","state_id":"35"},
    {"city_id":"4239","city_name":"Srirangam","state_id":"35"},
    {"city_id":"4240","city_name":"Srivaikuntam","state_id":"35"},
    {"city_id":"4241","city_name":"Srivilliputtur","state_id":"35"},
    {"city_id":"4242","city_name":"Suchindram","state_id":"35"},
    {"city_id":"4243","city_name":"Suliswaranpatti","state_id":"35"},
    {"city_id":"4244","city_name":"Sulur","state_id":"35"},
    {"city_id":"4245","city_name":"Sundarapandiam","state_id":"35"},
    {"city_id":"4246","city_name":"Sundarapandiapuram","state_id":"35"},
    {"city_id":"4247","city_name":"Surampatti","state_id":"35"},
    {"city_id":"4248","city_name":"Surandai","state_id":"35"},
    {"city_id":"4249","city_name":"Suriyampalayam","state_id":"35"},
    {"city_id":"4250","city_name":"Swamimalai","state_id":"35"},
    {"city_id":"4251","city_name":"TNPL Pugalur","state_id":"35"},
    {"city_id":"4252","city_name":"Tambaram","state_id":"35"},
    {"city_id":"4253","city_name":"Taramangalam","state_id":"35"},
    {"city_id":"4254","city_name":"Tattayyangarpettai","state_id":"35"},
    {"city_id":"4255","city_name":"Tayilupatti","state_id":"35"},
    {"city_id":"4256","city_name":"Tenkasi","state_id":"35"},
    {"city_id":"4257","city_name":"Thadikombu","state_id":"35"},
    {"city_id":"4258","city_name":"Thakkolam","state_id":"35"},
    {"city_id":"4259","city_name":"Thalainayar","state_id":"35"},
    {"city_id":"4260","city_name":"Thalakudi","state_id":"35"},
    {"city_id":"4261","city_name":"Thamaraikulam","state_id":"35"},
    {"city_id":"4262","city_name":"Thammampatti","state_id":"35"},
    {"city_id":"4263","city_name":"Thanjavur","state_id":"35"},
    {"city_id":"4264","city_name":"Thanthoni","state_id":"35"},
    {"city_id":"4265","city_name":"Tharangambadi","state_id":"35"},
    {"city_id":"4266","city_name":"Thedavur","state_id":"35"},
    {"city_id":"4267","city_name":"Thenambakkam","state_id":"35"},
    {"city_id":"4268","city_name":"Thengampudur","state_id":"35"},
    {"city_id":"4269","city_name":"Theni","state_id":"35"},
    {"city_id":"4270","city_name":"Theni Allinagaram","state_id":"35"},
    {"city_id":"4271","city_name":"Thenkarai","state_id":"35"},
    {"city_id":"4272","city_name":"Thenthamaraikulam","state_id":"35"},
    {"city_id":"4273","city_name":"Thenthiruperai","state_id":"35"},
    {"city_id":"4274","city_name":"Thesur","state_id":"35"},
    {"city_id":"4275","city_name":"Thevaram","state_id":"35"},
    {"city_id":"4276","city_name":"Thevur","state_id":"35"},
    {"city_id":"4277","city_name":"Thiagadurgam","state_id":"35"},
    {"city_id":"4278","city_name":"Thiagarajar Colony","state_id":"35"},
    {"city_id":"4279","city_name":"Thingalnagar","state_id":"35"},
    {"city_id":"4280","city_name":"Thiruchirapalli","state_id":"35"},
    {"city_id":"4281","city_name":"Thirukarungudi","state_id":"35"},
    {"city_id":"4282","city_name":"Thirukazhukundram","state_id":"35"},
    {"city_id":"4283","city_name":"Thirumalayampalayam","state_id":"35"},
    {"city_id":"4284","city_name":"Thirumazhisai","state_id":"35"},
    {"city_id":"4285","city_name":"Thirunagar","state_id":"35"},
    {"city_id":"4286","city_name":"Thirunageswaram","state_id":"35"},
    {"city_id":"4287","city_name":"Thirunindravur","state_id":"35"},
    {"city_id":"4288","city_name":"Thirunirmalai","state_id":"35"},
    {"city_id":"4289","city_name":"Thiruparankundram","state_id":"35"},
    {"city_id":"4290","city_name":"Thiruparappu","state_id":"35"},
    {"city_id":"4291","city_name":"Thiruporur","state_id":"35"},
    {"city_id":"4292","city_name":"Thiruppanandal","state_id":"35"},
    {"city_id":"4293","city_name":"Thirupuvanam","state_id":"35"},
    {"city_id":"4294","city_name":"Thiruthangal","state_id":"35"},
    {"city_id":"4295","city_name":"Thiruthuraipundi","state_id":"35"},
    {"city_id":"4296","city_name":"Thiruvaivaru","state_id":"35"},
    {"city_id":"4297","city_name":"Thiruvalam","state_id":"35"},
    {"city_id":"4298","city_name":"Thiruvarur","state_id":"35"},
    {"city_id":"4299","city_name":"Thiruvattaru","state_id":"35"},
    {"city_id":"4300","city_name":"Thiruvenkatam","state_id":"35"},
    {"city_id":"4301","city_name":"Thiruvennainallur","state_id":"35"},
    {"city_id":"4302","city_name":"Thiruvithankodu","state_id":"35"},
    {"city_id":"4303","city_name":"Thisayanvilai","state_id":"35"},
    {"city_id":"4304","city_name":"Thittacheri","state_id":"35"},
    {"city_id":"4305","city_name":"Thondamuthur","state_id":"35"},
    {"city_id":"4306","city_name":"Thorapadi","state_id":"35"},
    {"city_id":"4307","city_name":"Thottipalayam","state_id":"35"},
    {"city_id":"4308","city_name":"Thottiyam","state_id":"35"},
    {"city_id":"4309","city_name":"Thudiyalur","state_id":"35"},
    {"city_id":"4310","city_name":"Thuthipattu","state_id":"35"},
    {"city_id":"4311","city_name":"Thuvakudi","state_id":"35"},
    {"city_id":"4312","city_name":"Timiri","state_id":"35"},
    {"city_id":"4313","city_name":"Tindivanam","state_id":"35"},
    {"city_id":"4314","city_name":"Tinnanur","state_id":"35"},
    {"city_id":"4315","city_name":"Tiruchchendur","state_id":"35"},
    {"city_id":"4316","city_name":"Tiruchengode","state_id":"35"},
    {"city_id":"4317","city_name":"Tirukkalukkundram","state_id":"35"},
    {"city_id":"4318","city_name":"Tirukkattuppalli","state_id":"35"},
    {"city_id":"4319","city_name":"Tirukkoyilur","state_id":"35"},
    {"city_id":"4320","city_name":"Tirumangalam","state_id":"35"},
    {"city_id":"4321","city_name":"Tirumullaivasal","state_id":"35"},
    {"city_id":"4322","city_name":"Tirumuruganpundi","state_id":"35"},
    {"city_id":"4323","city_name":"Tirunageswaram","state_id":"35"},
    {"city_id":"4324","city_name":"Tirunelveli","state_id":"35"},
    {"city_id":"4325","city_name":"Tirupathur","state_id":"35"},
    {"city_id":"4326","city_name":"Tirupattur","state_id":"35"},
    {"city_id":"4327","city_name":"Tiruppuvanam","state_id":"35"},
    {"city_id":"4328","city_name":"Tirupur","state_id":"35"},
    {"city_id":"4329","city_name":"Tirusulam","state_id":"35"},
    {"city_id":"4330","city_name":"Tiruttani","state_id":"35"},
    {"city_id":"4331","city_name":"Tiruvallur","state_id":"35"},
    {"city_id":"4332","city_name":"Tiruvannamalai","state_id":"35"},
    {"city_id":"4333","city_name":"Tiruverambur","state_id":"35"},
    {"city_id":"4334","city_name":"Tiruverkadu","state_id":"35"},
    {"city_id":"4335","city_name":"Tiruvethipuram","state_id":"35"},
    {"city_id":"4336","city_name":"Tiruvidaimarudur","state_id":"35"},
    {"city_id":"4337","city_name":"Tiruvottiyur","state_id":"35"},
    {"city_id":"4338","city_name":"Tittakudi","state_id":"35"},
    {"city_id":"4339","city_name":"Tondi","state_id":"35"},
    {"city_id":"4340","city_name":"Turaiyur","state_id":"35"},
    {"city_id":"4341","city_name":"Tuticorin","state_id":"35"},
    {"city_id":"4342","city_name":"Udagamandalam","state_id":"35"},
    {"city_id":"4343","city_name":"Udagamandalam Valley","state_id":"35"},
    {"city_id":"4344","city_name":"Udankudi","state_id":"35"},
    {"city_id":"4345","city_name":"Udayarpalayam","state_id":"35"},
    {"city_id":"4346","city_name":"Udumalaipettai","state_id":"35"},
    {"city_id":"4347","city_name":"Udumalpet","state_id":"35"},
    {"city_id":"4348","city_name":"Ullur","state_id":"35"},
    {"city_id":"4349","city_name":"Ulundurpettai","state_id":"35"},
    {"city_id":"4350","city_name":"Unjalaur","state_id":"35"},
    {"city_id":"4351","city_name":"Unnamalaikadai","state_id":"35"},
    {"city_id":"4352","city_name":"Uppidamangalam","state_id":"35"},
    {"city_id":"4353","city_name":"Uppiliapuram","state_id":"35"},
    {"city_id":"4354","city_name":"Urachikkottai","state_id":"35"},
    {"city_id":"4355","city_name":"Urapakkam","state_id":"35"},
    {"city_id":"4356","city_name":"Usilampatti","state_id":"35"},
    {"city_id":"4357","city_name":"Uthangarai","state_id":"35"},
    {"city_id":"4358","city_name":"Uthayendram","state_id":"35"},
    {"city_id":"4359","city_name":"Uthiramerur","state_id":"35"},
    {"city_id":"4360","city_name":"Uthukkottai","state_id":"35"},
    {"city_id":"4361","city_name":"Uttamapalaiyam","state_id":"35"},
    {"city_id":"4362","city_name":"Uttukkuli","state_id":"35"},
    {"city_id":"4363","city_name":"Vadakarai Kizhpadugai","state_id":"35"},
    {"city_id":"4364","city_name":"Vadakkanandal","state_id":"35"},
    {"city_id":"4365","city_name":"Vadakku Valliyur","state_id":"35"},
    {"city_id":"4366","city_name":"Vadalur","state_id":"35"},
    {"city_id":"4367","city_name":"Vadamadurai","state_id":"35"},
    {"city_id":"4368","city_name":"Vadavalli","state_id":"35"},
    {"city_id":"4369","city_name":"Vadipatti","state_id":"35"},
    {"city_id":"4370","city_name":"Vadugapatti","state_id":"35"},
    {"city_id":"4371","city_name":"Vaithiswarankoil","state_id":"35"},
    {"city_id":"4372","city_name":"Valangaiman","state_id":"35"},
    {"city_id":"4373","city_name":"Valasaravakkam","state_id":"35"},
    {"city_id":"4374","city_name":"Valavanur","state_id":"35"},
    {"city_id":"4375","city_name":"Vallam","state_id":"35"},
    {"city_id":"4376","city_name":"Valparai","state_id":"35"},
    {"city_id":"4377","city_name":"Valvaithankoshtam","state_id":"35"},
    {"city_id":"4378","city_name":"Vanavasi","state_id":"35"},
    {"city_id":"4379","city_name":"Vandalur","state_id":"35"},
    {"city_id":"4380","city_name":"Vandavasi","state_id":"35"},
    {"city_id":"4381","city_name":"Vandiyur","state_id":"35"},
    {"city_id":"4382","city_name":"Vaniputhur","state_id":"35"},
    {"city_id":"4383","city_name":"Vaniyambadi","state_id":"35"},
    {"city_id":"4384","city_name":"Varadarajanpettai","state_id":"35"},
    {"city_id":"4385","city_name":"Varadharajapuram","state_id":"35"},
    {"city_id":"4386","city_name":"Vasudevanallur","state_id":"35"},
    {"city_id":"4387","city_name":"Vathirairuppu","state_id":"35"},
    {"city_id":"4388","city_name":"Vattalkundu","state_id":"35"},
    {"city_id":"4389","city_name":"Vazhapadi","state_id":"35"},
    {"city_id":"4390","city_name":"Vedapatti","state_id":"35"},
    {"city_id":"4391","city_name":"Vedaranniyam","state_id":"35"},
    {"city_id":"4392","city_name":"Vedasandur","state_id":"35"},
    {"city_id":"4393","city_name":"Velampalaiyam","state_id":"35"},
    {"city_id":"4394","city_name":"Velankanni","state_id":"35"},
    {"city_id":"4395","city_name":"Vellakinar","state_id":"35"},
    {"city_id":"4396","city_name":"Vellakoil","state_id":"35"},
    {"city_id":"4397","city_name":"Vellalapatti","state_id":"35"},
    {"city_id":"4398","city_name":"Vellalur","state_id":"35"},
    {"city_id":"4399","city_name":"Vellanur","state_id":"35"},
    {"city_id":"4400","city_name":"Vellimalai","state_id":"35"},
    {"city_id":"4401","city_name":"Vellore","state_id":"35"},
    {"city_id":"4402","city_name":"Vellottamparappu","state_id":"35"},
    {"city_id":"4403","city_name":"Velluru","state_id":"35"},
    {"city_id":"4404","city_name":"Vengampudur","state_id":"35"},
    {"city_id":"4405","city_name":"Vengathur","state_id":"35"},
    {"city_id":"4406","city_name":"Vengavasal","state_id":"35"},
    {"city_id":"4407","city_name":"Venghatur","state_id":"35"},
    {"city_id":"4408","city_name":"Venkarai","state_id":"35"},
    {"city_id":"4409","city_name":"Vennanthur","state_id":"35"},
    {"city_id":"4410","city_name":"Veppathur","state_id":"35"},
    {"city_id":"4411","city_name":"Verkilambi","state_id":"35"},
    {"city_id":"4412","city_name":"Vettaikaranpudur","state_id":"35"},
    {"city_id":"4413","city_name":"Vettavalam","state_id":"35"},
    {"city_id":"4414","city_name":"Vijayapuri","state_id":"35"},
    {"city_id":"4415","city_name":"Vikramasingapuram","state_id":"35"},
    {"city_id":"4416","city_name":"Vikravandi","state_id":"35"},
    {"city_id":"4417","city_name":"Vilangudi","state_id":"35"},
    {"city_id":"4418","city_name":"Vilankurichi","state_id":"35"},
    {"city_id":"4419","city_name":"Vilapakkam","state_id":"35"},
    {"city_id":"4420","city_name":"Vilathikulam","state_id":"35"},
    {"city_id":"4421","city_name":"Vilavur","state_id":"35"},
    {"city_id":"4422","city_name":"Villukuri","state_id":"35"},
    {"city_id":"4423","city_name":"Villupuram","state_id":"35"},
    {"city_id":"4424","city_name":"Viraganur","state_id":"35"},
    {"city_id":"4425","city_name":"Virakeralam","state_id":"35"},
    {"city_id":"4426","city_name":"Virakkalpudur","state_id":"35"},
    {"city_id":"4427","city_name":"Virapandi","state_id":"35"},
    {"city_id":"4428","city_name":"Virapandi Cantonment","state_id":"35"},
    {"city_id":"4429","city_name":"Virappanchatram","state_id":"35"},
    {"city_id":"4430","city_name":"Viravanallur","state_id":"35"},
    {"city_id":"4431","city_name":"Virudambattu","state_id":"35"},
    {"city_id":"4432","city_name":"Virudhachalam","state_id":"35"},
    {"city_id":"4433","city_name":"Virudhunagar","state_id":"35"},
    {"city_id":"4434","city_name":"Virupakshipuram","state_id":"35"},
    {"city_id":"4435","city_name":"Viswanatham","state_id":"35"},
    {"city_id":"4436","city_name":"Vriddhachalam","state_id":"35"},
    {"city_id":"4437","city_name":"Walajabad","state_id":"35"},
    {"city_id":"4438","city_name":"Walajapet","state_id":"35"},
    {"city_id":"4439","city_name":"Wellington","state_id":"35"},
    {"city_id":"4440","city_name":"Yercaud","state_id":"35"},
    {"city_id":"4441","city_name":"Zamin Uthukuli","state_id":"35"},
    {"city_id":"4442","city_name":"Achampet","state_id":"36"},
    {"city_id":"4443","city_name":"Adilabad","state_id":"36"},
    {"city_id":"4444","city_name":"Armoor","state_id":"36"},
    {"city_id":"4445","city_name":"Asifabad","state_id":"36"},
    {"city_id":"4446","city_name":"Badepally","state_id":"36"},
    {"city_id":"4447","city_name":"Banswada","state_id":"36"},
    {"city_id":"4448","city_name":"Bellampalli","state_id":"36"},
    {"city_id":"4449","city_name":"Bhadrachalam","state_id":"36"},
    {"city_id":"4450","city_name":"Bhainsa","state_id":"36"},
    {"city_id":"4451","city_name":"Bhongir","state_id":"36"},
    {"city_id":"4452","city_name":"Bhupalpally","state_id":"36"},
    {"city_id":"4453","city_name":"Bodhan","state_id":"36"},
    {"city_id":"4454","city_name":"Bollaram","state_id":"36"},
    {"city_id":"4455","city_name":"Devarkonda","state_id":"36"},
    {"city_id":"4456","city_name":"Farooqnagar","state_id":"36"},
    {"city_id":"4457","city_name":"Gadwal","state_id":"36"},
    {"city_id":"4458","city_name":"Gajwel","state_id":"36"},
    {"city_id":"4459","city_name":"Ghatkesar","state_id":"36"},
    {"city_id":"4460","city_name":"Hyderabad","state_id":"36"},
    {"city_id":"4461","city_name":"Jagtial","state_id":"36"},
    {"city_id":"4462","city_name":"Jangaon","state_id":"36"},
    {"city_id":"4463","city_name":"Kagaznagar","state_id":"36"},
    {"city_id":"4464","city_name":"Kalwakurthy","state_id":"36"},
    {"city_id":"4465","city_name":"Kamareddy","state_id":"36"},
    {"city_id":"4466","city_name":"Karimnagar","state_id":"36"},
    {"city_id":"4467","city_name":"Khammam","state_id":"36"},
    {"city_id":"4468","city_name":"Kodada","state_id":"36"},
    {"city_id":"4469","city_name":"Koratla","state_id":"36"},
    {"city_id":"4470","city_name":"Kottagudem","state_id":"36"},
    {"city_id":"4471","city_name":"Kyathampalle","state_id":"36"},
    {"city_id":"4472","city_name":"Madhira","state_id":"36"},
    {"city_id":"4473","city_name":"Mahabubabad","state_id":"36"},
    {"city_id":"4474","city_name":"Mahbubnagar","state_id":"36"},
    {"city_id":"4475","city_name":"Mancherial","state_id":"36"},
    {"city_id":"4476","city_name":"Mandamarri","state_id":"36"},
    {"city_id":"4477","city_name":"Manuguru","state_id":"36"},
    {"city_id":"4478","city_name":"Medak","state_id":"36"},
    {"city_id":"4479","city_name":"Medchal","state_id":"36"},
    {"city_id":"4480","city_name":"Miryalaguda","state_id":"36"},
    {"city_id":"4481","city_name":"Nagar Karnul","state_id":"36"},
    {"city_id":"4482","city_name":"Nakrekal","state_id":"36"},
    {"city_id":"4483","city_name":"Nalgonda","state_id":"36"},
    {"city_id":"4484","city_name":"Narayanpet","state_id":"36"},
    {"city_id":"4485","city_name":"Narsampet","state_id":"36"},
    {"city_id":"4486","city_name":"Nirmal","state_id":"36"},
    {"city_id":"4487","city_name":"Nizamabad","state_id":"36"},
    {"city_id":"4488","city_name":"Palwancha","state_id":"36"},
    {"city_id":"4489","city_name":"Peddapalli","state_id":"36"},
    {"city_id":"4490","city_name":"Ramagundam","state_id":"36"},
    {"city_id":"4491","city_name":"Ranga Reddy district","state_id":"36"},
    {"city_id":"4492","city_name":"Sadasivpet","state_id":"36"},
    {"city_id":"4493","city_name":"Sangareddy","state_id":"36"},
    {"city_id":"4494","city_name":"Sarapaka","state_id":"36"},
    {"city_id":"4495","city_name":"Sathupalle","state_id":"36"},
    {"city_id":"4496","city_name":"Secunderabad","state_id":"36"},
    {"city_id":"4497","city_name":"Siddipet","state_id":"36"},
    {"city_id":"4498","city_name":"Singapur","state_id":"36"},
    {"city_id":"4499","city_name":"Sircilla","state_id":"36"},
    {"city_id":"4500","city_name":"Suryapet","state_id":"36"},
    {"city_id":"4501","city_name":"Tandur","state_id":"36"},
    {"city_id":"4502","city_name":"Vemulawada","state_id":"36"},
    {"city_id":"4503","city_name":"Vikarabad","state_id":"36"},
    {"city_id":"4504","city_name":"Wanaparthy","state_id":"36"},
    {"city_id":"4505","city_name":"Warangal","state_id":"36"},
    {"city_id":"4506","city_name":"Yellandu","state_id":"36"},
    {"city_id":"4507","city_name":"Zahirabad","state_id":"36"},
    {"city_id":"4508","city_name":"Agartala","state_id":"37"},
    {"city_id":"4509","city_name":"Amarpur","state_id":"37"},
    {"city_id":"4510","city_name":"Ambassa","state_id":"37"},
    {"city_id":"4511","city_name":"Badharghat","state_id":"37"},
    {"city_id":"4512","city_name":"Belonia","state_id":"37"},
    {"city_id":"4513","city_name":"Dharmanagar","state_id":"37"},
    {"city_id":"4514","city_name":"Gakulnagar","state_id":"37"},
    {"city_id":"4515","city_name":"Gandhigram","state_id":"37"},
    {"city_id":"4516","city_name":"Indranagar","state_id":"37"},
    {"city_id":"4517","city_name":"Jogendranagar","state_id":"37"},
    {"city_id":"4518","city_name":"Kailasahar","state_id":"37"},
    {"city_id":"4519","city_name":"Kamalpur","state_id":"37"},
    {"city_id":"4520","city_name":"Kanchanpur","state_id":"37"},
    {"city_id":"4521","city_name":"Khowai","state_id":"37"},
    {"city_id":"4522","city_name":"Kumarghat","state_id":"37"},
    {"city_id":"4523","city_name":"Kunjaban","state_id":"37"},
    {"city_id":"4524","city_name":"Narsingarh","state_id":"37"},
    {"city_id":"4525","city_name":"Pratapgarh","state_id":"37"},
    {"city_id":"4526","city_name":"Ranir Bazar","state_id":"37"},
    {"city_id":"4527","city_name":"Sabrum","state_id":"37"},
    {"city_id":"4528","city_name":"Sonamura","state_id":"37"},
    {"city_id":"4529","city_name":"Teliamura","state_id":"37"},
    {"city_id":"4530","city_name":"Udaipur","state_id":"37"},
    {"city_id":"4531","city_name":"Achhalda","state_id":"38"},
    {"city_id":"4532","city_name":"Achhnera","state_id":"38"},
    {"city_id":"4533","city_name":"Adari","state_id":"38"},
    {"city_id":"4534","city_name":"Afzalgarh","state_id":"38"},
    {"city_id":"4535","city_name":"Agarwal Mandi","state_id":"38"},
    {"city_id":"4536","city_name":"Agra","state_id":"38"},
    {"city_id":"4537","city_name":"Agra Cantonment","state_id":"38"},
    {"city_id":"4538","city_name":"Ahraura","state_id":"38"},
    {"city_id":"4539","city_name":"Ailum","state_id":"38"},
    {"city_id":"4540","city_name":"Air Force Area","state_id":"38"},
    {"city_id":"4541","city_name":"Ajhuwa","state_id":"38"},
    {"city_id":"4542","city_name":"Akbarpur","state_id":"38"},
    {"city_id":"4543","city_name":"Alapur","state_id":"38"},
    {"city_id":"4544","city_name":"Aliganj","state_id":"38"},
    {"city_id":"4545","city_name":"Aligarh","state_id":"38"},
    {"city_id":"4546","city_name":"Allahabad","state_id":"38"},
    {"city_id":"4547","city_name":"Allahabad Cantonment","state_id":"38"},
    {"city_id":"4548","city_name":"Allahganj","state_id":"38"},
    {"city_id":"4549","city_name":"Amanpur","state_id":"38"},
    {"city_id":"4550","city_name":"Ambahta","state_id":"38"},
    {"city_id":"4551","city_name":"Amethi","state_id":"38"},
    {"city_id":"4552","city_name":"Amila","state_id":"38"},
    {"city_id":"4553","city_name":"Amilo","state_id":"38"},
    {"city_id":"4554","city_name":"Aminagar Sarai","state_id":"38"},
    {"city_id":"4555","city_name":"Aminagar Urf Bhurbaral","state_id":"38"},
    {"city_id":"4556","city_name":"Amraudha","state_id":"38"},
    {"city_id":"4557","city_name":"Amroha","state_id":"38"},
    {"city_id":"4558","city_name":"Anandnagar","state_id":"38"},
    {"city_id":"4559","city_name":"Anpara","state_id":"38"},
    {"city_id":"4560","city_name":"Antu","state_id":"38"},
    {"city_id":"4561","city_name":"Anupshahr","state_id":"38"},
    {"city_id":"4562","city_name":"Aonla","state_id":"38"},
    {"city_id":"4563","city_name":"Armapur Estate","state_id":"38"},
    {"city_id":"4564","city_name":"Ashokpuram","state_id":"38"},
    {"city_id":"4565","city_name":"Ashrafpur Kichhauchha","state_id":"38"},
    {"city_id":"4566","city_name":"Atarra","state_id":"38"},
    {"city_id":"4567","city_name":"Atasu","state_id":"38"},
    {"city_id":"4568","city_name":"Atrauli","state_id":"38"},
    {"city_id":"4569","city_name":"Atraulia","state_id":"38"},
    {"city_id":"4570","city_name":"Auraiya","state_id":"38"},
    {"city_id":"4571","city_name":"Aurangabad","state_id":"38"},
    {"city_id":"4572","city_name":"Aurangabad Bangar","state_id":"38"},
    {"city_id":"4573","city_name":"Auras","state_id":"38"},
    {"city_id":"4574","city_name":"Awagarh","state_id":"38"},
    {"city_id":"4575","city_name":"Ayodhya","state_id":"38"},
    {"city_id":"4576","city_name":"Azamgarh","state_id":"38"},
    {"city_id":"4577","city_name":"Azizpur","state_id":"38"},
    {"city_id":"4578","city_name":"Azmatgarh","state_id":"38"},
    {"city_id":"4579","city_name":"Babarpur Ajitmal","state_id":"38"},
    {"city_id":"4580","city_name":"Baberu","state_id":"38"},
    {"city_id":"4581","city_name":"Babina","state_id":"38"},
    {"city_id":"4582","city_name":"Babrala","state_id":"38"},
    {"city_id":"4583","city_name":"Babugarh","state_id":"38"},
    {"city_id":"4584","city_name":"Bachhiowan","state_id":"38"},
    {"city_id":"4585","city_name":"Bachhraon","state_id":"38"},
    {"city_id":"4586","city_name":"Bad","state_id":"38"},
    {"city_id":"4587","city_name":"Badaun","state_id":"38"},
    {"city_id":"4588","city_name":"Baghpat","state_id":"38"},
    {"city_id":"4589","city_name":"Bah","state_id":"38"},
    {"city_id":"4590","city_name":"Bahadurganj","state_id":"38"},
    {"city_id":"4591","city_name":"Baheri","state_id":"38"},
    {"city_id":"4592","city_name":"Bahjoi","state_id":"38"},
    {"city_id":"4593","city_name":"Bahraich","state_id":"38"},
    {"city_id":"4594","city_name":"Bahsuma","state_id":"38"},
    {"city_id":"4595","city_name":"Bahua","state_id":"38"},
    {"city_id":"4596","city_name":"Bajna","state_id":"38"},
    {"city_id":"4597","city_name":"Bakewar","state_id":"38"},
    {"city_id":"4598","city_name":"Bakiabad","state_id":"38"},
    {"city_id":"4599","city_name":"Baldeo","state_id":"38"},
    {"city_id":"4600","city_name":"Ballia","state_id":"38"},
    {"city_id":"4601","city_name":"Balrampur","state_id":"38"},
    {"city_id":"4602","city_name":"Banat","state_id":"38"},
    {"city_id":"4603","city_name":"Banda","state_id":"38"},
    {"city_id":"4604","city_name":"Bangarmau","state_id":"38"},
    {"city_id":"4605","city_name":"Banki","state_id":"38"},
    {"city_id":"4606","city_name":"Bansdih","state_id":"38"},
    {"city_id":"4607","city_name":"Bansgaon","state_id":"38"},
    {"city_id":"4608","city_name":"Bansi","state_id":"38"},
    {"city_id":"4609","city_name":"Barabanki","state_id":"38"},
    {"city_id":"4610","city_name":"Baragaon","state_id":"38"},
    {"city_id":"4611","city_name":"Baraut","state_id":"38"},
    {"city_id":"4612","city_name":"Bareilly","state_id":"38"},
    {"city_id":"4613","city_name":"Bareilly Cantonment","state_id":"38"},
    {"city_id":"4614","city_name":"Barhalganj","state_id":"38"},
    {"city_id":"4615","city_name":"Barhani","state_id":"38"},
    {"city_id":"4616","city_name":"Barhapur","state_id":"38"},
    {"city_id":"4617","city_name":"Barkhera","state_id":"38"},
    {"city_id":"4618","city_name":"Barsana","state_id":"38"},
    {"city_id":"4619","city_name":"Barva Sagar","state_id":"38"},
    {"city_id":"4620","city_name":"Barwar","state_id":"38"},
    {"city_id":"4621","city_name":"Basti","state_id":"38"},
    {"city_id":"4622","city_name":"Begumabad Budhana","state_id":"38"},
    {"city_id":"4623","city_name":"Behat","state_id":"38"},
    {"city_id":"4624","city_name":"Behta Hajipur","state_id":"38"},
    {"city_id":"4625","city_name":"Bela","state_id":"38"},
    {"city_id":"4626","city_name":"Belthara","state_id":"38"},
    {"city_id":"4627","city_name":"Beniganj","state_id":"38"},
    {"city_id":"4628","city_name":"Beswan","state_id":"38"},
    {"city_id":"4629","city_name":"Bewar","state_id":"38"},
    {"city_id":"4630","city_name":"Bhadarsa","state_id":"38"},
    {"city_id":"4631","city_name":"Bhadohi","state_id":"38"},
    {"city_id":"4632","city_name":"Bhagwantnagar","state_id":"38"},
    {"city_id":"4633","city_name":"Bharatganj","state_id":"38"},
    {"city_id":"4634","city_name":"Bhargain","state_id":"38"},
    {"city_id":"4635","city_name":"Bharthana","state_id":"38"},
    {"city_id":"4636","city_name":"Bharuhana","state_id":"38"},
    {"city_id":"4637","city_name":"Bharwari","state_id":"38"},
    {"city_id":"4638","city_name":"Bhatni Bazar","state_id":"38"},
    {"city_id":"4639","city_name":"Bhatpar Rani","state_id":"38"},
    {"city_id":"4640","city_name":"Bhawan Bahadurnagar","state_id":"38"},
    {"city_id":"4641","city_name":"Bhinga","state_id":"38"},
    {"city_id":"4642","city_name":"Bhojpur Dharampur","state_id":"38"},
    {"city_id":"4643","city_name":"Bhokarhedi","state_id":"38"},
    {"city_id":"4644","city_name":"Bhongaon","state_id":"38"},
    {"city_id":"4645","city_name":"Bhulepur","state_id":"38"},
    {"city_id":"4646","city_name":"Bidhuna","state_id":"38"},
    {"city_id":"4647","city_name":"Bighapur","state_id":"38"},
    {"city_id":"4648","city_name":"Bijnor","state_id":"38"},
    {"city_id":"4649","city_name":"Bijpur","state_id":"38"},
    {"city_id":"4650","city_name":"Bikapur","state_id":"38"},
    {"city_id":"4651","city_name":"Bilari","state_id":"38"},
    {"city_id":"4652","city_name":"Bilaspur","state_id":"38"},
    {"city_id":"4653","city_name":"Bilgram","state_id":"38"},
    {"city_id":"4654","city_name":"Bilhaur","state_id":"38"},
    {"city_id":"4655","city_name":"Bilram","state_id":"38"},
    {"city_id":"4656","city_name":"Bilrayaganj","state_id":"38"},
    {"city_id":"4657","city_name":"Bilsanda","state_id":"38"},
    {"city_id":"4658","city_name":"Bilsi","state_id":"38"},
    {"city_id":"4659","city_name":"Bindki","state_id":"38"},
    {"city_id":"4660","city_name":"Bisalpur","state_id":"38"},
    {"city_id":"4661","city_name":"Bisanda Buzurg","state_id":"38"},
    {"city_id":"4662","city_name":"Bisauli","state_id":"38"},
    {"city_id":"4663","city_name":"Bisharatganj","state_id":"38"},
    {"city_id":"4664","city_name":"Bisokhar","state_id":"38"},
    {"city_id":"4665","city_name":"Biswan","state_id":"38"},
    {"city_id":"4666","city_name":"Bithur","state_id":"38"},
    {"city_id":"4667","city_name":"Budaun","state_id":"38"},
    {"city_id":"4668","city_name":"Bugrasi","state_id":"38"},
    {"city_id":"4669","city_name":"Bulandshahar","state_id":"38"},
    {"city_id":"4670","city_name":"Burhana","state_id":"38"},
    {"city_id":"4671","city_name":"Chail","state_id":"38"},
    {"city_id":"4672","city_name":"Chak Imam Ali","state_id":"38"},
    {"city_id":"4673","city_name":"Chakeri","state_id":"38"},
    {"city_id":"4674","city_name":"Chakia","state_id":"38"},
    {"city_id":"4675","city_name":"Chandauli","state_id":"38"},
    {"city_id":"4676","city_name":"Chandausi","state_id":"38"},
    {"city_id":"4677","city_name":"Chandpur","state_id":"38"},
    {"city_id":"4678","city_name":"Charkhari","state_id":"38"},
    {"city_id":"4679","city_name":"Charthawal","state_id":"38"},
    {"city_id":"4680","city_name":"Chaumuhan","state_id":"38"},
    {"city_id":"4681","city_name":"Chhaprauli","state_id":"38"},
    {"city_id":"4682","city_name":"Chhara Rafatpur","state_id":"38"},
    {"city_id":"4683","city_name":"Chharprauli","state_id":"38"},
    {"city_id":"4684","city_name":"Chhata","state_id":"38"},
    {"city_id":"4685","city_name":"Chhatari","state_id":"38"},
    {"city_id":"4686","city_name":"Chhibramau","state_id":"38"},
    {"city_id":"4687","city_name":"Chhutmalpur","state_id":"38"},
    {"city_id":"4688","city_name":"Chilkana Sultanpur","state_id":"38"},
    {"city_id":"4689","city_name":"Chirgaon","state_id":"38"},
    {"city_id":"4690","city_name":"Chit Baragaon","state_id":"38"},
    {"city_id":"4691","city_name":"Chitrakut Dham","state_id":"38"},
    {"city_id":"4692","city_name":"Chopan","state_id":"38"},
    {"city_id":"4693","city_name":"Choubepur Kalan","state_id":"38"},
    {"city_id":"4694","city_name":"Chunar","state_id":"38"},
    {"city_id":"4695","city_name":"Churk Ghurma","state_id":"38"},
    {"city_id":"4696","city_name":"Colonelganj","state_id":"38"},
    {"city_id":"4697","city_name":"Dadri","state_id":"38"},
    {"city_id":"4698","city_name":"Dalmau","state_id":"38"},
    {"city_id":"4699","city_name":"Dankaur","state_id":"38"},
    {"city_id":"4700","city_name":"Dariyabad","state_id":"38"},
    {"city_id":"4701","city_name":"Dasna","state_id":"38"},
    {"city_id":"4702","city_name":"Dataganj","state_id":"38"},
    {"city_id":"4703","city_name":"Daurala","state_id":"38"},
    {"city_id":"4704","city_name":"Dayal Bagh","state_id":"38"},
    {"city_id":"4705","city_name":"Deoband","state_id":"38"},
    {"city_id":"4706","city_name":"Deoranian","state_id":"38"},
    {"city_id":"4707","city_name":"Deoria","state_id":"38"},
    {"city_id":"4708","city_name":"Dewa","state_id":"38"},
    {"city_id":"4709","city_name":"Dhampur","state_id":"38"},
    {"city_id":"4710","city_name":"Dhanauha","state_id":"38"},
    {"city_id":"4711","city_name":"Dhanauli","state_id":"38"},
    {"city_id":"4712","city_name":"Dhanaura","state_id":"38"},
    {"city_id":"4713","city_name":"Dharoti Khurd","state_id":"38"},
    {"city_id":"4714","city_name":"Dhauratanda","state_id":"38"},
    {"city_id":"4715","city_name":"Dhaurhra","state_id":"38"},
    {"city_id":"4716","city_name":"Dibai","state_id":"38"},
    {"city_id":"4717","city_name":"Dibiyapur","state_id":"38"},
    {"city_id":"4718","city_name":"Dildarnagar Fatehpur","state_id":"38"},
    {"city_id":"4719","city_name":"Do Ghat","state_id":"38"},
    {"city_id":"4720","city_name":"Dohrighat","state_id":"38"},
    {"city_id":"4721","city_name":"Dostpur","state_id":"38"},
    {"city_id":"4722","city_name":"Dudhinagar","state_id":"38"},
    {"city_id":"4723","city_name":"Dulhipur","state_id":"38"},
    {"city_id":"4724","city_name":"Dundwaraganj","state_id":"38"},
    {"city_id":"4725","city_name":"Ekdil","state_id":"38"},
    {"city_id":"4726","city_name":"Erich","state_id":"38"},
    {"city_id":"4727","city_name":"Etah","state_id":"38"},
    {"city_id":"4728","city_name":"Etawah","state_id":"38"},
    {"city_id":"4729","city_name":"Faizabad","state_id":"38"},
    {"city_id":"4730","city_name":"Faizabad Cantonment","state_id":"38"},
    {"city_id":"4731","city_name":"Faizganj","state_id":"38"},
    {"city_id":"4732","city_name":"Farah","state_id":"38"},
    {"city_id":"4733","city_name":"Faridnagar","state_id":"38"},
    {"city_id":"4734","city_name":"Faridpur","state_id":"38"},
    {"city_id":"4735","city_name":"Faridpur Cantonment","state_id":"38"},
    {"city_id":"4736","city_name":"Fariha","state_id":"38"},
    {"city_id":"4737","city_name":"Farrukhabad","state_id":"38"},
    {"city_id":"4738","city_name":"Fatehabad","state_id":"38"},
    {"city_id":"4739","city_name":"Fatehganj Pashchimi","state_id":"38"},
    {"city_id":"4740","city_name":"Fatehganj Purvi","state_id":"38"},
    {"city_id":"4741","city_name":"Fatehgarh","state_id":"38"},
    {"city_id":"4742","city_name":"Fatehpur","state_id":"38"},
    {"city_id":"4743","city_name":"Fatehpur Chaurasi","state_id":"38"},
    {"city_id":"4744","city_name":"Fatehpur Sikri","state_id":"38"},
    {"city_id":"4745","city_name":"Firozabad","state_id":"38"},
    {"city_id":"4746","city_name":"Gajraula","state_id":"38"},
    {"city_id":"4747","city_name":"Ganga Ghat","state_id":"38"},
    {"city_id":"4748","city_name":"Gangapur","state_id":"38"},
    {"city_id":"4749","city_name":"Gangoh","state_id":"38"},
    {"city_id":"4750","city_name":"Ganj Muradabad","state_id":"38"},
    {"city_id":"4751","city_name":"Garautha","state_id":"38"},
    {"city_id":"4752","city_name":"Garhi Pukhta","state_id":"38"},
    {"city_id":"4753","city_name":"Garhmukteshwar","state_id":"38"},
    {"city_id":"4754","city_name":"Gaura Barahaj","state_id":"38"},
    {"city_id":"4755","city_name":"Gauri Bazar","state_id":"38"},
    {"city_id":"4756","city_name":"Gausganj","state_id":"38"},
    {"city_id":"4757","city_name":"Gawan","state_id":"38"},
    {"city_id":"4758","city_name":"Ghatampur","state_id":"38"},
    {"city_id":"4759","city_name":"Ghaziabad","state_id":"38"},
    {"city_id":"4760","city_name":"Ghazipur","state_id":"38"},
    {"city_id":"4761","city_name":"Ghiror","state_id":"38"},
    {"city_id":"4762","city_name":"Ghorawal","state_id":"38"},
    {"city_id":"4763","city_name":"Ghosi","state_id":"38"},
    {"city_id":"4764","city_name":"Ghosia Bazar","state_id":"38"},
    {"city_id":"4765","city_name":"Ghughuli","state_id":"38"},
    {"city_id":"4766","city_name":"Gohand","state_id":"38"},
    {"city_id":"4767","city_name":"Gokul","state_id":"38"},
    {"city_id":"4768","city_name":"Gola Bazar","state_id":"38"},
    {"city_id":"4769","city_name":"Gola Gokarannath","state_id":"38"},
    {"city_id":"4770","city_name":"Gonda","state_id":"38"},
    {"city_id":"4771","city_name":"Gopamau","state_id":"38"},
    {"city_id":"4772","city_name":"Gopiganj","state_id":"38"},
    {"city_id":"4773","city_name":"Gorakhpur","state_id":"38"},
    {"city_id":"4774","city_name":"Gosainganj","state_id":"38"},
    {"city_id":"4775","city_name":"Govardhan","state_id":"38"},
    {"city_id":"4776","city_name":"Greater Noida","state_id":"38"},
    {"city_id":"4777","city_name":"Gulaothi","state_id":"38"},
    {"city_id":"4778","city_name":"Gulariya","state_id":"38"},
    {"city_id":"4779","city_name":"Gulariya Bhindara","state_id":"38"},
    {"city_id":"4780","city_name":"Gunnaur","state_id":"38"},
    {"city_id":"4781","city_name":"Gursahaiganj","state_id":"38"},
    {"city_id":"4782","city_name":"Gursarai","state_id":"38"},
    {"city_id":"4783","city_name":"Gyanpur","state_id":"38"},
    {"city_id":"4784","city_name":"Hafizpur","state_id":"38"},
    {"city_id":"4785","city_name":"Haidergarh","state_id":"38"},
    {"city_id":"4786","city_name":"Haldaur","state_id":"38"},
    {"city_id":"4787","city_name":"Hamirpur","state_id":"38"},
    {"city_id":"4788","city_name":"Handia","state_id":"38"},
    {"city_id":"4789","city_name":"Hapur","state_id":"38"},
    {"city_id":"4790","city_name":"Hardoi","state_id":"38"},
    {"city_id":"4791","city_name":"Harduaganj","state_id":"38"},
    {"city_id":"4792","city_name":"Hargaon","state_id":"38"},
    {"city_id":"4793","city_name":"Hariharpur","state_id":"38"},
    {"city_id":"4794","city_name":"Harraiya","state_id":"38"},
    {"city_id":"4795","city_name":"Hasanpur","state_id":"38"},
    {"city_id":"4796","city_name":"Hasayan","state_id":"38"},
    {"city_id":"4797","city_name":"Hastinapur","state_id":"38"},
    {"city_id":"4798","city_name":"Hata","state_id":"38"},
    {"city_id":"4799","city_name":"Hathras","state_id":"38"},
    {"city_id":"4800","city_name":"Hyderabad","state_id":"38"},
    {"city_id":"4801","city_name":"Ibrahimpur","state_id":"38"},
    {"city_id":"4802","city_name":"Iglas","state_id":"38"},
    {"city_id":"4803","city_name":"Ikauna","state_id":"38"},
    {"city_id":"4804","city_name":"Iltifatganj Bazar","state_id":"38"},
    {"city_id":"4805","city_name":"Indian Telephone Industry Mank","state_id":"38"},
    {"city_id":"4806","city_name":"Islamnagar","state_id":"38"},
    {"city_id":"4807","city_name":"Itaunja","state_id":"38"},
    {"city_id":"4808","city_name":"Itimadpur","state_id":"38"},
    {"city_id":"4809","city_name":"Jagner","state_id":"38"},
    {"city_id":"4810","city_name":"Jahanabad","state_id":"38"},
    {"city_id":"4811","city_name":"Jahangirabad","state_id":"38"},
    {"city_id":"4812","city_name":"Jahangirpur","state_id":"38"},
    {"city_id":"4813","city_name":"Jais","state_id":"38"},
    {"city_id":"4814","city_name":"Jaithara","state_id":"38"},
    {"city_id":"4815","city_name":"Jalalabad","state_id":"38"},
    {"city_id":"4816","city_name":"Jalali","state_id":"38"},
    {"city_id":"4817","city_name":"Jalalpur","state_id":"38"},
    {"city_id":"4818","city_name":"Jalaun","state_id":"38"},
    {"city_id":"4819","city_name":"Jalesar","state_id":"38"},
    {"city_id":"4820","city_name":"Jamshila","state_id":"38"},
    {"city_id":"4821","city_name":"Jangipur","state_id":"38"},
    {"city_id":"4822","city_name":"Jansath","state_id":"38"},
    {"city_id":"4823","city_name":"Jarwal","state_id":"38"},
    {"city_id":"4824","city_name":"Jasrana","state_id":"38"},
    {"city_id":"4825","city_name":"Jaswantnagar","state_id":"38"},
    {"city_id":"4826","city_name":"Jatari","state_id":"38"},
    {"city_id":"4827","city_name":"Jaunpur","state_id":"38"},
    {"city_id":"4828","city_name":"Jewar","state_id":"38"},
    {"city_id":"4829","city_name":"Jhalu","state_id":"38"},
    {"city_id":"4830","city_name":"Jhansi","state_id":"38"},
    {"city_id":"4831","city_name":"Jhansi Cantonment","state_id":"38"},
    {"city_id":"4832","city_name":"Jhansi Railway Settlement","state_id":"38"},
    {"city_id":"4833","city_name":"Jhinjhak","state_id":"38"},
    {"city_id":"4834","city_name":"Jhinjhana","state_id":"38"},
    {"city_id":"4835","city_name":"Jhusi","state_id":"38"},
    {"city_id":"4836","city_name":"Jhusi Kohna","state_id":"38"},
    {"city_id":"4837","city_name":"Jiyanpur","state_id":"38"},
    {"city_id":"4838","city_name":"Joya","state_id":"38"},
    {"city_id":"4839","city_name":"Jyoti Khuria","state_id":"38"},
    {"city_id":"4840","city_name":"Jyotiba Phule Nagar","state_id":"38"},
    {"city_id":"4841","city_name":"Kabrai","state_id":"38"},
    {"city_id":"4842","city_name":"Kachhauna Patseni","state_id":"38"},
    {"city_id":"4843","city_name":"Kachhla","state_id":"38"},
    {"city_id":"4844","city_name":"Kachhwa","state_id":"38"},
    {"city_id":"4845","city_name":"Kadaura","state_id":"38"},
    {"city_id":"4846","city_name":"Kadipur","state_id":"38"},
    {"city_id":"4847","city_name":"Kailashpur","state_id":"38"},
    {"city_id":"4848","city_name":"Kaimganj","state_id":"38"},
    {"city_id":"4849","city_name":"Kairana","state_id":"38"},
    {"city_id":"4850","city_name":"Kakgaina","state_id":"38"},
    {"city_id":"4851","city_name":"Kakod","state_id":"38"},
    {"city_id":"4852","city_name":"Kakori","state_id":"38"},
    {"city_id":"4853","city_name":"Kakrala","state_id":"38"},
    {"city_id":"4854","city_name":"Kalinagar","state_id":"38"},
    {"city_id":"4855","city_name":"Kalpi","state_id":"38"},
    {"city_id":"4856","city_name":"Kamalganj","state_id":"38"},
    {"city_id":"4857","city_name":"Kampil","state_id":"38"},
    {"city_id":"4858","city_name":"Kandhla","state_id":"38"},
    {"city_id":"4859","city_name":"Kandwa","state_id":"38"},
    {"city_id":"4860","city_name":"Kannauj","state_id":"38"},
    {"city_id":"4861","city_name":"Kanpur","state_id":"38"},
    {"city_id":"4862","city_name":"Kant","state_id":"38"},
    {"city_id":"4863","city_name":"Kanth","state_id":"38"},
    {"city_id":"4864","city_name":"Kaptanganj","state_id":"38"},
    {"city_id":"4865","city_name":"Karaon","state_id":"38"},
    {"city_id":"4866","city_name":"Karari","state_id":"38"},
    {"city_id":"4867","city_name":"Karhal","state_id":"38"},
    {"city_id":"4868","city_name":"Karnawal","state_id":"38"},
    {"city_id":"4869","city_name":"Kasganj","state_id":"38"},
    {"city_id":"4870","city_name":"Katariya","state_id":"38"},
    {"city_id":"4871","city_name":"Katghar Lalganj","state_id":"38"},
    {"city_id":"4872","city_name":"Kathera","state_id":"38"},
    {"city_id":"4873","city_name":"Katra","state_id":"38"},
    {"city_id":"4874","city_name":"Katra Medniganj","state_id":"38"},
    {"city_id":"4875","city_name":"Kauriaganj","state_id":"38"},
    {"city_id":"4876","city_name":"Kemri","state_id":"38"},
    {"city_id":"4877","city_name":"Kerakat","state_id":"38"},
    {"city_id":"4878","city_name":"Khadda","state_id":"38"},
    {"city_id":"4879","city_name":"Khaga","state_id":"38"},
    {"city_id":"4880","city_name":"Khailar","state_id":"38"},
    {"city_id":"4881","city_name":"Khair","state_id":"38"},
    {"city_id":"4882","city_name":"Khairabad","state_id":"38"},
    {"city_id":"4883","city_name":"Khairagarh","state_id":"38"},
    {"city_id":"4884","city_name":"Khalilabad","state_id":"38"},
    {"city_id":"4885","city_name":"Khamaria","state_id":"38"},
    {"city_id":"4886","city_name":"Khanpur","state_id":"38"},
    {"city_id":"4887","city_name":"Kharela","state_id":"38"},
    {"city_id":"4888","city_name":"Khargupur","state_id":"38"},
    {"city_id":"4889","city_name":"Khariya","state_id":"38"},
    {"city_id":"4890","city_name":"Kharkhoda","state_id":"38"},
    {"city_id":"4891","city_name":"Khatauli","state_id":"38"},
    {"city_id":"4892","city_name":"Khatauli Rural","state_id":"38"},
    {"city_id":"4893","city_name":"Khekra","state_id":"38"},
    {"city_id":"4894","city_name":"Kheri","state_id":"38"},
    {"city_id":"4895","city_name":"Kheta Sarai","state_id":"38"},
    {"city_id":"4896","city_name":"Khudaganj","state_id":"38"},
    {"city_id":"4897","city_name":"Khurja","state_id":"38"},
    {"city_id":"4898","city_name":"Khutar","state_id":"38"},
    {"city_id":"4899","city_name":"Kiraoli","state_id":"38"},
    {"city_id":"4900","city_name":"Kiratpur","state_id":"38"},
    {"city_id":"4901","city_name":"Kishanpur","state_id":"38"},
    {"city_id":"4902","city_name":"Kishni","state_id":"38"},
    {"city_id":"4903","city_name":"Kithaur","state_id":"38"},
    {"city_id":"4904","city_name":"Koiripur","state_id":"38"},
    {"city_id":"4905","city_name":"Konch","state_id":"38"},
    {"city_id":"4906","city_name":"Kopaganj","state_id":"38"},
    {"city_id":"4907","city_name":"Kora Jahanabad","state_id":"38"},
    {"city_id":"4908","city_name":"Korwa","state_id":"38"},
    {"city_id":"4909","city_name":"Kosi Kalan","state_id":"38"},
    {"city_id":"4910","city_name":"Kota","state_id":"38"},
    {"city_id":"4911","city_name":"Kotra","state_id":"38"},
    {"city_id":"4912","city_name":"Kotwa","state_id":"38"},
    {"city_id":"4913","city_name":"Kulpahar","state_id":"38"},
    {"city_id":"4914","city_name":"Kunda","state_id":"38"},
    {"city_id":"4915","city_name":"Kundarki","state_id":"38"},
    {"city_id":"4916","city_name":"Kunwargaon","state_id":"38"},
    {"city_id":"4917","city_name":"Kurara","state_id":"38"},
    {"city_id":"4918","city_name":"Kurawali","state_id":"38"},
    {"city_id":"4919","city_name":"Kursath","state_id":"38"},
    {"city_id":"4920","city_name":"Kurthi Jafarpur","state_id":"38"},
    {"city_id":"4921","city_name":"Kushinagar","state_id":"38"},
    {"city_id":"4922","city_name":"Kusmara","state_id":"38"},
    {"city_id":"4923","city_name":"Laharpur","state_id":"38"},
    {"city_id":"4924","city_name":"Lakhimpur","state_id":"38"},
    {"city_id":"4925","city_name":"Lakhna","state_id":"38"},
    {"city_id":"4926","city_name":"Lalganj","state_id":"38"},
    {"city_id":"4927","city_name":"Lalitpur","state_id":"38"},
    {"city_id":"4928","city_name":"Lar","state_id":"38"},
    {"city_id":"4929","city_name":"Lawar","state_id":"38"},
    {"city_id":"4930","city_name":"Ledwa Mahuwa","state_id":"38"},
    {"city_id":"4931","city_name":"Lohta","state_id":"38"},
    {"city_id":"4932","city_name":"Loni","state_id":"38"},
    {"city_id":"4933","city_name":"Lucknow","state_id":"38"},
    {"city_id":"4934","city_name":"Machhlishahr","state_id":"38"},
    {"city_id":"4935","city_name":"Madhoganj","state_id":"38"},
    {"city_id":"4936","city_name":"Madhogarh","state_id":"38"},
    {"city_id":"4937","city_name":"Maghar","state_id":"38"},
    {"city_id":"4938","city_name":"Mahaban","state_id":"38"},
    {"city_id":"4939","city_name":"Maharajganj","state_id":"38"},
    {"city_id":"4940","city_name":"Mahmudabad","state_id":"38"},
    {"city_id":"4941","city_name":"Mahoba","state_id":"38"},
    {"city_id":"4942","city_name":"Maholi","state_id":"38"},
    {"city_id":"4943","city_name":"Mahona","state_id":"38"},
    {"city_id":"4944","city_name":"Mahroni","state_id":"38"},
    {"city_id":"4945","city_name":"Mailani","state_id":"38"},
    {"city_id":"4946","city_name":"Mainpuri","state_id":"38"},
    {"city_id":"4947","city_name":"Majhara Pipar Ehatmali","state_id":"38"},
    {"city_id":"4948","city_name":"Majhauli Raj","state_id":"38"},
    {"city_id":"4949","city_name":"Malihabad","state_id":"38"},
    {"city_id":"4950","city_name":"Mallanwam","state_id":"38"},
    {"city_id":"4951","city_name":"Mandawar","state_id":"38"},
    {"city_id":"4952","city_name":"Manikpur","state_id":"38"},
    {"city_id":"4953","city_name":"Maniyar","state_id":"38"},
    {"city_id":"4954","city_name":"Manjhanpur","state_id":"38"},
    {"city_id":"4955","city_name":"Mankapur","state_id":"38"},
    {"city_id":"4956","city_name":"Marehra","state_id":"38"},
    {"city_id":"4957","city_name":"Mariahu","state_id":"38"},
    {"city_id":"4958","city_name":"Maruadih","state_id":"38"},
    {"city_id":"4959","city_name":"Maswasi","state_id":"38"},
    {"city_id":"4960","city_name":"Mataundh","state_id":"38"},
    {"city_id":"4961","city_name":"Mathu","state_id":"38"},
    {"city_id":"4962","city_name":"Mathura","state_id":"38"},
    {"city_id":"4963","city_name":"Mathura Cantonment","state_id":"38"},
    {"city_id":"4964","city_name":"Mau","state_id":"38"},
    {"city_id":"4965","city_name":"Mau Aima","state_id":"38"},
    {"city_id":"4966","city_name":"Maudaha","state_id":"38"},
    {"city_id":"4967","city_name":"Mauranipur","state_id":"38"},
    {"city_id":"4968","city_name":"Maurawan","state_id":"38"},
    {"city_id":"4969","city_name":"Mawana","state_id":"38"},
    {"city_id":"4970","city_name":"Meerut","state_id":"38"},
    {"city_id":"4971","city_name":"Mehnagar","state_id":"38"},
    {"city_id":"4972","city_name":"Mehndawal","state_id":"38"},
    {"city_id":"4973","city_name":"Mendu","state_id":"38"},
    {"city_id":"4974","city_name":"Milak","state_id":"38"},
    {"city_id":"4975","city_name":"Miranpur","state_id":"38"},
    {"city_id":"4976","city_name":"Mirat","state_id":"38"},
    {"city_id":"4977","city_name":"Mirat Cantonment","state_id":"38"},
    {"city_id":"4978","city_name":"Mirganj","state_id":"38"},
    {"city_id":"4979","city_name":"Mirzapur","state_id":"38"},
    {"city_id":"4980","city_name":"Misrikh","state_id":"38"},
    {"city_id":"4981","city_name":"Modinagar","state_id":"38"},
    {"city_id":"4982","city_name":"Mogra Badshahpur","state_id":"38"},
    {"city_id":"4983","city_name":"Mohan","state_id":"38"},
    {"city_id":"4984","city_name":"Mohanpur","state_id":"38"},
    {"city_id":"4985","city_name":"Mohiuddinpur","state_id":"38"},
    {"city_id":"4986","city_name":"Moradabad","state_id":"38"},
    {"city_id":"4987","city_name":"Moth","state_id":"38"},
    {"city_id":"4988","city_name":"Mubarakpur","state_id":"38"},
    {"city_id":"4989","city_name":"Mughal Sarai","state_id":"38"},
    {"city_id":"4990","city_name":"Mughal Sarai Railway Settlemen","state_id":"38"},
    {"city_id":"4991","city_name":"Muhammadabad","state_id":"38"},
    {"city_id":"4992","city_name":"Muhammadi","state_id":"38"},
    {"city_id":"4993","city_name":"Mukrampur Khema","state_id":"38"},
    {"city_id":"4994","city_name":"Mundia","state_id":"38"},
    {"city_id":"4995","city_name":"Mundora","state_id":"38"},
    {"city_id":"4996","city_name":"Muradnagar","state_id":"38"},
    {"city_id":"4997","city_name":"Mursan","state_id":"38"},
    {"city_id":"4998","city_name":"Musafirkhana","state_id":"38"},
    {"city_id":"4999","city_name":"Muzaffarnagar","state_id":"38"},
    {"city_id":"5000","city_name":"Nadigaon","state_id":"38"},
    {"city_id":"5001","city_name":"Nagina","state_id":"38"},
    {"city_id":"5002","city_name":"Nagram","state_id":"38"},
    {"city_id":"5003","city_name":"Nai Bazar","state_id":"38"},
    {"city_id":"5004","city_name":"Nainana Jat","state_id":"38"},
    {"city_id":"5005","city_name":"Najibabad","state_id":"38"},
    {"city_id":"5006","city_name":"Nakur","state_id":"38"},
    {"city_id":"5007","city_name":"Nanaunta","state_id":"38"},
    {"city_id":"5008","city_name":"Nandgaon","state_id":"38"},
    {"city_id":"5009","city_name":"Nanpara","state_id":"38"},
    {"city_id":"5010","city_name":"Naraini","state_id":"38"},
    {"city_id":"5011","city_name":"Narauli","state_id":"38"},
    {"city_id":"5012","city_name":"Naraura","state_id":"38"},
    {"city_id":"5013","city_name":"Naugawan Sadat","state_id":"38"},
    {"city_id":"5014","city_name":"Nautanwa","state_id":"38"},
    {"city_id":"5015","city_name":"Nawabganj","state_id":"38"},
    {"city_id":"5016","city_name":"Nichlaul","state_id":"38"},
    {"city_id":"5017","city_name":"Nidhauli Kalan","state_id":"38"},
    {"city_id":"5018","city_name":"Nihtaur","state_id":"38"},
    {"city_id":"5019","city_name":"Nindaura","state_id":"38"},
    {"city_id":"5020","city_name":"Niwari","state_id":"38"},
    {"city_id":"5021","city_name":"Nizamabad","state_id":"38"},
    {"city_id":"5022","city_name":"Noida","state_id":"38"},
    {"city_id":"5023","city_name":"Northern Railway Colony","state_id":"38"},
    {"city_id":"5024","city_name":"Nurpur","state_id":"38"},
    {"city_id":"5025","city_name":"Nyoria Husenpur","state_id":"38"},
    {"city_id":"5026","city_name":"Nyotini","state_id":"38"},
    {"city_id":"5027","city_name":"Obra","state_id":"38"},
    {"city_id":"5028","city_name":"Oel Dhakwa","state_id":"38"},
    {"city_id":"5029","city_name":"Orai","state_id":"38"},
    {"city_id":"5030","city_name":"Oran","state_id":"38"},
    {"city_id":"5031","city_name":"Ordinance Factory Muradnagar","state_id":"38"},
    {"city_id":"5032","city_name":"Pachperwa","state_id":"38"},
    {"city_id":"5033","city_name":"Padrauna","state_id":"38"},
    {"city_id":"5034","city_name":"Pahasu","state_id":"38"},
    {"city_id":"5035","city_name":"Paintepur","state_id":"38"},
    {"city_id":"5036","city_name":"Pali","state_id":"38"},
    {"city_id":"5037","city_name":"Palia Kalan","state_id":"38"},
    {"city_id":"5038","city_name":"Parasi","state_id":"38"},
    {"city_id":"5039","city_name":"Parichha","state_id":"38"},
    {"city_id":"5040","city_name":"Parichhatgarh","state_id":"38"},
    {"city_id":"5041","city_name":"Parsadepur","state_id":"38"},
    {"city_id":"5042","city_name":"Patala","state_id":"38"},
    {"city_id":"5043","city_name":"Patiyali","state_id":"38"},
    {"city_id":"5044","city_name":"Patti","state_id":"38"},
    {"city_id":"5045","city_name":"Pawayan","state_id":"38"},
    {"city_id":"5046","city_name":"Phalauda","state_id":"38"},
    {"city_id":"5047","city_name":"Phaphund","state_id":"38"},
    {"city_id":"5048","city_name":"Phulpur","state_id":"38"},
    {"city_id":"5049","city_name":"Phulwaria","state_id":"38"},
    {"city_id":"5050","city_name":"Pihani","state_id":"38"},
    {"city_id":"5051","city_name":"Pilibhit","state_id":"38"},
    {"city_id":"5052","city_name":"Pilkana","state_id":"38"},
    {"city_id":"5053","city_name":"Pilkhuwa","state_id":"38"},
    {"city_id":"5054","city_name":"Pinahat","state_id":"38"},
    {"city_id":"5055","city_name":"Pipalsana Chaudhari","state_id":"38"},
    {"city_id":"5056","city_name":"Pipiganj","state_id":"38"},
    {"city_id":"5057","city_name":"Pipraich","state_id":"38"},
    {"city_id":"5058","city_name":"Pipri","state_id":"38"},
    {"city_id":"5059","city_name":"Pratapgarh","state_id":"38"},
    {"city_id":"5060","city_name":"Pukhrayan","state_id":"38"},
    {"city_id":"5061","city_name":"Puranpur","state_id":"38"},
    {"city_id":"5062","city_name":"Purdil Nagar","state_id":"38"},
    {"city_id":"5063","city_name":"Purqazi","state_id":"38"},
    {"city_id":"5064","city_name":"Purwa","state_id":"38"},
    {"city_id":"5065","city_name":"Qasimpur","state_id":"38"},
    {"city_id":"5066","city_name":"Rabupura","state_id":"38"},
    {"city_id":"5067","city_name":"Radha Kund","state_id":"38"},
    {"city_id":"5068","city_name":"Rae Bareilly","state_id":"38"},
    {"city_id":"5069","city_name":"Raja Ka Rampur","state_id":"38"},
    {"city_id":"5070","city_name":"Rajapur","state_id":"38"},
    {"city_id":"5071","city_name":"Ramkola","state_id":"38"},
    {"city_id":"5072","city_name":"Ramnagar","state_id":"38"},
    {"city_id":"5073","city_name":"Rampur","state_id":"38"},
    {"city_id":"5074","city_name":"Rampur Bhawanipur","state_id":"38"},
    {"city_id":"5075","city_name":"Rampur Karkhana","state_id":"38"},
    {"city_id":"5076","city_name":"Rampur Maniharan","state_id":"38"},
    {"city_id":"5077","city_name":"Rampura","state_id":"38"},
    {"city_id":"5078","city_name":"Ranipur","state_id":"38"},
    {"city_id":"5079","city_name":"Rashidpur Garhi","state_id":"38"},
    {"city_id":"5080","city_name":"Rasra","state_id":"38"},
    {"city_id":"5081","city_name":"Rasulabad","state_id":"38"},
    {"city_id":"5082","city_name":"Rath","state_id":"38"},
    {"city_id":"5083","city_name":"Raya","state_id":"38"},
    {"city_id":"5084","city_name":"Renukut","state_id":"38"},
    {"city_id":"5085","city_name":"Reoti","state_id":"38"},
    {"city_id":"5086","city_name":"Richha","state_id":"38"},
    {"city_id":"5087","city_name":"Risia Bazar","state_id":"38"},
    {"city_id":"5088","city_name":"Rithora","state_id":"38"},
    {"city_id":"5089","city_name":"Robertsganj","state_id":"38"},
    {"city_id":"5090","city_name":"Roza","state_id":"38"},
    {"city_id":"5091","city_name":"Rudarpur","state_id":"38"},
    {"city_id":"5092","city_name":"Rudauli","state_id":"38"},
    {"city_id":"5093","city_name":"Rudayan","state_id":"38"},
    {"city_id":"5094","city_name":"Rura","state_id":"38"},
    {"city_id":"5095","city_name":"Rustamnagar Sahaspur","state_id":"38"},
    {"city_id":"5096","city_name":"Sabatwar","state_id":"38"},
    {"city_id":"5097","city_name":"Sadabad","state_id":"38"},
    {"city_id":"5098","city_name":"Sadat","state_id":"38"},
    {"city_id":"5099","city_name":"Safipur","state_id":"38"},
    {"city_id":"5100","city_name":"Sahanpur","state_id":"38"},
    {"city_id":"5101","city_name":"Saharanpur","state_id":"38"},
    {"city_id":"5102","city_name":"Sahaspur","state_id":"38"},
    {"city_id":"5103","city_name":"Sahaswan","state_id":"38"},
    {"city_id":"5104","city_name":"Sahawar","state_id":"38"},
    {"city_id":"5105","city_name":"Sahibabad","state_id":"38"},
    {"city_id":"5106","city_name":"Sahjanwa","state_id":"38"},
    {"city_id":"5107","city_name":"Sahpau","state_id":"38"},
    {"city_id":"5108","city_name":"Saidpur","state_id":"38"},
    {"city_id":"5109","city_name":"Sainthal","state_id":"38"},
    {"city_id":"5110","city_name":"Saiyadraja","state_id":"38"},
    {"city_id":"5111","city_name":"Sakhanu","state_id":"38"},
    {"city_id":"5112","city_name":"Sakit","state_id":"38"},
    {"city_id":"5113","city_name":"Salarpur Khadar","state_id":"38"},
    {"city_id":"5114","city_name":"Salimpur","state_id":"38"},
    {"city_id":"5115","city_name":"Salon","state_id":"38"},
    {"city_id":"5116","city_name":"Sambhal","state_id":"38"},
    {"city_id":"5117","city_name":"Sambhawali","state_id":"38"},
    {"city_id":"5118","city_name":"Samdhan","state_id":"38"},
    {"city_id":"5119","city_name":"Samthar","state_id":"38"},
    {"city_id":"5120","city_name":"Sandi","state_id":"38"},
    {"city_id":"5121","city_name":"Sandila","state_id":"38"},
    {"city_id":"5122","city_name":"Sarai Mir","state_id":"38"},
    {"city_id":"5123","city_name":"Sarai akil","state_id":"38"},
    {"city_id":"5124","city_name":"Sarauli","state_id":"38"},
    {"city_id":"5125","city_name":"Sardhana","state_id":"38"},
    {"city_id":"5126","city_name":"Sarila","state_id":"38"},
    {"city_id":"5127","city_name":"Sarsawan","state_id":"38"},
    {"city_id":"5128","city_name":"Sasni","state_id":"38"},
    {"city_id":"5129","city_name":"Satrikh","state_id":"38"},
    {"city_id":"5130","city_name":"Saunkh","state_id":"38"},
    {"city_id":"5131","city_name":"Saurikh","state_id":"38"},
    {"city_id":"5132","city_name":"Seohara","state_id":"38"},
    {"city_id":"5133","city_name":"Sewal Khas","state_id":"38"},
    {"city_id":"5134","city_name":"Sewarhi","state_id":"38"},
    {"city_id":"5135","city_name":"Shahabad","state_id":"38"},
    {"city_id":"5136","city_name":"Shahganj","state_id":"38"},
    {"city_id":"5137","city_name":"Shahi","state_id":"38"},
    {"city_id":"5138","city_name":"Shahjahanpur","state_id":"38"},
    {"city_id":"5139","city_name":"Shahjahanpur Cantonment","state_id":"38"},
    {"city_id":"5140","city_name":"Shahpur","state_id":"38"},
    {"city_id":"5141","city_name":"Shamli","state_id":"38"},
    {"city_id":"5142","city_name":"Shamsabad","state_id":"38"},
    {"city_id":"5143","city_name":"Shankargarh","state_id":"38"},
    {"city_id":"5144","city_name":"Shergarh","state_id":"38"},
    {"city_id":"5145","city_name":"Sherkot","state_id":"38"},
    {"city_id":"5146","city_name":"Shikarpur","state_id":"38"},
    {"city_id":"5147","city_name":"Shikohabad","state_id":"38"},
    {"city_id":"5148","city_name":"Shisgarh","state_id":"38"},
    {"city_id":"5149","city_name":"Shivdaspur","state_id":"38"},
    {"city_id":"5150","city_name":"Shivli","state_id":"38"},
    {"city_id":"5151","city_name":"Shivrajpur","state_id":"38"},
    {"city_id":"5152","city_name":"Shohratgarh","state_id":"38"},
    {"city_id":"5153","city_name":"Siddhanur","state_id":"38"},
    {"city_id":"5154","city_name":"Siddharthnagar","state_id":"38"},
    {"city_id":"5155","city_name":"Sidhauli","state_id":"38"},
    {"city_id":"5156","city_name":"Sidhpura","state_id":"38"},
    {"city_id":"5157","city_name":"Sikandarabad","state_id":"38"},
    {"city_id":"5158","city_name":"Sikandarpur","state_id":"38"},
    {"city_id":"5159","city_name":"Sikandra","state_id":"38"},
    {"city_id":"5160","city_name":"Sikandra Rao","state_id":"38"},
    {"city_id":"5161","city_name":"Singahi Bhiraura","state_id":"38"},
    {"city_id":"5162","city_name":"Sirathu","state_id":"38"},
    {"city_id":"5163","city_name":"Sirsa","state_id":"38"},
    {"city_id":"5164","city_name":"Sirsaganj","state_id":"38"},
    {"city_id":"5165","city_name":"Sirsi","state_id":"38"},
    {"city_id":"5166","city_name":"Sisauli","state_id":"38"},
    {"city_id":"5167","city_name":"Siswa Bazar","state_id":"38"},
    {"city_id":"5168","city_name":"Sitapur","state_id":"38"},
    {"city_id":"5169","city_name":"Siyana","state_id":"38"},
    {"city_id":"5170","city_name":"Som","state_id":"38"},
    {"city_id":"5171","city_name":"Sonbhadra","state_id":"38"},
    {"city_id":"5172","city_name":"Soron","state_id":"38"},
    {"city_id":"5173","city_name":"Suar","state_id":"38"},
    {"city_id":"5174","city_name":"Sukhmalpur Nizamabad","state_id":"38"},
    {"city_id":"5175","city_name":"Sultanpur","state_id":"38"},
    {"city_id":"5176","city_name":"Sumerpur","state_id":"38"},
    {"city_id":"5177","city_name":"Suriyawan","state_id":"38"},
    {"city_id":"5178","city_name":"Swamibagh","state_id":"38"},
    {"city_id":"5179","city_name":"Tajpur","state_id":"38"},
    {"city_id":"5180","city_name":"Talbahat","state_id":"38"},
    {"city_id":"5181","city_name":"Talgram","state_id":"38"},
    {"city_id":"5182","city_name":"Tambaur","state_id":"38"},
    {"city_id":"5183","city_name":"Tanda","state_id":"38"},
    {"city_id":"5184","city_name":"Tatarpur Lallu","state_id":"38"},
    {"city_id":"5185","city_name":"Tetribazar","state_id":"38"},
    {"city_id":"5186","city_name":"Thakurdwara","state_id":"38"},
    {"city_id":"5187","city_name":"Thana Bhawan","state_id":"38"},
    {"city_id":"5188","city_name":"Thiriya Nizamat Khan","state_id":"38"},
    {"city_id":"5189","city_name":"Tikaitnagar","state_id":"38"},
    {"city_id":"5190","city_name":"Tikri","state_id":"38"},
    {"city_id":"5191","city_name":"Tilhar","state_id":"38"},
    {"city_id":"5192","city_name":"Tindwari","state_id":"38"},
    {"city_id":"5193","city_name":"Tirwaganj","state_id":"38"},
    {"city_id":"5194","city_name":"Titron","state_id":"38"},
    {"city_id":"5195","city_name":"Tori Fatehpur","state_id":"38"},
    {"city_id":"5196","city_name":"Tulsipur","state_id":"38"},
    {"city_id":"5197","city_name":"Tundla","state_id":"38"},
    {"city_id":"5198","city_name":"Tundla Kham","state_id":"38"},
    {"city_id":"5199","city_name":"Tundla Railway Colony","state_id":"38"},
    {"city_id":"5200","city_name":"Ugu","state_id":"38"},
    {"city_id":"5201","city_name":"Ujhani","state_id":"38"},
    {"city_id":"5202","city_name":"Ujhari","state_id":"38"},
    {"city_id":"5203","city_name":"Umri","state_id":"38"},
    {"city_id":"5204","city_name":"Umri Kalan","state_id":"38"},
    {"city_id":"5205","city_name":"Un","state_id":"38"},
    {"city_id":"5206","city_name":"Unchahar","state_id":"38"},
    {"city_id":"5207","city_name":"Unnao","state_id":"38"},
    {"city_id":"5208","city_name":"Usaihat","state_id":"38"},
    {"city_id":"5209","city_name":"Usawan","state_id":"38"},
    {"city_id":"5210","city_name":"Utraula","state_id":"38"},
    {"city_id":"5211","city_name":"Varanasi","state_id":"38"},
    {"city_id":"5212","city_name":"Varanasi Cantonment","state_id":"38"},
    {"city_id":"5213","city_name":"Vijaigarh","state_id":"38"},
    {"city_id":"5214","city_name":"Vrindavan","state_id":"38"},
    {"city_id":"5215","city_name":"Wazirganj","state_id":"38"},
    {"city_id":"5216","city_name":"Zafarabad","state_id":"38"},
    {"city_id":"5217","city_name":"Zaidpur","state_id":"38"},
    {"city_id":"5218","city_name":"Zamania","state_id":"38"},
    {"city_id":"5219","city_name":"Almora","state_id":"39"},
    {"city_id":"5220","city_name":"Almora Cantonment","state_id":"39"},
    {"city_id":"5221","city_name":"Badrinathpuri","state_id":"39"},
    {"city_id":"5222","city_name":"Bageshwar","state_id":"39"},
    {"city_id":"5223","city_name":"Bah Bazar","state_id":"39"},
    {"city_id":"5224","city_name":"Banbasa","state_id":"39"},
    {"city_id":"5225","city_name":"Bandia","state_id":"39"},
    {"city_id":"5226","city_name":"Barkot","state_id":"39"},
    {"city_id":"5227","city_name":"Bazpur","state_id":"39"},
    {"city_id":"5228","city_name":"Bhim Tal","state_id":"39"},
    {"city_id":"5229","city_name":"Bhowali","state_id":"39"},
    {"city_id":"5230","city_name":"Chakrata","state_id":"39"},
    {"city_id":"5231","city_name":"Chamba","state_id":"39"},
    {"city_id":"5232","city_name":"Chamoli and Gopeshwar","state_id":"39"},
    {"city_id":"5233","city_name":"Champawat","state_id":"39"},
    {"city_id":"5234","city_name":"Clement Town","state_id":"39"},
    {"city_id":"5235","city_name":"Dehra Dun Cantonment","state_id":"39"},
    {"city_id":"5236","city_name":"Dehradun","state_id":"39"},
    {"city_id":"5237","city_name":"Dehrakhas","state_id":"39"},
    {"city_id":"5238","city_name":"Devaprayag","state_id":"39"},
    {"city_id":"5239","city_name":"Dhaluwala","state_id":"39"},
    {"city_id":"5240","city_name":"Dhandera","state_id":"39"},
    {"city_id":"5241","city_name":"Dharchula","state_id":"39"},
    {"city_id":"5242","city_name":"Dharchula Dehat","state_id":"39"},
    {"city_id":"5243","city_name":"Didihat","state_id":"39"},
    {"city_id":"5244","city_name":"Dineshpur","state_id":"39"},
    {"city_id":"5245","city_name":"Doiwala","state_id":"39"},
    {"city_id":"5246","city_name":"Dugadda","state_id":"39"},
    {"city_id":"5247","city_name":"Dwarahat","state_id":"39"},
    {"city_id":"5248","city_name":"Gadarpur","state_id":"39"},
    {"city_id":"5249","city_name":"Gangotri","state_id":"39"},
    {"city_id":"5250","city_name":"Gauchar","state_id":"39"},
    {"city_id":"5251","city_name":"Haldwani","state_id":"39"},
    {"city_id":"5252","city_name":"Haridwar","state_id":"39"},
    {"city_id":"5253","city_name":"Herbertpur","state_id":"39"},
    {"city_id":"5254","city_name":"Jaspur","state_id":"39"},
    {"city_id":"5255","city_name":"Jhabrera","state_id":"39"},
    {"city_id":"5256","city_name":"Joshimath","state_id":"39"},
    {"city_id":"5257","city_name":"Kachnal Gosain","state_id":"39"},
    {"city_id":"5258","city_name":"Kaladungi","state_id":"39"},
    {"city_id":"5259","city_name":"Kalagarh","state_id":"39"},
    {"city_id":"5260","city_name":"Karnaprayang","state_id":"39"},
    {"city_id":"5261","city_name":"Kashipur","state_id":"39"},
    {"city_id":"5262","city_name":"Kashirampur","state_id":"39"},
    {"city_id":"5263","city_name":"Kausani","state_id":"39"},
    {"city_id":"5264","city_name":"Kedarnath","state_id":"39"},
    {"city_id":"5265","city_name":"Kelakhera","state_id":"39"},
    {"city_id":"5266","city_name":"Khatima","state_id":"39"},
    {"city_id":"5267","city_name":"Kichha","state_id":"39"},
    {"city_id":"5268","city_name":"Kirtinagar","state_id":"39"},
    {"city_id":"5269","city_name":"Kotdwara","state_id":"39"},
    {"city_id":"5270","city_name":"Laksar","state_id":"39"},
    {"city_id":"5271","city_name":"Lalkuan","state_id":"39"},
    {"city_id":"5272","city_name":"Landaura","state_id":"39"},
    {"city_id":"5273","city_name":"Landhaura Cantonment","state_id":"39"},
    {"city_id":"5274","city_name":"Lensdaun","state_id":"39"},
    {"city_id":"5275","city_name":"Logahat","state_id":"39"},
    {"city_id":"5276","city_name":"Mahua Dabra Haripura","state_id":"39"},
    {"city_id":"5277","city_name":"Mahua Kheraganj","state_id":"39"},
    {"city_id":"5278","city_name":"Manglaur","state_id":"39"},
    {"city_id":"5279","city_name":"Masuri","state_id":"39"},
    {"city_id":"5280","city_name":"Mohanpur Mohammadpur","state_id":"39"},
    {"city_id":"5281","city_name":"Muni Ki Reti","state_id":"39"},
    {"city_id":"5282","city_name":"Nagla","state_id":"39"},
    {"city_id":"5283","city_name":"Nainital","state_id":"39"},
    {"city_id":"5284","city_name":"Nainital Cantonment","state_id":"39"},
    {"city_id":"5285","city_name":"Nandaprayang","state_id":"39"},
    {"city_id":"5286","city_name":"Narendranagar","state_id":"39"},
    {"city_id":"5287","city_name":"Pauri","state_id":"39"},
    {"city_id":"5288","city_name":"Pithoragarh","state_id":"39"},
    {"city_id":"5289","city_name":"Pratitnagar","state_id":"39"},
    {"city_id":"5290","city_name":"Raipur","state_id":"39"},
    {"city_id":"5291","city_name":"Raiwala","state_id":"39"},
    {"city_id":"5292","city_name":"Ramnagar","state_id":"39"},
    {"city_id":"5293","city_name":"Ranikhet","state_id":"39"},
    {"city_id":"5294","city_name":"Ranipur","state_id":"39"},
    {"city_id":"5295","city_name":"Rishikesh","state_id":"39"},
    {"city_id":"5296","city_name":"Rishikesh Cantonment","state_id":"39"},
    {"city_id":"5297","city_name":"Roorkee","state_id":"39"},
    {"city_id":"5298","city_name":"Rudraprayag","state_id":"39"},
    {"city_id":"5299","city_name":"Rudrapur","state_id":"39"},
    {"city_id":"5300","city_name":"Rurki","state_id":"39"},
    {"city_id":"5301","city_name":"Rurki Cantonment","state_id":"39"},
    {"city_id":"5302","city_name":"Shaktigarh","state_id":"39"},
    {"city_id":"5303","city_name":"Sitarganj","state_id":"39"},
    {"city_id":"5304","city_name":"Srinagar","state_id":"39"},
    {"city_id":"5305","city_name":"Sultanpur","state_id":"39"},
    {"city_id":"5306","city_name":"Tanakpur","state_id":"39"},
    {"city_id":"5307","city_name":"Tehri","state_id":"39"},
    {"city_id":"5308","city_name":"Udham Singh Nagar","state_id":"39"},
    {"city_id":"5309","city_name":"Uttarkashi","state_id":"39"},
    {"city_id":"5310","city_name":"Vikasnagar","state_id":"39"},
    {"city_id":"5311","city_name":"Virbhadra","state_id":"39"},
    {"city_id":"5312","city_name":"24 Parganas (n)","state_id":"41"},
    {"city_id":"5313","city_name":"24 Parganas (s)","state_id":"41"},
    {"city_id":"5314","city_name":"Adra","state_id":"41"},
    {"city_id":"5315","city_name":"Ahmadpur","state_id":"41"},
    {"city_id":"5316","city_name":"Aiho","state_id":"41"},
    {"city_id":"5317","city_name":"Aistala","state_id":"41"},
    {"city_id":"5318","city_name":"Alipur Duar","state_id":"41"},
    {"city_id":"5319","city_name":"Alipur Duar Railway Junction","state_id":"41"},
    {"city_id":"5320","city_name":"Alpur","state_id":"41"},
    {"city_id":"5321","city_name":"Amalhara","state_id":"41"},
    {"city_id":"5322","city_name":"Amkula","state_id":"41"},
    {"city_id":"5323","city_name":"Amlagora","state_id":"41"},
    {"city_id":"5324","city_name":"Amodghata","state_id":"41"},
    {"city_id":"5325","city_name":"Amtala","state_id":"41"},
    {"city_id":"5326","city_name":"Andul","state_id":"41"},
    {"city_id":"5327","city_name":"Anksa","state_id":"41"},
    {"city_id":"5328","city_name":"Ankurhati","state_id":"41"},
    {"city_id":"5329","city_name":"Anup Nagar","state_id":"41"},
    {"city_id":"5330","city_name":"Arambagh","state_id":"41"},
    {"city_id":"5331","city_name":"Argari","state_id":"41"},
    {"city_id":"5332","city_name":"Arsha","state_id":"41"},
    {"city_id":"5333","city_name":"Asansol","state_id":"41"},
    {"city_id":"5334","city_name":"Ashoknagar Kalyangarh","state_id":"41"},
    {"city_id":"5335","city_name":"Aurangabad","state_id":"41"},
    {"city_id":"5336","city_name":"Bablari Dewanganj","state_id":"41"},
    {"city_id":"5337","city_name":"Badhagachhi","state_id":"41"},
    {"city_id":"5338","city_name":"Baduria","state_id":"41"},
    {"city_id":"5339","city_name":"Baghdogra","state_id":"41"},
    {"city_id":"5340","city_name":"Bagnan","state_id":"41"},
    {"city_id":"5341","city_name":"Bagra","state_id":"41"},
    {"city_id":"5342","city_name":"Bagula","state_id":"41"},
    {"city_id":"5343","city_name":"Baharampur","state_id":"41"},
    {"city_id":"5344","city_name":"Bahirgram","state_id":"41"},
    {"city_id":"5345","city_name":"Bahula","state_id":"41"},
    {"city_id":"5346","city_name":"Baidyabati","state_id":"41"},
    {"city_id":"5347","city_name":"Bairatisal","state_id":"41"},
    {"city_id":"5348","city_name":"Baj Baj","state_id":"41"},
    {"city_id":"5349","city_name":"Bakreswar","state_id":"41"},
    {"city_id":"5350","city_name":"Balaram Pota","state_id":"41"},
    {"city_id":"5351","city_name":"Balarampur","state_id":"41"},
    {"city_id":"5352","city_name":"Bali Chak","state_id":"41"},
    {"city_id":"5353","city_name":"Ballavpur","state_id":"41"},
    {"city_id":"5354","city_name":"Bally","state_id":"41"},
    {"city_id":"5355","city_name":"Balurghat","state_id":"41"},
    {"city_id":"5356","city_name":"Bamunari","state_id":"41"},
    {"city_id":"5357","city_name":"Banarhat Tea Garden","state_id":"41"},
    {"city_id":"5358","city_name":"Bandel","state_id":"41"},
    {"city_id":"5359","city_name":"Bangaon","state_id":"41"},
    {"city_id":"5360","city_name":"Bankra","state_id":"41"},
    {"city_id":"5361","city_name":"Bankura","state_id":"41"},
    {"city_id":"5362","city_name":"Bansbaria","state_id":"41"},
    {"city_id":"5363","city_name":"Banshra","state_id":"41"},
    {"city_id":"5364","city_name":"Banupur","state_id":"41"},
    {"city_id":"5365","city_name":"Bara Bamonia","state_id":"41"},
    {"city_id":"5366","city_name":"Barakpur","state_id":"41"},
    {"city_id":"5367","city_name":"Barakpur Cantonment","state_id":"41"},
    {"city_id":"5368","city_name":"Baranagar","state_id":"41"},
    {"city_id":"5369","city_name":"Barasat","state_id":"41"},
    {"city_id":"5370","city_name":"Barddhaman","state_id":"41"},
    {"city_id":"5371","city_name":"Barijhati","state_id":"41"},
    {"city_id":"5372","city_name":"Barjora","state_id":"41"},
    {"city_id":"5373","city_name":"Barrackpore","state_id":"41"},
    {"city_id":"5374","city_name":"Baruihuda","state_id":"41"},
    {"city_id":"5375","city_name":"Baruipur","state_id":"41"},
    {"city_id":"5376","city_name":"Barunda","state_id":"41"},
    {"city_id":"5377","city_name":"Basirhat","state_id":"41"},
    {"city_id":"5378","city_name":"Baska","state_id":"41"},
    {"city_id":"5379","city_name":"Begampur","state_id":"41"},
    {"city_id":"5380","city_name":"Beldanga","state_id":"41"},
    {"city_id":"5381","city_name":"Beldubi","state_id":"41"},
    {"city_id":"5382","city_name":"Belebathan","state_id":"41"},
    {"city_id":"5383","city_name":"Beliator","state_id":"41"},
    {"city_id":"5384","city_name":"Bhadreswar","state_id":"41"},
    {"city_id":"5385","city_name":"Bhandardaha","state_id":"41"},
    {"city_id":"5386","city_name":"Bhangar Raghunathpur","state_id":"41"},
    {"city_id":"5387","city_name":"Bhangri Pratham Khanda","state_id":"41"},
    {"city_id":"5388","city_name":"Bhanowara","state_id":"41"},
    {"city_id":"5389","city_name":"Bhatpara","state_id":"41"},
    {"city_id":"5390","city_name":"Bholar Dabri","state_id":"41"},
    {"city_id":"5391","city_name":"Bidhannagar","state_id":"41"},
    {"city_id":"5392","city_name":"Bidyadharpur","state_id":"41"},
    {"city_id":"5393","city_name":"Biki Hakola","state_id":"41"},
    {"city_id":"5394","city_name":"Bilandapur","state_id":"41"},
    {"city_id":"5395","city_name":"Bilpahari","state_id":"41"},
    {"city_id":"5396","city_name":"Bipra Noapara","state_id":"41"},
    {"city_id":"5397","city_name":"Birlapur","state_id":"41"},
    {"city_id":"5398","city_name":"Birnagar","state_id":"41"},
    {"city_id":"5399","city_name":"Bisarpara","state_id":"41"},
    {"city_id":"5400","city_name":"Bishnupur","state_id":"41"},
    {"city_id":"5401","city_name":"Bolpur","state_id":"41"},
    {"city_id":"5402","city_name":"Bongaon","state_id":"41"},
    {"city_id":"5403","city_name":"Bowali","state_id":"41"},
    {"city_id":"5404","city_name":"Burdwan","state_id":"41"},
    {"city_id":"5405","city_name":"Canning","state_id":"41"},
    {"city_id":"5406","city_name":"Cart Road","state_id":"41"},
    {"city_id":"5407","city_name":"Chachanda","state_id":"41"},
    {"city_id":"5408","city_name":"Chak Bankola","state_id":"41"},
    {"city_id":"5409","city_name":"Chak Enayetnagar","state_id":"41"},
    {"city_id":"5410","city_name":"Chak Kashipur","state_id":"41"},
    {"city_id":"5411","city_name":"Chakalampur","state_id":"41"},
    {"city_id":"5412","city_name":"Chakbansberia","state_id":"41"},
    {"city_id":"5413","city_name":"Chakdaha","state_id":"41"},
    {"city_id":"5414","city_name":"Chakpara","state_id":"41"},
    {"city_id":"5415","city_name":"Champahati","state_id":"41"},
    {"city_id":"5416","city_name":"Champdani","state_id":"41"},
    {"city_id":"5417","city_name":"Chamrail","state_id":"41"},
    {"city_id":"5418","city_name":"Chandannagar","state_id":"41"},
    {"city_id":"5419","city_name":"Chandpur","state_id":"41"},
    {"city_id":"5420","city_name":"Chandrakona","state_id":"41"},
    {"city_id":"5421","city_name":"Chapari","state_id":"41"},
    {"city_id":"5422","city_name":"Chapui","state_id":"41"},
    {"city_id":"5423","city_name":"Char Brahmanagar","state_id":"41"},
    {"city_id":"5424","city_name":"Char Maijdia","state_id":"41"},
    {"city_id":"5425","city_name":"Charka","state_id":"41"},
    {"city_id":"5426","city_name":"Chata Kalikapur","state_id":"41"},
    {"city_id":"5427","city_name":"Chauhati","state_id":"41"},
    {"city_id":"5428","city_name":"Checha Khata","state_id":"41"},
    {"city_id":"5429","city_name":"Chelad","state_id":"41"},
    {"city_id":"5430","city_name":"Chhora","state_id":"41"},
    {"city_id":"5431","city_name":"Chikrand","state_id":"41"},
    {"city_id":"5432","city_name":"Chittaranjan","state_id":"41"},
    {"city_id":"5433","city_name":"Contai","state_id":"41"},
    {"city_id":"5434","city_name":"Cooch Behar","state_id":"41"},
    {"city_id":"5435","city_name":"Dainhat","state_id":"41"},
    {"city_id":"5436","city_name":"Dakshin Baguan","state_id":"41"},
    {"city_id":"5437","city_name":"Dakshin Jhapardaha","state_id":"41"},
    {"city_id":"5438","city_name":"Dakshin Rajyadharpur","state_id":"41"},
    {"city_id":"5439","city_name":"Dakshin Raypur","state_id":"41"},
    {"city_id":"5440","city_name":"Dalkola","state_id":"41"},
    {"city_id":"5441","city_name":"Dalurband","state_id":"41"},
    {"city_id":"5442","city_name":"Darap Pur","state_id":"41"},
    {"city_id":"5443","city_name":"Darjiling","state_id":"41"},
    {"city_id":"5444","city_name":"Daulatpur","state_id":"41"},
    {"city_id":"5445","city_name":"Debipur","state_id":"41"},
    {"city_id":"5446","city_name":"Defahat","state_id":"41"},
    {"city_id":"5447","city_name":"Deora","state_id":"41"},
    {"city_id":"5448","city_name":"Deulia","state_id":"41"},
    {"city_id":"5449","city_name":"Dhakuria","state_id":"41"},
    {"city_id":"5450","city_name":"Dhandadihi","state_id":"41"},
    {"city_id":"5451","city_name":"Dhanyakuria","state_id":"41"},
    {"city_id":"5452","city_name":"Dharmapur","state_id":"41"},
    {"city_id":"5453","city_name":"Dhatri Gram","state_id":"41"},
    {"city_id":"5454","city_name":"Dhuilya","state_id":"41"},
    {"city_id":"5455","city_name":"Dhulagari","state_id":"41"},
    {"city_id":"5456","city_name":"Dhulian","state_id":"41"},
    {"city_id":"5457","city_name":"Dhupgari","state_id":"41"},
    {"city_id":"5458","city_name":"Dhusaripara","state_id":"41"},
    {"city_id":"5459","city_name":"Diamond Harbour","state_id":"41"},
    {"city_id":"5460","city_name":"Digha","state_id":"41"},
    {"city_id":"5461","city_name":"Dignala","state_id":"41"},
    {"city_id":"5462","city_name":"Dinhata","state_id":"41"},
    {"city_id":"5463","city_name":"Dubrajpur","state_id":"41"},
    {"city_id":"5464","city_name":"Dumjor","state_id":"41"},
    {"city_id":"5465","city_name":"Durgapur","state_id":"41"},
    {"city_id":"5466","city_name":"Durllabhganj","state_id":"41"},
    {"city_id":"5467","city_name":"Egra","state_id":"41"},
    {"city_id":"5468","city_name":"Eksara","state_id":"41"},
    {"city_id":"5469","city_name":"Falakata","state_id":"41"},
    {"city_id":"5470","city_name":"Farakka","state_id":"41"},
    {"city_id":"5471","city_name":"Fatellapur","state_id":"41"},
    {"city_id":"5472","city_name":"Fort Gloster","state_id":"41"},
    {"city_id":"5473","city_name":"Gabberia","state_id":"41"},
    {"city_id":"5474","city_name":"Gadigachha","state_id":"41"},
    {"city_id":"5475","city_name":"Gairkata","state_id":"41"},
    {"city_id":"5476","city_name":"Gangarampur","state_id":"41"},
    {"city_id":"5477","city_name":"Garalgachha","state_id":"41"},
    {"city_id":"5478","city_name":"Garbeta Amlagora","state_id":"41"},
    {"city_id":"5479","city_name":"Garhbeta","state_id":"41"},
    {"city_id":"5480","city_name":"Garshyamnagar","state_id":"41"},
    {"city_id":"5481","city_name":"Garui","state_id":"41"},
    {"city_id":"5482","city_name":"Garulia","state_id":"41"},
    {"city_id":"5483","city_name":"Gayespur","state_id":"41"},
    {"city_id":"5484","city_name":"Ghatal","state_id":"41"},
    {"city_id":"5485","city_name":"Ghorsala","state_id":"41"},
    {"city_id":"5486","city_name":"Goaljan","state_id":"41"},
    {"city_id":"5487","city_name":"Goasafat","state_id":"41"},
    {"city_id":"5488","city_name":"Gobardanga","state_id":"41"},
    {"city_id":"5489","city_name":"Gobindapur","state_id":"41"},
    {"city_id":"5490","city_name":"Gopalpur","state_id":"41"},
    {"city_id":"5491","city_name":"Gopinathpur","state_id":"41"},
    {"city_id":"5492","city_name":"Gora Bazar","state_id":"41"},
    {"city_id":"5493","city_name":"Guma","state_id":"41"},
    {"city_id":"5494","city_name":"Gurdaha","state_id":"41"},
    {"city_id":"5495","city_name":"Guriahati","state_id":"41"},
    {"city_id":"5496","city_name":"Guskhara","state_id":"41"},
    {"city_id":"5497","city_name":"Habra","state_id":"41"},
    {"city_id":"5498","city_name":"Haldia","state_id":"41"},
    {"city_id":"5499","city_name":"Haldibari","state_id":"41"},
    {"city_id":"5500","city_name":"Halisahar","state_id":"41"},
    {"city_id":"5501","city_name":"Haora","state_id":"41"},
    {"city_id":"5502","city_name":"Harharia Chak","state_id":"41"},
    {"city_id":"5503","city_name":"Harindanga","state_id":"41"},
    {"city_id":"5504","city_name":"Haringhata","state_id":"41"},
    {"city_id":"5505","city_name":"Haripur","state_id":"41"},
    {"city_id":"5506","city_name":"Harishpur","state_id":"41"},
    {"city_id":"5507","city_name":"Hatgachha","state_id":"41"},
    {"city_id":"5508","city_name":"Hatsimla","state_id":"41"},
    {"city_id":"5509","city_name":"Hijuli","state_id":"41"},
    {"city_id":"5510","city_name":"Hindustan Cables Town","state_id":"41"},
    {"city_id":"5511","city_name":"Hooghly","state_id":"41"},
    {"city_id":"5512","city_name":"Howrah","state_id":"41"},
    {"city_id":"5513","city_name":"Hugli-Chunchura","state_id":"41"},
    {"city_id":"5514","city_name":"Humaipur","state_id":"41"},
    {"city_id":"5515","city_name":"Ichha Pur Defence Estate","state_id":"41"},
    {"city_id":"5516","city_name":"Ingraj Bazar","state_id":"41"},
    {"city_id":"5517","city_name":"Islampur","state_id":"41"},
    {"city_id":"5518","city_name":"Jafarpur","state_id":"41"},
    {"city_id":"5519","city_name":"Jagadanandapur","state_id":"41"},
    {"city_id":"5520","city_name":"Jagdishpur","state_id":"41"},
    {"city_id":"5521","city_name":"Jagtaj","state_id":"41"},
    {"city_id":"5522","city_name":"Jala Kendua","state_id":"41"},
    {"city_id":"5523","city_name":"Jaldhaka","state_id":"41"},
    {"city_id":"5524","city_name":"Jalkhura","state_id":"41"},
    {"city_id":"5525","city_name":"Jalpaiguri","state_id":"41"},
    {"city_id":"5526","city_name":"Jamuria","state_id":"41"},
    {"city_id":"5527","city_name":"Jangipur","state_id":"41"},
    {"city_id":"5528","city_name":"Jaygaon","state_id":"41"},
    {"city_id":"5529","city_name":"Jaynagar-Majilpur","state_id":"41"},
    {"city_id":"5530","city_name":"Jemari","state_id":"41"},
    {"city_id":"5531","city_name":"Jemari Township","state_id":"41"},
    {"city_id":"5532","city_name":"Jetia","state_id":"41"},
    {"city_id":"5533","city_name":"Jhalida","state_id":"41"},
    {"city_id":"5534","city_name":"Jhargram","state_id":"41"},
    {"city_id":"5535","city_name":"Jhorhat","state_id":"41"},
    {"city_id":"5536","city_name":"Jiaganj-Azimganj","state_id":"41"},
    {"city_id":"5537","city_name":"Joka","state_id":"41"},
    {"city_id":"5538","city_name":"Jot Kamal","state_id":"41"},
    {"city_id":"5539","city_name":"Kachu Pukur","state_id":"41"},
    {"city_id":"5540","city_name":"Kajora","state_id":"41"},
    {"city_id":"5541","city_name":"Kakdihi","state_id":"41"},
    {"city_id":"5542","city_name":"Kakdwip","state_id":"41"},
    {"city_id":"5543","city_name":"Kalaikunda","state_id":"41"},
    {"city_id":"5544","city_name":"Kalara","state_id":"41"},
    {"city_id":"5545","city_name":"Kalimpong","state_id":"41"},
    {"city_id":"5546","city_name":"Kaliyaganj","state_id":"41"},
    {"city_id":"5547","city_name":"Kalna","state_id":"41"},
    {"city_id":"5548","city_name":"Kalyani","state_id":"41"},
    {"city_id":"5549","city_name":"Kamarhati","state_id":"41"},
    {"city_id":"5550","city_name":"Kanaipur","state_id":"41"},
    {"city_id":"5551","city_name":"Kanchrapara","state_id":"41"},
    {"city_id":"5552","city_name":"Kandi","state_id":"41"},
    {"city_id":"5553","city_name":"Kanki","state_id":"41"},
    {"city_id":"5554","city_name":"Kankuria","state_id":"41"},
    {"city_id":"5555","city_name":"Kantlia","state_id":"41"},
    {"city_id":"5556","city_name":"Kanyanagar","state_id":"41"},
    {"city_id":"5557","city_name":"Karimpur","state_id":"41"},
    {"city_id":"5558","city_name":"Karsiyang","state_id":"41"},
    {"city_id":"5559","city_name":"Kasba","state_id":"41"},
    {"city_id":"5560","city_name":"Kasimbazar","state_id":"41"},
    {"city_id":"5561","city_name":"Katwa","state_id":"41"},
    {"city_id":"5562","city_name":"Kaugachhi","state_id":"41"},
    {"city_id":"5563","city_name":"Kenda","state_id":"41"},
    {"city_id":"5564","city_name":"Kendra Khottamdi","state_id":"41"},
    {"city_id":"5565","city_name":"Kendua","state_id":"41"},
    {"city_id":"5566","city_name":"Kesabpur","state_id":"41"},
    {"city_id":"5567","city_name":"Khagrabari","state_id":"41"},
    {"city_id":"5568","city_name":"Khalia","state_id":"41"},
    {"city_id":"5569","city_name":"Khalor","state_id":"41"},
    {"city_id":"5570","city_name":"Khandra","state_id":"41"},
    {"city_id":"5571","city_name":"Khantora","state_id":"41"},
    {"city_id":"5572","city_name":"Kharagpur","state_id":"41"},
    {"city_id":"5573","city_name":"Kharagpur Railway Settlement","state_id":"41"},
    {"city_id":"5574","city_name":"Kharar","state_id":"41"},
    {"city_id":"5575","city_name":"Khardaha","state_id":"41"},
    {"city_id":"5576","city_name":"Khari Mala Khagrabari","state_id":"41"},
    {"city_id":"5577","city_name":"Kharsarai","state_id":"41"},
    {"city_id":"5578","city_name":"Khatra","state_id":"41"},
    {"city_id":"5579","city_name":"Khodarampur","state_id":"41"},
    {"city_id":"5580","city_name":"Kodalia","state_id":"41"},
    {"city_id":"5581","city_name":"Kolaghat","state_id":"41"},
    {"city_id":"5582","city_name":"Kolaghat Thermal Power Project","state_id":"41"},
    {"city_id":"5583","city_name":"Kolkata","state_id":"41"},
    {"city_id":"5584","city_name":"Konardihi","state_id":"41"},
    {"city_id":"5585","city_name":"Konnogar","state_id":"41"},
    {"city_id":"5586","city_name":"Krishnanagar","state_id":"41"},
    {"city_id":"5587","city_name":"Krishnapur","state_id":"41"},
    {"city_id":"5588","city_name":"Kshidirpur","state_id":"41"},
    {"city_id":"5589","city_name":"Kshirpai","state_id":"41"},
    {"city_id":"5590","city_name":"Kulihanda","state_id":"41"},
    {"city_id":"5591","city_name":"Kulti","state_id":"41"},
    {"city_id":"5592","city_name":"Kunustara","state_id":"41"},
    {"city_id":"5593","city_name":"Kuperskem","state_id":"41"},
    {"city_id":"5594","city_name":"Madanpur","state_id":"41"},
    {"city_id":"5595","city_name":"Madhusudanpur","state_id":"41"},
    {"city_id":"5596","city_name":"Madhyamgram","state_id":"41"},
    {"city_id":"5597","city_name":"Maheshtala","state_id":"41"},
    {"city_id":"5598","city_name":"Mahiari","state_id":"41"},
    {"city_id":"5599","city_name":"Mahikpur","state_id":"41"},
    {"city_id":"5600","city_name":"Mahira","state_id":"41"},
    {"city_id":"5601","city_name":"Mahishadal","state_id":"41"},
    {"city_id":"5602","city_name":"Mainaguri","state_id":"41"},
    {"city_id":"5603","city_name":"Makardaha","state_id":"41"},
    {"city_id":"5604","city_name":"Mal","state_id":"41"},
    {"city_id":"5605","city_name":"Malda","state_id":"41"},
    {"city_id":"5606","city_name":"Mandarbani","state_id":"41"},
    {"city_id":"5607","city_name":"Mansinhapur","state_id":"41"},
    {"city_id":"5608","city_name":"Masila","state_id":"41"},
    {"city_id":"5609","city_name":"Maslandapur","state_id":"41"},
    {"city_id":"5610","city_name":"Mathabhanga","state_id":"41"},
    {"city_id":"5611","city_name":"Mekliganj","state_id":"41"},
    {"city_id":"5612","city_name":"Memari","state_id":"41"},
    {"city_id":"5613","city_name":"Midnapur","state_id":"41"},
    {"city_id":"5614","city_name":"Mirik","state_id":"41"},
    {"city_id":"5615","city_name":"Monoharpur","state_id":"41"},
    {"city_id":"5616","city_name":"Mrigala","state_id":"41"},
    {"city_id":"5617","city_name":"Muragachha","state_id":"41"},
    {"city_id":"5618","city_name":"Murgathaul","state_id":"41"},
    {"city_id":"5619","city_name":"Murshidabad","state_id":"41"},
    {"city_id":"5620","city_name":"Nabadhai Dutta Pukur","state_id":"41"},
    {"city_id":"5621","city_name":"Nabagram","state_id":"41"},
    {"city_id":"5622","city_name":"Nabgram","state_id":"41"},
    {"city_id":"5623","city_name":"Nachhratpur Katabari","state_id":"41"},
    {"city_id":"5624","city_name":"Nadia","state_id":"41"},
    {"city_id":"5625","city_name":"Naihati","state_id":"41"},
    {"city_id":"5626","city_name":"Nalhati","state_id":"41"},
    {"city_id":"5627","city_name":"Nasra","state_id":"41"},
    {"city_id":"5628","city_name":"Natibpur","state_id":"41"},
    {"city_id":"5629","city_name":"Naupala","state_id":"41"},
    {"city_id":"5630","city_name":"Navadwip","state_id":"41"},
    {"city_id":"5631","city_name":"Nebadhai Duttapukur","state_id":"41"},
    {"city_id":"5632","city_name":"New Barrackpore","state_id":"41"},
    {"city_id":"5633","city_name":"Ni Barakpur","state_id":"41"},
    {"city_id":"5634","city_name":"Nibra","state_id":"41"},
    {"city_id":"5635","city_name":"Noapara","state_id":"41"},
    {"city_id":"5636","city_name":"Nokpul","state_id":"41"},
    {"city_id":"5637","city_name":"North Barakpur","state_id":"41"},
    {"city_id":"5638","city_name":"Odlabari","state_id":"41"},
    {"city_id":"5639","city_name":"Old Maldah","state_id":"41"},
    {"city_id":"5640","city_name":"Ondal","state_id":"41"},
    {"city_id":"5641","city_name":"Pairagachha","state_id":"41"},
    {"city_id":"5642","city_name":"Palashban","state_id":"41"},
    {"city_id":"5643","city_name":"Panchla","state_id":"41"},
    {"city_id":"5644","city_name":"Panchpara","state_id":"41"},
    {"city_id":"5645","city_name":"Pandua","state_id":"41"},
    {"city_id":"5646","city_name":"Pangachhiya","state_id":"41"},
    {"city_id":"5647","city_name":"Paniara","state_id":"41"},
    {"city_id":"5648","city_name":"Panihati","state_id":"41"},
    {"city_id":"5649","city_name":"Panuhat","state_id":"41"},
    {"city_id":"5650","city_name":"Par Beliya","state_id":"41"},
    {"city_id":"5651","city_name":"Parashkol","state_id":"41"},
    {"city_id":"5652","city_name":"Parasia","state_id":"41"},
    {"city_id":"5653","city_name":"Parbbatipur","state_id":"41"},
    {"city_id":"5654","city_name":"Parui","state_id":"41"},
    {"city_id":"5655","city_name":"Paschim Jitpur","state_id":"41"},
    {"city_id":"5656","city_name":"Paschim Punro Para","state_id":"41"},
    {"city_id":"5657","city_name":"Patrasaer","state_id":"41"},
    {"city_id":"5658","city_name":"Pattabong Tea Garden","state_id":"41"},
    {"city_id":"5659","city_name":"Patuli","state_id":"41"},
    {"city_id":"5660","city_name":"Patulia","state_id":"41"},
    {"city_id":"5661","city_name":"Phulia","state_id":"41"},
    {"city_id":"5662","city_name":"Podara","state_id":"41"},
    {"city_id":"5663","city_name":"Port Blair","state_id":"41"},
    {"city_id":"5664","city_name":"Prayagpur","state_id":"41"},
    {"city_id":"5665","city_name":"Pujali","state_id":"41"},
    {"city_id":"5666","city_name":"Purba Medinipur","state_id":"41"},
    {"city_id":"5667","city_name":"Purba Tajpur","state_id":"41"},
    {"city_id":"5668","city_name":"Purulia","state_id":"41"},
    {"city_id":"5669","city_name":"Raghudebbati","state_id":"41"},
    {"city_id":"5670","city_name":"Raghudebpur","state_id":"41"},
    {"city_id":"5671","city_name":"Raghunathchak","state_id":"41"},
    {"city_id":"5672","city_name":"Raghunathpur","state_id":"41"},
    {"city_id":"5673","city_name":"Raghunathpur-Dankuni","state_id":"41"},
    {"city_id":"5674","city_name":"Raghunathpur-Magra","state_id":"41"},
    {"city_id":"5675","city_name":"Raigachhi","state_id":"41"},
    {"city_id":"5676","city_name":"Raiganj","state_id":"41"},
    {"city_id":"5677","city_name":"Raipur","state_id":"41"},
    {"city_id":"5678","city_name":"Rajarhat Gopalpur","state_id":"41"},
    {"city_id":"5679","city_name":"Rajpur","state_id":"41"},
    {"city_id":"5680","city_name":"Ramchandrapur","state_id":"41"},
    {"city_id":"5681","city_name":"Ramjibanpur","state_id":"41"},
    {"city_id":"5682","city_name":"Ramnagar","state_id":"41"},
    {"city_id":"5683","city_name":"Rampur Hat","state_id":"41"},
    {"city_id":"5684","city_name":"Ranaghat","state_id":"41"},
    {"city_id":"5685","city_name":"Raniganj","state_id":"41"},
    {"city_id":"5686","city_name":"Ratibati","state_id":"41"},
    {"city_id":"5687","city_name":"Raypur","state_id":"41"},
    {"city_id":"5688","city_name":"Rishra","state_id":"41"},
    {"city_id":"5689","city_name":"Rishra Cantonment","state_id":"41"},
    {"city_id":"5690","city_name":"Ruiya","state_id":"41"},
    {"city_id":"5691","city_name":"Sahajadpur","state_id":"41"},
    {"city_id":"5692","city_name":"Sahapur","state_id":"41"},
    {"city_id":"5693","city_name":"Sainthia","state_id":"41"},
    {"city_id":"5694","city_name":"Salap","state_id":"41"},
    {"city_id":"5695","city_name":"Sankarpur","state_id":"41"},
    {"city_id":"5696","city_name":"Sankrail","state_id":"41"},
    {"city_id":"5697","city_name":"Santoshpur","state_id":"41"},
    {"city_id":"5698","city_name":"Saontaidih","state_id":"41"},
    {"city_id":"5699","city_name":"Sarenga","state_id":"41"},
    {"city_id":"5700","city_name":"Sarpi","state_id":"41"},
    {"city_id":"5701","city_name":"Satigachha","state_id":"41"},
    {"city_id":"5702","city_name":"Serpur","state_id":"41"},
    {"city_id":"5703","city_name":"Shankhanagar","state_id":"41"},
    {"city_id":"5704","city_name":"Shantipur","state_id":"41"},
    {"city_id":"5705","city_name":"Shrirampur","state_id":"41"},
    {"city_id":"5706","city_name":"Siduli","state_id":"41"},
    {"city_id":"5707","city_name":"Siliguri","state_id":"41"},
    {"city_id":"5708","city_name":"Simla","state_id":"41"},
    {"city_id":"5709","city_name":"Singur","state_id":"41"},
    {"city_id":"5710","city_name":"Sirsha","state_id":"41"},
    {"city_id":"5711","city_name":"Siuri","state_id":"41"},
    {"city_id":"5712","city_name":"Sobhaganj","state_id":"41"},
    {"city_id":"5713","city_name":"Sodpur","state_id":"41"},
    {"city_id":"5714","city_name":"Sonamukhi","state_id":"41"},
    {"city_id":"5715","city_name":"Sonatikiri","state_id":"41"},
    {"city_id":"5716","city_name":"Srikantabati","state_id":"41"},
    {"city_id":"5717","city_name":"Srirampur","state_id":"41"},
    {"city_id":"5718","city_name":"Sukdal","state_id":"41"},
    {"city_id":"5719","city_name":"Taherpur","state_id":"41"},
    {"city_id":"5720","city_name":"Taki","state_id":"41"},
    {"city_id":"5721","city_name":"Talbandha","state_id":"41"},
    {"city_id":"5722","city_name":"Tamluk","state_id":"41"},
    {"city_id":"5723","city_name":"Tarakeswar","state_id":"41"},
    {"city_id":"5724","city_name":"Tentulberia","state_id":"41"},
    {"city_id":"5725","city_name":"Tentulkuli","state_id":"41"},
    {"city_id":"5726","city_name":"Thermal Power Project","state_id":"41"},
    {"city_id":"5727","city_name":"Tinsukia","state_id":"41"},
    {"city_id":"5728","city_name":"Titagarh","state_id":"41"},
    {"city_id":"5729","city_name":"Tufanganj","state_id":"41"},
    {"city_id":"5730","city_name":"Ukhra","state_id":"41"},
    {"city_id":"5731","city_name":"Ula","state_id":"41"},
    {"city_id":"5732","city_name":"Ulubaria","state_id":"41"},
    {"city_id":"5733","city_name":"Uttar Durgapur","state_id":"41"},
    {"city_id":"5734","city_name":"Uttar Goara","state_id":"41"},
    {"city_id":"5735","city_name":"Uttar Kalas","state_id":"41"},
    {"city_id":"5736","city_name":"Uttar Kamakhyaguri","state_id":"41"},
    {"city_id":"5737","city_name":"Uttar Latabari","state_id":"41"},
    {"city_id":"5738","city_name":"Uttar Mahammadpur","state_id":"41"},
    {"city_id":"5739","city_name":"Uttar Pirpur","state_id":"41"},
    {"city_id":"5740","city_name":"Uttar Raypur","state_id":"41"},
    {"city_id":"5741","city_name":"Uttarpara-Kotrung","state_id":"41"},
    {"city_id":"5742","city_name":"Abohar","state_id":"2728"},
    {"city_id":"5743","city_name":"Adampur","state_id":"2728"},
    {"city_id":"5744","city_name":"Ahmedgarh","state_id":"2728"},
    {"city_id":"5745","city_name":"Ajnala","state_id":"2728"},
    {"city_id":"5746","city_name":"Akalgarh","state_id":"2728"},
    {"city_id":"5747","city_name":"Alawalpur","state_id":"2728"},
    {"city_id":"5748","city_name":"Amloh","state_id":"2728"},
    {"city_id":"5749","city_name":"Amritsar","state_id":"2728"},
    {"city_id":"5750","city_name":"Amritsar Cantonment","state_id":"2728"},
    {"city_id":"5751","city_name":"Anandpur Sahib","state_id":"2728"},
    {"city_id":"5752","city_name":"Badhni Kalan","state_id":"2728"},
    {"city_id":"5753","city_name":"Bagh Purana","state_id":"2728"},
    {"city_id":"5754","city_name":"Balachaur","state_id":"2728"},
    {"city_id":"5755","city_name":"Banaur","state_id":"2728"},
    {"city_id":"5756","city_name":"Banga","state_id":"2728"},
    {"city_id":"5757","city_name":"Banur","state_id":"2728"},
    {"city_id":"5758","city_name":"Baretta","state_id":"2728"},
    {"city_id":"5759","city_name":"Bariwala","state_id":"2728"},
    {"city_id":"5760","city_name":"Barnala","state_id":"2728"},
    {"city_id":"5761","city_name":"Bassi Pathana","state_id":"2728"},
    {"city_id":"5762","city_name":"Batala","state_id":"2728"},
    {"city_id":"5763","city_name":"Bathinda","state_id":"2728"},
    {"city_id":"5764","city_name":"Begowal","state_id":"2728"},
    {"city_id":"5765","city_name":"Behrampur","state_id":"2728"},
    {"city_id":"5766","city_name":"Bhabat","state_id":"2728"},
    {"city_id":"5767","city_name":"Bhadur","state_id":"2728"},
    {"city_id":"5768","city_name":"Bhankharpur","state_id":"2728"},
    {"city_id":"5769","city_name":"Bharoli Kalan","state_id":"2728"},
    {"city_id":"5770","city_name":"Bhawanigarh","state_id":"2728"},
    {"city_id":"5771","city_name":"Bhikhi","state_id":"2728"},
    {"city_id":"5772","city_name":"Bhikhiwind","state_id":"2728"},
    {"city_id":"5773","city_name":"Bhisiana","state_id":"2728"},
    {"city_id":"5774","city_name":"Bhogpur","state_id":"2728"},
    {"city_id":"5775","city_name":"Bhuch","state_id":"2728"},
    {"city_id":"5776","city_name":"Bhulath","state_id":"2728"},
    {"city_id":"5777","city_name":"Budha Theh","state_id":"2728"},
    {"city_id":"5778","city_name":"Budhlada","state_id":"2728"},
    {"city_id":"5779","city_name":"Chima","state_id":"2728"},
    {"city_id":"5780","city_name":"Chohal","state_id":"2728"},
    {"city_id":"5781","city_name":"Dasuya","state_id":"2728"},
    {"city_id":"5782","city_name":"Daulatpur","state_id":"2728"},
    {"city_id":"5783","city_name":"Dera Baba Nanak","state_id":"2728"},
    {"city_id":"5784","city_name":"Dera Bassi","state_id":"2728"},
    {"city_id":"5785","city_name":"Dhanaula","state_id":"2728"},
    {"city_id":"5786","city_name":"Dharam Kot","state_id":"2728"},
    {"city_id":"5787","city_name":"Dhariwal","state_id":"2728"},
    {"city_id":"5788","city_name":"Dhilwan","state_id":"2728"},
    {"city_id":"5789","city_name":"Dhuri","state_id":"2728"},
    {"city_id":"5790","city_name":"Dinanagar","state_id":"2728"},
    {"city_id":"5791","city_name":"Dirba","state_id":"2728"},
    {"city_id":"5792","city_name":"Doraha","state_id":"2728"},
    {"city_id":"5793","city_name":"Faridkot","state_id":"2728"},
    {"city_id":"5794","city_name":"Fateh Nangal","state_id":"2728"},
    {"city_id":"5795","city_name":"Fatehgarh Churian","state_id":"2728"},
    {"city_id":"5796","city_name":"Fatehgarh Sahib","state_id":"2728"},
    {"city_id":"5797","city_name":"Fazilka","state_id":"2728"},
    {"city_id":"5798","city_name":"Firozpur","state_id":"2728"},
    {"city_id":"5799","city_name":"Firozpur Cantonment","state_id":"2728"},
    {"city_id":"5800","city_name":"Gardhiwala","state_id":"2728"},
    {"city_id":"5801","city_name":"Garhshankar","state_id":"2728"},
    {"city_id":"5802","city_name":"Ghagga","state_id":"2728"},
    {"city_id":"5803","city_name":"Ghanaur","state_id":"2728"},
    {"city_id":"5804","city_name":"Giddarbaha","state_id":"2728"},
    {"city_id":"5805","city_name":"Gobindgarh","state_id":"2728"},
    {"city_id":"5806","city_name":"Goniana","state_id":"2728"},
    {"city_id":"5807","city_name":"Goraya","state_id":"2728"},
    {"city_id":"5808","city_name":"Gurdaspur","state_id":"2728"},
    {"city_id":"5809","city_name":"Guru Har Sahai","state_id":"2728"},
    {"city_id":"5810","city_name":"Hajipur","state_id":"2728"},
    {"city_id":"5811","city_name":"Handiaya","state_id":"2728"},
    {"city_id":"5812","city_name":"Hariana","state_id":"2728"},
    {"city_id":"5813","city_name":"Hoshiarpur","state_id":"2728"},
    {"city_id":"5814","city_name":"Hussainpur","state_id":"2728"},
    {"city_id":"5815","city_name":"Jagraon","state_id":"2728"},
    {"city_id":"5816","city_name":"Jaitu","state_id":"2728"},
    {"city_id":"5817","city_name":"Jalalabad","state_id":"2728"},
    {"city_id":"5818","city_name":"Jalandhar","state_id":"2728"},
    {"city_id":"5819","city_name":"Jalandhar Cantonment","state_id":"2728"},
    {"city_id":"5820","city_name":"Jandiala","state_id":"2728"},
    {"city_id":"5821","city_name":"Jugial","state_id":"2728"},
    {"city_id":"5822","city_name":"Kalanaur","state_id":"2728"},
    {"city_id":"5823","city_name":"Kapurthala","state_id":"2728"},
    {"city_id":"5824","city_name":"Karoran","state_id":"2728"},
    {"city_id":"5825","city_name":"Kartarpur","state_id":"2728"},
    {"city_id":"5826","city_name":"Khamanon","state_id":"2728"},
    {"city_id":"5827","city_name":"Khanauri","state_id":"2728"},
    {"city_id":"5828","city_name":"Khanna","state_id":"2728"},
    {"city_id":"5829","city_name":"Kharar","state_id":"2728"},
    {"city_id":"5830","city_name":"Khem Karan","state_id":"2728"},
    {"city_id":"5831","city_name":"Kot Fatta","state_id":"2728"},
    {"city_id":"5832","city_name":"Kot Isa Khan","state_id":"2728"},
    {"city_id":"5833","city_name":"Kot Kapura","state_id":"2728"},
    {"city_id":"5834","city_name":"Kotkapura","state_id":"2728"},
    {"city_id":"5835","city_name":"Kurali","state_id":"2728"},
    {"city_id":"5836","city_name":"Lalru","state_id":"2728"},
    {"city_id":"5837","city_name":"Lehra Gaga","state_id":"2728"},
    {"city_id":"5838","city_name":"Lodhian Khas","state_id":"2728"},
    {"city_id":"5839","city_name":"Longowal","state_id":"2728"},
    {"city_id":"5840","city_name":"Ludhiana","state_id":"2728"},
    {"city_id":"5841","city_name":"Machhiwara","state_id":"2728"},
    {"city_id":"5842","city_name":"Mahilpur","state_id":"2728"},
    {"city_id":"5843","city_name":"Majitha","state_id":"2728"},
    {"city_id":"5844","city_name":"Makhu","state_id":"2728"},
    {"city_id":"5845","city_name":"Malaut","state_id":"2728"},
    {"city_id":"5846","city_name":"Malerkotla","state_id":"2728"},
    {"city_id":"5847","city_name":"Maloud","state_id":"2728"},
    {"city_id":"5848","city_name":"Mandi Gobindgarh","state_id":"2728"},
    {"city_id":"5849","city_name":"Mansa","state_id":"2728"},
    {"city_id":"5850","city_name":"Maur","state_id":"2728"},
    {"city_id":"5851","city_name":"Moga","state_id":"2728"},
    {"city_id":"5852","city_name":"Mohali","state_id":"2728"},
    {"city_id":"5853","city_name":"Moonak","state_id":"2728"},
    {"city_id":"5854","city_name":"Morinda","state_id":"2728"},
    {"city_id":"5855","city_name":"Mukerian","state_id":"2728"},
    {"city_id":"5856","city_name":"Muktsar","state_id":"2728"},
    {"city_id":"5857","city_name":"Mullanpur Dakha","state_id":"2728"},
    {"city_id":"5858","city_name":"Mullanpur Garibdas","state_id":"2728"},
    {"city_id":"5859","city_name":"Munak","state_id":"2728"},
    {"city_id":"5860","city_name":"Muradpura","state_id":"2728"},
    {"city_id":"5861","city_name":"Nabha","state_id":"2728"},
    {"city_id":"5862","city_name":"Nakodar","state_id":"2728"},
    {"city_id":"5863","city_name":"Nangal","state_id":"2728"},
    {"city_id":"5864","city_name":"Nawashahr","state_id":"2728"},
    {"city_id":"5865","city_name":"Naya Nangal","state_id":"2728"},
    {"city_id":"5866","city_name":"Nehon","state_id":"2728"},
    {"city_id":"5867","city_name":"Nurmahal","state_id":"2728"},
    {"city_id":"5868","city_name":"Pathankot","state_id":"2728"},
    {"city_id":"5869","city_name":"Patiala","state_id":"2728"},
    {"city_id":"5870","city_name":"Patti","state_id":"2728"},
    {"city_id":"5871","city_name":"Pattran","state_id":"2728"},
    {"city_id":"5872","city_name":"Payal","state_id":"2728"},
    {"city_id":"5873","city_name":"Phagwara","state_id":"2728"},
    {"city_id":"5874","city_name":"Phillaur","state_id":"2728"},
    {"city_id":"5875","city_name":"Qadian","state_id":"2728"},
    {"city_id":"5876","city_name":"Rahon","state_id":"2728"},
    {"city_id":"5877","city_name":"Raikot","state_id":"2728"},
    {"city_id":"5878","city_name":"Raja Sansi","state_id":"2728"},
    {"city_id":"5879","city_name":"Rajpura","state_id":"2728"},
    {"city_id":"5880","city_name":"Ram Das","state_id":"2728"},
    {"city_id":"5881","city_name":"Raman","state_id":"2728"},
    {"city_id":"5882","city_name":"Rampura","state_id":"2728"},
    {"city_id":"5883","city_name":"Rayya","state_id":"2728"},
    {"city_id":"5884","city_name":"Rupnagar","state_id":"2728"},
    {"city_id":"5885","city_name":"Rurki Kasba","state_id":"2728"},
    {"city_id":"5886","city_name":"Sahnewal","state_id":"2728"},
    {"city_id":"5887","city_name":"Samana","state_id":"2728"},
    {"city_id":"5888","city_name":"Samrala","state_id":"2728"},
    {"city_id":"5889","city_name":"Sanaur","state_id":"2728"},
    {"city_id":"5890","city_name":"Sangat","state_id":"2728"},
    {"city_id":"5891","city_name":"Sangrur","state_id":"2728"},
    {"city_id":"5892","city_name":"Sansarpur","state_id":"2728"},
    {"city_id":"5893","city_name":"Sardulgarh","state_id":"2728"},
    {"city_id":"5894","city_name":"Shahkot","state_id":"2728"},
    {"city_id":"5895","city_name":"Sham Churasi","state_id":"2728"},
    {"city_id":"5896","city_name":"Shekhpura","state_id":"2728"},
    {"city_id":"5897","city_name":"Sirhind","state_id":"2728"},
    {"city_id":"5898","city_name":"Sri Hargobindpur","state_id":"2728"},
    {"city_id":"5899","city_name":"Sujanpur","state_id":"2728"},
    {"city_id":"5900","city_name":"Sultanpur Lodhi","state_id":"2728"},
    {"city_id":"5901","city_name":"Sunam","state_id":"2728"},
    {"city_id":"5902","city_name":"Talwandi Bhai","state_id":"2728"},
    {"city_id":"5903","city_name":"Talwara","state_id":"2728"},
    {"city_id":"5904","city_name":"Tappa","state_id":"2728"},
    {"city_id":"5905","city_name":"Tarn Taran","state_id":"2728"},
    {"city_id":"5906","city_name":"Urmar Tanda","state_id":"2728"},
    {"city_id":"5907","city_name":"Zira","state_id":"2728"},
    {"city_id":"5908","city_name":"Zirakpur","state_id":"2728"},
    {"city_id":"5909","city_name":"Eshkashem","state_id":"42"},
    {"city_id":"5910","city_name":"Fayzabad","state_id":"42"},
    {"city_id":"5911","city_name":"Jurm","state_id":"42"},
    {"city_id":"5912","city_name":"Khandud","state_id":"42"},
    {"city_id":"5913","city_name":"Qal'eh-ye Panjeh","state_id":"42"},
    {"city_id":"5914","city_name":"Bala Morghab","state_id":"43"},
    {"city_id":"5915","city_name":"Qal'eh-ye Naw","state_id":"43"},
    {"city_id":"5916","city_name":"Andarab","state_id":"44"},
    {"city_id":"5917","city_name":"Baghlan","state_id":"44"},
    {"city_id":"5918","city_name":"Dahaneh-ye Ghawri","state_id":"44"},
    {"city_id":"5919","city_name":"Nahrin","state_id":"44"},
    {"city_id":"5920","city_name":"Pol-e Khumri","state_id":"44"},
    {"city_id":"5921","city_name":"Balkh","state_id":"45"},
    {"city_id":"5922","city_name":"Dawlatabad","state_id":"45"},
    {"city_id":"5923","city_name":"Mazar-e Sharif","state_id":"45"},
    {"city_id":"5924","city_name":"Qarchi Gak","state_id":"45"},
    {"city_id":"5925","city_name":"Shulgara","state_id":"45"},
    {"city_id":"5926","city_name":"Tash Gozar","state_id":"45"},
    {"city_id":"5927","city_name":"Bamiyan","state_id":"46"},
    {"city_id":"5928","city_name":"Panjab","state_id":"46"},
    {"city_id":"5929","city_name":"Qil Qal'eh","state_id":"46"},
    {"city_id":"5930","city_name":"Anar Darreh","state_id":"47"},
    {"city_id":"5931","city_name":"Farah","state_id":"47"},
    {"city_id":"5932","city_name":"Shindand","state_id":"47"},
    {"city_id":"5933","city_name":"Andkhvoy","state_id":"48"},
    {"city_id":"5934","city_name":"Darzi Ab","state_id":"48"},
    {"city_id":"5935","city_name":"Dawlatabad","state_id":"48"},
    {"city_id":"5936","city_name":"Maymanah","state_id":"48"},
    {"city_id":"5937","city_name":"Chaghcharan","state_id":"49"},
    {"city_id":"5938","city_name":"Shahrak","state_id":"49"},
    {"city_id":"5939","city_name":"Taywarah","state_id":"49"},
    {"city_id":"5940","city_name":"Ghazni","state_id":"50"},
    {"city_id":"5941","city_name":"Awbeh","state_id":"51"},
    {"city_id":"5942","city_name":"Eslam Qal'eh","state_id":"51"},
    {"city_id":"5943","city_name":"Ghurian","state_id":"51"},
    {"city_id":"5944","city_name":"Herat","state_id":"51"},
    {"city_id":"5945","city_name":"Karukh","state_id":"51"},
    {"city_id":"5946","city_name":"Kuhestan","state_id":"51"},
    {"city_id":"5947","city_name":"Kushk","state_id":"51"},
    {"city_id":"5948","city_name":"Qarabagh","state_id":"51"},
    {"city_id":"5949","city_name":"Tawraghudi","state_id":"51"},
    {"city_id":"5950","city_name":"Tir Pol","state_id":"51"},
    {"city_id":"5951","city_name":"Zendejan","state_id":"51"},
    {"city_id":"5952","city_name":"Baghran","state_id":"52"},
    {"city_id":"5953","city_name":"Darwishan","state_id":"52"},
    {"city_id":"5954","city_name":"Deh Shu","state_id":"52"},
    {"city_id":"5955","city_name":"Gereshk","state_id":"52"},
    {"city_id":"5956","city_name":"Lashkar Gah","state_id":"52"},
    {"city_id":"5957","city_name":"Sangin","state_id":"52"},
    {"city_id":"5958","city_name":"Aqchah","state_id":"53"},
    {"city_id":"5959","city_name":"Qarqin","state_id":"53"},
    {"city_id":"5960","city_name":"Sang-e Charak","state_id":"53"},
    {"city_id":"5961","city_name":"Shibarghan","state_id":"53"},
    {"city_id":"5962","city_name":"Baghrami","state_id":"54"},
    {"city_id":"5963","city_name":"Kabul","state_id":"54"},
    {"city_id":"5964","city_name":"Mir Bachchekut","state_id":"54"},
    {"city_id":"5965","city_name":"Paghman","state_id":"54"},
    {"city_id":"5966","city_name":"Qarabagh","state_id":"54"},
    {"city_id":"5967","city_name":"Sarawbi","state_id":"54"},
    {"city_id":"5968","city_name":"Mahmud-e Raqi","state_id":"55"},
    {"city_id":"5969","city_name":"Taghab","state_id":"55"},
    {"city_id":"5970","city_name":"Khawst","state_id":"56"},
    {"city_id":"5971","city_name":"Asadabad","state_id":"57"},
    {"city_id":"5972","city_name":"Asmar","state_id":"57"},
    {"city_id":"5973","city_name":"Mehtar Lam","state_id":"58"},
    {"city_id":"5974","city_name":"Azraw","state_id":"59"},
    {"city_id":"5975","city_name":"Baraki Barak","state_id":"59"},
    {"city_id":"5976","city_name":"Pol-e Alam","state_id":"59"},
    {"city_id":"5977","city_name":"Achin","state_id":"60"},
    {"city_id":"5978","city_name":"Batsawul","state_id":"60"},
    {"city_id":"5979","city_name":"Hugyani","state_id":"60"},
    {"city_id":"5980","city_name":"Jalalabad","state_id":"60"},
    {"city_id":"5981","city_name":"Nader Shah Kawt","state_id":"60"},
    {"city_id":"5982","city_name":"Chahar Burjak","state_id":"61"},
    {"city_id":"5983","city_name":"Chakhansur","state_id":"61"},
    {"city_id":"5984","city_name":"Khash","state_id":"61"},
    {"city_id":"5985","city_name":"Mirabad","state_id":"61"},
    {"city_id":"5986","city_name":"Rudbar","state_id":"61"},
    {"city_id":"5987","city_name":"Zaranj","state_id":"61"},
    {"city_id":"5988","city_name":"Nuristan","state_id":"62"},
    {"city_id":"5989","city_name":"Orgun","state_id":"63"},
    {"city_id":"5990","city_name":"Zareh Sharan","state_id":"63"},
    {"city_id":"5991","city_name":"Zarghun Shahr","state_id":"63"},
    {"city_id":"5992","city_name":"'Ali Khayl","state_id":"64"},
    {"city_id":"5993","city_name":"Ghardez","state_id":"64"},
    {"city_id":"5994","city_name":"Charikar","state_id":"65"},
    {"city_id":"5995","city_name":"Jabal-os-Saraj","state_id":"65"},
    {"city_id":"5996","city_name":"Qandahar","state_id":"66"},
    {"city_id":"5997","city_name":"Dasht-e Archa","state_id":"67"},
    {"city_id":"5998","city_name":"Emam Saheb","state_id":"67"},
    {"city_id":"5999","city_name":"Hazart Imam","state_id":"67"},
    {"city_id":"6000","city_name":"Khanabad","state_id":"67"},
    {"city_id":"6001","city_name":"Qal'eh-ye Zal","state_id":"67"},
    {"city_id":"6002","city_name":"Qunduz","state_id":"67"},
    {"city_id":"6003","city_name":"Aybak","state_id":"68"},
    {"city_id":"6004","city_name":"Kholm","state_id":"68"},
    {"city_id":"6005","city_name":"Chah Ab","state_id":"70"},
    {"city_id":"6006","city_name":"Eshkamesh","state_id":"70"},
    {"city_id":"6007","city_name":"Farkhar","state_id":"70"},
    {"city_id":"6008","city_name":"Khwajeh Ghar","state_id":"70"},
    {"city_id":"6009","city_name":"Rostaq","state_id":"70"},
    {"city_id":"6010","city_name":"Taloqan","state_id":"70"},
    {"city_id":"6011","city_name":"Yangi Qal'eh","state_id":"70"},
    {"city_id":"6012","city_name":"Deh Rawud","state_id":"71"},
    {"city_id":"6013","city_name":"Gaz Ab","state_id":"71"},
    {"city_id":"6014","city_name":"Tarin Kawt","state_id":"71"},
    {"city_id":"6015","city_name":"Uruzgan","state_id":"71"},
    {"city_id":"6016","city_name":"Gardan Diwal","state_id":"72"},
    {"city_id":"6017","city_name":"Maydanshahr","state_id":"72"},
    {"city_id":"6018","city_name":"Qalat-e Ghilzay","state_id":"73"},
    {"city_id":"6019","city_name":"Berat","state_id":"74"},
    {"city_id":"6020","city_name":"Polican","state_id":"74"},
    {"city_id":"6021","city_name":"Ure Vajgurore","state_id":"74"},
    {"city_id":"6022","city_name":"Bulqize","state_id":"75"},
    {"city_id":"6023","city_name":"Delvine","state_id":"76"},
    {"city_id":"6024","city_name":"Bilisht","state_id":"77"},
    {"city_id":"6025","city_name":"Maqellare","state_id":"78"},
    {"city_id":"6026","city_name":"Peshkopi","state_id":"78"},
    {"city_id":"6027","city_name":"Durres","state_id":"79"},
    {"city_id":"6028","city_name":"Shijak","state_id":"79"},
    {"city_id":"6029","city_name":"Cerrik","state_id":"80"},
    {"city_id":"6030","city_name":"Elbasan","state_id":"80"},
    {"city_id":"6031","city_name":"Kerrabe","state_id":"80"},
    {"city_id":"6032","city_name":"Fier","state_id":"81"},
    {"city_id":"6033","city_name":"Patos","state_id":"81"},
    {"city_id":"6034","city_name":"Roskovec","state_id":"81"},
    {"city_id":"6035","city_name":"Gjirokaster","state_id":"82"},
    {"city_id":"6036","city_name":"Libohove","state_id":"82"},
    {"city_id":"6037","city_name":"Gramsh","state_id":"83"},
    {"city_id":"6038","city_name":"Krume","state_id":"84"},
    {"city_id":"6039","city_name":"Kavaje","state_id":"85"},
    {"city_id":"6040","city_name":"Erseke","state_id":"86"},
    {"city_id":"6041","city_name":"Leskovik","state_id":"86"},
    {"city_id":"6042","city_name":"Korce","state_id":"87"},
    {"city_id":"6043","city_name":"Maliq","state_id":"87"},
    {"city_id":"6044","city_name":"Fushe-Kruje","state_id":"88"},
    {"city_id":"6045","city_name":"Kruje","state_id":"88"},
    {"city_id":"6046","city_name":"Mamurras","state_id":"88"},
    {"city_id":"6047","city_name":"Milot","state_id":"88"},
    {"city_id":"6048","city_name":"Kucove","state_id":"89"},
    {"city_id":"6049","city_name":"Kukes","state_id":"90"},
    {"city_id":"6050","city_name":"Lac","state_id":"91"},
    {"city_id":"6051","city_name":"Lezhe","state_id":"92"},
    {"city_id":"6052","city_name":"Shengjin","state_id":"92"},
    {"city_id":"6053","city_name":"Librazhd","state_id":"93"},
    {"city_id":"6054","city_name":"Perrenjas","state_id":"93"},
    {"city_id":"6055","city_name":"Lushnje","state_id":"94"},
    {"city_id":"6056","city_name":"Rrogozhine","state_id":"94"},
    {"city_id":"6057","city_name":"Ballsh","state_id":"95"},
    {"city_id":"6058","city_name":"Burrel","state_id":"97"},
    {"city_id":"6059","city_name":"Klos","state_id":"97"},
    {"city_id":"6060","city_name":"Kraste","state_id":"97"},
    {"city_id":"6061","city_name":"Ulze","state_id":"97"},
    {"city_id":"6062","city_name":"Kurbnesh","state_id":"98"},
    {"city_id":"6063","city_name":"Rreshen","state_id":"98"},
    {"city_id":"6064","city_name":"Rubik","state_id":"98"},
    {"city_id":"6065","city_name":"Peqin","state_id":"99"},
    {"city_id":"6066","city_name":"Kelcyre","state_id":"100"},
    {"city_id":"6067","city_name":"Permet","state_id":"100"},
    {"city_id":"6068","city_name":"Pogradec","state_id":"101"},
    {"city_id":"6069","city_name":"Fierze","state_id":"102"},
    {"city_id":"6070","city_name":"Fushe-Arrez","state_id":"102"},
    {"city_id":"6071","city_name":"Puke","state_id":"102"},
    {"city_id":"6072","city_name":"Konispol","state_id":"103"},
    {"city_id":"6073","city_name":"Sarande","state_id":"103"},
    {"city_id":"6074","city_name":"Shkoder","state_id":"104"},
    {"city_id":"6075","city_name":"Corovode","state_id":"105"},
    {"city_id":"6076","city_name":"Memaliaj","state_id":"106"},
    {"city_id":"6077","city_name":"Tepelene","state_id":"106"},
    {"city_id":"6078","city_name":"Kamze","state_id":"107"},
    {"city_id":"6079","city_name":"Paskuqan","state_id":"107"},
    {"city_id":"6080","city_name":"Tirane","state_id":"107"},
    {"city_id":"6081","city_name":"Vore","state_id":"107"},
    {"city_id":"6082","city_name":"Bajram Curri","state_id":"108"},
    {"city_id":"6083","city_name":"Himare","state_id":"109"},
    {"city_id":"6084","city_name":"Orikum","state_id":"109"},
    {"city_id":"6085","city_name":"Selenice","state_id":"109"},
    {"city_id":"6086","city_name":"Vlore","state_id":"109"},
    {"city_id":"6087","city_name":"Adrar","state_id":"112"},
    {"city_id":"6088","city_name":"Awlaf","state_id":"112"},
    {"city_id":"6089","city_name":"Rijan","state_id":"112"},
    {"city_id":"6090","city_name":"Timimun","state_id":"112"},
    {"city_id":"6091","city_name":"Hydra","state_id":"113"},
    {"city_id":"6092","city_name":"Kouba","state_id":"113"},
    {"city_id":"6093","city_name":"Annabah","state_id":"114"},
    {"city_id":"6094","city_name":"Birrahhal","state_id":"114"},
    {"city_id":"6095","city_name":"Saraydih","state_id":"114"},
    {"city_id":"6096","city_name":"Sidi Amar","state_id":"114"},
    {"city_id":"6097","city_name":"al-Buni","state_id":"114"},
    {"city_id":"6098","city_name":"al-Hajar","state_id":"114"},
    {"city_id":"6099","city_name":"'Abadlah","state_id":"115"},
    {"city_id":"6100","city_name":"Bani Wanif","state_id":"115"},
    {"city_id":"6101","city_name":"Bashshar","state_id":"115"},
    {"city_id":"6102","city_name":"Qanadsan","state_id":"115"},
    {"city_id":"6103","city_name":"Taghit","state_id":"115"},
    {"city_id":"6104","city_name":"'Aris","state_id":"116"},
    {"city_id":"6105","city_name":"'Ayn Tutah","state_id":"116"},
    {"city_id":"6106","city_name":"Barikah","state_id":"116"},
    {"city_id":"6107","city_name":"Batnah","state_id":"116"},
    {"city_id":"6108","city_name":"Marwanah","state_id":"116"},
    {"city_id":"6109","city_name":"Naghaus","state_id":"116"},
    {"city_id":"6110","city_name":"Ra's-al-'Ayun","state_id":"116"},
    {"city_id":"6111","city_name":"Tazult","state_id":"116"},
    {"city_id":"6112","city_name":"'Ayt Rizin","state_id":"117"},
    {"city_id":"6113","city_name":"Akbu","state_id":"117"},
    {"city_id":"6114","city_name":"Amizur","state_id":"117"},
    {"city_id":"6115","city_name":"Barbasha","state_id":"117"},
    {"city_id":"6116","city_name":"Bijayah","state_id":"117"},
    {"city_id":"6117","city_name":"Farrawn","state_id":"117"},
    {"city_id":"6118","city_name":"Ighram","state_id":"117"},
    {"city_id":"6119","city_name":"Sadduk","state_id":"117"},
    {"city_id":"6120","city_name":"Shamini","state_id":"117"},
    {"city_id":"6121","city_name":"Sidi 'Aysh","state_id":"117"},
    {"city_id":"6122","city_name":"Taskaryut","state_id":"117"},
    {"city_id":"6123","city_name":"Tazmalt","state_id":"117"},
    {"city_id":"6124","city_name":"Timazrit","state_id":"117"},
    {"city_id":"6125","city_name":"Uz-al-Laqin","state_id":"117"},
    {"city_id":"6126","city_name":"al-Qasr","state_id":"117"},
    {"city_id":"6127","city_name":"Awlad Jallal","state_id":"118"},
    {"city_id":"6128","city_name":"Biskrah","state_id":"118"},
    {"city_id":"6129","city_name":"Sidi Khalid","state_id":"118"},
    {"city_id":"6130","city_name":"Sidi Ukbah","state_id":"118"},
    {"city_id":"6131","city_name":"Tulja","state_id":"118"},
    {"city_id":"6132","city_name":"Um'ash","state_id":"118"},
    {"city_id":"6133","city_name":"Zaribat-al-Wad","state_id":"118"},
    {"city_id":"6134","city_name":"Awlad Salam","state_id":"119"},
    {"city_id":"6135","city_name":"Awlad Yaysh","state_id":"119"},
    {"city_id":"6136","city_name":"Bani Khalil","state_id":"119"},
    {"city_id":"6137","city_name":"Bani Marad","state_id":"119"},
    {"city_id":"6138","city_name":"Bani Tamu","state_id":"119"},
    {"city_id":"6139","city_name":"Blidah","state_id":"119"},
    {"city_id":"6140","city_name":"Bu Arfa","state_id":"119"},
    {"city_id":"6141","city_name":"Bufarik","state_id":"119"},
    {"city_id":"6142","city_name":"Buinan","state_id":"119"},
    {"city_id":"6143","city_name":"Buqara","state_id":"119"},
    {"city_id":"6144","city_name":"Maftah","state_id":"119"},
    {"city_id":"6145","city_name":"Muzayah","state_id":"119"},
    {"city_id":"6146","city_name":"Shabli","state_id":"119"},
    {"city_id":"6147","city_name":"Shari'ah","state_id":"119"},
    {"city_id":"6148","city_name":"Shiffa","state_id":"119"},
    {"city_id":"6149","city_name":"Sidi Mussa","state_id":"119"},
    {"city_id":"6150","city_name":"Suma","state_id":"119"},
    {"city_id":"6151","city_name":"Wadi al-Allagh","state_id":"119"},
    {"city_id":"6152","city_name":"al-'Afrun","state_id":"119"},
    {"city_id":"6153","city_name":"al-Arba'a","state_id":"119"},
    {"city_id":"6154","city_name":"'Ayn Bissim","state_id":"120"},
    {"city_id":"6155","city_name":"Aghbalu","state_id":"120"},
    {"city_id":"6156","city_name":"Bi'r Ghabalu","state_id":"120"},
    {"city_id":"6157","city_name":"Buirah","state_id":"120"},
    {"city_id":"6158","city_name":"Lakhdariyah","state_id":"120"},
    {"city_id":"6159","city_name":"Shurfa","state_id":"120"},
    {"city_id":"6160","city_name":"Sur-al-Ghuzlan","state_id":"120"},
    {"city_id":"6161","city_name":"'Ayn Tayah","state_id":"121"},
    {"city_id":"6162","city_name":"Awlad Haddaj","state_id":"121"},
    {"city_id":"6163","city_name":"Awlad Mussa","state_id":"121"},
    {"city_id":"6164","city_name":"Bani Amran","state_id":"121"},
    {"city_id":"6165","city_name":"Budwawu","state_id":"121"},
    {"city_id":"6166","city_name":"Budwawu al-Bahri","state_id":"121"},
    {"city_id":"6167","city_name":"Bumardas","state_id":"121"},
    {"city_id":"6168","city_name":"Burj Minayal","state_id":"121"},
    {"city_id":"6169","city_name":"Dalis","state_id":"121"},
    {"city_id":"6170","city_name":"Hammadi","state_id":"121"},
    {"city_id":"6171","city_name":"Issar","state_id":"121"},
    {"city_id":"6172","city_name":"Khamis-al-Khashnah","state_id":"121"},
    {"city_id":"6173","city_name":"Nasiriyah","state_id":"121"},
    {"city_id":"6174","city_name":"Raghayah","state_id":"121"},
    {"city_id":"6175","city_name":"Sa'abat","state_id":"121"},
    {"city_id":"6176","city_name":"Tinyah","state_id":"121"},
    {"city_id":"6177","city_name":"al-Arba'a Tash","state_id":"121"},
    {"city_id":"6178","city_name":"ar-Ruwibah","state_id":"121"},
    {"city_id":"6179","city_name":"Ammi Mussa","state_id":"123"},
    {"city_id":"6180","city_name":"Ghalizan","state_id":"123"},
    {"city_id":"6181","city_name":"Jidiwiyah","state_id":"123"},
    {"city_id":"6182","city_name":"Mazunah","state_id":"123"},
    {"city_id":"6183","city_name":"Sidi Muhammad Ban 'Ali","state_id":"123"},
    {"city_id":"6184","city_name":"Wadi Rahiyu","state_id":"123"},
    {"city_id":"6185","city_name":"Zammurah","state_id":"123"},
    {"city_id":"6186","city_name":"Biryan","state_id":"124"},
    {"city_id":"6187","city_name":"Bu Nura","state_id":"124"},
    {"city_id":"6188","city_name":"Ghardaia","state_id":"124"},
    {"city_id":"6189","city_name":"Ghardayah","state_id":"124"},
    {"city_id":"6190","city_name":"Matlili","state_id":"124"},
    {"city_id":"6191","city_name":"al-Ghuli'ah","state_id":"124"},
    {"city_id":"6192","city_name":"al-Qararah","state_id":"124"},
    {"city_id":"6193","city_name":"Ilizi","state_id":"125"},
    {"city_id":"6194","city_name":"Amir 'Abd-al-Qadar","state_id":"126"},
    {"city_id":"6195","city_name":"Jijili","state_id":"126"},
    {"city_id":"6196","city_name":"Shifka","state_id":"126"},
    {"city_id":"6197","city_name":"Tahar","state_id":"126"},
    {"city_id":"6198","city_name":"al-Miliyah","state_id":"126"},
    {"city_id":"6199","city_name":"'Ayn Wissarah","state_id":"127"},
    {"city_id":"6200","city_name":"'Ayn-al-Ibil","state_id":"127"},
    {"city_id":"6201","city_name":"Birin","state_id":"127"},
    {"city_id":"6202","city_name":"Dar Shiyukh","state_id":"127"},
    {"city_id":"6203","city_name":"Hassi Bahbah","state_id":"127"},
    {"city_id":"6204","city_name":"Jilfah","state_id":"127"},
    {"city_id":"6205","city_name":"Mis'ad","state_id":"127"},
    {"city_id":"6206","city_name":"Sharif","state_id":"127"},
    {"city_id":"6207","city_name":"al-Idrisiyah","state_id":"127"},
    {"city_id":"6208","city_name":"Khanshalah","state_id":"128"},
    {"city_id":"6209","city_name":"Sharshar","state_id":"128"},
    {"city_id":"6210","city_name":"Tawziyanat","state_id":"128"},
    {"city_id":"6211","city_name":"al-Mahmal","state_id":"128"},
    {"city_id":"6212","city_name":"'Ayn-al-Hajal","state_id":"129"},
    {"city_id":"6213","city_name":"'Ayn-al-Milh","state_id":"129"},
    {"city_id":"6214","city_name":"Bu Sa'adah","state_id":"129"},
    {"city_id":"6215","city_name":"Hammam Dhala'a","state_id":"129"},
    {"city_id":"6216","city_name":"Ma'adid","state_id":"129"},
    {"city_id":"6217","city_name":"Maghra","state_id":"129"},
    {"city_id":"6218","city_name":"Masilah","state_id":"129"},
    {"city_id":"6219","city_name":"Sidi 'Aysa","state_id":"129"},
    {"city_id":"6220","city_name":"Wanugha","state_id":"129"},
    {"city_id":"6221","city_name":"'Ayn Bu Sif","state_id":"130"},
    {"city_id":"6222","city_name":"Birwaghiyah","state_id":"130"},
    {"city_id":"6223","city_name":"Midyah","state_id":"130"},
    {"city_id":"6224","city_name":"Qasr-al-Bukhari","state_id":"130"},
    {"city_id":"6225","city_name":"Shillalah","state_id":"130"},
    {"city_id":"6226","city_name":"Tablat","state_id":"130"},
    {"city_id":"6227","city_name":"Farjiwah","state_id":"131"},
    {"city_id":"6228","city_name":"Milah","state_id":"131"},
    {"city_id":"6229","city_name":"Qararam Quqa","state_id":"131"},
    {"city_id":"6230","city_name":"Ruwashad","state_id":"131"},
    {"city_id":"6231","city_name":"Salghum-al-'Ayd","state_id":"131"},
    {"city_id":"6232","city_name":"Sidi Maruf","state_id":"131"},
    {"city_id":"6233","city_name":"Sidi Marwan","state_id":"131"},
    {"city_id":"6234","city_name":"Tajananah","state_id":"131"},
    {"city_id":"6235","city_name":"Talighmah","state_id":"131"},
    {"city_id":"6236","city_name":"Wadi Athmaniyah","state_id":"131"},
    {"city_id":"6237","city_name":"Bu Khanifiyah","state_id":"132"},
    {"city_id":"6238","city_name":"Muaskar","state_id":"132"},
    {"city_id":"6239","city_name":"Muhammadiyah","state_id":"132"},
    {"city_id":"6240","city_name":"Siq","state_id":"132"},
    {"city_id":"6241","city_name":"Tighinnif","state_id":"132"},
    {"city_id":"6242","city_name":"Wadi al-Abtal","state_id":"132"},
    {"city_id":"6243","city_name":"Zahana","state_id":"132"},
    {"city_id":"6244","city_name":"'Ayn Tadalas","state_id":"133"},
    {"city_id":"6245","city_name":"Hassi Mamash","state_id":"133"},
    {"city_id":"6246","city_name":"Mazaghran","state_id":"133"},
    {"city_id":"6247","city_name":"Mustaghanam","state_id":"133"},
    {"city_id":"6248","city_name":"Sidi Ali","state_id":"133"},
    {"city_id":"6249","city_name":"'Ayn Safra","state_id":"134"},
    {"city_id":"6250","city_name":"Mishriyah","state_id":"134"},
    {"city_id":"6251","city_name":"Naama","state_id":"134"},
    {"city_id":"6252","city_name":"Oran","state_id":"135"},
    {"city_id":"6253","city_name":"Ouargla","state_id":"136"},
    {"city_id":"6254","city_name":"'Ayn Bardah","state_id":"137"},
    {"city_id":"6255","city_name":"Bumahra Ahmad","state_id":"137"},
    {"city_id":"6256","city_name":"Hamman Awlad 'Ali","state_id":"137"},
    {"city_id":"6257","city_name":"Qalmah","state_id":"137"},
    {"city_id":"6258","city_name":"Wadi Zinati","state_id":"137"},
    {"city_id":"6259","city_name":"'Ayn Abid","state_id":"138"},
    {"city_id":"6260","city_name":"'Ayn Samara","state_id":"138"},
    {"city_id":"6261","city_name":"Didush Murad","state_id":"138"},
    {"city_id":"6262","city_name":"Hamma Bu Ziyan","state_id":"138"},
    {"city_id":"6263","city_name":"Qustantinah","state_id":"138"},
    {"city_id":"6264","city_name":"Zighut Yusuf","state_id":"138"},
    {"city_id":"6265","city_name":"al-Khurub","state_id":"138"},
    {"city_id":"6266","city_name":"'Azzabah","state_id":"139"},
    {"city_id":"6267","city_name":"Amjaz Adshish","state_id":"139"},
    {"city_id":"6268","city_name":"Fil Fila","state_id":"139"},
    {"city_id":"6269","city_name":"Karkira","state_id":"139"},
    {"city_id":"6270","city_name":"Ramadan Jamal","state_id":"139"},
    {"city_id":"6271","city_name":"Sakikdah","state_id":"139"},
    {"city_id":"6272","city_name":"Shataybih","state_id":"139"},
    {"city_id":"6273","city_name":"Tamalus","state_id":"139"},
    {"city_id":"6274","city_name":"al-Harush","state_id":"139"},
    {"city_id":"6275","city_name":"al-Qull","state_id":"139"},
    {"city_id":"6276","city_name":"'Ayn 'Azl","state_id":"140"},
    {"city_id":"6277","city_name":"'Ayn Arnat","state_id":"140"},
    {"city_id":"6278","city_name":"'Ayn Taqrut","state_id":"140"},
    {"city_id":"6279","city_name":"'Ayn Wilman","state_id":"140"},
    {"city_id":"6280","city_name":"'Ayn-al-Khabira","state_id":"140"},
    {"city_id":"6281","city_name":"Bouira","state_id":"140"},
    {"city_id":"6282","city_name":"Buq'ah","state_id":"140"},
    {"city_id":"6283","city_name":"Salah Bay","state_id":"140"},
    {"city_id":"6284","city_name":"Satif","state_id":"140"},
    {"city_id":"6285","city_name":"Setif","state_id":"140"},
    {"city_id":"6286","city_name":"Ziyama Mansuriyah","state_id":"140"},
    {"city_id":"6287","city_name":"al-'Ulmah","state_id":"140"},
    {"city_id":"6288","city_name":"'Ayn-al-Hajar","state_id":"141"},
    {"city_id":"6289","city_name":"Sayda'","state_id":"141"},
    {"city_id":"6290","city_name":"'Ayn Qazzan","state_id":"144"},
    {"city_id":"6291","city_name":"'Ayn Salah","state_id":"144"},
    {"city_id":"6292","city_name":"Tamanghasat","state_id":"144"},
    {"city_id":"6293","city_name":"'Ayn Binyan","state_id":"145"},
    {"city_id":"6294","city_name":"Bu Isma'il","state_id":"145"},
    {"city_id":"6295","city_name":"Bu Midfar'ah","state_id":"145"},
    {"city_id":"6296","city_name":"Damus","state_id":"145"},
    {"city_id":"6297","city_name":"Duwirah","state_id":"145"},
    {"city_id":"6298","city_name":"Hajut","state_id":"145"},
    {"city_id":"6299","city_name":"Hammam Righa","state_id":"145"},
    {"city_id":"6300","city_name":"Sawlah","state_id":"145"},
    {"city_id":"6301","city_name":"Shiragha","state_id":"145"},
    {"city_id":"6302","city_name":"Shirshall","state_id":"145"},
    {"city_id":"6303","city_name":"Sidi Farj","state_id":"145"},
    {"city_id":"6304","city_name":"Stawali","state_id":"145"},
    {"city_id":"6305","city_name":"Tibazah","state_id":"145"},
    {"city_id":"6306","city_name":"Ziralda","state_id":"145"},
    {"city_id":"6307","city_name":"al-Qull'ah","state_id":"145"},
    {"city_id":"6308","city_name":"Bi'r-al-'Itir","state_id":"146"},
    {"city_id":"6309","city_name":"Hammamat","state_id":"146"},
    {"city_id":"6310","city_name":"Mursut","state_id":"146"},
    {"city_id":"6311","city_name":"Shariyah","state_id":"146"},
    {"city_id":"6312","city_name":"Tibissah","state_id":"146"},
    {"city_id":"6313","city_name":"Winzah","state_id":"146"},
    {"city_id":"6314","city_name":"al-'Awaynat","state_id":"146"},
    {"city_id":"6315","city_name":"Awlad Mimun","state_id":"147"},
    {"city_id":"6316","city_name":"Bani Mastar","state_id":"147"},
    {"city_id":"6317","city_name":"Bani Sikran","state_id":"147"},
    {"city_id":"6318","city_name":"Ghazawat","state_id":"147"},
    {"city_id":"6319","city_name":"Hannayah","state_id":"147"},
    {"city_id":"6320","city_name":"Maghniyah","state_id":"147"},
    {"city_id":"6321","city_name":"Nidruma","state_id":"147"},
    {"city_id":"6322","city_name":"Ramsh","state_id":"147"},
    {"city_id":"6323","city_name":"Sabra","state_id":"147"},
    {"city_id":"6324","city_name":"Shatwan","state_id":"147"},
    {"city_id":"6325","city_name":"Sibdu","state_id":"147"},
    {"city_id":"6326","city_name":"Sidi 'Abdallah","state_id":"147"},
    {"city_id":"6327","city_name":"Tilimsan","state_id":"147"},
    {"city_id":"6328","city_name":"al-Mansurah","state_id":"147"},
    {"city_id":"6329","city_name":"Tinduf","state_id":"148"},
    {"city_id":"6330","city_name":"Thaniyat-al-Had","state_id":"149"},
    {"city_id":"6331","city_name":"Tisamsilt","state_id":"149"},
    {"city_id":"6332","city_name":"'Ayn Dhahab","state_id":"150"},
    {"city_id":"6333","city_name":"Firindah","state_id":"150"},
    {"city_id":"6334","city_name":"Mahdiyah","state_id":"150"},
    {"city_id":"6335","city_name":"Mashra'a Asfa","state_id":"150"},
    {"city_id":"6336","city_name":"Qasr Shillalah","state_id":"150"},
    {"city_id":"6337","city_name":"Rahuyah","state_id":"150"},
    {"city_id":"6338","city_name":"Sughar","state_id":"150"},
    {"city_id":"6339","city_name":"Takhamarat","state_id":"150"},
    {"city_id":"6340","city_name":"Tiyarat","state_id":"150"},
    {"city_id":"6341","city_name":"'Ayn Bayda","state_id":"152"},
    {"city_id":"6342","city_name":"'Ayn Fakrun","state_id":"152"},
    {"city_id":"6343","city_name":"'Ayn Kirshah","state_id":"152"},
    {"city_id":"6344","city_name":"'Ayn Malilah","state_id":"152"},
    {"city_id":"6345","city_name":"Bi'r Shuhada","state_id":"152"},
    {"city_id":"6346","city_name":"Miskyanah","state_id":"152"},
    {"city_id":"6347","city_name":"Shamurah","state_id":"152"},
    {"city_id":"6348","city_name":"Umm-al-Bawaghi","state_id":"152"},
    {"city_id":"6349","city_name":"'Ayn Biya","state_id":"153"},
    {"city_id":"6350","city_name":"'Ayn-at-Turk","state_id":"153"},
    {"city_id":"6351","city_name":"Arzu","state_id":"153"},
    {"city_id":"6352","city_name":"Bi'r-al-Jir","state_id":"153"},
    {"city_id":"6353","city_name":"Butlilis","state_id":"153"},
    {"city_id":"6354","city_name":"Hassi Bu Nif","state_id":"153"},
    {"city_id":"6355","city_name":"Mars-al-Kabir","state_id":"153"},
    {"city_id":"6356","city_name":"Qadayal","state_id":"153"},
    {"city_id":"6357","city_name":"Sidi ash-Shami","state_id":"153"},
    {"city_id":"6358","city_name":"Wadi Thalatha","state_id":"153"},
    {"city_id":"6359","city_name":"Wahran","state_id":"153"},
    {"city_id":"6360","city_name":"al-Ansur","state_id":"153"},
    {"city_id":"6361","city_name":"as-Saniyah","state_id":"153"},
    {"city_id":"6362","city_name":"Hassi Mas'ud","state_id":"154"},
    {"city_id":"6363","city_name":"Nazla","state_id":"154"},
    {"city_id":"6364","city_name":"Ruwisiyat","state_id":"154"},
    {"city_id":"6365","city_name":"Tabisbast","state_id":"154"},
    {"city_id":"6366","city_name":"Tamalhat","state_id":"154"},
    {"city_id":"6367","city_name":"Tamasin","state_id":"154"},
    {"city_id":"6368","city_name":"Tayabat-al-Janubiyah","state_id":"154"},
    {"city_id":"6369","city_name":"Tughghurt","state_id":"154"},
    {"city_id":"6370","city_name":"Warqla","state_id":"154"},
    {"city_id":"6371","city_name":"al-Hajirah","state_id":"154"},
    {"city_id":"6372","city_name":"Aflu","state_id":"158"},
    {"city_id":"6373","city_name":"Hassi al-Raml","state_id":"158"},
    {"city_id":"6374","city_name":"al-Aghwat","state_id":"158"},
    {"city_id":"6375","city_name":"Brizyanah","state_id":"159"},
    {"city_id":"6376","city_name":"al-Abyad Sidi Shaykh","state_id":"159"},
    {"city_id":"6377","city_name":"al-Bayadh","state_id":"159"},
    {"city_id":"6378","city_name":"Bab Azwar","state_id":"160"},
    {"city_id":"6379","city_name":"Baraki","state_id":"160"},
    {"city_id":"6380","city_name":"Bir Murad Rais","state_id":"160"},
    {"city_id":"6381","city_name":"Birkhadam","state_id":"160"},
    {"city_id":"6382","city_name":"Burj-al-Kiffan","state_id":"160"},
    {"city_id":"6383","city_name":"Dar-al-Bayda","state_id":"160"},
    {"city_id":"6384","city_name":"al-Jaza'ir","state_id":"160"},
    {"city_id":"6385","city_name":"Bayadha","state_id":"161"},
    {"city_id":"6386","city_name":"Dabilah","state_id":"161"},
    {"city_id":"6387","city_name":"Hassan 'Abd-al-Karim","state_id":"161"},
    {"city_id":"6388","city_name":"Hassi Halifa","state_id":"161"},
    {"city_id":"6389","city_name":"Jama'a","state_id":"161"},
    {"city_id":"6390","city_name":"Maqran","state_id":"161"},
    {"city_id":"6391","city_name":"Qamar","state_id":"161"},
    {"city_id":"6392","city_name":"Raqiba","state_id":"161"},
    {"city_id":"6393","city_name":"Rubbah","state_id":"161"},
    {"city_id":"6394","city_name":"Sidi Amran","state_id":"161"},
    {"city_id":"6395","city_name":"al-Mighair","state_id":"161"},
    {"city_id":"6396","city_name":"al-Wad","state_id":"161"},
    {"city_id":"6397","city_name":"'Ayn Maran","state_id":"162"},
    {"city_id":"6398","city_name":"Abu al-Hassan","state_id":"162"},
    {"city_id":"6399","city_name":"Bani Hawa","state_id":"162"},
    {"city_id":"6400","city_name":"Bu Qadir","state_id":"162"},
    {"city_id":"6401","city_name":"Sidi Ukaskah","state_id":"162"},
    {"city_id":"6402","city_name":"Tanas","state_id":"162"},
    {"city_id":"6403","city_name":"Wadi Sali","state_id":"162"},
    {"city_id":"6404","city_name":"Wadi al-Fiddah","state_id":"162"},
    {"city_id":"6405","city_name":"ash-Shalif","state_id":"162"},
    {"city_id":"6406","city_name":"ash-Shattiyah","state_id":"162"},
    {"city_id":"6407","city_name":"Ban Mahdi","state_id":"163"},
    {"city_id":"6408","city_name":"Bani Amar","state_id":"163"},
    {"city_id":"6409","city_name":"Basbas","state_id":"163"},
    {"city_id":"6410","city_name":"Dariyan","state_id":"163"},
    {"city_id":"6411","city_name":"Saba'ita Muk","state_id":"163"},
    {"city_id":"6412","city_name":"al-Qal'ah","state_id":"163"},
    {"city_id":"6413","city_name":"at-Tarif","state_id":"163"},
    {"city_id":"6414","city_name":"Afono","state_id":"164"},
    {"city_id":"6415","city_name":"Alao","state_id":"164"},
    {"city_id":"6416","city_name":"Alofau","state_id":"164"},
    {"city_id":"6417","city_name":"Amouli","state_id":"164"},
    {"city_id":"6418","city_name":"Aoa","state_id":"164"},
    {"city_id":"6419","city_name":"Atu'u","state_id":"164"},
    {"city_id":"6420","city_name":"Aua","state_id":"164"},
    {"city_id":"6421","city_name":"Aunu'u","state_id":"164"},
    {"city_id":"6422","city_name":"Faga'alu","state_id":"164"},
    {"city_id":"6423","city_name":"Faga'itua","state_id":"164"},
    {"city_id":"6424","city_name":"Fagasa","state_id":"164"},
    {"city_id":"6425","city_name":"Fagatogo","state_id":"164"},
    {"city_id":"6426","city_name":"Lauli'i","state_id":"164"},
    {"city_id":"6427","city_name":"Leloaloa","state_id":"164"},
    {"city_id":"6428","city_name":"Nu'uuli","state_id":"164"},
    {"city_id":"6429","city_name":"Pago Pago","state_id":"164"},
    {"city_id":"6430","city_name":"Tula","state_id":"164"},
    {"city_id":"6431","city_name":"Utulei","state_id":"164"},
    {"city_id":"6432","city_name":"Vatia","state_id":"164"},
    {"city_id":"6433","city_name":"Ofu","state_id":"165"},
    {"city_id":"6434","city_name":"Aoloau","state_id":"167"},
    {"city_id":"6435","city_name":"Faleniu","state_id":"167"},
    {"city_id":"6436","city_name":"Futiga","state_id":"167"},
    {"city_id":"6437","city_name":"Ili'ili","state_id":"167"},
    {"city_id":"6438","city_name":"Leone","state_id":"167"},
    {"city_id":"6439","city_name":"Malaeimi","state_id":"167"},
    {"city_id":"6440","city_name":"Malaeloa-Aitulagi","state_id":"167"},
    {"city_id":"6441","city_name":"Malaeloa-Ituau","state_id":"167"},
    {"city_id":"6442","city_name":"Mapusagafou","state_id":"167"},
    {"city_id":"6443","city_name":"Mesepa","state_id":"167"},
    {"city_id":"6444","city_name":"Pava'ia'i","state_id":"167"},
    {"city_id":"6445","city_name":"Tafuna","state_id":"167"},
    {"city_id":"6446","city_name":"Taputimu","state_id":"167"},
    {"city_id":"6447","city_name":"Vailoatai","state_id":"167"},
    {"city_id":"6448","city_name":"Vaitogi","state_id":"167"},
    {"city_id":"6449","city_name":"Canillo","state_id":"169"},
    {"city_id":"6450","city_name":"Encamp","state_id":"170"},
    {"city_id":"6451","city_name":"Ordino","state_id":"173"},
    {"city_id":"6452","city_name":"Caxito","state_id":"175"},
    {"city_id":"6453","city_name":"Benguela","state_id":"176"},
    {"city_id":"6454","city_name":"Lobito","state_id":"176"},
    {"city_id":"6455","city_name":"Camacupa","state_id":"177"},
    {"city_id":"6456","city_name":"Catabola","state_id":"177"},
    {"city_id":"6457","city_name":"Catumbela","state_id":"177"},
    {"city_id":"6458","city_name":"Chissamba","state_id":"177"},
    {"city_id":"6459","city_name":"Kuito","state_id":"177"},
    {"city_id":"6460","city_name":"Cabinda","state_id":"178"},
    {"city_id":"6461","city_name":"Ondjiva","state_id":"179"},
    {"city_id":"6462","city_name":"Caala","state_id":"180"},
    {"city_id":"6463","city_name":"Catchiungo","state_id":"180"},
    {"city_id":"6464","city_name":"Huambo","state_id":"180"},
    {"city_id":"6465","city_name":"Longonjo","state_id":"180"},
    {"city_id":"6466","city_name":"Caconda","state_id":"181"},
    {"city_id":"6467","city_name":"Caluquembe","state_id":"181"},
    {"city_id":"6468","city_name":"Lubango","state_id":"181"},
    {"city_id":"6469","city_name":"Menongue","state_id":"182"},
    {"city_id":"6470","city_name":"Luanda","state_id":"185"},
    {"city_id":"6471","city_name":"Malanje","state_id":"188"},
    {"city_id":"6472","city_name":"Cazaje","state_id":"189"},
    {"city_id":"6473","city_name":"Leua","state_id":"189"},
    {"city_id":"6474","city_name":"Luau","state_id":"189"},
    {"city_id":"6475","city_name":"Luena","state_id":"189"},
    {"city_id":"6476","city_name":"Lumeje","state_id":"189"},
    {"city_id":"6477","city_name":"Namibe","state_id":"190"},
    {"city_id":"6478","city_name":"Camabatela","state_id":"191"},
    {"city_id":"6479","city_name":"Uige","state_id":"191"},
    {"city_id":"6480","city_name":"M'banza-Kongo","state_id":"192"},
    {"city_id":"6481","city_name":"N'zeto","state_id":"192"},
    {"city_id":"6482","city_name":"Soyo","state_id":"192"},
    {"city_id":"6483","city_name":"Codrington","state_id":"201"},
    {"city_id":"6484","city_name":"Catamarca","state_id":"209"},
    {"city_id":"6485","city_name":"Presidencia Roque Saenz Pena","state_id":"210"},
    {"city_id":"6486","city_name":"Resistencia","state_id":"210"},
    {"city_id":"6487","city_name":"Comodoro Rivadavia","state_id":"211"},
    {"city_id":"6488","city_name":"Puerto Madryn","state_id":"211"},
    {"city_id":"6489","city_name":"Rawson","state_id":"211"},
    {"city_id":"6490","city_name":"Trelew","state_id":"211"},
    {"city_id":"6491","city_name":"Alta Gracia","state_id":"212"},
    {"city_id":"6492","city_name":"Bell Ville","state_id":"212"},
    {"city_id":"6493","city_name":"Cordoba","state_id":"212"},
    {"city_id":"6494","city_name":"Rio Cuarto","state_id":"212"},
    {"city_id":"6495","city_name":"Rio Tercero","state_id":"212"},
    {"city_id":"6496","city_name":"San Francisco","state_id":"212"},
    {"city_id":"6497","city_name":"Villa Carlos Paz","state_id":"212"},
    {"city_id":"6498","city_name":"Villa Maria","state_id":"212"},
    {"city_id":"6499","city_name":"Corrientes","state_id":"213"},
    {"city_id":"6500","city_name":"Goya","state_id":"213"},
    {"city_id":"6501","city_name":"Mercedes","state_id":"213"},
    {"city_id":"6502","city_name":"San Lorenzo","state_id":"213"},
    {"city_id":"6503","city_name":"Formosa","state_id":"216"},
    {"city_id":"6504","city_name":"Jujuy","state_id":"217"},
    {"city_id":"6505","city_name":"Libertador General San Martin","state_id":"217"},
    {"city_id":"6506","city_name":"San Pedro","state_id":"217"},
    {"city_id":"6507","city_name":"Godoy Cruz","state_id":"220"},
    {"city_id":"6508","city_name":"Lujan","state_id":"220"},
    {"city_id":"6509","city_name":"Mendoza","state_id":"220"},
    {"city_id":"6510","city_name":"Rivadavia","state_id":"220"},
    {"city_id":"6511","city_name":"San Martin","state_id":"220"},
    {"city_id":"6512","city_name":"San Rafael","state_id":"220"},
    {"city_id":"6513","city_name":"Eldorado","state_id":"221"},
    {"city_id":"6514","city_name":"Obera","state_id":"221"},
    {"city_id":"6515","city_name":"Posadas","state_id":"221"},
    {"city_id":"6516","city_name":"Cutral Co","state_id":"222"},
    {"city_id":"6517","city_name":"Neuquen","state_id":"222"},
    {"city_id":"6518","city_name":"Salta","state_id":"224"},
    {"city_id":"6519","city_name":"San Ramon de la Nueva Oran","state_id":"224"},
    {"city_id":"6520","city_name":"Tartagal","state_id":"224"},
    {"city_id":"6521","city_name":"Tucuman","state_id":"231"},
    {"city_id":"6522","city_name":"Ashtarak","state_id":"232"},
    {"city_id":"6523","city_name":"Talin","state_id":"232"},
    {"city_id":"6524","city_name":"Ararat","state_id":"233"},
    {"city_id":"6525","city_name":"Artashat","state_id":"233"},
    {"city_id":"6526","city_name":"Armavir","state_id":"234"},
    {"city_id":"6527","city_name":"Vagharshapat","state_id":"234"},
    {"city_id":"6528","city_name":"Gavar","state_id":"235"},
    {"city_id":"6529","city_name":"Martuni","state_id":"235"},
    {"city_id":"6530","city_name":"Sevan","state_id":"235"},
    {"city_id":"6531","city_name":"Vardenis","state_id":"235"},
    {"city_id":"6532","city_name":"Abovyan","state_id":"236"},
    {"city_id":"6533","city_name":"Arzni","state_id":"236"},
    {"city_id":"6534","city_name":"Charentsavan","state_id":"236"},
    {"city_id":"6535","city_name":"Hrazdan","state_id":"236"},
    {"city_id":"6536","city_name":"Akhtala","state_id":"237"},
    {"city_id":"6537","city_name":"Alaverdi","state_id":"237"},
    {"city_id":"6538","city_name":"Spitak","state_id":"237"},
    {"city_id":"6539","city_name":"Stepanavan","state_id":"237"},
    {"city_id":"6540","city_name":"Tashir","state_id":"237"},
    {"city_id":"6541","city_name":"Vanadzor","state_id":"237"},
    {"city_id":"6542","city_name":"Artik","state_id":"238"},
    {"city_id":"6543","city_name":"Gyumri","state_id":"238"},
    {"city_id":"6544","city_name":"Stepanakert","state_id":"239"},
    {"city_id":"6545","city_name":"Angeghakot","state_id":"240"},
    {"city_id":"6546","city_name":"Goris","state_id":"240"},
    {"city_id":"6547","city_name":"Kajaran","state_id":"240"},
    {"city_id":"6548","city_name":"Kapan","state_id":"240"},
    {"city_id":"6549","city_name":"Meghri","state_id":"240"},
    {"city_id":"6550","city_name":"Sisian","state_id":"240"},
    {"city_id":"6551","city_name":"Dilijan","state_id":"241"},
    {"city_id":"6552","city_name":"Ijevan","state_id":"241"},
    {"city_id":"6553","city_name":"Yerevan","state_id":"243"},
    {"city_id":"6554","city_name":"Aruba","state_id":"244"},
    {"city_id":"6555","city_name":"Glenfield","state_id":"245"},
    {"city_id":"6556","city_name":"Henderson","state_id":"245"},
    {"city_id":"6557","city_name":"Milford","state_id":"245"},
    {"city_id":"6558","city_name":"Balgowlah","state_id":"247"},
    {"city_id":"6559","city_name":"Balmain","state_id":"248"},
    {"city_id":"6560","city_name":"Bankstown","state_id":"249"},
    {"city_id":"6561","city_name":"Camberwell","state_id":"252"},
    {"city_id":"6562","city_name":"Caulfield","state_id":"255"},
    {"city_id":"6563","city_name":"Chatswood","state_id":"256"},
    {"city_id":"6564","city_name":"Cheltenham","state_id":"257"},
    {"city_id":"6565","city_name":"Cherrybrook","state_id":"258"},
    {"city_id":"6566","city_name":"Clayton","state_id":"259"},
    {"city_id":"6567","city_name":"Collingwood","state_id":"260"},
    {"city_id":"6568","city_name":"Hawthorn","state_id":"262"},
    {"city_id":"6569","city_name":"Jannnali","state_id":"263"},
    {"city_id":"6570","city_name":"Knoxfield","state_id":"264"},
    {"city_id":"6571","city_name":"Melbourne","state_id":"265"},
    {"city_id":"6572","city_name":"South Oakleigh","state_id":"265"},
    {"city_id":"6573","city_name":"Cottesole","state_id":"268"},
    {"city_id":"6574","city_name":"Darch","state_id":"268"},
    {"city_id":"6575","city_name":"Mullaloo","state_id":"268"},
    {"city_id":"6576","city_name":"Noranda","state_id":"268"},
    {"city_id":"6577","city_name":"Osborne Park","state_id":"268"},
    {"city_id":"6578","city_name":"Willetton","state_id":"268"},
    {"city_id":"6579","city_name":"Acacia Ridge","state_id":"269"},
    {"city_id":"6580","city_name":"Aitkenvale","state_id":"269"},
    {"city_id":"6581","city_name":"Alderley","state_id":"269"},
    {"city_id":"6582","city_name":"Alexander Hills","state_id":"269"},
    {"city_id":"6583","city_name":"Archerfield","state_id":"269"},
    {"city_id":"6584","city_name":"Ashmore","state_id":"269"},
    {"city_id":"6585","city_name":"Atherton","state_id":"269"},
    {"city_id":"6586","city_name":"Ayr","state_id":"269"},
    {"city_id":"6587","city_name":"Beachmere","state_id":"269"},
    {"city_id":"6588","city_name":"Beenleigh","state_id":"269"},
    {"city_id":"6589","city_name":"Beerwah","state_id":"269"},
    {"city_id":"6590","city_name":"Bell","state_id":"269"},
    {"city_id":"6591","city_name":"Belmont","state_id":"269"},
    {"city_id":"6592","city_name":"Biloela","state_id":"269"},
    {"city_id":"6593","city_name":"Birkdale","state_id":"269"},
    {"city_id":"6594","city_name":"Blackwater","state_id":"269"},
    {"city_id":"6595","city_name":"Bongaree","state_id":"269"},
    {"city_id":"6596","city_name":"Bonogin","state_id":"269"},
    {"city_id":"6597","city_name":"Boonah","state_id":"269"},
    {"city_id":"6598","city_name":"Bowen","state_id":"269"},
    {"city_id":"6599","city_name":"Brendale","state_id":"269"},
    {"city_id":"6600","city_name":"Brisbane","state_id":"269"},
    {"city_id":"6601","city_name":"Buddina","state_id":"269"},
    {"city_id":"6602","city_name":"Buderim","state_id":"269"},
    {"city_id":"6603","city_name":"Bundaberg","state_id":"269"},
    {"city_id":"6604","city_name":"Bundall","state_id":"269"},
    {"city_id":"6605","city_name":"Burleigh Heads","state_id":"269"},
    {"city_id":"6606","city_name":"Burpengary","state_id":"269"},
    {"city_id":"6607","city_name":"Cabaland","state_id":"269"},
    {"city_id":"6608","city_name":"Caboolture","state_id":"269"},
    {"city_id":"6609","city_name":"Cairns","state_id":"269"},
    {"city_id":"6610","city_name":"Cairns Northern Beaches","state_id":"269"},
    {"city_id":"6611","city_name":"Caloundra","state_id":"269"},
    {"city_id":"6612","city_name":"Capalaba","state_id":"269"},
    {"city_id":"6613","city_name":"Charters Towers","state_id":"269"},
    {"city_id":"6614","city_name":"Childers","state_id":"269"},
    {"city_id":"6615","city_name":"Cleveland","state_id":"269"},
    {"city_id":"6616","city_name":"Coolum Beach","state_id":"269"},
    {"city_id":"6617","city_name":"Coopers Plain","state_id":"269"},
    {"city_id":"6618","city_name":"Dalby","state_id":"269"},
    {"city_id":"6619","city_name":"Deception Bay","state_id":"269"},
    {"city_id":"6620","city_name":"Eagle Farm","state_id":"269"},
    {"city_id":"6621","city_name":"Eagleby","state_id":"269"},
    {"city_id":"6622","city_name":"Emerald","state_id":"269"},
    {"city_id":"6623","city_name":"Emundi","state_id":"269"},
    {"city_id":"6624","city_name":"Forest Lake","state_id":"269"},
    {"city_id":"6625","city_name":"Gatton","state_id":"269"},
    {"city_id":"6626","city_name":"Gladstone","state_id":"269"},
    {"city_id":"6627","city_name":"Gold Coast","state_id":"269"},
    {"city_id":"6628","city_name":"Gowrie Junction","state_id":"269"},
    {"city_id":"6629","city_name":"Gympie","state_id":"269"},
    {"city_id":"6630","city_name":"Helensvale","state_id":"269"},
    {"city_id":"6631","city_name":"Hervey Bay","state_id":"269"},
    {"city_id":"6632","city_name":"Ingham","state_id":"269"},
    {"city_id":"6633","city_name":"Innisfail","state_id":"269"},
    {"city_id":"6634","city_name":"Ipswich","state_id":"269"},
    {"city_id":"6635","city_name":"Jindalee","state_id":"269"},
    {"city_id":"6636","city_name":"Julatten","state_id":"269"},
    {"city_id":"6637","city_name":"Kawana Waters","state_id":"269"},
    {"city_id":"6638","city_name":"Kingaroy","state_id":"269"},
    {"city_id":"6639","city_name":"Lawnton","state_id":"269"},
    {"city_id":"6640","city_name":"Loganholme","state_id":"269"},
    {"city_id":"6641","city_name":"Mackay","state_id":"269"},
    {"city_id":"6642","city_name":"Mareeba","state_id":"269"},
    {"city_id":"6643","city_name":"Maroochydore","state_id":"269"},
    {"city_id":"6644","city_name":"Maroochydore-Mooloolaba","state_id":"269"},
    {"city_id":"6645","city_name":"Maryborough","state_id":"269"},
    {"city_id":"6646","city_name":"Miami","state_id":"269"},
    {"city_id":"6647","city_name":"Milton","state_id":"269"},
    {"city_id":"6648","city_name":"Molendinar","state_id":"269"},
    {"city_id":"6649","city_name":"Moranbah","state_id":"269"},
    {"city_id":"6650","city_name":"Morayfield","state_id":"269"},
    {"city_id":"6651","city_name":"Mossman","state_id":"269"},
    {"city_id":"6652","city_name":"Mount Glorious","state_id":"269"},
    {"city_id":"6653","city_name":"Mount Isa","state_id":"269"},
    {"city_id":"6654","city_name":"Murarrie","state_id":"269"},
    {"city_id":"6655","city_name":"Nairand Kay","state_id":"269"},
    {"city_id":"6656","city_name":"Nambour","state_id":"269"},
    {"city_id":"6657","city_name":"Nerang","state_id":"269"},
    {"city_id":"6658","city_name":"Nobby Beach","state_id":"269"},
    {"city_id":"6659","city_name":"Noosa Heads","state_id":"269"},
    {"city_id":"6660","city_name":"Noosaville","state_id":"269"},
    {"city_id":"6661","city_name":"Paddington","state_id":"269"},
    {"city_id":"6662","city_name":"Port Douglas","state_id":"269"},
    {"city_id":"6663","city_name":"Robina","state_id":"269"},
    {"city_id":"6664","city_name":"Rockhampton","state_id":"269"},
    {"city_id":"6665","city_name":"Rocklea","state_id":"269"},
    {"city_id":"6666","city_name":"Roma","state_id":"269"},
    {"city_id":"6667","city_name":"Rubyvale","state_id":"269"},
    {"city_id":"6668","city_name":"Sanctuary Cove","state_id":"269"},
    {"city_id":"6669","city_name":"Slade Point","state_id":"269"},
    {"city_id":"6670","city_name":"Southport","state_id":"269"},
    {"city_id":"6671","city_name":"Suburb","state_id":"269"},
    {"city_id":"6672","city_name":"Sunshine Coast","state_id":"269"},
    {"city_id":"6673","city_name":"Tewantin-Noosa","state_id":"269"},
    {"city_id":"6674","city_name":"The Summit","state_id":"269"},
    {"city_id":"6675","city_name":"Toowong","state_id":"269"},
    {"city_id":"6676","city_name":"Toowoomba","state_id":"269"},
    {"city_id":"6677","city_name":"Townsville","state_id":"269"},
    {"city_id":"6678","city_name":"Victoria Point","state_id":"269"},
    {"city_id":"6679","city_name":"Warwick","state_id":"269"},
    {"city_id":"6680","city_name":"Willawong","state_id":"269"},
    {"city_id":"6681","city_name":"Yatala","state_id":"269"},
    {"city_id":"6682","city_name":"Yeppoon","state_id":"269"},
    {"city_id":"6683","city_name":"Zillmere","state_id":"269"},
    {"city_id":"6684","city_name":"Bridgewater-Gagebrook","state_id":"271"},
    {"city_id":"6685","city_name":"Burnie","state_id":"271"},
    {"city_id":"6686","city_name":"Burnie-Devonport","state_id":"271"},
    {"city_id":"6687","city_name":"Glenorchy","state_id":"271"},
    {"city_id":"6688","city_name":"Hobart","state_id":"271"},
    {"city_id":"6689","city_name":"Kingston-Blackmans Bay","state_id":"271"},
    {"city_id":"6690","city_name":"Launceston","state_id":"271"},
    {"city_id":"6691","city_name":"New Norfolk","state_id":"271"},
    {"city_id":"6692","city_name":"Rowella","state_id":"271"},
    {"city_id":"6693","city_name":"Sheffield","state_id":"271"},
    {"city_id":"6694","city_name":"Ulverstone","state_id":"271"},
    {"city_id":"6695","city_name":"Templestowe","state_id":"272"},
    {"city_id":"6696","city_name":"Albion","state_id":"273"},
    {"city_id":"6697","city_name":"Ararat","state_id":"273"},
    {"city_id":"6698","city_name":"Avenel","state_id":"273"},
    {"city_id":"6699","city_name":"Avondale Heights","state_id":"273"},
    {"city_id":"6700","city_name":"Baccus Marsh","state_id":"273"},
    {"city_id":"6701","city_name":"Bairnsdale","state_id":"273"},
    {"city_id":"6702","city_name":"Ballarat","state_id":"273"},
    {"city_id":"6703","city_name":"Balwyn","state_id":"273"},
    {"city_id":"6704","city_name":"Batman","state_id":"273"},
    {"city_id":"6705","city_name":"Bayswater","state_id":"273"},
    {"city_id":"6706","city_name":"Belmont","state_id":"273"},
    {"city_id":"6707","city_name":"Benalla","state_id":"273"},
    {"city_id":"6708","city_name":"Bendigo","state_id":"273"},
    {"city_id":"6709","city_name":"Bentleigh East","state_id":"273"},
    {"city_id":"6710","city_name":"Berwick","state_id":"273"},
    {"city_id":"6711","city_name":"Blackburn","state_id":"273"},
    {"city_id":"6712","city_name":"Blue Mountains","state_id":"273"},
    {"city_id":"6713","city_name":"Boonah","state_id":"273"},
    {"city_id":"6714","city_name":"Boronia","state_id":"273"},
    {"city_id":"6715","city_name":"Box Hill","state_id":"273"},
    {"city_id":"6716","city_name":"Brighton East","state_id":"273"},
    {"city_id":"6717","city_name":"Bundoora","state_id":"273"},
    {"city_id":"6718","city_name":"Campbellfield","state_id":"273"},
    {"city_id":"6719","city_name":"Carlton South","state_id":"273"},
    {"city_id":"6720","city_name":"Caroline Springs","state_id":"273"},
    {"city_id":"6721","city_name":"Castle Hill","state_id":"273"},
    {"city_id":"6722","city_name":"Castlemaine","state_id":"273"},
    {"city_id":"6723","city_name":"Caulfield","state_id":"273"},
    {"city_id":"6724","city_name":"Cheltenham","state_id":"273"},
    {"city_id":"6725","city_name":"Chester","state_id":"273"},
    {"city_id":"6726","city_name":"Clayton South","state_id":"273"},
    {"city_id":"6727","city_name":"Clifton Springs","state_id":"273"},
    {"city_id":"6728","city_name":"Coburg","state_id":"273"},
    {"city_id":"6729","city_name":"Colac","state_id":"273"},
    {"city_id":"6730","city_name":"Collingwood","state_id":"273"},
    {"city_id":"6731","city_name":"Craigieburn","state_id":"273"},
    {"city_id":"6732","city_name":"Cranbourne","state_id":"273"},
    {"city_id":"6733","city_name":"Crib Point","state_id":"273"},
    {"city_id":"6734","city_name":"Dandenong","state_id":"273"},
    {"city_id":"6735","city_name":"Devon Meadows","state_id":"273"},
    {"city_id":"6736","city_name":"Diamond Creek","state_id":"273"},
    {"city_id":"6737","city_name":"Doncaster East","state_id":"273"},
    {"city_id":"6738","city_name":"Doncester","state_id":"273"},
    {"city_id":"6739","city_name":"Donvale","state_id":"273"},
    {"city_id":"6740","city_name":"Echuca-Moama","state_id":"273"},
    {"city_id":"6741","city_name":"Elsternwick","state_id":"273"},
    {"city_id":"6742","city_name":"Eltham","state_id":"273"},
    {"city_id":"6743","city_name":"Fairfield","state_id":"273"},
    {"city_id":"6744","city_name":"Fitzroy","state_id":"273"},
    {"city_id":"6745","city_name":"Frnakston","state_id":"273"},
    {"city_id":"6746","city_name":"Geelong","state_id":"273"},
    {"city_id":"6747","city_name":"Glen Waverley","state_id":"273"},
    {"city_id":"6748","city_name":"Glenorchy","state_id":"273"},
    {"city_id":"6749","city_name":"Hallam","state_id":"273"},
    {"city_id":"6750","city_name":"Hamilton","state_id":"273"},
    {"city_id":"6751","city_name":"Hastings","state_id":"273"},
    {"city_id":"6752","city_name":"Healesville","state_id":"273"},
    {"city_id":"6753","city_name":"Horsham","state_id":"273"},
    {"city_id":"6754","city_name":"Hughesdale","state_id":"273"},
    {"city_id":"6755","city_name":"Ivanhoe","state_id":"273"},
    {"city_id":"6756","city_name":"Keilor","state_id":"273"},
    {"city_id":"6757","city_name":"Kensington","state_id":"273"},
    {"city_id":"6758","city_name":"Kerang","state_id":"273"},
    {"city_id":"6759","city_name":"Kilmore","state_id":"273"},
    {"city_id":"6760","city_name":"Kilsyth","state_id":"273"},
    {"city_id":"6761","city_name":"Kyabram","state_id":"273"},
    {"city_id":"6762","city_name":"Lakes Entrance","state_id":"273"},
    {"city_id":"6763","city_name":"Lalor","state_id":"273"},
    {"city_id":"6764","city_name":"Lara","state_id":"273"},
    {"city_id":"6765","city_name":"Laverton North","state_id":"273"},
    {"city_id":"6766","city_name":"Leopold","state_id":"273"},
    {"city_id":"6767","city_name":"Lower Templestowe","state_id":"273"},
    {"city_id":"6768","city_name":"Maidstone","state_id":"273"},
    {"city_id":"6769","city_name":"Malvern","state_id":"273"},
    {"city_id":"6770","city_name":"Maryborough","state_id":"273"},
    {"city_id":"6771","city_name":"Melbourne","state_id":"273"},
    {"city_id":"6772","city_name":"Melton","state_id":"273"},
    {"city_id":"6773","city_name":"Middle Park","state_id":"273"},
    {"city_id":"6774","city_name":"Mildura","state_id":"273"},
    {"city_id":"6775","city_name":"Mitcham","state_id":"273"},
    {"city_id":"6776","city_name":"Moe-Yallourn","state_id":"273"},
    {"city_id":"6777","city_name":"Moorabbin","state_id":"273"},
    {"city_id":"6778","city_name":"Mordialloc","state_id":"273"},
    {"city_id":"6779","city_name":"Morwell","state_id":"273"},
    {"city_id":"6780","city_name":"Mulgrave","state_id":"273"},
    {"city_id":"6781","city_name":"Murrumbeena","state_id":"273"},
    {"city_id":"6782","city_name":"Neerim South","state_id":"273"},
    {"city_id":"6783","city_name":"Noble Park North","state_id":"273"},
    {"city_id":"6784","city_name":"Notting Hill","state_id":"273"},
    {"city_id":"6785","city_name":"Oak Park","state_id":"273"},
    {"city_id":"6786","city_name":"Ocean Grove-Barwon Heads","state_id":"273"},
    {"city_id":"6787","city_name":"Olinda","state_id":"273"},
    {"city_id":"6788","city_name":"Pakenham","state_id":"273"},
    {"city_id":"6789","city_name":"Park Orchards","state_id":"273"},
    {"city_id":"6790","city_name":"Port Melbourne","state_id":"273"},
    {"city_id":"6791","city_name":"Portland","state_id":"273"},
    {"city_id":"6792","city_name":"Prahran","state_id":"273"},
    {"city_id":"6793","city_name":"Preston","state_id":"273"},
    {"city_id":"6794","city_name":"Pritbourne","state_id":"273"},
    {"city_id":"6795","city_name":"Queenscliff","state_id":"273"},
    {"city_id":"6796","city_name":"Reservoir","state_id":"273"},
    {"city_id":"6797","city_name":"Ringwood","state_id":"273"},
    {"city_id":"6798","city_name":"Rowville","state_id":"273"},
    {"city_id":"6799","city_name":"Sale","state_id":"273"},
    {"city_id":"6800","city_name":"Sandringham","state_id":"273"},
    {"city_id":"6801","city_name":"Scoresby","state_id":"273"},
    {"city_id":"6802","city_name":"Sebastopol","state_id":"273"},
    {"city_id":"6803","city_name":"Seymour","state_id":"273"},
    {"city_id":"6804","city_name":"Shepparton-Mooroopna","state_id":"273"},
    {"city_id":"6805","city_name":"Somerville","state_id":"273"},
    {"city_id":"6806","city_name":"South Yarra","state_id":"273"},
    {"city_id":"6807","city_name":"Southbank","state_id":"273"},
    {"city_id":"6808","city_name":"St. Albans","state_id":"273"},
    {"city_id":"6809","city_name":"St. Kilda East","state_id":"273"},
    {"city_id":"6810","city_name":"Stawell","state_id":"273"},
    {"city_id":"6811","city_name":"Sunbury","state_id":"273"},
    {"city_id":"6812","city_name":"Sunshine West","state_id":"273"},
    {"city_id":"6813","city_name":"Swan Hill","state_id":"273"},
    {"city_id":"6814","city_name":"Tatura","state_id":"273"},
    {"city_id":"6815","city_name":"Thomastown","state_id":"273"},
    {"city_id":"6816","city_name":"Thornbury","state_id":"273"},
    {"city_id":"6817","city_name":"Tongala","state_id":"273"},
    {"city_id":"6818","city_name":"Torquay","state_id":"273"},
    {"city_id":"6819","city_name":"Traralgon","state_id":"273"},
    {"city_id":"6820","city_name":"Tullamarine","state_id":"273"},
    {"city_id":"6821","city_name":"Vermont","state_id":"273"},
    {"city_id":"6822","city_name":"Wangaratta","state_id":"273"},
    {"city_id":"6823","city_name":"Wantirna","state_id":"273"},
    {"city_id":"6824","city_name":"Warragul","state_id":"273"},
    {"city_id":"6825","city_name":"Warrnambool","state_id":"273"},
    {"city_id":"6826","city_name":"Welshpool","state_id":"273"},
    {"city_id":"6827","city_name":"Wendouree","state_id":"273"},
    {"city_id":"6828","city_name":"Wonthaggi","state_id":"273"},
    {"city_id":"6829","city_name":"Woodend","state_id":"273"},
    {"city_id":"6830","city_name":"Yarrawonga-Mulwala","state_id":"273"},
    {"city_id":"6831","city_name":"Yea","state_id":"273"},
    {"city_id":"6832","city_name":"Wheeler","state_id":"276"},
    {"city_id":"6833","city_name":"Eisenstadt","state_id":"280"},
    {"city_id":"6834","city_name":"GroBpetersdorf","state_id":"280"},
    {"city_id":"6835","city_name":"Jennersdorf","state_id":"280"},
    {"city_id":"6836","city_name":"Kemeten","state_id":"280"},
    {"city_id":"6837","city_name":"Mattersburg","state_id":"280"},
    {"city_id":"6838","city_name":"Neudorfl","state_id":"280"},
    {"city_id":"6839","city_name":"Neusiedl am See","state_id":"280"},
    {"city_id":"6840","city_name":"Oberwart","state_id":"280"},
    {"city_id":"6841","city_name":"Pinkafeld","state_id":"280"},
    {"city_id":"6842","city_name":"Rust","state_id":"280"},
    {"city_id":"6843","city_name":"Maria Rain","state_id":"281"},
    {"city_id":"6844","city_name":"Poggersdorf","state_id":"281"},
    {"city_id":"6845","city_name":"Althofen","state_id":"282"},
    {"city_id":"6846","city_name":"Arnoldstein","state_id":"282"},
    {"city_id":"6847","city_name":"Bad Sankt Leonhard","state_id":"282"},
    {"city_id":"6848","city_name":"Bleiburg","state_id":"282"},
    {"city_id":"6849","city_name":"Ebenthal","state_id":"282"},
    {"city_id":"6850","city_name":"Eberndorf","state_id":"282"},
    {"city_id":"6851","city_name":"Feldkirchen","state_id":"282"},
    {"city_id":"6852","city_name":"Ferlach","state_id":"282"},
    {"city_id":"6853","city_name":"Finkenstein","state_id":"282"},
    {"city_id":"6854","city_name":"Friesach","state_id":"282"},
    {"city_id":"6855","city_name":"Hermagor","state_id":"282"},
    {"city_id":"6856","city_name":"Klagenfurt","state_id":"282"},
    {"city_id":"6857","city_name":"Klagenfurt ","state_id":"282"},
    {"city_id":"6858","city_name":"Lohnsburg","state_id":"282"},
    {"city_id":"6859","city_name":"Moosburg","state_id":"282"},
    {"city_id":"6860","city_name":"Paternion","state_id":"282"},
    {"city_id":"6861","city_name":"Radentheim","state_id":"282"},
    {"city_id":"6862","city_name":"Sankt Andra","state_id":"282"},
    {"city_id":"6863","city_name":"Sankt Jakob","state_id":"282"},
    {"city_id":"6864","city_name":"Sankt Veit","state_id":"282"},
    {"city_id":"6865","city_name":"Seeboden","state_id":"282"},
    {"city_id":"6866","city_name":"Spittal","state_id":"282"},
    {"city_id":"6867","city_name":"Velden am Worthersee","state_id":"282"},
    {"city_id":"6868","city_name":"Villach","state_id":"282"},
    {"city_id":"6869","city_name":"Volkermarkt","state_id":"282"},
    {"city_id":"6870","city_name":"Wernberg","state_id":"282"},
    {"city_id":"6871","city_name":"Wolfsberg","state_id":"282"},
    {"city_id":"6872","city_name":"Amstetten","state_id":"285"},
    {"city_id":"6873","city_name":"Bad Voslau","state_id":"285"},
    {"city_id":"6874","city_name":"Baden","state_id":"285"},
    {"city_id":"6875","city_name":"Berndorf","state_id":"285"},
    {"city_id":"6876","city_name":"Boheimkirchen","state_id":"285"},
    {"city_id":"6877","city_name":"Bruck an der Leitha","state_id":"285"},
    {"city_id":"6878","city_name":"Brunn","state_id":"285"},
    {"city_id":"6879","city_name":"Deutsch-Wagram","state_id":"285"},
    {"city_id":"6880","city_name":"Ebreichsdorf","state_id":"285"},
    {"city_id":"6881","city_name":"Eggendorf","state_id":"285"},
    {"city_id":"6882","city_name":"Fischamend","state_id":"285"},
    {"city_id":"6883","city_name":"Gablitz","state_id":"285"},
    {"city_id":"6884","city_name":"Ganserndorf","state_id":"285"},
    {"city_id":"6885","city_name":"Gerasdorf","state_id":"285"},
    {"city_id":"6886","city_name":"Gloggnitz","state_id":"285"},
    {"city_id":"6887","city_name":"Gmund","state_id":"285"},
    {"city_id":"6888","city_name":"Greifenstein","state_id":"285"},
    {"city_id":"6889","city_name":"GroB-Enzersdorf","state_id":"285"},
    {"city_id":"6890","city_name":"GroB-Gerungs","state_id":"285"},
    {"city_id":"6891","city_name":"Guntramsdorf","state_id":"285"},
    {"city_id":"6892","city_name":"Haag","state_id":"285"},
    {"city_id":"6893","city_name":"Hainburg","state_id":"285"},
    {"city_id":"6894","city_name":"Heidenreichstein","state_id":"285"},
    {"city_id":"6895","city_name":"Herzogenburg","state_id":"285"},
    {"city_id":"6896","city_name":"Himberg","state_id":"285"},
    {"city_id":"6897","city_name":"Hollabrunn","state_id":"285"},
    {"city_id":"6898","city_name":"Horn","state_id":"285"},
    {"city_id":"6899","city_name":"Klosterneuburg","state_id":"285"},
    {"city_id":"6900","city_name":"Korneuburg","state_id":"285"},
    {"city_id":"6901","city_name":"Kottingbrunn","state_id":"285"},
    {"city_id":"6902","city_name":"Krems","state_id":"285"},
    {"city_id":"6903","city_name":"Laa","state_id":"285"},
    {"city_id":"6904","city_name":"Langenlois","state_id":"285"},
    {"city_id":"6905","city_name":"Langenzersdorf","state_id":"285"},
    {"city_id":"6906","city_name":"Leobendorf","state_id":"285"},
    {"city_id":"6907","city_name":"Leopoldsdorf","state_id":"285"},
    {"city_id":"6908","city_name":"Lilienfeld","state_id":"285"},
    {"city_id":"6909","city_name":"Loipersdorf","state_id":"285"},
    {"city_id":"6910","city_name":"Maria Enzersdorf","state_id":"285"},
    {"city_id":"6911","city_name":"Melk","state_id":"285"},
    {"city_id":"6912","city_name":"Mistelbach","state_id":"285"},
    {"city_id":"6913","city_name":"Modling","state_id":"285"},
    {"city_id":"6914","city_name":"Neulengbach","state_id":"285"},
    {"city_id":"6915","city_name":"Neunkirchen","state_id":"285"},
    {"city_id":"6916","city_name":"Niederleis","state_id":"285"},
    {"city_id":"6917","city_name":"Ober-Grabendorf","state_id":"285"},
    {"city_id":"6918","city_name":"Perchtoldsdorf","state_id":"285"},
    {"city_id":"6919","city_name":"Pernitz","state_id":"285"},
    {"city_id":"6920","city_name":"Pottendorf","state_id":"285"},
    {"city_id":"6921","city_name":"Poysdorf","state_id":"285"},
    {"city_id":"6922","city_name":"Pressbaum","state_id":"285"},
    {"city_id":"6923","city_name":"Purgstall","state_id":"285"},
    {"city_id":"6924","city_name":"Purkersdorf","state_id":"285"},
    {"city_id":"6925","city_name":"Reichenau","state_id":"285"},
    {"city_id":"6926","city_name":"Retz","state_id":"285"},
    {"city_id":"6927","city_name":"Sankt Andra-Wordern","state_id":"285"},
    {"city_id":"6928","city_name":"Sankt Peter in der Au","state_id":"285"},
    {"city_id":"6929","city_name":"Sankt Polten","state_id":"285"},
    {"city_id":"6930","city_name":"Sankt Valentin","state_id":"285"},
    {"city_id":"6931","city_name":"Scheibbs","state_id":"285"},
    {"city_id":"6932","city_name":"Schrems","state_id":"285"},
    {"city_id":"6933","city_name":"Schwechat","state_id":"285"},
    {"city_id":"6934","city_name":"Seitenstetten","state_id":"285"},
    {"city_id":"6935","city_name":"Sollenau","state_id":"285"},
    {"city_id":"6936","city_name":"Stockerau","state_id":"285"},
    {"city_id":"6937","city_name":"Strasshof","state_id":"285"},
    {"city_id":"6938","city_name":"Ternitz","state_id":"285"},
    {"city_id":"6939","city_name":"Traiskirchen","state_id":"285"},
    {"city_id":"6940","city_name":"Traismauer","state_id":"285"},
    {"city_id":"6941","city_name":"Tulln","state_id":"285"},
    {"city_id":"6942","city_name":"Vosendorf","state_id":"285"},
    {"city_id":"6943","city_name":"Waidhofen","state_id":"285"},
    {"city_id":"6944","city_name":"Wiener Neudorf","state_id":"285"},
    {"city_id":"6945","city_name":"Wiener Neustadt","state_id":"285"},
    {"city_id":"6946","city_name":"Wieselburg","state_id":"285"},
    {"city_id":"6947","city_name":"Wilhelmsburg","state_id":"285"},
    {"city_id":"6948","city_name":"Wolkersdorf","state_id":"285"},
    {"city_id":"6949","city_name":"Ybbs","state_id":"285"},
    {"city_id":"6950","city_name":"Ybbsitz","state_id":"285"},
    {"city_id":"6951","city_name":"Zistersdorf","state_id":"285"},
    {"city_id":"6952","city_name":"Zwettl","state_id":"285"},
    {"city_id":"6953","city_name":"Alkoven","state_id":"286"},
    {"city_id":"6954","city_name":"Altheim","state_id":"286"},
    {"city_id":"6955","city_name":"Altmunster","state_id":"286"},
    {"city_id":"6956","city_name":"Andorf","state_id":"286"},
    {"city_id":"6957","city_name":"Ansfelden","state_id":"286"},
    {"city_id":"6958","city_name":"Asten","state_id":"286"},
    {"city_id":"6959","city_name":"Attnang-Puchheim","state_id":"286"},
    {"city_id":"6960","city_name":"Aurolzmunster","state_id":"286"},
    {"city_id":"6961","city_name":"Bad Goisern","state_id":"286"},
    {"city_id":"6962","city_name":"Bad Hall","state_id":"286"},
    {"city_id":"6963","city_name":"Bad Ischl","state_id":"286"},
    {"city_id":"6964","city_name":"Braunau","state_id":"286"},
    {"city_id":"6965","city_name":"Breitenfurt","state_id":"286"},
    {"city_id":"6966","city_name":"Ebensee","state_id":"286"},
    {"city_id":"6967","city_name":"Eferding","state_id":"286"},
    {"city_id":"6968","city_name":"Engerwitzdorf","state_id":"286"},
    {"city_id":"6969","city_name":"Enns","state_id":"286"},
    {"city_id":"6970","city_name":"Feldkirchen an der Donau","state_id":"286"},
    {"city_id":"6971","city_name":"Frankenburg","state_id":"286"},
    {"city_id":"6972","city_name":"Freistadt","state_id":"286"},
    {"city_id":"6973","city_name":"Gallneukirchen","state_id":"286"},
    {"city_id":"6974","city_name":"Garsten","state_id":"286"},
    {"city_id":"6975","city_name":"Gmunden","state_id":"286"},
    {"city_id":"6976","city_name":"Gramastetten","state_id":"286"},
    {"city_id":"6977","city_name":"Grieskirchen","state_id":"286"},
    {"city_id":"6978","city_name":"Gunskirchen","state_id":"286"},
    {"city_id":"6979","city_name":"Horsching","state_id":"286"},
    {"city_id":"6980","city_name":"Kirchdorf an der Krems","state_id":"286"},
    {"city_id":"6981","city_name":"Kremsmunster","state_id":"286"},
    {"city_id":"6982","city_name":"Krenglbach","state_id":"286"},
    {"city_id":"6983","city_name":"Laakirchen","state_id":"286"},
    {"city_id":"6984","city_name":"Lenzing","state_id":"286"},
    {"city_id":"6985","city_name":"Leonding","state_id":"286"},
    {"city_id":"6986","city_name":"Linz","state_id":"286"},
    {"city_id":"6987","city_name":"Loibichl","state_id":"286"},
    {"city_id":"6988","city_name":"Marchtrenk","state_id":"286"},
    {"city_id":"6989","city_name":"Mattighofen","state_id":"286"},
    {"city_id":"6990","city_name":"Mauthausen","state_id":"286"},
    {"city_id":"6991","city_name":"Micheldorf","state_id":"286"},
    {"city_id":"6992","city_name":"Neuhofen an der Krems","state_id":"286"},
    {"city_id":"6993","city_name":"Ohlsdorf","state_id":"286"},
    {"city_id":"6994","city_name":"Ottensheim","state_id":"286"},
    {"city_id":"6995","city_name":"Pasching","state_id":"286"},
    {"city_id":"6996","city_name":"Perg","state_id":"286"},
    {"city_id":"6997","city_name":"Pettenbach","state_id":"286"},
    {"city_id":"6998","city_name":"Pram","state_id":"286"},
    {"city_id":"6999","city_name":"Pregarten","state_id":"286"},
    {"city_id":"7000","city_name":"Puchenau","state_id":"286"},
    {"city_id":"7001","city_name":"Regau","state_id":"286"},
    {"city_id":"7002","city_name":"Ried","state_id":"286"},
    {"city_id":"7003","city_name":"Rohrbach in Oberosterreich","state_id":"286"},
    {"city_id":"7004","city_name":"Rutzenmoos","state_id":"286"},
    {"city_id":"7005","city_name":"Sankt Florian","state_id":"286"},
    {"city_id":"7006","city_name":"Sankt Georgen","state_id":"286"},
    {"city_id":"7007","city_name":"Sankt Marien","state_id":"286"},
    {"city_id":"7008","city_name":"Scharding","state_id":"286"},
    {"city_id":"7009","city_name":"Scharnstein","state_id":"286"},
    {"city_id":"7010","city_name":"Schwertberg","state_id":"286"},
    {"city_id":"7011","city_name":"Seewalchen","state_id":"286"},
    {"city_id":"7012","city_name":"Sierning","state_id":"286"},
    {"city_id":"7013","city_name":"Stadl-Paura","state_id":"286"},
    {"city_id":"7014","city_name":"Steyr","state_id":"286"},
    {"city_id":"7015","city_name":"Steyregg","state_id":"286"},
    {"city_id":"7016","city_name":"Steyrermuhl","state_id":"286"},
    {"city_id":"7017","city_name":"Thalheim","state_id":"286"},
    {"city_id":"7018","city_name":"Timelkam","state_id":"286"},
    {"city_id":"7019","city_name":"Traun","state_id":"286"},
    {"city_id":"7020","city_name":"Vocklabruck","state_id":"286"},
    {"city_id":"7021","city_name":"Vocklamarkt","state_id":"286"},
    {"city_id":"7022","city_name":"Vorchdorf","state_id":"286"},
    {"city_id":"7023","city_name":"Wels","state_id":"286"},
    {"city_id":"7024","city_name":"Wilhering","state_id":"286"},
    {"city_id":"7025","city_name":"Abtenau","state_id":"287"},
    {"city_id":"7026","city_name":"Anif","state_id":"287"},
    {"city_id":"7027","city_name":"Bad Gastein","state_id":"287"},
    {"city_id":"7028","city_name":"Bad Hofgastein","state_id":"287"},
    {"city_id":"7029","city_name":"Bergheim","state_id":"287"},
    {"city_id":"7030","city_name":"Bischofshofen","state_id":"287"},
    {"city_id":"7031","city_name":"Bruck an der GroBglocknerstraB","state_id":"287"},
    {"city_id":"7032","city_name":"Burmoos","state_id":"287"},
    {"city_id":"7033","city_name":"Elsbethen","state_id":"287"},
    {"city_id":"7034","city_name":"Eugendorf","state_id":"287"},
    {"city_id":"7035","city_name":"Forstau","state_id":"287"},
    {"city_id":"7036","city_name":"Grodig","state_id":"287"},
    {"city_id":"7037","city_name":"Hallein","state_id":"287"},
    {"city_id":"7038","city_name":"Hallwang","state_id":"287"},
    {"city_id":"7039","city_name":"Henndorf","state_id":"287"},
    {"city_id":"7040","city_name":"Kuchl","state_id":"287"},
    {"city_id":"7041","city_name":"Mayrhofen","state_id":"287"},
    {"city_id":"7042","city_name":"Mittersill","state_id":"287"},
    {"city_id":"7043","city_name":"Neumarkt","state_id":"287"},
    {"city_id":"7044","city_name":"Oberndorf","state_id":"287"},
    {"city_id":"7045","city_name":"Obertrum am See","state_id":"287"},
    {"city_id":"7046","city_name":"Piesendorf","state_id":"287"},
    {"city_id":"7047","city_name":"Puch","state_id":"287"},
    {"city_id":"7048","city_name":"Radstadt","state_id":"287"},
    {"city_id":"7049","city_name":"Saalfelden","state_id":"287"},
    {"city_id":"7050","city_name":"Salzburg","state_id":"287"},
    {"city_id":"7051","city_name":"Sankt Johann im Pongau","state_id":"287"},
    {"city_id":"7052","city_name":"Seekirchen","state_id":"287"},
    {"city_id":"7053","city_name":"Sieghartskirchen","state_id":"287"},
    {"city_id":"7054","city_name":"StraBwalchen","state_id":"287"},
    {"city_id":"7055","city_name":"Strobl","state_id":"287"},
    {"city_id":"7056","city_name":"Tamsweg","state_id":"287"},
    {"city_id":"7057","city_name":"Thalgau","state_id":"287"},
    {"city_id":"7058","city_name":"Wals-Siezenheim","state_id":"287"},
    {"city_id":"7059","city_name":"Wolfgangsee","state_id":"287"},
    {"city_id":"7060","city_name":"Zell am See","state_id":"287"},
    {"city_id":"7061","city_name":"Albersdorf","state_id":"288"},
    {"city_id":"7062","city_name":"Bad Aussee","state_id":"289"},
    {"city_id":"7063","city_name":"Barnbach","state_id":"289"},
    {"city_id":"7064","city_name":"Bruck an der Mur","state_id":"289"},
    {"city_id":"7065","city_name":"Deutschlandsberg","state_id":"289"},
    {"city_id":"7066","city_name":"Eisenerz","state_id":"289"},
    {"city_id":"7067","city_name":"Feldbach","state_id":"289"},
    {"city_id":"7068","city_name":"Feldkirchen bei Graz","state_id":"289"},
    {"city_id":"7069","city_name":"Fohnsdorf","state_id":"289"},
    {"city_id":"7070","city_name":"Frohnleiten","state_id":"289"},
    {"city_id":"7071","city_name":"Furstenfeld","state_id":"289"},
    {"city_id":"7072","city_name":"Gleisdorf","state_id":"289"},
    {"city_id":"7073","city_name":"Gratkorn","state_id":"289"},
    {"city_id":"7074","city_name":"Graz","state_id":"289"},
    {"city_id":"7075","city_name":"Hartberg","state_id":"289"},
    {"city_id":"7076","city_name":"Judenburg","state_id":"289"},
    {"city_id":"7077","city_name":"Judendorf-StraBengel","state_id":"289"},
    {"city_id":"7078","city_name":"Kapfenberg","state_id":"289"},
    {"city_id":"7079","city_name":"Karlsdorf","state_id":"289"},
    {"city_id":"7080","city_name":"Kindberg","state_id":"289"},
    {"city_id":"7081","city_name":"Knittelfeld","state_id":"289"},
    {"city_id":"7082","city_name":"Koflach","state_id":"289"},
    {"city_id":"7083","city_name":"Krieglach","state_id":"289"},
    {"city_id":"7084","city_name":"Lannach","state_id":"289"},
    {"city_id":"7085","city_name":"Leibnitz","state_id":"289"},
    {"city_id":"7086","city_name":"Leoben","state_id":"289"},
    {"city_id":"7087","city_name":"Liezen","state_id":"289"},
    {"city_id":"7088","city_name":"Murzzuschlag","state_id":"289"},
    {"city_id":"7089","city_name":"Rottenmann","state_id":"289"},
    {"city_id":"7090","city_name":"Schladming","state_id":"289"},
    {"city_id":"7091","city_name":"Seiersberg","state_id":"289"},
    {"city_id":"7092","city_name":"Spielberg","state_id":"289"},
    {"city_id":"7093","city_name":"Trofaiach","state_id":"289"},
    {"city_id":"7094","city_name":"Voitsberg","state_id":"289"},
    {"city_id":"7095","city_name":"Wagna","state_id":"289"},
    {"city_id":"7096","city_name":"Weiz","state_id":"289"},
    {"city_id":"7097","city_name":"Zeltweg","state_id":"289"},
    {"city_id":"7098","city_name":"Deutschfeistritz","state_id":"290"},
    {"city_id":"7099","city_name":"Liezen","state_id":"290"},
    {"city_id":"7100","city_name":"Sankt Bartholoma","state_id":"290"},
    {"city_id":"7101","city_name":"Absam","state_id":"291"},
    {"city_id":"7102","city_name":"Axams","state_id":"291"},
    {"city_id":"7103","city_name":"Ebbs","state_id":"291"},
    {"city_id":"7104","city_name":"Fugen","state_id":"291"},
    {"city_id":"7105","city_name":"Hall","state_id":"291"},
    {"city_id":"7106","city_name":"Haselgehr","state_id":"291"},
    {"city_id":"7107","city_name":"Hopfgarten","state_id":"291"},
    {"city_id":"7108","city_name":"Imst","state_id":"291"},
    {"city_id":"7109","city_name":"Innsbruck","state_id":"291"},
    {"city_id":"7110","city_name":"Jenbach","state_id":"291"},
    {"city_id":"7111","city_name":"Kirchberg","state_id":"291"},
    {"city_id":"7112","city_name":"Kirchbichl","state_id":"291"},
    {"city_id":"7113","city_name":"Kitzbuhel","state_id":"291"},
    {"city_id":"7114","city_name":"Kramsach","state_id":"291"},
    {"city_id":"7115","city_name":"Kufstein","state_id":"291"},
    {"city_id":"7116","city_name":"Landeck","state_id":"291"},
    {"city_id":"7117","city_name":"Lienz","state_id":"291"},
    {"city_id":"7118","city_name":"Matrei","state_id":"291"},
    {"city_id":"7119","city_name":"Neustift im Stubaital","state_id":"291"},
    {"city_id":"7120","city_name":"Reutte","state_id":"291"},
    {"city_id":"7121","city_name":"Rum","state_id":"291"},
    {"city_id":"7122","city_name":"Sankt Johann in Tirol","state_id":"291"},
    {"city_id":"7123","city_name":"Scheffau","state_id":"291"},
    {"city_id":"7124","city_name":"Schwaz","state_id":"291"},
    {"city_id":"7125","city_name":"St. Johann Am Walde","state_id":"291"},
    {"city_id":"7126","city_name":"Telfs","state_id":"291"},
    {"city_id":"7127","city_name":"Vols","state_id":"291"},
    {"city_id":"7128","city_name":"Vomp","state_id":"291"},
    {"city_id":"7129","city_name":"Wattens","state_id":"291"},
    {"city_id":"7130","city_name":"Worgl","state_id":"291"},
    {"city_id":"7131","city_name":"Zirl","state_id":"291"},
    {"city_id":"7132","city_name":"Altach","state_id":"293"},
    {"city_id":"7133","city_name":"Bludenz","state_id":"293"},
    {"city_id":"7134","city_name":"Bregenz","state_id":"293"},
    {"city_id":"7135","city_name":"Chassieu","state_id":"293"},
    {"city_id":"7136","city_name":"Dietmannsried","state_id":"293"},
    {"city_id":"7137","city_name":"Dornbirn","state_id":"293"},
    {"city_id":"7138","city_name":"Feldkirch","state_id":"293"},
    {"city_id":"7139","city_name":"Frastanz","state_id":"293"},
    {"city_id":"7140","city_name":"Gotzis","state_id":"293"},
    {"city_id":"7141","city_name":"Hard","state_id":"293"},
    {"city_id":"7142","city_name":"Hochst","state_id":"293"},
    {"city_id":"7143","city_name":"Hohenems","state_id":"293"},
    {"city_id":"7144","city_name":"Horbranz","state_id":"293"},
    {"city_id":"7145","city_name":"Hufingen","state_id":"293"},
    {"city_id":"7146","city_name":"Lauterach","state_id":"293"},
    {"city_id":"7147","city_name":"Lochau","state_id":"293"},
    {"city_id":"7148","city_name":"Lustenau","state_id":"293"},
    {"city_id":"7149","city_name":"Mittelberg","state_id":"293"},
    {"city_id":"7150","city_name":"Nenzing","state_id":"293"},
    {"city_id":"7151","city_name":"Nuziders","state_id":"293"},
    {"city_id":"7152","city_name":"Rankweil","state_id":"293"},
    {"city_id":"7153","city_name":"Schruns","state_id":"293"},
    {"city_id":"7154","city_name":"Thuringen","state_id":"293"},
    {"city_id":"7155","city_name":"Wolfurt","state_id":"293"},
    {"city_id":"7156","city_name":"Vienna","state_id":"294"},
    {"city_id":"7157","city_name":"Wien","state_id":"294"},
    {"city_id":"7158","city_name":"Alat","state_id":"295"},
    {"city_id":"7159","city_name":"Artyom","state_id":"295"},
    {"city_id":"7160","city_name":"Baki","state_id":"295"},
    {"city_id":"7161","city_name":"Bakixanov","state_id":"295"},
    {"city_id":"7162","city_name":"Balaxani","state_id":"295"},
    {"city_id":"7163","city_name":"Bilacari","state_id":"295"},
    {"city_id":"7164","city_name":"Bilqax","state_id":"295"},
    {"city_id":"7165","city_name":"Bina","state_id":"295"},
    {"city_id":"7166","city_name":"Buzovna","state_id":"295"},
    {"city_id":"7167","city_name":"Haci Zeynalabdin","state_id":"295"},
    {"city_id":"7168","city_name":"Hovsan","state_id":"295"},
    {"city_id":"7169","city_name":"Lokbatan","state_id":"295"},
    {"city_id":"7170","city_name":"Mastaga","state_id":"295"},
    {"city_id":"7171","city_name":"Puta","state_id":"295"},
    {"city_id":"7172","city_name":"Qarasuxur","state_id":"295"},
    {"city_id":"7173","city_name":"Qobustan","state_id":"295"},
    {"city_id":"7174","city_name":"Rasulzada","state_id":"295"},
    {"city_id":"7175","city_name":"Sabuncu","state_id":"295"},
    {"city_id":"7176","city_name":"Sanqacal","state_id":"295"},
    {"city_id":"7177","city_name":"Sumqayit","state_id":"295"},
    {"city_id":"7178","city_name":"Suraxani","state_id":"295"},
    {"city_id":"7179","city_name":"Xirdalan","state_id":"295"},
    {"city_id":"7180","city_name":"Zirya","state_id":"295"},
    {"city_id":"7181","city_name":"Daskasan","state_id":"297"},
    {"city_id":"7182","city_name":"Ganca","state_id":"297"},
    {"city_id":"7183","city_name":"Xanlar","state_id":"297"},
    {"city_id":"7184","city_name":"Ganja","state_id":"298"},
    {"city_id":"7185","city_name":"Cabrayil","state_id":"299"},
    {"city_id":"7186","city_name":"Kalbacar","state_id":"299"},
    {"city_id":"7187","city_name":"Lacin","state_id":"299"},
    {"city_id":"7188","city_name":"Astara","state_id":"300"},
    {"city_id":"7189","city_name":"Goytapa","state_id":"300"},
    {"city_id":"7190","city_name":"Lankaran","state_id":"300"},
    {"city_id":"7191","city_name":"Masalli","state_id":"300"},
    {"city_id":"7192","city_name":"Neftcala","state_id":"300"},
    {"city_id":"7193","city_name":"Agcabadi","state_id":"301"},
    {"city_id":"7194","city_name":"Agdam","state_id":"301"},
    {"city_id":"7195","city_name":"Barda","state_id":"301"},
    {"city_id":"7196","city_name":"Mingacevir","state_id":"301"},
    {"city_id":"7197","city_name":"Tartar","state_id":"301"},
    {"city_id":"7198","city_name":"Yevlax","state_id":"301"},
    {"city_id":"7199","city_name":"Ali Bayramli","state_id":"302"},
    {"city_id":"7200","city_name":"Bilasuvar","state_id":"302"},
    {"city_id":"7201","city_name":"Calilabad","state_id":"302"},
    {"city_id":"7202","city_name":"Qarasu","state_id":"302"},
    {"city_id":"7203","city_name":"Qazimammad","state_id":"302"},
    {"city_id":"7204","city_name":"Saatli","state_id":"302"},
    {"city_id":"7205","city_name":"Sabirabad","state_id":"302"},
    {"city_id":"7206","city_name":"Salyan","state_id":"302"},
    {"city_id":"7207","city_name":"Susa","state_id":"303"},
    {"city_id":"7208","city_name":"Xankandi","state_id":"303"},
    {"city_id":"7209","city_name":"Xocavand","state_id":"303"},
    {"city_id":"7210","city_name":"Culfa","state_id":"304"},
    {"city_id":"7211","city_name":"Naxcivan","state_id":"304"},
    {"city_id":"7212","city_name":"Ordubad","state_id":"304"},
    {"city_id":"7213","city_name":"Sadarak","state_id":"304"},
    {"city_id":"7214","city_name":"Sarur","state_id":"304"},
    {"city_id":"7215","city_name":"Beylaqan","state_id":"305"},
    {"city_id":"7216","city_name":"Fuzuli","state_id":"305"},
    {"city_id":"7217","city_name":"Imisli","state_id":"305"},
    {"city_id":"7218","city_name":"Agstafa","state_id":"306"},
    {"city_id":"7219","city_name":"Gadabay","state_id":"306"},
    {"city_id":"7220","city_name":"Kovlar","state_id":"306"},
    {"city_id":"7221","city_name":"Qaracamirli","state_id":"306"},
    {"city_id":"7222","city_name":"Qazax","state_id":"306"},
    {"city_id":"7223","city_name":"Samkir","state_id":"306"},
    {"city_id":"7224","city_name":"Tovuz","state_id":"306"},
    {"city_id":"7225","city_name":"Amircan","state_id":"307"},
    {"city_id":"7226","city_name":"Balakan","state_id":"307"},
    {"city_id":"7227","city_name":"Katex","state_id":"307"},
    {"city_id":"7228","city_name":"Oguz","state_id":"307"},
    {"city_id":"7229","city_name":"Qabala","state_id":"307"},
    {"city_id":"7230","city_name":"Qax","state_id":"307"},
    {"city_id":"7231","city_name":"Saki","state_id":"307"},
    {"city_id":"7232","city_name":"Zaqatala","state_id":"307"},
    {"city_id":"7233","city_name":"Agdas","state_id":"308"},
    {"city_id":"7234","city_name":"Agsu","state_id":"308"},
    {"city_id":"7235","city_name":"Goycay","state_id":"308"},
    {"city_id":"7236","city_name":"Ismayilli","state_id":"308"},
    {"city_id":"7237","city_name":"Kurdamir","state_id":"308"},
    {"city_id":"7238","city_name":"Samaxi","state_id":"308"},
    {"city_id":"7239","city_name":"Ucar","state_id":"308"},
    {"city_id":"7240","city_name":"Zardab","state_id":"308"},
    {"city_id":"7241","city_name":"Davaci","state_id":"309"},
    {"city_id":"7242","city_name":"Quba","state_id":"309"},
    {"city_id":"7243","city_name":"Qusar","state_id":"309"},
    {"city_id":"7244","city_name":"Siyazan","state_id":"309"},
    {"city_id":"7245","city_name":"Xacmaz","state_id":"309"},
    {"city_id":"7246","city_name":"Xudat","state_id":"309"},
    {"city_id":"7247","city_name":"Coopers Town","state_id":"310"},
    {"city_id":"7248","city_name":"Marsh Harbour","state_id":"310"},
    {"city_id":"7249","city_name":"Andros Town","state_id":"312"},
    {"city_id":"7250","city_name":"Nicholls Town","state_id":"312"},
    {"city_id":"7251","city_name":"Alice Town","state_id":"314"},
    {"city_id":"7252","city_name":"Freetown","state_id":"317"},
    {"city_id":"7253","city_name":"Rock Sound","state_id":"317"},
    {"city_id":"7254","city_name":"Pirates Well","state_id":"322"},
    {"city_id":"7255","city_name":"'Isa","state_id":"327"},
    {"city_id":"7256","city_name":"Badiyah","state_id":"328"},
    {"city_id":"7257","city_name":"Hidd","state_id":"329"},
    {"city_id":"7258","city_name":"Mahama","state_id":"331"},
    {"city_id":"7259","city_name":"Manama","state_id":"332"},
    {"city_id":"7260","city_name":"Sitrah","state_id":"333"},
    {"city_id":"7261","city_name":"al-Manamah","state_id":"334"},
    {"city_id":"7262","city_name":"al-Muharraq","state_id":"335"},
    {"city_id":"7263","city_name":"ar-Rifa'a","state_id":"336"},
    {"city_id":"7264","city_name":"Bandarban","state_id":"338"},
    {"city_id":"7265","city_name":"Barguna","state_id":"339"},
    {"city_id":"7266","city_name":"Barisal","state_id":"340"},
    {"city_id":"7267","city_name":"Gaurnadi","state_id":"340"},
    {"city_id":"7268","city_name":"Mehendiganj","state_id":"340"},
    {"city_id":"7269","city_name":"Nalchiti","state_id":"340"},
    {"city_id":"7270","city_name":"Bhola","state_id":"341"},
    {"city_id":"7271","city_name":"Burhanuddin","state_id":"341"},
    {"city_id":"7272","city_name":"Char Fasson","state_id":"341"},
    {"city_id":"7273","city_name":"Lalmohan","state_id":"341"},
    {"city_id":"7274","city_name":"Adamighi","state_id":"342"},
    {"city_id":"7275","city_name":"Bogora","state_id":"342"},
    {"city_id":"7276","city_name":"Sherpur","state_id":"342"},
    {"city_id":"7277","city_name":"Chandpur","state_id":"344"},
    {"city_id":"7278","city_name":"Hajiganj","state_id":"344"},
    {"city_id":"7279","city_name":"Boalkhali","state_id":"345"},
    {"city_id":"7280","city_name":"Chattagam","state_id":"345"},
    {"city_id":"7281","city_name":"Fatikchhari","state_id":"345"},
    {"city_id":"7282","city_name":"Lohagara","state_id":"345"},
    {"city_id":"7283","city_name":"Patiya","state_id":"345"},
    {"city_id":"7284","city_name":"Rangunia","state_id":"345"},
    {"city_id":"7285","city_name":"Raozan","state_id":"345"},
    {"city_id":"7286","city_name":"Sandip","state_id":"345"},
    {"city_id":"7287","city_name":"Satkaniya","state_id":"345"},
    {"city_id":"7288","city_name":"Alamdanga","state_id":"347"},
    {"city_id":"7289","city_name":"Chuadanga","state_id":"347"},
    {"city_id":"7290","city_name":"Damurhuda","state_id":"347"},
    {"city_id":"7291","city_name":"Dhaka","state_id":"348"},
    {"city_id":"7292","city_name":"Dhamrai","state_id":"348"},
    {"city_id":"7293","city_name":"Dohar","state_id":"348"},
    {"city_id":"7294","city_name":"Bochanganj","state_id":"349"},
    {"city_id":"7295","city_name":"Dinajpur","state_id":"349"},
    {"city_id":"7296","city_name":"Fulbari","state_id":"349"},
    {"city_id":"7297","city_name":"Parbatipur","state_id":"349"},
    {"city_id":"7298","city_name":"Bhanga","state_id":"350"},
    {"city_id":"7299","city_name":"Char Bhadrasan","state_id":"350"},
    {"city_id":"7300","city_name":"Faridpur","state_id":"350"},
    {"city_id":"7301","city_name":"Chhagalnaiya","state_id":"351"},
    {"city_id":"7302","city_name":"Feni","state_id":"351"},
    {"city_id":"7303","city_name":"Gaybanda","state_id":"352"},
    {"city_id":"7304","city_name":"Gazipur","state_id":"353"},
    {"city_id":"7305","city_name":"Tungi","state_id":"353"},
    {"city_id":"7306","city_name":"Gopalganj","state_id":"354"},
    {"city_id":"7307","city_name":"Tungi Para","state_id":"354"},
    {"city_id":"7308","city_name":"Baniachang","state_id":"355"},
    {"city_id":"7309","city_name":"Habiganj","state_id":"355"},
    {"city_id":"7310","city_name":"Jamalpur","state_id":"357"},
    {"city_id":"7311","city_name":"Sarishabari","state_id":"357"},
    {"city_id":"7312","city_name":"Abhaynagar","state_id":"358"},
    {"city_id":"7313","city_name":"Jessor","state_id":"358"},
    {"city_id":"7314","city_name":"Jhikargachha","state_id":"358"},
    {"city_id":"7315","city_name":"Keshabpur","state_id":"358"},
    {"city_id":"7316","city_name":"Jhalakati","state_id":"359"},
    {"city_id":"7317","city_name":"Jhanaydah","state_id":"360"},
    {"city_id":"7318","city_name":"Kaliganj","state_id":"360"},
    {"city_id":"7319","city_name":"Kotchandpur","state_id":"360"},
    {"city_id":"7320","city_name":"Shailkupa","state_id":"360"},
    {"city_id":"7321","city_name":"Khagrachari","state_id":"361"},
    {"city_id":"7322","city_name":"Manikchhari","state_id":"361"},
    {"city_id":"7323","city_name":"Ramgarh","state_id":"361"},
    {"city_id":"7324","city_name":"Khulna","state_id":"362"},
    {"city_id":"7325","city_name":"Phultala","state_id":"362"},
    {"city_id":"7326","city_name":"Bajitpur","state_id":"363"},
    {"city_id":"7327","city_name":"Bhairab Bazar","state_id":"363"},
    {"city_id":"7328","city_name":"Itna","state_id":"363"},
    {"city_id":"7329","city_name":"Kishorganj","state_id":"363"},
    {"city_id":"7330","city_name":"Komilla","state_id":"365"},
    {"city_id":"7331","city_name":"Laksham","state_id":"365"},
    {"city_id":"7332","city_name":"Chilmari","state_id":"366"},
    {"city_id":"7333","city_name":"Kurigram","state_id":"366"},
    {"city_id":"7334","city_name":"Nageshwari","state_id":"366"},
    {"city_id":"7335","city_name":"Ulipur","state_id":"366"},
    {"city_id":"7336","city_name":"Bheramara","state_id":"367"},
    {"city_id":"7337","city_name":"Kushtiya","state_id":"367"},
    {"city_id":"7338","city_name":"Lakshmipur","state_id":"368"},
    {"city_id":"7339","city_name":"Ramganj","state_id":"368"},
    {"city_id":"7340","city_name":"Ramgati","state_id":"368"},
    {"city_id":"7341","city_name":"Raypur","state_id":"368"},
    {"city_id":"7342","city_name":"Madaripur","state_id":"370"},
    {"city_id":"7343","city_name":"Magura","state_id":"371"},
    {"city_id":"7344","city_name":"Bhaluka","state_id":"372"},
    {"city_id":"7345","city_name":"Fulbaria","state_id":"372"},
    {"city_id":"7346","city_name":"Gafargaon","state_id":"372"},
    {"city_id":"7347","city_name":"Ishwarganj","state_id":"372"},
    {"city_id":"7348","city_name":"Maimansingh","state_id":"372"},
    {"city_id":"7349","city_name":"Muktagachha","state_id":"372"},
    {"city_id":"7350","city_name":"Trishal","state_id":"372"},
    {"city_id":"7351","city_name":"Manikganj","state_id":"373"},
    {"city_id":"7352","city_name":"Meherpur","state_id":"375"},
    {"city_id":"7353","city_name":"Munshiganj","state_id":"376"},
    {"city_id":"7354","city_name":"Kalia","state_id":"377"},
    {"city_id":"7355","city_name":"Naral","state_id":"377"},
    {"city_id":"7356","city_name":"Narayanganj","state_id":"378"},
    {"city_id":"7357","city_name":"Rupganj","state_id":"378"},
    {"city_id":"7358","city_name":"Narsingdi","state_id":"379"},
    {"city_id":"7359","city_name":"Roypura","state_id":"379"},
    {"city_id":"7360","city_name":"Gurudaspur","state_id":"380"},
    {"city_id":"7361","city_name":"Nator","state_id":"380"},
    {"city_id":"7362","city_name":"Naugaon","state_id":"381"},
    {"city_id":"7363","city_name":"Gomastapur","state_id":"382"},
    {"city_id":"7364","city_name":"Nawabganj","state_id":"382"},
    {"city_id":"7365","city_name":"Shibganj","state_id":"382"},
    {"city_id":"7366","city_name":"Netrakona","state_id":"383"},
    {"city_id":"7367","city_name":"Domar","state_id":"384"},
    {"city_id":"7368","city_name":"Nilphamari","state_id":"384"},
    {"city_id":"7369","city_name":"Sa'idpur","state_id":"384"},
    {"city_id":"7370","city_name":"Begamganj","state_id":"385"},
    {"city_id":"7371","city_name":"Noakhali","state_id":"385"},
    {"city_id":"7372","city_name":"Senbagh","state_id":"385"},
    {"city_id":"7373","city_name":"Bera","state_id":"386"},
    {"city_id":"7374","city_name":"Bhangura","state_id":"386"},
    {"city_id":"7375","city_name":"Ishurdi","state_id":"386"},
    {"city_id":"7376","city_name":"Pabna","state_id":"386"},
    {"city_id":"7377","city_name":"Panchagarh","state_id":"387"},
    {"city_id":"7378","city_name":"Patuakhali","state_id":"388"},
    {"city_id":"7379","city_name":"Bhandaria","state_id":"389"},
    {"city_id":"7380","city_name":"Mathbaria","state_id":"389"},
    {"city_id":"7381","city_name":"Nesarabad","state_id":"389"},
    {"city_id":"7382","city_name":"Pirojpur","state_id":"389"},
    {"city_id":"7383","city_name":"Pangsha","state_id":"390"},
    {"city_id":"7384","city_name":"Rajbari","state_id":"390"},
    {"city_id":"7385","city_name":"Rajshahi","state_id":"391"},
    {"city_id":"7386","city_name":"Kaptai","state_id":"392"},
    {"city_id":"7387","city_name":"Rangamati","state_id":"392"},
    {"city_id":"7388","city_name":"Badarganj","state_id":"393"},
    {"city_id":"7389","city_name":"Kaunia","state_id":"393"},
    {"city_id":"7390","city_name":"Rangpur","state_id":"393"},
    {"city_id":"7391","city_name":"Satkhira","state_id":"394"},
    {"city_id":"7392","city_name":"Palang","state_id":"395"},
    {"city_id":"7393","city_name":"Nalitabari","state_id":"396"},
    {"city_id":"7394","city_name":"Sherpur","state_id":"396"},
    {"city_id":"7395","city_name":"Silhat","state_id":"397"},
    {"city_id":"7396","city_name":"Shahjadpur","state_id":"398"},
    {"city_id":"7397","city_name":"Sirajganj","state_id":"398"},
    {"city_id":"7398","city_name":"Chhatak","state_id":"399"},
    {"city_id":"7399","city_name":"Sunamganj","state_id":"399"},
    {"city_id":"7400","city_name":"Gopalpur","state_id":"400"},
    {"city_id":"7401","city_name":"Mirzapur","state_id":"400"},
    {"city_id":"7402","city_name":"Sakhipur","state_id":"400"},
    {"city_id":"7403","city_name":"Tangayal","state_id":"400"},
    {"city_id":"7404","city_name":"Pirganj","state_id":"401"},
    {"city_id":"7405","city_name":"Thakurgaon","state_id":"401"},
    {"city_id":"7406","city_name":"Baranavichy","state_id":"413"},
    {"city_id":"7407","city_name":"Bjaroza","state_id":"413"},
    {"city_id":"7408","city_name":"Bjeloozersk","state_id":"413"},
    {"city_id":"7409","city_name":"Brest","state_id":"413"},
    {"city_id":"7410","city_name":"David-Haradok","state_id":"413"},
    {"city_id":"7411","city_name":"Drahichyn","state_id":"413"},
    {"city_id":"7412","city_name":"Hancavichy","state_id":"413"},
    {"city_id":"7413","city_name":"Ivacevichy","state_id":"413"},
    {"city_id":"7414","city_name":"Ivanava","state_id":"413"},
    {"city_id":"7415","city_name":"Kamjenec","state_id":"413"},
    {"city_id":"7416","city_name":"Kobryn","state_id":"413"},
    {"city_id":"7417","city_name":"Kosava","state_id":"413"},
    {"city_id":"7418","city_name":"Ljahavichy","state_id":"413"},
    {"city_id":"7419","city_name":"Luninjec","state_id":"413"},
    {"city_id":"7420","city_name":"Malaryta","state_id":"413"},
    {"city_id":"7421","city_name":"Mikashevichy","state_id":"413"},
    {"city_id":"7422","city_name":"Pinsk","state_id":"413"},
    {"city_id":"7423","city_name":"Pruzhany","state_id":"413"},
    {"city_id":"7424","city_name":"Stolin","state_id":"413"},
    {"city_id":"7425","city_name":"Vysokoje","state_id":"413"},
    {"city_id":"7426","city_name":"Zhabinka","state_id":"413"},
    {"city_id":"7427","city_name":"Buda Kosheljovo","state_id":"414"},
    {"city_id":"7428","city_name":"Chechersk","state_id":"414"},
    {"city_id":"7429","city_name":"Dobrush","state_id":"414"},
    {"city_id":"7430","city_name":"Hojniki","state_id":"414"},
    {"city_id":"7431","city_name":"Homjel'","state_id":"414"},
    {"city_id":"7432","city_name":"Jel'sk","state_id":"414"},
    {"city_id":"7433","city_name":"Kalinkavichy","state_id":"414"},
    {"city_id":"7434","city_name":"Kascjukovka","state_id":"414"},
    {"city_id":"7435","city_name":"Mazyr","state_id":"414"},
    {"city_id":"7436","city_name":"Narovlja","state_id":"414"},
    {"city_id":"7437","city_name":"Pjetrykav","state_id":"414"},
    {"city_id":"7438","city_name":"Rahachow","state_id":"414"},
    {"city_id":"7439","city_name":"Rechyca","state_id":"414"},
    {"city_id":"7440","city_name":"Svetlahorsk","state_id":"414"},
    {"city_id":"7441","city_name":"Vasilevichy","state_id":"414"},
    {"city_id":"7442","city_name":"Vjetka","state_id":"414"},
    {"city_id":"7443","city_name":"Zhlobin","state_id":"414"},
    {"city_id":"7444","city_name":"Zhytkavichy","state_id":"414"},
    {"city_id":"7445","city_name":"Ashmjany","state_id":"415"},
    {"city_id":"7446","city_name":"Berjozovka","state_id":"415"},
    {"city_id":"7447","city_name":"Hrodna","state_id":"415"},
    {"city_id":"7448","city_name":"Lida","state_id":"415"},
    {"city_id":"7449","city_name":"Masty","state_id":"415"},
    {"city_id":"7450","city_name":"Navahrudak","state_id":"415"},
    {"city_id":"7451","city_name":"Shchuchyn","state_id":"415"},
    {"city_id":"7452","city_name":"Skidel'","state_id":"415"},
    {"city_id":"7453","city_name":"Slonim","state_id":"415"},
    {"city_id":"7454","city_name":"Smarhon'","state_id":"415"},
    {"city_id":"7455","city_name":"Vawkavysk","state_id":"415"},
    {"city_id":"7456","city_name":"Asipovichy","state_id":"416"},
    {"city_id":"7457","city_name":"Babrujsk","state_id":"416"},
    {"city_id":"7458","city_name":"Byhau","state_id":"416"},
    {"city_id":"7459","city_name":"Chausy","state_id":"416"},
    {"city_id":"7460","city_name":"Cherikov","state_id":"416"},
    {"city_id":"7461","city_name":"Horki","state_id":"416"},
    {"city_id":"7462","city_name":"Kascjukovichy","state_id":"416"},
    {"city_id":"7463","city_name":"Klimavichy","state_id":"416"},
    {"city_id":"7464","city_name":"Krychaw","state_id":"416"},
    {"city_id":"7465","city_name":"Mahiljow","state_id":"416"},
    {"city_id":"7466","city_name":"Mstislav","state_id":"416"},
    {"city_id":"7467","city_name":"Shklov","state_id":"416"},
    {"city_id":"7468","city_name":"Slaeuharad","state_id":"416"},
    {"city_id":"7469","city_name":"Minsk","state_id":"418"},
    {"city_id":"7470","city_name":"Petrikov","state_id":"420"},
    {"city_id":"7471","city_name":"Baran'","state_id":"421"},
    {"city_id":"7472","city_name":"Braslav","state_id":"421"},
    {"city_id":"7473","city_name":"Chashniki","state_id":"421"},
    {"city_id":"7474","city_name":"Disna","state_id":"421"},
    {"city_id":"7475","city_name":"Dokshicy","state_id":"421"},
    {"city_id":"7476","city_name":"Dubrovna","state_id":"421"},
    {"city_id":"7477","city_name":"Haradok","state_id":"421"},
    {"city_id":"7478","city_name":"Hlybokaje","state_id":"421"},
    {"city_id":"7479","city_name":"Ljepjel'","state_id":"421"},
    {"city_id":"7480","city_name":"Mjory","state_id":"421"},
    {"city_id":"7481","city_name":"Navapolack","state_id":"421"},
    {"city_id":"7482","city_name":"Novalukoml'","state_id":"421"},
    {"city_id":"7483","city_name":"Orsha","state_id":"421"},
    {"city_id":"7484","city_name":"Pastavy","state_id":"421"},
    {"city_id":"7485","city_name":"Polack","state_id":"421"},
    {"city_id":"7486","city_name":"Polotsk","state_id":"421"},
    {"city_id":"7487","city_name":"Senno","state_id":"421"},
    {"city_id":"7488","city_name":"Talachyn","state_id":"421"},
    {"city_id":"7489","city_name":"Verhnjadzvimsk","state_id":"421"},
    {"city_id":"7490","city_name":"Vicebsk","state_id":"421"},
    {"city_id":"7491","city_name":"Aartselaar","state_id":"422"},
    {"city_id":"7492","city_name":"Antwerpen","state_id":"422"},
    {"city_id":"7493","city_name":"Arendonk","state_id":"422"},
    {"city_id":"7494","city_name":"Baarle-Hertog","state_id":"422"},
    {"city_id":"7495","city_name":"Balen","state_id":"422"},
    {"city_id":"7496","city_name":"Beerse","state_id":"422"},
    {"city_id":"7497","city_name":"Berlaar","state_id":"422"},
    {"city_id":"7498","city_name":"Boechout","state_id":"422"},
    {"city_id":"7499","city_name":"Bonheiden","state_id":"422"},
    {"city_id":"7500","city_name":"Boom","state_id":"422"},
    {"city_id":"7501","city_name":"Bornem","state_id":"422"},
    {"city_id":"7502","city_name":"Borsbeek","state_id":"422"},
    {"city_id":"7503","city_name":"Brasschaat","state_id":"422"},
    {"city_id":"7504","city_name":"Brecht","state_id":"422"},
    {"city_id":"7505","city_name":"Dessel","state_id":"422"},
    {"city_id":"7506","city_name":"Deurne","state_id":"422"},
    {"city_id":"7507","city_name":"Duffel","state_id":"422"},
    {"city_id":"7508","city_name":"Edegem","state_id":"422"},
    {"city_id":"7509","city_name":"Ekeren","state_id":"422"},
    {"city_id":"7510","city_name":"Essen","state_id":"422"},
    {"city_id":"7511","city_name":"Geel","state_id":"422"},
    {"city_id":"7512","city_name":"Geluvele","state_id":"422"},
    {"city_id":"7513","city_name":"Grobbendonk","state_id":"422"},
    {"city_id":"7514","city_name":"Heist-op-den-Berg","state_id":"422"},
    {"city_id":"7515","city_name":"Hemiksem","state_id":"422"},
    {"city_id":"7516","city_name":"Herentals","state_id":"422"},
    {"city_id":"7517","city_name":"Herenthout","state_id":"422"},
    {"city_id":"7518","city_name":"Herselt","state_id":"422"},
    {"city_id":"7519","city_name":"Hoogstraten","state_id":"422"},
    {"city_id":"7520","city_name":"Hove","state_id":"422"},
    {"city_id":"7521","city_name":"Hulshout","state_id":"422"},
    {"city_id":"7522","city_name":"Kalmpthout","state_id":"422"},
    {"city_id":"7523","city_name":"Kalmthout","state_id":"422"},
    {"city_id":"7524","city_name":"Kapellen","state_id":"422"},
    {"city_id":"7525","city_name":"Kasterlee","state_id":"422"},
    {"city_id":"7526","city_name":"Kontich","state_id":"422"},
    {"city_id":"7527","city_name":"Laakdal","state_id":"422"},
    {"city_id":"7528","city_name":"Lier","state_id":"422"},
    {"city_id":"7529","city_name":"Lille","state_id":"422"},
    {"city_id":"7530","city_name":"Lint","state_id":"422"},
    {"city_id":"7531","city_name":"Malle","state_id":"422"},
    {"city_id":"7532","city_name":"Mechelen","state_id":"422"},
    {"city_id":"7533","city_name":"Meerhout","state_id":"422"},
    {"city_id":"7534","city_name":"Merksplas","state_id":"422"},
    {"city_id":"7535","city_name":"Mol","state_id":"422"},
    {"city_id":"7536","city_name":"Mortsel","state_id":"422"},
    {"city_id":"7537","city_name":"Niel","state_id":"422"},
    {"city_id":"7538","city_name":"Nijlen","state_id":"422"},
    {"city_id":"7539","city_name":"Olen","state_id":"422"},
    {"city_id":"7540","city_name":"Oud-Turnhout","state_id":"422"},
    {"city_id":"7541","city_name":"Putte","state_id":"422"},
    {"city_id":"7542","city_name":"Puurs","state_id":"422"},
    {"city_id":"7543","city_name":"Ranst","state_id":"422"},
    {"city_id":"7544","city_name":"Ravels","state_id":"422"},
    {"city_id":"7545","city_name":"Retie","state_id":"422"},
    {"city_id":"7546","city_name":"Rijkevorsel","state_id":"422"},
    {"city_id":"7547","city_name":"Robrechts","state_id":"422"},
    {"city_id":"7548","city_name":"Rumst","state_id":"422"},
    {"city_id":"7549","city_name":"Schelle","state_id":"422"},
    {"city_id":"7550","city_name":"Schilde","state_id":"422"},
    {"city_id":"7551","city_name":"Schoten","state_id":"422"},
    {"city_id":"7552","city_name":"Sint-Amands","state_id":"422"},
    {"city_id":"7553","city_name":"Sint-Katelijne-Waver","state_id":"422"},
    {"city_id":"7554","city_name":"Stabroek","state_id":"422"},
    {"city_id":"7555","city_name":"Turnhout","state_id":"422"},
    {"city_id":"7556","city_name":"Vorselaar","state_id":"422"},
    {"city_id":"7557","city_name":"Vosselaar","state_id":"422"},
    {"city_id":"7558","city_name":"Westerlo","state_id":"422"},
    {"city_id":"7559","city_name":"Wijnegem","state_id":"422"},
    {"city_id":"7560","city_name":"Willebroek","state_id":"422"},
    {"city_id":"7561","city_name":"Wilrijk","state_id":"422"},
    {"city_id":"7562","city_name":"Wommelgem","state_id":"422"},
    {"city_id":"7563","city_name":"Wuustwezel","state_id":"422"},
    {"city_id":"7564","city_name":"Zandhoven","state_id":"422"},
    {"city_id":"7565","city_name":"Zoersel","state_id":"422"},
    {"city_id":"7566","city_name":"Zwijndrecht","state_id":"422"},
    {"city_id":"7567","city_name":"Berchem","state_id":"423"},
    {"city_id":"7568","city_name":"Alsemberg","state_id":"424"},
    {"city_id":"7569","city_name":"Asse Zellik","state_id":"424"},
    {"city_id":"7570","city_name":"Brussels","state_id":"424"},
    {"city_id":"7571","city_name":"Bruxelles","state_id":"424"},
    {"city_id":"7572","city_name":"Diegem","state_id":"424"},
    {"city_id":"7573","city_name":"Alsemberg","state_id":"425"},
    {"city_id":"7574","city_name":"Asse Zellik","state_id":"425"},
    {"city_id":"7575","city_name":"Brussels","state_id":"425"},
    {"city_id":"7576","city_name":"Bruxelles","state_id":"425"},
    {"city_id":"7577","city_name":"Diegem","state_id":"425"},
    {"city_id":"7578","city_name":"Brussel","state_id":"426"},
    {"city_id":"7579","city_name":"Aiseau-Presles","state_id":"428"},
    {"city_id":"7580","city_name":"Anderlues","state_id":"428"},
    {"city_id":"7581","city_name":"Antoing","state_id":"428"},
    {"city_id":"7582","city_name":"Ath","state_id":"428"},
    {"city_id":"7583","city_name":"Beaumont","state_id":"428"},
    {"city_id":"7584","city_name":"Beloeil","state_id":"428"},
    {"city_id":"7585","city_name":"Bernissart","state_id":"428"},
    {"city_id":"7586","city_name":"Binche","state_id":"428"},
    {"city_id":"7587","city_name":"Boussu","state_id":"428"},
    {"city_id":"7588","city_name":"Braine-le-Comte","state_id":"428"},
    {"city_id":"7589","city_name":"Brugelette","state_id":"428"},
    {"city_id":"7590","city_name":"Brunehaut","state_id":"428"},
    {"city_id":"7591","city_name":"Celles","state_id":"428"},
    {"city_id":"7592","city_name":"Chapelle-lez-Herlaimont","state_id":"428"},
    {"city_id":"7593","city_name":"Charleroi","state_id":"428"},
    {"city_id":"7594","city_name":"Chatelet","state_id":"428"},
    {"city_id":"7595","city_name":"Chievres","state_id":"428"},
    {"city_id":"7596","city_name":"Chimay","state_id":"428"},
    {"city_id":"7597","city_name":"Colfontaine","state_id":"428"},
    {"city_id":"7598","city_name":"Comines-Warneton","state_id":"428"},
    {"city_id":"7599","city_name":"Courcelles","state_id":"428"},
    {"city_id":"7600","city_name":"Dour","state_id":"428"},
    {"city_id":"7601","city_name":"Ecaussinnes","state_id":"428"},
    {"city_id":"7602","city_name":"Ellezelles","state_id":"428"},
    {"city_id":"7603","city_name":"Enghien","state_id":"428"},
    {"city_id":"7604","city_name":"Erquelinnes","state_id":"428"},
    {"city_id":"7605","city_name":"Estaimpuis","state_id":"428"},
    {"city_id":"7606","city_name":"Estinnes","state_id":"428"},
    {"city_id":"7607","city_name":"Farciennes","state_id":"428"},
    {"city_id":"7608","city_name":"Fleurus","state_id":"428"},
    {"city_id":"7609","city_name":"Flobecq","state_id":"428"},
    {"city_id":"7610","city_name":"Fontaine-l'Eveque","state_id":"428"},
    {"city_id":"7611","city_name":"Frameries","state_id":"428"},
    {"city_id":"7612","city_name":"Frasnes-lez-Anvaing","state_id":"428"},
    {"city_id":"7613","city_name":"Froidchapelle","state_id":"428"},
    {"city_id":"7614","city_name":"Gerpinnes","state_id":"428"},
    {"city_id":"7615","city_name":"Haine-Saint-Pierre","state_id":"428"},
    {"city_id":"7616","city_name":"Ham-sur-Heure-Nalinnes","state_id":"428"},
    {"city_id":"7617","city_name":"Hensies","state_id":"428"},
    {"city_id":"7618","city_name":"Honnelles","state_id":"428"},
    {"city_id":"7619","city_name":"Jurbise","state_id":"428"},
    {"city_id":"7620","city_name":"La Louviere","state_id":"428"},
    {"city_id":"7621","city_name":"Le Roeulx","state_id":"428"},
    {"city_id":"7622","city_name":"Lens","state_id":"428"},
    {"city_id":"7623","city_name":"Les Bons Villers","state_id":"428"},
    {"city_id":"7624","city_name":"Lessines","state_id":"428"},
    {"city_id":"7625","city_name":"Leuze-en-Hainaut","state_id":"428"},
    {"city_id":"7626","city_name":"Lobbes","state_id":"428"},
    {"city_id":"7627","city_name":"Manage","state_id":"428"},
    {"city_id":"7628","city_name":"Merbes-le-Chateau","state_id":"428"},
    {"city_id":"7629","city_name":"Momignies","state_id":"428"},
    {"city_id":"7630","city_name":"Mons","state_id":"428"},
    {"city_id":"7631","city_name":"Mont-de-l'Enclus","state_id":"428"},
    {"city_id":"7632","city_name":"Montigny-le-Tilleul","state_id":"428"},
    {"city_id":"7633","city_name":"Morlanwelz","state_id":"428"},
    {"city_id":"7634","city_name":"Mouscron","state_id":"428"},
    {"city_id":"7635","city_name":"Pecq","state_id":"428"},
    {"city_id":"7636","city_name":"Peruwelz","state_id":"428"},
    {"city_id":"7637","city_name":"Pont-a-Celles","state_id":"428"},
    {"city_id":"7638","city_name":"Quaregnon","state_id":"428"},
    {"city_id":"7639","city_name":"Quevy","state_id":"428"},
    {"city_id":"7640","city_name":"Quievrain","state_id":"428"},
    {"city_id":"7641","city_name":"Rumes","state_id":"428"},
    {"city_id":"7642","city_name":"Saint-Ghislain","state_id":"428"},
    {"city_id":"7643","city_name":"Seneffe","state_id":"428"},
    {"city_id":"7644","city_name":"Silly","state_id":"428"},
    {"city_id":"7645","city_name":"Sivry-Rance","state_id":"428"},
    {"city_id":"7646","city_name":"Soignies","state_id":"428"},
    {"city_id":"7647","city_name":"Thuin","state_id":"428"},
    {"city_id":"7648","city_name":"Tournai","state_id":"428"},
    {"city_id":"7649","city_name":"Amay","state_id":"429"},
    {"city_id":"7650","city_name":"Amel","state_id":"429"},
    {"city_id":"7651","city_name":"Angleur","state_id":"429"},
    {"city_id":"7652","city_name":"Ans","state_id":"429"},
    {"city_id":"7653","city_name":"Anthisnes","state_id":"429"},
    {"city_id":"7654","city_name":"Aubel","state_id":"429"},
    {"city_id":"7655","city_name":"Awans","state_id":"429"},
    {"city_id":"7656","city_name":"Aywaille","state_id":"429"},
    {"city_id":"7657","city_name":"Baelen","state_id":"429"},
    {"city_id":"7658","city_name":"Bassenge","state_id":"429"},
    {"city_id":"7659","city_name":"Berloz","state_id":"429"},
    {"city_id":"7660","city_name":"Beyne-Heusay","state_id":"429"},
    {"city_id":"7661","city_name":"Blegny","state_id":"429"},
    {"city_id":"7662","city_name":"Braives","state_id":"429"},
    {"city_id":"7663","city_name":"Bullingen","state_id":"429"},
    {"city_id":"7664","city_name":"Burdinne","state_id":"429"},
    {"city_id":"7665","city_name":"Burg-Reuland","state_id":"429"},
    {"city_id":"7666","city_name":"Butgenbach","state_id":"429"},
    {"city_id":"7667","city_name":"Chaudfontaine","state_id":"429"},
    {"city_id":"7668","city_name":"Clavier","state_id":"429"},
    {"city_id":"7669","city_name":"Comblain-au-Pont","state_id":"429"},
    {"city_id":"7670","city_name":"Crisnee","state_id":"429"},
    {"city_id":"7671","city_name":"Dalhem","state_id":"429"},
    {"city_id":"7672","city_name":"Dison","state_id":"429"},
    {"city_id":"7673","city_name":"Donceel","state_id":"429"},
    {"city_id":"7674","city_name":"Engis","state_id":"429"},
    {"city_id":"7675","city_name":"Esneux","state_id":"429"},
    {"city_id":"7676","city_name":"Eupen","state_id":"429"},
    {"city_id":"7677","city_name":"Faimes","state_id":"429"},
    {"city_id":"7678","city_name":"Ferrieres","state_id":"429"},
    {"city_id":"7679","city_name":"Fexhe-le-Haut-Clocher","state_id":"429"},
    {"city_id":"7680","city_name":"Flemalle","state_id":"429"},
    {"city_id":"7681","city_name":"Fleron","state_id":"429"},
    {"city_id":"7682","city_name":"Geer","state_id":"429"},
    {"city_id":"7683","city_name":"Grace-Hollogne","state_id":"429"},
    {"city_id":"7684","city_name":"Hamoir","state_id":"429"},
    {"city_id":"7685","city_name":"Hannut","state_id":"429"},
    {"city_id":"7686","city_name":"Heron","state_id":"429"},
    {"city_id":"7687","city_name":"Herstal","state_id":"429"},
    {"city_id":"7688","city_name":"Herve","state_id":"429"},
    {"city_id":"7689","city_name":"Huy","state_id":"429"},
    {"city_id":"7690","city_name":"Jalhay","state_id":"429"},
    {"city_id":"7691","city_name":"Juprelle","state_id":"429"},
    {"city_id":"7692","city_name":"Kelmis","state_id":"429"},
    {"city_id":"7693","city_name":"Liege","state_id":"429"},
    {"city_id":"7694","city_name":"Lierneux","state_id":"429"},
    {"city_id":"7695","city_name":"Limbourg","state_id":"429"},
    {"city_id":"7696","city_name":"Lincent","state_id":"429"},
    {"city_id":"7697","city_name":"Lontzen","state_id":"429"},
    {"city_id":"7698","city_name":"Malmedy","state_id":"429"},
    {"city_id":"7699","city_name":"Marchin","state_id":"429"},
    {"city_id":"7700","city_name":"Modave","state_id":"429"},
    {"city_id":"7701","city_name":"Montzen","state_id":"429"},
    {"city_id":"7702","city_name":"Nandrin","state_id":"429"},
    {"city_id":"7703","city_name":"Neupre","state_id":"429"},
    {"city_id":"7704","city_name":"Olne","state_id":"429"},
    {"city_id":"7705","city_name":"Oreye","state_id":"429"},
    {"city_id":"7706","city_name":"Ouffet","state_id":"429"},
    {"city_id":"7707","city_name":"Oupeye","state_id":"429"},
    {"city_id":"7708","city_name":"Pepinster","state_id":"429"},
    {"city_id":"7709","city_name":"Plombieres","state_id":"429"},
    {"city_id":"7710","city_name":"Racour","state_id":"429"},
    {"city_id":"7711","city_name":"Raeren","state_id":"429"},
    {"city_id":"7712","city_name":"Remicourt","state_id":"429"},
    {"city_id":"7713","city_name":"Saint-Georges-sur-Meuse","state_id":"429"},
    {"city_id":"7714","city_name":"Saint-Nicolas","state_id":"429"},
    {"city_id":"7715","city_name":"Sankt Vith","state_id":"429"},
    {"city_id":"7716","city_name":"Seraing","state_id":"429"},
    {"city_id":"7717","city_name":"Soumagne","state_id":"429"},
    {"city_id":"7718","city_name":"Spa","state_id":"429"},
    {"city_id":"7719","city_name":"Sprimont","state_id":"429"},
    {"city_id":"7720","city_name":"Stavelot","state_id":"429"},
    {"city_id":"7721","city_name":"Stoumont","state_id":"429"},
    {"city_id":"7722","city_name":"Theux","state_id":"429"},
    {"city_id":"7723","city_name":"Thimister-Clermont","state_id":"429"},
    {"city_id":"7724","city_name":"Tinlot","state_id":"429"},
    {"city_id":"7725","city_name":"Trois-Ponts","state_id":"429"},
    {"city_id":"7726","city_name":"Trooz","state_id":"429"},
    {"city_id":"7727","city_name":"Verlaine","state_id":"429"},
    {"city_id":"7728","city_name":"Verviers","state_id":"429"},
    {"city_id":"7729","city_name":"Villers-le-Bouillet","state_id":"429"},
    {"city_id":"7730","city_name":"Vise","state_id":"429"},
    {"city_id":"7731","city_name":"Waimes","state_id":"429"},
    {"city_id":"7732","city_name":"Wanze","state_id":"429"},
    {"city_id":"7733","city_name":"Waremme","state_id":"429"},
    {"city_id":"7734","city_name":"Wasseiges","state_id":"429"},
    {"city_id":"7735","city_name":"Welkenraedt","state_id":"429"},
    {"city_id":"7736","city_name":"Alken","state_id":"430"},
    {"city_id":"7737","city_name":"As","state_id":"430"},
    {"city_id":"7738","city_name":"Beringen","state_id":"430"},
    {"city_id":"7739","city_name":"Bilzen","state_id":"430"},
    {"city_id":"7740","city_name":"Bocholt","state_id":"430"},
    {"city_id":"7741","city_name":"Borgloon","state_id":"430"},
    {"city_id":"7742","city_name":"Bree","state_id":"430"},
    {"city_id":"7743","city_name":"Diepenbeek","state_id":"430"},
    {"city_id":"7744","city_name":"Dilsen-Stokkem","state_id":"430"},
    {"city_id":"7745","city_name":"Genk","state_id":"430"},
    {"city_id":"7746","city_name":"Gingelom","state_id":"430"},
    {"city_id":"7747","city_name":"Halen","state_id":"430"},
    {"city_id":"7748","city_name":"Ham","state_id":"430"},
    {"city_id":"7749","city_name":"Hamont-Achel","state_id":"430"},
    {"city_id":"7750","city_name":"Hasselt","state_id":"430"},
    {"city_id":"7751","city_name":"Hechtel-Eksel","state_id":"430"},
    {"city_id":"7752","city_name":"Heers","state_id":"430"},
    {"city_id":"7753","city_name":"Herk-de-Stad","state_id":"430"},
    {"city_id":"7754","city_name":"Herstappe","state_id":"430"},
    {"city_id":"7755","city_name":"Heusden-Zolder","state_id":"430"},
    {"city_id":"7756","city_name":"Hoeselt","state_id":"430"},
    {"city_id":"7757","city_name":"Houthalen-Helchteren","state_id":"430"},
    {"city_id":"7758","city_name":"Kinrooi","state_id":"430"},
    {"city_id":"7759","city_name":"Kortessem","state_id":"430"},
    {"city_id":"7760","city_name":"Kuringen","state_id":"430"},
    {"city_id":"7761","city_name":"Lanaken","state_id":"430"},
    {"city_id":"7762","city_name":"Leopoldsburg","state_id":"430"},
    {"city_id":"7763","city_name":"Lommel","state_id":"430"},
    {"city_id":"7764","city_name":"Lummen","state_id":"430"},
    {"city_id":"7765","city_name":"Maaseik","state_id":"430"},
    {"city_id":"7766","city_name":"Maasmechelen","state_id":"430"},
    {"city_id":"7767","city_name":"Meeuwen-Gruitrode","state_id":"430"},
    {"city_id":"7768","city_name":"Neerpelt","state_id":"430"},
    {"city_id":"7769","city_name":"Nieuwerkerken","state_id":"430"},
    {"city_id":"7770","city_name":"Opglabbeek","state_id":"430"},
    {"city_id":"7771","city_name":"Overpelt","state_id":"430"},
    {"city_id":"7772","city_name":"Peer","state_id":"430"},
    {"city_id":"7773","city_name":"Riemst","state_id":"430"},
    {"city_id":"7774","city_name":"Sint-Truiden","state_id":"430"},
    {"city_id":"7775","city_name":"Tessenderlo","state_id":"430"},
    {"city_id":"7776","city_name":"Tongeren","state_id":"430"},
    {"city_id":"7777","city_name":"Voeren","state_id":"430"},
    {"city_id":"7778","city_name":"Wellen","state_id":"430"},
    {"city_id":"7779","city_name":"Zonhoven","state_id":"430"},
    {"city_id":"7780","city_name":"Zutendaal","state_id":"430"},
    {"city_id":"7781","city_name":"Arlon","state_id":"431"},
    {"city_id":"7782","city_name":"Attert","state_id":"431"},
    {"city_id":"7783","city_name":"Aubange","state_id":"431"},
    {"city_id":"7784","city_name":"Bastogne","state_id":"431"},
    {"city_id":"7785","city_name":"Bertogne","state_id":"431"},
    {"city_id":"7786","city_name":"Bertrix","state_id":"431"},
    {"city_id":"7787","city_name":"Bouillon","state_id":"431"},
    {"city_id":"7788","city_name":"Chiny","state_id":"431"},
    {"city_id":"7789","city_name":"Daverdisse","state_id":"431"},
    {"city_id":"7790","city_name":"Durbuy","state_id":"431"},
    {"city_id":"7791","city_name":"Erezee","state_id":"431"},
    {"city_id":"7792","city_name":"Etalle","state_id":"431"},
    {"city_id":"7793","city_name":"Fauvillers","state_id":"431"},
    {"city_id":"7794","city_name":"Florenville","state_id":"431"},
    {"city_id":"7795","city_name":"Gouvy","state_id":"431"},
    {"city_id":"7796","city_name":"Grapfontaine","state_id":"431"},
    {"city_id":"7797","city_name":"Habay","state_id":"431"},
    {"city_id":"7798","city_name":"Herbeumont","state_id":"431"},
    {"city_id":"7799","city_name":"Hotton","state_id":"431"},
    {"city_id":"7800","city_name":"Houffalize","state_id":"431"},
    {"city_id":"7801","city_name":"La Roche-en-Ardenne","state_id":"431"},
    {"city_id":"7802","city_name":"Leglise","state_id":"431"},
    {"city_id":"7803","city_name":"Libin","state_id":"431"},
    {"city_id":"7804","city_name":"Libramont-Chevigny","state_id":"431"},
    {"city_id":"7805","city_name":"Manhay","state_id":"431"},
    {"city_id":"7806","city_name":"Marche-en-Famenne","state_id":"431"},
    {"city_id":"7807","city_name":"Marloie","state_id":"431"},
    {"city_id":"7808","city_name":"Martelange","state_id":"431"},
    {"city_id":"7809","city_name":"Meix-devant-Virton","state_id":"431"},
    {"city_id":"7810","city_name":"Messancy","state_id":"431"},
    {"city_id":"7811","city_name":"Musson","state_id":"431"},
    {"city_id":"7812","city_name":"Nassogne","state_id":"431"},
    {"city_id":"7813","city_name":"Neufchateau","state_id":"431"},
    {"city_id":"7814","city_name":"Paliseul","state_id":"431"},
    {"city_id":"7815","city_name":"Rendeux","state_id":"431"},
    {"city_id":"7816","city_name":"Rouvroy","state_id":"431"},
    {"city_id":"7817","city_name":"Saint-Hubert","state_id":"431"},
    {"city_id":"7818","city_name":"Saint-Leger","state_id":"431"},
    {"city_id":"7819","city_name":"Sainte-Ode","state_id":"431"},
    {"city_id":"7820","city_name":"Tellin","state_id":"431"},
    {"city_id":"7821","city_name":"Tenneville","state_id":"431"},
    {"city_id":"7822","city_name":"Tintigny","state_id":"431"},
    {"city_id":"7823","city_name":"Vaux-sur-Sure","state_id":"431"},
    {"city_id":"7824","city_name":"Vielsalm","state_id":"431"},
    {"city_id":"7825","city_name":"Virton","state_id":"431"},
    {"city_id":"7826","city_name":"Wellin","state_id":"431"},
    {"city_id":"7827","city_name":"Andenne","state_id":"432"},
    {"city_id":"7828","city_name":"Anhee","state_id":"432"},
    {"city_id":"7829","city_name":"Assesse","state_id":"432"},
    {"city_id":"7830","city_name":"Beauraing","state_id":"432"},
    {"city_id":"7831","city_name":"Bievre","state_id":"432"},
    {"city_id":"7832","city_name":"Cerfontaine","state_id":"432"},
    {"city_id":"7833","city_name":"Ciney","state_id":"432"},
    {"city_id":"7834","city_name":"Couvin","state_id":"432"},
    {"city_id":"7835","city_name":"Dinant","state_id":"432"},
    {"city_id":"7836","city_name":"Doische","state_id":"432"},
    {"city_id":"7837","city_name":"Eghezee","state_id":"432"},
    {"city_id":"7838","city_name":"Fernelmont","state_id":"432"},
    {"city_id":"7839","city_name":"Floreffe","state_id":"432"},
    {"city_id":"7840","city_name":"Florennes","state_id":"432"},
    {"city_id":"7841","city_name":"Fosses-la-Ville","state_id":"432"},
    {"city_id":"7842","city_name":"Gedinne","state_id":"432"},
    {"city_id":"7843","city_name":"Gembloux","state_id":"432"},
    {"city_id":"7844","city_name":"Gesves","state_id":"432"},
    {"city_id":"7845","city_name":"Hamois","state_id":"432"},
    {"city_id":"7846","city_name":"Hastiere","state_id":"432"},
    {"city_id":"7847","city_name":"Havelange","state_id":"432"},
    {"city_id":"7848","city_name":"Houyet","state_id":"432"},
    {"city_id":"7849","city_name":"Jambes","state_id":"432"},
    {"city_id":"7850","city_name":"Jemeppe-sur-Sambre","state_id":"432"},
    {"city_id":"7851","city_name":"La Bruyere","state_id":"432"},
    {"city_id":"7852","city_name":"Malonne","state_id":"432"},
    {"city_id":"7853","city_name":"Mettet","state_id":"432"},
    {"city_id":"7854","city_name":"Namur","state_id":"432"},
    {"city_id":"7855","city_name":"Ohey","state_id":"432"},
    {"city_id":"7856","city_name":"Onhaye","state_id":"432"},
    {"city_id":"7857","city_name":"Philippeville","state_id":"432"},
    {"city_id":"7858","city_name":"Profondeville","state_id":"432"},
    {"city_id":"7859","city_name":"Rochefort","state_id":"432"},
    {"city_id":"7860","city_name":"Sambreville","state_id":"432"},
    {"city_id":"7861","city_name":"Sombreffe","state_id":"432"},
    {"city_id":"7862","city_name":"Somme-Leuze","state_id":"432"},
    {"city_id":"7863","city_name":"Suarlee","state_id":"432"},
    {"city_id":"7864","city_name":"Viroinval","state_id":"432"},
    {"city_id":"7865","city_name":"Vresse-sur-Semois","state_id":"432"},
    {"city_id":"7866","city_name":"Walcourt","state_id":"432"},
    {"city_id":"7867","city_name":"Yvoir","state_id":"432"},
    {"city_id":"7868","city_name":"Gullegem","state_id":"433"},
    {"city_id":"7869","city_name":"Langdorp","state_id":"433"},
    {"city_id":"7870","city_name":"Aalst","state_id":"434"},
    {"city_id":"7871","city_name":"Aalter","state_id":"434"},
    {"city_id":"7872","city_name":"Assenede","state_id":"434"},
    {"city_id":"7873","city_name":"Berlare","state_id":"434"},
    {"city_id":"7874","city_name":"Beveren","state_id":"434"},
    {"city_id":"7875","city_name":"Brakel","state_id":"434"},
    {"city_id":"7876","city_name":"Buggenhout","state_id":"434"},
    {"city_id":"7877","city_name":"De Pinte","state_id":"434"},
    {"city_id":"7878","city_name":"Deinze","state_id":"434"},
    {"city_id":"7879","city_name":"Denderleeuw","state_id":"434"},
    {"city_id":"7880","city_name":"Dendermonde","state_id":"434"},
    {"city_id":"7881","city_name":"Destelbergen","state_id":"434"},
    {"city_id":"7882","city_name":"Drongen","state_id":"434"},
    {"city_id":"7883","city_name":"Eeklo","state_id":"434"},
    {"city_id":"7884","city_name":"Erembodegem","state_id":"434"},
    {"city_id":"7885","city_name":"Erpe-Mere","state_id":"434"},
    {"city_id":"7886","city_name":"Evergem","state_id":"434"},
    {"city_id":"7887","city_name":"Gavere","state_id":"434"},
    {"city_id":"7888","city_name":"Gent","state_id":"434"},
    {"city_id":"7889","city_name":"Geraardsbergen","state_id":"434"},
    {"city_id":"7890","city_name":"Ghent","state_id":"434"},
    {"city_id":"7891","city_name":"Haaltert","state_id":"434"},
    {"city_id":"7892","city_name":"Hamme","state_id":"434"},
    {"city_id":"7893","city_name":"Herzele","state_id":"434"},
    {"city_id":"7894","city_name":"Horebeke","state_id":"434"},
    {"city_id":"7895","city_name":"Kaprijke","state_id":"434"},
    {"city_id":"7896","city_name":"Kerksken","state_id":"434"},
    {"city_id":"7897","city_name":"Kluisbergen","state_id":"434"},
    {"city_id":"7898","city_name":"Knesselare","state_id":"434"},
    {"city_id":"7899","city_name":"Kruibeke","state_id":"434"},
    {"city_id":"7900","city_name":"Kruishoutem","state_id":"434"},
    {"city_id":"7901","city_name":"Laarne","state_id":"434"},
    {"city_id":"7902","city_name":"Lebbeke","state_id":"434"},
    {"city_id":"7903","city_name":"Lede","state_id":"434"},
    {"city_id":"7904","city_name":"Lierde","state_id":"434"},
    {"city_id":"7905","city_name":"Lochristi","state_id":"434"},
    {"city_id":"7906","city_name":"Lokeren","state_id":"434"},
    {"city_id":"7907","city_name":"Lovendegem","state_id":"434"},
    {"city_id":"7908","city_name":"Maarkedal","state_id":"434"},
    {"city_id":"7909","city_name":"Maldegem","state_id":"434"},
    {"city_id":"7910","city_name":"Melle","state_id":"434"},
    {"city_id":"7911","city_name":"Merelbeke","state_id":"434"},
    {"city_id":"7912","city_name":"Moerbeke","state_id":"434"},
    {"city_id":"7913","city_name":"Nazareth","state_id":"434"},
    {"city_id":"7914","city_name":"Nevele","state_id":"434"},
    {"city_id":"7915","city_name":"Ninove","state_id":"434"},
    {"city_id":"7916","city_name":"Oosterzele","state_id":"434"},
    {"city_id":"7917","city_name":"Oudenaarde","state_id":"434"},
    {"city_id":"7918","city_name":"Ronse","state_id":"434"},
    {"city_id":"7919","city_name":"Semmerzake","state_id":"434"},
    {"city_id":"7920","city_name":"Sint-Gillis-Waas","state_id":"434"},
    {"city_id":"7921","city_name":"Sint-Laureins","state_id":"434"},
    {"city_id":"7922","city_name":"Sint-Lievens-Houtem","state_id":"434"},
    {"city_id":"7923","city_name":"Sint-Martens-Latem","state_id":"434"},
    {"city_id":"7924","city_name":"Sint-Niklaas","state_id":"434"},
    {"city_id":"7925","city_name":"Stekene","state_id":"434"},
    {"city_id":"7926","city_name":"Temse","state_id":"434"},
    {"city_id":"7927","city_name":"Waarschoot","state_id":"434"},
    {"city_id":"7928","city_name":"Waasmunster","state_id":"434"},
    {"city_id":"7929","city_name":"Wachtebeke","state_id":"434"},
    {"city_id":"7930","city_name":"Wetteren","state_id":"434"},
    {"city_id":"7931","city_name":"Wichelen","state_id":"434"},
    {"city_id":"7932","city_name":"Wortegem-Petegem","state_id":"434"},
    {"city_id":"7933","city_name":"Zele","state_id":"434"},
    {"city_id":"7934","city_name":"Zelzate","state_id":"434"},
    {"city_id":"7935","city_name":"Zingem","state_id":"434"},
    {"city_id":"7936","city_name":"Zomergem","state_id":"434"},
    {"city_id":"7937","city_name":"Zottegem","state_id":"434"},
    {"city_id":"7938","city_name":"Zulte","state_id":"434"},
    {"city_id":"7939","city_name":"Zwalm","state_id":"434"},
    {"city_id":"7940","city_name":"Zwijnaarde","state_id":"434"},
    {"city_id":"7941","city_name":"Aarschot","state_id":"436"},
    {"city_id":"7942","city_name":"Affligem","state_id":"436"},
    {"city_id":"7943","city_name":"Asse","state_id":"436"},
    {"city_id":"7944","city_name":"Beersel","state_id":"436"},
    {"city_id":"7945","city_name":"Begijnendijk","state_id":"436"},
    {"city_id":"7946","city_name":"Bekkevoort","state_id":"436"},
    {"city_id":"7947","city_name":"Bertem","state_id":"436"},
    {"city_id":"7948","city_name":"Bever","state_id":"436"},
    {"city_id":"7949","city_name":"Bierbeek","state_id":"436"},
    {"city_id":"7950","city_name":"Boortmeerbeek","state_id":"436"},
    {"city_id":"7951","city_name":"Boutersem","state_id":"436"},
    {"city_id":"7952","city_name":"Diest","state_id":"436"},
    {"city_id":"7953","city_name":"Dilbeek","state_id":"436"},
    {"city_id":"7954","city_name":"Drogenbos","state_id":"436"},
    {"city_id":"7955","city_name":"Galmaarden","state_id":"436"},
    {"city_id":"7956","city_name":"Geetbets","state_id":"436"},
    {"city_id":"7957","city_name":"Glabbeek","state_id":"436"},
    {"city_id":"7958","city_name":"Gooik","state_id":"436"},
    {"city_id":"7959","city_name":"Grimbergen","state_id":"436"},
    {"city_id":"7960","city_name":"Haacht","state_id":"436"},
    {"city_id":"7961","city_name":"Halle","state_id":"436"},
    {"city_id":"7962","city_name":"Herent","state_id":"436"},
    {"city_id":"7963","city_name":"Herne","state_id":"436"},
    {"city_id":"7964","city_name":"Hoegaarden","state_id":"436"},
    {"city_id":"7965","city_name":"Hoeilaart","state_id":"436"},
    {"city_id":"7966","city_name":"Holsbeek","state_id":"436"},
    {"city_id":"7967","city_name":"Huldenberg","state_id":"436"},
    {"city_id":"7968","city_name":"Kampenhout","state_id":"436"},
    {"city_id":"7969","city_name":"Kapelle-op-den-Bos","state_id":"436"},
    {"city_id":"7970","city_name":"Keerbergen","state_id":"436"},
    {"city_id":"7971","city_name":"Kortenaken","state_id":"436"},
    {"city_id":"7972","city_name":"Kortenberg","state_id":"436"},
    {"city_id":"7973","city_name":"Kraainem","state_id":"436"},
    {"city_id":"7974","city_name":"Landen","state_id":"436"},
    {"city_id":"7975","city_name":"Lennik","state_id":"436"},
    {"city_id":"7976","city_name":"Leuven","state_id":"436"},
    {"city_id":"7977","city_name":"Liedekerke","state_id":"436"},
    {"city_id":"7978","city_name":"Linkebeek","state_id":"436"},
    {"city_id":"7979","city_name":"Linter","state_id":"436"},
    {"city_id":"7980","city_name":"Londerzeel","state_id":"436"},
    {"city_id":"7981","city_name":"Lubbeek","state_id":"436"},
    {"city_id":"7982","city_name":"Machelen","state_id":"436"},
    {"city_id":"7983","city_name":"Meise","state_id":"436"},
    {"city_id":"7984","city_name":"Merchtem","state_id":"436"},
    {"city_id":"7985","city_name":"Opwijk","state_id":"436"},
    {"city_id":"7986","city_name":"Oud-Heverlee","state_id":"436"},
    {"city_id":"7987","city_name":"Overijse","state_id":"436"},
    {"city_id":"7988","city_name":"Pepingen","state_id":"436"},
    {"city_id":"7989","city_name":"Roosdaal","state_id":"436"},
    {"city_id":"7990","city_name":"Rotselaar","state_id":"436"},
    {"city_id":"7991","city_name":"Scherpenheuvel-Zichem","state_id":"436"},
    {"city_id":"7992","city_name":"Sint-Genesius-Rode","state_id":"436"},
    {"city_id":"7993","city_name":"Sint-Pieters-Leeuw","state_id":"436"},
    {"city_id":"7994","city_name":"Steenokkerzeel","state_id":"436"},
    {"city_id":"7995","city_name":"Ternat","state_id":"436"},
    {"city_id":"7996","city_name":"Tervuren","state_id":"436"},
    {"city_id":"7997","city_name":"Tielt-Winge","state_id":"436"},
    {"city_id":"7998","city_name":"Tienen","state_id":"436"},
    {"city_id":"7999","city_name":"Tremelo","state_id":"436"},
    {"city_id":"8000","city_name":"Vilvoorde","state_id":"436"},
    {"city_id":"8001","city_name":"Wemmel","state_id":"436"},
    {"city_id":"8002","city_name":"Wezembeek-Oppem","state_id":"436"},
    {"city_id":"8003","city_name":"Zaventem","state_id":"436"},
    {"city_id":"8004","city_name":"Zemst","state_id":"436"},
    {"city_id":"8005","city_name":"Zoutleeuw","state_id":"436"},
    {"city_id":"8006","city_name":"Callenelle","state_id":"437"},
    {"city_id":"8007","city_name":"Marcinelle","state_id":"437"},
    {"city_id":"8008","city_name":"Strepy-Bracquegnies","state_id":"437"},
    {"city_id":"8009","city_name":"Alveringem","state_id":"438"},
    {"city_id":"8010","city_name":"Anzegem","state_id":"438"},
    {"city_id":"8011","city_name":"Ardooie","state_id":"438"},
    {"city_id":"8012","city_name":"Avelgem","state_id":"438"},
    {"city_id":"8013","city_name":"Beernem","state_id":"438"},
    {"city_id":"8014","city_name":"Blankenberge","state_id":"438"},
    {"city_id":"8015","city_name":"Bredene","state_id":"438"},
    {"city_id":"8016","city_name":"Brugge","state_id":"438"},
    {"city_id":"8017","city_name":"Damme","state_id":"438"},
    {"city_id":"8018","city_name":"De Haan","state_id":"438"},
    {"city_id":"8019","city_name":"De Panne","state_id":"438"},
    {"city_id":"8020","city_name":"Deerlijk","state_id":"438"},
    {"city_id":"8021","city_name":"Dentergem","state_id":"438"},
    {"city_id":"8022","city_name":"Diksmuide","state_id":"438"},
    {"city_id":"8023","city_name":"Geluveld","state_id":"438"},
    {"city_id":"8024","city_name":"Gistel","state_id":"438"},
    {"city_id":"8025","city_name":"Harelbeke","state_id":"438"},
    {"city_id":"8026","city_name":"Heuvelland","state_id":"438"},
    {"city_id":"8027","city_name":"Hooglede","state_id":"438"},
    {"city_id":"8028","city_name":"Houthulst","state_id":"438"},
    {"city_id":"8029","city_name":"Ichtegem","state_id":"438"},
    {"city_id":"8030","city_name":"Ieper","state_id":"438"},
    {"city_id":"8031","city_name":"Ingelmunster","state_id":"438"},
    {"city_id":"8032","city_name":"Ingooigem","state_id":"438"},
    {"city_id":"8033","city_name":"Izegem","state_id":"438"},
    {"city_id":"8034","city_name":"Jabbeke","state_id":"438"},
    {"city_id":"8035","city_name":"Knokke-Heist","state_id":"438"},
    {"city_id":"8036","city_name":"Koekelare","state_id":"438"},
    {"city_id":"8037","city_name":"Koksijde","state_id":"438"},
    {"city_id":"8038","city_name":"Kortemark","state_id":"438"},
    {"city_id":"8039","city_name":"Kortrijk","state_id":"438"},
    {"city_id":"8040","city_name":"Kuurne","state_id":"438"},
    {"city_id":"8041","city_name":"Langemark-Poelkapelle","state_id":"438"},
    {"city_id":"8042","city_name":"Ledegem","state_id":"438"},
    {"city_id":"8043","city_name":"Lendelede","state_id":"438"},
    {"city_id":"8044","city_name":"Lichtervelde","state_id":"438"},
    {"city_id":"8045","city_name":"Lo-Reninge","state_id":"438"},
    {"city_id":"8046","city_name":"Menen","state_id":"438"},
    {"city_id":"8047","city_name":"Mesen","state_id":"438"},
    {"city_id":"8048","city_name":"Meulebeke","state_id":"438"},
    {"city_id":"8049","city_name":"Middelkerke","state_id":"438"},
    {"city_id":"8050","city_name":"Moorslede","state_id":"438"},
    {"city_id":"8051","city_name":"Nieuwpoort","state_id":"438"},
    {"city_id":"8052","city_name":"Oostende","state_id":"438"},
    {"city_id":"8053","city_name":"Oostkamp","state_id":"438"},
    {"city_id":"8054","city_name":"Oostrozebeke","state_id":"438"},
    {"city_id":"8055","city_name":"Oudenburg","state_id":"438"},
    {"city_id":"8056","city_name":"Pittem","state_id":"438"},
    {"city_id":"8057","city_name":"Poperinge","state_id":"438"},
    {"city_id":"8058","city_name":"Roeselare","state_id":"438"},
    {"city_id":"8059","city_name":"Ruiselede","state_id":"438"},
    {"city_id":"8060","city_name":"Spiere-Helkijn","state_id":"438"},
    {"city_id":"8061","city_name":"Staden","state_id":"438"},
    {"city_id":"8062","city_name":"Tielt","state_id":"438"},
    {"city_id":"8063","city_name":"Torhout","state_id":"438"},
    {"city_id":"8064","city_name":"Veurne","state_id":"438"},
    {"city_id":"8065","city_name":"Vleteren","state_id":"438"},
    {"city_id":"8066","city_name":"Waregem","state_id":"438"},
    {"city_id":"8067","city_name":"Wervik","state_id":"438"},
    {"city_id":"8068","city_name":"Wevelgem","state_id":"438"},
    {"city_id":"8069","city_name":"Wielsbeke","state_id":"438"},
    {"city_id":"8070","city_name":"Wingene","state_id":"438"},
    {"city_id":"8071","city_name":"Zedelgem","state_id":"438"},
    {"city_id":"8072","city_name":"Zeebrugge","state_id":"438"},
    {"city_id":"8073","city_name":"Zonnebeke","state_id":"438"},
    {"city_id":"8074","city_name":"Zuienkerke","state_id":"438"},
    {"city_id":"8075","city_name":"Zwevegem","state_id":"438"},
    {"city_id":"8076","city_name":"Belize","state_id":"439"},
    {"city_id":"8077","city_name":"San Pedro","state_id":"439"},
    {"city_id":"8078","city_name":"Belmopan","state_id":"440"},
    {"city_id":"8079","city_name":"Benque Viejo","state_id":"440"},
    {"city_id":"8080","city_name":"San Ignacio","state_id":"440"},
    {"city_id":"8081","city_name":"Corozal","state_id":"441"},
    {"city_id":"8082","city_name":"Punta Gorda","state_id":"444"},
    {"city_id":"8083","city_name":"Kandi","state_id":"445"},
    {"city_id":"8084","city_name":"Malanville","state_id":"445"},
    {"city_id":"8085","city_name":"Batia","state_id":"446"},
    {"city_id":"8086","city_name":"Boukombe","state_id":"446"},
    {"city_id":"8087","city_name":"Kouande","state_id":"446"},
    {"city_id":"8088","city_name":"Natitingou","state_id":"446"},
    {"city_id":"8089","city_name":"Allada","state_id":"447"},
    {"city_id":"8090","city_name":"Cotonou","state_id":"447"},
    {"city_id":"8091","city_name":"Ouidah","state_id":"447"},
    {"city_id":"8092","city_name":"Tanguiete","state_id":"447"},
    {"city_id":"8093","city_name":"Bembereke","state_id":"448"},
    {"city_id":"8094","city_name":"Nikki","state_id":"448"},
    {"city_id":"8095","city_name":"Parakou","state_id":"448"},
    {"city_id":"8096","city_name":"Tchaourou","state_id":"448"},
    {"city_id":"8097","city_name":"Dassa","state_id":"449"},
    {"city_id":"8098","city_name":"Savalou","state_id":"449"},
    {"city_id":"8099","city_name":"Save","state_id":"449"},
    {"city_id":"8100","city_name":"Aplahoue","state_id":"450"},
    {"city_id":"8101","city_name":"Dogbo","state_id":"450"},
    {"city_id":"8102","city_name":"Djougou","state_id":"451"},
    {"city_id":"8103","city_name":"Cotonou","state_id":"452"},
    {"city_id":"8104","city_name":"Athieme","state_id":"453"},
    {"city_id":"8105","city_name":"Come","state_id":"453"},
    {"city_id":"8106","city_name":"Grand Popo","state_id":"453"},
    {"city_id":"8107","city_name":"Lokossa","state_id":"453"},
    {"city_id":"8108","city_name":"Adjohoun","state_id":"454"},
    {"city_id":"8109","city_name":"Porto Novo","state_id":"454"},
    {"city_id":"8110","city_name":"Ketou","state_id":"455"},
    {"city_id":"8111","city_name":"Pobe","state_id":"455"},
    {"city_id":"8112","city_name":"Sakete","state_id":"455"},
    {"city_id":"8113","city_name":"Abomey","state_id":"456"},
    {"city_id":"8114","city_name":"Bohicon","state_id":"456"},
    {"city_id":"8115","city_name":"Cove","state_id":"456"},
    {"city_id":"8116","city_name":"Zagnanado","state_id":"456"},
    {"city_id":"8117","city_name":"Hamilton","state_id":"457"},
    {"city_id":"8118","city_name":"Jakar","state_id":"459"},
    {"city_id":"8119","city_name":"Chhukha","state_id":"460"},
    {"city_id":"8120","city_name":"Phuentsholing","state_id":"460"},
    {"city_id":"8121","city_name":"Damphu","state_id":"461"},
    {"city_id":"8122","city_name":"Taga Dzong","state_id":"462"},
    {"city_id":"8123","city_name":"Geylegphug","state_id":"463"},
    {"city_id":"8124","city_name":"Ha","state_id":"464"},
    {"city_id":"8125","city_name":"Lhuntshi","state_id":"465"},
    {"city_id":"8126","city_name":"Mongar","state_id":"466"},
    {"city_id":"8127","city_name":"Pemagatsel","state_id":"467"},
    {"city_id":"8128","city_name":"Gasa","state_id":"468"},
    {"city_id":"8129","city_name":"Punakha","state_id":"468"},
    {"city_id":"8130","city_name":"Paro","state_id":"469"},
    {"city_id":"8131","city_name":"Phuntsholing","state_id":"470"},
    {"city_id":"8132","city_name":"Samchi","state_id":"470"},
    {"city_id":"8133","city_name":"Shemgang","state_id":"472"},
    {"city_id":"8134","city_name":"Tashigang","state_id":"473"},
    {"city_id":"8135","city_name":"Thimphu","state_id":"474"},
    {"city_id":"8136","city_name":"Timphu","state_id":"474"},
    {"city_id":"8137","city_name":"Tongsa","state_id":"475"},
    {"city_id":"8138","city_name":"Wangdiphodrang","state_id":"476"},
    {"city_id":"8139","city_name":"Guayaramerin","state_id":"477"},
    {"city_id":"8140","city_name":"Magdalena","state_id":"477"},
    {"city_id":"8141","city_name":"Reyes","state_id":"477"},
    {"city_id":"8142","city_name":"Riberalta","state_id":"477"},
    {"city_id":"8143","city_name":"Rurrenabaque","state_id":"477"},
    {"city_id":"8144","city_name":"San Borja","state_id":"477"},
    {"city_id":"8145","city_name":"San Ignacio","state_id":"477"},
    {"city_id":"8146","city_name":"San Ramon","state_id":"477"},
    {"city_id":"8147","city_name":"Santa Ana","state_id":"477"},
    {"city_id":"8148","city_name":"Santa Rosa","state_id":"477"},
    {"city_id":"8149","city_name":"Trinidad","state_id":"477"},
    {"city_id":"8150","city_name":"Camargo","state_id":"478"},
    {"city_id":"8151","city_name":"Monteagudo","state_id":"478"},
    {"city_id":"8152","city_name":"Muyupampa","state_id":"478"},
    {"city_id":"8153","city_name":"Padilla","state_id":"478"},
    {"city_id":"8154","city_name":"Sucre","state_id":"478"},
    {"city_id":"8155","city_name":"Tarabuco","state_id":"478"},
    {"city_id":"8156","city_name":"Villa Serano","state_id":"478"},
    {"city_id":"8157","city_name":"Aiquile","state_id":"479"},
    {"city_id":"8158","city_name":"Arani","state_id":"479"},
    {"city_id":"8159","city_name":"Capinota","state_id":"479"},
    {"city_id":"8160","city_name":"Chimore","state_id":"479"},
    {"city_id":"8161","city_name":"Cliza","state_id":"479"},
    {"city_id":"8162","city_name":"Cochabamba","state_id":"479"},
    {"city_id":"8163","city_name":"Colomi","state_id":"479"},
    {"city_id":"8164","city_name":"Entre Rios","state_id":"479"},
    {"city_id":"8165","city_name":"Irpa Irpa","state_id":"479"},
    {"city_id":"8166","city_name":"Ivirgarzama","state_id":"479"},
    {"city_id":"8167","city_name":"Mizque","state_id":"479"},
    {"city_id":"8168","city_name":"Punata","state_id":"479"},
    {"city_id":"8169","city_name":"Shinahota","state_id":"479"},
    {"city_id":"8170","city_name":"Sipe Sipe","state_id":"479"},
    {"city_id":"8171","city_name":"Tarata","state_id":"479"},
    {"city_id":"8172","city_name":"Ucurena","state_id":"479"},
    {"city_id":"8173","city_name":"Caracollo","state_id":"481"},
    {"city_id":"8174","city_name":"Challapata","state_id":"481"},
    {"city_id":"8175","city_name":"Eucaliptus","state_id":"481"},
    {"city_id":"8176","city_name":"Huanuni","state_id":"481"},
    {"city_id":"8177","city_name":"Machacamarca","state_id":"481"},
    {"city_id":"8178","city_name":"Oruro","state_id":"481"},
    {"city_id":"8179","city_name":"Poopo","state_id":"481"},
    {"city_id":"8180","city_name":"Santiago de Huari","state_id":"481"},
    {"city_id":"8181","city_name":"Totoral","state_id":"481"},
    {"city_id":"8182","city_name":"Cobija","state_id":"482"},
    {"city_id":"8183","city_name":"Atocha","state_id":"483"},
    {"city_id":"8184","city_name":"Betanzos","state_id":"483"},
    {"city_id":"8185","city_name":"Colquechaca","state_id":"483"},
    {"city_id":"8186","city_name":"Llallagua","state_id":"483"},
    {"city_id":"8187","city_name":"Potosi","state_id":"483"},
    {"city_id":"8188","city_name":"Santa Barbara","state_id":"483"},
    {"city_id":"8189","city_name":"Tupiza","state_id":"483"},
    {"city_id":"8190","city_name":"Uncia","state_id":"483"},
    {"city_id":"8191","city_name":"Uyuni","state_id":"483"},
    {"city_id":"8192","city_name":"Villazon","state_id":"483"},
    {"city_id":"8193","city_name":"Bermejo","state_id":"485"},
    {"city_id":"8194","city_name":"Entre Rios","state_id":"485"},
    {"city_id":"8195","city_name":"San Lorenzo","state_id":"485"},
    {"city_id":"8196","city_name":"Tarija","state_id":"485"},
    {"city_id":"8197","city_name":"Villamontes","state_id":"485"},
    {"city_id":"8198","city_name":"Yacuiba","state_id":"485"},
    {"city_id":"8199","city_name":"Kachikau","state_id":"493"},
    {"city_id":"8200","city_name":"Kasane","state_id":"493"},
    {"city_id":"8201","city_name":"Kavimba","state_id":"493"},
    {"city_id":"8202","city_name":"Kazungula","state_id":"493"},
    {"city_id":"8203","city_name":"Lesoma","state_id":"493"},
    {"city_id":"8204","city_name":"Muchinje-Mabale","state_id":"493"},
    {"city_id":"8205","city_name":"Pandamatenga","state_id":"493"},
    {"city_id":"8206","city_name":"Pandamatenga Botswana Defence ","state_id":"493"},
    {"city_id":"8207","city_name":"Parakarungu","state_id":"493"},
    {"city_id":"8208","city_name":"Satau","state_id":"493"},
    {"city_id":"8209","city_name":"Francistown","state_id":"494"},
    {"city_id":"8210","city_name":"Gaborone","state_id":"495"},
    {"city_id":"8211","city_name":"Bere","state_id":"496"},
    {"city_id":"8212","city_name":"Charles Hill","state_id":"496"},
    {"city_id":"8213","city_name":"Chobokwane","state_id":"496"},
    {"city_id":"8214","city_name":"Dekar","state_id":"496"},
    {"city_id":"8215","city_name":"East Hanahai","state_id":"496"},
    {"city_id":"8216","city_name":"Ghanzi","state_id":"496"},
    {"city_id":"8217","city_name":"Groote Laagte","state_id":"496"},
    {"city_id":"8218","city_name":"Kacgae","state_id":"496"},
    {"city_id":"8219","city_name":"Karakobis","state_id":"496"},
    {"city_id":"8220","city_name":"Kuke Quarantine Camp","state_id":"496"},
    {"city_id":"8221","city_name":"Kule","state_id":"496"},
    {"city_id":"8222","city_name":"Makunda","state_id":"496"},
    {"city_id":"8223","city_name":"Ncojane","state_id":"496"},
    {"city_id":"8224","city_name":"New Xade","state_id":"496"},
    {"city_id":"8225","city_name":"New Xanagas","state_id":"496"},
    {"city_id":"8226","city_name":"Qabo","state_id":"496"},
    {"city_id":"8227","city_name":"Tsootsha","state_id":"496"},
    {"city_id":"8228","city_name":"West Hanahai","state_id":"496"},
    {"city_id":"8229","city_name":"Jwaneng","state_id":"497"},
    {"city_id":"8230","city_name":"Artisia","state_id":"500"},
    {"city_id":"8231","city_name":"Bokaa","state_id":"500"},
    {"city_id":"8232","city_name":"Dikgonye","state_id":"500"},
    {"city_id":"8233","city_name":"Dikwididi","state_id":"500"},
    {"city_id":"8234","city_name":"Kgomodiatshaba","state_id":"500"},
    {"city_id":"8235","city_name":"Khurutshe","state_id":"500"},
    {"city_id":"8236","city_name":"Leshibitse","state_id":"500"},
    {"city_id":"8237","city_name":"Mabalane","state_id":"500"},
    {"city_id":"8238","city_name":"Malolwane","state_id":"500"},
    {"city_id":"8239","city_name":"Malotwana Siding","state_id":"500"},
    {"city_id":"8240","city_name":"Matebeleng","state_id":"500"},
    {"city_id":"8241","city_name":"Mmamashia","state_id":"500"},
    {"city_id":"8242","city_name":"Mmathubudukwane","state_id":"500"},
    {"city_id":"8243","city_name":"Mochudi","state_id":"500"},
    {"city_id":"8244","city_name":"Modipane","state_id":"500"},
    {"city_id":"8245","city_name":"Morwa","state_id":"500"},
    {"city_id":"8246","city_name":"Oliphants Drift","state_id":"500"},
    {"city_id":"8247","city_name":"Oodi","state_id":"500"},
    {"city_id":"8248","city_name":"Pilane","state_id":"500"},
    {"city_id":"8249","city_name":"Ramonaka","state_id":"500"},
    {"city_id":"8250","city_name":"Ramotlabaki","state_id":"500"},
    {"city_id":"8251","city_name":"Rasesa","state_id":"500"},
    {"city_id":"8252","city_name":"Sikwane","state_id":"500"},
    {"city_id":"8253","city_name":"Boatlaname","state_id":"501"},
    {"city_id":"8254","city_name":"Botlhapatlou","state_id":"501"},
    {"city_id":"8255","city_name":"Diagane","state_id":"501"},
    {"city_id":"8256","city_name":"Diphudugodu","state_id":"501"},
    {"city_id":"8257","city_name":"Diremogolo Lands","state_id":"501"},
    {"city_id":"8258","city_name":"Ditshegwane","state_id":"501"},
    {"city_id":"8259","city_name":"Ditshukudu","state_id":"501"},
    {"city_id":"8260","city_name":"Dumadumane","state_id":"501"},
    {"city_id":"8261","city_name":"Dutlwe","state_id":"501"},
    {"city_id":"8262","city_name":"Gabane","state_id":"501"},
    {"city_id":"8263","city_name":"Gakgatla","state_id":"501"},
    {"city_id":"8264","city_name":"Gakuto","state_id":"501"},
    {"city_id":"8265","city_name":"Galekgatshwane","state_id":"501"},
    {"city_id":"8266","city_name":"Gamodubu","state_id":"501"},
    {"city_id":"8267","city_name":"Gaphatshwa","state_id":"501"},
    {"city_id":"8268","city_name":"Hatsalatladi","state_id":"501"},
    {"city_id":"8269","city_name":"Kamenakwe","state_id":"501"},
    {"city_id":"8270","city_name":"Kaudwane","state_id":"501"},
    {"city_id":"8271","city_name":"Kgaphamadi","state_id":"501"},
    {"city_id":"8272","city_name":"Kgope","state_id":"501"},
    {"city_id":"8273","city_name":"Khekhenya-Chepetese","state_id":"501"},
    {"city_id":"8274","city_name":"Khudumelapye","state_id":"501"},
    {"city_id":"8275","city_name":"Kopong","state_id":"501"},
    {"city_id":"8276","city_name":"Kotolaname","state_id":"501"},
    {"city_id":"8277","city_name":"Kubung","state_id":"501"},
    {"city_id":"8278","city_name":"Kumakwane","state_id":"501"},
    {"city_id":"8279","city_name":"Kweneng","state_id":"501"},
    {"city_id":"8280","city_name":"Lentsweletau","state_id":"501"},
    {"city_id":"8281","city_name":"Lephepe","state_id":"501"},
    {"city_id":"8282","city_name":"Lesirane","state_id":"501"},
    {"city_id":"8283","city_name":"Letlhakeng","state_id":"501"},
    {"city_id":"8284","city_name":"Losilakgokong","state_id":"501"},
    {"city_id":"8285","city_name":"Maboane","state_id":"501"},
    {"city_id":"8286","city_name":"Mahetlwe","state_id":"501"},
    {"city_id":"8287","city_name":"Makabanyane-Dikgokong","state_id":"501"},
    {"city_id":"8288","city_name":"Malwelwe","state_id":"501"},
    {"city_id":"8289","city_name":"Mamhiko","state_id":"501"},
    {"city_id":"8290","city_name":"Manaledi","state_id":"501"},
    {"city_id":"8291","city_name":"Mantshwabisi","state_id":"501"},
    {"city_id":"8292","city_name":"Marejwane","state_id":"501"},
    {"city_id":"8293","city_name":"Masebele","state_id":"501"},
    {"city_id":"8294","city_name":"Medie","state_id":"501"},
    {"city_id":"8295","city_name":"Metsibotlhoko","state_id":"501"},
    {"city_id":"8296","city_name":"Metsimotlhaba","state_id":"501"},
    {"city_id":"8297","city_name":"Mmakanke","state_id":"501"},
    {"city_id":"8298","city_name":"Mmankgodi","state_id":"501"},
    {"city_id":"8299","city_name":"Mmanoko","state_id":"501"},
    {"city_id":"8300","city_name":"Mmokolodi","state_id":"501"},
    {"city_id":"8301","city_name":"Mmopane","state_id":"501"},
    {"city_id":"8302","city_name":"Mmopane Lands","state_id":"501"},
    {"city_id":"8303","city_name":"Mogoditshane","state_id":"501"},
    {"city_id":"8304","city_name":"Mogoditshane Botswana Defence ","state_id":"501"},
    {"city_id":"8305","city_name":"Mogoditshane Lands","state_id":"501"},
    {"city_id":"8306","city_name":"Mogonono","state_id":"501"},
    {"city_id":"8307","city_name":"Molepolole","state_id":"501"},
    {"city_id":"8308","city_name":"Mononyane","state_id":"501"},
    {"city_id":"8309","city_name":"Monwane","state_id":"501"},
    {"city_id":"8310","city_name":"Morabane","state_id":"501"},
    {"city_id":"8311","city_name":"Morope","state_id":"501"},
    {"city_id":"8312","city_name":"Moshaweng","state_id":"501"},
    {"city_id":"8313","city_name":"Mosokotswe","state_id":"501"},
    {"city_id":"8314","city_name":"Motokwe","state_id":"501"},
    {"city_id":"8315","city_name":"Ngware","state_id":"501"},
    {"city_id":"8316","city_name":"Nkoyaphiri","state_id":"501"},
    {"city_id":"8317","city_name":"Ramaphatlhe","state_id":"501"},
    {"city_id":"8318","city_name":"Salajwe","state_id":"501"},
    {"city_id":"8319","city_name":"Serinane","state_id":"501"},
    {"city_id":"8320","city_name":"Sesung","state_id":"501"},
    {"city_id":"8321","city_name":"Shadishadi","state_id":"501"},
    {"city_id":"8322","city_name":"Sojwe","state_id":"501"},
    {"city_id":"8323","city_name":"Sorilatholo","state_id":"501"},
    {"city_id":"8324","city_name":"Suping","state_id":"501"},
    {"city_id":"8325","city_name":"Takatokwane","state_id":"501"},
    {"city_id":"8326","city_name":"Thamaga","state_id":"501"},
    {"city_id":"8327","city_name":"Thebephatshwa","state_id":"501"},
    {"city_id":"8328","city_name":"Tlowaneng","state_id":"501"},
    {"city_id":"8329","city_name":"Tsetseng","state_id":"501"},
    {"city_id":"8330","city_name":"Tswaane","state_id":"501"},
    {"city_id":"8331","city_name":"Lobatse","state_id":"502"},
    {"city_id":"8332","city_name":"Bodibeng","state_id":"503"},
    {"city_id":"8333","city_name":"Boro","state_id":"503"},
    {"city_id":"8334","city_name":"Botlhatlogo","state_id":"503"},
    {"city_id":"8335","city_name":"Chanoga","state_id":"503"},
    {"city_id":"8336","city_name":"Chuchubega","state_id":"503"},
    {"city_id":"8337","city_name":"Daonara","state_id":"503"},
    {"city_id":"8338","city_name":"Ditshiping","state_id":"503"},
    {"city_id":"8339","city_name":"Habu","state_id":"503"},
    {"city_id":"8340","city_name":"Jao","state_id":"503"},
    {"city_id":"8341","city_name":"Kareng","state_id":"503"},
    {"city_id":"8342","city_name":"Katamaga","state_id":"503"},
    {"city_id":"8343","city_name":"Kgakge","state_id":"503"},
    {"city_id":"8344","city_name":"Khwai Camp","state_id":"503"},
    {"city_id":"8345","city_name":"Komana","state_id":"503"},
    {"city_id":"8346","city_name":"Legotlhwana","state_id":"503"},
    {"city_id":"8347","city_name":"Mababe","state_id":"503"},
    {"city_id":"8348","city_name":"Makalamabedi","state_id":"503"},
    {"city_id":"8349","city_name":"Matlapana","state_id":"503"},
    {"city_id":"8350","city_name":"Matsaudi","state_id":"503"},
    {"city_id":"8351","city_name":"Mawana","state_id":"503"},
    {"city_id":"8352","city_name":"Mokgalo-Haka","state_id":"503"},
    {"city_id":"8353","city_name":"Morutsha","state_id":"503"},
    {"city_id":"8354","city_name":"Nxharaga","state_id":"503"},
    {"city_id":"8355","city_name":"Phuduhudu","state_id":"503"},
    {"city_id":"8356","city_name":"Samodupi","state_id":"503"},
    {"city_id":"8357","city_name":"Sankuyo","state_id":"503"},
    {"city_id":"8358","city_name":"Sehithwa","state_id":"503"},
    {"city_id":"8359","city_name":"Semboyo","state_id":"503"},
    {"city_id":"8360","city_name":"Sexaxa","state_id":"503"},
    {"city_id":"8361","city_name":"Shakawe","state_id":"503"},
    {"city_id":"8362","city_name":"Shorobe","state_id":"503"},
    {"city_id":"8363","city_name":"Somela","state_id":"503"},
    {"city_id":"8364","city_name":"Toteng","state_id":"503"},
    {"city_id":"8365","city_name":"Tsanekona","state_id":"503"},
    {"city_id":"8366","city_name":"Tsao","state_id":"503"},
    {"city_id":"8367","city_name":"Xaxaba","state_id":"503"},
    {"city_id":"8368","city_name":"Xhobe","state_id":"503"},
    {"city_id":"8369","city_name":"Bethel","state_id":"504"},
    {"city_id":"8370","city_name":"Borobadilepe","state_id":"504"},
    {"city_id":"8371","city_name":"Diabo","state_id":"504"},
    {"city_id":"8372","city_name":"Digawana","state_id":"504"},
    {"city_id":"8373","city_name":"Dikhukhung","state_id":"504"},
    {"city_id":"8374","city_name":"Dinatshana","state_id":"504"},
    {"city_id":"8375","city_name":"Dipotsana","state_id":"504"},
    {"city_id":"8376","city_name":"Ditlharapa","state_id":"504"},
    {"city_id":"8377","city_name":"Gamajalela","state_id":"504"},
    {"city_id":"8378","city_name":"Gasita","state_id":"504"},
    {"city_id":"8379","city_name":"Gathwane","state_id":"504"},
    {"city_id":"8380","city_name":"Good Hope","state_id":"504"},
    {"city_id":"8381","city_name":"Goora-seno","state_id":"504"},
    {"city_id":"8382","city_name":"Gopong","state_id":"504"},
    {"city_id":"8383","city_name":"Hebron","state_id":"504"},
    {"city_id":"8384","city_name":"Itholoke","state_id":"504"},
    {"city_id":"8385","city_name":"Kanaku","state_id":"504"},
    {"city_id":"8386","city_name":"Kangwe","state_id":"504"},
    {"city_id":"8387","city_name":"Kanye","state_id":"504"},
    {"city_id":"8388","city_name":"Keng","state_id":"504"},
    {"city_id":"8389","city_name":"Kgomokasitwa","state_id":"504"},
    {"city_id":"8390","city_name":"Kgoro","state_id":"504"},
    {"city_id":"8391","city_name":"Khakhea","state_id":"504"},
    {"city_id":"8392","city_name":"Khonkhwa","state_id":"504"},
    {"city_id":"8393","city_name":"Kokong","state_id":"504"},
    {"city_id":"8394","city_name":"Lehoko","state_id":"504"},
    {"city_id":"8395","city_name":"Lejwana","state_id":"504"},
    {"city_id":"8396","city_name":"Lekgolobotlo","state_id":"504"},
    {"city_id":"8397","city_name":"Leporung","state_id":"504"},
    {"city_id":"8398","city_name":"Logagane","state_id":"504"},
    {"city_id":"8399","city_name":"Lorolwana","state_id":"504"},
    {"city_id":"8400","city_name":"Lorwana","state_id":"504"},
    {"city_id":"8401","city_name":"Lotlhakane","state_id":"504"},
    {"city_id":"8402","city_name":"Lotlhakane West","state_id":"504"},
    {"city_id":"8403","city_name":"Mabule","state_id":"504"},
    {"city_id":"8404","city_name":"Mabutsane","state_id":"504"},
    {"city_id":"8405","city_name":"Madingwana","state_id":"504"},
    {"city_id":"8406","city_name":"Magoriapitse","state_id":"504"},
    {"city_id":"8407","city_name":"Magotlhawane","state_id":"504"},
    {"city_id":"8408","city_name":"Mahotshwane","state_id":"504"},
    {"city_id":"8409","city_name":"Maisane","state_id":"504"},
    {"city_id":"8410","city_name":"Makokwe","state_id":"504"},
    {"city_id":"8411","city_name":"Malokaganyane","state_id":"504"},
    {"city_id":"8412","city_name":"Manyana","state_id":"504"},
    {"city_id":"8413","city_name":"Maokane","state_id":"504"},
    {"city_id":"8414","city_name":"Marojane","state_id":"504"},
    {"city_id":"8415","city_name":"Maruswa","state_id":"504"},
    {"city_id":"8416","city_name":"Metlobo","state_id":"504"},
    {"city_id":"8417","city_name":"Metlojane","state_id":"504"},
    {"city_id":"8418","city_name":"Mmakgori","state_id":"504"},
    {"city_id":"8419","city_name":"Mmathethe","state_id":"504"},
    {"city_id":"8420","city_name":"Mogojogojo","state_id":"504"},
    {"city_id":"8421","city_name":"Mogonye","state_id":"504"},
    {"city_id":"8422","city_name":"Mogwalale","state_id":"504"},
    {"city_id":"8423","city_name":"Mokatako","state_id":"504"},
    {"city_id":"8424","city_name":"Mokgomane","state_id":"504"},
    {"city_id":"8425","city_name":"Mokhomba","state_id":"504"},
    {"city_id":"8426","city_name":"Molapowabojang","state_id":"504"},
    {"city_id":"8427","city_name":"Molete","state_id":"504"},
    {"city_id":"8428","city_name":"Morwamosu","state_id":"504"},
    {"city_id":"8429","city_name":"Moshaneng","state_id":"504"},
    {"city_id":"8430","city_name":"Moshupa","state_id":"504"},
    {"city_id":"8431","city_name":"Motlhwatse","state_id":"504"},
    {"city_id":"8432","city_name":"Motsentshe","state_id":"504"},
    {"city_id":"8433","city_name":"Musi","state_id":"504"},
    {"city_id":"8434","city_name":"Ngwatsau","state_id":"504"},
    {"city_id":"8435","city_name":"Ntlhantlhe","state_id":"504"},
    {"city_id":"8436","city_name":"Papatlo","state_id":"504"},
    {"city_id":"8437","city_name":"Phihitshwane","state_id":"504"},
    {"city_id":"8438","city_name":"Pitsana-Potokwe","state_id":"504"},
    {"city_id":"8439","city_name":"Pitsane","state_id":"504"},
    {"city_id":"8440","city_name":"Pitseng-Ralekgetho","state_id":"504"},
    {"city_id":"8441","city_name":"Pitshane Molopo","state_id":"504"},
    {"city_id":"8442","city_name":"Rakhuna","state_id":"504"},
    {"city_id":"8443","city_name":"Ralekgetho","state_id":"504"},
    {"city_id":"8444","city_name":"Ramatlabama","state_id":"504"},
    {"city_id":"8445","city_name":"Ranaka","state_id":"504"},
    {"city_id":"8446","city_name":"Sedibeng","state_id":"504"},
    {"city_id":"8447","city_name":"Segakwana","state_id":"504"},
    {"city_id":"8448","city_name":"Segwagwa","state_id":"504"},
    {"city_id":"8449","city_name":"Seherelela","state_id":"504"},
    {"city_id":"8450","city_name":"Sekhutlane","state_id":"504"},
    {"city_id":"8451","city_name":"Sekoma","state_id":"504"},
    {"city_id":"8452","city_name":"Selokolela","state_id":"504"},
    {"city_id":"8453","city_name":"Semane","state_id":"504"},
    {"city_id":"8454","city_name":"Sese","state_id":"504"},
    {"city_id":"8455","city_name":"Sesung","state_id":"504"},
    {"city_id":"8456","city_name":"Sheep Farm","state_id":"504"},
    {"city_id":"8457","city_name":"Tlhankane","state_id":"504"},
    {"city_id":"8458","city_name":"Tlhareseleele","state_id":"504"},
    {"city_id":"8459","city_name":"Tshidilamolomo","state_id":"504"},
    {"city_id":"8460","city_name":"Tshwaane","state_id":"504"},
    {"city_id":"8461","city_name":"Tsonyane","state_id":"504"},
    {"city_id":"8462","city_name":"Tswaaneng","state_id":"504"},
    {"city_id":"8463","city_name":"Tswagare-Lothoje-Lokalana","state_id":"504"},
    {"city_id":"8464","city_name":"Tswanyaneng","state_id":"504"},
    {"city_id":"8465","city_name":"Beetsha","state_id":"506"},
    {"city_id":"8466","city_name":"Eretsha","state_id":"506"},
    {"city_id":"8467","city_name":"Etsha 1","state_id":"506"},
    {"city_id":"8468","city_name":"Etsha 13","state_id":"506"},
    {"city_id":"8469","city_name":"Etsha 6","state_id":"506"},
    {"city_id":"8470","city_name":"Etsha 8","state_id":"506"},
    {"city_id":"8471","city_name":"Etsha 9","state_id":"506"},
    {"city_id":"8472","city_name":"Gane","state_id":"506"},
    {"city_id":"8473","city_name":"Gonutsuga","state_id":"506"},
    {"city_id":"8474","city_name":"Gowe","state_id":"506"},
    {"city_id":"8475","city_name":"Gudingwa","state_id":"506"},
    {"city_id":"8476","city_name":"Gumare","state_id":"506"},
    {"city_id":"8477","city_name":"Ikoga","state_id":"506"},
    {"city_id":"8478","city_name":"Kajaja","state_id":"506"},
    {"city_id":"8479","city_name":"Kapotora Lands","state_id":"506"},
    {"city_id":"8480","city_name":"Kauxwhi","state_id":"506"},
    {"city_id":"8481","city_name":"Matswee","state_id":"506"},
    {"city_id":"8482","city_name":"Maun","state_id":"506"},
    {"city_id":"8483","city_name":"Moaha","state_id":"506"},
    {"city_id":"8484","city_name":"Mohembo East","state_id":"506"},
    {"city_id":"8485","city_name":"Mohembo West","state_id":"506"},
    {"city_id":"8486","city_name":"Mokgacha","state_id":"506"},
    {"city_id":"8487","city_name":"Ngarange","state_id":"506"},
    {"city_id":"8488","city_name":"Nokaneng","state_id":"506"},
    {"city_id":"8489","city_name":"Nxamasere","state_id":"506"},
    {"city_id":"8490","city_name":"Nxaunxau","state_id":"506"},
    {"city_id":"8491","city_name":"Nxwee","state_id":"506"},
    {"city_id":"8492","city_name":"Qangwa","state_id":"506"},
    {"city_id":"8493","city_name":"Roye","state_id":"506"},
    {"city_id":"8494","city_name":"Samochema","state_id":"506"},
    {"city_id":"8495","city_name":"Sekondomboro","state_id":"506"},
    {"city_id":"8496","city_name":"Sepopa","state_id":"506"},
    {"city_id":"8497","city_name":"Seronga","state_id":"506"},
    {"city_id":"8498","city_name":"Shaowe","state_id":"506"},
    {"city_id":"8499","city_name":"Tobere Lands","state_id":"506"},
    {"city_id":"8500","city_name":"Tubu","state_id":"506"},
    {"city_id":"8501","city_name":"Tubu Lands","state_id":"506"},
    {"city_id":"8502","city_name":"Xadau","state_id":"506"},
    {"city_id":"8503","city_name":"Xakao","state_id":"506"},
    {"city_id":"8504","city_name":"Xaxa","state_id":"506"},
    {"city_id":"8505","city_name":"Xhauga","state_id":"506"},
    {"city_id":"8506","city_name":"Xurube","state_id":"506"},
    {"city_id":"8507","city_name":"Orapa","state_id":"507"},
    {"city_id":"8508","city_name":"Sowa","state_id":"510"},
    {"city_id":"8509","city_name":"Acrelandia","state_id":"512"},
    {"city_id":"8510","city_name":"Brasileia","state_id":"512"},
    {"city_id":"8511","city_name":"Cruzeiro do Sul","state_id":"512"},
    {"city_id":"8512","city_name":"Epitaciolandia","state_id":"512"},
    {"city_id":"8513","city_name":"Feijo","state_id":"512"},
    {"city_id":"8514","city_name":"Mancio Lima","state_id":"512"},
    {"city_id":"8515","city_name":"Manoel Urbano","state_id":"512"},
    {"city_id":"8516","city_name":"Marechal Thaumaturgo","state_id":"512"},
    {"city_id":"8517","city_name":"Placido de Castro","state_id":"512"},
    {"city_id":"8518","city_name":"Porto Walter","state_id":"512"},
    {"city_id":"8519","city_name":"Rio Branco","state_id":"512"},
    {"city_id":"8520","city_name":"Rodrigues Alves","state_id":"512"},
    {"city_id":"8521","city_name":"Sena Madureira","state_id":"512"},
    {"city_id":"8522","city_name":"Senador Guiomard","state_id":"512"},
    {"city_id":"8523","city_name":"Tarauaca","state_id":"512"},
    {"city_id":"8524","city_name":"Xapuri","state_id":"512"},
    {"city_id":"8525","city_name":"Agua Branca","state_id":"513"},
    {"city_id":"8526","city_name":"Anadia","state_id":"513"},
    {"city_id":"8527","city_name":"Arapiraca","state_id":"513"},
    {"city_id":"8528","city_name":"Atalaia","state_id":"513"},
    {"city_id":"8529","city_name":"Barra de Santo Antonio","state_id":"513"},
    {"city_id":"8530","city_name":"Batalha","state_id":"513"},
    {"city_id":"8531","city_name":"Boca da Mata","state_id":"513"},
    {"city_id":"8532","city_name":"Cacimbinhas","state_id":"513"},
    {"city_id":"8533","city_name":"Cajueiro","state_id":"513"},
    {"city_id":"8534","city_name":"Campo Alegre","state_id":"513"},
    {"city_id":"8535","city_name":"Campo Grande","state_id":"513"},
    {"city_id":"8536","city_name":"Canapi","state_id":"513"},
    {"city_id":"8537","city_name":"Capela","state_id":"513"},
    {"city_id":"8538","city_name":"Coite do Noia","state_id":"513"},
    {"city_id":"8539","city_name":"Colonia Leopoldina","state_id":"513"},
    {"city_id":"8540","city_name":"Coruripe","state_id":"513"},
    {"city_id":"8541","city_name":"Craibas","state_id":"513"},
    {"city_id":"8542","city_name":"Delmiro Gouveia","state_id":"513"},
    {"city_id":"8543","city_name":"Dois Riachos","state_id":"513"},
    {"city_id":"8544","city_name":"Estrela de Alagoas","state_id":"513"},
    {"city_id":"8545","city_name":"Feira Grande","state_id":"513"},
    {"city_id":"8546","city_name":"Flexeiras","state_id":"513"},
    {"city_id":"8547","city_name":"Girau do Ponciano","state_id":"513"},
    {"city_id":"8548","city_name":"Ibateguara","state_id":"513"},
    {"city_id":"8549","city_name":"Igaci","state_id":"513"},
    {"city_id":"8550","city_name":"Igreja Nova","state_id":"513"},
    {"city_id":"8551","city_name":"Inhapi","state_id":"513"},
    {"city_id":"8552","city_name":"Joaquim Gomes","state_id":"513"},
    {"city_id":"8553","city_name":"Jundia","state_id":"513"},
    {"city_id":"8554","city_name":"Junqueiro","state_id":"513"},
    {"city_id":"8555","city_name":"Lagoa da Canoa","state_id":"513"},
    {"city_id":"8556","city_name":"Limoeiro de Anadia","state_id":"513"},
    {"city_id":"8557","city_name":"Maceio","state_id":"513"},
    {"city_id":"8558","city_name":"Major Isidoro","state_id":"513"},
    {"city_id":"8559","city_name":"Maragogi","state_id":"513"},
    {"city_id":"8560","city_name":"Maravilha","state_id":"513"},
    {"city_id":"8561","city_name":"Marechal Deodoro","state_id":"513"},
    {"city_id":"8562","city_name":"Maribondo","state_id":"513"},
    {"city_id":"8563","city_name":"Mata Grande","state_id":"513"},
    {"city_id":"8564","city_name":"Matriz de Camaragibe","state_id":"513"},
    {"city_id":"8565","city_name":"Messias","state_id":"513"},
    {"city_id":"8566","city_name":"Minador do Negrao","state_id":"513"},
    {"city_id":"8567","city_name":"Murici","state_id":"513"},
    {"city_id":"8568","city_name":"Novo Lino","state_id":"513"},
    {"city_id":"8569","city_name":"Olho d'Agua das Flores","state_id":"513"},
    {"city_id":"8570","city_name":"Olivenca","state_id":"513"},
    {"city_id":"8571","city_name":"Palmeira dos Indios","state_id":"513"},
    {"city_id":"8572","city_name":"Pao de Acucar","state_id":"513"},
    {"city_id":"8573","city_name":"Passo de Camaragibe","state_id":"513"},
    {"city_id":"8574","city_name":"Penedo","state_id":"513"},
    {"city_id":"8575","city_name":"Piacabucu","state_id":"513"},
    {"city_id":"8576","city_name":"Pilar","state_id":"513"},
    {"city_id":"8577","city_name":"Piranhas","state_id":"513"},
    {"city_id":"8578","city_name":"Poco das Trincheiras","state_id":"513"},
    {"city_id":"8579","city_name":"Porto Calvo","state_id":"513"},
    {"city_id":"8580","city_name":"Porto Real do Colegio","state_id":"513"},
    {"city_id":"8581","city_name":"Quebrangulo","state_id":"513"},
    {"city_id":"8582","city_name":"Rio Largo","state_id":"513"},
    {"city_id":"8583","city_name":"Santana do Ipanema","state_id":"513"},
    {"city_id":"8584","city_name":"Santana do Mundau","state_id":"513"},
    {"city_id":"8585","city_name":"Sao Jose da Laje","state_id":"513"},
    {"city_id":"8586","city_name":"Sao Jose da Tapera","state_id":"513"},
    {"city_id":"8587","city_name":"Sao Luis do Quitunde","state_id":"513"},
    {"city_id":"8588","city_name":"Sao Miguel dos Campos","state_id":"513"},
    {"city_id":"8589","city_name":"Sao Sebastiao","state_id":"513"},
    {"city_id":"8590","city_name":"Satuba","state_id":"513"},
    {"city_id":"8591","city_name":"Senador Rui Palmeira","state_id":"513"},
    {"city_id":"8592","city_name":"Taquarana","state_id":"513"},
    {"city_id":"8593","city_name":"Teotonio Vilela","state_id":"513"},
    {"city_id":"8594","city_name":"Traipu","state_id":"513"},
    {"city_id":"8595","city_name":"Uniao dos Palmares","state_id":"513"},
    {"city_id":"8596","city_name":"Vicosa","state_id":"513"},
    {"city_id":"8597","city_name":"Amapa","state_id":"514"},
    {"city_id":"8598","city_name":"Laranjal do Jari","state_id":"514"},
    {"city_id":"8599","city_name":"Macapa","state_id":"514"},
    {"city_id":"8600","city_name":"Mazagao","state_id":"514"},
    {"city_id":"8601","city_name":"Oiapoque","state_id":"514"},
    {"city_id":"8602","city_name":"Santana","state_id":"514"},
    {"city_id":"8603","city_name":"Alvaraes","state_id":"515"},
    {"city_id":"8604","city_name":"Anori","state_id":"515"},
    {"city_id":"8605","city_name":"Apui","state_id":"515"},
    {"city_id":"8606","city_name":"Autazes","state_id":"515"},
    {"city_id":"8607","city_name":"Barcelos","state_id":"515"},
    {"city_id":"8608","city_name":"Barreirinha","state_id":"515"},
    {"city_id":"8609","city_name":"Benjamin Constant","state_id":"515"},
    {"city_id":"8610","city_name":"Boca do Acre","state_id":"515"},
    {"city_id":"8611","city_name":"Borba","state_id":"515"},
    {"city_id":"8612","city_name":"Canutama","state_id":"515"},
    {"city_id":"8613","city_name":"Carauari","state_id":"515"},
    {"city_id":"8614","city_name":"Careiro","state_id":"515"},
    {"city_id":"8615","city_name":"Careiro da Varzea","state_id":"515"},
    {"city_id":"8616","city_name":"Coari","state_id":"515"},
    {"city_id":"8617","city_name":"Codajas","state_id":"515"},
    {"city_id":"8618","city_name":"Eirunepe","state_id":"515"},
    {"city_id":"8619","city_name":"Envira","state_id":"515"},
    {"city_id":"8620","city_name":"Fonte Boa","state_id":"515"},
    {"city_id":"8621","city_name":"Guajara","state_id":"515"},
    {"city_id":"8622","city_name":"Humaita","state_id":"515"},
    {"city_id":"8623","city_name":"Ipixuna","state_id":"515"},
    {"city_id":"8624","city_name":"Iranduba","state_id":"515"},
    {"city_id":"8625","city_name":"Itacoatiara","state_id":"515"},
    {"city_id":"8626","city_name":"Japura","state_id":"515"},
    {"city_id":"8627","city_name":"Jutai","state_id":"515"},
    {"city_id":"8628","city_name":"Labrea","state_id":"515"},
    {"city_id":"8629","city_name":"Manacapuru","state_id":"515"},
    {"city_id":"8630","city_name":"Manaquiri","state_id":"515"},
    {"city_id":"8631","city_name":"Manaus","state_id":"515"},
    {"city_id":"8632","city_name":"Manicore","state_id":"515"},
    {"city_id":"8633","city_name":"Maraa","state_id":"515"},
    {"city_id":"8634","city_name":"Maues","state_id":"515"},
    {"city_id":"8635","city_name":"Nhamunda","state_id":"515"},
    {"city_id":"8636","city_name":"Nova Olinda do Norte","state_id":"515"},
    {"city_id":"8637","city_name":"Novo Airao","state_id":"515"},
    {"city_id":"8638","city_name":"Novo Aripuana","state_id":"515"},
    {"city_id":"8639","city_name":"Parintins","state_id":"515"},
    {"city_id":"8640","city_name":"Pauini","state_id":"515"},
    {"city_id":"8641","city_name":"Rio Preto da Eva","state_id":"515"},
    {"city_id":"8642","city_name":"Santa Isabel do Rio Negro","state_id":"515"},
    {"city_id":"8643","city_name":"Santo Antonio do Ica","state_id":"515"},
    {"city_id":"8644","city_name":"Sao Gabriel da Cachoeira","state_id":"515"},
    {"city_id":"8645","city_name":"Sao Paulo de Olivenca","state_id":"515"},
    {"city_id":"8646","city_name":"Tabatinga","state_id":"515"},
    {"city_id":"8647","city_name":"Tapaua","state_id":"515"},
    {"city_id":"8648","city_name":"Tefe","state_id":"515"},
    {"city_id":"8649","city_name":"Tonantins","state_id":"515"},
    {"city_id":"8650","city_name":"Uarini","state_id":"515"},
    {"city_id":"8651","city_name":"Urucara","state_id":"515"},
    {"city_id":"8652","city_name":"Urucurituba","state_id":"515"},
    {"city_id":"8653","city_name":"Acajutiba","state_id":"516"},
    {"city_id":"8654","city_name":"Alagoinhas","state_id":"516"},
    {"city_id":"8655","city_name":"Amargosa","state_id":"516"},
    {"city_id":"8656","city_name":"Amelia Rodrigues","state_id":"516"},
    {"city_id":"8657","city_name":"America Dourada","state_id":"516"},
    {"city_id":"8658","city_name":"Anage","state_id":"516"},
    {"city_id":"8659","city_name":"Araci","state_id":"516"},
    {"city_id":"8660","city_name":"Aurelino Leal","state_id":"516"},
    {"city_id":"8661","city_name":"Baixa Grande","state_id":"516"},
    {"city_id":"8662","city_name":"Barra","state_id":"516"},
    {"city_id":"8663","city_name":"Barra da Estiva","state_id":"516"},
    {"city_id":"8664","city_name":"Barra do Choca","state_id":"516"},
    {"city_id":"8665","city_name":"Barreiras","state_id":"516"},
    {"city_id":"8666","city_name":"Belmonte","state_id":"516"},
    {"city_id":"8667","city_name":"Boa Vista do Tupim","state_id":"516"},
    {"city_id":"8668","city_name":"Bom Jesus da Lapa","state_id":"516"},
    {"city_id":"8669","city_name":"Boquira","state_id":"516"},
    {"city_id":"8670","city_name":"Brumado","state_id":"516"},
    {"city_id":"8671","city_name":"Buerarema","state_id":"516"},
    {"city_id":"8672","city_name":"Cachoeira","state_id":"516"},
    {"city_id":"8673","city_name":"Cacule","state_id":"516"},
    {"city_id":"8674","city_name":"Caetite","state_id":"516"},
    {"city_id":"8675","city_name":"Cafarnaum","state_id":"516"},
    {"city_id":"8676","city_name":"Camacan","state_id":"516"},
    {"city_id":"8677","city_name":"Camacari","state_id":"516"},
    {"city_id":"8678","city_name":"Camamu","state_id":"516"},
    {"city_id":"8679","city_name":"Campo Alegre de Lourdes","state_id":"516"},
    {"city_id":"8680","city_name":"Campo Formoso","state_id":"516"},
    {"city_id":"8681","city_name":"Canarana","state_id":"516"},
    {"city_id":"8682","city_name":"Canavieiras","state_id":"516"},
    {"city_id":"8683","city_name":"Candeias","state_id":"516"},
    {"city_id":"8684","city_name":"Candido Sales","state_id":"516"},
    {"city_id":"8685","city_name":"Cansancao","state_id":"516"},
    {"city_id":"8686","city_name":"Capim Grosso","state_id":"516"},
    {"city_id":"8687","city_name":"Caravelas","state_id":"516"},
    {"city_id":"8688","city_name":"Carinhanha","state_id":"516"},
    {"city_id":"8689","city_name":"Casa Nova","state_id":"516"},
    {"city_id":"8690","city_name":"Castro Alves","state_id":"516"},
    {"city_id":"8691","city_name":"Catu","state_id":"516"},
    {"city_id":"8692","city_name":"Cicero Dantas","state_id":"516"},
    {"city_id":"8693","city_name":"Cipo","state_id":"516"},
    {"city_id":"8694","city_name":"Coaraci","state_id":"516"},
    {"city_id":"8695","city_name":"Conceicao da Feira","state_id":"516"},
    {"city_id":"8696","city_name":"Conceicao do Almeida","state_id":"516"},
    {"city_id":"8697","city_name":"Conceicao do Coite","state_id":"516"},
    {"city_id":"8698","city_name":"Conceicao do Jacuipe","state_id":"516"},
    {"city_id":"8699","city_name":"Conde","state_id":"516"},
    {"city_id":"8700","city_name":"Coracao de Maria","state_id":"516"},
    {"city_id":"8701","city_name":"Coronel Joao Sa","state_id":"516"},
    {"city_id":"8702","city_name":"Correntina","state_id":"516"},
    {"city_id":"8703","city_name":"Cruz das Almas","state_id":"516"},
    {"city_id":"8704","city_name":"Curaca","state_id":"516"},
    {"city_id":"8705","city_name":"Dias d'Avila","state_id":"516"},
    {"city_id":"8706","city_name":"Encruzilhada","state_id":"516"},
    {"city_id":"8707","city_name":"Entre Rios","state_id":"516"},
    {"city_id":"8708","city_name":"Esplanada","state_id":"516"},
    {"city_id":"8709","city_name":"Euclides da Cunha","state_id":"516"},
    {"city_id":"8710","city_name":"Eunapolis","state_id":"516"},
    {"city_id":"8711","city_name":"Feira de Santana","state_id":"516"},
    {"city_id":"8712","city_name":"Filadelfia","state_id":"516"},
    {"city_id":"8713","city_name":"Formosa do Rio Preto","state_id":"516"},
    {"city_id":"8714","city_name":"Gandu","state_id":"516"},
    {"city_id":"8715","city_name":"Guanambi","state_id":"516"},
    {"city_id":"8716","city_name":"Guaratinga","state_id":"516"},
    {"city_id":"8717","city_name":"Iacu","state_id":"516"},
    {"city_id":"8718","city_name":"Ibicarai","state_id":"516"},
    {"city_id":"8719","city_name":"Ibicui","state_id":"516"},
    {"city_id":"8720","city_name":"Ibipeba","state_id":"516"},
    {"city_id":"8721","city_name":"Ibirapitanga","state_id":"516"},
    {"city_id":"8722","city_name":"Ibirataia","state_id":"516"},
    {"city_id":"8723","city_name":"Ibotirama","state_id":"516"},
    {"city_id":"8724","city_name":"Iguai","state_id":"516"},
    {"city_id":"8725","city_name":"Ilheus","state_id":"516"},
    {"city_id":"8726","city_name":"Inhambupe","state_id":"516"},
    {"city_id":"8727","city_name":"Ipiau","state_id":"516"},
    {"city_id":"8728","city_name":"Ipira","state_id":"516"},
    {"city_id":"8729","city_name":"Iraquara","state_id":"516"},
    {"city_id":"8730","city_name":"Irara","state_id":"516"},
    {"city_id":"8731","city_name":"Irece","state_id":"516"},
    {"city_id":"8732","city_name":"Itabela","state_id":"516"},
    {"city_id":"8733","city_name":"Itaberaba","state_id":"516"},
    {"city_id":"8734","city_name":"Itabuna","state_id":"516"},
    {"city_id":"8735","city_name":"Itacare","state_id":"516"},
    {"city_id":"8736","city_name":"Itagi","state_id":"516"},
    {"city_id":"8737","city_name":"Itagiba","state_id":"516"},
    {"city_id":"8738","city_name":"Itajuipe","state_id":"516"},
    {"city_id":"8739","city_name":"Itamaraju","state_id":"516"},
    {"city_id":"8740","city_name":"Itambe","state_id":"516"},
    {"city_id":"8741","city_name":"Itanhem","state_id":"516"},
    {"city_id":"8742","city_name":"Itaparica","state_id":"516"},
    {"city_id":"8743","city_name":"Itapetinga","state_id":"516"},
    {"city_id":"8744","city_name":"Itapicuru","state_id":"516"},
    {"city_id":"8745","city_name":"Itarantim","state_id":"516"},
    {"city_id":"8746","city_name":"Itirucu","state_id":"516"},
    {"city_id":"8747","city_name":"Itiuba","state_id":"516"},
    {"city_id":"8748","city_name":"Itororo","state_id":"516"},
    {"city_id":"8749","city_name":"Ituacu","state_id":"516"},
    {"city_id":"8750","city_name":"Itubera","state_id":"516"},
    {"city_id":"8751","city_name":"Jacobina","state_id":"516"},
    {"city_id":"8752","city_name":"Jaguaquara","state_id":"516"},
    {"city_id":"8753","city_name":"Jaguarari","state_id":"516"},
    {"city_id":"8754","city_name":"Jequie","state_id":"516"},
    {"city_id":"8755","city_name":"Jeremoabo","state_id":"516"},
    {"city_id":"8756","city_name":"Jitauna","state_id":"516"},
    {"city_id":"8757","city_name":"Joao Dourado","state_id":"516"},
    {"city_id":"8758","city_name":"Juazeiro","state_id":"516"},
    {"city_id":"8759","city_name":"Jussara","state_id":"516"},
    {"city_id":"8760","city_name":"Laje","state_id":"516"},
    {"city_id":"8761","city_name":"Lapao","state_id":"516"},
    {"city_id":"8762","city_name":"Lauro de Freitas","state_id":"516"},
    {"city_id":"8763","city_name":"Livramento","state_id":"516"},
    {"city_id":"8764","city_name":"Macarani","state_id":"516"},
    {"city_id":"8765","city_name":"Macaubas","state_id":"516"},
    {"city_id":"8766","city_name":"Madre de Deus","state_id":"516"},
    {"city_id":"8767","city_name":"Mairi","state_id":"516"},
    {"city_id":"8768","city_name":"Maracas","state_id":"516"},
    {"city_id":"8769","city_name":"Maragogipe","state_id":"516"},
    {"city_id":"8770","city_name":"Marau","state_id":"516"},
    {"city_id":"8771","city_name":"Mascote","state_id":"516"},
    {"city_id":"8772","city_name":"Mata de Sao Joao","state_id":"516"},
    {"city_id":"8773","city_name":"Medeiros Neto","state_id":"516"},
    {"city_id":"8774","city_name":"Miguel Calmon","state_id":"516"},
    {"city_id":"8775","city_name":"Milagres","state_id":"516"},
    {"city_id":"8776","city_name":"Monte Santo","state_id":"516"},
    {"city_id":"8777","city_name":"Morro de Chapeu","state_id":"516"},
    {"city_id":"8778","city_name":"Mucuri","state_id":"516"},
    {"city_id":"8779","city_name":"Mundo Novo","state_id":"516"},
    {"city_id":"8780","city_name":"Muritiba","state_id":"516"},
    {"city_id":"8781","city_name":"Mutuipe","state_id":"516"},
    {"city_id":"8782","city_name":"Nazare","state_id":"516"},
    {"city_id":"8783","city_name":"Nova Soure","state_id":"516"},
    {"city_id":"8784","city_name":"Nova Vicosa","state_id":"516"},
    {"city_id":"8785","city_name":"Olindina","state_id":"516"},
    {"city_id":"8786","city_name":"Oliveira dos Brejinhos","state_id":"516"},
    {"city_id":"8787","city_name":"Palmas de Monte Alto","state_id":"516"},
    {"city_id":"8788","city_name":"Paramirim","state_id":"516"},
    {"city_id":"8789","city_name":"Paratinga","state_id":"516"},
    {"city_id":"8790","city_name":"Paripiranga","state_id":"516"},
    {"city_id":"8791","city_name":"Pau Brasil","state_id":"516"},
    {"city_id":"8792","city_name":"Paulo Afonso","state_id":"516"},
    {"city_id":"8793","city_name":"Pilao Arcado","state_id":"516"},
    {"city_id":"8794","city_name":"Pindobacu","state_id":"516"},
    {"city_id":"8795","city_name":"Piritiba","state_id":"516"},
    {"city_id":"8796","city_name":"Planalto","state_id":"516"},
    {"city_id":"8797","city_name":"Pocoes","state_id":"516"},
    {"city_id":"8798","city_name":"Pojuca","state_id":"516"},
    {"city_id":"8799","city_name":"Ponto Novo","state_id":"516"},
    {"city_id":"8800","city_name":"Porto Seguro","state_id":"516"},
    {"city_id":"8801","city_name":"Prado","state_id":"516"},
    {"city_id":"8802","city_name":"Presidente Tancredo Neves","state_id":"516"},
    {"city_id":"8803","city_name":"Queimadas","state_id":"516"},
    {"city_id":"8804","city_name":"Quijingue","state_id":"516"},
    {"city_id":"8805","city_name":"Rafael Jambeiro","state_id":"516"},
    {"city_id":"8806","city_name":"Remanso","state_id":"516"},
    {"city_id":"8807","city_name":"Riachao das Neves","state_id":"516"},
    {"city_id":"8808","city_name":"Riachao do Jacuipe","state_id":"516"},
    {"city_id":"8809","city_name":"Riacho de Santana","state_id":"516"},
    {"city_id":"8810","city_name":"Ribeira do Pombal","state_id":"516"},
    {"city_id":"8811","city_name":"Rio Real","state_id":"516"},
    {"city_id":"8812","city_name":"Ruy Barbosa","state_id":"516"},
    {"city_id":"8813","city_name":"Salvador","state_id":"516"},
    {"city_id":"8814","city_name":"Santa Cruz Cabralia","state_id":"516"},
    {"city_id":"8815","city_name":"Santa Ines","state_id":"516"},
    {"city_id":"8816","city_name":"Santa Maria da Vitoria","state_id":"516"},
    {"city_id":"8817","city_name":"Santa Rita de Cassia","state_id":"516"},
    {"city_id":"8818","city_name":"Santaluz","state_id":"516"},
    {"city_id":"8819","city_name":"Santana","state_id":"516"},
    {"city_id":"8820","city_name":"Santo Amaro","state_id":"516"},
    {"city_id":"8821","city_name":"Santo Antonio de Jesus","state_id":"516"},
    {"city_id":"8822","city_name":"Santo Estevao","state_id":"516"},
    {"city_id":"8823","city_name":"Sao Desiderio","state_id":"516"},
    {"city_id":"8824","city_name":"Sao Felipe","state_id":"516"},
    {"city_id":"8825","city_name":"Sao Francisco do Conde","state_id":"516"},
    {"city_id":"8826","city_name":"Sao Gabriel","state_id":"516"},
    {"city_id":"8827","city_name":"Sao Goncalo dos Campos","state_id":"516"},
    {"city_id":"8828","city_name":"Sao Sebastiao do Passe","state_id":"516"},
    {"city_id":"8829","city_name":"Saubara","state_id":"516"},
    {"city_id":"8830","city_name":"Seabra","state_id":"516"},
    {"city_id":"8831","city_name":"Senhor do Bonfim","state_id":"516"},
    {"city_id":"8832","city_name":"Sento Se","state_id":"516"},
    {"city_id":"8833","city_name":"Serra Dourada","state_id":"516"},
    {"city_id":"8834","city_name":"Serra do Ramalho","state_id":"516"},
    {"city_id":"8835","city_name":"Serrinha","state_id":"516"},
    {"city_id":"8836","city_name":"Simoes Filho","state_id":"516"},
    {"city_id":"8837","city_name":"Sobradinho","state_id":"516"},
    {"city_id":"8838","city_name":"Souto Soares","state_id":"516"},
    {"city_id":"8839","city_name":"Tanhacu","state_id":"516"},
    {"city_id":"8840","city_name":"Taperoa","state_id":"516"},
    {"city_id":"8841","city_name":"Tapiramuta","state_id":"516"},
    {"city_id":"8842","city_name":"Teixeira de Freitas","state_id":"516"},
    {"city_id":"8843","city_name":"Teofilandia","state_id":"516"},
    {"city_id":"8844","city_name":"Terra Nova","state_id":"516"},
    {"city_id":"8845","city_name":"Tremedal","state_id":"516"},
    {"city_id":"8846","city_name":"Tucano","state_id":"516"},
    {"city_id":"8847","city_name":"Uaua","state_id":"516"},
    {"city_id":"8848","city_name":"Ubaira","state_id":"516"},
    {"city_id":"8849","city_name":"Ubaitaba","state_id":"516"},
    {"city_id":"8850","city_name":"Ubata","state_id":"516"},
    {"city_id":"8851","city_name":"Una","state_id":"516"},
    {"city_id":"8852","city_name":"Urucuca","state_id":"516"},
    {"city_id":"8853","city_name":"Utinga","state_id":"516"},
    {"city_id":"8854","city_name":"Valenca","state_id":"516"},
    {"city_id":"8855","city_name":"Valente","state_id":"516"},
    {"city_id":"8856","city_name":"Vera Cruz","state_id":"516"},
    {"city_id":"8857","city_name":"Vitoria da Conquista","state_id":"516"},
    {"city_id":"8858","city_name":"Wenceslau Guimaraes","state_id":"516"},
    {"city_id":"8859","city_name":"Xique-Xique","state_id":"516"},
    {"city_id":"8860","city_name":"Acarau","state_id":"517"},
    {"city_id":"8861","city_name":"Acopiara","state_id":"517"},
    {"city_id":"8862","city_name":"Amontada","state_id":"517"},
    {"city_id":"8863","city_name":"Aquiraz","state_id":"517"},
    {"city_id":"8864","city_name":"Aracati","state_id":"517"},
    {"city_id":"8865","city_name":"Aracoiaba","state_id":"517"},
    {"city_id":"8866","city_name":"Araripe","state_id":"517"},
    {"city_id":"8867","city_name":"Assare","state_id":"517"},
    {"city_id":"8868","city_name":"Aurora","state_id":"517"},
    {"city_id":"8869","city_name":"Barbalha","state_id":"517"},
    {"city_id":"8870","city_name":"Barro","state_id":"517"},
    {"city_id":"8871","city_name":"Barroquinha","state_id":"517"},
    {"city_id":"8872","city_name":"Baturite","state_id":"517"},
    {"city_id":"8873","city_name":"Beberibe","state_id":"517"},
    {"city_id":"8874","city_name":"Bela Cruz","state_id":"517"},
    {"city_id":"8875","city_name":"Boa Viagem","state_id":"517"},
    {"city_id":"8876","city_name":"Brejo Santo","state_id":"517"},
    {"city_id":"8877","city_name":"Camocim","state_id":"517"},
    {"city_id":"8878","city_name":"Campos Sales","state_id":"517"},
    {"city_id":"8879","city_name":"Caninde","state_id":"517"},
    {"city_id":"8880","city_name":"Carire","state_id":"517"},
    {"city_id":"8881","city_name":"Caririacu","state_id":"517"},
    {"city_id":"8882","city_name":"Cascavel","state_id":"517"},
    {"city_id":"8883","city_name":"Caucaia","state_id":"517"},
    {"city_id":"8884","city_name":"Cedro","state_id":"517"},
    {"city_id":"8885","city_name":"Chorozinho","state_id":"517"},
    {"city_id":"8886","city_name":"Coreau","state_id":"517"},
    {"city_id":"8887","city_name":"Crateus","state_id":"517"},
    {"city_id":"8888","city_name":"Crato","state_id":"517"},
    {"city_id":"8889","city_name":"Cruz","state_id":"517"},
    {"city_id":"8890","city_name":"Eusebio","state_id":"517"},
    {"city_id":"8891","city_name":"Farias Brito","state_id":"517"},
    {"city_id":"8892","city_name":"Forquilha","state_id":"517"},
    {"city_id":"8893","city_name":"Fortaleza","state_id":"517"},
    {"city_id":"8894","city_name":"Granja","state_id":"517"},
    {"city_id":"8895","city_name":"Guaiuba","state_id":"517"},
    {"city_id":"8896","city_name":"Guaraciaba do Norte","state_id":"517"},
    {"city_id":"8897","city_name":"Hidrolandia","state_id":"517"},
    {"city_id":"8898","city_name":"Horizonte","state_id":"517"},
    {"city_id":"8899","city_name":"Ibiapina","state_id":"517"},
    {"city_id":"8900","city_name":"Ico","state_id":"517"},
    {"city_id":"8901","city_name":"Iguatu","state_id":"517"},
    {"city_id":"8902","city_name":"Independencia","state_id":"517"},
    {"city_id":"8903","city_name":"Ipu","state_id":"517"},
    {"city_id":"8904","city_name":"Ipueiras","state_id":"517"},
    {"city_id":"8905","city_name":"Iraucuba","state_id":"517"},
    {"city_id":"8906","city_name":"Itaitinga","state_id":"517"},
    {"city_id":"8907","city_name":"Itapage","state_id":"517"},
    {"city_id":"8908","city_name":"Itapipoca","state_id":"517"},
    {"city_id":"8909","city_name":"Itarema","state_id":"517"},
    {"city_id":"8910","city_name":"Jaguaribe","state_id":"517"},
    {"city_id":"8911","city_name":"Jaguaruana","state_id":"517"},
    {"city_id":"8912","city_name":"Jardim","state_id":"517"},
    {"city_id":"8913","city_name":"Juazeiro do Norte","state_id":"517"},
    {"city_id":"8914","city_name":"Jucas","state_id":"517"},
    {"city_id":"8915","city_name":"Lavras da Mangabeira","state_id":"517"},
    {"city_id":"8916","city_name":"Limoeiro do Norte","state_id":"517"},
    {"city_id":"8917","city_name":"Maracanau","state_id":"517"},
    {"city_id":"8918","city_name":"Maranguape","state_id":"517"},
    {"city_id":"8919","city_name":"Marco","state_id":"517"},
    {"city_id":"8920","city_name":"Massape","state_id":"517"},
    {"city_id":"8921","city_name":"Mauriti","state_id":"517"},
    {"city_id":"8922","city_name":"Milagres","state_id":"517"},
    {"city_id":"8923","city_name":"Missao Velha","state_id":"517"},
    {"city_id":"8924","city_name":"Mombaca","state_id":"517"},
    {"city_id":"8925","city_name":"Morada Nova","state_id":"517"},
    {"city_id":"8926","city_name":"Nova Russas","state_id":"517"},
    {"city_id":"8927","city_name":"Novo Oriente","state_id":"517"},
    {"city_id":"8928","city_name":"Ocara","state_id":"517"},
    {"city_id":"8929","city_name":"Oros","state_id":"517"},
    {"city_id":"8930","city_name":"Pacajus","state_id":"517"},
    {"city_id":"8931","city_name":"Pacatuba","state_id":"517"},
    {"city_id":"8932","city_name":"Paracuru","state_id":"517"},
    {"city_id":"8933","city_name":"Paraipaba","state_id":"517"},
    {"city_id":"8934","city_name":"Parambu","state_id":"517"},
    {"city_id":"8935","city_name":"Pedra Branca","state_id":"517"},
    {"city_id":"8936","city_name":"Pentecoste","state_id":"517"},
    {"city_id":"8937","city_name":"Quixada","state_id":"517"},
    {"city_id":"8938","city_name":"Quixeramobim","state_id":"517"},
    {"city_id":"8939","city_name":"Quixere","state_id":"517"},
    {"city_id":"8940","city_name":"Redencao","state_id":"517"},
    {"city_id":"8941","city_name":"Reriutaba","state_id":"517"},
    {"city_id":"8942","city_name":"Russas","state_id":"517"},
    {"city_id":"8943","city_name":"Santa Quiteria","state_id":"517"},
    {"city_id":"8944","city_name":"Santana do Acarau","state_id":"517"},
    {"city_id":"8945","city_name":"Sao Benedito","state_id":"517"},
    {"city_id":"8946","city_name":"Sao Goncalo do Amarante","state_id":"517"},
    {"city_id":"8947","city_name":"Senador Pompeu","state_id":"517"},
    {"city_id":"8948","city_name":"Sobral","state_id":"517"},
    {"city_id":"8949","city_name":"Tabuleiro do Norte","state_id":"517"},
    {"city_id":"8950","city_name":"Tamboril","state_id":"517"},
    {"city_id":"8951","city_name":"Taua","state_id":"517"},
    {"city_id":"8952","city_name":"Tiangua","state_id":"517"},
    {"city_id":"8953","city_name":"Trairi","state_id":"517"},
    {"city_id":"8954","city_name":"Ubajara","state_id":"517"},
    {"city_id":"8955","city_name":"Umirim","state_id":"517"},
    {"city_id":"8956","city_name":"Uruburetama","state_id":"517"},
    {"city_id":"8957","city_name":"Varjota","state_id":"517"},
    {"city_id":"8958","city_name":"Varzea Alegre","state_id":"517"},
    {"city_id":"8959","city_name":"Vicosa do Ceara","state_id":"517"},
    {"city_id":"8960","city_name":"Abadiania","state_id":"521"},
    {"city_id":"8961","city_name":"Acreuna","state_id":"521"},
    {"city_id":"8962","city_name":"Aguas Lindas de Goias","state_id":"521"},
    {"city_id":"8963","city_name":"Alexania","state_id":"521"},
    {"city_id":"8964","city_name":"Anapolis","state_id":"521"},
    {"city_id":"8965","city_name":"Anicuns","state_id":"521"},
    {"city_id":"8966","city_name":"Aparecida de Goiania","state_id":"521"},
    {"city_id":"8967","city_name":"Aragarcas","state_id":"521"},
    {"city_id":"8968","city_name":"Bela Vista de Goias","state_id":"521"},
    {"city_id":"8969","city_name":"Bom Jesus de Goias","state_id":"521"},
    {"city_id":"8970","city_name":"Buriti Alegre","state_id":"521"},
    {"city_id":"8971","city_name":"Cacu","state_id":"521"},
    {"city_id":"8972","city_name":"Caiaponia","state_id":"521"},
    {"city_id":"8973","city_name":"Caldas Novas","state_id":"521"},
    {"city_id":"8974","city_name":"Campos Belos","state_id":"521"},
    {"city_id":"8975","city_name":"Campos Verdes","state_id":"521"},
    {"city_id":"8976","city_name":"Carmo do Rio Verde","state_id":"521"},
    {"city_id":"8977","city_name":"Catalao","state_id":"521"},
    {"city_id":"8978","city_name":"Cavalcante","state_id":"521"},
    {"city_id":"8979","city_name":"Ceres","state_id":"521"},
    {"city_id":"8980","city_name":"Cidade Ocidental","state_id":"521"},
    {"city_id":"8981","city_name":"Cocalzinho de Coias","state_id":"521"},
    {"city_id":"8982","city_name":"Cristalina","state_id":"521"},
    {"city_id":"8983","city_name":"Crixas","state_id":"521"},
    {"city_id":"8984","city_name":"Doverlandia","state_id":"521"},
    {"city_id":"8985","city_name":"Edeia","state_id":"521"},
    {"city_id":"8986","city_name":"Firminopolis","state_id":"521"},
    {"city_id":"8987","city_name":"Formosa","state_id":"521"},
    {"city_id":"8988","city_name":"Goianapolis","state_id":"521"},
    {"city_id":"8989","city_name":"Goianesia","state_id":"521"},
    {"city_id":"8990","city_name":"Goiania","state_id":"521"},
    {"city_id":"8991","city_name":"Goianira","state_id":"521"},
    {"city_id":"8992","city_name":"Goias","state_id":"521"},
    {"city_id":"8993","city_name":"Goiatuba","state_id":"521"},
    {"city_id":"8994","city_name":"Guapo","state_id":"521"},
    {"city_id":"8995","city_name":"Hidrolandia","state_id":"521"},
    {"city_id":"8996","city_name":"Iaciara","state_id":"521"},
    {"city_id":"8997","city_name":"Indiara","state_id":"521"},
    {"city_id":"8998","city_name":"Inhumas","state_id":"521"},
    {"city_id":"8999","city_name":"Ipameri","state_id":"521"},
    {"city_id":"9000","city_name":"Ipora","state_id":"521"},
    {"city_id":"9001","city_name":"Itaberai","state_id":"521"},
    {"city_id":"9002","city_name":"Itapaci","state_id":"521"},
    {"city_id":"9003","city_name":"Itapirapua","state_id":"521"},
    {"city_id":"9004","city_name":"Itapuranga","state_id":"521"},
    {"city_id":"9005","city_name":"Itumbiara","state_id":"521"},
    {"city_id":"9006","city_name":"Jaragua","state_id":"521"},
    {"city_id":"9007","city_name":"Jatai","state_id":"521"},
    {"city_id":"9008","city_name":"Jussara","state_id":"521"},
    {"city_id":"9009","city_name":"Luziania","state_id":"521"},
    {"city_id":"9010","city_name":"Mara Rosa","state_id":"521"},
    {"city_id":"9011","city_name":"Minacu","state_id":"521"},
    {"city_id":"9012","city_name":"Mineiros","state_id":"521"},
    {"city_id":"9013","city_name":"Morrinhos","state_id":"521"},
    {"city_id":"9014","city_name":"Mozarlandia","state_id":"521"},
    {"city_id":"9015","city_name":"Neropolis","state_id":"521"},
    {"city_id":"9016","city_name":"Niquelandia","state_id":"521"},
    {"city_id":"9017","city_name":"Nova Crixas","state_id":"521"},
    {"city_id":"9018","city_name":"Novo Gama","state_id":"521"},
    {"city_id":"9019","city_name":"Orizona","state_id":"521"},
    {"city_id":"9020","city_name":"Padre Bernardo","state_id":"521"},
    {"city_id":"9021","city_name":"Palmeiras de Goias","state_id":"521"},
    {"city_id":"9022","city_name":"Parauna","state_id":"521"},
    {"city_id":"9023","city_name":"Petrolina de Goias","state_id":"521"},
    {"city_id":"9024","city_name":"Piracanjuba","state_id":"521"},
    {"city_id":"9025","city_name":"Piranhas","state_id":"521"},
    {"city_id":"9026","city_name":"Pirenopolis","state_id":"521"},
    {"city_id":"9027","city_name":"Pires do Rio","state_id":"521"},
    {"city_id":"9028","city_name":"Planaltina","state_id":"521"},
    {"city_id":"9029","city_name":"Pontalina","state_id":"521"},
    {"city_id":"9030","city_name":"Porangatu","state_id":"521"},
    {"city_id":"9031","city_name":"Posse","state_id":"521"},
    {"city_id":"9032","city_name":"Quirinopolis","state_id":"521"},
    {"city_id":"9033","city_name":"Rialma","state_id":"521"},
    {"city_id":"9034","city_name":"Rio Verde","state_id":"521"},
    {"city_id":"9035","city_name":"Rubiataba","state_id":"521"},
    {"city_id":"9036","city_name":"Santa Helena de Goias","state_id":"521"},
    {"city_id":"9037","city_name":"Santa Terezinha de Goias","state_id":"521"},
    {"city_id":"9038","city_name":"Santo Antonio do Descoberto","state_id":"521"},
    {"city_id":"9039","city_name":"Sao Domingos","state_id":"521"},
    {"city_id":"9040","city_name":"Sao Luis de Montes Belos","state_id":"521"},
    {"city_id":"9041","city_name":"Sao Miguel do Araguaia","state_id":"521"},
    {"city_id":"9042","city_name":"Sao Simao","state_id":"521"},
    {"city_id":"9043","city_name":"Senador Canedo","state_id":"521"},
    {"city_id":"9044","city_name":"Silvania","state_id":"521"},
    {"city_id":"9045","city_name":"Trindade","state_id":"521"},
    {"city_id":"9046","city_name":"Uruacu","state_id":"521"},
    {"city_id":"9047","city_name":"Uruana","state_id":"521"},
    {"city_id":"9048","city_name":"Valparaiso de Goias","state_id":"521"},
    {"city_id":"9049","city_name":"Vianopolis","state_id":"521"},
    {"city_id":"9050","city_name":"Acailandia","state_id":"522"},
    {"city_id":"9051","city_name":"Alcantara","state_id":"522"},
    {"city_id":"9052","city_name":"Aldeias Altas","state_id":"522"},
    {"city_id":"9053","city_name":"Alto Alegre do Pindare","state_id":"522"},
    {"city_id":"9054","city_name":"Amarante do Maranhao","state_id":"522"},
    {"city_id":"9055","city_name":"Anajatuba","state_id":"522"},
    {"city_id":"9056","city_name":"Araioses","state_id":"522"},
    {"city_id":"9057","city_name":"Arame","state_id":"522"},
    {"city_id":"9058","city_name":"Arari","state_id":"522"},
    {"city_id":"9059","city_name":"Bacabal","state_id":"522"},
    {"city_id":"9060","city_name":"Balsas","state_id":"522"},
    {"city_id":"9061","city_name":"Barra do Corda","state_id":"522"},
    {"city_id":"9062","city_name":"Barreirinhas","state_id":"522"},
    {"city_id":"9063","city_name":"Bequimao","state_id":"522"},
    {"city_id":"9064","city_name":"Bom Jardim","state_id":"522"},
    {"city_id":"9065","city_name":"Brejo","state_id":"522"},
    {"city_id":"9066","city_name":"Buriti","state_id":"522"},
    {"city_id":"9067","city_name":"Buriti Bravo","state_id":"522"},
    {"city_id":"9068","city_name":"Buriticupu","state_id":"522"},
    {"city_id":"9069","city_name":"Candido Mendes","state_id":"522"},
    {"city_id":"9070","city_name":"Cantanhede","state_id":"522"},
    {"city_id":"9071","city_name":"Carolina","state_id":"522"},
    {"city_id":"9072","city_name":"Carutapera","state_id":"522"},
    {"city_id":"9073","city_name":"Caxias","state_id":"522"},
    {"city_id":"9074","city_name":"Chapadinha","state_id":"522"},
    {"city_id":"9075","city_name":"Codo","state_id":"522"},
    {"city_id":"9076","city_name":"Coelho Neto","state_id":"522"},
    {"city_id":"9077","city_name":"Colinas","state_id":"522"},
    {"city_id":"9078","city_name":"Coroata","state_id":"522"},
    {"city_id":"9079","city_name":"Cururupu","state_id":"522"},
    {"city_id":"9080","city_name":"Davinopolis","state_id":"522"},
    {"city_id":"9081","city_name":"Dom Pedro","state_id":"522"},
    {"city_id":"9082","city_name":"Esperantinopolis","state_id":"522"},
    {"city_id":"9083","city_name":"Estreito","state_id":"522"},
    {"city_id":"9084","city_name":"Fortuna","state_id":"522"},
    {"city_id":"9085","city_name":"Godofredo Viana","state_id":"522"},
    {"city_id":"9086","city_name":"Governador Eugenio Barros","state_id":"522"},
    {"city_id":"9087","city_name":"Governador Nunes Freire","state_id":"522"},
    {"city_id":"9088","city_name":"Grajau","state_id":"522"},
    {"city_id":"9089","city_name":"Humberto de Campos","state_id":"522"},
    {"city_id":"9090","city_name":"Icatu","state_id":"522"},
    {"city_id":"9091","city_name":"Imperatriz","state_id":"522"},
    {"city_id":"9092","city_name":"Itapecuru Mirim","state_id":"522"},
    {"city_id":"9093","city_name":"Itinga do Maranhao","state_id":"522"},
    {"city_id":"9094","city_name":"Joao Lisboa","state_id":"522"},
    {"city_id":"9095","city_name":"Lago da Pedra","state_id":"522"},
    {"city_id":"9096","city_name":"Lago do Junco","state_id":"522"},
    {"city_id":"9097","city_name":"Maracacume","state_id":"522"},
    {"city_id":"9098","city_name":"Matinha","state_id":"522"},
    {"city_id":"9099","city_name":"Matoes","state_id":"522"},
    {"city_id":"9100","city_name":"Mirador","state_id":"522"},
    {"city_id":"9101","city_name":"Miranda do Norte","state_id":"522"},
    {"city_id":"9102","city_name":"Moncao","state_id":"522"},
    {"city_id":"9103","city_name":"Montes Altos","state_id":"522"},
    {"city_id":"9104","city_name":"Morros","state_id":"522"},
    {"city_id":"9105","city_name":"Nova Olinda do Maranhao","state_id":"522"},
    {"city_id":"9106","city_name":"Olho d'Agua das Cunhas","state_id":"522"},
    {"city_id":"9107","city_name":"Paco do Lumiar","state_id":"522"},
    {"city_id":"9108","city_name":"Paraibano","state_id":"522"},
    {"city_id":"9109","city_name":"Parnarama","state_id":"522"},
    {"city_id":"9110","city_name":"Passagem Franca","state_id":"522"},
    {"city_id":"9111","city_name":"Pastos Bons","state_id":"522"},
    {"city_id":"9112","city_name":"Paulo Ramos","state_id":"522"},
    {"city_id":"9113","city_name":"Pedreiras","state_id":"522"},
    {"city_id":"9114","city_name":"Penalva","state_id":"522"},
    {"city_id":"9115","city_name":"Pindare Mirim","state_id":"522"},
    {"city_id":"9116","city_name":"Pinheiro","state_id":"522"},
    {"city_id":"9117","city_name":"Pio XII","state_id":"522"},
    {"city_id":"9118","city_name":"Pirapemas","state_id":"522"},
    {"city_id":"9119","city_name":"Pocao de Pedras","state_id":"522"},
    {"city_id":"9120","city_name":"Porto Franco","state_id":"522"},
    {"city_id":"9121","city_name":"Presidente Dutra","state_id":"522"},
    {"city_id":"9122","city_name":"Raposa","state_id":"522"},
    {"city_id":"9123","city_name":"Riachao","state_id":"522"},
    {"city_id":"9124","city_name":"Rosario","state_id":"522"},
    {"city_id":"9125","city_name":"Santa Helena","state_id":"522"},
    {"city_id":"9126","city_name":"Santa Ines","state_id":"522"},
    {"city_id":"9127","city_name":"Santa Luzia","state_id":"522"},
    {"city_id":"9128","city_name":"Santa Luzia do Parua","state_id":"522"},
    {"city_id":"9129","city_name":"Santa Quiteria do Maranhao","state_id":"522"},
    {"city_id":"9130","city_name":"Santa Rita","state_id":"522"},
    {"city_id":"9131","city_name":"Sao Benedito do Rio Preto","state_id":"522"},
    {"city_id":"9132","city_name":"Sao Bento","state_id":"522"},
    {"city_id":"9133","city_name":"Sao Bernardo","state_id":"522"},
    {"city_id":"9134","city_name":"Sao Domingos do Maranhao","state_id":"522"},
    {"city_id":"9135","city_name":"Sao Joao Batista","state_id":"522"},
    {"city_id":"9136","city_name":"Sao Joao dos Patos","state_id":"522"},
    {"city_id":"9137","city_name":"Sao Jose de Ribamar","state_id":"522"},
    {"city_id":"9138","city_name":"Sao Luis","state_id":"522"},
    {"city_id":"9139","city_name":"Sao Luis Gonzaga do Maranhao","state_id":"522"},
    {"city_id":"9140","city_name":"Sao Mateus do Maranhao","state_id":"522"},
    {"city_id":"9141","city_name":"Sao Pedro da Agua Branca","state_id":"522"},
    {"city_id":"9142","city_name":"Sao Raimundo das Mangabeiras","state_id":"522"},
    {"city_id":"9143","city_name":"Timbiras","state_id":"522"},
    {"city_id":"9144","city_name":"Timon","state_id":"522"},
    {"city_id":"9145","city_name":"Trizidela do Vale","state_id":"522"},
    {"city_id":"9146","city_name":"Tuntum","state_id":"522"},
    {"city_id":"9147","city_name":"Turiacu","state_id":"522"},
    {"city_id":"9148","city_name":"Tutoia","state_id":"522"},
    {"city_id":"9149","city_name":"Urbano Santos","state_id":"522"},
    {"city_id":"9150","city_name":"Vargem Grande","state_id":"522"},
    {"city_id":"9151","city_name":"Viana","state_id":"522"},
    {"city_id":"9152","city_name":"Vitoria do Mearim","state_id":"522"},
    {"city_id":"9153","city_name":"Vitorino Freire","state_id":"522"},
    {"city_id":"9154","city_name":"Ze Doca","state_id":"522"},
    {"city_id":"9155","city_name":"Abaetetuba","state_id":"526"},
    {"city_id":"9156","city_name":"Acara","state_id":"526"},
    {"city_id":"9157","city_name":"Afua","state_id":"526"},
    {"city_id":"9158","city_name":"Agua Azul do Norte","state_id":"526"},
    {"city_id":"9159","city_name":"Alenquer","state_id":"526"},
    {"city_id":"9160","city_name":"Almeirim","state_id":"526"},
    {"city_id":"9161","city_name":"Altamira","state_id":"526"},
    {"city_id":"9162","city_name":"Ananindeua","state_id":"526"},
    {"city_id":"9163","city_name":"Augusto Correa","state_id":"526"},
    {"city_id":"9164","city_name":"Baiao","state_id":"526"},
    {"city_id":"9165","city_name":"Barcarena","state_id":"526"},
    {"city_id":"9166","city_name":"Belem","state_id":"526"},
    {"city_id":"9167","city_name":"Benevides","state_id":"526"},
    {"city_id":"9168","city_name":"Braganca","state_id":"526"},
    {"city_id":"9169","city_name":"Breu Branco","state_id":"526"},
    {"city_id":"9170","city_name":"Breves","state_id":"526"},
    {"city_id":"9171","city_name":"Bujaru","state_id":"526"},
    {"city_id":"9172","city_name":"Cameta","state_id":"526"},
    {"city_id":"9173","city_name":"Capanema","state_id":"526"},
    {"city_id":"9174","city_name":"Capitao Poco","state_id":"526"},
    {"city_id":"9175","city_name":"Castanhal","state_id":"526"},
    {"city_id":"9176","city_name":"Conceicao do Araguaia","state_id":"526"},
    {"city_id":"9177","city_name":"Concordia do Para","state_id":"526"},
    {"city_id":"9178","city_name":"Curionopolis","state_id":"526"},
    {"city_id":"9179","city_name":"Curuca","state_id":"526"},
    {"city_id":"9180","city_name":"Dom Eliseu","state_id":"526"},
    {"city_id":"9181","city_name":"Eldorado dos Carajas","state_id":"526"},
    {"city_id":"9182","city_name":"Garrafao do Norte","state_id":"526"},
    {"city_id":"9183","city_name":"Goianesia do Para","state_id":"526"},
    {"city_id":"9184","city_name":"Gurupa","state_id":"526"},
    {"city_id":"9185","city_name":"Igarape-Acu","state_id":"526"},
    {"city_id":"9186","city_name":"Igarape-Miri","state_id":"526"},
    {"city_id":"9187","city_name":"Irituia","state_id":"526"},
    {"city_id":"9188","city_name":"Itaituba","state_id":"526"},
    {"city_id":"9189","city_name":"Itupiranga","state_id":"526"},
    {"city_id":"9190","city_name":"Jacareacanga","state_id":"526"},
    {"city_id":"9191","city_name":"Jacunda","state_id":"526"},
    {"city_id":"9192","city_name":"Juruti","state_id":"526"},
    {"city_id":"9193","city_name":"Limoeiro do Ajuru","state_id":"526"},
    {"city_id":"9194","city_name":"Mae do Rio","state_id":"526"},
    {"city_id":"9195","city_name":"Maraba","state_id":"526"},
    {"city_id":"9196","city_name":"Maracana","state_id":"526"},
    {"city_id":"9197","city_name":"Marapanim","state_id":"526"},
    {"city_id":"9198","city_name":"Marituba","state_id":"526"},
    {"city_id":"9199","city_name":"Medicilandia","state_id":"526"},
    {"city_id":"9200","city_name":"Mocajuba","state_id":"526"},
    {"city_id":"9201","city_name":"Moju","state_id":"526"},
    {"city_id":"9202","city_name":"Monte Alegre","state_id":"526"},
    {"city_id":"9203","city_name":"Muana","state_id":"526"},
    {"city_id":"9204","city_name":"Novo Progresso","state_id":"526"},
    {"city_id":"9205","city_name":"Novo Repartimento","state_id":"526"},
    {"city_id":"9206","city_name":"Obidos","state_id":"526"},
    {"city_id":"9207","city_name":"Oeiras do Para","state_id":"526"},
    {"city_id":"9208","city_name":"Oriximina","state_id":"526"},
    {"city_id":"9209","city_name":"Ourem","state_id":"526"},
    {"city_id":"9210","city_name":"Ourilandia","state_id":"526"},
    {"city_id":"9211","city_name":"Pacaja","state_id":"526"},
    {"city_id":"9212","city_name":"Paragominas","state_id":"526"},
    {"city_id":"9213","city_name":"Parauapebas","state_id":"526"},
    {"city_id":"9214","city_name":"Portel","state_id":"526"},
    {"city_id":"9215","city_name":"Porto de Moz","state_id":"526"},
    {"city_id":"9216","city_name":"Prainha","state_id":"526"},
    {"city_id":"9217","city_name":"Redencao","state_id":"526"},
    {"city_id":"9218","city_name":"Rio Maria","state_id":"526"},
    {"city_id":"9219","city_name":"Rondon do Para","state_id":"526"},
    {"city_id":"9220","city_name":"Ruropolis","state_id":"526"},
    {"city_id":"9221","city_name":"Salinopolis","state_id":"526"},
    {"city_id":"9222","city_name":"Santa Isabel do Para","state_id":"526"},
    {"city_id":"9223","city_name":"Santa Luzia do Para","state_id":"526"},
    {"city_id":"9224","city_name":"Santa Maria do Para","state_id":"526"},
    {"city_id":"9225","city_name":"Santana do Araguaia","state_id":"526"},
    {"city_id":"9226","city_name":"Santarem","state_id":"526"},
    {"city_id":"9227","city_name":"Santo Antonio do Taua","state_id":"526"},
    {"city_id":"9228","city_name":"Sao Caetano de Odivelas","state_id":"526"},
    {"city_id":"9229","city_name":"Sao Domingos do Araguaia","state_id":"526"},
    {"city_id":"9230","city_name":"Sao Domingos do Capim","state_id":"526"},
    {"city_id":"9231","city_name":"Sao Felix do Xingu","state_id":"526"},
    {"city_id":"9232","city_name":"Sao Geraldo do Araguaia","state_id":"526"},
    {"city_id":"9233","city_name":"Sao Joao de Pirabas","state_id":"526"},
    {"city_id":"9234","city_name":"Sao Miguel do Guama","state_id":"526"},
    {"city_id":"9235","city_name":"Senador Jose Porfirio","state_id":"526"},
    {"city_id":"9236","city_name":"Soure","state_id":"526"},
    {"city_id":"9237","city_name":"Tailandia","state_id":"526"},
    {"city_id":"9238","city_name":"Terra Santa","state_id":"526"},
    {"city_id":"9239","city_name":"Tome-Acu","state_id":"526"},
    {"city_id":"9240","city_name":"Tucuma","state_id":"526"},
    {"city_id":"9241","city_name":"Tucurui","state_id":"526"},
    {"city_id":"9242","city_name":"Ulianopolis","state_id":"526"},
    {"city_id":"9243","city_name":"Uruara","state_id":"526"},
    {"city_id":"9244","city_name":"Vigia","state_id":"526"},
    {"city_id":"9245","city_name":"Viseu","state_id":"526"},
    {"city_id":"9246","city_name":"Xinguara","state_id":"526"},
    {"city_id":"9247","city_name":"Alagoa Grande","state_id":"527"},
    {"city_id":"9248","city_name":"Alagoa Nova","state_id":"527"},
    {"city_id":"9249","city_name":"Alagoinha","state_id":"527"},
    {"city_id":"9250","city_name":"Alhandra","state_id":"527"},
    {"city_id":"9251","city_name":"Aracagi","state_id":"527"},
    {"city_id":"9252","city_name":"Arara","state_id":"527"},
    {"city_id":"9253","city_name":"Araruna","state_id":"527"},
    {"city_id":"9254","city_name":"Areia","state_id":"527"},
    {"city_id":"9255","city_name":"Aroeiras","state_id":"527"},
    {"city_id":"9256","city_name":"Bananeiras","state_id":"527"},
    {"city_id":"9257","city_name":"Barra de Santa Rosa","state_id":"527"},
    {"city_id":"9258","city_name":"Bayeux","state_id":"527"},
    {"city_id":"9259","city_name":"Belem","state_id":"527"},
    {"city_id":"9260","city_name":"Boqueirao","state_id":"527"},
    {"city_id":"9261","city_name":"Brejo do Cruz","state_id":"527"},
    {"city_id":"9262","city_name":"Caapora","state_id":"527"},
    {"city_id":"9263","city_name":"Cabedelo","state_id":"527"},
    {"city_id":"9264","city_name":"Cacimba de Dentro","state_id":"527"},
    {"city_id":"9265","city_name":"Cajazeiras","state_id":"527"},
    {"city_id":"9266","city_name":"Campina Grande","state_id":"527"},
    {"city_id":"9267","city_name":"Catole do Rocha","state_id":"527"},
    {"city_id":"9268","city_name":"Conceicao","state_id":"527"},
    {"city_id":"9269","city_name":"Conde","state_id":"527"},
    {"city_id":"9270","city_name":"Coremas","state_id":"527"},
    {"city_id":"9271","city_name":"Cruz do Espirito Santo","state_id":"527"},
    {"city_id":"9272","city_name":"Cuite","state_id":"527"},
    {"city_id":"9273","city_name":"Desterro","state_id":"527"},
    {"city_id":"9274","city_name":"Dona Ines","state_id":"527"},
    {"city_id":"9275","city_name":"Esperanca","state_id":"527"},
    {"city_id":"9276","city_name":"Fagundes","state_id":"527"},
    {"city_id":"9277","city_name":"Guarabira","state_id":"527"},
    {"city_id":"9278","city_name":"Gurinhem","state_id":"527"},
    {"city_id":"9279","city_name":"Imaculada","state_id":"527"},
    {"city_id":"9280","city_name":"Inga","state_id":"527"},
    {"city_id":"9281","city_name":"Itabaiana","state_id":"527"},
    {"city_id":"9282","city_name":"Itaporanga","state_id":"527"},
    {"city_id":"9283","city_name":"Itapororoca","state_id":"527"},
    {"city_id":"9284","city_name":"Itatuba","state_id":"527"},
    {"city_id":"9285","city_name":"Jacarau","state_id":"527"},
    {"city_id":"9286","city_name":"Joao Pessoa","state_id":"527"},
    {"city_id":"9287","city_name":"Juazeirinho","state_id":"527"},
    {"city_id":"9288","city_name":"Juripiranga","state_id":"527"},
    {"city_id":"9289","city_name":"Juru","state_id":"527"},
    {"city_id":"9290","city_name":"Lagoa Seca","state_id":"527"},
    {"city_id":"9291","city_name":"Mamanguape","state_id":"527"},
    {"city_id":"9292","city_name":"Manaira","state_id":"527"},
    {"city_id":"9293","city_name":"Mari","state_id":"527"},
    {"city_id":"9294","city_name":"Massaranduba","state_id":"527"},
    {"city_id":"9295","city_name":"Mogeiro","state_id":"527"},
    {"city_id":"9296","city_name":"Monteiro","state_id":"527"},
    {"city_id":"9297","city_name":"Mulungu","state_id":"527"},
    {"city_id":"9298","city_name":"Natuba","state_id":"527"},
    {"city_id":"9299","city_name":"Nova Floresta","state_id":"527"},
    {"city_id":"9300","city_name":"Patos","state_id":"527"},
    {"city_id":"9301","city_name":"Paulista","state_id":"527"},
    {"city_id":"9302","city_name":"Pedras de Fogo","state_id":"527"},
    {"city_id":"9303","city_name":"Pianco","state_id":"527"},
    {"city_id":"9304","city_name":"Picui","state_id":"527"},
    {"city_id":"9305","city_name":"Pilar","state_id":"527"},
    {"city_id":"9306","city_name":"Pirpirituba","state_id":"527"},
    {"city_id":"9307","city_name":"Pitimbu","state_id":"527"},
    {"city_id":"9308","city_name":"Pocinhos","state_id":"527"},
    {"city_id":"9309","city_name":"Pombal","state_id":"527"},
    {"city_id":"9310","city_name":"Princesa Isabel","state_id":"527"},
    {"city_id":"9311","city_name":"Puxinana","state_id":"527"},
    {"city_id":"9312","city_name":"Queimadas","state_id":"527"},
    {"city_id":"9313","city_name":"Remigio","state_id":"527"},
    {"city_id":"9314","city_name":"Rio Tinto","state_id":"527"},
    {"city_id":"9315","city_name":"Salgado de Sao Felix","state_id":"527"},
    {"city_id":"9316","city_name":"Santa Luzia","state_id":"527"},
    {"city_id":"9317","city_name":"Santa Rita","state_id":"527"},
    {"city_id":"9318","city_name":"Sao Bento","state_id":"527"},
    {"city_id":"9319","city_name":"Sao Joao do Rio do Peixe","state_id":"527"},
    {"city_id":"9320","city_name":"Sao Jose de Piranhas","state_id":"527"},
    {"city_id":"9321","city_name":"Sao Sebastiao de Lagoa de Roca","state_id":"527"},
    {"city_id":"9322","city_name":"Sape","state_id":"527"},
    {"city_id":"9323","city_name":"Serra Branca","state_id":"527"},
    {"city_id":"9324","city_name":"Solanea","state_id":"527"},
    {"city_id":"9325","city_name":"Soledade","state_id":"527"},
    {"city_id":"9326","city_name":"Sousa","state_id":"527"},
    {"city_id":"9327","city_name":"Sume","state_id":"527"},
    {"city_id":"9328","city_name":"Taperoa","state_id":"527"},
    {"city_id":"9329","city_name":"Tavares","state_id":"527"},
    {"city_id":"9330","city_name":"Teixeira","state_id":"527"},
    {"city_id":"9331","city_name":"Triunfo","state_id":"527"},
    {"city_id":"9332","city_name":"Uirauna","state_id":"527"},
    {"city_id":"9333","city_name":"Umbuzeiro","state_id":"527"},
    {"city_id":"9334","city_name":"Almirante Tamandare","state_id":"528"},
    {"city_id":"9335","city_name":"Alto Parana","state_id":"528"},
    {"city_id":"9336","city_name":"Alto Piquiri","state_id":"528"},
    {"city_id":"9337","city_name":"Altonia","state_id":"528"},
    {"city_id":"9338","city_name":"Ampere","state_id":"528"},
    {"city_id":"9339","city_name":"Andira","state_id":"528"},
    {"city_id":"9340","city_name":"Antonina","state_id":"528"},
    {"city_id":"9341","city_name":"Apucarana","state_id":"528"},
    {"city_id":"9342","city_name":"Arapongas","state_id":"528"},
    {"city_id":"9343","city_name":"Arapoti","state_id":"528"},
    {"city_id":"9344","city_name":"Araucaria","state_id":"528"},
    {"city_id":"9345","city_name":"Assai","state_id":"528"},
    {"city_id":"9346","city_name":"Assis Chateaubriand","state_id":"528"},
    {"city_id":"9347","city_name":"Astorga","state_id":"528"},
    {"city_id":"9348","city_name":"Bandeirantes","state_id":"528"},
    {"city_id":"9349","city_name":"Barbosa Ferraz","state_id":"528"},
    {"city_id":"9350","city_name":"Bela Vista do Paraiso","state_id":"528"},
    {"city_id":"9351","city_name":"Cambara","state_id":"528"},
    {"city_id":"9352","city_name":"Cambe","state_id":"528"},
    {"city_id":"9353","city_name":"Campina Grande do Sul","state_id":"528"},
    {"city_id":"9354","city_name":"Campina da Lagoa","state_id":"528"},
    {"city_id":"9355","city_name":"Campo Largo","state_id":"528"},
    {"city_id":"9356","city_name":"Campo Murao","state_id":"528"},
    {"city_id":"9357","city_name":"Candido de Abreu","state_id":"528"},
    {"city_id":"9358","city_name":"Capitao Leonidas Marques","state_id":"528"},
    {"city_id":"9359","city_name":"Carambei","state_id":"528"},
    {"city_id":"9360","city_name":"Cascavel","state_id":"528"},
    {"city_id":"9361","city_name":"Castro","state_id":"528"},
    {"city_id":"9362","city_name":"Centenario do Sul","state_id":"528"},
    {"city_id":"9363","city_name":"Chopinzinho","state_id":"528"},
    {"city_id":"9364","city_name":"Cianorte","state_id":"528"},
    {"city_id":"9365","city_name":"Clevelandia","state_id":"528"},
    {"city_id":"9366","city_name":"Colombo","state_id":"528"},
    {"city_id":"9367","city_name":"Colorado","state_id":"528"},
    {"city_id":"9368","city_name":"Contenda","state_id":"528"},
    {"city_id":"9369","city_name":"Corbelia","state_id":"528"},
    {"city_id":"9370","city_name":"Cornelio Procopio","state_id":"528"},
    {"city_id":"9371","city_name":"Coronel Vivida","state_id":"528"},
    {"city_id":"9372","city_name":"Cruzeiro do Oeste","state_id":"528"},
    {"city_id":"9373","city_name":"Curitiba","state_id":"528"},
    {"city_id":"9374","city_name":"Dois Vizinhos","state_id":"528"},
    {"city_id":"9375","city_name":"Engenheiro Beltrao","state_id":"528"},
    {"city_id":"9376","city_name":"Faxinal","state_id":"528"},
    {"city_id":"9377","city_name":"Fazenda Rio Grande","state_id":"528"},
    {"city_id":"9378","city_name":"Florestopolis","state_id":"528"},
    {"city_id":"9379","city_name":"Foz do Iguacu","state_id":"528"},
    {"city_id":"9380","city_name":"Francisco Beltrao","state_id":"528"},
    {"city_id":"9381","city_name":"Goioere","state_id":"528"},
    {"city_id":"9382","city_name":"Guaira","state_id":"528"},
    {"city_id":"9383","city_name":"Guaraniacu","state_id":"528"},
    {"city_id":"9384","city_name":"Guarapuava","state_id":"528"},
    {"city_id":"9385","city_name":"Guaratuba","state_id":"528"},
    {"city_id":"9386","city_name":"Ibaiti","state_id":"528"},
    {"city_id":"9387","city_name":"Ibipora","state_id":"528"},
    {"city_id":"9388","city_name":"Imbituva","state_id":"528"},
    {"city_id":"9389","city_name":"Ipora","state_id":"528"},
    {"city_id":"9390","city_name":"Irati","state_id":"528"},
    {"city_id":"9391","city_name":"Itaperucu","state_id":"528"},
    {"city_id":"9392","city_name":"Ivaipora","state_id":"528"},
    {"city_id":"9393","city_name":"Jacarezinho","state_id":"528"},
    {"city_id":"9394","city_name":"Jaguariaiva","state_id":"528"},
    {"city_id":"9395","city_name":"Jandaia do Sul","state_id":"528"},
    {"city_id":"9396","city_name":"Jataizinho","state_id":"528"},
    {"city_id":"9397","city_name":"Lapa","state_id":"528"},
    {"city_id":"9398","city_name":"Laranjeiras do Sul","state_id":"528"},
    {"city_id":"9399","city_name":"Loanda","state_id":"528"},
    {"city_id":"9400","city_name":"Londrina","state_id":"528"},
    {"city_id":"9401","city_name":"Mandaguacu","state_id":"528"},
    {"city_id":"9402","city_name":"Mandaguari","state_id":"528"},
    {"city_id":"9403","city_name":"Marechal Candido Rondon","state_id":"528"},
    {"city_id":"9404","city_name":"Marialva","state_id":"528"},
    {"city_id":"9405","city_name":"Maringa","state_id":"528"},
    {"city_id":"9406","city_name":"Matelandia","state_id":"528"},
    {"city_id":"9407","city_name":"Matinhos","state_id":"528"},
    {"city_id":"9408","city_name":"Medianeira","state_id":"528"},
    {"city_id":"9409","city_name":"Moreira Sales","state_id":"528"},
    {"city_id":"9410","city_name":"Nova Aurora","state_id":"528"},
    {"city_id":"9411","city_name":"Nova Esperanca","state_id":"528"},
    {"city_id":"9412","city_name":"Nova Londrina","state_id":"528"},
    {"city_id":"9413","city_name":"Ortigueira","state_id":"528"},
    {"city_id":"9414","city_name":"Paicandu","state_id":"528"},
    {"city_id":"9415","city_name":"Palmas","state_id":"528"},
    {"city_id":"9416","city_name":"Palmeira","state_id":"528"},
    {"city_id":"9417","city_name":"Palotina","state_id":"528"},
    {"city_id":"9418","city_name":"Paranagua","state_id":"528"},
    {"city_id":"9419","city_name":"Paranavai","state_id":"528"},
    {"city_id":"9420","city_name":"Pato Branco","state_id":"528"},
    {"city_id":"9421","city_name":"Peabiru","state_id":"528"},
    {"city_id":"9422","city_name":"Pinhais","state_id":"528"},
    {"city_id":"9423","city_name":"Pinhao","state_id":"528"},
    {"city_id":"9424","city_name":"Pirai do Sul","state_id":"528"},
    {"city_id":"9425","city_name":"Piraquara","state_id":"528"},
    {"city_id":"9426","city_name":"Pitanga","state_id":"528"},
    {"city_id":"9427","city_name":"Ponta Grossa","state_id":"528"},
    {"city_id":"9428","city_name":"Pontal do Parana","state_id":"528"},
    {"city_id":"9429","city_name":"Porecatu","state_id":"528"},
    {"city_id":"9430","city_name":"Primero de Maio","state_id":"528"},
    {"city_id":"9431","city_name":"Prudentopolis","state_id":"528"},
    {"city_id":"9432","city_name":"Quatro Barras","state_id":"528"},
    {"city_id":"9433","city_name":"Quedas do Iguacu","state_id":"528"},
    {"city_id":"9434","city_name":"Realeza","state_id":"528"},
    {"city_id":"9435","city_name":"Reserva","state_id":"528"},
    {"city_id":"9436","city_name":"Ribeirao do Pinhal","state_id":"528"},
    {"city_id":"9437","city_name":"Rio Branco do Sul","state_id":"528"},
    {"city_id":"9438","city_name":"Rio Negro","state_id":"528"},
    {"city_id":"9439","city_name":"Rolandia","state_id":"528"},
    {"city_id":"9440","city_name":"Santa Helena","state_id":"528"},
    {"city_id":"9441","city_name":"Santa Terezinha de Itaipu","state_id":"528"},
    {"city_id":"9442","city_name":"Santo Antonio da Platina","state_id":"528"},
    {"city_id":"9443","city_name":"Santo Antonio do Sudoeste","state_id":"528"},
    {"city_id":"9444","city_name":"Sao Joao do Ivai","state_id":"528"},
    {"city_id":"9445","city_name":"Sao Jose dos Pinhais","state_id":"528"},
    {"city_id":"9446","city_name":"Sao Mateus do Sul","state_id":"528"},
    {"city_id":"9447","city_name":"Sao Miguel do Iguacu","state_id":"528"},
    {"city_id":"9448","city_name":"Sarandi","state_id":"528"},
    {"city_id":"9449","city_name":"Senges","state_id":"528"},
    {"city_id":"9450","city_name":"Sertanopolis","state_id":"528"},
    {"city_id":"9451","city_name":"Siquera Campos","state_id":"528"},
    {"city_id":"9452","city_name":"Tapejara","state_id":"528"},
    {"city_id":"9453","city_name":"Telemaco Borba","state_id":"528"},
    {"city_id":"9454","city_name":"Terra Boa","state_id":"528"},
    {"city_id":"9455","city_name":"Terra Rica","state_id":"528"},
    {"city_id":"9456","city_name":"Terra Roxa","state_id":"528"},
    {"city_id":"9457","city_name":"Tibagi","state_id":"528"},
    {"city_id":"9458","city_name":"Toledo","state_id":"528"},
    {"city_id":"9459","city_name":"Ubirata","state_id":"528"},
    {"city_id":"9460","city_name":"Umuarama","state_id":"528"},
    {"city_id":"9461","city_name":"Uniao da Victoria","state_id":"528"},
    {"city_id":"9462","city_name":"Wenceslau Braz","state_id":"528"},
    {"city_id":"9463","city_name":"Abreu e Lima","state_id":"529"},
    {"city_id":"9464","city_name":"Afogados da Ingazeira","state_id":"529"},
    {"city_id":"9465","city_name":"Agrestina","state_id":"529"},
    {"city_id":"9466","city_name":"Agua Preta","state_id":"529"},
    {"city_id":"9467","city_name":"Aguas Belas","state_id":"529"},
    {"city_id":"9468","city_name":"Alianca","state_id":"529"},
    {"city_id":"9469","city_name":"Altinho","state_id":"529"},
    {"city_id":"9470","city_name":"Amaraji","state_id":"529"},
    {"city_id":"9471","city_name":"Aracoiaba","state_id":"529"},
    {"city_id":"9472","city_name":"Araripina","state_id":"529"},
    {"city_id":"9473","city_name":"Arcoverde","state_id":"529"},
    {"city_id":"9474","city_name":"Barra de Guabiraba","state_id":"529"},
    {"city_id":"9475","city_name":"Barreiros","state_id":"529"},
    {"city_id":"9476","city_name":"Belem de Sao Francisco","state_id":"529"},
    {"city_id":"9477","city_name":"Belo Jardim","state_id":"529"},
    {"city_id":"9478","city_name":"Bezerros","state_id":"529"},
    {"city_id":"9479","city_name":"Bodoco","state_id":"529"},
    {"city_id":"9480","city_name":"Bom Conselho","state_id":"529"},
    {"city_id":"9481","city_name":"Bom Jardim","state_id":"529"},
    {"city_id":"9482","city_name":"Bonito","state_id":"529"},
    {"city_id":"9483","city_name":"Brejo da Madre de Deus","state_id":"529"},
    {"city_id":"9484","city_name":"Buique","state_id":"529"},
    {"city_id":"9485","city_name":"Cabo de Santo Agostinho","state_id":"529"},
    {"city_id":"9486","city_name":"Cabrobo","state_id":"529"},
    {"city_id":"9487","city_name":"Cachoeirinha","state_id":"529"},
    {"city_id":"9488","city_name":"Caetes","state_id":"529"},
    {"city_id":"9489","city_name":"Camaragibe","state_id":"529"},
    {"city_id":"9490","city_name":"Camocim de Sao Felix","state_id":"529"},
    {"city_id":"9491","city_name":"Canhotinho","state_id":"529"},
    {"city_id":"9492","city_name":"Capoeiras","state_id":"529"},
    {"city_id":"9493","city_name":"Carnaiba","state_id":"529"},
    {"city_id":"9494","city_name":"Carpina","state_id":"529"},
    {"city_id":"9495","city_name":"Caruaru","state_id":"529"},
    {"city_id":"9496","city_name":"Catende","state_id":"529"},
    {"city_id":"9497","city_name":"Cha Grande","state_id":"529"},
    {"city_id":"9498","city_name":"Condado","state_id":"529"},
    {"city_id":"9499","city_name":"Cumaru","state_id":"529"},
    {"city_id":"9500","city_name":"Cupira","state_id":"529"},
    {"city_id":"9501","city_name":"Custodia","state_id":"529"},
    {"city_id":"9502","city_name":"Escada","state_id":"529"},
    {"city_id":"9503","city_name":"Exu","state_id":"529"},
    {"city_id":"9504","city_name":"Feira Nova","state_id":"529"},
    {"city_id":"9505","city_name":"Fernando de Noronha","state_id":"529"},
    {"city_id":"9506","city_name":"Flores","state_id":"529"},
    {"city_id":"9507","city_name":"Floresta","state_id":"529"},
    {"city_id":"9508","city_name":"Gameleira","state_id":"529"},
    {"city_id":"9509","city_name":"Garanhuns","state_id":"529"},
    {"city_id":"9510","city_name":"Gloria do Goita","state_id":"529"},
    {"city_id":"9511","city_name":"Goiana","state_id":"529"},
    {"city_id":"9512","city_name":"Gravata","state_id":"529"},
    {"city_id":"9513","city_name":"Ibimirim","state_id":"529"},
    {"city_id":"9514","city_name":"Igarassu","state_id":"529"},
    {"city_id":"9515","city_name":"Inaja","state_id":"529"},
    {"city_id":"9516","city_name":"Ipojuca","state_id":"529"},
    {"city_id":"9517","city_name":"Ipubi","state_id":"529"},
    {"city_id":"9518","city_name":"Itaiba","state_id":"529"},
    {"city_id":"9519","city_name":"Itamaraca","state_id":"529"},
    {"city_id":"9520","city_name":"Itambe","state_id":"529"},
    {"city_id":"9521","city_name":"Itapissuma","state_id":"529"},
    {"city_id":"9522","city_name":"Itaquitinga","state_id":"529"},
    {"city_id":"9523","city_name":"Jaboatao","state_id":"529"},
    {"city_id":"9524","city_name":"Joao Alfredo","state_id":"529"},
    {"city_id":"9525","city_name":"Joaquim Nabuco","state_id":"529"},
    {"city_id":"9526","city_name":"Lagoa do Itaenga","state_id":"529"},
    {"city_id":"9527","city_name":"Lajedo","state_id":"529"},
    {"city_id":"9528","city_name":"Limoeiro","state_id":"529"},
    {"city_id":"9529","city_name":"Macaparana","state_id":"529"},
    {"city_id":"9530","city_name":"Maraial","state_id":"529"},
    {"city_id":"9531","city_name":"Moreno","state_id":"529"},
    {"city_id":"9532","city_name":"Nazare da Mata","state_id":"529"},
    {"city_id":"9533","city_name":"Olinda","state_id":"529"},
    {"city_id":"9534","city_name":"Orobo","state_id":"529"},
    {"city_id":"9535","city_name":"Ouricuri","state_id":"529"},
    {"city_id":"9536","city_name":"Palmares","state_id":"529"},
    {"city_id":"9537","city_name":"Panelas","state_id":"529"},
    {"city_id":"9538","city_name":"Parnamirim","state_id":"529"},
    {"city_id":"9539","city_name":"Passira","state_id":"529"},
    {"city_id":"9540","city_name":"Paudalho","state_id":"529"},
    {"city_id":"9541","city_name":"Paulista","state_id":"529"},
    {"city_id":"9542","city_name":"Pedra","state_id":"529"},
    {"city_id":"9543","city_name":"Pesqueira","state_id":"529"},
    {"city_id":"9544","city_name":"Petrolandia","state_id":"529"},
    {"city_id":"9545","city_name":"Petrolina","state_id":"529"},
    {"city_id":"9546","city_name":"Pombos","state_id":"529"},
    {"city_id":"9547","city_name":"Quipapa","state_id":"529"},
    {"city_id":"9548","city_name":"Recife","state_id":"529"},
    {"city_id":"9549","city_name":"Ribeirao","state_id":"529"},
    {"city_id":"9550","city_name":"Rio Formoso","state_id":"529"},
    {"city_id":"9551","city_name":"Salgueiro","state_id":"529"},
    {"city_id":"9552","city_name":"Santa Cruz do Capibaribe","state_id":"529"},
    {"city_id":"9553","city_name":"Santa Maria da Boa Vista","state_id":"529"},
    {"city_id":"9554","city_name":"Sao Bento do Una","state_id":"529"},
    {"city_id":"9555","city_name":"Sao Caitano","state_id":"529"},
    {"city_id":"9556","city_name":"Sao Joao","state_id":"529"},
    {"city_id":"9557","city_name":"Sao Joaquim do Monte","state_id":"529"},
    {"city_id":"9558","city_name":"Sao Jose da Coroa Grande","state_id":"529"},
    {"city_id":"9559","city_name":"Sao Jose do Belmonte","state_id":"529"},
    {"city_id":"9560","city_name":"Sao Jose do Egito","state_id":"529"},
    {"city_id":"9561","city_name":"Sao Lourenco da Mata","state_id":"529"},
    {"city_id":"9562","city_name":"Serra Talhada","state_id":"529"},
    {"city_id":"9563","city_name":"Sertania","state_id":"529"},
    {"city_id":"9564","city_name":"Sirinhaem","state_id":"529"},
    {"city_id":"9565","city_name":"Surubim","state_id":"529"},
    {"city_id":"9566","city_name":"Tabira","state_id":"529"},
    {"city_id":"9567","city_name":"Tamandare","state_id":"529"},
    {"city_id":"9568","city_name":"Taquaritinga do Norte","state_id":"529"},
    {"city_id":"9569","city_name":"Timbauba","state_id":"529"},
    {"city_id":"9570","city_name":"Toritama","state_id":"529"},
    {"city_id":"9571","city_name":"Trindade","state_id":"529"},
    {"city_id":"9572","city_name":"Triunfo","state_id":"529"},
    {"city_id":"9573","city_name":"Tupanatinga","state_id":"529"},
    {"city_id":"9574","city_name":"Vicencia","state_id":"529"},
    {"city_id":"9575","city_name":"Vitoria de Santo Antao","state_id":"529"},
    {"city_id":"9576","city_name":"Agua Branca","state_id":"530"},
    {"city_id":"9577","city_name":"Alto Longa","state_id":"530"},
    {"city_id":"9578","city_name":"Altos","state_id":"530"},
    {"city_id":"9579","city_name":"Amarante","state_id":"530"},
    {"city_id":"9580","city_name":"Avelino Lopes","state_id":"530"},
    {"city_id":"9581","city_name":"Barras","state_id":"530"},
    {"city_id":"9582","city_name":"Batalha","state_id":"530"},
    {"city_id":"9583","city_name":"Beneditinos","state_id":"530"},
    {"city_id":"9584","city_name":"Bom Jesus","state_id":"530"},
    {"city_id":"9585","city_name":"Buriti dos Lopes","state_id":"530"},
    {"city_id":"9586","city_name":"Campo Maior","state_id":"530"},
    {"city_id":"9587","city_name":"Canto do Buriti","state_id":"530"},
    {"city_id":"9588","city_name":"Castelo do Piaui","state_id":"530"},
    {"city_id":"9589","city_name":"Cocal","state_id":"530"},
    {"city_id":"9590","city_name":"Corrente","state_id":"530"},
    {"city_id":"9591","city_name":"Demerval Lobao","state_id":"530"},
    {"city_id":"9592","city_name":"Elesbao Veloso","state_id":"530"},
    {"city_id":"9593","city_name":"Esperantina","state_id":"530"},
    {"city_id":"9594","city_name":"Floriano","state_id":"530"},
    {"city_id":"9595","city_name":"Gilbues","state_id":"530"},
    {"city_id":"9596","city_name":"Guadalupe","state_id":"530"},
    {"city_id":"9597","city_name":"Inhuma","state_id":"530"},
    {"city_id":"9598","city_name":"Itainopolis","state_id":"530"},
    {"city_id":"9599","city_name":"Itaueira","state_id":"530"},
    {"city_id":"9600","city_name":"Jaicos","state_id":"530"},
    {"city_id":"9601","city_name":"Joaquim Pires","state_id":"530"},
    {"city_id":"9602","city_name":"Jose de Freitas","state_id":"530"},
    {"city_id":"9603","city_name":"Luis Correia","state_id":"530"},
    {"city_id":"9604","city_name":"Luzilandia","state_id":"530"},
    {"city_id":"9605","city_name":"Matias Olimpio","state_id":"530"},
    {"city_id":"9606","city_name":"Miguel Alves","state_id":"530"},
    {"city_id":"9607","city_name":"Monsenhor Gil","state_id":"530"},
    {"city_id":"9608","city_name":"Oeiras","state_id":"530"},
    {"city_id":"9609","city_name":"Palmeirais","state_id":"530"},
    {"city_id":"9610","city_name":"Parnaiba","state_id":"530"},
    {"city_id":"9611","city_name":"Pedro II","state_id":"530"},
    {"city_id":"9612","city_name":"Picos","state_id":"530"},
    {"city_id":"9613","city_name":"Pimenteiras","state_id":"530"},
    {"city_id":"9614","city_name":"Pio IX","state_id":"530"},
    {"city_id":"9615","city_name":"Piracuruca","state_id":"530"},
    {"city_id":"9616","city_name":"Piripiri","state_id":"530"},
    {"city_id":"9617","city_name":"Porto","state_id":"530"},
    {"city_id":"9618","city_name":"Regeneracao","state_id":"530"},
    {"city_id":"9619","city_name":"Sao Joao do Piaui","state_id":"530"},
    {"city_id":"9620","city_name":"Sao Miguel do Tapuio","state_id":"530"},
    {"city_id":"9621","city_name":"Sao Pedro do Piaui","state_id":"530"},
    {"city_id":"9622","city_name":"Sao Raimundo Nonato","state_id":"530"},
    {"city_id":"9623","city_name":"Simoes","state_id":"530"},
    {"city_id":"9624","city_name":"Simplicio Mendes","state_id":"530"},
    {"city_id":"9625","city_name":"Teresina","state_id":"530"},
    {"city_id":"9626","city_name":"Uniao","state_id":"530"},
    {"city_id":"9627","city_name":"Urucui","state_id":"530"},
    {"city_id":"9628","city_name":"Valenca do Piaui","state_id":"530"},
    {"city_id":"9629","city_name":"Alta Floresta d'Oeste","state_id":"534"},
    {"city_id":"9630","city_name":"Alto Alegre do Parecis","state_id":"534"},
    {"city_id":"9631","city_name":"Alto Paraiso","state_id":"534"},
    {"city_id":"9632","city_name":"Alvorada d'Oeste","state_id":"534"},
    {"city_id":"9633","city_name":"Ariquemes","state_id":"534"},
    {"city_id":"9634","city_name":"Buritis","state_id":"534"},
    {"city_id":"9635","city_name":"Cacoal","state_id":"534"},
    {"city_id":"9636","city_name":"Candeias do Jamari","state_id":"534"},
    {"city_id":"9637","city_name":"Cerejeiras","state_id":"534"},
    {"city_id":"9638","city_name":"Colorado do Oeste","state_id":"534"},
    {"city_id":"9639","city_name":"Corumbiara","state_id":"534"},
    {"city_id":"9640","city_name":"Espigao d'Oeste","state_id":"534"},
    {"city_id":"9641","city_name":"Governador Jorge Teixeira","state_id":"534"},
    {"city_id":"9642","city_name":"Guajara-Mirim","state_id":"534"},
    {"city_id":"9643","city_name":"Jaru","state_id":"534"},
    {"city_id":"9644","city_name":"Ji-Parana","state_id":"534"},
    {"city_id":"9645","city_name":"Machadinho d'Oeste","state_id":"534"},
    {"city_id":"9646","city_name":"Ministro Andreazza","state_id":"534"},
    {"city_id":"9647","city_name":"Mirante da Serra","state_id":"534"},
    {"city_id":"9648","city_name":"Nova Brasilandia d'Oeste","state_id":"534"},
    {"city_id":"9649","city_name":"Nova Mamore","state_id":"534"},
    {"city_id":"9650","city_name":"Novo Horizonte do Oeste","state_id":"534"},
    {"city_id":"9651","city_name":"Ouro Preto do Oeste","state_id":"534"},
    {"city_id":"9652","city_name":"Pimenta Bueno","state_id":"534"},
    {"city_id":"9653","city_name":"Porto Velho","state_id":"534"},
    {"city_id":"9654","city_name":"Presidente Medici","state_id":"534"},
    {"city_id":"9655","city_name":"Rolim de Moura","state_id":"534"},
    {"city_id":"9656","city_name":"Santa Luzia d'Oeste","state_id":"534"},
    {"city_id":"9657","city_name":"Sao Miguel do Guapore","state_id":"534"},
    {"city_id":"9658","city_name":"Urupa","state_id":"534"},
    {"city_id":"9659","city_name":"Vale do Paraiso","state_id":"534"},
    {"city_id":"9660","city_name":"Vilhena","state_id":"534"},
    {"city_id":"9661","city_name":"Alto Alegre","state_id":"535"},
    {"city_id":"9662","city_name":"Boa Vista","state_id":"535"},
    {"city_id":"9663","city_name":"Bonfim","state_id":"535"},
    {"city_id":"9664","city_name":"Caracarai","state_id":"535"},
    {"city_id":"9665","city_name":"Mucajai","state_id":"535"},
    {"city_id":"9666","city_name":"Normandia","state_id":"535"},
    {"city_id":"9667","city_name":"Sao Joao da Baliza","state_id":"535"},
    {"city_id":"9668","city_name":"Sao Luiz","state_id":"535"},
    {"city_id":"9669","city_name":"Aquidaba","state_id":"538"},
    {"city_id":"9670","city_name":"Aracaju","state_id":"538"},
    {"city_id":"9671","city_name":"Araua","state_id":"538"},
    {"city_id":"9672","city_name":"Areia Branca","state_id":"538"},
    {"city_id":"9673","city_name":"Barra dos Coqueiros","state_id":"538"},
    {"city_id":"9674","city_name":"Boquim","state_id":"538"},
    {"city_id":"9675","city_name":"Campo do Brito","state_id":"538"},
    {"city_id":"9676","city_name":"Caninde de Sao Francisco","state_id":"538"},
    {"city_id":"9677","city_name":"Capela","state_id":"538"},
    {"city_id":"9678","city_name":"Carira","state_id":"538"},
    {"city_id":"9679","city_name":"Cristinapolis","state_id":"538"},
    {"city_id":"9680","city_name":"Estancia","state_id":"538"},
    {"city_id":"9681","city_name":"Frei Paulo","state_id":"538"},
    {"city_id":"9682","city_name":"Gararu","state_id":"538"},
    {"city_id":"9683","city_name":"Indiaroba","state_id":"538"},
    {"city_id":"9684","city_name":"Itabaiana","state_id":"538"},
    {"city_id":"9685","city_name":"Itabaianinha","state_id":"538"},
    {"city_id":"9686","city_name":"Itaporanga d'Ajuda","state_id":"538"},
    {"city_id":"9687","city_name":"Japaratuba","state_id":"538"},
    {"city_id":"9688","city_name":"Japoata","state_id":"538"},
    {"city_id":"9689","city_name":"Lagarto","state_id":"538"},
    {"city_id":"9690","city_name":"Laranjeiras","state_id":"538"},
    {"city_id":"9691","city_name":"Malhador","state_id":"538"},
    {"city_id":"9692","city_name":"Maruim","state_id":"538"},
    {"city_id":"9693","city_name":"Moita Bonita","state_id":"538"},
    {"city_id":"9694","city_name":"Monte Alegre de Sergipe","state_id":"538"},
    {"city_id":"9695","city_name":"Neopolis","state_id":"538"},
    {"city_id":"9696","city_name":"Nossa Senhora da Gloria","state_id":"538"},
    {"city_id":"9697","city_name":"Nossa Senhora das Dores","state_id":"538"},
    {"city_id":"9698","city_name":"Nossa Senhora do Socorro","state_id":"538"},
    {"city_id":"9699","city_name":"Pacatuba","state_id":"538"},
    {"city_id":"9700","city_name":"Poco Verde","state_id":"538"},
    {"city_id":"9701","city_name":"Porto da Folha","state_id":"538"},
    {"city_id":"9702","city_name":"Propria","state_id":"538"},
    {"city_id":"9703","city_name":"Riachao do Dantas","state_id":"538"},
    {"city_id":"9704","city_name":"Ribeiropolis","state_id":"538"},
    {"city_id":"9705","city_name":"Salgado","state_id":"538"},
    {"city_id":"9706","city_name":"Santa Luzia do Itanhy","state_id":"538"},
    {"city_id":"9707","city_name":"Santo Amaro das Brotas","state_id":"538"},
    {"city_id":"9708","city_name":"Sao Cristovao","state_id":"538"},
    {"city_id":"9709","city_name":"Simao Dias","state_id":"538"},
    {"city_id":"9710","city_name":"Tobias Barreto","state_id":"538"},
    {"city_id":"9711","city_name":"Tomar do Geru","state_id":"538"},
    {"city_id":"9712","city_name":"Umbauba","state_id":"538"},
    {"city_id":"9713","city_name":"Alvorada","state_id":"539"},
    {"city_id":"9714","city_name":"Ananas","state_id":"539"},
    {"city_id":"9715","city_name":"Araguacu","state_id":"539"},
    {"city_id":"9716","city_name":"Araguaina","state_id":"539"},
    {"city_id":"9717","city_name":"Araguatins","state_id":"539"},
    {"city_id":"9718","city_name":"Arraias","state_id":"539"},
    {"city_id":"9719","city_name":"Augustinopolis","state_id":"539"},
    {"city_id":"9720","city_name":"Axixa do Tocantins","state_id":"539"},
    {"city_id":"9721","city_name":"Colinas do Tocantins","state_id":"539"},
    {"city_id":"9722","city_name":"Dianopolis","state_id":"539"},
    {"city_id":"9723","city_name":"Formoso do Araguaia","state_id":"539"},
    {"city_id":"9724","city_name":"Goiatins","state_id":"539"},
    {"city_id":"9725","city_name":"Guarai","state_id":"539"},
    {"city_id":"9726","city_name":"Gurupi","state_id":"539"},
    {"city_id":"9727","city_name":"Miracema do Tocantins","state_id":"539"},
    {"city_id":"9728","city_name":"Miranorte","state_id":"539"},
    {"city_id":"9729","city_name":"Palmas","state_id":"539"},
    {"city_id":"9730","city_name":"Paraiso","state_id":"539"},
    {"city_id":"9731","city_name":"Parana","state_id":"539"},
    {"city_id":"9732","city_name":"Porto Nacional","state_id":"539"},
    {"city_id":"9733","city_name":"Sitio Novo do Tocantins","state_id":"539"},
    {"city_id":"9734","city_name":"Taguatinga","state_id":"539"},
    {"city_id":"9735","city_name":"Tocantinopolis","state_id":"539"},
    {"city_id":"9736","city_name":"Wanderlandia","state_id":"539"},
    {"city_id":"9737","city_name":"Xambioa","state_id":"539"},
    {"city_id":"9738","city_name":"Kuala Belait","state_id":"541"},
    {"city_id":"9739","city_name":"Seria","state_id":"541"},
    {"city_id":"9740","city_name":"Bandar Seri Begawan","state_id":"542"},
    {"city_id":"9741","city_name":"Bangar","state_id":"543"},
    {"city_id":"9742","city_name":"Tutong","state_id":"544"},
    {"city_id":"9743","city_name":"Bansko","state_id":"545"},
    {"city_id":"9744","city_name":"Belica","state_id":"545"},
    {"city_id":"9745","city_name":"Blagoevgrad","state_id":"545"},
    {"city_id":"9746","city_name":"Goce Delchev","state_id":"545"},
    {"city_id":"9747","city_name":"Hadzhidimovo","state_id":"545"},
    {"city_id":"9748","city_name":"Jakoruda","state_id":"545"},
    {"city_id":"9749","city_name":"Kresna","state_id":"545"},
    {"city_id":"9750","city_name":"Melnik","state_id":"545"},
    {"city_id":"9751","city_name":"Petrich","state_id":"545"},
    {"city_id":"9752","city_name":"Razlog","state_id":"545"},
    {"city_id":"9753","city_name":"Sandanski","state_id":"545"},
    {"city_id":"9754","city_name":"Simitli","state_id":"545"},
    {"city_id":"9755","city_name":"Ahtopol","state_id":"546"},
    {"city_id":"9756","city_name":"Ajtos","state_id":"546"},
    {"city_id":"9757","city_name":"Balgarovo","state_id":"546"},
    {"city_id":"9758","city_name":"Bourgas","state_id":"546"},
    {"city_id":"9759","city_name":"Burgas","state_id":"546"},
    {"city_id":"9760","city_name":"Carevo","state_id":"546"},
    {"city_id":"9761","city_name":"Kableshkovo","state_id":"546"},
    {"city_id":"9762","city_name":"Kameno","state_id":"546"},
    {"city_id":"9763","city_name":"Karnobat","state_id":"546"},
    {"city_id":"9764","city_name":"Malko Tarnovo","state_id":"546"},
    {"city_id":"9765","city_name":"Nesebar","state_id":"546"},
    {"city_id":"9766","city_name":"Obzor","state_id":"546"},
    {"city_id":"9767","city_name":"Pomorie","state_id":"546"},
    {"city_id":"9768","city_name":"Primorsko","state_id":"546"},
    {"city_id":"9769","city_name":"Sozopol","state_id":"546"},
    {"city_id":"9770","city_name":"Sredec","state_id":"546"},
    {"city_id":"9771","city_name":"Sungurlare","state_id":"546"},
    {"city_id":"9772","city_name":"Tvardica","state_id":"546"},
    {"city_id":"9773","city_name":"Balchik","state_id":"547"},
    {"city_id":"9774","city_name":"Dobrich","state_id":"547"},
    {"city_id":"9775","city_name":"General-Toshevo","state_id":"547"},
    {"city_id":"9776","city_name":"Kavarna","state_id":"547"},
    {"city_id":"9777","city_name":"Loznica","state_id":"547"},
    {"city_id":"9778","city_name":"Shabla","state_id":"547"},
    {"city_id":"9779","city_name":"Tervel","state_id":"547"},
    {"city_id":"9780","city_name":"Drjanovo","state_id":"548"},
    {"city_id":"9781","city_name":"Gabrovo","state_id":"548"},
    {"city_id":"9782","city_name":"Plachkovci","state_id":"548"},
    {"city_id":"9783","city_name":"Sevlievo","state_id":"548"},
    {"city_id":"9784","city_name":"Trjavna","state_id":"548"},
    {"city_id":"9785","city_name":"Dimitrovgrad","state_id":"549"},
    {"city_id":"9786","city_name":"Harmanli","state_id":"549"},
    {"city_id":"9787","city_name":"Haskovo","state_id":"549"},
    {"city_id":"9788","city_name":"Ivajlovgrad","state_id":"549"},
    {"city_id":"9789","city_name":"Ljubimec","state_id":"549"},
    {"city_id":"9790","city_name":"Madzharovo","state_id":"549"},
    {"city_id":"9791","city_name":"Merichleri","state_id":"549"},
    {"city_id":"9792","city_name":"Simeonovgrad","state_id":"549"},
    {"city_id":"9793","city_name":"Svilengrad","state_id":"549"},
    {"city_id":"9794","city_name":"Boljarovo","state_id":"550"},
    {"city_id":"9795","city_name":"Elhovo","state_id":"550"},
    {"city_id":"9796","city_name":"Jambol","state_id":"550"},
    {"city_id":"9797","city_name":"Straldzha","state_id":"550"},
    {"city_id":"9798","city_name":"Topolovgrad","state_id":"550"},
    {"city_id":"9799","city_name":"Ardino","state_id":"551"},
    {"city_id":"9800","city_name":"Dzhebel","state_id":"551"},
    {"city_id":"9801","city_name":"Kardzhali","state_id":"551"},
    {"city_id":"9802","city_name":"Krumovgrad","state_id":"551"},
    {"city_id":"9803","city_name":"Momchilgrad","state_id":"551"},
    {"city_id":"9804","city_name":"Boboshevo","state_id":"552"},
    {"city_id":"9805","city_name":"Bobovdol","state_id":"552"},
    {"city_id":"9806","city_name":"Dupnica","state_id":"552"},
    {"city_id":"9807","city_name":"Kjustendil","state_id":"552"},
    {"city_id":"9808","city_name":"Kocherinovo","state_id":"552"},
    {"city_id":"9809","city_name":"Rila","state_id":"552"},
    {"city_id":"9810","city_name":"Sapareva Banja","state_id":"552"},
    {"city_id":"9811","city_name":"Zemen","state_id":"552"},
    {"city_id":"9812","city_name":"Aprilci","state_id":"553"},
    {"city_id":"9813","city_name":"Jablanica","state_id":"553"},
    {"city_id":"9814","city_name":"Letnica","state_id":"553"},
    {"city_id":"9815","city_name":"Lovech","state_id":"553"},
    {"city_id":"9816","city_name":"Lukovit","state_id":"553"},
    {"city_id":"9817","city_name":"Sopot","state_id":"553"},
    {"city_id":"9818","city_name":"Teteven","state_id":"553"},
    {"city_id":"9819","city_name":"Trojan","state_id":"553"},
    {"city_id":"9820","city_name":"Ugarchin","state_id":"553"},
    {"city_id":"9821","city_name":"Berkovica","state_id":"554"},
    {"city_id":"9822","city_name":"Bojchinovci","state_id":"554"},
    {"city_id":"9823","city_name":"Brusarci","state_id":"554"},
    {"city_id":"9824","city_name":"Chiprovci","state_id":"554"},
    {"city_id":"9825","city_name":"Lom","state_id":"554"},
    {"city_id":"9826","city_name":"Montana","state_id":"554"},
    {"city_id":"9827","city_name":"Valchedram","state_id":"554"},
    {"city_id":"9828","city_name":"Varshec","state_id":"554"},
    {"city_id":"9829","city_name":"Batak","state_id":"556"},
    {"city_id":"9830","city_name":"Belovo","state_id":"556"},
    {"city_id":"9831","city_name":"Bracigovo","state_id":"556"},
    {"city_id":"9832","city_name":"Koprivshtica","state_id":"556"},
    {"city_id":"9833","city_name":"Panagjurishte","state_id":"556"},
    {"city_id":"9834","city_name":"Pazardzhik","state_id":"556"},
    {"city_id":"9835","city_name":"Peshtera","state_id":"556"},
    {"city_id":"9836","city_name":"Rakitovo","state_id":"556"},
    {"city_id":"9837","city_name":"Septemvri","state_id":"556"},
    {"city_id":"9838","city_name":"Strelcha","state_id":"556"},
    {"city_id":"9839","city_name":"Velingrad","state_id":"556"},
    {"city_id":"9840","city_name":"Bankja","state_id":"557"},
    {"city_id":"9841","city_name":"Batanovci","state_id":"557"},
    {"city_id":"9842","city_name":"Breznik","state_id":"557"},
    {"city_id":"9843","city_name":"Pernik","state_id":"557"},
    {"city_id":"9844","city_name":"Radomir","state_id":"557"},
    {"city_id":"9845","city_name":"Tran","state_id":"557"},
    {"city_id":"9846","city_name":"Belene","state_id":"558"},
    {"city_id":"9847","city_name":"Cherven Brjag","state_id":"558"},
    {"city_id":"9848","city_name":"Dolna Mitropolija","state_id":"558"},
    {"city_id":"9849","city_name":"Dolni Dabnik","state_id":"558"},
    {"city_id":"9850","city_name":"Guljanci","state_id":"558"},
    {"city_id":"9851","city_name":"Levski","state_id":"558"},
    {"city_id":"9852","city_name":"Nikopol","state_id":"558"},
    {"city_id":"9853","city_name":"Pleven","state_id":"558"},
    {"city_id":"9854","city_name":"Pordim","state_id":"558"},
    {"city_id":"9855","city_name":"Slavjanovo","state_id":"558"},
    {"city_id":"9856","city_name":"Trashtenik","state_id":"558"},
    {"city_id":"9857","city_name":"Varbica","state_id":"558"},
    {"city_id":"9858","city_name":"Asenovgrad","state_id":"559"},
    {"city_id":"9859","city_name":"Brezovo","state_id":"559"},
    {"city_id":"9860","city_name":"Car Kalojan","state_id":"559"},
    {"city_id":"9861","city_name":"Hisarja","state_id":"559"},
    {"city_id":"9862","city_name":"Kalofer","state_id":"559"},
    {"city_id":"9863","city_name":"Karlovo","state_id":"559"},
    {"city_id":"9864","city_name":"Klisura","state_id":"559"},
    {"city_id":"9865","city_name":"Krichim","state_id":"559"},
    {"city_id":"9866","city_name":"Parvomaj","state_id":"559"},
    {"city_id":"9867","city_name":"Perushtica","state_id":"559"},
    {"city_id":"9868","city_name":"Plovdiv","state_id":"559"},
    {"city_id":"9869","city_name":"Rakovski","state_id":"559"},
    {"city_id":"9870","city_name":"Sadovo","state_id":"559"},
    {"city_id":"9871","city_name":"Saedinenie","state_id":"559"},
    {"city_id":"9872","city_name":"Stambolijski","state_id":"559"},
    {"city_id":"9873","city_name":"Isperih","state_id":"560"},
    {"city_id":"9874","city_name":"Kubrat","state_id":"560"},
    {"city_id":"9875","city_name":"Razgrad","state_id":"560"},
    {"city_id":"9876","city_name":"Senovo","state_id":"560"},
    {"city_id":"9877","city_name":"Zavet","state_id":"560"},
    {"city_id":"9878","city_name":"Bjala","state_id":"561"},
    {"city_id":"9879","city_name":"Borovo","state_id":"561"},
    {"city_id":"9880","city_name":"Dve Mogili","state_id":"561"},
    {"city_id":"9881","city_name":"Ruse","state_id":"561"},
    {"city_id":"9882","city_name":"Russe","state_id":"561"},
    {"city_id":"9883","city_name":"Vetovo","state_id":"561"},
    {"city_id":"9884","city_name":"Kaolinovo","state_id":"562"},
    {"city_id":"9885","city_name":"Kaspichan","state_id":"562"},
    {"city_id":"9886","city_name":"Novi Pazar","state_id":"562"},
    {"city_id":"9887","city_name":"Pliska","state_id":"562"},
    {"city_id":"9888","city_name":"Shumen","state_id":"562"},
    {"city_id":"9889","city_name":"Smjadovo","state_id":"562"},
    {"city_id":"9890","city_name":"Veliki Preslav","state_id":"562"},
    {"city_id":"9891","city_name":"Alfatar","state_id":"563"},
    {"city_id":"9892","city_name":"Dulovo","state_id":"563"},
    {"city_id":"9893","city_name":"Glavinica","state_id":"563"},
    {"city_id":"9894","city_name":"Silistra","state_id":"563"},
    {"city_id":"9895","city_name":"Tutrakan","state_id":"563"},
    {"city_id":"9896","city_name":"Kermen","state_id":"564"},
    {"city_id":"9897","city_name":"Kotel","state_id":"564"},
    {"city_id":"9898","city_name":"Nova Zagora","state_id":"564"},
    {"city_id":"9899","city_name":"Shivachevo","state_id":"564"},
    {"city_id":"9900","city_name":"Sliven","state_id":"564"},
    {"city_id":"9901","city_name":"Chepelare","state_id":"565"},
    {"city_id":"9902","city_name":"Devin","state_id":"565"},
    {"city_id":"9903","city_name":"Dospat","state_id":"565"},
    {"city_id":"9904","city_name":"Laki","state_id":"565"},
    {"city_id":"9905","city_name":"Madan","state_id":"565"},
    {"city_id":"9906","city_name":"Nedelino","state_id":"565"},
    {"city_id":"9907","city_name":"Rudozem","state_id":"565"},
    {"city_id":"9908","city_name":"Smoljan","state_id":"565"},
    {"city_id":"9909","city_name":"Zlatograd","state_id":"565"},
    {"city_id":"9910","city_name":"Antonovo","state_id":"569"},
    {"city_id":"9911","city_name":"Omurtag","state_id":"569"},
    {"city_id":"9912","city_name":"Opaka","state_id":"569"},
    {"city_id":"9913","city_name":"Popovo","state_id":"569"},
    {"city_id":"9914","city_name":"Targovishte","state_id":"569"},
    {"city_id":"9915","city_name":"Beloslav","state_id":"570"},
    {"city_id":"9916","city_name":"Bjala","state_id":"570"},
    {"city_id":"9917","city_name":"Dalgopol","state_id":"570"},
    {"city_id":"9918","city_name":"Devnja","state_id":"570"},
    {"city_id":"9919","city_name":"Iskar","state_id":"570"},
    {"city_id":"9920","city_name":"Provadija","state_id":"570"},
    {"city_id":"9921","city_name":"Suvorovo","state_id":"570"},
    {"city_id":"9922","city_name":"Valchi Dol","state_id":"570"},
    {"city_id":"9923","city_name":"Varna","state_id":"570"},
    {"city_id":"9924","city_name":"Belogradchik","state_id":"572"},
    {"city_id":"9925","city_name":"Bregovo","state_id":"572"},
    {"city_id":"9926","city_name":"Dimovo","state_id":"572"},
    {"city_id":"9927","city_name":"Dolni Chiflik","state_id":"572"},
    {"city_id":"9928","city_name":"Dunavci","state_id":"572"},
    {"city_id":"9929","city_name":"Gramada","state_id":"572"},
    {"city_id":"9930","city_name":"Kula","state_id":"572"},
    {"city_id":"9931","city_name":"Vidin","state_id":"572"},
    {"city_id":"9932","city_name":"Bjala Slatina","state_id":"573"},
    {"city_id":"9933","city_name":"Knezha","state_id":"573"},
    {"city_id":"9934","city_name":"Kojnare","state_id":"573"},
    {"city_id":"9935","city_name":"Kozloduj","state_id":"573"},
    {"city_id":"9936","city_name":"Krivodol","state_id":"573"},
    {"city_id":"9937","city_name":"Mezdra","state_id":"573"},
    {"city_id":"9938","city_name":"Mizija","state_id":"573"},
    {"city_id":"9939","city_name":"Orjahovo","state_id":"573"},
    {"city_id":"9940","city_name":"Roman","state_id":"573"},
    {"city_id":"9941","city_name":"Vraca","state_id":"573"},
    {"city_id":"9942","city_name":"Yablaniza","state_id":"574"},
    {"city_id":"9943","city_name":"Boromo","state_id":"575"},
    {"city_id":"9944","city_name":"Kongoussi","state_id":"576"},
    {"city_id":"9945","city_name":"Kombissiri","state_id":"577"},
    {"city_id":"9946","city_name":"Diebougou","state_id":"578"},
    {"city_id":"9947","city_name":"Pa","state_id":"578"},
    {"city_id":"9948","city_name":"Garango","state_id":"579"},
    {"city_id":"9949","city_name":"Tenkodogo","state_id":"579"},
    {"city_id":"9950","city_name":"Koudougou","state_id":"580"},
    {"city_id":"9951","city_name":"Banfora","state_id":"581"},
    {"city_id":"9952","city_name":"Zorgo","state_id":"582"},
    {"city_id":"9953","city_name":"Bogande","state_id":"583"},
    {"city_id":"9954","city_name":"Fada N'gourma","state_id":"584"},
    {"city_id":"9955","city_name":"Bekuy","state_id":"585"},
    {"city_id":"9956","city_name":"Bobo Dioulasso","state_id":"585"},
    {"city_id":"9957","city_name":"Dano","state_id":"586"},
    {"city_id":"9958","city_name":"Ouagadougou","state_id":"587"},
    {"city_id":"9959","city_name":"Koalla","state_id":"588"},
    {"city_id":"9960","city_name":"Koloko","state_id":"588"},
    {"city_id":"9961","city_name":"Orodara","state_id":"588"},
    {"city_id":"9962","city_name":"Gayeri","state_id":"589"},
    {"city_id":"9963","city_name":"Pama","state_id":"590"},
    {"city_id":"9964","city_name":"Nouna","state_id":"591"},
    {"city_id":"9965","city_name":"Koupela","state_id":"592"},
    {"city_id":"9966","city_name":"Bousse","state_id":"593"},
    {"city_id":"9967","city_name":"Sindou","state_id":"594"},
    {"city_id":"9968","city_name":"Dedougou","state_id":"595"},
    {"city_id":"9969","city_name":"Po","state_id":"596"},
    {"city_id":"9970","city_name":"Boulsa","state_id":"597"},
    {"city_id":"9971","city_name":"Batie","state_id":"598"},
    {"city_id":"9972","city_name":"Ziniare","state_id":"599"},
    {"city_id":"9973","city_name":"Gorom-Gorom","state_id":"600"},
    {"city_id":"9974","city_name":"Yako","state_id":"601"},
    {"city_id":"9975","city_name":"Gaoua","state_id":"602"},
    {"city_id":"9976","city_name":"Kampti","state_id":"602"},
    {"city_id":"9977","city_name":"Loropeni","state_id":"602"},
    {"city_id":"9978","city_name":"Reo","state_id":"603"},
    {"city_id":"9979","city_name":"Kaya","state_id":"604"},
    {"city_id":"9980","city_name":"Dori","state_id":"605"},
    {"city_id":"9981","city_name":"Gao","state_id":"606"},
    {"city_id":"9982","city_name":"Leo","state_id":"606"},
    {"city_id":"9983","city_name":"Aribinda","state_id":"607"},
    {"city_id":"9984","city_name":"Djibo","state_id":"607"},
    {"city_id":"9985","city_name":"Louta","state_id":"608"},
    {"city_id":"9986","city_name":"Tougan","state_id":"608"},
    {"city_id":"9987","city_name":"Diapaga","state_id":"609"},
    {"city_id":"9988","city_name":"Kantchari","state_id":"609"},
    {"city_id":"9989","city_name":"Hounde","state_id":"610"},
    {"city_id":"9990","city_name":"Ouahigouya","state_id":"611"},
    {"city_id":"9991","city_name":"Gourcy","state_id":"612"},
    {"city_id":"9992","city_name":"Manga","state_id":"613"},
    {"city_id":"9993","city_name":"Bubanza","state_id":"614"},
    {"city_id":"9994","city_name":"Bujumbura","state_id":"615"},
    {"city_id":"9995","city_name":"Bururi","state_id":"616"},
    {"city_id":"9996","city_name":"Cankuzo","state_id":"617"},
    {"city_id":"9997","city_name":"Cibitoke","state_id":"618"},
    {"city_id":"9998","city_name":"Gitega","state_id":"619"},
    {"city_id":"9999","city_name":"Karuzi","state_id":"620"},
    {"city_id":"10000","city_name":"Kayanza","state_id":"621"},
    {"city_id":"10001","city_name":"Kirundo","state_id":"622"},
    {"city_id":"10002","city_name":"Makamba","state_id":"623"},
    {"city_id":"10003","city_name":"Muramvya","state_id":"624"},
    {"city_id":"10004","city_name":"Muyinga","state_id":"625"},
    {"city_id":"10005","city_name":"Ngozi","state_id":"626"},
    {"city_id":"10006","city_name":"Rutana","state_id":"627"},
    {"city_id":"10007","city_name":"Ruyigi","state_id":"628"},
    {"city_id":"10008","city_name":"Kampot","state_id":"635"},
    {"city_id":"10009","city_name":"Ta Khmau","state_id":"636"},
    {"city_id":"10010","city_name":"Kracheh","state_id":"638"},
    {"city_id":"10011","city_name":"Pousat","state_id":"645"},
    {"city_id":"10012","city_name":"Phumi Takaev","state_id":"652"},
    {"city_id":"10013","city_name":"Banyo","state_id":"653"},
    {"city_id":"10014","city_name":"Meiganga","state_id":"653"},
    {"city_id":"10015","city_name":"Ngaoundere","state_id":"653"},
    {"city_id":"10016","city_name":"Tibati","state_id":"653"},
    {"city_id":"10017","city_name":"Tignere","state_id":"653"},
    {"city_id":"10018","city_name":"Akonolinga","state_id":"654"},
    {"city_id":"10019","city_name":"Bafia","state_id":"654"},
    {"city_id":"10020","city_name":"Eseka","state_id":"654"},
    {"city_id":"10021","city_name":"Mbalmayo","state_id":"654"},
    {"city_id":"10022","city_name":"Mfou","state_id":"654"},
    {"city_id":"10023","city_name":"Monatele","state_id":"654"},
    {"city_id":"10024","city_name":"Nanga Eboko","state_id":"654"},
    {"city_id":"10025","city_name":"Obala","state_id":"654"},
    {"city_id":"10026","city_name":"Ombesa","state_id":"654"},
    {"city_id":"10027","city_name":"Saa","state_id":"654"},
    {"city_id":"10028","city_name":"Yaounde","state_id":"654"},
    {"city_id":"10029","city_name":"Abong Mbang","state_id":"655"},
    {"city_id":"10030","city_name":"Batouri","state_id":"655"},
    {"city_id":"10031","city_name":"Bertoua","state_id":"655"},
    {"city_id":"10032","city_name":"Betare Oya","state_id":"655"},
    {"city_id":"10033","city_name":"Djoum","state_id":"655"},
    {"city_id":"10034","city_name":"Doume","state_id":"655"},
    {"city_id":"10035","city_name":"Lomie","state_id":"655"},
    {"city_id":"10036","city_name":"Yokadouma","state_id":"655"},
    {"city_id":"10037","city_name":"Bonaberi","state_id":"656"},
    {"city_id":"10038","city_name":"Dibombari","state_id":"656"},
    {"city_id":"10039","city_name":"Douala","state_id":"656"},
    {"city_id":"10040","city_name":"Edea","state_id":"656"},
    {"city_id":"10041","city_name":"Loum","state_id":"656"},
    {"city_id":"10042","city_name":"Manjo","state_id":"656"},
    {"city_id":"10043","city_name":"Mbanga","state_id":"656"},
    {"city_id":"10044","city_name":"Nkongsamba","state_id":"656"},
    {"city_id":"10045","city_name":"Yabassi","state_id":"656"},
    {"city_id":"10046","city_name":"Figuif","state_id":"657"},
    {"city_id":"10047","city_name":"Garoua","state_id":"657"},
    {"city_id":"10048","city_name":"Guider","state_id":"657"},
    {"city_id":"10049","city_name":"Lagdo","state_id":"657"},
    {"city_id":"10050","city_name":"Poli","state_id":"657"},
    {"city_id":"10051","city_name":"Rey Bouba","state_id":"657"},
    {"city_id":"10052","city_name":"Tchollire","state_id":"657"},
    {"city_id":"10053","city_name":"Figuif","state_id":"658"},
    {"city_id":"10054","city_name":"Garoua","state_id":"658"},
    {"city_id":"10055","city_name":"Guider","state_id":"658"},
    {"city_id":"10056","city_name":"Lagdo","state_id":"658"},
    {"city_id":"10057","city_name":"Poli","state_id":"658"},
    {"city_id":"10058","city_name":"Rey Bouba","state_id":"658"},
    {"city_id":"10059","city_name":"Tchollire","state_id":"658"},
    {"city_id":"10060","city_name":"Bamenda","state_id":"659"},
    {"city_id":"10061","city_name":"Kumbo","state_id":"659"},
    {"city_id":"10062","city_name":"Mbengwi","state_id":"659"},
    {"city_id":"10063","city_name":"Mme","state_id":"659"},
    {"city_id":"10064","city_name":"Njinikom","state_id":"659"},
    {"city_id":"10065","city_name":"Nkambe","state_id":"659"},
    {"city_id":"10066","city_name":"Wum","state_id":"659"},
    {"city_id":"10067","city_name":"Bafang","state_id":"660"},
    {"city_id":"10068","city_name":"Bafoussam","state_id":"660"},
    {"city_id":"10069","city_name":"Bafut","state_id":"660"},
    {"city_id":"10070","city_name":"Bali","state_id":"660"},
    {"city_id":"10071","city_name":"Bana","state_id":"660"},
    {"city_id":"10072","city_name":"Bangangte","state_id":"660"},
    {"city_id":"10073","city_name":"Djang","state_id":"660"},
    {"city_id":"10074","city_name":"Fontem","state_id":"660"},
    {"city_id":"10075","city_name":"Foumban","state_id":"660"},
    {"city_id":"10076","city_name":"Foumbot","state_id":"660"},
    {"city_id":"10077","city_name":"Mbouda","state_id":"660"},
    {"city_id":"10078","city_name":"Akom","state_id":"661"},
    {"city_id":"10079","city_name":"Ambam","state_id":"661"},
    {"city_id":"10080","city_name":"Ebolowa","state_id":"661"},
    {"city_id":"10081","city_name":"Kribi","state_id":"661"},
    {"city_id":"10082","city_name":"Lolodorf","state_id":"661"},
    {"city_id":"10083","city_name":"Moloundou","state_id":"661"},
    {"city_id":"10084","city_name":"Mvangue","state_id":"661"},
    {"city_id":"10085","city_name":"Sangmelima","state_id":"661"},
    {"city_id":"10086","city_name":"Buea","state_id":"662"},
    {"city_id":"10087","city_name":"Idenao","state_id":"662"},
    {"city_id":"10088","city_name":"Kumba","state_id":"662"},
    {"city_id":"10089","city_name":"Limbe","state_id":"662"},
    {"city_id":"10090","city_name":"Mamfe","state_id":"662"},
    {"city_id":"10091","city_name":"Muyuka","state_id":"662"},
    {"city_id":"10092","city_name":"Tiko","state_id":"662"},
    {"city_id":"10093","city_name":"Airdrie","state_id":"663"},
    {"city_id":"10094","city_name":"Athabasca","state_id":"663"},
    {"city_id":"10095","city_name":"Banff","state_id":"663"},
    {"city_id":"10096","city_name":"Barrhead","state_id":"663"},
    {"city_id":"10097","city_name":"Bassano","state_id":"663"},
    {"city_id":"10098","city_name":"Beaumont","state_id":"663"},
    {"city_id":"10099","city_name":"Beaverlodge","state_id":"663"},
    {"city_id":"10100","city_name":"Black Diamond","state_id":"663"},
    {"city_id":"10101","city_name":"Blackfalds","state_id":"663"},
    {"city_id":"10102","city_name":"Blairmore","state_id":"663"},
    {"city_id":"10103","city_name":"Bon Accord","state_id":"663"},
    {"city_id":"10104","city_name":"Bonnyville","state_id":"663"},
    {"city_id":"10105","city_name":"Bow Island","state_id":"663"},
    {"city_id":"10106","city_name":"Brooks","state_id":"663"},
    {"city_id":"10107","city_name":"Calgary","state_id":"663"},
    {"city_id":"10108","city_name":"Calmar","state_id":"663"},
    {"city_id":"10109","city_name":"Camrose","state_id":"663"},
    {"city_id":"10110","city_name":"Canmore","state_id":"663"},
    {"city_id":"10111","city_name":"Cardston","state_id":"663"},
    {"city_id":"10112","city_name":"Carstairs","state_id":"663"},
    {"city_id":"10113","city_name":"Chateau Lake Louise","state_id":"663"},
    {"city_id":"10114","city_name":"Chestermere","state_id":"663"},
    {"city_id":"10115","city_name":"Clairmont","state_id":"663"},
    {"city_id":"10116","city_name":"Claresholm","state_id":"663"},
    {"city_id":"10117","city_name":"Coaldale","state_id":"663"},
    {"city_id":"10118","city_name":"Coalhurst","state_id":"663"},
    {"city_id":"10119","city_name":"Cochrane","state_id":"663"},
    {"city_id":"10120","city_name":"Crossfield","state_id":"663"},
    {"city_id":"10121","city_name":"Devon","state_id":"663"},
    {"city_id":"10122","city_name":"Didsbury","state_id":"663"},
    {"city_id":"10123","city_name":"Drayton Valley","state_id":"663"},
    {"city_id":"10124","city_name":"Drumheller","state_id":"663"},
    {"city_id":"10125","city_name":"Edmonton","state_id":"663"},
    {"city_id":"10126","city_name":"Edson","state_id":"663"},
    {"city_id":"10127","city_name":"Elk Point","state_id":"663"},
    {"city_id":"10128","city_name":"Fairview","state_id":"663"},
    {"city_id":"10129","city_name":"Falher","state_id":"663"},
    {"city_id":"10130","city_name":"Fort MacLeod","state_id":"663"},
    {"city_id":"10131","city_name":"Fox Creek","state_id":"663"},
    {"city_id":"10132","city_name":"Gibbons","state_id":"663"},
    {"city_id":"10133","city_name":"Grand Centre","state_id":"663"},
    {"city_id":"10134","city_name":"Grande Cache","state_id":"663"},
    {"city_id":"10135","city_name":"Grande Prairie","state_id":"663"},
    {"city_id":"10136","city_name":"Grimshaw","state_id":"663"},
    {"city_id":"10137","city_name":"Hanna","state_id":"663"},
    {"city_id":"10138","city_name":"High Level","state_id":"663"},
    {"city_id":"10139","city_name":"High Prairie","state_id":"663"},
    {"city_id":"10140","city_name":"High River","state_id":"663"},
    {"city_id":"10141","city_name":"Hinton","state_id":"663"},
    {"city_id":"10142","city_name":"Irricana","state_id":"663"},
    {"city_id":"10143","city_name":"Jasper","state_id":"663"},
    {"city_id":"10144","city_name":"Killam","state_id":"663"},
    {"city_id":"10145","city_name":"La Crete","state_id":"663"},
    {"city_id":"10146","city_name":"Lac la Biche","state_id":"663"},
    {"city_id":"10147","city_name":"Lacombe","state_id":"663"},
    {"city_id":"10148","city_name":"Lamont","state_id":"663"},
    {"city_id":"10149","city_name":"Leduc","state_id":"663"},
    {"city_id":"10150","city_name":"Lethbridge","state_id":"663"},
    {"city_id":"10151","city_name":"Lloydminster","state_id":"663"},
    {"city_id":"10152","city_name":"Magrath","state_id":"663"},
    {"city_id":"10153","city_name":"Manning","state_id":"663"},
    {"city_id":"10154","city_name":"Mayerthorpe","state_id":"663"},
    {"city_id":"10155","city_name":"McMurray","state_id":"663"},
    {"city_id":"10156","city_name":"Medicine Hat","state_id":"663"},
    {"city_id":"10157","city_name":"Millet","state_id":"663"},
    {"city_id":"10158","city_name":"Morinville","state_id":"663"},
    {"city_id":"10159","city_name":"Nanton","state_id":"663"},
    {"city_id":"10160","city_name":"Okotoks","state_id":"663"},
    {"city_id":"10161","city_name":"Olds","state_id":"663"},
    {"city_id":"10162","city_name":"Peace River","state_id":"663"},
    {"city_id":"10163","city_name":"Penhold","state_id":"663"},
    {"city_id":"10164","city_name":"Picture Butte","state_id":"663"},
    {"city_id":"10165","city_name":"Pincher Creek","state_id":"663"},
    {"city_id":"10166","city_name":"Ponoka","state_id":"663"},
    {"city_id":"10167","city_name":"Provost","state_id":"663"},
    {"city_id":"10168","city_name":"Raymond","state_id":"663"},
    {"city_id":"10169","city_name":"Red Deer","state_id":"663"},
    {"city_id":"10170","city_name":"Redwater","state_id":"663"},
    {"city_id":"10171","city_name":"Rimbey","state_id":"663"},
    {"city_id":"10172","city_name":"Rocky Mountain House","state_id":"663"},
    {"city_id":"10173","city_name":"Rocky View","state_id":"663"},
    {"city_id":"10174","city_name":"Saint Paul","state_id":"663"},
    {"city_id":"10175","city_name":"Sexsmith","state_id":"663"},
    {"city_id":"10176","city_name":"Sherwood Park","state_id":"663"},
    {"city_id":"10177","city_name":"Slave Lake","state_id":"663"},
    {"city_id":"10178","city_name":"Smoky Lake","state_id":"663"},
    {"city_id":"10179","city_name":"Spirit River","state_id":"663"},
    {"city_id":"10180","city_name":"Spruce Grove","state_id":"663"},
    {"city_id":"10181","city_name":"Stettler","state_id":"663"},
    {"city_id":"10182","city_name":"Stony Plain","state_id":"663"},
    {"city_id":"10183","city_name":"Strathmore","state_id":"663"},
    {"city_id":"10184","city_name":"Sundre","state_id":"663"},
    {"city_id":"10185","city_name":"Swan Hills","state_id":"663"},
    {"city_id":"10186","city_name":"Sylvan Lake","state_id":"663"},
    {"city_id":"10187","city_name":"Taber","state_id":"663"},
    {"city_id":"10188","city_name":"Three Hills","state_id":"663"},
    {"city_id":"10189","city_name":"Tofield","state_id":"663"},
    {"city_id":"10190","city_name":"Two Hills","state_id":"663"},
    {"city_id":"10191","city_name":"Valleyview","state_id":"663"},
    {"city_id":"10192","city_name":"Vegreville","state_id":"663"},
    {"city_id":"10193","city_name":"Vermilion","state_id":"663"},
    {"city_id":"10194","city_name":"Viking","state_id":"663"},
    {"city_id":"10195","city_name":"Vulcan","state_id":"663"},
    {"city_id":"10196","city_name":"Wainwright","state_id":"663"},
    {"city_id":"10197","city_name":"Wembley","state_id":"663"},
    {"city_id":"10198","city_name":"Westlock","state_id":"663"},
    {"city_id":"10199","city_name":"Wetaskiwin","state_id":"663"},
    {"city_id":"10200","city_name":"Whitecourt","state_id":"663"},
    {"city_id":"10201","city_name":"Wood Buffalo","state_id":"663"},
    {"city_id":"10202","city_name":"Altona","state_id":"665"},
    {"city_id":"10203","city_name":"Beausejour","state_id":"665"},
    {"city_id":"10204","city_name":"Boissevain","state_id":"665"},
    {"city_id":"10205","city_name":"Brandon","state_id":"665"},
    {"city_id":"10206","city_name":"Carberry","state_id":"665"},
    {"city_id":"10207","city_name":"Carman","state_id":"665"},
    {"city_id":"10208","city_name":"Dauphin","state_id":"665"},
    {"city_id":"10209","city_name":"Deloraine","state_id":"665"},
    {"city_id":"10210","city_name":"Dugald","state_id":"665"},
    {"city_id":"10211","city_name":"Flin Flon","state_id":"665"},
    {"city_id":"10212","city_name":"Gimli","state_id":"665"},
    {"city_id":"10213","city_name":"Hamiota","state_id":"665"},
    {"city_id":"10214","city_name":"Killarney","state_id":"665"},
    {"city_id":"10215","city_name":"Lac du Bonnet","state_id":"665"},
    {"city_id":"10216","city_name":"Leaf Rapids","state_id":"665"},
    {"city_id":"10217","city_name":"Lorette","state_id":"665"},
    {"city_id":"10218","city_name":"Melita","state_id":"665"},
    {"city_id":"10219","city_name":"Minnedosa","state_id":"665"},
    {"city_id":"10220","city_name":"Morden","state_id":"665"},
    {"city_id":"10221","city_name":"Morris","state_id":"665"},
    {"city_id":"10222","city_name":"Neepawa","state_id":"665"},
    {"city_id":"10223","city_name":"Niverville","state_id":"665"},
    {"city_id":"10224","city_name":"Pinawa","state_id":"665"},
    {"city_id":"10225","city_name":"Portage la Prairie","state_id":"665"},
    {"city_id":"10226","city_name":"Ritchot","state_id":"665"},
    {"city_id":"10227","city_name":"Rivers","state_id":"665"},
    {"city_id":"10228","city_name":"Roblin","state_id":"665"},
    {"city_id":"10229","city_name":"Saint Adolphe","state_id":"665"},
    {"city_id":"10230","city_name":"Sainte Anne","state_id":"665"},
    {"city_id":"10231","city_name":"Sainte Rose du Lac","state_id":"665"},
    {"city_id":"10232","city_name":"Selkirk","state_id":"665"},
    {"city_id":"10233","city_name":"Shilo","state_id":"665"},
    {"city_id":"10234","city_name":"Snow Lake","state_id":"665"},
    {"city_id":"10235","city_name":"Souris","state_id":"665"},
    {"city_id":"10236","city_name":"Springfield","state_id":"665"},
    {"city_id":"10237","city_name":"Steinbach","state_id":"665"},
    {"city_id":"10238","city_name":"Stonewall","state_id":"665"},
    {"city_id":"10239","city_name":"Stony Mountain","state_id":"665"},
    {"city_id":"10240","city_name":"Swan River","state_id":"665"},
    {"city_id":"10241","city_name":"The Pas","state_id":"665"},
    {"city_id":"10242","city_name":"Thompson","state_id":"665"},
    {"city_id":"10243","city_name":"Virden","state_id":"665"},
    {"city_id":"10244","city_name":"Winkler","state_id":"665"},
    {"city_id":"10245","city_name":"Winnipeg","state_id":"665"},
    {"city_id":"10246","city_name":"Clyde River","state_id":"670"},
    {"city_id":"10247","city_name":"Iqaluit","state_id":"670"},
    {"city_id":"10248","city_name":"Kangerdlinerk","state_id":"670"},
    {"city_id":"10249","city_name":"Oqsuqtooq","state_id":"670"},
    {"city_id":"10250","city_name":"Pangnirtung","state_id":"670"},
    {"city_id":"10251","city_name":"Tununirusiq","state_id":"670"},
    {"city_id":"10252","city_name":"Acton","state_id":"671"},
    {"city_id":"10253","city_name":"Ajax","state_id":"671"},
    {"city_id":"10254","city_name":"Alexandria","state_id":"671"},
    {"city_id":"10255","city_name":"Alfred","state_id":"671"},
    {"city_id":"10256","city_name":"Alliston","state_id":"671"},
    {"city_id":"10257","city_name":"Almonte","state_id":"671"},
    {"city_id":"10258","city_name":"Amherstburg","state_id":"671"},
    {"city_id":"10259","city_name":"Amigo Beach","state_id":"671"},
    {"city_id":"10260","city_name":"Angus-Borden","state_id":"671"},
    {"city_id":"10261","city_name":"Arnprior","state_id":"671"},
    {"city_id":"10262","city_name":"Arthur","state_id":"671"},
    {"city_id":"10263","city_name":"Athens","state_id":"671"},
    {"city_id":"10264","city_name":"Atikokan","state_id":"671"},
    {"city_id":"10265","city_name":"Attawapiskat","state_id":"671"},
    {"city_id":"10266","city_name":"Aurora","state_id":"671"},
    {"city_id":"10267","city_name":"Aylmer","state_id":"671"},
    {"city_id":"10268","city_name":"Ayr","state_id":"671"},
    {"city_id":"10269","city_name":"Barrie","state_id":"671"},
    {"city_id":"10270","city_name":"Barry's Bay","state_id":"671"},
    {"city_id":"10271","city_name":"Beamsville","state_id":"671"},
    {"city_id":"10272","city_name":"Beaverton","state_id":"671"},
    {"city_id":"10273","city_name":"Beeton","state_id":"671"},
    {"city_id":"10274","city_name":"Belleville","state_id":"671"},
    {"city_id":"10275","city_name":"Belmont","state_id":"671"},
    {"city_id":"10276","city_name":"Blenheim","state_id":"671"},
    {"city_id":"10277","city_name":"Blind River","state_id":"671"},
    {"city_id":"10278","city_name":"Bobcaygeon","state_id":"671"},
    {"city_id":"10279","city_name":"Bolton","state_id":"671"},
    {"city_id":"10280","city_name":"Bourget","state_id":"671"},
    {"city_id":"10281","city_name":"Bowmanville-Newcastle","state_id":"671"},
    {"city_id":"10282","city_name":"Bracebridge","state_id":"671"},
    {"city_id":"10283","city_name":"Bradford","state_id":"671"},
    {"city_id":"10284","city_name":"Brampton","state_id":"671"},
    {"city_id":"10285","city_name":"Brantford","state_id":"671"},
    {"city_id":"10286","city_name":"Bridgenorth-Chemong Park Area","state_id":"671"},
    {"city_id":"10287","city_name":"Brighton","state_id":"671"},
    {"city_id":"10288","city_name":"Brockville","state_id":"671"},
    {"city_id":"10289","city_name":"Brooklin","state_id":"671"},
    {"city_id":"10290","city_name":"Brussels","state_id":"671"},
    {"city_id":"10291","city_name":"Burford","state_id":"671"},
    {"city_id":"10292","city_name":"Burlington","state_id":"671"},
    {"city_id":"10293","city_name":"Caledon","state_id":"671"},
    {"city_id":"10294","city_name":"Caledon East","state_id":"671"},
    {"city_id":"10295","city_name":"Caledonia","state_id":"671"},
    {"city_id":"10296","city_name":"Cambridge","state_id":"671"},
    {"city_id":"10297","city_name":"Campbellford","state_id":"671"},
    {"city_id":"10298","city_name":"Campbellville","state_id":"671"},
    {"city_id":"10299","city_name":"Cannington","state_id":"671"},
    {"city_id":"10300","city_name":"Capreol","state_id":"671"},
    {"city_id":"10301","city_name":"Cardinal","state_id":"671"},
    {"city_id":"10302","city_name":"Carleton Place","state_id":"671"},
    {"city_id":"10303","city_name":"Carlisle","state_id":"671"},
    {"city_id":"10304","city_name":"Casselman","state_id":"671"},
    {"city_id":"10305","city_name":"Cayuga","state_id":"671"},
    {"city_id":"10306","city_name":"Chalk River","state_id":"671"},
    {"city_id":"10307","city_name":"Chapleau","state_id":"671"},
    {"city_id":"10308","city_name":"Chatham","state_id":"671"},
    {"city_id":"10309","city_name":"Chesley","state_id":"671"},
    {"city_id":"10310","city_name":"Chesterville","state_id":"671"},
    {"city_id":"10311","city_name":"Clinton","state_id":"671"},
    {"city_id":"10312","city_name":"Cobourg","state_id":"671"},
    {"city_id":"10313","city_name":"Cochrane","state_id":"671"},
    {"city_id":"10314","city_name":"Colborne","state_id":"671"},
    {"city_id":"10315","city_name":"Colchester","state_id":"671"},
    {"city_id":"10316","city_name":"Collingwood","state_id":"671"},
    {"city_id":"10317","city_name":"Concord","state_id":"671"},
    {"city_id":"10318","city_name":"Constance Bay","state_id":"671"},
    {"city_id":"10319","city_name":"Cookstown","state_id":"671"},
    {"city_id":"10320","city_name":"Cornwall","state_id":"671"},
    {"city_id":"10321","city_name":"Creemore","state_id":"671"},
    {"city_id":"10322","city_name":"Crystal Beach","state_id":"671"},
    {"city_id":"10323","city_name":"Deep River","state_id":"671"},
    {"city_id":"10324","city_name":"Delhi","state_id":"671"},
    {"city_id":"10325","city_name":"Deseronto","state_id":"671"},
    {"city_id":"10326","city_name":"Downsview","state_id":"671"},
    {"city_id":"10327","city_name":"Drayton","state_id":"671"},
    {"city_id":"10328","city_name":"Dresden","state_id":"671"},
    {"city_id":"10329","city_name":"Dryden","state_id":"671"},
    {"city_id":"10330","city_name":"Dundalk","state_id":"671"},
    {"city_id":"10331","city_name":"Dunnville","state_id":"671"},
    {"city_id":"10332","city_name":"Durham","state_id":"671"},
    {"city_id":"10333","city_name":"Dutton","state_id":"671"},
    {"city_id":"10334","city_name":"Eganville","state_id":"671"},
    {"city_id":"10335","city_name":"Elliot Lake","state_id":"671"},
    {"city_id":"10336","city_name":"Elmira","state_id":"671"},
    {"city_id":"10337","city_name":"Elmvale","state_id":"671"},
    {"city_id":"10338","city_name":"Embrun","state_id":"671"},
    {"city_id":"10339","city_name":"Englehart","state_id":"671"},
    {"city_id":"10340","city_name":"Erin","state_id":"671"},
    {"city_id":"10341","city_name":"Espanola","state_id":"671"},
    {"city_id":"10342","city_name":"Essex","state_id":"671"},
    {"city_id":"10343","city_name":"Etobicoke","state_id":"671"},
    {"city_id":"10344","city_name":"Everett","state_id":"671"},
    {"city_id":"10345","city_name":"Exeter","state_id":"671"},
    {"city_id":"10346","city_name":"Fenelon Falls","state_id":"671"},
    {"city_id":"10347","city_name":"Fergus","state_id":"671"},
    {"city_id":"10348","city_name":"Forest","state_id":"671"},
    {"city_id":"10349","city_name":"Fort Erie","state_id":"671"},
    {"city_id":"10350","city_name":"Fort Frances","state_id":"671"},
    {"city_id":"10351","city_name":"Frankford","state_id":"671"},
    {"city_id":"10352","city_name":"Gananoque","state_id":"671"},
    {"city_id":"10353","city_name":"Georgetown","state_id":"671"},
    {"city_id":"10354","city_name":"Georgina","state_id":"671"},
    {"city_id":"10355","city_name":"Geraldton","state_id":"671"},
    {"city_id":"10356","city_name":"Glencoe","state_id":"671"},
    {"city_id":"10357","city_name":"Goderich","state_id":"671"},
    {"city_id":"10358","city_name":"Golden","state_id":"671"},
    {"city_id":"10359","city_name":"Gormley","state_id":"671"},
    {"city_id":"10360","city_name":"Grand Bend","state_id":"671"},
    {"city_id":"10361","city_name":"Grand Valley","state_id":"671"},
    {"city_id":"10362","city_name":"Gravenhurst","state_id":"671"},
    {"city_id":"10363","city_name":"Guelph","state_id":"671"},
    {"city_id":"10364","city_name":"Hagersville","state_id":"671"},
    {"city_id":"10365","city_name":"Haileybury","state_id":"671"},
    {"city_id":"10366","city_name":"Hamilton","state_id":"671"},
    {"city_id":"10367","city_name":"Hanover","state_id":"671"},
    {"city_id":"10368","city_name":"Harriston","state_id":"671"},
    {"city_id":"10369","city_name":"Harrow","state_id":"671"},
    {"city_id":"10370","city_name":"Hastings","state_id":"671"},
    {"city_id":"10371","city_name":"Havelock","state_id":"671"},
    {"city_id":"10372","city_name":"Hawkesbury","state_id":"671"},
    {"city_id":"10373","city_name":"Hearst","state_id":"671"},
    {"city_id":"10374","city_name":"Hensall","state_id":"671"},
    {"city_id":"10375","city_name":"Hillsburgh","state_id":"671"},
    {"city_id":"10376","city_name":"Hornepayne","state_id":"671"},
    {"city_id":"10377","city_name":"Huntsville","state_id":"671"},
    {"city_id":"10378","city_name":"Ingersoll","state_id":"671"},
    {"city_id":"10379","city_name":"Innisfil","state_id":"671"},
    {"city_id":"10380","city_name":"Iroquois","state_id":"671"},
    {"city_id":"10381","city_name":"Iroquois Falls","state_id":"671"},
    {"city_id":"10382","city_name":"Jarvis","state_id":"671"},
    {"city_id":"10383","city_name":"Kanata","state_id":"671"},
    {"city_id":"10384","city_name":"Kapuskasing","state_id":"671"},
    {"city_id":"10385","city_name":"Kars","state_id":"671"},
    {"city_id":"10386","city_name":"Kemptville","state_id":"671"},
    {"city_id":"10387","city_name":"Kenora","state_id":"671"},
    {"city_id":"10388","city_name":"Kincardine","state_id":"671"},
    {"city_id":"10389","city_name":"Kingston","state_id":"671"},
    {"city_id":"10390","city_name":"Kirkland Lake","state_id":"671"},
    {"city_id":"10391","city_name":"Kitchener","state_id":"671"},
    {"city_id":"10392","city_name":"L'Original","state_id":"671"},
    {"city_id":"10393","city_name":"Lakefield","state_id":"671"},
    {"city_id":"10394","city_name":"Lanark","state_id":"671"},
    {"city_id":"10395","city_name":"Leamington","state_id":"671"},
    {"city_id":"10396","city_name":"Lindsay","state_id":"671"},
    {"city_id":"10397","city_name":"Listowel","state_id":"671"},
    {"city_id":"10398","city_name":"Little Current","state_id":"671"},
    {"city_id":"10399","city_name":"Lively","state_id":"671"},
    {"city_id":"10400","city_name":"London","state_id":"671"},
    {"city_id":"10401","city_name":"Longlac","state_id":"671"},
    {"city_id":"10402","city_name":"Lucan","state_id":"671"},
    {"city_id":"10403","city_name":"Lucknow","state_id":"671"},
    {"city_id":"10404","city_name":"Madoc","state_id":"671"},
    {"city_id":"10405","city_name":"Manitouwadge","state_id":"671"},
    {"city_id":"10406","city_name":"Maple","state_id":"671"},
    {"city_id":"10407","city_name":"Marathon","state_id":"671"},
    {"city_id":"10408","city_name":"Markdale","state_id":"671"},
    {"city_id":"10409","city_name":"Markham","state_id":"671"},
    {"city_id":"10410","city_name":"Marmora","state_id":"671"},
    {"city_id":"10411","city_name":"Mattawa","state_id":"671"},
    {"city_id":"10412","city_name":"Meaford","state_id":"671"},
    {"city_id":"10413","city_name":"Metcalfe","state_id":"671"},
    {"city_id":"10414","city_name":"Midland","state_id":"671"},
    {"city_id":"10415","city_name":"Mildmay","state_id":"671"},
    {"city_id":"10416","city_name":"Millbrook","state_id":"671"},
    {"city_id":"10417","city_name":"Milton","state_id":"671"},
    {"city_id":"10418","city_name":"Milverton","state_id":"671"},
    {"city_id":"10419","city_name":"Mississauga","state_id":"671"},
    {"city_id":"10420","city_name":"Mississauga Beach","state_id":"671"},
    {"city_id":"10421","city_name":"Mitchell","state_id":"671"},
    {"city_id":"10422","city_name":"Moose Factory","state_id":"671"},
    {"city_id":"10423","city_name":"Morrisburg","state_id":"671"},
    {"city_id":"10424","city_name":"Mount Albert","state_id":"671"},
    {"city_id":"10425","city_name":"Mount Brydges","state_id":"671"},
    {"city_id":"10426","city_name":"Mount Forest","state_id":"671"},
    {"city_id":"10427","city_name":"Munster","state_id":"671"},
    {"city_id":"10428","city_name":"Nanticoke","state_id":"671"},
    {"city_id":"10429","city_name":"Napanee","state_id":"671"},
    {"city_id":"10430","city_name":"Nepean","state_id":"671"},
    {"city_id":"10431","city_name":"New Hamburg","state_id":"671"},
    {"city_id":"10432","city_name":"Newmarket","state_id":"671"},
    {"city_id":"10433","city_name":"Newtonville","state_id":"671"},
    {"city_id":"10434","city_name":"Nobleton","state_id":"671"},
    {"city_id":"10435","city_name":"North Bay","state_id":"671"},
    {"city_id":"10436","city_name":"North Gower","state_id":"671"},
    {"city_id":"10437","city_name":"North York","state_id":"671"},
    {"city_id":"10438","city_name":"Norwich","state_id":"671"},
    {"city_id":"10439","city_name":"Norwood","state_id":"671"},
    {"city_id":"10440","city_name":"Oakville","state_id":"671"},
    {"city_id":"10441","city_name":"Omemee","state_id":"671"},
    {"city_id":"10442","city_name":"Onaping-Levack","state_id":"671"},
    {"city_id":"10443","city_name":"Ontario","state_id":"671"},
    {"city_id":"10444","city_name":"Orangeville","state_id":"671"},
    {"city_id":"10445","city_name":"Orillia","state_id":"671"},
    {"city_id":"10446","city_name":"Orono","state_id":"671"},
    {"city_id":"10447","city_name":"Osgoode","state_id":"671"},
    {"city_id":"10448","city_name":"Oshawa","state_id":"671"},
    {"city_id":"10449","city_name":"Ottawa","state_id":"671"},
    {"city_id":"10450","city_name":"Owen Sound","state_id":"671"},
    {"city_id":"10451","city_name":"Paisley","state_id":"671"},
    {"city_id":"10452","city_name":"Palmerston","state_id":"671"},
    {"city_id":"10453","city_name":"Paris","state_id":"671"},
    {"city_id":"10454","city_name":"Parkhill","state_id":"671"},
    {"city_id":"10455","city_name":"Parry Sound","state_id":"671"},
    {"city_id":"10456","city_name":"Pembroke","state_id":"671"},
    {"city_id":"10457","city_name":"Perth","state_id":"671"},
    {"city_id":"10458","city_name":"Petawawa","state_id":"671"},
    {"city_id":"10459","city_name":"Peterborough","state_id":"671"},
    {"city_id":"10460","city_name":"Petrolia","state_id":"671"},
    {"city_id":"10461","city_name":"Pickering","state_id":"671"},
    {"city_id":"10462","city_name":"Picton","state_id":"671"},
    {"city_id":"10463","city_name":"Porcupine","state_id":"671"},
    {"city_id":"10464","city_name":"Port Credit","state_id":"671"},
    {"city_id":"10465","city_name":"Port Dover","state_id":"671"},
    {"city_id":"10466","city_name":"Port Elgin","state_id":"671"},
    {"city_id":"10467","city_name":"Port Hope","state_id":"671"},
    {"city_id":"10468","city_name":"Port Perry","state_id":"671"},
    {"city_id":"10469","city_name":"Port Stanley","state_id":"671"},
    {"city_id":"10470","city_name":"Powassan","state_id":"671"},
    {"city_id":"10471","city_name":"Prescott","state_id":"671"},
    {"city_id":"10472","city_name":"Queensville","state_id":"671"},
    {"city_id":"10473","city_name":"Renfrew","state_id":"671"},
    {"city_id":"10474","city_name":"Richmond","state_id":"671"},
    {"city_id":"10475","city_name":"Richmond Hill","state_id":"671"},
    {"city_id":"10476","city_name":"Ridgetown","state_id":"671"},
    {"city_id":"10477","city_name":"Rockland","state_id":"671"},
    {"city_id":"10478","city_name":"Rockwood","state_id":"671"},
    {"city_id":"10479","city_name":"Rodney","state_id":"671"},
    {"city_id":"10480","city_name":"Saint Catharines","state_id":"671"},
    {"city_id":"10481","city_name":"Saint Catharines-Niagara","state_id":"671"},
    {"city_id":"10482","city_name":"Saint George","state_id":"671"},
    {"city_id":"10483","city_name":"Saint Jacobs","state_id":"671"},
    {"city_id":"10484","city_name":"Saint Marys","state_id":"671"},
    {"city_id":"10485","city_name":"Saint Thomas","state_id":"671"},
    {"city_id":"10486","city_name":"Sarnia","state_id":"671"},
    {"city_id":"10487","city_name":"Sault Sainte Marie","state_id":"671"},
    {"city_id":"10488","city_name":"Scarborough","state_id":"671"},
    {"city_id":"10489","city_name":"Schomberg","state_id":"671"},
    {"city_id":"10490","city_name":"Seaforth","state_id":"671"},
    {"city_id":"10491","city_name":"Shelburne","state_id":"671"},
    {"city_id":"10492","city_name":"Simcoe","state_id":"671"},
    {"city_id":"10493","city_name":"Sioux Lookout","state_id":"671"},
    {"city_id":"10494","city_name":"Smiths Falls","state_id":"671"},
    {"city_id":"10495","city_name":"Smithville","state_id":"671"},
    {"city_id":"10496","city_name":"South River","state_id":"671"},
    {"city_id":"10497","city_name":"Southampton","state_id":"671"},
    {"city_id":"10498","city_name":"Stayner","state_id":"671"},
    {"city_id":"10499","city_name":"Stirling","state_id":"671"},
    {"city_id":"10500","city_name":"Stoney Creek","state_id":"671"},
    {"city_id":"10501","city_name":"Stoney Point","state_id":"671"},
    {"city_id":"10502","city_name":"Stouffville","state_id":"671"},
    {"city_id":"10503","city_name":"Stratford","state_id":"671"},
    {"city_id":"10504","city_name":"Strathroy","state_id":"671"},
    {"city_id":"10505","city_name":"Sturgeon Falls","state_id":"671"},
    {"city_id":"10506","city_name":"Sudbury","state_id":"671"},
    {"city_id":"10507","city_name":"Sutton","state_id":"671"},
    {"city_id":"10508","city_name":"Tavistock","state_id":"671"},
    {"city_id":"10509","city_name":"Teeswater","state_id":"671"},
    {"city_id":"10510","city_name":"Terrace Bay","state_id":"671"},
    {"city_id":"10511","city_name":"Thamesford","state_id":"671"},
    {"city_id":"10512","city_name":"Thessalon","state_id":"671"},
    {"city_id":"10513","city_name":"Thornbury","state_id":"671"},
    {"city_id":"10514","city_name":"Thornhill","state_id":"671"},
    {"city_id":"10515","city_name":"Thunder Bay","state_id":"671"},
    {"city_id":"10516","city_name":"Tilbury","state_id":"671"},
    {"city_id":"10517","city_name":"Tilsonburg","state_id":"671"},
    {"city_id":"10518","city_name":"Timmins","state_id":"671"},
    {"city_id":"10519","city_name":"Toronto","state_id":"671"},
    {"city_id":"10520","city_name":"Tory Hill","state_id":"671"},
    {"city_id":"10521","city_name":"Tottenham","state_id":"671"},
    {"city_id":"10522","city_name":"Tweed","state_id":"671"},
    {"city_id":"10523","city_name":"Uxbridge","state_id":"671"},
    {"city_id":"10524","city_name":"Valley East","state_id":"671"},
    {"city_id":"10525","city_name":"Vankleek Hill","state_id":"671"},
    {"city_id":"10526","city_name":"Vaughan","state_id":"671"},
    {"city_id":"10527","city_name":"Vineland","state_id":"671"},
    {"city_id":"10528","city_name":"Walkerton","state_id":"671"},
    {"city_id":"10529","city_name":"Wallaceburg","state_id":"671"},
    {"city_id":"10530","city_name":"Wasaga Beach","state_id":"671"},
    {"city_id":"10531","city_name":"Waterdown","state_id":"671"},
    {"city_id":"10532","city_name":"Waterford","state_id":"671"},
    {"city_id":"10533","city_name":"Waterloo","state_id":"671"},
    {"city_id":"10534","city_name":"Watford","state_id":"671"},
    {"city_id":"10535","city_name":"Wawa","state_id":"671"},
    {"city_id":"10536","city_name":"Welland","state_id":"671"},
    {"city_id":"10537","city_name":"Wellesley","state_id":"671"},
    {"city_id":"10538","city_name":"Wellington","state_id":"671"},
    {"city_id":"10539","city_name":"West Lorne","state_id":"671"},
    {"city_id":"10540","city_name":"Wheatley","state_id":"671"},
    {"city_id":"10541","city_name":"Whitby","state_id":"671"},
    {"city_id":"10542","city_name":"Whitchurch-Stouffville","state_id":"671"},
    {"city_id":"10543","city_name":"Wiarton","state_id":"671"},
    {"city_id":"10544","city_name":"Wikwemikong","state_id":"671"},
    {"city_id":"10545","city_name":"Willowdale","state_id":"671"},
    {"city_id":"10546","city_name":"Winchester","state_id":"671"},
    {"city_id":"10547","city_name":"Windsor","state_id":"671"},
    {"city_id":"10548","city_name":"Wingham","state_id":"671"},
    {"city_id":"10549","city_name":"Woodbridge","state_id":"671"},
    {"city_id":"10550","city_name":"Woodstock","state_id":"671"},
    {"city_id":"10551","city_name":"Wyoming","state_id":"671"},
    {"city_id":"10552","city_name":"Acton Vale","state_id":"673"},
    {"city_id":"10553","city_name":"Albanel","state_id":"673"},
    {"city_id":"10554","city_name":"Alencon","state_id":"673"},
    {"city_id":"10555","city_name":"Alma","state_id":"673"},
    {"city_id":"10556","city_name":"Amos","state_id":"673"},
    {"city_id":"10557","city_name":"Amqui","state_id":"673"},
    {"city_id":"10558","city_name":"Anjou","state_id":"673"},
    {"city_id":"10559","city_name":"Asbestos","state_id":"673"},
    {"city_id":"10560","city_name":"Bagotville","state_id":"673"},
    {"city_id":"10561","city_name":"Baie-Comeau","state_id":"673"},
    {"city_id":"10562","city_name":"Baie-Saint-Paul","state_id":"673"},
    {"city_id":"10563","city_name":"Barraute","state_id":"673"},
    {"city_id":"10564","city_name":"Beauceville","state_id":"673"},
    {"city_id":"10565","city_name":"Beaupre","state_id":"673"},
    {"city_id":"10566","city_name":"Bedford","state_id":"673"},
    {"city_id":"10567","city_name":"Beloeil","state_id":"673"},
    {"city_id":"10568","city_name":"Bernierville","state_id":"673"},
    {"city_id":"10569","city_name":"Berthierville","state_id":"673"},
    {"city_id":"10570","city_name":"Betsiamites","state_id":"673"},
    {"city_id":"10571","city_name":"Boisbriand","state_id":"673"},
    {"city_id":"10572","city_name":"Bonaventure","state_id":"673"},
    {"city_id":"10573","city_name":"Boucherville","state_id":"673"},
    {"city_id":"10574","city_name":"Bromont","state_id":"673"},
    {"city_id":"10575","city_name":"Brossard","state_id":"673"},
    {"city_id":"10576","city_name":"Brownsburg","state_id":"673"},
    {"city_id":"10577","city_name":"Buckingham","state_id":"673"},
    {"city_id":"10578","city_name":"Cabano","state_id":"673"},
    {"city_id":"10579","city_name":"Candiac","state_id":"673"},
    {"city_id":"10580","city_name":"Cap-Chat","state_id":"673"},
    {"city_id":"10581","city_name":"Cap-aux-Meules","state_id":"673"},
    {"city_id":"10582","city_name":"Carleton","state_id":"673"},
    {"city_id":"10583","city_name":"Causapscal","state_id":"673"},
    {"city_id":"10584","city_name":"Chandler","state_id":"673"},
    {"city_id":"10585","city_name":"Chapais","state_id":"673"},
    {"city_id":"10586","city_name":"Charlesbourg","state_id":"673"},
    {"city_id":"10587","city_name":"Chateau-Richer","state_id":"673"},
    {"city_id":"10588","city_name":"Chibougamou","state_id":"673"},
    {"city_id":"10589","city_name":"Chicoutimi-Jonquiere","state_id":"673"},
    {"city_id":"10590","city_name":"Chisasibi","state_id":"673"},
    {"city_id":"10591","city_name":"Chute-aux-Outardes","state_id":"673"},
    {"city_id":"10592","city_name":"Clermont","state_id":"673"},
    {"city_id":"10593","city_name":"Coaticook","state_id":"673"},
    {"city_id":"10594","city_name":"Coleraine","state_id":"673"},
    {"city_id":"10595","city_name":"Contrecoeur","state_id":"673"},
    {"city_id":"10596","city_name":"Cookshire","state_id":"673"},
    {"city_id":"10597","city_name":"Cowansville","state_id":"673"},
    {"city_id":"10598","city_name":"Crabtree","state_id":"673"},
    {"city_id":"10599","city_name":"Danville","state_id":"673"},
    {"city_id":"10600","city_name":"Daveluyville","state_id":"673"},
    {"city_id":"10601","city_name":"Degelis","state_id":"673"},
    {"city_id":"10602","city_name":"Desbiens","state_id":"673"},
    {"city_id":"10603","city_name":"Disraeli","state_id":"673"},
    {"city_id":"10604","city_name":"Dolbeau","state_id":"673"},
    {"city_id":"10605","city_name":"Donnacona","state_id":"673"},
    {"city_id":"10606","city_name":"Dorval","state_id":"673"},
    {"city_id":"10607","city_name":"Drummondville","state_id":"673"},
    {"city_id":"10608","city_name":"East Angus","state_id":"673"},
    {"city_id":"10609","city_name":"East Broughton","state_id":"673"},
    {"city_id":"10610","city_name":"Farnham","state_id":"673"},
    {"city_id":"10611","city_name":"Ferme-Neuve","state_id":"673"},
    {"city_id":"10612","city_name":"Fermont","state_id":"673"},
    {"city_id":"10613","city_name":"Filion","state_id":"673"},
    {"city_id":"10614","city_name":"Forestville","state_id":"673"},
    {"city_id":"10615","city_name":"Fort-Coulonge","state_id":"673"},
    {"city_id":"10616","city_name":"Gaspe","state_id":"673"},
    {"city_id":"10617","city_name":"Gentilly","state_id":"673"},
    {"city_id":"10618","city_name":"Granby","state_id":"673"},
    {"city_id":"10619","city_name":"Grande-Riviere","state_id":"673"},
    {"city_id":"10620","city_name":"Grenville","state_id":"673"},
    {"city_id":"10621","city_name":"Ham Nord","state_id":"673"},
    {"city_id":"10622","city_name":"Hampstead","state_id":"673"},
    {"city_id":"10623","city_name":"Hauterive","state_id":"673"},
    {"city_id":"10624","city_name":"Havre-Saint-Pierre","state_id":"673"},
    {"city_id":"10625","city_name":"Hebertville","state_id":"673"},
    {"city_id":"10626","city_name":"Huntingdon","state_id":"673"},
    {"city_id":"10627","city_name":"Joliette","state_id":"673"},
    {"city_id":"10628","city_name":"Kingsey Falls","state_id":"673"},
    {"city_id":"10629","city_name":"L'Annonciation","state_id":"673"},
    {"city_id":"10630","city_name":"L'Ascension-de-Notre-Seigneur","state_id":"673"},
    {"city_id":"10631","city_name":"L'Epiphanie","state_id":"673"},
    {"city_id":"10632","city_name":"La Malbaie","state_id":"673"},
    {"city_id":"10633","city_name":"La Pocatiere","state_id":"673"},
    {"city_id":"10634","city_name":"La Sarre","state_id":"673"},
    {"city_id":"10635","city_name":"La Tuque","state_id":"673"},
    {"city_id":"10636","city_name":"Labelle","state_id":"673"},
    {"city_id":"10637","city_name":"Lac-Etchemin","state_id":"673"},
    {"city_id":"10638","city_name":"Lac-Lapierre","state_id":"673"},
    {"city_id":"10639","city_name":"Lac-Megantic","state_id":"673"},
    {"city_id":"10640","city_name":"Lac-au-Saumon","state_id":"673"},
    {"city_id":"10641","city_name":"Lachine","state_id":"673"},
    {"city_id":"10642","city_name":"Lachute","state_id":"673"},
    {"city_id":"10643","city_name":"Lacolle","state_id":"673"},
    {"city_id":"10644","city_name":"Lasalle","state_id":"673"},
    {"city_id":"10645","city_name":"Laurentides","state_id":"673"},
    {"city_id":"10646","city_name":"Laurier-Station","state_id":"673"},
    {"city_id":"10647","city_name":"Laval","state_id":"673"},
    {"city_id":"10648","city_name":"Lavaltrie","state_id":"673"},
    {"city_id":"10649","city_name":"Le Bic","state_id":"673"},
    {"city_id":"10650","city_name":"Lebel-sur-Quevillon","state_id":"673"},
    {"city_id":"10651","city_name":"Les Cedres","state_id":"673"},
    {"city_id":"10652","city_name":"Les Coteaux","state_id":"673"},
    {"city_id":"10653","city_name":"Les Escoumins","state_id":"673"},
    {"city_id":"10654","city_name":"Liniere","state_id":"673"},
    {"city_id":"10655","city_name":"Longueuil","state_id":"673"},
    {"city_id":"10656","city_name":"Louiseville","state_id":"673"},
    {"city_id":"10657","city_name":"Luceville","state_id":"673"},
    {"city_id":"10658","city_name":"Macamic","state_id":"673"},
    {"city_id":"10659","city_name":"Magog","state_id":"673"},
    {"city_id":"10660","city_name":"Malartic","state_id":"673"},
    {"city_id":"10661","city_name":"Maniwaki","state_id":"673"},
    {"city_id":"10662","city_name":"Marieville","state_id":"673"},
    {"city_id":"10663","city_name":"Maskinonge","state_id":"673"},
    {"city_id":"10664","city_name":"Matagami","state_id":"673"},
    {"city_id":"10665","city_name":"Matane","state_id":"673"},
    {"city_id":"10666","city_name":"Metabetchouan","state_id":"673"},
    {"city_id":"10667","city_name":"Mirabel","state_id":"673"},
    {"city_id":"10668","city_name":"Mistissini","state_id":"673"},
    {"city_id":"10669","city_name":"Mont-Joli","state_id":"673"},
    {"city_id":"10670","city_name":"Mont-Laurier","state_id":"673"},
    {"city_id":"10671","city_name":"Montmagny","state_id":"673"},
    {"city_id":"10672","city_name":"Montreal","state_id":"673"},
    {"city_id":"10673","city_name":"Murdochville","state_id":"673"},
    {"city_id":"10674","city_name":"Napierville","state_id":"673"},
    {"city_id":"10675","city_name":"New Richmond","state_id":"673"},
    {"city_id":"10676","city_name":"Nicolet","state_id":"673"},
    {"city_id":"10677","city_name":"Normandin","state_id":"673"},
    {"city_id":"10678","city_name":"Notre-Dame-du-Bon-Conseil","state_id":"673"},
    {"city_id":"10679","city_name":"Notre-Dame-du-Lac","state_id":"673"},
    {"city_id":"10680","city_name":"Notre-Dame-du-Mont-Carmel","state_id":"673"},
    {"city_id":"10681","city_name":"Oka-Kanesatake","state_id":"673"},
    {"city_id":"10682","city_name":"Ormstown","state_id":"673"},
    {"city_id":"10683","city_name":"Papineauville","state_id":"673"},
    {"city_id":"10684","city_name":"Pierreville","state_id":"673"},
    {"city_id":"10685","city_name":"Plessisville","state_id":"673"},
    {"city_id":"10686","city_name":"Pointe-Claire","state_id":"673"},
    {"city_id":"10687","city_name":"Pont-Rouge","state_id":"673"},
    {"city_id":"10688","city_name":"Port-Alfred-Bagotville","state_id":"673"},
    {"city_id":"10689","city_name":"Port-Cartier","state_id":"673"},
    {"city_id":"10690","city_name":"Portneuf","state_id":"673"},
    {"city_id":"10691","city_name":"Price","state_id":"673"},
    {"city_id":"10692","city_name":"Princeville","state_id":"673"},
    {"city_id":"10693","city_name":"Quebec","state_id":"673"},
    {"city_id":"10694","city_name":"Rawdon","state_id":"673"},
    {"city_id":"10695","city_name":"Repentigny","state_id":"673"},
    {"city_id":"10696","city_name":"Richmond","state_id":"673"},
    {"city_id":"10697","city_name":"Rigaud","state_id":"673"},
    {"city_id":"10698","city_name":"Rimouski","state_id":"673"},
    {"city_id":"10699","city_name":"Riviere-au-Renard","state_id":"673"},
    {"city_id":"10700","city_name":"Riviere-du-Loup","state_id":"673"},
    {"city_id":"10701","city_name":"Roberval","state_id":"673"},
    {"city_id":"10702","city_name":"Rougemont","state_id":"673"},
    {"city_id":"10703","city_name":"Rouyn-Noranda","state_id":"673"},
    {"city_id":"10704","city_name":"Saint-Agapit","state_id":"673"},
    {"city_id":"10705","city_name":"Saint-Alexandre","state_id":"673"},
    {"city_id":"10706","city_name":"Saint-Alexis-des-Monts","state_id":"673"},
    {"city_id":"10707","city_name":"Saint-Ambroise","state_id":"673"},
    {"city_id":"10708","city_name":"Saint-Andre-Avellin","state_id":"673"},
    {"city_id":"10709","city_name":"Saint-Anselme","state_id":"673"},
    {"city_id":"10710","city_name":"Saint-Apollinaire","state_id":"673"},
    {"city_id":"10711","city_name":"Saint-Augustin","state_id":"673"},
    {"city_id":"10712","city_name":"Saint-Basile-Sud","state_id":"673"},
    {"city_id":"10713","city_name":"Saint-Bruno","state_id":"673"},
    {"city_id":"10714","city_name":"Saint-Canut","state_id":"673"},
    {"city_id":"10715","city_name":"Saint-Cesaire","state_id":"673"},
    {"city_id":"10716","city_name":"Saint-Cyrill-de-Wendover","state_id":"673"},
    {"city_id":"10717","city_name":"Saint-Damase","state_id":"673"},
    {"city_id":"10718","city_name":"Saint-Damien-de-Buckland","state_id":"673"},
    {"city_id":"10719","city_name":"Saint-Denis","state_id":"673"},
    {"city_id":"10720","city_name":"Saint-Donat-de-Montcalm","state_id":"673"},
    {"city_id":"10721","city_name":"Saint-Ephrem-de-Tring","state_id":"673"},
    {"city_id":"10722","city_name":"Saint-Fabien","state_id":"673"},
    {"city_id":"10723","city_name":"Saint-Felicien","state_id":"673"},
    {"city_id":"10724","city_name":"Saint-Felix-de-Valois","state_id":"673"},
    {"city_id":"10725","city_name":"Saint-Gabriel","state_id":"673"},
    {"city_id":"10726","city_name":"Saint-Gedeon","state_id":"673"},
    {"city_id":"10727","city_name":"Saint-Georges","state_id":"673"},
    {"city_id":"10728","city_name":"Saint-Germain-de-Grantham","state_id":"673"},
    {"city_id":"10729","city_name":"Saint-Gregoire","state_id":"673"},
    {"city_id":"10730","city_name":"Saint-Henri-de-Levis","state_id":"673"},
    {"city_id":"10731","city_name":"Saint-Honore","state_id":"673"},
    {"city_id":"10732","city_name":"Saint-Hyacinthe","state_id":"673"},
    {"city_id":"10733","city_name":"Saint-Jacques","state_id":"673"},
    {"city_id":"10734","city_name":"Saint-Jean-Port-Joli","state_id":"673"},
    {"city_id":"10735","city_name":"Saint-Jean-de-Dieu","state_id":"673"},
    {"city_id":"10736","city_name":"Saint-Jean-sur-Richelieu","state_id":"673"},
    {"city_id":"10737","city_name":"Saint-Jerome","state_id":"673"},
    {"city_id":"10738","city_name":"Saint-Josephe-de-Beauce","state_id":"673"},
    {"city_id":"10739","city_name":"Saint-Josephe-de-Lanoraie","state_id":"673"},
    {"city_id":"10740","city_name":"Saint-Josephe-de-la-Riviere-Bl","state_id":"673"},
    {"city_id":"10741","city_name":"Saint-Jovite","state_id":"673"},
    {"city_id":"10742","city_name":"Saint-Laurent","state_id":"673"},
    {"city_id":"10743","city_name":"Saint-Liboire","state_id":"673"},
    {"city_id":"10744","city_name":"Saint-Marc-des-Carrieres","state_id":"673"},
    {"city_id":"10745","city_name":"Saint-Martin","state_id":"673"},
    {"city_id":"10746","city_name":"Saint-Michel-des-Saints","state_id":"673"},
    {"city_id":"10747","city_name":"Saint-Pacome","state_id":"673"},
    {"city_id":"10748","city_name":"Saint-Pascal","state_id":"673"},
    {"city_id":"10749","city_name":"Saint-Pie","state_id":"673"},
    {"city_id":"10750","city_name":"Saint-Prosper","state_id":"673"},
    {"city_id":"10751","city_name":"Saint-Raphael","state_id":"673"},
    {"city_id":"10752","city_name":"Saint-Raymond","state_id":"673"},
    {"city_id":"10753","city_name":"Saint-Remi","state_id":"673"},
    {"city_id":"10754","city_name":"Saint-Roch-de-l'Achigan","state_id":"673"},
    {"city_id":"10755","city_name":"Saint-Sauveur-des-Monts","state_id":"673"},
    {"city_id":"10756","city_name":"Saint-Tite","state_id":"673"},
    {"city_id":"10757","city_name":"Sainte-Adele","state_id":"673"},
    {"city_id":"10758","city_name":"Sainte-Agathe-des-Monts","state_id":"673"},
    {"city_id":"10759","city_name":"Sainte-Anne-des-Monts","state_id":"673"},
    {"city_id":"10760","city_name":"Sainte-Anne-des-Plaines","state_id":"673"},
    {"city_id":"10761","city_name":"Sainte-Catherine","state_id":"673"},
    {"city_id":"10762","city_name":"Sainte-Claire","state_id":"673"},
    {"city_id":"10763","city_name":"Sainte-Julienne","state_id":"673"},
    {"city_id":"10764","city_name":"Sainte-Justine","state_id":"673"},
    {"city_id":"10765","city_name":"Sainte-Madeleine","state_id":"673"},
    {"city_id":"10766","city_name":"Sainte-Marie","state_id":"673"},
    {"city_id":"10767","city_name":"Sainte-Martine","state_id":"673"},
    {"city_id":"10768","city_name":"Sainte-Sophie","state_id":"673"},
    {"city_id":"10769","city_name":"Sainte-Thecle","state_id":"673"},
    {"city_id":"10770","city_name":"Sainte-Therese","state_id":"673"},
    {"city_id":"10771","city_name":"Salaberry-de-Valleyfield","state_id":"673"},
    {"city_id":"10772","city_name":"Sayabec","state_id":"673"},
    {"city_id":"10773","city_name":"Senneterre","state_id":"673"},
    {"city_id":"10774","city_name":"Sept-Iles","state_id":"673"},
    {"city_id":"10775","city_name":"Shawinigan","state_id":"673"},
    {"city_id":"10776","city_name":"Shawville","state_id":"673"},
    {"city_id":"10777","city_name":"Sherbrooke","state_id":"673"},
    {"city_id":"10778","city_name":"Sorel","state_id":"673"},
    {"city_id":"10779","city_name":"St Faustin","state_id":"673"},
    {"city_id":"10780","city_name":"St. Hubert","state_id":"673"},
    {"city_id":"10781","city_name":"St. Jean Chrysostome","state_id":"673"},
    {"city_id":"10782","city_name":"Temiscaming","state_id":"673"},
    {"city_id":"10783","city_name":"Terrebonne","state_id":"673"},
    {"city_id":"10784","city_name":"Thetford Mines","state_id":"673"},
    {"city_id":"10785","city_name":"Thurso","state_id":"673"},
    {"city_id":"10786","city_name":"Trois-Pistoles","state_id":"673"},
    {"city_id":"10787","city_name":"Trois-Rivieres","state_id":"673"},
    {"city_id":"10788","city_name":"Val-David","state_id":"673"},
    {"city_id":"10789","city_name":"Val-d'Or","state_id":"673"},
    {"city_id":"10790","city_name":"Valcourt","state_id":"673"},
    {"city_id":"10791","city_name":"Vallee-Jonction","state_id":"673"},
    {"city_id":"10792","city_name":"Vaudreuil","state_id":"673"},
    {"city_id":"10793","city_name":"Vercheres","state_id":"673"},
    {"city_id":"10794","city_name":"Victoriaville","state_id":"673"},
    {"city_id":"10795","city_name":"Ville-Marie","state_id":"673"},
    {"city_id":"10796","city_name":"Warwick","state_id":"673"},
    {"city_id":"10797","city_name":"Waterloo","state_id":"673"},
    {"city_id":"10798","city_name":"Weedon Centre","state_id":"673"},
    {"city_id":"10799","city_name":"Westmount","state_id":"673"},
    {"city_id":"10800","city_name":"Wickham","state_id":"673"},
    {"city_id":"10801","city_name":"Windsor","state_id":"673"},
    {"city_id":"10802","city_name":"Yamachiche","state_id":"673"},
    {"city_id":"10803","city_name":"Assiniboia","state_id":"674"},
    {"city_id":"10804","city_name":"Biggar","state_id":"674"},
    {"city_id":"10805","city_name":"Canora","state_id":"674"},
    {"city_id":"10806","city_name":"Carlyle","state_id":"674"},
    {"city_id":"10807","city_name":"Carnduff","state_id":"674"},
    {"city_id":"10808","city_name":"Caronport","state_id":"674"},
    {"city_id":"10809","city_name":"Carrot","state_id":"674"},
    {"city_id":"10810","city_name":"Dalmeny","state_id":"674"},
    {"city_id":"10811","city_name":"Davidson","state_id":"674"},
    {"city_id":"10812","city_name":"Esterhazy","state_id":"674"},
    {"city_id":"10813","city_name":"Estevan","state_id":"674"},
    {"city_id":"10814","city_name":"Eston","state_id":"674"},
    {"city_id":"10815","city_name":"Foam Lake","state_id":"674"},
    {"city_id":"10816","city_name":"Fort Qu'Appelle","state_id":"674"},
    {"city_id":"10817","city_name":"Gravelbourg","state_id":"674"},
    {"city_id":"10818","city_name":"Grenfell","state_id":"674"},
    {"city_id":"10819","city_name":"Gull Lake","state_id":"674"},
    {"city_id":"10820","city_name":"Hudson Bay","state_id":"674"},
    {"city_id":"10821","city_name":"Humboldt","state_id":"674"},
    {"city_id":"10822","city_name":"Indian Head","state_id":"674"},
    {"city_id":"10823","city_name":"Kamsack","state_id":"674"},
    {"city_id":"10824","city_name":"Kelvington","state_id":"674"},
    {"city_id":"10825","city_name":"Kerrobert","state_id":"674"},
    {"city_id":"10826","city_name":"Kindersley","state_id":"674"},
    {"city_id":"10827","city_name":"Kipling","state_id":"674"},
    {"city_id":"10828","city_name":"La Ronge","state_id":"674"},
    {"city_id":"10829","city_name":"Langenburg","state_id":"674"},
    {"city_id":"10830","city_name":"Langham","state_id":"674"},
    {"city_id":"10831","city_name":"Lanigan","state_id":"674"},
    {"city_id":"10832","city_name":"Lloydminster","state_id":"674"},
    {"city_id":"10833","city_name":"Lumsden","state_id":"674"},
    {"city_id":"10834","city_name":"Macklin","state_id":"674"},
    {"city_id":"10835","city_name":"Maple Creek","state_id":"674"},
    {"city_id":"10836","city_name":"Martensville","state_id":"674"},
    {"city_id":"10837","city_name":"Meadow Lake","state_id":"674"},
    {"city_id":"10838","city_name":"Melfort","state_id":"674"},
    {"city_id":"10839","city_name":"Melville","state_id":"674"},
    {"city_id":"10840","city_name":"Moose Jaw","state_id":"674"},
    {"city_id":"10841","city_name":"Moosomin","state_id":"674"},
    {"city_id":"10842","city_name":"Nipawin","state_id":"674"},
    {"city_id":"10843","city_name":"North Battleford","state_id":"674"},
    {"city_id":"10844","city_name":"Outlook","state_id":"674"},
    {"city_id":"10845","city_name":"Oxbow","state_id":"674"},
    {"city_id":"10846","city_name":"Pilot Butte","state_id":"674"},
    {"city_id":"10847","city_name":"Preeceville","state_id":"674"},
    {"city_id":"10848","city_name":"Prince Albert","state_id":"674"},
    {"city_id":"10849","city_name":"Regina","state_id":"674"},
    {"city_id":"10850","city_name":"Rosetown","state_id":"674"},
    {"city_id":"10851","city_name":"Rosthem","state_id":"674"},
    {"city_id":"10852","city_name":"Saskatoon","state_id":"674"},
    {"city_id":"10853","city_name":"Shaunavon","state_id":"674"},
    {"city_id":"10854","city_name":"Shellbrook","state_id":"674"},
    {"city_id":"10855","city_name":"Swift Current","state_id":"674"},
    {"city_id":"10856","city_name":"Tisdale","state_id":"674"},
    {"city_id":"10857","city_name":"Unity","state_id":"674"},
    {"city_id":"10858","city_name":"Wadena","state_id":"674"},
    {"city_id":"10859","city_name":"Warman","state_id":"674"},
    {"city_id":"10860","city_name":"Watrous","state_id":"674"},
    {"city_id":"10861","city_name":"Weyburn","state_id":"674"},
    {"city_id":"10862","city_name":"White City","state_id":"674"},
    {"city_id":"10863","city_name":"Wilkie","state_id":"674"},
    {"city_id":"10864","city_name":"Wynyard","state_id":"674"},
    {"city_id":"10865","city_name":"Yorkton","state_id":"674"},
    {"city_id":"10866","city_name":"Haines Junction","state_id":"675"},
    {"city_id":"10867","city_name":"Mayo","state_id":"675"},
    {"city_id":"10868","city_name":"Whitehorse","state_id":"675"},
    {"city_id":"10869","city_name":"Sal Rei","state_id":"676"},
    {"city_id":"10870","city_name":"Nova Sintra","state_id":"677"},
    {"city_id":"10871","city_name":"Mosteiros","state_id":"678"},
    {"city_id":"10872","city_name":"Sao Filipe","state_id":"678"},
    {"city_id":"10873","city_name":"Vila do Maio","state_id":"679"},
    {"city_id":"10874","city_name":"Santa Maria","state_id":"680"},
    {"city_id":"10875","city_name":"Ndele","state_id":"686"},
    {"city_id":"10876","city_name":"Bangui","state_id":"687"},
    {"city_id":"10877","city_name":"Alindao","state_id":"688"},
    {"city_id":"10878","city_name":"Kembe","state_id":"688"},
    {"city_id":"10879","city_name":"Mobaye","state_id":"688"},
    {"city_id":"10880","city_name":"Obo","state_id":"689"},
    {"city_id":"10881","city_name":"Zemio","state_id":"689"},
    {"city_id":"10882","city_name":"Bria","state_id":"690"},
    {"city_id":"10883","city_name":"Ouadda","state_id":"690"},
    {"city_id":"10884","city_name":"Dekoa","state_id":"691"},
    {"city_id":"10885","city_name":"Sibut","state_id":"691"},
    {"city_id":"10886","city_name":"Boda","state_id":"692"},
    {"city_id":"10887","city_name":"Mbaiki","state_id":"692"},
    {"city_id":"10888","city_name":"Mongoumba","state_id":"692"},
    {"city_id":"10889","city_name":"Berberati","state_id":"693"},
    {"city_id":"10890","city_name":"Carnot","state_id":"693"},
    {"city_id":"10891","city_name":"Gamboula","state_id":"693"},
    {"city_id":"10892","city_name":"Bangassou","state_id":"694"},
    {"city_id":"10893","city_name":"Gambo","state_id":"694"},
    {"city_id":"10894","city_name":"Ouango","state_id":"694"},
    {"city_id":"10895","city_name":"Rafai","state_id":"694"},
    {"city_id":"10896","city_name":"Kaga-Bandoro","state_id":"695"},
    {"city_id":"10897","city_name":"Baboua","state_id":"696"},
    {"city_id":"10898","city_name":"Baoro","state_id":"696"},
    {"city_id":"10899","city_name":"Bouar","state_id":"696"},
    {"city_id":"10900","city_name":"Bambari","state_id":"698"},
    {"city_id":"10901","city_name":"Grimari","state_id":"698"},
    {"city_id":"10902","city_name":"Ippy","state_id":"698"},
    {"city_id":"10903","city_name":"Kouango","state_id":"698"},
    {"city_id":"10904","city_name":"Batangafo","state_id":"699"},
    {"city_id":"10905","city_name":"Bossangoa","state_id":"699"},
    {"city_id":"10906","city_name":"Bouca","state_id":"699"},
    {"city_id":"10907","city_name":"Kabo","state_id":"699"},
    {"city_id":"10908","city_name":"Bocaranga","state_id":"700"},
    {"city_id":"10909","city_name":"Bozoum","state_id":"700"},
    {"city_id":"10910","city_name":"Paoua","state_id":"700"},
    {"city_id":"10911","city_name":"Nola","state_id":"701"},
    {"city_id":"10912","city_name":"Birao","state_id":"702"},
    {"city_id":"10913","city_name":"Ati","state_id":"703"},
    {"city_id":"10914","city_name":"Oum Hadjer","state_id":"703"},
    {"city_id":"10915","city_name":"Biltine","state_id":"704"},
    {"city_id":"10916","city_name":"Aouzou","state_id":"705"},
    {"city_id":"10917","city_name":"Bardai","state_id":"705"},
    {"city_id":"10918","city_name":"Fada","state_id":"705"},
    {"city_id":"10919","city_name":"Faya","state_id":"705"},
    {"city_id":"10920","city_name":"Bokoro","state_id":"706"},
    {"city_id":"10921","city_name":"Bousso","state_id":"706"},
    {"city_id":"10922","city_name":"Dourbali","state_id":"706"},
    {"city_id":"10923","city_name":"Massaguet","state_id":"706"},
    {"city_id":"10924","city_name":"Massakory","state_id":"706"},
    {"city_id":"10925","city_name":"Massenya","state_id":"706"},
    {"city_id":"10926","city_name":"N'Djamena","state_id":"706"},
    {"city_id":"10927","city_name":"Ngama","state_id":"706"},
    {"city_id":"10928","city_name":"Bitkine","state_id":"707"},
    {"city_id":"10929","city_name":"Melfi","state_id":"707"},
    {"city_id":"10930","city_name":"Mongo","state_id":"707"},
    {"city_id":"10931","city_name":"Mao","state_id":"708"},
    {"city_id":"10932","city_name":"Moussoro","state_id":"708"},
    {"city_id":"10933","city_name":"Rig-Rig","state_id":"708"},
    {"city_id":"10934","city_name":"Bol","state_id":"709"},
    {"city_id":"10935","city_name":"Bongor","state_id":"712"},
    {"city_id":"10936","city_name":"Fianga","state_id":"712"},
    {"city_id":"10937","city_name":"Gounou Gaya","state_id":"712"},
    {"city_id":"10938","city_name":"Guelengdeng","state_id":"712"},
    {"city_id":"10939","city_name":"Lere","state_id":"712"},
    {"city_id":"10940","city_name":"Pala","state_id":"712"},
    {"city_id":"10941","city_name":"Goundi","state_id":"713"},
    {"city_id":"10942","city_name":"Koumra","state_id":"713"},
    {"city_id":"10943","city_name":"Kyabe","state_id":"713"},
    {"city_id":"10944","city_name":"Moissala","state_id":"713"},
    {"city_id":"10945","city_name":"Sarh","state_id":"713"},
    {"city_id":"10946","city_name":"Abeche","state_id":"714"},
    {"city_id":"10947","city_name":"Adre","state_id":"714"},
    {"city_id":"10948","city_name":"Am Dam","state_id":"714"},
    {"city_id":"10949","city_name":"Abou Deia","state_id":"715"},
    {"city_id":"10950","city_name":"Am Timan","state_id":"715"},
    {"city_id":"10951","city_name":"Mangueigne","state_id":"715"},
    {"city_id":"10952","city_name":"Benoy","state_id":"716"},
    {"city_id":"10953","city_name":"Bere","state_id":"716"},
    {"city_id":"10954","city_name":"Kelo","state_id":"716"},
    {"city_id":"10955","city_name":"Lai","state_id":"716"},
    {"city_id":"10956","city_name":"Aisen","state_id":"717"},
    {"city_id":"10957","city_name":"Chile Chico","state_id":"717"},
    {"city_id":"10958","city_name":"Cisnes","state_id":"717"},
    {"city_id":"10959","city_name":"Cochrane","state_id":"717"},
    {"city_id":"10960","city_name":"Coihaique","state_id":"717"},
    {"city_id":"10961","city_name":"Guaitecas","state_id":"717"},
    {"city_id":"10962","city_name":"Lago Verde","state_id":"717"},
    {"city_id":"10963","city_name":"O'Higgins","state_id":"717"},
    {"city_id":"10964","city_name":"Rio Ibanez","state_id":"717"},
    {"city_id":"10965","city_name":"Tortel","state_id":"717"},
    {"city_id":"10966","city_name":"Antofagasta","state_id":"718"},
    {"city_id":"10967","city_name":"Calama","state_id":"718"},
    {"city_id":"10968","city_name":"Maria Elena","state_id":"718"},
    {"city_id":"10969","city_name":"Mejilones","state_id":"718"},
    {"city_id":"10970","city_name":"Ollague","state_id":"718"},
    {"city_id":"10971","city_name":"San Pedro de Atacama","state_id":"718"},
    {"city_id":"10972","city_name":"Sierra Gorda","state_id":"718"},
    {"city_id":"10973","city_name":"Taltal","state_id":"718"},
    {"city_id":"10974","city_name":"Tocopilla","state_id":"718"},
    {"city_id":"10975","city_name":"Angol","state_id":"719"},
    {"city_id":"10976","city_name":"Carahue","state_id":"719"},
    {"city_id":"10977","city_name":"Collipulli","state_id":"719"},
    {"city_id":"10978","city_name":"Cunco","state_id":"719"},
    {"city_id":"10979","city_name":"Curacautin","state_id":"719"},
    {"city_id":"10980","city_name":"Curarrehue","state_id":"719"},
    {"city_id":"10981","city_name":"Ercilla","state_id":"719"},
    {"city_id":"10982","city_name":"Freire","state_id":"719"},
    {"city_id":"10983","city_name":"Galvarino","state_id":"719"},
    {"city_id":"10984","city_name":"Gorbea","state_id":"719"},
    {"city_id":"10985","city_name":"Lautaro","state_id":"719"},
    {"city_id":"10986","city_name":"Loncoche","state_id":"719"},
    {"city_id":"10987","city_name":"Lonquimay","state_id":"719"},
    {"city_id":"10988","city_name":"Los Sauces","state_id":"719"},
    {"city_id":"10989","city_name":"Lumaco","state_id":"719"},
    {"city_id":"10990","city_name":"Melipeuco","state_id":"719"},
    {"city_id":"10991","city_name":"Nueva Imperial","state_id":"719"},
    {"city_id":"10992","city_name":"Padre las Casas","state_id":"719"},
    {"city_id":"10993","city_name":"Perquenco","state_id":"719"},
    {"city_id":"10994","city_name":"Pitrufquen","state_id":"719"},
    {"city_id":"10995","city_name":"Pucon","state_id":"719"},
    {"city_id":"10996","city_name":"Puren","state_id":"719"},
    {"city_id":"10997","city_name":"Renaico","state_id":"719"},
    {"city_id":"10998","city_name":"Saavedra","state_id":"719"},
    {"city_id":"10999","city_name":"Temuco","state_id":"719"},
    {"city_id":"11000","city_name":"Teodoro Schmidt","state_id":"719"},
    {"city_id":"11001","city_name":"Tolten","state_id":"719"},
    {"city_id":"11002","city_name":"Traiguen","state_id":"719"},
    {"city_id":"11003","city_name":"Victoria","state_id":"719"},
    {"city_id":"11004","city_name":"Vilcun","state_id":"719"},
    {"city_id":"11005","city_name":"Villarica","state_id":"719"},
    {"city_id":"11006","city_name":"Alto del Carmen","state_id":"720"},
    {"city_id":"11007","city_name":"Caldera","state_id":"720"},
    {"city_id":"11008","city_name":"Chanaral","state_id":"720"},
    {"city_id":"11009","city_name":"Copiapo","state_id":"720"},
    {"city_id":"11010","city_name":"Diego de Almagro","state_id":"720"},
    {"city_id":"11011","city_name":"Freirina","state_id":"720"},
    {"city_id":"11012","city_name":"Huasco","state_id":"720"},
    {"city_id":"11013","city_name":"Tierra Amarilla","state_id":"720"},
    {"city_id":"11014","city_name":"Vallenar","state_id":"720"},
    {"city_id":"11015","city_name":"Andacollo","state_id":"722"},
    {"city_id":"11016","city_name":"Canela","state_id":"722"},
    {"city_id":"11017","city_name":"Combarbala","state_id":"722"},
    {"city_id":"11018","city_name":"Coquimbo","state_id":"722"},
    {"city_id":"11019","city_name":"Illapel","state_id":"722"},
    {"city_id":"11020","city_name":"La Higuera","state_id":"722"},
    {"city_id":"11021","city_name":"La Serena","state_id":"722"},
    {"city_id":"11022","city_name":"Los Vilos","state_id":"722"},
    {"city_id":"11023","city_name":"Monte Patria","state_id":"722"},
    {"city_id":"11024","city_name":"Ovalle","state_id":"722"},
    {"city_id":"11025","city_name":"Paiguano","state_id":"722"},
    {"city_id":"11026","city_name":"Punitaci","state_id":"722"},
    {"city_id":"11027","city_name":"Rio Hurtado","state_id":"722"},
    {"city_id":"11028","city_name":"Salamanca","state_id":"722"},
    {"city_id":"11029","city_name":"Vicuna","state_id":"722"},
    {"city_id":"11030","city_name":"Cabo de Horno","state_id":"725"},
    {"city_id":"11031","city_name":"Laguna Blanca","state_id":"725"},
    {"city_id":"11032","city_name":"Natales","state_id":"725"},
    {"city_id":"11033","city_name":"Porvenir","state_id":"725"},
    {"city_id":"11034","city_name":"Primavera","state_id":"725"},
    {"city_id":"11035","city_name":"Punta Arenas","state_id":"725"},
    {"city_id":"11036","city_name":"Rio Verde","state_id":"725"},
    {"city_id":"11037","city_name":"San Gregorio","state_id":"725"},
    {"city_id":"11038","city_name":"Timaukel","state_id":"725"},
    {"city_id":"11039","city_name":"Torres del Paine","state_id":"725"},
    {"city_id":"11040","city_name":"Cauquenes","state_id":"726"},
    {"city_id":"11041","city_name":"Chanco","state_id":"726"},
    {"city_id":"11042","city_name":"Colbun","state_id":"726"},
    {"city_id":"11043","city_name":"Constitucion","state_id":"726"},
    {"city_id":"11044","city_name":"Curepto","state_id":"726"},
    {"city_id":"11045","city_name":"Curico","state_id":"726"},
    {"city_id":"11046","city_name":"Empedrado","state_id":"726"},
    {"city_id":"11047","city_name":"Hualane","state_id":"726"},
    {"city_id":"11048","city_name":"Licanten","state_id":"726"},
    {"city_id":"11049","city_name":"Linares","state_id":"726"},
    {"city_id":"11050","city_name":"Longavi","state_id":"726"},
    {"city_id":"11051","city_name":"Maule","state_id":"726"},
    {"city_id":"11052","city_name":"Molina","state_id":"726"},
    {"city_id":"11053","city_name":"Parral","state_id":"726"},
    {"city_id":"11054","city_name":"Pelarco","state_id":"726"},
    {"city_id":"11055","city_name":"Pelluhue","state_id":"726"},
    {"city_id":"11056","city_name":"Pencahue","state_id":"726"},
    {"city_id":"11057","city_name":"Rauco","state_id":"726"},
    {"city_id":"11058","city_name":"Retiro","state_id":"726"},
    {"city_id":"11059","city_name":"Rio Claro","state_id":"726"},
    {"city_id":"11060","city_name":"Romeral","state_id":"726"},
    {"city_id":"11061","city_name":"Sagrada Familia","state_id":"726"},
    {"city_id":"11062","city_name":"San Clemente","state_id":"726"},
    {"city_id":"11063","city_name":"San Javier","state_id":"726"},
    {"city_id":"11064","city_name":"San Rafael","state_id":"726"},
    {"city_id":"11065","city_name":"Talca","state_id":"726"},
    {"city_id":"11066","city_name":"Teno","state_id":"726"},
    {"city_id":"11067","city_name":"Vichuquen","state_id":"726"},
    {"city_id":"11068","city_name":"Villa Alegre","state_id":"726"},
    {"city_id":"11069","city_name":"Yerbas Buenas","state_id":"726"},
    {"city_id":"11070","city_name":"Alhue","state_id":"727"},
    {"city_id":"11071","city_name":"Buin","state_id":"727"},
    {"city_id":"11072","city_name":"Calera de Tango","state_id":"727"},
    {"city_id":"11073","city_name":"Colina","state_id":"727"},
    {"city_id":"11074","city_name":"Curacavi","state_id":"727"},
    {"city_id":"11075","city_name":"El Monte","state_id":"727"},
    {"city_id":"11076","city_name":"Isla de Maipo","state_id":"727"},
    {"city_id":"11077","city_name":"Lampa","state_id":"727"},
    {"city_id":"11078","city_name":"Maria Pinto","state_id":"727"},
    {"city_id":"11079","city_name":"Melipilla","state_id":"727"},
    {"city_id":"11080","city_name":"Padre Hurtado","state_id":"727"},
    {"city_id":"11081","city_name":"Paine","state_id":"727"},
    {"city_id":"11082","city_name":"Penaflor","state_id":"727"},
    {"city_id":"11083","city_name":"Pirque","state_id":"727"},
    {"city_id":"11084","city_name":"Puente Alto","state_id":"727"},
    {"city_id":"11085","city_name":"Quilicura","state_id":"727"},
    {"city_id":"11086","city_name":"San Bernardo","state_id":"727"},
    {"city_id":"11087","city_name":"San Jose de Maipo","state_id":"727"},
    {"city_id":"11088","city_name":"San Pedro","state_id":"727"},
    {"city_id":"11089","city_name":"Santiago","state_id":"727"},
    {"city_id":"11090","city_name":"Talagante","state_id":"727"},
    {"city_id":"11091","city_name":"Tiltil","state_id":"727"},
    {"city_id":"11092","city_name":"Alhue","state_id":"728"},
    {"city_id":"11093","city_name":"Buin","state_id":"728"},
    {"city_id":"11094","city_name":"Calera de Tango","state_id":"728"},
    {"city_id":"11095","city_name":"Colina","state_id":"728"},
    {"city_id":"11096","city_name":"Curacavi","state_id":"728"},
    {"city_id":"11097","city_name":"El Monte","state_id":"728"},
    {"city_id":"11098","city_name":"Isla de Maipo","state_id":"728"},
    {"city_id":"11099","city_name":"Lampa","state_id":"728"},
    {"city_id":"11100","city_name":"Maria Pinto","state_id":"728"},
    {"city_id":"11101","city_name":"Melipilla","state_id":"728"},
    {"city_id":"11102","city_name":"Padre Hurtado","state_id":"728"},
    {"city_id":"11103","city_name":"Paine","state_id":"728"},
    {"city_id":"11104","city_name":"Penaflor","state_id":"728"},
    {"city_id":"11105","city_name":"Pirque","state_id":"728"},
    {"city_id":"11106","city_name":"Puente Alto","state_id":"728"},
    {"city_id":"11107","city_name":"Quilicura","state_id":"728"},
    {"city_id":"11108","city_name":"San Bernardo","state_id":"728"},
    {"city_id":"11109","city_name":"San Jose de Maipo","state_id":"728"},
    {"city_id":"11110","city_name":"San Pedro","state_id":"728"},
    {"city_id":"11111","city_name":"Santiago","state_id":"728"},
    {"city_id":"11112","city_name":"Talagante","state_id":"728"},
    {"city_id":"11113","city_name":"Tiltil","state_id":"728"},
    {"city_id":"11114","city_name":"Arica","state_id":"729"},
    {"city_id":"11115","city_name":"Camarones","state_id":"729"},
    {"city_id":"11116","city_name":"Camina","state_id":"729"},
    {"city_id":"11117","city_name":"Colchane","state_id":"729"},
    {"city_id":"11118","city_name":"General Lagos","state_id":"729"},
    {"city_id":"11119","city_name":"Huara","state_id":"729"},
    {"city_id":"11120","city_name":"Iquique","state_id":"729"},
    {"city_id":"11121","city_name":"Pica","state_id":"729"},
    {"city_id":"11122","city_name":"Pozo Almonte","state_id":"729"},
    {"city_id":"11123","city_name":"Putre","state_id":"729"},
    {"city_id":"11124","city_name":"Algarrobo","state_id":"730"},
    {"city_id":"11125","city_name":"Cabildo","state_id":"730"},
    {"city_id":"11126","city_name":"Calera","state_id":"730"},
    {"city_id":"11127","city_name":"Calle Larga","state_id":"730"},
    {"city_id":"11128","city_name":"Cartagena","state_id":"730"},
    {"city_id":"11129","city_name":"Casablanca","state_id":"730"},
    {"city_id":"11130","city_name":"Catemu","state_id":"730"},
    {"city_id":"11131","city_name":"Concon","state_id":"730"},
    {"city_id":"11132","city_name":"El Quisco","state_id":"730"},
    {"city_id":"11133","city_name":"El Tabo","state_id":"730"},
    {"city_id":"11134","city_name":"Hijuelas","state_id":"730"},
    {"city_id":"11135","city_name":"La Cruz","state_id":"730"},
    {"city_id":"11136","city_name":"La Ligua","state_id":"730"},
    {"city_id":"11137","city_name":"Limache","state_id":"730"},
    {"city_id":"11138","city_name":"Llaillay","state_id":"730"},
    {"city_id":"11139","city_name":"Los Andes","state_id":"730"},
    {"city_id":"11140","city_name":"Nogales","state_id":"730"},
    {"city_id":"11141","city_name":"Olmue","state_id":"730"},
    {"city_id":"11142","city_name":"Panquehue","state_id":"730"},
    {"city_id":"11143","city_name":"Papudo","state_id":"730"},
    {"city_id":"11144","city_name":"Petorca","state_id":"730"},
    {"city_id":"11145","city_name":"Puchuncavi","state_id":"730"},
    {"city_id":"11146","city_name":"Putaendeo","state_id":"730"},
    {"city_id":"11147","city_name":"Quillota","state_id":"730"},
    {"city_id":"11148","city_name":"Quilpue","state_id":"730"},
    {"city_id":"11149","city_name":"Quintero","state_id":"730"},
    {"city_id":"11150","city_name":"Rinconada","state_id":"730"},
    {"city_id":"11151","city_name":"San Antonio","state_id":"730"},
    {"city_id":"11152","city_name":"San Esteban","state_id":"730"},
    {"city_id":"11153","city_name":"San Felipe","state_id":"730"},
    {"city_id":"11154","city_name":"Santa Maria","state_id":"730"},
    {"city_id":"11155","city_name":"Santo Domingo","state_id":"730"},
    {"city_id":"11156","city_name":"Valparaiso","state_id":"730"},
    {"city_id":"11157","city_name":"Villa Alemana","state_id":"730"},
    {"city_id":"11158","city_name":"Vina del Mar","state_id":"730"},
    {"city_id":"11159","city_name":"Zapallar","state_id":"730"},
    {"city_id":"11160","city_name":"Fengyang","state_id":"731"},
    {"city_id":"11161","city_name":"Guangde","state_id":"731"},
    {"city_id":"11162","city_name":"Liuan","state_id":"731"},
    {"city_id":"11163","city_name":"Ningguo","state_id":"731"},
    {"city_id":"11164","city_name":"Shucheng","state_id":"731"},
    {"city_id":"11165","city_name":"Xinchang","state_id":"731"},
    {"city_id":"11166","city_name":"Xuancheng","state_id":"731"},
    {"city_id":"11167","city_name":"Fengyang","state_id":"732"},
    {"city_id":"11168","city_name":"Guangde","state_id":"732"},
    {"city_id":"11169","city_name":"Liuan","state_id":"732"},
    {"city_id":"11170","city_name":"Ningguo","state_id":"732"},
    {"city_id":"11171","city_name":"Shucheng","state_id":"732"},
    {"city_id":"11172","city_name":"Xinchang","state_id":"732"},
    {"city_id":"11173","city_name":"Xuancheng","state_id":"732"},
    {"city_id":"11174","city_name":"Fengyang","state_id":"733"},
    {"city_id":"11175","city_name":"Guangde","state_id":"733"},
    {"city_id":"11176","city_name":"Liuan","state_id":"733"},
    {"city_id":"11177","city_name":"Ningguo","state_id":"733"},
    {"city_id":"11178","city_name":"Shucheng","state_id":"733"},
    {"city_id":"11179","city_name":"Xinchang","state_id":"733"},
    {"city_id":"11180","city_name":"Xuancheng","state_id":"733"},
    {"city_id":"11181","city_name":"Aomen","state_id":"734"},
    {"city_id":"11182","city_name":"Beijing","state_id":"735"},
    {"city_id":"11183","city_name":"Changping","state_id":"735"},
    {"city_id":"11184","city_name":"Fangshan","state_id":"735"},
    {"city_id":"11185","city_name":"Huangcun","state_id":"735"},
    {"city_id":"11186","city_name":"Liangxiang","state_id":"735"},
    {"city_id":"11187","city_name":"Mentougou","state_id":"735"},
    {"city_id":"11188","city_name":"Shunyi","state_id":"735"},
    {"city_id":"11189","city_name":"Tongzhou","state_id":"735"},
    {"city_id":"11190","city_name":"Beijing","state_id":"736"},
    {"city_id":"11191","city_name":"Changping","state_id":"736"},
    {"city_id":"11192","city_name":"Fangshan","state_id":"736"},
    {"city_id":"11193","city_name":"Huangcun","state_id":"736"},
    {"city_id":"11194","city_name":"Liangxiang","state_id":"736"},
    {"city_id":"11195","city_name":"Mentougou","state_id":"736"},
    {"city_id":"11196","city_name":"Shunyi","state_id":"736"},
    {"city_id":"11197","city_name":"Tongzhou","state_id":"736"},
    {"city_id":"11198","city_name":"Beibei","state_id":"737"},
    {"city_id":"11199","city_name":"Chongqing","state_id":"737"},
    {"city_id":"11200","city_name":"Fuling","state_id":"737"},
    {"city_id":"11201","city_name":"Longhua","state_id":"737"},
    {"city_id":"11202","city_name":"Nantongkuang","state_id":"737"},
    {"city_id":"11203","city_name":"Wanxian","state_id":"737"},
    {"city_id":"11204","city_name":"Xiuma","state_id":"737"},
    {"city_id":"11205","city_name":"Yubei","state_id":"737"},
    {"city_id":"11206","city_name":"Yudong","state_id":"737"},
    {"city_id":"11207","city_name":"Bantou","state_id":"738"},
    {"city_id":"11208","city_name":"Dongshan","state_id":"738"},
    {"city_id":"11209","city_name":"Fuan","state_id":"738"},
    {"city_id":"11210","city_name":"Fujian","state_id":"738"},
    {"city_id":"11211","city_name":"Fuqing","state_id":"738"},
    {"city_id":"11212","city_name":"Fuzhou","state_id":"738"},
    {"city_id":"11213","city_name":"Gantou","state_id":"738"},
    {"city_id":"11214","city_name":"Hanyang","state_id":"738"},
    {"city_id":"11215","city_name":"Jiangkou","state_id":"738"},
    {"city_id":"11216","city_name":"Jiaocheng","state_id":"738"},
    {"city_id":"11217","city_name":"Jinjiang","state_id":"738"},
    {"city_id":"11218","city_name":"Jinshang","state_id":"738"},
    {"city_id":"11219","city_name":"Longhai","state_id":"738"},
    {"city_id":"11220","city_name":"Longyan","state_id":"738"},
    {"city_id":"11221","city_name":"Luoyang","state_id":"738"},
    {"city_id":"11222","city_name":"Nanan","state_id":"738"},
    {"city_id":"11223","city_name":"Nanping","state_id":"738"},
    {"city_id":"11224","city_name":"Nanpu","state_id":"738"},
    {"city_id":"11225","city_name":"Putian","state_id":"738"},
    {"city_id":"11226","city_name":"Qingyang","state_id":"738"},
    {"city_id":"11227","city_name":"Quanzhou","state_id":"738"},
    {"city_id":"11228","city_name":"Rongcheng","state_id":"738"},
    {"city_id":"11229","city_name":"Sanming","state_id":"738"},
    {"city_id":"11230","city_name":"Shaowu","state_id":"738"},
    {"city_id":"11231","city_name":"Shima","state_id":"738"},
    {"city_id":"11232","city_name":"Shishi","state_id":"738"},
    {"city_id":"11233","city_name":"Tantou","state_id":"738"},
    {"city_id":"11234","city_name":"Tongshan","state_id":"738"},
    {"city_id":"11235","city_name":"Xiamen","state_id":"738"},
    {"city_id":"11236","city_name":"Xiapu","state_id":"738"},
    {"city_id":"11237","city_name":"Xiapu Ningde","state_id":"738"},
    {"city_id":"11238","city_name":"Ximei","state_id":"738"},
    {"city_id":"11239","city_name":"Yongan","state_id":"738"},
    {"city_id":"11240","city_name":"Zhangzhou","state_id":"738"},
    {"city_id":"11241","city_name":"Zhicheng","state_id":"738"},
    {"city_id":"11242","city_name":"Bantou","state_id":"739"},
    {"city_id":"11243","city_name":"Dongshan","state_id":"739"},
    {"city_id":"11244","city_name":"Fuan","state_id":"739"},
    {"city_id":"11245","city_name":"Fujian","state_id":"739"},
    {"city_id":"11246","city_name":"Fuqing","state_id":"739"},
    {"city_id":"11247","city_name":"Fuzhou","state_id":"739"},
    {"city_id":"11248","city_name":"Gantou","state_id":"739"},
    {"city_id":"11249","city_name":"Hanyang","state_id":"739"},
    {"city_id":"11250","city_name":"Jiangkou","state_id":"739"},
    {"city_id":"11251","city_name":"Jiaocheng","state_id":"739"},
    {"city_id":"11252","city_name":"Jinjiang","state_id":"739"},
    {"city_id":"11253","city_name":"Jinshang","state_id":"739"},
    {"city_id":"11254","city_name":"Longhai","state_id":"739"},
    {"city_id":"11255","city_name":"Longyan","state_id":"739"},
    {"city_id":"11256","city_name":"Luoyang","state_id":"739"},
    {"city_id":"11257","city_name":"Nanan","state_id":"739"},
    {"city_id":"11258","city_name":"Nanping","state_id":"739"},
    {"city_id":"11259","city_name":"Nanpu","state_id":"739"},
    {"city_id":"11260","city_name":"Putian","state_id":"739"},
    {"city_id":"11261","city_name":"Qingyang","state_id":"739"},
    {"city_id":"11262","city_name":"Quanzhou","state_id":"739"},
    {"city_id":"11263","city_name":"Rongcheng","state_id":"739"},
    {"city_id":"11264","city_name":"Sanming","state_id":"739"},
    {"city_id":"11265","city_name":"Shaowu","state_id":"739"},
    {"city_id":"11266","city_name":"Shima","state_id":"739"},
    {"city_id":"11267","city_name":"Shishi","state_id":"739"},
    {"city_id":"11268","city_name":"Tantou","state_id":"739"},
    {"city_id":"11269","city_name":"Tongshan","state_id":"739"},
    {"city_id":"11270","city_name":"Xiamen","state_id":"739"},
    {"city_id":"11271","city_name":"Xiapu","state_id":"739"},
    {"city_id":"11272","city_name":"Xiapu Ningde","state_id":"739"},
    {"city_id":"11273","city_name":"Ximei","state_id":"739"},
    {"city_id":"11274","city_name":"Yongan","state_id":"739"},
    {"city_id":"11275","city_name":"Zhangzhou","state_id":"739"},
    {"city_id":"11276","city_name":"Zhicheng","state_id":"739"},
    {"city_id":"11277","city_name":"Baiyin","state_id":"740"},
    {"city_id":"11278","city_name":"Baoji","state_id":"740"},
    {"city_id":"11279","city_name":"Beidao","state_id":"740"},
    {"city_id":"11280","city_name":"Jiayuguan","state_id":"740"},
    {"city_id":"11281","city_name":"Jinchang","state_id":"740"},
    {"city_id":"11282","city_name":"Jiuquan","state_id":"740"},
    {"city_id":"11283","city_name":"Lanzhou","state_id":"740"},
    {"city_id":"11284","city_name":"Linxia","state_id":"740"},
    {"city_id":"11285","city_name":"Pingliang","state_id":"740"},
    {"city_id":"11286","city_name":"Qincheng","state_id":"740"},
    {"city_id":"11287","city_name":"Wuwei","state_id":"740"},
    {"city_id":"11288","city_name":"Yaojie","state_id":"740"},
    {"city_id":"11289","city_name":"Yumen","state_id":"740"},
    {"city_id":"11290","city_name":"Zhangye","state_id":"740"},
    {"city_id":"11291","city_name":"Zhuanglang","state_id":"740"},
    {"city_id":"11292","city_name":"Anbu","state_id":"741"},
    {"city_id":"11293","city_name":"Chaozhou","state_id":"741"},
    {"city_id":"11294","city_name":"Chenghai","state_id":"741"},
    {"city_id":"11295","city_name":"Chuncheng","state_id":"741"},
    {"city_id":"11296","city_name":"Daliang","state_id":"741"},
    {"city_id":"11297","city_name":"Danshui","state_id":"741"},
    {"city_id":"11298","city_name":"Dongguan","state_id":"741"},
    {"city_id":"11299","city_name":"Donghai","state_id":"741"},
    {"city_id":"11300","city_name":"Dongli","state_id":"741"},
    {"city_id":"11301","city_name":"Dongzhen","state_id":"741"},
    {"city_id":"11302","city_name":"Ducheng","state_id":"741"},
    {"city_id":"11303","city_name":"Encheng","state_id":"741"},
    {"city_id":"11304","city_name":"Foahn","state_id":"741"},
    {"city_id":"11305","city_name":"Foshan","state_id":"741"},
    {"city_id":"11306","city_name":"Gaozhou","state_id":"741"},
    {"city_id":"11307","city_name":"Guangdong","state_id":"741"},
    {"city_id":"11308","city_name":"Guangzhou","state_id":"741"},
    {"city_id":"11309","city_name":"Guanjiao","state_id":"741"},
    {"city_id":"11310","city_name":"Haicheng","state_id":"741"},
    {"city_id":"11311","city_name":"Haimen","state_id":"741"},
    {"city_id":"11312","city_name":"Hepo","state_id":"741"},
    {"city_id":"11313","city_name":"Houpu","state_id":"741"},
    {"city_id":"11314","city_name":"Huaicheng","state_id":"741"},
    {"city_id":"11315","city_name":"Huanggang","state_id":"741"},
    {"city_id":"11316","city_name":"Huangpu","state_id":"741"},
    {"city_id":"11317","city_name":"Huazhou","state_id":"741"},
    {"city_id":"11318","city_name":"Huicheng","state_id":"741"},
    {"city_id":"11319","city_name":"Huizhou","state_id":"741"},
    {"city_id":"11320","city_name":"Humen","state_id":"741"},
    {"city_id":"11321","city_name":"Jiangmen","state_id":"741"},
    {"city_id":"11322","city_name":"Jiazi","state_id":"741"},
    {"city_id":"11323","city_name":"Jieshi","state_id":"741"},
    {"city_id":"11324","city_name":"Jieyang","state_id":"741"},
    {"city_id":"11325","city_name":"Lecheng","state_id":"741"},
    {"city_id":"11326","city_name":"Leicheng","state_id":"741"},
    {"city_id":"11327","city_name":"Liancheng","state_id":"741"},
    {"city_id":"11328","city_name":"Lianzhou","state_id":"741"},
    {"city_id":"11329","city_name":"Licheng","state_id":"741"},
    {"city_id":"11330","city_name":"Liusha","state_id":"741"},
    {"city_id":"11331","city_name":"Longgang","state_id":"741"},
    {"city_id":"11332","city_name":"Lubu","state_id":"741"},
    {"city_id":"11333","city_name":"Luocheng","state_id":"741"},
    {"city_id":"11334","city_name":"Luohu","state_id":"741"},
    {"city_id":"11335","city_name":"Luoyang","state_id":"741"},
    {"city_id":"11336","city_name":"Maba","state_id":"741"},
    {"city_id":"11337","city_name":"Maoming","state_id":"741"},
    {"city_id":"11338","city_name":"Mata","state_id":"741"},
    {"city_id":"11339","city_name":"Meilu","state_id":"741"},
    {"city_id":"11340","city_name":"Meizhou","state_id":"741"},
    {"city_id":"11341","city_name":"Mianchang","state_id":"741"},
    {"city_id":"11342","city_name":"Nanfeng","state_id":"741"},
    {"city_id":"11343","city_name":"Nanhai","state_id":"741"},
    {"city_id":"11344","city_name":"Pingshan","state_id":"741"},
    {"city_id":"11345","city_name":"Qingtang","state_id":"741"},
    {"city_id":"11346","city_name":"Qingyuan","state_id":"741"},
    {"city_id":"11347","city_name":"Rongcheng","state_id":"741"},
    {"city_id":"11348","city_name":"Sanbu","state_id":"741"},
    {"city_id":"11349","city_name":"Shantou","state_id":"741"},
    {"city_id":"11350","city_name":"Shanwei","state_id":"741"},
    {"city_id":"11351","city_name":"Shaoguan","state_id":"741"},
    {"city_id":"11352","city_name":"Shaping","state_id":"741"},
    {"city_id":"11353","city_name":"Shenzhen","state_id":"741"},
    {"city_id":"11354","city_name":"Shilong","state_id":"741"},
    {"city_id":"11355","city_name":"Shiqiao","state_id":"741"},
    {"city_id":"11356","city_name":"Shiwan","state_id":"741"},
    {"city_id":"11357","city_name":"Shuizhai","state_id":"741"},
    {"city_id":"11358","city_name":"Shunde","state_id":"741"},
    {"city_id":"11359","city_name":"Suicheng","state_id":"741"},
    {"city_id":"11360","city_name":"Taicheng","state_id":"741"},
    {"city_id":"11361","city_name":"Tangping","state_id":"741"},
    {"city_id":"11362","city_name":"Xiaolan","state_id":"741"},
    {"city_id":"11363","city_name":"Xinan","state_id":"741"},
    {"city_id":"11364","city_name":"Xingcheng","state_id":"741"},
    {"city_id":"11365","city_name":"Xiongzhou","state_id":"741"},
    {"city_id":"11366","city_name":"Xucheng","state_id":"741"},
    {"city_id":"11367","city_name":"Yangjiang","state_id":"741"},
    {"city_id":"11368","city_name":"Yingcheng","state_id":"741"},
    {"city_id":"11369","city_name":"Yuancheng","state_id":"741"},
    {"city_id":"11370","city_name":"Yuncheng","state_id":"741"},
    {"city_id":"11371","city_name":"Yunfu","state_id":"741"},
    {"city_id":"11372","city_name":"Zengcheng","state_id":"741"},
    {"city_id":"11373","city_name":"Zhanjiang","state_id":"741"},
    {"city_id":"11374","city_name":"Zhaoqing","state_id":"741"},
    {"city_id":"11375","city_name":"Zhilong","state_id":"741"},
    {"city_id":"11376","city_name":"Zhongshan","state_id":"741"},
    {"city_id":"11377","city_name":"Zhuhai","state_id":"741"},
    {"city_id":"11378","city_name":"Anbu","state_id":"742"},
    {"city_id":"11379","city_name":"Chaozhou","state_id":"742"},
    {"city_id":"11380","city_name":"Chenghai","state_id":"742"},
    {"city_id":"11381","city_name":"Chuncheng","state_id":"742"},
    {"city_id":"11382","city_name":"Daliang","state_id":"742"},
    {"city_id":"11383","city_name":"Danshui","state_id":"742"},
    {"city_id":"11384","city_name":"Dongguan","state_id":"742"},
    {"city_id":"11385","city_name":"Donghai","state_id":"742"},
    {"city_id":"11386","city_name":"Dongli","state_id":"742"},
    {"city_id":"11387","city_name":"Dongzhen","state_id":"742"},
    {"city_id":"11388","city_name":"Ducheng","state_id":"742"},
    {"city_id":"11389","city_name":"Encheng","state_id":"742"},
    {"city_id":"11390","city_name":"Foahn","state_id":"742"},
    {"city_id":"11391","city_name":"Foshan","state_id":"742"},
    {"city_id":"11392","city_name":"Gaozhou","state_id":"742"},
    {"city_id":"11393","city_name":"Guangdong","state_id":"742"},
    {"city_id":"11394","city_name":"Guangzhou","state_id":"742"},
    {"city_id":"11395","city_name":"Guanjiao","state_id":"742"},
    {"city_id":"11396","city_name":"Haicheng","state_id":"742"},
    {"city_id":"11397","city_name":"Haimen","state_id":"742"},
    {"city_id":"11398","city_name":"Hepo","state_id":"742"},
    {"city_id":"11399","city_name":"Houpu","state_id":"742"},
    {"city_id":"11400","city_name":"Huaicheng","state_id":"742"},
    {"city_id":"11401","city_name":"Huanggang","state_id":"742"},
    {"city_id":"11402","city_name":"Huangpu","state_id":"742"},
    {"city_id":"11403","city_name":"Huazhou","state_id":"742"},
    {"city_id":"11404","city_name":"Huicheng","state_id":"742"},
    {"city_id":"11405","city_name":"Huizhou","state_id":"742"},
    {"city_id":"11406","city_name":"Humen","state_id":"742"},
    {"city_id":"11407","city_name":"Jiangmen","state_id":"742"},
    {"city_id":"11408","city_name":"Jiazi","state_id":"742"},
    {"city_id":"11409","city_name":"Jieshi","state_id":"742"},
    {"city_id":"11410","city_name":"Jieyang","state_id":"742"},
    {"city_id":"11411","city_name":"Lecheng","state_id":"742"},
    {"city_id":"11412","city_name":"Leicheng","state_id":"742"},
    {"city_id":"11413","city_name":"Liancheng","state_id":"742"},
    {"city_id":"11414","city_name":"Lianzhou","state_id":"742"},
    {"city_id":"11415","city_name":"Licheng","state_id":"742"},
    {"city_id":"11416","city_name":"Liusha","state_id":"742"},
    {"city_id":"11417","city_name":"Longgang","state_id":"742"},
    {"city_id":"11418","city_name":"Lubu","state_id":"742"},
    {"city_id":"11419","city_name":"Luocheng","state_id":"742"},
    {"city_id":"11420","city_name":"Luohu","state_id":"742"},
    {"city_id":"11421","city_name":"Luoyang","state_id":"742"},
    {"city_id":"11422","city_name":"Maba","state_id":"742"},
    {"city_id":"11423","city_name":"Maoming","state_id":"742"},
    {"city_id":"11424","city_name":"Mata","state_id":"742"},
    {"city_id":"11425","city_name":"Meilu","state_id":"742"},
    {"city_id":"11426","city_name":"Meizhou","state_id":"742"},
    {"city_id":"11427","city_name":"Mianchang","state_id":"742"},
    {"city_id":"11428","city_name":"Nanfeng","state_id":"742"},
    {"city_id":"11429","city_name":"Nanhai","state_id":"742"},
    {"city_id":"11430","city_name":"Pingshan","state_id":"742"},
    {"city_id":"11431","city_name":"Qingtang","state_id":"742"},
    {"city_id":"11432","city_name":"Qingyuan","state_id":"742"},
    {"city_id":"11433","city_name":"Rongcheng","state_id":"742"},
    {"city_id":"11434","city_name":"Sanbu","state_id":"742"},
    {"city_id":"11435","city_name":"Shantou","state_id":"742"},
    {"city_id":"11436","city_name":"Shanwei","state_id":"742"},
    {"city_id":"11437","city_name":"Shaoguan","state_id":"742"},
    {"city_id":"11438","city_name":"Shaping","state_id":"742"},
    {"city_id":"11439","city_name":"Shenzhen","state_id":"742"},
    {"city_id":"11440","city_name":"Shilong","state_id":"742"},
    {"city_id":"11441","city_name":"Shiqiao","state_id":"742"},
    {"city_id":"11442","city_name":"Shiwan","state_id":"742"},
    {"city_id":"11443","city_name":"Shuizhai","state_id":"742"},
    {"city_id":"11444","city_name":"Shunde","state_id":"742"},
    {"city_id":"11445","city_name":"Suicheng","state_id":"742"},
    {"city_id":"11446","city_name":"Taicheng","state_id":"742"},
    {"city_id":"11447","city_name":"Tangping","state_id":"742"},
    {"city_id":"11448","city_name":"Xiaolan","state_id":"742"},
    {"city_id":"11449","city_name":"Xinan","state_id":"742"},
    {"city_id":"11450","city_name":"Xingcheng","state_id":"742"},
    {"city_id":"11451","city_name":"Xiongzhou","state_id":"742"},
    {"city_id":"11452","city_name":"Xucheng","state_id":"742"},
    {"city_id":"11453","city_name":"Yangjiang","state_id":"742"},
    {"city_id":"11454","city_name":"Yingcheng","state_id":"742"},
    {"city_id":"11455","city_name":"Yuancheng","state_id":"742"},
    {"city_id":"11456","city_name":"Yuncheng","state_id":"742"},
    {"city_id":"11457","city_name":"Yunfu","state_id":"742"},
    {"city_id":"11458","city_name":"Zengcheng","state_id":"742"},
    {"city_id":"11459","city_name":"Zhanjiang","state_id":"742"},
    {"city_id":"11460","city_name":"Zhaoqing","state_id":"742"},
    {"city_id":"11461","city_name":"Zhilong","state_id":"742"},
    {"city_id":"11462","city_name":"Zhongshan","state_id":"742"},
    {"city_id":"11463","city_name":"Zhuhai","state_id":"742"},
    {"city_id":"11464","city_name":"Babu","state_id":"743"},
    {"city_id":"11465","city_name":"Baihe","state_id":"743"},
    {"city_id":"11466","city_name":"Baise","state_id":"743"},
    {"city_id":"11467","city_name":"Beihai","state_id":"743"},
    {"city_id":"11468","city_name":"Binzhou","state_id":"743"},
    {"city_id":"11469","city_name":"Bose","state_id":"743"},
    {"city_id":"11470","city_name":"Fangchenggang","state_id":"743"},
    {"city_id":"11471","city_name":"Guicheng","state_id":"743"},
    {"city_id":"11472","city_name":"Guilin","state_id":"743"},
    {"city_id":"11473","city_name":"Guiping","state_id":"743"},
    {"city_id":"11474","city_name":"Jinchengjiang","state_id":"743"},
    {"city_id":"11475","city_name":"Jinji","state_id":"743"},
    {"city_id":"11476","city_name":"Laibin","state_id":"743"},
    {"city_id":"11477","city_name":"Lianzhou","state_id":"743"},
    {"city_id":"11478","city_name":"Liuzhou","state_id":"743"},
    {"city_id":"11479","city_name":"Luorong","state_id":"743"},
    {"city_id":"11480","city_name":"Matong","state_id":"743"},
    {"city_id":"11481","city_name":"Nandu","state_id":"743"},
    {"city_id":"11482","city_name":"Nanning","state_id":"743"},
    {"city_id":"11483","city_name":"Pingnan","state_id":"743"},
    {"city_id":"11484","city_name":"Pumiao","state_id":"743"},
    {"city_id":"11485","city_name":"Qinzhou","state_id":"743"},
    {"city_id":"11486","city_name":"Songhua","state_id":"743"},
    {"city_id":"11487","city_name":"Wuzhou","state_id":"743"},
    {"city_id":"11488","city_name":"Yashan","state_id":"743"},
    {"city_id":"11489","city_name":"Yulin","state_id":"743"},
    {"city_id":"11490","city_name":"Anshun","state_id":"744"},
    {"city_id":"11491","city_name":"Bijie","state_id":"744"},
    {"city_id":"11492","city_name":"Caohai","state_id":"744"},
    {"city_id":"11493","city_name":"Duyun","state_id":"744"},
    {"city_id":"11494","city_name":"Guiyang","state_id":"744"},
    {"city_id":"11495","city_name":"Kaili","state_id":"744"},
    {"city_id":"11496","city_name":"Liupanshui","state_id":"744"},
    {"city_id":"11497","city_name":"Luoyang","state_id":"744"},
    {"city_id":"11498","city_name":"Pingzhai","state_id":"744"},
    {"city_id":"11499","city_name":"Tongren","state_id":"744"},
    {"city_id":"11500","city_name":"Tongzi","state_id":"744"},
    {"city_id":"11501","city_name":"Xiaoweizhai","state_id":"744"},
    {"city_id":"11502","city_name":"Xingyi","state_id":"744"},
    {"city_id":"11503","city_name":"Zunyi","state_id":"744"},
    {"city_id":"11504","city_name":"Chengmai","state_id":"745"},
    {"city_id":"11505","city_name":"Dingan","state_id":"745"},
    {"city_id":"11506","city_name":"Haikou","state_id":"745"},
    {"city_id":"11507","city_name":"Lingao","state_id":"745"},
    {"city_id":"11508","city_name":"Qiongshan","state_id":"745"},
    {"city_id":"11509","city_name":"Sansha ","state_id":"745"},
    {"city_id":"11510","city_name":"Sanya","state_id":"745"},
    {"city_id":"11511","city_name":"Wanning","state_id":"745"},
    {"city_id":"11512","city_name":"Anping","state_id":"746"},
    {"city_id":"11513","city_name":"Baoding","state_id":"746"},
    {"city_id":"11514","city_name":"Botou","state_id":"746"},
    {"city_id":"11515","city_name":"Cangzhou","state_id":"746"},
    {"city_id":"11516","city_name":"Changli","state_id":"746"},
    {"city_id":"11517","city_name":"Chengde","state_id":"746"},
    {"city_id":"11518","city_name":"Dingzhou","state_id":"746"},
    {"city_id":"11519","city_name":"Fengfeng","state_id":"746"},
    {"city_id":"11520","city_name":"Fengrun","state_id":"746"},
    {"city_id":"11521","city_name":"Guye","state_id":"746"},
    {"city_id":"11522","city_name":"Handan","state_id":"746"},
    {"city_id":"11523","city_name":"Hebei","state_id":"746"},
    {"city_id":"11524","city_name":"Hecun","state_id":"746"},
    {"city_id":"11525","city_name":"Hejian","state_id":"746"},
    {"city_id":"11526","city_name":"Hengshui","state_id":"746"},
    {"city_id":"11527","city_name":"Huanghua","state_id":"746"},
    {"city_id":"11528","city_name":"Jingxingkuang","state_id":"746"},
    {"city_id":"11529","city_name":"Jinzhou","state_id":"746"},
    {"city_id":"11530","city_name":"Langfang","state_id":"746"},
    {"city_id":"11531","city_name":"Lianzhou","state_id":"746"},
    {"city_id":"11532","city_name":"Linshui","state_id":"746"},
    {"city_id":"11533","city_name":"Linxi","state_id":"746"},
    {"city_id":"11534","city_name":"Longyao County","state_id":"746"},
    {"city_id":"11535","city_name":"Nangong","state_id":"746"},
    {"city_id":"11536","city_name":"Pengcheng","state_id":"746"},
    {"city_id":"11537","city_name":"Qinhuangdao","state_id":"746"},
    {"city_id":"11538","city_name":"Renqiu","state_id":"746"},
    {"city_id":"11539","city_name":"Shahe","state_id":"746"},
    {"city_id":"11540","city_name":"Shijiazhuang","state_id":"746"},
    {"city_id":"11541","city_name":"Tangjiazhuang","state_id":"746"},
    {"city_id":"11542","city_name":"Tangshan","state_id":"746"},
    {"city_id":"11543","city_name":"Wuan","state_id":"746"},
    {"city_id":"11544","city_name":"Xian County","state_id":"746"},
    {"city_id":"11545","city_name":"Xingtai","state_id":"746"},
    {"city_id":"11546","city_name":"Xinji","state_id":"746"},
    {"city_id":"11547","city_name":"Xinle","state_id":"746"},
    {"city_id":"11548","city_name":"Xuanhua","state_id":"746"},
    {"city_id":"11549","city_name":"Zhangjiakou","state_id":"746"},
    {"city_id":"11550","city_name":"Zhaogezhuang","state_id":"746"},
    {"city_id":"11551","city_name":"Zhuozhou","state_id":"746"},
    {"city_id":"11552","city_name":"Acheng","state_id":"747"},
    {"city_id":"11553","city_name":"Anda","state_id":"747"},
    {"city_id":"11554","city_name":"Angangxi","state_id":"747"},
    {"city_id":"11555","city_name":"Baiquan","state_id":"747"},
    {"city_id":"11556","city_name":"Bamiantong","state_id":"747"},
    {"city_id":"11557","city_name":"Baoqing","state_id":"747"},
    {"city_id":"11558","city_name":"Baoshan","state_id":"747"},
    {"city_id":"11559","city_name":"Bayan","state_id":"747"},
    {"city_id":"11560","city_name":"Beian","state_id":"747"},
    {"city_id":"11561","city_name":"Binzhou","state_id":"747"},
    {"city_id":"11562","city_name":"Boli","state_id":"747"},
    {"city_id":"11563","city_name":"Chaihe","state_id":"747"},
    {"city_id":"11564","city_name":"Chengzihe","state_id":"747"},
    {"city_id":"11565","city_name":"Cuiluan","state_id":"747"},
    {"city_id":"11566","city_name":"Daqing","state_id":"747"},
    {"city_id":"11567","city_name":"Didao","state_id":"747"},
    {"city_id":"11568","city_name":"Dongning","state_id":"747"},
    {"city_id":"11569","city_name":"Fujin","state_id":"747"},
    {"city_id":"11570","city_name":"Fuli","state_id":"747"},
    {"city_id":"11571","city_name":"Fulitun","state_id":"747"},
    {"city_id":"11572","city_name":"Fuyu","state_id":"747"},
    {"city_id":"11573","city_name":"Gannan","state_id":"747"},
    {"city_id":"11574","city_name":"Hailin","state_id":"747"},
    {"city_id":"11575","city_name":"Hailun","state_id":"747"},
    {"city_id":"11576","city_name":"Harbin","state_id":"747"},
    {"city_id":"11577","city_name":"Hegang","state_id":"747"},
    {"city_id":"11578","city_name":"Heihe","state_id":"747"},
    {"city_id":"11579","city_name":"Hengshan","state_id":"747"},
    {"city_id":"11580","city_name":"Honggang","state_id":"747"},
    {"city_id":"11581","city_name":"Huanan","state_id":"747"},
    {"city_id":"11582","city_name":"Hulan","state_id":"747"},
    {"city_id":"11583","city_name":"Hulan Ergi","state_id":"747"},
    {"city_id":"11584","city_name":"Jiamusi","state_id":"747"},
    {"city_id":"11585","city_name":"Jidong","state_id":"747"},
    {"city_id":"11586","city_name":"Jixi","state_id":"747"},
    {"city_id":"11587","city_name":"Keshan","state_id":"747"},
    {"city_id":"11588","city_name":"Langxiang","state_id":"747"},
    {"city_id":"11589","city_name":"Lanxi","state_id":"747"},
    {"city_id":"11590","city_name":"Lingdong","state_id":"747"},
    {"city_id":"11591","city_name":"Linkou","state_id":"747"},
    {"city_id":"11592","city_name":"Lishu","state_id":"747"},
    {"city_id":"11593","city_name":"Longfeng","state_id":"747"},
    {"city_id":"11594","city_name":"Longjiang","state_id":"747"},
    {"city_id":"11595","city_name":"Mingshui","state_id":"747"},
    {"city_id":"11596","city_name":"Mishan","state_id":"747"},
    {"city_id":"11597","city_name":"Mudanjiang","state_id":"747"},
    {"city_id":"11598","city_name":"Nancha","state_id":"747"},
    {"city_id":"11599","city_name":"Nehe","state_id":"747"},
    {"city_id":"11600","city_name":"Nenjiang","state_id":"747"},
    {"city_id":"11601","city_name":"Nianzishan","state_id":"747"},
    {"city_id":"11602","city_name":"Ningan","state_id":"747"},
    {"city_id":"11603","city_name":"Qingan","state_id":"747"},
    {"city_id":"11604","city_name":"Qinggang","state_id":"747"},
    {"city_id":"11605","city_name":"Qiqihar","state_id":"747"},
    {"city_id":"11606","city_name":"Qitaihe","state_id":"747"},
    {"city_id":"11607","city_name":"Ranghulu","state_id":"747"},
    {"city_id":"11608","city_name":"Saertu","state_id":"747"},
    {"city_id":"11609","city_name":"Shangzhi","state_id":"747"},
    {"city_id":"11610","city_name":"Shanhetun","state_id":"747"},
    {"city_id":"11611","city_name":"Shuangcheng","state_id":"747"},
    {"city_id":"11612","city_name":"Shuangyashan","state_id":"747"},
    {"city_id":"11613","city_name":"Sifantan","state_id":"747"},
    {"city_id":"11614","city_name":"Suifenhe","state_id":"747"},
    {"city_id":"11615","city_name":"Suihua","state_id":"747"},
    {"city_id":"11616","city_name":"Suileng","state_id":"747"},
    {"city_id":"11617","city_name":"Tahe","state_id":"747"},
    {"city_id":"11618","city_name":"Taikang","state_id":"747"},
    {"city_id":"11619","city_name":"Tailai","state_id":"747"},
    {"city_id":"11620","city_name":"Tieli","state_id":"747"},
    {"city_id":"11621","city_name":"Wangkui","state_id":"747"},
    {"city_id":"11622","city_name":"Weihe","state_id":"747"},
    {"city_id":"11623","city_name":"Wuchang","state_id":"747"},
    {"city_id":"11624","city_name":"Xinglongzhen","state_id":"747"},
    {"city_id":"11625","city_name":"Xinqing","state_id":"747"},
    {"city_id":"11626","city_name":"Yian","state_id":"747"},
    {"city_id":"11627","city_name":"Yichun","state_id":"747"},
    {"city_id":"11628","city_name":"Yilan","state_id":"747"},
    {"city_id":"11629","city_name":"Youhao","state_id":"747"},
    {"city_id":"11630","city_name":"Zhaodong","state_id":"747"},
    {"city_id":"11631","city_name":"Zhaoyuan","state_id":"747"},
    {"city_id":"11632","city_name":"Zhaozhou","state_id":"747"},
    {"city_id":"11633","city_name":"Anyang","state_id":"748"},
    {"city_id":"11634","city_name":"Changying","state_id":"748"},
    {"city_id":"11635","city_name":"Dancheng","state_id":"748"},
    {"city_id":"11636","city_name":"Daokou","state_id":"748"},
    {"city_id":"11637","city_name":"Dengzhou","state_id":"748"},
    {"city_id":"11638","city_name":"Gongyi","state_id":"748"},
    {"city_id":"11639","city_name":"Gushi","state_id":"748"},
    {"city_id":"11640","city_name":"Hebi","state_id":"748"},
    {"city_id":"11641","city_name":"Huaidian","state_id":"748"},
    {"city_id":"11642","city_name":"Huangchuan","state_id":"748"},
    {"city_id":"11643","city_name":"Huangzhai","state_id":"748"},
    {"city_id":"11644","city_name":"Jiaozuo","state_id":"748"},
    {"city_id":"11645","city_name":"Jishui","state_id":"748"},
    {"city_id":"11646","city_name":"Kaifeng","state_id":"748"},
    {"city_id":"11647","city_name":"Liupen","state_id":"748"},
    {"city_id":"11648","city_name":"Luohe","state_id":"748"},
    {"city_id":"11649","city_name":"Luoyang","state_id":"748"},
    {"city_id":"11650","city_name":"Luyang","state_id":"748"},
    {"city_id":"11651","city_name":"Mengzhou","state_id":"748"},
    {"city_id":"11652","city_name":"Minggang","state_id":"748"},
    {"city_id":"11653","city_name":"Nandun","state_id":"748"},
    {"city_id":"11654","city_name":"Nanyang","state_id":"748"},
    {"city_id":"11655","city_name":"Pingdingshan","state_id":"748"},
    {"city_id":"11656","city_name":"Puyang","state_id":"748"},
    {"city_id":"11657","city_name":"Sanmenxia","state_id":"748"},
    {"city_id":"11658","city_name":"Shangqiu","state_id":"748"},
    {"city_id":"11659","city_name":"Tanghe","state_id":"748"},
    {"city_id":"11660","city_name":"Xiaoyi","state_id":"748"},
    {"city_id":"11661","city_name":"Xihua","state_id":"748"},
    {"city_id":"11662","city_name":"Xinxiang","state_id":"748"},
    {"city_id":"11663","city_name":"Xinyang","state_id":"748"},
    {"city_id":"11664","city_name":"Xinye","state_id":"748"},
    {"city_id":"11665","city_name":"Xixiang","state_id":"748"},
    {"city_id":"11666","city_name":"Xuanwu","state_id":"748"},
    {"city_id":"11667","city_name":"Xuchang","state_id":"748"},
    {"city_id":"11668","city_name":"Yigou","state_id":"748"},
    {"city_id":"11669","city_name":"Yima","state_id":"748"},
    {"city_id":"11670","city_name":"Yinzhuang","state_id":"748"},
    {"city_id":"11671","city_name":"Yunyang","state_id":"748"},
    {"city_id":"11672","city_name":"Yuzhou","state_id":"748"},
    {"city_id":"11673","city_name":"Zhecheng","state_id":"748"},
    {"city_id":"11674","city_name":"Zhengzhou","state_id":"748"},
    {"city_id":"11675","city_name":"Zhenping","state_id":"748"},
    {"city_id":"11676","city_name":"Zhoukou","state_id":"748"},
    {"city_id":"11677","city_name":"Zhumadian","state_id":"748"},
    {"city_id":"11678","city_name":"Anlu","state_id":"749"},
    {"city_id":"11679","city_name":"Baisha","state_id":"749"},
    {"city_id":"11680","city_name":"Buhe","state_id":"749"},
    {"city_id":"11681","city_name":"Caidian","state_id":"749"},
    {"city_id":"11682","city_name":"Caohe","state_id":"749"},
    {"city_id":"11683","city_name":"Danjiangkou","state_id":"749"},
    {"city_id":"11684","city_name":"Daye","state_id":"749"},
    {"city_id":"11685","city_name":"Duobao","state_id":"749"},
    {"city_id":"11686","city_name":"Enshi","state_id":"749"},
    {"city_id":"11687","city_name":"Ezhou","state_id":"749"},
    {"city_id":"11688","city_name":"Fengkou","state_id":"749"},
    {"city_id":"11689","city_name":"Guangshui","state_id":"749"},
    {"city_id":"11690","city_name":"Gucheng","state_id":"749"},
    {"city_id":"11691","city_name":"Hanchuan","state_id":"749"},
    {"city_id":"11692","city_name":"Hongan","state_id":"749"},
    {"city_id":"11693","city_name":"Honghu","state_id":"749"},
    {"city_id":"11694","city_name":"Huangmei","state_id":"749"},
    {"city_id":"11695","city_name":"Huangpi","state_id":"749"},
    {"city_id":"11696","city_name":"Huangshi","state_id":"749"},
    {"city_id":"11697","city_name":"Huangzhou","state_id":"749"},
    {"city_id":"11698","city_name":"Jingmen","state_id":"749"},
    {"city_id":"11699","city_name":"Jingzhou","state_id":"749"},
    {"city_id":"11700","city_name":"Laohekou","state_id":"749"},
    {"city_id":"11701","city_name":"Lichuan","state_id":"749"},
    {"city_id":"11702","city_name":"Macheng","state_id":"749"},
    {"city_id":"11703","city_name":"Nanhai","state_id":"749"},
    {"city_id":"11704","city_name":"Nanzhang","state_id":"749"},
    {"city_id":"11705","city_name":"Puqi","state_id":"749"},
    {"city_id":"11706","city_name":"Qianjiang","state_id":"749"},
    {"city_id":"11707","city_name":"Qingquan","state_id":"749"},
    {"city_id":"11708","city_name":"Qixingtai","state_id":"749"},
    {"city_id":"11709","city_name":"Rongcheng","state_id":"749"},
    {"city_id":"11710","city_name":"Shashi","state_id":"749"},
    {"city_id":"11711","city_name":"Shishou","state_id":"749"},
    {"city_id":"11712","city_name":"Shiyan","state_id":"749"},
    {"city_id":"11713","city_name":"Suizhou","state_id":"749"},
    {"city_id":"11714","city_name":"Tianmen","state_id":"749"},
    {"city_id":"11715","city_name":"Tongcheng","state_id":"749"},
    {"city_id":"11716","city_name":"Wuhan","state_id":"749"},
    {"city_id":"11717","city_name":"Wuxue","state_id":"749"},
    {"city_id":"11718","city_name":"Xiangfan","state_id":"749"},
    {"city_id":"11719","city_name":"Xianning","state_id":"749"},
    {"city_id":"11720","city_name":"Xiantao","state_id":"749"},
    {"city_id":"11721","city_name":"Xiaogan","state_id":"749"},
    {"city_id":"11722","city_name":"Xiaoxita","state_id":"749"},
    {"city_id":"11723","city_name":"Xiaxindian","state_id":"749"},
    {"city_id":"11724","city_name":"Xihe","state_id":"749"},
    {"city_id":"11725","city_name":"Xinpu","state_id":"749"},
    {"city_id":"11726","city_name":"Xinshi","state_id":"749"},
    {"city_id":"11727","city_name":"Xinzhou","state_id":"749"},
    {"city_id":"11728","city_name":"Yichang","state_id":"749"},
    {"city_id":"11729","city_name":"Yicheng","state_id":"749"},
    {"city_id":"11730","city_name":"Yingcheng","state_id":"749"},
    {"city_id":"11731","city_name":"Yingzhong","state_id":"749"},
    {"city_id":"11732","city_name":"Zaoyang","state_id":"749"},
    {"city_id":"11733","city_name":"Zhengchang","state_id":"749"},
    {"city_id":"11734","city_name":"Zhicheng","state_id":"749"},
    {"city_id":"11735","city_name":"Zhifang","state_id":"749"},
    {"city_id":"11736","city_name":"Zhongxiang","state_id":"749"},
    {"city_id":"11737","city_name":"Anjiang","state_id":"750"},
    {"city_id":"11738","city_name":"Anxiang","state_id":"750"},
    {"city_id":"11739","city_name":"Changde","state_id":"750"},
    {"city_id":"11740","city_name":"Changsha","state_id":"750"},
    {"city_id":"11741","city_name":"Chenzhou","state_id":"750"},
    {"city_id":"11742","city_name":"Dayong","state_id":"750"},
    {"city_id":"11743","city_name":"Hengyang","state_id":"750"},
    {"city_id":"11744","city_name":"Hongjiang","state_id":"750"},
    {"city_id":"11745","city_name":"Huaihua","state_id":"750"},
    {"city_id":"11746","city_name":"Jinshi","state_id":"750"},
    {"city_id":"11747","city_name":"Jishou","state_id":"750"},
    {"city_id":"11748","city_name":"Leiyang","state_id":"750"},
    {"city_id":"11749","city_name":"Lengshuijiang","state_id":"750"},
    {"city_id":"11750","city_name":"Lengshuitan","state_id":"750"},
    {"city_id":"11751","city_name":"Lianyuan","state_id":"750"},
    {"city_id":"11752","city_name":"Liling","state_id":"750"},
    {"city_id":"11753","city_name":"Liuyang","state_id":"750"},
    {"city_id":"11754","city_name":"Loudi","state_id":"750"},
    {"city_id":"11755","city_name":"Matian","state_id":"750"},
    {"city_id":"11756","city_name":"Nanzhou","state_id":"750"},
    {"city_id":"11757","city_name":"Ningxiang","state_id":"750"},
    {"city_id":"11758","city_name":"Qidong","state_id":"750"},
    {"city_id":"11759","city_name":"Qiyang","state_id":"750"},
    {"city_id":"11760","city_name":"Shaoyang","state_id":"750"},
    {"city_id":"11761","city_name":"Xiangtan","state_id":"750"},
    {"city_id":"11762","city_name":"Xiangxiang","state_id":"750"},
    {"city_id":"11763","city_name":"Xiangyin","state_id":"750"},
    {"city_id":"11764","city_name":"Xinhua","state_id":"750"},
    {"city_id":"11765","city_name":"Yiyang","state_id":"750"},
    {"city_id":"11766","city_name":"Yongfeng","state_id":"750"},
    {"city_id":"11767","city_name":"Yongzhou","state_id":"750"},
    {"city_id":"11768","city_name":"Yuanjiang","state_id":"750"},
    {"city_id":"11769","city_name":"Yueyang","state_id":"750"},
    {"city_id":"11770","city_name":"Zhuzhou","state_id":"750"},
    {"city_id":"11771","city_name":"Baoying","state_id":"751"},
    {"city_id":"11772","city_name":"Changzhou","state_id":"751"},
    {"city_id":"11773","city_name":"Dachang","state_id":"751"},
    {"city_id":"11774","city_name":"Dafeng","state_id":"751"},
    {"city_id":"11775","city_name":"Danyang","state_id":"751"},
    {"city_id":"11776","city_name":"Dingshu","state_id":"751"},
    {"city_id":"11777","city_name":"Dongkan","state_id":"751"},
    {"city_id":"11778","city_name":"Dongtai","state_id":"751"},
    {"city_id":"11779","city_name":"Fengxian","state_id":"751"},
    {"city_id":"11780","city_name":"Gaogou","state_id":"751"},
    {"city_id":"11781","city_name":"Gaoyou","state_id":"751"},
    {"city_id":"11782","city_name":"Guiren","state_id":"751"},
    {"city_id":"11783","city_name":"Haian","state_id":"751"},
    {"city_id":"11784","city_name":"Haizhou","state_id":"751"},
    {"city_id":"11785","city_name":"Hede","state_id":"751"},
    {"city_id":"11786","city_name":"Huaicheng","state_id":"751"},
    {"city_id":"11787","city_name":"Huaiyin","state_id":"751"},
    {"city_id":"11788","city_name":"Huilong","state_id":"751"},
    {"city_id":"11789","city_name":"Hutang","state_id":"751"},
    {"city_id":"11790","city_name":"Jiangdu","state_id":"751"},
    {"city_id":"11791","city_name":"Jiangyan","state_id":"751"},
    {"city_id":"11792","city_name":"Jiangyin","state_id":"751"},
    {"city_id":"11793","city_name":"Jiangyuan","state_id":"751"},
    {"city_id":"11794","city_name":"Jianhu","state_id":"751"},
    {"city_id":"11795","city_name":"Jingcheng","state_id":"751"},
    {"city_id":"11796","city_name":"Jinsha","state_id":"751"},
    {"city_id":"11797","city_name":"Jintan","state_id":"751"},
    {"city_id":"11798","city_name":"Juegang","state_id":"751"},
    {"city_id":"11799","city_name":"Jurong","state_id":"751"},
    {"city_id":"11800","city_name":"Kunshan","state_id":"751"},
    {"city_id":"11801","city_name":"Lianyungang","state_id":"751"},
    {"city_id":"11802","city_name":"Liucheng","state_id":"751"},
    {"city_id":"11803","city_name":"Liyang","state_id":"751"},
    {"city_id":"11804","city_name":"Luodu","state_id":"751"},
    {"city_id":"11805","city_name":"Mudu","state_id":"751"},
    {"city_id":"11806","city_name":"Nanjing","state_id":"751"},
    {"city_id":"11807","city_name":"Nantong","state_id":"751"},
    {"city_id":"11808","city_name":"Pecheng","state_id":"751"},
    {"city_id":"11809","city_name":"Pukou","state_id":"751"},
    {"city_id":"11810","city_name":"Qidong","state_id":"751"},
    {"city_id":"11811","city_name":"Qinnan","state_id":"751"},
    {"city_id":"11812","city_name":"Qixia","state_id":"751"},
    {"city_id":"11813","city_name":"Rucheng","state_id":"751"},
    {"city_id":"11814","city_name":"Songling","state_id":"751"},
    {"city_id":"11815","city_name":"Sucheng","state_id":"751"},
    {"city_id":"11816","city_name":"Suicheng","state_id":"751"},
    {"city_id":"11817","city_name":"Suqian","state_id":"751"},
    {"city_id":"11818","city_name":"Suzhou","state_id":"751"},
    {"city_id":"11819","city_name":"Taicang","state_id":"751"},
    {"city_id":"11820","city_name":"Taixing","state_id":"751"},
    {"city_id":"11821","city_name":"Wujiang","state_id":"751"},
    {"city_id":"11822","city_name":"Wuxi","state_id":"751"},
    {"city_id":"11823","city_name":"Xiaolingwei","state_id":"751"},
    {"city_id":"11824","city_name":"Xiaoshi","state_id":"751"},
    {"city_id":"11825","city_name":"Xinan","state_id":"751"},
    {"city_id":"11826","city_name":"Xinpu","state_id":"751"},
    {"city_id":"11827","city_name":"Xuzhou","state_id":"751"},
    {"city_id":"11828","city_name":"Yancheng","state_id":"751"},
    {"city_id":"11829","city_name":"Yangshe","state_id":"751"},
    {"city_id":"11830","city_name":"Yangzhou","state_id":"751"},
    {"city_id":"11831","city_name":"Yizheng","state_id":"751"},
    {"city_id":"11832","city_name":"Yunhe","state_id":"751"},
    {"city_id":"11833","city_name":"Yunyang","state_id":"751"},
    {"city_id":"11834","city_name":"Yushan","state_id":"751"},
    {"city_id":"11835","city_name":"Zhangjiagang","state_id":"751"},
    {"city_id":"11836","city_name":"Zhangjiangang","state_id":"751"},
    {"city_id":"11837","city_name":"Zhaoyang","state_id":"751"},
    {"city_id":"11838","city_name":"Zhenjiang","state_id":"751"},
    {"city_id":"11839","city_name":"Zhongxing","state_id":"751"},
    {"city_id":"11840","city_name":"Baoying","state_id":"752"},
    {"city_id":"11841","city_name":"Changzhou","state_id":"752"},
    {"city_id":"11842","city_name":"Dachang","state_id":"752"},
    {"city_id":"11843","city_name":"Dafeng","state_id":"752"},
    {"city_id":"11844","city_name":"Danyang","state_id":"752"},
    {"city_id":"11845","city_name":"Dingshu","state_id":"752"},
    {"city_id":"11846","city_name":"Dongkan","state_id":"752"},
    {"city_id":"11847","city_name":"Dongtai","state_id":"752"},
    {"city_id":"11848","city_name":"Fengxian","state_id":"752"},
    {"city_id":"11849","city_name":"Gaogou","state_id":"752"},
    {"city_id":"11850","city_name":"Gaoyou","state_id":"752"},
    {"city_id":"11851","city_name":"Guiren","state_id":"752"},
    {"city_id":"11852","city_name":"Haian","state_id":"752"},
    {"city_id":"11853","city_name":"Haizhou","state_id":"752"},
    {"city_id":"11854","city_name":"Hede","state_id":"752"},
    {"city_id":"11855","city_name":"Huaicheng","state_id":"752"},
    {"city_id":"11856","city_name":"Huaiyin","state_id":"752"},
    {"city_id":"11857","city_name":"Huilong","state_id":"752"},
    {"city_id":"11858","city_name":"Hutang","state_id":"752"},
    {"city_id":"11859","city_name":"Jiangdu","state_id":"752"},
    {"city_id":"11860","city_name":"Jiangyan","state_id":"752"},
    {"city_id":"11861","city_name":"Jiangyin","state_id":"752"},
    {"city_id":"11862","city_name":"Jiangyuan","state_id":"752"},
    {"city_id":"11863","city_name":"Jianhu","state_id":"752"},
    {"city_id":"11864","city_name":"Jingcheng","state_id":"752"},
    {"city_id":"11865","city_name":"Jinsha","state_id":"752"},
    {"city_id":"11866","city_name":"Jintan","state_id":"752"},
    {"city_id":"11867","city_name":"Juegang","state_id":"752"},
    {"city_id":"11868","city_name":"Jurong","state_id":"752"},
    {"city_id":"11869","city_name":"Kunshan","state_id":"752"},
    {"city_id":"11870","city_name":"Lianyungang","state_id":"752"},
    {"city_id":"11871","city_name":"Liucheng","state_id":"752"},
    {"city_id":"11872","city_name":"Liyang","state_id":"752"},
    {"city_id":"11873","city_name":"Luodu","state_id":"752"},
    {"city_id":"11874","city_name":"Mudu","state_id":"752"},
    {"city_id":"11875","city_name":"Nanjing","state_id":"752"},
    {"city_id":"11876","city_name":"Nantong","state_id":"752"},
    {"city_id":"11877","city_name":"Pecheng","state_id":"752"},
    {"city_id":"11878","city_name":"Pukou","state_id":"752"},
    {"city_id":"11879","city_name":"Qidong","state_id":"752"},
    {"city_id":"11880","city_name":"Qinnan","state_id":"752"},
    {"city_id":"11881","city_name":"Qixia","state_id":"752"},
    {"city_id":"11882","city_name":"Rucheng","state_id":"752"},
    {"city_id":"11883","city_name":"Songling","state_id":"752"},
    {"city_id":"11884","city_name":"Sucheng","state_id":"752"},
    {"city_id":"11885","city_name":"Suicheng","state_id":"752"},
    {"city_id":"11886","city_name":"Suqian","state_id":"752"},
    {"city_id":"11887","city_name":"Suzhou","state_id":"752"},
    {"city_id":"11888","city_name":"Taicang","state_id":"752"},
    {"city_id":"11889","city_name":"Taixing","state_id":"752"},
    {"city_id":"11890","city_name":"Wujiang","state_id":"752"},
    {"city_id":"11891","city_name":"Wuxi","state_id":"752"},
    {"city_id":"11892","city_name":"Xiaolingwei","state_id":"752"},
    {"city_id":"11893","city_name":"Xiaoshi","state_id":"752"},
    {"city_id":"11894","city_name":"Xinan","state_id":"752"},
    {"city_id":"11895","city_name":"Xinpu","state_id":"752"},
    {"city_id":"11896","city_name":"Xuzhou","state_id":"752"},
    {"city_id":"11897","city_name":"Yancheng","state_id":"752"},
    {"city_id":"11898","city_name":"Yangshe","state_id":"752"},
    {"city_id":"11899","city_name":"Yangzhou","state_id":"752"},
    {"city_id":"11900","city_name":"Yizheng","state_id":"752"},
    {"city_id":"11901","city_name":"Yunhe","state_id":"752"},
    {"city_id":"11902","city_name":"Yunyang","state_id":"752"},
    {"city_id":"11903","city_name":"Yushan","state_id":"752"},
    {"city_id":"11904","city_name":"Zhangjiagang","state_id":"752"},
    {"city_id":"11905","city_name":"Zhangjiangang","state_id":"752"},
    {"city_id":"11906","city_name":"Zhaoyang","state_id":"752"},
    {"city_id":"11907","city_name":"Zhenjiang","state_id":"752"},
    {"city_id":"11908","city_name":"Zhongxing","state_id":"752"},
    {"city_id":"11909","city_name":"Fengxin","state_id":"753"},
    {"city_id":"11910","city_name":"Fenyi","state_id":"753"},
    {"city_id":"11911","city_name":"Ganzhou","state_id":"753"},
    {"city_id":"11912","city_name":"Jian","state_id":"753"},
    {"city_id":"11913","city_name":"Jiangguang","state_id":"753"},
    {"city_id":"11914","city_name":"Jingdezhen","state_id":"753"},
    {"city_id":"11915","city_name":"Jiujiang","state_id":"753"},
    {"city_id":"11916","city_name":"Leping","state_id":"753"},
    {"city_id":"11917","city_name":"Linchuan","state_id":"753"},
    {"city_id":"11918","city_name":"Nanchang","state_id":"753"},
    {"city_id":"11919","city_name":"Pingxiang","state_id":"753"},
    {"city_id":"11920","city_name":"Poyang","state_id":"753"},
    {"city_id":"11921","city_name":"Shangrao","state_id":"753"},
    {"city_id":"11922","city_name":"Xiangdong","state_id":"753"},
    {"city_id":"11923","city_name":"Xingan","state_id":"753"},
    {"city_id":"11924","city_name":"Xinjian","state_id":"753"},
    {"city_id":"11925","city_name":"Xinyu","state_id":"753"},
    {"city_id":"11926","city_name":"Xiongshi","state_id":"753"},
    {"city_id":"11927","city_name":"Yichun","state_id":"753"},
    {"city_id":"11928","city_name":"Yingtai","state_id":"753"},
    {"city_id":"11929","city_name":"Yingtan","state_id":"753"},
    {"city_id":"11930","city_name":"Zhangshui","state_id":"753"},
    {"city_id":"11931","city_name":"Badaojiang","state_id":"754"},
    {"city_id":"11932","city_name":"Baicheng","state_id":"754"},
    {"city_id":"11933","city_name":"Baishishan","state_id":"754"},
    {"city_id":"11934","city_name":"Changchun","state_id":"754"},
    {"city_id":"11935","city_name":"Changling","state_id":"754"},
    {"city_id":"11936","city_name":"Chaoyang","state_id":"754"},
    {"city_id":"11937","city_name":"Daan","state_id":"754"},
    {"city_id":"11938","city_name":"Dashitou","state_id":"754"},
    {"city_id":"11939","city_name":"Dehui","state_id":"754"},
    {"city_id":"11940","city_name":"Dongchang","state_id":"754"},
    {"city_id":"11941","city_name":"Dongfeng","state_id":"754"},
    {"city_id":"11942","city_name":"Dunhua","state_id":"754"},
    {"city_id":"11943","city_name":"Erdaojiang","state_id":"754"},
    {"city_id":"11944","city_name":"Fuyu","state_id":"754"},
    {"city_id":"11945","city_name":"Gongzhuling","state_id":"754"},
    {"city_id":"11946","city_name":"Helong","state_id":"754"},
    {"city_id":"11947","city_name":"Hongmei","state_id":"754"},
    {"city_id":"11948","city_name":"Huadian","state_id":"754"},
    {"city_id":"11949","city_name":"Huangnihe","state_id":"754"},
    {"city_id":"11950","city_name":"Huinan","state_id":"754"},
    {"city_id":"11951","city_name":"Hunchun","state_id":"754"},
    {"city_id":"11952","city_name":"Jiaohe","state_id":"754"},
    {"city_id":"11953","city_name":"Jilin","state_id":"754"},
    {"city_id":"11954","city_name":"Jishu","state_id":"754"},
    {"city_id":"11955","city_name":"Jiutai","state_id":"754"},
    {"city_id":"11956","city_name":"Kaitong","state_id":"754"},
    {"city_id":"11957","city_name":"Kouqian","state_id":"754"},
    {"city_id":"11958","city_name":"Liaoyuan","state_id":"754"},
    {"city_id":"11959","city_name":"Linjiang","state_id":"754"},
    {"city_id":"11960","city_name":"Lishu","state_id":"754"},
    {"city_id":"11961","city_name":"Liuhe","state_id":"754"},
    {"city_id":"11962","city_name":"Longjing","state_id":"754"},
    {"city_id":"11963","city_name":"Meihekou","state_id":"754"},
    {"city_id":"11964","city_name":"Mingyue","state_id":"754"},
    {"city_id":"11965","city_name":"Nongan","state_id":"754"},
    {"city_id":"11966","city_name":"Panshi","state_id":"754"},
    {"city_id":"11967","city_name":"Pizhou","state_id":"754"},
    {"city_id":"11968","city_name":"Qianan","state_id":"754"},
    {"city_id":"11969","city_name":"Qianguo","state_id":"754"},
    {"city_id":"11970","city_name":"Sanchazi","state_id":"754"},
    {"city_id":"11971","city_name":"Shuangyang","state_id":"754"},
    {"city_id":"11972","city_name":"Shulan","state_id":"754"},
    {"city_id":"11973","city_name":"Siping","state_id":"754"},
    {"city_id":"11974","city_name":"Songjianghe","state_id":"754"},
    {"city_id":"11975","city_name":"Taonan","state_id":"754"},
    {"city_id":"11976","city_name":"Tumen","state_id":"754"},
    {"city_id":"11977","city_name":"Wangou","state_id":"754"},
    {"city_id":"11978","city_name":"Wangqing","state_id":"754"},
    {"city_id":"11979","city_name":"Xinglongshan","state_id":"754"},
    {"city_id":"11980","city_name":"Yanji","state_id":"754"},
    {"city_id":"11981","city_name":"Yantongshan","state_id":"754"},
    {"city_id":"11982","city_name":"Yushu","state_id":"754"},
    {"city_id":"11983","city_name":"Zhengjiatun","state_id":"754"},
    {"city_id":"11984","city_name":"Zhenlai","state_id":"754"},
    {"city_id":"11985","city_name":"Anshan","state_id":"755"},
    {"city_id":"11986","city_name":"Beipiao","state_id":"755"},
    {"city_id":"11987","city_name":"Benxi","state_id":"755"},
    {"city_id":"11988","city_name":"Changtu","state_id":"755"},
    {"city_id":"11989","city_name":"Chaoyang","state_id":"755"},
    {"city_id":"11990","city_name":"Dalian","state_id":"755"},
    {"city_id":"11991","city_name":"Dalianwan","state_id":"755"},
    {"city_id":"11992","city_name":"Dalinghe","state_id":"755"},
    {"city_id":"11993","city_name":"Dandong","state_id":"755"},
    {"city_id":"11994","city_name":"Dashiqiao","state_id":"755"},
    {"city_id":"11995","city_name":"Dongling","state_id":"755"},
    {"city_id":"11996","city_name":"Fengcheng","state_id":"755"},
    {"city_id":"11997","city_name":"Fushun","state_id":"755"},
    {"city_id":"11998","city_name":"Fuxin","state_id":"755"},
    {"city_id":"11999","city_name":"Haicheng","state_id":"755"},
    {"city_id":"12000","city_name":"Heishan","state_id":"755"},
    {"city_id":"12001","city_name":"Huanren","state_id":"755"},
    {"city_id":"12002","city_name":"Huludao","state_id":"755"},
    {"city_id":"12003","city_name":"Hushitai","state_id":"755"},
    {"city_id":"12004","city_name":"Jinxi","state_id":"755"},
    {"city_id":"12005","city_name":"Jinzhou","state_id":"755"},
    {"city_id":"12006","city_name":"Jiupu","state_id":"755"},
    {"city_id":"12007","city_name":"Kaiyuan","state_id":"755"},
    {"city_id":"12008","city_name":"Kuandian","state_id":"755"},
    {"city_id":"12009","city_name":"Langtou","state_id":"755"},
    {"city_id":"12010","city_name":"Liaoyang","state_id":"755"},
    {"city_id":"12011","city_name":"Liaozhong","state_id":"755"},
    {"city_id":"12012","city_name":"Lingyuan","state_id":"755"},
    {"city_id":"12013","city_name":"Liuerbao","state_id":"755"},
    {"city_id":"12014","city_name":"Lushunkou","state_id":"755"},
    {"city_id":"12015","city_name":"Nantai","state_id":"755"},
    {"city_id":"12016","city_name":"Panjin","state_id":"755"},
    {"city_id":"12017","city_name":"Pulandian","state_id":"755"},
    {"city_id":"12018","city_name":"Shenyang","state_id":"755"},
    {"city_id":"12019","city_name":"Sujiatun","state_id":"755"},
    {"city_id":"12020","city_name":"Tieling","state_id":"755"},
    {"city_id":"12021","city_name":"Wafangdian","state_id":"755"},
    {"city_id":"12022","city_name":"Xiaoshi","state_id":"755"},
    {"city_id":"12023","city_name":"Xifeng","state_id":"755"},
    {"city_id":"12024","city_name":"Xinchengxi","state_id":"755"},
    {"city_id":"12025","city_name":"Xingcheng","state_id":"755"},
    {"city_id":"12026","city_name":"Xinmin","state_id":"755"},
    {"city_id":"12027","city_name":"Xiongyue","state_id":"755"},
    {"city_id":"12028","city_name":"Xiuyan","state_id":"755"},
    {"city_id":"12029","city_name":"Yebaishou","state_id":"755"},
    {"city_id":"12030","city_name":"Yingkou","state_id":"755"},
    {"city_id":"12031","city_name":"Yuhong","state_id":"755"},
    {"city_id":"12032","city_name":"Zhuanghe","state_id":"755"},
    {"city_id":"12033","city_name":"Anshan","state_id":"756"},
    {"city_id":"12034","city_name":"Beipiao","state_id":"756"},
    {"city_id":"12035","city_name":"Benxi","state_id":"756"},
    {"city_id":"12036","city_name":"Changtu","state_id":"756"},
    {"city_id":"12037","city_name":"Chaoyang","state_id":"756"},
    {"city_id":"12038","city_name":"Dalian","state_id":"756"},
    {"city_id":"12039","city_name":"Dalianwan","state_id":"756"},
    {"city_id":"12040","city_name":"Dalinghe","state_id":"756"},
    {"city_id":"12041","city_name":"Dandong","state_id":"756"},
    {"city_id":"12042","city_name":"Dashiqiao","state_id":"756"},
    {"city_id":"12043","city_name":"Dongling","state_id":"756"},
    {"city_id":"12044","city_name":"Fengcheng","state_id":"756"},
    {"city_id":"12045","city_name":"Fushun","state_id":"756"},
    {"city_id":"12046","city_name":"Fuxin","state_id":"756"},
    {"city_id":"12047","city_name":"Haicheng","state_id":"756"},
    {"city_id":"12048","city_name":"Heishan","state_id":"756"},
    {"city_id":"12049","city_name":"Huanren","state_id":"756"},
    {"city_id":"12050","city_name":"Huludao","state_id":"756"},
    {"city_id":"12051","city_name":"Hushitai","state_id":"756"},
    {"city_id":"12052","city_name":"Jinxi","state_id":"756"},
    {"city_id":"12053","city_name":"Jinzhou","state_id":"756"},
    {"city_id":"12054","city_name":"Jiupu","state_id":"756"},
    {"city_id":"12055","city_name":"Kaiyuan","state_id":"756"},
    {"city_id":"12056","city_name":"Kuandian","state_id":"756"},
    {"city_id":"12057","city_name":"Langtou","state_id":"756"},
    {"city_id":"12058","city_name":"Liaoyang","state_id":"756"},
    {"city_id":"12059","city_name":"Liaozhong","state_id":"756"},
    {"city_id":"12060","city_name":"Lingyuan","state_id":"756"},
    {"city_id":"12061","city_name":"Liuerbao","state_id":"756"},
    {"city_id":"12062","city_name":"Lushunkou","state_id":"756"},
    {"city_id":"12063","city_name":"Nantai","state_id":"756"},
    {"city_id":"12064","city_name":"Panjin","state_id":"756"},
    {"city_id":"12065","city_name":"Pulandian","state_id":"756"},
    {"city_id":"12066","city_name":"Shenyang","state_id":"756"},
    {"city_id":"12067","city_name":"Sujiatun","state_id":"756"},
    {"city_id":"12068","city_name":"Tieling","state_id":"756"},
    {"city_id":"12069","city_name":"Wafangdian","state_id":"756"},
    {"city_id":"12070","city_name":"Xiaoshi","state_id":"756"},
    {"city_id":"12071","city_name":"Xifeng","state_id":"756"},
    {"city_id":"12072","city_name":"Xinchengxi","state_id":"756"},
    {"city_id":"12073","city_name":"Xingcheng","state_id":"756"},
    {"city_id":"12074","city_name":"Xinmin","state_id":"756"},
    {"city_id":"12075","city_name":"Xiongyue","state_id":"756"},
    {"city_id":"12076","city_name":"Xiuyan","state_id":"756"},
    {"city_id":"12077","city_name":"Yebaishou","state_id":"756"},
    {"city_id":"12078","city_name":"Yingkou","state_id":"756"},
    {"city_id":"12079","city_name":"Yuhong","state_id":"756"},
    {"city_id":"12080","city_name":"Zhuanghe","state_id":"756"},
    {"city_id":"12081","city_name":"Qiatou","state_id":"759"},
    {"city_id":"12082","city_name":"Xining","state_id":"759"},
    {"city_id":"12083","city_name":"Ankang","state_id":"760"},
    {"city_id":"12084","city_name":"Baoji","state_id":"760"},
    {"city_id":"12085","city_name":"Guozhen","state_id":"760"},
    {"city_id":"12086","city_name":"Hancheng","state_id":"760"},
    {"city_id":"12087","city_name":"Hanzhong","state_id":"760"},
    {"city_id":"12088","city_name":"Lishan","state_id":"760"},
    {"city_id":"12089","city_name":"Qili","state_id":"760"},
    {"city_id":"12090","city_name":"Tongchuan","state_id":"760"},
    {"city_id":"12091","city_name":"Weinan","state_id":"760"},
    {"city_id":"12092","city_name":"Xian","state_id":"760"},
    {"city_id":"12093","city_name":"Xianyang","state_id":"760"},
    {"city_id":"12094","city_name":"Yanan","state_id":"760"},
    {"city_id":"12095","city_name":"Yanliang","state_id":"760"},
    {"city_id":"12096","city_name":"Yulin","state_id":"760"},
    {"city_id":"12097","city_name":"Yuxia","state_id":"760"},
    {"city_id":"12098","city_name":"Anqiu","state_id":"761"},
    {"city_id":"12099","city_name":"Bianzhuang","state_id":"761"},
    {"city_id":"12100","city_name":"Binzhou","state_id":"761"},
    {"city_id":"12101","city_name":"Boshan","state_id":"761"},
    {"city_id":"12102","city_name":"Boxing County","state_id":"761"},
    {"city_id":"12103","city_name":"Caocheng","state_id":"761"},
    {"city_id":"12104","city_name":"Changqing","state_id":"761"},
    {"city_id":"12105","city_name":"Chengyang","state_id":"761"},
    {"city_id":"12106","city_name":"Dengzhou","state_id":"761"},
    {"city_id":"12107","city_name":"Dezhou","state_id":"761"},
    {"city_id":"12108","city_name":"Dingtao","state_id":"761"},
    {"city_id":"12109","city_name":"Dongcun","state_id":"761"},
    {"city_id":"12110","city_name":"Dongdu","state_id":"761"},
    {"city_id":"12111","city_name":"Donge County","state_id":"761"},
    {"city_id":"12112","city_name":"Dongying","state_id":"761"},
    {"city_id":"12113","city_name":"Feicheng","state_id":"761"},
    {"city_id":"12114","city_name":"Fushan","state_id":"761"},
    {"city_id":"12115","city_name":"Gaomi","state_id":"761"},
    {"city_id":"12116","city_name":"Haiyang","state_id":"761"},
    {"city_id":"12117","city_name":"Hanting","state_id":"761"},
    {"city_id":"12118","city_name":"Hekou","state_id":"761"},
    {"city_id":"12119","city_name":"Heze","state_id":"761"},
    {"city_id":"12120","city_name":"Jiaonan","state_id":"761"},
    {"city_id":"12121","city_name":"Jiaozhou","state_id":"761"},
    {"city_id":"12122","city_name":"Jiehu","state_id":"761"},
    {"city_id":"12123","city_name":"Jimo","state_id":"761"},
    {"city_id":"12124","city_name":"Jinan","state_id":"761"},
    {"city_id":"12125","city_name":"Jining","state_id":"761"},
    {"city_id":"12126","city_name":"Juxian","state_id":"761"},
    {"city_id":"12127","city_name":"Juye","state_id":"761"},
    {"city_id":"12128","city_name":"Kunlun","state_id":"761"},
    {"city_id":"12129","city_name":"Laiwu","state_id":"761"},
    {"city_id":"12130","city_name":"Laiyang","state_id":"761"},
    {"city_id":"12131","city_name":"Laizhou","state_id":"761"},
    {"city_id":"12132","city_name":"Leling","state_id":"761"},
    {"city_id":"12133","city_name":"Liaocheng","state_id":"761"},
    {"city_id":"12134","city_name":"Licung","state_id":"761"},
    {"city_id":"12135","city_name":"Linqing","state_id":"761"},
    {"city_id":"12136","city_name":"Linqu","state_id":"761"},
    {"city_id":"12137","city_name":"Linshu","state_id":"761"},
    {"city_id":"12138","city_name":"Linyi","state_id":"761"},
    {"city_id":"12139","city_name":"Longkou","state_id":"761"},
    {"city_id":"12140","city_name":"Mengyin","state_id":"761"},
    {"city_id":"12141","city_name":"Mingshui","state_id":"761"},
    {"city_id":"12142","city_name":"Nanchou","state_id":"761"},
    {"city_id":"12143","city_name":"Nanding","state_id":"761"},
    {"city_id":"12144","city_name":"Nanma","state_id":"761"},
    {"city_id":"12145","city_name":"Ninghai","state_id":"761"},
    {"city_id":"12146","city_name":"Ningyang","state_id":"761"},
    {"city_id":"12147","city_name":"Pingdu","state_id":"761"},
    {"city_id":"12148","city_name":"Pingyi","state_id":"761"},
    {"city_id":"12149","city_name":"Pingyin","state_id":"761"},
    {"city_id":"12150","city_name":"Qingdao","state_id":"761"},
    {"city_id":"12151","city_name":"Qingzhou","state_id":"761"},
    {"city_id":"12152","city_name":"Qixia","state_id":"761"},
    {"city_id":"12153","city_name":"Qufu","state_id":"761"},
    {"city_id":"12154","city_name":"Rizhao","state_id":"761"},
    {"city_id":"12155","city_name":"Rongcheng","state_id":"761"},
    {"city_id":"12156","city_name":"Shancheng","state_id":"761"},
    {"city_id":"12157","city_name":"Shanting","state_id":"761"},
    {"city_id":"12158","city_name":"Shengzhuang","state_id":"761"},
    {"city_id":"12159","city_name":"Shenxian","state_id":"761"},
    {"city_id":"12160","city_name":"Shizilu","state_id":"761"},
    {"city_id":"12161","city_name":"Shouguang","state_id":"761"},
    {"city_id":"12162","city_name":"Shuiji","state_id":"761"},
    {"city_id":"12163","city_name":"Sishui","state_id":"761"},
    {"city_id":"12164","city_name":"Suozhen","state_id":"761"},
    {"city_id":"12165","city_name":"Taian","state_id":"761"},
    {"city_id":"12166","city_name":"Tancheng","state_id":"761"},
    {"city_id":"12167","city_name":"Taozhuang","state_id":"761"},
    {"city_id":"12168","city_name":"Tengzhou","state_id":"761"},
    {"city_id":"12169","city_name":"Weifang","state_id":"761"},
    {"city_id":"12170","city_name":"Weihai","state_id":"761"},
    {"city_id":"12171","city_name":"Wencheng","state_id":"761"},
    {"city_id":"12172","city_name":"Wendeng","state_id":"761"},
    {"city_id":"12173","city_name":"Wenshang","state_id":"761"},
    {"city_id":"12174","city_name":"Wudi","state_id":"761"},
    {"city_id":"12175","city_name":"Xiazhen","state_id":"761"},
    {"city_id":"12176","city_name":"Xincheng","state_id":"761"},
    {"city_id":"12177","city_name":"Xindian","state_id":"761"},
    {"city_id":"12178","city_name":"Xintai","state_id":"761"},
    {"city_id":"12179","city_name":"Yanggu","state_id":"761"},
    {"city_id":"12180","city_name":"Yangshan","state_id":"761"},
    {"city_id":"12181","city_name":"Yantai","state_id":"761"},
    {"city_id":"12182","city_name":"Yanzhou","state_id":"761"},
    {"city_id":"12183","city_name":"Yatou","state_id":"761"},
    {"city_id":"12184","city_name":"Yidu","state_id":"761"},
    {"city_id":"12185","city_name":"Yishui","state_id":"761"},
    {"city_id":"12186","city_name":"Yucheng","state_id":"761"},
    {"city_id":"12187","city_name":"Yuncheng","state_id":"761"},
    {"city_id":"12188","city_name":"Zaozhuang","state_id":"761"},
    {"city_id":"12189","city_name":"Zhangdian","state_id":"761"},
    {"city_id":"12190","city_name":"Zhangjiawa","state_id":"761"},
    {"city_id":"12191","city_name":"Zhangqiu","state_id":"761"},
    {"city_id":"12192","city_name":"Zhaocheng","state_id":"761"},
    {"city_id":"12193","city_name":"Zhoucheng","state_id":"761"},
    {"city_id":"12194","city_name":"Zhoucun","state_id":"761"},
    {"city_id":"12195","city_name":"Zhucheng","state_id":"761"},
    {"city_id":"12196","city_name":"Zhuwang","state_id":"761"},
    {"city_id":"12197","city_name":"Zicheng","state_id":"761"},
    {"city_id":"12198","city_name":"Zouping","state_id":"761"},
    {"city_id":"12199","city_name":"Zouxian","state_id":"761"},
    {"city_id":"12200","city_name":"Anqiu","state_id":"762"},
    {"city_id":"12201","city_name":"Bianzhuang","state_id":"762"},
    {"city_id":"12202","city_name":"Binzhou","state_id":"762"},
    {"city_id":"12203","city_name":"Boshan","state_id":"762"},
    {"city_id":"12204","city_name":"Boxing County","state_id":"762"},
    {"city_id":"12205","city_name":"Caocheng","state_id":"762"},
    {"city_id":"12206","city_name":"Changqing","state_id":"762"},
    {"city_id":"12207","city_name":"Chengyang","state_id":"762"},
    {"city_id":"12208","city_name":"Dengzhou","state_id":"762"},
    {"city_id":"12209","city_name":"Dezhou","state_id":"762"},
    {"city_id":"12210","city_name":"Dingtao","state_id":"762"},
    {"city_id":"12211","city_name":"Dongcun","state_id":"762"},
    {"city_id":"12212","city_name":"Dongdu","state_id":"762"},
    {"city_id":"12213","city_name":"Donge County","state_id":"762"},
    {"city_id":"12214","city_name":"Dongying","state_id":"762"},
    {"city_id":"12215","city_name":"Feicheng","state_id":"762"},
    {"city_id":"12216","city_name":"Fushan","state_id":"762"},
    {"city_id":"12217","city_name":"Gaomi","state_id":"762"},
    {"city_id":"12218","city_name":"Haiyang","state_id":"762"},
    {"city_id":"12219","city_name":"Hanting","state_id":"762"},
    {"city_id":"12220","city_name":"Hekou","state_id":"762"},
    {"city_id":"12221","city_name":"Heze","state_id":"762"},
    {"city_id":"12222","city_name":"Jiaonan","state_id":"762"},
    {"city_id":"12223","city_name":"Jiaozhou","state_id":"762"},
    {"city_id":"12224","city_name":"Jiehu","state_id":"762"},
    {"city_id":"12225","city_name":"Jimo","state_id":"762"},
    {"city_id":"12226","city_name":"Jinan","state_id":"762"},
    {"city_id":"12227","city_name":"Jining","state_id":"762"},
    {"city_id":"12228","city_name":"Juxian","state_id":"762"},
    {"city_id":"12229","city_name":"Juye","state_id":"762"},
    {"city_id":"12230","city_name":"Kunlun","state_id":"762"},
    {"city_id":"12231","city_name":"Laiwu","state_id":"762"},
    {"city_id":"12232","city_name":"Laiyang","state_id":"762"},
    {"city_id":"12233","city_name":"Laizhou","state_id":"762"},
    {"city_id":"12234","city_name":"Leling","state_id":"762"},
    {"city_id":"12235","city_name":"Liaocheng","state_id":"762"},
    {"city_id":"12236","city_name":"Licung","state_id":"762"},
    {"city_id":"12237","city_name":"Linqing","state_id":"762"},
    {"city_id":"12238","city_name":"Linqu","state_id":"762"},
    {"city_id":"12239","city_name":"Linshu","state_id":"762"},
    {"city_id":"12240","city_name":"Linyi","state_id":"762"},
    {"city_id":"12241","city_name":"Longkou","state_id":"762"},
    {"city_id":"12242","city_name":"Mengyin","state_id":"762"},
    {"city_id":"12243","city_name":"Mingshui","state_id":"762"},
    {"city_id":"12244","city_name":"Nanchou","state_id":"762"},
    {"city_id":"12245","city_name":"Nanding","state_id":"762"},
    {"city_id":"12246","city_name":"Nanma","state_id":"762"},
    {"city_id":"12247","city_name":"Ninghai","state_id":"762"},
    {"city_id":"12248","city_name":"Ningyang","state_id":"762"},
    {"city_id":"12249","city_name":"Pingdu","state_id":"762"},
    {"city_id":"12250","city_name":"Pingyi","state_id":"762"},
    {"city_id":"12251","city_name":"Pingyin","state_id":"762"},
    {"city_id":"12252","city_name":"Qingdao","state_id":"762"},
    {"city_id":"12253","city_name":"Qingzhou","state_id":"762"},
    {"city_id":"12254","city_name":"Qixia","state_id":"762"},
    {"city_id":"12255","city_name":"Qufu","state_id":"762"},
    {"city_id":"12256","city_name":"Rizhao","state_id":"762"},
    {"city_id":"12257","city_name":"Rongcheng","state_id":"762"},
    {"city_id":"12258","city_name":"Shancheng","state_id":"762"},
    {"city_id":"12259","city_name":"Shanting","state_id":"762"},
    {"city_id":"12260","city_name":"Shengzhuang","state_id":"762"},
    {"city_id":"12261","city_name":"Shenxian","state_id":"762"},
    {"city_id":"12262","city_name":"Shizilu","state_id":"762"},
    {"city_id":"12263","city_name":"Shouguang","state_id":"762"},
    {"city_id":"12264","city_name":"Shuiji","state_id":"762"},
    {"city_id":"12265","city_name":"Sishui","state_id":"762"},
    {"city_id":"12266","city_name":"Suozhen","state_id":"762"},
    {"city_id":"12267","city_name":"Taian","state_id":"762"},
    {"city_id":"12268","city_name":"Tancheng","state_id":"762"},
    {"city_id":"12269","city_name":"Taozhuang","state_id":"762"},
    {"city_id":"12270","city_name":"Tengzhou","state_id":"762"},
    {"city_id":"12271","city_name":"Weifang","state_id":"762"},
    {"city_id":"12272","city_name":"Weihai","state_id":"762"},
    {"city_id":"12273","city_name":"Wencheng","state_id":"762"},
    {"city_id":"12274","city_name":"Wendeng","state_id":"762"},
    {"city_id":"12275","city_name":"Wenshang","state_id":"762"},
    {"city_id":"12276","city_name":"Wudi","state_id":"762"},
    {"city_id":"12277","city_name":"Xiazhen","state_id":"762"},
    {"city_id":"12278","city_name":"Xincheng","state_id":"762"},
    {"city_id":"12279","city_name":"Xindian","state_id":"762"},
    {"city_id":"12280","city_name":"Xintai","state_id":"762"},
    {"city_id":"12281","city_name":"Yanggu","state_id":"762"},
    {"city_id":"12282","city_name":"Yangshan","state_id":"762"},
    {"city_id":"12283","city_name":"Yantai","state_id":"762"},
    {"city_id":"12284","city_name":"Yanzhou","state_id":"762"},
    {"city_id":"12285","city_name":"Yatou","state_id":"762"},
    {"city_id":"12286","city_name":"Yidu","state_id":"762"},
    {"city_id":"12287","city_name":"Yishui","state_id":"762"},
    {"city_id":"12288","city_name":"Yucheng","state_id":"762"},
    {"city_id":"12289","city_name":"Yuncheng","state_id":"762"},
    {"city_id":"12290","city_name":"Zaozhuang","state_id":"762"},
    {"city_id":"12291","city_name":"Zhangdian","state_id":"762"},
    {"city_id":"12292","city_name":"Zhangjiawa","state_id":"762"},
    {"city_id":"12293","city_name":"Zhangqiu","state_id":"762"},
    {"city_id":"12294","city_name":"Zhaocheng","state_id":"762"},
    {"city_id":"12295","city_name":"Zhoucheng","state_id":"762"},
    {"city_id":"12296","city_name":"Zhoucun","state_id":"762"},
    {"city_id":"12297","city_name":"Zhucheng","state_id":"762"},
    {"city_id":"12298","city_name":"Zhuwang","state_id":"762"},
    {"city_id":"12299","city_name":"Zicheng","state_id":"762"},
    {"city_id":"12300","city_name":"Zouping","state_id":"762"},
    {"city_id":"12301","city_name":"Zouxian","state_id":"762"},
    {"city_id":"12302","city_name":"Jiading","state_id":"763"},
    {"city_id":"12303","city_name":"Minhang","state_id":"763"},
    {"city_id":"12304","city_name":"Shanghai","state_id":"763"},
    {"city_id":"12305","city_name":"Songjiang","state_id":"763"},
    {"city_id":"12306","city_name":"Trencin","state_id":"763"},
    {"city_id":"12307","city_name":"Changzhi","state_id":"764"},
    {"city_id":"12308","city_name":"Datong","state_id":"764"},
    {"city_id":"12309","city_name":"Houma","state_id":"764"},
    {"city_id":"12310","city_name":"Jiexiu","state_id":"764"},
    {"city_id":"12311","city_name":"Jincheng","state_id":"764"},
    {"city_id":"12312","city_name":"Linfen","state_id":"764"},
    {"city_id":"12313","city_name":"Taiyuan","state_id":"764"},
    {"city_id":"12314","city_name":"Xian","state_id":"764"},
    {"city_id":"12315","city_name":"Xinzhi","state_id":"764"},
    {"city_id":"12316","city_name":"Xinzhou","state_id":"764"},
    {"city_id":"12317","city_name":"Yangquan","state_id":"764"},
    {"city_id":"12318","city_name":"Yuanping","state_id":"764"},
    {"city_id":"12319","city_name":"Yuci","state_id":"764"},
    {"city_id":"12320","city_name":"Yuncheng","state_id":"764"},
    {"city_id":"12321","city_name":"Anju","state_id":"765"},
    {"city_id":"12322","city_name":"Baoning","state_id":"765"},
    {"city_id":"12323","city_name":"Chengdu","state_id":"765"},
    {"city_id":"12324","city_name":"Daan","state_id":"765"},
    {"city_id":"12325","city_name":"Dawan","state_id":"765"},
    {"city_id":"12326","city_name":"Daxian","state_id":"765"},
    {"city_id":"12327","city_name":"Deyang","state_id":"765"},
    {"city_id":"12328","city_name":"Dujiangyan City","state_id":"765"},
    {"city_id":"12329","city_name":"Guangkou","state_id":"765"},
    {"city_id":"12330","city_name":"Guangyuan","state_id":"765"},
    {"city_id":"12331","city_name":"Guihu","state_id":"765"},
    {"city_id":"12332","city_name":"Heyang","state_id":"765"},
    {"city_id":"12333","city_name":"Huayang","state_id":"765"},
    {"city_id":"12334","city_name":"Jiancheng","state_id":"765"},
    {"city_id":"12335","city_name":"Jiangyou","state_id":"765"},
    {"city_id":"12336","city_name":"Jijiang","state_id":"765"},
    {"city_id":"12337","city_name":"Leshan","state_id":"765"},
    {"city_id":"12338","city_name":"Linqiong","state_id":"765"},
    {"city_id":"12339","city_name":"Luocheng","state_id":"765"},
    {"city_id":"12340","city_name":"Luzhou","state_id":"765"},
    {"city_id":"12341","city_name":"Mianyang","state_id":"765"},
    {"city_id":"12342","city_name":"Nanchong","state_id":"765"},
    {"city_id":"12343","city_name":"Nanlong","state_id":"765"},
    {"city_id":"12344","city_name":"Neijiang","state_id":"765"},
    {"city_id":"12345","city_name":"Panzhihua","state_id":"765"},
    {"city_id":"12346","city_name":"Shifang","state_id":"765"},
    {"city_id":"12347","city_name":"Suining","state_id":"765"},
    {"city_id":"12348","city_name":"Taihe","state_id":"765"},
    {"city_id":"12349","city_name":"Tianpeng","state_id":"765"},
    {"city_id":"12350","city_name":"Tongchuan","state_id":"765"},
    {"city_id":"12351","city_name":"Xichang","state_id":"765"},
    {"city_id":"12352","city_name":"Xunchang","state_id":"765"},
    {"city_id":"12353","city_name":"Yaan","state_id":"765"},
    {"city_id":"12354","city_name":"Yibin","state_id":"765"},
    {"city_id":"12355","city_name":"Yongchang","state_id":"765"},
    {"city_id":"12356","city_name":"Zhonglong","state_id":"765"},
    {"city_id":"12357","city_name":"Zigong","state_id":"765"},
    {"city_id":"12358","city_name":"Ziyang","state_id":"765"},
    {"city_id":"12359","city_name":"Beichen","state_id":"766"},
    {"city_id":"12360","city_name":"Gangdong","state_id":"766"},
    {"city_id":"12361","city_name":"Hangu","state_id":"766"},
    {"city_id":"12362","city_name":"Jinghai","state_id":"766"},
    {"city_id":"12363","city_name":"Nankai","state_id":"766"},
    {"city_id":"12364","city_name":"Tanggu","state_id":"766"},
    {"city_id":"12365","city_name":"Tianjin","state_id":"766"},
    {"city_id":"12366","city_name":"Xianshuigu","state_id":"766"},
    {"city_id":"12367","city_name":"Yangcun","state_id":"766"},
    {"city_id":"12368","city_name":"Yangliuqing","state_id":"766"},
    {"city_id":"12369","city_name":"Guiqing","state_id":"767"},
    {"city_id":"12370","city_name":"Jiulong","state_id":"767"},
    {"city_id":"12371","city_name":"Quanwan","state_id":"767"},
    {"city_id":"12372","city_name":"Saigong","state_id":"767"},
    {"city_id":"12373","city_name":"Shatin","state_id":"767"},
    {"city_id":"12374","city_name":"Taipo","state_id":"767"},
    {"city_id":"12375","city_name":"Tuanmun","state_id":"767"},
    {"city_id":"12376","city_name":"Xianggang","state_id":"767"},
    {"city_id":"12377","city_name":"Yuanlong","state_id":"767"},
    {"city_id":"12378","city_name":"Aksu","state_id":"768"},
    {"city_id":"12379","city_name":"Baijiantan","state_id":"768"},
    {"city_id":"12380","city_name":"Changji","state_id":"768"},
    {"city_id":"12381","city_name":"Dongshan","state_id":"768"},
    {"city_id":"12382","city_name":"Hami","state_id":"768"},
    {"city_id":"12383","city_name":"Hetian","state_id":"768"},
    {"city_id":"12384","city_name":"Karamay","state_id":"768"},
    {"city_id":"12385","city_name":"Kashi","state_id":"768"},
    {"city_id":"12386","city_name":"Korla","state_id":"768"},
    {"city_id":"12387","city_name":"Kuche","state_id":"768"},
    {"city_id":"12388","city_name":"Kuytun","state_id":"768"},
    {"city_id":"12389","city_name":"Shache","state_id":"768"},
    {"city_id":"12390","city_name":"Shihezi","state_id":"768"},
    {"city_id":"12391","city_name":"Shuimogou","state_id":"768"},
    {"city_id":"12392","city_name":"Toutunhe","state_id":"768"},
    {"city_id":"12393","city_name":"Urumqi","state_id":"768"},
    {"city_id":"12394","city_name":"Yining","state_id":"768"},
    {"city_id":"12395","city_name":"Lasa","state_id":"769"},
    {"city_id":"12396","city_name":"Dali","state_id":"770"},
    {"city_id":"12397","city_name":"Gejiu","state_id":"770"},
    {"city_id":"12398","city_name":"Haikou","state_id":"770"},
    {"city_id":"12399","city_name":"Heilin","state_id":"770"},
    {"city_id":"12400","city_name":"Jinma","state_id":"770"},
    {"city_id":"12401","city_name":"Kaihua","state_id":"770"},
    {"city_id":"12402","city_name":"Kaiyuan","state_id":"770"},
    {"city_id":"12403","city_name":"Kunming","state_id":"770"},
    {"city_id":"12404","city_name":"Lianran","state_id":"770"},
    {"city_id":"12405","city_name":"Longquan","state_id":"770"},
    {"city_id":"12406","city_name":"Lucheng","state_id":"770"},
    {"city_id":"12407","city_name":"Mabai","state_id":"770"},
    {"city_id":"12408","city_name":"Majie","state_id":"770"},
    {"city_id":"12409","city_name":"Miyang","state_id":"770"},
    {"city_id":"12410","city_name":"Qujing","state_id":"770"},
    {"city_id":"12411","city_name":"Rongcheng","state_id":"770"},
    {"city_id":"12412","city_name":"Simao","state_id":"770"},
    {"city_id":"12413","city_name":"Wufeng","state_id":"770"},
    {"city_id":"12414","city_name":"Yunjinghong","state_id":"770"},
    {"city_id":"12415","city_name":"Yuxi Municipal","state_id":"770"},
    {"city_id":"12416","city_name":"Zhaotong","state_id":"770"},
    {"city_id":"12417","city_name":"Zhenhai","state_id":"770"},
    {"city_id":"12418","city_name":"Zhongshu","state_id":"770"},
    {"city_id":"12419","city_name":"Zhoucheng","state_id":"770"},
    {"city_id":"12420","city_name":"Zhuocheng","state_id":"770"},
    {"city_id":"12421","city_name":"Aojiang","state_id":"771"},
    {"city_id":"12422","city_name":"Choucheng","state_id":"771"},
    {"city_id":"12423","city_name":"Cixi","state_id":"771"},
    {"city_id":"12424","city_name":"Daqiao","state_id":"771"},
    {"city_id":"12425","city_name":"Deqing","state_id":"771"},
    {"city_id":"12426","city_name":"Dinghai","state_id":"771"},
    {"city_id":"12427","city_name":"Dongyang","state_id":"771"},
    {"city_id":"12428","city_name":"Fuyang","state_id":"771"},
    {"city_id":"12429","city_name":"Haining","state_id":"771"},
    {"city_id":"12430","city_name":"Haiyan","state_id":"771"},
    {"city_id":"12431","city_name":"Hangzhou","state_id":"771"},
    {"city_id":"12432","city_name":"Huangyan","state_id":"771"},
    {"city_id":"12433","city_name":"Hushan","state_id":"771"},
    {"city_id":"12434","city_name":"Huzhou","state_id":"771"},
    {"city_id":"12435","city_name":"Jiaojiang","state_id":"771"},
    {"city_id":"12436","city_name":"Jiaxing","state_id":"771"},
    {"city_id":"12437","city_name":"Jinhua","state_id":"771"},
    {"city_id":"12438","city_name":"Jinxiang","state_id":"771"},
    {"city_id":"12439","city_name":"Kaihua","state_id":"771"},
    {"city_id":"12440","city_name":"Kunyang","state_id":"771"},
    {"city_id":"12441","city_name":"Lanxi","state_id":"771"},
    {"city_id":"12442","city_name":"Linan City","state_id":"771"},
    {"city_id":"12443","city_name":"Linhai","state_id":"771"},
    {"city_id":"12444","city_name":"Linping","state_id":"771"},
    {"city_id":"12445","city_name":"Lishui","state_id":"771"},
    {"city_id":"12446","city_name":"Liushi","state_id":"771"},
    {"city_id":"12447","city_name":"Ningbo","state_id":"771"},
    {"city_id":"12448","city_name":"Ninghai","state_id":"771"},
    {"city_id":"12449","city_name":"Pinghu","state_id":"771"},
    {"city_id":"12450","city_name":"Quzhou","state_id":"771"},
    {"city_id":"12451","city_name":"Ruian","state_id":"771"},
    {"city_id":"12452","city_name":"Shangyu","state_id":"771"},
    {"city_id":"12453","city_name":"Shaoxing","state_id":"771"},
    {"city_id":"12454","city_name":"Shenjiamen","state_id":"771"},
    {"city_id":"12455","city_name":"Taizhou City","state_id":"771"},
    {"city_id":"12456","city_name":"Tonglu","state_id":"771"},
    {"city_id":"12457","city_name":"Wenling","state_id":"771"},
    {"city_id":"12458","city_name":"Wenzhou","state_id":"771"},
    {"city_id":"12459","city_name":"Wuning","state_id":"771"},
    {"city_id":"12460","city_name":"Wuyi","state_id":"771"},
    {"city_id":"12461","city_name":"Xianju","state_id":"771"},
    {"city_id":"12462","city_name":"Xiaoshan","state_id":"771"},
    {"city_id":"12463","city_name":"Xiashi","state_id":"771"},
    {"city_id":"12464","city_name":"Xushan","state_id":"771"},
    {"city_id":"12465","city_name":"Yiwu","state_id":"771"},
    {"city_id":"12466","city_name":"Yongkang","state_id":"771"},
    {"city_id":"12467","city_name":"Yueqing","state_id":"771"},
    {"city_id":"12468","city_name":"Yuhuan","state_id":"771"},
    {"city_id":"12469","city_name":"Yuyao","state_id":"771"},
    {"city_id":"12470","city_name":"Zhejiang","state_id":"771"},
    {"city_id":"12471","city_name":"Zhenhai","state_id":"771"},
    {"city_id":"12472","city_name":"Zhicheng","state_id":"771"},
    {"city_id":"12473","city_name":"Zhuji","state_id":"771"},
    {"city_id":"12474","city_name":"fenghua","state_id":"771"},
    {"city_id":"12475","city_name":"jiashan","state_id":"771"},
    {"city_id":"12476","city_name":"Aojiang","state_id":"772"},
    {"city_id":"12477","city_name":"Choucheng","state_id":"772"},
    {"city_id":"12478","city_name":"Cixi","state_id":"772"},
    {"city_id":"12479","city_name":"Daqiao","state_id":"772"},
    {"city_id":"12480","city_name":"Deqing","state_id":"772"},
    {"city_id":"12481","city_name":"Dinghai","state_id":"772"},
    {"city_id":"12482","city_name":"Dongyang","state_id":"772"},
    {"city_id":"12483","city_name":"Fuyang","state_id":"772"},
    {"city_id":"12484","city_name":"Haining","state_id":"772"},
    {"city_id":"12485","city_name":"Haiyan","state_id":"772"},
    {"city_id":"12486","city_name":"Hangzhou","state_id":"772"},
    {"city_id":"12487","city_name":"Huangyan","state_id":"772"},
    {"city_id":"12488","city_name":"Hushan","state_id":"772"},
    {"city_id":"12489","city_name":"Huzhou","state_id":"772"},
    {"city_id":"12490","city_name":"Jiaojiang","state_id":"772"},
    {"city_id":"12491","city_name":"Jiaxing","state_id":"772"},
    {"city_id":"12492","city_name":"Jinhua","state_id":"772"},
    {"city_id":"12493","city_name":"Jinxiang","state_id":"772"},
    {"city_id":"12494","city_name":"Kaihua","state_id":"772"},
    {"city_id":"12495","city_name":"Kunyang","state_id":"772"},
    {"city_id":"12496","city_name":"Lanxi","state_id":"772"},
    {"city_id":"12497","city_name":"Linan City","state_id":"772"},
    {"city_id":"12498","city_name":"Linhai","state_id":"772"},
    {"city_id":"12499","city_name":"Linping","state_id":"772"},
    {"city_id":"12500","city_name":"Lishui","state_id":"772"},
    {"city_id":"12501","city_name":"Liushi","state_id":"772"},
    {"city_id":"12502","city_name":"Ningbo","state_id":"772"},
    {"city_id":"12503","city_name":"Ninghai","state_id":"772"},
    {"city_id":"12504","city_name":"Pinghu","state_id":"772"},
    {"city_id":"12505","city_name":"Quzhou","state_id":"772"},
    {"city_id":"12506","city_name":"Ruian","state_id":"772"},
    {"city_id":"12507","city_name":"Shangyu","state_id":"772"},
    {"city_id":"12508","city_name":"Shaoxing","state_id":"772"},
    {"city_id":"12509","city_name":"Shenjiamen","state_id":"772"},
    {"city_id":"12510","city_name":"Taizhou City","state_id":"772"},
    {"city_id":"12511","city_name":"Tonglu","state_id":"772"},
    {"city_id":"12512","city_name":"Wenling","state_id":"772"},
    {"city_id":"12513","city_name":"Wenzhou","state_id":"772"},
    {"city_id":"12514","city_name":"Wuning","state_id":"772"},
    {"city_id":"12515","city_name":"Wuyi","state_id":"772"},
    {"city_id":"12516","city_name":"Xianju","state_id":"772"},
    {"city_id":"12517","city_name":"Xiaoshan","state_id":"772"},
    {"city_id":"12518","city_name":"Xiashi","state_id":"772"},
    {"city_id":"12519","city_name":"Xushan","state_id":"772"},
    {"city_id":"12520","city_name":"Yiwu","state_id":"772"},
    {"city_id":"12521","city_name":"Yongkang","state_id":"772"},
    {"city_id":"12522","city_name":"Yueqing","state_id":"772"},
    {"city_id":"12523","city_name":"Yuhuan","state_id":"772"},
    {"city_id":"12524","city_name":"Yuyao","state_id":"772"},
    {"city_id":"12525","city_name":"Zhejiang","state_id":"772"},
    {"city_id":"12526","city_name":"Zhenhai","state_id":"772"},
    {"city_id":"12527","city_name":"Zhicheng","state_id":"772"},
    {"city_id":"12528","city_name":"Zhuji","state_id":"772"},
    {"city_id":"12529","city_name":"fenghua","state_id":"772"},
    {"city_id":"12530","city_name":"jiashan","state_id":"772"},
    {"city_id":"12531","city_name":"Leticia","state_id":"775"},
    {"city_id":"12532","city_name":"Puerto Narino","state_id":"775"},
    {"city_id":"12533","city_name":"Abejorral","state_id":"776"},
    {"city_id":"12534","city_name":"Abriaqui","state_id":"776"},
    {"city_id":"12535","city_name":"Alejandria","state_id":"776"},
    {"city_id":"12536","city_name":"Amaga","state_id":"776"},
    {"city_id":"12537","city_name":"Amalfi","state_id":"776"},
    {"city_id":"12538","city_name":"Andes","state_id":"776"},
    {"city_id":"12539","city_name":"Angelopolis","state_id":"776"},
    {"city_id":"12540","city_name":"Angostura","state_id":"776"},
    {"city_id":"12541","city_name":"Anori","state_id":"776"},
    {"city_id":"12542","city_name":"Antioquia","state_id":"776"},
    {"city_id":"12543","city_name":"Anza","state_id":"776"},
    {"city_id":"12544","city_name":"Apartado","state_id":"776"},
    {"city_id":"12545","city_name":"Arboletes","state_id":"776"},
    {"city_id":"12546","city_name":"Argelia","state_id":"776"},
    {"city_id":"12547","city_name":"Armenia","state_id":"776"},
    {"city_id":"12548","city_name":"Barbosa","state_id":"776"},
    {"city_id":"12549","city_name":"Bello","state_id":"776"},
    {"city_id":"12550","city_name":"Belmira","state_id":"776"},
    {"city_id":"12551","city_name":"Betania","state_id":"776"},
    {"city_id":"12552","city_name":"Betulia","state_id":"776"},
    {"city_id":"12553","city_name":"Bolivar","state_id":"776"},
    {"city_id":"12554","city_name":"Briceno","state_id":"776"},
    {"city_id":"12555","city_name":"Buritica","state_id":"776"},
    {"city_id":"12556","city_name":"Caceres","state_id":"776"},
    {"city_id":"12557","city_name":"Caicedo","state_id":"776"},
    {"city_id":"12558","city_name":"Caldas","state_id":"776"},
    {"city_id":"12559","city_name":"Campamento","state_id":"776"},
    {"city_id":"12560","city_name":"Canasgordas","state_id":"776"},
    {"city_id":"12561","city_name":"Caracoli","state_id":"776"},
    {"city_id":"12562","city_name":"Caramanta","state_id":"776"},
    {"city_id":"12563","city_name":"Carepa","state_id":"776"},
    {"city_id":"12564","city_name":"Carmen de Viboral","state_id":"776"},
    {"city_id":"12565","city_name":"Carolina","state_id":"776"},
    {"city_id":"12566","city_name":"Caucasia","state_id":"776"},
    {"city_id":"12567","city_name":"Chigorodo","state_id":"776"},
    {"city_id":"12568","city_name":"Cisneros","state_id":"776"},
    {"city_id":"12569","city_name":"Cocorna","state_id":"776"},
    {"city_id":"12570","city_name":"Concepcion","state_id":"776"},
    {"city_id":"12571","city_name":"Concordia","state_id":"776"},
    {"city_id":"12572","city_name":"Copacabana","state_id":"776"},
    {"city_id":"12573","city_name":"Dabeiba","state_id":"776"},
    {"city_id":"12574","city_name":"Don Matias","state_id":"776"},
    {"city_id":"12575","city_name":"Ebejico","state_id":"776"},
    {"city_id":"12576","city_name":"El Bagre","state_id":"776"},
    {"city_id":"12577","city_name":"Entrerrios","state_id":"776"},
    {"city_id":"12578","city_name":"Envigado","state_id":"776"},
    {"city_id":"12579","city_name":"Fredonia","state_id":"776"},
    {"city_id":"12580","city_name":"Frontino","state_id":"776"},
    {"city_id":"12581","city_name":"Giraldo","state_id":"776"},
    {"city_id":"12582","city_name":"Girardota","state_id":"776"},
    {"city_id":"12583","city_name":"Gomez Plata","state_id":"776"},
    {"city_id":"12584","city_name":"Granada","state_id":"776"},
    {"city_id":"12585","city_name":"Guadalupe","state_id":"776"},
    {"city_id":"12586","city_name":"Guarne","state_id":"776"},
    {"city_id":"12587","city_name":"Guatape","state_id":"776"},
    {"city_id":"12588","city_name":"Heliconia","state_id":"776"},
    {"city_id":"12589","city_name":"Hispania","state_id":"776"},
    {"city_id":"12590","city_name":"Itagui","state_id":"776"},
    {"city_id":"12591","city_name":"Ituango","state_id":"776"},
    {"city_id":"12592","city_name":"Jardin","state_id":"776"},
    {"city_id":"12593","city_name":"Jerico","state_id":"776"},
    {"city_id":"12594","city_name":"La Ceja","state_id":"776"},
    {"city_id":"12595","city_name":"La Estrella","state_id":"776"},
    {"city_id":"12596","city_name":"La Pintada","state_id":"776"},
    {"city_id":"12597","city_name":"La Union","state_id":"776"},
    {"city_id":"12598","city_name":"Liborina","state_id":"776"},
    {"city_id":"12599","city_name":"Maceo","state_id":"776"},
    {"city_id":"12600","city_name":"Marinilla","state_id":"776"},
    {"city_id":"12601","city_name":"Medellin","state_id":"776"},
    {"city_id":"12602","city_name":"Montebello","state_id":"776"},
    {"city_id":"12603","city_name":"Murindo","state_id":"776"},
    {"city_id":"12604","city_name":"Mutata","state_id":"776"},
    {"city_id":"12605","city_name":"Narino","state_id":"776"},
    {"city_id":"12606","city_name":"Nechi","state_id":"776"},
    {"city_id":"12607","city_name":"Necocli","state_id":"776"},
    {"city_id":"12608","city_name":"Olaya","state_id":"776"},
    {"city_id":"12609","city_name":"Penol","state_id":"776"},
    {"city_id":"12610","city_name":"Peque","state_id":"776"},
    {"city_id":"12611","city_name":"Pueblorrico","state_id":"776"},
    {"city_id":"12612","city_name":"Puerto Berrio","state_id":"776"},
    {"city_id":"12613","city_name":"Puerto Nare","state_id":"776"},
    {"city_id":"12614","city_name":"Puerto Triunfo","state_id":"776"},
    {"city_id":"12615","city_name":"Remedios","state_id":"776"},
    {"city_id":"12616","city_name":"Retiro","state_id":"776"},
    {"city_id":"12617","city_name":"Rionegro","state_id":"776"},
    {"city_id":"12618","city_name":"Sabanalarga","state_id":"776"},
    {"city_id":"12619","city_name":"Sabaneta","state_id":"776"},
    {"city_id":"12620","city_name":"Salgar","state_id":"776"},
    {"city_id":"12621","city_name":"San Andres","state_id":"776"},
    {"city_id":"12622","city_name":"San Carlos","state_id":"776"},
    {"city_id":"12623","city_name":"San Francisco","state_id":"776"},
    {"city_id":"12624","city_name":"San Jeronimo","state_id":"776"},
    {"city_id":"12625","city_name":"San Jose de la Montana","state_id":"776"},
    {"city_id":"12626","city_name":"San Juan de Uraba","state_id":"776"},
    {"city_id":"12627","city_name":"San Luis","state_id":"776"},
    {"city_id":"12628","city_name":"San Pedro","state_id":"776"},
    {"city_id":"12629","city_name":"San Pedro de Uraba","state_id":"776"},
    {"city_id":"12630","city_name":"San Rafael","state_id":"776"},
    {"city_id":"12631","city_name":"San Roque","state_id":"776"},
    {"city_id":"12632","city_name":"San Vicente","state_id":"776"},
    {"city_id":"12633","city_name":"Santa Barbara","state_id":"776"},
    {"city_id":"12634","city_name":"Santa Rosa de Osos","state_id":"776"},
    {"city_id":"12635","city_name":"Santo Domingo","state_id":"776"},
    {"city_id":"12636","city_name":"Santuario","state_id":"776"},
    {"city_id":"12637","city_name":"Segovia","state_id":"776"},
    {"city_id":"12638","city_name":"Sonson","state_id":"776"},
    {"city_id":"12639","city_name":"Sopetran","state_id":"776"},
    {"city_id":"12640","city_name":"Tamesis","state_id":"776"},
    {"city_id":"12641","city_name":"Taraza","state_id":"776"},
    {"city_id":"12642","city_name":"Tarso","state_id":"776"},
    {"city_id":"12643","city_name":"Titiribi","state_id":"776"},
    {"city_id":"12644","city_name":"Toledo","state_id":"776"},
    {"city_id":"12645","city_name":"Turbo","state_id":"776"},
    {"city_id":"12646","city_name":"Uramita","state_id":"776"},
    {"city_id":"12647","city_name":"Urrao","state_id":"776"},
    {"city_id":"12648","city_name":"Valdivia","state_id":"776"},
    {"city_id":"12649","city_name":"Valparaiso","state_id":"776"},
    {"city_id":"12650","city_name":"Vegachi","state_id":"776"},
    {"city_id":"12651","city_name":"Venecia","state_id":"776"},
    {"city_id":"12652","city_name":"Vigia del Fuerte","state_id":"776"},
    {"city_id":"12653","city_name":"Yali","state_id":"776"},
    {"city_id":"12654","city_name":"Yarumal","state_id":"776"},
    {"city_id":"12655","city_name":"Yolombo","state_id":"776"},
    {"city_id":"12656","city_name":"Yondo","state_id":"776"},
    {"city_id":"12657","city_name":"Zaragoza","state_id":"776"},
    {"city_id":"12658","city_name":"Arauca","state_id":"777"},
    {"city_id":"12659","city_name":"Arauquita","state_id":"777"},
    {"city_id":"12660","city_name":"Cravo Norte","state_id":"777"},
    {"city_id":"12661","city_name":"Fortul","state_id":"777"},
    {"city_id":"12662","city_name":"Puerto Rondon","state_id":"777"},
    {"city_id":"12663","city_name":"Saravena","state_id":"777"},
    {"city_id":"12664","city_name":"Tame","state_id":"777"},
    {"city_id":"12665","city_name":"Baranoa","state_id":"778"},
    {"city_id":"12666","city_name":"Barranquilla","state_id":"778"},
    {"city_id":"12667","city_name":"Campo de la Cruz","state_id":"778"},
    {"city_id":"12668","city_name":"Candelaria","state_id":"778"},
    {"city_id":"12669","city_name":"Galapa","state_id":"778"},
    {"city_id":"12670","city_name":"Juan de Acosta","state_id":"778"},
    {"city_id":"12671","city_name":"Luruaco","state_id":"778"},
    {"city_id":"12672","city_name":"Malambo","state_id":"778"},
    {"city_id":"12673","city_name":"Manati","state_id":"778"},
    {"city_id":"12674","city_name":"Palmar de Varela","state_id":"778"},
    {"city_id":"12675","city_name":"Piojo","state_id":"778"},
    {"city_id":"12676","city_name":"Polo Nuevo","state_id":"778"},
    {"city_id":"12677","city_name":"Ponedera","state_id":"778"},
    {"city_id":"12678","city_name":"Puerto Colombia","state_id":"778"},
    {"city_id":"12679","city_name":"Repelon","state_id":"778"},
    {"city_id":"12680","city_name":"Sabanagrande","state_id":"778"},
    {"city_id":"12681","city_name":"Sabanalarga","state_id":"778"},
    {"city_id":"12682","city_name":"Santa Lucia","state_id":"778"},
    {"city_id":"12683","city_name":"Santo Tomas","state_id":"778"},
    {"city_id":"12684","city_name":"Soledad","state_id":"778"},
    {"city_id":"12685","city_name":"Suan","state_id":"778"},
    {"city_id":"12686","city_name":"Tubara","state_id":"778"},
    {"city_id":"12687","city_name":"Usiacuri","state_id":"778"},
    {"city_id":"12688","city_name":"Bogota","state_id":"779"},
    {"city_id":"12689","city_name":"Achi","state_id":"780"},
    {"city_id":"12690","city_name":"Altos del Rosario","state_id":"780"},
    {"city_id":"12691","city_name":"Arenal","state_id":"780"},
    {"city_id":"12692","city_name":"Arjona","state_id":"780"},
    {"city_id":"12693","city_name":"Arroyohondo","state_id":"780"},
    {"city_id":"12694","city_name":"Barranco de Loba","state_id":"780"},
    {"city_id":"12695","city_name":"Calamar","state_id":"780"},
    {"city_id":"12696","city_name":"Cantagallo","state_id":"780"},
    {"city_id":"12697","city_name":"Cartagena","state_id":"780"},
    {"city_id":"12698","city_name":"Cicuco","state_id":"780"},
    {"city_id":"12699","city_name":"Clemencia","state_id":"780"},
    {"city_id":"12700","city_name":"Cordoba","state_id":"780"},
    {"city_id":"12701","city_name":"El Carmen de Bolivar","state_id":"780"},
    {"city_id":"12702","city_name":"El Guamo","state_id":"780"},
    {"city_id":"12703","city_name":"El Penon","state_id":"780"},
    {"city_id":"12704","city_name":"Hatillo de Loba","state_id":"780"},
    {"city_id":"12705","city_name":"Magangue","state_id":"780"},
    {"city_id":"12706","city_name":"Mahates","state_id":"780"},
    {"city_id":"12707","city_name":"Margarita","state_id":"780"},
    {"city_id":"12708","city_name":"Maria la Baja","state_id":"780"},
    {"city_id":"12709","city_name":"Mompos","state_id":"780"},
    {"city_id":"12710","city_name":"Montecristo","state_id":"780"},
    {"city_id":"12711","city_name":"Morales","state_id":"780"},
    {"city_id":"12712","city_name":"Pinillos","state_id":"780"},
    {"city_id":"12713","city_name":"Regidor","state_id":"780"},
    {"city_id":"12714","city_name":"Rio Viejo","state_id":"780"},
    {"city_id":"12715","city_name":"San Cristobal","state_id":"780"},
    {"city_id":"12716","city_name":"San Estanislao","state_id":"780"},
    {"city_id":"12717","city_name":"San Fernando","state_id":"780"},
    {"city_id":"12718","city_name":"San Jacinto","state_id":"780"},
    {"city_id":"12719","city_name":"San Jacinto del Cauca","state_id":"780"},
    {"city_id":"12720","city_name":"San Juan Nepomuceno","state_id":"780"},
    {"city_id":"12721","city_name":"San Martin de Loba","state_id":"780"},
    {"city_id":"12722","city_name":"San Pablo","state_id":"780"},
    {"city_id":"12723","city_name":"Santa Catalina","state_id":"780"},
    {"city_id":"12724","city_name":"Santa Rosa","state_id":"780"},
    {"city_id":"12725","city_name":"Santa Rosa del Sur","state_id":"780"},
    {"city_id":"12726","city_name":"Simiti","state_id":"780"},
    {"city_id":"12727","city_name":"Soplaviento","state_id":"780"},
    {"city_id":"12728","city_name":"Talaigua Nuevo","state_id":"780"},
    {"city_id":"12729","city_name":"Tiquisio","state_id":"780"},
    {"city_id":"12730","city_name":"Turbaco","state_id":"780"},
    {"city_id":"12731","city_name":"Turbana","state_id":"780"},
    {"city_id":"12732","city_name":"Villanueva","state_id":"780"},
    {"city_id":"12733","city_name":"Zambrano","state_id":"780"},
    {"city_id":"12734","city_name":"Almeida","state_id":"781"},
    {"city_id":"12735","city_name":"Aquitania","state_id":"781"},
    {"city_id":"12736","city_name":"Arcabuco","state_id":"781"},
    {"city_id":"12737","city_name":"Belen","state_id":"781"},
    {"city_id":"12738","city_name":"Berbeo","state_id":"781"},
    {"city_id":"12739","city_name":"Beteitiva","state_id":"781"},
    {"city_id":"12740","city_name":"Boavita","state_id":"781"},
    {"city_id":"12741","city_name":"Boyaca","state_id":"781"},
    {"city_id":"12742","city_name":"Briceno","state_id":"781"},
    {"city_id":"12743","city_name":"Buenavista","state_id":"781"},
    {"city_id":"12744","city_name":"Busbanza","state_id":"781"},
    {"city_id":"12745","city_name":"Caldas","state_id":"781"},
    {"city_id":"12746","city_name":"Campohermoso","state_id":"781"},
    {"city_id":"12747","city_name":"Cerinza","state_id":"781"},
    {"city_id":"12748","city_name":"Chinavita","state_id":"781"},
    {"city_id":"12749","city_name":"Chiquinquira","state_id":"781"},
    {"city_id":"12750","city_name":"Chiquiza","state_id":"781"},
    {"city_id":"12751","city_name":"Chiscas","state_id":"781"},
    {"city_id":"12752","city_name":"Chita","state_id":"781"},
    {"city_id":"12753","city_name":"Chitaraque","state_id":"781"},
    {"city_id":"12754","city_name":"Chivata","state_id":"781"},
    {"city_id":"12755","city_name":"Chivor","state_id":"781"},
    {"city_id":"12756","city_name":"Cienega","state_id":"781"},
    {"city_id":"12757","city_name":"Combita","state_id":"781"},
    {"city_id":"12758","city_name":"Coper","state_id":"781"},
    {"city_id":"12759","city_name":"Corrales","state_id":"781"},
    {"city_id":"12760","city_name":"Covarachia","state_id":"781"},
    {"city_id":"12761","city_name":"Cubara","state_id":"781"},
    {"city_id":"12762","city_name":"Cucaita","state_id":"781"},
    {"city_id":"12763","city_name":"Cuitiva","state_id":"781"},
    {"city_id":"12764","city_name":"Duitama","state_id":"781"},
    {"city_id":"12765","city_name":"El Cocuy","state_id":"781"},
    {"city_id":"12766","city_name":"El Espino","state_id":"781"},
    {"city_id":"12767","city_name":"Firavitoba","state_id":"781"},
    {"city_id":"12768","city_name":"Floresta","state_id":"781"},
    {"city_id":"12769","city_name":"Gachantiva","state_id":"781"},
    {"city_id":"12770","city_name":"Gameza","state_id":"781"},
    {"city_id":"12771","city_name":"Garagoa","state_id":"781"},
    {"city_id":"12772","city_name":"Guacamayas","state_id":"781"},
    {"city_id":"12773","city_name":"Guateque","state_id":"781"},
    {"city_id":"12774","city_name":"Guayata","state_id":"781"},
    {"city_id":"12775","city_name":"Guican","state_id":"781"},
    {"city_id":"12776","city_name":"Iza","state_id":"781"},
    {"city_id":"12777","city_name":"Jenesano","state_id":"781"},
    {"city_id":"12778","city_name":"Jerico","state_id":"781"},
    {"city_id":"12779","city_name":"La Capilla","state_id":"781"},
    {"city_id":"12780","city_name":"La Uvita","state_id":"781"},
    {"city_id":"12781","city_name":"La Victoria","state_id":"781"},
    {"city_id":"12782","city_name":"Labranzagrande","state_id":"781"},
    {"city_id":"12783","city_name":"Leiva","state_id":"781"},
    {"city_id":"12784","city_name":"Macanal","state_id":"781"},
    {"city_id":"12785","city_name":"Maripi","state_id":"781"},
    {"city_id":"12786","city_name":"Miraflores","state_id":"781"},
    {"city_id":"12787","city_name":"Mongua","state_id":"781"},
    {"city_id":"12788","city_name":"Mongui","state_id":"781"},
    {"city_id":"12789","city_name":"Moniquira","state_id":"781"},
    {"city_id":"12790","city_name":"Motavita","state_id":"781"},
    {"city_id":"12791","city_name":"Muzo","state_id":"781"},
    {"city_id":"12792","city_name":"Nobsa","state_id":"781"},
    {"city_id":"12793","city_name":"Nuevo Colon","state_id":"781"},
    {"city_id":"12794","city_name":"Oicata","state_id":"781"},
    {"city_id":"12795","city_name":"Otanche","state_id":"781"},
    {"city_id":"12796","city_name":"Pachavita","state_id":"781"},
    {"city_id":"12797","city_name":"Paez","state_id":"781"},
    {"city_id":"12798","city_name":"Paipa","state_id":"781"},
    {"city_id":"12799","city_name":"Pajarito","state_id":"781"},
    {"city_id":"12800","city_name":"Panqueba","state_id":"781"},
    {"city_id":"12801","city_name":"Pauna","state_id":"781"},
    {"city_id":"12802","city_name":"Paya","state_id":"781"},
    {"city_id":"12803","city_name":"Paz del Rio","state_id":"781"},
    {"city_id":"12804","city_name":"Pesca","state_id":"781"},
    {"city_id":"12805","city_name":"Pisba","state_id":"781"},
    {"city_id":"12806","city_name":"Puerto Boyaca","state_id":"781"},
    {"city_id":"12807","city_name":"Quipama","state_id":"781"},
    {"city_id":"12808","city_name":"Ramiriqui","state_id":"781"},
    {"city_id":"12809","city_name":"Raquira","state_id":"781"},
    {"city_id":"12810","city_name":"Rondon","state_id":"781"},
    {"city_id":"12811","city_name":"Saboya","state_id":"781"},
    {"city_id":"12812","city_name":"Sachica","state_id":"781"},
    {"city_id":"12813","city_name":"Samaca","state_id":"781"},
    {"city_id":"12814","city_name":"San Eduardo","state_id":"781"},
    {"city_id":"12815","city_name":"San Jose de Pare","state_id":"781"},
    {"city_id":"12816","city_name":"San Luis de Gaceno","state_id":"781"},
    {"city_id":"12817","city_name":"San Mateo","state_id":"781"},
    {"city_id":"12818","city_name":"San Miguel de Sema","state_id":"781"},
    {"city_id":"12819","city_name":"San Pablo de Borbur","state_id":"781"},
    {"city_id":"12820","city_name":"Santa Maria","state_id":"781"},
    {"city_id":"12821","city_name":"Santa Rosa de Viterbo","state_id":"781"},
    {"city_id":"12822","city_name":"Santa Sofia","state_id":"781"},
    {"city_id":"12823","city_name":"Santana","state_id":"781"},
    {"city_id":"12824","city_name":"Sativanorte","state_id":"781"},
    {"city_id":"12825","city_name":"Sativasur","state_id":"781"},
    {"city_id":"12826","city_name":"Siachoque","state_id":"781"},
    {"city_id":"12827","city_name":"Soata","state_id":"781"},
    {"city_id":"12828","city_name":"Socha","state_id":"781"},
    {"city_id":"12829","city_name":"Socota","state_id":"781"},
    {"city_id":"12830","city_name":"Sogamoso","state_id":"781"},
    {"city_id":"12831","city_name":"Somondoco","state_id":"781"},
    {"city_id":"12832","city_name":"Sora","state_id":"781"},
    {"city_id":"12833","city_name":"Soraca","state_id":"781"},
    {"city_id":"12834","city_name":"Sotaquira","state_id":"781"},
    {"city_id":"12835","city_name":"Susacon","state_id":"781"},
    {"city_id":"12836","city_name":"Sutamarchan","state_id":"781"},
    {"city_id":"12837","city_name":"Sutatenza","state_id":"781"},
    {"city_id":"12838","city_name":"Tasco","state_id":"781"},
    {"city_id":"12839","city_name":"Tenza","state_id":"781"},
    {"city_id":"12840","city_name":"Tibana","state_id":"781"},
    {"city_id":"12841","city_name":"Tibasosa","state_id":"781"},
    {"city_id":"12842","city_name":"Tinjaca","state_id":"781"},
    {"city_id":"12843","city_name":"Tipacoque","state_id":"781"},
    {"city_id":"12844","city_name":"Toca","state_id":"781"},
    {"city_id":"12845","city_name":"Togui","state_id":"781"},
    {"city_id":"12846","city_name":"Topaga","state_id":"781"},
    {"city_id":"12847","city_name":"Tota","state_id":"781"},
    {"city_id":"12848","city_name":"Tunja","state_id":"781"},
    {"city_id":"12849","city_name":"Tunungua","state_id":"781"},
    {"city_id":"12850","city_name":"Turmeque","state_id":"781"},
    {"city_id":"12851","city_name":"Tuta","state_id":"781"},
    {"city_id":"12852","city_name":"Tutasa","state_id":"781"},
    {"city_id":"12853","city_name":"Umbita","state_id":"781"},
    {"city_id":"12854","city_name":"Ventaquemada","state_id":"781"},
    {"city_id":"12855","city_name":"Viracacha","state_id":"781"},
    {"city_id":"12856","city_name":"Zetaquira","state_id":"781"},
    {"city_id":"12857","city_name":"Aguadas","state_id":"782"},
    {"city_id":"12858","city_name":"Anserma","state_id":"782"},
    {"city_id":"12859","city_name":"Aranzazu","state_id":"782"},
    {"city_id":"12860","city_name":"Belalcazar","state_id":"782"},
    {"city_id":"12861","city_name":"Chinchina","state_id":"782"},
    {"city_id":"12862","city_name":"Filadelfia","state_id":"782"},
    {"city_id":"12863","city_name":"La Dorada","state_id":"782"},
    {"city_id":"12864","city_name":"La Merced","state_id":"782"},
    {"city_id":"12865","city_name":"Manizales","state_id":"782"},
    {"city_id":"12866","city_name":"Manzanares","state_id":"782"},
    {"city_id":"12867","city_name":"Marmato","state_id":"782"},
    {"city_id":"12868","city_name":"Marquetalia","state_id":"782"},
    {"city_id":"12869","city_name":"Marulanda","state_id":"782"},
    {"city_id":"12870","city_name":"Neira","state_id":"782"},
    {"city_id":"12871","city_name":"Norcasia","state_id":"782"},
    {"city_id":"12872","city_name":"Pacora","state_id":"782"},
    {"city_id":"12873","city_name":"Palestina","state_id":"782"},
    {"city_id":"12874","city_name":"Pensilvania","state_id":"782"},
    {"city_id":"12875","city_name":"Riosucio","state_id":"782"},
    {"city_id":"12876","city_name":"Risaralda","state_id":"782"},
    {"city_id":"12877","city_name":"Salamina","state_id":"782"},
    {"city_id":"12878","city_name":"Samana","state_id":"782"},
    {"city_id":"12879","city_name":"San Jose","state_id":"782"},
    {"city_id":"12880","city_name":"Supia","state_id":"782"},
    {"city_id":"12881","city_name":"Victoria","state_id":"782"},
    {"city_id":"12882","city_name":"Villamaria","state_id":"782"},
    {"city_id":"12883","city_name":"Viterbo","state_id":"782"},
    {"city_id":"12884","city_name":"Albania","state_id":"783"},
    {"city_id":"12885","city_name":"Belen Andaquies","state_id":"783"},
    {"city_id":"12886","city_name":"Cartagena del Chaira","state_id":"783"},
    {"city_id":"12887","city_name":"Curillo","state_id":"783"},
    {"city_id":"12888","city_name":"El Doncello","state_id":"783"},
    {"city_id":"12889","city_name":"El Paujil","state_id":"783"},
    {"city_id":"12890","city_name":"Florencia","state_id":"783"},
    {"city_id":"12891","city_name":"La Montanita","state_id":"783"},
    {"city_id":"12892","city_name":"Milan","state_id":"783"},
    {"city_id":"12893","city_name":"Morelia","state_id":"783"},
    {"city_id":"12894","city_name":"Puerto Rico","state_id":"783"},
    {"city_id":"12895","city_name":"San Jose de Fragua","state_id":"783"},
    {"city_id":"12896","city_name":"San Vicente del Caguan","state_id":"783"},
    {"city_id":"12897","city_name":"Solano","state_id":"783"},
    {"city_id":"12898","city_name":"Solita","state_id":"783"},
    {"city_id":"12899","city_name":"Valparaiso","state_id":"783"},
    {"city_id":"12900","city_name":"Aguazul","state_id":"784"},
    {"city_id":"12901","city_name":"Chameza","state_id":"784"},
    {"city_id":"12902","city_name":"Hato Corozal","state_id":"784"},
    {"city_id":"12903","city_name":"La Salina","state_id":"784"},
    {"city_id":"12904","city_name":"Mani","state_id":"784"},
    {"city_id":"12905","city_name":"Monterrey","state_id":"784"},
    {"city_id":"12906","city_name":"Nunchia","state_id":"784"},
    {"city_id":"12907","city_name":"Orocue","state_id":"784"},
    {"city_id":"12908","city_name":"Paz de Ariporo","state_id":"784"},
    {"city_id":"12909","city_name":"Pore","state_id":"784"},
    {"city_id":"12910","city_name":"Recetor","state_id":"784"},
    {"city_id":"12911","city_name":"Sabanalarga","state_id":"784"},
    {"city_id":"12912","city_name":"Sacama","state_id":"784"},
    {"city_id":"12913","city_name":"San Luis de Palenque","state_id":"784"},
    {"city_id":"12914","city_name":"Tamara","state_id":"784"},
    {"city_id":"12915","city_name":"Tauramena","state_id":"784"},
    {"city_id":"12916","city_name":"Trinidad","state_id":"784"},
    {"city_id":"12917","city_name":"Villanueva","state_id":"784"},
    {"city_id":"12918","city_name":"Yopal","state_id":"784"},
    {"city_id":"12919","city_name":"Almaguer","state_id":"785"},
    {"city_id":"12920","city_name":"Argelia","state_id":"785"},
    {"city_id":"12921","city_name":"Balboa","state_id":"785"},
    {"city_id":"12922","city_name":"Bolivar","state_id":"785"},
    {"city_id":"12923","city_name":"Buenos Aires","state_id":"785"},
    {"city_id":"12924","city_name":"Cajibio","state_id":"785"},
    {"city_id":"12925","city_name":"Caldono","state_id":"785"},
    {"city_id":"12926","city_name":"Caloto","state_id":"785"},
    {"city_id":"12927","city_name":"Corinto","state_id":"785"},
    {"city_id":"12928","city_name":"El Bordo","state_id":"785"},
    {"city_id":"12929","city_name":"El Tambo","state_id":"785"},
    {"city_id":"12930","city_name":"Florencia","state_id":"785"},
    {"city_id":"12931","city_name":"Guapi","state_id":"785"},
    {"city_id":"12932","city_name":"Inza","state_id":"785"},
    {"city_id":"12933","city_name":"Jambalo","state_id":"785"},
    {"city_id":"12934","city_name":"La Sierra","state_id":"785"},
    {"city_id":"12935","city_name":"La Vega","state_id":"785"},
    {"city_id":"12936","city_name":"Lopez","state_id":"785"},
    {"city_id":"12937","city_name":"Mercaderes","state_id":"785"},
    {"city_id":"12938","city_name":"Miranda","state_id":"785"},
    {"city_id":"12939","city_name":"Morales","state_id":"785"},
    {"city_id":"12940","city_name":"Padilla","state_id":"785"},
    {"city_id":"12941","city_name":"Paez","state_id":"785"},
    {"city_id":"12942","city_name":"Piamonte","state_id":"785"},
    {"city_id":"12943","city_name":"Piendamo","state_id":"785"},
    {"city_id":"12944","city_name":"Popayan","state_id":"785"},
    {"city_id":"12945","city_name":"Puerto Tejada","state_id":"785"},
    {"city_id":"12946","city_name":"Purace","state_id":"785"},
    {"city_id":"12947","city_name":"Rosas","state_id":"785"},
    {"city_id":"12948","city_name":"San Sebastian","state_id":"785"},
    {"city_id":"12949","city_name":"Santa Rosa","state_id":"785"},
    {"city_id":"12950","city_name":"Santander de Quilichao","state_id":"785"},
    {"city_id":"12951","city_name":"Silvia","state_id":"785"},
    {"city_id":"12952","city_name":"Sotara","state_id":"785"},
    {"city_id":"12953","city_name":"Suarez","state_id":"785"},
    {"city_id":"12954","city_name":"Sucre","state_id":"785"},
    {"city_id":"12955","city_name":"Timbio","state_id":"785"},
    {"city_id":"12956","city_name":"Timbiqui","state_id":"785"},
    {"city_id":"12957","city_name":"Toribio","state_id":"785"},
    {"city_id":"12958","city_name":"Totoro","state_id":"785"},
    {"city_id":"12959","city_name":"Villa Rica","state_id":"785"},
    {"city_id":"12960","city_name":"Aguachica","state_id":"786"},
    {"city_id":"12961","city_name":"Agustin Codazzi","state_id":"786"},
    {"city_id":"12962","city_name":"Astrea","state_id":"786"},
    {"city_id":"12963","city_name":"Becerril","state_id":"786"},
    {"city_id":"12964","city_name":"Bosconia","state_id":"786"},
    {"city_id":"12965","city_name":"Chimichagua","state_id":"786"},
    {"city_id":"12966","city_name":"Chiriguana","state_id":"786"},
    {"city_id":"12967","city_name":"Curumani","state_id":"786"},
    {"city_id":"12968","city_name":"El Copey","state_id":"786"},
    {"city_id":"12969","city_name":"El Paso","state_id":"786"},
    {"city_id":"12970","city_name":"Gamarra","state_id":"786"},
    {"city_id":"12971","city_name":"Gonzalez","state_id":"786"},
    {"city_id":"12972","city_name":"La Gloria","state_id":"786"},
    {"city_id":"12973","city_name":"La Jagua Ibirico","state_id":"786"},
    {"city_id":"12974","city_name":"Manaure","state_id":"786"},
    {"city_id":"12975","city_name":"Pailitas","state_id":"786"},
    {"city_id":"12976","city_name":"Pelaya","state_id":"786"},
    {"city_id":"12977","city_name":"Pueblo Bello","state_id":"786"},
    {"city_id":"12978","city_name":"Rio de Oro","state_id":"786"},
    {"city_id":"12979","city_name":"Robles la Paz","state_id":"786"},
    {"city_id":"12980","city_name":"San Alberto","state_id":"786"},
    {"city_id":"12981","city_name":"San Diego","state_id":"786"},
    {"city_id":"12982","city_name":"San Martin","state_id":"786"},
    {"city_id":"12983","city_name":"Tamalameque","state_id":"786"},
    {"city_id":"12984","city_name":"Valledupar","state_id":"786"},
    {"city_id":"12985","city_name":"Acandi","state_id":"787"},
    {"city_id":"12986","city_name":"Alto Baudo","state_id":"787"},
    {"city_id":"12987","city_name":"Atrato","state_id":"787"},
    {"city_id":"12988","city_name":"Bagado","state_id":"787"},
    {"city_id":"12989","city_name":"Bahia Solano","state_id":"787"},
    {"city_id":"12990","city_name":"Bajo Baudo","state_id":"787"},
    {"city_id":"12991","city_name":"Bojaya","state_id":"787"},
    {"city_id":"12992","city_name":"Canton de San Pablo","state_id":"787"},
    {"city_id":"12993","city_name":"Carmen del Darien","state_id":"787"},
    {"city_id":"12994","city_name":"Certegui","state_id":"787"},
    {"city_id":"12995","city_name":"Condoto","state_id":"787"},
    {"city_id":"12996","city_name":"El Carmen","state_id":"787"},
    {"city_id":"12997","city_name":"Istmina","state_id":"787"},
    {"city_id":"12998","city_name":"Jurado","state_id":"787"},
    {"city_id":"12999","city_name":"Litoral del San Juan","state_id":"787"},
    {"city_id":"13000","city_name":"Lloro","state_id":"787"},
    {"city_id":"13001","city_name":"Medio Atrato","state_id":"787"},
    {"city_id":"13002","city_name":"Medio Baudo","state_id":"787"},
    {"city_id":"13003","city_name":"Medio San Juan","state_id":"787"},
    {"city_id":"13004","city_name":"Novita","state_id":"787"},
    {"city_id":"13005","city_name":"Nuqui","state_id":"787"},
    {"city_id":"13006","city_name":"Quibdo","state_id":"787"},
    {"city_id":"13007","city_name":"Rio Iro","state_id":"787"},
    {"city_id":"13008","city_name":"Rio Quito","state_id":"787"},
    {"city_id":"13009","city_name":"Riosucio","state_id":"787"},
    {"city_id":"13010","city_name":"San Jose del Palmar","state_id":"787"},
    {"city_id":"13011","city_name":"Sipi","state_id":"787"},
    {"city_id":"13012","city_name":"Tado","state_id":"787"},
    {"city_id":"13013","city_name":"Unguia","state_id":"787"},
    {"city_id":"13014","city_name":"Union Panamericana","state_id":"787"},
    {"city_id":"13015","city_name":"Ayapel","state_id":"788"},
    {"city_id":"13016","city_name":"Buenavista","state_id":"788"},
    {"city_id":"13017","city_name":"Canalete","state_id":"788"},
    {"city_id":"13018","city_name":"Cerete","state_id":"788"},
    {"city_id":"13019","city_name":"Chima","state_id":"788"},
    {"city_id":"13020","city_name":"Chinu","state_id":"788"},
    {"city_id":"13021","city_name":"Cienaga de Oro","state_id":"788"},
    {"city_id":"13022","city_name":"Cotorra","state_id":"788"},
    {"city_id":"13023","city_name":"La Apartada","state_id":"788"},
    {"city_id":"13024","city_name":"Lorica","state_id":"788"},
    {"city_id":"13025","city_name":"Los Cordobas","state_id":"788"},
    {"city_id":"13026","city_name":"Momil","state_id":"788"},
    {"city_id":"13027","city_name":"Monitos","state_id":"788"},
    {"city_id":"13028","city_name":"Montelibano","state_id":"788"},
    {"city_id":"13029","city_name":"Monteria","state_id":"788"},
    {"city_id":"13030","city_name":"Planeta Rica","state_id":"788"},
    {"city_id":"13031","city_name":"Pueblo Nuevo","state_id":"788"},
    {"city_id":"13032","city_name":"Puerto Escondido","state_id":"788"},
    {"city_id":"13033","city_name":"Puerto Libertador","state_id":"788"},
    {"city_id":"13034","city_name":"Purisima","state_id":"788"},
    {"city_id":"13035","city_name":"Sahagun","state_id":"788"},
    {"city_id":"13036","city_name":"San Andres Sotavento","state_id":"788"},
    {"city_id":"13037","city_name":"San Antero","state_id":"788"},
    {"city_id":"13038","city_name":"San Bernardo Viento","state_id":"788"},
    {"city_id":"13039","city_name":"San Carlos","state_id":"788"},
    {"city_id":"13040","city_name":"San Pelayo","state_id":"788"},
    {"city_id":"13041","city_name":"Tierralta","state_id":"788"},
    {"city_id":"13042","city_name":"Valencia","state_id":"788"},
    {"city_id":"13043","city_name":"Agua de Dios","state_id":"789"},
    {"city_id":"13044","city_name":"Alban","state_id":"789"},
    {"city_id":"13045","city_name":"Anapoima","state_id":"789"},
    {"city_id":"13046","city_name":"Anolaima","state_id":"789"},
    {"city_id":"13047","city_name":"Arbelaez","state_id":"789"},
    {"city_id":"13048","city_name":"Beltran","state_id":"789"},
    {"city_id":"13049","city_name":"Bituima","state_id":"789"},
    {"city_id":"13050","city_name":"Bojaca","state_id":"789"},
    {"city_id":"13051","city_name":"Cabrera","state_id":"789"},
    {"city_id":"13052","city_name":"Cachipay","state_id":"789"},
    {"city_id":"13053","city_name":"Cajica","state_id":"789"},
    {"city_id":"13054","city_name":"Caparrapi","state_id":"789"},
    {"city_id":"13055","city_name":"Caqueza","state_id":"789"},
    {"city_id":"13056","city_name":"Carmen de Carupa","state_id":"789"},
    {"city_id":"13057","city_name":"Chaguani","state_id":"789"},
    {"city_id":"13058","city_name":"Chia","state_id":"789"},
    {"city_id":"13059","city_name":"Chipaque","state_id":"789"},
    {"city_id":"13060","city_name":"Choachi","state_id":"789"},
    {"city_id":"13061","city_name":"Choconta","state_id":"789"},
    {"city_id":"13062","city_name":"Cogua","state_id":"789"},
    {"city_id":"13063","city_name":"Cota","state_id":"789"},
    {"city_id":"13064","city_name":"Cucunuba","state_id":"789"},
    {"city_id":"13065","city_name":"El Colegio","state_id":"789"},
    {"city_id":"13066","city_name":"El Penon","state_id":"789"},
    {"city_id":"13067","city_name":"El Rosal","state_id":"789"},
    {"city_id":"13068","city_name":"Facatativa","state_id":"789"},
    {"city_id":"13069","city_name":"Fomeque","state_id":"789"},
    {"city_id":"13070","city_name":"Fosca","state_id":"789"},
    {"city_id":"13071","city_name":"Funza","state_id":"789"},
    {"city_id":"13072","city_name":"Fuquene","state_id":"789"},
    {"city_id":"13073","city_name":"Fusagasuga","state_id":"789"},
    {"city_id":"13074","city_name":"Gachala","state_id":"789"},
    {"city_id":"13075","city_name":"Gachancipa","state_id":"789"},
    {"city_id":"13076","city_name":"Gacheta","state_id":"789"},
    {"city_id":"13077","city_name":"Gama","state_id":"789"},
    {"city_id":"13078","city_name":"Girardot","state_id":"789"},
    {"city_id":"13079","city_name":"Granada","state_id":"789"},
    {"city_id":"13080","city_name":"Guacheta","state_id":"789"},
    {"city_id":"13081","city_name":"Guaduas","state_id":"789"},
    {"city_id":"13082","city_name":"Guasca","state_id":"789"},
    {"city_id":"13083","city_name":"Guataqui","state_id":"789"},
    {"city_id":"13084","city_name":"Guatavita","state_id":"789"},
    {"city_id":"13085","city_name":"Guayabal de Siquima","state_id":"789"},
    {"city_id":"13086","city_name":"Guayabetal","state_id":"789"},
    {"city_id":"13087","city_name":"Gutierrez","state_id":"789"},
    {"city_id":"13088","city_name":"Jerusalen","state_id":"789"},
    {"city_id":"13089","city_name":"Junin","state_id":"789"},
    {"city_id":"13090","city_name":"La Calera","state_id":"789"},
    {"city_id":"13091","city_name":"La Mesa","state_id":"789"},
    {"city_id":"13092","city_name":"La Palma","state_id":"789"},
    {"city_id":"13093","city_name":"La Pena","state_id":"789"},
    {"city_id":"13094","city_name":"La Vega","state_id":"789"},
    {"city_id":"13095","city_name":"Lenguazaque","state_id":"789"},
    {"city_id":"13096","city_name":"Macheta","state_id":"789"},
    {"city_id":"13097","city_name":"Madrid","state_id":"789"},
    {"city_id":"13098","city_name":"Manta","state_id":"789"},
    {"city_id":"13099","city_name":"Medina","state_id":"789"},
    {"city_id":"13100","city_name":"Mosquera","state_id":"789"},
    {"city_id":"13101","city_name":"Narino","state_id":"789"},
    {"city_id":"13102","city_name":"Nemocon","state_id":"789"},
    {"city_id":"13103","city_name":"Nilo","state_id":"789"},
    {"city_id":"13104","city_name":"Nimaima","state_id":"789"},
    {"city_id":"13105","city_name":"Nocaima","state_id":"789"},
    {"city_id":"13106","city_name":"Ospina Perez","state_id":"789"},
    {"city_id":"13107","city_name":"Pacho","state_id":"789"},
    {"city_id":"13108","city_name":"Paime","state_id":"789"},
    {"city_id":"13109","city_name":"Pandi","state_id":"789"},
    {"city_id":"13110","city_name":"Paratebueno","state_id":"789"},
    {"city_id":"13111","city_name":"Pasca","state_id":"789"},
    {"city_id":"13112","city_name":"Puerto Salgar","state_id":"789"},
    {"city_id":"13113","city_name":"Puli","state_id":"789"},
    {"city_id":"13114","city_name":"Quebradanegra","state_id":"789"},
    {"city_id":"13115","city_name":"Quetame","state_id":"789"},
    {"city_id":"13116","city_name":"Quipile","state_id":"789"},
    {"city_id":"13117","city_name":"Rafael Reyes","state_id":"789"},
    {"city_id":"13118","city_name":"Ricaurte","state_id":"789"},
    {"city_id":"13119","city_name":"San Antonio del Tequendama","state_id":"789"},
    {"city_id":"13120","city_name":"San Bernardo","state_id":"789"},
    {"city_id":"13121","city_name":"San Cayetano","state_id":"789"},
    {"city_id":"13122","city_name":"San Francisco","state_id":"789"},
    {"city_id":"13123","city_name":"San Juan de Rioseco","state_id":"789"},
    {"city_id":"13124","city_name":"Sasaima","state_id":"789"},
    {"city_id":"13125","city_name":"Sesquile","state_id":"789"},
    {"city_id":"13126","city_name":"Sibate","state_id":"789"},
    {"city_id":"13127","city_name":"Silvania","state_id":"789"},
    {"city_id":"13128","city_name":"Simijaca","state_id":"789"},
    {"city_id":"13129","city_name":"Soacha","state_id":"789"},
    {"city_id":"13130","city_name":"Sopo","state_id":"789"},
    {"city_id":"13131","city_name":"Subachoque","state_id":"789"},
    {"city_id":"13132","city_name":"Suesca","state_id":"789"},
    {"city_id":"13133","city_name":"Supata","state_id":"789"},
    {"city_id":"13134","city_name":"Susa","state_id":"789"},
    {"city_id":"13135","city_name":"Sutatausa","state_id":"789"},
    {"city_id":"13136","city_name":"Tabio","state_id":"789"},
    {"city_id":"13137","city_name":"Tausa","state_id":"789"},
    {"city_id":"13138","city_name":"Tena","state_id":"789"},
    {"city_id":"13139","city_name":"Tenjo","state_id":"789"},
    {"city_id":"13140","city_name":"Tibacuy","state_id":"789"},
    {"city_id":"13141","city_name":"Tibirita","state_id":"789"},
    {"city_id":"13142","city_name":"Tocaima","state_id":"789"},
    {"city_id":"13143","city_name":"Tocancipa","state_id":"789"},
    {"city_id":"13144","city_name":"Topaipi","state_id":"789"},
    {"city_id":"13145","city_name":"Ubala","state_id":"789"},
    {"city_id":"13146","city_name":"Ubaque","state_id":"789"},
    {"city_id":"13147","city_name":"Ubate","state_id":"789"},
    {"city_id":"13148","city_name":"Une","state_id":"789"},
    {"city_id":"13149","city_name":"Utica","state_id":"789"},
    {"city_id":"13150","city_name":"Vergara","state_id":"789"},
    {"city_id":"13151","city_name":"Viani","state_id":"789"},
    {"city_id":"13152","city_name":"Villagomez","state_id":"789"},
    {"city_id":"13153","city_name":"Villapinzon","state_id":"789"},
    {"city_id":"13154","city_name":"Villeta","state_id":"789"},
    {"city_id":"13155","city_name":"Viota","state_id":"789"},
    {"city_id":"13156","city_name":"Yacopi","state_id":"789"},
    {"city_id":"13157","city_name":"Zipacon","state_id":"789"},
    {"city_id":"13158","city_name":"Zipaquira","state_id":"789"},
    {"city_id":"13159","city_name":"Inirida","state_id":"790"},
    {"city_id":"13160","city_name":"Calamar","state_id":"791"},
    {"city_id":"13161","city_name":"El Retorno","state_id":"791"},
    {"city_id":"13162","city_name":"Miraflores","state_id":"791"},
    {"city_id":"13163","city_name":"San Jose del Guaviare","state_id":"791"},
    {"city_id":"13164","city_name":"Acevedo","state_id":"792"},
    {"city_id":"13165","city_name":"Agrado","state_id":"792"},
    {"city_id":"13166","city_name":"Aipe","state_id":"792"},
    {"city_id":"13167","city_name":"Algeciras","state_id":"792"},
    {"city_id":"13168","city_name":"Altamira","state_id":"792"},
    {"city_id":"13169","city_name":"Baraya","state_id":"792"},
    {"city_id":"13170","city_name":"Campoalegre","state_id":"792"},
    {"city_id":"13171","city_name":"Colombia","state_id":"792"},
    {"city_id":"13172","city_name":"Elias","state_id":"792"},
    {"city_id":"13173","city_name":"Garzon","state_id":"792"},
    {"city_id":"13174","city_name":"Gigante","state_id":"792"},
    {"city_id":"13175","city_name":"Guadalupe","state_id":"792"},
    {"city_id":"13176","city_name":"Hobo","state_id":"792"},
    {"city_id":"13177","city_name":"Iquira","state_id":"792"},
    {"city_id":"13178","city_name":"Isnos","state_id":"792"},
    {"city_id":"13179","city_name":"La Argentina","state_id":"792"},
    {"city_id":"13180","city_name":"La Plata","state_id":"792"},
    {"city_id":"13181","city_name":"Nataga","state_id":"792"},
    {"city_id":"13182","city_name":"Neiva","state_id":"792"},
    {"city_id":"13183","city_name":"Oporapa","state_id":"792"},
    {"city_id":"13184","city_name":"Paicol","state_id":"792"},
    {"city_id":"13185","city_name":"Palermo","state_id":"792"},
    {"city_id":"13186","city_name":"Palestina","state_id":"792"},
    {"city_id":"13187","city_name":"Pital","state_id":"792"},
    {"city_id":"13188","city_name":"Pitalito","state_id":"792"},
    {"city_id":"13189","city_name":"Rivera","state_id":"792"},
    {"city_id":"13190","city_name":"Saladoblanco","state_id":"792"},
    {"city_id":"13191","city_name":"San Agustin","state_id":"792"},
    {"city_id":"13192","city_name":"Santa Maria","state_id":"792"},
    {"city_id":"13193","city_name":"Suaza","state_id":"792"},
    {"city_id":"13194","city_name":"Tarqui","state_id":"792"},
    {"city_id":"13195","city_name":"Tello","state_id":"792"},
    {"city_id":"13196","city_name":"Teruel","state_id":"792"},
    {"city_id":"13197","city_name":"Tesalia","state_id":"792"},
    {"city_id":"13198","city_name":"Timana","state_id":"792"},
    {"city_id":"13199","city_name":"Villavieja","state_id":"792"},
    {"city_id":"13200","city_name":"Yaguara","state_id":"792"},
    {"city_id":"13201","city_name":"Algarrobo","state_id":"794"},
    {"city_id":"13202","city_name":"Aracataca","state_id":"794"},
    {"city_id":"13203","city_name":"Ariguani","state_id":"794"},
    {"city_id":"13204","city_name":"Cerro San Antonio","state_id":"794"},
    {"city_id":"13205","city_name":"Chivolo","state_id":"794"},
    {"city_id":"13206","city_name":"Cienaga","state_id":"794"},
    {"city_id":"13207","city_name":"Concordia","state_id":"794"},
    {"city_id":"13208","city_name":"El Banco","state_id":"794"},
    {"city_id":"13209","city_name":"El Pinon","state_id":"794"},
    {"city_id":"13210","city_name":"El Reten","state_id":"794"},
    {"city_id":"13211","city_name":"Fundacion","state_id":"794"},
    {"city_id":"13212","city_name":"Guamal","state_id":"794"},
    {"city_id":"13213","city_name":"Nueva Granada","state_id":"794"},
    {"city_id":"13214","city_name":"Pedraza","state_id":"794"},
    {"city_id":"13215","city_name":"Pijino del Carmen","state_id":"794"},
    {"city_id":"13216","city_name":"Pivijay","state_id":"794"},
    {"city_id":"13217","city_name":"Plato","state_id":"794"},
    {"city_id":"13218","city_name":"Puebloviejo","state_id":"794"},
    {"city_id":"13219","city_name":"Remolino","state_id":"794"},
    {"city_id":"13220","city_name":"Sabanas de San Angel","state_id":"794"},
    {"city_id":"13221","city_name":"Salamina","state_id":"794"},
    {"city_id":"13222","city_name":"San Sebastian","state_id":"794"},
    {"city_id":"13223","city_name":"San Zenon","state_id":"794"},
    {"city_id":"13224","city_name":"Santa Ana","state_id":"794"},
    {"city_id":"13225","city_name":"Santa Barbara de Pinto","state_id":"794"},
    {"city_id":"13226","city_name":"Santa Marta","state_id":"794"},
    {"city_id":"13227","city_name":"Sitionuevo","state_id":"794"},
    {"city_id":"13228","city_name":"Tenerife","state_id":"794"},
    {"city_id":"13229","city_name":"Zapayan","state_id":"794"},
    {"city_id":"13230","city_name":"Zona Bananera","state_id":"794"},
    {"city_id":"13231","city_name":"Acacias","state_id":"795"},
    {"city_id":"13232","city_name":"Barranca de Upia","state_id":"795"},
    {"city_id":"13233","city_name":"Cabuyaro","state_id":"795"},
    {"city_id":"13234","city_name":"Castilla la Nueva","state_id":"795"},
    {"city_id":"13235","city_name":"Cubarral","state_id":"795"},
    {"city_id":"13236","city_name":"Cumaral","state_id":"795"},
    {"city_id":"13237","city_name":"El Calvario","state_id":"795"},
    {"city_id":"13238","city_name":"El Castillo","state_id":"795"},
    {"city_id":"13239","city_name":"El Dorado","state_id":"795"},
    {"city_id":"13240","city_name":"Fuente de Oro","state_id":"795"},
    {"city_id":"13241","city_name":"Granada","state_id":"795"},
    {"city_id":"13242","city_name":"Guamal","state_id":"795"},
    {"city_id":"13243","city_name":"La Macarena","state_id":"795"},
    {"city_id":"13244","city_name":"La Uribe","state_id":"795"},
    {"city_id":"13245","city_name":"Lejanias","state_id":"795"},
    {"city_id":"13246","city_name":"Mapiripan","state_id":"795"},
    {"city_id":"13247","city_name":"Mesetas","state_id":"795"},
    {"city_id":"13248","city_name":"Puerto Concordia","state_id":"795"},
    {"city_id":"13249","city_name":"Puerto Gaitan","state_id":"795"},
    {"city_id":"13250","city_name":"Puerto Lleras","state_id":"795"},
    {"city_id":"13251","city_name":"Puerto Lopez","state_id":"795"},
    {"city_id":"13252","city_name":"Puerto Rico","state_id":"795"},
    {"city_id":"13253","city_name":"Restrepo","state_id":"795"},
    {"city_id":"13254","city_name":"San Carlos Guaroa","state_id":"795"},
    {"city_id":"13255","city_name":"San Juan de Arama","state_id":"795"},
    {"city_id":"13256","city_name":"San Juanito","state_id":"795"},
    {"city_id":"13257","city_name":"San Martin","state_id":"795"},
    {"city_id":"13258","city_name":"Villavicencio","state_id":"795"},
    {"city_id":"13259","city_name":"Vista Hermosa","state_id":"795"},
    {"city_id":"13260","city_name":"Alban","state_id":"796"},
    {"city_id":"13261","city_name":"Aldana","state_id":"796"},
    {"city_id":"13262","city_name":"Ancuya","state_id":"796"},
    {"city_id":"13263","city_name":"Arboleda","state_id":"796"},
    {"city_id":"13264","city_name":"Barbacoas","state_id":"796"},
    {"city_id":"13265","city_name":"Belen","state_id":"796"},
    {"city_id":"13266","city_name":"Buesaco","state_id":"796"},
    {"city_id":"13267","city_name":"Chachagui","state_id":"796"},
    {"city_id":"13268","city_name":"Colon","state_id":"796"},
    {"city_id":"13269","city_name":"Consaca","state_id":"796"},
    {"city_id":"13270","city_name":"Contadero","state_id":"796"},
    {"city_id":"13271","city_name":"Cordoba","state_id":"796"},
    {"city_id":"13272","city_name":"Cuaspud","state_id":"796"},
    {"city_id":"13273","city_name":"Cumbal","state_id":"796"},
    {"city_id":"13274","city_name":"Cumbitara","state_id":"796"},
    {"city_id":"13275","city_name":"El Charco","state_id":"796"},
    {"city_id":"13276","city_name":"El Penol","state_id":"796"},
    {"city_id":"13277","city_name":"El Rosario","state_id":"796"},
    {"city_id":"13278","city_name":"El Tablon","state_id":"796"},
    {"city_id":"13279","city_name":"El Tambo","state_id":"796"},
    {"city_id":"13280","city_name":"Funes","state_id":"796"},
    {"city_id":"13281","city_name":"Guachucal","state_id":"796"},
    {"city_id":"13282","city_name":"Guaitarilla","state_id":"796"},
    {"city_id":"13283","city_name":"Gualmatan","state_id":"796"},
    {"city_id":"13284","city_name":"Iles","state_id":"796"},
    {"city_id":"13285","city_name":"Imues","state_id":"796"},
    {"city_id":"13286","city_name":"Ipiales","state_id":"796"},
    {"city_id":"13287","city_name":"La Cruz","state_id":"796"},
    {"city_id":"13288","city_name":"La Florida","state_id":"796"},
    {"city_id":"13289","city_name":"La Llanada","state_id":"796"},
    {"city_id":"13290","city_name":"La Tola","state_id":"796"},
    {"city_id":"13291","city_name":"La Union","state_id":"796"},
    {"city_id":"13292","city_name":"Leiva","state_id":"796"},
    {"city_id":"13293","city_name":"Linares","state_id":"796"},
    {"city_id":"13294","city_name":"Los Andes","state_id":"796"},
    {"city_id":"13295","city_name":"Magui","state_id":"796"},
    {"city_id":"13296","city_name":"Mallama","state_id":"796"},
    {"city_id":"13297","city_name":"Mosquera","state_id":"796"},
    {"city_id":"13298","city_name":"Narino","state_id":"796"},
    {"city_id":"13299","city_name":"Olaya Herrera","state_id":"796"},
    {"city_id":"13300","city_name":"Ospina","state_id":"796"},
    {"city_id":"13301","city_name":"Pasto","state_id":"796"},
    {"city_id":"13302","city_name":"Pizarro","state_id":"796"},
    {"city_id":"13303","city_name":"Policarpa","state_id":"796"},
    {"city_id":"13304","city_name":"Potosi","state_id":"796"},
    {"city_id":"13305","city_name":"Providencia","state_id":"796"},
    {"city_id":"13306","city_name":"Puerres","state_id":"796"},
    {"city_id":"13307","city_name":"Pupiales","state_id":"796"},
    {"city_id":"13308","city_name":"Ricaurte","state_id":"796"},
    {"city_id":"13309","city_name":"Roberto Payan","state_id":"796"},
    {"city_id":"13310","city_name":"Samaniego","state_id":"796"},
    {"city_id":"13311","city_name":"San Bernardo","state_id":"796"},
    {"city_id":"13312","city_name":"San Lorenzo","state_id":"796"},
    {"city_id":"13313","city_name":"San Pablo","state_id":"796"},
    {"city_id":"13314","city_name":"San Pedro de Cartago","state_id":"796"},
    {"city_id":"13315","city_name":"Sandona","state_id":"796"},
    {"city_id":"13316","city_name":"Santa Barbara","state_id":"796"},
    {"city_id":"13317","city_name":"Santacruz","state_id":"796"},
    {"city_id":"13318","city_name":"Sapuyes","state_id":"796"},
    {"city_id":"13319","city_name":"Taminango","state_id":"796"},
    {"city_id":"13320","city_name":"Tangua","state_id":"796"},
    {"city_id":"13321","city_name":"Tumaco","state_id":"796"},
    {"city_id":"13322","city_name":"Tuquerres","state_id":"796"},
    {"city_id":"13323","city_name":"Yacuanquer","state_id":"796"},
    {"city_id":"13324","city_name":"Colon","state_id":"798"},
    {"city_id":"13325","city_name":"Mocoa","state_id":"798"},
    {"city_id":"13326","city_name":"Orito","state_id":"798"},
    {"city_id":"13327","city_name":"Puerto Asis","state_id":"798"},
    {"city_id":"13328","city_name":"Puerto Caycedo","state_id":"798"},
    {"city_id":"13329","city_name":"Puerto Guzman","state_id":"798"},
    {"city_id":"13330","city_name":"Puerto Leguizamo","state_id":"798"},
    {"city_id":"13331","city_name":"San Francisco","state_id":"798"},
    {"city_id":"13332","city_name":"San Miguel","state_id":"798"},
    {"city_id":"13333","city_name":"Santiago","state_id":"798"},
    {"city_id":"13334","city_name":"Sibundoy","state_id":"798"},
    {"city_id":"13335","city_name":"Valle del Guamuez","state_id":"798"},
    {"city_id":"13336","city_name":"Villagarzon","state_id":"798"},
    {"city_id":"13337","city_name":"Armenia","state_id":"799"},
    {"city_id":"13338","city_name":"Buenavista","state_id":"799"},
    {"city_id":"13339","city_name":"Calarca","state_id":"799"},
    {"city_id":"13340","city_name":"Circasia","state_id":"799"},
    {"city_id":"13341","city_name":"Cordoba","state_id":"799"},
    {"city_id":"13342","city_name":"Filandia","state_id":"799"},
    {"city_id":"13343","city_name":"Genova","state_id":"799"},
    {"city_id":"13344","city_name":"La Tebaida","state_id":"799"},
    {"city_id":"13345","city_name":"Montenegro","state_id":"799"},
    {"city_id":"13346","city_name":"Pijao","state_id":"799"},
    {"city_id":"13347","city_name":"Quimbaya","state_id":"799"},
    {"city_id":"13348","city_name":"Salento","state_id":"799"},
    {"city_id":"13349","city_name":"Apia","state_id":"800"},
    {"city_id":"13350","city_name":"Balboa","state_id":"800"},
    {"city_id":"13351","city_name":"Belen de Umbria","state_id":"800"},
    {"city_id":"13352","city_name":"Dos Quebradas","state_id":"800"},
    {"city_id":"13353","city_name":"Guatica","state_id":"800"},
    {"city_id":"13354","city_name":"La Celia","state_id":"800"},
    {"city_id":"13355","city_name":"La Virginia","state_id":"800"},
    {"city_id":"13356","city_name":"Marsella","state_id":"800"},
    {"city_id":"13357","city_name":"Mistrato","state_id":"800"},
    {"city_id":"13358","city_name":"Pereira","state_id":"800"},
    {"city_id":"13359","city_name":"Pueblo Rico","state_id":"800"},
    {"city_id":"13360","city_name":"Quinchia","state_id":"800"},
    {"city_id":"13361","city_name":"Santa Rosa de Cabal","state_id":"800"},
    {"city_id":"13362","city_name":"Santuario","state_id":"800"},
    {"city_id":"13363","city_name":"Aguada","state_id":"802"},
    {"city_id":"13364","city_name":"Albania","state_id":"802"},
    {"city_id":"13365","city_name":"Aratoca","state_id":"802"},
    {"city_id":"13366","city_name":"Barbosa","state_id":"802"},
    {"city_id":"13367","city_name":"Barichara","state_id":"802"},
    {"city_id":"13368","city_name":"Barrancabermeja","state_id":"802"},
    {"city_id":"13369","city_name":"Betulia","state_id":"802"},
    {"city_id":"13370","city_name":"Bolivar","state_id":"802"},
    {"city_id":"13371","city_name":"Bucaramanga","state_id":"802"},
    {"city_id":"13372","city_name":"Cabrera","state_id":"802"},
    {"city_id":"13373","city_name":"California","state_id":"802"},
    {"city_id":"13374","city_name":"Capitanejo","state_id":"802"},
    {"city_id":"13375","city_name":"Carcasi","state_id":"802"},
    {"city_id":"13376","city_name":"Cepita","state_id":"802"},
    {"city_id":"13377","city_name":"Cerrito","state_id":"802"},
    {"city_id":"13378","city_name":"Charala","state_id":"802"},
    {"city_id":"13379","city_name":"Charta","state_id":"802"},
    {"city_id":"13380","city_name":"Chima","state_id":"802"},
    {"city_id":"13381","city_name":"Chipata","state_id":"802"},
    {"city_id":"13382","city_name":"Cimitarra","state_id":"802"},
    {"city_id":"13383","city_name":"Concepcion","state_id":"802"},
    {"city_id":"13384","city_name":"Confines","state_id":"802"},
    {"city_id":"13385","city_name":"Contratacion","state_id":"802"},
    {"city_id":"13386","city_name":"Coromoro","state_id":"802"},
    {"city_id":"13387","city_name":"Curiti","state_id":"802"},
    {"city_id":"13388","city_name":"El Carmen","state_id":"802"},
    {"city_id":"13389","city_name":"El Guacamayo","state_id":"802"},
    {"city_id":"13390","city_name":"El Penon","state_id":"802"},
    {"city_id":"13391","city_name":"El Playon","state_id":"802"},
    {"city_id":"13392","city_name":"Encino","state_id":"802"},
    {"city_id":"13393","city_name":"Enciso","state_id":"802"},
    {"city_id":"13394","city_name":"Florian","state_id":"802"},
    {"city_id":"13395","city_name":"Floridablanca","state_id":"802"},
    {"city_id":"13396","city_name":"Galan","state_id":"802"},
    {"city_id":"13397","city_name":"Gambita","state_id":"802"},
    {"city_id":"13398","city_name":"Giron","state_id":"802"},
    {"city_id":"13399","city_name":"Guaca","state_id":"802"},
    {"city_id":"13400","city_name":"Guadalupe","state_id":"802"},
    {"city_id":"13401","city_name":"Guapota","state_id":"802"},
    {"city_id":"13402","city_name":"Guavata","state_id":"802"},
    {"city_id":"13403","city_name":"Guepsa","state_id":"802"},
    {"city_id":"13404","city_name":"Hato","state_id":"802"},
    {"city_id":"13405","city_name":"Jesus Maria","state_id":"802"},
    {"city_id":"13406","city_name":"Jordan","state_id":"802"},
    {"city_id":"13407","city_name":"La Belleza","state_id":"802"},
    {"city_id":"13408","city_name":"La Paz","state_id":"802"},
    {"city_id":"13409","city_name":"Landazuri","state_id":"802"},
    {"city_id":"13410","city_name":"Lebrija","state_id":"802"},
    {"city_id":"13411","city_name":"Los Santos","state_id":"802"},
    {"city_id":"13412","city_name":"Macaravita","state_id":"802"},
    {"city_id":"13413","city_name":"Malaga","state_id":"802"},
    {"city_id":"13414","city_name":"Matanza","state_id":"802"},
    {"city_id":"13415","city_name":"Mogotes","state_id":"802"},
    {"city_id":"13416","city_name":"Molagavita","state_id":"802"},
    {"city_id":"13417","city_name":"Ocamonte","state_id":"802"},
    {"city_id":"13418","city_name":"Oiba","state_id":"802"},
    {"city_id":"13419","city_name":"Onzaga","state_id":"802"},
    {"city_id":"13420","city_name":"Palmar","state_id":"802"},
    {"city_id":"13421","city_name":"Palmas del Socorro","state_id":"802"},
    {"city_id":"13422","city_name":"Paramo","state_id":"802"},
    {"city_id":"13423","city_name":"Piedecuesta","state_id":"802"},
    {"city_id":"13424","city_name":"Pinchote","state_id":"802"},
    {"city_id":"13425","city_name":"Puente Nacional","state_id":"802"},
    {"city_id":"13426","city_name":"Puerto Parra","state_id":"802"},
    {"city_id":"13427","city_name":"Puerto Wilches","state_id":"802"},
    {"city_id":"13428","city_name":"Rionegro","state_id":"802"},
    {"city_id":"13429","city_name":"Sabana de Torres","state_id":"802"},
    {"city_id":"13430","city_name":"San Andres","state_id":"802"},
    {"city_id":"13431","city_name":"San Benito","state_id":"802"},
    {"city_id":"13432","city_name":"San Gil","state_id":"802"},
    {"city_id":"13433","city_name":"San Joaquin","state_id":"802"},
    {"city_id":"13434","city_name":"San Jose de Miranda","state_id":"802"},
    {"city_id":"13435","city_name":"San Miguel","state_id":"802"},
    {"city_id":"13436","city_name":"San Vicente de Chucuri","state_id":"802"},
    {"city_id":"13437","city_name":"Santa Barbara","state_id":"802"},
    {"city_id":"13438","city_name":"Santa Helena","state_id":"802"},
    {"city_id":"13439","city_name":"Simacota","state_id":"802"},
    {"city_id":"13440","city_name":"Socorro","state_id":"802"},
    {"city_id":"13441","city_name":"Suaita","state_id":"802"},
    {"city_id":"13442","city_name":"Sucre","state_id":"802"},
    {"city_id":"13443","city_name":"Surata","state_id":"802"},
    {"city_id":"13444","city_name":"Tona","state_id":"802"},
    {"city_id":"13445","city_name":"Valle San Jose","state_id":"802"},
    {"city_id":"13446","city_name":"Velez","state_id":"802"},
    {"city_id":"13447","city_name":"Vetas","state_id":"802"},
    {"city_id":"13448","city_name":"Villanueva","state_id":"802"},
    {"city_id":"13449","city_name":"Zapatoca","state_id":"802"},
    {"city_id":"13450","city_name":"Buenavista","state_id":"803"},
    {"city_id":"13451","city_name":"Caimito","state_id":"803"},
    {"city_id":"13452","city_name":"Chalan","state_id":"803"},
    {"city_id":"13453","city_name":"Coloso","state_id":"803"},
    {"city_id":"13454","city_name":"Corozal","state_id":"803"},
    {"city_id":"13455","city_name":"El Roble","state_id":"803"},
    {"city_id":"13456","city_name":"Galeras","state_id":"803"},
    {"city_id":"13457","city_name":"Guaranda","state_id":"803"},
    {"city_id":"13458","city_name":"La Union","state_id":"803"},
    {"city_id":"13459","city_name":"Los Palmitos","state_id":"803"},
    {"city_id":"13460","city_name":"Majagual","state_id":"803"},
    {"city_id":"13461","city_name":"Morroa","state_id":"803"},
    {"city_id":"13462","city_name":"Ovejas","state_id":"803"},
    {"city_id":"13463","city_name":"Palmito","state_id":"803"},
    {"city_id":"13464","city_name":"Sampues","state_id":"803"},
    {"city_id":"13465","city_name":"San Benito Abad","state_id":"803"},
    {"city_id":"13466","city_name":"San Juan de Betulia","state_id":"803"},
    {"city_id":"13467","city_name":"San Marcos","state_id":"803"},
    {"city_id":"13468","city_name":"San Onofre","state_id":"803"},
    {"city_id":"13469","city_name":"San Pedro","state_id":"803"},
    {"city_id":"13470","city_name":"Since","state_id":"803"},
    {"city_id":"13471","city_name":"Sincelejo","state_id":"803"},
    {"city_id":"13472","city_name":"Sucre","state_id":"803"},
    {"city_id":"13473","city_name":"Tolu","state_id":"803"},
    {"city_id":"13474","city_name":"Toluviejo","state_id":"803"},
    {"city_id":"13475","city_name":"Alpujarra","state_id":"804"},
    {"city_id":"13476","city_name":"Alvarado","state_id":"804"},
    {"city_id":"13477","city_name":"Ambalema","state_id":"804"},
    {"city_id":"13478","city_name":"Anzoategui","state_id":"804"},
    {"city_id":"13479","city_name":"Ataco","state_id":"804"},
    {"city_id":"13480","city_name":"Cajamarca","state_id":"804"},
    {"city_id":"13481","city_name":"Carmen de Apicala","state_id":"804"},
    {"city_id":"13482","city_name":"Casabianca","state_id":"804"},
    {"city_id":"13483","city_name":"Chaparral","state_id":"804"},
    {"city_id":"13484","city_name":"Coello","state_id":"804"},
    {"city_id":"13485","city_name":"Coyaima","state_id":"804"},
    {"city_id":"13486","city_name":"Cunday","state_id":"804"},
    {"city_id":"13487","city_name":"Dolores","state_id":"804"},
    {"city_id":"13488","city_name":"Espinal","state_id":"804"},
    {"city_id":"13489","city_name":"Falan","state_id":"804"},
    {"city_id":"13490","city_name":"Flandes","state_id":"804"},
    {"city_id":"13491","city_name":"Fresno","state_id":"804"},
    {"city_id":"13492","city_name":"Guamo","state_id":"804"},
    {"city_id":"13493","city_name":"Guayabal","state_id":"804"},
    {"city_id":"13494","city_name":"Herveo","state_id":"804"},
    {"city_id":"13495","city_name":"Honda","state_id":"804"},
    {"city_id":"13496","city_name":"Ibague","state_id":"804"},
    {"city_id":"13497","city_name":"Icononzo","state_id":"804"},
    {"city_id":"13498","city_name":"Lerida","state_id":"804"},
    {"city_id":"13499","city_name":"Libano","state_id":"804"},
    {"city_id":"13500","city_name":"Mariquita","state_id":"804"},
    {"city_id":"13501","city_name":"Melgar","state_id":"804"},
    {"city_id":"13502","city_name":"Murillo","state_id":"804"},
    {"city_id":"13503","city_name":"Natagaima","state_id":"804"},
    {"city_id":"13504","city_name":"Ortega","state_id":"804"},
    {"city_id":"13505","city_name":"Palocabildo","state_id":"804"},
    {"city_id":"13506","city_name":"Piedras","state_id":"804"},
    {"city_id":"13507","city_name":"Planadas","state_id":"804"},
    {"city_id":"13508","city_name":"Prado","state_id":"804"},
    {"city_id":"13509","city_name":"Purificacion","state_id":"804"},
    {"city_id":"13510","city_name":"Rioblanco","state_id":"804"},
    {"city_id":"13511","city_name":"Roncesvalles","state_id":"804"},
    {"city_id":"13512","city_name":"Rovira","state_id":"804"},
    {"city_id":"13513","city_name":"Saldana","state_id":"804"},
    {"city_id":"13514","city_name":"San Antonio","state_id":"804"},
    {"city_id":"13515","city_name":"San Luis","state_id":"804"},
    {"city_id":"13516","city_name":"Santa Isabel","state_id":"804"},
    {"city_id":"13517","city_name":"Suarez","state_id":"804"},
    {"city_id":"13518","city_name":"Valle de San Juan","state_id":"804"},
    {"city_id":"13519","city_name":"Venadillo","state_id":"804"},
    {"city_id":"13520","city_name":"Villahermosa","state_id":"804"},
    {"city_id":"13521","city_name":"Villarrica","state_id":"804"},
    {"city_id":"13522","city_name":"Acaricuara","state_id":"806"},
    {"city_id":"13523","city_name":"Mitu","state_id":"806"},
    {"city_id":"13524","city_name":"Papunaua","state_id":"806"},
    {"city_id":"13525","city_name":"Taraira","state_id":"806"},
    {"city_id":"13526","city_name":"Villa Fatima","state_id":"806"},
    {"city_id":"13527","city_name":"Yavarate","state_id":"806"},
    {"city_id":"13528","city_name":"Cumaribo","state_id":"807"},
    {"city_id":"13529","city_name":"La Primavera","state_id":"807"},
    {"city_id":"13530","city_name":"Puerto Carreno","state_id":"807"},
    {"city_id":"13531","city_name":"Santa Rosalia","state_id":"807"},
    {"city_id":"13532","city_name":"Fomboni","state_id":"808"},
    {"city_id":"13533","city_name":"Mitsamiouli","state_id":"809"},
    {"city_id":"13534","city_name":"Moroni","state_id":"809"},
    {"city_id":"13535","city_name":"Domoni","state_id":"810"},
    {"city_id":"13536","city_name":"Mutsamudu","state_id":"810"},
    {"city_id":"13537","city_name":"Loudima","state_id":"811"},
    {"city_id":"13538","city_name":"Madingou","state_id":"811"},
    {"city_id":"13539","city_name":"Nkayi","state_id":"811"},
    {"city_id":"13540","city_name":"Brazzaville","state_id":"812"},
    {"city_id":"13541","city_name":"Ewo","state_id":"813"},
    {"city_id":"13542","city_name":"Kelle","state_id":"813"},
    {"city_id":"13543","city_name":"Makoua","state_id":"813"},
    {"city_id":"13544","city_name":"Mossaka","state_id":"813"},
    {"city_id":"13545","city_name":"Owando","state_id":"813"},
    {"city_id":"13546","city_name":"Loandjili","state_id":"814"},
    {"city_id":"13547","city_name":"Ngamaba-Mfilou","state_id":"814"},
    {"city_id":"13548","city_name":"Pointe Noire","state_id":"814"},
    {"city_id":"13549","city_name":"Sibiti","state_id":"815"},
    {"city_id":"13550","city_name":"Zanaga","state_id":"815"},
    {"city_id":"13551","city_name":"Dongou","state_id":"816"},
    {"city_id":"13552","city_name":"Epena","state_id":"816"},
    {"city_id":"13553","city_name":"Impfondo","state_id":"816"},
    {"city_id":"13554","city_name":"Kibangou","state_id":"817"},
    {"city_id":"13555","city_name":"Loubomo","state_id":"817"},
    {"city_id":"13556","city_name":"Matsanga","state_id":"817"},
    {"city_id":"13557","city_name":"Mossendjo","state_id":"817"},
    {"city_id":"13558","city_name":"Djambala","state_id":"818"},
    {"city_id":"13559","city_name":"Gamboma","state_id":"818"},
    {"city_id":"13560","city_name":"Boko","state_id":"819"},
    {"city_id":"13561","city_name":"Kinkala","state_id":"819"},
    {"city_id":"13562","city_name":"Mindouli","state_id":"819"},
    {"city_id":"13563","city_name":"Ikelemba","state_id":"820"},
    {"city_id":"13564","city_name":"Ouesso","state_id":"820"},
    {"city_id":"13565","city_name":"Sembe","state_id":"820"},
    {"city_id":"13566","city_name":"Souanke","state_id":"820"},
    {"city_id":"13567","city_name":"Bandundu","state_id":"821"},
    {"city_id":"13568","city_name":"Bolobo","state_id":"821"},
    {"city_id":"13569","city_name":"Bulungu","state_id":"821"},
    {"city_id":"13570","city_name":"Gungu","state_id":"821"},
    {"city_id":"13571","city_name":"Idiofa","state_id":"821"},
    {"city_id":"13572","city_name":"Inongo","state_id":"821"},
    {"city_id":"13573","city_name":"Kahemba","state_id":"821"},
    {"city_id":"13574","city_name":"Kasongo-Lunda","state_id":"821"},
    {"city_id":"13575","city_name":"Kenge","state_id":"821"},
    {"city_id":"13576","city_name":"Kikwit","state_id":"821"},
    {"city_id":"13577","city_name":"Kiri","state_id":"821"},
    {"city_id":"13578","city_name":"Kutu","state_id":"821"},
    {"city_id":"13579","city_name":"Lusanga","state_id":"821"},
    {"city_id":"13580","city_name":"Mangai","state_id":"821"},
    {"city_id":"13581","city_name":"Mushie","state_id":"821"},
    {"city_id":"13582","city_name":"Nioki","state_id":"821"},
    {"city_id":"13583","city_name":"Boma","state_id":"822"},
    {"city_id":"13584","city_name":"Kasangulu","state_id":"822"},
    {"city_id":"13585","city_name":"Kimpese","state_id":"822"},
    {"city_id":"13586","city_name":"Madimba","state_id":"822"},
    {"city_id":"13587","city_name":"Matadi","state_id":"822"},
    {"city_id":"13588","city_name":"Mbanza-Ngungu","state_id":"822"},
    {"city_id":"13589","city_name":"Muanda","state_id":"822"},
    {"city_id":"13590","city_name":"Tshela","state_id":"822"},
    {"city_id":"13591","city_name":"Basankusu","state_id":"823"},
    {"city_id":"13592","city_name":"Binga","state_id":"823"},
    {"city_id":"13593","city_name":"Bodalangi","state_id":"823"},
    {"city_id":"13594","city_name":"Boende","state_id":"823"},
    {"city_id":"13595","city_name":"Bongandanga","state_id":"823"},
    {"city_id":"13596","city_name":"Bosobolo","state_id":"823"},
    {"city_id":"13597","city_name":"Bumba","state_id":"823"},
    {"city_id":"13598","city_name":"Businga","state_id":"823"},
    {"city_id":"13599","city_name":"Gbadolite","state_id":"823"},
    {"city_id":"13600","city_name":"Gemena","state_id":"823"},
    {"city_id":"13601","city_name":"Ikela","state_id":"823"},
    {"city_id":"13602","city_name":"Libenge","state_id":"823"},
    {"city_id":"13603","city_name":"Lisala","state_id":"823"},
    {"city_id":"13604","city_name":"Makanza","state_id":"823"},
    {"city_id":"13605","city_name":"Mbandaka","state_id":"823"},
    {"city_id":"13606","city_name":"Mobayi-Mbongo","state_id":"823"},
    {"city_id":"13607","city_name":"Yakoma","state_id":"823"},
    {"city_id":"13608","city_name":"Yandongi","state_id":"823"},
    {"city_id":"13609","city_name":"Yumbi","state_id":"823"},
    {"city_id":"13610","city_name":"Zongo","state_id":"823"},
    {"city_id":"13611","city_name":"Aba","state_id":"824"},
    {"city_id":"13612","city_name":"Aketi","state_id":"824"},
    {"city_id":"13613","city_name":"Bafwasende","state_id":"824"},
    {"city_id":"13614","city_name":"Banalia","state_id":"824"},
    {"city_id":"13615","city_name":"Basoko","state_id":"824"},
    {"city_id":"13616","city_name":"Bondo","state_id":"824"},
    {"city_id":"13617","city_name":"Bunia","state_id":"824"},
    {"city_id":"13618","city_name":"Buta","state_id":"824"},
    {"city_id":"13619","city_name":"Djugu","state_id":"824"},
    {"city_id":"13620","city_name":"Faradje","state_id":"824"},
    {"city_id":"13621","city_name":"Gwane","state_id":"824"},
    {"city_id":"13622","city_name":"Isiro","state_id":"824"},
    {"city_id":"13623","city_name":"Itoko","state_id":"824"},
    {"city_id":"13624","city_name":"Kisangani","state_id":"824"},
    {"city_id":"13625","city_name":"Mambasa","state_id":"824"},
    {"city_id":"13626","city_name":"Mongbwalu","state_id":"824"},
    {"city_id":"13627","city_name":"Niangara","state_id":"824"},
    {"city_id":"13628","city_name":"Poko","state_id":"824"},
    {"city_id":"13629","city_name":"Simba","state_id":"824"},
    {"city_id":"13630","city_name":"Titule","state_id":"824"},
    {"city_id":"13631","city_name":"Ubundu","state_id":"824"},
    {"city_id":"13632","city_name":"Wamba","state_id":"824"},
    {"city_id":"13633","city_name":"Watsa","state_id":"824"},
    {"city_id":"13634","city_name":"Yangambi","state_id":"824"},
    {"city_id":"13635","city_name":"Demba","state_id":"825"},
    {"city_id":"13636","city_name":"Dibaya","state_id":"825"},
    {"city_id":"13637","city_name":"Ilebo","state_id":"825"},
    {"city_id":"13638","city_name":"Kananga","state_id":"825"},
    {"city_id":"13639","city_name":"Kazumba","state_id":"825"},
    {"city_id":"13640","city_name":"Luebo","state_id":"825"},
    {"city_id":"13641","city_name":"Mweka","state_id":"825"},
    {"city_id":"13642","city_name":"Tshikapa","state_id":"825"},
    {"city_id":"13643","city_name":"Gandajika","state_id":"826"},
    {"city_id":"13644","city_name":"Kabinda","state_id":"826"},
    {"city_id":"13645","city_name":"Katako-Kombe","state_id":"826"},
    {"city_id":"13646","city_name":"Kole","state_id":"826"},
    {"city_id":"13647","city_name":"Lodja","state_id":"826"},
    {"city_id":"13648","city_name":"Lubao","state_id":"826"},
    {"city_id":"13649","city_name":"Lubefu","state_id":"826"},
    {"city_id":"13650","city_name":"Lusambo","state_id":"826"},
    {"city_id":"13651","city_name":"Mbuji-Mayi","state_id":"826"},
    {"city_id":"13652","city_name":"Mwene-Ditu","state_id":"826"},
    {"city_id":"13653","city_name":"Tshilenge","state_id":"826"},
    {"city_id":"13654","city_name":"Tshofa","state_id":"826"},
    {"city_id":"13655","city_name":"Bukama","state_id":"827"},
    {"city_id":"13656","city_name":"Dilolo","state_id":"827"},
    {"city_id":"13657","city_name":"Kabalo","state_id":"827"},
    {"city_id":"13658","city_name":"Kalemie","state_id":"827"},
    {"city_id":"13659","city_name":"Kambove","state_id":"827"},
    {"city_id":"13660","city_name":"Kamina","state_id":"827"},
    {"city_id":"13661","city_name":"Kaniama","state_id":"827"},
    {"city_id":"13662","city_name":"Kikondjo","state_id":"827"},
    {"city_id":"13663","city_name":"Kipushi","state_id":"827"},
    {"city_id":"13664","city_name":"Kolwezi","state_id":"827"},
    {"city_id":"13665","city_name":"Kongolo","state_id":"827"},
    {"city_id":"13666","city_name":"Le Marinel","state_id":"827"},
    {"city_id":"13667","city_name":"Likasi","state_id":"827"},
    {"city_id":"13668","city_name":"Lubudi","state_id":"827"},
    {"city_id":"13669","city_name":"Lubumbashi","state_id":"827"},
    {"city_id":"13670","city_name":"Malemba-Nkulu","state_id":"827"},
    {"city_id":"13671","city_name":"Manono","state_id":"827"},
    {"city_id":"13672","city_name":"Moba","state_id":"827"},
    {"city_id":"13673","city_name":"Mulongo","state_id":"827"},
    {"city_id":"13674","city_name":"Mwanza","state_id":"827"},
    {"city_id":"13675","city_name":"Nyunzu","state_id":"827"},
    {"city_id":"13676","city_name":"Sakania","state_id":"827"},
    {"city_id":"13677","city_name":"Shinkolobwe","state_id":"827"},
    {"city_id":"13678","city_name":"Kinshasa","state_id":"828"},
    {"city_id":"13679","city_name":"Kalima","state_id":"829"},
    {"city_id":"13680","city_name":"Kasongo","state_id":"829"},
    {"city_id":"13681","city_name":"Kindu","state_id":"829"},
    {"city_id":"13682","city_name":"Beni","state_id":"830"},
    {"city_id":"13683","city_name":"Butembo","state_id":"830"},
    {"city_id":"13684","city_name":"Goma","state_id":"830"},
    {"city_id":"13685","city_name":"Bukavu","state_id":"831"},
    {"city_id":"13686","city_name":"Kabare","state_id":"831"},
    {"city_id":"13687","city_name":"Kama","state_id":"831"},
    {"city_id":"13688","city_name":"Kampene","state_id":"831"},
    {"city_id":"13689","city_name":"Kibombo","state_id":"831"},
    {"city_id":"13690","city_name":"Uvira","state_id":"831"},
    {"city_id":"13691","city_name":"Amuri","state_id":"832"},
    {"city_id":"13692","city_name":"Atiu","state_id":"833"},
    {"city_id":"13693","city_name":"Mangaia","state_id":"834"},
    {"city_id":"13694","city_name":"Tauhunu","state_id":"835"},
    {"city_id":"13695","city_name":"Mauke","state_id":"836"},
    {"city_id":"13696","city_name":"Mitiaro","state_id":"837"},
    {"city_id":"13697","city_name":"Nassau","state_id":"838"},
    {"city_id":"13698","city_name":"Roto","state_id":"839"},
    {"city_id":"13699","city_name":"Rakahanga","state_id":"840"},
    {"city_id":"13700","city_name":"Avarua","state_id":"841"},
    {"city_id":"13701","city_name":"Omoka","state_id":"842"},
    {"city_id":"13702","city_name":"Alajuela","state_id":"843"},
    {"city_id":"13703","city_name":"Atenas","state_id":"843"},
    {"city_id":"13704","city_name":"Bijagua","state_id":"843"},
    {"city_id":"13705","city_name":"Buenos Aires","state_id":"843"},
    {"city_id":"13706","city_name":"Carrillos","state_id":"843"},
    {"city_id":"13707","city_name":"Desemparados","state_id":"843"},
    {"city_id":"13708","city_name":"Dos Rios","state_id":"843"},
    {"city_id":"13709","city_name":"Esquipulas","state_id":"843"},
    {"city_id":"13710","city_name":"Florencia","state_id":"843"},
    {"city_id":"13711","city_name":"Fortuna","state_id":"843"},
    {"city_id":"13712","city_name":"Grecia","state_id":"843"},
    {"city_id":"13713","city_name":"Guacimo","state_id":"843"},
    {"city_id":"13714","city_name":"Laguna","state_id":"843"},
    {"city_id":"13715","city_name":"Los Chiles","state_id":"843"},
    {"city_id":"13716","city_name":"Mastate","state_id":"843"},
    {"city_id":"13717","city_name":"Naranjo","state_id":"843"},
    {"city_id":"13718","city_name":"Orotina","state_id":"843"},
    {"city_id":"13719","city_name":"Palmares","state_id":"843"},
    {"city_id":"13720","city_name":"Piedades Norte","state_id":"843"},
    {"city_id":"13721","city_name":"Pital","state_id":"843"},
    {"city_id":"13722","city_name":"Pocosol","state_id":"843"},
    {"city_id":"13723","city_name":"Quesada","state_id":"843"},
    {"city_id":"13724","city_name":"Rio Segundo","state_id":"843"},
    {"city_id":"13725","city_name":"Sabanilla","state_id":"843"},
    {"city_id":"13726","city_name":"San Antonio","state_id":"843"},
    {"city_id":"13727","city_name":"San Jose","state_id":"843"},
    {"city_id":"13728","city_name":"San Juan","state_id":"843"},
    {"city_id":"13729","city_name":"San Mateo","state_id":"843"},
    {"city_id":"13730","city_name":"San Pedro","state_id":"843"},
    {"city_id":"13731","city_name":"San Rafael","state_id":"843"},
    {"city_id":"13732","city_name":"San Ramon","state_id":"843"},
    {"city_id":"13733","city_name":"San Roque","state_id":"843"},
    {"city_id":"13734","city_name":"Santiago","state_id":"843"},
    {"city_id":"13735","city_name":"Sarchi Norte","state_id":"843"},
    {"city_id":"13736","city_name":"Sarchi Sur","state_id":"843"},
    {"city_id":"13737","city_name":"Tigra","state_id":"843"},
    {"city_id":"13738","city_name":"Turricares","state_id":"843"},
    {"city_id":"13739","city_name":"Upala","state_id":"843"},
    {"city_id":"13740","city_name":"Venado","state_id":"843"},
    {"city_id":"13741","city_name":"Zarcero","state_id":"843"},
    {"city_id":"13742","city_name":"Aguacaliente","state_id":"844"},
    {"city_id":"13743","city_name":"Capellades","state_id":"844"},
    {"city_id":"13744","city_name":"Carmen","state_id":"844"},
    {"city_id":"13745","city_name":"Cartago","state_id":"844"},
    {"city_id":"13746","city_name":"Concepcion","state_id":"844"},
    {"city_id":"13747","city_name":"Cot","state_id":"844"},
    {"city_id":"13748","city_name":"Dulce Nombre","state_id":"844"},
    {"city_id":"13749","city_name":"El Tejar","state_id":"844"},
    {"city_id":"13750","city_name":"Guadalupe","state_id":"844"},
    {"city_id":"13751","city_name":"Juan Vinas","state_id":"844"},
    {"city_id":"13752","city_name":"La Suiza","state_id":"844"},
    {"city_id":"13753","city_name":"Orosi","state_id":"844"},
    {"city_id":"13754","city_name":"Pacayas","state_id":"844"},
    {"city_id":"13755","city_name":"Paraiso","state_id":"844"},
    {"city_id":"13756","city_name":"Pejibaye","state_id":"844"},
    {"city_id":"13757","city_name":"San Diego","state_id":"844"},
    {"city_id":"13758","city_name":"San Isidro","state_id":"844"},
    {"city_id":"13759","city_name":"San Juan","state_id":"844"},
    {"city_id":"13760","city_name":"San Nicolas","state_id":"844"},
    {"city_id":"13761","city_name":"San Rafael","state_id":"844"},
    {"city_id":"13762","city_name":"San Ramon","state_id":"844"},
    {"city_id":"13763","city_name":"Tierra Blanca","state_id":"844"},
    {"city_id":"13764","city_name":"Tobosi","state_id":"844"},
    {"city_id":"13765","city_name":"Tres Rios","state_id":"844"},
    {"city_id":"13766","city_name":"Tucurrique","state_id":"844"},
    {"city_id":"13767","city_name":"Turrialba","state_id":"844"},
    {"city_id":"13768","city_name":"Bagaces","state_id":"845"},
    {"city_id":"13769","city_name":"Belen","state_id":"845"},
    {"city_id":"13770","city_name":"Canas","state_id":"845"},
    {"city_id":"13771","city_name":"Filadeldia","state_id":"845"},
    {"city_id":"13772","city_name":"Fortuna","state_id":"845"},
    {"city_id":"13773","city_name":"Hojancha","state_id":"845"},
    {"city_id":"13774","city_name":"Juntas","state_id":"845"},
    {"city_id":"13775","city_name":"La Cruz","state_id":"845"},
    {"city_id":"13776","city_name":"Liberia","state_id":"845"},
    {"city_id":"13777","city_name":"Mogote","state_id":"845"},
    {"city_id":"13778","city_name":"Nandayure","state_id":"845"},
    {"city_id":"13779","city_name":"Nicoya","state_id":"845"},
    {"city_id":"13780","city_name":"Samara","state_id":"845"},
    {"city_id":"13781","city_name":"Santa Cruz","state_id":"845"},
    {"city_id":"13782","city_name":"Sardinal","state_id":"845"},
    {"city_id":"13783","city_name":"Tilaran","state_id":"845"},
    {"city_id":"13784","city_name":"Angeles","state_id":"846"},
    {"city_id":"13785","city_name":"Asuncion","state_id":"846"},
    {"city_id":"13786","city_name":"Barrantes","state_id":"846"},
    {"city_id":"13787","city_name":"Barva","state_id":"846"},
    {"city_id":"13788","city_name":"Heredia","state_id":"846"},
    {"city_id":"13789","city_name":"Horquetas","state_id":"846"},
    {"city_id":"13790","city_name":"Llorente","state_id":"846"},
    {"city_id":"13791","city_name":"Mercedes","state_id":"846"},
    {"city_id":"13792","city_name":"Puerto Viejo","state_id":"846"},
    {"city_id":"13793","city_name":"Rivera","state_id":"846"},
    {"city_id":"13794","city_name":"San Antonio","state_id":"846"},
    {"city_id":"13795","city_name":"San Francisco","state_id":"846"},
    {"city_id":"13796","city_name":"San Isidro","state_id":"846"},
    {"city_id":"13797","city_name":"San Joaquin","state_id":"846"},
    {"city_id":"13798","city_name":"San Jose","state_id":"846"},
    {"city_id":"13799","city_name":"San Josecito","state_id":"846"},
    {"city_id":"13800","city_name":"San Juan","state_id":"846"},
    {"city_id":"13801","city_name":"San Miguel","state_id":"846"},
    {"city_id":"13802","city_name":"San Pablo","state_id":"846"},
    {"city_id":"13803","city_name":"San Pedro","state_id":"846"},
    {"city_id":"13804","city_name":"San Rafael","state_id":"846"},
    {"city_id":"13805","city_name":"San Roque","state_id":"846"},
    {"city_id":"13806","city_name":"San Vicente","state_id":"846"},
    {"city_id":"13807","city_name":"Santa Barbara","state_id":"846"},
    {"city_id":"13808","city_name":"Santa Lucia","state_id":"846"},
    {"city_id":"13809","city_name":"Santa Rosa","state_id":"846"},
    {"city_id":"13810","city_name":"Santiago","state_id":"846"},
    {"city_id":"13811","city_name":"Santo Domingo","state_id":"846"},
    {"city_id":"13812","city_name":"Santo Tomas","state_id":"846"},
    {"city_id":"13813","city_name":"Ulloa","state_id":"846"},
    {"city_id":"13814","city_name":"Batan","state_id":"847"},
    {"city_id":"13815","city_name":"Cahuita","state_id":"847"},
    {"city_id":"13816","city_name":"Cariari","state_id":"847"},
    {"city_id":"13817","city_name":"Guacimo","state_id":"847"},
    {"city_id":"13818","city_name":"Guapiles","state_id":"847"},
    {"city_id":"13819","city_name":"Jimenez","state_id":"847"},
    {"city_id":"13820","city_name":"Limon","state_id":"847"},
    {"city_id":"13821","city_name":"Matina","state_id":"847"},
    {"city_id":"13822","city_name":"Pocora","state_id":"847"},
    {"city_id":"13823","city_name":"Rita","state_id":"847"},
    {"city_id":"13824","city_name":"Roxana","state_id":"847"},
    {"city_id":"13825","city_name":"Siquirres","state_id":"847"},
    {"city_id":"13826","city_name":"Sixaola","state_id":"847"},
    {"city_id":"13827","city_name":"Valle la Estrella","state_id":"847"},
    {"city_id":"13828","city_name":"Buenos Aires","state_id":"848"},
    {"city_id":"13829","city_name":"Canoas","state_id":"848"},
    {"city_id":"13830","city_name":"Chacarita","state_id":"848"},
    {"city_id":"13831","city_name":"Corredor","state_id":"848"},
    {"city_id":"13832","city_name":"Esparta","state_id":"848"},
    {"city_id":"13833","city_name":"Espiritu Santo","state_id":"848"},
    {"city_id":"13834","city_name":"Golfito","state_id":"848"},
    {"city_id":"13835","city_name":"Guaycara","state_id":"848"},
    {"city_id":"13836","city_name":"Jaco","state_id":"848"},
    {"city_id":"13837","city_name":"La Cuesta","state_id":"848"},
    {"city_id":"13838","city_name":"Macacona","state_id":"848"},
    {"city_id":"13839","city_name":"Manzanillo","state_id":"848"},
    {"city_id":"13840","city_name":"Miramar","state_id":"848"},
    {"city_id":"13841","city_name":"Palmar","state_id":"848"},
    {"city_id":"13842","city_name":"Paquera","state_id":"848"},
    {"city_id":"13843","city_name":"Parrita","state_id":"848"},
    {"city_id":"13844","city_name":"Puerto Cortes","state_id":"848"},
    {"city_id":"13845","city_name":"Puerto Jimenez","state_id":"848"},
    {"city_id":"13846","city_name":"Puntarenas","state_id":"848"},
    {"city_id":"13847","city_name":"Quepos","state_id":"848"},
    {"city_id":"13848","city_name":"San Vito","state_id":"848"},
    {"city_id":"13849","city_name":"Marcory","state_id":"850"},
    {"city_id":"13850","city_name":"Adzope","state_id":"851"},
    {"city_id":"13851","city_name":"Affery","state_id":"851"},
    {"city_id":"13852","city_name":"Agboville","state_id":"851"},
    {"city_id":"13853","city_name":"Akoupe","state_id":"851"},
    {"city_id":"13854","city_name":"Rubino","state_id":"851"},
    {"city_id":"13855","city_name":"Touba","state_id":"852"},
    {"city_id":"13856","city_name":"Bako","state_id":"853"},
    {"city_id":"13857","city_name":"Odienne","state_id":"853"},
    {"city_id":"13858","city_name":"Gagnoa","state_id":"855"},
    {"city_id":"13859","city_name":"Guiberoua","state_id":"855"},
    {"city_id":"13860","city_name":"Hire","state_id":"855"},
    {"city_id":"13861","city_name":"Ndouci","state_id":"855"},
    {"city_id":"13862","city_name":"Oume","state_id":"855"},
    {"city_id":"13863","city_name":"Ouragahio","state_id":"855"},
    {"city_id":"13864","city_name":"Daloa","state_id":"856"},
    {"city_id":"13865","city_name":"Issia","state_id":"856"},
    {"city_id":"13866","city_name":"Vavoua","state_id":"856"},
    {"city_id":"13867","city_name":"Ndiekro","state_id":"857"},
    {"city_id":"13868","city_name":"Tiebissou","state_id":"857"},
    {"city_id":"13869","city_name":"Toumodi","state_id":"857"},
    {"city_id":"13870","city_name":"Yamoussoukro","state_id":"857"},
    {"city_id":"13871","city_name":"Abidjan","state_id":"858"},
    {"city_id":"13872","city_name":"Alepe","state_id":"858"},
    {"city_id":"13873","city_name":"Anyama","state_id":"858"},
    {"city_id":"13874","city_name":"Bingerville","state_id":"858"},
    {"city_id":"13875","city_name":"Dabou","state_id":"858"},
    {"city_id":"13876","city_name":"Grand-Lahou","state_id":"858"},
    {"city_id":"13877","city_name":"Jacqueville","state_id":"858"},
    {"city_id":"13878","city_name":"Tiassale","state_id":"858"},
    {"city_id":"13879","city_name":"Bouafle","state_id":"859"},
    {"city_id":"13880","city_name":"Sinfra","state_id":"859"},
    {"city_id":"13881","city_name":"Zuenoula","state_id":"859"},
    {"city_id":"13882","city_name":"Duekoue","state_id":"860"},
    {"city_id":"13883","city_name":"Guiglo","state_id":"860"},
    {"city_id":"13884","city_name":"Toulepleu","state_id":"860"},
    {"city_id":"13885","city_name":"Abengourou","state_id":"861"},
    {"city_id":"13886","city_name":"Agnibilekrou","state_id":"861"},
    {"city_id":"13887","city_name":"Arrah","state_id":"862"},
    {"city_id":"13888","city_name":"Bocanda","state_id":"862"},
    {"city_id":"13889","city_name":"Bongouanou","state_id":"862"},
    {"city_id":"13890","city_name":"Daoukro","state_id":"862"},
    {"city_id":"13891","city_name":"Dimbokro","state_id":"862"},
    {"city_id":"13892","city_name":"M'bahiakro","state_id":"862"},
    {"city_id":"13893","city_name":"Mbatto","state_id":"862"},
    {"city_id":"13894","city_name":"N'zi-Comoe","state_id":"862"},
    {"city_id":"13895","city_name":"San-Pedro","state_id":"863"},
    {"city_id":"13896","city_name":"Sassandra","state_id":"863"},
    {"city_id":"13897","city_name":"Soubre","state_id":"863"},
    {"city_id":"13898","city_name":"Tabou","state_id":"863"},
    {"city_id":"13899","city_name":"Boundiali","state_id":"864"},
    {"city_id":"13900","city_name":"Dikodougou","state_id":"864"},
    {"city_id":"13901","city_name":"Ferkessedougou","state_id":"864"},
    {"city_id":"13902","city_name":"Korhogo","state_id":"864"},
    {"city_id":"13903","city_name":"Tingrela","state_id":"864"},
    {"city_id":"13904","city_name":"Divo","state_id":"865"},
    {"city_id":"13905","city_name":"Fresco","state_id":"865"},
    {"city_id":"13906","city_name":"Lakota","state_id":"865"},
    {"city_id":"13907","city_name":"Aboisso","state_id":"866"},
    {"city_id":"13908","city_name":"Adiake","state_id":"866"},
    {"city_id":"13909","city_name":"Ayame","state_id":"866"},
    {"city_id":"13910","city_name":"Bonoua","state_id":"866"},
    {"city_id":"13911","city_name":"Grand Bassam","state_id":"866"},
    {"city_id":"13912","city_name":"Kani","state_id":"868"},
    {"city_id":"13913","city_name":"Mankono","state_id":"868"},
    {"city_id":"13914","city_name":"Seguela","state_id":"868"},
    {"city_id":"13915","city_name":"Bondoukou","state_id":"869"},
    {"city_id":"13916","city_name":"Bouna","state_id":"869"},
    {"city_id":"13917","city_name":"Tanda","state_id":"869"},
    {"city_id":"13918","city_name":"Bjelovar","state_id":"870"},
    {"city_id":"13919","city_name":"Brezovac","state_id":"870"},
    {"city_id":"13920","city_name":"Chazma","state_id":"870"},
    {"city_id":"13921","city_name":"Daruvar","state_id":"870"},
    {"city_id":"13922","city_name":"Daruvarski Brestovac","state_id":"870"},
    {"city_id":"13923","city_name":"Dezhanovac","state_id":"870"},
    {"city_id":"13924","city_name":"Dhulovac","state_id":"870"},
    {"city_id":"13925","city_name":"Gareshnica","state_id":"870"},
    {"city_id":"13926","city_name":"Gareshnichki Brestovac","state_id":"870"},
    {"city_id":"13927","city_name":"Grubishno Polje","state_id":"870"},
    {"city_id":"13928","city_name":"Gudovac","state_id":"870"},
    {"city_id":"13929","city_name":"Hercegovac","state_id":"870"},
    {"city_id":"13930","city_name":"Ivanska","state_id":"870"},
    {"city_id":"13931","city_name":"Klokochevac","state_id":"870"},
    {"city_id":"13932","city_name":"Konchanica","state_id":"870"},
    {"city_id":"13933","city_name":"Predavac","state_id":"870"},
    {"city_id":"13934","city_name":"Rovishce","state_id":"870"},
    {"city_id":"13935","city_name":"Shandrovac","state_id":"870"},
    {"city_id":"13936","city_name":"Sirach","state_id":"870"},
    {"city_id":"13937","city_name":"Trojstveni Markovac","state_id":"870"},
    {"city_id":"13938","city_name":"Velika Pisanica","state_id":"870"},
    {"city_id":"13939","city_name":"Veliki Grdhevac","state_id":"870"},
    {"city_id":"13940","city_name":"Veliki Zdenci","state_id":"870"},
    {"city_id":"13941","city_name":"Veliko Trojstvo","state_id":"870"},
    {"city_id":"13942","city_name":"Zhdralovi","state_id":"870"},
    {"city_id":"13943","city_name":"Blato","state_id":"871"},
    {"city_id":"13944","city_name":"Cavtat","state_id":"871"},
    {"city_id":"13945","city_name":"Chibacha","state_id":"871"},
    {"city_id":"13946","city_name":"Chilipi","state_id":"871"},
    {"city_id":"13947","city_name":"Dubrovnik","state_id":"871"},
    {"city_id":"13948","city_name":"Komin","state_id":"871"},
    {"city_id":"13949","city_name":"Korchula","state_id":"871"},
    {"city_id":"13950","city_name":"Lumbarda","state_id":"871"},
    {"city_id":"13951","city_name":"Metkovic","state_id":"871"},
    {"city_id":"13952","city_name":"Mlini","state_id":"871"},
    {"city_id":"13953","city_name":"Mokoshica","state_id":"871"},
    {"city_id":"13954","city_name":"Nova Mokoshica","state_id":"871"},
    {"city_id":"13955","city_name":"Opuzen","state_id":"871"},
    {"city_id":"13956","city_name":"Orebic","state_id":"871"},
    {"city_id":"13957","city_name":"Otrich-Seoci","state_id":"871"},
    {"city_id":"13958","city_name":"Ploche","state_id":"871"},
    {"city_id":"13959","city_name":"Smokvica","state_id":"871"},
    {"city_id":"13960","city_name":"Stashevica","state_id":"871"},
    {"city_id":"13961","city_name":"Vela Luka","state_id":"871"},
    {"city_id":"13962","city_name":"Zaton","state_id":"871"},
    {"city_id":"13963","city_name":"Zhrnovo","state_id":"871"},
    {"city_id":"13964","city_name":"Bale","state_id":"873"},
    {"city_id":"13965","city_name":"Banjole","state_id":"873"},
    {"city_id":"13966","city_name":"Brtonigla","state_id":"873"},
    {"city_id":"13967","city_name":"Buje","state_id":"873"},
    {"city_id":"13968","city_name":"Buzet","state_id":"873"},
    {"city_id":"13969","city_name":"Fazhana","state_id":"873"},
    {"city_id":"13970","city_name":"Funtana","state_id":"873"},
    {"city_id":"13971","city_name":"Galizhana","state_id":"873"},
    {"city_id":"13972","city_name":"Labin","state_id":"873"},
    {"city_id":"13973","city_name":"Lizhnjan","state_id":"873"},
    {"city_id":"13974","city_name":"Marchana","state_id":"873"},
    {"city_id":"13975","city_name":"Medulin","state_id":"873"},
    {"city_id":"13976","city_name":"Novigrad","state_id":"873"},
    {"city_id":"13977","city_name":"Pazin","state_id":"873"},
    {"city_id":"13978","city_name":"Porech","state_id":"873"},
    {"city_id":"13979","city_name":"Premantura","state_id":"873"},
    {"city_id":"13980","city_name":"Pula","state_id":"873"},
    {"city_id":"13981","city_name":"Rabac","state_id":"873"},
    {"city_id":"13982","city_name":"Rasha","state_id":"873"},
    {"city_id":"13983","city_name":"Rovinj","state_id":"873"},
    {"city_id":"13984","city_name":"Sveti Petar u Shumi","state_id":"873"},
    {"city_id":"13985","city_name":"Tar","state_id":"873"},
    {"city_id":"13986","city_name":"Umag","state_id":"873"},
    {"city_id":"13987","city_name":"Vinezh","state_id":"873"},
    {"city_id":"13988","city_name":"Vodnjan","state_id":"873"},
    {"city_id":"13989","city_name":"Vrsar","state_id":"873"},
    {"city_id":"13990","city_name":"Cerovac Vukmansichki","state_id":"874"},
    {"city_id":"13991","city_name":"Draganic","state_id":"874"},
    {"city_id":"13992","city_name":"Duga Resa","state_id":"874"},
    {"city_id":"13993","city_name":"Josipdol","state_id":"874"},
    {"city_id":"13994","city_name":"Karlovac","state_id":"874"},
    {"city_id":"13995","city_name":"Mrezhnichki Varosh","state_id":"874"},
    {"city_id":"13996","city_name":"Ogulin","state_id":"874"},
    {"city_id":"13997","city_name":"Oshtarije","state_id":"874"},
    {"city_id":"13998","city_name":"Ozalj","state_id":"874"},
    {"city_id":"13999","city_name":"Plashki","state_id":"874"},
    {"city_id":"14000","city_name":"Slunj","state_id":"874"},
    {"city_id":"14001","city_name":"Vojnic","state_id":"874"},
    {"city_id":"14002","city_name":"Dhelekovec","state_id":"875"},
    {"city_id":"14003","city_name":"Dhurdhevac","state_id":"875"},
    {"city_id":"14004","city_name":"Drnje","state_id":"875"},
    {"city_id":"14005","city_name":"Ferdinandovac","state_id":"875"},
    {"city_id":"14006","city_name":"Glogovac","state_id":"875"},
    {"city_id":"14007","city_name":"Gola","state_id":"875"},
    {"city_id":"14008","city_name":"Hlebine","state_id":"875"},
    {"city_id":"14009","city_name":"Kalinovac","state_id":"875"},
    {"city_id":"14010","city_name":"Kloshtar Podravski","state_id":"875"},
    {"city_id":"14011","city_name":"Koprivnica","state_id":"875"},
    {"city_id":"14012","city_name":"Koprivnichki Bregi","state_id":"875"},
    {"city_id":"14013","city_name":"Koprivnichki Ivanec","state_id":"875"},
    {"city_id":"14014","city_name":"Krizhevci","state_id":"875"},
    {"city_id":"14015","city_name":"Legrad","state_id":"875"},
    {"city_id":"14016","city_name":"Molve","state_id":"875"},
    {"city_id":"14017","city_name":"Novigrad Podravski","state_id":"875"},
    {"city_id":"14018","city_name":"Novo Virje","state_id":"875"},
    {"city_id":"14019","city_name":"Peteranec","state_id":"875"},
    {"city_id":"14020","city_name":"Podravske Sesvete","state_id":"875"},
    {"city_id":"14021","city_name":"Rasinja","state_id":"875"},
    {"city_id":"14022","city_name":"Reka","state_id":"875"},
    {"city_id":"14023","city_name":"Sigetec","state_id":"875"},
    {"city_id":"14024","city_name":"Starigrad","state_id":"875"},
    {"city_id":"14025","city_name":"Sveti Ivan Zhabno","state_id":"875"},
    {"city_id":"14026","city_name":"Trema","state_id":"875"},
    {"city_id":"14027","city_name":"Virje","state_id":"875"},
    {"city_id":"14028","city_name":"Andrashevec","state_id":"876"},
    {"city_id":"14029","city_name":"Bedekovchina","state_id":"876"},
    {"city_id":"14030","city_name":"Dhurmanec","state_id":"876"},
    {"city_id":"14031","city_name":"Donja Pachetina","state_id":"876"},
    {"city_id":"14032","city_name":"Donja Shemnica","state_id":"876"},
    {"city_id":"14033","city_name":"Donja Stubica","state_id":"876"},
    {"city_id":"14034","city_name":"Dubrovchan","state_id":"876"},
    {"city_id":"14035","city_name":"Gornja Stubica","state_id":"876"},
    {"city_id":"14036","city_name":"Hum na Sutli","state_id":"876"},
    {"city_id":"14037","city_name":"Klanjec","state_id":"876"},
    {"city_id":"14038","city_name":"Konjishchina","state_id":"876"},
    {"city_id":"14039","city_name":"Krapina","state_id":"876"},
    {"city_id":"14040","city_name":"Krapinske Toplice","state_id":"876"},
    {"city_id":"14041","city_name":"Laz Bistrichki","state_id":"876"},
    {"city_id":"14042","city_name":"Marija Bistrica","state_id":"876"},
    {"city_id":"14043","city_name":"Mihovljan","state_id":"876"},
    {"city_id":"14044","city_name":"Oroslavje","state_id":"876"},
    {"city_id":"14045","city_name":"Podgorje Bistrichko","state_id":"876"},
    {"city_id":"14046","city_name":"Poznanovec","state_id":"876"},
    {"city_id":"14047","city_name":"Pregrada","state_id":"876"},
    {"city_id":"14048","city_name":"Radoboj","state_id":"876"},
    {"city_id":"14049","city_name":"Shkaricevo","state_id":"876"},
    {"city_id":"14050","city_name":"Shpichkovina","state_id":"876"},
    {"city_id":"14051","city_name":"Stubichke Toplice","state_id":"876"},
    {"city_id":"14052","city_name":"Sveti Krizh Zachretje","state_id":"876"},
    {"city_id":"14053","city_name":"Veliko Trgovishce","state_id":"876"},
    {"city_id":"14054","city_name":"Zabok","state_id":"876"},
    {"city_id":"14055","city_name":"Zlatar","state_id":"876"},
    {"city_id":"14056","city_name":"Zlatar-Bistrica","state_id":"876"},
    {"city_id":"14057","city_name":"Brinje","state_id":"877"},
    {"city_id":"14058","city_name":"Donji Lapac","state_id":"877"},
    {"city_id":"14059","city_name":"Gospic","state_id":"877"},
    {"city_id":"14060","city_name":"Korenica","state_id":"877"},
    {"city_id":"14061","city_name":"Lichki Osik","state_id":"877"},
    {"city_id":"14062","city_name":"Lichko Leshce","state_id":"877"},
    {"city_id":"14063","city_name":"Novalja","state_id":"877"},
    {"city_id":"14064","city_name":"Otochac","state_id":"877"},
    {"city_id":"14065","city_name":"Perushic","state_id":"877"},
    {"city_id":"14066","city_name":"Prozor","state_id":"877"},
    {"city_id":"14067","city_name":"Senj","state_id":"877"},
    {"city_id":"14068","city_name":"Belica","state_id":"878"},
    {"city_id":"14069","city_name":"Chakovec","state_id":"878"},
    {"city_id":"14070","city_name":"Cirkovljan","state_id":"878"},
    {"city_id":"14071","city_name":"Dekanovec","state_id":"878"},
    {"city_id":"14072","city_name":"Domashinec","state_id":"878"},
    {"city_id":"14073","city_name":"Donja Dubrava","state_id":"878"},
    {"city_id":"14074","city_name":"Donji Kraljevec","state_id":"878"},
    {"city_id":"14075","city_name":"Donji Vidovec","state_id":"878"},
    {"city_id":"14076","city_name":"Drzhimurec","state_id":"878"},
    {"city_id":"14077","city_name":"Dunjkovec","state_id":"878"},
    {"city_id":"14078","city_name":"Gardinovec","state_id":"878"},
    {"city_id":"14079","city_name":"Gorichan","state_id":"878"},
    {"city_id":"14080","city_name":"Gornji Hrashcan","state_id":"878"},
    {"city_id":"14081","city_name":"Hodoshan","state_id":"878"},
    {"city_id":"14082","city_name":"Ivanovec","state_id":"878"},
    {"city_id":"14083","city_name":"Kotoriba","state_id":"878"},
    {"city_id":"14084","city_name":"Kurshanec","state_id":"878"},
    {"city_id":"14085","city_name":"Lopatinec","state_id":"878"},
    {"city_id":"14086","city_name":"Machkovec","state_id":"878"},
    {"city_id":"14087","city_name":"Mala Subotica","state_id":"878"},
    {"city_id":"14088","city_name":"Mihovljan","state_id":"878"},
    {"city_id":"14089","city_name":"Mursko Sredishce","state_id":"878"},
    {"city_id":"14090","city_name":"Nedelishce","state_id":"878"},
    {"city_id":"14091","city_name":"Novakovec","state_id":"878"},
    {"city_id":"14092","city_name":"Novo Selo Rok","state_id":"878"},
    {"city_id":"14093","city_name":"Orehovica","state_id":"878"},
    {"city_id":"14094","city_name":"Palovec","state_id":"878"},
    {"city_id":"14095","city_name":"Peklenica","state_id":"878"},
    {"city_id":"14096","city_name":"Podturen","state_id":"878"},
    {"city_id":"14097","city_name":"Prelog","state_id":"878"},
    {"city_id":"14098","city_name":"Pribislavec","state_id":"878"},
    {"city_id":"14099","city_name":"Pushcine","state_id":"878"},
    {"city_id":"14100","city_name":"Savska Ves","state_id":"878"},
    {"city_id":"14101","city_name":"Selnica","state_id":"878"},
    {"city_id":"14102","city_name":"Shenkovec","state_id":"878"},
    {"city_id":"14103","city_name":"Strahoninec","state_id":"878"},
    {"city_id":"14104","city_name":"Sveta Marija","state_id":"878"},
    {"city_id":"14105","city_name":"Trnovec","state_id":"878"},
    {"city_id":"14106","city_name":"Vratishinec","state_id":"878"},
    {"city_id":"14107","city_name":"Zasadbreg","state_id":"878"},
    {"city_id":"14108","city_name":"Antunovac","state_id":"880"},
    {"city_id":"14109","city_name":"Batina","state_id":"880"},
    {"city_id":"14110","city_name":"Beli Manastir","state_id":"880"},
    {"city_id":"14111","city_name":"Belishce","state_id":"880"},
    {"city_id":"14112","city_name":"Beljevina","state_id":"880"},
    {"city_id":"14113","city_name":"Bijelo Brdo","state_id":"880"},
    {"city_id":"14114","city_name":"Bilje","state_id":"880"},
    {"city_id":"14115","city_name":"Bistrinci","state_id":"880"},
    {"city_id":"14116","city_name":"Bizovac","state_id":"880"},
    {"city_id":"14117","city_name":"Branjin Vrh","state_id":"880"},
    {"city_id":"14118","city_name":"Brijeshce","state_id":"880"},
    {"city_id":"14119","city_name":"Brijest","state_id":"880"},
    {"city_id":"14120","city_name":"Ceminac","state_id":"880"},
    {"city_id":"14121","city_name":"Chepin","state_id":"880"},
    {"city_id":"14122","city_name":"Crnkovci","state_id":"880"},
    {"city_id":"14123","city_name":"Dalj","state_id":"880"},
    {"city_id":"14124","city_name":"Darda","state_id":"880"},
    {"city_id":"14125","city_name":"Dhakovo","state_id":"880"},
    {"city_id":"14126","city_name":"Dhurdhenovac","state_id":"880"},
    {"city_id":"14127","city_name":"Donja Motichina","state_id":"880"},
    {"city_id":"14128","city_name":"Donji Miholac","state_id":"880"},
    {"city_id":"14129","city_name":"Erdut","state_id":"880"},
    {"city_id":"14130","city_name":"Ernestinovo","state_id":"880"},
    {"city_id":"14131","city_name":"Ferichanci","state_id":"880"},
    {"city_id":"14132","city_name":"Gashinci","state_id":"880"},
    {"city_id":"14133","city_name":"Gorjani","state_id":"880"},
    {"city_id":"14134","city_name":"Grabovac","state_id":"880"},
    {"city_id":"14135","city_name":"Ivanovac","state_id":"880"},
    {"city_id":"14136","city_name":"Ivanovci Gorjanski","state_id":"880"},
    {"city_id":"14137","city_name":"Jagodnjak","state_id":"880"},
    {"city_id":"14138","city_name":"Jelisavac","state_id":"880"},
    {"city_id":"14139","city_name":"Josipovac","state_id":"880"},
    {"city_id":"14140","city_name":"Josipovac Punitovachki","state_id":"880"},
    {"city_id":"14141","city_name":"Karanac","state_id":"880"},
    {"city_id":"14142","city_name":"Keshinci","state_id":"880"},
    {"city_id":"14143","city_name":"Knezhevi Vinogradi","state_id":"880"},
    {"city_id":"14144","city_name":"Knezhevo","state_id":"880"},
    {"city_id":"14145","city_name":"Koritna","state_id":"880"},
    {"city_id":"14146","city_name":"Koshka","state_id":"880"},
    {"city_id":"14147","city_name":"Kushevac","state_id":"880"},
    {"city_id":"14148","city_name":"Ladimirevci","state_id":"880"},
    {"city_id":"14149","city_name":"Laslovo","state_id":"880"},
    {"city_id":"14150","city_name":"Lug","state_id":"880"},
    {"city_id":"14151","city_name":"Marijanci","state_id":"880"},
    {"city_id":"14152","city_name":"Markovac Nashichki","state_id":"880"},
    {"city_id":"14153","city_name":"Martin","state_id":"880"},
    {"city_id":"14154","city_name":"Mece","state_id":"880"},
    {"city_id":"14155","city_name":"Moslavina Podravska","state_id":"880"},
    {"city_id":"14156","city_name":"Nashice","state_id":"880"},
    {"city_id":"14157","city_name":"Osijek","state_id":"880"},
    {"city_id":"14158","city_name":"Ovchara","state_id":"880"},
    {"city_id":"14159","city_name":"Petlovac","state_id":"880"},
    {"city_id":"14160","city_name":"Petrijevci","state_id":"880"},
    {"city_id":"14161","city_name":"Pishkorevci","state_id":"880"},
    {"city_id":"14162","city_name":"Podgorach","state_id":"880"},
    {"city_id":"14163","city_name":"Popvac","state_id":"880"},
    {"city_id":"14164","city_name":"Rakitovica","state_id":"880"},
    {"city_id":"14165","city_name":"Sarvash","state_id":"880"},
    {"city_id":"14166","city_name":"Satnica Dhakovachka","state_id":"880"},
    {"city_id":"14167","city_name":"Selci Dhakovacki","state_id":"880"},
    {"city_id":"14168","city_name":"Semeljci","state_id":"880"},
    {"city_id":"14169","city_name":"Shiroko Polje","state_id":"880"},
    {"city_id":"14170","city_name":"Strizivojna","state_id":"880"},
    {"city_id":"14171","city_name":"Sveti Dhuradh","state_id":"880"},
    {"city_id":"14172","city_name":"Tenja","state_id":"880"},
    {"city_id":"14173","city_name":"Valpovo","state_id":"880"},
    {"city_id":"14174","city_name":"Velimirovac","state_id":"880"},
    {"city_id":"14175","city_name":"Viljevo","state_id":"880"},
    {"city_id":"14176","city_name":"Vishkovci","state_id":"880"},
    {"city_id":"14177","city_name":"Vishnjevac","state_id":"880"},
    {"city_id":"14178","city_name":"Vladislavci","state_id":"880"},
    {"city_id":"14179","city_name":"Vuka","state_id":"880"},
    {"city_id":"14180","city_name":"Vukojevci","state_id":"880"},
    {"city_id":"14181","city_name":"Zmajevac","state_id":"880"},
    {"city_id":"14182","city_name":"Zoljan","state_id":"880"},
    {"city_id":"14183","city_name":"Badljevina","state_id":"882"},
    {"city_id":"14184","city_name":"Brodski Drenovac","state_id":"882"},
    {"city_id":"14185","city_name":"Dervishaga","state_id":"882"},
    {"city_id":"14186","city_name":"Gradac","state_id":"882"},
    {"city_id":"14187","city_name":"Jakshic","state_id":"882"},
    {"city_id":"14188","city_name":"Kaptol","state_id":"882"},
    {"city_id":"14189","city_name":"Kutjevo","state_id":"882"},
    {"city_id":"14190","city_name":"Lipik","state_id":"882"},
    {"city_id":"14191","city_name":"Pakrac","state_id":"882"},
    {"city_id":"14192","city_name":"Pleternica","state_id":"882"},
    {"city_id":"14193","city_name":"Pozhega","state_id":"882"},
    {"city_id":"14194","city_name":"Prekopkra","state_id":"882"},
    {"city_id":"14195","city_name":"Trenkovo","state_id":"882"},
    {"city_id":"14196","city_name":"Velika","state_id":"882"},
    {"city_id":"14197","city_name":"Vetovo","state_id":"882"},
    {"city_id":"14198","city_name":"Vidovci","state_id":"882"},
    {"city_id":"14199","city_name":"Bilice","state_id":"884"},
    {"city_id":"14200","city_name":"Brodarica","state_id":"884"},
    {"city_id":"14201","city_name":"Drnish","state_id":"884"},
    {"city_id":"14202","city_name":"Dubrava kod Shibenika","state_id":"884"},
    {"city_id":"14203","city_name":"Grebashtica","state_id":"884"},
    {"city_id":"14204","city_name":"Jezera","state_id":"884"},
    {"city_id":"14205","city_name":"Kistanje","state_id":"884"},
    {"city_id":"14206","city_name":"Knin","state_id":"884"},
    {"city_id":"14207","city_name":"Kovachic","state_id":"884"},
    {"city_id":"14208","city_name":"Murter","state_id":"884"},
    {"city_id":"14209","city_name":"Pirovac","state_id":"884"},
    {"city_id":"14210","city_name":"Primoshten","state_id":"884"},
    {"city_id":"14211","city_name":"Rogoznica","state_id":"884"},
    {"city_id":"14212","city_name":"Shibenik","state_id":"884"},
    {"city_id":"14213","city_name":"Skradin","state_id":"884"},
    {"city_id":"14214","city_name":"Tisno","state_id":"884"},
    {"city_id":"14215","city_name":"Tribunj","state_id":"884"},
    {"city_id":"14216","city_name":"Vodice","state_id":"884"},
    {"city_id":"14217","city_name":"Vrpolje","state_id":"884"},
    {"city_id":"14218","city_name":"Zaton","state_id":"884"},
    {"city_id":"14219","city_name":"Brestacha","state_id":"885"},
    {"city_id":"14220","city_name":"Brochice","state_id":"885"},
    {"city_id":"14221","city_name":"Budashevo","state_id":"885"},
    {"city_id":"14222","city_name":"Donja Grachenica","state_id":"885"},
    {"city_id":"14223","city_name":"Dvor","state_id":"885"},
    {"city_id":"14224","city_name":"Glina","state_id":"885"},
    {"city_id":"14225","city_name":"Gornja Grachenica","state_id":"885"},
    {"city_id":"14226","city_name":"Gornja Jelenska","state_id":"885"},
    {"city_id":"14227","city_name":"Greda","state_id":"885"},
    {"city_id":"14228","city_name":"Gvozd","state_id":"885"},
    {"city_id":"14229","city_name":"Hrastelnica","state_id":"885"},
    {"city_id":"14230","city_name":"Hrvatska Dubica","state_id":"885"},
    {"city_id":"14231","city_name":"Hrvatska Kostajnica","state_id":"885"},
    {"city_id":"14232","city_name":"Husain","state_id":"885"},
    {"city_id":"14233","city_name":"Ilova","state_id":"885"},
    {"city_id":"14234","city_name":"Kutina","state_id":"885"},
    {"city_id":"14235","city_name":"Lekenik","state_id":"885"},
    {"city_id":"14236","city_name":"Lipovljani","state_id":"885"},
    {"city_id":"14237","city_name":"Moshchenica","state_id":"885"},
    {"city_id":"14238","city_name":"Novska","state_id":"885"},
    {"city_id":"14239","city_name":"Odra Sisachka","state_id":"885"},
    {"city_id":"14240","city_name":"Osekovo","state_id":"885"},
    {"city_id":"14241","city_name":"Peshcenica","state_id":"885"},
    {"city_id":"14242","city_name":"Petrinja","state_id":"885"},
    {"city_id":"14243","city_name":"Popovacha","state_id":"885"},
    {"city_id":"14244","city_name":"Potok","state_id":"885"},
    {"city_id":"14245","city_name":"Rajic","state_id":"885"},
    {"city_id":"14246","city_name":"Repushnica","state_id":"885"},
    {"city_id":"14247","city_name":"Sisak","state_id":"885"},
    {"city_id":"14248","city_name":"Staro Prachno","state_id":"885"},
    {"city_id":"14249","city_name":"Sunja","state_id":"885"},
    {"city_id":"14250","city_name":"Topolovac","state_id":"885"},
    {"city_id":"14251","city_name":"Voloder","state_id":"885"},
    {"city_id":"14252","city_name":"Bashka Voda","state_id":"887"},
    {"city_id":"14253","city_name":"Bol","state_id":"887"},
    {"city_id":"14254","city_name":"Brela","state_id":"887"},
    {"city_id":"14255","city_name":"Brnaze","state_id":"887"},
    {"city_id":"14256","city_name":"Cista Velika","state_id":"887"},
    {"city_id":"14257","city_name":"Donji Prolozhac","state_id":"887"},
    {"city_id":"14258","city_name":"Donji Vinjani","state_id":"887"},
    {"city_id":"14259","city_name":"Duce","state_id":"887"},
    {"city_id":"14260","city_name":"Dugi Rat","state_id":"887"},
    {"city_id":"14261","city_name":"Dugopolje","state_id":"887"},
    {"city_id":"14262","city_name":"Gala","state_id":"887"},
    {"city_id":"14263","city_name":"Glavice","state_id":"887"},
    {"city_id":"14264","city_name":"Glavina Donja","state_id":"887"},
    {"city_id":"14265","city_name":"Gornji Vincjani","state_id":"887"},
    {"city_id":"14266","city_name":"Gradac","state_id":"887"},
    {"city_id":"14267","city_name":"Grubine","state_id":"887"},
    {"city_id":"14268","city_name":"Hrvace","state_id":"887"},
    {"city_id":"14269","city_name":"Hvar","state_id":"887"},
    {"city_id":"14270","city_name":"Imotski","state_id":"887"},
    {"city_id":"14271","city_name":"Jelsa","state_id":"887"},
    {"city_id":"14272","city_name":"Jesenice","state_id":"887"},
    {"city_id":"14273","city_name":"Kamen","state_id":"887"},
    {"city_id":"14274","city_name":"Kashtel Gambelovac","state_id":"887"},
    {"city_id":"14275","city_name":"Kashtel Gomilica","state_id":"887"},
    {"city_id":"14276","city_name":"Kashtel Lukshic","state_id":"887"},
    {"city_id":"14277","city_name":"Kashtel Novi","state_id":"887"},
    {"city_id":"14278","city_name":"Kashtel Shtafilic","state_id":"887"},
    {"city_id":"14279","city_name":"Kashtel Stari","state_id":"887"},
    {"city_id":"14280","city_name":"Kashtel Sucurac","state_id":"887"},
    {"city_id":"14281","city_name":"Katuni","state_id":"887"},
    {"city_id":"14282","city_name":"Klis","state_id":"887"},
    {"city_id":"14283","city_name":"Komizha","state_id":"887"},
    {"city_id":"14284","city_name":"Koshute","state_id":"887"},
    {"city_id":"14285","city_name":"Lovrec","state_id":"887"},
    {"city_id":"14286","city_name":"Makarska","state_id":"887"},
    {"city_id":"14287","city_name":"Marina","state_id":"887"},
    {"city_id":"14288","city_name":"Mastrinka","state_id":"887"},
    {"city_id":"14289","city_name":"Milna","state_id":"887"},
    {"city_id":"14290","city_name":"Mravince","state_id":"887"},
    {"city_id":"14291","city_name":"Neoric","state_id":"887"},
    {"city_id":"14292","city_name":"Obrovac Sinjski","state_id":"887"},
    {"city_id":"14293","city_name":"Okrug Gornji","state_id":"887"},
    {"city_id":"14294","city_name":"Omish","state_id":"887"},
    {"city_id":"14295","city_name":"Otok","state_id":"887"},
    {"city_id":"14296","city_name":"Podgora","state_id":"887"},
    {"city_id":"14297","city_name":"Podstrana","state_id":"887"},
    {"city_id":"14298","city_name":"Poljica","state_id":"887"},
    {"city_id":"14299","city_name":"Postira","state_id":"887"},
    {"city_id":"14300","city_name":"Postranje","state_id":"887"},
    {"city_id":"14301","city_name":"Potravlje","state_id":"887"},
    {"city_id":"14302","city_name":"Primorski Dolac","state_id":"887"},
    {"city_id":"14303","city_name":"Puchishca","state_id":"887"},
    {"city_id":"14304","city_name":"Ruda","state_id":"887"},
    {"city_id":"14305","city_name":"Runovic","state_id":"887"},
    {"city_id":"14306","city_name":"Seget Donji","state_id":"887"},
    {"city_id":"14307","city_name":"Seget Vranjica","state_id":"887"},
    {"city_id":"14308","city_name":"Selca","state_id":"887"},
    {"city_id":"14309","city_name":"Sinj","state_id":"887"},
    {"city_id":"14310","city_name":"Slatine","state_id":"887"},
    {"city_id":"14311","city_name":"Solin","state_id":"887"},
    {"city_id":"14312","city_name":"Split","state_id":"887"},
    {"city_id":"14313","city_name":"Srinjine","state_id":"887"},
    {"city_id":"14314","city_name":"Stari Grad","state_id":"887"},
    {"city_id":"14315","city_name":"Stobrech","state_id":"887"},
    {"city_id":"14316","city_name":"Supetar","state_id":"887"},
    {"city_id":"14317","city_name":"Trilj","state_id":"887"},
    {"city_id":"14318","city_name":"Trogir","state_id":"887"},
    {"city_id":"14319","city_name":"Tuchepi","state_id":"887"},
    {"city_id":"14320","city_name":"Turjaci","state_id":"887"},
    {"city_id":"14321","city_name":"Vinishce","state_id":"887"},
    {"city_id":"14322","city_name":"Vis","state_id":"887"},
    {"city_id":"14323","city_name":"Vranjic","state_id":"887"},
    {"city_id":"14324","city_name":"Vrgorac","state_id":"887"},
    {"city_id":"14325","city_name":"Vrlika","state_id":"887"},
    {"city_id":"14326","city_name":"Zagvozd","state_id":"887"},
    {"city_id":"14327","city_name":"Zhrnovnica","state_id":"887"},
    {"city_id":"14328","city_name":"Zmijavci","state_id":"887"},
    {"city_id":"14329","city_name":"Bedenec","state_id":"888"},
    {"city_id":"14330","city_name":"Beletinec","state_id":"888"},
    {"city_id":"14331","city_name":"Beretinec","state_id":"888"},
    {"city_id":"14332","city_name":"Breznica","state_id":"888"},
    {"city_id":"14333","city_name":"Chreshnjevo","state_id":"888"},
    {"city_id":"14334","city_name":"Donja Voca","state_id":"888"},
    {"city_id":"14335","city_name":"Donje Ladanje","state_id":"888"},
    {"city_id":"14336","city_name":"Gornje Ladanje","state_id":"888"},
    {"city_id":"14337","city_name":"Gornje Vratno","state_id":"888"},
    {"city_id":"14338","city_name":"Gornji Kneginec","state_id":"888"},
    {"city_id":"14339","city_name":"Gornji Kucan","state_id":"888"},
    {"city_id":"14340","city_name":"Hrashcica","state_id":"888"},
    {"city_id":"14341","city_name":"Hrastovsko","state_id":"888"},
    {"city_id":"14342","city_name":"Hrzhenica","state_id":"888"},
    {"city_id":"14343","city_name":"Ivanec","state_id":"888"},
    {"city_id":"14344","city_name":"Jalkovec","state_id":"888"},
    {"city_id":"14345","city_name":"Jalzhabet","state_id":"888"},
    {"city_id":"14346","city_name":"Jerovec","state_id":"888"},
    {"city_id":"14347","city_name":"Klenovnik","state_id":"888"},
    {"city_id":"14348","city_name":"Kljuch","state_id":"888"},
    {"city_id":"14349","city_name":"Kucan Marof","state_id":"888"},
    {"city_id":"14350","city_name":"Lepoglava","state_id":"888"},
    {"city_id":"14351","city_name":"Ljubeshcica","state_id":"888"},
    {"city_id":"14352","city_name":"Ludbreg","state_id":"888"},
    {"city_id":"14353","city_name":"Madzharevo","state_id":"888"},
    {"city_id":"14354","city_name":"Mali Bukovec","state_id":"888"},
    {"city_id":"14355","city_name":"Nedeljanec","state_id":"888"},
    {"city_id":"14356","city_name":"Nova Ves Petrijanec","state_id":"888"},
    {"city_id":"14357","city_name":"Novi Marof","state_id":"888"},
    {"city_id":"14358","city_name":"Petrijanec","state_id":"888"},
    {"city_id":"14359","city_name":"Podevchevo","state_id":"888"},
    {"city_id":"14360","city_name":"Presechno","state_id":"888"},
    {"city_id":"14361","city_name":"Remetinec","state_id":"888"},
    {"city_id":"14362","city_name":"Selnik","state_id":"888"},
    {"city_id":"14363","city_name":"Shemovec","state_id":"888"},
    {"city_id":"14364","city_name":"Srachinec","state_id":"888"},
    {"city_id":"14365","city_name":"Sveti Petar","state_id":"888"},
    {"city_id":"14366","city_name":"Svibovec Podravski","state_id":"888"},
    {"city_id":"14367","city_name":"Trnovec","state_id":"888"},
    {"city_id":"14368","city_name":"Turchin","state_id":"888"},
    {"city_id":"14369","city_name":"Tuzhno","state_id":"888"},
    {"city_id":"14370","city_name":"Varazhdin","state_id":"888"},
    {"city_id":"14371","city_name":"Varazhdin Breg","state_id":"888"},
    {"city_id":"14372","city_name":"Varazhdinske Toplice","state_id":"888"},
    {"city_id":"14373","city_name":"Vidovec","state_id":"888"},
    {"city_id":"14374","city_name":"Vinica","state_id":"888"},
    {"city_id":"14375","city_name":"Zavrshje Podbelsko","state_id":"888"},
    {"city_id":"14376","city_name":"Zharovnica","state_id":"888"},
    {"city_id":"14377","city_name":"Borova","state_id":"889"},
    {"city_id":"14378","city_name":"Busetina","state_id":"889"},
    {"city_id":"14379","city_name":"Cabuna","state_id":"889"},
    {"city_id":"14380","city_name":"Chachinci","state_id":"889"},
    {"city_id":"14381","city_name":"Gradina","state_id":"889"},
    {"city_id":"14382","city_name":"Korija","state_id":"889"},
    {"city_id":"14383","city_name":"Mikleush","state_id":"889"},
    {"city_id":"14384","city_name":"Milanovac","state_id":"889"},
    {"city_id":"14385","city_name":"Nova Bukovica","state_id":"889"},
    {"city_id":"14386","city_name":"Orahovica","state_id":"889"},
    {"city_id":"14387","city_name":"Pitomacha","state_id":"889"},
    {"city_id":"14388","city_name":"Podgorje","state_id":"889"},
    {"city_id":"14389","city_name":"Rezovac","state_id":"889"},
    {"city_id":"14390","city_name":"Shpishic Bukovica","state_id":"889"},
    {"city_id":"14391","city_name":"Slatina","state_id":"889"},
    {"city_id":"14392","city_name":"Suhopolje","state_id":"889"},
    {"city_id":"14393","city_name":"Turanovac","state_id":"889"},
    {"city_id":"14394","city_name":"Virovitica","state_id":"889"},
    {"city_id":"14395","city_name":"Vocin","state_id":"889"},
    {"city_id":"14396","city_name":"Zdenci","state_id":"889"},
    {"city_id":"14397","city_name":"Andrijashevci","state_id":"890"},
    {"city_id":"14398","city_name":"Antin","state_id":"890"},
    {"city_id":"14399","city_name":"Babina Greda","state_id":"890"},
    {"city_id":"14400","city_name":"Bapska","state_id":"890"},
    {"city_id":"14401","city_name":"Bobota","state_id":"890"},
    {"city_id":"14402","city_name":"Bogdanovci","state_id":"890"},
    {"city_id":"14403","city_name":"Borovo","state_id":"890"},
    {"city_id":"14404","city_name":"Boshnjaci","state_id":"890"},
    {"city_id":"14405","city_name":"Brshadin","state_id":"890"},
    {"city_id":"14406","city_name":"Ceric","state_id":"890"},
    {"city_id":"14407","city_name":"Cerna","state_id":"890"},
    {"city_id":"14408","city_name":"Drenovci","state_id":"890"},
    {"city_id":"14409","city_name":"Gradishte","state_id":"890"},
    {"city_id":"14410","city_name":"Gunja","state_id":"890"},
    {"city_id":"14411","city_name":"Ilacha","state_id":"890"},
    {"city_id":"14412","city_name":"Ilok","state_id":"890"},
    {"city_id":"14413","city_name":"Ivankovo","state_id":"890"},
    {"city_id":"14414","city_name":"Jarmina","state_id":"890"},
    {"city_id":"14415","city_name":"Komletinci","state_id":"890"},
    {"city_id":"14416","city_name":"Lipovac","state_id":"890"},
    {"city_id":"14417","city_name":"Lovas","state_id":"890"},
    {"city_id":"14418","city_name":"Markushica","state_id":"890"},
    {"city_id":"14419","city_name":"Mirkovci","state_id":"890"},
    {"city_id":"14420","city_name":"Negoslavci","state_id":"890"},
    {"city_id":"14421","city_name":"Nijemci","state_id":"890"},
    {"city_id":"14422","city_name":"Novi Jankovci","state_id":"890"},
    {"city_id":"14423","city_name":"Nushtar","state_id":"890"},
    {"city_id":"14424","city_name":"Otok","state_id":"890"},
    {"city_id":"14425","city_name":"Petrovci","state_id":"890"},
    {"city_id":"14426","city_name":"Posavski Podgajci","state_id":"890"},
    {"city_id":"14427","city_name":"Privlaka","state_id":"890"},
    {"city_id":"14428","city_name":"Rachinovci","state_id":"890"},
    {"city_id":"14429","city_name":"Rajevo Selo","state_id":"890"},
    {"city_id":"14430","city_name":"Retkovci","state_id":"890"},
    {"city_id":"14431","city_name":"Rokovci","state_id":"890"},
    {"city_id":"14432","city_name":"Sharengrad","state_id":"890"},
    {"city_id":"14433","city_name":"Shishkovci","state_id":"890"},
    {"city_id":"14434","city_name":"Shtitar","state_id":"890"},
    {"city_id":"14435","city_name":"Slakovci","state_id":"890"},
    {"city_id":"14436","city_name":"Soljani","state_id":"890"},
    {"city_id":"14437","city_name":"Sotin","state_id":"890"},
    {"city_id":"14438","city_name":"Stari Jankovci","state_id":"890"},
    {"city_id":"14439","city_name":"Stari Mikanovci","state_id":"890"},
    {"city_id":"14440","city_name":"Tordinci","state_id":"890"},
    {"city_id":"14441","city_name":"Tovarnik","state_id":"890"},
    {"city_id":"14442","city_name":"Trpinja","state_id":"890"},
    {"city_id":"14443","city_name":"Vinkovci","state_id":"890"},
    {"city_id":"14444","city_name":"Vodhinci","state_id":"890"},
    {"city_id":"14445","city_name":"Vrbanja","state_id":"890"},
    {"city_id":"14446","city_name":"Vukovar","state_id":"890"},
    {"city_id":"14447","city_name":"Zhupanja","state_id":"890"},
    {"city_id":"14448","city_name":"Benkovac","state_id":"891"},
    {"city_id":"14449","city_name":"Bibinje","state_id":"891"},
    {"city_id":"14450","city_name":"Biograd na Moru","state_id":"891"},
    {"city_id":"14451","city_name":"Debeljak","state_id":"891"},
    {"city_id":"14452","city_name":"Galovac","state_id":"891"},
    {"city_id":"14453","city_name":"Gorica","state_id":"891"},
    {"city_id":"14454","city_name":"Gornji Karin","state_id":"891"},
    {"city_id":"14455","city_name":"Grachac","state_id":"891"},
    {"city_id":"14456","city_name":"Jasenice","state_id":"891"},
    {"city_id":"14457","city_name":"Kali","state_id":"891"},
    {"city_id":"14458","city_name":"Krushevo","state_id":"891"},
    {"city_id":"14459","city_name":"Nin","state_id":"891"},
    {"city_id":"14460","city_name":"Obrovac","state_id":"891"},
    {"city_id":"14461","city_name":"Pag","state_id":"891"},
    {"city_id":"14462","city_name":"Pakoshtane","state_id":"891"},
    {"city_id":"14463","city_name":"Polacha","state_id":"891"},
    {"city_id":"14464","city_name":"Polichnik","state_id":"891"},
    {"city_id":"14465","city_name":"Poljica","state_id":"891"},
    {"city_id":"14466","city_name":"Posedarje","state_id":"891"},
    {"city_id":"14467","city_name":"Preko","state_id":"891"},
    {"city_id":"14468","city_name":"Pridraga","state_id":"891"},
    {"city_id":"14469","city_name":"Privlaka","state_id":"891"},
    {"city_id":"14470","city_name":"Razhanac","state_id":"891"},
    {"city_id":"14471","city_name":"Shkabrnja","state_id":"891"},
    {"city_id":"14472","city_name":"Slivnica","state_id":"891"},
    {"city_id":"14473","city_name":"Starigrad","state_id":"891"},
    {"city_id":"14474","city_name":"Sukoshan","state_id":"891"},
    {"city_id":"14475","city_name":"Sveti Filip i Jakov","state_id":"891"},
    {"city_id":"14476","city_name":"Turanj","state_id":"891"},
    {"city_id":"14477","city_name":"Ugljan","state_id":"891"},
    {"city_id":"14478","city_name":"Vir","state_id":"891"},
    {"city_id":"14479","city_name":"Vrsi","state_id":"891"},
    {"city_id":"14480","city_name":"Zadar","state_id":"891"},
    {"city_id":"14481","city_name":"Zemunik Donji","state_id":"891"},
    {"city_id":"14482","city_name":"Bestovje","state_id":"892"},
    {"city_id":"14483","city_name":"Bishkupec Zelinski","state_id":"892"},
    {"city_id":"14484","city_name":"Brckovljani","state_id":"892"},
    {"city_id":"14485","city_name":"Brdovec","state_id":"892"},
    {"city_id":"14486","city_name":"Bregana","state_id":"892"},
    {"city_id":"14487","city_name":"Brezje","state_id":"892"},
    {"city_id":"14488","city_name":"Bushevec","state_id":"892"},
    {"city_id":"14489","city_name":"Celine","state_id":"892"},
    {"city_id":"14490","city_name":"Domaslovec","state_id":"892"},
    {"city_id":"14491","city_name":"Donja Bistra","state_id":"892"},
    {"city_id":"14492","city_name":"Donja Kupchina","state_id":"892"},
    {"city_id":"14493","city_name":"Donja Lomnica","state_id":"892"},
    {"city_id":"14494","city_name":"Donja Zdenchina","state_id":"892"},
    {"city_id":"14495","city_name":"Donji Desinec","state_id":"892"},
    {"city_id":"14496","city_name":"Donji Stupnik","state_id":"892"},
    {"city_id":"14497","city_name":"Dubrava","state_id":"892"},
    {"city_id":"14498","city_name":"Dugo Selo","state_id":"892"},
    {"city_id":"14499","city_name":"Gornja Bistra","state_id":"892"},
    {"city_id":"14500","city_name":"Gornji Laduch","state_id":"892"},
    {"city_id":"14501","city_name":"Gornji Stupnik","state_id":"892"},
    {"city_id":"14502","city_name":"Grachec","state_id":"892"},
    {"city_id":"14503","city_name":"Gradici","state_id":"892"},
    {"city_id":"14504","city_name":"Ivan Bistranski","state_id":"892"},
    {"city_id":"14505","city_name":"Ivanic-Grad","state_id":"892"},
    {"city_id":"14506","city_name":"Jablanovec","state_id":"892"},
    {"city_id":"14507","city_name":"Jakovlje","state_id":"892"},
    {"city_id":"14508","city_name":"Jastrebarsko","state_id":"892"},
    {"city_id":"14509","city_name":"Kerestinec","state_id":"892"},
    {"city_id":"14510","city_name":"Klincha Sela","state_id":"892"},
    {"city_id":"14511","city_name":"Kloshtar Ivanic","state_id":"892"},
    {"city_id":"14512","city_name":"Kozinshchak","state_id":"892"},
    {"city_id":"14513","city_name":"Krizh","state_id":"892"},
    {"city_id":"14514","city_name":"Kuche","state_id":"892"},
    {"city_id":"14515","city_name":"Kupinec","state_id":"892"},
    {"city_id":"14516","city_name":"Lonjica","state_id":"892"},
    {"city_id":"14517","city_name":"Luka","state_id":"892"},
    {"city_id":"14518","city_name":"Lukarishce","state_id":"892"},
    {"city_id":"14519","city_name":"Lukavec","state_id":"892"},
    {"city_id":"14520","city_name":"Lupoglav","state_id":"892"},
    {"city_id":"14521","city_name":"Michevec","state_id":"892"},
    {"city_id":"14522","city_name":"Mraclin","state_id":"892"},
    {"city_id":"14523","city_name":"Novaki","state_id":"892"},
    {"city_id":"14524","city_name":"Novo Chiche","state_id":"892"},
    {"city_id":"14525","city_name":"Novoselec","state_id":"892"},
    {"city_id":"14526","city_name":"Oborovo Bistranski","state_id":"892"},
    {"city_id":"14527","city_name":"Oreshje","state_id":"892"},
    {"city_id":"14528","city_name":"Pojatno","state_id":"892"},
    {"city_id":"14529","city_name":"Poljanica Bistranska","state_id":"892"},
    {"city_id":"14530","city_name":"Prigorje Brdovechko","state_id":"892"},
    {"city_id":"14531","city_name":"Rakitje","state_id":"892"},
    {"city_id":"14532","city_name":"Rakov Potok","state_id":"892"},
    {"city_id":"14533","city_name":"Rude","state_id":"892"},
    {"city_id":"14534","city_name":"Samobor","state_id":"892"},
    {"city_id":"14535","city_name":"Strmec","state_id":"892"},
    {"city_id":"14536","city_name":"Sveta Nedelja","state_id":"892"},
    {"city_id":"14537","city_name":"Sveti Ivan Zelina","state_id":"892"},
    {"city_id":"14538","city_name":"Turopolje","state_id":"892"},
    {"city_id":"14539","city_name":"Velika Gorica","state_id":"892"},
    {"city_id":"14540","city_name":"Velika Mlaka","state_id":"892"},
    {"city_id":"14541","city_name":"Velika Ostrna","state_id":"892"},
    {"city_id":"14542","city_name":"Vrbovec","state_id":"892"},
    {"city_id":"14543","city_name":"Vukovina","state_id":"892"},
    {"city_id":"14544","city_name":"Zapreshic","state_id":"892"},
    {"city_id":"14545","city_name":"Zdenci Brdovechki","state_id":"892"},
    {"city_id":"14546","city_name":"Camaguey","state_id":"893"},
    {"city_id":"14547","city_name":"Caney","state_id":"893"},
    {"city_id":"14548","city_name":"Carlos Manuel de Cespedes","state_id":"893"},
    {"city_id":"14549","city_name":"Esmeralda","state_id":"893"},
    {"city_id":"14550","city_name":"Florida","state_id":"893"},
    {"city_id":"14551","city_name":"Guaimaro","state_id":"893"},
    {"city_id":"14552","city_name":"Minas","state_id":"893"},
    {"city_id":"14553","city_name":"Nuevitas","state_id":"893"},
    {"city_id":"14554","city_name":"Santa Cruz del Sur","state_id":"893"},
    {"city_id":"14555","city_name":"Sibanicu","state_id":"893"},
    {"city_id":"14556","city_name":"Vertientes","state_id":"893"},
    {"city_id":"14557","city_name":"Cienfuegos","state_id":"895"},
    {"city_id":"14558","city_name":"Cruces","state_id":"895"},
    {"city_id":"14559","city_name":"Cumanayagua","state_id":"895"},
    {"city_id":"14560","city_name":"Palmira","state_id":"895"},
    {"city_id":"14561","city_name":"Rodas","state_id":"895"},
    {"city_id":"14562","city_name":"Bayamo","state_id":"897"},
    {"city_id":"14563","city_name":"Campechuela","state_id":"897"},
    {"city_id":"14564","city_name":"Guisa","state_id":"897"},
    {"city_id":"14565","city_name":"Jiguani","state_id":"897"},
    {"city_id":"14566","city_name":"Manzanillo","state_id":"897"},
    {"city_id":"14567","city_name":"Media Luna","state_id":"897"},
    {"city_id":"14568","city_name":"Niquero","state_id":"897"},
    {"city_id":"14569","city_name":"Pilon","state_id":"897"},
    {"city_id":"14570","city_name":"Rio Cauto","state_id":"897"},
    {"city_id":"14571","city_name":"Yara","state_id":"897"},
    {"city_id":"14572","city_name":"Baracoa","state_id":"898"},
    {"city_id":"14573","city_name":"Guantanamo","state_id":"898"},
    {"city_id":"14574","city_name":"Yateras","state_id":"898"},
    {"city_id":"14575","city_name":"Havana","state_id":"899"},
    {"city_id":"14576","city_name":"Antilla","state_id":"900"},
    {"city_id":"14577","city_name":"Baguanos","state_id":"900"},
    {"city_id":"14578","city_name":"Banes","state_id":"900"},
    {"city_id":"14579","city_name":"Cacocum","state_id":"900"},
    {"city_id":"14580","city_name":"Cauto Cristo","state_id":"900"},
    {"city_id":"14581","city_name":"Cueto","state_id":"900"},
    {"city_id":"14582","city_name":"Gibara","state_id":"900"},
    {"city_id":"14583","city_name":"Holguin","state_id":"900"},
    {"city_id":"14584","city_name":"Jobabo","state_id":"900"},
    {"city_id":"14585","city_name":"Moa","state_id":"900"},
    {"city_id":"14586","city_name":"Sagua de Tanamo","state_id":"900"},
    {"city_id":"14587","city_name":"Abreus","state_id":"904"},
    {"city_id":"14588","city_name":"Agramonte","state_id":"904"},
    {"city_id":"14589","city_name":"Aguacate","state_id":"904"},
    {"city_id":"14590","city_name":"Aguada de Pasajeros","state_id":"904"},
    {"city_id":"14591","city_name":"Alacranes","state_id":"904"},
    {"city_id":"14592","city_name":"Bolondron","state_id":"904"},
    {"city_id":"14593","city_name":"Calimete","state_id":"904"},
    {"city_id":"14594","city_name":"Cardenas","state_id":"904"},
    {"city_id":"14595","city_name":"Carlos Rojas","state_id":"904"},
    {"city_id":"14596","city_name":"Colon","state_id":"904"},
    {"city_id":"14597","city_name":"Corralillo","state_id":"904"},
    {"city_id":"14598","city_name":"Jaguey Grande","state_id":"904"},
    {"city_id":"14599","city_name":"Jovellanos","state_id":"904"},
    {"city_id":"14600","city_name":"Juan Gualberto Gomez","state_id":"904"},
    {"city_id":"14601","city_name":"Los Arabos","state_id":"904"},
    {"city_id":"14602","city_name":"Manguito","state_id":"904"},
    {"city_id":"14603","city_name":"Marti","state_id":"904"},
    {"city_id":"14604","city_name":"Matanzas","state_id":"904"},
    {"city_id":"14605","city_name":"Maximo Gomez","state_id":"904"},
    {"city_id":"14606","city_name":"Pedro Betancourt","state_id":"904"},
    {"city_id":"14607","city_name":"Perico","state_id":"904"},
    {"city_id":"14608","city_name":"Union de Reyes","state_id":"904"},
    {"city_id":"14609","city_name":"Varadero","state_id":"904"},
    {"city_id":"14610","city_name":"Limassol","state_id":"910"},
    {"city_id":"14611","city_name":"Paphos","state_id":"912"},
    {"city_id":"14612","city_name":"Frycovice","state_id":"915"},
    {"city_id":"14613","city_name":"Bechyne","state_id":"917"},
    {"city_id":"14614","city_name":"Blatna","state_id":"917"},
    {"city_id":"14615","city_name":"Cheske Budejovice","state_id":"917"},
    {"city_id":"14616","city_name":"Chesky Krumlov","state_id":"917"},
    {"city_id":"14617","city_name":"Dachice","state_id":"917"},
    {"city_id":"14618","city_name":"Jindrichuv Hradec","state_id":"917"},
    {"city_id":"14619","city_name":"Kaplice","state_id":"917"},
    {"city_id":"14620","city_name":"Milevsko","state_id":"917"},
    {"city_id":"14621","city_name":"Pisek","state_id":"917"},
    {"city_id":"14622","city_name":"Prachatice","state_id":"917"},
    {"city_id":"14623","city_name":"Protivin","state_id":"917"},
    {"city_id":"14624","city_name":"Sezimovo Usti","state_id":"917"},
    {"city_id":"14625","city_name":"Sobeslav","state_id":"917"},
    {"city_id":"14626","city_name":"Strakonice","state_id":"917"},
    {"city_id":"14627","city_name":"Tabor","state_id":"917"},
    {"city_id":"14628","city_name":"Trebon","state_id":"917"},
    {"city_id":"14629","city_name":"Tyn nad Vltavou","state_id":"917"},
    {"city_id":"14630","city_name":"Veseli nad Luzhnici","state_id":"917"},
    {"city_id":"14631","city_name":"Vimperk","state_id":"917"},
    {"city_id":"14632","city_name":"Vodnany","state_id":"917"},
    {"city_id":"14633","city_name":"Adamov","state_id":"918"},
    {"city_id":"14634","city_name":"Blansko","state_id":"918"},
    {"city_id":"14635","city_name":"Boskovice","state_id":"918"},
    {"city_id":"14636","city_name":"Breclav","state_id":"918"},
    {"city_id":"14637","city_name":"Brno","state_id":"918"},
    {"city_id":"14638","city_name":"Buchovice","state_id":"918"},
    {"city_id":"14639","city_name":"Dubnany","state_id":"918"},
    {"city_id":"14640","city_name":"Hodonin","state_id":"918"},
    {"city_id":"14641","city_name":"Hrusky","state_id":"918"},
    {"city_id":"14642","city_name":"Hustopeche","state_id":"918"},
    {"city_id":"14643","city_name":"Ivanchice","state_id":"918"},
    {"city_id":"14644","city_name":"Kurim","state_id":"918"},
    {"city_id":"14645","city_name":"Kyjov","state_id":"918"},
    {"city_id":"14646","city_name":"Letovice","state_id":"918"},
    {"city_id":"14647","city_name":"Mikulov","state_id":"918"},
    {"city_id":"14648","city_name":"Moravsky Krumlov","state_id":"918"},
    {"city_id":"14649","city_name":"Namesht' nad Oslavou","state_id":"918"},
    {"city_id":"14650","city_name":"Rosice","state_id":"918"},
    {"city_id":"14651","city_name":"Shlapanice","state_id":"918"},
    {"city_id":"14652","city_name":"Slavkov u Brna","state_id":"918"},
    {"city_id":"14653","city_name":"Tishnov","state_id":"918"},
    {"city_id":"14654","city_name":"Vyshkov","state_id":"918"},
    {"city_id":"14655","city_name":"Znojmo","state_id":"918"},
    {"city_id":"14656","city_name":"Ash","state_id":"919"},
    {"city_id":"14657","city_name":"Bozicany","state_id":"919"},
    {"city_id":"14658","city_name":"Cheb","state_id":"919"},
    {"city_id":"14659","city_name":"Chodov","state_id":"919"},
    {"city_id":"14660","city_name":"Frantishkovy Lazne","state_id":"919"},
    {"city_id":"14661","city_name":"Horni Slavkov","state_id":"919"},
    {"city_id":"14662","city_name":"Karlovy Vary","state_id":"919"},
    {"city_id":"14663","city_name":"Kraslice","state_id":"919"},
    {"city_id":"14664","city_name":"Kynshperk nad Ohri","state_id":"919"},
    {"city_id":"14665","city_name":"Marianske Lazne","state_id":"919"},
    {"city_id":"14666","city_name":"Nejdek","state_id":"919"},
    {"city_id":"14667","city_name":"Ostrov","state_id":"919"},
    {"city_id":"14668","city_name":"Sokolov","state_id":"919"},
    {"city_id":"14669","city_name":"Klecany","state_id":"920"},
    {"city_id":"14670","city_name":"Broumov","state_id":"921"},
    {"city_id":"14671","city_name":"Cherveny Kostelec","state_id":"921"},
    {"city_id":"14672","city_name":"Cheska Skalice","state_id":"921"},
    {"city_id":"14673","city_name":"Chlumec nad Cidlinou","state_id":"921"},
    {"city_id":"14674","city_name":"Dobrushka","state_id":"921"},
    {"city_id":"14675","city_name":"Dvur Kralove","state_id":"921"},
    {"city_id":"14676","city_name":"Habartov","state_id":"921"},
    {"city_id":"14677","city_name":"Holice","state_id":"921"},
    {"city_id":"14678","city_name":"Horice","state_id":"921"},
    {"city_id":"14679","city_name":"Hostinne","state_id":"921"},
    {"city_id":"14680","city_name":"Hradec Kralove","state_id":"921"},
    {"city_id":"14681","city_name":"Hronov","state_id":"921"},
    {"city_id":"14682","city_name":"Jaromer","state_id":"921"},
    {"city_id":"14683","city_name":"Jichin","state_id":"921"},
    {"city_id":"14684","city_name":"Kostelec nad Orlici","state_id":"921"},
    {"city_id":"14685","city_name":"Nachod","state_id":"921"},
    {"city_id":"14686","city_name":"Nova Paka","state_id":"921"},
    {"city_id":"14687","city_name":"Nove Mesto nad Metuji","state_id":"921"},
    {"city_id":"14688","city_name":"Novy Bydzhov","state_id":"921"},
    {"city_id":"14689","city_name":"Rychnov nad Knezhnou","state_id":"921"},
    {"city_id":"14690","city_name":"Trebechovice pod Orebem","state_id":"921"},
    {"city_id":"14691","city_name":"Trutnov","state_id":"921"},
    {"city_id":"14692","city_name":"Tynishte nad Orlici","state_id":"921"},
    {"city_id":"14693","city_name":"Upice","state_id":"921"},
    {"city_id":"14694","city_name":"Vrchlabi","state_id":"921"},
    {"city_id":"14695","city_name":"Ceska Lipa","state_id":"922"},
    {"city_id":"14696","city_name":"Cheska Kamenice","state_id":"922"},
    {"city_id":"14697","city_name":"Cheska Lipa","state_id":"922"},
    {"city_id":"14698","city_name":"Chrastava","state_id":"922"},
    {"city_id":"14699","city_name":"Doksy","state_id":"922"},
    {"city_id":"14700","city_name":"Frydlant","state_id":"922"},
    {"city_id":"14701","city_name":"Hradek","state_id":"922"},
    {"city_id":"14702","city_name":"Jablonec","state_id":"922"},
    {"city_id":"14703","city_name":"Jilemnice","state_id":"922"},
    {"city_id":"14704","city_name":"Liberec","state_id":"922"},
    {"city_id":"14705","city_name":"Lomnice nad Popelkou","state_id":"922"},
    {"city_id":"14706","city_name":"Mimon","state_id":"922"},
    {"city_id":"14707","city_name":"Novy Bor","state_id":"922"},
    {"city_id":"14708","city_name":"Semily","state_id":"922"},
    {"city_id":"14709","city_name":"Tanvald","state_id":"922"},
    {"city_id":"14710","city_name":"Turnov","state_id":"922"},
    {"city_id":"14711","city_name":"Zhelezny Brod","state_id":"922"},
    {"city_id":"14712","city_name":"Lipov","state_id":"923"},
    {"city_id":"14713","city_name":"Bilovec","state_id":"924"},
    {"city_id":"14714","city_name":"Bohumin","state_id":"924"},
    {"city_id":"14715","city_name":"Bruntal","state_id":"924"},
    {"city_id":"14716","city_name":"Chesky Teshin","state_id":"924"},
    {"city_id":"14717","city_name":"Frenshtat","state_id":"924"},
    {"city_id":"14718","city_name":"Frydek-Mistek","state_id":"924"},
    {"city_id":"14719","city_name":"Frydlant nad Ostravici","state_id":"924"},
    {"city_id":"14720","city_name":"Fulnek","state_id":"924"},
    {"city_id":"14721","city_name":"Havirov","state_id":"924"},
    {"city_id":"14722","city_name":"Hluchin","state_id":"924"},
    {"city_id":"14723","city_name":"Hradec nad Moravice","state_id":"924"},
    {"city_id":"14724","city_name":"Jablunkov","state_id":"924"},
    {"city_id":"14725","city_name":"Karvina","state_id":"924"},
    {"city_id":"14726","city_name":"Koprivnice","state_id":"924"},
    {"city_id":"14727","city_name":"Kravare","state_id":"924"},
    {"city_id":"14728","city_name":"Krnov","state_id":"924"},
    {"city_id":"14729","city_name":"Novy Jichin","state_id":"924"},
    {"city_id":"14730","city_name":"Odry","state_id":"924"},
    {"city_id":"14731","city_name":"Opava","state_id":"924"},
    {"city_id":"14732","city_name":"Orlova","state_id":"924"},
    {"city_id":"14733","city_name":"Ostrava","state_id":"924"},
    {"city_id":"14734","city_name":"Petrvald","state_id":"924"},
    {"city_id":"14735","city_name":"Pribor","state_id":"924"},
    {"city_id":"14736","city_name":"Rychvald","state_id":"924"},
    {"city_id":"14737","city_name":"Rymarov","state_id":"924"},
    {"city_id":"14738","city_name":"Shenov","state_id":"924"},
    {"city_id":"14739","city_name":"Studenka","state_id":"924"},
    {"city_id":"14740","city_name":"Trinec","state_id":"924"},
    {"city_id":"14741","city_name":"Vitkov","state_id":"924"},
    {"city_id":"14742","city_name":"Vratimov","state_id":"924"},
    {"city_id":"14743","city_name":"Vrbno pod Pradedem","state_id":"924"},
    {"city_id":"14744","city_name":"Hranice","state_id":"925"},
    {"city_id":"14745","city_name":"Jesenik","state_id":"925"},
    {"city_id":"14746","city_name":"Kojetin","state_id":"925"},
    {"city_id":"14747","city_name":"Lipnik nad Becvou","state_id":"925"},
    {"city_id":"14748","city_name":"Litovel","state_id":"925"},
    {"city_id":"14749","city_name":"Mohelnice","state_id":"925"},
    {"city_id":"14750","city_name":"Olomouc","state_id":"925"},
    {"city_id":"14751","city_name":"Prerov","state_id":"925"},
    {"city_id":"14752","city_name":"Prostejov","state_id":"925"},
    {"city_id":"14753","city_name":"Shternberk","state_id":"925"},
    {"city_id":"14754","city_name":"Shumperk","state_id":"925"},
    {"city_id":"14755","city_name":"Unichov","state_id":"925"},
    {"city_id":"14756","city_name":"Zabreh","state_id":"925"},
    {"city_id":"14757","city_name":"Hranice","state_id":"926"},
    {"city_id":"14758","city_name":"Jesenik","state_id":"926"},
    {"city_id":"14759","city_name":"Kojetin","state_id":"926"},
    {"city_id":"14760","city_name":"Lipnik nad Becvou","state_id":"926"},
    {"city_id":"14761","city_name":"Litovel","state_id":"926"},
    {"city_id":"14762","city_name":"Mohelnice","state_id":"926"},
    {"city_id":"14763","city_name":"Olomouc","state_id":"926"},
    {"city_id":"14764","city_name":"Prerov","state_id":"926"},
    {"city_id":"14765","city_name":"Prostejov","state_id":"926"},
    {"city_id":"14766","city_name":"Shternberk","state_id":"926"},
    {"city_id":"14767","city_name":"Shumperk","state_id":"926"},
    {"city_id":"14768","city_name":"Unichov","state_id":"926"},
    {"city_id":"14769","city_name":"Zabreh","state_id":"926"},
    {"city_id":"14770","city_name":"Cheska Trebova","state_id":"927"},
    {"city_id":"14771","city_name":"Chocen","state_id":"927"},
    {"city_id":"14772","city_name":"Chrudim","state_id":"927"},
    {"city_id":"14773","city_name":"Chvaletice","state_id":"927"},
    {"city_id":"14774","city_name":"Hermanuv Mestec","state_id":"927"},
    {"city_id":"14775","city_name":"Hlinsko","state_id":"927"},
    {"city_id":"14776","city_name":"Lanshkroun","state_id":"927"},
    {"city_id":"14777","city_name":"Letohrad","state_id":"927"},
    {"city_id":"14778","city_name":"Litomyshl","state_id":"927"},
    {"city_id":"14779","city_name":"Moravska Trebova","state_id":"927"},
    {"city_id":"14780","city_name":"Pardubice","state_id":"927"},
    {"city_id":"14781","city_name":"Polichka","state_id":"927"},
    {"city_id":"14782","city_name":"Policka","state_id":"927"},
    {"city_id":"14783","city_name":"Prelouch","state_id":"927"},
    {"city_id":"14784","city_name":"Skutech","state_id":"927"},
    {"city_id":"14785","city_name":"Svitavy","state_id":"927"},
    {"city_id":"14786","city_name":"Usti nad Orlici","state_id":"927"},
    {"city_id":"14787","city_name":"Vysoke Myto","state_id":"927"},
    {"city_id":"14788","city_name":"Zhamberk","state_id":"927"},
    {"city_id":"14789","city_name":"Dobrany","state_id":"928"},
    {"city_id":"14790","city_name":"Domazhlice","state_id":"928"},
    {"city_id":"14791","city_name":"Horazhd'ovice","state_id":"928"},
    {"city_id":"14792","city_name":"Horshovky Tyn","state_id":"928"},
    {"city_id":"14793","city_name":"Kdyne","state_id":"928"},
    {"city_id":"14794","city_name":"Klatovy","state_id":"928"},
    {"city_id":"14795","city_name":"Nyrany","state_id":"928"},
    {"city_id":"14796","city_name":"Nyrsko","state_id":"928"},
    {"city_id":"14797","city_name":"Plana","state_id":"928"},
    {"city_id":"14798","city_name":"Plzen","state_id":"928"},
    {"city_id":"14799","city_name":"Preshtice","state_id":"928"},
    {"city_id":"14800","city_name":"Radnice","state_id":"928"},
    {"city_id":"14801","city_name":"Rokycany","state_id":"928"},
    {"city_id":"14802","city_name":"Stribro","state_id":"928"},
    {"city_id":"14803","city_name":"Sushice","state_id":"928"},
    {"city_id":"14804","city_name":"Tachov","state_id":"928"},
    {"city_id":"14805","city_name":"Prague","state_id":"929"},
    {"city_id":"14806","city_name":"Praha","state_id":"929"},
    {"city_id":"14807","city_name":"Rajhrad","state_id":"930"},
    {"city_id":"14808","city_name":"Smirice","state_id":"931"},
    {"city_id":"14809","city_name":"Benatky nad Jizerou","state_id":"934"},
    {"city_id":"14810","city_name":"Beneshov","state_id":"934"},
    {"city_id":"14811","city_name":"Beroun","state_id":"934"},
    {"city_id":"14812","city_name":"Brandys nad Labem-Stara Bolesl","state_id":"934"},
    {"city_id":"14813","city_name":"Chaslav","state_id":"934"},
    {"city_id":"14814","city_name":"Chavaletice","state_id":"934"},
    {"city_id":"14815","city_name":"Chelakovice","state_id":"934"},
    {"city_id":"14816","city_name":"Chesky Brod","state_id":"934"},
    {"city_id":"14817","city_name":"Dobrish","state_id":"934"},
    {"city_id":"14818","city_name":"Horovice","state_id":"934"},
    {"city_id":"14819","city_name":"Kladno","state_id":"934"},
    {"city_id":"14820","city_name":"Kolin","state_id":"934"},
    {"city_id":"14821","city_name":"Kralupy nad Vltavou","state_id":"934"},
    {"city_id":"14822","city_name":"Kutna Hora","state_id":"934"},
    {"city_id":"14823","city_name":"Lysa nad Labem","state_id":"934"},
    {"city_id":"14824","city_name":"Melnik","state_id":"934"},
    {"city_id":"14825","city_name":"Mlada Boleslav","state_id":"934"},
    {"city_id":"14826","city_name":"Mnichovo Hradishte","state_id":"934"},
    {"city_id":"14827","city_name":"Neratovice","state_id":"934"},
    {"city_id":"14828","city_name":"Nove Strasheci","state_id":"934"},
    {"city_id":"14829","city_name":"Nymburk","state_id":"934"},
    {"city_id":"14830","city_name":"Podebrady","state_id":"934"},
    {"city_id":"14831","city_name":"Pribram","state_id":"934"},
    {"city_id":"14832","city_name":"Rakovnik","state_id":"934"},
    {"city_id":"14833","city_name":"Richany","state_id":"934"},
    {"city_id":"14834","city_name":"Rousinov","state_id":"934"},
    {"city_id":"14835","city_name":"Roztoky","state_id":"934"},
    {"city_id":"14836","city_name":"Sedlcany","state_id":"934"},
    {"city_id":"14837","city_name":"Slany","state_id":"934"},
    {"city_id":"14838","city_name":"Stochov","state_id":"934"},
    {"city_id":"14839","city_name":"Vlashim","state_id":"934"},
    {"city_id":"14840","city_name":"Zruch nad Sazavou","state_id":"934"},
    {"city_id":"14841","city_name":"Unicov","state_id":"935"},
    {"city_id":"14842","city_name":"Bilina","state_id":"936"},
    {"city_id":"14843","city_name":"Chomutov","state_id":"936"},
    {"city_id":"14844","city_name":"Dechin","state_id":"936"},
    {"city_id":"14845","city_name":"Dubi","state_id":"936"},
    {"city_id":"14846","city_name":"Duchcov","state_id":"936"},
    {"city_id":"14847","city_name":"Jilove","state_id":"936"},
    {"city_id":"14848","city_name":"Jirkov","state_id":"936"},
    {"city_id":"14849","city_name":"Kadan","state_id":"936"},
    {"city_id":"14850","city_name":"Klasterec nad Ohri","state_id":"936"},
    {"city_id":"14851","city_name":"Krupka","state_id":"936"},
    {"city_id":"14852","city_name":"Litomerice","state_id":"936"},
    {"city_id":"14853","city_name":"Litvinov","state_id":"936"},
    {"city_id":"14854","city_name":"Louny","state_id":"936"},
    {"city_id":"14855","city_name":"Lovosice","state_id":"936"},
    {"city_id":"14856","city_name":"Mezibori","state_id":"936"},
    {"city_id":"14857","city_name":"Most","state_id":"936"},
    {"city_id":"14858","city_name":"Osek","state_id":"936"},
    {"city_id":"14859","city_name":"Podborany","state_id":"936"},
    {"city_id":"14860","city_name":"Roudnice","state_id":"936"},
    {"city_id":"14861","city_name":"Rumburk","state_id":"936"},
    {"city_id":"14862","city_name":"Shluknov","state_id":"936"},
    {"city_id":"14863","city_name":"Shteti","state_id":"936"},
    {"city_id":"14864","city_name":"Teplice","state_id":"936"},
    {"city_id":"14865","city_name":"Usti","state_id":"936"},
    {"city_id":"14866","city_name":"Varnsdorf","state_id":"936"},
    {"city_id":"14867","city_name":"Zatec","state_id":"936"},
    {"city_id":"14868","city_name":"Valletta","state_id":"937"},
    {"city_id":"14869","city_name":"Velesin","state_id":"938"},
    {"city_id":"14870","city_name":"Bystrice nad Pernshtejnem","state_id":"939"},
    {"city_id":"14871","city_name":"Chotebor","state_id":"939"},
    {"city_id":"14872","city_name":"Havlichkuv Brod","state_id":"939"},
    {"city_id":"14873","city_name":"Humpolec","state_id":"939"},
    {"city_id":"14874","city_name":"Jihlava","state_id":"939"},
    {"city_id":"14875","city_name":"Ledech","state_id":"939"},
    {"city_id":"14876","city_name":"Moravske Budejovice","state_id":"939"},
    {"city_id":"14877","city_name":"Nove Mesto na Morave","state_id":"939"},
    {"city_id":"14878","city_name":"Okrisky","state_id":"939"},
    {"city_id":"14879","city_name":"Pacov","state_id":"939"},
    {"city_id":"14880","city_name":"Pelhrimov","state_id":"939"},
    {"city_id":"14881","city_name":"Polna","state_id":"939"},
    {"city_id":"14882","city_name":"Svetla nad Sazavou","state_id":"939"},
    {"city_id":"14883","city_name":"Telch","state_id":"939"},
    {"city_id":"14884","city_name":"Trebich","state_id":"939"},
    {"city_id":"14885","city_name":"Tresht'","state_id":"939"},
    {"city_id":"14886","city_name":"Velke Mezirichi","state_id":"939"},
    {"city_id":"14887","city_name":"Zhd'ar","state_id":"939"},
    {"city_id":"14888","city_name":"Brumov","state_id":"940"},
    {"city_id":"14889","city_name":"Bystrice pod Hostynem","state_id":"940"},
    {"city_id":"14890","city_name":"Chropyne","state_id":"940"},
    {"city_id":"14891","city_name":"Holeshov","state_id":"940"},
    {"city_id":"14892","city_name":"Hulin","state_id":"940"},
    {"city_id":"14893","city_name":"Kromerizh","state_id":"940"},
    {"city_id":"14894","city_name":"Kunovice","state_id":"940"},
    {"city_id":"14895","city_name":"Napajedla","state_id":"940"},
    {"city_id":"14896","city_name":"Otrokovice","state_id":"940"},
    {"city_id":"14897","city_name":"Rozhnov","state_id":"940"},
    {"city_id":"14898","city_name":"Roznov pod Radhostem","state_id":"940"},
    {"city_id":"14899","city_name":"Slavicin","state_id":"940"},
    {"city_id":"14900","city_name":"Slusovice","state_id":"940"},
    {"city_id":"14901","city_name":"Stare Mesto","state_id":"940"},
    {"city_id":"14902","city_name":"Strazhnice","state_id":"940"},
    {"city_id":"14903","city_name":"Uherske Hradishte","state_id":"940"},
    {"city_id":"14904","city_name":"Uhersky Brod","state_id":"940"},
    {"city_id":"14905","city_name":"Valashske Klobouky","state_id":"940"},
    {"city_id":"14906","city_name":"Valashske Mezirichi","state_id":"940"},
    {"city_id":"14907","city_name":"Veseli nad Moravou","state_id":"940"},
    {"city_id":"14908","city_name":"Vsetin","state_id":"940"},
    {"city_id":"14909","city_name":"Zborovice","state_id":"940"},
    {"city_id":"14910","city_name":"Zlin","state_id":"940"},
    {"city_id":"14911","city_name":"Aarhus","state_id":"941"},
    {"city_id":"14912","city_name":"Allingabro","state_id":"941"},
    {"city_id":"14913","city_name":"Arhus","state_id":"941"},
    {"city_id":"14914","city_name":"Assentoft","state_id":"941"},
    {"city_id":"14915","city_name":"Auning","state_id":"941"},
    {"city_id":"14916","city_name":"Beder","state_id":"941"},
    {"city_id":"14917","city_name":"Brabrand","state_id":"941"},
    {"city_id":"14918","city_name":"Ebeltoft","state_id":"941"},
    {"city_id":"14919","city_name":"Framlev","state_id":"941"},
    {"city_id":"14920","city_name":"Galten","state_id":"941"},
    {"city_id":"14921","city_name":"Grenaa","state_id":"941"},
    {"city_id":"14922","city_name":"Hadsten","state_id":"941"},
    {"city_id":"14923","city_name":"Hammel","state_id":"941"},
    {"city_id":"14924","city_name":"Hinnerup","state_id":"941"},
    {"city_id":"14925","city_name":"Hjortshoj","state_id":"941"},
    {"city_id":"14926","city_name":"Horning","state_id":"941"},
    {"city_id":"14927","city_name":"Hornslet","state_id":"941"},
    {"city_id":"14928","city_name":"Kolt","state_id":"941"},
    {"city_id":"14929","city_name":"Langa","state_id":"941"},
    {"city_id":"14930","city_name":"Logten","state_id":"941"},
    {"city_id":"14931","city_name":"Lystrup","state_id":"941"},
    {"city_id":"14932","city_name":"Malling","state_id":"941"},
    {"city_id":"14933","city_name":"Mariager","state_id":"941"},
    {"city_id":"14934","city_name":"Marslet","state_id":"941"},
    {"city_id":"14935","city_name":"Odder","state_id":"941"},
    {"city_id":"14936","city_name":"Randers","state_id":"941"},
    {"city_id":"14937","city_name":"Risskov","state_id":"941"},
    {"city_id":"14938","city_name":"Ronde","state_id":"941"},
    {"city_id":"14939","city_name":"Ry","state_id":"941"},
    {"city_id":"14940","city_name":"Ryomgard","state_id":"941"},
    {"city_id":"14941","city_name":"Sabro","state_id":"941"},
    {"city_id":"14942","city_name":"Silkeborg","state_id":"941"},
    {"city_id":"14943","city_name":"Skanderborg","state_id":"941"},
    {"city_id":"14944","city_name":"Skovby","state_id":"941"},
    {"city_id":"14945","city_name":"Soften","state_id":"941"},
    {"city_id":"14946","city_name":"Solbjerg","state_id":"941"},
    {"city_id":"14947","city_name":"Spentrup","state_id":"941"},
    {"city_id":"14948","city_name":"Stavtrup","state_id":"941"},
    {"city_id":"14949","city_name":"Stilling","state_id":"941"},
    {"city_id":"14950","city_name":"Svejbak","state_id":"941"},
    {"city_id":"14951","city_name":"Tranbjerg","state_id":"941"},
    {"city_id":"14952","city_name":"Trige","state_id":"941"},
    {"city_id":"14953","city_name":"Virklund","state_id":"941"},
    {"city_id":"14954","city_name":"Aakirkeby","state_id":"942"},
    {"city_id":"14955","city_name":"Allinge-Sandvig","state_id":"942"},
    {"city_id":"14956","city_name":"Nexo","state_id":"942"},
    {"city_id":"14957","city_name":"Ronne","state_id":"942"},
    {"city_id":"14958","city_name":"Allerod","state_id":"943"},
    {"city_id":"14959","city_name":"Birkerod","state_id":"943"},
    {"city_id":"14960","city_name":"Blovstrod","state_id":"943"},
    {"city_id":"14961","city_name":"Espergarde","state_id":"943"},
    {"city_id":"14962","city_name":"Farum","state_id":"943"},
    {"city_id":"14963","city_name":"Fredensborg","state_id":"943"},
    {"city_id":"14964","city_name":"Frederikssund","state_id":"943"},
    {"city_id":"14965","city_name":"Frederiksvark","state_id":"943"},
    {"city_id":"14966","city_name":"Ganlose","state_id":"943"},
    {"city_id":"14967","city_name":"Gilleleje","state_id":"943"},
    {"city_id":"14968","city_name":"Grasted","state_id":"943"},
    {"city_id":"14969","city_name":"Hellebak","state_id":"943"},
    {"city_id":"14970","city_name":"Helsinge","state_id":"943"},
    {"city_id":"14971","city_name":"Helsingor","state_id":"943"},
    {"city_id":"14972","city_name":"Hillerod","state_id":"943"},
    {"city_id":"14973","city_name":"Hornbak","state_id":"943"},
    {"city_id":"14974","city_name":"Horsholm","state_id":"943"},
    {"city_id":"14975","city_name":"Humlebak","state_id":"943"},
    {"city_id":"14976","city_name":"Hundested","state_id":"943"},
    {"city_id":"14977","city_name":"Jagerspris","state_id":"943"},
    {"city_id":"14978","city_name":"Kvistgaard","state_id":"943"},
    {"city_id":"14979","city_name":"Lillerod","state_id":"943"},
    {"city_id":"14980","city_name":"Liseleje","state_id":"943"},
    {"city_id":"14981","city_name":"Lynge","state_id":"943"},
    {"city_id":"14982","city_name":"Niva","state_id":"943"},
    {"city_id":"14983","city_name":"Nodebo","state_id":"943"},
    {"city_id":"14984","city_name":"Olstykke","state_id":"943"},
    {"city_id":"14985","city_name":"Skibby","state_id":"943"},
    {"city_id":"14986","city_name":"Slangerup","state_id":"943"},
    {"city_id":"14987","city_name":"Stavnsholt","state_id":"943"},
    {"city_id":"14988","city_name":"Stenlose","state_id":"943"},
    {"city_id":"14989","city_name":"Valby","state_id":"943"},
    {"city_id":"14990","city_name":"Vekso","state_id":"943"},
    {"city_id":"14991","city_name":"Aarup","state_id":"944"},
    {"city_id":"14992","city_name":"Arslev","state_id":"944"},
    {"city_id":"14993","city_name":"Assens","state_id":"944"},
    {"city_id":"14994","city_name":"Bellinge","state_id":"944"},
    {"city_id":"14995","city_name":"Blommenslyst","state_id":"944"},
    {"city_id":"14996","city_name":"Bogense","state_id":"944"},
    {"city_id":"14997","city_name":"Brenderup","state_id":"944"},
    {"city_id":"14998","city_name":"Broby","state_id":"944"},
    {"city_id":"14999","city_name":"Bullerup","state_id":"944"},
    {"city_id":"15000","city_name":"Ejby","state_id":"944"},
    {"city_id":"15001","city_name":"Faaborg","state_id":"944"},
    {"city_id":"15002","city_name":"Glamsbjerg","state_id":"944"},
    {"city_id":"15003","city_name":"Haarby","state_id":"944"},
    {"city_id":"15004","city_name":"Hojby","state_id":"944"},
    {"city_id":"15005","city_name":"Kerteminde","state_id":"944"},
    {"city_id":"15006","city_name":"Langeskov","state_id":"944"},
    {"city_id":"15007","city_name":"Marstal","state_id":"944"},
    {"city_id":"15008","city_name":"Middelfart","state_id":"944"},
    {"city_id":"15009","city_name":"Munkebo","state_id":"944"},
    {"city_id":"15010","city_name":"Neder Holluf","state_id":"944"},
    {"city_id":"15011","city_name":"Norre Aaby","state_id":"944"},
    {"city_id":"15012","city_name":"Nyborg","state_id":"944"},
    {"city_id":"15013","city_name":"Odense","state_id":"944"},
    {"city_id":"15014","city_name":"Otterup","state_id":"944"},
    {"city_id":"15015","city_name":"Ringe","state_id":"944"},
    {"city_id":"15016","city_name":"Rudkobing","state_id":"944"},
    {"city_id":"15017","city_name":"Sankt Klemens","state_id":"944"},
    {"city_id":"15018","city_name":"Seden","state_id":"944"},
    {"city_id":"15019","city_name":"Sonderso","state_id":"944"},
    {"city_id":"15020","city_name":"Stige","state_id":"944"},
    {"city_id":"15021","city_name":"Strib","state_id":"944"},
    {"city_id":"15022","city_name":"Svendborg","state_id":"944"},
    {"city_id":"15023","city_name":"Thuro","state_id":"944"},
    {"city_id":"15024","city_name":"Tommerup","state_id":"944"},
    {"city_id":"15025","city_name":"Ullerslev","state_id":"944"},
    {"city_id":"15026","city_name":"Vindeby","state_id":"944"},
    {"city_id":"15027","city_name":"Vissenbjerg","state_id":"944"},
    {"city_id":"15028","city_name":"Ballerup","state_id":"945"},
    {"city_id":"15029","city_name":"Brondby","state_id":"945"},
    {"city_id":"15030","city_name":"Stenlose","state_id":"945"},
    {"city_id":"15031","city_name":"Vallensbaek","state_id":"945"},
    {"city_id":"15032","city_name":"Dragor","state_id":"946"},
    {"city_id":"15033","city_name":"Flong","state_id":"946"},
    {"city_id":"15034","city_name":"Gentofte","state_id":"946"},
    {"city_id":"15035","city_name":"Glostrup","state_id":"946"},
    {"city_id":"15036","city_name":"Herlev","state_id":"946"},
    {"city_id":"15037","city_name":"Hvidovre","state_id":"946"},
    {"city_id":"15038","city_name":"Ishoj","state_id":"946"},
    {"city_id":"15039","city_name":"Kastrup","state_id":"946"},
    {"city_id":"15040","city_name":"Lyngby","state_id":"946"},
    {"city_id":"15041","city_name":"Malov","state_id":"946"},
    {"city_id":"15042","city_name":"Smorumnedre","state_id":"946"},
    {"city_id":"15043","city_name":"Taastrup","state_id":"946"},
    {"city_id":"15044","city_name":"Trorod","state_id":"946"},
    {"city_id":"15045","city_name":"Vanlose","state_id":"946"},
    {"city_id":"15046","city_name":"Varlose","state_id":"946"},
    {"city_id":"15047","city_name":"Aabybro","state_id":"949"},
    {"city_id":"15048","city_name":"Aalborg","state_id":"949"},
    {"city_id":"15049","city_name":"Aars","state_id":"949"},
    {"city_id":"15050","city_name":"Arden","state_id":"949"},
    {"city_id":"15051","city_name":"Bindslev","state_id":"949"},
    {"city_id":"15052","city_name":"Bronderslev","state_id":"949"},
    {"city_id":"15053","city_name":"Brovst","state_id":"949"},
    {"city_id":"15054","city_name":"Dronninglund","state_id":"949"},
    {"city_id":"15055","city_name":"Farso","state_id":"949"},
    {"city_id":"15056","city_name":"Fjerritslev","state_id":"949"},
    {"city_id":"15057","city_name":"Frederikshavn","state_id":"949"},
    {"city_id":"15058","city_name":"Frejlev","state_id":"949"},
    {"city_id":"15059","city_name":"Gistrup","state_id":"949"},
    {"city_id":"15060","city_name":"Gorlose","state_id":"949"},
    {"city_id":"15061","city_name":"Hadsund","state_id":"949"},
    {"city_id":"15062","city_name":"Hals","state_id":"949"},
    {"city_id":"15063","city_name":"Hirtshals","state_id":"949"},
    {"city_id":"15064","city_name":"Hjallerup","state_id":"949"},
    {"city_id":"15065","city_name":"Hjorring","state_id":"949"},
    {"city_id":"15066","city_name":"Hobro","state_id":"949"},
    {"city_id":"15067","city_name":"Kas","state_id":"949"},
    {"city_id":"15068","city_name":"Klarup","state_id":"949"},
    {"city_id":"15069","city_name":"Logstor","state_id":"949"},
    {"city_id":"15070","city_name":"Nibe","state_id":"949"},
    {"city_id":"15071","city_name":"Norresundby","state_id":"949"},
    {"city_id":"15072","city_name":"NÃƒÂƒÃ‚Â¸rresundby","state_id":"949"},
    {"city_id":"15073","city_name":"Pandrup","state_id":"949"},
    {"city_id":"15074","city_name":"Saby","state_id":"949"},
    {"city_id":"15075","city_name":"Sindal","state_id":"949"},
    {"city_id":"15076","city_name":"Skagen","state_id":"949"},
    {"city_id":"15077","city_name":"Skorping","state_id":"949"},
    {"city_id":"15078","city_name":"Storvorde","state_id":"949"},
    {"city_id":"15079","city_name":"Stovring","state_id":"949"},
    {"city_id":"15080","city_name":"Strandby","state_id":"949"},
    {"city_id":"15081","city_name":"Sulsted","state_id":"949"},
    {"city_id":"15082","city_name":"Svenstrup","state_id":"949"},
    {"city_id":"15083","city_name":"Tars","state_id":"949"},
    {"city_id":"15084","city_name":"Tranekaer","state_id":"949"},
    {"city_id":"15085","city_name":"Vadum","state_id":"949"},
    {"city_id":"15086","city_name":"Vestbjerg","state_id":"949"},
    {"city_id":"15087","city_name":"Vester Hassing","state_id":"949"},
    {"city_id":"15088","city_name":"Vodskov","state_id":"949"},
    {"city_id":"15089","city_name":"Vra","state_id":"949"},
    {"city_id":"15090","city_name":"Ansager","state_id":"950"},
    {"city_id":"15091","city_name":"Billund","state_id":"950"},
    {"city_id":"15092","city_name":"Bramming","state_id":"950"},
    {"city_id":"15093","city_name":"Brorup","state_id":"950"},
    {"city_id":"15094","city_name":"Esbjerg","state_id":"950"},
    {"city_id":"15095","city_name":"Grindsted","state_id":"950"},
    {"city_id":"15096","city_name":"Holsted","state_id":"950"},
    {"city_id":"15097","city_name":"Nordby","state_id":"950"},
    {"city_id":"15098","city_name":"Oksbol","state_id":"950"},
    {"city_id":"15099","city_name":"Olgod","state_id":"950"},
    {"city_id":"15100","city_name":"Ribe","state_id":"950"},
    {"city_id":"15101","city_name":"Tjareborg","state_id":"950"},
    {"city_id":"15102","city_name":"Varde","state_id":"950"},
    {"city_id":"15103","city_name":"Vejen","state_id":"950"},
    {"city_id":"15104","city_name":"Vorbasse","state_id":"950"},
    {"city_id":"15105","city_name":"Aulum","state_id":"951"},
    {"city_id":"15106","city_name":"Bording","state_id":"951"},
    {"city_id":"15107","city_name":"Brande","state_id":"951"},
    {"city_id":"15108","city_name":"Gjellerup Kirkeby","state_id":"951"},
    {"city_id":"15109","city_name":"Hammerum","state_id":"951"},
    {"city_id":"15110","city_name":"Harboore","state_id":"951"},
    {"city_id":"15111","city_name":"Herning","state_id":"951"},
    {"city_id":"15112","city_name":"Holstebro","state_id":"951"},
    {"city_id":"15113","city_name":"Hvide Sande","state_id":"951"},
    {"city_id":"15114","city_name":"Ikast","state_id":"951"},
    {"city_id":"15115","city_name":"Kibak","state_id":"951"},
    {"city_id":"15116","city_name":"Lemvig","state_id":"951"},
    {"city_id":"15117","city_name":"Lind","state_id":"951"},
    {"city_id":"15118","city_name":"Ringkobing","state_id":"951"},
    {"city_id":"15119","city_name":"Skaerbaek","state_id":"951"},
    {"city_id":"15120","city_name":"Skjern","state_id":"951"},
    {"city_id":"15121","city_name":"Snejbjerg","state_id":"951"},
    {"city_id":"15122","city_name":"Struer","state_id":"951"},
    {"city_id":"15123","city_name":"Sunds","state_id":"951"},
    {"city_id":"15124","city_name":"Tarm","state_id":"951"},
    {"city_id":"15125","city_name":"Thyboron","state_id":"951"},
    {"city_id":"15126","city_name":"Ulfborg","state_id":"951"},
    {"city_id":"15127","city_name":"Videbak","state_id":"951"},
    {"city_id":"15128","city_name":"Vildbjerg","state_id":"951"},
    {"city_id":"15129","city_name":"Vinderup","state_id":"951"},
    {"city_id":"15130","city_name":"Roervig","state_id":"952"},
    {"city_id":"15131","city_name":"Bjaverskov","state_id":"953"},
    {"city_id":"15132","city_name":"Borup","state_id":"953"},
    {"city_id":"15133","city_name":"Ejby","state_id":"953"},
    {"city_id":"15134","city_name":"Greve Strand","state_id":"953"},
    {"city_id":"15135","city_name":"Gundsomagle","state_id":"953"},
    {"city_id":"15136","city_name":"Harlev","state_id":"953"},
    {"city_id":"15137","city_name":"Havdrup","state_id":"953"},
    {"city_id":"15138","city_name":"Hvalso","state_id":"953"},
    {"city_id":"15139","city_name":"Jyllinge","state_id":"953"},
    {"city_id":"15140","city_name":"Koge","state_id":"953"},
    {"city_id":"15141","city_name":"Lejre","state_id":"953"},
    {"city_id":"15142","city_name":"Osted","state_id":"953"},
    {"city_id":"15143","city_name":"Roskilde","state_id":"953"},
    {"city_id":"15144","city_name":"Solrod","state_id":"953"},
    {"city_id":"15145","city_name":"Stroby Egede","state_id":"953"},
    {"city_id":"15146","city_name":"Svogerslev","state_id":"953"},
    {"city_id":"15147","city_name":"Tune","state_id":"953"},
    {"city_id":"15148","city_name":"Viby","state_id":"953"},
    {"city_id":"15149","city_name":"Vindinge","state_id":"953"},
    {"city_id":"15150","city_name":"Glyngore","state_id":"954"},
    {"city_id":"15151","city_name":"Karise","state_id":"955"},
    {"city_id":"15152","city_name":"Naestved","state_id":"955"},
    {"city_id":"15153","city_name":"Soeborg","state_id":"956"},
    {"city_id":"15154","city_name":"Aabenraa","state_id":"957"},
    {"city_id":"15155","city_name":"Aarsleve","state_id":"957"},
    {"city_id":"15156","city_name":"Augustenborg","state_id":"957"},
    {"city_id":"15157","city_name":"Broager","state_id":"957"},
    {"city_id":"15158","city_name":"Christiansfeld","state_id":"957"},
    {"city_id":"15159","city_name":"Dybbol","state_id":"957"},
    {"city_id":"15160","city_name":"Gram","state_id":"957"},
    {"city_id":"15161","city_name":"Grasten","state_id":"957"},
    {"city_id":"15162","city_name":"Guderup","state_id":"957"},
    {"city_id":"15163","city_name":"Haderslev","state_id":"957"},
    {"city_id":"15164","city_name":"Horuphav","state_id":"957"},
    {"city_id":"15165","city_name":"Krusa","state_id":"957"},
    {"city_id":"15166","city_name":"Logumkloster","state_id":"957"},
    {"city_id":"15167","city_name":"Lojt Kirkeby","state_id":"957"},
    {"city_id":"15168","city_name":"Nordborg","state_id":"957"},
    {"city_id":"15169","city_name":"Padborg","state_id":"957"},
    {"city_id":"15170","city_name":"Rodding","state_id":"957"},
    {"city_id":"15171","city_name":"Rodekro","state_id":"957"},
    {"city_id":"15172","city_name":"Skarbak","state_id":"957"},
    {"city_id":"15173","city_name":"Sonderborg","state_id":"957"},
    {"city_id":"15174","city_name":"Starup","state_id":"957"},
    {"city_id":"15175","city_name":"Tinglev","state_id":"957"},
    {"city_id":"15176","city_name":"Toftlund","state_id":"957"},
    {"city_id":"15177","city_name":"Tonder","state_id":"957"},
    {"city_id":"15178","city_name":"Vojens","state_id":"957"},
    {"city_id":"15179","city_name":"Fakse","state_id":"958"},
    {"city_id":"15180","city_name":"Fakse Ladeplads","state_id":"958"},
    {"city_id":"15181","city_name":"Fensmark","state_id":"958"},
    {"city_id":"15182","city_name":"Holeby","state_id":"958"},
    {"city_id":"15183","city_name":"Maribo","state_id":"958"},
    {"city_id":"15184","city_name":"Nakskov","state_id":"958"},
    {"city_id":"15185","city_name":"Nastved","state_id":"958"},
    {"city_id":"15186","city_name":"Neder Vindinge","state_id":"958"},
    {"city_id":"15187","city_name":"Norre Alslev","state_id":"958"},
    {"city_id":"15188","city_name":"Nykobing","state_id":"958"},
    {"city_id":"15189","city_name":"Nyrad","state_id":"958"},
    {"city_id":"15190","city_name":"Orslev","state_id":"958"},
    {"city_id":"15191","city_name":"Prasto","state_id":"958"},
    {"city_id":"15192","city_name":"Rodby","state_id":"958"},
    {"city_id":"15193","city_name":"Rodbyhavn","state_id":"958"},
    {"city_id":"15194","city_name":"Ronnede","state_id":"958"},
    {"city_id":"15195","city_name":"Sakskobing","state_id":"958"},
    {"city_id":"15196","city_name":"Stege","state_id":"958"},
    {"city_id":"15197","city_name":"Store Heddinge","state_id":"958"},
    {"city_id":"15198","city_name":"Stubbekobing","state_id":"958"},
    {"city_id":"15199","city_name":"Sundby","state_id":"958"},
    {"city_id":"15200","city_name":"Vordingborg","state_id":"958"},
    {"city_id":"15201","city_name":"Bylderup-Bov","state_id":"959"},
    {"city_id":"15202","city_name":"Fovling","state_id":"959"},
    {"city_id":"15203","city_name":"Toelloese","state_id":"960"},
    {"city_id":"15204","city_name":"Borkop","state_id":"961"},
    {"city_id":"15205","city_name":"Bradstrup","state_id":"961"},
    {"city_id":"15206","city_name":"Brejning","state_id":"961"},
    {"city_id":"15207","city_name":"Egtved","state_id":"961"},
    {"city_id":"15208","city_name":"Fredericia","state_id":"961"},
    {"city_id":"15209","city_name":"Give","state_id":"961"},
    {"city_id":"15210","city_name":"Hedensted","state_id":"961"},
    {"city_id":"15211","city_name":"Horsens","state_id":"961"},
    {"city_id":"15212","city_name":"Jelling","state_id":"961"},
    {"city_id":"15213","city_name":"Juelsminde","state_id":"961"},
    {"city_id":"15214","city_name":"Kolding","state_id":"961"},
    {"city_id":"15215","city_name":"Lunderskov","state_id":"961"},
    {"city_id":"15216","city_name":"Snoghoj","state_id":"961"},
    {"city_id":"15217","city_name":"Sonder Bjert","state_id":"961"},
    {"city_id":"15218","city_name":"Taulov","state_id":"961"},
    {"city_id":"15219","city_name":"Torring","state_id":"961"},
    {"city_id":"15220","city_name":"Vamdrup","state_id":"961"},
    {"city_id":"15221","city_name":"Vejle","state_id":"961"},
    {"city_id":"15222","city_name":"Asnas","state_id":"962"},
    {"city_id":"15223","city_name":"Dianalund","state_id":"962"},
    {"city_id":"15224","city_name":"Forlev","state_id":"962"},
    {"city_id":"15225","city_name":"Frederiksberg","state_id":"962"},
    {"city_id":"15226","city_name":"Fuglebjerg","state_id":"962"},
    {"city_id":"15227","city_name":"Gorlev","state_id":"962"},
    {"city_id":"15228","city_name":"Haslev","state_id":"962"},
    {"city_id":"15229","city_name":"Holbaek","state_id":"962"},
    {"city_id":"15230","city_name":"Hong","state_id":"962"},
    {"city_id":"15231","city_name":"Horve","state_id":"962"},
    {"city_id":"15232","city_name":"Jyderup","state_id":"962"},
    {"city_id":"15233","city_name":"Kalundborg","state_id":"962"},
    {"city_id":"15234","city_name":"Korsor","state_id":"962"},
    {"city_id":"15235","city_name":"Nykobing","state_id":"962"},
    {"city_id":"15236","city_name":"Ringsted","state_id":"962"},
    {"city_id":"15237","city_name":"Skalskor","state_id":"962"},
    {"city_id":"15238","city_name":"Slagelse","state_id":"962"},
    {"city_id":"15239","city_name":"Soro","state_id":"962"},
    {"city_id":"15240","city_name":"Svebolle","state_id":"962"},
    {"city_id":"15241","city_name":"Svinninge","state_id":"962"},
    {"city_id":"15242","city_name":"Tollose","state_id":"962"},
    {"city_id":"15243","city_name":"Vipperod","state_id":"962"},
    {"city_id":"15244","city_name":"Aalestrup","state_id":"963"},
    {"city_id":"15245","city_name":"Bjerringbro","state_id":"963"},
    {"city_id":"15246","city_name":"Hanstholm","state_id":"963"},
    {"city_id":"15247","city_name":"Hojslev","state_id":"963"},
    {"city_id":"15248","city_name":"Hurup","state_id":"963"},
    {"city_id":"15249","city_name":"Karup","state_id":"963"},
    {"city_id":"15250","city_name":"Kjellerup","state_id":"963"},
    {"city_id":"15251","city_name":"Nykobing","state_id":"963"},
    {"city_id":"15252","city_name":"Skive","state_id":"963"},
    {"city_id":"15253","city_name":"Stoholm","state_id":"963"},
    {"city_id":"15254","city_name":"Thisted","state_id":"963"},
    {"city_id":"15255","city_name":"Ulstrup","state_id":"963"},
    {"city_id":"15256","city_name":"Viborg","state_id":"963"},
    {"city_id":"15257","city_name":"Dikhil","state_id":"965"},
    {"city_id":"15258","city_name":"Jibuti","state_id":"966"},
    {"city_id":"15259","city_name":"Tajurah","state_id":"967"},
    {"city_id":"15260","city_name":"Ubuk","state_id":"968"},
    {"city_id":"15261","city_name":"Azua","state_id":"979"},
    {"city_id":"15262","city_name":"Sabana Yegua","state_id":"979"},
    {"city_id":"15263","city_name":"Neyba","state_id":"980"},
    {"city_id":"15264","city_name":"Tamayo","state_id":"980"},
    {"city_id":"15265","city_name":"Barahona","state_id":"981"},
    {"city_id":"15266","city_name":"Cabral","state_id":"981"},
    {"city_id":"15267","city_name":"El Penon","state_id":"981"},
    {"city_id":"15268","city_name":"Dajabon","state_id":"982"},
    {"city_id":"15269","city_name":"Las Guaranas","state_id":"984"},
    {"city_id":"15270","city_name":"Pimentel","state_id":"984"},
    {"city_id":"15271","city_name":"San Francisco de Macoris","state_id":"984"},
    {"city_id":"15272","city_name":"Moca","state_id":"987"},
    {"city_id":"15273","city_name":"Duverge","state_id":"989"},
    {"city_id":"15274","city_name":"Jimani","state_id":"989"},
    {"city_id":"15275","city_name":"Pedernales","state_id":"997"},
    {"city_id":"15276","city_name":"Bani","state_id":"998"},
    {"city_id":"15277","city_name":"Ocoa","state_id":"998"},
    {"city_id":"15278","city_name":"Salcedo","state_id":"1000"},
    {"city_id":"15279","city_name":"Samana","state_id":"1001"},
    {"city_id":"15280","city_name":"Sanchez","state_id":"1001"},
    {"city_id":"15281","city_name":"Santiago","state_id":"1006"},
    {"city_id":"15282","city_name":"Tamboril","state_id":"1006"},
    {"city_id":"15283","city_name":"Villa Bisono","state_id":"1006"},
    {"city_id":"15284","city_name":"Santiago","state_id":"1007"},
    {"city_id":"15285","city_name":"Tamboril","state_id":"1007"},
    {"city_id":"15286","city_name":"Villa Bisono","state_id":"1007"},
    {"city_id":"15287","city_name":"Esperanza","state_id":"1008"},
    {"city_id":"15288","city_name":"Mao","state_id":"1008"},
    {"city_id":"15289","city_name":"Aileu","state_id":"1009"},
    {"city_id":"15290","city_name":"Ainaro","state_id":"1010"},
    {"city_id":"15291","city_name":"Pante Macassar","state_id":"1011"},
    {"city_id":"15292","city_name":"Baucau","state_id":"1012"},
    {"city_id":"15293","city_name":"Auba","state_id":"1013"},
    {"city_id":"15294","city_name":"Lolotoi","state_id":"1013"},
    {"city_id":"15295","city_name":"Maliana","state_id":"1013"},
    {"city_id":"15296","city_name":"Dare","state_id":"1015"},
    {"city_id":"15297","city_name":"Dili","state_id":"1015"},
    {"city_id":"15298","city_name":"Metinaro","state_id":"1015"},
    {"city_id":"15299","city_name":"Ermera","state_id":"1016"},
    {"city_id":"15300","city_name":"Lautem","state_id":"1017"},
    {"city_id":"15301","city_name":"Los Palos","state_id":"1017"},
    {"city_id":"15302","city_name":"Bazartete","state_id":"1018"},
    {"city_id":"15303","city_name":"Liquica","state_id":"1018"},
    {"city_id":"15304","city_name":"Manatuto","state_id":"1019"},
    {"city_id":"15305","city_name":"Same","state_id":"1020"},
    {"city_id":"15306","city_name":"Viqueque","state_id":"1021"},
    {"city_id":"15307","city_name":"Cuenca","state_id":"1022"},
    {"city_id":"15308","city_name":"Gualaceo","state_id":"1022"},
    {"city_id":"15309","city_name":"Guaranda","state_id":"1023"},
    {"city_id":"15310","city_name":"San Miguel","state_id":"1023"},
    {"city_id":"15311","city_name":"Azogues","state_id":"1024"},
    {"city_id":"15312","city_name":"Canar","state_id":"1024"},
    {"city_id":"15313","city_name":"La Troncal","state_id":"1024"},
    {"city_id":"15314","city_name":"El Angel","state_id":"1025"},
    {"city_id":"15315","city_name":"San Gabriel","state_id":"1025"},
    {"city_id":"15316","city_name":"Tulcan","state_id":"1025"},
    {"city_id":"15317","city_name":"Alausi","state_id":"1026"},
    {"city_id":"15318","city_name":"Guano","state_id":"1026"},
    {"city_id":"15319","city_name":"Riobamba","state_id":"1026"},
    {"city_id":"15320","city_name":"La Mana","state_id":"1027"},
    {"city_id":"15321","city_name":"Latacunga","state_id":"1027"},
    {"city_id":"15322","city_name":"Pujili","state_id":"1027"},
    {"city_id":"15323","city_name":"San Miguel","state_id":"1027"},
    {"city_id":"15324","city_name":"Saquisili","state_id":"1027"},
    {"city_id":"15325","city_name":"Esmeraldas","state_id":"1029"},
    {"city_id":"15326","city_name":"Muisne","state_id":"1029"},
    {"city_id":"15327","city_name":"Rosa Zarate","state_id":"1029"},
    {"city_id":"15328","city_name":"San Lorenzo","state_id":"1029"},
    {"city_id":"15329","city_name":"Valdez","state_id":"1029"},
    {"city_id":"15330","city_name":"Puerto Ayora","state_id":"1030"},
    {"city_id":"15331","city_name":"Puerto Baquerizo Moreno","state_id":"1030"},
    {"city_id":"15332","city_name":"San Cristobal","state_id":"1030"},
    {"city_id":"15333","city_name":"Alfredo Baquerizo Moreno","state_id":"1031"},
    {"city_id":"15334","city_name":"Balao","state_id":"1031"},
    {"city_id":"15335","city_name":"Balzar","state_id":"1031"},
    {"city_id":"15336","city_name":"Colimes","state_id":"1031"},
    {"city_id":"15337","city_name":"Coronel Mariduena","state_id":"1031"},
    {"city_id":"15338","city_name":"Daule","state_id":"1031"},
    {"city_id":"15339","city_name":"El Salitre","state_id":"1031"},
    {"city_id":"15340","city_name":"El Triunfo","state_id":"1031"},
    {"city_id":"15341","city_name":"Eloy Alfaro","state_id":"1031"},
    {"city_id":"15342","city_name":"Guayaquil","state_id":"1031"},
    {"city_id":"15343","city_name":"La Libertad","state_id":"1031"},
    {"city_id":"15344","city_name":"Lomas de Sargentillo","state_id":"1031"},
    {"city_id":"15345","city_name":"Mapasingue","state_id":"1031"},
    {"city_id":"15346","city_name":"Milagro","state_id":"1031"},
    {"city_id":"15347","city_name":"Naranjal","state_id":"1031"},
    {"city_id":"15348","city_name":"Naranjito","state_id":"1031"},
    {"city_id":"15349","city_name":"Palestina","state_id":"1031"},
    {"city_id":"15350","city_name":"Pedro Carbo","state_id":"1031"},
    {"city_id":"15351","city_name":"Playas","state_id":"1031"},
    {"city_id":"15352","city_name":"Salinas","state_id":"1031"},
    {"city_id":"15353","city_name":"Samborondon","state_id":"1031"},
    {"city_id":"15354","city_name":"Santa Elena","state_id":"1031"},
    {"city_id":"15355","city_name":"Santa Lucia","state_id":"1031"},
    {"city_id":"15356","city_name":"Velasco Ibarra","state_id":"1031"},
    {"city_id":"15357","city_name":"Yaguachi","state_id":"1031"},
    {"city_id":"15358","city_name":"Atuntaqui","state_id":"1032"},
    {"city_id":"15359","city_name":"Cotacachi","state_id":"1032"},
    {"city_id":"15360","city_name":"Ibarra","state_id":"1032"},
    {"city_id":"15361","city_name":"Otavalo","state_id":"1032"},
    {"city_id":"15362","city_name":"Pimampiro","state_id":"1032"},
    {"city_id":"15363","city_name":"Alamor","state_id":"1033"},
    {"city_id":"15364","city_name":"Cariamanga","state_id":"1033"},
    {"city_id":"15365","city_name":"Catacocha","state_id":"1033"},
    {"city_id":"15366","city_name":"Catamayo","state_id":"1033"},
    {"city_id":"15367","city_name":"Celica","state_id":"1033"},
    {"city_id":"15368","city_name":"Loja","state_id":"1033"},
    {"city_id":"15369","city_name":"Macara","state_id":"1033"},
    {"city_id":"15370","city_name":"Bahia de Caraquez","state_id":"1035"},
    {"city_id":"15371","city_name":"Calceta","state_id":"1035"},
    {"city_id":"15372","city_name":"Chone","state_id":"1035"},
    {"city_id":"15373","city_name":"El Carmen","state_id":"1035"},
    {"city_id":"15374","city_name":"Jipijapa","state_id":"1035"},
    {"city_id":"15375","city_name":"Junin","state_id":"1035"},
    {"city_id":"15376","city_name":"Manta","state_id":"1035"},
    {"city_id":"15377","city_name":"Montecristi","state_id":"1035"},
    {"city_id":"15378","city_name":"Pajan","state_id":"1035"},
    {"city_id":"15379","city_name":"Pedernales","state_id":"1035"},
    {"city_id":"15380","city_name":"Portoviejo","state_id":"1035"},
    {"city_id":"15381","city_name":"Rocafuerte","state_id":"1035"},
    {"city_id":"15382","city_name":"Santa Ana","state_id":"1035"},
    {"city_id":"15383","city_name":"Sucre","state_id":"1035"},
    {"city_id":"15384","city_name":"Tosagua","state_id":"1035"},
    {"city_id":"15385","city_name":"Archidona","state_id":"1037"},
    {"city_id":"15386","city_name":"Tena","state_id":"1037"},
    {"city_id":"15387","city_name":"Orellana","state_id":"1038"},
    {"city_id":"15388","city_name":"Puyo","state_id":"1039"},
    {"city_id":"15389","city_name":"Cayambe","state_id":"1040"},
    {"city_id":"15390","city_name":"Machachi","state_id":"1040"},
    {"city_id":"15391","city_name":"Quito","state_id":"1040"},
    {"city_id":"15392","city_name":"Sangolqui","state_id":"1040"},
    {"city_id":"15393","city_name":"Santo Domingo","state_id":"1040"},
    {"city_id":"15394","city_name":"Nueva Loja","state_id":"1041"},
    {"city_id":"15395","city_name":"Shushufindi","state_id":"1041"},
    {"city_id":"15396","city_name":"Ambato","state_id":"1042"},
    {"city_id":"15397","city_name":"Banos","state_id":"1042"},
    {"city_id":"15398","city_name":"Pelileo","state_id":"1042"},
    {"city_id":"15399","city_name":"Pillaro","state_id":"1042"},
    {"city_id":"15400","city_name":"Aswan","state_id":"1044"},
    {"city_id":"15401","city_name":"Daraw","state_id":"1044"},
    {"city_id":"15402","city_name":"Kawm Umbu","state_id":"1044"},
    {"city_id":"15403","city_name":"an-Nasir","state_id":"1044"},
    {"city_id":"15404","city_name":"Abnub","state_id":"1045"},
    {"city_id":"15405","city_name":"Abu Tij","state_id":"1045"},
    {"city_id":"15406","city_name":"Asyut","state_id":"1045"},
    {"city_id":"15407","city_name":"Bani Muhammadiyat","state_id":"1045"},
    {"city_id":"15408","city_name":"Dayrut","state_id":"1045"},
    {"city_id":"15409","city_name":"Dayrut-ash-Sharif","state_id":"1045"},
    {"city_id":"15410","city_name":"Manfalut","state_id":"1045"},
    {"city_id":"15411","city_name":"Musha","state_id":"1045"},
    {"city_id":"15412","city_name":"Sahil Salim","state_id":"1045"},
    {"city_id":"15413","city_name":"Sanabu","state_id":"1045"},
    {"city_id":"15414","city_name":"Umm-al-Qusur","state_id":"1045"},
    {"city_id":"15415","city_name":"al-Badari","state_id":"1045"},
    {"city_id":"15416","city_name":"al-Qusiyah","state_id":"1045"},
    {"city_id":"15417","city_name":"an-Nukhaylah","state_id":"1045"},
    {"city_id":"15418","city_name":"6th of October City","state_id":"1048"},
    {"city_id":"15419","city_name":"Ataba","state_id":"1048"},
    {"city_id":"15420","city_name":"Cairo","state_id":"1048"},
    {"city_id":"15421","city_name":"Nasr","state_id":"1048"},
    {"city_id":"15422","city_name":"Nasr City","state_id":"1048"},
    {"city_id":"15423","city_name":"Obour City","state_id":"1048"},
    {"city_id":"15424","city_name":"'Izbat-al-Burj","state_id":"1049"},
    {"city_id":"15425","city_name":"Damietta","state_id":"1049"},
    {"city_id":"15426","city_name":"Dumyat","state_id":"1049"},
    {"city_id":"15427","city_name":"El-Zarka","state_id":"1049"},
    {"city_id":"15428","city_name":"Faraskur","state_id":"1049"},
    {"city_id":"15429","city_name":"Kafr Sa'd","state_id":"1049"},
    {"city_id":"15430","city_name":"Kafr-al-Battikh","state_id":"1049"},
    {"city_id":"15431","city_name":"az-Zarqa","state_id":"1049"},
    {"city_id":"15432","city_name":"Biyala","state_id":"1050"},
    {"city_id":"15433","city_name":"Disuq","state_id":"1050"},
    {"city_id":"15434","city_name":"Fuwah","state_id":"1050"},
    {"city_id":"15435","city_name":"Kafr-al-Jara'idah","state_id":"1050"},
    {"city_id":"15436","city_name":"Kafr-ash-Shaykh","state_id":"1050"},
    {"city_id":"15437","city_name":"Mutubis","state_id":"1050"},
    {"city_id":"15438","city_name":"Qallin","state_id":"1050"},
    {"city_id":"15439","city_name":"Sidi Salim","state_id":"1050"},
    {"city_id":"15440","city_name":"al-Burj","state_id":"1050"},
    {"city_id":"15441","city_name":"al-Burullus","state_id":"1050"},
    {"city_id":"15442","city_name":"al-Haddadi","state_id":"1050"},
    {"city_id":"15443","city_name":"al-Hamul","state_id":"1050"},
    {"city_id":"15444","city_name":"Marsa Matruh","state_id":"1051"},
    {"city_id":"15445","city_name":"Nasr","state_id":"1051"},
    {"city_id":"15446","city_name":"Sidi Barrani","state_id":"1051"},
    {"city_id":"15447","city_name":"Zawiyat Shammas","state_id":"1051"},
    {"city_id":"15448","city_name":"ad-Da'ba","state_id":"1051"},
    {"city_id":"15449","city_name":"Armant","state_id":"1057"},
    {"city_id":"15450","city_name":"Asfun-al-Mata'inah","state_id":"1057"},
    {"city_id":"15451","city_name":"Dandarah","state_id":"1057"},
    {"city_id":"15452","city_name":"Dishna","state_id":"1057"},
    {"city_id":"15453","city_name":"Farshut","state_id":"1057"},
    {"city_id":"15454","city_name":"Hijazah","state_id":"1057"},
    {"city_id":"15455","city_name":"Hiw","state_id":"1057"},
    {"city_id":"15456","city_name":"Idfu","state_id":"1057"},
    {"city_id":"15457","city_name":"Isna","state_id":"1057"},
    {"city_id":"15458","city_name":"Kiman-al-Mata'inah","state_id":"1057"},
    {"city_id":"15459","city_name":"Naj' Hammadi","state_id":"1057"},
    {"city_id":"15460","city_name":"Naqadah","state_id":"1057"},
    {"city_id":"15461","city_name":"Qift","state_id":"1057"},
    {"city_id":"15462","city_name":"Qina","state_id":"1057"},
    {"city_id":"15463","city_name":"Qus","state_id":"1057"},
    {"city_id":"15464","city_name":"ad-Dabbiyah","state_id":"1057"},
    {"city_id":"15465","city_name":"ad-Dayr","state_id":"1057"},
    {"city_id":"15466","city_name":"al-Ballas","state_id":"1057"},
    {"city_id":"15467","city_name":"al-Karnak","state_id":"1057"},
    {"city_id":"15468","city_name":"al-Waqf","state_id":"1057"},
    {"city_id":"15469","city_name":"ar-Radisiyat-al-Bahriyah","state_id":"1057"},
    {"city_id":"15470","city_name":"Akhmim","state_id":"1058"},
    {"city_id":"15471","city_name":"Awlad Tawq Sharq","state_id":"1058"},
    {"city_id":"15472","city_name":"Dar-as-Salam","state_id":"1058"},
    {"city_id":"15473","city_name":"Jirja","state_id":"1058"},
    {"city_id":"15474","city_name":"Juhaynah","state_id":"1058"},
    {"city_id":"15475","city_name":"Sawhaj","state_id":"1058"},
    {"city_id":"15476","city_name":"Tahta","state_id":"1058"},
    {"city_id":"15477","city_name":"Tima","state_id":"1058"},
    {"city_id":"15478","city_name":"al-Balyana","state_id":"1058"},
    {"city_id":"15479","city_name":"al-Manshah","state_id":"1058"},
    {"city_id":"15480","city_name":"al-Maragah","state_id":"1058"},
    {"city_id":"15481","city_name":"Aja","state_id":"1061"},
    {"city_id":"15482","city_name":"Bahut","state_id":"1061"},
    {"city_id":"15483","city_name":"Bilqas","state_id":"1061"},
    {"city_id":"15484","city_name":"Dikirnis","state_id":"1061"},
    {"city_id":"15485","city_name":"Minyat-an-Nasr","state_id":"1061"},
    {"city_id":"15486","city_name":"Mit Gamr","state_id":"1061"},
    {"city_id":"15487","city_name":"Shirbin","state_id":"1061"},
    {"city_id":"15488","city_name":"Talkha","state_id":"1061"},
    {"city_id":"15489","city_name":"al-Jamaliyah","state_id":"1061"},
    {"city_id":"15490","city_name":"al-Ma'sarah","state_id":"1061"},
    {"city_id":"15491","city_name":"al-Mansurah","state_id":"1061"},
    {"city_id":"15492","city_name":"al-Manzilah","state_id":"1061"},
    {"city_id":"15493","city_name":"al-Matariyah","state_id":"1061"},
    {"city_id":"15494","city_name":"as-Sinbillawayn","state_id":"1061"},
    {"city_id":"15495","city_name":"Ras Gharib","state_id":"1062"},
    {"city_id":"15496","city_name":"Safaja","state_id":"1062"},
    {"city_id":"15497","city_name":"al-Ghardaqah","state_id":"1062"},
    {"city_id":"15498","city_name":"al-Qusayr","state_id":"1062"},
    {"city_id":"15499","city_name":"Abu Hummus","state_id":"1063"},
    {"city_id":"15500","city_name":"Abu al-Matamir","state_id":"1063"},
    {"city_id":"15501","city_name":"Buturis","state_id":"1063"},
    {"city_id":"15502","city_name":"Damanhur","state_id":"1063"},
    {"city_id":"15503","city_name":"Edfina","state_id":"1063"},
    {"city_id":"15504","city_name":"Hawsh 'Isa","state_id":"1063"},
    {"city_id":"15505","city_name":"Idku","state_id":"1063"},
    {"city_id":"15506","city_name":"Ityay-al-Barud","state_id":"1063"},
    {"city_id":"15507","city_name":"Kafr Salim","state_id":"1063"},
    {"city_id":"15508","city_name":"Kafr-ad-Dawwar","state_id":"1063"},
    {"city_id":"15509","city_name":"Kawm Hamada","state_id":"1063"},
    {"city_id":"15510","city_name":"Nubaria","state_id":"1063"},
    {"city_id":"15511","city_name":"Rashid","state_id":"1063"},
    {"city_id":"15512","city_name":"Shubra Khit","state_id":"1063"},
    {"city_id":"15513","city_name":"Zawiyat Sidi Gazi","state_id":"1063"},
    {"city_id":"15514","city_name":"ad-Dilinjat","state_id":"1063"},
    {"city_id":"15515","city_name":"al-Kawm-al-Akhdar","state_id":"1063"},
    {"city_id":"15516","city_name":"al-Mahmudiyah","state_id":"1063"},
    {"city_id":"15517","city_name":"ar-Rahmaniyah","state_id":"1063"},
    {"city_id":"15518","city_name":"Fidimin","state_id":"1064"},
    {"city_id":"15519","city_name":"Ibshaway","state_id":"1064"},
    {"city_id":"15520","city_name":"Itsa","state_id":"1064"},
    {"city_id":"15521","city_name":"Qasr Qarun","state_id":"1064"},
    {"city_id":"15522","city_name":"Sanhur","state_id":"1064"},
    {"city_id":"15523","city_name":"Sinnuris","state_id":"1064"},
    {"city_id":"15524","city_name":"Tamiyah","state_id":"1064"},
    {"city_id":"15525","city_name":"al-Fayyum","state_id":"1064"},
    {"city_id":"15526","city_name":"Abyar","state_id":"1065"},
    {"city_id":"15527","city_name":"Basyun","state_id":"1065"},
    {"city_id":"15528","city_name":"Kafr-az-Zayyat","state_id":"1065"},
    {"city_id":"15529","city_name":"Mahallat Marhum","state_id":"1065"},
    {"city_id":"15530","city_name":"Nisf Thani Bashbish","state_id":"1065"},
    {"city_id":"15531","city_name":"Qutur","state_id":"1065"},
    {"city_id":"15532","city_name":"Samannud","state_id":"1065"},
    {"city_id":"15533","city_name":"Tanta","state_id":"1065"},
    {"city_id":"15534","city_name":"Zifta","state_id":"1065"},
    {"city_id":"15535","city_name":"ad-Daljamun","state_id":"1065"},
    {"city_id":"15536","city_name":"al-Mahallah al-Kubra","state_id":"1065"},
    {"city_id":"15537","city_name":"as-Santah","state_id":"1065"},
    {"city_id":"15538","city_name":"Agamy","state_id":"1066"},
    {"city_id":"15539","city_name":"al-Iskandariyah","state_id":"1066"},
    {"city_id":"15540","city_name":"al-Maks","state_id":"1066"},
    {"city_id":"15541","city_name":"Fa'id","state_id":"1067"},
    {"city_id":"15542","city_name":"Sarabiyum","state_id":"1067"},
    {"city_id":"15543","city_name":"al-Ismailiyah","state_id":"1067"},
    {"city_id":"15544","city_name":"Atfih","state_id":"1068"},
    {"city_id":"15545","city_name":"Awsim","state_id":"1068"},
    {"city_id":"15546","city_name":"Giza","state_id":"1068"},
    {"city_id":"15547","city_name":"Madinat Sittah Uktubar","state_id":"1068"},
    {"city_id":"15548","city_name":"Nahya","state_id":"1068"},
    {"city_id":"15549","city_name":"Saqqarah","state_id":"1068"},
    {"city_id":"15550","city_name":"al-'Ayyat","state_id":"1068"},
    {"city_id":"15551","city_name":"al-Badrashayn","state_id":"1068"},
    {"city_id":"15552","city_name":"al-Hawamidiyah","state_id":"1068"},
    {"city_id":"15553","city_name":"al-Jizah","state_id":"1068"},
    {"city_id":"15554","city_name":"al-Mansuriyah","state_id":"1068"},
    {"city_id":"15555","city_name":"al-Wahat-al-Bahriyah","state_id":"1068"},
    {"city_id":"15556","city_name":"as-Saff","state_id":"1068"},
    {"city_id":"15557","city_name":"Ashmun","state_id":"1069"},
    {"city_id":"15558","city_name":"Birkat-as-Sab'","state_id":"1069"},
    {"city_id":"15559","city_name":"Milij","state_id":"1069"},
    {"city_id":"15560","city_name":"Minuf","state_id":"1069"},
    {"city_id":"15561","city_name":"Quwaysina","state_id":"1069"},
    {"city_id":"15562","city_name":"Shibin-al-Kawm","state_id":"1069"},
    {"city_id":"15563","city_name":"Sirs-al-Layyanah","state_id":"1069"},
    {"city_id":"15564","city_name":"Tala","state_id":"1069"},
    {"city_id":"15565","city_name":"al-Bajur","state_id":"1069"},
    {"city_id":"15566","city_name":"al-Batanun","state_id":"1069"},
    {"city_id":"15567","city_name":"ash-Shuhada","state_id":"1069"},
    {"city_id":"15568","city_name":"Abu Qurqas","state_id":"1070"},
    {"city_id":"15569","city_name":"Bani Mazar","state_id":"1070"},
    {"city_id":"15570","city_name":"Dayr Mawas","state_id":"1070"},
    {"city_id":"15571","city_name":"Magagah","state_id":"1070"},
    {"city_id":"15572","city_name":"Mallawi","state_id":"1070"},
    {"city_id":"15573","city_name":"Matay","state_id":"1070"},
    {"city_id":"15574","city_name":"Samalut","state_id":"1070"},
    {"city_id":"15575","city_name":"Tallah","state_id":"1070"},
    {"city_id":"15576","city_name":"Tandah","state_id":"1070"},
    {"city_id":"15577","city_name":"al-Anayim","state_id":"1070"},
    {"city_id":"15578","city_name":"al-Fikriyah","state_id":"1070"},
    {"city_id":"15579","city_name":"al-Minya","state_id":"1070"},
    {"city_id":"15580","city_name":"ar-Rawdah","state_id":"1070"},
    {"city_id":"15581","city_name":"Badr City","state_id":"1071"},
    {"city_id":"15582","city_name":"Heliopolis","state_id":"1071"},
    {"city_id":"15583","city_name":"al-Qahira","state_id":"1071"},
    {"city_id":"15584","city_name":"Abu Za'bal","state_id":"1072"},
    {"city_id":"15585","city_name":"Banha","state_id":"1072"},
    {"city_id":"15586","city_name":"Qalyub","state_id":"1072"},
    {"city_id":"15587","city_name":"Shubra al-Khaymah","state_id":"1072"},
    {"city_id":"15588","city_name":"Sibin-al-Qanatir","state_id":"1072"},
    {"city_id":"15589","city_name":"Tukh","state_id":"1072"},
    {"city_id":"15590","city_name":"al-Khankah","state_id":"1072"},
    {"city_id":"15591","city_name":"al-Qanatir-al-Khayriyah","state_id":"1072"},
    {"city_id":"15592","city_name":"al-Uqsur","state_id":"1073"},
    {"city_id":"15593","city_name":"as-Suways","state_id":"1075"},
    {"city_id":"15594","city_name":"Abu Hammad","state_id":"1076"},
    {"city_id":"15595","city_name":"Abu Kabir","state_id":"1076"},
    {"city_id":"15596","city_name":"Bilbays","state_id":"1076"},
    {"city_id":"15597","city_name":"Diyarb Najm","state_id":"1076"},
    {"city_id":"15598","city_name":"Faqus","state_id":"1076"},
    {"city_id":"15599","city_name":"Hihya","state_id":"1076"},
    {"city_id":"15600","city_name":"Kafr Saqr","state_id":"1076"},
    {"city_id":"15601","city_name":"Mashtul-as-Suq","state_id":"1076"},
    {"city_id":"15602","city_name":"Minyat-al-Qamh","state_id":"1076"},
    {"city_id":"15603","city_name":"al-Ashir mir-Ramadan","state_id":"1076"},
    {"city_id":"15604","city_name":"al-Husayniyah","state_id":"1076"},
    {"city_id":"15605","city_name":"al-Ibrahimiyah","state_id":"1076"},
    {"city_id":"15606","city_name":"al-Qanayat","state_id":"1076"},
    {"city_id":"15607","city_name":"al-Qassasin","state_id":"1076"},
    {"city_id":"15608","city_name":"al-Qurayn","state_id":"1076"},
    {"city_id":"15609","city_name":"as-Salihiyah","state_id":"1076"},
    {"city_id":"15610","city_name":"at-Tall-al-Kabir","state_id":"1076"},
    {"city_id":"15611","city_name":"az-Zaqaziq","state_id":"1076"},
    {"city_id":"15612","city_name":"Ahuachapan","state_id":"1077"},
    {"city_id":"15613","city_name":"Atiquizaya","state_id":"1077"},
    {"city_id":"15614","city_name":"Concepcion de Ataco","state_id":"1077"},
    {"city_id":"15615","city_name":"Guaymango","state_id":"1077"},
    {"city_id":"15616","city_name":"Jujutla","state_id":"1077"},
    {"city_id":"15617","city_name":"San Francisco Menendez","state_id":"1077"},
    {"city_id":"15618","city_name":"Tacuba","state_id":"1077"},
    {"city_id":"15619","city_name":"Ilobasco","state_id":"1078"},
    {"city_id":"15620","city_name":"Sensuntepeque","state_id":"1078"},
    {"city_id":"15621","city_name":"Victoria","state_id":"1078"},
    {"city_id":"15622","city_name":"Chalatenango","state_id":"1079"},
    {"city_id":"15623","city_name":"La Palma","state_id":"1079"},
    {"city_id":"15624","city_name":"Nueva Concepcion","state_id":"1079"},
    {"city_id":"15625","city_name":"San Francisco Morazan","state_id":"1079"},
    {"city_id":"15626","city_name":"Cojutepeque","state_id":"1080"},
    {"city_id":"15627","city_name":"San Pedro Perulapan","state_id":"1080"},
    {"city_id":"15628","city_name":"Suchitoto","state_id":"1080"},
    {"city_id":"15629","city_name":"Tecoluca","state_id":"1080"},
    {"city_id":"15630","city_name":"Tenancingo","state_id":"1080"},
    {"city_id":"15631","city_name":"Cacaopera","state_id":"1084"},
    {"city_id":"15632","city_name":"Corinto","state_id":"1084"},
    {"city_id":"15633","city_name":"Gotera","state_id":"1084"},
    {"city_id":"15634","city_name":"Guatajiagua","state_id":"1084"},
    {"city_id":"15635","city_name":"Jocoro","state_id":"1084"},
    {"city_id":"15636","city_name":"Sociedad","state_id":"1084"},
    {"city_id":"15637","city_name":"Acajutla","state_id":"1089"},
    {"city_id":"15638","city_name":"Armenia","state_id":"1089"},
    {"city_id":"15639","city_name":"Izalco","state_id":"1089"},
    {"city_id":"15640","city_name":"Juayua","state_id":"1089"},
    {"city_id":"15641","city_name":"Nahuizalco","state_id":"1089"},
    {"city_id":"15642","city_name":"San Antonio del Monte","state_id":"1089"},
    {"city_id":"15643","city_name":"San Julian","state_id":"1089"},
    {"city_id":"15644","city_name":"Sonsonate","state_id":"1089"},
    {"city_id":"15645","city_name":"Sonzacate","state_id":"1089"},
    {"city_id":"15646","city_name":"Berlin","state_id":"1090"},
    {"city_id":"15647","city_name":"Concepcion Batres","state_id":"1090"},
    {"city_id":"15648","city_name":"Estanzuelas","state_id":"1090"},
    {"city_id":"15649","city_name":"Jiquilisco","state_id":"1090"},
    {"city_id":"15650","city_name":"Jucuapa","state_id":"1090"},
    {"city_id":"15651","city_name":"Jucuaran","state_id":"1090"},
    {"city_id":"15652","city_name":"Ozatlan","state_id":"1090"},
    {"city_id":"15653","city_name":"Puerto El Triunfo","state_id":"1090"},
    {"city_id":"15654","city_name":"San Agustin","state_id":"1090"},
    {"city_id":"15655","city_name":"Santa Elena","state_id":"1090"},
    {"city_id":"15656","city_name":"Santiago de Maria","state_id":"1090"},
    {"city_id":"15657","city_name":"Usulutan","state_id":"1090"},
    {"city_id":"15658","city_name":"Pale","state_id":"1091"},
    {"city_id":"15659","city_name":"Ebebiyin","state_id":"1095"},
    {"city_id":"15660","city_name":"Mikomeseng","state_id":"1095"},
    {"city_id":"15661","city_name":"Bata","state_id":"1096"},
    {"city_id":"15662","city_name":"Mbini","state_id":"1096"},
    {"city_id":"15663","city_name":"Aconibe","state_id":"1097"},
    {"city_id":"15664","city_name":"Anisoc","state_id":"1097"},
    {"city_id":"15665","city_name":"Mongomo","state_id":"1097"},
    {"city_id":"15666","city_name":"Nsok","state_id":"1097"},
    {"city_id":"15667","city_name":"Keren","state_id":"1098"},
    {"city_id":"15668","city_name":"Addi Kwala","state_id":"1099"},
    {"city_id":"15669","city_name":"Addi Ugri","state_id":"1099"},
    {"city_id":"15670","city_name":"Asseb","state_id":"1100"},
    {"city_id":"15671","city_name":"Beylul","state_id":"1100"},
    {"city_id":"15672","city_name":"Edd","state_id":"1100"},
    {"city_id":"15673","city_name":"Mersa Fatma","state_id":"1100"},
    {"city_id":"15674","city_name":"Ak'ordat","state_id":"1101"},
    {"city_id":"15675","city_name":"Barentu","state_id":"1101"},
    {"city_id":"15676","city_name":"Teseney","state_id":"1101"},
    {"city_id":"15677","city_name":"Asmara","state_id":"1102"},
    {"city_id":"15678","city_name":"Ginda","state_id":"1102"},
    {"city_id":"15679","city_name":"Himbirti","state_id":"1102"},
    {"city_id":"15680","city_name":"Nefasit","state_id":"1102"},
    {"city_id":"15681","city_name":"Addi K'eyih","state_id":"1103"},
    {"city_id":"15682","city_name":"Dek'emhare","state_id":"1103"},
    {"city_id":"15683","city_name":"Mitsiwa","state_id":"1103"},
    {"city_id":"15684","city_name":"Sen'afe","state_id":"1103"},
    {"city_id":"15685","city_name":"Aasmae","state_id":"1104"},
    {"city_id":"15686","city_name":"Aaviku","state_id":"1104"},
    {"city_id":"15687","city_name":"Aegviidu","state_id":"1104"},
    {"city_id":"15688","city_name":"Aigrumae","state_id":"1104"},
    {"city_id":"15689","city_name":"Aila","state_id":"1104"},
    {"city_id":"15690","city_name":"Alavere","state_id":"1104"},
    {"city_id":"15691","city_name":"Alliku","state_id":"1104"},
    {"city_id":"15692","city_name":"Amari","state_id":"1104"},
    {"city_id":"15693","city_name":"Anija","state_id":"1104"},
    {"city_id":"15694","city_name":"Ardu","state_id":"1104"},
    {"city_id":"15695","city_name":"Arukula","state_id":"1104"},
    {"city_id":"15696","city_name":"Aruvalla","state_id":"1104"},
    {"city_id":"15697","city_name":"Assaku","state_id":"1104"},
    {"city_id":"15698","city_name":"Ellamaa","state_id":"1104"},
    {"city_id":"15699","city_name":"Haabneeme","state_id":"1104"},
    {"city_id":"15700","city_name":"Habaja","state_id":"1104"},
    {"city_id":"15701","city_name":"Haiba","state_id":"1104"},
    {"city_id":"15702","city_name":"Haljava","state_id":"1104"},
    {"city_id":"15703","city_name":"Hara","state_id":"1104"},
    {"city_id":"15704","city_name":"Harju-Risti","state_id":"1104"},
    {"city_id":"15705","city_name":"Harku","state_id":"1104"},
    {"city_id":"15706","city_name":"Harkujarve","state_id":"1104"},
    {"city_id":"15707","city_name":"Harma","state_id":"1104"},
    {"city_id":"15708","city_name":"Huuru","state_id":"1104"},
    {"city_id":"15709","city_name":"Ilmandu","state_id":"1104"},
    {"city_id":"15710","city_name":"Iru","state_id":"1104"},
    {"city_id":"15711","city_name":"Jagala","state_id":"1104"},
    {"city_id":"15712","city_name":"Jalgimae","state_id":"1104"},
    {"city_id":"15713","city_name":"Jarsi","state_id":"1104"},
    {"city_id":"15714","city_name":"Jarvekula","state_id":"1104"},
    {"city_id":"15715","city_name":"Jogisoo","state_id":"1104"},
    {"city_id":"15716","city_name":"Juri","state_id":"1104"},
    {"city_id":"15717","city_name":"Kaasiku","state_id":"1104"},
    {"city_id":"15718","city_name":"Kaberneeme","state_id":"1104"},
    {"city_id":"15719","city_name":"Kahala","state_id":"1104"},
    {"city_id":"15720","city_name":"Kalesi","state_id":"1104"},
    {"city_id":"15721","city_name":"Kallavere","state_id":"1104"},
    {"city_id":"15722","city_name":"Karjakula","state_id":"1104"},
    {"city_id":"15723","city_name":"Karla","state_id":"1104"},
    {"city_id":"15724","city_name":"Kasepere","state_id":"1104"},
    {"city_id":"15725","city_name":"Kasispea","state_id":"1104"},
    {"city_id":"15726","city_name":"Kehra","state_id":"1104"},
    {"city_id":"15727","city_name":"Keila","state_id":"1104"},
    {"city_id":"15728","city_name":"Keila-Joa","state_id":"1104"},
    {"city_id":"15729","city_name":"Kelvingi","state_id":"1104"},
    {"city_id":"15730","city_name":"Kiia","state_id":"1104"},
    {"city_id":"15731","city_name":"Kiili","state_id":"1104"},
    {"city_id":"15732","city_name":"Kiisa","state_id":"1104"},
    {"city_id":"15733","city_name":"Kiiu","state_id":"1104"},
    {"city_id":"15734","city_name":"Klooga","state_id":"1104"},
    {"city_id":"15735","city_name":"Kloogaranna","state_id":"1104"},
    {"city_id":"15736","city_name":"Kohatu","state_id":"1104"},
    {"city_id":"15737","city_name":"Kolga","state_id":"1104"},
    {"city_id":"15738","city_name":"Kolga-Aabla","state_id":"1104"},
    {"city_id":"15739","city_name":"Kolgakula","state_id":"1104"},
    {"city_id":"15740","city_name":"Konnu","state_id":"1104"},
    {"city_id":"15741","city_name":"Kose","state_id":"1104"},
    {"city_id":"15742","city_name":"Kose-Uuemoisa","state_id":"1104"},
    {"city_id":"15743","city_name":"Kostivere","state_id":"1104"},
    {"city_id":"15744","city_name":"Krei","state_id":"1104"},
    {"city_id":"15745","city_name":"Kuivajoe","state_id":"1104"},
    {"city_id":"15746","city_name":"Kumna","state_id":"1104"},
    {"city_id":"15747","city_name":"Kurtna","state_id":"1104"},
    {"city_id":"15748","city_name":"Kuusalu","state_id":"1104"},
    {"city_id":"15749","city_name":"Laabi","state_id":"1104"},
    {"city_id":"15750","city_name":"Laagri","state_id":"1104"},
    {"city_id":"15751","city_name":"Lagedi","state_id":"1104"},
    {"city_id":"15752","city_name":"Laitse","state_id":"1104"},
    {"city_id":"15753","city_name":"Laulasmaa","state_id":"1104"},
    {"city_id":"15754","city_name":"Lehetu","state_id":"1104"},
    {"city_id":"15755","city_name":"Lehola","state_id":"1104"},
    {"city_id":"15756","city_name":"Lehtmetsa","state_id":"1104"},
    {"city_id":"15757","city_name":"Leppneeme","state_id":"1104"},
    {"city_id":"15758","city_name":"Liikva","state_id":"1104"},
    {"city_id":"15759","city_name":"Lilli","state_id":"1104"},
    {"city_id":"15760","city_name":"Lohusalu","state_id":"1104"},
    {"city_id":"15761","city_name":"Loksa","state_id":"1104"},
    {"city_id":"15762","city_name":"Lokuti","state_id":"1104"},
    {"city_id":"15763","city_name":"Loo","state_id":"1104"},
    {"city_id":"15764","city_name":"Lubja","state_id":"1104"},
    {"city_id":"15765","city_name":"Luige","state_id":"1104"},
    {"city_id":"15766","city_name":"Maardu","state_id":"1104"},
    {"city_id":"15767","city_name":"Maidla","state_id":"1104"},
    {"city_id":"15768","city_name":"Manniku","state_id":"1104"},
    {"city_id":"15769","city_name":"Metsakasti","state_id":"1104"},
    {"city_id":"15770","city_name":"Metsanurme","state_id":"1104"},
    {"city_id":"15771","city_name":"Miiduranna","state_id":"1104"},
    {"city_id":"15772","city_name":"Munalaskme","state_id":"1104"},
    {"city_id":"15773","city_name":"Muraste","state_id":"1104"},
    {"city_id":"15774","city_name":"Muuga","state_id":"1104"},
    {"city_id":"15775","city_name":"Nabala","state_id":"1104"},
    {"city_id":"15776","city_name":"Neeme","state_id":"1104"},
    {"city_id":"15777","city_name":"Ohtu","state_id":"1104"},
    {"city_id":"15778","city_name":"Ojasoo","state_id":"1104"},
    {"city_id":"15779","city_name":"Oru","state_id":"1104"},
    {"city_id":"15780","city_name":"Padise","state_id":"1104"},
    {"city_id":"15781","city_name":"Pae","state_id":"1104"},
    {"city_id":"15782","city_name":"Paekna","state_id":"1104"},
    {"city_id":"15783","city_name":"Pajupea","state_id":"1104"},
    {"city_id":"15784","city_name":"Paldiski","state_id":"1104"},
    {"city_id":"15785","city_name":"Palvere","state_id":"1104"},
    {"city_id":"15786","city_name":"Parispea","state_id":"1104"},
    {"city_id":"15787","city_name":"Patika","state_id":"1104"},
    {"city_id":"15788","city_name":"Paunkula","state_id":"1104"},
    {"city_id":"15789","city_name":"Peetri","state_id":"1104"},
    {"city_id":"15790","city_name":"Peningi","state_id":"1104"},
    {"city_id":"15791","city_name":"Perila","state_id":"1104"},
    {"city_id":"15792","city_name":"Pikva","state_id":"1104"},
    {"city_id":"15793","city_name":"Pillapalu","state_id":"1104"},
    {"city_id":"15794","city_name":"Pringi","state_id":"1104"},
    {"city_id":"15795","city_name":"Puunsi","state_id":"1104"},
    {"city_id":"15796","city_name":"Raasiku","state_id":"1104"},
    {"city_id":"15797","city_name":"Rae","state_id":"1104"},
    {"city_id":"15798","city_name":"Randvere","state_id":"1104"},
    {"city_id":"15799","city_name":"Rannamoisa","state_id":"1104"},
    {"city_id":"15800","city_name":"Ravila","state_id":"1104"},
    {"city_id":"15801","city_name":"Riisipere","state_id":"1104"},
    {"city_id":"15802","city_name":"Rohuneeme","state_id":"1104"},
    {"city_id":"15803","city_name":"Roobuka","state_id":"1104"},
    {"city_id":"15804","city_name":"Ruila","state_id":"1104"},
    {"city_id":"15805","city_name":"Rummu","state_id":"1104"},
    {"city_id":"15806","city_name":"Saha","state_id":"1104"},
    {"city_id":"15807","city_name":"Saku","state_id":"1104"},
    {"city_id":"15808","city_name":"Saue","state_id":"1104"},
    {"city_id":"15809","city_name":"Saula","state_id":"1104"},
    {"city_id":"15810","city_name":"Saunja","state_id":"1104"},
    {"city_id":"15811","city_name":"Suurpea","state_id":"1104"},
    {"city_id":"15812","city_name":"Suurupi","state_id":"1104"},
    {"city_id":"15813","city_name":"Tabasalu","state_id":"1104"},
    {"city_id":"15814","city_name":"Tagadi","state_id":"1104"},
    {"city_id":"15815","city_name":"Tagametsa","state_id":"1104"},
    {"city_id":"15816","city_name":"Tallinn","state_id":"1104"},
    {"city_id":"15817","city_name":"Tammneeme","state_id":"1104"},
    {"city_id":"15818","city_name":"Tiskre","state_id":"1104"},
    {"city_id":"15819","city_name":"Todva","state_id":"1104"},
    {"city_id":"15820","city_name":"Turba","state_id":"1104"},
    {"city_id":"15821","city_name":"Turisalu","state_id":"1104"},
    {"city_id":"15822","city_name":"Tutermaa","state_id":"1104"},
    {"city_id":"15823","city_name":"Tuula","state_id":"1104"},
    {"city_id":"15824","city_name":"Tuulna","state_id":"1104"},
    {"city_id":"15825","city_name":"Uksnurme","state_id":"1104"},
    {"city_id":"15826","city_name":"Ulejoe","state_id":"1104"},
    {"city_id":"15827","city_name":"Uuri","state_id":"1104"},
    {"city_id":"15828","city_name":"Uuskula","state_id":"1104"},
    {"city_id":"15829","city_name":"Vaana","state_id":"1104"},
    {"city_id":"15830","city_name":"Vaana-Joesuu","state_id":"1104"},
    {"city_id":"15831","city_name":"Vaida","state_id":"1104"},
    {"city_id":"15832","city_name":"Vaidasoo","state_id":"1104"},
    {"city_id":"15833","city_name":"Valingu","state_id":"1104"},
    {"city_id":"15834","city_name":"Valkla","state_id":"1104"},
    {"city_id":"15835","city_name":"Vanamoisa","state_id":"1104"},
    {"city_id":"15836","city_name":"Vardja","state_id":"1104"},
    {"city_id":"15837","city_name":"Vasalemma","state_id":"1104"},
    {"city_id":"15838","city_name":"Vaskjala","state_id":"1104"},
    {"city_id":"15839","city_name":"Vatsla","state_id":"1104"},
    {"city_id":"15840","city_name":"Veskikula","state_id":"1104"},
    {"city_id":"15841","city_name":"Vihasoo","state_id":"1104"},
    {"city_id":"15842","city_name":"Viimsi","state_id":"1104"},
    {"city_id":"15843","city_name":"Viinistu","state_id":"1104"},
    {"city_id":"15844","city_name":"Viti","state_id":"1104"},
    {"city_id":"15845","city_name":"Emmaste","state_id":"1105"},
    {"city_id":"15846","city_name":"Jausa","state_id":"1105"},
    {"city_id":"15847","city_name":"Kaina","state_id":"1105"},
    {"city_id":"15848","city_name":"Kardla","state_id":"1105"},
    {"city_id":"15849","city_name":"Korgessaare","state_id":"1105"},
    {"city_id":"15850","city_name":"Lauka","state_id":"1105"},
    {"city_id":"15851","city_name":"Lope","state_id":"1105"},
    {"city_id":"15852","city_name":"Mannamaa","state_id":"1105"},
    {"city_id":"15853","city_name":"Putkaste","state_id":"1105"},
    {"city_id":"15854","city_name":"Suuremoisa","state_id":"1105"},
    {"city_id":"15855","city_name":"Aa","state_id":"1106"},
    {"city_id":"15856","city_name":"Alajoe","state_id":"1106"},
    {"city_id":"15857","city_name":"Aseri","state_id":"1106"},
    {"city_id":"15858","city_name":"Avinurme","state_id":"1106"},
    {"city_id":"15859","city_name":"Edise","state_id":"1106"},
    {"city_id":"15860","city_name":"Erra","state_id":"1106"},
    {"city_id":"15861","city_name":"Iisaku","state_id":"1106"},
    {"city_id":"15862","city_name":"Jarve","state_id":"1106"},
    {"city_id":"15863","city_name":"Johvi","state_id":"1106"},
    {"city_id":"15864","city_name":"Kahula","state_id":"1106"},
    {"city_id":"15865","city_name":"Kiikla","state_id":"1106"},
    {"city_id":"15866","city_name":"Kivioli","state_id":"1106"},
    {"city_id":"15867","city_name":"Kohtla-Jarve","state_id":"1106"},
    {"city_id":"15868","city_name":"Kohtla-Nomme","state_id":"1106"},
    {"city_id":"15869","city_name":"Konju","state_id":"1106"},
    {"city_id":"15870","city_name":"Kose","state_id":"1106"},
    {"city_id":"15871","city_name":"Kuremae","state_id":"1106"},
    {"city_id":"15872","city_name":"Kurtna","state_id":"1106"},
    {"city_id":"15873","city_name":"Lohusuu","state_id":"1106"},
    {"city_id":"15874","city_name":"Luganuse","state_id":"1106"},
    {"city_id":"15875","city_name":"Maetaguse","state_id":"1106"},
    {"city_id":"15876","city_name":"Maidla","state_id":"1106"},
    {"city_id":"15877","city_name":"Narva","state_id":"1106"},
    {"city_id":"15878","city_name":"Narva-Joesuu","state_id":"1106"},
    {"city_id":"15879","city_name":"Olgina","state_id":"1106"},
    {"city_id":"15880","city_name":"Pagari","state_id":"1106"},
    {"city_id":"15881","city_name":"Puhajoe","state_id":"1106"},
    {"city_id":"15882","city_name":"Purtse","state_id":"1106"},
    {"city_id":"15883","city_name":"Pussi","state_id":"1106"},
    {"city_id":"15884","city_name":"Rannu","state_id":"1106"},
    {"city_id":"15885","city_name":"Saka","state_id":"1106"},
    {"city_id":"15886","city_name":"Savala","state_id":"1106"},
    {"city_id":"15887","city_name":"Sillamae","state_id":"1106"},
    {"city_id":"15888","city_name":"Sinimae","state_id":"1106"},
    {"city_id":"15889","city_name":"Soldina","state_id":"1106"},
    {"city_id":"15890","city_name":"Sompa","state_id":"1106"},
    {"city_id":"15891","city_name":"Sonda","state_id":"1106"},
    {"city_id":"15892","city_name":"Tammiku","state_id":"1106"},
    {"city_id":"15893","city_name":"Toila","state_id":"1106"},
    {"city_id":"15894","city_name":"Tudulinna","state_id":"1106"},
    {"city_id":"15895","city_name":"Ulvi","state_id":"1106"},
    {"city_id":"15896","city_name":"Vaivara","state_id":"1106"},
    {"city_id":"15897","city_name":"Varja","state_id":"1106"},
    {"city_id":"15898","city_name":"Vasavere","state_id":"1106"},
    {"city_id":"15899","city_name":"Voka","state_id":"1106"},
    {"city_id":"15900","city_name":"Ahula","state_id":"1107"},
    {"city_id":"15901","city_name":"Aiamaa","state_id":"1107"},
    {"city_id":"15902","city_name":"Albu","state_id":"1107"},
    {"city_id":"15903","city_name":"Ambla","state_id":"1107"},
    {"city_id":"15904","city_name":"Anari","state_id":"1107"},
    {"city_id":"15905","city_name":"Anna","state_id":"1107"},
    {"city_id":"15906","city_name":"Aravete","state_id":"1107"},
    {"city_id":"15907","city_name":"Ervita","state_id":"1107"},
    {"city_id":"15908","city_name":"Imavere","state_id":"1107"},
    {"city_id":"15909","city_name":"Janeda","state_id":"1107"},
    {"city_id":"15910","city_name":"Jarva-Jaani","state_id":"1107"},
    {"city_id":"15911","city_name":"Jogisoo","state_id":"1107"},
    {"city_id":"15912","city_name":"Jootme","state_id":"1107"},
    {"city_id":"15913","city_name":"Kaalepi","state_id":"1107"},
    {"city_id":"15914","city_name":"Kabala","state_id":"1107"},
    {"city_id":"15915","city_name":"Kahala","state_id":"1107"},
    {"city_id":"15916","city_name":"Karavete","state_id":"1107"},
    {"city_id":"15917","city_name":"Karevere","state_id":"1107"},
    {"city_id":"15918","city_name":"Karinu","state_id":"1107"},
    {"city_id":"15919","city_name":"Kasukonna","state_id":"1107"},
    {"city_id":"15920","city_name":"Kirna","state_id":"1107"},
    {"city_id":"15921","city_name":"Koeru","state_id":"1107"},
    {"city_id":"15922","city_name":"Koigi","state_id":"1107"},
    {"city_id":"15923","city_name":"Kolu","state_id":"1107"},
    {"city_id":"15924","city_name":"Kuksema","state_id":"1107"},
    {"city_id":"15925","city_name":"Laupa","state_id":"1107"},
    {"city_id":"15926","city_name":"Lehtse","state_id":"1107"},
    {"city_id":"15927","city_name":"Lokuta","state_id":"1107"},
    {"city_id":"15928","city_name":"Loola","state_id":"1107"},
    {"city_id":"15929","city_name":"Muusleri","state_id":"1107"},
    {"city_id":"15930","city_name":"Oisu","state_id":"1107"},
    {"city_id":"15931","city_name":"Paide","state_id":"1107"},
    {"city_id":"15932","city_name":"Painurme","state_id":"1107"},
    {"city_id":"15933","city_name":"Peetri","state_id":"1107"},
    {"city_id":"15934","city_name":"Poikva","state_id":"1107"},
    {"city_id":"15935","city_name":"Reopalu","state_id":"1107"},
    {"city_id":"15936","city_name":"Retla","state_id":"1107"},
    {"city_id":"15937","city_name":"Roa","state_id":"1107"},
    {"city_id":"15938","city_name":"Roosna","state_id":"1107"},
    {"city_id":"15939","city_name":"Roosna-Alliku","state_id":"1107"},
    {"city_id":"15940","city_name":"Sarevere","state_id":"1107"},
    {"city_id":"15941","city_name":"Sargvere","state_id":"1107"},
    {"city_id":"15942","city_name":"Taikse","state_id":"1107"},
    {"city_id":"15943","city_name":"Tarbja","state_id":"1107"},
    {"city_id":"15944","city_name":"Turi","state_id":"1107"},
    {"city_id":"15945","city_name":"Turi-Alliku","state_id":"1107"},
    {"city_id":"15946","city_name":"Vaatsa","state_id":"1107"},
    {"city_id":"15947","city_name":"Vahukula","state_id":"1107"},
    {"city_id":"15948","city_name":"Vao","state_id":"1107"},
    {"city_id":"15949","city_name":"Viisu","state_id":"1107"},
    {"city_id":"15950","city_name":"Villevere","state_id":"1107"},
    {"city_id":"15951","city_name":"Adavere","state_id":"1108"},
    {"city_id":"15952","city_name":"Esku","state_id":"1108"},
    {"city_id":"15953","city_name":"Harjanurme","state_id":"1108"},
    {"city_id":"15954","city_name":"Jogeva","state_id":"1108"},
    {"city_id":"15955","city_name":"Kaarepere","state_id":"1108"},
    {"city_id":"15956","city_name":"Kalana","state_id":"1108"},
    {"city_id":"15957","city_name":"Kalme","state_id":"1108"},
    {"city_id":"15958","city_name":"Kamari","state_id":"1108"},
    {"city_id":"15959","city_name":"Karde","state_id":"1108"},
    {"city_id":"15960","city_name":"Kasepaa","state_id":"1108"},
    {"city_id":"15961","city_name":"Kassinurme","state_id":"1108"},
    {"city_id":"15962","city_name":"Konnu","state_id":"1108"},
    {"city_id":"15963","city_name":"Kudina","state_id":"1108"},
    {"city_id":"15964","city_name":"Kukita","state_id":"1108"},
    {"city_id":"15965","city_name":"Kuremaa","state_id":"1108"},
    {"city_id":"15966","city_name":"Kurista","state_id":"1108"},
    {"city_id":"15967","city_name":"Lahavere","state_id":"1108"},
    {"city_id":"15968","city_name":"Laiuse","state_id":"1108"},
    {"city_id":"15969","city_name":"Laiusevalja","state_id":"1108"},
    {"city_id":"15970","city_name":"Leedi","state_id":"1108"},
    {"city_id":"15971","city_name":"Lustivere","state_id":"1108"},
    {"city_id":"15972","city_name":"Luua","state_id":"1108"},
    {"city_id":"15973","city_name":"Maarja","state_id":"1108"},
    {"city_id":"15974","city_name":"Mallikvere","state_id":"1108"},
    {"city_id":"15975","city_name":"Metsakula","state_id":"1108"},
    {"city_id":"15976","city_name":"Mohkula","state_id":"1108"},
    {"city_id":"15977","city_name":"Mustvee","state_id":"1108"},
    {"city_id":"15978","city_name":"Neanurme","state_id":"1108"},
    {"city_id":"15979","city_name":"Nova","state_id":"1108"},
    {"city_id":"15980","city_name":"Omedu","state_id":"1108"},
    {"city_id":"15981","city_name":"Ouna","state_id":"1108"},
    {"city_id":"15982","city_name":"Painkula","state_id":"1108"},
    {"city_id":"15983","city_name":"Pajusi","state_id":"1108"},
    {"city_id":"15984","city_name":"Pala","state_id":"1108"},
    {"city_id":"15985","city_name":"Palamuse","state_id":"1108"},
    {"city_id":"15986","city_name":"Pataste","state_id":"1108"},
    {"city_id":"15987","city_name":"Pauastvere","state_id":"1108"},
    {"city_id":"15988","city_name":"Pikkjarve","state_id":"1108"},
    {"city_id":"15989","city_name":"Pikknurme","state_id":"1108"},
    {"city_id":"15990","city_name":"Pisisaare","state_id":"1108"},
    {"city_id":"15991","city_name":"Poltsamaa","state_id":"1108"},
    {"city_id":"15992","city_name":"Poora","state_id":"1108"},
    {"city_id":"15993","city_name":"Puurmani","state_id":"1108"},
    {"city_id":"15994","city_name":"Raabise","state_id":"1108"},
    {"city_id":"15995","city_name":"Raja","state_id":"1108"},
    {"city_id":"15996","city_name":"Saare","state_id":"1108"},
    {"city_id":"15997","city_name":"Sadala","state_id":"1108"},
    {"city_id":"15998","city_name":"Sadukula","state_id":"1108"},
    {"city_id":"15999","city_name":"Siimusti","state_id":"1108"},
    {"city_id":"16000","city_name":"Tabivere","state_id":"1108"},
    {"city_id":"16001","city_name":"Tahkvere","state_id":"1108"},
    {"city_id":"16002","city_name":"Tiheda","state_id":"1108"},
    {"city_id":"16003","city_name":"Toikvere","state_id":"1108"},
    {"city_id":"16004","city_name":"Torma","state_id":"1108"},
    {"city_id":"16005","city_name":"Umbusi","state_id":"1108"},
    {"city_id":"16006","city_name":"Vagari","state_id":"1108"},
    {"city_id":"16007","city_name":"Vaiatu","state_id":"1108"},
    {"city_id":"16008","city_name":"Vaike-Kamari","state_id":"1108"},
    {"city_id":"16009","city_name":"Vaimastvere","state_id":"1108"},
    {"city_id":"16010","city_name":"Valgma","state_id":"1108"},
    {"city_id":"16011","city_name":"Visusti","state_id":"1108"},
    {"city_id":"16012","city_name":"Voduvere","state_id":"1108"},
    {"city_id":"16013","city_name":"Vohmanomme","state_id":"1108"},
    {"city_id":"16014","city_name":"Voisiku","state_id":"1108"},
    {"city_id":"16015","city_name":"Voldi","state_id":"1108"},
    {"city_id":"16016","city_name":"Voore","state_id":"1108"},
    {"city_id":"16017","city_name":"Votikvere","state_id":"1108"},
    {"city_id":"16018","city_name":"Haapsalu","state_id":"1109"},
    {"city_id":"16019","city_name":"Hullo","state_id":"1109"},
    {"city_id":"16020","city_name":"Joodre","state_id":"1109"},
    {"city_id":"16021","city_name":"Kirbla","state_id":"1109"},
    {"city_id":"16022","city_name":"Kirimae","state_id":"1109"},
    {"city_id":"16023","city_name":"Koluvere","state_id":"1109"},
    {"city_id":"16024","city_name":"Komsi","state_id":"1109"},
    {"city_id":"16025","city_name":"Kullamaa","state_id":"1109"},
    {"city_id":"16026","city_name":"Lihula","state_id":"1109"},
    {"city_id":"16027","city_name":"Liivi","state_id":"1109"},
    {"city_id":"16028","city_name":"Linnamae","state_id":"1109"},
    {"city_id":"16029","city_name":"Martna","state_id":"1109"},
    {"city_id":"16030","city_name":"Nigula","state_id":"1109"},
    {"city_id":"16031","city_name":"Nova","state_id":"1109"},
    {"city_id":"16032","city_name":"Palivere","state_id":"1109"},
    {"city_id":"16033","city_name":"Palli","state_id":"1109"},
    {"city_id":"16034","city_name":"Panga","state_id":"1109"},
    {"city_id":"16035","city_name":"Paralepa","state_id":"1109"},
    {"city_id":"16036","city_name":"Piirsalu","state_id":"1109"},
    {"city_id":"16037","city_name":"Purksi","state_id":"1109"},
    {"city_id":"16038","city_name":"Rannakula","state_id":"1109"},
    {"city_id":"16039","city_name":"Risti","state_id":"1109"},
    {"city_id":"16040","city_name":"Roude","state_id":"1109"},
    {"city_id":"16041","city_name":"Sutlepa","state_id":"1109"},
    {"city_id":"16042","city_name":"Taebla","state_id":"1109"},
    {"city_id":"16043","city_name":"Tuudi","state_id":"1109"},
    {"city_id":"16044","city_name":"Uuemoisa","state_id":"1109"},
    {"city_id":"16045","city_name":"Variku","state_id":"1109"},
    {"city_id":"16046","city_name":"Vatla","state_id":"1109"},
    {"city_id":"16047","city_name":"Virtsu","state_id":"1109"},
    {"city_id":"16048","city_name":"Aaspere","state_id":"1110"},
    {"city_id":"16049","city_name":"Ama","state_id":"1110"},
    {"city_id":"16050","city_name":"Arkna","state_id":"1110"},
    {"city_id":"16051","city_name":"Assamalla","state_id":"1110"},
    {"city_id":"16052","city_name":"Avanduse","state_id":"1110"},
    {"city_id":"16053","city_name":"Avispea","state_id":"1110"},
    {"city_id":"16054","city_name":"Ebavere","state_id":"1110"},
    {"city_id":"16055","city_name":"Eipri","state_id":"1110"},
    {"city_id":"16056","city_name":"Essu","state_id":"1110"},
    {"city_id":"16057","city_name":"Haljala","state_id":"1110"},
    {"city_id":"16058","city_name":"Hulja","state_id":"1110"},
    {"city_id":"16059","city_name":"Imastu","state_id":"1110"},
    {"city_id":"16060","city_name":"Inju","state_id":"1110"},
    {"city_id":"16061","city_name":"Kadapiku","state_id":"1110"},
    {"city_id":"16062","city_name":"Kadila","state_id":"1110"},
    {"city_id":"16063","city_name":"Kadrina","state_id":"1110"},
    {"city_id":"16064","city_name":"Kakumae","state_id":"1110"},
    {"city_id":"16065","city_name":"Karitsa","state_id":"1110"},
    {"city_id":"16066","city_name":"Karu","state_id":"1110"},
    {"city_id":"16067","city_name":"Kasmu","state_id":"1110"},
    {"city_id":"16068","city_name":"Kihlevere","state_id":"1110"},
    {"city_id":"16069","city_name":"Kiku","state_id":"1110"},
    {"city_id":"16070","city_name":"Kiltsi","state_id":"1110"},
    {"city_id":"16071","city_name":"Kohala","state_id":"1110"},
    {"city_id":"16072","city_name":"Kunda","state_id":"1110"},
    {"city_id":"16073","city_name":"Kuti","state_id":"1110"},
    {"city_id":"16074","city_name":"Laekvere","state_id":"1110"},
    {"city_id":"16075","city_name":"Lasila","state_id":"1110"},
    {"city_id":"16076","city_name":"Lepna","state_id":"1110"},
    {"city_id":"16077","city_name":"Levala","state_id":"1110"},
    {"city_id":"16078","city_name":"Liigvalla","state_id":"1110"},
    {"city_id":"16079","city_name":"Modriku","state_id":"1110"},
    {"city_id":"16080","city_name":"Moe","state_id":"1110"},
    {"city_id":"16081","city_name":"Moora","state_id":"1110"},
    {"city_id":"16082","city_name":"Muuga","state_id":"1110"},
    {"city_id":"16083","city_name":"Napi","state_id":"1110"},
    {"city_id":"16084","city_name":"Paasvere","state_id":"1110"},
    {"city_id":"16085","city_name":"Pajusti","state_id":"1110"},
    {"city_id":"16086","city_name":"Pandivere","state_id":"1110"},
    {"city_id":"16087","city_name":"Piira","state_id":"1110"},
    {"city_id":"16088","city_name":"Pikevere","state_id":"1110"},
    {"city_id":"16089","city_name":"Podrangu","state_id":"1110"},
    {"city_id":"16090","city_name":"Podruse","state_id":"1110"},
    {"city_id":"16091","city_name":"Porkuni","state_id":"1110"},
    {"city_id":"16092","city_name":"Rahkla","state_id":"1110"},
    {"city_id":"16093","city_name":"Rakke","state_id":"1110"},
    {"city_id":"16094","city_name":"Rakvere","state_id":"1110"},
    {"city_id":"16095","city_name":"Ridakula","state_id":"1110"},
    {"city_id":"16096","city_name":"Roela","state_id":"1110"},
    {"city_id":"16097","city_name":"Roodevalja","state_id":"1110"},
    {"city_id":"16098","city_name":"Saase","state_id":"1110"},
    {"city_id":"16099","city_name":"Salda","state_id":"1110"},
    {"city_id":"16100","city_name":"Salla","state_id":"1110"},
    {"city_id":"16101","city_name":"Simuna","state_id":"1110"},
    {"city_id":"16102","city_name":"Someru","state_id":"1110"},
    {"city_id":"16103","city_name":"Taaravainu","state_id":"1110"},
    {"city_id":"16104","city_name":"Tamsalu","state_id":"1110"},
    {"city_id":"16105","city_name":"Tapa","state_id":"1110"},
    {"city_id":"16106","city_name":"Torma","state_id":"1110"},
    {"city_id":"16107","city_name":"Torremae","state_id":"1110"},
    {"city_id":"16108","city_name":"Triigi","state_id":"1110"},
    {"city_id":"16109","city_name":"Tudu","state_id":"1110"},
    {"city_id":"16110","city_name":"Ubja","state_id":"1110"},
    {"city_id":"16111","city_name":"Udriku","state_id":"1110"},
    {"city_id":"16112","city_name":"Uhtna","state_id":"1110"},
    {"city_id":"16113","city_name":"Ulvi","state_id":"1110"},
    {"city_id":"16114","city_name":"Undla","state_id":"1110"},
    {"city_id":"16115","city_name":"Ussimae","state_id":"1110"},
    {"city_id":"16116","city_name":"Uudekula","state_id":"1110"},
    {"city_id":"16117","city_name":"Vaekula","state_id":"1110"},
    {"city_id":"16118","city_name":"Vahakulmu","state_id":"1110"},
    {"city_id":"16119","city_name":"Vaiatu","state_id":"1110"},
    {"city_id":"16120","city_name":"Vaike-Maarja","state_id":"1110"},
    {"city_id":"16121","city_name":"Vajangu","state_id":"1110"},
    {"city_id":"16122","city_name":"Vao","state_id":"1110"},
    {"city_id":"16123","city_name":"Veltsi","state_id":"1110"},
    {"city_id":"16124","city_name":"Venevere","state_id":"1110"},
    {"city_id":"16125","city_name":"Vergi","state_id":"1110"},
    {"city_id":"16126","city_name":"Vetiku","state_id":"1110"},
    {"city_id":"16127","city_name":"Vihula","state_id":"1110"},
    {"city_id":"16128","city_name":"Viitna","state_id":"1110"},
    {"city_id":"16129","city_name":"Vinni","state_id":"1110"},
    {"city_id":"16130","city_name":"Viru-Jaagupi","state_id":"1110"},
    {"city_id":"16131","city_name":"Viru-Kabala","state_id":"1110"},
    {"city_id":"16132","city_name":"Viru-Nigula","state_id":"1110"},
    {"city_id":"16133","city_name":"Vohnja","state_id":"1110"},
    {"city_id":"16134","city_name":"Vosu","state_id":"1110"},
    {"city_id":"16135","city_name":"Vosupere","state_id":"1110"},
    {"city_id":"16136","city_name":"Ahaste","state_id":"1111"},
    {"city_id":"16137","city_name":"Aluste","state_id":"1111"},
    {"city_id":"16138","city_name":"Are","state_id":"1111"},
    {"city_id":"16139","city_name":"Arumetsa","state_id":"1111"},
    {"city_id":"16140","city_name":"Audru","state_id":"1111"},
    {"city_id":"16141","city_name":"Eametsa","state_id":"1111"},
    {"city_id":"16142","city_name":"Haademeeste","state_id":"1111"},
    {"city_id":"16143","city_name":"Halinga","state_id":"1111"},
    {"city_id":"16144","city_name":"Ikla","state_id":"1111"},
    {"city_id":"16145","city_name":"Ilvese","state_id":"1111"},
    {"city_id":"16146","city_name":"Jaamakula","state_id":"1111"},
    {"city_id":"16147","city_name":"Jaarja","state_id":"1111"},
    {"city_id":"16148","city_name":"Joesuu","state_id":"1111"},
    {"city_id":"16149","city_name":"Joopre","state_id":"1111"},
    {"city_id":"16150","city_name":"Kaansoo","state_id":"1111"},
    {"city_id":"16151","city_name":"Kabli","state_id":"1111"},
    {"city_id":"16152","city_name":"Kadjaste","state_id":"1111"},
    {"city_id":"16153","city_name":"Kaisma","state_id":"1111"},
    {"city_id":"16154","city_name":"Kalita","state_id":"1111"},
    {"city_id":"16155","city_name":"Kanakula","state_id":"1111"},
    {"city_id":"16156","city_name":"Kergu","state_id":"1111"},
    {"city_id":"16157","city_name":"Kihlepa","state_id":"1111"},
    {"city_id":"16158","city_name":"Kilingi-Nomme","state_id":"1111"},
    {"city_id":"16159","city_name":"Kilksama","state_id":"1111"},
    {"city_id":"16160","city_name":"Koima","state_id":"1111"},
    {"city_id":"16161","city_name":"Koonga","state_id":"1111"},
    {"city_id":"16162","city_name":"Krundikula","state_id":"1111"},
    {"city_id":"16163","city_name":"Laadi","state_id":"1111"},
    {"city_id":"16164","city_name":"Langerma","state_id":"1111"},
    {"city_id":"16165","city_name":"Lavassaare","state_id":"1111"},
    {"city_id":"16166","city_name":"Leipste","state_id":"1111"},
    {"city_id":"16167","city_name":"Lemmetsa","state_id":"1111"},
    {"city_id":"16168","city_name":"Lemsi","state_id":"1111"},
    {"city_id":"16169","city_name":"Libatse","state_id":"1111"},
    {"city_id":"16170","city_name":"Linakula","state_id":"1111"},
    {"city_id":"16171","city_name":"Lindi","state_id":"1111"},
    {"city_id":"16172","city_name":"Liu","state_id":"1111"},
    {"city_id":"16173","city_name":"Lodja","state_id":"1111"},
    {"city_id":"16174","city_name":"Lope","state_id":"1111"},
    {"city_id":"16175","city_name":"Malda","state_id":"1111"},
    {"city_id":"16176","city_name":"Mannikuste","state_id":"1111"},
    {"city_id":"16177","city_name":"Massiaru","state_id":"1111"},
    {"city_id":"16178","city_name":"Metsaaare","state_id":"1111"},
    {"city_id":"16179","city_name":"Metsapoole","state_id":"1111"},
    {"city_id":"16180","city_name":"Moisakula","state_id":"1111"},
    {"city_id":"16181","city_name":"Muraka","state_id":"1111"},
    {"city_id":"16182","city_name":"Niidu","state_id":"1111"},
    {"city_id":"16183","city_name":"Nurme","state_id":"1111"},
    {"city_id":"16184","city_name":"Oara","state_id":"1111"},
    {"city_id":"16185","city_name":"Oidrema","state_id":"1111"},
    {"city_id":"16186","city_name":"Paikuse","state_id":"1111"},
    {"city_id":"16187","city_name":"Papsaare","state_id":"1111"},
    {"city_id":"16188","city_name":"Parnjoe","state_id":"1111"},
    {"city_id":"16189","city_name":"Parnu","state_id":"1111"},
    {"city_id":"16190","city_name":"Parnu-Jaagupi","state_id":"1111"},
    {"city_id":"16191","city_name":"Penu","state_id":"1111"},
    {"city_id":"16192","city_name":"Piistaoja","state_id":"1111"},
    {"city_id":"16193","city_name":"Pohara","state_id":"1111"},
    {"city_id":"16194","city_name":"Poldeotsa","state_id":"1111"},
    {"city_id":"16195","city_name":"Pootsi","state_id":"1111"},
    {"city_id":"16196","city_name":"Pulli","state_id":"1111"},
    {"city_id":"16197","city_name":"Rannametsa","state_id":"1111"},
    {"city_id":"16198","city_name":"Ratsepa","state_id":"1111"},
    {"city_id":"16199","city_name":"Reiu","state_id":"1111"},
    {"city_id":"16200","city_name":"Ridalepa","state_id":"1111"},
    {"city_id":"16201","city_name":"Rootsikula","state_id":"1111"},
    {"city_id":"16202","city_name":"Saarde","state_id":"1111"},
    {"city_id":"16203","city_name":"Saare","state_id":"1111"},
    {"city_id":"16204","city_name":"Sauga","state_id":"1111"},
    {"city_id":"16205","city_name":"Seliste","state_id":"1111"},
    {"city_id":"16206","city_name":"Selja","state_id":"1111"},
    {"city_id":"16207","city_name":"Seljametsa","state_id":"1111"},
    {"city_id":"16208","city_name":"Silla","state_id":"1111"},
    {"city_id":"16209","city_name":"Sindi","state_id":"1111"},
    {"city_id":"16210","city_name":"Soeva","state_id":"1111"},
    {"city_id":"16211","city_name":"Soometsa","state_id":"1111"},
    {"city_id":"16212","city_name":"Suigu","state_id":"1111"},
    {"city_id":"16213","city_name":"Surju","state_id":"1111"},
    {"city_id":"16214","city_name":"Suurejoe","state_id":"1111"},
    {"city_id":"16215","city_name":"Taali","state_id":"1111"},
    {"city_id":"16216","city_name":"Tahkuranna","state_id":"1111"},
    {"city_id":"16217","city_name":"Tali","state_id":"1111"},
    {"city_id":"16218","city_name":"Tammiste","state_id":"1111"},
    {"city_id":"16219","city_name":"Tammuru","state_id":"1111"},
    {"city_id":"16220","city_name":"Tihemetsa","state_id":"1111"},
    {"city_id":"16221","city_name":"Tolla","state_id":"1111"},
    {"city_id":"16222","city_name":"Tootsi","state_id":"1111"},
    {"city_id":"16223","city_name":"Tori","state_id":"1111"},
    {"city_id":"16224","city_name":"Tostamaa","state_id":"1111"},
    {"city_id":"16225","city_name":"Tousi","state_id":"1111"},
    {"city_id":"16226","city_name":"Treimani","state_id":"1111"},
    {"city_id":"16227","city_name":"Urge","state_id":"1111"},
    {"city_id":"16228","city_name":"Uulu","state_id":"1111"},
    {"city_id":"16229","city_name":"Vahenurme","state_id":"1111"},
    {"city_id":"16230","city_name":"Vaki","state_id":"1111"},
    {"city_id":"16231","city_name":"Vandra","state_id":"1111"},
    {"city_id":"16232","city_name":"Varbla","state_id":"1111"},
    {"city_id":"16233","city_name":"Veelikse","state_id":"1111"},
    {"city_id":"16234","city_name":"Vihtra","state_id":"1111"},
    {"city_id":"16235","city_name":"Voidu","state_id":"1111"},
    {"city_id":"16236","city_name":"Voiste","state_id":"1111"},
    {"city_id":"16237","city_name":"Aarna","state_id":"1112"},
    {"city_id":"16238","city_name":"Ahja","state_id":"1112"},
    {"city_id":"16239","city_name":"Erastvere","state_id":"1112"},
    {"city_id":"16240","city_name":"Himma","state_id":"1112"},
    {"city_id":"16241","city_name":"Himmaste","state_id":"1112"},
    {"city_id":"16242","city_name":"Holvandi","state_id":"1112"},
    {"city_id":"16243","city_name":"Ihamaru","state_id":"1112"},
    {"city_id":"16244","city_name":"Jaanimoisa","state_id":"1112"},
    {"city_id":"16245","city_name":"Kanepi","state_id":"1112"},
    {"city_id":"16246","city_name":"Karilatsi","state_id":"1112"},
    {"city_id":"16247","city_name":"Karsa","state_id":"1112"},
    {"city_id":"16248","city_name":"Karste","state_id":"1112"},
    {"city_id":"16249","city_name":"Kauksi","state_id":"1112"},
    {"city_id":"16250","city_name":"Kiidjarve","state_id":"1112"},
    {"city_id":"16251","city_name":"Kostrimae","state_id":"1112"},
    {"city_id":"16252","city_name":"Krootuse","state_id":"1112"},
    {"city_id":"16253","city_name":"Leevaku","state_id":"1112"},
    {"city_id":"16254","city_name":"Leevi","state_id":"1112"},
    {"city_id":"16255","city_name":"Leevijoe","state_id":"1112"},
    {"city_id":"16256","city_name":"Linte","state_id":"1112"},
    {"city_id":"16257","city_name":"Lootvina","state_id":"1112"},
    {"city_id":"16258","city_name":"Maaritsa","state_id":"1112"},
    {"city_id":"16259","city_name":"Mammaste","state_id":"1112"},
    {"city_id":"16260","city_name":"Metste","state_id":"1112"},
    {"city_id":"16261","city_name":"Mikitamae","state_id":"1112"},
    {"city_id":"16262","city_name":"Mooste","state_id":"1112"},
    {"city_id":"16263","city_name":"Naha","state_id":"1112"},
    {"city_id":"16264","city_name":"Orava","state_id":"1112"},
    {"city_id":"16265","city_name":"Pahtpaa","state_id":"1112"},
    {"city_id":"16266","city_name":"Partsi","state_id":"1112"},
    {"city_id":"16267","city_name":"Peri","state_id":"1112"},
    {"city_id":"16268","city_name":"Piigandi","state_id":"1112"},
    {"city_id":"16269","city_name":"Polgaste","state_id":"1112"},
    {"city_id":"16270","city_name":"Polva","state_id":"1112"},
    {"city_id":"16271","city_name":"Prangli","state_id":"1112"},
    {"city_id":"16272","city_name":"Puuri","state_id":"1112"},
    {"city_id":"16273","city_name":"Raadama","state_id":"1112"},
    {"city_id":"16274","city_name":"Rahumae","state_id":"1112"},
    {"city_id":"16275","city_name":"Raigla","state_id":"1112"},
    {"city_id":"16276","city_name":"Rapina","state_id":"1112"},
    {"city_id":"16277","city_name":"Rasina","state_id":"1112"},
    {"city_id":"16278","city_name":"Ristipalo","state_id":"1112"},
    {"city_id":"16279","city_name":"Rosma","state_id":"1112"},
    {"city_id":"16280","city_name":"Ruusa","state_id":"1112"},
    {"city_id":"16281","city_name":"Saverna","state_id":"1112"},
    {"city_id":"16282","city_name":"Sillapaa","state_id":"1112"},
    {"city_id":"16283","city_name":"Soodoma","state_id":"1112"},
    {"city_id":"16284","city_name":"Suurkula","state_id":"1112"},
    {"city_id":"16285","city_name":"Taevaskoja","state_id":"1112"},
    {"city_id":"16286","city_name":"Tannassilma","state_id":"1112"},
    {"city_id":"16287","city_name":"Tilsi","state_id":"1112"},
    {"city_id":"16288","city_name":"Treski","state_id":"1112"},
    {"city_id":"16289","city_name":"Vaike-Rosna","state_id":"1112"},
    {"city_id":"16290","city_name":"Valgjarve","state_id":"1112"},
    {"city_id":"16291","city_name":"Vana-Koiola","state_id":"1112"},
    {"city_id":"16292","city_name":"Vardja","state_id":"1112"},
    {"city_id":"16293","city_name":"Varska","state_id":"1112"},
    {"city_id":"16294","city_name":"Vastse-Kuuste","state_id":"1112"},
    {"city_id":"16295","city_name":"Veriora","state_id":"1112"},
    {"city_id":"16296","city_name":"Viluste","state_id":"1112"},
    {"city_id":"16297","city_name":"Voopsu","state_id":"1112"},
    {"city_id":"16298","city_name":"Aespa","state_id":"1113"},
    {"city_id":"16299","city_name":"Alu","state_id":"1113"},
    {"city_id":"16300","city_name":"Eidapere","state_id":"1113"},
    {"city_id":"16301","city_name":"Hageri","state_id":"1113"},
    {"city_id":"16302","city_name":"Hagudi","state_id":"1113"},
    {"city_id":"16303","city_name":"Haimre","state_id":"1113"},
    {"city_id":"16304","city_name":"Hertu","state_id":"1113"},
    {"city_id":"16305","city_name":"Iira","state_id":"1113"},
    {"city_id":"16306","city_name":"Ingliste","state_id":"1113"},
    {"city_id":"16307","city_name":"Jarlepa","state_id":"1113"},
    {"city_id":"16308","city_name":"Jarvakandi","state_id":"1113"},
    {"city_id":"16309","city_name":"Juuru","state_id":"1113"},
    {"city_id":"16310","city_name":"Kaerepere","state_id":"1113"},
    {"city_id":"16311","city_name":"Kaiu","state_id":"1113"},
    {"city_id":"16312","city_name":"Kalbu","state_id":"1113"},
    {"city_id":"16313","city_name":"Karu","state_id":"1113"},
    {"city_id":"16314","city_name":"Kasti","state_id":"1113"},
    {"city_id":"16315","city_name":"Keava","state_id":"1113"},
    {"city_id":"16316","city_name":"Kehtna","state_id":"1113"},
    {"city_id":"16317","city_name":"Kivi-Vigala","state_id":"1113"},
    {"city_id":"16318","city_name":"Kodila","state_id":"1113"},
    {"city_id":"16319","city_name":"Kohila","state_id":"1113"},
    {"city_id":"16320","city_name":"Kuimetsa","state_id":"1113"},
    {"city_id":"16321","city_name":"Kuusiku","state_id":"1113"},
    {"city_id":"16322","city_name":"Laukna","state_id":"1113"},
    {"city_id":"16323","city_name":"Lelle","state_id":"1113"},
    {"city_id":"16324","city_name":"Lipa","state_id":"1113"},
    {"city_id":"16325","city_name":"Lohu","state_id":"1113"},
    {"city_id":"16326","city_name":"Lokuta","state_id":"1113"},
    {"city_id":"16327","city_name":"Maidla","state_id":"1113"},
    {"city_id":"16328","city_name":"Marjamaa","state_id":"1113"},
    {"city_id":"16329","city_name":"Masti","state_id":"1113"},
    {"city_id":"16330","city_name":"Moisamaa","state_id":"1113"},
    {"city_id":"16331","city_name":"Naravere","state_id":"1113"},
    {"city_id":"16332","city_name":"Orgita","state_id":"1113"},
    {"city_id":"16333","city_name":"Paardu","state_id":"1113"},
    {"city_id":"16334","city_name":"Pae","state_id":"1113"},
    {"city_id":"16335","city_name":"Pahkla","state_id":"1113"},
    {"city_id":"16336","city_name":"Pihali","state_id":"1113"},
    {"city_id":"16337","city_name":"Pirgu","state_id":"1113"},
    {"city_id":"16338","city_name":"Prillimae","state_id":"1113"},
    {"city_id":"16339","city_name":"Purila","state_id":"1113"},
    {"city_id":"16340","city_name":"Purku","state_id":"1113"},
    {"city_id":"16341","city_name":"Rabivere","state_id":"1113"},
    {"city_id":"16342","city_name":"Raikkula","state_id":"1113"},
    {"city_id":"16343","city_name":"Rangu","state_id":"1113"},
    {"city_id":"16344","city_name":"Rapla","state_id":"1113"},
    {"city_id":"16345","city_name":"Salutaguse","state_id":"1113"},
    {"city_id":"16346","city_name":"Sipa","state_id":"1113"},
    {"city_id":"16347","city_name":"Sotke","state_id":"1113"},
    {"city_id":"16348","city_name":"Sutlema","state_id":"1113"},
    {"city_id":"16349","city_name":"Tamme","state_id":"1113"},
    {"city_id":"16350","city_name":"Teenuse","state_id":"1113"},
    {"city_id":"16351","city_name":"Uuskula","state_id":"1113"},
    {"city_id":"16352","city_name":"Vahastu","state_id":"1113"},
    {"city_id":"16353","city_name":"Valgu","state_id":"1113"},
    {"city_id":"16354","city_name":"Valtu","state_id":"1113"},
    {"city_id":"16355","city_name":"Vana-Kaiu","state_id":"1113"},
    {"city_id":"16356","city_name":"Vana-Vigala","state_id":"1113"},
    {"city_id":"16357","city_name":"Varbola","state_id":"1113"},
    {"city_id":"16358","city_name":"Vilivere","state_id":"1113"},
    {"city_id":"16359","city_name":"Arandi","state_id":"1114"},
    {"city_id":"16360","city_name":"Aste","state_id":"1114"},
    {"city_id":"16361","city_name":"Eikla","state_id":"1114"},
    {"city_id":"16362","city_name":"Hellamaa","state_id":"1114"},
    {"city_id":"16363","city_name":"Kao","state_id":"1114"},
    {"city_id":"16364","city_name":"Karja","state_id":"1114"},
    {"city_id":"16365","city_name":"Karla","state_id":"1114"},
    {"city_id":"16366","city_name":"Kihelkonna","state_id":"1114"},
    {"city_id":"16367","city_name":"Koimla","state_id":"1114"},
    {"city_id":"16368","city_name":"Koljala","state_id":"1114"},
    {"city_id":"16369","city_name":"Korkkula","state_id":"1114"},
    {"city_id":"16370","city_name":"Korkvere","state_id":"1114"},
    {"city_id":"16371","city_name":"Kudjape","state_id":"1114"},
    {"city_id":"16372","city_name":"Kuressaare","state_id":"1114"},
    {"city_id":"16373","city_name":"Laatsa","state_id":"1114"},
    {"city_id":"16374","city_name":"Lahekula","state_id":"1114"},
    {"city_id":"16375","city_name":"Laimjala","state_id":"1114"},
    {"city_id":"16376","city_name":"Leisi","state_id":"1114"},
    {"city_id":"16377","city_name":"Liiva","state_id":"1114"},
    {"city_id":"16378","city_name":"Lumanda","state_id":"1114"},
    {"city_id":"16379","city_name":"Metskula","state_id":"1114"},
    {"city_id":"16380","city_name":"Mustjala","state_id":"1114"},
    {"city_id":"16381","city_name":"Nasva","state_id":"1114"},
    {"city_id":"16382","city_name":"Nommkula","state_id":"1114"},
    {"city_id":"16383","city_name":"Orissaare","state_id":"1114"},
    {"city_id":"16384","city_name":"Pahkla","state_id":"1114"},
    {"city_id":"16385","city_name":"Parsama","state_id":"1114"},
    {"city_id":"16386","city_name":"Pihtla","state_id":"1114"},
    {"city_id":"16387","city_name":"Sakla","state_id":"1114"},
    {"city_id":"16388","city_name":"Salme","state_id":"1114"},
    {"city_id":"16389","city_name":"Sandla","state_id":"1114"},
    {"city_id":"16390","city_name":"Tagavere","state_id":"1114"},
    {"city_id":"16391","city_name":"Tornimae","state_id":"1114"},
    {"city_id":"16392","city_name":"Valjala","state_id":"1114"},
    {"city_id":"16393","city_name":"Vohma","state_id":"1114"},
    {"city_id":"16394","city_name":"Aardla","state_id":"1115"},
    {"city_id":"16395","city_name":"Aksi","state_id":"1115"},
    {"city_id":"16396","city_name":"Alasoo","state_id":"1115"},
    {"city_id":"16397","city_name":"Alatskivi","state_id":"1115"},
    {"city_id":"16398","city_name":"Annikoru","state_id":"1115"},
    {"city_id":"16399","city_name":"Aravu","state_id":"1115"},
    {"city_id":"16400","city_name":"Elva","state_id":"1115"},
    {"city_id":"16401","city_name":"Erala","state_id":"1115"},
    {"city_id":"16402","city_name":"Ervu","state_id":"1115"},
    {"city_id":"16403","city_name":"Haage","state_id":"1115"},
    {"city_id":"16404","city_name":"Haaslava","state_id":"1115"},
    {"city_id":"16405","city_name":"Ignase","state_id":"1115"},
    {"city_id":"16406","city_name":"Illi","state_id":"1115"},
    {"city_id":"16407","city_name":"Ilmatsalu","state_id":"1115"},
    {"city_id":"16408","city_name":"Kaagvere","state_id":"1115"},
    {"city_id":"16409","city_name":"Kaardi","state_id":"1115"},
    {"city_id":"16410","city_name":"Kaarlijarve","state_id":"1115"},
    {"city_id":"16411","city_name":"Kallaste","state_id":"1115"},
    {"city_id":"16412","city_name":"Kalme","state_id":"1115"},
    {"city_id":"16413","city_name":"Kambja","state_id":"1115"},
    {"city_id":"16414","city_name":"Kandikula","state_id":"1115"},
    {"city_id":"16415","city_name":"Karevere","state_id":"1115"},
    {"city_id":"16416","city_name":"Karkna","state_id":"1115"},
    {"city_id":"16417","city_name":"Kasepaa","state_id":"1115"},
    {"city_id":"16418","city_name":"Kavastu","state_id":"1115"},
    {"city_id":"16419","city_name":"Kirepi","state_id":"1115"},
    {"city_id":"16420","city_name":"Kodukula","state_id":"1115"},
    {"city_id":"16421","city_name":"Kokora","state_id":"1115"},
    {"city_id":"16422","city_name":"Kolkja","state_id":"1115"},
    {"city_id":"16423","city_name":"Konguta","state_id":"1115"},
    {"city_id":"16424","city_name":"Koosa","state_id":"1115"},
    {"city_id":"16425","city_name":"Koruste","state_id":"1115"},
    {"city_id":"16426","city_name":"Korvekula","state_id":"1115"},
    {"city_id":"16427","city_name":"Kulitse","state_id":"1115"},
    {"city_id":"16428","city_name":"Kurekula","state_id":"1115"},
    {"city_id":"16429","city_name":"Kurista","state_id":"1115"},
    {"city_id":"16430","city_name":"Laaniste","state_id":"1115"},
    {"city_id":"16431","city_name":"Laeva","state_id":"1115"},
    {"city_id":"16432","city_name":"Lahte","state_id":"1115"},
    {"city_id":"16433","city_name":"Lalli","state_id":"1115"},
    {"city_id":"16434","city_name":"Lapetukme","state_id":"1115"},
    {"city_id":"16435","city_name":"Lemmatsi","state_id":"1115"},
    {"city_id":"16436","city_name":"Lohkva","state_id":"1115"},
    {"city_id":"16437","city_name":"Lossimae","state_id":"1115"},
    {"city_id":"16438","city_name":"Luke","state_id":"1115"},
    {"city_id":"16439","city_name":"Luunja","state_id":"1115"},
    {"city_id":"16440","city_name":"Maksa","state_id":"1115"},
    {"city_id":"16441","city_name":"Maramaa","state_id":"1115"},
    {"city_id":"16442","city_name":"Marja","state_id":"1115"},
    {"city_id":"16443","city_name":"Meeksi","state_id":"1115"},
    {"city_id":"16444","city_name":"Meeri","state_id":"1115"},
    {"city_id":"16445","city_name":"Mehikoorma","state_id":"1115"},
    {"city_id":"16446","city_name":"Melliste","state_id":"1115"},
    {"city_id":"16447","city_name":"Metsakivi","state_id":"1115"},
    {"city_id":"16448","city_name":"Metsalaane","state_id":"1115"},
    {"city_id":"16449","city_name":"Moisanurme","state_id":"1115"},
    {"city_id":"16450","city_name":"Nina","state_id":"1115"},
    {"city_id":"16451","city_name":"Nogiaru","state_id":"1115"},
    {"city_id":"16452","city_name":"Noo","state_id":"1115"},
    {"city_id":"16453","city_name":"Pilka","state_id":"1115"},
    {"city_id":"16454","city_name":"Poka","state_id":"1115"},
    {"city_id":"16455","city_name":"Pooritsa","state_id":"1115"},
    {"city_id":"16456","city_name":"Puhja","state_id":"1115"},
    {"city_id":"16457","city_name":"Rahinge","state_id":"1115"},
    {"city_id":"16458","city_name":"Raigaste","state_id":"1115"},
    {"city_id":"16459","city_name":"Ramsi","state_id":"1115"},
    {"city_id":"16460","city_name":"Rani","state_id":"1115"},
    {"city_id":"16461","city_name":"Rannu","state_id":"1115"},
    {"city_id":"16462","city_name":"Rebase","state_id":"1115"},
    {"city_id":"16463","city_name":"Reola","state_id":"1115"},
    {"city_id":"16464","city_name":"Rohu","state_id":"1115"},
    {"city_id":"16465","city_name":"Roiu","state_id":"1115"},
    {"city_id":"16466","city_name":"Rongu","state_id":"1115"},
    {"city_id":"16467","city_name":"Saadjarve","state_id":"1115"},
    {"city_id":"16468","city_name":"Sinikula","state_id":"1115"},
    {"city_id":"16469","city_name":"Sirgu","state_id":"1115"},
    {"city_id":"16470","city_name":"Soinaste","state_id":"1115"},
    {"city_id":"16471","city_name":"Sojamaa","state_id":"1115"},
    {"city_id":"16472","city_name":"Tahtvere","state_id":"1115"},
    {"city_id":"16473","city_name":"Tammiste","state_id":"1115"},
    {"city_id":"16474","city_name":"Tammistu","state_id":"1115"},
    {"city_id":"16475","city_name":"Tartu","state_id":"1115"},
    {"city_id":"16476","city_name":"Teedla","state_id":"1115"},
    {"city_id":"16477","city_name":"Tilga","state_id":"1115"},
    {"city_id":"16478","city_name":"Toravere","state_id":"1115"},
    {"city_id":"16479","city_name":"Torvandi","state_id":"1115"},
    {"city_id":"16480","city_name":"Tuki","state_id":"1115"},
    {"city_id":"16481","city_name":"Uderna","state_id":"1115"},
    {"city_id":"16482","city_name":"Uhti","state_id":"1115"},
    {"city_id":"16483","city_name":"Ulenurme","state_id":"1115"},
    {"city_id":"16484","city_name":"Ulila","state_id":"1115"},
    {"city_id":"16485","city_name":"Unikula","state_id":"1115"},
    {"city_id":"16486","city_name":"Vahi","state_id":"1115"},
    {"city_id":"16487","city_name":"Vaike-Rakke","state_id":"1115"},
    {"city_id":"16488","city_name":"Valguta","state_id":"1115"},
    {"city_id":"16489","city_name":"Vana-Kuuste","state_id":"1115"},
    {"city_id":"16490","city_name":"Vara","state_id":"1115"},
    {"city_id":"16491","city_name":"Varnja","state_id":"1115"},
    {"city_id":"16492","city_name":"Vasula","state_id":"1115"},
    {"city_id":"16493","city_name":"Vedu","state_id":"1115"},
    {"city_id":"16494","city_name":"Vesneri","state_id":"1115"},
    {"city_id":"16495","city_name":"Vissi","state_id":"1115"},
    {"city_id":"16496","city_name":"Voibla","state_id":"1115"},
    {"city_id":"16497","city_name":"Voika","state_id":"1115"},
    {"city_id":"16498","city_name":"Vonnu","state_id":"1115"},
    {"city_id":"16499","city_name":"Voopste","state_id":"1115"},
    {"city_id":"16500","city_name":"Vorbuse","state_id":"1115"},
    {"city_id":"16501","city_name":"Aakre","state_id":"1116"},
    {"city_id":"16502","city_name":"Ala","state_id":"1116"},
    {"city_id":"16503","city_name":"Hargla","state_id":"1116"},
    {"city_id":"16504","city_name":"Hellenurme","state_id":"1116"},
    {"city_id":"16505","city_name":"Helme","state_id":"1116"},
    {"city_id":"16506","city_name":"Hummuli","state_id":"1116"},
    {"city_id":"16507","city_name":"Jeti","state_id":"1116"},
    {"city_id":"16508","city_name":"Jogeveste","state_id":"1116"},
    {"city_id":"16509","city_name":"Kaagjarve","state_id":"1116"},
    {"city_id":"16510","city_name":"Kalme","state_id":"1116"},
    {"city_id":"16511","city_name":"Karjatnurme","state_id":"1116"},
    {"city_id":"16512","city_name":"Karula","state_id":"1116"},
    {"city_id":"16513","city_name":"Keeni","state_id":"1116"},
    {"city_id":"16514","city_name":"Koikkula","state_id":"1116"},
    {"city_id":"16515","city_name":"Laanemetsa","state_id":"1116"},
    {"city_id":"16516","city_name":"Laatre","state_id":"1116"},
    {"city_id":"16517","city_name":"Laukula","state_id":"1116"},
    {"city_id":"16518","city_name":"Leebiku","state_id":"1116"},
    {"city_id":"16519","city_name":"Linna","state_id":"1116"},
    {"city_id":"16520","city_name":"Lossikula","state_id":"1116"},
    {"city_id":"16521","city_name":"Lullemae","state_id":"1116"},
    {"city_id":"16522","city_name":"Moldre","state_id":"1116"},
    {"city_id":"16523","city_name":"Nouni","state_id":"1116"},
    {"city_id":"16524","city_name":"Nupli","state_id":"1116"},
    {"city_id":"16525","city_name":"Oru","state_id":"1116"},
    {"city_id":"16526","city_name":"Oruste","state_id":"1116"},
    {"city_id":"16527","city_name":"Otepaa","state_id":"1116"},
    {"city_id":"16528","city_name":"Paidla","state_id":"1116"},
    {"city_id":"16529","city_name":"Paju","state_id":"1116"},
    {"city_id":"16530","city_name":"Palupera","state_id":"1116"},
    {"city_id":"16531","city_name":"Patkula","state_id":"1116"},
    {"city_id":"16532","city_name":"Piiri","state_id":"1116"},
    {"city_id":"16533","city_name":"Pikasilla","state_id":"1116"},
    {"city_id":"16534","city_name":"Pilkuse","state_id":"1116"},
    {"city_id":"16535","city_name":"Puhajarve","state_id":"1116"},
    {"city_id":"16536","city_name":"Puka","state_id":"1116"},
    {"city_id":"16537","city_name":"Restu","state_id":"1116"},
    {"city_id":"16538","city_name":"Riidaja","state_id":"1116"},
    {"city_id":"16539","city_name":"Sangaste","state_id":"1116"},
    {"city_id":"16540","city_name":"Sihva","state_id":"1116"},
    {"city_id":"16541","city_name":"Sooru","state_id":"1116"},
    {"city_id":"16542","city_name":"Taagepera","state_id":"1116"},
    {"city_id":"16543","city_name":"Tagula","state_id":"1116"},
    {"city_id":"16544","city_name":"Taheva","state_id":"1116"},
    {"city_id":"16545","city_name":"Tiidu","state_id":"1116"},
    {"city_id":"16546","city_name":"Tolliste","state_id":"1116"},
    {"city_id":"16547","city_name":"Torva","state_id":"1116"},
    {"city_id":"16548","city_name":"Tsirguliina","state_id":"1116"},
    {"city_id":"16549","city_name":"Valga","state_id":"1116"},
    {"city_id":"16550","city_name":"Vana-Otepaa","state_id":"1116"},
    {"city_id":"16551","city_name":"Abja-Paluoja","state_id":"1117"},
    {"city_id":"16552","city_name":"Abja-Vanamoisa","state_id":"1117"},
    {"city_id":"16553","city_name":"Aidu","state_id":"1117"},
    {"city_id":"16554","city_name":"Angi","state_id":"1117"},
    {"city_id":"16555","city_name":"Anikatsi","state_id":"1117"},
    {"city_id":"16556","city_name":"Arikula","state_id":"1117"},
    {"city_id":"16557","city_name":"Halliste","state_id":"1117"},
    {"city_id":"16558","city_name":"Heimtali","state_id":"1117"},
    {"city_id":"16559","city_name":"Holstre","state_id":"1117"},
    {"city_id":"16560","city_name":"Intsu","state_id":"1117"},
    {"city_id":"16561","city_name":"Jamejala","state_id":"1117"},
    {"city_id":"16562","city_name":"Jaska","state_id":"1117"},
    {"city_id":"16563","city_name":"Kaavere","state_id":"1117"},
    {"city_id":"16564","city_name":"Kamara","state_id":"1117"},
    {"city_id":"16565","city_name":"Karksi","state_id":"1117"},
    {"city_id":"16566","city_name":"Karksi-Nuia","state_id":"1117"},
    {"city_id":"16567","city_name":"Karstna","state_id":"1117"},
    {"city_id":"16568","city_name":"Karula","state_id":"1117"},
    {"city_id":"16569","city_name":"Kobruvere","state_id":"1117"},
    {"city_id":"16570","city_name":"Koidama","state_id":"1117"},
    {"city_id":"16571","city_name":"Koksvere","state_id":"1117"},
    {"city_id":"16572","city_name":"Kolga-Jaani","state_id":"1117"},
    {"city_id":"16573","city_name":"Koo","state_id":"1117"},
    {"city_id":"16574","city_name":"Kopu","state_id":"1117"},
    {"city_id":"16575","city_name":"Kulla","state_id":"1117"},
    {"city_id":"16576","city_name":"Lalsi","state_id":"1117"},
    {"city_id":"16577","city_name":"Leie","state_id":"1117"},
    {"city_id":"16578","city_name":"Lilli","state_id":"1117"},
    {"city_id":"16579","city_name":"Lohavere","state_id":"1117"},
    {"city_id":"16580","city_name":"Loodi","state_id":"1117"},
    {"city_id":"16581","city_name":"Maeltkula","state_id":"1117"},
    {"city_id":"16582","city_name":"Matapera","state_id":"1117"},
    {"city_id":"16583","city_name":"Meleski","state_id":"1117"},
    {"city_id":"16584","city_name":"Metskula","state_id":"1117"},
    {"city_id":"16585","city_name":"Moisakula","state_id":"1117"},
    {"city_id":"16586","city_name":"Monnaste","state_id":"1117"},
    {"city_id":"16587","city_name":"Mustivere","state_id":"1117"},
    {"city_id":"16588","city_name":"Mustla","state_id":"1117"},
    {"city_id":"16589","city_name":"Navesti","state_id":"1117"},
    {"city_id":"16590","city_name":"Odiste","state_id":"1117"},
    {"city_id":"16591","city_name":"Oisu","state_id":"1117"},
    {"city_id":"16592","city_name":"Olustvere","state_id":"1117"},
    {"city_id":"16593","city_name":"Pahuvere","state_id":"1117"},
    {"city_id":"16594","city_name":"Paistu","state_id":"1117"},
    {"city_id":"16595","city_name":"Parakula","state_id":"1117"},
    {"city_id":"16596","city_name":"Pari","state_id":"1117"},
    {"city_id":"16597","city_name":"Parsti","state_id":"1117"},
    {"city_id":"16598","city_name":"Peetrimoisa","state_id":"1117"},
    {"city_id":"16599","city_name":"Pilistvere","state_id":"1117"},
    {"city_id":"16600","city_name":"Pinska","state_id":"1117"},
    {"city_id":"16601","city_name":"Polde","state_id":"1117"},
    {"city_id":"16602","city_name":"Polli","state_id":"1117"},
    {"city_id":"16603","city_name":"Puiatu","state_id":"1117"},
    {"city_id":"16604","city_name":"Ramsi","state_id":"1117"},
    {"city_id":"16605","city_name":"Reegoldi","state_id":"1117"},
    {"city_id":"16606","city_name":"Saarepeedi","state_id":"1117"},
    {"city_id":"16607","city_name":"Savikoti","state_id":"1117"},
    {"city_id":"16608","city_name":"Sinialliku","state_id":"1117"},
    {"city_id":"16609","city_name":"Soe","state_id":"1117"},
    {"city_id":"16610","city_name":"Soomevere","state_id":"1117"},
    {"city_id":"16611","city_name":"Sudiste","state_id":"1117"},
    {"city_id":"16612","city_name":"Suislepa","state_id":"1117"},
    {"city_id":"16613","city_name":"Sultsi","state_id":"1117"},
    {"city_id":"16614","city_name":"Supsi","state_id":"1117"},
    {"city_id":"16615","city_name":"Surgavere","state_id":"1117"},
    {"city_id":"16616","city_name":"Suure-Jaani","state_id":"1117"},
    {"city_id":"16617","city_name":"Taaksi","state_id":"1117"},
    {"city_id":"16618","city_name":"Tanassilma","state_id":"1117"},
    {"city_id":"16619","city_name":"Tinnikuru","state_id":"1117"},
    {"city_id":"16620","city_name":"Tohvri","state_id":"1117"},
    {"city_id":"16621","city_name":"Tuhalaane","state_id":"1117"},
    {"city_id":"16622","city_name":"Tusti","state_id":"1117"},
    {"city_id":"16623","city_name":"Ulde","state_id":"1117"},
    {"city_id":"16624","city_name":"Ulensi","state_id":"1117"},
    {"city_id":"16625","city_name":"Uusna","state_id":"1117"},
    {"city_id":"16626","city_name":"Valgita","state_id":"1117"},
    {"city_id":"16627","city_name":"Valma","state_id":"1117"},
    {"city_id":"16628","city_name":"Valuste","state_id":"1117"},
    {"city_id":"16629","city_name":"Vana-Kariste","state_id":"1117"},
    {"city_id":"16630","city_name":"Vana-Voidu","state_id":"1117"},
    {"city_id":"16631","city_name":"Vardi","state_id":"1117"},
    {"city_id":"16632","city_name":"Vardja","state_id":"1117"},
    {"city_id":"16633","city_name":"Vastemoisa","state_id":"1117"},
    {"city_id":"16634","city_name":"Veelikse","state_id":"1117"},
    {"city_id":"16635","city_name":"Verilaske","state_id":"1117"},
    {"city_id":"16636","city_name":"Veskimae","state_id":"1117"},
    {"city_id":"16637","city_name":"Viiratsi","state_id":"1117"},
    {"city_id":"16638","city_name":"Viljandi","state_id":"1117"},
    {"city_id":"16639","city_name":"Villa","state_id":"1117"},
    {"city_id":"16640","city_name":"Vohma","state_id":"1117"},
    {"city_id":"16641","city_name":"Voivaku","state_id":"1117"},
    {"city_id":"16642","city_name":"Vooru","state_id":"1117"},
    {"city_id":"16643","city_name":"Antsla","state_id":"1118"},
    {"city_id":"16644","city_name":"Haanja","state_id":"1118"},
    {"city_id":"16645","city_name":"Jarvere","state_id":"1118"},
    {"city_id":"16646","city_name":"Kaapa","state_id":"1118"},
    {"city_id":"16647","city_name":"Kirumpaa","state_id":"1118"},
    {"city_id":"16648","city_name":"Kobela","state_id":"1118"},
    {"city_id":"16649","city_name":"Kollino","state_id":"1118"},
    {"city_id":"16650","city_name":"Kose","state_id":"1118"},
    {"city_id":"16651","city_name":"Kraavi","state_id":"1118"},
    {"city_id":"16652","city_name":"Krabi","state_id":"1118"},
    {"city_id":"16653","city_name":"Kulaoru","state_id":"1118"},
    {"city_id":"16654","city_name":"Kuldre","state_id":"1118"},
    {"city_id":"16655","city_name":"Kuutsi","state_id":"1118"},
    {"city_id":"16656","city_name":"Lasva","state_id":"1118"},
    {"city_id":"16657","city_name":"Loosu","state_id":"1118"},
    {"city_id":"16658","city_name":"Lusti","state_id":"1118"},
    {"city_id":"16659","city_name":"Meegomae","state_id":"1118"},
    {"city_id":"16660","city_name":"Meremae","state_id":"1118"},
    {"city_id":"16661","city_name":"Misso","state_id":"1118"},
    {"city_id":"16662","city_name":"Moniste","state_id":"1118"},
    {"city_id":"16663","city_name":"Navi","state_id":"1118"},
    {"city_id":"16664","city_name":"Nursi","state_id":"1118"},
    {"city_id":"16665","city_name":"Obinitsa","state_id":"1118"},
    {"city_id":"16666","city_name":"Osula","state_id":"1118"},
    {"city_id":"16667","city_name":"Otsa","state_id":"1118"},
    {"city_id":"16668","city_name":"Parksepa","state_id":"1118"},
    {"city_id":"16669","city_name":"Puiga","state_id":"1118"},
    {"city_id":"16670","city_name":"Raiste","state_id":"1118"},
    {"city_id":"16671","city_name":"Rimmi","state_id":"1118"},
    {"city_id":"16672","city_name":"Rouge","state_id":"1118"},
    {"city_id":"16673","city_name":"Ruusmae","state_id":"1118"},
    {"city_id":"16674","city_name":"Saru","state_id":"1118"},
    {"city_id":"16675","city_name":"Somerpalu","state_id":"1118"},
    {"city_id":"16676","city_name":"Sulbi","state_id":"1118"},
    {"city_id":"16677","city_name":"Taberlaane","state_id":"1118"},
    {"city_id":"16678","city_name":"Tagakula","state_id":"1118"},
    {"city_id":"16679","city_name":"Tsolgo","state_id":"1118"},
    {"city_id":"16680","city_name":"Tsooru","state_id":"1118"},
    {"city_id":"16681","city_name":"Urvaste","state_id":"1118"},
    {"city_id":"16682","city_name":"Uue-Antsla","state_id":"1118"},
    {"city_id":"16683","city_name":"Vaabina","state_id":"1118"},
    {"city_id":"16684","city_name":"Vagula","state_id":"1118"},
    {"city_id":"16685","city_name":"Vaimela","state_id":"1118"},
    {"city_id":"16686","city_name":"Vana-Antsla","state_id":"1118"},
    {"city_id":"16687","city_name":"Vana-Roosa","state_id":"1118"},
    {"city_id":"16688","city_name":"Vana-Vastseliina","state_id":"1118"},
    {"city_id":"16689","city_name":"Varstu","state_id":"1118"},
    {"city_id":"16690","city_name":"Vastseliina","state_id":"1118"},
    {"city_id":"16691","city_name":"Verijarve","state_id":"1118"},
    {"city_id":"16692","city_name":"Viitina","state_id":"1118"},
    {"city_id":"16693","city_name":"Viitka","state_id":"1118"},
    {"city_id":"16694","city_name":"Visela","state_id":"1118"},
    {"city_id":"16695","city_name":"Voru","state_id":"1118"},
    {"city_id":"16696","city_name":"Vorumoisa","state_id":"1118"},
    {"city_id":"16697","city_name":"Asayita","state_id":"1120"},
    {"city_id":"16698","city_name":"Awash","state_id":"1120"},
    {"city_id":"16699","city_name":"Dubti","state_id":"1120"},
    {"city_id":"16700","city_name":"Gewane","state_id":"1120"},
    {"city_id":"16701","city_name":"Addi Ark'ay","state_id":"1121"},
    {"city_id":"16702","city_name":"Addis 'Alem","state_id":"1121"},
    {"city_id":"16703","city_name":"Addis Zemen","state_id":"1121"},
    {"city_id":"16704","city_name":"Adet","state_id":"1121"},
    {"city_id":"16705","city_name":"Bahir Dar","state_id":"1121"},
    {"city_id":"16706","city_name":"Bati","state_id":"1121"},
    {"city_id":"16707","city_name":"Bichena","state_id":"1121"},
    {"city_id":"16708","city_name":"Bure","state_id":"1121"},
    {"city_id":"16709","city_name":"Chagni","state_id":"1121"},
    {"city_id":"16710","city_name":"Dabat","state_id":"1121"},
    {"city_id":"16711","city_name":"Dangla","state_id":"1121"},
    {"city_id":"16712","city_name":"Debark","state_id":"1121"},
    {"city_id":"16713","city_name":"Debre Birhan","state_id":"1121"},
    {"city_id":"16714","city_name":"Debre Mark'os","state_id":"1121"},
    {"city_id":"16715","city_name":"Debre Sina","state_id":"1121"},
    {"city_id":"16716","city_name":"Debre Tabor","state_id":"1121"},
    {"city_id":"16717","city_name":"Debre Werk","state_id":"1121"},
    {"city_id":"16718","city_name":"Dejen","state_id":"1121"},
    {"city_id":"16719","city_name":"Dese","state_id":"1121"},
    {"city_id":"16720","city_name":"Finote Selam","state_id":"1121"},
    {"city_id":"16721","city_name":"Gondar","state_id":"1121"},
    {"city_id":"16722","city_name":"K'obo","state_id":"1121"},
    {"city_id":"16723","city_name":"Kembolcha","state_id":"1121"},
    {"city_id":"16724","city_name":"Kemise","state_id":"1121"},
    {"city_id":"16725","city_name":"Lalibela","state_id":"1121"},
    {"city_id":"16726","city_name":"Mott'a","state_id":"1121"},
    {"city_id":"16727","city_name":"Nefas Mewcha","state_id":"1121"},
    {"city_id":"16728","city_name":"Sek'ot'a","state_id":"1121"},
    {"city_id":"16729","city_name":"Shewa Robit","state_id":"1121"},
    {"city_id":"16730","city_name":"Weldiya","state_id":"1121"},
    {"city_id":"16731","city_name":"Were Ilu","state_id":"1121"},
    {"city_id":"16732","city_name":"Werota","state_id":"1121"},
    {"city_id":"16733","city_name":"Asosa","state_id":"1122"},
    {"city_id":"16734","city_name":"Dire Dawa","state_id":"1123"},
    {"city_id":"16735","city_name":"Gambela","state_id":"1124"},
    {"city_id":"16736","city_name":"Harer","state_id":"1125"},
    {"city_id":"16737","city_name":"Jigjiga","state_id":"1126"},
    {"city_id":"16738","city_name":"'Alem Maya","state_id":"1128"},
    {"city_id":"16739","city_name":"Abomsa","state_id":"1128"},
    {"city_id":"16740","city_name":"Agaro","state_id":"1128"},
    {"city_id":"16741","city_name":"Asasa","state_id":"1128"},
    {"city_id":"16742","city_name":"Asbe Teferi","state_id":"1128"},
    {"city_id":"16743","city_name":"Assela","state_id":"1128"},
    {"city_id":"16744","city_name":"Bako","state_id":"1128"},
    {"city_id":"16745","city_name":"Bedele","state_id":"1128"},
    {"city_id":"16746","city_name":"Bedesa","state_id":"1128"},
    {"city_id":"16747","city_name":"Burayu","state_id":"1128"},
    {"city_id":"16748","city_name":"Debre Zeyit","state_id":"1128"},
    {"city_id":"16749","city_name":"Deder","state_id":"1128"},
    {"city_id":"16750","city_name":"Dembi Dolo","state_id":"1128"},
    {"city_id":"16751","city_name":"Dodola","state_id":"1128"},
    {"city_id":"16752","city_name":"Fiche","state_id":"1128"},
    {"city_id":"16753","city_name":"Gebre Guracha","state_id":"1128"},
    {"city_id":"16754","city_name":"Gedo","state_id":"1128"},
    {"city_id":"16755","city_name":"Gelemso","state_id":"1128"},
    {"city_id":"16756","city_name":"Gimbi","state_id":"1128"},
    {"city_id":"16757","city_name":"Ginch'i","state_id":"1128"},
    {"city_id":"16758","city_name":"Ginir","state_id":"1128"},
    {"city_id":"16759","city_name":"Giyon","state_id":"1128"},
    {"city_id":"16760","city_name":"Goba","state_id":"1128"},
    {"city_id":"16761","city_name":"Gore","state_id":"1128"},
    {"city_id":"16762","city_name":"Guder","state_id":"1128"},
    {"city_id":"16763","city_name":"Hagere Hiywet","state_id":"1128"},
    {"city_id":"16764","city_name":"Hagere Maryam","state_id":"1128"},
    {"city_id":"16765","city_name":"Hirna","state_id":"1128"},
    {"city_id":"16766","city_name":"Holeta Genet","state_id":"1128"},
    {"city_id":"16767","city_name":"Huruta","state_id":"1128"},
    {"city_id":"16768","city_name":"Jimma","state_id":"1128"},
    {"city_id":"16769","city_name":"Kibre Mengist","state_id":"1128"},
    {"city_id":"16770","city_name":"Kofele","state_id":"1128"},
    {"city_id":"16771","city_name":"Mega","state_id":"1128"},
    {"city_id":"16772","city_name":"Mek'i","state_id":"1128"},
    {"city_id":"16773","city_name":"Mendi","state_id":"1128"},
    {"city_id":"16774","city_name":"Metehara","state_id":"1128"},
    {"city_id":"16775","city_name":"Metu","state_id":"1128"},
    {"city_id":"16776","city_name":"Mojo","state_id":"1128"},
    {"city_id":"16777","city_name":"Moyale","state_id":"1128"},
    {"city_id":"16778","city_name":"Nazret","state_id":"1128"},
    {"city_id":"16779","city_name":"Nedjo","state_id":"1128"},
    {"city_id":"16780","city_name":"Negele","state_id":"1128"},
    {"city_id":"16781","city_name":"Nek'emte","state_id":"1128"},
    {"city_id":"16782","city_name":"Robe","state_id":"1128"},
    {"city_id":"16783","city_name":"Sebeta","state_id":"1128"},
    {"city_id":"16784","city_name":"Sendafa","state_id":"1128"},
    {"city_id":"16785","city_name":"Shakiso","state_id":"1128"},
    {"city_id":"16786","city_name":"Shambu","state_id":"1128"},
    {"city_id":"16787","city_name":"Shashemenne","state_id":"1128"},
    {"city_id":"16788","city_name":"Sheh Hussen","state_id":"1128"},
    {"city_id":"16789","city_name":"Sire","state_id":"1128"},
    {"city_id":"16790","city_name":"Tulu Bolo","state_id":"1128"},
    {"city_id":"16791","city_name":"Welench'iti","state_id":"1128"},
    {"city_id":"16792","city_name":"Welk'it'e","state_id":"1128"},
    {"city_id":"16793","city_name":"Wonji","state_id":"1128"},
    {"city_id":"16794","city_name":"Yabelo","state_id":"1128"},
    {"city_id":"16795","city_name":"Ziway","state_id":"1128"},
    {"city_id":"16796","city_name":"Bircot","state_id":"1129"},
    {"city_id":"16797","city_name":"Degeh Bur","state_id":"1129"},
    {"city_id":"16798","city_name":"Dollo Odo","state_id":"1129"},
    {"city_id":"16799","city_name":"Imi","state_id":"1129"},
    {"city_id":"16800","city_name":"Jijiga","state_id":"1129"},
    {"city_id":"16801","city_name":"Werder","state_id":"1129"},
    {"city_id":"16802","city_name":"Alaba Kulito","state_id":"1130"},
    {"city_id":"16803","city_name":"Arba Minch","state_id":"1130"},
    {"city_id":"16804","city_name":"Areka","state_id":"1130"},
    {"city_id":"16805","city_name":"Awassa","state_id":"1130"},
    {"city_id":"16806","city_name":"Bako","state_id":"1130"},
    {"city_id":"16807","city_name":"Boditi","state_id":"1130"},
    {"city_id":"16808","city_name":"Bonga","state_id":"1130"},
    {"city_id":"16809","city_name":"Butajira","state_id":"1130"},
    {"city_id":"16810","city_name":"Ch'ench'a","state_id":"1130"},
    {"city_id":"16811","city_name":"Dilla","state_id":"1130"},
    {"city_id":"16812","city_name":"Gidole","state_id":"1130"},
    {"city_id":"16813","city_name":"Hossa'ina","state_id":"1130"},
    {"city_id":"16814","city_name":"Jinka","state_id":"1130"},
    {"city_id":"16815","city_name":"Leku","state_id":"1130"},
    {"city_id":"16816","city_name":"Mizan Teferi","state_id":"1130"},
    {"city_id":"16817","city_name":"Sawla","state_id":"1130"},
    {"city_id":"16818","city_name":"Soddo","state_id":"1130"},
    {"city_id":"16819","city_name":"Teppi","state_id":"1130"},
    {"city_id":"16820","city_name":"Wendo","state_id":"1130"},
    {"city_id":"16821","city_name":"Yirga Alem","state_id":"1130"},
    {"city_id":"16822","city_name":"Yirga Ch'efe","state_id":"1130"},
    {"city_id":"16823","city_name":"Abiy Adi","state_id":"1131"},
    {"city_id":"16824","city_name":"Addigrat","state_id":"1131"},
    {"city_id":"16825","city_name":"Adwa","state_id":"1131"},
    {"city_id":"16826","city_name":"Aksum","state_id":"1131"},
    {"city_id":"16827","city_name":"Alamat'a","state_id":"1131"},
    {"city_id":"16828","city_name":"Endasilasie","state_id":"1131"},
    {"city_id":"16829","city_name":"Hagere Selam","state_id":"1131"},
    {"city_id":"16830","city_name":"Himora","state_id":"1131"},
    {"city_id":"16831","city_name":"Korem","state_id":"1131"},
    {"city_id":"16832","city_name":"Maych'ew","state_id":"1131"},
    {"city_id":"16833","city_name":"Mek'ele","state_id":"1131"},
    {"city_id":"16834","city_name":"Mekele","state_id":"1131"},
    {"city_id":"16835","city_name":"Wik'ro","state_id":"1131"},
    {"city_id":"16836","city_name":"Hvannasund","state_id":"1137"},
    {"city_id":"16837","city_name":"Klaksvik","state_id":"1137"},
    {"city_id":"16838","city_name":"Husavik","state_id":"1140"},
    {"city_id":"16839","city_name":"Sands","state_id":"1140"},
    {"city_id":"16840","city_name":"Skalavik","state_id":"1140"},
    {"city_id":"16841","city_name":"Skopun","state_id":"1140"},
    {"city_id":"16842","city_name":"Skuvoy","state_id":"1140"},
    {"city_id":"16843","city_name":"Haldarsvik","state_id":"1141"},
    {"city_id":"16844","city_name":"Hests","state_id":"1141"},
    {"city_id":"16845","city_name":"Hosvik","state_id":"1141"},
    {"city_id":"16846","city_name":"Hvalvik","state_id":"1141"},
    {"city_id":"16847","city_name":"Kirkjubo","state_id":"1141"},
    {"city_id":"16848","city_name":"Kollafjar ","state_id":"1141"},
    {"city_id":"16849","city_name":"Kvivik","state_id":"1141"},
    {"city_id":"16850","city_name":"Nolsoy","state_id":"1141"},
    {"city_id":"16851","city_name":"Saksun","state_id":"1141"},
    {"city_id":"16852","city_name":"Torshavn","state_id":"1141"},
    {"city_id":"16853","city_name":"Vestmanna","state_id":"1141"},
    {"city_id":"16854","city_name":"Torshavn","state_id":"1144"},
    {"city_id":"16855","city_name":"Biggjar","state_id":"1145"},
    {"city_id":"16856","city_name":"Midvags","state_id":"1145"},
    {"city_id":"16857","city_name":"Mykines","state_id":"1145"},
    {"city_id":"16858","city_name":"Sandavags","state_id":"1145"},
    {"city_id":"16859","city_name":"Sorvags","state_id":"1145"},
    {"city_id":"16860","city_name":"Deuba","state_id":"1146"},
    {"city_id":"16861","city_name":"Korovou","state_id":"1146"},
    {"city_id":"16862","city_name":"Lami","state_id":"1146"},
    {"city_id":"16863","city_name":"Namosi","state_id":"1146"},
    {"city_id":"16864","city_name":"Nausori","state_id":"1146"},
    {"city_id":"16865","city_name":"Navua","state_id":"1146"},
    {"city_id":"16866","city_name":"Suva","state_id":"1146"},
    {"city_id":"16867","city_name":"Levuka","state_id":"1147"},
    {"city_id":"16868","city_name":"Malhaha","state_id":"1147"},
    {"city_id":"16869","city_name":"Tubou","state_id":"1147"},
    {"city_id":"16870","city_name":"Vunisea","state_id":"1147"},
    {"city_id":"16871","city_name":"Korokade","state_id":"1148"},
    {"city_id":"16872","city_name":"Labasa","state_id":"1148"},
    {"city_id":"16873","city_name":"Savusavu","state_id":"1148"},
    {"city_id":"16874","city_name":"Ba","state_id":"1150"},
    {"city_id":"16875","city_name":"Lautoka","state_id":"1150"},
    {"city_id":"16876","city_name":"Nadi","state_id":"1150"},
    {"city_id":"16877","city_name":"Rakiraki","state_id":"1150"},
    {"city_id":"16878","city_name":"Sigatoka","state_id":"1150"},
    {"city_id":"16879","city_name":"Tavua","state_id":"1150"},
    {"city_id":"16880","city_name":"Vatukoula","state_id":"1150"},
    {"city_id":"16881","city_name":"Maarianhamina","state_id":"1151"},
    {"city_id":"16882","city_name":"Imatra","state_id":"1152"},
    {"city_id":"16883","city_name":"Joutseno","state_id":"1152"},
    {"city_id":"16884","city_name":"Lappeenranta","state_id":"1152"},
    {"city_id":"16885","city_name":"Hyllykallio","state_id":"1153"},
    {"city_id":"16886","city_name":"Ilmajoki","state_id":"1153"},
    {"city_id":"16887","city_name":"Kauhajoki","state_id":"1153"},
    {"city_id":"16888","city_name":"Kurikka","state_id":"1153"},
    {"city_id":"16889","city_name":"Lapua","state_id":"1153"},
    {"city_id":"16890","city_name":"Seinajoki","state_id":"1153"},
    {"city_id":"16891","city_name":"Mikkeli","state_id":"1154"},
    {"city_id":"16892","city_name":"Savonlinna","state_id":"1154"},
    {"city_id":"16893","city_name":"Loviisa","state_id":"1157"},
    {"city_id":"16894","city_name":"Orimattila","state_id":"1157"},
    {"city_id":"16895","city_name":"Porvoo","state_id":"1157"},
    {"city_id":"16896","city_name":"Kajaani","state_id":"1158"},
    {"city_id":"16897","city_name":"Forssa","state_id":"1159"},
    {"city_id":"16898","city_name":"Hameenlinna","state_id":"1159"},
    {"city_id":"16899","city_name":"Janakkala","state_id":"1159"},
    {"city_id":"16900","city_name":"Riihimaki","state_id":"1159"},
    {"city_id":"16901","city_name":"Kokkola","state_id":"1160"},
    {"city_id":"16902","city_name":"Aanekoski","state_id":"1161"},
    {"city_id":"16903","city_name":"Jamsa","state_id":"1161"},
    {"city_id":"16904","city_name":"Jyvaskyla","state_id":"1161"},
    {"city_id":"16905","city_name":"Keuruu","state_id":"1161"},
    {"city_id":"16906","city_name":"Laukaa","state_id":"1161"},
    {"city_id":"16907","city_name":"Lieto","state_id":"1161"},
    {"city_id":"16908","city_name":"Anjalankoski","state_id":"1162"},
    {"city_id":"16909","city_name":"Hamina","state_id":"1162"},
    {"city_id":"16910","city_name":"Kotka","state_id":"1162"},
    {"city_id":"16911","city_name":"Kouvola","state_id":"1162"},
    {"city_id":"16912","city_name":"Kuusankoski","state_id":"1162"},
    {"city_id":"16913","city_name":"Valkeala","state_id":"1162"},
    {"city_id":"16914","city_name":"Vehkalahti","state_id":"1162"},
    {"city_id":"16915","city_name":"Kemi","state_id":"1164"},
    {"city_id":"16916","city_name":"Kemijarvi","state_id":"1164"},
    {"city_id":"16917","city_name":"Rovaniemi","state_id":"1164"},
    {"city_id":"16918","city_name":"Sonka","state_id":"1164"},
    {"city_id":"16919","city_name":"Tornio","state_id":"1164"},
    {"city_id":"16920","city_name":"Jakobstad","state_id":"1166"},
    {"city_id":"16921","city_name":"Oulunsalo","state_id":"1166"},
    {"city_id":"16922","city_name":"Heinola","state_id":"1168"},
    {"city_id":"16923","city_name":"Hollola","state_id":"1168"},
    {"city_id":"16924","city_name":"Lahti","state_id":"1168"},
    {"city_id":"16925","city_name":"Nastola","state_id":"1168"},
    {"city_id":"16926","city_name":"Kangasala","state_id":"1169"},
    {"city_id":"16927","city_name":"Lempaala","state_id":"1169"},
    {"city_id":"16928","city_name":"Nokia","state_id":"1169"},
    {"city_id":"16929","city_name":"Pirkkala","state_id":"1169"},
    {"city_id":"16930","city_name":"Sastamala","state_id":"1169"},
    {"city_id":"16931","city_name":"Tampere","state_id":"1169"},
    {"city_id":"16932","city_name":"Valkeakoski","state_id":"1169"},
    {"city_id":"16933","city_name":"Ylojarvi","state_id":"1169"},
    {"city_id":"16934","city_name":"Kristiinankaupunki","state_id":"1170"},
    {"city_id":"16935","city_name":"Mustasaari","state_id":"1170"},
    {"city_id":"16936","city_name":"Pietarsaari","state_id":"1170"},
    {"city_id":"16937","city_name":"Uusikarlepyy","state_id":"1170"},
    {"city_id":"16938","city_name":"Vaasa","state_id":"1170"},
    {"city_id":"16939","city_name":"Joensuu","state_id":"1171"},
    {"city_id":"16940","city_name":"Lieksa","state_id":"1171"},
    {"city_id":"16941","city_name":"Haukipudas","state_id":"1172"},
    {"city_id":"16942","city_name":"Kempele","state_id":"1172"},
    {"city_id":"16943","city_name":"Kuusamo","state_id":"1172"},
    {"city_id":"16944","city_name":"Muhos","state_id":"1172"},
    {"city_id":"16945","city_name":"Nivala","state_id":"1172"},
    {"city_id":"16946","city_name":"Oulainen","state_id":"1172"},
    {"city_id":"16947","city_name":"Oulu","state_id":"1172"},
    {"city_id":"16948","city_name":"Raahe","state_id":"1172"},
    {"city_id":"16949","city_name":"Ylivieska","state_id":"1172"},
    {"city_id":"16950","city_name":"Iisalmi","state_id":"1173"},
    {"city_id":"16951","city_name":"Kuopio","state_id":"1173"},
    {"city_id":"16952","city_name":"Sillinjarvi","state_id":"1173"},
    {"city_id":"16953","city_name":"Varkaus","state_id":"1173"},
    {"city_id":"16954","city_name":"Saarijarvi","state_id":"1174"},
    {"city_id":"16955","city_name":"Eura","state_id":"1175"},
    {"city_id":"16956","city_name":"Eurajoki","state_id":"1175"},
    {"city_id":"16957","city_name":"Harjavalta","state_id":"1175"},
    {"city_id":"16958","city_name":"Huittinen","state_id":"1175"},
    {"city_id":"16959","city_name":"Kankaanpaa","state_id":"1175"},
    {"city_id":"16960","city_name":"Kokemaki","state_id":"1175"},
    {"city_id":"16961","city_name":"Lappi","state_id":"1175"},
    {"city_id":"16962","city_name":"Nakkila","state_id":"1175"},
    {"city_id":"16963","city_name":"Noormarkku","state_id":"1175"},
    {"city_id":"16964","city_name":"Pori","state_id":"1175"},
    {"city_id":"16965","city_name":"Rauma","state_id":"1175"},
    {"city_id":"16966","city_name":"Sakyla","state_id":"1175"},
    {"city_id":"16967","city_name":"Ulvila","state_id":"1175"},
    {"city_id":"16968","city_name":"Vammala","state_id":"1175"},
    {"city_id":"16969","city_name":"Espoo","state_id":"1179"},
    {"city_id":"16970","city_name":"Hanko","state_id":"1179"},
    {"city_id":"16971","city_name":"Helsinki","state_id":"1179"},
    {"city_id":"16972","city_name":"Hyvinkaa","state_id":"1179"},
    {"city_id":"16973","city_name":"Jarvenpaa","state_id":"1179"},
    {"city_id":"16974","city_name":"Kauniainen","state_id":"1179"},
    {"city_id":"16975","city_name":"Kerava","state_id":"1179"},
    {"city_id":"16976","city_name":"Kirkkonummi","state_id":"1179"},
    {"city_id":"16977","city_name":"Lohja","state_id":"1179"},
    {"city_id":"16978","city_name":"Mantsala","state_id":"1179"},
    {"city_id":"16979","city_name":"Nurmijarvi","state_id":"1179"},
    {"city_id":"16980","city_name":"Sipoo","state_id":"1179"},
    {"city_id":"16981","city_name":"Tammisaari","state_id":"1179"},
    {"city_id":"16982","city_name":"Tuusula","state_id":"1179"},
    {"city_id":"16983","city_name":"Vantaa","state_id":"1179"},
    {"city_id":"16984","city_name":"Vihti","state_id":"1179"},
    {"city_id":"16985","city_name":"Kaarina","state_id":"1180"},
    {"city_id":"16986","city_name":"Naantali","state_id":"1180"},
    {"city_id":"16987","city_name":"Parainen","state_id":"1180"},
    {"city_id":"16988","city_name":"Raisio","state_id":"1180"},
    {"city_id":"16989","city_name":"Salo","state_id":"1180"},
    {"city_id":"16990","city_name":"Turku","state_id":"1180"},
    {"city_id":"16991","city_name":"Uusikaupunki","state_id":"1180"},
    {"city_id":"16992","city_name":"Amberieu-en-Bugey","state_id":"1181"},
    {"city_id":"16993","city_name":"Bellegarde-sur-Valserine","state_id":"1181"},
    {"city_id":"16994","city_name":"Bourg-en-Bresse","state_id":"1181"},
    {"city_id":"16995","city_name":"Oyonnax","state_id":"1181"},
    {"city_id":"16996","city_name":"Chateau-Thierry","state_id":"1182"},
    {"city_id":"16997","city_name":"Chauny","state_id":"1182"},
    {"city_id":"16998","city_name":"Hirson","state_id":"1182"},
    {"city_id":"16999","city_name":"Laon","state_id":"1182"},
    {"city_id":"17000","city_name":"Saint-Quentin","state_id":"1182"},
    {"city_id":"17001","city_name":"Soissons","state_id":"1182"},
    {"city_id":"17002","city_name":"Strassbourg","state_id":"1182"},
    {"city_id":"17003","city_name":"Tergnier","state_id":"1182"},
    {"city_id":"17004","city_name":"Cusset","state_id":"1184"},
    {"city_id":"17005","city_name":"Montlucon","state_id":"1184"},
    {"city_id":"17006","city_name":"Moulins","state_id":"1184"},
    {"city_id":"17007","city_name":"Vichy","state_id":"1184"},
    {"city_id":"17008","city_name":"Yzeure","state_id":"1184"},
    {"city_id":"17009","city_name":"Antibes","state_id":"1186"},
    {"city_id":"17010","city_name":"Beausoleil","state_id":"1186"},
    {"city_id":"17011","city_name":"Cagnes-sur-Mer","state_id":"1186"},
    {"city_id":"17012","city_name":"Cannes","state_id":"1186"},
    {"city_id":"17013","city_name":"Carros","state_id":"1186"},
    {"city_id":"17014","city_name":"Grasse","state_id":"1186"},
    {"city_id":"17015","city_name":"La Trinite","state_id":"1186"},
    {"city_id":"17016","city_name":"Le Cannet","state_id":"1186"},
    {"city_id":"17017","city_name":"Mandelieu-la-Napoule","state_id":"1186"},
    {"city_id":"17018","city_name":"Menton","state_id":"1186"},
    {"city_id":"17019","city_name":"Mougins","state_id":"1186"},
    {"city_id":"17020","city_name":"Nice","state_id":"1186"},
    {"city_id":"17021","city_name":"Roquebrune-Cap-Martin","state_id":"1186"},
    {"city_id":"17022","city_name":"Saint-Laurent-du-Var","state_id":"1186"},
    {"city_id":"17023","city_name":"Valbonne","state_id":"1186"},
    {"city_id":"17024","city_name":"Vallauris","state_id":"1186"},
    {"city_id":"17025","city_name":"Vence","state_id":"1186"},
    {"city_id":"17026","city_name":"Villeneuve-Loubet","state_id":"1186"},
    {"city_id":"17027","city_name":"Digne-les-Bains","state_id":"1187"},
    {"city_id":"17028","city_name":"Manosque","state_id":"1187"},
    {"city_id":"17029","city_name":"Adainville","state_id":"1188"},
    {"city_id":"17030","city_name":"Alby-sur-Cheran","state_id":"1188"},
    {"city_id":"17031","city_name":"Aubervilliers","state_id":"1188"},
    {"city_id":"17032","city_name":"Auterive","state_id":"1188"},
    {"city_id":"17033","city_name":"Baillet","state_id":"1188"},
    {"city_id":"17034","city_name":"Beaune","state_id":"1188"},
    {"city_id":"17035","city_name":"Beauzelle","state_id":"1188"},
    {"city_id":"17036","city_name":"Bonnet De Mure","state_id":"1188"},
    {"city_id":"17037","city_name":"Bormes-les-Mimosas","state_id":"1188"},
    {"city_id":"17038","city_name":"Brindas","state_id":"1188"},
    {"city_id":"17039","city_name":"Burlats","state_id":"1188"},
    {"city_id":"17040","city_name":"Buzancais","state_id":"1188"},
    {"city_id":"17041","city_name":"Candillargues","state_id":"1188"},
    {"city_id":"17042","city_name":"Carry-le-Rouet","state_id":"1188"},
    {"city_id":"17043","city_name":"Cassis","state_id":"1188"},
    {"city_id":"17044","city_name":"Cessenon-Sur-Orb","state_id":"1188"},
    {"city_id":"17045","city_name":"Chanac","state_id":"1188"},
    {"city_id":"17046","city_name":"Chevigny","state_id":"1188"},
    {"city_id":"17047","city_name":"Cogolin","state_id":"1188"},
    {"city_id":"17048","city_name":"Collioure","state_id":"1188"},
    {"city_id":"17049","city_name":"Cremieu","state_id":"1188"},
    {"city_id":"17050","city_name":"Drulingen","state_id":"1188"},
    {"city_id":"17051","city_name":"Ecouen","state_id":"1188"},
    {"city_id":"17052","city_name":"Eschau","state_id":"1188"},
    {"city_id":"17053","city_name":"Feignies","state_id":"1188"},
    {"city_id":"17054","city_name":"Ferus","state_id":"1188"},
    {"city_id":"17055","city_name":"Fourqueux","state_id":"1188"},
    {"city_id":"17056","city_name":"Franqueville","state_id":"1188"},
    {"city_id":"17057","city_name":"Gignac","state_id":"1188"},
    {"city_id":"17058","city_name":"Gravigny","state_id":"1188"},
    {"city_id":"17059","city_name":"Hangenbieten","state_id":"1188"},
    {"city_id":"17060","city_name":"Hunspach","state_id":"1188"},
    {"city_id":"17061","city_name":"Kilstett","state_id":"1188"},
    {"city_id":"17062","city_name":"La Chevroliere","state_id":"1188"},
    {"city_id":"17063","city_name":"La-Fare-Les-Oliviers","state_id":"1188"},
    {"city_id":"17064","city_name":"Lanvellec","state_id":"1188"},
    {"city_id":"17065","city_name":"Le Faget","state_id":"1188"},
    {"city_id":"17066","city_name":"Lesigny","state_id":"1188"},
    {"city_id":"17067","city_name":"Lesquin","state_id":"1188"},
    {"city_id":"17068","city_name":"Limonest","state_id":"1188"},
    {"city_id":"17069","city_name":"Messein","state_id":"1188"},
    {"city_id":"17070","city_name":"Morgat","state_id":"1188"},
    {"city_id":"17071","city_name":"Mundolsheim","state_id":"1188"},
    {"city_id":"17072","city_name":"Nantiat","state_id":"1188"},
    {"city_id":"17073","city_name":"Niederbronn-les-Bain","state_id":"1188"},
    {"city_id":"17074","city_name":"Nimes","state_id":"1188"},
    {"city_id":"17075","city_name":"Opoul","state_id":"1188"},
    {"city_id":"17076","city_name":"Pance","state_id":"1188"},
    {"city_id":"17077","city_name":"Peronne","state_id":"1188"},
    {"city_id":"17078","city_name":"Ploneour Lanvern","state_id":"1188"},
    {"city_id":"17079","city_name":"Realmont","state_id":"1188"},
    {"city_id":"17080","city_name":"Reichstett","state_id":"1188"},
    {"city_id":"17081","city_name":"Saint Aubin","state_id":"1188"},
    {"city_id":"17082","city_name":"Saint Christophe","state_id":"1188"},
    {"city_id":"17083","city_name":"Saint Martin dÃƒÂ¢Ã‚Â€Ã‚Â™Here","state_id":"1188"},
    {"city_id":"17084","city_name":"Saint-Berthevin","state_id":"1188"},
    {"city_id":"17085","city_name":"Saint-Cergues","state_id":"1188"},
    {"city_id":"17086","city_name":"Sancerre","state_id":"1188"},
    {"city_id":"17087","city_name":"Sarzeau","state_id":"1188"},
    {"city_id":"17088","city_name":"Seltz","state_id":"1188"},
    {"city_id":"17089","city_name":"Seyssins","state_id":"1188"},
    {"city_id":"17090","city_name":"Souffelweyersheim","state_id":"1188"},
    {"city_id":"17091","city_name":"Vireux-Molhain","state_id":"1188"},
    {"city_id":"17092","city_name":"Voves","state_id":"1188"},
    {"city_id":"17093","city_name":"Wambrechies","state_id":"1188"},
    {"city_id":"17094","city_name":"Wervocq Sud","state_id":"1188"},
    {"city_id":"17095","city_name":"Abzac","state_id":"1189"},
    {"city_id":"17096","city_name":"Bidart","state_id":"1189"},
    {"city_id":"17097","city_name":"Biganos","state_id":"1189"},
    {"city_id":"17098","city_name":"Buzet-sur-Baise","state_id":"1189"},
    {"city_id":"17099","city_name":"Coursac","state_id":"1189"},
    {"city_id":"17100","city_name":"Hasparren","state_id":"1189"},
    {"city_id":"17101","city_name":"Landiras","state_id":"1189"},
    {"city_id":"17102","city_name":"Le Haillan","state_id":"1189"},
    {"city_id":"17103","city_name":"Ledat","state_id":"1189"},
    {"city_id":"17104","city_name":"Martillac","state_id":"1189"},
    {"city_id":"17105","city_name":"Puyoo","state_id":"1189"},
    {"city_id":"17106","city_name":"Saint-Jean-dIllac","state_id":"1189"},
    {"city_id":"17107","city_name":"Seignosse","state_id":"1189"},
    {"city_id":"17108","city_name":"Tresses","state_id":"1189"},
    {"city_id":"17109","city_name":"Annonay","state_id":"1190"},
    {"city_id":"17110","city_name":"Aubenas","state_id":"1190"},
    {"city_id":"17111","city_name":"Guilherand-Granges","state_id":"1190"},
    {"city_id":"17112","city_name":"Privas","state_id":"1190"},
    {"city_id":"17113","city_name":"Tournon-sur-Rhone","state_id":"1190"},
    {"city_id":"17114","city_name":"Charleville-Mezieres","state_id":"1191"},
    {"city_id":"17115","city_name":"Les Hautes-Rivieres","state_id":"1191"},
    {"city_id":"17116","city_name":"Revin","state_id":"1191"},
    {"city_id":"17117","city_name":"Sedan","state_id":"1191"},
    {"city_id":"17118","city_name":"Foix","state_id":"1192"},
    {"city_id":"17119","city_name":"Pamier","state_id":"1192"},
    {"city_id":"17120","city_name":"Bar-sur-Seine","state_id":"1193"},
    {"city_id":"17121","city_name":"Romilly-sur-Seine","state_id":"1193"},
    {"city_id":"17122","city_name":"Saint-Andre-les-Vergers","state_id":"1193"},
    {"city_id":"17123","city_name":"Sainte-Savine","state_id":"1193"},
    {"city_id":"17124","city_name":"Troyes","state_id":"1193"},
    {"city_id":"17125","city_name":"Carcassonne","state_id":"1194"},
    {"city_id":"17126","city_name":"Castelnaudary","state_id":"1194"},
    {"city_id":"17127","city_name":"Limoux","state_id":"1194"},
    {"city_id":"17128","city_name":"Narbonne","state_id":"1194"},
    {"city_id":"17129","city_name":"Ambert","state_id":"1195"},
    {"city_id":"17130","city_name":"Creuzier-le-Vieux","state_id":"1195"},
    {"city_id":"17131","city_name":"Ferrieres","state_id":"1195"},
    {"city_id":"17132","city_name":"Peschadoires","state_id":"1195"},
    {"city_id":"17133","city_name":"Riotord","state_id":"1195"},
    {"city_id":"17134","city_name":"Saint-Pal-de-Chalencon","state_id":"1195"},
    {"city_id":"17135","city_name":"Saint-Romain-Lachalm","state_id":"1195"},
    {"city_id":"17136","city_name":"Saint-Vidal","state_id":"1195"},
    {"city_id":"17137","city_name":"Sainte-Sigolene","state_id":"1195"},
    {"city_id":"17138","city_name":"Millau","state_id":"1196"},
    {"city_id":"17139","city_name":"Onet-le-Chataeu","state_id":"1196"},
    {"city_id":"17140","city_name":"Rodez","state_id":"1196"},
    {"city_id":"17141","city_name":"Villefranche-de-Rouergue","state_id":"1196"},
    {"city_id":"17142","city_name":"Bischheim","state_id":"1197"},
    {"city_id":"17143","city_name":"Bischwiller","state_id":"1197"},
    {"city_id":"17144","city_name":"Haguenau","state_id":"1197"},
    {"city_id":"17145","city_name":"Hoenheim","state_id":"1197"},
    {"city_id":"17146","city_name":"Illkirch-Graffenstaden","state_id":"1197"},
    {"city_id":"17147","city_name":"Lingolsheim","state_id":"1197"},
    {"city_id":"17148","city_name":"Obernai","state_id":"1197"},
    {"city_id":"17149","city_name":"Ostwald","state_id":"1197"},
    {"city_id":"17150","city_name":"Saverne","state_id":"1197"},
    {"city_id":"17151","city_name":"Schiltigheim","state_id":"1197"},
    {"city_id":"17152","city_name":"Selestat","state_id":"1197"},
    {"city_id":"17153","city_name":"Strasbourg","state_id":"1197"},
    {"city_id":"17154","city_name":"Deauville","state_id":"1198"},
    {"city_id":"17155","city_name":"Aix-en-Provence","state_id":"1199"},
    {"city_id":"17156","city_name":"Allauch","state_id":"1199"},
    {"city_id":"17157","city_name":"Arles","state_id":"1199"},
    {"city_id":"17158","city_name":"Aubagne","state_id":"1199"},
    {"city_id":"17159","city_name":"Berre-l'Etang","state_id":"1199"},
    {"city_id":"17160","city_name":"Bouc-Bel-Air","state_id":"1199"},
    {"city_id":"17161","city_name":"Chateauneuf-les-Martigues","state_id":"1199"},
    {"city_id":"17162","city_name":"Chateaurenard","state_id":"1199"},
    {"city_id":"17163","city_name":"Fos-sur-Mer","state_id":"1199"},
    {"city_id":"17164","city_name":"Gardanne","state_id":"1199"},
    {"city_id":"17165","city_name":"Istres","state_id":"1199"},
    {"city_id":"17166","city_name":"La Ciotat","state_id":"1199"},
    {"city_id":"17167","city_name":"Les Pennes-Mirabeau","state_id":"1199"},
    {"city_id":"17168","city_name":"Maillane","state_id":"1199"},
    {"city_id":"17169","city_name":"Marignane","state_id":"1199"},
    {"city_id":"17170","city_name":"Marseille","state_id":"1199"},
    {"city_id":"17171","city_name":"Martigues","state_id":"1199"},
    {"city_id":"17172","city_name":"Miramas","state_id":"1199"},
    {"city_id":"17173","city_name":"Plan-de-Cuques","state_id":"1199"},
    {"city_id":"17174","city_name":"Port-de-Bouc","state_id":"1199"},
    {"city_id":"17175","city_name":"Rognac","state_id":"1199"},
    {"city_id":"17176","city_name":"Saint-Martin-de-Crau","state_id":"1199"},
    {"city_id":"17177","city_name":"Saint-Remy-de-Provence","state_id":"1199"},
    {"city_id":"17178","city_name":"Salon-de-Provence","state_id":"1199"},
    {"city_id":"17179","city_name":"Septemes-les-Vallons","state_id":"1199"},
    {"city_id":"17180","city_name":"Tarascon","state_id":"1199"},
    {"city_id":"17181","city_name":"Vitrolles","state_id":"1199"},
    {"city_id":"17182","city_name":"Migennes","state_id":"1200"},
    {"city_id":"17183","city_name":"Javene","state_id":"1201"},
    {"city_id":"17184","city_name":"Plouha","state_id":"1201"},
    {"city_id":"17185","city_name":"Brehan","state_id":"1202"},
    {"city_id":"17186","city_name":"Broons","state_id":"1202"},
    {"city_id":"17187","city_name":"Guipry","state_id":"1202"},
    {"city_id":"17188","city_name":"Miniac-Morvan","state_id":"1202"},
    {"city_id":"17189","city_name":"Ploudaniel","state_id":"1202"},
    {"city_id":"17190","city_name":"Vern-sur-Seiche","state_id":"1202"},
    {"city_id":"17191","city_name":"Aillant-sur-Tholon","state_id":"1203"},
    {"city_id":"17192","city_name":"Bayeux","state_id":"1204"},
    {"city_id":"17193","city_name":"Caen","state_id":"1204"},
    {"city_id":"17194","city_name":"Herouville-Saint-Clair","state_id":"1204"},
    {"city_id":"17195","city_name":"Lisieux","state_id":"1204"},
    {"city_id":"17196","city_name":"Mondeville","state_id":"1204"},
    {"city_id":"17197","city_name":"Vire","state_id":"1204"},
    {"city_id":"17198","city_name":"Aurillac","state_id":"1205"},
    {"city_id":"17199","city_name":"Brumath","state_id":"1206"},
    {"city_id":"17200","city_name":"Courtaboeuf","state_id":"1206"},
    {"city_id":"17201","city_name":"Iregny","state_id":"1206"},
    {"city_id":"17202","city_name":"cedex","state_id":"1206"},
    {"city_id":"17203","city_name":"Azay-le-Rideau","state_id":"1207"},
    {"city_id":"17204","city_name":"Chevillon-sur-Huillard","state_id":"1207"},
    {"city_id":"17205","city_name":"Cloyes-sur-le-Loir","state_id":"1207"},
    {"city_id":"17206","city_name":"Gellainville","state_id":"1207"},
    {"city_id":"17207","city_name":"La Chaussse-Saint-Victor","state_id":"1207"},
    {"city_id":"17208","city_name":"La Ville-aux-Clercs","state_id":"1207"},
    {"city_id":"17209","city_name":"Ladon","state_id":"1207"},
    {"city_id":"17210","city_name":"Le Chatelet","state_id":"1207"},
    {"city_id":"17211","city_name":"Angouleme","state_id":"1208"},
    {"city_id":"17212","city_name":"Cognac","state_id":"1208"},
    {"city_id":"17213","city_name":"Lencloitre","state_id":"1208"},
    {"city_id":"17214","city_name":"Soyaux","state_id":"1208"},
    {"city_id":"17215","city_name":"La Rochelle","state_id":"1209"},
    {"city_id":"17216","city_name":"Rochefort","state_id":"1209"},
    {"city_id":"17217","city_name":"Royan","state_id":"1209"},
    {"city_id":"17218","city_name":"Saintes","state_id":"1209"},
    {"city_id":"17219","city_name":"Bourges","state_id":"1210"},
    {"city_id":"17220","city_name":"Saint-Amand-Montrond","state_id":"1210"},
    {"city_id":"17221","city_name":"Saint-Doulchard","state_id":"1210"},
    {"city_id":"17222","city_name":"Vierzon","state_id":"1210"},
    {"city_id":"17223","city_name":"Brive-la-Gaillarde","state_id":"1211"},
    {"city_id":"17224","city_name":"Tulle","state_id":"1211"},
    {"city_id":"17225","city_name":"Ussel","state_id":"1211"},
    {"city_id":"17226","city_name":"Ajaccio","state_id":"1212"},
    {"city_id":"17227","city_name":"Porto-Vecchio","state_id":"1212"},
    {"city_id":"17228","city_name":"Beaune","state_id":"1213"},
    {"city_id":"17229","city_name":"Chenove","state_id":"1213"},
    {"city_id":"17230","city_name":"Dijon","state_id":"1213"},
    {"city_id":"17231","city_name":"Quetigny","state_id":"1213"},
    {"city_id":"17232","city_name":"Talant","state_id":"1213"},
    {"city_id":"17233","city_name":"Dinan","state_id":"1214"},
    {"city_id":"17234","city_name":"Lamballe","state_id":"1214"},
    {"city_id":"17235","city_name":"Lannion","state_id":"1214"},
    {"city_id":"17236","city_name":"Loudeac","state_id":"1214"},
    {"city_id":"17237","city_name":"Plerin","state_id":"1214"},
    {"city_id":"17238","city_name":"Ploufragan","state_id":"1214"},
    {"city_id":"17239","city_name":"Saint-Brieuc","state_id":"1214"},
    {"city_id":"17240","city_name":"Gueret","state_id":"1215"},
    {"city_id":"17241","city_name":"Crolles","state_id":"1216"},
    {"city_id":"17242","city_name":"Bressuire","state_id":"1217"},
    {"city_id":"17243","city_name":"Niort","state_id":"1217"},
    {"city_id":"17244","city_name":"Parthenay","state_id":"1217"},
    {"city_id":"17245","city_name":"Thouars","state_id":"1217"},
    {"city_id":"17246","city_name":"Bergerac","state_id":"1218"},
    {"city_id":"17247","city_name":"Perigueux","state_id":"1218"},
    {"city_id":"17248","city_name":"Sarlat-la-Caneda","state_id":"1218"},
    {"city_id":"17249","city_name":"Audincourt","state_id":"1219"},
    {"city_id":"17250","city_name":"Besancon","state_id":"1219"},
    {"city_id":"17251","city_name":"Beure","state_id":"1219"},
    {"city_id":"17252","city_name":"Montbeliard","state_id":"1219"},
    {"city_id":"17253","city_name":"Pontarlier","state_id":"1219"},
    {"city_id":"17254","city_name":"Valentigney","state_id":"1219"},
    {"city_id":"17255","city_name":"Bourg-les-Valence","state_id":"1220"},
    {"city_id":"17256","city_name":"Montelimar","state_id":"1220"},
    {"city_id":"17257","city_name":"Pierrelatte","state_id":"1220"},
    {"city_id":"17258","city_name":"Romans-sur-Isere","state_id":"1220"},
    {"city_id":"17259","city_name":"Valence","state_id":"1220"},
    {"city_id":"17260","city_name":"Athis-Mons","state_id":"1221"},
    {"city_id":"17261","city_name":"Bretigny-sur-Orge","state_id":"1221"},
    {"city_id":"17262","city_name":"Brunoy","state_id":"1221"},
    {"city_id":"17263","city_name":"Bures-sur-Yvette","state_id":"1221"},
    {"city_id":"17264","city_name":"Chilly-Mazarin","state_id":"1221"},
    {"city_id":"17265","city_name":"Corbeil-Essonnes","state_id":"1221"},
    {"city_id":"17266","city_name":"Courcouronnes","state_id":"1221"},
    {"city_id":"17267","city_name":"Dourdan","state_id":"1221"},
    {"city_id":"17268","city_name":"Draveil","state_id":"1221"},
    {"city_id":"17269","city_name":"Epinay-sous-Senart","state_id":"1221"},
    {"city_id":"17270","city_name":"Epinay-sur-Orge","state_id":"1221"},
    {"city_id":"17271","city_name":"Etampes","state_id":"1221"},
    {"city_id":"17272","city_name":"Evry","state_id":"1221"},
    {"city_id":"17273","city_name":"Fleury-Merogis","state_id":"1221"},
    {"city_id":"17274","city_name":"Gif-sur-Yvette","state_id":"1221"},
    {"city_id":"17275","city_name":"Grigny","state_id":"1221"},
    {"city_id":"17276","city_name":"Igny","state_id":"1221"},
    {"city_id":"17277","city_name":"Juvisy-sur-Orge","state_id":"1221"},
    {"city_id":"17278","city_name":"Les Ulis","state_id":"1221"},
    {"city_id":"17279","city_name":"Longjumeau","state_id":"1221"},
    {"city_id":"17280","city_name":"Massy","state_id":"1221"},
    {"city_id":"17281","city_name":"Mennecy","state_id":"1221"},
    {"city_id":"17282","city_name":"Montgeron","state_id":"1221"},
    {"city_id":"17283","city_name":"Morangis","state_id":"1221"},
    {"city_id":"17284","city_name":"Morsang-sur-Orge","state_id":"1221"},
    {"city_id":"17285","city_name":"Orsay","state_id":"1221"},
    {"city_id":"17286","city_name":"Palaiseau","state_id":"1221"},
    {"city_id":"17287","city_name":"Ris-Orangis","state_id":"1221"},
    {"city_id":"17288","city_name":"Saint-Michel-sur-Orge","state_id":"1221"},
    {"city_id":"17289","city_name":"Sainte-Genevieve-des-Bois","state_id":"1221"},
    {"city_id":"17290","city_name":"Savigny-sur-Orge","state_id":"1221"},
    {"city_id":"17291","city_name":"Verrieres-le-Buisson","state_id":"1221"},
    {"city_id":"17292","city_name":"Vigneux-sur-Seine","state_id":"1221"},
    {"city_id":"17293","city_name":"Villebon-sur-Yvette","state_id":"1221"},
    {"city_id":"17294","city_name":"Viry-Chatillon","state_id":"1221"},
    {"city_id":"17295","city_name":"Yerres","state_id":"1221"},
    {"city_id":"17296","city_name":"Bernay","state_id":"1222"},
    {"city_id":"17297","city_name":"Evreux","state_id":"1222"},
    {"city_id":"17298","city_name":"Gisors","state_id":"1222"},
    {"city_id":"17299","city_name":"Louviers","state_id":"1222"},
    {"city_id":"17300","city_name":"Pont-Audemer","state_id":"1222"},
    {"city_id":"17301","city_name":"Val-de-Reuil","state_id":"1222"},
    {"city_id":"17302","city_name":"Vernon","state_id":"1222"},
    {"city_id":"17303","city_name":"Chartres","state_id":"1223"},
    {"city_id":"17304","city_name":"Chateaudun","state_id":"1223"},
    {"city_id":"17305","city_name":"Dreux","state_id":"1223"},
    {"city_id":"17306","city_name":"Luce","state_id":"1223"},
    {"city_id":"17307","city_name":"Mainvillier","state_id":"1223"},
    {"city_id":"17308","city_name":"Nogent-le-Rotrou","state_id":"1223"},
    {"city_id":"17309","city_name":"Vernouillet","state_id":"1223"},
    {"city_id":"17310","city_name":"Feucherolles","state_id":"1224"},
    {"city_id":"17311","city_name":"Brest","state_id":"1225"},
    {"city_id":"17312","city_name":"Concarneau","state_id":"1225"},
    {"city_id":"17313","city_name":"Douarnenez","state_id":"1225"},
    {"city_id":"17314","city_name":"Guipavas","state_id":"1225"},
    {"city_id":"17315","city_name":"Landerneau","state_id":"1225"},
    {"city_id":"17316","city_name":"Le Relecq-Kerhoun","state_id":"1225"},
    {"city_id":"17317","city_name":"Morlaix","state_id":"1225"},
    {"city_id":"17318","city_name":"Plougastel-Daoulas","state_id":"1225"},
    {"city_id":"17319","city_name":"Plouzane","state_id":"1225"},
    {"city_id":"17320","city_name":"Quimper","state_id":"1225"},
    {"city_id":"17321","city_name":"Quimperle","state_id":"1225"},
    {"city_id":"17322","city_name":"Charquemont","state_id":"1226"},
    {"city_id":"17323","city_name":"Chemaudin","state_id":"1226"},
    {"city_id":"17324","city_name":"Pelousey","state_id":"1226"},
    {"city_id":"17325","city_name":"Perrigny","state_id":"1226"},
    {"city_id":"17326","city_name":"Pirey","state_id":"1226"},
    {"city_id":"17327","city_name":"Villers-le-Lac","state_id":"1226"},
    {"city_id":"17328","city_name":"Ales","state_id":"1227"},
    {"city_id":"17329","city_name":"Bagnols-sur-Ceze","state_id":"1227"},
    {"city_id":"17330","city_name":"Beaucaire","state_id":"1227"},
    {"city_id":"17331","city_name":"Nimes","state_id":"1227"},
    {"city_id":"17332","city_name":"Pont-Saint-Esprit","state_id":"1227"},
    {"city_id":"17333","city_name":"Saint-Gilles","state_id":"1227"},
    {"city_id":"17334","city_name":"Vauvert","state_id":"1227"},
    {"city_id":"17335","city_name":"Villeneuve-les-Avignon","state_id":"1227"},
    {"city_id":"17336","city_name":"Auch","state_id":"1228"},
    {"city_id":"17337","city_name":"Beraut","state_id":"1228"},
    {"city_id":"17338","city_name":"Ambares-et-Lagrave","state_id":"1229"},
    {"city_id":"17339","city_name":"Arcachon","state_id":"1229"},
    {"city_id":"17340","city_name":"Begles","state_id":"1229"},
    {"city_id":"17341","city_name":"Blanquefort","state_id":"1229"},
    {"city_id":"17342","city_name":"Bordeaux","state_id":"1229"},
    {"city_id":"17343","city_name":"Bruges","state_id":"1229"},
    {"city_id":"17344","city_name":"Cenon","state_id":"1229"},
    {"city_id":"17345","city_name":"Cestas","state_id":"1229"},
    {"city_id":"17346","city_name":"Eysines","state_id":"1229"},
    {"city_id":"17347","city_name":"Floirac","state_id":"1229"},
    {"city_id":"17348","city_name":"Gradignan","state_id":"1229"},
    {"city_id":"17349","city_name":"Gujan-Mestras","state_id":"1229"},
    {"city_id":"17350","city_name":"La Teste-de-Buch","state_id":"1229"},
    {"city_id":"17351","city_name":"Le Bouscat","state_id":"1229"},
    {"city_id":"17352","city_name":"Libourne","state_id":"1229"},
    {"city_id":"17353","city_name":"Lormont","state_id":"1229"},
    {"city_id":"17354","city_name":"Merignac","state_id":"1229"},
    {"city_id":"17355","city_name":"Pessac","state_id":"1229"},
    {"city_id":"17356","city_name":"Saint-Medard-en-Jalles","state_id":"1229"},
    {"city_id":"17357","city_name":"Talence","state_id":"1229"},
    {"city_id":"17358","city_name":"Villenave-d'Ornon","state_id":"1229"},
    {"city_id":"17359","city_name":"Cernay","state_id":"1230"},
    {"city_id":"17360","city_name":"Colmar","state_id":"1230"},
    {"city_id":"17361","city_name":"Guebwiller","state_id":"1230"},
    {"city_id":"17362","city_name":"Illzach","state_id":"1230"},
    {"city_id":"17363","city_name":"Kingersheim","state_id":"1230"},
    {"city_id":"17364","city_name":"Mulhouse","state_id":"1230"},
    {"city_id":"17365","city_name":"Riediesheim","state_id":"1230"},
    {"city_id":"17366","city_name":"Rixheim","state_id":"1230"},
    {"city_id":"17367","city_name":"Saint-Louis","state_id":"1230"},
    {"city_id":"17368","city_name":"Wittelsheim","state_id":"1230"},
    {"city_id":"17369","city_name":"Wittenheim","state_id":"1230"},
    {"city_id":"17370","city_name":"Bastia","state_id":"1231"},
    {"city_id":"17371","city_name":"Balma","state_id":"1232"},
    {"city_id":"17372","city_name":"Blagnac","state_id":"1232"},
    {"city_id":"17373","city_name":"Colomiers","state_id":"1232"},
    {"city_id":"17374","city_name":"Cugnaux","state_id":"1232"},
    {"city_id":"17375","city_name":"L'Union","state_id":"1232"},
    {"city_id":"17376","city_name":"Muret","state_id":"1232"},
    {"city_id":"17377","city_name":"Plaisance-du-Touch","state_id":"1232"},
    {"city_id":"17378","city_name":"Ramonville-Saint-Agne","state_id":"1232"},
    {"city_id":"17379","city_name":"Saint-Gaudens","state_id":"1232"},
    {"city_id":"17380","city_name":"Saint-Orens-de-Gameville","state_id":"1232"},
    {"city_id":"17381","city_name":"Toulouse","state_id":"1232"},
    {"city_id":"17382","city_name":"Tournefeuille","state_id":"1232"},
    {"city_id":"17383","city_name":"Blanzac","state_id":"1233"},
    {"city_id":"17384","city_name":"Le Puy-en-Velay","state_id":"1233"},
    {"city_id":"17385","city_name":"Chaumont","state_id":"1234"},
    {"city_id":"17386","city_name":"Langres","state_id":"1234"},
    {"city_id":"17387","city_name":"Saint-Dizier","state_id":"1234"},
    {"city_id":"17388","city_name":"Hericourt","state_id":"1235"},
    {"city_id":"17389","city_name":"Lure","state_id":"1235"},
    {"city_id":"17390","city_name":"Luxeuil-les-Bains","state_id":"1235"},
    {"city_id":"17391","city_name":"Vesoul","state_id":"1235"},
    {"city_id":"17392","city_name":"Annecy","state_id":"1236"},
    {"city_id":"17393","city_name":"Annecy-le-Vieux","state_id":"1236"},
    {"city_id":"17394","city_name":"Annemasse","state_id":"1236"},
    {"city_id":"17395","city_name":"Archamps","state_id":"1236"},
    {"city_id":"17396","city_name":"Bonneville","state_id":"1236"},
    {"city_id":"17397","city_name":"Chamonix-Mont-Blanc","state_id":"1236"},
    {"city_id":"17398","city_name":"Cluses","state_id":"1236"},
    {"city_id":"17399","city_name":"Contamine sur Arve","state_id":"1236"},
    {"city_id":"17400","city_name":"Cran-Gevrier","state_id":"1236"},
    {"city_id":"17401","city_name":"Passy","state_id":"1236"},
    {"city_id":"17402","city_name":"Rumilly","state_id":"1236"},
    {"city_id":"17403","city_name":"Sallanches","state_id":"1236"},
    {"city_id":"17404","city_name":"Seynod","state_id":"1236"},
    {"city_id":"17405","city_name":"Thonon-les-Bains","state_id":"1236"},
    {"city_id":"17406","city_name":"Gaillard","state_id":"1237"},
    {"city_id":"17407","city_name":"Limoges","state_id":"1237"},
    {"city_id":"17408","city_name":"Saint-Junien","state_id":"1237"},
    {"city_id":"17409","city_name":"Briancon","state_id":"1238"},
    {"city_id":"17410","city_name":"Gap","state_id":"1238"},
    {"city_id":"17411","city_name":"Lourdes","state_id":"1239"},
    {"city_id":"17412","city_name":"Tarbes","state_id":"1239"},
    {"city_id":"17413","city_name":"Antony","state_id":"1240"},
    {"city_id":"17414","city_name":"Asnieres-sur-Seine","state_id":"1240"},
    {"city_id":"17415","city_name":"Bagneux","state_id":"1240"},
    {"city_id":"17416","city_name":"Bois-Colombes","state_id":"1240"},
    {"city_id":"17417","city_name":"Boulogne-Billancourt","state_id":"1240"},
    {"city_id":"17418","city_name":"Bourg-la-Reine","state_id":"1240"},
    {"city_id":"17419","city_name":"Chatenay-Malabry","state_id":"1240"},
    {"city_id":"17420","city_name":"Chatillon","state_id":"1240"},
    {"city_id":"17421","city_name":"Chaville","state_id":"1240"},
    {"city_id":"17422","city_name":"Clamart","state_id":"1240"},
    {"city_id":"17423","city_name":"Clichy","state_id":"1240"},
    {"city_id":"17424","city_name":"Colombes","state_id":"1240"},
    {"city_id":"17425","city_name":"Courbevoie","state_id":"1240"},
    {"city_id":"17426","city_name":"Fontenay-aux-Roses","state_id":"1240"},
    {"city_id":"17427","city_name":"Garches","state_id":"1240"},
    {"city_id":"17428","city_name":"Gennevillers","state_id":"1240"},
    {"city_id":"17429","city_name":"Issy-les-Moulineaux","state_id":"1240"},
    {"city_id":"17430","city_name":"La Garenne-Colombes","state_id":"1240"},
    {"city_id":"17431","city_name":"Le Plessis-Robinson","state_id":"1240"},
    {"city_id":"17432","city_name":"Levallois-Perret","state_id":"1240"},
    {"city_id":"17433","city_name":"Malakoff","state_id":"1240"},
    {"city_id":"17434","city_name":"Meudon","state_id":"1240"},
    {"city_id":"17435","city_name":"Montrouge","state_id":"1240"},
    {"city_id":"17436","city_name":"Nanterre","state_id":"1240"},
    {"city_id":"17437","city_name":"Neuilly-sur-Seine","state_id":"1240"},
    {"city_id":"17438","city_name":"Puteaux","state_id":"1240"},
    {"city_id":"17439","city_name":"Rueil-Malmaison","state_id":"1240"},
    {"city_id":"17440","city_name":"Saint-Cloud","state_id":"1240"},
    {"city_id":"17441","city_name":"Sceaux","state_id":"1240"},
    {"city_id":"17442","city_name":"Sevres","state_id":"1240"},
    {"city_id":"17443","city_name":"Suresnes","state_id":"1240"},
    {"city_id":"17444","city_name":"Vanves","state_id":"1240"},
    {"city_id":"17445","city_name":"Ville-d'Avray","state_id":"1240"},
    {"city_id":"17446","city_name":"Villeneuve-la-Garenne","state_id":"1240"},
    {"city_id":"17447","city_name":"Agde","state_id":"1241"},
    {"city_id":"17448","city_name":"Beziers","state_id":"1241"},
    {"city_id":"17449","city_name":"Castelnau-le-Lez","state_id":"1241"},
    {"city_id":"17450","city_name":"Frontignan","state_id":"1241"},
    {"city_id":"17451","city_name":"Lattes","state_id":"1241"},
    {"city_id":"17452","city_name":"Lunel","state_id":"1241"},
    {"city_id":"17453","city_name":"Mauguio","state_id":"1241"},
    {"city_id":"17454","city_name":"Montpellier","state_id":"1241"},
    {"city_id":"17455","city_name":"Sete","state_id":"1241"},
    {"city_id":"17456","city_name":"Champagne-sur-Oise","state_id":"1242"},
    {"city_id":"17457","city_name":"Croissy-Beaubourg","state_id":"1242"},
    {"city_id":"17458","city_name":"Gennevilliers","state_id":"1242"},
    {"city_id":"17459","city_name":"Le Mesnil-le-Roi","state_id":"1242"},
    {"city_id":"17460","city_name":"Le Plessis-Bouchard","state_id":"1242"},
    {"city_id":"17461","city_name":"Rebais","state_id":"1242"},
    {"city_id":"17462","city_name":"Saint-Thibault-des-Vignes","state_id":"1242"},
    {"city_id":"17463","city_name":"Cesson-Sevigne","state_id":"1243"},
    {"city_id":"17464","city_name":"Dinard","state_id":"1243"},
    {"city_id":"17465","city_name":"Fougeres","state_id":"1243"},
    {"city_id":"17466","city_name":"Rennes","state_id":"1243"},
    {"city_id":"17467","city_name":"Saint-Malo","state_id":"1243"},
    {"city_id":"17468","city_name":"Vitre","state_id":"1243"},
    {"city_id":"17469","city_name":"Chateauroux","state_id":"1244"},
    {"city_id":"17470","city_name":"Issoudun","state_id":"1244"},
    {"city_id":"17471","city_name":"Amboise","state_id":"1245"},
    {"city_id":"17472","city_name":"Joue-les-Tours","state_id":"1245"},
    {"city_id":"17473","city_name":"Saint-Avertin","state_id":"1245"},
    {"city_id":"17474","city_name":"Saint-Cyr-sur-Loire","state_id":"1245"},
    {"city_id":"17475","city_name":"Saint-Pierre-des-Corps","state_id":"1245"},
    {"city_id":"17476","city_name":"Tours","state_id":"1245"},
    {"city_id":"17477","city_name":"Bourgoin-Jallieu","state_id":"1246"},
    {"city_id":"17478","city_name":"Crolles","state_id":"1246"},
    {"city_id":"17479","city_name":"Echirolles","state_id":"1246"},
    {"city_id":"17480","city_name":"Fontaine","state_id":"1246"},
    {"city_id":"17481","city_name":"Grenoble","state_id":"1246"},
    {"city_id":"17482","city_name":"Le Pont-de-Claix","state_id":"1246"},
    {"city_id":"17483","city_name":"Meylan","state_id":"1246"},
    {"city_id":"17484","city_name":"Saint-Egreve","state_id":"1246"},
    {"city_id":"17485","city_name":"Saint-Martin-d'Heres","state_id":"1246"},
    {"city_id":"17486","city_name":"Seyssinet-Pariset","state_id":"1246"},
    {"city_id":"17487","city_name":"Vienne","state_id":"1246"},
    {"city_id":"17488","city_name":"Villefontaine","state_id":"1246"},
    {"city_id":"17489","city_name":"Voiron","state_id":"1246"},
    {"city_id":"17490","city_name":"Champagnole","state_id":"1247"},
    {"city_id":"17491","city_name":"Dole","state_id":"1247"},
    {"city_id":"17492","city_name":"Lons-le-Saunier","state_id":"1247"},
    {"city_id":"17493","city_name":"Saint-Claude","state_id":"1247"},
    {"city_id":"17494","city_name":"Sebastiangasse","state_id":"1248"},
    {"city_id":"17495","city_name":"Biscarrosse","state_id":"1249"},
    {"city_id":"17496","city_name":"Dax","state_id":"1249"},
    {"city_id":"17497","city_name":"Hagetmau","state_id":"1249"},
    {"city_id":"17498","city_name":"Landes","state_id":"1249"},
    {"city_id":"17499","city_name":"Mont-de-Marsan","state_id":"1249"},
    {"city_id":"17500","city_name":"Saint-Paul-les-Dax","state_id":"1249"},
    {"city_id":"17501","city_name":"Tarnos","state_id":"1249"},
    {"city_id":"17502","city_name":"Codolet","state_id":"1250"},
    {"city_id":"17503","city_name":"Cuxac-d'Aude","state_id":"1250"},
    {"city_id":"17504","city_name":"Gigean","state_id":"1250"},
    {"city_id":"17505","city_name":"Grabels","state_id":"1250"},
    {"city_id":"17506","city_name":"Lamalou-les-Bains","state_id":"1250"},
    {"city_id":"17507","city_name":"Perols","state_id":"1250"},
    {"city_id":"17508","city_name":"Peyrens","state_id":"1250"},
    {"city_id":"17509","city_name":"Tuchan","state_id":"1250"},
    {"city_id":"17510","city_name":"Larcay","state_id":"1251"},
    {"city_id":"17511","city_name":"Voutezac","state_id":"1254"},
    {"city_id":"17512","city_name":"Blois","state_id":"1255"},
    {"city_id":"17513","city_name":"Romorantin-Lanthenay","state_id":"1255"},
    {"city_id":"17514","city_name":"Vendome","state_id":"1255"},
    {"city_id":"17515","city_name":"Andrezieux-Boutheon","state_id":"1256"},
    {"city_id":"17516","city_name":"Firminy","state_id":"1256"},
    {"city_id":"17517","city_name":"La Ricamarie","state_id":"1256"},
    {"city_id":"17518","city_name":"Le Chambon-Feugerolles","state_id":"1256"},
    {"city_id":"17519","city_name":"Montbrison","state_id":"1256"},
    {"city_id":"17520","city_name":"Riorges","state_id":"1256"},
    {"city_id":"17521","city_name":"Rive-de-Gier","state_id":"1256"},
    {"city_id":"17522","city_name":"Roanne","state_id":"1256"},
    {"city_id":"17523","city_name":"Roche-la-Moliere","state_id":"1256"},
    {"city_id":"17524","city_name":"Saint-Chamond","state_id":"1256"},
    {"city_id":"17525","city_name":"Saint-Etienne","state_id":"1256"},
    {"city_id":"17526","city_name":"Saint-Just-Saint-Rambert","state_id":"1256"},
    {"city_id":"17527","city_name":"Bouguenais","state_id":"1257"},
    {"city_id":"17528","city_name":"Carquefou","state_id":"1257"},
    {"city_id":"17529","city_name":"Chateaubriant","state_id":"1257"},
    {"city_id":"17530","city_name":"Coueron","state_id":"1257"},
    {"city_id":"17531","city_name":"Guerande","state_id":"1257"},
    {"city_id":"17532","city_name":"La Baule-Escoublac","state_id":"1257"},
    {"city_id":"17533","city_name":"La Chapelle-sur-Erdre","state_id":"1257"},
    {"city_id":"17534","city_name":"Nantes","state_id":"1257"},
    {"city_id":"17535","city_name":"Orvault","state_id":"1257"},
    {"city_id":"17536","city_name":"Reze","state_id":"1257"},
    {"city_id":"17537","city_name":"Saint Etienne de Mer Morte","state_id":"1257"},
    {"city_id":"17538","city_name":"Saint-Herblain","state_id":"1257"},
    {"city_id":"17539","city_name":"Saint-Nazaire","state_id":"1257"},
    {"city_id":"17540","city_name":"Saint-Sebastien-sur-Loire","state_id":"1257"},
    {"city_id":"17541","city_name":"Sainte-Luce-sur-Loire","state_id":"1257"},
    {"city_id":"17542","city_name":"Vertou","state_id":"1257"},
    {"city_id":"17543","city_name":"Amilly","state_id":"1258"},
    {"city_id":"17544","city_name":"Chalette-sur-Loing","state_id":"1258"},
    {"city_id":"17545","city_name":"Fleury-les-Aubrais","state_id":"1258"},
    {"city_id":"17546","city_name":"Gien","state_id":"1258"},
    {"city_id":"17547","city_name":"Montargis","state_id":"1258"},
    {"city_id":"17548","city_name":"Olivet","state_id":"1258"},
    {"city_id":"17549","city_name":"Orleans","state_id":"1258"},
    {"city_id":"17550","city_name":"Pithiviers","state_id":"1258"},
    {"city_id":"17551","city_name":"Saint-Jean-de-Braye","state_id":"1258"},
    {"city_id":"17552","city_name":"Saint-Jean-de-la-Ruelle","state_id":"1258"},
    {"city_id":"17553","city_name":"Saran","state_id":"1258"},
    {"city_id":"17554","city_name":"Montbronn","state_id":"1259"},
    {"city_id":"17555","city_name":"Cahors","state_id":"1260"},
    {"city_id":"17556","city_name":"Figeac","state_id":"1260"},
    {"city_id":"17557","city_name":"Agen","state_id":"1261"},
    {"city_id":"17558","city_name":"Le Passage","state_id":"1261"},
    {"city_id":"17559","city_name":"Marmande","state_id":"1261"},
    {"city_id":"17560","city_name":"Tonneins","state_id":"1261"},
    {"city_id":"17561","city_name":"Villeneuve-sur-Lot","state_id":"1261"},
    {"city_id":"17562","city_name":"Mende","state_id":"1263"},
    {"city_id":"17563","city_name":"Angers","state_id":"1264"},
    {"city_id":"17564","city_name":"Avrille","state_id":"1264"},
    {"city_id":"17565","city_name":"Cholet","state_id":"1264"},
    {"city_id":"17566","city_name":"Les Ponts-de-Ce","state_id":"1264"},
    {"city_id":"17567","city_name":"Saint-Barthelemy-d'Anjou","state_id":"1264"},
    {"city_id":"17568","city_name":"Saumur","state_id":"1264"},
    {"city_id":"17569","city_name":"Torfou","state_id":"1264"},
    {"city_id":"17570","city_name":"Trelaze","state_id":"1264"},
    {"city_id":"17571","city_name":"Cherbourg","state_id":"1265"},
    {"city_id":"17572","city_name":"Coutances","state_id":"1265"},
    {"city_id":"17573","city_name":"Equeurdreville-Hainneville","state_id":"1265"},
    {"city_id":"17574","city_name":"Granville","state_id":"1265"},
    {"city_id":"17575","city_name":"Octeville","state_id":"1265"},
    {"city_id":"17576","city_name":"Saint-Lo","state_id":"1265"},
    {"city_id":"17577","city_name":"Tourlaville","state_id":"1265"},
    {"city_id":"17578","city_name":"Chalons-en-Champagne","state_id":"1266"},
    {"city_id":"17579","city_name":"Epernay","state_id":"1266"},
    {"city_id":"17580","city_name":"Reims","state_id":"1266"},
    {"city_id":"17581","city_name":"Tinqueux","state_id":"1266"},
    {"city_id":"17582","city_name":"Vitry-le-Francois","state_id":"1266"},
    {"city_id":"17583","city_name":"Chateau-Gontier","state_id":"1267"},
    {"city_id":"17584","city_name":"Laval","state_id":"1267"},
    {"city_id":"17585","city_name":"Mayenne","state_id":"1267"},
    {"city_id":"17586","city_name":"Montsurs","state_id":"1267"},
    {"city_id":"17587","city_name":"Dombasle-sur-Meurthe","state_id":"1268"},
    {"city_id":"17588","city_name":"Jarville-la-Malgrange","state_id":"1268"},
    {"city_id":"17589","city_name":"Laxou","state_id":"1268"},
    {"city_id":"17590","city_name":"Longwy","state_id":"1268"},
    {"city_id":"17591","city_name":"Luneville","state_id":"1268"},
    {"city_id":"17592","city_name":"Nancy","state_id":"1268"},
    {"city_id":"17593","city_name":"Pont-a-Mousson","state_id":"1268"},
    {"city_id":"17594","city_name":"Saint-Max","state_id":"1268"},
    {"city_id":"17595","city_name":"Toul","state_id":"1268"},
    {"city_id":"17596","city_name":"Vandoeuvre-les-Nancy","state_id":"1268"},
    {"city_id":"17597","city_name":"Villers-les-Nancy","state_id":"1268"},
    {"city_id":"17598","city_name":"Villerupt","state_id":"1268"},
    {"city_id":"17599","city_name":"Bar-le-Duc","state_id":"1269"},
    {"city_id":"17600","city_name":"Verdun","state_id":"1269"},
    {"city_id":"17601","city_name":"Boissezon","state_id":"1270"},
    {"city_id":"17602","city_name":"Hauterive","state_id":"1270"},
    {"city_id":"17603","city_name":"Launaguet","state_id":"1270"},
    {"city_id":"17604","city_name":"Mauleon","state_id":"1270"},
    {"city_id":"17605","city_name":"Maurens-Scopont","state_id":"1270"},
    {"city_id":"17606","city_name":"Auray","state_id":"1271"},
    {"city_id":"17607","city_name":"Guidel","state_id":"1271"},
    {"city_id":"17608","city_name":"Hennebont","state_id":"1271"},
    {"city_id":"17609","city_name":"Lanester","state_id":"1271"},
    {"city_id":"17610","city_name":"Lorient","state_id":"1271"},
    {"city_id":"17611","city_name":"Ploemeur","state_id":"1271"},
    {"city_id":"17612","city_name":"Pontivy","state_id":"1271"},
    {"city_id":"17613","city_name":"Vannes","state_id":"1271"},
    {"city_id":"17614","city_name":"Amneville","state_id":"1272"},
    {"city_id":"17615","city_name":"Behren-les-Forbach","state_id":"1272"},
    {"city_id":"17616","city_name":"Creutzwald","state_id":"1272"},
    {"city_id":"17617","city_name":"Fameck","state_id":"1272"},
    {"city_id":"17618","city_name":"Florange","state_id":"1272"},
    {"city_id":"17619","city_name":"Forbach","state_id":"1272"},
    {"city_id":"17620","city_name":"Freyming-Merlebach","state_id":"1272"},
    {"city_id":"17621","city_name":"Hagondange","state_id":"1272"},
    {"city_id":"17622","city_name":"Hayange","state_id":"1272"},
    {"city_id":"17623","city_name":"Hombourg-Haut","state_id":"1272"},
    {"city_id":"17624","city_name":"Maizieres-les-Metz","state_id":"1272"},
    {"city_id":"17625","city_name":"Marly","state_id":"1272"},
    {"city_id":"17626","city_name":"Metz","state_id":"1272"},
    {"city_id":"17627","city_name":"Montigny-les-Metz","state_id":"1272"},
    {"city_id":"17628","city_name":"Moyeuvre-Grande","state_id":"1272"},
    {"city_id":"17629","city_name":"Rombas","state_id":"1272"},
    {"city_id":"17630","city_name":"Saint-Avold","state_id":"1272"},
    {"city_id":"17631","city_name":"Sarrebourg","state_id":"1272"},
    {"city_id":"17632","city_name":"Sarreguemines","state_id":"1272"},
    {"city_id":"17633","city_name":"Stiring-Wendel","state_id":"1272"},
    {"city_id":"17634","city_name":"Thionville","state_id":"1272"},
    {"city_id":"17635","city_name":"Uckange","state_id":"1272"},
    {"city_id":"17636","city_name":"Woippy","state_id":"1272"},
    {"city_id":"17637","city_name":"Yutz","state_id":"1272"},
    {"city_id":"17638","city_name":"Cosne-Cours-sur-Loire","state_id":"1273"},
    {"city_id":"17639","city_name":"Nevers","state_id":"1273"},
    {"city_id":"17640","city_name":"Varennes-Vauzelles","state_id":"1273"},
    {"city_id":"17641","city_name":"Aniche","state_id":"1274"},
    {"city_id":"17642","city_name":"Annoeullin","state_id":"1274"},
    {"city_id":"17643","city_name":"Anzin","state_id":"1274"},
    {"city_id":"17644","city_name":"Armentieres","state_id":"1274"},
    {"city_id":"17645","city_name":"Aulnoye-Aymeries","state_id":"1274"},
    {"city_id":"17646","city_name":"Bailleul","state_id":"1274"},
    {"city_id":"17647","city_name":"Bondues","state_id":"1274"},
    {"city_id":"17648","city_name":"Bruay-sur-l'Escaut","state_id":"1274"},
    {"city_id":"17649","city_name":"Cambrai","state_id":"1274"},
    {"city_id":"17650","city_name":"Cappelle-la-Grande","state_id":"1274"},
    {"city_id":"17651","city_name":"Caudry","state_id":"1274"},
    {"city_id":"17652","city_name":"Comines","state_id":"1274"},
    {"city_id":"17653","city_name":"Conde-sur-l'Escaut","state_id":"1274"},
    {"city_id":"17654","city_name":"Coudekerque-Branche","state_id":"1274"},
    {"city_id":"17655","city_name":"Croix","state_id":"1274"},
    {"city_id":"17656","city_name":"Denain","state_id":"1274"},
    {"city_id":"17657","city_name":"Douai","state_id":"1274"},
    {"city_id":"17658","city_name":"Douchy-les-Mines","state_id":"1274"},
    {"city_id":"17659","city_name":"Dunkerque","state_id":"1274"},
    {"city_id":"17660","city_name":"Escaudain","state_id":"1274"},
    {"city_id":"17661","city_name":"Fache-Thumesnil","state_id":"1274"},
    {"city_id":"17662","city_name":"Fourmies","state_id":"1274"},
    {"city_id":"17663","city_name":"Grande-Synthe","state_id":"1274"},
    {"city_id":"17664","city_name":"Graveline","state_id":"1274"},
    {"city_id":"17665","city_name":"Halluin","state_id":"1274"},
    {"city_id":"17666","city_name":"Haubourdin","state_id":"1274"},
    {"city_id":"17667","city_name":"Hautmont","state_id":"1274"},
    {"city_id":"17668","city_name":"Hazebrouck","state_id":"1274"},
    {"city_id":"17669","city_name":"Hem","state_id":"1274"},
    {"city_id":"17670","city_name":"Hulluch","state_id":"1274"},
    {"city_id":"17671","city_name":"Jeumont","state_id":"1274"},
    {"city_id":"17672","city_name":"La Madeleine","state_id":"1274"},
    {"city_id":"17673","city_name":"Lambersart","state_id":"1274"},
    {"city_id":"17674","city_name":"Leers","state_id":"1274"},
    {"city_id":"17675","city_name":"Lille","state_id":"1274"},
    {"city_id":"17676","city_name":"Lomme","state_id":"1274"},
    {"city_id":"17677","city_name":"Loos","state_id":"1274"},
    {"city_id":"17678","city_name":"Lys-lez-Lannoy","state_id":"1274"},
    {"city_id":"17679","city_name":"Marcq-en-Baroeul","state_id":"1274"},
    {"city_id":"17680","city_name":"Marennes","state_id":"1274"},
    {"city_id":"17681","city_name":"Marly","state_id":"1274"},
    {"city_id":"17682","city_name":"Marquette-lez-Lille","state_id":"1274"},
    {"city_id":"17683","city_name":"Maubeuge","state_id":"1274"},
    {"city_id":"17684","city_name":"Merville","state_id":"1274"},
    {"city_id":"17685","city_name":"Mons-en-Baroeul","state_id":"1274"},
    {"city_id":"17686","city_name":"Mouvaux","state_id":"1274"},
    {"city_id":"17687","city_name":"Neuville-en-Ferrain","state_id":"1274"},
    {"city_id":"17688","city_name":"Onnaing","state_id":"1274"},
    {"city_id":"17689","city_name":"Raismes","state_id":"1274"},
    {"city_id":"17690","city_name":"Ronchin","state_id":"1274"},
    {"city_id":"17691","city_name":"Roncq","state_id":"1274"},
    {"city_id":"17692","city_name":"Roubaix","state_id":"1274"},
    {"city_id":"17693","city_name":"Saint-Amand-les-Eaux","state_id":"1274"},
    {"city_id":"17694","city_name":"Saint-Andre-lez-Lille","state_id":"1274"},
    {"city_id":"17695","city_name":"Saint-Pol-sur-Mer","state_id":"1274"},
    {"city_id":"17696","city_name":"Saint-Saulve","state_id":"1274"},
    {"city_id":"17697","city_name":"Seclin","state_id":"1274"},
    {"city_id":"17698","city_name":"Sin-le-Noble","state_id":"1274"},
    {"city_id":"17699","city_name":"Somain","state_id":"1274"},
    {"city_id":"17700","city_name":"Tourcoing","state_id":"1274"},
    {"city_id":"17701","city_name":"Valenciennes","state_id":"1274"},
    {"city_id":"17702","city_name":"Vieux-Conde","state_id":"1274"},
    {"city_id":"17703","city_name":"Villeneuve-d'Ascq","state_id":"1274"},
    {"city_id":"17704","city_name":"Wasquehal","state_id":"1274"},
    {"city_id":"17705","city_name":"Wattignies","state_id":"1274"},
    {"city_id":"17706","city_name":"Wattrelos","state_id":"1274"},
    {"city_id":"17707","city_name":"Waziers","state_id":"1274"},
    {"city_id":"17708","city_name":"Esquelbecq","state_id":"1275"},
    {"city_id":"17709","city_name":"Beauvais","state_id":"1276"},
    {"city_id":"17710","city_name":"Chantilly","state_id":"1276"},
    {"city_id":"17711","city_name":"Clermont","state_id":"1276"},
    {"city_id":"17712","city_name":"Compiegne","state_id":"1276"},
    {"city_id":"17713","city_name":"Creil","state_id":"1276"},
    {"city_id":"17714","city_name":"Crepy-en-Valois","state_id":"1276"},
    {"city_id":"17715","city_name":"Gouvieux","state_id":"1276"},
    {"city_id":"17716","city_name":"Meru","state_id":"1276"},
    {"city_id":"17717","city_name":"Montataire","state_id":"1276"},
    {"city_id":"17718","city_name":"Nogent-sur-Oise","state_id":"1276"},
    {"city_id":"17719","city_name":"Noyon","state_id":"1276"},
    {"city_id":"17720","city_name":"Pont-Sainte-Maxence","state_id":"1276"},
    {"city_id":"17721","city_name":"Senlis","state_id":"1276"},
    {"city_id":"17722","city_name":"Alencon","state_id":"1277"},
    {"city_id":"17723","city_name":"Argentan","state_id":"1277"},
    {"city_id":"17724","city_name":"Flers","state_id":"1277"},
    {"city_id":"17725","city_name":"L'Aigle","state_id":"1277"},
    {"city_id":"17726","city_name":"Paris","state_id":"1278"},
    {"city_id":"17727","city_name":"Aire-sur-la-Lys","state_id":"1279"},
    {"city_id":"17728","city_name":"Arras","state_id":"1279"},
    {"city_id":"17729","city_name":"Auchel","state_id":"1279"},
    {"city_id":"17730","city_name":"Avion","state_id":"1279"},
    {"city_id":"17731","city_name":"Berck","state_id":"1279"},
    {"city_id":"17732","city_name":"Bethune","state_id":"1279"},
    {"city_id":"17733","city_name":"Boulogne-sur-Mer","state_id":"1279"},
    {"city_id":"17734","city_name":"Bruay-la-Brussiere","state_id":"1279"},
    {"city_id":"17735","city_name":"Bully-les-Mines","state_id":"1279"},
    {"city_id":"17736","city_name":"Calais","state_id":"1279"},
    {"city_id":"17737","city_name":"Carvin","state_id":"1279"},
    {"city_id":"17738","city_name":"Courrieres","state_id":"1279"},
    {"city_id":"17739","city_name":"Etaples","state_id":"1279"},
    {"city_id":"17740","city_name":"Harnes","state_id":"1279"},
    {"city_id":"17741","city_name":"Henin-Beaumont","state_id":"1279"},
    {"city_id":"17742","city_name":"Le Portel","state_id":"1279"},
    {"city_id":"17743","city_name":"Lens","state_id":"1279"},
    {"city_id":"17744","city_name":"Libercourt","state_id":"1279"},
    {"city_id":"17745","city_name":"Lievin","state_id":"1279"},
    {"city_id":"17746","city_name":"Lillers","state_id":"1279"},
    {"city_id":"17747","city_name":"Longuenesse","state_id":"1279"},
    {"city_id":"17748","city_name":"Marck","state_id":"1279"},
    {"city_id":"17749","city_name":"Mericourt","state_id":"1279"},
    {"city_id":"17750","city_name":"Montigny-en-Gohelle","state_id":"1279"},
    {"city_id":"17751","city_name":"Noeux-les-Mines","state_id":"1279"},
    {"city_id":"17752","city_name":"Oignies","state_id":"1279"},
    {"city_id":"17753","city_name":"Outreau","state_id":"1279"},
    {"city_id":"17754","city_name":"Rouvroy","state_id":"1279"},
    {"city_id":"17755","city_name":"Saint-Martin-Boulogne","state_id":"1279"},
    {"city_id":"17756","city_name":"Saint-Omer","state_id":"1279"},
    {"city_id":"17757","city_name":"Sallaumines","state_id":"1279"},
    {"city_id":"17758","city_name":"Vendin-le-Vieil","state_id":"1279"},
    {"city_id":"17759","city_name":"Loiron","state_id":"1281"},
    {"city_id":"17760","city_name":"Marolles-les-Braults","state_id":"1281"},
    {"city_id":"17761","city_name":"Mortagne-sur-Sevre","state_id":"1281"},
    {"city_id":"17762","city_name":"Mouzillon","state_id":"1281"},
    {"city_id":"17763","city_name":"Noirmoutier-en-l'ÃƒÂŽle","state_id":"1281"},
    {"city_id":"17764","city_name":"Friville","state_id":"1282"},
    {"city_id":"17765","city_name":"Liancourt","state_id":"1282"},
    {"city_id":"17766","city_name":"Maizy","state_id":"1282"},
    {"city_id":"17767","city_name":"Oust-Marest","state_id":"1282"},
    {"city_id":"17768","city_name":"Puiseux-le-Hauberger","state_id":"1282"},
    {"city_id":"17769","city_name":"Saint-Crepin-Ibouvillers","state_id":"1282"},
    {"city_id":"17770","city_name":"Aubiere","state_id":"1283"},
    {"city_id":"17771","city_name":"Beaumont","state_id":"1283"},
    {"city_id":"17772","city_name":"Chamalieres","state_id":"1283"},
    {"city_id":"17773","city_name":"Clermont-Ferrand","state_id":"1283"},
    {"city_id":"17774","city_name":"Cournon-d'Auvergne","state_id":"1283"},
    {"city_id":"17775","city_name":"Gerzat","state_id":"1283"},
    {"city_id":"17776","city_name":"Issoire","state_id":"1283"},
    {"city_id":"17777","city_name":"Riom","state_id":"1283"},
    {"city_id":"17778","city_name":"Thiers","state_id":"1283"},
    {"city_id":"17779","city_name":"Anglet","state_id":"1284"},
    {"city_id":"17780","city_name":"Bayonne","state_id":"1284"},
    {"city_id":"17781","city_name":"Biarritz","state_id":"1284"},
    {"city_id":"17782","city_name":"Billere","state_id":"1284"},
    {"city_id":"17783","city_name":"Hendaye","state_id":"1284"},
    {"city_id":"17784","city_name":"Lons","state_id":"1284"},
    {"city_id":"17785","city_name":"Oloron-Sainte-Marie","state_id":"1284"},
    {"city_id":"17786","city_name":"Orthez","state_id":"1284"},
    {"city_id":"17787","city_name":"Pau","state_id":"1284"},
    {"city_id":"17788","city_name":"Saint-Jean-de-Luz","state_id":"1284"},
    {"city_id":"17789","city_name":"Perpignan","state_id":"1285"},
    {"city_id":"17790","city_name":"Saint-Esteve","state_id":"1285"},
    {"city_id":"17791","city_name":"Quelmes","state_id":"1286"},
    {"city_id":"17792","city_name":"Brignais","state_id":"1287"},
    {"city_id":"17793","city_name":"Bron","state_id":"1287"},
    {"city_id":"17794","city_name":"Caluire-et-Cuire","state_id":"1287"},
    {"city_id":"17795","city_name":"Decines-Charpieu","state_id":"1287"},
    {"city_id":"17796","city_name":"Ecully","state_id":"1287"},
    {"city_id":"17797","city_name":"Francheville","state_id":"1287"},
    {"city_id":"17798","city_name":"Genas","state_id":"1287"},
    {"city_id":"17799","city_name":"Genay","state_id":"1287"},
    {"city_id":"17800","city_name":"Givors","state_id":"1287"},
    {"city_id":"17801","city_name":"Lyon","state_id":"1287"},
    {"city_id":"17802","city_name":"Meyzieu","state_id":"1287"},
    {"city_id":"17803","city_name":"Mions","state_id":"1287"},
    {"city_id":"17804","city_name":"Oullins","state_id":"1287"},
    {"city_id":"17805","city_name":"Pierre-Benite","state_id":"1287"},
    {"city_id":"17806","city_name":"Rillieux-la-Pape","state_id":"1287"},
    {"city_id":"17807","city_name":"Saint-Fons","state_id":"1287"},
    {"city_id":"17808","city_name":"Saint-Genis-Laval","state_id":"1287"},
    {"city_id":"17809","city_name":"Saint-Priest","state_id":"1287"},
    {"city_id":"17810","city_name":"Sainte-Foy-les-Lyon","state_id":"1287"},
    {"city_id":"17811","city_name":"Tarare","state_id":"1287"},
    {"city_id":"17812","city_name":"Tassin-la-Demi-Lune","state_id":"1287"},
    {"city_id":"17813","city_name":"Vaulx-en-Velin","state_id":"1287"},
    {"city_id":"17814","city_name":"Venissieux","state_id":"1287"},
    {"city_id":"17815","city_name":"Villefranche-sur-Saone","state_id":"1287"},
    {"city_id":"17816","city_name":"Villeurbanne","state_id":"1287"},
    {"city_id":"17817","city_name":"Beauvoir-en-Royans","state_id":"1288"},
    {"city_id":"17818","city_name":"Belley","state_id":"1288"},
    {"city_id":"17819","city_name":"Bons-en-Chablais","state_id":"1288"},
    {"city_id":"17820","city_name":"Chalain-d'Uzore","state_id":"1288"},
    {"city_id":"17821","city_name":"Chassieu","state_id":"1288"},
    {"city_id":"17822","city_name":"Chavanod","state_id":"1288"},
    {"city_id":"17823","city_name":"Chazay-d-Azergues","state_id":"1288"},
    {"city_id":"17824","city_name":"Chimilin","state_id":"1288"},
    {"city_id":"17825","city_name":"Civrieux-d Azergues","state_id":"1288"},
    {"city_id":"17826","city_name":"Corbas","state_id":"1288"},
    {"city_id":"17827","city_name":"Courzieu","state_id":"1288"},
    {"city_id":"17828","city_name":"Dardilly","state_id":"1288"},
    {"city_id":"17829","city_name":"Guereins","state_id":"1288"},
    {"city_id":"17830","city_name":"Izernore","state_id":"1288"},
    {"city_id":"17831","city_name":"La Talaudiere","state_id":"1288"},
    {"city_id":"17832","city_name":"La Tronche","state_id":"1288"},
    {"city_id":"17833","city_name":"La Verpilliere","state_id":"1288"},
    {"city_id":"17834","city_name":"Le Cheylard","state_id":"1288"},
    {"city_id":"17835","city_name":"Le Cheylas","state_id":"1288"},
    {"city_id":"17836","city_name":"Mery","state_id":"1288"},
    {"city_id":"17837","city_name":"Moirans","state_id":"1288"},
    {"city_id":"17838","city_name":"Montalieu-Vercieu","state_id":"1288"},
    {"city_id":"17839","city_name":"Montmiral","state_id":"1288"},
    {"city_id":"17840","city_name":"Peronnas","state_id":"1288"},
    {"city_id":"17841","city_name":"Poncin","state_id":"1288"},
    {"city_id":"17842","city_name":"Quincie-en-Beaujolais","state_id":"1288"},
    {"city_id":"17843","city_name":"Saint-Quentin-sur-Isere","state_id":"1288"},
    {"city_id":"17844","city_name":"Sainte Agathe la Bouteresse","state_id":"1288"},
    {"city_id":"17845","city_name":"Sainte-Consorce","state_id":"1288"},
    {"city_id":"17846","city_name":"Sisteron","state_id":"1288"},
    {"city_id":"17847","city_name":"Trevoux","state_id":"1288"},
    {"city_id":"17848","city_name":"Villard-Bonnot","state_id":"1288"},
    {"city_id":"17849","city_name":"Autun","state_id":"1291"},
    {"city_id":"17850","city_name":"Chalon-sur-Saone","state_id":"1291"},
    {"city_id":"17851","city_name":"Digoin","state_id":"1291"},
    {"city_id":"17852","city_name":"Gueugnon","state_id":"1291"},
    {"city_id":"17853","city_name":"Le Creusot","state_id":"1291"},
    {"city_id":"17854","city_name":"Macon","state_id":"1291"},
    {"city_id":"17855","city_name":"Montceau-les-Mines","state_id":"1291"},
    {"city_id":"17856","city_name":"Paray-le-Monial","state_id":"1291"},
    {"city_id":"17857","city_name":"Saint-Vallier","state_id":"1291"},
    {"city_id":"17858","city_name":"Allonnes","state_id":"1292"},
    {"city_id":"17859","city_name":"La Ferte-Bernard","state_id":"1292"},
    {"city_id":"17860","city_name":"La Fleche","state_id":"1292"},
    {"city_id":"17861","city_name":"Le Mans","state_id":"1292"},
    {"city_id":"17862","city_name":"Sable-sur-Sarthe","state_id":"1292"},
    {"city_id":"17863","city_name":"Aix-les-Bains","state_id":"1293"},
    {"city_id":"17864","city_name":"Albertville","state_id":"1293"},
    {"city_id":"17865","city_name":"Chambery","state_id":"1293"},
    {"city_id":"17866","city_name":"La Motte-Servolex","state_id":"1293"},
    {"city_id":"17867","city_name":"Saint-Jean-de-Maurienne","state_id":"1293"},
    {"city_id":"17868","city_name":"Barentin","state_id":"1294"},
    {"city_id":"17869","city_name":"Bihorel","state_id":"1294"},
    {"city_id":"17870","city_name":"Bois-Guillaume","state_id":"1294"},
    {"city_id":"17871","city_name":"Bolbec","state_id":"1294"},
    {"city_id":"17872","city_name":"Canteleu","state_id":"1294"},
    {"city_id":"17873","city_name":"Caudebec-les-Elbeuf","state_id":"1294"},
    {"city_id":"17874","city_name":"Darnetal","state_id":"1294"},
    {"city_id":"17875","city_name":"Deville-les-Rouen","state_id":"1294"},
    {"city_id":"17876","city_name":"Dieppe","state_id":"1294"},
    {"city_id":"17877","city_name":"Elbeuf","state_id":"1294"},
    {"city_id":"17878","city_name":"Fecamp","state_id":"1294"},
    {"city_id":"17879","city_name":"Gonfreville-l'Orcher","state_id":"1294"},
    {"city_id":"17880","city_name":"Grand-Couronne","state_id":"1294"},
    {"city_id":"17881","city_name":"Harfleur","state_id":"1294"},
    {"city_id":"17882","city_name":"Le Grand-Quevilly","state_id":"1294"},
    {"city_id":"17883","city_name":"Le Havre","state_id":"1294"},
    {"city_id":"17884","city_name":"Le Petit-Quevilly","state_id":"1294"},
    {"city_id":"17885","city_name":"Lillebonne","state_id":"1294"},
    {"city_id":"17886","city_name":"Maromme","state_id":"1294"},
    {"city_id":"17887","city_name":"Mont-Saint-Aignan","state_id":"1294"},
    {"city_id":"17888","city_name":"Montivilliers","state_id":"1294"},
    {"city_id":"17889","city_name":"Notre-Dame-de-Gravenchon","state_id":"1294"},
    {"city_id":"17890","city_name":"Oissel","state_id":"1294"},
    {"city_id":"17891","city_name":"Rouen","state_id":"1294"},
    {"city_id":"17892","city_name":"Saint-Etienne-du-Rouvray","state_id":"1294"},
    {"city_id":"17893","city_name":"Sotteville-les-Rouen","state_id":"1294"},
    {"city_id":"17894","city_name":"Yvetot","state_id":"1294"},
    {"city_id":"17895","city_name":"Aubervillers","state_id":"1295"},
    {"city_id":"17896","city_name":"Aulnay-sous-Bois","state_id":"1295"},
    {"city_id":"17897","city_name":"Bagnolet","state_id":"1295"},
    {"city_id":"17898","city_name":"Bobigny","state_id":"1295"},
    {"city_id":"17899","city_name":"Bondy","state_id":"1295"},
    {"city_id":"17900","city_name":"Clichy-sous-Bois","state_id":"1295"},
    {"city_id":"17901","city_name":"Drancy","state_id":"1295"},
    {"city_id":"17902","city_name":"Epinay-sur-Seine","state_id":"1295"},
    {"city_id":"17903","city_name":"Gagny","state_id":"1295"},
    {"city_id":"17904","city_name":"La Courneuve","state_id":"1295"},
    {"city_id":"17905","city_name":"Le Blanc-Mesnil","state_id":"1295"},
    {"city_id":"17906","city_name":"Le Bourget","state_id":"1295"},
    {"city_id":"17907","city_name":"Le Pre-Saint-Gervais","state_id":"1295"},
    {"city_id":"17908","city_name":"Le Raincy","state_id":"1295"},
    {"city_id":"17909","city_name":"Les Lilas","state_id":"1295"},
    {"city_id":"17910","city_name":"Les Pavillons-sous-Bois","state_id":"1295"},
    {"city_id":"17911","city_name":"Livry-Gargan","state_id":"1295"},
    {"city_id":"17912","city_name":"Montfermeil","state_id":"1295"},
    {"city_id":"17913","city_name":"Montreuil","state_id":"1295"},
    {"city_id":"17914","city_name":"Neuilly-Plaisance","state_id":"1295"},
    {"city_id":"17915","city_name":"Neuilly-sur-Marne","state_id":"1295"},
    {"city_id":"17916","city_name":"Noisy-le-Grand","state_id":"1295"},
    {"city_id":"17917","city_name":"Noisy-le-Sec","state_id":"1295"},
    {"city_id":"17918","city_name":"Pantin","state_id":"1295"},
    {"city_id":"17919","city_name":"Pierrefitte-sur-Seine","state_id":"1295"},
    {"city_id":"17920","city_name":"Romainville","state_id":"1295"},
    {"city_id":"17921","city_name":"Rosny-sous-Bois","state_id":"1295"},
    {"city_id":"17922","city_name":"Saint-Denis","state_id":"1295"},
    {"city_id":"17923","city_name":"Saint-Ouen","state_id":"1295"},
    {"city_id":"17924","city_name":"Sevran","state_id":"1295"},
    {"city_id":"17925","city_name":"Stains","state_id":"1295"},
    {"city_id":"17926","city_name":"Tremblay-en-France","state_id":"1295"},
    {"city_id":"17927","city_name":"Villemomble","state_id":"1295"},
    {"city_id":"17928","city_name":"Villepinte","state_id":"1295"},
    {"city_id":"17929","city_name":"Villetaneuse","state_id":"1295"},
    {"city_id":"17930","city_name":"Avon","state_id":"1296"},
    {"city_id":"17931","city_name":"Brie-Comte-Robert","state_id":"1296"},
    {"city_id":"17932","city_name":"Champs-sur-Marne","state_id":"1296"},
    {"city_id":"17933","city_name":"Chelles","state_id":"1296"},
    {"city_id":"17934","city_name":"Claye-Souilly","state_id":"1296"},
    {"city_id":"17935","city_name":"Combs-la-Ville","state_id":"1296"},
    {"city_id":"17936","city_name":"Coulommiers","state_id":"1296"},
    {"city_id":"17937","city_name":"Dammarie-les-Lys","state_id":"1296"},
    {"city_id":"17938","city_name":"Fontainebleau","state_id":"1296"},
    {"city_id":"17939","city_name":"Lagny-sur-Marne","state_id":"1296"},
    {"city_id":"17940","city_name":"Le Mee-sur-Seine","state_id":"1296"},
    {"city_id":"17941","city_name":"Lognes","state_id":"1296"},
    {"city_id":"17942","city_name":"Meaux","state_id":"1296"},
    {"city_id":"17943","city_name":"Melun","state_id":"1296"},
    {"city_id":"17944","city_name":"Mitry-Mory","state_id":"1296"},
    {"city_id":"17945","city_name":"Moissy-Cramayel","state_id":"1296"},
    {"city_id":"17946","city_name":"Montereau-Fault-Yonne","state_id":"1296"},
    {"city_id":"17947","city_name":"Nemours","state_id":"1296"},
    {"city_id":"17948","city_name":"Noisiel","state_id":"1296"},
    {"city_id":"17949","city_name":"Ozoir-la-Ferriere","state_id":"1296"},
    {"city_id":"17950","city_name":"Pontault-Combault","state_id":"1296"},
    {"city_id":"17951","city_name":"Provins","state_id":"1296"},
    {"city_id":"17952","city_name":"Roissy-en-Brie","state_id":"1296"},
    {"city_id":"17953","city_name":"Saint-Fargeau-Ponthierry","state_id":"1296"},
    {"city_id":"17954","city_name":"Savigny-le-Temple","state_id":"1296"},
    {"city_id":"17955","city_name":"Torcy","state_id":"1296"},
    {"city_id":"17956","city_name":"Vaires-sur-Marne","state_id":"1296"},
    {"city_id":"17957","city_name":"Veneux","state_id":"1296"},
    {"city_id":"17958","city_name":"Villeparisis","state_id":"1296"},
    {"city_id":"17959","city_name":"Abbeville","state_id":"1297"},
    {"city_id":"17960","city_name":"Albert","state_id":"1297"},
    {"city_id":"17961","city_name":"Amiens","state_id":"1297"},
    {"city_id":"17962","city_name":"Souvans","state_id":"1299"},
    {"city_id":"17963","city_name":"Albi","state_id":"1300"},
    {"city_id":"17964","city_name":"Carmaux","state_id":"1300"},
    {"city_id":"17965","city_name":"Castres","state_id":"1300"},
    {"city_id":"17966","city_name":"Gaillac","state_id":"1300"},
    {"city_id":"17967","city_name":"Graulhet","state_id":"1300"},
    {"city_id":"17968","city_name":"Mazamet","state_id":"1300"},
    {"city_id":"17969","city_name":"Castelsarassin","state_id":"1301"},
    {"city_id":"17970","city_name":"Moissac","state_id":"1301"},
    {"city_id":"17971","city_name":"Montauban","state_id":"1301"},
    {"city_id":"17972","city_name":"Chamberet","state_id":"1303"},
    {"city_id":"17973","city_name":"Argenteuil","state_id":"1305"},
    {"city_id":"17974","city_name":"Arnouville-les-Gonesse","state_id":"1305"},
    {"city_id":"17975","city_name":"Beauchamps","state_id":"1305"},
    {"city_id":"17976","city_name":"Bezons","state_id":"1305"},
    {"city_id":"17977","city_name":"Cergy","state_id":"1305"},
    {"city_id":"17978","city_name":"Cormeilles-en-Parisis","state_id":"1305"},
    {"city_id":"17979","city_name":"Deuil-la-Barre","state_id":"1305"},
    {"city_id":"17980","city_name":"Domont","state_id":"1305"},
    {"city_id":"17981","city_name":"Eaubonne","state_id":"1305"},
    {"city_id":"17982","city_name":"Enghien-les-Bains","state_id":"1305"},
    {"city_id":"17983","city_name":"Eragny","state_id":"1305"},
    {"city_id":"17984","city_name":"Ermont","state_id":"1305"},
    {"city_id":"17985","city_name":"Ezanville","state_id":"1305"},
    {"city_id":"17986","city_name":"Fosses","state_id":"1305"},
    {"city_id":"17987","city_name":"Franconville","state_id":"1305"},
    {"city_id":"17988","city_name":"Garges-les-Gonesse","state_id":"1305"},
    {"city_id":"17989","city_name":"Gonesse","state_id":"1305"},
    {"city_id":"17990","city_name":"Goussainville","state_id":"1305"},
    {"city_id":"17991","city_name":"Herblay","state_id":"1305"},
    {"city_id":"17992","city_name":"Jouy-le-Moutier","state_id":"1305"},
    {"city_id":"17993","city_name":"L'Isle-Adam","state_id":"1305"},
    {"city_id":"17994","city_name":"Montigny-les-Cormeilles","state_id":"1305"},
    {"city_id":"17995","city_name":"Montmagny","state_id":"1305"},
    {"city_id":"17996","city_name":"Montmorency","state_id":"1305"},
    {"city_id":"17997","city_name":"Osny","state_id":"1305"},
    {"city_id":"17998","city_name":"Persan","state_id":"1305"},
    {"city_id":"17999","city_name":"Pontoise","state_id":"1305"},
    {"city_id":"18000","city_name":"Saint-Brice-sous-Foret","state_id":"1305"},
    {"city_id":"18001","city_name":"Saint-Gratien","state_id":"1305"},
    {"city_id":"18002","city_name":"Saint-Leu-la-Foret","state_id":"1305"},
    {"city_id":"18003","city_name":"Saint-Ouen-l'Aumone","state_id":"1305"},
    {"city_id":"18004","city_name":"Sannois","state_id":"1305"},
    {"city_id":"18005","city_name":"Sarcelles","state_id":"1305"},
    {"city_id":"18006","city_name":"Soisy-sous-Montmorency","state_id":"1305"},
    {"city_id":"18007","city_name":"Taverny","state_id":"1305"},
    {"city_id":"18008","city_name":"Vaureal","state_id":"1305"},
    {"city_id":"18009","city_name":"Villiers-le-Bel","state_id":"1305"},
    {"city_id":"18010","city_name":"Alfortville","state_id":"1306"},
    {"city_id":"18011","city_name":"Arcueil","state_id":"1306"},
    {"city_id":"18012","city_name":"Boissy-Saint-Leger","state_id":"1306"},
    {"city_id":"18013","city_name":"Bonneuil","state_id":"1306"},
    {"city_id":"18014","city_name":"Bry-sur-Marne","state_id":"1306"},
    {"city_id":"18015","city_name":"Cachan","state_id":"1306"},
    {"city_id":"18016","city_name":"Champigny-sur-Marne","state_id":"1306"},
    {"city_id":"18017","city_name":"Charenton-le-Pont","state_id":"1306"},
    {"city_id":"18018","city_name":"Chennevieres-sur-Marne","state_id":"1306"},
    {"city_id":"18019","city_name":"Chevilly-Larue","state_id":"1306"},
    {"city_id":"18020","city_name":"Choisy-le-Roi","state_id":"1306"},
    {"city_id":"18021","city_name":"Creteil","state_id":"1306"},
    {"city_id":"18022","city_name":"Fontenay-sous-Bois","state_id":"1306"},
    {"city_id":"18023","city_name":"Fresnes","state_id":"1306"},
    {"city_id":"18024","city_name":"Gentilly","state_id":"1306"},
    {"city_id":"18025","city_name":"Ivry-sur-Seine","state_id":"1306"},
    {"city_id":"18026","city_name":"Joinville-le-Pont","state_id":"1306"},
    {"city_id":"18027","city_name":"L'Hay-les-Roses","state_id":"1306"},
    {"city_id":"18028","city_name":"La Queue-en-Brie","state_id":"1306"},
    {"city_id":"18029","city_name":"Le Kremlin-Bicetre","state_id":"1306"},
    {"city_id":"18030","city_name":"Le Perreux-sur-Marne","state_id":"1306"},
    {"city_id":"18031","city_name":"Le Plessis-Trevise","state_id":"1306"},
    {"city_id":"18032","city_name":"Limeil-Brevannes","state_id":"1306"},
    {"city_id":"18033","city_name":"Maisons-Alfort","state_id":"1306"},
    {"city_id":"18034","city_name":"Nogent-sur-Marne","state_id":"1306"},
    {"city_id":"18035","city_name":"Orly","state_id":"1306"},
    {"city_id":"18036","city_name":"Ormesson-sur-Marne","state_id":"1306"},
    {"city_id":"18037","city_name":"Saint-Mande","state_id":"1306"},
    {"city_id":"18038","city_name":"Saint-Maur-des-Fosses","state_id":"1306"},
    {"city_id":"18039","city_name":"Saint-Maurice","state_id":"1306"},
    {"city_id":"18040","city_name":"Sucy-en-Brie","state_id":"1306"},
    {"city_id":"18041","city_name":"Thiais","state_id":"1306"},
    {"city_id":"18042","city_name":"Valenton","state_id":"1306"},
    {"city_id":"18043","city_name":"Villejuif","state_id":"1306"},
    {"city_id":"18044","city_name":"Villeneuve-Saint-Georges","state_id":"1306"},
    {"city_id":"18045","city_name":"Villeneuve-le-Roi","state_id":"1306"},
    {"city_id":"18046","city_name":"Villiers-sur-Marne","state_id":"1306"},
    {"city_id":"18047","city_name":"Vincennes","state_id":"1306"},
    {"city_id":"18048","city_name":"Vitry-sur-Seine","state_id":"1306"},
    {"city_id":"18049","city_name":"Brignoles","state_id":"1307"},
    {"city_id":"18050","city_name":"Draguignan","state_id":"1307"},
    {"city_id":"18051","city_name":"Frejus","state_id":"1307"},
    {"city_id":"18052","city_name":"Hyeres","state_id":"1307"},
    {"city_id":"18053","city_name":"La Crau","state_id":"1307"},
    {"city_id":"18054","city_name":"La Garde","state_id":"1307"},
    {"city_id":"18055","city_name":"La Seyne-sur-Mer","state_id":"1307"},
    {"city_id":"18056","city_name":"La Valette-du-Var","state_id":"1307"},
    {"city_id":"18057","city_name":"Le Pradet","state_id":"1307"},
    {"city_id":"18058","city_name":"Ollioules","state_id":"1307"},
    {"city_id":"18059","city_name":"Roquebrune-sur-Argens","state_id":"1307"},
    {"city_id":"18060","city_name":"Saint-Maximin-la-Sainte-Baume","state_id":"1307"},
    {"city_id":"18061","city_name":"Saint-Raphael","state_id":"1307"},
    {"city_id":"18062","city_name":"Sainte-Maxime","state_id":"1307"},
    {"city_id":"18063","city_name":"Sanary-sur-Mer","state_id":"1307"},
    {"city_id":"18064","city_name":"Six-Fours-les-Plages","state_id":"1307"},
    {"city_id":"18065","city_name":"Sollies-Pont","state_id":"1307"},
    {"city_id":"18066","city_name":"Toulon","state_id":"1307"},
    {"city_id":"18067","city_name":"Apt","state_id":"1308"},
    {"city_id":"18068","city_name":"Avignon","state_id":"1308"},
    {"city_id":"18069","city_name":"Bollene","state_id":"1308"},
    {"city_id":"18070","city_name":"Carpentras","state_id":"1308"},
    {"city_id":"18071","city_name":"Cavaillon","state_id":"1308"},
    {"city_id":"18072","city_name":"L'Isle-sur-la-Sorgue","state_id":"1308"},
    {"city_id":"18073","city_name":"Le Pontet","state_id":"1308"},
    {"city_id":"18074","city_name":"Orange","state_id":"1308"},
    {"city_id":"18075","city_name":"Pertuis","state_id":"1308"},
    {"city_id":"18076","city_name":"Sorgues","state_id":"1308"},
    {"city_id":"18077","city_name":"Valreas","state_id":"1308"},
    {"city_id":"18078","city_name":"Vellise","state_id":"1309"},
    {"city_id":"18079","city_name":"Challans","state_id":"1310"},
    {"city_id":"18080","city_name":"Chateau-d'Olonne","state_id":"1310"},
    {"city_id":"18081","city_name":"Fontenay-le-Comte","state_id":"1310"},
    {"city_id":"18082","city_name":"La Chasnis","state_id":"1310"},
    {"city_id":"18083","city_name":"La Roche-sur-Yon","state_id":"1310"},
    {"city_id":"18084","city_name":"Les Herbiers","state_id":"1310"},
    {"city_id":"18085","city_name":"Les Sables-d'Olonne","state_id":"1310"},
    {"city_id":"18086","city_name":"Longeville-sur-Mer","state_id":"1310"},
    {"city_id":"18087","city_name":"Chatellerault","state_id":"1311"},
    {"city_id":"18088","city_name":"Poitiers","state_id":"1311"},
    {"city_id":"18089","city_name":"Epinal","state_id":"1312"},
    {"city_id":"18090","city_name":"Gerardmer","state_id":"1312"},
    {"city_id":"18091","city_name":"Remiremont","state_id":"1312"},
    {"city_id":"18092","city_name":"Saint-Die","state_id":"1312"},
    {"city_id":"18093","city_name":"Auxerre","state_id":"1313"},
    {"city_id":"18094","city_name":"Avallon","state_id":"1313"},
    {"city_id":"18095","city_name":"Joigny","state_id":"1313"},
    {"city_id":"18096","city_name":"Sens","state_id":"1313"},
    {"city_id":"18097","city_name":"Acheres","state_id":"1314"},
    {"city_id":"18098","city_name":"Andresy","state_id":"1314"},
    {"city_id":"18099","city_name":"Aubergenville","state_id":"1314"},
    {"city_id":"18100","city_name":"Bois-d'Arcy","state_id":"1314"},
    {"city_id":"18101","city_name":"Carrieres-sous-Poissy","state_id":"1314"},
    {"city_id":"18102","city_name":"Carrieres-sur-Seine","state_id":"1314"},
    {"city_id":"18103","city_name":"Chanteloup-les-Vignes","state_id":"1314"},
    {"city_id":"18104","city_name":"Chatou","state_id":"1314"},
    {"city_id":"18105","city_name":"Conflans-Sainte-Honorine","state_id":"1314"},
    {"city_id":"18106","city_name":"Croissy-sur-Seine","state_id":"1314"},
    {"city_id":"18107","city_name":"Elancourt","state_id":"1314"},
    {"city_id":"18108","city_name":"Fontenay-le-Fleury","state_id":"1314"},
    {"city_id":"18109","city_name":"Guyancourt","state_id":"1314"},
    {"city_id":"18110","city_name":"Houilles","state_id":"1314"},
    {"city_id":"18111","city_name":"La Celle-Saint-Cloud","state_id":"1314"},
    {"city_id":"18112","city_name":"Le Chesnay","state_id":"1314"},
    {"city_id":"18113","city_name":"Le Pecq","state_id":"1314"},
    {"city_id":"18114","city_name":"Le Vesinet","state_id":"1314"},
    {"city_id":"18115","city_name":"Les Clayes-sous-Bois","state_id":"1314"},
    {"city_id":"18116","city_name":"Les Mureaux","state_id":"1314"},
    {"city_id":"18117","city_name":"Limay","state_id":"1314"},
    {"city_id":"18118","city_name":"Maisons-Laffitte","state_id":"1314"},
    {"city_id":"18119","city_name":"Mantes-la-Jolie","state_id":"1314"},
    {"city_id":"18120","city_name":"Mantes-la-Ville","state_id":"1314"},
    {"city_id":"18121","city_name":"Marly-le-Roi","state_id":"1314"},
    {"city_id":"18122","city_name":"Maurepas","state_id":"1314"},
    {"city_id":"18123","city_name":"Montesson","state_id":"1314"},
    {"city_id":"18124","city_name":"Montigny-le-Bretonneux","state_id":"1314"},
    {"city_id":"18125","city_name":"Plaisir","state_id":"1314"},
    {"city_id":"18126","city_name":"Poissy","state_id":"1314"},
    {"city_id":"18127","city_name":"Rambouillet","state_id":"1314"},
    {"city_id":"18128","city_name":"Saint-Cyr-l'Ecole","state_id":"1314"},
    {"city_id":"18129","city_name":"Saint-Germain-en-Laye","state_id":"1314"},
    {"city_id":"18130","city_name":"Sartrouville","state_id":"1314"},
    {"city_id":"18131","city_name":"Trappes","state_id":"1314"},
    {"city_id":"18132","city_name":"Triel-sur-Seine","state_id":"1314"},
    {"city_id":"18133","city_name":"Velizy-Villacoublay","state_id":"1314"},
    {"city_id":"18134","city_name":"Verneuil-sur-Seine","state_id":"1314"},
    {"city_id":"18135","city_name":"Versailles","state_id":"1314"},
    {"city_id":"18136","city_name":"Viroflay","state_id":"1314"},
    {"city_id":"18137","city_name":"Voisins-le-Bretonneux","state_id":"1314"},
    {"city_id":"18138","city_name":"Camopi","state_id":"1315"},
    {"city_id":"18139","city_name":"Cayenne","state_id":"1315"},
    {"city_id":"18140","city_name":"Iracoubo","state_id":"1315"},
    {"city_id":"18141","city_name":"Kourou","state_id":"1315"},
    {"city_id":"18142","city_name":"Macouria","state_id":"1315"},
    {"city_id":"18143","city_name":"Matoury","state_id":"1315"},
    {"city_id":"18144","city_name":"Remire-Montjoly","state_id":"1315"},
    {"city_id":"18145","city_name":"Roura","state_id":"1315"},
    {"city_id":"18146","city_name":"Saint-Georges","state_id":"1315"},
    {"city_id":"18147","city_name":"Sinnamary","state_id":"1315"},
    {"city_id":"18148","city_name":"Apatou","state_id":"1316"},
    {"city_id":"18149","city_name":"Grand-Santi","state_id":"1316"},
    {"city_id":"18150","city_name":"Mana","state_id":"1316"},
    {"city_id":"18151","city_name":"Maripasoula","state_id":"1316"},
    {"city_id":"18152","city_name":"Saint-Laurent-du-Maroni","state_id":"1316"},
    {"city_id":"18153","city_name":"Atuona","state_id":"1319"},
    {"city_id":"18154","city_name":"Fatu-Hiva","state_id":"1319"},
    {"city_id":"18155","city_name":"Hakahao","state_id":"1319"},
    {"city_id":"18156","city_name":"Hakamaii","state_id":"1319"},
    {"city_id":"18157","city_name":"Hatiheu","state_id":"1319"},
    {"city_id":"18158","city_name":"Tahuata","state_id":"1319"},
    {"city_id":"18159","city_name":"Taiohae","state_id":"1319"},
    {"city_id":"18160","city_name":"Taipivai","state_id":"1319"},
    {"city_id":"18161","city_name":"Ua-Huka","state_id":"1319"},
    {"city_id":"18162","city_name":"Ahe","state_id":"1320"},
    {"city_id":"18163","city_name":"Amanu","state_id":"1320"},
    {"city_id":"18164","city_name":"Anaa","state_id":"1320"},
    {"city_id":"18165","city_name":"Apataki","state_id":"1320"},
    {"city_id":"18166","city_name":"Arutua","state_id":"1320"},
    {"city_id":"18167","city_name":"Faaite","state_id":"1320"},
    {"city_id":"18168","city_name":"Fakahima","state_id":"1320"},
    {"city_id":"18169","city_name":"Fakarava","state_id":"1320"},
    {"city_id":"18170","city_name":"Fangatau","state_id":"1320"},
    {"city_id":"18171","city_name":"Hao","state_id":"1320"},
    {"city_id":"18172","city_name":"Hereheretue","state_id":"1320"},
    {"city_id":"18173","city_name":"Hikueru","state_id":"1320"},
    {"city_id":"18174","city_name":"Katiu","state_id":"1320"},
    {"city_id":"18175","city_name":"Kauehi","state_id":"1320"},
    {"city_id":"18176","city_name":"Kaukura","state_id":"1320"},
    {"city_id":"18177","city_name":"Makatea","state_id":"1320"},
    {"city_id":"18178","city_name":"Makemo","state_id":"1320"},
    {"city_id":"18179","city_name":"Manihi","state_id":"1320"},
    {"city_id":"18180","city_name":"Marokau","state_id":"1320"},
    {"city_id":"18181","city_name":"Napuka","state_id":"1320"},
    {"city_id":"18182","city_name":"Niau","state_id":"1320"},
    {"city_id":"18183","city_name":"Nukutavake","state_id":"1320"},
    {"city_id":"18184","city_name":"Puamau","state_id":"1320"},
    {"city_id":"18185","city_name":"Pukapuka","state_id":"1320"},
    {"city_id":"18186","city_name":"Pukarua","state_id":"1320"},
    {"city_id":"18187","city_name":"Rangiroa","state_id":"1320"},
    {"city_id":"18188","city_name":"Raroia","state_id":"1320"},
    {"city_id":"18189","city_name":"Reao","state_id":"1320"},
    {"city_id":"18190","city_name":"Rikitea","state_id":"1320"},
    {"city_id":"18191","city_name":"Taenga","state_id":"1320"},
    {"city_id":"18192","city_name":"Takapoto","state_id":"1320"},
    {"city_id":"18193","city_name":"Takaroa","state_id":"1320"},
    {"city_id":"18194","city_name":"Tatakoto","state_id":"1320"},
    {"city_id":"18195","city_name":"Tepoto","state_id":"1320"},
    {"city_id":"18196","city_name":"Tikehau","state_id":"1320"},
    {"city_id":"18197","city_name":"Tureia","state_id":"1320"},
    {"city_id":"18198","city_name":"Vahitahi","state_id":"1320"},
    {"city_id":"18199","city_name":"Vairaatea","state_id":"1320"},
    {"city_id":"18200","city_name":"Amaru","state_id":"1321"},
    {"city_id":"18201","city_name":"Anapoto","state_id":"1321"},
    {"city_id":"18202","city_name":"Anatonu","state_id":"1321"},
    {"city_id":"18203","city_name":"Auti","state_id":"1321"},
    {"city_id":"18204","city_name":"Avera","state_id":"1321"},
    {"city_id":"18205","city_name":"Mahu","state_id":"1321"},
    {"city_id":"18206","city_name":"Mataura","state_id":"1321"},
    {"city_id":"18207","city_name":"Moerai","state_id":"1321"},
    {"city_id":"18208","city_name":"Mutuaura","state_id":"1321"},
    {"city_id":"18209","city_name":"Rairua","state_id":"1321"},
    {"city_id":"18210","city_name":"Rapa","state_id":"1321"},
    {"city_id":"18211","city_name":"Taahuaia","state_id":"1321"},
    {"city_id":"18212","city_name":"Vaiuru","state_id":"1321"},
    {"city_id":"18213","city_name":"Martin-de-Vivies","state_id":"1322"},
    {"city_id":"18214","city_name":"Port-aux-Francais","state_id":"1324"},
    {"city_id":"18215","city_name":"Cocobeach","state_id":"1325"},
    {"city_id":"18216","city_name":"Kango","state_id":"1325"},
    {"city_id":"18217","city_name":"Libreville","state_id":"1325"},
    {"city_id":"18218","city_name":"Nkan","state_id":"1325"},
    {"city_id":"18219","city_name":"Ntoum","state_id":"1325"},
    {"city_id":"18220","city_name":"Lekoni","state_id":"1326"},
    {"city_id":"18221","city_name":"Masuku","state_id":"1326"},
    {"city_id":"18222","city_name":"Moanda","state_id":"1326"},
    {"city_id":"18223","city_name":"Mounana","state_id":"1326"},
    {"city_id":"18224","city_name":"Okandja","state_id":"1326"},
    {"city_id":"18225","city_name":"Lambarene","state_id":"1327"},
    {"city_id":"18226","city_name":"Ndjole","state_id":"1327"},
    {"city_id":"18227","city_name":"Fougamou","state_id":"1328"},
    {"city_id":"18228","city_name":"Mbigou","state_id":"1328"},
    {"city_id":"18229","city_name":"Mimongo","state_id":"1328"},
    {"city_id":"18230","city_name":"Mouila","state_id":"1328"},
    {"city_id":"18231","city_name":"Ndende","state_id":"1328"},
    {"city_id":"18232","city_name":"Mayumba","state_id":"1329"},
    {"city_id":"18233","city_name":"Tchibanga","state_id":"1329"},
    {"city_id":"18234","city_name":"Tsogni","state_id":"1329"},
    {"city_id":"18235","city_name":"Booue","state_id":"1330"},
    {"city_id":"18236","city_name":"Makokou","state_id":"1330"},
    {"city_id":"18237","city_name":"Mekambo","state_id":"1330"},
    {"city_id":"18238","city_name":"Koulamoutou","state_id":"1331"},
    {"city_id":"18239","city_name":"Lastoursville","state_id":"1331"},
    {"city_id":"18240","city_name":"Gamba","state_id":"1332"},
    {"city_id":"18241","city_name":"Omboue","state_id":"1332"},
    {"city_id":"18242","city_name":"Port-Gentil","state_id":"1332"},
    {"city_id":"18243","city_name":"Sette Cama","state_id":"1332"},
    {"city_id":"18244","city_name":"Bitam","state_id":"1333"},
    {"city_id":"18245","city_name":"Lalara","state_id":"1333"},
    {"city_id":"18246","city_name":"Medouneu","state_id":"1333"},
    {"city_id":"18247","city_name":"Minvoul","state_id":"1333"},
    {"city_id":"18248","city_name":"Mitzic","state_id":"1333"},
    {"city_id":"18249","city_name":"Oyem","state_id":"1333"},
    {"city_id":"18250","city_name":"Banjul","state_id":"1334"},
    {"city_id":"18251","city_name":"Basse","state_id":"1335"},
    {"city_id":"18252","city_name":"Gambissara","state_id":"1335"},
    {"city_id":"18253","city_name":"Sabi","state_id":"1335"},
    {"city_id":"18254","city_name":"Salikeni","state_id":"1335"},
    {"city_id":"18255","city_name":"Brikama","state_id":"1336"},
    {"city_id":"18256","city_name":"Brufut","state_id":"1336"},
    {"city_id":"18257","city_name":"Gunjur","state_id":"1336"},
    {"city_id":"18258","city_name":"Sukuta","state_id":"1336"},
    {"city_id":"18259","city_name":"Bansang","state_id":"1337"},
    {"city_id":"18260","city_name":"Janjanbureh","state_id":"1337"},
    {"city_id":"18261","city_name":"Bakau","state_id":"1338"},
    {"city_id":"18262","city_name":"Serekunda","state_id":"1338"},
    {"city_id":"18263","city_name":"Barra","state_id":"1339"},
    {"city_id":"18264","city_name":"Essau","state_id":"1339"},
    {"city_id":"18265","city_name":"Farafenni","state_id":"1339"},
    {"city_id":"18266","city_name":"Kerewan","state_id":"1339"},
    {"city_id":"18267","city_name":"Lamin","state_id":"1339"},
    {"city_id":"18268","city_name":"Kuntaur","state_id":"1340"},
    {"city_id":"18269","city_name":"Mansakonko","state_id":"1341"},
    {"city_id":"18270","city_name":"Ahali Atoni","state_id":"1342"},
    {"city_id":"18271","city_name":"Bihvinta","state_id":"1342"},
    {"city_id":"18272","city_name":"Gagra","state_id":"1342"},
    {"city_id":"18273","city_name":"Gali","state_id":"1342"},
    {"city_id":"18274","city_name":"Gudauta","state_id":"1342"},
    {"city_id":"18275","city_name":"Gulripshi","state_id":"1342"},
    {"city_id":"18276","city_name":"Ochamchira","state_id":"1342"},
    {"city_id":"18277","city_name":"Suhumi","state_id":"1342"},
    {"city_id":"18278","city_name":"Tkvarcheli","state_id":"1342"},
    {"city_id":"18279","city_name":"Batumi","state_id":"1343"},
    {"city_id":"18280","city_name":"Dioknisi","state_id":"1343"},
    {"city_id":"18281","city_name":"Kobuleti","state_id":"1343"},
    {"city_id":"18282","city_name":"Mahindzhauri","state_id":"1343"},
    {"city_id":"18283","city_name":"Lanchhuti","state_id":"1344"},
    {"city_id":"18284","city_name":"Ozurgeti","state_id":"1344"},
    {"city_id":"18285","city_name":"Bagdadi","state_id":"1345"},
    {"city_id":"18286","city_name":"Chaltubo","state_id":"1345"},
    {"city_id":"18287","city_name":"Chiatura","state_id":"1345"},
    {"city_id":"18288","city_name":"Honi","state_id":"1345"},
    {"city_id":"18289","city_name":"Kutaisi","state_id":"1345"},
    {"city_id":"18290","city_name":"Sachhere","state_id":"1345"},
    {"city_id":"18291","city_name":"Samtredia","state_id":"1345"},
    {"city_id":"18292","city_name":"Terzhola","state_id":"1345"},
    {"city_id":"18293","city_name":"Tkibuli","state_id":"1345"},
    {"city_id":"18294","city_name":"Vani","state_id":"1345"},
    {"city_id":"18295","city_name":"Zestaponi","state_id":"1345"},
    {"city_id":"18296","city_name":"Ahmeta","state_id":"1346"},
    {"city_id":"18297","city_name":"Cnori","state_id":"1346"},
    {"city_id":"18298","city_name":"Dedoplisckaro","state_id":"1346"},
    {"city_id":"18299","city_name":"Gurdzhaani","state_id":"1346"},
    {"city_id":"18300","city_name":"Kvareli","state_id":"1346"},
    {"city_id":"18301","city_name":"Lagodehi","state_id":"1346"},
    {"city_id":"18302","city_name":"Sagaredzho","state_id":"1346"},
    {"city_id":"18303","city_name":"Signahi","state_id":"1346"},
    {"city_id":"18304","city_name":"Telavi","state_id":"1346"},
    {"city_id":"18305","city_name":"Dusheti","state_id":"1348"},
    {"city_id":"18306","city_name":"Mcheta","state_id":"1348"},
    {"city_id":"18307","city_name":"Ambrolauri","state_id":"1349"},
    {"city_id":"18308","city_name":"Cageri","state_id":"1349"},
    {"city_id":"18309","city_name":"Oni","state_id":"1349"},
    {"city_id":"18310","city_name":"Ahalcihe","state_id":"1351"},
    {"city_id":"18311","city_name":"Ahalkalaki","state_id":"1351"},
    {"city_id":"18312","city_name":"Borzhomi","state_id":"1351"},
    {"city_id":"18313","city_name":"Ninocminda","state_id":"1351"},
    {"city_id":"18314","city_name":"Vale","state_id":"1351"},
    {"city_id":"18315","city_name":"Tbilisi","state_id":"1353"},
    {"city_id":"18316","city_name":"Aalen","state_id":"1355"},
    {"city_id":"18317","city_name":"Achern","state_id":"1355"},
    {"city_id":"18318","city_name":"Aichtal","state_id":"1355"},
    {"city_id":"18319","city_name":"Albstadt","state_id":"1355"},
    {"city_id":"18320","city_name":"Aldingen","state_id":"1355"},
    {"city_id":"18321","city_name":"Allmersbach","state_id":"1355"},
    {"city_id":"18322","city_name":"Alpirsbach","state_id":"1355"},
    {"city_id":"18323","city_name":"Altensteig","state_id":"1355"},
    {"city_id":"18324","city_name":"Altlussheim","state_id":"1355"},
    {"city_id":"18325","city_name":"Ammerbuch","state_id":"1355"},
    {"city_id":"18326","city_name":"Appenweier","state_id":"1355"},
    {"city_id":"18327","city_name":"Asperg","state_id":"1355"},
    {"city_id":"18328","city_name":"Backnang","state_id":"1355"},
    {"city_id":"18329","city_name":"Bad Durrheim","state_id":"1355"},
    {"city_id":"18330","city_name":"Bad Friedrichshall","state_id":"1355"},
    {"city_id":"18331","city_name":"Bad Krozingen","state_id":"1355"},
    {"city_id":"18332","city_name":"Bad Liebenzell","state_id":"1355"},
    {"city_id":"18333","city_name":"Bad Mergentheim","state_id":"1355"},
    {"city_id":"18334","city_name":"Bad Rappenau","state_id":"1355"},
    {"city_id":"18335","city_name":"Bad Sackingen","state_id":"1355"},
    {"city_id":"18336","city_name":"Bad Schonborn","state_id":"1355"},
    {"city_id":"18337","city_name":"Bad Urach","state_id":"1355"},
    {"city_id":"18338","city_name":"Bad Waldsee","state_id":"1355"},
    {"city_id":"18339","city_name":"Bad Wurzach","state_id":"1355"},
    {"city_id":"18340","city_name":"Baden-Baden","state_id":"1355"},
    {"city_id":"18341","city_name":"Bahlingen","state_id":"1355"},
    {"city_id":"18342","city_name":"Baiersbronn","state_id":"1355"},
    {"city_id":"18343","city_name":"Balgheim","state_id":"1355"},
    {"city_id":"18344","city_name":"Balingen","state_id":"1355"},
    {"city_id":"18345","city_name":"Ballrechten-Dottingen","state_id":"1355"},
    {"city_id":"18346","city_name":"Besigheim","state_id":"1355"},
    {"city_id":"18347","city_name":"Biberach","state_id":"1355"},
    {"city_id":"18348","city_name":"Bietigheim-Bissingen","state_id":"1355"},
    {"city_id":"18349","city_name":"Binzen","state_id":"1355"},
    {"city_id":"18350","city_name":"Birkenfeld","state_id":"1355"},
    {"city_id":"18351","city_name":"Bisingen","state_id":"1355"},
    {"city_id":"18352","city_name":"Bitz","state_id":"1355"},
    {"city_id":"18353","city_name":"Blaubeuren","state_id":"1355"},
    {"city_id":"18354","city_name":"Blaustein","state_id":"1355"},
    {"city_id":"18355","city_name":"Blumberg","state_id":"1355"},
    {"city_id":"18356","city_name":"Boblingen","state_id":"1355"},
    {"city_id":"18357","city_name":"Bodelshausen","state_id":"1355"},
    {"city_id":"18358","city_name":"Bonndorf","state_id":"1355"},
    {"city_id":"18359","city_name":"Bonnigheim","state_id":"1355"},
    {"city_id":"18360","city_name":"Bopfingen","state_id":"1355"},
    {"city_id":"18361","city_name":"Bottingen","state_id":"1355"},
    {"city_id":"18362","city_name":"Brackenheim","state_id":"1355"},
    {"city_id":"18363","city_name":"Breisach","state_id":"1355"},
    {"city_id":"18364","city_name":"Bretten","state_id":"1355"},
    {"city_id":"18365","city_name":"Bretzfeld","state_id":"1355"},
    {"city_id":"18366","city_name":"Bruchsal","state_id":"1355"},
    {"city_id":"18367","city_name":"Bruhl","state_id":"1355"},
    {"city_id":"18368","city_name":"Buchen","state_id":"1355"},
    {"city_id":"18369","city_name":"Buchenbach","state_id":"1355"},
    {"city_id":"18370","city_name":"Buhl","state_id":"1355"},
    {"city_id":"18371","city_name":"Burgstetten","state_id":"1355"},
    {"city_id":"18372","city_name":"Burladingen","state_id":"1355"},
    {"city_id":"18373","city_name":"Calw","state_id":"1355"},
    {"city_id":"18374","city_name":"Crailsheim","state_id":"1355"},
    {"city_id":"18375","city_name":"Denkendorf","state_id":"1355"},
    {"city_id":"18376","city_name":"Denzlingen","state_id":"1355"},
    {"city_id":"18377","city_name":"Dettingen","state_id":"1355"},
    {"city_id":"18378","city_name":"Dietenheim","state_id":"1355"},
    {"city_id":"18379","city_name":"Ditzingen","state_id":"1355"},
    {"city_id":"18380","city_name":"Donaueschingen","state_id":"1355"},
    {"city_id":"18381","city_name":"Donzdorf","state_id":"1355"},
    {"city_id":"18382","city_name":"Dornhan","state_id":"1355"},
    {"city_id":"18383","city_name":"Dornstadt","state_id":"1355"},
    {"city_id":"18384","city_name":"Dornstetten","state_id":"1355"},
    {"city_id":"18385","city_name":"Dossenheim","state_id":"1355"},
    {"city_id":"18386","city_name":"Durbheim","state_id":"1355"},
    {"city_id":"18387","city_name":"Durmersheim","state_id":"1355"},
    {"city_id":"18388","city_name":"Eberbach","state_id":"1355"},
    {"city_id":"18389","city_name":"Ebersbach","state_id":"1355"},
    {"city_id":"18390","city_name":"Edingen","state_id":"1355"},
    {"city_id":"18391","city_name":"Edingen-Neckarhausen","state_id":"1355"},
    {"city_id":"18392","city_name":"Eggenstein-Leopoldshafen","state_id":"1355"},
    {"city_id":"18393","city_name":"Ehingen","state_id":"1355"},
    {"city_id":"18394","city_name":"Eislingen","state_id":"1355"},
    {"city_id":"18395","city_name":"Ellhofen","state_id":"1355"},
    {"city_id":"18396","city_name":"Ellwangen","state_id":"1355"},
    {"city_id":"18397","city_name":"Emmendingen","state_id":"1355"},
    {"city_id":"18398","city_name":"Emmingen-Liptingen","state_id":"1355"},
    {"city_id":"18399","city_name":"Engen","state_id":"1355"},
    {"city_id":"18400","city_name":"Eningen","state_id":"1355"},
    {"city_id":"18401","city_name":"Eppelheim","state_id":"1355"},
    {"city_id":"18402","city_name":"Eppingen","state_id":"1355"},
    {"city_id":"18403","city_name":"Erbach","state_id":"1355"},
    {"city_id":"18404","city_name":"Eriskirch","state_id":"1355"},
    {"city_id":"18405","city_name":"Eschach","state_id":"1355"},
    {"city_id":"18406","city_name":"Esslingen","state_id":"1355"},
    {"city_id":"18407","city_name":"Ettenheim","state_id":"1355"},
    {"city_id":"18408","city_name":"Ettlingen","state_id":"1355"},
    {"city_id":"18409","city_name":"Fellbach","state_id":"1355"},
    {"city_id":"18410","city_name":"Filderstadt","state_id":"1355"},
    {"city_id":"18411","city_name":"Freiberg","state_id":"1355"},
    {"city_id":"18412","city_name":"Freiburg","state_id":"1355"},
    {"city_id":"18413","city_name":"Freudenstadt","state_id":"1355"},
    {"city_id":"18414","city_name":"Frickenhausen","state_id":"1355"},
    {"city_id":"18415","city_name":"Fridingen","state_id":"1355"},
    {"city_id":"18416","city_name":"Friedrichshafen","state_id":"1355"},
    {"city_id":"18417","city_name":"Friesenheim","state_id":"1355"},
    {"city_id":"18418","city_name":"Frittlingen","state_id":"1355"},
    {"city_id":"18419","city_name":"Furtwangen","state_id":"1355"},
    {"city_id":"18420","city_name":"Gaggenau","state_id":"1355"},
    {"city_id":"18421","city_name":"Gaildorf","state_id":"1355"},
    {"city_id":"18422","city_name":"Gartringen","state_id":"1355"},
    {"city_id":"18423","city_name":"Gaufelden","state_id":"1355"},
    {"city_id":"18424","city_name":"Geislingen","state_id":"1355"},
    {"city_id":"18425","city_name":"Gengenbach","state_id":"1355"},
    {"city_id":"18426","city_name":"Gerlingen","state_id":"1355"},
    {"city_id":"18427","city_name":"Gernsbach","state_id":"1355"},
    {"city_id":"18428","city_name":"Gerstetten","state_id":"1355"},
    {"city_id":"18429","city_name":"Giengen","state_id":"1355"},
    {"city_id":"18430","city_name":"Goppingen","state_id":"1355"},
    {"city_id":"18431","city_name":"Gottmadingen","state_id":"1355"},
    {"city_id":"18432","city_name":"Graben-Neudorf","state_id":"1355"},
    {"city_id":"18433","city_name":"Grafenberg","state_id":"1355"},
    {"city_id":"18434","city_name":"Grafenhausen","state_id":"1355"},
    {"city_id":"18435","city_name":"Grenzach-Wyhlen","state_id":"1355"},
    {"city_id":"18436","city_name":"GroBbettlingen","state_id":"1355"},
    {"city_id":"18437","city_name":"Grunkraut","state_id":"1355"},
    {"city_id":"18438","city_name":"Gschwend","state_id":"1355"},
    {"city_id":"18439","city_name":"Guglingen","state_id":"1355"},
    {"city_id":"18440","city_name":"Gundelfingen","state_id":"1355"},
    {"city_id":"18441","city_name":"Gutach","state_id":"1355"},
    {"city_id":"18442","city_name":"Haigerloch","state_id":"1355"},
    {"city_id":"18443","city_name":"Haiterbach","state_id":"1355"},
    {"city_id":"18444","city_name":"Harmersabch","state_id":"1355"},
    {"city_id":"18445","city_name":"Hausach","state_id":"1355"},
    {"city_id":"18446","city_name":"Hechingen","state_id":"1355"},
    {"city_id":"18447","city_name":"Heddesheim","state_id":"1355"},
    {"city_id":"18448","city_name":"Heidelberg","state_id":"1355"},
    {"city_id":"18449","city_name":"Heidenheim","state_id":"1355"},
    {"city_id":"18450","city_name":"Heilbronn","state_id":"1355"},
    {"city_id":"18451","city_name":"Hemsbach","state_id":"1355"},
    {"city_id":"18452","city_name":"Herbrechtingen","state_id":"1355"},
    {"city_id":"18453","city_name":"Herrenberg","state_id":"1355"},
    {"city_id":"18454","city_name":"Heubach","state_id":"1355"},
    {"city_id":"18455","city_name":"Hirschberg","state_id":"1355"},
    {"city_id":"18456","city_name":"Hockenheim","state_id":"1355"},
    {"city_id":"18457","city_name":"Holzgerlingen","state_id":"1355"},
    {"city_id":"18458","city_name":"Horb","state_id":"1355"},
    {"city_id":"18459","city_name":"Hufingen","state_id":"1355"},
    {"city_id":"18460","city_name":"Huttlingen","state_id":"1355"},
    {"city_id":"18461","city_name":"Isny","state_id":"1355"},
    {"city_id":"18462","city_name":"Ispringen","state_id":"1355"},
    {"city_id":"18463","city_name":"Jungingen","state_id":"1355"},
    {"city_id":"18464","city_name":"Karlsbad","state_id":"1355"},
    {"city_id":"18465","city_name":"Karlsruhe","state_id":"1355"},
    {"city_id":"18466","city_name":"Kehl","state_id":"1355"},
    {"city_id":"18467","city_name":"Kernen","state_id":"1355"},
    {"city_id":"18468","city_name":"Ketsch","state_id":"1355"},
    {"city_id":"18469","city_name":"Kieselbronn","state_id":"1355"},
    {"city_id":"18470","city_name":"Kirchberg an der Jagst","state_id":"1355"},
    {"city_id":"18471","city_name":"Kirchheim","state_id":"1355"},
    {"city_id":"18472","city_name":"Kirchzarten","state_id":"1355"},
    {"city_id":"18473","city_name":"Kisslegg","state_id":"1355"},
    {"city_id":"18474","city_name":"Klettgau","state_id":"1355"},
    {"city_id":"18475","city_name":"Kongen","state_id":"1355"},
    {"city_id":"18476","city_name":"Konigsbach-Stein","state_id":"1355"},
    {"city_id":"18477","city_name":"Konigsbronn","state_id":"1355"},
    {"city_id":"18478","city_name":"Konstanz","state_id":"1355"},
    {"city_id":"18479","city_name":"Korb","state_id":"1355"},
    {"city_id":"18480","city_name":"Korntal-Munchingen","state_id":"1355"},
    {"city_id":"18481","city_name":"Kornwestheim","state_id":"1355"},
    {"city_id":"18482","city_name":"Kraichtal","state_id":"1355"},
    {"city_id":"18483","city_name":"Kressbronn an Bodensee","state_id":"1355"},
    {"city_id":"18484","city_name":"Kronau","state_id":"1355"},
    {"city_id":"18485","city_name":"Kuessaberg","state_id":"1355"},
    {"city_id":"18486","city_name":"Kunzelsau","state_id":"1355"},
    {"city_id":"18487","city_name":"Ladenburg","state_id":"1355"},
    {"city_id":"18488","city_name":"Lahr","state_id":"1355"},
    {"city_id":"18489","city_name":"Laichingen","state_id":"1355"},
    {"city_id":"18490","city_name":"Langenau","state_id":"1355"},
    {"city_id":"18491","city_name":"Lauda-Konigshofen","state_id":"1355"},
    {"city_id":"18492","city_name":"Lauffen","state_id":"1355"},
    {"city_id":"18493","city_name":"Laupheim","state_id":"1355"},
    {"city_id":"18494","city_name":"Leimen","state_id":"1355"},
    {"city_id":"18495","city_name":"Leinfelden-Echterdingen","state_id":"1355"},
    {"city_id":"18496","city_name":"Leingarten","state_id":"1355"},
    {"city_id":"18497","city_name":"Lenningen","state_id":"1355"},
    {"city_id":"18498","city_name":"Lenzkirch","state_id":"1355"},
    {"city_id":"18499","city_name":"Leonberg","state_id":"1355"},
    {"city_id":"18500","city_name":"Leutenbach","state_id":"1355"},
    {"city_id":"18501","city_name":"Leutkirch","state_id":"1355"},
    {"city_id":"18502","city_name":"Lichtenstein","state_id":"1355"},
    {"city_id":"18503","city_name":"Linkenheim-Hochstetten","state_id":"1355"},
    {"city_id":"18504","city_name":"Lorch","state_id":"1355"},
    {"city_id":"18505","city_name":"Lorrach","state_id":"1355"},
    {"city_id":"18506","city_name":"Ludwigsburg","state_id":"1355"},
    {"city_id":"18507","city_name":"Malsch","state_id":"1355"},
    {"city_id":"18508","city_name":"Mannheim","state_id":"1355"},
    {"city_id":"18509","city_name":"Marbach","state_id":"1355"},
    {"city_id":"18510","city_name":"Markdorf","state_id":"1355"},
    {"city_id":"18511","city_name":"Markgroningen","state_id":"1355"},
    {"city_id":"18512","city_name":"Maulburg","state_id":"1355"},
    {"city_id":"18513","city_name":"MeBstetten","state_id":"1355"},
    {"city_id":"18514","city_name":"Meckenbeuren","state_id":"1355"},
    {"city_id":"18515","city_name":"Meckesheim","state_id":"1355"},
    {"city_id":"18516","city_name":"Meersburg","state_id":"1355"},
    {"city_id":"18517","city_name":"Mengen","state_id":"1355"},
    {"city_id":"18518","city_name":"Metzingen","state_id":"1355"},
    {"city_id":"18519","city_name":"Mockmuhl","state_id":"1355"},
    {"city_id":"18520","city_name":"Moglingen","state_id":"1355"},
    {"city_id":"18521","city_name":"Monsheim","state_id":"1355"},
    {"city_id":"18522","city_name":"Mosbach","state_id":"1355"},
    {"city_id":"18523","city_name":"Mossingen","state_id":"1355"},
    {"city_id":"18524","city_name":"Muhlacker","state_id":"1355"},
    {"city_id":"18525","city_name":"Mullheim","state_id":"1355"},
    {"city_id":"18526","city_name":"Munsingen","state_id":"1355"},
    {"city_id":"18527","city_name":"Murrhardt","state_id":"1355"},
    {"city_id":"18528","city_name":"Nagold","state_id":"1355"},
    {"city_id":"18529","city_name":"Neckargemund","state_id":"1355"},
    {"city_id":"18530","city_name":"Neckarsulm","state_id":"1355"},
    {"city_id":"18531","city_name":"Neresheim","state_id":"1355"},
    {"city_id":"18532","city_name":"Neuenburg","state_id":"1355"},
    {"city_id":"18533","city_name":"Neuhausen","state_id":"1355"},
    {"city_id":"18534","city_name":"Niederstetten","state_id":"1355"},
    {"city_id":"18535","city_name":"Niefern-Oschelbronn","state_id":"1355"},
    {"city_id":"18536","city_name":"NuBloch","state_id":"1355"},
    {"city_id":"18537","city_name":"Nurtingen","state_id":"1355"},
    {"city_id":"18538","city_name":"Oberhausen-Rheinhausen","state_id":"1355"},
    {"city_id":"18539","city_name":"Oberkirch","state_id":"1355"},
    {"city_id":"18540","city_name":"Oberndorf","state_id":"1355"},
    {"city_id":"18541","city_name":"Oberstenfeld","state_id":"1355"},
    {"city_id":"18542","city_name":"Obersulm","state_id":"1355"},
    {"city_id":"18543","city_name":"Ochsenhausen","state_id":"1355"},
    {"city_id":"18544","city_name":"Offenburg","state_id":"1355"},
    {"city_id":"18545","city_name":"Ofterdingen","state_id":"1355"},
    {"city_id":"18546","city_name":"Oftersheim","state_id":"1355"},
    {"city_id":"18547","city_name":"Oggelshausen","state_id":"1355"},
    {"city_id":"18548","city_name":"Ohringen","state_id":"1355"},
    {"city_id":"18549","city_name":"Olbronn-Durrn","state_id":"1355"},
    {"city_id":"18550","city_name":"Oppenweiler","state_id":"1355"},
    {"city_id":"18551","city_name":"Ostfildern","state_id":"1355"},
    {"city_id":"18552","city_name":"Ostringen","state_id":"1355"},
    {"city_id":"18553","city_name":"Otisheim","state_id":"1355"},
    {"city_id":"18554","city_name":"Pfalzgrafenweiler","state_id":"1355"},
    {"city_id":"18555","city_name":"Pfinztal","state_id":"1355"},
    {"city_id":"18556","city_name":"Pforzheim","state_id":"1355"},
    {"city_id":"18557","city_name":"Pfullendorf","state_id":"1355"},
    {"city_id":"18558","city_name":"Pfullingen","state_id":"1355"},
    {"city_id":"18559","city_name":"Philippsburg","state_id":"1355"},
    {"city_id":"18560","city_name":"Plankstadt","state_id":"1355"},
    {"city_id":"18561","city_name":"Pleidelsheim","state_id":"1355"},
    {"city_id":"18562","city_name":"Pliezhausen","state_id":"1355"},
    {"city_id":"18563","city_name":"Plochingen","state_id":"1355"},
    {"city_id":"18564","city_name":"Pluderhausen","state_id":"1355"},
    {"city_id":"18565","city_name":"Radolfzell","state_id":"1355"},
    {"city_id":"18566","city_name":"Rastatt","state_id":"1355"},
    {"city_id":"18567","city_name":"Ravensburg","state_id":"1355"},
    {"city_id":"18568","city_name":"Reilingen","state_id":"1355"},
    {"city_id":"18569","city_name":"Remchingen","state_id":"1355"},
    {"city_id":"18570","city_name":"Remseck","state_id":"1355"},
    {"city_id":"18571","city_name":"Remshalden","state_id":"1355"},
    {"city_id":"18572","city_name":"Renchen","state_id":"1355"},
    {"city_id":"18573","city_name":"Renningen","state_id":"1355"},
    {"city_id":"18574","city_name":"Reutlingen","state_id":"1355"},
    {"city_id":"18575","city_name":"Rheinau","state_id":"1355"},
    {"city_id":"18576","city_name":"Rheinfelden","state_id":"1355"},
    {"city_id":"18577","city_name":"Rheinmunster","state_id":"1355"},
    {"city_id":"18578","city_name":"Rheinstetten","state_id":"1355"},
    {"city_id":"18579","city_name":"Riederich","state_id":"1355"},
    {"city_id":"18580","city_name":"Riedlingen","state_id":"1355"},
    {"city_id":"18581","city_name":"Rielasingen-Worblingen","state_id":"1355"},
    {"city_id":"18582","city_name":"Rosenfeld","state_id":"1355"},
    {"city_id":"18583","city_name":"Rottenburg","state_id":"1355"},
    {"city_id":"18584","city_name":"Rottweil","state_id":"1355"},
    {"city_id":"18585","city_name":"Rudersberg","state_id":"1355"},
    {"city_id":"18586","city_name":"Rutesheim","state_id":"1355"},
    {"city_id":"18587","city_name":"Sachsenheim","state_id":"1355"},
    {"city_id":"18588","city_name":"Salem","state_id":"1355"},
    {"city_id":"18589","city_name":"Sandhausen","state_id":"1355"},
    {"city_id":"18590","city_name":"Sankt Georgen","state_id":"1355"},
    {"city_id":"18591","city_name":"Sankt Leon-Rot","state_id":"1355"},
    {"city_id":"18592","city_name":"Saulgau","state_id":"1355"},
    {"city_id":"18593","city_name":"Scheer","state_id":"1355"},
    {"city_id":"18594","city_name":"Schlierbach","state_id":"1355"},
    {"city_id":"18595","city_name":"Schonaich","state_id":"1355"},
    {"city_id":"18596","city_name":"Schopfheim","state_id":"1355"},
    {"city_id":"18597","city_name":"Schorndorf","state_id":"1355"},
    {"city_id":"18598","city_name":"Schramberg","state_id":"1355"},
    {"city_id":"18599","city_name":"Schriesheim","state_id":"1355"},
    {"city_id":"18600","city_name":"Schwabisch Gmund","state_id":"1355"},
    {"city_id":"18601","city_name":"Schwabisch Hall","state_id":"1355"},
    {"city_id":"18602","city_name":"Schwaigern","state_id":"1355"},
    {"city_id":"18603","city_name":"Schwetzingen","state_id":"1355"},
    {"city_id":"18604","city_name":"Schwieberdingen","state_id":"1355"},
    {"city_id":"18605","city_name":"Seitingen-Oberflacht","state_id":"1355"},
    {"city_id":"18606","city_name":"Sexau","state_id":"1355"},
    {"city_id":"18607","city_name":"Sigmaringen","state_id":"1355"},
    {"city_id":"18608","city_name":"Sindelfingen","state_id":"1355"},
    {"city_id":"18609","city_name":"Singen","state_id":"1355"},
    {"city_id":"18610","city_name":"Sinsheim","state_id":"1355"},
    {"city_id":"18611","city_name":"Sinzheim","state_id":"1355"},
    {"city_id":"18612","city_name":"Sonnenbuhl","state_id":"1355"},
    {"city_id":"18613","city_name":"Sontheim","state_id":"1355"},
    {"city_id":"18614","city_name":"Spaichingen","state_id":"1355"},
    {"city_id":"18615","city_name":"Stegen","state_id":"1355"},
    {"city_id":"18616","city_name":"Steinen","state_id":"1355"},
    {"city_id":"18617","city_name":"Steinheim","state_id":"1355"},
    {"city_id":"18618","city_name":"Steinmauern","state_id":"1355"},
    {"city_id":"18619","city_name":"Stockach","state_id":"1355"},
    {"city_id":"18620","city_name":"Straubenhardt","state_id":"1355"},
    {"city_id":"18621","city_name":"Stutensee","state_id":"1355"},
    {"city_id":"18622","city_name":"Stuttgart","state_id":"1355"},
    {"city_id":"18623","city_name":"SuBen","state_id":"1355"},
    {"city_id":"18624","city_name":"Sulz","state_id":"1355"},
    {"city_id":"18625","city_name":"Sulzfeld","state_id":"1355"},
    {"city_id":"18626","city_name":"Tamm","state_id":"1355"},
    {"city_id":"18627","city_name":"Tauberbischofsheim","state_id":"1355"},
    {"city_id":"18628","city_name":"Teningen","state_id":"1355"},
    {"city_id":"18629","city_name":"Tettnang","state_id":"1355"},
    {"city_id":"18630","city_name":"Titisee-Neustadt","state_id":"1355"},
    {"city_id":"18631","city_name":"Trossingen","state_id":"1355"},
    {"city_id":"18632","city_name":"Tubingen","state_id":"1355"},
    {"city_id":"18633","city_name":"Tuningen","state_id":"1355"},
    {"city_id":"18634","city_name":"Tuttlingen","state_id":"1355"},
    {"city_id":"18635","city_name":"Uberlingen","state_id":"1355"},
    {"city_id":"18636","city_name":"Ubstadt-Weiher","state_id":"1355"},
    {"city_id":"18637","city_name":"Uhingen","state_id":"1355"},
    {"city_id":"18638","city_name":"Ulm","state_id":"1355"},
    {"city_id":"18639","city_name":"Umkirch","state_id":"1355"},
    {"city_id":"18640","city_name":"Vaihingen","state_id":"1355"},
    {"city_id":"18641","city_name":"Villingen-Schwenningen","state_id":"1355"},
    {"city_id":"18642","city_name":"Villingendorf","state_id":"1355"},
    {"city_id":"18643","city_name":"Waghausel","state_id":"1355"},
    {"city_id":"18644","city_name":"Waiblingen","state_id":"1355"},
    {"city_id":"18645","city_name":"Waldbronn","state_id":"1355"},
    {"city_id":"18646","city_name":"Waldkirch","state_id":"1355"},
    {"city_id":"18647","city_name":"Waldlaubersheim","state_id":"1355"},
    {"city_id":"18648","city_name":"Waldshut-Tiengen","state_id":"1355"},
    {"city_id":"18649","city_name":"Walldorf","state_id":"1355"},
    {"city_id":"18650","city_name":"Walldurn","state_id":"1355"},
    {"city_id":"18651","city_name":"Walzbachtal","state_id":"1355"},
    {"city_id":"18652","city_name":"Wangen","state_id":"1355"},
    {"city_id":"18653","city_name":"Wehr","state_id":"1355"},
    {"city_id":"18654","city_name":"Weikersheim","state_id":"1355"},
    {"city_id":"18655","city_name":"Weil","state_id":"1355"},
    {"city_id":"18656","city_name":"Weil am Rhein","state_id":"1355"},
    {"city_id":"18657","city_name":"Weil der Stadt","state_id":"1355"},
    {"city_id":"18658","city_name":"Weil im Schonbuch","state_id":"1355"},
    {"city_id":"18659","city_name":"Weilheim","state_id":"1355"},
    {"city_id":"18660","city_name":"Weingarten","state_id":"1355"},
    {"city_id":"18661","city_name":"Weinheim","state_id":"1355"},
    {"city_id":"18662","city_name":"Weinsberg","state_id":"1355"},
    {"city_id":"18663","city_name":"Weinstadt","state_id":"1355"},
    {"city_id":"18664","city_name":"Wellendingen","state_id":"1355"},
    {"city_id":"18665","city_name":"Welzheim","state_id":"1355"},
    {"city_id":"18666","city_name":"Wendlingen","state_id":"1355"},
    {"city_id":"18667","city_name":"Wernau","state_id":"1355"},
    {"city_id":"18668","city_name":"Wertheim","state_id":"1355"},
    {"city_id":"18669","city_name":"Wiesloch","state_id":"1355"},
    {"city_id":"18670","city_name":"Wildbad","state_id":"1355"},
    {"city_id":"18671","city_name":"Wildberg","state_id":"1355"},
    {"city_id":"18672","city_name":"Winnenden","state_id":"1355"},
    {"city_id":"18673","city_name":"Wolpertshausen","state_id":"1355"},
    {"city_id":"18674","city_name":"Zuzenhausen","state_id":"1355"},
    {"city_id":"18675","city_name":"Ahorn","state_id":"1356"},
    {"city_id":"18676","city_name":"Allershausen","state_id":"1356"},
    {"city_id":"18677","city_name":"Attenhofen","state_id":"1356"},
    {"city_id":"18678","city_name":"Bad Birnbach","state_id":"1356"},
    {"city_id":"18679","city_name":"Bad Endorf","state_id":"1356"},
    {"city_id":"18680","city_name":"Bad Gronenbach","state_id":"1356"},
    {"city_id":"18681","city_name":"Barbing","state_id":"1356"},
    {"city_id":"18682","city_name":"Benediktbeuern","state_id":"1356"},
    {"city_id":"18683","city_name":"Breitenberg","state_id":"1356"},
    {"city_id":"18684","city_name":"Bruckberg","state_id":"1356"},
    {"city_id":"18685","city_name":"Brunnthal","state_id":"1356"},
    {"city_id":"18686","city_name":"Burgheim","state_id":"1356"},
    {"city_id":"18687","city_name":"Chiemsee","state_id":"1356"},
    {"city_id":"18688","city_name":"Emersacker","state_id":"1356"},
    {"city_id":"18689","city_name":"Eresing","state_id":"1356"},
    {"city_id":"18690","city_name":"Fahrenzhausen","state_id":"1356"},
    {"city_id":"18691","city_name":"Faulbach","state_id":"1356"},
    {"city_id":"18692","city_name":"Finning","state_id":"1356"},
    {"city_id":"18693","city_name":"Forstinning","state_id":"1356"},
    {"city_id":"18694","city_name":"Georgensgmund","state_id":"1356"},
    {"city_id":"18695","city_name":"Haldenwang","state_id":"1356"},
    {"city_id":"18696","city_name":"Heideck","state_id":"1356"},
    {"city_id":"18697","city_name":"Heimenkirch","state_id":"1356"},
    {"city_id":"18698","city_name":"Hergensweiler","state_id":"1356"},
    {"city_id":"18699","city_name":"Hirschau","state_id":"1356"},
    {"city_id":"18700","city_name":"Iffeldorf","state_id":"1356"},
    {"city_id":"18701","city_name":"Karlstein","state_id":"1356"},
    {"city_id":"18702","city_name":"Kleinheubach","state_id":"1356"},
    {"city_id":"18703","city_name":"Kleinwallstadt","state_id":"1356"},
    {"city_id":"18704","city_name":"Kotz","state_id":"1356"},
    {"city_id":"18705","city_name":"Krailling","state_id":"1356"},
    {"city_id":"18706","city_name":"Langenbach","state_id":"1356"},
    {"city_id":"18707","city_name":"Laudenbach","state_id":"1356"},
    {"city_id":"18708","city_name":"Leutershausen","state_id":"1356"},
    {"city_id":"18709","city_name":"Mammendorf","state_id":"1356"},
    {"city_id":"18710","city_name":"Mombris","state_id":"1356"},
    {"city_id":"18711","city_name":"Munnerstadt","state_id":"1356"},
    {"city_id":"18712","city_name":"Neu-Ulm","state_id":"1356"},
    {"city_id":"18713","city_name":"Niederlauer","state_id":"1356"},
    {"city_id":"18714","city_name":"Obernburg","state_id":"1356"},
    {"city_id":"18715","city_name":"Oberpfaffenhofen","state_id":"1356"},
    {"city_id":"18716","city_name":"Ostallgau","state_id":"1356"},
    {"city_id":"18717","city_name":"Prittriching","state_id":"1356"},
    {"city_id":"18718","city_name":"Prutting","state_id":"1356"},
    {"city_id":"18719","city_name":"Pullach","state_id":"1356"},
    {"city_id":"18720","city_name":"Putzbrunn","state_id":"1356"},
    {"city_id":"18721","city_name":"Randersacker","state_id":"1356"},
    {"city_id":"18722","city_name":"Rednitzhembach","state_id":"1356"},
    {"city_id":"18723","city_name":"Ronsberg","state_id":"1356"},
    {"city_id":"18724","city_name":"Ruckersdorf","state_id":"1356"},
    {"city_id":"18725","city_name":"Schaufling","state_id":"1356"},
    {"city_id":"18726","city_name":"Schonberg","state_id":"1356"},
    {"city_id":"18727","city_name":"Seefeld","state_id":"1356"},
    {"city_id":"18728","city_name":"Sengenthal","state_id":"1356"},
    {"city_id":"18729","city_name":"Sondheim v.d.Rhon","state_id":"1356"},
    {"city_id":"18730","city_name":"Steinwiesen","state_id":"1356"},
    {"city_id":"18731","city_name":"Stockheim","state_id":"1356"},
    {"city_id":"18732","city_name":"Tagmersheim","state_id":"1356"},
    {"city_id":"18733","city_name":"Uettingen","state_id":"1356"},
    {"city_id":"18734","city_name":"Unterfohring","state_id":"1356"},
    {"city_id":"18735","city_name":"Weibensberg","state_id":"1356"},
    {"city_id":"18736","city_name":"Wiesthal","state_id":"1356"},
    {"city_id":"18737","city_name":"Wildflecken","state_id":"1356"},
    {"city_id":"18738","city_name":"Wolfertschwenden","state_id":"1356"},
    {"city_id":"18739","city_name":"Zorneding","state_id":"1356"},
    {"city_id":"18740","city_name":"Abensberg","state_id":"1357"},
    {"city_id":"18741","city_name":"Aichach","state_id":"1357"},
    {"city_id":"18742","city_name":"Ainring","state_id":"1357"},
    {"city_id":"18743","city_name":"Altdorf","state_id":"1357"},
    {"city_id":"18744","city_name":"Altotting","state_id":"1357"},
    {"city_id":"18745","city_name":"Altusried","state_id":"1357"},
    {"city_id":"18746","city_name":"Alzenau","state_id":"1357"},
    {"city_id":"18747","city_name":"Amberg","state_id":"1357"},
    {"city_id":"18748","city_name":"Amerang","state_id":"1357"},
    {"city_id":"18749","city_name":"Ansbach","state_id":"1357"},
    {"city_id":"18750","city_name":"Aschaffenburg","state_id":"1357"},
    {"city_id":"18751","city_name":"Augsburg","state_id":"1357"},
    {"city_id":"18752","city_name":"Bad Aibling","state_id":"1357"},
    {"city_id":"18753","city_name":"Bad Kissingen","state_id":"1357"},
    {"city_id":"18754","city_name":"Bad Neustadt","state_id":"1357"},
    {"city_id":"18755","city_name":"Bad Reichenhall","state_id":"1357"},
    {"city_id":"18756","city_name":"Bad Tolz","state_id":"1357"},
    {"city_id":"18757","city_name":"Bad Windsheim","state_id":"1357"},
    {"city_id":"18758","city_name":"Bad Worishofen","state_id":"1357"},
    {"city_id":"18759","city_name":"Bamberg","state_id":"1357"},
    {"city_id":"18760","city_name":"Bayreuth","state_id":"1357"},
    {"city_id":"18761","city_name":"Bobingen","state_id":"1357"},
    {"city_id":"18762","city_name":"Bogen","state_id":"1357"},
    {"city_id":"18763","city_name":"Bruckmuhl","state_id":"1357"},
    {"city_id":"18764","city_name":"Buchloe","state_id":"1357"},
    {"city_id":"18765","city_name":"Burghausen","state_id":"1357"},
    {"city_id":"18766","city_name":"Burgkirchen","state_id":"1357"},
    {"city_id":"18767","city_name":"Burglengenfeld","state_id":"1357"},
    {"city_id":"18768","city_name":"Burgthann","state_id":"1357"},
    {"city_id":"18769","city_name":"Buttenheim","state_id":"1357"},
    {"city_id":"18770","city_name":"Cadolzburg","state_id":"1357"},
    {"city_id":"18771","city_name":"Castell","state_id":"1357"},
    {"city_id":"18772","city_name":"Cham","state_id":"1357"},
    {"city_id":"18773","city_name":"Coburg","state_id":"1357"},
    {"city_id":"18774","city_name":"Dachau","state_id":"1357"},
    {"city_id":"18775","city_name":"Deggendorf","state_id":"1357"},
    {"city_id":"18776","city_name":"DieBen","state_id":"1357"},
    {"city_id":"18777","city_name":"Diedorf","state_id":"1357"},
    {"city_id":"18778","city_name":"Dietmannsried","state_id":"1357"},
    {"city_id":"18779","city_name":"Dietramszell","state_id":"1357"},
    {"city_id":"18780","city_name":"Dillingen","state_id":"1357"},
    {"city_id":"18781","city_name":"Dingolfing","state_id":"1357"},
    {"city_id":"18782","city_name":"Dinkelsbuhl","state_id":"1357"},
    {"city_id":"18783","city_name":"Dombuhl","state_id":"1357"},
    {"city_id":"18784","city_name":"Donauworth","state_id":"1357"},
    {"city_id":"18785","city_name":"Dorfen","state_id":"1357"},
    {"city_id":"18786","city_name":"Ebersberg","state_id":"1357"},
    {"city_id":"18787","city_name":"Eching","state_id":"1357"},
    {"city_id":"18788","city_name":"Eckental","state_id":"1357"},
    {"city_id":"18789","city_name":"Eggenfelden","state_id":"1357"},
    {"city_id":"18790","city_name":"Eichenau","state_id":"1357"},
    {"city_id":"18791","city_name":"Eichstatt","state_id":"1357"},
    {"city_id":"18792","city_name":"Elchingen","state_id":"1357"},
    {"city_id":"18793","city_name":"Erding","state_id":"1357"},
    {"city_id":"18794","city_name":"Ergolding","state_id":"1357"},
    {"city_id":"18795","city_name":"Erlangen","state_id":"1357"},
    {"city_id":"18796","city_name":"Erlenbach","state_id":"1357"},
    {"city_id":"18797","city_name":"Essenbach","state_id":"1357"},
    {"city_id":"18798","city_name":"Feldafing","state_id":"1357"},
    {"city_id":"18799","city_name":"Feldkirchen-Westerham","state_id":"1357"},
    {"city_id":"18800","city_name":"Feucht","state_id":"1357"},
    {"city_id":"18801","city_name":"Feuchtwangen","state_id":"1357"},
    {"city_id":"18802","city_name":"Forchheim","state_id":"1357"},
    {"city_id":"18803","city_name":"Freilassing","state_id":"1357"},
    {"city_id":"18804","city_name":"Freising","state_id":"1357"},
    {"city_id":"18805","city_name":"Fridolfing","state_id":"1357"},
    {"city_id":"18806","city_name":"Friedberg","state_id":"1357"},
    {"city_id":"18807","city_name":"Furstenfeldbruck","state_id":"1357"},
    {"city_id":"18808","city_name":"Furth","state_id":"1357"},
    {"city_id":"18809","city_name":"Furth im Wald","state_id":"1357"},
    {"city_id":"18810","city_name":"Fussen","state_id":"1357"},
    {"city_id":"18811","city_name":"Gachenbach","state_id":"1357"},
    {"city_id":"18812","city_name":"Gaimersheim","state_id":"1357"},
    {"city_id":"18813","city_name":"Garching","state_id":"1357"},
    {"city_id":"18814","city_name":"Garmisch-Partenkirchen","state_id":"1357"},
    {"city_id":"18815","city_name":"Gauting","state_id":"1357"},
    {"city_id":"18816","city_name":"Gemunden","state_id":"1357"},
    {"city_id":"18817","city_name":"Geretsried","state_id":"1357"},
    {"city_id":"18818","city_name":"Germering","state_id":"1357"},
    {"city_id":"18819","city_name":"Gersthofen","state_id":"1357"},
    {"city_id":"18820","city_name":"Gilching","state_id":"1357"},
    {"city_id":"18821","city_name":"Goldbach","state_id":"1357"},
    {"city_id":"18822","city_name":"Grafelfing","state_id":"1357"},
    {"city_id":"18823","city_name":"Grafenau","state_id":"1357"},
    {"city_id":"18824","city_name":"Grafing","state_id":"1357"},
    {"city_id":"18825","city_name":"GroBostheim","state_id":"1357"},
    {"city_id":"18826","city_name":"Grobenstadt","state_id":"1357"},
    {"city_id":"18827","city_name":"Grobenzell","state_id":"1357"},
    {"city_id":"18828","city_name":"Grunwald","state_id":"1357"},
    {"city_id":"18829","city_name":"Gunzburg","state_id":"1357"},
    {"city_id":"18830","city_name":"Gunzenhausen","state_id":"1357"},
    {"city_id":"18831","city_name":"HaBfurt","state_id":"1357"},
    {"city_id":"18832","city_name":"Haar","state_id":"1357"},
    {"city_id":"18833","city_name":"Hammelburg","state_id":"1357"},
    {"city_id":"18834","city_name":"Hasloch","state_id":"1357"},
    {"city_id":"18835","city_name":"Hauzenberg","state_id":"1357"},
    {"city_id":"18836","city_name":"Helmbrechts","state_id":"1357"},
    {"city_id":"18837","city_name":"Henfenfeld","state_id":"1357"},
    {"city_id":"18838","city_name":"Hersbruck","state_id":"1357"},
    {"city_id":"18839","city_name":"Herzogenaurach","state_id":"1357"},
    {"city_id":"18840","city_name":"Hilpoltstein","state_id":"1357"},
    {"city_id":"18841","city_name":"Hirschaid","state_id":"1357"},
    {"city_id":"18842","city_name":"Hochstadt","state_id":"1357"},
    {"city_id":"18843","city_name":"Hof","state_id":"1357"},
    {"city_id":"18844","city_name":"Holzkirchen","state_id":"1357"},
    {"city_id":"18845","city_name":"Hosbach","state_id":"1357"},
    {"city_id":"18846","city_name":"Illertissen","state_id":"1357"},
    {"city_id":"18847","city_name":"Immenstadt","state_id":"1357"},
    {"city_id":"18848","city_name":"Ingolstadt","state_id":"1357"},
    {"city_id":"18849","city_name":"Inning","state_id":"1357"},
    {"city_id":"18850","city_name":"Ismaning","state_id":"1357"},
    {"city_id":"18851","city_name":"Karlsfeld","state_id":"1357"},
    {"city_id":"18852","city_name":"Karlstadt","state_id":"1357"},
    {"city_id":"18853","city_name":"Kaufbeuren","state_id":"1357"},
    {"city_id":"18854","city_name":"Kelheim","state_id":"1357"},
    {"city_id":"18855","city_name":"Kempten","state_id":"1357"},
    {"city_id":"18856","city_name":"Kiefersfelden","state_id":"1357"},
    {"city_id":"18857","city_name":"Kirchheim","state_id":"1357"},
    {"city_id":"18858","city_name":"Kissing","state_id":"1357"},
    {"city_id":"18859","city_name":"Kitzingen","state_id":"1357"},
    {"city_id":"18860","city_name":"Kleinostheim","state_id":"1357"},
    {"city_id":"18861","city_name":"Klingenberg","state_id":"1357"},
    {"city_id":"18862","city_name":"Kolbermoor","state_id":"1357"},
    {"city_id":"18863","city_name":"Konigsbrunn","state_id":"1357"},
    {"city_id":"18864","city_name":"Kreuzwertheim","state_id":"1357"},
    {"city_id":"18865","city_name":"Kronach","state_id":"1357"},
    {"city_id":"18866","city_name":"Krumbach","state_id":"1357"},
    {"city_id":"18867","city_name":"Kulmbach","state_id":"1357"},
    {"city_id":"18868","city_name":"Kummersbruck","state_id":"1357"},
    {"city_id":"18869","city_name":"Landau","state_id":"1357"},
    {"city_id":"18870","city_name":"Landsberg","state_id":"1357"},
    {"city_id":"18871","city_name":"Landshut","state_id":"1357"},
    {"city_id":"18872","city_name":"Langenzenn","state_id":"1357"},
    {"city_id":"18873","city_name":"Lappersdorf","state_id":"1357"},
    {"city_id":"18874","city_name":"Lauf","state_id":"1357"},
    {"city_id":"18875","city_name":"Lauingen","state_id":"1357"},
    {"city_id":"18876","city_name":"Leinburg","state_id":"1357"},
    {"city_id":"18877","city_name":"Lenggries","state_id":"1357"},
    {"city_id":"18878","city_name":"Lichtenfels","state_id":"1357"},
    {"city_id":"18879","city_name":"Lindach","state_id":"1357"},
    {"city_id":"18880","city_name":"Lindau","state_id":"1357"},
    {"city_id":"18881","city_name":"Lindenberg","state_id":"1357"},
    {"city_id":"18882","city_name":"Lohr","state_id":"1357"},
    {"city_id":"18883","city_name":"Mainburg","state_id":"1357"},
    {"city_id":"18884","city_name":"Maisach","state_id":"1357"},
    {"city_id":"18885","city_name":"Manching","state_id":"1357"},
    {"city_id":"18886","city_name":"Markt Schwaben","state_id":"1357"},
    {"city_id":"18887","city_name":"Marktheidenfeld","state_id":"1357"},
    {"city_id":"18888","city_name":"Marktoberdorf","state_id":"1357"},
    {"city_id":"18889","city_name":"Marktredwitz","state_id":"1357"},
    {"city_id":"18890","city_name":"Maxhutte-Haidhof","state_id":"1357"},
    {"city_id":"18891","city_name":"Meitingen","state_id":"1357"},
    {"city_id":"18892","city_name":"Memmingen","state_id":"1357"},
    {"city_id":"18893","city_name":"Mering","state_id":"1357"},
    {"city_id":"18894","city_name":"Miesbach","state_id":"1357"},
    {"city_id":"18895","city_name":"Miltenberg","state_id":"1357"},
    {"city_id":"18896","city_name":"Mindelheim","state_id":"1357"},
    {"city_id":"18897","city_name":"Moosburg","state_id":"1357"},
    {"city_id":"18898","city_name":"Muhldorf","state_id":"1357"},
    {"city_id":"18899","city_name":"Munchberg","state_id":"1357"},
    {"city_id":"18900","city_name":"Munchen","state_id":"1357"},
    {"city_id":"18901","city_name":"Munningen","state_id":"1357"},
    {"city_id":"18902","city_name":"Murnau","state_id":"1357"},
    {"city_id":"18903","city_name":"Naila","state_id":"1357"},
    {"city_id":"18904","city_name":"Neubiberg","state_id":"1357"},
    {"city_id":"18905","city_name":"Neuburg","state_id":"1357"},
    {"city_id":"18906","city_name":"Neufahrn","state_id":"1357"},
    {"city_id":"18907","city_name":"Neumarkt","state_id":"1357"},
    {"city_id":"18908","city_name":"Neuried","state_id":"1357"},
    {"city_id":"18909","city_name":"NeusaB","state_id":"1357"},
    {"city_id":"18910","city_name":"Neustadt","state_id":"1357"},
    {"city_id":"18911","city_name":"Neutraubling","state_id":"1357"},
    {"city_id":"18912","city_name":"Nordlingen","state_id":"1357"},
    {"city_id":"18913","city_name":"Nuremberg","state_id":"1357"},
    {"city_id":"18914","city_name":"Nurnberg","state_id":"1357"},
    {"city_id":"18915","city_name":"Oberasbach","state_id":"1357"},
    {"city_id":"18916","city_name":"Oberhaching","state_id":"1357"},
    {"city_id":"18917","city_name":"OberschleiBheim","state_id":"1357"},
    {"city_id":"18918","city_name":"Oberstdorf","state_id":"1357"},
    {"city_id":"18919","city_name":"Ochsenfurt","state_id":"1357"},
    {"city_id":"18920","city_name":"Olching","state_id":"1357"},
    {"city_id":"18921","city_name":"Osterhofen","state_id":"1357"},
    {"city_id":"18922","city_name":"Ostheim","state_id":"1357"},
    {"city_id":"18923","city_name":"Ottobrunn","state_id":"1357"},
    {"city_id":"18924","city_name":"Parsberg","state_id":"1357"},
    {"city_id":"18925","city_name":"Passau","state_id":"1357"},
    {"city_id":"18926","city_name":"Pegnitz","state_id":"1357"},
    {"city_id":"18927","city_name":"PeiBenberg","state_id":"1357"},
    {"city_id":"18928","city_name":"Peiting","state_id":"1357"},
    {"city_id":"18929","city_name":"Pentling","state_id":"1357"},
    {"city_id":"18930","city_name":"Penzberg","state_id":"1357"},
    {"city_id":"18931","city_name":"Pfaffenhofen","state_id":"1357"},
    {"city_id":"18932","city_name":"Pfarrkirchen","state_id":"1357"},
    {"city_id":"18933","city_name":"Planegg","state_id":"1357"},
    {"city_id":"18934","city_name":"Plattling","state_id":"1357"},
    {"city_id":"18935","city_name":"Pocking","state_id":"1357"},
    {"city_id":"18936","city_name":"Poing","state_id":"1357"},
    {"city_id":"18937","city_name":"Polling","state_id":"1357"},
    {"city_id":"18938","city_name":"Pommelsbrunn","state_id":"1357"},
    {"city_id":"18939","city_name":"Prien","state_id":"1357"},
    {"city_id":"18940","city_name":"Puchheim","state_id":"1357"},
    {"city_id":"18941","city_name":"Rannersdorf","state_id":"1357"},
    {"city_id":"18942","city_name":"Raubling","state_id":"1357"},
    {"city_id":"18943","city_name":"Regen","state_id":"1357"},
    {"city_id":"18944","city_name":"Regensburg","state_id":"1357"},
    {"city_id":"18945","city_name":"Regenstauf","state_id":"1357"},
    {"city_id":"18946","city_name":"Rehau","state_id":"1357"},
    {"city_id":"18947","city_name":"Rodental","state_id":"1357"},
    {"city_id":"18948","city_name":"Roding","state_id":"1357"},
    {"city_id":"18949","city_name":"Rosenheim","state_id":"1357"},
    {"city_id":"18950","city_name":"Roth","state_id":"1357"},
    {"city_id":"18951","city_name":"Rothenbach","state_id":"1357"},
    {"city_id":"18952","city_name":"Rothenburg","state_id":"1357"},
    {"city_id":"18953","city_name":"Ruhstorf","state_id":"1357"},
    {"city_id":"18954","city_name":"Schnelldorf","state_id":"1357"},
    {"city_id":"18955","city_name":"Schongau","state_id":"1357"},
    {"city_id":"18956","city_name":"Schrobenhausen","state_id":"1357"},
    {"city_id":"18957","city_name":"Schwabach","state_id":"1357"},
    {"city_id":"18958","city_name":"Schwabmunchen","state_id":"1357"},
    {"city_id":"18959","city_name":"Schwandorf","state_id":"1357"},
    {"city_id":"18960","city_name":"Schwarzenbach","state_id":"1357"},
    {"city_id":"18961","city_name":"Schweinfurt","state_id":"1357"},
    {"city_id":"18962","city_name":"Selb","state_id":"1357"},
    {"city_id":"18963","city_name":"Selbitz","state_id":"1357"},
    {"city_id":"18964","city_name":"Senden","state_id":"1357"},
    {"city_id":"18965","city_name":"Simbach","state_id":"1357"},
    {"city_id":"18966","city_name":"Sonthofen","state_id":"1357"},
    {"city_id":"18967","city_name":"Spalt","state_id":"1357"},
    {"city_id":"18968","city_name":"Stadtbergen","state_id":"1357"},
    {"city_id":"18969","city_name":"Staffelstein","state_id":"1357"},
    {"city_id":"18970","city_name":"Starnberg","state_id":"1357"},
    {"city_id":"18971","city_name":"Stein","state_id":"1357"},
    {"city_id":"18972","city_name":"Stephanskirchen","state_id":"1357"},
    {"city_id":"18973","city_name":"Straubing","state_id":"1357"},
    {"city_id":"18974","city_name":"Sulzbach-Rosenberg","state_id":"1357"},
    {"city_id":"18975","city_name":"Taufkirchen","state_id":"1357"},
    {"city_id":"18976","city_name":"Thansau","state_id":"1357"},
    {"city_id":"18977","city_name":"Tirschenreuth","state_id":"1357"},
    {"city_id":"18978","city_name":"Toging","state_id":"1357"},
    {"city_id":"18979","city_name":"Traunreut","state_id":"1357"},
    {"city_id":"18980","city_name":"Traunstein","state_id":"1357"},
    {"city_id":"18981","city_name":"Treuchtlingen","state_id":"1357"},
    {"city_id":"18982","city_name":"Trostberg","state_id":"1357"},
    {"city_id":"18983","city_name":"Tutzing","state_id":"1357"},
    {"city_id":"18984","city_name":"Unterbergen","state_id":"1357"},
    {"city_id":"18985","city_name":"Unterhaching","state_id":"1357"},
    {"city_id":"18986","city_name":"UnterschleiBheim","state_id":"1357"},
    {"city_id":"18987","city_name":"Valley","state_id":"1357"},
    {"city_id":"18988","city_name":"Vaterstetten","state_id":"1357"},
    {"city_id":"18989","city_name":"Veitshochheim","state_id":"1357"},
    {"city_id":"18990","city_name":"Viechtach","state_id":"1357"},
    {"city_id":"18991","city_name":"Vilgertshofen","state_id":"1357"},
    {"city_id":"18992","city_name":"Vilsbiburg","state_id":"1357"},
    {"city_id":"18993","city_name":"Vilshofen","state_id":"1357"},
    {"city_id":"18994","city_name":"Vohringen","state_id":"1357"},
    {"city_id":"18995","city_name":"Volkach","state_id":"1357"},
    {"city_id":"18996","city_name":"Waldkirchen","state_id":"1357"},
    {"city_id":"18997","city_name":"Waldkraiburg","state_id":"1357"},
    {"city_id":"18998","city_name":"Walkertshofen","state_id":"1357"},
    {"city_id":"18999","city_name":"Wasserburg","state_id":"1357"},
    {"city_id":"19000","city_name":"WeiBenburg","state_id":"1357"},
    {"city_id":"19001","city_name":"WeiBenhorn","state_id":"1357"},
    {"city_id":"19002","city_name":"Weiden","state_id":"1357"},
    {"city_id":"19003","city_name":"Weidenberg","state_id":"1357"},
    {"city_id":"19004","city_name":"Weilheim","state_id":"1357"},
    {"city_id":"19005","city_name":"Wendelstein","state_id":"1357"},
    {"city_id":"19006","city_name":"Werneck","state_id":"1357"},
    {"city_id":"19007","city_name":"Wessling","state_id":"1357"},
    {"city_id":"19008","city_name":"Wolfratshausen","state_id":"1357"},
    {"city_id":"19009","city_name":"Wolnzach","state_id":"1357"},
    {"city_id":"19010","city_name":"Wunsiedel","state_id":"1357"},
    {"city_id":"19011","city_name":"Wurzburg","state_id":"1357"},
    {"city_id":"19012","city_name":"Zirndorf","state_id":"1357"},
    {"city_id":"19013","city_name":"Zwiesel","state_id":"1357"},
    {"city_id":"19014","city_name":"Berlin","state_id":"1359"},
    {"city_id":"19015","city_name":"Panketal","state_id":"1359"},
    {"city_id":"19016","city_name":"Steinfeld","state_id":"1359"},
    {"city_id":"19017","city_name":"Angermunde","state_id":"1360"},
    {"city_id":"19018","city_name":"Bad Freienwalde","state_id":"1360"},
    {"city_id":"19019","city_name":"Bad Liebenwerda","state_id":"1360"},
    {"city_id":"19020","city_name":"Barnim","state_id":"1360"},
    {"city_id":"19021","city_name":"Beeskow","state_id":"1360"},
    {"city_id":"19022","city_name":"Bernau","state_id":"1360"},
    {"city_id":"19023","city_name":"Blankenfelde","state_id":"1360"},
    {"city_id":"19024","city_name":"Brandenburg","state_id":"1360"},
    {"city_id":"19025","city_name":"Brieselang","state_id":"1360"},
    {"city_id":"19026","city_name":"Cottbus","state_id":"1360"},
    {"city_id":"19027","city_name":"Dahlewitz","state_id":"1360"},
    {"city_id":"19028","city_name":"Dahme","state_id":"1360"},
    {"city_id":"19029","city_name":"Eberswalde","state_id":"1360"},
    {"city_id":"19030","city_name":"Eisenhuttenstadt","state_id":"1360"},
    {"city_id":"19031","city_name":"Elsterwerda","state_id":"1360"},
    {"city_id":"19032","city_name":"Erkner","state_id":"1360"},
    {"city_id":"19033","city_name":"Falkensee","state_id":"1360"},
    {"city_id":"19034","city_name":"Finsterwalde","state_id":"1360"},
    {"city_id":"19035","city_name":"Forst","state_id":"1360"},
    {"city_id":"19036","city_name":"Frankfurt","state_id":"1360"},
    {"city_id":"19037","city_name":"Fredersdorf-Vogelsdorf","state_id":"1360"},
    {"city_id":"19038","city_name":"Furstenwalde","state_id":"1360"},
    {"city_id":"19039","city_name":"Glienicke","state_id":"1360"},
    {"city_id":"19040","city_name":"Gransee","state_id":"1360"},
    {"city_id":"19041","city_name":"GroBraschen","state_id":"1360"},
    {"city_id":"19042","city_name":"Guben","state_id":"1360"},
    {"city_id":"19043","city_name":"Heidesee","state_id":"1360"},
    {"city_id":"19044","city_name":"Hennigsdorf","state_id":"1360"},
    {"city_id":"19045","city_name":"Herzberg","state_id":"1360"},
    {"city_id":"19046","city_name":"Hohen Neuendorf","state_id":"1360"},
    {"city_id":"19047","city_name":"Jacobsdorf","state_id":"1360"},
    {"city_id":"19048","city_name":"Juterbog","state_id":"1360"},
    {"city_id":"19049","city_name":"Kleinmachnow","state_id":"1360"},
    {"city_id":"19050","city_name":"Kolkwitz","state_id":"1360"},
    {"city_id":"19051","city_name":"Konigs Wusterhausen","state_id":"1360"},
    {"city_id":"19052","city_name":"Kyritz","state_id":"1360"},
    {"city_id":"19053","city_name":"Lauchhammer","state_id":"1360"},
    {"city_id":"19054","city_name":"Lubben","state_id":"1360"},
    {"city_id":"19055","city_name":"Lubbenau","state_id":"1360"},
    {"city_id":"19056","city_name":"Luckenwalde","state_id":"1360"},
    {"city_id":"19057","city_name":"Ludwigsfelde","state_id":"1360"},
    {"city_id":"19058","city_name":"Nauen","state_id":"1360"},
    {"city_id":"19059","city_name":"Neuenhagen","state_id":"1360"},
    {"city_id":"19060","city_name":"Neuruppin","state_id":"1360"},
    {"city_id":"19061","city_name":"Oranienburg","state_id":"1360"},
    {"city_id":"19062","city_name":"Perleberg","state_id":"1360"},
    {"city_id":"19063","city_name":"Petershagen-Eggersdorf","state_id":"1360"},
    {"city_id":"19064","city_name":"Potsdam","state_id":"1360"},
    {"city_id":"19065","city_name":"Premnitz","state_id":"1360"},
    {"city_id":"19066","city_name":"Prenzlau","state_id":"1360"},
    {"city_id":"19067","city_name":"Pritzwalk","state_id":"1360"},
    {"city_id":"19068","city_name":"Rathenow","state_id":"1360"},
    {"city_id":"19069","city_name":"Rudersdorf","state_id":"1360"},
    {"city_id":"19070","city_name":"Schonefeld","state_id":"1360"},
    {"city_id":"19071","city_name":"Schoneiche","state_id":"1360"},
    {"city_id":"19072","city_name":"Schwedt","state_id":"1360"},
    {"city_id":"19073","city_name":"Schwielowsee","state_id":"1360"},
    {"city_id":"19074","city_name":"Senftenberg","state_id":"1360"},
    {"city_id":"19075","city_name":"Spremberg","state_id":"1360"},
    {"city_id":"19076","city_name":"Strausberg","state_id":"1360"},
    {"city_id":"19077","city_name":"Teltow","state_id":"1360"},
    {"city_id":"19078","city_name":"Templin","state_id":"1360"},
    {"city_id":"19079","city_name":"Velten","state_id":"1360"},
    {"city_id":"19080","city_name":"Werder","state_id":"1360"},
    {"city_id":"19081","city_name":"Wildau","state_id":"1360"},
    {"city_id":"19082","city_name":"Wittenberge","state_id":"1360"},
    {"city_id":"19083","city_name":"Wittstock","state_id":"1360"},
    {"city_id":"19084","city_name":"Zehdenick","state_id":"1360"},
    {"city_id":"19085","city_name":"Zepernick","state_id":"1360"},
    {"city_id":"19086","city_name":"Bremen","state_id":"1361"},
    {"city_id":"19087","city_name":"Bremerhaven","state_id":"1361"},
    {"city_id":"19088","city_name":"Berne","state_id":"1364"},
    {"city_id":"19089","city_name":"Hamburg","state_id":"1364"},
    {"city_id":"19090","city_name":"Diekholzen","state_id":"1365"},
    {"city_id":"19091","city_name":"Heroldstatt","state_id":"1366"},
    {"city_id":"19092","city_name":"ABlar","state_id":"1367"},
    {"city_id":"19093","city_name":"Alsbach-HÃƒÂ¤hnlein","state_id":"1367"},
    {"city_id":"19094","city_name":"Alsfeld","state_id":"1367"},
    {"city_id":"19095","city_name":"Altenstadt","state_id":"1367"},
    {"city_id":"19096","city_name":"Angelburg","state_id":"1367"},
    {"city_id":"19097","city_name":"Arolsen","state_id":"1367"},
    {"city_id":"19098","city_name":"Asslar","state_id":"1367"},
    {"city_id":"19099","city_name":"Babenhausen","state_id":"1367"},
    {"city_id":"19100","city_name":"Bad Camberg","state_id":"1367"},
    {"city_id":"19101","city_name":"Bad Hersfeld","state_id":"1367"},
    {"city_id":"19102","city_name":"Bad Homburg","state_id":"1367"},
    {"city_id":"19103","city_name":"Bad Nauheim","state_id":"1367"},
    {"city_id":"19104","city_name":"Bad Orb","state_id":"1367"},
    {"city_id":"19105","city_name":"Bad Schwalbach","state_id":"1367"},
    {"city_id":"19106","city_name":"Bad Soden","state_id":"1367"},
    {"city_id":"19107","city_name":"Bad Soden-Salmunster","state_id":"1367"},
    {"city_id":"19108","city_name":"Bad Sooden-Allendorf","state_id":"1367"},
    {"city_id":"19109","city_name":"Bad Vilbel","state_id":"1367"},
    {"city_id":"19110","city_name":"Bad Wildungen","state_id":"1367"},
    {"city_id":"19111","city_name":"Baunatal","state_id":"1367"},
    {"city_id":"19112","city_name":"Bebra","state_id":"1367"},
    {"city_id":"19113","city_name":"Beerfelden","state_id":"1367"},
    {"city_id":"19114","city_name":"Bensheim","state_id":"1367"},
    {"city_id":"19115","city_name":"Berkatal","state_id":"1367"},
    {"city_id":"19116","city_name":"Biblis","state_id":"1367"},
    {"city_id":"19117","city_name":"Bickenbach","state_id":"1367"},
    {"city_id":"19118","city_name":"Biebertal","state_id":"1367"},
    {"city_id":"19119","city_name":"Biedenkopf","state_id":"1367"},
    {"city_id":"19120","city_name":"Birkenau","state_id":"1367"},
    {"city_id":"19121","city_name":"Bischofsheim","state_id":"1367"},
    {"city_id":"19122","city_name":"Borken","state_id":"1367"},
    {"city_id":"19123","city_name":"Braunfels","state_id":"1367"},
    {"city_id":"19124","city_name":"Breidenbach","state_id":"1367"},
    {"city_id":"19125","city_name":"Bruchkobel","state_id":"1367"},
    {"city_id":"19126","city_name":"Budingen","state_id":"1367"},
    {"city_id":"19127","city_name":"Burstadt","state_id":"1367"},
    {"city_id":"19128","city_name":"Buseck","state_id":"1367"},
    {"city_id":"19129","city_name":"Buttelborn","state_id":"1367"},
    {"city_id":"19130","city_name":"Butzbach","state_id":"1367"},
    {"city_id":"19131","city_name":"Darmstadt","state_id":"1367"},
    {"city_id":"19132","city_name":"Dautphetal","state_id":"1367"},
    {"city_id":"19133","city_name":"Dieburg","state_id":"1367"},
    {"city_id":"19134","city_name":"Dietzenbach","state_id":"1367"},
    {"city_id":"19135","city_name":"Dillenburg","state_id":"1367"},
    {"city_id":"19136","city_name":"Dreieich","state_id":"1367"},
    {"city_id":"19137","city_name":"Egelsbach","state_id":"1367"},
    {"city_id":"19138","city_name":"Eichenzell","state_id":"1367"},
    {"city_id":"19139","city_name":"Eltville","state_id":"1367"},
    {"city_id":"19140","city_name":"Eppstein","state_id":"1367"},
    {"city_id":"19141","city_name":"Erbach","state_id":"1367"},
    {"city_id":"19142","city_name":"Erlensee","state_id":"1367"},
    {"city_id":"19143","city_name":"Erzhausen","state_id":"1367"},
    {"city_id":"19144","city_name":"Eschborn","state_id":"1367"},
    {"city_id":"19145","city_name":"Eschenburg","state_id":"1367"},
    {"city_id":"19146","city_name":"Eschwege","state_id":"1367"},
    {"city_id":"19147","city_name":"Felsberg","state_id":"1367"},
    {"city_id":"19148","city_name":"Fernwald","state_id":"1367"},
    {"city_id":"19149","city_name":"Florsheim","state_id":"1367"},
    {"city_id":"19150","city_name":"Frankenberg","state_id":"1367"},
    {"city_id":"19151","city_name":"Frankfurt","state_id":"1367"},
    {"city_id":"19152","city_name":"Freigericht","state_id":"1367"},
    {"city_id":"19153","city_name":"Friedberg","state_id":"1367"},
    {"city_id":"19154","city_name":"Friedrichsdorf","state_id":"1367"},
    {"city_id":"19155","city_name":"Fritzlar","state_id":"1367"},
    {"city_id":"19156","city_name":"Fulda","state_id":"1367"},
    {"city_id":"19157","city_name":"Fuldabruck","state_id":"1367"},
    {"city_id":"19158","city_name":"Fuldatal","state_id":"1367"},
    {"city_id":"19159","city_name":"Geisenheim","state_id":"1367"},
    {"city_id":"19160","city_name":"Gelnhausen","state_id":"1367"},
    {"city_id":"19161","city_name":"Gernsheim","state_id":"1367"},
    {"city_id":"19162","city_name":"GieBen","state_id":"1367"},
    {"city_id":"19163","city_name":"Giessen","state_id":"1367"},
    {"city_id":"19164","city_name":"Ginsheim-Gustavsburg","state_id":"1367"},
    {"city_id":"19165","city_name":"Gladenbach","state_id":"1367"},
    {"city_id":"19166","city_name":"Griesheim","state_id":"1367"},
    {"city_id":"19167","city_name":"GroB-Gerau","state_id":"1367"},
    {"city_id":"19168","city_name":"GroB-Umstadt","state_id":"1367"},
    {"city_id":"19169","city_name":"GroB-Zimmern","state_id":"1367"},
    {"city_id":"19170","city_name":"Grossenluder","state_id":"1367"},
    {"city_id":"19171","city_name":"Gruenberg","state_id":"1367"},
    {"city_id":"19172","city_name":"Grunberg","state_id":"1367"},
    {"city_id":"19173","city_name":"Grundau","state_id":"1367"},
    {"city_id":"19174","city_name":"Hadamar","state_id":"1367"},
    {"city_id":"19175","city_name":"Haiger","state_id":"1367"},
    {"city_id":"19176","city_name":"Hainburg","state_id":"1367"},
    {"city_id":"19177","city_name":"Hanau","state_id":"1367"},
    {"city_id":"19178","city_name":"Hattersheim","state_id":"1367"},
    {"city_id":"19179","city_name":"Heppenheim","state_id":"1367"},
    {"city_id":"19180","city_name":"Herborn","state_id":"1367"},
    {"city_id":"19181","city_name":"Hessisch Lichtenau","state_id":"1367"},
    {"city_id":"19182","city_name":"Heuchelheim","state_id":"1367"},
    {"city_id":"19183","city_name":"Heusenstamm","state_id":"1367"},
    {"city_id":"19184","city_name":"Hochheim","state_id":"1367"},
    {"city_id":"19185","city_name":"Hochst","state_id":"1367"},
    {"city_id":"19186","city_name":"Hofbieber","state_id":"1367"},
    {"city_id":"19187","city_name":"Hofgeismar","state_id":"1367"},
    {"city_id":"19188","city_name":"Hofheim","state_id":"1367"},
    {"city_id":"19189","city_name":"Homberg","state_id":"1367"},
    {"city_id":"19190","city_name":"Hunfeld","state_id":"1367"},
    {"city_id":"19191","city_name":"Hunfelden","state_id":"1367"},
    {"city_id":"19192","city_name":"Hungen","state_id":"1367"},
    {"city_id":"19193","city_name":"Huttenberg","state_id":"1367"},
    {"city_id":"19194","city_name":"Idstein","state_id":"1367"},
    {"city_id":"19195","city_name":"Karben","state_id":"1367"},
    {"city_id":"19196","city_name":"Kassel","state_id":"1367"},
    {"city_id":"19197","city_name":"Kaufungen","state_id":"1367"},
    {"city_id":"19198","city_name":"Kelkheim","state_id":"1367"},
    {"city_id":"19199","city_name":"Kelsterbach","state_id":"1367"},
    {"city_id":"19200","city_name":"Kirchhain","state_id":"1367"},
    {"city_id":"19201","city_name":"Konigstein","state_id":"1367"},
    {"city_id":"19202","city_name":"Korbach","state_id":"1367"},
    {"city_id":"19203","city_name":"Kriftel","state_id":"1367"},
    {"city_id":"19204","city_name":"Kronberg","state_id":"1367"},
    {"city_id":"19205","city_name":"Kunzell","state_id":"1367"},
    {"city_id":"19206","city_name":"Lahnau","state_id":"1367"},
    {"city_id":"19207","city_name":"Lahntal","state_id":"1367"},
    {"city_id":"19208","city_name":"Lampertheim","state_id":"1367"},
    {"city_id":"19209","city_name":"Langen","state_id":"1367"},
    {"city_id":"19210","city_name":"Langenselbold","state_id":"1367"},
    {"city_id":"19211","city_name":"Langgons","state_id":"1367"},
    {"city_id":"19212","city_name":"Laubach","state_id":"1367"},
    {"city_id":"19213","city_name":"Lauterbach","state_id":"1367"},
    {"city_id":"19214","city_name":"Lich","state_id":"1367"},
    {"city_id":"19215","city_name":"Limburg","state_id":"1367"},
    {"city_id":"19216","city_name":"Linden","state_id":"1367"},
    {"city_id":"19217","city_name":"Lindenholzhausen","state_id":"1367"},
    {"city_id":"19218","city_name":"Linsengericht","state_id":"1367"},
    {"city_id":"19219","city_name":"Lohfelden","state_id":"1367"},
    {"city_id":"19220","city_name":"Lollar","state_id":"1367"},
    {"city_id":"19221","city_name":"Lorsch","state_id":"1367"},
    {"city_id":"19222","city_name":"Maintal","state_id":"1367"},
    {"city_id":"19223","city_name":"Marburg","state_id":"1367"},
    {"city_id":"19224","city_name":"Melsungen","state_id":"1367"},
    {"city_id":"19225","city_name":"Mengerskirchen","state_id":"1367"},
    {"city_id":"19226","city_name":"Michelstadt","state_id":"1367"},
    {"city_id":"19227","city_name":"Morfelden-Walldorf","state_id":"1367"},
    {"city_id":"19228","city_name":"Morlenbach","state_id":"1367"},
    {"city_id":"19229","city_name":"Mucke","state_id":"1367"},
    {"city_id":"19230","city_name":"Muhlheim","state_id":"1367"},
    {"city_id":"19231","city_name":"Muhltal","state_id":"1367"},
    {"city_id":"19232","city_name":"Munster","state_id":"1367"},
    {"city_id":"19233","city_name":"Nauheim","state_id":"1367"},
    {"city_id":"19234","city_name":"Neu-Anspach","state_id":"1367"},
    {"city_id":"19235","city_name":"Neu-Isenburg","state_id":"1367"},
    {"city_id":"19236","city_name":"Neuhof","state_id":"1367"},
    {"city_id":"19237","city_name":"Neustadt","state_id":"1367"},
    {"city_id":"19238","city_name":"Nidda","state_id":"1367"},
    {"city_id":"19239","city_name":"Niddatal","state_id":"1367"},
    {"city_id":"19240","city_name":"Nidderau","state_id":"1367"},
    {"city_id":"19241","city_name":"Niederdorfelden","state_id":"1367"},
    {"city_id":"19242","city_name":"Niedernhausen","state_id":"1367"},
    {"city_id":"19243","city_name":"Niestetal","state_id":"1367"},
    {"city_id":"19244","city_name":"Ober-Ramstedt","state_id":"1367"},
    {"city_id":"19245","city_name":"Obertshausen","state_id":"1367"},
    {"city_id":"19246","city_name":"Oberursel","state_id":"1367"},
    {"city_id":"19247","city_name":"Oestrich-Winkel","state_id":"1367"},
    {"city_id":"19248","city_name":"Offenbach","state_id":"1367"},
    {"city_id":"19249","city_name":"Petersberg","state_id":"1367"},
    {"city_id":"19250","city_name":"Pfungstadt","state_id":"1367"},
    {"city_id":"19251","city_name":"Pohlheim","state_id":"1367"},
    {"city_id":"19252","city_name":"Raunheim","state_id":"1367"},
    {"city_id":"19253","city_name":"Reichelsheim","state_id":"1367"},
    {"city_id":"19254","city_name":"Reinheim","state_id":"1367"},
    {"city_id":"19255","city_name":"Reiskirchen","state_id":"1367"},
    {"city_id":"19256","city_name":"Riedstadt","state_id":"1367"},
    {"city_id":"19257","city_name":"Rimbach","state_id":"1367"},
    {"city_id":"19258","city_name":"RoBdorf","state_id":"1367"},
    {"city_id":"19259","city_name":"Rodenbach","state_id":"1367"},
    {"city_id":"19260","city_name":"Rodermark","state_id":"1367"},
    {"city_id":"19261","city_name":"Rodgau","state_id":"1367"},
    {"city_id":"19262","city_name":"Rosbach","state_id":"1367"},
    {"city_id":"19263","city_name":"Rotenburg","state_id":"1367"},
    {"city_id":"19264","city_name":"Rudesheim","state_id":"1367"},
    {"city_id":"19265","city_name":"Runkel","state_id":"1367"},
    {"city_id":"19266","city_name":"Russelsheim","state_id":"1367"},
    {"city_id":"19267","city_name":"Schauenburg","state_id":"1367"},
    {"city_id":"19268","city_name":"Schlangenbad","state_id":"1367"},
    {"city_id":"19269","city_name":"Schlitz","state_id":"1367"},
    {"city_id":"19270","city_name":"Schluchtern","state_id":"1367"},
    {"city_id":"19271","city_name":"Schoneck","state_id":"1367"},
    {"city_id":"19272","city_name":"Schotten","state_id":"1367"},
    {"city_id":"19273","city_name":"Schwalbach","state_id":"1367"},
    {"city_id":"19274","city_name":"Schwalbach am Taunus","state_id":"1367"},
    {"city_id":"19275","city_name":"Schwalmstadt","state_id":"1367"},
    {"city_id":"19276","city_name":"Seeheim-Jugenheim","state_id":"1367"},
    {"city_id":"19277","city_name":"Seligenstadt","state_id":"1367"},
    {"city_id":"19278","city_name":"Selters","state_id":"1367"},
    {"city_id":"19279","city_name":"Sinntal","state_id":"1367"},
    {"city_id":"19280","city_name":"Solms","state_id":"1367"},
    {"city_id":"19281","city_name":"Sontra","state_id":"1367"},
    {"city_id":"19282","city_name":"Spangenberg","state_id":"1367"},
    {"city_id":"19283","city_name":"Stadtallendorf","state_id":"1367"},
    {"city_id":"19284","city_name":"Steinau","state_id":"1367"},
    {"city_id":"19285","city_name":"Steinbach","state_id":"1367"},
    {"city_id":"19286","city_name":"Taunusstein","state_id":"1367"},
    {"city_id":"19287","city_name":"Trebur","state_id":"1367"},
    {"city_id":"19288","city_name":"Ulrichstein","state_id":"1367"},
    {"city_id":"19289","city_name":"Usingen","state_id":"1367"},
    {"city_id":"19290","city_name":"Vellmar","state_id":"1367"},
    {"city_id":"19291","city_name":"Viernheim","state_id":"1367"},
    {"city_id":"19292","city_name":"Volkmarsen","state_id":"1367"},
    {"city_id":"19293","city_name":"Wachtersbach","state_id":"1367"},
    {"city_id":"19294","city_name":"Wald-Michelbach","state_id":"1367"},
    {"city_id":"19295","city_name":"Waldbrunn","state_id":"1367"},
    {"city_id":"19296","city_name":"Waldems","state_id":"1367"},
    {"city_id":"19297","city_name":"Wehrheim","state_id":"1367"},
    {"city_id":"19298","city_name":"Weilburg","state_id":"1367"},
    {"city_id":"19299","city_name":"Weilmunster","state_id":"1367"},
    {"city_id":"19300","city_name":"Weiterstadt","state_id":"1367"},
    {"city_id":"19301","city_name":"Wettenberg","state_id":"1367"},
    {"city_id":"19302","city_name":"Wetter","state_id":"1367"},
    {"city_id":"19303","city_name":"Wetzlar","state_id":"1367"},
    {"city_id":"19304","city_name":"Wiesbaden","state_id":"1367"},
    {"city_id":"19305","city_name":"Witzenhausen","state_id":"1367"},
    {"city_id":"19306","city_name":"Wolfersheim","state_id":"1367"},
    {"city_id":"19307","city_name":"Wolfhagen","state_id":"1367"},
    {"city_id":"19308","city_name":"Zwingenberg","state_id":"1367"},
    {"city_id":"19309","city_name":"Kortenberg","state_id":"1368"},
    {"city_id":"19310","city_name":"Laasdorf","state_id":"1369"},
    {"city_id":"19311","city_name":"Anklam","state_id":"1381"},
    {"city_id":"19312","city_name":"Bad Doberan","state_id":"1381"},
    {"city_id":"19313","city_name":"Barth","state_id":"1381"},
    {"city_id":"19314","city_name":"Bergen","state_id":"1381"},
    {"city_id":"19315","city_name":"Boizenburg","state_id":"1381"},
    {"city_id":"19316","city_name":"Butzow","state_id":"1381"},
    {"city_id":"19317","city_name":"Demmin","state_id":"1381"},
    {"city_id":"19318","city_name":"Gagelow","state_id":"1381"},
    {"city_id":"19319","city_name":"Gallin","state_id":"1381"},
    {"city_id":"19320","city_name":"Grabow","state_id":"1381"},
    {"city_id":"19321","city_name":"Greifswald","state_id":"1381"},
    {"city_id":"19322","city_name":"Grevesmuhlen","state_id":"1381"},
    {"city_id":"19323","city_name":"Grimmen","state_id":"1381"},
    {"city_id":"19324","city_name":"Gustrow","state_id":"1381"},
    {"city_id":"19325","city_name":"Hagenow","state_id":"1381"},
    {"city_id":"19326","city_name":"Konigsee","state_id":"1381"},
    {"city_id":"19327","city_name":"Lubtheen","state_id":"1381"},
    {"city_id":"19328","city_name":"Ludersdorf","state_id":"1381"},
    {"city_id":"19329","city_name":"Ludwigslust","state_id":"1381"},
    {"city_id":"19330","city_name":"Malchin","state_id":"1381"},
    {"city_id":"19331","city_name":"Neubrandenburg","state_id":"1381"},
    {"city_id":"19332","city_name":"Neustrelitz","state_id":"1381"},
    {"city_id":"19333","city_name":"Parchim","state_id":"1381"},
    {"city_id":"19334","city_name":"Pasewalk","state_id":"1381"},
    {"city_id":"19335","city_name":"Ribnitz-Damgarten","state_id":"1381"},
    {"city_id":"19336","city_name":"Rostock","state_id":"1381"},
    {"city_id":"19337","city_name":"SaBnitz","state_id":"1381"},
    {"city_id":"19338","city_name":"Schweina","state_id":"1381"},
    {"city_id":"19339","city_name":"Schwerin","state_id":"1381"},
    {"city_id":"19340","city_name":"Selmsdorf","state_id":"1381"},
    {"city_id":"19341","city_name":"Stralsund","state_id":"1381"},
    {"city_id":"19342","city_name":"Teterow","state_id":"1381"},
    {"city_id":"19343","city_name":"Torgelow","state_id":"1381"},
    {"city_id":"19344","city_name":"Ueckermunde","state_id":"1381"},
    {"city_id":"19345","city_name":"Waren","state_id":"1381"},
    {"city_id":"19346","city_name":"Wismar","state_id":"1381"},
    {"city_id":"19347","city_name":"Wolgast","state_id":"1381"},
    {"city_id":"19348","city_name":"Zarrentin","state_id":"1381"},
    {"city_id":"19349","city_name":"Mulfingen","state_id":"1382"},
    {"city_id":"19350","city_name":"Grafing bei Munchen","state_id":"1383"},
    {"city_id":"19351","city_name":"Neubeuern","state_id":"1384"},
    {"city_id":"19352","city_name":"Achim","state_id":"1385"},
    {"city_id":"19353","city_name":"Adendorf","state_id":"1385"},
    {"city_id":"19354","city_name":"Aerzen","state_id":"1385"},
    {"city_id":"19355","city_name":"Alfeld","state_id":"1385"},
    {"city_id":"19356","city_name":"Ankum","state_id":"1385"},
    {"city_id":"19357","city_name":"Apen","state_id":"1385"},
    {"city_id":"19358","city_name":"Aurich","state_id":"1385"},
    {"city_id":"19359","city_name":"Bad Bentheim","state_id":"1385"},
    {"city_id":"19360","city_name":"Bad Bevensen","state_id":"1385"},
    {"city_id":"19361","city_name":"Bad Essen","state_id":"1385"},
    {"city_id":"19362","city_name":"Bad Gandersheim","state_id":"1385"},
    {"city_id":"19363","city_name":"Bad Harzburg","state_id":"1385"},
    {"city_id":"19364","city_name":"Bad Iburg","state_id":"1385"},
    {"city_id":"19365","city_name":"Bad Laer","state_id":"1385"},
    {"city_id":"19366","city_name":"Bad Lauterberg","state_id":"1385"},
    {"city_id":"19367","city_name":"Bad Munder","state_id":"1385"},
    {"city_id":"19368","city_name":"Bad Nenndorf","state_id":"1385"},
    {"city_id":"19369","city_name":"Bad Pyrmont","state_id":"1385"},
    {"city_id":"19370","city_name":"Bad Sachsa","state_id":"1385"},
    {"city_id":"19371","city_name":"Bad Salzdetfurth","state_id":"1385"},
    {"city_id":"19372","city_name":"Bad Zwischenahn","state_id":"1385"},
    {"city_id":"19373","city_name":"BarBel","state_id":"1385"},
    {"city_id":"19374","city_name":"Barsinghausen","state_id":"1385"},
    {"city_id":"19375","city_name":"Bassum","state_id":"1385"},
    {"city_id":"19376","city_name":"Beesten","state_id":"1385"},
    {"city_id":"19377","city_name":"Belm","state_id":"1385"},
    {"city_id":"19378","city_name":"Bergen","state_id":"1385"},
    {"city_id":"19379","city_name":"Bissendorf","state_id":"1385"},
    {"city_id":"19380","city_name":"Bleckede","state_id":"1385"},
    {"city_id":"19381","city_name":"Bockenem","state_id":"1385"},
    {"city_id":"19382","city_name":"Bohmte","state_id":"1385"},
    {"city_id":"19383","city_name":"Bovenden","state_id":"1385"},
    {"city_id":"19384","city_name":"Brake","state_id":"1385"},
    {"city_id":"19385","city_name":"Bramsche","state_id":"1385"},
    {"city_id":"19386","city_name":"Braunschweig","state_id":"1385"},
    {"city_id":"19387","city_name":"Bremervorde","state_id":"1385"},
    {"city_id":"19388","city_name":"Brockel","state_id":"1385"},
    {"city_id":"19389","city_name":"Brunswick","state_id":"1385"},
    {"city_id":"19390","city_name":"Buchholz","state_id":"1385"},
    {"city_id":"19391","city_name":"Buckeburg","state_id":"1385"},
    {"city_id":"19392","city_name":"Burgdorf","state_id":"1385"},
    {"city_id":"19393","city_name":"Burgwedel","state_id":"1385"},
    {"city_id":"19394","city_name":"Buxtehude","state_id":"1385"},
    {"city_id":"19395","city_name":"Celle","state_id":"1385"},
    {"city_id":"19396","city_name":"Clausthal-Zellerfeld","state_id":"1385"},
    {"city_id":"19397","city_name":"Clenze","state_id":"1385"},
    {"city_id":"19398","city_name":"Cloppenburg","state_id":"1385"},
    {"city_id":"19399","city_name":"Cremlingen","state_id":"1385"},
    {"city_id":"19400","city_name":"Cuxhaven","state_id":"1385"},
    {"city_id":"19401","city_name":"Dahlenburg","state_id":"1385"},
    {"city_id":"19402","city_name":"Damme","state_id":"1385"},
    {"city_id":"19403","city_name":"Dannenberg","state_id":"1385"},
    {"city_id":"19404","city_name":"Dassel","state_id":"1385"},
    {"city_id":"19405","city_name":"Deinste","state_id":"1385"},
    {"city_id":"19406","city_name":"Delmenhorst","state_id":"1385"},
    {"city_id":"19407","city_name":"Diepholz","state_id":"1385"},
    {"city_id":"19408","city_name":"Dinklage","state_id":"1385"},
    {"city_id":"19409","city_name":"Dorverden","state_id":"1385"},
    {"city_id":"19410","city_name":"Dransfeld","state_id":"1385"},
    {"city_id":"19411","city_name":"Drochtersen","state_id":"1385"},
    {"city_id":"19412","city_name":"Duderstadt","state_id":"1385"},
    {"city_id":"19413","city_name":"Edemissen","state_id":"1385"},
    {"city_id":"19414","city_name":"Edewecht","state_id":"1385"},
    {"city_id":"19415","city_name":"Einbeck","state_id":"1385"},
    {"city_id":"19416","city_name":"Elsfleth","state_id":"1385"},
    {"city_id":"19417","city_name":"Elze","state_id":"1385"},
    {"city_id":"19418","city_name":"Emden","state_id":"1385"},
    {"city_id":"19419","city_name":"Emlichheim","state_id":"1385"},
    {"city_id":"19420","city_name":"Emmerthal","state_id":"1385"},
    {"city_id":"19421","city_name":"Emsburen","state_id":"1385"},
    {"city_id":"19422","city_name":"Eppendorf","state_id":"1385"},
    {"city_id":"19423","city_name":"Fallingbostel","state_id":"1385"},
    {"city_id":"19424","city_name":"Frellstedt","state_id":"1385"},
    {"city_id":"19425","city_name":"Friedeburg","state_id":"1385"},
    {"city_id":"19426","city_name":"Friedland","state_id":"1385"},
    {"city_id":"19427","city_name":"Friesoythe","state_id":"1385"},
    {"city_id":"19428","city_name":"Ganderkesee","state_id":"1385"},
    {"city_id":"19429","city_name":"Garbsen","state_id":"1385"},
    {"city_id":"19430","city_name":"Garrel","state_id":"1385"},
    {"city_id":"19431","city_name":"Geeste","state_id":"1385"},
    {"city_id":"19432","city_name":"Gehrden","state_id":"1385"},
    {"city_id":"19433","city_name":"Georgsmarienhutte","state_id":"1385"},
    {"city_id":"19434","city_name":"Gifhorn","state_id":"1385"},
    {"city_id":"19435","city_name":"Gleichen","state_id":"1385"},
    {"city_id":"19436","city_name":"Goslar","state_id":"1385"},
    {"city_id":"19437","city_name":"Gottingen","state_id":"1385"},
    {"city_id":"19438","city_name":"GroBefehn","state_id":"1385"},
    {"city_id":"19439","city_name":"GroBenkneten","state_id":"1385"},
    {"city_id":"19440","city_name":"Hagen","state_id":"1385"},
    {"city_id":"19441","city_name":"Hambergen","state_id":"1385"},
    {"city_id":"19442","city_name":"Hameln","state_id":"1385"},
    {"city_id":"19443","city_name":"Hannover","state_id":"1385"},
    {"city_id":"19444","city_name":"Hannoversch Munden","state_id":"1385"},
    {"city_id":"19445","city_name":"Haren","state_id":"1385"},
    {"city_id":"19446","city_name":"Harsum","state_id":"1385"},
    {"city_id":"19447","city_name":"Hasbergen","state_id":"1385"},
    {"city_id":"19448","city_name":"Haselunne","state_id":"1385"},
    {"city_id":"19449","city_name":"Hatten","state_id":"1385"},
    {"city_id":"19450","city_name":"Hauslingen","state_id":"1385"},
    {"city_id":"19451","city_name":"Helmstedt","state_id":"1385"},
    {"city_id":"19452","city_name":"Hemmingen","state_id":"1385"},
    {"city_id":"19453","city_name":"Herzberg","state_id":"1385"},
    {"city_id":"19454","city_name":"Hessisch Oldendorf","state_id":"1385"},
    {"city_id":"19455","city_name":"Hildesheim","state_id":"1385"},
    {"city_id":"19456","city_name":"Hilter","state_id":"1385"},
    {"city_id":"19457","city_name":"Hittfeld","state_id":"1385"},
    {"city_id":"19458","city_name":"Holzminden","state_id":"1385"},
    {"city_id":"19459","city_name":"Hoya","state_id":"1385"},
    {"city_id":"19460","city_name":"Hude","state_id":"1385"},
    {"city_id":"19461","city_name":"Ihlow","state_id":"1385"},
    {"city_id":"19462","city_name":"Ilsede","state_id":"1385"},
    {"city_id":"19463","city_name":"Isernhagen","state_id":"1385"},
    {"city_id":"19464","city_name":"Jade","state_id":"1385"},
    {"city_id":"19465","city_name":"Jever","state_id":"1385"},
    {"city_id":"19466","city_name":"Jork","state_id":"1385"},
    {"city_id":"19467","city_name":"Kalefeld","state_id":"1385"},
    {"city_id":"19468","city_name":"Kirchlinteln","state_id":"1385"},
    {"city_id":"19469","city_name":"Knesebeck","state_id":"1385"},
    {"city_id":"19470","city_name":"Konigslutter","state_id":"1385"},
    {"city_id":"19471","city_name":"Krummhorn","state_id":"1385"},
    {"city_id":"19472","city_name":"Laatzen","state_id":"1385"},
    {"city_id":"19473","city_name":"Lahstedt","state_id":"1385"},
    {"city_id":"19474","city_name":"Langelsheim","state_id":"1385"},
    {"city_id":"19475","city_name":"Langen","state_id":"1385"},
    {"city_id":"19476","city_name":"Langenhagen","state_id":"1385"},
    {"city_id":"19477","city_name":"Langwedel","state_id":"1385"},
    {"city_id":"19478","city_name":"Lastrup","state_id":"1385"},
    {"city_id":"19479","city_name":"Leer","state_id":"1385"},
    {"city_id":"19480","city_name":"Lehre","state_id":"1385"},
    {"city_id":"19481","city_name":"Lehrte","state_id":"1385"},
    {"city_id":"19482","city_name":"Lemforde","state_id":"1385"},
    {"city_id":"19483","city_name":"Lengede","state_id":"1385"},
    {"city_id":"19484","city_name":"Liebenburg","state_id":"1385"},
    {"city_id":"19485","city_name":"Lilienthal","state_id":"1385"},
    {"city_id":"19486","city_name":"Lingen","state_id":"1385"},
    {"city_id":"19487","city_name":"Lohne","state_id":"1385"},
    {"city_id":"19488","city_name":"Loningen","state_id":"1385"},
    {"city_id":"19489","city_name":"Lorup","state_id":"1385"},
    {"city_id":"19490","city_name":"Loxstedt","state_id":"1385"},
    {"city_id":"19491","city_name":"Luchow","state_id":"1385"},
    {"city_id":"19492","city_name":"Luneburg","state_id":"1385"},
    {"city_id":"19493","city_name":"Melle","state_id":"1385"},
    {"city_id":"19494","city_name":"Meppen","state_id":"1385"},
    {"city_id":"19495","city_name":"Moormerland","state_id":"1385"},
    {"city_id":"19496","city_name":"Munster","state_id":"1385"},
    {"city_id":"19497","city_name":"Neu Wulmstorf","state_id":"1385"},
    {"city_id":"19498","city_name":"Neustadt","state_id":"1385"},
    {"city_id":"19499","city_name":"Nienburg","state_id":"1385"},
    {"city_id":"19500","city_name":"Norden","state_id":"1385"},
    {"city_id":"19501","city_name":"Nordenham","state_id":"1385"},
    {"city_id":"19502","city_name":"Nordhorn","state_id":"1385"},
    {"city_id":"19503","city_name":"Nordstemmen","state_id":"1385"},
    {"city_id":"19504","city_name":"Norten-Hardenberg","state_id":"1385"},
    {"city_id":"19505","city_name":"Northeim","state_id":"1385"},
    {"city_id":"19506","city_name":"Obernkirchen","state_id":"1385"},
    {"city_id":"19507","city_name":"Oldenburg","state_id":"1385"},
    {"city_id":"19508","city_name":"Osnabruck","state_id":"1385"},
    {"city_id":"19509","city_name":"Ostercappeln","state_id":"1385"},
    {"city_id":"19510","city_name":"Osterholz-Scharmbeck","state_id":"1385"},
    {"city_id":"19511","city_name":"Osterode","state_id":"1385"},
    {"city_id":"19512","city_name":"Ostrhauderfehn","state_id":"1385"},
    {"city_id":"19513","city_name":"Ottersberg","state_id":"1385"},
    {"city_id":"19514","city_name":"Oyten","state_id":"1385"},
    {"city_id":"19515","city_name":"Papenburg","state_id":"1385"},
    {"city_id":"19516","city_name":"Pattensen","state_id":"1385"},
    {"city_id":"19517","city_name":"Peine","state_id":"1385"},
    {"city_id":"19518","city_name":"Quakenbruck","state_id":"1385"},
    {"city_id":"19519","city_name":"Rastede","state_id":"1385"},
    {"city_id":"19520","city_name":"Rehburg-Loccum","state_id":"1385"},
    {"city_id":"19521","city_name":"Rhauderfehn","state_id":"1385"},
    {"city_id":"19522","city_name":"Rinteln","state_id":"1385"},
    {"city_id":"19523","city_name":"Ritterhude","state_id":"1385"},
    {"city_id":"19524","city_name":"Ronnenberg","state_id":"1385"},
    {"city_id":"19525","city_name":"Rosdorf","state_id":"1385"},
    {"city_id":"19526","city_name":"Rosengarten","state_id":"1385"},
    {"city_id":"19527","city_name":"Rotenburg","state_id":"1385"},
    {"city_id":"19528","city_name":"Salzgitter","state_id":"1385"},
    {"city_id":"19529","city_name":"Salzhemmendorf","state_id":"1385"},
    {"city_id":"19530","city_name":"Sarstedt","state_id":"1385"},
    {"city_id":"19531","city_name":"Saterland","state_id":"1385"},
    {"city_id":"19532","city_name":"ScheeBel","state_id":"1385"},
    {"city_id":"19533","city_name":"Schiffdorf","state_id":"1385"},
    {"city_id":"19534","city_name":"Schneverdingen","state_id":"1385"},
    {"city_id":"19535","city_name":"Schoningen","state_id":"1385"},
    {"city_id":"19536","city_name":"Schortens","state_id":"1385"},
    {"city_id":"19537","city_name":"Schuttorf","state_id":"1385"},
    {"city_id":"19538","city_name":"Schwanewede","state_id":"1385"},
    {"city_id":"19539","city_name":"Seelze","state_id":"1385"},
    {"city_id":"19540","city_name":"Seesen","state_id":"1385"},
    {"city_id":"19541","city_name":"Seevetal","state_id":"1385"},
    {"city_id":"19542","city_name":"Sehnde","state_id":"1385"},
    {"city_id":"19543","city_name":"Soltau","state_id":"1385"},
    {"city_id":"19544","city_name":"Springe","state_id":"1385"},
    {"city_id":"19545","city_name":"Stade","state_id":"1385"},
    {"city_id":"19546","city_name":"Stadthagen","state_id":"1385"},
    {"city_id":"19547","city_name":"Stadtoldendorf","state_id":"1385"},
    {"city_id":"19548","city_name":"Stelle","state_id":"1385"},
    {"city_id":"19549","city_name":"Stuhr","state_id":"1385"},
    {"city_id":"19550","city_name":"Sudbrookmerland","state_id":"1385"},
    {"city_id":"19551","city_name":"Sulingen","state_id":"1385"},
    {"city_id":"19552","city_name":"Syke","state_id":"1385"},
    {"city_id":"19553","city_name":"Tarmstedt","state_id":"1385"},
    {"city_id":"19554","city_name":"Tostedt","state_id":"1385"},
    {"city_id":"19555","city_name":"Twistringen","state_id":"1385"},
    {"city_id":"19556","city_name":"Uchte","state_id":"1385"},
    {"city_id":"19557","city_name":"Uelzen","state_id":"1385"},
    {"city_id":"19558","city_name":"Uetze","state_id":"1385"},
    {"city_id":"19559","city_name":"Uplengen","state_id":"1385"},
    {"city_id":"19560","city_name":"Uslar","state_id":"1385"},
    {"city_id":"19561","city_name":"Varel","state_id":"1385"},
    {"city_id":"19562","city_name":"Vechelde","state_id":"1385"},
    {"city_id":"19563","city_name":"Vechta","state_id":"1385"},
    {"city_id":"19564","city_name":"Verden","state_id":"1385"},
    {"city_id":"19565","city_name":"Vienenburg","state_id":"1385"},
    {"city_id":"19566","city_name":"Visselhovede","state_id":"1385"},
    {"city_id":"19567","city_name":"Walkenried","state_id":"1385"},
    {"city_id":"19568","city_name":"Wallenhorst","state_id":"1385"},
    {"city_id":"19569","city_name":"Walsrode","state_id":"1385"},
    {"city_id":"19570","city_name":"Wangerland","state_id":"1385"},
    {"city_id":"19571","city_name":"Wardenburg","state_id":"1385"},
    {"city_id":"19572","city_name":"Wedemark","state_id":"1385"},
    {"city_id":"19573","city_name":"Weener","state_id":"1385"},
    {"city_id":"19574","city_name":"Wendeburg","state_id":"1385"},
    {"city_id":"19575","city_name":"Wennigsen","state_id":"1385"},
    {"city_id":"19576","city_name":"Westerstede","state_id":"1385"},
    {"city_id":"19577","city_name":"Westoverledingen","state_id":"1385"},
    {"city_id":"19578","city_name":"Weyhe","state_id":"1385"},
    {"city_id":"19579","city_name":"Wiefelstede","state_id":"1385"},
    {"city_id":"19580","city_name":"Wiesmoor","state_id":"1385"},
    {"city_id":"19581","city_name":"Wildeshausen","state_id":"1385"},
    {"city_id":"19582","city_name":"Wilhelmshaven","state_id":"1385"},
    {"city_id":"19583","city_name":"Winsen","state_id":"1385"},
    {"city_id":"19584","city_name":"Wittingen","state_id":"1385"},
    {"city_id":"19585","city_name":"Wittmund","state_id":"1385"},
    {"city_id":"19586","city_name":"Wolfenbuttel","state_id":"1385"},
    {"city_id":"19587","city_name":"Wolfsburg","state_id":"1385"},
    {"city_id":"19588","city_name":"Worpswede","state_id":"1385"},
    {"city_id":"19589","city_name":"Wunstorf","state_id":"1385"},
    {"city_id":"19590","city_name":"Zetel","state_id":"1385"},
    {"city_id":"19591","city_name":"Zeven","state_id":"1385"},
    {"city_id":"19592","city_name":"Middenbeemster","state_id":"1386"},
    {"city_id":"19593","city_name":"Aachen","state_id":"1387"},
    {"city_id":"19594","city_name":"Ahaus","state_id":"1387"},
    {"city_id":"19595","city_name":"Ahlen","state_id":"1387"},
    {"city_id":"19596","city_name":"Aldenhoven","state_id":"1387"},
    {"city_id":"19597","city_name":"Alfter","state_id":"1387"},
    {"city_id":"19598","city_name":"Alpen","state_id":"1387"},
    {"city_id":"19599","city_name":"Alsdorf","state_id":"1387"},
    {"city_id":"19600","city_name":"Altena","state_id":"1387"},
    {"city_id":"19601","city_name":"Altendorf","state_id":"1387"},
    {"city_id":"19602","city_name":"Anrochte","state_id":"1387"},
    {"city_id":"19603","city_name":"Arnsberg","state_id":"1387"},
    {"city_id":"19604","city_name":"Ascheberg","state_id":"1387"},
    {"city_id":"19605","city_name":"Attendorn","state_id":"1387"},
    {"city_id":"19606","city_name":"Augustdorf","state_id":"1387"},
    {"city_id":"19607","city_name":"Bad Berleburg","state_id":"1387"},
    {"city_id":"19608","city_name":"Bad Driburg","state_id":"1387"},
    {"city_id":"19609","city_name":"Bad Honnef","state_id":"1387"},
    {"city_id":"19610","city_name":"Bad Laasphe","state_id":"1387"},
    {"city_id":"19611","city_name":"Bad Lippspringe","state_id":"1387"},
    {"city_id":"19612","city_name":"Bad Munstereifel","state_id":"1387"},
    {"city_id":"19613","city_name":"Bad Oeynhausen","state_id":"1387"},
    {"city_id":"19614","city_name":"Bad Salzuflen","state_id":"1387"},
    {"city_id":"19615","city_name":"Bad Sassendorf","state_id":"1387"},
    {"city_id":"19616","city_name":"Baesweiler","state_id":"1387"},
    {"city_id":"19617","city_name":"Balve","state_id":"1387"},
    {"city_id":"19618","city_name":"Barntrup","state_id":"1387"},
    {"city_id":"19619","city_name":"Beckum","state_id":"1387"},
    {"city_id":"19620","city_name":"Bedburg","state_id":"1387"},
    {"city_id":"19621","city_name":"Bedburg-Hau","state_id":"1387"},
    {"city_id":"19622","city_name":"Bergheim","state_id":"1387"},
    {"city_id":"19623","city_name":"Bergisch Gladbach","state_id":"1387"},
    {"city_id":"19624","city_name":"Bergkamen","state_id":"1387"},
    {"city_id":"19625","city_name":"Bergneustadt","state_id":"1387"},
    {"city_id":"19626","city_name":"Bestwig","state_id":"1387"},
    {"city_id":"19627","city_name":"Beverungen","state_id":"1387"},
    {"city_id":"19628","city_name":"Bielefeld","state_id":"1387"},
    {"city_id":"19629","city_name":"Billerbeck","state_id":"1387"},
    {"city_id":"19630","city_name":"Blomberg","state_id":"1387"},
    {"city_id":"19631","city_name":"Bocholt","state_id":"1387"},
    {"city_id":"19632","city_name":"Bochum","state_id":"1387"},
    {"city_id":"19633","city_name":"Bocket","state_id":"1387"},
    {"city_id":"19634","city_name":"Bonen","state_id":"1387"},
    {"city_id":"19635","city_name":"Bonn","state_id":"1387"},
    {"city_id":"19636","city_name":"Borchen","state_id":"1387"},
    {"city_id":"19637","city_name":"Borgentreich","state_id":"1387"},
    {"city_id":"19638","city_name":"Borgholzhausen","state_id":"1387"},
    {"city_id":"19639","city_name":"Borken","state_id":"1387"},
    {"city_id":"19640","city_name":"Bornheim","state_id":"1387"},
    {"city_id":"19641","city_name":"Bottrop","state_id":"1387"},
    {"city_id":"19642","city_name":"Brakel","state_id":"1387"},
    {"city_id":"19643","city_name":"Brilon","state_id":"1387"},
    {"city_id":"19644","city_name":"Bruggen","state_id":"1387"},
    {"city_id":"19645","city_name":"Bruhl","state_id":"1387"},
    {"city_id":"19646","city_name":"Bunde","state_id":"1387"},
    {"city_id":"19647","city_name":"Burbach","state_id":"1387"},
    {"city_id":"19648","city_name":"Buren","state_id":"1387"},
    {"city_id":"19649","city_name":"Burscheid","state_id":"1387"},
    {"city_id":"19650","city_name":"Castrop-Rauxel","state_id":"1387"},
    {"city_id":"19651","city_name":"Coesfeld","state_id":"1387"},
    {"city_id":"19652","city_name":"Cologne","state_id":"1387"},
    {"city_id":"19653","city_name":"Datteln","state_id":"1387"},
    {"city_id":"19654","city_name":"Delbruck","state_id":"1387"},
    {"city_id":"19655","city_name":"Denklingen","state_id":"1387"},
    {"city_id":"19656","city_name":"Detmold","state_id":"1387"},
    {"city_id":"19657","city_name":"Dingden","state_id":"1387"},
    {"city_id":"19658","city_name":"Dinslaken","state_id":"1387"},
    {"city_id":"19659","city_name":"Dormagen","state_id":"1387"},
    {"city_id":"19660","city_name":"Dorsten","state_id":"1387"},
    {"city_id":"19661","city_name":"Dortmund","state_id":"1387"},
    {"city_id":"19662","city_name":"Drensteinfurt","state_id":"1387"},
    {"city_id":"19663","city_name":"Drolshagen","state_id":"1387"},
    {"city_id":"19664","city_name":"Duisburg","state_id":"1387"},
    {"city_id":"19665","city_name":"Dulmen","state_id":"1387"},
    {"city_id":"19666","city_name":"Duren","state_id":"1387"},
    {"city_id":"19667","city_name":"Dusseldorf","state_id":"1387"},
    {"city_id":"19668","city_name":"Eitorf","state_id":"1387"},
    {"city_id":"19669","city_name":"Elsdorf","state_id":"1387"},
    {"city_id":"19670","city_name":"Emmerich","state_id":"1387"},
    {"city_id":"19671","city_name":"Emsdetten","state_id":"1387"},
    {"city_id":"19672","city_name":"Engelskirchen","state_id":"1387"},
    {"city_id":"19673","city_name":"Enger","state_id":"1387"},
    {"city_id":"19674","city_name":"Ennepetal","state_id":"1387"},
    {"city_id":"19675","city_name":"Ennigerloh","state_id":"1387"},
    {"city_id":"19676","city_name":"Ense","state_id":"1387"},
    {"city_id":"19677","city_name":"Erftstadt","state_id":"1387"},
    {"city_id":"19678","city_name":"Erkelenz","state_id":"1387"},
    {"city_id":"19679","city_name":"Erkrath","state_id":"1387"},
    {"city_id":"19680","city_name":"Erwitte","state_id":"1387"},
    {"city_id":"19681","city_name":"Espelkamp","state_id":"1387"},
    {"city_id":"19682","city_name":"Essen","state_id":"1387"},
    {"city_id":"19683","city_name":"Euskirchen","state_id":"1387"},
    {"city_id":"19684","city_name":"Extertal","state_id":"1387"},
    {"city_id":"19685","city_name":"Finnentrop","state_id":"1387"},
    {"city_id":"19686","city_name":"Frechen","state_id":"1387"},
    {"city_id":"19687","city_name":"Freudenberg","state_id":"1387"},
    {"city_id":"19688","city_name":"Frondenberg","state_id":"1387"},
    {"city_id":"19689","city_name":"Gangelt","state_id":"1387"},
    {"city_id":"19690","city_name":"Geilenkirchen","state_id":"1387"},
    {"city_id":"19691","city_name":"Geldern","state_id":"1387"},
    {"city_id":"19692","city_name":"Gelsenkirchen","state_id":"1387"},
    {"city_id":"19693","city_name":"Gescher","state_id":"1387"},
    {"city_id":"19694","city_name":"Geseke","state_id":"1387"},
    {"city_id":"19695","city_name":"Gevelsberg","state_id":"1387"},
    {"city_id":"19696","city_name":"Gladbeck","state_id":"1387"},
    {"city_id":"19697","city_name":"Goch","state_id":"1387"},
    {"city_id":"19698","city_name":"Grefrath","state_id":"1387"},
    {"city_id":"19699","city_name":"Greven","state_id":"1387"},
    {"city_id":"19700","city_name":"Grevenbroich","state_id":"1387"},
    {"city_id":"19701","city_name":"Gronau","state_id":"1387"},
    {"city_id":"19702","city_name":"Gummersbach","state_id":"1387"},
    {"city_id":"19703","city_name":"Gutersloh","state_id":"1387"},
    {"city_id":"19704","city_name":"Haan","state_id":"1387"},
    {"city_id":"19705","city_name":"Hagen","state_id":"1387"},
    {"city_id":"19706","city_name":"Halle","state_id":"1387"},
    {"city_id":"19707","city_name":"Haltern","state_id":"1387"},
    {"city_id":"19708","city_name":"Halver","state_id":"1387"},
    {"city_id":"19709","city_name":"Hamm","state_id":"1387"},
    {"city_id":"19710","city_name":"Hamminkeln","state_id":"1387"},
    {"city_id":"19711","city_name":"Harsewinkel","state_id":"1387"},
    {"city_id":"19712","city_name":"Hattingen","state_id":"1387"},
    {"city_id":"19713","city_name":"Havixbeck","state_id":"1387"},
    {"city_id":"19714","city_name":"Heiligenhaus","state_id":"1387"},
    {"city_id":"19715","city_name":"Heinsberg","state_id":"1387"},
    {"city_id":"19716","city_name":"Hemer","state_id":"1387"},
    {"city_id":"19717","city_name":"Hennef","state_id":"1387"},
    {"city_id":"19718","city_name":"Herdecke","state_id":"1387"},
    {"city_id":"19719","city_name":"Herford","state_id":"1387"},
    {"city_id":"19720","city_name":"Herne","state_id":"1387"},
    {"city_id":"19721","city_name":"Herten","state_id":"1387"},
    {"city_id":"19722","city_name":"Herzebrock-Clarholz","state_id":"1387"},
    {"city_id":"19723","city_name":"Herzogenrath","state_id":"1387"},
    {"city_id":"19724","city_name":"Hiddenhausen","state_id":"1387"},
    {"city_id":"19725","city_name":"Hilchenbach","state_id":"1387"},
    {"city_id":"19726","city_name":"Hilden","state_id":"1387"},
    {"city_id":"19727","city_name":"Hille","state_id":"1387"},
    {"city_id":"19728","city_name":"Holzwickede","state_id":"1387"},
    {"city_id":"19729","city_name":"Horn-Bad Meinberg","state_id":"1387"},
    {"city_id":"19730","city_name":"Horstel","state_id":"1387"},
    {"city_id":"19731","city_name":"Hovelhof","state_id":"1387"},
    {"city_id":"19732","city_name":"Hoxter","state_id":"1387"},
    {"city_id":"19733","city_name":"Huckelhoven","state_id":"1387"},
    {"city_id":"19734","city_name":"Huckeswagen","state_id":"1387"},
    {"city_id":"19735","city_name":"Hullhorst","state_id":"1387"},
    {"city_id":"19736","city_name":"Hunxe","state_id":"1387"},
    {"city_id":"19737","city_name":"Hurth","state_id":"1387"},
    {"city_id":"19738","city_name":"Ibbenburen","state_id":"1387"},
    {"city_id":"19739","city_name":"Iserlohn","state_id":"1387"},
    {"city_id":"19740","city_name":"Isselburg","state_id":"1387"},
    {"city_id":"19741","city_name":"Issum","state_id":"1387"},
    {"city_id":"19742","city_name":"Juchen","state_id":"1387"},
    {"city_id":"19743","city_name":"Julich","state_id":"1387"},
    {"city_id":"19744","city_name":"Kaarst","state_id":"1387"},
    {"city_id":"19745","city_name":"Kalkar","state_id":"1387"},
    {"city_id":"19746","city_name":"Kall","state_id":"1387"},
    {"city_id":"19747","city_name":"Kalletal","state_id":"1387"},
    {"city_id":"19748","city_name":"Kamen","state_id":"1387"},
    {"city_id":"19749","city_name":"Kamp-Lintfort","state_id":"1387"},
    {"city_id":"19750","city_name":"Kempen","state_id":"1387"},
    {"city_id":"19751","city_name":"Kerken","state_id":"1387"},
    {"city_id":"19752","city_name":"Kerpen","state_id":"1387"},
    {"city_id":"19753","city_name":"Kevelaer","state_id":"1387"},
    {"city_id":"19754","city_name":"Kierspe","state_id":"1387"},
    {"city_id":"19755","city_name":"Kirchhundem","state_id":"1387"},
    {"city_id":"19756","city_name":"Kirchlengern","state_id":"1387"},
    {"city_id":"19757","city_name":"Kleve","state_id":"1387"},
    {"city_id":"19758","city_name":"Koln","state_id":"1387"},
    {"city_id":"19759","city_name":"Konigswinter","state_id":"1387"},
    {"city_id":"19760","city_name":"Korschenbroich","state_id":"1387"},
    {"city_id":"19761","city_name":"Krefeld","state_id":"1387"},
    {"city_id":"19762","city_name":"Kreuzau","state_id":"1387"},
    {"city_id":"19763","city_name":"Kreuztal","state_id":"1387"},
    {"city_id":"19764","city_name":"Kurten","state_id":"1387"},
    {"city_id":"19765","city_name":"Lage","state_id":"1387"},
    {"city_id":"19766","city_name":"Langenfeld","state_id":"1387"},
    {"city_id":"19767","city_name":"Langerwehe","state_id":"1387"},
    {"city_id":"19768","city_name":"Leichlingen","state_id":"1387"},
    {"city_id":"19769","city_name":"Lemgo","state_id":"1387"},
    {"city_id":"19770","city_name":"Lengerich","state_id":"1387"},
    {"city_id":"19771","city_name":"Lennestadt","state_id":"1387"},
    {"city_id":"19772","city_name":"Leopoldshohe","state_id":"1387"},
    {"city_id":"19773","city_name":"Leverkusen","state_id":"1387"},
    {"city_id":"19774","city_name":"Lichtenau","state_id":"1387"},
    {"city_id":"19775","city_name":"Lindlar","state_id":"1387"},
    {"city_id":"19776","city_name":"Linnich","state_id":"1387"},
    {"city_id":"19777","city_name":"Lippetal","state_id":"1387"},
    {"city_id":"19778","city_name":"Lippstadt","state_id":"1387"},
    {"city_id":"19779","city_name":"Lohmar","state_id":"1387"},
    {"city_id":"19780","city_name":"Lohne","state_id":"1387"},
    {"city_id":"19781","city_name":"Lotte","state_id":"1387"},
    {"city_id":"19782","city_name":"Lubbecke","state_id":"1387"},
    {"city_id":"19783","city_name":"Ludenscheid","state_id":"1387"},
    {"city_id":"19784","city_name":"Ludinghausen","state_id":"1387"},
    {"city_id":"19785","city_name":"Lugde","state_id":"1387"},
    {"city_id":"19786","city_name":"Lunen","state_id":"1387"},
    {"city_id":"19787","city_name":"Marienheide","state_id":"1387"},
    {"city_id":"19788","city_name":"Marl","state_id":"1387"},
    {"city_id":"19789","city_name":"Marsberg","state_id":"1387"},
    {"city_id":"19790","city_name":"Mechernich","state_id":"1387"},
    {"city_id":"19791","city_name":"Meckenheim","state_id":"1387"},
    {"city_id":"19792","city_name":"Meerbusch","state_id":"1387"},
    {"city_id":"19793","city_name":"Meinerzhagen","state_id":"1387"},
    {"city_id":"19794","city_name":"Menden","state_id":"1387"},
    {"city_id":"19795","city_name":"Meschede","state_id":"1387"},
    {"city_id":"19796","city_name":"Mettingen","state_id":"1387"},
    {"city_id":"19797","city_name":"Mettmann","state_id":"1387"},
    {"city_id":"19798","city_name":"Minden","state_id":"1387"},
    {"city_id":"19799","city_name":"Moers","state_id":"1387"},
    {"city_id":"19800","city_name":"Mohnesee","state_id":"1387"},
    {"city_id":"19801","city_name":"Monchengladbach","state_id":"1387"},
    {"city_id":"19802","city_name":"Monheim","state_id":"1387"},
    {"city_id":"19803","city_name":"Monschau","state_id":"1387"},
    {"city_id":"19804","city_name":"Morsbach","state_id":"1387"},
    {"city_id":"19805","city_name":"Much","state_id":"1387"},
    {"city_id":"19806","city_name":"Mulheim","state_id":"1387"},
    {"city_id":"19807","city_name":"Munster","state_id":"1387"},
    {"city_id":"19808","city_name":"Netphen","state_id":"1387"},
    {"city_id":"19809","city_name":"Nettetal","state_id":"1387"},
    {"city_id":"19810","city_name":"Neuenkirchen","state_id":"1387"},
    {"city_id":"19811","city_name":"Neuenrade","state_id":"1387"},
    {"city_id":"19812","city_name":"Neukirchen-Vluyn","state_id":"1387"},
    {"city_id":"19813","city_name":"Neunkirchen","state_id":"1387"},
    {"city_id":"19814","city_name":"Neunkirchen-Seelscheid","state_id":"1387"},
    {"city_id":"19815","city_name":"Neuss","state_id":"1387"},
    {"city_id":"19816","city_name":"Nideggen","state_id":"1387"},
    {"city_id":"19817","city_name":"Niederkassel","state_id":"1387"},
    {"city_id":"19818","city_name":"Niederkruchten","state_id":"1387"},
    {"city_id":"19819","city_name":"Niederzier","state_id":"1387"},
    {"city_id":"19820","city_name":"Nordkirchen","state_id":"1387"},
    {"city_id":"19821","city_name":"Norvenich","state_id":"1387"},
    {"city_id":"19822","city_name":"Nottuln","state_id":"1387"},
    {"city_id":"19823","city_name":"Numbrecht","state_id":"1387"},
    {"city_id":"19824","city_name":"Oberhausen","state_id":"1387"},
    {"city_id":"19825","city_name":"Ochtrup","state_id":"1387"},
    {"city_id":"19826","city_name":"Odenthal","state_id":"1387"},
    {"city_id":"19827","city_name":"Oelde","state_id":"1387"},
    {"city_id":"19828","city_name":"Oer-Erkenschwick","state_id":"1387"},
    {"city_id":"19829","city_name":"Oerlinghausen","state_id":"1387"},
    {"city_id":"19830","city_name":"Olfen","state_id":"1387"},
    {"city_id":"19831","city_name":"Olpe","state_id":"1387"},
    {"city_id":"19832","city_name":"Olsberg","state_id":"1387"},
    {"city_id":"19833","city_name":"Overath","state_id":"1387"},
    {"city_id":"19834","city_name":"Paderborn","state_id":"1387"},
    {"city_id":"19835","city_name":"Petershagen","state_id":"1387"},
    {"city_id":"19836","city_name":"Plettenberg","state_id":"1387"},
    {"city_id":"19837","city_name":"Porta Westfalica","state_id":"1387"},
    {"city_id":"19838","city_name":"PreuBisch Oldendorf","state_id":"1387"},
    {"city_id":"19839","city_name":"Pulheim","state_id":"1387"},
    {"city_id":"19840","city_name":"Radevormwald","state_id":"1387"},
    {"city_id":"19841","city_name":"Raesfeld","state_id":"1387"},
    {"city_id":"19842","city_name":"Rahden","state_id":"1387"},
    {"city_id":"19843","city_name":"Ratingen","state_id":"1387"},
    {"city_id":"19844","city_name":"Recke","state_id":"1387"},
    {"city_id":"19845","city_name":"Recklinghausen","state_id":"1387"},
    {"city_id":"19846","city_name":"Rees","state_id":"1387"},
    {"city_id":"19847","city_name":"Reichshof","state_id":"1387"},
    {"city_id":"19848","city_name":"Reken","state_id":"1387"},
    {"city_id":"19849","city_name":"Remscheid","state_id":"1387"},
    {"city_id":"19850","city_name":"Rheda-Wiedenbruck","state_id":"1387"},
    {"city_id":"19851","city_name":"Rhede","state_id":"1387"},
    {"city_id":"19852","city_name":"Rheinbach","state_id":"1387"},
    {"city_id":"19853","city_name":"Rheinberg","state_id":"1387"},
    {"city_id":"19854","city_name":"Rheine","state_id":"1387"},
    {"city_id":"19855","city_name":"Rietberg","state_id":"1387"},
    {"city_id":"19856","city_name":"Rommerskirchen","state_id":"1387"},
    {"city_id":"19857","city_name":"Rosendahl","state_id":"1387"},
    {"city_id":"19858","city_name":"Rosrath","state_id":"1387"},
    {"city_id":"19859","city_name":"Ruthen","state_id":"1387"},
    {"city_id":"19860","city_name":"Salzkotten","state_id":"1387"},
    {"city_id":"19861","city_name":"Sassenberg","state_id":"1387"},
    {"city_id":"19862","city_name":"Schalksmuhle","state_id":"1387"},
    {"city_id":"19863","city_name":"Schermbeck","state_id":"1387"},
    {"city_id":"19864","city_name":"Schieder-Schwalenberg","state_id":"1387"},
    {"city_id":"19865","city_name":"Schleiden","state_id":"1387"},
    {"city_id":"19866","city_name":"SchloB Holte-Stukenbrock","state_id":"1387"},
    {"city_id":"19867","city_name":"Schmallenberg","state_id":"1387"},
    {"city_id":"19868","city_name":"Schwalmtal","state_id":"1387"},
    {"city_id":"19869","city_name":"Schwelm","state_id":"1387"},
    {"city_id":"19870","city_name":"Schwerte","state_id":"1387"},
    {"city_id":"19871","city_name":"Selm","state_id":"1387"},
    {"city_id":"19872","city_name":"Senden","state_id":"1387"},
    {"city_id":"19873","city_name":"Sendenhorst","state_id":"1387"},
    {"city_id":"19874","city_name":"Siegburg","state_id":"1387"},
    {"city_id":"19875","city_name":"Siegen","state_id":"1387"},
    {"city_id":"19876","city_name":"Simmerath","state_id":"1387"},
    {"city_id":"19877","city_name":"Soest","state_id":"1387"},
    {"city_id":"19878","city_name":"Solingen","state_id":"1387"},
    {"city_id":"19879","city_name":"Sonsbeck","state_id":"1387"},
    {"city_id":"19880","city_name":"Spenge","state_id":"1387"},
    {"city_id":"19881","city_name":"Sprockhovel","state_id":"1387"},
    {"city_id":"19882","city_name":"Stadtlohn","state_id":"1387"},
    {"city_id":"19883","city_name":"Steinfurt","state_id":"1387"},
    {"city_id":"19884","city_name":"Steinhagen","state_id":"1387"},
    {"city_id":"19885","city_name":"Steinheim","state_id":"1387"},
    {"city_id":"19886","city_name":"Stemwede","state_id":"1387"},
    {"city_id":"19887","city_name":"Stolberg","state_id":"1387"},
    {"city_id":"19888","city_name":"Straelen","state_id":"1387"},
    {"city_id":"19889","city_name":"Sundern","state_id":"1387"},
    {"city_id":"19890","city_name":"Swisttal","state_id":"1387"},
    {"city_id":"19891","city_name":"Tecklenburg","state_id":"1387"},
    {"city_id":"19892","city_name":"Telgte","state_id":"1387"},
    {"city_id":"19893","city_name":"Tonisvorst","state_id":"1387"},
    {"city_id":"19894","city_name":"Troisdorf","state_id":"1387"},
    {"city_id":"19895","city_name":"Ubach-Palenberg","state_id":"1387"},
    {"city_id":"19896","city_name":"Unna","state_id":"1387"},
    {"city_id":"19897","city_name":"Velbert","state_id":"1387"},
    {"city_id":"19898","city_name":"Velen","state_id":"1387"},
    {"city_id":"19899","city_name":"Verl","state_id":"1387"},
    {"city_id":"19900","city_name":"Versmold","state_id":"1387"},
    {"city_id":"19901","city_name":"Viersen","state_id":"1387"},
    {"city_id":"19902","city_name":"Vlotho","state_id":"1387"},
    {"city_id":"19903","city_name":"Voerde","state_id":"1387"},
    {"city_id":"19904","city_name":"Vreden","state_id":"1387"},
    {"city_id":"19905","city_name":"Wachtberg","state_id":"1387"},
    {"city_id":"19906","city_name":"Wachtendonk","state_id":"1387"},
    {"city_id":"19907","city_name":"Wadersloh","state_id":"1387"},
    {"city_id":"19908","city_name":"Waldbrol","state_id":"1387"},
    {"city_id":"19909","city_name":"Waltrop","state_id":"1387"},
    {"city_id":"19910","city_name":"Warburg","state_id":"1387"},
    {"city_id":"19911","city_name":"Warendorf","state_id":"1387"},
    {"city_id":"19912","city_name":"Warstein","state_id":"1387"},
    {"city_id":"19913","city_name":"Wassenberg","state_id":"1387"},
    {"city_id":"19914","city_name":"Weeze","state_id":"1387"},
    {"city_id":"19915","city_name":"Wegberg","state_id":"1387"},
    {"city_id":"19916","city_name":"Weilerswist","state_id":"1387"},
    {"city_id":"19917","city_name":"Welver","state_id":"1387"},
    {"city_id":"19918","city_name":"Wenden","state_id":"1387"},
    {"city_id":"19919","city_name":"Werdohl","state_id":"1387"},
    {"city_id":"19920","city_name":"Werl","state_id":"1387"},
    {"city_id":"19921","city_name":"Wermelskirchen","state_id":"1387"},
    {"city_id":"19922","city_name":"Werne","state_id":"1387"},
    {"city_id":"19923","city_name":"Werther","state_id":"1387"},
    {"city_id":"19924","city_name":"Wesel","state_id":"1387"},
    {"city_id":"19925","city_name":"Wesseling","state_id":"1387"},
    {"city_id":"19926","city_name":"Westerkappeln","state_id":"1387"},
    {"city_id":"19927","city_name":"Wetter","state_id":"1387"},
    {"city_id":"19928","city_name":"Wickede","state_id":"1387"},
    {"city_id":"19929","city_name":"Wiehl","state_id":"1387"},
    {"city_id":"19930","city_name":"Willich","state_id":"1387"},
    {"city_id":"19931","city_name":"Wilnsdorf","state_id":"1387"},
    {"city_id":"19932","city_name":"Windeck","state_id":"1387"},
    {"city_id":"19933","city_name":"Winterberg","state_id":"1387"},
    {"city_id":"19934","city_name":"Wipperfurth","state_id":"1387"},
    {"city_id":"19935","city_name":"Witten","state_id":"1387"},
    {"city_id":"19936","city_name":"Wulfrath","state_id":"1387"},
    {"city_id":"19937","city_name":"Wunnenberg","state_id":"1387"},
    {"city_id":"19938","city_name":"Wuppertal","state_id":"1387"},
    {"city_id":"19939","city_name":"Wurselen","state_id":"1387"},
    {"city_id":"19940","city_name":"Xanten","state_id":"1387"},
    {"city_id":"19941","city_name":"Zulpich","state_id":"1387"},
    {"city_id":"19942","city_name":"Herzberg am Harz","state_id":"1389"},
    {"city_id":"19943","city_name":"Alzey","state_id":"1390"},
    {"city_id":"19944","city_name":"Andernach","state_id":"1390"},
    {"city_id":"19945","city_name":"Bad Durkheim","state_id":"1390"},
    {"city_id":"19946","city_name":"Bad Ems","state_id":"1390"},
    {"city_id":"19947","city_name":"Bad Kreuznach","state_id":"1390"},
    {"city_id":"19948","city_name":"Bad Neuenahr-Ahrweiler","state_id":"1390"},
    {"city_id":"19949","city_name":"Bendorf","state_id":"1390"},
    {"city_id":"19950","city_name":"Betzdorf","state_id":"1390"},
    {"city_id":"19951","city_name":"Bingen","state_id":"1390"},
    {"city_id":"19952","city_name":"Bitburg","state_id":"1390"},
    {"city_id":"19953","city_name":"Bobenheim-Roxheim","state_id":"1390"},
    {"city_id":"19954","city_name":"Bohl-Iggelheim","state_id":"1390"},
    {"city_id":"19955","city_name":"Boppard","state_id":"1390"},
    {"city_id":"19956","city_name":"Daun","state_id":"1390"},
    {"city_id":"19957","city_name":"Diez","state_id":"1390"},
    {"city_id":"19958","city_name":"Eisenberg","state_id":"1390"},
    {"city_id":"19959","city_name":"Essingen","state_id":"1390"},
    {"city_id":"19960","city_name":"Frankenthal","state_id":"1390"},
    {"city_id":"19961","city_name":"Gau-Odernheim","state_id":"1390"},
    {"city_id":"19962","city_name":"Germersheim","state_id":"1390"},
    {"city_id":"19963","city_name":"Grunstadt","state_id":"1390"},
    {"city_id":"19964","city_name":"HaBloch","state_id":"1390"},
    {"city_id":"19965","city_name":"Hahnstatten","state_id":"1390"},
    {"city_id":"19966","city_name":"Hallschlag","state_id":"1390"},
    {"city_id":"19967","city_name":"Herschbach","state_id":"1390"},
    {"city_id":"19968","city_name":"Herxheim","state_id":"1390"},
    {"city_id":"19969","city_name":"Hirschhorn","state_id":"1390"},
    {"city_id":"19970","city_name":"Hohr-Grenzhausen","state_id":"1390"},
    {"city_id":"19971","city_name":"Holzheim","state_id":"1390"},
    {"city_id":"19972","city_name":"Idar-Oberstein","state_id":"1390"},
    {"city_id":"19973","city_name":"Ingelheim","state_id":"1390"},
    {"city_id":"19974","city_name":"Kaisersesch","state_id":"1390"},
    {"city_id":"19975","city_name":"Kaiserslautern","state_id":"1390"},
    {"city_id":"19976","city_name":"Kastellaun","state_id":"1390"},
    {"city_id":"19977","city_name":"Kindsbach","state_id":"1390"},
    {"city_id":"19978","city_name":"Kirchen","state_id":"1390"},
    {"city_id":"19979","city_name":"Kirn","state_id":"1390"},
    {"city_id":"19980","city_name":"Koblenz","state_id":"1390"},
    {"city_id":"19981","city_name":"Lahnstein","state_id":"1390"},
    {"city_id":"19982","city_name":"Landau","state_id":"1390"},
    {"city_id":"19983","city_name":"Limburgerhof","state_id":"1390"},
    {"city_id":"19984","city_name":"Luckenburg","state_id":"1390"},
    {"city_id":"19985","city_name":"Ludwigshafen","state_id":"1390"},
    {"city_id":"19986","city_name":"Mainz","state_id":"1390"},
    {"city_id":"19987","city_name":"Mayen","state_id":"1390"},
    {"city_id":"19988","city_name":"Montabaur","state_id":"1390"},
    {"city_id":"19989","city_name":"Morbach","state_id":"1390"},
    {"city_id":"19990","city_name":"Mulheim-Karlich","state_id":"1390"},
    {"city_id":"19991","city_name":"Mundersbach","state_id":"1390"},
    {"city_id":"19992","city_name":"Mutterstadt","state_id":"1390"},
    {"city_id":"19993","city_name":"Nassau","state_id":"1390"},
    {"city_id":"19994","city_name":"Neitersen","state_id":"1390"},
    {"city_id":"19995","city_name":"Neustadt","state_id":"1390"},
    {"city_id":"19996","city_name":"Neuwied","state_id":"1390"},
    {"city_id":"19997","city_name":"Niederzissen","state_id":"1390"},
    {"city_id":"19998","city_name":"Pirmasens","state_id":"1390"},
    {"city_id":"19999","city_name":"Plaidt","state_id":"1390"},
    {"city_id":"20000","city_name":"Remagen","state_id":"1390"},
    {"city_id":"20001","city_name":"Schifferstadt","state_id":"1390"},
    {"city_id":"20002","city_name":"Schoenenberg Kuebelberg","state_id":"1390"},
    {"city_id":"20003","city_name":"Sinzig","state_id":"1390"},
    {"city_id":"20004","city_name":"Speyer","state_id":"1390"},
    {"city_id":"20005","city_name":"St. Goar","state_id":"1390"},
    {"city_id":"20006","city_name":"Trier","state_id":"1390"},
    {"city_id":"20007","city_name":"Vallendar","state_id":"1390"},
    {"city_id":"20008","city_name":"Winterbach","state_id":"1390"},
    {"city_id":"20009","city_name":"Wittlich","state_id":"1390"},
    {"city_id":"20010","city_name":"Worms","state_id":"1390"},
    {"city_id":"20011","city_name":"Worth","state_id":"1390"},
    {"city_id":"20012","city_name":"Zweibrucken","state_id":"1390"},
    {"city_id":"20013","city_name":"Adenau","state_id":"1391"},
    {"city_id":"20014","city_name":"Anhausen","state_id":"1391"},
    {"city_id":"20015","city_name":"Barbelroth","state_id":"1391"},
    {"city_id":"20016","city_name":"Berndroth","state_id":"1391"},
    {"city_id":"20017","city_name":"Bernkastel-Kues","state_id":"1391"},
    {"city_id":"20018","city_name":"Burgbrohl","state_id":"1391"},
    {"city_id":"20019","city_name":"Dieblich","state_id":"1391"},
    {"city_id":"20020","city_name":"Dierdorf","state_id":"1391"},
    {"city_id":"20021","city_name":"Dreisbach","state_id":"1391"},
    {"city_id":"20022","city_name":"Elsoff","state_id":"1391"},
    {"city_id":"20023","city_name":"Enkenbach-Alsenborn","state_id":"1391"},
    {"city_id":"20024","city_name":"Etzbach","state_id":"1391"},
    {"city_id":"20025","city_name":"Flonheim","state_id":"1391"},
    {"city_id":"20026","city_name":"Fohren","state_id":"1391"},
    {"city_id":"20027","city_name":"Grafschaft","state_id":"1391"},
    {"city_id":"20028","city_name":"Hochspeyer","state_id":"1391"},
    {"city_id":"20029","city_name":"Leiningen","state_id":"1391"},
    {"city_id":"20030","city_name":"Moschheim","state_id":"1391"},
    {"city_id":"20031","city_name":"Murlenbach","state_id":"1391"},
    {"city_id":"20032","city_name":"Neuhofen","state_id":"1391"},
    {"city_id":"20033","city_name":"Nievern","state_id":"1391"},
    {"city_id":"20034","city_name":"Norken","state_id":"1391"},
    {"city_id":"20035","city_name":"Oberlahr","state_id":"1391"},
    {"city_id":"20036","city_name":"Otterstadt","state_id":"1391"},
    {"city_id":"20037","city_name":"Rennerod","state_id":"1391"},
    {"city_id":"20038","city_name":"Rheinbreitbach","state_id":"1391"},
    {"city_id":"20039","city_name":"Rieschweiler-Muhlbach","state_id":"1391"},
    {"city_id":"20040","city_name":"Saarburg","state_id":"1391"},
    {"city_id":"20041","city_name":"Stahlhofen","state_id":"1391"},
    {"city_id":"20042","city_name":"Steinebach","state_id":"1391"},
    {"city_id":"20043","city_name":"Weinsheim","state_id":"1391"},
    {"city_id":"20044","city_name":"Winnweiler","state_id":"1391"},
    {"city_id":"20045","city_name":"Wissen","state_id":"1391"},
    {"city_id":"20046","city_name":"Beckingen","state_id":"1392"},
    {"city_id":"20047","city_name":"Bexbach","state_id":"1392"},
    {"city_id":"20048","city_name":"Blieskastel","state_id":"1392"},
    {"city_id":"20049","city_name":"Dillingen","state_id":"1392"},
    {"city_id":"20050","city_name":"Duppenweiler","state_id":"1392"},
    {"city_id":"20051","city_name":"Eppelborn","state_id":"1392"},
    {"city_id":"20052","city_name":"Friedrichsthal","state_id":"1392"},
    {"city_id":"20053","city_name":"GroBrosseln","state_id":"1392"},
    {"city_id":"20054","city_name":"Heusweiler","state_id":"1392"},
    {"city_id":"20055","city_name":"Homburg","state_id":"1392"},
    {"city_id":"20056","city_name":"Illingen","state_id":"1392"},
    {"city_id":"20057","city_name":"Kirkel","state_id":"1392"},
    {"city_id":"20058","city_name":"Kleinblittersdorf","state_id":"1392"},
    {"city_id":"20059","city_name":"Lebach","state_id":"1392"},
    {"city_id":"20060","city_name":"Losheim","state_id":"1392"},
    {"city_id":"20061","city_name":"Mandelbachtal","state_id":"1392"},
    {"city_id":"20062","city_name":"Marpingen","state_id":"1392"},
    {"city_id":"20063","city_name":"Merchweiler","state_id":"1392"},
    {"city_id":"20064","city_name":"Merzig","state_id":"1392"},
    {"city_id":"20065","city_name":"Mettlach","state_id":"1392"},
    {"city_id":"20066","city_name":"Nalbach","state_id":"1392"},
    {"city_id":"20067","city_name":"Neunkirchen","state_id":"1392"},
    {"city_id":"20068","city_name":"Nohfelden","state_id":"1392"},
    {"city_id":"20069","city_name":"Nonnweiler","state_id":"1392"},
    {"city_id":"20070","city_name":"Oberthal","state_id":"1392"},
    {"city_id":"20071","city_name":"Ottweiler","state_id":"1392"},
    {"city_id":"20072","city_name":"Puttlingen","state_id":"1392"},
    {"city_id":"20073","city_name":"Quierschied","state_id":"1392"},
    {"city_id":"20074","city_name":"Rehlingen-Siersburg","state_id":"1392"},
    {"city_id":"20075","city_name":"Riegelsberg","state_id":"1392"},
    {"city_id":"20076","city_name":"Saarbrucken","state_id":"1392"},
    {"city_id":"20077","city_name":"Saarlouis","state_id":"1392"},
    {"city_id":"20078","city_name":"Saarwellingen","state_id":"1392"},
    {"city_id":"20079","city_name":"Sankt Ingbert","state_id":"1392"},
    {"city_id":"20080","city_name":"Sankt Wendel","state_id":"1392"},
    {"city_id":"20081","city_name":"Schiffweiler","state_id":"1392"},
    {"city_id":"20082","city_name":"Schmelz","state_id":"1392"},
    {"city_id":"20083","city_name":"Schwalbach","state_id":"1392"},
    {"city_id":"20084","city_name":"Spiesen-Elversberg","state_id":"1392"},
    {"city_id":"20085","city_name":"Sulzbach","state_id":"1392"},
    {"city_id":"20086","city_name":"Tholey","state_id":"1392"},
    {"city_id":"20087","city_name":"Uberherrn","state_id":"1392"},
    {"city_id":"20088","city_name":"Volklingen","state_id":"1392"},
    {"city_id":"20089","city_name":"Wadern","state_id":"1392"},
    {"city_id":"20090","city_name":"Wadgassen","state_id":"1392"},
    {"city_id":"20091","city_name":"Wallerfangen","state_id":"1392"},
    {"city_id":"20092","city_name":"Weiskirchen","state_id":"1392"},
    {"city_id":"20093","city_name":"Annaberg-Buchholz","state_id":"1393"},
    {"city_id":"20094","city_name":"Aue","state_id":"1393"},
    {"city_id":"20095","city_name":"Auerbach","state_id":"1393"},
    {"city_id":"20096","city_name":"Bautzen","state_id":"1393"},
    {"city_id":"20097","city_name":"Bischofswerda","state_id":"1393"},
    {"city_id":"20098","city_name":"Borna","state_id":"1393"},
    {"city_id":"20099","city_name":"Brand-Erbisdorf","state_id":"1393"},
    {"city_id":"20100","city_name":"Burgstadt","state_id":"1393"},
    {"city_id":"20101","city_name":"Chemnitz","state_id":"1393"},
    {"city_id":"20102","city_name":"Coswig","state_id":"1393"},
    {"city_id":"20103","city_name":"Crimmitschau","state_id":"1393"},
    {"city_id":"20104","city_name":"Delitzsch","state_id":"1393"},
    {"city_id":"20105","city_name":"Dobeln","state_id":"1393"},
    {"city_id":"20106","city_name":"Dresden","state_id":"1393"},
    {"city_id":"20107","city_name":"Ebersbach","state_id":"1393"},
    {"city_id":"20108","city_name":"Eilenburg","state_id":"1393"},
    {"city_id":"20109","city_name":"Falkenstein","state_id":"1393"},
    {"city_id":"20110","city_name":"Floha","state_id":"1393"},
    {"city_id":"20111","city_name":"Frankenberg","state_id":"1393"},
    {"city_id":"20112","city_name":"Freiberg","state_id":"1393"},
    {"city_id":"20113","city_name":"Freital","state_id":"1393"},
    {"city_id":"20114","city_name":"Friedewald","state_id":"1393"},
    {"city_id":"20115","city_name":"Glauchau","state_id":"1393"},
    {"city_id":"20116","city_name":"Gorlitz","state_id":"1393"},
    {"city_id":"20117","city_name":"Grimma","state_id":"1393"},
    {"city_id":"20118","city_name":"GroBenhain","state_id":"1393"},
    {"city_id":"20119","city_name":"Groditz","state_id":"1393"},
    {"city_id":"20120","city_name":"Hainichen","state_id":"1393"},
    {"city_id":"20121","city_name":"Heidenau","state_id":"1393"},
    {"city_id":"20122","city_name":"Hirschstein","state_id":"1393"},
    {"city_id":"20123","city_name":"Hohenstein-Ernstthal","state_id":"1393"},
    {"city_id":"20124","city_name":"Hoyerswerda","state_id":"1393"},
    {"city_id":"20125","city_name":"Kamenz","state_id":"1393"},
    {"city_id":"20126","city_name":"Klingenthal","state_id":"1393"},
    {"city_id":"20127","city_name":"Leipzig","state_id":"1393"},
    {"city_id":"20128","city_name":"Lichtenstein","state_id":"1393"},
    {"city_id":"20129","city_name":"Limbach-Oberfrohna","state_id":"1393"},
    {"city_id":"20130","city_name":"LoBnitz","state_id":"1393"},
    {"city_id":"20131","city_name":"Lobau","state_id":"1393"},
    {"city_id":"20132","city_name":"Lugau","state_id":"1393"},
    {"city_id":"20133","city_name":"Marienberg","state_id":"1393"},
    {"city_id":"20134","city_name":"Markkleeberg","state_id":"1393"},
    {"city_id":"20135","city_name":"Meerane","state_id":"1393"},
    {"city_id":"20136","city_name":"MeiBen","state_id":"1393"},
    {"city_id":"20137","city_name":"Mittweida","state_id":"1393"},
    {"city_id":"20138","city_name":"Muldenhammer","state_id":"1393"},
    {"city_id":"20139","city_name":"Neustadt","state_id":"1393"},
    {"city_id":"20140","city_name":"Niesky","state_id":"1393"},
    {"city_id":"20141","city_name":"Oelsnitz","state_id":"1393"},
    {"city_id":"20142","city_name":"Olbernhau","state_id":"1393"},
    {"city_id":"20143","city_name":"Olbersdorf","state_id":"1393"},
    {"city_id":"20144","city_name":"Oschatz","state_id":"1393"},
    {"city_id":"20145","city_name":"Pirna","state_id":"1393"},
    {"city_id":"20146","city_name":"Plauen","state_id":"1393"},
    {"city_id":"20147","city_name":"Radeberg","state_id":"1393"},
    {"city_id":"20148","city_name":"Radebeul","state_id":"1393"},
    {"city_id":"20149","city_name":"Reichenbach","state_id":"1393"},
    {"city_id":"20150","city_name":"Riesa","state_id":"1393"},
    {"city_id":"20151","city_name":"Rietschen","state_id":"1393"},
    {"city_id":"20152","city_name":"Schkeuditz","state_id":"1393"},
    {"city_id":"20153","city_name":"Schneeberg","state_id":"1393"},
    {"city_id":"20154","city_name":"Schwarzenberg","state_id":"1393"},
    {"city_id":"20155","city_name":"Sebnitz","state_id":"1393"},
    {"city_id":"20156","city_name":"Stollberg","state_id":"1393"},
    {"city_id":"20157","city_name":"Taubenheim","state_id":"1393"},
    {"city_id":"20158","city_name":"Taucha","state_id":"1393"},
    {"city_id":"20159","city_name":"Thalheim","state_id":"1393"},
    {"city_id":"20160","city_name":"Torgau","state_id":"1393"},
    {"city_id":"20161","city_name":"Waldheim","state_id":"1393"},
    {"city_id":"20162","city_name":"WeiBwasser","state_id":"1393"},
    {"city_id":"20163","city_name":"Werdau","state_id":"1393"},
    {"city_id":"20164","city_name":"Wilkau-HaBlau","state_id":"1393"},
    {"city_id":"20165","city_name":"Wurzen","state_id":"1393"},
    {"city_id":"20166","city_name":"Zittau","state_id":"1393"},
    {"city_id":"20167","city_name":"Zschopau","state_id":"1393"},
    {"city_id":"20168","city_name":"Zwickau","state_id":"1393"},
    {"city_id":"20169","city_name":"Zwonitz","state_id":"1393"},
    {"city_id":"20170","city_name":"Aken","state_id":"1394"},
    {"city_id":"20171","city_name":"Aschersleben","state_id":"1394"},
    {"city_id":"20172","city_name":"Bad Durrenberg","state_id":"1394"},
    {"city_id":"20173","city_name":"Bebitz","state_id":"1394"},
    {"city_id":"20174","city_name":"Bernburg","state_id":"1394"},
    {"city_id":"20175","city_name":"Bitterfeld","state_id":"1394"},
    {"city_id":"20176","city_name":"Blankenburg","state_id":"1394"},
    {"city_id":"20177","city_name":"Braunsbedra","state_id":"1394"},
    {"city_id":"20178","city_name":"Burg","state_id":"1394"},
    {"city_id":"20179","city_name":"Calbe","state_id":"1394"},
    {"city_id":"20180","city_name":"Coswig","state_id":"1394"},
    {"city_id":"20181","city_name":"Dessau","state_id":"1394"},
    {"city_id":"20182","city_name":"Eisleben","state_id":"1394"},
    {"city_id":"20183","city_name":"Gardelegen","state_id":"1394"},
    {"city_id":"20184","city_name":"Genthin","state_id":"1394"},
    {"city_id":"20185","city_name":"Gommern","state_id":"1394"},
    {"city_id":"20186","city_name":"Grafenhainichen","state_id":"1394"},
    {"city_id":"20187","city_name":"Halberstadt","state_id":"1394"},
    {"city_id":"20188","city_name":"Haldensleben","state_id":"1394"},
    {"city_id":"20189","city_name":"Halle","state_id":"1394"},
    {"city_id":"20190","city_name":"Hettstedt","state_id":"1394"},
    {"city_id":"20191","city_name":"Heyrothsberge","state_id":"1394"},
    {"city_id":"20192","city_name":"Hotensleben","state_id":"1394"},
    {"city_id":"20193","city_name":"Kothen","state_id":"1394"},
    {"city_id":"20194","city_name":"Leuna","state_id":"1394"},
    {"city_id":"20195","city_name":"Magdeburg","state_id":"1394"},
    {"city_id":"20196","city_name":"Merseburg","state_id":"1394"},
    {"city_id":"20197","city_name":"Naumburg","state_id":"1394"},
    {"city_id":"20198","city_name":"Oschersleben","state_id":"1394"},
    {"city_id":"20199","city_name":"Osterburg","state_id":"1394"},
    {"city_id":"20200","city_name":"Osterwieck","state_id":"1394"},
    {"city_id":"20201","city_name":"Quedlinburg","state_id":"1394"},
    {"city_id":"20202","city_name":"Querfurt","state_id":"1394"},
    {"city_id":"20203","city_name":"Raguhn","state_id":"1394"},
    {"city_id":"20204","city_name":"RoBlau","state_id":"1394"},
    {"city_id":"20205","city_name":"Salzwedel","state_id":"1394"},
    {"city_id":"20206","city_name":"Sangerhausen","state_id":"1394"},
    {"city_id":"20207","city_name":"Schonebeck","state_id":"1394"},
    {"city_id":"20208","city_name":"StaBfurt","state_id":"1394"},
    {"city_id":"20209","city_name":"Stendal","state_id":"1394"},
    {"city_id":"20210","city_name":"Tangermunde","state_id":"1394"},
    {"city_id":"20211","city_name":"Thale","state_id":"1394"},
    {"city_id":"20212","city_name":"WeiBenfels","state_id":"1394"},
    {"city_id":"20213","city_name":"Wittenberg","state_id":"1394"},
    {"city_id":"20214","city_name":"Wolfen","state_id":"1394"},
    {"city_id":"20215","city_name":"Wolmirstedt","state_id":"1394"},
    {"city_id":"20216","city_name":"Zeitz","state_id":"1394"},
    {"city_id":"20217","city_name":"Zerbst","state_id":"1394"},
    {"city_id":"20218","city_name":"Bad Lausick","state_id":"1395"},
    {"city_id":"20219","city_name":"Bernsdorf","state_id":"1395"},
    {"city_id":"20220","city_name":"Borde-Hakel","state_id":"1395"},
    {"city_id":"20221","city_name":"Gelenau","state_id":"1395"},
    {"city_id":"20222","city_name":"Groberkmannsdorf ","state_id":"1395"},
    {"city_id":"20223","city_name":"Hartha","state_id":"1395"},
    {"city_id":"20224","city_name":"Kreischa","state_id":"1395"},
    {"city_id":"20225","city_name":"Malschwitz","state_id":"1395"},
    {"city_id":"20226","city_name":"Naunhof","state_id":"1395"},
    {"city_id":"20227","city_name":"Pausa","state_id":"1395"},
    {"city_id":"20228","city_name":"Seiffen","state_id":"1395"},
    {"city_id":"20229","city_name":"Stutzengrun","state_id":"1395"},
    {"city_id":"20230","city_name":"Ahrensbok","state_id":"1396"},
    {"city_id":"20231","city_name":"Ahrensburg","state_id":"1396"},
    {"city_id":"20232","city_name":"Altenholz","state_id":"1396"},
    {"city_id":"20233","city_name":"Alveslohe","state_id":"1396"},
    {"city_id":"20234","city_name":"Ammersbek","state_id":"1396"},
    {"city_id":"20235","city_name":"Bad Bramstedt","state_id":"1396"},
    {"city_id":"20236","city_name":"Bad Oldesloe","state_id":"1396"},
    {"city_id":"20237","city_name":"Bad Schwartau","state_id":"1396"},
    {"city_id":"20238","city_name":"Bad Segeberg","state_id":"1396"},
    {"city_id":"20239","city_name":"Bargteheide","state_id":"1396"},
    {"city_id":"20240","city_name":"Barmstedt","state_id":"1396"},
    {"city_id":"20241","city_name":"Barsbuttel","state_id":"1396"},
    {"city_id":"20242","city_name":"Bredstedt","state_id":"1396"},
    {"city_id":"20243","city_name":"Brunsbuttel","state_id":"1396"},
    {"city_id":"20244","city_name":"Budelsdorf","state_id":"1396"},
    {"city_id":"20245","city_name":"Eckernforde","state_id":"1396"},
    {"city_id":"20246","city_name":"Eddelak","state_id":"1396"},
    {"city_id":"20247","city_name":"Elmshorn","state_id":"1396"},
    {"city_id":"20248","city_name":"Eutin","state_id":"1396"},
    {"city_id":"20249","city_name":"Flensburg","state_id":"1396"},
    {"city_id":"20250","city_name":"Friedrichstadt","state_id":"1396"},
    {"city_id":"20251","city_name":"Geesthacht","state_id":"1396"},
    {"city_id":"20252","city_name":"Glinde","state_id":"1396"},
    {"city_id":"20253","city_name":"Gluckstadt","state_id":"1396"},
    {"city_id":"20254","city_name":"Grob Pampau","state_id":"1396"},
    {"city_id":"20255","city_name":"Halstenbek","state_id":"1396"},
    {"city_id":"20256","city_name":"Hamfelde","state_id":"1396"},
    {"city_id":"20257","city_name":"Harrislee","state_id":"1396"},
    {"city_id":"20258","city_name":"Hartenholm","state_id":"1396"},
    {"city_id":"20259","city_name":"Heide","state_id":"1396"},
    {"city_id":"20260","city_name":"Heiligenhafen","state_id":"1396"},
    {"city_id":"20261","city_name":"Henstedt-Ulzburg","state_id":"1396"},
    {"city_id":"20262","city_name":"Honenwestedt","state_id":"1396"},
    {"city_id":"20263","city_name":"Husum","state_id":"1396"},
    {"city_id":"20264","city_name":"Itzehoe","state_id":"1396"},
    {"city_id":"20265","city_name":"Kaltenkirchen","state_id":"1396"},
    {"city_id":"20266","city_name":"Kappeln","state_id":"1396"},
    {"city_id":"20267","city_name":"Kiel","state_id":"1396"},
    {"city_id":"20268","city_name":"Kronshagen","state_id":"1396"},
    {"city_id":"20269","city_name":"Lauenburg","state_id":"1396"},
    {"city_id":"20270","city_name":"Lensahn","state_id":"1396"},
    {"city_id":"20271","city_name":"Lubeck","state_id":"1396"},
    {"city_id":"20272","city_name":"Malente","state_id":"1396"},
    {"city_id":"20273","city_name":"Mielkendorf","state_id":"1396"},
    {"city_id":"20274","city_name":"Molfsee","state_id":"1396"},
    {"city_id":"20275","city_name":"Molln","state_id":"1396"},
    {"city_id":"20276","city_name":"Neuenbrook","state_id":"1396"},
    {"city_id":"20277","city_name":"Neumunster","state_id":"1396"},
    {"city_id":"20278","city_name":"Neustadt","state_id":"1396"},
    {"city_id":"20279","city_name":"Norderstedt","state_id":"1396"},
    {"city_id":"20280","city_name":"Oldenburg","state_id":"1396"},
    {"city_id":"20281","city_name":"Oststeinbek","state_id":"1396"},
    {"city_id":"20282","city_name":"Pinneberg","state_id":"1396"},
    {"city_id":"20283","city_name":"Plon","state_id":"1396"},
    {"city_id":"20284","city_name":"Preetz","state_id":"1396"},
    {"city_id":"20285","city_name":"Quickborn","state_id":"1396"},
    {"city_id":"20286","city_name":"Ratekau","state_id":"1396"},
    {"city_id":"20287","city_name":"Ratzeburg","state_id":"1396"},
    {"city_id":"20288","city_name":"Reinbek","state_id":"1396"},
    {"city_id":"20289","city_name":"Reinfeld","state_id":"1396"},
    {"city_id":"20290","city_name":"Rellingen","state_id":"1396"},
    {"city_id":"20291","city_name":"Rendsburg","state_id":"1396"},
    {"city_id":"20292","city_name":"Rethwisch","state_id":"1396"},
    {"city_id":"20293","city_name":"Satrup","state_id":"1396"},
    {"city_id":"20294","city_name":"Scharbeutz","state_id":"1396"},
    {"city_id":"20295","city_name":"Schenefeld","state_id":"1396"},
    {"city_id":"20296","city_name":"Schleswig","state_id":"1396"},
    {"city_id":"20297","city_name":"Schmalfeld","state_id":"1396"},
    {"city_id":"20298","city_name":"Schoenkirchen","state_id":"1396"},
    {"city_id":"20299","city_name":"Schwarzenbek","state_id":"1396"},
    {"city_id":"20300","city_name":"Seefeld","state_id":"1396"},
    {"city_id":"20301","city_name":"Sievershutten","state_id":"1396"},
    {"city_id":"20302","city_name":"Stockelsdorf","state_id":"1396"},
    {"city_id":"20303","city_name":"Tangstedt","state_id":"1396"},
    {"city_id":"20304","city_name":"Timmendorfer Strand","state_id":"1396"},
    {"city_id":"20305","city_name":"Tornesch","state_id":"1396"},
    {"city_id":"20306","city_name":"Travemunde","state_id":"1396"},
    {"city_id":"20307","city_name":"Uetersen","state_id":"1396"},
    {"city_id":"20308","city_name":"Wahlstedt","state_id":"1396"},
    {"city_id":"20309","city_name":"Wedel","state_id":"1396"},
    {"city_id":"20310","city_name":"Wentorf","state_id":"1396"},
    {"city_id":"20311","city_name":"Westerland","state_id":"1396"},
    {"city_id":"20312","city_name":"Westerronfeld","state_id":"1396"},
    {"city_id":"20313","city_name":"Wohltorf","state_id":"1396"},
    {"city_id":"20314","city_name":"Wotersen","state_id":"1396"},
    {"city_id":"20315","city_name":"Altenburg","state_id":"1397"},
    {"city_id":"20316","city_name":"Apolda","state_id":"1397"},
    {"city_id":"20317","city_name":"Arnstadt","state_id":"1397"},
    {"city_id":"20318","city_name":"Bad Frankenhausen","state_id":"1397"},
    {"city_id":"20319","city_name":"Bad Langensalza","state_id":"1397"},
    {"city_id":"20320","city_name":"Bad Salzungen","state_id":"1397"},
    {"city_id":"20321","city_name":"Cursdorf","state_id":"1397"},
    {"city_id":"20322","city_name":"Dornburg","state_id":"1397"},
    {"city_id":"20323","city_name":"Eisenach","state_id":"1397"},
    {"city_id":"20324","city_name":"Eisenberg","state_id":"1397"},
    {"city_id":"20325","city_name":"Erfurt","state_id":"1397"},
    {"city_id":"20326","city_name":"Gera","state_id":"1397"},
    {"city_id":"20327","city_name":"Geschwenda","state_id":"1397"},
    {"city_id":"20328","city_name":"Gotha","state_id":"1397"},
    {"city_id":"20329","city_name":"Greiz","state_id":"1397"},
    {"city_id":"20330","city_name":"Heiligenstadt","state_id":"1397"},
    {"city_id":"20331","city_name":"Hermsdorf","state_id":"1397"},
    {"city_id":"20332","city_name":"Hildburghausen","state_id":"1397"},
    {"city_id":"20333","city_name":"Ilmenau","state_id":"1397"},
    {"city_id":"20334","city_name":"Immelborn","state_id":"1397"},
    {"city_id":"20335","city_name":"Jena","state_id":"1397"},
    {"city_id":"20336","city_name":"Leinefelde","state_id":"1397"},
    {"city_id":"20337","city_name":"Leutenberg","state_id":"1397"},
    {"city_id":"20338","city_name":"Meiningen","state_id":"1397"},
    {"city_id":"20339","city_name":"Meuselwitz","state_id":"1397"},
    {"city_id":"20340","city_name":"Muhlhausen","state_id":"1397"},
    {"city_id":"20341","city_name":"Neustadt","state_id":"1397"},
    {"city_id":"20342","city_name":"Nordhausen","state_id":"1397"},
    {"city_id":"20343","city_name":"PoBneck","state_id":"1397"},
    {"city_id":"20344","city_name":"Rosenthal","state_id":"1397"},
    {"city_id":"20345","city_name":"Rositz","state_id":"1397"},
    {"city_id":"20346","city_name":"Rudolstadt","state_id":"1397"},
    {"city_id":"20347","city_name":"Ruhla","state_id":"1397"},
    {"city_id":"20348","city_name":"Saalfeld","state_id":"1397"},
    {"city_id":"20349","city_name":"Schmalkalden","state_id":"1397"},
    {"city_id":"20350","city_name":"Schmolln","state_id":"1397"},
    {"city_id":"20351","city_name":"Sommerda","state_id":"1397"},
    {"city_id":"20352","city_name":"Sondershausen","state_id":"1397"},
    {"city_id":"20353","city_name":"Sonneberg","state_id":"1397"},
    {"city_id":"20354","city_name":"Suhl","state_id":"1397"},
    {"city_id":"20355","city_name":"Triptis","state_id":"1397"},
    {"city_id":"20356","city_name":"Uhlstadt","state_id":"1397"},
    {"city_id":"20357","city_name":"Waltershausen","state_id":"1397"},
    {"city_id":"20358","city_name":"Weida","state_id":"1397"},
    {"city_id":"20359","city_name":"Weimar","state_id":"1397"},
    {"city_id":"20360","city_name":"Wernigerode","state_id":"1397"},
    {"city_id":"20361","city_name":"Wormstedt","state_id":"1397"},
    {"city_id":"20362","city_name":"Zella-Mehlis","state_id":"1397"},
    {"city_id":"20363","city_name":"Zeulenroda","state_id":"1397"},
    {"city_id":"20364","city_name":"Webling","state_id":"1398"},
    {"city_id":"20365","city_name":"Neustadt","state_id":"1399"},
    {"city_id":"20366","city_name":"Schlobborn","state_id":"1400"},
    {"city_id":"20367","city_name":"Agogo","state_id":"1401"},
    {"city_id":"20368","city_name":"Bekwai","state_id":"1401"},
    {"city_id":"20369","city_name":"Konongo","state_id":"1401"},
    {"city_id":"20370","city_name":"Kumasi","state_id":"1401"},
    {"city_id":"20371","city_name":"Mampong","state_id":"1401"},
    {"city_id":"20372","city_name":"Mankranso","state_id":"1401"},
    {"city_id":"20373","city_name":"Obuasi","state_id":"1401"},
    {"city_id":"20374","city_name":"Ofinso","state_id":"1401"},
    {"city_id":"20375","city_name":"Tafo","state_id":"1401"},
    {"city_id":"20376","city_name":"Bechem","state_id":"1402"},
    {"city_id":"20377","city_name":"Berekum","state_id":"1402"},
    {"city_id":"20378","city_name":"Duayaw Nkwanta","state_id":"1402"},
    {"city_id":"20379","city_name":"Kintampo","state_id":"1402"},
    {"city_id":"20380","city_name":"Sunyani","state_id":"1402"},
    {"city_id":"20381","city_name":"Techiman","state_id":"1402"},
    {"city_id":"20382","city_name":"Wenchi","state_id":"1402"},
    {"city_id":"20383","city_name":"Apam","state_id":"1403"},
    {"city_id":"20384","city_name":"Cape Coast","state_id":"1403"},
    {"city_id":"20385","city_name":"Dunkwa","state_id":"1403"},
    {"city_id":"20386","city_name":"Elmina","state_id":"1403"},
    {"city_id":"20387","city_name":"Foso","state_id":"1403"},
    {"city_id":"20388","city_name":"Komenda","state_id":"1403"},
    {"city_id":"20389","city_name":"Mauri","state_id":"1403"},
    {"city_id":"20390","city_name":"Mumford","state_id":"1403"},
    {"city_id":"20391","city_name":"Nyakrom","state_id":"1403"},
    {"city_id":"20392","city_name":"Okitsiu","state_id":"1403"},
    {"city_id":"20393","city_name":"Saltpond","state_id":"1403"},
    {"city_id":"20394","city_name":"Swedru","state_id":"1403"},
    {"city_id":"20395","city_name":"Winneba","state_id":"1403"},
    {"city_id":"20396","city_name":"Aburi","state_id":"1404"},
    {"city_id":"20397","city_name":"Ada","state_id":"1404"},
    {"city_id":"20398","city_name":"Akim Swedru","state_id":"1404"},
    {"city_id":"20399","city_name":"Akropong","state_id":"1404"},
    {"city_id":"20400","city_name":"Asamankese","state_id":"1404"},
    {"city_id":"20401","city_name":"Begoro","state_id":"1404"},
    {"city_id":"20402","city_name":"Kade","state_id":"1404"},
    {"city_id":"20403","city_name":"Kibi","state_id":"1404"},
    {"city_id":"20404","city_name":"Koforidua","state_id":"1404"},
    {"city_id":"20405","city_name":"Mpraeso","state_id":"1404"},
    {"city_id":"20406","city_name":"Nkawkaw","state_id":"1404"},
    {"city_id":"20407","city_name":"Nsawam","state_id":"1404"},
    {"city_id":"20408","city_name":"Oda","state_id":"1404"},
    {"city_id":"20409","city_name":"Somanya","state_id":"1404"},
    {"city_id":"20410","city_name":"Suhum","state_id":"1404"},
    {"city_id":"20411","city_name":"Kpandae","state_id":"1406"},
    {"city_id":"20412","city_name":"Salaga","state_id":"1406"},
    {"city_id":"20413","city_name":"Savelugu","state_id":"1406"},
    {"city_id":"20414","city_name":"Tamale","state_id":"1406"},
    {"city_id":"20415","city_name":"Yendi","state_id":"1406"},
    {"city_id":"20416","city_name":"Aflao","state_id":"1409"},
    {"city_id":"20417","city_name":"Anloga","state_id":"1409"},
    {"city_id":"20418","city_name":"Ho","state_id":"1409"},
    {"city_id":"20419","city_name":"Hohoe","state_id":"1409"},
    {"city_id":"20420","city_name":"Keta","state_id":"1409"},
    {"city_id":"20421","city_name":"Kete-Krachi","state_id":"1409"},
    {"city_id":"20422","city_name":"Kpandu","state_id":"1409"},
    {"city_id":"20423","city_name":"Aboso","state_id":"1410"},
    {"city_id":"20424","city_name":"Anomabu","state_id":"1410"},
    {"city_id":"20425","city_name":"Axim","state_id":"1410"},
    {"city_id":"20426","city_name":"Bibiani","state_id":"1410"},
    {"city_id":"20427","city_name":"Prestea","state_id":"1410"},
    {"city_id":"20428","city_name":"Sekondi","state_id":"1410"},
    {"city_id":"20429","city_name":"Shama","state_id":"1410"},
    {"city_id":"20430","city_name":"Takoradi","state_id":"1410"},
    {"city_id":"20431","city_name":"Tarkwa","state_id":"1410"},
    {"city_id":"20432","city_name":"Gibraltar","state_id":"1411"},
    {"city_id":"20433","city_name":"Elassonos","state_id":"1412"},
    {"city_id":"20434","city_name":"Aiyion","state_id":"1413"},
    {"city_id":"20435","city_name":"Patra","state_id":"1413"},
    {"city_id":"20436","city_name":"Argos","state_id":"1415"},
    {"city_id":"20437","city_name":"Navplion","state_id":"1415"},
    {"city_id":"20438","city_name":"Tripoli","state_id":"1416"},
    {"city_id":"20439","city_name":"Arta","state_id":"1417"},
    {"city_id":"20440","city_name":"Acharnes","state_id":"1418"},
    {"city_id":"20441","city_name":"Agios Ioannis Rentis","state_id":"1418"},
    {"city_id":"20442","city_name":"Drapetsona","state_id":"1418"},
    {"city_id":"20443","city_name":"Koropi","state_id":"1418"},
    {"city_id":"20444","city_name":"Lavrion","state_id":"1418"},
    {"city_id":"20445","city_name":"Mandra","state_id":"1418"},
    {"city_id":"20446","city_name":"Spata","state_id":"1418"},
    {"city_id":"20447","city_name":"Aharna","state_id":"1419"},
    {"city_id":"20448","city_name":"Aiyaleo","state_id":"1419"},
    {"city_id":"20449","city_name":"Alimos","state_id":"1419"},
    {"city_id":"20450","city_name":"Amarousion","state_id":"1419"},
    {"city_id":"20451","city_name":"Ano Liosia","state_id":"1419"},
    {"city_id":"20452","city_name":"Aryiroupoli","state_id":"1419"},
    {"city_id":"20453","city_name":"Aspropirgos","state_id":"1419"},
    {"city_id":"20454","city_name":"Athina","state_id":"1419"},
    {"city_id":"20455","city_name":"Athinai","state_id":"1419"},
    {"city_id":"20456","city_name":"Ayia Barbara","state_id":"1419"},
    {"city_id":"20457","city_name":"Ayia Paraskevi","state_id":"1419"},
    {"city_id":"20458","city_name":"Ayios Anaryiros","state_id":"1419"},
    {"city_id":"20459","city_name":"Ayios Dimitrios","state_id":"1419"},
    {"city_id":"20460","city_name":"Dafne","state_id":"1419"},
    {"city_id":"20461","city_name":"Elevsis","state_id":"1419"},
    {"city_id":"20462","city_name":"Ellenikon","state_id":"1419"},
    {"city_id":"20463","city_name":"Galatsion","state_id":"1419"},
    {"city_id":"20464","city_name":"Glifada","state_id":"1419"},
    {"city_id":"20465","city_name":"Haidarion","state_id":"1419"},
    {"city_id":"20466","city_name":"Halandrion","state_id":"1419"},
    {"city_id":"20467","city_name":"Holargos","state_id":"1419"},
    {"city_id":"20468","city_name":"Ilioupoli","state_id":"1419"},
    {"city_id":"20469","city_name":"Iraklion","state_id":"1419"},
    {"city_id":"20470","city_name":"Kaisariani","state_id":"1419"},
    {"city_id":"20471","city_name":"Kallithea","state_id":"1419"},
    {"city_id":"20472","city_name":"Kamateron","state_id":"1419"},
    {"city_id":"20473","city_name":"Keratea","state_id":"1419"},
    {"city_id":"20474","city_name":"Keratsinion","state_id":"1419"},
    {"city_id":"20475","city_name":"Kifisia","state_id":"1419"},
    {"city_id":"20476","city_name":"Koridallos","state_id":"1419"},
    {"city_id":"20477","city_name":"Kropion","state_id":"1419"},
    {"city_id":"20478","city_name":"Markopoulos Mesogaia","state_id":"1419"},
    {"city_id":"20479","city_name":"Maroussi","state_id":"1419"},
    {"city_id":"20480","city_name":"Megara","state_id":"1419"},
    {"city_id":"20481","city_name":"Melission","state_id":"1419"},
    {"city_id":"20482","city_name":"Metamorfosios","state_id":"1419"},
    {"city_id":"20483","city_name":"Moshatos","state_id":"1419"},
    {"city_id":"20484","city_name":"Nea Filedelfia","state_id":"1419"},
    {"city_id":"20485","city_name":"Nea Ionia","state_id":"1419"},
    {"city_id":"20486","city_name":"Nea Liosia","state_id":"1419"},
    {"city_id":"20487","city_name":"Nea Smirni","state_id":"1419"},
    {"city_id":"20488","city_name":"Nikaia","state_id":"1419"},
    {"city_id":"20489","city_name":"Palaion Faliron","state_id":"1419"},
    {"city_id":"20490","city_name":"Perama","state_id":"1419"},
    {"city_id":"20491","city_name":"Peristerion","state_id":"1419"},
    {"city_id":"20492","city_name":"Petroupoli","state_id":"1419"},
    {"city_id":"20493","city_name":"Pevka","state_id":"1419"},
    {"city_id":"20494","city_name":"Piraeus","state_id":"1419"},
    {"city_id":"20495","city_name":"Salamis","state_id":"1419"},
    {"city_id":"20496","city_name":"Tavros","state_id":"1419"},
    {"city_id":"20497","city_name":"Viron","state_id":"1419"},
    {"city_id":"20498","city_name":"Voula","state_id":"1419"},
    {"city_id":"20499","city_name":"Vrilission","state_id":"1419"},
    {"city_id":"20500","city_name":"Zografos","state_id":"1419"},
    {"city_id":"20501","city_name":"Heraklion","state_id":"1421"},
    {"city_id":"20502","city_name":"Arhangelos","state_id":"1422"},
    {"city_id":"20503","city_name":"Ialysos","state_id":"1422"},
    {"city_id":"20504","city_name":"Kos","state_id":"1422"},
    {"city_id":"20505","city_name":"Rodos","state_id":"1422"},
    {"city_id":"20506","city_name":"Drama","state_id":"1423"},
    {"city_id":"20507","city_name":"Chalkis","state_id":"1424"},
    {"city_id":"20508","city_name":"Karpenisi","state_id":"1425"},
    {"city_id":"20509","city_name":"Alexandroupoli","state_id":"1426"},
    {"city_id":"20510","city_name":"Orestias","state_id":"1426"},
    {"city_id":"20511","city_name":"Halkida","state_id":"1427"},
    {"city_id":"20512","city_name":"Florina","state_id":"1428"},
    {"city_id":"20513","city_name":"Amfissa","state_id":"1429"},
    {"city_id":"20514","city_name":"Lamia","state_id":"1430"},
    {"city_id":"20515","city_name":"Grevena","state_id":"1431"},
    {"city_id":"20516","city_name":"Halandri","state_id":"1432"},
    {"city_id":"20517","city_name":"Lakkoma","state_id":"1433"},
    {"city_id":"20518","city_name":"N. Kallikrateia","state_id":"1433"},
    {"city_id":"20519","city_name":"Poliyiros","state_id":"1433"},
    {"city_id":"20520","city_name":"Hania","state_id":"1434"},
    {"city_id":"20521","city_name":"Crete","state_id":"1435"},
    {"city_id":"20522","city_name":"Hios","state_id":"1436"},
    {"city_id":"20523","city_name":"Pirgos","state_id":"1437"},
    {"city_id":"20524","city_name":"Veroia","state_id":"1438"},
    {"city_id":"20525","city_name":"Ioannina","state_id":"1439"},
    {"city_id":"20526","city_name":"Karditsa","state_id":"1441"},
    {"city_id":"20527","city_name":"Kastoria","state_id":"1442"},
    {"city_id":"20528","city_name":"Kavala","state_id":"1443"},
    {"city_id":"20529","city_name":"Agioi Theodoroi","state_id":"1444"},
    {"city_id":"20530","city_name":"Argostolion","state_id":"1444"},
    {"city_id":"20531","city_name":"Kerkira","state_id":"1445"},
    {"city_id":"20532","city_name":"Ermoupoli","state_id":"1446"},
    {"city_id":"20533","city_name":"Fira","state_id":"1446"},
    {"city_id":"20534","city_name":"Mikonos","state_id":"1446"},
    {"city_id":"20535","city_name":"Kilkis","state_id":"1447"},
    {"city_id":"20536","city_name":"Korinthos","state_id":"1448"},
    {"city_id":"20537","city_name":"Kozani","state_id":"1449"},
    {"city_id":"20538","city_name":"Ptolemais","state_id":"1449"},
    {"city_id":"20539","city_name":"Sparti","state_id":"1450"},
    {"city_id":"20540","city_name":"Larisa","state_id":"1451"},
    {"city_id":"20541","city_name":"Larissa","state_id":"1451"},
    {"city_id":"20542","city_name":"Ayios Nikolaos","state_id":"1452"},
    {"city_id":"20543","city_name":"Ierapetra","state_id":"1452"},
    {"city_id":"20544","city_name":"Sitia","state_id":"1452"},
    {"city_id":"20545","city_name":"Mitilini","state_id":"1453"},
    {"city_id":"20546","city_name":"Levkas","state_id":"1454"},
    {"city_id":"20547","city_name":"Volos","state_id":"1455"},
    {"city_id":"20548","city_name":"Kalamata","state_id":"1456"},
    {"city_id":"20549","city_name":"Edessa","state_id":"1459"},
    {"city_id":"20550","city_name":"Yiannitsa","state_id":"1459"},
    {"city_id":"20551","city_name":"Katerini","state_id":"1460"},
    {"city_id":"20552","city_name":"Acharne","state_id":"1461"},
    {"city_id":"20553","city_name":"Pallini","state_id":"1461"},
    {"city_id":"20554","city_name":"Preveza","state_id":"1462"},
    {"city_id":"20555","city_name":"Rethimnon","state_id":"1463"},
    {"city_id":"20556","city_name":"Komotini","state_id":"1464"},
    {"city_id":"20557","city_name":"Samos","state_id":"1465"},
    {"city_id":"20558","city_name":"Serrai","state_id":"1466"},
    {"city_id":"20559","city_name":"Igoumenitsa","state_id":"1467"},
    {"city_id":"20560","city_name":"Ampelokipa","state_id":"1468"},
    {"city_id":"20561","city_name":"Kalamaria","state_id":"1468"},
    {"city_id":"20562","city_name":"Neapoli","state_id":"1468"},
    {"city_id":"20563","city_name":"Oristiada","state_id":"1468"},
    {"city_id":"20564","city_name":"Thessaloniki","state_id":"1468"},
    {"city_id":"20565","city_name":"Trikala","state_id":"1469"},
    {"city_id":"20566","city_name":"Levadia","state_id":"1470"},
    {"city_id":"20567","city_name":"Thivai","state_id":"1470"},
    {"city_id":"20568","city_name":"Xanthi","state_id":"1472"},
    {"city_id":"20569","city_name":"Zakinthos","state_id":"1473"},
    {"city_id":"20570","city_name":"Aasiaat","state_id":"1474"},
    {"city_id":"20571","city_name":"Akunnaaq","state_id":"1474"},
    {"city_id":"20572","city_name":"Kitsissuarsuit","state_id":"1474"},
    {"city_id":"20573","city_name":"Ikkatteq","state_id":"1475"},
    {"city_id":"20574","city_name":"Isortoq","state_id":"1475"},
    {"city_id":"20575","city_name":"Kulusuk","state_id":"1475"},
    {"city_id":"20576","city_name":"Kuumiut","state_id":"1475"},
    {"city_id":"20577","city_name":"Qernertuarssuit","state_id":"1475"},
    {"city_id":"20578","city_name":"Sermiligaaq","state_id":"1475"},
    {"city_id":"20579","city_name":"Tasiilaq","state_id":"1475"},
    {"city_id":"20580","city_name":"Tiniteqilaaq","state_id":"1475"},
    {"city_id":"20581","city_name":"Illoqqortoormiut","state_id":"1476"},
    {"city_id":"20582","city_name":"Itterajivit","state_id":"1476"},
    {"city_id":"20583","city_name":"Uunarteq","state_id":"1476"},
    {"city_id":"20584","city_name":"Ilimanaq","state_id":"1477"},
    {"city_id":"20585","city_name":"Ilulissat","state_id":"1477"},
    {"city_id":"20586","city_name":"Oqaatsut","state_id":"1477"},
    {"city_id":"20587","city_name":"Qeqertaq","state_id":"1477"},
    {"city_id":"20588","city_name":"Saqqaq","state_id":"1477"},
    {"city_id":"20589","city_name":"Ivittuut","state_id":"1478"},
    {"city_id":"20590","city_name":"Kangilinnguit","state_id":"1478"},
    {"city_id":"20591","city_name":"Attu","state_id":"1479"},
    {"city_id":"20592","city_name":"Iginniarfik","state_id":"1479"},
    {"city_id":"20593","city_name":"Ikerasaarsuk","state_id":"1479"},
    {"city_id":"20594","city_name":"Kangaatsiaq","state_id":"1479"},
    {"city_id":"20595","city_name":"Niaqornaarsuk","state_id":"1479"},
    {"city_id":"20596","city_name":"Atammik","state_id":"1480"},
    {"city_id":"20597","city_name":"Kangaamiut","state_id":"1480"},
    {"city_id":"20598","city_name":"Maniitsoq","state_id":"1480"},
    {"city_id":"20599","city_name":"Napasoq","state_id":"1480"},
    {"city_id":"20600","city_name":"Aappilattoq","state_id":"1481"},
    {"city_id":"20601","city_name":"Akuliaruseq","state_id":"1481"},
    {"city_id":"20602","city_name":"Alluitsoq","state_id":"1481"},
    {"city_id":"20603","city_name":"Alluitsup Paa","state_id":"1481"},
    {"city_id":"20604","city_name":"Ammassivik","state_id":"1481"},
    {"city_id":"20605","city_name":"Ikerasassuaq","state_id":"1481"},
    {"city_id":"20606","city_name":"Nanortalik","state_id":"1481"},
    {"city_id":"20607","city_name":"Narsarmijit","state_id":"1481"},
    {"city_id":"20608","city_name":"Nuugaarsuk","state_id":"1481"},
    {"city_id":"20609","city_name":"Qallimiut","state_id":"1481"},
    {"city_id":"20610","city_name":"Qortortorsuaq","state_id":"1481"},
    {"city_id":"20611","city_name":"Tasiusaq","state_id":"1481"},
    {"city_id":"20612","city_name":"Amannguit","state_id":"1482"},
    {"city_id":"20613","city_name":"Atarnaatsoq","state_id":"1482"},
    {"city_id":"20614","city_name":"Eqaluit Ilua","state_id":"1482"},
    {"city_id":"20615","city_name":"Igaliku","state_id":"1482"},
    {"city_id":"20616","city_name":"Igaliku Kujalleq","state_id":"1482"},
    {"city_id":"20617","city_name":"Inneruulalik","state_id":"1482"},
    {"city_id":"20618","city_name":"Issormiut","state_id":"1482"},
    {"city_id":"20619","city_name":"Iterlak","state_id":"1482"},
    {"city_id":"20620","city_name":"Kangerlua","state_id":"1482"},
    {"city_id":"20621","city_name":"Narsaq","state_id":"1482"},
    {"city_id":"20622","city_name":"Narsarsuaq","state_id":"1482"},
    {"city_id":"20623","city_name":"Nunataaq","state_id":"1482"},
    {"city_id":"20624","city_name":"Qassiarsuk","state_id":"1482"},
    {"city_id":"20625","city_name":"Qinngua","state_id":"1482"},
    {"city_id":"20626","city_name":"Qinngua Kangilleq","state_id":"1482"},
    {"city_id":"20627","city_name":"Qolortup Itinnera","state_id":"1482"},
    {"city_id":"20628","city_name":"Sillisit","state_id":"1482"},
    {"city_id":"20629","city_name":"Tasiusaq","state_id":"1482"},
    {"city_id":"20630","city_name":"Timerliit","state_id":"1482"},
    {"city_id":"20631","city_name":"Uummannartuuaraq","state_id":"1482"},
    {"city_id":"20632","city_name":"Kangerluarsoruseq","state_id":"1483"},
    {"city_id":"20633","city_name":"Kapisillit","state_id":"1483"},
    {"city_id":"20634","city_name":"Neriunaq","state_id":"1483"},
    {"city_id":"20635","city_name":"Nuuk","state_id":"1483"},
    {"city_id":"20636","city_name":"Qeqertarsuatsiaat","state_id":"1483"},
    {"city_id":"20637","city_name":"Qooqqut","state_id":"1483"},
    {"city_id":"20638","city_name":"Qoornoq","state_id":"1483"},
    {"city_id":"20639","city_name":"Arsuk","state_id":"1484"},
    {"city_id":"20640","city_name":"Paamiut","state_id":"1484"},
    {"city_id":"20641","city_name":"Moriusaq","state_id":"1485"},
    {"city_id":"20642","city_name":"Qaanaaq","state_id":"1485"},
    {"city_id":"20643","city_name":"Qeqertarsuaq","state_id":"1485"},
    {"city_id":"20644","city_name":"Qeqertat","state_id":"1485"},
    {"city_id":"20645","city_name":"Savissivik","state_id":"1485"},
    {"city_id":"20646","city_name":"Siorapaluk","state_id":"1485"},
    {"city_id":"20647","city_name":"Eqalugaarsuit","state_id":"1486"},
    {"city_id":"20648","city_name":"Illorsuit","state_id":"1486"},
    {"city_id":"20649","city_name":"Kangerluarsorujuk","state_id":"1486"},
    {"city_id":"20650","city_name":"Kangerluarsorujuup Qinngua","state_id":"1486"},
    {"city_id":"20651","city_name":"Qanisartuut","state_id":"1486"},
    {"city_id":"20652","city_name":"Qaqortokolook","state_id":"1486"},
    {"city_id":"20653","city_name":"Qaqortoq","state_id":"1486"},
    {"city_id":"20654","city_name":"Qassimiut","state_id":"1486"},
    {"city_id":"20655","city_name":"Saarloq","state_id":"1486"},
    {"city_id":"20656","city_name":"Saqqamiut","state_id":"1486"},
    {"city_id":"20657","city_name":"Tasiluk","state_id":"1486"},
    {"city_id":"20658","city_name":"Upernaviarsuk","state_id":"1486"},
    {"city_id":"20659","city_name":"Ikamiut","state_id":"1487"},
    {"city_id":"20660","city_name":"Qasigiannguit","state_id":"1487"},
    {"city_id":"20661","city_name":"Kangerluk","state_id":"1488"},
    {"city_id":"20662","city_name":"Qeqertarsuaq","state_id":"1488"},
    {"city_id":"20663","city_name":"Itilleq","state_id":"1489"},
    {"city_id":"20664","city_name":"Kangerlussuaq","state_id":"1489"},
    {"city_id":"20665","city_name":"Sarfannguit","state_id":"1489"},
    {"city_id":"20666","city_name":"Sisimiut","state_id":"1489"},
    {"city_id":"20667","city_name":"Aappilattoq","state_id":"1491"},
    {"city_id":"20668","city_name":"Ikerakuuk","state_id":"1491"},
    {"city_id":"20669","city_name":"Innarsuit","state_id":"1491"},
    {"city_id":"20670","city_name":"Kangersuatsiaq","state_id":"1491"},
    {"city_id":"20671","city_name":"Kullorsuaq","state_id":"1491"},
    {"city_id":"20672","city_name":"Naajaat","state_id":"1491"},
    {"city_id":"20673","city_name":"Nutaarmiut","state_id":"1491"},
    {"city_id":"20674","city_name":"Nuusuaq","state_id":"1491"},
    {"city_id":"20675","city_name":"Tasiusaq","state_id":"1491"},
    {"city_id":"20676","city_name":"Upernavik","state_id":"1491"},
    {"city_id":"20677","city_name":"Upernavik Kujalleq","state_id":"1491"},
    {"city_id":"20678","city_name":"Ikerasak","state_id":"1492"},
    {"city_id":"20679","city_name":"Illorsuit","state_id":"1492"},
    {"city_id":"20680","city_name":"Niaqornat","state_id":"1492"},
    {"city_id":"20681","city_name":"Nuugaatsiaq","state_id":"1492"},
    {"city_id":"20682","city_name":"Qaarsut","state_id":"1492"},
    {"city_id":"20683","city_name":"Saattut","state_id":"1492"},
    {"city_id":"20684","city_name":"Ukkusissat","state_id":"1492"},
    {"city_id":"20685","city_name":"Uummannaq","state_id":"1492"},
    {"city_id":"20686","city_name":"Baie-Mahault","state_id":"1500"},
    {"city_id":"20687","city_name":"Baillif","state_id":"1500"},
    {"city_id":"20688","city_name":"Basse-Terre","state_id":"1500"},
    {"city_id":"20689","city_name":"Bouillante","state_id":"1500"},
    {"city_id":"20690","city_name":"Capesterre-Belle-Eau","state_id":"1500"},
    {"city_id":"20691","city_name":"Gourbeyre","state_id":"1500"},
    {"city_id":"20692","city_name":"Lamentin","state_id":"1500"},
    {"city_id":"20693","city_name":"Petit-Bourg","state_id":"1500"},
    {"city_id":"20694","city_name":"Pointe-Noire","state_id":"1500"},
    {"city_id":"20695","city_name":"Saint-Claude","state_id":"1500"},
    {"city_id":"20696","city_name":"Sainte-Rose","state_id":"1500"},
    {"city_id":"20697","city_name":"Trois-Rivieres","state_id":"1500"},
    {"city_id":"20698","city_name":"Vieux-Habitants","state_id":"1500"},
    {"city_id":"20699","city_name":"Anse-Bertrand","state_id":"1501"},
    {"city_id":"20700","city_name":"Le Gosier","state_id":"1501"},
    {"city_id":"20701","city_name":"Le Moule","state_id":"1501"},
    {"city_id":"20702","city_name":"Les Abymes","state_id":"1501"},
    {"city_id":"20703","city_name":"Morne-a-l'Eau","state_id":"1501"},
    {"city_id":"20704","city_name":"Petit-Canal","state_id":"1501"},
    {"city_id":"20705","city_name":"Point-a-Pitre","state_id":"1501"},
    {"city_id":"20706","city_name":"Port-Louis","state_id":"1501"},
    {"city_id":"20707","city_name":"Saint-Francois","state_id":"1501"},
    {"city_id":"20708","city_name":"Sainte-Anne","state_id":"1501"},
    {"city_id":"20709","city_name":"Grand-Bourg","state_id":"1504"},
    {"city_id":"20710","city_name":"Agat","state_id":"1508"},
    {"city_id":"20711","city_name":"Barrigada","state_id":"1509"},
    {"city_id":"20712","city_name":"Barrigada Heights","state_id":"1509"},
    {"city_id":"20713","city_name":"Chalan Pago","state_id":"1510"},
    {"city_id":"20714","city_name":"Ordot","state_id":"1510"},
    {"city_id":"20715","city_name":"Astumbo","state_id":"1511"},
    {"city_id":"20716","city_name":"Dededo","state_id":"1511"},
    {"city_id":"20717","city_name":"Finegayan Station","state_id":"1511"},
    {"city_id":"20718","city_name":"Agana","state_id":"1512"},
    {"city_id":"20719","city_name":"Agana Station","state_id":"1512"},
    {"city_id":"20720","city_name":"Inarajan","state_id":"1513"},
    {"city_id":"20721","city_name":"Mangilao","state_id":"1514"},
    {"city_id":"20722","city_name":"Merizo","state_id":"1515"},
    {"city_id":"20723","city_name":"Mongmong","state_id":"1516"},
    {"city_id":"20724","city_name":"Toto","state_id":"1516"},
    {"city_id":"20725","city_name":"Sinajana","state_id":"1518"},
    {"city_id":"20726","city_name":"Talofofo","state_id":"1519"},
    {"city_id":"20727","city_name":"Tamuning","state_id":"1520"},
    {"city_id":"20728","city_name":"Anderson Air Force Base","state_id":"1521"},
    {"city_id":"20729","city_name":"Yigo","state_id":"1521"},
    {"city_id":"20730","city_name":"Yona","state_id":"1522"},
    {"city_id":"20731","city_name":"Chimaltenango","state_id":"1525"},
    {"city_id":"20732","city_name":"Comalapa","state_id":"1525"},
    {"city_id":"20733","city_name":"Itzapa","state_id":"1525"},
    {"city_id":"20734","city_name":"Patzun","state_id":"1525"},
    {"city_id":"20735","city_name":"Chiquimula","state_id":"1526"},
    {"city_id":"20736","city_name":"Esquipulas","state_id":"1526"},
    {"city_id":"20737","city_name":"Cotzumalguapa","state_id":"1528"},
    {"city_id":"20738","city_name":"Escuintla","state_id":"1528"},
    {"city_id":"20739","city_name":"Palin","state_id":"1528"},
    {"city_id":"20740","city_name":"San Jose","state_id":"1528"},
    {"city_id":"20741","city_name":"Tiquisate","state_id":"1528"},
    {"city_id":"20742","city_name":"Amatitlan","state_id":"1529"},
    {"city_id":"20743","city_name":"Chinautla","state_id":"1529"},
    {"city_id":"20744","city_name":"Guatemala","state_id":"1529"},
    {"city_id":"20745","city_name":"Mixco","state_id":"1529"},
    {"city_id":"20746","city_name":"Petapa","state_id":"1529"},
    {"city_id":"20747","city_name":"Villa Nueva","state_id":"1529"},
    {"city_id":"20748","city_name":"Huehuetenango","state_id":"1530"},
    {"city_id":"20749","city_name":"Puerto Barrios","state_id":"1531"},
    {"city_id":"20750","city_name":"Jalapa","state_id":"1532"},
    {"city_id":"20751","city_name":"Asuncion Mita","state_id":"1533"},
    {"city_id":"20752","city_name":"Jutiapa","state_id":"1533"},
    {"city_id":"20753","city_name":"Flores","state_id":"1534"},
    {"city_id":"20754","city_name":"San Benito","state_id":"1534"},
    {"city_id":"20755","city_name":"Quezaltenango","state_id":"1535"},
    {"city_id":"20756","city_name":"Quiche","state_id":"1536"},
    {"city_id":"20757","city_name":"Retalhuleu","state_id":"1537"},
    {"city_id":"20758","city_name":"Antigua","state_id":"1538"},
    {"city_id":"20759","city_name":"Ciudad Vieja","state_id":"1538"},
    {"city_id":"20760","city_name":"Jocotenango","state_id":"1538"},
    {"city_id":"20761","city_name":"Santa Maria de Jesus","state_id":"1538"},
    {"city_id":"20762","city_name":"Sumpango","state_id":"1538"},
    {"city_id":"20763","city_name":"Atitlan","state_id":"1541"},
    {"city_id":"20764","city_name":"Solola","state_id":"1541"},
    {"city_id":"20765","city_name":"Mazatenango","state_id":"1542"},
    {"city_id":"20766","city_name":"Totonicapan","state_id":"1543"},
    {"city_id":"20767","city_name":"Zacapa","state_id":"1544"},
    {"city_id":"20768","city_name":"Saint Anne's","state_id":"1545"},
    {"city_id":"20769","city_name":"Castle","state_id":"1546"},
    {"city_id":"20770","city_name":"Forest","state_id":"1547"},
    {"city_id":"20771","city_name":"Sark","state_id":"1554"},
    {"city_id":"20772","city_name":"Torteval","state_id":"1555"},
    {"city_id":"20773","city_name":"Vale","state_id":"1556"},
    {"city_id":"20774","city_name":"Beyla","state_id":"1557"},
    {"city_id":"20775","city_name":"Boffa","state_id":"1558"},
    {"city_id":"20776","city_name":"Boke","state_id":"1559"},
    {"city_id":"20777","city_name":"Conakry","state_id":"1560"},
    {"city_id":"20778","city_name":"Coyah","state_id":"1561"},
    {"city_id":"20779","city_name":"Dabola","state_id":"1562"},
    {"city_id":"20780","city_name":"Dalaba","state_id":"1563"},
    {"city_id":"20781","city_name":"Dinguiraye","state_id":"1564"},
    {"city_id":"20782","city_name":"Faranah","state_id":"1565"},
    {"city_id":"20783","city_name":"Forecariah","state_id":"1566"},
    {"city_id":"20784","city_name":"Fria","state_id":"1567"},
    {"city_id":"20785","city_name":"Gaoual","state_id":"1568"},
    {"city_id":"20786","city_name":"Guekedou","state_id":"1569"},
    {"city_id":"20787","city_name":"Kankan","state_id":"1570"},
    {"city_id":"20788","city_name":"Kerouane","state_id":"1571"},
    {"city_id":"20789","city_name":"Kindia","state_id":"1572"},
    {"city_id":"20790","city_name":"Kissidougou","state_id":"1573"},
    {"city_id":"20791","city_name":"Koubia","state_id":"1574"},
    {"city_id":"20792","city_name":"Koundara","state_id":"1575"},
    {"city_id":"20793","city_name":"Kouroussa","state_id":"1576"},
    {"city_id":"20794","city_name":"Labe","state_id":"1577"},
    {"city_id":"20795","city_name":"Lola","state_id":"1578"},
    {"city_id":"20796","city_name":"Macenta","state_id":"1579"},
    {"city_id":"20797","city_name":"Mali","state_id":"1580"},
    {"city_id":"20798","city_name":"Mamou","state_id":"1581"},
    {"city_id":"20799","city_name":"Mandiana","state_id":"1582"},
    {"city_id":"20800","city_name":"Nzerekore","state_id":"1583"},
    {"city_id":"20801","city_name":"Pita","state_id":"1584"},
    {"city_id":"20802","city_name":"Siguiri","state_id":"1585"},
    {"city_id":"20803","city_name":"Telimele","state_id":"1586"},
    {"city_id":"20804","city_name":"Tougue","state_id":"1587"},
    {"city_id":"20805","city_name":"Yomou","state_id":"1588"},
    {"city_id":"20806","city_name":"Bafata","state_id":"1589"},
    {"city_id":"20807","city_name":"Bissau","state_id":"1590"},
    {"city_id":"20808","city_name":"Bolama","state_id":"1591"},
    {"city_id":"20809","city_name":"Bubaque","state_id":"1591"},
    {"city_id":"20810","city_name":"Cacheu","state_id":"1592"},
    {"city_id":"20811","city_name":"Canchungo","state_id":"1592"},
    {"city_id":"20812","city_name":"Gabu","state_id":"1593"},
    {"city_id":"20813","city_name":"Bissora","state_id":"1594"},
    {"city_id":"20814","city_name":"Farim","state_id":"1594"},
    {"city_id":"20815","city_name":"Mansoa","state_id":"1594"},
    {"city_id":"20816","city_name":"Buba","state_id":"1595"},
    {"city_id":"20817","city_name":"Fulacunda","state_id":"1595"},
    {"city_id":"20818","city_name":"Quebo","state_id":"1595"},
    {"city_id":"20819","city_name":"Catio","state_id":"1596"},
    {"city_id":"20820","city_name":"Mabaruma","state_id":"1597"},
    {"city_id":"20821","city_name":"Morawhanna","state_id":"1597"},
    {"city_id":"20822","city_name":"Bartica","state_id":"1598"},
    {"city_id":"20823","city_name":"Issano","state_id":"1598"},
    {"city_id":"20824","city_name":"Kamarang","state_id":"1598"},
    {"city_id":"20825","city_name":"Georgetown","state_id":"1599"},
    {"city_id":"20826","city_name":"Mahaica","state_id":"1599"},
    {"city_id":"20827","city_name":"Paradise","state_id":"1599"},
    {"city_id":"20828","city_name":"Queenstown","state_id":"1599"},
    {"city_id":"20829","city_name":"Fort Wellington","state_id":"1602"},
    {"city_id":"20830","city_name":"Mahaicony","state_id":"1602"},
    {"city_id":"20831","city_name":"Rosignol","state_id":"1602"},
    {"city_id":"20832","city_name":"Anna Regina","state_id":"1603"},
    {"city_id":"20833","city_name":"Charity","state_id":"1603"},
    {"city_id":"20834","city_name":"Suddie","state_id":"1603"},
    {"city_id":"20835","city_name":"Mahdia","state_id":"1604"},
    {"city_id":"20836","city_name":"Tumatumari","state_id":"1604"},
    {"city_id":"20837","city_name":"Desdunes","state_id":"1607"},
    {"city_id":"20838","city_name":"Dessalines","state_id":"1607"},
    {"city_id":"20839","city_name":"Gonaives","state_id":"1607"},
    {"city_id":"20840","city_name":"Gros-Morne","state_id":"1607"},
    {"city_id":"20841","city_name":"L'Artibonite","state_id":"1607"},
    {"city_id":"20842","city_name":"Saint-Marc","state_id":"1607"},
    {"city_id":"20843","city_name":"Saint-Michel-de-l'Atalaye","state_id":"1607"},
    {"city_id":"20844","city_name":"Saint-Raphael","state_id":"1607"},
    {"city_id":"20845","city_name":"Verrettes","state_id":"1607"},
    {"city_id":"20846","city_name":"Hinche","state_id":"1608"},
    {"city_id":"20847","city_name":"Mirebalais","state_id":"1608"},
    {"city_id":"20848","city_name":"Anse-d'Hainault","state_id":"1609"},
    {"city_id":"20849","city_name":"Dame Marie","state_id":"1609"},
    {"city_id":"20850","city_name":"Jeremie","state_id":"1609"},
    {"city_id":"20851","city_name":"Miragoane","state_id":"1609"},
    {"city_id":"20852","city_name":"Cap-Haitien","state_id":"1610"},
    {"city_id":"20853","city_name":"Croix-des-Bouquets","state_id":"1610"},
    {"city_id":"20854","city_name":"Grande Riviere du Nord","state_id":"1610"},
    {"city_id":"20855","city_name":"Limbe","state_id":"1610"},
    {"city_id":"20856","city_name":"Pignon","state_id":"1610"},
    {"city_id":"20857","city_name":"Derac","state_id":"1611"},
    {"city_id":"20858","city_name":"Fort-Liberte","state_id":"1611"},
    {"city_id":"20859","city_name":"Ouanaminthe","state_id":"1611"},
    {"city_id":"20860","city_name":"Trou-du-Nord","state_id":"1611"},
    {"city_id":"20861","city_name":"Port-de-Paix","state_id":"1612"},
    {"city_id":"20862","city_name":"Saint-Louis-du-Nord","state_id":"1612"},
    {"city_id":"20863","city_name":"Anse-a-Galets","state_id":"1613"},
    {"city_id":"20864","city_name":"Carrefour","state_id":"1613"},
    {"city_id":"20865","city_name":"Delmas","state_id":"1613"},
    {"city_id":"20866","city_name":"Kenscoff","state_id":"1613"},
    {"city_id":"20867","city_name":"Lascahobas","state_id":"1613"},
    {"city_id":"20868","city_name":"Leogane","state_id":"1613"},
    {"city_id":"20869","city_name":"Petionville","state_id":"1613"},
    {"city_id":"20870","city_name":"Petit Goave","state_id":"1613"},
    {"city_id":"20871","city_name":"Port-au-Prince","state_id":"1613"},
    {"city_id":"20872","city_name":"Aquin","state_id":"1614"},
    {"city_id":"20873","city_name":"Les Cayes","state_id":"1614"},
    {"city_id":"20874","city_name":"Jacmel","state_id":"1615"},
    {"city_id":"20875","city_name":"La Ceiba","state_id":"1617"},
    {"city_id":"20876","city_name":"Olanchito","state_id":"1617"},
    {"city_id":"20877","city_name":"Tela","state_id":"1617"},
    {"city_id":"20878","city_name":"Choluteca","state_id":"1618"},
    {"city_id":"20879","city_name":"El Triunfo","state_id":"1618"},
    {"city_id":"20880","city_name":"Pespire","state_id":"1618"},
    {"city_id":"20881","city_name":"Sonaguera","state_id":"1619"},
    {"city_id":"20882","city_name":"Tocoa","state_id":"1619"},
    {"city_id":"20883","city_name":"Trujillo","state_id":"1619"},
    {"city_id":"20884","city_name":"Comayagua","state_id":"1620"},
    {"city_id":"20885","city_name":"Siguatepeque","state_id":"1620"},
    {"city_id":"20886","city_name":"Copan","state_id":"1621"},
    {"city_id":"20887","city_name":"Corquin","state_id":"1621"},
    {"city_id":"20888","city_name":"Dulce Nombre","state_id":"1621"},
    {"city_id":"20889","city_name":"El Paraiso","state_id":"1621"},
    {"city_id":"20890","city_name":"San Antonio","state_id":"1621"},
    {"city_id":"20891","city_name":"San Nicolas","state_id":"1621"},
    {"city_id":"20892","city_name":"Santa Rosa de Copan","state_id":"1621"},
    {"city_id":"20893","city_name":"Choloma","state_id":"1622"},
    {"city_id":"20894","city_name":"La Lima","state_id":"1622"},
    {"city_id":"20895","city_name":"Omoa","state_id":"1622"},
    {"city_id":"20896","city_name":"Puerto Cortes","state_id":"1622"},
    {"city_id":"20897","city_name":"San Pedro Sula","state_id":"1622"},
    {"city_id":"20898","city_name":"Intibuca","state_id":"1627"},
    {"city_id":"20899","city_name":"La Esperanza","state_id":"1627"},
    {"city_id":"20900","city_name":"Utila","state_id":"1627"},
    {"city_id":"20901","city_name":"Gracias","state_id":"1630"},
    {"city_id":"20902","city_name":"Ocotepeque","state_id":"1631"},
    {"city_id":"20903","city_name":"San Marcos","state_id":"1631"},
    {"city_id":"20904","city_name":"Sinuapa","state_id":"1631"},
    {"city_id":"20905","city_name":"Catacamas","state_id":"1632"},
    {"city_id":"20906","city_name":"Juticalpa","state_id":"1632"},
    {"city_id":"20907","city_name":"Amapala","state_id":"1634"},
    {"city_id":"20908","city_name":"Langue","state_id":"1634"},
    {"city_id":"20909","city_name":"Nacaome","state_id":"1634"},
    {"city_id":"20910","city_name":"San Lorenzo","state_id":"1634"},
    {"city_id":"20911","city_name":"El Progreso","state_id":"1635"},
    {"city_id":"20912","city_name":"Morazan","state_id":"1635"},
    {"city_id":"20913","city_name":"Santa Rita","state_id":"1635"},
    {"city_id":"20914","city_name":"Yoro","state_id":"1635"},
    {"city_id":"20915","city_name":"Akaszto","state_id":"1637"},
    {"city_id":"20916","city_name":"Bacsalmas","state_id":"1637"},
    {"city_id":"20917","city_name":"Bacsbokod","state_id":"1637"},
    {"city_id":"20918","city_name":"Baja","state_id":"1637"},
    {"city_id":"20919","city_name":"Bugac","state_id":"1637"},
    {"city_id":"20920","city_name":"Davod","state_id":"1637"},
    {"city_id":"20921","city_name":"Dunapataj","state_id":"1637"},
    {"city_id":"20922","city_name":"Dunavecse","state_id":"1637"},
    {"city_id":"20923","city_name":"Fulopszallas","state_id":"1637"},
    {"city_id":"20924","city_name":"Hajos","state_id":"1637"},
    {"city_id":"20925","city_name":"Harta","state_id":"1637"},
    {"city_id":"20926","city_name":"Izsak","state_id":"1637"},
    {"city_id":"20927","city_name":"Jakabszallas","state_id":"1637"},
    {"city_id":"20928","city_name":"Janoshalma","state_id":"1637"},
    {"city_id":"20929","city_name":"Kalocsa","state_id":"1637"},
    {"city_id":"20930","city_name":"Kecel","state_id":"1637"},
    {"city_id":"20931","city_name":"Kecskemet","state_id":"1637"},
    {"city_id":"20932","city_name":"Kiskoros","state_id":"1637"},
    {"city_id":"20933","city_name":"Kiskunfelegyhaza","state_id":"1637"},
    {"city_id":"20934","city_name":"Kiskunhalas","state_id":"1637"},
    {"city_id":"20935","city_name":"Kiskunmajsa","state_id":"1637"},
    {"city_id":"20936","city_name":"Kunfeherto","state_id":"1637"},
    {"city_id":"20937","city_name":"Kunszentmiklos","state_id":"1637"},
    {"city_id":"20938","city_name":"Lajosmizse","state_id":"1637"},
    {"city_id":"20939","city_name":"Lakitelek","state_id":"1637"},
    {"city_id":"20940","city_name":"Madaras","state_id":"1637"},
    {"city_id":"20941","city_name":"Melykut","state_id":"1637"},
    {"city_id":"20942","city_name":"Nagybaracska","state_id":"1637"},
    {"city_id":"20943","city_name":"Orgovany","state_id":"1637"},
    {"city_id":"20944","city_name":"Palmonostora","state_id":"1637"},
    {"city_id":"20945","city_name":"Solt","state_id":"1637"},
    {"city_id":"20946","city_name":"Soltvadkert","state_id":"1637"},
    {"city_id":"20947","city_name":"Sukosd","state_id":"1637"},
    {"city_id":"20948","city_name":"Szabadszallas","state_id":"1637"},
    {"city_id":"20949","city_name":"Szalkszentmarton","state_id":"1637"},
    {"city_id":"20950","city_name":"Tass","state_id":"1637"},
    {"city_id":"20951","city_name":"Tiszakecske","state_id":"1637"},
    {"city_id":"20952","city_name":"Tompa","state_id":"1637"},
    {"city_id":"20953","city_name":"Beremend","state_id":"1638"},
    {"city_id":"20954","city_name":"Boly","state_id":"1638"},
    {"city_id":"20955","city_name":"Dunaszekcso","state_id":"1638"},
    {"city_id":"20956","city_name":"Harkany","state_id":"1638"},
    {"city_id":"20957","city_name":"Hosszuheteny","state_id":"1638"},
    {"city_id":"20958","city_name":"Komlo","state_id":"1638"},
    {"city_id":"20959","city_name":"Magocs","state_id":"1638"},
    {"city_id":"20960","city_name":"Mohacs","state_id":"1638"},
    {"city_id":"20961","city_name":"Pecs","state_id":"1638"},
    {"city_id":"20962","city_name":"Pecsvarad","state_id":"1638"},
    {"city_id":"20963","city_name":"Sasd","state_id":"1638"},
    {"city_id":"20964","city_name":"Sellye","state_id":"1638"},
    {"city_id":"20965","city_name":"Siklos","state_id":"1638"},
    {"city_id":"20966","city_name":"Szentlorinc","state_id":"1638"},
    {"city_id":"20967","city_name":"Szigetvar","state_id":"1638"},
    {"city_id":"20968","city_name":"Vajszlo","state_id":"1638"},
    {"city_id":"20969","city_name":"Villany","state_id":"1638"},
    {"city_id":"20970","city_name":"Battonya","state_id":"1639"},
    {"city_id":"20971","city_name":"Bekes","state_id":"1639"},
    {"city_id":"20972","city_name":"Bekescsaba","state_id":"1639"},
    {"city_id":"20973","city_name":"Bekessamson","state_id":"1639"},
    {"city_id":"20974","city_name":"Bekesszentandras","state_id":"1639"},
    {"city_id":"20975","city_name":"Csorvas","state_id":"1639"},
    {"city_id":"20976","city_name":"Devavanya","state_id":"1639"},
    {"city_id":"20977","city_name":"Doboz","state_id":"1639"},
    {"city_id":"20978","city_name":"Elek","state_id":"1639"},
    {"city_id":"20979","city_name":"Endrod","state_id":"1639"},
    {"city_id":"20980","city_name":"Fuzesgyarmat","state_id":"1639"},
    {"city_id":"20981","city_name":"Gyula","state_id":"1639"},
    {"city_id":"20982","city_name":"Ketegyhaza","state_id":"1639"},
    {"city_id":"20983","city_name":"Kondoros","state_id":"1639"},
    {"city_id":"20984","city_name":"Korosladany","state_id":"1639"},
    {"city_id":"20985","city_name":"Kunagota","state_id":"1639"},
    {"city_id":"20986","city_name":"Lokoshaza","state_id":"1639"},
    {"city_id":"20987","city_name":"Mezobereny","state_id":"1639"},
    {"city_id":"20988","city_name":"Mezohegyes","state_id":"1639"},
    {"city_id":"20989","city_name":"Mezokovacshaza","state_id":"1639"},
    {"city_id":"20990","city_name":"Nagyszenas","state_id":"1639"},
    {"city_id":"20991","city_name":"Oroshaza","state_id":"1639"},
    {"city_id":"20992","city_name":"Sarkad","state_id":"1639"},
    {"city_id":"20993","city_name":"Szabadkigyos","state_id":"1639"},
    {"city_id":"20994","city_name":"Szarvas","state_id":"1639"},
    {"city_id":"20995","city_name":"Szeghalom","state_id":"1639"},
    {"city_id":"20996","city_name":"Totkomlos","state_id":"1639"},
    {"city_id":"20997","city_name":"Veszto","state_id":"1639"},
    {"city_id":"20998","city_name":"Abaujszanto","state_id":"1640"},
    {"city_id":"20999","city_name":"Arlo","state_id":"1640"},
    {"city_id":"21000","city_name":"Bogacs","state_id":"1640"},
    {"city_id":"21001","city_name":"Cigand","state_id":"1640"},
    {"city_id":"21002","city_name":"Edeleny","state_id":"1640"},
    {"city_id":"21003","city_name":"Emod","state_id":"1640"},
    {"city_id":"21004","city_name":"Encs","state_id":"1640"},
    {"city_id":"21005","city_name":"Gonc","state_id":"1640"},
    {"city_id":"21006","city_name":"Karcsa","state_id":"1640"},
    {"city_id":"21007","city_name":"Kazincbarcika","state_id":"1640"},
    {"city_id":"21008","city_name":"Mad","state_id":"1640"},
    {"city_id":"21009","city_name":"Megyaszo","state_id":"1640"},
    {"city_id":"21010","city_name":"Mezokeresztes","state_id":"1640"},
    {"city_id":"21011","city_name":"Mezokovesd","state_id":"1640"},
    {"city_id":"21012","city_name":"Miskolc","state_id":"1640"},
    {"city_id":"21013","city_name":"Monok","state_id":"1640"},
    {"city_id":"21014","city_name":"Nyekladhaza","state_id":"1640"},
    {"city_id":"21015","city_name":"Olaszliszka","state_id":"1640"},
    {"city_id":"21016","city_name":"Onod","state_id":"1640"},
    {"city_id":"21017","city_name":"Ozd","state_id":"1640"},
    {"city_id":"21018","city_name":"Putnok","state_id":"1640"},
    {"city_id":"21019","city_name":"Rudabanya","state_id":"1640"},
    {"city_id":"21020","city_name":"Sajokaza","state_id":"1640"},
    {"city_id":"21021","city_name":"Sajolad","state_id":"1640"},
    {"city_id":"21022","city_name":"Sajoszentpeter","state_id":"1640"},
    {"city_id":"21023","city_name":"Saly","state_id":"1640"},
    {"city_id":"21024","city_name":"Sarospatak","state_id":"1640"},
    {"city_id":"21025","city_name":"Satoraljaujhely","state_id":"1640"},
    {"city_id":"21026","city_name":"Szendro","state_id":"1640"},
    {"city_id":"21027","city_name":"Szentistvan","state_id":"1640"},
    {"city_id":"21028","city_name":"Szerencs","state_id":"1640"},
    {"city_id":"21029","city_name":"Szihalom","state_id":"1640"},
    {"city_id":"21030","city_name":"Szikszo","state_id":"1640"},
    {"city_id":"21031","city_name":"Taktaharkany","state_id":"1640"},
    {"city_id":"21032","city_name":"Taktaszada","state_id":"1640"},
    {"city_id":"21033","city_name":"Tallya","state_id":"1640"},
    {"city_id":"21034","city_name":"Tarcal","state_id":"1640"},
    {"city_id":"21035","city_name":"Tiszaluc","state_id":"1640"},
    {"city_id":"21036","city_name":"Tiszaujvaros","state_id":"1640"},
    {"city_id":"21037","city_name":"Tokaj","state_id":"1640"},
    {"city_id":"21038","city_name":"Tolcsva","state_id":"1640"},
    {"city_id":"21039","city_name":"Budapest","state_id":"1641"},
    {"city_id":"21040","city_name":"Csongrad","state_id":"1642"},
    {"city_id":"21041","city_name":"Fabiansebestyen","state_id":"1642"},
    {"city_id":"21042","city_name":"Foldeak","state_id":"1642"},
    {"city_id":"21043","city_name":"Hodmezovasarhely","state_id":"1642"},
    {"city_id":"21044","city_name":"Kiskundorozsma","state_id":"1642"},
    {"city_id":"21045","city_name":"Kistelek","state_id":"1642"},
    {"city_id":"21046","city_name":"Kiszombor","state_id":"1642"},
    {"city_id":"21047","city_name":"Mako","state_id":"1642"},
    {"city_id":"21048","city_name":"Mindszent","state_id":"1642"},
    {"city_id":"21049","city_name":"Morahalom","state_id":"1642"},
    {"city_id":"21050","city_name":"Pusztaszer","state_id":"1642"},
    {"city_id":"21051","city_name":"Roszke","state_id":"1642"},
    {"city_id":"21052","city_name":"Sandorfalva","state_id":"1642"},
    {"city_id":"21053","city_name":"Szatymaz","state_id":"1642"},
    {"city_id":"21054","city_name":"Szeged","state_id":"1642"},
    {"city_id":"21055","city_name":"Szegvar","state_id":"1642"},
    {"city_id":"21056","city_name":"Szekkutas","state_id":"1642"},
    {"city_id":"21057","city_name":"Szentes","state_id":"1642"},
    {"city_id":"21058","city_name":"Aba","state_id":"1643"},
    {"city_id":"21059","city_name":"Adony","state_id":"1643"},
    {"city_id":"21060","city_name":"Alap","state_id":"1643"},
    {"city_id":"21061","city_name":"Apostag","state_id":"1643"},
    {"city_id":"21062","city_name":"Bakonycsernye","state_id":"1643"},
    {"city_id":"21063","city_name":"Bicske","state_id":"1643"},
    {"city_id":"21064","city_name":"Bodajk","state_id":"1643"},
    {"city_id":"21065","city_name":"Cece","state_id":"1643"},
    {"city_id":"21066","city_name":"Csakvar","state_id":"1643"},
    {"city_id":"21067","city_name":"Deg","state_id":"1643"},
    {"city_id":"21068","city_name":"Dios","state_id":"1643"},
    {"city_id":"21069","city_name":"Dunaujvaros","state_id":"1643"},
    {"city_id":"21070","city_name":"Enying","state_id":"1643"},
    {"city_id":"21071","city_name":"Ercsi","state_id":"1643"},
    {"city_id":"21072","city_name":"Etyek","state_id":"1643"},
    {"city_id":"21073","city_name":"Fehervarcsurgo","state_id":"1643"},
    {"city_id":"21074","city_name":"Lovasbereny","state_id":"1643"},
    {"city_id":"21075","city_name":"Martonvasar","state_id":"1643"},
    {"city_id":"21076","city_name":"Mezofalva","state_id":"1643"},
    {"city_id":"21077","city_name":"Mezoszilas","state_id":"1643"},
    {"city_id":"21078","city_name":"Mor","state_id":"1643"},
    {"city_id":"21079","city_name":"Pazmand","state_id":"1643"},
    {"city_id":"21080","city_name":"Polgardi","state_id":"1643"},
    {"city_id":"21081","city_name":"Pusztavam","state_id":"1643"},
    {"city_id":"21082","city_name":"Rackeresztur","state_id":"1643"},
    {"city_id":"21083","city_name":"Sarbogard","state_id":"1643"},
    {"city_id":"21084","city_name":"Seregelyes","state_id":"1643"},
    {"city_id":"21085","city_name":"Soponya","state_id":"1643"},
    {"city_id":"21086","city_name":"Szabadbattyan","state_id":"1643"},
    {"city_id":"21087","city_name":"Szekesfehervar","state_id":"1643"},
    {"city_id":"21088","city_name":"Val","state_id":"1643"},
    {"city_id":"21089","city_name":"Asvanyraro","state_id":"1644"},
    {"city_id":"21090","city_name":"Beled","state_id":"1644"},
    {"city_id":"21091","city_name":"Bosarkany","state_id":"1644"},
    {"city_id":"21092","city_name":"Csorna","state_id":"1644"},
    {"city_id":"21093","city_name":"Fertod","state_id":"1644"},
    {"city_id":"21094","city_name":"Fertorakos","state_id":"1644"},
    {"city_id":"21095","city_name":"Fertoszentmiklos","state_id":"1644"},
    {"city_id":"21096","city_name":"Gyor","state_id":"1644"},
    {"city_id":"21097","city_name":"Gyorujbarat","state_id":"1644"},
    {"city_id":"21098","city_name":"Hunyadi u.","state_id":"1644"},
    {"city_id":"21099","city_name":"Kapuvar","state_id":"1644"},
    {"city_id":"21100","city_name":"Lebeny","state_id":"1644"},
    {"city_id":"21101","city_name":"Mihalyi","state_id":"1644"},
    {"city_id":"21102","city_name":"Mosonmagyarovar","state_id":"1644"},
    {"city_id":"21103","city_name":"Nyul","state_id":"1644"},
    {"city_id":"21104","city_name":"Pannonhalma","state_id":"1644"},
    {"city_id":"21105","city_name":"Rajka","state_id":"1644"},
    {"city_id":"21106","city_name":"Sopron","state_id":"1644"},
    {"city_id":"21107","city_name":"Szany","state_id":"1644"},
    {"city_id":"21108","city_name":"Tet","state_id":"1644"},
    {"city_id":"21109","city_name":"Balmazujvaros","state_id":"1645"},
    {"city_id":"21110","city_name":"Barand","state_id":"1645"},
    {"city_id":"21111","city_name":"Berettyoujfalu","state_id":"1645"},
    {"city_id":"21112","city_name":"Biharkeresztes","state_id":"1645"},
    {"city_id":"21113","city_name":"Biharnagybajom","state_id":"1645"},
    {"city_id":"21114","city_name":"Debrecen","state_id":"1645"},
    {"city_id":"21115","city_name":"Derecske","state_id":"1645"},
    {"city_id":"21116","city_name":"Egyek","state_id":"1645"},
    {"city_id":"21117","city_name":"Foldes","state_id":"1645"},
    {"city_id":"21118","city_name":"Hajduboszormeny","state_id":"1645"},
    {"city_id":"21119","city_name":"Hajdudorog","state_id":"1645"},
    {"city_id":"21120","city_name":"Hajduhadhaz","state_id":"1645"},
    {"city_id":"21121","city_name":"Hajdusamson","state_id":"1645"},
    {"city_id":"21122","city_name":"Hajduszoboszlo","state_id":"1645"},
    {"city_id":"21123","city_name":"Hajduszovat","state_id":"1645"},
    {"city_id":"21124","city_name":"Hortobagy","state_id":"1645"},
    {"city_id":"21125","city_name":"Hosszupalyi","state_id":"1645"},
    {"city_id":"21126","city_name":"Kaba","state_id":"1645"},
    {"city_id":"21127","city_name":"Komadi","state_id":"1645"},
    {"city_id":"21128","city_name":"Mikepercs","state_id":"1645"},
    {"city_id":"21129","city_name":"Monostorpalyi","state_id":"1645"},
    {"city_id":"21130","city_name":"Nadudvar","state_id":"1645"},
    {"city_id":"21131","city_name":"Nagyleta","state_id":"1645"},
    {"city_id":"21132","city_name":"Nyirabrany","state_id":"1645"},
    {"city_id":"21133","city_name":"Nyiracsad","state_id":"1645"},
    {"city_id":"21134","city_name":"Nyiradony","state_id":"1645"},
    {"city_id":"21135","city_name":"Polgar","state_id":"1645"},
    {"city_id":"21136","city_name":"Puspokladany","state_id":"1645"},
    {"city_id":"21137","city_name":"Sarretudvari","state_id":"1645"},
    {"city_id":"21138","city_name":"Tiszacsege","state_id":"1645"},
    {"city_id":"21139","city_name":"Ujfeherto","state_id":"1645"},
    {"city_id":"21140","city_name":"Vamospercs","state_id":"1645"},
    {"city_id":"21141","city_name":"Abasar","state_id":"1646"},
    {"city_id":"21142","city_name":"Andornaktalya","state_id":"1646"},
    {"city_id":"21143","city_name":"Belapatfalva","state_id":"1646"},
    {"city_id":"21144","city_name":"Domoszlo","state_id":"1646"},
    {"city_id":"21145","city_name":"Eger","state_id":"1646"},
    {"city_id":"21146","city_name":"Erdotelek","state_id":"1646"},
    {"city_id":"21147","city_name":"Felsotarkany","state_id":"1646"},
    {"city_id":"21148","city_name":"Fuzesabony","state_id":"1646"},
    {"city_id":"21149","city_name":"Gyongyos","state_id":"1646"},
    {"city_id":"21150","city_name":"Gyongyospata","state_id":"1646"},
    {"city_id":"21151","city_name":"Gyongyossolymos","state_id":"1646"},
    {"city_id":"21152","city_name":"Gyongyostarjan","state_id":"1646"},
    {"city_id":"21153","city_name":"Hatvan","state_id":"1646"},
    {"city_id":"21154","city_name":"Heves","state_id":"1646"},
    {"city_id":"21155","city_name":"Kal","state_id":"1646"},
    {"city_id":"21156","city_name":"Lorinci","state_id":"1646"},
    {"city_id":"21157","city_name":"Matraderecske","state_id":"1646"},
    {"city_id":"21158","city_name":"Parad","state_id":"1646"},
    {"city_id":"21159","city_name":"Petervasara","state_id":"1646"},
    {"city_id":"21160","city_name":"Recsk","state_id":"1646"},
    {"city_id":"21161","city_name":"Sirok","state_id":"1646"},
    {"city_id":"21162","city_name":"Tarnalelesz","state_id":"1646"},
    {"city_id":"21163","city_name":"Verpelet","state_id":"1646"},
    {"city_id":"21164","city_name":"Abadszalok","state_id":"1647"},
    {"city_id":"21165","city_name":"Besenyszog","state_id":"1647"},
    {"city_id":"21166","city_name":"Cserkeszolo","state_id":"1647"},
    {"city_id":"21167","city_name":"Fegyvernek","state_id":"1647"},
    {"city_id":"21168","city_name":"Hegyeshalom","state_id":"1647"},
    {"city_id":"21169","city_name":"Jaszalsoszentgyorgy","state_id":"1647"},
    {"city_id":"21170","city_name":"Jaszapati","state_id":"1647"},
    {"city_id":"21171","city_name":"Jaszarokszallas","state_id":"1647"},
    {"city_id":"21172","city_name":"Jaszbereny","state_id":"1647"},
    {"city_id":"21173","city_name":"Jaszfenyzaru","state_id":"1647"},
    {"city_id":"21174","city_name":"Jaszjakohalma","state_id":"1647"},
    {"city_id":"21175","city_name":"Jaszkiser","state_id":"1647"},
    {"city_id":"21176","city_name":"Jaszladany","state_id":"1647"},
    {"city_id":"21177","city_name":"Jaszszentandras","state_id":"1647"},
    {"city_id":"21178","city_name":"Karcag","state_id":"1647"},
    {"city_id":"21179","city_name":"Kenderes","state_id":"1647"},
    {"city_id":"21180","city_name":"Kisujszallas","state_id":"1647"},
    {"city_id":"21181","city_name":"Kunhegyes","state_id":"1647"},
    {"city_id":"21182","city_name":"Kunmadaras","state_id":"1647"},
    {"city_id":"21183","city_name":"Kunszentmarton","state_id":"1647"},
    {"city_id":"21184","city_name":"Martfu","state_id":"1647"},
    {"city_id":"21185","city_name":"Mezotur","state_id":"1647"},
    {"city_id":"21186","city_name":"Ocsod","state_id":"1647"},
    {"city_id":"21187","city_name":"Szolnok","state_id":"1647"},
    {"city_id":"21188","city_name":"Tiszabura","state_id":"1647"},
    {"city_id":"21189","city_name":"Tiszafoldvar","state_id":"1647"},
    {"city_id":"21190","city_name":"Tiszafured","state_id":"1647"},
    {"city_id":"21191","city_name":"Tiszapuspoki","state_id":"1647"},
    {"city_id":"21192","city_name":"Tiszaroff","state_id":"1647"},
    {"city_id":"21193","city_name":"Tiszasuly","state_id":"1647"},
    {"city_id":"21194","city_name":"Torokszentmiklos","state_id":"1647"},
    {"city_id":"21195","city_name":"Toszeg","state_id":"1647"},
    {"city_id":"21196","city_name":"Turkeve","state_id":"1647"},
    {"city_id":"21197","city_name":"Ujszasz","state_id":"1647"},
    {"city_id":"21198","city_name":"Acs","state_id":"1648"},
    {"city_id":"21199","city_name":"Almasfuzito","state_id":"1648"},
    {"city_id":"21200","city_name":"Babolna","state_id":"1648"},
    {"city_id":"21201","city_name":"Bajna","state_id":"1648"},
    {"city_id":"21202","city_name":"Dorog","state_id":"1648"},
    {"city_id":"21203","city_name":"Esztergom","state_id":"1648"},
    {"city_id":"21204","city_name":"Kesztolc","state_id":"1648"},
    {"city_id":"21205","city_name":"Kisber","state_id":"1648"},
    {"city_id":"21206","city_name":"Komarom","state_id":"1648"},
    {"city_id":"21207","city_name":"Kornye","state_id":"1648"},
    {"city_id":"21208","city_name":"Labatlan","state_id":"1648"},
    {"city_id":"21209","city_name":"Mocsa","state_id":"1648"},
    {"city_id":"21210","city_name":"Nagyigmand","state_id":"1648"},
    {"city_id":"21211","city_name":"Nyergesujfalu","state_id":"1648"},
    {"city_id":"21212","city_name":"Oroszlany","state_id":"1648"},
    {"city_id":"21213","city_name":"Sarisap","state_id":"1648"},
    {"city_id":"21214","city_name":"Tardos","state_id":"1648"},
    {"city_id":"21215","city_name":"Tarjan","state_id":"1648"},
    {"city_id":"21216","city_name":"Tata","state_id":"1648"},
    {"city_id":"21217","city_name":"Tatabanya","state_id":"1648"},
    {"city_id":"21218","city_name":"Tokod","state_id":"1648"},
    {"city_id":"21219","city_name":"Vertesszolos","state_id":"1648"},
    {"city_id":"21220","city_name":"Balassagyarmat","state_id":"1649"},
    {"city_id":"21221","city_name":"Batonyterenye","state_id":"1649"},
    {"city_id":"21222","city_name":"Bercel","state_id":"1649"},
    {"city_id":"21223","city_name":"Bujak","state_id":"1649"},
    {"city_id":"21224","city_name":"Diosjeno","state_id":"1649"},
    {"city_id":"21225","city_name":"Karancskeszi","state_id":"1649"},
    {"city_id":"21226","city_name":"Matraverebely","state_id":"1649"},
    {"city_id":"21227","city_name":"Nagyoroszi","state_id":"1649"},
    {"city_id":"21228","city_name":"Paszto","state_id":"1649"},
    {"city_id":"21229","city_name":"Retsag","state_id":"1649"},
    {"city_id":"21230","city_name":"Romhany","state_id":"1649"},
    {"city_id":"21231","city_name":"Salgotarjan","state_id":"1649"},
    {"city_id":"21232","city_name":"Szecseny","state_id":"1649"},
    {"city_id":"21233","city_name":"Tar","state_id":"1649"},
    {"city_id":"21234","city_name":"Abony","state_id":"1650"},
    {"city_id":"21235","city_name":"Albertirsa","state_id":"1650"},
    {"city_id":"21236","city_name":"Aszod","state_id":"1650"},
    {"city_id":"21237","city_name":"Biatorbagy","state_id":"1650"},
    {"city_id":"21238","city_name":"Budakalasz","state_id":"1650"},
    {"city_id":"21239","city_name":"Budakeszi","state_id":"1650"},
    {"city_id":"21240","city_name":"Budaors","state_id":"1650"},
    {"city_id":"21241","city_name":"Bugyi","state_id":"1650"},
    {"city_id":"21242","city_name":"Cegled","state_id":"1650"},
    {"city_id":"21243","city_name":"Csobanka","state_id":"1650"},
    {"city_id":"21244","city_name":"Dabas","state_id":"1650"},
    {"city_id":"21245","city_name":"Domsod","state_id":"1650"},
    {"city_id":"21246","city_name":"Dunabogdany","state_id":"1650"},
    {"city_id":"21247","city_name":"Dunaharaszti","state_id":"1650"},
    {"city_id":"21248","city_name":"Dunakeszi","state_id":"1650"},
    {"city_id":"21249","city_name":"Erd","state_id":"1650"},
    {"city_id":"21250","city_name":"Forro","state_id":"1650"},
    {"city_id":"21251","city_name":"Fot","state_id":"1650"},
    {"city_id":"21252","city_name":"Galgaheviz","state_id":"1650"},
    {"city_id":"21253","city_name":"God","state_id":"1650"},
    {"city_id":"21254","city_name":"Godollo","state_id":"1650"},
    {"city_id":"21255","city_name":"Gyomro","state_id":"1650"},
    {"city_id":"21256","city_name":"Hevizgyork","state_id":"1650"},
    {"city_id":"21257","city_name":"Isaszeg","state_id":"1650"},
    {"city_id":"21258","city_name":"Jaszkarajeno","state_id":"1650"},
    {"city_id":"21259","city_name":"Kiskunlachaza","state_id":"1650"},
    {"city_id":"21260","city_name":"Kocser","state_id":"1650"},
    {"city_id":"21261","city_name":"Koka","state_id":"1650"},
    {"city_id":"21262","city_name":"Kosd","state_id":"1650"},
    {"city_id":"21263","city_name":"Maglod","state_id":"1650"},
    {"city_id":"21264","city_name":"Monor","state_id":"1650"},
    {"city_id":"21265","city_name":"Nagykata","state_id":"1650"},
    {"city_id":"21266","city_name":"Nagykoros","state_id":"1650"},
    {"city_id":"21267","city_name":"Nagykovacsi","state_id":"1650"},
    {"city_id":"21268","city_name":"Nagymaros","state_id":"1650"},
    {"city_id":"21269","city_name":"Nagytarcsa","state_id":"1650"},
    {"city_id":"21270","city_name":"Nyaregyhaza","state_id":"1650"},
    {"city_id":"21271","city_name":"Ocsa","state_id":"1650"},
    {"city_id":"21272","city_name":"Orbottyan","state_id":"1650"},
    {"city_id":"21273","city_name":"Orkeny","state_id":"1650"},
    {"city_id":"21274","city_name":"Paty","state_id":"1650"},
    {"city_id":"21275","city_name":"Pecel","state_id":"1650"},
    {"city_id":"21276","city_name":"Perbal","state_id":"1650"},
    {"city_id":"21277","city_name":"Pilis","state_id":"1650"},
    {"city_id":"21278","city_name":"Pilisborosjeno","state_id":"1650"},
    {"city_id":"21279","city_name":"Piliscsaba","state_id":"1650"},
    {"city_id":"21280","city_name":"Pilisszanto","state_id":"1650"},
    {"city_id":"21281","city_name":"Pilisszentivan","state_id":"1650"},
    {"city_id":"21282","city_name":"Pilisszentkereszt","state_id":"1650"},
    {"city_id":"21283","city_name":"Pilisvorosvar","state_id":"1650"},
    {"city_id":"21284","city_name":"Pomaz","state_id":"1650"},
    {"city_id":"21285","city_name":"Racalmas","state_id":"1650"},
    {"city_id":"21286","city_name":"Rackeve","state_id":"1650"},
    {"city_id":"21287","city_name":"Solymar","state_id":"1650"},
    {"city_id":"21288","city_name":"Soskut","state_id":"1650"},
    {"city_id":"21289","city_name":"Szada","state_id":"1650"},
    {"city_id":"21290","city_name":"Szazhalombatta","state_id":"1650"},
    {"city_id":"21291","city_name":"Szentendre","state_id":"1650"},
    {"city_id":"21292","city_name":"Szentmartonkata","state_id":"1650"},
    {"city_id":"21293","city_name":"Szigetcsep","state_id":"1650"},
    {"city_id":"21294","city_name":"Szigetszentmiklos","state_id":"1650"},
    {"city_id":"21295","city_name":"Szigetujfalu","state_id":"1650"},
    {"city_id":"21296","city_name":"Szob","state_id":"1650"},
    {"city_id":"21297","city_name":"Tahitofalu","state_id":"1650"},
    {"city_id":"21298","city_name":"Tapiobicske","state_id":"1650"},
    {"city_id":"21299","city_name":"Tapioszecso","state_id":"1650"},
    {"city_id":"21300","city_name":"Tapioszele","state_id":"1650"},
    {"city_id":"21301","city_name":"Toalmas","state_id":"1650"},
    {"city_id":"21302","city_name":"Torokbalint","state_id":"1650"},
    {"city_id":"21303","city_name":"Tortel","state_id":"1650"},
    {"city_id":"21304","city_name":"Tura","state_id":"1650"},
    {"city_id":"21305","city_name":"Ullo","state_id":"1650"},
    {"city_id":"21306","city_name":"Uri","state_id":"1650"},
    {"city_id":"21307","city_name":"Urom","state_id":"1650"},
    {"city_id":"21308","city_name":"Vac","state_id":"1650"},
    {"city_id":"21309","city_name":"Vecses","state_id":"1650"},
    {"city_id":"21310","city_name":"Veresegyhaz","state_id":"1650"},
    {"city_id":"21311","city_name":"Verocemaros","state_id":"1650"},
    {"city_id":"21312","city_name":"Visegrad","state_id":"1650"},
    {"city_id":"21313","city_name":"Zsambek","state_id":"1650"},
    {"city_id":"21314","city_name":"Zsambok","state_id":"1650"},
    {"city_id":"21315","city_name":"Adand","state_id":"1651"},
    {"city_id":"21316","city_name":"Balatonfoldvar","state_id":"1651"},
    {"city_id":"21317","city_name":"Balatonoszod","state_id":"1651"},
    {"city_id":"21318","city_name":"Balatonszabadi","state_id":"1651"},
    {"city_id":"21319","city_name":"Balatonszarszo","state_id":"1651"},
    {"city_id":"21320","city_name":"Barcs","state_id":"1651"},
    {"city_id":"21321","city_name":"Berzence","state_id":"1651"},
    {"city_id":"21322","city_name":"Boglarlelle","state_id":"1651"},
    {"city_id":"21323","city_name":"Bohonye","state_id":"1651"},
    {"city_id":"21324","city_name":"Csurgo","state_id":"1651"},
    {"city_id":"21325","city_name":"Fonyod","state_id":"1651"},
    {"city_id":"21326","city_name":"Kaposvar","state_id":"1651"},
    {"city_id":"21327","city_name":"Karad","state_id":"1651"},
    {"city_id":"21328","city_name":"Kethely","state_id":"1651"},
    {"city_id":"21329","city_name":"Lengyeltoti","state_id":"1651"},
    {"city_id":"21330","city_name":"Marcali","state_id":"1651"},
    {"city_id":"21331","city_name":"Nagyatad","state_id":"1651"},
    {"city_id":"21332","city_name":"Nagybajom","state_id":"1651"},
    {"city_id":"21333","city_name":"Siofok","state_id":"1651"},
    {"city_id":"21334","city_name":"Somogyvar","state_id":"1651"},
    {"city_id":"21335","city_name":"Tab","state_id":"1651"},
    {"city_id":"21336","city_name":"Zamardi","state_id":"1651"},
    {"city_id":"21337","city_name":"Ajak","state_id":"1652"},
    {"city_id":"21338","city_name":"Baktaloranthaza","state_id":"1652"},
    {"city_id":"21339","city_name":"Balkany","state_id":"1652"},
    {"city_id":"21340","city_name":"Buj","state_id":"1652"},
    {"city_id":"21341","city_name":"Demecser","state_id":"1652"},
    {"city_id":"21342","city_name":"Dombrad","state_id":"1652"},
    {"city_id":"21343","city_name":"Fehergyarmat","state_id":"1652"},
    {"city_id":"21344","city_name":"Ibrany","state_id":"1652"},
    {"city_id":"21345","city_name":"Kemecse","state_id":"1652"},
    {"city_id":"21346","city_name":"Kisvarda","state_id":"1652"},
    {"city_id":"21347","city_name":"Kotaj","state_id":"1652"},
    {"city_id":"21348","city_name":"Mandok","state_id":"1652"},
    {"city_id":"21349","city_name":"Mariapocs","state_id":"1652"},
    {"city_id":"21350","city_name":"Mateszalka","state_id":"1652"},
    {"city_id":"21351","city_name":"Nagyecsed","state_id":"1652"},
    {"city_id":"21352","city_name":"Nagyhalasz","state_id":"1652"},
    {"city_id":"21353","city_name":"Nagykallo","state_id":"1652"},
    {"city_id":"21354","city_name":"Nyirbator","state_id":"1652"},
    {"city_id":"21355","city_name":"Nyirbeltek","state_id":"1652"},
    {"city_id":"21356","city_name":"Nyiregyhaza","state_id":"1652"},
    {"city_id":"21357","city_name":"Nyirmada","state_id":"1652"},
    {"city_id":"21358","city_name":"Nyirpazony","state_id":"1652"},
    {"city_id":"21359","city_name":"Nyirtelek","state_id":"1652"},
    {"city_id":"21360","city_name":"Ofeherto","state_id":"1652"},
    {"city_id":"21361","city_name":"Rakamaz","state_id":"1652"},
    {"city_id":"21362","city_name":"Tarpa","state_id":"1652"},
    {"city_id":"21363","city_name":"Tiszabercel","state_id":"1652"},
    {"city_id":"21364","city_name":"Tiszalok","state_id":"1652"},
    {"city_id":"21365","city_name":"Tiszavasvari","state_id":"1652"},
    {"city_id":"21366","city_name":"Tuzser","state_id":"1652"},
    {"city_id":"21367","city_name":"Vaja","state_id":"1652"},
    {"city_id":"21368","city_name":"Vasarosnameny","state_id":"1652"},
    {"city_id":"21369","city_name":"Zahony","state_id":"1652"},
    {"city_id":"21370","city_name":"Bataszek","state_id":"1653"},
    {"city_id":"21371","city_name":"Bonyhad","state_id":"1653"},
    {"city_id":"21372","city_name":"Decs","state_id":"1653"},
    {"city_id":"21373","city_name":"Dombovar","state_id":"1653"},
    {"city_id":"21374","city_name":"Dunafoldvar","state_id":"1653"},
    {"city_id":"21375","city_name":"Fadd","state_id":"1653"},
    {"city_id":"21376","city_name":"Gyonk","state_id":"1653"},
    {"city_id":"21377","city_name":"Hogyesz","state_id":"1653"},
    {"city_id":"21378","city_name":"Iregszemcse","state_id":"1653"},
    {"city_id":"21379","city_name":"Madocsa","state_id":"1653"},
    {"city_id":"21380","city_name":"Nagymagocs","state_id":"1653"},
    {"city_id":"21381","city_name":"Nagymanyok","state_id":"1653"},
    {"city_id":"21382","city_name":"Ozora","state_id":"1653"},
    {"city_id":"21383","city_name":"Paks","state_id":"1653"},
    {"city_id":"21384","city_name":"Pincehely","state_id":"1653"},
    {"city_id":"21385","city_name":"Simontornya","state_id":"1653"},
    {"city_id":"21386","city_name":"Szekszard","state_id":"1653"},
    {"city_id":"21387","city_name":"Szentgal","state_id":"1653"},
    {"city_id":"21388","city_name":"Tamasi","state_id":"1653"},
    {"city_id":"21389","city_name":"Tengelic","state_id":"1653"},
    {"city_id":"21390","city_name":"Tolna","state_id":"1653"},
    {"city_id":"21391","city_name":"Zomba","state_id":"1653"},
    {"city_id":"21392","city_name":"Buk","state_id":"1654"},
    {"city_id":"21393","city_name":"Celldomolk","state_id":"1654"},
    {"city_id":"21394","city_name":"Csepreg","state_id":"1654"},
    {"city_id":"21395","city_name":"Gencsapati","state_id":"1654"},
    {"city_id":"21396","city_name":"Jak","state_id":"1654"},
    {"city_id":"21397","city_name":"Janoshaza","state_id":"1654"},
    {"city_id":"21398","city_name":"Kormend","state_id":"1654"},
    {"city_id":"21399","city_name":"Koszeg","state_id":"1654"},
    {"city_id":"21400","city_name":"Sarvar","state_id":"1654"},
    {"city_id":"21401","city_name":"Szentgotthard","state_id":"1654"},
    {"city_id":"21402","city_name":"Szombathely","state_id":"1654"},
    {"city_id":"21403","city_name":"Vasvar","state_id":"1654"},
    {"city_id":"21404","city_name":"Vep","state_id":"1654"},
    {"city_id":"21405","city_name":"Ajka","state_id":"1655"},
    {"city_id":"21406","city_name":"Badacsonytomaj","state_id":"1655"},
    {"city_id":"21407","city_name":"Balatonalmadi","state_id":"1655"},
    {"city_id":"21408","city_name":"Balatonfured","state_id":"1655"},
    {"city_id":"21409","city_name":"Balatonfuzfo","state_id":"1655"},
    {"city_id":"21410","city_name":"Balatonkenese","state_id":"1655"},
    {"city_id":"21411","city_name":"Band","state_id":"1655"},
    {"city_id":"21412","city_name":"Berhida","state_id":"1655"},
    {"city_id":"21413","city_name":"Csabrendek","state_id":"1655"},
    {"city_id":"21414","city_name":"Devecser","state_id":"1655"},
    {"city_id":"21415","city_name":"Herend","state_id":"1655"},
    {"city_id":"21416","city_name":"Papa","state_id":"1655"},
    {"city_id":"21417","city_name":"Sumeg","state_id":"1655"},
    {"city_id":"21418","city_name":"Tapolca","state_id":"1655"},
    {"city_id":"21419","city_name":"Urkut","state_id":"1655"},
    {"city_id":"21420","city_name":"Varpalota","state_id":"1655"},
    {"city_id":"21421","city_name":"Veszprem","state_id":"1655"},
    {"city_id":"21422","city_name":"Zirc","state_id":"1655"},
    {"city_id":"21423","city_name":"Becsehely","state_id":"1656"},
    {"city_id":"21424","city_name":"Heviz","state_id":"1656"},
    {"city_id":"21425","city_name":"Keszthely","state_id":"1656"},
    {"city_id":"21426","city_name":"Lenti","state_id":"1656"},
    {"city_id":"21427","city_name":"Letenye","state_id":"1656"},
    {"city_id":"21428","city_name":"Nagykanizsa","state_id":"1656"},
    {"city_id":"21429","city_name":"Sarmellek","state_id":"1656"},
    {"city_id":"21430","city_name":"Turje","state_id":"1656"},
    {"city_id":"21431","city_name":"Zalaegerszeg","state_id":"1656"},
    {"city_id":"21432","city_name":"Zalakomar","state_id":"1656"},
    {"city_id":"21433","city_name":"Zalalovo","state_id":"1656"},
    {"city_id":"21434","city_name":"Zalaszentgrot","state_id":"1656"},
    {"city_id":"21435","city_name":"Bakkafjor ur","state_id":"1657"},
    {"city_id":"21436","city_name":"Borgarfjor ur","state_id":"1657"},
    {"city_id":"21437","city_name":"Brei dalsvik","state_id":"1657"},
    {"city_id":"21438","city_name":"Djupivogur","state_id":"1657"},
    {"city_id":"21439","city_name":"Egilssta ir","state_id":"1657"},
    {"city_id":"21440","city_name":"Eskifjor ur","state_id":"1657"},
    {"city_id":"21441","city_name":"Faskru sfjor ur","state_id":"1657"},
    {"city_id":"21442","city_name":"Fellabar","state_id":"1657"},
    {"city_id":"21443","city_name":"Hallormssta ur","state_id":"1657"},
    {"city_id":"21444","city_name":"Hofn","state_id":"1657"},
    {"city_id":"21445","city_name":"Nesjakauptun","state_id":"1657"},
    {"city_id":"21446","city_name":"Neskaupsta ur","state_id":"1657"},
    {"city_id":"21447","city_name":"Rey arfjor ur","state_id":"1657"},
    {"city_id":"21448","city_name":"Sey isfjor ur","state_id":"1657"},
    {"city_id":"21449","city_name":"Sto varfjor ur","state_id":"1657"},
    {"city_id":"21450","city_name":"Vopnafjor ur","state_id":"1657"},
    {"city_id":"21451","city_name":"Akranes","state_id":"1665"},
    {"city_id":"21452","city_name":"Borgarnes","state_id":"1665"},
    {"city_id":"21453","city_name":"Bu ardalur","state_id":"1665"},
    {"city_id":"21454","city_name":"Grundarfjor ur","state_id":"1665"},
    {"city_id":"21455","city_name":"Hellissandur","state_id":"1665"},
    {"city_id":"21456","city_name":"Hvanneyri","state_id":"1665"},
    {"city_id":"21457","city_name":"Olafsvik","state_id":"1665"},
    {"city_id":"21458","city_name":"Rif","state_id":"1665"},
    {"city_id":"21459","city_name":"Stykkisholmur","state_id":"1665"},
    {"city_id":"21460","city_name":"Banda Aceh","state_id":"1666"},
    {"city_id":"21461","city_name":"Bireun","state_id":"1666"},
    {"city_id":"21462","city_name":"Langsa","state_id":"1666"},
    {"city_id":"21463","city_name":"Lhokseumawe","state_id":"1666"},
    {"city_id":"21464","city_name":"Meulaboh","state_id":"1666"},
    {"city_id":"21465","city_name":"Denpasar","state_id":"1667"},
    {"city_id":"21466","city_name":"Karangasem","state_id":"1667"},
    {"city_id":"21467","city_name":"Klungkung","state_id":"1667"},
    {"city_id":"21468","city_name":"Kuta","state_id":"1667"},
    {"city_id":"21469","city_name":"Negara","state_id":"1667"},
    {"city_id":"21470","city_name":"Singaraja","state_id":"1667"},
    {"city_id":"21471","city_name":"Tabanan","state_id":"1667"},
    {"city_id":"21472","city_name":"Ubud","state_id":"1667"},
    {"city_id":"21473","city_name":"Manggar","state_id":"1668"},
    {"city_id":"21474","city_name":"Mentok","state_id":"1668"},
    {"city_id":"21475","city_name":"Pangkal Pinang","state_id":"1668"},
    {"city_id":"21476","city_name":"Sungai Liat","state_id":"1668"},
    {"city_id":"21477","city_name":"Tanjung Pandan","state_id":"1668"},
    {"city_id":"21478","city_name":"Toboali-Rias","state_id":"1668"},
    {"city_id":"21479","city_name":"Cikupa","state_id":"1669"},
    {"city_id":"21480","city_name":"Cilegon","state_id":"1669"},
    {"city_id":"21481","city_name":"Ciputat","state_id":"1669"},
    {"city_id":"21482","city_name":"Curug","state_id":"1669"},
    {"city_id":"21483","city_name":"Kresek","state_id":"1669"},
    {"city_id":"21484","city_name":"Labuhan","state_id":"1669"},
    {"city_id":"21485","city_name":"Pandegelang","state_id":"1669"},
    {"city_id":"21486","city_name":"Pondok Aren","state_id":"1669"},
    {"city_id":"21487","city_name":"Rangkasbitung","state_id":"1669"},
    {"city_id":"21488","city_name":"Serang","state_id":"1669"},
    {"city_id":"21489","city_name":"Serpong","state_id":"1669"},
    {"city_id":"21490","city_name":"Tangerang","state_id":"1669"},
    {"city_id":"21491","city_name":"Teluknaga","state_id":"1669"},
    {"city_id":"21492","city_name":"Bengkulu","state_id":"1670"},
    {"city_id":"21493","city_name":"Curup","state_id":"1670"},
    {"city_id":"21494","city_name":"Gandaria","state_id":"1671"},
    {"city_id":"21495","city_name":"Gorontalo","state_id":"1672"},
    {"city_id":"21496","city_name":"Cengkareng","state_id":"1673"},
    {"city_id":"21497","city_name":"Jakarta","state_id":"1673"},
    {"city_id":"21498","city_name":"Jambi","state_id":"1674"},
    {"city_id":"21499","city_name":"Kualatungka","state_id":"1674"},
    {"city_id":"21500","city_name":"Simpang","state_id":"1674"},
    {"city_id":"21501","city_name":"Sungaipenuh","state_id":"1674"},
    {"city_id":"21502","city_name":"Kendal","state_id":"1682"},
    {"city_id":"21503","city_name":"Bandar Lampung","state_id":"1683"},
    {"city_id":"21504","city_name":"Kota Bumi","state_id":"1683"},
    {"city_id":"21505","city_name":"Metro","state_id":"1683"},
    {"city_id":"21506","city_name":"Pringsewu","state_id":"1683"},
    {"city_id":"21507","city_name":"Terbanggi Besar","state_id":"1683"},
    {"city_id":"21508","city_name":"Amahai","state_id":"1684"},
    {"city_id":"21509","city_name":"Ambon","state_id":"1684"},
    {"city_id":"21510","city_name":"Tual","state_id":"1684"},
    {"city_id":"21511","city_name":"Amahai","state_id":"1685"},
    {"city_id":"21512","city_name":"Ambon","state_id":"1685"},
    {"city_id":"21513","city_name":"Tual","state_id":"1685"},
    {"city_id":"21514","city_name":"Aberpura","state_id":"1688"},
    {"city_id":"21515","city_name":"Biak","state_id":"1688"},
    {"city_id":"21516","city_name":"Jaya Pura","state_id":"1688"},
    {"city_id":"21517","city_name":"Manokwari","state_id":"1688"},
    {"city_id":"21518","city_name":"Merauke","state_id":"1688"},
    {"city_id":"21519","city_name":"Sorong","state_id":"1688"},
    {"city_id":"21520","city_name":"Balaipungut","state_id":"1689"},
    {"city_id":"21521","city_name":"Bengkalis","state_id":"1689"},
    {"city_id":"21522","city_name":"Dumai","state_id":"1689"},
    {"city_id":"21523","city_name":"Duri","state_id":"1689"},
    {"city_id":"21524","city_name":"Pekan Baru","state_id":"1689"},
    {"city_id":"21525","city_name":"Selatpanjang","state_id":"1689"},
    {"city_id":"21526","city_name":"Tanjung Balai-Meral","state_id":"1689"},
    {"city_id":"21527","city_name":"Tembilahan","state_id":"1689"},
    {"city_id":"21528","city_name":"Balaipungut","state_id":"1690"},
    {"city_id":"21529","city_name":"Bengkalis","state_id":"1690"},
    {"city_id":"21530","city_name":"Dumai","state_id":"1690"},
    {"city_id":"21531","city_name":"Duri","state_id":"1690"},
    {"city_id":"21532","city_name":"Pekan Baru","state_id":"1690"},
    {"city_id":"21533","city_name":"Selatpanjang","state_id":"1690"},
    {"city_id":"21534","city_name":"Tanjung Balai-Meral","state_id":"1690"},
    {"city_id":"21535","city_name":"Tembilahan","state_id":"1690"},
    {"city_id":"21536","city_name":"Solo","state_id":"1691"},
    {"city_id":"21537","city_name":"Bambanglipuro","state_id":"1699"},
    {"city_id":"21538","city_name":"Banguntapan","state_id":"1699"},
    {"city_id":"21539","city_name":"Bantul","state_id":"1699"},
    {"city_id":"21540","city_name":"Depok","state_id":"1699"},
    {"city_id":"21541","city_name":"Gamping","state_id":"1699"},
    {"city_id":"21542","city_name":"Godean","state_id":"1699"},
    {"city_id":"21543","city_name":"Jetis","state_id":"1699"},
    {"city_id":"21544","city_name":"Kasihan","state_id":"1699"},
    {"city_id":"21545","city_name":"Ngaglik","state_id":"1699"},
    {"city_id":"21546","city_name":"Pandak","state_id":"1699"},
    {"city_id":"21547","city_name":"Pundong","state_id":"1699"},
    {"city_id":"21548","city_name":"Sewon","state_id":"1699"},
    {"city_id":"21549","city_name":"Seyegan","state_id":"1699"},
    {"city_id":"21550","city_name":"Sleman","state_id":"1699"},
    {"city_id":"21551","city_name":"Srandakan","state_id":"1699"},
    {"city_id":"21552","city_name":"Wonosari","state_id":"1699"},
    {"city_id":"21553","city_name":"Yogyakarta","state_id":"1699"},
    {"city_id":"21554","city_name":"Ardabil","state_id":"1700"},
    {"city_id":"21555","city_name":"Garmi","state_id":"1700"},
    {"city_id":"21556","city_name":"Khalkhal","state_id":"1700"},
    {"city_id":"21557","city_name":"Meshkinshahr","state_id":"1700"},
    {"city_id":"21558","city_name":"Parsabad","state_id":"1700"},
    {"city_id":"21559","city_name":"Bandar-e Gonaveh","state_id":"1703"},
    {"city_id":"21560","city_name":"Borazjan","state_id":"1703"},
    {"city_id":"21561","city_name":"Bushehr","state_id":"1703"},
    {"city_id":"21562","city_name":"Dashti","state_id":"1703"},
    {"city_id":"21563","city_name":"Dir","state_id":"1703"},
    {"city_id":"21564","city_name":"Khormuj","state_id":"1703"},
    {"city_id":"21565","city_name":"Kongan","state_id":"1703"},
    {"city_id":"21566","city_name":"Tangestan","state_id":"1703"},
    {"city_id":"21567","city_name":"Ardistan","state_id":"1705"},
    {"city_id":"21568","city_name":"Dorchehpiyaz","state_id":"1705"},
    {"city_id":"21569","city_name":"Dowlatabad","state_id":"1705"},
    {"city_id":"21570","city_name":"Esfahan","state_id":"1705"},
    {"city_id":"21571","city_name":"Falavarjan","state_id":"1705"},
    {"city_id":"21572","city_name":"Faridan","state_id":"1705"},
    {"city_id":"21573","city_name":"Fereydunshahr","state_id":"1705"},
    {"city_id":"21574","city_name":"Fuladshahr","state_id":"1705"},
    {"city_id":"21575","city_name":"Golara","state_id":"1705"},
    {"city_id":"21576","city_name":"Golpayegan","state_id":"1705"},
    {"city_id":"21577","city_name":"Kashan","state_id":"1705"},
    {"city_id":"21578","city_name":"Kelishad","state_id":"1705"},
    {"city_id":"21579","city_name":"Khomeynishahr","state_id":"1705"},
    {"city_id":"21580","city_name":"Khonsar","state_id":"1705"},
    {"city_id":"21581","city_name":"Khuresgan","state_id":"1705"},
    {"city_id":"21582","city_name":"Mobarakeh","state_id":"1705"},
    {"city_id":"21583","city_name":"Na'in","state_id":"1705"},
    {"city_id":"21584","city_name":"Najafabad","state_id":"1705"},
    {"city_id":"21585","city_name":"Natnaz","state_id":"1705"},
    {"city_id":"21586","city_name":"Qahdarijan","state_id":"1705"},
    {"city_id":"21587","city_name":"Rehnan","state_id":"1705"},
    {"city_id":"21588","city_name":"Semirom","state_id":"1705"},
    {"city_id":"21589","city_name":"Shahinshahr","state_id":"1705"},
    {"city_id":"21590","city_name":"Shahreza","state_id":"1705"},
    {"city_id":"21591","city_name":"Zarinshahr","state_id":"1705"},
    {"city_id":"21592","city_name":"Abadeh","state_id":"1706"},
    {"city_id":"21593","city_name":"Akbarabad","state_id":"1706"},
    {"city_id":"21594","city_name":"Darab","state_id":"1706"},
    {"city_id":"21595","city_name":"Eqlid","state_id":"1706"},
    {"city_id":"21596","city_name":"Estehban","state_id":"1706"},
    {"city_id":"21597","city_name":"Fasa","state_id":"1706"},
    {"city_id":"21598","city_name":"Firuzabad","state_id":"1706"},
    {"city_id":"21599","city_name":"Gerash","state_id":"1706"},
    {"city_id":"21600","city_name":"Jahrom","state_id":"1706"},
    {"city_id":"21601","city_name":"Kazerun","state_id":"1706"},
    {"city_id":"21602","city_name":"Lar","state_id":"1706"},
    {"city_id":"21603","city_name":"Marv Dasht","state_id":"1706"},
    {"city_id":"21604","city_name":"Neyriz","state_id":"1706"},
    {"city_id":"21605","city_name":"Nurabad","state_id":"1706"},
    {"city_id":"21606","city_name":"Qa'emiyeh","state_id":"1706"},
    {"city_id":"21607","city_name":"Sepidan","state_id":"1706"},
    {"city_id":"21608","city_name":"Shiraz","state_id":"1706"},
    {"city_id":"21609","city_name":"Astaneh-ye Ashrafiyeh","state_id":"1707"},
    {"city_id":"21610","city_name":"Astara","state_id":"1707"},
    {"city_id":"21611","city_name":"Bandar-e Anzali","state_id":"1707"},
    {"city_id":"21612","city_name":"Faman","state_id":"1707"},
    {"city_id":"21613","city_name":"Hashtpar","state_id":"1707"},
    {"city_id":"21614","city_name":"Lahijan","state_id":"1707"},
    {"city_id":"21615","city_name":"Langarud","state_id":"1707"},
    {"city_id":"21616","city_name":"Rasht","state_id":"1707"},
    {"city_id":"21617","city_name":"Rudbar","state_id":"1707"},
    {"city_id":"21618","city_name":"Rudsar","state_id":"1707"},
    {"city_id":"21619","city_name":"Sawma'eh Sara","state_id":"1707"},
    {"city_id":"21620","city_name":"Aq Qal'eh","state_id":"1708"},
    {"city_id":"21621","city_name":"Azad Shahr","state_id":"1708"},
    {"city_id":"21622","city_name":"Bandar-e Torkaman","state_id":"1708"},
    {"city_id":"21623","city_name":"Gonbad-e Qabus","state_id":"1708"},
    {"city_id":"21624","city_name":"Gorgan","state_id":"1708"},
    {"city_id":"21625","city_name":"Asadabad","state_id":"1709"},
    {"city_id":"21626","city_name":"Bahar","state_id":"1709"},
    {"city_id":"21627","city_name":"Hamadan","state_id":"1709"},
    {"city_id":"21628","city_name":"Malayer","state_id":"1709"},
    {"city_id":"21629","city_name":"Nahavand","state_id":"1709"},
    {"city_id":"21630","city_name":"Tuysarkan","state_id":"1709"},
    {"city_id":"21631","city_name":"Bandar Abbas","state_id":"1710"},
    {"city_id":"21632","city_name":"Bandar-e 'Abbas","state_id":"1710"},
    {"city_id":"21633","city_name":"Bandar-e Lengeh","state_id":"1710"},
    {"city_id":"21634","city_name":"Gheshm","state_id":"1710"},
    {"city_id":"21635","city_name":"Jask","state_id":"1710"},
    {"city_id":"21636","city_name":"Kish","state_id":"1710"},
    {"city_id":"21637","city_name":"Kish Island","state_id":"1710"},
    {"city_id":"21638","city_name":"Minab","state_id":"1710"},
    {"city_id":"21639","city_name":"Abdanan","state_id":"1711"},
    {"city_id":"21640","city_name":"Darrehshahr","state_id":"1711"},
    {"city_id":"21641","city_name":"Dehloran","state_id":"1711"},
    {"city_id":"21642","city_name":"Ilam","state_id":"1711"},
    {"city_id":"21643","city_name":"Ivan","state_id":"1711"},
    {"city_id":"21644","city_name":"Mehran","state_id":"1711"},
    {"city_id":"21645","city_name":"Baft","state_id":"1712"},
    {"city_id":"21646","city_name":"Bam","state_id":"1712"},
    {"city_id":"21647","city_name":"Bardsir","state_id":"1712"},
    {"city_id":"21648","city_name":"Jiroft","state_id":"1712"},
    {"city_id":"21649","city_name":"Kahnuj","state_id":"1712"},
    {"city_id":"21650","city_name":"Kerman","state_id":"1712"},
    {"city_id":"21651","city_name":"Rafsanjan","state_id":"1712"},
    {"city_id":"21652","city_name":"Ravar","state_id":"1712"},
    {"city_id":"21653","city_name":"Shahr-e Babak","state_id":"1712"},
    {"city_id":"21654","city_name":"Sirjan","state_id":"1712"},
    {"city_id":"21655","city_name":"Zarand","state_id":"1712"},
    {"city_id":"21656","city_name":"Eslamabad","state_id":"1713"},
    {"city_id":"21657","city_name":"Gilan-e Garb","state_id":"1713"},
    {"city_id":"21658","city_name":"Harsin","state_id":"1713"},
    {"city_id":"21659","city_name":"Javanrud","state_id":"1713"},
    {"city_id":"21660","city_name":"Kangavar","state_id":"1713"},
    {"city_id":"21661","city_name":"Kermanshah","state_id":"1713"},
    {"city_id":"21662","city_name":"Paveh","state_id":"1713"},
    {"city_id":"21663","city_name":"Sahneh","state_id":"1713"},
    {"city_id":"21664","city_name":"Sar-e-Pol-e-Zohab","state_id":"1713"},
    {"city_id":"21665","city_name":"Sonqor","state_id":"1713"},
    {"city_id":"21666","city_name":"Birjand","state_id":"1714"},
    {"city_id":"21667","city_name":"Bojnurd","state_id":"1714"},
    {"city_id":"21668","city_name":"Chenaran","state_id":"1714"},
    {"city_id":"21669","city_name":"Darreh Gaz","state_id":"1714"},
    {"city_id":"21670","city_name":"Esfarayen","state_id":"1714"},
    {"city_id":"21671","city_name":"Fariman","state_id":"1714"},
    {"city_id":"21672","city_name":"Ferdus","state_id":"1714"},
    {"city_id":"21673","city_name":"Gha'nat","state_id":"1714"},
    {"city_id":"21674","city_name":"Gonabad","state_id":"1714"},
    {"city_id":"21675","city_name":"Kashmar","state_id":"1714"},
    {"city_id":"21676","city_name":"Mashad","state_id":"1714"},
    {"city_id":"21677","city_name":"Mashhad","state_id":"1714"},
    {"city_id":"21678","city_name":"Neyshabur","state_id":"1714"},
    {"city_id":"21679","city_name":"Qayen","state_id":"1714"},
    {"city_id":"21680","city_name":"Quchan","state_id":"1714"},
    {"city_id":"21681","city_name":"Sabzevar","state_id":"1714"},
    {"city_id":"21682","city_name":"Sarakhs","state_id":"1714"},
    {"city_id":"21683","city_name":"Shirvan","state_id":"1714"},
    {"city_id":"21684","city_name":"Tabas","state_id":"1714"},
    {"city_id":"21685","city_name":"Tayyebat","state_id":"1714"},
    {"city_id":"21686","city_name":"Torbat-e Heydariyeh","state_id":"1714"},
    {"city_id":"21687","city_name":"Torbat-e Jam","state_id":"1714"},
    {"city_id":"21688","city_name":"Abadan","state_id":"1715"},
    {"city_id":"21689","city_name":"Agha Jari","state_id":"1715"},
    {"city_id":"21690","city_name":"Ahvaz","state_id":"1715"},
    {"city_id":"21691","city_name":"Ahwaz","state_id":"1715"},
    {"city_id":"21692","city_name":"Andimeshk","state_id":"1715"},
    {"city_id":"21693","city_name":"Bandar-e Emam Khomeyni","state_id":"1715"},
    {"city_id":"21694","city_name":"Bandar-e Mahshahr","state_id":"1715"},
    {"city_id":"21695","city_name":"Behbahan","state_id":"1715"},
    {"city_id":"21696","city_name":"Dezful","state_id":"1715"},
    {"city_id":"21697","city_name":"Ezeh","state_id":"1715"},
    {"city_id":"21698","city_name":"Hendijan","state_id":"1715"},
    {"city_id":"21699","city_name":"Khorramshahr","state_id":"1715"},
    {"city_id":"21700","city_name":"Masjed-e Soleyman","state_id":"1715"},
    {"city_id":"21701","city_name":"Omidiyeh","state_id":"1715"},
    {"city_id":"21702","city_name":"Ramhormoz","state_id":"1715"},
    {"city_id":"21703","city_name":"Ramshir","state_id":"1715"},
    {"city_id":"21704","city_name":"Shadegan","state_id":"1715"},
    {"city_id":"21705","city_name":"Shush","state_id":"1715"},
    {"city_id":"21706","city_name":"Shushtar","state_id":"1715"},
    {"city_id":"21707","city_name":"Susangerd","state_id":"1715"},
    {"city_id":"21708","city_name":"Baneh","state_id":"1717"},
    {"city_id":"21709","city_name":"Bijar","state_id":"1717"},
    {"city_id":"21710","city_name":"Kamyaran","state_id":"1717"},
    {"city_id":"21711","city_name":"Marivan","state_id":"1717"},
    {"city_id":"21712","city_name":"Qorveh","state_id":"1717"},
    {"city_id":"21713","city_name":"Sanandaj","state_id":"1717"},
    {"city_id":"21714","city_name":"Saqqez","state_id":"1717"},
    {"city_id":"21715","city_name":"Alashtar","state_id":"1718"},
    {"city_id":"21716","city_name":"Aligudarz","state_id":"1718"},
    {"city_id":"21717","city_name":"Azna","state_id":"1718"},
    {"city_id":"21718","city_name":"Borujerd","state_id":"1718"},
    {"city_id":"21719","city_name":"Do Rud","state_id":"1718"},
    {"city_id":"21720","city_name":"Khorramabad","state_id":"1718"},
    {"city_id":"21721","city_name":"Kuhdasht","state_id":"1718"},
    {"city_id":"21722","city_name":"Nurabad","state_id":"1718"},
    {"city_id":"21723","city_name":"Arak","state_id":"1719"},
    {"city_id":"21724","city_name":"Ashtian","state_id":"1719"},
    {"city_id":"21725","city_name":"Delijan","state_id":"1719"},
    {"city_id":"21726","city_name":"Khomeyn","state_id":"1719"},
    {"city_id":"21727","city_name":"Mahallat","state_id":"1719"},
    {"city_id":"21728","city_name":"Sarband","state_id":"1719"},
    {"city_id":"21729","city_name":"Saveh","state_id":"1719"},
    {"city_id":"21730","city_name":"Tafresh","state_id":"1719"},
    {"city_id":"21731","city_name":"Aliabad","state_id":"1720"},
    {"city_id":"21732","city_name":"Amir Kala","state_id":"1720"},
    {"city_id":"21733","city_name":"Amol","state_id":"1720"},
    {"city_id":"21734","city_name":"Babol","state_id":"1720"},
    {"city_id":"21735","city_name":"Babol Sar","state_id":"1720"},
    {"city_id":"21736","city_name":"Behshahr","state_id":"1720"},
    {"city_id":"21737","city_name":"Chalus","state_id":"1720"},
    {"city_id":"21738","city_name":"Fereydunkenar","state_id":"1720"},
    {"city_id":"21739","city_name":"Juybar","state_id":"1720"},
    {"city_id":"21740","city_name":"Kalaleh","state_id":"1720"},
    {"city_id":"21741","city_name":"Kordkuy","state_id":"1720"},
    {"city_id":"21742","city_name":"Mahmudabad","state_id":"1720"},
    {"city_id":"21743","city_name":"Minudasht","state_id":"1720"},
    {"city_id":"21744","city_name":"Neka","state_id":"1720"},
    {"city_id":"21745","city_name":"Nur","state_id":"1720"},
    {"city_id":"21746","city_name":"Nushahr","state_id":"1720"},
    {"city_id":"21747","city_name":"Qa'emshahr","state_id":"1720"},
    {"city_id":"21748","city_name":"Ramsar","state_id":"1720"},
    {"city_id":"21749","city_name":"Sari","state_id":"1720"},
    {"city_id":"21750","city_name":"Savadkuh","state_id":"1720"},
    {"city_id":"21751","city_name":"Tonekabon","state_id":"1720"},
    {"city_id":"21752","city_name":"Abhar","state_id":"1722"},
    {"city_id":"21753","city_name":"Abyek","state_id":"1722"},
    {"city_id":"21754","city_name":"Qazvin","state_id":"1722"},
    {"city_id":"21755","city_name":"Takestan","state_id":"1722"},
    {"city_id":"21756","city_name":"Qom","state_id":"1723"},
    {"city_id":"21757","city_name":"Damghan","state_id":"1724"},
    {"city_id":"21758","city_name":"Garmsar","state_id":"1724"},
    {"city_id":"21759","city_name":"Semnan","state_id":"1724"},
    {"city_id":"21760","city_name":"Shahrud","state_id":"1724"},
    {"city_id":"21761","city_name":"Damavand","state_id":"1726"},
    {"city_id":"21762","city_name":"Eqbaliyeh","state_id":"1726"},
    {"city_id":"21763","city_name":"Eslamshahr","state_id":"1726"},
    {"city_id":"21764","city_name":"Hashtgerd","state_id":"1726"},
    {"city_id":"21765","city_name":"Karaj","state_id":"1726"},
    {"city_id":"21766","city_name":"Mahdasht","state_id":"1726"},
    {"city_id":"21767","city_name":"Malard","state_id":"1726"},
    {"city_id":"21768","city_name":"Mohammadiyeh","state_id":"1726"},
    {"city_id":"21769","city_name":"Nazarabad","state_id":"1726"},
    {"city_id":"21770","city_name":"Pakdasht","state_id":"1726"},
    {"city_id":"21771","city_name":"Pishva","state_id":"1726"},
    {"city_id":"21772","city_name":"Qarchak","state_id":"1726"},
    {"city_id":"21773","city_name":"Qods","state_id":"1726"},
    {"city_id":"21774","city_name":"Robat Karim","state_id":"1726"},
    {"city_id":"21775","city_name":"Shahriyar","state_id":"1726"},
    {"city_id":"21776","city_name":"Tehran","state_id":"1726"},
    {"city_id":"21777","city_name":"Varamin","state_id":"1726"},
    {"city_id":"21778","city_name":"Ardakan","state_id":"1727"},
    {"city_id":"21779","city_name":"Bafq","state_id":"1727"},
    {"city_id":"21780","city_name":"Mehriz","state_id":"1727"},
    {"city_id":"21781","city_name":"Meybod","state_id":"1727"},
    {"city_id":"21782","city_name":"Taft","state_id":"1727"},
    {"city_id":"21783","city_name":"Yazd","state_id":"1727"},
    {"city_id":"21784","city_name":"Alvand","state_id":"1728"},
    {"city_id":"21785","city_name":"Khorramdarreh","state_id":"1728"},
    {"city_id":"21786","city_name":"Zanjan","state_id":"1728"},
    {"city_id":"21787","city_name":"Jurf-as-Sakhr","state_id":"1729"},
    {"city_id":"21788","city_name":"Saddat-al-Hindiyah","state_id":"1729"},
    {"city_id":"21789","city_name":"al-Hillah","state_id":"1729"},
    {"city_id":"21790","city_name":"al-Madhatiyah","state_id":"1729"},
    {"city_id":"21791","city_name":"al-Musayyib","state_id":"1729"},
    {"city_id":"21792","city_name":"al-Qasim","state_id":"1729"},
    {"city_id":"21793","city_name":"Baghdad","state_id":"1730"},
    {"city_id":"21794","city_name":"Dahuk","state_id":"1731"},
    {"city_id":"21795","city_name":"Zakhu","state_id":"1731"},
    {"city_id":"21796","city_name":"Ba'qubah","state_id":"1733"},
    {"city_id":"21797","city_name":"Hanaqin","state_id":"1733"},
    {"city_id":"21798","city_name":"Jalula''","state_id":"1733"},
    {"city_id":"21799","city_name":"Kifri","state_id":"1733"},
    {"city_id":"21800","city_name":"Mandali","state_id":"1733"},
    {"city_id":"21801","city_name":"al-Fuhud","state_id":"1733"},
    {"city_id":"21802","city_name":"al-Khalis","state_id":"1733"},
    {"city_id":"21803","city_name":"al-Miqdadiyah","state_id":"1733"},
    {"city_id":"21804","city_name":"ash-Shatrah","state_id":"1733"},
    {"city_id":"21805","city_name":"Ankawa","state_id":"1734"},
    {"city_id":"21806","city_name":"Chaqalawa","state_id":"1735"},
    {"city_id":"21807","city_name":"Irbil","state_id":"1735"},
    {"city_id":"21808","city_name":"Rawanduz","state_id":"1735"},
    {"city_id":"21809","city_name":"Karbala","state_id":"1736"},
    {"city_id":"21810","city_name":"al-Hindiyah","state_id":"1736"},
    {"city_id":"21811","city_name":"Erbil","state_id":"1737"},
    {"city_id":"21812","city_name":"'Ali al Gharbi","state_id":"1738"},
    {"city_id":"21813","city_name":"al-'Amarah","state_id":"1738"},
    {"city_id":"21814","city_name":"al-Majarr-al-Kabir","state_id":"1738"},
    {"city_id":"21815","city_name":"Qarah Qush","state_id":"1739"},
    {"city_id":"21816","city_name":"Sinjar","state_id":"1739"},
    {"city_id":"21817","city_name":"Tall 'Afar","state_id":"1739"},
    {"city_id":"21818","city_name":"Tall Kayf","state_id":"1739"},
    {"city_id":"21819","city_name":"al-Mawsil","state_id":"1739"},
    {"city_id":"21820","city_name":"ash-Shaykhan","state_id":"1739"},
    {"city_id":"21821","city_name":"Balad","state_id":"1740"},
    {"city_id":"21822","city_name":"Bayji","state_id":"1740"},
    {"city_id":"21823","city_name":"Dhalu'iyah","state_id":"1740"},
    {"city_id":"21824","city_name":"Samarra","state_id":"1740"},
    {"city_id":"21825","city_name":"Tikrit","state_id":"1740"},
    {"city_id":"21826","city_name":"Tuz","state_id":"1740"},
    {"city_id":"21827","city_name":"ad-Dujayl","state_id":"1740"},
    {"city_id":"21828","city_name":"al-'Aziziyah","state_id":"1741"},
    {"city_id":"21829","city_name":"al-Hayy","state_id":"1741"},
    {"city_id":"21830","city_name":"al-Kut","state_id":"1741"},
    {"city_id":"21831","city_name":"an-Nu'maniyah","state_id":"1741"},
    {"city_id":"21832","city_name":"as-Suwayrah","state_id":"1741"},
    {"city_id":"21833","city_name":"'Anah","state_id":"1742"},
    {"city_id":"21834","city_name":"Hit","state_id":"1742"},
    {"city_id":"21835","city_name":"Rawah","state_id":"1742"},
    {"city_id":"21836","city_name":"al-Fallujah","state_id":"1742"},
    {"city_id":"21837","city_name":"al-Habbaniyah","state_id":"1742"},
    {"city_id":"21838","city_name":"al-Hadithah","state_id":"1742"},
    {"city_id":"21839","city_name":"ar-Ramadi","state_id":"1742"},
    {"city_id":"21840","city_name":"ar-Rutbah","state_id":"1742"},
    {"city_id":"21841","city_name":"Abu al-Khasib","state_id":"1743"},
    {"city_id":"21842","city_name":"Harithah","state_id":"1743"},
    {"city_id":"21843","city_name":"Shatt-al-'Arab","state_id":"1743"},
    {"city_id":"21844","city_name":"al-Basrah","state_id":"1743"},
    {"city_id":"21845","city_name":"al-Faw","state_id":"1743"},
    {"city_id":"21846","city_name":"al-Qurnah","state_id":"1743"},
    {"city_id":"21847","city_name":"az-Zubayr","state_id":"1743"},
    {"city_id":"21848","city_name":"ar-Rumaythah","state_id":"1744"},
    {"city_id":"21849","city_name":"as-Samawah","state_id":"1744"},
    {"city_id":"21850","city_name":"'Afak","state_id":"1745"},
    {"city_id":"21851","city_name":"ad-Diwaniyah","state_id":"1745"},
    {"city_id":"21852","city_name":"al-Ghammas","state_id":"1745"},
    {"city_id":"21853","city_name":"al-Hamzah","state_id":"1745"},
    {"city_id":"21854","city_name":"ash-Shamiyah","state_id":"1745"},
    {"city_id":"21855","city_name":"ash-Shinafiyah","state_id":"1745"},
    {"city_id":"21856","city_name":"al-Kufah","state_id":"1746"},
    {"city_id":"21857","city_name":"al-Mishkhab","state_id":"1746"},
    {"city_id":"21858","city_name":"an-Najaf","state_id":"1746"},
    {"city_id":"21859","city_name":"Chamchamal","state_id":"1747"},
    {"city_id":"21860","city_name":"Halabjah","state_id":"1747"},
    {"city_id":"21861","city_name":"Kusanjaq","state_id":"1747"},
    {"city_id":"21862","city_name":"Panjwin","state_id":"1747"},
    {"city_id":"21863","city_name":"Qal'at Dizeh","state_id":"1747"},
    {"city_id":"21864","city_name":"as-Sulaymaniyah","state_id":"1747"},
    {"city_id":"21865","city_name":"'Aqrah","state_id":"1748"},
    {"city_id":"21866","city_name":"Kirkuk","state_id":"1748"},
    {"city_id":"21867","city_name":"Moira","state_id":"1749"},
    {"city_id":"21868","city_name":"Bagenalstown","state_id":"1750"},
    {"city_id":"21869","city_name":"Carlow","state_id":"1750"},
    {"city_id":"21870","city_name":"Tullow","state_id":"1750"},
    {"city_id":"21871","city_name":"Bailieborough","state_id":"1751"},
    {"city_id":"21872","city_name":"Belturbet","state_id":"1751"},
    {"city_id":"21873","city_name":"Cavan","state_id":"1751"},
    {"city_id":"21874","city_name":"Coothill","state_id":"1751"},
    {"city_id":"21875","city_name":"Ennis","state_id":"1752"},
    {"city_id":"21876","city_name":"Kilkee","state_id":"1752"},
    {"city_id":"21877","city_name":"Kilrush","state_id":"1752"},
    {"city_id":"21878","city_name":"Newmarket-on-Fergus","state_id":"1752"},
    {"city_id":"21879","city_name":"Shannon","state_id":"1752"},
    {"city_id":"21880","city_name":"Bandon","state_id":"1753"},
    {"city_id":"21881","city_name":"Bantry","state_id":"1753"},
    {"city_id":"21882","city_name":"Blarney","state_id":"1753"},
    {"city_id":"21883","city_name":"Carrigaline","state_id":"1753"},
    {"city_id":"21884","city_name":"Charleville","state_id":"1753"},
    {"city_id":"21885","city_name":"Clonakilty","state_id":"1753"},
    {"city_id":"21886","city_name":"Cobh","state_id":"1753"},
    {"city_id":"21887","city_name":"Cork","state_id":"1753"},
    {"city_id":"21888","city_name":"Drishane","state_id":"1753"},
    {"city_id":"21889","city_name":"Dunmanway","state_id":"1753"},
    {"city_id":"21890","city_name":"Fermoy","state_id":"1753"},
    {"city_id":"21891","city_name":"Kanturk","state_id":"1753"},
    {"city_id":"21892","city_name":"Kinsale","state_id":"1753"},
    {"city_id":"21893","city_name":"Macroom","state_id":"1753"},
    {"city_id":"21894","city_name":"Mallow","state_id":"1753"},
    {"city_id":"21895","city_name":"Midleton","state_id":"1753"},
    {"city_id":"21896","city_name":"Millstreet","state_id":"1753"},
    {"city_id":"21897","city_name":"Mitchelstown","state_id":"1753"},
    {"city_id":"21898","city_name":"Passage West","state_id":"1753"},
    {"city_id":"21899","city_name":"Skibbereen","state_id":"1753"},
    {"city_id":"21900","city_name":"Youghal","state_id":"1753"},
    {"city_id":"21901","city_name":"Ballybofey","state_id":"1754"},
    {"city_id":"21902","city_name":"Ballyshannon","state_id":"1754"},
    {"city_id":"21903","city_name":"Buncrana","state_id":"1754"},
    {"city_id":"21904","city_name":"Bundoran","state_id":"1754"},
    {"city_id":"21905","city_name":"Carndonagh","state_id":"1754"},
    {"city_id":"21906","city_name":"Donegal","state_id":"1754"},
    {"city_id":"21907","city_name":"Killybegs","state_id":"1754"},
    {"city_id":"21908","city_name":"Letterkenny","state_id":"1754"},
    {"city_id":"21909","city_name":"Lifford","state_id":"1754"},
    {"city_id":"21910","city_name":"Moville","state_id":"1754"},
    {"city_id":"21911","city_name":"Balbriggan","state_id":"1755"},
    {"city_id":"21912","city_name":"Ballsbridge","state_id":"1755"},
    {"city_id":"21913","city_name":"Dublin","state_id":"1755"},
    {"city_id":"21914","city_name":"Leixlip","state_id":"1755"},
    {"city_id":"21915","city_name":"Lucan","state_id":"1755"},
    {"city_id":"21916","city_name":"Malahide","state_id":"1755"},
    {"city_id":"21917","city_name":"Portrane","state_id":"1755"},
    {"city_id":"21918","city_name":"Rathcoole","state_id":"1755"},
    {"city_id":"21919","city_name":"Rush","state_id":"1755"},
    {"city_id":"21920","city_name":"Skerries","state_id":"1755"},
    {"city_id":"21921","city_name":"Swords","state_id":"1755"},
    {"city_id":"21922","city_name":"Athenry","state_id":"1756"},
    {"city_id":"21923","city_name":"Ballinasloe","state_id":"1756"},
    {"city_id":"21924","city_name":"Clifden","state_id":"1756"},
    {"city_id":"21925","city_name":"Galway","state_id":"1756"},
    {"city_id":"21926","city_name":"Gort","state_id":"1756"},
    {"city_id":"21927","city_name":"Loughrea","state_id":"1756"},
    {"city_id":"21928","city_name":"Tuam","state_id":"1756"},
    {"city_id":"21929","city_name":"Ballybunion","state_id":"1757"},
    {"city_id":"21930","city_name":"Cahirciveen","state_id":"1757"},
    {"city_id":"21931","city_name":"Castleisland","state_id":"1757"},
    {"city_id":"21932","city_name":"Dingle","state_id":"1757"},
    {"city_id":"21933","city_name":"Kenmare","state_id":"1757"},
    {"city_id":"21934","city_name":"Killarney","state_id":"1757"},
    {"city_id":"21935","city_name":"Killorglin","state_id":"1757"},
    {"city_id":"21936","city_name":"Listowel","state_id":"1757"},
    {"city_id":"21937","city_name":"Tralee","state_id":"1757"},
    {"city_id":"21938","city_name":"Athy","state_id":"1758"},
    {"city_id":"21939","city_name":"Celbridge","state_id":"1758"},
    {"city_id":"21940","city_name":"Clane","state_id":"1758"},
    {"city_id":"21941","city_name":"Kilcock","state_id":"1758"},
    {"city_id":"21942","city_name":"Kilcullen","state_id":"1758"},
    {"city_id":"21943","city_name":"Kildare","state_id":"1758"},
    {"city_id":"21944","city_name":"Maynooth","state_id":"1758"},
    {"city_id":"21945","city_name":"Monasterevan","state_id":"1758"},
    {"city_id":"21946","city_name":"Naas","state_id":"1758"},
    {"city_id":"21947","city_name":"Newbridge","state_id":"1758"},
    {"city_id":"21948","city_name":"Callan","state_id":"1759"},
    {"city_id":"21949","city_name":"Castlecomer","state_id":"1759"},
    {"city_id":"21950","city_name":"Kilkenny","state_id":"1759"},
    {"city_id":"21951","city_name":"Thomastown","state_id":"1759"},
    {"city_id":"21952","city_name":"Abbeyleix","state_id":"1760"},
    {"city_id":"21953","city_name":"Mountmellick","state_id":"1760"},
    {"city_id":"21954","city_name":"Mountrath","state_id":"1760"},
    {"city_id":"21955","city_name":"Port Laoise","state_id":"1760"},
    {"city_id":"21956","city_name":"Portarlington","state_id":"1760"},
    {"city_id":"21957","city_name":"Meath","state_id":"1761"},
    {"city_id":"21958","city_name":"Carrick-on-Shannon","state_id":"1762"},
    {"city_id":"21959","city_name":"Abbeyfeale","state_id":"1763"},
    {"city_id":"21960","city_name":"Kilmallock","state_id":"1763"},
    {"city_id":"21961","city_name":"Limerick","state_id":"1763"},
    {"city_id":"21962","city_name":"Newcastle","state_id":"1763"},
    {"city_id":"21963","city_name":"Rathkeale","state_id":"1763"},
    {"city_id":"21964","city_name":"Granard","state_id":"1765"},
    {"city_id":"21965","city_name":"Longford","state_id":"1765"},
    {"city_id":"21966","city_name":"Moate","state_id":"1765"},
    {"city_id":"21967","city_name":"Ardee","state_id":"1766"},
    {"city_id":"21968","city_name":"Drogheda","state_id":"1766"},
    {"city_id":"21969","city_name":"Drumcar","state_id":"1766"},
    {"city_id":"21970","city_name":"Dundalk","state_id":"1766"},
    {"city_id":"21971","city_name":"Ballina","state_id":"1767"},
    {"city_id":"21972","city_name":"Ballinrobe","state_id":"1767"},
    {"city_id":"21973","city_name":"Ballyhaunis","state_id":"1767"},
    {"city_id":"21974","city_name":"Castlebar","state_id":"1767"},
    {"city_id":"21975","city_name":"Claremorris","state_id":"1767"},
    {"city_id":"21976","city_name":"Swinford","state_id":"1767"},
    {"city_id":"21977","city_name":"Westport","state_id":"1767"},
    {"city_id":"21978","city_name":"Ashbourne","state_id":"1768"},
    {"city_id":"21979","city_name":"Duleek","state_id":"1768"},
    {"city_id":"21980","city_name":"Dunboyne","state_id":"1768"},
    {"city_id":"21981","city_name":"Dunshaughlin","state_id":"1768"},
    {"city_id":"21982","city_name":"Kells","state_id":"1768"},
    {"city_id":"21983","city_name":"Laytown","state_id":"1768"},
    {"city_id":"21984","city_name":"Navan","state_id":"1768"},
    {"city_id":"21985","city_name":"Trim","state_id":"1768"},
    {"city_id":"21986","city_name":"Carrickmacross","state_id":"1769"},
    {"city_id":"21987","city_name":"Castleblayney","state_id":"1769"},
    {"city_id":"21988","city_name":"Clones","state_id":"1769"},
    {"city_id":"21989","city_name":"Monaghan","state_id":"1769"},
    {"city_id":"21990","city_name":"Banagher","state_id":"1770"},
    {"city_id":"21991","city_name":"Birr","state_id":"1770"},
    {"city_id":"21992","city_name":"Clara","state_id":"1770"},
    {"city_id":"21993","city_name":"Edenderry","state_id":"1770"},
    {"city_id":"21994","city_name":"Kilcormac","state_id":"1770"},
    {"city_id":"21995","city_name":"Tullamore","state_id":"1770"},
    {"city_id":"21996","city_name":"Ballaghaderreen","state_id":"1771"},
    {"city_id":"21997","city_name":"Boyle","state_id":"1771"},
    {"city_id":"21998","city_name":"Castlerea","state_id":"1771"},
    {"city_id":"21999","city_name":"Roscommon","state_id":"1771"},
    {"city_id":"22000","city_name":"Sligo","state_id":"1772"},
    {"city_id":"22001","city_name":"Co Tyrone","state_id":"1775"},
    {"city_id":"22002","city_name":"Downpatrick","state_id":"1775"},
    {"city_id":"22003","city_name":"Dungarvan","state_id":"1776"},
    {"city_id":"22004","city_name":"Tramore","state_id":"1776"},
    {"city_id":"22005","city_name":"Waterford","state_id":"1776"},
    {"city_id":"22006","city_name":"Athlone","state_id":"1777"},
    {"city_id":"22007","city_name":"Mullingar","state_id":"1777"},
    {"city_id":"22008","city_name":"Enniscorthy","state_id":"1778"},
    {"city_id":"22009","city_name":"Gorey","state_id":"1778"},
    {"city_id":"22010","city_name":"New Ross","state_id":"1778"},
    {"city_id":"22011","city_name":"Wexford","state_id":"1778"},
    {"city_id":"22012","city_name":"Arklow","state_id":"1779"},
    {"city_id":"22013","city_name":"Baltinglass","state_id":"1779"},
    {"city_id":"22014","city_name":"Blessington","state_id":"1779"},
    {"city_id":"22015","city_name":"Bray","state_id":"1779"},
    {"city_id":"22016","city_name":"Greystones","state_id":"1779"},
    {"city_id":"22017","city_name":"Kilcoole","state_id":"1779"},
    {"city_id":"22018","city_name":"Newtownmountkennedy","state_id":"1779"},
    {"city_id":"22019","city_name":"Rathdrum","state_id":"1779"},
    {"city_id":"22020","city_name":"Wicklow","state_id":"1779"},
    {"city_id":"22021","city_name":"Bethlehem","state_id":"1782"},
    {"city_id":"22022","city_name":"Caesarea","state_id":"1783"},
    {"city_id":"22023","city_name":"Petach Tikva","state_id":"1784"},
    {"city_id":"22024","city_name":"Ramallah","state_id":"1784"},
    {"city_id":"22025","city_name":"Gaza","state_id":"1785"},
    {"city_id":"22026","city_name":"'Arad","state_id":"1786"},
    {"city_id":"22027","city_name":"'Omer","state_id":"1786"},
    {"city_id":"22028","city_name":"Ashdod","state_id":"1786"},
    {"city_id":"22029","city_name":"Ashqelon","state_id":"1786"},
    {"city_id":"22030","city_name":"Be'er Sheva","state_id":"1786"},
    {"city_id":"22031","city_name":"Beersheba","state_id":"1786"},
    {"city_id":"22032","city_name":"Bene Ayish","state_id":"1786"},
    {"city_id":"22033","city_name":"Dimona","state_id":"1786"},
    {"city_id":"22034","city_name":"Elat","state_id":"1786"},
    {"city_id":"22035","city_name":"Gan Yavne","state_id":"1786"},
    {"city_id":"22036","city_name":"Nahal `Oz","state_id":"1786"},
    {"city_id":"22037","city_name":"Netivot","state_id":"1786"},
    {"city_id":"22038","city_name":"Ofaqim","state_id":"1786"},
    {"city_id":"22039","city_name":"Qiryat Gat","state_id":"1786"},
    {"city_id":"22040","city_name":"Qiryat Mal'akhi","state_id":"1786"},
    {"city_id":"22041","city_name":"Sederot","state_id":"1786"},
    {"city_id":"22042","city_name":"Yeroham","state_id":"1786"},
    {"city_id":"22043","city_name":"kiryat Malachi","state_id":"1786"},
    {"city_id":"22044","city_name":"Be'er Ya'aqov","state_id":"1788"},
    {"city_id":"22045","city_name":"Beit Shemesh","state_id":"1788"},
    {"city_id":"22046","city_name":"Bene Beraq","state_id":"1788"},
    {"city_id":"22047","city_name":"Bnei Brak","state_id":"1788"},
    {"city_id":"22048","city_name":"Even Yehuda","state_id":"1788"},
    {"city_id":"22049","city_name":"Fureidis","state_id":"1788"},
    {"city_id":"22050","city_name":"Gat Rimon","state_id":"1788"},
    {"city_id":"22051","city_name":"Gedera","state_id":"1788"},
    {"city_id":"22052","city_name":"Givat Shmuel","state_id":"1788"},
    {"city_id":"22053","city_name":"Hibat Zion","state_id":"1788"},
    {"city_id":"22054","city_name":"Hod HaSharon","state_id":"1788"},
    {"city_id":"22055","city_name":"Hogar","state_id":"1788"},
    {"city_id":"22056","city_name":"Jaljulye","state_id":"1788"},
    {"city_id":"22057","city_name":"Jatt","state_id":"1788"},
    {"city_id":"22058","city_name":"Kafar Qasem","state_id":"1788"},
    {"city_id":"22059","city_name":"Kefar Sava","state_id":"1788"},
    {"city_id":"22060","city_name":"Kefar Yona","state_id":"1788"},
    {"city_id":"22061","city_name":"Kfar Saba","state_id":"1788"},
    {"city_id":"22062","city_name":"Kiryag Bialik","state_id":"1788"},
    {"city_id":"22063","city_name":"Lod","state_id":"1788"},
    {"city_id":"22064","city_name":"Mazkeret Batya","state_id":"1788"},
    {"city_id":"22065","city_name":"Modi'in","state_id":"1788"},
    {"city_id":"22066","city_name":"Nes Ziyyona","state_id":"1788"},
    {"city_id":"22067","city_name":"Ness Ziona","state_id":"1788"},
    {"city_id":"22068","city_name":"Netanya","state_id":"1788"},
    {"city_id":"22069","city_name":"Nordiya","state_id":"1788"},
    {"city_id":"22070","city_name":"Pardesiyya","state_id":"1788"},
    {"city_id":"22071","city_name":"Petakh Tiqwa","state_id":"1788"},
    {"city_id":"22072","city_name":"Qadima","state_id":"1788"},
    {"city_id":"22073","city_name":"Qalansawe","state_id":"1788"},
    {"city_id":"22074","city_name":"Qiryat 'Eqron","state_id":"1788"},
    {"city_id":"22075","city_name":"Ra'anana","state_id":"1788"},
    {"city_id":"22076","city_name":"Ramla","state_id":"1788"},
    {"city_id":"22077","city_name":"Rehovot","state_id":"1788"},
    {"city_id":"22078","city_name":"Rekhovot","state_id":"1788"},
    {"city_id":"22079","city_name":"Rinnatya","state_id":"1788"},
    {"city_id":"22080","city_name":"Rishon LeZiyyon","state_id":"1788"},
    {"city_id":"22081","city_name":"Rosh HaAyin","state_id":"1788"},
    {"city_id":"22082","city_name":"Shoham","state_id":"1788"},
    {"city_id":"22083","city_name":"Tayibe","state_id":"1788"},
    {"city_id":"22084","city_name":"Tire","state_id":"1788"},
    {"city_id":"22085","city_name":"Tsur Igal","state_id":"1788"},
    {"city_id":"22086","city_name":"Udim","state_id":"1788"},
    {"city_id":"22087","city_name":"Yavne","state_id":"1788"},
    {"city_id":"22088","city_name":"Yehud","state_id":"1788"},
    {"city_id":"22089","city_name":"'Afula","state_id":"1789"},
    {"city_id":"22090","city_name":"'Akko","state_id":"1789"},
    {"city_id":"22091","city_name":"'Arrabe","state_id":"1789"},
    {"city_id":"22092","city_name":"'Ein Mahel","state_id":"1789"},
    {"city_id":"22093","city_name":"'Ilut","state_id":"1789"},
    {"city_id":"22094","city_name":"Abu Sinan","state_id":"1789"},
    {"city_id":"22095","city_name":"Basmat Tab'un","state_id":"1789"},
    {"city_id":"22096","city_name":"Beit Jann","state_id":"1789"},
    {"city_id":"22097","city_name":"Bet She'an","state_id":"1789"},
    {"city_id":"22098","city_name":"Bi'ne","state_id":"1789"},
    {"city_id":"22099","city_name":"Bir-al-Maksur","state_id":"1789"},
    {"city_id":"22100","city_name":"Bu'eine-Nujeidat","state_id":"1789"},
    {"city_id":"22101","city_name":"Dabburye","state_id":"1789"},
    {"city_id":"22102","city_name":"Dayr Hannah","state_id":"1789"},
    {"city_id":"22103","city_name":"Dayr-al-Asad","state_id":"1789"},
    {"city_id":"22104","city_name":"Hazor HaGelilit","state_id":"1789"},
    {"city_id":"22105","city_name":"I'billin","state_id":"1789"},
    {"city_id":"22106","city_name":"Iksal","state_id":"1789"},
    {"city_id":"22107","city_name":"Judeide-Maker","state_id":"1789"},
    {"city_id":"22108","city_name":"Kabul","state_id":"1789"},
    {"city_id":"22109","city_name":"Kafar Kanna","state_id":"1789"},
    {"city_id":"22110","city_name":"Kafar Manda","state_id":"1789"},
    {"city_id":"22111","city_name":"Kafar Yasif","state_id":"1789"},
    {"city_id":"22112","city_name":"Karmiel","state_id":"1789"},
    {"city_id":"22113","city_name":"Kisra-Sumei","state_id":"1789"},
    {"city_id":"22114","city_name":"Ma'alot Tarshikha","state_id":"1789"},
    {"city_id":"22115","city_name":"Majd-al-Kurum","state_id":"1789"},
    {"city_id":"22116","city_name":"Migdal Ha'Emeq","state_id":"1789"},
    {"city_id":"22117","city_name":"Mugar","state_id":"1789"},
    {"city_id":"22118","city_name":"Nahariyya","state_id":"1789"},
    {"city_id":"22119","city_name":"Nahef","state_id":"1789"},
    {"city_id":"22120","city_name":"Nazerat","state_id":"1789"},
    {"city_id":"22121","city_name":"Nazerat 'Illit","state_id":"1789"},
    {"city_id":"22122","city_name":"Qiryat Shemona","state_id":"1789"},
    {"city_id":"22123","city_name":"Qiryat Tiv'on","state_id":"1789"},
    {"city_id":"22124","city_name":"Rame","state_id":"1789"},
    {"city_id":"22125","city_name":"Reine","state_id":"1789"},
    {"city_id":"22126","city_name":"Sakhnin","state_id":"1789"},
    {"city_id":"22127","city_name":"Shefar'am","state_id":"1789"},
    {"city_id":"22128","city_name":"Tamra","state_id":"1789"},
    {"city_id":"22129","city_name":"Tiberias","state_id":"1789"},
    {"city_id":"22130","city_name":"Tur'an","state_id":"1789"},
    {"city_id":"22131","city_name":"Yirka","state_id":"1789"},
    {"city_id":"22132","city_name":"Yoqne'am Illit","state_id":"1789"},
    {"city_id":"22133","city_name":"Zefat","state_id":"1789"},
    {"city_id":"22134","city_name":"Zur Yigal","state_id":"1789"},
    {"city_id":"22135","city_name":"Hebron","state_id":"1790"},
    {"city_id":"22136","city_name":"Sgula","state_id":"1791"},
    {"city_id":"22137","city_name":"Jerusalem","state_id":"1792"},
    {"city_id":"22138","city_name":"'Ar'ara","state_id":"1793"},
    {"city_id":"22139","city_name":"'Isifya","state_id":"1793"},
    {"city_id":"22140","city_name":"Baqa al-Gharbiyyah","state_id":"1793"},
    {"city_id":"22141","city_name":"Binyamina","state_id":"1793"},
    {"city_id":"22142","city_name":"Daliyat-al-Karmil","state_id":"1793"},
    {"city_id":"22143","city_name":"Jizr-az-Zarqa","state_id":"1793"},
    {"city_id":"22144","city_name":"Khadera","state_id":"1793"},
    {"city_id":"22145","city_name":"Khefa","state_id":"1793"},
    {"city_id":"22146","city_name":"Nesher","state_id":"1793"},
    {"city_id":"22147","city_name":"Or 'Aqiva","state_id":"1793"},
    {"city_id":"22148","city_name":"Pardes Khanna-Karkur","state_id":"1793"},
    {"city_id":"22149","city_name":"Qiryat Atta","state_id":"1793"},
    {"city_id":"22150","city_name":"Qiryat Bialik","state_id":"1793"},
    {"city_id":"22151","city_name":"Qiryat Motzkin","state_id":"1793"},
    {"city_id":"22152","city_name":"Qiryat Yam","state_id":"1793"},
    {"city_id":"22153","city_name":"Rekhasim","state_id":"1793"},
    {"city_id":"22154","city_name":"Tirat Karmel","state_id":"1793"},
    {"city_id":"22155","city_name":"Umm-al-Fahm","state_id":"1793"},
    {"city_id":"22156","city_name":"Zikhron Ya'aqov","state_id":"1793"},
    {"city_id":"22157","city_name":"Qalqilya","state_id":"1796"},
    {"city_id":"22158","city_name":"Hadera","state_id":"1799"},
    {"city_id":"22159","city_name":"Kiryag Bialik","state_id":"1799"},
    {"city_id":"22160","city_name":"Bet Shemesh","state_id":"1801"},
    {"city_id":"22161","city_name":"Mevasserat Ziyyon","state_id":"1801"},
    {"city_id":"22162","city_name":"Yerushalayim","state_id":"1801"},
    {"city_id":"22163","city_name":"Meta","state_id":"1802"},
    {"city_id":"22164","city_name":"Miano","state_id":"1802"},
    {"city_id":"22165","city_name":"Alba Adriatica","state_id":"1803"},
    {"city_id":"22166","city_name":"Atessa","state_id":"1803"},
    {"city_id":"22167","city_name":"Atri","state_id":"1803"},
    {"city_id":"22168","city_name":"Avezzano","state_id":"1803"},
    {"city_id":"22169","city_name":"Celano","state_id":"1803"},
    {"city_id":"22170","city_name":"Cepagatti","state_id":"1803"},
    {"city_id":"22171","city_name":"Chieti","state_id":"1803"},
    {"city_id":"22172","city_name":"Citta Sant'Angelo","state_id":"1803"},
    {"city_id":"22173","city_name":"Francavilla al Mare","state_id":"1803"},
    {"city_id":"22174","city_name":"Giulianova","state_id":"1803"},
    {"city_id":"22175","city_name":"Guardiagrele","state_id":"1803"},
    {"city_id":"22176","city_name":"L'Aquila","state_id":"1803"},
    {"city_id":"22177","city_name":"Lanciano","state_id":"1803"},
    {"city_id":"22178","city_name":"Martinsicuro","state_id":"1803"},
    {"city_id":"22179","city_name":"Montesilvano","state_id":"1803"},
    {"city_id":"22180","city_name":"Montorio al Vomano","state_id":"1803"},
    {"city_id":"22181","city_name":"Mosciano Sant'Angelo","state_id":"1803"},
    {"city_id":"22182","city_name":"Ortona","state_id":"1803"},
    {"city_id":"22183","city_name":"Penne","state_id":"1803"},
    {"city_id":"22184","city_name":"Pescara","state_id":"1803"},
    {"city_id":"22185","city_name":"Pineto","state_id":"1803"},
    {"city_id":"22186","city_name":"Roseto degli Abruzzi","state_id":"1803"},
    {"city_id":"22187","city_name":"San Giovanni Teatino","state_id":"1803"},
    {"city_id":"22188","city_name":"San Salvo","state_id":"1803"},
    {"city_id":"22189","city_name":"Sant'Egidio alla Vibrata","state_id":"1803"},
    {"city_id":"22190","city_name":"Silvi","state_id":"1803"},
    {"city_id":"22191","city_name":"Spoltore","state_id":"1803"},
    {"city_id":"22192","city_name":"Sulmona","state_id":"1803"},
    {"city_id":"22193","city_name":"Teramo","state_id":"1803"},
    {"city_id":"22194","city_name":"Vasto","state_id":"1803"},
    {"city_id":"22195","city_name":"Agrigento","state_id":"1804"},
    {"city_id":"22196","city_name":"Alessandria della Rocca","state_id":"1804"},
    {"city_id":"22197","city_name":"Aragona","state_id":"1804"},
    {"city_id":"22198","city_name":"Bivona","state_id":"1804"},
    {"city_id":"22199","city_name":"Burgio","state_id":"1804"},
    {"city_id":"22200","city_name":"Calamonaci","state_id":"1804"},
    {"city_id":"22201","city_name":"Caltabellotta","state_id":"1804"},
    {"city_id":"22202","city_name":"Camastra","state_id":"1804"},
    {"city_id":"22203","city_name":"Cammarata","state_id":"1804"},
    {"city_id":"22204","city_name":"Campobello di Licata","state_id":"1804"},
    {"city_id":"22205","city_name":"CanicattÃƒÂ¬","state_id":"1804"},
    {"city_id":"22206","city_name":"Casteltermini","state_id":"1804"},
    {"city_id":"22207","city_name":"Castrofilippo","state_id":"1804"},
    {"city_id":"22208","city_name":"Cattolica Eraclea","state_id":"1804"},
    {"city_id":"22209","city_name":"Cianciana","state_id":"1804"},
    {"city_id":"22210","city_name":"Comitini","state_id":"1804"},
    {"city_id":"22211","city_name":"Favara","state_id":"1804"},
    {"city_id":"22212","city_name":"Grotte","state_id":"1804"},
    {"city_id":"22213","city_name":"Joppolo Giancaxio","state_id":"1804"},
    {"city_id":"22214","city_name":"Lampedusa e Linosa","state_id":"1804"},
    {"city_id":"22215","city_name":"Licata","state_id":"1804"},
    {"city_id":"22216","city_name":"Lucca Sicula","state_id":"1804"},
    {"city_id":"22217","city_name":"Menfi","state_id":"1804"},
    {"city_id":"22218","city_name":"Montallegro","state_id":"1804"},
    {"city_id":"22219","city_name":"Montevago","state_id":"1804"},
    {"city_id":"22220","city_name":"Naro","state_id":"1804"},
    {"city_id":"22221","city_name":"Palma di Montechiaro","state_id":"1804"},
    {"city_id":"22222","city_name":"Porto Empedocle","state_id":"1804"},
    {"city_id":"22223","city_name":"Racalmuto","state_id":"1804"},
    {"city_id":"22224","city_name":"Raffadali","state_id":"1804"},
    {"city_id":"22225","city_name":"Ravanusa","state_id":"1804"},
    {"city_id":"22226","city_name":"Realmonte","state_id":"1804"},
    {"city_id":"22227","city_name":"Ribera","state_id":"1804"},
    {"city_id":"22228","city_name":"Sambuca di Sicilia","state_id":"1804"},
    {"city_id":"22229","city_name":"San Biagio Platani","state_id":"1804"},
    {"city_id":"22230","city_name":"San Giovanni Gemini","state_id":"1804"},
    {"city_id":"22231","city_name":"Sant Angelo Muxaro","state_id":"1804"},
    {"city_id":"22232","city_name":"Santa Elisabetta","state_id":"1804"},
    {"city_id":"22233","city_name":"Santa Margherita di Belice","state_id":"1804"},
    {"city_id":"22234","city_name":"Santo Stefano Quisquina","state_id":"1804"},
    {"city_id":"22235","city_name":"Sciacca","state_id":"1804"},
    {"city_id":"22236","city_name":"Siculiana","state_id":"1804"},
    {"city_id":"22237","city_name":"Villafranca Sicula","state_id":"1804"},
    {"city_id":"22238","city_name":"Castellazzo Bormida","state_id":"1805"},
    {"city_id":"22239","city_name":"Gavi","state_id":"1805"},
    {"city_id":"22240","city_name":"Villanova Monferrato","state_id":"1805"},
    {"city_id":"22241","city_name":"Camerano","state_id":"1806"},
    {"city_id":"22242","city_name":"Castelplanio","state_id":"1806"},
    {"city_id":"22243","city_name":"Capolona","state_id":"1807"},
    {"city_id":"22244","city_name":"Montevarchi","state_id":"1807"},
    {"city_id":"22245","city_name":"Subbiano","state_id":"1807"},
    {"city_id":"22246","city_name":"Buttigliera d'Asti","state_id":"1809"},
    {"city_id":"22247","city_name":"Flumeri","state_id":"1810"},
    {"city_id":"22248","city_name":"Nusco","state_id":"1810"},
    {"city_id":"22249","city_name":"Prata di Principato Ultra","state_id":"1810"},
    {"city_id":"22250","city_name":"Villanova del Battista","state_id":"1810"},
    {"city_id":"22251","city_name":"Avigliano","state_id":"1812"},
    {"city_id":"22252","city_name":"Bernalda","state_id":"1812"},
    {"city_id":"22253","city_name":"Ferrandina","state_id":"1812"},
    {"city_id":"22254","city_name":"Lauria","state_id":"1812"},
    {"city_id":"22255","city_name":"Lavello","state_id":"1812"},
    {"city_id":"22256","city_name":"Matera","state_id":"1812"},
    {"city_id":"22257","city_name":"Melfi","state_id":"1812"},
    {"city_id":"22258","city_name":"Montescaglioso","state_id":"1812"},
    {"city_id":"22259","city_name":"Pisticci","state_id":"1812"},
    {"city_id":"22260","city_name":"Policoro","state_id":"1812"},
    {"city_id":"22261","city_name":"Potenza","state_id":"1812"},
    {"city_id":"22262","city_name":"Rionero in Vulture","state_id":"1812"},
    {"city_id":"22263","city_name":"Venosa","state_id":"1812"},
    {"city_id":"22264","city_name":"Belluno","state_id":"1813"},
    {"city_id":"22265","city_name":"D'alpago","state_id":"1813"},
    {"city_id":"22266","city_name":"Longarone","state_id":"1813"},
    {"city_id":"22267","city_name":"Pedavena","state_id":"1813"},
    {"city_id":"22268","city_name":"San Bartolomeo","state_id":"1814"},
    {"city_id":"22269","city_name":"","state_id":"1815"},
    {"city_id":"22270","city_name":"Bagnatica","state_id":"1815"},
    {"city_id":"22271","city_name":"Bergamo","state_id":"1815"},
    {"city_id":"22272","city_name":"Bolgare","state_id":"1815"},
    {"city_id":"22273","city_name":"Bottanuco","state_id":"1815"},
    {"city_id":"22274","city_name":"Brignano Gera d'Adda","state_id":"1815"},
    {"city_id":"22275","city_name":"Calcio","state_id":"1815"},
    {"city_id":"22276","city_name":"Caravaggio","state_id":"1815"},
    {"city_id":"22277","city_name":"Chiuduno","state_id":"1815"},
    {"city_id":"22278","city_name":"Ciserano","state_id":"1815"},
    {"city_id":"22279","city_name":"Comun Nuovo","state_id":"1815"},
    {"city_id":"22280","city_name":"Costa di Mezzate","state_id":"1815"},
    {"city_id":"22281","city_name":"Gandino","state_id":"1815"},
    {"city_id":"22282","city_name":"Grassobbio","state_id":"1815"},
    {"city_id":"22283","city_name":"Grumello Del Monte","state_id":"1815"},
    {"city_id":"22284","city_name":"Grumello del Monte","state_id":"1815"},
    {"city_id":"22285","city_name":"Lallio","state_id":"1815"},
    {"city_id":"22286","city_name":"Levate","state_id":"1815"},
    {"city_id":"22287","city_name":"Lurano","state_id":"1815"},
    {"city_id":"22288","city_name":"Mapello","state_id":"1815"},
    {"city_id":"22289","city_name":"Pagazzano","state_id":"1815"},
    {"city_id":"22290","city_name":"Ponteranica","state_id":"1815"},
    {"city_id":"22291","city_name":"Pontida","state_id":"1815"},
    {"city_id":"22292","city_name":"Sant Omobono Imagna","state_id":"1815"},
    {"city_id":"22293","city_name":"Torre Pallavicina","state_id":"1815"},
    {"city_id":"22294","city_name":"Trescore Balneario","state_id":"1815"},
    {"city_id":"22295","city_name":"Verdellino","state_id":"1815"},
    {"city_id":"22296","city_name":"Zingonia","state_id":"1815"},
    {"city_id":"22297","city_name":"Camburzano","state_id":"1816"},
    {"city_id":"22298","city_name":"Crevacuore","state_id":"1816"},
    {"city_id":"22299","city_name":"Gaglianico","state_id":"1816"},
    {"city_id":"22300","city_name":"Sandigliano","state_id":"1816"},
    {"city_id":"22301","city_name":"Vigliano Biellese","state_id":"1816"},
    {"city_id":"22302","city_name":"Anzola dell'Emilia","state_id":"1817"},
    {"city_id":"22303","city_name":"Bologna","state_id":"1817"},
    {"city_id":"22304","city_name":"Borgo Tossignano","state_id":"1817"},
    {"city_id":"22305","city_name":"Casalfiumanese","state_id":"1817"},
    {"city_id":"22306","city_name":"Castiglione Dei Pepoli ","state_id":"1817"},
    {"city_id":"22307","city_name":"Funo","state_id":"1817"},
    {"city_id":"22308","city_name":"Loiano","state_id":"1817"},
    {"city_id":"22309","city_name":"Monterenzio","state_id":"1817"},
    {"city_id":"22310","city_name":"Osteria Grande","state_id":"1817"},
    {"city_id":"22311","city_name":"Frangarto","state_id":"1818"},
    {"city_id":"22312","city_name":"Agnosine","state_id":"1819"},
    {"city_id":"22313","city_name":"Brescia","state_id":"1819"},
    {"city_id":"22314","city_name":"Capriano del Colle","state_id":"1819"},
    {"city_id":"22315","city_name":"Capriolo","state_id":"1819"},
    {"city_id":"22316","city_name":"Castegnato","state_id":"1819"},
    {"city_id":"22317","city_name":"Castelcovati","state_id":"1819"},
    {"city_id":"22318","city_name":"Cellatica","state_id":"1819"},
    {"city_id":"22319","city_name":"Coccaglio","state_id":"1819"},
    {"city_id":"22320","city_name":"Comezzano-Cizzago","state_id":"1819"},
    {"city_id":"22321","city_name":"Erbusco","state_id":"1819"},
    {"city_id":"22322","city_name":"Flero","state_id":"1819"},
    {"city_id":"22323","city_name":"Lavenone","state_id":"1819"},
    {"city_id":"22324","city_name":"Longhena","state_id":"1819"},
    {"city_id":"22325","city_name":"Maclodio","state_id":"1819"},
    {"city_id":"22326","city_name":"Muscoline","state_id":"1819"},
    {"city_id":"22327","city_name":"Padenghe sul Garda","state_id":"1819"},
    {"city_id":"22328","city_name":"Paderno Franciacorta","state_id":"1819"},
    {"city_id":"22329","city_name":"Paratico","state_id":"1819"},
    {"city_id":"22330","city_name":"Passirano","state_id":"1819"},
    {"city_id":"22331","city_name":"Polaveno","state_id":"1819"},
    {"city_id":"22332","city_name":"Poncarale","state_id":"1819"},
    {"city_id":"22333","city_name":"Prevalle","state_id":"1819"},
    {"city_id":"22334","city_name":"Provaglio dIseo","state_id":"1819"},
    {"city_id":"22335","city_name":"Roncadelle","state_id":"1819"},
    {"city_id":"22336","city_name":"Verolavecchia","state_id":"1819"},
    {"city_id":"22337","city_name":"Visano","state_id":"1819"},
    {"city_id":"22338","city_name":"San Donaci","state_id":"1820"},
    {"city_id":"22339","city_name":"Acri","state_id":"1821"},
    {"city_id":"22340","city_name":"Amantea","state_id":"1821"},
    {"city_id":"22341","city_name":"Bagnara Calabra","state_id":"1821"},
    {"city_id":"22342","city_name":"Belvedere Marittimo","state_id":"1821"},
    {"city_id":"22343","city_name":"Bisignano","state_id":"1821"},
    {"city_id":"22344","city_name":"Bovalino","state_id":"1821"},
    {"city_id":"22345","city_name":"Cariati","state_id":"1821"},
    {"city_id":"22346","city_name":"Cassano allo Ionio","state_id":"1821"},
    {"city_id":"22347","city_name":"Castrolibero","state_id":"1821"},
    {"city_id":"22348","city_name":"Castrovillari","state_id":"1821"},
    {"city_id":"22349","city_name":"Catanzaro","state_id":"1821"},
    {"city_id":"22350","city_name":"Cetraro","state_id":"1821"},
    {"city_id":"22351","city_name":"Ciro Marina","state_id":"1821"},
    {"city_id":"22352","city_name":"Cittanova","state_id":"1821"},
    {"city_id":"22353","city_name":"Corigliano Calabro","state_id":"1821"},
    {"city_id":"22354","city_name":"Cosenza","state_id":"1821"},
    {"city_id":"22355","city_name":"Crosia","state_id":"1821"},
    {"city_id":"22356","city_name":"Crotone","state_id":"1821"},
    {"city_id":"22357","city_name":"Cutro","state_id":"1821"},
    {"city_id":"22358","city_name":"Fuscaldo","state_id":"1821"},
    {"city_id":"22359","city_name":"Gioia Tauro","state_id":"1821"},
    {"city_id":"22360","city_name":"Isola di Capo Rizzuto","state_id":"1821"},
    {"city_id":"22361","city_name":"Lamezia Terme","state_id":"1821"},
    {"city_id":"22362","city_name":"Locri","state_id":"1821"},
    {"city_id":"22363","city_name":"Luzzi","state_id":"1821"},
    {"city_id":"22364","city_name":"Melito di Porto Salvo","state_id":"1821"},
    {"city_id":"22365","city_name":"Mendicino","state_id":"1821"},
    {"city_id":"22366","city_name":"Montalto Uffugo","state_id":"1821"},
    {"city_id":"22367","city_name":"Palmi","state_id":"1821"},
    {"city_id":"22368","city_name":"Paola","state_id":"1821"},
    {"city_id":"22369","city_name":"Petilia Policastro","state_id":"1821"},
    {"city_id":"22370","city_name":"Pizzo","state_id":"1821"},
    {"city_id":"22371","city_name":"Polistena","state_id":"1821"},
    {"city_id":"22372","city_name":"Reggio di Calabria","state_id":"1821"},
    {"city_id":"22373","city_name":"Rende","state_id":"1821"},
    {"city_id":"22374","city_name":"Rosarno","state_id":"1821"},
    {"city_id":"22375","city_name":"Rossano","state_id":"1821"},
    {"city_id":"22376","city_name":"San Giovanni in Fiore","state_id":"1821"},
    {"city_id":"22377","city_name":"Scalea","state_id":"1821"},
    {"city_id":"22378","city_name":"Sellia Marina","state_id":"1821"},
    {"city_id":"22379","city_name":"Siderno","state_id":"1821"},
    {"city_id":"22380","city_name":"Soverato","state_id":"1821"},
    {"city_id":"22381","city_name":"Taurianova","state_id":"1821"},
    {"city_id":"22382","city_name":"Trebisacce","state_id":"1821"},
    {"city_id":"22383","city_name":"Vibo Valentia","state_id":"1821"},
    {"city_id":"22384","city_name":"Villa San Giovanni","state_id":"1821"},
    {"city_id":"22385","city_name":"Acerra","state_id":"1822"},
    {"city_id":"22386","city_name":"Afragola","state_id":"1822"},
    {"city_id":"22387","city_name":"Agropoli","state_id":"1822"},
    {"city_id":"22388","city_name":"Angri","state_id":"1822"},
    {"city_id":"22389","city_name":"Ariano Irpino","state_id":"1822"},
    {"city_id":"22390","city_name":"Arzano","state_id":"1822"},
    {"city_id":"22391","city_name":"Atripalda","state_id":"1822"},
    {"city_id":"22392","city_name":"Avellino","state_id":"1822"},
    {"city_id":"22393","city_name":"Aversa","state_id":"1822"},
    {"city_id":"22394","city_name":"Bacoli","state_id":"1822"},
    {"city_id":"22395","city_name":"Barano d'Ischia","state_id":"1822"},
    {"city_id":"22396","city_name":"Baronissi","state_id":"1822"},
    {"city_id":"22397","city_name":"Battipaglia","state_id":"1822"},
    {"city_id":"22398","city_name":"Bellizzi","state_id":"1822"},
    {"city_id":"22399","city_name":"Benevento","state_id":"1822"},
    {"city_id":"22400","city_name":"Boscoreale","state_id":"1822"},
    {"city_id":"22401","city_name":"Boscotrecase","state_id":"1822"},
    {"city_id":"22402","city_name":"Brusciano","state_id":"1822"},
    {"city_id":"22403","city_name":"Caivano","state_id":"1822"},
    {"city_id":"22404","city_name":"Calvizzano","state_id":"1822"},
    {"city_id":"22405","city_name":"Campagna","state_id":"1822"},
    {"city_id":"22406","city_name":"Capaccio","state_id":"1822"},
    {"city_id":"22407","city_name":"Capua","state_id":"1822"},
    {"city_id":"22408","city_name":"Cardito","state_id":"1822"},
    {"city_id":"22409","city_name":"Carinola","state_id":"1822"},
    {"city_id":"22410","city_name":"Casagiove","state_id":"1822"},
    {"city_id":"22411","city_name":"Casal di Principe","state_id":"1822"},
    {"city_id":"22412","city_name":"Casalnuovo di Napoli","state_id":"1822"},
    {"city_id":"22413","city_name":"Casaluce","state_id":"1822"},
    {"city_id":"22414","city_name":"Casandrino","state_id":"1822"},
    {"city_id":"22415","city_name":"Casavatore","state_id":"1822"},
    {"city_id":"22416","city_name":"Caserta","state_id":"1822"},
    {"city_id":"22417","city_name":"Casoria","state_id":"1822"},
    {"city_id":"22418","city_name":"Castel San Giorgio","state_id":"1822"},
    {"city_id":"22419","city_name":"Castel Volturno","state_id":"1822"},
    {"city_id":"22420","city_name":"Castellammare di Stabia","state_id":"1822"},
    {"city_id":"22421","city_name":"Cava de' Tirreni","state_id":"1822"},
    {"city_id":"22422","city_name":"Cercola","state_id":"1822"},
    {"city_id":"22423","city_name":"Cervinara","state_id":"1822"},
    {"city_id":"22424","city_name":"Cicciano","state_id":"1822"},
    {"city_id":"22425","city_name":"Crispano","state_id":"1822"},
    {"city_id":"22426","city_name":"Eboli","state_id":"1822"},
    {"city_id":"22427","city_name":"Ercolano","state_id":"1822"},
    {"city_id":"22428","city_name":"Fisciano","state_id":"1822"},
    {"city_id":"22429","city_name":"Forio","state_id":"1822"},
    {"city_id":"22430","city_name":"Frattamaggiore","state_id":"1822"},
    {"city_id":"22431","city_name":"Frattaminore","state_id":"1822"},
    {"city_id":"22432","city_name":"Frignano","state_id":"1822"},
    {"city_id":"22433","city_name":"Giffoni Valle Piana","state_id":"1822"},
    {"city_id":"22434","city_name":"Giugliano in Campania","state_id":"1822"},
    {"city_id":"22435","city_name":"Gragnano","state_id":"1822"},
    {"city_id":"22436","city_name":"Gricignano di Aversa","state_id":"1822"},
    {"city_id":"22437","city_name":"Grottaminarda","state_id":"1822"},
    {"city_id":"22438","city_name":"Grumo Nevano","state_id":"1822"},
    {"city_id":"22439","city_name":"Ischia","state_id":"1822"},
    {"city_id":"22440","city_name":"Lusciano","state_id":"1822"},
    {"city_id":"22441","city_name":"Macerata Campania","state_id":"1822"},
    {"city_id":"22442","city_name":"Maddaloni","state_id":"1822"},
    {"city_id":"22443","city_name":"Marano di Napoli","state_id":"1822"},
    {"city_id":"22444","city_name":"Marcianise","state_id":"1822"},
    {"city_id":"22445","city_name":"Marigliano","state_id":"1822"},
    {"city_id":"22446","city_name":"Massa Lubrense","state_id":"1822"},
    {"city_id":"22447","city_name":"Melito di Napoli","state_id":"1822"},
    {"city_id":"22448","city_name":"Mercato San Severino","state_id":"1822"},
    {"city_id":"22449","city_name":"Mercogliano","state_id":"1822"},
    {"city_id":"22450","city_name":"Meta","state_id":"1822"},
    {"city_id":"22451","city_name":"Mirabella Eclano","state_id":"1822"},
    {"city_id":"22452","city_name":"Mondragone","state_id":"1822"},
    {"city_id":"22453","city_name":"Monte di Procida","state_id":"1822"},
    {"city_id":"22454","city_name":"Montecorvino Rovella","state_id":"1822"},
    {"city_id":"22455","city_name":"Monteforte Irpino","state_id":"1822"},
    {"city_id":"22456","city_name":"Montesarchio","state_id":"1822"},
    {"city_id":"22457","city_name":"Montoro Inferiore","state_id":"1822"},
    {"city_id":"22458","city_name":"Mugnano di Napoli","state_id":"1822"},
    {"city_id":"22459","city_name":"Naples","state_id":"1822"},
    {"city_id":"22460","city_name":"Napoli","state_id":"1822"},
    {"city_id":"22461","city_name":"Nocera Inferiore","state_id":"1822"},
    {"city_id":"22462","city_name":"Nocera Superiore","state_id":"1822"},
    {"city_id":"22463","city_name":"Nola","state_id":"1822"},
    {"city_id":"22464","city_name":"Orta di Atella","state_id":"1822"},
    {"city_id":"22465","city_name":"Ottaviano","state_id":"1822"},
    {"city_id":"22466","city_name":"Pagani","state_id":"1822"},
    {"city_id":"22467","city_name":"Palma Campania","state_id":"1822"},
    {"city_id":"22468","city_name":"Parete","state_id":"1822"},
    {"city_id":"22469","city_name":"Pellezzano","state_id":"1822"},
    {"city_id":"22470","city_name":"Piano di Sorrento","state_id":"1822"},
    {"city_id":"22471","city_name":"Piedimonte Matese","state_id":"1822"},
    {"city_id":"22472","city_name":"Poggiomarino","state_id":"1822"},
    {"city_id":"22473","city_name":"Pollena Trocchia","state_id":"1822"},
    {"city_id":"22474","city_name":"Pomigliano d'Arco","state_id":"1822"},
    {"city_id":"22475","city_name":"Pompei","state_id":"1822"},
    {"city_id":"22476","city_name":"Pontecagnano","state_id":"1822"},
    {"city_id":"22477","city_name":"Portici","state_id":"1822"},
    {"city_id":"22478","city_name":"Positano","state_id":"1822"},
    {"city_id":"22479","city_name":"Pozzuoli","state_id":"1822"},
    {"city_id":"22480","city_name":"Procida","state_id":"1822"},
    {"city_id":"22481","city_name":"Qualiano","state_id":"1822"},
    {"city_id":"22482","city_name":"Quarto","state_id":"1822"},
    {"city_id":"22483","city_name":"Roccapiemonte","state_id":"1822"},
    {"city_id":"22484","city_name":"Sala Consilina","state_id":"1822"},
    {"city_id":"22485","city_name":"Salerno","state_id":"1822"},
    {"city_id":"22486","city_name":"San Cipriano d'Aversa","state_id":"1822"},
    {"city_id":"22487","city_name":"San Felice a Cancello","state_id":"1822"},
    {"city_id":"22488","city_name":"San Gennaro Vesuviano","state_id":"1822"},
    {"city_id":"22489","city_name":"San Giorgio a Cremano","state_id":"1822"},
    {"city_id":"22490","city_name":"San Giorgio del Sannio","state_id":"1822"},
    {"city_id":"22491","city_name":"San Giuseppe Vesuviano","state_id":"1822"},
    {"city_id":"22492","city_name":"San Marcellino","state_id":"1822"},
    {"city_id":"22493","city_name":"San Marzano sul Sarno","state_id":"1822"},
    {"city_id":"22494","city_name":"San Nicola","state_id":"1822"},
    {"city_id":"22495","city_name":"San Prisco","state_id":"1822"},
    {"city_id":"22496","city_name":"San Sebastiano al Vesuvio","state_id":"1822"},
    {"city_id":"22497","city_name":"San Valentino Torio","state_id":"1822"},
    {"city_id":"22498","city_name":"Sant Antimo","state_id":"1822"},
    {"city_id":"22499","city_name":"Sant'Agata de' Goti","state_id":"1822"},
    {"city_id":"22500","city_name":"Sant'Agnello","state_id":"1822"},
    {"city_id":"22501","city_name":"Sant'Anastasia","state_id":"1822"},
    {"city_id":"22502","city_name":"Sant'Antonio Abate","state_id":"1822"},
    {"city_id":"22503","city_name":"Sant'Arpino","state_id":"1822"},
    {"city_id":"22504","city_name":"Sant'Egidio del Monte Albino","state_id":"1822"},
    {"city_id":"22505","city_name":"Santa Maria Capua Vetere","state_id":"1822"},
    {"city_id":"22506","city_name":"Santa Maria a Vico","state_id":"1822"},
    {"city_id":"22507","city_name":"Santa Maria la Carita","state_id":"1822"},
    {"city_id":"22508","city_name":"Sarno","state_id":"1822"},
    {"city_id":"22509","city_name":"Saviano","state_id":"1822"},
    {"city_id":"22510","city_name":"Scafati","state_id":"1822"},
    {"city_id":"22511","city_name":"Sessa Aurunca","state_id":"1822"},
    {"city_id":"22512","city_name":"Siano","state_id":"1822"},
    {"city_id":"22513","city_name":"Solofra","state_id":"1822"},
    {"city_id":"22514","city_name":"Somma Vesuviana","state_id":"1822"},
    {"city_id":"22515","city_name":"Sorrento","state_id":"1822"},
    {"city_id":"22516","city_name":"Teano","state_id":"1822"},
    {"city_id":"22517","city_name":"Teggiano","state_id":"1822"},
    {"city_id":"22518","city_name":"Terzigno","state_id":"1822"},
    {"city_id":"22519","city_name":"Teverola","state_id":"1822"},
    {"city_id":"22520","city_name":"Torre Annunziata","state_id":"1822"},
    {"city_id":"22521","city_name":"Torre del Greco","state_id":"1822"},
    {"city_id":"22522","city_name":"Trecase","state_id":"1822"},
    {"city_id":"22523","city_name":"Trentola-Ducenta","state_id":"1822"},
    {"city_id":"22524","city_name":"Vallo della Lucania","state_id":"1822"},
    {"city_id":"22525","city_name":"Vico Equense","state_id":"1822"},
    {"city_id":"22526","city_name":"Vietri sul Mare","state_id":"1822"},
    {"city_id":"22527","city_name":"Villa Literno","state_id":"1822"},
    {"city_id":"22528","city_name":"Villaricca","state_id":"1822"},
    {"city_id":"22529","city_name":"Volla","state_id":"1822"},
    {"city_id":"22530","city_name":"Cartoceto","state_id":"1823"},
    {"city_id":"22531","city_name":"Carinaro","state_id":"1824"},
    {"city_id":"22532","city_name":"San Marco Evangelista","state_id":"1824"},
    {"city_id":"22533","city_name":"Fiandaca Di Acireale","state_id":"1825"},
    {"city_id":"22534","city_name":"San Cono","state_id":"1825"},
    {"city_id":"22535","city_name":"Altino","state_id":"1826"},
    {"city_id":"22536","city_name":"Archi","state_id":"1826"},
    {"city_id":"22537","city_name":"Ari","state_id":"1826"},
    {"city_id":"22538","city_name":"Arielli","state_id":"1826"},
    {"city_id":"22539","city_name":"Atessa","state_id":"1826"},
    {"city_id":"22540","city_name":"Bomba","state_id":"1826"},
    {"city_id":"22541","city_name":"Borrello","state_id":"1826"},
    {"city_id":"22542","city_name":"Bucchianico","state_id":"1826"},
    {"city_id":"22543","city_name":"Canosa Sannita","state_id":"1826"},
    {"city_id":"22544","city_name":"Carpineto Sinello","state_id":"1826"},
    {"city_id":"22545","city_name":"Carunchio","state_id":"1826"},
    {"city_id":"22546","city_name":"Casacanditella","state_id":"1826"},
    {"city_id":"22547","city_name":"Casalanguida","state_id":"1826"},
    {"city_id":"22548","city_name":"Casalbordino","state_id":"1826"},
    {"city_id":"22549","city_name":"Casalincontrada","state_id":"1826"},
    {"city_id":"22550","city_name":"Casoli","state_id":"1826"},
    {"city_id":"22551","city_name":"Castel Frentano","state_id":"1826"},
    {"city_id":"22552","city_name":"Castelguidone","state_id":"1826"},
    {"city_id":"22553","city_name":"Castiglione Messer Marino","state_id":"1826"},
    {"city_id":"22554","city_name":"Celenza sul Trigno","state_id":"1826"},
    {"city_id":"22555","city_name":"Chieti","state_id":"1826"},
    {"city_id":"22556","city_name":"Civitaluparella","state_id":"1826"},
    {"city_id":"22557","city_name":"Civitella Messer Raimondo","state_id":"1826"},
    {"city_id":"22558","city_name":"Colledimacine","state_id":"1826"},
    {"city_id":"22559","city_name":"Colledimezzo","state_id":"1826"},
    {"city_id":"22560","city_name":"Crecchio","state_id":"1826"},
    {"city_id":"22561","city_name":"Cupello","state_id":"1826"},
    {"city_id":"22562","city_name":"Dogliola","state_id":"1826"},
    {"city_id":"22563","city_name":"Fallo","state_id":"1826"},
    {"city_id":"22564","city_name":"Fara Filiorum Petri","state_id":"1826"},
    {"city_id":"22565","city_name":"Fara San Martino","state_id":"1826"},
    {"city_id":"22566","city_name":"Filetto","state_id":"1826"},
    {"city_id":"22567","city_name":"Fossacesia","state_id":"1826"},
    {"city_id":"22568","city_name":"Fraine","state_id":"1826"},
    {"city_id":"22569","city_name":"Francavilla al Mare","state_id":"1826"},
    {"city_id":"22570","city_name":"Fresagrandinaria","state_id":"1826"},
    {"city_id":"22571","city_name":"Frisa","state_id":"1826"},
    {"city_id":"22572","city_name":"Furci","state_id":"1826"},
    {"city_id":"22573","city_name":"Gamberale","state_id":"1826"},
    {"city_id":"22574","city_name":"Gessopalena","state_id":"1826"},
    {"city_id":"22575","city_name":"Gissi","state_id":"1826"},
    {"city_id":"22576","city_name":"Giuliano Teatino","state_id":"1826"},
    {"city_id":"22577","city_name":"Guardiagrele","state_id":"1826"},
    {"city_id":"22578","city_name":"Guilmi","state_id":"1826"},
    {"city_id":"22579","city_name":"Lama dei Peligni","state_id":"1826"},
    {"city_id":"22580","city_name":"Lanciano","state_id":"1826"},
    {"city_id":"22581","city_name":"Lentella","state_id":"1826"},
    {"city_id":"22582","city_name":"Lettopalena","state_id":"1826"},
    {"city_id":"22583","city_name":"Liscia","state_id":"1826"},
    {"city_id":"22584","city_name":"Miglianico","state_id":"1826"},
    {"city_id":"22585","city_name":"Montazzoli","state_id":"1826"},
    {"city_id":"22586","city_name":"Montebello sul Sangro","state_id":"1826"},
    {"city_id":"22587","city_name":"Monteferrante","state_id":"1826"},
    {"city_id":"22588","city_name":"Montelapiano","state_id":"1826"},
    {"city_id":"22589","city_name":"Montenerodomo","state_id":"1826"},
    {"city_id":"22590","city_name":"Monteodorisio","state_id":"1826"},
    {"city_id":"22591","city_name":"Mozzagrogna","state_id":"1826"},
    {"city_id":"22592","city_name":"Orsogna","state_id":"1826"},
    {"city_id":"22593","city_name":"Ortona","state_id":"1826"},
    {"city_id":"22594","city_name":"Paglieta","state_id":"1826"},
    {"city_id":"22595","city_name":"Palena","state_id":"1826"},
    {"city_id":"22596","city_name":"Palmoli","state_id":"1826"},
    {"city_id":"22597","city_name":"Palombaro","state_id":"1826"},
    {"city_id":"22598","city_name":"Pennadomo","state_id":"1826"},
    {"city_id":"22599","city_name":"Pennapiedimonte","state_id":"1826"},
    {"city_id":"22600","city_name":"Perano","state_id":"1826"},
    {"city_id":"22601","city_name":"Pietraferrazzana","state_id":"1826"},
    {"city_id":"22602","city_name":"Pizzoferrato","state_id":"1826"},
    {"city_id":"22603","city_name":"Poggiofiorito","state_id":"1826"},
    {"city_id":"22604","city_name":"Pollutri","state_id":"1826"},
    {"city_id":"22605","city_name":"Pretoro","state_id":"1826"},
    {"city_id":"22606","city_name":"Quadri","state_id":"1826"},
    {"city_id":"22607","city_name":"Rapino","state_id":"1826"},
    {"city_id":"22608","city_name":"Ripa Teatina","state_id":"1826"},
    {"city_id":"22609","city_name":"Rocca San Giovanni","state_id":"1826"},
    {"city_id":"22610","city_name":"Roccamontepiano","state_id":"1826"},
    {"city_id":"22611","city_name":"Roccascalegna","state_id":"1826"},
    {"city_id":"22612","city_name":"Roccaspinalveti","state_id":"1826"},
    {"city_id":"22613","city_name":"Roio del Sangro","state_id":"1826"},
    {"city_id":"22614","city_name":"Rosello","state_id":"1826"},
    {"city_id":"22615","city_name":"San Buono","state_id":"1826"},
    {"city_id":"22616","city_name":"San Giovanni Lipioni","state_id":"1826"},
    {"city_id":"22617","city_name":"San Giovanni Teatino","state_id":"1826"},
    {"city_id":"22618","city_name":"San Martino sulla Marrucina","state_id":"1826"},
    {"city_id":"22619","city_name":"San Salvo","state_id":"1826"},
    {"city_id":"22620","city_name":"San Vito Chietino","state_id":"1826"},
    {"city_id":"22621","city_name":"Sant Eusanio del Sangro","state_id":"1826"},
    {"city_id":"22622","city_name":"Santa Maria Imbaro","state_id":"1826"},
    {"city_id":"22623","city_name":"Scerni","state_id":"1826"},
    {"city_id":"22624","city_name":"Schiavi di Abruzzo","state_id":"1826"},
    {"city_id":"22625","city_name":"Taranta Peligna","state_id":"1826"},
    {"city_id":"22626","city_name":"Tollo","state_id":"1826"},
    {"city_id":"22627","city_name":"Torino di Sangro","state_id":"1826"},
    {"city_id":"22628","city_name":"Tornareccio","state_id":"1826"},
    {"city_id":"22629","city_name":"Torrebruna","state_id":"1826"},
    {"city_id":"22630","city_name":"Torrevecchia Teatina","state_id":"1826"},
    {"city_id":"22631","city_name":"Torricella Peligna","state_id":"1826"},
    {"city_id":"22632","city_name":"Treglio","state_id":"1826"},
    {"city_id":"22633","city_name":"Tufillo","state_id":"1826"},
    {"city_id":"22634","city_name":"Vacri","state_id":"1826"},
    {"city_id":"22635","city_name":"Vasto","state_id":"1826"},
    {"city_id":"22636","city_name":"Villa Santa Maria","state_id":"1826"},
    {"city_id":"22637","city_name":"Villalfonsina","state_id":"1826"},
    {"city_id":"22638","city_name":"Villamagna","state_id":"1826"},
    {"city_id":"22639","city_name":"Albavilla","state_id":"1827"},
    {"city_id":"22640","city_name":"Cadorago","state_id":"1827"},
    {"city_id":"22641","city_name":"Carimate","state_id":"1827"},
    {"city_id":"22642","city_name":"Castelmarte","state_id":"1827"},
    {"city_id":"22643","city_name":"Cavaria","state_id":"1827"},
    {"city_id":"22644","city_name":"Cernobbio","state_id":"1827"},
    {"city_id":"22645","city_name":"Comocrea","state_id":"1827"},
    {"city_id":"22646","city_name":"Dongo","state_id":"1827"},
    {"city_id":"22647","city_name":"Gironico","state_id":"1827"},
    {"city_id":"22648","city_name":"Grandate","state_id":"1827"},
    {"city_id":"22649","city_name":"Lurago dErba","state_id":"1827"},
    {"city_id":"22650","city_name":"Mozzate","state_id":"1827"},
    {"city_id":"22651","city_name":"Novedrate","state_id":"1827"},
    {"city_id":"22652","city_name":"Orsenigo","state_id":"1827"},
    {"city_id":"22653","city_name":"Turate","state_id":"1827"},
    {"city_id":"22654","city_name":"Uggiate","state_id":"1827"},
    {"city_id":"22655","city_name":"Corso del Tirreno","state_id":"1828"},
    {"city_id":"22656","city_name":"Mangone","state_id":"1828"},
    {"city_id":"22657","city_name":"Casalbuttano","state_id":"1829"},
    {"city_id":"22658","city_name":"Casalmaggiore","state_id":"1829"},
    {"city_id":"22659","city_name":"Castelverde","state_id":"1829"},
    {"city_id":"22660","city_name":"Madignano","state_id":"1829"},
    {"city_id":"22661","city_name":"Pieve San Giacomo","state_id":"1829"},
    {"city_id":"22662","city_name":"Bandito","state_id":"1830"},
    {"city_id":"22663","city_name":"Bra","state_id":"1830"},
    {"city_id":"22664","city_name":"Casalgrasso","state_id":"1830"},
    {"city_id":"22665","city_name":"Cossano Belbo","state_id":"1830"},
    {"city_id":"22666","city_name":"Magliano Alpi","state_id":"1830"},
    {"city_id":"22667","city_name":"Mondovi","state_id":"1830"},
    {"city_id":"22668","city_name":"Roddi","state_id":"1830"},
    {"city_id":"22669","city_name":"Santa Vittoria d'Alba","state_id":"1830"},
    {"city_id":"22670","city_name":"Verduno","state_id":"1830"},
    {"city_id":"22671","city_name":"Alfonsine","state_id":"1831"},
    {"city_id":"22672","city_name":"Argelato","state_id":"1831"},
    {"city_id":"22673","city_name":"Argenta","state_id":"1831"},
    {"city_id":"22674","city_name":"Bagnacavallo","state_id":"1831"},
    {"city_id":"22675","city_name":"Bagnolo in Piano","state_id":"1831"},
    {"city_id":"22676","city_name":"Bellaria-Igea Marina","state_id":"1831"},
    {"city_id":"22677","city_name":"Bertinoro","state_id":"1831"},
    {"city_id":"22678","city_name":"Bologna","state_id":"1831"},
    {"city_id":"22679","city_name":"Bondeno","state_id":"1831"},
    {"city_id":"22680","city_name":"Budrio","state_id":"1831"},
    {"city_id":"22681","city_name":"Calderara di Reno","state_id":"1831"},
    {"city_id":"22682","city_name":"Carpi","state_id":"1831"},
    {"city_id":"22683","city_name":"Casalecchio di Reno","state_id":"1831"},
    {"city_id":"22684","city_name":"Casalgrande","state_id":"1831"},
    {"city_id":"22685","city_name":"Castel Bolognese","state_id":"1831"},
    {"city_id":"22686","city_name":"Castel Maggiore","state_id":"1831"},
    {"city_id":"22687","city_name":"Castel San Giovanni","state_id":"1831"},
    {"city_id":"22688","city_name":"Castel San Pietro Terme","state_id":"1831"},
    {"city_id":"22689","city_name":"Castelfranco Emilia","state_id":"1831"},
    {"city_id":"22690","city_name":"Castellarano","state_id":"1831"},
    {"city_id":"22691","city_name":"Castelnovo ne' Monti","state_id":"1831"},
    {"city_id":"22692","city_name":"Castelnuovo Rangone","state_id":"1831"},
    {"city_id":"22693","city_name":"Castelvetro di Modena","state_id":"1831"},
    {"city_id":"22694","city_name":"Castenaso","state_id":"1831"},
    {"city_id":"22695","city_name":"Cattolica","state_id":"1831"},
    {"city_id":"22696","city_name":"Cavriago","state_id":"1831"},
    {"city_id":"22697","city_name":"Cento","state_id":"1831"},
    {"city_id":"22698","city_name":"Cervia","state_id":"1831"},
    {"city_id":"22699","city_name":"Cesena","state_id":"1831"},
    {"city_id":"22700","city_name":"Cesenatico","state_id":"1831"},
    {"city_id":"22701","city_name":"Codigoro","state_id":"1831"},
    {"city_id":"22702","city_name":"Collecchio","state_id":"1831"},
    {"city_id":"22703","city_name":"Comacchio","state_id":"1831"},
    {"city_id":"22704","city_name":"Concordia sulla Secchia","state_id":"1831"},
    {"city_id":"22705","city_name":"Conselice","state_id":"1831"},
    {"city_id":"22706","city_name":"Copparo","state_id":"1831"},
    {"city_id":"22707","city_name":"Coriano","state_id":"1831"},
    {"city_id":"22708","city_name":"Correggio","state_id":"1831"},
    {"city_id":"22709","city_name":"Crespellano","state_id":"1831"},
    {"city_id":"22710","city_name":"Crevalcore","state_id":"1831"},
    {"city_id":"22711","city_name":"Faenza","state_id":"1831"},
    {"city_id":"22712","city_name":"Ferrara","state_id":"1831"},
    {"city_id":"22713","city_name":"Fidenza","state_id":"1831"},
    {"city_id":"22714","city_name":"Finale Emilia","state_id":"1831"},
    {"city_id":"22715","city_name":"Fiorano Modenese","state_id":"1831"},
    {"city_id":"22716","city_name":"Fiorenzuola d'Arda","state_id":"1831"},
    {"city_id":"22717","city_name":"Forli","state_id":"1831"},
    {"city_id":"22718","city_name":"Forlimpopoli","state_id":"1831"},
    {"city_id":"22719","city_name":"Formigine","state_id":"1831"},
    {"city_id":"22720","city_name":"Gambettola","state_id":"1831"},
    {"city_id":"22721","city_name":"Granarolo dell'Emilia","state_id":"1831"},
    {"city_id":"22722","city_name":"Guastalla","state_id":"1831"},
    {"city_id":"22723","city_name":"Imola","state_id":"1831"},
    {"city_id":"22724","city_name":"Langhirano","state_id":"1831"},
    {"city_id":"22725","city_name":"Lugo","state_id":"1831"},
    {"city_id":"22726","city_name":"Luzzara","state_id":"1831"},
    {"city_id":"22727","city_name":"Maranello","state_id":"1831"},
    {"city_id":"22728","city_name":"Massa Lombarda","state_id":"1831"},
    {"city_id":"22729","city_name":"Medesano","state_id":"1831"},
    {"city_id":"22730","city_name":"Medicina","state_id":"1831"},
    {"city_id":"22731","city_name":"Meldola","state_id":"1831"},
    {"city_id":"22732","city_name":"Mirandola","state_id":"1831"},
    {"city_id":"22733","city_name":"Misano Adriatico","state_id":"1831"},
    {"city_id":"22734","city_name":"Modena","state_id":"1831"},
    {"city_id":"22735","city_name":"Molinella","state_id":"1831"},
    {"city_id":"22736","city_name":"Monte San Pietro","state_id":"1831"},
    {"city_id":"22737","city_name":"Montecchio Emilia","state_id":"1831"},
    {"city_id":"22738","city_name":"Montechiarugolo","state_id":"1831"},
    {"city_id":"22739","city_name":"Noceto","state_id":"1831"},
    {"city_id":"22740","city_name":"Nonantola","state_id":"1831"},
    {"city_id":"22741","city_name":"Novellara","state_id":"1831"},
    {"city_id":"22742","city_name":"Novi di Modena","state_id":"1831"},
    {"city_id":"22743","city_name":"Ozzano dell'Emilia","state_id":"1831"},
    {"city_id":"22744","city_name":"Parma","state_id":"1831"},
    {"city_id":"22745","city_name":"Pavullo nel Frignano","state_id":"1831"},
    {"city_id":"22746","city_name":"Piacenza","state_id":"1831"},
    {"city_id":"22747","city_name":"Pianoro","state_id":"1831"},
    {"city_id":"22748","city_name":"Ponticino","state_id":"1831"},
    {"city_id":"22749","city_name":"Portomaggiore","state_id":"1831"},
    {"city_id":"22750","city_name":"Quattro Castella","state_id":"1831"},
    {"city_id":"22751","city_name":"Ravenna","state_id":"1831"},
    {"city_id":"22752","city_name":"Reggio nell'Emilia","state_id":"1831"},
    {"city_id":"22753","city_name":"Reggiolo","state_id":"1831"},
    {"city_id":"22754","city_name":"Riccione","state_id":"1831"},
    {"city_id":"22755","city_name":"Rimini","state_id":"1831"},
    {"city_id":"22756","city_name":"Rottofreno","state_id":"1831"},
    {"city_id":"22757","city_name":"Rubiera","state_id":"1831"},
    {"city_id":"22758","city_name":"Russi","state_id":"1831"},
    {"city_id":"22759","city_name":"Salsomaggiore Terme","state_id":"1831"},
    {"city_id":"22760","city_name":"San Felice sul Panaro","state_id":"1831"},
    {"city_id":"22761","city_name":"San Giovanni in Persiceto","state_id":"1831"},
    {"city_id":"22762","city_name":"San Lazzaro di Savena","state_id":"1831"},
    {"city_id":"22763","city_name":"San Mauro Pascoli","state_id":"1831"},
    {"city_id":"22764","city_name":"San Pietro in Casale","state_id":"1831"},
    {"city_id":"22765","city_name":"Sant'Ilario d'Enza","state_id":"1831"},
    {"city_id":"22766","city_name":"Santarcangelo di Romagna","state_id":"1831"},
    {"city_id":"22767","city_name":"Sasso Marconi","state_id":"1831"},
    {"city_id":"22768","city_name":"Sassuolo","state_id":"1831"},
    {"city_id":"22769","city_name":"Savignano sul Panaro","state_id":"1831"},
    {"city_id":"22770","city_name":"Savignano sul Rubicone","state_id":"1831"},
    {"city_id":"22771","city_name":"Scandiano","state_id":"1831"},
    {"city_id":"22772","city_name":"Soliera","state_id":"1831"},
    {"city_id":"22773","city_name":"Sorbolo","state_id":"1831"},
    {"city_id":"22774","city_name":"Spilamberto","state_id":"1831"},
    {"city_id":"22775","city_name":"Verucchio","state_id":"1831"},
    {"city_id":"22776","city_name":"Vignola","state_id":"1831"},
    {"city_id":"22777","city_name":"Zola Predosa","state_id":"1831"},
    {"city_id":"22778","city_name":"Saint Agostino","state_id":"1832"},
    {"city_id":"22779","city_name":"Capalle","state_id":"1833"},
    {"city_id":"22780","city_name":"Firenze","state_id":"1833"},
    {"city_id":"22781","city_name":"Pelago","state_id":"1833"},
    {"city_id":"22782","city_name":"San Donnino","state_id":"1833"},
    {"city_id":"22783","city_name":"Scarperia","state_id":"1833"},
    {"city_id":"22784","city_name":"Scandicci","state_id":"1834"},
    {"city_id":"22785","city_name":"Sesto Fiorentino","state_id":"1834"},
    {"city_id":"22786","city_name":"Casalvieri","state_id":"1837"},
    {"city_id":"22787","city_name":"Frosinone","state_id":"1837"},
    {"city_id":"22788","city_name":"Sgurgola","state_id":"1837"},
    {"city_id":"22789","city_name":"Genoa","state_id":"1838"},
    {"city_id":"22790","city_name":"Moneglia","state_id":"1838"},
    {"city_id":"22791","city_name":"Romans d'Isonzo","state_id":"1839"},
    {"city_id":"22792","city_name":"Savogna d'Isonzo","state_id":"1839"},
    {"city_id":"22793","city_name":"Magliano de Marsi","state_id":"1840"},
    {"city_id":"22794","city_name":"Alatri","state_id":"1841"},
    {"city_id":"22795","city_name":"Albano Laziale","state_id":"1841"},
    {"city_id":"22796","city_name":"Anagni","state_id":"1841"},
    {"city_id":"22797","city_name":"Anguillara Sabazia","state_id":"1841"},
    {"city_id":"22798","city_name":"Anzio","state_id":"1841"},
    {"city_id":"22799","city_name":"Aprilia","state_id":"1841"},
    {"city_id":"22800","city_name":"Ardea","state_id":"1841"},
    {"city_id":"22801","city_name":"Ariccia","state_id":"1841"},
    {"city_id":"22802","city_name":"Artena","state_id":"1841"},
    {"city_id":"22803","city_name":"Boville Ernica","state_id":"1841"},
    {"city_id":"22804","city_name":"Bracciano","state_id":"1841"},
    {"city_id":"22805","city_name":"Campagnano di Roma","state_id":"1841"},
    {"city_id":"22806","city_name":"Cassino","state_id":"1841"},
    {"city_id":"22807","city_name":"Cave","state_id":"1841"},
    {"city_id":"22808","city_name":"Ceccano","state_id":"1841"},
    {"city_id":"22809","city_name":"Ceprano","state_id":"1841"},
    {"city_id":"22810","city_name":"Cerveteri","state_id":"1841"},
    {"city_id":"22811","city_name":"Ciampino","state_id":"1841"},
    {"city_id":"22812","city_name":"Cisterna","state_id":"1841"},
    {"city_id":"22813","city_name":"Civita Castellana","state_id":"1841"},
    {"city_id":"22814","city_name":"Civitavecchia","state_id":"1841"},
    {"city_id":"22815","city_name":"Colleferro","state_id":"1841"},
    {"city_id":"22816","city_name":"Cori","state_id":"1841"},
    {"city_id":"22817","city_name":"Fara in Sabina","state_id":"1841"},
    {"city_id":"22818","city_name":"Ferentino","state_id":"1841"},
    {"city_id":"22819","city_name":"Fiano Romano","state_id":"1841"},
    {"city_id":"22820","city_name":"Fiuggi","state_id":"1841"},
    {"city_id":"22821","city_name":"Fiumicino","state_id":"1841"},
    {"city_id":"22822","city_name":"Fondi","state_id":"1841"},
    {"city_id":"22823","city_name":"Fonte Nuova","state_id":"1841"},
    {"city_id":"22824","city_name":"Formello","state_id":"1841"},
    {"city_id":"22825","city_name":"Formia","state_id":"1841"},
    {"city_id":"22826","city_name":"Frascati","state_id":"1841"},
    {"city_id":"22827","city_name":"Frosinone","state_id":"1841"},
    {"city_id":"22828","city_name":"Gaeta","state_id":"1841"},
    {"city_id":"22829","city_name":"Genzano di Roma","state_id":"1841"},
    {"city_id":"22830","city_name":"Grottaferrata","state_id":"1841"},
    {"city_id":"22831","city_name":"Guidonia","state_id":"1841"},
    {"city_id":"22832","city_name":"Isola del Liri","state_id":"1841"},
    {"city_id":"22833","city_name":"Itri","state_id":"1841"},
    {"city_id":"22834","city_name":"Ladispoli","state_id":"1841"},
    {"city_id":"22835","city_name":"Lanuvio","state_id":"1841"},
    {"city_id":"22836","city_name":"Lariano","state_id":"1841"},
    {"city_id":"22837","city_name":"Latina","state_id":"1841"},
    {"city_id":"22838","city_name":"Marino","state_id":"1841"},
    {"city_id":"22839","city_name":"Mentana","state_id":"1841"},
    {"city_id":"22840","city_name":"Minturno","state_id":"1841"},
    {"city_id":"22841","city_name":"Monte San Giovanni Campano","state_id":"1841"},
    {"city_id":"22842","city_name":"Montefiascone","state_id":"1841"},
    {"city_id":"22843","city_name":"Monterotondo","state_id":"1841"},
    {"city_id":"22844","city_name":"Nettuno","state_id":"1841"},
    {"city_id":"22845","city_name":"Palestrina","state_id":"1841"},
    {"city_id":"22846","city_name":"Palombara Sabina","state_id":"1841"},
    {"city_id":"22847","city_name":"Pomezia","state_id":"1841"},
    {"city_id":"22848","city_name":"Pontecorvo","state_id":"1841"},
    {"city_id":"22849","city_name":"Pontinia","state_id":"1841"},
    {"city_id":"22850","city_name":"Priverno","state_id":"1841"},
    {"city_id":"22851","city_name":"Rieti","state_id":"1841"},
    {"city_id":"22852","city_name":"Rocca Priora","state_id":"1841"},
    {"city_id":"22853","city_name":"Rocca di Papa","state_id":"1841"},
    {"city_id":"22854","city_name":"Roma","state_id":"1841"},
    {"city_id":"22855","city_name":"Rome","state_id":"1841"},
    {"city_id":"22856","city_name":"Sabaudia","state_id":"1841"},
    {"city_id":"22857","city_name":"San Cesareo","state_id":"1841"},
    {"city_id":"22858","city_name":"Santa Marinella","state_id":"1841"},
    {"city_id":"22859","city_name":"Segni","state_id":"1841"},
    {"city_id":"22860","city_name":"Sezze","state_id":"1841"},
    {"city_id":"22861","city_name":"Sora","state_id":"1841"},
    {"city_id":"22862","city_name":"Soriano nel Cimino","state_id":"1841"},
    {"city_id":"22863","city_name":"Subiaco","state_id":"1841"},
    {"city_id":"22864","city_name":"Tarquinia","state_id":"1841"},
    {"city_id":"22865","city_name":"Terracina","state_id":"1841"},
    {"city_id":"22866","city_name":"Tivoli","state_id":"1841"},
    {"city_id":"22867","city_name":"Valmontone","state_id":"1841"},
    {"city_id":"22868","city_name":"Velletri","state_id":"1841"},
    {"city_id":"22869","city_name":"Veroli","state_id":"1841"},
    {"city_id":"22870","city_name":"Vetralla","state_id":"1841"},
    {"city_id":"22871","city_name":"Viterbo","state_id":"1841"},
    {"city_id":"22872","city_name":"Zagarolo","state_id":"1841"},
    {"city_id":"22873","city_name":"Acquarica del Capo","state_id":"1842"},
    {"city_id":"22874","city_name":"Airuno","state_id":"1843"},
    {"city_id":"22875","city_name":"Bosisio Parini","state_id":"1843"},
    {"city_id":"22876","city_name":"Lecco","state_id":"1843"},
    {"city_id":"22877","city_name":"Margno","state_id":"1843"},
    {"city_id":"22878","city_name":"Osnago","state_id":"1843"},
    {"city_id":"22879","city_name":"Sirone","state_id":"1843"},
    {"city_id":"22880","city_name":"Airuno","state_id":"1844"},
    {"city_id":"22881","city_name":"Bosisio Parini","state_id":"1844"},
    {"city_id":"22882","city_name":"Lecco","state_id":"1844"},
    {"city_id":"22883","city_name":"Margno","state_id":"1844"},
    {"city_id":"22884","city_name":"Osnago","state_id":"1844"},
    {"city_id":"22885","city_name":"Sirone","state_id":"1844"},
    {"city_id":"22886","city_name":"Alassio","state_id":"1845"},
    {"city_id":"22887","city_name":"Albenga","state_id":"1845"},
    {"city_id":"22888","city_name":"Albisola Superiore","state_id":"1845"},
    {"city_id":"22889","city_name":"Arcola","state_id":"1845"},
    {"city_id":"22890","city_name":"Arenzano","state_id":"1845"},
    {"city_id":"22891","city_name":"Bordighera","state_id":"1845"},
    {"city_id":"22892","city_name":"Borgonuovo","state_id":"1845"},
    {"city_id":"22893","city_name":"Cairo Montenotte","state_id":"1845"},
    {"city_id":"22894","city_name":"Chiavari","state_id":"1845"},
    {"city_id":"22895","city_name":"Cogoleto","state_id":"1845"},
    {"city_id":"22896","city_name":"Finale Ligure","state_id":"1845"},
    {"city_id":"22897","city_name":"Genova","state_id":"1845"},
    {"city_id":"22898","city_name":"Imperia","state_id":"1845"},
    {"city_id":"22899","city_name":"La Spezia","state_id":"1845"},
    {"city_id":"22900","city_name":"Lavagna","state_id":"1845"},
    {"city_id":"22901","city_name":"Lerici","state_id":"1845"},
    {"city_id":"22902","city_name":"Loano","state_id":"1845"},
    {"city_id":"22903","city_name":"Ortonovo","state_id":"1845"},
    {"city_id":"22904","city_name":"Pietra Ligure","state_id":"1845"},
    {"city_id":"22905","city_name":"Rapallo","state_id":"1845"},
    {"city_id":"22906","city_name":"Recco","state_id":"1845"},
    {"city_id":"22907","city_name":"San Remo","state_id":"1845"},
    {"city_id":"22908","city_name":"Santa Margherita Ligure","state_id":"1845"},
    {"city_id":"22909","city_name":"Santo Stefano di Magra","state_id":"1845"},
    {"city_id":"22910","city_name":"Sarzana","state_id":"1845"},
    {"city_id":"22911","city_name":"Savona","state_id":"1845"},
    {"city_id":"22912","city_name":"Sestri Levante","state_id":"1845"},
    {"city_id":"22913","city_name":"Taggia","state_id":"1845"},
    {"city_id":"22914","city_name":"Varazze","state_id":"1845"},
    {"city_id":"22915","city_name":"Ventimiglia","state_id":"1845"},
    {"city_id":"22916","city_name":"Somaglia","state_id":"1846"},
    {"city_id":"22917","city_name":"Abbiategrasso","state_id":"1847"},
    {"city_id":"22918","city_name":"Agrate Brianza","state_id":"1847"},
    {"city_id":"22919","city_name":"Albiate","state_id":"1847"},
    {"city_id":"22920","city_name":"Albino","state_id":"1847"},
    {"city_id":"22921","city_name":"Albizzate","state_id":"1847"},
    {"city_id":"22922","city_name":"Alzano Lombardo","state_id":"1847"},
    {"city_id":"22923","city_name":"Arcisate","state_id":"1847"},
    {"city_id":"22924","city_name":"Arconate","state_id":"1847"},
    {"city_id":"22925","city_name":"Arcore","state_id":"1847"},
    {"city_id":"22926","city_name":"Arese","state_id":"1847"},
    {"city_id":"22927","city_name":"Arluno","state_id":"1847"},
    {"city_id":"22928","city_name":"Asola","state_id":"1847"},
    {"city_id":"22929","city_name":"Bagnolo Mella","state_id":"1847"},
    {"city_id":"22930","city_name":"Ballabio","state_id":"1847"},
    {"city_id":"22931","city_name":"Bareggio","state_id":"1847"},
    {"city_id":"22932","city_name":"Basiglio","state_id":"1847"},
    {"city_id":"22933","city_name":"Bedizzole","state_id":"1847"},
    {"city_id":"22934","city_name":"Bergamo","state_id":"1847"},
    {"city_id":"22935","city_name":"Bernareggio","state_id":"1847"},
    {"city_id":"22936","city_name":"Besana in Brianza","state_id":"1847"},
    {"city_id":"22937","city_name":"Besozzo","state_id":"1847"},
    {"city_id":"22938","city_name":"Biassono","state_id":"1847"},
    {"city_id":"22939","city_name":"Bienate","state_id":"1847"},
    {"city_id":"22940","city_name":"Bollate","state_id":"1847"},
    {"city_id":"22941","city_name":"Botticino","state_id":"1847"},
    {"city_id":"22942","city_name":"Bovisio-Masciago","state_id":"1847"},
    {"city_id":"22943","city_name":"Brembilla","state_id":"1847"},
    {"city_id":"22944","city_name":"Brescia","state_id":"1847"},
    {"city_id":"22945","city_name":"Bresso","state_id":"1847"},
    {"city_id":"22946","city_name":"Broni","state_id":"1847"},
    {"city_id":"22947","city_name":"Brugherio","state_id":"1847"},
    {"city_id":"22948","city_name":"Buccinasco","state_id":"1847"},
    {"city_id":"22949","city_name":"Bussero","state_id":"1847"},
    {"city_id":"22950","city_name":"Busto Arsizio","state_id":"1847"},
    {"city_id":"22951","city_name":"Busto Garolfo","state_id":"1847"},
    {"city_id":"22952","city_name":"Cairate","state_id":"1847"},
    {"city_id":"22953","city_name":"Calcinato","state_id":"1847"},
    {"city_id":"22954","city_name":"Calolziocorte","state_id":"1847"},
    {"city_id":"22955","city_name":"Calusco d'Adda","state_id":"1847"},
    {"city_id":"22956","city_name":"Canegrate","state_id":"1847"},
    {"city_id":"22957","city_name":"Cantu","state_id":"1847"},
    {"city_id":"22958","city_name":"Capriolo","state_id":"1847"},
    {"city_id":"22959","city_name":"Carate Brianza","state_id":"1847"},
    {"city_id":"22960","city_name":"Caravaggio","state_id":"1847"},
    {"city_id":"22961","city_name":"Cardano al Campo","state_id":"1847"},
    {"city_id":"22962","city_name":"Caronno Pertusella","state_id":"1847"},
    {"city_id":"22963","city_name":"Carpenedolo","state_id":"1847"},
    {"city_id":"22964","city_name":"Carugate","state_id":"1847"},
    {"city_id":"22965","city_name":"Carvico","state_id":"1847"},
    {"city_id":"22966","city_name":"Casalmaggiore","state_id":"1847"},
    {"city_id":"22967","city_name":"Casalpusterlengo","state_id":"1847"},
    {"city_id":"22968","city_name":"Casatenovo","state_id":"1847"},
    {"city_id":"22969","city_name":"Casazza","state_id":"1847"},
    {"city_id":"22970","city_name":"Casnigo","state_id":"1847"},
    {"city_id":"22971","city_name":"Cassano Magnago","state_id":"1847"},
    {"city_id":"22972","city_name":"Cassano d'Adda","state_id":"1847"},
    {"city_id":"22973","city_name":"Cassina de' Pecchi","state_id":"1847"},
    {"city_id":"22974","city_name":"Castano Primo","state_id":"1847"},
    {"city_id":"22975","city_name":"Castel Goffredo","state_id":"1847"},
    {"city_id":"22976","city_name":"Castel Mella","state_id":"1847"},
    {"city_id":"22977","city_name":"Castellanza","state_id":"1847"},
    {"city_id":"22978","city_name":"Castelleone","state_id":"1847"},
    {"city_id":"22979","city_name":"Castelli Calepio","state_id":"1847"},
    {"city_id":"22980","city_name":"Castenedolo","state_id":"1847"},
    {"city_id":"22981","city_name":"Castiglione delle Stiviere","state_id":"1847"},
    {"city_id":"22982","city_name":"Cazzago San Martino","state_id":"1847"},
    {"city_id":"22983","city_name":"Cene","state_id":"1847"},
    {"city_id":"22984","city_name":"Cermenate","state_id":"1847"},
    {"city_id":"22985","city_name":"Cernusco sul Naviglio","state_id":"1847"},
    {"city_id":"22986","city_name":"Cerro Maggiore","state_id":"1847"},
    {"city_id":"22987","city_name":"Cesano Boscone","state_id":"1847"},
    {"city_id":"22988","city_name":"Cesano Maderno","state_id":"1847"},
    {"city_id":"22989","city_name":"Cesate","state_id":"1847"},
    {"city_id":"22990","city_name":"Chiari","state_id":"1847"},
    {"city_id":"22991","city_name":"Cilavegna","state_id":"1847"},
    {"city_id":"22992","city_name":"Cinisello Balsamo","state_id":"1847"},
    {"city_id":"22993","city_name":"Cislago","state_id":"1847"},
    {"city_id":"22994","city_name":"Clusone","state_id":"1847"},
    {"city_id":"22995","city_name":"Codogno","state_id":"1847"},
    {"city_id":"22996","city_name":"Cologno Monzese","state_id":"1847"},
    {"city_id":"22997","city_name":"Cologno al Serio","state_id":"1847"},
    {"city_id":"22998","city_name":"Como","state_id":"1847"},
    {"city_id":"22999","city_name":"Concesio","state_id":"1847"},
    {"city_id":"23000","city_name":"Concorezzo","state_id":"1847"},
    {"city_id":"23001","city_name":"Corbetta","state_id":"1847"},
    {"city_id":"23002","city_name":"Cormano","state_id":"1847"},
    {"city_id":"23003","city_name":"Cornaredo","state_id":"1847"},
    {"city_id":"23004","city_name":"Cornate d'Adda","state_id":"1847"},
    {"city_id":"23005","city_name":"Corsico","state_id":"1847"},
    {"city_id":"23006","city_name":"Corte dei Cortesi","state_id":"1847"},
    {"city_id":"23007","city_name":"Costa Volpino","state_id":"1847"},
    {"city_id":"23008","city_name":"Crema","state_id":"1847"},
    {"city_id":"23009","city_name":"Cremona","state_id":"1847"},
    {"city_id":"23010","city_name":"Crocetta","state_id":"1847"},
    {"city_id":"23011","city_name":"Curtatone","state_id":"1847"},
    {"city_id":"23012","city_name":"Cusano Milanino","state_id":"1847"},
    {"city_id":"23013","city_name":"Dalmine","state_id":"1847"},
    {"city_id":"23014","city_name":"Darfo","state_id":"1847"},
    {"city_id":"23015","city_name":"Desenzano del Garda","state_id":"1847"},
    {"city_id":"23016","city_name":"Desio","state_id":"1847"},
    {"city_id":"23017","city_name":"Erba","state_id":"1847"},
    {"city_id":"23018","city_name":"Fagnano Olona","state_id":"1847"},
    {"city_id":"23019","city_name":"Fino Mornasco","state_id":"1847"},
    {"city_id":"23020","city_name":"Gaggiano","state_id":"1847"},
    {"city_id":"23021","city_name":"Galbiate","state_id":"1847"},
    {"city_id":"23022","city_name":"Gallarate","state_id":"1847"},
    {"city_id":"23023","city_name":"Gambolo","state_id":"1847"},
    {"city_id":"23024","city_name":"Garbagnate Milanese","state_id":"1847"},
    {"city_id":"23025","city_name":"Gardone Val Trompia","state_id":"1847"},
    {"city_id":"23026","city_name":"Garlasco","state_id":"1847"},
    {"city_id":"23027","city_name":"Gavardo","state_id":"1847"},
    {"city_id":"23028","city_name":"Gavirate","state_id":"1847"},
    {"city_id":"23029","city_name":"Gerenzano","state_id":"1847"},
    {"city_id":"23030","city_name":"Ghedi","state_id":"1847"},
    {"city_id":"23031","city_name":"Giussano","state_id":"1847"},
    {"city_id":"23032","city_name":"Goito","state_id":"1847"},
    {"city_id":"23033","city_name":"Gonzaga","state_id":"1847"},
    {"city_id":"23034","city_name":"Gorgonzola","state_id":"1847"},
    {"city_id":"23035","city_name":"Gussago","state_id":"1847"},
    {"city_id":"23036","city_name":"Gussola","state_id":"1847"},
    {"city_id":"23037","city_name":"Induno Olona","state_id":"1847"},
    {"city_id":"23038","city_name":"Inveruno","state_id":"1847"},
    {"city_id":"23039","city_name":"Inzago","state_id":"1847"},
    {"city_id":"23040","city_name":"Iseo","state_id":"1847"},
    {"city_id":"23041","city_name":"Isola Dovarese","state_id":"1847"},
    {"city_id":"23042","city_name":"Lacchiarella","state_id":"1847"},
    {"city_id":"23043","city_name":"Lainate","state_id":"1847"},
    {"city_id":"23044","city_name":"Laveno-Mombello","state_id":"1847"},
    {"city_id":"23045","city_name":"Lecco","state_id":"1847"},
    {"city_id":"23046","city_name":"Leffe","state_id":"1847"},
    {"city_id":"23047","city_name":"Legnano","state_id":"1847"},
    {"city_id":"23048","city_name":"Leno","state_id":"1847"},
    {"city_id":"23049","city_name":"Lentate sul Seveso","state_id":"1847"},
    {"city_id":"23050","city_name":"Limbiate","state_id":"1847"},
    {"city_id":"23051","city_name":"Lissone","state_id":"1847"},
    {"city_id":"23052","city_name":"Locate di Triulzi","state_id":"1847"},
    {"city_id":"23053","city_name":"Lodi","state_id":"1847"},
    {"city_id":"23054","city_name":"Lomazzo","state_id":"1847"},
    {"city_id":"23055","city_name":"Lonate Pozzolo","state_id":"1847"},
    {"city_id":"23056","city_name":"Lonato","state_id":"1847"},
    {"city_id":"23057","city_name":"Luino","state_id":"1847"},
    {"city_id":"23058","city_name":"Lumezzane","state_id":"1847"},
    {"city_id":"23059","city_name":"Lurate Caccivio","state_id":"1847"},
    {"city_id":"23060","city_name":"Magenta","state_id":"1847"},
    {"city_id":"23061","city_name":"Malnate","state_id":"1847"},
    {"city_id":"23062","city_name":"Mandello del Lario","state_id":"1847"},
    {"city_id":"23063","city_name":"Manerbio","state_id":"1847"},
    {"city_id":"23064","city_name":"Mantova","state_id":"1847"},
    {"city_id":"23065","city_name":"Mariano Comense","state_id":"1847"},
    {"city_id":"23066","city_name":"Martinengo","state_id":"1847"},
    {"city_id":"23067","city_name":"Mazzano","state_id":"1847"},
    {"city_id":"23068","city_name":"Meda","state_id":"1847"},
    {"city_id":"23069","city_name":"Mediglia","state_id":"1847"},
    {"city_id":"23070","city_name":"Melegnano","state_id":"1847"},
    {"city_id":"23071","city_name":"Melzo","state_id":"1847"},
    {"city_id":"23072","city_name":"Merate","state_id":"1847"},
    {"city_id":"23073","city_name":"Milano","state_id":"1847"},
    {"city_id":"23074","city_name":"Molteno","state_id":"1847"},
    {"city_id":"23075","city_name":"Montichiari","state_id":"1847"},
    {"city_id":"23076","city_name":"Monza","state_id":"1847"},
    {"city_id":"23077","city_name":"Morbegno","state_id":"1847"},
    {"city_id":"23078","city_name":"Mornago","state_id":"1847"},
    {"city_id":"23079","city_name":"Mortara","state_id":"1847"},
    {"city_id":"23080","city_name":"Muggio","state_id":"1847"},
    {"city_id":"23081","city_name":"Nave","state_id":"1847"},
    {"city_id":"23082","city_name":"Nembro","state_id":"1847"},
    {"city_id":"23083","city_name":"Nerviano","state_id":"1847"},
    {"city_id":"23084","city_name":"Nova Milanese","state_id":"1847"},
    {"city_id":"23085","city_name":"Novate Milanese","state_id":"1847"},
    {"city_id":"23086","city_name":"Olgiate Comasco","state_id":"1847"},
    {"city_id":"23087","city_name":"Olgiate Olona","state_id":"1847"},
    {"city_id":"23088","city_name":"Opera","state_id":"1847"},
    {"city_id":"23089","city_name":"Orzinuovi","state_id":"1847"},
    {"city_id":"23090","city_name":"Osio Sotto","state_id":"1847"},
    {"city_id":"23091","city_name":"Ospitaletto","state_id":"1847"},
    {"city_id":"23092","city_name":"Paderno Dugnano","state_id":"1847"},
    {"city_id":"23093","city_name":"Palazzolo sull'Oglio","state_id":"1847"},
    {"city_id":"23094","city_name":"Pandino","state_id":"1847"},
    {"city_id":"23095","city_name":"Parabiago","state_id":"1847"},
    {"city_id":"23096","city_name":"Paullo","state_id":"1847"},
    {"city_id":"23097","city_name":"Pavia","state_id":"1847"},
    {"city_id":"23098","city_name":"Pero","state_id":"1847"},
    {"city_id":"23099","city_name":"Peschiera Borromeo","state_id":"1847"},
    {"city_id":"23100","city_name":"Pessano con Bornago","state_id":"1847"},
    {"city_id":"23101","city_name":"Pieve Emanuele","state_id":"1847"},
    {"city_id":"23102","city_name":"Pioltello","state_id":"1847"},
    {"city_id":"23103","city_name":"Ponte Nossa","state_id":"1847"},
    {"city_id":"23104","city_name":"Ponte San Pietro","state_id":"1847"},
    {"city_id":"23105","city_name":"Porto Mantovano","state_id":"1847"},
    {"city_id":"23106","city_name":"Pozzolengo","state_id":"1847"},
    {"city_id":"23107","city_name":"Rescaldina","state_id":"1847"},
    {"city_id":"23108","city_name":"Rezzato","state_id":"1847"},
    {"city_id":"23109","city_name":"Rho","state_id":"1847"},
    {"city_id":"23110","city_name":"Rivarolo Mantovano","state_id":"1847"},
    {"city_id":"23111","city_name":"Rodano","state_id":"1847"},
    {"city_id":"23112","city_name":"Romano di Lombardia","state_id":"1847"},
    {"city_id":"23113","city_name":"Rovato","state_id":"1847"},
    {"city_id":"23114","city_name":"Rozzano","state_id":"1847"},
    {"city_id":"23115","city_name":"Saletto","state_id":"1847"},
    {"city_id":"23116","city_name":"Salo","state_id":"1847"},
    {"city_id":"23117","city_name":"Samarate","state_id":"1847"},
    {"city_id":"23118","city_name":"San Donato Milanese","state_id":"1847"},
    {"city_id":"23119","city_name":"San Giuliano Milanese","state_id":"1847"},
    {"city_id":"23120","city_name":"Sant'Angelo Lodigiano","state_id":"1847"},
    {"city_id":"23121","city_name":"Sarezzo","state_id":"1847"},
    {"city_id":"23122","city_name":"Saronno","state_id":"1847"},
    {"city_id":"23123","city_name":"Scanzorosciate","state_id":"1847"},
    {"city_id":"23124","city_name":"Sedriano","state_id":"1847"},
    {"city_id":"23125","city_name":"Segrate","state_id":"1847"},
    {"city_id":"23126","city_name":"Senago","state_id":"1847"},
    {"city_id":"23127","city_name":"Seregno","state_id":"1847"},
    {"city_id":"23128","city_name":"Seriate","state_id":"1847"},
    {"city_id":"23129","city_name":"Sesto Calende","state_id":"1847"},
    {"city_id":"23130","city_name":"Sesto San Giovanni","state_id":"1847"},
    {"city_id":"23131","city_name":"Settimo Milanese","state_id":"1847"},
    {"city_id":"23132","city_name":"Seveso","state_id":"1847"},
    {"city_id":"23133","city_name":"Sirmione","state_id":"1847"},
    {"city_id":"23134","city_name":"Solaro","state_id":"1847"},
    {"city_id":"23135","city_name":"Somma Lombardo","state_id":"1847"},
    {"city_id":"23136","city_name":"Sondrio","state_id":"1847"},
    {"city_id":"23137","city_name":"Soresina","state_id":"1847"},
    {"city_id":"23138","city_name":"Sorisole","state_id":"1847"},
    {"city_id":"23139","city_name":"Stezzano","state_id":"1847"},
    {"city_id":"23140","city_name":"Stradella","state_id":"1847"},
    {"city_id":"23141","city_name":"Suzzara","state_id":"1847"},
    {"city_id":"23142","city_name":"Tirano","state_id":"1847"},
    {"city_id":"23143","city_name":"Tornata","state_id":"1847"},
    {"city_id":"23144","city_name":"Tradate","state_id":"1847"},
    {"city_id":"23145","city_name":"Travagliato","state_id":"1847"},
    {"city_id":"23146","city_name":"Treviglio","state_id":"1847"},
    {"city_id":"23147","city_name":"Treviolo","state_id":"1847"},
    {"city_id":"23148","city_name":"Trezzano sul Naviglio","state_id":"1847"},
    {"city_id":"23149","city_name":"Trezzo","state_id":"1847"},
    {"city_id":"23150","city_name":"Tromello","state_id":"1847"},
    {"city_id":"23151","city_name":"Uboldo","state_id":"1847"},
    {"city_id":"23152","city_name":"Urgnano","state_id":"1847"},
    {"city_id":"23153","city_name":"Usmate Velate","state_id":"1847"},
    {"city_id":"23154","city_name":"Valmadrera","state_id":"1847"},
    {"city_id":"23155","city_name":"Varedo","state_id":"1847"},
    {"city_id":"23156","city_name":"Varese","state_id":"1847"},
    {"city_id":"23157","city_name":"Verano Brianza","state_id":"1847"},
    {"city_id":"23158","city_name":"Vergiate","state_id":"1847"},
    {"city_id":"23159","city_name":"Viadana","state_id":"1847"},
    {"city_id":"23160","city_name":"Vigevano","state_id":"1847"},
    {"city_id":"23161","city_name":"Vignate","state_id":"1847"},
    {"city_id":"23162","city_name":"Villa Carcina","state_id":"1847"},
    {"city_id":"23163","city_name":"Villa Guardia","state_id":"1847"},
    {"city_id":"23164","city_name":"Villasanta","state_id":"1847"},
    {"city_id":"23165","city_name":"Vimercate","state_id":"1847"},
    {"city_id":"23166","city_name":"Vimodrone","state_id":"1847"},
    {"city_id":"23167","city_name":"Virgilio","state_id":"1847"},
    {"city_id":"23168","city_name":"Voghera","state_id":"1847"},
    {"city_id":"23169","city_name":"Zibido San Giacomo","state_id":"1847"},
    {"city_id":"23170","city_name":"Zogno","state_id":"1847"},
    {"city_id":"23171","city_name":"Barasso","state_id":"1848"},
    {"city_id":"23172","city_name":"Bergamo","state_id":"1848"},
    {"city_id":"23173","city_name":"Bolladello","state_id":"1848"},
    {"city_id":"23174","city_name":"Capergnanica","state_id":"1848"},
    {"city_id":"23175","city_name":"Costa Masnaga","state_id":"1848"},
    {"city_id":"23176","city_name":"Medolago","state_id":"1848"},
    {"city_id":"23177","city_name":"Nibionno","state_id":"1848"},
    {"city_id":"23178","city_name":"Rodano","state_id":"1848"},
    {"city_id":"23179","city_name":"Sordio","state_id":"1848"},
    {"city_id":"23180","city_name":"Torre d'Isola","state_id":"1848"},
    {"city_id":"23181","city_name":"Varese","state_id":"1848"},
    {"city_id":"23182","city_name":"Villongo","state_id":"1848"},
    {"city_id":"23183","city_name":"milan","state_id":"1848"},
    {"city_id":"23184","city_name":"Colmurano","state_id":"1849"},
    {"city_id":"23185","city_name":"Monte San Giusto","state_id":"1849"},
    {"city_id":"23186","city_name":"Castel","state_id":"1850"},
    {"city_id":"23187","city_name":"Gazoldo","state_id":"1850"},
    {"city_id":"23188","city_name":"Marmirolo","state_id":"1850"},
    {"city_id":"23189","city_name":"Monzambano","state_id":"1850"},
    {"city_id":"23190","city_name":"Ostiglia","state_id":"1850"},
    {"city_id":"23191","city_name":"Pegognaga","state_id":"1850"},
    {"city_id":"23192","city_name":"Poggio Rusco","state_id":"1850"},
    {"city_id":"23193","city_name":"Quistello","state_id":"1850"},
    {"city_id":"23194","city_name":"Roverbella","state_id":"1850"},
    {"city_id":"23195","city_name":"Suzzara","state_id":"1850"},
    {"city_id":"23196","city_name":"Ancona","state_id":"1851"},
    {"city_id":"23197","city_name":"Ascoli Piceno","state_id":"1851"},
    {"city_id":"23198","city_name":"Barchi","state_id":"1851"},
    {"city_id":"23199","city_name":"Cagli","state_id":"1851"},
    {"city_id":"23200","city_name":"Castelfidardo","state_id":"1851"},
    {"city_id":"23201","city_name":"Chiaravalle","state_id":"1851"},
    {"city_id":"23202","city_name":"Cingoli","state_id":"1851"},
    {"city_id":"23203","city_name":"Civitanova Marche","state_id":"1851"},
    {"city_id":"23204","city_name":"Corridonia","state_id":"1851"},
    {"city_id":"23205","city_name":"Fabriano","state_id":"1851"},
    {"city_id":"23206","city_name":"Falconara Marittima","state_id":"1851"},
    {"city_id":"23207","city_name":"Fano","state_id":"1851"},
    {"city_id":"23208","city_name":"Fermo","state_id":"1851"},
    {"city_id":"23209","city_name":"Filottrano","state_id":"1851"},
    {"city_id":"23210","city_name":"Folignano","state_id":"1851"},
    {"city_id":"23211","city_name":"Fossombrone","state_id":"1851"},
    {"city_id":"23212","city_name":"Grottammare","state_id":"1851"},
    {"city_id":"23213","city_name":"Jesi","state_id":"1851"},
    {"city_id":"23214","city_name":"Loreto","state_id":"1851"},
    {"city_id":"23215","city_name":"Macerata","state_id":"1851"},
    {"city_id":"23216","city_name":"Matelica","state_id":"1851"},
    {"city_id":"23217","city_name":"Mondavio","state_id":"1851"},
    {"city_id":"23218","city_name":"Mondolfo","state_id":"1851"},
    {"city_id":"23219","city_name":"Montappone","state_id":"1851"},
    {"city_id":"23220","city_name":"Montecosaro","state_id":"1851"},
    {"city_id":"23221","city_name":"Montegranaro","state_id":"1851"},
    {"city_id":"23222","city_name":"Montemarciano","state_id":"1851"},
    {"city_id":"23223","city_name":"Monteprandone","state_id":"1851"},
    {"city_id":"23224","city_name":"Morrovalle","state_id":"1851"},
    {"city_id":"23225","city_name":"Osimo","state_id":"1851"},
    {"city_id":"23226","city_name":"Pesaro","state_id":"1851"},
    {"city_id":"23227","city_name":"Polverigi","state_id":"1851"},
    {"city_id":"23228","city_name":"Porto Recanati","state_id":"1851"},
    {"city_id":"23229","city_name":"Porto San Giorgio","state_id":"1851"},
    {"city_id":"23230","city_name":"Porto Sant'Elpidio","state_id":"1851"},
    {"city_id":"23231","city_name":"Potenza Picena","state_id":"1851"},
    {"city_id":"23232","city_name":"Recanati","state_id":"1851"},
    {"city_id":"23233","city_name":"San Benedetto del Tronto","state_id":"1851"},
    {"city_id":"23234","city_name":"San Severino Marche","state_id":"1851"},
    {"city_id":"23235","city_name":"Sant'Elpidio a Mare","state_id":"1851"},
    {"city_id":"23236","city_name":"Senigallia","state_id":"1851"},
    {"city_id":"23237","city_name":"Tolentino","state_id":"1851"},
    {"city_id":"23238","city_name":"Treia","state_id":"1851"},
    {"city_id":"23239","city_name":"Urbino","state_id":"1851"},
    {"city_id":"23240","city_name":"Cumiana","state_id":"1852"},
    {"city_id":"23241","city_name":"Giammoro","state_id":"1852"},
    {"city_id":"23242","city_name":"Assago","state_id":"1853"},
    {"city_id":"23243","city_name":"Besana in Brianza","state_id":"1853"},
    {"city_id":"23244","city_name":"Biassono","state_id":"1853"},
    {"city_id":"23245","city_name":"Burago Molgora","state_id":"1853"},
    {"city_id":"23246","city_name":"Cernusco Sul Naviglio","state_id":"1853"},
    {"city_id":"23247","city_name":"Cologno Monzese","state_id":"1853"},
    {"city_id":"23248","city_name":"Concorezzo","state_id":"1853"},
    {"city_id":"23249","city_name":"Cornaredo","state_id":"1853"},
    {"city_id":"23250","city_name":"Cuggiono","state_id":"1853"},
    {"city_id":"23251","city_name":"Cusago","state_id":"1853"},
    {"city_id":"23252","city_name":"Foro Buonaparte","state_id":"1853"},
    {"city_id":"23253","city_name":"Gessate","state_id":"1853"},
    {"city_id":"23254","city_name":"Gorgonzola","state_id":"1853"},
    {"city_id":"23255","city_name":"Liscate","state_id":"1853"},
    {"city_id":"23256","city_name":"Magenta","state_id":"1853"},
    {"city_id":"23257","city_name":"Milan","state_id":"1853"},
    {"city_id":"23258","city_name":"Noviglio","state_id":"1853"},
    {"city_id":"23259","city_name":"Passirana Di Rho","state_id":"1853"},
    {"city_id":"23260","city_name":"Pregnana Milane","state_id":"1853"},
    {"city_id":"23261","city_name":"San Donato Milanese","state_id":"1853"},
    {"city_id":"23262","city_name":"Seregno","state_id":"1853"},
    {"city_id":"23263","city_name":"Trezzo Sull'adda","state_id":"1853"},
    {"city_id":"23264","city_name":"Tribiano","state_id":"1853"},
    {"city_id":"23265","city_name":"Vaprio d'Adda","state_id":"1853"},
    {"city_id":"23266","city_name":"Vermezzo","state_id":"1853"},
    {"city_id":"23267","city_name":"Bomporto","state_id":"1854"},
    {"city_id":"23268","city_name":"Campogalliano","state_id":"1854"},
    {"city_id":"23269","city_name":"Cavezzo","state_id":"1854"},
    {"city_id":"23270","city_name":"Medolla","state_id":"1854"},
    {"city_id":"23271","city_name":"Nonantola","state_id":"1854"},
    {"city_id":"23272","city_name":"San Possidonio","state_id":"1854"},
    {"city_id":"23273","city_name":"Sassuolo","state_id":"1854"},
    {"city_id":"23274","city_name":"Spilamberto","state_id":"1854"},
    {"city_id":"23275","city_name":"Bojano","state_id":"1855"},
    {"city_id":"23276","city_name":"Campobasso","state_id":"1855"},
    {"city_id":"23277","city_name":"Cantalupo","state_id":"1855"},
    {"city_id":"23278","city_name":"Isernia","state_id":"1855"},
    {"city_id":"23279","city_name":"Termoli","state_id":"1855"},
    {"city_id":"23280","city_name":"Venafro","state_id":"1855"},
    {"city_id":"23281","city_name":"Montenegro","state_id":"1857"},
    {"city_id":"23282","city_name":"Forio d'Ischia","state_id":"1859"},
    {"city_id":"23283","city_name":"Bogogno","state_id":"1860"},
    {"city_id":"23284","city_name":"Invorio","state_id":"1860"},
    {"city_id":"23285","city_name":"Pombia","state_id":"1860"},
    {"city_id":"23286","city_name":"Bagnoli di Sopra","state_id":"1861"},
    {"city_id":"23287","city_name":"Bovolenta","state_id":"1861"},
    {"city_id":"23288","city_name":"Casale Di Scodosia","state_id":"1861"},
    {"city_id":"23289","city_name":"Cervarese Santa Croce","state_id":"1861"},
    {"city_id":"23290","city_name":"Fontaniva","state_id":"1861"},
    {"city_id":"23291","city_name":"Galliera Veneta","state_id":"1861"},
    {"city_id":"23292","city_name":"Legnaro","state_id":"1861"},
    {"city_id":"23293","city_name":"Limena","state_id":"1861"},
    {"city_id":"23294","city_name":"Loreggia","state_id":"1861"},
    {"city_id":"23295","city_name":"Massanzago","state_id":"1861"},
    {"city_id":"23296","city_name":"Onara","state_id":"1861"},
    {"city_id":"23297","city_name":"Ponso","state_id":"1861"},
    {"city_id":"23298","city_name":"Portogallo","state_id":"1861"},
    {"city_id":"23299","city_name":"Tribano","state_id":"1861"},
    {"city_id":"23300","city_name":"Baganzola","state_id":"1862"},
    {"city_id":"23301","city_name":"Busseto","state_id":"1862"},
    {"city_id":"23302","city_name":"Casale Di Mezzani","state_id":"1862"},
    {"city_id":"23303","city_name":"Fontevivo","state_id":"1862"},
    {"city_id":"23304","city_name":"Solignano","state_id":"1862"},
    {"city_id":"23305","city_name":"Torrile","state_id":"1862"},
    {"city_id":"23306","city_name":"Codevilla","state_id":"1863"},
    {"city_id":"23307","city_name":"Marcignago","state_id":"1863"},
    {"city_id":"23308","city_name":"Pavia","state_id":"1863"},
    {"city_id":"23309","city_name":"Siziano","state_id":"1863"},
    {"city_id":"23310","city_name":"Pianello","state_id":"1864"},
    {"city_id":"23311","city_name":"Ponte Felcino","state_id":"1864"},
    {"city_id":"23312","city_name":"Zanica","state_id":"1864"},
    {"city_id":"23313","city_name":"Gradara","state_id":"1865"},
    {"city_id":"23314","city_name":"Monte Porzio","state_id":"1865"},
    {"city_id":"23315","city_name":"Pergola","state_id":"1865"},
    {"city_id":"23316","city_name":"Tavullia","state_id":"1865"},
    {"city_id":"23317","city_name":"Alseno","state_id":"1866"},
    {"city_id":"23318","city_name":"Gossolengo","state_id":"1866"},
    {"city_id":"23319","city_name":"Vigolzone","state_id":"1866"},
    {"city_id":"23320","city_name":"Armeno","state_id":"1867"},
    {"city_id":"23321","city_name":"Bergamasco","state_id":"1867"},
    {"city_id":"23322","city_name":"Caselette","state_id":"1867"},
    {"city_id":"23323","city_name":"Rosta","state_id":"1867"},
    {"city_id":"23324","city_name":"San Damiano","state_id":"1867"},
    {"city_id":"23325","city_name":"Spinetta Marengo","state_id":"1867"},
    {"city_id":"23326","city_name":"Acqui Terme","state_id":"1868"},
    {"city_id":"23327","city_name":"Alba","state_id":"1868"},
    {"city_id":"23328","city_name":"Alessandria","state_id":"1868"},
    {"city_id":"23329","city_name":"Alpignano","state_id":"1868"},
    {"city_id":"23330","city_name":"Andezeno","state_id":"1868"},
    {"city_id":"23331","city_name":"Andonno","state_id":"1868"},
    {"city_id":"23332","city_name":"Arona","state_id":"1868"},
    {"city_id":"23333","city_name":"Asti","state_id":"1868"},
    {"city_id":"23334","city_name":"Avigliana","state_id":"1868"},
    {"city_id":"23335","city_name":"Baveno","state_id":"1868"},
    {"city_id":"23336","city_name":"Beinasco","state_id":"1868"},
    {"city_id":"23337","city_name":"Bellinzago Novarese","state_id":"1868"},
    {"city_id":"23338","city_name":"Biella","state_id":"1868"},
    {"city_id":"23339","city_name":"Borgaro Torinese","state_id":"1868"},
    {"city_id":"23340","city_name":"Borgo San Dalmazzo","state_id":"1868"},
    {"city_id":"23341","city_name":"Borgomanero","state_id":"1868"},
    {"city_id":"23342","city_name":"Borgosesia","state_id":"1868"},
    {"city_id":"23343","city_name":"Boves","state_id":"1868"},
    {"city_id":"23344","city_name":"Bra","state_id":"1868"},
    {"city_id":"23345","city_name":"Busca","state_id":"1868"},
    {"city_id":"23346","city_name":"Cameri","state_id":"1868"},
    {"city_id":"23347","city_name":"Canelli","state_id":"1868"},
    {"city_id":"23348","city_name":"Carignano","state_id":"1868"},
    {"city_id":"23349","city_name":"Carmagnola","state_id":"1868"},
    {"city_id":"23350","city_name":"Casale Monferrato","state_id":"1868"},
    {"city_id":"23351","city_name":"Caselle Torinese","state_id":"1868"},
    {"city_id":"23352","city_name":"Castellamonte","state_id":"1868"},
    {"city_id":"23353","city_name":"Castelletto sopra Ticino","state_id":"1868"},
    {"city_id":"23354","city_name":"Chieri","state_id":"1868"},
    {"city_id":"23355","city_name":"Chivasso","state_id":"1868"},
    {"city_id":"23356","city_name":"Cirie","state_id":"1868"},
    {"city_id":"23357","city_name":"Collegno","state_id":"1868"},
    {"city_id":"23358","city_name":"Cossato","state_id":"1868"},
    {"city_id":"23359","city_name":"Cuneo","state_id":"1868"},
    {"city_id":"23360","city_name":"Cuorgne","state_id":"1868"},
    {"city_id":"23361","city_name":"Domodossola","state_id":"1868"},
    {"city_id":"23362","city_name":"Druento","state_id":"1868"},
    {"city_id":"23363","city_name":"Fossano","state_id":"1868"},
    {"city_id":"23364","city_name":"Galliate","state_id":"1868"},
    {"city_id":"23365","city_name":"Gassino Torinese","state_id":"1868"},
    {"city_id":"23366","city_name":"Gattinara","state_id":"1868"},
    {"city_id":"23367","city_name":"Giaveno","state_id":"1868"},
    {"city_id":"23368","city_name":"Grugliasco","state_id":"1868"},
    {"city_id":"23369","city_name":"Ivrea","state_id":"1868"},
    {"city_id":"23370","city_name":"Leini","state_id":"1868"},
    {"city_id":"23371","city_name":"LusigliÃƒÂ¨","state_id":"1868"},
    {"city_id":"23372","city_name":"Marano Ticino","state_id":"1868"},
    {"city_id":"23373","city_name":"Mergozzo","state_id":"1868"},
    {"city_id":"23374","city_name":"Moncalieri","state_id":"1868"},
    {"city_id":"23375","city_name":"Mondovi","state_id":"1868"},
    {"city_id":"23376","city_name":"Mongrando","state_id":"1868"},
    {"city_id":"23377","city_name":"Nichelino","state_id":"1868"},
    {"city_id":"23378","city_name":"Nizza Monferrato","state_id":"1868"},
    {"city_id":"23379","city_name":"Novara","state_id":"1868"},
    {"city_id":"23380","city_name":"Novi Ligure","state_id":"1868"},
    {"city_id":"23381","city_name":"Oleggio","state_id":"1868"},
    {"city_id":"23382","city_name":"Omegna","state_id":"1868"},
    {"city_id":"23383","city_name":"Orbassano","state_id":"1868"},
    {"city_id":"23384","city_name":"Ovada","state_id":"1868"},
    {"city_id":"23385","city_name":"Pianezza","state_id":"1868"},
    {"city_id":"23386","city_name":"Pinerolo","state_id":"1868"},
    {"city_id":"23387","city_name":"Pino Torinese","state_id":"1868"},
    {"city_id":"23388","city_name":"Piossasco","state_id":"1868"},
    {"city_id":"23389","city_name":"Poirino","state_id":"1868"},
    {"city_id":"23390","city_name":"Racconigi","state_id":"1868"},
    {"city_id":"23391","city_name":"Rivalta di Torino","state_id":"1868"},
    {"city_id":"23392","city_name":"Rivarolo Canavese","state_id":"1868"},
    {"city_id":"23393","city_name":"Rivoli","state_id":"1868"},
    {"city_id":"23394","city_name":"Saluzzo","state_id":"1868"},
    {"city_id":"23395","city_name":"San Maurizio","state_id":"1868"},
    {"city_id":"23396","city_name":"San Mauro Torinese","state_id":"1868"},
    {"city_id":"23397","city_name":"Sandigliano","state_id":"1868"},
    {"city_id":"23398","city_name":"Santena","state_id":"1868"},
    {"city_id":"23399","city_name":"Santhia","state_id":"1868"},
    {"city_id":"23400","city_name":"Savigliano","state_id":"1868"},
    {"city_id":"23401","city_name":"Settimo Torinese","state_id":"1868"},
    {"city_id":"23402","city_name":"Torino","state_id":"1868"},
    {"city_id":"23403","city_name":"Tortona","state_id":"1868"},
    {"city_id":"23404","city_name":"Trecate","state_id":"1868"},
    {"city_id":"23405","city_name":"Trofarello","state_id":"1868"},
    {"city_id":"23406","city_name":"Valduggia","state_id":"1868"},
    {"city_id":"23407","city_name":"Valenza","state_id":"1868"},
    {"city_id":"23408","city_name":"Venaria Reale","state_id":"1868"},
    {"city_id":"23409","city_name":"Verbania","state_id":"1868"},
    {"city_id":"23410","city_name":"Vercelli","state_id":"1868"},
    {"city_id":"23411","city_name":"Vigliano Biellese","state_id":"1868"},
    {"city_id":"23412","city_name":"Vinovo","state_id":"1868"},
    {"city_id":"23413","city_name":"Volpiano","state_id":"1868"},
    {"city_id":"23414","city_name":"Perignano","state_id":"1869"},
    {"city_id":"23415","city_name":"Ponte a Egola","state_id":"1869"},
    {"city_id":"23416","city_name":"San Quirino","state_id":"1870"},
    {"city_id":"23417","city_name":"Latronico","state_id":"1871"},
    {"city_id":"23418","city_name":"Acquaviva delle Fonti","state_id":"1872"},
    {"city_id":"23419","city_name":"Adelfia","state_id":"1872"},
    {"city_id":"23420","city_name":"Alberobello","state_id":"1872"},
    {"city_id":"23421","city_name":"Altamura","state_id":"1872"},
    {"city_id":"23422","city_name":"Andria","state_id":"1872"},
    {"city_id":"23423","city_name":"Apricena","state_id":"1872"},
    {"city_id":"23424","city_name":"Aradeo","state_id":"1872"},
    {"city_id":"23425","city_name":"Bari","state_id":"1872"},
    {"city_id":"23426","city_name":"Barletta","state_id":"1872"},
    {"city_id":"23427","city_name":"Bisceglie","state_id":"1872"},
    {"city_id":"23428","city_name":"Bitetto","state_id":"1872"},
    {"city_id":"23429","city_name":"Bitonto","state_id":"1872"},
    {"city_id":"23430","city_name":"Bitritto","state_id":"1872"},
    {"city_id":"23431","city_name":"Brindisi","state_id":"1872"},
    {"city_id":"23432","city_name":"Campi Salentina","state_id":"1872"},
    {"city_id":"23433","city_name":"Canosa di Puglia","state_id":"1872"},
    {"city_id":"23434","city_name":"Capurso","state_id":"1872"},
    {"city_id":"23435","city_name":"Carmiano","state_id":"1872"},
    {"city_id":"23436","city_name":"Carovigno","state_id":"1872"},
    {"city_id":"23437","city_name":"Casamassima","state_id":"1872"},
    {"city_id":"23438","city_name":"Casarano","state_id":"1872"},
    {"city_id":"23439","city_name":"Cassano delle Murge","state_id":"1872"},
    {"city_id":"23440","city_name":"Castellana Grotte","state_id":"1872"},
    {"city_id":"23441","city_name":"Castellaneta","state_id":"1872"},
    {"city_id":"23442","city_name":"Cavallino","state_id":"1872"},
    {"city_id":"23443","city_name":"Ceglie Messapica","state_id":"1872"},
    {"city_id":"23444","city_name":"Cerignola","state_id":"1872"},
    {"city_id":"23445","city_name":"Cisternino","state_id":"1872"},
    {"city_id":"23446","city_name":"Conversano","state_id":"1872"},
    {"city_id":"23447","city_name":"Copertino","state_id":"1872"},
    {"city_id":"23448","city_name":"Corato","state_id":"1872"},
    {"city_id":"23449","city_name":"Crispiano","state_id":"1872"},
    {"city_id":"23450","city_name":"Cutrofiano","state_id":"1872"},
    {"city_id":"23451","city_name":"Erchie","state_id":"1872"},
    {"city_id":"23452","city_name":"Fasano","state_id":"1872"},
    {"city_id":"23453","city_name":"Foggia","state_id":"1872"},
    {"city_id":"23454","city_name":"Francavilla Fontana","state_id":"1872"},
    {"city_id":"23455","city_name":"Galatina","state_id":"1872"},
    {"city_id":"23456","city_name":"Galatone","state_id":"1872"},
    {"city_id":"23457","city_name":"Gallipoli","state_id":"1872"},
    {"city_id":"23458","city_name":"Ginosa","state_id":"1872"},
    {"city_id":"23459","city_name":"Gioia del Colle","state_id":"1872"},
    {"city_id":"23460","city_name":"Giovinazzo","state_id":"1872"},
    {"city_id":"23461","city_name":"Gravina in Puglia","state_id":"1872"},
    {"city_id":"23462","city_name":"Grottaglie","state_id":"1872"},
    {"city_id":"23463","city_name":"Grumo Appula","state_id":"1872"},
    {"city_id":"23464","city_name":"Laterza","state_id":"1872"},
    {"city_id":"23465","city_name":"Latiano","state_id":"1872"},
    {"city_id":"23466","city_name":"Lecce","state_id":"1872"},
    {"city_id":"23467","city_name":"Leverano","state_id":"1872"},
    {"city_id":"23468","city_name":"Lizzanello","state_id":"1872"},
    {"city_id":"23469","city_name":"Lizzano","state_id":"1872"},
    {"city_id":"23470","city_name":"Locorotondo","state_id":"1872"},
    {"city_id":"23471","city_name":"Lucera","state_id":"1872"},
    {"city_id":"23472","city_name":"Maglie","state_id":"1872"},
    {"city_id":"23473","city_name":"Manduria","state_id":"1872"},
    {"city_id":"23474","city_name":"Manfredonia","state_id":"1872"},
    {"city_id":"23475","city_name":"Margherita di Savoia","state_id":"1872"},
    {"city_id":"23476","city_name":"Martano","state_id":"1872"},
    {"city_id":"23477","city_name":"Martina Franca","state_id":"1872"},
    {"city_id":"23478","city_name":"Massafra","state_id":"1872"},
    {"city_id":"23479","city_name":"Matino","state_id":"1872"},
    {"city_id":"23480","city_name":"Melendugno","state_id":"1872"},
    {"city_id":"23481","city_name":"Mesagne","state_id":"1872"},
    {"city_id":"23482","city_name":"Minervino Murge","state_id":"1872"},
    {"city_id":"23483","city_name":"Modugno","state_id":"1872"},
    {"city_id":"23484","city_name":"Mola di Bari","state_id":"1872"},
    {"city_id":"23485","city_name":"Molfetta","state_id":"1872"},
    {"city_id":"23486","city_name":"Monopoli","state_id":"1872"},
    {"city_id":"23487","city_name":"Monte Sant'Angelo","state_id":"1872"},
    {"city_id":"23488","city_name":"Monteroni di Lecce","state_id":"1872"},
    {"city_id":"23489","city_name":"Mottola","state_id":"1872"},
    {"city_id":"23490","city_name":"Nardo","state_id":"1872"},
    {"city_id":"23491","city_name":"Neviano","state_id":"1872"},
    {"city_id":"23492","city_name":"Noci","state_id":"1872"},
    {"city_id":"23493","city_name":"Noicattaro","state_id":"1872"},
    {"city_id":"23494","city_name":"Novoli","state_id":"1872"},
    {"city_id":"23495","city_name":"Oria","state_id":"1872"},
    {"city_id":"23496","city_name":"Orta Nova","state_id":"1872"},
    {"city_id":"23497","city_name":"Ostuni","state_id":"1872"},
    {"city_id":"23498","city_name":"Palagiano","state_id":"1872"},
    {"city_id":"23499","city_name":"Palo del Colle","state_id":"1872"},
    {"city_id":"23500","city_name":"Parabita","state_id":"1872"},
    {"city_id":"23501","city_name":"Polignano a Mare","state_id":"1872"},
    {"city_id":"23502","city_name":"Pulsano","state_id":"1872"},
    {"city_id":"23503","city_name":"Putignano","state_id":"1872"},
    {"city_id":"23504","city_name":"Putignano,","state_id":"1872"},
    {"city_id":"23505","city_name":"Racale","state_id":"1872"},
    {"city_id":"23506","city_name":"Ruffano","state_id":"1872"},
    {"city_id":"23507","city_name":"Rutigliano","state_id":"1872"},
    {"city_id":"23508","city_name":"Ruvo di Puglia","state_id":"1872"},
    {"city_id":"23509","city_name":"Salice Salentino","state_id":"1872"},
    {"city_id":"23510","city_name":"San Ferdinando di Puglia","state_id":"1872"},
    {"city_id":"23511","city_name":"San Giorgio Ionico","state_id":"1872"},
    {"city_id":"23512","city_name":"San Giovanni Rotondo","state_id":"1872"},
    {"city_id":"23513","city_name":"San Marco in Lamis","state_id":"1872"},
    {"city_id":"23514","city_name":"San Marzano di San Giuseppe","state_id":"1872"},
    {"city_id":"23515","city_name":"San Nicandro Garganico","state_id":"1872"},
    {"city_id":"23516","city_name":"San Pancrazio Salentino","state_id":"1872"},
    {"city_id":"23517","city_name":"San Pietro Vernotico","state_id":"1872"},
    {"city_id":"23518","city_name":"San Severo","state_id":"1872"},
    {"city_id":"23519","city_name":"San Vito dei Normanni","state_id":"1872"},
    {"city_id":"23520","city_name":"Sannicandro di Bari","state_id":"1872"},
    {"city_id":"23521","city_name":"Santeramo in Colle","state_id":"1872"},
    {"city_id":"23522","city_name":"Sava","state_id":"1872"},
    {"city_id":"23523","city_name":"Squinzano","state_id":"1872"},
    {"city_id":"23524","city_name":"Statte","state_id":"1872"},
    {"city_id":"23525","city_name":"Surbo","state_id":"1872"},
    {"city_id":"23526","city_name":"Taranto","state_id":"1872"},
    {"city_id":"23527","city_name":"Taurisano","state_id":"1872"},
    {"city_id":"23528","city_name":"Taviano","state_id":"1872"},
    {"city_id":"23529","city_name":"Terlizzi","state_id":"1872"},
    {"city_id":"23530","city_name":"Toritto","state_id":"1872"},
    {"city_id":"23531","city_name":"Torre Santa Susanna","state_id":"1872"},
    {"city_id":"23532","city_name":"Torremaggiore","state_id":"1872"},
    {"city_id":"23533","city_name":"Trani","state_id":"1872"},
    {"city_id":"23534","city_name":"Trepuzzi","state_id":"1872"},
    {"city_id":"23535","city_name":"Tricase","state_id":"1872"},
    {"city_id":"23536","city_name":"Triggiano","state_id":"1872"},
    {"city_id":"23537","city_name":"Trinitapoli","state_id":"1872"},
    {"city_id":"23538","city_name":"Turi","state_id":"1872"},
    {"city_id":"23539","city_name":"Ugento","state_id":"1872"},
    {"city_id":"23540","city_name":"Valenzano","state_id":"1872"},
    {"city_id":"23541","city_name":"Veglie","state_id":"1872"},
    {"city_id":"23542","city_name":"Vico del Gargano","state_id":"1872"},
    {"city_id":"23543","city_name":"Vieste","state_id":"1872"},
    {"city_id":"23544","city_name":"Villa Castelli","state_id":"1872"},
    {"city_id":"23545","city_name":"San Giovanni in Marignano","state_id":"1874"},
    {"city_id":"23546","city_name":"Torriana","state_id":"1874"},
    {"city_id":"23547","city_name":"Anzio","state_id":"1875"},
    {"city_id":"23548","city_name":"Atena Lucana","state_id":"1876"},
    {"city_id":"23549","city_name":"Castel San Giorgio","state_id":"1876"},
    {"city_id":"23550","city_name":"Fisciano","state_id":"1876"},
    {"city_id":"23551","city_name":"Giungano","state_id":"1876"},
    {"city_id":"23552","city_name":"Omignano","state_id":"1876"},
    {"city_id":"23553","city_name":"Alghero","state_id":"1877"},
    {"city_id":"23554","city_name":"Arzachena","state_id":"1877"},
    {"city_id":"23555","city_name":"Assemini","state_id":"1877"},
    {"city_id":"23556","city_name":"Cabras","state_id":"1877"},
    {"city_id":"23557","city_name":"Cagliari","state_id":"1877"},
    {"city_id":"23558","city_name":"Capoterra","state_id":"1877"},
    {"city_id":"23559","city_name":"Carbonia","state_id":"1877"},
    {"city_id":"23560","city_name":"Dorgali","state_id":"1877"},
    {"city_id":"23561","city_name":"Guspini","state_id":"1877"},
    {"city_id":"23562","city_name":"Iglesias","state_id":"1877"},
    {"city_id":"23563","city_name":"Ittiri","state_id":"1877"},
    {"city_id":"23564","city_name":"La Maddalena","state_id":"1877"},
    {"city_id":"23565","city_name":"Macomer","state_id":"1877"},
    {"city_id":"23566","city_name":"Monserrato","state_id":"1877"},
    {"city_id":"23567","city_name":"Nuoro","state_id":"1877"},
    {"city_id":"23568","city_name":"Olbia","state_id":"1877"},
    {"city_id":"23569","city_name":"Oristano","state_id":"1877"},
    {"city_id":"23570","city_name":"Ozieri","state_id":"1877"},
    {"city_id":"23571","city_name":"Porto Torres","state_id":"1877"},
    {"city_id":"23572","city_name":"Quartu Sant'Elena","state_id":"1877"},
    {"city_id":"23573","city_name":"Quartucciu","state_id":"1877"},
    {"city_id":"23574","city_name":"San Gavino Monreale","state_id":"1877"},
    {"city_id":"23575","city_name":"Sanluri","state_id":"1877"},
    {"city_id":"23576","city_name":"Sant'Antioco","state_id":"1877"},
    {"city_id":"23577","city_name":"Sassari","state_id":"1877"},
    {"city_id":"23578","city_name":"Selargius","state_id":"1877"},
    {"city_id":"23579","city_name":"Serramanna","state_id":"1877"},
    {"city_id":"23580","city_name":"Sestu","state_id":"1877"},
    {"city_id":"23581","city_name":"Siniscola","state_id":"1877"},
    {"city_id":"23582","city_name":"Sinnai","state_id":"1877"},
    {"city_id":"23583","city_name":"Sorso","state_id":"1877"},
    {"city_id":"23584","city_name":"Tempio Pausania","state_id":"1877"},
    {"city_id":"23585","city_name":"Terralba","state_id":"1877"},
    {"city_id":"23586","city_name":"Tortoli","state_id":"1877"},
    {"city_id":"23587","city_name":"Villacidro","state_id":"1877"},
    {"city_id":"23588","city_name":"Nule","state_id":"1878"},
    {"city_id":"23589","city_name":"Altare","state_id":"1879"},
    {"city_id":"23590","city_name":"Aci Castello","state_id":"1880"},
    {"city_id":"23591","city_name":"Aci Catena","state_id":"1880"},
    {"city_id":"23592","city_name":"Aci Sant'Antonio","state_id":"1880"},
    {"city_id":"23593","city_name":"Acireale","state_id":"1880"},
    {"city_id":"23594","city_name":"Adrano","state_id":"1880"},
    {"city_id":"23595","city_name":"Agira","state_id":"1880"},
    {"city_id":"23596","city_name":"Agrigento","state_id":"1880"},
    {"city_id":"23597","city_name":"Alcamo","state_id":"1880"},
    {"city_id":"23598","city_name":"Altofonte","state_id":"1880"},
    {"city_id":"23599","city_name":"Aragona","state_id":"1880"},
    {"city_id":"23600","city_name":"Augusta","state_id":"1880"},
    {"city_id":"23601","city_name":"Avola","state_id":"1880"},
    {"city_id":"23602","city_name":"Bagheria","state_id":"1880"},
    {"city_id":"23603","city_name":"Barcellona","state_id":"1880"},
    {"city_id":"23604","city_name":"Barrafranca","state_id":"1880"},
    {"city_id":"23605","city_name":"Belmonte Mezzagno","state_id":"1880"},
    {"city_id":"23606","city_name":"Belpasso","state_id":"1880"},
    {"city_id":"23607","city_name":"Biancavilla","state_id":"1880"},
    {"city_id":"23608","city_name":"Bronte","state_id":"1880"},
    {"city_id":"23609","city_name":"Caccamo","state_id":"1880"},
    {"city_id":"23610","city_name":"Caltagirone","state_id":"1880"},
    {"city_id":"23611","city_name":"Caltanissetta","state_id":"1880"},
    {"city_id":"23612","city_name":"Campobello di Licata","state_id":"1880"},
    {"city_id":"23613","city_name":"Campobello di Mazara","state_id":"1880"},
    {"city_id":"23614","city_name":"Canicatti","state_id":"1880"},
    {"city_id":"23615","city_name":"Capaci","state_id":"1880"},
    {"city_id":"23616","city_name":"Capo d'Orlando","state_id":"1880"},
    {"city_id":"23617","city_name":"Carini","state_id":"1880"},
    {"city_id":"23618","city_name":"Carlentini","state_id":"1880"},
    {"city_id":"23619","city_name":"Castelbuono","state_id":"1880"},
    {"city_id":"23620","city_name":"Casteldaccia","state_id":"1880"},
    {"city_id":"23621","city_name":"Castellammare del Golfo","state_id":"1880"},
    {"city_id":"23622","city_name":"Casteltermini","state_id":"1880"},
    {"city_id":"23623","city_name":"Castelvetrano","state_id":"1880"},
    {"city_id":"23624","city_name":"Catania","state_id":"1880"},
    {"city_id":"23625","city_name":"Catenanuova","state_id":"1880"},
    {"city_id":"23626","city_name":"Cefalu","state_id":"1880"},
    {"city_id":"23627","city_name":"Chiaramonte Gulfi","state_id":"1880"},
    {"city_id":"23628","city_name":"Cinisi","state_id":"1880"},
    {"city_id":"23629","city_name":"Comiso","state_id":"1880"},
    {"city_id":"23630","city_name":"Corleone","state_id":"1880"},
    {"city_id":"23631","city_name":"Enna","state_id":"1880"},
    {"city_id":"23632","city_name":"Erice","state_id":"1880"},
    {"city_id":"23633","city_name":"Favara","state_id":"1880"},
    {"city_id":"23634","city_name":"Ficarazzi","state_id":"1880"},
    {"city_id":"23635","city_name":"Fiumefreddo di Sicilia","state_id":"1880"},
    {"city_id":"23636","city_name":"Floridia","state_id":"1880"},
    {"city_id":"23637","city_name":"Francofonte","state_id":"1880"},
    {"city_id":"23638","city_name":"Gela","state_id":"1880"},
    {"city_id":"23639","city_name":"Giardini-Naxos","state_id":"1880"},
    {"city_id":"23640","city_name":"Giarre","state_id":"1880"},
    {"city_id":"23641","city_name":"Grammichele","state_id":"1880"},
    {"city_id":"23642","city_name":"Gravina di Catania","state_id":"1880"},
    {"city_id":"23643","city_name":"Ispica","state_id":"1880"},
    {"city_id":"23644","city_name":"Lentini","state_id":"1880"},
    {"city_id":"23645","city_name":"Leonforte","state_id":"1880"},
    {"city_id":"23646","city_name":"Licata","state_id":"1880"},
    {"city_id":"23647","city_name":"Lipari","state_id":"1880"},
    {"city_id":"23648","city_name":"Marsala","state_id":"1880"},
    {"city_id":"23649","city_name":"Mascali","state_id":"1880"},
    {"city_id":"23650","city_name":"Mascalucia","state_id":"1880"},
    {"city_id":"23651","city_name":"Mazara del Vallo","state_id":"1880"},
    {"city_id":"23652","city_name":"Mazzarino","state_id":"1880"},
    {"city_id":"23653","city_name":"Melilli","state_id":"1880"},
    {"city_id":"23654","city_name":"Menfi","state_id":"1880"},
    {"city_id":"23655","city_name":"Messina","state_id":"1880"},
    {"city_id":"23656","city_name":"Milazzo","state_id":"1880"},
    {"city_id":"23657","city_name":"Militello in Val di Catania","state_id":"1880"},
    {"city_id":"23658","city_name":"Misilmeri","state_id":"1880"},
    {"city_id":"23659","city_name":"Misterbianco","state_id":"1880"},
    {"city_id":"23660","city_name":"Modica","state_id":"1880"},
    {"city_id":"23661","city_name":"Monreale","state_id":"1880"},
    {"city_id":"23662","city_name":"Motta Sant'Anastasia","state_id":"1880"},
    {"city_id":"23663","city_name":"Mussomeli","state_id":"1880"},
    {"city_id":"23664","city_name":"Naro","state_id":"1880"},
    {"city_id":"23665","city_name":"Nicosia","state_id":"1880"},
    {"city_id":"23666","city_name":"Niscemi","state_id":"1880"},
    {"city_id":"23667","city_name":"Noto","state_id":"1880"},
    {"city_id":"23668","city_name":"Paceco","state_id":"1880"},
    {"city_id":"23669","city_name":"Pachino","state_id":"1880"},
    {"city_id":"23670","city_name":"Palagonia","state_id":"1880"},
    {"city_id":"23671","city_name":"Palazzolo Acreide","state_id":"1880"},
    {"city_id":"23672","city_name":"Palermo","state_id":"1880"},
    {"city_id":"23673","city_name":"Palma di Montechiaro","state_id":"1880"},
    {"city_id":"23674","city_name":"Partanna","state_id":"1880"},
    {"city_id":"23675","city_name":"Partinico","state_id":"1880"},
    {"city_id":"23676","city_name":"Paterno","state_id":"1880"},
    {"city_id":"23677","city_name":"Patti","state_id":"1880"},
    {"city_id":"23678","city_name":"Pedara","state_id":"1880"},
    {"city_id":"23679","city_name":"Piazza Armerina","state_id":"1880"},
    {"city_id":"23680","city_name":"Porto Empedocle","state_id":"1880"},
    {"city_id":"23681","city_name":"Pozzallo","state_id":"1880"},
    {"city_id":"23682","city_name":"Priolo Gargallo","state_id":"1880"},
    {"city_id":"23683","city_name":"Racalmuto","state_id":"1880"},
    {"city_id":"23684","city_name":"Raffadali","state_id":"1880"},
    {"city_id":"23685","city_name":"Ragusa","state_id":"1880"},
    {"city_id":"23686","city_name":"Ramacca","state_id":"1880"},
    {"city_id":"23687","city_name":"Randazzo","state_id":"1880"},
    {"city_id":"23688","city_name":"Ravanusa","state_id":"1880"},
    {"city_id":"23689","city_name":"Ribera","state_id":"1880"},
    {"city_id":"23690","city_name":"Riesi","state_id":"1880"},
    {"city_id":"23691","city_name":"Riposto","state_id":"1880"},
    {"city_id":"23692","city_name":"Rosolini","state_id":"1880"},
    {"city_id":"23693","city_name":"Salemi","state_id":"1880"},
    {"city_id":"23694","city_name":"San Cataldo","state_id":"1880"},
    {"city_id":"23695","city_name":"San Giovanni Gemini","state_id":"1880"},
    {"city_id":"23696","city_name":"San Giovanni la Punta","state_id":"1880"},
    {"city_id":"23697","city_name":"San Giuseppe Jato","state_id":"1880"},
    {"city_id":"23698","city_name":"San Gregorio di Catania","state_id":"1880"},
    {"city_id":"23699","city_name":"Sant'Agata di Militello","state_id":"1880"},
    {"city_id":"23700","city_name":"Sant'Agata li Battiati","state_id":"1880"},
    {"city_id":"23701","city_name":"Santa Croce Camerina","state_id":"1880"},
    {"city_id":"23702","city_name":"Santa Flavia","state_id":"1880"},
    {"city_id":"23703","city_name":"Santa Teresa di Riva","state_id":"1880"},
    {"city_id":"23704","city_name":"Sciacca","state_id":"1880"},
    {"city_id":"23705","city_name":"Scicli","state_id":"1880"},
    {"city_id":"23706","city_name":"Scordia","state_id":"1880"},
    {"city_id":"23707","city_name":"Siracusa","state_id":"1880"},
    {"city_id":"23708","city_name":"Sortino","state_id":"1880"},
    {"city_id":"23709","city_name":"Taormina","state_id":"1880"},
    {"city_id":"23710","city_name":"Termini Imerese","state_id":"1880"},
    {"city_id":"23711","city_name":"Terrasini","state_id":"1880"},
    {"city_id":"23712","city_name":"Trabia","state_id":"1880"},
    {"city_id":"23713","city_name":"Trapani","state_id":"1880"},
    {"city_id":"23714","city_name":"Trecastagni","state_id":"1880"},
    {"city_id":"23715","city_name":"Tremestieri Etneo","state_id":"1880"},
    {"city_id":"23716","city_name":"Troina","state_id":"1880"},
    {"city_id":"23717","city_name":"Valderice","state_id":"1880"},
    {"city_id":"23718","city_name":"Valguarnera Caropepe","state_id":"1880"},
    {"city_id":"23719","city_name":"Villabate","state_id":"1880"},
    {"city_id":"23720","city_name":"Villafranca Tirrena","state_id":"1880"},
    {"city_id":"23721","city_name":"Vittoria","state_id":"1880"},
    {"city_id":"23722","city_name":"Zafferana Etnea","state_id":"1880"},
    {"city_id":"23723","city_name":"Monteriggioni","state_id":"1881"},
    {"city_id":"23724","city_name":"Monteroni d Arbia","state_id":"1881"},
    {"city_id":"23725","city_name":"Delebio","state_id":"1882"},
    {"city_id":"23726","city_name":"Talamona","state_id":"1882"},
    {"city_id":"23727","city_name":"Faggiano","state_id":"1884"},
    {"city_id":"23728","city_name":"Riva del Garda","state_id":"1884"},
    {"city_id":"23729","city_name":"Castilenti","state_id":"1885"},
    {"city_id":"23730","city_name":"Controguerra","state_id":"1885"},
    {"city_id":"23731","city_name":"Bruino","state_id":"1886"},
    {"city_id":"23732","city_name":"Busano","state_id":"1886"},
    {"city_id":"23733","city_name":"Buttigliera Alta","state_id":"1886"},
    {"city_id":"23734","city_name":"Cavour","state_id":"1886"},
    {"city_id":"23735","city_name":"Chieri","state_id":"1886"},
    {"city_id":"23736","city_name":"Colleretto Giacosa","state_id":"1886"},
    {"city_id":"23737","city_name":"Cuceglio","state_id":"1886"},
    {"city_id":"23738","city_name":"Giaveno","state_id":"1886"},
    {"city_id":"23739","city_name":"Mazze","state_id":"1886"},
    {"city_id":"23740","city_name":"Mercenasco","state_id":"1886"},
    {"city_id":"23741","city_name":"Piobesi","state_id":"1886"},
    {"city_id":"23742","city_name":"Rivoli","state_id":"1886"},
    {"city_id":"23743","city_name":"Robassomero","state_id":"1886"},
    {"city_id":"23744","city_name":"Scarmagno","state_id":"1886"},
    {"city_id":"23745","city_name":"Strambino","state_id":"1886"},
    {"city_id":"23746","city_name":"Turin","state_id":"1886"},
    {"city_id":"23747","city_name":"Villar Perosa","state_id":"1886"},
    {"city_id":"23748","city_name":"Volpiano","state_id":"1886"},
    {"city_id":"23749","city_name":"Agliana","state_id":"1887"},
    {"city_id":"23750","city_name":"Altopascio","state_id":"1887"},
    {"city_id":"23751","city_name":"Anghiari","state_id":"1887"},
    {"city_id":"23752","city_name":"Arezzo","state_id":"1887"},
    {"city_id":"23753","city_name":"Aulla","state_id":"1887"},
    {"city_id":"23754","city_name":"Bagno a Ripoli","state_id":"1887"},
    {"city_id":"23755","city_name":"Barberino di Mugello","state_id":"1887"},
    {"city_id":"23756","city_name":"Barga","state_id":"1887"},
    {"city_id":"23757","city_name":"Bibbiena","state_id":"1887"},
    {"city_id":"23758","city_name":"Borgo San Lorenzo","state_id":"1887"},
    {"city_id":"23759","city_name":"Bucine","state_id":"1887"},
    {"city_id":"23760","city_name":"Buggiano","state_id":"1887"},
    {"city_id":"23761","city_name":"Calcinaia","state_id":"1887"},
    {"city_id":"23762","city_name":"Calenzano","state_id":"1887"},
    {"city_id":"23763","city_name":"Camaiore","state_id":"1887"},
    {"city_id":"23764","city_name":"Campi Bisenzio","state_id":"1887"},
    {"city_id":"23765","city_name":"Campiglia Marittima","state_id":"1887"},
    {"city_id":"23766","city_name":"Capannori","state_id":"1887"},
    {"city_id":"23767","city_name":"Cappelle","state_id":"1887"},
    {"city_id":"23768","city_name":"Capraia","state_id":"1887"},
    {"city_id":"23769","city_name":"Carmignano","state_id":"1887"},
    {"city_id":"23770","city_name":"Carrara","state_id":"1887"},
    {"city_id":"23771","city_name":"Cascina","state_id":"1887"},
    {"city_id":"23772","city_name":"Castagneto Carducci","state_id":"1887"},
    {"city_id":"23773","city_name":"Castelfiorentino","state_id":"1887"},
    {"city_id":"23774","city_name":"Castelfranco di Sotto","state_id":"1887"},
    {"city_id":"23775","city_name":"Castiglion Fiorentino","state_id":"1887"},
    {"city_id":"23776","city_name":"Cecina","state_id":"1887"},
    {"city_id":"23777","city_name":"Cerreto Guidi","state_id":"1887"},
    {"city_id":"23778","city_name":"Certaldo","state_id":"1887"},
    {"city_id":"23779","city_name":"Chiesina Uzzanese","state_id":"1887"},
    {"city_id":"23780","city_name":"Chiusi","state_id":"1887"},
    {"city_id":"23781","city_name":"Civitella in Val di Chiana","state_id":"1887"},
    {"city_id":"23782","city_name":"Colle di Val d'Elsa","state_id":"1887"},
    {"city_id":"23783","city_name":"Collesalvetti","state_id":"1887"},
    {"city_id":"23784","city_name":"Cortona","state_id":"1887"},
    {"city_id":"23785","city_name":"Empoli","state_id":"1887"},
    {"city_id":"23786","city_name":"Fiesole","state_id":"1887"},
    {"city_id":"23787","city_name":"Figline Valdarno","state_id":"1887"},
    {"city_id":"23788","city_name":"Firenze","state_id":"1887"},
    {"city_id":"23789","city_name":"Fivizzano","state_id":"1887"},
    {"city_id":"23790","city_name":"Florence","state_id":"1887"},
    {"city_id":"23791","city_name":"Foiano della Chiana","state_id":"1887"},
    {"city_id":"23792","city_name":"Follonica","state_id":"1887"},
    {"city_id":"23793","city_name":"Forte dei Marmi","state_id":"1887"},
    {"city_id":"23794","city_name":"Fucecchio","state_id":"1887"},
    {"city_id":"23795","city_name":"Gavorrano","state_id":"1887"},
    {"city_id":"23796","city_name":"Greve in Chianti","state_id":"1887"},
    {"city_id":"23797","city_name":"Grosseto","state_id":"1887"},
    {"city_id":"23798","city_name":"Impruneta","state_id":"1887"},
    {"city_id":"23799","city_name":"Lari","state_id":"1887"},
    {"city_id":"23800","city_name":"Lastra a Signa","state_id":"1887"},
    {"city_id":"23801","city_name":"Livorno","state_id":"1887"},
    {"city_id":"23802","city_name":"Lucca","state_id":"1887"},
    {"city_id":"23803","city_name":"Massa","state_id":"1887"},
    {"city_id":"23804","city_name":"Massa Marittima","state_id":"1887"},
    {"city_id":"23805","city_name":"Massarosa","state_id":"1887"},
    {"city_id":"23806","city_name":"Monsummano Terme","state_id":"1887"},
    {"city_id":"23807","city_name":"Montale","state_id":"1887"},
    {"city_id":"23808","city_name":"Monte Argentario","state_id":"1887"},
    {"city_id":"23809","city_name":"Monte San Savino","state_id":"1887"},
    {"city_id":"23810","city_name":"Montecatini-Terme","state_id":"1887"},
    {"city_id":"23811","city_name":"Montelupo Fiorentino","state_id":"1887"},
    {"city_id":"23812","city_name":"Montemurlo","state_id":"1887"},
    {"city_id":"23813","city_name":"Montepulciano","state_id":"1887"},
    {"city_id":"23814","city_name":"Montespertoli","state_id":"1887"},
    {"city_id":"23815","city_name":"Montevarchi","state_id":"1887"},
    {"city_id":"23816","city_name":"Montignoso","state_id":"1887"},
    {"city_id":"23817","city_name":"Montopoli in Val d'Arno","state_id":"1887"},
    {"city_id":"23818","city_name":"Orbetello","state_id":"1887"},
    {"city_id":"23819","city_name":"Pescia","state_id":"1887"},
    {"city_id":"23820","city_name":"Pietrasanta","state_id":"1887"},
    {"city_id":"23821","city_name":"Pieve a Nievole","state_id":"1887"},
    {"city_id":"23822","city_name":"Piombino","state_id":"1887"},
    {"city_id":"23823","city_name":"Pisa","state_id":"1887"},
    {"city_id":"23824","city_name":"Pistoia","state_id":"1887"},
    {"city_id":"23825","city_name":"Poggibonsi","state_id":"1887"},
    {"city_id":"23826","city_name":"Poggio a Caiano","state_id":"1887"},
    {"city_id":"23827","city_name":"Ponsacco","state_id":"1887"},
    {"city_id":"23828","city_name":"Pontassieve","state_id":"1887"},
    {"city_id":"23829","city_name":"Pontedera","state_id":"1887"},
    {"city_id":"23830","city_name":"Ponticino","state_id":"1887"},
    {"city_id":"23831","city_name":"Pontremoli","state_id":"1887"},
    {"city_id":"23832","city_name":"Portoferraio","state_id":"1887"},
    {"city_id":"23833","city_name":"Prato","state_id":"1887"},
    {"city_id":"23834","city_name":"Quarrata","state_id":"1887"},
    {"city_id":"23835","city_name":"Reggello","state_id":"1887"},
    {"city_id":"23836","city_name":"Rignano","state_id":"1887"},
    {"city_id":"23837","city_name":"Roccastrada","state_id":"1887"},
    {"city_id":"23838","city_name":"Rosignano Marittimo","state_id":"1887"},
    {"city_id":"23839","city_name":"San Casciano","state_id":"1887"},
    {"city_id":"23840","city_name":"San Giovanni Valdarno","state_id":"1887"},
    {"city_id":"23841","city_name":"San Giuliano Terme","state_id":"1887"},
    {"city_id":"23842","city_name":"San Miniato","state_id":"1887"},
    {"city_id":"23843","city_name":"Sansepolcro","state_id":"1887"},
    {"city_id":"23844","city_name":"Santa Croce sull'Arno","state_id":"1887"},
    {"city_id":"23845","city_name":"Santa Maria a Monte","state_id":"1887"},
    {"city_id":"23846","city_name":"Scandicci","state_id":"1887"},
    {"city_id":"23847","city_name":"Seravezza","state_id":"1887"},
    {"city_id":"23848","city_name":"Serravalle Pistoiese","state_id":"1887"},
    {"city_id":"23849","city_name":"Sesto Fiorentino","state_id":"1887"},
    {"city_id":"23850","city_name":"Siena","state_id":"1887"},
    {"city_id":"23851","city_name":"Signa","state_id":"1887"},
    {"city_id":"23852","city_name":"Sinalunga","state_id":"1887"},
    {"city_id":"23853","city_name":"Sovicille","state_id":"1887"},
    {"city_id":"23854","city_name":"Terranuova Bracciolini","state_id":"1887"},
    {"city_id":"23855","city_name":"Vaiano","state_id":"1887"},
    {"city_id":"23856","city_name":"Varna","state_id":"1887"},
    {"city_id":"23857","city_name":"Vecchiano","state_id":"1887"},
    {"city_id":"23858","city_name":"Viareggio","state_id":"1887"},
    {"city_id":"23859","city_name":"Vinci","state_id":"1887"},
    {"city_id":"23860","city_name":"Volterra","state_id":"1887"},
    {"city_id":"23861","city_name":"Guarrato","state_id":"1888"},
    {"city_id":"23862","city_name":" San Giorgio","state_id":"1890"},
    {"city_id":"23863","city_name":"Dro","state_id":"1890"},
    {"city_id":"23864","city_name":"Asolo","state_id":"1891"},
    {"city_id":"23865","city_name":"Conegliano","state_id":"1891"},
    {"city_id":"23866","city_name":"Cordignano","state_id":"1891"},
    {"city_id":"23867","city_name":"Gaiarine","state_id":"1891"},
    {"city_id":"23868","city_name":"Ormelle","state_id":"1891"},
    {"city_id":"23869","city_name":"Possagno","state_id":"1891"},
    {"city_id":"23870","city_name":"Revine Lago","state_id":"1891"},
    {"city_id":"23871","city_name":"Basiliano","state_id":"1892"},
    {"city_id":"23872","city_name":"Bicinicco","state_id":"1892"},
    {"city_id":"23873","city_name":"Buttrio","state_id":"1892"},
    {"city_id":"23874","city_name":"Coseano","state_id":"1892"},
    {"city_id":"23875","city_name":"Pradamano","state_id":"1892"},
    {"city_id":"23876","city_name":"San Giovanni al Natisone","state_id":"1892"},
    {"city_id":"23877","city_name":"Torreano","state_id":"1892"},
    {"city_id":"23878","city_name":"Amelia","state_id":"1893"},
    {"city_id":"23879","city_name":"Assisi","state_id":"1893"},
    {"city_id":"23880","city_name":"Bastia Umbra","state_id":"1893"},
    {"city_id":"23881","city_name":"Castiglione del Lago","state_id":"1893"},
    {"city_id":"23882","city_name":"Citta di Castello","state_id":"1893"},
    {"city_id":"23883","city_name":"Corciano","state_id":"1893"},
    {"city_id":"23884","city_name":"Deruta","state_id":"1893"},
    {"city_id":"23885","city_name":"Foligno","state_id":"1893"},
    {"city_id":"23886","city_name":"Gualdo Tadino","state_id":"1893"},
    {"city_id":"23887","city_name":"Gubbio","state_id":"1893"},
    {"city_id":"23888","city_name":"Magione","state_id":"1893"},
    {"city_id":"23889","city_name":"Marsciano","state_id":"1893"},
    {"city_id":"23890","city_name":"Narni","state_id":"1893"},
    {"city_id":"23891","city_name":"Orvieto","state_id":"1893"},
    {"city_id":"23892","city_name":"Perugia","state_id":"1893"},
    {"city_id":"23893","city_name":"San Giustino","state_id":"1893"},
    {"city_id":"23894","city_name":"Spello","state_id":"1893"},
    {"city_id":"23895","city_name":"Spoleto","state_id":"1893"},
    {"city_id":"23896","city_name":"Terni","state_id":"1893"},
    {"city_id":"23897","city_name":"Todi","state_id":"1893"},
    {"city_id":"23898","city_name":"Umbertide","state_id":"1893"},
    {"city_id":"23899","city_name":"Arsago Seprio","state_id":"1895"},
    {"city_id":"23900","city_name":"Busto Arsizio","state_id":"1895"},
    {"city_id":"23901","city_name":"Cassano Magnago","state_id":"1895"},
    {"city_id":"23902","city_name":"Gazzada","state_id":"1895"},
    {"city_id":"23903","city_name":"Oggiona Con Santo Stefano","state_id":"1895"},
    {"city_id":"23904","city_name":"Solbiate Arno","state_id":"1895"},
    {"city_id":"23905","city_name":"Solbiate Olona","state_id":"1895"},
    {"city_id":"23906","city_name":"Ternate","state_id":"1895"},
    {"city_id":"23907","city_name":"Venegono Inferiore","state_id":"1895"},
    {"city_id":"23908","city_name":"Abano Terme","state_id":"1896"},
    {"city_id":"23909","city_name":"Adria","state_id":"1896"},
    {"city_id":"23910","city_name":"Albignasego","state_id":"1896"},
    {"city_id":"23911","city_name":"Altavilla Vicentina","state_id":"1896"},
    {"city_id":"23912","city_name":"Arzignano","state_id":"1896"},
    {"city_id":"23913","city_name":"Badia Polesine","state_id":"1896"},
    {"city_id":"23914","city_name":"Bassano del Grappa","state_id":"1896"},
    {"city_id":"23915","city_name":"Belfiore","state_id":"1896"},
    {"city_id":"23916","city_name":"Belluno","state_id":"1896"},
    {"city_id":"23917","city_name":"Borso del Grappa","state_id":"1896"},
    {"city_id":"23918","city_name":"Bovolone","state_id":"1896"},
    {"city_id":"23919","city_name":"Bussolengo","state_id":"1896"},
    {"city_id":"23920","city_name":"Cadoneghe","state_id":"1896"},
    {"city_id":"23921","city_name":"Caldogno","state_id":"1896"},
    {"city_id":"23922","city_name":"Camisano Vicentino","state_id":"1896"},
    {"city_id":"23923","city_name":"Campodarsego","state_id":"1896"},
    {"city_id":"23924","city_name":"Campolongo Maggiore","state_id":"1896"},
    {"city_id":"23925","city_name":"Camponogara","state_id":"1896"},
    {"city_id":"23926","city_name":"Camposampiero","state_id":"1896"},
    {"city_id":"23927","city_name":"Caorle","state_id":"1896"},
    {"city_id":"23928","city_name":"Carbonera","state_id":"1896"},
    {"city_id":"23929","city_name":"Casale sul Sile","state_id":"1896"},
    {"city_id":"23930","city_name":"Casier","state_id":"1896"},
    {"city_id":"23931","city_name":"Cassola","state_id":"1896"},
    {"city_id":"23932","city_name":"Castel d'Azzano","state_id":"1896"},
    {"city_id":"23933","city_name":"Castelfranco Veneto","state_id":"1896"},
    {"city_id":"23934","city_name":"Castello di Godego","state_id":"1896"},
    {"city_id":"23935","city_name":"Castelnuovo del Garda","state_id":"1896"},
    {"city_id":"23936","city_name":"Cavallino-Treporti","state_id":"1896"},
    {"city_id":"23937","city_name":"Cavarzere","state_id":"1896"},
    {"city_id":"23938","city_name":"Cerea","state_id":"1896"},
    {"city_id":"23939","city_name":"Chiampo","state_id":"1896"},
    {"city_id":"23940","city_name":"Chioggia","state_id":"1896"},
    {"city_id":"23941","city_name":"Cittadella","state_id":"1896"},
    {"city_id":"23942","city_name":"Colombano","state_id":"1896"},
    {"city_id":"23943","city_name":"Concordia Sagittaria","state_id":"1896"},
    {"city_id":"23944","city_name":"Conegliano","state_id":"1896"},
    {"city_id":"23945","city_name":"Conselve","state_id":"1896"},
    {"city_id":"23946","city_name":"Cornedo Vicentino","state_id":"1896"},
    {"city_id":"23947","city_name":"Creazzo","state_id":"1896"},
    {"city_id":"23948","city_name":"Dolo","state_id":"1896"},
    {"city_id":"23949","city_name":"Due Carrare","state_id":"1896"},
    {"city_id":"23950","city_name":"Dueville","state_id":"1896"},
    {"city_id":"23951","city_name":"Eraclea","state_id":"1896"},
    {"city_id":"23952","city_name":"Este","state_id":"1896"},
    {"city_id":"23953","city_name":"Feltre","state_id":"1896"},
    {"city_id":"23954","city_name":"Galzignano Terme","state_id":"1896"},
    {"city_id":"23955","city_name":"Grezzana","state_id":"1896"},
    {"city_id":"23956","city_name":"Iesolo","state_id":"1896"},
    {"city_id":"23957","city_name":"Isola Vicentina","state_id":"1896"},
    {"city_id":"23958","city_name":"Isola della Scala","state_id":"1896"},
    {"city_id":"23959","city_name":"Jesolo","state_id":"1896"},
    {"city_id":"23960","city_name":"Legnago","state_id":"1896"},
    {"city_id":"23961","city_name":"Lendinara","state_id":"1896"},
    {"city_id":"23962","city_name":"Lonigo","state_id":"1896"},
    {"city_id":"23963","city_name":"Malo","state_id":"1896"},
    {"city_id":"23964","city_name":"Marano Vicentino","state_id":"1896"},
    {"city_id":"23965","city_name":"Marcon","state_id":"1896"},
    {"city_id":"23966","city_name":"Marostica","state_id":"1896"},
    {"city_id":"23967","city_name":"Martellago","state_id":"1896"},
    {"city_id":"23968","city_name":"Mestrino","state_id":"1896"},
    {"city_id":"23969","city_name":"Mira","state_id":"1896"},
    {"city_id":"23970","city_name":"Mirano","state_id":"1896"},
    {"city_id":"23971","city_name":"Mogliano Veneto","state_id":"1896"},
    {"city_id":"23972","city_name":"Monselice","state_id":"1896"},
    {"city_id":"23973","city_name":"Montagnana","state_id":"1896"},
    {"city_id":"23974","city_name":"Montebelluna","state_id":"1896"},
    {"city_id":"23975","city_name":"Montecchio Maggiore","state_id":"1896"},
    {"city_id":"23976","city_name":"Montegrotto Terme","state_id":"1896"},
    {"city_id":"23977","city_name":"Monticello Conte Otto","state_id":"1896"},
    {"city_id":"23978","city_name":"Motta di Livenza","state_id":"1896"},
    {"city_id":"23979","city_name":"Murano","state_id":"1896"},
    {"city_id":"23980","city_name":"Musile di Piave","state_id":"1896"},
    {"city_id":"23981","city_name":"Mussolente","state_id":"1896"},
    {"city_id":"23982","city_name":"Negrar","state_id":"1896"},
    {"city_id":"23983","city_name":"Noale","state_id":"1896"},
    {"city_id":"23984","city_name":"Noventa Padovana","state_id":"1896"},
    {"city_id":"23985","city_name":"Noventa Vicentina","state_id":"1896"},
    {"city_id":"23986","city_name":"Occhiobello","state_id":"1896"},
    {"city_id":"23987","city_name":"Oderzo","state_id":"1896"},
    {"city_id":"23988","city_name":"Ormelle","state_id":"1896"},
    {"city_id":"23989","city_name":"Padova","state_id":"1896"},
    {"city_id":"23990","city_name":"Padua","state_id":"1896"},
    {"city_id":"23991","city_name":"Paese","state_id":"1896"},
    {"city_id":"23992","city_name":"Pescantina","state_id":"1896"},
    {"city_id":"23993","city_name":"Peschiera del Garda","state_id":"1896"},
    {"city_id":"23994","city_name":"Pianiga","state_id":"1896"},
    {"city_id":"23995","city_name":"Piazzola sul Brenta","state_id":"1896"},
    {"city_id":"23996","city_name":"Pieve di Soligo","state_id":"1896"},
    {"city_id":"23997","city_name":"Pievebelvicino","state_id":"1896"},
    {"city_id":"23998","city_name":"Piombino Dese","state_id":"1896"},
    {"city_id":"23999","city_name":"Piove di Sacco","state_id":"1896"},
    {"city_id":"24000","city_name":"Ponte San Nicolo","state_id":"1896"},
    {"city_id":"24001","city_name":"Ponzano Veneto","state_id":"1896"},
    {"city_id":"24002","city_name":"Porto Tolle","state_id":"1896"},
    {"city_id":"24003","city_name":"Porto Viro","state_id":"1896"},
    {"city_id":"24004","city_name":"Portogruaro","state_id":"1896"},
    {"city_id":"24005","city_name":"Preganziol","state_id":"1896"},
    {"city_id":"24006","city_name":"Quinto di Treviso","state_id":"1896"},
    {"city_id":"24007","city_name":"Riese Pio X","state_id":"1896"},
    {"city_id":"24008","city_name":"Romano dEzzelino","state_id":"1896"},
    {"city_id":"24009","city_name":"Roncade","state_id":"1896"},
    {"city_id":"24010","city_name":"Rosa","state_id":"1896"},
    {"city_id":"24011","city_name":"Rovigo","state_id":"1896"},
    {"city_id":"24012","city_name":"Rubano","state_id":"1896"},
    {"city_id":"24013","city_name":"Salzano","state_id":"1896"},
    {"city_id":"24014","city_name":"San Biagio di Callalta","state_id":"1896"},
    {"city_id":"24015","city_name":"San Bonifacio","state_id":"1896"},
    {"city_id":"24016","city_name":"San Dona di Piave","state_id":"1896"},
    {"city_id":"24017","city_name":"San Giovanni Lupatoto","state_id":"1896"},
    {"city_id":"24018","city_name":"San Martino Buon Albergo","state_id":"1896"},
    {"city_id":"24019","city_name":"San Martino di Lupari","state_id":"1896"},
    {"city_id":"24020","city_name":"San Michele al Tagliamento","state_id":"1896"},
    {"city_id":"24021","city_name":"San Pietro in Cariano","state_id":"1896"},
    {"city_id":"24022","city_name":"San Vendemiano","state_id":"1896"},
    {"city_id":"24023","city_name":"Sant'Ambrogio","state_id":"1896"},
    {"city_id":"24024","city_name":"Santa Maria di Sala","state_id":"1896"},
    {"city_id":"24025","city_name":"Santo Stino di Livenza","state_id":"1896"},
    {"city_id":"24026","city_name":"Santorso","state_id":"1896"},
    {"city_id":"24027","city_name":"Saonara","state_id":"1896"},
    {"city_id":"24028","city_name":"Sarcedo","state_id":"1896"},
    {"city_id":"24029","city_name":"Schio","state_id":"1896"},
    {"city_id":"24030","city_name":"Scorze","state_id":"1896"},
    {"city_id":"24031","city_name":"Sedico","state_id":"1896"},
    {"city_id":"24032","city_name":"Selvazzano Dentro","state_id":"1896"},
    {"city_id":"24033","city_name":"Silea","state_id":"1896"},
    {"city_id":"24034","city_name":"Sivizzo","state_id":"1896"},
    {"city_id":"24035","city_name":"Sommacampagna","state_id":"1896"},
    {"city_id":"24036","city_name":"Sona","state_id":"1896"},
    {"city_id":"24037","city_name":"Spinea","state_id":"1896"},
    {"city_id":"24038","city_name":"Spresiano","state_id":"1896"},
    {"city_id":"24039","city_name":"Susegana","state_id":"1896"},
    {"city_id":"24040","city_name":"Taglio di Po","state_id":"1896"},
    {"city_id":"24041","city_name":"Teolo","state_id":"1896"},
    {"city_id":"24042","city_name":"Tezze sul Brenta","state_id":"1896"},
    {"city_id":"24043","city_name":"Thiene","state_id":"1896"},
    {"city_id":"24044","city_name":"Torri di Quartesolo","state_id":"1896"},
    {"city_id":"24045","city_name":"Trebaseleghe","state_id":"1896"},
    {"city_id":"24046","city_name":"Trevignano","state_id":"1896"},
    {"city_id":"24047","city_name":"Treviso","state_id":"1896"},
    {"city_id":"24048","city_name":"Trissino","state_id":"1896"},
    {"city_id":"24049","city_name":"Valdagno","state_id":"1896"},
    {"city_id":"24050","city_name":"Valdobbiadene","state_id":"1896"},
    {"city_id":"24051","city_name":"Valeggio sul Mincio","state_id":"1896"},
    {"city_id":"24052","city_name":"Vedelago","state_id":"1896"},
    {"city_id":"24053","city_name":"Venezia","state_id":"1896"},
    {"city_id":"24054","city_name":"Venice","state_id":"1896"},
    {"city_id":"24055","city_name":"Verona","state_id":"1896"},
    {"city_id":"24056","city_name":"Vicenza","state_id":"1896"},
    {"city_id":"24057","city_name":"Vigodarzere","state_id":"1896"},
    {"city_id":"24058","city_name":"Vigonovo","state_id":"1896"},
    {"city_id":"24059","city_name":"Vigonza","state_id":"1896"},
    {"city_id":"24060","city_name":"Villafranca di Verona","state_id":"1896"},
    {"city_id":"24061","city_name":"Villorba","state_id":"1896"},
    {"city_id":"24062","city_name":"Vittorio Veneto","state_id":"1896"},
    {"city_id":"24063","city_name":"Volpago del Montello","state_id":"1896"},
    {"city_id":"24064","city_name":"Zane","state_id":"1896"},
    {"city_id":"24065","city_name":"Zero Branco","state_id":"1896"},
    {"city_id":"24066","city_name":"Zevio","state_id":"1896"},
    {"city_id":"24067","city_name":"Cona","state_id":"1897"},
    {"city_id":"24068","city_name":"Marghera","state_id":"1897"},
    {"city_id":"24069","city_name":"Oriago di Mira","state_id":"1897"},
    {"city_id":"24070","city_name":"Tessera","state_id":"1897"},
    {"city_id":"24071","city_name":"Piedimulera","state_id":"1898"},
    {"city_id":"24072","city_name":"Crescentino","state_id":"1899"},
    {"city_id":"24073","city_name":"Moncrivello","state_id":"1899"},
    {"city_id":"24074","city_name":"Rovasenda","state_id":"1899"},
    {"city_id":"24075","city_name":"Trino","state_id":"1899"},
    {"city_id":"24076","city_name":" Valeggio Sul Mincio","state_id":"1900"},
    {"city_id":"24077","city_name":"Cadidavid","state_id":"1900"},
    {"city_id":"24078","city_name":"Colognola ai Colli","state_id":"1900"},
    {"city_id":"24079","city_name":"Pastrengo","state_id":"1900"},
    {"city_id":"24080","city_name":"Valgatara","state_id":"1900"},
    {"city_id":"24081","city_name":" Montebello Vicentino","state_id":"1901"},
    {"city_id":"24082","city_name":"Alonte","state_id":"1901"},
    {"city_id":"24083","city_name":"Arcugnano","state_id":"1901"},
    {"city_id":"24084","city_name":"Arzignano","state_id":"1901"},
    {"city_id":"24085","city_name":"Barbarano Vicentino","state_id":"1901"},
    {"city_id":"24086","city_name":"Breganze","state_id":"1901"},
    {"city_id":"24087","city_name":"Brendola","state_id":"1901"},
    {"city_id":"24088","city_name":"Bressanvido","state_id":"1901"},
    {"city_id":"24089","city_name":"Carre","state_id":"1901"},
    {"city_id":"24090","city_name":"Castelgomberto","state_id":"1901"},
    {"city_id":"24091","city_name":"Costabissara","state_id":"1901"},
    {"city_id":"24092","city_name":"Grumolo delle Abbadesse","state_id":"1901"},
    {"city_id":"24093","city_name":"Piovene Rocchette","state_id":"1901"},
    {"city_id":"24094","city_name":"Povolaro","state_id":"1901"},
    {"city_id":"24095","city_name":"Rossano Veneto","state_id":"1901"},
    {"city_id":"24096","city_name":"San Pietro di Rosa","state_id":"1901"},
    {"city_id":"24097","city_name":"San Vito di Leguzzano","state_id":"1901"},
    {"city_id":"24098","city_name":"Sandrigo","state_id":"1901"},
    {"city_id":"24099","city_name":"Thiene","state_id":"1901"},
    {"city_id":"24100","city_name":"Torrebelvicino","state_id":"1901"},
    {"city_id":"24101","city_name":"Torri di Quartesolo","state_id":"1901"},
    {"city_id":"24102","city_name":"Villaverla","state_id":"1901"},
    {"city_id":"24103","city_name":"Nepi","state_id":"1902"},
    {"city_id":"24104","city_name":"May Pen","state_id":"1904"},
    {"city_id":"24105","city_name":"Lucea","state_id":"1905"},
    {"city_id":"24106","city_name":"Kingston","state_id":"1906"},
    {"city_id":"24107","city_name":"Mandeville","state_id":"1907"},
    {"city_id":"24108","city_name":"Port Antonio","state_id":"1908"},
    {"city_id":"24109","city_name":"Albert Town","state_id":"1916"},
    {"city_id":"24110","city_name":"Falmouth","state_id":"1916"},
    {"city_id":"24111","city_name":"Savanna la Mar","state_id":"1917"},
    {"city_id":"24112","city_name":"Agui","state_id":"1918"},
    {"city_id":"24113","city_name":"Anjo","state_id":"1918"},
    {"city_id":"24114","city_name":"Atsumi","state_id":"1918"},
    {"city_id":"24115","city_name":"Bisai","state_id":"1918"},
    {"city_id":"24116","city_name":"Chiryu","state_id":"1918"},
    {"city_id":"24117","city_name":"Chita","state_id":"1918"},
    {"city_id":"24118","city_name":"Fujioka","state_id":"1918"},
    {"city_id":"24119","city_name":"Fuso","state_id":"1918"},
    {"city_id":"24120","city_name":"Gamagori","state_id":"1918"},
    {"city_id":"24121","city_name":"Handa","state_id":"1918"},
    {"city_id":"24122","city_name":"Hekinan","state_id":"1918"},
    {"city_id":"24123","city_name":"Higashiura","state_id":"1918"},
    {"city_id":"24124","city_name":"Ichinomiya","state_id":"1918"},
    {"city_id":"24125","city_name":"Inazawa","state_id":"1918"},
    {"city_id":"24126","city_name":"Inuyama","state_id":"1918"},
    {"city_id":"24127","city_name":"Isshiki","state_id":"1918"},
    {"city_id":"24128","city_name":"Iwakura","state_id":"1918"},
    {"city_id":"24129","city_name":"Jimokuji","state_id":"1918"},
    {"city_id":"24130","city_name":"Kanie","state_id":"1918"},
    {"city_id":"24131","city_name":"Kariya","state_id":"1918"},
    {"city_id":"24132","city_name":"Kasugai","state_id":"1918"},
    {"city_id":"24133","city_name":"Kira","state_id":"1918"},
    {"city_id":"24134","city_name":"Kisogawa","state_id":"1918"},
    {"city_id":"24135","city_name":"Komaki","state_id":"1918"},
    {"city_id":"24136","city_name":"Konan","state_id":"1918"},
    {"city_id":"24137","city_name":"Kota","state_id":"1918"},
    {"city_id":"24138","city_name":"Kozakai","state_id":"1918"},
    {"city_id":"24139","city_name":"Mihama","state_id":"1918"},
    {"city_id":"24140","city_name":"Minamichita","state_id":"1918"},
    {"city_id":"24141","city_name":"Miwa","state_id":"1918"},
    {"city_id":"24142","city_name":"Miyoshi","state_id":"1918"},
    {"city_id":"24143","city_name":"Nagakute","state_id":"1918"},
    {"city_id":"24144","city_name":"Nagoya","state_id":"1918"},
    {"city_id":"24145","city_name":"Nishiharu","state_id":"1918"},
    {"city_id":"24146","city_name":"Nishio","state_id":"1918"},
    {"city_id":"24147","city_name":"Nisshin","state_id":"1918"},
    {"city_id":"24148","city_name":"Obu","state_id":"1918"},
    {"city_id":"24149","city_name":"Oharu","state_id":"1918"},
    {"city_id":"24150","city_name":"Okazaki","state_id":"1918"},
    {"city_id":"24151","city_name":"Owariashi","state_id":"1918"},
    {"city_id":"24152","city_name":"Saori","state_id":"1918"},
    {"city_id":"24153","city_name":"Saya","state_id":"1918"},
    {"city_id":"24154","city_name":"Seto","state_id":"1918"},
    {"city_id":"24155","city_name":"Shikatsu","state_id":"1918"},
    {"city_id":"24156","city_name":"Shinshiro","state_id":"1918"},
    {"city_id":"24157","city_name":"Shippo","state_id":"1918"},
    {"city_id":"24158","city_name":"Sobue","state_id":"1918"},
    {"city_id":"24159","city_name":"Tahara","state_id":"1918"},
    {"city_id":"24160","city_name":"Takahama","state_id":"1918"},
    {"city_id":"24161","city_name":"Taketoyo","state_id":"1918"},
    {"city_id":"24162","city_name":"Togo","state_id":"1918"},
    {"city_id":"24163","city_name":"Tokai","state_id":"1918"},
    {"city_id":"24164","city_name":"Tokoname","state_id":"1918"},
    {"city_id":"24165","city_name":"Toyoake","state_id":"1918"},
    {"city_id":"24166","city_name":"Toyohashi","state_id":"1918"},
    {"city_id":"24167","city_name":"Toyokawa","state_id":"1918"},
    {"city_id":"24168","city_name":"Toyota","state_id":"1918"},
    {"city_id":"24169","city_name":"Tsushima","state_id":"1918"},
    {"city_id":"24170","city_name":"Yatomi","state_id":"1918"},
    {"city_id":"24171","city_name":"Akita","state_id":"1919"},
    {"city_id":"24172","city_name":"Honjo","state_id":"1919"},
    {"city_id":"24173","city_name":"Kazuno","state_id":"1919"},
    {"city_id":"24174","city_name":"Noshiro","state_id":"1919"},
    {"city_id":"24175","city_name":"Odate","state_id":"1919"},
    {"city_id":"24176","city_name":"Oga","state_id":"1919"},
    {"city_id":"24177","city_name":"Omagari","state_id":"1919"},
    {"city_id":"24178","city_name":"Takanosu","state_id":"1919"},
    {"city_id":"24179","city_name":"Tenno","state_id":"1919"},
    {"city_id":"24180","city_name":"Ugo","state_id":"1919"},
    {"city_id":"24181","city_name":"Yokote","state_id":"1919"},
    {"city_id":"24182","city_name":"Yuzawa","state_id":"1919"},
    {"city_id":"24183","city_name":"Aomori","state_id":"1920"},
    {"city_id":"24184","city_name":"Goshogawara","state_id":"1920"},
    {"city_id":"24185","city_name":"Hachinohe","state_id":"1920"},
    {"city_id":"24186","city_name":"Hiraka","state_id":"1920"},
    {"city_id":"24187","city_name":"Hirosaki","state_id":"1920"},
    {"city_id":"24188","city_name":"Kizukuri","state_id":"1920"},
    {"city_id":"24189","city_name":"Kuroishi","state_id":"1920"},
    {"city_id":"24190","city_name":"Misawa","state_id":"1920"},
    {"city_id":"24191","city_name":"Mutsu","state_id":"1920"},
    {"city_id":"24192","city_name":"Namioka","state_id":"1920"},
    {"city_id":"24193","city_name":"Towada","state_id":"1920"},
    {"city_id":"24194","city_name":"Abiko","state_id":"1921"},
    {"city_id":"24195","city_name":"Asahi","state_id":"1921"},
    {"city_id":"24196","city_name":"Chiba","state_id":"1921"},
    {"city_id":"24197","city_name":"Choshi","state_id":"1921"},
    {"city_id":"24198","city_name":"Funabashi","state_id":"1921"},
    {"city_id":"24199","city_name":"Fussa","state_id":"1921"},
    {"city_id":"24200","city_name":"Futtsu","state_id":"1921"},
    {"city_id":"24201","city_name":"Ichihara","state_id":"1921"},
    {"city_id":"24202","city_name":"Ichikawa","state_id":"1921"},
    {"city_id":"24203","city_name":"Inzai","state_id":"1921"},
    {"city_id":"24204","city_name":"Kamagaya","state_id":"1921"},
    {"city_id":"24205","city_name":"Kamogawa","state_id":"1921"},
    {"city_id":"24206","city_name":"Kashiwa","state_id":"1921"},
    {"city_id":"24207","city_name":"Katsuura","state_id":"1921"},
    {"city_id":"24208","city_name":"Kimitsu","state_id":"1921"},
    {"city_id":"24209","city_name":"Kisarazu","state_id":"1921"},
    {"city_id":"24210","city_name":"Kujukuri","state_id":"1921"},
    {"city_id":"24211","city_name":"Matsudo","state_id":"1921"},
    {"city_id":"24212","city_name":"Mobara","state_id":"1921"},
    {"city_id":"24213","city_name":"Nagareyama","state_id":"1921"},
    {"city_id":"24214","city_name":"Narashino","state_id":"1921"},
    {"city_id":"24215","city_name":"Narita","state_id":"1921"},
    {"city_id":"24216","city_name":"Naruto","state_id":"1921"},
    {"city_id":"24217","city_name":"Noda","state_id":"1921"},
    {"city_id":"24218","city_name":"Oamishirasato","state_id":"1921"},
    {"city_id":"24219","city_name":"Ohara","state_id":"1921"},
    {"city_id":"24220","city_name":"Omigawa","state_id":"1921"},
    {"city_id":"24221","city_name":"Sakae","state_id":"1921"},
    {"city_id":"24222","city_name":"Sakura","state_id":"1921"},
    {"city_id":"24223","city_name":"Sambu","state_id":"1921"},
    {"city_id":"24224","city_name":"Sawara","state_id":"1921"},
    {"city_id":"24225","city_name":"Sekiyado","state_id":"1921"},
    {"city_id":"24226","city_name":"Shiroi","state_id":"1921"},
    {"city_id":"24227","city_name":"Shisui","state_id":"1921"},
    {"city_id":"24228","city_name":"Shonan","state_id":"1921"},
    {"city_id":"24229","city_name":"Sodegaura","state_id":"1921"},
    {"city_id":"24230","city_name":"Tateyama","state_id":"1921"},
    {"city_id":"24231","city_name":"Togane","state_id":"1921"},
    {"city_id":"24232","city_name":"Tomisato","state_id":"1921"},
    {"city_id":"24233","city_name":"Urayasu","state_id":"1921"},
    {"city_id":"24234","city_name":"Yachimata","state_id":"1921"},
    {"city_id":"24235","city_name":"Yachiyo","state_id":"1921"},
    {"city_id":"24236","city_name":"Yokaichiba","state_id":"1921"},
    {"city_id":"24237","city_name":"Yotsukaido","state_id":"1921"},
    {"city_id":"24238","city_name":"Hojo","state_id":"1922"},
    {"city_id":"24239","city_name":"Imabari","state_id":"1922"},
    {"city_id":"24240","city_name":"Iyo","state_id":"1922"},
    {"city_id":"24241","city_name":"Iyomishima","state_id":"1922"},
    {"city_id":"24242","city_name":"Kawanoe","state_id":"1922"},
    {"city_id":"24243","city_name":"Masaki","state_id":"1922"},
    {"city_id":"24244","city_name":"Matsuyama","state_id":"1922"},
    {"city_id":"24245","city_name":"Niihama","state_id":"1922"},
    {"city_id":"24246","city_name":"Ozu","state_id":"1922"},
    {"city_id":"24247","city_name":"Saijo","state_id":"1922"},
    {"city_id":"24248","city_name":"Shigenobu","state_id":"1922"},
    {"city_id":"24249","city_name":"Tobe","state_id":"1922"},
    {"city_id":"24250","city_name":"Toyo","state_id":"1922"},
    {"city_id":"24251","city_name":"Uwajima","state_id":"1922"},
    {"city_id":"24252","city_name":"Yawatahama","state_id":"1922"},
    {"city_id":"24253","city_name":"Fukui","state_id":"1923"},
    {"city_id":"24254","city_name":"Harue","state_id":"1923"},
    {"city_id":"24255","city_name":"Katsuyama","state_id":"1923"},
    {"city_id":"24256","city_name":"Maruoka","state_id":"1923"},
    {"city_id":"24257","city_name":"Mikuni","state_id":"1923"},
    {"city_id":"24258","city_name":"Obama","state_id":"1923"},
    {"city_id":"24259","city_name":"Ono","state_id":"1923"},
    {"city_id":"24260","city_name":"Sabae","state_id":"1923"},
    {"city_id":"24261","city_name":"Takefu","state_id":"1923"},
    {"city_id":"24262","city_name":"Tsuruga","state_id":"1923"},
    {"city_id":"24263","city_name":"Amagi","state_id":"1924"},
    {"city_id":"24264","city_name":"Buzen","state_id":"1924"},
    {"city_id":"24265","city_name":"Chikugo","state_id":"1924"},
    {"city_id":"24266","city_name":"Chikushino","state_id":"1924"},
    {"city_id":"24267","city_name":"Dazaifu","state_id":"1924"},
    {"city_id":"24268","city_name":"Fukuma","state_id":"1924"},
    {"city_id":"24269","city_name":"Fukuoka","state_id":"1924"},
    {"city_id":"24270","city_name":"Hirokawa","state_id":"1924"},
    {"city_id":"24271","city_name":"Honami","state_id":"1924"},
    {"city_id":"24272","city_name":"Iizuka","state_id":"1924"},
    {"city_id":"24273","city_name":"Inatsuki","state_id":"1924"},
    {"city_id":"24274","city_name":"Kanda","state_id":"1924"},
    {"city_id":"24275","city_name":"Kasuga","state_id":"1924"},
    {"city_id":"24276","city_name":"Kasuya","state_id":"1924"},
    {"city_id":"24277","city_name":"Kawasaki","state_id":"1924"},
    {"city_id":"24278","city_name":"Kitakyushu","state_id":"1924"},
    {"city_id":"24279","city_name":"Koga","state_id":"1924"},
    {"city_id":"24280","city_name":"Kurate","state_id":"1924"},
    {"city_id":"24281","city_name":"Kurume","state_id":"1924"},
    {"city_id":"24282","city_name":"Maebaru","state_id":"1924"},
    {"city_id":"24283","city_name":"Miyata","state_id":"1924"},
    {"city_id":"24284","city_name":"Mizumaki","state_id":"1924"},
    {"city_id":"24285","city_name":"Munakata","state_id":"1924"},
    {"city_id":"24286","city_name":"Nakagawa","state_id":"1924"},
    {"city_id":"24287","city_name":"Nakama","state_id":"1924"},
    {"city_id":"24288","city_name":"Nogata","state_id":"1924"},
    {"city_id":"24289","city_name":"Ogori","state_id":"1924"},
    {"city_id":"24290","city_name":"Okagaki","state_id":"1924"},
    {"city_id":"24291","city_name":"Okawa","state_id":"1924"},
    {"city_id":"24292","city_name":"Omuta","state_id":"1924"},
    {"city_id":"24293","city_name":"Onojo","state_id":"1924"},
    {"city_id":"24294","city_name":"Sasaguri","state_id":"1924"},
    {"city_id":"24295","city_name":"Setaka","state_id":"1924"},
    {"city_id":"24296","city_name":"Shime","state_id":"1924"},
    {"city_id":"24297","city_name":"Shingu","state_id":"1924"},
    {"city_id":"24298","city_name":"Sue","state_id":"1924"},
    {"city_id":"24299","city_name":"Tagawa","state_id":"1924"},
    {"city_id":"24300","city_name":"Tanushimaru","state_id":"1924"},
    {"city_id":"24301","city_name":"Umi","state_id":"1924"},
    {"city_id":"24302","city_name":"Yamada","state_id":"1924"},
    {"city_id":"24303","city_name":"Yame","state_id":"1924"},
    {"city_id":"24304","city_name":"Yanagawa","state_id":"1924"},
    {"city_id":"24305","city_name":"Yukuhashi","state_id":"1924"},
    {"city_id":"24306","city_name":"Aizubange","state_id":"1925"},
    {"city_id":"24307","city_name":"Aizuwakamatsu","state_id":"1925"},
    {"city_id":"24308","city_name":"Fukushima","state_id":"1925"},
    {"city_id":"24309","city_name":"Funehiki","state_id":"1925"},
    {"city_id":"24310","city_name":"Haramachi","state_id":"1925"},
    {"city_id":"24311","city_name":"Hobara","state_id":"1925"},
    {"city_id":"24312","city_name":"Inawashiro","state_id":"1925"},
    {"city_id":"24313","city_name":"Ishikawa","state_id":"1925"},
    {"city_id":"24314","city_name":"Iwaki","state_id":"1925"},
    {"city_id":"24315","city_name":"Kawamata","state_id":"1925"},
    {"city_id":"24316","city_name":"Kitakata","state_id":"1925"},
    {"city_id":"24317","city_name":"Koriyama","state_id":"1925"},
    {"city_id":"24318","city_name":"Miharu","state_id":"1925"},
    {"city_id":"24319","city_name":"Motomiya","state_id":"1925"},
    {"city_id":"24320","city_name":"Namie","state_id":"1925"},
    {"city_id":"24321","city_name":"Nihommatsu","state_id":"1925"},
    {"city_id":"24322","city_name":"Shirakawa","state_id":"1925"},
    {"city_id":"24323","city_name":"Soma","state_id":"1925"},
    {"city_id":"24324","city_name":"Sukagawa","state_id":"1925"},
    {"city_id":"24325","city_name":"Yanagawa","state_id":"1925"},
    {"city_id":"24326","city_name":"Ena","state_id":"1926"},
    {"city_id":"24327","city_name":"Gifu","state_id":"1926"},
    {"city_id":"24328","city_name":"Ginan","state_id":"1926"},
    {"city_id":"24329","city_name":"Godo","state_id":"1926"},
    {"city_id":"24330","city_name":"Hashima","state_id":"1926"},
    {"city_id":"24331","city_name":"Hozumi","state_id":"1926"},
    {"city_id":"24332","city_name":"Ibigawa","state_id":"1926"},
    {"city_id":"24333","city_name":"Ikeda","state_id":"1926"},
    {"city_id":"24334","city_name":"Kakamigahara","state_id":"1926"},
    {"city_id":"24335","city_name":"Kani","state_id":"1926"},
    {"city_id":"24336","city_name":"Kasamatsu","state_id":"1926"},
    {"city_id":"24337","city_name":"Mino","state_id":"1926"},
    {"city_id":"24338","city_name":"Minokamo","state_id":"1926"},
    {"city_id":"24339","city_name":"Mitake","state_id":"1926"},
    {"city_id":"24340","city_name":"Mizunami","state_id":"1926"},
    {"city_id":"24341","city_name":"Nakatsugawa","state_id":"1926"},
    {"city_id":"24342","city_name":"Ogaki","state_id":"1926"},
    {"city_id":"24343","city_name":"Ono","state_id":"1926"},
    {"city_id":"24344","city_name":"Seki","state_id":"1926"},
    {"city_id":"24345","city_name":"Tajimi","state_id":"1926"},
    {"city_id":"24346","city_name":"Takayama","state_id":"1926"},
    {"city_id":"24347","city_name":"Tarui","state_id":"1926"},
    {"city_id":"24348","city_name":"Toki","state_id":"1926"},
    {"city_id":"24349","city_name":"Yoro","state_id":"1926"},
    {"city_id":"24350","city_name":"Annaka","state_id":"1927"},
    {"city_id":"24351","city_name":"Azuma","state_id":"1927"},
    {"city_id":"24352","city_name":"Fujimi","state_id":"1927"},
    {"city_id":"24353","city_name":"Fujioka","state_id":"1927"},
    {"city_id":"24354","city_name":"Gumma","state_id":"1927"},
    {"city_id":"24355","city_name":"Haruna","state_id":"1927"},
    {"city_id":"24356","city_name":"Isesaki","state_id":"1927"},
    {"city_id":"24357","city_name":"Kasakake","state_id":"1927"},
    {"city_id":"24358","city_name":"Kiryu","state_id":"1927"},
    {"city_id":"24359","city_name":"Maebashi","state_id":"1927"},
    {"city_id":"24360","city_name":"Nakanojo","state_id":"1927"},
    {"city_id":"24361","city_name":"Nitta","state_id":"1927"},
    {"city_id":"24362","city_name":"Numata","state_id":"1927"},
    {"city_id":"24363","city_name":"Oizumi","state_id":"1927"},
    {"city_id":"24364","city_name":"Omama","state_id":"1927"},
    {"city_id":"24365","city_name":"Ora","state_id":"1927"},
    {"city_id":"24366","city_name":"Ota","state_id":"1927"},
    {"city_id":"24367","city_name":"Sakai","state_id":"1927"},
    {"city_id":"24368","city_name":"Shibukawa","state_id":"1927"},
    {"city_id":"24369","city_name":"Takasaki","state_id":"1927"},
    {"city_id":"24370","city_name":"Tamamura","state_id":"1927"},
    {"city_id":"24371","city_name":"Tatebayashi","state_id":"1927"},
    {"city_id":"24372","city_name":"Tomioka","state_id":"1927"},
    {"city_id":"24373","city_name":"Yoshii","state_id":"1927"},
    {"city_id":"24374","city_name":"Fuchu","state_id":"1928"},
    {"city_id":"24375","city_name":"Fukuyama","state_id":"1928"},
    {"city_id":"24376","city_name":"Hatsukaichi","state_id":"1928"},
    {"city_id":"24377","city_name":"Higashihiroshima","state_id":"1928"},
    {"city_id":"24378","city_name":"Hiroshima","state_id":"1928"},
    {"city_id":"24379","city_name":"Innoshima","state_id":"1928"},
    {"city_id":"24380","city_name":"Kaita","state_id":"1928"},
    {"city_id":"24381","city_name":"Kannabe","state_id":"1928"},
    {"city_id":"24382","city_name":"Kumano","state_id":"1928"},
    {"city_id":"24383","city_name":"Kure","state_id":"1928"},
    {"city_id":"24384","city_name":"Kurose","state_id":"1928"},
    {"city_id":"24385","city_name":"Mihara","state_id":"1928"},
    {"city_id":"24386","city_name":"Miyoshi","state_id":"1928"},
    {"city_id":"24387","city_name":"Ono","state_id":"1928"},
    {"city_id":"24388","city_name":"Onomichi","state_id":"1928"},
    {"city_id":"24389","city_name":"Otake","state_id":"1928"},
    {"city_id":"24390","city_name":"Shinichi","state_id":"1928"},
    {"city_id":"24391","city_name":"Shobara","state_id":"1928"},
    {"city_id":"24392","city_name":"Takehara","state_id":"1928"},
    {"city_id":"24393","city_name":"Abashiri","state_id":"1929"},
    {"city_id":"24394","city_name":"Akabira","state_id":"1929"},
    {"city_id":"24395","city_name":"Asahikawa","state_id":"1929"},
    {"city_id":"24396","city_name":"Ashibetsu","state_id":"1929"},
    {"city_id":"24397","city_name":"Bibai","state_id":"1929"},
    {"city_id":"24398","city_name":"Bihoro","state_id":"1929"},
    {"city_id":"24399","city_name":"Chitose","state_id":"1929"},
    {"city_id":"24400","city_name":"Date","state_id":"1929"},
    {"city_id":"24401","city_name":"Ebetsu","state_id":"1929"},
    {"city_id":"24402","city_name":"Eniwa","state_id":"1929"},
    {"city_id":"24403","city_name":"Fukagawa","state_id":"1929"},
    {"city_id":"24404","city_name":"Furano","state_id":"1929"},
    {"city_id":"24405","city_name":"Hakodate","state_id":"1929"},
    {"city_id":"24406","city_name":"Hokkaido","state_id":"1929"},
    {"city_id":"24407","city_name":"Hyogo","state_id":"1929"},
    {"city_id":"24408","city_name":"Ishikari","state_id":"1929"},
    {"city_id":"24409","city_name":"Iwamizawa","state_id":"1929"},
    {"city_id":"24410","city_name":"Iwanai","state_id":"1929"},
    {"city_id":"24411","city_name":"Kamiiso","state_id":"1929"},
    {"city_id":"24412","city_name":"Kitahiroshima","state_id":"1929"},
    {"city_id":"24413","city_name":"Kitami","state_id":"1929"},
    {"city_id":"24414","city_name":"Kushiro","state_id":"1929"},
    {"city_id":"24415","city_name":"Makubetsu","state_id":"1929"},
    {"city_id":"24416","city_name":"Mikasa","state_id":"1929"},
    {"city_id":"24417","city_name":"Mombetsu","state_id":"1929"},
    {"city_id":"24418","city_name":"Muroran","state_id":"1929"},
    {"city_id":"24419","city_name":"Nakashibetsu","state_id":"1929"},
    {"city_id":"24420","city_name":"Nanae","state_id":"1929"},
    {"city_id":"24421","city_name":"Nayoro","state_id":"1929"},
    {"city_id":"24422","city_name":"Nemuro","state_id":"1929"},
    {"city_id":"24423","city_name":"Noboribetsu","state_id":"1929"},
    {"city_id":"24424","city_name":"Obihiro","state_id":"1929"},
    {"city_id":"24425","city_name":"Otaru","state_id":"1929"},
    {"city_id":"24426","city_name":"Otofuke","state_id":"1929"},
    {"city_id":"24427","city_name":"Rumoi","state_id":"1929"},
    {"city_id":"24428","city_name":"Sapporo","state_id":"1929"},
    {"city_id":"24429","city_name":"Shibetsu","state_id":"1929"},
    {"city_id":"24430","city_name":"Shiraoi","state_id":"1929"},
    {"city_id":"24431","city_name":"Shizunai","state_id":"1929"},
    {"city_id":"24432","city_name":"Sunagawa","state_id":"1929"},
    {"city_id":"24433","city_name":"Takikawa","state_id":"1929"},
    {"city_id":"24434","city_name":"Tobetsu","state_id":"1929"},
    {"city_id":"24435","city_name":"Tomakomai","state_id":"1929"},
    {"city_id":"24436","city_name":"Utashinai","state_id":"1929"},
    {"city_id":"24437","city_name":"Wakkanai","state_id":"1929"},
    {"city_id":"24438","city_name":"Yoichi","state_id":"1929"},
    {"city_id":"24439","city_name":"Yubari","state_id":"1929"},
    {"city_id":"24440","city_name":"Aioi","state_id":"1930"},
    {"city_id":"24441","city_name":"Akashi","state_id":"1930"},
    {"city_id":"24442","city_name":"Ako","state_id":"1930"},
    {"city_id":"24443","city_name":"Amagasaki","state_id":"1930"},
    {"city_id":"24444","city_name":"Ashiya","state_id":"1930"},
    {"city_id":"24445","city_name":"Fukusaki","state_id":"1930"},
    {"city_id":"24446","city_name":"Harima","state_id":"1930"},
    {"city_id":"24447","city_name":"Himeji","state_id":"1930"},
    {"city_id":"24448","city_name":"Inagawa","state_id":"1930"},
    {"city_id":"24449","city_name":"Inami","state_id":"1930"},
    {"city_id":"24450","city_name":"Itami","state_id":"1930"},
    {"city_id":"24451","city_name":"Kakogawa","state_id":"1930"},
    {"city_id":"24452","city_name":"Kasai","state_id":"1930"},
    {"city_id":"24453","city_name":"Kawanishi","state_id":"1930"},
    {"city_id":"24454","city_name":"Kobe","state_id":"1930"},
    {"city_id":"24455","city_name":"Kodera","state_id":"1930"},
    {"city_id":"24456","city_name":"Miki","state_id":"1930"},
    {"city_id":"24457","city_name":"Nandan","state_id":"1930"},
    {"city_id":"24458","city_name":"Nishinomiya","state_id":"1930"},
    {"city_id":"24459","city_name":"Nishiwaki","state_id":"1930"},
    {"city_id":"24460","city_name":"Ono","state_id":"1930"},
    {"city_id":"24461","city_name":"Sanda","state_id":"1930"},
    {"city_id":"24462","city_name":"Sasayama","state_id":"1930"},
    {"city_id":"24463","city_name":"Sumoto","state_id":"1930"},
    {"city_id":"24464","city_name":"Taishi","state_id":"1930"},
    {"city_id":"24465","city_name":"Takarazuka","state_id":"1930"},
    {"city_id":"24466","city_name":"Takasago","state_id":"1930"},
    {"city_id":"24467","city_name":"Tatsuno","state_id":"1930"},
    {"city_id":"24468","city_name":"Toyooka","state_id":"1930"},
    {"city_id":"24469","city_name":"Yamasaki","state_id":"1930"},
    {"city_id":"24470","city_name":"Yashiro","state_id":"1930"},
    {"city_id":"24471","city_name":"Yumesaki","state_id":"1930"},
    {"city_id":"24472","city_name":"Ami","state_id":"1931"},
    {"city_id":"24473","city_name":"Chiyoda","state_id":"1931"},
    {"city_id":"24474","city_name":"Daigo","state_id":"1931"},
    {"city_id":"24475","city_name":"Edosaki","state_id":"1931"},
    {"city_id":"24476","city_name":"Fujishiro","state_id":"1931"},
    {"city_id":"24477","city_name":"Hasaki","state_id":"1931"},
    {"city_id":"24478","city_name":"Hitachi","state_id":"1931"},
    {"city_id":"24479","city_name":"Hitachinaka","state_id":"1931"},
    {"city_id":"24480","city_name":"Hitachiota","state_id":"1931"},
    {"city_id":"24481","city_name":"Hokota","state_id":"1931"},
    {"city_id":"24482","city_name":"Ibaraki","state_id":"1931"},
    {"city_id":"24483","city_name":"Ina","state_id":"1931"},
    {"city_id":"24484","city_name":"Ishige","state_id":"1931"},
    {"city_id":"24485","city_name":"Ishioka","state_id":"1931"},
    {"city_id":"24486","city_name":"Itako","state_id":"1931"},
    {"city_id":"24487","city_name":"Iwai","state_id":"1931"},
    {"city_id":"24488","city_name":"Iwase","state_id":"1931"},
    {"city_id":"24489","city_name":"Kamisu","state_id":"1931"},
    {"city_id":"24490","city_name":"Kasama","state_id":"1931"},
    {"city_id":"24491","city_name":"Kashima","state_id":"1931"},
    {"city_id":"24492","city_name":"Kasumigaura","state_id":"1931"},
    {"city_id":"24493","city_name":"Kitaibaraki","state_id":"1931"},
    {"city_id":"24494","city_name":"Koga","state_id":"1931"},
    {"city_id":"24495","city_name":"Kukizaki","state_id":"1931"},
    {"city_id":"24496","city_name":"Makabe","state_id":"1931"},
    {"city_id":"24497","city_name":"Minori","state_id":"1931"},
    {"city_id":"24498","city_name":"Mito","state_id":"1931"},
    {"city_id":"24499","city_name":"Mitsukaido","state_id":"1931"},
    {"city_id":"24500","city_name":"Moriya","state_id":"1931"},
    {"city_id":"24501","city_name":"Naka","state_id":"1931"},
    {"city_id":"24502","city_name":"Oarai","state_id":"1931"},
    {"city_id":"24503","city_name":"Omiya","state_id":"1931"},
    {"city_id":"24504","city_name":"Ryugasaki","state_id":"1931"},
    {"city_id":"24505","city_name":"Sakai","state_id":"1931"},
    {"city_id":"24506","city_name":"Sanwa","state_id":"1931"},
    {"city_id":"24507","city_name":"Shimodate","state_id":"1931"},
    {"city_id":"24508","city_name":"Shimotsuma","state_id":"1931"},
    {"city_id":"24509","city_name":"Sowa","state_id":"1931"},
    {"city_id":"24510","city_name":"Takahagi","state_id":"1931"},
    {"city_id":"24511","city_name":"Tokai","state_id":"1931"},
    {"city_id":"24512","city_name":"Tomobe","state_id":"1931"},
    {"city_id":"24513","city_name":"Tone","state_id":"1931"},
    {"city_id":"24514","city_name":"Toride","state_id":"1931"},
    {"city_id":"24515","city_name":"Tsukuba","state_id":"1931"},
    {"city_id":"24516","city_name":"Ushiku","state_id":"1931"},
    {"city_id":"24517","city_name":"Yachiyo","state_id":"1931"},
    {"city_id":"24518","city_name":"Yasato","state_id":"1931"},
    {"city_id":"24519","city_name":"Yuki","state_id":"1931"},
    {"city_id":"24520","city_name":"Hakui","state_id":"1932"},
    {"city_id":"24521","city_name":"Kaga","state_id":"1932"},
    {"city_id":"24522","city_name":"Kanazawa","state_id":"1932"},
    {"city_id":"24523","city_name":"Komatsu","state_id":"1932"},
    {"city_id":"24524","city_name":"Matto","state_id":"1932"},
    {"city_id":"24525","city_name":"Nanao","state_id":"1932"},
    {"city_id":"24526","city_name":"Nonoichi","state_id":"1932"},
    {"city_id":"24527","city_name":"Suzu","state_id":"1932"},
    {"city_id":"24528","city_name":"Tsubata","state_id":"1932"},
    {"city_id":"24529","city_name":"Tsurugi","state_id":"1932"},
    {"city_id":"24530","city_name":"Uchinada","state_id":"1932"},
    {"city_id":"24531","city_name":"Wajima","state_id":"1932"},
    {"city_id":"24532","city_name":"Daito","state_id":"1933"},
    {"city_id":"24533","city_name":"Esashi","state_id":"1933"},
    {"city_id":"24534","city_name":"Hanamaki","state_id":"1933"},
    {"city_id":"24535","city_name":"Ichinohe","state_id":"1933"},
    {"city_id":"24536","city_name":"Ichinoseki","state_id":"1933"},
    {"city_id":"24537","city_name":"Iwate","state_id":"1933"},
    {"city_id":"24538","city_name":"Kamaishi","state_id":"1933"},
    {"city_id":"24539","city_name":"Kitakami","state_id":"1933"},
    {"city_id":"24540","city_name":"Kuji","state_id":"1933"},
    {"city_id":"24541","city_name":"Miyako","state_id":"1933"},
    {"city_id":"24542","city_name":"Mizusawa","state_id":"1933"},
    {"city_id":"24543","city_name":"Morioka","state_id":"1933"},
    {"city_id":"24544","city_name":"Ninohe","state_id":"1933"},
    {"city_id":"24545","city_name":"Ofunato","state_id":"1933"},
    {"city_id":"24546","city_name":"Otsuchi","state_id":"1933"},
    {"city_id":"24547","city_name":"Rikuzentakata","state_id":"1933"},
    {"city_id":"24548","city_name":"Shiwa","state_id":"1933"},
    {"city_id":"24549","city_name":"Shizukuishi","state_id":"1933"},
    {"city_id":"24550","city_name":"Takizawa","state_id":"1933"},
    {"city_id":"24551","city_name":"Tono","state_id":"1933"},
    {"city_id":"24552","city_name":"Yahaba","state_id":"1933"},
    {"city_id":"24553","city_name":"Yamada","state_id":"1933"},
    {"city_id":"24554","city_name":"Kagawa","state_id":"1934"},
    {"city_id":"24555","city_name":"Kanonji","state_id":"1934"},
    {"city_id":"24556","city_name":"Kokubunji","state_id":"1934"},
    {"city_id":"24557","city_name":"Marugame","state_id":"1934"},
    {"city_id":"24558","city_name":"Miki","state_id":"1934"},
    {"city_id":"24559","city_name":"Ryonan","state_id":"1934"},
    {"city_id":"24560","city_name":"Sakaide","state_id":"1934"},
    {"city_id":"24561","city_name":"Shido","state_id":"1934"},
    {"city_id":"24562","city_name":"Tadotsu","state_id":"1934"},
    {"city_id":"24563","city_name":"Takamatsu","state_id":"1934"},
    {"city_id":"24564","city_name":"Tonosho","state_id":"1934"},
    {"city_id":"24565","city_name":"Zentsuji","state_id":"1934"},
    {"city_id":"24566","city_name":"Aira","state_id":"1935"},
    {"city_id":"24567","city_name":"Akune","state_id":"1935"},
    {"city_id":"24568","city_name":"Hayato","state_id":"1935"},
    {"city_id":"24569","city_name":"Ibusuki","state_id":"1935"},
    {"city_id":"24570","city_name":"Ijuin","state_id":"1935"},
    {"city_id":"24571","city_name":"Izumi","state_id":"1935"},
    {"city_id":"24572","city_name":"Kagoshima","state_id":"1935"},
    {"city_id":"24573","city_name":"Kajiki","state_id":"1935"},
    {"city_id":"24574","city_name":"Kanoya","state_id":"1935"},
    {"city_id":"24575","city_name":"Kaseda","state_id":"1935"},
    {"city_id":"24576","city_name":"Kokubu","state_id":"1935"},
    {"city_id":"24577","city_name":"Kushikino","state_id":"1935"},
    {"city_id":"24578","city_name":"Makurazaki","state_id":"1935"},
    {"city_id":"24579","city_name":"Naze","state_id":"1935"},
    {"city_id":"24580","city_name":"Nishinoomote","state_id":"1935"},
    {"city_id":"24581","city_name":"Okuchi","state_id":"1935"},
    {"city_id":"24582","city_name":"Sendai","state_id":"1935"},
    {"city_id":"24583","city_name":"Shibushi","state_id":"1935"},
    {"city_id":"24584","city_name":"Sueyoshi","state_id":"1935"},
    {"city_id":"24585","city_name":"Tarumizu","state_id":"1935"},
    {"city_id":"24586","city_name":"Aikawa","state_id":"1936"},
    {"city_id":"24587","city_name":"Atsugi","state_id":"1936"},
    {"city_id":"24588","city_name":"Ayase","state_id":"1936"},
    {"city_id":"24589","city_name":"Chigasaki","state_id":"1936"},
    {"city_id":"24590","city_name":"Ebina","state_id":"1936"},
    {"city_id":"24591","city_name":"Fujisawa","state_id":"1936"},
    {"city_id":"24592","city_name":"Hadano","state_id":"1936"},
    {"city_id":"24593","city_name":"Hakone","state_id":"1936"},
    {"city_id":"24594","city_name":"Hayama","state_id":"1936"},
    {"city_id":"24595","city_name":"Hiratsuka","state_id":"1936"},
    {"city_id":"24596","city_name":"Isehara","state_id":"1936"},
    {"city_id":"24597","city_name":"Kamakura","state_id":"1936"},
    {"city_id":"24598","city_name":"Kawasaki","state_id":"1936"},
    {"city_id":"24599","city_name":"Minamiashigara","state_id":"1936"},
    {"city_id":"24600","city_name":"Miura","state_id":"1936"},
    {"city_id":"24601","city_name":"Ninomiya","state_id":"1936"},
    {"city_id":"24602","city_name":"Odawara","state_id":"1936"},
    {"city_id":"24603","city_name":"Oiso","state_id":"1936"},
    {"city_id":"24604","city_name":"Sagamihara","state_id":"1936"},
    {"city_id":"24605","city_name":"Samukawa","state_id":"1936"},
    {"city_id":"24606","city_name":"Shiroyama","state_id":"1936"},
    {"city_id":"24607","city_name":"Takatsu-Ku","state_id":"1936"},
    {"city_id":"24608","city_name":"Tsukui","state_id":"1936"},
    {"city_id":"24609","city_name":"Yamato","state_id":"1936"},
    {"city_id":"24610","city_name":"Yokohama","state_id":"1936"},
    {"city_id":"24611","city_name":"Yokosuka","state_id":"1936"},
    {"city_id":"24612","city_name":"Yugawara","state_id":"1936"},
    {"city_id":"24613","city_name":"Zama","state_id":"1936"},
    {"city_id":"24614","city_name":"Zushi","state_id":"1936"},
    {"city_id":"24615","city_name":"Gunma","state_id":"1937"},
    {"city_id":"24616","city_name":"Saitama","state_id":"1937"},
    {"city_id":"24617","city_name":"Aki","state_id":"1938"},
    {"city_id":"24618","city_name":"Ino","state_id":"1938"},
    {"city_id":"24619","city_name":"Kochi","state_id":"1938"},
    {"city_id":"24620","city_name":"Muroto","state_id":"1938"},
    {"city_id":"24621","city_name":"Nakamura","state_id":"1938"},
    {"city_id":"24622","city_name":"Nankoku","state_id":"1938"},
    {"city_id":"24623","city_name":"Sukumo","state_id":"1938"},
    {"city_id":"24624","city_name":"Susaki","state_id":"1938"},
    {"city_id":"24625","city_name":"Tosa","state_id":"1938"},
    {"city_id":"24626","city_name":"Tosashimizu","state_id":"1938"},
    {"city_id":"24627","city_name":"Tosayamada","state_id":"1938"},
    {"city_id":"24628","city_name":"Arao","state_id":"1939"},
    {"city_id":"24629","city_name":"Ashikita","state_id":"1939"},
    {"city_id":"24630","city_name":"Aso","state_id":"1939"},
    {"city_id":"24631","city_name":"Hitoyoshi","state_id":"1939"},
    {"city_id":"24632","city_name":"Hondo","state_id":"1939"},
    {"city_id":"24633","city_name":"Jonan","state_id":"1939"},
    {"city_id":"24634","city_name":"Kikuchi","state_id":"1939"},
    {"city_id":"24635","city_name":"Kikuyo","state_id":"1939"},
    {"city_id":"24636","city_name":"Koshi","state_id":"1939"},
    {"city_id":"24637","city_name":"Kumamoto","state_id":"1939"},
    {"city_id":"24638","city_name":"Mashiki","state_id":"1939"},
    {"city_id":"24639","city_name":"Matsubase","state_id":"1939"},
    {"city_id":"24640","city_name":"Minamata","state_id":"1939"},
    {"city_id":"24641","city_name":"Nishigoshi","state_id":"1939"},
    {"city_id":"24642","city_name":"Ozu","state_id":"1939"},
    {"city_id":"24643","city_name":"Tamana","state_id":"1939"},
    {"city_id":"24644","city_name":"Ueki","state_id":"1939"},
    {"city_id":"24645","city_name":"Ushibuka","state_id":"1939"},
    {"city_id":"24646","city_name":"Uto","state_id":"1939"},
    {"city_id":"24647","city_name":"Yamaga","state_id":"1939"},
    {"city_id":"24648","city_name":"Yatsushiro","state_id":"1939"},
    {"city_id":"24649","city_name":"Ayabe","state_id":"1940"},
    {"city_id":"24650","city_name":"Fukuchiyama","state_id":"1940"},
    {"city_id":"24651","city_name":"Joyo","state_id":"1940"},
    {"city_id":"24652","city_name":"Kameoka","state_id":"1940"},
    {"city_id":"24653","city_name":"Kizu","state_id":"1940"},
    {"city_id":"24654","city_name":"Kumiyama","state_id":"1940"},
    {"city_id":"24655","city_name":"Kyotanabe","state_id":"1940"},
    {"city_id":"24656","city_name":"Kyoto","state_id":"1940"},
    {"city_id":"24657","city_name":"Maizuru","state_id":"1940"},
    {"city_id":"24658","city_name":"Miyazu","state_id":"1940"},
    {"city_id":"24659","city_name":"Muko","state_id":"1940"},
    {"city_id":"24660","city_name":"Nagaokakyo","state_id":"1940"},
    {"city_id":"24661","city_name":"Seika","state_id":"1940"},
    {"city_id":"24662","city_name":"Uji","state_id":"1940"},
    {"city_id":"24663","city_name":"Yawata","state_id":"1940"},
    {"city_id":"24664","city_name":"Ago","state_id":"1941"},
    {"city_id":"24665","city_name":"Hisai","state_id":"1941"},
    {"city_id":"24666","city_name":"Ise","state_id":"1941"},
    {"city_id":"24667","city_name":"Kameyama","state_id":"1941"},
    {"city_id":"24668","city_name":"Komono","state_id":"1941"},
    {"city_id":"24669","city_name":"Kumano","state_id":"1941"},
    {"city_id":"24670","city_name":"Kuwana","state_id":"1941"},
    {"city_id":"24671","city_name":"Matsusaka","state_id":"1941"},
    {"city_id":"24672","city_name":"Meiwa","state_id":"1941"},
    {"city_id":"24673","city_name":"Nabari","state_id":"1941"},
    {"city_id":"24674","city_name":"Owase","state_id":"1941"},
    {"city_id":"24675","city_name":"Suzuka","state_id":"1941"},
    {"city_id":"24676","city_name":"Toba","state_id":"1941"},
    {"city_id":"24677","city_name":"Toin","state_id":"1941"},
    {"city_id":"24678","city_name":"Tsu","state_id":"1941"},
    {"city_id":"24679","city_name":"Ueno","state_id":"1941"},
    {"city_id":"24680","city_name":"Yokkaichi","state_id":"1941"},
    {"city_id":"24681","city_name":"Furukawa","state_id":"1942"},
    {"city_id":"24682","city_name":"Hasama","state_id":"1942"},
    {"city_id":"24683","city_name":"Ishinomaki","state_id":"1942"},
    {"city_id":"24684","city_name":"Iwanuma","state_id":"1942"},
    {"city_id":"24685","city_name":"Kakuda","state_id":"1942"},
    {"city_id":"24686","city_name":"Kesennuma","state_id":"1942"},
    {"city_id":"24687","city_name":"Kogota","state_id":"1942"},
    {"city_id":"24688","city_name":"Marumori","state_id":"1942"},
    {"city_id":"24689","city_name":"Natori","state_id":"1942"},
    {"city_id":"24690","city_name":"Ogawara","state_id":"1942"},
    {"city_id":"24691","city_name":"Rifu","state_id":"1942"},
    {"city_id":"24692","city_name":"Sendai","state_id":"1942"},
    {"city_id":"24693","city_name":"Shibata","state_id":"1942"},
    {"city_id":"24694","city_name":"Shichigahama","state_id":"1942"},
    {"city_id":"24695","city_name":"Shiogama","state_id":"1942"},
    {"city_id":"24696","city_name":"Shiroishi","state_id":"1942"},
    {"city_id":"24697","city_name":"Tagajo","state_id":"1942"},
    {"city_id":"24698","city_name":"Taiwa","state_id":"1942"},
    {"city_id":"24699","city_name":"Tomiya","state_id":"1942"},
    {"city_id":"24700","city_name":"Wakuya","state_id":"1942"},
    {"city_id":"24701","city_name":"Watari","state_id":"1942"},
    {"city_id":"24702","city_name":"Yamoto","state_id":"1942"},
    {"city_id":"24703","city_name":"Ebino","state_id":"1943"},
    {"city_id":"24704","city_name":"Hyuga","state_id":"1943"},
    {"city_id":"24705","city_name":"Kiyotake","state_id":"1943"},
    {"city_id":"24706","city_name":"Kobayashi","state_id":"1943"},
    {"city_id":"24707","city_name":"Kunitomi","state_id":"1943"},
    {"city_id":"24708","city_name":"Kushima","state_id":"1943"},
    {"city_id":"24709","city_name":"Mimata","state_id":"1943"},
    {"city_id":"24710","city_name":"Miyakonojo","state_id":"1943"},
    {"city_id":"24711","city_name":"Miyazaki","state_id":"1943"},
    {"city_id":"24712","city_name":"Nichinan","state_id":"1943"},
    {"city_id":"24713","city_name":"Nobeoka","state_id":"1943"},
    {"city_id":"24714","city_name":"Sadowara","state_id":"1943"},
    {"city_id":"24715","city_name":"Saito","state_id":"1943"},
    {"city_id":"24716","city_name":"Shintomi","state_id":"1943"},
    {"city_id":"24717","city_name":"Takanabe","state_id":"1943"},
    {"city_id":"24718","city_name":"Chino","state_id":"1944"},
    {"city_id":"24719","city_name":"Hotaka","state_id":"1944"},
    {"city_id":"24720","city_name":"Iida","state_id":"1944"},
    {"city_id":"24721","city_name":"Iiyama","state_id":"1944"},
    {"city_id":"24722","city_name":"Ina","state_id":"1944"},
    {"city_id":"24723","city_name":"Komagane","state_id":"1944"},
    {"city_id":"24724","city_name":"Komoro","state_id":"1944"},
    {"city_id":"24725","city_name":"Koshoku","state_id":"1944"},
    {"city_id":"24726","city_name":"Maruko","state_id":"1944"},
    {"city_id":"24727","city_name":"Matsumoto","state_id":"1944"},
    {"city_id":"24728","city_name":"Minowa","state_id":"1944"},
    {"city_id":"24729","city_name":"Nagano","state_id":"1944"},
    {"city_id":"24730","city_name":"Nakano","state_id":"1944"},
    {"city_id":"24731","city_name":"Okaya","state_id":"1944"},
    {"city_id":"24732","city_name":"Omachi","state_id":"1944"},
    {"city_id":"24733","city_name":"Saku","state_id":"1944"},
    {"city_id":"24734","city_name":"Shimosuwa","state_id":"1944"},
    {"city_id":"24735","city_name":"Shiojiri","state_id":"1944"},
    {"city_id":"24736","city_name":"Suwa","state_id":"1944"},
    {"city_id":"24737","city_name":"Suzaka","state_id":"1944"},
    {"city_id":"24738","city_name":"Tatsuno","state_id":"1944"},
    {"city_id":"24739","city_name":"Tobu","state_id":"1944"},
    {"city_id":"24740","city_name":"Toyoshina","state_id":"1944"},
    {"city_id":"24741","city_name":"Ueda","state_id":"1944"},
    {"city_id":"24742","city_name":"Fukue","state_id":"1945"},
    {"city_id":"24743","city_name":"Hirado","state_id":"1945"},
    {"city_id":"24744","city_name":"Isahaya","state_id":"1945"},
    {"city_id":"24745","city_name":"Matsuura","state_id":"1945"},
    {"city_id":"24746","city_name":"Nagasaki","state_id":"1945"},
    {"city_id":"24747","city_name":"Nagayo","state_id":"1945"},
    {"city_id":"24748","city_name":"Omura","state_id":"1945"},
    {"city_id":"24749","city_name":"Sasebo","state_id":"1945"},
    {"city_id":"24750","city_name":"Shimabara","state_id":"1945"},
    {"city_id":"24751","city_name":"Togitsu","state_id":"1945"},
    {"city_id":"24752","city_name":"Gojo","state_id":"1946"},
    {"city_id":"24753","city_name":"Gose","state_id":"1946"},
    {"city_id":"24754","city_name":"Haibara","state_id":"1946"},
    {"city_id":"24755","city_name":"Heguri","state_id":"1946"},
    {"city_id":"24756","city_name":"Ikagura","state_id":"1946"},
    {"city_id":"24757","city_name":"Ikoma","state_id":"1946"},
    {"city_id":"24758","city_name":"Kammaki","state_id":"1946"},
    {"city_id":"24759","city_name":"Kashiba","state_id":"1946"},
    {"city_id":"24760","city_name":"Kashihara","state_id":"1946"},
    {"city_id":"24761","city_name":"Kawai","state_id":"1946"},
    {"city_id":"24762","city_name":"Koryo","state_id":"1946"},
    {"city_id":"24763","city_name":"Nara","state_id":"1946"},
    {"city_id":"24764","city_name":"Oji","state_id":"1946"},
    {"city_id":"24765","city_name":"Oyodo","state_id":"1946"},
    {"city_id":"24766","city_name":"Sakurai","state_id":"1946"},
    {"city_id":"24767","city_name":"Sango","state_id":"1946"},
    {"city_id":"24768","city_name":"Tawaramoto","state_id":"1946"},
    {"city_id":"24769","city_name":"Tenri","state_id":"1946"},
    {"city_id":"24770","city_name":"Yamatokoriyama","state_id":"1946"},
    {"city_id":"24771","city_name":"Yamatotakada","state_id":"1946"},
    {"city_id":"24772","city_name":"Arai","state_id":"1947"},
    {"city_id":"24773","city_name":"Fuchu","state_id":"1947"},
    {"city_id":"24774","city_name":"Gosen","state_id":"1947"},
    {"city_id":"24775","city_name":"Itoigawa","state_id":"1947"},
    {"city_id":"24776","city_name":"Joetsu","state_id":"1947"},
    {"city_id":"24777","city_name":"Kameda","state_id":"1947"},
    {"city_id":"24778","city_name":"Kamo","state_id":"1947"},
    {"city_id":"24779","city_name":"Kashiwazaki","state_id":"1947"},
    {"city_id":"24780","city_name":"Kurosaki","state_id":"1947"},
    {"city_id":"24781","city_name":"Maki","state_id":"1947"},
    {"city_id":"24782","city_name":"Mitsuke","state_id":"1947"},
    {"city_id":"24783","city_name":"Muika","state_id":"1947"},
    {"city_id":"24784","city_name":"Murakami","state_id":"1947"},
    {"city_id":"24785","city_name":"Muramatsu","state_id":"1947"},
    {"city_id":"24786","city_name":"Nagaoka","state_id":"1947"},
    {"city_id":"24787","city_name":"Nakajo","state_id":"1947"},
    {"city_id":"24788","city_name":"Niigata","state_id":"1947"},
    {"city_id":"24789","city_name":"Niitsu","state_id":"1947"},
    {"city_id":"24790","city_name":"Ojiya","state_id":"1947"},
    {"city_id":"24791","city_name":"Ryotsu","state_id":"1947"},
    {"city_id":"24792","city_name":"Sanjo","state_id":"1947"},
    {"city_id":"24793","city_name":"Shibata","state_id":"1947"},
    {"city_id":"24794","city_name":"Shiozawa","state_id":"1947"},
    {"city_id":"24795","city_name":"Shirone","state_id":"1947"},
    {"city_id":"24796","city_name":"Suibara","state_id":"1947"},
    {"city_id":"24797","city_name":"Tochio","state_id":"1947"},
    {"city_id":"24798","city_name":"Tokamachi","state_id":"1947"},
    {"city_id":"24799","city_name":"Toyosaka","state_id":"1947"},
    {"city_id":"24800","city_name":"Tsubame","state_id":"1947"},
    {"city_id":"24801","city_name":"Yoshida","state_id":"1947"},
    {"city_id":"24802","city_name":"Beppu","state_id":"1948"},
    {"city_id":"24803","city_name":"Bungotakada","state_id":"1948"},
    {"city_id":"24804","city_name":"Hiji","state_id":"1948"},
    {"city_id":"24805","city_name":"Hita","state_id":"1948"},
    {"city_id":"24806","city_name":"Kitsuki","state_id":"1948"},
    {"city_id":"24807","city_name":"Kusu","state_id":"1948"},
    {"city_id":"24808","city_name":"Nakatsu","state_id":"1948"},
    {"city_id":"24809","city_name":"Oita","state_id":"1948"},
    {"city_id":"24810","city_name":"Saiki","state_id":"1948"},
    {"city_id":"24811","city_name":"Taketa","state_id":"1948"},
    {"city_id":"24812","city_name":"Tsukumi","state_id":"1948"},
    {"city_id":"24813","city_name":"Usa","state_id":"1948"},
    {"city_id":"24814","city_name":"Usuki","state_id":"1948"},
    {"city_id":"24815","city_name":"Bizen","state_id":"1949"},
    {"city_id":"24816","city_name":"Ibara","state_id":"1949"},
    {"city_id":"24817","city_name":"Kamogata","state_id":"1949"},
    {"city_id":"24818","city_name":"Kasaoka","state_id":"1949"},
    {"city_id":"24819","city_name":"Kurashiki","state_id":"1949"},
    {"city_id":"24820","city_name":"Mabi","state_id":"1949"},
    {"city_id":"24821","city_name":"Niimi","state_id":"1949"},
    {"city_id":"24822","city_name":"Okayama","state_id":"1949"},
    {"city_id":"24823","city_name":"Sanyo","state_id":"1949"},
    {"city_id":"24824","city_name":"Soja","state_id":"1949"},
    {"city_id":"24825","city_name":"Takahashi","state_id":"1949"},
    {"city_id":"24826","city_name":"Tamano","state_id":"1949"},
    {"city_id":"24827","city_name":"Tsuyama","state_id":"1949"},
    {"city_id":"24828","city_name":"Chatan","state_id":"1950"},
    {"city_id":"24829","city_name":"Ginowan","state_id":"1950"},
    {"city_id":"24830","city_name":"Gushikawa","state_id":"1950"},
    {"city_id":"24831","city_name":"Haebaru","state_id":"1950"},
    {"city_id":"24832","city_name":"Hirara","state_id":"1950"},
    {"city_id":"24833","city_name":"Ishigaki","state_id":"1950"},
    {"city_id":"24834","city_name":"Ishikawa","state_id":"1950"},
    {"city_id":"24835","city_name":"Itoman","state_id":"1950"},
    {"city_id":"24836","city_name":"Nago","state_id":"1950"},
    {"city_id":"24837","city_name":"Naha","state_id":"1950"},
    {"city_id":"24838","city_name":"Nishihara","state_id":"1950"},
    {"city_id":"24839","city_name":"Okinawa","state_id":"1950"},
    {"city_id":"24840","city_name":"Tomigusuku","state_id":"1950"},
    {"city_id":"24841","city_name":"Urasoe","state_id":"1950"},
    {"city_id":"24842","city_name":"Yomitan","state_id":"1950"},
    {"city_id":"24843","city_name":"Daito","state_id":"1951"},
    {"city_id":"24844","city_name":"Fujiidera","state_id":"1951"},
    {"city_id":"24845","city_name":"Habikino","state_id":"1951"},
    {"city_id":"24846","city_name":"Hannan","state_id":"1951"},
    {"city_id":"24847","city_name":"Higashiosaka","state_id":"1951"},
    {"city_id":"24848","city_name":"Hirakata","state_id":"1951"},
    {"city_id":"24849","city_name":"Ibaraki","state_id":"1951"},
    {"city_id":"24850","city_name":"Ikeda","state_id":"1951"},
    {"city_id":"24851","city_name":"Izumi","state_id":"1951"},
    {"city_id":"24852","city_name":"Izumiotsu","state_id":"1951"},
    {"city_id":"24853","city_name":"Izumisano","state_id":"1951"},
    {"city_id":"24854","city_name":"Kadoma","state_id":"1951"},
    {"city_id":"24855","city_name":"Kaizuka","state_id":"1951"},
    {"city_id":"24856","city_name":"Kashiwara","state_id":"1951"},
    {"city_id":"24857","city_name":"Katano","state_id":"1951"},
    {"city_id":"24858","city_name":"Kawachinagano","state_id":"1951"},
    {"city_id":"24859","city_name":"Kishiwada","state_id":"1951"},
    {"city_id":"24860","city_name":"Kobe","state_id":"1951"},
    {"city_id":"24861","city_name":"Kumatori","state_id":"1951"},
    {"city_id":"24862","city_name":"Matsubara","state_id":"1951"},
    {"city_id":"24863","city_name":"Mihara","state_id":"1951"},
    {"city_id":"24864","city_name":"Mino","state_id":"1951"},
    {"city_id":"24865","city_name":"Misaki","state_id":"1951"},
    {"city_id":"24866","city_name":"Moriguchi","state_id":"1951"},
    {"city_id":"24867","city_name":"Neyagawa","state_id":"1951"},
    {"city_id":"24868","city_name":"Osaka","state_id":"1951"},
    {"city_id":"24869","city_name":"Osakasayama","state_id":"1951"},
    {"city_id":"24870","city_name":"Sakai","state_id":"1951"},
    {"city_id":"24871","city_name":"Sennan","state_id":"1951"},
    {"city_id":"24872","city_name":"Settsu","state_id":"1951"},
    {"city_id":"24873","city_name":"Shijonawate","state_id":"1951"},
    {"city_id":"24874","city_name":"Shimamoto","state_id":"1951"},
    {"city_id":"24875","city_name":"Suita","state_id":"1951"},
    {"city_id":"24876","city_name":"Takaishi","state_id":"1951"},
    {"city_id":"24877","city_name":"Takatsuki","state_id":"1951"},
    {"city_id":"24878","city_name":"Tondabayashi","state_id":"1951"},
    {"city_id":"24879","city_name":"Toyonaka","state_id":"1951"},
    {"city_id":"24880","city_name":"Toyono","state_id":"1951"},
    {"city_id":"24881","city_name":"Yao","state_id":"1951"},
    {"city_id":"24882","city_name":"Imari","state_id":"1952"},
    {"city_id":"24883","city_name":"Kanzaki","state_id":"1952"},
    {"city_id":"24884","city_name":"Karatsu","state_id":"1952"},
    {"city_id":"24885","city_name":"Kashima","state_id":"1952"},
    {"city_id":"24886","city_name":"Kawasoe","state_id":"1952"},
    {"city_id":"24887","city_name":"Saga","state_id":"1952"},
    {"city_id":"24888","city_name":"Takeo","state_id":"1952"},
    {"city_id":"24889","city_name":"Taku","state_id":"1952"},
    {"city_id":"24890","city_name":"Tosu","state_id":"1952"},
    {"city_id":"24891","city_name":"Ureshino","state_id":"1952"},
    {"city_id":"24892","city_name":"Yamato","state_id":"1952"},
    {"city_id":"24893","city_name":"Ageo","state_id":"1953"},
    {"city_id":"24894","city_name":"Asaka","state_id":"1953"},
    {"city_id":"24895","city_name":"Chichibu","state_id":"1953"},
    {"city_id":"24896","city_name":"Fujimi","state_id":"1953"},
    {"city_id":"24897","city_name":"Fukaya","state_id":"1953"},
    {"city_id":"24898","city_name":"Fukiage","state_id":"1953"},
    {"city_id":"24899","city_name":"Gyoda","state_id":"1953"},
    {"city_id":"24900","city_name":"Hanno","state_id":"1953"},
    {"city_id":"24901","city_name":"Hanyu","state_id":"1953"},
    {"city_id":"24902","city_name":"Hasuda","state_id":"1953"},
    {"city_id":"24903","city_name":"Hatogaya","state_id":"1953"},
    {"city_id":"24904","city_name":"Hatoyama","state_id":"1953"},
    {"city_id":"24905","city_name":"Hidaka","state_id":"1953"},
    {"city_id":"24906","city_name":"Higashimatsuyama","state_id":"1953"},
    {"city_id":"24907","city_name":"Honjo","state_id":"1953"},
    {"city_id":"24908","city_name":"Ina","state_id":"1953"},
    {"city_id":"24909","city_name":"Iruma","state_id":"1953"},
    {"city_id":"24910","city_name":"Iwatsuki","state_id":"1953"},
    {"city_id":"24911","city_name":"Kamifukuoka","state_id":"1953"},
    {"city_id":"24912","city_name":"Kamisato","state_id":"1953"},
    {"city_id":"24913","city_name":"Kasukabe","state_id":"1953"},
    {"city_id":"24914","city_name":"Kawagoe","state_id":"1953"},
    {"city_id":"24915","city_name":"Kawaguchi","state_id":"1953"},
    {"city_id":"24916","city_name":"Kawajima","state_id":"1953"},
    {"city_id":"24917","city_name":"Kazo","state_id":"1953"},
    {"city_id":"24918","city_name":"Kisai","state_id":"1953"},
    {"city_id":"24919","city_name":"Kitamoto","state_id":"1953"},
    {"city_id":"24920","city_name":"Kodama","state_id":"1953"},
    {"city_id":"24921","city_name":"Konosu","state_id":"1953"},
    {"city_id":"24922","city_name":"Koshigaya","state_id":"1953"},
    {"city_id":"24923","city_name":"Kuki","state_id":"1953"},
    {"city_id":"24924","city_name":"Kumagaya","state_id":"1953"},
    {"city_id":"24925","city_name":"Kurihashi","state_id":"1953"},
    {"city_id":"24926","city_name":"Matsubushi","state_id":"1953"},
    {"city_id":"24927","city_name":"Menuma","state_id":"1953"},
    {"city_id":"24928","city_name":"Misato","state_id":"1953"},
    {"city_id":"24929","city_name":"Miyashiro","state_id":"1953"},
    {"city_id":"24930","city_name":"Miyoshi","state_id":"1953"},
    {"city_id":"24931","city_name":"Moroyama","state_id":"1953"},
    {"city_id":"24932","city_name":"Niiza","state_id":"1953"},
    {"city_id":"24933","city_name":"Ogawa","state_id":"1953"},
    {"city_id":"24934","city_name":"Oi","state_id":"1953"},
    {"city_id":"24935","city_name":"Okegawa","state_id":"1953"},
    {"city_id":"24936","city_name":"Omiya","state_id":"1953"},
    {"city_id":"24937","city_name":"Saitma-Shi","state_id":"1953"},
    {"city_id":"24938","city_name":"Sakado","state_id":"1953"},
    {"city_id":"24939","city_name":"Satte","state_id":"1953"},
    {"city_id":"24940","city_name":"Sayama","state_id":"1953"},
    {"city_id":"24941","city_name":"Shiki","state_id":"1953"},
    {"city_id":"24942","city_name":"Shiraoka","state_id":"1953"},
    {"city_id":"24943","city_name":"Shobu","state_id":"1953"},
    {"city_id":"24944","city_name":"Showa","state_id":"1953"},
    {"city_id":"24945","city_name":"Soka","state_id":"1953"},
    {"city_id":"24946","city_name":"Sugito","state_id":"1953"},
    {"city_id":"24947","city_name":"Toda","state_id":"1953"},
    {"city_id":"24948","city_name":"Tokorozawa","state_id":"1953"},
    {"city_id":"24949","city_name":"Tsurugashima","state_id":"1953"},
    {"city_id":"24950","city_name":"Urawa","state_id":"1953"},
    {"city_id":"24951","city_name":"Wako","state_id":"1953"},
    {"city_id":"24952","city_name":"Warabi","state_id":"1953"},
    {"city_id":"24953","city_name":"Washimiya","state_id":"1953"},
    {"city_id":"24954","city_name":"Yashio","state_id":"1953"},
    {"city_id":"24955","city_name":"Yono","state_id":"1953"},
    {"city_id":"24956","city_name":"Yorii","state_id":"1953"},
    {"city_id":"24957","city_name":"Yoshikawa","state_id":"1953"},
    {"city_id":"24958","city_name":"Yoshimi","state_id":"1953"},
    {"city_id":"24959","city_name":"Hikone","state_id":"1954"},
    {"city_id":"24960","city_name":"Hino","state_id":"1954"},
    {"city_id":"24961","city_name":"Konan","state_id":"1954"},
    {"city_id":"24962","city_name":"Kosei","state_id":"1954"},
    {"city_id":"24963","city_name":"Kusatsu","state_id":"1954"},
    {"city_id":"24964","city_name":"Minakuchi","state_id":"1954"},
    {"city_id":"24965","city_name":"Moriyama","state_id":"1954"},
    {"city_id":"24966","city_name":"Nagahama","state_id":"1954"},
    {"city_id":"24967","city_name":"Notogawa","state_id":"1954"},
    {"city_id":"24968","city_name":"Omihachiman","state_id":"1954"},
    {"city_id":"24969","city_name":"Otsu","state_id":"1954"},
    {"city_id":"24970","city_name":"Ritto","state_id":"1954"},
    {"city_id":"24971","city_name":"Shiga","state_id":"1954"},
    {"city_id":"24972","city_name":"Yasu","state_id":"1954"},
    {"city_id":"24973","city_name":"Yokaichi","state_id":"1954"},
    {"city_id":"24974","city_name":"Gotsu","state_id":"1955"},
    {"city_id":"24975","city_name":"Hamada","state_id":"1955"},
    {"city_id":"24976","city_name":"Hikawa","state_id":"1955"},
    {"city_id":"24977","city_name":"Hirata","state_id":"1955"},
    {"city_id":"24978","city_name":"Izumo","state_id":"1955"},
    {"city_id":"24979","city_name":"Masuda","state_id":"1955"},
    {"city_id":"24980","city_name":"Matsue","state_id":"1955"},
    {"city_id":"24981","city_name":"Oda","state_id":"1955"},
    {"city_id":"24982","city_name":"Yasugi","state_id":"1955"},
    {"city_id":"24983","city_name":"Asaba","state_id":"1956"},
    {"city_id":"24984","city_name":"Atami","state_id":"1956"},
    {"city_id":"24985","city_name":"Daito","state_id":"1956"},
    {"city_id":"24986","city_name":"Fuji","state_id":"1956"},
    {"city_id":"24987","city_name":"Fujieda","state_id":"1956"},
    {"city_id":"24988","city_name":"Fujinomiya","state_id":"1956"},
    {"city_id":"24989","city_name":"Fukuroi","state_id":"1956"},
    {"city_id":"24990","city_name":"Gotemba","state_id":"1956"},
    {"city_id":"24991","city_name":"Haibara","state_id":"1956"},
    {"city_id":"24992","city_name":"Hamakita","state_id":"1956"},
    {"city_id":"24993","city_name":"Hamamatsu","state_id":"1956"},
    {"city_id":"24994","city_name":"Hamaoka","state_id":"1956"},
    {"city_id":"24995","city_name":"Hosoe","state_id":"1956"},
    {"city_id":"24996","city_name":"Ito","state_id":"1956"},
    {"city_id":"24997","city_name":"Iwata","state_id":"1956"},
    {"city_id":"24998","city_name":"Kakegawa","state_id":"1956"},
    {"city_id":"24999","city_name":"Kanaya","state_id":"1956"},
    {"city_id":"25000","city_name":"Kannami","state_id":"1956"},
    {"city_id":"25001","city_name":"Kikugawa","state_id":"1956"},
    {"city_id":"25002","city_name":"Kosai","state_id":"1956"},
    {"city_id":"25003","city_name":"Mishima","state_id":"1956"},
    {"city_id":"25004","city_name":"Mori","state_id":"1956"},
    {"city_id":"25005","city_name":"Nagaizumi","state_id":"1956"},
    {"city_id":"25006","city_name":"Numazu","state_id":"1956"},
    {"city_id":"25007","city_name":"Oigawa","state_id":"1956"},
    {"city_id":"25008","city_name":"Oyama","state_id":"1956"},
    {"city_id":"25009","city_name":"Ryuyo","state_id":"1956"},
    {"city_id":"25010","city_name":"Sagara","state_id":"1956"},
    {"city_id":"25011","city_name":"Shimada","state_id":"1956"},
    {"city_id":"25012","city_name":"Shimizu","state_id":"1956"},
    {"city_id":"25013","city_name":"Shimoda","state_id":"1956"},
    {"city_id":"25014","city_name":"Shizuoka","state_id":"1956"},
    {"city_id":"25015","city_name":"Susono","state_id":"1956"},
    {"city_id":"25016","city_name":"Tenryu","state_id":"1956"},
    {"city_id":"25017","city_name":"Toyoda","state_id":"1956"},
    {"city_id":"25018","city_name":"Yaizu","state_id":"1956"},
    {"city_id":"25019","city_name":"Yoshida","state_id":"1956"},
    {"city_id":"25020","city_name":"Ashikaga","state_id":"1957"},
    {"city_id":"25021","city_name":"Fujioka","state_id":"1957"},
    {"city_id":"25022","city_name":"Imaichi","state_id":"1957"},
    {"city_id":"25023","city_name":"Iwafune","state_id":"1957"},
    {"city_id":"25024","city_name":"Kaminokawa","state_id":"1957"},
    {"city_id":"25025","city_name":"Kanuma","state_id":"1957"},
    {"city_id":"25026","city_name":"Karasuyama","state_id":"1957"},
    {"city_id":"25027","city_name":"Kawachi","state_id":"1957"},
    {"city_id":"25028","city_name":"Kuroiso","state_id":"1957"},
    {"city_id":"25029","city_name":"Mashiko","state_id":"1957"},
    {"city_id":"25030","city_name":"Mibu","state_id":"1957"},
    {"city_id":"25031","city_name":"Minamikawachi","state_id":"1957"},
    {"city_id":"25032","city_name":"Moka","state_id":"1957"},
    {"city_id":"25033","city_name":"Motegi","state_id":"1957"},
    {"city_id":"25034","city_name":"Nasu","state_id":"1957"},
    {"city_id":"25035","city_name":"Nikko","state_id":"1957"},
    {"city_id":"25036","city_name":"Nishinasuno","state_id":"1957"},
    {"city_id":"25037","city_name":"Nogi","state_id":"1957"},
    {"city_id":"25038","city_name":"Ohira","state_id":"1957"},
    {"city_id":"25039","city_name":"Otawara","state_id":"1957"},
    {"city_id":"25040","city_name":"Oyama","state_id":"1957"},
    {"city_id":"25041","city_name":"Sano","state_id":"1957"},
    {"city_id":"25042","city_name":"Takanezawa","state_id":"1957"},
    {"city_id":"25043","city_name":"Tanuma","state_id":"1957"},
    {"city_id":"25044","city_name":"Tochigi","state_id":"1957"},
    {"city_id":"25045","city_name":"Ujiie","state_id":"1957"},
    {"city_id":"25046","city_name":"Utsunomiya","state_id":"1957"},
    {"city_id":"25047","city_name":"Yaita","state_id":"1957"},
    {"city_id":"25048","city_name":"Aizumi","state_id":"1958"},
    {"city_id":"25049","city_name":"Anan","state_id":"1958"},
    {"city_id":"25050","city_name":"Ikeda","state_id":"1958"},
    {"city_id":"25051","city_name":"Ishii","state_id":"1958"},
    {"city_id":"25052","city_name":"Kamojima","state_id":"1958"},
    {"city_id":"25053","city_name":"Kitajima","state_id":"1958"},
    {"city_id":"25054","city_name":"Komatsushima","state_id":"1958"},
    {"city_id":"25055","city_name":"Naruto","state_id":"1958"},
    {"city_id":"25056","city_name":"Tokushima","state_id":"1958"},
    {"city_id":"25057","city_name":"Waki","state_id":"1958"},
    {"city_id":"25058","city_name":"Akiruno","state_id":"1959"},
    {"city_id":"25059","city_name":"Akishima","state_id":"1959"},
    {"city_id":"25060","city_name":"Chofu","state_id":"1959"},
    {"city_id":"25061","city_name":"Fuchu","state_id":"1959"},
    {"city_id":"25062","city_name":"Hachioji","state_id":"1959"},
    {"city_id":"25063","city_name":"Hamura","state_id":"1959"},
    {"city_id":"25064","city_name":"Higashikurume","state_id":"1959"},
    {"city_id":"25065","city_name":"Higashimurayama","state_id":"1959"},
    {"city_id":"25066","city_name":"Higashiyamato","state_id":"1959"},
    {"city_id":"25067","city_name":"Hino","state_id":"1959"},
    {"city_id":"25068","city_name":"Hoya","state_id":"1959"},
    {"city_id":"25069","city_name":"Inagi","state_id":"1959"},
    {"city_id":"25070","city_name":"Kiyose","state_id":"1959"},
    {"city_id":"25071","city_name":"Kodaira","state_id":"1959"},
    {"city_id":"25072","city_name":"Koganei","state_id":"1959"},
    {"city_id":"25073","city_name":"Kokubunji","state_id":"1959"},
    {"city_id":"25074","city_name":"Komae","state_id":"1959"},
    {"city_id":"25075","city_name":"Kunitachi","state_id":"1959"},
    {"city_id":"25076","city_name":"Machida","state_id":"1959"},
    {"city_id":"25077","city_name":"Mitaka","state_id":"1959"},
    {"city_id":"25078","city_name":"Mizuho","state_id":"1959"},
    {"city_id":"25079","city_name":"Musashimurayama","state_id":"1959"},
    {"city_id":"25080","city_name":"Musashino","state_id":"1959"},
    {"city_id":"25081","city_name":"Ome","state_id":"1959"},
    {"city_id":"25082","city_name":"Tachikawa","state_id":"1959"},
    {"city_id":"25083","city_name":"Tama","state_id":"1959"},
    {"city_id":"25084","city_name":"Tanashi","state_id":"1959"},
    {"city_id":"25085","city_name":"Tokyo","state_id":"1959"},
    {"city_id":"25086","city_name":"Kurayoshi","state_id":"1960"},
    {"city_id":"25087","city_name":"Sakaiminato","state_id":"1960"},
    {"city_id":"25088","city_name":"Tottori","state_id":"1960"},
    {"city_id":"25089","city_name":"Yonago","state_id":"1960"},
    {"city_id":"25090","city_name":"Fukumitsu","state_id":"1961"},
    {"city_id":"25091","city_name":"Himi","state_id":"1961"},
    {"city_id":"25092","city_name":"Kamiichi","state_id":"1961"},
    {"city_id":"25093","city_name":"Kosugi","state_id":"1961"},
    {"city_id":"25094","city_name":"Kurobe","state_id":"1961"},
    {"city_id":"25095","city_name":"Namerikawa","state_id":"1961"},
    {"city_id":"25096","city_name":"Nyuzen","state_id":"1961"},
    {"city_id":"25097","city_name":"Osawano","state_id":"1961"},
    {"city_id":"25098","city_name":"Oyabe","state_id":"1961"},
    {"city_id":"25099","city_name":"Shimminato","state_id":"1961"},
    {"city_id":"25100","city_name":"Takaoka","state_id":"1961"},
    {"city_id":"25101","city_name":"Tateyama","state_id":"1961"},
    {"city_id":"25102","city_name":"Tonami","state_id":"1961"},
    {"city_id":"25103","city_name":"Toyama","state_id":"1961"},
    {"city_id":"25104","city_name":"Uozu","state_id":"1961"},
    {"city_id":"25105","city_name":"Yatsuo","state_id":"1961"},
    {"city_id":"25106","city_name":"Arida","state_id":"1962"},
    {"city_id":"25107","city_name":"Gobo","state_id":"1962"},
    {"city_id":"25108","city_name":"Hashimoto","state_id":"1962"},
    {"city_id":"25109","city_name":"Iwade","state_id":"1962"},
    {"city_id":"25110","city_name":"Kainan","state_id":"1962"},
    {"city_id":"25111","city_name":"Katsuragi","state_id":"1962"},
    {"city_id":"25112","city_name":"Kishigawa","state_id":"1962"},
    {"city_id":"25113","city_name":"Nachikatsuura","state_id":"1962"},
    {"city_id":"25114","city_name":"Shingu","state_id":"1962"},
    {"city_id":"25115","city_name":"Tanabe","state_id":"1962"},
    {"city_id":"25116","city_name":"Wakayama","state_id":"1962"},
    {"city_id":"25117","city_name":"Higashine","state_id":"1963"},
    {"city_id":"25118","city_name":"Kahoku","state_id":"1963"},
    {"city_id":"25119","city_name":"Kaminoyama","state_id":"1963"},
    {"city_id":"25120","city_name":"Kawanishi","state_id":"1963"},
    {"city_id":"25121","city_name":"Murayama","state_id":"1963"},
    {"city_id":"25122","city_name":"Nagai","state_id":"1963"},
    {"city_id":"25123","city_name":"Nanyo","state_id":"1963"},
    {"city_id":"25124","city_name":"Obanazawa","state_id":"1963"},
    {"city_id":"25125","city_name":"Sagae","state_id":"1963"},
    {"city_id":"25126","city_name":"Sakata","state_id":"1963"},
    {"city_id":"25127","city_name":"Shinjo","state_id":"1963"},
    {"city_id":"25128","city_name":"Takahata","state_id":"1963"},
    {"city_id":"25129","city_name":"Tendo","state_id":"1963"},
    {"city_id":"25130","city_name":"Tsuchiura","state_id":"1963"},
    {"city_id":"25131","city_name":"Tsuruoka","state_id":"1963"},
    {"city_id":"25132","city_name":"Yamagata","state_id":"1963"},
    {"city_id":"25133","city_name":"Yonezawa","state_id":"1963"},
    {"city_id":"25134","city_name":"Yuza","state_id":"1963"},
    {"city_id":"25135","city_name":"Hagi","state_id":"1964"},
    {"city_id":"25136","city_name":"Hikari","state_id":"1964"},
    {"city_id":"25137","city_name":"Hofu","state_id":"1964"},
    {"city_id":"25138","city_name":"Iwakuni","state_id":"1964"},
    {"city_id":"25139","city_name":"Kudamatsu","state_id":"1964"},
    {"city_id":"25140","city_name":"Mine","state_id":"1964"},
    {"city_id":"25141","city_name":"Nagato","state_id":"1964"},
    {"city_id":"25142","city_name":"Ogori","state_id":"1964"},
    {"city_id":"25143","city_name":"Onoda","state_id":"1964"},
    {"city_id":"25144","city_name":"Sanyo","state_id":"1964"},
    {"city_id":"25145","city_name":"Shimonoseki","state_id":"1964"},
    {"city_id":"25146","city_name":"Shinnanyo","state_id":"1964"},
    {"city_id":"25147","city_name":"Tokuyama","state_id":"1964"},
    {"city_id":"25148","city_name":"Toyoura","state_id":"1964"},
    {"city_id":"25149","city_name":"Ube","state_id":"1964"},
    {"city_id":"25150","city_name":"Yamaguchi","state_id":"1964"},
    {"city_id":"25151","city_name":"Yanai","state_id":"1964"},
    {"city_id":"25152","city_name":"Enzan","state_id":"1965"},
    {"city_id":"25153","city_name":"Fujiyoshida","state_id":"1965"},
    {"city_id":"25154","city_name":"Isawa","state_id":"1965"},
    {"city_id":"25155","city_name":"Kofu","state_id":"1965"},
    {"city_id":"25156","city_name":"Nirasaki","state_id":"1965"},
    {"city_id":"25157","city_name":"Otsuki","state_id":"1965"},
    {"city_id":"25158","city_name":"Ryuo","state_id":"1965"},
    {"city_id":"25159","city_name":"Tsuru","state_id":"1965"},
    {"city_id":"25160","city_name":"Uenohara","state_id":"1965"},
    {"city_id":"25161","city_name":"Yamanashi","state_id":"1965"},
    {"city_id":"25162","city_name":"Grouville","state_id":"1966"},
    {"city_id":"25163","city_name":"Trinity","state_id":"1976"},
    {"city_id":"25164","city_name":"'Abbin","state_id":"1977"},
    {"city_id":"25165","city_name":"'Ajlun","state_id":"1977"},
    {"city_id":"25166","city_name":"'Anjarah","state_id":"1977"},
    {"city_id":"25167","city_name":"'Arjan","state_id":"1977"},
    {"city_id":"25168","city_name":"'Ayn Janna","state_id":"1977"},
    {"city_id":"25169","city_name":"Halawah","state_id":"1977"},
    {"city_id":"25170","city_name":"Kharbat al-Wahadnah","state_id":"1977"},
    {"city_id":"25171","city_name":"Kufranjah","state_id":"1977"},
    {"city_id":"25172","city_name":"Sakhrah","state_id":"1977"},
    {"city_id":"25173","city_name":"al-Hashamiyah","state_id":"1977"},
    {"city_id":"25174","city_name":"Abu 'Alanda","state_id":"1978"},
    {"city_id":"25175","city_name":"Amman","state_id":"1978"},
    {"city_id":"25176","city_name":"Askan Abu Nusayr","state_id":"1978"},
    {"city_id":"25177","city_name":"Jawa","state_id":"1978"},
    {"city_id":"25178","city_name":"Khalda wa Tila'-al-'Ali","state_id":"1978"},
    {"city_id":"25179","city_name":"Khraybat As-Suq","state_id":"1978"},
    {"city_id":"25180","city_name":"Marj al-Hammam","state_id":"1978"},
    {"city_id":"25181","city_name":"Na'ur","state_id":"1978"},
    {"city_id":"25182","city_name":"Nuzhat Sahab","state_id":"1978"},
    {"city_id":"25183","city_name":"Sahab","state_id":"1978"},
    {"city_id":"25184","city_name":"Shafa Badran","state_id":"1978"},
    {"city_id":"25185","city_name":"Suwaylih","state_id":"1978"},
    {"city_id":"25186","city_name":"Tariq","state_id":"1978"},
    {"city_id":"25187","city_name":"Umm As-Summaq","state_id":"1978"},
    {"city_id":"25188","city_name":"Umm Nuwarah","state_id":"1978"},
    {"city_id":"25189","city_name":"Umm Qusayr wal Muqabalin","state_id":"1978"},
    {"city_id":"25190","city_name":"Umm al-Basatin","state_id":"1978"},
    {"city_id":"25191","city_name":"Wadi as-Sir","state_id":"1978"},
    {"city_id":"25192","city_name":"al-'Abdaliyah","state_id":"1978"},
    {"city_id":"25193","city_name":"al-Binayat","state_id":"1978"},
    {"city_id":"25194","city_name":"al-Faysaliyah","state_id":"1978"},
    {"city_id":"25195","city_name":"al-Jizah","state_id":"1978"},
    {"city_id":"25196","city_name":"al-Jubayhah","state_id":"1978"},
    {"city_id":"25197","city_name":"al-Juwaydah","state_id":"1978"},
    {"city_id":"25198","city_name":"al-Quwaysimah","state_id":"1978"},
    {"city_id":"25199","city_name":"al-Yadudah","state_id":"1978"},
    {"city_id":"25200","city_name":"ar-Rawdah","state_id":"1978"},
    {"city_id":"25201","city_name":"at-Talbiyah","state_id":"1978"},
    {"city_id":"25202","city_name":"at-Taybah","state_id":"1978"},
    {"city_id":"25203","city_name":"'Al'al","state_id":"1979"},
    {"city_id":"25204","city_name":"'Anbah","state_id":"1979"},
    {"city_id":"25205","city_name":"Arhaba","state_id":"1979"},
    {"city_id":"25206","city_name":"Aydun","state_id":"1979"},
    {"city_id":"25207","city_name":"Bayt Idas","state_id":"1979"},
    {"city_id":"25208","city_name":"Bayt Ras","state_id":"1979"},
    {"city_id":"25209","city_name":"Bayt Yafa","state_id":"1979"},
    {"city_id":"25210","city_name":"Bushra","state_id":"1979"},
    {"city_id":"25211","city_name":"Dayr Abi Sa'id","state_id":"1979"},
    {"city_id":"25212","city_name":"Dayr Yusif","state_id":"1979"},
    {"city_id":"25213","city_name":"Dayr as-Sa'anah","state_id":"1979"},
    {"city_id":"25214","city_name":"Duwaqarah","state_id":"1979"},
    {"city_id":"25215","city_name":"Hakama","state_id":"1979"},
    {"city_id":"25216","city_name":"Hartha","state_id":"1979"},
    {"city_id":"25217","city_name":"Hatam","state_id":"1979"},
    {"city_id":"25218","city_name":"Hawwarah","state_id":"1979"},
    {"city_id":"25219","city_name":"Irbid","state_id":"1979"},
    {"city_id":"25220","city_name":"Jasar ash-Shaykh Hussayn","state_id":"1979"},
    {"city_id":"25221","city_name":"Judayta","state_id":"1979"},
    {"city_id":"25222","city_name":"Katam","state_id":"1979"},
    {"city_id":"25223","city_name":"Kharja","state_id":"1979"},
    {"city_id":"25224","city_name":"Kufur 'Awan","state_id":"1979"},
    {"city_id":"25225","city_name":"Kufur Abil","state_id":"1979"},
    {"city_id":"25226","city_name":"Kufur Asad","state_id":"1979"},
    {"city_id":"25227","city_name":"Kufur Rakab","state_id":"1979"},
    {"city_id":"25228","city_name":"Kufur Sum","state_id":"1979"},
    {"city_id":"25229","city_name":"Kufur Yuba","state_id":"1979"},
    {"city_id":"25230","city_name":"Kufur al-Ma'","state_id":"1979"},
    {"city_id":"25231","city_name":"Kuraymah","state_id":"1979"},
    {"city_id":"25232","city_name":"Malka","state_id":"1979"},
    {"city_id":"25233","city_name":"Mukhayyam al-Husun","state_id":"1979"},
    {"city_id":"25234","city_name":"Qumaym","state_id":"1979"},
    {"city_id":"25235","city_name":"Saham","state_id":"1979"},
    {"city_id":"25236","city_name":"Sal","state_id":"1979"},
    {"city_id":"25237","city_name":"Samma","state_id":"1979"},
    {"city_id":"25238","city_name":"Sammaw'","state_id":"1979"},
    {"city_id":"25239","city_name":"Sum","state_id":"1979"},
    {"city_id":"25240","city_name":"Tabnah","state_id":"1979"},
    {"city_id":"25241","city_name":"Umm Qays","state_id":"1979"},
    {"city_id":"25242","city_name":"Wadi al-Yabas","state_id":"1979"},
    {"city_id":"25243","city_name":"Waqqas","state_id":"1979"},
    {"city_id":"25244","city_name":"Zahar","state_id":"1979"},
    {"city_id":"25245","city_name":"al-Ashrafiyah","state_id":"1979"},
    {"city_id":"25246","city_name":"al-Balawnah","state_id":"1979"},
    {"city_id":"25247","city_name":"al-Buwaydah","state_id":"1979"},
    {"city_id":"25248","city_name":"al-Husun","state_id":"1979"},
    {"city_id":"25249","city_name":"al-Manshiyah","state_id":"1979"},
    {"city_id":"25250","city_name":"al-Mashari'a","state_id":"1979"},
    {"city_id":"25251","city_name":"al-Mazar ash-Shamaliyah","state_id":"1979"},
    {"city_id":"25252","city_name":"al-Mughayyar","state_id":"1979"},
    {"city_id":"25253","city_name":"an-Nu'aymeh","state_id":"1979"},
    {"city_id":"25254","city_name":"ar-Ramtha","state_id":"1979"},
    {"city_id":"25255","city_name":"as-Sarih","state_id":"1979"},
    {"city_id":"25256","city_name":"ash-Shajarah","state_id":"1979"},
    {"city_id":"25257","city_name":"ash-Shunah ash-Shamaliyah","state_id":"1979"},
    {"city_id":"25258","city_name":"at-Taybah","state_id":"1979"},
    {"city_id":"25259","city_name":"at-Turrah","state_id":"1979"},
    {"city_id":"25260","city_name":"Balila","state_id":"1980"},
    {"city_id":"25261","city_name":"Burma","state_id":"1980"},
    {"city_id":"25262","city_name":"Jarash","state_id":"1980"},
    {"city_id":"25263","city_name":"Kufur Khall","state_id":"1980"},
    {"city_id":"25264","city_name":"Mukhayyam Ghazzah","state_id":"1980"},
    {"city_id":"25265","city_name":"Mukhayyam Suf","state_id":"1980"},
    {"city_id":"25266","city_name":"Qafqafa","state_id":"1980"},
    {"city_id":"25267","city_name":"Raymun","state_id":"1980"},
    {"city_id":"25268","city_name":"Sakab","state_id":"1980"},
    {"city_id":"25269","city_name":"Suf","state_id":"1980"},
    {"city_id":"25270","city_name":"al-Kattah","state_id":"1980"},
    {"city_id":"25271","city_name":"Bayar","state_id":"1981"},
    {"city_id":"25272","city_name":"Ma'an","state_id":"1981"},
    {"city_id":"25273","city_name":"Wadi Musa","state_id":"1981"},
    {"city_id":"25274","city_name":"al-Hussayniyah","state_id":"1981"},
    {"city_id":"25275","city_name":"al-Jafar","state_id":"1981"},
    {"city_id":"25276","city_name":"at-Taybah","state_id":"1981"},
    {"city_id":"25277","city_name":"Dhiban","state_id":"1982"},
    {"city_id":"25278","city_name":"Ma'in","state_id":"1982"},
    {"city_id":"25279","city_name":"Madaba","state_id":"1982"},
    {"city_id":"25280","city_name":"Mulayh","state_id":"1982"},
    {"city_id":"25281","city_name":"al-Faysaliyah","state_id":"1982"},
    {"city_id":"25282","city_name":"al-'Aqabah","state_id":"1983"},
    {"city_id":"25283","city_name":"al-Quwayrah","state_id":"1983"},
    {"city_id":"25284","city_name":"'Ayn al-Basha","state_id":"1984"},
    {"city_id":"25285","city_name":"Darar","state_id":"1984"},
    {"city_id":"25286","city_name":"Juwafat al-Kafrayn","state_id":"1984"},
    {"city_id":"25287","city_name":"Ma'addi","state_id":"1984"},
    {"city_id":"25288","city_name":"Mahas","state_id":"1984"},
    {"city_id":"25289","city_name":"Safut","state_id":"1984"},
    {"city_id":"25290","city_name":"Suknat ash-Shunah","state_id":"1984"},
    {"city_id":"25291","city_name":"Yarqa","state_id":"1984"},
    {"city_id":"25292","city_name":"al-Baq'ah","state_id":"1984"},
    {"city_id":"25293","city_name":"al-Fuhays","state_id":"1984"},
    {"city_id":"25294","city_name":"al-Karamah","state_id":"1984"},
    {"city_id":"25295","city_name":"ar-Rawdah","state_id":"1984"},
    {"city_id":"25296","city_name":"as-Salt","state_id":"1984"},
    {"city_id":"25297","city_name":"as-Sawalhah","state_id":"1984"},
    {"city_id":"25298","city_name":"as-Subayhi","state_id":"1984"},
    {"city_id":"25299","city_name":"at-Tawal al-Janubi","state_id":"1984"},
    {"city_id":"25300","city_name":"at-Tuwal ash-Shamali","state_id":"1984"},
    {"city_id":"25301","city_name":"'Ayy","state_id":"1985"},
    {"city_id":"25302","city_name":"Adar","state_id":"1985"},
    {"city_id":"25303","city_name":"Faqqu'","state_id":"1985"},
    {"city_id":"25304","city_name":"Ghawr al-Mazra'ah","state_id":"1985"},
    {"city_id":"25305","city_name":"Ghawr as-Safi","state_id":"1985"},
    {"city_id":"25306","city_name":"Maw'tah","state_id":"1985"},
    {"city_id":"25307","city_name":"Sirfa","state_id":"1985"},
    {"city_id":"25308","city_name":"al-Hussayniyah","state_id":"1985"},
    {"city_id":"25309","city_name":"al-Karak","state_id":"1985"},
    {"city_id":"25310","city_name":"al-Mazar","state_id":"1985"},
    {"city_id":"25311","city_name":"al-Qasr","state_id":"1985"},
    {"city_id":"25312","city_name":"al-Qitranah","state_id":"1985"},
    {"city_id":"25313","city_name":"ar-Rabbah","state_id":"1985"},
    {"city_id":"25314","city_name":"ash-Shahabiyah","state_id":"1985"},
    {"city_id":"25315","city_name":"at-Taybah","state_id":"1985"},
    {"city_id":"25316","city_name":"Badiyat ar-Ruwayshid","state_id":"1986"},
    {"city_id":"25317","city_name":"Bal'ama","state_id":"1986"},
    {"city_id":"25318","city_name":"Manshiyat Bani Hassan","state_id":"1986"},
    {"city_id":"25319","city_name":"Mugayyar as-Sarhan","state_id":"1986"},
    {"city_id":"25320","city_name":"Sabha","state_id":"1986"},
    {"city_id":"25321","city_name":"Sama as-Sarhan","state_id":"1986"},
    {"city_id":"25322","city_name":"Umm al-Quttayn","state_id":"1986"},
    {"city_id":"25323","city_name":"al-Hamra'","state_id":"1986"},
    {"city_id":"25324","city_name":"al-Khaldiyah al-Jadidah","state_id":"1986"},
    {"city_id":"25325","city_name":"al-Mabrukah","state_id":"1986"},
    {"city_id":"25326","city_name":"al-Mafraq","state_id":"1986"},
    {"city_id":"25327","city_name":"ar-Ruwayshid","state_id":"1986"},
    {"city_id":"25328","city_name":"az-Za'tari","state_id":"1986"},
    {"city_id":"25329","city_name":"Busayrah","state_id":"1987"},
    {"city_id":"25330","city_name":"al-'Ayn al-Bayda","state_id":"1987"},
    {"city_id":"25331","city_name":"al-Hasa","state_id":"1987"},
    {"city_id":"25332","city_name":"al-Qadisiyah","state_id":"1987"},
    {"city_id":"25333","city_name":"at-Tafilah","state_id":"1987"},
    {"city_id":"25334","city_name":"Iskan al-Hashamiyah","state_id":"1988"},
    {"city_id":"25335","city_name":"Mushayrfat Ras al-'Ayn","state_id":"1988"},
    {"city_id":"25336","city_name":"Shnillar","state_id":"1988"},
    {"city_id":"25337","city_name":"ad-Dulayl","state_id":"1988"},
    {"city_id":"25338","city_name":"al-Azraq ash-Shamali","state_id":"1988"},
    {"city_id":"25339","city_name":"al-Hashamiyah","state_id":"1988"},
    {"city_id":"25340","city_name":"ar-Russayfah","state_id":"1988"},
    {"city_id":"25341","city_name":"as-Sukhnah","state_id":"1988"},
    {"city_id":"25342","city_name":"az-Zarqa'","state_id":"1988"},
    {"city_id":"25343","city_name":"Akmecet","state_id":"1989"},
    {"city_id":"25344","city_name":"Aral","state_id":"1989"},
    {"city_id":"25345","city_name":"Baykonir","state_id":"1989"},
    {"city_id":"25346","city_name":"Canakazali","state_id":"1989"},
    {"city_id":"25347","city_name":"Canakorgan","state_id":"1989"},
    {"city_id":"25348","city_name":"Cosali","state_id":"1989"},
    {"city_id":"25349","city_name":"Kazali","state_id":"1989"},
    {"city_id":"25350","city_name":"Sieli","state_id":"1989"},
    {"city_id":"25351","city_name":"Tasboget","state_id":"1989"},
    {"city_id":"25352","city_name":"Aksu","state_id":"1990"},
    {"city_id":"25353","city_name":"Alekseevka","state_id":"1990"},
    {"city_id":"25354","city_name":"Astana","state_id":"1990"},
    {"city_id":"25355","city_name":"Atbasar","state_id":"1990"},
    {"city_id":"25356","city_name":"Bestobe","state_id":"1990"},
    {"city_id":"25357","city_name":"Caltir","state_id":"1990"},
    {"city_id":"25358","city_name":"Colimbet","state_id":"1990"},
    {"city_id":"25359","city_name":"Ereymentau","state_id":"1990"},
    {"city_id":"25360","city_name":"Koksetau","state_id":"1990"},
    {"city_id":"25361","city_name":"Makin","state_id":"1990"},
    {"city_id":"25362","city_name":"Stepnogorsk","state_id":"1990"},
    {"city_id":"25363","city_name":"Aktobe","state_id":"1991"},
    {"city_id":"25364","city_name":"Alka","state_id":"1991"},
    {"city_id":"25365","city_name":"Batamsi","state_id":"1991"},
    {"city_id":"25366","city_name":"Embi","state_id":"1991"},
    {"city_id":"25367","city_name":"Hromtau","state_id":"1991"},
    {"city_id":"25368","city_name":"Kandagac","state_id":"1991"},
    {"city_id":"25369","city_name":"Salkar","state_id":"1991"},
    {"city_id":"25370","city_name":"Subarkudik","state_id":"1991"},
    {"city_id":"25371","city_name":"Subarsi","state_id":"1991"},
    {"city_id":"25372","city_name":"Temir","state_id":"1991"},
    {"city_id":"25373","city_name":"Almati","state_id":"1992"},
    {"city_id":"25374","city_name":"Almaty","state_id":"1992"},
    {"city_id":"25375","city_name":"Boralday","state_id":"1992"},
    {"city_id":"25376","city_name":"Carkant","state_id":"1992"},
    {"city_id":"25377","city_name":"Energetyceskiy","state_id":"1992"},
    {"city_id":"25378","city_name":"Esik","state_id":"1992"},
    {"city_id":"25379","city_name":"Fabrichniy","state_id":"1992"},
    {"city_id":"25380","city_name":"Kapsagay","state_id":"1992"},
    {"city_id":"25381","city_name":"Karabulak","state_id":"1992"},
    {"city_id":"25382","city_name":"Kaskelen","state_id":"1992"},
    {"city_id":"25383","city_name":"Kirov","state_id":"1992"},
    {"city_id":"25384","city_name":"Sariozek","state_id":"1992"},
    {"city_id":"25385","city_name":"Sarkand","state_id":"1992"},
    {"city_id":"25386","city_name":"Selek","state_id":"1992"},
    {"city_id":"25387","city_name":"Taldikorgan","state_id":"1992"},
    {"city_id":"25388","city_name":"Talgar","state_id":"1992"},
    {"city_id":"25389","city_name":"Tekeli","state_id":"1992"},
    {"city_id":"25390","city_name":"Ustobe","state_id":"1992"},
    {"city_id":"25391","city_name":"Uzunagac","state_id":"1992"},
    {"city_id":"25392","city_name":"Atirau","state_id":"1993"},
    {"city_id":"25393","city_name":"Atyrau","state_id":"1993"},
    {"city_id":"25394","city_name":"Dossor","state_id":"1993"},
    {"city_id":"25395","city_name":"Inderbor","state_id":"1993"},
    {"city_id":"25396","city_name":"Karaton","state_id":"1993"},
    {"city_id":"25397","city_name":"Kulsari","state_id":"1993"},
    {"city_id":"25398","city_name":"Makat","state_id":"1993"},
    {"city_id":"25399","city_name":"Oporni","state_id":"1993"},
    {"city_id":"25400","city_name":"Sumisker","state_id":"1993"},
    {"city_id":"25401","city_name":"Abay","state_id":"1996"},
    {"city_id":"25402","city_name":"Akadir","state_id":"1996"},
    {"city_id":"25403","city_name":"Aktas","state_id":"1996"},
    {"city_id":"25404","city_name":"Aktau","state_id":"1996"},
    {"city_id":"25405","city_name":"Atasu","state_id":"1996"},
    {"city_id":"25406","city_name":"Balkas","state_id":"1996"},
    {"city_id":"25407","city_name":"Cayrem","state_id":"1996"},
    {"city_id":"25408","city_name":"Cezkazgan","state_id":"1996"},
    {"city_id":"25409","city_name":"Gulsat","state_id":"1996"},
    {"city_id":"25410","city_name":"Karacal","state_id":"1996"},
    {"city_id":"25411","city_name":"Karaganda","state_id":"1996"},
    {"city_id":"25412","city_name":"Karkarali","state_id":"1996"},
    {"city_id":"25413","city_name":"Karsakbay","state_id":"1996"},
    {"city_id":"25414","city_name":"Konrat","state_id":"1996"},
    {"city_id":"25415","city_name":"Novodolinskiy","state_id":"1996"},
    {"city_id":"25416","city_name":"Osakarovka","state_id":"1996"},
    {"city_id":"25417","city_name":"Sahti","state_id":"1996"},
    {"city_id":"25418","city_name":"Saran","state_id":"1996"},
    {"city_id":"25419","city_name":"Sarisagan","state_id":"1996"},
    {"city_id":"25420","city_name":"Satpaev","state_id":"1996"},
    {"city_id":"25421","city_name":"Temirtau","state_id":"1996"},
    {"city_id":"25422","city_name":"Tokaryovka","state_id":"1996"},
    {"city_id":"25423","city_name":"Arkalik","state_id":"1997"},
    {"city_id":"25424","city_name":"Borovskoy","state_id":"1997"},
    {"city_id":"25425","city_name":"Cetikara","state_id":"1997"},
    {"city_id":"25426","city_name":"Derjavinsk","state_id":"1997"},
    {"city_id":"25427","city_name":"Esil","state_id":"1997"},
    {"city_id":"25428","city_name":"Fodorov","state_id":"1997"},
    {"city_id":"25429","city_name":"Kacar","state_id":"1997"},
    {"city_id":"25430","city_name":"Komsomol","state_id":"1997"},
    {"city_id":"25431","city_name":"Kostanay","state_id":"1997"},
    {"city_id":"25432","city_name":"Kusmurin","state_id":"1997"},
    {"city_id":"25433","city_name":"Lisakovsk","state_id":"1997"},
    {"city_id":"25434","city_name":"Rudni","state_id":"1997"},
    {"city_id":"25435","city_name":"Tobol","state_id":"1997"},
    {"city_id":"25436","city_name":"Uritsk","state_id":"1997"},
    {"city_id":"25437","city_name":"Zatobolsk","state_id":"1997"},
    {"city_id":"25438","city_name":"Aktau","state_id":"1998"},
    {"city_id":"25439","city_name":"Beyneu","state_id":"1998"},
    {"city_id":"25440","city_name":"Canaozen","state_id":"1998"},
    {"city_id":"25441","city_name":"Cetibay","state_id":"1998"},
    {"city_id":"25442","city_name":"Fort-Sevcenko","state_id":"1998"},
    {"city_id":"25443","city_name":"Kurik","state_id":"1998"},
    {"city_id":"25444","city_name":"Mangyslak","state_id":"1998"},
    {"city_id":"25445","city_name":"Setpe","state_id":"1998"},
    {"city_id":"25446","city_name":"Ekibastuz","state_id":"2000"},
    {"city_id":"25447","city_name":"Ermak","state_id":"2000"},
    {"city_id":"25448","city_name":"Ertis","state_id":"2000"},
    {"city_id":"25449","city_name":"Kaciri","state_id":"2000"},
    {"city_id":"25450","city_name":"Maykain","state_id":"2000"},
    {"city_id":"25451","city_name":"Pavlodar","state_id":"2000"},
    {"city_id":"25452","city_name":"Sarbakti","state_id":"2000"},
    {"city_id":"25453","city_name":"Canatas","state_id":"2003"},
    {"city_id":"25454","city_name":"Georgiyev","state_id":"2003"},
    {"city_id":"25455","city_name":"Karatau","state_id":"2003"},
    {"city_id":"25456","city_name":"Lugovoy","state_id":"2003"},
    {"city_id":"25457","city_name":"Merke","state_id":"2003"},
    {"city_id":"25458","city_name":"Mihaylov","state_id":"2003"},
    {"city_id":"25459","city_name":"Oytal","state_id":"2003"},
    {"city_id":"25460","city_name":"Su","state_id":"2003"},
    {"city_id":"25461","city_name":"Taraz","state_id":"2003"},
    {"city_id":"25462","city_name":"Gilgil","state_id":"2004"},
    {"city_id":"25463","city_name":"Karatina","state_id":"2004"},
    {"city_id":"25464","city_name":"Kiambu","state_id":"2004"},
    {"city_id":"25465","city_name":"Kijabe","state_id":"2004"},
    {"city_id":"25466","city_name":"Kikuyu","state_id":"2004"},
    {"city_id":"25467","city_name":"Limuru","state_id":"2004"},
    {"city_id":"25468","city_name":"Maragua","state_id":"2004"},
    {"city_id":"25469","city_name":"Muranga","state_id":"2004"},
    {"city_id":"25470","city_name":"Nyeri","state_id":"2004"},
    {"city_id":"25471","city_name":"Ruiru","state_id":"2004"},
    {"city_id":"25472","city_name":"Sagana","state_id":"2004"},
    {"city_id":"25473","city_name":"Thika","state_id":"2004"},
    {"city_id":"25474","city_name":"Vanga","state_id":"2004"},
    {"city_id":"25475","city_name":"Changamwe","state_id":"2005"},
    {"city_id":"25476","city_name":"Garsen","state_id":"2005"},
    {"city_id":"25477","city_name":"Gazi","state_id":"2005"},
    {"city_id":"25478","city_name":"Hola","state_id":"2005"},
    {"city_id":"25479","city_name":"Kaloleni","state_id":"2005"},
    {"city_id":"25480","city_name":"Kilifi","state_id":"2005"},
    {"city_id":"25481","city_name":"Kinango","state_id":"2005"},
    {"city_id":"25482","city_name":"Kipini","state_id":"2005"},
    {"city_id":"25483","city_name":"Kwale","state_id":"2005"},
    {"city_id":"25484","city_name":"Lamu","state_id":"2005"},
    {"city_id":"25485","city_name":"Malindi","state_id":"2005"},
    {"city_id":"25486","city_name":"Mambrui","state_id":"2005"},
    {"city_id":"25487","city_name":"Mombasa","state_id":"2005"},
    {"city_id":"25488","city_name":"Sawasawa","state_id":"2005"},
    {"city_id":"25489","city_name":"Shimoni","state_id":"2005"},
    {"city_id":"25490","city_name":"Takaungu","state_id":"2005"},
    {"city_id":"25491","city_name":"Taveta","state_id":"2005"},
    {"city_id":"25492","city_name":"Voi","state_id":"2005"},
    {"city_id":"25493","city_name":"Witu","state_id":"2005"},
    {"city_id":"25494","city_name":"Wundanyi","state_id":"2005"},
    {"city_id":"25495","city_name":"Athi River","state_id":"2006"},
    {"city_id":"25496","city_name":"Chuka","state_id":"2006"},
    {"city_id":"25497","city_name":"Embu","state_id":"2006"},
    {"city_id":"25498","city_name":"Isiolo","state_id":"2006"},
    {"city_id":"25499","city_name":"Kangonde","state_id":"2006"},
    {"city_id":"25500","city_name":"Kitui","state_id":"2006"},
    {"city_id":"25501","city_name":"Machakos","state_id":"2006"},
    {"city_id":"25502","city_name":"Mado Gashi","state_id":"2006"},
    {"city_id":"25503","city_name":"Marsabit","state_id":"2006"},
    {"city_id":"25504","city_name":"Meru","state_id":"2006"},
    {"city_id":"25505","city_name":"Moyale","state_id":"2006"},
    {"city_id":"25506","city_name":"Mutomo","state_id":"2006"},
    {"city_id":"25507","city_name":"Nairobi","state_id":"2007"},
    {"city_id":"25508","city_name":"Homa Bay","state_id":"2009"},
    {"city_id":"25509","city_name":"Kisii","state_id":"2009"},
    {"city_id":"25510","city_name":"Kisumu","state_id":"2009"},
    {"city_id":"25511","city_name":"Migori","state_id":"2009"},
    {"city_id":"25512","city_name":"Bungoma","state_id":"2011"},
    {"city_id":"25513","city_name":"Busia","state_id":"2011"},
    {"city_id":"25514","city_name":"Kakamega","state_id":"2011"},
    {"city_id":"25515","city_name":"Mumias","state_id":"2011"},
    {"city_id":"25516","city_name":"Webuye","state_id":"2011"},
    {"city_id":"25517","city_name":"Taburao","state_id":"2012"},
    {"city_id":"25518","city_name":"Binoinano","state_id":"2013"},
    {"city_id":"25519","city_name":"Takaeang","state_id":"2014"},
    {"city_id":"25520","city_name":"Roreti","state_id":"2015"},
    {"city_id":"25521","city_name":"Ooma","state_id":"2016"},
    {"city_id":"25522","city_name":"Tabukiniberu","state_id":"2017"},
    {"city_id":"25523","city_name":"Butaritari","state_id":"2018"},
    {"city_id":"25524","city_name":"London","state_id":"2019"},
    {"city_id":"25525","city_name":"Tabontebike","state_id":"2020"},
    {"city_id":"25526","city_name":"Tabiauea","state_id":"2021"},
    {"city_id":"25527","city_name":"Makin","state_id":"2022"},
    {"city_id":"25528","city_name":"Rawannawi","state_id":"2023"},
    {"city_id":"25529","city_name":"Rungata","state_id":"2024"},
    {"city_id":"25530","city_name":"Temaraia","state_id":"2025"},
    {"city_id":"25531","city_name":"Ijaki","state_id":"2026"},
    {"city_id":"25532","city_name":"Nabari","state_id":"2030"},
    {"city_id":"25533","city_name":"Abaokoro","state_id":"2031"},
    {"city_id":"25534","city_name":"Washington","state_id":"2034"},
    {"city_id":"25535","city_name":"Kanggye","state_id":"2035"},
    {"city_id":"25536","city_name":"Cheongjin","state_id":"2036"},
    {"city_id":"25537","city_name":"Kimchaek","state_id":"2036"},
    {"city_id":"25538","city_name":"Najin","state_id":"2036"},
    {"city_id":"25539","city_name":"Hamheung","state_id":"2037"},
    {"city_id":"25540","city_name":"Sariweon","state_id":"2038"},
    {"city_id":"25541","city_name":"Seongnim","state_id":"2038"},
    {"city_id":"25542","city_name":"Haeju","state_id":"2039"},
    {"city_id":"25543","city_name":"Kaeseong","state_id":"2040"},
    {"city_id":"25544","city_name":"Weonsan","state_id":"2041"},
    {"city_id":"25545","city_name":"Nampo","state_id":"2042"},
    {"city_id":"25546","city_name":"Sineuiju","state_id":"2043"},
    {"city_id":"25547","city_name":"Phyeongseong","state_id":"2044"},
    {"city_id":"25548","city_name":"Pyeongyang","state_id":"2045"},
    {"city_id":"25549","city_name":"Pyongyang","state_id":"2045"},
    {"city_id":"25550","city_name":"Hyesan","state_id":"2046"},
    {"city_id":"25551","city_name":"Busan","state_id":"2047"},
    {"city_id":"25552","city_name":"Aeweol","state_id":"2048"},
    {"city_id":"25553","city_name":"Cheju","state_id":"2048"},
    {"city_id":"25554","city_name":"Seogwipo","state_id":"2048"},
    {"city_id":"25555","city_name":"Cheonju","state_id":"2049"},
    {"city_id":"25556","city_name":"Chongup","state_id":"2049"},
    {"city_id":"25557","city_name":"Iksan","state_id":"2049"},
    {"city_id":"25558","city_name":"Kimje","state_id":"2049"},
    {"city_id":"25559","city_name":"Kochang","state_id":"2049"},
    {"city_id":"25560","city_name":"Kunsan","state_id":"2049"},
    {"city_id":"25561","city_name":"Namwon","state_id":"2049"},
    {"city_id":"25562","city_name":"Puan","state_id":"2049"},
    {"city_id":"25563","city_name":"Haenam","state_id":"2050"},
    {"city_id":"25564","city_name":"Hwasun","state_id":"2050"},
    {"city_id":"25565","city_name":"Kwangyang","state_id":"2050"},
    {"city_id":"25566","city_name":"Kwangyang Up","state_id":"2050"},
    {"city_id":"25567","city_name":"Mokpo","state_id":"2050"},
    {"city_id":"25568","city_name":"Naju","state_id":"2050"},
    {"city_id":"25569","city_name":"Peolgyo","state_id":"2050"},
    {"city_id":"25570","city_name":"Suncheon","state_id":"2050"},
    {"city_id":"25571","city_name":"Yeocheon","state_id":"2050"},
    {"city_id":"25572","city_name":"Yeosu","state_id":"2050"},
    {"city_id":"25573","city_name":"Yonggwang","state_id":"2050"},
    {"city_id":"25574","city_name":"Cheongwon-gun","state_id":"2051"},
    {"city_id":"25575","city_name":"Eumsung-Kun","state_id":"2051"},
    {"city_id":"25576","city_name":"Jinchunkun","state_id":"2051"},
    {"city_id":"25577","city_name":"Okcheon-gun","state_id":"2051"},
    {"city_id":"25578","city_name":"Um Sung Gun","state_id":"2051"},
    {"city_id":"25579","city_name":"Chechon","state_id":"2052"},
    {"city_id":"25580","city_name":"Cheongju","state_id":"2052"},
    {"city_id":"25581","city_name":"Chincheon","state_id":"2052"},
    {"city_id":"25582","city_name":"Chungju","state_id":"2052"},
    {"city_id":"25583","city_name":"Jincheon-gun","state_id":"2052"},
    {"city_id":"25584","city_name":"Okchon","state_id":"2052"},
    {"city_id":"25585","city_name":"Yongdong","state_id":"2052"},
    {"city_id":"25586","city_name":"Asan","state_id":"2053"},
    {"city_id":"25587","city_name":"Cheonan","state_id":"2053"},
    {"city_id":"25588","city_name":"Chochiwon","state_id":"2053"},
    {"city_id":"25589","city_name":"Geumsan-Gun","state_id":"2053"},
    {"city_id":"25590","city_name":"Hongseong","state_id":"2053"},
    {"city_id":"25591","city_name":"Kongju","state_id":"2053"},
    {"city_id":"25592","city_name":"Kumsan","state_id":"2053"},
    {"city_id":"25593","city_name":"Nonsan","state_id":"2053"},
    {"city_id":"25594","city_name":"Poryong","state_id":"2053"},
    {"city_id":"25595","city_name":"Puyeo","state_id":"2053"},
    {"city_id":"25596","city_name":"Seonghwan","state_id":"2053"},
    {"city_id":"25597","city_name":"Seosan","state_id":"2053"},
    {"city_id":"25598","city_name":"Taean","state_id":"2053"},
    {"city_id":"25599","city_name":"Taesan","state_id":"2053"},
    {"city_id":"25600","city_name":"Tangjin","state_id":"2053"},
    {"city_id":"25601","city_name":"Yesan","state_id":"2053"},
    {"city_id":"25602","city_name":"Yonmu","state_id":"2053"},
    {"city_id":"25603","city_name":"Chonan","state_id":"2054"},
    {"city_id":"25604","city_name":"Danjin-gun","state_id":"2054"},
    {"city_id":"25605","city_name":"Gongjusi","state_id":"2054"},
    {"city_id":"25606","city_name":"Daegu","state_id":"2055"},
    {"city_id":"25607","city_name":"Dalseo-gu","state_id":"2055"},
    {"city_id":"25608","city_name":"Chungcheong","state_id":"2056"},
    {"city_id":"25609","city_name":"Hoengseong","state_id":"2056"},
    {"city_id":"25610","city_name":"Mummakeup","state_id":"2056"},
    {"city_id":"25611","city_name":"Wonju","state_id":"2056"},
    {"city_id":"25612","city_name":"Gimhae","state_id":"2057"},
    {"city_id":"25613","city_name":"Goyang-si","state_id":"2057"},
    {"city_id":"25614","city_name":"Ansan","state_id":"2058"},
    {"city_id":"25615","city_name":"Anyang","state_id":"2058"},
    {"city_id":"25616","city_name":"Anyang-Si","state_id":"2058"},
    {"city_id":"25617","city_name":"Anyangsi","state_id":"2058"},
    {"city_id":"25618","city_name":"Buchon","state_id":"2058"},
    {"city_id":"25619","city_name":"Buchun-Si","state_id":"2058"},
    {"city_id":"25620","city_name":"Byeoryangdong","state_id":"2058"},
    {"city_id":"25621","city_name":"Dongan-gu","state_id":"2058"},
    {"city_id":"25622","city_name":"Gimpo City","state_id":"2058"},
    {"city_id":"25623","city_name":"Goyang","state_id":"2058"},
    {"city_id":"25624","city_name":"Gupo-si","state_id":"2058"},
    {"city_id":"25625","city_name":"Guri-Si","state_id":"2058"},
    {"city_id":"25626","city_name":"Gwacheon Si","state_id":"2058"},
    {"city_id":"25627","city_name":"Gwangju-Si","state_id":"2058"},
    {"city_id":"25628","city_name":"Hwaseong-si","state_id":"2058"},
    {"city_id":"25629","city_name":"Icheon-si","state_id":"2058"},
    {"city_id":"25630","city_name":"Iljuk-myeon","state_id":"2058"},
    {"city_id":"25631","city_name":"Kwangju","state_id":"2058"},
    {"city_id":"25632","city_name":"Pocheon-Si","state_id":"2058"},
    {"city_id":"25633","city_name":"Seongnam","state_id":"2058"},
    {"city_id":"25634","city_name":"Shihung-Si","state_id":"2058"},
    {"city_id":"25635","city_name":"Siheung","state_id":"2058"},
    {"city_id":"25636","city_name":"Sihung-si","state_id":"2058"},
    {"city_id":"25637","city_name":"Suwon","state_id":"2058"},
    {"city_id":"25638","city_name":"Uijeongbu-dong","state_id":"2058"},
    {"city_id":"25639","city_name":"Uiwang","state_id":"2058"},
    {"city_id":"25640","city_name":"Yangju","state_id":"2058"},
    {"city_id":"25641","city_name":"Yangpyeong-Gun","state_id":"2058"},
    {"city_id":"25642","city_name":"Yeokbuk-Dong-Si","state_id":"2058"},
    {"city_id":"25643","city_name":"Changwon","state_id":"2060"},
    {"city_id":"25644","city_name":"Gimhae-si","state_id":"2060"},
    {"city_id":"25645","city_name":"Incheon","state_id":"2061"},
    {"city_id":"25646","city_name":"Kanghwa","state_id":"2061"},
    {"city_id":"25647","city_name":"Namdongku","state_id":"2061"},
    {"city_id":"25648","city_name":"Jeju","state_id":"2062"},
    {"city_id":"25649","city_name":"Yeongpyeong-Dong","state_id":"2062"},
    {"city_id":"25650","city_name":"Jeonju-Si","state_id":"2063"},
    {"city_id":"25651","city_name":"Chuncheon","state_id":"2064"},
    {"city_id":"25652","city_name":"Hongcheon","state_id":"2064"},
    {"city_id":"25653","city_name":"Kangneung","state_id":"2064"},
    {"city_id":"25654","city_name":"Samchok","state_id":"2064"},
    {"city_id":"25655","city_name":"Seokcho","state_id":"2064"},
    {"city_id":"25656","city_name":"Taebaek","state_id":"2064"},
    {"city_id":"25657","city_name":"Tonghae","state_id":"2064"},
    {"city_id":"25658","city_name":"Weonju","state_id":"2064"},
    {"city_id":"25659","city_name":"Yeongweol","state_id":"2064"},
    {"city_id":"25660","city_name":"Kwangju","state_id":"2065"},
    {"city_id":"25661","city_name":"Ansan","state_id":"2066"},
    {"city_id":"25662","city_name":"Anseong","state_id":"2066"},
    {"city_id":"25663","city_name":"Anyang","state_id":"2066"},
    {"city_id":"25664","city_name":"Chongok","state_id":"2066"},
    {"city_id":"25665","city_name":"Euijeongbu","state_id":"2066"},
    {"city_id":"25666","city_name":"Euiwang","state_id":"2066"},
    {"city_id":"25667","city_name":"Hanam","state_id":"2066"},
    {"city_id":"25668","city_name":"Hoechon","state_id":"2066"},
    {"city_id":"25669","city_name":"Hwado","state_id":"2066"},
    {"city_id":"25670","city_name":"Hwasung","state_id":"2066"},
    {"city_id":"25671","city_name":"Ichon","state_id":"2066"},
    {"city_id":"25672","city_name":"Kihung","state_id":"2066"},
    {"city_id":"25673","city_name":"Kimpo","state_id":"2066"},
    {"city_id":"25674","city_name":"Koyang","state_id":"2066"},
    {"city_id":"25675","city_name":"Kumchon","state_id":"2066"},
    {"city_id":"25676","city_name":"Kunpo","state_id":"2066"},
    {"city_id":"25677","city_name":"Kuri","state_id":"2066"},
    {"city_id":"25678","city_name":"Kwacheon","state_id":"2066"},
    {"city_id":"25679","city_name":"Kwangju","state_id":"2066"},
    {"city_id":"25680","city_name":"Kwangmyeong","state_id":"2066"},
    {"city_id":"25681","city_name":"Munsan","state_id":"2066"},
    {"city_id":"25682","city_name":"Namyangju","state_id":"2066"},
    {"city_id":"25683","city_name":"Osan","state_id":"2066"},
    {"city_id":"25684","city_name":"Paengseong","state_id":"2066"},
    {"city_id":"25685","city_name":"Pochon","state_id":"2066"},
    {"city_id":"25686","city_name":"Pubal","state_id":"2066"},
    {"city_id":"25687","city_name":"Pucheon","state_id":"2066"},
    {"city_id":"25688","city_name":"Pyeongtaek","state_id":"2066"},
    {"city_id":"25689","city_name":"Seongnam","state_id":"2066"},
    {"city_id":"25690","city_name":"Shiheung","state_id":"2066"},
    {"city_id":"25691","city_name":"Suweon","state_id":"2066"},
    {"city_id":"25692","city_name":"Taean","state_id":"2066"},
    {"city_id":"25693","city_name":"Tongducheon","state_id":"2066"},
    {"city_id":"25694","city_name":"Wabu","state_id":"2066"},
    {"city_id":"25695","city_name":"Yeoju","state_id":"2066"},
    {"city_id":"25696","city_name":"Yongin","state_id":"2066"},
    {"city_id":"25697","city_name":"Andong","state_id":"2067"},
    {"city_id":"25698","city_name":"Angang","state_id":"2067"},
    {"city_id":"25699","city_name":"Hayang","state_id":"2067"},
    {"city_id":"25700","city_name":"Heunghae","state_id":"2067"},
    {"city_id":"25701","city_name":"Kimcheon","state_id":"2067"},
    {"city_id":"25702","city_name":"Kumi","state_id":"2067"},
    {"city_id":"25703","city_name":"Kyeongju","state_id":"2067"},
    {"city_id":"25704","city_name":"Kyeongsan","state_id":"2067"},
    {"city_id":"25705","city_name":"Mungyong","state_id":"2067"},
    {"city_id":"25706","city_name":"Ochon","state_id":"2067"},
    {"city_id":"25707","city_name":"Oedong","state_id":"2067"},
    {"city_id":"25708","city_name":"Pohang","state_id":"2067"},
    {"city_id":"25709","city_name":"Sangju","state_id":"2067"},
    {"city_id":"25710","city_name":"Seonsan","state_id":"2067"},
    {"city_id":"25711","city_name":"Waegwan","state_id":"2067"},
    {"city_id":"25712","city_name":"Yechon","state_id":"2067"},
    {"city_id":"25713","city_name":"Yeongcheon","state_id":"2067"},
    {"city_id":"25714","city_name":"Yeongju","state_id":"2067"},
    {"city_id":"25715","city_name":"Yeonil","state_id":"2067"},
    {"city_id":"25716","city_name":"Changweon","state_id":"2068"},
    {"city_id":"25717","city_name":"Chinhae","state_id":"2068"},
    {"city_id":"25718","city_name":"Chinju","state_id":"2068"},
    {"city_id":"25719","city_name":"Chinyeong","state_id":"2068"},
    {"city_id":"25720","city_name":"Keoje","state_id":"2068"},
    {"city_id":"25721","city_name":"Kimhae","state_id":"2068"},
    {"city_id":"25722","city_name":"Kochang","state_id":"2068"},
    {"city_id":"25723","city_name":"Koseong","state_id":"2068"},
    {"city_id":"25724","city_name":"Masan","state_id":"2068"},
    {"city_id":"25725","city_name":"Miryang","state_id":"2068"},
    {"city_id":"25726","city_name":"Naeso","state_id":"2068"},
    {"city_id":"25727","city_name":"Sacheon","state_id":"2068"},
    {"city_id":"25728","city_name":"Sinhyeon","state_id":"2068"},
    {"city_id":"25729","city_name":"Tongyong","state_id":"2068"},
    {"city_id":"25730","city_name":"Ungsang","state_id":"2068"},
    {"city_id":"25731","city_name":"Yangju","state_id":"2068"},
    {"city_id":"25732","city_name":"Yangsan","state_id":"2068"},
    {"city_id":"25733","city_name":"Anyang-Si","state_id":"2069"},
    {"city_id":"25734","city_name":"Bucheon","state_id":"2069"},
    {"city_id":"25735","city_name":"Paju","state_id":"2069"},
    {"city_id":"25736","city_name":"Sungnamshi","state_id":"2069"},
    {"city_id":"25737","city_name":"Suwon","state_id":"2069"},
    {"city_id":"25738","city_name":"Uijongbu","state_id":"2069"},
    {"city_id":"25739","city_name":"Yangju","state_id":"2069"},
    {"city_id":"25740","city_name":"Gumi-Shi","state_id":"2070"},
    {"city_id":"25741","city_name":"Kyungsan","state_id":"2070"},
    {"city_id":"25742","city_name":"Youngcheon-Si","state_id":"2070"},
    {"city_id":"25743","city_name":"Bundang","state_id":"2071"},
    {"city_id":"25744","city_name":"Gunpo","state_id":"2071"},
    {"city_id":"25745","city_name":"Yangu","state_id":"2071"},
    {"city_id":"25746","city_name":"Pochun","state_id":"2072"},
    {"city_id":"25747","city_name":"Yangju","state_id":"2072"},
    {"city_id":"25748","city_name":"Yonggi","state_id":"2072"},
    {"city_id":"25749","city_name":"Kijang","state_id":"2073"},
    {"city_id":"25750","city_name":"Pusan","state_id":"2073"},
    {"city_id":"25751","city_name":"Seoul","state_id":"2074"},
    {"city_id":"25752","city_name":"Paju","state_id":"2075"},
    {"city_id":"25753","city_name":"Hwawon","state_id":"2076"},
    {"city_id":"25754","city_name":"Taegu","state_id":"2076"},
    {"city_id":"25755","city_name":"Taejeon","state_id":"2077"},
    {"city_id":"25756","city_name":"Daejeon","state_id":"2078"},
    {"city_id":"25757","city_name":"Nongso","state_id":"2079"},
    {"city_id":"25758","city_name":"Ulsan","state_id":"2079"},
    {"city_id":"25759","city_name":"Heungup-Myun","state_id":"2080"},
    {"city_id":"25760","city_name":"Inchon","state_id":"2081"},
    {"city_id":"25761","city_name":"Pusan","state_id":"2081"},
    {"city_id":"25762","city_name":"Taejon","state_id":"2081"},
    {"city_id":"25763","city_name":"Bayan","state_id":"2083"},
    {"city_id":"25764","city_name":"Hawalli","state_id":"2083"},
    {"city_id":"25765","city_name":"Massilah","state_id":"2083"},
    {"city_id":"25766","city_name":"Mushrif","state_id":"2083"},
    {"city_id":"25767","city_name":"Salwa'","state_id":"2083"},
    {"city_id":"25768","city_name":"Sha''ab","state_id":"2083"},
    {"city_id":"25769","city_name":"Subbah-as-Salim","state_id":"2083"},
    {"city_id":"25770","city_name":"al-Funaytis","state_id":"2083"},
    {"city_id":"25771","city_name":"al-Funaytis-al-Garbiyah","state_id":"2083"},
    {"city_id":"25772","city_name":"al-Jabiriyah","state_id":"2083"},
    {"city_id":"25773","city_name":"al-Karim","state_id":"2083"},
    {"city_id":"25774","city_name":"ar-Rumaythiyah","state_id":"2083"},
    {"city_id":"25775","city_name":"as-Salimiyah","state_id":"2083"},
    {"city_id":"25776","city_name":"Mishref","state_id":"2084"},
    {"city_id":"25777","city_name":"Qadesiya","state_id":"2085"},
    {"city_id":"25778","city_name":"Safat","state_id":"2086"},
    {"city_id":"25779","city_name":"Salmiya","state_id":"2087"},
    {"city_id":"25780","city_name":"A'qaylah","state_id":"2088"},
    {"city_id":"25781","city_name":"Abu Hulayfah","state_id":"2088"},
    {"city_id":"25782","city_name":"Dahar","state_id":"2088"},
    {"city_id":"25783","city_name":"Desert Area","state_id":"2088"},
    {"city_id":"25784","city_name":"Hadiyah","state_id":"2088"},
    {"city_id":"25785","city_name":"Jabbar-al-'Ali","state_id":"2088"},
    {"city_id":"25786","city_name":"Shu'aybah","state_id":"2088"},
    {"city_id":"25787","city_name":"al-Ahmadi","state_id":"2088"},
    {"city_id":"25788","city_name":"al-Fintas","state_id":"2088"},
    {"city_id":"25789","city_name":"al-Fuhayhil","state_id":"2088"},
    {"city_id":"25790","city_name":"al-Mahbulah","state_id":"2088"},
    {"city_id":"25791","city_name":"al-Manqaf","state_id":"2088"},
    {"city_id":"25792","city_name":"al-Wafrah","state_id":"2088"},
    {"city_id":"25793","city_name":"ar-Riqqah","state_id":"2088"},
    {"city_id":"25794","city_name":"as-Sabahiyah","state_id":"2088"},
    {"city_id":"25795","city_name":"az-Zawr","state_id":"2088"},
    {"city_id":"25796","city_name":"'Umayriyah","state_id":"2089"},
    {"city_id":"25797","city_name":"Abraq Khitan","state_id":"2089"},
    {"city_id":"25798","city_name":"Ardiyah","state_id":"2089"},
    {"city_id":"25799","city_name":"Fardaws","state_id":"2089"},
    {"city_id":"25800","city_name":"Jalib ash-Shuyuh","state_id":"2089"},
    {"city_id":"25801","city_name":"Janub-as-Surrah","state_id":"2089"},
    {"city_id":"25802","city_name":"Khitan-al-Janubiyah","state_id":"2089"},
    {"city_id":"25803","city_name":"Qartaba","state_id":"2089"},
    {"city_id":"25804","city_name":"Ray","state_id":"2089"},
    {"city_id":"25805","city_name":"Riqay","state_id":"2089"},
    {"city_id":"25806","city_name":"Sabhan","state_id":"2089"},
    {"city_id":"25807","city_name":"Sarbah-an-Nasr","state_id":"2089"},
    {"city_id":"25808","city_name":"Warmawk","state_id":"2089"},
    {"city_id":"25809","city_name":"al-Andalus","state_id":"2089"},
    {"city_id":"25810","city_name":"al-Farwaniyah","state_id":"2089"},
    {"city_id":"25811","city_name":"ar-Rabbiyah","state_id":"2089"},
    {"city_id":"25812","city_name":"Amgarah","state_id":"2090"},
    {"city_id":"25813","city_name":"Desert Area","state_id":"2090"},
    {"city_id":"25814","city_name":"Nasim","state_id":"2090"},
    {"city_id":"25815","city_name":"Tayma'","state_id":"2090"},
    {"city_id":"25816","city_name":"Uyawn","state_id":"2090"},
    {"city_id":"25817","city_name":"Waha","state_id":"2090"},
    {"city_id":"25818","city_name":"al-Jahra","state_id":"2090"},
    {"city_id":"25819","city_name":"al-Qusayr","state_id":"2090"},
    {"city_id":"25820","city_name":"as-Sulaybiyah","state_id":"2090"},
    {"city_id":"25821","city_name":"'Abullah-as-Salam","state_id":"2091"},
    {"city_id":"25822","city_name":"Ardhiyah","state_id":"2091"},
    {"city_id":"25823","city_name":"Banayd-al-Qar","state_id":"2091"},
    {"city_id":"25824","city_name":"Health District","state_id":"2091"},
    {"city_id":"25825","city_name":"Kayfan","state_id":"2091"},
    {"city_id":"25826","city_name":"Khalidiyah","state_id":"2091"},
    {"city_id":"25827","city_name":"Mansuriyah","state_id":"2091"},
    {"city_id":"25828","city_name":"Nuzha","state_id":"2091"},
    {"city_id":"25829","city_name":"Qarnadah","state_id":"2091"},
    {"city_id":"25830","city_name":"Shamiyah","state_id":"2091"},
    {"city_id":"25831","city_name":"ad-Da'iyah","state_id":"2091"},
    {"city_id":"25832","city_name":"ad-Dasma","state_id":"2091"},
    {"city_id":"25833","city_name":"ad-Dawhah","state_id":"2091"},
    {"city_id":"25834","city_name":"al-'Udayliyah","state_id":"2091"},
    {"city_id":"25835","city_name":"al-Fayha","state_id":"2091"},
    {"city_id":"25836","city_name":"al-Kuwayt","state_id":"2091"},
    {"city_id":"25837","city_name":"al-Qadisiyah","state_id":"2091"},
    {"city_id":"25838","city_name":"ar-Rawdah","state_id":"2091"},
    {"city_id":"25839","city_name":"as-Sulaybihat","state_id":"2091"},
    {"city_id":"25840","city_name":"ash-Shuwaykh Industrial","state_id":"2091"},
    {"city_id":"25841","city_name":"ash-Shuwaykh Reservoir","state_id":"2091"},
    {"city_id":"25842","city_name":"Batken","state_id":"2092"},
    {"city_id":"25843","city_name":"Isfana","state_id":"2092"},
    {"city_id":"25844","city_name":"Khaidarkan","state_id":"2092"},
    {"city_id":"25845","city_name":"Kyzyl-Kiya","state_id":"2092"},
    {"city_id":"25846","city_name":"Ravat","state_id":"2092"},
    {"city_id":"25847","city_name":"Sulukta","state_id":"2092"},
    {"city_id":"25848","city_name":"Bishkek","state_id":"2093"},
    {"city_id":"25849","city_name":"Ak-Suu","state_id":"2094"},
    {"city_id":"25850","city_name":"Belovodskoye","state_id":"2094"},
    {"city_id":"25851","city_name":"Boroldoy","state_id":"2094"},
    {"city_id":"25852","city_name":"Chaldovar","state_id":"2094"},
    {"city_id":"25853","city_name":"Chatkyol","state_id":"2094"},
    {"city_id":"25854","city_name":"Chui","state_id":"2094"},
    {"city_id":"25855","city_name":"Don Arik","state_id":"2094"},
    {"city_id":"25856","city_name":"Ivanovka","state_id":"2094"},
    {"city_id":"25857","city_name":"Jangyjar","state_id":"2094"},
    {"city_id":"25858","city_name":"Jangypakhtar","state_id":"2094"},
    {"city_id":"25859","city_name":"Kalininskoye","state_id":"2094"},
    {"city_id":"25860","city_name":"Kalinovka","state_id":"2094"},
    {"city_id":"25861","city_name":"Kant","state_id":"2094"},
    {"city_id":"25862","city_name":"Kara-Suu","state_id":"2094"},
    {"city_id":"25863","city_name":"Karabalta","state_id":"2094"},
    {"city_id":"25864","city_name":"Kayingdi","state_id":"2094"},
    {"city_id":"25865","city_name":"Kegety","state_id":"2094"},
    {"city_id":"25866","city_name":"Kemin","state_id":"2094"},
    {"city_id":"25867","city_name":"Kosh Tegirmen","state_id":"2094"},
    {"city_id":"25868","city_name":"Krasnaya Rechka","state_id":"2094"},
    {"city_id":"25869","city_name":"Orlovka","state_id":"2094"},
    {"city_id":"25870","city_name":"Sokuluk","state_id":"2094"},
    {"city_id":"25871","city_name":"Sopokov","state_id":"2094"},
    {"city_id":"25872","city_name":"Sosnovka","state_id":"2094"},
    {"city_id":"25873","city_name":"Tokmok","state_id":"2094"},
    {"city_id":"25874","city_name":"Tunuk","state_id":"2094"},
    {"city_id":"25875","city_name":"Yuryevka","state_id":"2094"},
    {"city_id":"25876","city_name":"Ak-Bulok","state_id":"2095"},
    {"city_id":"25877","city_name":"Ak-Terek","state_id":"2095"},
    {"city_id":"25878","city_name":"Ananyevo","state_id":"2095"},
    {"city_id":"25879","city_name":"Balykchy","state_id":"2095"},
    {"city_id":"25880","city_name":"Barskoon","state_id":"2095"},
    {"city_id":"25881","city_name":"Cholpon-Ata","state_id":"2095"},
    {"city_id":"25882","city_name":"Darkhon","state_id":"2095"},
    {"city_id":"25883","city_name":"Enilchek","state_id":"2095"},
    {"city_id":"25884","city_name":"Grigoryevka","state_id":"2095"},
    {"city_id":"25885","city_name":"Jyrgolon","state_id":"2095"},
    {"city_id":"25886","city_name":"Kara-Say","state_id":"2095"},
    {"city_id":"25887","city_name":"Karako","state_id":"2095"},
    {"city_id":"25888","city_name":"Karakol","state_id":"2095"},
    {"city_id":"25889","city_name":"Kuturgu","state_id":"2095"},
    {"city_id":"25890","city_name":"Mikhaylovka","state_id":"2095"},
    {"city_id":"25891","city_name":"Novovoznesenovka","state_id":"2095"},
    {"city_id":"25892","city_name":"Ottuk","state_id":"2095"},
    {"city_id":"25893","city_name":"Semyonovka","state_id":"2095"},
    {"city_id":"25894","city_name":"Taldy-Suu","state_id":"2095"},
    {"city_id":"25895","city_name":"Teploklyuchenka","state_id":"2095"},
    {"city_id":"25896","city_name":"Tyup","state_id":"2095"},
    {"city_id":"25897","city_name":"Ala Buka","state_id":"2096"},
    {"city_id":"25898","city_name":"Bazarkurgon","state_id":"2096"},
    {"city_id":"25899","city_name":"Jalal-Abad","state_id":"2096"},
    {"city_id":"25900","city_name":"Jangybazar","state_id":"2096"},
    {"city_id":"25901","city_name":"Karavan","state_id":"2096"},
    {"city_id":"25902","city_name":"Kochkor-Ata","state_id":"2096"},
    {"city_id":"25903","city_name":"Kok-Jangak","state_id":"2096"},
    {"city_id":"25904","city_name":"Mailuu-Suu","state_id":"2096"},
    {"city_id":"25905","city_name":"Tash-Kumyr","state_id":"2096"},
    {"city_id":"25906","city_name":"At-Bashi","state_id":"2097"},
    {"city_id":"25907","city_name":"Chaiek","state_id":"2097"},
    {"city_id":"25908","city_name":"Kara-Suu","state_id":"2097"},
    {"city_id":"25909","city_name":"Kayirma","state_id":"2097"},
    {"city_id":"25910","city_name":"Kek-Algyp","state_id":"2097"},
    {"city_id":"25911","city_name":"Kochkorka","state_id":"2097"},
    {"city_id":"25912","city_name":"Kulanak","state_id":"2097"},
    {"city_id":"25913","city_name":"Mingbulok","state_id":"2097"},
    {"city_id":"25914","city_name":"Mingkush","state_id":"2097"},
    {"city_id":"25915","city_name":"Naryn","state_id":"2097"},
    {"city_id":"25916","city_name":"Ugyut","state_id":"2097"},
    {"city_id":"25917","city_name":"Daraut-Korgan","state_id":"2098"},
    {"city_id":"25918","city_name":"Gulcha","state_id":"2098"},
    {"city_id":"25919","city_name":"Kara-Suu","state_id":"2098"},
    {"city_id":"25920","city_name":"Leninskoye","state_id":"2098"},
    {"city_id":"25921","city_name":"Osh","state_id":"2098"},
    {"city_id":"25922","city_name":"Uzgen","state_id":"2098"},
    {"city_id":"25923","city_name":"Chat-Bazar","state_id":"2099"},
    {"city_id":"25924","city_name":"Groznoye","state_id":"2099"},
    {"city_id":"25925","city_name":"Kara-Kul","state_id":"2099"},
    {"city_id":"25926","city_name":"Klyuchevka","state_id":"2099"},
    {"city_id":"25927","city_name":"Kyzyl-Adyr","state_id":"2099"},
    {"city_id":"25928","city_name":"Leninopol","state_id":"2099"},
    {"city_id":"25929","city_name":"Maimak","state_id":"2099"},
    {"city_id":"25930","city_name":"Talas","state_id":"2099"},
    {"city_id":"25931","city_name":"Toktogul","state_id":"2099"},
    {"city_id":"25932","city_name":"Samakhixai","state_id":"2100"},
    {"city_id":"25933","city_name":"Huayxay","state_id":"2101"},
    {"city_id":"25934","city_name":"Pakxan","state_id":"2102"},
    {"city_id":"25935","city_name":"Champasak","state_id":"2103"},
    {"city_id":"25936","city_name":"Muang Khong","state_id":"2103"},
    {"city_id":"25937","city_name":"Muang Khongxedon","state_id":"2103"},
    {"city_id":"25938","city_name":"Pakxe","state_id":"2103"},
    {"city_id":"25939","city_name":"Xam Nua","state_id":"2104"},
    {"city_id":"25940","city_name":"Thakek","state_id":"2105"},
    {"city_id":"25941","city_name":"Ban Nahin","state_id":"2108"},
    {"city_id":"25942","city_name":"Phongsaly","state_id":"2109"},
    {"city_id":"25943","city_name":"Saravan","state_id":"2110"},
    {"city_id":"25944","city_name":"Savannakhet","state_id":"2111"},
    {"city_id":"25945","city_name":"Sekong","state_id":"2112"},
    {"city_id":"25946","city_name":"Xaignabury","state_id":"2115"},
    {"city_id":"25947","city_name":"Aizkraukle","state_id":"2117"},
    {"city_id":"25948","city_name":"Jaunjelgava","state_id":"2117"},
    {"city_id":"25949","city_name":"Plavinas","state_id":"2117"},
    {"city_id":"25950","city_name":"Aluksne","state_id":"2118"},
    {"city_id":"25951","city_name":"Ape","state_id":"2118"},
    {"city_id":"25952","city_name":"Balvi","state_id":"2119"},
    {"city_id":"25953","city_name":"Vilaka","state_id":"2119"},
    {"city_id":"25954","city_name":"Bauska","state_id":"2120"},
    {"city_id":"25955","city_name":"Cesis","state_id":"2121"},
    {"city_id":"25956","city_name":"Ligatne","state_id":"2121"},
    {"city_id":"25957","city_name":"Ilukste","state_id":"2122"},
    {"city_id":"25958","city_name":"Subate","state_id":"2122"},
    {"city_id":"25959","city_name":"Ilukste","state_id":"2123"},
    {"city_id":"25960","city_name":"Subate","state_id":"2123"},
    {"city_id":"25961","city_name":"Auce","state_id":"2124"},
    {"city_id":"25962","city_name":"Dobele","state_id":"2124"},
    {"city_id":"25963","city_name":"Gulbene","state_id":"2125"},
    {"city_id":"25964","city_name":"Akniste","state_id":"2126"},
    {"city_id":"25965","city_name":"Jekabspils","state_id":"2126"},
    {"city_id":"25966","city_name":"Viesite","state_id":"2126"},
    {"city_id":"25967","city_name":"Jelgava","state_id":"2127"},
    {"city_id":"25968","city_name":"Kalnciems","state_id":"2128"},
    {"city_id":"25969","city_name":"Dagda","state_id":"2130"},
    {"city_id":"25970","city_name":"Kraslava","state_id":"2130"},
    {"city_id":"25971","city_name":"Kuldiga","state_id":"2131"},
    {"city_id":"25972","city_name":"Skrunda","state_id":"2131"},
    {"city_id":"25973","city_name":"Liepaja","state_id":"2132"},
    {"city_id":"25974","city_name":"Aizpute","state_id":"2133"},
    {"city_id":"25975","city_name":"Durbe","state_id":"2133"},
    {"city_id":"25976","city_name":"Grobina","state_id":"2133"},
    {"city_id":"25977","city_name":"Pavilosta","state_id":"2133"},
    {"city_id":"25978","city_name":"Priekule","state_id":"2133"},
    {"city_id":"25979","city_name":"Ainazhi","state_id":"2134"},
    {"city_id":"25980","city_name":"Aloja","state_id":"2134"},
    {"city_id":"25981","city_name":"Limbazhi","state_id":"2134"},
    {"city_id":"25982","city_name":"Salacgriva","state_id":"2134"},
    {"city_id":"25983","city_name":"Staicele","state_id":"2134"},
    {"city_id":"25984","city_name":"Karsava","state_id":"2135"},
    {"city_id":"25985","city_name":"Ludza","state_id":"2135"},
    {"city_id":"25986","city_name":"Zilupe","state_id":"2135"},
    {"city_id":"25987","city_name":"Cesvaine","state_id":"2136"},
    {"city_id":"25988","city_name":"Lubana","state_id":"2136"},
    {"city_id":"25989","city_name":"Madona","state_id":"2136"},
    {"city_id":"25990","city_name":"Varaklani","state_id":"2136"},
    {"city_id":"25991","city_name":"Ikskile","state_id":"2137"},
    {"city_id":"25992","city_name":"Kegums","state_id":"2137"},
    {"city_id":"25993","city_name":"Les Hautes-Rivieres","state_id":"2137"},
    {"city_id":"25994","city_name":"Lielvarde","state_id":"2137"},
    {"city_id":"25995","city_name":"Ogre","state_id":"2137"},
    {"city_id":"25996","city_name":"Livani","state_id":"2138"},
    {"city_id":"25997","city_name":"Preili","state_id":"2138"},
    {"city_id":"25998","city_name":"Rezekne","state_id":"2139"},
    {"city_id":"25999","city_name":"Vilani","state_id":"2140"},
    {"city_id":"26000","city_name":"Riga","state_id":"2141"},
    {"city_id":"26001","city_name":"Baldone","state_id":"2142"},
    {"city_id":"26002","city_name":"Balozhi","state_id":"2142"},
    {"city_id":"26003","city_name":"Olaine","state_id":"2142"},
    {"city_id":"26004","city_name":"Salaspils","state_id":"2142"},
    {"city_id":"26005","city_name":"Saulkrasti","state_id":"2142"},
    {"city_id":"26006","city_name":"Sigulda","state_id":"2142"},
    {"city_id":"26007","city_name":"Vangazhi","state_id":"2142"},
    {"city_id":"26008","city_name":"Broceni","state_id":"2143"},
    {"city_id":"26009","city_name":"Saldus","state_id":"2143"},
    {"city_id":"26010","city_name":"Sabile","state_id":"2144"},
    {"city_id":"26011","city_name":"Stende","state_id":"2144"},
    {"city_id":"26012","city_name":"Talsi","state_id":"2144"},
    {"city_id":"26013","city_name":"Valdemarpils","state_id":"2144"},
    {"city_id":"26014","city_name":"Kandava","state_id":"2145"},
    {"city_id":"26015","city_name":"Tukums","state_id":"2145"},
    {"city_id":"26016","city_name":"Seda","state_id":"2146"},
    {"city_id":"26017","city_name":"Smiltene","state_id":"2146"},
    {"city_id":"26018","city_name":"Strenchi","state_id":"2146"},
    {"city_id":"26019","city_name":"Valka","state_id":"2146"},
    {"city_id":"26020","city_name":"Mazsalaca","state_id":"2147"},
    {"city_id":"26021","city_name":"Rujiena","state_id":"2147"},
    {"city_id":"26022","city_name":"Valmiera","state_id":"2147"},
    {"city_id":"26023","city_name":"Piltene","state_id":"2148"},
    {"city_id":"26024","city_name":"Piltene","state_id":"2149"},
    {"city_id":"26025","city_name":"Beirut","state_id":"2150"},
    {"city_id":"26026","city_name":"Jdeidet el Metn","state_id":"2150"},
    {"city_id":"26027","city_name":"Jnaah","state_id":"2150"},
    {"city_id":"26028","city_name":"Sidon","state_id":"2154"},
    {"city_id":"26029","city_name":"Ba'labakk","state_id":"2155"},
    {"city_id":"26030","city_name":"Jubb Jannin","state_id":"2155"},
    {"city_id":"26031","city_name":"Rashayya","state_id":"2155"},
    {"city_id":"26032","city_name":"Riyak","state_id":"2155"},
    {"city_id":"26033","city_name":"Zahlah","state_id":"2155"},
    {"city_id":"26034","city_name":"al-Hirmil","state_id":"2155"},
    {"city_id":"26035","city_name":"Jazzin","state_id":"2156"},
    {"city_id":"26036","city_name":"Juwayya","state_id":"2156"},
    {"city_id":"26037","city_name":"Sayda'","state_id":"2156"},
    {"city_id":"26038","city_name":"Sur","state_id":"2156"},
    {"city_id":"26039","city_name":"Marj 'Uyun","state_id":"2157"},
    {"city_id":"26040","city_name":"Nabatieh","state_id":"2157"},
    {"city_id":"26041","city_name":"an-Nabatiyat-at-Tahta","state_id":"2157"},
    {"city_id":"26042","city_name":"Tarabulus ash-Sham","state_id":"2158"},
    {"city_id":"26043","city_name":"al-Batrun","state_id":"2158"},
    {"city_id":"26044","city_name":"Teyateyaneng","state_id":"2159"},
    {"city_id":"26045","city_name":"Butha Buthe","state_id":"2160"},
    {"city_id":"26046","city_name":"Hlotse","state_id":"2161"},
    {"city_id":"26047","city_name":"Maputsoa","state_id":"2161"},
    {"city_id":"26048","city_name":"Mafeteng","state_id":"2162"},
    {"city_id":"26049","city_name":"Maseru","state_id":"2163"},
    {"city_id":"26050","city_name":"Mokhotlong","state_id":"2165"},
    {"city_id":"26051","city_name":"Quthing","state_id":"2167"},
    {"city_id":"26052","city_name":"Thaba-Tseka","state_id":"2168"},
    {"city_id":"26053","city_name":"Tubmanburg","state_id":"2169"},
    {"city_id":"26054","city_name":"Gbarnga","state_id":"2170"},
    {"city_id":"26055","city_name":"Voinjama","state_id":"2174"},
    {"city_id":"26056","city_name":"Harbel","state_id":"2175"},
    {"city_id":"26057","city_name":"Kakata","state_id":"2175"},
    {"city_id":"26058","city_name":"Bensonville","state_id":"2177"},
    {"city_id":"26059","city_name":"Monrovia","state_id":"2177"},
    {"city_id":"26060","city_name":"Ganta","state_id":"2178"},
    {"city_id":"26061","city_name":"Sanniquellie","state_id":"2178"},
    {"city_id":"26062","city_name":"Yekepa","state_id":"2178"},
    {"city_id":"26063","city_name":"Rivercess","state_id":"2179"},
    {"city_id":"26064","city_name":"Greenville","state_id":"2180"},
    {"city_id":"26065","city_name":"Ajdabiya","state_id":"2181"},
    {"city_id":"26066","city_name":"Awjilah","state_id":"2181"},
    {"city_id":"26067","city_name":"Marsa al-Burayqah","state_id":"2181"},
    {"city_id":"26068","city_name":"az-Zuwaytinah","state_id":"2181"},
    {"city_id":"26069","city_name":"Awbari","state_id":"2182"},
    {"city_id":"26070","city_name":"Ghat","state_id":"2182"},
    {"city_id":"26071","city_name":"Banghazi","state_id":"2183"},
    {"city_id":"26072","city_name":"Suluq","state_id":"2183"},
    {"city_id":"26073","city_name":"al-Quriyah","state_id":"2183"},
    {"city_id":"26074","city_name":"Darnah","state_id":"2184"},
    {"city_id":"26075","city_name":"Ghadamis","state_id":"2185"},
    {"city_id":"26076","city_name":"Nalut","state_id":"2185"},
    {"city_id":"26077","city_name":"Gharyan","state_id":"2186"},
    {"city_id":"26078","city_name":"Mizdah","state_id":"2186"},
    {"city_id":"26079","city_name":"al-Qaryah-ash-Sharqiyah","state_id":"2186"},
    {"city_id":"26080","city_name":"Misratah","state_id":"2187"},
    {"city_id":"26081","city_name":"Murzuq","state_id":"2188"},
    {"city_id":"26082","city_name":"Sabha","state_id":"2189"},
    {"city_id":"26083","city_name":"Bani Walid","state_id":"2190"},
    {"city_id":"26084","city_name":"Surt","state_id":"2191"},
    {"city_id":"26085","city_name":"Tarabulus","state_id":"2192"},
    {"city_id":"26086","city_name":"Tripoli","state_id":"2192"},
    {"city_id":"26087","city_name":"Tarhunah","state_id":"2193"},
    {"city_id":"26088","city_name":"Misrata","state_id":"2194"},
    {"city_id":"26089","city_name":"Bardiyah","state_id":"2195"},
    {"city_id":"26090","city_name":"Tubruq","state_id":"2195"},
    {"city_id":"26091","city_name":"Yafran","state_id":"2196"},
    {"city_id":"26092","city_name":"Zlitan","state_id":"2197"},
    {"city_id":"26093","city_name":"al-Aziziyah","state_id":"2198"},
    {"city_id":"26094","city_name":"al-Abyar","state_id":"2199"},
    {"city_id":"26095","city_name":"al-Marj","state_id":"2199"},
    {"city_id":"26096","city_name":"Waddan","state_id":"2201"},
    {"city_id":"26097","city_name":"al-Khums","state_id":"2202"},
    {"city_id":"26098","city_name":"al-Jawf","state_id":"2203"},
    {"city_id":"26099","city_name":"Birak","state_id":"2205"},
    {"city_id":"26100","city_name":"az-Zawiyah","state_id":"2206"},
    {"city_id":"26101","city_name":"Balzers","state_id":"2207"},
    {"city_id":"26102","city_name":"Eschen","state_id":"2208"},
    {"city_id":"26103","city_name":"Gamprin","state_id":"2209"},
    {"city_id":"26104","city_name":"Mauren","state_id":"2210"},
    {"city_id":"26105","city_name":"Planken","state_id":"2211"},
    {"city_id":"26106","city_name":"Ruggell","state_id":"2212"},
    {"city_id":"26107","city_name":"Schaan","state_id":"2213"},
    {"city_id":"26108","city_name":"Schellenberg","state_id":"2214"},
    {"city_id":"26109","city_name":"Triesen","state_id":"2215"},
    {"city_id":"26110","city_name":"Triesenberg","state_id":"2216"},
    {"city_id":"26111","city_name":"Vaduz","state_id":"2217"},
    {"city_id":"26112","city_name":"Alytus","state_id":"2218"},
    {"city_id":"26113","city_name":"Daugai","state_id":"2218"},
    {"city_id":"26114","city_name":"Druskininkai","state_id":"2218"},
    {"city_id":"26115","city_name":"Lazdijai","state_id":"2218"},
    {"city_id":"26116","city_name":"Simnas","state_id":"2218"},
    {"city_id":"26117","city_name":"Varena","state_id":"2218"},
    {"city_id":"26118","city_name":"Veisiejai","state_id":"2218"},
    {"city_id":"26119","city_name":"Anyksciai","state_id":"2219"},
    {"city_id":"26120","city_name":"Ariogala","state_id":"2220"},
    {"city_id":"26121","city_name":"Birshtonas","state_id":"2220"},
    {"city_id":"26122","city_name":"Dotnuva","state_id":"2220"},
    {"city_id":"26123","city_name":"Ezherelis","state_id":"2220"},
    {"city_id":"26124","city_name":"Garliava","state_id":"2220"},
    {"city_id":"26125","city_name":"Jieznas","state_id":"2220"},
    {"city_id":"26126","city_name":"Jonava","state_id":"2220"},
    {"city_id":"26127","city_name":"Kachergine","state_id":"2220"},
    {"city_id":"26128","city_name":"Kaishiadorys","state_id":"2220"},
    {"city_id":"26129","city_name":"Kaunas","state_id":"2220"},
    {"city_id":"26130","city_name":"Kedainiai","state_id":"2220"},
    {"city_id":"26131","city_name":"Kulautuva","state_id":"2220"},
    {"city_id":"26132","city_name":"Prienai","state_id":"2220"},
    {"city_id":"26133","city_name":"Raseiniai","state_id":"2220"},
    {"city_id":"26134","city_name":"Vilkija","state_id":"2220"},
    {"city_id":"26135","city_name":"Zhiezhmariai","state_id":"2220"},
    {"city_id":"26136","city_name":"Gargzhdai","state_id":"2221"},
    {"city_id":"26137","city_name":"Klaipeda","state_id":"2221"},
    {"city_id":"26138","city_name":"Kretinga","state_id":"2221"},
    {"city_id":"26139","city_name":"Neringa","state_id":"2221"},
    {"city_id":"26140","city_name":"Pagegiai","state_id":"2221"},
    {"city_id":"26141","city_name":"Palanga","state_id":"2221"},
    {"city_id":"26142","city_name":"Panemune","state_id":"2221"},
    {"city_id":"26143","city_name":"Priekule","state_id":"2221"},
    {"city_id":"26144","city_name":"Rusne","state_id":"2221"},
    {"city_id":"26145","city_name":"Salantai","state_id":"2221"},
    {"city_id":"26146","city_name":"Shilute","state_id":"2221"},
    {"city_id":"26147","city_name":"Skuodas","state_id":"2221"},
    {"city_id":"26148","city_name":"Zhemaichiu Naumiestis","state_id":"2221"},
    {"city_id":"26149","city_name":"Gelgaudishkis","state_id":"2222"},
    {"city_id":"26150","city_name":"Kalvarija","state_id":"2222"},
    {"city_id":"26151","city_name":"Kazlu Ruda","state_id":"2222"},
    {"city_id":"26152","city_name":"Kudirkos Naumiestis","state_id":"2222"},
    {"city_id":"26153","city_name":"Kybartai","state_id":"2222"},
    {"city_id":"26154","city_name":"Marijampole","state_id":"2222"},
    {"city_id":"26155","city_name":"Shakiai","state_id":"2222"},
    {"city_id":"26156","city_name":"Vilkavishkis","state_id":"2222"},
    {"city_id":"26157","city_name":"Virbalis","state_id":"2222"},
    {"city_id":"26158","city_name":"Birzhai","state_id":"2223"},
    {"city_id":"26159","city_name":"Jonishkelis","state_id":"2223"},
    {"city_id":"26160","city_name":"Juodupe","state_id":"2223"},
    {"city_id":"26161","city_name":"Kupishkis","state_id":"2223"},
    {"city_id":"26162","city_name":"Obeliai","state_id":"2223"},
    {"city_id":"26163","city_name":"Pandelys","state_id":"2223"},
    {"city_id":"26164","city_name":"Panevezhys","state_id":"2223"},
    {"city_id":"26165","city_name":"Panevezio","state_id":"2223"},
    {"city_id":"26166","city_name":"Pasvalys","state_id":"2223"},
    {"city_id":"26167","city_name":"Ramygala","state_id":"2223"},
    {"city_id":"26168","city_name":"Rokishkis","state_id":"2223"},
    {"city_id":"26169","city_name":"Subachius","state_id":"2223"},
    {"city_id":"26170","city_name":"Vabalninkas","state_id":"2223"},
    {"city_id":"26171","city_name":"Panevezys","state_id":"2224"},
    {"city_id":"26172","city_name":"Akmene","state_id":"2225"},
    {"city_id":"26173","city_name":"Jonishkis","state_id":"2225"},
    {"city_id":"26174","city_name":"Kelme","state_id":"2225"},
    {"city_id":"26175","city_name":"Kurshenai","state_id":"2225"},
    {"city_id":"26176","city_name":"Linkuva","state_id":"2225"},
    {"city_id":"26177","city_name":"Naujoji Akmene","state_id":"2225"},
    {"city_id":"26178","city_name":"Pakruojis","state_id":"2225"},
    {"city_id":"26179","city_name":"Radvilishkis","state_id":"2225"},
    {"city_id":"26180","city_name":"Sheduva","state_id":"2225"},
    {"city_id":"26181","city_name":"Shiauliai","state_id":"2225"},
    {"city_id":"26182","city_name":"Siauliai","state_id":"2225"},
    {"city_id":"26183","city_name":"Tyruliai","state_id":"2225"},
    {"city_id":"26184","city_name":"Tytuvenai","state_id":"2225"},
    {"city_id":"26185","city_name":"Uzhventis","state_id":"2225"},
    {"city_id":"26186","city_name":"Venta","state_id":"2225"},
    {"city_id":"26187","city_name":"Viekshniai","state_id":"2225"},
    {"city_id":"26188","city_name":"Zhagare","state_id":"2225"},
    {"city_id":"26189","city_name":"Jurbarkas","state_id":"2226"},
    {"city_id":"26190","city_name":"Shilale","state_id":"2226"},
    {"city_id":"26191","city_name":"Skaudvile","state_id":"2226"},
    {"city_id":"26192","city_name":"Smalininkai","state_id":"2226"},
    {"city_id":"26193","city_name":"Taurage","state_id":"2226"},
    {"city_id":"26194","city_name":"Guglingen","state_id":"2227"},
    {"city_id":"26195","city_name":"Mazheikiai","state_id":"2227"},
    {"city_id":"26196","city_name":"Plunge","state_id":"2227"},
    {"city_id":"26197","city_name":"Rietavas","state_id":"2227"},
    {"city_id":"26198","city_name":"Seda","state_id":"2227"},
    {"city_id":"26199","city_name":"Telshiai","state_id":"2227"},
    {"city_id":"26200","city_name":"Varniai","state_id":"2227"},
    {"city_id":"26201","city_name":"Mazeikiai","state_id":"2228"},
    {"city_id":"26202","city_name":"Anykshchiai","state_id":"2229"},
    {"city_id":"26203","city_name":"Dukshtas","state_id":"2229"},
    {"city_id":"26204","city_name":"Dusetos","state_id":"2229"},
    {"city_id":"26205","city_name":"Ignalina","state_id":"2229"},
    {"city_id":"26206","city_name":"Kavarskas","state_id":"2229"},
    {"city_id":"26207","city_name":"Moletai","state_id":"2229"},
    {"city_id":"26208","city_name":"Troshkunai","state_id":"2229"},
    {"city_id":"26209","city_name":"Turmantas","state_id":"2229"},
    {"city_id":"26210","city_name":"Utena","state_id":"2229"},
    {"city_id":"26211","city_name":"Visaginas","state_id":"2229"},
    {"city_id":"26212","city_name":"Zarasai","state_id":"2229"},
    {"city_id":"26213","city_name":"Baltoji Voke","state_id":"2230"},
    {"city_id":"26214","city_name":"Eishishkes","state_id":"2230"},
    {"city_id":"26215","city_name":"Elektrenai","state_id":"2230"},
    {"city_id":"26216","city_name":"Grigishkes","state_id":"2230"},
    {"city_id":"26217","city_name":"Lentvaris","state_id":"2230"},
    {"city_id":"26218","city_name":"Nemenchine","state_id":"2230"},
    {"city_id":"26219","city_name":"Pabrade","state_id":"2230"},
    {"city_id":"26220","city_name":"Rudishkes","state_id":"2230"},
    {"city_id":"26221","city_name":"Shalchininkai","state_id":"2230"},
    {"city_id":"26222","city_name":"Shirvintos","state_id":"2230"},
    {"city_id":"26223","city_name":"Shvenchioneliai","state_id":"2230"},
    {"city_id":"26224","city_name":"Shvenchionys","state_id":"2230"},
    {"city_id":"26225","city_name":"Trakai","state_id":"2230"},
    {"city_id":"26226","city_name":"Ukmerge","state_id":"2230"},
    {"city_id":"26227","city_name":"Vievis","state_id":"2230"},
    {"city_id":"26228","city_name":"Vilnius","state_id":"2230"},
    {"city_id":"26229","city_name":"Bascharage","state_id":"2231"},
    {"city_id":"26230","city_name":"Bettange-sur-Mess","state_id":"2231"},
    {"city_id":"26231","city_name":"Bridel","state_id":"2231"},
    {"city_id":"26232","city_name":"Capellen","state_id":"2231"},
    {"city_id":"26233","city_name":"Clemency","state_id":"2231"},
    {"city_id":"26234","city_name":"Dahlem","state_id":"2231"},
    {"city_id":"26235","city_name":"Dippach","state_id":"2231"},
    {"city_id":"26236","city_name":"Dondelange","state_id":"2231"},
    {"city_id":"26237","city_name":"Eischen","state_id":"2231"},
    {"city_id":"26238","city_name":"Fingig","state_id":"2231"},
    {"city_id":"26239","city_name":"Garnich","state_id":"2231"},
    {"city_id":"26240","city_name":"Goeblange","state_id":"2231"},
    {"city_id":"26241","city_name":"Goetzingen","state_id":"2231"},
    {"city_id":"26242","city_name":"Grass","state_id":"2231"},
    {"city_id":"26243","city_name":"Greisch","state_id":"2231"},
    {"city_id":"26244","city_name":"Hagen","state_id":"2231"},
    {"city_id":"26245","city_name":"Hautcharage","state_id":"2231"},
    {"city_id":"26246","city_name":"Hivange","state_id":"2231"},
    {"city_id":"26247","city_name":"Hobscheid","state_id":"2231"},
    {"city_id":"26248","city_name":"Holzem","state_id":"2231"},
    {"city_id":"26249","city_name":"Kahler","state_id":"2231"},
    {"city_id":"26250","city_name":"Kehlen","state_id":"2231"},
    {"city_id":"26251","city_name":"Keispelt","state_id":"2231"},
    {"city_id":"26252","city_name":"Kleinbettingen","state_id":"2231"},
    {"city_id":"26253","city_name":"Koerich","state_id":"2231"},
    {"city_id":"26254","city_name":"Kospstal","state_id":"2231"},
    {"city_id":"26255","city_name":"Linger","state_id":"2231"},
    {"city_id":"26256","city_name":"Mamer","state_id":"2231"},
    {"city_id":"26257","city_name":"Meispelt","state_id":"2231"},
    {"city_id":"26258","city_name":"Nospelt","state_id":"2231"},
    {"city_id":"26259","city_name":"Olm","state_id":"2231"},
    {"city_id":"26260","city_name":"Roodt","state_id":"2231"},
    {"city_id":"26261","city_name":"Schouweiler","state_id":"2231"},
    {"city_id":"26262","city_name":"Septfontaines","state_id":"2231"},
    {"city_id":"26263","city_name":"Sprinkange","state_id":"2231"},
    {"city_id":"26264","city_name":"Steinfort","state_id":"2231"},
    {"city_id":"26265","city_name":"Allerborn","state_id":"2232"},
    {"city_id":"26266","city_name":"Asselborn","state_id":"2232"},
    {"city_id":"26267","city_name":"Basbellain","state_id":"2232"},
    {"city_id":"26268","city_name":"Beiler","state_id":"2232"},
    {"city_id":"26269","city_name":"Binsfeld","state_id":"2232"},
    {"city_id":"26270","city_name":"Biwisch","state_id":"2232"},
    {"city_id":"26271","city_name":"Bockholtz","state_id":"2232"},
    {"city_id":"26272","city_name":"Boevange","state_id":"2232"},
    {"city_id":"26273","city_name":"Boxhorn","state_id":"2232"},
    {"city_id":"26274","city_name":"Brachtenbach","state_id":"2232"},
    {"city_id":"26275","city_name":"Breidfeld","state_id":"2232"},
    {"city_id":"26276","city_name":"Cinqfontaines","state_id":"2232"},
    {"city_id":"26277","city_name":"Clervaux","state_id":"2232"},
    {"city_id":"26278","city_name":"Consthum","state_id":"2232"},
    {"city_id":"26279","city_name":"Crendal","state_id":"2232"},
    {"city_id":"26280","city_name":"Deiffelt-Lentzweiler","state_id":"2232"},
    {"city_id":"26281","city_name":"Derenbach","state_id":"2232"},
    {"city_id":"26282","city_name":"Doennange","state_id":"2232"},
    {"city_id":"26283","city_name":"Dorscheid","state_id":"2232"},
    {"city_id":"26284","city_name":"Drauffelt","state_id":"2232"},
    {"city_id":"26285","city_name":"Drinklange","state_id":"2232"},
    {"city_id":"26286","city_name":"Eisenbach","state_id":"2232"},
    {"city_id":"26287","city_name":"Eselborn","state_id":"2232"},
    {"city_id":"26288","city_name":"Fischbach","state_id":"2232"},
    {"city_id":"26289","city_name":"Goedange","state_id":"2232"},
    {"city_id":"26290","city_name":"Grindhausen","state_id":"2232"},
    {"city_id":"26291","city_name":"Hachiville","state_id":"2232"},
    {"city_id":"26292","city_name":"Hamiville","state_id":"2232"},
    {"city_id":"26293","city_name":"Hautbellain","state_id":"2232"},
    {"city_id":"26294","city_name":"Heinerscheid","state_id":"2232"},
    {"city_id":"26295","city_name":"Hoffelt","state_id":"2232"},
    {"city_id":"26296","city_name":"Holler","state_id":"2232"},
    {"city_id":"26297","city_name":"Holzthum","state_id":"2232"},
    {"city_id":"26298","city_name":"Hosingen","state_id":"2232"},
    {"city_id":"26299","city_name":"Huldange","state_id":"2232"},
    {"city_id":"26300","city_name":"Hupperdange","state_id":"2232"},
    {"city_id":"26301","city_name":"Kalborn","state_id":"2232"},
    {"city_id":"26302","city_name":"Leithum","state_id":"2232"},
    {"city_id":"26303","city_name":"Lieler","state_id":"2232"},
    {"city_id":"26304","city_name":"Lullange","state_id":"2232"},
    {"city_id":"26305","city_name":"Marnach","state_id":"2232"},
    {"city_id":"26306","city_name":"Maulusmuehle","state_id":"2232"},
    {"city_id":"26307","city_name":"Munshausen","state_id":"2232"},
    {"city_id":"26308","city_name":"Neidhausen","state_id":"2232"},
    {"city_id":"26309","city_name":"Niederwampach-Schimpach","state_id":"2232"},
    {"city_id":"26310","city_name":"Oberwampach","state_id":"2232"},
    {"city_id":"26311","city_name":"Reuler","state_id":"2232"},
    {"city_id":"26312","city_name":"Roder","state_id":"2232"},
    {"city_id":"26313","city_name":"Rodershausen","state_id":"2232"},
    {"city_id":"26314","city_name":"Rumlange-Lentzweiler","state_id":"2232"},
    {"city_id":"26315","city_name":"Sassel","state_id":"2232"},
    {"city_id":"26316","city_name":"Siebenaler","state_id":"2232"},
    {"city_id":"26317","city_name":"Stockem","state_id":"2232"},
    {"city_id":"26318","city_name":"Troine-Hinterhasselt","state_id":"2232"},
    {"city_id":"26319","city_name":"Troine-Route","state_id":"2232"},
    {"city_id":"26320","city_name":"Troisvierges","state_id":"2232"},
    {"city_id":"26321","city_name":"Urspelt","state_id":"2232"},
    {"city_id":"26322","city_name":"Wahlhausen","state_id":"2232"},
    {"city_id":"26323","city_name":"Weicherdange","state_id":"2232"},
    {"city_id":"26324","city_name":"Weiler","state_id":"2232"},
    {"city_id":"26325","city_name":"Weiswampach","state_id":"2232"},
    {"city_id":"26326","city_name":"Wilwerdange","state_id":"2232"},
    {"city_id":"26327","city_name":"Wincrange","state_id":"2232"},
    {"city_id":"26328","city_name":"Bastendorf","state_id":"2233"},
    {"city_id":"26329","city_name":"Bettendorf","state_id":"2233"},
    {"city_id":"26330","city_name":"Bigelbach","state_id":"2233"},
    {"city_id":"26331","city_name":"Bourscheid","state_id":"2233"},
    {"city_id":"26332","city_name":"Brandenbourg","state_id":"2233"},
    {"city_id":"26333","city_name":"Burden","state_id":"2233"},
    {"city_id":"26334","city_name":"Diekirch","state_id":"2233"},
    {"city_id":"26335","city_name":"Eppeldorf","state_id":"2233"},
    {"city_id":"26336","city_name":"Ermsdorf","state_id":"2233"},
    {"city_id":"26337","city_name":"Erpeldange","state_id":"2233"},
    {"city_id":"26338","city_name":"Ettelbruck","state_id":"2233"},
    {"city_id":"26339","city_name":"Folkendange","state_id":"2233"},
    {"city_id":"26340","city_name":"Gilsdorf","state_id":"2233"},
    {"city_id":"26341","city_name":"Hoesdorf","state_id":"2233"},
    {"city_id":"26342","city_name":"Hoscheid","state_id":"2233"},
    {"city_id":"26343","city_name":"Hoscheid-Dickt","state_id":"2233"},
    {"city_id":"26344","city_name":"Ingeldorf","state_id":"2233"},
    {"city_id":"26345","city_name":"Kehmen-Scheidel","state_id":"2233"},
    {"city_id":"26346","city_name":"Landscheid","state_id":"2233"},
    {"city_id":"26347","city_name":"Lipperscheid","state_id":"2233"},
    {"city_id":"26348","city_name":"Medernach","state_id":"2233"},
    {"city_id":"26349","city_name":"Mertzig","state_id":"2233"},
    {"city_id":"26350","city_name":"Michelau","state_id":"2233"},
    {"city_id":"26351","city_name":"Moestroff","state_id":"2233"},
    {"city_id":"26352","city_name":"Niederfeulen","state_id":"2233"},
    {"city_id":"26353","city_name":"Oberfeulen","state_id":"2233"},
    {"city_id":"26354","city_name":"Reisdorf","state_id":"2233"},
    {"city_id":"26355","city_name":"Savelborn + Fermes","state_id":"2233"},
    {"city_id":"26356","city_name":"Schieren","state_id":"2233"},
    {"city_id":"26357","city_name":"Schlindermanderscheid","state_id":"2233"},
    {"city_id":"26358","city_name":"Stegen","state_id":"2233"},
    {"city_id":"26359","city_name":"Tandel","state_id":"2233"},
    {"city_id":"26360","city_name":"Wallendorf","state_id":"2233"},
    {"city_id":"26361","city_name":"Warken","state_id":"2233"},
    {"city_id":"26362","city_name":"Welscheid","state_id":"2233"},
    {"city_id":"26363","city_name":"Altrier","state_id":"2234"},
    {"city_id":"26364","city_name":"Beaufort","state_id":"2234"},
    {"city_id":"26365","city_name":"Bech","state_id":"2234"},
    {"city_id":"26366","city_name":"Berdorf","state_id":"2234"},
    {"city_id":"26367","city_name":"Bollendorf-Pont","state_id":"2234"},
    {"city_id":"26368","city_name":"Born","state_id":"2234"},
    {"city_id":"26369","city_name":"Boursdorf","state_id":"2234"},
    {"city_id":"26370","city_name":"Breidweiler","state_id":"2234"},
    {"city_id":"26371","city_name":"Christnach","state_id":"2234"},
    {"city_id":"26372","city_name":"Consdorf","state_id":"2234"},
    {"city_id":"26373","city_name":"Dickweiler","state_id":"2234"},
    {"city_id":"26374","city_name":"Dillingen","state_id":"2234"},
    {"city_id":"26375","city_name":"Echternach","state_id":"2234"},
    {"city_id":"26376","city_name":"Girst","state_id":"2234"},
    {"city_id":"26377","city_name":"Girsterklaus","state_id":"2234"},
    {"city_id":"26378","city_name":"Givenich","state_id":"2234"},
    {"city_id":"26379","city_name":"Grundhof","state_id":"2234"},
    {"city_id":"26380","city_name":"Haller","state_id":"2234"},
    {"city_id":"26381","city_name":"Hemstal","state_id":"2234"},
    {"city_id":"26382","city_name":"Herborn","state_id":"2234"},
    {"city_id":"26383","city_name":"Hersberg-Kobenbour-Graulinster","state_id":"2234"},
    {"city_id":"26384","city_name":"Hinkel","state_id":"2234"},
    {"city_id":"26385","city_name":"Moersdorf","state_id":"2234"},
    {"city_id":"26386","city_name":"Mompach","state_id":"2234"},
    {"city_id":"26387","city_name":"Mullerthal","state_id":"2234"},
    {"city_id":"26388","city_name":"Osweiler","state_id":"2234"},
    {"city_id":"26389","city_name":"Rippig","state_id":"2234"},
    {"city_id":"26390","city_name":"Rosport","state_id":"2234"},
    {"city_id":"26391","city_name":"Scheidgen","state_id":"2234"},
    {"city_id":"26392","city_name":"Steinheim","state_id":"2234"},
    {"city_id":"26393","city_name":"Waldbillig","state_id":"2234"},
    {"city_id":"26394","city_name":"Weilerbach","state_id":"2234"},
    {"city_id":"26395","city_name":"Zittig","state_id":"2234"},
    {"city_id":"26396","city_name":"Abweiler","state_id":"2235"},
    {"city_id":"26397","city_name":"Aspelt","state_id":"2235"},
    {"city_id":"26398","city_name":"Belvaux","state_id":"2235"},
    {"city_id":"26399","city_name":"Berchem","state_id":"2235"},
    {"city_id":"26400","city_name":"Bergem","state_id":"2235"},
    {"city_id":"26401","city_name":"Bettembourg","state_id":"2235"},
    {"city_id":"26402","city_name":"Bivange","state_id":"2235"},
    {"city_id":"26403","city_name":"Crauthem","state_id":"2235"},
    {"city_id":"26404","city_name":"Differdange","state_id":"2235"},
    {"city_id":"26405","city_name":"Dudelange","state_id":"2235"},
    {"city_id":"26406","city_name":"Ehlange","state_id":"2235"},
    {"city_id":"26407","city_name":"Ehlerange","state_id":"2235"},
    {"city_id":"26408","city_name":"Esch-Alzette","state_id":"2235"},
    {"city_id":"26409","city_name":"Fennange","state_id":"2235"},
    {"city_id":"26410","city_name":"Foetz","state_id":"2235"},
    {"city_id":"26411","city_name":"Fousbann","state_id":"2235"},
    {"city_id":"26412","city_name":"Frisange","state_id":"2235"},
    {"city_id":"26413","city_name":"Hellange","state_id":"2235"},
    {"city_id":"26414","city_name":"Huncherange","state_id":"2235"},
    {"city_id":"26415","city_name":"Kayl","state_id":"2235"},
    {"city_id":"26416","city_name":"Kockelscheuer","state_id":"2235"},
    {"city_id":"26417","city_name":"Lamadelaine","state_id":"2235"},
    {"city_id":"26418","city_name":"Lasauvage","state_id":"2235"},
    {"city_id":"26419","city_name":"Leudelange","state_id":"2235"},
    {"city_id":"26420","city_name":"Limpach","state_id":"2235"},
    {"city_id":"26421","city_name":"Livange","state_id":"2235"},
    {"city_id":"26422","city_name":"Mondercange","state_id":"2235"},
    {"city_id":"26423","city_name":"Niedercorn","state_id":"2235"},
    {"city_id":"26424","city_name":"Noertzange","state_id":"2235"},
    {"city_id":"26425","city_name":"Obercorn","state_id":"2235"},
    {"city_id":"26426","city_name":"Peppange","state_id":"2235"},
    {"city_id":"26427","city_name":"Petange","state_id":"2235"},
    {"city_id":"26428","city_name":"Pissange","state_id":"2235"},
    {"city_id":"26429","city_name":"Pontpierre","state_id":"2235"},
    {"city_id":"26430","city_name":"Reckange-sur-Mess","state_id":"2235"},
    {"city_id":"26431","city_name":"Rodange","state_id":"2235"},
    {"city_id":"26432","city_name":"Roedgen","state_id":"2235"},
    {"city_id":"26433","city_name":"Roeser","state_id":"2235"},
    {"city_id":"26434","city_name":"Rumelange","state_id":"2235"},
    {"city_id":"26435","city_name":"Sanem","state_id":"2235"},
    {"city_id":"26436","city_name":"Schifflange","state_id":"2235"},
    {"city_id":"26437","city_name":"Soleuvre","state_id":"2235"},
    {"city_id":"26438","city_name":"Tetange","state_id":"2235"},
    {"city_id":"26439","city_name":"Wickrange","state_id":"2235"},
    {"city_id":"26440","city_name":"Ahn-Dreiborn","state_id":"2236"},
    {"city_id":"26441","city_name":"Altlinster","state_id":"2236"},
    {"city_id":"26442","city_name":"Beidweiler","state_id":"2236"},
    {"city_id":"26443","city_name":"Berbourg","state_id":"2236"},
    {"city_id":"26444","city_name":"Berg","state_id":"2236"},
    {"city_id":"26445","city_name":"Betzdorf","state_id":"2236"},
    {"city_id":"26446","city_name":"Beyren","state_id":"2236"},
    {"city_id":"26447","city_name":"Biwer","state_id":"2236"},
    {"city_id":"26448","city_name":"Biwerbach","state_id":"2236"},
    {"city_id":"26449","city_name":"Boudler","state_id":"2236"},
    {"city_id":"26450","city_name":"Boudlerbach","state_id":"2236"},
    {"city_id":"26451","city_name":"Bourglinster","state_id":"2236"},
    {"city_id":"26452","city_name":"Breinert","state_id":"2236"},
    {"city_id":"26453","city_name":"Brouch","state_id":"2236"},
    {"city_id":"26454","city_name":"Ehnen","state_id":"2236"},
    {"city_id":"26455","city_name":"Eisenborn","state_id":"2236"},
    {"city_id":"26456","city_name":"Eschweiler","state_id":"2236"},
    {"city_id":"26457","city_name":"Flaxweiler","state_id":"2236"},
    {"city_id":"26458","city_name":"Godbrange","state_id":"2236"},
    {"city_id":"26459","city_name":"Gonderange","state_id":"2236"},
    {"city_id":"26460","city_name":"Gostingen","state_id":"2236"},
    {"city_id":"26461","city_name":"Graulinster-Blumenthal","state_id":"2236"},
    {"city_id":"26462","city_name":"Grevenmacher","state_id":"2236"},
    {"city_id":"26463","city_name":"Hagelsdorf","state_id":"2236"},
    {"city_id":"26464","city_name":"Imbringen","state_id":"2236"},
    {"city_id":"26465","city_name":"Junglinster","state_id":"2236"},
    {"city_id":"26466","city_name":"Lellig","state_id":"2236"},
    {"city_id":"26467","city_name":"Machtum","state_id":"2236"},
    {"city_id":"26468","city_name":"Manternach","state_id":"2236"},
    {"city_id":"26469","city_name":"Mensdorf","state_id":"2236"},
    {"city_id":"26470","city_name":"Mertert","state_id":"2236"},
    {"city_id":"26471","city_name":"Munschecker","state_id":"2236"},
    {"city_id":"26472","city_name":"Niederdonven","state_id":"2236"},
    {"city_id":"26473","city_name":"Oberdonven","state_id":"2236"},
    {"city_id":"26474","city_name":"Olingen","state_id":"2236"},
    {"city_id":"26475","city_name":"Rodenbourg","state_id":"2236"},
    {"city_id":"26476","city_name":"Roodt-sur-Syr","state_id":"2236"},
    {"city_id":"26477","city_name":"Wasserbillig","state_id":"2236"},
    {"city_id":"26478","city_name":"Wecker","state_id":"2236"},
    {"city_id":"26479","city_name":"Wecker-Gare","state_id":"2236"},
    {"city_id":"26480","city_name":"Weydig","state_id":"2236"},
    {"city_id":"26481","city_name":"Wormeldange","state_id":"2236"},
    {"city_id":"26482","city_name":"Wormeldange-Haut","state_id":"2236"},
    {"city_id":"26483","city_name":"Alzingen","state_id":"2237"},
    {"city_id":"26484","city_name":"Bereldange","state_id":"2237"},
    {"city_id":"26485","city_name":"Bertrange","state_id":"2237"},
    {"city_id":"26486","city_name":"Contern","state_id":"2237"},
    {"city_id":"26487","city_name":"Ernster","state_id":"2237"},
    {"city_id":"26488","city_name":"Fentange","state_id":"2237"},
    {"city_id":"26489","city_name":"Findel","state_id":"2237"},
    {"city_id":"26490","city_name":"Hassel","state_id":"2237"},
    {"city_id":"26491","city_name":"Heisdorf","state_id":"2237"},
    {"city_id":"26492","city_name":"Helmsange","state_id":"2237"},
    {"city_id":"26493","city_name":"Hesperange","state_id":"2237"},
    {"city_id":"26494","city_name":"Hostert","state_id":"2237"},
    {"city_id":"26495","city_name":"Howald","state_id":"2237"},
    {"city_id":"26496","city_name":"Itzig","state_id":"2237"},
    {"city_id":"26497","city_name":"Luxembourg","state_id":"2237"},
    {"city_id":"26498","city_name":"Medingen","state_id":"2237"},
    {"city_id":"26499","city_name":"Moutfort","state_id":"2237"},
    {"city_id":"26500","city_name":"Mullendorf","state_id":"2237"},
    {"city_id":"26501","city_name":"Munsbach","state_id":"2237"},
    {"city_id":"26502","city_name":"Neuhaeusgen","state_id":"2237"},
    {"city_id":"26503","city_name":"Niederanven","state_id":"2237"},
    {"city_id":"26504","city_name":"Oberanven","state_id":"2237"},
    {"city_id":"26505","city_name":"Oetrange","state_id":"2237"},
    {"city_id":"26506","city_name":"Rameldange","state_id":"2237"},
    {"city_id":"26507","city_name":"Sandweiler","state_id":"2237"},
    {"city_id":"26508","city_name":"Schrassig","state_id":"2237"},
    {"city_id":"26509","city_name":"Schuttrange","state_id":"2237"},
    {"city_id":"26510","city_name":"Senningen","state_id":"2237"},
    {"city_id":"26511","city_name":"Senningerberg","state_id":"2237"},
    {"city_id":"26512","city_name":"Steinsel","state_id":"2237"},
    {"city_id":"26513","city_name":"Strassen","state_id":"2237"},
    {"city_id":"26514","city_name":"Syren","state_id":"2237"},
    {"city_id":"26515","city_name":"Uebersyren","state_id":"2237"},
    {"city_id":"26516","city_name":"Waldhof","state_id":"2237"},
    {"city_id":"26517","city_name":"Walferdange","state_id":"2237"},
    {"city_id":"26518","city_name":"Weiler-la-Tour","state_id":"2237"},
    {"city_id":"26519","city_name":"Angelsberg","state_id":"2238"},
    {"city_id":"26520","city_name":"Ansembourg","state_id":"2238"},
    {"city_id":"26521","city_name":"Beringen","state_id":"2238"},
    {"city_id":"26522","city_name":"Bissen","state_id":"2238"},
    {"city_id":"26523","city_name":"Blaschette","state_id":"2238"},
    {"city_id":"26524","city_name":"Boevange-Attert","state_id":"2238"},
    {"city_id":"26525","city_name":"Bofferdange","state_id":"2238"},
    {"city_id":"26526","city_name":"Bour","state_id":"2238"},
    {"city_id":"26527","city_name":"Brouch","state_id":"2238"},
    {"city_id":"26528","city_name":"Buschdorf","state_id":"2238"},
    {"city_id":"26529","city_name":"Colmar-Berg","state_id":"2238"},
    {"city_id":"26530","city_name":"Cruchten","state_id":"2238"},
    {"city_id":"26531","city_name":"Ernzen","state_id":"2238"},
    {"city_id":"26532","city_name":"Essingen","state_id":"2238"},
    {"city_id":"26533","city_name":"Fischbach","state_id":"2238"},
    {"city_id":"26534","city_name":"Glabach","state_id":"2238"},
    {"city_id":"26535","city_name":"Gosseldange","state_id":"2238"},
    {"city_id":"26536","city_name":"Grevenknapp-Bill-Finsterthal","state_id":"2238"},
    {"city_id":"26537","city_name":"Heffingen","state_id":"2238"},
    {"city_id":"26538","city_name":"Helmdange","state_id":"2238"},
    {"city_id":"26539","city_name":"Hollenfels","state_id":"2238"},
    {"city_id":"26540","city_name":"Hunsdorf","state_id":"2238"},
    {"city_id":"26541","city_name":"Larochette","state_id":"2238"},
    {"city_id":"26542","city_name":"Lintgen","state_id":"2238"},
    {"city_id":"26543","city_name":"Lorentzweiler","state_id":"2238"},
    {"city_id":"26544","city_name":"Marienthal","state_id":"2238"},
    {"city_id":"26545","city_name":"Mersch","state_id":"2238"},
    {"city_id":"26546","city_name":"Moesdorf","state_id":"2238"},
    {"city_id":"26547","city_name":"Nommern","state_id":"2238"},
    {"city_id":"26548","city_name":"Pettingen","state_id":"2238"},
    {"city_id":"26549","city_name":"Prettange","state_id":"2238"},
    {"city_id":"26550","city_name":"Reckange","state_id":"2238"},
    {"city_id":"26551","city_name":"Reuland","state_id":"2238"},
    {"city_id":"26552","city_name":"Rollingen","state_id":"2238"},
    {"city_id":"26553","city_name":"Scherbach","state_id":"2238"},
    {"city_id":"26554","city_name":"Scherfenhof","state_id":"2238"},
    {"city_id":"26555","city_name":"Schiltzberg","state_id":"2238"},
    {"city_id":"26556","city_name":"Schoenfels","state_id":"2238"},
    {"city_id":"26557","city_name":"Schoos","state_id":"2238"},
    {"city_id":"26558","city_name":"Schrondweiler","state_id":"2238"},
    {"city_id":"26559","city_name":"Tuntange","state_id":"2238"},
    {"city_id":"26560","city_name":"Arsdorf","state_id":"2239"},
    {"city_id":"26561","city_name":"Beckerich","state_id":"2239"},
    {"city_id":"26562","city_name":"Bettborn","state_id":"2239"},
    {"city_id":"26563","city_name":"Bigonville","state_id":"2239"},
    {"city_id":"26564","city_name":"Bilsdorf","state_id":"2239"},
    {"city_id":"26565","city_name":"Brattert-Rindschleiden","state_id":"2239"},
    {"city_id":"26566","city_name":"Buschrodt","state_id":"2239"},
    {"city_id":"26567","city_name":"Calmus","state_id":"2239"},
    {"city_id":"26568","city_name":"Colpach-Bas","state_id":"2239"},
    {"city_id":"26569","city_name":"Colpach-Haut","state_id":"2239"},
    {"city_id":"26570","city_name":"Dellen-Lehrhof-Grevels","state_id":"2239"},
    {"city_id":"26571","city_name":"Ehner","state_id":"2239"},
    {"city_id":"26572","city_name":"Ell","state_id":"2239"},
    {"city_id":"26573","city_name":"Eltz","state_id":"2239"},
    {"city_id":"26574","city_name":"Elvange","state_id":"2239"},
    {"city_id":"26575","city_name":"Eschette","state_id":"2239"},
    {"city_id":"26576","city_name":"Everlange","state_id":"2239"},
    {"city_id":"26577","city_name":"Folschette","state_id":"2239"},
    {"city_id":"26578","city_name":"Grevels","state_id":"2239"},
    {"city_id":"26579","city_name":"Grosbous","state_id":"2239"},
    {"city_id":"26580","city_name":"Haut-Martelange","state_id":"2239"},
    {"city_id":"26581","city_name":"Heispelt","state_id":"2239"},
    {"city_id":"26582","city_name":"Holtz","state_id":"2239"},
    {"city_id":"26583","city_name":"Hostert","state_id":"2239"},
    {"city_id":"26584","city_name":"Hovelange","state_id":"2239"},
    {"city_id":"26585","city_name":"Huttange","state_id":"2239"},
    {"city_id":"26586","city_name":"Kapweiler","state_id":"2239"},
    {"city_id":"26587","city_name":"Koetschette","state_id":"2239"},
    {"city_id":"26588","city_name":"Kuborn","state_id":"2239"},
    {"city_id":"26589","city_name":"Lannen","state_id":"2239"},
    {"city_id":"26590","city_name":"Levelange","state_id":"2239"},
    {"city_id":"26591","city_name":"Michelbouch","state_id":"2239"},
    {"city_id":"26592","city_name":"Nagem","state_id":"2239"},
    {"city_id":"26593","city_name":"Niederpallen","state_id":"2239"},
    {"city_id":"26594","city_name":"Noerdange","state_id":"2239"},
    {"city_id":"26595","city_name":"Oberpallen","state_id":"2239"},
    {"city_id":"26596","city_name":"Ospern","state_id":"2239"},
    {"city_id":"26597","city_name":"Perle","state_id":"2239"},
    {"city_id":"26598","city_name":"Petit-Nobressart","state_id":"2239"},
    {"city_id":"26599","city_name":"Platen","state_id":"2239"},
    {"city_id":"26600","city_name":"Pratz","state_id":"2239"},
    {"city_id":"26601","city_name":"Rambrouch","state_id":"2239"},
    {"city_id":"26602","city_name":"Redange","state_id":"2239"},
    {"city_id":"26603","city_name":"Reichlange","state_id":"2239"},
    {"city_id":"26604","city_name":"Reimberg","state_id":"2239"},
    {"city_id":"26605","city_name":"Rippweiler","state_id":"2239"},
    {"city_id":"26606","city_name":"Rombach","state_id":"2239"},
    {"city_id":"26607","city_name":"Roodt","state_id":"2239"},
    {"city_id":"26608","city_name":"Saeul","state_id":"2239"},
    {"city_id":"26609","city_name":"Schandel","state_id":"2239"},
    {"city_id":"26610","city_name":"Schwebach","state_id":"2239"},
    {"city_id":"26611","city_name":"Schweich","state_id":"2239"},
    {"city_id":"26612","city_name":"Useldange","state_id":"2239"},
    {"city_id":"26613","city_name":"Vichten","state_id":"2239"},
    {"city_id":"26614","city_name":"Wahl","state_id":"2239"},
    {"city_id":"26615","city_name":"Wolwelange","state_id":"2239"},
    {"city_id":"26616","city_name":"Altwies","state_id":"2240"},
    {"city_id":"26617","city_name":"Assel","state_id":"2240"},
    {"city_id":"26618","city_name":"Bech-Kleinmacher","state_id":"2240"},
    {"city_id":"26619","city_name":"Bous","state_id":"2240"},
    {"city_id":"26620","city_name":"Burmerange","state_id":"2240"},
    {"city_id":"26621","city_name":"Canach","state_id":"2240"},
    {"city_id":"26622","city_name":"Dalheim","state_id":"2240"},
    {"city_id":"26623","city_name":"Ellange","state_id":"2240"},
    {"city_id":"26624","city_name":"Elvange","state_id":"2240"},
    {"city_id":"26625","city_name":"Emerange","state_id":"2240"},
    {"city_id":"26626","city_name":"Erpeldange","state_id":"2240"},
    {"city_id":"26627","city_name":"Filsdorf","state_id":"2240"},
    {"city_id":"26628","city_name":"Greiveldange","state_id":"2240"},
    {"city_id":"26629","city_name":"Lenningen","state_id":"2240"},
    {"city_id":"26630","city_name":"Mondorf-les-Bains","state_id":"2240"},
    {"city_id":"26631","city_name":"Remerschen","state_id":"2240"},
    {"city_id":"26632","city_name":"Remich","state_id":"2240"},
    {"city_id":"26633","city_name":"Rolling","state_id":"2240"},
    {"city_id":"26634","city_name":"Schengen","state_id":"2240"},
    {"city_id":"26635","city_name":"Schwebsingen","state_id":"2240"},
    {"city_id":"26636","city_name":"Stadtbredimus","state_id":"2240"},
    {"city_id":"26637","city_name":"Trintange","state_id":"2240"},
    {"city_id":"26638","city_name":"Trintange-Ersange-Roedt","state_id":"2240"},
    {"city_id":"26639","city_name":"Waldbredimus","state_id":"2240"},
    {"city_id":"26640","city_name":"Welfrange","state_id":"2240"},
    {"city_id":"26641","city_name":"Wellenstein","state_id":"2240"},
    {"city_id":"26642","city_name":"Wintrange","state_id":"2240"},
    {"city_id":"26643","city_name":"Bettel","state_id":"2241"},
    {"city_id":"26644","city_name":"Bivels","state_id":"2241"},
    {"city_id":"26645","city_name":"Fouhren","state_id":"2241"},
    {"city_id":"26646","city_name":"Gralingen","state_id":"2241"},
    {"city_id":"26647","city_name":"Longsdorf","state_id":"2241"},
    {"city_id":"26648","city_name":"Merscheid","state_id":"2241"},
    {"city_id":"26649","city_name":"Nachtmanderscheid","state_id":"2241"},
    {"city_id":"26650","city_name":"Putscheid","state_id":"2241"},
    {"city_id":"26651","city_name":"Stolzembourg","state_id":"2241"},
    {"city_id":"26652","city_name":"Vianden","state_id":"2241"},
    {"city_id":"26653","city_name":"Walsdorf","state_id":"2241"},
    {"city_id":"26654","city_name":"Weiler","state_id":"2241"},
    {"city_id":"26655","city_name":"Alscheid","state_id":"2242"},
    {"city_id":"26656","city_name":"Baschleiden","state_id":"2242"},
    {"city_id":"26657","city_name":"Bavigne","state_id":"2242"},
    {"city_id":"26658","city_name":"Berle","state_id":"2242"},
    {"city_id":"26659","city_name":"Bockholtz","state_id":"2242"},
    {"city_id":"26660","city_name":"Boulaide","state_id":"2242"},
    {"city_id":"26661","city_name":"Buderscheid","state_id":"2242"},
    {"city_id":"26662","city_name":"Dahl","state_id":"2242"},
    {"city_id":"26663","city_name":"Doncols","state_id":"2242"},
    {"city_id":"26664","city_name":"Enscherange","state_id":"2242"},
    {"city_id":"26665","city_name":"Erpeldange","state_id":"2242"},
    {"city_id":"26666","city_name":"Esch-sur-Sure","state_id":"2242"},
    {"city_id":"26667","city_name":"Eschdorf","state_id":"2242"},
    {"city_id":"26668","city_name":"Eschweiler","state_id":"2242"},
    {"city_id":"26669","city_name":"Goesdorf","state_id":"2242"},
    {"city_id":"26670","city_name":"Grumelscheid","state_id":"2242"},
    {"city_id":"26671","city_name":"Harlange","state_id":"2242"},
    {"city_id":"26672","city_name":"Heiderscheid","state_id":"2242"},
    {"city_id":"26673","city_name":"Heiderscheid-Fond","state_id":"2242"},
    {"city_id":"26674","city_name":"Hierheck","state_id":"2242"},
    {"city_id":"26675","city_name":"Insenborn","state_id":"2242"},
    {"city_id":"26676","city_name":"Kaundorf","state_id":"2242"},
    {"city_id":"26677","city_name":"Kautenbach","state_id":"2242"},
    {"city_id":"26678","city_name":"Knaphoscheid","state_id":"2242"},
    {"city_id":"26679","city_name":"Lellingen","state_id":"2242"},
    {"city_id":"26680","city_name":"Liefrange","state_id":"2242"},
    {"city_id":"26681","city_name":"Lultzhausen","state_id":"2242"},
    {"city_id":"26682","city_name":"Masseler","state_id":"2242"},
    {"city_id":"26683","city_name":"Mecher","state_id":"2242"},
    {"city_id":"26684","city_name":"Merckholtz","state_id":"2242"},
    {"city_id":"26685","city_name":"Merscheid","state_id":"2242"},
    {"city_id":"26686","city_name":"Neunhausen","state_id":"2242"},
    {"city_id":"26687","city_name":"Nocher","state_id":"2242"},
    {"city_id":"26688","city_name":"Nocher-Route","state_id":"2242"},
    {"city_id":"26689","city_name":"Noertrange","state_id":"2242"},
    {"city_id":"26690","city_name":"Nothum","state_id":"2242"},
    {"city_id":"26691","city_name":"Pintsch","state_id":"2242"},
    {"city_id":"26692","city_name":"Pommerloch","state_id":"2242"},
    {"city_id":"26693","city_name":"Schleif","state_id":"2242"},
    {"city_id":"26694","city_name":"Selscheid","state_id":"2242"},
    {"city_id":"26695","city_name":"Sonlez","state_id":"2242"},
    {"city_id":"26696","city_name":"Surre","state_id":"2242"},
    {"city_id":"26697","city_name":"Tadler-Ringel-Dirbach","state_id":"2242"},
    {"city_id":"26698","city_name":"Tarchamps","state_id":"2242"},
    {"city_id":"26699","city_name":"Watrange","state_id":"2242"},
    {"city_id":"26700","city_name":"Wiltz","state_id":"2242"},
    {"city_id":"26701","city_name":"Wilwerwiltz","state_id":"2242"},
    {"city_id":"26702","city_name":"Winseler","state_id":"2242"},
    {"city_id":"26703","city_name":"Macau","state_id":"2243"},
    {"city_id":"26704","city_name":"Berovo","state_id":"2244"},
    {"city_id":"26705","city_name":"Pehchevo","state_id":"2244"},
    {"city_id":"26706","city_name":"Bach","state_id":"2245"},
    {"city_id":"26707","city_name":"Bistrica","state_id":"2245"},
    {"city_id":"26708","city_name":"Bitola","state_id":"2245"},
    {"city_id":"26709","city_name":"Capari","state_id":"2245"},
    {"city_id":"26710","city_name":"Dobrushevo","state_id":"2245"},
    {"city_id":"26711","city_name":"Kukurechani","state_id":"2245"},
    {"city_id":"26712","city_name":"Mogila","state_id":"2245"},
    {"city_id":"26713","city_name":"Novaci","state_id":"2245"},
    {"city_id":"26714","city_name":"Staravina","state_id":"2245"},
    {"city_id":"26715","city_name":"Brod","state_id":"2246"},
    {"city_id":"26716","city_name":"Plasnica","state_id":"2246"},
    {"city_id":"26717","city_name":"Samokov","state_id":"2246"},
    {"city_id":"26718","city_name":"Debar","state_id":"2247"},
    {"city_id":"26719","city_name":"Zhupa","state_id":"2247"},
    {"city_id":"26720","city_name":"Delchevo","state_id":"2248"},
    {"city_id":"26721","city_name":"Kamenica","state_id":"2248"},
    {"city_id":"26722","city_name":"Bogdanci","state_id":"2250"},
    {"city_id":"26723","city_name":"Gevgelija","state_id":"2250"},
    {"city_id":"26724","city_name":"Miravci","state_id":"2250"},
    {"city_id":"26725","city_name":"Star Dojran","state_id":"2250"},
    {"city_id":"26726","city_name":"Chegrane","state_id":"2251"},
    {"city_id":"26727","city_name":"Dolna Banjica","state_id":"2251"},
    {"city_id":"26728","city_name":"Gostivar","state_id":"2251"},
    {"city_id":"26729","city_name":"Mavrovi Anovi","state_id":"2251"},
    {"city_id":"26730","city_name":"Negotino-Poloshko","state_id":"2251"},
    {"city_id":"26731","city_name":"Rostusha","state_id":"2251"},
    {"city_id":"26732","city_name":"Srbinovo","state_id":"2251"},
    {"city_id":"26733","city_name":"Vrapchishte","state_id":"2251"},
    {"city_id":"26734","city_name":"Vrutok","state_id":"2251"},
    {"city_id":"26735","city_name":"Kavadarci","state_id":"2252"},
    {"city_id":"26736","city_name":"Konopishte","state_id":"2252"},
    {"city_id":"26737","city_name":"Rosoman","state_id":"2252"},
    {"city_id":"26738","city_name":"Drugovo","state_id":"2253"},
    {"city_id":"26739","city_name":"Kichevo","state_id":"2253"},
    {"city_id":"26740","city_name":"Oslomej","state_id":"2253"},
    {"city_id":"26741","city_name":"Vraneshtica","state_id":"2253"},
    {"city_id":"26742","city_name":"Zajas","state_id":"2253"},
    {"city_id":"26743","city_name":"Cheshinovo","state_id":"2254"},
    {"city_id":"26744","city_name":"Kochani","state_id":"2254"},
    {"city_id":"26745","city_name":"Obleshevo","state_id":"2254"},
    {"city_id":"26746","city_name":"Zletovo","state_id":"2254"},
    {"city_id":"26747","city_name":"Kratovo","state_id":"2255"},
    {"city_id":"26748","city_name":"Krushevo","state_id":"2257"},
    {"city_id":"26749","city_name":"Zhitoshe","state_id":"2257"},
    {"city_id":"26750","city_name":"Izvor","state_id":"2258"},
    {"city_id":"26751","city_name":"Klechevce","state_id":"2258"},
    {"city_id":"26752","city_name":"Kumanovo","state_id":"2258"},
    {"city_id":"26753","city_name":"Lipkovo","state_id":"2258"},
    {"city_id":"26754","city_name":"Orashac","state_id":"2258"},
    {"city_id":"26755","city_name":"Staro Nagorichane","state_id":"2258"},
    {"city_id":"26756","city_name":"Demir Kapija","state_id":"2259"},
    {"city_id":"26757","city_name":"Negotino","state_id":"2259"},
    {"city_id":"26758","city_name":"Belchishta","state_id":"2260"},
    {"city_id":"26759","city_name":"Kosel","state_id":"2260"},
    {"city_id":"26760","city_name":"Mesheishta","state_id":"2260"},
    {"city_id":"26761","city_name":"Ohrid","state_id":"2260"},
    {"city_id":"26762","city_name":"Dolneni","state_id":"2261"},
    {"city_id":"26763","city_name":"Krivogashtani","state_id":"2261"},
    {"city_id":"26764","city_name":"Prilep","state_id":"2261"},
    {"city_id":"26765","city_name":"Topolchani","state_id":"2261"},
    {"city_id":"26766","city_name":"Vitolishte","state_id":"2261"},
    {"city_id":"26767","city_name":"Probishtip","state_id":"2262"},
    {"city_id":"26768","city_name":"Konche","state_id":"2263"},
    {"city_id":"26769","city_name":"Podaresh","state_id":"2263"},
    {"city_id":"26770","city_name":"Radovish","state_id":"2263"},
    {"city_id":"26771","city_name":"Resen","state_id":"2264"},
    {"city_id":"26772","city_name":"Karbinci","state_id":"2265"},
    {"city_id":"26773","city_name":"Shtip","state_id":"2265"},
    {"city_id":"26774","city_name":"Stip","state_id":"2265"},
    {"city_id":"26775","city_name":"Arachinovo","state_id":"2266"},
    {"city_id":"26776","city_name":"Chucher","state_id":"2266"},
    {"city_id":"26777","city_name":"Ilinden","state_id":"2266"},
    {"city_id":"26778","city_name":"Kondovo","state_id":"2266"},
    {"city_id":"26779","city_name":"Petrovec","state_id":"2266"},
    {"city_id":"26780","city_name":"Saraj","state_id":"2266"},
    {"city_id":"26781","city_name":"Skopje","state_id":"2266"},
    {"city_id":"26782","city_name":"Sopishte","state_id":"2266"},
    {"city_id":"26783","city_name":"Studenichani","state_id":"2266"},
    {"city_id":"26784","city_name":"Zelenikovo","state_id":"2266"},
    {"city_id":"26785","city_name":"Delogozhdi","state_id":"2267"},
    {"city_id":"26786","city_name":"Labunishta","state_id":"2267"},
    {"city_id":"26787","city_name":"Lukovo","state_id":"2267"},
    {"city_id":"26788","city_name":"Struga","state_id":"2267"},
    {"city_id":"26789","city_name":"Veleshta","state_id":"2267"},
    {"city_id":"26790","city_name":"Vevchani","state_id":"2267"},
    {"city_id":"26791","city_name":"Bosilovo","state_id":"2268"},
    {"city_id":"26792","city_name":"Kuklish","state_id":"2268"},
    {"city_id":"26793","city_name":"Murtino","state_id":"2268"},
    {"city_id":"26794","city_name":"Novo Selo","state_id":"2268"},
    {"city_id":"26795","city_name":"Strumica","state_id":"2268"},
    {"city_id":"26796","city_name":"Vasilevo","state_id":"2268"},
    {"city_id":"26797","city_name":"Bogovinje","state_id":"2270"},
    {"city_id":"26798","city_name":"Brvenica","state_id":"2270"},
    {"city_id":"26799","city_name":"Dzhepchishte","state_id":"2270"},
    {"city_id":"26800","city_name":"Jegunovce","state_id":"2270"},
    {"city_id":"26801","city_name":"Kamenjance","state_id":"2270"},
    {"city_id":"26802","city_name":"Shipkovica","state_id":"2270"},
    {"city_id":"26803","city_name":"Tearce","state_id":"2270"},
    {"city_id":"26804","city_name":"Tetovo","state_id":"2270"},
    {"city_id":"26805","city_name":"Vratnica","state_id":"2270"},
    {"city_id":"26806","city_name":"Zhelino","state_id":"2270"},
    {"city_id":"26807","city_name":"Valandovo","state_id":"2271"},
    {"city_id":"26808","city_name":"Bogomila","state_id":"2272"},
    {"city_id":"26809","city_name":"Chashka","state_id":"2272"},
    {"city_id":"26810","city_name":"Gradsko","state_id":"2272"},
    {"city_id":"26811","city_name":"Veles","state_id":"2272"},
    {"city_id":"26812","city_name":"Blatec","state_id":"2273"},
    {"city_id":"26813","city_name":"Orizari","state_id":"2273"},
    {"city_id":"26814","city_name":"Vinica","state_id":"2273"},
    {"city_id":"26815","city_name":"Zrnovci","state_id":"2273"},
    {"city_id":"26816","city_name":"Ambatolampy","state_id":"2274"},
    {"city_id":"26817","city_name":"Anjozorobe","state_id":"2274"},
    {"city_id":"26818","city_name":"Ankazobe","state_id":"2274"},
    {"city_id":"26819","city_name":"Antananarivo","state_id":"2274"},
    {"city_id":"26820","city_name":"Antanifotsy","state_id":"2274"},
    {"city_id":"26821","city_name":"Antsirabe","state_id":"2274"},
    {"city_id":"26822","city_name":"Arivonimamo","state_id":"2274"},
    {"city_id":"26823","city_name":"Betafo","state_id":"2274"},
    {"city_id":"26824","city_name":"Faratsiho","state_id":"2274"},
    {"city_id":"26825","city_name":"Fenoarivo","state_id":"2274"},
    {"city_id":"26826","city_name":"Manjakandriana","state_id":"2274"},
    {"city_id":"26827","city_name":"Soavinandriana","state_id":"2274"},
    {"city_id":"26828","city_name":"Tsiroanomandidy","state_id":"2274"},
    {"city_id":"26829","city_name":"Ambanja","state_id":"2275"},
    {"city_id":"26830","city_name":"Ambilobe","state_id":"2275"},
    {"city_id":"26831","city_name":"Andapa","state_id":"2275"},
    {"city_id":"26832","city_name":"Antalaha","state_id":"2275"},
    {"city_id":"26833","city_name":"Antsirambazaha","state_id":"2275"},
    {"city_id":"26834","city_name":"Antsiranana","state_id":"2275"},
    {"city_id":"26835","city_name":"Sambava","state_id":"2275"},
    {"city_id":"26836","city_name":"Ambalavao","state_id":"2276"},
    {"city_id":"26837","city_name":"Ambatofinandrahana","state_id":"2276"},
    {"city_id":"26838","city_name":"Ambositra","state_id":"2276"},
    {"city_id":"26839","city_name":"Fandriana","state_id":"2276"},
    {"city_id":"26840","city_name":"Farafangana","state_id":"2276"},
    {"city_id":"26841","city_name":"Fianarantsoa","state_id":"2276"},
    {"city_id":"26842","city_name":"Ifanadiana","state_id":"2276"},
    {"city_id":"26843","city_name":"Ihosy","state_id":"2276"},
    {"city_id":"26844","city_name":"Ikalamavony","state_id":"2276"},
    {"city_id":"26845","city_name":"Ikongo","state_id":"2276"},
    {"city_id":"26846","city_name":"Manakara","state_id":"2276"},
    {"city_id":"26847","city_name":"Manandriana","state_id":"2276"},
    {"city_id":"26848","city_name":"Mananjary","state_id":"2276"},
    {"city_id":"26849","city_name":"Nosy Varika","state_id":"2276"},
    {"city_id":"26850","city_name":"Vangaindrano","state_id":"2276"},
    {"city_id":"26851","city_name":"Vondrozo","state_id":"2276"},
    {"city_id":"26852","city_name":"Ambato Boina","state_id":"2277"},
    {"city_id":"26853","city_name":"Antsohihy","state_id":"2277"},
    {"city_id":"26854","city_name":"Bealanana","state_id":"2277"},
    {"city_id":"26855","city_name":"Mahajanga","state_id":"2277"},
    {"city_id":"26856","city_name":"Marovoay","state_id":"2277"},
    {"city_id":"26857","city_name":"Tsaratanana","state_id":"2277"},
    {"city_id":"26858","city_name":"Ambatondrazaka","state_id":"2278"},
    {"city_id":"26859","city_name":"Ambodifototra","state_id":"2278"},
    {"city_id":"26860","city_name":"Amparafaravola","state_id":"2278"},
    {"city_id":"26861","city_name":"Andevoranto","state_id":"2278"},
    {"city_id":"26862","city_name":"Andilamena","state_id":"2278"},
    {"city_id":"26863","city_name":"Anosibe An'ala","state_id":"2278"},
    {"city_id":"26864","city_name":"Fenoarivo Atsinanana","state_id":"2278"},
    {"city_id":"26865","city_name":"Mahanoro","state_id":"2278"},
    {"city_id":"26866","city_name":"Mananara","state_id":"2278"},
    {"city_id":"26867","city_name":"Maroantsetra","state_id":"2278"},
    {"city_id":"26868","city_name":"Marolambo","state_id":"2278"},
    {"city_id":"26869","city_name":"Moramanga","state_id":"2278"},
    {"city_id":"26870","city_name":"Soanierana Ivongo","state_id":"2278"},
    {"city_id":"26871","city_name":"Toamasina","state_id":"2278"},
    {"city_id":"26872","city_name":"Vavatenina","state_id":"2278"},
    {"city_id":"26873","city_name":"Vohibinany","state_id":"2278"},
    {"city_id":"26874","city_name":"Amboasary","state_id":"2279"},
    {"city_id":"26875","city_name":"Ambovombe","state_id":"2279"},
    {"city_id":"26876","city_name":"Ampanihy","state_id":"2279"},
    {"city_id":"26877","city_name":"Ankazoabo","state_id":"2279"},
    {"city_id":"26878","city_name":"Beloha","state_id":"2279"},
    {"city_id":"26879","city_name":"Belon'i Tsiribihina","state_id":"2279"},
    {"city_id":"26880","city_name":"Beroroha","state_id":"2279"},
    {"city_id":"26881","city_name":"Betioky","state_id":"2279"},
    {"city_id":"26882","city_name":"Miandrivazo","state_id":"2279"},
    {"city_id":"26883","city_name":"Morondava","state_id":"2279"},
    {"city_id":"26884","city_name":"Sakaraha","state_id":"2279"},
    {"city_id":"26885","city_name":"Taolanaro","state_id":"2279"},
    {"city_id":"26886","city_name":"Toliary","state_id":"2279"},
    {"city_id":"26887","city_name":"Tsihombe","state_id":"2279"},
    {"city_id":"26888","city_name":"Balaka","state_id":"2280"},
    {"city_id":"26889","city_name":"Chikwawa","state_id":"2282"},
    {"city_id":"26890","city_name":"Chiradzulu","state_id":"2283"},
    {"city_id":"26891","city_name":"Chitipa","state_id":"2284"},
    {"city_id":"26892","city_name":"Chipoka","state_id":"2285"},
    {"city_id":"26893","city_name":"Dedza","state_id":"2285"},
    {"city_id":"26894","city_name":"Dowa","state_id":"2286"},
    {"city_id":"26895","city_name":"Mponela","state_id":"2286"},
    {"city_id":"26896","city_name":"Chilumba","state_id":"2287"},
    {"city_id":"26897","city_name":"Karonga","state_id":"2287"},
    {"city_id":"26898","city_name":"Livingstonia","state_id":"2287"},
    {"city_id":"26899","city_name":"Kasungu","state_id":"2288"},
    {"city_id":"26900","city_name":"Liwonde","state_id":"2290"},
    {"city_id":"26901","city_name":"Machinga","state_id":"2290"},
    {"city_id":"26902","city_name":"Mangochi","state_id":"2291"},
    {"city_id":"26903","city_name":"Monkey Bay","state_id":"2291"},
    {"city_id":"26904","city_name":"Mchinji","state_id":"2292"},
    {"city_id":"26905","city_name":"Mulanje","state_id":"2293"},
    {"city_id":"26906","city_name":"Mwanza","state_id":"2294"},
    {"city_id":"26907","city_name":"Mzimba","state_id":"2295"},
    {"city_id":"26908","city_name":"Nkhotakota","state_id":"2298"},
    {"city_id":"26909","city_name":"Nsanje","state_id":"2299"},
    {"city_id":"26910","city_name":"Ntcheu","state_id":"2300"},
    {"city_id":"26911","city_name":"Ntchisi","state_id":"2301"},
    {"city_id":"26912","city_name":"Phalombe","state_id":"2302"},
    {"city_id":"26913","city_name":"Rumphi","state_id":"2303"},
    {"city_id":"26914","city_name":"Salima","state_id":"2304"},
    {"city_id":"26915","city_name":"Luchenza","state_id":"2305"},
    {"city_id":"26916","city_name":"Thyolo","state_id":"2305"},
    {"city_id":"26917","city_name":"Bandar Maharani","state_id":"2307"},
    {"city_id":"26918","city_name":"Bandar Penggaram","state_id":"2307"},
    {"city_id":"26919","city_name":"Bukit Bakri","state_id":"2307"},
    {"city_id":"26920","city_name":"Buloh Kasap","state_id":"2307"},
    {"city_id":"26921","city_name":"Chaah","state_id":"2307"},
    {"city_id":"26922","city_name":"Johor Bahru","state_id":"2307"},
    {"city_id":"26923","city_name":"Kelapa Sawit","state_id":"2307"},
    {"city_id":"26924","city_name":"Kluang","state_id":"2307"},
    {"city_id":"26925","city_name":"Kota Tinggi","state_id":"2307"},
    {"city_id":"26926","city_name":"Kulai","state_id":"2307"},
    {"city_id":"26927","city_name":"Labis","state_id":"2307"},
    {"city_id":"26928","city_name":"Ledang","state_id":"2307"},
    {"city_id":"26929","city_name":"Masai","state_id":"2307"},
    {"city_id":"26930","city_name":"Mersing","state_id":"2307"},
    {"city_id":"26931","city_name":"Parit Raja","state_id":"2307"},
    {"city_id":"26932","city_name":"Pasir Gudang","state_id":"2307"},
    {"city_id":"26933","city_name":"Pekan Nenas","state_id":"2307"},
    {"city_id":"26934","city_name":"Pontian Kecil","state_id":"2307"},
    {"city_id":"26935","city_name":"Segamat","state_id":"2307"},
    {"city_id":"26936","city_name":"Sekudai","state_id":"2307"},
    {"city_id":"26937","city_name":"Senai","state_id":"2307"},
    {"city_id":"26938","city_name":"Simpang Rengam","state_id":"2307"},
    {"city_id":"26939","city_name":"Tangkak","state_id":"2307"},
    {"city_id":"26940","city_name":"Ulu Tiram","state_id":"2307"},
    {"city_id":"26941","city_name":"Yong Peng","state_id":"2307"},
    {"city_id":"26942","city_name":"Alor Setar","state_id":"2308"},
    {"city_id":"26943","city_name":"Bailing","state_id":"2308"},
    {"city_id":"26944","city_name":"Bedong","state_id":"2308"},
    {"city_id":"26945","city_name":"Gurun","state_id":"2308"},
    {"city_id":"26946","city_name":"Jitra","state_id":"2308"},
    {"city_id":"26947","city_name":"Kuah","state_id":"2308"},
    {"city_id":"26948","city_name":"Kuala Kedah","state_id":"2308"},
    {"city_id":"26949","city_name":"Kulim","state_id":"2308"},
    {"city_id":"26950","city_name":"Langgar","state_id":"2308"},
    {"city_id":"26951","city_name":"Sungai Petani","state_id":"2308"},
    {"city_id":"26952","city_name":"Gua Musang","state_id":"2309"},
    {"city_id":"26953","city_name":"Kadok","state_id":"2309"},
    {"city_id":"26954","city_name":"Kota Bahru","state_id":"2309"},
    {"city_id":"26955","city_name":"Kuala Krai","state_id":"2309"},
    {"city_id":"26956","city_name":"Pangkal Kalong","state_id":"2309"},
    {"city_id":"26957","city_name":"Pasir Mas","state_id":"2309"},
    {"city_id":"26958","city_name":"Peringat","state_id":"2309"},
    {"city_id":"26959","city_name":"Tanah Merah","state_id":"2309"},
    {"city_id":"26960","city_name":"Tumpat","state_id":"2309"},
    {"city_id":"26961","city_name":"Wakaf Baru","state_id":"2309"},
    {"city_id":"26962","city_name":"Labuan","state_id":"2311"},
    {"city_id":"26963","city_name":"Alor Gajah","state_id":"2312"},
    {"city_id":"26964","city_name":"Ayer Keroh","state_id":"2312"},
    {"city_id":"26965","city_name":"Ayer Molek","state_id":"2312"},
    {"city_id":"26966","city_name":"Batu Berendam","state_id":"2312"},
    {"city_id":"26967","city_name":"Bemban","state_id":"2312"},
    {"city_id":"26968","city_name":"Bukit Baru","state_id":"2312"},
    {"city_id":"26969","city_name":"Bukit Rambai","state_id":"2312"},
    {"city_id":"26970","city_name":"Klebang","state_id":"2312"},
    {"city_id":"26971","city_name":"Kuala Sungai Baru","state_id":"2312"},
    {"city_id":"26972","city_name":"Malacca","state_id":"2312"},
    {"city_id":"26973","city_name":"Masjid Tanah","state_id":"2312"},
    {"city_id":"26974","city_name":"Melaka","state_id":"2312"},
    {"city_id":"26975","city_name":"Pulau Sebang","state_id":"2312"},
    {"city_id":"26976","city_name":"Sungai Udang","state_id":"2312"},
    {"city_id":"26977","city_name":"Bandar Jengka Pusat","state_id":"2315"},
    {"city_id":"26978","city_name":"Bentong","state_id":"2315"},
    {"city_id":"26979","city_name":"Bukit Tinggi","state_id":"2315"},
    {"city_id":"26980","city_name":"Jerantut","state_id":"2315"},
    {"city_id":"26981","city_name":"Karak","state_id":"2315"},
    {"city_id":"26982","city_name":"Kuala Lipis","state_id":"2315"},
    {"city_id":"26983","city_name":"Kuantan","state_id":"2315"},
    {"city_id":"26984","city_name":"Mentakab","state_id":"2315"},
    {"city_id":"26985","city_name":"Pekan","state_id":"2315"},
    {"city_id":"26986","city_name":"Pulau Pinang","state_id":"2315"},
    {"city_id":"26987","city_name":"Raub","state_id":"2315"},
    {"city_id":"26988","city_name":"Temerloh","state_id":"2315"},
    {"city_id":"26989","city_name":"Bayan Lepas","state_id":"2316"},
    {"city_id":"26990","city_name":"Church Town","state_id":"2316"},
    {"city_id":"26991","city_name":"George Town","state_id":"2316"},
    {"city_id":"26992","city_name":"Ayer Tawar","state_id":"2317"},
    {"city_id":"26993","city_name":"Bagan Serai","state_id":"2317"},
    {"city_id":"26994","city_name":"Batu Gajah","state_id":"2317"},
    {"city_id":"26995","city_name":"Bidor","state_id":"2317"},
    {"city_id":"26996","city_name":"Ipoh","state_id":"2317"},
    {"city_id":"26997","city_name":"Jelapang","state_id":"2317"},
    {"city_id":"26998","city_name":"Kampar","state_id":"2317"},
    {"city_id":"26999","city_name":"Kampong Koh","state_id":"2317"},
    {"city_id":"27000","city_name":"Kuala Kangsar","state_id":"2317"},
    {"city_id":"27001","city_name":"Lawan Kuda Baharu","state_id":"2317"},
    {"city_id":"27002","city_name":"Lumut","state_id":"2317"},
    {"city_id":"27003","city_name":"Pantai Remis","state_id":"2317"},
    {"city_id":"27004","city_name":"Parit Buntar","state_id":"2317"},
    {"city_id":"27005","city_name":"Perak","state_id":"2317"},
    {"city_id":"27006","city_name":"Pusing","state_id":"2317"},
    {"city_id":"27007","city_name":"Simpang Empat","state_id":"2317"},
    {"city_id":"27008","city_name":"Sungai Siput Utara","state_id":"2317"},
    {"city_id":"27009","city_name":"Taiping","state_id":"2317"},
    {"city_id":"27010","city_name":"Tanjong Malim","state_id":"2317"},
    {"city_id":"27011","city_name":"Tapah","state_id":"2317"},
    {"city_id":"27012","city_name":"Teluk Intan","state_id":"2317"},
    {"city_id":"27013","city_name":"Kangar","state_id":"2318"},
    {"city_id":"27014","city_name":"Kuala Perlis","state_id":"2318"},
    {"city_id":"27015","city_name":"Beaufort","state_id":"2320"},
    {"city_id":"27016","city_name":"Donggongon","state_id":"2320"},
    {"city_id":"27017","city_name":"Keningau","state_id":"2320"},
    {"city_id":"27018","city_name":"Kinarut","state_id":"2320"},
    {"city_id":"27019","city_name":"Kota Belud","state_id":"2320"},
    {"city_id":"27020","city_name":"Kota Kinabalu","state_id":"2320"},
    {"city_id":"27021","city_name":"Kudat","state_id":"2320"},
    {"city_id":"27022","city_name":"Kunak","state_id":"2320"},
    {"city_id":"27023","city_name":"Lahad Datu","state_id":"2320"},
    {"city_id":"27024","city_name":"Papar","state_id":"2320"},
    {"city_id":"27025","city_name":"Putatan","state_id":"2320"},
    {"city_id":"27026","city_name":"Ranau","state_id":"2320"},
    {"city_id":"27027","city_name":"Sandakan","state_id":"2320"},
    {"city_id":"27028","city_name":"Semporna","state_id":"2320"},
    {"city_id":"27029","city_name":"Tawau","state_id":"2320"},
    {"city_id":"27030","city_name":"Batu Delapan Bazaar","state_id":"2321"},
    {"city_id":"27031","city_name":"Bintulu","state_id":"2321"},
    {"city_id":"27032","city_name":"Kapit","state_id":"2321"},
    {"city_id":"27033","city_name":"Kota Samarahan","state_id":"2321"},
    {"city_id":"27034","city_name":"Kuching","state_id":"2321"},
    {"city_id":"27035","city_name":"Limbang","state_id":"2321"},
    {"city_id":"27036","city_name":"Miri","state_id":"2321"},
    {"city_id":"27037","city_name":"Sarawak","state_id":"2321"},
    {"city_id":"27038","city_name":"Sarikel","state_id":"2321"},
    {"city_id":"27039","city_name":"Sibu","state_id":"2321"},
    {"city_id":"27040","city_name":"Sri Aman","state_id":"2321"},
    {"city_id":"27041","city_name":"Ampang","state_id":"2322"},
    {"city_id":"27042","city_name":"Ampang Jaya","state_id":"2322"},
    {"city_id":"27043","city_name":"Balakong","state_id":"2322"},
    {"city_id":"27044","city_name":"Bandar Baru Bangi","state_id":"2322"},
    {"city_id":"27045","city_name":"Bandar Baru Salak Tinggi","state_id":"2322"},
    {"city_id":"27046","city_name":"Bander Baro Bangi","state_id":"2322"},
    {"city_id":"27047","city_name":"Banting","state_id":"2322"},
    {"city_id":"27048","city_name":"Batang Berjuntai","state_id":"2322"},
    {"city_id":"27049","city_name":"Batu Arang","state_id":"2322"},
    {"city_id":"27050","city_name":"Batu Sembilan Cheras","state_id":"2322"},
    {"city_id":"27051","city_name":"Beranang","state_id":"2322"},
    {"city_id":"27052","city_name":"Bukit Beruntung","state_id":"2322"},
    {"city_id":"27053","city_name":"Cyberjaya","state_id":"2322"},
    {"city_id":"27054","city_name":"Darul Ehsan","state_id":"2322"},
    {"city_id":"27055","city_name":"Dungun","state_id":"2322"},
    {"city_id":"27056","city_name":"Gombak Setia","state_id":"2322"},
    {"city_id":"27057","city_name":"Jenjarom","state_id":"2322"},
    {"city_id":"27058","city_name":"Kajang","state_id":"2322"},
    {"city_id":"27059","city_name":"Kajang-Sungai Chua","state_id":"2322"},
    {"city_id":"27060","city_name":"Klang","state_id":"2322"},
    {"city_id":"27061","city_name":"Kuala Kubu Baru","state_id":"2322"},
    {"city_id":"27062","city_name":"Kuala Selangor","state_id":"2322"},
    {"city_id":"27063","city_name":"Kuang","state_id":"2322"},
    {"city_id":"27064","city_name":"Pengkalan Kundang","state_id":"2322"},
    {"city_id":"27065","city_name":"Petaling Jaya","state_id":"2322"},
    {"city_id":"27066","city_name":"Port Klang","state_id":"2322"},
    {"city_id":"27067","city_name":"Puchong","state_id":"2322"},
    {"city_id":"27068","city_name":"Rawang","state_id":"2322"},
    {"city_id":"27069","city_name":"Sabak","state_id":"2322"},
    {"city_id":"27070","city_name":"Sekinchan","state_id":"2322"},
    {"city_id":"27071","city_name":"Selayang Baru","state_id":"2322"},
    {"city_id":"27072","city_name":"Semenyih","state_id":"2322"},
    {"city_id":"27073","city_name":"Serendah","state_id":"2322"},
    {"city_id":"27074","city_name":"Seri Kembangan","state_id":"2322"},
    {"city_id":"27075","city_name":"Shah Alam","state_id":"2322"},
    {"city_id":"27076","city_name":"Subang Jaya","state_id":"2322"},
    {"city_id":"27077","city_name":"Sungai Besar","state_id":"2322"},
    {"city_id":"27078","city_name":"Sungai Buloh","state_id":"2322"},
    {"city_id":"27079","city_name":"Sungai Pelek","state_id":"2322"},
    {"city_id":"27080","city_name":"Taman Greenwood","state_id":"2322"},
    {"city_id":"27081","city_name":"Tanjong Karang","state_id":"2322"},
    {"city_id":"27082","city_name":"Tanjong Sepat","state_id":"2322"},
    {"city_id":"27083","city_name":"Bahaiyah","state_id":"2323"},
    {"city_id":"27084","city_name":"Langkap","state_id":"2323"},
    {"city_id":"27085","city_name":"Cukai","state_id":"2324"},
    {"city_id":"27086","city_name":"Jertih","state_id":"2324"},
    {"city_id":"27087","city_name":"Kemaman","state_id":"2324"},
    {"city_id":"27088","city_name":"Kerteh","state_id":"2324"},
    {"city_id":"27089","city_name":"Kuala Terengganu","state_id":"2324"},
    {"city_id":"27090","city_name":"Marang","state_id":"2324"},
    {"city_id":"27091","city_name":"Paka","state_id":"2324"},
    {"city_id":"27092","city_name":"Serdang","state_id":"2324"},
    {"city_id":"27093","city_name":"Dharavandhoo","state_id":"2327"},
    {"city_id":"27094","city_name":"Dhonfanu","state_id":"2327"},
    {"city_id":"27095","city_name":"Eydhafushi","state_id":"2327"},
    {"city_id":"27096","city_name":"Fehendhoo","state_id":"2327"},
    {"city_id":"27097","city_name":"Fulhadhoo","state_id":"2327"},
    {"city_id":"27098","city_name":"Goidhoo","state_id":"2327"},
    {"city_id":"27099","city_name":"Hithaadhoo","state_id":"2327"},
    {"city_id":"27100","city_name":"Kamadhoo","state_id":"2327"},
    {"city_id":"27101","city_name":"Kendhoo","state_id":"2327"},
    {"city_id":"27102","city_name":"Kihaadhoo","state_id":"2327"},
    {"city_id":"27103","city_name":"Kudarikilu","state_id":"2327"},
    {"city_id":"27104","city_name":"Maalhos","state_id":"2327"},
    {"city_id":"27105","city_name":"Thulhaadhoo","state_id":"2327"},
    {"city_id":"27106","city_name":"Badidhoo","state_id":"2328"},
    {"city_id":"27107","city_name":"Gemendhoo","state_id":"2328"},
    {"city_id":"27108","city_name":"Hulhudheli","state_id":"2328"},
    {"city_id":"27109","city_name":"Kudahuvadhoo","state_id":"2328"},
    {"city_id":"27110","city_name":"Maaeboodhoo","state_id":"2328"},
    {"city_id":"27111","city_name":"Meedhoo","state_id":"2328"},
    {"city_id":"27112","city_name":"Ribudhoo","state_id":"2328"},
    {"city_id":"27113","city_name":"Vaanee","state_id":"2328"},
    {"city_id":"27114","city_name":"Biledhdhoo","state_id":"2329"},
    {"city_id":"27115","city_name":"Dharaboodhoo","state_id":"2329"},
    {"city_id":"27116","city_name":"Feeali","state_id":"2329"},
    {"city_id":"27117","city_name":"Magoodhoo","state_id":"2329"},
    {"city_id":"27118","city_name":"Nilandhoo","state_id":"2329"},
    {"city_id":"27119","city_name":"Fuvammulah","state_id":"2332"},
    {"city_id":"27120","city_name":"Dhiffushi","state_id":"2335"},
    {"city_id":"27121","city_name":"Gaafaru","state_id":"2335"},
    {"city_id":"27122","city_name":"Gulhi","state_id":"2335"},
    {"city_id":"27123","city_name":"Guraidhoo","state_id":"2335"},
    {"city_id":"27124","city_name":"Himmafushi","state_id":"2335"},
    {"city_id":"27125","city_name":"Huraa","state_id":"2335"},
    {"city_id":"27126","city_name":"Kaashidhoo","state_id":"2335"},
    {"city_id":"27127","city_name":"Maafushi","state_id":"2335"},
    {"city_id":"27128","city_name":"Thulusdhoo","state_id":"2335"},
    {"city_id":"27129","city_name":"Dhabidhoo","state_id":"2336"},
    {"city_id":"27130","city_name":"Fonadhoo","state_id":"2336"},
    {"city_id":"27131","city_name":"Gaadhoo","state_id":"2336"},
    {"city_id":"27132","city_name":"Gamu","state_id":"2336"},
    {"city_id":"27133","city_name":"Hithadhoo","state_id":"2336"},
    {"city_id":"27134","city_name":"Isdhoo","state_id":"2336"},
    {"city_id":"27135","city_name":"Kalhaidhoo","state_id":"2336"},
    {"city_id":"27136","city_name":"Kunahandhoo","state_id":"2336"},
    {"city_id":"27137","city_name":"Maabaidhoo","state_id":"2336"},
    {"city_id":"27138","city_name":"Maamendhoo","state_id":"2336"},
    {"city_id":"27139","city_name":"Maavah","state_id":"2336"},
    {"city_id":"27140","city_name":"Mundhoo","state_id":"2336"},
    {"city_id":"27141","city_name":"Hinnavaru","state_id":"2337"},
    {"city_id":"27142","city_name":"Kurendhoo","state_id":"2337"},
    {"city_id":"27143","city_name":"Maafilaafushi","state_id":"2337"},
    {"city_id":"27144","city_name":"Naifaru","state_id":"2337"},
    {"city_id":"27145","city_name":"Olhuvelifushi","state_id":"2337"},
    {"city_id":"27146","city_name":"Dhiggaru","state_id":"2339"},
    {"city_id":"27147","city_name":"Kolhufushi","state_id":"2339"},
    {"city_id":"27148","city_name":"Madifushi","state_id":"2339"},
    {"city_id":"27149","city_name":"Maduvvari","state_id":"2339"},
    {"city_id":"27150","city_name":"Mulah","state_id":"2339"},
    {"city_id":"27151","city_name":"Muli","state_id":"2339"},
    {"city_id":"27152","city_name":"Naalaafushi","state_id":"2339"},
    {"city_id":"27153","city_name":"Raimandhoo","state_id":"2339"},
    {"city_id":"27154","city_name":"Veyvah","state_id":"2339"},
    {"city_id":"27155","city_name":"Fodhdhoo","state_id":"2340"},
    {"city_id":"27156","city_name":"Hebadhoo","state_id":"2340"},
    {"city_id":"27157","city_name":"Holhudhoo","state_id":"2340"},
    {"city_id":"27158","city_name":"Kedhikolhudhoo","state_id":"2340"},
    {"city_id":"27159","city_name":"Kudafari","state_id":"2340"},
    {"city_id":"27160","city_name":"Landhoo","state_id":"2340"},
    {"city_id":"27161","city_name":"Lhohi","state_id":"2340"},
    {"city_id":"27162","city_name":"Maafaru","state_id":"2340"},
    {"city_id":"27163","city_name":"Maalhendhoo","state_id":"2340"},
    {"city_id":"27164","city_name":"Magoodhoo","state_id":"2340"},
    {"city_id":"27165","city_name":"Manadhoo","state_id":"2340"},
    {"city_id":"27166","city_name":"Miladhoo","state_id":"2340"},
    {"city_id":"27167","city_name":"Velidhoo","state_id":"2340"},
    {"city_id":"27168","city_name":"Agolhitheemu","state_id":"2341"},
    {"city_id":"27169","city_name":"Alifushi","state_id":"2341"},
    {"city_id":"27170","city_name":"Fainu","state_id":"2341"},
    {"city_id":"27171","city_name":"Hulhudhuffaaru","state_id":"2341"},
    {"city_id":"27172","city_name":"Iguraidhoo","state_id":"2341"},
    {"city_id":"27173","city_name":"Innamaadhoo","state_id":"2341"},
    {"city_id":"27174","city_name":"Kadholhudhoo","state_id":"2341"},
    {"city_id":"27175","city_name":"Kinolhas","state_id":"2341"},
    {"city_id":"27176","city_name":"Maakurathu","state_id":"2341"},
    {"city_id":"27177","city_name":"Maduvvari","state_id":"2341"},
    {"city_id":"27178","city_name":"Meedhoo","state_id":"2341"},
    {"city_id":"27179","city_name":"Rasgetheemu","state_id":"2341"},
    {"city_id":"27180","city_name":"Rasmaadhoo","state_id":"2341"},
    {"city_id":"27181","city_name":"Ugoofaaru","state_id":"2341"},
    {"city_id":"27182","city_name":"Vaadhoo","state_id":"2341"},
    {"city_id":"27183","city_name":"Bilehffahi","state_id":"2342"},
    {"city_id":"27184","city_name":"Feevah","state_id":"2342"},
    {"city_id":"27185","city_name":"Feydhoo","state_id":"2342"},
    {"city_id":"27186","city_name":"Firubaidhoo","state_id":"2342"},
    {"city_id":"27187","city_name":"Foakaidhoo","state_id":"2342"},
    {"city_id":"27188","city_name":"Funadhoo","state_id":"2342"},
    {"city_id":"27189","city_name":"Goidhoo","state_id":"2342"},
    {"city_id":"27190","city_name":"Kaditheemu","state_id":"2342"},
    {"city_id":"27191","city_name":"Komandoo","state_id":"2342"},
    {"city_id":"27192","city_name":"Lhaimagu","state_id":"2342"},
    {"city_id":"27193","city_name":"Maakandoodhoo","state_id":"2342"},
    {"city_id":"27194","city_name":"Maaugoodhoo","state_id":"2342"},
    {"city_id":"27195","city_name":"Maroshi","state_id":"2342"},
    {"city_id":"27196","city_name":"Narudhoo","state_id":"2342"},
    {"city_id":"27197","city_name":"Noomaraa","state_id":"2342"},
    {"city_id":"27198","city_name":"Feydhoo","state_id":"2343"},
    {"city_id":"27199","city_name":"Hithadhoo","state_id":"2343"},
    {"city_id":"27200","city_name":"Hulhudhoo","state_id":"2343"},
    {"city_id":"27201","city_name":"Maradhoo","state_id":"2343"},
    {"city_id":"27202","city_name":"Maradhoo-Feydhoo","state_id":"2343"},
    {"city_id":"27203","city_name":"Meedhoo","state_id":"2343"},
    {"city_id":"27204","city_name":"Buruni","state_id":"2344"},
    {"city_id":"27205","city_name":"Dhiyamigili","state_id":"2344"},
    {"city_id":"27206","city_name":"Gaadhiffushi","state_id":"2344"},
    {"city_id":"27207","city_name":"Guraidhoo","state_id":"2344"},
    {"city_id":"27208","city_name":"Hirilandhoo","state_id":"2344"},
    {"city_id":"27209","city_name":"Kadoodhoo","state_id":"2344"},
    {"city_id":"27210","city_name":"Kibidhoo","state_id":"2344"},
    {"city_id":"27211","city_name":"Madifushi","state_id":"2344"},
    {"city_id":"27212","city_name":"Omadhoo","state_id":"2344"},
    {"city_id":"27213","city_name":"Thimarafushi","state_id":"2344"},
    {"city_id":"27214","city_name":"Vandhoo","state_id":"2344"},
    {"city_id":"27215","city_name":"Veymandhoo","state_id":"2344"},
    {"city_id":"27216","city_name":"Vilufushi","state_id":"2344"},
    {"city_id":"27217","city_name":"Felidhoo","state_id":"2345"},
    {"city_id":"27218","city_name":"Fulidhoo","state_id":"2345"},
    {"city_id":"27219","city_name":"Keyodhoo","state_id":"2345"},
    {"city_id":"27220","city_name":"Rakeedhoo","state_id":"2345"},
    {"city_id":"27221","city_name":"Thinadhoo","state_id":"2345"},
    {"city_id":"27222","city_name":"Bamako","state_id":"2346"},
    {"city_id":"27223","city_name":"Gao","state_id":"2347"},
    {"city_id":"27224","city_name":"Bafoulabe","state_id":"2348"},
    {"city_id":"27225","city_name":"Kayes","state_id":"2348"},
    {"city_id":"27226","city_name":"Kita","state_id":"2348"},
    {"city_id":"27227","city_name":"Nioro","state_id":"2348"},
    {"city_id":"27228","city_name":"Kidal","state_id":"2349"},
    {"city_id":"27229","city_name":"Tessalit","state_id":"2349"},
    {"city_id":"27230","city_name":"Banamba","state_id":"2350"},
    {"city_id":"27231","city_name":"Kangaba","state_id":"2350"},
    {"city_id":"27232","city_name":"Kati","state_id":"2350"},
    {"city_id":"27233","city_name":"Kolokani","state_id":"2350"},
    {"city_id":"27234","city_name":"Koulikoro","state_id":"2350"},
    {"city_id":"27235","city_name":"Nara","state_id":"2350"},
    {"city_id":"27236","city_name":"Bandiagara","state_id":"2351"},
    {"city_id":"27237","city_name":"Djenne","state_id":"2351"},
    {"city_id":"27238","city_name":"Douentza","state_id":"2351"},
    {"city_id":"27239","city_name":"Koro","state_id":"2351"},
    {"city_id":"27240","city_name":"Mopti","state_id":"2351"},
    {"city_id":"27241","city_name":"Tenenkou","state_id":"2351"},
    {"city_id":"27242","city_name":"Ke-Massina","state_id":"2352"},
    {"city_id":"27243","city_name":"Kimparana","state_id":"2352"},
    {"city_id":"27244","city_name":"Markala","state_id":"2352"},
    {"city_id":"27245","city_name":"Niono","state_id":"2352"},
    {"city_id":"27246","city_name":"San","state_id":"2352"},
    {"city_id":"27247","city_name":"Segou","state_id":"2352"},
    {"city_id":"27248","city_name":"Sokolo","state_id":"2352"},
    {"city_id":"27249","city_name":"Bougouni","state_id":"2353"},
    {"city_id":"27250","city_name":"Kolondieba","state_id":"2353"},
    {"city_id":"27251","city_name":"Koutiala","state_id":"2353"},
    {"city_id":"27252","city_name":"Sikasso","state_id":"2353"},
    {"city_id":"27253","city_name":"Yorosso","state_id":"2353"},
    {"city_id":"27254","city_name":"Araouane","state_id":"2354"},
    {"city_id":"27255","city_name":"Dire","state_id":"2354"},
    {"city_id":"27256","city_name":"Goundam","state_id":"2354"},
    {"city_id":"27257","city_name":"Niafunke","state_id":"2354"},
    {"city_id":"27258","city_name":"Taoudenni","state_id":"2354"},
    {"city_id":"27259","city_name":"Tombouctou","state_id":"2354"},
    {"city_id":"27260","city_name":"Gharghur","state_id":"2357"},
    {"city_id":"27261","city_name":"Mellieha","state_id":"2357"},
    {"city_id":"27262","city_name":"Mgarr","state_id":"2357"},
    {"city_id":"27263","city_name":"Mosta","state_id":"2357"},
    {"city_id":"27264","city_name":"Naxxar","state_id":"2357"},
    {"city_id":"27265","city_name":"San Pawl il-Bahar","state_id":"2357"},
    {"city_id":"27266","city_name":"L-Iklin","state_id":"2360"},
    {"city_id":"27267","city_name":"Attard","state_id":"2361"},
    {"city_id":"27268","city_name":"Balzan","state_id":"2361"},
    {"city_id":"27269","city_name":"Dingli","state_id":"2361"},
    {"city_id":"27270","city_name":"Iklin","state_id":"2361"},
    {"city_id":"27271","city_name":"Lija","state_id":"2361"},
    {"city_id":"27272","city_name":"Mdina","state_id":"2361"},
    {"city_id":"27273","city_name":"Rabat","state_id":"2361"},
    {"city_id":"27274","city_name":"Sighghiewi","state_id":"2361"},
    {"city_id":"27275","city_name":"Zebbug","state_id":"2361"},
    {"city_id":"27276","city_name":"Castletown","state_id":"2362"},
    {"city_id":"27277","city_name":"Douglas","state_id":"2363"},
    {"city_id":"27278","city_name":"Laxey","state_id":"2364"},
    {"city_id":"27279","city_name":"Onchan","state_id":"2365"},
    {"city_id":"27280","city_name":"Peel","state_id":"2366"},
    {"city_id":"27281","city_name":"Ramsey","state_id":"2369"},
    {"city_id":"27282","city_name":"Aerok","state_id":"2370"},
    {"city_id":"27283","city_name":"Beran","state_id":"2370"},
    {"city_id":"27284","city_name":"Bikeer","state_id":"2370"},
    {"city_id":"27285","city_name":"Buoj","state_id":"2370"},
    {"city_id":"27286","city_name":"Enewe","state_id":"2370"},
    {"city_id":"27287","city_name":"Jabwan","state_id":"2370"},
    {"city_id":"27288","city_name":"Jah","state_id":"2370"},
    {"city_id":"27289","city_name":"Jeh","state_id":"2370"},
    {"city_id":"27290","city_name":"Kattiej","state_id":"2370"},
    {"city_id":"27291","city_name":"Mejajok","state_id":"2370"},
    {"city_id":"27292","city_name":"Mejil","state_id":"2370"},
    {"city_id":"27293","city_name":"Tobomaro","state_id":"2370"},
    {"city_id":"27294","city_name":"Woja","state_id":"2370"},
    {"city_id":"27295","city_name":"Wolar","state_id":"2370"},
    {"city_id":"27296","city_name":"Ailuk","state_id":"2371"},
    {"city_id":"27297","city_name":"Ajelep","state_id":"2371"},
    {"city_id":"27298","city_name":"Aliej","state_id":"2371"},
    {"city_id":"27299","city_name":"Biken","state_id":"2371"},
    {"city_id":"27300","city_name":"Enejabrok","state_id":"2371"},
    {"city_id":"27301","city_name":"Enejelar","state_id":"2371"},
    {"city_id":"27302","city_name":"Kapen","state_id":"2371"},
    {"city_id":"27303","city_name":"Arno Arno","state_id":"2372"},
    {"city_id":"27304","city_name":"Bikarej","state_id":"2372"},
    {"city_id":"27305","city_name":"Bikonele","state_id":"2372"},
    {"city_id":"27306","city_name":"Boken","state_id":"2372"},
    {"city_id":"27307","city_name":"Eneaidrik","state_id":"2372"},
    {"city_id":"27308","city_name":"Enirik","state_id":"2372"},
    {"city_id":"27309","city_name":"Ijoen","state_id":"2372"},
    {"city_id":"27310","city_name":"Ine","state_id":"2372"},
    {"city_id":"27311","city_name":"Japo","state_id":"2372"},
    {"city_id":"27312","city_name":"Jilane","state_id":"2372"},
    {"city_id":"27313","city_name":"Kejbwe","state_id":"2372"},
    {"city_id":"27314","city_name":"Kilange","state_id":"2372"},
    {"city_id":"27315","city_name":"Kilemman","state_id":"2372"},
    {"city_id":"27316","city_name":"Kinajon","state_id":"2372"},
    {"city_id":"27317","city_name":"Langor","state_id":"2372"},
    {"city_id":"27318","city_name":"Lukoj","state_id":"2372"},
    {"city_id":"27319","city_name":"Malel","state_id":"2372"},
    {"city_id":"27320","city_name":"Manrar","state_id":"2372"},
    {"city_id":"27321","city_name":"Matolen","state_id":"2372"},
    {"city_id":"27322","city_name":"Meetdik","state_id":"2372"},
    {"city_id":"27323","city_name":"Mian","state_id":"2372"},
    {"city_id":"27324","city_name":"Najaj","state_id":"2372"},
    {"city_id":"27325","city_name":"Namwi","state_id":"2372"},
    {"city_id":"27326","city_name":"Neenkotkot","state_id":"2372"},
    {"city_id":"27327","city_name":"Taklep","state_id":"2372"},
    {"city_id":"27328","city_name":"Tinak","state_id":"2372"},
    {"city_id":"27329","city_name":"Tutu","state_id":"2372"},
    {"city_id":"27330","city_name":"Ulien","state_id":"2372"},
    {"city_id":"27331","city_name":"Aur","state_id":"2373"},
    {"city_id":"27332","city_name":"Tabal","state_id":"2373"},
    {"city_id":"27333","city_name":"Eneu","state_id":"2374"},
    {"city_id":"27334","city_name":"Emej","state_id":"2375"},
    {"city_id":"27335","city_name":"Enearmij","state_id":"2375"},
    {"city_id":"27336","city_name":"Enekoion","state_id":"2375"},
    {"city_id":"27337","city_name":"Enilok","state_id":"2375"},
    {"city_id":"27338","city_name":"Jittaken","state_id":"2375"},
    {"city_id":"27339","city_name":"Jittoen","state_id":"2375"},
    {"city_id":"27340","city_name":"Rerok","state_id":"2375"},
    {"city_id":"27341","city_name":"Toka","state_id":"2375"},
    {"city_id":"27342","city_name":"Enewetak","state_id":"2376"},
    {"city_id":"27343","city_name":"Japtan","state_id":"2376"},
    {"city_id":"27344","city_name":"Medren","state_id":"2376"},
    {"city_id":"27345","city_name":"Jabat","state_id":"2377"},
    {"city_id":"27346","city_name":"Ae","state_id":"2378"},
    {"city_id":"27347","city_name":"Ajejen","state_id":"2378"},
    {"city_id":"27348","city_name":"Anilep","state_id":"2378"},
    {"city_id":"27349","city_name":"Arreen","state_id":"2378"},
    {"city_id":"27350","city_name":"Bokanake","state_id":"2378"},
    {"city_id":"27351","city_name":"Bokkan","state_id":"2378"},
    {"city_id":"27352","city_name":"Bukantorak","state_id":"2378"},
    {"city_id":"27353","city_name":"Dede","state_id":"2378"},
    {"city_id":"27354","city_name":"Ewo","state_id":"2378"},
    {"city_id":"27355","city_name":"Imiej","state_id":"2378"},
    {"city_id":"27356","city_name":"Imroj","state_id":"2378"},
    {"city_id":"27357","city_name":"Jabnoren","state_id":"2378"},
    {"city_id":"27358","city_name":"Jabwor","state_id":"2378"},
    {"city_id":"27359","city_name":"Jaluit","state_id":"2378"},
    {"city_id":"27360","city_name":"Lonone","state_id":"2378"},
    {"city_id":"27361","city_name":"Mejatto","state_id":"2378"},
    {"city_id":"27362","city_name":"Mejrirok","state_id":"2378"},
    {"city_id":"27363","city_name":"Menge","state_id":"2378"},
    {"city_id":"27364","city_name":"Nabbe","state_id":"2378"},
    {"city_id":"27365","city_name":"Narmej","state_id":"2378"},
    {"city_id":"27366","city_name":"Urbaj","state_id":"2378"},
    {"city_id":"27367","city_name":"Kili","state_id":"2379"},
    {"city_id":"27368","city_name":"Arbwa","state_id":"2380"},
    {"city_id":"27369","city_name":"Ebadon","state_id":"2380"},
    {"city_id":"27370","city_name":"Ebeye","state_id":"2380"},
    {"city_id":"27371","city_name":"Ebjadik","state_id":"2380"},
    {"city_id":"27372","city_name":"Ebwaj","state_id":"2380"},
    {"city_id":"27373","city_name":"Ennilabegan","state_id":"2380"},
    {"city_id":"27374","city_name":"Enubirr","state_id":"2380"},
    {"city_id":"27375","city_name":"Enubuj","state_id":"2380"},
    {"city_id":"27376","city_name":"Gugeegue","state_id":"2380"},
    {"city_id":"27377","city_name":"Mejatto","state_id":"2380"},
    {"city_id":"27378","city_name":"Ningi","state_id":"2380"},
    {"city_id":"27379","city_name":"North Loi","state_id":"2380"},
    {"city_id":"27380","city_name":"Orpap","state_id":"2380"},
    {"city_id":"27381","city_name":"South Loi","state_id":"2380"},
    {"city_id":"27382","city_name":"Enerein","state_id":"2381"},
    {"city_id":"27383","city_name":"Lae","state_id":"2381"},
    {"city_id":"27384","city_name":"Lejab","state_id":"2381"},
    {"city_id":"27385","city_name":"Lib","state_id":"2382"},
    {"city_id":"27386","city_name":"Jebal","state_id":"2383"},
    {"city_id":"27387","city_name":"Likiep","state_id":"2383"},
    {"city_id":"27388","city_name":"Liklal","state_id":"2383"},
    {"city_id":"27389","city_name":"Melang","state_id":"2383"},
    {"city_id":"27390","city_name":"Ajeltake","state_id":"2384"},
    {"city_id":"27391","city_name":"Aneenwudej","state_id":"2384"},
    {"city_id":"27392","city_name":"Aneko","state_id":"2384"},
    {"city_id":"27393","city_name":"Arrak","state_id":"2384"},
    {"city_id":"27394","city_name":"Biken","state_id":"2384"},
    {"city_id":"27395","city_name":"Bokaetoktok","state_id":"2384"},
    {"city_id":"27396","city_name":"Denmeo","state_id":"2384"},
    {"city_id":"27397","city_name":"Didej","state_id":"2384"},
    {"city_id":"27398","city_name":"Ejit","state_id":"2384"},
    {"city_id":"27399","city_name":"Enemanet","state_id":"2384"},
    {"city_id":"27400","city_name":"Jelter","state_id":"2384"},
    {"city_id":"27401","city_name":"Kalalen","state_id":"2384"},
    {"city_id":"27402","city_name":"Kemman","state_id":"2384"},
    {"city_id":"27403","city_name":"Laura","state_id":"2384"},
    {"city_id":"27404","city_name":"Pikiriin","state_id":"2384"},
    {"city_id":"27405","city_name":"Rita","state_id":"2384"},
    {"city_id":"27406","city_name":"Rongrong","state_id":"2384"},
    {"city_id":"27407","city_name":"Woja","state_id":"2384"},
    {"city_id":"27408","city_name":"Airok","state_id":"2385"},
    {"city_id":"27409","city_name":"Jang","state_id":"2385"},
    {"city_id":"27410","city_name":"Kaben","state_id":"2385"},
    {"city_id":"27411","city_name":"Tarawa","state_id":"2385"},
    {"city_id":"27412","city_name":"Wolot","state_id":"2385"},
    {"city_id":"27413","city_name":"Mejit","state_id":"2386"},
    {"city_id":"27414","city_name":"Aeloneo","state_id":"2387"},
    {"city_id":"27415","city_name":"Anel","state_id":"2387"},
    {"city_id":"27416","city_name":"Arbar","state_id":"2387"},
    {"city_id":"27417","city_name":"Bar","state_id":"2387"},
    {"city_id":"27418","city_name":"Bikonel","state_id":"2387"},
    {"city_id":"27419","city_name":"Enajet","state_id":"2387"},
    {"city_id":"27420","city_name":"Eoo","state_id":"2387"},
    {"city_id":"27421","city_name":"Jabonwod","state_id":"2387"},
    {"city_id":"27422","city_name":"Loen","state_id":"2387"},
    {"city_id":"27423","city_name":"Lukoj","state_id":"2387"},
    {"city_id":"27424","city_name":"Lukonwor","state_id":"2387"},
    {"city_id":"27425","city_name":"Mae","state_id":"2387"},
    {"city_id":"27426","city_name":"Majkin","state_id":"2387"},
    {"city_id":"27427","city_name":"Mili","state_id":"2387"},
    {"city_id":"27428","city_name":"Nad","state_id":"2387"},
    {"city_id":"27429","city_name":"Nallu","state_id":"2387"},
    {"city_id":"27430","city_name":"Tokewa","state_id":"2387"},
    {"city_id":"27431","city_name":"Wau","state_id":"2387"},
    {"city_id":"27432","city_name":"Namorik","state_id":"2388"},
    {"city_id":"27433","city_name":"Namu","state_id":"2389"},
    {"city_id":"27434","city_name":"Rongelap","state_id":"2390"},
    {"city_id":"27435","city_name":"Ujae","state_id":"2391"},
    {"city_id":"27436","city_name":"Utirik","state_id":"2392"},
    {"city_id":"27437","city_name":"Wotho","state_id":"2393"},
    {"city_id":"27438","city_name":"Didi","state_id":"2394"},
    {"city_id":"27439","city_name":"Nibun","state_id":"2394"},
    {"city_id":"27440","city_name":"Wormej","state_id":"2394"},
    {"city_id":"27441","city_name":"Wotje","state_id":"2394"},
    {"city_id":"27442","city_name":"Fort-de-France","state_id":"2395"},
    {"city_id":"27443","city_name":"Le Lamentin","state_id":"2395"},
    {"city_id":"27444","city_name":"Saint-Joseph","state_id":"2395"},
    {"city_id":"27445","city_name":"Schoelcher","state_id":"2395"},
    {"city_id":"27446","city_name":"Le Morne-Rouge","state_id":"2398"},
    {"city_id":"27447","city_name":"Saint-Pierre","state_id":"2398"},
    {"city_id":"27448","city_name":"Atar","state_id":"2399"},
    {"city_id":"27449","city_name":"Shingati","state_id":"2399"},
    {"city_id":"27450","city_name":"Kifah","state_id":"2400"},
    {"city_id":"27451","city_name":"Alaq","state_id":"2401"},
    {"city_id":"27452","city_name":"Buqah","state_id":"2401"},
    {"city_id":"27453","city_name":"Magta' Lahjar","state_id":"2401"},
    {"city_id":"27454","city_name":"'Ayun-al-'Atrus","state_id":"2403"},
    {"city_id":"27455","city_name":"Kubanni","state_id":"2403"},
    {"city_id":"27456","city_name":"Timbedra","state_id":"2404"},
    {"city_id":"27457","city_name":"Walatah","state_id":"2404"},
    {"city_id":"27458","city_name":"an-Na'mah","state_id":"2404"},
    {"city_id":"27459","city_name":"Aqjawajat","state_id":"2405"},
    {"city_id":"27460","city_name":"Nawakshut","state_id":"2406"},
    {"city_id":"27461","city_name":"Hsay Walad 'Ali Babi","state_id":"2407"},
    {"city_id":"27462","city_name":"Kayhaydi","state_id":"2408"},
    {"city_id":"27463","city_name":"Tijiqjah","state_id":"2409"},
    {"city_id":"27464","city_name":"Rusu","state_id":"2411"},
    {"city_id":"27465","city_name":"Bel Air","state_id":"2414"},
    {"city_id":"27466","city_name":"Bon Accueil","state_id":"2414"},
    {"city_id":"27467","city_name":"Brisee Verdiere","state_id":"2414"},
    {"city_id":"27468","city_name":"Camp Ithier","state_id":"2414"},
    {"city_id":"27469","city_name":"Camp de Masque","state_id":"2414"},
    {"city_id":"27470","city_name":"Camp de Masque Pave","state_id":"2414"},
    {"city_id":"27471","city_name":"Central Flacq","state_id":"2414"},
    {"city_id":"27472","city_name":"Clemencia","state_id":"2414"},
    {"city_id":"27473","city_name":"Ecroignard","state_id":"2414"},
    {"city_id":"27474","city_name":"Grand River South East","state_id":"2414"},
    {"city_id":"27475","city_name":"Lalmatie","state_id":"2414"},
    {"city_id":"27476","city_name":"Laventure","state_id":"2414"},
    {"city_id":"27477","city_name":"Mare La Chaux","state_id":"2414"},
    {"city_id":"27478","city_name":"Medine-Camp de Masque","state_id":"2414"},
    {"city_id":"27479","city_name":"Olivia","state_id":"2414"},
    {"city_id":"27480","city_name":"Poste de Flacq","state_id":"2414"},
    {"city_id":"27481","city_name":"Quatre Cocos","state_id":"2414"},
    {"city_id":"27482","city_name":"Quatre Soeurs","state_id":"2414"},
    {"city_id":"27483","city_name":"Queen Victoria","state_id":"2414"},
    {"city_id":"27484","city_name":"Saint Julien","state_id":"2414"},
    {"city_id":"27485","city_name":"Saint Julien d'Hotman","state_id":"2414"},
    {"city_id":"27486","city_name":"Sebastopol","state_id":"2414"},
    {"city_id":"27487","city_name":"Trou d'Eau Douce","state_id":"2414"},
    {"city_id":"27488","city_name":"Floreal","state_id":"2415"},
    {"city_id":"27489","city_name":"Camp Thorel","state_id":"2417"},
    {"city_id":"27490","city_name":"Dagotiere","state_id":"2417"},
    {"city_id":"27491","city_name":"Dubreuil","state_id":"2417"},
    {"city_id":"27492","city_name":"Esperance","state_id":"2417"},
    {"city_id":"27493","city_name":"L'Avenir","state_id":"2417"},
    {"city_id":"27494","city_name":"La Laura-Malenga","state_id":"2417"},
    {"city_id":"27495","city_name":"Melrose","state_id":"2417"},
    {"city_id":"27496","city_name":"Moka","state_id":"2417"},
    {"city_id":"27497","city_name":"Montagne Blanche","state_id":"2417"},
    {"city_id":"27498","city_name":"Pailles","state_id":"2417"},
    {"city_id":"27499","city_name":"Providence","state_id":"2417"},
    {"city_id":"27500","city_name":"Quartier Militaire","state_id":"2417"},
    {"city_id":"27501","city_name":"Ripailles","state_id":"2417"},
    {"city_id":"27502","city_name":"Saint Pierre","state_id":"2417"},
    {"city_id":"27503","city_name":"Verdun","state_id":"2417"},
    {"city_id":"27504","city_name":"Arsenal","state_id":"2418"},
    {"city_id":"27505","city_name":"Baie du Tombeau","state_id":"2418"},
    {"city_id":"27506","city_name":"Calebasses","state_id":"2418"},
    {"city_id":"27507","city_name":"Congomah","state_id":"2418"},
    {"city_id":"27508","city_name":"Creve Coeur","state_id":"2418"},
    {"city_id":"27509","city_name":"D'Epinay","state_id":"2418"},
    {"city_id":"27510","city_name":"Fond du Sac","state_id":"2418"},
    {"city_id":"27511","city_name":"La Tour Koenig","state_id":"2418"},
    {"city_id":"27512","city_name":"Le Hochet","state_id":"2418"},
    {"city_id":"27513","city_name":"Long Mountain","state_id":"2418"},
    {"city_id":"27514","city_name":"Morcellement Saint Andre","state_id":"2418"},
    {"city_id":"27515","city_name":"Notre Dame","state_id":"2418"},
    {"city_id":"27516","city_name":"Pamplemousse","state_id":"2418"},
    {"city_id":"27517","city_name":"Plaines des Papayes","state_id":"2418"},
    {"city_id":"27518","city_name":"Pointe aux Piments","state_id":"2418"},
    {"city_id":"27519","city_name":"Terre Rouge","state_id":"2418"},
    {"city_id":"27520","city_name":"Triolet","state_id":"2418"},
    {"city_id":"27521","city_name":"Villebague","state_id":"2418"},
    {"city_id":"27522","city_name":"Baie Malgache","state_id":"2422"},
    {"city_id":"27523","city_name":"Coromandel-Graviers","state_id":"2422"},
    {"city_id":"27524","city_name":"Grand Baie-Montagne Goyaves","state_id":"2422"},
    {"city_id":"27525","city_name":"La Ferme","state_id":"2422"},
    {"city_id":"27526","city_name":"Lataniers-Mont Lubin","state_id":"2422"},
    {"city_id":"27527","city_name":"Mangues-Quatre Vents","state_id":"2422"},
    {"city_id":"27528","city_name":"Oyster Bay","state_id":"2422"},
    {"city_id":"27529","city_name":"Petit Gabriel","state_id":"2422"},
    {"city_id":"27530","city_name":"Piments-Baie Topaze","state_id":"2422"},
    {"city_id":"27531","city_name":"Plaine Corail-La Fouche Corail","state_id":"2422"},
    {"city_id":"27532","city_name":"Port Mathurin","state_id":"2422"},
    {"city_id":"27533","city_name":"Port Sud-Est","state_id":"2422"},
    {"city_id":"27534","city_name":"Riviere Cocos","state_id":"2422"},
    {"city_id":"27535","city_name":"Roche Bon Dieu-Trefles","state_id":"2422"},
    {"city_id":"27536","city_name":"Baie du Cap","state_id":"2424"},
    {"city_id":"27537","city_name":"Bel Ombre","state_id":"2424"},
    {"city_id":"27538","city_name":"Benares","state_id":"2424"},
    {"city_id":"27539","city_name":"Bois Cheri","state_id":"2424"},
    {"city_id":"27540","city_name":"Britannia","state_id":"2424"},
    {"city_id":"27541","city_name":"Camp Diable","state_id":"2424"},
    {"city_id":"27542","city_name":"Chamouny","state_id":"2424"},
    {"city_id":"27543","city_name":"Chemin Grenier","state_id":"2424"},
    {"city_id":"27544","city_name":"Grand Bois","state_id":"2424"},
    {"city_id":"27545","city_name":"Riviere des Anguilles","state_id":"2424"},
    {"city_id":"27546","city_name":"Saint Aubin","state_id":"2424"},
    {"city_id":"27547","city_name":"Souillac","state_id":"2424"},
    {"city_id":"27548","city_name":"Surinam","state_id":"2424"},
    {"city_id":"27549","city_name":"Acoua","state_id":"2425"},
    {"city_id":"27550","city_name":"Bandraboua","state_id":"2425"},
    {"city_id":"27551","city_name":"Bandrele","state_id":"2425"},
    {"city_id":"27552","city_name":"Boueni","state_id":"2425"},
    {"city_id":"27553","city_name":"Chiconi","state_id":"2425"},
    {"city_id":"27554","city_name":"Chirongui","state_id":"2425"},
    {"city_id":"27555","city_name":"Dembeni","state_id":"2425"},
    {"city_id":"27556","city_name":"Kanikeli","state_id":"2425"},
    {"city_id":"27557","city_name":"Koungou","state_id":"2425"},
    {"city_id":"27558","city_name":"Mamoudzou","state_id":"2425"},
    {"city_id":"27559","city_name":"Mtsamboro","state_id":"2425"},
    {"city_id":"27560","city_name":"Mtsangamouji","state_id":"2425"},
    {"city_id":"27561","city_name":"Ouangani","state_id":"2425"},
    {"city_id":"27562","city_name":"Sada","state_id":"2425"},
    {"city_id":"27563","city_name":"Tsingoni","state_id":"2425"},
    {"city_id":"27564","city_name":"Dzaoudzi","state_id":"2426"},
    {"city_id":"27565","city_name":"Pamanzi","state_id":"2426"},
    {"city_id":"27566","city_name":"Aguascalientes","state_id":"2427"},
    {"city_id":"27567","city_name":"Asientos","state_id":"2427"},
    {"city_id":"27568","city_name":"Calvillo","state_id":"2427"},
    {"city_id":"27569","city_name":"Cosio","state_id":"2427"},
    {"city_id":"27570","city_name":"Margaritas","state_id":"2427"},
    {"city_id":"27571","city_name":"Ojocaliente","state_id":"2427"},
    {"city_id":"27572","city_name":"Pabellon de Arteaga","state_id":"2427"},
    {"city_id":"27573","city_name":"Pabellon de Hidalgo","state_id":"2427"},
    {"city_id":"27574","city_name":"Palo Alto","state_id":"2427"},
    {"city_id":"27575","city_name":"Rincon de Romos","state_id":"2427"},
    {"city_id":"27576","city_name":"San Francisco de los Romo","state_id":"2427"},
    {"city_id":"27577","city_name":"San Jose de Gracia","state_id":"2427"},
    {"city_id":"27578","city_name":"Tepezala","state_id":"2427"},
    {"city_id":"27579","city_name":"Villa Juarez","state_id":"2427"},
    {"city_id":"27580","city_name":"Becal","state_id":"2430"},
    {"city_id":"27581","city_name":"Bolonchen","state_id":"2430"},
    {"city_id":"27582","city_name":"Calkini","state_id":"2430"},
    {"city_id":"27583","city_name":"Campeche","state_id":"2430"},
    {"city_id":"27584","city_name":"Candelaria","state_id":"2430"},
    {"city_id":"27585","city_name":"Carmen","state_id":"2430"},
    {"city_id":"27586","city_name":"Champoton","state_id":"2430"},
    {"city_id":"27587","city_name":"China","state_id":"2430"},
    {"city_id":"27588","city_name":"Dzitbalche","state_id":"2430"},
    {"city_id":"27589","city_name":"Escarcega","state_id":"2430"},
    {"city_id":"27590","city_name":"Hecelchakan","state_id":"2430"},
    {"city_id":"27591","city_name":"Hopelchen","state_id":"2430"},
    {"city_id":"27592","city_name":"Isla Aguada","state_id":"2430"},
    {"city_id":"27593","city_name":"Nuevo Progreso","state_id":"2430"},
    {"city_id":"27594","city_name":"Nunkini","state_id":"2430"},
    {"city_id":"27595","city_name":"Palizada","state_id":"2430"},
    {"city_id":"27596","city_name":"Pomuch","state_id":"2430"},
    {"city_id":"27597","city_name":"Sabancuy","state_id":"2430"},
    {"city_id":"27598","city_name":"Seybaplaya","state_id":"2430"},
    {"city_id":"27599","city_name":"Tenabo","state_id":"2430"},
    {"city_id":"27600","city_name":"Acacoyagua","state_id":"2431"},
    {"city_id":"27601","city_name":"Acala","state_id":"2431"},
    {"city_id":"27602","city_name":"Acapetahua","state_id":"2431"},
    {"city_id":"27603","city_name":"Altamirano","state_id":"2431"},
    {"city_id":"27604","city_name":"Alvaro Obregon","state_id":"2431"},
    {"city_id":"27605","city_name":"Amatan","state_id":"2431"},
    {"city_id":"27606","city_name":"Amatenango del Valle","state_id":"2431"},
    {"city_id":"27607","city_name":"Arriaga","state_id":"2431"},
    {"city_id":"27608","city_name":"Benemerito de las Americas","state_id":"2431"},
    {"city_id":"27609","city_name":"Berriozabal","state_id":"2431"},
    {"city_id":"27610","city_name":"Bochil","state_id":"2431"},
    {"city_id":"27611","city_name":"Cacahoatan","state_id":"2431"},
    {"city_id":"27612","city_name":"Cancuc","state_id":"2431"},
    {"city_id":"27613","city_name":"Catazaja","state_id":"2431"},
    {"city_id":"27614","city_name":"Chalchihiutan","state_id":"2431"},
    {"city_id":"27615","city_name":"Chanal","state_id":"2431"},
    {"city_id":"27616","city_name":"Chiapa","state_id":"2431"},
    {"city_id":"27617","city_name":"Chiapilla","state_id":"2431"},
    {"city_id":"27618","city_name":"Chicomuselo","state_id":"2431"},
    {"city_id":"27619","city_name":"Chilon","state_id":"2431"},
    {"city_id":"27620","city_name":"Cintalapa","state_id":"2431"},
    {"city_id":"27621","city_name":"Comaltitlan","state_id":"2431"},
    {"city_id":"27622","city_name":"Comitan","state_id":"2431"},
    {"city_id":"27623","city_name":"Copainala","state_id":"2431"},
    {"city_id":"27624","city_name":"Copoya","state_id":"2431"},
    {"city_id":"27625","city_name":"Cristobal Obregon","state_id":"2431"},
    {"city_id":"27626","city_name":"El Bosque","state_id":"2431"},
    {"city_id":"27627","city_name":"El Parral","state_id":"2431"},
    {"city_id":"27628","city_name":"Escuintla","state_id":"2431"},
    {"city_id":"27629","city_name":"Frontera Comalapa","state_id":"2431"},
    {"city_id":"27630","city_name":"Frontera Corozal","state_id":"2431"},
    {"city_id":"27631","city_name":"Frontera Hidalgo","state_id":"2431"},
    {"city_id":"27632","city_name":"Huehuetan","state_id":"2431"},
    {"city_id":"27633","city_name":"Huehuetan Estacion","state_id":"2431"},
    {"city_id":"27634","city_name":"Huixtla","state_id":"2431"},
    {"city_id":"27635","city_name":"Ixhuatan","state_id":"2431"},
    {"city_id":"27636","city_name":"Ixtacomitan","state_id":"2431"},
    {"city_id":"27637","city_name":"Ixtapa","state_id":"2431"},
    {"city_id":"27638","city_name":"Jaltenango","state_id":"2431"},
    {"city_id":"27639","city_name":"Jesus Maria Garza","state_id":"2431"},
    {"city_id":"27640","city_name":"Jiquipilas","state_id":"2431"},
    {"city_id":"27641","city_name":"Jitotol","state_id":"2431"},
    {"city_id":"27642","city_name":"Juarez","state_id":"2431"},
    {"city_id":"27643","city_name":"La Concordia","state_id":"2431"},
    {"city_id":"27644","city_name":"La Libertad","state_id":"2431"},
    {"city_id":"27645","city_name":"La Trinitaria","state_id":"2431"},
    {"city_id":"27646","city_name":"Las Margaritas","state_id":"2431"},
    {"city_id":"27647","city_name":"Las Rosas","state_id":"2431"},
    {"city_id":"27648","city_name":"Mapastepec","state_id":"2431"},
    {"city_id":"27649","city_name":"Mazatan","state_id":"2431"},
    {"city_id":"27650","city_name":"Motozintla","state_id":"2431"},
    {"city_id":"27651","city_name":"Navenchauc","state_id":"2431"},
    {"city_id":"27652","city_name":"Nicolas Ruiz","state_id":"2431"},
    {"city_id":"27653","city_name":"Ocosingo","state_id":"2431"},
    {"city_id":"27654","city_name":"Ocotepec","state_id":"2431"},
    {"city_id":"27655","city_name":"Ocozocoautla","state_id":"2431"},
    {"city_id":"27656","city_name":"Ostuacan","state_id":"2431"},
    {"city_id":"27657","city_name":"Oxchuc","state_id":"2431"},
    {"city_id":"27658","city_name":"Palenque","state_id":"2431"},
    {"city_id":"27659","city_name":"Palestina","state_id":"2431"},
    {"city_id":"27660","city_name":"Pantelho","state_id":"2431"},
    {"city_id":"27661","city_name":"Paredon","state_id":"2431"},
    {"city_id":"27662","city_name":"Petalcingo","state_id":"2431"},
    {"city_id":"27663","city_name":"Pichucalco","state_id":"2431"},
    {"city_id":"27664","city_name":"Pijijiapan","state_id":"2431"},
    {"city_id":"27665","city_name":"Pueblo Nuevo Solistahuacan","state_id":"2431"},
    {"city_id":"27666","city_name":"Puerto Madero","state_id":"2431"},
    {"city_id":"27667","city_name":"Pujiltic","state_id":"2431"},
    {"city_id":"27668","city_name":"Raudales Malpaso","state_id":"2431"},
    {"city_id":"27669","city_name":"Rayon","state_id":"2431"},
    {"city_id":"27670","city_name":"Reforma","state_id":"2431"},
    {"city_id":"27671","city_name":"Revolucion Mexicana","state_id":"2431"},
    {"city_id":"27672","city_name":"Rincon Chamula","state_id":"2431"},
    {"city_id":"27673","city_name":"Salto de Agua","state_id":"2431"},
    {"city_id":"27674","city_name":"San Cristobal de las Casas","state_id":"2431"},
    {"city_id":"27675","city_name":"San Fernando","state_id":"2431"},
    {"city_id":"27676","city_name":"San Lucas","state_id":"2431"},
    {"city_id":"27677","city_name":"San Pedro Buenavista","state_id":"2431"},
    {"city_id":"27678","city_name":"Simojovel","state_id":"2431"},
    {"city_id":"27679","city_name":"Socoltenango","state_id":"2431"},
    {"city_id":"27680","city_name":"Soyalo","state_id":"2431"},
    {"city_id":"27681","city_name":"Suchiapa","state_id":"2431"},
    {"city_id":"27682","city_name":"Suchiate","state_id":"2431"},
    {"city_id":"27683","city_name":"Tapachula","state_id":"2431"},
    {"city_id":"27684","city_name":"Tapilula","state_id":"2431"},
    {"city_id":"27685","city_name":"Tecpatan","state_id":"2431"},
    {"city_id":"27686","city_name":"Tenango","state_id":"2431"},
    {"city_id":"27687","city_name":"Teopisca","state_id":"2431"},
    {"city_id":"27688","city_name":"Tila","state_id":"2431"},
    {"city_id":"27689","city_name":"Tonala","state_id":"2431"},
    {"city_id":"27690","city_name":"Totolapa","state_id":"2431"},
    {"city_id":"27691","city_name":"Tres Picos","state_id":"2431"},
    {"city_id":"27692","city_name":"Tuxtla Chico","state_id":"2431"},
    {"city_id":"27693","city_name":"Tuxtla Gutierrez","state_id":"2431"},
    {"city_id":"27694","city_name":"Tzimol","state_id":"2431"},
    {"city_id":"27695","city_name":"Veinte de Noviembre","state_id":"2431"},
    {"city_id":"27696","city_name":"Venustiano Carranza","state_id":"2431"},
    {"city_id":"27697","city_name":"Villa Corzo","state_id":"2431"},
    {"city_id":"27698","city_name":"Villaflores","state_id":"2431"},
    {"city_id":"27699","city_name":"Yajalon","state_id":"2431"},
    {"city_id":"27700","city_name":"Zinacantan","state_id":"2431"},
    {"city_id":"27701","city_name":"Adolfo Lopez Mateos","state_id":"2432"},
    {"city_id":"27702","city_name":"Ahumada","state_id":"2432"},
    {"city_id":"27703","city_name":"Anahuac","state_id":"2432"},
    {"city_id":"27704","city_name":"Ascension","state_id":"2432"},
    {"city_id":"27705","city_name":"Benito Juarez","state_id":"2432"},
    {"city_id":"27706","city_name":"Camargo","state_id":"2432"},
    {"city_id":"27707","city_name":"Carichi","state_id":"2432"},
    {"city_id":"27708","city_name":"Casas Grandes","state_id":"2432"},
    {"city_id":"27709","city_name":"Chihuahua","state_id":"2432"},
    {"city_id":"27710","city_name":"Colonia Municipio Libre","state_id":"2432"},
    {"city_id":"27711","city_name":"Creel","state_id":"2432"},
    {"city_id":"27712","city_name":"Cuauhtemoc","state_id":"2432"},
    {"city_id":"27713","city_name":"Delicias","state_id":"2432"},
    {"city_id":"27714","city_name":"El Largo","state_id":"2432"},
    {"city_id":"27715","city_name":"Guachochi","state_id":"2432"},
    {"city_id":"27716","city_name":"Guadalupe","state_id":"2432"},
    {"city_id":"27717","city_name":"Guadalupe y Calvo","state_id":"2432"},
    {"city_id":"27718","city_name":"Hermenegildo Galeana","state_id":"2432"},
    {"city_id":"27719","city_name":"Hidalgo","state_id":"2432"},
    {"city_id":"27720","city_name":"Ignacio Zaragoza","state_id":"2432"},
    {"city_id":"27721","city_name":"Jimenez","state_id":"2432"},
    {"city_id":"27722","city_name":"Juan Aldama","state_id":"2432"},
    {"city_id":"27723","city_name":"Juarez","state_id":"2432"},
    {"city_id":"27724","city_name":"Lazaro Cardenas","state_id":"2432"},
    {"city_id":"27725","city_name":"Madera","state_id":"2432"},
    {"city_id":"27726","city_name":"Majimachi","state_id":"2432"},
    {"city_id":"27727","city_name":"Meoqui","state_id":"2432"},
    {"city_id":"27728","city_name":"Naica","state_id":"2432"},
    {"city_id":"27729","city_name":"Nuevo Casas Grandes","state_id":"2432"},
    {"city_id":"27730","city_name":"Octaviano Lopez","state_id":"2432"},
    {"city_id":"27731","city_name":"Ojinaga","state_id":"2432"},
    {"city_id":"27732","city_name":"Praxedis Guerrero","state_id":"2432"},
    {"city_id":"27733","city_name":"Puerto Palomas","state_id":"2432"},
    {"city_id":"27734","city_name":"Puerto de Anapra","state_id":"2432"},
    {"city_id":"27735","city_name":"San Buenaventura","state_id":"2432"},
    {"city_id":"27736","city_name":"San Francisco el Oro","state_id":"2432"},
    {"city_id":"27737","city_name":"Santa Barbara","state_id":"2432"},
    {"city_id":"27738","city_name":"Santa Cruz de Rosales","state_id":"2432"},
    {"city_id":"27739","city_name":"Saucillo","state_id":"2432"},
    {"city_id":"27740","city_name":"Valentin Gomez Farias","state_id":"2432"},
    {"city_id":"27741","city_name":"Valle de Allende","state_id":"2432"},
    {"city_id":"27742","city_name":"Vicente Guerrero","state_id":"2432"},
    {"city_id":"27743","city_name":"Acuna","state_id":"2433"},
    {"city_id":"27744","city_name":"Allende","state_id":"2433"},
    {"city_id":"27745","city_name":"Arteaga","state_id":"2433"},
    {"city_id":"27746","city_name":"Castanos","state_id":"2433"},
    {"city_id":"27747","city_name":"Concordia","state_id":"2433"},
    {"city_id":"27748","city_name":"Cuatrocienagas","state_id":"2433"},
    {"city_id":"27749","city_name":"El Coyote","state_id":"2433"},
    {"city_id":"27750","city_name":"Frontera","state_id":"2433"},
    {"city_id":"27751","city_name":"General Cepeda","state_id":"2433"},
    {"city_id":"27752","city_name":"Hercules","state_id":"2433"},
    {"city_id":"27753","city_name":"Matamoros","state_id":"2433"},
    {"city_id":"27754","city_name":"Minas de Barroteran","state_id":"2433"},
    {"city_id":"27755","city_name":"Monclova","state_id":"2433"},
    {"city_id":"27756","city_name":"Muzquiz","state_id":"2433"},
    {"city_id":"27757","city_name":"Nadadores","state_id":"2433"},
    {"city_id":"27758","city_name":"Nava","state_id":"2433"},
    {"city_id":"27759","city_name":"Nueva Rosita","state_id":"2433"},
    {"city_id":"27760","city_name":"Ocampo","state_id":"2433"},
    {"city_id":"27761","city_name":"Palau","state_id":"2433"},
    {"city_id":"27762","city_name":"Parras","state_id":"2433"},
    {"city_id":"27763","city_name":"Piedras Negras","state_id":"2433"},
    {"city_id":"27764","city_name":"Ramos Arizpe","state_id":"2433"},
    {"city_id":"27765","city_name":"Sabinas","state_id":"2433"},
    {"city_id":"27766","city_name":"Saltillo","state_id":"2433"},
    {"city_id":"27767","city_name":"San Buenaventura","state_id":"2433"},
    {"city_id":"27768","city_name":"San Pedro","state_id":"2433"},
    {"city_id":"27769","city_name":"Torreon","state_id":"2433"},
    {"city_id":"27770","city_name":"Venustiano Carranza","state_id":"2433"},
    {"city_id":"27771","city_name":"Viesca","state_id":"2433"},
    {"city_id":"27772","city_name":"Villa Madero","state_id":"2433"},
    {"city_id":"27773","city_name":"Villa Union","state_id":"2433"},
    {"city_id":"27774","city_name":"Zaragoza","state_id":"2433"},
    {"city_id":"27775","city_name":"Armeria","state_id":"2434"},
    {"city_id":"27776","city_name":"Cofradia","state_id":"2434"},
    {"city_id":"27777","city_name":"Colima","state_id":"2434"},
    {"city_id":"27778","city_name":"Colonia Bayardo","state_id":"2434"},
    {"city_id":"27779","city_name":"Comala","state_id":"2434"},
    {"city_id":"27780","city_name":"Coquimatlan","state_id":"2434"},
    {"city_id":"27781","city_name":"Cuauhtemoc","state_id":"2434"},
    {"city_id":"27782","city_name":"El Colomo","state_id":"2434"},
    {"city_id":"27783","city_name":"Madrid","state_id":"2434"},
    {"city_id":"27784","city_name":"Manzanillo","state_id":"2434"},
    {"city_id":"27785","city_name":"Minatitlan","state_id":"2434"},
    {"city_id":"27786","city_name":"Queseria","state_id":"2434"},
    {"city_id":"27787","city_name":"Suchitlan","state_id":"2434"},
    {"city_id":"27788","city_name":"Tecoman","state_id":"2434"},
    {"city_id":"27789","city_name":"Villa de Alvarez","state_id":"2434"},
    {"city_id":"27790","city_name":"Bermejillo","state_id":"2436"},
    {"city_id":"27791","city_name":"Canatlan","state_id":"2436"},
    {"city_id":"27792","city_name":"Cuencame","state_id":"2436"},
    {"city_id":"27793","city_name":"Durango","state_id":"2436"},
    {"city_id":"27794","city_name":"Gomez Palacio","state_id":"2436"},
    {"city_id":"27795","city_name":"Guadalupe Victoria","state_id":"2436"},
    {"city_id":"27796","city_name":"Juarez","state_id":"2436"},
    {"city_id":"27797","city_name":"Lerdo","state_id":"2436"},
    {"city_id":"27798","city_name":"Mapimi","state_id":"2436"},
    {"city_id":"27799","city_name":"Nazareno","state_id":"2436"},
    {"city_id":"27800","city_name":"Nazas","state_id":"2436"},
    {"city_id":"27801","city_name":"Nombre de Dios","state_id":"2436"},
    {"city_id":"27802","city_name":"Nuevo Ideal","state_id":"2436"},
    {"city_id":"27803","city_name":"Ocampo","state_id":"2436"},
    {"city_id":"27804","city_name":"Panuco","state_id":"2436"},
    {"city_id":"27805","city_name":"Papasquiaro","state_id":"2436"},
    {"city_id":"27806","city_name":"Penon Blanco","state_id":"2436"},
    {"city_id":"27807","city_name":"Poanas","state_id":"2436"},
    {"city_id":"27808","city_name":"Pueblo Nuevo","state_id":"2436"},
    {"city_id":"27809","city_name":"Rodeo","state_id":"2436"},
    {"city_id":"27810","city_name":"Santa Catarina de Tepehuanes","state_id":"2436"},
    {"city_id":"27811","city_name":"Santa Clara","state_id":"2436"},
    {"city_id":"27812","city_name":"Santa Maria del Oro","state_id":"2436"},
    {"city_id":"27813","city_name":"Suchil","state_id":"2436"},
    {"city_id":"27814","city_name":"Tayoltita","state_id":"2436"},
    {"city_id":"27815","city_name":"Tlahualilo","state_id":"2436"},
    {"city_id":"27816","city_name":"Vicente Guerrero","state_id":"2436"},
    {"city_id":"27817","city_name":"Abasolo","state_id":"2438"},
    {"city_id":"27818","city_name":"Acambaro","state_id":"2438"},
    {"city_id":"27819","city_name":"Aldama","state_id":"2438"},
    {"city_id":"27820","city_name":"Allende","state_id":"2438"},
    {"city_id":"27821","city_name":"Antonio Rodriguez","state_id":"2438"},
    {"city_id":"27822","city_name":"Apaseo el Alto","state_id":"2438"},
    {"city_id":"27823","city_name":"Apaseo el Grande","state_id":"2438"},
    {"city_id":"27824","city_name":"Celaya","state_id":"2438"},
    {"city_id":"27825","city_name":"Centro Familiar la Soledad","state_id":"2438"},
    {"city_id":"27826","city_name":"Cerano","state_id":"2438"},
    {"city_id":"27827","city_name":"Cerro Gordo","state_id":"2438"},
    {"city_id":"27828","city_name":"Colonias Nuevo Mexico","state_id":"2438"},
    {"city_id":"27829","city_name":"Comonfort","state_id":"2438"},
    {"city_id":"27830","city_name":"Coroneo","state_id":"2438"},
    {"city_id":"27831","city_name":"Cortazar","state_id":"2438"},
    {"city_id":"27832","city_name":"Cueramaro","state_id":"2438"},
    {"city_id":"27833","city_name":"Doctor Mora","state_id":"2438"},
    {"city_id":"27834","city_name":"Dolores Hidalgo","state_id":"2438"},
    {"city_id":"27835","city_name":"Duarte","state_id":"2438"},
    {"city_id":"27836","city_name":"El Sabino","state_id":"2438"},
    {"city_id":"27837","city_name":"Escobedo","state_id":"2438"},
    {"city_id":"27838","city_name":"Guanajuato","state_id":"2438"},
    {"city_id":"27839","city_name":"Huanimaro","state_id":"2438"},
    {"city_id":"27840","city_name":"Iramuco","state_id":"2438"},
    {"city_id":"27841","city_name":"Irapuato","state_id":"2438"},
    {"city_id":"27842","city_name":"Jaral del Progreso","state_id":"2438"},
    {"city_id":"27843","city_name":"Jerecuaro","state_id":"2438"},
    {"city_id":"27844","city_name":"Juventino Rosas","state_id":"2438"},
    {"city_id":"27845","city_name":"La Aldea","state_id":"2438"},
    {"city_id":"27846","city_name":"La Calera","state_id":"2438"},
    {"city_id":"27847","city_name":"La Ermita","state_id":"2438"},
    {"city_id":"27848","city_name":"La Moncada","state_id":"2438"},
    {"city_id":"27849","city_name":"Leon","state_id":"2438"},
    {"city_id":"27850","city_name":"Loma Pelada","state_id":"2438"},
    {"city_id":"27851","city_name":"Manuel Doblado","state_id":"2438"},
    {"city_id":"27852","city_name":"Maravatio","state_id":"2438"},
    {"city_id":"27853","city_name":"Marfil","state_id":"2438"},
    {"city_id":"27854","city_name":"Medina","state_id":"2438"},
    {"city_id":"27855","city_name":"Mexicanos","state_id":"2438"},
    {"city_id":"27856","city_name":"Moroleon","state_id":"2438"},
    {"city_id":"27857","city_name":"Ocampo","state_id":"2438"},
    {"city_id":"27858","city_name":"Octopan","state_id":"2438"},
    {"city_id":"27859","city_name":"Pacueco","state_id":"2438"},
    {"city_id":"27860","city_name":"Palo Verde","state_id":"2438"},
    {"city_id":"27861","city_name":"Paracuaro","state_id":"2438"},
    {"city_id":"27862","city_name":"Penjamo","state_id":"2438"},
    {"city_id":"27863","city_name":"Plan de Ayala","state_id":"2438"},
    {"city_id":"27864","city_name":"Pueblo Nuevo","state_id":"2438"},
    {"city_id":"27865","city_name":"Purisima de Bustos","state_id":"2438"},
    {"city_id":"27866","city_name":"Rincon de Tamayo","state_id":"2438"},
    {"city_id":"27867","city_name":"Romita","state_id":"2438"},
    {"city_id":"27868","city_name":"Salamanca","state_id":"2438"},
    {"city_id":"27869","city_name":"Salvatierra","state_id":"2438"},
    {"city_id":"27870","city_name":"San Bartolo de Berrios","state_id":"2438"},
    {"city_id":"27871","city_name":"San Cristobal","state_id":"2438"},
    {"city_id":"27872","city_name":"San Diego de la Union","state_id":"2438"},
    {"city_id":"27873","city_name":"San Felipe","state_id":"2438"},
    {"city_id":"27874","city_name":"San Francisco del Rincon","state_id":"2438"},
    {"city_id":"27875","city_name":"San Jose Agua Azul","state_id":"2438"},
    {"city_id":"27876","city_name":"San Jose Iturbide","state_id":"2438"},
    {"city_id":"27877","city_name":"San Jose Temascatio","state_id":"2438"},
    {"city_id":"27878","city_name":"San Juan de la Vega","state_id":"2438"},
    {"city_id":"27879","city_name":"San Luis de la Paz","state_id":"2438"},
    {"city_id":"27880","city_name":"San Nicolas de los Agustinos","state_id":"2438"},
    {"city_id":"27881","city_name":"San Pedro de los Naranjos","state_id":"2438"},
    {"city_id":"27882","city_name":"San Roque","state_id":"2438"},
    {"city_id":"27883","city_name":"Santa Teresa","state_id":"2438"},
    {"city_id":"27884","city_name":"Santiago de Cuenda","state_id":"2438"},
    {"city_id":"27885","city_name":"Sarabia","state_id":"2438"},
    {"city_id":"27886","city_name":"Silao","state_id":"2438"},
    {"city_id":"27887","city_name":"Tarandacuao","state_id":"2438"},
    {"city_id":"27888","city_name":"Tarimoro","state_id":"2438"},
    {"city_id":"27889","city_name":"Teneria del Santuario","state_id":"2438"},
    {"city_id":"27890","city_name":"Uriangato","state_id":"2438"},
    {"city_id":"27891","city_name":"Urireo","state_id":"2438"},
    {"city_id":"27892","city_name":"Valle de Santiago","state_id":"2438"},
    {"city_id":"27893","city_name":"Valtierrilla","state_id":"2438"},
    {"city_id":"27894","city_name":"Victoria","state_id":"2438"},
    {"city_id":"27895","city_name":"Villagran","state_id":"2438"},
    {"city_id":"27896","city_name":"Villas de Irapuato","state_id":"2438"},
    {"city_id":"27897","city_name":"Yerbabuena","state_id":"2438"},
    {"city_id":"27898","city_name":"Yuriria","state_id":"2438"},
    {"city_id":"27899","city_name":"Acamixtla","state_id":"2439"},
    {"city_id":"27900","city_name":"Acapulco","state_id":"2439"},
    {"city_id":"27901","city_name":"Acatlan","state_id":"2439"},
    {"city_id":"27902","city_name":"Ajuchitlan","state_id":"2439"},
    {"city_id":"27903","city_name":"Alpoyeca","state_id":"2439"},
    {"city_id":"27904","city_name":"Altamirano","state_id":"2439"},
    {"city_id":"27905","city_name":"Apango","state_id":"2439"},
    {"city_id":"27906","city_name":"Apaxtla","state_id":"2439"},
    {"city_id":"27907","city_name":"Arcelia","state_id":"2439"},
    {"city_id":"27908","city_name":"Atliaca","state_id":"2439"},
    {"city_id":"27909","city_name":"Atoyac","state_id":"2439"},
    {"city_id":"27910","city_name":"Ayutla","state_id":"2439"},
    {"city_id":"27911","city_name":"Azoyu","state_id":"2439"},
    {"city_id":"27912","city_name":"Bajos de Ejido","state_id":"2439"},
    {"city_id":"27913","city_name":"Buenavista","state_id":"2439"},
    {"city_id":"27914","city_name":"Chichihualco","state_id":"2439"},
    {"city_id":"27915","city_name":"Chilapa","state_id":"2439"},
    {"city_id":"27916","city_name":"Chilpancingo","state_id":"2439"},
    {"city_id":"27917","city_name":"Coacoyul","state_id":"2439"},
    {"city_id":"27918","city_name":"Cocula","state_id":"2439"},
    {"city_id":"27919","city_name":"Copala","state_id":"2439"},
    {"city_id":"27920","city_name":"Copalillo","state_id":"2439"},
    {"city_id":"27921","city_name":"Coyuca","state_id":"2439"},
    {"city_id":"27922","city_name":"Cruz Grande","state_id":"2439"},
    {"city_id":"27923","city_name":"Cuajinicuilapa","state_id":"2439"},
    {"city_id":"27924","city_name":"Cuautepec","state_id":"2439"},
    {"city_id":"27925","city_name":"Cutzamala","state_id":"2439"},
    {"city_id":"27926","city_name":"El Ocotito","state_id":"2439"},
    {"city_id":"27927","city_name":"El Paraiso","state_id":"2439"},
    {"city_id":"27928","city_name":"El Suchil","state_id":"2439"},
    {"city_id":"27929","city_name":"Huamuxtitlan","state_id":"2439"},
    {"city_id":"27930","city_name":"Huitziltepec","state_id":"2439"},
    {"city_id":"27931","city_name":"Huitzuco","state_id":"2439"},
    {"city_id":"27932","city_name":"Iguala","state_id":"2439"},
    {"city_id":"27933","city_name":"Ixtapa","state_id":"2439"},
    {"city_id":"27934","city_name":"Kilometro Treinta","state_id":"2439"},
    {"city_id":"27935","city_name":"La Loma","state_id":"2439"},
    {"city_id":"27936","city_name":"La Union","state_id":"2439"},
    {"city_id":"27937","city_name":"Las Petaquillas","state_id":"2439"},
    {"city_id":"27938","city_name":"Las Vigas","state_id":"2439"},
    {"city_id":"27939","city_name":"Marquelia","state_id":"2439"},
    {"city_id":"27940","city_name":"Mazatlan","state_id":"2439"},
    {"city_id":"27941","city_name":"Mochitlan","state_id":"2439"},
    {"city_id":"27942","city_name":"Olinala","state_id":"2439"},
    {"city_id":"27943","city_name":"Ometepec","state_id":"2439"},
    {"city_id":"27944","city_name":"Petatlan","state_id":"2439"},
    {"city_id":"27945","city_name":"Pilcaya","state_id":"2439"},
    {"city_id":"27946","city_name":"Quechultenango","state_id":"2439"},
    {"city_id":"27947","city_name":"San Jeronimito","state_id":"2439"},
    {"city_id":"27948","city_name":"San Jeronimo","state_id":"2439"},
    {"city_id":"27949","city_name":"San Jose Ixtapa","state_id":"2439"},
    {"city_id":"27950","city_name":"San Luis San Pedro","state_id":"2439"},
    {"city_id":"27951","city_name":"San Marcos","state_id":"2439"},
    {"city_id":"27952","city_name":"Taxco","state_id":"2439"},
    {"city_id":"27953","city_name":"Taxco de Alarcon","state_id":"2439"},
    {"city_id":"27954","city_name":"Tecoanapa","state_id":"2439"},
    {"city_id":"27955","city_name":"Tecpan","state_id":"2439"},
    {"city_id":"27956","city_name":"Teloloapan","state_id":"2439"},
    {"city_id":"27957","city_name":"Tepecoacuilco","state_id":"2439"},
    {"city_id":"27958","city_name":"Tierra Colorada","state_id":"2439"},
    {"city_id":"27959","city_name":"Tixtla","state_id":"2439"},
    {"city_id":"27960","city_name":"Tlacoachistlahuaca","state_id":"2439"},
    {"city_id":"27961","city_name":"Tlacotepec","state_id":"2439"},
    {"city_id":"27962","city_name":"Tlalchapa","state_id":"2439"},
    {"city_id":"27963","city_name":"Tlamacazapa","state_id":"2439"},
    {"city_id":"27964","city_name":"Tlapa","state_id":"2439"},
    {"city_id":"27965","city_name":"Tlapehuala","state_id":"2439"},
    {"city_id":"27966","city_name":"Totolapan","state_id":"2439"},
    {"city_id":"27967","city_name":"Tres Palos","state_id":"2439"},
    {"city_id":"27968","city_name":"Xalpatlahuac","state_id":"2439"},
    {"city_id":"27969","city_name":"Xaltianguis","state_id":"2439"},
    {"city_id":"27970","city_name":"Xochihuehuetlan","state_id":"2439"},
    {"city_id":"27971","city_name":"Xochistlahuaca","state_id":"2439"},
    {"city_id":"27972","city_name":"Zacualpan","state_id":"2439"},
    {"city_id":"27973","city_name":"Zihuatanejo","state_id":"2439"},
    {"city_id":"27974","city_name":"Zirandaro","state_id":"2439"},
    {"city_id":"27975","city_name":"Zitlala","state_id":"2439"},
    {"city_id":"27976","city_name":"Zumpango","state_id":"2439"},
    {"city_id":"27977","city_name":"Acaxochitlan","state_id":"2440"},
    {"city_id":"27978","city_name":"Acayuca","state_id":"2440"},
    {"city_id":"27979","city_name":"Actopan","state_id":"2440"},
    {"city_id":"27980","city_name":"Ajacuba","state_id":"2440"},
    {"city_id":"27981","city_name":"Almoloya","state_id":"2440"},
    {"city_id":"27982","city_name":"Apan","state_id":"2440"},
    {"city_id":"27983","city_name":"Atengo","state_id":"2440"},
    {"city_id":"27984","city_name":"Atitalaquia","state_id":"2440"},
    {"city_id":"27985","city_name":"Atotonilco de Tula","state_id":"2440"},
    {"city_id":"27986","city_name":"Atotonilco el Grande","state_id":"2440"},
    {"city_id":"27987","city_name":"Calnali","state_id":"2440"},
    {"city_id":"27988","city_name":"Cardonal","state_id":"2440"},
    {"city_id":"27989","city_name":"Chapulhuacan","state_id":"2440"},
    {"city_id":"27990","city_name":"Cuautepec","state_id":"2440"},
    {"city_id":"27991","city_name":"Doxey","state_id":"2440"},
    {"city_id":"27992","city_name":"El Arenal","state_id":"2440"},
    {"city_id":"27993","city_name":"El Llano","state_id":"2440"},
    {"city_id":"27994","city_name":"El Rosario","state_id":"2440"},
    {"city_id":"27995","city_name":"Emiliano Zapata","state_id":"2440"},
    {"city_id":"27996","city_name":"Huautla","state_id":"2440"},
    {"city_id":"27997","city_name":"Huejutla","state_id":"2440"},
    {"city_id":"27998","city_name":"Hueytlalpan","state_id":"2440"},
    {"city_id":"27999","city_name":"Huichapan","state_id":"2440"},
    {"city_id":"28000","city_name":"Ixmiquilpan","state_id":"2440"},
    {"city_id":"28001","city_name":"Jacala","state_id":"2440"},
    {"city_id":"28002","city_name":"Jaltocan","state_id":"2440"},
    {"city_id":"28003","city_name":"Los Reyes","state_id":"2440"},
    {"city_id":"28004","city_name":"Mineral del Monte","state_id":"2440"},
    {"city_id":"28005","city_name":"Mixquiahuala","state_id":"2440"},
    {"city_id":"28006","city_name":"Molango","state_id":"2440"},
    {"city_id":"28007","city_name":"Orizatlan","state_id":"2440"},
    {"city_id":"28008","city_name":"Pachuca","state_id":"2440"},
    {"city_id":"28009","city_name":"Pachuquilla","state_id":"2440"},
    {"city_id":"28010","city_name":"Progreso","state_id":"2440"},
    {"city_id":"28011","city_name":"Sahagun","state_id":"2440"},
    {"city_id":"28012","city_name":"San Ildefonso","state_id":"2440"},
    {"city_id":"28013","city_name":"San Juan Tepa","state_id":"2440"},
    {"city_id":"28014","city_name":"San Marcos","state_id":"2440"},
    {"city_id":"28015","city_name":"Singuilucan","state_id":"2440"},
    {"city_id":"28016","city_name":"Tasquillo","state_id":"2440"},
    {"city_id":"28017","city_name":"Tecozautla","state_id":"2440"},
    {"city_id":"28018","city_name":"Tepatepec","state_id":"2440"},
    {"city_id":"28019","city_name":"Tepeapulco","state_id":"2440"},
    {"city_id":"28020","city_name":"Tepeji","state_id":"2440"},
    {"city_id":"28021","city_name":"Tepepa","state_id":"2440"},
    {"city_id":"28022","city_name":"Tetepango","state_id":"2440"},
    {"city_id":"28023","city_name":"Tezontepec","state_id":"2440"},
    {"city_id":"28024","city_name":"Tizayuca","state_id":"2440"},
    {"city_id":"28025","city_name":"Tlahuelilpan","state_id":"2440"},
    {"city_id":"28026","city_name":"Tlanalapa","state_id":"2440"},
    {"city_id":"28027","city_name":"Tlanchinol","state_id":"2440"},
    {"city_id":"28028","city_name":"Tlaxcoapan","state_id":"2440"},
    {"city_id":"28029","city_name":"Tlaxiaca","state_id":"2440"},
    {"city_id":"28030","city_name":"Tolcayuca","state_id":"2440"},
    {"city_id":"28031","city_name":"Tula de Allende","state_id":"2440"},
    {"city_id":"28032","city_name":"Tulancingo","state_id":"2440"},
    {"city_id":"28033","city_name":"Tulantepec","state_id":"2440"},
    {"city_id":"28034","city_name":"Vindho","state_id":"2440"},
    {"city_id":"28035","city_name":"Zacualtipan","state_id":"2440"},
    {"city_id":"28036","city_name":"Zapotlan","state_id":"2440"},
    {"city_id":"28037","city_name":"Zempoala","state_id":"2440"},
    {"city_id":"28038","city_name":"Zimapan","state_id":"2440"},
    {"city_id":"28039","city_name":"Acatic","state_id":"2441"},
    {"city_id":"28040","city_name":"Acatlan","state_id":"2441"},
    {"city_id":"28041","city_name":"Ahualulco","state_id":"2441"},
    {"city_id":"28042","city_name":"Ajijic","state_id":"2441"},
    {"city_id":"28043","city_name":"Alejandria","state_id":"2441"},
    {"city_id":"28044","city_name":"Amatitan","state_id":"2441"},
    {"city_id":"28045","city_name":"Ameca","state_id":"2441"},
    {"city_id":"28046","city_name":"Antonio Escobedo","state_id":"2441"},
    {"city_id":"28047","city_name":"Arandas","state_id":"2441"},
    {"city_id":"28048","city_name":"Atemajac","state_id":"2441"},
    {"city_id":"28049","city_name":"Atequiza","state_id":"2441"},
    {"city_id":"28050","city_name":"Atotonilco el Alto","state_id":"2441"},
    {"city_id":"28051","city_name":"Atotonilquillo","state_id":"2441"},
    {"city_id":"28052","city_name":"Atoyac","state_id":"2441"},
    {"city_id":"28053","city_name":"Autlan","state_id":"2441"},
    {"city_id":"28054","city_name":"Ayotlan","state_id":"2441"},
    {"city_id":"28055","city_name":"Ayutla","state_id":"2441"},
    {"city_id":"28056","city_name":"Bellavista","state_id":"2441"},
    {"city_id":"28057","city_name":"Cajititlan","state_id":"2441"},
    {"city_id":"28058","city_name":"Capilla de Guadalupe","state_id":"2441"},
    {"city_id":"28059","city_name":"Casimiro Castillo","state_id":"2441"},
    {"city_id":"28060","city_name":"Centro de Readaptacion Social","state_id":"2441"},
    {"city_id":"28061","city_name":"Chapala","state_id":"2441"},
    {"city_id":"28062","city_name":"Chiquilistlan","state_id":"2441"},
    {"city_id":"28063","city_name":"Cihuatlan","state_id":"2441"},
    {"city_id":"28064","city_name":"Cocula","state_id":"2441"},
    {"city_id":"28065","city_name":"Colotlan","state_id":"2441"},
    {"city_id":"28066","city_name":"Concepcion de Buenos Aires","state_id":"2441"},
    {"city_id":"28067","city_name":"Cosala","state_id":"2441"},
    {"city_id":"28068","city_name":"Coyula","state_id":"2441"},
    {"city_id":"28069","city_name":"Cuitzeo","state_id":"2441"},
    {"city_id":"28070","city_name":"Cuqio","state_id":"2441"},
    {"city_id":"28071","city_name":"Cuyutlan","state_id":"2441"},
    {"city_id":"28072","city_name":"Degollado","state_id":"2441"},
    {"city_id":"28073","city_name":"El Arenal","state_id":"2441"},
    {"city_id":"28074","city_name":"El Grullo","state_id":"2441"},
    {"city_id":"28075","city_name":"El Limon","state_id":"2441"},
    {"city_id":"28076","city_name":"El Quince","state_id":"2441"},
    {"city_id":"28077","city_name":"El Refugio","state_id":"2441"},
    {"city_id":"28078","city_name":"El Salto","state_id":"2441"},
    {"city_id":"28079","city_name":"El Verde","state_id":"2441"},
    {"city_id":"28080","city_name":"Encarnacion","state_id":"2441"},
    {"city_id":"28081","city_name":"Etzatlan","state_id":"2441"},
    {"city_id":"28082","city_name":"Guadalajara","state_id":"2441"},
    {"city_id":"28083","city_name":"Guzman","state_id":"2441"},
    {"city_id":"28084","city_name":"Hidalgo","state_id":"2441"},
    {"city_id":"28085","city_name":"Hostotipaquillo","state_id":"2441"},
    {"city_id":"28086","city_name":"Huejucar","state_id":"2441"},
    {"city_id":"28087","city_name":"Huejuquilla el Alto","state_id":"2441"},
    {"city_id":"28088","city_name":"Itzican","state_id":"2441"},
    {"city_id":"28089","city_name":"Ixtapa","state_id":"2441"},
    {"city_id":"28090","city_name":"Ixtlahuacan de los Membrillos","state_id":"2441"},
    {"city_id":"28091","city_name":"Ixtlahuacan del Rio","state_id":"2441"},
    {"city_id":"28092","city_name":"Ixtlan","state_id":"2441"},
    {"city_id":"28093","city_name":"Jalostotitlan","state_id":"2441"},
    {"city_id":"28094","city_name":"Jamay","state_id":"2441"},
    {"city_id":"28095","city_name":"Jesus Maria","state_id":"2441"},
    {"city_id":"28096","city_name":"Jocotepec","state_id":"2441"},
    {"city_id":"28097","city_name":"Juanacatlan","state_id":"2441"},
    {"city_id":"28098","city_name":"Juchitlan","state_id":"2441"},
    {"city_id":"28099","city_name":"La Barca","state_id":"2441"},
    {"city_id":"28100","city_name":"La Huerta","state_id":"2441"},
    {"city_id":"28101","city_name":"La Ribera","state_id":"2441"},
    {"city_id":"28102","city_name":"La Tijera","state_id":"2441"},
    {"city_id":"28103","city_name":"La Venta del Astillero","state_id":"2441"},
    {"city_id":"28104","city_name":"Lagos de Moreno","state_id":"2441"},
    {"city_id":"28105","city_name":"Las Juntas","state_id":"2441"},
    {"city_id":"28106","city_name":"Las Pintas","state_id":"2441"},
    {"city_id":"28107","city_name":"Las Pintitas","state_id":"2441"},
    {"city_id":"28108","city_name":"Lo Arado","state_id":"2441"},
    {"city_id":"28109","city_name":"Magdalena","state_id":"2441"},
    {"city_id":"28110","city_name":"Mascota","state_id":"2441"},
    {"city_id":"28111","city_name":"Mazamitla","state_id":"2441"},
    {"city_id":"28112","city_name":"Mexticacan","state_id":"2441"},
    {"city_id":"28113","city_name":"Mezcala","state_id":"2441"},
    {"city_id":"28114","city_name":"Nuevo Mexico","state_id":"2441"},
    {"city_id":"28115","city_name":"Ocotlan","state_id":"2441"},
    {"city_id":"28116","city_name":"Ojuelos de Jalisco","state_id":"2441"},
    {"city_id":"28117","city_name":"Pihuamo","state_id":"2441"},
    {"city_id":"28118","city_name":"Poncitlan","state_id":"2441"},
    {"city_id":"28119","city_name":"Puente Grande","state_id":"2441"},
    {"city_id":"28120","city_name":"Puerto Vallarta","state_id":"2441"},
    {"city_id":"28121","city_name":"Purificacion","state_id":"2441"},
    {"city_id":"28122","city_name":"San Agustin","state_id":"2441"},
    {"city_id":"28123","city_name":"San Francisco de Asis","state_id":"2441"},
    {"city_id":"28124","city_name":"San Gabriel","state_id":"2441"},
    {"city_id":"28125","city_name":"San Jose de Gracia","state_id":"2441"},
    {"city_id":"28126","city_name":"San Jose del Castillo","state_id":"2441"},
    {"city_id":"28127","city_name":"San Juan de los Lagos","state_id":"2441"},
    {"city_id":"28128","city_name":"San Julian","state_id":"2441"},
    {"city_id":"28129","city_name":"San Marcos","state_id":"2441"},
    {"city_id":"28130","city_name":"San Martin de Hidalgo","state_id":"2441"},
    {"city_id":"28131","city_name":"San Miguel el Alto","state_id":"2441"},
    {"city_id":"28132","city_name":"San Patricio","state_id":"2441"},
    {"city_id":"28133","city_name":"San Sebastian del Sur","state_id":"2441"},
    {"city_id":"28134","city_name":"San Sebastian el Grande","state_id":"2441"},
    {"city_id":"28135","city_name":"Santa Anita","state_id":"2441"},
    {"city_id":"28136","city_name":"Santa Cruz de las Flores","state_id":"2441"},
    {"city_id":"28137","city_name":"Santa Cruz del Valle","state_id":"2441"},
    {"city_id":"28138","city_name":"Sayula","state_id":"2441"},
    {"city_id":"28139","city_name":"Tala","state_id":"2441"},
    {"city_id":"28140","city_name":"Talpa","state_id":"2441"},
    {"city_id":"28141","city_name":"Tamazula","state_id":"2441"},
    {"city_id":"28142","city_name":"Tapalpa","state_id":"2441"},
    {"city_id":"28143","city_name":"Tecalitlan","state_id":"2441"},
    {"city_id":"28144","city_name":"Tecolotlan","state_id":"2441"},
    {"city_id":"28145","city_name":"Tenamaxtlan","state_id":"2441"},
    {"city_id":"28146","city_name":"Teocaltiche","state_id":"2441"},
    {"city_id":"28147","city_name":"Teocuitatlan de Corona","state_id":"2441"},
    {"city_id":"28148","city_name":"Tepatitlan","state_id":"2441"},
    {"city_id":"28149","city_name":"Tequila","state_id":"2441"},
    {"city_id":"28150","city_name":"Tesistan","state_id":"2441"},
    {"city_id":"28151","city_name":"Teuchitlan","state_id":"2441"},
    {"city_id":"28152","city_name":"Tizapan el Alto","state_id":"2441"},
    {"city_id":"28153","city_name":"Tlajomulco","state_id":"2441"},
    {"city_id":"28154","city_name":"Tlaquepaque","state_id":"2441"},
    {"city_id":"28155","city_name":"Tomatlan","state_id":"2441"},
    {"city_id":"28156","city_name":"Tonala","state_id":"2441"},
    {"city_id":"28157","city_name":"Tonaya","state_id":"2441"},
    {"city_id":"28158","city_name":"Tonila","state_id":"2441"},
    {"city_id":"28159","city_name":"Tototlan","state_id":"2441"},
    {"city_id":"28160","city_name":"Tuxpan","state_id":"2441"},
    {"city_id":"28161","city_name":"Union de San Antonio","state_id":"2441"},
    {"city_id":"28162","city_name":"Union de Tula","state_id":"2441"},
    {"city_id":"28163","city_name":"Usmajac","state_id":"2441"},
    {"city_id":"28164","city_name":"Valle de Guadalupe","state_id":"2441"},
    {"city_id":"28165","city_name":"Valle de Juarez","state_id":"2441"},
    {"city_id":"28166","city_name":"Villa Corona","state_id":"2441"},
    {"city_id":"28167","city_name":"Villa Guerrero","state_id":"2441"},
    {"city_id":"28168","city_name":"Yahualica","state_id":"2441"},
    {"city_id":"28169","city_name":"Zacoalco","state_id":"2441"},
    {"city_id":"28170","city_name":"Zapopan","state_id":"2441"},
    {"city_id":"28171","city_name":"Zapote","state_id":"2441"},
    {"city_id":"28172","city_name":"Zapotiltic","state_id":"2441"},
    {"city_id":"28173","city_name":"Zapotlanejo","state_id":"2441"},
    {"city_id":"28174","city_name":"Acahualco","state_id":"2442"},
    {"city_id":"28175","city_name":"Acambay","state_id":"2442"},
    {"city_id":"28176","city_name":"Acazulco","state_id":"2442"},
    {"city_id":"28177","city_name":"Acolman","state_id":"2442"},
    {"city_id":"28178","city_name":"Acuautla","state_id":"2442"},
    {"city_id":"28179","city_name":"Acutzilapan","state_id":"2442"},
    {"city_id":"28180","city_name":"Ajoloapan","state_id":"2442"},
    {"city_id":"28181","city_name":"Alborada","state_id":"2442"},
    {"city_id":"28182","city_name":"Almaya","state_id":"2442"},
    {"city_id":"28183","city_name":"Almoloya","state_id":"2442"},
    {"city_id":"28184","city_name":"Almoloya del Rio","state_id":"2442"},
    {"city_id":"28185","city_name":"Amanalco","state_id":"2442"},
    {"city_id":"28186","city_name":"Amecameca","state_id":"2442"},
    {"city_id":"28187","city_name":"Ameyalco","state_id":"2442"},
    {"city_id":"28188","city_name":"Apaxco","state_id":"2442"},
    {"city_id":"28189","city_name":"Atarasquillo","state_id":"2442"},
    {"city_id":"28190","city_name":"Atenco","state_id":"2442"},
    {"city_id":"28191","city_name":"Atizapan","state_id":"2442"},
    {"city_id":"28192","city_name":"Atlacomulco","state_id":"2442"},
    {"city_id":"28193","city_name":"Atlatlahuca","state_id":"2442"},
    {"city_id":"28194","city_name":"Atlatongo","state_id":"2442"},
    {"city_id":"28195","city_name":"Atlautla","state_id":"2442"},
    {"city_id":"28196","city_name":"Atlazalpan","state_id":"2442"},
    {"city_id":"28197","city_name":"Autopan","state_id":"2442"},
    {"city_id":"28198","city_name":"Axapusco","state_id":"2442"},
    {"city_id":"28199","city_name":"Ayotuzco","state_id":"2442"},
    {"city_id":"28200","city_name":"Ayotzingo","state_id":"2442"},
    {"city_id":"28201","city_name":"Azcatepec","state_id":"2442"},
    {"city_id":"28202","city_name":"Balderas","state_id":"2442"},
    {"city_id":"28203","city_name":"Bocanegra","state_id":"2442"},
    {"city_id":"28204","city_name":"Boreje","state_id":"2442"},
    {"city_id":"28205","city_name":"Buenavista","state_id":"2442"},
    {"city_id":"28206","city_name":"Cacalomacan","state_id":"2442"},
    {"city_id":"28207","city_name":"Cahuacan","state_id":"2442"},
    {"city_id":"28208","city_name":"Calimaya","state_id":"2442"},
    {"city_id":"28209","city_name":"Calixtlahuaca","state_id":"2442"},
    {"city_id":"28210","city_name":"Capulhuac","state_id":"2442"},
    {"city_id":"28211","city_name":"Carbon","state_id":"2442"},
    {"city_id":"28212","city_name":"Cautzingo","state_id":"2442"},
    {"city_id":"28213","city_name":"Chalco","state_id":"2442"},
    {"city_id":"28214","city_name":"Chapultepec","state_id":"2442"},
    {"city_id":"28215","city_name":"Chiautla","state_id":"2442"},
    {"city_id":"28216","city_name":"Chicoloapan","state_id":"2442"},
    {"city_id":"28217","city_name":"Chiconautla","state_id":"2442"},
    {"city_id":"28218","city_name":"Chiconcuac","state_id":"2442"},
    {"city_id":"28219","city_name":"Chimalhuacan","state_id":"2442"},
    {"city_id":"28220","city_name":"Chimalpa","state_id":"2442"},
    {"city_id":"28221","city_name":"Cholula","state_id":"2442"},
    {"city_id":"28222","city_name":"Citendeje","state_id":"2442"},
    {"city_id":"28223","city_name":"Coacalco","state_id":"2442"},
    {"city_id":"28224","city_name":"Coachochitlan","state_id":"2442"},
    {"city_id":"28225","city_name":"Coacomulco","state_id":"2442"},
    {"city_id":"28226","city_name":"Coapango","state_id":"2442"},
    {"city_id":"28227","city_name":"Coatepec","state_id":"2442"},
    {"city_id":"28228","city_name":"Coatepec Harinas","state_id":"2442"},
    {"city_id":"28229","city_name":"Coatlinchan","state_id":"2442"},
    {"city_id":"28230","city_name":"Cocotitlan","state_id":"2442"},
    {"city_id":"28231","city_name":"Colorines","state_id":"2442"},
    {"city_id":"28232","city_name":"Concepcion Jolalpan","state_id":"2442"},
    {"city_id":"28233","city_name":"Coyotepec","state_id":"2442"},
    {"city_id":"28234","city_name":"Cuauhtemoc","state_id":"2442"},
    {"city_id":"28235","city_name":"Cuauhtenco","state_id":"2442"},
    {"city_id":"28236","city_name":"Cuautitlan","state_id":"2442"},
    {"city_id":"28237","city_name":"Cuautitlan Izcalli","state_id":"2442"},
    {"city_id":"28238","city_name":"Cuautlalpan","state_id":"2442"},
    {"city_id":"28239","city_name":"Cuaxustenco","state_id":"2442"},
    {"city_id":"28240","city_name":"Cuexontitlan","state_id":"2442"},
    {"city_id":"28241","city_name":"Cuijingo","state_id":"2442"},
    {"city_id":"28242","city_name":"Ecatepec","state_id":"2442"},
    {"city_id":"28243","city_name":"Ecatzingo","state_id":"2442"},
    {"city_id":"28244","city_name":"Ejido Cahuacan","state_id":"2442"},
    {"city_id":"28245","city_name":"Ejido Veinte de Noviembre la C","state_id":"2442"},
    {"city_id":"28246","city_name":"Ejido de la Y Seccion Siete a ","state_id":"2442"},
    {"city_id":"28247","city_name":"El Coporo","state_id":"2442"},
    {"city_id":"28248","city_name":"El Oro","state_id":"2442"},
    {"city_id":"28249","city_name":"Enchisi","state_id":"2442"},
    {"city_id":"28250","city_name":"Enthavi","state_id":"2442"},
    {"city_id":"28251","city_name":"Fuentes del Valle","state_id":"2442"},
    {"city_id":"28252","city_name":"Huehuetoca","state_id":"2442"},
    {"city_id":"28253","city_name":"Huexoculco","state_id":"2442"},
    {"city_id":"28254","city_name":"Hueypoxtla","state_id":"2442"},
    {"city_id":"28255","city_name":"Huilango","state_id":"2442"},
    {"city_id":"28256","city_name":"Huitzilzingo","state_id":"2442"},
    {"city_id":"28257","city_name":"Huixquilucan","state_id":"2442"},
    {"city_id":"28258","city_name":"Huixquilucan de Degollado","state_id":"2442"},
    {"city_id":"28259","city_name":"Huixtoco","state_id":"2442"},
    {"city_id":"28260","city_name":"Ixlahuaca","state_id":"2442"},
    {"city_id":"28261","city_name":"Ixtacalco","state_id":"2442"},
    {"city_id":"28262","city_name":"Ixtapaluca","state_id":"2442"},
    {"city_id":"28263","city_name":"Ixtapan","state_id":"2442"},
    {"city_id":"28264","city_name":"Ixtlahuatzingo","state_id":"2442"},
    {"city_id":"28265","city_name":"Jajalpa","state_id":"2442"},
    {"city_id":"28266","city_name":"Jaltenco","state_id":"2442"},
    {"city_id":"28267","city_name":"Jaltepec","state_id":"2442"},
    {"city_id":"28268","city_name":"Jesus del Monte","state_id":"2442"},
    {"city_id":"28269","city_name":"Jicaltepec Autopan","state_id":"2442"},
    {"city_id":"28270","city_name":"Jilotepec","state_id":"2442"},
    {"city_id":"28271","city_name":"Jilotzingo","state_id":"2442"},
    {"city_id":"28272","city_name":"Jocotitlan","state_id":"2442"},
    {"city_id":"28273","city_name":"Joquicingo","state_id":"2442"},
    {"city_id":"28274","city_name":"Jorge Jimenez Cantu","state_id":"2442"},
    {"city_id":"28275","city_name":"Juchitepec","state_id":"2442"},
    {"city_id":"28276","city_name":"La Concepcion los Banos","state_id":"2442"},
    {"city_id":"28277","city_name":"La Constitucion","state_id":"2442"},
    {"city_id":"28278","city_name":"La Magdalema","state_id":"2442"},
    {"city_id":"28279","city_name":"Lerma","state_id":"2442"},
    {"city_id":"28280","city_name":"Loma de Juarez","state_id":"2442"},
    {"city_id":"28281","city_name":"Lomas de Zacamulpa","state_id":"2442"},
    {"city_id":"28282","city_name":"Lopez Mateos","state_id":"2442"},
    {"city_id":"28283","city_name":"Los Esparragos","state_id":"2442"},
    {"city_id":"28284","city_name":"Los Reyes","state_id":"2442"},
    {"city_id":"28285","city_name":"Los Reyes Acozac","state_id":"2442"},
    {"city_id":"28286","city_name":"Luvianos","state_id":"2442"},
    {"city_id":"28287","city_name":"Malinalco","state_id":"2442"},
    {"city_id":"28288","city_name":"Melchor Ocampo","state_id":"2442"},
    {"city_id":"28289","city_name":"Metepec","state_id":"2442"},
    {"city_id":"28290","city_name":"Mexicaltzingo","state_id":"2442"},
    {"city_id":"28291","city_name":"Mextepec","state_id":"2442"},
    {"city_id":"28292","city_name":"Montecillo","state_id":"2442"},
    {"city_id":"28293","city_name":"Nativitas","state_id":"2442"},
    {"city_id":"28294","city_name":"Naucalpan","state_id":"2442"},
    {"city_id":"28295","city_name":"Nexquipayac","state_id":"2442"},
    {"city_id":"28296","city_name":"Nextlalpan","state_id":"2442"},
    {"city_id":"28297","city_name":"Nezahualcoyotl","state_id":"2442"},
    {"city_id":"28298","city_name":"Nicolas Romero","state_id":"2442"},
    {"city_id":"28299","city_name":"Nopaltepec","state_id":"2442"},
    {"city_id":"28300","city_name":"Ocotitlan","state_id":"2442"},
    {"city_id":"28301","city_name":"Ocotlan","state_id":"2442"},
    {"city_id":"28302","city_name":"Ocoyoacac","state_id":"2442"},
    {"city_id":"28303","city_name":"Ojo de Agua","state_id":"2442"},
    {"city_id":"28304","city_name":"Otumba","state_id":"2442"},
    {"city_id":"28305","city_name":"Otzacatipan","state_id":"2442"},
    {"city_id":"28306","city_name":"Oyamel","state_id":"2442"},
    {"city_id":"28307","city_name":"Oztolotepec","state_id":"2442"},
    {"city_id":"28308","city_name":"Ozumba","state_id":"2442"},
    {"city_id":"28309","city_name":"Papalotla","state_id":"2442"},
    {"city_id":"28310","city_name":"Progreso Industrial","state_id":"2442"},
    {"city_id":"28311","city_name":"Pueblo Nuevo","state_id":"2442"},
    {"city_id":"28312","city_name":"Rayon","state_id":"2442"},
    {"city_id":"28313","city_name":"Rio Frio","state_id":"2442"},
    {"city_id":"28314","city_name":"Salitrillo","state_id":"2442"},
    {"city_id":"28315","city_name":"San Antonio Buenavista","state_id":"2442"},
    {"city_id":"28316","city_name":"San Antonio La Isla","state_id":"2442"},
    {"city_id":"28317","city_name":"San Bartolo","state_id":"2442"},
    {"city_id":"28318","city_name":"San Bartolo del Llano","state_id":"2442"},
    {"city_id":"28319","city_name":"San Bernardino","state_id":"2442"},
    {"city_id":"28320","city_name":"San Buenaventura","state_id":"2442"},
    {"city_id":"28321","city_name":"San Felipe del Progreso","state_id":"2442"},
    {"city_id":"28322","city_name":"San Jeronimo","state_id":"2442"},
    {"city_id":"28323","city_name":"San Jose Guadalupe","state_id":"2442"},
    {"city_id":"28324","city_name":"San Jose el Vidrio","state_id":"2442"},
    {"city_id":"28325","city_name":"San Juan Tilapa","state_id":"2442"},
    {"city_id":"28326","city_name":"San Juan de las Huertas","state_id":"2442"},
    {"city_id":"28327","city_name":"San Juan y San Pedro Tezompa","state_id":"2442"},
    {"city_id":"28328","city_name":"San Lorenzo Cuauhtenco","state_id":"2442"},
    {"city_id":"28329","city_name":"San Martin de las Piramides","state_id":"2442"},
    {"city_id":"28330","city_name":"San Miguel la Labor","state_id":"2442"},
    {"city_id":"28331","city_name":"San Nicolas Guadalupe","state_id":"2442"},
    {"city_id":"28332","city_name":"San Nicolas Tolentino","state_id":"2442"},
    {"city_id":"28333","city_name":"San Pablo de las Salinas","state_id":"2442"},
    {"city_id":"28334","city_name":"San Pedro Abajo","state_id":"2442"},
    {"city_id":"28335","city_name":"San Pedro Arriba","state_id":"2442"},
    {"city_id":"28336","city_name":"San Pedro el Alto","state_id":"2442"},
    {"city_id":"28337","city_name":"San Pedro los Banos","state_id":"2442"},
    {"city_id":"28338","city_name":"San Simon de la Laguna","state_id":"2442"},
    {"city_id":"28339","city_name":"Santa Catarina del Monte","state_id":"2442"},
    {"city_id":"28340","city_name":"Santa Cruz del Monte","state_id":"2442"},
    {"city_id":"28341","city_name":"Santa Gertrudis","state_id":"2442"},
    {"city_id":"28342","city_name":"Santa Maria del Monte","state_id":"2442"},
    {"city_id":"28343","city_name":"Santo Domingo de Guzman","state_id":"2442"},
    {"city_id":"28344","city_name":"Soyaniquilpan","state_id":"2442"},
    {"city_id":"28345","city_name":"Sultepec","state_id":"2442"},
    {"city_id":"28346","city_name":"Tecalco","state_id":"2442"},
    {"city_id":"28347","city_name":"Tecamac","state_id":"2442"},
    {"city_id":"28348","city_name":"Techuchulco","state_id":"2442"},
    {"city_id":"28349","city_name":"Tecuahutitlan","state_id":"2442"},
    {"city_id":"28350","city_name":"Tehuixtitlan","state_id":"2442"},
    {"city_id":"28351","city_name":"Tejupilco","state_id":"2442"},
    {"city_id":"28352","city_name":"Temamatla","state_id":"2442"},
    {"city_id":"28353","city_name":"Temascalapa","state_id":"2442"},
    {"city_id":"28354","city_name":"Temascalcingo","state_id":"2442"},
    {"city_id":"28355","city_name":"Temoaya","state_id":"2442"},
    {"city_id":"28356","city_name":"Tenancingo","state_id":"2442"},
    {"city_id":"28357","city_name":"Tenango de Arista","state_id":"2442"},
    {"city_id":"28358","city_name":"Tenango del Aire","state_id":"2442"},
    {"city_id":"28359","city_name":"Tenochtitlan","state_id":"2442"},
    {"city_id":"28360","city_name":"Teoloyucan","state_id":"2442"},
    {"city_id":"28361","city_name":"Teotihuacan","state_id":"2442"},
    {"city_id":"28362","city_name":"Tepeolulco","state_id":"2442"},
    {"city_id":"28363","city_name":"Tepetitlan","state_id":"2442"},
    {"city_id":"28364","city_name":"Tepetlaoxtoc","state_id":"2442"},
    {"city_id":"28365","city_name":"Tepetlixpa","state_id":"2442"},
    {"city_id":"28366","city_name":"Tepexpan","state_id":"2442"},
    {"city_id":"28367","city_name":"Tepotzotlan","state_id":"2442"},
    {"city_id":"28368","city_name":"Tequexquinahuac","state_id":"2442"},
    {"city_id":"28369","city_name":"Tequisistlan","state_id":"2442"},
    {"city_id":"28370","city_name":"Tequixquiac","state_id":"2442"},
    {"city_id":"28371","city_name":"Texcalyacac","state_id":"2442"},
    {"city_id":"28372","city_name":"Texcoco","state_id":"2442"},
    {"city_id":"28373","city_name":"Teyahualco","state_id":"2442"},
    {"city_id":"28374","city_name":"Tezoquipan","state_id":"2442"},
    {"city_id":"28375","city_name":"Tezoyuca","state_id":"2442"},
    {"city_id":"28376","city_name":"Tianguistenco","state_id":"2442"},
    {"city_id":"28377","city_name":"Tilapa","state_id":"2442"},
    {"city_id":"28378","city_name":"Tlachaloya Segunda Seccion","state_id":"2442"},
    {"city_id":"28379","city_name":"Tlachihualpa","state_id":"2442"},
    {"city_id":"28380","city_name":"Tlacotepec","state_id":"2442"},
    {"city_id":"28381","city_name":"Tlahuelilpan","state_id":"2442"},
    {"city_id":"28382","city_name":"Tlaixpan","state_id":"2442"},
    {"city_id":"28383","city_name":"Tlalcilalcalli","state_id":"2442"},
    {"city_id":"28384","city_name":"Tlalcilalcalpan","state_id":"2442"},
    {"city_id":"28385","city_name":"Tlalmanalco","state_id":"2442"},
    {"city_id":"28386","city_name":"Tlalmimilolpan","state_id":"2442"},
    {"city_id":"28387","city_name":"Tlalnepantla","state_id":"2442"},
    {"city_id":"28388","city_name":"Tlaltelulco","state_id":"2442"},
    {"city_id":"28389","city_name":"Tlaltizapan","state_id":"2442"},
    {"city_id":"28390","city_name":"Tlanisco","state_id":"2442"},
    {"city_id":"28391","city_name":"Toluca","state_id":"2442"},
    {"city_id":"28392","city_name":"Tonanitla","state_id":"2442"},
    {"city_id":"28393","city_name":"Tonatico","state_id":"2442"},
    {"city_id":"28394","city_name":"Totocuitlapilco","state_id":"2442"},
    {"city_id":"28395","city_name":"Totoltepec","state_id":"2442"},
    {"city_id":"28396","city_name":"Tulantongo","state_id":"2442"},
    {"city_id":"28397","city_name":"Tultepec","state_id":"2442"},
    {"city_id":"28398","city_name":"Tultitlan","state_id":"2442"},
    {"city_id":"28399","city_name":"Valle de Bravo","state_id":"2442"},
    {"city_id":"28400","city_name":"Victoria","state_id":"2442"},
    {"city_id":"28401","city_name":"Villa Guerrero","state_id":"2442"},
    {"city_id":"28402","city_name":"Vista Hermosa","state_id":"2442"},
    {"city_id":"28403","city_name":"Xalatlaco","state_id":"2442"},
    {"city_id":"28404","city_name":"Xalpa","state_id":"2442"},
    {"city_id":"28405","city_name":"Xico","state_id":"2442"},
    {"city_id":"28406","city_name":"Xochimanca","state_id":"2442"},
    {"city_id":"28407","city_name":"Xolalpa","state_id":"2442"},
    {"city_id":"28408","city_name":"Xoloc","state_id":"2442"},
    {"city_id":"28409","city_name":"Xometla","state_id":"2442"},
    {"city_id":"28410","city_name":"Xonacatlan","state_id":"2442"},
    {"city_id":"28411","city_name":"Yachihuacaltepec","state_id":"2442"},
    {"city_id":"28412","city_name":"Yancuitlalpan","state_id":"2442"},
    {"city_id":"28413","city_name":"Zacacalco","state_id":"2442"},
    {"city_id":"28414","city_name":"Zacamulpa","state_id":"2442"},
    {"city_id":"28415","city_name":"Zacualpan","state_id":"2442"},
    {"city_id":"28416","city_name":"Zaragoza","state_id":"2442"},
    {"city_id":"28417","city_name":"Zictepec","state_id":"2442"},
    {"city_id":"28418","city_name":"Zinacantepec","state_id":"2442"},
    {"city_id":"28419","city_name":"Zolotepec","state_id":"2442"},
    {"city_id":"28420","city_name":"Zumpahuacan","state_id":"2442"},
    {"city_id":"28421","city_name":"Zumpango","state_id":"2442"},
    {"city_id":"28422","city_name":"Acuitzio","state_id":"2443"},
    {"city_id":"28423","city_name":"Aguililla","state_id":"2443"},
    {"city_id":"28424","city_name":"Alvaro Obregon","state_id":"2443"},
    {"city_id":"28425","city_name":"Angahuan","state_id":"2443"},
    {"city_id":"28426","city_name":"Angamacutiro","state_id":"2443"},
    {"city_id":"28427","city_name":"Angangueo","state_id":"2443"},
    {"city_id":"28428","city_name":"Antunez","state_id":"2443"},
    {"city_id":"28429","city_name":"Apatzingan","state_id":"2443"},
    {"city_id":"28430","city_name":"Ario","state_id":"2443"},
    {"city_id":"28431","city_name":"Arteaga","state_id":"2443"},
    {"city_id":"28432","city_name":"Benito Juarez","state_id":"2443"},
    {"city_id":"28433","city_name":"Brisenas","state_id":"2443"},
    {"city_id":"28434","city_name":"Buenavista","state_id":"2443"},
    {"city_id":"28435","city_name":"Buenos Aires","state_id":"2443"},
    {"city_id":"28436","city_name":"Caltzontzin","state_id":"2443"},
    {"city_id":"28437","city_name":"Capacuaro","state_id":"2443"},
    {"city_id":"28438","city_name":"Capula","state_id":"2443"},
    {"city_id":"28439","city_name":"Caracuaro","state_id":"2443"},
    {"city_id":"28440","city_name":"Charapan","state_id":"2443"},
    {"city_id":"28441","city_name":"Charo","state_id":"2443"},
    {"city_id":"28442","city_name":"Chavinda","state_id":"2443"},
    {"city_id":"28443","city_name":"Cheran","state_id":"2443"},
    {"city_id":"28444","city_name":"Chilchota","state_id":"2443"},
    {"city_id":"28445","city_name":"Churintzio","state_id":"2443"},
    {"city_id":"28446","city_name":"Churumuco","state_id":"2443"},
    {"city_id":"28447","city_name":"Coahuayana","state_id":"2443"},
    {"city_id":"28448","city_name":"Coalcoman","state_id":"2443"},
    {"city_id":"28449","city_name":"Coeneo","state_id":"2443"},
    {"city_id":"28450","city_name":"Cojumatlan","state_id":"2443"},
    {"city_id":"28451","city_name":"Comachuen","state_id":"2443"},
    {"city_id":"28452","city_name":"Contepec","state_id":"2443"},
    {"city_id":"28453","city_name":"Copandaro","state_id":"2443"},
    {"city_id":"28454","city_name":"Cotija","state_id":"2443"},
    {"city_id":"28455","city_name":"Cuanajo","state_id":"2443"},
    {"city_id":"28456","city_name":"Cuitareo","state_id":"2443"},
    {"city_id":"28457","city_name":"Cuitzeo","state_id":"2443"},
    {"city_id":"28458","city_name":"Ecuandureo","state_id":"2443"},
    {"city_id":"28459","city_name":"Hidalgo","state_id":"2443"},
    {"city_id":"28460","city_name":"Huandacareo","state_id":"2443"},
    {"city_id":"28461","city_name":"Huetamo","state_id":"2443"},
    {"city_id":"28462","city_name":"Indaparapeo","state_id":"2443"},
    {"city_id":"28463","city_name":"Irimbo","state_id":"2443"},
    {"city_id":"28464","city_name":"Ixtlan","state_id":"2443"},
    {"city_id":"28465","city_name":"Jacona","state_id":"2443"},
    {"city_id":"28466","city_name":"Jiquilpan","state_id":"2443"},
    {"city_id":"28467","city_name":"Jungapeo","state_id":"2443"},
    {"city_id":"28468","city_name":"La Huacana","state_id":"2443"},
    {"city_id":"28469","city_name":"La Mira","state_id":"2443"},
    {"city_id":"28470","city_name":"La Orilla","state_id":"2443"},
    {"city_id":"28471","city_name":"La Piedad","state_id":"2443"},
    {"city_id":"28472","city_name":"La Ruana","state_id":"2443"},
    {"city_id":"28473","city_name":"Las Guacamayas","state_id":"2443"},
    {"city_id":"28474","city_name":"Lazaro Cardenas","state_id":"2443"},
    {"city_id":"28475","city_name":"Lombardia","state_id":"2443"},
    {"city_id":"28476","city_name":"Los Reyes","state_id":"2443"},
    {"city_id":"28477","city_name":"Madero","state_id":"2443"},
    {"city_id":"28478","city_name":"Maravatio","state_id":"2443"},
    {"city_id":"28479","city_name":"Maya","state_id":"2443"},
    {"city_id":"28480","city_name":"Morelia","state_id":"2443"},
    {"city_id":"28481","city_name":"Morelos","state_id":"2443"},
    {"city_id":"28482","city_name":"Nahuatzen","state_id":"2443"},
    {"city_id":"28483","city_name":"Nocupetaro","state_id":"2443"},
    {"city_id":"28484","city_name":"Nueva Italia de Ruiz","state_id":"2443"},
    {"city_id":"28485","city_name":"Nuevo San Juan Parangaricutiro","state_id":"2443"},
    {"city_id":"28486","city_name":"Numaran","state_id":"2443"},
    {"city_id":"28487","city_name":"Ocampo","state_id":"2443"},
    {"city_id":"28488","city_name":"Opopeo","state_id":"2443"},
    {"city_id":"28489","city_name":"Pajacuaran","state_id":"2443"},
    {"city_id":"28490","city_name":"Panindicuaro","state_id":"2443"},
    {"city_id":"28491","city_name":"Paracho","state_id":"2443"},
    {"city_id":"28492","city_name":"Paracuaro","state_id":"2443"},
    {"city_id":"28493","city_name":"Pastor Ortiz","state_id":"2443"},
    {"city_id":"28494","city_name":"Patzcuaro","state_id":"2443"},
    {"city_id":"28495","city_name":"Pedernales","state_id":"2443"},
    {"city_id":"28496","city_name":"Penjamillo","state_id":"2443"},
    {"city_id":"28497","city_name":"Periban","state_id":"2443"},
    {"city_id":"28498","city_name":"Pichataro","state_id":"2443"},
    {"city_id":"28499","city_name":"Purepero","state_id":"2443"},
    {"city_id":"28500","city_name":"Puruandiro","state_id":"2443"},
    {"city_id":"28501","city_name":"Puruaran","state_id":"2443"},
    {"city_id":"28502","city_name":"Querendaro","state_id":"2443"},
    {"city_id":"28503","city_name":"Quiroga","state_id":"2443"},
    {"city_id":"28504","city_name":"Rincon de Nicolas Romero","state_id":"2443"},
    {"city_id":"28505","city_name":"Riva Palacio","state_id":"2443"},
    {"city_id":"28506","city_name":"Sahuayo","state_id":"2443"},
    {"city_id":"28507","city_name":"San Jose de Gracia","state_id":"2443"},
    {"city_id":"28508","city_name":"San Lucas","state_id":"2443"},
    {"city_id":"28509","city_name":"San Matias Grande","state_id":"2443"},
    {"city_id":"28510","city_name":"Santa Clara de Valladares","state_id":"2443"},
    {"city_id":"28511","city_name":"Santa Clara del Cobre","state_id":"2443"},
    {"city_id":"28512","city_name":"Santa Fe de la Laguna","state_id":"2443"},
    {"city_id":"28513","city_name":"Tacambaro","state_id":"2443"},
    {"city_id":"28514","city_name":"Tancitaro","state_id":"2443"},
    {"city_id":"28515","city_name":"Tangamandapio","state_id":"2443"},
    {"city_id":"28516","city_name":"Tangancicuaro","state_id":"2443"},
    {"city_id":"28517","city_name":"Tanhuato","state_id":"2443"},
    {"city_id":"28518","city_name":"Tarecuato","state_id":"2443"},
    {"city_id":"28519","city_name":"Taretan","state_id":"2443"},
    {"city_id":"28520","city_name":"Tarimbaro","state_id":"2443"},
    {"city_id":"28521","city_name":"Tejaro","state_id":"2443"},
    {"city_id":"28522","city_name":"Tepalcatepec","state_id":"2443"},
    {"city_id":"28523","city_name":"Tingambato","state_id":"2443"},
    {"city_id":"28524","city_name":"Tinguindin","state_id":"2443"},
    {"city_id":"28525","city_name":"Tiquicheo","state_id":"2443"},
    {"city_id":"28526","city_name":"Tlalpujahua","state_id":"2443"},
    {"city_id":"28527","city_name":"Tlazazalca","state_id":"2443"},
    {"city_id":"28528","city_name":"Tungareo","state_id":"2443"},
    {"city_id":"28529","city_name":"Tuxpan","state_id":"2443"},
    {"city_id":"28530","city_name":"Tzintzuntzan","state_id":"2443"},
    {"city_id":"28531","city_name":"Uruapan","state_id":"2443"},
    {"city_id":"28532","city_name":"Venustiano Carranza","state_id":"2443"},
    {"city_id":"28533","city_name":"Villa Jimenez","state_id":"2443"},
    {"city_id":"28534","city_name":"Villachuato","state_id":"2443"},
    {"city_id":"28535","city_name":"Villamar","state_id":"2443"},
    {"city_id":"28536","city_name":"Vista Hermosa","state_id":"2443"},
    {"city_id":"28537","city_name":"Yurecuaro","state_id":"2443"},
    {"city_id":"28538","city_name":"Zacapu","state_id":"2443"},
    {"city_id":"28539","city_name":"Zamora","state_id":"2443"},
    {"city_id":"28540","city_name":"Zinapecuaro","state_id":"2443"},
    {"city_id":"28541","city_name":"Zitacuaro","state_id":"2443"},
    {"city_id":"28542","city_name":"Alpuyeca","state_id":"2444"},
    {"city_id":"28543","city_name":"Amacuzac","state_id":"2444"},
    {"city_id":"28544","city_name":"Amayuca","state_id":"2444"},
    {"city_id":"28545","city_name":"Anenecuilco","state_id":"2444"},
    {"city_id":"28546","city_name":"Apatlaco","state_id":"2444"},
    {"city_id":"28547","city_name":"Atlatlahucan","state_id":"2444"},
    {"city_id":"28548","city_name":"Axochiapan","state_id":"2444"},
    {"city_id":"28549","city_name":"Ayala","state_id":"2444"},
    {"city_id":"28550","city_name":"Calera Chica","state_id":"2444"},
    {"city_id":"28551","city_name":"Chiconcuac","state_id":"2444"},
    {"city_id":"28552","city_name":"Coatetelco","state_id":"2444"},
    {"city_id":"28553","city_name":"Cocoyoc","state_id":"2444"},
    {"city_id":"28554","city_name":"Cuautla","state_id":"2444"},
    {"city_id":"28555","city_name":"Cuernavaca","state_id":"2444"},
    {"city_id":"28556","city_name":"Emiliano Zapata","state_id":"2444"},
    {"city_id":"28557","city_name":"Higueron","state_id":"2444"},
    {"city_id":"28558","city_name":"Hueyapan","state_id":"2444"},
    {"city_id":"28559","city_name":"Huiztilac","state_id":"2444"},
    {"city_id":"28560","city_name":"Independencia","state_id":"2444"},
    {"city_id":"28561","city_name":"Jantetelco","state_id":"2444"},
    {"city_id":"28562","city_name":"Jiutepec","state_id":"2444"},
    {"city_id":"28563","city_name":"Jojutla","state_id":"2444"},
    {"city_id":"28564","city_name":"Jonacatepec","state_id":"2444"},
    {"city_id":"28565","city_name":"Juan Morales","state_id":"2444"},
    {"city_id":"28566","city_name":"La Joya","state_id":"2444"},
    {"city_id":"28567","city_name":"Los Arcos","state_id":"2444"},
    {"city_id":"28568","city_name":"Mazatepec","state_id":"2444"},
    {"city_id":"28569","city_name":"Miacatlan","state_id":"2444"},
    {"city_id":"28570","city_name":"Oaxtepec","state_id":"2444"},
    {"city_id":"28571","city_name":"Ocuituco","state_id":"2444"},
    {"city_id":"28572","city_name":"Pedro Amaro","state_id":"2444"},
    {"city_id":"28573","city_name":"Progreso","state_id":"2444"},
    {"city_id":"28574","city_name":"Puente de Ixtla","state_id":"2444"},
    {"city_id":"28575","city_name":"San Jose Vista Hermosa","state_id":"2444"},
    {"city_id":"28576","city_name":"San Nicolas Galeana","state_id":"2444"},
    {"city_id":"28577","city_name":"Santa Catarina","state_id":"2444"},
    {"city_id":"28578","city_name":"Santa Rosa Treinta","state_id":"2444"},
    {"city_id":"28579","city_name":"Tehuixtla","state_id":"2444"},
    {"city_id":"28580","city_name":"Telixtac","state_id":"2444"},
    {"city_id":"28581","city_name":"Temixco","state_id":"2444"},
    {"city_id":"28582","city_name":"Temoac","state_id":"2444"},
    {"city_id":"28583","city_name":"Tenextepango","state_id":"2444"},
    {"city_id":"28584","city_name":"Tepalcingo","state_id":"2444"},
    {"city_id":"28585","city_name":"Tepoztlan","state_id":"2444"},
    {"city_id":"28586","city_name":"Tetecala","state_id":"2444"},
    {"city_id":"28587","city_name":"Tetela del Volcan","state_id":"2444"},
    {"city_id":"28588","city_name":"Tilzapotla","state_id":"2444"},
    {"city_id":"28589","city_name":"Tlacotepec","state_id":"2444"},
    {"city_id":"28590","city_name":"Tlalnepantla","state_id":"2444"},
    {"city_id":"28591","city_name":"Tlaltizapan","state_id":"2444"},
    {"city_id":"28592","city_name":"Tlaquiltenango","state_id":"2444"},
    {"city_id":"28593","city_name":"Tlatenchi","state_id":"2444"},
    {"city_id":"28594","city_name":"Tlayacapan","state_id":"2444"},
    {"city_id":"28595","city_name":"Totolapan","state_id":"2444"},
    {"city_id":"28596","city_name":"Tres Marias","state_id":"2444"},
    {"city_id":"28597","city_name":"Unidad Habitacional Jose Maria","state_id":"2444"},
    {"city_id":"28598","city_name":"Xochitepec","state_id":"2444"},
    {"city_id":"28599","city_name":"Xoxocotla","state_id":"2444"},
    {"city_id":"28600","city_name":"Yautepec","state_id":"2444"},
    {"city_id":"28601","city_name":"Yecapixtla","state_id":"2444"},
    {"city_id":"28602","city_name":"Zacatepec","state_id":"2444"},
    {"city_id":"28603","city_name":"Zacualpan","state_id":"2444"},
    {"city_id":"28604","city_name":"Acaponeta","state_id":"2445"},
    {"city_id":"28605","city_name":"Ahuacatlan","state_id":"2445"},
    {"city_id":"28606","city_name":"Amatlan","state_id":"2445"},
    {"city_id":"28607","city_name":"Aztatan","state_id":"2445"},
    {"city_id":"28608","city_name":"Bucerias","state_id":"2445"},
    {"city_id":"28609","city_name":"Carmen","state_id":"2445"},
    {"city_id":"28610","city_name":"Cerralvo","state_id":"2445"},
    {"city_id":"28611","city_name":"Compostela","state_id":"2445"},
    {"city_id":"28612","city_name":"Hidalgo","state_id":"2445"},
    {"city_id":"28613","city_name":"Ixcuintla","state_id":"2445"},
    {"city_id":"28614","city_name":"Ixtlan del Rio","state_id":"2445"},
    {"city_id":"28615","city_name":"Jala","state_id":"2445"},
    {"city_id":"28616","city_name":"Jalcocotan","state_id":"2445"},
    {"city_id":"28617","city_name":"La Penita de Jaltemba","state_id":"2445"},
    {"city_id":"28618","city_name":"La Presa","state_id":"2445"},
    {"city_id":"28619","city_name":"Lagunillas","state_id":"2445"},
    {"city_id":"28620","city_name":"Las Jarretaderas","state_id":"2445"},
    {"city_id":"28621","city_name":"Las Varas","state_id":"2445"},
    {"city_id":"28622","city_name":"Puga","state_id":"2445"},
    {"city_id":"28623","city_name":"Rosamorada","state_id":"2445"},
    {"city_id":"28624","city_name":"Ruiz","state_id":"2445"},
    {"city_id":"28625","city_name":"San Blas","state_id":"2445"},
    {"city_id":"28626","city_name":"San Jose del Valle","state_id":"2445"},
    {"city_id":"28627","city_name":"San Juan de Abajo","state_id":"2445"},
    {"city_id":"28628","city_name":"San Vicente","state_id":"2445"},
    {"city_id":"28629","city_name":"Santa Maria del Oro","state_id":"2445"},
    {"city_id":"28630","city_name":"Tecuala","state_id":"2445"},
    {"city_id":"28631","city_name":"Tepic","state_id":"2445"},
    {"city_id":"28632","city_name":"Tuxpan","state_id":"2445"},
    {"city_id":"28633","city_name":"Valle de Banderas","state_id":"2445"},
    {"city_id":"28634","city_name":"Xalisco","state_id":"2445"},
    {"city_id":"28635","city_name":"Yago","state_id":"2445"},
    {"city_id":"28636","city_name":"Zacualpan","state_id":"2445"},
    {"city_id":"28637","city_name":"Acatlan","state_id":"2447"},
    {"city_id":"28638","city_name":"Amatlan","state_id":"2447"},
    {"city_id":"28639","city_name":"Amilpas","state_id":"2447"},
    {"city_id":"28640","city_name":"Amuzgos","state_id":"2447"},
    {"city_id":"28641","city_name":"Asuncion Ixtaltepec","state_id":"2447"},
    {"city_id":"28642","city_name":"Asuncion Nochixtlan","state_id":"2447"},
    {"city_id":"28643","city_name":"Asuncion Ocotlan","state_id":"2447"},
    {"city_id":"28644","city_name":"Atempa","state_id":"2447"},
    {"city_id":"28645","city_name":"Atzompa","state_id":"2447"},
    {"city_id":"28646","city_name":"Ayautla","state_id":"2447"},
    {"city_id":"28647","city_name":"Ayoquezco","state_id":"2447"},
    {"city_id":"28648","city_name":"Ayotzintepec","state_id":"2447"},
    {"city_id":"28649","city_name":"Bajos de Chila","state_id":"2447"},
    {"city_id":"28650","city_name":"Brisas de Zicatela","state_id":"2447"},
    {"city_id":"28651","city_name":"Cacahuatepec","state_id":"2447"},
    {"city_id":"28652","city_name":"Cacaotepec","state_id":"2447"},
    {"city_id":"28653","city_name":"Chahuites","state_id":"2447"},
    {"city_id":"28654","city_name":"Chichicapam","state_id":"2447"},
    {"city_id":"28655","city_name":"Chiltepec","state_id":"2447"},
    {"city_id":"28656","city_name":"Cienaga de Zimatlan","state_id":"2447"},
    {"city_id":"28657","city_name":"Coatlan","state_id":"2447"},
    {"city_id":"28658","city_name":"Comitancillo","state_id":"2447"},
    {"city_id":"28659","city_name":"Cosolapa","state_id":"2447"},
    {"city_id":"28660","city_name":"Coyotepec","state_id":"2447"},
    {"city_id":"28661","city_name":"Crucecita","state_id":"2447"},
    {"city_id":"28662","city_name":"Cuicatlan","state_id":"2447"},
    {"city_id":"28663","city_name":"Cuilapam","state_id":"2447"},
    {"city_id":"28664","city_name":"Ejutla de Crespo","state_id":"2447"},
    {"city_id":"28665","city_name":"El Espinal","state_id":"2447"},
    {"city_id":"28666","city_name":"Etla","state_id":"2447"},
    {"city_id":"28667","city_name":"Fraccionamiento el Rosario","state_id":"2447"},
    {"city_id":"28668","city_name":"Guelavia","state_id":"2447"},
    {"city_id":"28669","city_name":"Guichicovi","state_id":"2447"},
    {"city_id":"28670","city_name":"Huajuapan","state_id":"2447"},
    {"city_id":"28671","city_name":"Huatulco","state_id":"2447"},
    {"city_id":"28672","city_name":"Huautla","state_id":"2447"},
    {"city_id":"28673","city_name":"Huaxpaltepec","state_id":"2447"},
    {"city_id":"28674","city_name":"Huayapam","state_id":"2447"},
    {"city_id":"28675","city_name":"Huazolotitlan","state_id":"2447"},
    {"city_id":"28676","city_name":"Huitzo","state_id":"2447"},
    {"city_id":"28677","city_name":"Huixtepec","state_id":"2447"},
    {"city_id":"28678","city_name":"Ingenio","state_id":"2447"},
    {"city_id":"28679","city_name":"Ixcatlan","state_id":"2447"},
    {"city_id":"28680","city_name":"Ixhuatan","state_id":"2447"},
    {"city_id":"28681","city_name":"Ixtepec","state_id":"2447"},
    {"city_id":"28682","city_name":"Jalapa","state_id":"2447"},
    {"city_id":"28683","city_name":"Jamiltepec","state_id":"2447"},
    {"city_id":"28684","city_name":"Jicayan","state_id":"2447"},
    {"city_id":"28685","city_name":"Juchitan","state_id":"2447"},
    {"city_id":"28686","city_name":"Juquila","state_id":"2447"},
    {"city_id":"28687","city_name":"Juxtlahuaca","state_id":"2447"},
    {"city_id":"28688","city_name":"Loma Bonita","state_id":"2447"},
    {"city_id":"28689","city_name":"Magdalena Teitipac","state_id":"2447"},
    {"city_id":"28690","city_name":"Magdalena Tequisistlan","state_id":"2447"},
    {"city_id":"28691","city_name":"Matatlan","state_id":"2447"},
    {"city_id":"28692","city_name":"Matias Romero","state_id":"2447"},
    {"city_id":"28693","city_name":"Mechoacan","state_id":"2447"},
    {"city_id":"28694","city_name":"Miahuatlan","state_id":"2447"},
    {"city_id":"28695","city_name":"Mitla","state_id":"2447"},
    {"city_id":"28696","city_name":"Mixtepec","state_id":"2447"},
    {"city_id":"28697","city_name":"Mixtequilla","state_id":"2447"},
    {"city_id":"28698","city_name":"Nazareno Etla","state_id":"2447"},
    {"city_id":"28699","city_name":"Niltepec","state_id":"2447"},
    {"city_id":"28700","city_name":"Oaxaca","state_id":"2447"},
    {"city_id":"28701","city_name":"Ocotepec","state_id":"2447"},
    {"city_id":"28702","city_name":"Ocotlan","state_id":"2447"},
    {"city_id":"28703","city_name":"Ojitlan","state_id":"2447"},
    {"city_id":"28704","city_name":"Palomares","state_id":"2447"},
    {"city_id":"28705","city_name":"Panixtlahuaca","state_id":"2447"},
    {"city_id":"28706","city_name":"Petapa","state_id":"2447"},
    {"city_id":"28707","city_name":"Pinotepa Nacional","state_id":"2447"},
    {"city_id":"28708","city_name":"Pinotepa de Don Luis","state_id":"2447"},
    {"city_id":"28709","city_name":"Pochutla","state_id":"2447"},
    {"city_id":"28710","city_name":"Puerto Escondido","state_id":"2447"},
    {"city_id":"28711","city_name":"Putla","state_id":"2447"},
    {"city_id":"28712","city_name":"Quetzaltepec","state_id":"2447"},
    {"city_id":"28713","city_name":"Rincon Viejo","state_id":"2447"},
    {"city_id":"28714","city_name":"Rio Grande","state_id":"2447"},
    {"city_id":"28715","city_name":"Salina Cruz","state_id":"2447"},
    {"city_id":"28716","city_name":"San Agustin de las Juntas","state_id":"2447"},
    {"city_id":"28717","city_name":"San Antonio Castillo Velasco","state_id":"2447"},
    {"city_id":"28718","city_name":"San Antonio de la Cal","state_id":"2447"},
    {"city_id":"28719","city_name":"San Dionisio del Mar","state_id":"2447"},
    {"city_id":"28720","city_name":"San Francisco del Mar","state_id":"2447"},
    {"city_id":"28721","city_name":"San Jose del Progreso","state_id":"2447"},
    {"city_id":"28722","city_name":"San Juan Colorado","state_id":"2447"},
    {"city_id":"28723","city_name":"San Mateo del Mar","state_id":"2447"},
    {"city_id":"28724","city_name":"Santa Lucia del Camino","state_id":"2447"},
    {"city_id":"28725","city_name":"Santa Maria del Tule","state_id":"2447"},
    {"city_id":"28726","city_name":"Santiago Apostol","state_id":"2447"},
    {"city_id":"28727","city_name":"Santos Reyes Nopala","state_id":"2447"},
    {"city_id":"28728","city_name":"Soyaltepec","state_id":"2447"},
    {"city_id":"28729","city_name":"Suchilquitongo","state_id":"2447"},
    {"city_id":"28730","city_name":"Tamazulapam","state_id":"2447"},
    {"city_id":"28731","city_name":"Tapanatepec","state_id":"2447"},
    {"city_id":"28732","city_name":"Tehuantepec","state_id":"2447"},
    {"city_id":"28733","city_name":"Teitipac","state_id":"2447"},
    {"city_id":"28734","city_name":"Telixtlahuaca","state_id":"2447"},
    {"city_id":"28735","city_name":"Teotitlan","state_id":"2447"},
    {"city_id":"28736","city_name":"Teotitlan den Valle","state_id":"2447"},
    {"city_id":"28737","city_name":"Tilquiapam","state_id":"2447"},
    {"city_id":"28738","city_name":"Tlacolula","state_id":"2447"},
    {"city_id":"28739","city_name":"Tlalixtac","state_id":"2447"},
    {"city_id":"28740","city_name":"Tlaxiaco","state_id":"2447"},
    {"city_id":"28741","city_name":"Tutla","state_id":"2447"},
    {"city_id":"28742","city_name":"Tuxtepec","state_id":"2447"},
    {"city_id":"28743","city_name":"Union Hidalgo","state_id":"2447"},
    {"city_id":"28744","city_name":"Usila","state_id":"2447"},
    {"city_id":"28745","city_name":"Valle Nacional","state_id":"2447"},
    {"city_id":"28746","city_name":"Vicente Camalote","state_id":"2447"},
    {"city_id":"28747","city_name":"Vicente Guerrero","state_id":"2447"},
    {"city_id":"28748","city_name":"Xadani","state_id":"2447"},
    {"city_id":"28749","city_name":"Xitla","state_id":"2447"},
    {"city_id":"28750","city_name":"Xoxocotlan","state_id":"2447"},
    {"city_id":"28751","city_name":"Yaitepec","state_id":"2447"},
    {"city_id":"28752","city_name":"Yatareni","state_id":"2447"},
    {"city_id":"28753","city_name":"Zaachila","state_id":"2447"},
    {"city_id":"28754","city_name":"Zacatepec","state_id":"2447"},
    {"city_id":"28755","city_name":"Zanatepec","state_id":"2447"},
    {"city_id":"28756","city_name":"Zimatlan","state_id":"2447"},
    {"city_id":"28757","city_name":"Acajete","state_id":"2448"},
    {"city_id":"28758","city_name":"Acateno","state_id":"2448"},
    {"city_id":"28759","city_name":"Acatlan","state_id":"2448"},
    {"city_id":"28760","city_name":"Acatzingo","state_id":"2448"},
    {"city_id":"28761","city_name":"Actipan","state_id":"2448"},
    {"city_id":"28762","city_name":"Acuexcomac","state_id":"2448"},
    {"city_id":"28763","city_name":"Ahuatempan","state_id":"2448"},
    {"city_id":"28764","city_name":"Ajalpan","state_id":"2448"},
    {"city_id":"28765","city_name":"Aljojuca","state_id":"2448"},
    {"city_id":"28766","city_name":"Almecatla","state_id":"2448"},
    {"city_id":"28767","city_name":"Alseseca","state_id":"2448"},
    {"city_id":"28768","city_name":"Altepexi","state_id":"2448"},
    {"city_id":"28769","city_name":"Amecac","state_id":"2448"},
    {"city_id":"28770","city_name":"Amozoc","state_id":"2448"},
    {"city_id":"28771","city_name":"Aparicio","state_id":"2448"},
    {"city_id":"28772","city_name":"Atempan","state_id":"2448"},
    {"city_id":"28773","city_name":"Atencingo","state_id":"2448"},
    {"city_id":"28774","city_name":"Atlixco","state_id":"2448"},
    {"city_id":"28775","city_name":"Atoyatempan","state_id":"2448"},
    {"city_id":"28776","city_name":"Atzitzintla","state_id":"2448"},
    {"city_id":"28777","city_name":"Atzompa","state_id":"2448"},
    {"city_id":"28778","city_name":"Ayutla","state_id":"2448"},
    {"city_id":"28779","city_name":"Azumiatla","state_id":"2448"},
    {"city_id":"28780","city_name":"Benito Juarez","state_id":"2448"},
    {"city_id":"28781","city_name":"Buenos Aires","state_id":"2448"},
    {"city_id":"28782","city_name":"Cacaloxuchitl","state_id":"2448"},
    {"city_id":"28783","city_name":"Calipan","state_id":"2448"},
    {"city_id":"28784","city_name":"Calmeca","state_id":"2448"},
    {"city_id":"28785","city_name":"Calpan","state_id":"2448"},
    {"city_id":"28786","city_name":"Caltenco","state_id":"2448"},
    {"city_id":"28787","city_name":"Canada","state_id":"2448"},
    {"city_id":"28788","city_name":"Canoa","state_id":"2448"},
    {"city_id":"28789","city_name":"Caxhuacan","state_id":"2448"},
    {"city_id":"28790","city_name":"Chalchihuapan","state_id":"2448"},
    {"city_id":"28791","city_name":"Chapulco","state_id":"2448"},
    {"city_id":"28792","city_name":"Chautla","state_id":"2448"},
    {"city_id":"28793","city_name":"Chiapa","state_id":"2448"},
    {"city_id":"28794","city_name":"Chiautla","state_id":"2448"},
    {"city_id":"28795","city_name":"Chiautzingo","state_id":"2448"},
    {"city_id":"28796","city_name":"Chiconquiac","state_id":"2448"},
    {"city_id":"28797","city_name":"Chietla","state_id":"2448"},
    {"city_id":"28798","city_name":"Chignahuapan","state_id":"2448"},
    {"city_id":"28799","city_name":"Chignautla","state_id":"2448"},
    {"city_id":"28800","city_name":"Chilac","state_id":"2448"},
    {"city_id":"28801","city_name":"Chilchotla","state_id":"2448"},
    {"city_id":"28802","city_name":"Cholula","state_id":"2448"},
    {"city_id":"28803","city_name":"Citlaltepetl","state_id":"2448"},
    {"city_id":"28804","city_name":"Coatepec","state_id":"2448"},
    {"city_id":"28805","city_name":"Coronango","state_id":"2448"},
    {"city_id":"28806","city_name":"Coxcatlan","state_id":"2448"},
    {"city_id":"28807","city_name":"Coyula","state_id":"2448"},
    {"city_id":"28808","city_name":"Cuacnopalan","state_id":"2448"},
    {"city_id":"28809","city_name":"Cuanala","state_id":"2448"},
    {"city_id":"28810","city_name":"Cuapiaxtla","state_id":"2448"},
    {"city_id":"28811","city_name":"Cuautlancingo","state_id":"2448"},
    {"city_id":"28812","city_name":"Cuayucatepec","state_id":"2448"},
    {"city_id":"28813","city_name":"Cuetzalan","state_id":"2448"},
    {"city_id":"28814","city_name":"Domingo Arenas","state_id":"2448"},
    {"city_id":"28815","city_name":"Escape","state_id":"2448"},
    {"city_id":"28816","city_name":"Esperanza","state_id":"2448"},
    {"city_id":"28817","city_name":"Guadalupe Victoria","state_id":"2448"},
    {"city_id":"28818","city_name":"Huaquechula","state_id":"2448"},
    {"city_id":"28819","city_name":"Huauchinango","state_id":"2448"},
    {"city_id":"28820","city_name":"Huehuetlan","state_id":"2448"},
    {"city_id":"28821","city_name":"Huejotzingo","state_id":"2448"},
    {"city_id":"28822","city_name":"Hueyapan","state_id":"2448"},
    {"city_id":"28823","city_name":"Hueyotlipan","state_id":"2448"},
    {"city_id":"28824","city_name":"Hueytamalco","state_id":"2448"},
    {"city_id":"28825","city_name":"Huixcolotla","state_id":"2448"},
    {"city_id":"28826","city_name":"Ixcaquixtla","state_id":"2448"},
    {"city_id":"28827","city_name":"Ixtiyucan","state_id":"2448"},
    {"city_id":"28828","city_name":"Izucar","state_id":"2448"},
    {"city_id":"28829","city_name":"Jolalpan","state_id":"2448"},
    {"city_id":"28830","city_name":"La Ceiba","state_id":"2448"},
    {"city_id":"28831","city_name":"La Galarza","state_id":"2448"},
    {"city_id":"28832","city_name":"La Resureccion","state_id":"2448"},
    {"city_id":"28833","city_name":"Lazaro Cardenas","state_id":"2448"},
    {"city_id":"28834","city_name":"Libres","state_id":"2448"},
    {"city_id":"28835","city_name":"Los Ranchos","state_id":"2448"},
    {"city_id":"28836","city_name":"Los Reyes de Juarez","state_id":"2448"},
    {"city_id":"28837","city_name":"Malacatepec","state_id":"2448"},
    {"city_id":"28838","city_name":"Metlaltoyuca","state_id":"2448"},
    {"city_id":"28839","city_name":"Miahuatlan","state_id":"2448"},
    {"city_id":"28840","city_name":"Mihuacan","state_id":"2448"},
    {"city_id":"28841","city_name":"Momoxpan","state_id":"2448"},
    {"city_id":"28842","city_name":"Moyotzingo","state_id":"2448"},
    {"city_id":"28843","city_name":"Nealtican","state_id":"2448"},
    {"city_id":"28844","city_name":"Necaxa","state_id":"2448"},
    {"city_id":"28845","city_name":"Nopalucan","state_id":"2448"},
    {"city_id":"28846","city_name":"Ocotitlan","state_id":"2448"},
    {"city_id":"28847","city_name":"Ocotlan","state_id":"2448"},
    {"city_id":"28848","city_name":"Ocoyucan","state_id":"2448"},
    {"city_id":"28849","city_name":"Oriental","state_id":"2448"},
    {"city_id":"28850","city_name":"Pahuatlan","state_id":"2448"},
    {"city_id":"28851","city_name":"Palmar de Bravo","state_id":"2448"},
    {"city_id":"28852","city_name":"Puebla","state_id":"2448"},
    {"city_id":"28853","city_name":"Quecholac","state_id":"2448"},
    {"city_id":"28854","city_name":"Raboso","state_id":"2448"},
    {"city_id":"28855","city_name":"Rafael Lara Grajales","state_id":"2448"},
    {"city_id":"28856","city_name":"San Gabriel Casa Blanca","state_id":"2448"},
    {"city_id":"28857","city_name":"San Lucas el Grande","state_id":"2448"},
    {"city_id":"28858","city_name":"San Salvador el Seco","state_id":"2448"},
    {"city_id":"28859","city_name":"San Sebastian Villanueva","state_id":"2448"},
    {"city_id":"28860","city_name":"Sanctorum","state_id":"2448"},
    {"city_id":"28861","city_name":"Santa Maria la Alta","state_id":"2448"},
    {"city_id":"28862","city_name":"Serdan","state_id":"2448"},
    {"city_id":"28863","city_name":"Soltepec","state_id":"2448"},
    {"city_id":"28864","city_name":"Tatoxcac","state_id":"2448"},
    {"city_id":"28865","city_name":"Tecali","state_id":"2448"},
    {"city_id":"28866","city_name":"Tecamachalco","state_id":"2448"},
    {"city_id":"28867","city_name":"Tehuacan","state_id":"2448"},
    {"city_id":"28868","city_name":"Tehuitzingo","state_id":"2448"},
    {"city_id":"28869","city_name":"Tenango","state_id":"2448"},
    {"city_id":"28870","city_name":"Tenango de las Flores","state_id":"2448"},
    {"city_id":"28871","city_name":"Tenextatiloyan","state_id":"2448"},
    {"city_id":"28872","city_name":"Teontepec","state_id":"2448"},
    {"city_id":"28873","city_name":"Teopantlan","state_id":"2448"},
    {"city_id":"28874","city_name":"Teotlalcingo","state_id":"2448"},
    {"city_id":"28875","city_name":"Tepango","state_id":"2448"},
    {"city_id":"28876","city_name":"Tepatlaxco","state_id":"2448"},
    {"city_id":"28877","city_name":"Tepeaca","state_id":"2448"},
    {"city_id":"28878","city_name":"Tepeojuma","state_id":"2448"},
    {"city_id":"28879","city_name":"Tepexi","state_id":"2448"},
    {"city_id":"28880","city_name":"Tepulco","state_id":"2448"},
    {"city_id":"28881","city_name":"Tetela Morelos","state_id":"2448"},
    {"city_id":"28882","city_name":"Tetela de Ocampo","state_id":"2448"},
    {"city_id":"28883","city_name":"Teteles","state_id":"2448"},
    {"city_id":"28884","city_name":"Tetzoyocan","state_id":"2448"},
    {"city_id":"28885","city_name":"Texmelucan","state_id":"2448"},
    {"city_id":"28886","city_name":"Teziutlan","state_id":"2448"},
    {"city_id":"28887","city_name":"Tianguismanalco","state_id":"2448"},
    {"city_id":"28888","city_name":"Tlachichuca","state_id":"2448"},
    {"city_id":"28889","city_name":"Tlacotepec","state_id":"2448"},
    {"city_id":"28890","city_name":"Tlacoyalco","state_id":"2448"},
    {"city_id":"28891","city_name":"Tlahuapan","state_id":"2448"},
    {"city_id":"28892","city_name":"Tlaixpan","state_id":"2448"},
    {"city_id":"28893","city_name":"Tlalancaleca","state_id":"2448"},
    {"city_id":"28894","city_name":"Tlaltenango","state_id":"2448"},
    {"city_id":"28895","city_name":"Tlanalapan","state_id":"2448"},
    {"city_id":"28896","city_name":"Tlancualpican","state_id":"2448"},
    {"city_id":"28897","city_name":"Tlanepantla","state_id":"2448"},
    {"city_id":"28898","city_name":"Tlapanala","state_id":"2448"},
    {"city_id":"28899","city_name":"Tlatlauquitepec","state_id":"2448"},
    {"city_id":"28900","city_name":"Tlaxcalancingo","state_id":"2448"},
    {"city_id":"28901","city_name":"Tlaxco","state_id":"2448"},
    {"city_id":"28902","city_name":"Tochapan","state_id":"2448"},
    {"city_id":"28903","city_name":"Tochimilco","state_id":"2448"},
    {"city_id":"28904","city_name":"Tochtepec","state_id":"2448"},
    {"city_id":"28905","city_name":"Tulcingo","state_id":"2448"},
    {"city_id":"28906","city_name":"Tuxco","state_id":"2448"},
    {"city_id":"28907","city_name":"Venustiano Carranza","state_id":"2448"},
    {"city_id":"28908","city_name":"Xalmimilulco","state_id":"2448"},
    {"city_id":"28909","city_name":"Xaltepec","state_id":"2448"},
    {"city_id":"28910","city_name":"Xicotepec","state_id":"2448"},
    {"city_id":"28911","city_name":"Xiutetelco","state_id":"2448"},
    {"city_id":"28912","city_name":"Xochiltenango","state_id":"2448"},
    {"city_id":"28913","city_name":"Xochitlan","state_id":"2448"},
    {"city_id":"28914","city_name":"Xonatepec","state_id":"2448"},
    {"city_id":"28915","city_name":"Xoxtla","state_id":"2448"},
    {"city_id":"28916","city_name":"Yaonahuac","state_id":"2448"},
    {"city_id":"28917","city_name":"Yehualtepec","state_id":"2448"},
    {"city_id":"28918","city_name":"Zacapechpan","state_id":"2448"},
    {"city_id":"28919","city_name":"Zacapoaxtla","state_id":"2448"},
    {"city_id":"28920","city_name":"Zacatepec","state_id":"2448"},
    {"city_id":"28921","city_name":"Zacatlan","state_id":"2448"},
    {"city_id":"28922","city_name":"Zaragoza","state_id":"2448"},
    {"city_id":"28923","city_name":"Zinacatepec","state_id":"2448"},
    {"city_id":"28924","city_name":"Zongozotla","state_id":"2448"},
    {"city_id":"28925","city_name":"Zozutla","state_id":"2448"},
    {"city_id":"28926","city_name":"Ajuchitlan","state_id":"2449"},
    {"city_id":"28927","city_name":"Amazcala","state_id":"2449"},
    {"city_id":"28928","city_name":"Amealco","state_id":"2449"},
    {"city_id":"28929","city_name":"Cadereyta","state_id":"2449"},
    {"city_id":"28930","city_name":"Candiles","state_id":"2449"},
    {"city_id":"28931","city_name":"Colon","state_id":"2449"},
    {"city_id":"28932","city_name":"El Colorado","state_id":"2449"},
    {"city_id":"28933","city_name":"El Pueblito","state_id":"2449"},
    {"city_id":"28934","city_name":"El Sauz","state_id":"2449"},
    {"city_id":"28935","city_name":"Ezequiel Montes","state_id":"2449"},
    {"city_id":"28936","city_name":"Huimilpan","state_id":"2449"},
    {"city_id":"28937","city_name":"Jalpan","state_id":"2449"},
    {"city_id":"28938","city_name":"Jauregui","state_id":"2449"},
    {"city_id":"28939","city_name":"La Canada","state_id":"2449"},
    {"city_id":"28940","city_name":"La Estancia","state_id":"2449"},
    {"city_id":"28941","city_name":"La Lira","state_id":"2449"},
    {"city_id":"28942","city_name":"La Llave","state_id":"2449"},
    {"city_id":"28943","city_name":"Paso de Mata","state_id":"2449"},
    {"city_id":"28944","city_name":"Pedro Escobedo","state_id":"2449"},
    {"city_id":"28945","city_name":"Queretaro","state_id":"2449"},
    {"city_id":"28946","city_name":"San Jose de los Olvera","state_id":"2449"},
    {"city_id":"28947","city_name":"San Jose el Alto","state_id":"2449"},
    {"city_id":"28948","city_name":"San Juan del Rio","state_id":"2449"},
    {"city_id":"28949","city_name":"San Nicolas","state_id":"2449"},
    {"city_id":"28950","city_name":"San Pedro Martir","state_id":"2449"},
    {"city_id":"28951","city_name":"Santa Maria Magdalena","state_id":"2449"},
    {"city_id":"28952","city_name":"Tequisquiapan","state_id":"2449"},
    {"city_id":"28953","city_name":"Tlacote el Bajo","state_id":"2449"},
    {"city_id":"28954","city_name":"Adolfo Ruiz Cortines","state_id":"2452"},
    {"city_id":"28955","city_name":"Agua Verde","state_id":"2452"},
    {"city_id":"28956","city_name":"Ahome","state_id":"2452"},
    {"city_id":"28957","city_name":"Angostura","state_id":"2452"},
    {"city_id":"28958","city_name":"Badiraguato","state_id":"2452"},
    {"city_id":"28959","city_name":"Bagojo","state_id":"2452"},
    {"city_id":"28960","city_name":"Benito Juarez","state_id":"2452"},
    {"city_id":"28961","city_name":"Campo Gobierno","state_id":"2452"},
    {"city_id":"28962","city_name":"Choix","state_id":"2452"},
    {"city_id":"28963","city_name":"Compuertas","state_id":"2452"},
    {"city_id":"28964","city_name":"Concordia","state_id":"2452"},
    {"city_id":"28965","city_name":"Constancia","state_id":"2452"},
    {"city_id":"28966","city_name":"Cosala","state_id":"2452"},
    {"city_id":"28967","city_name":"Costa Rica","state_id":"2452"},
    {"city_id":"28968","city_name":"Culiacan","state_id":"2452"},
    {"city_id":"28969","city_name":"Culiacancito","state_id":"2452"},
    {"city_id":"28970","city_name":"El Burrion","state_id":"2452"},
    {"city_id":"28971","city_name":"El Carrizo","state_id":"2452"},
    {"city_id":"28972","city_name":"El Diez","state_id":"2452"},
    {"city_id":"28973","city_name":"El Estero","state_id":"2452"},
    {"city_id":"28974","city_name":"El Fuerte","state_id":"2452"},
    {"city_id":"28975","city_name":"El Rosario","state_id":"2452"},
    {"city_id":"28976","city_name":"El Tamarindo","state_id":"2452"},
    {"city_id":"28977","city_name":"Eldorado","state_id":"2452"},
    {"city_id":"28978","city_name":"Escuinapa","state_id":"2452"},
    {"city_id":"28979","city_name":"Estacion Naranjo","state_id":"2452"},
    {"city_id":"28980","city_name":"Estancia Bamoa","state_id":"2452"},
    {"city_id":"28981","city_name":"Gabriel Leyva Solano","state_id":"2452"},
    {"city_id":"28982","city_name":"Guamuchil","state_id":"2452"},
    {"city_id":"28983","city_name":"Guasave","state_id":"2452"},
    {"city_id":"28984","city_name":"Higuera","state_id":"2452"},
    {"city_id":"28985","city_name":"Isla del Bosque","state_id":"2452"},
    {"city_id":"28986","city_name":"Jahuara Segundo","state_id":"2452"},
    {"city_id":"28987","city_name":"Juan Jose Rios","state_id":"2452"},
    {"city_id":"28988","city_name":"La Cruz","state_id":"2452"},
    {"city_id":"28989","city_name":"La Palma","state_id":"2452"},
    {"city_id":"28990","city_name":"La Reforma","state_id":"2452"},
    {"city_id":"28991","city_name":"La Trinidad","state_id":"2452"},
    {"city_id":"28992","city_name":"Los Mochis","state_id":"2452"},
    {"city_id":"28993","city_name":"Mazatlan","state_id":"2452"},
    {"city_id":"28994","city_name":"Mochicahui","state_id":"2452"},
    {"city_id":"28995","city_name":"Mocorito","state_id":"2452"},
    {"city_id":"28996","city_name":"Navolato","state_id":"2452"},
    {"city_id":"28997","city_name":"Pericos","state_id":"2452"},
    {"city_id":"28998","city_name":"Pueblos Unidos","state_id":"2452"},
    {"city_id":"28999","city_name":"Quila","state_id":"2452"},
    {"city_id":"29000","city_name":"San Blas","state_id":"2452"},
    {"city_id":"29001","city_name":"San Ignacio","state_id":"2452"},
    {"city_id":"29002","city_name":"Sinaloa","state_id":"2452"},
    {"city_id":"29003","city_name":"Teacapan","state_id":"2452"},
    {"city_id":"29004","city_name":"Topolobampo","state_id":"2452"},
    {"city_id":"29005","city_name":"Villa Union","state_id":"2452"},
    {"city_id":"29006","city_name":"Zapotitlan","state_id":"2452"},
    {"city_id":"29007","city_name":"Agua Prieta","state_id":"2453"},
    {"city_id":"29008","city_name":"Alamos","state_id":"2453"},
    {"city_id":"29009","city_name":"Altar","state_id":"2453"},
    {"city_id":"29010","city_name":"Bacobampo","state_id":"2453"},
    {"city_id":"29011","city_name":"Bacum","state_id":"2453"},
    {"city_id":"29012","city_name":"Bahia de Kino","state_id":"2453"},
    {"city_id":"29013","city_name":"Benjamin Hill","state_id":"2453"},
    {"city_id":"29014","city_name":"Caborca","state_id":"2453"},
    {"city_id":"29015","city_name":"Campo Sesenta","state_id":"2453"},
    {"city_id":"29016","city_name":"Cananea","state_id":"2453"},
    {"city_id":"29017","city_name":"Carbo","state_id":"2453"},
    {"city_id":"29018","city_name":"Cocorit","state_id":"2453"},
    {"city_id":"29019","city_name":"Cumpas","state_id":"2453"},
    {"city_id":"29020","city_name":"Empalme","state_id":"2453"},
    {"city_id":"29021","city_name":"Esperanza","state_id":"2453"},
    {"city_id":"29022","city_name":"Esqueda","state_id":"2453"},
    {"city_id":"29023","city_name":"Etchojoa","state_id":"2453"},
    {"city_id":"29024","city_name":"Etchoropo","state_id":"2453"},
    {"city_id":"29025","city_name":"Guaymas","state_id":"2453"},
    {"city_id":"29026","city_name":"Hermosillo","state_id":"2453"},
    {"city_id":"29027","city_name":"Huatabampo","state_id":"2453"},
    {"city_id":"29028","city_name":"Imuris","state_id":"2453"},
    {"city_id":"29029","city_name":"Juarez","state_id":"2453"},
    {"city_id":"29030","city_name":"Kino","state_id":"2453"},
    {"city_id":"29031","city_name":"La Doce","state_id":"2453"},
    {"city_id":"29032","city_name":"La Union","state_id":"2453"},
    {"city_id":"29033","city_name":"Moctezuma","state_id":"2453"},
    {"city_id":"29034","city_name":"Naco","state_id":"2453"},
    {"city_id":"29035","city_name":"Nacozari","state_id":"2453"},
    {"city_id":"29036","city_name":"Navajoa","state_id":"2453"},
    {"city_id":"29037","city_name":"Nogales","state_id":"2453"},
    {"city_id":"29038","city_name":"Obregon","state_id":"2453"},
    {"city_id":"29039","city_name":"Pitiquito","state_id":"2453"},
    {"city_id":"29040","city_name":"Potam","state_id":"2453"},
    {"city_id":"29041","city_name":"Providencia","state_id":"2453"},
    {"city_id":"29042","city_name":"Puerto Penasco","state_id":"2453"},
    {"city_id":"29043","city_name":"Rio Muerto","state_id":"2453"},
    {"city_id":"29044","city_name":"Sahuaripa","state_id":"2453"},
    {"city_id":"29045","city_name":"San Jose de Bacum","state_id":"2453"},
    {"city_id":"29046","city_name":"San Luis Rio Colorado","state_id":"2453"},
    {"city_id":"29047","city_name":"Sanchez","state_id":"2453"},
    {"city_id":"29048","city_name":"Santa Ana","state_id":"2453"},
    {"city_id":"29049","city_name":"Sonoita","state_id":"2453"},
    {"city_id":"29050","city_name":"Tobarito","state_id":"2453"},
    {"city_id":"29051","city_name":"Ures","state_id":"2453"},
    {"city_id":"29052","city_name":"Vicam","state_id":"2453"},
    {"city_id":"29053","city_name":"Yaqui","state_id":"2453"},
    {"city_id":"29054","city_name":"Yavaros","state_id":"2453"},
    {"city_id":"29055","city_name":"Aquiles Serdan","state_id":"2454"},
    {"city_id":"29056","city_name":"Ayapa","state_id":"2454"},
    {"city_id":"29057","city_name":"Balancan","state_id":"2454"},
    {"city_id":"29058","city_name":"Barra de Santa Ana","state_id":"2454"},
    {"city_id":"29059","city_name":"Belen","state_id":"2454"},
    {"city_id":"29060","city_name":"Bosque de Saloya","state_id":"2454"},
    {"city_id":"29061","city_name":"Buenavista","state_id":"2454"},
    {"city_id":"29062","city_name":"Campo Magellanes","state_id":"2454"},
    {"city_id":"29063","city_name":"Cardenas","state_id":"2454"},
    {"city_id":"29064","city_name":"Chichicapa","state_id":"2454"},
    {"city_id":"29065","city_name":"Chontalpa","state_id":"2454"},
    {"city_id":"29066","city_name":"Comalcalco","state_id":"2454"},
    {"city_id":"29067","city_name":"Cunduacan","state_id":"2454"},
    {"city_id":"29068","city_name":"El Triunfo","state_id":"2454"},
    {"city_id":"29069","city_name":"Emiliano Zapata","state_id":"2454"},
    {"city_id":"29070","city_name":"Fraccionamiento la Selva","state_id":"2454"},
    {"city_id":"29071","city_name":"Frontera","state_id":"2454"},
    {"city_id":"29072","city_name":"Huimanguillo","state_id":"2454"},
    {"city_id":"29073","city_name":"Jalapa","state_id":"2454"},
    {"city_id":"29074","city_name":"Jalpa","state_id":"2454"},
    {"city_id":"29075","city_name":"Jonuta","state_id":"2454"},
    {"city_id":"29076","city_name":"La Curva","state_id":"2454"},
    {"city_id":"29077","city_name":"La Venta","state_id":"2454"},
    {"city_id":"29078","city_name":"Luis Gil Perez","state_id":"2454"},
    {"city_id":"29079","city_name":"Macultepec","state_id":"2454"},
    {"city_id":"29080","city_name":"Macuspana","state_id":"2454"},
    {"city_id":"29081","city_name":"Magana","state_id":"2454"},
    {"city_id":"29082","city_name":"Miguel Hidalgo","state_id":"2454"},
    {"city_id":"29083","city_name":"Nacajuca","state_id":"2454"},
    {"city_id":"29084","city_name":"Ocuiltzapotlan","state_id":"2454"},
    {"city_id":"29085","city_name":"Once de Febrero","state_id":"2454"},
    {"city_id":"29086","city_name":"Paraiso","state_id":"2454"},
    {"city_id":"29087","city_name":"Parrilla","state_id":"2454"},
    {"city_id":"29088","city_name":"Pemex","state_id":"2454"},
    {"city_id":"29089","city_name":"Playas del Rosario","state_id":"2454"},
    {"city_id":"29090","city_name":"Rio Viejo","state_id":"2454"},
    {"city_id":"29091","city_name":"San Carlos","state_id":"2454"},
    {"city_id":"29092","city_name":"Santa Rosalia","state_id":"2454"},
    {"city_id":"29093","city_name":"Simon Sarlat","state_id":"2454"},
    {"city_id":"29094","city_name":"Tacotalpa","state_id":"2454"},
    {"city_id":"29095","city_name":"Tamulte","state_id":"2454"},
    {"city_id":"29096","city_name":"Teapa","state_id":"2454"},
    {"city_id":"29097","city_name":"Tecolutilla","state_id":"2454"},
    {"city_id":"29098","city_name":"Tenosique","state_id":"2454"},
    {"city_id":"29099","city_name":"Vicente Guerrero","state_id":"2454"},
    {"city_id":"29100","city_name":"Villa Aldama","state_id":"2454"},
    {"city_id":"29101","city_name":"Villahermosa","state_id":"2454"},
    {"city_id":"29102","city_name":"Abasolo","state_id":"2455"},
    {"city_id":"29103","city_name":"Aldama","state_id":"2455"},
    {"city_id":"29104","city_name":"Altamira","state_id":"2455"},
    {"city_id":"29105","city_name":"Antiguo Morelos","state_id":"2455"},
    {"city_id":"29106","city_name":"Camargo","state_id":"2455"},
    {"city_id":"29107","city_name":"Cuauhtemoc","state_id":"2455"},
    {"city_id":"29108","city_name":"El Mante","state_id":"2455"},
    {"city_id":"29109","city_name":"Estacion Manuel","state_id":"2455"},
    {"city_id":"29110","city_name":"Gonzalez","state_id":"2455"},
    {"city_id":"29111","city_name":"Graciano Sanchez","state_id":"2455"},
    {"city_id":"29112","city_name":"Guerrero","state_id":"2455"},
    {"city_id":"29113","city_name":"Gustavo Diaz Ordaz","state_id":"2455"},
    {"city_id":"29114","city_name":"Hidalgo","state_id":"2455"},
    {"city_id":"29115","city_name":"Jaumave","state_id":"2455"},
    {"city_id":"29116","city_name":"Llerca","state_id":"2455"},
    {"city_id":"29117","city_name":"Los Guerra","state_id":"2455"},
    {"city_id":"29118","city_name":"Madero","state_id":"2455"},
    {"city_id":"29119","city_name":"Matamoros","state_id":"2455"},
    {"city_id":"29120","city_name":"Mier","state_id":"2455"},
    {"city_id":"29121","city_name":"Miguel Aleman","state_id":"2455"},
    {"city_id":"29122","city_name":"Miramar","state_id":"2455"},
    {"city_id":"29123","city_name":"Nuevo Laredo","state_id":"2455"},
    {"city_id":"29124","city_name":"Nuevo Progreso","state_id":"2455"},
    {"city_id":"29125","city_name":"Ocampo","state_id":"2455"},
    {"city_id":"29126","city_name":"Padilla","state_id":"2455"},
    {"city_id":"29127","city_name":"Reynosa","state_id":"2455"},
    {"city_id":"29128","city_name":"Rio Bravo","state_id":"2455"},
    {"city_id":"29129","city_name":"San Fernando","state_id":"2455"},
    {"city_id":"29130","city_name":"Santa Engracia","state_id":"2455"},
    {"city_id":"29131","city_name":"Santander Jimenez","state_id":"2455"},
    {"city_id":"29132","city_name":"Soto la Marina","state_id":"2455"},
    {"city_id":"29133","city_name":"Tampico","state_id":"2455"},
    {"city_id":"29134","city_name":"Tula","state_id":"2455"},
    {"city_id":"29135","city_name":"Valle Hermoso","state_id":"2455"},
    {"city_id":"29136","city_name":"Victoria","state_id":"2455"},
    {"city_id":"29137","city_name":"Xicotencatl","state_id":"2455"},
    {"city_id":"29138","city_name":"Ahuashuatepec","state_id":"2456"},
    {"city_id":"29139","city_name":"Altzayanca","state_id":"2456"},
    {"city_id":"29140","city_name":"Amaxac","state_id":"2456"},
    {"city_id":"29141","city_name":"Apetatitlan","state_id":"2456"},
    {"city_id":"29142","city_name":"Apizaco","state_id":"2456"},
    {"city_id":"29143","city_name":"Apizaquito","state_id":"2456"},
    {"city_id":"29144","city_name":"Atexcatzingo","state_id":"2456"},
    {"city_id":"29145","city_name":"Axocomanitla","state_id":"2456"},
    {"city_id":"29146","city_name":"Ayometla","state_id":"2456"},
    {"city_id":"29147","city_name":"Benito Juarez","state_id":"2456"},
    {"city_id":"29148","city_name":"Buen Suceso","state_id":"2456"},
    {"city_id":"29149","city_name":"Calpulalpan","state_id":"2456"},
    {"city_id":"29150","city_name":"Chiautempan","state_id":"2456"},
    {"city_id":"29151","city_name":"Contla","state_id":"2456"},
    {"city_id":"29152","city_name":"Cuapiaxtla","state_id":"2456"},
    {"city_id":"29153","city_name":"Huactzinco","state_id":"2456"},
    {"city_id":"29154","city_name":"Huamantla","state_id":"2456"},
    {"city_id":"29155","city_name":"Hueyotlipan","state_id":"2456"},
    {"city_id":"29156","city_name":"Huiloac","state_id":"2456"},
    {"city_id":"29157","city_name":"Ignacio Zaragoza","state_id":"2456"},
    {"city_id":"29158","city_name":"Ixtacuixtla","state_id":"2456"},
    {"city_id":"29159","city_name":"Ixtenco","state_id":"2456"},
    {"city_id":"29160","city_name":"Mazatecochco","state_id":"2456"},
    {"city_id":"29161","city_name":"Nanacamilpa","state_id":"2456"},
    {"city_id":"29162","city_name":"Nativitas","state_id":"2456"},
    {"city_id":"29163","city_name":"Nopalucan","state_id":"2456"},
    {"city_id":"29164","city_name":"Panotla","state_id":"2456"},
    {"city_id":"29165","city_name":"Papalotla","state_id":"2456"},
    {"city_id":"29166","city_name":"Quilehtla","state_id":"2456"},
    {"city_id":"29167","city_name":"Sanctorum","state_id":"2456"},
    {"city_id":"29168","city_name":"Teacalco","state_id":"2456"},
    {"city_id":"29169","city_name":"Tenancingo","state_id":"2456"},
    {"city_id":"29170","city_name":"Teolocholco","state_id":"2456"},
    {"city_id":"29171","city_name":"Tepetitla","state_id":"2456"},
    {"city_id":"29172","city_name":"Tepeyanco","state_id":"2456"},
    {"city_id":"29173","city_name":"Tequixquitla","state_id":"2456"},
    {"city_id":"29174","city_name":"Terrenate","state_id":"2456"},
    {"city_id":"29175","city_name":"Tetla","state_id":"2456"},
    {"city_id":"29176","city_name":"Tetlanohcan","state_id":"2456"},
    {"city_id":"29177","city_name":"Tetlatlahuca","state_id":"2456"},
    {"city_id":"29178","city_name":"Texcalac","state_id":"2456"},
    {"city_id":"29179","city_name":"Texoloc","state_id":"2456"},
    {"city_id":"29180","city_name":"Tlaltelulco","state_id":"2456"},
    {"city_id":"29181","city_name":"Tlaxcala","state_id":"2456"},
    {"city_id":"29182","city_name":"Tlaxco","state_id":"2456"},
    {"city_id":"29183","city_name":"Tocatlan","state_id":"2456"},
    {"city_id":"29184","city_name":"Totolac","state_id":"2456"},
    {"city_id":"29185","city_name":"Vicente Guerrero","state_id":"2456"},
    {"city_id":"29186","city_name":"Villa Alta","state_id":"2456"},
    {"city_id":"29187","city_name":"Xaloztoc","state_id":"2456"},
    {"city_id":"29188","city_name":"Xaltocan","state_id":"2456"},
    {"city_id":"29189","city_name":"Xicohtzinco","state_id":"2456"},
    {"city_id":"29190","city_name":"Xicotencatl","state_id":"2456"},
    {"city_id":"29191","city_name":"Xiloxoxtla","state_id":"2456"},
    {"city_id":"29192","city_name":"Yauhquemecan","state_id":"2456"},
    {"city_id":"29193","city_name":"Zacatelco","state_id":"2456"},
    {"city_id":"29194","city_name":"Zacualpan","state_id":"2456"},
    {"city_id":"29195","city_name":"Zitlaltepec","state_id":"2456"},
    {"city_id":"29196","city_name":"Acayucan","state_id":"2457"},
    {"city_id":"29197","city_name":"Actopan","state_id":"2457"},
    {"city_id":"29198","city_name":"Acultzingo","state_id":"2457"},
    {"city_id":"29199","city_name":"Agua Dulce","state_id":"2457"},
    {"city_id":"29200","city_name":"Alamo","state_id":"2457"},
    {"city_id":"29201","city_name":"Allende","state_id":"2457"},
    {"city_id":"29202","city_name":"Altamirano","state_id":"2457"},
    {"city_id":"29203","city_name":"Alto Lucero","state_id":"2457"},
    {"city_id":"29204","city_name":"Altotonga","state_id":"2457"},
    {"city_id":"29205","city_name":"Alvarado","state_id":"2457"},
    {"city_id":"29206","city_name":"Amatlan","state_id":"2457"},
    {"city_id":"29207","city_name":"Anahuac","state_id":"2457"},
    {"city_id":"29208","city_name":"Anton Lizardo","state_id":"2457"},
    {"city_id":"29209","city_name":"Atzacan","state_id":"2457"},
    {"city_id":"29210","city_name":"Azueta","state_id":"2457"},
    {"city_id":"29211","city_name":"Banderilla","state_id":"2457"},
    {"city_id":"29212","city_name":"Benito Juarez","state_id":"2457"},
    {"city_id":"29213","city_name":"Boca del Monte","state_id":"2457"},
    {"city_id":"29214","city_name":"Boca del Rio","state_id":"2457"},
    {"city_id":"29215","city_name":"Cabada","state_id":"2457"},
    {"city_id":"29216","city_name":"Cabezas","state_id":"2457"},
    {"city_id":"29217","city_name":"Carrillo","state_id":"2457"},
    {"city_id":"29218","city_name":"Castillo de Teayo","state_id":"2457"},
    {"city_id":"29219","city_name":"Catemaco","state_id":"2457"},
    {"city_id":"29220","city_name":"Cazones","state_id":"2457"},
    {"city_id":"29221","city_name":"Cerro Azul","state_id":"2457"},
    {"city_id":"29222","city_name":"Chacaltianguis","state_id":"2457"},
    {"city_id":"29223","city_name":"Chicontepec","state_id":"2457"},
    {"city_id":"29224","city_name":"Chinameca","state_id":"2457"},
    {"city_id":"29225","city_name":"Chinampa","state_id":"2457"},
    {"city_id":"29226","city_name":"Chocaman","state_id":"2457"},
    {"city_id":"29227","city_name":"Citlaltepetl","state_id":"2457"},
    {"city_id":"29228","city_name":"Clara","state_id":"2457"},
    {"city_id":"29229","city_name":"Coacoatzintla","state_id":"2457"},
    {"city_id":"29230","city_name":"Coacotla","state_id":"2457"},
    {"city_id":"29231","city_name":"Coatepec","state_id":"2457"},
    {"city_id":"29232","city_name":"Coatzacoalcos","state_id":"2457"},
    {"city_id":"29233","city_name":"Coatzintla","state_id":"2457"},
    {"city_id":"29234","city_name":"Comoapan","state_id":"2457"},
    {"city_id":"29235","city_name":"Cordoba","state_id":"2457"},
    {"city_id":"29236","city_name":"Corral Nuevo","state_id":"2457"},
    {"city_id":"29237","city_name":"Cosamaloapan","state_id":"2457"},
    {"city_id":"29238","city_name":"Cosautlan","state_id":"2457"},
    {"city_id":"29239","city_name":"Coscomatepec","state_id":"2457"},
    {"city_id":"29240","city_name":"Cosoleacaque","state_id":"2457"},
    {"city_id":"29241","city_name":"Covarrubias","state_id":"2457"},
    {"city_id":"29242","city_name":"Coxquihui","state_id":"2457"},
    {"city_id":"29243","city_name":"Coyutla","state_id":"2457"},
    {"city_id":"29244","city_name":"Cuauhtemoc","state_id":"2457"},
    {"city_id":"29245","city_name":"Cuautlapan","state_id":"2457"},
    {"city_id":"29246","city_name":"Cuichapa","state_id":"2457"},
    {"city_id":"29247","city_name":"Cuitlahuac","state_id":"2457"},
    {"city_id":"29248","city_name":"El Castillo","state_id":"2457"},
    {"city_id":"29249","city_name":"El Higo","state_id":"2457"},
    {"city_id":"29250","city_name":"El Naranjito","state_id":"2457"},
    {"city_id":"29251","city_name":"El Pueblito","state_id":"2457"},
    {"city_id":"29252","city_name":"El Tejar","state_id":"2457"},
    {"city_id":"29253","city_name":"Emilio Carranza","state_id":"2457"},
    {"city_id":"29254","city_name":"Estacion Juanita","state_id":"2457"},
    {"city_id":"29255","city_name":"Estacion del Idolo","state_id":"2457"},
    {"city_id":"29256","city_name":"Filomeno Mata","state_id":"2457"},
    {"city_id":"29257","city_name":"Fortin","state_id":"2457"},
    {"city_id":"29258","city_name":"Gabino Barreda","state_id":"2457"},
    {"city_id":"29259","city_name":"Guadalupe Victoria","state_id":"2457"},
    {"city_id":"29260","city_name":"Gutierrez Zamora","state_id":"2457"},
    {"city_id":"29261","city_name":"Hidalgo","state_id":"2457"},
    {"city_id":"29262","city_name":"Hidalgotitlan","state_id":"2457"},
    {"city_id":"29263","city_name":"Huatusco","state_id":"2457"},
    {"city_id":"29264","city_name":"Huayacoctla","state_id":"2457"},
    {"city_id":"29265","city_name":"Hueyapan","state_id":"2457"},
    {"city_id":"29266","city_name":"Huiloapan","state_id":"2457"},
    {"city_id":"29267","city_name":"Ignacio de la Llave","state_id":"2457"},
    {"city_id":"29268","city_name":"Isla","state_id":"2457"},
    {"city_id":"29269","city_name":"Ixcatepec","state_id":"2457"},
    {"city_id":"29270","city_name":"Ixhuatlan del Cafe","state_id":"2457"},
    {"city_id":"29271","city_name":"Ixhuatlan del Sureste","state_id":"2457"},
    {"city_id":"29272","city_name":"Ixhuatlancillo","state_id":"2457"},
    {"city_id":"29273","city_name":"Ixtaczoquitlan","state_id":"2457"},
    {"city_id":"29274","city_name":"Jalacingo","state_id":"2457"},
    {"city_id":"29275","city_name":"Jalapilla","state_id":"2457"},
    {"city_id":"29276","city_name":"Jaltipan","state_id":"2457"},
    {"city_id":"29277","city_name":"Jamapa","state_id":"2457"},
    {"city_id":"29278","city_name":"Jesus Carranza","state_id":"2457"},
    {"city_id":"29279","city_name":"Jilotepec","state_id":"2457"},
    {"city_id":"29280","city_name":"La Antigua","state_id":"2457"},
    {"city_id":"29281","city_name":"La Estanzuela","state_id":"2457"},
    {"city_id":"29282","city_name":"La Pena","state_id":"2457"},
    {"city_id":"29283","city_name":"La Perla","state_id":"2457"},
    {"city_id":"29284","city_name":"La Poza","state_id":"2457"},
    {"city_id":"29285","city_name":"Las Amapolas","state_id":"2457"},
    {"city_id":"29286","city_name":"Las Choapas","state_id":"2457"},
    {"city_id":"29287","city_name":"Las Vigas","state_id":"2457"},
    {"city_id":"29288","city_name":"Lerdo","state_id":"2457"},
    {"city_id":"29289","city_name":"Lomas de Barillas","state_id":"2457"},
    {"city_id":"29290","city_name":"Los Altos","state_id":"2457"},
    {"city_id":"29291","city_name":"Los Mangos","state_id":"2457"},
    {"city_id":"29292","city_name":"Maltrata","state_id":"2457"},
    {"city_id":"29293","city_name":"Mariano Escobedo","state_id":"2457"},
    {"city_id":"29294","city_name":"Martinez","state_id":"2457"},
    {"city_id":"29295","city_name":"Mecatlan","state_id":"2457"},
    {"city_id":"29296","city_name":"Mecayapan","state_id":"2457"},
    {"city_id":"29297","city_name":"Mendoza","state_id":"2457"},
    {"city_id":"29298","city_name":"Minatitlan","state_id":"2457"},
    {"city_id":"29299","city_name":"Misantla","state_id":"2457"},
    {"city_id":"29300","city_name":"Monte Blanco","state_id":"2457"},
    {"city_id":"29301","city_name":"Moralillo","state_id":"2457"},
    {"city_id":"29302","city_name":"Motzorongo","state_id":"2457"},
    {"city_id":"29303","city_name":"Mundo Nuevo","state_id":"2457"},
    {"city_id":"29304","city_name":"Nanchital","state_id":"2457"},
    {"city_id":"29305","city_name":"Naolinco","state_id":"2457"},
    {"city_id":"29306","city_name":"Naranjos","state_id":"2457"},
    {"city_id":"29307","city_name":"Nautla","state_id":"2457"},
    {"city_id":"29308","city_name":"Nogales","state_id":"2457"},
    {"city_id":"29309","city_name":"Oluta","state_id":"2457"},
    {"city_id":"29310","city_name":"Omealca","state_id":"2457"},
    {"city_id":"29311","city_name":"Orizaba","state_id":"2457"},
    {"city_id":"29312","city_name":"Otatitlan","state_id":"2457"},
    {"city_id":"29313","city_name":"Oteapan","state_id":"2457"},
    {"city_id":"29314","city_name":"Ozuluama","state_id":"2457"},
    {"city_id":"29315","city_name":"Pajapan","state_id":"2457"},
    {"city_id":"29316","city_name":"Palmira","state_id":"2457"},
    {"city_id":"29317","city_name":"Panuco","state_id":"2457"},
    {"city_id":"29318","city_name":"Papantla","state_id":"2457"},
    {"city_id":"29319","city_name":"Paraje Nuevo","state_id":"2457"},
    {"city_id":"29320","city_name":"Paso de Ovejas","state_id":"2457"},
    {"city_id":"29321","city_name":"Paso del Macho","state_id":"2457"},
    {"city_id":"29322","city_name":"Paso del Toro","state_id":"2457"},
    {"city_id":"29323","city_name":"Penuela","state_id":"2457"},
    {"city_id":"29324","city_name":"Perote","state_id":"2457"},
    {"city_id":"29325","city_name":"Piedras Negras","state_id":"2457"},
    {"city_id":"29326","city_name":"Plan de Ayala","state_id":"2457"},
    {"city_id":"29327","city_name":"Platon Sanchez","state_id":"2457"},
    {"city_id":"29328","city_name":"Playa Vicente","state_id":"2457"},
    {"city_id":"29329","city_name":"Potrero Nuevo","state_id":"2457"},
    {"city_id":"29330","city_name":"Potrero del Llano","state_id":"2457"},
    {"city_id":"29331","city_name":"Poza Rica","state_id":"2457"},
    {"city_id":"29332","city_name":"Puntilla Aldama","state_id":"2457"},
    {"city_id":"29333","city_name":"Rafael Delgado","state_id":"2457"},
    {"city_id":"29334","city_name":"Rafael Lucio","state_id":"2457"},
    {"city_id":"29335","city_name":"Rinconada","state_id":"2457"},
    {"city_id":"29336","city_name":"Saltabarranca","state_id":"2457"},
    {"city_id":"29337","city_name":"San Juan Evangelista","state_id":"2457"},
    {"city_id":"29338","city_name":"San Marcos","state_id":"2457"},
    {"city_id":"29339","city_name":"San Rafael","state_id":"2457"},
    {"city_id":"29340","city_name":"San Roman","state_id":"2457"},
    {"city_id":"29341","city_name":"Santiago Tuxtla","state_id":"2457"},
    {"city_id":"29342","city_name":"Sayula","state_id":"2457"},
    {"city_id":"29343","city_name":"Soconusco","state_id":"2457"},
    {"city_id":"29344","city_name":"Soledad","state_id":"2457"},
    {"city_id":"29345","city_name":"Soteapan","state_id":"2457"},
    {"city_id":"29346","city_name":"Tamalin","state_id":"2457"},
    {"city_id":"29347","city_name":"Tamiahua","state_id":"2457"},
    {"city_id":"29348","city_name":"Tantoyuca","state_id":"2457"},
    {"city_id":"29349","city_name":"Tatahuicapan","state_id":"2457"},
    {"city_id":"29350","city_name":"Tecolutla","state_id":"2457"},
    {"city_id":"29351","city_name":"Tempoal","state_id":"2457"},
    {"city_id":"29352","city_name":"Tenango de Rio Blanco","state_id":"2457"},
    {"city_id":"29353","city_name":"Teocelo","state_id":"2457"},
    {"city_id":"29354","city_name":"Tepetzintla","state_id":"2457"},
    {"city_id":"29355","city_name":"Tequila","state_id":"2457"},
    {"city_id":"29356","city_name":"Tetelzingo","state_id":"2457"},
    {"city_id":"29357","city_name":"Texistepec","state_id":"2457"},
    {"city_id":"29358","city_name":"Tezonapa","state_id":"2457"},
    {"city_id":"29359","city_name":"Tierra Blanca","state_id":"2457"},
    {"city_id":"29360","city_name":"Tihuatlan","state_id":"2457"},
    {"city_id":"29361","city_name":"Tlacojalpan","state_id":"2457"},
    {"city_id":"29362","city_name":"Tlacotalpan","state_id":"2457"},
    {"city_id":"29363","city_name":"Tlaltetela","state_id":"2457"},
    {"city_id":"29364","city_name":"Tlapacoyan","state_id":"2457"},
    {"city_id":"29365","city_name":"Tocuila","state_id":"2457"},
    {"city_id":"29366","city_name":"Tomatlan","state_id":"2457"},
    {"city_id":"29367","city_name":"Totolapa","state_id":"2457"},
    {"city_id":"29368","city_name":"Totula","state_id":"2457"},
    {"city_id":"29369","city_name":"Tres Valles","state_id":"2457"},
    {"city_id":"29370","city_name":"Tuxpam","state_id":"2457"},
    {"city_id":"29371","city_name":"Tuxtla","state_id":"2457"},
    {"city_id":"29372","city_name":"Tuzamapan","state_id":"2457"},
    {"city_id":"29373","city_name":"Union y Progreso","state_id":"2457"},
    {"city_id":"29374","city_name":"Ursulo Galvan","state_id":"2457"},
    {"city_id":"29375","city_name":"Valente Diaz","state_id":"2457"},
    {"city_id":"29376","city_name":"Vega de Alatorre","state_id":"2457"},
    {"city_id":"29377","city_name":"Veracruz","state_id":"2457"},
    {"city_id":"29378","city_name":"Xalapa","state_id":"2457"},
    {"city_id":"29379","city_name":"Xico","state_id":"2457"},
    {"city_id":"29380","city_name":"Xocotla","state_id":"2457"},
    {"city_id":"29381","city_name":"Yanga","state_id":"2457"},
    {"city_id":"29382","city_name":"Yecuatla","state_id":"2457"},
    {"city_id":"29383","city_name":"Zaragoza","state_id":"2457"},
    {"city_id":"29384","city_name":"Zempoala","state_id":"2457"},
    {"city_id":"29385","city_name":"Zongolica","state_id":"2457"},
    {"city_id":"29386","city_name":"Zozocolco","state_id":"2457"},
    {"city_id":"29387","city_name":"Acanceh","state_id":"2458"},
    {"city_id":"29388","city_name":"Akil","state_id":"2458"},
    {"city_id":"29389","city_name":"Baca","state_id":"2458"},
    {"city_id":"29390","city_name":"Buctzotz","state_id":"2458"},
    {"city_id":"29391","city_name":"Cacalchen","state_id":"2458"},
    {"city_id":"29392","city_name":"Cansahcab","state_id":"2458"},
    {"city_id":"29393","city_name":"Caucel","state_id":"2458"},
    {"city_id":"29394","city_name":"Celestun","state_id":"2458"},
    {"city_id":"29395","city_name":"Cenotillo","state_id":"2458"},
    {"city_id":"29396","city_name":"Chemax","state_id":"2458"},
    {"city_id":"29397","city_name":"Chichimila","state_id":"2458"},
    {"city_id":"29398","city_name":"Chicxulub","state_id":"2458"},
    {"city_id":"29399","city_name":"Chochola","state_id":"2458"},
    {"city_id":"29400","city_name":"Cholul","state_id":"2458"},
    {"city_id":"29401","city_name":"Chumayel","state_id":"2458"},
    {"city_id":"29402","city_name":"Conkal","state_id":"2458"},
    {"city_id":"29403","city_name":"Cuzama","state_id":"2458"},
    {"city_id":"29404","city_name":"Dzan","state_id":"2458"},
    {"city_id":"29405","city_name":"Dzemul","state_id":"2458"},
    {"city_id":"29406","city_name":"Dzidzantun","state_id":"2458"},
    {"city_id":"29407","city_name":"Dzilam Gonzalez","state_id":"2458"},
    {"city_id":"29408","city_name":"Dzitas","state_id":"2458"},
    {"city_id":"29409","city_name":"Espita","state_id":"2458"},
    {"city_id":"29410","city_name":"Halacho","state_id":"2458"},
    {"city_id":"29411","city_name":"Hocaba","state_id":"2458"},
    {"city_id":"29412","city_name":"Hoctun","state_id":"2458"},
    {"city_id":"29413","city_name":"Homun","state_id":"2458"},
    {"city_id":"29414","city_name":"Huhi","state_id":"2458"},
    {"city_id":"29415","city_name":"Hunucma","state_id":"2458"},
    {"city_id":"29416","city_name":"Itzincab","state_id":"2458"},
    {"city_id":"29417","city_name":"Ixil","state_id":"2458"},
    {"city_id":"29418","city_name":"Izamal","state_id":"2458"},
    {"city_id":"29419","city_name":"Kanasin","state_id":"2458"},
    {"city_id":"29420","city_name":"Kantunil","state_id":"2458"},
    {"city_id":"29421","city_name":"Kinchil","state_id":"2458"},
    {"city_id":"29422","city_name":"Mama","state_id":"2458"},
    {"city_id":"29423","city_name":"Mani","state_id":"2458"},
    {"city_id":"29424","city_name":"Maxcanu","state_id":"2458"},
    {"city_id":"29425","city_name":"Merida","state_id":"2458"},
    {"city_id":"29426","city_name":"Motul","state_id":"2458"},
    {"city_id":"29427","city_name":"Muna","state_id":"2458"},
    {"city_id":"29428","city_name":"Opichen","state_id":"2458"},
    {"city_id":"29429","city_name":"Oxkutzcab","state_id":"2458"},
    {"city_id":"29430","city_name":"Panaba","state_id":"2458"},
    {"city_id":"29431","city_name":"Peto","state_id":"2458"},
    {"city_id":"29432","city_name":"Piste","state_id":"2458"},
    {"city_id":"29433","city_name":"Progreso","state_id":"2458"},
    {"city_id":"29434","city_name":"Sacalum","state_id":"2458"},
    {"city_id":"29435","city_name":"Santa Elena","state_id":"2458"},
    {"city_id":"29436","city_name":"Seye","state_id":"2458"},
    {"city_id":"29437","city_name":"Sotuta","state_id":"2458"},
    {"city_id":"29438","city_name":"Sucila","state_id":"2458"},
    {"city_id":"29439","city_name":"Tahmek","state_id":"2458"},
    {"city_id":"29440","city_name":"Teabo","state_id":"2458"},
    {"city_id":"29441","city_name":"Tecoh","state_id":"2458"},
    {"city_id":"29442","city_name":"Tekanto","state_id":"2458"},
    {"city_id":"29443","city_name":"Tekax","state_id":"2458"},
    {"city_id":"29444","city_name":"Tekit","state_id":"2458"},
    {"city_id":"29445","city_name":"Telchac","state_id":"2458"},
    {"city_id":"29446","city_name":"Temax","state_id":"2458"},
    {"city_id":"29447","city_name":"Temozon","state_id":"2458"},
    {"city_id":"29448","city_name":"Tetiz","state_id":"2458"},
    {"city_id":"29449","city_name":"Ticul","state_id":"2458"},
    {"city_id":"29450","city_name":"Timucuy","state_id":"2458"},
    {"city_id":"29451","city_name":"Tixkokob","state_id":"2458"},
    {"city_id":"29452","city_name":"Tixpehual","state_id":"2458"},
    {"city_id":"29453","city_name":"Tizimin","state_id":"2458"},
    {"city_id":"29454","city_name":"Tunkas","state_id":"2458"},
    {"city_id":"29455","city_name":"Tzucacab","state_id":"2458"},
    {"city_id":"29456","city_name":"Uman","state_id":"2458"},
    {"city_id":"29457","city_name":"Valladolid","state_id":"2458"},
    {"city_id":"29458","city_name":"X-Can","state_id":"2458"},
    {"city_id":"29459","city_name":"Yucatan","state_id":"2458"},
    {"city_id":"29460","city_name":"Apozol","state_id":"2459"},
    {"city_id":"29461","city_name":"Banon","state_id":"2459"},
    {"city_id":"29462","city_name":"Canitas","state_id":"2459"},
    {"city_id":"29463","city_name":"Chalchihuites","state_id":"2459"},
    {"city_id":"29464","city_name":"Concepcion del Oro","state_id":"2459"},
    {"city_id":"29465","city_name":"Cos","state_id":"2459"},
    {"city_id":"29466","city_name":"Estrada","state_id":"2459"},
    {"city_id":"29467","city_name":"Fresnillo","state_id":"2459"},
    {"city_id":"29468","city_name":"Garcia","state_id":"2459"},
    {"city_id":"29469","city_name":"Gonzalez Ortega","state_id":"2459"},
    {"city_id":"29470","city_name":"Guadalupe","state_id":"2459"},
    {"city_id":"29471","city_name":"Hidalgo","state_id":"2459"},
    {"city_id":"29472","city_name":"Jalpa","state_id":"2459"},
    {"city_id":"29473","city_name":"Jerez","state_id":"2459"},
    {"city_id":"29474","city_name":"Juan Aldama","state_id":"2459"},
    {"city_id":"29475","city_name":"Juchipila","state_id":"2459"},
    {"city_id":"29476","city_name":"Loreto","state_id":"2459"},
    {"city_id":"29477","city_name":"Lourdes","state_id":"2459"},
    {"city_id":"29478","city_name":"Luis Moya","state_id":"2459"},
    {"city_id":"29479","city_name":"Miguel Auza","state_id":"2459"},
    {"city_id":"29480","city_name":"Monte Escobedo","state_id":"2459"},
    {"city_id":"29481","city_name":"Morelos","state_id":"2459"},
    {"city_id":"29482","city_name":"Natera","state_id":"2459"},
    {"city_id":"29483","city_name":"Nieves","state_id":"2459"},
    {"city_id":"29484","city_name":"Nochistlan","state_id":"2459"},
    {"city_id":"29485","city_name":"Ojo Caliente","state_id":"2459"},
    {"city_id":"29486","city_name":"Piedra Gorda","state_id":"2459"},
    {"city_id":"29487","city_name":"Pinos","state_id":"2459"},
    {"city_id":"29488","city_name":"Plateros","state_id":"2459"},
    {"city_id":"29489","city_name":"Pozo de Gamboa","state_id":"2459"},
    {"city_id":"29490","city_name":"Rio Grande","state_id":"2459"},
    {"city_id":"29491","city_name":"Sain Alto","state_id":"2459"},
    {"city_id":"29492","city_name":"Sombrerete","state_id":"2459"},
    {"city_id":"29493","city_name":"Tabasco","state_id":"2459"},
    {"city_id":"29494","city_name":"Tacoaleche","state_id":"2459"},
    {"city_id":"29495","city_name":"Tepechitlan","state_id":"2459"},
    {"city_id":"29496","city_name":"Teul","state_id":"2459"},
    {"city_id":"29497","city_name":"Tlaltenango","state_id":"2459"},
    {"city_id":"29498","city_name":"Toribio","state_id":"2459"},
    {"city_id":"29499","city_name":"Trancoso","state_id":"2459"},
    {"city_id":"29500","city_name":"Valparaiso","state_id":"2459"},
    {"city_id":"29501","city_name":"Victor Rosales","state_id":"2459"},
    {"city_id":"29502","city_name":"Villa Gonzalez Ortega","state_id":"2459"},
    {"city_id":"29503","city_name":"Villanueva","state_id":"2459"},
    {"city_id":"29504","city_name":"Zacatecas","state_id":"2459"},
    {"city_id":"29505","city_name":"Tol","state_id":"2460"},
    {"city_id":"29506","city_name":"Weno","state_id":"2460"},
    {"city_id":"29507","city_name":"Lelu","state_id":"2461"},
    {"city_id":"29508","city_name":"Kolonia","state_id":"2462"},
    {"city_id":"29509","city_name":"Palikir","state_id":"2462"},
    {"city_id":"29510","city_name":"Colonia","state_id":"2463"},
    {"city_id":"29511","city_name":"Balti","state_id":"2464"},
    {"city_id":"29512","city_name":"Falesti","state_id":"2464"},
    {"city_id":"29513","city_name":"Glodeni","state_id":"2464"},
    {"city_id":"29514","city_name":"Rascani","state_id":"2464"},
    {"city_id":"29515","city_name":"Cahul","state_id":"2465"},
    {"city_id":"29516","city_name":"Straseni","state_id":"2466"},
    {"city_id":"29517","city_name":"Straseni","state_id":"2467"},
    {"city_id":"29518","city_name":"Briceni","state_id":"2468"},
    {"city_id":"29519","city_name":"Donduseni","state_id":"2468"},
    {"city_id":"29520","city_name":"Edinet","state_id":"2468"},
    {"city_id":"29521","city_name":"Ocnita","state_id":"2468"},
    {"city_id":"29522","city_name":"Ciadar Lunga","state_id":"2469"},
    {"city_id":"29523","city_name":"Comrat","state_id":"2469"},
    {"city_id":"29524","city_name":"Vulcanesti","state_id":"2469"},
    {"city_id":"29525","city_name":"Basarabeasca","state_id":"2470"},
    {"city_id":"29526","city_name":"Cimislia","state_id":"2470"},
    {"city_id":"29527","city_name":"Hancesti","state_id":"2470"},
    {"city_id":"29528","city_name":"Leova","state_id":"2470"},
    {"city_id":"29529","city_name":"Orhei","state_id":"2471"},
    {"city_id":"29530","city_name":"Rezina","state_id":"2471"},
    {"city_id":"29531","city_name":"Telenesti","state_id":"2471"},
    {"city_id":"29532","city_name":"Drochia","state_id":"2472"},
    {"city_id":"29533","city_name":"Floresti","state_id":"2472"},
    {"city_id":"29534","city_name":"Soroca","state_id":"2472"},
    {"city_id":"29535","city_name":"Taraclia","state_id":"2473"},
    {"city_id":"29536","city_name":"Causani","state_id":"2474"},
    {"city_id":"29537","city_name":"Camenca","state_id":"2475"},
    {"city_id":"29538","city_name":"Dnestrovsc","state_id":"2475"},
    {"city_id":"29539","city_name":"Dubasari","state_id":"2475"},
    {"city_id":"29540","city_name":"Griguriopol","state_id":"2475"},
    {"city_id":"29541","city_name":"Rabnita","state_id":"2475"},
    {"city_id":"29542","city_name":"Slobozia","state_id":"2475"},
    {"city_id":"29543","city_name":"Tighina","state_id":"2475"},
    {"city_id":"29544","city_name":"Tiraspol'","state_id":"2475"},
    {"city_id":"29545","city_name":"Calarasi","state_id":"2476"},
    {"city_id":"29546","city_name":"Nisporeni","state_id":"2476"},
    {"city_id":"29547","city_name":"Ungheni","state_id":"2476"},
    {"city_id":"29548","city_name":"Fontvieille","state_id":"2477"},
    {"city_id":"29549","city_name":"Monaco-Ville","state_id":"2479"},
    {"city_id":"29550","city_name":"Cecerleg","state_id":"2481"},
    {"city_id":"29551","city_name":"Olgij","state_id":"2482"},
    {"city_id":"29552","city_name":"Bajanhongor","state_id":"2483"},
    {"city_id":"29553","city_name":"Bulgan","state_id":"2484"},
    {"city_id":"29554","city_name":"Darhan","state_id":"2485"},
    {"city_id":"29555","city_name":"Chojbalsan","state_id":"2486"},
    {"city_id":"29556","city_name":"Sajnshand","state_id":"2487"},
    {"city_id":"29557","city_name":"Mandalgovi","state_id":"2488"},
    {"city_id":"29558","city_name":"Altaj","state_id":"2489"},
    {"city_id":"29559","city_name":"Chojr","state_id":"2490"},
    {"city_id":"29560","city_name":"Ondorhaan","state_id":"2491"},
    {"city_id":"29561","city_name":"Hovd","state_id":"2492"},
    {"city_id":"29562","city_name":"Moron","state_id":"2493"},
    {"city_id":"29563","city_name":"Dalanzadgad","state_id":"2494"},
    {"city_id":"29564","city_name":"Erdenet","state_id":"2495"},
    {"city_id":"29565","city_name":"Arvajheer","state_id":"2496"},
    {"city_id":"29566","city_name":"Suhbaatar","state_id":"2497"},
    {"city_id":"29567","city_name":"Zuunharaa","state_id":"2497"},
    {"city_id":"29568","city_name":"Baruun-Urt","state_id":"2498"},
    {"city_id":"29569","city_name":"Nalajh","state_id":"2499"},
    {"city_id":"29570","city_name":"Zuunmod","state_id":"2499"},
    {"city_id":"29571","city_name":"Ulaanbaatar","state_id":"2500"},
    {"city_id":"29572","city_name":"Ulaangom","state_id":"2501"},
    {"city_id":"29573","city_name":"Tosoncengel","state_id":"2502"},
    {"city_id":"29574","city_name":"Uliastaj","state_id":"2502"},
    {"city_id":"29575","city_name":"Montserrat","state_id":"2503"},
    {"city_id":"29576","city_name":"Agadir","state_id":"2504"},
    {"city_id":"29577","city_name":"Mohammedia","state_id":"2505"},
    {"city_id":"29578","city_name":"ad-Dar-al-Bayda","state_id":"2505"},
    {"city_id":"29579","city_name":"Bin Sulayman","state_id":"2506"},
    {"city_id":"29580","city_name":"Bir Rashid","state_id":"2506"},
    {"city_id":"29581","city_name":"Bu-al-Jad","state_id":"2506"},
    {"city_id":"29582","city_name":"Buznika","state_id":"2506"},
    {"city_id":"29583","city_name":"Khuribghah","state_id":"2506"},
    {"city_id":"29584","city_name":"Sattat","state_id":"2506"},
    {"city_id":"29585","city_name":"Wad Zam","state_id":"2506"},
    {"city_id":"29586","city_name":"Asfi","state_id":"2507"},
    {"city_id":"29587","city_name":"Azimur","state_id":"2507"},
    {"city_id":"29588","city_name":"Sidi Binnur","state_id":"2507"},
    {"city_id":"29589","city_name":"Sidi Isma'il","state_id":"2507"},
    {"city_id":"29590","city_name":"Yussufiyah","state_id":"2507"},
    {"city_id":"29591","city_name":"al-Jadidah","state_id":"2507"},
    {"city_id":"29592","city_name":"Fas","state_id":"2508"},
    {"city_id":"29593","city_name":"Fes","state_id":"2508"},
    {"city_id":"29594","city_name":"Safru","state_id":"2508"},
    {"city_id":"29595","city_name":"Ghulimim","state_id":"2510"},
    {"city_id":"29596","city_name":"Sidi Ifni","state_id":"2510"},
    {"city_id":"29597","city_name":"Tafraut","state_id":"2510"},
    {"city_id":"29598","city_name":"Tantan","state_id":"2510"},
    {"city_id":"29599","city_name":"Kenitra","state_id":"2511"},
    {"city_id":"29600","city_name":"Amalu Ighriban","state_id":"2513"},
    {"city_id":"29601","city_name":"Azru","state_id":"2513"},
    {"city_id":"29602","city_name":"Jama'at-al-Marirt","state_id":"2513"},
    {"city_id":"29603","city_name":"Khanifrah","state_id":"2513"},
    {"city_id":"29604","city_name":"Meknes","state_id":"2513"},
    {"city_id":"29605","city_name":"Midalt","state_id":"2513"},
    {"city_id":"29606","city_name":"Miknas","state_id":"2513"},
    {"city_id":"29607","city_name":"Mulay Idris","state_id":"2513"},
    {"city_id":"29608","city_name":"al-Hajab","state_id":"2513"},
    {"city_id":"29609","city_name":"ar-Rasidiyah","state_id":"2513"},
    {"city_id":"29610","city_name":"Ahfir","state_id":"2514"},
    {"city_id":"29611","city_name":"Bin Ansar","state_id":"2514"},
    {"city_id":"29612","city_name":"Birkan","state_id":"2514"},
    {"city_id":"29613","city_name":"Fijij","state_id":"2514"},
    {"city_id":"29614","city_name":"Jaradah","state_id":"2514"},
    {"city_id":"29615","city_name":"Tawrirt","state_id":"2514"},
    {"city_id":"29616","city_name":"Ujdah","state_id":"2514"},
    {"city_id":"29617","city_name":"Zayu","state_id":"2514"},
    {"city_id":"29618","city_name":"al-Arwi","state_id":"2514"},
    {"city_id":"29619","city_name":"al-Ayun Sidi Malluk","state_id":"2514"},
    {"city_id":"29620","city_name":"an-Nadur","state_id":"2514"},
    {"city_id":"29621","city_name":"Oujda","state_id":"2515"},
    {"city_id":"29622","city_name":"Khamissat","state_id":"2517"},
    {"city_id":"29623","city_name":"Sakhirat","state_id":"2517"},
    {"city_id":"29624","city_name":"Tiflat","state_id":"2517"},
    {"city_id":"29625","city_name":"ar-Ribat","state_id":"2517"},
    {"city_id":"29626","city_name":"Settat","state_id":"2519"},
    {"city_id":"29627","city_name":"Bani Mallal","state_id":"2521"},
    {"city_id":"29628","city_name":"Beni Mellal","state_id":"2521"},
    {"city_id":"29629","city_name":"Qasbat Tadlah","state_id":"2521"},
    {"city_id":"29630","city_name":"Suq Sabt Awlat Nama","state_id":"2521"},
    {"city_id":"29631","city_name":"al-Faqih Bin Salah","state_id":"2521"},
    {"city_id":"29632","city_name":"Asilah","state_id":"2522"},
    {"city_id":"29633","city_name":"Finidiq","state_id":"2522"},
    {"city_id":"29634","city_name":"Martil","state_id":"2522"},
    {"city_id":"29635","city_name":"Midiq","state_id":"2522"},
    {"city_id":"29636","city_name":"Shifshawn","state_id":"2522"},
    {"city_id":"29637","city_name":"Tangier","state_id":"2522"},
    {"city_id":"29638","city_name":"Tanjah","state_id":"2522"},
    {"city_id":"29639","city_name":"Tittawin","state_id":"2522"},
    {"city_id":"29640","city_name":"Titwan","state_id":"2522"},
    {"city_id":"29641","city_name":"al-'Ara'ish","state_id":"2522"},
    {"city_id":"29642","city_name":"al-Qasr-al-Kabir","state_id":"2522"},
    {"city_id":"29643","city_name":"Chibuto","state_id":"2527"},
    {"city_id":"29644","city_name":"Chidenguele","state_id":"2527"},
    {"city_id":"29645","city_name":"Chokwe","state_id":"2527"},
    {"city_id":"29646","city_name":"Chongoene","state_id":"2527"},
    {"city_id":"29647","city_name":"Guija","state_id":"2527"},
    {"city_id":"29648","city_name":"Macia","state_id":"2527"},
    {"city_id":"29649","city_name":"Manjacaze","state_id":"2527"},
    {"city_id":"29650","city_name":"Xai-Xai","state_id":"2527"},
    {"city_id":"29651","city_name":"Homoine","state_id":"2528"},
    {"city_id":"29652","city_name":"Inhambane","state_id":"2528"},
    {"city_id":"29653","city_name":"Inharrime","state_id":"2528"},
    {"city_id":"29654","city_name":"Jangamo","state_id":"2528"},
    {"city_id":"29655","city_name":"Maxixe","state_id":"2528"},
    {"city_id":"29656","city_name":"Panda","state_id":"2528"},
    {"city_id":"29657","city_name":"Quissico","state_id":"2528"},
    {"city_id":"29658","city_name":"Chimoio","state_id":"2529"},
    {"city_id":"29659","city_name":"Manica","state_id":"2529"},
    {"city_id":"29660","city_name":"Maputo","state_id":"2530"},
    {"city_id":"29661","city_name":"Maputo","state_id":"2531"},
    {"city_id":"29662","city_name":"Angoche","state_id":"2532"},
    {"city_id":"29663","city_name":"Lumbo","state_id":"2532"},
    {"city_id":"29664","city_name":"Mocambique","state_id":"2532"},
    {"city_id":"29665","city_name":"Nacala","state_id":"2532"},
    {"city_id":"29666","city_name":"Nampula","state_id":"2532"},
    {"city_id":"29667","city_name":"Cuamba","state_id":"2533"},
    {"city_id":"29668","city_name":"Lichinga","state_id":"2533"},
    {"city_id":"29669","city_name":"Mandimba","state_id":"2533"},
    {"city_id":"29670","city_name":"Maniamba","state_id":"2533"},
    {"city_id":"29671","city_name":"Marrupa","state_id":"2533"},
    {"city_id":"29672","city_name":"Beira","state_id":"2534"},
    {"city_id":"29673","city_name":"Dondo","state_id":"2534"},
    {"city_id":"29674","city_name":"Tete","state_id":"2535"},
    {"city_id":"29675","city_name":"Zumbo","state_id":"2535"},
    {"city_id":"29676","city_name":"Garue","state_id":"2536"},
    {"city_id":"29677","city_name":"Mocuba","state_id":"2536"},
    {"city_id":"29678","city_name":"Quelimane","state_id":"2536"},
    {"city_id":"29679","city_name":"Bogale","state_id":"2537"},
    {"city_id":"29680","city_name":"Henzada","state_id":"2537"},
    {"city_id":"29681","city_name":"Kyaiklat","state_id":"2537"},
    {"city_id":"29682","city_name":"Labutta","state_id":"2537"},
    {"city_id":"29683","city_name":"Maubin","state_id":"2537"},
    {"city_id":"29684","city_name":"Moulmeingyun","state_id":"2537"},
    {"city_id":"29685","city_name":"Myanaung","state_id":"2537"},
    {"city_id":"29686","city_name":"Pathein","state_id":"2537"},
    {"city_id":"29687","city_name":"Pyapon","state_id":"2537"},
    {"city_id":"29688","city_name":"Wakema","state_id":"2537"},
    {"city_id":"29689","city_name":"Yandoon","state_id":"2537"},
    {"city_id":"29690","city_name":"Ye","state_id":"2537"},
    {"city_id":"29691","city_name":"Bago","state_id":"2538"},
    {"city_id":"29692","city_name":"Letpadan","state_id":"2538"},
    {"city_id":"29693","city_name":"Nyaunglebin","state_id":"2538"},
    {"city_id":"29694","city_name":"Paungde","state_id":"2538"},
    {"city_id":"29695","city_name":"Pyay","state_id":"2538"},
    {"city_id":"29696","city_name":"Pyu","state_id":"2538"},
    {"city_id":"29697","city_name":"Thanatpin","state_id":"2538"},
    {"city_id":"29698","city_name":"Tharrawaddy","state_id":"2538"},
    {"city_id":"29699","city_name":"Toungoo","state_id":"2538"},
    {"city_id":"29700","city_name":"Falam","state_id":"2539"},
    {"city_id":"29701","city_name":"Banmo","state_id":"2540"},
    {"city_id":"29702","city_name":"Myitkyina","state_id":"2540"},
    {"city_id":"29703","city_name":"Loikaw","state_id":"2541"},
    {"city_id":"29704","city_name":"Hpa-an","state_id":"2542"},
    {"city_id":"29705","city_name":"Allanmyo","state_id":"2543"},
    {"city_id":"29706","city_name":"Chauk","state_id":"2543"},
    {"city_id":"29707","city_name":"Magway","state_id":"2543"},
    {"city_id":"29708","city_name":"Minbu","state_id":"2543"},
    {"city_id":"29709","city_name":"Pakokku","state_id":"2543"},
    {"city_id":"29710","city_name":"Taungdwingyi","state_id":"2543"},
    {"city_id":"29711","city_name":"Thayetmyo","state_id":"2543"},
    {"city_id":"29712","city_name":"Yenangyaung","state_id":"2543"},
    {"city_id":"29713","city_name":"Kyaukse","state_id":"2544"},
    {"city_id":"29714","city_name":"Mandalay","state_id":"2544"},
    {"city_id":"29715","city_name":"Maymyo","state_id":"2544"},
    {"city_id":"29716","city_name":"Meiktila","state_id":"2544"},
    {"city_id":"29717","city_name":"Mogok","state_id":"2544"},
    {"city_id":"29718","city_name":"Myingyan","state_id":"2544"},
    {"city_id":"29719","city_name":"Pyinmana","state_id":"2544"},
    {"city_id":"29720","city_name":"Yamethin","state_id":"2544"},
    {"city_id":"29721","city_name":"Kyaikkami","state_id":"2545"},
    {"city_id":"29722","city_name":"Kyaikto","state_id":"2545"},
    {"city_id":"29723","city_name":"Martaban","state_id":"2545"},
    {"city_id":"29724","city_name":"Mawlamyine","state_id":"2545"},
    {"city_id":"29725","city_name":"Mudon","state_id":"2545"},
    {"city_id":"29726","city_name":"Thaton","state_id":"2545"},
    {"city_id":"29727","city_name":"Akyab","state_id":"2547"},
    {"city_id":"29728","city_name":"Arakan","state_id":"2547"},
    {"city_id":"29729","city_name":"Kalay","state_id":"2548"},
    {"city_id":"29730","city_name":"Katha","state_id":"2548"},
    {"city_id":"29731","city_name":"Mawlaik","state_id":"2548"},
    {"city_id":"29732","city_name":"Monywa","state_id":"2548"},
    {"city_id":"29733","city_name":"Sagaing","state_id":"2548"},
    {"city_id":"29734","city_name":"Shwebo","state_id":"2548"},
    {"city_id":"29735","city_name":"Keng Tung","state_id":"2549"},
    {"city_id":"29736","city_name":"Lashio","state_id":"2549"},
    {"city_id":"29737","city_name":"Taunggyi","state_id":"2549"},
    {"city_id":"29738","city_name":"Dawei","state_id":"2550"},
    {"city_id":"29739","city_name":"Mergui","state_id":"2550"},
    {"city_id":"29740","city_name":"Kanbe","state_id":"2551"},
    {"city_id":"29741","city_name":"Kayan","state_id":"2551"},
    {"city_id":"29742","city_name":"Syriam","state_id":"2551"},
    {"city_id":"29743","city_name":"Thongwa","state_id":"2551"},
    {"city_id":"29744","city_name":"Twante","state_id":"2551"},
    {"city_id":"29745","city_name":"Yangon","state_id":"2551"},
    {"city_id":"29746","city_name":"Katima Mulilo","state_id":"2552"},
    {"city_id":"29747","city_name":"Henties Bay","state_id":"2553"},
    {"city_id":"29748","city_name":"Karibib","state_id":"2553"},
    {"city_id":"29749","city_name":"Kuisebmond","state_id":"2553"},
    {"city_id":"29750","city_name":"Omaruru","state_id":"2553"},
    {"city_id":"29751","city_name":"Otjimbingwe","state_id":"2553"},
    {"city_id":"29752","city_name":"Swakopmund","state_id":"2553"},
    {"city_id":"29753","city_name":"Usakos","state_id":"2553"},
    {"city_id":"29754","city_name":"Walvis Bay","state_id":"2553"},
    {"city_id":"29755","city_name":"Aranos","state_id":"2554"},
    {"city_id":"29756","city_name":"Maltahohe","state_id":"2554"},
    {"city_id":"29757","city_name":"Mariental","state_id":"2554"},
    {"city_id":"29758","city_name":"Rehoboth","state_id":"2554"},
    {"city_id":"29759","city_name":"Bethanien","state_id":"2555"},
    {"city_id":"29760","city_name":"Karasburg","state_id":"2555"},
    {"city_id":"29761","city_name":"Keetmanshoop","state_id":"2555"},
    {"city_id":"29762","city_name":"Luderitz","state_id":"2555"},
    {"city_id":"29763","city_name":"Oranjemund","state_id":"2555"},
    {"city_id":"29764","city_name":"Warmbad","state_id":"2555"},
    {"city_id":"29765","city_name":"Rundu","state_id":"2556"},
    {"city_id":"29766","city_name":"Windhoek","state_id":"2557"},
    {"city_id":"29767","city_name":"Arandis","state_id":"2558"},
    {"city_id":"29768","city_name":"Khorixas","state_id":"2558"},
    {"city_id":"29769","city_name":"Opuwo","state_id":"2558"},
    {"city_id":"29770","city_name":"Outjo","state_id":"2558"},
    {"city_id":"29771","city_name":"Oshikango","state_id":"2559"},
    {"city_id":"29772","city_name":"Gobabis","state_id":"2560"},
    {"city_id":"29773","city_name":"Leonardville","state_id":"2560"},
    {"city_id":"29774","city_name":"Ongandjera","state_id":"2561"},
    {"city_id":"29775","city_name":"Ongwediva","state_id":"2562"},
    {"city_id":"29776","city_name":"Oshakati","state_id":"2562"},
    {"city_id":"29777","city_name":"Ondangwa","state_id":"2563"},
    {"city_id":"29778","city_name":"Otavi","state_id":"2563"},
    {"city_id":"29779","city_name":"Tsumeb","state_id":"2563"},
    {"city_id":"29780","city_name":"Grootfontein","state_id":"2564"},
    {"city_id":"29781","city_name":"Okahandja","state_id":"2564"},
    {"city_id":"29782","city_name":"Okakarara","state_id":"2564"},
    {"city_id":"29783","city_name":"Otjiwarongo","state_id":"2564"},
    {"city_id":"29784","city_name":"Yaren","state_id":"2565"},
    {"city_id":"29785","city_name":"Banepa","state_id":"2566"},
    {"city_id":"29786","city_name":"Bhaktapur","state_id":"2566"},
    {"city_id":"29787","city_name":"Bidur","state_id":"2566"},
    {"city_id":"29788","city_name":"Bishalter","state_id":"2566"},
    {"city_id":"29789","city_name":"Dhulikhel","state_id":"2566"},
    {"city_id":"29790","city_name":"Kathmandu","state_id":"2566"},
    {"city_id":"29791","city_name":"Kirtipur","state_id":"2566"},
    {"city_id":"29792","city_name":"Lalitpur","state_id":"2566"},
    {"city_id":"29793","city_name":"Madhyapur Thimi","state_id":"2566"},
    {"city_id":"29794","city_name":"Panauti","state_id":"2566"},
    {"city_id":"29795","city_name":"Tribuvannagar","state_id":"2566"},
    {"city_id":"29796","city_name":"Birendranagar","state_id":"2567"},
    {"city_id":"29797","city_name":"Gulariya","state_id":"2567"},
    {"city_id":"29798","city_name":"Narayan","state_id":"2567"},
    {"city_id":"29799","city_name":"Nepalganj","state_id":"2567"},
    {"city_id":"29800","city_name":"Surkhet","state_id":"2567"},
    {"city_id":"29801","city_name":"Baglung","state_id":"2568"},
    {"city_id":"29802","city_name":"Weni","state_id":"2568"},
    {"city_id":"29803","city_name":"Byas","state_id":"2569"},
    {"city_id":"29804","city_name":"Leknath","state_id":"2569"},
    {"city_id":"29805","city_name":"Pokhara","state_id":"2569"},
    {"city_id":"29806","city_name":"Prithivinarayan","state_id":"2569"},
    {"city_id":"29807","city_name":"Putalibazar","state_id":"2569"},
    {"city_id":"29808","city_name":"Waling","state_id":"2569"},
    {"city_id":"29809","city_name":"Bhimeshwar","state_id":"2570"},
    {"city_id":"29810","city_name":"Jaleshwar","state_id":"2570"},
    {"city_id":"29811","city_name":"Janakpur","state_id":"2570"},
    {"city_id":"29812","city_name":"Kamalamai","state_id":"2570"},
    {"city_id":"29813","city_name":"Malangwa","state_id":"2570"},
    {"city_id":"29814","city_name":"Sinduli Marhi","state_id":"2570"},
    {"city_id":"29815","city_name":"Jumla","state_id":"2571"},
    {"city_id":"29816","city_name":"Biratnagar","state_id":"2572"},
    {"city_id":"29817","city_name":"Dhankuta","state_id":"2572"},
    {"city_id":"29818","city_name":"Dharan","state_id":"2572"},
    {"city_id":"29819","city_name":"Inaruwa","state_id":"2572"},
    {"city_id":"29820","city_name":"Itahari","state_id":"2572"},
    {"city_id":"29821","city_name":"Khandbari","state_id":"2572"},
    {"city_id":"29822","city_name":"Butwal","state_id":"2573"},
    {"city_id":"29823","city_name":"Kapilwastu","state_id":"2573"},
    {"city_id":"29824","city_name":"Ramgram","state_id":"2573"},
    {"city_id":"29825","city_name":"Sidharthanagar","state_id":"2573"},
    {"city_id":"29826","city_name":"Tansen","state_id":"2573"},
    {"city_id":"29827","city_name":"Wahadurganj","state_id":"2573"},
    {"city_id":"29828","city_name":"Amargadhi","state_id":"2574"},
    {"city_id":"29829","city_name":"Dashrathchand","state_id":"2574"},
    {"city_id":"29830","city_name":"Mahendranagar","state_id":"2574"},
    {"city_id":"29831","city_name":"Bhadrapur","state_id":"2575"},
    {"city_id":"29832","city_name":"Damak","state_id":"2575"},
    {"city_id":"29833","city_name":"Ilam","state_id":"2575"},
    {"city_id":"29834","city_name":"Mechinagar","state_id":"2575"},
    {"city_id":"29835","city_name":"Bharatpur","state_id":"2576"},
    {"city_id":"29836","city_name":"Birganj","state_id":"2576"},
    {"city_id":"29837","city_name":"Chitwan","state_id":"2576"},
    {"city_id":"29838","city_name":"Gaur","state_id":"2576"},
    {"city_id":"29839","city_name":"Hetauda","state_id":"2576"},
    {"city_id":"29840","city_name":"Kalaiya","state_id":"2576"},
    {"city_id":"29841","city_name":"Ratnanagar","state_id":"2576"},
    {"city_id":"29842","city_name":"Salyan","state_id":"2577"},
    {"city_id":"29843","city_name":"Tulsipur","state_id":"2577"},
    {"city_id":"29844","city_name":"Lahan","state_id":"2578"},
    {"city_id":"29845","city_name":"Rajbiraj","state_id":"2578"},
    {"city_id":"29846","city_name":"Siraha","state_id":"2578"},
    {"city_id":"29847","city_name":"Triyuga","state_id":"2578"},
    {"city_id":"29848","city_name":"Dhangadi","state_id":"2579"},
    {"city_id":"29849","city_name":"Dipayal","state_id":"2579"},
    {"city_id":"29850","city_name":"Tikapur","state_id":"2579"},
    {"city_id":"29851","city_name":"Kralendijk","state_id":"2580"},
    {"city_id":"29852","city_name":"Curacao","state_id":"2581"},
    {"city_id":"29853","city_name":"Willemstad","state_id":"2581"},
    {"city_id":"29854","city_name":"The Bottom","state_id":"2582"},
    {"city_id":"29855","city_name":"SchipolRijk","state_id":"2585"},
    {"city_id":"29856","city_name":"Amstelveen","state_id":"2586"},
    {"city_id":"29857","city_name":"Aa en Hunze","state_id":"2587"},
    {"city_id":"29858","city_name":"Assen","state_id":"2587"},
    {"city_id":"29859","city_name":"Borger-Odoorn","state_id":"2587"},
    {"city_id":"29860","city_name":"Coevorden","state_id":"2587"},
    {"city_id":"29861","city_name":"De Wolden","state_id":"2587"},
    {"city_id":"29862","city_name":"Den Oever","state_id":"2587"},
    {"city_id":"29863","city_name":"Emmen","state_id":"2587"},
    {"city_id":"29864","city_name":"Gasteren","state_id":"2587"},
    {"city_id":"29865","city_name":"Hoogeveen","state_id":"2587"},
    {"city_id":"29866","city_name":"Menterwolde","state_id":"2587"},
    {"city_id":"29867","city_name":"Meppel","state_id":"2587"},
    {"city_id":"29868","city_name":"Midden-Drenthe","state_id":"2587"},
    {"city_id":"29869","city_name":"Noordenveld","state_id":"2587"},
    {"city_id":"29870","city_name":"Stadskanaal","state_id":"2587"},
    {"city_id":"29871","city_name":"Tynaarlo","state_id":"2587"},
    {"city_id":"29872","city_name":"Veenoord","state_id":"2587"},
    {"city_id":"29873","city_name":"Westerveld","state_id":"2587"},
    {"city_id":"29874","city_name":"Zuidlaren","state_id":"2587"},
    {"city_id":"29875","city_name":"Almere","state_id":"2588"},
    {"city_id":"29876","city_name":"Dronten","state_id":"2588"},
    {"city_id":"29877","city_name":"Lelystad","state_id":"2588"},
    {"city_id":"29878","city_name":"Noordoostpolder","state_id":"2588"},
    {"city_id":"29879","city_name":"Urk","state_id":"2588"},
    {"city_id":"29880","city_name":"Zeewolde","state_id":"2588"},
    {"city_id":"29881","city_name":"Achtkarspelen","state_id":"2589"},
    {"city_id":"29882","city_name":"Ameland","state_id":"2589"},
    {"city_id":"29883","city_name":"Boarnsterhim","state_id":"2589"},
    {"city_id":"29884","city_name":"Bolsward","state_id":"2589"},
    {"city_id":"29885","city_name":"Dantumadeel","state_id":"2589"},
    {"city_id":"29886","city_name":"Dongeradeel","state_id":"2589"},
    {"city_id":"29887","city_name":"Drachten","state_id":"2589"},
    {"city_id":"29888","city_name":"Ferwerderadiel","state_id":"2589"},
    {"city_id":"29889","city_name":"Franekeradeel","state_id":"2589"},
    {"city_id":"29890","city_name":"Gaasterlan-Sleat","state_id":"2589"},
    {"city_id":"29891","city_name":"Gorredijk","state_id":"2589"},
    {"city_id":"29892","city_name":"Harlingen","state_id":"2589"},
    {"city_id":"29893","city_name":"Heerenveen","state_id":"2589"},
    {"city_id":"29894","city_name":"Het Bildt","state_id":"2589"},
    {"city_id":"29895","city_name":"Kollumerland","state_id":"2589"},
    {"city_id":"29896","city_name":"Leeuwarden","state_id":"2589"},
    {"city_id":"29897","city_name":"Leeuwarderadeel","state_id":"2589"},
    {"city_id":"29898","city_name":"Lemsterland","state_id":"2589"},
    {"city_id":"29899","city_name":"Littenseradiel","state_id":"2589"},
    {"city_id":"29900","city_name":"Menaldumadeel","state_id":"2589"},
    {"city_id":"29901","city_name":"Nijefurd","state_id":"2589"},
    {"city_id":"29902","city_name":"Oostrum","state_id":"2589"},
    {"city_id":"29903","city_name":"Ooststellingwerf","state_id":"2589"},
    {"city_id":"29904","city_name":"Opsterland","state_id":"2589"},
    {"city_id":"29905","city_name":"Schiermonnikoog","state_id":"2589"},
    {"city_id":"29906","city_name":"Skasterlan","state_id":"2589"},
    {"city_id":"29907","city_name":"Smallingerland","state_id":"2589"},
    {"city_id":"29908","city_name":"Sneek","state_id":"2589"},
    {"city_id":"29909","city_name":"Terschelling","state_id":"2589"},
    {"city_id":"29910","city_name":"Tytsjerksteradiel","state_id":"2589"},
    {"city_id":"29911","city_name":"Ureterp","state_id":"2589"},
    {"city_id":"29912","city_name":"Weststellingwerf","state_id":"2589"},
    {"city_id":"29913","city_name":"Wolvega","state_id":"2589"},
    {"city_id":"29914","city_name":"Wunseradiel","state_id":"2589"},
    {"city_id":"29915","city_name":"Wymbritseradiel","state_id":"2589"},
    {"city_id":"29916","city_name":"Aalten","state_id":"2590"},
    {"city_id":"29917","city_name":"Angerlo","state_id":"2590"},
    {"city_id":"29918","city_name":"Apeldoorn","state_id":"2590"},
    {"city_id":"29919","city_name":"Appeldoorn","state_id":"2590"},
    {"city_id":"29920","city_name":"Arnhem","state_id":"2590"},
    {"city_id":"29921","city_name":"Barneveld","state_id":"2590"},
    {"city_id":"29922","city_name":"Bemmel","state_id":"2590"},
    {"city_id":"29923","city_name":"Bergh","state_id":"2590"},
    {"city_id":"29924","city_name":"Beuningen","state_id":"2590"},
    {"city_id":"29925","city_name":"Borculo","state_id":"2590"},
    {"city_id":"29926","city_name":"Brummen","state_id":"2590"},
    {"city_id":"29927","city_name":"Buren","state_id":"2590"},
    {"city_id":"29928","city_name":"Culemborg","state_id":"2590"},
    {"city_id":"29929","city_name":"Delden","state_id":"2590"},
    {"city_id":"29930","city_name":"Didam","state_id":"2590"},
    {"city_id":"29931","city_name":"Dieren","state_id":"2590"},
    {"city_id":"29932","city_name":"Dinxperlo","state_id":"2590"},
    {"city_id":"29933","city_name":"Dodewaard","state_id":"2590"},
    {"city_id":"29934","city_name":"Doesburg","state_id":"2590"},
    {"city_id":"29935","city_name":"Doetinchem","state_id":"2590"},
    {"city_id":"29936","city_name":"Druten","state_id":"2590"},
    {"city_id":"29937","city_name":"Duiven","state_id":"2590"},
    {"city_id":"29938","city_name":"Ede","state_id":"2590"},
    {"city_id":"29939","city_name":"Eerbeek","state_id":"2590"},
    {"city_id":"29940","city_name":"Eibergen","state_id":"2590"},
    {"city_id":"29941","city_name":"Elburg","state_id":"2590"},
    {"city_id":"29942","city_name":"Epe","state_id":"2590"},
    {"city_id":"29943","city_name":"Ermelo","state_id":"2590"},
    {"city_id":"29944","city_name":"Geldermalsen","state_id":"2590"},
    {"city_id":"29945","city_name":"Gendringen","state_id":"2590"},
    {"city_id":"29946","city_name":"Giesbeek","state_id":"2590"},
    {"city_id":"29947","city_name":"Gorssel","state_id":"2590"},
    {"city_id":"29948","city_name":"Groenlo","state_id":"2590"},
    {"city_id":"29949","city_name":"Groesbeek","state_id":"2590"},
    {"city_id":"29950","city_name":"Harderwijk","state_id":"2590"},
    {"city_id":"29951","city_name":"Hattem","state_id":"2590"},
    {"city_id":"29952","city_name":"Heerde","state_id":"2590"},
    {"city_id":"29953","city_name":"Hengelo","state_id":"2590"},
    {"city_id":"29954","city_name":"Heumen","state_id":"2590"},
    {"city_id":"29955","city_name":"Huisen","state_id":"2590"},
    {"city_id":"29956","city_name":"Hummelo en Keppel","state_id":"2590"},
    {"city_id":"29957","city_name":"Kesteren","state_id":"2590"},
    {"city_id":"29958","city_name":"Kootwijkerbroek","state_id":"2590"},
    {"city_id":"29959","city_name":"Leerdam","state_id":"2590"},
    {"city_id":"29960","city_name":"Leeuwen","state_id":"2590"},
    {"city_id":"29961","city_name":"Lichtenvoorde","state_id":"2590"},
    {"city_id":"29962","city_name":"Lingewaal","state_id":"2590"},
    {"city_id":"29963","city_name":"Lochem","state_id":"2590"},
    {"city_id":"29964","city_name":"Loppersum","state_id":"2590"},
    {"city_id":"29965","city_name":"Maasdriel","state_id":"2590"},
    {"city_id":"29966","city_name":"Malden","state_id":"2590"},
    {"city_id":"29967","city_name":"Millingen","state_id":"2590"},
    {"city_id":"29968","city_name":"Molenhoek","state_id":"2590"},
    {"city_id":"29969","city_name":"Neede","state_id":"2590"},
    {"city_id":"29970","city_name":"Neerijnen","state_id":"2590"},
    {"city_id":"29971","city_name":"Nijkerk","state_id":"2590"},
    {"city_id":"29972","city_name":"Nijmegen","state_id":"2590"},
    {"city_id":"29973","city_name":"Nunspeet","state_id":"2590"},
    {"city_id":"29974","city_name":"Oldebroek","state_id":"2590"},
    {"city_id":"29975","city_name":"Oosterbeek","state_id":"2590"},
    {"city_id":"29976","city_name":"Overbetuwe","state_id":"2590"},
    {"city_id":"29977","city_name":"Putten","state_id":"2590"},
    {"city_id":"29978","city_name":"Renkum","state_id":"2590"},
    {"city_id":"29979","city_name":"Rheden","state_id":"2590"},
    {"city_id":"29980","city_name":"Rijnwaarden","state_id":"2590"},
    {"city_id":"29981","city_name":"Rozendaal","state_id":"2590"},
    {"city_id":"29982","city_name":"Ruurlo","state_id":"2590"},
    {"city_id":"29983","city_name":"Scherpenzeel","state_id":"2590"},
    {"city_id":"29984","city_name":"Steenderen","state_id":"2590"},
    {"city_id":"29985","city_name":"Terborg","state_id":"2590"},
    {"city_id":"29986","city_name":"Tiel","state_id":"2590"},
    {"city_id":"29987","city_name":"Twello","state_id":"2590"},
    {"city_id":"29988","city_name":"Ubbergen","state_id":"2590"},
    {"city_id":"29989","city_name":"Vaassen","state_id":"2590"},
    {"city_id":"29990","city_name":"Varsseveld","state_id":"2590"},
    {"city_id":"29991","city_name":"Voorst","state_id":"2590"},
    {"city_id":"29992","city_name":"Vorden","state_id":"2590"},
    {"city_id":"29993","city_name":"Waardenburg","state_id":"2590"},
    {"city_id":"29994","city_name":"Wageningen","state_id":"2590"},
    {"city_id":"29995","city_name":"Warmsveld","state_id":"2590"},
    {"city_id":"29996","city_name":"Wehl","state_id":"2590"},
    {"city_id":"29997","city_name":"Westervoort","state_id":"2590"},
    {"city_id":"29998","city_name":"Wijchen","state_id":"2590"},
    {"city_id":"29999","city_name":"Winterswijk","state_id":"2590"},
    {"city_id":"30000","city_name":"Wisch","state_id":"2590"},
    {"city_id":"30001","city_name":"Zaltbommel","state_id":"2590"},
    {"city_id":"30002","city_name":"Zelhem","state_id":"2590"},
    {"city_id":"30003","city_name":"Zevenaar","state_id":"2590"},
    {"city_id":"30004","city_name":"Zutphen","state_id":"2590"},
    {"city_id":"30005","city_name":"s-Heerenberg","state_id":"2590"},
    {"city_id":"30006","city_name":"Appingedam","state_id":"2591"},
    {"city_id":"30007","city_name":"Bedum","state_id":"2591"},
    {"city_id":"30008","city_name":"Bellingwedde","state_id":"2591"},
    {"city_id":"30009","city_name":"De Marne","state_id":"2591"},
    {"city_id":"30010","city_name":"Delfzijl","state_id":"2591"},
    {"city_id":"30011","city_name":"Eemsmond","state_id":"2591"},
    {"city_id":"30012","city_name":"Groningen","state_id":"2591"},
    {"city_id":"30013","city_name":"Grootegast","state_id":"2591"},
    {"city_id":"30014","city_name":"Haren","state_id":"2591"},
    {"city_id":"30015","city_name":"Hoogezand-Sappemeer","state_id":"2591"},
    {"city_id":"30016","city_name":"Leek","state_id":"2591"},
    {"city_id":"30017","city_name":"Marum","state_id":"2591"},
    {"city_id":"30018","city_name":"Midwolda","state_id":"2591"},
    {"city_id":"30019","city_name":"Muntendam","state_id":"2591"},
    {"city_id":"30020","city_name":"Pekela","state_id":"2591"},
    {"city_id":"30021","city_name":"Reiderland","state_id":"2591"},
    {"city_id":"30022","city_name":"Scheemda","state_id":"2591"},
    {"city_id":"30023","city_name":"Slochteren","state_id":"2591"},
    {"city_id":"30024","city_name":"Ten Boer","state_id":"2591"},
    {"city_id":"30025","city_name":"Tolbert","state_id":"2591"},
    {"city_id":"30026","city_name":"Veendam","state_id":"2591"},
    {"city_id":"30027","city_name":"Vlagtwedde","state_id":"2591"},
    {"city_id":"30028","city_name":"Winschoten","state_id":"2591"},
    {"city_id":"30029","city_name":"Winsum","state_id":"2591"},
    {"city_id":"30030","city_name":"Zuidhorn","state_id":"2591"},
    {"city_id":"30031","city_name":"Ambt Montfort","state_id":"2592"},
    {"city_id":"30032","city_name":"Arcen en Velden","state_id":"2592"},
    {"city_id":"30033","city_name":"Beek","state_id":"2592"},
    {"city_id":"30034","city_name":"Beesel","state_id":"2592"},
    {"city_id":"30035","city_name":"Bergen","state_id":"2592"},
    {"city_id":"30036","city_name":"Blerick","state_id":"2592"},
    {"city_id":"30037","city_name":"Brunssum","state_id":"2592"},
    {"city_id":"30038","city_name":"Echt","state_id":"2592"},
    {"city_id":"30039","city_name":"Eijsden","state_id":"2592"},
    {"city_id":"30040","city_name":"Gennep","state_id":"2592"},
    {"city_id":"30041","city_name":"Gulpen-Wittem","state_id":"2592"},
    {"city_id":"30042","city_name":"Haelen","state_id":"2592"},
    {"city_id":"30043","city_name":"Heel","state_id":"2592"},
    {"city_id":"30044","city_name":"Heerlen","state_id":"2592"},
    {"city_id":"30045","city_name":"Helden","state_id":"2592"},
    {"city_id":"30046","city_name":"Heythuysen","state_id":"2592"},
    {"city_id":"30047","city_name":"Horst","state_id":"2592"},
    {"city_id":"30048","city_name":"Hunsel","state_id":"2592"},
    {"city_id":"30049","city_name":"Kerkrade","state_id":"2592"},
    {"city_id":"30050","city_name":"Kessel","state_id":"2592"},
    {"city_id":"30051","city_name":"Landgraaf","state_id":"2592"},
    {"city_id":"30052","city_name":"Maasbracht","state_id":"2592"},
    {"city_id":"30053","city_name":"Maasbree","state_id":"2592"},
    {"city_id":"30054","city_name":"Maastricht","state_id":"2592"},
    {"city_id":"30055","city_name":"Margraten","state_id":"2592"},
    {"city_id":"30056","city_name":"Meerlo-Wanssum","state_id":"2592"},
    {"city_id":"30057","city_name":"Meerssen","state_id":"2592"},
    {"city_id":"30058","city_name":"Meijel","state_id":"2592"},
    {"city_id":"30059","city_name":"Mook en Middelaar","state_id":"2592"},
    {"city_id":"30060","city_name":"Nederweert","state_id":"2592"},
    {"city_id":"30061","city_name":"Nuth","state_id":"2592"},
    {"city_id":"30062","city_name":"Onderbanken","state_id":"2592"},
    {"city_id":"30063","city_name":"Roerdalen","state_id":"2592"},
    {"city_id":"30064","city_name":"Roermond","state_id":"2592"},
    {"city_id":"30065","city_name":"Roggel","state_id":"2592"},
    {"city_id":"30066","city_name":"Roggel en Neer","state_id":"2592"},
    {"city_id":"30067","city_name":"Schinnen","state_id":"2592"},
    {"city_id":"30068","city_name":"Sevenum","state_id":"2592"},
    {"city_id":"30069","city_name":"Simpelveld","state_id":"2592"},
    {"city_id":"30070","city_name":"Sittard","state_id":"2592"},
    {"city_id":"30071","city_name":"Sittard-Geleen","state_id":"2592"},
    {"city_id":"30072","city_name":"Stein","state_id":"2592"},
    {"city_id":"30073","city_name":"Stramproy","state_id":"2592"},
    {"city_id":"30074","city_name":"Susteren","state_id":"2592"},
    {"city_id":"30075","city_name":"Swalmen","state_id":"2592"},
    {"city_id":"30076","city_name":"Tegelen","state_id":"2592"},
    {"city_id":"30077","city_name":"Thorn","state_id":"2592"},
    {"city_id":"30078","city_name":"Vaals","state_id":"2592"},
    {"city_id":"30079","city_name":"Valkenburg","state_id":"2592"},
    {"city_id":"30080","city_name":"Venlo","state_id":"2592"},
    {"city_id":"30081","city_name":"Venray","state_id":"2592"},
    {"city_id":"30082","city_name":"Vilt Limburg","state_id":"2592"},
    {"city_id":"30083","city_name":"Voerendaal","state_id":"2592"},
    {"city_id":"30084","city_name":"Weert","state_id":"2592"},
    {"city_id":"30085","city_name":"'s-Hertogenbosch","state_id":"2593"},
    {"city_id":"30086","city_name":"Aalburg","state_id":"2593"},
    {"city_id":"30087","city_name":"Alphen-Chaam","state_id":"2593"},
    {"city_id":"30088","city_name":"Asten","state_id":"2593"},
    {"city_id":"30089","city_name":"Baarle-Nassau","state_id":"2593"},
    {"city_id":"30090","city_name":"Bergeijk","state_id":"2593"},
    {"city_id":"30091","city_name":"Bergen op Zoom","state_id":"2593"},
    {"city_id":"30092","city_name":"Berghem","state_id":"2593"},
    {"city_id":"30093","city_name":"Bernheze","state_id":"2593"},
    {"city_id":"30094","city_name":"Bernisse","state_id":"2593"},
    {"city_id":"30095","city_name":"Best","state_id":"2593"},
    {"city_id":"30096","city_name":"Bladel","state_id":"2593"},
    {"city_id":"30097","city_name":"Boekel","state_id":"2593"},
    {"city_id":"30098","city_name":"Boxmeer","state_id":"2593"},
    {"city_id":"30099","city_name":"Boxtel","state_id":"2593"},
    {"city_id":"30100","city_name":"Breda","state_id":"2593"},
    {"city_id":"30101","city_name":"Budel","state_id":"2593"},
    {"city_id":"30102","city_name":"Cranendonck","state_id":"2593"},
    {"city_id":"30103","city_name":"Cuijk","state_id":"2593"},
    {"city_id":"30104","city_name":"Den Bosch","state_id":"2593"},
    {"city_id":"30105","city_name":"Den Dungen","state_id":"2593"},
    {"city_id":"30106","city_name":"Deurne","state_id":"2593"},
    {"city_id":"30107","city_name":"Dongen","state_id":"2593"},
    {"city_id":"30108","city_name":"Drimmelen","state_id":"2593"},
    {"city_id":"30109","city_name":"Drunen","state_id":"2593"},
    {"city_id":"30110","city_name":"Duizel","state_id":"2593"},
    {"city_id":"30111","city_name":"Eersel","state_id":"2593"},
    {"city_id":"30112","city_name":"Eindhoven","state_id":"2593"},
    {"city_id":"30113","city_name":"Etten-Leur","state_id":"2593"},
    {"city_id":"30114","city_name":"Geertruidenberg","state_id":"2593"},
    {"city_id":"30115","city_name":"Geldrop","state_id":"2593"},
    {"city_id":"30116","city_name":"Gemert-Bakel","state_id":"2593"},
    {"city_id":"30117","city_name":"Gilze en Rijen","state_id":"2593"},
    {"city_id":"30118","city_name":"Goirle","state_id":"2593"},
    {"city_id":"30119","city_name":"Grave","state_id":"2593"},
    {"city_id":"30120","city_name":"Haaren","state_id":"2593"},
    {"city_id":"30121","city_name":"Halderberge","state_id":"2593"},
    {"city_id":"30122","city_name":"Heeze-Leende","state_id":"2593"},
    {"city_id":"30123","city_name":"Heijningen","state_id":"2593"},
    {"city_id":"30124","city_name":"Helmond","state_id":"2593"},
    {"city_id":"30125","city_name":"Heusden","state_id":"2593"},
    {"city_id":"30126","city_name":"Hilvarenbeek","state_id":"2593"},
    {"city_id":"30127","city_name":"Hoeven","state_id":"2593"},
    {"city_id":"30128","city_name":"Hoogerheide","state_id":"2593"},
    {"city_id":"30129","city_name":"Kaatsheuvel","state_id":"2593"},
    {"city_id":"30130","city_name":"Korendijk","state_id":"2593"},
    {"city_id":"30131","city_name":"Laarbeek","state_id":"2593"},
    {"city_id":"30132","city_name":"Landerd","state_id":"2593"},
    {"city_id":"30133","city_name":"Lith","state_id":"2593"},
    {"city_id":"30134","city_name":"Loon op Zand","state_id":"2593"},
    {"city_id":"30135","city_name":"Maarheeze","state_id":"2593"},
    {"city_id":"30136","city_name":"Maasdonk","state_id":"2593"},
    {"city_id":"30137","city_name":"Mierlo","state_id":"2593"},
    {"city_id":"30138","city_name":"Mill en Sint Hubert","state_id":"2593"},
    {"city_id":"30139","city_name":"Moerdijk","state_id":"2593"},
    {"city_id":"30140","city_name":"Nieuwkuijk","state_id":"2593"},
    {"city_id":"30141","city_name":"Nuenen","state_id":"2593"},
    {"city_id":"30142","city_name":"Oirschot","state_id":"2593"},
    {"city_id":"30143","city_name":"Oisterwijk","state_id":"2593"},
    {"city_id":"30144","city_name":"Oosterhout","state_id":"2593"},
    {"city_id":"30145","city_name":"Oss","state_id":"2593"},
    {"city_id":"30146","city_name":"Raamsdonksveer","state_id":"2593"},
    {"city_id":"30147","city_name":"Ravenstein","state_id":"2593"},
    {"city_id":"30148","city_name":"Reusel-De Mierden","state_id":"2593"},
    {"city_id":"30149","city_name":"Roosendaal","state_id":"2593"},
    {"city_id":"30150","city_name":"Rosmalen","state_id":"2593"},
    {"city_id":"30151","city_name":"Rucphen","state_id":"2593"},
    {"city_id":"30152","city_name":"Schaijk","state_id":"2593"},
    {"city_id":"30153","city_name":"Schijndel","state_id":"2593"},
    {"city_id":"30154","city_name":"Sint Anthonis","state_id":"2593"},
    {"city_id":"30155","city_name":"Sint Willebrord","state_id":"2593"},
    {"city_id":"30156","city_name":"Sint-Michielsgestel","state_id":"2593"},
    {"city_id":"30157","city_name":"Sint-Oedenrode","state_id":"2593"},
    {"city_id":"30158","city_name":"Sleeuwijk","state_id":"2593"},
    {"city_id":"30159","city_name":"Someren","state_id":"2593"},
    {"city_id":"30160","city_name":"Son en Breugel","state_id":"2593"},
    {"city_id":"30161","city_name":"Steenbergen","state_id":"2593"},
    {"city_id":"30162","city_name":"Tilburg","state_id":"2593"},
    {"city_id":"30163","city_name":"Uden","state_id":"2593"},
    {"city_id":"30164","city_name":"Valkenswaard","state_id":"2593"},
    {"city_id":"30165","city_name":"Veghel","state_id":"2593"},
    {"city_id":"30166","city_name":"Veldhoven","state_id":"2593"},
    {"city_id":"30167","city_name":"Vinkel","state_id":"2593"},
    {"city_id":"30168","city_name":"Vught","state_id":"2593"},
    {"city_id":"30169","city_name":"Waalre","state_id":"2593"},
    {"city_id":"30170","city_name":"Waalwijk","state_id":"2593"},
    {"city_id":"30171","city_name":"Werkendam","state_id":"2593"},
    {"city_id":"30172","city_name":"Woensdrecht","state_id":"2593"},
    {"city_id":"30173","city_name":"Woudrichem","state_id":"2593"},
    {"city_id":"30174","city_name":"Zundert","state_id":"2593"},
    {"city_id":"30175","city_name":"Aalsmeer","state_id":"2594"},
    {"city_id":"30176","city_name":"Alkmaar","state_id":"2594"},
    {"city_id":"30177","city_name":"Amstelveen","state_id":"2594"},
    {"city_id":"30178","city_name":"Amsterdam","state_id":"2594"},
    {"city_id":"30179","city_name":"Andijk","state_id":"2594"},
    {"city_id":"30180","city_name":"Ankeveen","state_id":"2594"},
    {"city_id":"30181","city_name":"Anna Paulowna","state_id":"2594"},
    {"city_id":"30182","city_name":"Assendelft","state_id":"2594"},
    {"city_id":"30183","city_name":"Badhoevedorp","state_id":"2594"},
    {"city_id":"30184","city_name":"Beemster","state_id":"2594"},
    {"city_id":"30185","city_name":"Bennebroek","state_id":"2594"},
    {"city_id":"30186","city_name":"Bergen","state_id":"2594"},
    {"city_id":"30187","city_name":"Beverwijk","state_id":"2594"},
    {"city_id":"30188","city_name":"Blaricum","state_id":"2594"},
    {"city_id":"30189","city_name":"Bloemendaal","state_id":"2594"},
    {"city_id":"30190","city_name":"Bovenkarspel","state_id":"2594"},
    {"city_id":"30191","city_name":"Bussum","state_id":"2594"},
    {"city_id":"30192","city_name":"Castricum","state_id":"2594"},
    {"city_id":"30193","city_name":"Den Helder","state_id":"2594"},
    {"city_id":"30194","city_name":"Diemen","state_id":"2594"},
    {"city_id":"30195","city_name":"Drechterland","state_id":"2594"},
    {"city_id":"30196","city_name":"Edam-Volendam","state_id":"2594"},
    {"city_id":"30197","city_name":"Enkhuizen","state_id":"2594"},
    {"city_id":"30198","city_name":"Graft-De Rijp","state_id":"2594"},
    {"city_id":"30199","city_name":"Haarlem","state_id":"2594"},
    {"city_id":"30200","city_name":"Haarlemmerliede","state_id":"2594"},
    {"city_id":"30201","city_name":"Haarlemmermeer","state_id":"2594"},
    {"city_id":"30202","city_name":"Harenkarspel","state_id":"2594"},
    {"city_id":"30203","city_name":"Heemskerk","state_id":"2594"},
    {"city_id":"30204","city_name":"Heemstede","state_id":"2594"},
    {"city_id":"30205","city_name":"Heerhugowaard","state_id":"2594"},
    {"city_id":"30206","city_name":"Heiloo","state_id":"2594"},
    {"city_id":"30207","city_name":"Hillegom","state_id":"2594"},
    {"city_id":"30208","city_name":"Hilversum","state_id":"2594"},
    {"city_id":"30209","city_name":"Hoofddorp","state_id":"2594"},
    {"city_id":"30210","city_name":"Hoorn","state_id":"2594"},
    {"city_id":"30211","city_name":"Huizen","state_id":"2594"},
    {"city_id":"30212","city_name":"Ijmuiden","state_id":"2594"},
    {"city_id":"30213","city_name":"Katwijk","state_id":"2594"},
    {"city_id":"30214","city_name":"Krommenie","state_id":"2594"},
    {"city_id":"30215","city_name":"Landsmeer","state_id":"2594"},
    {"city_id":"30216","city_name":"Langedijk","state_id":"2594"},
    {"city_id":"30217","city_name":"Laren","state_id":"2594"},
    {"city_id":"30218","city_name":"Loosdrecht","state_id":"2594"},
    {"city_id":"30219","city_name":"Medemblik","state_id":"2594"},
    {"city_id":"30220","city_name":"Middenbeemster","state_id":"2594"},
    {"city_id":"30221","city_name":"Muiden","state_id":"2594"},
    {"city_id":"30222","city_name":"Naarden","state_id":"2594"},
    {"city_id":"30223","city_name":"Niedorp","state_id":"2594"},
    {"city_id":"30224","city_name":"Nieuw-Vennep","state_id":"2594"},
    {"city_id":"30225","city_name":"Noorder-Koggenland","state_id":"2594"},
    {"city_id":"30226","city_name":"Obdam","state_id":"2594"},
    {"city_id":"30227","city_name":"Oostzaan","state_id":"2594"},
    {"city_id":"30228","city_name":"Opmeer","state_id":"2594"},
    {"city_id":"30229","city_name":"Oude Meer","state_id":"2594"},
    {"city_id":"30230","city_name":"Ouder-Amstel","state_id":"2594"},
    {"city_id":"30231","city_name":"Oudkarspel","state_id":"2594"},
    {"city_id":"30232","city_name":"Purmerend","state_id":"2594"},
    {"city_id":"30233","city_name":"Rozenburg","state_id":"2594"},
    {"city_id":"30234","city_name":"Schagen","state_id":"2594"},
    {"city_id":"30235","city_name":"Schermer","state_id":"2594"},
    {"city_id":"30236","city_name":"Stede Broec","state_id":"2594"},
    {"city_id":"30237","city_name":"Texel","state_id":"2594"},
    {"city_id":"30238","city_name":"Tuitjenhorn","state_id":"2594"},
    {"city_id":"30239","city_name":"Uitgeest","state_id":"2594"},
    {"city_id":"30240","city_name":"Uithoorn","state_id":"2594"},
    {"city_id":"30241","city_name":"Velsen","state_id":"2594"},
    {"city_id":"30242","city_name":"Venhuizen","state_id":"2594"},
    {"city_id":"30243","city_name":"Vijfhuizen","state_id":"2594"},
    {"city_id":"30244","city_name":"Waarland","state_id":"2594"},
    {"city_id":"30245","city_name":"Waterland","state_id":"2594"},
    {"city_id":"30246","city_name":"Weesp","state_id":"2594"},
    {"city_id":"30247","city_name":"Wervershoof","state_id":"2594"},
    {"city_id":"30248","city_name":"Wester-Koggenland","state_id":"2594"},
    {"city_id":"30249","city_name":"Westwoud","state_id":"2594"},
    {"city_id":"30250","city_name":"Wieringen","state_id":"2594"},
    {"city_id":"30251","city_name":"Wieringermeer","state_id":"2594"},
    {"city_id":"30252","city_name":"Wognum","state_id":"2594"},
    {"city_id":"30253","city_name":"Wormer","state_id":"2594"},
    {"city_id":"30254","city_name":"Wormerland","state_id":"2594"},
    {"city_id":"30255","city_name":"Wormerveer","state_id":"2594"},
    {"city_id":"30256","city_name":"Zaandam","state_id":"2594"},
    {"city_id":"30257","city_name":"Zaanstad","state_id":"2594"},
    {"city_id":"30258","city_name":"Zandvoort","state_id":"2594"},
    {"city_id":"30259","city_name":"Zeevang","state_id":"2594"},
    {"city_id":"30260","city_name":"Zwaag","state_id":"2594"},
    {"city_id":"30261","city_name":"Zwanenburg","state_id":"2594"},
    {"city_id":"30262","city_name":"Almelo","state_id":"2595"},
    {"city_id":"30263","city_name":"Bathmen","state_id":"2595"},
    {"city_id":"30264","city_name":"Borne","state_id":"2595"},
    {"city_id":"30265","city_name":"Dalfsen","state_id":"2595"},
    {"city_id":"30266","city_name":"Dedemsvaart","state_id":"2595"},
    {"city_id":"30267","city_name":"Denekamp","state_id":"2595"},
    {"city_id":"30268","city_name":"Deventer","state_id":"2595"},
    {"city_id":"30269","city_name":"Diepenheim","state_id":"2595"},
    {"city_id":"30270","city_name":"Enschede","state_id":"2595"},
    {"city_id":"30271","city_name":"Genemuiden","state_id":"2595"},
    {"city_id":"30272","city_name":"Haaksbergen","state_id":"2595"},
    {"city_id":"30273","city_name":"Hardenberg","state_id":"2595"},
    {"city_id":"30274","city_name":"Hasselt","state_id":"2595"},
    {"city_id":"30275","city_name":"Hellendoorn","state_id":"2595"},
    {"city_id":"30276","city_name":"Hengelo","state_id":"2595"},
    {"city_id":"30277","city_name":"Hof van Twente","state_id":"2595"},
    {"city_id":"30278","city_name":"IJsselmuiden","state_id":"2595"},
    {"city_id":"30279","city_name":"Kampen","state_id":"2595"},
    {"city_id":"30280","city_name":"Lemelerveld","state_id":"2595"},
    {"city_id":"30281","city_name":"Losser","state_id":"2595"},
    {"city_id":"30282","city_name":"Nieuwleusen","state_id":"2595"},
    {"city_id":"30283","city_name":"Nijverdal","state_id":"2595"},
    {"city_id":"30284","city_name":"Oldenzaal","state_id":"2595"},
    {"city_id":"30285","city_name":"Olst","state_id":"2595"},
    {"city_id":"30286","city_name":"Ommen","state_id":"2595"},
    {"city_id":"30287","city_name":"Ootmarsum","state_id":"2595"},
    {"city_id":"30288","city_name":"Raalte","state_id":"2595"},
    {"city_id":"30289","city_name":"Rijssen","state_id":"2595"},
    {"city_id":"30290","city_name":"Staphorst","state_id":"2595"},
    {"city_id":"30291","city_name":"Steenwijk","state_id":"2595"},
    {"city_id":"30292","city_name":"Tubbergen","state_id":"2595"},
    {"city_id":"30293","city_name":"Vriezenveen","state_id":"2595"},
    {"city_id":"30294","city_name":"Vroomshoop","state_id":"2595"},
    {"city_id":"30295","city_name":"Weerselo","state_id":"2595"},
    {"city_id":"30296","city_name":"Wierden","state_id":"2595"},
    {"city_id":"30297","city_name":"Zwartewaterland","state_id":"2595"},
    {"city_id":"30298","city_name":"Zwolle","state_id":"2595"},
    {"city_id":"30299","city_name":"Abcoude","state_id":"2597"},
    {"city_id":"30300","city_name":"Amerongen","state_id":"2597"},
    {"city_id":"30301","city_name":"Amersfoort","state_id":"2597"},
    {"city_id":"30302","city_name":"Baarn","state_id":"2597"},
    {"city_id":"30303","city_name":"Benschop","state_id":"2597"},
    {"city_id":"30304","city_name":"Breukelen","state_id":"2597"},
    {"city_id":"30305","city_name":"Bunnik","state_id":"2597"},
    {"city_id":"30306","city_name":"Bunschoten","state_id":"2597"},
    {"city_id":"30307","city_name":"De Bilt","state_id":"2597"},
    {"city_id":"30308","city_name":"De Ronde Venen","state_id":"2597"},
    {"city_id":"30309","city_name":"Den Dolder","state_id":"2597"},
    {"city_id":"30310","city_name":"Doorn","state_id":"2597"},
    {"city_id":"30311","city_name":"Driebergen-Rijsenburg","state_id":"2597"},
    {"city_id":"30312","city_name":"Eemnes","state_id":"2597"},
    {"city_id":"30313","city_name":"Houten","state_id":"2597"},
    {"city_id":"30314","city_name":"IJsselstein","state_id":"2597"},
    {"city_id":"30315","city_name":"Kockengen","state_id":"2597"},
    {"city_id":"30316","city_name":"Leersum","state_id":"2597"},
    {"city_id":"30317","city_name":"Leusden","state_id":"2597"},
    {"city_id":"30318","city_name":"Loenen","state_id":"2597"},
    {"city_id":"30319","city_name":"Lopik","state_id":"2597"},
    {"city_id":"30320","city_name":"Maarn","state_id":"2597"},
    {"city_id":"30321","city_name":"Maarsen","state_id":"2597"},
    {"city_id":"30322","city_name":"Mijdrecht","state_id":"2597"},
    {"city_id":"30323","city_name":"Montfoort","state_id":"2597"},
    {"city_id":"30324","city_name":"Nieuwegein","state_id":"2597"},
    {"city_id":"30325","city_name":"Nigtevecht","state_id":"2597"},
    {"city_id":"30326","city_name":"Odijk","state_id":"2597"},
    {"city_id":"30327","city_name":"Oudewater","state_id":"2597"},
    {"city_id":"30328","city_name":"Renswoude","state_id":"2597"},
    {"city_id":"30329","city_name":"Rhenen","state_id":"2597"},
    {"city_id":"30330","city_name":"Soest","state_id":"2597"},
    {"city_id":"30331","city_name":"Soesterberg","state_id":"2597"},
    {"city_id":"30332","city_name":"Utrecht","state_id":"2597"},
    {"city_id":"30333","city_name":"Veenendaal","state_id":"2597"},
    {"city_id":"30334","city_name":"Vianen","state_id":"2597"},
    {"city_id":"30335","city_name":"Wijdemeren","state_id":"2597"},
    {"city_id":"30336","city_name":"Wijk","state_id":"2597"},
    {"city_id":"30337","city_name":"Wilnis","state_id":"2597"},
    {"city_id":"30338","city_name":"Woerden","state_id":"2597"},
    {"city_id":"30339","city_name":"Woudenberg","state_id":"2597"},
    {"city_id":"30340","city_name":"Zeist","state_id":"2597"},
    {"city_id":"30341","city_name":"Axel","state_id":"2598"},
    {"city_id":"30342","city_name":"Borsele","state_id":"2598"},
    {"city_id":"30343","city_name":"Goes","state_id":"2598"},
    {"city_id":"30344","city_name":"Hontenisse","state_id":"2598"},
    {"city_id":"30345","city_name":"Hulst","state_id":"2598"},
    {"city_id":"30346","city_name":"Kapelle","state_id":"2598"},
    {"city_id":"30347","city_name":"Middelburg","state_id":"2598"},
    {"city_id":"30348","city_name":"Noord-Beveland","state_id":"2598"},
    {"city_id":"30349","city_name":"Oostburg","state_id":"2598"},
    {"city_id":"30350","city_name":"Reimerswaal","state_id":"2598"},
    {"city_id":"30351","city_name":"Sas van Gent","state_id":"2598"},
    {"city_id":"30352","city_name":"Schouwen-Duiveland","state_id":"2598"},
    {"city_id":"30353","city_name":"Sluis-Aardenburg","state_id":"2598"},
    {"city_id":"30354","city_name":"Terneuzen","state_id":"2598"},
    {"city_id":"30355","city_name":"Tholen","state_id":"2598"},
    {"city_id":"30356","city_name":"Veere","state_id":"2598"},
    {"city_id":"30357","city_name":"Vlissingen","state_id":"2598"},
    {"city_id":"30358","city_name":"Zierikzee","state_id":"2598"},
    {"city_id":"30359","city_name":"Zijpe","state_id":"2598"},
    {"city_id":"30360","city_name":"'s-Gravendeel","state_id":"2599"},
    {"city_id":"30361","city_name":"'s-Gravenhage","state_id":"2599"},
    {"city_id":"30362","city_name":"'s-Gravenzande","state_id":"2599"},
    {"city_id":"30363","city_name":"Alblasserdam","state_id":"2599"},
    {"city_id":"30364","city_name":"Albrandswaard","state_id":"2599"},
    {"city_id":"30365","city_name":"Alkemade","state_id":"2599"},
    {"city_id":"30366","city_name":"Alphen","state_id":"2599"},
    {"city_id":"30367","city_name":"Alphen aan den Rijn","state_id":"2599"},
    {"city_id":"30368","city_name":"Barendrecht","state_id":"2599"},
    {"city_id":"30369","city_name":"Bergambacht","state_id":"2599"},
    {"city_id":"30370","city_name":"Bergschenhoek","state_id":"2599"},
    {"city_id":"30371","city_name":"Berkel en Rodenrijs","state_id":"2599"},
    {"city_id":"30372","city_name":"Binnenmaas","state_id":"2599"},
    {"city_id":"30373","city_name":"Bleiswijk","state_id":"2599"},
    {"city_id":"30374","city_name":"Bodegraven","state_id":"2599"},
    {"city_id":"30375","city_name":"Boskoop","state_id":"2599"},
    {"city_id":"30376","city_name":"Brielle","state_id":"2599"},
    {"city_id":"30377","city_name":"Capelle","state_id":"2599"},
    {"city_id":"30378","city_name":"Cromstrijen","state_id":"2599"},
    {"city_id":"30379","city_name":"De Lier","state_id":"2599"},
    {"city_id":"30380","city_name":"Delft","state_id":"2599"},
    {"city_id":"30381","city_name":"Dirksland","state_id":"2599"},
    {"city_id":"30382","city_name":"Dordrecht","state_id":"2599"},
    {"city_id":"30383","city_name":"Giessenlanden","state_id":"2599"},
    {"city_id":"30384","city_name":"Goedereede","state_id":"2599"},
    {"city_id":"30385","city_name":"Gorinchem","state_id":"2599"},
    {"city_id":"30386","city_name":"Gouda","state_id":"2599"},
    {"city_id":"30387","city_name":"Graafstroom","state_id":"2599"},
    {"city_id":"30388","city_name":"Hardinxveld-Giessendam","state_id":"2599"},
    {"city_id":"30389","city_name":"Heerjansdam","state_id":"2599"},
    {"city_id":"30390","city_name":"Hellevoetsluis","state_id":"2599"},
    {"city_id":"30391","city_name":"Hendrik-Ido-Ambacht","state_id":"2599"},
    {"city_id":"30392","city_name":"Jacobswoude","state_id":"2599"},
    {"city_id":"30393","city_name":"Katwijk","state_id":"2599"},
    {"city_id":"30394","city_name":"Kinderdijk","state_id":"2599"},
    {"city_id":"30395","city_name":"Krimpen","state_id":"2599"},
    {"city_id":"30396","city_name":"Leiden","state_id":"2599"},
    {"city_id":"30397","city_name":"Leiderdorp","state_id":"2599"},
    {"city_id":"30398","city_name":"Leidschendam-Voorburg","state_id":"2599"},
    {"city_id":"30399","city_name":"Liemeer","state_id":"2599"},
    {"city_id":"30400","city_name":"Liesveld","state_id":"2599"},
    {"city_id":"30401","city_name":"Lisse","state_id":"2599"},
    {"city_id":"30402","city_name":"Maasland","state_id":"2599"},
    {"city_id":"30403","city_name":"Maassluis","state_id":"2599"},
    {"city_id":"30404","city_name":"Middelharnis","state_id":"2599"},
    {"city_id":"30405","city_name":"Monster","state_id":"2599"},
    {"city_id":"30406","city_name":"Moordrecht","state_id":"2599"},
    {"city_id":"30407","city_name":"Naaldwijk","state_id":"2599"},
    {"city_id":"30408","city_name":"Nederlek","state_id":"2599"},
    {"city_id":"30409","city_name":"Nieuw-Lekkerland","state_id":"2599"},
    {"city_id":"30410","city_name":"Nieuwekerk aan den IJssel","state_id":"2599"},
    {"city_id":"30411","city_name":"Nieuwkoop","state_id":"2599"},
    {"city_id":"30412","city_name":"Noordwijk","state_id":"2599"},
    {"city_id":"30413","city_name":"Noordwijkerhout","state_id":"2599"},
    {"city_id":"30414","city_name":"Oegestgeest","state_id":"2599"},
    {"city_id":"30415","city_name":"Oostflakkee","state_id":"2599"},
    {"city_id":"30416","city_name":"Oud-Beijerland","state_id":"2599"},
    {"city_id":"30417","city_name":"Ouderkerk","state_id":"2599"},
    {"city_id":"30418","city_name":"Papendrecht","state_id":"2599"},
    {"city_id":"30419","city_name":"Pijnacker-Nootdorp","state_id":"2599"},
    {"city_id":"30420","city_name":"Reeuwijk","state_id":"2599"},
    {"city_id":"30421","city_name":"Ridderkerk","state_id":"2599"},
    {"city_id":"30422","city_name":"Rijnsburg","state_id":"2599"},
    {"city_id":"30423","city_name":"Rijnwoude","state_id":"2599"},
    {"city_id":"30424","city_name":"Rijswijk","state_id":"2599"},
    {"city_id":"30425","city_name":"Rotterdam","state_id":"2599"},
    {"city_id":"30426","city_name":"Sassenheim","state_id":"2599"},
    {"city_id":"30427","city_name":"Schiedam","state_id":"2599"},
    {"city_id":"30428","city_name":"Schipluiden","state_id":"2599"},
    {"city_id":"30429","city_name":"Schoonhoven","state_id":"2599"},
    {"city_id":"30430","city_name":"Sliedrecht","state_id":"2599"},
    {"city_id":"30431","city_name":"Spijkenisse","state_id":"2599"},
    {"city_id":"30432","city_name":"Strijen","state_id":"2599"},
    {"city_id":"30433","city_name":"Ter Aar","state_id":"2599"},
    {"city_id":"30434","city_name":"The Hague","state_id":"2599"},
    {"city_id":"30435","city_name":"Valkenburg","state_id":"2599"},
    {"city_id":"30436","city_name":"Vierpolders","state_id":"2599"},
    {"city_id":"30437","city_name":"Vlaardingen","state_id":"2599"},
    {"city_id":"30438","city_name":"Vlist","state_id":"2599"},
    {"city_id":"30439","city_name":"Voorhout","state_id":"2599"},
    {"city_id":"30440","city_name":"Voorschoten","state_id":"2599"},
    {"city_id":"30441","city_name":"Waddinxveen","state_id":"2599"},
    {"city_id":"30442","city_name":"Warmond","state_id":"2599"},
    {"city_id":"30443","city_name":"Wassenaar","state_id":"2599"},
    {"city_id":"30444","city_name":"Wateringen","state_id":"2599"},
    {"city_id":"30445","city_name":"West Maas en Waal","state_id":"2599"},
    {"city_id":"30446","city_name":"Westvoorne","state_id":"2599"},
    {"city_id":"30447","city_name":"Zederik","state_id":"2599"},
    {"city_id":"30448","city_name":"Zevenhuizen-Moerkapelle","state_id":"2599"},
    {"city_id":"30449","city_name":"Zoetermeer","state_id":"2599"},
    {"city_id":"30450","city_name":"Zoeterwoude","state_id":"2599"},
    {"city_id":"30451","city_name":"Zwijndrecht","state_id":"2599"},
    {"city_id":"30452","city_name":"Belep","state_id":"2600"},
    {"city_id":"30453","city_name":"Fayaoue","state_id":"2600"},
    {"city_id":"30454","city_name":"Tadine","state_id":"2600"},
    {"city_id":"30455","city_name":"Vao","state_id":"2600"},
    {"city_id":"30456","city_name":"We","state_id":"2600"},
    {"city_id":"30457","city_name":"Canala","state_id":"2601"},
    {"city_id":"30458","city_name":"Hienghene","state_id":"2601"},
    {"city_id":"30459","city_name":"Houailu","state_id":"2601"},
    {"city_id":"30460","city_name":"Kaala Gomen","state_id":"2601"},
    {"city_id":"30461","city_name":"Kone","state_id":"2601"},
    {"city_id":"30462","city_name":"Koumac","state_id":"2601"},
    {"city_id":"30463","city_name":"Ouegoa","state_id":"2601"},
    {"city_id":"30464","city_name":"Poindimie","state_id":"2601"},
    {"city_id":"30465","city_name":"Ponerihouen","state_id":"2601"},
    {"city_id":"30466","city_name":"Pouebo","state_id":"2601"},
    {"city_id":"30467","city_name":"Pouembout","state_id":"2601"},
    {"city_id":"30468","city_name":"Poum","state_id":"2601"},
    {"city_id":"30469","city_name":"Poya","state_id":"2601"},
    {"city_id":"30470","city_name":"Touho","state_id":"2601"},
    {"city_id":"30471","city_name":"Voh","state_id":"2601"},
    {"city_id":"30472","city_name":"Bouloupari","state_id":"2602"},
    {"city_id":"30473","city_name":"Bourail","state_id":"2602"},
    {"city_id":"30474","city_name":"Dumbea","state_id":"2602"},
    {"city_id":"30475","city_name":"Farino","state_id":"2602"},
    {"city_id":"30476","city_name":"La Foa","state_id":"2602"},
    {"city_id":"30477","city_name":"Moindou","state_id":"2602"},
    {"city_id":"30478","city_name":"Mont-Dore","state_id":"2602"},
    {"city_id":"30479","city_name":"Noumea","state_id":"2602"},
    {"city_id":"30480","city_name":"Paita","state_id":"2602"},
    {"city_id":"30481","city_name":"Sarramea","state_id":"2602"},
    {"city_id":"30482","city_name":"Thio","state_id":"2602"},
    {"city_id":"30483","city_name":"Yate","state_id":"2602"},
    {"city_id":"30484","city_name":"Auckland","state_id":"2604"},
    {"city_id":"30485","city_name":"Helensville","state_id":"2604"},
    {"city_id":"30486","city_name":"Henderson","state_id":"2604"},
    {"city_id":"30487","city_name":"Hillsborough","state_id":"2604"},
    {"city_id":"30488","city_name":"Lynfield","state_id":"2604"},
    {"city_id":"30489","city_name":"Manukau","state_id":"2604"},
    {"city_id":"30490","city_name":"Manurewa","state_id":"2604"},
    {"city_id":"30491","city_name":"Mt. Roskill","state_id":"2604"},
    {"city_id":"30492","city_name":"North Shore","state_id":"2604"},
    {"city_id":"30493","city_name":"Onehunga","state_id":"2604"},
    {"city_id":"30494","city_name":"Orewa","state_id":"2604"},
    {"city_id":"30495","city_name":"Otahuhu","state_id":"2604"},
    {"city_id":"30496","city_name":"Panmure","state_id":"2604"},
    {"city_id":"30497","city_name":"Papakura","state_id":"2604"},
    {"city_id":"30498","city_name":"Papatoetoe","state_id":"2604"},
    {"city_id":"30499","city_name":"Ponsonby","state_id":"2604"},
    {"city_id":"30500","city_name":"Royal Oak","state_id":"2604"},
    {"city_id":"30501","city_name":"Sandringham","state_id":"2604"},
    {"city_id":"30502","city_name":"Snells Beach","state_id":"2604"},
    {"city_id":"30503","city_name":"Takanini","state_id":"2604"},
    {"city_id":"30504","city_name":"Waiheke","state_id":"2604"},
    {"city_id":"30505","city_name":"Waitakere","state_id":"2604"},
    {"city_id":"30506","city_name":"Amberley","state_id":"2606"},
    {"city_id":"30507","city_name":"Ashburton","state_id":"2606"},
    {"city_id":"30508","city_name":"Christchurch","state_id":"2606"},
    {"city_id":"30509","city_name":"Fairlie","state_id":"2606"},
    {"city_id":"30510","city_name":"Geraldine","state_id":"2606"},
    {"city_id":"30511","city_name":"Kaikoura","state_id":"2606"},
    {"city_id":"30512","city_name":"Leeston","state_id":"2606"},
    {"city_id":"30513","city_name":"Lyttelton","state_id":"2606"},
    {"city_id":"30514","city_name":"Oamaru","state_id":"2606"},
    {"city_id":"30515","city_name":"Rangiora","state_id":"2606"},
    {"city_id":"30516","city_name":"Temuka","state_id":"2606"},
    {"city_id":"30517","city_name":"Timaru","state_id":"2606"},
    {"city_id":"30518","city_name":"Waimate","state_id":"2606"},
    {"city_id":"30519","city_name":"Gisborne","state_id":"2608"},
    {"city_id":"30520","city_name":"Dannevirke","state_id":"2610"},
    {"city_id":"30521","city_name":"Feilding","state_id":"2610"},
    {"city_id":"30522","city_name":"Foxton","state_id":"2610"},
    {"city_id":"30523","city_name":"Levin","state_id":"2610"},
    {"city_id":"30524","city_name":"Marton","state_id":"2610"},
    {"city_id":"30525","city_name":"Palmerston North","state_id":"2610"},
    {"city_id":"30526","city_name":"Picton","state_id":"2610"},
    {"city_id":"30527","city_name":"Taumarunui","state_id":"2610"},
    {"city_id":"30528","city_name":"Wanganui","state_id":"2610"},
    {"city_id":"30529","city_name":"Blenheim","state_id":"2611"},
    {"city_id":"30530","city_name":"Havelock","state_id":"2611"},
    {"city_id":"30531","city_name":"Nelson","state_id":"2612"},
    {"city_id":"30532","city_name":"Dargaville","state_id":"2613"},
    {"city_id":"30533","city_name":"Kaikohe","state_id":"2613"},
    {"city_id":"30534","city_name":"Kaitaia","state_id":"2613"},
    {"city_id":"30535","city_name":"Kerikeri","state_id":"2613"},
    {"city_id":"30536","city_name":"Maungatapere","state_id":"2613"},
    {"city_id":"30537","city_name":"Whangarei","state_id":"2613"},
    {"city_id":"30538","city_name":"Alexandra","state_id":"2614"},
    {"city_id":"30539","city_name":"Balclutha","state_id":"2614"},
    {"city_id":"30540","city_name":"Dunedin","state_id":"2614"},
    {"city_id":"30541","city_name":"Queenstown","state_id":"2614"},
    {"city_id":"30542","city_name":"Wanaka","state_id":"2614"},
    {"city_id":"30543","city_name":"Warkworth","state_id":"2615"},
    {"city_id":"30544","city_name":"Gore","state_id":"2616"},
    {"city_id":"30545","city_name":"Invercargill","state_id":"2616"},
    {"city_id":"30546","city_name":"Eltham","state_id":"2617"},
    {"city_id":"30547","city_name":"Hawera","state_id":"2617"},
    {"city_id":"30548","city_name":"Inglewood","state_id":"2617"},
    {"city_id":"30549","city_name":"New Plymouth","state_id":"2617"},
    {"city_id":"30550","city_name":"Oakura","state_id":"2617"},
    {"city_id":"30551","city_name":"Stratford","state_id":"2617"},
    {"city_id":"30552","city_name":"Waitara","state_id":"2617"},
    {"city_id":"30553","city_name":"Motueka","state_id":"2618"},
    {"city_id":"30554","city_name":"Richmond","state_id":"2618"},
    {"city_id":"30555","city_name":"Cambridge","state_id":"2619"},
    {"city_id":"30556","city_name":"Coromandel","state_id":"2619"},
    {"city_id":"30557","city_name":"Hamilton","state_id":"2619"},
    {"city_id":"30558","city_name":"Hillcrest","state_id":"2619"},
    {"city_id":"30559","city_name":"Huntly","state_id":"2619"},
    {"city_id":"30560","city_name":"Matamata","state_id":"2619"},
    {"city_id":"30561","city_name":"Morrinsville","state_id":"2619"},
    {"city_id":"30562","city_name":"Ngaruawahia","state_id":"2619"},
    {"city_id":"30563","city_name":"Otorohanga","state_id":"2619"},
    {"city_id":"30564","city_name":"Paeroa","state_id":"2619"},
    {"city_id":"30565","city_name":"Pukekohe","state_id":"2619"},
    {"city_id":"30566","city_name":"Putaruru","state_id":"2619"},
    {"city_id":"30567","city_name":"Taupo","state_id":"2619"},
    {"city_id":"30568","city_name":"Te Aroha","state_id":"2619"},
    {"city_id":"30569","city_name":"Te Awamutu","state_id":"2619"},
    {"city_id":"30570","city_name":"Te Kuiti","state_id":"2619"},
    {"city_id":"30571","city_name":"Thames","state_id":"2619"},
    {"city_id":"30572","city_name":"Tokoroa","state_id":"2619"},
    {"city_id":"30573","city_name":"Turangi","state_id":"2619"},
    {"city_id":"30574","city_name":"Waiuku","state_id":"2619"},
    {"city_id":"30575","city_name":"Whangamata","state_id":"2619"},
    {"city_id":"30576","city_name":"Whitianga","state_id":"2619"},
    {"city_id":"30577","city_name":"Carterton","state_id":"2620"},
    {"city_id":"30578","city_name":"Kapiti","state_id":"2620"},
    {"city_id":"30579","city_name":"Lower Hutt","state_id":"2620"},
    {"city_id":"30580","city_name":"Martinborough","state_id":"2620"},
    {"city_id":"30581","city_name":"Masterton","state_id":"2620"},
    {"city_id":"30582","city_name":"Otaki","state_id":"2620"},
    {"city_id":"30583","city_name":"Paraparaumu","state_id":"2620"},
    {"city_id":"30584","city_name":"Porirua","state_id":"2620"},
    {"city_id":"30585","city_name":"Upper Hutt","state_id":"2620"},
    {"city_id":"30586","city_name":"Wairarapa","state_id":"2620"},
    {"city_id":"30587","city_name":"Wellington","state_id":"2620"},
    {"city_id":"30588","city_name":"Boaco","state_id":"2624"},
    {"city_id":"30589","city_name":"Camoapa","state_id":"2624"},
    {"city_id":"30590","city_name":"San Lorenzo","state_id":"2624"},
    {"city_id":"30591","city_name":"Diriamba","state_id":"2625"},
    {"city_id":"30592","city_name":"Dolores","state_id":"2625"},
    {"city_id":"30593","city_name":"Jinotepe","state_id":"2625"},
    {"city_id":"30594","city_name":"Masatepe","state_id":"2625"},
    {"city_id":"30595","city_name":"San Marcos","state_id":"2625"},
    {"city_id":"30596","city_name":"Santa Teresa","state_id":"2625"},
    {"city_id":"30597","city_name":"Chichigalpa","state_id":"2626"},
    {"city_id":"30598","city_name":"Chinandega","state_id":"2626"},
    {"city_id":"30599","city_name":"Corinto","state_id":"2626"},
    {"city_id":"30600","city_name":"El Viejo","state_id":"2626"},
    {"city_id":"30601","city_name":"Puerto Morazan","state_id":"2626"},
    {"city_id":"30602","city_name":"Somotillo","state_id":"2626"},
    {"city_id":"30603","city_name":"Acoyapa","state_id":"2627"},
    {"city_id":"30604","city_name":"Juigalpa","state_id":"2627"},
    {"city_id":"30605","city_name":"Santo Domingo","state_id":"2627"},
    {"city_id":"30606","city_name":"Santo Tomas","state_id":"2627"},
    {"city_id":"30607","city_name":"Villa Sandino","state_id":"2627"},
    {"city_id":"30608","city_name":"Condega","state_id":"2628"},
    {"city_id":"30609","city_name":"Esteli","state_id":"2628"},
    {"city_id":"30610","city_name":"La Trinidad","state_id":"2628"},
    {"city_id":"30611","city_name":"Diriomo","state_id":"2629"},
    {"city_id":"30612","city_name":"Granada","state_id":"2629"},
    {"city_id":"30613","city_name":"Nandaime","state_id":"2629"},
    {"city_id":"30614","city_name":"Jinotega","state_id":"2630"},
    {"city_id":"30615","city_name":"Wiwili","state_id":"2630"},
    {"city_id":"30616","city_name":"El Sauce","state_id":"2631"},
    {"city_id":"30617","city_name":"La Paz Centro","state_id":"2631"},
    {"city_id":"30618","city_name":"Larreynaga","state_id":"2631"},
    {"city_id":"30619","city_name":"Leon","state_id":"2631"},
    {"city_id":"30620","city_name":"Nagarote","state_id":"2631"},
    {"city_id":"30621","city_name":"Telica","state_id":"2631"},
    {"city_id":"30622","city_name":"Somoto","state_id":"2632"},
    {"city_id":"30623","city_name":"Managua","state_id":"2633"},
    {"city_id":"30624","city_name":"Mateare","state_id":"2633"},
    {"city_id":"30625","city_name":"San Rafael del Sur","state_id":"2633"},
    {"city_id":"30626","city_name":"Ticuantepe","state_id":"2633"},
    {"city_id":"30627","city_name":"Tipitapa","state_id":"2633"},
    {"city_id":"30628","city_name":"La Concepcion","state_id":"2634"},
    {"city_id":"30629","city_name":"Masaya","state_id":"2634"},
    {"city_id":"30630","city_name":"Nandasmo","state_id":"2634"},
    {"city_id":"30631","city_name":"Nindiri","state_id":"2634"},
    {"city_id":"30632","city_name":"Niquinohomo","state_id":"2634"},
    {"city_id":"30633","city_name":"Ciudad Dario","state_id":"2635"},
    {"city_id":"30634","city_name":"Esquipulas","state_id":"2635"},
    {"city_id":"30635","city_name":"Matagalpa","state_id":"2635"},
    {"city_id":"30636","city_name":"Matiguas","state_id":"2635"},
    {"city_id":"30637","city_name":"Rio Blanco","state_id":"2635"},
    {"city_id":"30638","city_name":"San Isidro","state_id":"2635"},
    {"city_id":"30639","city_name":"Sebaco","state_id":"2635"},
    {"city_id":"30640","city_name":"Belen","state_id":"2638"},
    {"city_id":"30641","city_name":"Rivas","state_id":"2638"},
    {"city_id":"30642","city_name":"San Jorge","state_id":"2638"},
    {"city_id":"30643","city_name":"San Juan del Sur","state_id":"2638"},
    {"city_id":"30644","city_name":"Agadez","state_id":"2639"},
    {"city_id":"30645","city_name":"Arlit","state_id":"2639"},
    {"city_id":"30646","city_name":"Bilma","state_id":"2639"},
    {"city_id":"30647","city_name":"Fachi","state_id":"2639"},
    {"city_id":"30648","city_name":"Ingall","state_id":"2639"},
    {"city_id":"30649","city_name":"Tchirozerine","state_id":"2639"},
    {"city_id":"30650","city_name":"Diffa","state_id":"2640"},
    {"city_id":"30651","city_name":"Maine-Soroa","state_id":"2640"},
    {"city_id":"30652","city_name":"N'Guigmi","state_id":"2640"},
    {"city_id":"30653","city_name":"Birni N'Gaoure","state_id":"2641"},
    {"city_id":"30654","city_name":"Boboye","state_id":"2641"},
    {"city_id":"30655","city_name":"Dogondoutchi","state_id":"2641"},
    {"city_id":"30656","city_name":"Dosso","state_id":"2641"},
    {"city_id":"30657","city_name":"Gaya","state_id":"2641"},
    {"city_id":"30658","city_name":"Loga","state_id":"2641"},
    {"city_id":"30659","city_name":"Aguie","state_id":"2642"},
    {"city_id":"30660","city_name":"Dakoro","state_id":"2642"},
    {"city_id":"30661","city_name":"Gazaoua","state_id":"2642"},
    {"city_id":"30662","city_name":"Guidan Roumdji","state_id":"2642"},
    {"city_id":"30663","city_name":"Madarounfa","state_id":"2642"},
    {"city_id":"30664","city_name":"Maradi","state_id":"2642"},
    {"city_id":"30665","city_name":"Mayahi","state_id":"2642"},
    {"city_id":"30666","city_name":"Tessaoua","state_id":"2642"},
    {"city_id":"30667","city_name":"Tibiri","state_id":"2642"},
    {"city_id":"30668","city_name":"Niamey","state_id":"2643"},
    {"city_id":"30669","city_name":"Birni N'Konni","state_id":"2644"},
    {"city_id":"30670","city_name":"Bouza","state_id":"2644"},
    {"city_id":"30671","city_name":"Illela","state_id":"2644"},
    {"city_id":"30672","city_name":"Keita","state_id":"2644"},
    {"city_id":"30673","city_name":"Madaoua","state_id":"2644"},
    {"city_id":"30674","city_name":"Malbaza'uzine","state_id":"2644"},
    {"city_id":"30675","city_name":"Tahoua","state_id":"2644"},
    {"city_id":"30676","city_name":"Tchintabaraden","state_id":"2644"},
    {"city_id":"30677","city_name":"Ayorou","state_id":"2645"},
    {"city_id":"30678","city_name":"Filingue","state_id":"2645"},
    {"city_id":"30679","city_name":"Kollo","state_id":"2645"},
    {"city_id":"30680","city_name":"Ouallam","state_id":"2645"},
    {"city_id":"30681","city_name":"Say","state_id":"2645"},
    {"city_id":"30682","city_name":"Tera","state_id":"2645"},
    {"city_id":"30683","city_name":"Tillabery","state_id":"2645"},
    {"city_id":"30684","city_name":"Goure","state_id":"2646"},
    {"city_id":"30685","city_name":"Kantche","state_id":"2646"},
    {"city_id":"30686","city_name":"Magaria","state_id":"2646"},
    {"city_id":"30687","city_name":"Matameye","state_id":"2646"},
    {"city_id":"30688","city_name":"Mirriah","state_id":"2646"},
    {"city_id":"30689","city_name":"Tanout","state_id":"2646"},
    {"city_id":"30690","city_name":"Zinder","state_id":"2646"},
    {"city_id":"30691","city_name":"Aba","state_id":"2647"},
    {"city_id":"30692","city_name":"Amaigbo","state_id":"2647"},
    {"city_id":"30693","city_name":"Arochukwu","state_id":"2647"},
    {"city_id":"30694","city_name":"Bende","state_id":"2647"},
    {"city_id":"30695","city_name":"Ohafia","state_id":"2647"},
    {"city_id":"30696","city_name":"Okwe","state_id":"2647"},
    {"city_id":"30697","city_name":"Umuahia","state_id":"2647"},
    {"city_id":"30698","city_name":"Demsa","state_id":"2649"},
    {"city_id":"30699","city_name":"Ganye","state_id":"2649"},
    {"city_id":"30700","city_name":"Girei","state_id":"2649"},
    {"city_id":"30701","city_name":"Gombi","state_id":"2649"},
    {"city_id":"30702","city_name":"Jada","state_id":"2649"},
    {"city_id":"30703","city_name":"Jimeta","state_id":"2649"},
    {"city_id":"30704","city_name":"Lamurde","state_id":"2649"},
    {"city_id":"30705","city_name":"Madagala","state_id":"2649"},
    {"city_id":"30706","city_name":"Maiha","state_id":"2649"},
    {"city_id":"30707","city_name":"Mubi","state_id":"2649"},
    {"city_id":"30708","city_name":"Ngurore","state_id":"2649"},
    {"city_id":"30709","city_name":"Numan","state_id":"2649"},
    {"city_id":"30710","city_name":"Shelleng","state_id":"2649"},
    {"city_id":"30711","city_name":"Song","state_id":"2649"},
    {"city_id":"30712","city_name":"Toungo","state_id":"2649"},
    {"city_id":"30713","city_name":"Yola","state_id":"2649"},
    {"city_id":"30714","city_name":"Aguata","state_id":"2651"},
    {"city_id":"30715","city_name":"Agulu","state_id":"2651"},
    {"city_id":"30716","city_name":"Anambra","state_id":"2651"},
    {"city_id":"30717","city_name":"Awka","state_id":"2651"},
    {"city_id":"30718","city_name":"Enugu Ukwu","state_id":"2651"},
    {"city_id":"30719","city_name":"Igbo Ukwu","state_id":"2651"},
    {"city_id":"30720","city_name":"Ihiala","state_id":"2651"},
    {"city_id":"30721","city_name":"Nkpor","state_id":"2651"},
    {"city_id":"30722","city_name":"Nnewi","state_id":"2651"},
    {"city_id":"30723","city_name":"Obosi","state_id":"2651"},
    {"city_id":"30724","city_name":"Okija","state_id":"2651"},
    {"city_id":"30725","city_name":"Okpoko","state_id":"2651"},
    {"city_id":"30726","city_name":"Onitsha","state_id":"2651"},
    {"city_id":"30727","city_name":"Ozubulu","state_id":"2651"},
    {"city_id":"30728","city_name":"Uga","state_id":"2651"},
    {"city_id":"30729","city_name":"Alkaleri","state_id":"2652"},
    {"city_id":"30730","city_name":"Azare","state_id":"2652"},
    {"city_id":"30731","city_name":"Bauchi","state_id":"2652"},
    {"city_id":"30732","city_name":"Bogoro","state_id":"2652"},
    {"city_id":"30733","city_name":"Bununu Dass","state_id":"2652"},
    {"city_id":"30734","city_name":"Darazo","state_id":"2652"},
    {"city_id":"30735","city_name":"Gamawa","state_id":"2652"},
    {"city_id":"30736","city_name":"Ganjuwa","state_id":"2652"},
    {"city_id":"30737","city_name":"Jamari","state_id":"2652"},
    {"city_id":"30738","city_name":"Katagum","state_id":"2652"},
    {"city_id":"30739","city_name":"Misau","state_id":"2652"},
    {"city_id":"30740","city_name":"Ningi","state_id":"2652"},
    {"city_id":"30741","city_name":"Tafawa Balewa","state_id":"2652"},
    {"city_id":"30742","city_name":"Brass","state_id":"2653"},
    {"city_id":"30743","city_name":"Ekeremor","state_id":"2653"},
    {"city_id":"30744","city_name":"Nembe","state_id":"2653"},
    {"city_id":"30745","city_name":"Yenagoa","state_id":"2653"},
    {"city_id":"30746","city_name":"Aliade","state_id":"2654"},
    {"city_id":"30747","city_name":"Gboko","state_id":"2654"},
    {"city_id":"30748","city_name":"Katsina-Ala","state_id":"2654"},
    {"city_id":"30749","city_name":"Makurdi","state_id":"2654"},
    {"city_id":"30750","city_name":"Otukpo","state_id":"2654"},
    {"city_id":"30751","city_name":"Ukum","state_id":"2654"},
    {"city_id":"30752","city_name":"Zaki Biam","state_id":"2654"},
    {"city_id":"30753","city_name":"Abadan","state_id":"2655"},
    {"city_id":"30754","city_name":"Askira","state_id":"2655"},
    {"city_id":"30755","city_name":"Bama","state_id":"2655"},
    {"city_id":"30756","city_name":"Biu","state_id":"2655"},
    {"city_id":"30757","city_name":"Chibok","state_id":"2655"},
    {"city_id":"30758","city_name":"Damboa","state_id":"2655"},
    {"city_id":"30759","city_name":"Dikwa","state_id":"2655"},
    {"city_id":"30760","city_name":"Gamboru","state_id":"2655"},
    {"city_id":"30761","city_name":"Gubio","state_id":"2655"},
    {"city_id":"30762","city_name":"Gwoza","state_id":"2655"},
    {"city_id":"30763","city_name":"Kaga","state_id":"2655"},
    {"city_id":"30764","city_name":"Kala","state_id":"2655"},
    {"city_id":"30765","city_name":"Konduga","state_id":"2655"},
    {"city_id":"30766","city_name":"Kukawa","state_id":"2655"},
    {"city_id":"30767","city_name":"Mafa","state_id":"2655"},
    {"city_id":"30768","city_name":"Magumeri","state_id":"2655"},
    {"city_id":"30769","city_name":"Maiduguri","state_id":"2655"},
    {"city_id":"30770","city_name":"Marte","state_id":"2655"},
    {"city_id":"30771","city_name":"Monguno","state_id":"2655"},
    {"city_id":"30772","city_name":"Ngala","state_id":"2655"},
    {"city_id":"30773","city_name":"Shani","state_id":"2655"},
    {"city_id":"30774","city_name":"Agbor","state_id":"2657"},
    {"city_id":"30775","city_name":"Asaba","state_id":"2657"},
    {"city_id":"30776","city_name":"Bomadi","state_id":"2657"},
    {"city_id":"30777","city_name":"Burutu","state_id":"2657"},
    {"city_id":"30778","city_name":"Okpe","state_id":"2657"},
    {"city_id":"30779","city_name":"Patani","state_id":"2657"},
    {"city_id":"30780","city_name":"Sapele","state_id":"2657"},
    {"city_id":"30781","city_name":"Ughelli","state_id":"2657"},
    {"city_id":"30782","city_name":"Warri","state_id":"2657"},
    {"city_id":"30783","city_name":"Abakaliki","state_id":"2658"},
    {"city_id":"30784","city_name":"Afikpo","state_id":"2658"},
    {"city_id":"30785","city_name":"Effium","state_id":"2658"},
    {"city_id":"30786","city_name":"Ezza","state_id":"2658"},
    {"city_id":"30787","city_name":"Ishieke","state_id":"2658"},
    {"city_id":"30788","city_name":"Uburu","state_id":"2658"},
    {"city_id":"30789","city_name":"Auchi","state_id":"2659"},
    {"city_id":"30790","city_name":"Benin","state_id":"2659"},
    {"city_id":"30791","city_name":"Ekpoma","state_id":"2659"},
    {"city_id":"30792","city_name":"Igarra","state_id":"2659"},
    {"city_id":"30793","city_name":"Ikpoba","state_id":"2659"},
    {"city_id":"30794","city_name":"Irrua","state_id":"2659"},
    {"city_id":"30795","city_name":"Sabongida","state_id":"2659"},
    {"city_id":"30796","city_name":"Ubiaja","state_id":"2659"},
    {"city_id":"30797","city_name":"Uromi","state_id":"2659"},
    {"city_id":"30798","city_name":"Ado","state_id":"2660"},
    {"city_id":"30799","city_name":"Aramoko","state_id":"2660"},
    {"city_id":"30800","city_name":"Efon Alaye","state_id":"2660"},
    {"city_id":"30801","city_name":"Emure","state_id":"2660"},
    {"city_id":"30802","city_name":"Igbara Odo","state_id":"2660"},
    {"city_id":"30803","city_name":"Igede","state_id":"2660"},
    {"city_id":"30804","city_name":"Ijero","state_id":"2660"},
    {"city_id":"30805","city_name":"Ikere","state_id":"2660"},
    {"city_id":"30806","city_name":"Ikole","state_id":"2660"},
    {"city_id":"30807","city_name":"Ilawe","state_id":"2660"},
    {"city_id":"30808","city_name":"Ipoti","state_id":"2660"},
    {"city_id":"30809","city_name":"Ise","state_id":"2660"},
    {"city_id":"30810","city_name":"Ode","state_id":"2660"},
    {"city_id":"30811","city_name":"Omuo","state_id":"2660"},
    {"city_id":"30812","city_name":"Osi","state_id":"2660"},
    {"city_id":"30813","city_name":"Agwa","state_id":"2661"},
    {"city_id":"30814","city_name":"Aku","state_id":"2661"},
    {"city_id":"30815","city_name":"Awgu","state_id":"2661"},
    {"city_id":"30816","city_name":"Eha Amufu","state_id":"2661"},
    {"city_id":"30817","city_name":"Enugu","state_id":"2661"},
    {"city_id":"30818","city_name":"Enugu Ezike","state_id":"2661"},
    {"city_id":"30819","city_name":"Enugu Ngwo","state_id":"2661"},
    {"city_id":"30820","city_name":"Ezeagu","state_id":"2661"},
    {"city_id":"30821","city_name":"Mberubu","state_id":"2661"},
    {"city_id":"30822","city_name":"Nsukka","state_id":"2661"},
    {"city_id":"30823","city_name":"Oji","state_id":"2661"},
    {"city_id":"30824","city_name":"Udi","state_id":"2661"},
    {"city_id":"30825","city_name":"Ako","state_id":"2662"},
    {"city_id":"30826","city_name":"Deba","state_id":"2662"},
    {"city_id":"30827","city_name":"Duku","state_id":"2662"},
    {"city_id":"30828","city_name":"Garko","state_id":"2662"},
    {"city_id":"30829","city_name":"Gombe","state_id":"2662"},
    {"city_id":"30830","city_name":"Kaltungo","state_id":"2662"},
    {"city_id":"30831","city_name":"Kumo","state_id":"2662"},
    {"city_id":"30832","city_name":"Nafada","state_id":"2662"},
    {"city_id":"30833","city_name":"Pindiga","state_id":"2662"},
    {"city_id":"30834","city_name":"Aboh","state_id":"2663"},
    {"city_id":"30835","city_name":"Etiti","state_id":"2663"},
    {"city_id":"30836","city_name":"Ihite","state_id":"2663"},
    {"city_id":"30837","city_name":"Nkwerre","state_id":"2663"},
    {"city_id":"30838","city_name":"Oguta","state_id":"2663"},
    {"city_id":"30839","city_name":"Okigwe","state_id":"2663"},
    {"city_id":"30840","city_name":"Owerri","state_id":"2663"},
    {"city_id":"30841","city_name":"Babura","state_id":"2664"},
    {"city_id":"30842","city_name":"Birnin Kudu","state_id":"2664"},
    {"city_id":"30843","city_name":"Buji","state_id":"2664"},
    {"city_id":"30844","city_name":"Dutse","state_id":"2664"},
    {"city_id":"30845","city_name":"Garki","state_id":"2664"},
    {"city_id":"30846","city_name":"Gumel","state_id":"2664"},
    {"city_id":"30847","city_name":"Gwaram","state_id":"2664"},
    {"city_id":"30848","city_name":"Gwiwa","state_id":"2664"},
    {"city_id":"30849","city_name":"Hadejia","state_id":"2664"},
    {"city_id":"30850","city_name":"Jahun","state_id":"2664"},
    {"city_id":"30851","city_name":"Kaugama","state_id":"2664"},
    {"city_id":"30852","city_name":"Kazaure","state_id":"2664"},
    {"city_id":"30853","city_name":"Keffin Hausa","state_id":"2664"},
    {"city_id":"30854","city_name":"Kiyawa","state_id":"2664"},
    {"city_id":"30855","city_name":"Maigatari","state_id":"2664"},
    {"city_id":"30856","city_name":"Malammaduri","state_id":"2664"},
    {"city_id":"30857","city_name":"Ringim","state_id":"2664"},
    {"city_id":"30858","city_name":"Sule Tankarkar","state_id":"2664"},
    {"city_id":"30859","city_name":"Taura","state_id":"2664"},
    {"city_id":"30860","city_name":"Birnin Gwari","state_id":"2665"},
    {"city_id":"30861","city_name":"Doka","state_id":"2665"},
    {"city_id":"30862","city_name":"Giwa","state_id":"2665"},
    {"city_id":"30863","city_name":"Gwagwada","state_id":"2665"},
    {"city_id":"30864","city_name":"Hunkuyi","state_id":"2665"},
    {"city_id":"30865","city_name":"Igabi","state_id":"2665"},
    {"city_id":"30866","city_name":"Ikara","state_id":"2665"},
    {"city_id":"30867","city_name":"Jemaa","state_id":"2665"},
    {"city_id":"30868","city_name":"Kachia","state_id":"2665"},
    {"city_id":"30869","city_name":"Kaduna","state_id":"2665"},
    {"city_id":"30870","city_name":"Kafanchan","state_id":"2665"},
    {"city_id":"30871","city_name":"Kagarko","state_id":"2665"},
    {"city_id":"30872","city_name":"Kagoro","state_id":"2665"},
    {"city_id":"30873","city_name":"Kaura","state_id":"2665"},
    {"city_id":"30874","city_name":"Kudan","state_id":"2665"},
    {"city_id":"30875","city_name":"Lere","state_id":"2665"},
    {"city_id":"30876","city_name":"Makarfi","state_id":"2665"},
    {"city_id":"30877","city_name":"Sabon Birnin Gwari","state_id":"2665"},
    {"city_id":"30878","city_name":"Sabongari","state_id":"2665"},
    {"city_id":"30879","city_name":"Sanga","state_id":"2665"},
    {"city_id":"30880","city_name":"Soba","state_id":"2665"},
    {"city_id":"30881","city_name":"Tudun Wada","state_id":"2665"},
    {"city_id":"30882","city_name":"Zangon Katab","state_id":"2665"},
    {"city_id":"30883","city_name":"Zaria","state_id":"2665"},
    {"city_id":"30884","city_name":"Ajingi","state_id":"2666"},
    {"city_id":"30885","city_name":"Albasu","state_id":"2666"},
    {"city_id":"30886","city_name":"Bagwai","state_id":"2666"},
    {"city_id":"30887","city_name":"Bebeji","state_id":"2666"},
    {"city_id":"30888","city_name":"Bichi","state_id":"2666"},
    {"city_id":"30889","city_name":"Bunkure","state_id":"2666"},
    {"city_id":"30890","city_name":"Dambarta","state_id":"2666"},
    {"city_id":"30891","city_name":"Dawakin Tofe","state_id":"2666"},
    {"city_id":"30892","city_name":"Fagge","state_id":"2666"},
    {"city_id":"30893","city_name":"Garko","state_id":"2666"},
    {"city_id":"30894","city_name":"Garun Mallam","state_id":"2666"},
    {"city_id":"30895","city_name":"Gaya","state_id":"2666"},
    {"city_id":"30896","city_name":"Gezawa","state_id":"2666"},
    {"city_id":"30897","city_name":"Gwarzo","state_id":"2666"},
    {"city_id":"30898","city_name":"Kabo","state_id":"2666"},
    {"city_id":"30899","city_name":"Kano","state_id":"2666"},
    {"city_id":"30900","city_name":"Karaye","state_id":"2666"},
    {"city_id":"30901","city_name":"Kibiya","state_id":"2666"},
    {"city_id":"30902","city_name":"Kiru","state_id":"2666"},
    {"city_id":"30903","city_name":"Kumbotso","state_id":"2666"},
    {"city_id":"30904","city_name":"Kunchi","state_id":"2666"},
    {"city_id":"30905","city_name":"Kura","state_id":"2666"},
    {"city_id":"30906","city_name":"Madobi","state_id":"2666"},
    {"city_id":"30907","city_name":"Makoda","state_id":"2666"},
    {"city_id":"30908","city_name":"Nassarawa","state_id":"2666"},
    {"city_id":"30909","city_name":"Rano","state_id":"2666"},
    {"city_id":"30910","city_name":"Rimin Gado","state_id":"2666"},
    {"city_id":"30911","city_name":"Shanono","state_id":"2666"},
    {"city_id":"30912","city_name":"Sumaila","state_id":"2666"},
    {"city_id":"30913","city_name":"Takai","state_id":"2666"},
    {"city_id":"30914","city_name":"Tofa","state_id":"2666"},
    {"city_id":"30915","city_name":"Tudun Wada","state_id":"2666"},
    {"city_id":"30916","city_name":"Wudil","state_id":"2666"},
    {"city_id":"30917","city_name":"Bakori","state_id":"2667"},
    {"city_id":"30918","city_name":"Batsari","state_id":"2667"},
    {"city_id":"30919","city_name":"Bindawa","state_id":"2667"},
    {"city_id":"30920","city_name":"Cheranchi","state_id":"2667"},
    {"city_id":"30921","city_name":"Dan Dume","state_id":"2667"},
    {"city_id":"30922","city_name":"Danja","state_id":"2667"},
    {"city_id":"30923","city_name":"Daura","state_id":"2667"},
    {"city_id":"30924","city_name":"Dutsi","state_id":"2667"},
    {"city_id":"30925","city_name":"Dutsin Ma","state_id":"2667"},
    {"city_id":"30926","city_name":"Faskari","state_id":"2667"},
    {"city_id":"30927","city_name":"Funtua","state_id":"2667"},
    {"city_id":"30928","city_name":"Ingawa","state_id":"2667"},
    {"city_id":"30929","city_name":"Jibiya","state_id":"2667"},
    {"city_id":"30930","city_name":"Kangiwa","state_id":"2667"},
    {"city_id":"30931","city_name":"Kankara","state_id":"2667"},
    {"city_id":"30932","city_name":"Kankiya","state_id":"2667"},
    {"city_id":"30933","city_name":"Katsina","state_id":"2667"},
    {"city_id":"30934","city_name":"Kurfi","state_id":"2667"},
    {"city_id":"30935","city_name":"Malumfashi","state_id":"2667"},
    {"city_id":"30936","city_name":"Mani","state_id":"2667"},
    {"city_id":"30937","city_name":"Mashi","state_id":"2667"},
    {"city_id":"30938","city_name":"Musawa","state_id":"2667"},
    {"city_id":"30939","city_name":"Rimi","state_id":"2667"},
    {"city_id":"30940","city_name":"Sandamu","state_id":"2667"},
    {"city_id":"30941","city_name":"Zango","state_id":"2667"},
    {"city_id":"30942","city_name":"Argungu","state_id":"2668"},
    {"city_id":"30943","city_name":"Augie","state_id":"2668"},
    {"city_id":"30944","city_name":"Bagudo","state_id":"2668"},
    {"city_id":"30945","city_name":"Birnin Kebbi","state_id":"2668"},
    {"city_id":"30946","city_name":"Birnin Yauri","state_id":"2668"},
    {"city_id":"30947","city_name":"Bunza","state_id":"2668"},
    {"city_id":"30948","city_name":"Fakai","state_id":"2668"},
    {"city_id":"30949","city_name":"Gwandu","state_id":"2668"},
    {"city_id":"30950","city_name":"Jega","state_id":"2668"},
    {"city_id":"30951","city_name":"Kalgo","state_id":"2668"},
    {"city_id":"30952","city_name":"Koko","state_id":"2668"},
    {"city_id":"30953","city_name":"Maiyema","state_id":"2668"},
    {"city_id":"30954","city_name":"Sakaba","state_id":"2668"},
    {"city_id":"30955","city_name":"Shanga","state_id":"2668"},
    {"city_id":"30956","city_name":"Suru","state_id":"2668"},
    {"city_id":"30957","city_name":"Wasagu","state_id":"2668"},
    {"city_id":"30958","city_name":"Zuru","state_id":"2668"},
    {"city_id":"30959","city_name":"Ajaokuta","state_id":"2669"},
    {"city_id":"30960","city_name":"Ankpa","state_id":"2669"},
    {"city_id":"30961","city_name":"Dekina","state_id":"2669"},
    {"city_id":"30962","city_name":"Idah","state_id":"2669"},
    {"city_id":"30963","city_name":"Kabba","state_id":"2669"},
    {"city_id":"30964","city_name":"Koton-Karifi","state_id":"2669"},
    {"city_id":"30965","city_name":"Kuroro","state_id":"2669"},
    {"city_id":"30966","city_name":"Lokoja","state_id":"2669"},
    {"city_id":"30967","city_name":"Mopa","state_id":"2669"},
    {"city_id":"30968","city_name":"Ogaminana","state_id":"2669"},
    {"city_id":"30969","city_name":"Ogori","state_id":"2669"},
    {"city_id":"30970","city_name":"Okene","state_id":"2669"},
    {"city_id":"30971","city_name":"Ajasse","state_id":"2670"},
    {"city_id":"30972","city_name":"Ilorin","state_id":"2670"},
    {"city_id":"30973","city_name":"Jebba","state_id":"2670"},
    {"city_id":"30974","city_name":"Kaiama","state_id":"2670"},
    {"city_id":"30975","city_name":"Lafiagi","state_id":"2670"},
    {"city_id":"30976","city_name":"Offa","state_id":"2670"},
    {"city_id":"30977","city_name":"Pategi","state_id":"2670"},
    {"city_id":"30978","city_name":"Apapa","state_id":"2671"},
    {"city_id":"30979","city_name":"Badagri","state_id":"2671"},
    {"city_id":"30980","city_name":"Epe","state_id":"2671"},
    {"city_id":"30981","city_name":"Ibeju","state_id":"2671"},
    {"city_id":"30982","city_name":"Iganmi","state_id":"2671"},
    {"city_id":"30983","city_name":"Ikeja","state_id":"2671"},
    {"city_id":"30984","city_name":"Ikorodu","state_id":"2671"},
    {"city_id":"30985","city_name":"Lagos","state_id":"2671"},
    {"city_id":"30986","city_name":"Ojo","state_id":"2671"},
    {"city_id":"30987","city_name":"Surulere","state_id":"2671"},
    {"city_id":"30988","city_name":"Akwanga","state_id":"2672"},
    {"city_id":"30989","city_name":"Awe","state_id":"2672"},
    {"city_id":"30990","city_name":"Doma","state_id":"2672"},
    {"city_id":"30991","city_name":"Keana","state_id":"2672"},
    {"city_id":"30992","city_name":"Keffi","state_id":"2672"},
    {"city_id":"30993","city_name":"Lafia","state_id":"2672"},
    {"city_id":"30994","city_name":"Nassarawa","state_id":"2672"},
    {"city_id":"30995","city_name":"Obi","state_id":"2672"},
    {"city_id":"30996","city_name":"Toto","state_id":"2672"},
    {"city_id":"30997","city_name":"Wamba","state_id":"2672"},
    {"city_id":"30998","city_name":"Agale","state_id":"2673"},
    {"city_id":"30999","city_name":"Babana","state_id":"2673"},
    {"city_id":"31000","city_name":"Bida","state_id":"2673"},
    {"city_id":"31001","city_name":"Bosso","state_id":"2673"},
    {"city_id":"31002","city_name":"Chanchaga","state_id":"2673"},
    {"city_id":"31003","city_name":"Gbako","state_id":"2673"},
    {"city_id":"31004","city_name":"Kontagora","state_id":"2673"},
    {"city_id":"31005","city_name":"Lapai","state_id":"2673"},
    {"city_id":"31006","city_name":"Minna","state_id":"2673"},
    {"city_id":"31007","city_name":"Mokwa","state_id":"2673"},
    {"city_id":"31008","city_name":"New Bussa","state_id":"2673"},
    {"city_id":"31009","city_name":"Rijau","state_id":"2673"},
    {"city_id":"31010","city_name":"Shiroro","state_id":"2673"},
    {"city_id":"31011","city_name":"Suleja","state_id":"2673"},
    {"city_id":"31012","city_name":"Wushishi","state_id":"2673"},
    {"city_id":"31013","city_name":"Abeokuta","state_id":"2674"},
    {"city_id":"31014","city_name":"Ado Odo","state_id":"2674"},
    {"city_id":"31015","city_name":"Agbara","state_id":"2674"},
    {"city_id":"31016","city_name":"Aiyetoro","state_id":"2674"},
    {"city_id":"31017","city_name":"Ewekoro","state_id":"2674"},
    {"city_id":"31018","city_name":"Ifo","state_id":"2674"},
    {"city_id":"31019","city_name":"Ijebu Igbo","state_id":"2674"},
    {"city_id":"31020","city_name":"Ijebu Ode","state_id":"2674"},
    {"city_id":"31021","city_name":"Ikene","state_id":"2674"},
    {"city_id":"31022","city_name":"Ilaro","state_id":"2674"},
    {"city_id":"31023","city_name":"Ipokia","state_id":"2674"},
    {"city_id":"31024","city_name":"Odogbolu","state_id":"2674"},
    {"city_id":"31025","city_name":"Owode","state_id":"2674"},
    {"city_id":"31026","city_name":"Sango Ota","state_id":"2674"},
    {"city_id":"31027","city_name":"Shagamu","state_id":"2674"},
    {"city_id":"31028","city_name":"Akure","state_id":"2675"},
    {"city_id":"31029","city_name":"Idanre","state_id":"2675"},
    {"city_id":"31030","city_name":"Ikare","state_id":"2675"},
    {"city_id":"31031","city_name":"Irele","state_id":"2675"},
    {"city_id":"31032","city_name":"Odigbo","state_id":"2675"},
    {"city_id":"31033","city_name":"Oka","state_id":"2675"},
    {"city_id":"31034","city_name":"Okitipupa","state_id":"2675"},
    {"city_id":"31035","city_name":"Ondo","state_id":"2675"},
    {"city_id":"31036","city_name":"Owo","state_id":"2675"},
    {"city_id":"31037","city_name":"Apomu","state_id":"2676"},
    {"city_id":"31038","city_name":"Ede","state_id":"2676"},
    {"city_id":"31039","city_name":"Ejigbo","state_id":"2676"},
    {"city_id":"31040","city_name":"Erin-Oshogbo","state_id":"2676"},
    {"city_id":"31041","city_name":"Gbongan","state_id":"2676"},
    {"city_id":"31042","city_name":"Ife","state_id":"2676"},
    {"city_id":"31043","city_name":"Ifon Osun","state_id":"2676"},
    {"city_id":"31044","city_name":"Ijesha","state_id":"2676"},
    {"city_id":"31045","city_name":"Ikire","state_id":"2676"},
    {"city_id":"31046","city_name":"Ikirun","state_id":"2676"},
    {"city_id":"31047","city_name":"Ila","state_id":"2676"},
    {"city_id":"31048","city_name":"Ilesha","state_id":"2676"},
    {"city_id":"31049","city_name":"Ilobu","state_id":"2676"},
    {"city_id":"31050","city_name":"Inisa","state_id":"2676"},
    {"city_id":"31051","city_name":"Iwo","state_id":"2676"},
    {"city_id":"31052","city_name":"Modakeke","state_id":"2676"},
    {"city_id":"31053","city_name":"Oke-Mesi","state_id":"2676"},
    {"city_id":"31054","city_name":"Olorunda","state_id":"2676"},
    {"city_id":"31055","city_name":"Olupona","state_id":"2676"},
    {"city_id":"31056","city_name":"Ore","state_id":"2676"},
    {"city_id":"31057","city_name":"Orolu","state_id":"2676"},
    {"city_id":"31058","city_name":"Oshogbo","state_id":"2676"},
    {"city_id":"31059","city_name":"Oyan","state_id":"2676"},
    {"city_id":"31060","city_name":"Akinyele","state_id":"2677"},
    {"city_id":"31061","city_name":"Egbeda","state_id":"2677"},
    {"city_id":"31062","city_name":"Eruwa","state_id":"2677"},
    {"city_id":"31063","city_name":"Fiditi","state_id":"2677"},
    {"city_id":"31064","city_name":"Ibadan","state_id":"2677"},
    {"city_id":"31065","city_name":"Ibeto","state_id":"2677"},
    {"city_id":"31066","city_name":"Igbo Ora","state_id":"2677"},
    {"city_id":"31067","city_name":"Igboho","state_id":"2677"},
    {"city_id":"31068","city_name":"Iseyin","state_id":"2677"},
    {"city_id":"31069","city_name":"Kajola","state_id":"2677"},
    {"city_id":"31070","city_name":"Kishi","state_id":"2677"},
    {"city_id":"31071","city_name":"Lalupon","state_id":"2677"},
    {"city_id":"31072","city_name":"Ogbomosho","state_id":"2677"},
    {"city_id":"31073","city_name":"Ogo","state_id":"2677"},
    {"city_id":"31074","city_name":"Oke-Iho","state_id":"2677"},
    {"city_id":"31075","city_name":"Oyo","state_id":"2677"},
    {"city_id":"31076","city_name":"Shaki","state_id":"2677"},
    {"city_id":"31077","city_name":"Barakin","state_id":"2678"},
    {"city_id":"31078","city_name":"Bassa","state_id":"2678"},
    {"city_id":"31079","city_name":"Bokkos","state_id":"2678"},
    {"city_id":"31080","city_name":"Bukuru","state_id":"2678"},
    {"city_id":"31081","city_name":"Jos","state_id":"2678"},
    {"city_id":"31082","city_name":"Langtang","state_id":"2678"},
    {"city_id":"31083","city_name":"Pankshin","state_id":"2678"},
    {"city_id":"31084","city_name":"Riyom","state_id":"2678"},
    {"city_id":"31085","city_name":"Shendam","state_id":"2678"},
    {"city_id":"31086","city_name":"Vom","state_id":"2678"},
    {"city_id":"31087","city_name":"Wase","state_id":"2678"},
    {"city_id":"31088","city_name":"Abonnema","state_id":"2679"},
    {"city_id":"31089","city_name":"Abua","state_id":"2679"},
    {"city_id":"31090","city_name":"Ahoada","state_id":"2679"},
    {"city_id":"31091","city_name":"Bonny","state_id":"2679"},
    {"city_id":"31092","city_name":"Bugama","state_id":"2679"},
    {"city_id":"31093","city_name":"Degema","state_id":"2679"},
    {"city_id":"31094","city_name":"Egbema","state_id":"2679"},
    {"city_id":"31095","city_name":"Ogu","state_id":"2679"},
    {"city_id":"31096","city_name":"Okrika","state_id":"2679"},
    {"city_id":"31097","city_name":"Omoko","state_id":"2679"},
    {"city_id":"31098","city_name":"Opobo","state_id":"2679"},
    {"city_id":"31099","city_name":"Oyigbo","state_id":"2679"},
    {"city_id":"31100","city_name":"Port Harcourt","state_id":"2679"},
    {"city_id":"31101","city_name":"Binji","state_id":"2680"},
    {"city_id":"31102","city_name":"Bodinga","state_id":"2680"},
    {"city_id":"31103","city_name":"Dange","state_id":"2680"},
    {"city_id":"31104","city_name":"Gada","state_id":"2680"},
    {"city_id":"31105","city_name":"Goronyo","state_id":"2680"},
    {"city_id":"31106","city_name":"Gwadabawa","state_id":"2680"},
    {"city_id":"31107","city_name":"Illela","state_id":"2680"},
    {"city_id":"31108","city_name":"Kebbe","state_id":"2680"},
    {"city_id":"31109","city_name":"Kware","state_id":"2680"},
    {"city_id":"31110","city_name":"Rabah","state_id":"2680"},
    {"city_id":"31111","city_name":"Raka","state_id":"2680"},
    {"city_id":"31112","city_name":"Sabon Birni","state_id":"2680"},
    {"city_id":"31113","city_name":"Sokoto","state_id":"2680"},
    {"city_id":"31114","city_name":"Tambawel","state_id":"2680"},
    {"city_id":"31115","city_name":"Tureta","state_id":"2680"},
    {"city_id":"31116","city_name":"Wamako","state_id":"2680"},
    {"city_id":"31117","city_name":"Wurno","state_id":"2680"},
    {"city_id":"31118","city_name":"Bali","state_id":"2681"},
    {"city_id":"31119","city_name":"Gashaka","state_id":"2681"},
    {"city_id":"31120","city_name":"Gassol","state_id":"2681"},
    {"city_id":"31121","city_name":"Ibi","state_id":"2681"},
    {"city_id":"31122","city_name":"Jalingo","state_id":"2681"},
    {"city_id":"31123","city_name":"Lau","state_id":"2681"},
    {"city_id":"31124","city_name":"Takum","state_id":"2681"},
    {"city_id":"31125","city_name":"Wukari","state_id":"2681"},
    {"city_id":"31126","city_name":"Yorro","state_id":"2681"},
    {"city_id":"31127","city_name":"Damaturu","state_id":"2682"},
    {"city_id":"31128","city_name":"Fika","state_id":"2682"},
    {"city_id":"31129","city_name":"Gashua","state_id":"2682"},
    {"city_id":"31130","city_name":"Geidam","state_id":"2682"},
    {"city_id":"31131","city_name":"Gorgoram","state_id":"2682"},
    {"city_id":"31132","city_name":"Gujba","state_id":"2682"},
    {"city_id":"31133","city_name":"Gulani","state_id":"2682"},
    {"city_id":"31134","city_name":"Jakusko","state_id":"2682"},
    {"city_id":"31135","city_name":"Matsena","state_id":"2682"},
    {"city_id":"31136","city_name":"Nguru","state_id":"2682"},
    {"city_id":"31137","city_name":"Potiskum","state_id":"2682"},
    {"city_id":"31138","city_name":"Yusufari","state_id":"2682"},
    {"city_id":"31139","city_name":"Anka","state_id":"2683"},
    {"city_id":"31140","city_name":"Bungudu","state_id":"2683"},
    {"city_id":"31141","city_name":"Chafe","state_id":"2683"},
    {"city_id":"31142","city_name":"Gummi","state_id":"2683"},
    {"city_id":"31143","city_name":"Gusau","state_id":"2683"},
    {"city_id":"31144","city_name":"Isa","state_id":"2683"},
    {"city_id":"31145","city_name":"Kaura Namoda","state_id":"2683"},
    {"city_id":"31146","city_name":"Kiyawa","state_id":"2683"},
    {"city_id":"31147","city_name":"Maradun","state_id":"2683"},
    {"city_id":"31148","city_name":"Maru","state_id":"2683"},
    {"city_id":"31149","city_name":"Shinkafe","state_id":"2683"},
    {"city_id":"31150","city_name":"Talata Mafara","state_id":"2683"},
    {"city_id":"31151","city_name":"Zurmi","state_id":"2683"},
    {"city_id":"31152","city_name":"Niue","state_id":"2684"},
    {"city_id":"31153","city_name":"Songsong","state_id":"2687"},
    {"city_id":"31154","city_name":"Capital Hill","state_id":"2688"},
    {"city_id":"31155","city_name":"Chalan Kanoa","state_id":"2688"},
    {"city_id":"31156","city_name":"Dandan","state_id":"2688"},
    {"city_id":"31157","city_name":"Garapan","state_id":"2688"},
    {"city_id":"31158","city_name":"Gualo Rai","state_id":"2688"},
    {"city_id":"31159","city_name":"Kagman","state_id":"2688"},
    {"city_id":"31160","city_name":"Koblerville","state_id":"2688"},
    {"city_id":"31161","city_name":"San Antonio","state_id":"2688"},
    {"city_id":"31162","city_name":"San Jose","state_id":"2688"},
    {"city_id":"31163","city_name":"San Roque","state_id":"2688"},
    {"city_id":"31164","city_name":"San Vicente","state_id":"2688"},
    {"city_id":"31165","city_name":"Susupe","state_id":"2688"},
    {"city_id":"31166","city_name":"Tanapag","state_id":"2688"},
    {"city_id":"31167","city_name":"San Jose","state_id":"2689"},
    {"city_id":"31168","city_name":"Asker","state_id":"2690"},
    {"city_id":"31169","city_name":"Billingstad","state_id":"2690"},
    {"city_id":"31170","city_name":"Haslum","state_id":"2690"},
    {"city_id":"31171","city_name":"Hosle","state_id":"2690"},
    {"city_id":"31172","city_name":"Kjeller","state_id":"2690"},
    {"city_id":"31173","city_name":"Lillestrom","state_id":"2690"},
    {"city_id":"31174","city_name":"Lorenskog","state_id":"2690"},
    {"city_id":"31175","city_name":"Lysaker","state_id":"2690"},
    {"city_id":"31176","city_name":"LÃƒÂ¸renskog","state_id":"2690"},
    {"city_id":"31177","city_name":"Rud","state_id":"2690"},
    {"city_id":"31178","city_name":"Sandvika","state_id":"2690"},
    {"city_id":"31179","city_name":"Strommen","state_id":"2690"},
    {"city_id":"31180","city_name":"Kokstad","state_id":"2692"},
    {"city_id":"31181","city_name":"Drammen","state_id":"2693"},
    {"city_id":"31182","city_name":"Hokksund","state_id":"2693"},
    {"city_id":"31183","city_name":"Honefoss","state_id":"2693"},
    {"city_id":"31184","city_name":"Kongsberg","state_id":"2693"},
    {"city_id":"31185","city_name":"Lyngdal","state_id":"2693"},
    {"city_id":"31186","city_name":"NÃƒÂ¦rsnes","state_id":"2693"},
    {"city_id":"31187","city_name":"Vestby","state_id":"2693"},
    {"city_id":"31188","city_name":"Baatsfjord","state_id":"2694"},
    {"city_id":"31189","city_name":"Hammerfest","state_id":"2694"},
    {"city_id":"31190","city_name":"Brumunddal","state_id":"2695"},
    {"city_id":"31191","city_name":"Elverum","state_id":"2695"},
    {"city_id":"31192","city_name":"Hamar","state_id":"2695"},
    {"city_id":"31193","city_name":"Ilseng","state_id":"2695"},
    {"city_id":"31194","city_name":"Rena","state_id":"2695"},
    {"city_id":"31195","city_name":"Trysil","state_id":"2695"},
    {"city_id":"31196","city_name":"Bergen","state_id":"2696"},
    {"city_id":"31197","city_name":"HÃƒÂ¸ylandsbygd","state_id":"2696"},
    {"city_id":"31198","city_name":"Lonevag","state_id":"2696"},
    {"city_id":"31199","city_name":"Straume","state_id":"2696"},
    {"city_id":"31200","city_name":"Tysnes","state_id":"2696"},
    {"city_id":"31201","city_name":"Voss","state_id":"2696"},
    {"city_id":"31202","city_name":"Bodo","state_id":"2699"},
    {"city_id":"31203","city_name":"Mosjoen","state_id":"2699"},
    {"city_id":"31204","city_name":"Narvik","state_id":"2699"},
    {"city_id":"31205","city_name":"Nesna","state_id":"2699"},
    {"city_id":"31206","city_name":"Saltdal","state_id":"2699"},
    {"city_id":"31207","city_name":"Sortland","state_id":"2699"},
    {"city_id":"31208","city_name":"Steigen","state_id":"2699"},
    {"city_id":"31209","city_name":"Askim","state_id":"2700"},
    {"city_id":"31210","city_name":"Fredrikstad","state_id":"2700"},
    {"city_id":"31211","city_name":"Halden","state_id":"2700"},
    {"city_id":"31212","city_name":"Hovik","state_id":"2700"},
    {"city_id":"31213","city_name":"Moss","state_id":"2700"},
    {"city_id":"31214","city_name":"Mysen","state_id":"2700"},
    {"city_id":"31215","city_name":"Sarpsborg","state_id":"2700"},
    {"city_id":"31216","city_name":"Tistedal","state_id":"2700"},
    {"city_id":"31217","city_name":"Lena","state_id":"2701"},
    {"city_id":"31218","city_name":"Lillehammer","state_id":"2701"},
    {"city_id":"31219","city_name":"Oslo","state_id":"2702"},
    {"city_id":"31220","city_name":"Skedsmo","state_id":"2702"},
    {"city_id":"31221","city_name":"Skjetten","state_id":"2702"},
    {"city_id":"31222","city_name":"Egersund","state_id":"2703"},
    {"city_id":"31223","city_name":"Haugesund","state_id":"2703"},
    {"city_id":"31224","city_name":"Kleppe","state_id":"2703"},
    {"city_id":"31225","city_name":"Sandnes","state_id":"2703"},
    {"city_id":"31226","city_name":"Sola","state_id":"2703"},
    {"city_id":"31227","city_name":"Stavanger","state_id":"2703"},
    {"city_id":"31228","city_name":"Stavern","state_id":"2706"},
    {"city_id":"31229","city_name":"Sykkylven","state_id":"2707"},
    {"city_id":"31230","city_name":"Notodden","state_id":"2708"},
    {"city_id":"31231","city_name":"Skien","state_id":"2708"},
    {"city_id":"31232","city_name":"Harstad","state_id":"2709"},
    {"city_id":"31233","city_name":"Troms","state_id":"2709"},
    {"city_id":"31234","city_name":"TromsÃƒÂ¸","state_id":"2709"},
    {"city_id":"31235","city_name":"Horten","state_id":"2711"},
    {"city_id":"31236","city_name":"HusÃƒÂ¸ysund","state_id":"2711"},
    {"city_id":"31237","city_name":"Larvik","state_id":"2711"},
    {"city_id":"31238","city_name":"Rygge","state_id":"2711"},
    {"city_id":"31239","city_name":"Sandefjord","state_id":"2711"},
    {"city_id":"31240","city_name":"TÃƒÂ¸nsberg","state_id":"2711"},
    {"city_id":"31241","city_name":"Salalah","state_id":"2714"},
    {"city_id":"31242","city_name":"Azaiba","state_id":"2715"},
    {"city_id":"31243","city_name":"Bawshar","state_id":"2715"},
    {"city_id":"31244","city_name":"Madinat Qabus","state_id":"2715"},
    {"city_id":"31245","city_name":"Masqat","state_id":"2715"},
    {"city_id":"31246","city_name":"Matrah","state_id":"2715"},
    {"city_id":"31247","city_name":"Muscat","state_id":"2715"},
    {"city_id":"31248","city_name":"Muttrah","state_id":"2715"},
    {"city_id":"31249","city_name":"Qurayyat","state_id":"2715"},
    {"city_id":"31250","city_name":"Qurm","state_id":"2715"},
    {"city_id":"31251","city_name":"Ruwi","state_id":"2715"},
    {"city_id":"31252","city_name":"Wadi Al Kabir","state_id":"2715"},
    {"city_id":"31253","city_name":"as-Sib","state_id":"2715"},
    {"city_id":"31254","city_name":"Khasab","state_id":"2716"},
    {"city_id":"31255","city_name":"Rusayl","state_id":"2717"},
    {"city_id":"31256","city_name":"Bahla'","state_id":"2719"},
    {"city_id":"31257","city_name":"Nizwa","state_id":"2719"},
    {"city_id":"31258","city_name":"Sumayl","state_id":"2719"},
    {"city_id":"31259","city_name":"'Ibri","state_id":"2720"},
    {"city_id":"31260","city_name":"al-Buraymi","state_id":"2720"},
    {"city_id":"31261","city_name":"Al khuwair","state_id":"2721"},
    {"city_id":"31262","city_name":"Barkah","state_id":"2721"},
    {"city_id":"31263","city_name":"Saham","state_id":"2721"},
    {"city_id":"31264","city_name":"Shinas","state_id":"2721"},
    {"city_id":"31265","city_name":"Suhar","state_id":"2721"},
    {"city_id":"31266","city_name":"al-Khaburah","state_id":"2721"},
    {"city_id":"31267","city_name":"al-Masna'ah","state_id":"2721"},
    {"city_id":"31268","city_name":"ar-Rustaq","state_id":"2721"},
    {"city_id":"31269","city_name":"as-Suwayq","state_id":"2721"},
    {"city_id":"31270","city_name":"Ibra","state_id":"2722"},
    {"city_id":"31271","city_name":"Sur","state_id":"2722"},
    {"city_id":"31272","city_name":"al-Mudaybi","state_id":"2722"},
    {"city_id":"31273","city_name":"Barkhan","state_id":"2723"},
    {"city_id":"31274","city_name":"Bela","state_id":"2723"},
    {"city_id":"31275","city_name":"Bhag","state_id":"2723"},
    {"city_id":"31276","city_name":"Chaman","state_id":"2723"},
    {"city_id":"31277","city_name":"Chitkan","state_id":"2723"},
    {"city_id":"31278","city_name":"Dalbandin","state_id":"2723"},
    {"city_id":"31279","city_name":"Dera Allah Yar","state_id":"2723"},
    {"city_id":"31280","city_name":"Dera Bugti","state_id":"2723"},
    {"city_id":"31281","city_name":"Dera Murad Jamali","state_id":"2723"},
    {"city_id":"31282","city_name":"Dhadar","state_id":"2723"},
    {"city_id":"31283","city_name":"Duki","state_id":"2723"},
    {"city_id":"31284","city_name":"Gaddani","state_id":"2723"},
    {"city_id":"31285","city_name":"Gwadar","state_id":"2723"},
    {"city_id":"31286","city_name":"Harnai","state_id":"2723"},
    {"city_id":"31287","city_name":"Hub","state_id":"2723"},
    {"city_id":"31288","city_name":"Jiwani","state_id":"2723"},
    {"city_id":"31289","city_name":"Kalat","state_id":"2723"},
    {"city_id":"31290","city_name":"Kharan","state_id":"2723"},
    {"city_id":"31291","city_name":"Khuzdar","state_id":"2723"},
    {"city_id":"31292","city_name":"Kohlu","state_id":"2723"},
    {"city_id":"31293","city_name":"Loralai","state_id":"2723"},
    {"city_id":"31294","city_name":"Mach","state_id":"2723"},
    {"city_id":"31295","city_name":"Mastung","state_id":"2723"},
    {"city_id":"31296","city_name":"Nushki","state_id":"2723"},
    {"city_id":"31297","city_name":"Ormara","state_id":"2723"},
    {"city_id":"31298","city_name":"Pasni","state_id":"2723"},
    {"city_id":"31299","city_name":"Pishin","state_id":"2723"},
    {"city_id":"31300","city_name":"Quetta","state_id":"2723"},
    {"city_id":"31301","city_name":"Sibi","state_id":"2723"},
    {"city_id":"31302","city_name":"Sohbatpur","state_id":"2723"},
    {"city_id":"31303","city_name":"Surab","state_id":"2723"},
    {"city_id":"31304","city_name":"Turbat","state_id":"2723"},
    {"city_id":"31305","city_name":"Usta Muhammad","state_id":"2723"},
    {"city_id":"31306","city_name":"Uthal","state_id":"2723"},
    {"city_id":"31307","city_name":"Wadh","state_id":"2723"},
    {"city_id":"31308","city_name":"Winder","state_id":"2723"},
    {"city_id":"31309","city_name":"Zehri","state_id":"2723"},
    {"city_id":"31310","city_name":"Zhob","state_id":"2723"},
    {"city_id":"31311","city_name":"Ziarat","state_id":"2723"},
    {"city_id":"31312","city_name":"'Abdul Hakim","state_id":"2728"},
    {"city_id":"31313","city_name":"Ahmadpur East","state_id":"2728"},
    {"city_id":"31314","city_name":"Ahmadpur Lumma","state_id":"2728"},
    {"city_id":"31315","city_name":"Ahmadpur Sial","state_id":"2728"},
    {"city_id":"31316","city_name":"Ahmedabad","state_id":"2728"},
    {"city_id":"31317","city_name":"Alipur","state_id":"2728"},
    {"city_id":"31318","city_name":"Alipur Chatha","state_id":"2728"},
    {"city_id":"31319","city_name":"Arifwala","state_id":"2728"},
    {"city_id":"31320","city_name":"Attock","state_id":"2728"},
    {"city_id":"31321","city_name":"Baddomalhi","state_id":"2728"},
    {"city_id":"31322","city_name":"Bagh","state_id":"2728"},
    {"city_id":"31323","city_name":"Bahawalnagar","state_id":"2728"},
    {"city_id":"31324","city_name":"Bahawalpur","state_id":"2728"},
    {"city_id":"31325","city_name":"Bai Pheru","state_id":"2728"},
    {"city_id":"31326","city_name":"Basirpur","state_id":"2728"},
    {"city_id":"31327","city_name":"Begowala","state_id":"2728"},
    {"city_id":"31328","city_name":"Bhakkar","state_id":"2728"},
    {"city_id":"31329","city_name":"Bhalwal","state_id":"2728"},
    {"city_id":"31330","city_name":"Bhawana","state_id":"2728"},
    {"city_id":"31331","city_name":"Bhera","state_id":"2728"},
    {"city_id":"31332","city_name":"Bhopalwala","state_id":"2728"},
    {"city_id":"31333","city_name":"Burewala","state_id":"2728"},
    {"city_id":"31334","city_name":"Chak Azam Sahu","state_id":"2728"},
    {"city_id":"31335","city_name":"Chak Jhumra","state_id":"2728"},
    {"city_id":"31336","city_name":"Chak Sarwar Shahid","state_id":"2728"},
    {"city_id":"31337","city_name":"Chakwal","state_id":"2728"},
    {"city_id":"31338","city_name":"Chawinda","state_id":"2728"},
    {"city_id":"31339","city_name":"Chichawatni","state_id":"2728"},
    {"city_id":"31340","city_name":"Chiniot","state_id":"2728"},
    {"city_id":"31341","city_name":"Chishtian Mandi","state_id":"2728"},
    {"city_id":"31342","city_name":"Choa Saidan Shah","state_id":"2728"},
    {"city_id":"31343","city_name":"Chuhar Kana","state_id":"2728"},
    {"city_id":"31344","city_name":"Chunian","state_id":"2728"},
    {"city_id":"31345","city_name":"Dajal","state_id":"2728"},
    {"city_id":"31346","city_name":"Darya Khan","state_id":"2728"},
    {"city_id":"31347","city_name":"Daska","state_id":"2728"},
    {"city_id":"31348","city_name":"Daud Khel","state_id":"2728"},
    {"city_id":"31349","city_name":"Daultala","state_id":"2728"},
    {"city_id":"31350","city_name":"Dera Din Panah","state_id":"2728"},
    {"city_id":"31351","city_name":"Dera Ghazi Khan","state_id":"2728"},
    {"city_id":"31352","city_name":"Dhanote","state_id":"2728"},
    {"city_id":"31353","city_name":"Dhonkal","state_id":"2728"},
    {"city_id":"31354","city_name":"Dijkot","state_id":"2728"},
    {"city_id":"31355","city_name":"Dina","state_id":"2728"},
    {"city_id":"31356","city_name":"Dinga","state_id":"2728"},
    {"city_id":"31357","city_name":"Dipalpur","state_id":"2728"},
    {"city_id":"31358","city_name":"Dullewala","state_id":"2728"},
    {"city_id":"31359","city_name":"Dunga Bunga","state_id":"2728"},
    {"city_id":"31360","city_name":"Dunyapur","state_id":"2728"},
    {"city_id":"31361","city_name":"Eminabad","state_id":"2728"},
    {"city_id":"31362","city_name":"Faisalabad","state_id":"2728"},
    {"city_id":"31363","city_name":"Faqirwali","state_id":"2728"},
    {"city_id":"31364","city_name":"Faruka","state_id":"2728"},
    {"city_id":"31365","city_name":"Fateh Jang","state_id":"2728"},
    {"city_id":"31366","city_name":"Fatehpur","state_id":"2728"},
    {"city_id":"31367","city_name":"Fazalpur","state_id":"2728"},
    {"city_id":"31368","city_name":"Ferozwala","state_id":"2728"},
    {"city_id":"31369","city_name":"Fort Abbas","state_id":"2728"},
    {"city_id":"31370","city_name":"Garh Maharaja","state_id":"2728"},
    {"city_id":"31371","city_name":"Ghakar","state_id":"2728"},
    {"city_id":"31372","city_name":"Ghurgushti","state_id":"2728"},
    {"city_id":"31373","city_name":"Gojra","state_id":"2728"},
    {"city_id":"31374","city_name":"Gujar Khan","state_id":"2728"},
    {"city_id":"31375","city_name":"Gujranwala","state_id":"2728"},
    {"city_id":"31376","city_name":"Gujrat","state_id":"2728"},
    {"city_id":"31377","city_name":"Hadali","state_id":"2728"},
    {"city_id":"31378","city_name":"Hafizabad","state_id":"2728"},
    {"city_id":"31379","city_name":"Harnoli","state_id":"2728"},
    {"city_id":"31380","city_name":"Harunabad","state_id":"2728"},
    {"city_id":"31381","city_name":"Hasan Abdal","state_id":"2728"},
    {"city_id":"31382","city_name":"Hasilpur","state_id":"2728"},
    {"city_id":"31383","city_name":"Haveli","state_id":"2728"},
    {"city_id":"31384","city_name":"Hazro","state_id":"2728"},
    {"city_id":"31385","city_name":"Hujra Shah Muqim","state_id":"2728"},
    {"city_id":"31386","city_name":"Isa Khel","state_id":"2728"},
    {"city_id":"31387","city_name":"Jahanian","state_id":"2728"},
    {"city_id":"31388","city_name":"Jalalpur Bhattian","state_id":"2728"},
    {"city_id":"31389","city_name":"Jalalpur Jattan","state_id":"2728"},
    {"city_id":"31390","city_name":"Jalalpur Pirwala","state_id":"2728"},
    {"city_id":"31391","city_name":"Jalla Jeem","state_id":"2728"},
    {"city_id":"31392","city_name":"Jamke Chima","state_id":"2728"},
    {"city_id":"31393","city_name":"Jampur","state_id":"2728"},
    {"city_id":"31394","city_name":"Jand","state_id":"2728"},
    {"city_id":"31395","city_name":"Jandanwala","state_id":"2728"},
    {"city_id":"31396","city_name":"Jandiala Sherkhan","state_id":"2728"},
    {"city_id":"31397","city_name":"Jaranwala","state_id":"2728"},
    {"city_id":"31398","city_name":"Jatoi","state_id":"2728"},
    {"city_id":"31399","city_name":"Jauharabad","state_id":"2728"},
    {"city_id":"31400","city_name":"Jhang","state_id":"2728"},
    {"city_id":"31401","city_name":"Jhawarian","state_id":"2728"},
    {"city_id":"31402","city_name":"Jhelum","state_id":"2728"},
    {"city_id":"31403","city_name":"Kabirwala","state_id":"2728"},
    {"city_id":"31404","city_name":"Kahna Nau","state_id":"2728"},
    {"city_id":"31405","city_name":"Kahror Pakka","state_id":"2728"},
    {"city_id":"31406","city_name":"Kahuta","state_id":"2728"},
    {"city_id":"31407","city_name":"Kalabagh","state_id":"2728"},
    {"city_id":"31408","city_name":"Kalaswala","state_id":"2728"},
    {"city_id":"31409","city_name":"Kaleke","state_id":"2728"},
    {"city_id":"31410","city_name":"Kalur Kot","state_id":"2728"},
    {"city_id":"31411","city_name":"Kamalia","state_id":"2728"},
    {"city_id":"31412","city_name":"Kamar Mashani","state_id":"2728"},
    {"city_id":"31413","city_name":"Kamir","state_id":"2728"},
    {"city_id":"31414","city_name":"Kamoke","state_id":"2728"},
    {"city_id":"31415","city_name":"Kamra","state_id":"2728"},
    {"city_id":"31416","city_name":"Kanganpur","state_id":"2728"},
    {"city_id":"31417","city_name":"Karampur","state_id":"2728"},
    {"city_id":"31418","city_name":"Karor Lal Esan","state_id":"2728"},
    {"city_id":"31419","city_name":"Kasur","state_id":"2728"},
    {"city_id":"31420","city_name":"Khairpur Tamewali","state_id":"2728"},
    {"city_id":"31421","city_name":"Khanewal","state_id":"2728"},
    {"city_id":"31422","city_name":"Khangah Dogran","state_id":"2728"},
    {"city_id":"31423","city_name":"Khangarh","state_id":"2728"},
    {"city_id":"31424","city_name":"Khanpur","state_id":"2728"},
    {"city_id":"31425","city_name":"Kharian","state_id":"2728"},
    {"city_id":"31426","city_name":"Khewra","state_id":"2728"},
    {"city_id":"31427","city_name":"Khundian","state_id":"2728"},
    {"city_id":"31428","city_name":"Khurianwala","state_id":"2728"},
    {"city_id":"31429","city_name":"Khushab","state_id":"2728"},
    {"city_id":"31430","city_name":"Kot Abdul Malik","state_id":"2728"},
    {"city_id":"31431","city_name":"Kot Addu","state_id":"2728"},
    {"city_id":"31432","city_name":"Kot Mithan","state_id":"2728"},
    {"city_id":"31433","city_name":"Kot Moman","state_id":"2728"},
    {"city_id":"31434","city_name":"Kot Radha Kishan","state_id":"2728"},
    {"city_id":"31435","city_name":"Kot Samaba","state_id":"2728"},
    {"city_id":"31436","city_name":"Kotli Loharan","state_id":"2728"},
    {"city_id":"31437","city_name":"Kundian","state_id":"2728"},
    {"city_id":"31438","city_name":"Kunjah","state_id":"2728"},
    {"city_id":"31439","city_name":"Lahore","state_id":"2728"},
    {"city_id":"31440","city_name":"Lalamusa","state_id":"2728"},
    {"city_id":"31441","city_name":"Lalian","state_id":"2728"},
    {"city_id":"31442","city_name":"Liaqatabad","state_id":"2728"},
    {"city_id":"31443","city_name":"Liaqatpur","state_id":"2728"},
    {"city_id":"31444","city_name":"Lieah","state_id":"2728"},
    {"city_id":"31445","city_name":"Liliani","state_id":"2728"},
    {"city_id":"31446","city_name":"Lodhran","state_id":"2728"},
    {"city_id":"31447","city_name":"Ludhewala Waraich","state_id":"2728"},
    {"city_id":"31448","city_name":"Mailsi","state_id":"2728"},
    {"city_id":"31449","city_name":"Makhdumpur","state_id":"2728"},
    {"city_id":"31450","city_name":"Makhdumpur Rashid","state_id":"2728"},
    {"city_id":"31451","city_name":"Malakwal","state_id":"2728"},
    {"city_id":"31452","city_name":"Mamu Kanjan","state_id":"2728"},
    {"city_id":"31453","city_name":"Mananwala Jodh Singh","state_id":"2728"},
    {"city_id":"31454","city_name":"Mandi Bahauddin","state_id":"2728"},
    {"city_id":"31455","city_name":"Mandi Sadiq Ganj","state_id":"2728"},
    {"city_id":"31456","city_name":"Mangat","state_id":"2728"},
    {"city_id":"31457","city_name":"Mangla","state_id":"2728"},
    {"city_id":"31458","city_name":"Mankera","state_id":"2728"},
    {"city_id":"31459","city_name":"Mian Channun","state_id":"2728"},
    {"city_id":"31460","city_name":"Miani","state_id":"2728"},
    {"city_id":"31461","city_name":"Mianwali","state_id":"2728"},
    {"city_id":"31462","city_name":"Minchinabad","state_id":"2728"},
    {"city_id":"31463","city_name":"Mitha Tiwana","state_id":"2728"},
    {"city_id":"31464","city_name":"Multan","state_id":"2728"},
    {"city_id":"31465","city_name":"Muridke","state_id":"2728"},
    {"city_id":"31466","city_name":"Murree","state_id":"2728"},
    {"city_id":"31467","city_name":"Mustafabad","state_id":"2728"},
    {"city_id":"31468","city_name":"Muzaffargarh","state_id":"2728"},
    {"city_id":"31469","city_name":"Nankana Sahib","state_id":"2728"},
    {"city_id":"31470","city_name":"Narang","state_id":"2728"},
    {"city_id":"31471","city_name":"Narowal","state_id":"2728"},
    {"city_id":"31472","city_name":"Noorpur Thal","state_id":"2728"},
    {"city_id":"31473","city_name":"Nowshera","state_id":"2728"},
    {"city_id":"31474","city_name":"Nowshera Virkan","state_id":"2728"},
    {"city_id":"31475","city_name":"Okara","state_id":"2728"},
    {"city_id":"31476","city_name":"Pakpattan","state_id":"2728"},
    {"city_id":"31477","city_name":"Pasrur","state_id":"2728"},
    {"city_id":"31478","city_name":"Pattoki","state_id":"2728"},
    {"city_id":"31479","city_name":"Phalia","state_id":"2728"},
    {"city_id":"31480","city_name":"Phularwan","state_id":"2728"},
    {"city_id":"31481","city_name":"Pind Dadan Khan","state_id":"2728"},
    {"city_id":"31482","city_name":"Pindi Bhattian","state_id":"2728"},
    {"city_id":"31483","city_name":"Pindi Gheb","state_id":"2728"},
    {"city_id":"31484","city_name":"Pirmahal","state_id":"2728"},
    {"city_id":"31485","city_name":"Qadirabad","state_id":"2728"},
    {"city_id":"31486","city_name":"Qadirpur Ran","state_id":"2728"},
    {"city_id":"31487","city_name":"Qila Disar Singh","state_id":"2728"},
    {"city_id":"31488","city_name":"Qila Sobha Singh","state_id":"2728"},
    {"city_id":"31489","city_name":"Quaidabad","state_id":"2728"},
    {"city_id":"31490","city_name":"Rabwah","state_id":"2728"},
    {"city_id":"31491","city_name":"Rahim Yar Khan","state_id":"2728"},
    {"city_id":"31492","city_name":"Raiwind","state_id":"2728"},
    {"city_id":"31493","city_name":"Raja Jang","state_id":"2728"},
    {"city_id":"31494","city_name":"Rajanpur","state_id":"2728"},
    {"city_id":"31495","city_name":"Rasulnagar","state_id":"2728"},
    {"city_id":"31496","city_name":"Rawalpindi","state_id":"2728"},
    {"city_id":"31497","city_name":"Renala Khurd","state_id":"2728"},
    {"city_id":"31498","city_name":"Rojhan","state_id":"2728"},
    {"city_id":"31499","city_name":"Saddar Gogera","state_id":"2728"},
    {"city_id":"31500","city_name":"Sadiqabad","state_id":"2728"},
    {"city_id":"31501","city_name":"Safdarabad","state_id":"2728"},
    {"city_id":"31502","city_name":"Sahiwal","state_id":"2728"},
    {"city_id":"31503","city_name":"Samasatta","state_id":"2728"},
    {"city_id":"31504","city_name":"Sambrial","state_id":"2728"},
    {"city_id":"31505","city_name":"Sammundri","state_id":"2728"},
    {"city_id":"31506","city_name":"Sangala Hill","state_id":"2728"},
    {"city_id":"31507","city_name":"Sanjwal","state_id":"2728"},
    {"city_id":"31508","city_name":"Sarai Alamgir","state_id":"2728"},
    {"city_id":"31509","city_name":"Sarai Sidhu","state_id":"2728"},
    {"city_id":"31510","city_name":"Sargodha","state_id":"2728"},
    {"city_id":"31511","city_name":"Shadiwal","state_id":"2728"},
    {"city_id":"31512","city_name":"Shahkot","state_id":"2728"},
    {"city_id":"31513","city_name":"Shahpur City","state_id":"2728"},
    {"city_id":"31514","city_name":"Shahpur Saddar","state_id":"2728"},
    {"city_id":"31515","city_name":"Shakargarh","state_id":"2728"},
    {"city_id":"31516","city_name":"Sharqpur","state_id":"2728"},
    {"city_id":"31517","city_name":"Shehr Sultan","state_id":"2728"},
    {"city_id":"31518","city_name":"Shekhupura","state_id":"2728"},
    {"city_id":"31519","city_name":"Shujaabad","state_id":"2728"},
    {"city_id":"31520","city_name":"Sialkot","state_id":"2728"},
    {"city_id":"31521","city_name":"Sillanwali","state_id":"2728"},
    {"city_id":"31522","city_name":"Sodhra","state_id":"2728"},
    {"city_id":"31523","city_name":"Sohawa","state_id":"2728"},
    {"city_id":"31524","city_name":"Sukheke","state_id":"2728"},
    {"city_id":"31525","city_name":"Talagang","state_id":"2728"},
    {"city_id":"31526","city_name":"Tandlianwala","state_id":"2728"},
    {"city_id":"31527","city_name":"Taunsa","state_id":"2728"},
    {"city_id":"31528","city_name":"Taxila","state_id":"2728"},
    {"city_id":"31529","city_name":"Tibba Sultanpur","state_id":"2728"},
    {"city_id":"31530","city_name":"Toba Tek Singh","state_id":"2728"},
    {"city_id":"31531","city_name":"Tulamba","state_id":"2728"},
    {"city_id":"31532","city_name":"Uch","state_id":"2728"},
    {"city_id":"31533","city_name":"Vihari","state_id":"2728"},
    {"city_id":"31534","city_name":"Wah","state_id":"2728"},
    {"city_id":"31535","city_name":"Warburton","state_id":"2728"},
    {"city_id":"31536","city_name":"Wazirabad","state_id":"2728"},
    {"city_id":"31537","city_name":"Yazman","state_id":"2728"},
    {"city_id":"31538","city_name":"Zafarwal","state_id":"2728"},
    {"city_id":"31539","city_name":"Zahir Pir","state_id":"2728"},
    {"city_id":"31540","city_name":"Adilpur","state_id":"2729"},
    {"city_id":"31541","city_name":"Badah","state_id":"2729"},
    {"city_id":"31542","city_name":"Badin","state_id":"2729"},
    {"city_id":"31543","city_name":"Bagarji","state_id":"2729"},
    {"city_id":"31544","city_name":"Bakshshapur","state_id":"2729"},
    {"city_id":"31545","city_name":"Bandhi","state_id":"2729"},
    {"city_id":"31546","city_name":"Berani","state_id":"2729"},
    {"city_id":"31547","city_name":"Bhan","state_id":"2729"},
    {"city_id":"31548","city_name":"Bhiria City","state_id":"2729"},
    {"city_id":"31549","city_name":"Bhiria Road","state_id":"2729"},
    {"city_id":"31550","city_name":"Bhit Shah","state_id":"2729"},
    {"city_id":"31551","city_name":"Bozdar","state_id":"2729"},
    {"city_id":"31552","city_name":"Bulri","state_id":"2729"},
    {"city_id":"31553","city_name":"Chak","state_id":"2729"},
    {"city_id":"31554","city_name":"Chambar","state_id":"2729"},
    {"city_id":"31555","city_name":"Chohar Jamali","state_id":"2729"},
    {"city_id":"31556","city_name":"Chor","state_id":"2729"},
    {"city_id":"31557","city_name":"Dadu","state_id":"2729"},
    {"city_id":"31558","city_name":"Daharki","state_id":"2729"},
    {"city_id":"31559","city_name":"Daro","state_id":"2729"},
    {"city_id":"31560","city_name":"Darya Khan Mari","state_id":"2729"},
    {"city_id":"31561","city_name":"Daulatpur","state_id":"2729"},
    {"city_id":"31562","city_name":"Daur","state_id":"2729"},
    {"city_id":"31563","city_name":"Dhoronaro","state_id":"2729"},
    {"city_id":"31564","city_name":"Digri","state_id":"2729"},
    {"city_id":"31565","city_name":"Diplo","state_id":"2729"},
    {"city_id":"31566","city_name":"Dokri","state_id":"2729"},
    {"city_id":"31567","city_name":"Faqirabad","state_id":"2729"},
    {"city_id":"31568","city_name":"Gambat","state_id":"2729"},
    {"city_id":"31569","city_name":"Garello","state_id":"2729"},
    {"city_id":"31570","city_name":"Garhi Khairo","state_id":"2729"},
    {"city_id":"31571","city_name":"Garhi Yasin","state_id":"2729"},
    {"city_id":"31572","city_name":"Gharo","state_id":"2729"},
    {"city_id":"31573","city_name":"Ghauspur","state_id":"2729"},
    {"city_id":"31574","city_name":"Ghotki","state_id":"2729"},
    {"city_id":"31575","city_name":"Golarchi","state_id":"2729"},
    {"city_id":"31576","city_name":"Guddu","state_id":"2729"},
    {"city_id":"31577","city_name":"Gulistan-E-Jauhar","state_id":"2729"},
    {"city_id":"31578","city_name":"Hala","state_id":"2729"},
    {"city_id":"31579","city_name":"Hingorja","state_id":"2729"},
    {"city_id":"31580","city_name":"Hyderabad","state_id":"2729"},
    {"city_id":"31581","city_name":"Islamkot","state_id":"2729"},
    {"city_id":"31582","city_name":"Jacobabad","state_id":"2729"},
    {"city_id":"31583","city_name":"Jam Nawaz Ali","state_id":"2729"},
    {"city_id":"31584","city_name":"Jam Sahib","state_id":"2729"},
    {"city_id":"31585","city_name":"Jati","state_id":"2729"},
    {"city_id":"31586","city_name":"Jhol","state_id":"2729"},
    {"city_id":"31587","city_name":"Jhudo","state_id":"2729"},
    {"city_id":"31588","city_name":"Johi","state_id":"2729"},
    {"city_id":"31589","city_name":"Kadhan","state_id":"2729"},
    {"city_id":"31590","city_name":"Kambar","state_id":"2729"},
    {"city_id":"31591","city_name":"Kandhra","state_id":"2729"},
    {"city_id":"31592","city_name":"Kandiari","state_id":"2729"},
    {"city_id":"31593","city_name":"Kandiaro","state_id":"2729"},
    {"city_id":"31594","city_name":"Karachi","state_id":"2729"},
    {"city_id":"31595","city_name":"Karampur","state_id":"2729"},
    {"city_id":"31596","city_name":"Kario Ghanwar","state_id":"2729"},
    {"city_id":"31597","city_name":"Karoondi","state_id":"2729"},
    {"city_id":"31598","city_name":"Kashmor","state_id":"2729"},
    {"city_id":"31599","city_name":"Kazi Ahmad","state_id":"2729"},
    {"city_id":"31600","city_name":"Keti Bandar","state_id":"2729"},
    {"city_id":"31601","city_name":"Khadro","state_id":"2729"},
    {"city_id":"31602","city_name":"Khairpur","state_id":"2729"},
    {"city_id":"31603","city_name":"Khairpur Nathan Shah","state_id":"2729"},
    {"city_id":"31604","city_name":"Khandh Kot","state_id":"2729"},
    {"city_id":"31605","city_name":"Khanpur","state_id":"2729"},
    {"city_id":"31606","city_name":"Khipro","state_id":"2729"},
    {"city_id":"31607","city_name":"Khoski","state_id":"2729"},
    {"city_id":"31608","city_name":"Khuhra","state_id":"2729"},
    {"city_id":"31609","city_name":"Khyber","state_id":"2729"},
    {"city_id":"31610","city_name":"Kot Diji","state_id":"2729"},
    {"city_id":"31611","city_name":"Kot Ghulam Mohammad","state_id":"2729"},
    {"city_id":"31612","city_name":"Kotri","state_id":"2729"},
    {"city_id":"31613","city_name":"Kumb","state_id":"2729"},
    {"city_id":"31614","city_name":"Kunri","state_id":"2729"},
    {"city_id":"31615","city_name":"Lakhi","state_id":"2729"},
    {"city_id":"31616","city_name":"Larkana","state_id":"2729"},
    {"city_id":"31617","city_name":"Madeji","state_id":"2729"},
    {"city_id":"31618","city_name":"Matiari","state_id":"2729"},
    {"city_id":"31619","city_name":"Matli","state_id":"2729"},
    {"city_id":"31620","city_name":"Mehar","state_id":"2729"},
    {"city_id":"31621","city_name":"Mehrabpur","state_id":"2729"},
    {"city_id":"31622","city_name":"Miro Khan","state_id":"2729"},
    {"city_id":"31623","city_name":"Mirpur Bathoro","state_id":"2729"},
    {"city_id":"31624","city_name":"Mirpur Khas","state_id":"2729"},
    {"city_id":"31625","city_name":"Mirpur Mathelo","state_id":"2729"},
    {"city_id":"31626","city_name":"Mirpur Sakro","state_id":"2729"},
    {"city_id":"31627","city_name":"Mirwah","state_id":"2729"},
    {"city_id":"31628","city_name":"Mithi","state_id":"2729"},
    {"city_id":"31629","city_name":"Moro","state_id":"2729"},
    {"city_id":"31630","city_name":"Nabisar","state_id":"2729"},
    {"city_id":"31631","city_name":"Nasarpur","state_id":"2729"},
    {"city_id":"31632","city_name":"Nasirabad","state_id":"2729"},
    {"city_id":"31633","city_name":"Naudero","state_id":"2729"},
    {"city_id":"31634","city_name":"Naukot","state_id":"2729"},
    {"city_id":"31635","city_name":"Naushahro Firoz","state_id":"2729"},
    {"city_id":"31636","city_name":"Nawabshah","state_id":"2729"},
    {"city_id":"31637","city_name":"Oderolal Station","state_id":"2729"},
    {"city_id":"31638","city_name":"Pacca Chang","state_id":"2729"},
    {"city_id":"31639","city_name":"Padidan","state_id":"2729"},
    {"city_id":"31640","city_name":"Pano Aqil","state_id":"2729"},
    {"city_id":"31641","city_name":"Perumal","state_id":"2729"},
    {"city_id":"31642","city_name":"Phulji","state_id":"2729"},
    {"city_id":"31643","city_name":"Pirjo Goth","state_id":"2729"},
    {"city_id":"31644","city_name":"Piryaloi","state_id":"2729"},
    {"city_id":"31645","city_name":"Pithoro","state_id":"2729"},
    {"city_id":"31646","city_name":"Radhan","state_id":"2729"},
    {"city_id":"31647","city_name":"Rajo Khanani","state_id":"2729"},
    {"city_id":"31648","city_name":"Ranipur","state_id":"2729"},
    {"city_id":"31649","city_name":"Ratodero","state_id":"2729"},
    {"city_id":"31650","city_name":"Rohri","state_id":"2729"},
    {"city_id":"31651","city_name":"Rustam","state_id":"2729"},
    {"city_id":"31652","city_name":"Saeedabad","state_id":"2729"},
    {"city_id":"31653","city_name":"Sakrand","state_id":"2729"},
    {"city_id":"31654","city_name":"Samaro","state_id":"2729"},
    {"city_id":"31655","city_name":"Sanghar","state_id":"2729"},
    {"city_id":"31656","city_name":"Sann","state_id":"2729"},
    {"city_id":"31657","city_name":"Sarhari","state_id":"2729"},
    {"city_id":"31658","city_name":"Sehwan","state_id":"2729"},
    {"city_id":"31659","city_name":"Setharja","state_id":"2729"},
    {"city_id":"31660","city_name":"Shah Dipalli","state_id":"2729"},
    {"city_id":"31661","city_name":"Shahdadkot","state_id":"2729"},
    {"city_id":"31662","city_name":"Shahdadpur","state_id":"2729"},
    {"city_id":"31663","city_name":"Shahpur Chakar","state_id":"2729"},
    {"city_id":"31664","city_name":"Shahpur Jahania","state_id":"2729"},
    {"city_id":"31665","city_name":"Shikarpur","state_id":"2729"},
    {"city_id":"31666","city_name":"Sinjhoro","state_id":"2729"},
    {"city_id":"31667","city_name":"Sita Road","state_id":"2729"},
    {"city_id":"31668","city_name":"Sobhodero","state_id":"2729"},
    {"city_id":"31669","city_name":"Sujawal","state_id":"2729"},
    {"city_id":"31670","city_name":"Sukkur","state_id":"2729"},
    {"city_id":"31671","city_name":"Talhar","state_id":"2729"},
    {"city_id":"31672","city_name":"Tando Adam","state_id":"2729"},
    {"city_id":"31673","city_name":"Tando Allah Yar","state_id":"2729"},
    {"city_id":"31674","city_name":"Tando Bagho","state_id":"2729"},
    {"city_id":"31675","city_name":"Tando Ghulam Ali","state_id":"2729"},
    {"city_id":"31676","city_name":"Tando Jam","state_id":"2729"},
    {"city_id":"31677","city_name":"Tando Jan Mohammad","state_id":"2729"},
    {"city_id":"31678","city_name":"Tando Mitha Khan","state_id":"2729"},
    {"city_id":"31679","city_name":"Tando Muhammad Khan","state_id":"2729"},
    {"city_id":"31680","city_name":"Tangwani","state_id":"2729"},
    {"city_id":"31681","city_name":"Thano Bula Khan","state_id":"2729"},
    {"city_id":"31682","city_name":"Thari Mirwah","state_id":"2729"},
    {"city_id":"31683","city_name":"Tharushah","state_id":"2729"},
    {"city_id":"31684","city_name":"Thatta","state_id":"2729"},
    {"city_id":"31685","city_name":"Ther I","state_id":"2729"},
    {"city_id":"31686","city_name":"Ther I Mohabat","state_id":"2729"},
    {"city_id":"31687","city_name":"Thul","state_id":"2729"},
    {"city_id":"31688","city_name":"Ubauro","state_id":"2729"},
    {"city_id":"31689","city_name":"Umarkot","state_id":"2729"},
    {"city_id":"31690","city_name":"Warah","state_id":"2729"},
    {"city_id":"31691","city_name":"Ulimang","state_id":"2730"},
    {"city_id":"31692","city_name":"Airai","state_id":"2731"},
    {"city_id":"31693","city_name":"Ngaramash","state_id":"2732"},
    {"city_id":"31694","city_name":"Hatohobei","state_id":"2733"},
    {"city_id":"31695","city_name":"Kayangel","state_id":"2734"},
    {"city_id":"31696","city_name":"Koror","state_id":"2735"},
    {"city_id":"31697","city_name":"Meyungs","state_id":"2735"},
    {"city_id":"31698","city_name":"Melekeok","state_id":"2736"},
    {"city_id":"31699","city_name":"Ngermechau","state_id":"2737"},
    {"city_id":"31700","city_name":"Chol","state_id":"2738"},
    {"city_id":"31701","city_name":"Ollei","state_id":"2739"},
    {"city_id":"31702","city_name":"Oikul","state_id":"2740"},
    {"city_id":"31703","city_name":"Ngerkeai","state_id":"2741"},
    {"city_id":"31704","city_name":"Imeong","state_id":"2742"},
    {"city_id":"31705","city_name":"Ngetkip","state_id":"2743"},
    {"city_id":"31706","city_name":"Kloulklubed","state_id":"2744"},
    {"city_id":"31707","city_name":"Dongosaru","state_id":"2745"},
    {"city_id":"31708","city_name":"Ariha","state_id":"2746"},
    {"city_id":"31709","city_name":"Beit Jala","state_id":"2748"},
    {"city_id":"31710","city_name":"Dayr-al-Balah","state_id":"2749"},
    {"city_id":"31711","city_name":"al-Burayj","state_id":"2749"},
    {"city_id":"31712","city_name":"al-Insayrat","state_id":"2749"},
    {"city_id":"31713","city_name":"al-Maghazi","state_id":"2749"},
    {"city_id":"31714","city_name":"Ghazzah","state_id":"2750"},
    {"city_id":"31715","city_name":"Ghazzah","state_id":"2751"},
    {"city_id":"31716","city_name":"Janin","state_id":"2752"},
    {"city_id":"31717","city_name":"Qabatiyah","state_id":"2752"},
    {"city_id":"31718","city_name":"Nabulus","state_id":"2754"},
    {"city_id":"31719","city_name":"Qalqilyah","state_id":"2755"},
    {"city_id":"31720","city_name":"Rafah","state_id":"2756"},
    {"city_id":"31721","city_name":"Tall as-Sultan","state_id":"2756"},
    {"city_id":"31722","city_name":"Salfit","state_id":"2758"},
    {"city_id":"31723","city_name":"Tubas","state_id":"2759"},
    {"city_id":"31724","city_name":"Tulkarm","state_id":"2760"},
    {"city_id":"31725","city_name":"Dura","state_id":"2761"},
    {"city_id":"31726","city_name":"Halhul","state_id":"2761"},
    {"city_id":"31727","city_name":"Yattah","state_id":"2761"},
    {"city_id":"31728","city_name":"ad-Dahiriyah","state_id":"2761"},
    {"city_id":"31729","city_name":"al-Khalil","state_id":"2761"},
    {"city_id":"31730","city_name":"Ma'ale Adummim","state_id":"2762"},
    {"city_id":"31731","city_name":"al-Quds","state_id":"2762"},
    {"city_id":"31732","city_name":"Alanje","state_id":"2764"},
    {"city_id":"31733","city_name":"Bajo Boquete","state_id":"2764"},
    {"city_id":"31734","city_name":"Boqueron","state_id":"2764"},
    {"city_id":"31735","city_name":"Bugaba","state_id":"2764"},
    {"city_id":"31736","city_name":"David","state_id":"2764"},
    {"city_id":"31737","city_name":"Dolega","state_id":"2764"},
    {"city_id":"31738","city_name":"Gualaca","state_id":"2764"},
    {"city_id":"31739","city_name":"Horconcitos","state_id":"2764"},
    {"city_id":"31740","city_name":"Las Lajas","state_id":"2764"},
    {"city_id":"31741","city_name":"Puerto Armuelles","state_id":"2764"},
    {"city_id":"31742","city_name":"Remedios","state_id":"2764"},
    {"city_id":"31743","city_name":"Rio Sereno","state_id":"2764"},
    {"city_id":"31744","city_name":"Tole","state_id":"2764"},
    {"city_id":"31745","city_name":"Aguadulce","state_id":"2765"},
    {"city_id":"31746","city_name":"Anton","state_id":"2765"},
    {"city_id":"31747","city_name":"La Pintada","state_id":"2765"},
    {"city_id":"31748","city_name":"Nata","state_id":"2765"},
    {"city_id":"31749","city_name":"Ola","state_id":"2765"},
    {"city_id":"31750","city_name":"Penonome","state_id":"2765"},
    {"city_id":"31751","city_name":"Colon","state_id":"2766"},
    {"city_id":"31752","city_name":"Miguel de la Borda","state_id":"2766"},
    {"city_id":"31753","city_name":"Nuevo Chagres","state_id":"2766"},
    {"city_id":"31754","city_name":"Portobelo","state_id":"2766"},
    {"city_id":"31755","city_name":"Santa Isabel","state_id":"2766"},
    {"city_id":"31756","city_name":"El Real de Santa Maria","state_id":"2767"},
    {"city_id":"31757","city_name":"La Palma","state_id":"2767"},
    {"city_id":"31758","city_name":"Cirilo Guainora","state_id":"2768"},
    {"city_id":"31759","city_name":"Rio Sabalo","state_id":"2768"},
    {"city_id":"31760","city_name":"Chitre","state_id":"2769"},
    {"city_id":"31761","city_name":"Las Minas","state_id":"2769"},
    {"city_id":"31762","city_name":"Los Pozos","state_id":"2769"},
    {"city_id":"31763","city_name":"Ocu","state_id":"2769"},
    {"city_id":"31764","city_name":"Parita","state_id":"2769"},
    {"city_id":"31765","city_name":"Pese","state_id":"2769"},
    {"city_id":"31766","city_name":"Santa Maria","state_id":"2769"},
    {"city_id":"31767","city_name":"Arraijan","state_id":"2773"},
    {"city_id":"31768","city_name":"Capira","state_id":"2773"},
    {"city_id":"31769","city_name":"Chame","state_id":"2773"},
    {"city_id":"31770","city_name":"Chepo","state_id":"2773"},
    {"city_id":"31771","city_name":"Chiman","state_id":"2773"},
    {"city_id":"31772","city_name":"La Chorrera","state_id":"2773"},
    {"city_id":"31773","city_name":"Panama","state_id":"2773"},
    {"city_id":"31774","city_name":"San Carlos","state_id":"2773"},
    {"city_id":"31775","city_name":"San Miguel","state_id":"2773"},
    {"city_id":"31776","city_name":"San Miguelito","state_id":"2773"},
    {"city_id":"31777","city_name":"Taboga","state_id":"2773"},
    {"city_id":"31778","city_name":"Atalaya","state_id":"2774"},
    {"city_id":"31779","city_name":"Calobre","state_id":"2774"},
    {"city_id":"31780","city_name":"Canazas","state_id":"2774"},
    {"city_id":"31781","city_name":"La Mesa","state_id":"2774"},
    {"city_id":"31782","city_name":"Las Palmas","state_id":"2774"},
    {"city_id":"31783","city_name":"Montijo","state_id":"2774"},
    {"city_id":"31784","city_name":"Rio de Jesus","state_id":"2774"},
    {"city_id":"31785","city_name":"San Francisco","state_id":"2774"},
    {"city_id":"31786","city_name":"Santa Fe","state_id":"2774"},
    {"city_id":"31787","city_name":"Santiago","state_id":"2774"},
    {"city_id":"31788","city_name":"Sona","state_id":"2774"},
    {"city_id":"31789","city_name":"Laiagam","state_id":"2778"},
    {"city_id":"31790","city_name":"Porgera","state_id":"2778"},
    {"city_id":"31791","city_name":"Wabag","state_id":"2778"},
    {"city_id":"31792","city_name":"Kerema","state_id":"2780"},
    {"city_id":"31793","city_name":"Finschhafen","state_id":"2781"},
    {"city_id":"31794","city_name":"Madang","state_id":"2781"},
    {"city_id":"31795","city_name":"Lorengau","state_id":"2782"},
    {"city_id":"31796","city_name":"Bulolo","state_id":"2784"},
    {"city_id":"31797","city_name":"Lae","state_id":"2784"},
    {"city_id":"31798","city_name":"Wau","state_id":"2784"},
    {"city_id":"31799","city_name":"Kokoda","state_id":"2788"},
    {"city_id":"31800","city_name":"Popondetta","state_id":"2788"},
    {"city_id":"31801","city_name":"Vanimo","state_id":"2789"},
    {"city_id":"31802","city_name":"Kundiawa","state_id":"2790"},
    {"city_id":"31803","city_name":"Bella Vista","state_id":"2796"},
    {"city_id":"31804","city_name":"Capitan Bado","state_id":"2796"},
    {"city_id":"31805","city_name":"Pedro Juan Caballero","state_id":"2796"},
    {"city_id":"31806","city_name":"Asuncion","state_id":"2797"},
    {"city_id":"31807","city_name":"Doctor Pedro P. Pena","state_id":"2798"},
    {"city_id":"31808","city_name":"Filadelfia","state_id":"2798"},
    {"city_id":"31809","city_name":"Marechal Estigarribia","state_id":"2798"},
    {"city_id":"31810","city_name":"Menno","state_id":"2798"},
    {"city_id":"31811","city_name":"Neuland","state_id":"2798"},
    {"city_id":"31812","city_name":"Caaguazu","state_id":"2799"},
    {"city_id":"31813","city_name":"Carayao","state_id":"2799"},
    {"city_id":"31814","city_name":"Coronel Oviedo","state_id":"2799"},
    {"city_id":"31815","city_name":"Doctor Cecilio Baez","state_id":"2799"},
    {"city_id":"31816","city_name":"Doctor Eulogio Estigarribia","state_id":"2799"},
    {"city_id":"31817","city_name":"Doctor Juan Manuel Frutos","state_id":"2799"},
    {"city_id":"31818","city_name":"Jose Ocampos","state_id":"2799"},
    {"city_id":"31819","city_name":"La Pastoria","state_id":"2799"},
    {"city_id":"31820","city_name":"Marechal Francisco Solano Lope","state_id":"2799"},
    {"city_id":"31821","city_name":"Mbutuy","state_id":"2799"},
    {"city_id":"31822","city_name":"Nueva Londres","state_id":"2799"},
    {"city_id":"31823","city_name":"Raul Arsenio Oviedo","state_id":"2799"},
    {"city_id":"31824","city_name":"Repatriacion","state_id":"2799"},
    {"city_id":"31825","city_name":"San Joaquin","state_id":"2799"},
    {"city_id":"31826","city_name":"San Jose de los Arroyos","state_id":"2799"},
    {"city_id":"31827","city_name":"Simon Bolivar","state_id":"2799"},
    {"city_id":"31828","city_name":"Tres Corrales","state_id":"2799"},
    {"city_id":"31829","city_name":"Tres de Febrero","state_id":"2799"},
    {"city_id":"31830","city_name":"Vaqueria","state_id":"2799"},
    {"city_id":"31831","city_name":"Yhu","state_id":"2799"},
    {"city_id":"31832","city_name":"Abai","state_id":"2800"},
    {"city_id":"31833","city_name":"Buena Vista","state_id":"2800"},
    {"city_id":"31834","city_name":"Caazapa","state_id":"2800"},
    {"city_id":"31835","city_name":"Doctor Moises Bertoni","state_id":"2800"},
    {"city_id":"31836","city_name":"General Higinio Morinigo","state_id":"2800"},
    {"city_id":"31837","city_name":"Maciel","state_id":"2800"},
    {"city_id":"31838","city_name":"San Juan Nepomuceno","state_id":"2800"},
    {"city_id":"31839","city_name":"Tavai","state_id":"2800"},
    {"city_id":"31840","city_name":"Yegros","state_id":"2800"},
    {"city_id":"31841","city_name":"Yuty","state_id":"2800"},
    {"city_id":"31842","city_name":"Corpus Christi","state_id":"2801"},
    {"city_id":"31843","city_name":"Curuguaty","state_id":"2801"},
    {"city_id":"31844","city_name":"General Francisco Alvarez","state_id":"2801"},
    {"city_id":"31845","city_name":"Itanara","state_id":"2801"},
    {"city_id":"31846","city_name":"Katuete","state_id":"2801"},
    {"city_id":"31847","city_name":"La Paloma","state_id":"2801"},
    {"city_id":"31848","city_name":"Nueva Esperanza","state_id":"2801"},
    {"city_id":"31849","city_name":"Salto del Guaira","state_id":"2801"},
    {"city_id":"31850","city_name":"Ygatimi","state_id":"2801"},
    {"city_id":"31851","city_name":"Ypehu","state_id":"2801"},
    {"city_id":"31852","city_name":"Aregua","state_id":"2802"},
    {"city_id":"31853","city_name":"Capiata","state_id":"2802"},
    {"city_id":"31854","city_name":"Fernando de la Mora","state_id":"2802"},
    {"city_id":"31855","city_name":"Guarambare","state_id":"2802"},
    {"city_id":"31856","city_name":"Ita","state_id":"2802"},
    {"city_id":"31857","city_name":"Itaugua","state_id":"2802"},
    {"city_id":"31858","city_name":"Juan Augusto Saldivar","state_id":"2802"},
    {"city_id":"31859","city_name":"Lambare","state_id":"2802"},
    {"city_id":"31860","city_name":"Limpio","state_id":"2802"},
    {"city_id":"31861","city_name":"Luque","state_id":"2802"},
    {"city_id":"31862","city_name":"Nemby","state_id":"2802"},
    {"city_id":"31863","city_name":"Nueva Italia","state_id":"2802"},
    {"city_id":"31864","city_name":"San Antonio","state_id":"2802"},
    {"city_id":"31865","city_name":"San Lorenzo","state_id":"2802"},
    {"city_id":"31866","city_name":"Villa Elisa","state_id":"2802"},
    {"city_id":"31867","city_name":"Villeta","state_id":"2802"},
    {"city_id":"31868","city_name":"Ypacarai","state_id":"2802"},
    {"city_id":"31869","city_name":"Ypane","state_id":"2802"},
    {"city_id":"31870","city_name":"Belen","state_id":"2803"},
    {"city_id":"31871","city_name":"Concepcion","state_id":"2803"},
    {"city_id":"31872","city_name":"Horqueta","state_id":"2803"},
    {"city_id":"31873","city_name":"Loreto","state_id":"2803"},
    {"city_id":"31874","city_name":"San Carlos","state_id":"2803"},
    {"city_id":"31875","city_name":"San Lazaro","state_id":"2803"},
    {"city_id":"31876","city_name":"Altos","state_id":"2804"},
    {"city_id":"31877","city_name":"Arroyos y Esteros","state_id":"2804"},
    {"city_id":"31878","city_name":"Atyra","state_id":"2804"},
    {"city_id":"31879","city_name":"Caacupe","state_id":"2804"},
    {"city_id":"31880","city_name":"Caraguatay","state_id":"2804"},
    {"city_id":"31881","city_name":"Emboscada","state_id":"2804"},
    {"city_id":"31882","city_name":"Eusebio Ayala","state_id":"2804"},
    {"city_id":"31883","city_name":"Isla Pucu","state_id":"2804"},
    {"city_id":"31884","city_name":"Itacurubi de la Cordillera","state_id":"2804"},
    {"city_id":"31885","city_name":"Juan de Mena","state_id":"2804"},
    {"city_id":"31886","city_name":"Loma Grande","state_id":"2804"},
    {"city_id":"31887","city_name":"Mbocayty del Yhaguy","state_id":"2804"},
    {"city_id":"31888","city_name":"Nueva Colombia","state_id":"2804"},
    {"city_id":"31889","city_name":"Piribebuy","state_id":"2804"},
    {"city_id":"31890","city_name":"Primero de Marzo","state_id":"2804"},
    {"city_id":"31891","city_name":"San Bernardino","state_id":"2804"},
    {"city_id":"31892","city_name":"San Jose Obrero","state_id":"2804"},
    {"city_id":"31893","city_name":"Santa Elena","state_id":"2804"},
    {"city_id":"31894","city_name":"Tobati","state_id":"2804"},
    {"city_id":"31895","city_name":"Valenzuela","state_id":"2804"},
    {"city_id":"31896","city_name":"Borja","state_id":"2805"},
    {"city_id":"31897","city_name":"Capitan Mauricio Jose Troche","state_id":"2805"},
    {"city_id":"31898","city_name":"Coronel Martinez","state_id":"2805"},
    {"city_id":"31899","city_name":"Doctor Botrell","state_id":"2805"},
    {"city_id":"31900","city_name":"Felix Perez Cardozo","state_id":"2805"},
    {"city_id":"31901","city_name":"General Eugenio Alejandrino Ga","state_id":"2805"},
    {"city_id":"31902","city_name":"Independencia","state_id":"2805"},
    {"city_id":"31903","city_name":"Itape","state_id":"2805"},
    {"city_id":"31904","city_name":"Iturbe","state_id":"2805"},
    {"city_id":"31905","city_name":"Jose Fasardi","state_id":"2805"},
    {"city_id":"31906","city_name":"Mbocayaty","state_id":"2805"},
    {"city_id":"31907","city_name":"Natalicio Talavera","state_id":"2805"},
    {"city_id":"31908","city_name":"Numi","state_id":"2805"},
    {"city_id":"31909","city_name":"Paso Yobai","state_id":"2805"},
    {"city_id":"31910","city_name":"San Salvador","state_id":"2805"},
    {"city_id":"31911","city_name":"Villarrica","state_id":"2805"},
    {"city_id":"31912","city_name":"Yataity","state_id":"2805"},
    {"city_id":"31913","city_name":"Alto Vera","state_id":"2806"},
    {"city_id":"31914","city_name":"Bella Vista","state_id":"2806"},
    {"city_id":"31915","city_name":"Cambyreta","state_id":"2806"},
    {"city_id":"31916","city_name":"Capitan Meza","state_id":"2806"},
    {"city_id":"31917","city_name":"Capitan Miranda","state_id":"2806"},
    {"city_id":"31918","city_name":"Carlos Antonio Lopez","state_id":"2806"},
    {"city_id":"31919","city_name":"Carmen del Parana","state_id":"2806"},
    {"city_id":"31920","city_name":"Coronel Bogado","state_id":"2806"},
    {"city_id":"31921","city_name":"Edelira","state_id":"2806"},
    {"city_id":"31922","city_name":"Encarnacion","state_id":"2806"},
    {"city_id":"31923","city_name":"Fram","state_id":"2806"},
    {"city_id":"31924","city_name":"General Artigas","state_id":"2806"},
    {"city_id":"31925","city_name":"General Delgado","state_id":"2806"},
    {"city_id":"31926","city_name":"Hohenau","state_id":"2806"},
    {"city_id":"31927","city_name":"Itapua Poty","state_id":"2806"},
    {"city_id":"31928","city_name":"Jesus","state_id":"2806"},
    {"city_id":"31929","city_name":"La Paz","state_id":"2806"},
    {"city_id":"31930","city_name":"Leandro Oviedo","state_id":"2806"},
    {"city_id":"31931","city_name":"Mayor Otano","state_id":"2806"},
    {"city_id":"31932","city_name":"Natalio","state_id":"2806"},
    {"city_id":"31933","city_name":"Nueva Alborada","state_id":"2806"},
    {"city_id":"31934","city_name":"Obligado","state_id":"2806"},
    {"city_id":"31935","city_name":"Pirapo","state_id":"2806"},
    {"city_id":"31936","city_name":"San Cosme y Damian","state_id":"2806"},
    {"city_id":"31937","city_name":"San Juan del Parana","state_id":"2806"},
    {"city_id":"31938","city_name":"San Pedro del Parana","state_id":"2806"},
    {"city_id":"31939","city_name":"San Rafael del Parana","state_id":"2806"},
    {"city_id":"31940","city_name":"Tomas Romero Pereira","state_id":"2806"},
    {"city_id":"31941","city_name":"Trinidad","state_id":"2806"},
    {"city_id":"31942","city_name":"Yatytay","state_id":"2806"},
    {"city_id":"31943","city_name":"Ayolas","state_id":"2807"},
    {"city_id":"31944","city_name":"San Ignacio","state_id":"2807"},
    {"city_id":"31945","city_name":"San Juan Bautista","state_id":"2807"},
    {"city_id":"31946","city_name":"San Miguel","state_id":"2807"},
    {"city_id":"31947","city_name":"San Patricio","state_id":"2807"},
    {"city_id":"31948","city_name":"Santa Maria","state_id":"2807"},
    {"city_id":"31949","city_name":"Santa Rosa","state_id":"2807"},
    {"city_id":"31950","city_name":"Santiago","state_id":"2807"},
    {"city_id":"31951","city_name":"Villa Florida","state_id":"2807"},
    {"city_id":"31952","city_name":"Yabebyry","state_id":"2807"},
    {"city_id":"31953","city_name":"Alberdi","state_id":"2808"},
    {"city_id":"31954","city_name":"Cerrito","state_id":"2808"},
    {"city_id":"31955","city_name":"Desmochados","state_id":"2808"},
    {"city_id":"31956","city_name":"General Jose Eduvigis Diaz","state_id":"2808"},
    {"city_id":"31957","city_name":"Guazu Cua","state_id":"2808"},
    {"city_id":"31958","city_name":"Humaita","state_id":"2808"},
    {"city_id":"31959","city_name":"Isla Umbu","state_id":"2808"},
    {"city_id":"31960","city_name":"Laureles","state_id":"2808"},
    {"city_id":"31961","city_name":"Mayor Jose Dejesus Martinez","state_id":"2808"},
    {"city_id":"31962","city_name":"Paso de Patria","state_id":"2808"},
    {"city_id":"31963","city_name":"Pilar","state_id":"2808"},
    {"city_id":"31964","city_name":"San Juan Bautista de Neembucu","state_id":"2808"},
    {"city_id":"31965","city_name":"Tacuaras","state_id":"2808"},
    {"city_id":"31966","city_name":"Villa Franca","state_id":"2808"},
    {"city_id":"31967","city_name":"Villa Oliva","state_id":"2808"},
    {"city_id":"31968","city_name":"Villalbin","state_id":"2808"},
    {"city_id":"31969","city_name":"Acahay","state_id":"2809"},
    {"city_id":"31970","city_name":"Caapucu","state_id":"2809"},
    {"city_id":"31971","city_name":"Carapegua","state_id":"2809"},
    {"city_id":"31972","city_name":"Escobar","state_id":"2809"},
    {"city_id":"31973","city_name":"General Bernardino Caballero","state_id":"2809"},
    {"city_id":"31974","city_name":"La Colmena","state_id":"2809"},
    {"city_id":"31975","city_name":"Mbuyapey","state_id":"2809"},
    {"city_id":"31976","city_name":"Paraguari","state_id":"2809"},
    {"city_id":"31977","city_name":"Pirayu","state_id":"2809"},
    {"city_id":"31978","city_name":"Quiindy","state_id":"2809"},
    {"city_id":"31979","city_name":"Quyquyho","state_id":"2809"},
    {"city_id":"31980","city_name":"San Roque Gonzalez de Santa Cr","state_id":"2809"},
    {"city_id":"31981","city_name":"Sapucai","state_id":"2809"},
    {"city_id":"31982","city_name":"Tebicuarymi","state_id":"2809"},
    {"city_id":"31983","city_name":"Yaguaron","state_id":"2809"},
    {"city_id":"31984","city_name":"Ybycui","state_id":"2809"},
    {"city_id":"31985","city_name":"Ybytimi","state_id":"2809"},
    {"city_id":"31986","city_name":"Bagua Grande","state_id":"2812"},
    {"city_id":"31987","city_name":"Cajaruro","state_id":"2812"},
    {"city_id":"31988","city_name":"Chachapoyas","state_id":"2812"},
    {"city_id":"31989","city_name":"Jazan","state_id":"2812"},
    {"city_id":"31990","city_name":"La Peca","state_id":"2812"},
    {"city_id":"31991","city_name":"Ancash","state_id":"2813"},
    {"city_id":"31992","city_name":"Caraz","state_id":"2813"},
    {"city_id":"31993","city_name":"Carhuaz","state_id":"2813"},
    {"city_id":"31994","city_name":"Casma","state_id":"2813"},
    {"city_id":"31995","city_name":"Chimbote","state_id":"2813"},
    {"city_id":"31996","city_name":"Choishco","state_id":"2813"},
    {"city_id":"31997","city_name":"Huallanca","state_id":"2813"},
    {"city_id":"31998","city_name":"Huaraz","state_id":"2813"},
    {"city_id":"31999","city_name":"Huari","state_id":"2813"},
    {"city_id":"32000","city_name":"Huarmey","state_id":"2813"},
    {"city_id":"32001","city_name":"Pomabamba","state_id":"2813"},
    {"city_id":"32002","city_name":"Santa","state_id":"2813"},
    {"city_id":"32003","city_name":"Yungay","state_id":"2813"},
    {"city_id":"32004","city_name":"Abancay","state_id":"2814"},
    {"city_id":"32005","city_name":"Andahuaylas","state_id":"2814"},
    {"city_id":"32006","city_name":"San Jeronimo","state_id":"2814"},
    {"city_id":"32007","city_name":"Talavera","state_id":"2814"},
    {"city_id":"32008","city_name":"Acari","state_id":"2815"},
    {"city_id":"32009","city_name":"Arequipa","state_id":"2815"},
    {"city_id":"32010","city_name":"Camana","state_id":"2815"},
    {"city_id":"32011","city_name":"Chivay","state_id":"2815"},
    {"city_id":"32012","city_name":"Cocachacra","state_id":"2815"},
    {"city_id":"32013","city_name":"Dean Valdivia","state_id":"2815"},
    {"city_id":"32014","city_name":"Lluta","state_id":"2815"},
    {"city_id":"32015","city_name":"Mollendo","state_id":"2815"},
    {"city_id":"32016","city_name":"Nicolas de Pierola","state_id":"2815"},
    {"city_id":"32017","city_name":"Orcopampa","state_id":"2815"},
    {"city_id":"32018","city_name":"Punta de Bombon","state_id":"2815"},
    {"city_id":"32019","city_name":"Rio Grande","state_id":"2815"},
    {"city_id":"32020","city_name":"Yura","state_id":"2815"},
    {"city_id":"32021","city_name":"Ayacucho","state_id":"2816"},
    {"city_id":"32022","city_name":"Ayna","state_id":"2816"},
    {"city_id":"32023","city_name":"Coracora","state_id":"2816"},
    {"city_id":"32024","city_name":"Huanta","state_id":"2816"},
    {"city_id":"32025","city_name":"Puquio","state_id":"2816"},
    {"city_id":"32026","city_name":"San Miguel","state_id":"2816"},
    {"city_id":"32027","city_name":"Santa Rosa","state_id":"2816"},
    {"city_id":"32028","city_name":"Silvia","state_id":"2816"},
    {"city_id":"32029","city_name":"Tambo","state_id":"2816"},
    {"city_id":"32030","city_name":"Bambamarca","state_id":"2817"},
    {"city_id":"32031","city_name":"Bellavista","state_id":"2817"},
    {"city_id":"32032","city_name":"Cajabamba","state_id":"2817"},
    {"city_id":"32033","city_name":"Cajamarca","state_id":"2817"},
    {"city_id":"32034","city_name":"Celendin","state_id":"2817"},
    {"city_id":"32035","city_name":"Chota","state_id":"2817"},
    {"city_id":"32036","city_name":"Cutervo","state_id":"2817"},
    {"city_id":"32037","city_name":"Jaen","state_id":"2817"},
    {"city_id":"32038","city_name":"Pedro Galvez","state_id":"2817"},
    {"city_id":"32039","city_name":"Pucara","state_id":"2817"},
    {"city_id":"32040","city_name":"San Ignacio","state_id":"2817"},
    {"city_id":"32041","city_name":"Santa Cruz","state_id":"2817"},
    {"city_id":"32042","city_name":"Yonan","state_id":"2817"},
    {"city_id":"32043","city_name":"Anta","state_id":"2818"},
    {"city_id":"32044","city_name":"Calca","state_id":"2818"},
    {"city_id":"32045","city_name":"Cusco","state_id":"2818"},
    {"city_id":"32046","city_name":"Espinar","state_id":"2818"},
    {"city_id":"32047","city_name":"Oropesa","state_id":"2818"},
    {"city_id":"32048","city_name":"Quillabamba","state_id":"2818"},
    {"city_id":"32049","city_name":"Santa Ana","state_id":"2818"},
    {"city_id":"32050","city_name":"Santo Tomas","state_id":"2818"},
    {"city_id":"32051","city_name":"Sicuani","state_id":"2818"},
    {"city_id":"32052","city_name":"Urcos","state_id":"2818"},
    {"city_id":"32053","city_name":"Urubamba","state_id":"2818"},
    {"city_id":"32054","city_name":"Huancavelica","state_id":"2819"},
    {"city_id":"32055","city_name":"Lircay","state_id":"2819"},
    {"city_id":"32056","city_name":"Pampas","state_id":"2819"},
    {"city_id":"32057","city_name":"Ambos","state_id":"2820"},
    {"city_id":"32058","city_name":"Huanuco","state_id":"2820"},
    {"city_id":"32059","city_name":"Jose Crespo y Castillo","state_id":"2820"},
    {"city_id":"32060","city_name":"La Union","state_id":"2820"},
    {"city_id":"32061","city_name":"Llata","state_id":"2820"},
    {"city_id":"32062","city_name":"Rupa-Rupa","state_id":"2820"},
    {"city_id":"32063","city_name":"San Miguel de Cauri","state_id":"2820"},
    {"city_id":"32064","city_name":"Tingo Maria","state_id":"2820"},
    {"city_id":"32065","city_name":"Chincha Alta","state_id":"2821"},
    {"city_id":"32066","city_name":"Ica","state_id":"2821"},
    {"city_id":"32067","city_name":"Los Aquijes","state_id":"2821"},
    {"city_id":"32068","city_name":"Marcona","state_id":"2821"},
    {"city_id":"32069","city_name":"Nazca","state_id":"2821"},
    {"city_id":"32070","city_name":"Pachacutec","state_id":"2821"},
    {"city_id":"32071","city_name":"Palpa","state_id":"2821"},
    {"city_id":"32072","city_name":"Pisco","state_id":"2821"},
    {"city_id":"32073","city_name":"Salas","state_id":"2821"},
    {"city_id":"32074","city_name":"San Andres","state_id":"2821"},
    {"city_id":"32075","city_name":"San Clemente","state_id":"2821"},
    {"city_id":"32076","city_name":"San Juan Bautista","state_id":"2821"},
    {"city_id":"32077","city_name":"Santiago","state_id":"2821"},
    {"city_id":"32078","city_name":"Subtanjalla","state_id":"2821"},
    {"city_id":"32079","city_name":"Tupac Amaru Inca","state_id":"2821"},
    {"city_id":"32080","city_name":"Vista Alegre","state_id":"2821"},
    {"city_id":"32081","city_name":"Acobamba","state_id":"2822"},
    {"city_id":"32082","city_name":"Acolla","state_id":"2822"},
    {"city_id":"32083","city_name":"Carhuamayo","state_id":"2822"},
    {"city_id":"32084","city_name":"Chanchamayo","state_id":"2822"},
    {"city_id":"32085","city_name":"Chupaca","state_id":"2822"},
    {"city_id":"32086","city_name":"Concepcion","state_id":"2822"},
    {"city_id":"32087","city_name":"Huancayo","state_id":"2822"},
    {"city_id":"32088","city_name":"Huasahuasi","state_id":"2822"},
    {"city_id":"32089","city_name":"Huayucachi","state_id":"2822"},
    {"city_id":"32090","city_name":"Jauja","state_id":"2822"},
    {"city_id":"32091","city_name":"Junin","state_id":"2822"},
    {"city_id":"32092","city_name":"La Oroya","state_id":"2822"},
    {"city_id":"32093","city_name":"Mazamari","state_id":"2822"},
    {"city_id":"32094","city_name":"Morococha","state_id":"2822"},
    {"city_id":"32095","city_name":"Orcotuna","state_id":"2822"},
    {"city_id":"32096","city_name":"Pangoa","state_id":"2822"},
    {"city_id":"32097","city_name":"Perene","state_id":"2822"},
    {"city_id":"32098","city_name":"Pichanaqui","state_id":"2822"},
    {"city_id":"32099","city_name":"Pilcomayo","state_id":"2822"},
    {"city_id":"32100","city_name":"San Agustin","state_id":"2822"},
    {"city_id":"32101","city_name":"San Jeronimo de Tunan","state_id":"2822"},
    {"city_id":"32102","city_name":"San Pedro de Cajas","state_id":"2822"},
    {"city_id":"32103","city_name":"San Ramon","state_id":"2822"},
    {"city_id":"32104","city_name":"Santa Rosa de Saco","state_id":"2822"},
    {"city_id":"32105","city_name":"Satipo","state_id":"2822"},
    {"city_id":"32106","city_name":"Sicaya","state_id":"2822"},
    {"city_id":"32107","city_name":"Tarma","state_id":"2822"},
    {"city_id":"32108","city_name":"Yauli","state_id":"2822"},
    {"city_id":"32109","city_name":"Yauya","state_id":"2822"},
    {"city_id":"32110","city_name":"Chiclayo","state_id":"2824"},
    {"city_id":"32111","city_name":"Chongoyape","state_id":"2824"},
    {"city_id":"32112","city_name":"Eten","state_id":"2824"},
    {"city_id":"32113","city_name":"Ferrenafe","state_id":"2824"},
    {"city_id":"32114","city_name":"Illimo","state_id":"2824"},
    {"city_id":"32115","city_name":"Jayanca","state_id":"2824"},
    {"city_id":"32116","city_name":"Lagunas","state_id":"2824"},
    {"city_id":"32117","city_name":"Lambayeque","state_id":"2824"},
    {"city_id":"32118","city_name":"Manuel Mesones Muro","state_id":"2824"},
    {"city_id":"32119","city_name":"Mochumi","state_id":"2824"},
    {"city_id":"32120","city_name":"Monsefu","state_id":"2824"},
    {"city_id":"32121","city_name":"Morrope","state_id":"2824"},
    {"city_id":"32122","city_name":"Motupe","state_id":"2824"},
    {"city_id":"32123","city_name":"Olmos","state_id":"2824"},
    {"city_id":"32124","city_name":"Oyotun","state_id":"2824"},
    {"city_id":"32125","city_name":"Picsi","state_id":"2824"},
    {"city_id":"32126","city_name":"Pimentel","state_id":"2824"},
    {"city_id":"32127","city_name":"Pueblo Nuevo","state_id":"2824"},
    {"city_id":"32128","city_name":"Reque","state_id":"2824"},
    {"city_id":"32129","city_name":"San Jose","state_id":"2824"},
    {"city_id":"32130","city_name":"Sana","state_id":"2824"},
    {"city_id":"32131","city_name":"Santa Rosa","state_id":"2824"},
    {"city_id":"32132","city_name":"Tucume","state_id":"2824"},
    {"city_id":"32133","city_name":"Barranca","state_id":"2826"},
    {"city_id":"32134","city_name":"Contamana","state_id":"2826"},
    {"city_id":"32135","city_name":"Fernando Lores","state_id":"2826"},
    {"city_id":"32136","city_name":"Iquitos","state_id":"2826"},
    {"city_id":"32137","city_name":"Lagunas","state_id":"2826"},
    {"city_id":"32138","city_name":"Nauta","state_id":"2826"},
    {"city_id":"32139","city_name":"Paucarpata","state_id":"2826"},
    {"city_id":"32140","city_name":"Ramon Castilla","state_id":"2826"},
    {"city_id":"32141","city_name":"Requena","state_id":"2826"},
    {"city_id":"32142","city_name":"Saquena","state_id":"2826"},
    {"city_id":"32143","city_name":"Vargas Guerra","state_id":"2826"},
    {"city_id":"32144","city_name":"Yurimaguas","state_id":"2826"},
    {"city_id":"32145","city_name":"Ilo","state_id":"2828"},
    {"city_id":"32146","city_name":"Moquegua","state_id":"2828"},
    {"city_id":"32147","city_name":"Pacocha","state_id":"2828"},
    {"city_id":"32148","city_name":"Torata","state_id":"2828"},
    {"city_id":"32149","city_name":"Cerro de Pasco","state_id":"2829"},
    {"city_id":"32150","city_name":"Chaupimarca","state_id":"2829"},
    {"city_id":"32151","city_name":"Oxapampa","state_id":"2829"},
    {"city_id":"32152","city_name":"Paucartambo","state_id":"2829"},
    {"city_id":"32153","city_name":"Simon Bolivar","state_id":"2829"},
    {"city_id":"32154","city_name":"Tinyahuarco","state_id":"2829"},
    {"city_id":"32155","city_name":"Villa Rica","state_id":"2829"},
    {"city_id":"32156","city_name":"Yanacancha","state_id":"2829"},
    {"city_id":"32157","city_name":"Yanahuanca","state_id":"2829"},
    {"city_id":"32158","city_name":"Ayabaca","state_id":"2830"},
    {"city_id":"32159","city_name":"Bernal","state_id":"2830"},
    {"city_id":"32160","city_name":"Buenos Aires","state_id":"2830"},
    {"city_id":"32161","city_name":"Catacaos","state_id":"2830"},
    {"city_id":"32162","city_name":"Chulucanas","state_id":"2830"},
    {"city_id":"32163","city_name":"Colan","state_id":"2830"},
    {"city_id":"32164","city_name":"Cura Mori","state_id":"2830"},
    {"city_id":"32165","city_name":"El Alto","state_id":"2830"},
    {"city_id":"32166","city_name":"Huancabamba","state_id":"2830"},
    {"city_id":"32167","city_name":"Ignacio Escudero","state_id":"2830"},
    {"city_id":"32168","city_name":"La Arena","state_id":"2830"},
    {"city_id":"32169","city_name":"La Brea","state_id":"2830"},
    {"city_id":"32170","city_name":"La Huaca","state_id":"2830"},
    {"city_id":"32171","city_name":"La Matanza","state_id":"2830"},
    {"city_id":"32172","city_name":"La Union","state_id":"2830"},
    {"city_id":"32173","city_name":"Las Lomas","state_id":"2830"},
    {"city_id":"32174","city_name":"Los Organos","state_id":"2830"},
    {"city_id":"32175","city_name":"Mancora","state_id":"2830"},
    {"city_id":"32176","city_name":"Marcavelica","state_id":"2830"},
    {"city_id":"32177","city_name":"Morropon","state_id":"2830"},
    {"city_id":"32178","city_name":"Paita","state_id":"2830"},
    {"city_id":"32179","city_name":"Piura","state_id":"2830"},
    {"city_id":"32180","city_name":"Querecotillo","state_id":"2830"},
    {"city_id":"32181","city_name":"Salitral","state_id":"2830"},
    {"city_id":"32182","city_name":"San Juan de Bigote","state_id":"2830"},
    {"city_id":"32183","city_name":"Sechura","state_id":"2830"},
    {"city_id":"32184","city_name":"Sullana","state_id":"2830"},
    {"city_id":"32185","city_name":"Talara","state_id":"2830"},
    {"city_id":"32186","city_name":"Tamarindo","state_id":"2830"},
    {"city_id":"32187","city_name":"Tambo Grande","state_id":"2830"},
    {"city_id":"32188","city_name":"Vice","state_id":"2830"},
    {"city_id":"32189","city_name":"Vichayal","state_id":"2830"},
    {"city_id":"32190","city_name":"Ayaviri","state_id":"2831"},
    {"city_id":"32191","city_name":"Azangaro","state_id":"2831"},
    {"city_id":"32192","city_name":"Desaguadero","state_id":"2831"},
    {"city_id":"32193","city_name":"Huancane","state_id":"2831"},
    {"city_id":"32194","city_name":"Ilave","state_id":"2831"},
    {"city_id":"32195","city_name":"Juli","state_id":"2831"},
    {"city_id":"32196","city_name":"Juliaca","state_id":"2831"},
    {"city_id":"32197","city_name":"Lampa","state_id":"2831"},
    {"city_id":"32198","city_name":"Macusani","state_id":"2831"},
    {"city_id":"32199","city_name":"Nunoa","state_id":"2831"},
    {"city_id":"32200","city_name":"Puno","state_id":"2831"},
    {"city_id":"32201","city_name":"Putina","state_id":"2831"},
    {"city_id":"32202","city_name":"Santa Lucia","state_id":"2831"},
    {"city_id":"32203","city_name":"Yanahuara","state_id":"2831"},
    {"city_id":"32204","city_name":"Yunguyo","state_id":"2831"},
    {"city_id":"32205","city_name":"Ilabaya","state_id":"2833"},
    {"city_id":"32206","city_name":"Tacna","state_id":"2833"},
    {"city_id":"32207","city_name":"Tarata","state_id":"2833"},
    {"city_id":"32208","city_name":"Aguas Verdes","state_id":"2834"},
    {"city_id":"32209","city_name":"Corrales","state_id":"2834"},
    {"city_id":"32210","city_name":"La Cruz","state_id":"2834"},
    {"city_id":"32211","city_name":"Papayal","state_id":"2834"},
    {"city_id":"32212","city_name":"San Jacinto","state_id":"2834"},
    {"city_id":"32213","city_name":"Tumbes","state_id":"2834"},
    {"city_id":"32214","city_name":"Zarumilla","state_id":"2834"},
    {"city_id":"32215","city_name":"Zorritos","state_id":"2834"},
    {"city_id":"32216","city_name":"Campoverde","state_id":"2835"},
    {"city_id":"32217","city_name":"Padre Abad","state_id":"2835"},
    {"city_id":"32218","city_name":"Pucallpa","state_id":"2835"},
    {"city_id":"32219","city_name":"Raymondi","state_id":"2835"},
    {"city_id":"32220","city_name":"San Juan","state_id":"2836"},
    {"city_id":"32221","city_name":"Albay","state_id":"2837"},
    {"city_id":"32222","city_name":"Daet","state_id":"2837"},
    {"city_id":"32223","city_name":"Iriga","state_id":"2837"},
    {"city_id":"32224","city_name":"Legaspi","state_id":"2837"},
    {"city_id":"32225","city_name":"Naga","state_id":"2837"},
    {"city_id":"32226","city_name":"Sorsogon","state_id":"2837"},
    {"city_id":"32227","city_name":"Bulacan","state_id":"2838"},
    {"city_id":"32228","city_name":"Marilao","state_id":"2838"},
    {"city_id":"32229","city_name":"Cagayan de Oro","state_id":"2839"},
    {"city_id":"32230","city_name":"Santiago","state_id":"2839"},
    {"city_id":"32231","city_name":"Solano","state_id":"2839"},
    {"city_id":"32232","city_name":"Tuguegarao","state_id":"2839"},
    {"city_id":"32233","city_name":"Bislig","state_id":"2840"},
    {"city_id":"32234","city_name":"Butuan","state_id":"2840"},
    {"city_id":"32235","city_name":"Surigao","state_id":"2840"},
    {"city_id":"32236","city_name":"Baguio","state_id":"2844"},
    {"city_id":"32237","city_name":"Bangued","state_id":"2844"},
    {"city_id":"32238","city_name":"Davao","state_id":"2845"},
    {"city_id":"32239","city_name":"Dagupan","state_id":"2848"},
    {"city_id":"32240","city_name":"Laoag","state_id":"2848"},
    {"city_id":"32241","city_name":"Manaoag","state_id":"2848"},
    {"city_id":"32242","city_name":"Mangaldan","state_id":"2848"},
    {"city_id":"32243","city_name":"San Fernando","state_id":"2848"},
    {"city_id":"32244","city_name":"Urdaneta","state_id":"2848"},
    {"city_id":"32245","city_name":"Vigan","state_id":"2848"},
    {"city_id":"32246","city_name":"Binan","state_id":"2849"},
    {"city_id":"32247","city_name":"Laguna","state_id":"2849"},
    {"city_id":"32248","city_name":"Pangil","state_id":"2849"},
    {"city_id":"32249","city_name":"San Pedro","state_id":"2849"},
    {"city_id":"32250","city_name":"Manila","state_id":"2850"},
    {"city_id":"32251","city_name":"Marikina","state_id":"2850"},
    {"city_id":"32252","city_name":"Mactan","state_id":"2851"},
    {"city_id":"32253","city_name":"Fabryczna","state_id":"2861"},
    {"city_id":"32254","city_name":"Bielawa","state_id":"2862"},
    {"city_id":"32255","city_name":"Bogatynia","state_id":"2862"},
    {"city_id":"32256","city_name":"Boguszow-Gorce","state_id":"2862"},
    {"city_id":"32257","city_name":"Boleslawiec","state_id":"2862"},
    {"city_id":"32258","city_name":"Brzeg Dolny","state_id":"2862"},
    {"city_id":"32259","city_name":"Bystrzyca Klodzka","state_id":"2862"},
    {"city_id":"32260","city_name":"Chojnow","state_id":"2862"},
    {"city_id":"32261","city_name":"Dzierzoniow","state_id":"2862"},
    {"city_id":"32262","city_name":"Glogow","state_id":"2862"},
    {"city_id":"32263","city_name":"Gora","state_id":"2862"},
    {"city_id":"32264","city_name":"Jawor","state_id":"2862"},
    {"city_id":"32265","city_name":"Jelcz-Laskowice","state_id":"2862"},
    {"city_id":"32266","city_name":"Jelenia Gora","state_id":"2862"},
    {"city_id":"32267","city_name":"Kamienna Gora","state_id":"2862"},
    {"city_id":"32268","city_name":"Klodzko","state_id":"2862"},
    {"city_id":"32269","city_name":"Kowary","state_id":"2862"},
    {"city_id":"32270","city_name":"Kudowa-Zdroj","state_id":"2862"},
    {"city_id":"32271","city_name":"Legnica","state_id":"2862"},
    {"city_id":"32272","city_name":"Luban","state_id":"2862"},
    {"city_id":"32273","city_name":"Lubin","state_id":"2862"},
    {"city_id":"32274","city_name":"Lwowek Slaski","state_id":"2862"},
    {"city_id":"32275","city_name":"Milicz","state_id":"2862"},
    {"city_id":"32276","city_name":"Nowa Ruda","state_id":"2862"},
    {"city_id":"32277","city_name":"Olawa","state_id":"2862"},
    {"city_id":"32278","city_name":"Olesnica","state_id":"2862"},
    {"city_id":"32279","city_name":"Piechowice","state_id":"2862"},
    {"city_id":"32280","city_name":"Pieszyce","state_id":"2862"},
    {"city_id":"32281","city_name":"Polkowice","state_id":"2862"},
    {"city_id":"32282","city_name":"Strzegom","state_id":"2862"},
    {"city_id":"32283","city_name":"Strzelin","state_id":"2862"},
    {"city_id":"32284","city_name":"Swidnica","state_id":"2862"},
    {"city_id":"32285","city_name":"Swiebodzice","state_id":"2862"},
    {"city_id":"32286","city_name":"Sycow","state_id":"2862"},
    {"city_id":"32287","city_name":"Trzebnica","state_id":"2862"},
    {"city_id":"32288","city_name":"Walbrzych","state_id":"2862"},
    {"city_id":"32289","city_name":"Wolow","state_id":"2862"},
    {"city_id":"32290","city_name":"Wroclaw","state_id":"2862"},
    {"city_id":"32291","city_name":"Zabkowice Slaskie","state_id":"2862"},
    {"city_id":"32292","city_name":"Zgorzelec","state_id":"2862"},
    {"city_id":"32293","city_name":"Ziebice","state_id":"2862"},
    {"city_id":"32294","city_name":"Zlotoryja","state_id":"2862"},
    {"city_id":"32295","city_name":"Hopowo","state_id":"2864"},
    {"city_id":"32296","city_name":"Chwaszczyno","state_id":"2865"},
    {"city_id":"32297","city_name":"Smigiel","state_id":"2866"},
    {"city_id":"32298","city_name":"Jerzmanowice","state_id":"2867"},
    {"city_id":"32299","city_name":"Zabierzow","state_id":"2867"},
    {"city_id":"32300","city_name":"Aleksandrow Kujawski","state_id":"2868"},
    {"city_id":"32301","city_name":"Brodnica","state_id":"2868"},
    {"city_id":"32302","city_name":"Bydgoszcz","state_id":"2868"},
    {"city_id":"32303","city_name":"Chelmno","state_id":"2868"},
    {"city_id":"32304","city_name":"Chelmza","state_id":"2868"},
    {"city_id":"32305","city_name":"Ciechocinek","state_id":"2868"},
    {"city_id":"32306","city_name":"Golub-Dobrzyn","state_id":"2868"},
    {"city_id":"32307","city_name":"Grudziadz","state_id":"2868"},
    {"city_id":"32308","city_name":"Inowroclaw","state_id":"2868"},
    {"city_id":"32309","city_name":"Janikowo","state_id":"2868"},
    {"city_id":"32310","city_name":"Koronowo","state_id":"2868"},
    {"city_id":"32311","city_name":"Kruszwica","state_id":"2868"},
    {"city_id":"32312","city_name":"Lipno","state_id":"2868"},
    {"city_id":"32313","city_name":"Mogilno","state_id":"2868"},
    {"city_id":"32314","city_name":"Naklo nad Notecia","state_id":"2868"},
    {"city_id":"32315","city_name":"Radziejow","state_id":"2868"},
    {"city_id":"32316","city_name":"Rypin","state_id":"2868"},
    {"city_id":"32317","city_name":"Sepolno Krajenskie","state_id":"2868"},
    {"city_id":"32318","city_name":"Solec Kujawski","state_id":"2868"},
    {"city_id":"32319","city_name":"Swiecie","state_id":"2868"},
    {"city_id":"32320","city_name":"Szubin","state_id":"2868"},
    {"city_id":"32321","city_name":"Torun","state_id":"2868"},
    {"city_id":"32322","city_name":"Tuchola","state_id":"2868"},
    {"city_id":"32323","city_name":"Wabrzezno","state_id":"2868"},
    {"city_id":"32324","city_name":"Wloclawek","state_id":"2868"},
    {"city_id":"32325","city_name":"Znin","state_id":"2868"},
    {"city_id":"32326","city_name":"Aleksandrow Lodzki","state_id":"2869"},
    {"city_id":"32327","city_name":"Belchatow","state_id":"2869"},
    {"city_id":"32328","city_name":"Bratoszewice","state_id":"2869"},
    {"city_id":"32329","city_name":"Brzeziny","state_id":"2869"},
    {"city_id":"32330","city_name":"Glowno","state_id":"2869"},
    {"city_id":"32331","city_name":"Koluszki","state_id":"2869"},
    {"city_id":"32332","city_name":"Konstantynow Lodzki","state_id":"2869"},
    {"city_id":"32333","city_name":"Kutno","state_id":"2869"},
    {"city_id":"32334","city_name":"Lask","state_id":"2869"},
    {"city_id":"32335","city_name":"Leczyca","state_id":"2869"},
    {"city_id":"32336","city_name":"Lodz","state_id":"2869"},
    {"city_id":"32337","city_name":"Lowicz","state_id":"2869"},
    {"city_id":"32338","city_name":"Opoczno","state_id":"2869"},
    {"city_id":"32339","city_name":"Ozorkow","state_id":"2869"},
    {"city_id":"32340","city_name":"Pabianice","state_id":"2869"},
    {"city_id":"32341","city_name":"Piotrkow Trybunalski","state_id":"2869"},
    {"city_id":"32342","city_name":"Radomsko","state_id":"2869"},
    {"city_id":"32343","city_name":"Rawa Mazowiecka","state_id":"2869"},
    {"city_id":"32344","city_name":"Sieradz","state_id":"2869"},
    {"city_id":"32345","city_name":"Skierniewice","state_id":"2869"},
    {"city_id":"32346","city_name":"Tomaszow Mazowiecki","state_id":"2869"},
    {"city_id":"32347","city_name":"Tuszyn","state_id":"2869"},
    {"city_id":"32348","city_name":"Wielun","state_id":"2869"},
    {"city_id":"32349","city_name":"Zdunska Wola","state_id":"2869"},
    {"city_id":"32350","city_name":"Zgierz","state_id":"2869"},
    {"city_id":"32351","city_name":"Zychlin","state_id":"2869"},
    {"city_id":"32352","city_name":"Biala Podlaska","state_id":"2870"},
    {"city_id":"32353","city_name":"Bilgoraj","state_id":"2870"},
    {"city_id":"32354","city_name":"Chelm","state_id":"2870"},
    {"city_id":"32355","city_name":"Deblin","state_id":"2870"},
    {"city_id":"32356","city_name":"Hrubieszow","state_id":"2870"},
    {"city_id":"32357","city_name":"Janow Lubelski","state_id":"2870"},
    {"city_id":"32358","city_name":"Krasnik","state_id":"2870"},
    {"city_id":"32359","city_name":"Krasnystaw","state_id":"2870"},
    {"city_id":"32360","city_name":"Leczna","state_id":"2870"},
    {"city_id":"32361","city_name":"Lubartow","state_id":"2870"},
    {"city_id":"32362","city_name":"Lublin","state_id":"2870"},
    {"city_id":"32363","city_name":"Lukow","state_id":"2870"},
    {"city_id":"32364","city_name":"Miedzyrzec Podlaski","state_id":"2870"},
    {"city_id":"32365","city_name":"Opole Lubelskie","state_id":"2870"},
    {"city_id":"32366","city_name":"Parczew","state_id":"2870"},
    {"city_id":"32367","city_name":"Poniatowa","state_id":"2870"},
    {"city_id":"32368","city_name":"Pulawy","state_id":"2870"},
    {"city_id":"32369","city_name":"Radzyn Podlaski","state_id":"2870"},
    {"city_id":"32370","city_name":"Ryki","state_id":"2870"},
    {"city_id":"32371","city_name":"Swidnik","state_id":"2870"},
    {"city_id":"32372","city_name":"Tomaszow Lubelski","state_id":"2870"},
    {"city_id":"32373","city_name":"Wlodawa","state_id":"2870"},
    {"city_id":"32374","city_name":"Zamosc","state_id":"2870"},
    {"city_id":"32375","city_name":"Drezdenko","state_id":"2871"},
    {"city_id":"32376","city_name":"Gorzow Wielkopolski","state_id":"2871"},
    {"city_id":"32377","city_name":"Gubin","state_id":"2871"},
    {"city_id":"32378","city_name":"Kostrzyn","state_id":"2871"},
    {"city_id":"32379","city_name":"Kozuchow","state_id":"2871"},
    {"city_id":"32380","city_name":"Krosno Odrzanskie","state_id":"2871"},
    {"city_id":"32381","city_name":"Lubsko","state_id":"2871"},
    {"city_id":"32382","city_name":"Miedzyrzecz","state_id":"2871"},
    {"city_id":"32383","city_name":"Nowa Sol","state_id":"2871"},
    {"city_id":"32384","city_name":"Skwierzyna","state_id":"2871"},
    {"city_id":"32385","city_name":"Slubice","state_id":"2871"},
    {"city_id":"32386","city_name":"Strzelce Krajenskie","state_id":"2871"},
    {"city_id":"32387","city_name":"Sulechow","state_id":"2871"},
    {"city_id":"32388","city_name":"Sulecin","state_id":"2871"},
    {"city_id":"32389","city_name":"Swiebodzin","state_id":"2871"},
    {"city_id":"32390","city_name":"Szprotawa","state_id":"2871"},
    {"city_id":"32391","city_name":"Wschowa","state_id":"2871"},
    {"city_id":"32392","city_name":"Zagan","state_id":"2871"},
    {"city_id":"32393","city_name":"Zary","state_id":"2871"},
    {"city_id":"32394","city_name":"Zielona Gora","state_id":"2871"},
    {"city_id":"32395","city_name":"Malomice","state_id":"2872"},
    {"city_id":"32396","city_name":"Andrychow","state_id":"2873"},
    {"city_id":"32397","city_name":"Bochnia","state_id":"2873"},
    {"city_id":"32398","city_name":"Brzesko","state_id":"2873"},
    {"city_id":"32399","city_name":"Brzeszcze","state_id":"2873"},
    {"city_id":"32400","city_name":"Bukowno","state_id":"2873"},
    {"city_id":"32401","city_name":"Chelmek","state_id":"2873"},
    {"city_id":"32402","city_name":"Chrzanow","state_id":"2873"},
    {"city_id":"32403","city_name":"Dabrowa Tarnowska","state_id":"2873"},
    {"city_id":"32404","city_name":"Gorlice","state_id":"2873"},
    {"city_id":"32405","city_name":"Kety","state_id":"2873"},
    {"city_id":"32406","city_name":"Krakow","state_id":"2873"},
    {"city_id":"32407","city_name":"Krynica","state_id":"2873"},
    {"city_id":"32408","city_name":"Krzeszowice","state_id":"2873"},
    {"city_id":"32409","city_name":"Libiaz","state_id":"2873"},
    {"city_id":"32410","city_name":"Limanowa","state_id":"2873"},
    {"city_id":"32411","city_name":"Miechow","state_id":"2873"},
    {"city_id":"32412","city_name":"Myslenice","state_id":"2873"},
    {"city_id":"32413","city_name":"Nowy Sacz","state_id":"2873"},
    {"city_id":"32414","city_name":"Nowy Targ","state_id":"2873"},
    {"city_id":"32415","city_name":"Olkusz","state_id":"2873"},
    {"city_id":"32416","city_name":"Oswiecim","state_id":"2873"},
    {"city_id":"32417","city_name":"Poronin","state_id":"2873"},
    {"city_id":"32418","city_name":"Rabka","state_id":"2873"},
    {"city_id":"32419","city_name":"Skawina","state_id":"2873"},
    {"city_id":"32420","city_name":"Stary Sacz","state_id":"2873"},
    {"city_id":"32421","city_name":"Sucha Beskidzka","state_id":"2873"},
    {"city_id":"32422","city_name":"Tarnow","state_id":"2873"},
    {"city_id":"32423","city_name":"Trzebinia","state_id":"2873"},
    {"city_id":"32424","city_name":"Wadowice","state_id":"2873"},
    {"city_id":"32425","city_name":"Wieliczka","state_id":"2873"},
    {"city_id":"32426","city_name":"Wolbrom","state_id":"2873"},
    {"city_id":"32427","city_name":"Zakopane","state_id":"2873"},
    {"city_id":"32428","city_name":"Blonie","state_id":"2874"},
    {"city_id":"32429","city_name":"Brwinow","state_id":"2874"},
    {"city_id":"32430","city_name":"Ciechanow","state_id":"2874"},
    {"city_id":"32431","city_name":"Garwolin","state_id":"2874"},
    {"city_id":"32432","city_name":"Gora Kalwaria","state_id":"2874"},
    {"city_id":"32433","city_name":"Gostynin","state_id":"2874"},
    {"city_id":"32434","city_name":"Grodzisk Mazowiecki","state_id":"2874"},
    {"city_id":"32435","city_name":"Grojec","state_id":"2874"},
    {"city_id":"32436","city_name":"Jozefow","state_id":"2874"},
    {"city_id":"32437","city_name":"Karczew","state_id":"2874"},
    {"city_id":"32438","city_name":"Kobylka","state_id":"2874"},
    {"city_id":"32439","city_name":"Konstancin-Jeziorna","state_id":"2874"},
    {"city_id":"32440","city_name":"Kozienice","state_id":"2874"},
    {"city_id":"32441","city_name":"Legionowo","state_id":"2874"},
    {"city_id":"32442","city_name":"Lomianki","state_id":"2874"},
    {"city_id":"32443","city_name":"Makow Mazowiecki","state_id":"2874"},
    {"city_id":"32444","city_name":"Marki","state_id":"2874"},
    {"city_id":"32445","city_name":"Milanowek","state_id":"2874"},
    {"city_id":"32446","city_name":"Minsk Mazowiecki","state_id":"2874"},
    {"city_id":"32447","city_name":"Mlawa","state_id":"2874"},
    {"city_id":"32448","city_name":"Nowy Dwor Mazowiecki","state_id":"2874"},
    {"city_id":"32449","city_name":"Ostroleka","state_id":"2874"},
    {"city_id":"32450","city_name":"Ostrow Mazowiecka","state_id":"2874"},
    {"city_id":"32451","city_name":"Otwock","state_id":"2874"},
    {"city_id":"32452","city_name":"Piaseczno","state_id":"2874"},
    {"city_id":"32453","city_name":"Piastow","state_id":"2874"},
    {"city_id":"32454","city_name":"Pionki","state_id":"2874"},
    {"city_id":"32455","city_name":"Plock","state_id":"2874"},
    {"city_id":"32456","city_name":"Plonsk","state_id":"2874"},
    {"city_id":"32457","city_name":"Pruszkow","state_id":"2874"},
    {"city_id":"32458","city_name":"Przasnysz","state_id":"2874"},
    {"city_id":"32459","city_name":"Pultusk","state_id":"2874"},
    {"city_id":"32460","city_name":"Radom","state_id":"2874"},
    {"city_id":"32461","city_name":"Siedlce","state_id":"2874"},
    {"city_id":"32462","city_name":"Sierpc","state_id":"2874"},
    {"city_id":"32463","city_name":"Sochaczew","state_id":"2874"},
    {"city_id":"32464","city_name":"Sokolow Podlaski","state_id":"2874"},
    {"city_id":"32465","city_name":"Sulejowek","state_id":"2874"},
    {"city_id":"32466","city_name":"Szydlowiec","state_id":"2874"},
    {"city_id":"32467","city_name":"Warka","state_id":"2874"},
    {"city_id":"32468","city_name":"Warszawa","state_id":"2874"},
    {"city_id":"32469","city_name":"Wegrow","state_id":"2874"},
    {"city_id":"32470","city_name":"Wesola","state_id":"2874"},
    {"city_id":"32471","city_name":"Wolomin","state_id":"2874"},
    {"city_id":"32472","city_name":"Wyszkow","state_id":"2874"},
    {"city_id":"32473","city_name":"Zabki","state_id":"2874"},
    {"city_id":"32474","city_name":"Zielonka","state_id":"2874"},
    {"city_id":"32475","city_name":"Zyrardow","state_id":"2874"},
    {"city_id":"32476","city_name":"Dlugoteka","state_id":"2875"},
    {"city_id":"32477","city_name":"Brzeg","state_id":"2876"},
    {"city_id":"32478","city_name":"Glubczyce","state_id":"2876"},
    {"city_id":"32479","city_name":"Glucholazy","state_id":"2876"},
    {"city_id":"32480","city_name":"Grodkow","state_id":"2876"},
    {"city_id":"32481","city_name":"Kedzierzyn-Kozle","state_id":"2876"},
    {"city_id":"32482","city_name":"Kluczbork","state_id":"2876"},
    {"city_id":"32483","city_name":"Krapkowice","state_id":"2876"},
    {"city_id":"32484","city_name":"Namyslow","state_id":"2876"},
    {"city_id":"32485","city_name":"Niemodlin","state_id":"2876"},
    {"city_id":"32486","city_name":"Nysa","state_id":"2876"},
    {"city_id":"32487","city_name":"Olesno","state_id":"2876"},
    {"city_id":"32488","city_name":"Opole","state_id":"2876"},
    {"city_id":"32489","city_name":"Ozimek","state_id":"2876"},
    {"city_id":"32490","city_name":"Prudnik","state_id":"2876"},
    {"city_id":"32491","city_name":"Strzelce Opolskie","state_id":"2876"},
    {"city_id":"32492","city_name":"Zawadzkie","state_id":"2876"},
    {"city_id":"32493","city_name":"Zdzieszowice","state_id":"2876"},
    {"city_id":"32494","city_name":"Cmielow","state_id":"2877"},
    {"city_id":"32495","city_name":"Debica","state_id":"2878"},
    {"city_id":"32496","city_name":"Jaroslaw","state_id":"2878"},
    {"city_id":"32497","city_name":"Jaslo","state_id":"2878"},
    {"city_id":"32498","city_name":"Krosno","state_id":"2878"},
    {"city_id":"32499","city_name":"Lancut","state_id":"2878"},
    {"city_id":"32500","city_name":"Lezajsk","state_id":"2878"},
    {"city_id":"32501","city_name":"Lubaczow","state_id":"2878"},
    {"city_id":"32502","city_name":"Mielec","state_id":"2878"},
    {"city_id":"32503","city_name":"Nisko","state_id":"2878"},
    {"city_id":"32504","city_name":"Nowa Deba","state_id":"2878"},
    {"city_id":"32505","city_name":"Przemysl","state_id":"2878"},
    {"city_id":"32506","city_name":"Przeworsk","state_id":"2878"},
    {"city_id":"32507","city_name":"Ropczyce","state_id":"2878"},
    {"city_id":"32508","city_name":"Rzeszow","state_id":"2878"},
    {"city_id":"32509","city_name":"Sanok","state_id":"2878"},
    {"city_id":"32510","city_name":"Stalowa Wola","state_id":"2878"},
    {"city_id":"32511","city_name":"Tarnobrzeg","state_id":"2878"},
    {"city_id":"32512","city_name":"Ustrzyki Dolne","state_id":"2878"},
    {"city_id":"32513","city_name":"Augustow","state_id":"2879"},
    {"city_id":"32514","city_name":"Bialystok","state_id":"2879"},
    {"city_id":"32515","city_name":"Bielsk Podlaski","state_id":"2879"},
    {"city_id":"32516","city_name":"Czarna Bialostocka","state_id":"2879"},
    {"city_id":"32517","city_name":"Grajewo","state_id":"2879"},
    {"city_id":"32518","city_name":"Hajnowka","state_id":"2879"},
    {"city_id":"32519","city_name":"Kolno","state_id":"2879"},
    {"city_id":"32520","city_name":"Lapy","state_id":"2879"},
    {"city_id":"32521","city_name":"Lomza","state_id":"2879"},
    {"city_id":"32522","city_name":"Monki","state_id":"2879"},
    {"city_id":"32523","city_name":"Siemiatycze","state_id":"2879"},
    {"city_id":"32524","city_name":"Sokolka","state_id":"2879"},
    {"city_id":"32525","city_name":"Suwalki","state_id":"2879"},
    {"city_id":"32526","city_name":"Wysokie Mazowieckie","state_id":"2879"},
    {"city_id":"32527","city_name":"Zambrow","state_id":"2879"},
    {"city_id":"32528","city_name":"Bogumilowice","state_id":"2880"},
    {"city_id":"32529","city_name":"Bytow","state_id":"2881"},
    {"city_id":"32530","city_name":"Chojnice","state_id":"2881"},
    {"city_id":"32531","city_name":"Czersk","state_id":"2881"},
    {"city_id":"32532","city_name":"Czluchow","state_id":"2881"},
    {"city_id":"32533","city_name":"Gdansk","state_id":"2881"},
    {"city_id":"32534","city_name":"Gdynia","state_id":"2881"},
    {"city_id":"32535","city_name":"Kartuzy","state_id":"2881"},
    {"city_id":"32536","city_name":"Koscierzyna","state_id":"2881"},
    {"city_id":"32537","city_name":"Kwidzyn","state_id":"2881"},
    {"city_id":"32538","city_name":"Lebork","state_id":"2881"},
    {"city_id":"32539","city_name":"Malbork","state_id":"2881"},
    {"city_id":"32540","city_name":"Miastko","state_id":"2881"},
    {"city_id":"32541","city_name":"Nowy Dwor Gdanski","state_id":"2881"},
    {"city_id":"32542","city_name":"Pruszcz Gdanski","state_id":"2881"},
    {"city_id":"32543","city_name":"Puck","state_id":"2881"},
    {"city_id":"32544","city_name":"Reda","state_id":"2881"},
    {"city_id":"32545","city_name":"Rumia","state_id":"2881"},
    {"city_id":"32546","city_name":"Slupsk","state_id":"2881"},
    {"city_id":"32547","city_name":"Sopot","state_id":"2881"},
    {"city_id":"32548","city_name":"Starogard Gdanski","state_id":"2881"},
    {"city_id":"32549","city_name":"Sztum","state_id":"2881"},
    {"city_id":"32550","city_name":"Tczew","state_id":"2881"},
    {"city_id":"32551","city_name":"Ustka","state_id":"2881"},
    {"city_id":"32552","city_name":"Wejherowo","state_id":"2881"},
    {"city_id":"32553","city_name":"Wladyslawowo","state_id":"2881"},
    {"city_id":"32554","city_name":"Komorniki","state_id":"2882"},
    {"city_id":"32555","city_name":"Steszew","state_id":"2882"},
    {"city_id":"32556","city_name":"Raszyn","state_id":"2883"},
    {"city_id":"32557","city_name":"Rymanowska","state_id":"2884"},
    {"city_id":"32558","city_name":"Glogow Malopolski","state_id":"2885"},
    {"city_id":"32559","city_name":"Bedzin","state_id":"2886"},
    {"city_id":"32560","city_name":"Bielsko-Biala","state_id":"2886"},
    {"city_id":"32561","city_name":"Bierun","state_id":"2886"},
    {"city_id":"32562","city_name":"Blachowania","state_id":"2886"},
    {"city_id":"32563","city_name":"Bytom","state_id":"2886"},
    {"city_id":"32564","city_name":"Chorzow","state_id":"2886"},
    {"city_id":"32565","city_name":"Cieszyn","state_id":"2886"},
    {"city_id":"32566","city_name":"Czechowice-Dziedzice","state_id":"2886"},
    {"city_id":"32567","city_name":"Czeladz","state_id":"2886"},
    {"city_id":"32568","city_name":"Czerwionka-Leszczyny","state_id":"2886"},
    {"city_id":"32569","city_name":"Czestochowa","state_id":"2886"},
    {"city_id":"32570","city_name":"Dabrowa Gornicza","state_id":"2886"},
    {"city_id":"32571","city_name":"Gliwice","state_id":"2886"},
    {"city_id":"32572","city_name":"Jastrzebie-Zdroj","state_id":"2886"},
    {"city_id":"32573","city_name":"Jaworzno","state_id":"2886"},
    {"city_id":"32574","city_name":"Kalety","state_id":"2886"},
    {"city_id":"32575","city_name":"Katowice","state_id":"2886"},
    {"city_id":"32576","city_name":"Klobuck","state_id":"2886"},
    {"city_id":"32577","city_name":"Knurow","state_id":"2886"},
    {"city_id":"32578","city_name":"Laziska Gorne","state_id":"2886"},
    {"city_id":"32579","city_name":"Ledziny","state_id":"2886"},
    {"city_id":"32580","city_name":"Lubliniec","state_id":"2886"},
    {"city_id":"32581","city_name":"Mikolow","state_id":"2886"},
    {"city_id":"32582","city_name":"Myslowice","state_id":"2886"},
    {"city_id":"32583","city_name":"Myszkow","state_id":"2886"},
    {"city_id":"32584","city_name":"Orzesze","state_id":"2886"},
    {"city_id":"32585","city_name":"Piekary Slaskie","state_id":"2886"},
    {"city_id":"32586","city_name":"Poreba","state_id":"2886"},
    {"city_id":"32587","city_name":"Pszczyna","state_id":"2886"},
    {"city_id":"32588","city_name":"Pszow","state_id":"2886"},
    {"city_id":"32589","city_name":"Pyskowice","state_id":"2886"},
    {"city_id":"32590","city_name":"Raciborz","state_id":"2886"},
    {"city_id":"32591","city_name":"Radlin","state_id":"2886"},
    {"city_id":"32592","city_name":"Radzionkow","state_id":"2886"},
    {"city_id":"32593","city_name":"Ruda Slaska","state_id":"2886"},
    {"city_id":"32594","city_name":"Rybnik","state_id":"2886"},
    {"city_id":"32595","city_name":"Rydultowy","state_id":"2886"},
    {"city_id":"32596","city_name":"Siemianowice Slaskie","state_id":"2886"},
    {"city_id":"32597","city_name":"Skoczow","state_id":"2886"},
    {"city_id":"32598","city_name":"Sosnowiec","state_id":"2886"},
    {"city_id":"32599","city_name":"Swietochlowice","state_id":"2886"},
    {"city_id":"32600","city_name":"Tarnowskie Gory","state_id":"2886"},
    {"city_id":"32601","city_name":"Tychy","state_id":"2886"},
    {"city_id":"32602","city_name":"Ustron","state_id":"2886"},
    {"city_id":"32603","city_name":"Wisla","state_id":"2886"},
    {"city_id":"32604","city_name":"Wodzislaw Slaski","state_id":"2886"},
    {"city_id":"32605","city_name":"Wojkowice","state_id":"2886"},
    {"city_id":"32606","city_name":"Zabrze","state_id":"2886"},
    {"city_id":"32607","city_name":"Zawiercie","state_id":"2886"},
    {"city_id":"32608","city_name":"Zory","state_id":"2886"},
    {"city_id":"32609","city_name":"Zywiec","state_id":"2886"},
    {"city_id":"32610","city_name":"Busko-Zdroj","state_id":"2888"},
    {"city_id":"32611","city_name":"Jedrzejow","state_id":"2888"},
    {"city_id":"32612","city_name":"Kielce","state_id":"2888"},
    {"city_id":"32613","city_name":"Konskie","state_id":"2888"},
    {"city_id":"32614","city_name":"Ostrowiec Swietokrzyski","state_id":"2888"},
    {"city_id":"32615","city_name":"Pinczow","state_id":"2888"},
    {"city_id":"32616","city_name":"Polaniec","state_id":"2888"},
    {"city_id":"32617","city_name":"Sandomierz","state_id":"2888"},
    {"city_id":"32618","city_name":"Skarzysko-Kamienna","state_id":"2888"},
    {"city_id":"32619","city_name":"Starachowice","state_id":"2888"},
    {"city_id":"32620","city_name":"Staszow","state_id":"2888"},
    {"city_id":"32621","city_name":"Suchedniow","state_id":"2888"},
    {"city_id":"32622","city_name":"Wloszczowa","state_id":"2888"},
    {"city_id":"32623","city_name":"Bartoszyce","state_id":"2889"},
    {"city_id":"32624","city_name":"Biskupiec","state_id":"2889"},
    {"city_id":"32625","city_name":"Braniewo","state_id":"2889"},
    {"city_id":"32626","city_name":"Dobre Miasto","state_id":"2889"},
    {"city_id":"32627","city_name":"Dzialdowo","state_id":"2889"},
    {"city_id":"32628","city_name":"Elblag","state_id":"2889"},
    {"city_id":"32629","city_name":"Elk","state_id":"2889"},
    {"city_id":"32630","city_name":"Gizycko","state_id":"2889"},
    {"city_id":"32631","city_name":"Goldap","state_id":"2889"},
    {"city_id":"32632","city_name":"Ilawa","state_id":"2889"},
    {"city_id":"32633","city_name":"Ketrzyn","state_id":"2889"},
    {"city_id":"32634","city_name":"Lidzbark Warminski","state_id":"2889"},
    {"city_id":"32635","city_name":"Lubawa","state_id":"2889"},
    {"city_id":"32636","city_name":"Morag","state_id":"2889"},
    {"city_id":"32637","city_name":"Mragowo","state_id":"2889"},
    {"city_id":"32638","city_name":"Nidzica","state_id":"2889"},
    {"city_id":"32639","city_name":"Nowe Miasto Lubawskie","state_id":"2889"},
    {"city_id":"32640","city_name":"Olecko","state_id":"2889"},
    {"city_id":"32641","city_name":"Olsztyn","state_id":"2889"},
    {"city_id":"32642","city_name":"Orneta","state_id":"2889"},
    {"city_id":"32643","city_name":"Ostroda","state_id":"2889"},
    {"city_id":"32644","city_name":"Paslek","state_id":"2889"},
    {"city_id":"32645","city_name":"Pisz","state_id":"2889"},
    {"city_id":"32646","city_name":"Szczytno","state_id":"2889"},
    {"city_id":"32647","city_name":"Wegorzewo","state_id":"2889"},
    {"city_id":"32648","city_name":"Nadarzyn","state_id":"2890"},
    {"city_id":"32649","city_name":"Szemud","state_id":"2890"},
    {"city_id":"32650","city_name":"Warsaw","state_id":"2890"},
    {"city_id":"32651","city_name":"Goscicino","state_id":"2891"},
    {"city_id":"32652","city_name":"Chodziez","state_id":"2892"},
    {"city_id":"32653","city_name":"Czarnkow","state_id":"2892"},
    {"city_id":"32654","city_name":"Gniezno","state_id":"2892"},
    {"city_id":"32655","city_name":"Gostyn","state_id":"2892"},
    {"city_id":"32656","city_name":"Grodzisk Wielkopolski","state_id":"2892"},
    {"city_id":"32657","city_name":"Jarocin","state_id":"2892"},
    {"city_id":"32658","city_name":"Kalisz","state_id":"2892"},
    {"city_id":"32659","city_name":"Kepno","state_id":"2892"},
    {"city_id":"32660","city_name":"Kolo","state_id":"2892"},
    {"city_id":"32661","city_name":"Konin","state_id":"2892"},
    {"city_id":"32662","city_name":"Koscian","state_id":"2892"},
    {"city_id":"32663","city_name":"Krotoszyn","state_id":"2892"},
    {"city_id":"32664","city_name":"Leszno","state_id":"2892"},
    {"city_id":"32665","city_name":"Lubon","state_id":"2892"},
    {"city_id":"32666","city_name":"Miedzychod","state_id":"2892"},
    {"city_id":"32667","city_name":"Mosina","state_id":"2892"},
    {"city_id":"32668","city_name":"Murowana Goslina","state_id":"2892"},
    {"city_id":"32669","city_name":"Nowy Tomysl","state_id":"2892"},
    {"city_id":"32670","city_name":"Oborniki","state_id":"2892"},
    {"city_id":"32671","city_name":"Opalenica","state_id":"2892"},
    {"city_id":"32672","city_name":"Ostrow Wielkopolski","state_id":"2892"},
    {"city_id":"32673","city_name":"Ostrzeszow","state_id":"2892"},
    {"city_id":"32674","city_name":"Pila","state_id":"2892"},
    {"city_id":"32675","city_name":"Pleszew","state_id":"2892"},
    {"city_id":"32676","city_name":"Poznan","state_id":"2892"},
    {"city_id":"32677","city_name":"Rawicz","state_id":"2892"},
    {"city_id":"32678","city_name":"Rogozno","state_id":"2892"},
    {"city_id":"32679","city_name":"Slupca","state_id":"2892"},
    {"city_id":"32680","city_name":"Srem","state_id":"2892"},
    {"city_id":"32681","city_name":"Sroda Wielkopolska","state_id":"2892"},
    {"city_id":"32682","city_name":"Swarzedz","state_id":"2892"},
    {"city_id":"32683","city_name":"Szamotuly","state_id":"2892"},
    {"city_id":"32684","city_name":"Trzcianka","state_id":"2892"},
    {"city_id":"32685","city_name":"Tuliszkow","state_id":"2892"},
    {"city_id":"32686","city_name":"Turek","state_id":"2892"},
    {"city_id":"32687","city_name":"Wagrowiec","state_id":"2892"},
    {"city_id":"32688","city_name":"Wolsztyn","state_id":"2892"},
    {"city_id":"32689","city_name":"Wronki","state_id":"2892"},
    {"city_id":"32690","city_name":"Wrzesnia","state_id":"2892"},
    {"city_id":"32691","city_name":"Zlotow","state_id":"2892"},
    {"city_id":"32692","city_name":"Borowa","state_id":"2893"},
    {"city_id":"32693","city_name":"Barlinek","state_id":"2894"},
    {"city_id":"32694","city_name":"Bialogard","state_id":"2894"},
    {"city_id":"32695","city_name":"Choszczno","state_id":"2894"},
    {"city_id":"32696","city_name":"Darlowo","state_id":"2894"},
    {"city_id":"32697","city_name":"Debno","state_id":"2894"},
    {"city_id":"32698","city_name":"Drawsko Pomorskie","state_id":"2894"},
    {"city_id":"32699","city_name":"Goleniow","state_id":"2894"},
    {"city_id":"32700","city_name":"Gryfice","state_id":"2894"},
    {"city_id":"32701","city_name":"Gryfino","state_id":"2894"},
    {"city_id":"32702","city_name":"Kamien Pomorski","state_id":"2894"},
    {"city_id":"32703","city_name":"Kolobrzeg","state_id":"2894"},
    {"city_id":"32704","city_name":"Koszalin","state_id":"2894"},
    {"city_id":"32705","city_name":"Lobez","state_id":"2894"},
    {"city_id":"32706","city_name":"Mysliborz","state_id":"2894"},
    {"city_id":"32707","city_name":"Nowogard","state_id":"2894"},
    {"city_id":"32708","city_name":"Polczyn-Zdroj","state_id":"2894"},
    {"city_id":"32709","city_name":"Police","state_id":"2894"},
    {"city_id":"32710","city_name":"Pyrzyce","state_id":"2894"},
    {"city_id":"32711","city_name":"Slawno","state_id":"2894"},
    {"city_id":"32712","city_name":"Stargard Szczecinski","state_id":"2894"},
    {"city_id":"32713","city_name":"Swidwin","state_id":"2894"},
    {"city_id":"32714","city_name":"Swinoujscie","state_id":"2894"},
    {"city_id":"32715","city_name":"Szczecin","state_id":"2894"},
    {"city_id":"32716","city_name":"Szczecinek","state_id":"2894"},
    {"city_id":"32717","city_name":"Trzebiatow","state_id":"2894"},
    {"city_id":"32718","city_name":"Walcz","state_id":"2894"},
    {"city_id":"32719","city_name":"Zlocieniec","state_id":"2894"},
    {"city_id":"32720","city_name":"Zukowskie","state_id":"2895"},
    {"city_id":"32721","city_name":"Tramagal","state_id":"2896"},
    {"city_id":"32722","city_name":"Angra","state_id":"2897"},
    {"city_id":"32723","city_name":"Arrifes","state_id":"2897"},
    {"city_id":"32724","city_name":"Faja de Baixo","state_id":"2897"},
    {"city_id":"32725","city_name":"Horta","state_id":"2897"},
    {"city_id":"32726","city_name":"Lagoa","state_id":"2897"},
    {"city_id":"32727","city_name":"Ponta Delgada","state_id":"2897"},
    {"city_id":"32728","city_name":"Praia da Vitoria","state_id":"2897"},
    {"city_id":"32729","city_name":"Rabo de Peixe","state_id":"2897"},
    {"city_id":"32730","city_name":"Ribeira Grande","state_id":"2897"},
    {"city_id":"32731","city_name":"Rosto do Cao","state_id":"2897"},
    {"city_id":"32732","city_name":"Vila Franca do Campo","state_id":"2897"},
    {"city_id":"32733","city_name":"Alcacer do Sal","state_id":"2898"},
    {"city_id":"32734","city_name":"Aljustrel","state_id":"2898"},
    {"city_id":"32735","city_name":"Beja","state_id":"2898"},
    {"city_id":"32736","city_name":"Borba","state_id":"2898"},
    {"city_id":"32737","city_name":"Caia e Sao Pedro","state_id":"2898"},
    {"city_id":"32738","city_name":"Campo Maior","state_id":"2898"},
    {"city_id":"32739","city_name":"Castro Verde","state_id":"2898"},
    {"city_id":"32740","city_name":"Cercal","state_id":"2898"},
    {"city_id":"32741","city_name":"Elvas","state_id":"2898"},
    {"city_id":"32742","city_name":"Estremoz","state_id":"2898"},
    {"city_id":"32743","city_name":"Evora","state_id":"2898"},
    {"city_id":"32744","city_name":"Ferreira do Alentejo","state_id":"2898"},
    {"city_id":"32745","city_name":"Grandola","state_id":"2898"},
    {"city_id":"32746","city_name":"Montemor-o-Novo","state_id":"2898"},
    {"city_id":"32747","city_name":"Moura","state_id":"2898"},
    {"city_id":"32748","city_name":"Odemira","state_id":"2898"},
    {"city_id":"32749","city_name":"Ponte de Sor","state_id":"2898"},
    {"city_id":"32750","city_name":"Portalegre","state_id":"2898"},
    {"city_id":"32751","city_name":"Redondo","state_id":"2898"},
    {"city_id":"32752","city_name":"Reguengos de Monsaraz","state_id":"2898"},
    {"city_id":"32753","city_name":"Santiago do Cacem","state_id":"2898"},
    {"city_id":"32754","city_name":"Santo Andre","state_id":"2898"},
    {"city_id":"32755","city_name":"Sao Teotonio","state_id":"2898"},
    {"city_id":"32756","city_name":"Serpa","state_id":"2898"},
    {"city_id":"32757","city_name":"Sines","state_id":"2898"},
    {"city_id":"32758","city_name":"Vendas Novas","state_id":"2898"},
    {"city_id":"32759","city_name":"Vila Nova de Milfontes","state_id":"2898"},
    {"city_id":"32760","city_name":"Vila Vicosa","state_id":"2898"},
    {"city_id":"32761","city_name":"Albufeira","state_id":"2899"},
    {"city_id":"32762","city_name":"Aljezur","state_id":"2899"},
    {"city_id":"32763","city_name":"Almancil","state_id":"2899"},
    {"city_id":"32764","city_name":"Alvor","state_id":"2899"},
    {"city_id":"32765","city_name":"Armacao de Pera","state_id":"2899"},
    {"city_id":"32766","city_name":"Boliqueime","state_id":"2899"},
    {"city_id":"32767","city_name":"Castro Marim","state_id":"2899"},
    {"city_id":"32768","city_name":"Estombar","state_id":"2899"},
    {"city_id":"32769","city_name":"Faro","state_id":"2899"},
    {"city_id":"32770","city_name":"Ferreiras","state_id":"2899"},
    {"city_id":"32771","city_name":"Guia","state_id":"2899"},
    {"city_id":"32772","city_name":"Lagoa","state_id":"2899"},
    {"city_id":"32773","city_name":"Lagos","state_id":"2899"},
    {"city_id":"32774","city_name":"Loule","state_id":"2899"},
    {"city_id":"32775","city_name":"Luz","state_id":"2899"},
    {"city_id":"32776","city_name":"Moncarapacho","state_id":"2899"},
    {"city_id":"32777","city_name":"Monchique","state_id":"2899"},
    {"city_id":"32778","city_name":"Monte Gordo","state_id":"2899"},
    {"city_id":"32779","city_name":"Montenegro","state_id":"2899"},
    {"city_id":"32780","city_name":"Olhao","state_id":"2899"},
    {"city_id":"32781","city_name":"Portimao","state_id":"2899"},
    {"city_id":"32782","city_name":"Quarteira","state_id":"2899"},
    {"city_id":"32783","city_name":"Santa Barbara de Nexe","state_id":"2899"},
    {"city_id":"32784","city_name":"Sao Bartolomeu de Messines","state_id":"2899"},
    {"city_id":"32785","city_name":"Sao Bras de Alportel","state_id":"2899"},
    {"city_id":"32786","city_name":"Silves","state_id":"2899"},
    {"city_id":"32787","city_name":"Tavira","state_id":"2899"},
    {"city_id":"32788","city_name":"Vila Real de Santo Antonio","state_id":"2899"},
    {"city_id":"32789","city_name":"Fafe","state_id":"2900"},
    {"city_id":"32790","city_name":"Forjaes","state_id":"2900"},
    {"city_id":"32791","city_name":"Vianna do Castello","state_id":"2900"},
    {"city_id":"32792","city_name":"Abraveses","state_id":"2901"},
    {"city_id":"32793","city_name":"Aguada de Cima","state_id":"2901"},
    {"city_id":"32794","city_name":"Agueda","state_id":"2901"},
    {"city_id":"32795","city_name":"Albergaria-A-Velha","state_id":"2901"},
    {"city_id":"32796","city_name":"Alcains","state_id":"2901"},
    {"city_id":"32797","city_name":"Alhadas","state_id":"2901"},
    {"city_id":"32798","city_name":"Amor","state_id":"2901"},
    {"city_id":"32799","city_name":"Aradas","state_id":"2901"},
    {"city_id":"32800","city_name":"Arazede","state_id":"2901"},
    {"city_id":"32801","city_name":"Arcos","state_id":"2901"},
    {"city_id":"32802","city_name":"Arganil","state_id":"2901"},
    {"city_id":"32803","city_name":"Avanca","state_id":"2901"},
    {"city_id":"32804","city_name":"Aveiro","state_id":"2901"},
    {"city_id":"32805","city_name":"Batalha","state_id":"2901"},
    {"city_id":"32806","city_name":"Beduido","state_id":"2901"},
    {"city_id":"32807","city_name":"Branca","state_id":"2901"},
    {"city_id":"32808","city_name":"Buarcos","state_id":"2901"},
    {"city_id":"32809","city_name":"Campo","state_id":"2901"},
    {"city_id":"32810","city_name":"Cantanhede","state_id":"2901"},
    {"city_id":"32811","city_name":"Caranguejeira","state_id":"2901"},
    {"city_id":"32812","city_name":"Castanheira de Pera","state_id":"2901"},
    {"city_id":"32813","city_name":"Castelo Branco","state_id":"2901"},
    {"city_id":"32814","city_name":"Castro Daire","state_id":"2901"},
    {"city_id":"32815","city_name":"Coimbra","state_id":"2901"},
    {"city_id":"32816","city_name":"Condeixa-A-Nova","state_id":"2901"},
    {"city_id":"32817","city_name":"Cortegaca","state_id":"2901"},
    {"city_id":"32818","city_name":"Covilha","state_id":"2901"},
    {"city_id":"32819","city_name":"Eixo","state_id":"2901"},
    {"city_id":"32820","city_name":"Esmoriz","state_id":"2901"},
    {"city_id":"32821","city_name":"Figueira da Foz","state_id":"2901"},
    {"city_id":"32822","city_name":"Fundao","state_id":"2901"},
    {"city_id":"32823","city_name":"Gafanha da Encarnacao","state_id":"2901"},
    {"city_id":"32824","city_name":"Gafanha da Nazare","state_id":"2901"},
    {"city_id":"32825","city_name":"Guarda","state_id":"2901"},
    {"city_id":"32826","city_name":"Ilhavo","state_id":"2901"},
    {"city_id":"32827","city_name":"Lavos","state_id":"2901"},
    {"city_id":"32828","city_name":"Leiria","state_id":"2901"},
    {"city_id":"32829","city_name":"Lorvao","state_id":"2901"},
    {"city_id":"32830","city_name":"Lourical","state_id":"2901"},
    {"city_id":"32831","city_name":"Lousa","state_id":"2901"},
    {"city_id":"32832","city_name":"Maceira","state_id":"2901"},
    {"city_id":"32833","city_name":"Mangualde","state_id":"2901"},
    {"city_id":"32834","city_name":"Marinha Grande","state_id":"2901"},
    {"city_id":"32835","city_name":"Mealhada","state_id":"2901"},
    {"city_id":"32836","city_name":"Mira","state_id":"2901"},
    {"city_id":"32837","city_name":"Mira de Aire","state_id":"2901"},
    {"city_id":"32838","city_name":"Miranda do Corvo","state_id":"2901"},
    {"city_id":"32839","city_name":"Monte Redondo","state_id":"2901"},
    {"city_id":"32840","city_name":"Nelas","state_id":"2901"},
    {"city_id":"32841","city_name":"Oia","state_id":"2901"},
    {"city_id":"32842","city_name":"Oliveira do Bairro","state_id":"2901"},
    {"city_id":"32843","city_name":"Oliveira do Hospital","state_id":"2901"},
    {"city_id":"32844","city_name":"Oliveirinha","state_id":"2901"},
    {"city_id":"32845","city_name":"Ovar","state_id":"2901"},
    {"city_id":"32846","city_name":"Pampilhosa","state_id":"2901"},
    {"city_id":"32847","city_name":"Pardilho","state_id":"2901"},
    {"city_id":"32848","city_name":"Poiares","state_id":"2901"},
    {"city_id":"32849","city_name":"Pombal","state_id":"2901"},
    {"city_id":"32850","city_name":"Porto de Mos","state_id":"2901"},
    {"city_id":"32851","city_name":"Proenca-A-Nova","state_id":"2901"},
    {"city_id":"32852","city_name":"Rio de Loba","state_id":"2901"},
    {"city_id":"32853","city_name":"Salreu","state_id":"2901"},
    {"city_id":"32854","city_name":"Sangalhos","state_id":"2901"},
    {"city_id":"32855","city_name":"Santa Catarina da Serra","state_id":"2901"},
    {"city_id":"32856","city_name":"Sao Joao","state_id":"2901"},
    {"city_id":"32857","city_name":"Sao Pedro do Sul","state_id":"2901"},
    {"city_id":"32858","city_name":"Satao","state_id":"2901"},
    {"city_id":"32859","city_name":"Seia","state_id":"2901"},
    {"city_id":"32860","city_name":"Serta","state_id":"2901"},
    {"city_id":"32861","city_name":"Soure","state_id":"2901"},
    {"city_id":"32862","city_name":"Souto da Carpalhosa","state_id":"2901"},
    {"city_id":"32863","city_name":"Tavarede","state_id":"2901"},
    {"city_id":"32864","city_name":"Teixoso","state_id":"2901"},
    {"city_id":"32865","city_name":"Tondela","state_id":"2901"},
    {"city_id":"32866","city_name":"Tortosendo","state_id":"2901"},
    {"city_id":"32867","city_name":"Vagos","state_id":"2901"},
    {"city_id":"32868","city_name":"Valega","state_id":"2901"},
    {"city_id":"32869","city_name":"Valongo do Vouga","state_id":"2901"},
    {"city_id":"32870","city_name":"Vieira de Leiria","state_id":"2901"},
    {"city_id":"32871","city_name":"Viseu","state_id":"2901"},
    {"city_id":"32872","city_name":"Camacha","state_id":"2907"},
    {"city_id":"32873","city_name":"Camara de Lobos","state_id":"2907"},
    {"city_id":"32874","city_name":"Campanario","state_id":"2907"},
    {"city_id":"32875","city_name":"Canico","state_id":"2907"},
    {"city_id":"32876","city_name":"Funchal","state_id":"2907"},
    {"city_id":"32877","city_name":"Machico","state_id":"2907"},
    {"city_id":"32878","city_name":"Ponta do Sol","state_id":"2907"},
    {"city_id":"32879","city_name":"Porto Santo","state_id":"2907"},
    {"city_id":"32880","city_name":"Ribeira Brava","state_id":"2907"},
    {"city_id":"32881","city_name":"Santa Cruz","state_id":"2907"},
    {"city_id":"32882","city_name":"A Ver-o-Mar","state_id":"2908"},
    {"city_id":"32883","city_name":"Adaufe","state_id":"2908"},
    {"city_id":"32884","city_name":"Aguas Santas","state_id":"2908"},
    {"city_id":"32885","city_name":"Agucadoura","state_id":"2908"},
    {"city_id":"32886","city_name":"Alfena","state_id":"2908"},
    {"city_id":"32887","city_name":"Alpendurada e Matos","state_id":"2908"},
    {"city_id":"32888","city_name":"Amarante","state_id":"2908"},
    {"city_id":"32889","city_name":"Anta","state_id":"2908"},
    {"city_id":"32890","city_name":"Antas","state_id":"2908"},
    {"city_id":"32891","city_name":"Apulia","state_id":"2908"},
    {"city_id":"32892","city_name":"Arcozelo","state_id":"2908"},
    {"city_id":"32893","city_name":"Areosa","state_id":"2908"},
    {"city_id":"32894","city_name":"Argoncilhe","state_id":"2908"},
    {"city_id":"32895","city_name":"Aroes","state_id":"2908"},
    {"city_id":"32896","city_name":"Arrifana","state_id":"2908"},
    {"city_id":"32897","city_name":"Arvore","state_id":"2908"},
    {"city_id":"32898","city_name":"Aves","state_id":"2908"},
    {"city_id":"32899","city_name":"Avintes","state_id":"2908"},
    {"city_id":"32900","city_name":"Baguim do Monte","state_id":"2908"},
    {"city_id":"32901","city_name":"Baltar","state_id":"2908"},
    {"city_id":"32902","city_name":"Barcelos","state_id":"2908"},
    {"city_id":"32903","city_name":"Barrosas","state_id":"2908"},
    {"city_id":"32904","city_name":"Bougado","state_id":"2908"},
    {"city_id":"32905","city_name":"Braga","state_id":"2908"},
    {"city_id":"32906","city_name":"Braganca","state_id":"2908"},
    {"city_id":"32907","city_name":"Brito","state_id":"2908"},
    {"city_id":"32908","city_name":"Caldas de Vizela","state_id":"2908"},
    {"city_id":"32909","city_name":"Caldelas","state_id":"2908"},
    {"city_id":"32910","city_name":"Calendario","state_id":"2908"},
    {"city_id":"32911","city_name":"Campo","state_id":"2908"},
    {"city_id":"32912","city_name":"Candoso","state_id":"2908"},
    {"city_id":"32913","city_name":"Canedo","state_id":"2908"},
    {"city_id":"32914","city_name":"Canelas","state_id":"2908"},
    {"city_id":"32915","city_name":"Canidelo","state_id":"2908"},
    {"city_id":"32916","city_name":"Carvalhosa","state_id":"2908"},
    {"city_id":"32917","city_name":"Casteloes de Cepeda","state_id":"2908"},
    {"city_id":"32918","city_name":"Chaves","state_id":"2908"},
    {"city_id":"32919","city_name":"Coronado","state_id":"2908"},
    {"city_id":"32920","city_name":"Couto","state_id":"2908"},
    {"city_id":"32921","city_name":"Custoias","state_id":"2908"},
    {"city_id":"32922","city_name":"Darque","state_id":"2908"},
    {"city_id":"32923","city_name":"Ermesinde","state_id":"2908"},
    {"city_id":"32924","city_name":"Espinho","state_id":"2908"},
    {"city_id":"32925","city_name":"Fafe","state_id":"2908"},
    {"city_id":"32926","city_name":"Fanzeres","state_id":"2908"},
    {"city_id":"32927","city_name":"Feira","state_id":"2908"},
    {"city_id":"32928","city_name":"Ferreira","state_id":"2908"},
    {"city_id":"32929","city_name":"Ferreiros","state_id":"2908"},
    {"city_id":"32930","city_name":"Fiaes","state_id":"2908"},
    {"city_id":"32931","city_name":"Figueiro","state_id":"2908"},
    {"city_id":"32932","city_name":"Foz do Sousa","state_id":"2908"},
    {"city_id":"32933","city_name":"Frazao","state_id":"2908"},
    {"city_id":"32934","city_name":"Freamunde","state_id":"2908"},
    {"city_id":"32935","city_name":"Galegos","state_id":"2908"},
    {"city_id":"32936","city_name":"Gandra","state_id":"2908"},
    {"city_id":"32937","city_name":"Gemunde","state_id":"2908"},
    {"city_id":"32938","city_name":"Godim","state_id":"2908"},
    {"city_id":"32939","city_name":"Gondomar","state_id":"2908"},
    {"city_id":"32940","city_name":"Grijo","state_id":"2908"},
    {"city_id":"32941","city_name":"Gueifaes","state_id":"2908"},
    {"city_id":"32942","city_name":"Guifoes","state_id":"2908"},
    {"city_id":"32943","city_name":"Guimaraes","state_id":"2908"},
    {"city_id":"32944","city_name":"Gulpilhares","state_id":"2908"},
    {"city_id":"32945","city_name":"Joane","state_id":"2908"},
    {"city_id":"32946","city_name":"Jovim","state_id":"2908"},
    {"city_id":"32947","city_name":"Lamego","state_id":"2908"},
    {"city_id":"32948","city_name":"Lavra","state_id":"2908"},
    {"city_id":"32949","city_name":"Leca da Palmeira","state_id":"2908"},
    {"city_id":"32950","city_name":"Leca do Bailio","state_id":"2908"},
    {"city_id":"32951","city_name":"Lobao","state_id":"2908"},
    {"city_id":"32952","city_name":"Lordelo","state_id":"2908"},
    {"city_id":"32953","city_name":"Lourosa","state_id":"2908"},
    {"city_id":"32954","city_name":"Lustosa","state_id":"2908"},
    {"city_id":"32955","city_name":"Macedo de Cavaleiros","state_id":"2908"},
    {"city_id":"32956","city_name":"Macieira de Cambra","state_id":"2908"},
    {"city_id":"32957","city_name":"Madalena","state_id":"2908"},
    {"city_id":"32958","city_name":"Maia","state_id":"2908"},
    {"city_id":"32959","city_name":"Margaride","state_id":"2908"},
    {"city_id":"32960","city_name":"Marinhas","state_id":"2908"},
    {"city_id":"32961","city_name":"Matosinhos","state_id":"2908"},
    {"city_id":"32962","city_name":"Meadela","state_id":"2908"},
    {"city_id":"32963","city_name":"Meinedo","state_id":"2908"},
    {"city_id":"32964","city_name":"Milheiros","state_id":"2908"},
    {"city_id":"32965","city_name":"Milheiros de Poiares","state_id":"2908"},
    {"city_id":"32966","city_name":"Mirandela","state_id":"2908"},
    {"city_id":"32967","city_name":"Mogadouro","state_id":"2908"},
    {"city_id":"32968","city_name":"Moreira","state_id":"2908"},
    {"city_id":"32969","city_name":"Moreira de Conegos","state_id":"2908"},
    {"city_id":"32970","city_name":"Mozelos","state_id":"2908"},
    {"city_id":"32971","city_name":"Negrelos","state_id":"2908"},
    {"city_id":"32972","city_name":"Nogueira","state_id":"2908"},
    {"city_id":"32973","city_name":"Nogueira da Regedoura","state_id":"2908"},
    {"city_id":"32974","city_name":"Olival","state_id":"2908"},
    {"city_id":"32975","city_name":"Oliveira","state_id":"2908"},
    {"city_id":"32976","city_name":"Oliveira de Azemeis","state_id":"2908"},
    {"city_id":"32977","city_name":"Oliveira do Douro","state_id":"2908"},
    {"city_id":"32978","city_name":"Pacos de Brandao","state_id":"2908"},
    {"city_id":"32979","city_name":"Pacos de Ferreira","state_id":"2908"},
    {"city_id":"32980","city_name":"Pedroso","state_id":"2908"},
    {"city_id":"32981","city_name":"Pedroucos","state_id":"2908"},
    {"city_id":"32982","city_name":"Penafiel","state_id":"2908"},
    {"city_id":"32983","city_name":"Perafita","state_id":"2908"},
    {"city_id":"32984","city_name":"Perozinho","state_id":"2908"},
    {"city_id":"32985","city_name":"Peso da Regua","state_id":"2908"},
    {"city_id":"32986","city_name":"Ponte","state_id":"2908"},
    {"city_id":"32987","city_name":"Porto","state_id":"2908"},
    {"city_id":"32988","city_name":"Povoa de Lanhoso","state_id":"2908"},
    {"city_id":"32989","city_name":"Povoa de Varzim","state_id":"2908"},
    {"city_id":"32990","city_name":"Real","state_id":"2908"},
    {"city_id":"32991","city_name":"Rebordosa","state_id":"2908"},
    {"city_id":"32992","city_name":"Recarei","state_id":"2908"},
    {"city_id":"32993","city_name":"Refojos de Basto","state_id":"2908"},
    {"city_id":"32994","city_name":"Ribeirao","state_id":"2908"},
    {"city_id":"32995","city_name":"Rio Meao","state_id":"2908"},
    {"city_id":"32996","city_name":"Rio Tinto","state_id":"2908"},
    {"city_id":"32997","city_name":"Ronfe","state_id":"2908"},
    {"city_id":"32998","city_name":"Sande","state_id":"2908"},
    {"city_id":"32999","city_name":"Sandim","state_id":"2908"},
    {"city_id":"33000","city_name":"Santa Cruz do Bispo","state_id":"2908"},
    {"city_id":"33001","city_name":"Santa Maria de Lamas","state_id":"2908"},
    {"city_id":"33002","city_name":"Santiago da Riba-Ul","state_id":"2908"},
    {"city_id":"33003","city_name":"Santo Tirso","state_id":"2908"},
    {"city_id":"33004","city_name":"Sao Felix da Marinha","state_id":"2908"},
    {"city_id":"33005","city_name":"Sao Joao da Madeira","state_id":"2908"},
    {"city_id":"33006","city_name":"Sao Joao de Ver","state_id":"2908"},
    {"city_id":"33007","city_name":"Sao Mamede de Infesta","state_id":"2908"},
    {"city_id":"33008","city_name":"Sao Pedro da Cova","state_id":"2908"},
    {"city_id":"33009","city_name":"Sao Pedro de Casteloes","state_id":"2908"},
    {"city_id":"33010","city_name":"Sao Roque","state_id":"2908"},
    {"city_id":"33011","city_name":"Selho","state_id":"2908"},
    {"city_id":"33012","city_name":"Senhora da Hora","state_id":"2908"},
    {"city_id":"33013","city_name":"Seroa","state_id":"2908"},
    {"city_id":"33014","city_name":"Serzedelo","state_id":"2908"},
    {"city_id":"33015","city_name":"Serzedo","state_id":"2908"},
    {"city_id":"33016","city_name":"Silvalde","state_id":"2908"},
    {"city_id":"33017","city_name":"Sobrado","state_id":"2908"},
    {"city_id":"33018","city_name":"Sobreira","state_id":"2908"},
    {"city_id":"33019","city_name":"Souto","state_id":"2908"},
    {"city_id":"33020","city_name":"Teloes","state_id":"2908"},
    {"city_id":"33021","city_name":"Urgezes","state_id":"2908"},
    {"city_id":"33022","city_name":"Valadares","state_id":"2908"},
    {"city_id":"33023","city_name":"Valbom","state_id":"2908"},
    {"city_id":"33024","city_name":"Vale","state_id":"2908"},
    {"city_id":"33025","city_name":"Valongo","state_id":"2908"},
    {"city_id":"33026","city_name":"Valpacos","state_id":"2908"},
    {"city_id":"33027","city_name":"Viana do Castelo","state_id":"2908"},
    {"city_id":"33028","city_name":"Vila Cha","state_id":"2908"},
    {"city_id":"33029","city_name":"Vila Frescainha","state_id":"2908"},
    {"city_id":"33030","city_name":"Vila Nova da Telha","state_id":"2908"},
    {"city_id":"33031","city_name":"Vila Nova de Famalicao","state_id":"2908"},
    {"city_id":"33032","city_name":"Vila Nova de Gaia","state_id":"2908"},
    {"city_id":"33033","city_name":"Vila Praia de Ancora","state_id":"2908"},
    {"city_id":"33034","city_name":"Vila Real","state_id":"2908"},
    {"city_id":"33035","city_name":"Vila Verde","state_id":"2908"},
    {"city_id":"33036","city_name":"Vila de Cucujaes","state_id":"2908"},
    {"city_id":"33037","city_name":"Vila de Prado","state_id":"2908"},
    {"city_id":"33038","city_name":"Vila do Conde","state_id":"2908"},
    {"city_id":"33039","city_name":"Vilar de Andorinho","state_id":"2908"},
    {"city_id":"33040","city_name":"Vilar do Paraiso","state_id":"2908"},
    {"city_id":"33041","city_name":"Vilarinho","state_id":"2908"},
    {"city_id":"33042","city_name":"Vilela","state_id":"2908"},
    {"city_id":"33043","city_name":"Vizela","state_id":"2908"},
    {"city_id":"33044","city_name":"Paivas","state_id":"2909"},
    {"city_id":"33045","city_name":"Arecibo","state_id":"2910"},
    {"city_id":"33046","city_name":"Barceloneta","state_id":"2910"},
    {"city_id":"33047","city_name":"Camuy","state_id":"2910"},
    {"city_id":"33048","city_name":"Dorado","state_id":"2910"},
    {"city_id":"33049","city_name":"Isabela","state_id":"2910"},
    {"city_id":"33050","city_name":"Manati","state_id":"2910"},
    {"city_id":"33051","city_name":"Vega Alta","state_id":"2910"},
    {"city_id":"33052","city_name":"Vega Baja","state_id":"2910"},
    {"city_id":"33053","city_name":"Bayamon","state_id":"2911"},
    {"city_id":"33054","city_name":"Catano","state_id":"2911"},
    {"city_id":"33055","city_name":"Guaynabo","state_id":"2911"},
    {"city_id":"33056","city_name":"Levittown","state_id":"2911"},
    {"city_id":"33057","city_name":"Valencia","state_id":"2911"},
    {"city_id":"33058","city_name":"Canovanas","state_id":"2912"},
    {"city_id":"33059","city_name":"Carolina","state_id":"2912"},
    {"city_id":"33060","city_name":"Trujillo Alto","state_id":"2912"},
    {"city_id":"33061","city_name":"Florida","state_id":"2913"},
    {"city_id":"33062","city_name":"Aibonito","state_id":"2914"},
    {"city_id":"33063","city_name":"Arroyo","state_id":"2914"},
    {"city_id":"33064","city_name":"Barranquitas","state_id":"2914"},
    {"city_id":"33065","city_name":"Cayey","state_id":"2914"},
    {"city_id":"33066","city_name":"Coamo","state_id":"2914"},
    {"city_id":"33067","city_name":"Corozal","state_id":"2914"},
    {"city_id":"33068","city_name":"Guayama","state_id":"2914"},
    {"city_id":"33069","city_name":"Juana Diaz","state_id":"2914"},
    {"city_id":"33070","city_name":"Aguas Buenas","state_id":"2915"},
    {"city_id":"33071","city_name":"Caguas","state_id":"2915"},
    {"city_id":"33072","city_name":"Culebra","state_id":"2915"},
    {"city_id":"33073","city_name":"Fajardo","state_id":"2915"},
    {"city_id":"33074","city_name":"Gurabo","state_id":"2915"},
    {"city_id":"33075","city_name":"Humacao","state_id":"2915"},
    {"city_id":"33076","city_name":"Juncos","state_id":"2915"},
    {"city_id":"33077","city_name":"Rio Grande","state_id":"2915"},
    {"city_id":"33078","city_name":"Vieques","state_id":"2915"},
    {"city_id":"33079","city_name":"Aguada","state_id":"2916"},
    {"city_id":"33080","city_name":"Aguadilla","state_id":"2916"},
    {"city_id":"33081","city_name":"Anasco","state_id":"2916"},
    {"city_id":"33082","city_name":"Cabo Rojo","state_id":"2916"},
    {"city_id":"33083","city_name":"Hormigueros","state_id":"2916"},
    {"city_id":"33084","city_name":"Mayaguez","state_id":"2916"},
    {"city_id":"33085","city_name":"San German","state_id":"2916"},
    {"city_id":"33086","city_name":"San Sebastian","state_id":"2916"},
    {"city_id":"33087","city_name":"Adjuntas","state_id":"2917"},
    {"city_id":"33088","city_name":"Guanica","state_id":"2917"},
    {"city_id":"33089","city_name":"Ponce","state_id":"2917"},
    {"city_id":"33090","city_name":"Utuado","state_id":"2917"},
    {"city_id":"33091","city_name":"Yauco","state_id":"2917"},
    {"city_id":"33092","city_name":"Salinas","state_id":"2918"},
    {"city_id":"33093","city_name":"Doha","state_id":"2920"},
    {"city_id":"33094","city_name":"Umm Bab","state_id":"2921"},
    {"city_id":"33095","city_name":"ad-Dawhah","state_id":"2923"},
    {"city_id":"33096","city_name":"al-Ghuwayriyah","state_id":"2924"},
    {"city_id":"33097","city_name":"Dukhan","state_id":"2925"},
    {"city_id":"33098","city_name":"al-Jumayliyah","state_id":"2925"},
    {"city_id":"33099","city_name":"al-Khawr","state_id":"2926"},
    {"city_id":"33100","city_name":"Musay'id","state_id":"2927"},
    {"city_id":"33101","city_name":"al-Wakrah","state_id":"2927"},
    {"city_id":"33102","city_name":"al-Wukayr","state_id":"2927"},
    {"city_id":"33103","city_name":"ar-Rayyan","state_id":"2928"},
    {"city_id":"33104","city_name":"ash-Shahaniyah","state_id":"2928"},
    {"city_id":"33105","city_name":"ar-Ruways","state_id":"2929"},
    {"city_id":"33106","city_name":"Bras-Panon","state_id":"2930"},
    {"city_id":"33107","city_name":"Saint-Andre","state_id":"2930"},
    {"city_id":"33108","city_name":"Saint-Benoit","state_id":"2930"},
    {"city_id":"33109","city_name":"Sainte-Rose","state_id":"2930"},
    {"city_id":"33110","city_name":"Salazie","state_id":"2930"},
    {"city_id":"33111","city_name":"La Possession","state_id":"2931"},
    {"city_id":"33112","city_name":"Le Port","state_id":"2931"},
    {"city_id":"33113","city_name":"Saint-Denis","state_id":"2931"},
    {"city_id":"33114","city_name":"Sainte-Marie","state_id":"2931"},
    {"city_id":"33115","city_name":"Sainte-Suzanne","state_id":"2931"},
    {"city_id":"33116","city_name":"L'Etang-Sale","state_id":"2932"},
    {"city_id":"33117","city_name":"Les Aviron","state_id":"2932"},
    {"city_id":"33118","city_name":"Les Trois-Bassins","state_id":"2932"},
    {"city_id":"33119","city_name":"Saint-Leu","state_id":"2932"},
    {"city_id":"33120","city_name":"Saint-Paul","state_id":"2932"},
    {"city_id":"33121","city_name":"Cilaos","state_id":"2933"},
    {"city_id":"33122","city_name":"Entre-Deux","state_id":"2933"},
    {"city_id":"33123","city_name":"Le Tampon","state_id":"2933"},
    {"city_id":"33124","city_name":"Petite-Ile","state_id":"2933"},
    {"city_id":"33125","city_name":"Saint-Joseph","state_id":"2933"},
    {"city_id":"33126","city_name":"Saint-Louis","state_id":"2933"},
    {"city_id":"33127","city_name":"Saint-Philippe","state_id":"2933"},
    {"city_id":"33128","city_name":"Saint-Pierre","state_id":"2933"},
    {"city_id":"33129","city_name":"Abrud","state_id":"2934"},
    {"city_id":"33130","city_name":"Aiud","state_id":"2934"},
    {"city_id":"33131","city_name":"Alba Iulia","state_id":"2934"},
    {"city_id":"33132","city_name":"Albac","state_id":"2934"},
    {"city_id":"33133","city_name":"Almasu Mare","state_id":"2934"},
    {"city_id":"33134","city_name":"Arieseni","state_id":"2934"},
    {"city_id":"33135","city_name":"Avram Iancu","state_id":"2934"},
    {"city_id":"33136","city_name":"Baia de Aries","state_id":"2934"},
    {"city_id":"33137","city_name":"Berghin","state_id":"2934"},
    {"city_id":"33138","city_name":"Bistra","state_id":"2934"},
    {"city_id":"33139","city_name":"Blaj","state_id":"2934"},
    {"city_id":"33140","city_name":"Blandiana","state_id":"2934"},
    {"city_id":"33141","city_name":"Bucium","state_id":"2934"},
    {"city_id":"33142","city_name":"Calnic","state_id":"2934"},
    {"city_id":"33143","city_name":"Campeni","state_id":"2934"},
    {"city_id":"33144","city_name":"Cenade","state_id":"2934"},
    {"city_id":"33145","city_name":"Cergau","state_id":"2934"},
    {"city_id":"33146","city_name":"Ceru-Bacainti","state_id":"2934"},
    {"city_id":"33147","city_name":"Cetatea de Balta","state_id":"2934"},
    {"city_id":"33148","city_name":"Ciugud","state_id":"2934"},
    {"city_id":"33149","city_name":"Ciuruleasa","state_id":"2934"},
    {"city_id":"33150","city_name":"Craciunelu de Jos","state_id":"2934"},
    {"city_id":"33151","city_name":"Cricau","state_id":"2934"},
    {"city_id":"33152","city_name":"Cugir","state_id":"2934"},
    {"city_id":"33153","city_name":"Daia Romana","state_id":"2934"},
    {"city_id":"33154","city_name":"Dostat","state_id":"2934"},
    {"city_id":"33155","city_name":"Farau","state_id":"2934"},
    {"city_id":"33156","city_name":"Galda de Jos","state_id":"2934"},
    {"city_id":"33157","city_name":"Garbova","state_id":"2934"},
    {"city_id":"33158","city_name":"Garda de Sus","state_id":"2934"},
    {"city_id":"33159","city_name":"Hoparta","state_id":"2934"},
    {"city_id":"33160","city_name":"Horea","state_id":"2934"},
    {"city_id":"33161","city_name":"Ighiu","state_id":"2934"},
    {"city_id":"33162","city_name":"Intregalde","state_id":"2934"},
    {"city_id":"33163","city_name":"Jidvei","state_id":"2934"},
    {"city_id":"33164","city_name":"Livezile","state_id":"2934"},
    {"city_id":"33165","city_name":"Lopadea Noua","state_id":"2934"},
    {"city_id":"33166","city_name":"Lunca Muresului","state_id":"2934"},
    {"city_id":"33167","city_name":"Lupsa","state_id":"2934"},
    {"city_id":"33168","city_name":"Metes","state_id":"2934"},
    {"city_id":"33169","city_name":"Mihalt","state_id":"2934"},
    {"city_id":"33170","city_name":"Miraslau","state_id":"2934"},
    {"city_id":"33171","city_name":"Mogos","state_id":"2934"},
    {"city_id":"33172","city_name":"Noslac","state_id":"2934"},
    {"city_id":"33173","city_name":"Ocna Mures","state_id":"2934"},
    {"city_id":"33174","city_name":"Ocolis","state_id":"2934"},
    {"city_id":"33175","city_name":"Ohaba","state_id":"2934"},
    {"city_id":"33176","city_name":"Pianu","state_id":"2934"},
    {"city_id":"33177","city_name":"Poiana Vadului","state_id":"2934"},
    {"city_id":"33178","city_name":"Ponor","state_id":"2934"},
    {"city_id":"33179","city_name":"Posaga","state_id":"2934"},
    {"city_id":"33180","city_name":"Radesti","state_id":"2934"},
    {"city_id":"33181","city_name":"Ramet","state_id":"2934"},
    {"city_id":"33182","city_name":"Rimetea","state_id":"2934"},
    {"city_id":"33183","city_name":"Rosia Montana","state_id":"2934"},
    {"city_id":"33184","city_name":"Rosia de Secas","state_id":"2934"},
    {"city_id":"33185","city_name":"Salciua","state_id":"2934"},
    {"city_id":"33186","city_name":"Salistea","state_id":"2934"},
    {"city_id":"33187","city_name":"Sancel","state_id":"2934"},
    {"city_id":"33188","city_name":"Santimbru","state_id":"2934"},
    {"city_id":"33189","city_name":"Sasciori","state_id":"2934"},
    {"city_id":"33190","city_name":"Scarisoara","state_id":"2934"},
    {"city_id":"33191","city_name":"Sebes","state_id":"2934"},
    {"city_id":"33192","city_name":"Sibot","state_id":"2934"},
    {"city_id":"33193","city_name":"Sohodol","state_id":"2934"},
    {"city_id":"33194","city_name":"Sona","state_id":"2934"},
    {"city_id":"33195","city_name":"Spring","state_id":"2934"},
    {"city_id":"33196","city_name":"Stremt","state_id":"2934"},
    {"city_id":"33197","city_name":"Sugag","state_id":"2934"},
    {"city_id":"33198","city_name":"Teius","state_id":"2934"},
    {"city_id":"33199","city_name":"Unirea","state_id":"2934"},
    {"city_id":"33200","city_name":"Vadu Motilor","state_id":"2934"},
    {"city_id":"33201","city_name":"Valea Lunga","state_id":"2934"},
    {"city_id":"33202","city_name":"Vidra","state_id":"2934"},
    {"city_id":"33203","city_name":"Vintu de Jos","state_id":"2934"},
    {"city_id":"33204","city_name":"Zlatna","state_id":"2934"},
    {"city_id":"33205","city_name":"Almas","state_id":"2935"},
    {"city_id":"33206","city_name":"Apateu","state_id":"2935"},
    {"city_id":"33207","city_name":"Arad","state_id":"2935"},
    {"city_id":"33208","city_name":"Archis","state_id":"2935"},
    {"city_id":"33209","city_name":"Barsa","state_id":"2935"},
    {"city_id":"33210","city_name":"Barzava","state_id":"2935"},
    {"city_id":"33211","city_name":"Bata","state_id":"2935"},
    {"city_id":"33212","city_name":"Beliu","state_id":"2935"},
    {"city_id":"33213","city_name":"Birchis","state_id":"2935"},
    {"city_id":"33214","city_name":"Bocsig","state_id":"2935"},
    {"city_id":"33215","city_name":"Brazii","state_id":"2935"},
    {"city_id":"33216","city_name":"Buteni","state_id":"2935"},
    {"city_id":"33217","city_name":"Carand","state_id":"2935"},
    {"city_id":"33218","city_name":"Cermei","state_id":"2935"},
    {"city_id":"33219","city_name":"Chisindia","state_id":"2935"},
    {"city_id":"33220","city_name":"Chisineu Cris","state_id":"2935"},
    {"city_id":"33221","city_name":"Conop","state_id":"2935"},
    {"city_id":"33222","city_name":"Covasint","state_id":"2935"},
    {"city_id":"33223","city_name":"Craiva","state_id":"2935"},
    {"city_id":"33224","city_name":"Curtici","state_id":"2935"},
    {"city_id":"33225","city_name":"Dezna","state_id":"2935"},
    {"city_id":"33226","city_name":"Dieci","state_id":"2935"},
    {"city_id":"33227","city_name":"Dorgos","state_id":"2935"},
    {"city_id":"33228","city_name":"Fantanele","state_id":"2935"},
    {"city_id":"33229","city_name":"Felnac","state_id":"2935"},
    {"city_id":"33230","city_name":"Ghioroc","state_id":"2935"},
    {"city_id":"33231","city_name":"Graniceri","state_id":"2935"},
    {"city_id":"33232","city_name":"Gurahont","state_id":"2935"},
    {"city_id":"33233","city_name":"Halmagel","state_id":"2935"},
    {"city_id":"33234","city_name":"Halmagiu","state_id":"2935"},
    {"city_id":"33235","city_name":"Hasmas","state_id":"2935"},
    {"city_id":"33236","city_name":"Ignesti","state_id":"2935"},
    {"city_id":"33237","city_name":"Ineu","state_id":"2935"},
    {"city_id":"33238","city_name":"Iratosu","state_id":"2935"},
    {"city_id":"33239","city_name":"Lipova","state_id":"2935"},
    {"city_id":"33240","city_name":"Livada","state_id":"2935"},
    {"city_id":"33241","city_name":"Macea","state_id":"2935"},
    {"city_id":"33242","city_name":"Masca","state_id":"2935"},
    {"city_id":"33243","city_name":"Moneasa","state_id":"2935"},
    {"city_id":"33244","city_name":"Nadlac","state_id":"2935"},
    {"city_id":"33245","city_name":"Olari","state_id":"2935"},
    {"city_id":"33246","city_name":"Pancota","state_id":"2935"},
    {"city_id":"33247","city_name":"Paulis","state_id":"2935"},
    {"city_id":"33248","city_name":"Pecica","state_id":"2935"},
    {"city_id":"33249","city_name":"Peregu Mare","state_id":"2935"},
    {"city_id":"33250","city_name":"Petris","state_id":"2935"},
    {"city_id":"33251","city_name":"Pilu","state_id":"2935"},
    {"city_id":"33252","city_name":"Plescuta","state_id":"2935"},
    {"city_id":"33253","city_name":"Sagu","state_id":"2935"},
    {"city_id":"33254","city_name":"Santana","state_id":"2935"},
    {"city_id":"33255","city_name":"Savarsin","state_id":"2935"},
    {"city_id":"33256","city_name":"Sebis","state_id":"2935"},
    {"city_id":"33257","city_name":"Secusigiu","state_id":"2935"},
    {"city_id":"33258","city_name":"Seitin","state_id":"2935"},
    {"city_id":"33259","city_name":"Seleus","state_id":"2935"},
    {"city_id":"33260","city_name":"Semlac","state_id":"2935"},
    {"city_id":"33261","city_name":"Sepreus","state_id":"2935"},
    {"city_id":"33262","city_name":"Sicula","state_id":"2935"},
    {"city_id":"33263","city_name":"Silindia","state_id":"2935"},
    {"city_id":"33264","city_name":"Simand","state_id":"2935"},
    {"city_id":"33265","city_name":"Sintea Mare","state_id":"2935"},
    {"city_id":"33266","city_name":"Siria","state_id":"2935"},
    {"city_id":"33267","city_name":"Sistarovat","state_id":"2935"},
    {"city_id":"33268","city_name":"Socodor","state_id":"2935"},
    {"city_id":"33269","city_name":"Sofronea","state_id":"2935"},
    {"city_id":"33270","city_name":"Tarnova","state_id":"2935"},
    {"city_id":"33271","city_name":"Taut","state_id":"2935"},
    {"city_id":"33272","city_name":"Varadia de Mures","state_id":"2935"},
    {"city_id":"33273","city_name":"Varfurile","state_id":"2935"},
    {"city_id":"33274","city_name":"Vinga","state_id":"2935"},
    {"city_id":"33275","city_name":"Vladimirescu","state_id":"2935"},
    {"city_id":"33276","city_name":"Zabrani","state_id":"2935"},
    {"city_id":"33277","city_name":"Zarand","state_id":"2935"},
    {"city_id":"33278","city_name":"Zerind","state_id":"2935"},
    {"city_id":"33279","city_name":"Zimandu Nou","state_id":"2935"},
    {"city_id":"33280","city_name":"Albesti","state_id":"2936"},
    {"city_id":"33281","city_name":"Albestii-Pamanteni","state_id":"2936"},
    {"city_id":"33282","city_name":"Albota","state_id":"2936"},
    {"city_id":"33283","city_name":"Aninoasa","state_id":"2936"},
    {"city_id":"33284","city_name":"Arefu","state_id":"2936"},
    {"city_id":"33285","city_name":"Babana","state_id":"2936"},
    {"city_id":"33286","city_name":"Baiculesti","state_id":"2936"},
    {"city_id":"33287","city_name":"Balilesti","state_id":"2936"},
    {"city_id":"33288","city_name":"Barla","state_id":"2936"},
    {"city_id":"33289","city_name":"Bascov","state_id":"2936"},
    {"city_id":"33290","city_name":"Beleti-Negresti","state_id":"2936"},
    {"city_id":"33291","city_name":"Berevoesti","state_id":"2936"},
    {"city_id":"33292","city_name":"Bogati","state_id":"2936"},
    {"city_id":"33293","city_name":"Boteni","state_id":"2936"},
    {"city_id":"33294","city_name":"Botesti","state_id":"2936"},
    {"city_id":"33295","city_name":"Bradu","state_id":"2936"},
    {"city_id":"33296","city_name":"Bradulet","state_id":"2936"},
    {"city_id":"33297","city_name":"Budeasa","state_id":"2936"},
    {"city_id":"33298","city_name":"Bughea de Jos","state_id":"2936"},
    {"city_id":"33299","city_name":"Buzoesti","state_id":"2936"},
    {"city_id":"33300","city_name":"Caldararu","state_id":"2936"},
    {"city_id":"33301","city_name":"Calinesti","state_id":"2936"},
    {"city_id":"33302","city_name":"Campulung","state_id":"2936"},
    {"city_id":"33303","city_name":"Cateasca","state_id":"2936"},
    {"city_id":"33304","city_name":"Cepari","state_id":"2936"},
    {"city_id":"33305","city_name":"Cetateni","state_id":"2936"},
    {"city_id":"33306","city_name":"Cicanesti","state_id":"2936"},
    {"city_id":"33307","city_name":"Ciofrangeni","state_id":"2936"},
    {"city_id":"33308","city_name":"Ciomagesti","state_id":"2936"},
    {"city_id":"33309","city_name":"Cocu","state_id":"2936"},
    {"city_id":"33310","city_name":"Corbeni","state_id":"2936"},
    {"city_id":"33311","city_name":"Corbi","state_id":"2936"},
    {"city_id":"33312","city_name":"Cosesti","state_id":"2936"},
    {"city_id":"33313","city_name":"Costesti","state_id":"2936"},
    {"city_id":"33314","city_name":"Cotmeana","state_id":"2936"},
    {"city_id":"33315","city_name":"Cuca","state_id":"2936"},
    {"city_id":"33316","city_name":"Curtea de Arges","state_id":"2936"},
    {"city_id":"33317","city_name":"Dambovicioara","state_id":"2936"},
    {"city_id":"33318","city_name":"Darmanesti","state_id":"2936"},
    {"city_id":"33319","city_name":"Davidesti","state_id":"2936"},
    {"city_id":"33320","city_name":"Dobresti","state_id":"2936"},
    {"city_id":"33321","city_name":"Domnesti","state_id":"2936"},
    {"city_id":"33322","city_name":"Draganu","state_id":"2936"},
    {"city_id":"33323","city_name":"Dragoslavele","state_id":"2936"},
    {"city_id":"33324","city_name":"Godeni","state_id":"2936"},
    {"city_id":"33325","city_name":"Harsesti","state_id":"2936"},
    {"city_id":"33326","city_name":"Hartiesti","state_id":"2936"},
    {"city_id":"33327","city_name":"Izvoru","state_id":"2936"},
    {"city_id":"33328","city_name":"Leordeni","state_id":"2936"},
    {"city_id":"33329","city_name":"Leresti","state_id":"2936"},
    {"city_id":"33330","city_name":"Lunca Corbului","state_id":"2936"},
    {"city_id":"33331","city_name":"Malureni","state_id":"2936"},
    {"city_id":"33332","city_name":"Maracineni","state_id":"2936"},
    {"city_id":"33333","city_name":"Merisani","state_id":"2936"},
    {"city_id":"33334","city_name":"Micesti","state_id":"2936"},
    {"city_id":"33335","city_name":"Mihaesti","state_id":"2936"},
    {"city_id":"33336","city_name":"Mioarele","state_id":"2936"},
    {"city_id":"33337","city_name":"Mioveni","state_id":"2936"},
    {"city_id":"33338","city_name":"Mirosi","state_id":"2936"},
    {"city_id":"33339","city_name":"Moraresti","state_id":"2936"},
    {"city_id":"33340","city_name":"Mosoaia","state_id":"2936"},
    {"city_id":"33341","city_name":"Mozaceni","state_id":"2936"},
    {"city_id":"33342","city_name":"Musatesti","state_id":"2936"},
    {"city_id":"33343","city_name":"Negrasi","state_id":"2936"},
    {"city_id":"33344","city_name":"Nucsoara","state_id":"2936"},
    {"city_id":"33345","city_name":"Oarja","state_id":"2936"},
    {"city_id":"33346","city_name":"Pietrosani","state_id":"2936"},
    {"city_id":"33347","city_name":"Pitesti","state_id":"2936"},
    {"city_id":"33348","city_name":"Poenari","state_id":"2936"},
    {"city_id":"33349","city_name":"Poiana Lacului","state_id":"2936"},
    {"city_id":"33350","city_name":"Popesti","state_id":"2936"},
    {"city_id":"33351","city_name":"Priboieni","state_id":"2936"},
    {"city_id":"33352","city_name":"Ratesti","state_id":"2936"},
    {"city_id":"33353","city_name":"Recea","state_id":"2936"},
    {"city_id":"33354","city_name":"Rociu","state_id":"2936"},
    {"city_id":"33355","city_name":"Rucar","state_id":"2936"},
    {"city_id":"33356","city_name":"Salatrucu","state_id":"2936"},
    {"city_id":"33357","city_name":"Sapata","state_id":"2936"},
    {"city_id":"33358","city_name":"Schitu-Golesti","state_id":"2936"},
    {"city_id":"33359","city_name":"Slobozia","state_id":"2936"},
    {"city_id":"33360","city_name":"Stalpeni","state_id":"2936"},
    {"city_id":"33361","city_name":"Stefan cel Mare","state_id":"2936"},
    {"city_id":"33362","city_name":"Stefanesti","state_id":"2936"},
    {"city_id":"33363","city_name":"Stoenesti","state_id":"2936"},
    {"city_id":"33364","city_name":"Stolnici","state_id":"2936"},
    {"city_id":"33365","city_name":"Suici","state_id":"2936"},
    {"city_id":"33366","city_name":"Suseni","state_id":"2936"},
    {"city_id":"33367","city_name":"Teiu","state_id":"2936"},
    {"city_id":"33368","city_name":"Tigveni","state_id":"2936"},
    {"city_id":"33369","city_name":"Titesti","state_id":"2936"},
    {"city_id":"33370","city_name":"Topoloveni","state_id":"2936"},
    {"city_id":"33371","city_name":"Uda","state_id":"2936"},
    {"city_id":"33372","city_name":"Ungheni","state_id":"2936"},
    {"city_id":"33373","city_name":"Valea Danului","state_id":"2936"},
    {"city_id":"33374","city_name":"Valea Iasului","state_id":"2936"},
    {"city_id":"33375","city_name":"Valea Mare-Pravat","state_id":"2936"},
    {"city_id":"33376","city_name":"Vedea","state_id":"2936"},
    {"city_id":"33377","city_name":"Vladesti","state_id":"2936"},
    {"city_id":"33378","city_name":"Agas","state_id":"2937"},
    {"city_id":"33379","city_name":"Ardeoani","state_id":"2937"},
    {"city_id":"33380","city_name":"Asau","state_id":"2937"},
    {"city_id":"33381","city_name":"Bacau","state_id":"2937"},
    {"city_id":"33382","city_name":"Balcani","state_id":"2937"},
    {"city_id":"33383","city_name":"Barsanesti","state_id":"2937"},
    {"city_id":"33384","city_name":"Beresti-Bistrita","state_id":"2937"},
    {"city_id":"33385","city_name":"Beresti-Tazlau","state_id":"2937"},
    {"city_id":"33386","city_name":"Berzunti","state_id":"2937"},
    {"city_id":"33387","city_name":"Blagesti","state_id":"2937"},
    {"city_id":"33388","city_name":"Bogdanesti","state_id":"2937"},
    {"city_id":"33389","city_name":"Brusturoasa","state_id":"2937"},
    {"city_id":"33390","city_name":"Buhoci","state_id":"2937"},
    {"city_id":"33391","city_name":"Buhusi","state_id":"2937"},
    {"city_id":"33392","city_name":"Caiuti","state_id":"2937"},
    {"city_id":"33393","city_name":"Casin","state_id":"2937"},
    {"city_id":"33394","city_name":"Cleja","state_id":"2937"},
    {"city_id":"33395","city_name":"Colonesti","state_id":"2937"},
    {"city_id":"33396","city_name":"Comanesti","state_id":"2937"},
    {"city_id":"33397","city_name":"Corbasca","state_id":"2937"},
    {"city_id":"33398","city_name":"Cotofanesti","state_id":"2937"},
    {"city_id":"33399","city_name":"Damienesti","state_id":"2937"},
    {"city_id":"33400","city_name":"Darmanesti","state_id":"2937"},
    {"city_id":"33401","city_name":"Dealu Morii","state_id":"2937"},
    {"city_id":"33402","city_name":"Dofteana","state_id":"2937"},
    {"city_id":"33403","city_name":"Faraoani","state_id":"2937"},
    {"city_id":"33404","city_name":"Filipeni","state_id":"2937"},
    {"city_id":"33405","city_name":"Filipesti","state_id":"2937"},
    {"city_id":"33406","city_name":"Gaiceana","state_id":"2937"},
    {"city_id":"33407","city_name":"Garleni","state_id":"2937"},
    {"city_id":"33408","city_name":"Ghimes-Faget","state_id":"2937"},
    {"city_id":"33409","city_name":"Glavanesti","state_id":"2937"},
    {"city_id":"33410","city_name":"Gura Vaii","state_id":"2937"},
    {"city_id":"33411","city_name":"Helegiu","state_id":"2937"},
    {"city_id":"33412","city_name":"Hemeius","state_id":"2937"},
    {"city_id":"33413","city_name":"Horgesti","state_id":"2937"},
    {"city_id":"33414","city_name":"Huruesti","state_id":"2937"},
    {"city_id":"33415","city_name":"Izvoru Berheciului","state_id":"2937"},
    {"city_id":"33416","city_name":"Letea Veche","state_id":"2937"},
    {"city_id":"33417","city_name":"Lipova","state_id":"2937"},
    {"city_id":"33418","city_name":"Livezi","state_id":"2937"},
    {"city_id":"33419","city_name":"Luizi-Calugara","state_id":"2937"},
    {"city_id":"33420","city_name":"Magiresti","state_id":"2937"},
    {"city_id":"33421","city_name":"Magura","state_id":"2937"},
    {"city_id":"33422","city_name":"Manastirea Casin","state_id":"2937"},
    {"city_id":"33423","city_name":"Margineni","state_id":"2937"},
    {"city_id":"33424","city_name":"Moinesti","state_id":"2937"},
    {"city_id":"33425","city_name":"Motoseni","state_id":"2937"},
    {"city_id":"33426","city_name":"Negri","state_id":"2937"},
    {"city_id":"33427","city_name":"Nicolae Balcescu","state_id":"2937"},
    {"city_id":"33428","city_name":"Oituz","state_id":"2937"},
    {"city_id":"33429","city_name":"Oncesti","state_id":"2937"},
    {"city_id":"33430","city_name":"Onesti","state_id":"2937"},
    {"city_id":"33431","city_name":"Orbeni","state_id":"2937"},
    {"city_id":"33432","city_name":"Palanca","state_id":"2937"},
    {"city_id":"33433","city_name":"Pancesti","state_id":"2937"},
    {"city_id":"33434","city_name":"Parava","state_id":"2937"},
    {"city_id":"33435","city_name":"Pargaresti","state_id":"2937"},
    {"city_id":"33436","city_name":"Parincea","state_id":"2937"},
    {"city_id":"33437","city_name":"Parjol","state_id":"2937"},
    {"city_id":"33438","city_name":"Plopana","state_id":"2937"},
    {"city_id":"33439","city_name":"Podu Turcului","state_id":"2937"},
    {"city_id":"33440","city_name":"Poduri","state_id":"2937"},
    {"city_id":"33441","city_name":"Racaciuni","state_id":"2937"},
    {"city_id":"33442","city_name":"Rachitoasa","state_id":"2937"},
    {"city_id":"33443","city_name":"Racova","state_id":"2937"},
    {"city_id":"33444","city_name":"Rosiori","state_id":"2937"},
    {"city_id":"33445","city_name":"Sanduleni","state_id":"2937"},
    {"city_id":"33446","city_name":"Sascut","state_id":"2937"},
    {"city_id":"33447","city_name":"Saucesti","state_id":"2937"},
    {"city_id":"33448","city_name":"Scorteni","state_id":"2937"},
    {"city_id":"33449","city_name":"Secuieni","state_id":"2937"},
    {"city_id":"33450","city_name":"Slanic-Moldova","state_id":"2937"},
    {"city_id":"33451","city_name":"Solont","state_id":"2937"},
    {"city_id":"33452","city_name":"Stanisesti","state_id":"2937"},
    {"city_id":"33453","city_name":"Stefan cel Mare","state_id":"2937"},
    {"city_id":"33454","city_name":"Strugari","state_id":"2937"},
    {"city_id":"33455","city_name":"Tamasi","state_id":"2937"},
    {"city_id":"33456","city_name":"Targu Ocna","state_id":"2937"},
    {"city_id":"33457","city_name":"Targu-Trotus","state_id":"2937"},
    {"city_id":"33458","city_name":"Tatarasti","state_id":"2937"},
    {"city_id":"33459","city_name":"Traian","state_id":"2937"},
    {"city_id":"33460","city_name":"Ungureni","state_id":"2937"},
    {"city_id":"33461","city_name":"Urechesti","state_id":"2937"},
    {"city_id":"33462","city_name":"Valea Seaca","state_id":"2937"},
    {"city_id":"33463","city_name":"Vultureni","state_id":"2937"},
    {"city_id":"33464","city_name":"Zemes","state_id":"2937"},
    {"city_id":"33465","city_name":"Abram","state_id":"2938"},
    {"city_id":"33466","city_name":"Abramut","state_id":"2938"},
    {"city_id":"33467","city_name":"Alesd","state_id":"2938"},
    {"city_id":"33468","city_name":"Astileu","state_id":"2938"},
    {"city_id":"33469","city_name":"Auseu","state_id":"2938"},
    {"city_id":"33470","city_name":"Avram Iancu","state_id":"2938"},
    {"city_id":"33471","city_name":"Balc","state_id":"2938"},
    {"city_id":"33472","city_name":"Batar","state_id":"2938"},
    {"city_id":"33473","city_name":"Beius","state_id":"2938"},
    {"city_id":"33474","city_name":"Biharia","state_id":"2938"},
    {"city_id":"33475","city_name":"Boianu Mare","state_id":"2938"},
    {"city_id":"33476","city_name":"Borod","state_id":"2938"},
    {"city_id":"33477","city_name":"Bors","state_id":"2938"},
    {"city_id":"33478","city_name":"Bratca","state_id":"2938"},
    {"city_id":"33479","city_name":"Brusturi","state_id":"2938"},
    {"city_id":"33480","city_name":"Budureasa","state_id":"2938"},
    {"city_id":"33481","city_name":"Buduslau","state_id":"2938"},
    {"city_id":"33482","city_name":"Bulz","state_id":"2938"},
    {"city_id":"33483","city_name":"Buntesti","state_id":"2938"},
    {"city_id":"33484","city_name":"Cabesti","state_id":"2938"},
    {"city_id":"33485","city_name":"Campani","state_id":"2938"},
    {"city_id":"33486","city_name":"Capalna","state_id":"2938"},
    {"city_id":"33487","city_name":"Carpinet","state_id":"2938"},
    {"city_id":"33488","city_name":"Cefa","state_id":"2938"},
    {"city_id":"33489","city_name":"Ceica","state_id":"2938"},
    {"city_id":"33490","city_name":"Cetariu","state_id":"2938"},
    {"city_id":"33491","city_name":"Cherechiu","state_id":"2938"},
    {"city_id":"33492","city_name":"Chislaz","state_id":"2938"},
    {"city_id":"33493","city_name":"Ciuhoiu","state_id":"2938"},
    {"city_id":"33494","city_name":"Ciumeghiu","state_id":"2938"},
    {"city_id":"33495","city_name":"Cociuba Mare","state_id":"2938"},
    {"city_id":"33496","city_name":"Copacel","state_id":"2938"},
    {"city_id":"33497","city_name":"Cristioru de Jos","state_id":"2938"},
    {"city_id":"33498","city_name":"Curatele","state_id":"2938"},
    {"city_id":"33499","city_name":"Curtuiseni","state_id":"2938"},
    {"city_id":"33500","city_name":"Derna","state_id":"2938"},
    {"city_id":"33501","city_name":"Diosig","state_id":"2938"},
    {"city_id":"33502","city_name":"Dobresti","state_id":"2938"},
    {"city_id":"33503","city_name":"Draganesti","state_id":"2938"},
    {"city_id":"33504","city_name":"Dragesti","state_id":"2938"},
    {"city_id":"33505","city_name":"Finis","state_id":"2938"},
    {"city_id":"33506","city_name":"Girisu de Cris","state_id":"2938"},
    {"city_id":"33507","city_name":"Hidiselu de Sus","state_id":"2938"},
    {"city_id":"33508","city_name":"Holod","state_id":"2938"},
    {"city_id":"33509","city_name":"Husasau de Tinca","state_id":"2938"},
    {"city_id":"33510","city_name":"Ineu","state_id":"2938"},
    {"city_id":"33511","city_name":"Lazareni","state_id":"2938"},
    {"city_id":"33512","city_name":"Lazuri de Beius","state_id":"2938"},
    {"city_id":"33513","city_name":"Lugasu de Jos","state_id":"2938"},
    {"city_id":"33514","city_name":"Lunca","state_id":"2938"},
    {"city_id":"33515","city_name":"Madaras","state_id":"2938"},
    {"city_id":"33516","city_name":"Magesti","state_id":"2938"},
    {"city_id":"33517","city_name":"Marghita","state_id":"2938"},
    {"city_id":"33518","city_name":"Nojorid","state_id":"2938"},
    {"city_id":"33519","city_name":"Nucet","state_id":"2938"},
    {"city_id":"33520","city_name":"Olcea","state_id":"2938"},
    {"city_id":"33521","city_name":"Oradea","state_id":"2938"},
    {"city_id":"33522","city_name":"Osorheiu","state_id":"2938"},
    {"city_id":"33523","city_name":"Pietroasa","state_id":"2938"},
    {"city_id":"33524","city_name":"Pocola","state_id":"2938"},
    {"city_id":"33525","city_name":"Pomezeu","state_id":"2938"},
    {"city_id":"33526","city_name":"Popesti","state_id":"2938"},
    {"city_id":"33527","city_name":"Rabagani","state_id":"2938"},
    {"city_id":"33528","city_name":"Remetea","state_id":"2938"},
    {"city_id":"33529","city_name":"Rieni","state_id":"2938"},
    {"city_id":"33530","city_name":"Rosia","state_id":"2938"},
    {"city_id":"33531","city_name":"Sacadat","state_id":"2938"},
    {"city_id":"33532","city_name":"Sacueni","state_id":"2938"},
    {"city_id":"33533","city_name":"Salacea","state_id":"2938"},
    {"city_id":"33534","city_name":"Salard","state_id":"2938"},
    {"city_id":"33535","city_name":"Salonta","state_id":"2938"},
    {"city_id":"33536","city_name":"Sambata","state_id":"2938"},
    {"city_id":"33537","city_name":"Sanmartin","state_id":"2938"},
    {"city_id":"33538","city_name":"Santandrei","state_id":"2938"},
    {"city_id":"33539","city_name":"Sarbi","state_id":"2938"},
    {"city_id":"33540","city_name":"Simian","state_id":"2938"},
    {"city_id":"33541","city_name":"Sinteu","state_id":"2938"},
    {"city_id":"33542","city_name":"Soimi","state_id":"2938"},
    {"city_id":"33543","city_name":"Spinus","state_id":"2938"},
    {"city_id":"33544","city_name":"Stei","state_id":"2938"},
    {"city_id":"33545","city_name":"Suncuius","state_id":"2938"},
    {"city_id":"33546","city_name":"Suplacu de Barcau","state_id":"2938"},
    {"city_id":"33547","city_name":"Tarcaia","state_id":"2938"},
    {"city_id":"33548","city_name":"Tarcea","state_id":"2938"},
    {"city_id":"33549","city_name":"Tauteu","state_id":"2938"},
    {"city_id":"33550","city_name":"Tetchea","state_id":"2938"},
    {"city_id":"33551","city_name":"Tileagd","state_id":"2938"},
    {"city_id":"33552","city_name":"Tinca","state_id":"2938"},
    {"city_id":"33553","city_name":"Tulca","state_id":"2938"},
    {"city_id":"33554","city_name":"Uileacu de Beius","state_id":"2938"},
    {"city_id":"33555","city_name":"Vadu Crisului","state_id":"2938"},
    {"city_id":"33556","city_name":"Valea lui Mihai","state_id":"2938"},
    {"city_id":"33557","city_name":"Varciorog","state_id":"2938"},
    {"city_id":"33558","city_name":"Vascau","state_id":"2938"},
    {"city_id":"33559","city_name":"Viisoara","state_id":"2938"},
    {"city_id":"33560","city_name":"Beclean","state_id":"2939"},
    {"city_id":"33561","city_name":"Bistrita","state_id":"2939"},
    {"city_id":"33562","city_name":"Bistrita Bargaului","state_id":"2939"},
    {"city_id":"33563","city_name":"Branistea","state_id":"2939"},
    {"city_id":"33564","city_name":"Budacu de Jos","state_id":"2939"},
    {"city_id":"33565","city_name":"Budesti","state_id":"2939"},
    {"city_id":"33566","city_name":"Caianu Mic","state_id":"2939"},
    {"city_id":"33567","city_name":"Cetate","state_id":"2939"},
    {"city_id":"33568","city_name":"Chiochis","state_id":"2939"},
    {"city_id":"33569","city_name":"Chiuza","state_id":"2939"},
    {"city_id":"33570","city_name":"Ciceu-Giurgesti","state_id":"2939"},
    {"city_id":"33571","city_name":"Cosbuc","state_id":"2939"},
    {"city_id":"33572","city_name":"Dumitra","state_id":"2939"},
    {"city_id":"33573","city_name":"Feldru","state_id":"2939"},
    {"city_id":"33574","city_name":"Galatii Bistritei","state_id":"2939"},
    {"city_id":"33575","city_name":"Ilva Mare","state_id":"2939"},
    {"city_id":"33576","city_name":"Ilva Mica","state_id":"2939"},
    {"city_id":"33577","city_name":"Josenii Bargaului","state_id":"2939"},
    {"city_id":"33578","city_name":"Lechinta","state_id":"2939"},
    {"city_id":"33579","city_name":"Lesu","state_id":"2939"},
    {"city_id":"33580","city_name":"Livezile","state_id":"2939"},
    {"city_id":"33581","city_name":"Lunca Ilvei","state_id":"2939"},
    {"city_id":"33582","city_name":"Magura Ilvei","state_id":"2939"},
    {"city_id":"33583","city_name":"Maieru","state_id":"2939"},
    {"city_id":"33584","city_name":"Mariselu","state_id":"2939"},
    {"city_id":"33585","city_name":"Matei","state_id":"2939"},
    {"city_id":"33586","city_name":"Micestii de Campie","state_id":"2939"},
    {"city_id":"33587","city_name":"Milas","state_id":"2939"},
    {"city_id":"33588","city_name":"Monor","state_id":"2939"},
    {"city_id":"33589","city_name":"Nasaud","state_id":"2939"},
    {"city_id":"33590","city_name":"Nimigea","state_id":"2939"},
    {"city_id":"33591","city_name":"Nuseni","state_id":"2939"},
    {"city_id":"33592","city_name":"Parva","state_id":"2939"},
    {"city_id":"33593","city_name":"Petru Rares","state_id":"2939"},
    {"city_id":"33594","city_name":"Prundu Bargaului","state_id":"2939"},
    {"city_id":"33595","city_name":"Rebra","state_id":"2939"},
    {"city_id":"33596","city_name":"Rebrisoara","state_id":"2939"},
    {"city_id":"33597","city_name":"Rodna","state_id":"2939"},
    {"city_id":"33598","city_name":"Romuli","state_id":"2939"},
    {"city_id":"33599","city_name":"Salva","state_id":"2939"},
    {"city_id":"33600","city_name":"Sangeorz-Bai","state_id":"2939"},
    {"city_id":"33601","city_name":"Sanmihaiu de Campie","state_id":"2939"},
    {"city_id":"33602","city_name":"Sant","state_id":"2939"},
    {"city_id":"33603","city_name":"Sieu","state_id":"2939"},
    {"city_id":"33604","city_name":"Sieu-Magherus","state_id":"2939"},
    {"city_id":"33605","city_name":"Sieu-Odorhei","state_id":"2939"},
    {"city_id":"33606","city_name":"Sieut","state_id":"2939"},
    {"city_id":"33607","city_name":"Silvasu de Campie","state_id":"2939"},
    {"city_id":"33608","city_name":"Sintereag","state_id":"2939"},
    {"city_id":"33609","city_name":"Spermezeu","state_id":"2939"},
    {"city_id":"33610","city_name":"Tarlisua","state_id":"2939"},
    {"city_id":"33611","city_name":"Teaca","state_id":"2939"},
    {"city_id":"33612","city_name":"Telciu","state_id":"2939"},
    {"city_id":"33613","city_name":"Tiha Bargaului","state_id":"2939"},
    {"city_id":"33614","city_name":"Uriu","state_id":"2939"},
    {"city_id":"33615","city_name":"Urmenis","state_id":"2939"},
    {"city_id":"33616","city_name":"Zagra","state_id":"2939"},
    {"city_id":"33617","city_name":"Albesti","state_id":"2940"},
    {"city_id":"33618","city_name":"Avrameni","state_id":"2940"},
    {"city_id":"33619","city_name":"Baluseni","state_id":"2940"},
    {"city_id":"33620","city_name":"Botosani","state_id":"2940"},
    {"city_id":"33621","city_name":"Braesti","state_id":"2940"},
    {"city_id":"33622","city_name":"Broscauti","state_id":"2940"},
    {"city_id":"33623","city_name":"Bucecea","state_id":"2940"},
    {"city_id":"33624","city_name":"Calarasi","state_id":"2940"},
    {"city_id":"33625","city_name":"Concesti","state_id":"2940"},
    {"city_id":"33626","city_name":"Copalau","state_id":"2940"},
    {"city_id":"33627","city_name":"Cordareni","state_id":"2940"},
    {"city_id":"33628","city_name":"Corlateni","state_id":"2940"},
    {"city_id":"33629","city_name":"Corni","state_id":"2940"},
    {"city_id":"33630","city_name":"Cotusca","state_id":"2940"},
    {"city_id":"33631","city_name":"Cristesti","state_id":"2940"},
    {"city_id":"33632","city_name":"Cristinesti","state_id":"2940"},
    {"city_id":"33633","city_name":"Curtesti","state_id":"2940"},
    {"city_id":"33634","city_name":"Dangeni","state_id":"2940"},
    {"city_id":"33635","city_name":"Darabani","state_id":"2940"},
    {"city_id":"33636","city_name":"Dersca","state_id":"2940"},
    {"city_id":"33637","city_name":"Dobarceni","state_id":"2940"},
    {"city_id":"33638","city_name":"Dorohoi","state_id":"2940"},
    {"city_id":"33639","city_name":"Draguseni","state_id":"2940"},
    {"city_id":"33640","city_name":"Durnesti","state_id":"2940"},
    {"city_id":"33641","city_name":"Flamanzi","state_id":"2940"},
    {"city_id":"33642","city_name":"Frumusica","state_id":"2940"},
    {"city_id":"33643","city_name":"George Enescu","state_id":"2940"},
    {"city_id":"33644","city_name":"Gorbanesti","state_id":"2940"},
    {"city_id":"33645","city_name":"Hanesti","state_id":"2940"},
    {"city_id":"33646","city_name":"Havarna","state_id":"2940"},
    {"city_id":"33647","city_name":"Hiliseu-Horia","state_id":"2940"},
    {"city_id":"33648","city_name":"Hlipiceni","state_id":"2940"},
    {"city_id":"33649","city_name":"Hudesti","state_id":"2940"},
    {"city_id":"33650","city_name":"Ibanesti","state_id":"2940"},
    {"city_id":"33651","city_name":"Leorda","state_id":"2940"},
    {"city_id":"33652","city_name":"Lunca","state_id":"2940"},
    {"city_id":"33653","city_name":"Manoleasa","state_id":"2940"},
    {"city_id":"33654","city_name":"Mihail Eminescu","state_id":"2940"},
    {"city_id":"33655","city_name":"Mihaileni","state_id":"2940"},
    {"city_id":"33656","city_name":"Mihalaseni","state_id":"2940"},
    {"city_id":"33657","city_name":"Mileanca","state_id":"2940"},
    {"city_id":"33658","city_name":"Mitoc","state_id":"2940"},
    {"city_id":"33659","city_name":"Nicseni","state_id":"2940"},
    {"city_id":"33660","city_name":"Paltinis","state_id":"2940"},
    {"city_id":"33661","city_name":"Pomarla","state_id":"2940"},
    {"city_id":"33662","city_name":"Prajeni","state_id":"2940"},
    {"city_id":"33663","city_name":"Rachiti","state_id":"2940"},
    {"city_id":"33664","city_name":"Radauti-Prut","state_id":"2940"},
    {"city_id":"33665","city_name":"Rauseni","state_id":"2940"},
    {"city_id":"33666","city_name":"Ripiceni","state_id":"2940"},
    {"city_id":"33667","city_name":"Roma","state_id":"2940"},
    {"city_id":"33668","city_name":"Romanesti","state_id":"2940"},
    {"city_id":"33669","city_name":"Santa-Maria","state_id":"2940"},
    {"city_id":"33670","city_name":"Saveni","state_id":"2940"},
    {"city_id":"33671","city_name":"Sendriceni","state_id":"2940"},
    {"city_id":"33672","city_name":"Stauceni","state_id":"2940"},
    {"city_id":"33673","city_name":"Stefanesti","state_id":"2940"},
    {"city_id":"33674","city_name":"Stiubieni","state_id":"2940"},
    {"city_id":"33675","city_name":"Suharau","state_id":"2940"},
    {"city_id":"33676","city_name":"Sulita","state_id":"2940"},
    {"city_id":"33677","city_name":"Todireni","state_id":"2940"},
    {"city_id":"33678","city_name":"Trusesti","state_id":"2940"},
    {"city_id":"33679","city_name":"Tudora","state_id":"2940"},
    {"city_id":"33680","city_name":"Ungureni","state_id":"2940"},
    {"city_id":"33681","city_name":"Unteni","state_id":"2940"},
    {"city_id":"33682","city_name":"Vaculesti","state_id":"2940"},
    {"city_id":"33683","city_name":"Varfu Campului","state_id":"2940"},
    {"city_id":"33684","city_name":"Viisoara","state_id":"2940"},
    {"city_id":"33685","city_name":"Vladeni","state_id":"2940"},
    {"city_id":"33686","city_name":"Vlasinesti","state_id":"2940"},
    {"city_id":"33687","city_name":"Vorniceni","state_id":"2940"},
    {"city_id":"33688","city_name":"Vorona","state_id":"2940"},
    {"city_id":"33689","city_name":"Baraganul","state_id":"2941"},
    {"city_id":"33690","city_name":"Bertestii de Jos","state_id":"2941"},
    {"city_id":"33691","city_name":"Bordei Verde","state_id":"2941"},
    {"city_id":"33692","city_name":"Braila","state_id":"2941"},
    {"city_id":"33693","city_name":"Chiscani","state_id":"2941"},
    {"city_id":"33694","city_name":"Ciocile","state_id":"2941"},
    {"city_id":"33695","city_name":"Ciresu","state_id":"2941"},
    {"city_id":"33696","city_name":"Dudesti","state_id":"2941"},
    {"city_id":"33697","city_name":"Faurei","state_id":"2941"},
    {"city_id":"33698","city_name":"Frecatei","state_id":"2941"},
    {"city_id":"33699","city_name":"Galbenu","state_id":"2941"},
    {"city_id":"33700","city_name":"Gemenele","state_id":"2941"},
    {"city_id":"33701","city_name":"Gradistea","state_id":"2941"},
    {"city_id":"33702","city_name":"Gropeni","state_id":"2941"},
    {"city_id":"33703","city_name":"Ianca","state_id":"2941"},
    {"city_id":"33704","city_name":"Insuratei","state_id":"2941"},
    {"city_id":"33705","city_name":"Jirlau","state_id":"2941"},
    {"city_id":"33706","city_name":"Marasu","state_id":"2941"},
    {"city_id":"33707","city_name":"Maxineni","state_id":"2941"},
    {"city_id":"33708","city_name":"Mircea Voda","state_id":"2941"},
    {"city_id":"33709","city_name":"Movila Miresei","state_id":"2941"},
    {"city_id":"33710","city_name":"Racovita","state_id":"2941"},
    {"city_id":"33711","city_name":"Ramnicelu","state_id":"2941"},
    {"city_id":"33712","city_name":"Romanu","state_id":"2941"},
    {"city_id":"33713","city_name":"Rosiori","state_id":"2941"},
    {"city_id":"33714","city_name":"Salcia Tudor","state_id":"2941"},
    {"city_id":"33715","city_name":"Scortaru Nou","state_id":"2941"},
    {"city_id":"33716","city_name":"Silistea","state_id":"2941"},
    {"city_id":"33717","city_name":"Stancuta","state_id":"2941"},
    {"city_id":"33718","city_name":"Surdila-Gaiseanca","state_id":"2941"},
    {"city_id":"33719","city_name":"Surdila-Greci","state_id":"2941"},
    {"city_id":"33720","city_name":"Sutesti","state_id":"2941"},
    {"city_id":"33721","city_name":"Tichilesti","state_id":"2941"},
    {"city_id":"33722","city_name":"Traian","state_id":"2941"},
    {"city_id":"33723","city_name":"Tudor Vladimirescu","state_id":"2941"},
    {"city_id":"33724","city_name":"Tufesti","state_id":"2941"},
    {"city_id":"33725","city_name":"Ulmu","state_id":"2941"},
    {"city_id":"33726","city_name":"Unirea","state_id":"2941"},
    {"city_id":"33727","city_name":"Vadeni","state_id":"2941"},
    {"city_id":"33728","city_name":"Victoria","state_id":"2941"},
    {"city_id":"33729","city_name":"Visani","state_id":"2941"},
    {"city_id":"33730","city_name":"Viziru","state_id":"2941"},
    {"city_id":"33731","city_name":"Zavoaia","state_id":"2941"},
    {"city_id":"33732","city_name":"Apata","state_id":"2942"},
    {"city_id":"33733","city_name":"Beclean","state_id":"2942"},
    {"city_id":"33734","city_name":"Bod","state_id":"2942"},
    {"city_id":"33735","city_name":"Bran","state_id":"2942"},
    {"city_id":"33736","city_name":"Brasov","state_id":"2942"},
    {"city_id":"33737","city_name":"Budila","state_id":"2942"},
    {"city_id":"33738","city_name":"Bunesti","state_id":"2942"},
    {"city_id":"33739","city_name":"Cata","state_id":"2942"},
    {"city_id":"33740","city_name":"Cincu","state_id":"2942"},
    {"city_id":"33741","city_name":"Codlea","state_id":"2942"},
    {"city_id":"33742","city_name":"Comana","state_id":"2942"},
    {"city_id":"33743","city_name":"Cristian","state_id":"2942"},
    {"city_id":"33744","city_name":"Dumbravita","state_id":"2942"},
    {"city_id":"33745","city_name":"Fagaras","state_id":"2942"},
    {"city_id":"33746","city_name":"Feldioara","state_id":"2942"},
    {"city_id":"33747","city_name":"Fundata","state_id":"2942"},
    {"city_id":"33748","city_name":"Ghimbav","state_id":"2942"},
    {"city_id":"33749","city_name":"Halchiu","state_id":"2942"},
    {"city_id":"33750","city_name":"Harman","state_id":"2942"},
    {"city_id":"33751","city_name":"Harseni","state_id":"2942"},
    {"city_id":"33752","city_name":"Hoghiz","state_id":"2942"},
    {"city_id":"33753","city_name":"Homorod","state_id":"2942"},
    {"city_id":"33754","city_name":"Jibert","state_id":"2942"},
    {"city_id":"33755","city_name":"Lisa","state_id":"2942"},
    {"city_id":"33756","city_name":"Maierus","state_id":"2942"},
    {"city_id":"33757","city_name":"Mandra","state_id":"2942"},
    {"city_id":"33758","city_name":"Moeciu","state_id":"2942"},
    {"city_id":"33759","city_name":"Ormenis","state_id":"2942"},
    {"city_id":"33760","city_name":"Parau","state_id":"2942"},
    {"city_id":"33761","city_name":"Poiana Marului","state_id":"2942"},
    {"city_id":"33762","city_name":"Predeal","state_id":"2942"},
    {"city_id":"33763","city_name":"Prejmer","state_id":"2942"},
    {"city_id":"33764","city_name":"Racos","state_id":"2942"},
    {"city_id":"33765","city_name":"Rasnov","state_id":"2942"},
    {"city_id":"33766","city_name":"Recea","state_id":"2942"},
    {"city_id":"33767","city_name":"Rupea","state_id":"2942"},
    {"city_id":"33768","city_name":"Sacele","state_id":"2942"},
    {"city_id":"33769","city_name":"Sanpetru","state_id":"2942"},
    {"city_id":"33770","city_name":"Sercaia","state_id":"2942"},
    {"city_id":"33771","city_name":"Sinca","state_id":"2942"},
    {"city_id":"33772","city_name":"Soars","state_id":"2942"},
    {"city_id":"33773","city_name":"Tarlungeni","state_id":"2942"},
    {"city_id":"33774","city_name":"Teliu","state_id":"2942"},
    {"city_id":"33775","city_name":"Ticusul","state_id":"2942"},
    {"city_id":"33776","city_name":"Ucea","state_id":"2942"},
    {"city_id":"33777","city_name":"Ungra","state_id":"2942"},
    {"city_id":"33778","city_name":"Vama Buzaului","state_id":"2942"},
    {"city_id":"33779","city_name":"Victoria","state_id":"2942"},
    {"city_id":"33780","city_name":"Vistea","state_id":"2942"},
    {"city_id":"33781","city_name":"Voila","state_id":"2942"},
    {"city_id":"33782","city_name":"Vulcan","state_id":"2942"},
    {"city_id":"33783","city_name":"Zarnesti","state_id":"2942"},
    {"city_id":"33784","city_name":"Bucharest","state_id":"2943"},
    {"city_id":"33785","city_name":"Bucuresti","state_id":"2943"},
    {"city_id":"33786","city_name":"Amaru","state_id":"2944"},
    {"city_id":"33787","city_name":"Balaceanu","state_id":"2944"},
    {"city_id":"33788","city_name":"Balta Alba","state_id":"2944"},
    {"city_id":"33789","city_name":"Beceni","state_id":"2944"},
    {"city_id":"33790","city_name":"Berca","state_id":"2944"},
    {"city_id":"33791","city_name":"Bisoca","state_id":"2944"},
    {"city_id":"33792","city_name":"Blajani","state_id":"2944"},
    {"city_id":"33793","city_name":"Boldu","state_id":"2944"},
    {"city_id":"33794","city_name":"Bozioru","state_id":"2944"},
    {"city_id":"33795","city_name":"Bradeanu","state_id":"2944"},
    {"city_id":"33796","city_name":"Braesti","state_id":"2944"},
    {"city_id":"33797","city_name":"Breaza","state_id":"2944"},
    {"city_id":"33798","city_name":"Buda","state_id":"2944"},
    {"city_id":"33799","city_name":"Buzau","state_id":"2944"},
    {"city_id":"33800","city_name":"Calvini","state_id":"2944"},
    {"city_id":"33801","city_name":"Canesti","state_id":"2944"},
    {"city_id":"33802","city_name":"Catina","state_id":"2944"},
    {"city_id":"33803","city_name":"Cernatesti","state_id":"2944"},
    {"city_id":"33804","city_name":"Chiliile","state_id":"2944"},
    {"city_id":"33805","city_name":"Chiojdu","state_id":"2944"},
    {"city_id":"33806","city_name":"Cilibia","state_id":"2944"},
    {"city_id":"33807","city_name":"Cislau","state_id":"2944"},
    {"city_id":"33808","city_name":"Cochirleanca","state_id":"2944"},
    {"city_id":"33809","city_name":"Colti","state_id":"2944"},
    {"city_id":"33810","city_name":"Constantin Rosetti","state_id":"2944"},
    {"city_id":"33811","city_name":"Costesti","state_id":"2944"},
    {"city_id":"33812","city_name":"Cozieni","state_id":"2944"},
    {"city_id":"33813","city_name":"Galbinasi","state_id":"2944"},
    {"city_id":"33814","city_name":"Gheraseni","state_id":"2944"},
    {"city_id":"33815","city_name":"Ghergheasa","state_id":"2944"},
    {"city_id":"33816","city_name":"Glodeanu-Silistea","state_id":"2944"},
    {"city_id":"33817","city_name":"Glodeanul Sarat","state_id":"2944"},
    {"city_id":"33818","city_name":"Grebanu","state_id":"2944"},
    {"city_id":"33819","city_name":"Gura Teghii","state_id":"2944"},
    {"city_id":"33820","city_name":"Largu","state_id":"2944"},
    {"city_id":"33821","city_name":"Lopatari","state_id":"2944"},
    {"city_id":"33822","city_name":"Luciu","state_id":"2944"},
    {"city_id":"33823","city_name":"Magura","state_id":"2944"},
    {"city_id":"33824","city_name":"Manzalesti","state_id":"2944"},
    {"city_id":"33825","city_name":"Maracineni","state_id":"2944"},
    {"city_id":"33826","city_name":"Margaritesti","state_id":"2944"},
    {"city_id":"33827","city_name":"Merei","state_id":"2944"},
    {"city_id":"33828","city_name":"Mihailesti","state_id":"2944"},
    {"city_id":"33829","city_name":"Movila Banului","state_id":"2944"},
    {"city_id":"33830","city_name":"Murgesti","state_id":"2944"},
    {"city_id":"33831","city_name":"Naeni","state_id":"2944"},
    {"city_id":"33832","city_name":"Nehoiu","state_id":"2944"},
    {"city_id":"33833","city_name":"Odaile","state_id":"2944"},
    {"city_id":"33834","city_name":"Padina","state_id":"2944"},
    {"city_id":"33835","city_name":"Panatau","state_id":"2944"},
    {"city_id":"33836","city_name":"Pardosi","state_id":"2944"},
    {"city_id":"33837","city_name":"Parscov","state_id":"2944"},
    {"city_id":"33838","city_name":"Patarlagele","state_id":"2944"},
    {"city_id":"33839","city_name":"Pietroasele","state_id":"2944"},
    {"city_id":"33840","city_name":"Podgoria","state_id":"2944"},
    {"city_id":"33841","city_name":"Pogoanele","state_id":"2944"},
    {"city_id":"33842","city_name":"Posta Calnau","state_id":"2944"},
    {"city_id":"33843","city_name":"Puiesti","state_id":"2944"},
    {"city_id":"33844","city_name":"Racoviteni","state_id":"2944"},
    {"city_id":"33845","city_name":"Ramnicelu","state_id":"2944"},
    {"city_id":"33846","city_name":"Ramnicu Sarat","state_id":"2944"},
    {"city_id":"33847","city_name":"Robeasca","state_id":"2944"},
    {"city_id":"33848","city_name":"Rusetu","state_id":"2944"},
    {"city_id":"33849","city_name":"Sageata","state_id":"2944"},
    {"city_id":"33850","city_name":"Sahateni","state_id":"2944"},
    {"city_id":"33851","city_name":"Sapoca","state_id":"2944"},
    {"city_id":"33852","city_name":"Sarulesti","state_id":"2944"},
    {"city_id":"33853","city_name":"Scortoasa","state_id":"2944"},
    {"city_id":"33854","city_name":"Scutelnici","state_id":"2944"},
    {"city_id":"33855","city_name":"Siriu","state_id":"2944"},
    {"city_id":"33856","city_name":"Smeeni","state_id":"2944"},
    {"city_id":"33857","city_name":"Stalpu","state_id":"2944"},
    {"city_id":"33858","city_name":"Tintesti","state_id":"2944"},
    {"city_id":"33859","city_name":"Tisau","state_id":"2944"},
    {"city_id":"33860","city_name":"Topliceni","state_id":"2944"},
    {"city_id":"33861","city_name":"Ulmeni","state_id":"2944"},
    {"city_id":"33862","city_name":"Vadu Pasii","state_id":"2944"},
    {"city_id":"33863","city_name":"Valcelele","state_id":"2944"},
    {"city_id":"33864","city_name":"Valea Ramnicului","state_id":"2944"},
    {"city_id":"33865","city_name":"Valea Salciei","state_id":"2944"},
    {"city_id":"33866","city_name":"Vernesti","state_id":"2944"},
    {"city_id":"33867","city_name":"Vintila Voda","state_id":"2944"},
    {"city_id":"33868","city_name":"Viperesti","state_id":"2944"},
    {"city_id":"33869","city_name":"Zarnesti","state_id":"2944"},
    {"city_id":"33870","city_name":"Ziduri","state_id":"2944"},
    {"city_id":"33871","city_name":"Alexandru Odobescu","state_id":"2945"},
    {"city_id":"33872","city_name":"Belciugatele","state_id":"2945"},
    {"city_id":"33873","city_name":"Borcea","state_id":"2945"},
    {"city_id":"33874","city_name":"Budesti","state_id":"2945"},
    {"city_id":"33875","city_name":"Calarasi","state_id":"2945"},
    {"city_id":"33876","city_name":"Cascioarele","state_id":"2945"},
    {"city_id":"33877","city_name":"Chirnogi","state_id":"2945"},
    {"city_id":"33878","city_name":"Chiselet","state_id":"2945"},
    {"city_id":"33879","city_name":"Ciocanesti","state_id":"2945"},
    {"city_id":"33880","city_name":"Curcani","state_id":"2945"},
    {"city_id":"33881","city_name":"Cuza Voda","state_id":"2945"},
    {"city_id":"33882","city_name":"Dichiseni","state_id":"2945"},
    {"city_id":"33883","city_name":"Dor Marunt","state_id":"2945"},
    {"city_id":"33884","city_name":"Dorobantu","state_id":"2945"},
    {"city_id":"33885","city_name":"Dragalina","state_id":"2945"},
    {"city_id":"33886","city_name":"Dragos Voda","state_id":"2945"},
    {"city_id":"33887","city_name":"Frasinet","state_id":"2945"},
    {"city_id":"33888","city_name":"Frumusani","state_id":"2945"},
    {"city_id":"33889","city_name":"Fundeni","state_id":"2945"},
    {"city_id":"33890","city_name":"Fundulea","state_id":"2945"},
    {"city_id":"33891","city_name":"Gradistea","state_id":"2945"},
    {"city_id":"33892","city_name":"Gurbanesti","state_id":"2945"},
    {"city_id":"33893","city_name":"Ileana","state_id":"2945"},
    {"city_id":"33894","city_name":"Independenta","state_id":"2945"},
    {"city_id":"33895","city_name":"Jegalia","state_id":"2945"},
    {"city_id":"33896","city_name":"Lehliu","state_id":"2945"},
    {"city_id":"33897","city_name":"Lehliu-Gara","state_id":"2945"},
    {"city_id":"33898","city_name":"Luica","state_id":"2945"},
    {"city_id":"33899","city_name":"Lupsanu","state_id":"2945"},
    {"city_id":"33900","city_name":"Manastirea","state_id":"2945"},
    {"city_id":"33901","city_name":"Mitreni","state_id":"2945"},
    {"city_id":"33902","city_name":"Modelu","state_id":"2945"},
    {"city_id":"33903","city_name":"Nana","state_id":"2945"},
    {"city_id":"33904","city_name":"Nicolae Balcescu","state_id":"2945"},
    {"city_id":"33905","city_name":"Oltenita","state_id":"2945"},
    {"city_id":"33906","city_name":"Perisoru","state_id":"2945"},
    {"city_id":"33907","city_name":"Plataresti","state_id":"2945"},
    {"city_id":"33908","city_name":"Radovanu","state_id":"2945"},
    {"city_id":"33909","city_name":"Roseti","state_id":"2945"},
    {"city_id":"33910","city_name":"Sarulesti","state_id":"2945"},
    {"city_id":"33911","city_name":"Sohatu","state_id":"2945"},
    {"city_id":"33912","city_name":"Soldanu","state_id":"2945"},
    {"city_id":"33913","city_name":"Spantov","state_id":"2945"},
    {"city_id":"33914","city_name":"Stefan Voda","state_id":"2945"},
    {"city_id":"33915","city_name":"Stefan cel Mare","state_id":"2945"},
    {"city_id":"33916","city_name":"Tamadau Mare","state_id":"2945"},
    {"city_id":"33917","city_name":"Ulmeni","state_id":"2945"},
    {"city_id":"33918","city_name":"Ulmu","state_id":"2945"},
    {"city_id":"33919","city_name":"Unirea","state_id":"2945"},
    {"city_id":"33920","city_name":"Valcelele","state_id":"2945"},
    {"city_id":"33921","city_name":"Valea Argovei","state_id":"2945"},
    {"city_id":"33922","city_name":"Vasilati","state_id":"2945"},
    {"city_id":"33923","city_name":"Vlad Tepes","state_id":"2945"},
    {"city_id":"33924","city_name":"Anina","state_id":"2946"},
    {"city_id":"33925","city_name":"Armenis","state_id":"2946"},
    {"city_id":"33926","city_name":"Baile Herculane","state_id":"2946"},
    {"city_id":"33927","city_name":"Bania","state_id":"2946"},
    {"city_id":"33928","city_name":"Bautar","state_id":"2946"},
    {"city_id":"33929","city_name":"Berliste","state_id":"2946"},
    {"city_id":"33930","city_name":"Berzasca","state_id":"2946"},
    {"city_id":"33931","city_name":"Berzovia","state_id":"2946"},
    {"city_id":"33932","city_name":"Bocsa","state_id":"2946"},
    {"city_id":"33933","city_name":"Bolvasnita","state_id":"2946"},
    {"city_id":"33934","city_name":"Bozovici","state_id":"2946"},
    {"city_id":"33935","city_name":"Brebu","state_id":"2946"},
    {"city_id":"33936","city_name":"Brebu Nou","state_id":"2946"},
    {"city_id":"33937","city_name":"Buchin","state_id":"2946"},
    {"city_id":"33938","city_name":"Bucosnita","state_id":"2946"},
    {"city_id":"33939","city_name":"Caransebes","state_id":"2946"},
    {"city_id":"33940","city_name":"Carasova","state_id":"2946"},
    {"city_id":"33941","city_name":"Carbunari","state_id":"2946"},
    {"city_id":"33942","city_name":"Ciclova Romana","state_id":"2946"},
    {"city_id":"33943","city_name":"Ciuchici","state_id":"2946"},
    {"city_id":"33944","city_name":"Ciudanovita","state_id":"2946"},
    {"city_id":"33945","city_name":"Constantin Daicoviciu","state_id":"2946"},
    {"city_id":"33946","city_name":"Copacele","state_id":"2946"},
    {"city_id":"33947","city_name":"Cornea","state_id":"2946"},
    {"city_id":"33948","city_name":"Cornereva","state_id":"2946"},
    {"city_id":"33949","city_name":"Coronini","state_id":"2946"},
    {"city_id":"33950","city_name":"Dalboset","state_id":"2946"},
    {"city_id":"33951","city_name":"Doclin","state_id":"2946"},
    {"city_id":"33952","city_name":"Dognecea","state_id":"2946"},
    {"city_id":"33953","city_name":"Domasnea","state_id":"2946"},
    {"city_id":"33954","city_name":"Eftimie Murgu","state_id":"2946"},
    {"city_id":"33955","city_name":"Ezeris","state_id":"2946"},
    {"city_id":"33956","city_name":"Farliug","state_id":"2946"},
    {"city_id":"33957","city_name":"Forotic","state_id":"2946"},
    {"city_id":"33958","city_name":"Garnic","state_id":"2946"},
    {"city_id":"33959","city_name":"Glimboca","state_id":"2946"},
    {"city_id":"33960","city_name":"Goruia","state_id":"2946"},
    {"city_id":"33961","city_name":"Gradinari","state_id":"2946"},
    {"city_id":"33962","city_name":"Iablanita","state_id":"2946"},
    {"city_id":"33963","city_name":"Lapusnicel","state_id":"2946"},
    {"city_id":"33964","city_name":"Lapusnicu Mare","state_id":"2946"},
    {"city_id":"33965","city_name":"Luncavita","state_id":"2946"},
    {"city_id":"33966","city_name":"Lupac","state_id":"2946"},
    {"city_id":"33967","city_name":"Marga","state_id":"2946"},
    {"city_id":"33968","city_name":"Maureni","state_id":"2946"},
    {"city_id":"33969","city_name":"Mehadia","state_id":"2946"},
    {"city_id":"33970","city_name":"Mehadica","state_id":"2946"},
    {"city_id":"33971","city_name":"Moldova Noua","state_id":"2946"},
    {"city_id":"33972","city_name":"Naidas","state_id":"2946"},
    {"city_id":"33973","city_name":"Obreja","state_id":"2946"},
    {"city_id":"33974","city_name":"Ocna de Fier","state_id":"2946"},
    {"city_id":"33975","city_name":"Oravita","state_id":"2946"},
    {"city_id":"33976","city_name":"Otelu Rosu","state_id":"2946"},
    {"city_id":"33977","city_name":"Paltinis","state_id":"2946"},
    {"city_id":"33978","city_name":"Pojejena","state_id":"2946"},
    {"city_id":"33979","city_name":"Prigor","state_id":"2946"},
    {"city_id":"33980","city_name":"Racasdia","state_id":"2946"},
    {"city_id":"33981","city_name":"Ramna","state_id":"2946"},
    {"city_id":"33982","city_name":"Resita","state_id":"2946"},
    {"city_id":"33983","city_name":"Rusca Montana","state_id":"2946"},
    {"city_id":"33984","city_name":"Sacu","state_id":"2946"},
    {"city_id":"33985","city_name":"Sasca Montana","state_id":"2946"},
    {"city_id":"33986","city_name":"Sichevita","state_id":"2946"},
    {"city_id":"33987","city_name":"Slatina-Timis","state_id":"2946"},
    {"city_id":"33988","city_name":"Socol","state_id":"2946"},
    {"city_id":"33989","city_name":"Sopotu Nou","state_id":"2946"},
    {"city_id":"33990","city_name":"Tarnova","state_id":"2946"},
    {"city_id":"33991","city_name":"Teregova","state_id":"2946"},
    {"city_id":"33992","city_name":"Ticvaniu Mare","state_id":"2946"},
    {"city_id":"33993","city_name":"Toplet","state_id":"2946"},
    {"city_id":"33994","city_name":"Turnu Ruieni","state_id":"2946"},
    {"city_id":"33995","city_name":"Valiug","state_id":"2946"},
    {"city_id":"33996","city_name":"Varadia","state_id":"2946"},
    {"city_id":"33997","city_name":"Vermes","state_id":"2946"},
    {"city_id":"33998","city_name":"Vrani","state_id":"2946"},
    {"city_id":"33999","city_name":"Zavoi","state_id":"2946"},
    {"city_id":"34000","city_name":"Zorlentu Mare","state_id":"2946"},
    {"city_id":"34001","city_name":"Aghiresu","state_id":"2947"},
    {"city_id":"34002","city_name":"Aiton","state_id":"2947"},
    {"city_id":"34003","city_name":"Alunis","state_id":"2947"},
    {"city_id":"34004","city_name":"Apahida","state_id":"2947"},
    {"city_id":"34005","city_name":"Aschileu","state_id":"2947"},
    {"city_id":"34006","city_name":"Baciu","state_id":"2947"},
    {"city_id":"34007","city_name":"Baisoara","state_id":"2947"},
    {"city_id":"34008","city_name":"Belis","state_id":"2947"},
    {"city_id":"34009","city_name":"Bobalna","state_id":"2947"},
    {"city_id":"34010","city_name":"Bontida","state_id":"2947"},
    {"city_id":"34011","city_name":"Borsa","state_id":"2947"},
    {"city_id":"34012","city_name":"Buza","state_id":"2947"},
    {"city_id":"34013","city_name":"Caianu","state_id":"2947"},
    {"city_id":"34014","city_name":"Calarasi","state_id":"2947"},
    {"city_id":"34015","city_name":"Calatele","state_id":"2947"},
    {"city_id":"34016","city_name":"Camarasu","state_id":"2947"},
    {"city_id":"34017","city_name":"Campia Turzii","state_id":"2947"},
    {"city_id":"34018","city_name":"Capusu Mare","state_id":"2947"},
    {"city_id":"34019","city_name":"Caseiu","state_id":"2947"},
    {"city_id":"34020","city_name":"Catcau","state_id":"2947"},
    {"city_id":"34021","city_name":"Catina","state_id":"2947"},
    {"city_id":"34022","city_name":"Ceanu Mare","state_id":"2947"},
    {"city_id":"34023","city_name":"Chinteni","state_id":"2947"},
    {"city_id":"34024","city_name":"Chiuiesti","state_id":"2947"},
    {"city_id":"34025","city_name":"Ciucea","state_id":"2947"},
    {"city_id":"34026","city_name":"Ciurila","state_id":"2947"},
    {"city_id":"34027","city_name":"Cluj-Napoca","state_id":"2947"},
    {"city_id":"34028","city_name":"Cojocna","state_id":"2947"},
    {"city_id":"34029","city_name":"Cornesti","state_id":"2947"},
    {"city_id":"34030","city_name":"Cuzdrioara","state_id":"2947"},
    {"city_id":"34031","city_name":"Dabaca","state_id":"2947"},
    {"city_id":"34032","city_name":"Dej","state_id":"2947"},
    {"city_id":"34033","city_name":"Feleacu","state_id":"2947"},
    {"city_id":"34034","city_name":"Fizesu Gherlii","state_id":"2947"},
    {"city_id":"34035","city_name":"Floresti","state_id":"2947"},
    {"city_id":"34036","city_name":"Frata","state_id":"2947"},
    {"city_id":"34037","city_name":"Garbau","state_id":"2947"},
    {"city_id":"34038","city_name":"Geaca","state_id":"2947"},
    {"city_id":"34039","city_name":"Gherla","state_id":"2947"},
    {"city_id":"34040","city_name":"Gilau","state_id":"2947"},
    {"city_id":"34041","city_name":"Huedin","state_id":"2947"},
    {"city_id":"34042","city_name":"Iara","state_id":"2947"},
    {"city_id":"34043","city_name":"Iclod","state_id":"2947"},
    {"city_id":"34044","city_name":"Izvoru Crisului","state_id":"2947"},
    {"city_id":"34045","city_name":"Jichisu de Jos","state_id":"2947"},
    {"city_id":"34046","city_name":"Jucu","state_id":"2947"},
    {"city_id":"34047","city_name":"Luna","state_id":"2947"},
    {"city_id":"34048","city_name":"Maguri-Racatau","state_id":"2947"},
    {"city_id":"34049","city_name":"Manastireni","state_id":"2947"},
    {"city_id":"34050","city_name":"Margau","state_id":"2947"},
    {"city_id":"34051","city_name":"Marisel","state_id":"2947"},
    {"city_id":"34052","city_name":"Mica","state_id":"2947"},
    {"city_id":"34053","city_name":"Mihai Viteazu","state_id":"2947"},
    {"city_id":"34054","city_name":"Mintiu Gherlii","state_id":"2947"},
    {"city_id":"34055","city_name":"Mociu","state_id":"2947"},
    {"city_id":"34056","city_name":"Moldovenesti","state_id":"2947"},
    {"city_id":"34057","city_name":"Palatca","state_id":"2947"},
    {"city_id":"34058","city_name":"Panticeu","state_id":"2947"},
    {"city_id":"34059","city_name":"Petrestii de Jos","state_id":"2947"},
    {"city_id":"34060","city_name":"Ploscos","state_id":"2947"},
    {"city_id":"34061","city_name":"Poieni","state_id":"2947"},
    {"city_id":"34062","city_name":"Rasca","state_id":"2947"},
    {"city_id":"34063","city_name":"Recea Cristur","state_id":"2947"},
    {"city_id":"34064","city_name":"Sacuieu","state_id":"2947"},
    {"city_id":"34065","city_name":"Sancraiu","state_id":"2947"},
    {"city_id":"34066","city_name":"Sandulesti","state_id":"2947"},
    {"city_id":"34067","city_name":"Sanmartin","state_id":"2947"},
    {"city_id":"34068","city_name":"Sanpaul","state_id":"2947"},
    {"city_id":"34069","city_name":"Savadisla","state_id":"2947"},
    {"city_id":"34070","city_name":"Sic","state_id":"2947"},
    {"city_id":"34071","city_name":"Suatu","state_id":"2947"},
    {"city_id":"34072","city_name":"Taga","state_id":"2947"},
    {"city_id":"34073","city_name":"Tritenii de Jos","state_id":"2947"},
    {"city_id":"34074","city_name":"Turda","state_id":"2947"},
    {"city_id":"34075","city_name":"Tureni","state_id":"2947"},
    {"city_id":"34076","city_name":"Unguras","state_id":"2947"},
    {"city_id":"34077","city_name":"Vad","state_id":"2947"},
    {"city_id":"34078","city_name":"Valea Ierii","state_id":"2947"},
    {"city_id":"34079","city_name":"Viisoara","state_id":"2947"},
    {"city_id":"34080","city_name":"Vultureni","state_id":"2947"},
    {"city_id":"34081","city_name":"Adamclisi","state_id":"2948"},
    {"city_id":"34082","city_name":"Agigea","state_id":"2948"},
    {"city_id":"34083","city_name":"Albesti","state_id":"2948"},
    {"city_id":"34084","city_name":"Aliman","state_id":"2948"},
    {"city_id":"34085","city_name":"Amzacea","state_id":"2948"},
    {"city_id":"34086","city_name":"Baneasa","state_id":"2948"},
    {"city_id":"34087","city_name":"Basarabi","state_id":"2948"},
    {"city_id":"34088","city_name":"Castelu","state_id":"2948"},
    {"city_id":"34089","city_name":"Cerchezu","state_id":"2948"},
    {"city_id":"34090","city_name":"Cernavoda","state_id":"2948"},
    {"city_id":"34091","city_name":"Chirnogeni","state_id":"2948"},
    {"city_id":"34092","city_name":"Ciobanu","state_id":"2948"},
    {"city_id":"34093","city_name":"Ciocarlia","state_id":"2948"},
    {"city_id":"34094","city_name":"Cobadin","state_id":"2948"},
    {"city_id":"34095","city_name":"Cogealac","state_id":"2948"},
    {"city_id":"34096","city_name":"Comana","state_id":"2948"},
    {"city_id":"34097","city_name":"Constanta","state_id":"2948"},
    {"city_id":"34098","city_name":"Corbu","state_id":"2948"},
    {"city_id":"34099","city_name":"Costinesti","state_id":"2948"},
    {"city_id":"34100","city_name":"Crucea","state_id":"2948"},
    {"city_id":"34101","city_name":"Cumpana","state_id":"2948"},
    {"city_id":"34102","city_name":"Deleni","state_id":"2948"},
    {"city_id":"34103","city_name":"Dobromir","state_id":"2948"},
    {"city_id":"34104","city_name":"Douazeci si Trei August","state_id":"2948"},
    {"city_id":"34105","city_name":"Dumbraveni","state_id":"2948"},
    {"city_id":"34106","city_name":"Eforie","state_id":"2948"},
    {"city_id":"34107","city_name":"Garliciu","state_id":"2948"},
    {"city_id":"34108","city_name":"Ghindaresti","state_id":"2948"},
    {"city_id":"34109","city_name":"Harsova","state_id":"2948"},
    {"city_id":"34110","city_name":"Horia","state_id":"2948"},
    {"city_id":"34111","city_name":"Independenta","state_id":"2948"},
    {"city_id":"34112","city_name":"Ion Corvin","state_id":"2948"},
    {"city_id":"34113","city_name":"Istria","state_id":"2948"},
    {"city_id":"34114","city_name":"Limanu","state_id":"2948"},
    {"city_id":"34115","city_name":"Lipnita","state_id":"2948"},
    {"city_id":"34116","city_name":"Lumina","state_id":"2948"},
    {"city_id":"34117","city_name":"Mangalia","state_id":"2948"},
    {"city_id":"34118","city_name":"Medgidia","state_id":"2948"},
    {"city_id":"34119","city_name":"Mereni","state_id":"2948"},
    {"city_id":"34120","city_name":"Mihai Viteazu","state_id":"2948"},
    {"city_id":"34121","city_name":"Mihail Kogalniceanu","state_id":"2948"},
    {"city_id":"34122","city_name":"Mircea Voda","state_id":"2948"},
    {"city_id":"34123","city_name":"Navodari","state_id":"2948"},
    {"city_id":"34124","city_name":"Negru Voda","state_id":"2948"},
    {"city_id":"34125","city_name":"Nicolae Balcescu","state_id":"2948"},
    {"city_id":"34126","city_name":"Oltina","state_id":"2948"},
    {"city_id":"34127","city_name":"Ostrov","state_id":"2948"},
    {"city_id":"34128","city_name":"Ovidiu","state_id":"2948"},
    {"city_id":"34129","city_name":"Pantelimon","state_id":"2948"},
    {"city_id":"34130","city_name":"Pecineaga","state_id":"2948"},
    {"city_id":"34131","city_name":"Pestera","state_id":"2948"},
    {"city_id":"34132","city_name":"Poarta Alba","state_id":"2948"},
    {"city_id":"34133","city_name":"Rasova","state_id":"2948"},
    {"city_id":"34134","city_name":"Sacele","state_id":"2948"},
    {"city_id":"34135","city_name":"Saraiu","state_id":"2948"},
    {"city_id":"34136","city_name":"Seimeni","state_id":"2948"},
    {"city_id":"34137","city_name":"Silistea","state_id":"2948"},
    {"city_id":"34138","city_name":"Targusor","state_id":"2948"},
    {"city_id":"34139","city_name":"Techirghiol","state_id":"2948"},
    {"city_id":"34140","city_name":"Topalu","state_id":"2948"},
    {"city_id":"34141","city_name":"Topraisar","state_id":"2948"},
    {"city_id":"34142","city_name":"Tortoman","state_id":"2948"},
    {"city_id":"34143","city_name":"Tuzla","state_id":"2948"},
    {"city_id":"34144","city_name":"Valu lui Traian","state_id":"2948"},
    {"city_id":"34145","city_name":"Vulturu","state_id":"2948"},
    {"city_id":"34146","city_name":"Aita Mare","state_id":"2949"},
    {"city_id":"34147","city_name":"Baraolt","state_id":"2949"},
    {"city_id":"34148","city_name":"Barcani","state_id":"2949"},
    {"city_id":"34149","city_name":"Batani","state_id":"2949"},
    {"city_id":"34150","city_name":"Belin","state_id":"2949"},
    {"city_id":"34151","city_name":"Bodoc","state_id":"2949"},
    {"city_id":"34152","city_name":"Borosneu Mare","state_id":"2949"},
    {"city_id":"34153","city_name":"Bradut","state_id":"2949"},
    {"city_id":"34154","city_name":"Brates","state_id":"2949"},
    {"city_id":"34155","city_name":"Bretcu","state_id":"2949"},
    {"city_id":"34156","city_name":"Catalina","state_id":"2949"},
    {"city_id":"34157","city_name":"Cernat","state_id":"2949"},
    {"city_id":"34158","city_name":"Chichis","state_id":"2949"},
    {"city_id":"34159","city_name":"Comandau","state_id":"2949"},
    {"city_id":"34160","city_name":"Covasna","state_id":"2949"},
    {"city_id":"34161","city_name":"Dobarlau","state_id":"2949"},
    {"city_id":"34162","city_name":"Ghelinta","state_id":"2949"},
    {"city_id":"34163","city_name":"Ghidfalau","state_id":"2949"},
    {"city_id":"34164","city_name":"Haghig","state_id":"2949"},
    {"city_id":"34165","city_name":"Ilieni","state_id":"2949"},
    {"city_id":"34166","city_name":"Intorsura Buzaului","state_id":"2949"},
    {"city_id":"34167","city_name":"Lemnia","state_id":"2949"},
    {"city_id":"34168","city_name":"Malnas","state_id":"2949"},
    {"city_id":"34169","city_name":"Moacsa","state_id":"2949"},
    {"city_id":"34170","city_name":"Ojdula","state_id":"2949"},
    {"city_id":"34171","city_name":"Ozun","state_id":"2949"},
    {"city_id":"34172","city_name":"Poian","state_id":"2949"},
    {"city_id":"34173","city_name":"Reci","state_id":"2949"},
    {"city_id":"34174","city_name":"Sanzieni","state_id":"2949"},
    {"city_id":"34175","city_name":"Sfantu Gheorghe","state_id":"2949"},
    {"city_id":"34176","city_name":"Sita Buzaului","state_id":"2949"},
    {"city_id":"34177","city_name":"Targu Secuiesc","state_id":"2949"},
    {"city_id":"34178","city_name":"Turia","state_id":"2949"},
    {"city_id":"34179","city_name":"Valcele","state_id":"2949"},
    {"city_id":"34180","city_name":"Valea Crisului","state_id":"2949"},
    {"city_id":"34181","city_name":"Valea Mare","state_id":"2949"},
    {"city_id":"34182","city_name":"Varghis","state_id":"2949"},
    {"city_id":"34183","city_name":"Zabala","state_id":"2949"},
    {"city_id":"34184","city_name":"Zagon","state_id":"2949"},
    {"city_id":"34185","city_name":"Aninoasa","state_id":"2950"},
    {"city_id":"34186","city_name":"Baleni","state_id":"2950"},
    {"city_id":"34187","city_name":"Barbuletu","state_id":"2950"},
    {"city_id":"34188","city_name":"Bezdead","state_id":"2950"},
    {"city_id":"34189","city_name":"Bilciuresti","state_id":"2950"},
    {"city_id":"34190","city_name":"Branesti","state_id":"2950"},
    {"city_id":"34191","city_name":"Branistea","state_id":"2950"},
    {"city_id":"34192","city_name":"Brezoaiele","state_id":"2950"},
    {"city_id":"34193","city_name":"Buciumeni","state_id":"2950"},
    {"city_id":"34194","city_name":"Bucsani","state_id":"2950"},
    {"city_id":"34195","city_name":"Butimanu","state_id":"2950"},
    {"city_id":"34196","city_name":"Candesti","state_id":"2950"},
    {"city_id":"34197","city_name":"Ciocanesti","state_id":"2950"},
    {"city_id":"34198","city_name":"Cobia","state_id":"2950"},
    {"city_id":"34199","city_name":"Cojasca","state_id":"2950"},
    {"city_id":"34200","city_name":"Comisani","state_id":"2950"},
    {"city_id":"34201","city_name":"Contesti","state_id":"2950"},
    {"city_id":"34202","city_name":"Corbii Mari","state_id":"2950"},
    {"city_id":"34203","city_name":"Cornatelu","state_id":"2950"},
    {"city_id":"34204","city_name":"Cornesti","state_id":"2950"},
    {"city_id":"34205","city_name":"Costestii din Vale","state_id":"2950"},
    {"city_id":"34206","city_name":"Crangurile de Sus","state_id":"2950"},
    {"city_id":"34207","city_name":"Crevedia","state_id":"2950"},
    {"city_id":"34208","city_name":"Darmanesti","state_id":"2950"},
    {"city_id":"34209","city_name":"Dobra","state_id":"2950"},
    {"city_id":"34210","city_name":"Doicesti","state_id":"2950"},
    {"city_id":"34211","city_name":"Dragodana","state_id":"2950"},
    {"city_id":"34212","city_name":"Dragomiresti","state_id":"2950"},
    {"city_id":"34213","city_name":"Edera de Jos","state_id":"2950"},
    {"city_id":"34214","city_name":"Fieni","state_id":"2950"},
    {"city_id":"34215","city_name":"Finta","state_id":"2950"},
    {"city_id":"34216","city_name":"Gaesti","state_id":"2950"},
    {"city_id":"34217","city_name":"Glodeni","state_id":"2950"},
    {"city_id":"34218","city_name":"Gura Foii","state_id":"2950"},
    {"city_id":"34219","city_name":"Gura Ocnitei","state_id":"2950"},
    {"city_id":"34220","city_name":"Gura Sutii","state_id":"2950"},
    {"city_id":"34221","city_name":"Hulubesti","state_id":"2950"},
    {"city_id":"34222","city_name":"Ion luca Caragiale","state_id":"2950"},
    {"city_id":"34223","city_name":"Lucieni","state_id":"2950"},
    {"city_id":"34224","city_name":"Ludesti","state_id":"2950"},
    {"city_id":"34225","city_name":"Lunguletu","state_id":"2950"},
    {"city_id":"34226","city_name":"Malu cu Flori","state_id":"2950"},
    {"city_id":"34227","city_name":"Manesti","state_id":"2950"},
    {"city_id":"34228","city_name":"Matasaru","state_id":"2950"},
    {"city_id":"34229","city_name":"Mogosani","state_id":"2950"},
    {"city_id":"34230","city_name":"Moreni","state_id":"2950"},
    {"city_id":"34231","city_name":"Moroeni","state_id":"2950"},
    {"city_id":"34232","city_name":"Morteni","state_id":"2950"},
    {"city_id":"34233","city_name":"Motaeni","state_id":"2950"},
    {"city_id":"34234","city_name":"Niculesti","state_id":"2950"},
    {"city_id":"34235","city_name":"Nucet","state_id":"2950"},
    {"city_id":"34236","city_name":"Ocnita","state_id":"2950"},
    {"city_id":"34237","city_name":"Odobesti","state_id":"2950"},
    {"city_id":"34238","city_name":"Petresti","state_id":"2950"},
    {"city_id":"34239","city_name":"Pietrosita","state_id":"2950"},
    {"city_id":"34240","city_name":"Poiana","state_id":"2950"},
    {"city_id":"34241","city_name":"Potlogi","state_id":"2950"},
    {"city_id":"34242","city_name":"Produlesti","state_id":"2950"},
    {"city_id":"34243","city_name":"Pucheni","state_id":"2950"},
    {"city_id":"34244","city_name":"Pucioasa","state_id":"2950"},
    {"city_id":"34245","city_name":"Racari","state_id":"2950"},
    {"city_id":"34246","city_name":"Razvad","state_id":"2950"},
    {"city_id":"34247","city_name":"Runcu","state_id":"2950"},
    {"city_id":"34248","city_name":"Salcioara","state_id":"2950"},
    {"city_id":"34249","city_name":"Selaru","state_id":"2950"},
    {"city_id":"34250","city_name":"Slobozia Moara","state_id":"2950"},
    {"city_id":"34251","city_name":"Sotanga","state_id":"2950"},
    {"city_id":"34252","city_name":"Targoviste","state_id":"2950"},
    {"city_id":"34253","city_name":"Tartasesti","state_id":"2950"},
    {"city_id":"34254","city_name":"Tatarani","state_id":"2950"},
    {"city_id":"34255","city_name":"Titu","state_id":"2950"},
    {"city_id":"34256","city_name":"Uliesti","state_id":"2950"},
    {"city_id":"34257","city_name":"Ulmi","state_id":"2950"},
    {"city_id":"34258","city_name":"Vacaresti","state_id":"2950"},
    {"city_id":"34259","city_name":"Valea Lunga","state_id":"2950"},
    {"city_id":"34260","city_name":"Valea Mare","state_id":"2950"},
    {"city_id":"34261","city_name":"Valeni-Dambovita","state_id":"2950"},
    {"city_id":"34262","city_name":"Varfuri","state_id":"2950"},
    {"city_id":"34263","city_name":"Visina","state_id":"2950"},
    {"city_id":"34264","city_name":"Visinesti","state_id":"2950"},
    {"city_id":"34265","city_name":"Voinesti","state_id":"2950"},
    {"city_id":"34266","city_name":"Vulcana Bai","state_id":"2950"},
    {"city_id":"34267","city_name":"Afumati","state_id":"2951"},
    {"city_id":"34268","city_name":"Almajiu","state_id":"2951"},
    {"city_id":"34269","city_name":"Amarastii de Jos","state_id":"2951"},
    {"city_id":"34270","city_name":"Amarastii de Sus","state_id":"2951"},
    {"city_id":"34271","city_name":"Apele Vii","state_id":"2951"},
    {"city_id":"34272","city_name":"Argetoaia","state_id":"2951"},
    {"city_id":"34273","city_name":"Bailesti","state_id":"2951"},
    {"city_id":"34274","city_name":"Barca","state_id":"2951"},
    {"city_id":"34275","city_name":"Bechet","state_id":"2951"},
    {"city_id":"34276","city_name":"Bistret","state_id":"2951"},
    {"city_id":"34277","city_name":"Botosesti-Paia","state_id":"2951"},
    {"city_id":"34278","city_name":"Brabova","state_id":"2951"},
    {"city_id":"34279","city_name":"Bradesti","state_id":"2951"},
    {"city_id":"34280","city_name":"Bralostita","state_id":"2951"},
    {"city_id":"34281","city_name":"Bratovoesti","state_id":"2951"},
    {"city_id":"34282","city_name":"Breasta","state_id":"2951"},
    {"city_id":"34283","city_name":"Bucovat","state_id":"2951"},
    {"city_id":"34284","city_name":"Bulzesti","state_id":"2951"},
    {"city_id":"34285","city_name":"Calafat","state_id":"2951"},
    {"city_id":"34286","city_name":"Calarasi","state_id":"2951"},
    {"city_id":"34287","city_name":"Calopar","state_id":"2951"},
    {"city_id":"34288","city_name":"Caraula","state_id":"2951"},
    {"city_id":"34289","city_name":"Carpen","state_id":"2951"},
    {"city_id":"34290","city_name":"Castra-Nova","state_id":"2951"},
    {"city_id":"34291","city_name":"Celaru","state_id":"2951"},
    {"city_id":"34292","city_name":"Ceratu","state_id":"2951"},
    {"city_id":"34293","city_name":"Cernatesti","state_id":"2951"},
    {"city_id":"34294","city_name":"Cetate","state_id":"2951"},
    {"city_id":"34295","city_name":"Cioroiasiu","state_id":"2951"},
    {"city_id":"34296","city_name":"Ciupercenii Noi","state_id":"2951"},
    {"city_id":"34297","city_name":"Cosoveni","state_id":"2951"},
    {"city_id":"34298","city_name":"Cotofenii din Dos","state_id":"2951"},
    {"city_id":"34299","city_name":"Craiova","state_id":"2951"},
    {"city_id":"34300","city_name":"Dabuleni","state_id":"2951"},
    {"city_id":"34301","city_name":"Daneti","state_id":"2951"},
    {"city_id":"34302","city_name":"Desa","state_id":"2951"},
    {"city_id":"34303","city_name":"Diosti","state_id":"2951"},
    {"city_id":"34304","city_name":"Dobresti","state_id":"2951"},
    {"city_id":"34305","city_name":"Dragotesti","state_id":"2951"},
    {"city_id":"34306","city_name":"Dranic","state_id":"2951"},
    {"city_id":"34307","city_name":"Farcasu","state_id":"2951"},
    {"city_id":"34308","city_name":"Filiasi","state_id":"2951"},
    {"city_id":"34309","city_name":"Galicea Mare","state_id":"2951"},
    {"city_id":"34310","city_name":"Gangiova","state_id":"2951"},
    {"city_id":"34311","city_name":"Ghercesti","state_id":"2951"},
    {"city_id":"34312","city_name":"Gighera","state_id":"2951"},
    {"city_id":"34313","city_name":"Giubega","state_id":"2951"},
    {"city_id":"34314","city_name":"Giurgita","state_id":"2951"},
    {"city_id":"34315","city_name":"Gogosu","state_id":"2951"},
    {"city_id":"34316","city_name":"Goicea","state_id":"2951"},
    {"city_id":"34317","city_name":"Goiesti","state_id":"2951"},
    {"city_id":"34318","city_name":"Grecesti","state_id":"2951"},
    {"city_id":"34319","city_name":"Isalnita","state_id":"2951"},
    {"city_id":"34320","city_name":"Izvoare","state_id":"2951"},
    {"city_id":"34321","city_name":"Leu","state_id":"2951"},
    {"city_id":"34322","city_name":"Lipovu","state_id":"2951"},
    {"city_id":"34323","city_name":"Macesu de Jos","state_id":"2951"},
    {"city_id":"34324","city_name":"Macesu de Sus","state_id":"2951"},
    {"city_id":"34325","city_name":"Maglavit","state_id":"2951"},
    {"city_id":"34326","city_name":"Malu Mare","state_id":"2951"},
    {"city_id":"34327","city_name":"Marsani","state_id":"2951"},
    {"city_id":"34328","city_name":"Melinesti","state_id":"2951"},
    {"city_id":"34329","city_name":"Mischii","state_id":"2951"},
    {"city_id":"34330","city_name":"Motatei","state_id":"2951"},
    {"city_id":"34331","city_name":"Murgasi","state_id":"2951"},
    {"city_id":"34332","city_name":"Negoi","state_id":"2951"},
    {"city_id":"34333","city_name":"Orodel","state_id":"2951"},
    {"city_id":"34334","city_name":"Ostroveni","state_id":"2951"},
    {"city_id":"34335","city_name":"Perisor","state_id":"2951"},
    {"city_id":"34336","city_name":"Pielesti","state_id":"2951"},
    {"city_id":"34337","city_name":"Piscu Vechi","state_id":"2951"},
    {"city_id":"34338","city_name":"Plenita","state_id":"2951"},
    {"city_id":"34339","city_name":"Podari","state_id":"2951"},
    {"city_id":"34340","city_name":"Poiana Mare","state_id":"2951"},
    {"city_id":"34341","city_name":"Predesti","state_id":"2951"},
    {"city_id":"34342","city_name":"Radovan","state_id":"2951"},
    {"city_id":"34343","city_name":"Rastu","state_id":"2951"},
    {"city_id":"34344","city_name":"Robanesti","state_id":"2951"},
    {"city_id":"34345","city_name":"Sadova","state_id":"2951"},
    {"city_id":"34346","city_name":"Salcuta","state_id":"2951"},
    {"city_id":"34347","city_name":"Scaesti","state_id":"2951"},
    {"city_id":"34348","city_name":"Seaca de Camp","state_id":"2951"},
    {"city_id":"34349","city_name":"Seaca de Padure","state_id":"2951"},
    {"city_id":"34350","city_name":"Secu","state_id":"2951"},
    {"city_id":"34351","city_name":"Segarcea","state_id":"2951"},
    {"city_id":"34352","city_name":"Silistea Crucii","state_id":"2951"},
    {"city_id":"34353","city_name":"Simnicu de Sus","state_id":"2951"},
    {"city_id":"34354","city_name":"Sopot","state_id":"2951"},
    {"city_id":"34355","city_name":"Teasc","state_id":"2951"},
    {"city_id":"34356","city_name":"Terpezita","state_id":"2951"},
    {"city_id":"34357","city_name":"Teslui","state_id":"2951"},
    {"city_id":"34358","city_name":"Tuglui","state_id":"2951"},
    {"city_id":"34359","city_name":"Unirea","state_id":"2951"},
    {"city_id":"34360","city_name":"Urzicuta","state_id":"2951"},
    {"city_id":"34361","city_name":"Valea Stanciului","state_id":"2951"},
    {"city_id":"34362","city_name":"Vartop","state_id":"2951"},
    {"city_id":"34363","city_name":"Varvoru","state_id":"2951"},
    {"city_id":"34364","city_name":"Vela","state_id":"2951"},
    {"city_id":"34365","city_name":"Verbita","state_id":"2951"},
    {"city_id":"34366","city_name":"Balabanesti","state_id":"2952"},
    {"city_id":"34367","city_name":"Balasesti","state_id":"2952"},
    {"city_id":"34368","city_name":"Baleni","state_id":"2952"},
    {"city_id":"34369","city_name":"Baneasa","state_id":"2952"},
    {"city_id":"34370","city_name":"Barcea","state_id":"2952"},
    {"city_id":"34371","city_name":"Beresti","state_id":"2952"},
    {"city_id":"34372","city_name":"Beresti-Sat","state_id":"2952"},
    {"city_id":"34373","city_name":"Brahasesti","state_id":"2952"},
    {"city_id":"34374","city_name":"Branistea","state_id":"2952"},
    {"city_id":"34375","city_name":"Buciumeni","state_id":"2952"},
    {"city_id":"34376","city_name":"Cavadinesti","state_id":"2952"},
    {"city_id":"34377","city_name":"Certesti","state_id":"2952"},
    {"city_id":"34378","city_name":"Corni","state_id":"2952"},
    {"city_id":"34379","city_name":"Corod","state_id":"2952"},
    {"city_id":"34380","city_name":"Cosmesti","state_id":"2952"},
    {"city_id":"34381","city_name":"Costache Negri","state_id":"2952"},
    {"city_id":"34382","city_name":"Cuca","state_id":"2952"},
    {"city_id":"34383","city_name":"Cudalbi","state_id":"2952"},
    {"city_id":"34384","city_name":"Draganesti","state_id":"2952"},
    {"city_id":"34385","city_name":"Draguseni","state_id":"2952"},
    {"city_id":"34386","city_name":"Fartanesti","state_id":"2952"},
    {"city_id":"34387","city_name":"Foltesti","state_id":"2952"},
    {"city_id":"34388","city_name":"Frumusita","state_id":"2952"},
    {"city_id":"34389","city_name":"Fundeni","state_id":"2952"},
    {"city_id":"34390","city_name":"Galati","state_id":"2952"},
    {"city_id":"34391","city_name":"Ghidigeni","state_id":"2952"},
    {"city_id":"34392","city_name":"Gohor","state_id":"2952"},
    {"city_id":"34393","city_name":"Grivita","state_id":"2952"},
    {"city_id":"34394","city_name":"Independenta","state_id":"2952"},
    {"city_id":"34395","city_name":"Ivesti","state_id":"2952"},
    {"city_id":"34396","city_name":"Jorasti","state_id":"2952"},
    {"city_id":"34397","city_name":"Liesti","state_id":"2952"},
    {"city_id":"34398","city_name":"Mastacani","state_id":"2952"},
    {"city_id":"34399","city_name":"Matca","state_id":"2952"},
    {"city_id":"34400","city_name":"Movileni","state_id":"2952"},
    {"city_id":"34401","city_name":"Munteni","state_id":"2952"},
    {"city_id":"34402","city_name":"Namoloasa","state_id":"2952"},
    {"city_id":"34403","city_name":"Nicoresti","state_id":"2952"},
    {"city_id":"34404","city_name":"Oancea","state_id":"2952"},
    {"city_id":"34405","city_name":"Pechea","state_id":"2952"},
    {"city_id":"34406","city_name":"Piscu","state_id":"2952"},
    {"city_id":"34407","city_name":"Priponesti","state_id":"2952"},
    {"city_id":"34408","city_name":"Rediu","state_id":"2952"},
    {"city_id":"34409","city_name":"Scanteiesti","state_id":"2952"},
    {"city_id":"34410","city_name":"Schela","state_id":"2952"},
    {"city_id":"34411","city_name":"Sendreni","state_id":"2952"},
    {"city_id":"34412","city_name":"Slobozia-Conachi","state_id":"2952"},
    {"city_id":"34413","city_name":"Smardan","state_id":"2952"},
    {"city_id":"34414","city_name":"Smulti","state_id":"2952"},
    {"city_id":"34415","city_name":"Suceveni","state_id":"2952"},
    {"city_id":"34416","city_name":"Targu Bujor","state_id":"2952"},
    {"city_id":"34417","city_name":"Tecuci","state_id":"2952"},
    {"city_id":"34418","city_name":"Tepu","state_id":"2952"},
    {"city_id":"34419","city_name":"Tudor Vladimirescu","state_id":"2952"},
    {"city_id":"34420","city_name":"Tulucesti","state_id":"2952"},
    {"city_id":"34421","city_name":"Umbraresti","state_id":"2952"},
    {"city_id":"34422","city_name":"Valea Marului","state_id":"2952"},
    {"city_id":"34423","city_name":"Vanatori","state_id":"2952"},
    {"city_id":"34424","city_name":"Varlezi","state_id":"2952"},
    {"city_id":"34425","city_name":"Vladesti","state_id":"2952"},
    {"city_id":"34426","city_name":"Adunatii-Copaceni","state_id":"2953"},
    {"city_id":"34427","city_name":"Baneasa","state_id":"2953"},
    {"city_id":"34428","city_name":"Bolintin Deal","state_id":"2953"},
    {"city_id":"34429","city_name":"Bolintinu-din-Vale","state_id":"2953"},
    {"city_id":"34430","city_name":"Bucsani","state_id":"2953"},
    {"city_id":"34431","city_name":"Bulbucata","state_id":"2953"},
    {"city_id":"34432","city_name":"Buturugeni","state_id":"2953"},
    {"city_id":"34433","city_name":"Calugareni","state_id":"2953"},
    {"city_id":"34434","city_name":"Clejani","state_id":"2953"},
    {"city_id":"34435","city_name":"Colibasi","state_id":"2953"},
    {"city_id":"34436","city_name":"Comana","state_id":"2953"},
    {"city_id":"34437","city_name":"Crevedia Mare","state_id":"2953"},
    {"city_id":"34438","city_name":"Daia","state_id":"2953"},
    {"city_id":"34439","city_name":"Floresti-Stoenesti","state_id":"2953"},
    {"city_id":"34440","city_name":"Fratesti","state_id":"2953"},
    {"city_id":"34441","city_name":"Gaiseni","state_id":"2953"},
    {"city_id":"34442","city_name":"Gaujani","state_id":"2953"},
    {"city_id":"34443","city_name":"Ghimpati","state_id":"2953"},
    {"city_id":"34444","city_name":"Giurgiu","state_id":"2953"},
    {"city_id":"34445","city_name":"Gogosari","state_id":"2953"},
    {"city_id":"34446","city_name":"Gostinari","state_id":"2953"},
    {"city_id":"34447","city_name":"Gostinu","state_id":"2953"},
    {"city_id":"34448","city_name":"Gradinari","state_id":"2953"},
    {"city_id":"34449","city_name":"Greaca","state_id":"2953"},
    {"city_id":"34450","city_name":"Hotarele","state_id":"2953"},
    {"city_id":"34451","city_name":"Iepuresti","state_id":"2953"},
    {"city_id":"34452","city_name":"Izvoarele","state_id":"2953"},
    {"city_id":"34453","city_name":"Joita","state_id":"2953"},
    {"city_id":"34454","city_name":"Letca Noua","state_id":"2953"},
    {"city_id":"34455","city_name":"Marsa","state_id":"2953"},
    {"city_id":"34456","city_name":"Mihai Bravu","state_id":"2953"},
    {"city_id":"34457","city_name":"Mihailesti","state_id":"2953"},
    {"city_id":"34458","city_name":"Ogrezeni","state_id":"2953"},
    {"city_id":"34459","city_name":"Oinacu","state_id":"2953"},
    {"city_id":"34460","city_name":"Prundu","state_id":"2953"},
    {"city_id":"34461","city_name":"Putineiu","state_id":"2953"},
    {"city_id":"34462","city_name":"Rasuceni","state_id":"2953"},
    {"city_id":"34463","city_name":"Roata de Jos","state_id":"2953"},
    {"city_id":"34464","city_name":"Schitu","state_id":"2953"},
    {"city_id":"34465","city_name":"Singureni","state_id":"2953"},
    {"city_id":"34466","city_name":"Slobozia","state_id":"2953"},
    {"city_id":"34467","city_name":"Stanesti","state_id":"2953"},
    {"city_id":"34468","city_name":"Stoenesti","state_id":"2953"},
    {"city_id":"34469","city_name":"Toporu","state_id":"2953"},
    {"city_id":"34470","city_name":"Ulmi","state_id":"2953"},
    {"city_id":"34471","city_name":"Valea Dragului","state_id":"2953"},
    {"city_id":"34472","city_name":"Vanatorii Mici","state_id":"2953"},
    {"city_id":"34473","city_name":"Varasti","state_id":"2953"},
    {"city_id":"34474","city_name":"Vedea","state_id":"2953"},
    {"city_id":"34475","city_name":"Albeni","state_id":"2954"},
    {"city_id":"34476","city_name":"Alimpesti","state_id":"2954"},
    {"city_id":"34477","city_name":"Aninoasa","state_id":"2954"},
    {"city_id":"34478","city_name":"Arcani","state_id":"2954"},
    {"city_id":"34479","city_name":"Baia de Fier","state_id":"2954"},
    {"city_id":"34480","city_name":"Balanesti","state_id":"2954"},
    {"city_id":"34481","city_name":"Balesti","state_id":"2954"},
    {"city_id":"34482","city_name":"Balteni","state_id":"2954"},
    {"city_id":"34483","city_name":"Barbatesti","state_id":"2954"},
    {"city_id":"34484","city_name":"Bengesti","state_id":"2954"},
    {"city_id":"34485","city_name":"Berlesti","state_id":"2954"},
    {"city_id":"34486","city_name":"Bolbosi","state_id":"2954"},
    {"city_id":"34487","city_name":"Borascu","state_id":"2954"},
    {"city_id":"34488","city_name":"Branesti","state_id":"2954"},
    {"city_id":"34489","city_name":"Bumbesti-Jiu","state_id":"2954"},
    {"city_id":"34490","city_name":"Bumbesti-Piticu","state_id":"2954"},
    {"city_id":"34491","city_name":"Bustuchin","state_id":"2954"},
    {"city_id":"34492","city_name":"Calnic","state_id":"2954"},
    {"city_id":"34493","city_name":"Capreni","state_id":"2954"},
    {"city_id":"34494","city_name":"Catunele","state_id":"2954"},
    {"city_id":"34495","city_name":"Ciuperceni","state_id":"2954"},
    {"city_id":"34496","city_name":"Crasna","state_id":"2954"},
    {"city_id":"34497","city_name":"Crusetu","state_id":"2954"},
    {"city_id":"34498","city_name":"Danciulesti","state_id":"2954"},
    {"city_id":"34499","city_name":"Danesti","state_id":"2954"},
    {"city_id":"34500","city_name":"Dragotesti","state_id":"2954"},
    {"city_id":"34501","city_name":"Dragutesti","state_id":"2954"},
    {"city_id":"34502","city_name":"Farcasesti","state_id":"2954"},
    {"city_id":"34503","city_name":"Glogova","state_id":"2954"},
    {"city_id":"34504","city_name":"Godinesti","state_id":"2954"},
    {"city_id":"34505","city_name":"Hurezani","state_id":"2954"},
    {"city_id":"34506","city_name":"Ionesti","state_id":"2954"},
    {"city_id":"34507","city_name":"Jupanesti","state_id":"2954"},
    {"city_id":"34508","city_name":"Lelesti","state_id":"2954"},
    {"city_id":"34509","city_name":"Licuriciu","state_id":"2954"},
    {"city_id":"34510","city_name":"Logresti","state_id":"2954"},
    {"city_id":"34511","city_name":"Matasari","state_id":"2954"},
    {"city_id":"34512","city_name":"Motru","state_id":"2954"},
    {"city_id":"34513","city_name":"Musetesti","state_id":"2954"},
    {"city_id":"34514","city_name":"Negomiru","state_id":"2954"},
    {"city_id":"34515","city_name":"Novaci","state_id":"2954"},
    {"city_id":"34516","city_name":"Pades","state_id":"2954"},
    {"city_id":"34517","city_name":"Pestisani","state_id":"2954"},
    {"city_id":"34518","city_name":"Plopsoru","state_id":"2954"},
    {"city_id":"34519","city_name":"Polovragi","state_id":"2954"},
    {"city_id":"34520","city_name":"Prigoria","state_id":"2954"},
    {"city_id":"34521","city_name":"Rosia de Amaradia","state_id":"2954"},
    {"city_id":"34522","city_name":"Rovinari","state_id":"2954"},
    {"city_id":"34523","city_name":"Runcu","state_id":"2954"},
    {"city_id":"34524","city_name":"Sacelu","state_id":"2954"},
    {"city_id":"34525","city_name":"Samarinesti","state_id":"2954"},
    {"city_id":"34526","city_name":"Saulesti","state_id":"2954"},
    {"city_id":"34527","city_name":"Schela","state_id":"2954"},
    {"city_id":"34528","city_name":"Scoarta","state_id":"2954"},
    {"city_id":"34529","city_name":"Slivilesti","state_id":"2954"},
    {"city_id":"34530","city_name":"Stanesti","state_id":"2954"},
    {"city_id":"34531","city_name":"Stejari","state_id":"2954"},
    {"city_id":"34532","city_name":"Stoina","state_id":"2954"},
    {"city_id":"34533","city_name":"Tantareni","state_id":"2954"},
    {"city_id":"34534","city_name":"Targu Carbunesti","state_id":"2954"},
    {"city_id":"34535","city_name":"Targu Jiu","state_id":"2954"},
    {"city_id":"34536","city_name":"Telesti","state_id":"2954"},
    {"city_id":"34537","city_name":"Ticleni","state_id":"2954"},
    {"city_id":"34538","city_name":"Tismana","state_id":"2954"},
    {"city_id":"34539","city_name":"Turburea","state_id":"2954"},
    {"city_id":"34540","city_name":"Turceni","state_id":"2954"},
    {"city_id":"34541","city_name":"Turcinesti","state_id":"2954"},
    {"city_id":"34542","city_name":"Urdari","state_id":"2954"},
    {"city_id":"34543","city_name":"Vagiulesti","state_id":"2954"},
    {"city_id":"34544","city_name":"Vladimir","state_id":"2954"},
    {"city_id":"34545","city_name":"Atid","state_id":"2955"},
    {"city_id":"34546","city_name":"Avramesti","state_id":"2955"},
    {"city_id":"34547","city_name":"Baile Tusnad","state_id":"2955"},
    {"city_id":"34548","city_name":"Balan","state_id":"2955"},
    {"city_id":"34549","city_name":"Bilbor","state_id":"2955"},
    {"city_id":"34550","city_name":"Borsec","state_id":"2955"},
    {"city_id":"34551","city_name":"Bradesti","state_id":"2955"},
    {"city_id":"34552","city_name":"Capalnita","state_id":"2955"},
    {"city_id":"34553","city_name":"Carta","state_id":"2955"},
    {"city_id":"34554","city_name":"Ciucsangeorgiu","state_id":"2955"},
    {"city_id":"34555","city_name":"Ciumani","state_id":"2955"},
    {"city_id":"34556","city_name":"Corbu","state_id":"2955"},
    {"city_id":"34557","city_name":"Corund","state_id":"2955"},
    {"city_id":"34558","city_name":"Cristuru Secuiesc","state_id":"2955"},
    {"city_id":"34559","city_name":"Danesti","state_id":"2955"},
    {"city_id":"34560","city_name":"Darjiu","state_id":"2955"},
    {"city_id":"34561","city_name":"Dealu","state_id":"2955"},
    {"city_id":"34562","city_name":"Ditrau","state_id":"2955"},
    {"city_id":"34563","city_name":"Feliceni","state_id":"2955"},
    {"city_id":"34564","city_name":"Frumoasa","state_id":"2955"},
    {"city_id":"34565","city_name":"Galautas","state_id":"2955"},
    {"city_id":"34566","city_name":"Gheorgheni","state_id":"2955"},
    {"city_id":"34567","city_name":"Joseni","state_id":"2955"},
    {"city_id":"34568","city_name":"Lazarea","state_id":"2955"},
    {"city_id":"34569","city_name":"Lueta","state_id":"2955"},
    {"city_id":"34570","city_name":"Lunca de Jos","state_id":"2955"},
    {"city_id":"34571","city_name":"Lunca de Sus","state_id":"2955"},
    {"city_id":"34572","city_name":"Lupeni","state_id":"2955"},
    {"city_id":"34573","city_name":"Martinis","state_id":"2955"},
    {"city_id":"34574","city_name":"Meresti","state_id":"2955"},
    {"city_id":"34575","city_name":"Miercurea-Ciuc","state_id":"2955"},
    {"city_id":"34576","city_name":"Mihaileni","state_id":"2955"},
    {"city_id":"34577","city_name":"Mugeni","state_id":"2955"},
    {"city_id":"34578","city_name":"Ocland","state_id":"2955"},
    {"city_id":"34579","city_name":"Odorheiu Secuiesc","state_id":"2955"},
    {"city_id":"34580","city_name":"Pauleni-Ciuc","state_id":"2955"},
    {"city_id":"34581","city_name":"Plaiesii-de-Jos","state_id":"2955"},
    {"city_id":"34582","city_name":"Praid","state_id":"2955"},
    {"city_id":"34583","city_name":"Remetea","state_id":"2955"},
    {"city_id":"34584","city_name":"Sacel","state_id":"2955"},
    {"city_id":"34585","city_name":"Sancraieni","state_id":"2955"},
    {"city_id":"34586","city_name":"Sandominic","state_id":"2955"},
    {"city_id":"34587","city_name":"Sanmartin","state_id":"2955"},
    {"city_id":"34588","city_name":"Sansimion","state_id":"2955"},
    {"city_id":"34589","city_name":"Sarmas","state_id":"2955"},
    {"city_id":"34590","city_name":"Secuieni","state_id":"2955"},
    {"city_id":"34591","city_name":"Siculeni","state_id":"2955"},
    {"city_id":"34592","city_name":"Simonesti","state_id":"2955"},
    {"city_id":"34593","city_name":"Subcetate","state_id":"2955"},
    {"city_id":"34594","city_name":"Suseni","state_id":"2955"},
    {"city_id":"34595","city_name":"Toplita","state_id":"2955"},
    {"city_id":"34596","city_name":"Tulghes","state_id":"2955"},
    {"city_id":"34597","city_name":"Tusnad","state_id":"2955"},
    {"city_id":"34598","city_name":"Ulies","state_id":"2955"},
    {"city_id":"34599","city_name":"Varsag","state_id":"2955"},
    {"city_id":"34600","city_name":"Vlahita","state_id":"2955"},
    {"city_id":"34601","city_name":"Voslobeni","state_id":"2955"},
    {"city_id":"34602","city_name":"Zetea","state_id":"2955"},
    {"city_id":"34603","city_name":"Aninoasa","state_id":"2956"},
    {"city_id":"34604","city_name":"Bacia","state_id":"2956"},
    {"city_id":"34605","city_name":"Baia de Cris","state_id":"2956"},
    {"city_id":"34606","city_name":"Baita","state_id":"2956"},
    {"city_id":"34607","city_name":"Balsa","state_id":"2956"},
    {"city_id":"34608","city_name":"Banita","state_id":"2956"},
    {"city_id":"34609","city_name":"Baru","state_id":"2956"},
    {"city_id":"34610","city_name":"Batrana","state_id":"2956"},
    {"city_id":"34611","city_name":"Beriu","state_id":"2956"},
    {"city_id":"34612","city_name":"Berthelot","state_id":"2956"},
    {"city_id":"34613","city_name":"Blajeni","state_id":"2956"},
    {"city_id":"34614","city_name":"Bosorod","state_id":"2956"},
    {"city_id":"34615","city_name":"Brad","state_id":"2956"},
    {"city_id":"34616","city_name":"Branisca","state_id":"2956"},
    {"city_id":"34617","city_name":"Bretea Romana","state_id":"2956"},
    {"city_id":"34618","city_name":"Buces","state_id":"2956"},
    {"city_id":"34619","city_name":"Bucuresci","state_id":"2956"},
    {"city_id":"34620","city_name":"Bulzesti","state_id":"2956"},
    {"city_id":"34621","city_name":"Bunila","state_id":"2956"},
    {"city_id":"34622","city_name":"Burjuc","state_id":"2956"},
    {"city_id":"34623","city_name":"Calan","state_id":"2956"},
    {"city_id":"34624","city_name":"Carjiti","state_id":"2956"},
    {"city_id":"34625","city_name":"Cerbal","state_id":"2956"},
    {"city_id":"34626","city_name":"Certeju de Sus","state_id":"2956"},
    {"city_id":"34627","city_name":"Criscior","state_id":"2956"},
    {"city_id":"34628","city_name":"Densus","state_id":"2956"},
    {"city_id":"34629","city_name":"Deva","state_id":"2956"},
    {"city_id":"34630","city_name":"Dobra","state_id":"2956"},
    {"city_id":"34631","city_name":"Geoagiu","state_id":"2956"},
    {"city_id":"34632","city_name":"Ghelari","state_id":"2956"},
    {"city_id":"34633","city_name":"Gurasada","state_id":"2956"},
    {"city_id":"34634","city_name":"Harau","state_id":"2956"},
    {"city_id":"34635","city_name":"Hateg","state_id":"2956"},
    {"city_id":"34636","city_name":"Hunedoara","state_id":"2956"},
    {"city_id":"34637","city_name":"Ilia","state_id":"2956"},
    {"city_id":"34638","city_name":"Lapugiu de Jos","state_id":"2956"},
    {"city_id":"34639","city_name":"Lelese","state_id":"2956"},
    {"city_id":"34640","city_name":"Lunca Cernii de Jos","state_id":"2956"},
    {"city_id":"34641","city_name":"Luncoiu de Jos","state_id":"2956"},
    {"city_id":"34642","city_name":"Lupeni","state_id":"2956"},
    {"city_id":"34643","city_name":"Martinesti","state_id":"2956"},
    {"city_id":"34644","city_name":"Orastie","state_id":"2956"},
    {"city_id":"34645","city_name":"Orastioara de Sus","state_id":"2956"},
    {"city_id":"34646","city_name":"Pestisu Mic","state_id":"2956"},
    {"city_id":"34647","city_name":"Petrila","state_id":"2956"},
    {"city_id":"34648","city_name":"Petrosani","state_id":"2956"},
    {"city_id":"34649","city_name":"Pui","state_id":"2956"},
    {"city_id":"34650","city_name":"Rachitova","state_id":"2956"},
    {"city_id":"34651","city_name":"Rapoltu Mare","state_id":"2956"},
    {"city_id":"34652","city_name":"Rau de Mori","state_id":"2956"},
    {"city_id":"34653","city_name":"Ribita","state_id":"2956"},
    {"city_id":"34654","city_name":"Romos","state_id":"2956"},
    {"city_id":"34655","city_name":"Salasu de Sus","state_id":"2956"},
    {"city_id":"34656","city_name":"Santamaria Orlea","state_id":"2956"},
    {"city_id":"34657","city_name":"Sarmizegetusa","state_id":"2956"},
    {"city_id":"34658","city_name":"Simeria","state_id":"2956"},
    {"city_id":"34659","city_name":"Soimus","state_id":"2956"},
    {"city_id":"34660","city_name":"Teliucu Inferior","state_id":"2956"},
    {"city_id":"34661","city_name":"Tomesti","state_id":"2956"},
    {"city_id":"34662","city_name":"Toplita","state_id":"2956"},
    {"city_id":"34663","city_name":"Totesti","state_id":"2956"},
    {"city_id":"34664","city_name":"Turdas","state_id":"2956"},
    {"city_id":"34665","city_name":"Uricani","state_id":"2956"},
    {"city_id":"34666","city_name":"Valisoara","state_id":"2956"},
    {"city_id":"34667","city_name":"Vata de Jos","state_id":"2956"},
    {"city_id":"34668","city_name":"Vetel","state_id":"2956"},
    {"city_id":"34669","city_name":"Vorta","state_id":"2956"},
    {"city_id":"34670","city_name":"Vulcan","state_id":"2956"},
    {"city_id":"34671","city_name":"Zam","state_id":"2956"},
    {"city_id":"34672","city_name":"Adancata","state_id":"2957"},
    {"city_id":"34673","city_name":"Albesti","state_id":"2957"},
    {"city_id":"34674","city_name":"Alexeni","state_id":"2957"},
    {"city_id":"34675","city_name":"Amara","state_id":"2957"},
    {"city_id":"34676","city_name":"Andrasesti","state_id":"2957"},
    {"city_id":"34677","city_name":"Armasesti","state_id":"2957"},
    {"city_id":"34678","city_name":"Axintele","state_id":"2957"},
    {"city_id":"34679","city_name":"Balaciu","state_id":"2957"},
    {"city_id":"34680","city_name":"Barcanesti","state_id":"2957"},
    {"city_id":"34681","city_name":"Bordusani","state_id":"2957"},
    {"city_id":"34682","city_name":"Brazii","state_id":"2957"},
    {"city_id":"34683","city_name":"Bucu","state_id":"2957"},
    {"city_id":"34684","city_name":"Cazanesti","state_id":"2957"},
    {"city_id":"34685","city_name":"Ciocarlia","state_id":"2957"},
    {"city_id":"34686","city_name":"Ciochina","state_id":"2957"},
    {"city_id":"34687","city_name":"Ciulnita","state_id":"2957"},
    {"city_id":"34688","city_name":"Cocora","state_id":"2957"},
    {"city_id":"34689","city_name":"Cosambesti","state_id":"2957"},
    {"city_id":"34690","city_name":"Cosereni","state_id":"2957"},
    {"city_id":"34691","city_name":"Dragoesti","state_id":"2957"},
    {"city_id":"34692","city_name":"Dridu","state_id":"2957"},
    {"city_id":"34693","city_name":"Facaeni","state_id":"2957"},
    {"city_id":"34694","city_name":"Fetesti","state_id":"2957"},
    {"city_id":"34695","city_name":"Fierbinti-Targ","state_id":"2957"},
    {"city_id":"34696","city_name":"Garbovi","state_id":"2957"},
    {"city_id":"34697","city_name":"Gheorghe Doja","state_id":"2957"},
    {"city_id":"34698","city_name":"Gheorghe Lazar","state_id":"2957"},
    {"city_id":"34699","city_name":"Giurgeni","state_id":"2957"},
    {"city_id":"34700","city_name":"Grindu","state_id":"2957"},
    {"city_id":"34701","city_name":"Grivita","state_id":"2957"},
    {"city_id":"34702","city_name":"Ion Roata","state_id":"2957"},
    {"city_id":"34703","city_name":"Jilavele","state_id":"2957"},
    {"city_id":"34704","city_name":"Manasia","state_id":"2957"},
    {"city_id":"34705","city_name":"Mihail Kogalniceanu","state_id":"2957"},
    {"city_id":"34706","city_name":"Milosesti","state_id":"2957"},
    {"city_id":"34707","city_name":"Movila","state_id":"2957"},
    {"city_id":"34708","city_name":"Movilita","state_id":"2957"},
    {"city_id":"34709","city_name":"Munteni Buzau","state_id":"2957"},
    {"city_id":"34710","city_name":"Perieti","state_id":"2957"},
    {"city_id":"34711","city_name":"Reviga","state_id":"2957"},
    {"city_id":"34712","city_name":"Salcioara","state_id":"2957"},
    {"city_id":"34713","city_name":"Saveni","state_id":"2957"},
    {"city_id":"34714","city_name":"Scanteia","state_id":"2957"},
    {"city_id":"34715","city_name":"Sfantu Gheorghe","state_id":"2957"},
    {"city_id":"34716","city_name":"Sinesti","state_id":"2957"},
    {"city_id":"34717","city_name":"Slobozia","state_id":"2957"},
    {"city_id":"34718","city_name":"Stelnica","state_id":"2957"},
    {"city_id":"34719","city_name":"Suditi","state_id":"2957"},
    {"city_id":"34720","city_name":"Tandarei","state_id":"2957"},
    {"city_id":"34721","city_name":"Urziceni","state_id":"2957"},
    {"city_id":"34722","city_name":"Valea Ciorii","state_id":"2957"},
    {"city_id":"34723","city_name":"Valea Macrisului","state_id":"2957"},
    {"city_id":"34724","city_name":"Vladeni","state_id":"2957"},
    {"city_id":"34725","city_name":"Alexandru Ioan Cuza","state_id":"2958"},
    {"city_id":"34726","city_name":"Andrieseni","state_id":"2958"},
    {"city_id":"34727","city_name":"Aroneanu","state_id":"2958"},
    {"city_id":"34728","city_name":"Baltati","state_id":"2958"},
    {"city_id":"34729","city_name":"Barnova","state_id":"2958"},
    {"city_id":"34730","city_name":"Belcesti","state_id":"2958"},
    {"city_id":"34731","city_name":"Bivolari","state_id":"2958"},
    {"city_id":"34732","city_name":"Braesti","state_id":"2958"},
    {"city_id":"34733","city_name":"Butea","state_id":"2958"},
    {"city_id":"34734","city_name":"Ceplenita","state_id":"2958"},
    {"city_id":"34735","city_name":"Ciortesti","state_id":"2958"},
    {"city_id":"34736","city_name":"Ciurea","state_id":"2958"},
    {"city_id":"34737","city_name":"Coarnele Caprei","state_id":"2958"},
    {"city_id":"34738","city_name":"Comarna","state_id":"2958"},
    {"city_id":"34739","city_name":"Costuleni","state_id":"2958"},
    {"city_id":"34740","city_name":"Cotnari","state_id":"2958"},
    {"city_id":"34741","city_name":"Cozmesti","state_id":"2958"},
    {"city_id":"34742","city_name":"Cristesti","state_id":"2958"},
    {"city_id":"34743","city_name":"Cucuteni","state_id":"2958"},
    {"city_id":"34744","city_name":"Dagata","state_id":"2958"},
    {"city_id":"34745","city_name":"Deleni","state_id":"2958"},
    {"city_id":"34746","city_name":"Dobrovat","state_id":"2958"},
    {"city_id":"34747","city_name":"Dolhesti","state_id":"2958"},
    {"city_id":"34748","city_name":"Dumesti","state_id":"2958"},
    {"city_id":"34749","city_name":"Erbiceni","state_id":"2958"},
    {"city_id":"34750","city_name":"Focuri","state_id":"2958"},
    {"city_id":"34751","city_name":"Golaesti","state_id":"2958"},
    {"city_id":"34752","city_name":"Gorban","state_id":"2958"},
    {"city_id":"34753","city_name":"Grajduri","state_id":"2958"},
    {"city_id":"34754","city_name":"Gropnita","state_id":"2958"},
    {"city_id":"34755","city_name":"Grozesti","state_id":"2958"},
    {"city_id":"34756","city_name":"Halaucesti","state_id":"2958"},
    {"city_id":"34757","city_name":"Harlau","state_id":"2958"},
    {"city_id":"34758","city_name":"Helesteni Harmaneasa","state_id":"2958"},
    {"city_id":"34759","city_name":"Holboca","state_id":"2958"},
    {"city_id":"34760","city_name":"Horlesti","state_id":"2958"},
    {"city_id":"34761","city_name":"Iasi","state_id":"2958"},
    {"city_id":"34762","city_name":"Ipatele","state_id":"2958"},
    {"city_id":"34763","city_name":"Lespezi","state_id":"2958"},
    {"city_id":"34764","city_name":"Letcani","state_id":"2958"},
    {"city_id":"34765","city_name":"Lungani","state_id":"2958"},
    {"city_id":"34766","city_name":"Madarjac","state_id":"2958"},
    {"city_id":"34767","city_name":"Mircesti","state_id":"2958"},
    {"city_id":"34768","city_name":"Mironeasa","state_id":"2958"},
    {"city_id":"34769","city_name":"Miroslava","state_id":"2958"},
    {"city_id":"34770","city_name":"Miroslovesti","state_id":"2958"},
    {"city_id":"34771","city_name":"Mogosesti","state_id":"2958"},
    {"city_id":"34772","city_name":"Mogosesti-Siret","state_id":"2958"},
    {"city_id":"34773","city_name":"Mosna","state_id":"2958"},
    {"city_id":"34774","city_name":"Motca","state_id":"2958"},
    {"city_id":"34775","city_name":"Movileni","state_id":"2958"},
    {"city_id":"34776","city_name":"Oteleni","state_id":"2958"},
    {"city_id":"34777","city_name":"Pascani","state_id":"2958"},
    {"city_id":"34778","city_name":"Plugari","state_id":"2958"},
    {"city_id":"34779","city_name":"Podu Iloaiei","state_id":"2958"},
    {"city_id":"34780","city_name":"Popesti","state_id":"2958"},
    {"city_id":"34781","city_name":"Popricani","state_id":"2958"},
    {"city_id":"34782","city_name":"Prisacani","state_id":"2958"},
    {"city_id":"34783","city_name":"Probota","state_id":"2958"},
    {"city_id":"34784","city_name":"Raducaneni","state_id":"2958"},
    {"city_id":"34785","city_name":"Rediu","state_id":"2958"},
    {"city_id":"34786","city_name":"Romanesti","state_id":"2958"},
    {"city_id":"34787","city_name":"Ruginoasa","state_id":"2958"},
    {"city_id":"34788","city_name":"Scanteia","state_id":"2958"},
    {"city_id":"34789","city_name":"Scheia","state_id":"2958"},
    {"city_id":"34790","city_name":"Schitu-Duca","state_id":"2958"},
    {"city_id":"34791","city_name":"Scobinti","state_id":"2958"},
    {"city_id":"34792","city_name":"Sinesti","state_id":"2958"},
    {"city_id":"34793","city_name":"Sipote","state_id":"2958"},
    {"city_id":"34794","city_name":"Siretel","state_id":"2958"},
    {"city_id":"34795","city_name":"Stolniceni-Prajescu","state_id":"2958"},
    {"city_id":"34796","city_name":"Strunga","state_id":"2958"},
    {"city_id":"34797","city_name":"Tansa","state_id":"2958"},
    {"city_id":"34798","city_name":"Targu Gangiulesti","state_id":"2958"},
    {"city_id":"34799","city_name":"Tatarusi","state_id":"2958"},
    {"city_id":"34800","city_name":"Tibana","state_id":"2958"},
    {"city_id":"34801","city_name":"Tibanesti","state_id":"2958"},
    {"city_id":"34802","city_name":"Tiganasi","state_id":"2958"},
    {"city_id":"34803","city_name":"Todiresti","state_id":"2958"},
    {"city_id":"34804","city_name":"Tomesti","state_id":"2958"},
    {"city_id":"34805","city_name":"Trifesti","state_id":"2958"},
    {"city_id":"34806","city_name":"Tutora","state_id":"2958"},
    {"city_id":"34807","city_name":"Ungheni","state_id":"2958"},
    {"city_id":"34808","city_name":"Valea Seaca","state_id":"2958"},
    {"city_id":"34809","city_name":"Vanatori","state_id":"2958"},
    {"city_id":"34810","city_name":"Victoria","state_id":"2958"},
    {"city_id":"34811","city_name":"Vladeni","state_id":"2958"},
    {"city_id":"34812","city_name":"Voinesti","state_id":"2958"},
    {"city_id":"34813","city_name":"Afumati","state_id":"2959"},
    {"city_id":"34814","city_name":"Balotesti","state_id":"2959"},
    {"city_id":"34815","city_name":"Berceni","state_id":"2959"},
    {"city_id":"34816","city_name":"Bragadiru","state_id":"2959"},
    {"city_id":"34817","city_name":"Branesti","state_id":"2959"},
    {"city_id":"34818","city_name":"Buftea","state_id":"2959"},
    {"city_id":"34819","city_name":"Cernica","state_id":"2959"},
    {"city_id":"34820","city_name":"Chiajna","state_id":"2959"},
    {"city_id":"34821","city_name":"Chitila","state_id":"2959"},
    {"city_id":"34822","city_name":"Ciolpani","state_id":"2959"},
    {"city_id":"34823","city_name":"Ciorogarla","state_id":"2959"},
    {"city_id":"34824","city_name":"Clinceni","state_id":"2959"},
    {"city_id":"34825","city_name":"Corbeanca","state_id":"2959"},
    {"city_id":"34826","city_name":"Cornetu","state_id":"2959"},
    {"city_id":"34827","city_name":"Darasti-Ilfov","state_id":"2959"},
    {"city_id":"34828","city_name":"Dascalu","state_id":"2959"},
    {"city_id":"34829","city_name":"Dobroesti","state_id":"2959"},
    {"city_id":"34830","city_name":"Domnesti","state_id":"2959"},
    {"city_id":"34831","city_name":"Dragomiresti-Vale","state_id":"2959"},
    {"city_id":"34832","city_name":"Ganeasa","state_id":"2959"},
    {"city_id":"34833","city_name":"Glina","state_id":"2959"},
    {"city_id":"34834","city_name":"Gradistea","state_id":"2959"},
    {"city_id":"34835","city_name":"Gruiu","state_id":"2959"},
    {"city_id":"34836","city_name":"Jilava","state_id":"2959"},
    {"city_id":"34837","city_name":"Magurele","state_id":"2959"},
    {"city_id":"34838","city_name":"Moara Vlasiei","state_id":"2959"},
    {"city_id":"34839","city_name":"Mogosoaia","state_id":"2959"},
    {"city_id":"34840","city_name":"Nuci","state_id":"2959"},
    {"city_id":"34841","city_name":"Otopeni","state_id":"2959"},
    {"city_id":"34842","city_name":"Pantelimon","state_id":"2959"},
    {"city_id":"34843","city_name":"Peris","state_id":"2959"},
    {"city_id":"34844","city_name":"Petrachioaia","state_id":"2959"},
    {"city_id":"34845","city_name":"Popesti-Leordeni","state_id":"2959"},
    {"city_id":"34846","city_name":"Prim Decembrie","state_id":"2959"},
    {"city_id":"34847","city_name":"Snagov","state_id":"2959"},
    {"city_id":"34848","city_name":"Stefanestii de Jos","state_id":"2959"},
    {"city_id":"34849","city_name":"Tunari","state_id":"2959"},
    {"city_id":"34850","city_name":"Vidra","state_id":"2959"},
    {"city_id":"34851","city_name":"Voluntari","state_id":"2959"},
    {"city_id":"34852","city_name":"Ardusat","state_id":"2960"},
    {"city_id":"34853","city_name":"Arinis","state_id":"2960"},
    {"city_id":"34854","city_name":"Asuaju de Sus","state_id":"2960"},
    {"city_id":"34855","city_name":"Baia Mare","state_id":"2960"},
    {"city_id":"34856","city_name":"Baia-Sprie","state_id":"2960"},
    {"city_id":"34857","city_name":"Baita de sub Codru","state_id":"2960"},
    {"city_id":"34858","city_name":"Baiut","state_id":"2960"},
    {"city_id":"34859","city_name":"Barsana","state_id":"2960"},
    {"city_id":"34860","city_name":"Basesti","state_id":"2960"},
    {"city_id":"34861","city_name":"Bicaz","state_id":"2960"},
    {"city_id":"34862","city_name":"Bistra","state_id":"2960"},
    {"city_id":"34863","city_name":"Bocicoiu Mare","state_id":"2960"},
    {"city_id":"34864","city_name":"Bogdan Voda","state_id":"2960"},
    {"city_id":"34865","city_name":"Boiu Mare","state_id":"2960"},
    {"city_id":"34866","city_name":"Borsa","state_id":"2960"},
    {"city_id":"34867","city_name":"Botiza","state_id":"2960"},
    {"city_id":"34868","city_name":"Budesti","state_id":"2960"},
    {"city_id":"34869","city_name":"Calinesti","state_id":"2960"},
    {"city_id":"34870","city_name":"Campulung la Tisa","state_id":"2960"},
    {"city_id":"34871","city_name":"Cavnic","state_id":"2960"},
    {"city_id":"34872","city_name":"Cernesti","state_id":"2960"},
    {"city_id":"34873","city_name":"Cicarlau","state_id":"2960"},
    {"city_id":"34874","city_name":"Copalnic Manastur","state_id":"2960"},
    {"city_id":"34875","city_name":"Coroieni","state_id":"2960"},
    {"city_id":"34876","city_name":"Cupseni","state_id":"2960"},
    {"city_id":"34877","city_name":"Desesti","state_id":"2960"},
    {"city_id":"34878","city_name":"Dragomiresti","state_id":"2960"},
    {"city_id":"34879","city_name":"Dumbravita","state_id":"2960"},
    {"city_id":"34880","city_name":"Farcasa","state_id":"2960"},
    {"city_id":"34881","city_name":"Giulesti","state_id":"2960"},
    {"city_id":"34882","city_name":"Grosi","state_id":"2960"},
    {"city_id":"34883","city_name":"Ieud","state_id":"2960"},
    {"city_id":"34884","city_name":"Lapus","state_id":"2960"},
    {"city_id":"34885","city_name":"Leordina","state_id":"2960"},
    {"city_id":"34886","city_name":"Miresu Mare","state_id":"2960"},
    {"city_id":"34887","city_name":"Moisei","state_id":"2960"},
    {"city_id":"34888","city_name":"Oarta de Jos","state_id":"2960"},
    {"city_id":"34889","city_name":"Ocna Sugatag","state_id":"2960"},
    {"city_id":"34890","city_name":"Petrova","state_id":"2960"},
    {"city_id":"34891","city_name":"Poienile Izei","state_id":"2960"},
    {"city_id":"34892","city_name":"Poienile de sub Munte","state_id":"2960"},
    {"city_id":"34893","city_name":"Recea","state_id":"2960"},
    {"city_id":"34894","city_name":"Remetea Chioarului","state_id":"2960"},
    {"city_id":"34895","city_name":"Remeti","state_id":"2960"},
    {"city_id":"34896","city_name":"Repedea","state_id":"2960"},
    {"city_id":"34897","city_name":"Rona de Jos","state_id":"2960"},
    {"city_id":"34898","city_name":"Rona de Sus","state_id":"2960"},
    {"city_id":"34899","city_name":"Rozavlea","state_id":"2960"},
    {"city_id":"34900","city_name":"Ruscova","state_id":"2960"},
    {"city_id":"34901","city_name":"Sacalaseni","state_id":"2960"},
    {"city_id":"34902","city_name":"Sacel","state_id":"2960"},
    {"city_id":"34903","city_name":"Salistea de Sus","state_id":"2960"},
    {"city_id":"34904","city_name":"Salsig","state_id":"2960"},
    {"city_id":"34905","city_name":"Sapanta","state_id":"2960"},
    {"city_id":"34906","city_name":"Sarasau","state_id":"2960"},
    {"city_id":"34907","city_name":"Sasar","state_id":"2960"},
    {"city_id":"34908","city_name":"Satulung","state_id":"2960"},
    {"city_id":"34909","city_name":"Seini","state_id":"2960"},
    {"city_id":"34910","city_name":"Sighetu Marmatiei","state_id":"2960"},
    {"city_id":"34911","city_name":"Sisesti","state_id":"2960"},
    {"city_id":"34912","city_name":"Somcuta Mare","state_id":"2960"},
    {"city_id":"34913","city_name":"Stramtura","state_id":"2960"},
    {"city_id":"34914","city_name":"Suciu de Sus","state_id":"2960"},
    {"city_id":"34915","city_name":"Targu-Lapus","state_id":"2960"},
    {"city_id":"34916","city_name":"Tautii Margheraus","state_id":"2960"},
    {"city_id":"34917","city_name":"Ulmeni","state_id":"2960"},
    {"city_id":"34918","city_name":"Vadu Izei","state_id":"2960"},
    {"city_id":"34919","city_name":"Valea Chioarului","state_id":"2960"},
    {"city_id":"34920","city_name":"Vima Mica","state_id":"2960"},
    {"city_id":"34921","city_name":"Viseu de Jos","state_id":"2960"},
    {"city_id":"34922","city_name":"Viseu de Sus","state_id":"2960"},
    {"city_id":"34923","city_name":"Baclesu","state_id":"2961"},
    {"city_id":"34924","city_name":"Baia de Arama","state_id":"2961"},
    {"city_id":"34925","city_name":"Bala","state_id":"2961"},
    {"city_id":"34926","city_name":"Balacita","state_id":"2961"},
    {"city_id":"34927","city_name":"Balta","state_id":"2961"},
    {"city_id":"34928","city_name":"Balvanesti","state_id":"2961"},
    {"city_id":"34929","city_name":"Breznita Ocol","state_id":"2961"},
    {"city_id":"34930","city_name":"Breznita-Motru","state_id":"2961"},
    {"city_id":"34931","city_name":"Brosteni","state_id":"2961"},
    {"city_id":"34932","city_name":"Burila Mare","state_id":"2961"},
    {"city_id":"34933","city_name":"Butoiesti","state_id":"2961"},
    {"city_id":"34934","city_name":"Cazanesti","state_id":"2961"},
    {"city_id":"34935","city_name":"Ciresu","state_id":"2961"},
    {"city_id":"34936","city_name":"Corcova","state_id":"2961"},
    {"city_id":"34937","city_name":"Corlatel","state_id":"2961"},
    {"city_id":"34938","city_name":"Cujmir","state_id":"2961"},
    {"city_id":"34939","city_name":"Darvari","state_id":"2961"},
    {"city_id":"34940","city_name":"Devesel","state_id":"2961"},
    {"city_id":"34941","city_name":"Drobeta-Turnu Severin","state_id":"2961"},
    {"city_id":"34942","city_name":"Dubova","state_id":"2961"},
    {"city_id":"34943","city_name":"Dumbrava","state_id":"2961"},
    {"city_id":"34944","city_name":"Eselnita","state_id":"2961"},
    {"city_id":"34945","city_name":"Floresti","state_id":"2961"},
    {"city_id":"34946","city_name":"Garla Mare","state_id":"2961"},
    {"city_id":"34947","city_name":"Godeanu","state_id":"2961"},
    {"city_id":"34948","city_name":"Gogosu","state_id":"2961"},
    {"city_id":"34949","city_name":"Greci","state_id":"2961"},
    {"city_id":"34950","city_name":"Grozesti","state_id":"2961"},
    {"city_id":"34951","city_name":"Gruia","state_id":"2961"},
    {"city_id":"34952","city_name":"Hinova","state_id":"2961"},
    {"city_id":"34953","city_name":"Husnicioara","state_id":"2961"},
    {"city_id":"34954","city_name":"Ilovat","state_id":"2961"},
    {"city_id":"34955","city_name":"Ilovita","state_id":"2961"},
    {"city_id":"34956","city_name":"Isverna","state_id":"2961"},
    {"city_id":"34957","city_name":"Izvoru Barzii","state_id":"2961"},
    {"city_id":"34958","city_name":"Jiana","state_id":"2961"},
    {"city_id":"34959","city_name":"Livezile","state_id":"2961"},
    {"city_id":"34960","city_name":"Malovat","state_id":"2961"},
    {"city_id":"34961","city_name":"Obarsia Noua","state_id":"2961"},
    {"city_id":"34962","city_name":"Obarsia-Closani","state_id":"2961"},
    {"city_id":"34963","city_name":"Oprisor","state_id":"2961"},
    {"city_id":"34964","city_name":"Orsova","state_id":"2961"},
    {"city_id":"34965","city_name":"Padina","state_id":"2961"},
    {"city_id":"34966","city_name":"Patulele","state_id":"2961"},
    {"city_id":"34967","city_name":"Podeni","state_id":"2961"},
    {"city_id":"34968","city_name":"Ponoarele","state_id":"2961"},
    {"city_id":"34969","city_name":"Poroina Mare","state_id":"2961"},
    {"city_id":"34970","city_name":"Pristol","state_id":"2961"},
    {"city_id":"34971","city_name":"Prunisor","state_id":"2961"},
    {"city_id":"34972","city_name":"Punghina","state_id":"2961"},
    {"city_id":"34973","city_name":"Rogova","state_id":"2961"},
    {"city_id":"34974","city_name":"Salcia","state_id":"2961"},
    {"city_id":"34975","city_name":"Simian","state_id":"2961"},
    {"city_id":"34976","city_name":"Sisesti","state_id":"2961"},
    {"city_id":"34977","city_name":"Sovarna","state_id":"2961"},
    {"city_id":"34978","city_name":"Stangaceaua","state_id":"2961"},
    {"city_id":"34979","city_name":"Strehaia","state_id":"2961"},
    {"city_id":"34980","city_name":"Svinita","state_id":"2961"},
    {"city_id":"34981","city_name":"Tamna","state_id":"2961"},
    {"city_id":"34982","city_name":"Vanatori","state_id":"2961"},
    {"city_id":"34983","city_name":"Vanju Mare","state_id":"2961"},
    {"city_id":"34984","city_name":"Vanjulet","state_id":"2961"},
    {"city_id":"34985","city_name":"Vladaia","state_id":"2961"},
    {"city_id":"34986","city_name":"Voloiac","state_id":"2961"},
    {"city_id":"34987","city_name":"Acatari","state_id":"2962"},
    {"city_id":"34988","city_name":"Adamus","state_id":"2962"},
    {"city_id":"34989","city_name":"Albesti","state_id":"2962"},
    {"city_id":"34990","city_name":"Alunis","state_id":"2962"},
    {"city_id":"34991","city_name":"Apold","state_id":"2962"},
    {"city_id":"34992","city_name":"Atintis","state_id":"2962"},
    {"city_id":"34993","city_name":"Bagaciu","state_id":"2962"},
    {"city_id":"34994","city_name":"Bahnea","state_id":"2962"},
    {"city_id":"34995","city_name":"Bala","state_id":"2962"},
    {"city_id":"34996","city_name":"Balauseri","state_id":"2962"},
    {"city_id":"34997","city_name":"Band","state_id":"2962"},
    {"city_id":"34998","city_name":"Batos","state_id":"2962"},
    {"city_id":"34999","city_name":"Beica de Jos","state_id":"2962"},
    {"city_id":"35000","city_name":"Bichis","state_id":"2962"},
    {"city_id":"35001","city_name":"Bogata","state_id":"2962"},
    {"city_id":"35002","city_name":"Brancovenesti","state_id":"2962"},
    {"city_id":"35003","city_name":"Breaza","state_id":"2962"},
    {"city_id":"35004","city_name":"Ceuasul-de Campie","state_id":"2962"},
    {"city_id":"35005","city_name":"Chetani","state_id":"2962"},
    {"city_id":"35006","city_name":"Chiheru de Jos","state_id":"2962"},
    {"city_id":"35007","city_name":"Coroisanmartin","state_id":"2962"},
    {"city_id":"35008","city_name":"Cozma","state_id":"2962"},
    {"city_id":"35009","city_name":"Craciunesti","state_id":"2962"},
    {"city_id":"35010","city_name":"Craiesti","state_id":"2962"},
    {"city_id":"35011","city_name":"Cristesti","state_id":"2962"},
    {"city_id":"35012","city_name":"Cucerdea","state_id":"2962"},
    {"city_id":"35013","city_name":"Cuci","state_id":"2962"},
    {"city_id":"35014","city_name":"Danes","state_id":"2962"},
    {"city_id":"35015","city_name":"Deda","state_id":"2962"},
    {"city_id":"35016","city_name":"Eremitu","state_id":"2962"},
    {"city_id":"35017","city_name":"Ernei","state_id":"2962"},
    {"city_id":"35018","city_name":"Fantanele","state_id":"2962"},
    {"city_id":"35019","city_name":"Faragau","state_id":"2962"},
    {"city_id":"35020","city_name":"Galesti","state_id":"2962"},
    {"city_id":"35021","city_name":"Ganesti","state_id":"2962"},
    {"city_id":"35022","city_name":"Gheorghe Doja","state_id":"2962"},
    {"city_id":"35023","city_name":"Ghindari","state_id":"2962"},
    {"city_id":"35024","city_name":"Glodeni","state_id":"2962"},
    {"city_id":"35025","city_name":"Gornesti","state_id":"2962"},
    {"city_id":"35026","city_name":"Grebenisu de Campie","state_id":"2962"},
    {"city_id":"35027","city_name":"Gurghiu","state_id":"2962"},
    {"city_id":"35028","city_name":"Hodac","state_id":"2962"},
    {"city_id":"35029","city_name":"Hodosa","state_id":"2962"},
    {"city_id":"35030","city_name":"Ibanesti","state_id":"2962"},
    {"city_id":"35031","city_name":"Iclanzel","state_id":"2962"},
    {"city_id":"35032","city_name":"Ideciu de Jos","state_id":"2962"},
    {"city_id":"35033","city_name":"Iernut","state_id":"2962"},
    {"city_id":"35034","city_name":"Livezeni","state_id":"2962"},
    {"city_id":"35035","city_name":"Ludus","state_id":"2962"},
    {"city_id":"35036","city_name":"Lunca","state_id":"2962"},
    {"city_id":"35037","city_name":"Lunca Bradului","state_id":"2962"},
    {"city_id":"35038","city_name":"Magherani","state_id":"2962"},
    {"city_id":"35039","city_name":"Mica","state_id":"2962"},
    {"city_id":"35040","city_name":"Miercurea Nirajului","state_id":"2962"},
    {"city_id":"35041","city_name":"Mihesu de Campie","state_id":"2962"},
    {"city_id":"35042","city_name":"Nades","state_id":"2962"},
    {"city_id":"35043","city_name":"Neaua","state_id":"2962"},
    {"city_id":"35044","city_name":"Ogra","state_id":"2962"},
    {"city_id":"35045","city_name":"Panet","state_id":"2962"},
    {"city_id":"35046","city_name":"Papiu Ilarian","state_id":"2962"},
    {"city_id":"35047","city_name":"Pasareni","state_id":"2962"},
    {"city_id":"35048","city_name":"Petelea","state_id":"2962"},
    {"city_id":"35049","city_name":"Pogaceaua","state_id":"2962"},
    {"city_id":"35050","city_name":"Raciu","state_id":"2962"},
    {"city_id":"35051","city_name":"Rastolita","state_id":"2962"},
    {"city_id":"35052","city_name":"Reghin","state_id":"2962"},
    {"city_id":"35053","city_name":"Rusii Munti","state_id":"2962"},
    {"city_id":"35054","city_name":"Sancraiul-de-Mures","state_id":"2962"},
    {"city_id":"35055","city_name":"Sangeorg-de Mures","state_id":"2962"},
    {"city_id":"35056","city_name":"Sangeorgiu-de-Padure","state_id":"2962"},
    {"city_id":"35057","city_name":"Sanger","state_id":"2962"},
    {"city_id":"35058","city_name":"Sanpaul","state_id":"2962"},
    {"city_id":"35059","city_name":"Sanpetru-de-Campie","state_id":"2962"},
    {"city_id":"35060","city_name":"Santana-de-Mures","state_id":"2962"},
    {"city_id":"35061","city_name":"Sarmasu","state_id":"2962"},
    {"city_id":"35062","city_name":"Saschiz","state_id":"2962"},
    {"city_id":"35063","city_name":"Saulia","state_id":"2962"},
    {"city_id":"35064","city_name":"Sighisoara","state_id":"2962"},
    {"city_id":"35065","city_name":"Sincai","state_id":"2962"},
    {"city_id":"35066","city_name":"Solovastru","state_id":"2962"},
    {"city_id":"35067","city_name":"Sovata","state_id":"2962"},
    {"city_id":"35068","city_name":"Stanceni","state_id":"2962"},
    {"city_id":"35069","city_name":"Suplac","state_id":"2962"},
    {"city_id":"35070","city_name":"Suseni","state_id":"2962"},
    {"city_id":"35071","city_name":"Targu-Mures","state_id":"2962"},
    {"city_id":"35072","city_name":"Tarnaveni","state_id":"2962"},
    {"city_id":"35073","city_name":"Taureni","state_id":"2962"},
    {"city_id":"35074","city_name":"Ungheni","state_id":"2962"},
    {"city_id":"35075","city_name":"Valea Larga","state_id":"2962"},
    {"city_id":"35076","city_name":"Vanatori","state_id":"2962"},
    {"city_id":"35077","city_name":"Vargata","state_id":"2962"},
    {"city_id":"35078","city_name":"Vatava","state_id":"2962"},
    {"city_id":"35079","city_name":"Vetca","state_id":"2962"},
    {"city_id":"35080","city_name":"Viisoara","state_id":"2962"},
    {"city_id":"35081","city_name":"Voivodeni","state_id":"2962"},
    {"city_id":"35082","city_name":"Zagar","state_id":"2962"},
    {"city_id":"35083","city_name":"Zau de Campie","state_id":"2962"},
    {"city_id":"35084","city_name":"Agapia","state_id":"2963"},
    {"city_id":"35085","city_name":"Bahna","state_id":"2963"},
    {"city_id":"35086","city_name":"Baltatesti","state_id":"2963"},
    {"city_id":"35087","city_name":"Bara","state_id":"2963"},
    {"city_id":"35088","city_name":"Bargaoani","state_id":"2963"},
    {"city_id":"35089","city_name":"Bicaz","state_id":"2963"},
    {"city_id":"35090","city_name":"Bicaz Chei","state_id":"2963"},
    {"city_id":"35091","city_name":"Bicazu Ardelean","state_id":"2963"},
    {"city_id":"35092","city_name":"Bodesti","state_id":"2963"},
    {"city_id":"35093","city_name":"Borca","state_id":"2963"},
    {"city_id":"35094","city_name":"Borlesti","state_id":"2963"},
    {"city_id":"35095","city_name":"Botesti","state_id":"2963"},
    {"city_id":"35096","city_name":"Bozieni","state_id":"2963"},
    {"city_id":"35097","city_name":"Brusturi-Draganesti","state_id":"2963"},
    {"city_id":"35098","city_name":"Candesti","state_id":"2963"},
    {"city_id":"35099","city_name":"Ceahlau","state_id":"2963"},
    {"city_id":"35100","city_name":"Cordun","state_id":"2963"},
    {"city_id":"35101","city_name":"Costisa","state_id":"2963"},
    {"city_id":"35102","city_name":"Cracaoani","state_id":"2963"},
    {"city_id":"35103","city_name":"Damuc","state_id":"2963"},
    {"city_id":"35104","city_name":"Dobreni","state_id":"2963"},
    {"city_id":"35105","city_name":"Doljesti","state_id":"2963"},
    {"city_id":"35106","city_name":"Dragomiresti","state_id":"2963"},
    {"city_id":"35107","city_name":"Dulcesti","state_id":"2963"},
    {"city_id":"35108","city_name":"Dumbrava Rosie","state_id":"2963"},
    {"city_id":"35109","city_name":"Farcasa","state_id":"2963"},
    {"city_id":"35110","city_name":"Faurei","state_id":"2963"},
    {"city_id":"35111","city_name":"Garcina","state_id":"2963"},
    {"city_id":"35112","city_name":"Gheraesti","state_id":"2963"},
    {"city_id":"35113","city_name":"Girov","state_id":"2963"},
    {"city_id":"35114","city_name":"Grinties","state_id":"2963"},
    {"city_id":"35115","city_name":"Grumazesti","state_id":"2963"},
    {"city_id":"35116","city_name":"Hangu","state_id":"2963"},
    {"city_id":"35117","city_name":"Horia","state_id":"2963"},
    {"city_id":"35118","city_name":"Icusesti","state_id":"2963"},
    {"city_id":"35119","city_name":"Ion Creanga","state_id":"2963"},
    {"city_id":"35120","city_name":"Margineni","state_id":"2963"},
    {"city_id":"35121","city_name":"Moldoveni","state_id":"2963"},
    {"city_id":"35122","city_name":"Oniceni","state_id":"2963"},
    {"city_id":"35123","city_name":"Pangarati","state_id":"2963"},
    {"city_id":"35124","city_name":"Pastraveni","state_id":"2963"},
    {"city_id":"35125","city_name":"Petricani","state_id":"2963"},
    {"city_id":"35126","city_name":"Piatra Neamt","state_id":"2963"},
    {"city_id":"35127","city_name":"Piatra Soimului","state_id":"2963"},
    {"city_id":"35128","city_name":"Pipirig","state_id":"2963"},
    {"city_id":"35129","city_name":"Podoleni","state_id":"2963"},
    {"city_id":"35130","city_name":"Poiana Teiului","state_id":"2963"},
    {"city_id":"35131","city_name":"Poienari","state_id":"2963"},
    {"city_id":"35132","city_name":"Raucesti","state_id":"2963"},
    {"city_id":"35133","city_name":"Razboieni","state_id":"2963"},
    {"city_id":"35134","city_name":"Rediu","state_id":"2963"},
    {"city_id":"35135","city_name":"Roman","state_id":"2963"},
    {"city_id":"35136","city_name":"Romani","state_id":"2963"},
    {"city_id":"35137","city_name":"Roznov","state_id":"2963"},
    {"city_id":"35138","city_name":"Sabaoani","state_id":"2963"},
    {"city_id":"35139","city_name":"Sagna","state_id":"2963"},
    {"city_id":"35140","city_name":"Savinesti","state_id":"2963"},
    {"city_id":"35141","city_name":"Secuieni","state_id":"2963"},
    {"city_id":"35142","city_name":"Stanita","state_id":"2963"},
    {"city_id":"35143","city_name":"Stefan cel Mare","state_id":"2963"},
    {"city_id":"35144","city_name":"Tamaseni","state_id":"2963"},
    {"city_id":"35145","city_name":"Tarcau","state_id":"2963"},
    {"city_id":"35146","city_name":"Targu-Neamt","state_id":"2963"},
    {"city_id":"35147","city_name":"Tasca","state_id":"2963"},
    {"city_id":"35148","city_name":"Tazlau","state_id":"2963"},
    {"city_id":"35149","city_name":"Tibucani","state_id":"2963"},
    {"city_id":"35150","city_name":"Timisesti","state_id":"2963"},
    {"city_id":"35151","city_name":"Trifesti","state_id":"2963"},
    {"city_id":"35152","city_name":"Tupilati","state_id":"2963"},
    {"city_id":"35153","city_name":"Urecheni","state_id":"2963"},
    {"city_id":"35154","city_name":"Valea Ursului","state_id":"2963"},
    {"city_id":"35155","city_name":"Vanatori-Neamt","state_id":"2963"},
    {"city_id":"35156","city_name":"Viisoara","state_id":"2963"},
    {"city_id":"35157","city_name":"Zanesti","state_id":"2963"},
    {"city_id":"35158","city_name":"Babiciu","state_id":"2964"},
    {"city_id":"35159","city_name":"Baldovinesti","state_id":"2964"},
    {"city_id":"35160","city_name":"Bals","state_id":"2964"},
    {"city_id":"35161","city_name":"Barasti","state_id":"2964"},
    {"city_id":"35162","city_name":"Barza","state_id":"2964"},
    {"city_id":"35163","city_name":"Bobicesti","state_id":"2964"},
    {"city_id":"35164","city_name":"Brancoveni","state_id":"2964"},
    {"city_id":"35165","city_name":"Brastavatu","state_id":"2964"},
    {"city_id":"35166","city_name":"Brebeni","state_id":"2964"},
    {"city_id":"35167","city_name":"Bucinisu","state_id":"2964"},
    {"city_id":"35168","city_name":"Caracal","state_id":"2964"},
    {"city_id":"35169","city_name":"Carlogani","state_id":"2964"},
    {"city_id":"35170","city_name":"Cezieni","state_id":"2964"},
    {"city_id":"35171","city_name":"Cilieni","state_id":"2964"},
    {"city_id":"35172","city_name":"Colonesti","state_id":"2964"},
    {"city_id":"35173","city_name":"Corabia","state_id":"2964"},
    {"city_id":"35174","city_name":"Corbu","state_id":"2964"},
    {"city_id":"35175","city_name":"Coteana","state_id":"2964"},
    {"city_id":"35176","city_name":"Crampoaia","state_id":"2964"},
    {"city_id":"35177","city_name":"Cungrea","state_id":"2964"},
    {"city_id":"35178","city_name":"Curtisoara","state_id":"2964"},
    {"city_id":"35179","city_name":"Daneasa","state_id":"2964"},
    {"city_id":"35180","city_name":"Deveselu","state_id":"2964"},
    {"city_id":"35181","city_name":"Dobretu","state_id":"2964"},
    {"city_id":"35182","city_name":"Dobrosloveni","state_id":"2964"},
    {"city_id":"35183","city_name":"Dobroteasa","state_id":"2964"},
    {"city_id":"35184","city_name":"Dobrun","state_id":"2964"},
    {"city_id":"35185","city_name":"Draganesti-Olt","state_id":"2964"},
    {"city_id":"35186","city_name":"Draghiceni","state_id":"2964"},
    {"city_id":"35187","city_name":"Fagetelu","state_id":"2964"},
    {"city_id":"35188","city_name":"Falcoiu","state_id":"2964"},
    {"city_id":"35189","city_name":"Farcasele","state_id":"2964"},
    {"city_id":"35190","city_name":"Ganeasa","state_id":"2964"},
    {"city_id":"35191","city_name":"Garcovu","state_id":"2964"},
    {"city_id":"35192","city_name":"Giuvarasti","state_id":"2964"},
    {"city_id":"35193","city_name":"Gostavatu","state_id":"2964"},
    {"city_id":"35194","city_name":"Gradinari","state_id":"2964"},
    {"city_id":"35195","city_name":"Grojdibodu","state_id":"2964"},
    {"city_id":"35196","city_name":"Ianca","state_id":"2964"},
    {"city_id":"35197","city_name":"Iancu Jianu","state_id":"2964"},
    {"city_id":"35198","city_name":"Icoana","state_id":"2964"},
    {"city_id":"35199","city_name":"Izbiceni","state_id":"2964"},
    {"city_id":"35200","city_name":"Izvoarele","state_id":"2964"},
    {"city_id":"35201","city_name":"Leleasca","state_id":"2964"},
    {"city_id":"35202","city_name":"Maruntei","state_id":"2964"},
    {"city_id":"35203","city_name":"Mihaesti","state_id":"2964"},
    {"city_id":"35204","city_name":"Milcov","state_id":"2964"},
    {"city_id":"35205","city_name":"Morunglav","state_id":"2964"},
    {"city_id":"35206","city_name":"Movileni","state_id":"2964"},
    {"city_id":"35207","city_name":"Nicolae Titulescu","state_id":"2964"},
    {"city_id":"35208","city_name":"Obarsia","state_id":"2964"},
    {"city_id":"35209","city_name":"Oboga","state_id":"2964"},
    {"city_id":"35210","city_name":"Oporelu","state_id":"2964"},
    {"city_id":"35211","city_name":"Optasi","state_id":"2964"},
    {"city_id":"35212","city_name":"Orlea","state_id":"2964"},
    {"city_id":"35213","city_name":"Osica de Sus","state_id":"2964"},
    {"city_id":"35214","city_name":"Parscoveni","state_id":"2964"},
    {"city_id":"35215","city_name":"Perieti","state_id":"2964"},
    {"city_id":"35216","city_name":"Piatra Olt","state_id":"2964"},
    {"city_id":"35217","city_name":"Plesoiu","state_id":"2964"},
    {"city_id":"35218","city_name":"Poboru","state_id":"2964"},
    {"city_id":"35219","city_name":"Potcoava","state_id":"2964"},
    {"city_id":"35220","city_name":"Priseaca","state_id":"2964"},
    {"city_id":"35221","city_name":"Radomiresti","state_id":"2964"},
    {"city_id":"35222","city_name":"Redea","state_id":"2964"},
    {"city_id":"35223","city_name":"Rotunda","state_id":"2964"},
    {"city_id":"35224","city_name":"Rusanesti","state_id":"2964"},
    {"city_id":"35225","city_name":"Samburesti","state_id":"2964"},
    {"city_id":"35226","city_name":"Scarisoara","state_id":"2964"},
    {"city_id":"35227","city_name":"Schitu","state_id":"2964"},
    {"city_id":"35228","city_name":"Scornicesti","state_id":"2964"},
    {"city_id":"35229","city_name":"Seaca","state_id":"2964"},
    {"city_id":"35230","city_name":"Serbanesti","state_id":"2964"},
    {"city_id":"35231","city_name":"Slatina","state_id":"2964"},
    {"city_id":"35232","city_name":"Slatioara","state_id":"2964"},
    {"city_id":"35233","city_name":"Spineni","state_id":"2964"},
    {"city_id":"35234","city_name":"Sprancenata","state_id":"2964"},
    {"city_id":"35235","city_name":"Stefan cel Mare","state_id":"2964"},
    {"city_id":"35236","city_name":"Stoenesti","state_id":"2964"},
    {"city_id":"35237","city_name":"Stoicanesti","state_id":"2964"},
    {"city_id":"35238","city_name":"Strejesti","state_id":"2964"},
    {"city_id":"35239","city_name":"Studina","state_id":"2964"},
    {"city_id":"35240","city_name":"Tatulesti","state_id":"2964"},
    {"city_id":"35241","city_name":"Teslui","state_id":"2964"},
    {"city_id":"35242","city_name":"Tia Mare","state_id":"2964"},
    {"city_id":"35243","city_name":"Topana","state_id":"2964"},
    {"city_id":"35244","city_name":"Traian","state_id":"2964"},
    {"city_id":"35245","city_name":"Tufeni","state_id":"2964"},
    {"city_id":"35246","city_name":"Urzica","state_id":"2964"},
    {"city_id":"35247","city_name":"Vadastra","state_id":"2964"},
    {"city_id":"35248","city_name":"Vadastrita","state_id":"2964"},
    {"city_id":"35249","city_name":"Valcele","state_id":"2964"},
    {"city_id":"35250","city_name":"Valea Mare","state_id":"2964"},
    {"city_id":"35251","city_name":"Valeni","state_id":"2964"},
    {"city_id":"35252","city_name":"Verguleasa","state_id":"2964"},
    {"city_id":"35253","city_name":"Visina","state_id":"2964"},
    {"city_id":"35254","city_name":"Vitomiresti","state_id":"2964"},
    {"city_id":"35255","city_name":"Vladila","state_id":"2964"},
    {"city_id":"35256","city_name":"Voineasa","state_id":"2964"},
    {"city_id":"35257","city_name":"Vulpeni","state_id":"2964"},
    {"city_id":"35258","city_name":"Vulturesti","state_id":"2964"},
    {"city_id":"35259","city_name":"Adunati","state_id":"2965"},
    {"city_id":"35260","city_name":"Albesti-Paleologu","state_id":"2965"},
    {"city_id":"35261","city_name":"Alunis","state_id":"2965"},
    {"city_id":"35262","city_name":"Apostolache","state_id":"2965"},
    {"city_id":"35263","city_name":"Aricestii-Rahtivani","state_id":"2965"},
    {"city_id":"35264","city_name":"Aricestii-Zeletin","state_id":"2965"},
    {"city_id":"35265","city_name":"Azuga","state_id":"2965"},
    {"city_id":"35266","city_name":"Baba Ana","state_id":"2965"},
    {"city_id":"35267","city_name":"Baicoi","state_id":"2965"},
    {"city_id":"35268","city_name":"Balta Doamnei","state_id":"2965"},
    {"city_id":"35269","city_name":"Baltesti","state_id":"2965"},
    {"city_id":"35270","city_name":"Banesti","state_id":"2965"},
    {"city_id":"35271","city_name":"Barcanesti","state_id":"2965"},
    {"city_id":"35272","city_name":"Berceni","state_id":"2965"},
    {"city_id":"35273","city_name":"Bertea","state_id":"2965"},
    {"city_id":"35274","city_name":"Blejoi","state_id":"2965"},
    {"city_id":"35275","city_name":"Boldesti","state_id":"2965"},
    {"city_id":"35276","city_name":"Boldesti-Scaeni","state_id":"2965"},
    {"city_id":"35277","city_name":"Brazi","state_id":"2965"},
    {"city_id":"35278","city_name":"Breaza","state_id":"2965"},
    {"city_id":"35279","city_name":"Brebu","state_id":"2965"},
    {"city_id":"35280","city_name":"Bucov","state_id":"2965"},
    {"city_id":"35281","city_name":"Busteni","state_id":"2965"},
    {"city_id":"35282","city_name":"Calugareni","state_id":"2965"},
    {"city_id":"35283","city_name":"Campina","state_id":"2965"},
    {"city_id":"35284","city_name":"Carbunesti","state_id":"2965"},
    {"city_id":"35285","city_name":"Ceptura","state_id":"2965"},
    {"city_id":"35286","city_name":"Cerasu","state_id":"2965"},
    {"city_id":"35287","city_name":"Chiojdeanca","state_id":"2965"},
    {"city_id":"35288","city_name":"Ciorani","state_id":"2965"},
    {"city_id":"35289","city_name":"Cocorastii-Misli","state_id":"2965"},
    {"city_id":"35290","city_name":"Colceag","state_id":"2965"},
    {"city_id":"35291","city_name":"Comarnic","state_id":"2965"},
    {"city_id":"35292","city_name":"Cornu","state_id":"2965"},
    {"city_id":"35293","city_name":"Cosminele","state_id":"2965"},
    {"city_id":"35294","city_name":"Doftana","state_id":"2965"},
    {"city_id":"35295","city_name":"Draganesti","state_id":"2965"},
    {"city_id":"35296","city_name":"Drajna","state_id":"2965"},
    {"city_id":"35297","city_name":"Dumbrava","state_id":"2965"},
    {"city_id":"35298","city_name":"Dumbravesti","state_id":"2965"},
    {"city_id":"35299","city_name":"Fantanele","state_id":"2965"},
    {"city_id":"35300","city_name":"Filipestii de Padure","state_id":"2965"},
    {"city_id":"35301","city_name":"Filipestii de Targ","state_id":"2965"},
    {"city_id":"35302","city_name":"Floresti","state_id":"2965"},
    {"city_id":"35303","city_name":"Fulga","state_id":"2965"},
    {"city_id":"35304","city_name":"Gherghita","state_id":"2965"},
    {"city_id":"35305","city_name":"Gorgota","state_id":"2965"},
    {"city_id":"35306","city_name":"Gornet","state_id":"2965"},
    {"city_id":"35307","city_name":"Gornet-Cricov","state_id":"2965"},
    {"city_id":"35308","city_name":"Gura Vadului","state_id":"2965"},
    {"city_id":"35309","city_name":"Gura Vitioarei","state_id":"2965"},
    {"city_id":"35310","city_name":"Iordacheanu","state_id":"2965"},
    {"city_id":"35311","city_name":"Izvoarele","state_id":"2965"},
    {"city_id":"35312","city_name":"Jugureni","state_id":"2965"},
    {"city_id":"35313","city_name":"Lapos","state_id":"2965"},
    {"city_id":"35314","city_name":"Lipanesti","state_id":"2965"},
    {"city_id":"35315","city_name":"Magurele","state_id":"2965"},
    {"city_id":"35316","city_name":"Magureni","state_id":"2965"},
    {"city_id":"35317","city_name":"Maneciu","state_id":"2965"},
    {"city_id":"35318","city_name":"Manesti","state_id":"2965"},
    {"city_id":"35319","city_name":"Mizil","state_id":"2965"},
    {"city_id":"35320","city_name":"Pacureti","state_id":"2965"},
    {"city_id":"35321","city_name":"Paulesti","state_id":"2965"},
    {"city_id":"35322","city_name":"Ploiesti","state_id":"2965"},
    {"city_id":"35323","city_name":"Plopeni","state_id":"2965"},
    {"city_id":"35324","city_name":"Plopu","state_id":"2965"},
    {"city_id":"35325","city_name":"Podenii Noi","state_id":"2965"},
    {"city_id":"35326","city_name":"Poenarii-Burchi","state_id":"2965"},
    {"city_id":"35327","city_name":"Poiana Campina","state_id":"2965"},
    {"city_id":"35328","city_name":"Posesti","state_id":"2965"},
    {"city_id":"35329","city_name":"Predeal-Sarari","state_id":"2965"},
    {"city_id":"35330","city_name":"Provita de Jos","state_id":"2965"},
    {"city_id":"35331","city_name":"Provita de Sus","state_id":"2965"},
    {"city_id":"35332","city_name":"Puchenii Mari","state_id":"2965"},
    {"city_id":"35333","city_name":"Rafov","state_id":"2965"},
    {"city_id":"35334","city_name":"Salcia","state_id":"2965"},
    {"city_id":"35335","city_name":"Salciile","state_id":"2965"},
    {"city_id":"35336","city_name":"Sangeru","state_id":"2965"},
    {"city_id":"35337","city_name":"Scorteni","state_id":"2965"},
    {"city_id":"35338","city_name":"Secaria","state_id":"2965"},
    {"city_id":"35339","city_name":"Sinaia","state_id":"2965"},
    {"city_id":"35340","city_name":"Sirna","state_id":"2965"},
    {"city_id":"35341","city_name":"Slanic","state_id":"2965"},
    {"city_id":"35342","city_name":"Soimari","state_id":"2965"},
    {"city_id":"35343","city_name":"Sotrile","state_id":"2965"},
    {"city_id":"35344","city_name":"Starchiojd","state_id":"2965"},
    {"city_id":"35345","city_name":"Stefesti","state_id":"2965"},
    {"city_id":"35346","city_name":"Surani","state_id":"2965"},
    {"city_id":"35347","city_name":"Talea","state_id":"2965"},
    {"city_id":"35348","city_name":"Targsorul-Vechi","state_id":"2965"},
    {"city_id":"35349","city_name":"Tataru","state_id":"2965"},
    {"city_id":"35350","city_name":"Teisani","state_id":"2965"},
    {"city_id":"35351","city_name":"Telega","state_id":"2965"},
    {"city_id":"35352","city_name":"Tinosu","state_id":"2965"},
    {"city_id":"35353","city_name":"Tomsani","state_id":"2965"},
    {"city_id":"35354","city_name":"Urlati","state_id":"2965"},
    {"city_id":"35355","city_name":"Valcanesti","state_id":"2965"},
    {"city_id":"35356","city_name":"Valea Calugareasca","state_id":"2965"},
    {"city_id":"35357","city_name":"Valenii de Munte","state_id":"2965"},
    {"city_id":"35358","city_name":"Varbilau","state_id":"2965"},
    {"city_id":"35359","city_name":"Agrij","state_id":"2966"},
    {"city_id":"35360","city_name":"Almasu","state_id":"2966"},
    {"city_id":"35361","city_name":"Babeni","state_id":"2966"},
    {"city_id":"35362","city_name":"Balan","state_id":"2966"},
    {"city_id":"35363","city_name":"Banisor","state_id":"2966"},
    {"city_id":"35364","city_name":"Benesat","state_id":"2966"},
    {"city_id":"35365","city_name":"Bobota","state_id":"2966"},
    {"city_id":"35366","city_name":"Bocsa","state_id":"2966"},
    {"city_id":"35367","city_name":"Buciumi","state_id":"2966"},
    {"city_id":"35368","city_name":"Camar","state_id":"2966"},
    {"city_id":"35369","city_name":"Carastelec","state_id":"2966"},
    {"city_id":"35370","city_name":"Cehu Silvaniei","state_id":"2966"},
    {"city_id":"35371","city_name":"Chiesd","state_id":"2966"},
    {"city_id":"35372","city_name":"Cizer","state_id":"2966"},
    {"city_id":"35373","city_name":"Coseiu","state_id":"2966"},
    {"city_id":"35374","city_name":"Crasna","state_id":"2966"},
    {"city_id":"35375","city_name":"Creaca","state_id":"2966"},
    {"city_id":"35376","city_name":"Criseni","state_id":"2966"},
    {"city_id":"35377","city_name":"Cristolt","state_id":"2966"},
    {"city_id":"35378","city_name":"Cuzaplac","state_id":"2966"},
    {"city_id":"35379","city_name":"Dobrin","state_id":"2966"},
    {"city_id":"35380","city_name":"Dragu","state_id":"2966"},
    {"city_id":"35381","city_name":"Fildu de Jos","state_id":"2966"},
    {"city_id":"35382","city_name":"Galgau","state_id":"2966"},
    {"city_id":"35383","city_name":"Garbou","state_id":"2966"},
    {"city_id":"35384","city_name":"Halmasd","state_id":"2966"},
    {"city_id":"35385","city_name":"Hereclean","state_id":"2966"},
    {"city_id":"35386","city_name":"Hida","state_id":"2966"},
    {"city_id":"35387","city_name":"Horoatu Crasnei","state_id":"2966"},
    {"city_id":"35388","city_name":"Ileanda","state_id":"2966"},
    {"city_id":"35389","city_name":"Ip","state_id":"2966"},
    {"city_id":"35390","city_name":"Jibou","state_id":"2966"},
    {"city_id":"35391","city_name":"Letca","state_id":"2966"},
    {"city_id":"35392","city_name":"Lozna","state_id":"2966"},
    {"city_id":"35393","city_name":"Maeriste","state_id":"2966"},
    {"city_id":"35394","city_name":"Marca","state_id":"2966"},
    {"city_id":"35395","city_name":"Mesesenii de Jos","state_id":"2966"},
    {"city_id":"35396","city_name":"Mirsid","state_id":"2966"},
    {"city_id":"35397","city_name":"Napradea","state_id":"2966"},
    {"city_id":"35398","city_name":"Nusfalau","state_id":"2966"},
    {"city_id":"35399","city_name":"Periceiu","state_id":"2966"},
    {"city_id":"35400","city_name":"Plopis","state_id":"2966"},
    {"city_id":"35401","city_name":"Poiana Blenchii","state_id":"2966"},
    {"city_id":"35402","city_name":"Romanasi","state_id":"2966"},
    {"city_id":"35403","city_name":"Rus","state_id":"2966"},
    {"city_id":"35404","city_name":"Sag","state_id":"2966"},
    {"city_id":"35405","city_name":"Salatig","state_id":"2966"},
    {"city_id":"35406","city_name":"Samsud","state_id":"2966"},
    {"city_id":"35407","city_name":"Sanmihaiul-Almasului","state_id":"2966"},
    {"city_id":"35408","city_name":"Sarmasag","state_id":"2966"},
    {"city_id":"35409","city_name":"Simleu Silvaniei","state_id":"2966"},
    {"city_id":"35410","city_name":"Somes-Odorhei","state_id":"2966"},
    {"city_id":"35411","city_name":"Surduc","state_id":"2966"},
    {"city_id":"35412","city_name":"Treznea","state_id":"2966"},
    {"city_id":"35413","city_name":"Valcau de Jos","state_id":"2966"},
    {"city_id":"35414","city_name":"Varsolt","state_id":"2966"},
    {"city_id":"35415","city_name":"Zalau","state_id":"2966"},
    {"city_id":"35416","city_name":"Zalha","state_id":"2966"},
    {"city_id":"35417","city_name":"Zimbor","state_id":"2966"},
    {"city_id":"35418","city_name":"Agnita","state_id":"2968"},
    {"city_id":"35419","city_name":"Altina","state_id":"2968"},
    {"city_id":"35420","city_name":"Apoldu de Jos","state_id":"2968"},
    {"city_id":"35421","city_name":"Arpasu de Jos","state_id":"2968"},
    {"city_id":"35422","city_name":"Atel","state_id":"2968"},
    {"city_id":"35423","city_name":"Avrig","state_id":"2968"},
    {"city_id":"35424","city_name":"Axente Sever","state_id":"2968"},
    {"city_id":"35425","city_name":"Barghis","state_id":"2968"},
    {"city_id":"35426","city_name":"Bazna","state_id":"2968"},
    {"city_id":"35427","city_name":"Biertan","state_id":"2968"},
    {"city_id":"35428","city_name":"Blajel","state_id":"2968"},
    {"city_id":"35429","city_name":"Bradeni","state_id":"2968"},
    {"city_id":"35430","city_name":"Brateiu","state_id":"2968"},
    {"city_id":"35431","city_name":"Bruiu","state_id":"2968"},
    {"city_id":"35432","city_name":"Carta","state_id":"2968"},
    {"city_id":"35433","city_name":"Cartisoara","state_id":"2968"},
    {"city_id":"35434","city_name":"Chirpar","state_id":"2968"},
    {"city_id":"35435","city_name":"Cisnadie","state_id":"2968"},
    {"city_id":"35436","city_name":"Copsa Mica","state_id":"2968"},
    {"city_id":"35437","city_name":"Cristian","state_id":"2968"},
    {"city_id":"35438","city_name":"Darlos","state_id":"2968"},
    {"city_id":"35439","city_name":"Dumbraveni","state_id":"2968"},
    {"city_id":"35440","city_name":"Gura Raului","state_id":"2968"},
    {"city_id":"35441","city_name":"Hoghilag","state_id":"2968"},
    {"city_id":"35442","city_name":"Iacobeni","state_id":"2968"},
    {"city_id":"35443","city_name":"Jina","state_id":"2968"},
    {"city_id":"35444","city_name":"Laslea","state_id":"2968"},
    {"city_id":"35445","city_name":"Loamnes","state_id":"2968"},
    {"city_id":"35446","city_name":"Ludos","state_id":"2968"},
    {"city_id":"35447","city_name":"Marpod","state_id":"2968"},
    {"city_id":"35448","city_name":"Medias","state_id":"2968"},
    {"city_id":"35449","city_name":"Merghindeal","state_id":"2968"},
    {"city_id":"35450","city_name":"Micasasa","state_id":"2968"},
    {"city_id":"35451","city_name":"Miercurea Sibiului","state_id":"2968"},
    {"city_id":"35452","city_name":"Mihaileni","state_id":"2968"},
    {"city_id":"35453","city_name":"Mosna","state_id":"2968"},
    {"city_id":"35454","city_name":"Nocrich","state_id":"2968"},
    {"city_id":"35455","city_name":"Ocna Sibiului","state_id":"2968"},
    {"city_id":"35456","city_name":"Orlat","state_id":"2968"},
    {"city_id":"35457","city_name":"Pauca","state_id":"2968"},
    {"city_id":"35458","city_name":"Poiana Sibiului","state_id":"2968"},
    {"city_id":"35459","city_name":"Poplaca","state_id":"2968"},
    {"city_id":"35460","city_name":"Porumbacu de Jos","state_id":"2968"},
    {"city_id":"35461","city_name":"Racovita","state_id":"2968"},
    {"city_id":"35462","city_name":"Rasinari","state_id":"2968"},
    {"city_id":"35463","city_name":"Rau Sadului","state_id":"2968"},
    {"city_id":"35464","city_name":"Rosia","state_id":"2968"},
    {"city_id":"35465","city_name":"Sadu","state_id":"2968"},
    {"city_id":"35466","city_name":"Saliste","state_id":"2968"},
    {"city_id":"35467","city_name":"Seica Mare","state_id":"2968"},
    {"city_id":"35468","city_name":"Seica Mica","state_id":"2968"},
    {"city_id":"35469","city_name":"Selimbar","state_id":"2968"},
    {"city_id":"35470","city_name":"Sibiu","state_id":"2968"},
    {"city_id":"35471","city_name":"Slimnic","state_id":"2968"},
    {"city_id":"35472","city_name":"Sura Mare","state_id":"2968"},
    {"city_id":"35473","city_name":"Sura Mica","state_id":"2968"},
    {"city_id":"35474","city_name":"Talmaciu","state_id":"2968"},
    {"city_id":"35475","city_name":"Tarnava","state_id":"2968"},
    {"city_id":"35476","city_name":"Tilisca","state_id":"2968"},
    {"city_id":"35477","city_name":"Turnu Rosu","state_id":"2968"},
    {"city_id":"35478","city_name":"Valea Viilor","state_id":"2968"},
    {"city_id":"35479","city_name":"Vurpar","state_id":"2968"},
    {"city_id":"35480","city_name":"Sondelor","state_id":"2969"},
    {"city_id":"35481","city_name":"Adancata","state_id":"2970"},
    {"city_id":"35482","city_name":"Arbore","state_id":"2970"},
    {"city_id":"35483","city_name":"Baia","state_id":"2970"},
    {"city_id":"35484","city_name":"Balcauti","state_id":"2970"},
    {"city_id":"35485","city_name":"Bilca","state_id":"2970"},
    {"city_id":"35486","city_name":"Bogdanesti","state_id":"2970"},
    {"city_id":"35487","city_name":"Boroaia","state_id":"2970"},
    {"city_id":"35488","city_name":"Bosanci","state_id":"2970"},
    {"city_id":"35489","city_name":"Botosana","state_id":"2970"},
    {"city_id":"35490","city_name":"Breaza","state_id":"2970"},
    {"city_id":"35491","city_name":"Brodina","state_id":"2970"},
    {"city_id":"35492","city_name":"Brosteni","state_id":"2970"},
    {"city_id":"35493","city_name":"Bunesti","state_id":"2970"},
    {"city_id":"35494","city_name":"Cacica","state_id":"2970"},
    {"city_id":"35495","city_name":"Cajvana","state_id":"2970"},
    {"city_id":"35496","city_name":"Calafindesti","state_id":"2970"},
    {"city_id":"35497","city_name":"Campulung Moldovenesc","state_id":"2970"},
    {"city_id":"35498","city_name":"Carlibaba","state_id":"2970"},
    {"city_id":"35499","city_name":"Ciprian Porumbescu","state_id":"2970"},
    {"city_id":"35500","city_name":"Cornu Luncii","state_id":"2970"},
    {"city_id":"35501","city_name":"Crucea","state_id":"2970"},
    {"city_id":"35502","city_name":"Darmanesti","state_id":"2970"},
    {"city_id":"35503","city_name":"Dolhasca","state_id":"2970"},
    {"city_id":"35504","city_name":"Dolhesti","state_id":"2970"},
    {"city_id":"35505","city_name":"Dorna","state_id":"2970"},
    {"city_id":"35506","city_name":"Dorna Candrenilor","state_id":"2970"},
    {"city_id":"35507","city_name":"Dornesti","state_id":"2970"},
    {"city_id":"35508","city_name":"Dragoesti","state_id":"2970"},
    {"city_id":"35509","city_name":"Draguseni","state_id":"2970"},
    {"city_id":"35510","city_name":"Dumbraveni","state_id":"2970"},
    {"city_id":"35511","city_name":"Falticeni","state_id":"2970"},
    {"city_id":"35512","city_name":"Fantanele","state_id":"2970"},
    {"city_id":"35513","city_name":"Forasti","state_id":"2970"},
    {"city_id":"35514","city_name":"Frasin","state_id":"2970"},
    {"city_id":"35515","city_name":"Fratautii Noi","state_id":"2970"},
    {"city_id":"35516","city_name":"Fratautii Vechi","state_id":"2970"},
    {"city_id":"35517","city_name":"Frumosu","state_id":"2970"},
    {"city_id":"35518","city_name":"Fundu Moldovei","state_id":"2970"},
    {"city_id":"35519","city_name":"Galanesti","state_id":"2970"},
    {"city_id":"35520","city_name":"Gramesti","state_id":"2970"},
    {"city_id":"35521","city_name":"Granicesti","state_id":"2970"},
    {"city_id":"35522","city_name":"Gura Humorului","state_id":"2970"},
    {"city_id":"35523","city_name":"Horodniceni","state_id":"2970"},
    {"city_id":"35524","city_name":"Horodnicu","state_id":"2970"},
    {"city_id":"35525","city_name":"Iacobeni","state_id":"2970"},
    {"city_id":"35526","city_name":"Ipotesti","state_id":"2970"},
    {"city_id":"35527","city_name":"Izvoarele Sucevei","state_id":"2970"},
    {"city_id":"35528","city_name":"Liteni","state_id":"2970"},
    {"city_id":"35529","city_name":"Malini","state_id":"2970"},
    {"city_id":"35530","city_name":"Manastirea Homorului","state_id":"2970"},
    {"city_id":"35531","city_name":"Marginea","state_id":"2970"},
    {"city_id":"35532","city_name":"Milisauti","state_id":"2970"},
    {"city_id":"35533","city_name":"Mitocu Dragomirnei","state_id":"2970"},
    {"city_id":"35534","city_name":"Moara","state_id":"2970"},
    {"city_id":"35535","city_name":"Moldova Sulita","state_id":"2970"},
    {"city_id":"35536","city_name":"Moldovita","state_id":"2970"},
    {"city_id":"35537","city_name":"Musenita","state_id":"2970"},
    {"city_id":"35538","city_name":"Ostra","state_id":"2970"},
    {"city_id":"35539","city_name":"Paltinoasa","state_id":"2970"},
    {"city_id":"35540","city_name":"Panaci","state_id":"2970"},
    {"city_id":"35541","city_name":"Partestii de Jos","state_id":"2970"},
    {"city_id":"35542","city_name":"Patrauti","state_id":"2970"},
    {"city_id":"35543","city_name":"Poiana Stampei","state_id":"2970"},
    {"city_id":"35544","city_name":"Pojorata","state_id":"2970"},
    {"city_id":"35545","city_name":"Preutesti","state_id":"2970"},
    {"city_id":"35546","city_name":"Putna","state_id":"2970"},
    {"city_id":"35547","city_name":"Radaseni","state_id":"2970"},
    {"city_id":"35548","city_name":"Radauti","state_id":"2970"},
    {"city_id":"35549","city_name":"Rasca","state_id":"2970"},
    {"city_id":"35550","city_name":"Sadova","state_id":"2970"},
    {"city_id":"35551","city_name":"Salcea","state_id":"2970"},
    {"city_id":"35552","city_name":"Saru Dornei","state_id":"2970"},
    {"city_id":"35553","city_name":"Satu Mare","state_id":"2970"},
    {"city_id":"35554","city_name":"Scheia","state_id":"2970"},
    {"city_id":"35555","city_name":"Siminicea","state_id":"2970"},
    {"city_id":"35556","city_name":"Siret","state_id":"2970"},
    {"city_id":"35557","city_name":"Slatina","state_id":"2970"},
    {"city_id":"35558","city_name":"Solca","state_id":"2970"},
    {"city_id":"35559","city_name":"Straja","state_id":"2970"},
    {"city_id":"35560","city_name":"Stroiesti","state_id":"2970"},
    {"city_id":"35561","city_name":"Stulpicani","state_id":"2970"},
    {"city_id":"35562","city_name":"Suceava","state_id":"2970"},
    {"city_id":"35563","city_name":"Sucevita","state_id":"2970"},
    {"city_id":"35564","city_name":"Todiresti","state_id":"2970"},
    {"city_id":"35565","city_name":"Udesti","state_id":"2970"},
    {"city_id":"35566","city_name":"Ulma","state_id":"2970"},
    {"city_id":"35567","city_name":"Vadu Moldovei","state_id":"2970"},
    {"city_id":"35568","city_name":"Valea Moldovei","state_id":"2970"},
    {"city_id":"35569","city_name":"Vama","state_id":"2970"},
    {"city_id":"35570","city_name":"Vatra Dornei","state_id":"2970"},
    {"city_id":"35571","city_name":"Vatra Moldovitei","state_id":"2970"},
    {"city_id":"35572","city_name":"Veresti","state_id":"2970"},
    {"city_id":"35573","city_name":"Vicovu de Jos","state_id":"2970"},
    {"city_id":"35574","city_name":"Vicovu de Sus","state_id":"2970"},
    {"city_id":"35575","city_name":"Volovat","state_id":"2970"},
    {"city_id":"35576","city_name":"Vulturesti","state_id":"2970"},
    {"city_id":"35577","city_name":"Zamostea","state_id":"2970"},
    {"city_id":"35578","city_name":"Zvoristea","state_id":"2970"},
    {"city_id":"35579","city_name":"Alexandria","state_id":"2971"},
    {"city_id":"35580","city_name":"Babaita","state_id":"2971"},
    {"city_id":"35581","city_name":"Balaci","state_id":"2971"},
    {"city_id":"35582","city_name":"Blejesti","state_id":"2971"},
    {"city_id":"35583","city_name":"Bogdana","state_id":"2971"},
    {"city_id":"35584","city_name":"Botoroaga","state_id":"2971"},
    {"city_id":"35585","city_name":"Bragadiru","state_id":"2971"},
    {"city_id":"35586","city_name":"Branceni","state_id":"2971"},
    {"city_id":"35587","city_name":"Bujoreni","state_id":"2971"},
    {"city_id":"35588","city_name":"Bujoru","state_id":"2971"},
    {"city_id":"35589","city_name":"Buzescu","state_id":"2971"},
    {"city_id":"35590","city_name":"Calinesti","state_id":"2971"},
    {"city_id":"35591","city_name":"Calmatuiu","state_id":"2971"},
    {"city_id":"35592","city_name":"Cervenia","state_id":"2971"},
    {"city_id":"35593","city_name":"Ciolanesti","state_id":"2971"},
    {"city_id":"35594","city_name":"Ciuperceni","state_id":"2971"},
    {"city_id":"35595","city_name":"Contesti","state_id":"2971"},
    {"city_id":"35596","city_name":"Cosmesti","state_id":"2971"},
    {"city_id":"35597","city_name":"Crangeni","state_id":"2971"},
    {"city_id":"35598","city_name":"Crangu","state_id":"2971"},
    {"city_id":"35599","city_name":"Crevenicu","state_id":"2971"},
    {"city_id":"35600","city_name":"Didesti","state_id":"2971"},
    {"city_id":"35601","city_name":"Dobrotesti","state_id":"2971"},
    {"city_id":"35602","city_name":"Dracsenei","state_id":"2971"},
    {"city_id":"35603","city_name":"Draganesti de Vede","state_id":"2971"},
    {"city_id":"35604","city_name":"Draganesti-Vlasca","state_id":"2971"},
    {"city_id":"35605","city_name":"Frumoasa","state_id":"2971"},
    {"city_id":"35606","city_name":"Furculesti","state_id":"2971"},
    {"city_id":"35607","city_name":"Galateni","state_id":"2971"},
    {"city_id":"35608","city_name":"Gratia","state_id":"2971"},
    {"city_id":"35609","city_name":"Islaz","state_id":"2971"},
    {"city_id":"35610","city_name":"Izvoarele","state_id":"2971"},
    {"city_id":"35611","city_name":"Lisa","state_id":"2971"},
    {"city_id":"35612","city_name":"Lita","state_id":"2971"},
    {"city_id":"35613","city_name":"Lunca","state_id":"2971"},
    {"city_id":"35614","city_name":"Magura","state_id":"2971"},
    {"city_id":"35615","city_name":"Maldaeni","state_id":"2971"},
    {"city_id":"35616","city_name":"Marzanesti","state_id":"2971"},
    {"city_id":"35617","city_name":"Mavrodin","state_id":"2971"},
    {"city_id":"35618","city_name":"Mereni","state_id":"2971"},
    {"city_id":"35619","city_name":"Mosteni","state_id":"2971"},
    {"city_id":"35620","city_name":"Nanov","state_id":"2971"},
    {"city_id":"35621","city_name":"Nasturelu","state_id":"2971"},
    {"city_id":"35622","city_name":"Necsesti","state_id":"2971"},
    {"city_id":"35623","city_name":"Olteni","state_id":"2971"},
    {"city_id":"35624","city_name":"Orbeasca","state_id":"2971"},
    {"city_id":"35625","city_name":"Peretu","state_id":"2971"},
    {"city_id":"35626","city_name":"Piatra","state_id":"2971"},
    {"city_id":"35627","city_name":"Pietrosani","state_id":"2971"},
    {"city_id":"35628","city_name":"Plopii Slavitesti","state_id":"2971"},
    {"city_id":"35629","city_name":"Plosca","state_id":"2971"},
    {"city_id":"35630","city_name":"Poeni","state_id":"2971"},
    {"city_id":"35631","city_name":"Poroschia","state_id":"2971"},
    {"city_id":"35632","city_name":"Putineiu","state_id":"2971"},
    {"city_id":"35633","city_name":"Radoiesti","state_id":"2971"},
    {"city_id":"35634","city_name":"Rasmiresti","state_id":"2971"},
    {"city_id":"35635","city_name":"Rosiori de Vede","state_id":"2971"},
    {"city_id":"35636","city_name":"Saceni","state_id":"2971"},
    {"city_id":"35637","city_name":"Salcia","state_id":"2971"},
    {"city_id":"35638","city_name":"Sarbeni","state_id":"2971"},
    {"city_id":"35639","city_name":"Scrioastea","state_id":"2971"},
    {"city_id":"35640","city_name":"Scurtu Mare","state_id":"2971"},
    {"city_id":"35641","city_name":"Seaca","state_id":"2971"},
    {"city_id":"35642","city_name":"Segarcea Vale","state_id":"2971"},
    {"city_id":"35643","city_name":"Sfintesti","state_id":"2971"},
    {"city_id":"35644","city_name":"Silistea","state_id":"2971"},
    {"city_id":"35645","city_name":"Silistea-Gumesti","state_id":"2971"},
    {"city_id":"35646","city_name":"Slobozia-Mandra","state_id":"2971"},
    {"city_id":"35647","city_name":"Smardioasa","state_id":"2971"},
    {"city_id":"35648","city_name":"Stejaru","state_id":"2971"},
    {"city_id":"35649","city_name":"Storobaneasa","state_id":"2971"},
    {"city_id":"35650","city_name":"Suhaia","state_id":"2971"},
    {"city_id":"35651","city_name":"Talpa","state_id":"2971"},
    {"city_id":"35652","city_name":"Tatarastii de Jos","state_id":"2971"},
    {"city_id":"35653","city_name":"Tatarastii de Sus","state_id":"2971"},
    {"city_id":"35654","city_name":"Tiganesti","state_id":"2971"},
    {"city_id":"35655","city_name":"Traian","state_id":"2971"},
    {"city_id":"35656","city_name":"Trivalea-Mosteni","state_id":"2971"},
    {"city_id":"35657","city_name":"Troianul","state_id":"2971"},
    {"city_id":"35658","city_name":"Turnu Magurele","state_id":"2971"},
    {"city_id":"35659","city_name":"Vartoapele-de-Jos","state_id":"2971"},
    {"city_id":"35660","city_name":"Vedea","state_id":"2971"},
    {"city_id":"35661","city_name":"Videle","state_id":"2971"},
    {"city_id":"35662","city_name":"Viisoara","state_id":"2971"},
    {"city_id":"35663","city_name":"Vitanesti","state_id":"2971"},
    {"city_id":"35664","city_name":"Zambreasca","state_id":"2971"},
    {"city_id":"35665","city_name":"Zimnicea","state_id":"2971"},
    {"city_id":"35666","city_name":"Balint","state_id":"2972"},
    {"city_id":"35667","city_name":"Banloc","state_id":"2972"},
    {"city_id":"35668","city_name":"Bara","state_id":"2972"},
    {"city_id":"35669","city_name":"Barna","state_id":"2972"},
    {"city_id":"35670","city_name":"Beba Veche","state_id":"2972"},
    {"city_id":"35671","city_name":"Becicherecu Mic","state_id":"2972"},
    {"city_id":"35672","city_name":"Belint","state_id":"2972"},
    {"city_id":"35673","city_name":"Bethausen","state_id":"2972"},
    {"city_id":"35674","city_name":"Biled","state_id":"2972"},
    {"city_id":"35675","city_name":"Bogda","state_id":"2972"},
    {"city_id":"35676","city_name":"Boldur","state_id":"2972"},
    {"city_id":"35677","city_name":"Brestovat","state_id":"2972"},
    {"city_id":"35678","city_name":"Buzias","state_id":"2972"},
    {"city_id":"35679","city_name":"Carpinis","state_id":"2972"},
    {"city_id":"35680","city_name":"Cenad","state_id":"2972"},
    {"city_id":"35681","city_name":"Cenei","state_id":"2972"},
    {"city_id":"35682","city_name":"Cheveresu Mare","state_id":"2972"},
    {"city_id":"35683","city_name":"Ciacova","state_id":"2972"},
    {"city_id":"35684","city_name":"Comlosu Mare","state_id":"2972"},
    {"city_id":"35685","city_name":"Costeiu","state_id":"2972"},
    {"city_id":"35686","city_name":"Criciova","state_id":"2972"},
    {"city_id":"35687","city_name":"Curtea","state_id":"2972"},
    {"city_id":"35688","city_name":"Darova","state_id":"2972"},
    {"city_id":"35689","city_name":"Denta","state_id":"2972"},
    {"city_id":"35690","city_name":"Deta","state_id":"2972"},
    {"city_id":"35691","city_name":"Dudestii Vechi","state_id":"2972"},
    {"city_id":"35692","city_name":"Dumbrava","state_id":"2972"},
    {"city_id":"35693","city_name":"Dumbravita","state_id":"2972"},
    {"city_id":"35694","city_name":"Faget","state_id":"2972"},
    {"city_id":"35695","city_name":"Fardea","state_id":"2972"},
    {"city_id":"35696","city_name":"Foeni","state_id":"2972"},
    {"city_id":"35697","city_name":"Gataia","state_id":"2972"},
    {"city_id":"35698","city_name":"Gavojdia","state_id":"2972"},
    {"city_id":"35699","city_name":"Ghiroda","state_id":"2972"},
    {"city_id":"35700","city_name":"Ghizela","state_id":"2972"},
    {"city_id":"35701","city_name":"Giarmata","state_id":"2972"},
    {"city_id":"35702","city_name":"Giera","state_id":"2972"},
    {"city_id":"35703","city_name":"Giroc","state_id":"2972"},
    {"city_id":"35704","city_name":"Giulvaz","state_id":"2972"},
    {"city_id":"35705","city_name":"Jamu Mare","state_id":"2972"},
    {"city_id":"35706","city_name":"Jebel","state_id":"2972"},
    {"city_id":"35707","city_name":"Jimbolia","state_id":"2972"},
    {"city_id":"35708","city_name":"Lenauheim","state_id":"2972"},
    {"city_id":"35709","city_name":"Liebling","state_id":"2972"},
    {"city_id":"35710","city_name":"Lovrin","state_id":"2972"},
    {"city_id":"35711","city_name":"Lugoj","state_id":"2972"},
    {"city_id":"35712","city_name":"Manastiur","state_id":"2972"},
    {"city_id":"35713","city_name":"Margina","state_id":"2972"},
    {"city_id":"35714","city_name":"Masloc","state_id":"2972"},
    {"city_id":"35715","city_name":"Moravita","state_id":"2972"},
    {"city_id":"35716","city_name":"Mosnita Noua","state_id":"2972"},
    {"city_id":"35717","city_name":"Nadrag","state_id":"2972"},
    {"city_id":"35718","city_name":"Nitchidorf","state_id":"2972"},
    {"city_id":"35719","city_name":"Ohaba Lunga","state_id":"2972"},
    {"city_id":"35720","city_name":"Ortisoara","state_id":"2972"},
    {"city_id":"35721","city_name":"Peciu Nou","state_id":"2972"},
    {"city_id":"35722","city_name":"Periam","state_id":"2972"},
    {"city_id":"35723","city_name":"Pietroasa","state_id":"2972"},
    {"city_id":"35724","city_name":"Pischia","state_id":"2972"},
    {"city_id":"35725","city_name":"Racovita","state_id":"2972"},
    {"city_id":"35726","city_name":"Recas","state_id":"2972"},
    {"city_id":"35727","city_name":"Remetea Mare","state_id":"2972"},
    {"city_id":"35728","city_name":"Sacalaz","state_id":"2972"},
    {"city_id":"35729","city_name":"Sacosu Turcesc","state_id":"2972"},
    {"city_id":"35730","city_name":"Sag","state_id":"2972"},
    {"city_id":"35731","city_name":"Sanandrei","state_id":"2972"},
    {"city_id":"35732","city_name":"Sanmihaiu Roman","state_id":"2972"},
    {"city_id":"35733","city_name":"Sannicolaul Mare","state_id":"2972"},
    {"city_id":"35734","city_name":"Sanpetrul-Mare","state_id":"2972"},
    {"city_id":"35735","city_name":"Satchinez","state_id":"2972"},
    {"city_id":"35736","city_name":"Secas","state_id":"2972"},
    {"city_id":"35737","city_name":"Stiuca","state_id":"2972"},
    {"city_id":"35738","city_name":"Teremia Mare","state_id":"2972"},
    {"city_id":"35739","city_name":"Timisoara","state_id":"2972"},
    {"city_id":"35740","city_name":"Tomesti","state_id":"2972"},
    {"city_id":"35741","city_name":"Topolovatu Mare","state_id":"2972"},
    {"city_id":"35742","city_name":"Tormac","state_id":"2972"},
    {"city_id":"35743","city_name":"Traian Vuia","state_id":"2972"},
    {"city_id":"35744","city_name":"Uivar","state_id":"2972"},
    {"city_id":"35745","city_name":"Varias","state_id":"2972"},
    {"city_id":"35746","city_name":"Victor Vlad Delamarina","state_id":"2972"},
    {"city_id":"35747","city_name":"Voiteg","state_id":"2972"},
    {"city_id":"35748","city_name":"Babadag","state_id":"2973"},
    {"city_id":"35749","city_name":"Baia","state_id":"2973"},
    {"city_id":"35750","city_name":"Beidaud","state_id":"2973"},
    {"city_id":"35751","city_name":"Carcaliu","state_id":"2973"},
    {"city_id":"35752","city_name":"Casimcea","state_id":"2973"},
    {"city_id":"35753","city_name":"Ceamurlia de Jos","state_id":"2973"},
    {"city_id":"35754","city_name":"Ceatalchioi","state_id":"2973"},
    {"city_id":"35755","city_name":"Cerna","state_id":"2973"},
    {"city_id":"35756","city_name":"Chilia Veche","state_id":"2973"},
    {"city_id":"35757","city_name":"Ciucurova","state_id":"2973"},
    {"city_id":"35758","city_name":"Constantin Rosetti","state_id":"2973"},
    {"city_id":"35759","city_name":"Crisan","state_id":"2973"},
    {"city_id":"35760","city_name":"Daeni","state_id":"2973"},
    {"city_id":"35761","city_name":"Dorobantu","state_id":"2973"},
    {"city_id":"35762","city_name":"Frecatei","state_id":"2973"},
    {"city_id":"35763","city_name":"Greci","state_id":"2973"},
    {"city_id":"35764","city_name":"Grindu","state_id":"2973"},
    {"city_id":"35765","city_name":"Hamcearca","state_id":"2973"},
    {"city_id":"35766","city_name":"Horia","state_id":"2973"},
    {"city_id":"35767","city_name":"Ion Bratianu","state_id":"2973"},
    {"city_id":"35768","city_name":"Isaccea","state_id":"2973"},
    {"city_id":"35769","city_name":"Izvoarele","state_id":"2973"},
    {"city_id":"35770","city_name":"Jijila","state_id":"2973"},
    {"city_id":"35771","city_name":"Jurilovca","state_id":"2973"},
    {"city_id":"35772","city_name":"Luncavita","state_id":"2973"},
    {"city_id":"35773","city_name":"Macin","state_id":"2973"},
    {"city_id":"35774","city_name":"Mahmudia","state_id":"2973"},
    {"city_id":"35775","city_name":"Malnas","state_id":"2973"},
    {"city_id":"35776","city_name":"Mihai Bravu","state_id":"2973"},
    {"city_id":"35777","city_name":"Mihail Kogalniceanu","state_id":"2973"},
    {"city_id":"35778","city_name":"Murighiol","state_id":"2973"},
    {"city_id":"35779","city_name":"Nalbant","state_id":"2973"},
    {"city_id":"35780","city_name":"Niculitel","state_id":"2973"},
    {"city_id":"35781","city_name":"Nufaru","state_id":"2973"},
    {"city_id":"35782","city_name":"Ostrov","state_id":"2973"},
    {"city_id":"35783","city_name":"Pardina","state_id":"2973"},
    {"city_id":"35784","city_name":"Peceneaga","state_id":"2973"},
    {"city_id":"35785","city_name":"Sarichioi","state_id":"2973"},
    {"city_id":"35786","city_name":"Sfantu Gheorghe","state_id":"2973"},
    {"city_id":"35787","city_name":"Slava Cercheza","state_id":"2973"},
    {"city_id":"35788","city_name":"Smardan","state_id":"2973"},
    {"city_id":"35789","city_name":"Somova","state_id":"2973"},
    {"city_id":"35790","city_name":"Stejaru","state_id":"2973"},
    {"city_id":"35791","city_name":"Sulina","state_id":"2973"},
    {"city_id":"35792","city_name":"Topolog","state_id":"2973"},
    {"city_id":"35793","city_name":"Tulcea","state_id":"2973"},
    {"city_id":"35794","city_name":"Turcoaia","state_id":"2973"},
    {"city_id":"35795","city_name":"Valea Nucarilor","state_id":"2973"},
    {"city_id":"35796","city_name":"Alunu","state_id":"2974"},
    {"city_id":"35797","city_name":"Amarasti","state_id":"2974"},
    {"city_id":"35798","city_name":"Babeni","state_id":"2974"},
    {"city_id":"35799","city_name":"Baile Govora","state_id":"2974"},
    {"city_id":"35800","city_name":"Baile Olanesti","state_id":"2974"},
    {"city_id":"35801","city_name":"Balcesti","state_id":"2974"},
    {"city_id":"35802","city_name":"Barbatesti","state_id":"2974"},
    {"city_id":"35803","city_name":"Berbesti","state_id":"2974"},
    {"city_id":"35804","city_name":"Berislavesti","state_id":"2974"},
    {"city_id":"35805","city_name":"Boisoara","state_id":"2974"},
    {"city_id":"35806","city_name":"Brezoi","state_id":"2974"},
    {"city_id":"35807","city_name":"Budesti","state_id":"2974"},
    {"city_id":"35808","city_name":"Bujoreni","state_id":"2974"},
    {"city_id":"35809","city_name":"Bunesti","state_id":"2974"},
    {"city_id":"35810","city_name":"Caineni","state_id":"2974"},
    {"city_id":"35811","city_name":"Calimanesti","state_id":"2974"},
    {"city_id":"35812","city_name":"Cernisoara","state_id":"2974"},
    {"city_id":"35813","city_name":"Copaceni","state_id":"2974"},
    {"city_id":"35814","city_name":"Costesti","state_id":"2974"},
    {"city_id":"35815","city_name":"Creteni","state_id":"2974"},
    {"city_id":"35816","city_name":"Daesti","state_id":"2974"},
    {"city_id":"35817","city_name":"Danicei","state_id":"2974"},
    {"city_id":"35818","city_name":"Dragasani","state_id":"2974"},
    {"city_id":"35819","city_name":"Dragoesti","state_id":"2974"},
    {"city_id":"35820","city_name":"Fartatesti","state_id":"2974"},
    {"city_id":"35821","city_name":"Fauresti","state_id":"2974"},
    {"city_id":"35822","city_name":"Francesti","state_id":"2974"},
    {"city_id":"35823","city_name":"Galicea","state_id":"2974"},
    {"city_id":"35824","city_name":"Ghioroiu","state_id":"2974"},
    {"city_id":"35825","city_name":"Glavile","state_id":"2974"},
    {"city_id":"35826","city_name":"Golesti","state_id":"2974"},
    {"city_id":"35827","city_name":"Gradistea","state_id":"2974"},
    {"city_id":"35828","city_name":"Gusoeni","state_id":"2974"},
    {"city_id":"35829","city_name":"Horezu","state_id":"2974"},
    {"city_id":"35830","city_name":"Ionesti","state_id":"2974"},
    {"city_id":"35831","city_name":"Ladesti","state_id":"2974"},
    {"city_id":"35832","city_name":"Lalosu","state_id":"2974"},
    {"city_id":"35833","city_name":"Lapusata","state_id":"2974"},
    {"city_id":"35834","city_name":"Livezi","state_id":"2974"},
    {"city_id":"35835","city_name":"Lungesti","state_id":"2974"},
    {"city_id":"35836","city_name":"Maciuca","state_id":"2974"},
    {"city_id":"35837","city_name":"Madulari","state_id":"2974"},
    {"city_id":"35838","city_name":"Malaia","state_id":"2974"},
    {"city_id":"35839","city_name":"Maldaresti","state_id":"2974"},
    {"city_id":"35840","city_name":"Mateesti","state_id":"2974"},
    {"city_id":"35841","city_name":"Mihaesti","state_id":"2974"},
    {"city_id":"35842","city_name":"Milcoiu","state_id":"2974"},
    {"city_id":"35843","city_name":"Muereasca","state_id":"2974"},
    {"city_id":"35844","city_name":"Nicolae Balcescu","state_id":"2974"},
    {"city_id":"35845","city_name":"Ocnele Mari","state_id":"2974"},
    {"city_id":"35846","city_name":"Olanu","state_id":"2974"},
    {"city_id":"35847","city_name":"Orlesti","state_id":"2974"},
    {"city_id":"35848","city_name":"Otesani","state_id":"2974"},
    {"city_id":"35849","city_name":"Pausesti","state_id":"2974"},
    {"city_id":"35850","city_name":"Pausesti-Maglasi","state_id":"2974"},
    {"city_id":"35851","city_name":"Perisani","state_id":"2974"},
    {"city_id":"35852","city_name":"Pesceana","state_id":"2974"},
    {"city_id":"35853","city_name":"Pietrari","state_id":"2974"},
    {"city_id":"35854","city_name":"Popesti","state_id":"2974"},
    {"city_id":"35855","city_name":"Prundeni","state_id":"2974"},
    {"city_id":"35856","city_name":"Racovita","state_id":"2974"},
    {"city_id":"35857","city_name":"Ramnicu Valcea","state_id":"2974"},
    {"city_id":"35858","city_name":"Roesti","state_id":"2974"},
    {"city_id":"35859","city_name":"Rosiile","state_id":"2974"},
    {"city_id":"35860","city_name":"Runcu","state_id":"2974"},
    {"city_id":"35861","city_name":"Salatrucel","state_id":"2974"},
    {"city_id":"35862","city_name":"Scundu","state_id":"2974"},
    {"city_id":"35863","city_name":"Sinesti","state_id":"2974"},
    {"city_id":"35864","city_name":"Sirineasa","state_id":"2974"},
    {"city_id":"35865","city_name":"Slatioara","state_id":"2974"},
    {"city_id":"35866","city_name":"Stanesti","state_id":"2974"},
    {"city_id":"35867","city_name":"Stefanesti","state_id":"2974"},
    {"city_id":"35868","city_name":"Stoenesti","state_id":"2974"},
    {"city_id":"35869","city_name":"Stoilesti","state_id":"2974"},
    {"city_id":"35870","city_name":"Stroesti","state_id":"2974"},
    {"city_id":"35871","city_name":"Susani","state_id":"2974"},
    {"city_id":"35872","city_name":"Sutesti","state_id":"2974"},
    {"city_id":"35873","city_name":"Tetoiu","state_id":"2974"},
    {"city_id":"35874","city_name":"Tomsani","state_id":"2974"},
    {"city_id":"35875","city_name":"Vaideeni","state_id":"2974"},
    {"city_id":"35876","city_name":"Valea Mare","state_id":"2974"},
    {"city_id":"35877","city_name":"Vladesti","state_id":"2974"},
    {"city_id":"35878","city_name":"Voicesti","state_id":"2974"},
    {"city_id":"35879","city_name":"Voineasa","state_id":"2974"},
    {"city_id":"35880","city_name":"Zatreni","state_id":"2974"},
    {"city_id":"35881","city_name":"Albesti","state_id":"2975"},
    {"city_id":"35882","city_name":"Alexandru Vlahuta","state_id":"2975"},
    {"city_id":"35883","city_name":"Arsura","state_id":"2975"},
    {"city_id":"35884","city_name":"Bacani","state_id":"2975"},
    {"city_id":"35885","city_name":"Bacesti","state_id":"2975"},
    {"city_id":"35886","city_name":"Balteni","state_id":"2975"},
    {"city_id":"35887","city_name":"Banca","state_id":"2975"},
    {"city_id":"35888","city_name":"Barlad","state_id":"2975"},
    {"city_id":"35889","city_name":"Berezeni","state_id":"2975"},
    {"city_id":"35890","city_name":"Blagesti","state_id":"2975"},
    {"city_id":"35891","city_name":"Bogdana","state_id":"2975"},
    {"city_id":"35892","city_name":"Bogdanesti","state_id":"2975"},
    {"city_id":"35893","city_name":"Bogdanita","state_id":"2975"},
    {"city_id":"35894","city_name":"Botesti","state_id":"2975"},
    {"city_id":"35895","city_name":"Bunesti","state_id":"2975"},
    {"city_id":"35896","city_name":"Codaesti","state_id":"2975"},
    {"city_id":"35897","city_name":"Coroiesti","state_id":"2975"},
    {"city_id":"35898","city_name":"Costesti","state_id":"2975"},
    {"city_id":"35899","city_name":"Cretesti","state_id":"2975"},
    {"city_id":"35900","city_name":"Danesti","state_id":"2975"},
    {"city_id":"35901","city_name":"Deleni","state_id":"2975"},
    {"city_id":"35902","city_name":"Delesti","state_id":"2975"},
    {"city_id":"35903","city_name":"Dimitrie Cantemir","state_id":"2975"},
    {"city_id":"35904","city_name":"Dragomiresti","state_id":"2975"},
    {"city_id":"35905","city_name":"Dranceni Sat","state_id":"2975"},
    {"city_id":"35906","city_name":"Duda","state_id":"2975"},
    {"city_id":"35907","city_name":"Dumesti","state_id":"2975"},
    {"city_id":"35908","city_name":"Epureni","state_id":"2975"},
    {"city_id":"35909","city_name":"Falciu","state_id":"2975"},
    {"city_id":"35910","city_name":"Gagesti","state_id":"2975"},
    {"city_id":"35911","city_name":"Garceni","state_id":"2975"},
    {"city_id":"35912","city_name":"Gherghesti","state_id":"2975"},
    {"city_id":"35913","city_name":"Grivita","state_id":"2975"},
    {"city_id":"35914","city_name":"Hoceni","state_id":"2975"},
    {"city_id":"35915","city_name":"Husi","state_id":"2975"},
    {"city_id":"35916","city_name":"Iana","state_id":"2975"},
    {"city_id":"35917","city_name":"Ivanesti","state_id":"2975"},
    {"city_id":"35918","city_name":"Ivesti","state_id":"2975"},
    {"city_id":"35919","city_name":"Laza","state_id":"2975"},
    {"city_id":"35920","city_name":"Lipovat","state_id":"2975"},
    {"city_id":"35921","city_name":"Lunca Banului","state_id":"2975"},
    {"city_id":"35922","city_name":"Malusteni","state_id":"2975"},
    {"city_id":"35923","city_name":"Miclesti","state_id":"2975"},
    {"city_id":"35924","city_name":"Muntenii de Jos","state_id":"2975"},
    {"city_id":"35925","city_name":"Murgeni","state_id":"2975"},
    {"city_id":"35926","city_name":"Negresti","state_id":"2975"},
    {"city_id":"35927","city_name":"Oltenesti","state_id":"2975"},
    {"city_id":"35928","city_name":"Osesti","state_id":"2975"},
    {"city_id":"35929","city_name":"Padureni","state_id":"2975"},
    {"city_id":"35930","city_name":"Perieni","state_id":"2975"},
    {"city_id":"35931","city_name":"Pogana","state_id":"2975"},
    {"city_id":"35932","city_name":"Poienesti","state_id":"2975"},
    {"city_id":"35933","city_name":"Puiesti","state_id":"2975"},
    {"city_id":"35934","city_name":"Pungesti","state_id":"2975"},
    {"city_id":"35935","city_name":"Rebricea","state_id":"2975"},
    {"city_id":"35936","city_name":"Rosiesti","state_id":"2975"},
    {"city_id":"35937","city_name":"Solesti","state_id":"2975"},
    {"city_id":"35938","city_name":"Stanilesti","state_id":"2975"},
    {"city_id":"35939","city_name":"Stefan cel Mare","state_id":"2975"},
    {"city_id":"35940","city_name":"Suletea","state_id":"2975"},
    {"city_id":"35941","city_name":"Tacuta","state_id":"2975"},
    {"city_id":"35942","city_name":"Tanacu","state_id":"2975"},
    {"city_id":"35943","city_name":"Tatarani","state_id":"2975"},
    {"city_id":"35944","city_name":"Todiresti","state_id":"2975"},
    {"city_id":"35945","city_name":"Tutova","state_id":"2975"},
    {"city_id":"35946","city_name":"Valeni","state_id":"2975"},
    {"city_id":"35947","city_name":"Vaslui","state_id":"2975"},
    {"city_id":"35948","city_name":"Vetrisoaia","state_id":"2975"},
    {"city_id":"35949","city_name":"Viisoara","state_id":"2975"},
    {"city_id":"35950","city_name":"Vinderei","state_id":"2975"},
    {"city_id":"35951","city_name":"Voinesti","state_id":"2975"},
    {"city_id":"35952","city_name":"Vulturesti","state_id":"2975"},
    {"city_id":"35953","city_name":"Vutcani","state_id":"2975"},
    {"city_id":"35954","city_name":"Zapodeni","state_id":"2975"},
    {"city_id":"35955","city_name":"Zorleni","state_id":"2975"},
    {"city_id":"35956","city_name":"Adjud","state_id":"2976"},
    {"city_id":"35957","city_name":"Andreiasu de Jos","state_id":"2976"},
    {"city_id":"35958","city_name":"Balesti","state_id":"2976"},
    {"city_id":"35959","city_name":"Barsesti","state_id":"2976"},
    {"city_id":"35960","city_name":"Boghesti","state_id":"2976"},
    {"city_id":"35961","city_name":"Bolotesti","state_id":"2976"},
    {"city_id":"35962","city_name":"Bordesti","state_id":"2976"},
    {"city_id":"35963","city_name":"Brosteni","state_id":"2976"},
    {"city_id":"35964","city_name":"Campineanca","state_id":"2976"},
    {"city_id":"35965","city_name":"Campuri","state_id":"2976"},
    {"city_id":"35966","city_name":"Carligele","state_id":"2976"},
    {"city_id":"35967","city_name":"Chiojdeni","state_id":"2976"},
    {"city_id":"35968","city_name":"Ciorasti","state_id":"2976"},
    {"city_id":"35969","city_name":"Corbita","state_id":"2976"},
    {"city_id":"35970","city_name":"Cotesti","state_id":"2976"},
    {"city_id":"35971","city_name":"Dumbraveni","state_id":"2976"},
    {"city_id":"35972","city_name":"Dumitresti","state_id":"2976"},
    {"city_id":"35973","city_name":"Fitionesti","state_id":"2976"},
    {"city_id":"35974","city_name":"Focsani","state_id":"2976"},
    {"city_id":"35975","city_name":"Garoafa","state_id":"2976"},
    {"city_id":"35976","city_name":"Golesti","state_id":"2976"},
    {"city_id":"35977","city_name":"Gugesti","state_id":"2976"},
    {"city_id":"35978","city_name":"Gura Calitei","state_id":"2976"},
    {"city_id":"35979","city_name":"Homocea","state_id":"2976"},
    {"city_id":"35980","city_name":"Jaristea","state_id":"2976"},
    {"city_id":"35981","city_name":"Jitia","state_id":"2976"},
    {"city_id":"35982","city_name":"Maicanesti","state_id":"2976"},
    {"city_id":"35983","city_name":"Marasesti","state_id":"2976"},
    {"city_id":"35984","city_name":"Mera","state_id":"2976"},
    {"city_id":"35985","city_name":"Milcovul","state_id":"2976"},
    {"city_id":"35986","city_name":"Movilita","state_id":"2976"},
    {"city_id":"35987","city_name":"Nanesti","state_id":"2976"},
    {"city_id":"35988","city_name":"Naruja","state_id":"2976"},
    {"city_id":"35989","city_name":"Nereju","state_id":"2976"},
    {"city_id":"35990","city_name":"Nistoresti","state_id":"2976"},
    {"city_id":"35991","city_name":"Odobesti","state_id":"2976"},
    {"city_id":"35992","city_name":"Paltin","state_id":"2976"},
    {"city_id":"35993","city_name":"Panciu","state_id":"2976"},
    {"city_id":"35994","city_name":"Paunesti","state_id":"2976"},
    {"city_id":"35995","city_name":"Poiana Cristei","state_id":"2976"},
    {"city_id":"35996","city_name":"Pufesti","state_id":"2976"},
    {"city_id":"35997","city_name":"Racoasa","state_id":"2976"},
    {"city_id":"35998","city_name":"Reghiu","state_id":"2976"},
    {"city_id":"35999","city_name":"Ruginesti","state_id":"2976"},
    {"city_id":"36000","city_name":"Sihlea","state_id":"2976"},
    {"city_id":"36001","city_name":"Slobozia Bradului","state_id":"2976"},
    {"city_id":"36002","city_name":"Slobozia-Ciorasti","state_id":"2976"},
    {"city_id":"36003","city_name":"Soveja","state_id":"2976"},
    {"city_id":"36004","city_name":"Straoane","state_id":"2976"},
    {"city_id":"36005","city_name":"Suraia","state_id":"2976"},
    {"city_id":"36006","city_name":"Tamboesti","state_id":"2976"},
    {"city_id":"36007","city_name":"Tanasoaia","state_id":"2976"},
    {"city_id":"36008","city_name":"Tataranu","state_id":"2976"},
    {"city_id":"36009","city_name":"Tifesti","state_id":"2976"},
    {"city_id":"36010","city_name":"Tulnici","state_id":"2976"},
    {"city_id":"36011","city_name":"Urechesti","state_id":"2976"},
    {"city_id":"36012","city_name":"Valea Sarii","state_id":"2976"},
    {"city_id":"36013","city_name":"Vanatori","state_id":"2976"},
    {"city_id":"36014","city_name":"Vartescoiu","state_id":"2976"},
    {"city_id":"36015","city_name":"Vidra","state_id":"2976"},
    {"city_id":"36016","city_name":"Vintileasca","state_id":"2976"},
    {"city_id":"36017","city_name":"Vizantea","state_id":"2976"},
    {"city_id":"36018","city_name":"Vrancioaia","state_id":"2976"},
    {"city_id":"36019","city_name":"Vulturu","state_id":"2976"},
    {"city_id":"36020","city_name":"Adygejsk","state_id":"2977"},
    {"city_id":"36021","city_name":"Enem","state_id":"2977"},
    {"city_id":"36022","city_name":"Jablonovskij","state_id":"2977"},
    {"city_id":"36023","city_name":"Kamennomostskij","state_id":"2977"},
    {"city_id":"36024","city_name":"Majkop","state_id":"2977"},
    {"city_id":"36025","city_name":"Tulskij","state_id":"2977"},
    {"city_id":"36026","city_name":"Aginskoje","state_id":"2978"},
    {"city_id":"36027","city_name":"Alagir","state_id":"2979"},
    {"city_id":"36028","city_name":"Ardon","state_id":"2979"},
    {"city_id":"36029","city_name":"Beslan","state_id":"2979"},
    {"city_id":"36030","city_name":"Digora","state_id":"2979"},
    {"city_id":"36031","city_name":"Mozdok","state_id":"2979"},
    {"city_id":"36032","city_name":"Vladikavkaz","state_id":"2979"},
    {"city_id":"36033","city_name":"Zavodskoj","state_id":"2979"},
    {"city_id":"36034","city_name":"Alejsk","state_id":"2980"},
    {"city_id":"36035","city_name":"Barnaul","state_id":"2980"},
    {"city_id":"36036","city_name":"Belojarsk","state_id":"2980"},
    {"city_id":"36037","city_name":"Belokuriha","state_id":"2980"},
    {"city_id":"36038","city_name":"Bijsk","state_id":"2980"},
    {"city_id":"36039","city_name":"Blagoveshchenka","state_id":"2980"},
    {"city_id":"36040","city_name":"Gornjak","state_id":"2980"},
    {"city_id":"36041","city_name":"Jarovoe","state_id":"2980"},
    {"city_id":"36042","city_name":"Juzhnyj","state_id":"2980"},
    {"city_id":"36043","city_name":"Kamen-na-Obi","state_id":"2980"},
    {"city_id":"36044","city_name":"Novoaltajsk","state_id":"2980"},
    {"city_id":"36045","city_name":"Novosilikatnyj","state_id":"2980"},
    {"city_id":"36046","city_name":"Rubcovsk","state_id":"2980"},
    {"city_id":"36047","city_name":"Sibirskiy","state_id":"2980"},
    {"city_id":"36048","city_name":"Slavgorod","state_id":"2980"},
    {"city_id":"36049","city_name":"Talmenka","state_id":"2980"},
    {"city_id":"36050","city_name":"Zarinsk","state_id":"2980"},
    {"city_id":"36051","city_name":"Zmeinogorsk","state_id":"2980"},
    {"city_id":"36052","city_name":"Arhara","state_id":"2981"},
    {"city_id":"36053","city_name":"Belogorsk","state_id":"2981"},
    {"city_id":"36054","city_name":"Blagoveshchensk","state_id":"2981"},
    {"city_id":"36055","city_name":"Jerofej Pavlovich","state_id":"2981"},
    {"city_id":"36056","city_name":"Magdagachi","state_id":"2981"},
    {"city_id":"36057","city_name":"Novoburejskij","state_id":"2981"},
    {"city_id":"36058","city_name":"Progress","state_id":"2981"},
    {"city_id":"36059","city_name":"Rajchihinsk","state_id":"2981"},
    {"city_id":"36060","city_name":"Seryshevo","state_id":"2981"},
    {"city_id":"36061","city_name":"Shimanovsk","state_id":"2981"},
    {"city_id":"36062","city_name":"Skovorodino","state_id":"2981"},
    {"city_id":"36063","city_name":"Svobodnyj","state_id":"2981"},
    {"city_id":"36064","city_name":"Tynda","state_id":"2981"},
    {"city_id":"36065","city_name":"Urusha","state_id":"2981"},
    {"city_id":"36066","city_name":"Zavitinsk","state_id":"2981"},
    {"city_id":"36067","city_name":"Zeja","state_id":"2981"},
    {"city_id":"36068","city_name":"Arhangelsk","state_id":"2982"},
    {"city_id":"36069","city_name":"Jemca","state_id":"2982"},
    {"city_id":"36070","city_name":"Jercevo","state_id":"2982"},
    {"city_id":"36071","city_name":"Kargopol","state_id":"2982"},
    {"city_id":"36072","city_name":"Konosha","state_id":"2982"},
    {"city_id":"36073","city_name":"Korjazhma","state_id":"2982"},
    {"city_id":"36074","city_name":"Kotlas","state_id":"2982"},
    {"city_id":"36075","city_name":"Kuloj","state_id":"2982"},
    {"city_id":"36076","city_name":"Maloshujka","state_id":"2982"},
    {"city_id":"36077","city_name":"Mirnyj","state_id":"2982"},
    {"city_id":"36078","city_name":"Njandoma","state_id":"2982"},
    {"city_id":"36079","city_name":"Novodvinsk","state_id":"2982"},
    {"city_id":"36080","city_name":"Obozjorskij","state_id":"2982"},
    {"city_id":"36081","city_name":"Oktjabrskij","state_id":"2982"},
    {"city_id":"36082","city_name":"Onega","state_id":"2982"},
    {"city_id":"36083","city_name":"Plesetsk","state_id":"2982"},
    {"city_id":"36084","city_name":"Podjuga","state_id":"2982"},
    {"city_id":"36085","city_name":"Puksoozero","state_id":"2982"},
    {"city_id":"36086","city_name":"Samoded","state_id":"2982"},
    {"city_id":"36087","city_name":"Savinskij","state_id":"2982"},
    {"city_id":"36088","city_name":"Severodvinsk","state_id":"2982"},
    {"city_id":"36089","city_name":"Shenkursk","state_id":"2982"},
    {"city_id":"36090","city_name":"Udimskij","state_id":"2982"},
    {"city_id":"36091","city_name":"Urdoma","state_id":"2982"},
    {"city_id":"36092","city_name":"Velsk","state_id":"2982"},
    {"city_id":"36093","city_name":"Vychegodskij","state_id":"2982"},
    {"city_id":"36094","city_name":"Ahtubinsk","state_id":"2983"},
    {"city_id":"36095","city_name":"Astrahan","state_id":"2983"},
    {"city_id":"36096","city_name":"Harabali","state_id":"2983"},
    {"city_id":"36097","city_name":"Kamyzjak","state_id":"2983"},
    {"city_id":"36098","city_name":"Kapustin Jar","state_id":"2983"},
    {"city_id":"36099","city_name":"Liman","state_id":"2983"},
    {"city_id":"36100","city_name":"Narimanov","state_id":"2983"},
    {"city_id":"36101","city_name":"Verhnij Baskunchak","state_id":"2983"},
    {"city_id":"36102","city_name":"Volodarskij","state_id":"2983"},
    {"city_id":"36103","city_name":"Znamensk","state_id":"2983"},
    {"city_id":"36104","city_name":"Agidel","state_id":"2984"},
    {"city_id":"36105","city_name":"Bajmak","state_id":"2984"},
    {"city_id":"36106","city_name":"Belebej","state_id":"2984"},
    {"city_id":"36107","city_name":"Beloreck","state_id":"2984"},
    {"city_id":"36108","city_name":"Birsk","state_id":"2984"},
    {"city_id":"36109","city_name":"Blagoveshchensk","state_id":"2984"},
    {"city_id":"36110","city_name":"Chishmy","state_id":"2984"},
    {"city_id":"36111","city_name":"Davlekanovo","state_id":"2984"},
    {"city_id":"36112","city_name":"Djurtjuli","state_id":"2984"},
    {"city_id":"36113","city_name":"Iglino","state_id":"2984"},
    {"city_id":"36114","city_name":"Ishimbaj","state_id":"2984"},
    {"city_id":"36115","city_name":"Janaul","state_id":"2984"},
    {"city_id":"36116","city_name":"Jermolajevo","state_id":"2984"},
    {"city_id":"36117","city_name":"Kandry","state_id":"2984"},
    {"city_id":"36118","city_name":"Krasnousolskij","state_id":"2984"},
    {"city_id":"36119","city_name":"Kumertau","state_id":"2984"},
    {"city_id":"36120","city_name":"Meleuz","state_id":"2984"},
    {"city_id":"36121","city_name":"Mezhgorje","state_id":"2984"},
    {"city_id":"36122","city_name":"Neftekamsk","state_id":"2984"},
    {"city_id":"36123","city_name":"Oktjabrskij","state_id":"2984"},
    {"city_id":"36124","city_name":"Oktyabrsky","state_id":"2984"},
    {"city_id":"36125","city_name":"Prijutovo","state_id":"2984"},
    {"city_id":"36126","city_name":"Rajevskij","state_id":"2984"},
    {"city_id":"36127","city_name":"Salavat","state_id":"2984"},
    {"city_id":"36128","city_name":"Serafimovskij","state_id":"2984"},
    {"city_id":"36129","city_name":"Sibaj","state_id":"2984"},
    {"city_id":"36130","city_name":"Sterlitamak","state_id":"2984"},
    {"city_id":"36131","city_name":"Tujmazy","state_id":"2984"},
    {"city_id":"36132","city_name":"Uchaly","state_id":"2984"},
    {"city_id":"36133","city_name":"Ufa","state_id":"2984"},
    {"city_id":"36134","city_name":"Aleksejevka","state_id":"2985"},
    {"city_id":"36135","city_name":"Belgorod","state_id":"2985"},
    {"city_id":"36136","city_name":"Borisovka","state_id":"2985"},
    {"city_id":"36137","city_name":"Chernjanka","state_id":"2985"},
    {"city_id":"36138","city_name":"Grajvoron","state_id":"2985"},
    {"city_id":"36139","city_name":"Gubkin","state_id":"2985"},
    {"city_id":"36140","city_name":"Novyj Oskol","state_id":"2985"},
    {"city_id":"36141","city_name":"Rakitnoe","state_id":"2985"},
    {"city_id":"36142","city_name":"Razumnoe","state_id":"2985"},
    {"city_id":"36143","city_name":"Shebekino","state_id":"2985"},
    {"city_id":"36144","city_name":"Staryj Oskol","state_id":"2985"},
    {"city_id":"36145","city_name":"Stroitel","state_id":"2985"},
    {"city_id":"36146","city_name":"Tomarovka","state_id":"2985"},
    {"city_id":"36147","city_name":"Valujki","state_id":"2985"},
    {"city_id":"36148","city_name":"Volokonovka","state_id":"2985"},
    {"city_id":"36149","city_name":"Belye Berega","state_id":"2986"},
    {"city_id":"36150","city_name":"Brjansk","state_id":"2986"},
    {"city_id":"36151","city_name":"Djatkovo","state_id":"2986"},
    {"city_id":"36152","city_name":"Fokino","state_id":"2986"},
    {"city_id":"36153","city_name":"Karachev","state_id":"2986"},
    {"city_id":"36154","city_name":"Kletnja","state_id":"2986"},
    {"city_id":"36155","city_name":"Klimovo","state_id":"2986"},
    {"city_id":"36156","city_name":"Klincy","state_id":"2986"},
    {"city_id":"36157","city_name":"Lokot","state_id":"2986"},
    {"city_id":"36158","city_name":"Navlja","state_id":"2986"},
    {"city_id":"36159","city_name":"Novozybkov","state_id":"2986"},
    {"city_id":"36160","city_name":"Pochjop","state_id":"2986"},
    {"city_id":"36161","city_name":"Pogar","state_id":"2986"},
    {"city_id":"36162","city_name":"Selco","state_id":"2986"},
    {"city_id":"36163","city_name":"Starodub","state_id":"2986"},
    {"city_id":"36164","city_name":"Surazh","state_id":"2986"},
    {"city_id":"36165","city_name":"Suzjomka","state_id":"2986"},
    {"city_id":"36166","city_name":"Trubchjovsk","state_id":"2986"},
    {"city_id":"36167","city_name":"Unecha","state_id":"2986"},
    {"city_id":"36168","city_name":"Zhukovka","state_id":"2986"},
    {"city_id":"36169","city_name":"Gusinoozjorsk","state_id":"2987"},
    {"city_id":"36170","city_name":"Kamensk","state_id":"2987"},
    {"city_id":"36171","city_name":"Kjahta","state_id":"2987"},
    {"city_id":"36172","city_name":"Novyj Uojan","state_id":"2987"},
    {"city_id":"36173","city_name":"Onohoj","state_id":"2987"},
    {"city_id":"36174","city_name":"Selenginsk","state_id":"2987"},
    {"city_id":"36175","city_name":"Severobajkalsk","state_id":"2987"},
    {"city_id":"36176","city_name":"Taksimo","state_id":"2987"},
    {"city_id":"36177","city_name":"Ulan-Ude","state_id":"2987"},
    {"city_id":"36178","city_name":"Zakamensk","state_id":"2987"},
    {"city_id":"36179","city_name":"Zarechnyj","state_id":"2987"},
    {"city_id":"36180","city_name":"Groznyj","state_id":"2988"},
    {"city_id":"36181","city_name":"Gudermes","state_id":"2988"},
    {"city_id":"36182","city_name":"Malgobek","state_id":"2988"},
    {"city_id":"36183","city_name":"Urus-Martan","state_id":"2988"},
    {"city_id":"36184","city_name":"Asha","state_id":"2989"},
    {"city_id":"36185","city_name":"Bakal","state_id":"2989"},
    {"city_id":"36186","city_name":"Bazhovo","state_id":"2989"},
    {"city_id":"36187","city_name":"Berdjaush","state_id":"2989"},
    {"city_id":"36188","city_name":"Chebarkul","state_id":"2989"},
    {"city_id":"36189","city_name":"Cheljabinsk","state_id":"2989"},
    {"city_id":"36190","city_name":"Chelyabinsk","state_id":"2989"},
    {"city_id":"36191","city_name":"Gornjak","state_id":"2989"},
    {"city_id":"36192","city_name":"Jemanzhelinsk","state_id":"2989"},
    {"city_id":"36193","city_name":"Jurjuzan","state_id":"2989"},
    {"city_id":"36194","city_name":"Juznouralsk","state_id":"2989"},
    {"city_id":"36195","city_name":"Karabash","state_id":"2989"},
    {"city_id":"36196","city_name":"Kartaly","state_id":"2989"},
    {"city_id":"36197","city_name":"Kasli","state_id":"2989"},
    {"city_id":"36198","city_name":"Katav-Ivanovsk","state_id":"2989"},
    {"city_id":"36199","city_name":"Kopejsk","state_id":"2989"},
    {"city_id":"36200","city_name":"Korkino","state_id":"2989"},
    {"city_id":"36201","city_name":"Krasnogorskij","state_id":"2989"},
    {"city_id":"36202","city_name":"Kusa","state_id":"2989"},
    {"city_id":"36203","city_name":"Kyshtym","state_id":"2989"},
    {"city_id":"36204","city_name":"Lokomotivnyj","state_id":"2989"},
    {"city_id":"36205","city_name":"Magnitka","state_id":"2989"},
    {"city_id":"36206","city_name":"Magnitogorsk","state_id":"2989"},
    {"city_id":"36207","city_name":"Miass","state_id":"2989"},
    {"city_id":"36208","city_name":"Minjar","state_id":"2989"},
    {"city_id":"36209","city_name":"Njazepetrovsk","state_id":"2989"},
    {"city_id":"36210","city_name":"Novosineglazovskij","state_id":"2989"},
    {"city_id":"36211","city_name":"Ozjorsk","state_id":"2989"},
    {"city_id":"36212","city_name":"Pervomajskij","state_id":"2989"},
    {"city_id":"36213","city_name":"Plast","state_id":"2989"},
    {"city_id":"36214","city_name":"Roza","state_id":"2989"},
    {"city_id":"36215","city_name":"Satka","state_id":"2989"},
    {"city_id":"36216","city_name":"Sim","state_id":"2989"},
    {"city_id":"36217","city_name":"Snezhinsk","state_id":"2989"},
    {"city_id":"36218","city_name":"Starokamyshinsk","state_id":"2989"},
    {"city_id":"36219","city_name":"Suleja","state_id":"2989"},
    {"city_id":"36220","city_name":"Trjohgornij","state_id":"2989"},
    {"city_id":"36221","city_name":"Troick","state_id":"2989"},
    {"city_id":"36222","city_name":"Ust-Katav","state_id":"2989"},
    {"city_id":"36223","city_name":"Verhneuralsk","state_id":"2989"},
    {"city_id":"36224","city_name":"Verhnij Ufalej","state_id":"2989"},
    {"city_id":"36225","city_name":"Zlatoust","state_id":"2989"},
    {"city_id":"36226","city_name":"Atamanovka","state_id":"2990"},
    {"city_id":"36227","city_name":"Balej","state_id":"2990"},
    {"city_id":"36228","city_name":"Borzja","state_id":"2990"},
    {"city_id":"36229","city_name":"Bukachacha","state_id":"2990"},
    {"city_id":"36230","city_name":"Chernyshevsk","state_id":"2990"},
    {"city_id":"36231","city_name":"Chita","state_id":"2990"},
    {"city_id":"36232","city_name":"Darasun","state_id":"2990"},
    {"city_id":"36233","city_name":"Gornyy","state_id":"2990"},
    {"city_id":"36234","city_name":"Hilok","state_id":"2990"},
    {"city_id":"36235","city_name":"Karymskoje","state_id":"2990"},
    {"city_id":"36236","city_name":"Krasnokamensk","state_id":"2990"},
    {"city_id":"36237","city_name":"Mogocha","state_id":"2990"},
    {"city_id":"36238","city_name":"Nerchinsk","state_id":"2990"},
    {"city_id":"36239","city_name":"Novaja Chara","state_id":"2990"},
    {"city_id":"36240","city_name":"Novokruchuninskij","state_id":"2990"},
    {"city_id":"36241","city_name":"Olovjannaja","state_id":"2990"},
    {"city_id":"36242","city_name":"Pervomajskij","state_id":"2990"},
    {"city_id":"36243","city_name":"Petrovsk-Zabajkalskij","state_id":"2990"},
    {"city_id":"36244","city_name":"Sherlovaja Gora","state_id":"2990"},
    {"city_id":"36245","city_name":"Shilka","state_id":"2990"},
    {"city_id":"36246","city_name":"Sretensk","state_id":"2990"},
    {"city_id":"36247","city_name":"Vershino-Darasunskij","state_id":"2990"},
    {"city_id":"36248","city_name":"Zabajkalsk","state_id":"2990"},
    {"city_id":"36249","city_name":"Anadyr","state_id":"2991"},
    {"city_id":"36250","city_name":"Bilibino","state_id":"2991"},
    {"city_id":"36251","city_name":"Pevek","state_id":"2991"},
    {"city_id":"36252","city_name":"Ugolnyje Kopi","state_id":"2991"},
    {"city_id":"36253","city_name":"Alatyr","state_id":"2992"},
    {"city_id":"36254","city_name":"Cheboksary","state_id":"2992"},
    {"city_id":"36255","city_name":"Civilsk","state_id":"2992"},
    {"city_id":"36256","city_name":"Ibresi","state_id":"2992"},
    {"city_id":"36257","city_name":"Jadrin","state_id":"2992"},
    {"city_id":"36258","city_name":"Kanash","state_id":"2992"},
    {"city_id":"36259","city_name":"Kozlovka","state_id":"2992"},
    {"city_id":"36260","city_name":"Kugesy","state_id":"2992"},
    {"city_id":"36261","city_name":"Mariinskij Posad","state_id":"2992"},
    {"city_id":"36262","city_name":"Novocheboksarsk","state_id":"2992"},
    {"city_id":"36263","city_name":"Shumerlja","state_id":"2992"},
    {"city_id":"36264","city_name":"Vurnary","state_id":"2992"},
    {"city_id":"36265","city_name":"Belidzhi","state_id":"2993"},
    {"city_id":"36266","city_name":"Bujnaksk","state_id":"2993"},
    {"city_id":"36267","city_name":"Dagestanskije Ogni","state_id":"2993"},
    {"city_id":"36268","city_name":"Derbent","state_id":"2993"},
    {"city_id":"36269","city_name":"Hasavjurt","state_id":"2993"},
    {"city_id":"36270","city_name":"Izberbash","state_id":"2993"},
    {"city_id":"36271","city_name":"Juzhno-Suhokumsk","state_id":"2993"},
    {"city_id":"36272","city_name":"Kaspijsk","state_id":"2993"},
    {"city_id":"36273","city_name":"Kiziljurt","state_id":"2993"},
    {"city_id":"36274","city_name":"Kizljar","state_id":"2993"},
    {"city_id":"36275","city_name":"Mahackala","state_id":"2993"},
    {"city_id":"36276","city_name":"Tura","state_id":"2994"},
    {"city_id":"36277","city_name":"Gorno-Altajsk","state_id":"2995"},
    {"city_id":"36278","city_name":"Amursk","state_id":"2996"},
    {"city_id":"36279","city_name":"Bikin","state_id":"2996"},
    {"city_id":"36280","city_name":"Chegdomyn","state_id":"2996"},
    {"city_id":"36281","city_name":"Cherdomyn","state_id":"2996"},
    {"city_id":"36282","city_name":"Habarovsk","state_id":"2996"},
    {"city_id":"36283","city_name":"Hor","state_id":"2996"},
    {"city_id":"36284","city_name":"Jelban","state_id":"2996"},
    {"city_id":"36285","city_name":"Komsomolsk-na-Amure","state_id":"2996"},
    {"city_id":"36286","city_name":"Litovko","state_id":"2996"},
    {"city_id":"36287","city_name":"Nikolajevsk-na-Amure","state_id":"2996"},
    {"city_id":"36288","city_name":"Ohotsk","state_id":"2996"},
    {"city_id":"36289","city_name":"Perejaslavka","state_id":"2996"},
    {"city_id":"36290","city_name":"Solnechnyj","state_id":"2996"},
    {"city_id":"36291","city_name":"Sovetskaja Gavan","state_id":"2996"},
    {"city_id":"36292","city_name":"Uglegorsk","state_id":"2996"},
    {"city_id":"36293","city_name":"Vanino","state_id":"2996"},
    {"city_id":"36294","city_name":"Vjazemskij","state_id":"2996"},
    {"city_id":"36295","city_name":"Zavety Iljicha","state_id":"2996"},
    {"city_id":"36296","city_name":"Abakan","state_id":"2997"},
    {"city_id":"36297","city_name":"Abaza","state_id":"2997"},
    {"city_id":"36298","city_name":"Cherjomushki","state_id":"2997"},
    {"city_id":"36299","city_name":"Chernogorsk","state_id":"2997"},
    {"city_id":"36300","city_name":"Sajanogorsk","state_id":"2997"},
    {"city_id":"36301","city_name":"Shira","state_id":"2997"},
    {"city_id":"36302","city_name":"Sorsk","state_id":"2997"},
    {"city_id":"36303","city_name":"Ust-Abakan","state_id":"2997"},
    {"city_id":"36304","city_name":"Belojarskij","state_id":"2998"},
    {"city_id":"36305","city_name":"Belyj Jar","state_id":"2998"},
    {"city_id":"36306","city_name":"Fjodorovskij","state_id":"2998"},
    {"city_id":"36307","city_name":"Hanty-Mansijsk","state_id":"2998"},
    {"city_id":"36308","city_name":"Igrim","state_id":"2998"},
    {"city_id":"36309","city_name":"Izluchinsk","state_id":"2998"},
    {"city_id":"36310","city_name":"Jugorsk","state_id":"2998"},
    {"city_id":"36311","city_name":"Kogalym","state_id":"2998"},
    {"city_id":"36312","city_name":"Langepas","state_id":"2998"},
    {"city_id":"36313","city_name":"Ljantor","state_id":"2998"},
    {"city_id":"36314","city_name":"Megion","state_id":"2998"},
    {"city_id":"36315","city_name":"Mezhdurechenskij","state_id":"2998"},
    {"city_id":"36316","city_name":"Neftejugansk","state_id":"2998"},
    {"city_id":"36317","city_name":"Nizhnevartovsk","state_id":"2998"},
    {"city_id":"36318","city_name":"Njagan","state_id":"2998"},
    {"city_id":"36319","city_name":"Novoagansk","state_id":"2998"},
    {"city_id":"36320","city_name":"Pojkovskij","state_id":"2998"},
    {"city_id":"36321","city_name":"Pokachi","state_id":"2998"},
    {"city_id":"36322","city_name":"Raduzhnyj","state_id":"2998"},
    {"city_id":"36323","city_name":"Sovetskij","state_id":"2998"},
    {"city_id":"36324","city_name":"Surgut","state_id":"2998"},
    {"city_id":"36325","city_name":"Uraj","state_id":"2998"},
    {"city_id":"36326","city_name":"Karabulak","state_id":"2999"},
    {"city_id":"36327","city_name":"Nazran","state_id":"2999"},
    {"city_id":"36328","city_name":"Angarsk","state_id":"3000"},
    {"city_id":"36329","city_name":"Bajkalsk","state_id":"3000"},
    {"city_id":"36330","city_name":"Balagansk","state_id":"3000"},
    {"city_id":"36331","city_name":"Birjusinsk","state_id":"3000"},
    {"city_id":"36332","city_name":"Bodajbo","state_id":"3000"},
    {"city_id":"36333","city_name":"Bratsk","state_id":"3000"},
    {"city_id":"36334","city_name":"Cheremhovo","state_id":"3000"},
    {"city_id":"36335","city_name":"Chunskij","state_id":"3000"},
    {"city_id":"36336","city_name":"Irkutsk","state_id":"3000"},
    {"city_id":"36337","city_name":"Kirensk","state_id":"3000"},
    {"city_id":"36338","city_name":"Kujtun","state_id":"3000"},
    {"city_id":"36339","city_name":"Mihajlovka","state_id":"3000"},
    {"city_id":"36340","city_name":"Nizhneudinsk","state_id":"3000"},
    {"city_id":"36341","city_name":"Novaja Igirma","state_id":"3000"},
    {"city_id":"36342","city_name":"Sajansk","state_id":"3000"},
    {"city_id":"36343","city_name":"Shelehov","state_id":"3000"},
    {"city_id":"36344","city_name":"Sljudjanka","state_id":"3000"},
    {"city_id":"36345","city_name":"Svirsk","state_id":"3000"},
    {"city_id":"36346","city_name":"Tajshet","state_id":"3000"},
    {"city_id":"36347","city_name":"Tulun","state_id":"3000"},
    {"city_id":"36348","city_name":"Usolje-Sibirskoje","state_id":"3000"},
    {"city_id":"36349","city_name":"Ust-Ilimsk","state_id":"3000"},
    {"city_id":"36350","city_name":"Ust-Kut","state_id":"3000"},
    {"city_id":"36351","city_name":"Vihorevka","state_id":"3000"},
    {"city_id":"36352","city_name":"Zalari","state_id":"3000"},
    {"city_id":"36353","city_name":"Zheleznodorozhnyj","state_id":"3000"},
    {"city_id":"36354","city_name":"Zheleznogorsk-Ilimskij","state_id":"3000"},
    {"city_id":"36355","city_name":"Zima","state_id":"3000"},
    {"city_id":"36356","city_name":"Furmanov","state_id":"3001"},
    {"city_id":"36357","city_name":"Ivanovo","state_id":"3001"},
    {"city_id":"36358","city_name":"Jurjevec","state_id":"3001"},
    {"city_id":"36359","city_name":"Juzha","state_id":"3001"},
    {"city_id":"36360","city_name":"Kineshma","state_id":"3001"},
    {"city_id":"36361","city_name":"Kohma","state_id":"3001"},
    {"city_id":"36362","city_name":"Komsomolsk","state_id":"3001"},
    {"city_id":"36363","city_name":"Lezhnjovo","state_id":"3001"},
    {"city_id":"36364","city_name":"Navoloki","state_id":"3001"},
    {"city_id":"36365","city_name":"Privolzhsk","state_id":"3001"},
    {"city_id":"36366","city_name":"Puchezh","state_id":"3001"},
    {"city_id":"36367","city_name":"Rodniki","state_id":"3001"},
    {"city_id":"36368","city_name":"Shuja","state_id":"3001"},
    {"city_id":"36369","city_name":"Tejkovo","state_id":"3001"},
    {"city_id":"36370","city_name":"Vichuga","state_id":"3001"},
    {"city_id":"36371","city_name":"Gubkinskij","state_id":"3002"},
    {"city_id":"36372","city_name":"Korotchajevo","state_id":"3002"},
    {"city_id":"36373","city_name":"Labytnangi","state_id":"3002"},
    {"city_id":"36374","city_name":"Muravlenko","state_id":"3002"},
    {"city_id":"36375","city_name":"Nadym","state_id":"3002"},
    {"city_id":"36376","city_name":"Nojabrsk","state_id":"3002"},
    {"city_id":"36377","city_name":"Novyj Urengoj","state_id":"3002"},
    {"city_id":"36378","city_name":"Pangody","state_id":"3002"},
    {"city_id":"36379","city_name":"Salehard","state_id":"3002"},
    {"city_id":"36380","city_name":"Tarko-Sale","state_id":"3002"},
    {"city_id":"36381","city_name":"Urengoj","state_id":"3002"},
    {"city_id":"36382","city_name":"Danilov","state_id":"3003"},
    {"city_id":"36383","city_name":"Gavrilov-Jam","state_id":"3003"},
    {"city_id":"36384","city_name":"Jaroslavl","state_id":"3003"},
    {"city_id":"36385","city_name":"Pereslavl-Zalesskij","state_id":"3003"},
    {"city_id":"36386","city_name":"Rostov","state_id":"3003"},
    {"city_id":"36387","city_name":"Rybinsk","state_id":"3003"},
    {"city_id":"36388","city_name":"Tutajev","state_id":"3003"},
    {"city_id":"36389","city_name":"Uglich","state_id":"3003"},
    {"city_id":"36390","city_name":"Birakan","state_id":"3004"},
    {"city_id":"36391","city_name":"Birobidzhan","state_id":"3004"},
    {"city_id":"36392","city_name":"Imeni Telmana","state_id":"3004"},
    {"city_id":"36393","city_name":"Izvestkovyj","state_id":"3004"},
    {"city_id":"36394","city_name":"Londoko","state_id":"3004"},
    {"city_id":"36395","city_name":"Obluchje","state_id":"3004"},
    {"city_id":"36396","city_name":"Teploozjorsk","state_id":"3004"},
    {"city_id":"36397","city_name":"Baksan","state_id":"3005"},
    {"city_id":"36398","city_name":"Chegem Pervyj","state_id":"3005"},
    {"city_id":"36399","city_name":"Majskij","state_id":"3005"},
    {"city_id":"36400","city_name":"Nalchik","state_id":"3005"},
    {"city_id":"36401","city_name":"Nartkala","state_id":"3005"},
    {"city_id":"36402","city_name":"Prohladnyj","state_id":"3005"},
    {"city_id":"36403","city_name":"Terek","state_id":"3005"},
    {"city_id":"36404","city_name":"Tyrnyauz","state_id":"3005"},
    {"city_id":"36405","city_name":"Bagrationovsk","state_id":"3006"},
    {"city_id":"36406","city_name":"Baltijsk","state_id":"3006"},
    {"city_id":"36407","city_name":"Chernjahovsk","state_id":"3006"},
    {"city_id":"36408","city_name":"Gurjevsk","state_id":"3006"},
    {"city_id":"36409","city_name":"Gusev","state_id":"3006"},
    {"city_id":"36410","city_name":"Gvardejsk","state_id":"3006"},
    {"city_id":"36411","city_name":"Kaliningrad","state_id":"3006"},
    {"city_id":"36412","city_name":"Mamonovo","state_id":"3006"},
    {"city_id":"36413","city_name":"Neman","state_id":"3006"},
    {"city_id":"36414","city_name":"Nesterov","state_id":"3006"},
    {"city_id":"36415","city_name":"Ozjorsk","state_id":"3006"},
    {"city_id":"36416","city_name":"Pionerskij","state_id":"3006"},
    {"city_id":"36417","city_name":"Sovetsk","state_id":"3006"},
    {"city_id":"36418","city_name":"Svetlogorsk","state_id":"3006"},
    {"city_id":"36419","city_name":"Svetlyj","state_id":"3006"},
    {"city_id":"36420","city_name":"Zeljenogradsk","state_id":"3006"},
    {"city_id":"36421","city_name":"Znamensk","state_id":"3006"},
    {"city_id":"36422","city_name":"Elista","state_id":"3007"},
    {"city_id":"36423","city_name":"Gorodovikovsk","state_id":"3007"},
    {"city_id":"36424","city_name":"Lagan","state_id":"3007"},
    {"city_id":"36425","city_name":"Balabanovo","state_id":"3008"},
    {"city_id":"36426","city_name":"Belousovo","state_id":"3008"},
    {"city_id":"36427","city_name":"Borovsk","state_id":"3008"},
    {"city_id":"36428","city_name":"Jermolino","state_id":"3008"},
    {"city_id":"36429","city_name":"Kaluga","state_id":"3008"},
    {"city_id":"36430","city_name":"Kirov","state_id":"3008"},
    {"city_id":"36431","city_name":"Kondrovo","state_id":"3008"},
    {"city_id":"36432","city_name":"Kozelsk","state_id":"3008"},
    {"city_id":"36433","city_name":"Kremenki","state_id":"3008"},
    {"city_id":"36434","city_name":"Ljudinovo","state_id":"3008"},
    {"city_id":"36435","city_name":"Malojaroslavec","state_id":"3008"},
    {"city_id":"36436","city_name":"Obninsk","state_id":"3008"},
    {"city_id":"36437","city_name":"Sosenskij","state_id":"3008"},
    {"city_id":"36438","city_name":"Suhinichi","state_id":"3008"},
    {"city_id":"36439","city_name":"Tarusa","state_id":"3008"},
    {"city_id":"36440","city_name":"Tovarkovo","state_id":"3008"},
    {"city_id":"36441","city_name":"Zhukov","state_id":"3008"},
    {"city_id":"36442","city_name":"Jelizovo","state_id":"3009"},
    {"city_id":"36443","city_name":"Kljuchi","state_id":"3009"},
    {"city_id":"36444","city_name":"Mohovaja","state_id":"3009"},
    {"city_id":"36445","city_name":"Petropavlovsk-Kamchatskij","state_id":"3009"},
    {"city_id":"36446","city_name":"Ust-Kamchatsk","state_id":"3009"},
    {"city_id":"36447","city_name":"Viljuchinsk","state_id":"3009"},
    {"city_id":"36448","city_name":"Vulkannyj","state_id":"3009"},
    {"city_id":"36449","city_name":"Cherkessk","state_id":"3010"},
    {"city_id":"36450","city_name":"Karachajevsk","state_id":"3010"},
    {"city_id":"36451","city_name":"Ust-Dzheguta","state_id":"3010"},
    {"city_id":"36452","city_name":"Belomorsk","state_id":"3011"},
    {"city_id":"36453","city_name":"Kem","state_id":"3011"},
    {"city_id":"36454","city_name":"Kondopoga","state_id":"3011"},
    {"city_id":"36455","city_name":"Kostomuksha","state_id":"3011"},
    {"city_id":"36456","city_name":"Lahdenpohja","state_id":"3011"},
    {"city_id":"36457","city_name":"Medvezhjegorsk","state_id":"3011"},
    {"city_id":"36458","city_name":"Nadvoicy","state_id":"3011"},
    {"city_id":"36459","city_name":"Olonec","state_id":"3011"},
    {"city_id":"36460","city_name":"Petrozavodsk","state_id":"3011"},
    {"city_id":"36461","city_name":"Pitkjaranta","state_id":"3011"},
    {"city_id":"36462","city_name":"Pudozh","state_id":"3011"},
    {"city_id":"36463","city_name":"Segezha","state_id":"3011"},
    {"city_id":"36464","city_name":"Sortavala","state_id":"3011"},
    {"city_id":"36465","city_name":"Suojarvi","state_id":"3011"},
    {"city_id":"36466","city_name":"Anzhero-Sudzhensk","state_id":"3012"},
    {"city_id":"36467","city_name":"Bachatskij","state_id":"3012"},
    {"city_id":"36468","city_name":"Belovo","state_id":"3012"},
    {"city_id":"36469","city_name":"Berjozovskij","state_id":"3012"},
    {"city_id":"36470","city_name":"Gramoteino","state_id":"3012"},
    {"city_id":"36471","city_name":"Gurjevsk","state_id":"3012"},
    {"city_id":"36472","city_name":"Inskoj","state_id":"3012"},
    {"city_id":"36473","city_name":"Jashkino","state_id":"3012"},
    {"city_id":"36474","city_name":"Jurga","state_id":"3012"},
    {"city_id":"36475","city_name":"Kaltan","state_id":"3012"},
    {"city_id":"36476","city_name":"Kedrovka","state_id":"3012"},
    {"city_id":"36477","city_name":"Kemerovo","state_id":"3012"},
    {"city_id":"36478","city_name":"Kiseljovsk","state_id":"3012"},
    {"city_id":"36479","city_name":"Krasnobrodskij","state_id":"3012"},
    {"city_id":"36480","city_name":"Leninsk-Kuzneckij","state_id":"3012"},
    {"city_id":"36481","city_name":"Malinovka","state_id":"3012"},
    {"city_id":"36482","city_name":"Mariinsk","state_id":"3012"},
    {"city_id":"36483","city_name":"Mezhdurechensk","state_id":"3012"},
    {"city_id":"36484","city_name":"Myski","state_id":"3012"},
    {"city_id":"36485","city_name":"Novokuzneck","state_id":"3012"},
    {"city_id":"36486","city_name":"Novokuznetsk","state_id":"3012"},
    {"city_id":"36487","city_name":"Novyj Gorodok","state_id":"3012"},
    {"city_id":"36488","city_name":"Osinniki","state_id":"3012"},
    {"city_id":"36489","city_name":"Polsaevo","state_id":"3012"},
    {"city_id":"36490","city_name":"Prokopjevsk","state_id":"3012"},
    {"city_id":"36491","city_name":"Promyshlennaja","state_id":"3012"},
    {"city_id":"36492","city_name":"Promyshlennovskij","state_id":"3012"},
    {"city_id":"36493","city_name":"Salair","state_id":"3012"},
    {"city_id":"36494","city_name":"Starobachaty","state_id":"3012"},
    {"city_id":"36495","city_name":"Tajga","state_id":"3012"},
    {"city_id":"36496","city_name":"Tajzhina","state_id":"3012"},
    {"city_id":"36497","city_name":"Tashtagol","state_id":"3012"},
    {"city_id":"36498","city_name":"Temirtau","state_id":"3012"},
    {"city_id":"36499","city_name":"Tisul","state_id":"3012"},
    {"city_id":"36500","city_name":"Tjazhinskij","state_id":"3012"},
    {"city_id":"36501","city_name":"Topki","state_id":"3012"},
    {"city_id":"36502","city_name":"Belaja Holunica","state_id":"3014"},
    {"city_id":"36503","city_name":"Jaransk","state_id":"3014"},
    {"city_id":"36504","city_name":"Jurja","state_id":"3014"},
    {"city_id":"36505","city_name":"Kirov","state_id":"3014"},
    {"city_id":"36506","city_name":"Kirovo-Chepeck","state_id":"3014"},
    {"city_id":"36507","city_name":"Kirs","state_id":"3014"},
    {"city_id":"36508","city_name":"Kotelnich","state_id":"3014"},
    {"city_id":"36509","city_name":"Ljangasovo","state_id":"3014"},
    {"city_id":"36510","city_name":"Luza","state_id":"3014"},
    {"city_id":"36511","city_name":"Malmyzh","state_id":"3014"},
    {"city_id":"36512","city_name":"Murashi","state_id":"3014"},
    {"city_id":"36513","city_name":"Nolinsk","state_id":"3014"},
    {"city_id":"36514","city_name":"Omutninsk","state_id":"3014"},
    {"city_id":"36515","city_name":"Orichi","state_id":"3014"},
    {"city_id":"36516","city_name":"Orlov","state_id":"3014"},
    {"city_id":"36517","city_name":"Pervomajskij","state_id":"3014"},
    {"city_id":"36518","city_name":"Peskovka","state_id":"3014"},
    {"city_id":"36519","city_name":"Slobodskoj","state_id":"3014"},
    {"city_id":"36520","city_name":"Sosnovka","state_id":"3014"},
    {"city_id":"36521","city_name":"Sovetsk","state_id":"3014"},
    {"city_id":"36522","city_name":"Urzhum","state_id":"3014"},
    {"city_id":"36523","city_name":"Vahrushi","state_id":"3014"},
    {"city_id":"36524","city_name":"Vjatskije Poljany","state_id":"3014"},
    {"city_id":"36525","city_name":"Zujevka","state_id":"3014"},
    {"city_id":"36526","city_name":"Blagoevo","state_id":"3015"},
    {"city_id":"36527","city_name":"Inta","state_id":"3015"},
    {"city_id":"36528","city_name":"Jarega","state_id":"3015"},
    {"city_id":"36529","city_name":"Jeletskij","state_id":"3015"},
    {"city_id":"36530","city_name":"Jemva","state_id":"3015"},
    {"city_id":"36531","city_name":"Komsomolskij","state_id":"3015"},
    {"city_id":"36532","city_name":"Krasnozatonskij","state_id":"3015"},
    {"city_id":"36533","city_name":"Mikun","state_id":"3015"},
    {"city_id":"36534","city_name":"Nizhnij Odes","state_id":"3015"},
    {"city_id":"36535","city_name":"Pechora","state_id":"3015"},
    {"city_id":"36536","city_name":"Promyshlennyj","state_id":"3015"},
    {"city_id":"36537","city_name":"Severnyj","state_id":"3015"},
    {"city_id":"36538","city_name":"Sosnogorsk","state_id":"3015"},
    {"city_id":"36539","city_name":"Syktyvkar","state_id":"3015"},
    {"city_id":"36540","city_name":"Troicko-Pechorsk","state_id":"3015"},
    {"city_id":"36541","city_name":"Uhta","state_id":"3015"},
    {"city_id":"36542","city_name":"Usinsk","state_id":"3015"},
    {"city_id":"36543","city_name":"Usogorsk","state_id":"3015"},
    {"city_id":"36544","city_name":"Vorgashor","state_id":"3015"},
    {"city_id":"36545","city_name":"Vorkuta","state_id":"3015"},
    {"city_id":"36546","city_name":"Vuktyl","state_id":"3015"},
    {"city_id":"36547","city_name":"Zheshart","state_id":"3015"},
    {"city_id":"36548","city_name":"Kudymkar","state_id":"3016"},
    {"city_id":"36549","city_name":"Palana","state_id":"3017"},
    {"city_id":"36550","city_name":"Buj","state_id":"3018"},
    {"city_id":"36551","city_name":"Galich","state_id":"3018"},
    {"city_id":"36552","city_name":"Kostroma","state_id":"3018"},
    {"city_id":"36553","city_name":"Makarjev","state_id":"3018"},
    {"city_id":"36554","city_name":"Manturovo","state_id":"3018"},
    {"city_id":"36555","city_name":"Neja","state_id":"3018"},
    {"city_id":"36556","city_name":"Nerehta","state_id":"3018"},
    {"city_id":"36557","city_name":"Sharja","state_id":"3018"},
    {"city_id":"36558","city_name":"Vetluzhskij","state_id":"3018"},
    {"city_id":"36559","city_name":"Volgorechensk","state_id":"3018"},
    {"city_id":"36560","city_name":"Zavolzhsk","state_id":"3018"},
    {"city_id":"36561","city_name":"Abinsk","state_id":"3019"},
    {"city_id":"36562","city_name":"Achujevo","state_id":"3019"},
    {"city_id":"36563","city_name":"Afipskij","state_id":"3019"},
    {"city_id":"36564","city_name":"Ahtyrskij","state_id":"3019"},
    {"city_id":"36565","city_name":"Anapa","state_id":"3019"},
    {"city_id":"36566","city_name":"Apsheronsk","state_id":"3019"},
    {"city_id":"36567","city_name":"Armavir","state_id":"3019"},
    {"city_id":"36568","city_name":"Belorechensk","state_id":"3019"},
    {"city_id":"36569","city_name":"Gelendzhik","state_id":"3019"},
    {"city_id":"36570","city_name":"Gorjachi Kljuch","state_id":"3019"},
    {"city_id":"36571","city_name":"Gulkevichi","state_id":"3019"},
    {"city_id":"36572","city_name":"Hadyzhensk","state_id":"3019"},
    {"city_id":"36573","city_name":"Ilskij","state_id":"3019"},
    {"city_id":"36574","city_name":"Jejsk","state_id":"3019"},
    {"city_id":"36575","city_name":"Kalinino","state_id":"3019"},
    {"city_id":"36576","city_name":"Korenovsk","state_id":"3019"},
    {"city_id":"36577","city_name":"Krasnodar","state_id":"3019"},
    {"city_id":"36578","city_name":"Kropotkin","state_id":"3019"},
    {"city_id":"36579","city_name":"Krymsk","state_id":"3019"},
    {"city_id":"36580","city_name":"Kurganinsk","state_id":"3019"},
    {"city_id":"36581","city_name":"Labinsk","state_id":"3019"},
    {"city_id":"36582","city_name":"Mostovskoj","state_id":"3019"},
    {"city_id":"36583","city_name":"Neftegorsk","state_id":"3019"},
    {"city_id":"36584","city_name":"Novokubansk","state_id":"3019"},
    {"city_id":"36585","city_name":"Novomihajlovskij","state_id":"3019"},
    {"city_id":"36586","city_name":"Novorossijsk","state_id":"3019"},
    {"city_id":"36587","city_name":"Pashkovskij","state_id":"3019"},
    {"city_id":"36588","city_name":"Primorsko-Ahtarsk","state_id":"3019"},
    {"city_id":"36589","city_name":"Psebaj","state_id":"3019"},
    {"city_id":"36590","city_name":"Slavjansk-na-Kubani","state_id":"3019"},
    {"city_id":"36591","city_name":"Sochi","state_id":"3019"},
    {"city_id":"36592","city_name":"Srednjaja Ahtuba","state_id":"3019"},
    {"city_id":"36593","city_name":"Temrjuk","state_id":"3019"},
    {"city_id":"36594","city_name":"Tihoreck","state_id":"3019"},
    {"city_id":"36595","city_name":"Timashevsk","state_id":"3019"},
    {"city_id":"36596","city_name":"Tuapse","state_id":"3019"},
    {"city_id":"36597","city_name":"Ust-Labinsk","state_id":"3019"},
    {"city_id":"36598","city_name":"Aban","state_id":"3020"},
    {"city_id":"36599","city_name":"Achinsk","state_id":"3020"},
    {"city_id":"36600","city_name":"Artjomovsk","state_id":"3020"},
    {"city_id":"36601","city_name":"Berjozovka","state_id":"3020"},
    {"city_id":"36602","city_name":"Bogotol","state_id":"3020"},
    {"city_id":"36603","city_name":"Borodino","state_id":"3020"},
    {"city_id":"36604","city_name":"Divnogorsk","state_id":"3020"},
    {"city_id":"36605","city_name":"Dubinino","state_id":"3020"},
    {"city_id":"36606","city_name":"Igarka","state_id":"3020"},
    {"city_id":"36607","city_name":"Ilanskij","state_id":"3020"},
    {"city_id":"36608","city_name":"Jemeljanovo","state_id":"3020"},
    {"city_id":"36609","city_name":"Jenisejsk","state_id":"3020"},
    {"city_id":"36610","city_name":"Kajerkan","state_id":"3020"},
    {"city_id":"36611","city_name":"Kansk","state_id":"3020"},
    {"city_id":"36612","city_name":"Kedrovyj","state_id":"3020"},
    {"city_id":"36613","city_name":"Kodinsk","state_id":"3020"},
    {"city_id":"36614","city_name":"Krasnojarsk","state_id":"3020"},
    {"city_id":"36615","city_name":"Kuragino","state_id":"3020"},
    {"city_id":"36616","city_name":"Lesosibirsk","state_id":"3020"},
    {"city_id":"36617","city_name":"Minusinsk","state_id":"3020"},
    {"city_id":"36618","city_name":"Nazarovo","state_id":"3020"},
    {"city_id":"36619","city_name":"Nizhnjaja Pojma","state_id":"3020"},
    {"city_id":"36620","city_name":"Norilsk","state_id":"3020"},
    {"city_id":"36621","city_name":"Podgornyj","state_id":"3020"},
    {"city_id":"36622","city_name":"Sharypovo","state_id":"3020"},
    {"city_id":"36623","city_name":"Shushenskoe","state_id":"3020"},
    {"city_id":"36624","city_name":"Solnechnyj","state_id":"3020"},
    {"city_id":"36625","city_name":"Sosnovoborsk","state_id":"3020"},
    {"city_id":"36626","city_name":"Talnah","state_id":"3020"},
    {"city_id":"36627","city_name":"Ujar","state_id":"3020"},
    {"city_id":"36628","city_name":"Uzhur","state_id":"3020"},
    {"city_id":"36629","city_name":"Zaozjornyj","state_id":"3020"},
    {"city_id":"36630","city_name":"Zelenogorsk","state_id":"3020"},
    {"city_id":"36631","city_name":"Zheleznogorsk","state_id":"3020"},
    {"city_id":"36632","city_name":"Dalmatovo","state_id":"3022"},
    {"city_id":"36633","city_name":"Kargapolje","state_id":"3022"},
    {"city_id":"36634","city_name":"Katajsk","state_id":"3022"},
    {"city_id":"36635","city_name":"Kurgan","state_id":"3022"},
    {"city_id":"36636","city_name":"Kurtamysh","state_id":"3022"},
    {"city_id":"36637","city_name":"Makushino","state_id":"3022"},
    {"city_id":"36638","city_name":"Petuhovo","state_id":"3022"},
    {"city_id":"36639","city_name":"Shadrinsk","state_id":"3022"},
    {"city_id":"36640","city_name":"Shumiha","state_id":"3022"},
    {"city_id":"36641","city_name":"Vargashi","state_id":"3022"},
    {"city_id":"36642","city_name":"Dmitriev Lgovskij","state_id":"3023"},
    {"city_id":"36643","city_name":"Imeni Karla Libknehta","state_id":"3023"},
    {"city_id":"36644","city_name":"Kurchatov","state_id":"3023"},
    {"city_id":"36645","city_name":"Kursk","state_id":"3023"},
    {"city_id":"36646","city_name":"Lgov","state_id":"3023"},
    {"city_id":"36647","city_name":"Obojan","state_id":"3023"},
    {"city_id":"36648","city_name":"Rylsk","state_id":"3023"},
    {"city_id":"36649","city_name":"Shchigry","state_id":"3023"},
    {"city_id":"36650","city_name":"Shchuchje","state_id":"3023"},
    {"city_id":"36651","city_name":"Sudzha","state_id":"3023"},
    {"city_id":"36652","city_name":"Zheleznogorsk","state_id":"3023"},
    {"city_id":"36653","city_name":"Boksitogorsk","state_id":"3024"},
    {"city_id":"36654","city_name":"Dubrovka","state_id":"3024"},
    {"city_id":"36655","city_name":"Gatchina","state_id":"3024"},
    {"city_id":"36656","city_name":"Imeni Morozova","state_id":"3024"},
    {"city_id":"36657","city_name":"Ivangorod","state_id":"3024"},
    {"city_id":"36658","city_name":"Kingisepp","state_id":"3024"},
    {"city_id":"36659","city_name":"Kirishi","state_id":"3024"},
    {"city_id":"36660","city_name":"Kirovsk","state_id":"3024"},
    {"city_id":"36661","city_name":"Kolpino","state_id":"3024"},
    {"city_id":"36662","city_name":"Kommunar","state_id":"3024"},
    {"city_id":"36663","city_name":"Kuzmolovskiy","state_id":"3024"},
    {"city_id":"36664","city_name":"Lodejnoje Pole","state_id":"3024"},
    {"city_id":"36665","city_name":"Luga","state_id":"3024"},
    {"city_id":"36666","city_name":"Nikolskoe","state_id":"3024"},
    {"city_id":"36667","city_name":"Novaja Ladoga","state_id":"3024"},
    {"city_id":"36668","city_name":"Otradnoe","state_id":"3024"},
    {"city_id":"36669","city_name":"Pikaljovo","state_id":"3024"},
    {"city_id":"36670","city_name":"Podporozhje","state_id":"3024"},
    {"city_id":"36671","city_name":"Priozjorsk","state_id":"3024"},
    {"city_id":"36672","city_name":"Sertolovo","state_id":"3024"},
    {"city_id":"36673","city_name":"Shlisselburg","state_id":"3024"},
    {"city_id":"36674","city_name":"Siverskij","state_id":"3024"},
    {"city_id":"36675","city_name":"Sjasstroj","state_id":"3024"},
    {"city_id":"36676","city_name":"Slancy","state_id":"3024"},
    {"city_id":"36677","city_name":"Sosnovyj Bor","state_id":"3024"},
    {"city_id":"36678","city_name":"Svetogorsk","state_id":"3024"},
    {"city_id":"36679","city_name":"Tihvin","state_id":"3024"},
    {"city_id":"36680","city_name":"Tosno","state_id":"3024"},
    {"city_id":"36681","city_name":"Uljanovka","state_id":"3024"},
    {"city_id":"36682","city_name":"Volhov","state_id":"3024"},
    {"city_id":"36683","city_name":"Volosovo","state_id":"3024"},
    {"city_id":"36684","city_name":"Vsevolozhsk","state_id":"3024"},
    {"city_id":"36685","city_name":"Vyborg","state_id":"3024"},
    {"city_id":"36686","city_name":"Vyrica","state_id":"3024"},
    {"city_id":"36687","city_name":"Zarskoje Selo","state_id":"3024"},
    {"city_id":"36688","city_name":"Chaplygin","state_id":"3025"},
    {"city_id":"36689","city_name":"Dankov","state_id":"3025"},
    {"city_id":"36690","city_name":"Dobrinka","state_id":"3025"},
    {"city_id":"36691","city_name":"Grjazi","state_id":"3025"},
    {"city_id":"36692","city_name":"Jelec","state_id":"3025"},
    {"city_id":"36693","city_name":"Lebedjan","state_id":"3025"},
    {"city_id":"36694","city_name":"Lipeck","state_id":"3025"},
    {"city_id":"36695","city_name":"Usman","state_id":"3025"},
    {"city_id":"36696","city_name":"Zadonsk","state_id":"3025"},
    {"city_id":"36697","city_name":"Jagodnoje","state_id":"3026"},
    {"city_id":"36698","city_name":"Magadan","state_id":"3026"},
    {"city_id":"36699","city_name":"Ola","state_id":"3026"},
    {"city_id":"36700","city_name":"Omsukchan","state_id":"3026"},
    {"city_id":"36701","city_name":"Palatka","state_id":"3026"},
    {"city_id":"36702","city_name":"Sinegorje","state_id":"3026"},
    {"city_id":"36703","city_name":"Susuman","state_id":"3026"},
    {"city_id":"36704","city_name":"Ust-Omchug","state_id":"3026"},
    {"city_id":"36705","city_name":"Ardatov","state_id":"3028"},
    {"city_id":"36706","city_name":"Chamzinka","state_id":"3028"},
    {"city_id":"36707","city_name":"Insar","state_id":"3028"},
    {"city_id":"36708","city_name":"Komsomolskij","state_id":"3028"},
    {"city_id":"36709","city_name":"Kovylkino","state_id":"3028"},
    {"city_id":"36710","city_name":"Krasnoslobodsk","state_id":"3028"},
    {"city_id":"36711","city_name":"Luhovka","state_id":"3028"},
    {"city_id":"36712","city_name":"Romodanovo","state_id":"3028"},
    {"city_id":"36713","city_name":"Ruzajevka","state_id":"3028"},
    {"city_id":"36714","city_name":"Saransk","state_id":"3028"},
    {"city_id":"36715","city_name":"Temnikov","state_id":"3028"},
    {"city_id":"36716","city_name":"Torbeevo","state_id":"3028"},
    {"city_id":"36717","city_name":"Zubova Poljana","state_id":"3028"},
    {"city_id":"36718","city_name":"Moscow","state_id":"3029"},
    {"city_id":"36719","city_name":"Moskva","state_id":"3032"},
    {"city_id":"36720","city_name":"Vostochnyj","state_id":"3032"},
    {"city_id":"36721","city_name":"Zeljenograd","state_id":"3032"},
    {"city_id":"36722","city_name":"Apatity","state_id":"3033"},
    {"city_id":"36723","city_name":"Gadzhievo","state_id":"3033"},
    {"city_id":"36724","city_name":"Kandalaksha","state_id":"3033"},
    {"city_id":"36725","city_name":"Kirovsk","state_id":"3033"},
    {"city_id":"36726","city_name":"Kola","state_id":"3033"},
    {"city_id":"36727","city_name":"Kovdor","state_id":"3033"},
    {"city_id":"36728","city_name":"Monchegorsk","state_id":"3033"},
    {"city_id":"36729","city_name":"Murmansk","state_id":"3033"},
    {"city_id":"36730","city_name":"Murmashi","state_id":"3033"},
    {"city_id":"36731","city_name":"Nikel","state_id":"3033"},
    {"city_id":"36732","city_name":"Olenegorsk","state_id":"3033"},
    {"city_id":"36733","city_name":"Ostrovnoj","state_id":"3033"},
    {"city_id":"36734","city_name":"Poljarnye Zory","state_id":"3033"},
    {"city_id":"36735","city_name":"Poljarnyj","state_id":"3033"},
    {"city_id":"36736","city_name":"Revda","state_id":"3033"},
    {"city_id":"36737","city_name":"Severomorsk","state_id":"3033"},
    {"city_id":"36738","city_name":"Snezhnogorsk","state_id":"3033"},
    {"city_id":"36739","city_name":"Zaozjorsk","state_id":"3033"},
    {"city_id":"36740","city_name":"Zapoljarnyj","state_id":"3033"},
    {"city_id":"36741","city_name":"Zeljenoborskij","state_id":"3033"},
    {"city_id":"36742","city_name":"Narjan-Mar","state_id":"3034"},
    {"city_id":"36743","city_name":"Borovichi","state_id":"3036"},
    {"city_id":"36744","city_name":"Chudovo","state_id":"3036"},
    {"city_id":"36745","city_name":"Krestcy","state_id":"3036"},
    {"city_id":"36746","city_name":"Malaja Vishera","state_id":"3036"},
    {"city_id":"36747","city_name":"Okulovka","state_id":"3036"},
    {"city_id":"36748","city_name":"Pestovo","state_id":"3036"},
    {"city_id":"36749","city_name":"Proletarij","state_id":"3036"},
    {"city_id":"36750","city_name":"Solcy","state_id":"3036"},
    {"city_id":"36751","city_name":"Staraja Russa","state_id":"3036"},
    {"city_id":"36752","city_name":"Uglovka","state_id":"3036"},
    {"city_id":"36753","city_name":"Valdaj","state_id":"3036"},
    {"city_id":"36754","city_name":"Velikij Novgorod","state_id":"3036"},
    {"city_id":"36755","city_name":"Novokusnezk","state_id":"3037"},
    {"city_id":"36756","city_name":"Barabinsk","state_id":"3038"},
    {"city_id":"36757","city_name":"Berdsk","state_id":"3038"},
    {"city_id":"36758","city_name":"Bolotnoe","state_id":"3038"},
    {"city_id":"36759","city_name":"Chany","state_id":"3038"},
    {"city_id":"36760","city_name":"Cherepanovo","state_id":"3038"},
    {"city_id":"36761","city_name":"Chulym","state_id":"3038"},
    {"city_id":"36762","city_name":"Iskitim","state_id":"3038"},
    {"city_id":"36763","city_name":"Karasuk","state_id":"3038"},
    {"city_id":"36764","city_name":"Kargat","state_id":"3038"},
    {"city_id":"36765","city_name":"Kochenjovo","state_id":"3038"},
    {"city_id":"36766","city_name":"Kolcovo","state_id":"3038"},
    {"city_id":"36767","city_name":"Kolyvan","state_id":"3038"},
    {"city_id":"36768","city_name":"Krasnoobsk","state_id":"3038"},
    {"city_id":"36769","city_name":"Krasnozerskoe","state_id":"3038"},
    {"city_id":"36770","city_name":"Kujbyshev","state_id":"3038"},
    {"city_id":"36771","city_name":"Kupino","state_id":"3038"},
    {"city_id":"36772","city_name":"Linjovo","state_id":"3038"},
    {"city_id":"36773","city_name":"Masljanino","state_id":"3038"},
    {"city_id":"36774","city_name":"Novosibirsk","state_id":"3038"},
    {"city_id":"36775","city_name":"Ob","state_id":"3038"},
    {"city_id":"36776","city_name":"Suzun","state_id":"3038"},
    {"city_id":"36777","city_name":"Tatarsk","state_id":"3038"},
    {"city_id":"36778","city_name":"Toguchin","state_id":"3038"},
    {"city_id":"36779","city_name":"Bolsherechje","state_id":"3039"},
    {"city_id":"36780","city_name":"Cherlak","state_id":"3039"},
    {"city_id":"36781","city_name":"Isilkul","state_id":"3039"},
    {"city_id":"36782","city_name":"Kalachinsk","state_id":"3039"},
    {"city_id":"36783","city_name":"Kormilovka","state_id":"3039"},
    {"city_id":"36784","city_name":"Ljubinskij","state_id":"3039"},
    {"city_id":"36785","city_name":"Moskaljoni","state_id":"3039"},
    {"city_id":"36786","city_name":"Muromcevo","state_id":"3039"},
    {"city_id":"36787","city_name":"Nazyvajevsk","state_id":"3039"},
    {"city_id":"36788","city_name":"Omsk","state_id":"3039"},
    {"city_id":"36789","city_name":"Tara","state_id":"3039"},
    {"city_id":"36790","city_name":"Tavricheskoje","state_id":"3039"},
    {"city_id":"36791","city_name":"Tjukalinsk","state_id":"3039"},
    {"city_id":"36792","city_name":"Abdulino","state_id":"3040"},
    {"city_id":"36793","city_name":"Buguruslan","state_id":"3040"},
    {"city_id":"36794","city_name":"Buzuluk","state_id":"3040"},
    {"city_id":"36795","city_name":"Jasnyj","state_id":"3040"},
    {"city_id":"36796","city_name":"Komarovskiy","state_id":"3040"},
    {"city_id":"36797","city_name":"Kuvandyk","state_id":"3040"},
    {"city_id":"36798","city_name":"Mednogorsk","state_id":"3040"},
    {"city_id":"36799","city_name":"Novotroick","state_id":"3040"},
    {"city_id":"36800","city_name":"Orenburg","state_id":"3040"},
    {"city_id":"36801","city_name":"Orsk","state_id":"3040"},
    {"city_id":"36802","city_name":"Sol-Ileck","state_id":"3040"},
    {"city_id":"36803","city_name":"Sorochinsk","state_id":"3040"},
    {"city_id":"36804","city_name":"Bolhov","state_id":"3041"},
    {"city_id":"36805","city_name":"Gaj","state_id":"3041"},
    {"city_id":"36806","city_name":"Livny","state_id":"3041"},
    {"city_id":"36807","city_name":"Mcensk","state_id":"3041"},
    {"city_id":"36808","city_name":"Orjol","state_id":"3041"},
    {"city_id":"36809","city_name":"Znamenka","state_id":"3041"},
    {"city_id":"36810","city_name":"Bashmakovo","state_id":"3042"},
    {"city_id":"36811","city_name":"Bekovo","state_id":"3042"},
    {"city_id":"36812","city_name":"Belinskiy","state_id":"3042"},
    {"city_id":"36813","city_name":"Kamenka","state_id":"3042"},
    {"city_id":"36814","city_name":"Kolyshlej","state_id":"3042"},
    {"city_id":"36815","city_name":"Kuzneck","state_id":"3042"},
    {"city_id":"36816","city_name":"Mokshan","state_id":"3042"},
    {"city_id":"36817","city_name":"Nikolsk","state_id":"3042"},
    {"city_id":"36818","city_name":"Nizhnij Lomov","state_id":"3042"},
    {"city_id":"36819","city_name":"Pachelma","state_id":"3042"},
    {"city_id":"36820","city_name":"Penza","state_id":"3042"},
    {"city_id":"36821","city_name":"Serdobsk","state_id":"3042"},
    {"city_id":"36822","city_name":"Sursk","state_id":"3042"},
    {"city_id":"36823","city_name":"Zarechnyj","state_id":"3042"},
    {"city_id":"36824","city_name":"Zemetchino","state_id":"3042"},
    {"city_id":"36825","city_name":"Aleksandrovsk","state_id":"3043"},
    {"city_id":"36826","city_name":"Berezniki","state_id":"3043"},
    {"city_id":"36827","city_name":"Chajkovskij","state_id":"3043"},
    {"city_id":"36828","city_name":"Chermoz","state_id":"3043"},
    {"city_id":"36829","city_name":"Chernushka","state_id":"3043"},
    {"city_id":"36830","city_name":"Chusovoj","state_id":"3043"},
    {"city_id":"36831","city_name":"Dobrjanka","state_id":"3043"},
    {"city_id":"36832","city_name":"Gornozavodsk","state_id":"3043"},
    {"city_id":"36833","city_name":"Gremjachinsk","state_id":"3043"},
    {"city_id":"36834","city_name":"Gubaha","state_id":"3043"},
    {"city_id":"36835","city_name":"Jajva","state_id":"3043"},
    {"city_id":"36836","city_name":"Kizel","state_id":"3043"},
    {"city_id":"36837","city_name":"Komsomolsky","state_id":"3043"},
    {"city_id":"36838","city_name":"Krasnokamsk","state_id":"3043"},
    {"city_id":"36839","city_name":"Krasnovishersk","state_id":"3043"},
    {"city_id":"36840","city_name":"Kungur","state_id":"3043"},
    {"city_id":"36841","city_name":"Lysva","state_id":"3043"},
    {"city_id":"36842","city_name":"Novye Ljady","state_id":"3043"},
    {"city_id":"36843","city_name":"Nytva","state_id":"3043"},
    {"city_id":"36844","city_name":"Ocher","state_id":"3043"},
    {"city_id":"36845","city_name":"Oktjabrskij","state_id":"3043"},
    {"city_id":"36846","city_name":"Osa","state_id":"3043"},
    {"city_id":"36847","city_name":"Pashija","state_id":"3043"},
    {"city_id":"36848","city_name":"Perm","state_id":"3043"},
    {"city_id":"36849","city_name":"Polazna","state_id":"3043"},
    {"city_id":"36850","city_name":"Skalnyj","state_id":"3043"},
    {"city_id":"36851","city_name":"Solikamsk","state_id":"3043"},
    {"city_id":"36852","city_name":"Ugleuralskij","state_id":"3043"},
    {"city_id":"36853","city_name":"Uralskij","state_id":"3043"},
    {"city_id":"36854","city_name":"Usolje","state_id":"3043"},
    {"city_id":"36855","city_name":"Vereshchagino","state_id":"3043"},
    {"city_id":"36856","city_name":"Zvjozdnyj","state_id":"3043"},
    {"city_id":"36857","city_name":"Arsenjev","state_id":"3044"},
    {"city_id":"36858","city_name":"Artjom","state_id":"3044"},
    {"city_id":"36859","city_name":"Artjomovskij","state_id":"3044"},
    {"city_id":"36860","city_name":"Bolshoj Kamen","state_id":"3044"},
    {"city_id":"36861","city_name":"Dalnegorsk","state_id":"3044"},
    {"city_id":"36862","city_name":"Dalnerechensk","state_id":"3044"},
    {"city_id":"36863","city_name":"Dunaj","state_id":"3044"},
    {"city_id":"36864","city_name":"Fokino","state_id":"3044"},
    {"city_id":"36865","city_name":"Jaroslavskij","state_id":"3044"},
    {"city_id":"36866","city_name":"Kavalerovo","state_id":"3044"},
    {"city_id":"36867","city_name":"Kirovskiy","state_id":"3044"},
    {"city_id":"36868","city_name":"Lesozavodsk","state_id":"3044"},
    {"city_id":"36869","city_name":"Lipovcy","state_id":"3044"},
    {"city_id":"36870","city_name":"Livadija","state_id":"3044"},
    {"city_id":"36871","city_name":"Luchegorsk","state_id":"3044"},
    {"city_id":"36872","city_name":"Nahodka","state_id":"3044"},
    {"city_id":"36873","city_name":"Novoshahtinskij","state_id":"3044"},
    {"city_id":"36874","city_name":"Partizansk","state_id":"3044"},
    {"city_id":"36875","city_name":"Pogranichnyj","state_id":"3044"},
    {"city_id":"36876","city_name":"Preobrazhenie","state_id":"3044"},
    {"city_id":"36877","city_name":"Putjatin","state_id":"3044"},
    {"city_id":"36878","city_name":"Sibircevo","state_id":"3044"},
    {"city_id":"36879","city_name":"Slavjanka","state_id":"3044"},
    {"city_id":"36880","city_name":"Spassk-Dalnij","state_id":"3044"},
    {"city_id":"36881","city_name":"Tavrichanka","state_id":"3044"},
    {"city_id":"36882","city_name":"Trudovoe","state_id":"3044"},
    {"city_id":"36883","city_name":"Uglekamensk","state_id":"3044"},
    {"city_id":"36884","city_name":"Uglovoe","state_id":"3044"},
    {"city_id":"36885","city_name":"Ussurijsk","state_id":"3044"},
    {"city_id":"36886","city_name":"Vladivostok","state_id":"3044"},
    {"city_id":"36887","city_name":"Vrangel","state_id":"3044"},
    {"city_id":"36888","city_name":"Zavodskoj","state_id":"3044"},
    {"city_id":"36889","city_name":"Dedovichi","state_id":"3045"},
    {"city_id":"36890","city_name":"Dno","state_id":"3045"},
    {"city_id":"36891","city_name":"Nevel","state_id":"3045"},
    {"city_id":"36892","city_name":"Novosokolniki","state_id":"3045"},
    {"city_id":"36893","city_name":"Opochka","state_id":"3045"},
    {"city_id":"36894","city_name":"Ostrov","state_id":"3045"},
    {"city_id":"36895","city_name":"Pechory","state_id":"3045"},
    {"city_id":"36896","city_name":"Porhov","state_id":"3045"},
    {"city_id":"36897","city_name":"Pskov","state_id":"3045"},
    {"city_id":"36898","city_name":"Sebezh","state_id":"3045"},
    {"city_id":"36899","city_name":"Strugi-Krasnye","state_id":"3045"},
    {"city_id":"36900","city_name":"Velikije Luki","state_id":"3045"},
    {"city_id":"36901","city_name":"Kasimov","state_id":"3047"},
    {"city_id":"36902","city_name":"Korablino","state_id":"3047"},
    {"city_id":"36903","city_name":"Mihajlov","state_id":"3047"},
    {"city_id":"36904","city_name":"Novomichurinsk","state_id":"3047"},
    {"city_id":"36905","city_name":"Rjazan","state_id":"3047"},
    {"city_id":"36906","city_name":"Rjazhsk","state_id":"3047"},
    {"city_id":"36907","city_name":"Rybnoje","state_id":"3047"},
    {"city_id":"36908","city_name":"Sasovo","state_id":"3047"},
    {"city_id":"36909","city_name":"Shilovo","state_id":"3047"},
    {"city_id":"36910","city_name":"Skopin","state_id":"3047"},
    {"city_id":"36911","city_name":"Ajutinskij","state_id":"3048"},
    {"city_id":"36912","city_name":"Aksaj","state_id":"3048"},
    {"city_id":"36913","city_name":"Azov","state_id":"3048"},
    {"city_id":"36914","city_name":"Batajsk","state_id":"3048"},
    {"city_id":"36915","city_name":"Belaja Kalitva","state_id":"3048"},
    {"city_id":"36916","city_name":"Cimljansk","state_id":"3048"},
    {"city_id":"36917","city_name":"Doneck","state_id":"3048"},
    {"city_id":"36918","city_name":"Donskoj","state_id":"3048"},
    {"city_id":"36919","city_name":"Gigant","state_id":"3048"},
    {"city_id":"36920","city_name":"Glubokij","state_id":"3048"},
    {"city_id":"36921","city_name":"Gornjackij","state_id":"3048"},
    {"city_id":"36922","city_name":"Gukovo","state_id":"3048"},
    {"city_id":"36923","city_name":"Kamenolomni","state_id":"3048"},
    {"city_id":"36924","city_name":"Kamensk-Shahtinskij","state_id":"3048"},
    {"city_id":"36925","city_name":"Konstantinovsk","state_id":"3048"},
    {"city_id":"36926","city_name":"Krasnyj Sulin","state_id":"3048"},
    {"city_id":"36927","city_name":"Lihovskoj","state_id":"3048"},
    {"city_id":"36928","city_name":"Majskij","state_id":"3048"},
    {"city_id":"36929","city_name":"Millerovo","state_id":"3048"},
    {"city_id":"36930","city_name":"Morozovsk","state_id":"3048"},
    {"city_id":"36931","city_name":"Novocherkassk","state_id":"3048"},
    {"city_id":"36932","city_name":"Novoshahtinsk","state_id":"3048"},
    {"city_id":"36933","city_name":"Proletarsk","state_id":"3048"},
    {"city_id":"36934","city_name":"Rostov","state_id":"3048"},
    {"city_id":"36935","city_name":"Rostov-na-Donu","state_id":"3048"},
    {"city_id":"36936","city_name":"Salsk","state_id":"3048"},
    {"city_id":"36937","city_name":"Semikarakorsk","state_id":"3048"},
    {"city_id":"36938","city_name":"Shahty","state_id":"3048"},
    {"city_id":"36939","city_name":"Sholohovskij","state_id":"3048"},
    {"city_id":"36940","city_name":"Sinegorskij","state_id":"3048"},
    {"city_id":"36941","city_name":"Sokolovo","state_id":"3048"},
    {"city_id":"36942","city_name":"Taganrog","state_id":"3048"},
    {"city_id":"36943","city_name":"Ust-Doneckij","state_id":"3048"},
    {"city_id":"36944","city_name":"Volgodonsk","state_id":"3048"},
    {"city_id":"36945","city_name":"Zernograd","state_id":"3048"},
    {"city_id":"36946","city_name":"Zhirnov","state_id":"3048"},
    {"city_id":"36947","city_name":"Zverevo","state_id":"3048"},
    {"city_id":"36948","city_name":"Ajhan","state_id":"3049"},
    {"city_id":"36949","city_name":"Aldan","state_id":"3049"},
    {"city_id":"36950","city_name":"Cherskij","state_id":"3049"},
    {"city_id":"36951","city_name":"Chulman","state_id":"3049"},
    {"city_id":"36952","city_name":"Deputatskij","state_id":"3049"},
    {"city_id":"36953","city_name":"Jakutsk","state_id":"3049"},
    {"city_id":"36954","city_name":"Lensk","state_id":"3049"},
    {"city_id":"36955","city_name":"Marha","state_id":"3049"},
    {"city_id":"36956","city_name":"Mirnyj","state_id":"3049"},
    {"city_id":"36957","city_name":"Nerjungri","state_id":"3049"},
    {"city_id":"36958","city_name":"Njurba","state_id":"3049"},
    {"city_id":"36959","city_name":"Oljokminsk","state_id":"3049"},
    {"city_id":"36960","city_name":"Pokrovsk","state_id":"3049"},
    {"city_id":"36961","city_name":"Sangar","state_id":"3049"},
    {"city_id":"36962","city_name":"Serebrjanyj Bor","state_id":"3049"},
    {"city_id":"36963","city_name":"Tiksi","state_id":"3049"},
    {"city_id":"36964","city_name":"Tommot","state_id":"3049"},
    {"city_id":"36965","city_name":"Udachnyj","state_id":"3049"},
    {"city_id":"36966","city_name":"Ust-Nera","state_id":"3049"},
    {"city_id":"36967","city_name":"Verhojansk","state_id":"3049"},
    {"city_id":"36968","city_name":"Viljujsk","state_id":"3049"},
    {"city_id":"36969","city_name":"Aleksandrovsk-Sahalinskij","state_id":"3050"},
    {"city_id":"36970","city_name":"Dolinsk","state_id":"3050"},
    {"city_id":"36971","city_name":"Gornozavodsk","state_id":"3050"},
    {"city_id":"36972","city_name":"Holmsk","state_id":"3050"},
    {"city_id":"36973","city_name":"Juzhno-Sahalinsk","state_id":"3050"},
    {"city_id":"36974","city_name":"Korsakov","state_id":"3050"},
    {"city_id":"36975","city_name":"Makarov","state_id":"3050"},
    {"city_id":"36976","city_name":"Nevelsk","state_id":"3050"},
    {"city_id":"36977","city_name":"Nogliki","state_id":"3050"},
    {"city_id":"36978","city_name":"Oha","state_id":"3050"},
    {"city_id":"36979","city_name":"Poronajsk","state_id":"3050"},
    {"city_id":"36980","city_name":"Shahtjorsk","state_id":"3050"},
    {"city_id":"36981","city_name":"Tymovskoje","state_id":"3050"},
    {"city_id":"36982","city_name":"Uglegorsk","state_id":"3050"},
    {"city_id":"36983","city_name":"Aleksejevka","state_id":"3051"},
    {"city_id":"36984","city_name":"Bezenchuk","state_id":"3051"},
    {"city_id":"36985","city_name":"Chapajevsk","state_id":"3051"},
    {"city_id":"36986","city_name":"Fjodorovka","state_id":"3051"},
    {"city_id":"36987","city_name":"Kinel","state_id":"3051"},
    {"city_id":"36988","city_name":"Mirnyj","state_id":"3051"},
    {"city_id":"36989","city_name":"Novokujbyshevsk","state_id":"3051"},
    {"city_id":"36990","city_name":"Novosemejkino","state_id":"3051"},
    {"city_id":"36991","city_name":"Oktjabrsk","state_id":"3051"},
    {"city_id":"36992","city_name":"Otradnyj","state_id":"3051"},
    {"city_id":"36993","city_name":"Pohvistnevo","state_id":"3051"},
    {"city_id":"36994","city_name":"Povolzhskij","state_id":"3051"},
    {"city_id":"36995","city_name":"Pribrezhnyj","state_id":"3051"},
    {"city_id":"36996","city_name":"Roscinskij","state_id":"3051"},
    {"city_id":"36997","city_name":"Samara","state_id":"3051"},
    {"city_id":"36998","city_name":"Smyshljaevka","state_id":"3051"},
    {"city_id":"36999","city_name":"Suhodol","state_id":"3051"},
    {"city_id":"37000","city_name":"Syzran","state_id":"3051"},
    {"city_id":"37001","city_name":"Timashjovo","state_id":"3051"},
    {"city_id":"37002","city_name":"Toljatti","state_id":"3051"},
    {"city_id":"37003","city_name":"Zhigulevsk","state_id":"3051"},
    {"city_id":"37004","city_name":"Togliatti","state_id":"3052"},
    {"city_id":"37005","city_name":"Saint Petersburg","state_id":"3053"},
    {"city_id":"37006","city_name":"Sankt Peterburg","state_id":"3053"},
    {"city_id":"37007","city_name":"Arkadak","state_id":"3054"},
    {"city_id":"37008","city_name":"Atkarsk","state_id":"3054"},
    {"city_id":"37009","city_name":"Balakovo","state_id":"3054"},
    {"city_id":"37010","city_name":"Balashov","state_id":"3054"},
    {"city_id":"37011","city_name":"Bazarnyj Karabulak","state_id":"3054"},
    {"city_id":"37012","city_name":"Dergachi","state_id":"3054"},
    {"city_id":"37013","city_name":"Engels","state_id":"3054"},
    {"city_id":"37014","city_name":"Hvalynsk","state_id":"3054"},
    {"city_id":"37015","city_name":"Jershov","state_id":"3054"},
    {"city_id":"37016","city_name":"Kalininsk","state_id":"3054"},
    {"city_id":"37017","city_name":"Krasnoarmejsk","state_id":"3054"},
    {"city_id":"37018","city_name":"Krasnyj Kut","state_id":"3054"},
    {"city_id":"37019","city_name":"Marks","state_id":"3054"},
    {"city_id":"37020","city_name":"Novouzensk","state_id":"3054"},
    {"city_id":"37021","city_name":"Ozinki","state_id":"3054"},
    {"city_id":"37022","city_name":"Petrovsk","state_id":"3054"},
    {"city_id":"37023","city_name":"Privolzhskij","state_id":"3054"},
    {"city_id":"37024","city_name":"Pugachjov","state_id":"3054"},
    {"city_id":"37025","city_name":"Rtishchevo","state_id":"3054"},
    {"city_id":"37026","city_name":"Saratov","state_id":"3054"},
    {"city_id":"37027","city_name":"Shihany","state_id":"3054"},
    {"city_id":"37028","city_name":"Stepnoe","state_id":"3054"},
    {"city_id":"37029","city_name":"Svetlyj","state_id":"3054"},
    {"city_id":"37030","city_name":"Tatishchevo","state_id":"3054"},
    {"city_id":"37031","city_name":"Volsk","state_id":"3054"},
    {"city_id":"37032","city_name":"Demidov","state_id":"3055"},
    {"city_id":"37033","city_name":"Desnogorsk","state_id":"3055"},
    {"city_id":"37034","city_name":"Dorogobuzh","state_id":"3055"},
    {"city_id":"37035","city_name":"Gagarin","state_id":"3055"},
    {"city_id":"37036","city_name":"Hislavichi","state_id":"3055"},
    {"city_id":"37037","city_name":"Jarcevo","state_id":"3055"},
    {"city_id":"37038","city_name":"Jelnja","state_id":"3055"},
    {"city_id":"37039","city_name":"Pochinok","state_id":"3055"},
    {"city_id":"37040","city_name":"Roslavl","state_id":"3055"},
    {"city_id":"37041","city_name":"Rudnja","state_id":"3055"},
    {"city_id":"37042","city_name":"Safonovo","state_id":"3055"},
    {"city_id":"37043","city_name":"Smolensk","state_id":"3055"},
    {"city_id":"37044","city_name":"Verhnedneprovskij","state_id":"3055"},
    {"city_id":"37045","city_name":"Vjazma","state_id":"3055"},
    {"city_id":"37046","city_name":"Blagodarnyj","state_id":"3056"},
    {"city_id":"37047","city_name":"Budjonnovsk","state_id":"3056"},
    {"city_id":"37048","city_name":"Georgijevsk","state_id":"3056"},
    {"city_id":"37049","city_name":"Gorjachevodskij","state_id":"3056"},
    {"city_id":"37050","city_name":"Inozemcevo","state_id":"3056"},
    {"city_id":"37051","city_name":"Ipatovo","state_id":"3056"},
    {"city_id":"37052","city_name":"Izobilnyj","state_id":"3056"},
    {"city_id":"37053","city_name":"Jessentuki","state_id":"3056"},
    {"city_id":"37054","city_name":"Kislovodsk","state_id":"3056"},
    {"city_id":"37055","city_name":"Lermontov","state_id":"3056"},
    {"city_id":"37056","city_name":"Mihajlovsk","state_id":"3056"},
    {"city_id":"37057","city_name":"Mineralnyje Vody","state_id":"3056"},
    {"city_id":"37058","city_name":"Neftekumsk","state_id":"3056"},
    {"city_id":"37059","city_name":"Nevinnomyssk","state_id":"3056"},
    {"city_id":"37060","city_name":"Novoaleksandrovsk","state_id":"3056"},
    {"city_id":"37061","city_name":"Novopavlovsk","state_id":"3056"},
    {"city_id":"37062","city_name":"Pjatigorsk","state_id":"3056"},
    {"city_id":"37063","city_name":"Solnechnodolsk","state_id":"3056"},
    {"city_id":"37064","city_name":"Stavropol","state_id":"3056"},
    {"city_id":"37065","city_name":"Svetlograd","state_id":"3056"},
    {"city_id":"37066","city_name":"Svobody","state_id":"3056"},
    {"city_id":"37067","city_name":"Zeljenokumsk","state_id":"3056"},
    {"city_id":"37068","city_name":"Zheleznovodsk","state_id":"3056"},
    {"city_id":"37069","city_name":"Alapajevsk","state_id":"3057"},
    {"city_id":"37070","city_name":"Aramil","state_id":"3057"},
    {"city_id":"37071","city_name":"Arti","state_id":"3057"},
    {"city_id":"37072","city_name":"Artjomovskij","state_id":"3057"},
    {"city_id":"37073","city_name":"Asbest","state_id":"3057"},
    {"city_id":"37074","city_name":"Baranchinskij","state_id":"3057"},
    {"city_id":"37075","city_name":"Belojarskij","state_id":"3057"},
    {"city_id":"37076","city_name":"Berjozovskij","state_id":"3057"},
    {"city_id":"37077","city_name":"Bisert","state_id":"3057"},
    {"city_id":"37078","city_name":"Bogdanovich","state_id":"3057"},
    {"city_id":"37079","city_name":"Bulanash","state_id":"3057"},
    {"city_id":"37080","city_name":"Degtjarsk","state_id":"3057"},
    {"city_id":"37081","city_name":"Ekaterinburg","state_id":"3057"},
    {"city_id":"37082","city_name":"Irbit","state_id":"3057"},
    {"city_id":"37083","city_name":"Ivdel","state_id":"3057"},
    {"city_id":"37084","city_name":"Izumrud","state_id":"3057"},
    {"city_id":"37085","city_name":"Jekaterinburg","state_id":"3057"},
    {"city_id":"37086","city_name":"Kachkanar","state_id":"3057"},
    {"city_id":"37087","city_name":"Kamensk-Uralskij","state_id":"3057"},
    {"city_id":"37088","city_name":"Kamyshlov","state_id":"3057"},
    {"city_id":"37089","city_name":"Karpinsk","state_id":"3057"},
    {"city_id":"37090","city_name":"Kirovgrad","state_id":"3057"},
    {"city_id":"37091","city_name":"Kolcovo","state_id":"3057"},
    {"city_id":"37092","city_name":"Krasnoturinsk","state_id":"3057"},
    {"city_id":"37093","city_name":"Krasnoufimsk","state_id":"3057"},
    {"city_id":"37094","city_name":"Krasnouralsk","state_id":"3057"},
    {"city_id":"37095","city_name":"Kushva","state_id":"3057"},
    {"city_id":"37096","city_name":"Lesnoj","state_id":"3057"},
    {"city_id":"37097","city_name":"Leviha","state_id":"3057"},
    {"city_id":"37098","city_name":"Lobva","state_id":"3057"},
    {"city_id":"37099","city_name":"Malysheva","state_id":"3057"},
    {"city_id":"37100","city_name":"Mihajlovsk","state_id":"3057"},
    {"city_id":"37101","city_name":"Monetnyj","state_id":"3057"},
    {"city_id":"37102","city_name":"Nevjansk","state_id":"3057"},
    {"city_id":"37103","city_name":"Nizhnie Sergi","state_id":"3057"},
    {"city_id":"37104","city_name":"Nizhnij Tagil","state_id":"3057"},
    {"city_id":"37105","city_name":"Nizhnjaja Salda","state_id":"3057"},
    {"city_id":"37106","city_name":"Nizhnjaja Tura","state_id":"3057"},
    {"city_id":"37107","city_name":"Novaja Ljalja","state_id":"3057"},
    {"city_id":"37108","city_name":"Novouralsk","state_id":"3057"},
    {"city_id":"37109","city_name":"Pervouralsk","state_id":"3057"},
    {"city_id":"37110","city_name":"Polevskoj","state_id":"3057"},
    {"city_id":"37111","city_name":"Pyshma","state_id":"3057"},
    {"city_id":"37112","city_name":"Pyt-Jah","state_id":"3057"},
    {"city_id":"37113","city_name":"Reftinskij","state_id":"3057"},
    {"city_id":"37114","city_name":"Revda","state_id":"3057"},
    {"city_id":"37115","city_name":"Rezh","state_id":"3057"},
    {"city_id":"37116","city_name":"Serov","state_id":"3057"},
    {"city_id":"37117","city_name":"Severouralsk","state_id":"3057"},
    {"city_id":"37118","city_name":"Sosva","state_id":"3057"},
    {"city_id":"37119","city_name":"Sredneuralsk","state_id":"3057"},
    {"city_id":"37120","city_name":"Suhoj Log","state_id":"3057"},
    {"city_id":"37121","city_name":"Svobodnyj","state_id":"3057"},
    {"city_id":"37122","city_name":"Sysert","state_id":"3057"},
    {"city_id":"37123","city_name":"Talica","state_id":"3057"},
    {"city_id":"37124","city_name":"Tavda","state_id":"3057"},
    {"city_id":"37125","city_name":"Troickij","state_id":"3057"},
    {"city_id":"37126","city_name":"Turinsk","state_id":"3057"},
    {"city_id":"37127","city_name":"Uralskij","state_id":"3057"},
    {"city_id":"37128","city_name":"Verhnij Tagil","state_id":"3057"},
    {"city_id":"37129","city_name":"Verhnjaja Pyshma","state_id":"3057"},
    {"city_id":"37130","city_name":"Verhnjaja Salda","state_id":"3057"},
    {"city_id":"37131","city_name":"Verhnjaja Sinyachiha","state_id":"3057"},
    {"city_id":"37132","city_name":"Verhnjaja Tura","state_id":"3057"},
    {"city_id":"37133","city_name":"Verhoturje","state_id":"3057"},
    {"city_id":"37134","city_name":"Volchansk","state_id":"3057"},
    {"city_id":"37135","city_name":"Yekaterinburg","state_id":"3057"},
    {"city_id":"37136","city_name":"Zarechnyj","state_id":"3057"},
    {"city_id":"37137","city_name":"Zjuzelskij","state_id":"3057"},
    {"city_id":"37138","city_name":"Dudinka","state_id":"3058"},
    {"city_id":"37139","city_name":"Dmitrievka","state_id":"3059"},
    {"city_id":"37140","city_name":"Inzhavino","state_id":"3059"},
    {"city_id":"37141","city_name":"Kirsanov","state_id":"3059"},
    {"city_id":"37142","city_name":"Kotovsk","state_id":"3059"},
    {"city_id":"37143","city_name":"Michurinsk","state_id":"3059"},
    {"city_id":"37144","city_name":"Morshansk","state_id":"3059"},
    {"city_id":"37145","city_name":"Pervomajskij","state_id":"3059"},
    {"city_id":"37146","city_name":"Rasskazovo","state_id":"3059"},
    {"city_id":"37147","city_name":"Sosnovka","state_id":"3059"},
    {"city_id":"37148","city_name":"Tambov","state_id":"3059"},
    {"city_id":"37149","city_name":"Uvarovo","state_id":"3059"},
    {"city_id":"37150","city_name":"Zherdevka","state_id":"3059"},
    {"city_id":"37151","city_name":"Znamenka","state_id":"3059"},
    {"city_id":"37152","city_name":"Agryz","state_id":"3060"},
    {"city_id":"37153","city_name":"Aksubajevo","state_id":"3060"},
    {"city_id":"37154","city_name":"Aktjubinskij","state_id":"3060"},
    {"city_id":"37155","city_name":"Aleksejevskoje","state_id":"3060"},
    {"city_id":"37156","city_name":"Almetjevsk","state_id":"3060"},
    {"city_id":"37157","city_name":"Arsk","state_id":"3060"},
    {"city_id":"37158","city_name":"Aznakajevo","state_id":"3060"},
    {"city_id":"37159","city_name":"Bavly","state_id":"3060"},
    {"city_id":"37160","city_name":"Bugulma","state_id":"3060"},
    {"city_id":"37161","city_name":"Buinsk","state_id":"3060"},
    {"city_id":"37162","city_name":"Chistopol","state_id":"3060"},
    {"city_id":"37163","city_name":"Dzhalil","state_id":"3060"},
    {"city_id":"37164","city_name":"Jelabuga","state_id":"3060"},
    {"city_id":"37165","city_name":"Kamskie Poljany","state_id":"3060"},
    {"city_id":"37166","city_name":"Kazan","state_id":"3060"},
    {"city_id":"37167","city_name":"Kukmor","state_id":"3060"},
    {"city_id":"37168","city_name":"Leninogorsk","state_id":"3060"},
    {"city_id":"37169","city_name":"Mamadysh","state_id":"3060"},
    {"city_id":"37170","city_name":"Mendelejevsk","state_id":"3060"},
    {"city_id":"37171","city_name":"Menzelinsk","state_id":"3060"},
    {"city_id":"37172","city_name":"Naberezhnyje Chelny","state_id":"3060"},
    {"city_id":"37173","city_name":"Nizhnekamsk","state_id":"3060"},
    {"city_id":"37174","city_name":"Niznjaja Maktama","state_id":"3060"},
    {"city_id":"37175","city_name":"Nurlat","state_id":"3060"},
    {"city_id":"37176","city_name":"Tetjushi","state_id":"3060"},
    {"city_id":"37177","city_name":"Urussu","state_id":"3060"},
    {"city_id":"37178","city_name":"Vasiljevo","state_id":"3060"},
    {"city_id":"37179","city_name":"Zainsk","state_id":"3060"},
    {"city_id":"37180","city_name":"Zeljonodolsk","state_id":"3060"},
    {"city_id":"37181","city_name":"Bogandinskij","state_id":"3061"},
    {"city_id":"37182","city_name":"Borovskij","state_id":"3061"},
    {"city_id":"37183","city_name":"Golyshmanovo","state_id":"3061"},
    {"city_id":"37184","city_name":"Ishim","state_id":"3061"},
    {"city_id":"37185","city_name":"Jalutorovosk","state_id":"3061"},
    {"city_id":"37186","city_name":"Tjumen","state_id":"3061"},
    {"city_id":"37187","city_name":"Tobolsk","state_id":"3061"},
    {"city_id":"37188","city_name":"Vinzili","state_id":"3061"},
    {"city_id":"37189","city_name":"Zavodoukovsk","state_id":"3061"},
    {"city_id":"37190","city_name":"Asino","state_id":"3062"},
    {"city_id":"37191","city_name":"Jaja","state_id":"3062"},
    {"city_id":"37192","city_name":"Kolpashevo","state_id":"3062"},
    {"city_id":"37193","city_name":"Seversk","state_id":"3062"},
    {"city_id":"37194","city_name":"Strezhevoj","state_id":"3062"},
    {"city_id":"37195","city_name":"Tomsk","state_id":"3062"},
    {"city_id":"37196","city_name":"Agejevo","state_id":"3063"},
    {"city_id":"37197","city_name":"Aleksin","state_id":"3063"},
    {"city_id":"37198","city_name":"Beljov","state_id":"3063"},
    {"city_id":"37199","city_name":"Bogoroditsk","state_id":"3063"},
    {"city_id":"37200","city_name":"Bolohovo","state_id":"3063"},
    {"city_id":"37201","city_name":"Donskoj","state_id":"3063"},
    {"city_id":"37202","city_name":"Dubovka","state_id":"3063"},
    {"city_id":"37203","city_name":"Jasnogorsk","state_id":"3063"},
    {"city_id":"37204","city_name":"Jefremov","state_id":"3063"},
    {"city_id":"37205","city_name":"Kimovsk","state_id":"3063"},
    {"city_id":"37206","city_name":"Kirejevsk","state_id":"3063"},
    {"city_id":"37207","city_name":"Kosaja Gora","state_id":"3063"},
    {"city_id":"37208","city_name":"Leninskij","state_id":"3063"},
    {"city_id":"37209","city_name":"Lipki","state_id":"3063"},
    {"city_id":"37210","city_name":"Mendelejevskij","state_id":"3063"},
    {"city_id":"37211","city_name":"Novomoskovsk","state_id":"3063"},
    {"city_id":"37212","city_name":"Pervomajskij","state_id":"3063"},
    {"city_id":"37213","city_name":"Plavsk","state_id":"3063"},
    {"city_id":"37214","city_name":"Severo-Zadonsk","state_id":"3063"},
    {"city_id":"37215","city_name":"Shchjokino","state_id":"3063"},
    {"city_id":"37216","city_name":"Skuratovskij","state_id":"3063"},
    {"city_id":"37217","city_name":"Sokolniki","state_id":"3063"},
    {"city_id":"37218","city_name":"Sovetsk","state_id":"3063"},
    {"city_id":"37219","city_name":"Suvorov","state_id":"3063"},
    {"city_id":"37220","city_name":"Tovarkovskij","state_id":"3063"},
    {"city_id":"37221","city_name":"Tula","state_id":"3063"},
    {"city_id":"37222","city_name":"Uzlovaja","state_id":"3063"},
    {"city_id":"37223","city_name":"Venjov","state_id":"3063"},
    {"city_id":"37224","city_name":"Andreapol","state_id":"3064"},
    {"city_id":"37225","city_name":"Bezheck","state_id":"3064"},
    {"city_id":"37226","city_name":"Bologoe","state_id":"3064"},
    {"city_id":"37227","city_name":"Kaljazin","state_id":"3064"},
    {"city_id":"37228","city_name":"Kashin","state_id":"3064"},
    {"city_id":"37229","city_name":"Kimry","state_id":"3064"},
    {"city_id":"37230","city_name":"Konakovo","state_id":"3064"},
    {"city_id":"37231","city_name":"Kuvshinovo","state_id":"3064"},
    {"city_id":"37232","city_name":"Lihoslavl","state_id":"3064"},
    {"city_id":"37233","city_name":"Maksatiha","state_id":"3064"},
    {"city_id":"37234","city_name":"Ostashkov","state_id":"3064"},
    {"city_id":"37235","city_name":"Ozjornyj","state_id":"3064"},
    {"city_id":"37236","city_name":"Pelidovo","state_id":"3064"},
    {"city_id":"37237","city_name":"Rameshki","state_id":"3064"},
    {"city_id":"37238","city_name":"Redkino","state_id":"3064"},
    {"city_id":"37239","city_name":"Rzhev","state_id":"3064"},
    {"city_id":"37240","city_name":"Solnechnyj","state_id":"3064"},
    {"city_id":"37241","city_name":"Starica","state_id":"3064"},
    {"city_id":"37242","city_name":"Toropec","state_id":"3064"},
    {"city_id":"37243","city_name":"Torzhok","state_id":"3064"},
    {"city_id":"37244","city_name":"Tver","state_id":"3064"},
    {"city_id":"37245","city_name":"Udomlja","state_id":"3064"},
    {"city_id":"37246","city_name":"Vyshnij Volochok","state_id":"3064"},
    {"city_id":"37247","city_name":"Zapadnaja Dvina","state_id":"3064"},
    {"city_id":"37248","city_name":"Ak-Dovurak","state_id":"3065"},
    {"city_id":"37249","city_name":"Kyzyl","state_id":"3065"},
    {"city_id":"37250","city_name":"Balezino","state_id":"3066"},
    {"city_id":"37251","city_name":"Glazov","state_id":"3066"},
    {"city_id":"37252","city_name":"Igra","state_id":"3066"},
    {"city_id":"37253","city_name":"Izhevsk","state_id":"3066"},
    {"city_id":"37254","city_name":"Kambarka","state_id":"3066"},
    {"city_id":"37255","city_name":"Kez","state_id":"3066"},
    {"city_id":"37256","city_name":"Kizner","state_id":"3066"},
    {"city_id":"37257","city_name":"Mozhga","state_id":"3066"},
    {"city_id":"37258","city_name":"Sarapul","state_id":"3066"},
    {"city_id":"37259","city_name":"Uva","state_id":"3066"},
    {"city_id":"37260","city_name":"Votkinsk","state_id":"3066"},
    {"city_id":"37261","city_name":"Barysh","state_id":"3067"},
    {"city_id":"37262","city_name":"Cherdakly","state_id":"3067"},
    {"city_id":"37263","city_name":"Dimitrovgrad","state_id":"3067"},
    {"city_id":"37264","city_name":"Inza","state_id":"3067"},
    {"city_id":"37265","city_name":"Isheevka","state_id":"3067"},
    {"city_id":"37266","city_name":"Novospasskoe","state_id":"3067"},
    {"city_id":"37267","city_name":"Novouljanovsk","state_id":"3067"},
    {"city_id":"37268","city_name":"Sengilej","state_id":"3067"},
    {"city_id":"37269","city_name":"Uljanovsk","state_id":"3067"},
    {"city_id":"37270","city_name":"Ust-Ordynskij","state_id":"3069"},
    {"city_id":"37271","city_name":"Aleksandrov","state_id":"3070"},
    {"city_id":"37272","city_name":"Balakirevo","state_id":"3070"},
    {"city_id":"37273","city_name":"Gorohovec","state_id":"3070"},
    {"city_id":"37274","city_name":"Gus-Hrustalnyj","state_id":"3070"},
    {"city_id":"37275","city_name":"Jurjev Polskij","state_id":"3070"},
    {"city_id":"37276","city_name":"Kameshkovo","state_id":"3070"},
    {"city_id":"37277","city_name":"Karabanovo","state_id":"3070"},
    {"city_id":"37278","city_name":"Kirzhach","state_id":"3070"},
    {"city_id":"37279","city_name":"Kolchugino","state_id":"3070"},
    {"city_id":"37280","city_name":"Kosterovo","state_id":"3070"},
    {"city_id":"37281","city_name":"Kovrov","state_id":"3070"},
    {"city_id":"37282","city_name":"Krasnaja Gorbatka","state_id":"3070"},
    {"city_id":"37283","city_name":"Krasnyj Oktjabr","state_id":"3070"},
    {"city_id":"37284","city_name":"Lakinsk","state_id":"3070"},
    {"city_id":"37285","city_name":"Melenki","state_id":"3070"},
    {"city_id":"37286","city_name":"Murom","state_id":"3070"},
    {"city_id":"37287","city_name":"Novovjazniki","state_id":"3070"},
    {"city_id":"37288","city_name":"Pokrov","state_id":"3070"},
    {"city_id":"37289","city_name":"Raduzhnyj","state_id":"3070"},
    {"city_id":"37290","city_name":"Sobinka","state_id":"3070"},
    {"city_id":"37291","city_name":"Strunino","state_id":"3070"},
    {"city_id":"37292","city_name":"Sudogda","state_id":"3070"},
    {"city_id":"37293","city_name":"Suzdal","state_id":"3070"},
    {"city_id":"37294","city_name":"Vjazniki","state_id":"3070"},
    {"city_id":"37295","city_name":"Vladimir","state_id":"3070"},
    {"city_id":"37296","city_name":"Dubovka","state_id":"3071"},
    {"city_id":"37297","city_name":"Frolovo","state_id":"3071"},
    {"city_id":"37298","city_name":"Gorkovskij","state_id":"3071"},
    {"city_id":"37299","city_name":"Gorodishche","state_id":"3071"},
    {"city_id":"37300","city_name":"Ilovlja","state_id":"3071"},
    {"city_id":"37301","city_name":"Jelan","state_id":"3071"},
    {"city_id":"37302","city_name":"Kalach-na-Donu","state_id":"3071"},
    {"city_id":"37303","city_name":"Kamyshin","state_id":"3071"},
    {"city_id":"37304","city_name":"Kotelnikovo","state_id":"3071"},
    {"city_id":"37305","city_name":"Kotovo","state_id":"3071"},
    {"city_id":"37306","city_name":"Krasnoslobodsk","state_id":"3071"},
    {"city_id":"37307","city_name":"Leninsk","state_id":"3071"},
    {"city_id":"37308","city_name":"Mihajlovka","state_id":"3071"},
    {"city_id":"37309","city_name":"Nikolajevsk","state_id":"3071"},
    {"city_id":"37310","city_name":"Nizhny Novgorod","state_id":"3071"},
    {"city_id":"37311","city_name":"Novoanninskij","state_id":"3071"},
    {"city_id":"37312","city_name":"Novonikolajevskij","state_id":"3071"},
    {"city_id":"37313","city_name":"Pallasovka","state_id":"3071"},
    {"city_id":"37314","city_name":"Petrov Val","state_id":"3071"},
    {"city_id":"37315","city_name":"Serafimovich","state_id":"3071"},
    {"city_id":"37316","city_name":"Surovikino","state_id":"3071"},
    {"city_id":"37317","city_name":"Svetlyj Jar","state_id":"3071"},
    {"city_id":"37318","city_name":"Urjupinsk","state_id":"3071"},
    {"city_id":"37319","city_name":"Volgograd","state_id":"3071"},
    {"city_id":"37320","city_name":"Volzhskij","state_id":"3071"},
    {"city_id":"37321","city_name":"Zhirnovsk","state_id":"3071"},
    {"city_id":"37322","city_name":"Babajevo","state_id":"3072"},
    {"city_id":"37323","city_name":"Belozjorsk","state_id":"3072"},
    {"city_id":"37324","city_name":"Cherepovec","state_id":"3072"},
    {"city_id":"37325","city_name":"Grjazovec","state_id":"3072"},
    {"city_id":"37326","city_name":"Harovsk","state_id":"3072"},
    {"city_id":"37327","city_name":"Kaduj","state_id":"3072"},
    {"city_id":"37328","city_name":"Krasavino","state_id":"3072"},
    {"city_id":"37329","city_name":"Sheksna","state_id":"3072"},
    {"city_id":"37330","city_name":"Sokol","state_id":"3072"},
    {"city_id":"37331","city_name":"Totma","state_id":"3072"},
    {"city_id":"37332","city_name":"Velikij Ustjug","state_id":"3072"},
    {"city_id":"37333","city_name":"Vologda","state_id":"3072"},
    {"city_id":"37334","city_name":"Vytegra","state_id":"3072"},
    {"city_id":"37335","city_name":"Anna","state_id":"3073"},
    {"city_id":"37336","city_name":"Bobrov","state_id":"3073"},
    {"city_id":"37337","city_name":"Boguchar","state_id":"3073"},
    {"city_id":"37338","city_name":"Borisoglebsk","state_id":"3073"},
    {"city_id":"37339","city_name":"Buturlinovka","state_id":"3073"},
    {"city_id":"37340","city_name":"Ertil","state_id":"3073"},
    {"city_id":"37341","city_name":"Gribanovskij","state_id":"3073"},
    {"city_id":"37342","city_name":"Kalach","state_id":"3073"},
    {"city_id":"37343","city_name":"Kamenka","state_id":"3073"},
    {"city_id":"37344","city_name":"Kantemirovka","state_id":"3073"},
    {"city_id":"37345","city_name":"Liski","state_id":"3073"},
    {"city_id":"37346","city_name":"Novohopjorsk","state_id":"3073"},
    {"city_id":"37347","city_name":"Novovoronezh","state_id":"3073"},
    {"city_id":"37348","city_name":"Ostrogozhsk","state_id":"3073"},
    {"city_id":"37349","city_name":"Pavlovsk","state_id":"3073"},
    {"city_id":"37350","city_name":"Povorino","state_id":"3073"},
    {"city_id":"37351","city_name":"Pridonskij","state_id":"3073"},
    {"city_id":"37352","city_name":"Rossosh","state_id":"3073"},
    {"city_id":"37353","city_name":"Semiluki","state_id":"3073"},
    {"city_id":"37354","city_name":"Somovo","state_id":"3073"},
    {"city_id":"37355","city_name":"Talovaja","state_id":"3073"},
    {"city_id":"37356","city_name":"Voronezh","state_id":"3073"},
    {"city_id":"37357","city_name":"Butare","state_id":"3074"},
    {"city_id":"37358","city_name":"Nyanza","state_id":"3074"},
    {"city_id":"37359","city_name":"Byumba","state_id":"3075"},
    {"city_id":"37360","city_name":"Cyangugu","state_id":"3076"},
    {"city_id":"37361","city_name":"Gikongoro","state_id":"3077"},
    {"city_id":"37362","city_name":"Gisenyi","state_id":"3078"},
    {"city_id":"37363","city_name":"Gitarama","state_id":"3079"},
    {"city_id":"37364","city_name":"Kibungo","state_id":"3080"},
    {"city_id":"37365","city_name":"Rwamagana","state_id":"3080"},
    {"city_id":"37366","city_name":"Kibuye","state_id":"3081"},
    {"city_id":"37367","city_name":"Kigali","state_id":"3082"},
    {"city_id":"37368","city_name":"Ruhengeri","state_id":"3083"},
    {"city_id":"37369","city_name":"Georgetown","state_id":"3084"},
    {"city_id":"37370","city_name":"Anse-la-Raye","state_id":"3102"},
    {"city_id":"37371","city_name":"Canaries","state_id":"3103"},
    {"city_id":"37372","city_name":"Castries","state_id":"3104"},
    {"city_id":"37373","city_name":"Choc","state_id":"3104"},
    {"city_id":"37374","city_name":"Choiseul","state_id":"3105"},
    {"city_id":"37375","city_name":"Dennery","state_id":"3106"},
    {"city_id":"37376","city_name":"Laborie","state_id":"3108"},
    {"city_id":"37377","city_name":"Micoud","state_id":"3109"},
    {"city_id":"37378","city_name":"Soufriere","state_id":"3110"},
    {"city_id":"37379","city_name":"Miquelon","state_id":"3112"},
    {"city_id":"37380","city_name":"Saint-Pierre","state_id":"3113"},
    {"city_id":"37381","city_name":"Biabou","state_id":"3114"},
    {"city_id":"37382","city_name":"Byera","state_id":"3114"},
    {"city_id":"37383","city_name":"Georgetown","state_id":"3114"},
    {"city_id":"37384","city_name":"Dovers","state_id":"3115"},
    {"city_id":"37385","city_name":"Hamilton","state_id":"3115"},
    {"city_id":"37386","city_name":"Port Elizabeth","state_id":"3115"},
    {"city_id":"37387","city_name":"Falelatai","state_id":"3120"},
    {"city_id":"37388","city_name":"Mulifanua","state_id":"3121"},
    {"city_id":"37389","city_name":"Solosolo","state_id":"3122"},
    {"city_id":"37390","city_name":"Safotulafai","state_id":"3123"},
    {"city_id":"37391","city_name":"Samalae'ulu","state_id":"3124"},
    {"city_id":"37392","city_name":"A'opo","state_id":"3125"},
    {"city_id":"37393","city_name":"Taga","state_id":"3126"},
    {"city_id":"37394","city_name":"Gautavai","state_id":"3127"},
    {"city_id":"37395","city_name":"Apia","state_id":"3128"},
    {"city_id":"37396","city_name":"Samamea","state_id":"3129"},
    {"city_id":"37397","city_name":"Neiafu","state_id":"3130"},
    {"city_id":"37398","city_name":"Acquaviva","state_id":"3131"},
    {"city_id":"37399","city_name":"Chiesanuova","state_id":"3133"},
    {"city_id":"37400","city_name":"Domagnano","state_id":"3134"},
    {"city_id":"37401","city_name":"Faetano","state_id":"3135"},
    {"city_id":"37402","city_name":"Fiorentino","state_id":"3136"},
    {"city_id":"37403","city_name":"Montegiardino","state_id":"3137"},
    {"city_id":"37404","city_name":"Serravalle","state_id":"3139"},
    {"city_id":"37405","city_name":"Santana","state_id":"3141"},
    {"city_id":"37406","city_name":"Neves","state_id":"3142"},
    {"city_id":"37407","city_name":"Santo Amaro","state_id":"3143"},
    {"city_id":"37408","city_name":"Trindade","state_id":"3144"},
    {"city_id":"37409","city_name":"Santo Antonio","state_id":"3145"},
    {"city_id":"37410","city_name":"Mahayel","state_id":"3147"},
    {"city_id":"37411","city_name":"Abha","state_id":"3149"},
    {"city_id":"37412","city_name":"Abu 'Aris","state_id":"3149"},
    {"city_id":"37413","city_name":"Khamis Mushayt","state_id":"3149"},
    {"city_id":"37414","city_name":"Qal'at Bishah","state_id":"3149"},
    {"city_id":"37415","city_name":"Ha'il","state_id":"3152"},
    {"city_id":"37416","city_name":"Jawf","state_id":"3153"},
    {"city_id":"37417","city_name":"Sakakah","state_id":"3153"},
    {"city_id":"37418","city_name":"Jizan","state_id":"3154"},
    {"city_id":"37419","city_name":"Sabya","state_id":"3154"},
    {"city_id":"37420","city_name":"Makkah","state_id":"3155"},
    {"city_id":"37421","city_name":"Rabig","state_id":"3155"},
    {"city_id":"37422","city_name":"al-Hawiyah","state_id":"3155"},
    {"city_id":"37423","city_name":"at-Ta'if","state_id":"3155"},
    {"city_id":"37424","city_name":"Dar'iyah","state_id":"3156"},
    {"city_id":"37425","city_name":"Najran","state_id":"3156"},
    {"city_id":"37426","city_name":"Sharurah","state_id":"3156"},
    {"city_id":"37427","city_name":"'Unayzah","state_id":"3157"},
    {"city_id":"37428","city_name":"Buraydah","state_id":"3157"},
    {"city_id":"37429","city_name":"ar-Rass","state_id":"3157"},
    {"city_id":"37430","city_name":"Tabuk","state_id":"3158"},
    {"city_id":"37431","city_name":"Umm Lajj","state_id":"3158"},
    {"city_id":"37432","city_name":"al-Bahah","state_id":"3160"},
    {"city_id":"37433","city_name":"Ara'ar","state_id":"3161"},
    {"city_id":"37434","city_name":"Rafha","state_id":"3161"},
    {"city_id":"37435","city_name":"Turayf","state_id":"3161"},
    {"city_id":"37436","city_name":"al-Qurayyat","state_id":"3161"},
    {"city_id":"37437","city_name":"Yanbu","state_id":"3162"},
    {"city_id":"37438","city_name":"al-Madinah","state_id":"3162"},
    {"city_id":"37439","city_name":"'Afif","state_id":"3163"},
    {"city_id":"37440","city_name":"ad-Dawadimi","state_id":"3163"},
    {"city_id":"37441","city_name":"ad-Dilam","state_id":"3163"},
    {"city_id":"37442","city_name":"al-Kharj","state_id":"3163"},
    {"city_id":"37443","city_name":"al-Majma'ah","state_id":"3163"},
    {"city_id":"37444","city_name":"ar-Riyad","state_id":"3163"},
    {"city_id":"37445","city_name":"az-Zulfi","state_id":"3163"},
    {"city_id":"37446","city_name":"Dakar","state_id":"3164"},
    {"city_id":"37447","city_name":"Bambey","state_id":"3165"},
    {"city_id":"37448","city_name":"Diourbel","state_id":"3165"},
    {"city_id":"37449","city_name":"Mbacke","state_id":"3165"},
    {"city_id":"37450","city_name":"Touba","state_id":"3165"},
    {"city_id":"37451","city_name":"Diofior","state_id":"3166"},
    {"city_id":"37452","city_name":"Fatick","state_id":"3166"},
    {"city_id":"37453","city_name":"Foundiougne","state_id":"3166"},
    {"city_id":"37454","city_name":"Gossas","state_id":"3166"},
    {"city_id":"37455","city_name":"Guinguineo","state_id":"3166"},
    {"city_id":"37456","city_name":"Kahone","state_id":"3166"},
    {"city_id":"37457","city_name":"Passy","state_id":"3166"},
    {"city_id":"37458","city_name":"Sokone","state_id":"3166"},
    {"city_id":"37459","city_name":"Gandiaye","state_id":"3167"},
    {"city_id":"37460","city_name":"Kaffrine","state_id":"3167"},
    {"city_id":"37461","city_name":"Kaolack","state_id":"3167"},
    {"city_id":"37462","city_name":"Koungheul","state_id":"3167"},
    {"city_id":"37463","city_name":"Medina","state_id":"3167"},
    {"city_id":"37464","city_name":"Ndoffane Lagheme","state_id":"3167"},
    {"city_id":"37465","city_name":"Nioro du Rip","state_id":"3167"},
    {"city_id":"37466","city_name":"Toubakouta","state_id":"3167"},
    {"city_id":"37467","city_name":"Dabo","state_id":"3168"},
    {"city_id":"37468","city_name":"Goudomp","state_id":"3168"},
    {"city_id":"37469","city_name":"Kolda","state_id":"3168"},
    {"city_id":"37470","city_name":"Marsassoum","state_id":"3168"},
    {"city_id":"37471","city_name":"Medina Gounas","state_id":"3168"},
    {"city_id":"37472","city_name":"Sedhiou","state_id":"3168"},
    {"city_id":"37473","city_name":"Velingara","state_id":"3168"},
    {"city_id":"37474","city_name":"Wassadou","state_id":"3168"},
    {"city_id":"37475","city_name":"Dahra","state_id":"3169"},
    {"city_id":"37476","city_name":"Kebemer","state_id":"3169"},
    {"city_id":"37477","city_name":"Linguere","state_id":"3169"},
    {"city_id":"37478","city_name":"Louga","state_id":"3169"},
    {"city_id":"37479","city_name":"Dagana","state_id":"3170"},
    {"city_id":"37480","city_name":"Gollere","state_id":"3170"},
    {"city_id":"37481","city_name":"Kanel","state_id":"3170"},
    {"city_id":"37482","city_name":"Matam","state_id":"3170"},
    {"city_id":"37483","city_name":"Ndioum","state_id":"3170"},
    {"city_id":"37484","city_name":"Ourossogui","state_id":"3170"},
    {"city_id":"37485","city_name":"Podor","state_id":"3170"},
    {"city_id":"37486","city_name":"Richard Toll","state_id":"3170"},
    {"city_id":"37487","city_name":"Saint-Louis","state_id":"3170"},
    {"city_id":"37488","city_name":"Semme","state_id":"3170"},
    {"city_id":"37489","city_name":"Thilogne","state_id":"3170"},
    {"city_id":"37490","city_name":"Waounde","state_id":"3170"},
    {"city_id":"37491","city_name":"Bakel","state_id":"3171"},
    {"city_id":"37492","city_name":"Kedougou","state_id":"3171"},
    {"city_id":"37493","city_name":"Tambacounda","state_id":"3171"},
    {"city_id":"37494","city_name":"Joal-Fadiouth","state_id":"3172"},
    {"city_id":"37495","city_name":"Kayar","state_id":"3172"},
    {"city_id":"37496","city_name":"Khombole","state_id":"3172"},
    {"city_id":"37497","city_name":"Mbour","state_id":"3172"},
    {"city_id":"37498","city_name":"Meckhe","state_id":"3172"},
    {"city_id":"37499","city_name":"Nguekhokh","state_id":"3172"},
    {"city_id":"37500","city_name":"Pout","state_id":"3172"},
    {"city_id":"37501","city_name":"Thiadiaye","state_id":"3172"},
    {"city_id":"37502","city_name":"Thies","state_id":"3172"},
    {"city_id":"37503","city_name":"Tivaouane","state_id":"3172"},
    {"city_id":"37504","city_name":"Bignona","state_id":"3173"},
    {"city_id":"37505","city_name":"Oussouye","state_id":"3173"},
    {"city_id":"37506","city_name":"Thionck-Essyl","state_id":"3173"},
    {"city_id":"37507","city_name":"Ziguinchor","state_id":"3173"},
    {"city_id":"37508","city_name":"Cascade","state_id":"3179"},
    {"city_id":"37509","city_name":"Takamaka","state_id":"3180"},
    {"city_id":"37510","city_name":"Mahe","state_id":"3181"},
    {"city_id":"37511","city_name":"Biama","state_id":"3182"},
    {"city_id":"37512","city_name":"Daru","state_id":"3182"},
    {"city_id":"37513","city_name":"Kailahun","state_id":"3182"},
    {"city_id":"37514","city_name":"Kenema","state_id":"3182"},
    {"city_id":"37515","city_name":"Koidu","state_id":"3182"},
    {"city_id":"37516","city_name":"Koindu","state_id":"3182"},
    {"city_id":"37517","city_name":"Pendembu","state_id":"3182"},
    {"city_id":"37518","city_name":"Sefadu","state_id":"3182"},
    {"city_id":"37519","city_name":"Segbwema","state_id":"3182"},
    {"city_id":"37520","city_name":"Yengema","state_id":"3182"},
    {"city_id":"37521","city_name":"Kabala","state_id":"3183"},
    {"city_id":"37522","city_name":"Kambia","state_id":"3183"},
    {"city_id":"37523","city_name":"Lungi","state_id":"3183"},
    {"city_id":"37524","city_name":"Lunsar","state_id":"3183"},
    {"city_id":"37525","city_name":"Magburaka","state_id":"3183"},
    {"city_id":"37526","city_name":"Makeni","state_id":"3183"},
    {"city_id":"37527","city_name":"Mambolo","state_id":"3183"},
    {"city_id":"37528","city_name":"Moyamba","state_id":"3183"},
    {"city_id":"37529","city_name":"Pepel","state_id":"3183"},
    {"city_id":"37530","city_name":"Port Loko","state_id":"3183"},
    {"city_id":"37531","city_name":"Yele","state_id":"3183"},
    {"city_id":"37532","city_name":"Binkolo","state_id":"3184"},
    {"city_id":"37533","city_name":"Bo","state_id":"3184"},
    {"city_id":"37534","city_name":"Bonthe","state_id":"3184"},
    {"city_id":"37535","city_name":"Bumpe","state_id":"3184"},
    {"city_id":"37536","city_name":"Matru","state_id":"3184"},
    {"city_id":"37537","city_name":"Pujehun","state_id":"3184"},
    {"city_id":"37538","city_name":"Taiama","state_id":"3184"},
    {"city_id":"37539","city_name":"Freetown","state_id":"3185"},
    {"city_id":"37540","city_name":"York","state_id":"3185"},
    {"city_id":"37541","city_name":"Singapore","state_id":"3186"},
    {"city_id":"37542","city_name":"Banska Bystrica","state_id":"3187"},
    {"city_id":"37543","city_name":"Banska Shtiavnica","state_id":"3187"},
    {"city_id":"37544","city_name":"Brezno","state_id":"3187"},
    {"city_id":"37545","city_name":"Chierny Balog","state_id":"3187"},
    {"city_id":"37546","city_name":"Detva","state_id":"3187"},
    {"city_id":"37547","city_name":"Fil'akovo","state_id":"3187"},
    {"city_id":"37548","city_name":"Hnusht'a","state_id":"3187"},
    {"city_id":"37549","city_name":"Hrinova","state_id":"3187"},
    {"city_id":"37550","city_name":"Kremnica","state_id":"3187"},
    {"city_id":"37551","city_name":"Krupina","state_id":"3187"},
    {"city_id":"37552","city_name":"Luchenec","state_id":"3187"},
    {"city_id":"37553","city_name":"Nova Bana","state_id":"3187"},
    {"city_id":"37554","city_name":"Podbrezova","state_id":"3187"},
    {"city_id":"37555","city_name":"Poltar","state_id":"3187"},
    {"city_id":"37556","city_name":"Revuca","state_id":"3187"},
    {"city_id":"37557","city_name":"Rimavska Sobota","state_id":"3187"},
    {"city_id":"37558","city_name":"Sliach","state_id":"3187"},
    {"city_id":"37559","city_name":"Tisovec","state_id":"3187"},
    {"city_id":"37560","city_name":"Tornal'a","state_id":"3187"},
    {"city_id":"37561","city_name":"Vel'ky Krtish","state_id":"3187"},
    {"city_id":"37562","city_name":"Vlkanova","state_id":"3187"},
    {"city_id":"37563","city_name":"Zharnovica","state_id":"3187"},
    {"city_id":"37564","city_name":"Zhiar","state_id":"3187"},
    {"city_id":"37565","city_name":"Ziar nad Hronom","state_id":"3187"},
    {"city_id":"37566","city_name":"Zvolen","state_id":"3187"},
    {"city_id":"37567","city_name":"Bernolakovo","state_id":"3188"},
    {"city_id":"37568","city_name":"Bratislava","state_id":"3188"},
    {"city_id":"37569","city_name":"Ivanka pri Dunaji","state_id":"3188"},
    {"city_id":"37570","city_name":"Malacky","state_id":"3188"},
    {"city_id":"37571","city_name":"Modra","state_id":"3188"},
    {"city_id":"37572","city_name":"Pezinok","state_id":"3188"},
    {"city_id":"37573","city_name":"Senec","state_id":"3188"},
    {"city_id":"37574","city_name":"Shenkvice","state_id":"3188"},
    {"city_id":"37575","city_name":"Stupava","state_id":"3188"},
    {"city_id":"37576","city_name":"Svaty Jur","state_id":"3188"},
    {"city_id":"37577","city_name":"Chana","state_id":"3189"},
    {"city_id":"37578","city_name":"Chierna nad Tisou","state_id":"3189"},
    {"city_id":"37579","city_name":"Dobshina","state_id":"3189"},
    {"city_id":"37580","city_name":"Gelnica","state_id":"3189"},
    {"city_id":"37581","city_name":"Kosice","state_id":"3189"},
    {"city_id":"37582","city_name":"Kral'ovsky Chlmec","state_id":"3189"},
    {"city_id":"37583","city_name":"Krompachy","state_id":"3189"},
    {"city_id":"37584","city_name":"Medzev","state_id":"3189"},
    {"city_id":"37585","city_name":"Michalovce","state_id":"3189"},
    {"city_id":"37586","city_name":"Moldava nad Bodvou","state_id":"3189"},
    {"city_id":"37587","city_name":"Pavlovce nad Uhom","state_id":"3189"},
    {"city_id":"37588","city_name":"Rozhnava","state_id":"3189"},
    {"city_id":"37589","city_name":"Sechovce","state_id":"3189"},
    {"city_id":"37590","city_name":"Smizhany","state_id":"3189"},
    {"city_id":"37591","city_name":"Sobrance","state_id":"3189"},
    {"city_id":"37592","city_name":"Strazhske","state_id":"3189"},
    {"city_id":"37593","city_name":"Trebishov","state_id":"3189"},
    {"city_id":"37594","city_name":"Vel'ke Kapushany","state_id":"3189"},
    {"city_id":"37595","city_name":"Vranov","state_id":"3189"},
    {"city_id":"37596","city_name":"Dvory nad Zhitavou","state_id":"3190"},
    {"city_id":"37597","city_name":"Hurbanovo","state_id":"3190"},
    {"city_id":"37598","city_name":"Kolarovo","state_id":"3190"},
    {"city_id":"37599","city_name":"Komarno","state_id":"3190"},
    {"city_id":"37600","city_name":"Komjatice","state_id":"3190"},
    {"city_id":"37601","city_name":"Levice","state_id":"3190"},
    {"city_id":"37602","city_name":"Levocha","state_id":"3190"},
    {"city_id":"37603","city_name":"Mocenok","state_id":"3190"},
    {"city_id":"37604","city_name":"Nesvady","state_id":"3190"},
    {"city_id":"37605","city_name":"Nitra","state_id":"3190"},
    {"city_id":"37606","city_name":"Nove Zamky","state_id":"3190"},
    {"city_id":"37607","city_name":"Palarikovo","state_id":"3190"},
    {"city_id":"37608","city_name":"Shahy","state_id":"3190"},
    {"city_id":"37609","city_name":"Shal'a","state_id":"3190"},
    {"city_id":"37610","city_name":"Shturovo","state_id":"3190"},
    {"city_id":"37611","city_name":"Shurany","state_id":"3190"},
    {"city_id":"37612","city_name":"Svodin","state_id":"3190"},
    {"city_id":"37613","city_name":"Tlmache","state_id":"3190"},
    {"city_id":"37614","city_name":"Tvrdoshovce","state_id":"3190"},
    {"city_id":"37615","city_name":"Vrable","state_id":"3190"},
    {"city_id":"37616","city_name":"Zlate Moravce","state_id":"3190"},
    {"city_id":"37617","city_name":"Bardejov","state_id":"3191"},
    {"city_id":"37618","city_name":"Giraltovce","state_id":"3191"},
    {"city_id":"37619","city_name":"Humenne","state_id":"3191"},
    {"city_id":"37620","city_name":"Jarovnice","state_id":"3191"},
    {"city_id":"37621","city_name":"Kezhmarok","state_id":"3191"},
    {"city_id":"37622","city_name":"Lipany","state_id":"3191"},
    {"city_id":"37623","city_name":"Medzilaborce","state_id":"3191"},
    {"city_id":"37624","city_name":"Podolinec","state_id":"3191"},
    {"city_id":"37625","city_name":"Poprad","state_id":"3191"},
    {"city_id":"37626","city_name":"Preshov","state_id":"3191"},
    {"city_id":"37627","city_name":"Presov","state_id":"3191"},
    {"city_id":"37628","city_name":"Sabinov","state_id":"3191"},
    {"city_id":"37629","city_name":"Snina","state_id":"3191"},
    {"city_id":"37630","city_name":"Spishska Bela","state_id":"3191"},
    {"city_id":"37631","city_name":"Spishska Nova Ves","state_id":"3191"},
    {"city_id":"37632","city_name":"Spishske Podhradie","state_id":"3191"},
    {"city_id":"37633","city_name":"Stara L'ubovna","state_id":"3191"},
    {"city_id":"37634","city_name":"Stropkov","state_id":"3191"},
    {"city_id":"37635","city_name":"Svidnik","state_id":"3191"},
    {"city_id":"37636","city_name":"Svit","state_id":"3191"},
    {"city_id":"37637","city_name":"Vel'ky Sharish","state_id":"3191"},
    {"city_id":"37638","city_name":"Vysoke Tatry","state_id":"3191"},
    {"city_id":"37639","city_name":"Banovce","state_id":"3192"},
    {"city_id":"37640","city_name":"Belusha","state_id":"3192"},
    {"city_id":"37641","city_name":"Bojnice","state_id":"3192"},
    {"city_id":"37642","city_name":"Boshany","state_id":"3192"},
    {"city_id":"37643","city_name":"Dubnica","state_id":"3192"},
    {"city_id":"37644","city_name":"Handlova","state_id":"3192"},
    {"city_id":"37645","city_name":"Ilava","state_id":"3192"},
    {"city_id":"37646","city_name":"Lednicke Rovne","state_id":"3192"},
    {"city_id":"37647","city_name":"Lendak","state_id":"3192"},
    {"city_id":"37648","city_name":"Myjava","state_id":"3192"},
    {"city_id":"37649","city_name":"Nemshova","state_id":"3192"},
    {"city_id":"37650","city_name":"Nova Dubnica","state_id":"3192"},
    {"city_id":"37651","city_name":"Novaky","state_id":"3192"},
    {"city_id":"37652","city_name":"Nove Mesto","state_id":"3192"},
    {"city_id":"37653","city_name":"Partizanske","state_id":"3192"},
    {"city_id":"37654","city_name":"Povazhska Bystrica","state_id":"3192"},
    {"city_id":"37655","city_name":"Prievidza","state_id":"3192"},
    {"city_id":"37656","city_name":"Puchov","state_id":"3192"},
    {"city_id":"37657","city_name":"Stara Tura","state_id":"3192"},
    {"city_id":"37658","city_name":"Topol'chany","state_id":"3192"},
    {"city_id":"37659","city_name":"Trenchianske Teplice","state_id":"3192"},
    {"city_id":"37660","city_name":"Trenchin","state_id":"3192"},
    {"city_id":"37661","city_name":"Trencin","state_id":"3192"},
    {"city_id":"37662","city_name":"Brezova pod Bradlom","state_id":"3193"},
    {"city_id":"37663","city_name":"Dunajska Streda","state_id":"3193"},
    {"city_id":"37664","city_name":"Gabchikovo","state_id":"3193"},
    {"city_id":"37665","city_name":"Galanta","state_id":"3193"},
    {"city_id":"37666","city_name":"Gbely","state_id":"3193"},
    {"city_id":"37667","city_name":"Hlohovec","state_id":"3193"},
    {"city_id":"37668","city_name":"Holich","state_id":"3193"},
    {"city_id":"37669","city_name":"Kuty","state_id":"3193"},
    {"city_id":"37670","city_name":"Leopoldov","state_id":"3193"},
    {"city_id":"37671","city_name":"Piesht'any","state_id":"3193"},
    {"city_id":"37672","city_name":"Piestany","state_id":"3193"},
    {"city_id":"37673","city_name":"Senica","state_id":"3193"},
    {"city_id":"37674","city_name":"Sered'","state_id":"3193"},
    {"city_id":"37675","city_name":"Shamorin","state_id":"3193"},
    {"city_id":"37676","city_name":"Shashtin-Strazhe","state_id":"3193"},
    {"city_id":"37677","city_name":"Shoporna","state_id":"3193"},
    {"city_id":"37678","city_name":"Skalica","state_id":"3193"},
    {"city_id":"37679","city_name":"Sladkovichovo","state_id":"3193"},
    {"city_id":"37680","city_name":"Trnava","state_id":"3193"},
    {"city_id":"37681","city_name":"Vel'ke Ul'any","state_id":"3193"},
    {"city_id":"37682","city_name":"Vel'ky Meder","state_id":"3193"},
    {"city_id":"37683","city_name":"Vrbove","state_id":"3193"},
    {"city_id":"37684","city_name":"Bytcha","state_id":"3194"},
    {"city_id":"37685","city_name":"Chadca","state_id":"3194"},
    {"city_id":"37686","city_name":"Chierne","state_id":"3194"},
    {"city_id":"37687","city_name":"Dolny Kubin","state_id":"3194"},
    {"city_id":"37688","city_name":"Krasno nad Kysucou","state_id":"3194"},
    {"city_id":"37689","city_name":"Kysucke Nove Mesto","state_id":"3194"},
    {"city_id":"37690","city_name":"Liptovsky Hradok","state_id":"3194"},
    {"city_id":"37691","city_name":"Liptovsky Mikulash","state_id":"3194"},
    {"city_id":"37692","city_name":"Martin","state_id":"3194"},
    {"city_id":"37693","city_name":"Namestovo","state_id":"3194"},
    {"city_id":"37694","city_name":"Nizhna","state_id":"3194"},
    {"city_id":"37695","city_name":"Oshchadnica","state_id":"3194"},
    {"city_id":"37696","city_name":"Rabcha","state_id":"3194"},
    {"city_id":"37697","city_name":"Rajec","state_id":"3194"},
    {"city_id":"37698","city_name":"Rakova","state_id":"3194"},
    {"city_id":"37699","city_name":"Ruzhomberok","state_id":"3194"},
    {"city_id":"37700","city_name":"Ruzomberok","state_id":"3194"},
    {"city_id":"37701","city_name":"Shtiavnik","state_id":"3194"},
    {"city_id":"37702","city_name":"Skalite","state_id":"3194"},
    {"city_id":"37703","city_name":"Suchany","state_id":"3194"},
    {"city_id":"37704","city_name":"Terchova","state_id":"3194"},
    {"city_id":"37705","city_name":"Trstena","state_id":"3194"},
    {"city_id":"37706","city_name":"Turany","state_id":"3194"},
    {"city_id":"37707","city_name":"Turchianske Teplice","state_id":"3194"},
    {"city_id":"37708","city_name":"Turzovka","state_id":"3194"},
    {"city_id":"37709","city_name":"Tvrdoshin","state_id":"3194"},
    {"city_id":"37710","city_name":"Vel'ke Rovne","state_id":"3194"},
    {"city_id":"37711","city_name":"Vrutky","state_id":"3194"},
    {"city_id":"37712","city_name":"Zakamenne","state_id":"3194"},
    {"city_id":"37713","city_name":"Zhilina","state_id":"3194"},
    {"city_id":"37714","city_name":"Zilina","state_id":"3194"},
    {"city_id":"37715","city_name":"Semic","state_id":"3195"},
    {"city_id":"37716","city_name":"Skofljica","state_id":"3195"},
    {"city_id":"37717","city_name":"Zelezniki","state_id":"3195"},
    {"city_id":"37718","city_name":"Begunje na Gorenjskem","state_id":"3196"},
    {"city_id":"37719","city_name":"Bistrica ob Trzhichu","state_id":"3196"},
    {"city_id":"37720","city_name":"Bled","state_id":"3196"},
    {"city_id":"37721","city_name":"Blejska Dobrava","state_id":"3196"},
    {"city_id":"37722","city_name":"Bohinjska Bistrica","state_id":"3196"},
    {"city_id":"37723","city_name":"Britof","state_id":"3196"},
    {"city_id":"37724","city_name":"Cerklje na Gorenjskem","state_id":"3196"},
    {"city_id":"37725","city_name":"Golnik","state_id":"3196"},
    {"city_id":"37726","city_name":"Gorenja Vas","state_id":"3196"},
    {"city_id":"37727","city_name":"Hrastje","state_id":"3196"},
    {"city_id":"37728","city_name":"Hrushica","state_id":"3196"},
    {"city_id":"37729","city_name":"Jesenice","state_id":"3196"},
    {"city_id":"37730","city_name":"Kokrica","state_id":"3196"},
    {"city_id":"37731","city_name":"Koroshka Bela","state_id":"3196"},
    {"city_id":"37732","city_name":"Kranj","state_id":"3196"},
    {"city_id":"37733","city_name":"Kranjska Gora","state_id":"3196"},
    {"city_id":"37734","city_name":"Krizhe","state_id":"3196"},
    {"city_id":"37735","city_name":"Kropa","state_id":"3196"},
    {"city_id":"37736","city_name":"Lesce","state_id":"3196"},
    {"city_id":"37737","city_name":"Mlaka pri Kranju","state_id":"3196"},
    {"city_id":"37738","city_name":"Mojstrana","state_id":"3196"},
    {"city_id":"37739","city_name":"Preddvor","state_id":"3196"},
    {"city_id":"37740","city_name":"Predoslje","state_id":"3196"},
    {"city_id":"37741","city_name":"Pristava","state_id":"3196"},
    {"city_id":"37742","city_name":"Radovljica","state_id":"3196"},
    {"city_id":"37743","city_name":"Shenchur","state_id":"3196"},
    {"city_id":"37744","city_name":"Shkofja Loka","state_id":"3196"},
    {"city_id":"37745","city_name":"Slovenski Javornik","state_id":"3196"},
    {"city_id":"37746","city_name":"Spodnja Besnica","state_id":"3196"},
    {"city_id":"37747","city_name":"Spodnje Gorje","state_id":"3196"},
    {"city_id":"37748","city_name":"Stara Loka","state_id":"3196"},
    {"city_id":"37749","city_name":"Sveti Duh","state_id":"3196"},
    {"city_id":"37750","city_name":"Trzhich","state_id":"3196"},
    {"city_id":"37751","city_name":"Visoko","state_id":"3196"},
    {"city_id":"37752","city_name":"Zasip","state_id":"3196"},
    {"city_id":"37753","city_name":"Zgornje Bitnje","state_id":"3196"},
    {"city_id":"37754","city_name":"Zhelezniki","state_id":"3196"},
    {"city_id":"37755","city_name":"Zhiri","state_id":"3196"},
    {"city_id":"37756","city_name":"Ajdovshchina","state_id":"3197"},
    {"city_id":"37757","city_name":"Bilje","state_id":"3197"},
    {"city_id":"37758","city_name":"Bovec","state_id":"3197"},
    {"city_id":"37759","city_name":"Branik","state_id":"3197"},
    {"city_id":"37760","city_name":"Cerkno","state_id":"3197"},
    {"city_id":"37761","city_name":"Deskle","state_id":"3197"},
    {"city_id":"37762","city_name":"Idrija","state_id":"3197"},
    {"city_id":"37763","city_name":"Kanal","state_id":"3197"},
    {"city_id":"37764","city_name":"Kobarid","state_id":"3197"},
    {"city_id":"37765","city_name":"Kromberk","state_id":"3197"},
    {"city_id":"37766","city_name":"Lokavec","state_id":"3197"},
    {"city_id":"37767","city_name":"Miren","state_id":"3197"},
    {"city_id":"37768","city_name":"Nova Gorica","state_id":"3197"},
    {"city_id":"37769","city_name":"Prvachina","state_id":"3197"},
    {"city_id":"37770","city_name":"Renche","state_id":"3197"},
    {"city_id":"37771","city_name":"Rozhna Dolina","state_id":"3197"},
    {"city_id":"37772","city_name":"Shempas","state_id":"3197"},
    {"city_id":"37773","city_name":"Shempeter","state_id":"3197"},
    {"city_id":"37774","city_name":"Solkan","state_id":"3197"},
    {"city_id":"37775","city_name":"Spodnja Idrija","state_id":"3197"},
    {"city_id":"37776","city_name":"Tolmin","state_id":"3197"},
    {"city_id":"37777","city_name":"Vipava","state_id":"3197"},
    {"city_id":"37778","city_name":"Vrtojba","state_id":"3197"},
    {"city_id":"37779","city_name":"Chrna na Koroshkem","state_id":"3199"},
    {"city_id":"37780","city_name":"Dravograd","state_id":"3199"},
    {"city_id":"37781","city_name":"Legen","state_id":"3199"},
    {"city_id":"37782","city_name":"Mezhica","state_id":"3199"},
    {"city_id":"37783","city_name":"Mislinja","state_id":"3199"},
    {"city_id":"37784","city_name":"Muta","state_id":"3199"},
    {"city_id":"37785","city_name":"Otishki Vrh","state_id":"3199"},
    {"city_id":"37786","city_name":"Pameche","state_id":"3199"},
    {"city_id":"37787","city_name":"Podgorje","state_id":"3199"},
    {"city_id":"37788","city_name":"Prevalje","state_id":"3199"},
    {"city_id":"37789","city_name":"Radlje ob Dravi","state_id":"3199"},
    {"city_id":"37790","city_name":"Ravne","state_id":"3199"},
    {"city_id":"37791","city_name":"Shmartno pri Slovenj Gradcu","state_id":"3199"},
    {"city_id":"37792","city_name":"Slovenj Gradec","state_id":"3199"},
    {"city_id":"37793","city_name":"Vuhred","state_id":"3199"},
    {"city_id":"37794","city_name":"Vuzenica","state_id":"3199"},
    {"city_id":"37795","city_name":"Cerknica","state_id":"3200"},
    {"city_id":"37796","city_name":"Ilirska Bistrica","state_id":"3200"},
    {"city_id":"37797","city_name":"Pivka","state_id":"3200"},
    {"city_id":"37798","city_name":"Postojna","state_id":"3200"},
    {"city_id":"37799","city_name":"Rakek","state_id":"3200"},
    {"city_id":"37800","city_name":"Stari trg pri Lozhu","state_id":"3200"},
    {"city_id":"37801","city_name":"Ankaran","state_id":"3201"},
    {"city_id":"37802","city_name":"Bertoki","state_id":"3201"},
    {"city_id":"37803","city_name":"Dekani","state_id":"3201"},
    {"city_id":"37804","city_name":"Divacha","state_id":"3201"},
    {"city_id":"37805","city_name":"Hrvatni","state_id":"3201"},
    {"city_id":"37806","city_name":"Izola","state_id":"3201"},
    {"city_id":"37807","city_name":"Jagodje","state_id":"3201"},
    {"city_id":"37808","city_name":"Koper","state_id":"3201"},
    {"city_id":"37809","city_name":"Lucija","state_id":"3201"},
    {"city_id":"37810","city_name":"Pareceg","state_id":"3201"},
    {"city_id":"37811","city_name":"Piran","state_id":"3201"},
    {"city_id":"37812","city_name":"Pobegi","state_id":"3201"},
    {"city_id":"37813","city_name":"Portorozh","state_id":"3201"},
    {"city_id":"37814","city_name":"Prade","state_id":"3201"},
    {"city_id":"37815","city_name":"Secha","state_id":"3201"},
    {"city_id":"37816","city_name":"Sezhana","state_id":"3201"},
    {"city_id":"37817","city_name":"Spodnje Shkofije","state_id":"3201"},
    {"city_id":"37818","city_name":"Sveti Anton","state_id":"3201"},
    {"city_id":"37819","city_name":"Borovnica","state_id":"3204"},
    {"city_id":"37820","city_name":"Brezovica pri Ljubljani","state_id":"3204"},
    {"city_id":"37821","city_name":"Dob","state_id":"3204"},
    {"city_id":"37822","city_name":"Dobrova","state_id":"3204"},
    {"city_id":"37823","city_name":"Domzhale","state_id":"3204"},
    {"city_id":"37824","city_name":"Dragomer","state_id":"3204"},
    {"city_id":"37825","city_name":"Grosuplje","state_id":"3204"},
    {"city_id":"37826","city_name":"Horjul","state_id":"3204"},
    {"city_id":"37827","city_name":"Ig","state_id":"3204"},
    {"city_id":"37828","city_name":"Ivanchna Gorica","state_id":"3204"},
    {"city_id":"37829","city_name":"Kamnik","state_id":"3204"},
    {"city_id":"37830","city_name":"Komenda","state_id":"3204"},
    {"city_id":"37831","city_name":"Lavrica","state_id":"3204"},
    {"city_id":"37832","city_name":"Litija","state_id":"3204"},
    {"city_id":"37833","city_name":"Ljubljana","state_id":"3204"},
    {"city_id":"37834","city_name":"Log pri Brezovici","state_id":"3204"},
    {"city_id":"37835","city_name":"Logatec","state_id":"3204"},
    {"city_id":"37836","city_name":"Medvode","state_id":"3204"},
    {"city_id":"37837","city_name":"Mekinje","state_id":"3204"},
    {"city_id":"37838","city_name":"Mengesh","state_id":"3204"},
    {"city_id":"37839","city_name":"Moravche","state_id":"3204"},
    {"city_id":"37840","city_name":"Notranje","state_id":"3204"},
    {"city_id":"37841","city_name":"Podgorje","state_id":"3204"},
    {"city_id":"37842","city_name":"Preserje pri Radomljah","state_id":"3204"},
    {"city_id":"37843","city_name":"Radomlje","state_id":"3204"},
    {"city_id":"37844","city_name":"Rovte","state_id":"3204"},
    {"city_id":"37845","city_name":"Shentvid pri Stichni","state_id":"3204"},
    {"city_id":"37846","city_name":"Shkofljica","state_id":"3204"},
    {"city_id":"37847","city_name":"Shmarca","state_id":"3204"},
    {"city_id":"37848","city_name":"Shmartno pri Litiji","state_id":"3204"},
    {"city_id":"37849","city_name":"Smarje-Sap","state_id":"3204"},
    {"city_id":"37850","city_name":"Trzin","state_id":"3204"},
    {"city_id":"37851","city_name":"Verd","state_id":"3204"},
    {"city_id":"37852","city_name":"Vir","state_id":"3204"},
    {"city_id":"37853","city_name":"Vishnja Gora","state_id":"3204"},
    {"city_id":"37854","city_name":"Vnanje Gorice","state_id":"3204"},
    {"city_id":"37855","city_name":"Vodice","state_id":"3204"},
    {"city_id":"37856","city_name":"Vrhnika","state_id":"3204"},
    {"city_id":"37857","city_name":"Zgornje Pirniche","state_id":"3204"},
    {"city_id":"37858","city_name":"Bistrica ob Dravi","state_id":"3205"},
    {"city_id":"37859","city_name":"Bresternica","state_id":"3205"},
    {"city_id":"37860","city_name":"Bukovci","state_id":"3205"},
    {"city_id":"37861","city_name":"Dogoshe","state_id":"3205"},
    {"city_id":"37862","city_name":"Dornava","state_id":"3205"},
    {"city_id":"37863","city_name":"Fram","state_id":"3205"},
    {"city_id":"37864","city_name":"Hotinja Vas","state_id":"3205"},
    {"city_id":"37865","city_name":"Kamnica","state_id":"3205"},
    {"city_id":"37866","city_name":"Kidrichevo","state_id":"3205"},
    {"city_id":"37867","city_name":"Koshaki","state_id":"3205"},
    {"city_id":"37868","city_name":"Krchevina pri Vurbergu","state_id":"3205"},
    {"city_id":"37869","city_name":"Lenart","state_id":"3205"},
    {"city_id":"37870","city_name":"Limbush","state_id":"3205"},
    {"city_id":"37871","city_name":"Lovrenc na Pohorju","state_id":"3205"},
    {"city_id":"37872","city_name":"Maribor","state_id":"3205"},
    {"city_id":"37873","city_name":"Miklavzh na Dravskem Polju","state_id":"3205"},
    {"city_id":"37874","city_name":"Ormozh","state_id":"3205"},
    {"city_id":"37875","city_name":"Pekre","state_id":"3205"},
    {"city_id":"37876","city_name":"Pesnica pri Mariboru","state_id":"3205"},
    {"city_id":"37877","city_name":"Pobrezhje","state_id":"3205"},
    {"city_id":"37878","city_name":"Podvinci","state_id":"3205"},
    {"city_id":"37879","city_name":"Poljchane","state_id":"3205"},
    {"city_id":"37880","city_name":"Pragersko","state_id":"3205"},
    {"city_id":"37881","city_name":"Ptuj","state_id":"3205"},
    {"city_id":"37882","city_name":"Rache","state_id":"3205"},
    {"city_id":"37883","city_name":"Radizel","state_id":"3205"},
    {"city_id":"37884","city_name":"Razvanje","state_id":"3205"},
    {"city_id":"37885","city_name":"Rogoza","state_id":"3205"},
    {"city_id":"37886","city_name":"Rushe","state_id":"3205"},
    {"city_id":"37887","city_name":"Selnica ob Dravi","state_id":"3205"},
    {"city_id":"37888","city_name":"Selnica ob Muri","state_id":"3205"},
    {"city_id":"37889","city_name":"Shentilj","state_id":"3205"},
    {"city_id":"37890","city_name":"Skoke","state_id":"3205"},
    {"city_id":"37891","city_name":"Slovenska Bistrica","state_id":"3205"},
    {"city_id":"37892","city_name":"Spodnja Polskava","state_id":"3205"},
    {"city_id":"37893","city_name":"Spodnje Hoche","state_id":"3205"},
    {"city_id":"37894","city_name":"Spodnji Duplek","state_id":"3205"},
    {"city_id":"37895","city_name":"Spuhlja","state_id":"3205"},
    {"city_id":"37896","city_name":"Sredishche ob Dravi","state_id":"3205"},
    {"city_id":"37897","city_name":"Stojnci","state_id":"3205"},
    {"city_id":"37898","city_name":"Zgornja Polskava","state_id":"3205"},
    {"city_id":"37899","city_name":"Zgornje Poljchane","state_id":"3205"},
    {"city_id":"37900","city_name":"Zgornji Duplek","state_id":"3205"},
    {"city_id":"37901","city_name":"Bakovci","state_id":"3206"},
    {"city_id":"37902","city_name":"Beltinci","state_id":"3206"},
    {"city_id":"37903","city_name":"Chernelavci","state_id":"3206"},
    {"city_id":"37904","city_name":"Chrenshovci","state_id":"3206"},
    {"city_id":"37905","city_name":"Dobrovnik","state_id":"3206"},
    {"city_id":"37906","city_name":"Doklezhovje","state_id":"3206"},
    {"city_id":"37907","city_name":"Ganchani","state_id":"3206"},
    {"city_id":"37908","city_name":"Gornja Radgona","state_id":"3206"},
    {"city_id":"37909","city_name":"Izhakovci","state_id":"3206"},
    {"city_id":"37910","city_name":"Krog","state_id":"3206"},
    {"city_id":"37911","city_name":"Lendava","state_id":"3206"},
    {"city_id":"37912","city_name":"Lipovci","state_id":"3206"},
    {"city_id":"37913","city_name":"Ljutomer","state_id":"3206"},
    {"city_id":"37914","city_name":"Melinci","state_id":"3206"},
    {"city_id":"37915","city_name":"Murska Sobota","state_id":"3206"},
    {"city_id":"37916","city_name":"Odranci","state_id":"3206"},
    {"city_id":"37917","city_name":"Petishovci","state_id":"3206"},
    {"city_id":"37918","city_name":"Radenci","state_id":"3206"},
    {"city_id":"37919","city_name":"Rakichan","state_id":"3206"},
    {"city_id":"37920","city_name":"Turnishche","state_id":"3206"},
    {"city_id":"37921","city_name":"Velika Polana","state_id":"3206"},
    {"city_id":"37922","city_name":"Verzej","state_id":"3206"},
    {"city_id":"37923","city_name":"Breg pri Polzeli","state_id":"3207"},
    {"city_id":"37924","city_name":"Celje","state_id":"3207"},
    {"city_id":"37925","city_name":"Gornji Grad","state_id":"3207"},
    {"city_id":"37926","city_name":"Gotovlje","state_id":"3207"},
    {"city_id":"37927","city_name":"Kasaze","state_id":"3207"},
    {"city_id":"37928","city_name":"Lashko","state_id":"3207"},
    {"city_id":"37929","city_name":"Ljubechna","state_id":"3207"},
    {"city_id":"37930","city_name":"Ljubno ob Savinji","state_id":"3207"},
    {"city_id":"37931","city_name":"Loka pri Zusmu","state_id":"3207"},
    {"city_id":"37932","city_name":"Lokovica","state_id":"3207"},
    {"city_id":"37933","city_name":"Mozirje","state_id":"3207"},
    {"city_id":"37934","city_name":"Naklo","state_id":"3207"},
    {"city_id":"37935","city_name":"Nazarje","state_id":"3207"},
    {"city_id":"37936","city_name":"Oplotnica","state_id":"3207"},
    {"city_id":"37937","city_name":"Petrovche","state_id":"3207"},
    {"city_id":"37938","city_name":"Polzela","state_id":"3207"},
    {"city_id":"37939","city_name":"Prebold","state_id":"3207"},
    {"city_id":"37940","city_name":"Radeche","state_id":"3207"},
    {"city_id":"37941","city_name":"Ravne","state_id":"3207"},
    {"city_id":"37942","city_name":"Rimske Toplica","state_id":"3207"},
    {"city_id":"37943","city_name":"Rogashka Slatina","state_id":"3207"},
    {"city_id":"37944","city_name":"Rogatec","state_id":"3207"},
    {"city_id":"37945","city_name":"Sentjernej","state_id":"3207"},
    {"city_id":"37946","city_name":"Shempeter","state_id":"3207"},
    {"city_id":"37947","city_name":"Shentjur","state_id":"3207"},
    {"city_id":"37948","city_name":"Shkale","state_id":"3207"},
    {"city_id":"37949","city_name":"Shmarje pri Jelshah","state_id":"3207"},
    {"city_id":"37950","city_name":"Shoshtanj","state_id":"3207"},
    {"city_id":"37951","city_name":"Shtore","state_id":"3207"},
    {"city_id":"37952","city_name":"Slovenske Konjice","state_id":"3207"},
    {"city_id":"37953","city_name":"Topolshica","state_id":"3207"},
    {"city_id":"37954","city_name":"Trnovlje pri Celju","state_id":"3207"},
    {"city_id":"37955","city_name":"Velenje","state_id":"3207"},
    {"city_id":"37956","city_name":"Vitanje","state_id":"3207"},
    {"city_id":"37957","city_name":"Vojnik","state_id":"3207"},
    {"city_id":"37958","city_name":"Zabukovica","state_id":"3207"},
    {"city_id":"37959","city_name":"Zadobrova","state_id":"3207"},
    {"city_id":"37960","city_name":"Zhalec","state_id":"3207"},
    {"city_id":"37961","city_name":"Zreche","state_id":"3207"},
    {"city_id":"37962","city_name":"Brestanica","state_id":"3209"},
    {"city_id":"37963","city_name":"Brezhice","state_id":"3209"},
    {"city_id":"37964","city_name":"Krshko","state_id":"3209"},
    {"city_id":"37965","city_name":"Leskovac pri Krshkem","state_id":"3209"},
    {"city_id":"37966","city_name":"Senovo","state_id":"3209"},
    {"city_id":"37967","city_name":"Sevnica","state_id":"3209"},
    {"city_id":"37968","city_name":"Dol pri Hrastniku","state_id":"3210"},
    {"city_id":"37969","city_name":"Hrastnik","state_id":"3210"},
    {"city_id":"37970","city_name":"Izlake","state_id":"3210"},
    {"city_id":"37971","city_name":"Kisovec","state_id":"3210"},
    {"city_id":"37972","city_name":"Trbovlje","state_id":"3210"},
    {"city_id":"37973","city_name":"Zagorje ob Savi","state_id":"3210"},
    {"city_id":"37974","city_name":"Adamstown","state_id":"3211"},
    {"city_id":"37975","city_name":"Tulagi","state_id":"3212"},
    {"city_id":"37976","city_name":"Taro Island","state_id":"3213"},
    {"city_id":"37977","city_name":"Honiara","state_id":"3214"},
    {"city_id":"37978","city_name":"Buala","state_id":"3215"},
    {"city_id":"37979","city_name":"Auki","state_id":"3217"},
    {"city_id":"37980","city_name":"Lata","state_id":"3219"},
    {"city_id":"37981","city_name":"Gizo","state_id":"3220"},
    {"city_id":"37982","city_name":"Baki","state_id":"3221"},
    {"city_id":"37983","city_name":"Borama","state_id":"3221"},
    {"city_id":"37984","city_name":"Gebilay","state_id":"3221"},
    {"city_id":"37985","city_name":"Sayla'","state_id":"3221"},
    {"city_id":"37986","city_name":"Tayeglo","state_id":"3222"},
    {"city_id":"37987","city_name":"Xuddur","state_id":"3222"},
    {"city_id":"37988","city_name":"Yet","state_id":"3222"},
    {"city_id":"37989","city_name":"Muqdisho","state_id":"3223"},
    {"city_id":"37990","city_name":"'Alula","state_id":"3224"},
    {"city_id":"37991","city_name":"Bandarbeyla","state_id":"3224"},
    {"city_id":"37992","city_name":"Bandarmu'ayo","state_id":"3224"},
    {"city_id":"37993","city_name":"Bargal","state_id":"3224"},
    {"city_id":"37994","city_name":"Bereda","state_id":"3224"},
    {"city_id":"37995","city_name":"Bosaso","state_id":"3224"},
    {"city_id":"37996","city_name":"Iskushuban","state_id":"3224"},
    {"city_id":"37997","city_name":"Qandala","state_id":"3224"},
    {"city_id":"37998","city_name":"Qardho","state_id":"3224"},
    {"city_id":"37999","city_name":"Xafun","state_id":"3224"},
    {"city_id":"38000","city_name":"Baydhabo","state_id":"3225"},
    {"city_id":"38001","city_name":"Bur Hakkaba","state_id":"3225"},
    {"city_id":"38002","city_name":"Dinsor","state_id":"3225"},
    {"city_id":"38003","city_name":"'Elbur","state_id":"3226"},
    {"city_id":"38004","city_name":"'Eldhere","state_id":"3226"},
    {"city_id":"38005","city_name":"Dhusa Marreb","state_id":"3226"},
    {"city_id":"38006","city_name":"Bardhere","state_id":"3227"},
    {"city_id":"38007","city_name":"Dolaw","state_id":"3227"},
    {"city_id":"38008","city_name":"Dujuma","state_id":"3227"},
    {"city_id":"38009","city_name":"Garbaharey","state_id":"3227"},
    {"city_id":"38010","city_name":"Luq","state_id":"3227"},
    {"city_id":"38011","city_name":"Sa'o","state_id":"3227"},
    {"city_id":"38012","city_name":"Wajid","state_id":"3227"},
    {"city_id":"38013","city_name":"Bal'ad","state_id":"3228"},
    {"city_id":"38014","city_name":"Beled Weyne","state_id":"3228"},
    {"city_id":"38015","city_name":"Bulobarde","state_id":"3228"},
    {"city_id":"38016","city_name":"Jalalassi","state_id":"3228"},
    {"city_id":"38017","city_name":"Galka'yo","state_id":"3231"},
    {"city_id":"38018","city_name":"Hobyo","state_id":"3231"},
    {"city_id":"38019","city_name":"Xarardhere","state_id":"3231"},
    {"city_id":"38020","city_name":"Eyl","state_id":"3232"},
    {"city_id":"38021","city_name":"Garowe","state_id":"3232"},
    {"city_id":"38022","city_name":"Las'anod","state_id":"3232"},
    {"city_id":"38023","city_name":"'Erigabo","state_id":"3233"},
    {"city_id":"38024","city_name":"Lasqoray","state_id":"3233"},
    {"city_id":"38025","city_name":"'Ek","state_id":"3236"},
    {"city_id":"38026","city_name":"Bur'o","state_id":"3236"},
    {"city_id":"38027","city_name":"Odweyne","state_id":"3236"},
    {"city_id":"38028","city_name":"Alberton","state_id":"3240"},
    {"city_id":"38029","city_name":"Alrode","state_id":"3240"},
    {"city_id":"38030","city_name":"Benmore","state_id":"3240"},
    {"city_id":"38031","city_name":"Benoni","state_id":"3240"},
    {"city_id":"38032","city_name":"Boksburg","state_id":"3240"},
    {"city_id":"38033","city_name":"Booysens","state_id":"3240"},
    {"city_id":"38034","city_name":"Brakpan","state_id":"3240"},
    {"city_id":"38035","city_name":"Bronkhorstspruit","state_id":"3240"},
    {"city_id":"38036","city_name":"Bryanston","state_id":"3240"},
    {"city_id":"38037","city_name":"Carltonville","state_id":"3240"},
    {"city_id":"38038","city_name":"Centurion","state_id":"3240"},
    {"city_id":"38039","city_name":"Cullinan","state_id":"3240"},
    {"city_id":"38040","city_name":"Dainfern","state_id":"3240"},
    {"city_id":"38041","city_name":"Edenvale","state_id":"3240"},
    {"city_id":"38042","city_name":"Ferndale","state_id":"3240"},
    {"city_id":"38043","city_name":"Fourways","state_id":"3240"},
    {"city_id":"38044","city_name":"Gardenview","state_id":"3240"},
    {"city_id":"38045","city_name":"Gauteng","state_id":"3240"},
    {"city_id":"38046","city_name":"Grant Park","state_id":"3240"},
    {"city_id":"38047","city_name":"Heidelberg","state_id":"3240"},
    {"city_id":"38048","city_name":"Isando","state_id":"3240"},
    {"city_id":"38049","city_name":"Johannesburg","state_id":"3240"},
    {"city_id":"38050","city_name":"Kelvin","state_id":"3240"},
    {"city_id":"38051","city_name":"Krugersdorp","state_id":"3240"},
    {"city_id":"38052","city_name":"Linmeyer","state_id":"3240"},
    {"city_id":"38053","city_name":"Maraisburg","state_id":"3240"},
    {"city_id":"38054","city_name":"Midrand","state_id":"3240"},
    {"city_id":"38055","city_name":"Nigel","state_id":"3240"},
    {"city_id":"38056","city_name":"Northmead","state_id":"3240"},
    {"city_id":"38057","city_name":"Petervale","state_id":"3240"},
    {"city_id":"38058","city_name":"Pinegowrie","state_id":"3240"},
    {"city_id":"38059","city_name":"Pretoria","state_id":"3240"},
    {"city_id":"38060","city_name":"Primrose","state_id":"3240"},
    {"city_id":"38061","city_name":"Randburg","state_id":"3240"},
    {"city_id":"38062","city_name":"Randfontein","state_id":"3240"},
    {"city_id":"38063","city_name":"Randvaal","state_id":"3240"},
    {"city_id":"38064","city_name":"Rivonia","state_id":"3240"},
    {"city_id":"38065","city_name":"Robertville","state_id":"3240"},
    {"city_id":"38066","city_name":"Soweto","state_id":"3240"},
    {"city_id":"38067","city_name":"Springs","state_id":"3240"},
    {"city_id":"38068","city_name":"Temba","state_id":"3240"},
    {"city_id":"38069","city_name":"Tembisa","state_id":"3240"},
    {"city_id":"38070","city_name":"Troyeville","state_id":"3240"},
    {"city_id":"38071","city_name":"Vanderbijlpark","state_id":"3240"},
    {"city_id":"38072","city_name":"Vereeniging","state_id":"3240"},
    {"city_id":"38073","city_name":"Verwoerdburg","state_id":"3240"},
    {"city_id":"38074","city_name":"Vorna Valley","state_id":"3240"},
    {"city_id":"38075","city_name":"Wadeville","state_id":"3240"},
    {"city_id":"38076","city_name":"Westonaria","state_id":"3240"},
    {"city_id":"38077","city_name":"Sandton","state_id":"3242"},
    {"city_id":"38078","city_name":"Bela-Bela","state_id":"3244"},
    {"city_id":"38079","city_name":"Dendron","state_id":"3244"},
    {"city_id":"38080","city_name":"Duiwelskloof","state_id":"3244"},
    {"city_id":"38081","city_name":"Ellisras","state_id":"3244"},
    {"city_id":"38082","city_name":"Giyani","state_id":"3244"},
    {"city_id":"38083","city_name":"Lebowakgomo","state_id":"3244"},
    {"city_id":"38084","city_name":"Louis Trichardt","state_id":"3244"},
    {"city_id":"38085","city_name":"Lulekani","state_id":"3244"},
    {"city_id":"38086","city_name":"Mankweng","state_id":"3244"},
    {"city_id":"38087","city_name":"Messina","state_id":"3244"},
    {"city_id":"38088","city_name":"Mogalakwena","state_id":"3244"},
    {"city_id":"38089","city_name":"Mutale","state_id":"3244"},
    {"city_id":"38090","city_name":"Nkowakowa","state_id":"3244"},
    {"city_id":"38091","city_name":"Nylstroom","state_id":"3244"},
    {"city_id":"38092","city_name":"Phalaborwa","state_id":"3244"},
    {"city_id":"38093","city_name":"Pietersburg","state_id":"3244"},
    {"city_id":"38094","city_name":"Polokwane","state_id":"3244"},
    {"city_id":"38095","city_name":"Soekmekaar","state_id":"3244"},
    {"city_id":"38096","city_name":"Southdale","state_id":"3244"},
    {"city_id":"38097","city_name":"Thabazimbi","state_id":"3244"},
    {"city_id":"38098","city_name":"Thohoyandou","state_id":"3244"},
    {"city_id":"38099","city_name":"Thulamahashe","state_id":"3244"},
    {"city_id":"38100","city_name":"Tzaneen","state_id":"3244"},
    {"city_id":"38101","city_name":"Botleng","state_id":"3245"},
    {"city_id":"38102","city_name":"Ekangala","state_id":"3245"},
    {"city_id":"38103","city_name":"Embalenhle","state_id":"3245"},
    {"city_id":"38104","city_name":"Emjindini","state_id":"3245"},
    {"city_id":"38105","city_name":"Empuluzi","state_id":"3245"},
    {"city_id":"38106","city_name":"Emzinoni","state_id":"3245"},
    {"city_id":"38107","city_name":"Ermelo","state_id":"3245"},
    {"city_id":"38108","city_name":"Ethandakukhanja","state_id":"3245"},
    {"city_id":"38109","city_name":"Groblersdal","state_id":"3245"},
    {"city_id":"38110","city_name":"Komatipoort","state_id":"3245"},
    {"city_id":"38111","city_name":"Kriel","state_id":"3245"},
    {"city_id":"38112","city_name":"KwaZanele","state_id":"3245"},
    {"city_id":"38113","city_name":"Kwazamokuhle","state_id":"3245"},
    {"city_id":"38114","city_name":"Lebohang","state_id":"3245"},
    {"city_id":"38115","city_name":"Marblehall","state_id":"3245"},
    {"city_id":"38116","city_name":"Mashishing","state_id":"3245"},
    {"city_id":"38117","city_name":"Mhluzi","state_id":"3245"},
    {"city_id":"38118","city_name":"Nelspruit","state_id":"3245"},
    {"city_id":"38119","city_name":"Phola","state_id":"3245"},
    {"city_id":"38120","city_name":"Sakhile","state_id":"3245"},
    {"city_id":"38121","city_name":"Secunda","state_id":"3245"},
    {"city_id":"38122","city_name":"Siyabuswa","state_id":"3245"},
    {"city_id":"38123","city_name":"Siyathemba","state_id":"3245"},
    {"city_id":"38124","city_name":"Siyathuthuka","state_id":"3245"},
    {"city_id":"38125","city_name":"Vukuzakhe","state_id":"3245"},
    {"city_id":"38126","city_name":"Witbank","state_id":"3245"},
    {"city_id":"38127","city_name":"Witrivier","state_id":"3245"},
    {"city_id":"38128","city_name":"eLukwatini","state_id":"3245"},
    {"city_id":"38129","city_name":"Parow","state_id":"3248"},
    {"city_id":"38130","city_name":"Umtentweni","state_id":"3250"},
    {"city_id":"38131","city_name":"ALbatera","state_id":"3255"},
    {"city_id":"38132","city_name":"Alacant","state_id":"3255"},
    {"city_id":"38133","city_name":"Alcoi","state_id":"3255"},
    {"city_id":"38134","city_name":"Almoradi","state_id":"3255"},
    {"city_id":"38135","city_name":"Altea","state_id":"3255"},
    {"city_id":"38136","city_name":"Aspe","state_id":"3255"},
    {"city_id":"38137","city_name":"Benidorm","state_id":"3255"},
    {"city_id":"38138","city_name":"Benissa","state_id":"3255"},
    {"city_id":"38139","city_name":"Callosa de Segura","state_id":"3255"},
    {"city_id":"38140","city_name":"Calp","state_id":"3255"},
    {"city_id":"38141","city_name":"Cocentaina","state_id":"3255"},
    {"city_id":"38142","city_name":"Crevillent","state_id":"3255"},
    {"city_id":"38143","city_name":"Denia","state_id":"3255"},
    {"city_id":"38144","city_name":"El Campello","state_id":"3255"},
    {"city_id":"38145","city_name":"Elda","state_id":"3255"},
    {"city_id":"38146","city_name":"Elx","state_id":"3255"},
    {"city_id":"38147","city_name":"Guardamar del Segura","state_id":"3255"},
    {"city_id":"38148","city_name":"Ibi","state_id":"3255"},
    {"city_id":"38149","city_name":"L'Alfas del Pi","state_id":"3255"},
    {"city_id":"38150","city_name":"La Vila Joiosa","state_id":"3255"},
    {"city_id":"38151","city_name":"Monover","state_id":"3255"},
    {"city_id":"38152","city_name":"Mutxamel","state_id":"3255"},
    {"city_id":"38153","city_name":"Novelda","state_id":"3255"},
    {"city_id":"38154","city_name":"Orihuela","state_id":"3255"},
    {"city_id":"38155","city_name":"Pedreguer","state_id":"3255"},
    {"city_id":"38156","city_name":"Pego","state_id":"3255"},
    {"city_id":"38157","city_name":"Petrer","state_id":"3255"},
    {"city_id":"38158","city_name":"Pilar de la Horadada","state_id":"3255"},
    {"city_id":"38159","city_name":"Pinoso","state_id":"3255"},
    {"city_id":"38160","city_name":"Rojales","state_id":"3255"},
    {"city_id":"38161","city_name":"Sant Joan d'Alacant","state_id":"3255"},
    {"city_id":"38162","city_name":"Sant Vicent del Raspeig","state_id":"3255"},
    {"city_id":"38163","city_name":"Santa Pola","state_id":"3255"},
    {"city_id":"38164","city_name":"Sax","state_id":"3255"},
    {"city_id":"38165","city_name":"Teulada","state_id":"3255"},
    {"city_id":"38166","city_name":"Torrevieja","state_id":"3255"},
    {"city_id":"38167","city_name":"Villena","state_id":"3255"},
    {"city_id":"38168","city_name":"Xabia","state_id":"3255"},
    {"city_id":"38169","city_name":"Amurrio","state_id":"3256"},
    {"city_id":"38170","city_name":"Llodio","state_id":"3256"},
    {"city_id":"38171","city_name":"Vitoria","state_id":"3256"},
    {"city_id":"38172","city_name":"Albacete","state_id":"3257"},
    {"city_id":"38173","city_name":"Almansa","state_id":"3257"},
    {"city_id":"38174","city_name":"Caudete","state_id":"3257"},
    {"city_id":"38175","city_name":"Hellin","state_id":"3257"},
    {"city_id":"38176","city_name":"La Roda","state_id":"3257"},
    {"city_id":"38177","city_name":"Villarrobledo","state_id":"3257"},
    {"city_id":"38178","city_name":"Adra","state_id":"3258"},
    {"city_id":"38179","city_name":"Albox","state_id":"3258"},
    {"city_id":"38180","city_name":"Almeria","state_id":"3258"},
    {"city_id":"38181","city_name":"Berja","state_id":"3258"},
    {"city_id":"38182","city_name":"Cuevas del Almanzora","state_id":"3258"},
    {"city_id":"38183","city_name":"El Ejido","state_id":"3258"},
    {"city_id":"38184","city_name":"Huercal de Almeria","state_id":"3258"},
    {"city_id":"38185","city_name":"Huercal-Overa","state_id":"3258"},
    {"city_id":"38186","city_name":"Nijar","state_id":"3258"},
    {"city_id":"38187","city_name":"Roquetas de Mar","state_id":"3258"},
    {"city_id":"38188","city_name":"Vicar","state_id":"3258"},
    {"city_id":"38189","city_name":"Benalmadena","state_id":"3259"},
    {"city_id":"38190","city_name":"Aller","state_id":"3260"},
    {"city_id":"38191","city_name":"Aviles","state_id":"3260"},
    {"city_id":"38192","city_name":"C\/ Pena Salon","state_id":"3260"},
    {"city_id":"38193","city_name":"Cangas del Narcea","state_id":"3260"},
    {"city_id":"38194","city_name":"Carreno","state_id":"3260"},
    {"city_id":"38195","city_name":"Castrillon","state_id":"3260"},
    {"city_id":"38196","city_name":"Corvera de Asturias","state_id":"3260"},
    {"city_id":"38197","city_name":"Gijon","state_id":"3260"},
    {"city_id":"38198","city_name":"Gozon","state_id":"3260"},
    {"city_id":"38199","city_name":"Grado","state_id":"3260"},
    {"city_id":"38200","city_name":"Langreo","state_id":"3260"},
    {"city_id":"38201","city_name":"Laviana","state_id":"3260"},
    {"city_id":"38202","city_name":"Lena","state_id":"3260"},
    {"city_id":"38203","city_name":"Llanera","state_id":"3260"},
    {"city_id":"38204","city_name":"Llanes","state_id":"3260"},
    {"city_id":"38205","city_name":"Mieres","state_id":"3260"},
    {"city_id":"38206","city_name":"Navia","state_id":"3260"},
    {"city_id":"38207","city_name":"Oviedo","state_id":"3260"},
    {"city_id":"38208","city_name":"Pilona","state_id":"3260"},
    {"city_id":"38209","city_name":"Pravia","state_id":"3260"},
    {"city_id":"38210","city_name":"San Martin del Rey Aurelio","state_id":"3260"},
    {"city_id":"38211","city_name":"Siero","state_id":"3260"},
    {"city_id":"38212","city_name":"Tineo","state_id":"3260"},
    {"city_id":"38213","city_name":"Valdes","state_id":"3260"},
    {"city_id":"38214","city_name":"Villaviciosa","state_id":"3260"},
    {"city_id":"38215","city_name":"Avila","state_id":"3261"},
    {"city_id":"38216","city_name":"Almendralejo","state_id":"3262"},
    {"city_id":"38217","city_name":"Azuaga","state_id":"3262"},
    {"city_id":"38218","city_name":"Badajoz","state_id":"3262"},
    {"city_id":"38219","city_name":"Don Benito","state_id":"3262"},
    {"city_id":"38220","city_name":"Jerez de los Caballeros","state_id":"3262"},
    {"city_id":"38221","city_name":"Merida","state_id":"3262"},
    {"city_id":"38222","city_name":"Montijo","state_id":"3262"},
    {"city_id":"38223","city_name":"Olivenza","state_id":"3262"},
    {"city_id":"38224","city_name":"Villafranca de los Barros","state_id":"3262"},
    {"city_id":"38225","city_name":"Villanueva de la Serena","state_id":"3262"},
    {"city_id":"38226","city_name":"Zafra","state_id":"3262"},
    {"city_id":"38227","city_name":"Alayor-Menorca","state_id":"3263"},
    {"city_id":"38228","city_name":"Alcudia","state_id":"3263"},
    {"city_id":"38229","city_name":"Calvia","state_id":"3263"},
    {"city_id":"38230","city_name":"Capdepera","state_id":"3263"},
    {"city_id":"38231","city_name":"Ciutadella de Menorca","state_id":"3263"},
    {"city_id":"38232","city_name":"Eivissa","state_id":"3263"},
    {"city_id":"38233","city_name":"Felanitx","state_id":"3263"},
    {"city_id":"38234","city_name":"Inca","state_id":"3263"},
    {"city_id":"38235","city_name":"Llucmajor","state_id":"3263"},
    {"city_id":"38236","city_name":"Mahon","state_id":"3263"},
    {"city_id":"38237","city_name":"Manacor","state_id":"3263"},
    {"city_id":"38238","city_name":"Marratxi","state_id":"3263"},
    {"city_id":"38239","city_name":"Palma","state_id":"3263"},
    {"city_id":"38240","city_name":"Pollenca","state_id":"3263"},
    {"city_id":"38241","city_name":"Sa Pobla","state_id":"3263"},
    {"city_id":"38242","city_name":"Sant Antoni de Portmany","state_id":"3263"},
    {"city_id":"38243","city_name":"Sant Josep de sa Talaia","state_id":"3263"},
    {"city_id":"38244","city_name":"Santa Eulalia del Rio","state_id":"3263"},
    {"city_id":"38245","city_name":"Santanyi","state_id":"3263"},
    {"city_id":"38246","city_name":"Soller","state_id":"3263"},
    {"city_id":"38247","city_name":"Abrera","state_id":"3264"},
    {"city_id":"38248","city_name":"Alella","state_id":"3264"},
    {"city_id":"38249","city_name":"Arenys de Mar","state_id":"3264"},
    {"city_id":"38250","city_name":"Argentona","state_id":"3264"},
    {"city_id":"38251","city_name":"Badalona","state_id":"3264"},
    {"city_id":"38252","city_name":"Badia del Valles","state_id":"3264"},
    {"city_id":"38253","city_name":"Barbera del Valles","state_id":"3264"},
    {"city_id":"38254","city_name":"Barcelona","state_id":"3264"},
    {"city_id":"38255","city_name":"Berga","state_id":"3264"},
    {"city_id":"38256","city_name":"Cabrera de Mar","state_id":"3264"},
    {"city_id":"38257","city_name":"Caldes de Montbui","state_id":"3264"},
    {"city_id":"38258","city_name":"Calella","state_id":"3264"},
    {"city_id":"38259","city_name":"Canet de Mar","state_id":"3264"},
    {"city_id":"38260","city_name":"Canovelles","state_id":"3264"},
    {"city_id":"38261","city_name":"Cardedeu","state_id":"3264"},
    {"city_id":"38262","city_name":"Castellar del Valles","state_id":"3264"},
    {"city_id":"38263","city_name":"Castellbisbal","state_id":"3264"},
    {"city_id":"38264","city_name":"Castelldefels","state_id":"3264"},
    {"city_id":"38265","city_name":"Cerdanyola","state_id":"3264"},
    {"city_id":"38266","city_name":"Corbera de Llobregat","state_id":"3264"},
    {"city_id":"38267","city_name":"Cornella","state_id":"3264"},
    {"city_id":"38268","city_name":"El Masnou","state_id":"3264"},
    {"city_id":"38269","city_name":"El Prat de Llobregat","state_id":"3264"},
    {"city_id":"38270","city_name":"Esparreguera","state_id":"3264"},
    {"city_id":"38271","city_name":"Esplugues de Llobregat","state_id":"3264"},
    {"city_id":"38272","city_name":"Gava","state_id":"3264"},
    {"city_id":"38273","city_name":"Granollers","state_id":"3264"},
    {"city_id":"38274","city_name":"Igualada","state_id":"3264"},
    {"city_id":"38275","city_name":"L'Hospitalet de Llobregat","state_id":"3264"},
    {"city_id":"38276","city_name":"La Garriga","state_id":"3264"},
    {"city_id":"38277","city_name":"La Llagosta","state_id":"3264"},
    {"city_id":"38278","city_name":"Les Franquesas del Valles","state_id":"3264"},
    {"city_id":"38279","city_name":"Llica d'Amunt","state_id":"3264"},
    {"city_id":"38280","city_name":"Malgrat de Mar","state_id":"3264"},
    {"city_id":"38281","city_name":"Manlleu","state_id":"3264"},
    {"city_id":"38282","city_name":"Manresa","state_id":"3264"},
    {"city_id":"38283","city_name":"Martorell","state_id":"3264"},
    {"city_id":"38284","city_name":"Mataro","state_id":"3264"},
    {"city_id":"38285","city_name":"Molins de Rei","state_id":"3264"},
    {"city_id":"38286","city_name":"Mollet del Valles","state_id":"3264"},
    {"city_id":"38287","city_name":"Montcada i Reixac","state_id":"3264"},
    {"city_id":"38288","city_name":"Montgat","state_id":"3264"},
    {"city_id":"38289","city_name":"Montmelo","state_id":"3264"},
    {"city_id":"38290","city_name":"Montornes del Valles","state_id":"3264"},
    {"city_id":"38291","city_name":"Olesa de Montserrat","state_id":"3264"},
    {"city_id":"38292","city_name":"Palau-solita i Plegamans","state_id":"3264"},
    {"city_id":"38293","city_name":"Palleja","state_id":"3264"},
    {"city_id":"38294","city_name":"Parets del Valles","state_id":"3264"},
    {"city_id":"38295","city_name":"Piera","state_id":"3264"},
    {"city_id":"38296","city_name":"Pineda de Mar","state_id":"3264"},
    {"city_id":"38297","city_name":"Premia de Dalt","state_id":"3264"},
    {"city_id":"38298","city_name":"Premia de Mar","state_id":"3264"},
    {"city_id":"38299","city_name":"Ripollet","state_id":"3264"},
    {"city_id":"38300","city_name":"Rubi","state_id":"3264"},
    {"city_id":"38301","city_name":"Sabadell","state_id":"3264"},
    {"city_id":"38302","city_name":"Sant Adria de Besos","state_id":"3264"},
    {"city_id":"38303","city_name":"Sant Andreu de la Barca","state_id":"3264"},
    {"city_id":"38304","city_name":"Sant Boi de Llobregat","state_id":"3264"},
    {"city_id":"38305","city_name":"Sant Celoni","state_id":"3264"},
    {"city_id":"38306","city_name":"Sant Cugat del Valles","state_id":"3264"},
    {"city_id":"38307","city_name":"Sant Feliu de Llobregat","state_id":"3264"},
    {"city_id":"38308","city_name":"Sant Joan Despi","state_id":"3264"},
    {"city_id":"38309","city_name":"Sant Joan de Vilatorrada","state_id":"3264"},
    {"city_id":"38310","city_name":"Sant Just Desvern","state_id":"3264"},
    {"city_id":"38311","city_name":"Sant Pere de Ribes","state_id":"3264"},
    {"city_id":"38312","city_name":"Sant Quirze del Valles","state_id":"3264"},
    {"city_id":"38313","city_name":"Sant Sadurni d'Anoia","state_id":"3264"},
    {"city_id":"38314","city_name":"Sant Vicenc dels Horts","state_id":"3264"},
    {"city_id":"38315","city_name":"Santa Coloma de Gramenet","state_id":"3264"},
    {"city_id":"38316","city_name":"Santa Margarida de Montbui","state_id":"3264"},
    {"city_id":"38317","city_name":"Santa Perpetua de Mogoda","state_id":"3264"},
    {"city_id":"38318","city_name":"Sitges","state_id":"3264"},
    {"city_id":"38319","city_name":"Son Servera","state_id":"3264"},
    {"city_id":"38320","city_name":"Terrassa","state_id":"3264"},
    {"city_id":"38321","city_name":"Tordera","state_id":"3264"},
    {"city_id":"38322","city_name":"Torello","state_id":"3264"},
    {"city_id":"38323","city_name":"Vallirana","state_id":"3264"},
    {"city_id":"38324","city_name":"Vic","state_id":"3264"},
    {"city_id":"38325","city_name":"Viladecans","state_id":"3264"},
    {"city_id":"38326","city_name":"Viladecavalls","state_id":"3264"},
    {"city_id":"38327","city_name":"Vilafranca del Penedes","state_id":"3264"},
    {"city_id":"38328","city_name":"Vilanova del Cami","state_id":"3264"},
    {"city_id":"38329","city_name":"Vilanova i la Geltru","state_id":"3264"},
    {"city_id":"38330","city_name":"Vilassar del Mar","state_id":"3264"},
    {"city_id":"38331","city_name":"Bertamirans","state_id":"3265"},
    {"city_id":"38332","city_name":"Berriatua","state_id":"3266"},
    {"city_id":"38333","city_name":"Derio","state_id":"3266"},
    {"city_id":"38334","city_name":"Elorio","state_id":"3266"},
    {"city_id":"38335","city_name":"Loiu","state_id":"3266"},
    {"city_id":"38336","city_name":"Aranda de Duero","state_id":"3267"},
    {"city_id":"38337","city_name":"Burgos","state_id":"3267"},
    {"city_id":"38338","city_name":"Miranda de Ebro","state_id":"3267"},
    {"city_id":"38339","city_name":"Caceres","state_id":"3268"},
    {"city_id":"38340","city_name":"Coria","state_id":"3268"},
    {"city_id":"38341","city_name":"Miajadas","state_id":"3268"},
    {"city_id":"38342","city_name":"Navalmoral de la Mata","state_id":"3268"},
    {"city_id":"38343","city_name":"Plasencia","state_id":"3268"},
    {"city_id":"38344","city_name":"Talayuela","state_id":"3268"},
    {"city_id":"38345","city_name":"Trujillo","state_id":"3268"},
    {"city_id":"38346","city_name":"Algeciras","state_id":"3269"},
    {"city_id":"38347","city_name":"Arcos de la Frontera","state_id":"3269"},
    {"city_id":"38348","city_name":"Barbate","state_id":"3269"},
    {"city_id":"38349","city_name":"Cadiz","state_id":"3269"},
    {"city_id":"38350","city_name":"Chiclana","state_id":"3269"},
    {"city_id":"38351","city_name":"Chipiona","state_id":"3269"},
    {"city_id":"38352","city_name":"Conil","state_id":"3269"},
    {"city_id":"38353","city_name":"El Puerto de Santa Maria","state_id":"3269"},
    {"city_id":"38354","city_name":"Jerez","state_id":"3269"},
    {"city_id":"38355","city_name":"Jimena de la Frontera","state_id":"3269"},
    {"city_id":"38356","city_name":"La Linea","state_id":"3269"},
    {"city_id":"38357","city_name":"Los Barrios","state_id":"3269"},
    {"city_id":"38358","city_name":"Medina-Sidonia","state_id":"3269"},
    {"city_id":"38359","city_name":"Olvera","state_id":"3269"},
    {"city_id":"38360","city_name":"Puerto Real","state_id":"3269"},
    {"city_id":"38361","city_name":"Rota","state_id":"3269"},
    {"city_id":"38362","city_name":"San Fernando","state_id":"3269"},
    {"city_id":"38363","city_name":"San Roque","state_id":"3269"},
    {"city_id":"38364","city_name":"Sanlucar de Barrameda","state_id":"3269"},
    {"city_id":"38365","city_name":"Tarifa","state_id":"3269"},
    {"city_id":"38366","city_name":"Ubrique","state_id":"3269"},
    {"city_id":"38367","city_name":"Vejer de la Frontera","state_id":"3269"},
    {"city_id":"38368","city_name":"Villamartin","state_id":"3269"},
    {"city_id":"38369","city_name":"Camargo","state_id":"3270"},
    {"city_id":"38370","city_name":"Castro-Urdiales","state_id":"3270"},
    {"city_id":"38371","city_name":"El Astillero","state_id":"3270"},
    {"city_id":"38372","city_name":"Laredo","state_id":"3270"},
    {"city_id":"38373","city_name":"Los Corrales de Buelna","state_id":"3270"},
    {"city_id":"38374","city_name":"Pielagos","state_id":"3270"},
    {"city_id":"38375","city_name":"Reinosa","state_id":"3270"},
    {"city_id":"38376","city_name":"San Felices De Buelna","state_id":"3270"},
    {"city_id":"38377","city_name":"Santa Cruz de Bezana","state_id":"3270"},
    {"city_id":"38378","city_name":"Santander","state_id":"3270"},
    {"city_id":"38379","city_name":"Santona","state_id":"3270"},
    {"city_id":"38380","city_name":"Torrelavega","state_id":"3270"},
    {"city_id":"38381","city_name":"Almassora","state_id":"3271"},
    {"city_id":"38382","city_name":"Benicarlo","state_id":"3271"},
    {"city_id":"38383","city_name":"Benicassim","state_id":"3271"},
    {"city_id":"38384","city_name":"Burriana","state_id":"3271"},
    {"city_id":"38385","city_name":"Castello","state_id":"3271"},
    {"city_id":"38386","city_name":"L'Alcora","state_id":"3271"},
    {"city_id":"38387","city_name":"La Vall d'Uixo","state_id":"3271"},
    {"city_id":"38388","city_name":"Nules","state_id":"3271"},
    {"city_id":"38389","city_name":"Onda","state_id":"3271"},
    {"city_id":"38390","city_name":"Segorbe","state_id":"3271"},
    {"city_id":"38391","city_name":"Vila-real","state_id":"3271"},
    {"city_id":"38392","city_name":"Vinaros","state_id":"3271"},
    {"city_id":"38393","city_name":"Barcelona","state_id":"3272"},
    {"city_id":"38394","city_name":"Ceuta","state_id":"3273"},
    {"city_id":"38395","city_name":"Aguilar de la Frontera","state_id":"3281"},
    {"city_id":"38396","city_name":"Baena","state_id":"3281"},
    {"city_id":"38397","city_name":"Bujalance","state_id":"3281"},
    {"city_id":"38398","city_name":"Cabra","state_id":"3281"},
    {"city_id":"38399","city_name":"Cordoba","state_id":"3281"},
    {"city_id":"38400","city_name":"Fernan-Nunez","state_id":"3281"},
    {"city_id":"38401","city_name":"Fuente Palmera","state_id":"3281"},
    {"city_id":"38402","city_name":"La Carlota","state_id":"3281"},
    {"city_id":"38403","city_name":"Lucena","state_id":"3281"},
    {"city_id":"38404","city_name":"Luque","state_id":"3281"},
    {"city_id":"38405","city_name":"Montilla","state_id":"3281"},
    {"city_id":"38406","city_name":"Montoro","state_id":"3281"},
    {"city_id":"38407","city_name":"Palma del Rio","state_id":"3281"},
    {"city_id":"38408","city_name":"Penarroya-Pueblonuevo","state_id":"3281"},
    {"city_id":"38409","city_name":"Pozoblanco","state_id":"3281"},
    {"city_id":"38410","city_name":"Priego de Cordoba","state_id":"3281"},
    {"city_id":"38411","city_name":"Puente Genil","state_id":"3281"},
    {"city_id":"38412","city_name":"Rute","state_id":"3281"},
    {"city_id":"38413","city_name":"Villanueva de Cordoba","state_id":"3281"},
    {"city_id":"38414","city_name":"Cuenca","state_id":"3282"},
    {"city_id":"38415","city_name":"Tarancon","state_id":"3282"},
    {"city_id":"38416","city_name":"Aduna","state_id":"3283"},
    {"city_id":"38417","city_name":"Lazakao","state_id":"3283"},
    {"city_id":"38418","city_name":"Banyoles","state_id":"3284"},
    {"city_id":"38419","city_name":"Blanes","state_id":"3284"},
    {"city_id":"38420","city_name":"Empuriabrava","state_id":"3284"},
    {"city_id":"38421","city_name":"Figueres","state_id":"3284"},
    {"city_id":"38422","city_name":"Girona","state_id":"3284"},
    {"city_id":"38423","city_name":"La Bisbal d'Emporda","state_id":"3284"},
    {"city_id":"38424","city_name":"Lloret de Mar","state_id":"3284"},
    {"city_id":"38425","city_name":"Massanet de la Selva","state_id":"3284"},
    {"city_id":"38426","city_name":"Olot","state_id":"3284"},
    {"city_id":"38427","city_name":"Palafrugell","state_id":"3284"},
    {"city_id":"38428","city_name":"Palamos","state_id":"3284"},
    {"city_id":"38429","city_name":"Ripoll","state_id":"3284"},
    {"city_id":"38430","city_name":"Roses","state_id":"3284"},
    {"city_id":"38431","city_name":"Salt","state_id":"3284"},
    {"city_id":"38432","city_name":"Sant Feliu de Guixols","state_id":"3284"},
    {"city_id":"38433","city_name":"Santa Coloma de Farners","state_id":"3284"},
    {"city_id":"38434","city_name":"Torroella de Montgri","state_id":"3284"},
    {"city_id":"38435","city_name":"Albolote","state_id":"3285"},
    {"city_id":"38436","city_name":"Almunecar","state_id":"3285"},
    {"city_id":"38437","city_name":"Armilla","state_id":"3285"},
    {"city_id":"38438","city_name":"Atarfe","state_id":"3285"},
    {"city_id":"38439","city_name":"Baza","state_id":"3285"},
    {"city_id":"38440","city_name":"Granada","state_id":"3285"},
    {"city_id":"38441","city_name":"Guadix","state_id":"3285"},
    {"city_id":"38442","city_name":"Huescar","state_id":"3285"},
    {"city_id":"38443","city_name":"Huetor Tajar","state_id":"3285"},
    {"city_id":"38444","city_name":"Huetor Vega","state_id":"3285"},
    {"city_id":"38445","city_name":"Illora","state_id":"3285"},
    {"city_id":"38446","city_name":"La Zubia","state_id":"3285"},
    {"city_id":"38447","city_name":"Las Gabias","state_id":"3285"},
    {"city_id":"38448","city_name":"Loja","state_id":"3285"},
    {"city_id":"38449","city_name":"Maracena","state_id":"3285"},
    {"city_id":"38450","city_name":"Motril","state_id":"3285"},
    {"city_id":"38451","city_name":"Ogijares","state_id":"3285"},
    {"city_id":"38452","city_name":"Peligros","state_id":"3285"},
    {"city_id":"38453","city_name":"Pinos Puente","state_id":"3285"},
    {"city_id":"38454","city_name":"Salobrena","state_id":"3285"},
    {"city_id":"38455","city_name":"Santa Fe","state_id":"3285"},
    {"city_id":"38456","city_name":"Azuqueca de Henares","state_id":"3286"},
    {"city_id":"38457","city_name":"Guadalajara","state_id":"3286"},
    {"city_id":"38458","city_name":"Andoain","state_id":"3287"},
    {"city_id":"38459","city_name":"Anoeta","state_id":"3287"},
    {"city_id":"38460","city_name":"Arrasate","state_id":"3287"},
    {"city_id":"38461","city_name":"Azkoitia","state_id":"3287"},
    {"city_id":"38462","city_name":"Azpeitia","state_id":"3287"},
    {"city_id":"38463","city_name":"Beasain","state_id":"3287"},
    {"city_id":"38464","city_name":"Bergara","state_id":"3287"},
    {"city_id":"38465","city_name":"Donostia","state_id":"3287"},
    {"city_id":"38466","city_name":"Eibar","state_id":"3287"},
    {"city_id":"38467","city_name":"Elgoibar","state_id":"3287"},
    {"city_id":"38468","city_name":"Errenteria","state_id":"3287"},
    {"city_id":"38469","city_name":"Guipuuzcoa","state_id":"3287"},
    {"city_id":"38470","city_name":"Hernani","state_id":"3287"},
    {"city_id":"38471","city_name":"Hondarribia","state_id":"3287"},
    {"city_id":"38472","city_name":"Irun","state_id":"3287"},
    {"city_id":"38473","city_name":"Legazpi","state_id":"3287"},
    {"city_id":"38474","city_name":"Mendaro","state_id":"3287"},
    {"city_id":"38475","city_name":"Mondragon","state_id":"3287"},
    {"city_id":"38476","city_name":"Oiartzun","state_id":"3287"},
    {"city_id":"38477","city_name":"Onati","state_id":"3287"},
    {"city_id":"38478","city_name":"Ordizia","state_id":"3287"},
    {"city_id":"38479","city_name":"Oria","state_id":"3287"},
    {"city_id":"38480","city_name":"Pasaia","state_id":"3287"},
    {"city_id":"38481","city_name":"Tolosa","state_id":"3287"},
    {"city_id":"38482","city_name":"Zarautz","state_id":"3287"},
    {"city_id":"38483","city_name":"Zumaia","state_id":"3287"},
    {"city_id":"38484","city_name":"Zumarraga","state_id":"3287"},
    {"city_id":"38485","city_name":"Aljaraque","state_id":"3288"},
    {"city_id":"38486","city_name":"Almonte","state_id":"3288"},
    {"city_id":"38487","city_name":"Ayamonte","state_id":"3288"},
    {"city_id":"38488","city_name":"Bollullos Par del Condado","state_id":"3288"},
    {"city_id":"38489","city_name":"Cartaya","state_id":"3288"},
    {"city_id":"38490","city_name":"Gibraleon","state_id":"3288"},
    {"city_id":"38491","city_name":"Huelva","state_id":"3288"},
    {"city_id":"38492","city_name":"Isla Cristina","state_id":"3288"},
    {"city_id":"38493","city_name":"La Palma del Condado","state_id":"3288"},
    {"city_id":"38494","city_name":"Lepe","state_id":"3288"},
    {"city_id":"38495","city_name":"Moguer","state_id":"3288"},
    {"city_id":"38496","city_name":"Punta Umbria","state_id":"3288"},
    {"city_id":"38497","city_name":"Valverde del Camino","state_id":"3288"},
    {"city_id":"38498","city_name":"Barbastro","state_id":"3289"},
    {"city_id":"38499","city_name":"Binefar","state_id":"3289"},
    {"city_id":"38500","city_name":"Fraga","state_id":"3289"},
    {"city_id":"38501","city_name":"Huesca","state_id":"3289"},
    {"city_id":"38502","city_name":"Jaca","state_id":"3289"},
    {"city_id":"38503","city_name":"Monzon","state_id":"3289"},
    {"city_id":"38504","city_name":"Sabinanigo","state_id":"3289"},
    {"city_id":"38505","city_name":"Alcala la Real","state_id":"3290"},
    {"city_id":"38506","city_name":"Alcaudete","state_id":"3290"},
    {"city_id":"38507","city_name":"Andujar","state_id":"3290"},
    {"city_id":"38508","city_name":"Baeza","state_id":"3290"},
    {"city_id":"38509","city_name":"Bailen","state_id":"3290"},
    {"city_id":"38510","city_name":"Cazorla","state_id":"3290"},
    {"city_id":"38511","city_name":"Jaen","state_id":"3290"},
    {"city_id":"38512","city_name":"Jodar","state_id":"3290"},
    {"city_id":"38513","city_name":"La Carolina","state_id":"3290"},
    {"city_id":"38514","city_name":"Linares","state_id":"3290"},
    {"city_id":"38515","city_name":"Mancha Real","state_id":"3290"},
    {"city_id":"38516","city_name":"Martos","state_id":"3290"},
    {"city_id":"38517","city_name":"Mengibar","state_id":"3290"},
    {"city_id":"38518","city_name":"Torre del Campo","state_id":"3290"},
    {"city_id":"38519","city_name":"Torredonjimeno","state_id":"3290"},
    {"city_id":"38520","city_name":"Ubeda","state_id":"3290"},
    {"city_id":"38521","city_name":"Villacarrillo","state_id":"3290"},
    {"city_id":"38522","city_name":"Villanueva del Arzobispo","state_id":"3290"},
    {"city_id":"38523","city_name":"Astorga","state_id":"3293"},
    {"city_id":"38524","city_name":"Bembibre","state_id":"3293"},
    {"city_id":"38525","city_name":"La Baneza","state_id":"3293"},
    {"city_id":"38526","city_name":"Leon","state_id":"3293"},
    {"city_id":"38527","city_name":"Ponferrada","state_id":"3293"},
    {"city_id":"38528","city_name":"San Andres del Rabanedo","state_id":"3293"},
    {"city_id":"38529","city_name":"Villablino","state_id":"3293"},
    {"city_id":"38530","city_name":"Villaquilambre","state_id":"3293"},
    {"city_id":"38531","city_name":"Tora","state_id":"3294"},
    {"city_id":"38532","city_name":"Balaguer","state_id":"3295"},
    {"city_id":"38533","city_name":"La Seu d'Urgell","state_id":"3295"},
    {"city_id":"38534","city_name":"Lleida","state_id":"3295"},
    {"city_id":"38535","city_name":"Mollerussa","state_id":"3295"},
    {"city_id":"38536","city_name":"Tarrega","state_id":"3295"},
    {"city_id":"38537","city_name":"Burela","state_id":"3296"},
    {"city_id":"38538","city_name":"Cervo","state_id":"3296"},
    {"city_id":"38539","city_name":"Chantada","state_id":"3296"},
    {"city_id":"38540","city_name":"Foz","state_id":"3296"},
    {"city_id":"38541","city_name":"Lugo","state_id":"3296"},
    {"city_id":"38542","city_name":"Monforte de Lemos","state_id":"3296"},
    {"city_id":"38543","city_name":"Ribadeo","state_id":"3296"},
    {"city_id":"38544","city_name":"Sarria","state_id":"3296"},
    {"city_id":"38545","city_name":"Vilalba","state_id":"3296"},
    {"city_id":"38546","city_name":"Viveiro","state_id":"3296"},
    {"city_id":"38547","city_name":"Alcala de Henares","state_id":"3297"},
    {"city_id":"38548","city_name":"Alcobendas","state_id":"3297"},
    {"city_id":"38549","city_name":"Alcorcon","state_id":"3297"},
    {"city_id":"38550","city_name":"Algete","state_id":"3297"},
    {"city_id":"38551","city_name":"Alpedrete","state_id":"3297"},
    {"city_id":"38552","city_name":"Aranjuez","state_id":"3297"},
    {"city_id":"38553","city_name":"Arganda del Rey","state_id":"3297"},
    {"city_id":"38554","city_name":"Boadilla del Monte","state_id":"3297"},
    {"city_id":"38555","city_name":"Ciempozuelos","state_id":"3297"},
    {"city_id":"38556","city_name":"Collado Villalba","state_id":"3297"},
    {"city_id":"38557","city_name":"Colmenar Viejo","state_id":"3297"},
    {"city_id":"38558","city_name":"Coslada","state_id":"3297"},
    {"city_id":"38559","city_name":"El Escorial","state_id":"3297"},
    {"city_id":"38560","city_name":"Fuenlabrada","state_id":"3297"},
    {"city_id":"38561","city_name":"Galapagar","state_id":"3297"},
    {"city_id":"38562","city_name":"Getafe","state_id":"3297"},
    {"city_id":"38563","city_name":"Guadarrama","state_id":"3297"},
    {"city_id":"38564","city_name":"Humanes de Madrid","state_id":"3297"},
    {"city_id":"38565","city_name":"Las Rozas de Madrid","state_id":"3297"},
    {"city_id":"38566","city_name":"Leganes","state_id":"3297"},
    {"city_id":"38567","city_name":"Madrid","state_id":"3297"},
    {"city_id":"38568","city_name":"Majadahonda","state_id":"3297"},
    {"city_id":"38569","city_name":"Meco","state_id":"3297"},
    {"city_id":"38570","city_name":"Mejorada del Campo","state_id":"3297"},
    {"city_id":"38571","city_name":"Mostoles","state_id":"3297"},
    {"city_id":"38572","city_name":"Navalcarnero","state_id":"3297"},
    {"city_id":"38573","city_name":"Parla","state_id":"3297"},
    {"city_id":"38574","city_name":"Pinto","state_id":"3297"},
    {"city_id":"38575","city_name":"Pozuelo de Alarcon","state_id":"3297"},
    {"city_id":"38576","city_name":"Rivas-Vaciamadrid","state_id":"3297"},
    {"city_id":"38577","city_name":"San Fernando de Henares","state_id":"3297"},
    {"city_id":"38578","city_name":"San Lorenzo de El Escorial","state_id":"3297"},
    {"city_id":"38579","city_name":"San Martin de la Vega","state_id":"3297"},
    {"city_id":"38580","city_name":"San Sebastian de los Reyes","state_id":"3297"},
    {"city_id":"38581","city_name":"Torrejon de Ardoz","state_id":"3297"},
    {"city_id":"38582","city_name":"Torrelodones","state_id":"3297"},
    {"city_id":"38583","city_name":"Tres Cantos","state_id":"3297"},
    {"city_id":"38584","city_name":"Valdemoro","state_id":"3297"},
    {"city_id":"38585","city_name":"Velilla de San Antonio","state_id":"3297"},
    {"city_id":"38586","city_name":"Villanueva de la Canada","state_id":"3297"},
    {"city_id":"38587","city_name":"Villaviciosa de Odon","state_id":"3297"},
    {"city_id":"38588","city_name":"Alhaurin de la Torre","state_id":"3298"},
    {"city_id":"38589","city_name":"Alhaurin el Grande","state_id":"3298"},
    {"city_id":"38590","city_name":"Alora","state_id":"3298"},
    {"city_id":"38591","city_name":"Antequera","state_id":"3298"},
    {"city_id":"38592","city_name":"Apartado","state_id":"3298"},
    {"city_id":"38593","city_name":"Archidona","state_id":"3298"},
    {"city_id":"38594","city_name":"Benalmadena","state_id":"3298"},
    {"city_id":"38595","city_name":"Cartama","state_id":"3298"},
    {"city_id":"38596","city_name":"Coin","state_id":"3298"},
    {"city_id":"38597","city_name":"Estepona","state_id":"3298"},
    {"city_id":"38598","city_name":"Fuengirola","state_id":"3298"},
    {"city_id":"38599","city_name":"Malaga","state_id":"3298"},
    {"city_id":"38600","city_name":"Marbella","state_id":"3298"},
    {"city_id":"38601","city_name":"Mijas","state_id":"3298"},
    {"city_id":"38602","city_name":"Nerja","state_id":"3298"},
    {"city_id":"38603","city_name":"Rincon de la Victoria","state_id":"3298"},
    {"city_id":"38604","city_name":"Ronda","state_id":"3298"},
    {"city_id":"38605","city_name":"Torremolinos","state_id":"3298"},
    {"city_id":"38606","city_name":"Torrox","state_id":"3298"},
    {"city_id":"38607","city_name":"Velez-Malaga","state_id":"3298"},
    {"city_id":"38608","city_name":"maro","state_id":"3298"},
    {"city_id":"38609","city_name":"Melilla","state_id":"3299"},
    {"city_id":"38610","city_name":"Abaran","state_id":"3300"},
    {"city_id":"38611","city_name":"Aguilas","state_id":"3300"},
    {"city_id":"38612","city_name":"Alcantarilla","state_id":"3300"},
    {"city_id":"38613","city_name":"Alhama de Murcia","state_id":"3300"},
    {"city_id":"38614","city_name":"Archena","state_id":"3300"},
    {"city_id":"38615","city_name":"Beniel","state_id":"3300"},
    {"city_id":"38616","city_name":"Bullas","state_id":"3300"},
    {"city_id":"38617","city_name":"Calasparra","state_id":"3300"},
    {"city_id":"38618","city_name":"Caravaca de la Cruz","state_id":"3300"},
    {"city_id":"38619","city_name":"Cartagena","state_id":"3300"},
    {"city_id":"38620","city_name":"Cehegin","state_id":"3300"},
    {"city_id":"38621","city_name":"Cieza","state_id":"3300"},
    {"city_id":"38622","city_name":"Fuente Alamo de Murcia","state_id":"3300"},
    {"city_id":"38623","city_name":"Jumilla","state_id":"3300"},
    {"city_id":"38624","city_name":"La Union","state_id":"3300"},
    {"city_id":"38625","city_name":"Las Torres de Cotillas","state_id":"3300"},
    {"city_id":"38626","city_name":"Lorca","state_id":"3300"},
    {"city_id":"38627","city_name":"Los Alcazares","state_id":"3300"},
    {"city_id":"38628","city_name":"Mazarron","state_id":"3300"},
    {"city_id":"38629","city_name":"Molina de Segura","state_id":"3300"},
    {"city_id":"38630","city_name":"Moratella","state_id":"3300"},
    {"city_id":"38631","city_name":"Mula","state_id":"3300"},
    {"city_id":"38632","city_name":"Murcia","state_id":"3300"},
    {"city_id":"38633","city_name":"Puerto Lumbreras","state_id":"3300"},
    {"city_id":"38634","city_name":"San Javier","state_id":"3300"},
    {"city_id":"38635","city_name":"San Pedro del Pinatar","state_id":"3300"},
    {"city_id":"38636","city_name":"Santomera","state_id":"3300"},
    {"city_id":"38637","city_name":"Torre Pacheco","state_id":"3300"},
    {"city_id":"38638","city_name":"Torre-Pacheco","state_id":"3300"},
    {"city_id":"38639","city_name":"Totana","state_id":"3300"},
    {"city_id":"38640","city_name":"Yecla","state_id":"3300"},
    {"city_id":"38641","city_name":"Ansoain","state_id":"3301"},
    {"city_id":"38642","city_name":"Atarrabia","state_id":"3301"},
    {"city_id":"38643","city_name":"Baranain","state_id":"3301"},
    {"city_id":"38644","city_name":"Burlata","state_id":"3301"},
    {"city_id":"38645","city_name":"Iruna","state_id":"3301"},
    {"city_id":"38646","city_name":"Lizarra","state_id":"3301"},
    {"city_id":"38647","city_name":"Navarra","state_id":"3301"},
    {"city_id":"38648","city_name":"Orkoien","state_id":"3301"},
    {"city_id":"38649","city_name":"Tafalla","state_id":"3301"},
    {"city_id":"38650","city_name":"Tudela","state_id":"3301"},
    {"city_id":"38651","city_name":"Zizur Nagusia","state_id":"3301"},
    {"city_id":"38652","city_name":"O Barco de Valdeorras","state_id":"3302"},
    {"city_id":"38653","city_name":"O Carballino","state_id":"3302"},
    {"city_id":"38654","city_name":"Ourense","state_id":"3302"},
    {"city_id":"38655","city_name":"Verin","state_id":"3302"},
    {"city_id":"38656","city_name":"Xinzo de Limia","state_id":"3302"},
    {"city_id":"38657","city_name":"Guardo","state_id":"3304"},
    {"city_id":"38658","city_name":"Palencia","state_id":"3304"},
    {"city_id":"38659","city_name":"A Estrada","state_id":"3305"},
    {"city_id":"38660","city_name":"A Guarda","state_id":"3305"},
    {"city_id":"38661","city_name":"Baiona","state_id":"3305"},
    {"city_id":"38662","city_name":"Bueu","state_id":"3305"},
    {"city_id":"38663","city_name":"Caldas de Reis","state_id":"3305"},
    {"city_id":"38664","city_name":"Cambados","state_id":"3305"},
    {"city_id":"38665","city_name":"Cangas","state_id":"3305"},
    {"city_id":"38666","city_name":"Cangas De Morrazo","state_id":"3305"},
    {"city_id":"38667","city_name":"Gondomar","state_id":"3305"},
    {"city_id":"38668","city_name":"Lalin","state_id":"3305"},
    {"city_id":"38669","city_name":"Marin","state_id":"3305"},
    {"city_id":"38670","city_name":"Moana","state_id":"3305"},
    {"city_id":"38671","city_name":"Mos","state_id":"3305"},
    {"city_id":"38672","city_name":"Nigran","state_id":"3305"},
    {"city_id":"38673","city_name":"O Grove","state_id":"3305"},
    {"city_id":"38674","city_name":"O Porrino","state_id":"3305"},
    {"city_id":"38675","city_name":"Poio","state_id":"3305"},
    {"city_id":"38676","city_name":"Ponteareas","state_id":"3305"},
    {"city_id":"38677","city_name":"Pontevedra","state_id":"3305"},
    {"city_id":"38678","city_name":"Redondela","state_id":"3305"},
    {"city_id":"38679","city_name":"Salvaterra de Mino","state_id":"3305"},
    {"city_id":"38680","city_name":"Sanxenxo","state_id":"3305"},
    {"city_id":"38681","city_name":"Silleda","state_id":"3305"},
    {"city_id":"38682","city_name":"Tomino","state_id":"3305"},
    {"city_id":"38683","city_name":"Tui","state_id":"3305"},
    {"city_id":"38684","city_name":"Vigo","state_id":"3305"},
    {"city_id":"38685","city_name":"Vilagarcia de Arousa","state_id":"3305"},
    {"city_id":"38686","city_name":"Vilanova de Arousa","state_id":"3305"},
    {"city_id":"38687","city_name":"Bejar","state_id":"3306"},
    {"city_id":"38688","city_name":"Ciudad Rodrigo","state_id":"3306"},
    {"city_id":"38689","city_name":"Salamanca","state_id":"3306"},
    {"city_id":"38690","city_name":"Santa Marta de Tormes","state_id":"3306"},
    {"city_id":"38691","city_name":"Cuellar","state_id":"3308"},
    {"city_id":"38692","city_name":"Segovia","state_id":"3308"},
    {"city_id":"38693","city_name":"Alcala de Guadaira","state_id":"3309"},
    {"city_id":"38694","city_name":"Alcala del Rio","state_id":"3309"},
    {"city_id":"38695","city_name":"Arahal","state_id":"3309"},
    {"city_id":"38696","city_name":"Bormujos","state_id":"3309"},
    {"city_id":"38697","city_name":"Brenes","state_id":"3309"},
    {"city_id":"38698","city_name":"Camas","state_id":"3309"},
    {"city_id":"38699","city_name":"Cantillana","state_id":"3309"},
    {"city_id":"38700","city_name":"Carmona","state_id":"3309"},
    {"city_id":"38701","city_name":"Castilleja de la Cuesta","state_id":"3309"},
    {"city_id":"38702","city_name":"Coria del Rio","state_id":"3309"},
    {"city_id":"38703","city_name":"Dos Hermanas","state_id":"3309"},
    {"city_id":"38704","city_name":"Ecija","state_id":"3309"},
    {"city_id":"38705","city_name":"El Viso del Alcor","state_id":"3309"},
    {"city_id":"38706","city_name":"Estepa","state_id":"3309"},
    {"city_id":"38707","city_name":"Gines","state_id":"3309"},
    {"city_id":"38708","city_name":"Guillena","state_id":"3309"},
    {"city_id":"38709","city_name":"La Algaba","state_id":"3309"},
    {"city_id":"38710","city_name":"La Puebla de Cazalla","state_id":"3309"},
    {"city_id":"38711","city_name":"La Puebla del Rio","state_id":"3309"},
    {"city_id":"38712","city_name":"La Rinconada","state_id":"3309"},
    {"city_id":"38713","city_name":"Las Cabezas de San Juan","state_id":"3309"},
    {"city_id":"38714","city_name":"Lebrija","state_id":"3309"},
    {"city_id":"38715","city_name":"Lora del Rio","state_id":"3309"},
    {"city_id":"38716","city_name":"Los Palacios y Villafranca","state_id":"3309"},
    {"city_id":"38717","city_name":"Mairena del Alcor","state_id":"3309"},
    {"city_id":"38718","city_name":"Mairena del Aljarafe","state_id":"3309"},
    {"city_id":"38719","city_name":"Marchena","state_id":"3309"},
    {"city_id":"38720","city_name":"Moron de la Frontera","state_id":"3309"},
    {"city_id":"38721","city_name":"Olivares","state_id":"3309"},
    {"city_id":"38722","city_name":"Osuna","state_id":"3309"},
    {"city_id":"38723","city_name":"Pilas","state_id":"3309"},
    {"city_id":"38724","city_name":"San Juan de Aznalfarache","state_id":"3309"},
    {"city_id":"38725","city_name":"Sanlucar la Mayor","state_id":"3309"},
    {"city_id":"38726","city_name":"Sevilla","state_id":"3309"},
    {"city_id":"38727","city_name":"Tocina","state_id":"3309"},
    {"city_id":"38728","city_name":"Tomares","state_id":"3309"},
    {"city_id":"38729","city_name":"Utrera","state_id":"3309"},
    {"city_id":"38730","city_name":"Soria","state_id":"3310"},
    {"city_id":"38731","city_name":"Alcanar","state_id":"3311"},
    {"city_id":"38732","city_name":"Amposta","state_id":"3311"},
    {"city_id":"38733","city_name":"Calafell","state_id":"3311"},
    {"city_id":"38734","city_name":"Cambrils","state_id":"3311"},
    {"city_id":"38735","city_name":"Deltrebe","state_id":"3311"},
    {"city_id":"38736","city_name":"El Vendrell","state_id":"3311"},
    {"city_id":"38737","city_name":"Reus","state_id":"3311"},
    {"city_id":"38738","city_name":"Salou","state_id":"3311"},
    {"city_id":"38739","city_name":"Sant Carles de la Rapita","state_id":"3311"},
    {"city_id":"38740","city_name":"Tarragona","state_id":"3311"},
    {"city_id":"38741","city_name":"Torredembarra","state_id":"3311"},
    {"city_id":"38742","city_name":"Tortosa","state_id":"3311"},
    {"city_id":"38743","city_name":"Valls","state_id":"3311"},
    {"city_id":"38744","city_name":"Vila-seca","state_id":"3311"},
    {"city_id":"38745","city_name":"Tenerife","state_id":"3312"},
    {"city_id":"38746","city_name":"Alcaniz","state_id":"3313"},
    {"city_id":"38747","city_name":"Teruel","state_id":"3313"},
    {"city_id":"38748","city_name":"Consuegra","state_id":"3314"},
    {"city_id":"38749","city_name":"Illescas","state_id":"3314"},
    {"city_id":"38750","city_name":"Madridejos","state_id":"3314"},
    {"city_id":"38751","city_name":"Mora","state_id":"3314"},
    {"city_id":"38752","city_name":"Pepino","state_id":"3314"},
    {"city_id":"38753","city_name":"Quintanar de la Orden","state_id":"3314"},
    {"city_id":"38754","city_name":"Sonseca","state_id":"3314"},
    {"city_id":"38755","city_name":"Talavera de la Reina","state_id":"3314"},
    {"city_id":"38756","city_name":"Toledo","state_id":"3314"},
    {"city_id":"38757","city_name":"Torrijos","state_id":"3314"},
    {"city_id":"38758","city_name":"Villacanas","state_id":"3314"},
    {"city_id":"38759","city_name":"Agullent","state_id":"3315"},
    {"city_id":"38760","city_name":"Alaquas","state_id":"3315"},
    {"city_id":"38761","city_name":"Albal","state_id":"3315"},
    {"city_id":"38762","city_name":"Alberic","state_id":"3315"},
    {"city_id":"38763","city_name":"Alboraya","state_id":"3315"},
    {"city_id":"38764","city_name":"Aldaia","state_id":"3315"},
    {"city_id":"38765","city_name":"Alfafar","state_id":"3315"},
    {"city_id":"38766","city_name":"Algemesi","state_id":"3315"},
    {"city_id":"38767","city_name":"Alginet","state_id":"3315"},
    {"city_id":"38768","city_name":"Alzira","state_id":"3315"},
    {"city_id":"38769","city_name":"Benaguasil","state_id":"3315"},
    {"city_id":"38770","city_name":"Benetusser","state_id":"3315"},
    {"city_id":"38771","city_name":"Benifaio","state_id":"3315"},
    {"city_id":"38772","city_name":"Beniparrell","state_id":"3315"},
    {"city_id":"38773","city_name":"Betera","state_id":"3315"},
    {"city_id":"38774","city_name":"Bunol","state_id":"3315"},
    {"city_id":"38775","city_name":"Burjassot","state_id":"3315"},
    {"city_id":"38776","city_name":"Canals","state_id":"3315"},
    {"city_id":"38777","city_name":"Carcaixent","state_id":"3315"},
    {"city_id":"38778","city_name":"Carlet","state_id":"3315"},
    {"city_id":"38779","city_name":"Catarroja","state_id":"3315"},
    {"city_id":"38780","city_name":"Chiva","state_id":"3315"},
    {"city_id":"38781","city_name":"Cullera","state_id":"3315"},
    {"city_id":"38782","city_name":"Elche","state_id":"3315"},
    {"city_id":"38783","city_name":"Gandia","state_id":"3315"},
    {"city_id":"38784","city_name":"Godella","state_id":"3315"},
    {"city_id":"38785","city_name":"L'Alcudia","state_id":"3315"},
    {"city_id":"38786","city_name":"L'Eliana","state_id":"3315"},
    {"city_id":"38787","city_name":"La Pobla de Vallbona","state_id":"3315"},
    {"city_id":"38788","city_name":"Lliria","state_id":"3315"},
    {"city_id":"38789","city_name":"Los Montesinos","state_id":"3315"},
    {"city_id":"38790","city_name":"Manises","state_id":"3315"},
    {"city_id":"38791","city_name":"Massamagrell","state_id":"3315"},
    {"city_id":"38792","city_name":"Meliana","state_id":"3315"},
    {"city_id":"38793","city_name":"Mislata","state_id":"3315"},
    {"city_id":"38794","city_name":"Moncada","state_id":"3315"},
    {"city_id":"38795","city_name":"Oliva","state_id":"3315"},
    {"city_id":"38796","city_name":"Ontinyent","state_id":"3315"},
    {"city_id":"38797","city_name":"Paiporta","state_id":"3315"},
    {"city_id":"38798","city_name":"Paterna","state_id":"3315"},
    {"city_id":"38799","city_name":"Picanya","state_id":"3315"},
    {"city_id":"38800","city_name":"Picassent","state_id":"3315"},
    {"city_id":"38801","city_name":"Pucol","state_id":"3315"},
    {"city_id":"38802","city_name":"Quart de Poblet","state_id":"3315"},
    {"city_id":"38803","city_name":"Requena","state_id":"3315"},
    {"city_id":"38804","city_name":"Riba-roja de Turia","state_id":"3315"},
    {"city_id":"38805","city_name":"Sagunt","state_id":"3315"},
    {"city_id":"38806","city_name":"Sedavi","state_id":"3315"},
    {"city_id":"38807","city_name":"Silla","state_id":"3315"},
    {"city_id":"38808","city_name":"Sueca","state_id":"3315"},
    {"city_id":"38809","city_name":"Tavernes Blanques","state_id":"3315"},
    {"city_id":"38810","city_name":"Tavernes de la Valldigna","state_id":"3315"},
    {"city_id":"38811","city_name":"Torrent","state_id":"3315"},
    {"city_id":"38812","city_name":"Utiel","state_id":"3315"},
    {"city_id":"38813","city_name":"Valencia","state_id":"3315"},
    {"city_id":"38814","city_name":"Xativa","state_id":"3315"},
    {"city_id":"38815","city_name":"Xirivella","state_id":"3315"},
    {"city_id":"38816","city_name":"Laguna de Duero","state_id":"3316"},
    {"city_id":"38817","city_name":"Medina del Campo","state_id":"3316"},
    {"city_id":"38818","city_name":"Tordesillas","state_id":"3316"},
    {"city_id":"38819","city_name":"Valladolid","state_id":"3316"},
    {"city_id":"38820","city_name":"Abadiano","state_id":"3317"},
    {"city_id":"38821","city_name":"Abanto Zierbena","state_id":"3317"},
    {"city_id":"38822","city_name":"Amorebieta","state_id":"3317"},
    {"city_id":"38823","city_name":"Arrigorriaga","state_id":"3317"},
    {"city_id":"38824","city_name":"Barakaldo","state_id":"3317"},
    {"city_id":"38825","city_name":"Basauri","state_id":"3317"},
    {"city_id":"38826","city_name":"Bermeo","state_id":"3317"},
    {"city_id":"38827","city_name":"Berriz","state_id":"3317"},
    {"city_id":"38828","city_name":"Bilbao","state_id":"3317"},
    {"city_id":"38829","city_name":"Durango","state_id":"3317"},
    {"city_id":"38830","city_name":"Erandio","state_id":"3317"},
    {"city_id":"38831","city_name":"Ermua","state_id":"3317"},
    {"city_id":"38832","city_name":"Etxano","state_id":"3317"},
    {"city_id":"38833","city_name":"Galdakao","state_id":"3317"},
    {"city_id":"38834","city_name":"Gernika-Lumo","state_id":"3317"},
    {"city_id":"38835","city_name":"Getxo","state_id":"3317"},
    {"city_id":"38836","city_name":"Igorre","state_id":"3317"},
    {"city_id":"38837","city_name":"Leioa","state_id":"3317"},
    {"city_id":"38838","city_name":"Mungia","state_id":"3317"},
    {"city_id":"38839","city_name":"Ondarroa","state_id":"3317"},
    {"city_id":"38840","city_name":"Ortuella","state_id":"3317"},
    {"city_id":"38841","city_name":"Portugalete","state_id":"3317"},
    {"city_id":"38842","city_name":"Santurtzi","state_id":"3317"},
    {"city_id":"38843","city_name":"Sestao","state_id":"3317"},
    {"city_id":"38844","city_name":"Sopelana","state_id":"3317"},
    {"city_id":"38845","city_name":"Trapagaran","state_id":"3317"},
    {"city_id":"38846","city_name":"Zamudio","state_id":"3317"},
    {"city_id":"38847","city_name":"Benavente","state_id":"3318"},
    {"city_id":"38848","city_name":"Toro","state_id":"3318"},
    {"city_id":"38849","city_name":"Zamora","state_id":"3318"},
    {"city_id":"38850","city_name":"Calatayud","state_id":"3319"},
    {"city_id":"38851","city_name":"Ejea de los Caballeros","state_id":"3319"},
    {"city_id":"38852","city_name":"Tarazona","state_id":"3319"},
    {"city_id":"38853","city_name":"Utebo","state_id":"3319"},
    {"city_id":"38854","city_name":"Zaragoza","state_id":"3319"},
    {"city_id":"38855","city_name":"Amparai","state_id":"3320"},
    {"city_id":"38856","city_name":"Galmune","state_id":"3320"},
    {"city_id":"38857","city_name":"Anuradhapuraya","state_id":"3321"},
    {"city_id":"38858","city_name":"Badulla","state_id":"3322"},
    {"city_id":"38859","city_name":"Bandarawela","state_id":"3322"},
    {"city_id":"38860","city_name":"Haputale","state_id":"3322"},
    {"city_id":"38861","city_name":"Boralesgamuwa","state_id":"3323"},
    {"city_id":"38862","city_name":"Battaramulla","state_id":"3324"},
    {"city_id":"38863","city_name":"Colombo","state_id":"3324"},
    {"city_id":"38864","city_name":"Dehiwala-Mount Lavinia","state_id":"3324"},
    {"city_id":"38865","city_name":"Homagama","state_id":"3324"},
    {"city_id":"38866","city_name":"Jayawardanapura","state_id":"3324"},
    {"city_id":"38867","city_name":"Kolonnawa","state_id":"3324"},
    {"city_id":"38868","city_name":"Kotikawatta","state_id":"3324"},
    {"city_id":"38869","city_name":"Maharagama","state_id":"3324"},
    {"city_id":"38870","city_name":"Moratuwa","state_id":"3324"},
    {"city_id":"38871","city_name":"Mulleriyawa","state_id":"3324"},
    {"city_id":"38872","city_name":"Ratmalana","state_id":"3324"},
    {"city_id":"38873","city_name":"Seethawakapura","state_id":"3324"},
    {"city_id":"38874","city_name":"Sri Jayawardanapura","state_id":"3324"},
    {"city_id":"38875","city_name":"Ambalamgoda","state_id":"3325"},
    {"city_id":"38876","city_name":"Galla","state_id":"3325"},
    {"city_id":"38877","city_name":"Daluguma","state_id":"3326"},
    {"city_id":"38878","city_name":"Gampaha","state_id":"3326"},
    {"city_id":"38879","city_name":"Hendala","state_id":"3326"},
    {"city_id":"38880","city_name":"Jaela","state_id":"3326"},
    {"city_id":"38881","city_name":"Kandana","state_id":"3326"},
    {"city_id":"38882","city_name":"Katunayaka","state_id":"3326"},
    {"city_id":"38883","city_name":"Kelaniya","state_id":"3326"},
    {"city_id":"38884","city_name":"Migamuwa","state_id":"3326"},
    {"city_id":"38885","city_name":"Minuwangoda","state_id":"3326"},
    {"city_id":"38886","city_name":"Peliyagoda","state_id":"3326"},
    {"city_id":"38887","city_name":"Ragama","state_id":"3326"},
    {"city_id":"38888","city_name":"Wattala","state_id":"3326"},
    {"city_id":"38889","city_name":"Welesara","state_id":"3326"},
    {"city_id":"38890","city_name":"Hambantota","state_id":"3327"},
    {"city_id":"38891","city_name":"Tanggalla","state_id":"3327"},
    {"city_id":"38892","city_name":"Beruwala","state_id":"3328"},
    {"city_id":"38893","city_name":"Horana","state_id":"3328"},
    {"city_id":"38894","city_name":"Kalutara","state_id":"3328"},
    {"city_id":"38895","city_name":"Keselwatta","state_id":"3328"},
    {"city_id":"38896","city_name":"Panadraya","state_id":"3328"},
    {"city_id":"38897","city_name":"Kegalla","state_id":"3329"},
    {"city_id":"38898","city_name":"Kilinochchi","state_id":"3330"},
    {"city_id":"38899","city_name":"Kuliyapitiya","state_id":"3331"},
    {"city_id":"38900","city_name":"Kurunegala","state_id":"3331"},
    {"city_id":"38901","city_name":"Polgahawela","state_id":"3331"},
    {"city_id":"38902","city_name":"Eravur","state_id":"3332"},
    {"city_id":"38903","city_name":"Katankudi","state_id":"3332"},
    {"city_id":"38904","city_name":"Madakalpuwa","state_id":"3332"},
    {"city_id":"38905","city_name":"Malwana","state_id":"3334"},
    {"city_id":"38906","city_name":"Mannarama","state_id":"3335"},
    {"city_id":"38907","city_name":"Dambulla","state_id":"3336"},
    {"city_id":"38908","city_name":"Matale","state_id":"3336"},
    {"city_id":"38909","city_name":"Sigiriya","state_id":"3336"},
    {"city_id":"38910","city_name":"Matara","state_id":"3337"},
    {"city_id":"38911","city_name":"Weligama","state_id":"3337"},
    {"city_id":"38912","city_name":"Monaragala","state_id":"3338"},
    {"city_id":"38913","city_name":"Wattegama","state_id":"3338"},
    {"city_id":"38914","city_name":"Mullaitivu","state_id":"3339"},
    {"city_id":"38915","city_name":"Polonnaruwa","state_id":"3343"},
    {"city_id":"38916","city_name":"Halawata","state_id":"3344"},
    {"city_id":"38917","city_name":"Puttalama","state_id":"3344"},
    {"city_id":"38918","city_name":"Balangoda","state_id":"3345"},
    {"city_id":"38919","city_name":"Ratnapuraya","state_id":"3345"},
    {"city_id":"38920","city_name":"Tirikunamalaya","state_id":"3347"},
    {"city_id":"38921","city_name":"Querceta","state_id":"3348"},
    {"city_id":"38922","city_name":"Vavuniyawa","state_id":"3349"},
    {"city_id":"38923","city_name":"Chavakachcheri","state_id":"3351"},
    {"city_id":"38924","city_name":"Peduru Tuduwa","state_id":"3351"},
    {"city_id":"38925","city_name":"Valvedditturai","state_id":"3351"},
    {"city_id":"38926","city_name":"Yapanaya","state_id":"3351"},
    {"city_id":"38927","city_name":"Mahara","state_id":"3352"},
    {"city_id":"38928","city_name":"Kodok","state_id":"3353"},
    {"city_id":"38929","city_name":"Malakal","state_id":"3353"},
    {"city_id":"38930","city_name":"Nasir","state_id":"3353"},
    {"city_id":"38931","city_name":"ar-Rank","state_id":"3353"},
    {"city_id":"38932","city_name":"Juba","state_id":"3354"},
    {"city_id":"38933","city_name":"Yei","state_id":"3354"},
    {"city_id":"38934","city_name":"Maridi","state_id":"3359"},
    {"city_id":"38935","city_name":"Nzara","state_id":"3359"},
    {"city_id":"38936","city_name":"Tambura","state_id":"3359"},
    {"city_id":"38937","city_name":"Yambio","state_id":"3359"},
    {"city_id":"38938","city_name":"Bur","state_id":"3362"},
    {"city_id":"38939","city_name":"Aruma","state_id":"3363"},
    {"city_id":"38940","city_name":"Halfa al-Jadidah","state_id":"3363"},
    {"city_id":"38941","city_name":"Kassala","state_id":"3363"},
    {"city_id":"38942","city_name":"Khashm-al-Qirbah","state_id":"3363"},
    {"city_id":"38943","city_name":"Wagar","state_id":"3363"},
    {"city_id":"38944","city_name":"al-Masnah","state_id":"3363"},
    {"city_id":"38945","city_name":"'Atbarah","state_id":"3364"},
    {"city_id":"38946","city_name":"Barbar","state_id":"3364"},
    {"city_id":"38947","city_name":"Kabushiyah","state_id":"3364"},
    {"city_id":"38948","city_name":"Shandi","state_id":"3364"},
    {"city_id":"38949","city_name":"ad-Damir","state_id":"3364"},
    {"city_id":"38950","city_name":"al-Bawga","state_id":"3364"},
    {"city_id":"38951","city_name":"al-Matammah","state_id":"3364"},
    {"city_id":"38952","city_name":"Kapoeta","state_id":"3368"},
    {"city_id":"38953","city_name":"Torit","state_id":"3368"},
    {"city_id":"38954","city_name":"Abu Hijar","state_id":"3369"},
    {"city_id":"38955","city_name":"Galgani","state_id":"3369"},
    {"city_id":"38956","city_name":"Hajj 'Abdallah","state_id":"3369"},
    {"city_id":"38957","city_name":"Karkawj","state_id":"3369"},
    {"city_id":"38958","city_name":"Mayurnu","state_id":"3369"},
    {"city_id":"38959","city_name":"Sinjah","state_id":"3369"},
    {"city_id":"38960","city_name":"Sinnar","state_id":"3369"},
    {"city_id":"38961","city_name":"Sinnar-at-Tagatu","state_id":"3369"},
    {"city_id":"38962","city_name":"Umm Shawkah","state_id":"3369"},
    {"city_id":"38963","city_name":"ad-Dindar","state_id":"3369"},
    {"city_id":"38964","city_name":"as-Suki","state_id":"3369"},
    {"city_id":"38965","city_name":"Mashra ar-Raqq","state_id":"3370"},
    {"city_id":"38966","city_name":"Tonj","state_id":"3370"},
    {"city_id":"38967","city_name":"Warab","state_id":"3370"},
    {"city_id":"38968","city_name":"Bur Sudan","state_id":"3372"},
    {"city_id":"38969","city_name":"Gabait","state_id":"3372"},
    {"city_id":"38970","city_name":"Sawakin","state_id":"3372"},
    {"city_id":"38971","city_name":"Sinkat","state_id":"3372"},
    {"city_id":"38972","city_name":"Tawkar","state_id":"3372"},
    {"city_id":"38973","city_name":"Rumbik","state_id":"3373"},
    {"city_id":"38974","city_name":"Yirol","state_id":"3373"},
    {"city_id":"38975","city_name":"Abu 'Usar","state_id":"3374"},
    {"city_id":"38976","city_name":"Arba'atun wa 'Ishrun-al-Qurash","state_id":"3374"},
    {"city_id":"38977","city_name":"Maharaybah","state_id":"3374"},
    {"city_id":"38978","city_name":"Miallayab","state_id":"3374"},
    {"city_id":"38979","city_name":"Rufa'a","state_id":"3374"},
    {"city_id":"38980","city_name":"Sharafat","state_id":"3374"},
    {"city_id":"38981","city_name":"Tabat","state_id":"3374"},
    {"city_id":"38982","city_name":"Tambul","state_id":"3374"},
    {"city_id":"38983","city_name":"Wad Madani","state_id":"3374"},
    {"city_id":"38984","city_name":"Wad Rawah","state_id":"3374"},
    {"city_id":"38985","city_name":"al-Hawsh","state_id":"3374"},
    {"city_id":"38986","city_name":"al-Hilaliyah","state_id":"3374"},
    {"city_id":"38987","city_name":"al-Huda","state_id":"3374"},
    {"city_id":"38988","city_name":"al-Husayhisah","state_id":"3374"},
    {"city_id":"38989","city_name":"al-Kamilin","state_id":"3374"},
    {"city_id":"38990","city_name":"al-Karaymat","state_id":"3374"},
    {"city_id":"38991","city_name":"al-Manaqil","state_id":"3374"},
    {"city_id":"38992","city_name":"al-Mu'sadiyah","state_id":"3374"},
    {"city_id":"38993","city_name":"al-Musallamiyah","state_id":"3374"},
    {"city_id":"38994","city_name":"Umm Dawm","state_id":"3375"},
    {"city_id":"38995","city_name":"Umm Durman","state_id":"3375"},
    {"city_id":"38996","city_name":"al-Khartum","state_id":"3375"},
    {"city_id":"38997","city_name":"al-Khartum Bahri","state_id":"3375"},
    {"city_id":"38998","city_name":"Doka","state_id":"3376"},
    {"city_id":"38999","city_name":"Qala'-an-Nahl","state_id":"3376"},
    {"city_id":"39000","city_name":"Shuwak","state_id":"3376"},
    {"city_id":"39001","city_name":"al-Faw","state_id":"3376"},
    {"city_id":"39002","city_name":"al-Hawatah","state_id":"3376"},
    {"city_id":"39003","city_name":"al-Qadarif","state_id":"3376"},
    {"city_id":"39004","city_name":"Bentiu","state_id":"3377"},
    {"city_id":"39005","city_name":"Ler","state_id":"3377"},
    {"city_id":"39006","city_name":"Asalaya","state_id":"3378"},
    {"city_id":"39007","city_name":"Bufi","state_id":"3378"},
    {"city_id":"39008","city_name":"Gulli","state_id":"3378"},
    {"city_id":"39009","city_name":"Kinanah","state_id":"3378"},
    {"city_id":"39010","city_name":"Kusti","state_id":"3378"},
    {"city_id":"39011","city_name":"Marabba","state_id":"3378"},
    {"city_id":"39012","city_name":"Rabak","state_id":"3378"},
    {"city_id":"39013","city_name":"Shabashah","state_id":"3378"},
    {"city_id":"39014","city_name":"Tandalti","state_id":"3378"},
    {"city_id":"39015","city_name":"Umm Jarr","state_id":"3378"},
    {"city_id":"39016","city_name":"Wad-az-Zaki","state_id":"3378"},
    {"city_id":"39017","city_name":"ad-Duwaym","state_id":"3378"},
    {"city_id":"39018","city_name":"al-Jazirah","state_id":"3378"},
    {"city_id":"39019","city_name":"al-Kawah","state_id":"3378"},
    {"city_id":"39020","city_name":"al-Qutaynah","state_id":"3378"},
    {"city_id":"39021","city_name":"ad-Damazin","state_id":"3379"},
    {"city_id":"39022","city_name":"al-Kurumuk","state_id":"3379"},
    {"city_id":"39023","city_name":"al-Quwaysi","state_id":"3379"},
    {"city_id":"39024","city_name":"ar-Rusayris","state_id":"3379"},
    {"city_id":"39025","city_name":"Argu","state_id":"3380"},
    {"city_id":"39026","city_name":"Dalqu","state_id":"3380"},
    {"city_id":"39027","city_name":"Dunqulah","state_id":"3380"},
    {"city_id":"39028","city_name":"Kanisah","state_id":"3380"},
    {"city_id":"39029","city_name":"Karmah-an-Nuzul","state_id":"3380"},
    {"city_id":"39030","city_name":"Kuraymah","state_id":"3380"},
    {"city_id":"39031","city_name":"Kurti","state_id":"3380"},
    {"city_id":"39032","city_name":"Marawi","state_id":"3380"},
    {"city_id":"39033","city_name":"Tangasi-ar-Ru'ays","state_id":"3380"},
    {"city_id":"39034","city_name":"Wadi Halfa","state_id":"3380"},
    {"city_id":"39035","city_name":"ad-Dabbah","state_id":"3380"},
    {"city_id":"39036","city_name":"Brokopondo","state_id":"3381"},
    {"city_id":"39037","city_name":"Brownsweg","state_id":"3381"},
    {"city_id":"39038","city_name":"Marienburg","state_id":"3382"},
    {"city_id":"39039","city_name":"Meerzorg","state_id":"3382"},
    {"city_id":"39040","city_name":"Nieuw Amsterdam","state_id":"3382"},
    {"city_id":"39041","city_name":"Totness","state_id":"3383"},
    {"city_id":"39042","city_name":"Albina","state_id":"3384"},
    {"city_id":"39043","city_name":"Moengo","state_id":"3384"},
    {"city_id":"39044","city_name":"Nieuw Nickerie","state_id":"3385"},
    {"city_id":"39045","city_name":"Wageningen","state_id":"3385"},
    {"city_id":"39046","city_name":"Onverwacht","state_id":"3386"},
    {"city_id":"39047","city_name":"Paramaribo","state_id":"3387"},
    {"city_id":"39048","city_name":"Groningen","state_id":"3388"},
    {"city_id":"39049","city_name":"Lelydorp","state_id":"3389"},
    {"city_id":"39050","city_name":"Barentsburg","state_id":"3390"},
    {"city_id":"39051","city_name":"Hornsund","state_id":"3390"},
    {"city_id":"39052","city_name":"Isfjord Radio","state_id":"3390"},
    {"city_id":"39053","city_name":"Longyearbyen","state_id":"3390"},
    {"city_id":"39054","city_name":"Ny-Alesund","state_id":"3390"},
    {"city_id":"39055","city_name":"Bulembu","state_id":"3391"},
    {"city_id":"39056","city_name":"Ezulwini","state_id":"3391"},
    {"city_id":"39057","city_name":"Mbabane","state_id":"3391"},
    {"city_id":"39058","city_name":"Ngwenya","state_id":"3391"},
    {"city_id":"39059","city_name":"Pigg's Peak","state_id":"3391"},
    {"city_id":"39060","city_name":"Big Bend","state_id":"3392"},
    {"city_id":"39061","city_name":"Mhlume","state_id":"3392"},
    {"city_id":"39062","city_name":"Mpaka","state_id":"3392"},
    {"city_id":"39063","city_name":"Nsoko","state_id":"3392"},
    {"city_id":"39064","city_name":"Simunye","state_id":"3392"},
    {"city_id":"39065","city_name":"Siteki","state_id":"3392"},
    {"city_id":"39066","city_name":"Tshaneni","state_id":"3392"},
    {"city_id":"39067","city_name":"Bhunya","state_id":"3393"},
    {"city_id":"39068","city_name":"Kwaluseni","state_id":"3393"},
    {"city_id":"39069","city_name":"Lobamba","state_id":"3393"},
    {"city_id":"39070","city_name":"Malkerns","state_id":"3393"},
    {"city_id":"39071","city_name":"Mankayane","state_id":"3393"},
    {"city_id":"39072","city_name":"Manzini","state_id":"3393"},
    {"city_id":"39073","city_name":"Matsapha","state_id":"3393"},
    {"city_id":"39074","city_name":"Mhlambanyatsi","state_id":"3393"},
    {"city_id":"39075","city_name":"Sidvokodvo","state_id":"3393"},
    {"city_id":"39076","city_name":"Hlatikulu","state_id":"3394"},
    {"city_id":"39077","city_name":"Hluti","state_id":"3394"},
    {"city_id":"39078","city_name":"Kubuta","state_id":"3394"},
    {"city_id":"39079","city_name":"Lavumisa","state_id":"3394"},
    {"city_id":"39080","city_name":"Nhlangano","state_id":"3394"},
    {"city_id":"39081","city_name":"Karlshamn","state_id":"3397"},
    {"city_id":"39082","city_name":"Karlskrona","state_id":"3397"},
    {"city_id":"39083","city_name":"Ronneby","state_id":"3397"},
    {"city_id":"39084","city_name":"Stenungsund","state_id":"3398"},
    {"city_id":"39085","city_name":"Avesta","state_id":"3399"},
    {"city_id":"39086","city_name":"Borlange","state_id":"3399"},
    {"city_id":"39087","city_name":"Falun","state_id":"3399"},
    {"city_id":"39088","city_name":"Hedemora","state_id":"3399"},
    {"city_id":"39089","city_name":"Ludvika","state_id":"3399"},
    {"city_id":"39090","city_name":"Malung","state_id":"3399"},
    {"city_id":"39091","city_name":"Bollnas","state_id":"3400"},
    {"city_id":"39092","city_name":"Bro","state_id":"3400"},
    {"city_id":"39093","city_name":"Gavle","state_id":"3400"},
    {"city_id":"39094","city_name":"Hudiksvall","state_id":"3400"},
    {"city_id":"39095","city_name":"Sandviken","state_id":"3400"},
    {"city_id":"39096","city_name":"Soderhamn","state_id":"3400"},
    {"city_id":"39097","city_name":"Skara","state_id":"3402"},
    {"city_id":"39098","city_name":"Visby","state_id":"3402"},
    {"city_id":"39099","city_name":"Anderstorp","state_id":"3403"},
    {"city_id":"39100","city_name":"Falkenberg","state_id":"3403"},
    {"city_id":"39101","city_name":"Halmstad","state_id":"3403"},
    {"city_id":"39102","city_name":"Ullared","state_id":"3403"},
    {"city_id":"39103","city_name":"Varberg","state_id":"3403"},
    {"city_id":"39104","city_name":"Farjestaden","state_id":"3404"},
    {"city_id":"39105","city_name":"Ostersund","state_id":"3404"},
    {"city_id":"39106","city_name":"Gislaved","state_id":"3405"},
    {"city_id":"39107","city_name":"Jonkoping","state_id":"3405"},
    {"city_id":"39108","city_name":"Nassjo","state_id":"3405"},
    {"city_id":"39109","city_name":"Tranas","state_id":"3405"},
    {"city_id":"39110","city_name":"Varnamo","state_id":"3405"},
    {"city_id":"39111","city_name":"Vetlanda","state_id":"3405"},
    {"city_id":"39112","city_name":"Blomstermala","state_id":"3406"},
    {"city_id":"39113","city_name":"Kalmar","state_id":"3406"},
    {"city_id":"39114","city_name":"Nybro","state_id":"3406"},
    {"city_id":"39115","city_name":"Oskarshamn","state_id":"3406"},
    {"city_id":"39116","city_name":"Solna","state_id":"3406"},
    {"city_id":"39117","city_name":"Torsas","state_id":"3406"},
    {"city_id":"39118","city_name":"Vastervik","state_id":"3406"},
    {"city_id":"39119","city_name":"Habo","state_id":"3407"},
    {"city_id":"39120","city_name":"Limhamn","state_id":"3407"},
    {"city_id":"39121","city_name":"Segeltorp","state_id":"3407"},
    {"city_id":"39122","city_name":"Svedala","state_id":"3407"},
    {"city_id":"39123","city_name":"Ljungby","state_id":"3408"},
    {"city_id":"39124","city_name":"Vaxjo","state_id":"3408"},
    {"city_id":"39125","city_name":"Boden","state_id":"3409"},
    {"city_id":"39126","city_name":"Kiruna","state_id":"3409"},
    {"city_id":"39127","city_name":"Lulea","state_id":"3409"},
    {"city_id":"39128","city_name":"Pitea","state_id":"3409"},
    {"city_id":"39129","city_name":"Askersund","state_id":"3410"},
    {"city_id":"39130","city_name":"Karlskoga","state_id":"3410"},
    {"city_id":"39131","city_name":"Kumla","state_id":"3410"},
    {"city_id":"39132","city_name":"Orebro","state_id":"3410"},
    {"city_id":"39133","city_name":"Finspang","state_id":"3411"},
    {"city_id":"39134","city_name":"Follinge","state_id":"3411"},
    {"city_id":"39135","city_name":"Kisa","state_id":"3411"},
    {"city_id":"39136","city_name":"Linkoping","state_id":"3411"},
    {"city_id":"39137","city_name":"Mjolby","state_id":"3411"},
    {"city_id":"39138","city_name":"Motala","state_id":"3411"},
    {"city_id":"39139","city_name":"Norrkoping","state_id":"3411"},
    {"city_id":"39140","city_name":"Vadstena","state_id":"3411"},
    {"city_id":"39141","city_name":"Skarpovagen","state_id":"3412"},
    {"city_id":"39142","city_name":"Angelholm","state_id":"3413"},
    {"city_id":"39143","city_name":"Arlov","state_id":"3413"},
    {"city_id":"39144","city_name":"Bastad","state_id":"3413"},
    {"city_id":"39145","city_name":"Eslov","state_id":"3413"},
    {"city_id":"39146","city_name":"Hassleholm","state_id":"3413"},
    {"city_id":"39147","city_name":"Helsingborg","state_id":"3413"},
    {"city_id":"39148","city_name":"Hjarup","state_id":"3413"},
    {"city_id":"39149","city_name":"Hoganas","state_id":"3413"},
    {"city_id":"39150","city_name":"Horby","state_id":"3413"},
    {"city_id":"39151","city_name":"Jonstorp","state_id":"3413"},
    {"city_id":"39152","city_name":"Klagstorp","state_id":"3413"},
    {"city_id":"39153","city_name":"Kristianstad","state_id":"3413"},
    {"city_id":"39154","city_name":"Landskrona","state_id":"3413"},
    {"city_id":"39155","city_name":"Lund","state_id":"3413"},
    {"city_id":"39156","city_name":"Malmo","state_id":"3413"},
    {"city_id":"39157","city_name":"Skanor","state_id":"3413"},
    {"city_id":"39158","city_name":"Staffanstorp","state_id":"3413"},
    {"city_id":"39159","city_name":"Trelleborg","state_id":"3413"},
    {"city_id":"39160","city_name":"Ystad","state_id":"3413"},
    {"city_id":"39161","city_name":"Hillerstorp","state_id":"3414"},
    {"city_id":"39162","city_name":"Markaryd","state_id":"3414"},
    {"city_id":"39163","city_name":"Smalandsstenar","state_id":"3414"},
    {"city_id":"39164","city_name":"Eskilstuna","state_id":"3415"},
    {"city_id":"39165","city_name":"Gnesta","state_id":"3415"},
    {"city_id":"39166","city_name":"Katrineholm","state_id":"3415"},
    {"city_id":"39167","city_name":"Nacka Strand","state_id":"3415"},
    {"city_id":"39168","city_name":"Nykoping","state_id":"3415"},
    {"city_id":"39169","city_name":"Strangnas","state_id":"3415"},
    {"city_id":"39170","city_name":"Vingaker","state_id":"3415"},
    {"city_id":"39171","city_name":"Akersberga","state_id":"3416"},
    {"city_id":"39172","city_name":"Boo","state_id":"3416"},
    {"city_id":"39173","city_name":"Bromma","state_id":"3416"},
    {"city_id":"39174","city_name":"Djursholm","state_id":"3416"},
    {"city_id":"39175","city_name":"Hallstavik","state_id":"3416"},
    {"city_id":"39176","city_name":"Jarfalla","state_id":"3416"},
    {"city_id":"39177","city_name":"Johannesfred","state_id":"3416"},
    {"city_id":"39178","city_name":"Lidingo","state_id":"3416"},
    {"city_id":"39179","city_name":"Marsta","state_id":"3416"},
    {"city_id":"39180","city_name":"Norrtalje","state_id":"3416"},
    {"city_id":"39181","city_name":"Norsborg","state_id":"3416"},
    {"city_id":"39182","city_name":"Nynashamn","state_id":"3416"},
    {"city_id":"39183","city_name":"Rosersberg","state_id":"3416"},
    {"city_id":"39184","city_name":"Sodertalje","state_id":"3416"},
    {"city_id":"39185","city_name":"Sollentuna","state_id":"3416"},
    {"city_id":"39186","city_name":"Stockholm","state_id":"3416"},
    {"city_id":"39187","city_name":"Sundbyberg","state_id":"3416"},
    {"city_id":"39188","city_name":"Taby","state_id":"3416"},
    {"city_id":"39189","city_name":"Tumba","state_id":"3416"},
    {"city_id":"39190","city_name":"Upplands-Vasby","state_id":"3416"},
    {"city_id":"39191","city_name":"Vallentuna","state_id":"3416"},
    {"city_id":"39192","city_name":"Vasterhaninge","state_id":"3416"},
    {"city_id":"39193","city_name":"Balsta","state_id":"3417"},
    {"city_id":"39194","city_name":"Enkoping","state_id":"3417"},
    {"city_id":"39195","city_name":"Knivsta","state_id":"3417"},
    {"city_id":"39196","city_name":"Skyttorp","state_id":"3417"},
    {"city_id":"39197","city_name":"Storvreta","state_id":"3417"},
    {"city_id":"39198","city_name":"Uppsala","state_id":"3417"},
    {"city_id":"39199","city_name":"Arvika","state_id":"3418"},
    {"city_id":"39200","city_name":"Hammaro","state_id":"3418"},
    {"city_id":"39201","city_name":"Karlstad","state_id":"3418"},
    {"city_id":"39202","city_name":"Kristinehamn","state_id":"3418"},
    {"city_id":"39203","city_name":"Skoghall","state_id":"3418"},
    {"city_id":"39204","city_name":"Guglingen","state_id":"3419"},
    {"city_id":"39205","city_name":"Robertsfors","state_id":"3419"},
    {"city_id":"39206","city_name":"Skelleftea","state_id":"3419"},
    {"city_id":"39207","city_name":"Umea","state_id":"3419"},
    {"city_id":"39208","city_name":"Gothenburg","state_id":"3420"},
    {"city_id":"39209","city_name":"Kallered","state_id":"3420"},
    {"city_id":"39210","city_name":"Kvanum","state_id":"3420"},
    {"city_id":"39211","city_name":"Timmersdala","state_id":"3420"},
    {"city_id":"39212","city_name":"Harnosand","state_id":"3421"},
    {"city_id":"39213","city_name":"Ornskoldsvik","state_id":"3421"},
    {"city_id":"39214","city_name":"Sundsvall","state_id":"3421"},
    {"city_id":"39215","city_name":"Arboga","state_id":"3422"},
    {"city_id":"39216","city_name":"Fagersta","state_id":"3422"},
    {"city_id":"39217","city_name":"Hallstahammar","state_id":"3422"},
    {"city_id":"39218","city_name":"Koping","state_id":"3422"},
    {"city_id":"39219","city_name":"Sala","state_id":"3422"},
    {"city_id":"39220","city_name":"Vasteras","state_id":"3422"},
    {"city_id":"39221","city_name":"Aarau","state_id":"3424"},
    {"city_id":"39222","city_name":"Aarburg","state_id":"3424"},
    {"city_id":"39223","city_name":"Baden","state_id":"3424"},
    {"city_id":"39224","city_name":"Bevilard","state_id":"3424"},
    {"city_id":"39225","city_name":"Bremgarten","state_id":"3424"},
    {"city_id":"39226","city_name":"Brugg","state_id":"3424"},
    {"city_id":"39227","city_name":"Buchs","state_id":"3424"},
    {"city_id":"39228","city_name":"Chevenez","state_id":"3424"},
    {"city_id":"39229","city_name":"Fislisbach","state_id":"3424"},
    {"city_id":"39230","city_name":"Granichen","state_id":"3424"},
    {"city_id":"39231","city_name":"Hagglingen","state_id":"3424"},
    {"city_id":"39232","city_name":"Kaiseraugst","state_id":"3424"},
    {"city_id":"39233","city_name":"Kleindoettingen","state_id":"3424"},
    {"city_id":"39234","city_name":"Klingnau","state_id":"3424"},
    {"city_id":"39235","city_name":"Kuttigen","state_id":"3424"},
    {"city_id":"39236","city_name":"Lenzburg","state_id":"3424"},
    {"city_id":"39237","city_name":"Menziken","state_id":"3424"},
    {"city_id":"39238","city_name":"Mohlin","state_id":"3424"},
    {"city_id":"39239","city_name":"Mumpf","state_id":"3424"},
    {"city_id":"39240","city_name":"Muri","state_id":"3424"},
    {"city_id":"39241","city_name":"Neuenhof","state_id":"3424"},
    {"city_id":"39242","city_name":"Niederlenz","state_id":"3424"},
    {"city_id":"39243","city_name":"Niederwangen","state_id":"3424"},
    {"city_id":"39244","city_name":"Niederwil","state_id":"3424"},
    {"city_id":"39245","city_name":"Oberentfelden","state_id":"3424"},
    {"city_id":"39246","city_name":"Obersiggenthal","state_id":"3424"},
    {"city_id":"39247","city_name":"Oftringen","state_id":"3424"},
    {"city_id":"39248","city_name":"Reinach","state_id":"3424"},
    {"city_id":"39249","city_name":"Rheinfelden","state_id":"3424"},
    {"city_id":"39250","city_name":"Rosshausern","state_id":"3424"},
    {"city_id":"39251","city_name":"Rothrist","state_id":"3424"},
    {"city_id":"39252","city_name":"Rupperswil","state_id":"3424"},
    {"city_id":"39253","city_name":"Schmitten","state_id":"3424"},
    {"city_id":"39254","city_name":"Seon","state_id":"3424"},
    {"city_id":"39255","city_name":"Spreitenbach","state_id":"3424"},
    {"city_id":"39256","city_name":"Stein","state_id":"3424"},
    {"city_id":"39257","city_name":"Suhr","state_id":"3424"},
    {"city_id":"39258","city_name":"Trogen","state_id":"3424"},
    {"city_id":"39259","city_name":"Untersiggenthal","state_id":"3424"},
    {"city_id":"39260","city_name":"Villmergen","state_id":"3424"},
    {"city_id":"39261","city_name":"Wettingen","state_id":"3424"},
    {"city_id":"39262","city_name":"Windisch","state_id":"3424"},
    {"city_id":"39263","city_name":"Wohlen","state_id":"3424"},
    {"city_id":"39264","city_name":"Wurenlos","state_id":"3424"},
    {"city_id":"39265","city_name":"Zofingen","state_id":"3424"},
    {"city_id":"39266","city_name":"Aesch","state_id":"3427"},
    {"city_id":"39267","city_name":"Allschwil","state_id":"3427"},
    {"city_id":"39268","city_name":"Arlesheim","state_id":"3427"},
    {"city_id":"39269","city_name":"Binningen","state_id":"3427"},
    {"city_id":"39270","city_name":"Birsfelden","state_id":"3427"},
    {"city_id":"39271","city_name":"Bottmingen","state_id":"3427"},
    {"city_id":"39272","city_name":"Ettingen","state_id":"3427"},
    {"city_id":"39273","city_name":"Frenkendorf","state_id":"3427"},
    {"city_id":"39274","city_name":"Gelterkinden","state_id":"3427"},
    {"city_id":"39275","city_name":"Laufen","state_id":"3427"},
    {"city_id":"39276","city_name":"Lausen","state_id":"3427"},
    {"city_id":"39277","city_name":"Liestal","state_id":"3427"},
    {"city_id":"39278","city_name":"Munchenstein","state_id":"3427"},
    {"city_id":"39279","city_name":"Muttenz","state_id":"3427"},
    {"city_id":"39280","city_name":"Oberwil","state_id":"3427"},
    {"city_id":"39281","city_name":"Pratteln","state_id":"3427"},
    {"city_id":"39282","city_name":"Reinach","state_id":"3427"},
    {"city_id":"39283","city_name":"Sissach","state_id":"3427"},
    {"city_id":"39284","city_name":"Therwil","state_id":"3427"},
    {"city_id":"39285","city_name":"Basel","state_id":"3428"},
    {"city_id":"39286","city_name":"Riehen","state_id":"3428"},
    {"city_id":"39287","city_name":"Belp","state_id":"3429"},
    {"city_id":"39288","city_name":"Bern","state_id":"3429"},
    {"city_id":"39289","city_name":"Berne","state_id":"3429"},
    {"city_id":"39290","city_name":"Biel","state_id":"3429"},
    {"city_id":"39291","city_name":"Biel-Bienne","state_id":"3429"},
    {"city_id":"39292","city_name":"Bolligen","state_id":"3429"},
    {"city_id":"39293","city_name":"Burgdorf","state_id":"3429"},
    {"city_id":"39294","city_name":"Frutigen","state_id":"3429"},
    {"city_id":"39295","city_name":"Heimberg","state_id":"3429"},
    {"city_id":"39296","city_name":"Herzogenbuchsee","state_id":"3429"},
    {"city_id":"39297","city_name":"Huttwil","state_id":"3429"},
    {"city_id":"39298","city_name":"Interlaken","state_id":"3429"},
    {"city_id":"39299","city_name":"Ittigen","state_id":"3429"},
    {"city_id":"39300","city_name":"Kirchberg","state_id":"3429"},
    {"city_id":"39301","city_name":"Koniz","state_id":"3429"},
    {"city_id":"39302","city_name":"Konolfingen","state_id":"3429"},
    {"city_id":"39303","city_name":"Langenthal","state_id":"3429"},
    {"city_id":"39304","city_name":"Langnau","state_id":"3429"},
    {"city_id":"39305","city_name":"Lengnau","state_id":"3429"},
    {"city_id":"39306","city_name":"Lyss","state_id":"3429"},
    {"city_id":"39307","city_name":"Meiringen","state_id":"3429"},
    {"city_id":"39308","city_name":"Moutier","state_id":"3429"},
    {"city_id":"39309","city_name":"Munchenbuchsee","state_id":"3429"},
    {"city_id":"39310","city_name":"Munsingen","state_id":"3429"},
    {"city_id":"39311","city_name":"Muri","state_id":"3429"},
    {"city_id":"39312","city_name":"Nidau","state_id":"3429"},
    {"city_id":"39313","city_name":"Orpund","state_id":"3429"},
    {"city_id":"39314","city_name":"Ostermundingen","state_id":"3429"},
    {"city_id":"39315","city_name":"Pieterlen","state_id":"3429"},
    {"city_id":"39316","city_name":"Saanen","state_id":"3429"},
    {"city_id":"39317","city_name":"Saint-Imier","state_id":"3429"},
    {"city_id":"39318","city_name":"Sonceboz","state_id":"3429"},
    {"city_id":"39319","city_name":"Spiez","state_id":"3429"},
    {"city_id":"39320","city_name":"Steffisburg","state_id":"3429"},
    {"city_id":"39321","city_name":"Sumiswald","state_id":"3429"},
    {"city_id":"39322","city_name":"Thun","state_id":"3429"},
    {"city_id":"39323","city_name":"Uetendorf","state_id":"3429"},
    {"city_id":"39324","city_name":"Unterseen","state_id":"3429"},
    {"city_id":"39325","city_name":"Urtenen","state_id":"3429"},
    {"city_id":"39326","city_name":"Vechingen","state_id":"3429"},
    {"city_id":"39327","city_name":"Wahlern","state_id":"3429"},
    {"city_id":"39328","city_name":"Wohlen","state_id":"3429"},
    {"city_id":"39329","city_name":"Worb","state_id":"3429"},
    {"city_id":"39330","city_name":"Zollikofen","state_id":"3429"},
    {"city_id":"39331","city_name":"Bulle","state_id":"3431"},
    {"city_id":"39332","city_name":"Dudingen","state_id":"3431"},
    {"city_id":"39333","city_name":"Fribourg","state_id":"3431"},
    {"city_id":"39334","city_name":"Granges-Paccot","state_id":"3431"},
    {"city_id":"39335","city_name":"Marly","state_id":"3431"},
    {"city_id":"39336","city_name":"Muntelier","state_id":"3431"},
    {"city_id":"39337","city_name":"Murten","state_id":"3431"},
    {"city_id":"39338","city_name":"Remaufens","state_id":"3431"},
    {"city_id":"39339","city_name":"Villars-sur-Glane","state_id":"3431"},
    {"city_id":"39340","city_name":"Villaz-Saint-Pierre","state_id":"3431"},
    {"city_id":"39341","city_name":"Wunnewil-Flamatt","state_id":"3431"},
    {"city_id":"39342","city_name":"Bernex","state_id":"3432"},
    {"city_id":"39343","city_name":"Carouge","state_id":"3432"},
    {"city_id":"39344","city_name":"Chene-Bougeries","state_id":"3432"},
    {"city_id":"39345","city_name":"Chene-Bourg","state_id":"3432"},
    {"city_id":"39346","city_name":"Collonge-Bellerive","state_id":"3432"},
    {"city_id":"39347","city_name":"Cologny","state_id":"3432"},
    {"city_id":"39348","city_name":"Geneva","state_id":"3432"},
    {"city_id":"39349","city_name":"Geneve","state_id":"3432"},
    {"city_id":"39350","city_name":"Lancy","state_id":"3432"},
    {"city_id":"39351","city_name":"Le Grand-Saconnex","state_id":"3432"},
    {"city_id":"39352","city_name":"Meyrin","state_id":"3432"},
    {"city_id":"39353","city_name":"Onex","state_id":"3432"},
    {"city_id":"39354","city_name":"Plan-les-Ouates","state_id":"3432"},
    {"city_id":"39355","city_name":"Thonex","state_id":"3432"},
    {"city_id":"39356","city_name":"Vernier","state_id":"3432"},
    {"city_id":"39357","city_name":"Versoix","state_id":"3432"},
    {"city_id":"39358","city_name":"Veyrier","state_id":"3432"},
    {"city_id":"39359","city_name":"Glarus","state_id":"3433"},
    {"city_id":"39360","city_name":"Nafels","state_id":"3433"},
    {"city_id":"39361","city_name":"Arosa","state_id":"3434"},
    {"city_id":"39362","city_name":"Chur","state_id":"3434"},
    {"city_id":"39363","city_name":"Davos","state_id":"3434"},
    {"city_id":"39364","city_name":"Domat","state_id":"3434"},
    {"city_id":"39365","city_name":"Igis","state_id":"3434"},
    {"city_id":"39366","city_name":"Landquart","state_id":"3434"},
    {"city_id":"39367","city_name":"Maienfeld","state_id":"3434"},
    {"city_id":"39368","city_name":"Sankt Moritz","state_id":"3434"},
    {"city_id":"39369","city_name":"Heerbrugg","state_id":"3435"},
    {"city_id":"39370","city_name":"Delemont","state_id":"3436"},
    {"city_id":"39371","city_name":"Glovelier","state_id":"3436"},
    {"city_id":"39372","city_name":"Le Noirmont","state_id":"3436"},
    {"city_id":"39373","city_name":"Porrentruy","state_id":"3436"},
    {"city_id":"39374","city_name":"Adligenswil","state_id":"3438"},
    {"city_id":"39375","city_name":"Ballwil","state_id":"3438"},
    {"city_id":"39376","city_name":"Buchrain","state_id":"3438"},
    {"city_id":"39377","city_name":"Ebikon","state_id":"3438"},
    {"city_id":"39378","city_name":"Emmen","state_id":"3438"},
    {"city_id":"39379","city_name":"Hochdorf","state_id":"3438"},
    {"city_id":"39380","city_name":"Horw","state_id":"3438"},
    {"city_id":"39381","city_name":"Kriens","state_id":"3438"},
    {"city_id":"39382","city_name":"Littau","state_id":"3438"},
    {"city_id":"39383","city_name":"Luzern","state_id":"3438"},
    {"city_id":"39384","city_name":"Malters","state_id":"3438"},
    {"city_id":"39385","city_name":"Meggen","state_id":"3438"},
    {"city_id":"39386","city_name":"Neuenkirch","state_id":"3438"},
    {"city_id":"39387","city_name":"Perlern","state_id":"3438"},
    {"city_id":"39388","city_name":"Rothenburg","state_id":"3438"},
    {"city_id":"39389","city_name":"Ruswil","state_id":"3438"},
    {"city_id":"39390","city_name":"Sursee","state_id":"3438"},
    {"city_id":"39391","city_name":"Muhen","state_id":"3440"},
    {"city_id":"39392","city_name":"Bole","state_id":"3441"},
    {"city_id":"39393","city_name":"Boudry","state_id":"3441"},
    {"city_id":"39394","city_name":"Colombier","state_id":"3441"},
    {"city_id":"39395","city_name":"La Chaux-de-Fonds","state_id":"3441"},
    {"city_id":"39396","city_name":"Le Locle","state_id":"3441"},
    {"city_id":"39397","city_name":"Neuchatel","state_id":"3441"},
    {"city_id":"39398","city_name":"Peseux","state_id":"3441"},
    {"city_id":"39399","city_name":"Buochs","state_id":"3442"},
    {"city_id":"39400","city_name":"Hergiswil","state_id":"3442"},
    {"city_id":"39401","city_name":"Stans","state_id":"3442"},
    {"city_id":"39402","city_name":"Stansstad","state_id":"3442"},
    {"city_id":"39403","city_name":"Alpnach","state_id":"3443"},
    {"city_id":"39404","city_name":"Kerns","state_id":"3443"},
    {"city_id":"39405","city_name":"Sachseln","state_id":"3443"},
    {"city_id":"39406","city_name":"Sarnen","state_id":"3443"},
    {"city_id":"39407","city_name":"Neuhausen","state_id":"3445"},
    {"city_id":"39408","city_name":"Schaffhausen","state_id":"3445"},
    {"city_id":"39409","city_name":"Altendorf","state_id":"3446"},
    {"city_id":"39410","city_name":"Arth","state_id":"3446"},
    {"city_id":"39411","city_name":"Buron","state_id":"3446"},
    {"city_id":"39412","city_name":"Einsiedeln","state_id":"3446"},
    {"city_id":"39413","city_name":"Feusisberg","state_id":"3446"},
    {"city_id":"39414","city_name":"Freienbach","state_id":"3446"},
    {"city_id":"39415","city_name":"Ingenbohl","state_id":"3446"},
    {"city_id":"39416","city_name":"Kussnacht","state_id":"3446"},
    {"city_id":"39417","city_name":"Lachen","state_id":"3446"},
    {"city_id":"39418","city_name":"Schubelbach","state_id":"3446"},
    {"city_id":"39419","city_name":"Schwyz","state_id":"3446"},
    {"city_id":"39420","city_name":"Wangen","state_id":"3446"},
    {"city_id":"39421","city_name":"Wollerau","state_id":"3446"},
    {"city_id":"39422","city_name":"Balsthal","state_id":"3447"},
    {"city_id":"39423","city_name":"Bellach","state_id":"3447"},
    {"city_id":"39424","city_name":"Bettlach","state_id":"3447"},
    {"city_id":"39425","city_name":"Biberist","state_id":"3447"},
    {"city_id":"39426","city_name":"Derendingen","state_id":"3447"},
    {"city_id":"39427","city_name":"Dornach","state_id":"3447"},
    {"city_id":"39428","city_name":"Dulliken","state_id":"3447"},
    {"city_id":"39429","city_name":"Feldbrunnen","state_id":"3447"},
    {"city_id":"39430","city_name":"Gerlafingen","state_id":"3447"},
    {"city_id":"39431","city_name":"Grenchen","state_id":"3447"},
    {"city_id":"39432","city_name":"Hagendorf","state_id":"3447"},
    {"city_id":"39433","city_name":"Olten","state_id":"3447"},
    {"city_id":"39434","city_name":"Schonenwerd","state_id":"3447"},
    {"city_id":"39435","city_name":"Solothurn","state_id":"3447"},
    {"city_id":"39436","city_name":"Trimbach","state_id":"3447"},
    {"city_id":"39437","city_name":"Wangen","state_id":"3447"},
    {"city_id":"39438","city_name":"Zuchwil","state_id":"3447"},
    {"city_id":"39439","city_name":"Aadorf","state_id":"3448"},
    {"city_id":"39440","city_name":"Amriswil","state_id":"3448"},
    {"city_id":"39441","city_name":"Arbon","state_id":"3448"},
    {"city_id":"39442","city_name":"Bischofszell","state_id":"3448"},
    {"city_id":"39443","city_name":"Felben-Wellhausen","state_id":"3448"},
    {"city_id":"39444","city_name":"Frauenfeld","state_id":"3448"},
    {"city_id":"39445","city_name":"Guttingen","state_id":"3448"},
    {"city_id":"39446","city_name":"Kreuzlingen","state_id":"3448"},
    {"city_id":"39447","city_name":"Romanshorn","state_id":"3448"},
    {"city_id":"39448","city_name":"Sirnach","state_id":"3448"},
    {"city_id":"39449","city_name":"Sulgen","state_id":"3448"},
    {"city_id":"39450","city_name":"Tagerwilen","state_id":"3448"},
    {"city_id":"39451","city_name":"Weinfelden","state_id":"3448"},
    {"city_id":"39452","city_name":"Ascona","state_id":"3449"},
    {"city_id":"39453","city_name":"Bellinzona","state_id":"3449"},
    {"city_id":"39454","city_name":"Biasca","state_id":"3449"},
    {"city_id":"39455","city_name":"Breganzona","state_id":"3449"},
    {"city_id":"39456","city_name":"Cadempino","state_id":"3449"},
    {"city_id":"39457","city_name":"Chiasso","state_id":"3449"},
    {"city_id":"39458","city_name":"Giubiasco","state_id":"3449"},
    {"city_id":"39459","city_name":"Locarno","state_id":"3449"},
    {"city_id":"39460","city_name":"Losone","state_id":"3449"},
    {"city_id":"39461","city_name":"Lugano","state_id":"3449"},
    {"city_id":"39462","city_name":"Massagno","state_id":"3449"},
    {"city_id":"39463","city_name":"Melide","state_id":"3449"},
    {"city_id":"39464","city_name":"Mendrisio","state_id":"3449"},
    {"city_id":"39465","city_name":"Mezzovico","state_id":"3449"},
    {"city_id":"39466","city_name":"Minusio","state_id":"3449"},
    {"city_id":"39467","city_name":"Porza","state_id":"3449"},
    {"city_id":"39468","city_name":"Pregassona","state_id":"3449"},
    {"city_id":"39469","city_name":"Viganello","state_id":"3449"},
    {"city_id":"39470","city_name":"Altdorf","state_id":"3450"},
    {"city_id":"39471","city_name":"Schattdorf","state_id":"3450"},
    {"city_id":"39472","city_name":"Bagnes","state_id":"3451"},
    {"city_id":"39473","city_name":"Brig-Glis","state_id":"3451"},
    {"city_id":"39474","city_name":"Collombey-Muraz","state_id":"3451"},
    {"city_id":"39475","city_name":"Conthey","state_id":"3451"},
    {"city_id":"39476","city_name":"Evionnaz","state_id":"3451"},
    {"city_id":"39477","city_name":"Fully","state_id":"3451"},
    {"city_id":"39478","city_name":"Martigny","state_id":"3451"},
    {"city_id":"39479","city_name":"Monthey","state_id":"3451"},
    {"city_id":"39480","city_name":"Naters","state_id":"3451"},
    {"city_id":"39481","city_name":"Nendaz","state_id":"3451"},
    {"city_id":"39482","city_name":"Niederwald","state_id":"3451"},
    {"city_id":"39483","city_name":"Saviese","state_id":"3451"},
    {"city_id":"39484","city_name":"Sierre","state_id":"3451"},
    {"city_id":"39485","city_name":"Sion","state_id":"3451"},
    {"city_id":"39486","city_name":"Visp","state_id":"3451"},
    {"city_id":"39487","city_name":"Vouvry","state_id":"3451"},
    {"city_id":"39488","city_name":"Zermatt","state_id":"3451"},
    {"city_id":"39489","city_name":"Aigle","state_id":"3452"},
    {"city_id":"39490","city_name":"Ballaigues","state_id":"3452"},
    {"city_id":"39491","city_name":"Bex","state_id":"3452"},
    {"city_id":"39492","city_name":"Blonay","state_id":"3452"},
    {"city_id":"39493","city_name":"Bussigny","state_id":"3452"},
    {"city_id":"39494","city_name":"Chavannes","state_id":"3452"},
    {"city_id":"39495","city_name":"Crissier","state_id":"3452"},
    {"city_id":"39496","city_name":"Ecublens","state_id":"3452"},
    {"city_id":"39497","city_name":"Epalinges","state_id":"3452"},
    {"city_id":"39498","city_name":"Gland","state_id":"3452"},
    {"city_id":"39499","city_name":"La Tour-de-Peilz","state_id":"3452"},
    {"city_id":"39500","city_name":"Lausanne","state_id":"3452"},
    {"city_id":"39501","city_name":"Le Mont-sur-Lausanne","state_id":"3452"},
    {"city_id":"39502","city_name":"Lucerne","state_id":"3452"},
    {"city_id":"39503","city_name":"Lutry","state_id":"3452"},
    {"city_id":"39504","city_name":"Montreux","state_id":"3452"},
    {"city_id":"39505","city_name":"Morges","state_id":"3452"},
    {"city_id":"39506","city_name":"Nyon","state_id":"3452"},
    {"city_id":"39507","city_name":"Ollon","state_id":"3452"},
    {"city_id":"39508","city_name":"Orbe","state_id":"3452"},
    {"city_id":"39509","city_name":"Payerne","state_id":"3452"},
    {"city_id":"39510","city_name":"Prilly","state_id":"3452"},
    {"city_id":"39511","city_name":"Puidoux","state_id":"3452"},
    {"city_id":"39512","city_name":"Pully","state_id":"3452"},
    {"city_id":"39513","city_name":"Renens","state_id":"3452"},
    {"city_id":"39514","city_name":"Romainmotier","state_id":"3452"},
    {"city_id":"39515","city_name":"Saint Sulpice","state_id":"3452"},
    {"city_id":"39516","city_name":"Vevey","state_id":"3452"},
    {"city_id":"39517","city_name":"Yverdon","state_id":"3452"},
    {"city_id":"39518","city_name":"Vauffelin","state_id":"3453"},
    {"city_id":"39519","city_name":"Baar","state_id":"3454"},
    {"city_id":"39520","city_name":"Cham","state_id":"3454"},
    {"city_id":"39521","city_name":"Hunenberg","state_id":"3454"},
    {"city_id":"39522","city_name":"Menzingen","state_id":"3454"},
    {"city_id":"39523","city_name":"Neuheim","state_id":"3454"},
    {"city_id":"39524","city_name":"Oberageri","state_id":"3454"},
    {"city_id":"39525","city_name":"Risch","state_id":"3454"},
    {"city_id":"39526","city_name":"Risch-Rotkreuz","state_id":"3454"},
    {"city_id":"39527","city_name":"Steinhausen","state_id":"3454"},
    {"city_id":"39528","city_name":"Unterageri","state_id":"3454"},
    {"city_id":"39529","city_name":"Zug","state_id":"3454"},
    {"city_id":"39530","city_name":"Adliswil","state_id":"3455"},
    {"city_id":"39531","city_name":"Affoltern","state_id":"3455"},
    {"city_id":"39532","city_name":"Bachenbulach","state_id":"3455"},
    {"city_id":"39533","city_name":"Bassersdorf","state_id":"3455"},
    {"city_id":"39534","city_name":"Birmensdorf","state_id":"3455"},
    {"city_id":"39535","city_name":"Bubikon","state_id":"3455"},
    {"city_id":"39536","city_name":"Bulach","state_id":"3455"},
    {"city_id":"39537","city_name":"Dielsdorf","state_id":"3455"},
    {"city_id":"39538","city_name":"Dietikon","state_id":"3455"},
    {"city_id":"39539","city_name":"Dietlikon","state_id":"3455"},
    {"city_id":"39540","city_name":"Dubendorf","state_id":"3455"},
    {"city_id":"39541","city_name":"Durnten","state_id":"3455"},
    {"city_id":"39542","city_name":"Ebmatingen","state_id":"3455"},
    {"city_id":"39543","city_name":"Egg","state_id":"3455"},
    {"city_id":"39544","city_name":"Embrach","state_id":"3455"},
    {"city_id":"39545","city_name":"Erlenbach","state_id":"3455"},
    {"city_id":"39546","city_name":"Fallanden","state_id":"3455"},
    {"city_id":"39547","city_name":"Fehraltdorf","state_id":"3455"},
    {"city_id":"39548","city_name":"Geroldswil","state_id":"3455"},
    {"city_id":"39549","city_name":"Gossau","state_id":"3455"},
    {"city_id":"39550","city_name":"Greifensee","state_id":"3455"},
    {"city_id":"39551","city_name":"Herrliberg","state_id":"3455"},
    {"city_id":"39552","city_name":"Hinwil","state_id":"3455"},
    {"city_id":"39553","city_name":"Hombrechtikon","state_id":"3455"},
    {"city_id":"39554","city_name":"Horgen","state_id":"3455"},
    {"city_id":"39555","city_name":"Illnau-Effretikon","state_id":"3455"},
    {"city_id":"39556","city_name":"Kilchberg","state_id":"3455"},
    {"city_id":"39557","city_name":"Kloten","state_id":"3455"},
    {"city_id":"39558","city_name":"Kusnacht","state_id":"3455"},
    {"city_id":"39559","city_name":"Langnau","state_id":"3455"},
    {"city_id":"39560","city_name":"Mannedorf","state_id":"3455"},
    {"city_id":"39561","city_name":"Maur","state_id":"3455"},
    {"city_id":"39562","city_name":"Meilen","state_id":"3455"},
    {"city_id":"39563","city_name":"Neftenbach","state_id":"3455"},
    {"city_id":"39564","city_name":"Niederhasli","state_id":"3455"},
    {"city_id":"39565","city_name":"Nurensdorf","state_id":"3455"},
    {"city_id":"39566","city_name":"Oberengstringen","state_id":"3455"},
    {"city_id":"39567","city_name":"Oberglatt","state_id":"3455"},
    {"city_id":"39568","city_name":"Oberrieden","state_id":"3455"},
    {"city_id":"39569","city_name":"Opfikon","state_id":"3455"},
    {"city_id":"39570","city_name":"Pfaffikon","state_id":"3455"},
    {"city_id":"39571","city_name":"Rafz","state_id":"3455"},
    {"city_id":"39572","city_name":"Regensdorf","state_id":"3455"},
    {"city_id":"39573","city_name":"Richterswil","state_id":"3455"},
    {"city_id":"39574","city_name":"Rumlang","state_id":"3455"},
    {"city_id":"39575","city_name":"Ruschlikon","state_id":"3455"},
    {"city_id":"39576","city_name":"Ruti","state_id":"3455"},
    {"city_id":"39577","city_name":"Schlieren","state_id":"3455"},
    {"city_id":"39578","city_name":"Schwerzenbach","state_id":"3455"},
    {"city_id":"39579","city_name":"Seuzach","state_id":"3455"},
    {"city_id":"39580","city_name":"Stafa","state_id":"3455"},
    {"city_id":"39581","city_name":"Thalwil","state_id":"3455"},
    {"city_id":"39582","city_name":"Uetikon","state_id":"3455"},
    {"city_id":"39583","city_name":"Urdorf","state_id":"3455"},
    {"city_id":"39584","city_name":"Uster","state_id":"3455"},
    {"city_id":"39585","city_name":"Volketswil","state_id":"3455"},
    {"city_id":"39586","city_name":"Wadenswil","state_id":"3455"},
    {"city_id":"39587","city_name":"Wald","state_id":"3455"},
    {"city_id":"39588","city_name":"Wallisellen","state_id":"3455"},
    {"city_id":"39589","city_name":"Wangen-Bruttisellen","state_id":"3455"},
    {"city_id":"39590","city_name":"Weiningen","state_id":"3455"},
    {"city_id":"39591","city_name":"Wetzikon","state_id":"3455"},
    {"city_id":"39592","city_name":"Winterthur","state_id":"3455"},
    {"city_id":"39593","city_name":"Zell","state_id":"3455"},
    {"city_id":"39594","city_name":"Zollikon","state_id":"3455"},
    {"city_id":"39595","city_name":"Zumikon","state_id":"3455"},
    {"city_id":"39596","city_name":"Zurich","state_id":"3455"},
    {"city_id":"39597","city_name":"Aleppo","state_id":"3456"},
    {"city_id":"39598","city_name":"Dar'a","state_id":"3457"},
    {"city_id":"39599","city_name":"Nawa","state_id":"3457"},
    {"city_id":"39600","city_name":"Abu Kamal","state_id":"3458"},
    {"city_id":"39601","city_name":"Dayr az-Zawr","state_id":"3458"},
    {"city_id":"39602","city_name":"al-Mayadin","state_id":"3458"},
    {"city_id":"39603","city_name":"Damascus","state_id":"3459"},
    {"city_id":"39604","city_name":"Darayya","state_id":"3459"},
    {"city_id":"39605","city_name":"Duma","state_id":"3459"},
    {"city_id":"39606","city_name":"Hajar Aswad","state_id":"3459"},
    {"city_id":"39607","city_name":"Harasta al-Bas","state_id":"3459"},
    {"city_id":"39608","city_name":"Jaramanah","state_id":"3459"},
    {"city_id":"39609","city_name":"Kabir-as-Sit","state_id":"3459"},
    {"city_id":"39610","city_name":"Kesweh","state_id":"3459"},
    {"city_id":"39611","city_name":"Qatana","state_id":"3459"},
    {"city_id":"39612","city_name":"Yabrud","state_id":"3459"},
    {"city_id":"39613","city_name":"al-Qutayfah","state_id":"3459"},
    {"city_id":"39614","city_name":"an-Nabk","state_id":"3459"},
    {"city_id":"39615","city_name":"at-Tall","state_id":"3459"},
    {"city_id":"39616","city_name":"az-Zabadani","state_id":"3459"},
    {"city_id":"39617","city_name":"'Afrin","state_id":"3460"},
    {"city_id":"39618","city_name":"'Ayn-al-'Arab","state_id":"3460"},
    {"city_id":"39619","city_name":"A'zaz","state_id":"3460"},
    {"city_id":"39620","city_name":"Halab","state_id":"3460"},
    {"city_id":"39621","city_name":"Manbij","state_id":"3460"},
    {"city_id":"39622","city_name":"al-Bab","state_id":"3460"},
    {"city_id":"39623","city_name":"as-Safirah","state_id":"3460"},
    {"city_id":"39624","city_name":"Hamah","state_id":"3461"},
    {"city_id":"39625","city_name":"Masyaf","state_id":"3461"},
    {"city_id":"39626","city_name":"Muhradah","state_id":"3461"},
    {"city_id":"39627","city_name":"Salamiyah","state_id":"3461"},
    {"city_id":"39628","city_name":"Hims","state_id":"3462"},
    {"city_id":"39629","city_name":"Homs","state_id":"3462"},
    {"city_id":"39630","city_name":"Tudmur","state_id":"3462"},
    {"city_id":"39631","city_name":"al-Qusayr","state_id":"3462"},
    {"city_id":"39632","city_name":"ar-Rastan","state_id":"3462"},
    {"city_id":"39633","city_name":"Ariha","state_id":"3463"},
    {"city_id":"39634","city_name":"Idlib","state_id":"3463"},
    {"city_id":"39635","city_name":"Jisr-ash-Shughur","state_id":"3463"},
    {"city_id":"39636","city_name":"Ma'arrat-an-Nu'man","state_id":"3463"},
    {"city_id":"39637","city_name":"Baniyas","state_id":"3465"},
    {"city_id":"39638","city_name":"Safita","state_id":"3465"},
    {"city_id":"39639","city_name":"Tartus","state_id":"3465"},
    {"city_id":"39640","city_name":"Ra's-al-'Ayn","state_id":"3466"},
    {"city_id":"39641","city_name":"al-Hasakah","state_id":"3466"},
    {"city_id":"39642","city_name":"al-Malikiyah","state_id":"3466"},
    {"city_id":"39643","city_name":"al-Qamishli","state_id":"3466"},
    {"city_id":"39644","city_name":"Jablah","state_id":"3467"},
    {"city_id":"39645","city_name":"Lattakia","state_id":"3467"},
    {"city_id":"39646","city_name":"al-Ladhiqiyah","state_id":"3467"},
    {"city_id":"39647","city_name":"al-Qunaytirah","state_id":"3468"},
    {"city_id":"39648","city_name":"ar-Raqqah","state_id":"3469"},
    {"city_id":"39649","city_name":"ath-Thawrah","state_id":"3469"},
    {"city_id":"39650","city_name":"as-Suwayda","state_id":"3470"},
    {"city_id":"39651","city_name":"Changhua City","state_id":"3471"},
    {"city_id":"39652","city_name":"Changhwa","state_id":"3471"},
    {"city_id":"39653","city_name":"Chihu","state_id":"3471"},
    {"city_id":"39654","city_name":"Erhlin","state_id":"3471"},
    {"city_id":"39655","city_name":"Hemei","state_id":"3471"},
    {"city_id":"39656","city_name":"Homei","state_id":"3471"},
    {"city_id":"39657","city_name":"Hua Tan Hsiang","state_id":"3471"},
    {"city_id":"39658","city_name":"Huatan Township","state_id":"3471"},
    {"city_id":"39659","city_name":"Lukang","state_id":"3471"},
    {"city_id":"39660","city_name":"Peitou","state_id":"3471"},
    {"city_id":"39661","city_name":"Puyen Hsiang","state_id":"3471"},
    {"city_id":"39662","city_name":"Tienchung","state_id":"3471"},
    {"city_id":"39663","city_name":"Yuanlin","state_id":"3471"},
    {"city_id":"39664","city_name":"Fenglin","state_id":"3477"},
    {"city_id":"39665","city_name":"Hualian","state_id":"3477"},
    {"city_id":"39666","city_name":"Yuli","state_id":"3477"},
    {"city_id":"39667","city_name":"Ilan","state_id":"3478"},
    {"city_id":"39668","city_name":"Lotung","state_id":"3478"},
    {"city_id":"39669","city_name":"Suao","state_id":"3478"},
    {"city_id":"39670","city_name":"Toucheng","state_id":"3478"},
    {"city_id":"39671","city_name":"Kincheng","state_id":"3482"},
    {"city_id":"39672","city_name":"Kinhsia","state_id":"3482"},
    {"city_id":"39673","city_name":"Kinhu","state_id":"3482"},
    {"city_id":"39674","city_name":"Cholan","state_id":"3483"},
    {"city_id":"39675","city_name":"Chunan","state_id":"3483"},
    {"city_id":"39676","city_name":"Houlung","state_id":"3483"},
    {"city_id":"39677","city_name":"Miaoli","state_id":"3483"},
    {"city_id":"39678","city_name":"Toufen","state_id":"3483"},
    {"city_id":"39679","city_name":"Tunghsiao","state_id":"3483"},
    {"city_id":"39680","city_name":"Yuanli","state_id":"3483"},
    {"city_id":"39681","city_name":"Zaociao","state_id":"3483"},
    {"city_id":"39682","city_name":"Chichi","state_id":"3484"},
    {"city_id":"39683","city_name":"Chushan","state_id":"3484"},
    {"city_id":"39684","city_name":"Nantou","state_id":"3484"},
    {"city_id":"39685","city_name":"Puli","state_id":"3484"},
    {"city_id":"39686","city_name":"Tsaotun","state_id":"3484"},
    {"city_id":"39687","city_name":"Makung","state_id":"3486"},
    {"city_id":"39688","city_name":"Chaochou","state_id":"3487"},
    {"city_id":"39689","city_name":"Hengchun","state_id":"3487"},
    {"city_id":"39690","city_name":"Pingtung","state_id":"3487"},
    {"city_id":"39691","city_name":"Tungkang","state_id":"3487"},
    {"city_id":"39692","city_name":"Dali","state_id":"3488"},
    {"city_id":"39693","city_name":"South District","state_id":"3488"},
    {"city_id":"39694","city_name":"Ta-Ya Shang","state_id":"3488"},
    {"city_id":"39695","city_name":"Dali","state_id":"3489"},
    {"city_id":"39696","city_name":"South District","state_id":"3489"},
    {"city_id":"39697","city_name":"Ta-Ya Shang","state_id":"3489"},
    {"city_id":"39698","city_name":"Dali","state_id":"3490"},
    {"city_id":"39699","city_name":"South District","state_id":"3490"},
    {"city_id":"39700","city_name":"Ta-Ya Shang","state_id":"3490"},
    {"city_id":"39701","city_name":"Kuanshan","state_id":"3495"},
    {"city_id":"39702","city_name":"Taitung","state_id":"3495"},
    {"city_id":"39703","city_name":"Chengkung","state_id":"3496"},
    {"city_id":"39704","city_name":"Chungli","state_id":"3496"},
    {"city_id":"39705","city_name":"Kuan Yin","state_id":"3496"},
    {"city_id":"39706","city_name":"Pate","state_id":"3496"},
    {"city_id":"39707","city_name":"Pingchen","state_id":"3496"},
    {"city_id":"39708","city_name":"Ta Yuan","state_id":"3496"},
    {"city_id":"39709","city_name":"Tachi","state_id":"3496"},
    {"city_id":"39710","city_name":"Taoyuan","state_id":"3496"},
    {"city_id":"39711","city_name":"Tungshih","state_id":"3496"},
    {"city_id":"39712","city_name":"Yangmei","state_id":"3496"},
    {"city_id":"39713","city_name":"Wujie","state_id":"3497"},
    {"city_id":"39714","city_name":"Hsilo","state_id":"3499"},
    {"city_id":"39715","city_name":"Huwei","state_id":"3499"},
    {"city_id":"39716","city_name":"Peikang","state_id":"3499"},
    {"city_id":"39717","city_name":"Touliu","state_id":"3499"},
    {"city_id":"39718","city_name":"Tounan","state_id":"3499"},
    {"city_id":"39719","city_name":"Tuku","state_id":"3499"},
    {"city_id":"39720","city_name":"Dushanbe","state_id":"3500"},
    {"city_id":"39721","city_name":"Khorug","state_id":"3501"},
    {"city_id":"39722","city_name":"Murgob","state_id":"3501"},
    {"city_id":"39723","city_name":"Garm","state_id":"3502"},
    {"city_id":"39724","city_name":"Hisor","state_id":"3502"},
    {"city_id":"39725","city_name":"Kofarnihon","state_id":"3502"},
    {"city_id":"39726","city_name":"Leninskiy","state_id":"3502"},
    {"city_id":"39727","city_name":"Rogun","state_id":"3502"},
    {"city_id":"39728","city_name":"Shakhrinau","state_id":"3502"},
    {"city_id":"39729","city_name":"Tursunzoda","state_id":"3502"},
    {"city_id":"39730","city_name":"Dangara","state_id":"3503"},
    {"city_id":"39731","city_name":"Farkhor","state_id":"3503"},
    {"city_id":"39732","city_name":"Jilikul","state_id":"3503"},
    {"city_id":"39733","city_name":"Khojamaston","state_id":"3503"},
    {"city_id":"39734","city_name":"Kolhozobod","state_id":"3503"},
    {"city_id":"39735","city_name":"Kulob","state_id":"3503"},
    {"city_id":"39736","city_name":"Kumsangir","state_id":"3503"},
    {"city_id":"39737","city_name":"Moskva","state_id":"3503"},
    {"city_id":"39738","city_name":"Mu'minobod","state_id":"3503"},
    {"city_id":"39739","city_name":"Nurak","state_id":"3503"},
    {"city_id":"39740","city_name":"Pyanj","state_id":"3503"},
    {"city_id":"39741","city_name":"Qurgonteppa","state_id":"3503"},
    {"city_id":"39742","city_name":"Sarband","state_id":"3503"},
    {"city_id":"39743","city_name":"Shaartuz","state_id":"3503"},
    {"city_id":"39744","city_name":"Sovetskiy","state_id":"3503"},
    {"city_id":"39745","city_name":"Vakhsh","state_id":"3503"},
    {"city_id":"39746","city_name":"Vose","state_id":"3503"},
    {"city_id":"39747","city_name":"Yovon","state_id":"3503"},
    {"city_id":"39748","city_name":"Adrasmon","state_id":"3504"},
    {"city_id":"39749","city_name":"Asht","state_id":"3504"},
    {"city_id":"39750","city_name":"Ayni","state_id":"3504"},
    {"city_id":"39751","city_name":"Buston","state_id":"3504"},
    {"city_id":"39752","city_name":"Chkalov","state_id":"3504"},
    {"city_id":"39753","city_name":"Chorku","state_id":"3504"},
    {"city_id":"39754","city_name":"Gafurov","state_id":"3504"},
    {"city_id":"39755","city_name":"Ganchi","state_id":"3504"},
    {"city_id":"39756","city_name":"Isfara","state_id":"3504"},
    {"city_id":"39757","city_name":"Khujand","state_id":"3504"},
    {"city_id":"39758","city_name":"Konibodom","state_id":"3504"},
    {"city_id":"39759","city_name":"Matcha","state_id":"3504"},
    {"city_id":"39760","city_name":"Nau","state_id":"3504"},
    {"city_id":"39761","city_name":"Panjakent","state_id":"3504"},
    {"city_id":"39762","city_name":"Proletarsk","state_id":"3504"},
    {"city_id":"39763","city_name":"Qayroqqum","state_id":"3504"},
    {"city_id":"39764","city_name":"Shurob","state_id":"3504"},
    {"city_id":"39765","city_name":"Taboshar","state_id":"3504"},
    {"city_id":"39766","city_name":"Uroteppa","state_id":"3504"},
    {"city_id":"39767","city_name":"Zafarobod","state_id":"3504"},
    {"city_id":"39768","city_name":"Arusha","state_id":"3505"},
    {"city_id":"39769","city_name":"Dodoma","state_id":"3507"},
    {"city_id":"39770","city_name":"Kondoa","state_id":"3507"},
    {"city_id":"39771","city_name":"Mpwapwa","state_id":"3507"},
    {"city_id":"39772","city_name":"Iringa","state_id":"3508"},
    {"city_id":"39773","city_name":"Njombe","state_id":"3508"},
    {"city_id":"39774","city_name":"Bukoba","state_id":"3509"},
    {"city_id":"39775","city_name":"Bwanga","state_id":"3509"},
    {"city_id":"39776","city_name":"Ngara","state_id":"3509"},
    {"city_id":"39777","city_name":"Kasulu","state_id":"3510"},
    {"city_id":"39778","city_name":"Kigoma","state_id":"3510"},
    {"city_id":"39779","city_name":"Uvinza","state_id":"3510"},
    {"city_id":"39780","city_name":"Moshi","state_id":"3511"},
    {"city_id":"39781","city_name":"Same","state_id":"3511"},
    {"city_id":"39782","city_name":"Kilwa Kivinje","state_id":"3512"},
    {"city_id":"39783","city_name":"Lindi","state_id":"3512"},
    {"city_id":"39784","city_name":"Nachingwea","state_id":"3512"},
    {"city_id":"39785","city_name":"Musoma","state_id":"3513"},
    {"city_id":"39786","city_name":"Tarime","state_id":"3513"},
    {"city_id":"39787","city_name":"Mbeya","state_id":"3514"},
    {"city_id":"39788","city_name":"Mwaya","state_id":"3514"},
    {"city_id":"39789","city_name":"Tukuyu","state_id":"3514"},
    {"city_id":"39790","city_name":"Kilosa","state_id":"3515"},
    {"city_id":"39791","city_name":"Mahenge","state_id":"3515"},
    {"city_id":"39792","city_name":"Morogoro","state_id":"3515"},
    {"city_id":"39793","city_name":"Masasi","state_id":"3516"},
    {"city_id":"39794","city_name":"Mikindani","state_id":"3516"},
    {"city_id":"39795","city_name":"Mtwara","state_id":"3516"},
    {"city_id":"39796","city_name":"Newala","state_id":"3516"},
    {"city_id":"39797","city_name":"Magu","state_id":"3517"},
    {"city_id":"39798","city_name":"Mwanza","state_id":"3517"},
    {"city_id":"39799","city_name":"Nansio","state_id":"3517"},
    {"city_id":"39800","city_name":"Sengerama","state_id":"3517"},
    {"city_id":"39801","city_name":"Bagamoyo","state_id":"3518"},
    {"city_id":"39802","city_name":"Kibaha","state_id":"3518"},
    {"city_id":"39803","city_name":"Rufiji","state_id":"3518"},
    {"city_id":"39804","city_name":"Utete","state_id":"3518"},
    {"city_id":"39805","city_name":"Mpanda","state_id":"3519"},
    {"city_id":"39806","city_name":"Sumbawanga","state_id":"3519"},
    {"city_id":"39807","city_name":"Songea","state_id":"3520"},
    {"city_id":"39808","city_name":"Tunduru","state_id":"3520"},
    {"city_id":"39809","city_name":"Kahama","state_id":"3521"},
    {"city_id":"39810","city_name":"Maswa","state_id":"3521"},
    {"city_id":"39811","city_name":"Mwadui","state_id":"3521"},
    {"city_id":"39812","city_name":"Old Shinyanga","state_id":"3521"},
    {"city_id":"39813","city_name":"Shinyanga","state_id":"3521"},
    {"city_id":"39814","city_name":"Manyoni","state_id":"3522"},
    {"city_id":"39815","city_name":"Singida","state_id":"3522"},
    {"city_id":"39816","city_name":"Bukene","state_id":"3523"},
    {"city_id":"39817","city_name":"Nzega","state_id":"3523"},
    {"city_id":"39818","city_name":"Tabora","state_id":"3523"},
    {"city_id":"39819","city_name":"Urambo","state_id":"3523"},
    {"city_id":"39820","city_name":"Korogwe","state_id":"3524"},
    {"city_id":"39821","city_name":"Muheza","state_id":"3524"},
    {"city_id":"39822","city_name":"Pangani","state_id":"3524"},
    {"city_id":"39823","city_name":"Tanga","state_id":"3524"},
    {"city_id":"39824","city_name":"Bangkhen","state_id":"3528"},
    {"city_id":"39825","city_name":"Bangkok","state_id":"3528"},
    {"city_id":"39826","city_name":"Phatumthanee","state_id":"3528"},
    {"city_id":"39827","city_name":"Bang Khla","state_id":"3530"},
    {"city_id":"39828","city_name":"Bang Pakong","state_id":"3530"},
    {"city_id":"39829","city_name":"Bangpakong","state_id":"3530"},
    {"city_id":"39830","city_name":"Chachoengsao","state_id":"3530"},
    {"city_id":"39831","city_name":"Phanom Sarakham","state_id":"3530"},
    {"city_id":"39832","city_name":"Plaeng Yao","state_id":"3530"},
    {"city_id":"39833","city_name":"Bamnet Narong","state_id":"3532"},
    {"city_id":"39834","city_name":"Chaiyaphum","state_id":"3532"},
    {"city_id":"39835","city_name":"Kaeng Khlo","state_id":"3532"},
    {"city_id":"39836","city_name":"Kaset Sombun","state_id":"3532"},
    {"city_id":"39837","city_name":"Phu Khieo","state_id":"3532"},
    {"city_id":"39838","city_name":"Chanthaburi","state_id":"3534"},
    {"city_id":"39839","city_name":"Khlung","state_id":"3534"},
    {"city_id":"39840","city_name":"Laem Sing","state_id":"3534"},
    {"city_id":"39841","city_name":"Soydow","state_id":"3534"},
    {"city_id":"39842","city_name":"Tha Mai","state_id":"3534"},
    {"city_id":"39843","city_name":"Chumphon","state_id":"3538"},
    {"city_id":"39844","city_name":"Lang Suan","state_id":"3538"},
    {"city_id":"39845","city_name":"Huai Mek","state_id":"3539"},
    {"city_id":"39846","city_name":"Kalasin","state_id":"3539"},
    {"city_id":"39847","city_name":"Kamalasai","state_id":"3539"},
    {"city_id":"39848","city_name":"Khao Wong","state_id":"3539"},
    {"city_id":"39849","city_name":"Kuchinarai","state_id":"3539"},
    {"city_id":"39850","city_name":"Nong Kung Si","state_id":"3539"},
    {"city_id":"39851","city_name":"Yang Talat","state_id":"3539"},
    {"city_id":"39852","city_name":"Bo Phloi","state_id":"3541"},
    {"city_id":"39853","city_name":"Kanchanaburi","state_id":"3541"},
    {"city_id":"39854","city_name":"Phanom Thuan","state_id":"3541"},
    {"city_id":"39855","city_name":"Tha Maka","state_id":"3541"},
    {"city_id":"39856","city_name":"Tha Muang","state_id":"3541"},
    {"city_id":"39857","city_name":"Ao Luk","state_id":"3543"},
    {"city_id":"39858","city_name":"Krabi","state_id":"3543"},
    {"city_id":"39859","city_name":"Lampang","state_id":"3545"},
    {"city_id":"39860","city_name":"Thoen","state_id":"3545"},
    {"city_id":"39861","city_name":"Lamphun","state_id":"3546"},
    {"city_id":"39862","city_name":"Mae Tha","state_id":"3546"},
    {"city_id":"39863","city_name":"Pa Sang","state_id":"3546"},
    {"city_id":"39864","city_name":"Loei","state_id":"3547"},
    {"city_id":"39865","city_name":"Phu Kra Dung","state_id":"3547"},
    {"city_id":"39866","city_name":"Wang Saphung","state_id":"3547"},
    {"city_id":"39867","city_name":"Mukdahan","state_id":"3551"},
    {"city_id":"39868","city_name":"Chiang Klang","state_id":"3558"},
    {"city_id":"39869","city_name":"Nan","state_id":"3558"},
    {"city_id":"39870","city_name":"Bacho","state_id":"3559"},
    {"city_id":"39871","city_name":"Narathiwat","state_id":"3559"},
    {"city_id":"39872","city_name":"Rangae","state_id":"3559"},
    {"city_id":"39873","city_name":"Sungai Kolok","state_id":"3559"},
    {"city_id":"39874","city_name":"Sungai Padi","state_id":"3559"},
    {"city_id":"39875","city_name":"Tak Bai","state_id":"3559"},
    {"city_id":"39876","city_name":"Bang Bo Thong","state_id":"3562"},
    {"city_id":"39877","city_name":"Bang Kruai","state_id":"3562"},
    {"city_id":"39878","city_name":"Bang Yai","state_id":"3562"},
    {"city_id":"39879","city_name":"Nonthaburi","state_id":"3562"},
    {"city_id":"39880","city_name":"Pak Kret","state_id":"3562"},
    {"city_id":"39881","city_name":"Sainoi","state_id":"3562"},
    {"city_id":"39882","city_name":"Nong Chik","state_id":"3564"},
    {"city_id":"39883","city_name":"Pattani","state_id":"3564"},
    {"city_id":"39884","city_name":"Sai Buri","state_id":"3564"},
    {"city_id":"39885","city_name":"Yaring","state_id":"3564"},
    {"city_id":"39886","city_name":"Phangnga","state_id":"3565"},
    {"city_id":"39887","city_name":"Phatthalung","state_id":"3566"},
    {"city_id":"39888","city_name":"Chiang Kham","state_id":"3567"},
    {"city_id":"39889","city_name":"Dok Kham Tai","state_id":"3567"},
    {"city_id":"39890","city_name":"Phayao","state_id":"3567"},
    {"city_id":"39891","city_name":"Pong","state_id":"3567"},
    {"city_id":"39892","city_name":"Chon Daen","state_id":"3568"},
    {"city_id":"39893","city_name":"Lom Sak","state_id":"3568"},
    {"city_id":"39894","city_name":"Nong Phai","state_id":"3568"},
    {"city_id":"39895","city_name":"Phetchabun","state_id":"3568"},
    {"city_id":"39896","city_name":"Wichian Buri","state_id":"3568"},
    {"city_id":"39897","city_name":"Ban Laem","state_id":"3569"},
    {"city_id":"39898","city_name":"Chaam","state_id":"3569"},
    {"city_id":"39899","city_name":"Khao Yoi","state_id":"3569"},
    {"city_id":"39900","city_name":"Phetchaburi","state_id":"3569"},
    {"city_id":"39901","city_name":"Tha Yang","state_id":"3569"},
    {"city_id":"39902","city_name":"Bang Mun Nak","state_id":"3570"},
    {"city_id":"39903","city_name":"Phichit","state_id":"3570"},
    {"city_id":"39904","city_name":"Taphan Hin","state_id":"3570"},
    {"city_id":"39905","city_name":"Thap Khlo","state_id":"3570"},
    {"city_id":"39906","city_name":"Bang Krathum","state_id":"3571"},
    {"city_id":"39907","city_name":"Bang Rakam","state_id":"3571"},
    {"city_id":"39908","city_name":"Nakhon Thai","state_id":"3571"},
    {"city_id":"39909","city_name":"Phitsanulok","state_id":"3571"},
    {"city_id":"39910","city_name":"Den Chai","state_id":"3573"},
    {"city_id":"39911","city_name":"Long","state_id":"3573"},
    {"city_id":"39912","city_name":"Phrae","state_id":"3573"},
    {"city_id":"39913","city_name":"Rong Kwang","state_id":"3573"},
    {"city_id":"39914","city_name":"Kathu","state_id":"3574"},
    {"city_id":"39915","city_name":"Phuket","state_id":"3574"},
    {"city_id":"39916","city_name":"Ranong","state_id":"3577"},
    {"city_id":"39917","city_name":"Ban Pong","state_id":"3578"},
    {"city_id":"39918","city_name":"Bang Phae","state_id":"3578"},
    {"city_id":"39919","city_name":"Chom Bung","state_id":"3578"},
    {"city_id":"39920","city_name":"Damnoen Saduak","state_id":"3578"},
    {"city_id":"39921","city_name":"Photharam","state_id":"3578"},
    {"city_id":"39922","city_name":"Ratchaburi","state_id":"3578"},
    {"city_id":"39923","city_name":"Ban Chang","state_id":"3579"},
    {"city_id":"39924","city_name":"Klaeng","state_id":"3579"},
    {"city_id":"39925","city_name":"Rayong","state_id":"3579"},
    {"city_id":"39926","city_name":"Ban Mo","state_id":"3586"},
    {"city_id":"39927","city_name":"Kaeng Khoi","state_id":"3586"},
    {"city_id":"39928","city_name":"Nong Khae","state_id":"3586"},
    {"city_id":"39929","city_name":"Phra Phutthabat","state_id":"3586"},
    {"city_id":"39930","city_name":"Saraburi","state_id":"3586"},
    {"city_id":"39931","city_name":"Satun","state_id":"3587"},
    {"city_id":"39932","city_name":"Hat Yai","state_id":"3590"},
    {"city_id":"39933","city_name":"Ranot","state_id":"3590"},
    {"city_id":"39934","city_name":"Sadao","state_id":"3590"},
    {"city_id":"39935","city_name":"Singha Nakhon","state_id":"3590"},
    {"city_id":"39936","city_name":"Songkhla","state_id":"3590"},
    {"city_id":"39937","city_name":"Khiri Mat","state_id":"3591"},
    {"city_id":"39938","city_name":"Sawankhalok","state_id":"3591"},
    {"city_id":"39939","city_name":"Si Satchanalai","state_id":"3591"},
    {"city_id":"39940","city_name":"Sukhothai","state_id":"3591"},
    {"city_id":"39941","city_name":"Phasat","state_id":"3594"},
    {"city_id":"39942","city_name":"Surin","state_id":"3594"},
    {"city_id":"39943","city_name":"Ban Tak","state_id":"3595"},
    {"city_id":"39944","city_name":"Mae Ramat","state_id":"3595"},
    {"city_id":"39945","city_name":"Mae Sot","state_id":"3595"},
    {"city_id":"39946","city_name":"Tak","state_id":"3595"},
    {"city_id":"39947","city_name":"Huai Yot","state_id":"3596"},
    {"city_id":"39948","city_name":"Kantang","state_id":"3596"},
    {"city_id":"39949","city_name":"Trang","state_id":"3596"},
    {"city_id":"39950","city_name":"Bo Rai","state_id":"3597"},
    {"city_id":"39951","city_name":"Trat","state_id":"3597"},
    {"city_id":"39952","city_name":"Laplae","state_id":"3601"},
    {"city_id":"39953","city_name":"Uttaradit","state_id":"3601"},
    {"city_id":"39954","city_name":"Betong","state_id":"3602"},
    {"city_id":"39955","city_name":"Yala","state_id":"3602"},
    {"city_id":"39956","city_name":"Yasothon","state_id":"3603"},
    {"city_id":"39957","city_name":"Guerin Kouka","state_id":"3604"},
    {"city_id":"39958","city_name":"Sokode","state_id":"3604"},
    {"city_id":"39959","city_name":"Sotouboua","state_id":"3604"},
    {"city_id":"39960","city_name":"Tchamba","state_id":"3604"},
    {"city_id":"39961","city_name":"Bafilo","state_id":"3605"},
    {"city_id":"39962","city_name":"Bassar","state_id":"3605"},
    {"city_id":"39963","city_name":"Kande","state_id":"3605"},
    {"city_id":"39964","city_name":"Kara","state_id":"3605"},
    {"city_id":"39965","city_name":"Kpagouda","state_id":"3605"},
    {"city_id":"39966","city_name":"Niamtougou","state_id":"3605"},
    {"city_id":"39967","city_name":"Agbelouve","state_id":"3606"},
    {"city_id":"39968","city_name":"Aneho","state_id":"3606"},
    {"city_id":"39969","city_name":"Lome","state_id":"3606"},
    {"city_id":"39970","city_name":"Tabligbo","state_id":"3606"},
    {"city_id":"39971","city_name":"Tsevie","state_id":"3606"},
    {"city_id":"39972","city_name":"Vogan","state_id":"3606"},
    {"city_id":"39973","city_name":"Amlame","state_id":"3607"},
    {"city_id":"39974","city_name":"Anie","state_id":"3607"},
    {"city_id":"39975","city_name":"Atakpame","state_id":"3607"},
    {"city_id":"39976","city_name":"Badou","state_id":"3607"},
    {"city_id":"39977","city_name":"Blitta","state_id":"3607"},
    {"city_id":"39978","city_name":"Kouve","state_id":"3607"},
    {"city_id":"39979","city_name":"Kpalime","state_id":"3607"},
    {"city_id":"39980","city_name":"Kpessi","state_id":"3607"},
    {"city_id":"39981","city_name":"Notse","state_id":"3607"},
    {"city_id":"39982","city_name":"Dapaong","state_id":"3608"},
    {"city_id":"39983","city_name":"Mango","state_id":"3608"},
    {"city_id":"39984","city_name":"Tandjouare","state_id":"3608"},
    {"city_id":"39985","city_name":"Atafu","state_id":"3609"},
    {"city_id":"39986","city_name":"Fakaofo","state_id":"3610"},
    {"city_id":"39987","city_name":"Nukunonu","state_id":"3611"},
    {"city_id":"39988","city_name":"Ohonua","state_id":"3612"},
    {"city_id":"39989","city_name":"Pangai","state_id":"3613"},
    {"city_id":"39990","city_name":"Hihifo","state_id":"3614"},
    {"city_id":"39991","city_name":"Haveloloto","state_id":"3615"},
    {"city_id":"39992","city_name":"Mu'a","state_id":"3615"},
    {"city_id":"39993","city_name":"Nuku'alofa","state_id":"3615"},
    {"city_id":"39994","city_name":"Tofoa-Koloua","state_id":"3615"},
    {"city_id":"39995","city_name":"Vaini","state_id":"3615"},
    {"city_id":"39996","city_name":"Neiafu","state_id":"3616"},
    {"city_id":"39997","city_name":"Arima","state_id":"3617"},
    {"city_id":"39998","city_name":"Arouca","state_id":"3617"},
    {"city_id":"39999","city_name":"Tacarigua","state_id":"3617"},
    {"city_id":"40000","city_name":"Tunapuna","state_id":"3617"},
    {"city_id":"40001","city_name":"Carapichaima","state_id":"3618"},
    {"city_id":"40002","city_name":"Claxton Bay","state_id":"3618"},
    {"city_id":"40003","city_name":"Chaguanas","state_id":"3619"},
    {"city_id":"40004","city_name":"Couva","state_id":"3620"},
    {"city_id":"40005","city_name":"Tabaquite","state_id":"3620"},
    {"city_id":"40006","city_name":"Glencoe","state_id":"3622"},
    {"city_id":"40007","city_name":"Siparia","state_id":"3631"},
    {"city_id":"40008","city_name":"Scarborough","state_id":"3632"},
    {"city_id":"40009","city_name":"Aryanah","state_id":"3633"},
    {"city_id":"40010","city_name":"Bordj Aalouine","state_id":"3633"},
    {"city_id":"40011","city_name":"Burj-al-'Amri","state_id":"3633"},
    {"city_id":"40012","city_name":"La Soukra","state_id":"3633"},
    {"city_id":"40013","city_name":"Qal'at-al-Andalus","state_id":"3633"},
    {"city_id":"40014","city_name":"Sidi Thabit","state_id":"3633"},
    {"city_id":"40015","city_name":"Taburbah","state_id":"3633"},
    {"city_id":"40016","city_name":"Wadi al-Layl","state_id":"3633"},
    {"city_id":"40017","city_name":"ad-Dandan","state_id":"3633"},
    {"city_id":"40018","city_name":"al-Battan","state_id":"3633"},
    {"city_id":"40019","city_name":"al-Jadidah","state_id":"3633"},
    {"city_id":"40020","city_name":"al-Murnaqiyah","state_id":"3633"},
    {"city_id":"40021","city_name":"at-Tadaman Dawwar Hishar","state_id":"3633"},
    {"city_id":"40022","city_name":"Bajah","state_id":"3634"},
    {"city_id":"40023","city_name":"Majaz-al-Bab","state_id":"3634"},
    {"city_id":"40024","city_name":"Nafzah","state_id":"3634"},
    {"city_id":"40025","city_name":"Quballat","state_id":"3634"},
    {"city_id":"40026","city_name":"Tabursuq","state_id":"3634"},
    {"city_id":"40027","city_name":"Tastur","state_id":"3634"},
    {"city_id":"40028","city_name":"Zahrat Madina","state_id":"3634"},
    {"city_id":"40029","city_name":"al-Ma'qulah","state_id":"3634"},
    {"city_id":"40030","city_name":"'Awsajah","state_id":"3636"},
    {"city_id":"40031","city_name":"Binzart","state_id":"3636"},
    {"city_id":"40032","city_name":"Ghar-al-Milh","state_id":"3636"},
    {"city_id":"40033","city_name":"Manzil 'Abd-ar-Rahman","state_id":"3636"},
    {"city_id":"40034","city_name":"Manzil Bu Ruqaybah","state_id":"3636"},
    {"city_id":"40035","city_name":"Manzil Jamil","state_id":"3636"},
    {"city_id":"40036","city_name":"Matir","state_id":"3636"},
    {"city_id":"40037","city_name":"Rafraf","state_id":"3636"},
    {"city_id":"40038","city_name":"Ras-al-Jabal","state_id":"3636"},
    {"city_id":"40039","city_name":"Sajanan","state_id":"3636"},
    {"city_id":"40040","city_name":"Tinjah","state_id":"3636"},
    {"city_id":"40041","city_name":"al-'Aliyah","state_id":"3636"},
    {"city_id":"40042","city_name":"al-Matlin","state_id":"3636"},
    {"city_id":"40043","city_name":"'Ayn Darahim","state_id":"3641"},
    {"city_id":"40044","city_name":"Bani Matir","state_id":"3641"},
    {"city_id":"40045","city_name":"Bu Salim","state_id":"3641"},
    {"city_id":"40046","city_name":"Firnanah","state_id":"3641"},
    {"city_id":"40047","city_name":"Ghar-ad-Dima'","state_id":"3641"},
    {"city_id":"40048","city_name":"Jundubah","state_id":"3641"},
    {"city_id":"40049","city_name":"Tabarqah","state_id":"3641"},
    {"city_id":"40050","city_name":"Wadi Maliz","state_id":"3641"},
    {"city_id":"40051","city_name":"Bani Khaddash","state_id":"3642"},
    {"city_id":"40052","city_name":"Bin Qirdan","state_id":"3642"},
    {"city_id":"40053","city_name":"Jarbah Ajim","state_id":"3642"},
    {"city_id":"40054","city_name":"Jarbah Hawmat-as-Suq","state_id":"3642"},
    {"city_id":"40055","city_name":"Jarbah Midun","state_id":"3642"},
    {"city_id":"40056","city_name":"Jarjis","state_id":"3642"},
    {"city_id":"40057","city_name":"Madaniyin","state_id":"3642"},
    {"city_id":"40058","city_name":"Manubah","state_id":"3643"},
    {"city_id":"40059","city_name":"Monastir","state_id":"3644"},
    {"city_id":"40060","city_name":"Azimur","state_id":"3645"},
    {"city_id":"40061","city_name":"Bani Hiyar","state_id":"3645"},
    {"city_id":"40062","city_name":"Bani Khallad","state_id":"3645"},
    {"city_id":"40063","city_name":"Bu 'Urqub","state_id":"3645"},
    {"city_id":"40064","city_name":"Dar 'Alush","state_id":"3645"},
    {"city_id":"40065","city_name":"Dar Sha'ban","state_id":"3645"},
    {"city_id":"40066","city_name":"Hammam-al-Aghzaz","state_id":"3645"},
    {"city_id":"40067","city_name":"Manzil Bu Zalafah","state_id":"3645"},
    {"city_id":"40068","city_name":"Manzil Hurr","state_id":"3645"},
    {"city_id":"40069","city_name":"Manzil Tamim","state_id":"3645"},
    {"city_id":"40070","city_name":"Nabul","state_id":"3645"},
    {"city_id":"40071","city_name":"Qalibiyah","state_id":"3645"},
    {"city_id":"40072","city_name":"Qurbah","state_id":"3645"},
    {"city_id":"40073","city_name":"Qurbus","state_id":"3645"},
    {"city_id":"40074","city_name":"Qurunbaliyah","state_id":"3645"},
    {"city_id":"40075","city_name":"Sulayman","state_id":"3645"},
    {"city_id":"40076","city_name":"Taklisah","state_id":"3645"},
    {"city_id":"40077","city_name":"Tazirkah","state_id":"3645"},
    {"city_id":"40078","city_name":"Zawiyat-al-Jadidi","state_id":"3645"},
    {"city_id":"40079","city_name":"al-Hammamat","state_id":"3645"},
    {"city_id":"40080","city_name":"al-Huwariyah","state_id":"3645"},
    {"city_id":"40081","city_name":"al-Ma'murah","state_id":"3645"},
    {"city_id":"40082","city_name":"al-Middah","state_id":"3645"},
    {"city_id":"40083","city_name":"as-Sama'ah","state_id":"3645"},
    {"city_id":"40084","city_name":"Ghannush","state_id":"3646"},
    {"city_id":"40085","city_name":"Marath","state_id":"3646"},
    {"city_id":"40086","city_name":"Matmatat-al-Jadidah","state_id":"3646"},
    {"city_id":"40087","city_name":"Matmatat-al-Qadimah","state_id":"3646"},
    {"city_id":"40088","city_name":"Qabis","state_id":"3646"},
    {"city_id":"40089","city_name":"Sanini an-Nahal","state_id":"3646"},
    {"city_id":"40090","city_name":"Wadhraf","state_id":"3646"},
    {"city_id":"40091","city_name":"al-Hammah","state_id":"3646"},
    {"city_id":"40092","city_name":"al-Matwiyah","state_id":"3646"},
    {"city_id":"40093","city_name":"az-Zarat","state_id":"3646"},
    {"city_id":"40094","city_name":"Qafsah","state_id":"3647"},
    {"city_id":"40095","city_name":"Umm-al-'Arais","state_id":"3647"},
    {"city_id":"40096","city_name":"al-Madilah","state_id":"3647"},
    {"city_id":"40097","city_name":"al-Mitlawi","state_id":"3647"},
    {"city_id":"40098","city_name":"al-Qasr","state_id":"3647"},
    {"city_id":"40099","city_name":"al-Qatar","state_id":"3647"},
    {"city_id":"40100","city_name":"ar-Rudayyif","state_id":"3647"},
    {"city_id":"40101","city_name":"as-Sanad","state_id":"3647"},
    {"city_id":"40102","city_name":"Duz","state_id":"3648"},
    {"city_id":"40103","city_name":"Jamnah","state_id":"3648"},
    {"city_id":"40104","city_name":"Qibili","state_id":"3648"},
    {"city_id":"40105","city_name":"Suq-al-Ahad","state_id":"3648"},
    {"city_id":"40106","city_name":"al-Qal'ah","state_id":"3648"},
    {"city_id":"40107","city_name":"'Aqarib","state_id":"3649"},
    {"city_id":"40108","city_name":"Bi'r Ali Bin Khalifah","state_id":"3649"},
    {"city_id":"40109","city_name":"Jabinyanah","state_id":"3649"},
    {"city_id":"40110","city_name":"Manzil Shakir","state_id":"3649"},
    {"city_id":"40111","city_name":"Qarmadah","state_id":"3649"},
    {"city_id":"40112","city_name":"Qarqannah","state_id":"3649"},
    {"city_id":"40113","city_name":"Safaqis","state_id":"3649"},
    {"city_id":"40114","city_name":"Saqiyat-ad-Dair","state_id":"3649"},
    {"city_id":"40115","city_name":"Saqiyat-az-Zayt","state_id":"3649"},
    {"city_id":"40116","city_name":"Sfax","state_id":"3649"},
    {"city_id":"40117","city_name":"Tinah","state_id":"3649"},
    {"city_id":"40118","city_name":"al-'Ayn","state_id":"3649"},
    {"city_id":"40119","city_name":"al-Hanashah","state_id":"3649"},
    {"city_id":"40120","city_name":"al-Mahras","state_id":"3649"},
    {"city_id":"40121","city_name":"as-Sukhayrah","state_id":"3649"},
    {"city_id":"40122","city_name":"ash-Shayhiyah","state_id":"3649"},
    {"city_id":"40123","city_name":"Sfax","state_id":"3650"},
    {"city_id":"40124","city_name":"Barqu","state_id":"3652"},
    {"city_id":"40125","city_name":"Bu 'Aradah","state_id":"3652"},
    {"city_id":"40126","city_name":"Bu Ruwis","state_id":"3652"},
    {"city_id":"40127","city_name":"Kasra","state_id":"3652"},
    {"city_id":"40128","city_name":"Makthar","state_id":"3652"},
    {"city_id":"40129","city_name":"Qa'afur","state_id":"3652"},
    {"city_id":"40130","city_name":"Silyanah","state_id":"3652"},
    {"city_id":"40131","city_name":"al-'Arusah","state_id":"3652"},
    {"city_id":"40132","city_name":"al-Karib","state_id":"3652"},
    {"city_id":"40133","city_name":"ar-Rawhiyah","state_id":"3652"},
    {"city_id":"40134","city_name":"Akkudah","state_id":"3653"},
    {"city_id":"40135","city_name":"Bu Fishah","state_id":"3653"},
    {"city_id":"40136","city_name":"Hammam Susah","state_id":"3653"},
    {"city_id":"40137","city_name":"Harqalah","state_id":"3653"},
    {"city_id":"40138","city_name":"Masakin","state_id":"3653"},
    {"city_id":"40139","city_name":"Qusaybat-ath-Thurayat","state_id":"3653"},
    {"city_id":"40140","city_name":"Sidi Bu 'Ali","state_id":"3653"},
    {"city_id":"40141","city_name":"Sidi al-Hani","state_id":"3653"},
    {"city_id":"40142","city_name":"Susah","state_id":"3653"},
    {"city_id":"40143","city_name":"Zawiyat Susah","state_id":"3653"},
    {"city_id":"40144","city_name":"al-Masa'din","state_id":"3653"},
    {"city_id":"40145","city_name":"al-Qal'at-al-Kabirah","state_id":"3653"},
    {"city_id":"40146","city_name":"al-Qal'at-as-Saghirah","state_id":"3653"},
    {"city_id":"40147","city_name":"an-Nafidah","state_id":"3653"},
    {"city_id":"40148","city_name":"az-Zuhur","state_id":"3653"},
    {"city_id":"40149","city_name":"Bi'r-al-Ahmar","state_id":"3654"},
    {"city_id":"40150","city_name":"Dhahibah","state_id":"3654"},
    {"city_id":"40151","city_name":"Ghumrassin","state_id":"3654"},
    {"city_id":"40152","city_name":"Rimadah","state_id":"3654"},
    {"city_id":"40153","city_name":"Tatawin","state_id":"3654"},
    {"city_id":"40154","city_name":"Daqash","state_id":"3655"},
    {"city_id":"40155","city_name":"Hammat-al-Jarid","state_id":"3655"},
    {"city_id":"40156","city_name":"Naftah","state_id":"3655"},
    {"city_id":"40157","city_name":"Tamaghzah","state_id":"3655"},
    {"city_id":"40158","city_name":"Tawzar","state_id":"3655"},
    {"city_id":"40159","city_name":"Bardaw","state_id":"3656"},
    {"city_id":"40160","city_name":"Ben Arous","state_id":"3656"},
    {"city_id":"40161","city_name":"Carthage","state_id":"3656"},
    {"city_id":"40162","city_name":"Halq-al-Wadi","state_id":"3656"},
    {"city_id":"40163","city_name":"Megrine","state_id":"3656"},
    {"city_id":"40164","city_name":"Qartaj","state_id":"3656"},
    {"city_id":"40165","city_name":"Sidi Bu Sa'id","state_id":"3656"},
    {"city_id":"40166","city_name":"Tunis","state_id":"3656"},
    {"city_id":"40167","city_name":"al-Marsa","state_id":"3656"},
    {"city_id":"40168","city_name":"Bi'r Mashariqah","state_id":"3657"},
    {"city_id":"40169","city_name":"Jabal-al-Wust","state_id":"3657"},
    {"city_id":"40170","city_name":"Zaghwan","state_id":"3657"},
    {"city_id":"40171","city_name":"al-Fahs","state_id":"3657"},
    {"city_id":"40172","city_name":"an-Nazur","state_id":"3657"},
    {"city_id":"40173","city_name":"az-Zaribah","state_id":"3657"},
    {"city_id":"40174","city_name":"Manzil Salim","state_id":"3658"},
    {"city_id":"40175","city_name":"Nibbar","state_id":"3658"},
    {"city_id":"40176","city_name":"Qal'at-as-Sanan","state_id":"3658"},
    {"city_id":"40177","city_name":"Saqiyat Sidi Yussuf","state_id":"3658"},
    {"city_id":"40178","city_name":"Tajarwin","state_id":"3658"},
    {"city_id":"40179","city_name":"ad-Dahmani","state_id":"3658"},
    {"city_id":"40180","city_name":"al-Jarissah","state_id":"3658"},
    {"city_id":"40181","city_name":"al-Kaf","state_id":"3658"},
    {"city_id":"40182","city_name":"al-Qal'at-al-Khasba'","state_id":"3658"},
    {"city_id":"40183","city_name":"al-Qusur","state_id":"3658"},
    {"city_id":"40184","city_name":"as-Sars","state_id":"3658"},
    {"city_id":"40185","city_name":"at-Tawiraf","state_id":"3658"},
    {"city_id":"40186","city_name":"Awlad ash-Shamakh","state_id":"3659"},
    {"city_id":"40187","city_name":"Bumirdas","state_id":"3659"},
    {"city_id":"40188","city_name":"Habirah","state_id":"3659"},
    {"city_id":"40189","city_name":"Karkar","state_id":"3659"},
    {"city_id":"40190","city_name":"Mallulash","state_id":"3659"},
    {"city_id":"40191","city_name":"Qusur-as-Saf","state_id":"3659"},
    {"city_id":"40192","city_name":"Rajish","state_id":"3659"},
    {"city_id":"40193","city_name":"Shurban","state_id":"3659"},
    {"city_id":"40194","city_name":"Sidi 'Ulwan","state_id":"3659"},
    {"city_id":"40195","city_name":"al-Baradi'ah","state_id":"3659"},
    {"city_id":"40196","city_name":"al-Jamm","state_id":"3659"},
    {"city_id":"40197","city_name":"al-Mahdiyah","state_id":"3659"},
    {"city_id":"40198","city_name":"as-Sawasi","state_id":"3659"},
    {"city_id":"40199","city_name":"ash-Shabbah","state_id":"3659"},
    {"city_id":"40200","city_name":"'Amirat-al-Fahul","state_id":"3660"},
    {"city_id":"40201","city_name":"'Amirat-al-Hajjaj","state_id":"3660"},
    {"city_id":"40202","city_name":"'Amirat-at-Tawazarah","state_id":"3660"},
    {"city_id":"40203","city_name":"Banan Budar","state_id":"3660"},
    {"city_id":"40204","city_name":"Banblat wal-Manarah","state_id":"3660"},
    {"city_id":"40205","city_name":"Bani Hassan","state_id":"3660"},
    {"city_id":"40206","city_name":"Bu Hajjar","state_id":"3660"},
    {"city_id":"40207","city_name":"Ghanadah","state_id":"3660"},
    {"city_id":"40208","city_name":"Jammal","state_id":"3660"},
    {"city_id":"40209","city_name":"Khunays","state_id":"3660"},
    {"city_id":"40210","city_name":"Lamtah","state_id":"3660"},
    {"city_id":"40211","city_name":"Manzil Farsi","state_id":"3660"},
    {"city_id":"40212","city_name":"Manzil Hayah","state_id":"3660"},
    {"city_id":"40213","city_name":"Manzil Kamil","state_id":"3660"},
    {"city_id":"40214","city_name":"Manzil Nur","state_id":"3660"},
    {"city_id":"40215","city_name":"Qasr Hallal","state_id":"3660"},
    {"city_id":"40216","city_name":"Qusaybat-al-Madyuni","state_id":"3660"},
    {"city_id":"40217","city_name":"Sahilin Ma'atmar","state_id":"3660"},
    {"city_id":"40218","city_name":"Sayyadah","state_id":"3660"},
    {"city_id":"40219","city_name":"Sidi 'Amara","state_id":"3660"},
    {"city_id":"40220","city_name":"Sidi Bin Nur","state_id":"3660"},
    {"city_id":"40221","city_name":"Tabulbah","state_id":"3660"},
    {"city_id":"40222","city_name":"Tuzah","state_id":"3660"},
    {"city_id":"40223","city_name":"Zaramidin","state_id":"3660"},
    {"city_id":"40224","city_name":"Zawiyat Quntash","state_id":"3660"},
    {"city_id":"40225","city_name":"al-Baqalitah","state_id":"3660"},
    {"city_id":"40226","city_name":"al-Masdur","state_id":"3660"},
    {"city_id":"40227","city_name":"al-Muknin","state_id":"3660"},
    {"city_id":"40228","city_name":"al-Munastir","state_id":"3660"},
    {"city_id":"40229","city_name":"al-Wardanin","state_id":"3660"},
    {"city_id":"40230","city_name":"ash-Sharahil","state_id":"3660"},
    {"city_id":"40231","city_name":"Firyanah","state_id":"3661"},
    {"city_id":"40232","city_name":"Fussanah","state_id":"3661"},
    {"city_id":"40233","city_name":"Haydrah","state_id":"3661"},
    {"city_id":"40234","city_name":"Jadliyan","state_id":"3661"},
    {"city_id":"40235","city_name":"Majal Bal 'Abbas","state_id":"3661"},
    {"city_id":"40236","city_name":"Sabibah","state_id":"3661"},
    {"city_id":"40237","city_name":"Subaytilah","state_id":"3661"},
    {"city_id":"40238","city_name":"Talabat","state_id":"3661"},
    {"city_id":"40239","city_name":"Talah","state_id":"3661"},
    {"city_id":"40240","city_name":"al-Qasrayn","state_id":"3661"},
    {"city_id":"40241","city_name":"'Ayn Jalulah","state_id":"3662"},
    {"city_id":"40242","city_name":"Buhajlah","state_id":"3662"},
    {"city_id":"40243","city_name":"Haffuz","state_id":"3662"},
    {"city_id":"40244","city_name":"Hajib-al-'Ayun","state_id":"3662"},
    {"city_id":"40245","city_name":"Manzil Mahidi","state_id":"3662"},
    {"city_id":"40246","city_name":"Nasr Allah","state_id":"3662"},
    {"city_id":"40247","city_name":"al-'Ala","state_id":"3662"},
    {"city_id":"40248","city_name":"al-Qayrawan","state_id":"3662"},
    {"city_id":"40249","city_name":"al-Waslatiyah","state_id":"3662"},
    {"city_id":"40250","city_name":"as-Subaykhah","state_id":"3662"},
    {"city_id":"40251","city_name":"ash-Shabikah","state_id":"3662"},
    {"city_id":"40252","city_name":"ash-Sharardah","state_id":"3662"},
    {"city_id":"40253","city_name":"Adana","state_id":"3663"},
    {"city_id":"40254","city_name":"Aladag","state_id":"3663"},
    {"city_id":"40255","city_name":"Ceyhan","state_id":"3663"},
    {"city_id":"40256","city_name":"Feke","state_id":"3663"},
    {"city_id":"40257","city_name":"Imamoglu","state_id":"3663"},
    {"city_id":"40258","city_name":"Karaisali","state_id":"3663"},
    {"city_id":"40259","city_name":"Karatas","state_id":"3663"},
    {"city_id":"40260","city_name":"Kozan","state_id":"3663"},
    {"city_id":"40261","city_name":"Pozanti","state_id":"3663"},
    {"city_id":"40262","city_name":"Saimbeyli","state_id":"3663"},
    {"city_id":"40263","city_name":"Tufanbeyli","state_id":"3663"},
    {"city_id":"40264","city_name":"Yumurtalik","state_id":"3663"},
    {"city_id":"40265","city_name":"Adiyaman","state_id":"3664"},
    {"city_id":"40266","city_name":"Besni","state_id":"3664"},
    {"city_id":"40267","city_name":"Celikhan","state_id":"3664"},
    {"city_id":"40268","city_name":"Gerger","state_id":"3664"},
    {"city_id":"40269","city_name":"Golbasi","state_id":"3664"},
    {"city_id":"40270","city_name":"Kahta","state_id":"3664"},
    {"city_id":"40271","city_name":"Samsat","state_id":"3664"},
    {"city_id":"40272","city_name":"Sincik","state_id":"3664"},
    {"city_id":"40273","city_name":"Tut","state_id":"3664"},
    {"city_id":"40274","city_name":"Afyonkarahisar","state_id":"3665"},
    {"city_id":"40275","city_name":"Basmakci","state_id":"3665"},
    {"city_id":"40276","city_name":"Bayat","state_id":"3665"},
    {"city_id":"40277","city_name":"Bolvadin","state_id":"3665"},
    {"city_id":"40278","city_name":"Cay","state_id":"3665"},
    {"city_id":"40279","city_name":"Dazkiri","state_id":"3665"},
    {"city_id":"40280","city_name":"Dinar","state_id":"3665"},
    {"city_id":"40281","city_name":"Emirdag","state_id":"3665"},
    {"city_id":"40282","city_name":"Evciler","state_id":"3665"},
    {"city_id":"40283","city_name":"Hocalar","state_id":"3665"},
    {"city_id":"40284","city_name":"Ihsaniye","state_id":"3665"},
    {"city_id":"40285","city_name":"Iscehisar","state_id":"3665"},
    {"city_id":"40286","city_name":"Kiziloren","state_id":"3665"},
    {"city_id":"40287","city_name":"Sandikli","state_id":"3665"},
    {"city_id":"40288","city_name":"Sincanli","state_id":"3665"},
    {"city_id":"40289","city_name":"Suhut","state_id":"3665"},
    {"city_id":"40290","city_name":"Sultandagi","state_id":"3665"},
    {"city_id":"40291","city_name":"Diyadin","state_id":"3666"},
    {"city_id":"40292","city_name":"Dogubeyazit","state_id":"3666"},
    {"city_id":"40293","city_name":"Eleskirt","state_id":"3666"},
    {"city_id":"40294","city_name":"Hamur","state_id":"3666"},
    {"city_id":"40295","city_name":"Karakose","state_id":"3666"},
    {"city_id":"40296","city_name":"Patnos","state_id":"3666"},
    {"city_id":"40297","city_name":"Taslicay","state_id":"3666"},
    {"city_id":"40298","city_name":"Tutak","state_id":"3666"},
    {"city_id":"40299","city_name":"Agacoren","state_id":"3667"},
    {"city_id":"40300","city_name":"Aksaray","state_id":"3667"},
    {"city_id":"40301","city_name":"Eskil","state_id":"3667"},
    {"city_id":"40302","city_name":"Gulagac","state_id":"3667"},
    {"city_id":"40303","city_name":"Guzelyurt","state_id":"3667"},
    {"city_id":"40304","city_name":"Ortakoy","state_id":"3667"},
    {"city_id":"40305","city_name":"Sariyahsi","state_id":"3667"},
    {"city_id":"40306","city_name":"Amasya","state_id":"3668"},
    {"city_id":"40307","city_name":"Goynucek","state_id":"3668"},
    {"city_id":"40308","city_name":"Gumushacikoy","state_id":"3668"},
    {"city_id":"40309","city_name":"Hamamozu","state_id":"3668"},
    {"city_id":"40310","city_name":"Merzifon","state_id":"3668"},
    {"city_id":"40311","city_name":"Suluova","state_id":"3668"},
    {"city_id":"40312","city_name":"Tasova","state_id":"3668"},
    {"city_id":"40313","city_name":"Akyurt","state_id":"3669"},
    {"city_id":"40314","city_name":"Ankara","state_id":"3669"},
    {"city_id":"40315","city_name":"Ayas","state_id":"3669"},
    {"city_id":"40316","city_name":"Beypazari","state_id":"3669"},
    {"city_id":"40317","city_name":"Camlidere","state_id":"3669"},
    {"city_id":"40318","city_name":"Cubuk","state_id":"3669"},
    {"city_id":"40319","city_name":"Elmadag","state_id":"3669"},
    {"city_id":"40320","city_name":"Evren","state_id":"3669"},
    {"city_id":"40321","city_name":"Gudul","state_id":"3669"},
    {"city_id":"40322","city_name":"Haymana","state_id":"3669"},
    {"city_id":"40323","city_name":"Kalecik","state_id":"3669"},
    {"city_id":"40324","city_name":"Kazan","state_id":"3669"},
    {"city_id":"40325","city_name":"Kizilcahamam","state_id":"3669"},
    {"city_id":"40326","city_name":"Nallihan","state_id":"3669"},
    {"city_id":"40327","city_name":"Polatli","state_id":"3669"},
    {"city_id":"40328","city_name":"Sereflikochisar","state_id":"3669"},
    {"city_id":"40329","city_name":"Yenisehir","state_id":"3669"},
    {"city_id":"40330","city_name":"Akseki","state_id":"3670"},
    {"city_id":"40331","city_name":"Alanya","state_id":"3670"},
    {"city_id":"40332","city_name":"Antalya","state_id":"3670"},
    {"city_id":"40333","city_name":"Elmali","state_id":"3670"},
    {"city_id":"40334","city_name":"Finike","state_id":"3670"},
    {"city_id":"40335","city_name":"Gazipasa","state_id":"3670"},
    {"city_id":"40336","city_name":"Gundogmus","state_id":"3670"},
    {"city_id":"40337","city_name":"Ibradi","state_id":"3670"},
    {"city_id":"40338","city_name":"Kale","state_id":"3670"},
    {"city_id":"40339","city_name":"Kas","state_id":"3670"},
    {"city_id":"40340","city_name":"Kemer","state_id":"3670"},
    {"city_id":"40341","city_name":"Konya","state_id":"3670"},
    {"city_id":"40342","city_name":"Korkuteli","state_id":"3670"},
    {"city_id":"40343","city_name":"Kumluca","state_id":"3670"},
    {"city_id":"40344","city_name":"Manavgat","state_id":"3670"},
    {"city_id":"40345","city_name":"Serik","state_id":"3670"},
    {"city_id":"40346","city_name":"Ardahan","state_id":"3671"},
    {"city_id":"40347","city_name":"Damal","state_id":"3671"},
    {"city_id":"40348","city_name":"Gole","state_id":"3671"},
    {"city_id":"40349","city_name":"Hanak","state_id":"3671"},
    {"city_id":"40350","city_name":"Posof","state_id":"3671"},
    {"city_id":"40351","city_name":"Ardanuc","state_id":"3672"},
    {"city_id":"40352","city_name":"Arhavi","state_id":"3672"},
    {"city_id":"40353","city_name":"Artvin","state_id":"3672"},
    {"city_id":"40354","city_name":"Borcka","state_id":"3672"},
    {"city_id":"40355","city_name":"Hopa","state_id":"3672"},
    {"city_id":"40356","city_name":"Murgul","state_id":"3672"},
    {"city_id":"40357","city_name":"Savsat","state_id":"3672"},
    {"city_id":"40358","city_name":"Yusufeli","state_id":"3672"},
    {"city_id":"40359","city_name":"Aydin","state_id":"3673"},
    {"city_id":"40360","city_name":"Bozdogan","state_id":"3673"},
    {"city_id":"40361","city_name":"Buharkent","state_id":"3673"},
    {"city_id":"40362","city_name":"Cine","state_id":"3673"},
    {"city_id":"40363","city_name":"Didim","state_id":"3673"},
    {"city_id":"40364","city_name":"Germencik","state_id":"3673"},
    {"city_id":"40365","city_name":"Incirliova","state_id":"3673"},
    {"city_id":"40366","city_name":"Karacasu","state_id":"3673"},
    {"city_id":"40367","city_name":"Karpuzlu","state_id":"3673"},
    {"city_id":"40368","city_name":"Kocarli","state_id":"3673"},
    {"city_id":"40369","city_name":"Kosk","state_id":"3673"},
    {"city_id":"40370","city_name":"Kusadasi","state_id":"3673"},
    {"city_id":"40371","city_name":"Kuyucak","state_id":"3673"},
    {"city_id":"40372","city_name":"Nazilli","state_id":"3673"},
    {"city_id":"40373","city_name":"Soke","state_id":"3673"},
    {"city_id":"40374","city_name":"Sultanhisar","state_id":"3673"},
    {"city_id":"40375","city_name":"Yenipazar","state_id":"3673"},
    {"city_id":"40376","city_name":"Ayvalik","state_id":"3674"},
    {"city_id":"40377","city_name":"Balikesir","state_id":"3674"},
    {"city_id":"40378","city_name":"Balya","state_id":"3674"},
    {"city_id":"40379","city_name":"Bandirma","state_id":"3674"},
    {"city_id":"40380","city_name":"Bigadic","state_id":"3674"},
    {"city_id":"40381","city_name":"Burhaniye","state_id":"3674"},
    {"city_id":"40382","city_name":"Dursunbey","state_id":"3674"},
    {"city_id":"40383","city_name":"Edremit","state_id":"3674"},
    {"city_id":"40384","city_name":"Erdek","state_id":"3674"},
    {"city_id":"40385","city_name":"Gomec","state_id":"3674"},
    {"city_id":"40386","city_name":"Gonen","state_id":"3674"},
    {"city_id":"40387","city_name":"Havran","state_id":"3674"},
    {"city_id":"40388","city_name":"Ivrindi","state_id":"3674"},
    {"city_id":"40389","city_name":"Kepsut","state_id":"3674"},
    {"city_id":"40390","city_name":"Manyas","state_id":"3674"},
    {"city_id":"40391","city_name":"Marmara","state_id":"3674"},
    {"city_id":"40392","city_name":"Sakarya","state_id":"3674"},
    {"city_id":"40393","city_name":"Savastepe","state_id":"3674"},
    {"city_id":"40394","city_name":"Sindirgi","state_id":"3674"},
    {"city_id":"40395","city_name":"Susurluk","state_id":"3674"},
    {"city_id":"40396","city_name":"Amasra","state_id":"3675"},
    {"city_id":"40397","city_name":"Bartin","state_id":"3675"},
    {"city_id":"40398","city_name":"Kurucasile","state_id":"3675"},
    {"city_id":"40399","city_name":"Ulus","state_id":"3675"},
    {"city_id":"40400","city_name":"Batman","state_id":"3676"},
    {"city_id":"40401","city_name":"Besiri","state_id":"3676"},
    {"city_id":"40402","city_name":"Gercus","state_id":"3676"},
    {"city_id":"40403","city_name":"Hasankeyf","state_id":"3676"},
    {"city_id":"40404","city_name":"Kozluk","state_id":"3676"},
    {"city_id":"40405","city_name":"Sason","state_id":"3676"},
    {"city_id":"40406","city_name":"Aydintepe","state_id":"3677"},
    {"city_id":"40407","city_name":"Bayburt","state_id":"3677"},
    {"city_id":"40408","city_name":"Demirozu","state_id":"3677"},
    {"city_id":"40409","city_name":"Bilecik","state_id":"3678"},
    {"city_id":"40410","city_name":"Bozuyuk","state_id":"3678"},
    {"city_id":"40411","city_name":"Golpazari","state_id":"3678"},
    {"city_id":"40412","city_name":"Inhisar","state_id":"3678"},
    {"city_id":"40413","city_name":"Osmaneli","state_id":"3678"},
    {"city_id":"40414","city_name":"Pazaryeri","state_id":"3678"},
    {"city_id":"40415","city_name":"Sogut","state_id":"3678"},
    {"city_id":"40416","city_name":"Yenipazar","state_id":"3678"},
    {"city_id":"40417","city_name":"Adakli","state_id":"3679"},
    {"city_id":"40418","city_name":"Bingol","state_id":"3679"},
    {"city_id":"40419","city_name":"Genc","state_id":"3679"},
    {"city_id":"40420","city_name":"Karliova","state_id":"3679"},
    {"city_id":"40421","city_name":"Kigi","state_id":"3679"},
    {"city_id":"40422","city_name":"Solhan","state_id":"3679"},
    {"city_id":"40423","city_name":"Yayladere","state_id":"3679"},
    {"city_id":"40424","city_name":"Yedisu","state_id":"3679"},
    {"city_id":"40425","city_name":"Adilcevaz","state_id":"3680"},
    {"city_id":"40426","city_name":"Ahlat","state_id":"3680"},
    {"city_id":"40427","city_name":"Bitlis","state_id":"3680"},
    {"city_id":"40428","city_name":"Guroymak","state_id":"3680"},
    {"city_id":"40429","city_name":"Hizan","state_id":"3680"},
    {"city_id":"40430","city_name":"Mutki","state_id":"3680"},
    {"city_id":"40431","city_name":"Tatvan","state_id":"3680"},
    {"city_id":"40432","city_name":"Akcakoca","state_id":"3681"},
    {"city_id":"40433","city_name":"Bolu","state_id":"3681"},
    {"city_id":"40434","city_name":"Dortdivan","state_id":"3681"},
    {"city_id":"40435","city_name":"Gerede","state_id":"3681"},
    {"city_id":"40436","city_name":"Goynuk","state_id":"3681"},
    {"city_id":"40437","city_name":"Kibriscik","state_id":"3681"},
    {"city_id":"40438","city_name":"Mengen","state_id":"3681"},
    {"city_id":"40439","city_name":"Mudurnu","state_id":"3681"},
    {"city_id":"40440","city_name":"Seben","state_id":"3681"},
    {"city_id":"40441","city_name":"Yenicaga","state_id":"3681"},
    {"city_id":"40442","city_name":"Aglasun","state_id":"3682"},
    {"city_id":"40443","city_name":"Altinyayla","state_id":"3682"},
    {"city_id":"40444","city_name":"Bucak","state_id":"3682"},
    {"city_id":"40445","city_name":"Burdur","state_id":"3682"},
    {"city_id":"40446","city_name":"Golhisar","state_id":"3682"},
    {"city_id":"40447","city_name":"Karamanli","state_id":"3682"},
    {"city_id":"40448","city_name":"Kemer","state_id":"3682"},
    {"city_id":"40449","city_name":"Tefenni","state_id":"3682"},
    {"city_id":"40450","city_name":"Yesilova","state_id":"3682"},
    {"city_id":"40451","city_name":"Bursa","state_id":"3683"},
    {"city_id":"40452","city_name":"Buyukorhan","state_id":"3683"},
    {"city_id":"40453","city_name":"Gemlik","state_id":"3683"},
    {"city_id":"40454","city_name":"Gursu","state_id":"3683"},
    {"city_id":"40455","city_name":"Harmancik","state_id":"3683"},
    {"city_id":"40456","city_name":"Inegol","state_id":"3683"},
    {"city_id":"40457","city_name":"Iznik","state_id":"3683"},
    {"city_id":"40458","city_name":"Karacabey","state_id":"3683"},
    {"city_id":"40459","city_name":"Keles","state_id":"3683"},
    {"city_id":"40460","city_name":"Kestel","state_id":"3683"},
    {"city_id":"40461","city_name":"Mudanya","state_id":"3683"},
    {"city_id":"40462","city_name":"Mustafakemalpasa","state_id":"3683"},
    {"city_id":"40463","city_name":"Orhaneli","state_id":"3683"},
    {"city_id":"40464","city_name":"Orhangazi","state_id":"3683"},
    {"city_id":"40465","city_name":"Yenisehir","state_id":"3683"},
    {"city_id":"40466","city_name":"Ayvacik","state_id":"3684"},
    {"city_id":"40467","city_name":"Bayramic","state_id":"3684"},
    {"city_id":"40468","city_name":"Biga","state_id":"3684"},
    {"city_id":"40469","city_name":"Bozcaada","state_id":"3684"},
    {"city_id":"40470","city_name":"Can","state_id":"3684"},
    {"city_id":"40471","city_name":"Canakkale","state_id":"3684"},
    {"city_id":"40472","city_name":"Eceabat","state_id":"3684"},
    {"city_id":"40473","city_name":"Ezine","state_id":"3684"},
    {"city_id":"40474","city_name":"Gelibolu","state_id":"3684"},
    {"city_id":"40475","city_name":"Gokceada","state_id":"3684"},
    {"city_id":"40476","city_name":"Lapseki","state_id":"3684"},
    {"city_id":"40477","city_name":"Yenice","state_id":"3684"},
    {"city_id":"40478","city_name":"Atkaracalar","state_id":"3685"},
    {"city_id":"40479","city_name":"Bayramoren","state_id":"3685"},
    {"city_id":"40480","city_name":"Cankiri","state_id":"3685"},
    {"city_id":"40481","city_name":"Cerkes","state_id":"3685"},
    {"city_id":"40482","city_name":"Eldivan","state_id":"3685"},
    {"city_id":"40483","city_name":"Ilgaz","state_id":"3685"},
    {"city_id":"40484","city_name":"Kizilirmak","state_id":"3685"},
    {"city_id":"40485","city_name":"Korgun","state_id":"3685"},
    {"city_id":"40486","city_name":"Kursunlu","state_id":"3685"},
    {"city_id":"40487","city_name":"Orta","state_id":"3685"},
    {"city_id":"40488","city_name":"Sabanozu","state_id":"3685"},
    {"city_id":"40489","city_name":"Yaprakli","state_id":"3685"},
    {"city_id":"40490","city_name":"Alaca","state_id":"3686"},
    {"city_id":"40491","city_name":"Bayat","state_id":"3686"},
    {"city_id":"40492","city_name":"Corum","state_id":"3686"},
    {"city_id":"40493","city_name":"Dodurga","state_id":"3686"},
    {"city_id":"40494","city_name":"Iskilip","state_id":"3686"},
    {"city_id":"40495","city_name":"Kargi","state_id":"3686"},
    {"city_id":"40496","city_name":"Lacin","state_id":"3686"},
    {"city_id":"40497","city_name":"Mecitozu","state_id":"3686"},
    {"city_id":"40498","city_name":"Oguzlar","state_id":"3686"},
    {"city_id":"40499","city_name":"Ortakoy","state_id":"3686"},
    {"city_id":"40500","city_name":"Osmancik","state_id":"3686"},
    {"city_id":"40501","city_name":"Sungurlu","state_id":"3686"},
    {"city_id":"40502","city_name":"Ugurludag","state_id":"3686"},
    {"city_id":"40503","city_name":"Acipayam","state_id":"3687"},
    {"city_id":"40504","city_name":"Akkoy","state_id":"3687"},
    {"city_id":"40505","city_name":"Babadag","state_id":"3687"},
    {"city_id":"40506","city_name":"Baklan","state_id":"3687"},
    {"city_id":"40507","city_name":"Bekilli","state_id":"3687"},
    {"city_id":"40508","city_name":"Bozkurt","state_id":"3687"},
    {"city_id":"40509","city_name":"Buldan","state_id":"3687"},
    {"city_id":"40510","city_name":"Cardak","state_id":"3687"},
    {"city_id":"40511","city_name":"Civril","state_id":"3687"},
    {"city_id":"40512","city_name":"Denizli","state_id":"3687"},
    {"city_id":"40513","city_name":"Guney","state_id":"3687"},
    {"city_id":"40514","city_name":"Honaz","state_id":"3687"},
    {"city_id":"40515","city_name":"Kale","state_id":"3687"},
    {"city_id":"40516","city_name":"Saraykoy","state_id":"3687"},
    {"city_id":"40517","city_name":"Serinhisar","state_id":"3687"},
    {"city_id":"40518","city_name":"Tavas","state_id":"3687"},
    {"city_id":"40519","city_name":"Bismil","state_id":"3688"},
    {"city_id":"40520","city_name":"Cermik","state_id":"3688"},
    {"city_id":"40521","city_name":"Cinar","state_id":"3688"},
    {"city_id":"40522","city_name":"Cungus","state_id":"3688"},
    {"city_id":"40523","city_name":"Dicle","state_id":"3688"},
    {"city_id":"40524","city_name":"Diyarbakir","state_id":"3688"},
    {"city_id":"40525","city_name":"Egil","state_id":"3688"},
    {"city_id":"40526","city_name":"Ergani","state_id":"3688"},
    {"city_id":"40527","city_name":"Hani","state_id":"3688"},
    {"city_id":"40528","city_name":"Hazro","state_id":"3688"},
    {"city_id":"40529","city_name":"Kocakoy","state_id":"3688"},
    {"city_id":"40530","city_name":"Kulp","state_id":"3688"},
    {"city_id":"40531","city_name":"Lice","state_id":"3688"},
    {"city_id":"40532","city_name":"Silvan","state_id":"3688"},
    {"city_id":"40533","city_name":"Cumayeri","state_id":"3689"},
    {"city_id":"40534","city_name":"Duzce","state_id":"3689"},
    {"city_id":"40535","city_name":"Golyaka","state_id":"3689"},
    {"city_id":"40536","city_name":"Gumusova","state_id":"3689"},
    {"city_id":"40537","city_name":"Kaynasli","state_id":"3689"},
    {"city_id":"40538","city_name":"Yigilca","state_id":"3689"},
    {"city_id":"40539","city_name":"Edirne","state_id":"3690"},
    {"city_id":"40540","city_name":"Enez","state_id":"3690"},
    {"city_id":"40541","city_name":"Havsa","state_id":"3690"},
    {"city_id":"40542","city_name":"Ipsala","state_id":"3690"},
    {"city_id":"40543","city_name":"Kesan","state_id":"3690"},
    {"city_id":"40544","city_name":"Lalapasa","state_id":"3690"},
    {"city_id":"40545","city_name":"Meric","state_id":"3690"},
    {"city_id":"40546","city_name":"Suleoglu","state_id":"3690"},
    {"city_id":"40547","city_name":"Uzunkopru","state_id":"3690"},
    {"city_id":"40548","city_name":"Agin","state_id":"3691"},
    {"city_id":"40549","city_name":"Alacakaya","state_id":"3691"},
    {"city_id":"40550","city_name":"Aricak","state_id":"3691"},
    {"city_id":"40551","city_name":"Baskil","state_id":"3691"},
    {"city_id":"40552","city_name":"Elazig","state_id":"3691"},
    {"city_id":"40553","city_name":"Karakocan","state_id":"3691"},
    {"city_id":"40554","city_name":"Keban","state_id":"3691"},
    {"city_id":"40555","city_name":"Kovancilar","state_id":"3691"},
    {"city_id":"40556","city_name":"Maden","state_id":"3691"},
    {"city_id":"40557","city_name":"Palu","state_id":"3691"},
    {"city_id":"40558","city_name":"Sivrice","state_id":"3691"},
    {"city_id":"40559","city_name":"Erzincan","state_id":"3692"},
    {"city_id":"40560","city_name":"Ilic","state_id":"3692"},
    {"city_id":"40561","city_name":"Kemah","state_id":"3692"},
    {"city_id":"40562","city_name":"Kemaliye","state_id":"3692"},
    {"city_id":"40563","city_name":"Otlukbeli","state_id":"3692"},
    {"city_id":"40564","city_name":"Refahiye","state_id":"3692"},
    {"city_id":"40565","city_name":"Tercan","state_id":"3692"},
    {"city_id":"40566","city_name":"Uzumlu","state_id":"3692"},
    {"city_id":"40567","city_name":"Askale","state_id":"3693"},
    {"city_id":"40568","city_name":"Erzurum","state_id":"3693"},
    {"city_id":"40569","city_name":"Hinis","state_id":"3693"},
    {"city_id":"40570","city_name":"Horasan","state_id":"3693"},
    {"city_id":"40571","city_name":"Ilica","state_id":"3693"},
    {"city_id":"40572","city_name":"Ispir","state_id":"3693"},
    {"city_id":"40573","city_name":"Karacoban","state_id":"3693"},
    {"city_id":"40574","city_name":"Karayazi","state_id":"3693"},
    {"city_id":"40575","city_name":"Koprukoy","state_id":"3693"},
    {"city_id":"40576","city_name":"Narman","state_id":"3693"},
    {"city_id":"40577","city_name":"Oltu","state_id":"3693"},
    {"city_id":"40578","city_name":"Olur","state_id":"3693"},
    {"city_id":"40579","city_name":"Pasinler","state_id":"3693"},
    {"city_id":"40580","city_name":"Pazaryolu","state_id":"3693"},
    {"city_id":"40581","city_name":"Senkaya","state_id":"3693"},
    {"city_id":"40582","city_name":"Tekman","state_id":"3693"},
    {"city_id":"40583","city_name":"Tortum","state_id":"3693"},
    {"city_id":"40584","city_name":"Uzundere","state_id":"3693"},
    {"city_id":"40585","city_name":"Alpu","state_id":"3694"},
    {"city_id":"40586","city_name":"Beylikova","state_id":"3694"},
    {"city_id":"40587","city_name":"Cifteler","state_id":"3694"},
    {"city_id":"40588","city_name":"Eskisehir","state_id":"3694"},
    {"city_id":"40589","city_name":"Gunyuzu","state_id":"3694"},
    {"city_id":"40590","city_name":"Han","state_id":"3694"},
    {"city_id":"40591","city_name":"Inonu","state_id":"3694"},
    {"city_id":"40592","city_name":"Mahmudiye","state_id":"3694"},
    {"city_id":"40593","city_name":"Mihalgazi","state_id":"3694"},
    {"city_id":"40594","city_name":"Mihaliccik","state_id":"3694"},
    {"city_id":"40595","city_name":"Saricakaya","state_id":"3694"},
    {"city_id":"40596","city_name":"Seyitgazi","state_id":"3694"},
    {"city_id":"40597","city_name":"Sivrihisar","state_id":"3694"},
    {"city_id":"40598","city_name":"Araban","state_id":"3695"},
    {"city_id":"40599","city_name":"Gaziantep","state_id":"3695"},
    {"city_id":"40600","city_name":"Islahiye","state_id":"3695"},
    {"city_id":"40601","city_name":"Karkamis","state_id":"3695"},
    {"city_id":"40602","city_name":"Nizip","state_id":"3695"},
    {"city_id":"40603","city_name":"Nurdagi","state_id":"3695"},
    {"city_id":"40604","city_name":"Oguzeli","state_id":"3695"},
    {"city_id":"40605","city_name":"Sehitkamil","state_id":"3695"},
    {"city_id":"40606","city_name":"Yavuzeli","state_id":"3695"},
    {"city_id":"40607","city_name":"Aluca","state_id":"3696"},
    {"city_id":"40608","city_name":"Bulancak","state_id":"3696"},
    {"city_id":"40609","city_name":"Dereli","state_id":"3696"},
    {"city_id":"40610","city_name":"Dogankent","state_id":"3696"},
    {"city_id":"40611","city_name":"Espiye","state_id":"3696"},
    {"city_id":"40612","city_name":"Eynesil","state_id":"3696"},
    {"city_id":"40613","city_name":"Giresun","state_id":"3696"},
    {"city_id":"40614","city_name":"Gorele","state_id":"3696"},
    {"city_id":"40615","city_name":"Guce","state_id":"3696"},
    {"city_id":"40616","city_name":"Kesap","state_id":"3696"},
    {"city_id":"40617","city_name":"Piraziz","state_id":"3696"},
    {"city_id":"40618","city_name":"Sebinkarahisar","state_id":"3696"},
    {"city_id":"40619","city_name":"Tirebolu","state_id":"3696"},
    {"city_id":"40620","city_name":"Yaglidere","state_id":"3696"},
    {"city_id":"40621","city_name":"Gumushane","state_id":"3697"},
    {"city_id":"40622","city_name":"Kelkit","state_id":"3697"},
    {"city_id":"40623","city_name":"Kose","state_id":"3697"},
    {"city_id":"40624","city_name":"Kurtun","state_id":"3697"},
    {"city_id":"40625","city_name":"Siran","state_id":"3697"},
    {"city_id":"40626","city_name":"Torul","state_id":"3697"},
    {"city_id":"40627","city_name":"Cukurca","state_id":"3698"},
    {"city_id":"40628","city_name":"Hakkari","state_id":"3698"},
    {"city_id":"40629","city_name":"Semdinli","state_id":"3698"},
    {"city_id":"40630","city_name":"Yuksekova","state_id":"3698"},
    {"city_id":"40631","city_name":"Altinozu","state_id":"3699"},
    {"city_id":"40632","city_name":"Antakya","state_id":"3699"},
    {"city_id":"40633","city_name":"Belen","state_id":"3699"},
    {"city_id":"40634","city_name":"Dortyol","state_id":"3699"},
    {"city_id":"40635","city_name":"Erzin","state_id":"3699"},
    {"city_id":"40636","city_name":"Hassa","state_id":"3699"},
    {"city_id":"40637","city_name":"Iskenderun","state_id":"3699"},
    {"city_id":"40638","city_name":"Kirikhan","state_id":"3699"},
    {"city_id":"40639","city_name":"Kumlu","state_id":"3699"},
    {"city_id":"40640","city_name":"Reyhanli","state_id":"3699"},
    {"city_id":"40641","city_name":"Samandag","state_id":"3699"},
    {"city_id":"40642","city_name":"Yayladagi","state_id":"3699"},
    {"city_id":"40643","city_name":"Anamur","state_id":"3700"},
    {"city_id":"40644","city_name":"Aydincik","state_id":"3700"},
    {"city_id":"40645","city_name":"Bozyazi","state_id":"3700"},
    {"city_id":"40646","city_name":"Erdemli","state_id":"3700"},
    {"city_id":"40647","city_name":"Gulnar","state_id":"3700"},
    {"city_id":"40648","city_name":"Mersin","state_id":"3700"},
    {"city_id":"40649","city_name":"Mut","state_id":"3700"},
    {"city_id":"40650","city_name":"Silifke","state_id":"3700"},
    {"city_id":"40651","city_name":"Tarsus","state_id":"3700"},
    {"city_id":"40652","city_name":"Aralik","state_id":"3701"},
    {"city_id":"40653","city_name":"Igdir","state_id":"3701"},
    {"city_id":"40654","city_name":"Karakoyunlu","state_id":"3701"},
    {"city_id":"40655","city_name":"Tuzluca","state_id":"3701"},
    {"city_id":"40656","city_name":"Aksu","state_id":"3702"},
    {"city_id":"40657","city_name":"Atabey","state_id":"3702"},
    {"city_id":"40658","city_name":"Egirdir","state_id":"3702"},
    {"city_id":"40659","city_name":"Gelendost","state_id":"3702"},
    {"city_id":"40660","city_name":"Gonen","state_id":"3702"},
    {"city_id":"40661","city_name":"Isparta","state_id":"3702"},
    {"city_id":"40662","city_name":"Keciborlu","state_id":"3702"},
    {"city_id":"40663","city_name":"Sarkikaraagac","state_id":"3702"},
    {"city_id":"40664","city_name":"Senirkent","state_id":"3702"},
    {"city_id":"40665","city_name":"Sutculer","state_id":"3702"},
    {"city_id":"40666","city_name":"Uluborlu","state_id":"3702"},
    {"city_id":"40667","city_name":"Yalvac","state_id":"3702"},
    {"city_id":"40668","city_name":"Yenisarbademli","state_id":"3702"},
    {"city_id":"40669","city_name":"Buyukcekmece","state_id":"3703"},
    {"city_id":"40670","city_name":"Catalca","state_id":"3703"},
    {"city_id":"40671","city_name":"Eminonu","state_id":"3703"},
    {"city_id":"40672","city_name":"Esenler","state_id":"3703"},
    {"city_id":"40673","city_name":"Istanbul","state_id":"3703"},
    {"city_id":"40674","city_name":"KadÃ„Â±koy","state_id":"3703"},
    {"city_id":"40675","city_name":"Sile","state_id":"3703"},
    {"city_id":"40676","city_name":"Silivri","state_id":"3703"},
    {"city_id":"40677","city_name":"Sultanbeyli","state_id":"3703"},
    {"city_id":"40678","city_name":"TopkapÃ„Â±","state_id":"3703"},
    {"city_id":"40679","city_name":"Tuzla","state_id":"3703"},
    {"city_id":"40680","city_name":"Umraniye","state_id":"3703"},
    {"city_id":"40681","city_name":"Aliaga","state_id":"3704"},
    {"city_id":"40682","city_name":"Alsancak","state_id":"3704"},
    {"city_id":"40683","city_name":"Bayindir","state_id":"3704"},
    {"city_id":"40684","city_name":"Bergama","state_id":"3704"},
    {"city_id":"40685","city_name":"Beyagac","state_id":"3704"},
    {"city_id":"40686","city_name":"Bornova","state_id":"3704"},
    {"city_id":"40687","city_name":"Cesme","state_id":"3704"},
    {"city_id":"40688","city_name":"Digor","state_id":"3704"},
    {"city_id":"40689","city_name":"Dikili","state_id":"3704"},
    {"city_id":"40690","city_name":"Foca","state_id":"3704"},
    {"city_id":"40691","city_name":"Izmir","state_id":"3704"},
    {"city_id":"40692","city_name":"Karaburun","state_id":"3704"},
    {"city_id":"40693","city_name":"Kemalpasa","state_id":"3704"},
    {"city_id":"40694","city_name":"Kinik","state_id":"3704"},
    {"city_id":"40695","city_name":"Kiraz","state_id":"3704"},
    {"city_id":"40696","city_name":"Menderes","state_id":"3704"},
    {"city_id":"40697","city_name":"Menemen","state_id":"3704"},
    {"city_id":"40698","city_name":"Merkezi","state_id":"3704"},
    {"city_id":"40699","city_name":"Mersinli","state_id":"3704"},
    {"city_id":"40700","city_name":"Odemis","state_id":"3704"},
    {"city_id":"40701","city_name":"Seferihisar","state_id":"3704"},
    {"city_id":"40702","city_name":"Selcuk","state_id":"3704"},
    {"city_id":"40703","city_name":"Tire","state_id":"3704"},
    {"city_id":"40704","city_name":"Torbali","state_id":"3704"},
    {"city_id":"40705","city_name":"Urla","state_id":"3704"},
    {"city_id":"40706","city_name":"Afsin","state_id":"3705"},
    {"city_id":"40707","city_name":"Andirin","state_id":"3705"},
    {"city_id":"40708","city_name":"Caglayancerit","state_id":"3705"},
    {"city_id":"40709","city_name":"Ekinozu","state_id":"3705"},
    {"city_id":"40710","city_name":"Elbistan","state_id":"3705"},
    {"city_id":"40711","city_name":"Goksun","state_id":"3705"},
    {"city_id":"40712","city_name":"Kahramanmaras","state_id":"3705"},
    {"city_id":"40713","city_name":"Nurhak","state_id":"3705"},
    {"city_id":"40714","city_name":"Pazarcik","state_id":"3705"},
    {"city_id":"40715","city_name":"Turkoglu","state_id":"3705"},
    {"city_id":"40716","city_name":"Eflani","state_id":"3706"},
    {"city_id":"40717","city_name":"Eskipazar","state_id":"3706"},
    {"city_id":"40718","city_name":"Karabuk","state_id":"3706"},
    {"city_id":"40719","city_name":"Ovacik","state_id":"3706"},
    {"city_id":"40720","city_name":"Safranbolu","state_id":"3706"},
    {"city_id":"40721","city_name":"Yenice","state_id":"3706"},
    {"city_id":"40722","city_name":"Ayranci","state_id":"3707"},
    {"city_id":"40723","city_name":"Basyayla","state_id":"3707"},
    {"city_id":"40724","city_name":"Ermenek","state_id":"3707"},
    {"city_id":"40725","city_name":"Karaman","state_id":"3707"},
    {"city_id":"40726","city_name":"Kazimkarabekir","state_id":"3707"},
    {"city_id":"40727","city_name":"Sariveliler","state_id":"3707"},
    {"city_id":"40728","city_name":"Akyaka","state_id":"3708"},
    {"city_id":"40729","city_name":"Arpacay","state_id":"3708"},
    {"city_id":"40730","city_name":"Kagizman","state_id":"3708"},
    {"city_id":"40731","city_name":"Kars","state_id":"3708"},
    {"city_id":"40732","city_name":"Sarikamis","state_id":"3708"},
    {"city_id":"40733","city_name":"Selim","state_id":"3708"},
    {"city_id":"40734","city_name":"Susuz","state_id":"3708"},
    {"city_id":"40735","city_name":"Karsiyaka","state_id":"3709"},
    {"city_id":"40736","city_name":"Abana","state_id":"3710"},
    {"city_id":"40737","city_name":"Agli","state_id":"3710"},
    {"city_id":"40738","city_name":"Arac","state_id":"3710"},
    {"city_id":"40739","city_name":"Azdavay","state_id":"3710"},
    {"city_id":"40740","city_name":"Bozkurt","state_id":"3710"},
    {"city_id":"40741","city_name":"Daday","state_id":"3710"},
    {"city_id":"40742","city_name":"Devrekani","state_id":"3710"},
    {"city_id":"40743","city_name":"Doganyurt","state_id":"3710"},
    {"city_id":"40744","city_name":"Hanonu","state_id":"3710"},
    {"city_id":"40745","city_name":"Ihsangazi","state_id":"3710"},
    {"city_id":"40746","city_name":"Inebolu","state_id":"3710"},
    {"city_id":"40747","city_name":"Kastamonu","state_id":"3710"},
    {"city_id":"40748","city_name":"Kure","state_id":"3710"},
    {"city_id":"40749","city_name":"Pinarbasi","state_id":"3710"},
    {"city_id":"40750","city_name":"Senpazar","state_id":"3710"},
    {"city_id":"40751","city_name":"Seydiler","state_id":"3710"},
    {"city_id":"40752","city_name":"Taskopru","state_id":"3710"},
    {"city_id":"40753","city_name":"Tosya","state_id":"3710"},
    {"city_id":"40754","city_name":"Akkisla","state_id":"3711"},
    {"city_id":"40755","city_name":"Bunyan","state_id":"3711"},
    {"city_id":"40756","city_name":"Develi","state_id":"3711"},
    {"city_id":"40757","city_name":"Felahiye","state_id":"3711"},
    {"city_id":"40758","city_name":"Hacilar","state_id":"3711"},
    {"city_id":"40759","city_name":"Incesu","state_id":"3711"},
    {"city_id":"40760","city_name":"Kayseri","state_id":"3711"},
    {"city_id":"40761","city_name":"Ozvatan","state_id":"3711"},
    {"city_id":"40762","city_name":"Pinarbasi","state_id":"3711"},
    {"city_id":"40763","city_name":"Sarioglan","state_id":"3711"},
    {"city_id":"40764","city_name":"Sariz","state_id":"3711"},
    {"city_id":"40765","city_name":"Talas","state_id":"3711"},
    {"city_id":"40766","city_name":"Tomarza","state_id":"3711"},
    {"city_id":"40767","city_name":"Yahyali","state_id":"3711"},
    {"city_id":"40768","city_name":"Yesilhisar","state_id":"3711"},
    {"city_id":"40769","city_name":"Elbeyli","state_id":"3712"},
    {"city_id":"40770","city_name":"Kilis","state_id":"3712"},
    {"city_id":"40771","city_name":"Musabeyli","state_id":"3712"},
    {"city_id":"40772","city_name":"Polateli","state_id":"3712"},
    {"city_id":"40773","city_name":"Bahsili","state_id":"3713"},
    {"city_id":"40774","city_name":"Baliseyh","state_id":"3713"},
    {"city_id":"40775","city_name":"Delice","state_id":"3713"},
    {"city_id":"40776","city_name":"Karakecili","state_id":"3713"},
    {"city_id":"40777","city_name":"Keskin","state_id":"3713"},
    {"city_id":"40778","city_name":"Kirikkale","state_id":"3713"},
    {"city_id":"40779","city_name":"Sulakyurt","state_id":"3713"},
    {"city_id":"40780","city_name":"Yahsihan","state_id":"3713"},
    {"city_id":"40781","city_name":"Babaeski","state_id":"3714"},
    {"city_id":"40782","city_name":"Demirkoy","state_id":"3714"},
    {"city_id":"40783","city_name":"Kirklareli","state_id":"3714"},
    {"city_id":"40784","city_name":"Kofcaz","state_id":"3714"},
    {"city_id":"40785","city_name":"Kumkoy","state_id":"3714"},
    {"city_id":"40786","city_name":"Luleburgaz","state_id":"3714"},
    {"city_id":"40787","city_name":"Pehlivankoy","state_id":"3714"},
    {"city_id":"40788","city_name":"Pinarhisar","state_id":"3714"},
    {"city_id":"40789","city_name":"Vize","state_id":"3714"},
    {"city_id":"40790","city_name":"Akcakent","state_id":"3715"},
    {"city_id":"40791","city_name":"Akpinar","state_id":"3715"},
    {"city_id":"40792","city_name":"Boztepe","state_id":"3715"},
    {"city_id":"40793","city_name":"Kaman","state_id":"3715"},
    {"city_id":"40794","city_name":"Kirsehir","state_id":"3715"},
    {"city_id":"40795","city_name":"Mucur","state_id":"3715"},
    {"city_id":"40796","city_name":"CayÃ„Â±rova","state_id":"3716"},
    {"city_id":"40797","city_name":"Derince","state_id":"3716"},
    {"city_id":"40798","city_name":"DilovasÃ„Â±","state_id":"3716"},
    {"city_id":"40799","city_name":"Gebze","state_id":"3716"},
    {"city_id":"40800","city_name":"Golcuk","state_id":"3716"},
    {"city_id":"40801","city_name":"Izmit","state_id":"3716"},
    {"city_id":"40802","city_name":"Kandira","state_id":"3716"},
    {"city_id":"40803","city_name":"Karamursel","state_id":"3716"},
    {"city_id":"40804","city_name":"Kocaeli","state_id":"3716"},
    {"city_id":"40805","city_name":"Korfez","state_id":"3716"},
    {"city_id":"40806","city_name":"Ahirli","state_id":"3717"},
    {"city_id":"40807","city_name":"Akoren","state_id":"3717"},
    {"city_id":"40808","city_name":"Aksehir","state_id":"3717"},
    {"city_id":"40809","city_name":"Altinekin","state_id":"3717"},
    {"city_id":"40810","city_name":"Beysehir","state_id":"3717"},
    {"city_id":"40811","city_name":"Bozkir","state_id":"3717"},
    {"city_id":"40812","city_name":"Cihanbeyli","state_id":"3717"},
    {"city_id":"40813","city_name":"Cumra","state_id":"3717"},
    {"city_id":"40814","city_name":"Derbent","state_id":"3717"},
    {"city_id":"40815","city_name":"Derebucak","state_id":"3717"},
    {"city_id":"40816","city_name":"Doganhisar","state_id":"3717"},
    {"city_id":"40817","city_name":"Emirgazi","state_id":"3717"},
    {"city_id":"40818","city_name":"Eregli","state_id":"3717"},
    {"city_id":"40819","city_name":"Guneysinir","state_id":"3717"},
    {"city_id":"40820","city_name":"Hadim","state_id":"3717"},
    {"city_id":"40821","city_name":"Halkapinar","state_id":"3717"},
    {"city_id":"40822","city_name":"Huyuk","state_id":"3717"},
    {"city_id":"40823","city_name":"Ilgin","state_id":"3717"},
    {"city_id":"40824","city_name":"Kadinhani","state_id":"3717"},
    {"city_id":"40825","city_name":"Karapinar","state_id":"3717"},
    {"city_id":"40826","city_name":"Konya","state_id":"3717"},
    {"city_id":"40827","city_name":"Kulu","state_id":"3717"},
    {"city_id":"40828","city_name":"Sarayonu","state_id":"3717"},
    {"city_id":"40829","city_name":"Seydisehir","state_id":"3717"},
    {"city_id":"40830","city_name":"Taskent","state_id":"3717"},
    {"city_id":"40831","city_name":"Tuzlukcu","state_id":"3717"},
    {"city_id":"40832","city_name":"Yalihuyuk","state_id":"3717"},
    {"city_id":"40833","city_name":"Yunak","state_id":"3717"},
    {"city_id":"40834","city_name":"Altinas","state_id":"3718"},
    {"city_id":"40835","city_name":"Aslanapa","state_id":"3718"},
    {"city_id":"40836","city_name":"Domanic","state_id":"3718"},
    {"city_id":"40837","city_name":"Dumlupinar","state_id":"3718"},
    {"city_id":"40838","city_name":"Emet","state_id":"3718"},
    {"city_id":"40839","city_name":"Gediz","state_id":"3718"},
    {"city_id":"40840","city_name":"Kutahya","state_id":"3718"},
    {"city_id":"40841","city_name":"Pazarlar","state_id":"3718"},
    {"city_id":"40842","city_name":"Saphane","state_id":"3718"},
    {"city_id":"40843","city_name":"Simav","state_id":"3718"},
    {"city_id":"40844","city_name":"Tavsanli","state_id":"3718"},
    {"city_id":"40845","city_name":"Lefkosa","state_id":"3719"},
    {"city_id":"40846","city_name":"Akcadag","state_id":"3720"},
    {"city_id":"40847","city_name":"Arapkir","state_id":"3720"},
    {"city_id":"40848","city_name":"Arguvan","state_id":"3720"},
    {"city_id":"40849","city_name":"Battalgazi","state_id":"3720"},
    {"city_id":"40850","city_name":"Darende","state_id":"3720"},
    {"city_id":"40851","city_name":"Dogansehir","state_id":"3720"},
    {"city_id":"40852","city_name":"Doganyol","state_id":"3720"},
    {"city_id":"40853","city_name":"Hekimhan","state_id":"3720"},
    {"city_id":"40854","city_name":"Kale","state_id":"3720"},
    {"city_id":"40855","city_name":"Kuluncak","state_id":"3720"},
    {"city_id":"40856","city_name":"Malatya","state_id":"3720"},
    {"city_id":"40857","city_name":"Poturge","state_id":"3720"},
    {"city_id":"40858","city_name":"Yazihan","state_id":"3720"},
    {"city_id":"40859","city_name":"Yesilyurt","state_id":"3720"},
    {"city_id":"40860","city_name":"Ahmetli","state_id":"3721"},
    {"city_id":"40861","city_name":"Akhisar","state_id":"3721"},
    {"city_id":"40862","city_name":"Alasehir","state_id":"3721"},
    {"city_id":"40863","city_name":"Demirci","state_id":"3721"},
    {"city_id":"40864","city_name":"Golmarmara","state_id":"3721"},
    {"city_id":"40865","city_name":"Gordes","state_id":"3721"},
    {"city_id":"40866","city_name":"Kirkagac","state_id":"3721"},
    {"city_id":"40867","city_name":"Koprubasi","state_id":"3721"},
    {"city_id":"40868","city_name":"Kula","state_id":"3721"},
    {"city_id":"40869","city_name":"Manisa","state_id":"3721"},
    {"city_id":"40870","city_name":"Salihli","state_id":"3721"},
    {"city_id":"40871","city_name":"Sarigol","state_id":"3721"},
    {"city_id":"40872","city_name":"Saruhanli","state_id":"3721"},
    {"city_id":"40873","city_name":"Selendi","state_id":"3721"},
    {"city_id":"40874","city_name":"Soma","state_id":"3721"},
    {"city_id":"40875","city_name":"Turgutlu","state_id":"3721"},
    {"city_id":"40876","city_name":"Dargecit","state_id":"3722"},
    {"city_id":"40877","city_name":"Derik","state_id":"3722"},
    {"city_id":"40878","city_name":"Kiziltepe","state_id":"3722"},
    {"city_id":"40879","city_name":"Mardin","state_id":"3722"},
    {"city_id":"40880","city_name":"Mazidagi","state_id":"3722"},
    {"city_id":"40881","city_name":"Midyat","state_id":"3722"},
    {"city_id":"40882","city_name":"Nusaybin","state_id":"3722"},
    {"city_id":"40883","city_name":"Omerli","state_id":"3722"},
    {"city_id":"40884","city_name":"Savur","state_id":"3722"},
    {"city_id":"40885","city_name":"Yesilli","state_id":"3722"},
    {"city_id":"40886","city_name":"Bodrum","state_id":"3723"},
    {"city_id":"40887","city_name":"Dalaman","state_id":"3723"},
    {"city_id":"40888","city_name":"Datca","state_id":"3723"},
    {"city_id":"40889","city_name":"Fethiye","state_id":"3723"},
    {"city_id":"40890","city_name":"Kavaklidere","state_id":"3723"},
    {"city_id":"40891","city_name":"Koycegiz","state_id":"3723"},
    {"city_id":"40892","city_name":"Marmaris","state_id":"3723"},
    {"city_id":"40893","city_name":"Milas","state_id":"3723"},
    {"city_id":"40894","city_name":"Mugla","state_id":"3723"},
    {"city_id":"40895","city_name":"Ortaca","state_id":"3723"},
    {"city_id":"40896","city_name":"Ula","state_id":"3723"},
    {"city_id":"40897","city_name":"Yatagan","state_id":"3723"},
    {"city_id":"40898","city_name":"Bulanik","state_id":"3724"},
    {"city_id":"40899","city_name":"Haskoy","state_id":"3724"},
    {"city_id":"40900","city_name":"Korkut","state_id":"3724"},
    {"city_id":"40901","city_name":"Malazgirt","state_id":"3724"},
    {"city_id":"40902","city_name":"Mus","state_id":"3724"},
    {"city_id":"40903","city_name":"Varto","state_id":"3724"},
    {"city_id":"40904","city_name":"Acigol","state_id":"3725"},
    {"city_id":"40905","city_name":"Avanos","state_id":"3725"},
    {"city_id":"40906","city_name":"Derinkuyu","state_id":"3725"},
    {"city_id":"40907","city_name":"Gulsehir","state_id":"3725"},
    {"city_id":"40908","city_name":"Hacibektas","state_id":"3725"},
    {"city_id":"40909","city_name":"Kozakli","state_id":"3725"},
    {"city_id":"40910","city_name":"Nevsehir","state_id":"3725"},
    {"city_id":"40911","city_name":"Urgup","state_id":"3725"},
    {"city_id":"40912","city_name":"Altunhisar","state_id":"3726"},
    {"city_id":"40913","city_name":"Bor","state_id":"3726"},
    {"city_id":"40914","city_name":"Nigde","state_id":"3726"},
    {"city_id":"40915","city_name":"Ulukisla","state_id":"3726"},
    {"city_id":"40916","city_name":"Akkus","state_id":"3727"},
    {"city_id":"40917","city_name":"Aybasti","state_id":"3727"},
    {"city_id":"40918","city_name":"Camas","state_id":"3727"},
    {"city_id":"40919","city_name":"Fatsa","state_id":"3727"},
    {"city_id":"40920","city_name":"Golkoy","state_id":"3727"},
    {"city_id":"40921","city_name":"Gulyali","state_id":"3727"},
    {"city_id":"40922","city_name":"Gurgentepe","state_id":"3727"},
    {"city_id":"40923","city_name":"Ikizce","state_id":"3727"},
    {"city_id":"40924","city_name":"Kabaduz","state_id":"3727"},
    {"city_id":"40925","city_name":"Kabatas","state_id":"3727"},
    {"city_id":"40926","city_name":"Korgan","state_id":"3727"},
    {"city_id":"40927","city_name":"Kumru","state_id":"3727"},
    {"city_id":"40928","city_name":"Mesudiye","state_id":"3727"},
    {"city_id":"40929","city_name":"Ordu","state_id":"3727"},
    {"city_id":"40930","city_name":"Persembe","state_id":"3727"},
    {"city_id":"40931","city_name":"Ulubey","state_id":"3727"},
    {"city_id":"40932","city_name":"Unye","state_id":"3727"},
    {"city_id":"40933","city_name":"Bahce","state_id":"3728"},
    {"city_id":"40934","city_name":"Duzici","state_id":"3728"},
    {"city_id":"40935","city_name":"Hasanbeyli","state_id":"3728"},
    {"city_id":"40936","city_name":"Kadirli","state_id":"3728"},
    {"city_id":"40937","city_name":"Osmaniye","state_id":"3728"},
    {"city_id":"40938","city_name":"Sumbas","state_id":"3728"},
    {"city_id":"40939","city_name":"Toprakkale","state_id":"3728"},
    {"city_id":"40940","city_name":"Ardesen","state_id":"3729"},
    {"city_id":"40941","city_name":"Cayeli","state_id":"3729"},
    {"city_id":"40942","city_name":"Derepazan","state_id":"3729"},
    {"city_id":"40943","city_name":"Findikli","state_id":"3729"},
    {"city_id":"40944","city_name":"Guneysu","state_id":"3729"},
    {"city_id":"40945","city_name":"Hemsin","state_id":"3729"},
    {"city_id":"40946","city_name":"Ikizdere","state_id":"3729"},
    {"city_id":"40947","city_name":"Iyidere","state_id":"3729"},
    {"city_id":"40948","city_name":"Kalkandere","state_id":"3729"},
    {"city_id":"40949","city_name":"Pazar","state_id":"3729"},
    {"city_id":"40950","city_name":"Rize","state_id":"3729"},
    {"city_id":"40951","city_name":"Adapazari","state_id":"3730"},
    {"city_id":"40952","city_name":"Akyazi","state_id":"3730"},
    {"city_id":"40953","city_name":"Ferizli","state_id":"3730"},
    {"city_id":"40954","city_name":"Geyve","state_id":"3730"},
    {"city_id":"40955","city_name":"Hendek","state_id":"3730"},
    {"city_id":"40956","city_name":"Karapurcek","state_id":"3730"},
    {"city_id":"40957","city_name":"Karasu","state_id":"3730"},
    {"city_id":"40958","city_name":"Kaynarca","state_id":"3730"},
    {"city_id":"40959","city_name":"Kocaali","state_id":"3730"},
    {"city_id":"40960","city_name":"Pamukova","state_id":"3730"},
    {"city_id":"40961","city_name":"Sapanca","state_id":"3730"},
    {"city_id":"40962","city_name":"Sogutlu","state_id":"3730"},
    {"city_id":"40963","city_name":"Tarakli","state_id":"3730"},
    {"city_id":"40964","city_name":"Akcakale","state_id":"3731"},
    {"city_id":"40965","city_name":"Alacam","state_id":"3731"},
    {"city_id":"40966","city_name":"Asarcik","state_id":"3731"},
    {"city_id":"40967","city_name":"Ayvacik","state_id":"3731"},
    {"city_id":"40968","city_name":"Bafra","state_id":"3731"},
    {"city_id":"40969","city_name":"Carsamba","state_id":"3731"},
    {"city_id":"40970","city_name":"Havza","state_id":"3731"},
    {"city_id":"40971","city_name":"Kavak","state_id":"3731"},
    {"city_id":"40972","city_name":"Ladik","state_id":"3731"},
    {"city_id":"40973","city_name":"Mayis 19","state_id":"3731"},
    {"city_id":"40974","city_name":"Salipazan","state_id":"3731"},
    {"city_id":"40975","city_name":"Samsun","state_id":"3731"},
    {"city_id":"40976","city_name":"Tekkekoy","state_id":"3731"},
    {"city_id":"40977","city_name":"Terme","state_id":"3731"},
    {"city_id":"40978","city_name":"Vezirkopru","state_id":"3731"},
    {"city_id":"40979","city_name":"Yakakent","state_id":"3731"},
    {"city_id":"40980","city_name":"Birecik","state_id":"3732"},
    {"city_id":"40981","city_name":"Bozova","state_id":"3732"},
    {"city_id":"40982","city_name":"Ceylanpinar","state_id":"3732"},
    {"city_id":"40983","city_name":"Halfeti","state_id":"3732"},
    {"city_id":"40984","city_name":"Harran","state_id":"3732"},
    {"city_id":"40985","city_name":"Hilvan","state_id":"3732"},
    {"city_id":"40986","city_name":"Sanliurfa","state_id":"3732"},
    {"city_id":"40987","city_name":"Siverek","state_id":"3732"},
    {"city_id":"40988","city_name":"Suruc","state_id":"3732"},
    {"city_id":"40989","city_name":"Urfa","state_id":"3732"},
    {"city_id":"40990","city_name":"Viransehir","state_id":"3732"},
    {"city_id":"40991","city_name":"Aydinlar","state_id":"3733"},
    {"city_id":"40992","city_name":"Baykan","state_id":"3733"},
    {"city_id":"40993","city_name":"Eruh","state_id":"3733"},
    {"city_id":"40994","city_name":"Kurtalan","state_id":"3733"},
    {"city_id":"40995","city_name":"Pervari","state_id":"3733"},
    {"city_id":"40996","city_name":"Siirt","state_id":"3733"},
    {"city_id":"40997","city_name":"Sirvan","state_id":"3733"},
    {"city_id":"40998","city_name":"Ayancik","state_id":"3734"},
    {"city_id":"40999","city_name":"Boyabat","state_id":"3734"},
    {"city_id":"41000","city_name":"Dikmen","state_id":"3734"},
    {"city_id":"41001","city_name":"Duragan","state_id":"3734"},
    {"city_id":"41002","city_name":"Erfelek","state_id":"3734"},
    {"city_id":"41003","city_name":"Gerze","state_id":"3734"},
    {"city_id":"41004","city_name":"Sarayduzu","state_id":"3734"},
    {"city_id":"41005","city_name":"Sinop","state_id":"3734"},
    {"city_id":"41006","city_name":"Turkeli","state_id":"3734"},
    {"city_id":"41007","city_name":"Beytussebap","state_id":"3735"},
    {"city_id":"41008","city_name":"Cizre","state_id":"3735"},
    {"city_id":"41009","city_name":"Guclukonak","state_id":"3735"},
    {"city_id":"41010","city_name":"Idil","state_id":"3735"},
    {"city_id":"41011","city_name":"Silopi","state_id":"3735"},
    {"city_id":"41012","city_name":"Sirnak","state_id":"3735"},
    {"city_id":"41013","city_name":"Uludere","state_id":"3735"},
    {"city_id":"41014","city_name":"Akincilar","state_id":"3736"},
    {"city_id":"41015","city_name":"Altinyayla","state_id":"3736"},
    {"city_id":"41016","city_name":"Divrigi","state_id":"3736"},
    {"city_id":"41017","city_name":"Dogansar","state_id":"3736"},
    {"city_id":"41018","city_name":"Gemerek","state_id":"3736"},
    {"city_id":"41019","city_name":"Golova","state_id":"3736"},
    {"city_id":"41020","city_name":"Gurun","state_id":"3736"},
    {"city_id":"41021","city_name":"Hafik","state_id":"3736"},
    {"city_id":"41022","city_name":"Imranli","state_id":"3736"},
    {"city_id":"41023","city_name":"Kangal","state_id":"3736"},
    {"city_id":"41024","city_name":"Koyulhisar","state_id":"3736"},
    {"city_id":"41025","city_name":"Sarkisla","state_id":"3736"},
    {"city_id":"41026","city_name":"Sivas","state_id":"3736"},
    {"city_id":"41027","city_name":"Susehri","state_id":"3736"},
    {"city_id":"41028","city_name":"Ulas","state_id":"3736"},
    {"city_id":"41029","city_name":"Yildizeli","state_id":"3736"},
    {"city_id":"41030","city_name":"Zara","state_id":"3736"},
    {"city_id":"41031","city_name":"Cerkezkoy","state_id":"3737"},
    {"city_id":"41032","city_name":"Corlu","state_id":"3737"},
    {"city_id":"41033","city_name":"Hayrabolu","state_id":"3737"},
    {"city_id":"41034","city_name":"Malkara","state_id":"3737"},
    {"city_id":"41035","city_name":"Marmaraereglisi","state_id":"3737"},
    {"city_id":"41036","city_name":"Muratli","state_id":"3737"},
    {"city_id":"41037","city_name":"Saray","state_id":"3737"},
    {"city_id":"41038","city_name":"Sarkoy","state_id":"3737"},
    {"city_id":"41039","city_name":"Tekirdag","state_id":"3737"},
    {"city_id":"41040","city_name":"Almus","state_id":"3738"},
    {"city_id":"41041","city_name":"Artova","state_id":"3738"},
    {"city_id":"41042","city_name":"Basciftlik","state_id":"3738"},
    {"city_id":"41043","city_name":"Erbaa","state_id":"3738"},
    {"city_id":"41044","city_name":"Niksar","state_id":"3738"},
    {"city_id":"41045","city_name":"Pazar","state_id":"3738"},
    {"city_id":"41046","city_name":"Resadiye","state_id":"3738"},
    {"city_id":"41047","city_name":"Sulusaray","state_id":"3738"},
    {"city_id":"41048","city_name":"Tokat","state_id":"3738"},
    {"city_id":"41049","city_name":"Turhal","state_id":"3738"},
    {"city_id":"41050","city_name":"Yesilyurt","state_id":"3738"},
    {"city_id":"41051","city_name":"Zile","state_id":"3738"},
    {"city_id":"41052","city_name":"Akcaabat","state_id":"3739"},
    {"city_id":"41053","city_name":"Arakli","state_id":"3739"},
    {"city_id":"41054","city_name":"Arsin","state_id":"3739"},
    {"city_id":"41055","city_name":"Besikduzu","state_id":"3739"},
    {"city_id":"41056","city_name":"Caykara","state_id":"3739"},
    {"city_id":"41057","city_name":"Dernekpazari","state_id":"3739"},
    {"city_id":"41058","city_name":"Duzkoy","state_id":"3739"},
    {"city_id":"41059","city_name":"Hayrat","state_id":"3739"},
    {"city_id":"41060","city_name":"Koprubasi","state_id":"3739"},
    {"city_id":"41061","city_name":"Macka","state_id":"3739"},
    {"city_id":"41062","city_name":"Of","state_id":"3739"},
    {"city_id":"41063","city_name":"Salpazari","state_id":"3739"},
    {"city_id":"41064","city_name":"Surmene","state_id":"3739"},
    {"city_id":"41065","city_name":"Tonya","state_id":"3739"},
    {"city_id":"41066","city_name":"Trabzon","state_id":"3739"},
    {"city_id":"41067","city_name":"Vakfikebir","state_id":"3739"},
    {"city_id":"41068","city_name":"Yomra","state_id":"3739"},
    {"city_id":"41069","city_name":"Hozat","state_id":"3740"},
    {"city_id":"41070","city_name":"Mazgirt","state_id":"3740"},
    {"city_id":"41071","city_name":"Nazimiye","state_id":"3740"},
    {"city_id":"41072","city_name":"Ovacik","state_id":"3740"},
    {"city_id":"41073","city_name":"Pertek","state_id":"3740"},
    {"city_id":"41074","city_name":"Pulumur","state_id":"3740"},
    {"city_id":"41075","city_name":"Tunceli","state_id":"3740"},
    {"city_id":"41076","city_name":"Banaz","state_id":"3741"},
    {"city_id":"41077","city_name":"Esme","state_id":"3741"},
    {"city_id":"41078","city_name":"Karahalli","state_id":"3741"},
    {"city_id":"41079","city_name":"Sivasli","state_id":"3741"},
    {"city_id":"41080","city_name":"Ulubey","state_id":"3741"},
    {"city_id":"41081","city_name":"Usak","state_id":"3741"},
    {"city_id":"41082","city_name":"Bahcesaray","state_id":"3742"},
    {"city_id":"41083","city_name":"Baskale","state_id":"3742"},
    {"city_id":"41084","city_name":"Caldiran","state_id":"3742"},
    {"city_id":"41085","city_name":"Edremit","state_id":"3742"},
    {"city_id":"41086","city_name":"Ercis","state_id":"3742"},
    {"city_id":"41087","city_name":"Gevas","state_id":"3742"},
    {"city_id":"41088","city_name":"Gurpinar","state_id":"3742"},
    {"city_id":"41089","city_name":"Muradiye","state_id":"3742"},
    {"city_id":"41090","city_name":"Ozalp","state_id":"3742"},
    {"city_id":"41091","city_name":"Saray","state_id":"3742"},
    {"city_id":"41092","city_name":"Van","state_id":"3742"},
    {"city_id":"41093","city_name":"Altinova","state_id":"3743"},
    {"city_id":"41094","city_name":"Armutlu","state_id":"3743"},
    {"city_id":"41095","city_name":"Ciftlikkoy","state_id":"3743"},
    {"city_id":"41096","city_name":"Cinarcik","state_id":"3743"},
    {"city_id":"41097","city_name":"Termal","state_id":"3743"},
    {"city_id":"41098","city_name":"Yalova","state_id":"3743"},
    {"city_id":"41099","city_name":"Akdagmadeni","state_id":"3744"},
    {"city_id":"41100","city_name":"Aydincik","state_id":"3744"},
    {"city_id":"41101","city_name":"Bogaziliyan","state_id":"3744"},
    {"city_id":"41102","city_name":"Candir","state_id":"3744"},
    {"city_id":"41103","city_name":"Cayiralan","state_id":"3744"},
    {"city_id":"41104","city_name":"Cekerek","state_id":"3744"},
    {"city_id":"41105","city_name":"Kadisehri","state_id":"3744"},
    {"city_id":"41106","city_name":"Saraykent","state_id":"3744"},
    {"city_id":"41107","city_name":"Sarikaya","state_id":"3744"},
    {"city_id":"41108","city_name":"Sefaatli","state_id":"3744"},
    {"city_id":"41109","city_name":"Sorgun","state_id":"3744"},
    {"city_id":"41110","city_name":"Yenifakili","state_id":"3744"},
    {"city_id":"41111","city_name":"Yerkoy","state_id":"3744"},
    {"city_id":"41112","city_name":"Yozgat","state_id":"3744"},
    {"city_id":"41113","city_name":"Alapli","state_id":"3745"},
    {"city_id":"41114","city_name":"Caycuma","state_id":"3745"},
    {"city_id":"41115","city_name":"Devrek","state_id":"3745"},
    {"city_id":"41116","city_name":"Eregli","state_id":"3745"},
    {"city_id":"41117","city_name":"Gokcebey","state_id":"3745"},
    {"city_id":"41118","city_name":"Zonguldak","state_id":"3745"},
    {"city_id":"41119","city_name":"Ahchadepe","state_id":"3746"},
    {"city_id":"41120","city_name":"Annau","state_id":"3746"},
    {"city_id":"41121","city_name":"Babadayhan","state_id":"3746"},
    {"city_id":"41122","city_name":"Baherden","state_id":"3746"},
    {"city_id":"41123","city_name":"Birleshik Pervi","state_id":"3746"},
    {"city_id":"41124","city_name":"Buzmeyin","state_id":"3746"},
    {"city_id":"41125","city_name":"Dushak","state_id":"3746"},
    {"city_id":"41126","city_name":"Gokedepe","state_id":"3746"},
    {"city_id":"41127","city_name":"Kaka","state_id":"3746"},
    {"city_id":"41128","city_name":"Miana","state_id":"3746"},
    {"city_id":"41129","city_name":"Ovaz Jalatay","state_id":"3746"},
    {"city_id":"41130","city_name":"Saragt","state_id":"3746"},
    {"city_id":"41131","city_name":"Tecen","state_id":"3746"},
    {"city_id":"41132","city_name":"Asgabat","state_id":"3747"},
    {"city_id":"41133","city_name":"Adyndaky","state_id":"3748"},
    {"city_id":"41134","city_name":"Balkanabat","state_id":"3748"},
    {"city_id":"41135","city_name":"Bekdash","state_id":"3748"},
    {"city_id":"41136","city_name":"Gasanguli","state_id":"3748"},
    {"city_id":"41137","city_name":"Gazanjyk","state_id":"3748"},
    {"city_id":"41138","city_name":"Goturdepe","state_id":"3748"},
    {"city_id":"41139","city_name":"Gumdag","state_id":"3748"},
    {"city_id":"41140","city_name":"Jebel","state_id":"3748"},
    {"city_id":"41141","city_name":"Karakala","state_id":"3748"},
    {"city_id":"41142","city_name":"Khazar","state_id":"3748"},
    {"city_id":"41143","city_name":"Serdar","state_id":"3748"},
    {"city_id":"41144","city_name":"Sharlawuk","state_id":"3748"},
    {"city_id":"41145","city_name":"Turkmenbasi","state_id":"3748"},
    {"city_id":"41146","city_name":"Akdepe","state_id":"3749"},
    {"city_id":"41147","city_name":"Boldumsaz","state_id":"3749"},
    {"city_id":"41148","city_name":"Dasoguz","state_id":"3749"},
    {"city_id":"41149","city_name":"Gubadag","state_id":"3749"},
    {"city_id":"41150","city_name":"Kohne Urgenc","state_id":"3749"},
    {"city_id":"41151","city_name":"Moskva","state_id":"3749"},
    {"city_id":"41152","city_name":"Tagta","state_id":"3749"},
    {"city_id":"41153","city_name":"Yilanli","state_id":"3749"},
    {"city_id":"41154","city_name":"Darganata","state_id":"3750"},
    {"city_id":"41155","city_name":"Esenmengli","state_id":"3750"},
    {"city_id":"41156","city_name":"Farap","state_id":"3750"},
    {"city_id":"41157","city_name":"Gazojak","state_id":"3750"},
    {"city_id":"41158","city_name":"Govurdak","state_id":"3750"},
    {"city_id":"41159","city_name":"Halac","state_id":"3750"},
    {"city_id":"41160","city_name":"Kerki","state_id":"3750"},
    {"city_id":"41161","city_name":"Komsomolsk","state_id":"3750"},
    {"city_id":"41162","city_name":"Mukri","state_id":"3750"},
    {"city_id":"41163","city_name":"Sayat","state_id":"3750"},
    {"city_id":"41164","city_name":"Seydi","state_id":"3750"},
    {"city_id":"41165","city_name":"Turkmenabat","state_id":"3750"},
    {"city_id":"41166","city_name":"Bayramali","state_id":"3751"},
    {"city_id":"41167","city_name":"Eloten","state_id":"3751"},
    {"city_id":"41168","city_name":"Mari","state_id":"3751"},
    {"city_id":"41169","city_name":"Murgab","state_id":"3751"},
    {"city_id":"41170","city_name":"Sakarchage","state_id":"3751"},
    {"city_id":"41171","city_name":"Tagtabazar","state_id":"3751"},
    {"city_id":"41172","city_name":"Turkmengala","state_id":"3751"},
    {"city_id":"41173","city_name":"Vaiaku","state_id":"3754"},
    {"city_id":"41174","city_name":"Tonga","state_id":"3755"},
    {"city_id":"41175","city_name":"Lolua","state_id":"3756"},
    {"city_id":"41176","city_name":"Kua","state_id":"3757"},
    {"city_id":"41177","city_name":"Tanrake","state_id":"3758"},
    {"city_id":"41178","city_name":"Savave","state_id":"3759"},
    {"city_id":"41179","city_name":"Fangaua","state_id":"3760"},
    {"city_id":"41180","city_name":"Asau","state_id":"3761"},
    {"city_id":"41181","city_name":"Bombo","state_id":"3762"},
    {"city_id":"41182","city_name":"Bweyogerere","state_id":"3762"},
    {"city_id":"41183","city_name":"Entebbe","state_id":"3762"},
    {"city_id":"41184","city_name":"Jinja-Kawempe","state_id":"3762"},
    {"city_id":"41185","city_name":"Kajansi","state_id":"3762"},
    {"city_id":"41186","city_name":"Kalangala","state_id":"3762"},
    {"city_id":"41187","city_name":"Kampala","state_id":"3762"},
    {"city_id":"41188","city_name":"Kayunga","state_id":"3762"},
    {"city_id":"41189","city_name":"Kiboga","state_id":"3762"},
    {"city_id":"41190","city_name":"Kireka","state_id":"3762"},
    {"city_id":"41191","city_name":"Kyotera","state_id":"3762"},
    {"city_id":"41192","city_name":"Lugazi","state_id":"3762"},
    {"city_id":"41193","city_name":"Lukaya","state_id":"3762"},
    {"city_id":"41194","city_name":"Luwero","state_id":"3762"},
    {"city_id":"41195","city_name":"Lyantonde","state_id":"3762"},
    {"city_id":"41196","city_name":"Masaka","state_id":"3762"},
    {"city_id":"41197","city_name":"Mityana","state_id":"3762"},
    {"city_id":"41198","city_name":"Mpigi","state_id":"3762"},
    {"city_id":"41199","city_name":"Mubende","state_id":"3762"},
    {"city_id":"41200","city_name":"Mukono","state_id":"3762"},
    {"city_id":"41201","city_name":"Nakasongola","state_id":"3762"},
    {"city_id":"41202","city_name":"Namasuba","state_id":"3762"},
    {"city_id":"41203","city_name":"Njeru","state_id":"3762"},
    {"city_id":"41204","city_name":"Nkonkonjeru","state_id":"3762"},
    {"city_id":"41205","city_name":"Rakai","state_id":"3762"},
    {"city_id":"41206","city_name":"Sembabule","state_id":"3762"},
    {"city_id":"41207","city_name":"Wakiso","state_id":"3762"},
    {"city_id":"41208","city_name":"Wobulenzi","state_id":"3762"},
    {"city_id":"41209","city_name":"Bugembe","state_id":"3763"},
    {"city_id":"41210","city_name":"Bugiri","state_id":"3763"},
    {"city_id":"41211","city_name":"Busembatia","state_id":"3763"},
    {"city_id":"41212","city_name":"Busia","state_id":"3763"},
    {"city_id":"41213","city_name":"Buwenge","state_id":"3763"},
    {"city_id":"41214","city_name":"Iganga","state_id":"3763"},
    {"city_id":"41215","city_name":"Jinja","state_id":"3763"},
    {"city_id":"41216","city_name":"Kaberamaido","state_id":"3763"},
    {"city_id":"41217","city_name":"Kamuli","state_id":"3763"},
    {"city_id":"41218","city_name":"Kapchorwa","state_id":"3763"},
    {"city_id":"41219","city_name":"Katakwi","state_id":"3763"},
    {"city_id":"41220","city_name":"Kumi","state_id":"3763"},
    {"city_id":"41221","city_name":"Malaba","state_id":"3763"},
    {"city_id":"41222","city_name":"Mayuge","state_id":"3763"},
    {"city_id":"41223","city_name":"Mbale","state_id":"3763"},
    {"city_id":"41224","city_name":"Ngora","state_id":"3763"},
    {"city_id":"41225","city_name":"Pallisa","state_id":"3763"},
    {"city_id":"41226","city_name":"Sironko","state_id":"3763"},
    {"city_id":"41227","city_name":"Soroti","state_id":"3763"},
    {"city_id":"41228","city_name":"Tororo","state_id":"3763"},
    {"city_id":"41229","city_name":"Adjumani","state_id":"3764"},
    {"city_id":"41230","city_name":"Amudat","state_id":"3764"},
    {"city_id":"41231","city_name":"Apac","state_id":"3764"},
    {"city_id":"41232","city_name":"Arua","state_id":"3764"},
    {"city_id":"41233","city_name":"Gulu","state_id":"3764"},
    {"city_id":"41234","city_name":"Kaabong","state_id":"3764"},
    {"city_id":"41235","city_name":"Kitgum","state_id":"3764"},
    {"city_id":"41236","city_name":"Koboko","state_id":"3764"},
    {"city_id":"41237","city_name":"Kotido","state_id":"3764"},
    {"city_id":"41238","city_name":"Lira","state_id":"3764"},
    {"city_id":"41239","city_name":"Moroto","state_id":"3764"},
    {"city_id":"41240","city_name":"Moyo","state_id":"3764"},
    {"city_id":"41241","city_name":"Nakapiripirit","state_id":"3764"},
    {"city_id":"41242","city_name":"Nebbi","state_id":"3764"},
    {"city_id":"41243","city_name":"Pader","state_id":"3764"},
    {"city_id":"41244","city_name":"Paidha","state_id":"3764"},
    {"city_id":"41245","city_name":"Pakwach","state_id":"3764"},
    {"city_id":"41246","city_name":"Yumbe","state_id":"3764"},
    {"city_id":"41247","city_name":"Bundibugyo","state_id":"3765"},
    {"city_id":"41248","city_name":"Bushenyi","state_id":"3765"},
    {"city_id":"41249","city_name":"Fort Portal","state_id":"3765"},
    {"city_id":"41250","city_name":"Hima","state_id":"3765"},
    {"city_id":"41251","city_name":"Hoima","state_id":"3765"},
    {"city_id":"41252","city_name":"Ibanda","state_id":"3765"},
    {"city_id":"41253","city_name":"Kabale","state_id":"3765"},
    {"city_id":"41254","city_name":"Kabwohe-Itendero","state_id":"3765"},
    {"city_id":"41255","city_name":"Kagadi","state_id":"3765"},
    {"city_id":"41256","city_name":"Kamwenge","state_id":"3765"},
    {"city_id":"41257","city_name":"Kanungu","state_id":"3765"},
    {"city_id":"41258","city_name":"Kasese","state_id":"3765"},
    {"city_id":"41259","city_name":"Katwe","state_id":"3765"},
    {"city_id":"41260","city_name":"Kibaale","state_id":"3765"},
    {"city_id":"41261","city_name":"Kigorobya","state_id":"3765"},
    {"city_id":"41262","city_name":"Kilembe","state_id":"3765"},
    {"city_id":"41263","city_name":"Kisoro","state_id":"3765"},
    {"city_id":"41264","city_name":"Kyenjojo","state_id":"3765"},
    {"city_id":"41265","city_name":"Masindi","state_id":"3765"},
    {"city_id":"41266","city_name":"Masindi-Port","state_id":"3765"},
    {"city_id":"41267","city_name":"Mbarara","state_id":"3765"},
    {"city_id":"41268","city_name":"Muhororo","state_id":"3765"},
    {"city_id":"41269","city_name":"Ntungamo","state_id":"3765"},
    {"city_id":"41270","city_name":"Rukungiri","state_id":"3765"},
    {"city_id":"41271","city_name":"Cherkasy","state_id":"3766"},
    {"city_id":"41272","city_name":"Smila","state_id":"3766"},
    {"city_id":"41273","city_name":"Uman'","state_id":"3766"},
    {"city_id":"41274","city_name":"Chernihiv","state_id":"3767"},
    {"city_id":"41275","city_name":"Nizhyn","state_id":"3767"},
    {"city_id":"41276","city_name":"Pryluky","state_id":"3767"},
    {"city_id":"41277","city_name":"Chernivtsi","state_id":"3768"},
    {"city_id":"41278","city_name":"Alushta","state_id":"3769"},
    {"city_id":"41279","city_name":"Sevastopol","state_id":"3769"},
    {"city_id":"41280","city_name":"Dneprodzerzhinsk","state_id":"3770"},
    {"city_id":"41281","city_name":"Dnipropetrovsk","state_id":"3770"},
    {"city_id":"41282","city_name":"Kryvyy Rih","state_id":"3770"},
    {"city_id":"41283","city_name":"Marhanets'","state_id":"3770"},
    {"city_id":"41284","city_name":"Nikopol'","state_id":"3770"},
    {"city_id":"41285","city_name":"Novomoskovs'k","state_id":"3770"},
    {"city_id":"41286","city_name":"Pavlohrad","state_id":"3770"},
    {"city_id":"41287","city_name":"Plekhanov","state_id":"3770"},
    {"city_id":"41288","city_name":"Zhovti Vody","state_id":"3770"},
    {"city_id":"41289","city_name":"Artemivs'k","state_id":"3771"},
    {"city_id":"41290","city_name":"Donets'k","state_id":"3771"},
    {"city_id":"41291","city_name":"Donetsk","state_id":"3771"},
    {"city_id":"41292","city_name":"Druzhkivka","state_id":"3771"},
    {"city_id":"41293","city_name":"Dymytrov","state_id":"3771"},
    {"city_id":"41294","city_name":"Dzerzhyns'k","state_id":"3771"},
    {"city_id":"41295","city_name":"Horlivka","state_id":"3771"},
    {"city_id":"41296","city_name":"Khartsyz'k","state_id":"3771"},
    {"city_id":"41297","city_name":"Kostyantynivka","state_id":"3771"},
    {"city_id":"41298","city_name":"Kramators'k","state_id":"3771"},
    {"city_id":"41299","city_name":"Krasnoarmiys'k","state_id":"3771"},
    {"city_id":"41300","city_name":"Makiyivka","state_id":"3771"},
    {"city_id":"41301","city_name":"Mariupol'","state_id":"3771"},
    {"city_id":"41302","city_name":"Shakhtars'k","state_id":"3771"},
    {"city_id":"41303","city_name":"Slov'yans'k","state_id":"3771"},
    {"city_id":"41304","city_name":"Snizhne","state_id":"3771"},
    {"city_id":"41305","city_name":"Torez","state_id":"3771"},
    {"city_id":"41306","city_name":"Yenakiyeve","state_id":"3771"},
    {"city_id":"41307","city_name":"Ivano-Frankivs'k","state_id":"3772"},
    {"city_id":"41308","city_name":"Kalush","state_id":"3772"},
    {"city_id":"41309","city_name":"Kolomyya","state_id":"3772"},
    {"city_id":"41310","city_name":"Izyum","state_id":"3773"},
    {"city_id":"41311","city_name":"Kharkiv","state_id":"3773"},
    {"city_id":"41312","city_name":"Lozova","state_id":"3773"},
    {"city_id":"41313","city_name":"Volchansk","state_id":"3774"},
    {"city_id":"41314","city_name":"Kherson","state_id":"3775"},
    {"city_id":"41315","city_name":"Nova Kakhovka","state_id":"3775"},
    {"city_id":"41316","city_name":"Geologov","state_id":"3776"},
    {"city_id":"41317","city_name":"Kam'yanets'-Podil's'kyy","state_id":"3776"},
    {"city_id":"41318","city_name":"Khmel'nyts'kyy","state_id":"3776"},
    {"city_id":"41319","city_name":"Shepetivka","state_id":"3776"},
    {"city_id":"41320","city_name":"khmelnitskiy","state_id":"3776"},
    {"city_id":"41321","city_name":"Kirovohrad","state_id":"3777"},
    {"city_id":"41322","city_name":"Oleksandriya","state_id":"3777"},
    {"city_id":"41323","city_name":"Svidlovodsk","state_id":"3777"},
    {"city_id":"41324","city_name":"Dzhankoy","state_id":"3778"},
    {"city_id":"41325","city_name":"Feodosiya","state_id":"3778"},
    {"city_id":"41326","city_name":"Kerch","state_id":"3778"},
    {"city_id":"41327","city_name":"Simferopol'","state_id":"3778"},
    {"city_id":"41328","city_name":"Yalta","state_id":"3778"},
    {"city_id":"41329","city_name":"Yevpatoriya","state_id":"3778"},
    {"city_id":"41330","city_name":"Kiev","state_id":"3779"},
    {"city_id":"41331","city_name":"Kyyiv","state_id":"3779"},
    {"city_id":"41332","city_name":"Bila Tserkva","state_id":"3780"},
    {"city_id":"41333","city_name":"Boryspil'","state_id":"3780"},
    {"city_id":"41334","city_name":"Brovary","state_id":"3780"},
    {"city_id":"41335","city_name":"Fastiv","state_id":"3780"},
    {"city_id":"41336","city_name":"Chervonohrad","state_id":"3781"},
    {"city_id":"41337","city_name":"Drohobych","state_id":"3781"},
    {"city_id":"41338","city_name":"L'viv","state_id":"3781"},
    {"city_id":"41339","city_name":"Stryy","state_id":"3781"},
    {"city_id":"41340","city_name":"Yavoriv","state_id":"3781"},
    {"city_id":"41341","city_name":"Alchevs'k","state_id":"3782"},
    {"city_id":"41342","city_name":"Antratsyt","state_id":"3782"},
    {"city_id":"41343","city_name":"Bryanka","state_id":"3782"},
    {"city_id":"41344","city_name":"Krasnodon","state_id":"3782"},
    {"city_id":"41345","city_name":"Krasnyy Luch","state_id":"3782"},
    {"city_id":"41346","city_name":"Luhans'k","state_id":"3782"},
    {"city_id":"41347","city_name":"Luhansk","state_id":"3782"},
    {"city_id":"41348","city_name":"Lysychans'k","state_id":"3782"},
    {"city_id":"41349","city_name":"Pervomays'k","state_id":"3782"},
    {"city_id":"41350","city_name":"Roven'ky","state_id":"3782"},
    {"city_id":"41351","city_name":"Rubizhne","state_id":"3782"},
    {"city_id":"41352","city_name":"Stakhanov","state_id":"3782"},
    {"city_id":"41353","city_name":"Sverdlovs'k","state_id":"3782"},
    {"city_id":"41354","city_name":"Syeverodonets'k","state_id":"3782"},
    {"city_id":"41355","city_name":"Mykolayiv","state_id":"3783"},
    {"city_id":"41356","city_name":"Pervomays'k","state_id":"3783"},
    {"city_id":"41357","city_name":"Bilhorod-Dnistrovs'kyy","state_id":"3784"},
    {"city_id":"41358","city_name":"Illichivs'k","state_id":"3784"},
    {"city_id":"41359","city_name":"Izmayil","state_id":"3784"},
    {"city_id":"41360","city_name":"Odesa","state_id":"3784"},
    {"city_id":"41361","city_name":"Odessa","state_id":"3785"},
    {"city_id":"41362","city_name":"Komsomol's'k","state_id":"3786"},
    {"city_id":"41363","city_name":"Kremenchuh","state_id":"3786"},
    {"city_id":"41364","city_name":"Lubny","state_id":"3786"},
    {"city_id":"41365","city_name":"Poltava","state_id":"3786"},
    {"city_id":"41366","city_name":"Rivne","state_id":"3787"},
    {"city_id":"41367","city_name":"Konotop","state_id":"3789"},
    {"city_id":"41368","city_name":"Okhtyrka","state_id":"3789"},
    {"city_id":"41369","city_name":"Romny","state_id":"3789"},
    {"city_id":"41370","city_name":"Shostka","state_id":"3789"},
    {"city_id":"41371","city_name":"Sumy","state_id":"3789"},
    {"city_id":"41372","city_name":"Ternopil'","state_id":"3790"},
    {"city_id":"41373","city_name":"Kovel'","state_id":"3791"},
    {"city_id":"41374","city_name":"Luts'k","state_id":"3791"},
    {"city_id":"41375","city_name":"Novovolyns'k","state_id":"3791"},
    {"city_id":"41376","city_name":"Vinnitsa","state_id":"3792"},
    {"city_id":"41377","city_name":"Vinnytsya","state_id":"3792"},
    {"city_id":"41378","city_name":"Mukacheve","state_id":"3793"},
    {"city_id":"41379","city_name":"Uzhhorod","state_id":"3793"},
    {"city_id":"41380","city_name":"Berdyans'k","state_id":"3794"},
    {"city_id":"41381","city_name":"Enerhodar","state_id":"3794"},
    {"city_id":"41382","city_name":"Melitpol'","state_id":"3794"},
    {"city_id":"41383","city_name":"Zaporizhia","state_id":"3794"},
    {"city_id":"41384","city_name":"Berdychiv","state_id":"3795"},
    {"city_id":"41385","city_name":"Korosten'","state_id":"3795"},
    {"city_id":"41386","city_name":"Novohrad-Volyns'kyy","state_id":"3795"},
    {"city_id":"41387","city_name":"Zhytomyr","state_id":"3795"},
    {"city_id":"41388","city_name":"Ajman","state_id":"3797"},
    {"city_id":"41389","city_name":"Al Qusais","state_id":"3798"},
    {"city_id":"41390","city_name":"Deira","state_id":"3798"},
    {"city_id":"41391","city_name":"Dubai","state_id":"3798"},
    {"city_id":"41392","city_name":"Jebel Ali","state_id":"3798"},
    {"city_id":"41393","city_name":"Sharjah","state_id":"3800"},
    {"city_id":"41394","city_name":"Khawr Fakkan","state_id":"3803"},
    {"city_id":"41395","city_name":"al-Fujayrah","state_id":"3803"},
    {"city_id":"41396","city_name":"Cleveland","state_id":"3805"},
    {"city_id":"41397","city_name":"Gilberdyke","state_id":"3805"},
    {"city_id":"41398","city_name":"Llanrwst","state_id":"3805"},
    {"city_id":"41399","city_name":"Swadlincote","state_id":"3805"},
    {"city_id":"41400","city_name":"Turriff","state_id":"3805"},
    {"city_id":"41401","city_name":"Westhill","state_id":"3806"},
    {"city_id":"41402","city_name":"Oban","state_id":"3807"},
    {"city_id":"41403","city_name":"Craigavon","state_id":"3808"},
    {"city_id":"41404","city_name":"Barton-le-Clay","state_id":"3809"},
    {"city_id":"41405","city_name":"Bedford","state_id":"3809"},
    {"city_id":"41406","city_name":"Biggleswade","state_id":"3809"},
    {"city_id":"41407","city_name":"Caddington","state_id":"3809"},
    {"city_id":"41408","city_name":"Flitton","state_id":"3809"},
    {"city_id":"41409","city_name":"Flitwick","state_id":"3809"},
    {"city_id":"41410","city_name":"Leighton Buzzard","state_id":"3809"},
    {"city_id":"41411","city_name":"Marston Moretaine","state_id":"3809"},
    {"city_id":"41412","city_name":"Sandy","state_id":"3809"},
    {"city_id":"41413","city_name":"Westoning","state_id":"3809"},
    {"city_id":"41414","city_name":"Dundonald","state_id":"3810"},
    {"city_id":"41415","city_name":"Holywood","state_id":"3810"},
    {"city_id":"41416","city_name":"Berkshire","state_id":"3811"},
    {"city_id":"41417","city_name":"Bracknell","state_id":"3811"},
    {"city_id":"41418","city_name":"Littlewick Green","state_id":"3811"},
    {"city_id":"41419","city_name":"Maidenhead","state_id":"3811"},
    {"city_id":"41420","city_name":"Newbury","state_id":"3811"},
    {"city_id":"41421","city_name":"Reading","state_id":"3811"},
    {"city_id":"41422","city_name":"Sandhurst","state_id":"3811"},
    {"city_id":"41423","city_name":"Slough","state_id":"3811"},
    {"city_id":"41424","city_name":"Sunninghill","state_id":"3811"},
    {"city_id":"41425","city_name":"Twyford","state_id":"3811"},
    {"city_id":"41426","city_name":"Windsor","state_id":"3811"},
    {"city_id":"41427","city_name":"Wokingham","state_id":"3811"},
    {"city_id":"41428","city_name":"Woodley","state_id":"3811"},
    {"city_id":"41429","city_name":"Coleshill","state_id":"3812"},
    {"city_id":"41430","city_name":"Edgbaston","state_id":"3812"},
    {"city_id":"41431","city_name":"Hockley","state_id":"3812"},
    {"city_id":"41432","city_name":"Ladywood","state_id":"3812"},
    {"city_id":"41433","city_name":"Nechells","state_id":"3812"},
    {"city_id":"41434","city_name":"Rubery","state_id":"3812"},
    {"city_id":"41435","city_name":"Small Heath","state_id":"3812"},
    {"city_id":"41436","city_name":"Angus","state_id":"3813"},
    {"city_id":"41437","city_name":"Bridgnorth","state_id":"3814"},
    {"city_id":"41438","city_name":"Avon","state_id":"3815"},
    {"city_id":"41439","city_name":"Fishponds","state_id":"3815"},
    {"city_id":"41440","city_name":"Henleaze","state_id":"3815"},
    {"city_id":"41441","city_name":"Thornbury","state_id":"3815"},
    {"city_id":"41442","city_name":"Warmley","state_id":"3815"},
    {"city_id":"41443","city_name":"Amersham","state_id":"3816"},
    {"city_id":"41444","city_name":"Aston Clinton","state_id":"3816"},
    {"city_id":"41445","city_name":"Beaconsfield","state_id":"3816"},
    {"city_id":"41446","city_name":"Bletchley","state_id":"3816"},
    {"city_id":"41447","city_name":"Bourne End","state_id":"3816"},
    {"city_id":"41448","city_name":"Buckingham","state_id":"3816"},
    {"city_id":"41449","city_name":"High Wycombe","state_id":"3816"},
    {"city_id":"41450","city_name":"Iver","state_id":"3816"},
    {"city_id":"41451","city_name":"Marlow","state_id":"3816"},
    {"city_id":"41452","city_name":"Milton Keynes","state_id":"3816"},
    {"city_id":"41453","city_name":"Newport Pagnell","state_id":"3816"},
    {"city_id":"41454","city_name":"Piddington","state_id":"3816"},
    {"city_id":"41455","city_name":"Princes Risborough","state_id":"3816"},
    {"city_id":"41456","city_name":"Rowsham","state_id":"3816"},
    {"city_id":"41457","city_name":"Cambridge","state_id":"3817"},
    {"city_id":"41458","city_name":"Ely","state_id":"3817"},
    {"city_id":"41459","city_name":"Huntingdon","state_id":"3817"},
    {"city_id":"41460","city_name":"Peterborough","state_id":"3817"},
    {"city_id":"41461","city_name":"Cambridge","state_id":"3818"},
    {"city_id":"41462","city_name":"Haddenham","state_id":"3818"},
    {"city_id":"41463","city_name":"Sawtry","state_id":"3818"},
    {"city_id":"41464","city_name":"Wisbech","state_id":"3818"},
    {"city_id":"41465","city_name":"Alderley Edge","state_id":"3820"},
    {"city_id":"41466","city_name":"Altrincham","state_id":"3820"},
    {"city_id":"41467","city_name":"Betley","state_id":"3820"},
    {"city_id":"41468","city_name":"Cheadle Gatley","state_id":"3820"},
    {"city_id":"41469","city_name":"Cheadle Hulme","state_id":"3820"},
    {"city_id":"41470","city_name":"Crewe","state_id":"3820"},
    {"city_id":"41471","city_name":"Dukinfield","state_id":"3820"},
    {"city_id":"41472","city_name":"Holmes Chapel","state_id":"3820"},
    {"city_id":"41473","city_name":"Hyde","state_id":"3820"},
    {"city_id":"41474","city_name":"Knuntsford","state_id":"3820"},
    {"city_id":"41475","city_name":"Knutsford","state_id":"3820"},
    {"city_id":"41476","city_name":"Lymm","state_id":"3820"},
    {"city_id":"41477","city_name":"Malpas","state_id":"3820"},
    {"city_id":"41478","city_name":"Merseyside","state_id":"3820"},
    {"city_id":"41479","city_name":"Middlewich","state_id":"3820"},
    {"city_id":"41480","city_name":"Mobberley","state_id":"3820"},
    {"city_id":"41481","city_name":"Nantwich","state_id":"3820"},
    {"city_id":"41482","city_name":"Saltney","state_id":"3820"},
    {"city_id":"41483","city_name":"Sandbach","state_id":"3820"},
    {"city_id":"41484","city_name":"Stalybridge","state_id":"3820"},
    {"city_id":"41485","city_name":"Stockport","state_id":"3820"},
    {"city_id":"41486","city_name":"Tarporley","state_id":"3820"},
    {"city_id":"41487","city_name":"Timperley","state_id":"3820"},
    {"city_id":"41488","city_name":"Widnes","state_id":"3820"},
    {"city_id":"41489","city_name":"Winsford","state_id":"3820"},
    {"city_id":"41490","city_name":"Redcar","state_id":"3821"},
    {"city_id":"41491","city_name":"Stockton-on-Tees","state_id":"3821"},
    {"city_id":"41492","city_name":"Conwy","state_id":"3823"},
    {"city_id":"41493","city_name":"Llandudno","state_id":"3823"},
    {"city_id":"41494","city_name":"Bude","state_id":"3824"},
    {"city_id":"41495","city_name":"Camborne","state_id":"3824"},
    {"city_id":"41496","city_name":"Fowey","state_id":"3824"},
    {"city_id":"41497","city_name":"Hayle","state_id":"3824"},
    {"city_id":"41498","city_name":"Helston","state_id":"3824"},
    {"city_id":"41499","city_name":"Launceston","state_id":"3824"},
    {"city_id":"41500","city_name":"Liskeard","state_id":"3824"},
    {"city_id":"41501","city_name":"Looe","state_id":"3824"},
    {"city_id":"41502","city_name":"Mevegissey","state_id":"3824"},
    {"city_id":"41503","city_name":"Newquay","state_id":"3824"},
    {"city_id":"41504","city_name":"Penryn","state_id":"3824"},
    {"city_id":"41505","city_name":"Penzance","state_id":"3824"},
    {"city_id":"41506","city_name":"St. Ives","state_id":"3824"},
    {"city_id":"41507","city_name":"Truro","state_id":"3824"},
    {"city_id":"41508","city_name":"Wadebridge","state_id":"3824"},
    {"city_id":"41509","city_name":"Holbrooks","state_id":"3825"},
    {"city_id":"41510","city_name":"Askam-in-Furness","state_id":"3827"},
    {"city_id":"41511","city_name":"Flookburgh","state_id":"3827"},
    {"city_id":"41512","city_name":"Grasmere","state_id":"3827"},
    {"city_id":"41513","city_name":"Kendal","state_id":"3827"},
    {"city_id":"41514","city_name":"Keswick","state_id":"3827"},
    {"city_id":"41515","city_name":"Kirkby Stephen","state_id":"3827"},
    {"city_id":"41516","city_name":"Milnthorpe","state_id":"3827"},
    {"city_id":"41517","city_name":"Penrith","state_id":"3827"},
    {"city_id":"41518","city_name":"Ulverston","state_id":"3827"},
    {"city_id":"41519","city_name":"Windermere","state_id":"3827"},
    {"city_id":"41520","city_name":"Denbigh","state_id":"3828"},
    {"city_id":"41521","city_name":"Ashbourne","state_id":"3829"},
    {"city_id":"41522","city_name":"Buxton","state_id":"3829"},
    {"city_id":"41523","city_name":"Chesterfield","state_id":"3829"},
    {"city_id":"41524","city_name":"Eckington","state_id":"3829"},
    {"city_id":"41525","city_name":"Bakewell","state_id":"3830"},
    {"city_id":"41526","city_name":"Belper","state_id":"3830"},
    {"city_id":"41527","city_name":"Breaston","state_id":"3830"},
    {"city_id":"41528","city_name":"Derby","state_id":"3830"},
    {"city_id":"41529","city_name":"Ilkeston","state_id":"3830"},
    {"city_id":"41530","city_name":"Matlock","state_id":"3830"},
    {"city_id":"41531","city_name":"Ripley","state_id":"3830"},
    {"city_id":"41532","city_name":"Axminster","state_id":"3831"},
    {"city_id":"41533","city_name":"Barnstaple","state_id":"3831"},
    {"city_id":"41534","city_name":"Beaworthy","state_id":"3831"},
    {"city_id":"41535","city_name":"Bideford","state_id":"3831"},
    {"city_id":"41536","city_name":"Bovey Tracey","state_id":"3831"},
    {"city_id":"41537","city_name":"Braunton","state_id":"3831"},
    {"city_id":"41538","city_name":"Brixham","state_id":"3831"},
    {"city_id":"41539","city_name":"Chudleigh","state_id":"3831"},
    {"city_id":"41540","city_name":"Crediton","state_id":"3831"},
    {"city_id":"41541","city_name":"Dalwood","state_id":"3831"},
    {"city_id":"41542","city_name":"Dartmouth","state_id":"3831"},
    {"city_id":"41543","city_name":"Dawlish","state_id":"3831"},
    {"city_id":"41544","city_name":"Exeter","state_id":"3831"},
    {"city_id":"41545","city_name":"Exmouth","state_id":"3831"},
    {"city_id":"41546","city_name":"Great Torrington","state_id":"3831"},
    {"city_id":"41547","city_name":"Holsworthy","state_id":"3831"},
    {"city_id":"41548","city_name":"Kingsbridge","state_id":"3831"},
    {"city_id":"41549","city_name":"Modbury","state_id":"3831"},
    {"city_id":"41550","city_name":"Newton Abbot","state_id":"3831"},
    {"city_id":"41551","city_name":"Okehampton","state_id":"3831"},
    {"city_id":"41552","city_name":"Plymouth","state_id":"3831"},
    {"city_id":"41553","city_name":"Plympton","state_id":"3831"},
    {"city_id":"41554","city_name":"Salcombe","state_id":"3831"},
    {"city_id":"41555","city_name":"Tiverton","state_id":"3831"},
    {"city_id":"41556","city_name":"Torquay","state_id":"3831"},
    {"city_id":"41557","city_name":"Totnes","state_id":"3831"},
    {"city_id":"41558","city_name":"Winkleigh","state_id":"3831"},
    {"city_id":"41559","city_name":"Woodburyd","state_id":"3831"},
    {"city_id":"41560","city_name":"Yelverton","state_id":"3831"},
    {"city_id":"41561","city_name":"Didcot","state_id":"3833"},
    {"city_id":"41562","city_name":"Beaminster","state_id":"3834"},
    {"city_id":"41563","city_name":"Blandford Forum","state_id":"3834"},
    {"city_id":"41564","city_name":"Christchurch","state_id":"3834"},
    {"city_id":"41565","city_name":"Dorset","state_id":"3834"},
    {"city_id":"41566","city_name":"Poole","state_id":"3834"},
    {"city_id":"41567","city_name":"Sherborne","state_id":"3834"},
    {"city_id":"41568","city_name":"Sturminster Newton","state_id":"3834"},
    {"city_id":"41569","city_name":"Swanage","state_id":"3834"},
    {"city_id":"41570","city_name":"Verwood","state_id":"3834"},
    {"city_id":"41571","city_name":"Wimborne","state_id":"3834"},
    {"city_id":"41572","city_name":"Alexandria","state_id":"3835"},
    {"city_id":"41573","city_name":"Crook","state_id":"3836"},
    {"city_id":"41574","city_name":"Spennymoor","state_id":"3836"},
    {"city_id":"41575","city_name":"Abingdon","state_id":"3842"},
    {"city_id":"41576","city_name":"Accrington","state_id":"3842"},
    {"city_id":"41577","city_name":"Aldershot","state_id":"3842"},
    {"city_id":"41578","city_name":"Alfreton","state_id":"3842"},
    {"city_id":"41579","city_name":"Altrincham","state_id":"3842"},
    {"city_id":"41580","city_name":"Amersham","state_id":"3842"},
    {"city_id":"41581","city_name":"Andover","state_id":"3842"},
    {"city_id":"41582","city_name":"Arnold","state_id":"3842"},
    {"city_id":"41583","city_name":"Ashford","state_id":"3842"},
    {"city_id":"41584","city_name":"Ashington","state_id":"3842"},
    {"city_id":"41585","city_name":"Ashton-in-Makerfield","state_id":"3842"},
    {"city_id":"41586","city_name":"Ashton-under-Lyne","state_id":"3842"},
    {"city_id":"41587","city_name":"Atherton","state_id":"3842"},
    {"city_id":"41588","city_name":"Aylesbury","state_id":"3842"},
    {"city_id":"41589","city_name":"Aylesford-East Malling","state_id":"3842"},
    {"city_id":"41590","city_name":"Banbury","state_id":"3842"},
    {"city_id":"41591","city_name":"Banstead-Tadworth","state_id":"3842"},
    {"city_id":"41592","city_name":"Barnsley","state_id":"3842"},
    {"city_id":"41593","city_name":"Barnstaple","state_id":"3842"},
    {"city_id":"41594","city_name":"Barrow-in-Furness","state_id":"3842"},
    {"city_id":"41595","city_name":"Basildon","state_id":"3842"},
    {"city_id":"41596","city_name":"Basingstoke","state_id":"3842"},
    {"city_id":"41597","city_name":"Bath","state_id":"3842"},
    {"city_id":"41598","city_name":"Batley","state_id":"3842"},
    {"city_id":"41599","city_name":"Bebington","state_id":"3842"},
    {"city_id":"41600","city_name":"Bedford","state_id":"3842"},
    {"city_id":"41601","city_name":"Bedworth","state_id":"3842"},
    {"city_id":"41602","city_name":"Beeston and Stapleford","state_id":"3842"},
    {"city_id":"41603","city_name":"Benfleet","state_id":"3842"},
    {"city_id":"41604","city_name":"Bentley","state_id":"3842"},
    {"city_id":"41605","city_name":"Berwick-upon-Tweed","state_id":"3842"},
    {"city_id":"41606","city_name":"Beverley","state_id":"3842"},
    {"city_id":"41607","city_name":"Bexhil","state_id":"3842"},
    {"city_id":"41608","city_name":"Bicester","state_id":"3842"},
    {"city_id":"41609","city_name":"Bideford","state_id":"3842"},
    {"city_id":"41610","city_name":"Billericay","state_id":"3842"},
    {"city_id":"41611","city_name":"Billingham","state_id":"3842"},
    {"city_id":"41612","city_name":"Birkenhead","state_id":"3842"},
    {"city_id":"41613","city_name":"Birmingham","state_id":"3842"},
    {"city_id":"41614","city_name":"Bishop Auckland","state_id":"3842"},
    {"city_id":"41615","city_name":"Bishop's Stortford","state_id":"3842"},
    {"city_id":"41616","city_name":"Blackburn","state_id":"3842"},
    {"city_id":"41617","city_name":"Blackpool","state_id":"3842"},
    {"city_id":"41618","city_name":"Bletchley","state_id":"3842"},
    {"city_id":"41619","city_name":"Blyth","state_id":"3842"},
    {"city_id":"41620","city_name":"Bodmin","state_id":"3842"},
    {"city_id":"41621","city_name":"Bognor Regis","state_id":"3842"},
    {"city_id":"41622","city_name":"Bolton","state_id":"3842"},
    {"city_id":"41623","city_name":"Bootle","state_id":"3842"},
    {"city_id":"41624","city_name":"Boston","state_id":"3842"},
    {"city_id":"41625","city_name":"Bournemouth","state_id":"3842"},
    {"city_id":"41626","city_name":"Bracknell","state_id":"3842"},
    {"city_id":"41627","city_name":"Bradford","state_id":"3842"},
    {"city_id":"41628","city_name":"Braintree","state_id":"3842"},
    {"city_id":"41629","city_name":"Bredbury and Romiley","state_id":"3842"},
    {"city_id":"41630","city_name":"Brentwood","state_id":"3842"},
    {"city_id":"41631","city_name":"Bridgwater","state_id":"3842"},
    {"city_id":"41632","city_name":"Bridlington","state_id":"3842"},
    {"city_id":"41633","city_name":"Brigg","state_id":"3842"},
    {"city_id":"41634","city_name":"Brighouse","state_id":"3842"},
    {"city_id":"41635","city_name":"Brighton","state_id":"3842"},
    {"city_id":"41636","city_name":"Bristol","state_id":"3842"},
    {"city_id":"41637","city_name":"Broadstairs","state_id":"3842"},
    {"city_id":"41638","city_name":"Bromley Cross-Bradshaw","state_id":"3842"},
    {"city_id":"41639","city_name":"Bromsgrove-Catshill","state_id":"3842"},
    {"city_id":"41640","city_name":"Burgess Hill","state_id":"3842"},
    {"city_id":"41641","city_name":"Burnley","state_id":"3842"},
    {"city_id":"41642","city_name":"Burntwood","state_id":"3842"},
    {"city_id":"41643","city_name":"Burton-upon-Trent","state_id":"3842"},
    {"city_id":"41644","city_name":"Bury","state_id":"3842"},
    {"city_id":"41645","city_name":"Bury Saint Edmunds","state_id":"3842"},
    {"city_id":"41646","city_name":"Camberley-Frimley","state_id":"3842"},
    {"city_id":"41647","city_name":"Cambourne-Redruth","state_id":"3842"},
    {"city_id":"41648","city_name":"Cambridge","state_id":"3842"},
    {"city_id":"41649","city_name":"Cannock","state_id":"3842"},
    {"city_id":"41650","city_name":"Canterbury","state_id":"3842"},
    {"city_id":"41651","city_name":"Canvey Island","state_id":"3842"},
    {"city_id":"41652","city_name":"Carlisle","state_id":"3842"},
    {"city_id":"41653","city_name":"Carlton","state_id":"3842"},
    {"city_id":"41654","city_name":"Castleford","state_id":"3842"},
    {"city_id":"41655","city_name":"Caterham and Warlingham","state_id":"3842"},
    {"city_id":"41656","city_name":"Chadderton","state_id":"3842"},
    {"city_id":"41657","city_name":"Chapeltown","state_id":"3842"},
    {"city_id":"41658","city_name":"Chatham","state_id":"3842"},
    {"city_id":"41659","city_name":"Cheadle and Gatley","state_id":"3842"},
    {"city_id":"41660","city_name":"Chelmsford","state_id":"3842"},
    {"city_id":"41661","city_name":"Cheltenham","state_id":"3842"},
    {"city_id":"41662","city_name":"Chesham","state_id":"3842"},
    {"city_id":"41663","city_name":"Cheshunt","state_id":"3842"},
    {"city_id":"41664","city_name":"Chessington","state_id":"3842"},
    {"city_id":"41665","city_name":"Chester","state_id":"3842"},
    {"city_id":"41666","city_name":"Chester-le-Street","state_id":"3842"},
    {"city_id":"41667","city_name":"Chesterfield","state_id":"3842"},
    {"city_id":"41668","city_name":"Chichester","state_id":"3842"},
    {"city_id":"41669","city_name":"Chippenham","state_id":"3842"},
    {"city_id":"41670","city_name":"Chipping Sodbury","state_id":"3842"},
    {"city_id":"41671","city_name":"Chorley","state_id":"3842"},
    {"city_id":"41672","city_name":"Christchurch","state_id":"3842"},
    {"city_id":"41673","city_name":"Clacton-on-Sea","state_id":"3842"},
    {"city_id":"41674","city_name":"Clay Cross-North Wingfield","state_id":"3842"},
    {"city_id":"41675","city_name":"Cleethorpes","state_id":"3842"},
    {"city_id":"41676","city_name":"Clevedon","state_id":"3842"},
    {"city_id":"41677","city_name":"Coalville","state_id":"3842"},
    {"city_id":"41678","city_name":"Colchester","state_id":"3842"},
    {"city_id":"41679","city_name":"Congleton","state_id":"3842"},
    {"city_id":"41680","city_name":"Consett","state_id":"3842"},
    {"city_id":"41681","city_name":"Corby","state_id":"3842"},
    {"city_id":"41682","city_name":"Coventry","state_id":"3842"},
    {"city_id":"41683","city_name":"Cramlington","state_id":"3842"},
    {"city_id":"41684","city_name":"Crawley","state_id":"3842"},
    {"city_id":"41685","city_name":"Crosby","state_id":"3842"},
    {"city_id":"41686","city_name":"Crowthorne","state_id":"3842"},
    {"city_id":"41687","city_name":"Darlington","state_id":"3842"},
    {"city_id":"41688","city_name":"Dartford","state_id":"3842"},
    {"city_id":"41689","city_name":"Darwen","state_id":"3842"},
    {"city_id":"41690","city_name":"Deal","state_id":"3842"},
    {"city_id":"41691","city_name":"Denton","state_id":"3842"},
    {"city_id":"41692","city_name":"Derby","state_id":"3842"},
    {"city_id":"41693","city_name":"Dewsbury","state_id":"3842"},
    {"city_id":"41694","city_name":"Doncaster","state_id":"3842"},
    {"city_id":"41695","city_name":"Dorchester","state_id":"3842"},
    {"city_id":"41696","city_name":"Dover","state_id":"3842"},
    {"city_id":"41697","city_name":"Droitwich","state_id":"3842"},
    {"city_id":"41698","city_name":"Dronfield","state_id":"3842"},
    {"city_id":"41699","city_name":"Droylsden","state_id":"3842"},
    {"city_id":"41700","city_name":"Dudley","state_id":"3842"},
    {"city_id":"41701","city_name":"Dunstable","state_id":"3842"},
    {"city_id":"41702","city_name":"Durham","state_id":"3842"},
    {"city_id":"41703","city_name":"East Grinstead","state_id":"3842"},
    {"city_id":"41704","city_name":"East Retford","state_id":"3842"},
    {"city_id":"41705","city_name":"Eastbourne","state_id":"3842"},
    {"city_id":"41706","city_name":"Eastleigh","state_id":"3842"},
    {"city_id":"41707","city_name":"Eaton Socon-Saint Neots","state_id":"3842"},
    {"city_id":"41708","city_name":"Eccles","state_id":"3842"},
    {"city_id":"41709","city_name":"Egham","state_id":"3842"},
    {"city_id":"41710","city_name":"Ellesmere Port","state_id":"3842"},
    {"city_id":"41711","city_name":"Epsom and Ewell","state_id":"3842"},
    {"city_id":"41712","city_name":"Esher-Molesey","state_id":"3842"},
    {"city_id":"41713","city_name":"Eston and South Bank","state_id":"3842"},
    {"city_id":"41714","city_name":"Exeter","state_id":"3842"},
    {"city_id":"41715","city_name":"Failsworth","state_id":"3842"},
    {"city_id":"41716","city_name":"Falmouth-Penryn","state_id":"3842"},
    {"city_id":"41717","city_name":"Fareham","state_id":"3842"},
    {"city_id":"41718","city_name":"Farnborough","state_id":"3842"},
    {"city_id":"41719","city_name":"Farnham","state_id":"3842"},
    {"city_id":"41720","city_name":"Farnworth","state_id":"3842"},
    {"city_id":"41721","city_name":"Farring","state_id":"3842"},
    {"city_id":"41722","city_name":"Felixtowe","state_id":"3842"},
    {"city_id":"41723","city_name":"Felling","state_id":"3842"},
    {"city_id":"41724","city_name":"Ferndown","state_id":"3842"},
    {"city_id":"41725","city_name":"Fleetwood","state_id":"3842"},
    {"city_id":"41726","city_name":"Folkestone","state_id":"3842"},
    {"city_id":"41727","city_name":"Formby","state_id":"3842"},
    {"city_id":"41728","city_name":"Frome","state_id":"3842"},
    {"city_id":"41729","city_name":"Fulham","state_id":"3842"},
    {"city_id":"41730","city_name":"Gateshead","state_id":"3842"},
    {"city_id":"41731","city_name":"Gillingham","state_id":"3842"},
    {"city_id":"41732","city_name":"Glossop","state_id":"3842"},
    {"city_id":"41733","city_name":"Gloucester","state_id":"3842"},
    {"city_id":"41734","city_name":"Godalming","state_id":"3842"},
    {"city_id":"41735","city_name":"Golborne","state_id":"3842"},
    {"city_id":"41736","city_name":"Gosforth","state_id":"3842"},
    {"city_id":"41737","city_name":"Gosport","state_id":"3842"},
    {"city_id":"41738","city_name":"Grantham","state_id":"3842"},
    {"city_id":"41739","city_name":"Gravesend","state_id":"3842"},
    {"city_id":"41740","city_name":"Grays","state_id":"3842"},
    {"city_id":"41741","city_name":"Greasby","state_id":"3842"},
    {"city_id":"41742","city_name":"Great Malvern","state_id":"3842"},
    {"city_id":"41743","city_name":"Great Sankey","state_id":"3842"},
    {"city_id":"41744","city_name":"Great Yarmouth","state_id":"3842"},
    {"city_id":"41745","city_name":"Grimsby","state_id":"3842"},
    {"city_id":"41746","city_name":"Guildford","state_id":"3842"},
    {"city_id":"41747","city_name":"Guiseley-Yeadon","state_id":"3842"},
    {"city_id":"41748","city_name":"Halesowen","state_id":"3842"},
    {"city_id":"41749","city_name":"Halifax","state_id":"3842"},
    {"city_id":"41750","city_name":"Harlow","state_id":"3842"},
    {"city_id":"41751","city_name":"Harpenden","state_id":"3842"},
    {"city_id":"41752","city_name":"Harrogate","state_id":"3842"},
    {"city_id":"41753","city_name":"Hartlepool","state_id":"3842"},
    {"city_id":"41754","city_name":"Hastings","state_id":"3842"},
    {"city_id":"41755","city_name":"Hatfield","state_id":"3842"},
    {"city_id":"41756","city_name":"Hatfield-Stainforth","state_id":"3842"},
    {"city_id":"41757","city_name":"Havant","state_id":"3842"},
    {"city_id":"41758","city_name":"Haywards Heath","state_id":"3842"},
    {"city_id":"41759","city_name":"Hazel Grove and Bramhill","state_id":"3842"},
    {"city_id":"41760","city_name":"Hazlemere","state_id":"3842"},
    {"city_id":"41761","city_name":"Heanor","state_id":"3842"},
    {"city_id":"41762","city_name":"Hemel Hempstead","state_id":"3842"},
    {"city_id":"41763","city_name":"Hereford","state_id":"3842"},
    {"city_id":"41764","city_name":"Herne Bay","state_id":"3842"},
    {"city_id":"41765","city_name":"Hertford","state_id":"3842"},
    {"city_id":"41766","city_name":"Heswall","state_id":"3842"},
    {"city_id":"41767","city_name":"Heywood","state_id":"3842"},
    {"city_id":"41768","city_name":"High Wycombe","state_id":"3842"},
    {"city_id":"41769","city_name":"Hinckley","state_id":"3842"},
    {"city_id":"41770","city_name":"Hindley","state_id":"3842"},
    {"city_id":"41771","city_name":"Hitchin","state_id":"3842"},
    {"city_id":"41772","city_name":"Hoddesdon","state_id":"3842"},
    {"city_id":"41773","city_name":"Holmfirth-Honley","state_id":"3842"},
    {"city_id":"41774","city_name":"Honiton","state_id":"3842"},
    {"city_id":"41775","city_name":"Horsham","state_id":"3842"},
    {"city_id":"41776","city_name":"Houghton-le-Spring","state_id":"3842"},
    {"city_id":"41777","city_name":"Hove","state_id":"3842"},
    {"city_id":"41778","city_name":"Hoylake-West Kirby","state_id":"3842"},
    {"city_id":"41779","city_name":"Hucknall","state_id":"3842"},
    {"city_id":"41780","city_name":"Huddersfield","state_id":"3842"},
    {"city_id":"41781","city_name":"Huyton-with-Roby","state_id":"3842"},
    {"city_id":"41782","city_name":"Hyde","state_id":"3842"},
    {"city_id":"41783","city_name":"Ilfracombe","state_id":"3842"},
    {"city_id":"41784","city_name":"Ilkeston","state_id":"3842"},
    {"city_id":"41785","city_name":"Ipswich","state_id":"3842"},
    {"city_id":"41786","city_name":"Ivybridge","state_id":"3842"},
    {"city_id":"41787","city_name":"Jarrow","state_id":"3842"},
    {"city_id":"41788","city_name":"Keighley","state_id":"3842"},
    {"city_id":"41789","city_name":"Kendal","state_id":"3842"},
    {"city_id":"41790","city_name":"Kenilworth","state_id":"3842"},
    {"city_id":"41791","city_name":"Kettering","state_id":"3842"},
    {"city_id":"41792","city_name":"Kidderminster","state_id":"3842"},
    {"city_id":"41793","city_name":"Kidsgrove","state_id":"3842"},
    {"city_id":"41794","city_name":"King's Lynn","state_id":"3842"},
    {"city_id":"41795","city_name":"Kingsteignton","state_id":"3842"},
    {"city_id":"41796","city_name":"Kingston upon Hull","state_id":"3842"},
    {"city_id":"41797","city_name":"Kingswood","state_id":"3842"},
    {"city_id":"41798","city_name":"Kirby in Ashfield","state_id":"3842"},
    {"city_id":"41799","city_name":"Kirkby","state_id":"3842"},
    {"city_id":"41800","city_name":"Lancaster","state_id":"3842"},
    {"city_id":"41801","city_name":"Leamington","state_id":"3842"},
    {"city_id":"41802","city_name":"Leatherhead","state_id":"3842"},
    {"city_id":"41803","city_name":"Leeds","state_id":"3842"},
    {"city_id":"41804","city_name":"Leicester","state_id":"3842"},
    {"city_id":"41805","city_name":"Leigh","state_id":"3842"},
    {"city_id":"41806","city_name":"Leighton Buzzard","state_id":"3842"},
    {"city_id":"41807","city_name":"Letchworth","state_id":"3842"},
    {"city_id":"41808","city_name":"Lewes","state_id":"3842"},
    {"city_id":"41809","city_name":"Leyland","state_id":"3842"},
    {"city_id":"41810","city_name":"Lichfield","state_id":"3842"},
    {"city_id":"41811","city_name":"Lincoln","state_id":"3842"},
    {"city_id":"41812","city_name":"Litherland","state_id":"3842"},
    {"city_id":"41813","city_name":"Littlehampton","state_id":"3842"},
    {"city_id":"41814","city_name":"Liverpool","state_id":"3842"},
    {"city_id":"41815","city_name":"Locks Heath","state_id":"3842"},
    {"city_id":"41816","city_name":"London","state_id":"3842"},
    {"city_id":"41817","city_name":"Long Benton-Killingworth","state_id":"3842"},
    {"city_id":"41818","city_name":"Long Eaton","state_id":"3842"},
    {"city_id":"41819","city_name":"Loughborough","state_id":"3842"},
    {"city_id":"41820","city_name":"Loughton","state_id":"3842"},
    {"city_id":"41821","city_name":"Louth","state_id":"3842"},
    {"city_id":"41822","city_name":"Lowestoft","state_id":"3842"},
    {"city_id":"41823","city_name":"Luton","state_id":"3842"},
    {"city_id":"41824","city_name":"Lyminge","state_id":"3842"},
    {"city_id":"41825","city_name":"Lytham Saint Anne's","state_id":"3842"},
    {"city_id":"41826","city_name":"Mablethorpe and Sutton","state_id":"3842"},
    {"city_id":"41827","city_name":"Macclesfield","state_id":"3842"},
    {"city_id":"41828","city_name":"Maghull-Lydiate","state_id":"3842"},
    {"city_id":"41829","city_name":"Maidenhead","state_id":"3842"},
    {"city_id":"41830","city_name":"Maidstone","state_id":"3842"},
    {"city_id":"41831","city_name":"Manchester","state_id":"3842"},
    {"city_id":"41832","city_name":"Mangotsfield","state_id":"3842"},
    {"city_id":"41833","city_name":"Mansfield","state_id":"3842"},
    {"city_id":"41834","city_name":"Margate","state_id":"3842"},
    {"city_id":"41835","city_name":"Matlock","state_id":"3842"},
    {"city_id":"41836","city_name":"Melton Mowbray","state_id":"3842"},
    {"city_id":"41837","city_name":"Middlesbrough","state_id":"3842"},
    {"city_id":"41838","city_name":"Middleton","state_id":"3842"},
    {"city_id":"41839","city_name":"Midsomer Norton","state_id":"3842"},
    {"city_id":"41840","city_name":"Milton Keynes","state_id":"3842"},
    {"city_id":"41841","city_name":"Morecambe","state_id":"3842"},
    {"city_id":"41842","city_name":"Morley","state_id":"3842"},
    {"city_id":"41843","city_name":"Nailsea","state_id":"3842"},
    {"city_id":"41844","city_name":"Nantwich","state_id":"3842"},
    {"city_id":"41845","city_name":"Nelson","state_id":"3842"},
    {"city_id":"41846","city_name":"New Addington","state_id":"3842"},
    {"city_id":"41847","city_name":"New Milton-Barton-on-Sea","state_id":"3842"},
    {"city_id":"41848","city_name":"Newark-on-Trent","state_id":"3842"},
    {"city_id":"41849","city_name":"Newburn","state_id":"3842"},
    {"city_id":"41850","city_name":"Newbury","state_id":"3842"},
    {"city_id":"41851","city_name":"Newcastle upon Tyne","state_id":"3842"},
    {"city_id":"41852","city_name":"Newcastle-under-Lyme","state_id":"3842"},
    {"city_id":"41853","city_name":"Newport","state_id":"3842"},
    {"city_id":"41854","city_name":"Newton Abbot","state_id":"3842"},
    {"city_id":"41855","city_name":"Newton Aycliffe","state_id":"3842"},
    {"city_id":"41856","city_name":"North Hykeham","state_id":"3842"},
    {"city_id":"41857","city_name":"North Shields","state_id":"3842"},
    {"city_id":"41858","city_name":"Northallerton","state_id":"3842"},
    {"city_id":"41859","city_name":"Northam","state_id":"3842"},
    {"city_id":"41860","city_name":"Northampton","state_id":"3842"},
    {"city_id":"41861","city_name":"Northfleet","state_id":"3842"},
    {"city_id":"41862","city_name":"Northwich","state_id":"3842"},
    {"city_id":"41863","city_name":"Norwich","state_id":"3842"},
    {"city_id":"41864","city_name":"Nottingham","state_id":"3842"},
    {"city_id":"41865","city_name":"Nuneaton","state_id":"3842"},
    {"city_id":"41866","city_name":"Oakengates-Donnington","state_id":"3842"},
    {"city_id":"41867","city_name":"Oakham","state_id":"3842"},
    {"city_id":"41868","city_name":"Oldbury-Smethwick","state_id":"3842"},
    {"city_id":"41869","city_name":"Oldham","state_id":"3842"},
    {"city_id":"41870","city_name":"Ormskirk","state_id":"3842"},
    {"city_id":"41871","city_name":"Ossett","state_id":"3842"},
    {"city_id":"41872","city_name":"Oxford","state_id":"3842"},
    {"city_id":"41873","city_name":"Paignton","state_id":"3842"},
    {"city_id":"41874","city_name":"Penzance","state_id":"3842"},
    {"city_id":"41875","city_name":"Peterborough","state_id":"3842"},
    {"city_id":"41876","city_name":"Peterlee","state_id":"3842"},
    {"city_id":"41877","city_name":"Plymouth","state_id":"3842"},
    {"city_id":"41878","city_name":"Pontefract","state_id":"3842"},
    {"city_id":"41879","city_name":"Poole","state_id":"3842"},
    {"city_id":"41880","city_name":"Portsmouth","state_id":"3842"},
    {"city_id":"41881","city_name":"Potters Bar","state_id":"3842"},
    {"city_id":"41882","city_name":"Prescot","state_id":"3842"},
    {"city_id":"41883","city_name":"Preston","state_id":"3842"},
    {"city_id":"41884","city_name":"Prestwich","state_id":"3842"},
    {"city_id":"41885","city_name":"Prestwood","state_id":"3842"},
    {"city_id":"41886","city_name":"Pudsey","state_id":"3842"},
    {"city_id":"41887","city_name":"Radcliffe","state_id":"3842"},
    {"city_id":"41888","city_name":"Ramsgate","state_id":"3842"},
    {"city_id":"41889","city_name":"Rawtenstall","state_id":"3842"},
    {"city_id":"41890","city_name":"Rayleigh","state_id":"3842"},
    {"city_id":"41891","city_name":"Reading","state_id":"3842"},
    {"city_id":"41892","city_name":"Redcar","state_id":"3842"},
    {"city_id":"41893","city_name":"Redditch","state_id":"3842"},
    {"city_id":"41894","city_name":"Reigate","state_id":"3842"},
    {"city_id":"41895","city_name":"Rochdale","state_id":"3842"},
    {"city_id":"41896","city_name":"Rochester","state_id":"3842"},
    {"city_id":"41897","city_name":"Rotherham","state_id":"3842"},
    {"city_id":"41898","city_name":"Rottingdean","state_id":"3842"},
    {"city_id":"41899","city_name":"Royal Tunbridge Wells","state_id":"3842"},
    {"city_id":"41900","city_name":"Royton","state_id":"3842"},
    {"city_id":"41901","city_name":"Rugby","state_id":"3842"},
    {"city_id":"41902","city_name":"Rugeley","state_id":"3842"},
    {"city_id":"41903","city_name":"Runcorn","state_id":"3842"},
    {"city_id":"41904","city_name":"Rushden","state_id":"3842"},
    {"city_id":"41905","city_name":"Ryde","state_id":"3842"},
    {"city_id":"41906","city_name":"Saint Albans","state_id":"3842"},
    {"city_id":"41907","city_name":"Saint Austell","state_id":"3842"},
    {"city_id":"41908","city_name":"Saint Helens","state_id":"3842"},
    {"city_id":"41909","city_name":"Sale","state_id":"3842"},
    {"city_id":"41910","city_name":"Salford","state_id":"3842"},
    {"city_id":"41911","city_name":"Salisbury","state_id":"3842"},
    {"city_id":"41912","city_name":"Scarborough","state_id":"3842"},
    {"city_id":"41913","city_name":"Scunthorpe","state_id":"3842"},
    {"city_id":"41914","city_name":"Seaham","state_id":"3842"},
    {"city_id":"41915","city_name":"Sevenoaks","state_id":"3842"},
    {"city_id":"41916","city_name":"Sheffield","state_id":"3842"},
    {"city_id":"41917","city_name":"Shipley","state_id":"3842"},
    {"city_id":"41918","city_name":"Shrewsbury","state_id":"3842"},
    {"city_id":"41919","city_name":"Sidmouth","state_id":"3842"},
    {"city_id":"41920","city_name":"Sittingbourne","state_id":"3842"},
    {"city_id":"41921","city_name":"Skegness","state_id":"3842"},
    {"city_id":"41922","city_name":"Skelmersdale","state_id":"3842"},
    {"city_id":"41923","city_name":"Sleaford","state_id":"3842"},
    {"city_id":"41924","city_name":"Slough","state_id":"3842"},
    {"city_id":"41925","city_name":"Solihull","state_id":"3842"},
    {"city_id":"41926","city_name":"Sompting-Lancing","state_id":"3842"},
    {"city_id":"41927","city_name":"South Shields","state_id":"3842"},
    {"city_id":"41928","city_name":"Southampton","state_id":"3842"},
    {"city_id":"41929","city_name":"Southend-on-Sea","state_id":"3842"},
    {"city_id":"41930","city_name":"Southport","state_id":"3842"},
    {"city_id":"41931","city_name":"Spalding-Pinchbeck","state_id":"3842"},
    {"city_id":"41932","city_name":"St. Helens","state_id":"3842"},
    {"city_id":"41933","city_name":"Stafford","state_id":"3842"},
    {"city_id":"41934","city_name":"Staines","state_id":"3842"},
    {"city_id":"41935","city_name":"Stalybridge","state_id":"3842"},
    {"city_id":"41936","city_name":"Stamford","state_id":"3842"},
    {"city_id":"41937","city_name":"Stanford le Hope-Corringham","state_id":"3842"},
    {"city_id":"41938","city_name":"Stanley-Annfield Plain","state_id":"3842"},
    {"city_id":"41939","city_name":"Staveley","state_id":"3842"},
    {"city_id":"41940","city_name":"Stevenage","state_id":"3842"},
    {"city_id":"41941","city_name":"Stockport","state_id":"3842"},
    {"city_id":"41942","city_name":"Stockton Heath-Thelwall","state_id":"3842"},
    {"city_id":"41943","city_name":"Stockton-on-Tees","state_id":"3842"},
    {"city_id":"41944","city_name":"Stoke-on-Trent","state_id":"3842"},
    {"city_id":"41945","city_name":"Stourbridge","state_id":"3842"},
    {"city_id":"41946","city_name":"Stratford-upon-Avon","state_id":"3842"},
    {"city_id":"41947","city_name":"Stretford","state_id":"3842"},
    {"city_id":"41948","city_name":"Strood","state_id":"3842"},
    {"city_id":"41949","city_name":"Stubbington","state_id":"3842"},
    {"city_id":"41950","city_name":"Sunbury","state_id":"3842"},
    {"city_id":"41951","city_name":"Sunderland","state_id":"3842"},
    {"city_id":"41952","city_name":"Sutton Coldfield","state_id":"3842"},
    {"city_id":"41953","city_name":"Sutton in Ashfield","state_id":"3842"},
    {"city_id":"41954","city_name":"Swadlincote","state_id":"3842"},
    {"city_id":"41955","city_name":"Swanley-Hextable","state_id":"3842"},
    {"city_id":"41956","city_name":"Swindon","state_id":"3842"},
    {"city_id":"41957","city_name":"Swinton and Pendlebury","state_id":"3842"},
    {"city_id":"41958","city_name":"Tamworth","state_id":"3842"},
    {"city_id":"41959","city_name":"Taunton","state_id":"3842"},
    {"city_id":"41960","city_name":"Tavistock","state_id":"3842"},
    {"city_id":"41961","city_name":"Teignmouth","state_id":"3842"},
    {"city_id":"41962","city_name":"Telford","state_id":"3842"},
    {"city_id":"41963","city_name":"Tenbury Wells","state_id":"3842"},
    {"city_id":"41964","city_name":"Thatcham","state_id":"3842"},
    {"city_id":"41965","city_name":"The Deepings","state_id":"3842"},
    {"city_id":"41966","city_name":"Thetford","state_id":"3842"},
    {"city_id":"41967","city_name":"Thornaby","state_id":"3842"},
    {"city_id":"41968","city_name":"Thornton-Cleveleys","state_id":"3842"},
    {"city_id":"41969","city_name":"Tiverton","state_id":"3842"},
    {"city_id":"41970","city_name":"Tonbridge","state_id":"3842"},
    {"city_id":"41971","city_name":"Torquay","state_id":"3842"},
    {"city_id":"41972","city_name":"Totton","state_id":"3842"},
    {"city_id":"41973","city_name":"Trowbridge","state_id":"3842"},
    {"city_id":"41974","city_name":"Truro","state_id":"3842"},
    {"city_id":"41975","city_name":"Tyldesley","state_id":"3842"},
    {"city_id":"41976","city_name":"Urmston","state_id":"3842"},
    {"city_id":"41977","city_name":"Wakefield","state_id":"3842"},
    {"city_id":"41978","city_name":"Walkden","state_id":"3842"},
    {"city_id":"41979","city_name":"Wallasey","state_id":"3842"},
    {"city_id":"41980","city_name":"Wallsend","state_id":"3842"},
    {"city_id":"41981","city_name":"Walsall","state_id":"3842"},
    {"city_id":"41982","city_name":"Walton and Weybridge","state_id":"3842"},
    {"city_id":"41983","city_name":"Warrington","state_id":"3842"},
    {"city_id":"41984","city_name":"Warwick","state_id":"3842"},
    {"city_id":"41985","city_name":"Washington","state_id":"3842"},
    {"city_id":"41986","city_name":"Waterlooville","state_id":"3842"},
    {"city_id":"41987","city_name":"Watford","state_id":"3842"},
    {"city_id":"41988","city_name":"Wellingborough","state_id":"3842"},
    {"city_id":"41989","city_name":"Welwyn Garden City","state_id":"3842"},
    {"city_id":"41990","city_name":"West Bridgeford","state_id":"3842"},
    {"city_id":"41991","city_name":"West Bromwich","state_id":"3842"},
    {"city_id":"41992","city_name":"Westhoughton","state_id":"3842"},
    {"city_id":"41993","city_name":"Weston-super-Mare","state_id":"3842"},
    {"city_id":"41994","city_name":"Weymouth","state_id":"3842"},
    {"city_id":"41995","city_name":"Whitefield","state_id":"3842"},
    {"city_id":"41996","city_name":"Whitehaven","state_id":"3842"},
    {"city_id":"41997","city_name":"Whitley Bay","state_id":"3842"},
    {"city_id":"41998","city_name":"Wickford","state_id":"3842"},
    {"city_id":"41999","city_name":"Widnes","state_id":"3842"},
    {"city_id":"42000","city_name":"Wigan","state_id":"3842"},
    {"city_id":"42001","city_name":"Wigston","state_id":"3842"},
    {"city_id":"42002","city_name":"Wilmslow","state_id":"3842"},
    {"city_id":"42003","city_name":"Wimbourne Minster","state_id":"3842"},
    {"city_id":"42004","city_name":"Winchester","state_id":"3842"},
    {"city_id":"42005","city_name":"Windsor Berks","state_id":"3842"},
    {"city_id":"42006","city_name":"Windsor-Eton","state_id":"3842"},
    {"city_id":"42007","city_name":"Winsford","state_id":"3842"},
    {"city_id":"42008","city_name":"Wisbech","state_id":"3842"},
    {"city_id":"42009","city_name":"Witham","state_id":"3842"},
    {"city_id":"42010","city_name":"Witney","state_id":"3842"},
    {"city_id":"42011","city_name":"Woking-Byfleet","state_id":"3842"},
    {"city_id":"42012","city_name":"Wokingham","state_id":"3842"},
    {"city_id":"42013","city_name":"Wolverhampton","state_id":"3842"},
    {"city_id":"42014","city_name":"Wolverton-Stony Stratford","state_id":"3842"},
    {"city_id":"42015","city_name":"Worcester","state_id":"3842"},
    {"city_id":"42016","city_name":"Worcestershire","state_id":"3842"},
    {"city_id":"42017","city_name":"Workington","state_id":"3842"},
    {"city_id":"42018","city_name":"Worksop","state_id":"3842"},
    {"city_id":"42019","city_name":"Worthing","state_id":"3842"},
    {"city_id":"42020","city_name":"Yeovil","state_id":"3842"},
    {"city_id":"42021","city_name":"York","state_id":"3842"},
    {"city_id":"42022","city_name":"Barking","state_id":"3843"},
    {"city_id":"42023","city_name":"Basildon","state_id":"3843"},
    {"city_id":"42024","city_name":"Brentwood","state_id":"3843"},
    {"city_id":"42025","city_name":"Cambrridge","state_id":"3843"},
    {"city_id":"42026","city_name":"Canvey Island","state_id":"3843"},
    {"city_id":"42027","city_name":"Chelmsford","state_id":"3843"},
    {"city_id":"42028","city_name":"Clacton-on-Sea","state_id":"3843"},
    {"city_id":"42029","city_name":"Colchester","state_id":"3843"},
    {"city_id":"42030","city_name":"Dagenham","state_id":"3843"},
    {"city_id":"42031","city_name":"Dunmow","state_id":"3843"},
    {"city_id":"42032","city_name":"Epping","state_id":"3843"},
    {"city_id":"42033","city_name":"Essex","state_id":"3843"},
    {"city_id":"42034","city_name":"Grays","state_id":"3843"},
    {"city_id":"42035","city_name":"Harlow","state_id":"3843"},
    {"city_id":"42036","city_name":"Ilford","state_id":"3843"},
    {"city_id":"42037","city_name":"Ingatestone","state_id":"3843"},
    {"city_id":"42038","city_name":"Leigh on Sea","state_id":"3843"},
    {"city_id":"42039","city_name":"Rainham","state_id":"3843"},
    {"city_id":"42040","city_name":"Romford","state_id":"3843"},
    {"city_id":"42041","city_name":"Saffron Walden","state_id":"3843"},
    {"city_id":"42042","city_name":"Stansted","state_id":"3843"},
    {"city_id":"42043","city_name":"Wickford","state_id":"3843"},
    {"city_id":"42044","city_name":"Ballinamallard","state_id":"3844"},
    {"city_id":"42045","city_name":"Kirkcaldy","state_id":"3845"},
    {"city_id":"42046","city_name":"Ewloe","state_id":"3846"},
    {"city_id":"42047","city_name":"Greenfield","state_id":"3846"},
    {"city_id":"42048","city_name":"Imperial Wharf","state_id":"3847"},
    {"city_id":"42049","city_name":"Kirton-in-Lindsey","state_id":"3848"},
    {"city_id":"42050","city_name":"Berkeley","state_id":"3849"},
    {"city_id":"42051","city_name":"Cheltenham","state_id":"3849"},
    {"city_id":"42052","city_name":"Churchham","state_id":"3849"},
    {"city_id":"42053","city_name":"Cirencester","state_id":"3849"},
    {"city_id":"42054","city_name":"East Kilbride","state_id":"3849"},
    {"city_id":"42055","city_name":"Gloucester","state_id":"3849"},
    {"city_id":"42056","city_name":"Lechlade","state_id":"3849"},
    {"city_id":"42057","city_name":"Lydney","state_id":"3849"},
    {"city_id":"42058","city_name":"Moreton in Marsh","state_id":"3849"},
    {"city_id":"42059","city_name":"Stroud","state_id":"3849"},
    {"city_id":"42060","city_name":"Tewkesbury","state_id":"3849"},
    {"city_id":"42061","city_name":"Blackwood","state_id":"3850"},
    {"city_id":"42062","city_name":"Blaenavon","state_id":"3850"},
    {"city_id":"42063","city_name":"Newport","state_id":"3850"},
    {"city_id":"42064","city_name":"Tredegar","state_id":"3850"},
    {"city_id":"42065","city_name":"Aldershot","state_id":"3851"},
    {"city_id":"42066","city_name":"Alton","state_id":"3851"},
    {"city_id":"42067","city_name":"Andover","state_id":"3851"},
    {"city_id":"42068","city_name":"Bordon","state_id":"3851"},
    {"city_id":"42069","city_name":"Botley","state_id":"3851"},
    {"city_id":"42070","city_name":"Fareham","state_id":"3851"},
    {"city_id":"42071","city_name":"Farnborough","state_id":"3851"},
    {"city_id":"42072","city_name":"Fleet","state_id":"3851"},
    {"city_id":"42073","city_name":"Fordingbridge","state_id":"3851"},
    {"city_id":"42074","city_name":"Havant","state_id":"3851"},
    {"city_id":"42075","city_name":"Hayling Island","state_id":"3851"},
    {"city_id":"42076","city_name":"Hook","state_id":"3851"},
    {"city_id":"42077","city_name":"Isle of wight","state_id":"3851"},
    {"city_id":"42078","city_name":"Liphook","state_id":"3851"},
    {"city_id":"42079","city_name":"Longparish","state_id":"3851"},
    {"city_id":"42080","city_name":"Old Bishopstoke","state_id":"3851"},
    {"city_id":"42081","city_name":"Petersfield","state_id":"3851"},
    {"city_id":"42082","city_name":"Portsmouth","state_id":"3851"},
    {"city_id":"42083","city_name":"Ringwood","state_id":"3851"},
    {"city_id":"42084","city_name":"Romsey","state_id":"3851"},
    {"city_id":"42085","city_name":"South Harting","state_id":"3851"},
    {"city_id":"42086","city_name":"Southampton","state_id":"3851"},
    {"city_id":"42087","city_name":"Waterlooville","state_id":"3851"},
    {"city_id":"42088","city_name":"West Wellow","state_id":"3851"},
    {"city_id":"42089","city_name":"Winchester","state_id":"3851"},
    {"city_id":"42090","city_name":"Lymington","state_id":"3852"},
    {"city_id":"42091","city_name":"Pennington","state_id":"3852"},
    {"city_id":"42092","city_name":"Southampton","state_id":"3852"},
    {"city_id":"42093","city_name":"Kington","state_id":"3853"},
    {"city_id":"42094","city_name":"Ledbury","state_id":"3853"},
    {"city_id":"42095","city_name":"Leominster","state_id":"3853"},
    {"city_id":"42096","city_name":"Saint Albans","state_id":"3853"},
    {"city_id":"42097","city_name":"Barnet","state_id":"3854"},
    {"city_id":"42098","city_name":"Bishops Stortford","state_id":"3854"},
    {"city_id":"42099","city_name":"Borehamwood","state_id":"3854"},
    {"city_id":"42100","city_name":"Brookmans Park","state_id":"3854"},
    {"city_id":"42101","city_name":"Bushey","state_id":"3854"},
    {"city_id":"42102","city_name":"Cheshunt","state_id":"3854"},
    {"city_id":"42103","city_name":"Cuffley","state_id":"3854"},
    {"city_id":"42104","city_name":"Elstree","state_id":"3854"},
    {"city_id":"42105","city_name":"Hemel Hempstead","state_id":"3854"},
    {"city_id":"42106","city_name":"Hertfordshire","state_id":"3854"},
    {"city_id":"42107","city_name":"Kings Langley","state_id":"3854"},
    {"city_id":"42108","city_name":"Much Hadham","state_id":"3854"},
    {"city_id":"42109","city_name":"Radlett","state_id":"3854"},
    {"city_id":"42110","city_name":"Rickmansworth","state_id":"3854"},
    {"city_id":"42111","city_name":"Royston","state_id":"3854"},
    {"city_id":"42112","city_name":"Stevenage","state_id":"3854"},
    {"city_id":"42113","city_name":"Waltham Cross","state_id":"3854"},
    {"city_id":"42114","city_name":"Watford","state_id":"3854"},
    {"city_id":"42115","city_name":"Welwyn","state_id":"3854"},
    {"city_id":"42116","city_name":"Newmarket","state_id":"3858"},
    {"city_id":"42117","city_name":"Ashford","state_id":"3859"},
    {"city_id":"42118","city_name":"Beckenham","state_id":"3859"},
    {"city_id":"42119","city_name":"Bromley","state_id":"3859"},
    {"city_id":"42120","city_name":"Brookland","state_id":"3859"},
    {"city_id":"42121","city_name":"Charing","state_id":"3859"},
    {"city_id":"42122","city_name":"Chatam","state_id":"3859"},
    {"city_id":"42123","city_name":"Crayford","state_id":"3859"},
    {"city_id":"42124","city_name":"Edenbridge","state_id":"3859"},
    {"city_id":"42125","city_name":"Erith","state_id":"3859"},
    {"city_id":"42126","city_name":"Faversham","state_id":"3859"},
    {"city_id":"42127","city_name":"Five Oak Green","state_id":"3859"},
    {"city_id":"42128","city_name":"Folkestone","state_id":"3859"},
    {"city_id":"42129","city_name":"Gillingham","state_id":"3859"},
    {"city_id":"42130","city_name":"Gravesend","state_id":"3859"},
    {"city_id":"42131","city_name":"Hartlip","state_id":"3859"},
    {"city_id":"42132","city_name":"Hayes","state_id":"3859"},
    {"city_id":"42133","city_name":"Herne Bay","state_id":"3859"},
    {"city_id":"42134","city_name":"Hythe","state_id":"3859"},
    {"city_id":"42135","city_name":"Lenham","state_id":"3859"},
    {"city_id":"42136","city_name":"Maidstone","state_id":"3859"},
    {"city_id":"42137","city_name":"Minster","state_id":"3859"},
    {"city_id":"42138","city_name":"New Romney","state_id":"3859"},
    {"city_id":"42139","city_name":"Orpington","state_id":"3859"},
    {"city_id":"42140","city_name":"Paddock Wood","state_id":"3859"},
    {"city_id":"42141","city_name":"Royal Tunbridge Wells","state_id":"3859"},
    {"city_id":"42142","city_name":"Sandwich","state_id":"3859"},
    {"city_id":"42143","city_name":"Sheerness","state_id":"3859"},
    {"city_id":"42144","city_name":"Sidcup","state_id":"3859"},
    {"city_id":"42145","city_name":"Sittingbourne","state_id":"3859"},
    {"city_id":"42146","city_name":"Staplehurst","state_id":"3859"},
    {"city_id":"42147","city_name":"Tunbridge Wells","state_id":"3859"},
    {"city_id":"42148","city_name":"West Malling","state_id":"3859"},
    {"city_id":"42149","city_name":"Westerham","state_id":"3859"},
    {"city_id":"42150","city_name":"Whitstable","state_id":"3859"},
    {"city_id":"42151","city_name":"canterbury","state_id":"3859"},
    {"city_id":"42152","city_name":"Ayrshire","state_id":"3860"},
    {"city_id":"42153","city_name":"Airdrie","state_id":"3861"},
    {"city_id":"42154","city_name":"Glasgow","state_id":"3861"},
    {"city_id":"42155","city_name":"Accrington","state_id":"3862"},
    {"city_id":"42156","city_name":"Blackburn","state_id":"3862"},
    {"city_id":"42157","city_name":"Blackpool","state_id":"3862"},
    {"city_id":"42158","city_name":"Burnley","state_id":"3862"},
    {"city_id":"42159","city_name":"Clayton-Le-Moors","state_id":"3862"},
    {"city_id":"42160","city_name":"Cleveleys","state_id":"3862"},
    {"city_id":"42161","city_name":"Darwen","state_id":"3862"},
    {"city_id":"42162","city_name":"Gisburn","state_id":"3862"},
    {"city_id":"42163","city_name":"Glasgow","state_id":"3862"},
    {"city_id":"42164","city_name":"Greater Manchester","state_id":"3862"},
    {"city_id":"42165","city_name":"Hamilton","state_id":"3862"},
    {"city_id":"42166","city_name":"Kirkby Lonsdale","state_id":"3862"},
    {"city_id":"42167","city_name":"Leyland","state_id":"3862"},
    {"city_id":"42168","city_name":"Littleborough","state_id":"3862"},
    {"city_id":"42169","city_name":"Lytham St Annes","state_id":"3862"},
    {"city_id":"42170","city_name":"Nelson","state_id":"3862"},
    {"city_id":"42171","city_name":"Oldham","state_id":"3862"},
    {"city_id":"42172","city_name":"Out Rawcliffe","state_id":"3862"},
    {"city_id":"42173","city_name":"Padiham","state_id":"3862"},
    {"city_id":"42174","city_name":"Preston","state_id":"3862"},
    {"city_id":"42175","city_name":"Rochdale","state_id":"3862"},
    {"city_id":"42176","city_name":"Rossendale","state_id":"3862"},
    {"city_id":"42177","city_name":"Tarleton","state_id":"3862"},
    {"city_id":"42178","city_name":"Todmorden","state_id":"3862"},
    {"city_id":"42179","city_name":"West Lancashire","state_id":"3862"},
    {"city_id":"42180","city_name":"Coalville","state_id":"3863"},
    {"city_id":"42181","city_name":"Fleckney","state_id":"3863"},
    {"city_id":"42182","city_name":"Leicester","state_id":"3863"},
    {"city_id":"42183","city_name":"Loughborough","state_id":"3863"},
    {"city_id":"42184","city_name":"Lutterworth","state_id":"3863"},
    {"city_id":"42185","city_name":"Market Harborough","state_id":"3863"},
    {"city_id":"42186","city_name":"Tur Langton","state_id":"3863"},
    {"city_id":"42187","city_name":"Alford","state_id":"3864"},
    {"city_id":"42188","city_name":"Bourne","state_id":"3864"},
    {"city_id":"42189","city_name":"Casewick","state_id":"3864"},
    {"city_id":"42190","city_name":"Digby","state_id":"3864"},
    {"city_id":"42191","city_name":"Gainsborough","state_id":"3864"},
    {"city_id":"42192","city_name":"Grimsby","state_id":"3864"},
    {"city_id":"42193","city_name":"Immingham","state_id":"3864"},
    {"city_id":"42194","city_name":"Laceby","state_id":"3864"},
    {"city_id":"42195","city_name":"Lincoln","state_id":"3864"},
    {"city_id":"42196","city_name":"Louth","state_id":"3864"},
    {"city_id":"42197","city_name":"Market Deeping","state_id":"3864"},
    {"city_id":"42198","city_name":"Market Rasen","state_id":"3864"},
    {"city_id":"42199","city_name":"Spalding","state_id":"3864"},
    {"city_id":"42200","city_name":"Spilsby","state_id":"3864"},
    {"city_id":"42201","city_name":"Swinderby","state_id":"3864"},
    {"city_id":"42202","city_name":"Thurlby","state_id":"3864"},
    {"city_id":"42203","city_name":"Witham St Hughs","state_id":"3864"},
    {"city_id":"42204","city_name":"Llanymynech","state_id":"3865"},
    {"city_id":"42205","city_name":"Abbeywood","state_id":"3866"},
    {"city_id":"42206","city_name":"Aldgate","state_id":"3866"},
    {"city_id":"42207","city_name":"Alperton","state_id":"3866"},
    {"city_id":"42208","city_name":"Castledawson","state_id":"3866"},
    {"city_id":"42209","city_name":"Edmonton","state_id":"3866"},
    {"city_id":"42210","city_name":"Enfield","state_id":"3866"},
    {"city_id":"42211","city_name":"Forest Gate","state_id":"3866"},
    {"city_id":"42212","city_name":"Greenwich","state_id":"3866"},
    {"city_id":"42213","city_name":"Hainault","state_id":"3866"},
    {"city_id":"42214","city_name":"Hampstead","state_id":"3866"},
    {"city_id":"42215","city_name":"Harrow Weald","state_id":"3866"},
    {"city_id":"42216","city_name":"Hendon","state_id":"3866"},
    {"city_id":"42217","city_name":"Kensington","state_id":"3866"},
    {"city_id":"42218","city_name":"Leyton","state_id":"3866"},
    {"city_id":"42219","city_name":"London","state_id":"3866"},
    {"city_id":"42220","city_name":"Magherafelt","state_id":"3866"},
    {"city_id":"42221","city_name":"Mill Hill","state_id":"3866"},
    {"city_id":"42222","city_name":"Southwark","state_id":"3866"},
    {"city_id":"42223","city_name":"Suffolk","state_id":"3866"},
    {"city_id":"42224","city_name":"Sulham","state_id":"3866"},
    {"city_id":"42225","city_name":"Victoria","state_id":"3866"},
    {"city_id":"42226","city_name":"Walthamstow","state_id":"3866"},
    {"city_id":"42227","city_name":"Wandsworth","state_id":"3866"},
    {"city_id":"42228","city_name":"Wembley","state_id":"3866"},
    {"city_id":"42229","city_name":"Wimbledon","state_id":"3866"},
    {"city_id":"42230","city_name":"Woolwich","state_id":"3866"},
    {"city_id":"42231","city_name":"Ludlow","state_id":"3867"},
    {"city_id":"42232","city_name":"Manchester","state_id":"3868"},
    {"city_id":"42233","city_name":"Prestwich","state_id":"3868"},
    {"city_id":"42234","city_name":"Salford","state_id":"3868"},
    {"city_id":"42235","city_name":"Swinton","state_id":"3868"},
    {"city_id":"42236","city_name":"Worsley","state_id":"3868"},
    {"city_id":"42237","city_name":"Mayfair","state_id":"3869"},
    {"city_id":"42238","city_name":"Southport","state_id":"3870"},
    {"city_id":"42239","city_name":"Brentford","state_id":"3872"},
    {"city_id":"42240","city_name":"Brimsdown","state_id":"3872"},
    {"city_id":"42241","city_name":"Drayton","state_id":"3872"},
    {"city_id":"42242","city_name":"Edgware","state_id":"3872"},
    {"city_id":"42243","city_name":"Feltham","state_id":"3872"},
    {"city_id":"42244","city_name":"Greenford","state_id":"3872"},
    {"city_id":"42245","city_name":"Hampton","state_id":"3872"},
    {"city_id":"42246","city_name":"Harmondsworth","state_id":"3872"},
    {"city_id":"42247","city_name":"Harrow","state_id":"3872"},
    {"city_id":"42248","city_name":"Hayes","state_id":"3872"},
    {"city_id":"42249","city_name":"Isleworth","state_id":"3872"},
    {"city_id":"42250","city_name":"Northolt","state_id":"3872"},
    {"city_id":"42251","city_name":"Northwood","state_id":"3872"},
    {"city_id":"42252","city_name":"Perivale","state_id":"3872"},
    {"city_id":"42253","city_name":"Pinner","state_id":"3872"},
    {"city_id":"42254","city_name":"Ruislip","state_id":"3872"},
    {"city_id":"42255","city_name":"Ruislip Manor","state_id":"3872"},
    {"city_id":"42256","city_name":"South Harrow","state_id":"3872"},
    {"city_id":"42257","city_name":"Southall","state_id":"3872"},
    {"city_id":"42258","city_name":"Staines","state_id":"3872"},
    {"city_id":"42259","city_name":"Stamore","state_id":"3872"},
    {"city_id":"42260","city_name":"Stanmore","state_id":"3872"},
    {"city_id":"42261","city_name":"Stanwell","state_id":"3872"},
    {"city_id":"42262","city_name":"Sunbury","state_id":"3872"},
    {"city_id":"42263","city_name":"Teddington","state_id":"3872"},
    {"city_id":"42264","city_name":"Twickenham","state_id":"3872"},
    {"city_id":"42265","city_name":"Uxbridge","state_id":"3872"},
    {"city_id":"42266","city_name":"Watford","state_id":"3872"},
    {"city_id":"42267","city_name":"Wembley","state_id":"3872"},
    {"city_id":"42268","city_name":"West Drayton","state_id":"3872"},
    {"city_id":"42269","city_name":"Wraysbury","state_id":"3872"},
    {"city_id":"42270","city_name":"hounslow","state_id":"3872"},
    {"city_id":"42271","city_name":"Mildenhall","state_id":"3873"},
    {"city_id":"42272","city_name":"Abergavenny","state_id":"3874"},
    {"city_id":"42273","city_name":"Monmouth","state_id":"3874"},
    {"city_id":"42274","city_name":"Attleborough","state_id":"3876"},
    {"city_id":"42275","city_name":"Bacton","state_id":"3876"},
    {"city_id":"42276","city_name":"Briston","state_id":"3876"},
    {"city_id":"42277","city_name":"Dereham","state_id":"3876"},
    {"city_id":"42278","city_name":"Diss","state_id":"3876"},
    {"city_id":"42279","city_name":"Downham Market","state_id":"3876"},
    {"city_id":"42280","city_name":"Fakenham","state_id":"3876"},
    {"city_id":"42281","city_name":"Garboldisham","state_id":"3876"},
    {"city_id":"42282","city_name":"Gayton","state_id":"3876"},
    {"city_id":"42283","city_name":"Glandford","state_id":"3876"},
    {"city_id":"42284","city_name":"Great Yarmouth","state_id":"3876"},
    {"city_id":"42285","city_name":"Heacham","state_id":"3876"},
    {"city_id":"42286","city_name":"Hopton","state_id":"3876"},
    {"city_id":"42287","city_name":"Kings Lynn","state_id":"3876"},
    {"city_id":"42288","city_name":"Little Cressingham","state_id":"3876"},
    {"city_id":"42289","city_name":"Norwich","state_id":"3876"},
    {"city_id":"42290","city_name":"Sheringham","state_id":"3876"},
    {"city_id":"42291","city_name":"Thetford","state_id":"3876"},
    {"city_id":"42292","city_name":"Trunch","state_id":"3876"},
    {"city_id":"42293","city_name":"Winordhan","state_id":"3876"},
    {"city_id":"42294","city_name":"Wymondham","state_id":"3876"},
    {"city_id":"42295","city_name":"Daventry","state_id":"3879"},
    {"city_id":"42296","city_name":"Irthlingborough","state_id":"3879"},
    {"city_id":"42297","city_name":"Middleton Cheney","state_id":"3879"},
    {"city_id":"42298","city_name":"Oundle","state_id":"3879"},
    {"city_id":"42299","city_name":"Towcester","state_id":"3879"},
    {"city_id":"42300","city_name":"Welford","state_id":"3879"},
    {"city_id":"42301","city_name":"Wellingborough","state_id":"3879"},
    {"city_id":"42302","city_name":"Woodford Halse","state_id":"3879"},
    {"city_id":"42303","city_name":"Brackley","state_id":"3880"},
    {"city_id":"42304","city_name":"Desborough","state_id":"3880"},
    {"city_id":"42305","city_name":"weedon","state_id":"3880"},
    {"city_id":"42306","city_name":"Bedlington","state_id":"3882"},
    {"city_id":"42307","city_name":"Corbridge","state_id":"3882"},
    {"city_id":"42308","city_name":"Cramlington","state_id":"3882"},
    {"city_id":"42309","city_name":"Morpeth","state_id":"3882"},
    {"city_id":"42310","city_name":"Northumberland","state_id":"3882"},
    {"city_id":"42311","city_name":"Ponteland","state_id":"3882"},
    {"city_id":"42312","city_name":"Wooler","state_id":"3882"},
    {"city_id":"42313","city_name":"Burton Joyce","state_id":"3883"},
    {"city_id":"42314","city_name":"Cotgraves","state_id":"3883"},
    {"city_id":"42315","city_name":"Gonalston","state_id":"3883"},
    {"city_id":"42316","city_name":"Mansfield","state_id":"3883"},
    {"city_id":"42317","city_name":"Newark","state_id":"3883"},
    {"city_id":"42318","city_name":"Nottingham","state_id":"3883"},
    {"city_id":"42319","city_name":"Pennyfoot Street","state_id":"3883"},
    {"city_id":"42320","city_name":"Sandiacre","state_id":"3883"},
    {"city_id":"42321","city_name":"Southwell","state_id":"3883"},
    {"city_id":"42322","city_name":"Whatton","state_id":"3883"},
    {"city_id":"42323","city_name":"Bampton","state_id":"3884"},
    {"city_id":"42324","city_name":"Banbury","state_id":"3884"},
    {"city_id":"42325","city_name":"Bicester","state_id":"3884"},
    {"city_id":"42326","city_name":"Blewbury","state_id":"3884"},
    {"city_id":"42327","city_name":"Cheltenham","state_id":"3884"},
    {"city_id":"42328","city_name":"Chipping Norton","state_id":"3884"},
    {"city_id":"42329","city_name":"Drayton","state_id":"3884"},
    {"city_id":"42330","city_name":"Eynsham","state_id":"3884"},
    {"city_id":"42331","city_name":"Farringdon","state_id":"3884"},
    {"city_id":"42332","city_name":"Henely on Thames","state_id":"3884"},
    {"city_id":"42333","city_name":"Henley-on-Thames","state_id":"3884"},
    {"city_id":"42334","city_name":"Oxford","state_id":"3884"},
    {"city_id":"42335","city_name":"Shenington","state_id":"3884"},
    {"city_id":"42336","city_name":"Thame","state_id":"3884"},
    {"city_id":"42337","city_name":"Wantage","state_id":"3884"},
    {"city_id":"42338","city_name":"Builth Wells","state_id":"3885"},
    {"city_id":"42339","city_name":"Knighton","state_id":"3885"},
    {"city_id":"42340","city_name":"Llanbrynmair","state_id":"3885"},
    {"city_id":"42341","city_name":"New town","state_id":"3885"},
    {"city_id":"42342","city_name":"Newtown","state_id":"3885"},
    {"city_id":"42343","city_name":"Rhaeadr","state_id":"3885"},
    {"city_id":"42344","city_name":"Welshpool","state_id":"3885"},
    {"city_id":"42345","city_name":"Hill of Fearn","state_id":"3886"},
    {"city_id":"42346","city_name":"Shoreham","state_id":"3887"},
    {"city_id":"42347","city_name":"Sark","state_id":"3888"},
    {"city_id":"42348","city_name":"Aberdeen","state_id":"3889"},
    {"city_id":"42349","city_name":"Alloa","state_id":"3889"},
    {"city_id":"42350","city_name":"Alness","state_id":"3889"},
    {"city_id":"42351","city_name":"Annan","state_id":"3889"},
    {"city_id":"42352","city_name":"Arbroath","state_id":"3889"},
    {"city_id":"42353","city_name":"Ardrossan","state_id":"3889"},
    {"city_id":"42354","city_name":"Armadale","state_id":"3889"},
    {"city_id":"42355","city_name":"Ayr","state_id":"3889"},
    {"city_id":"42356","city_name":"Bathgate","state_id":"3889"},
    {"city_id":"42357","city_name":"Blairgowrie","state_id":"3889"},
    {"city_id":"42358","city_name":"Blantyre-Hamilton","state_id":"3889"},
    {"city_id":"42359","city_name":"Boness","state_id":"3889"},
    {"city_id":"42360","city_name":"Bonnybridge","state_id":"3889"},
    {"city_id":"42361","city_name":"Broxburn","state_id":"3889"},
    {"city_id":"42362","city_name":"Broxham","state_id":"3889"},
    {"city_id":"42363","city_name":"Buckhaven","state_id":"3889"},
    {"city_id":"42364","city_name":"Burntisland","state_id":"3889"},
    {"city_id":"42365","city_name":"Carluke","state_id":"3889"},
    {"city_id":"42366","city_name":"Carnoustie","state_id":"3889"},
    {"city_id":"42367","city_name":"Coatbridge","state_id":"3889"},
    {"city_id":"42368","city_name":"Cowdenbeath","state_id":"3889"},
    {"city_id":"42369","city_name":"Cumbernauld","state_id":"3889"},
    {"city_id":"42370","city_name":"Cumnock","state_id":"3889"},
    {"city_id":"42371","city_name":"Cupar","state_id":"3889"},
    {"city_id":"42372","city_name":"Dalbeattie","state_id":"3889"},
    {"city_id":"42373","city_name":"Dalkeith","state_id":"3889"},
    {"city_id":"42374","city_name":"Dingwall","state_id":"3889"},
    {"city_id":"42375","city_name":"Dumbarton","state_id":"3889"},
    {"city_id":"42376","city_name":"Dumfries","state_id":"3889"},
    {"city_id":"42377","city_name":"Dundee","state_id":"3889"},
    {"city_id":"42378","city_name":"Dunfermline","state_id":"3889"},
    {"city_id":"42379","city_name":"Dunoon","state_id":"3889"},
    {"city_id":"42380","city_name":"East Kilbride","state_id":"3889"},
    {"city_id":"42381","city_name":"Edimburah","state_id":"3889"},
    {"city_id":"42382","city_name":"Edinburgh","state_id":"3889"},
    {"city_id":"42383","city_name":"Elgin","state_id":"3889"},
    {"city_id":"42384","city_name":"Ellon","state_id":"3889"},
    {"city_id":"42385","city_name":"Erskine","state_id":"3889"},
    {"city_id":"42386","city_name":"Falkirk","state_id":"3889"},
    {"city_id":"42387","city_name":"Forfar","state_id":"3889"},
    {"city_id":"42388","city_name":"Forres","state_id":"3889"},
    {"city_id":"42389","city_name":"Fort William","state_id":"3889"},
    {"city_id":"42390","city_name":"Fraserburgh","state_id":"3889"},
    {"city_id":"42391","city_name":"Galashiels","state_id":"3889"},
    {"city_id":"42392","city_name":"Galston-Newmilns","state_id":"3889"},
    {"city_id":"42393","city_name":"Girvan","state_id":"3889"},
    {"city_id":"42394","city_name":"Glasgow","state_id":"3889"},
    {"city_id":"42395","city_name":"Glenrothes","state_id":"3889"},
    {"city_id":"42396","city_name":"Greengairs","state_id":"3889"},
    {"city_id":"42397","city_name":"Greenock","state_id":"3889"},
    {"city_id":"42398","city_name":"Haddington","state_id":"3889"},
    {"city_id":"42399","city_name":"Hawick","state_id":"3889"},
    {"city_id":"42400","city_name":"Helensburgh","state_id":"3889"},
    {"city_id":"42401","city_name":"Insch","state_id":"3889"},
    {"city_id":"42402","city_name":"Inverkeithing-Dalgety Bay","state_id":"3889"},
    {"city_id":"42403","city_name":"Inverness","state_id":"3889"},
    {"city_id":"42404","city_name":"Inverurie","state_id":"3889"},
    {"city_id":"42405","city_name":"Irvine","state_id":"3889"},
    {"city_id":"42406","city_name":"Isle of Lewis","state_id":"3889"},
    {"city_id":"42407","city_name":"Kilmarnock","state_id":"3889"},
    {"city_id":"42408","city_name":"Kilsyth","state_id":"3889"},
    {"city_id":"42409","city_name":"Kilwinning","state_id":"3889"},
    {"city_id":"42410","city_name":"Kirkcaldy","state_id":"3889"},
    {"city_id":"42411","city_name":"Kirkintilloch-Lenzie","state_id":"3889"},
    {"city_id":"42412","city_name":"Kirkwall","state_id":"3889"},
    {"city_id":"42413","city_name":"Lanark","state_id":"3889"},
    {"city_id":"42414","city_name":"Largs","state_id":"3889"},
    {"city_id":"42415","city_name":"Larkhall","state_id":"3889"},
    {"city_id":"42416","city_name":"Lerwick","state_id":"3889"},
    {"city_id":"42417","city_name":"Linlithgow","state_id":"3889"},
    {"city_id":"42418","city_name":"Livingston","state_id":"3889"},
    {"city_id":"42419","city_name":"Loanhead","state_id":"3889"},
    {"city_id":"42420","city_name":"Montrose","state_id":"3889"},
    {"city_id":"42421","city_name":"Motherwell","state_id":"3889"},
    {"city_id":"42422","city_name":"Nairn","state_id":"3889"},
    {"city_id":"42423","city_name":"Newtown Saint Boswells","state_id":"3889"},
    {"city_id":"42424","city_name":"Paisley","state_id":"3889"},
    {"city_id":"42425","city_name":"Penicuik","state_id":"3889"},
    {"city_id":"42426","city_name":"Perth","state_id":"3889"},
    {"city_id":"42427","city_name":"Peterhead","state_id":"3889"},
    {"city_id":"42428","city_name":"Saint Andrews","state_id":"3889"},
    {"city_id":"42429","city_name":"Selkirkshire","state_id":"3889"},
    {"city_id":"42430","city_name":"Shotts","state_id":"3889"},
    {"city_id":"42431","city_name":"Stirling","state_id":"3889"},
    {"city_id":"42432","city_name":"Stonehaven","state_id":"3889"},
    {"city_id":"42433","city_name":"Stornoway","state_id":"3889"},
    {"city_id":"42434","city_name":"Stranraer","state_id":"3889"},
    {"city_id":"42435","city_name":"Tranent","state_id":"3889"},
    {"city_id":"42436","city_name":"Troon","state_id":"3889"},
    {"city_id":"42437","city_name":"Whitburn","state_id":"3889"},
    {"city_id":"42438","city_name":"Bishops Castle","state_id":"3891"},
    {"city_id":"42439","city_name":"Bridgnorth","state_id":"3891"},
    {"city_id":"42440","city_name":"Bucknell","state_id":"3891"},
    {"city_id":"42441","city_name":"Drayton","state_id":"3891"},
    {"city_id":"42442","city_name":"Greete","state_id":"3891"},
    {"city_id":"42443","city_name":"Hinstock","state_id":"3891"},
    {"city_id":"42444","city_name":"Jackfield","state_id":"3891"},
    {"city_id":"42445","city_name":"Ludlow","state_id":"3891"},
    {"city_id":"42446","city_name":"Much Wenlock","state_id":"3891"},
    {"city_id":"42447","city_name":"Oswestry","state_id":"3891"},
    {"city_id":"42448","city_name":"Ryton","state_id":"3891"},
    {"city_id":"42449","city_name":"Shifnal","state_id":"3891"},
    {"city_id":"42450","city_name":"Shrewsbury","state_id":"3891"},
    {"city_id":"42451","city_name":"Telford","state_id":"3891"},
    {"city_id":"42452","city_name":"Whitchurch","state_id":"3891"},
    {"city_id":"42453","city_name":"Bath","state_id":"3892"},
    {"city_id":"42454","city_name":"Brent Knoll","state_id":"3892"},
    {"city_id":"42455","city_name":"Castle Cary","state_id":"3892"},
    {"city_id":"42456","city_name":"Shepton Mallet","state_id":"3892"},
    {"city_id":"42457","city_name":"Somerset","state_id":"3892"},
    {"city_id":"42458","city_name":"Taunton","state_id":"3892"},
    {"city_id":"42459","city_name":"Wedmore","state_id":"3892"},
    {"city_id":"42460","city_name":"Wellington","state_id":"3892"},
    {"city_id":"42461","city_name":"Weston-super-Mare","state_id":"3892"},
    {"city_id":"42462","city_name":"Burton-on-Trent","state_id":"3897"},
    {"city_id":"42463","city_name":"Hednesford","state_id":"3897"},
    {"city_id":"42464","city_name":"Stoke on Trent","state_id":"3897"},
    {"city_id":"42465","city_name":"Stone","state_id":"3897"},
    {"city_id":"42466","city_name":"Strabane","state_id":"3898"},
    {"city_id":"42467","city_name":"Bury St Edmunds","state_id":"3899"},
    {"city_id":"42468","city_name":"Felixstowe","state_id":"3899"},
    {"city_id":"42469","city_name":"Haverhill","state_id":"3899"},
    {"city_id":"42470","city_name":"Leiston","state_id":"3899"},
    {"city_id":"42471","city_name":"Lowestoft","state_id":"3899"},
    {"city_id":"42472","city_name":"Stowmarket","state_id":"3899"},
    {"city_id":"42473","city_name":"Sudbury","state_id":"3899"},
    {"city_id":"42474","city_name":"Woodbridge","state_id":"3899"},
    {"city_id":"42475","city_name":"Ashtead","state_id":"3900"},
    {"city_id":"42476","city_name":"Bagshot","state_id":"3900"},
    {"city_id":"42477","city_name":"Betchworth","state_id":"3900"},
    {"city_id":"42478","city_name":"Bletchingley","state_id":"3900"},
    {"city_id":"42479","city_name":"Carshalton","state_id":"3900"},
    {"city_id":"42480","city_name":"Chertsey","state_id":"3900"},
    {"city_id":"42481","city_name":"Claygate","state_id":"3900"},
    {"city_id":"42482","city_name":"Croydon","state_id":"3900"},
    {"city_id":"42483","city_name":"Dorking","state_id":"3900"},
    {"city_id":"42484","city_name":"Effingham","state_id":"3900"},
    {"city_id":"42485","city_name":"Epsom","state_id":"3900"},
    {"city_id":"42486","city_name":"Farnham","state_id":"3900"},
    {"city_id":"42487","city_name":"Haslemere","state_id":"3900"},
    {"city_id":"42488","city_name":"Kingston Upon Thames","state_id":"3900"},
    {"city_id":"42489","city_name":"Leatherhead","state_id":"3900"},
    {"city_id":"42490","city_name":"Mitcham","state_id":"3900"},
    {"city_id":"42491","city_name":"New Malden","state_id":"3900"},
    {"city_id":"42492","city_name":"Redhill","state_id":"3900"},
    {"city_id":"42493","city_name":"Richmond","state_id":"3900"},
    {"city_id":"42494","city_name":"Salfords","state_id":"3900"},
    {"city_id":"42495","city_name":"Shepperton","state_id":"3900"},
    {"city_id":"42496","city_name":"Stoneleigh","state_id":"3900"},
    {"city_id":"42497","city_name":"Surbiton","state_id":"3900"},
    {"city_id":"42498","city_name":"Surrey","state_id":"3900"},
    {"city_id":"42499","city_name":"Tadworth","state_id":"3900"},
    {"city_id":"42500","city_name":"Walton on Thames","state_id":"3900"},
    {"city_id":"42501","city_name":"West Molesey","state_id":"3900"},
    {"city_id":"42502","city_name":"Wisley","state_id":"3900"},
    {"city_id":"42503","city_name":"Woking","state_id":"3900"},
    {"city_id":"42504","city_name":"Brighton","state_id":"3901"},
    {"city_id":"42505","city_name":"Henfield","state_id":"3901"},
    {"city_id":"42506","city_name":"Sussex","state_id":"3901"},
    {"city_id":"42507","city_name":"Worthing","state_id":"3901"},
    {"city_id":"42508","city_name":"Twickenham","state_id":"3902"},
    {"city_id":"42509","city_name":"Omagh","state_id":"3904"},
    {"city_id":"42510","city_name":"Santaquin","state_id":"3905"},
    {"city_id":"42511","city_name":"Aberdare","state_id":"3906"},
    {"city_id":"42512","city_name":"Aberystwyth","state_id":"3906"},
    {"city_id":"42513","city_name":"Barry","state_id":"3906"},
    {"city_id":"42514","city_name":"Brecon","state_id":"3906"},
    {"city_id":"42515","city_name":"Bridgend","state_id":"3906"},
    {"city_id":"42516","city_name":"Brynmawr","state_id":"3906"},
    {"city_id":"42517","city_name":"Caernarfon","state_id":"3906"},
    {"city_id":"42518","city_name":"Caerphily","state_id":"3906"},
    {"city_id":"42519","city_name":"Caldicot","state_id":"3906"},
    {"city_id":"42520","city_name":"Cardiff","state_id":"3906"},
    {"city_id":"42521","city_name":"Carmarthen","state_id":"3906"},
    {"city_id":"42522","city_name":"Colwyn Bay","state_id":"3906"},
    {"city_id":"42523","city_name":"Connahs Quay","state_id":"3906"},
    {"city_id":"42524","city_name":"Cwmbran","state_id":"3906"},
    {"city_id":"42525","city_name":"Dolgellau","state_id":"3906"},
    {"city_id":"42526","city_name":"Ebbw Vale","state_id":"3906"},
    {"city_id":"42527","city_name":"Gaerwen","state_id":"3906"},
    {"city_id":"42528","city_name":"Gwynedd","state_id":"3906"},
    {"city_id":"42529","city_name":"Haverfordwest","state_id":"3906"},
    {"city_id":"42530","city_name":"Isle of Anglesey","state_id":"3906"},
    {"city_id":"42531","city_name":"Islwyn","state_id":"3906"},
    {"city_id":"42532","city_name":"Llandrindod Wells","state_id":"3906"},
    {"city_id":"42533","city_name":"Llanelli","state_id":"3906"},
    {"city_id":"42534","city_name":"Llangefni","state_id":"3906"},
    {"city_id":"42535","city_name":"Maesteg","state_id":"3906"},
    {"city_id":"42536","city_name":"Merthyr Tydfil","state_id":"3906"},
    {"city_id":"42537","city_name":"Mold","state_id":"3906"},
    {"city_id":"42538","city_name":"Mountain Ash-Abercynon","state_id":"3906"},
    {"city_id":"42539","city_name":"Neath","state_id":"3906"},
    {"city_id":"42540","city_name":"Newport","state_id":"3906"},
    {"city_id":"42541","city_name":"Pembrokeshire","state_id":"3906"},
    {"city_id":"42542","city_name":"Penarth","state_id":"3906"},
    {"city_id":"42543","city_name":"Pencader","state_id":"3906"},
    {"city_id":"42544","city_name":"Pontypool","state_id":"3906"},
    {"city_id":"42545","city_name":"Pontypridd","state_id":"3906"},
    {"city_id":"42546","city_name":"Port Talbot","state_id":"3906"},
    {"city_id":"42547","city_name":"Queensferry","state_id":"3906"},
    {"city_id":"42548","city_name":"Rhondda","state_id":"3906"},
    {"city_id":"42549","city_name":"Rhyl","state_id":"3906"},
    {"city_id":"42550","city_name":"Ruthin","state_id":"3906"},
    {"city_id":"42551","city_name":"Shotton-Hawarden","state_id":"3906"},
    {"city_id":"42552","city_name":"St. Asaph","state_id":"3906"},
    {"city_id":"42553","city_name":"Swansea","state_id":"3906"},
    {"city_id":"42554","city_name":"West Glamorgan","state_id":"3906"},
    {"city_id":"42555","city_name":"Wrexham","state_id":"3906"},
    {"city_id":"42556","city_name":"Alcester","state_id":"3907"},
    {"city_id":"42557","city_name":"Coventry","state_id":"3907"},
    {"city_id":"42558","city_name":"Henley in Arden","state_id":"3907"},
    {"city_id":"42559","city_name":"Nuneaton","state_id":"3907"},
    {"city_id":"42560","city_name":"Pershore","state_id":"3907"},
    {"city_id":"42561","city_name":"Southam","state_id":"3907"},
    {"city_id":"42562","city_name":"Warwick","state_id":"3907"},
    {"city_id":"42563","city_name":"Whissendine","state_id":"3912"},
    {"city_id":"42564","city_name":"Amesbury","state_id":"3913"},
    {"city_id":"42565","city_name":"Bradford on Avon","state_id":"3913"},
    {"city_id":"42566","city_name":"Calne","state_id":"3913"},
    {"city_id":"42567","city_name":"Chippenham","state_id":"3913"},
    {"city_id":"42568","city_name":"Corsham","state_id":"3913"},
    {"city_id":"42569","city_name":"Cosham","state_id":"3913"},
    {"city_id":"42570","city_name":"Devizes","state_id":"3913"},
    {"city_id":"42571","city_name":"Downton","state_id":"3913"},
    {"city_id":"42572","city_name":"Malmesbury","state_id":"3913"},
    {"city_id":"42573","city_name":"Marlborough","state_id":"3913"},
    {"city_id":"42574","city_name":"Melksham","state_id":"3913"},
    {"city_id":"42575","city_name":"Pewsey","state_id":"3913"},
    {"city_id":"42576","city_name":"Salisbury","state_id":"3913"},
    {"city_id":"42577","city_name":"Southwick","state_id":"3913"},
    {"city_id":"42578","city_name":"Swindon","state_id":"3913"},
    {"city_id":"42579","city_name":"Warminster","state_id":"3913"},
    {"city_id":"42580","city_name":"Westbury","state_id":"3913"},
    {"city_id":"42581","city_name":"Winnersh","state_id":"3914"},
    {"city_id":"42582","city_name":"Evesham","state_id":"3915"},
    {"city_id":"42583","city_name":"Hartlebury","state_id":"3915"},
    {"city_id":"42584","city_name":"Kidderminster","state_id":"3915"},
    {"city_id":"42585","city_name":"Pershore","state_id":"3915"},
    {"city_id":"42586","city_name":"Redditch","state_id":"3915"},
    {"city_id":"42587","city_name":"Worcester","state_id":"3915"},
    {"city_id":"42588","city_name":"Caergwrle","state_id":"3916"},
    {"city_id":"42589","city_name":"Ruabon","state_id":"3916"},
    {"city_id":"42590","city_name":"Neuffen","state_id":"3917"},
    {"city_id":"42591","city_name":"Beverley","state_id":"3918"},
    {"city_id":"42592","city_name":"Malton","state_id":"3918"},
    {"city_id":"42593","city_name":"Mexborough","state_id":"3918"},
    {"city_id":"42594","city_name":"Alabaster","state_id":"3919"},
    {"city_id":"42595","city_name":"Albertville","state_id":"3919"},
    {"city_id":"42596","city_name":"Alexander City","state_id":"3919"},
    {"city_id":"42597","city_name":"Anniston","state_id":"3919"},
    {"city_id":"42598","city_name":"Arab","state_id":"3919"},
    {"city_id":"42599","city_name":"Ashville","state_id":"3919"},
    {"city_id":"42600","city_name":"Athens","state_id":"3919"},
    {"city_id":"42601","city_name":"Atmore","state_id":"3919"},
    {"city_id":"42602","city_name":"Auburn","state_id":"3919"},
    {"city_id":"42603","city_name":"Bessemer","state_id":"3919"},
    {"city_id":"42604","city_name":"Birmingham","state_id":"3919"},
    {"city_id":"42605","city_name":"Capshaw","state_id":"3919"},
    {"city_id":"42606","city_name":"Center Point","state_id":"3919"},
    {"city_id":"42607","city_name":"Childersburg","state_id":"3919"},
    {"city_id":"42608","city_name":"Cullman","state_id":"3919"},
    {"city_id":"42609","city_name":"Daleville","state_id":"3919"},
    {"city_id":"42610","city_name":"Daphne","state_id":"3919"},
    {"city_id":"42611","city_name":"Decatur","state_id":"3919"},
    {"city_id":"42612","city_name":"Dothan","state_id":"3919"},
    {"city_id":"42613","city_name":"Enterprise","state_id":"3919"},
    {"city_id":"42614","city_name":"Eufaula","state_id":"3919"},
    {"city_id":"42615","city_name":"Fairfield","state_id":"3919"},
    {"city_id":"42616","city_name":"Fairhope","state_id":"3919"},
    {"city_id":"42617","city_name":"Florence","state_id":"3919"},
    {"city_id":"42618","city_name":"Fort Payne","state_id":"3919"},
    {"city_id":"42619","city_name":"Gadsden","state_id":"3919"},
    {"city_id":"42620","city_name":"Grand Bay","state_id":"3919"},
    {"city_id":"42621","city_name":"Grove Hill","state_id":"3919"},
    {"city_id":"42622","city_name":"Guntersville","state_id":"3919"},
    {"city_id":"42623","city_name":"Hampton Cove","state_id":"3919"},
    {"city_id":"42624","city_name":"Hanceville","state_id":"3919"},
    {"city_id":"42625","city_name":"Hartselle","state_id":"3919"},
    {"city_id":"42626","city_name":"Headland","state_id":"3919"},
    {"city_id":"42627","city_name":"Helena","state_id":"3919"},
    {"city_id":"42628","city_name":"Hodges","state_id":"3919"},
    {"city_id":"42629","city_name":"Homewood","state_id":"3919"},
    {"city_id":"42630","city_name":"Hoover","state_id":"3919"},
    {"city_id":"42631","city_name":"Hueytown","state_id":"3919"},
    {"city_id":"42632","city_name":"Huntsville","state_id":"3919"},
    {"city_id":"42633","city_name":"Jacksonville","state_id":"3919"},
    {"city_id":"42634","city_name":"Jasper","state_id":"3919"},
    {"city_id":"42635","city_name":"Leeds","state_id":"3919"},
    {"city_id":"42636","city_name":"Luverne","state_id":"3919"},
    {"city_id":"42637","city_name":"Madison","state_id":"3919"},
    {"city_id":"42638","city_name":"Mobile","state_id":"3919"},
    {"city_id":"42639","city_name":"Montgomery","state_id":"3919"},
    {"city_id":"42640","city_name":"Mountain Brook","state_id":"3919"},
    {"city_id":"42641","city_name":"Muscle Shoals","state_id":"3919"},
    {"city_id":"42642","city_name":"Northport","state_id":"3919"},
    {"city_id":"42643","city_name":"Notasulga","state_id":"3919"},
    {"city_id":"42644","city_name":"Opelika","state_id":"3919"},
    {"city_id":"42645","city_name":"Oxford","state_id":"3919"},
    {"city_id":"42646","city_name":"Ozark","state_id":"3919"},
    {"city_id":"42647","city_name":"Pelham","state_id":"3919"},
    {"city_id":"42648","city_name":"Pell City","state_id":"3919"},
    {"city_id":"42649","city_name":"Pennsylvania","state_id":"3919"},
    {"city_id":"42650","city_name":"Phenix City","state_id":"3919"},
    {"city_id":"42651","city_name":"Prattville","state_id":"3919"},
    {"city_id":"42652","city_name":"Prichard","state_id":"3919"},
    {"city_id":"42653","city_name":"Ramer","state_id":"3919"},
    {"city_id":"42654","city_name":"Roanoke","state_id":"3919"},
    {"city_id":"42655","city_name":"Saraland","state_id":"3919"},
    {"city_id":"42656","city_name":"Scottsboro","state_id":"3919"},
    {"city_id":"42657","city_name":"Selma","state_id":"3919"},
    {"city_id":"42658","city_name":"Sheffield","state_id":"3919"},
    {"city_id":"42659","city_name":"Smiths","state_id":"3919"},
    {"city_id":"42660","city_name":"Sumiton","state_id":"3919"},
    {"city_id":"42661","city_name":"Sylacauga","state_id":"3919"},
    {"city_id":"42662","city_name":"Talladega","state_id":"3919"},
    {"city_id":"42663","city_name":"Thomasville","state_id":"3919"},
    {"city_id":"42664","city_name":"Trafford","state_id":"3919"},
    {"city_id":"42665","city_name":"Troy","state_id":"3919"},
    {"city_id":"42666","city_name":"Trussville","state_id":"3919"},
    {"city_id":"42667","city_name":"Tuscaloosa","state_id":"3919"},
    {"city_id":"42668","city_name":"Tuskegee","state_id":"3919"},
    {"city_id":"42669","city_name":"Vestavia Hills","state_id":"3919"},
    {"city_id":"42670","city_name":"Anchorage","state_id":"3920"},
    {"city_id":"42671","city_name":"Barrow","state_id":"3920"},
    {"city_id":"42672","city_name":"Bethel","state_id":"3920"},
    {"city_id":"42673","city_name":"College","state_id":"3920"},
    {"city_id":"42674","city_name":"Fairbanks","state_id":"3920"},
    {"city_id":"42675","city_name":"Homer","state_id":"3920"},
    {"city_id":"42676","city_name":"Juneau","state_id":"3920"},
    {"city_id":"42677","city_name":"Kenai","state_id":"3920"},
    {"city_id":"42678","city_name":"Ketchikan","state_id":"3920"},
    {"city_id":"42679","city_name":"Kodiak","state_id":"3920"},
    {"city_id":"42680","city_name":"Nome","state_id":"3920"},
    {"city_id":"42681","city_name":"Palmer","state_id":"3920"},
    {"city_id":"42682","city_name":"Sitka","state_id":"3920"},
    {"city_id":"42683","city_name":"Soldotna","state_id":"3920"},
    {"city_id":"42684","city_name":"Sterling","state_id":"3920"},
    {"city_id":"42685","city_name":"Unalaska","state_id":"3920"},
    {"city_id":"42686","city_name":"Valdez","state_id":"3920"},
    {"city_id":"42687","city_name":"Wasilla","state_id":"3920"},
    {"city_id":"42688","city_name":"Apache Junction","state_id":"3921"},
    {"city_id":"42689","city_name":"Avondale","state_id":"3921"},
    {"city_id":"42690","city_name":"Bisbee","state_id":"3921"},
    {"city_id":"42691","city_name":"Bouse","state_id":"3921"},
    {"city_id":"42692","city_name":"Bullhead City","state_id":"3921"},
    {"city_id":"42693","city_name":"Carefree","state_id":"3921"},
    {"city_id":"42694","city_name":"Casa Grande","state_id":"3921"},
    {"city_id":"42695","city_name":"Casas Adobes","state_id":"3921"},
    {"city_id":"42696","city_name":"Chandler","state_id":"3921"},
    {"city_id":"42697","city_name":"Clarkdale","state_id":"3921"},
    {"city_id":"42698","city_name":"Cottonwood","state_id":"3921"},
    {"city_id":"42699","city_name":"Douglas","state_id":"3921"},
    {"city_id":"42700","city_name":"Drexel Heights","state_id":"3921"},
    {"city_id":"42701","city_name":"El Mirage","state_id":"3921"},
    {"city_id":"42702","city_name":"Flagstaff","state_id":"3921"},
    {"city_id":"42703","city_name":"Florence","state_id":"3921"},
    {"city_id":"42704","city_name":"Flowing Wells","state_id":"3921"},
    {"city_id":"42705","city_name":"Fort Mohave","state_id":"3921"},
    {"city_id":"42706","city_name":"Fortuna Foothills","state_id":"3921"},
    {"city_id":"42707","city_name":"Fountain Hills","state_id":"3921"},
    {"city_id":"42708","city_name":"Gilbert","state_id":"3921"},
    {"city_id":"42709","city_name":"Glendale","state_id":"3921"},
    {"city_id":"42710","city_name":"Globe","state_id":"3921"},
    {"city_id":"42711","city_name":"Goodyear","state_id":"3921"},
    {"city_id":"42712","city_name":"Green Valley","state_id":"3921"},
    {"city_id":"42713","city_name":"Kingman","state_id":"3921"},
    {"city_id":"42714","city_name":"Lake Havasu City","state_id":"3921"},
    {"city_id":"42715","city_name":"Laveen","state_id":"3921"},
    {"city_id":"42716","city_name":"Litchfield Park","state_id":"3921"},
    {"city_id":"42717","city_name":"Marana","state_id":"3921"},
    {"city_id":"42718","city_name":"Mesa","state_id":"3921"},
    {"city_id":"42719","city_name":"New Kingman-Butler","state_id":"3921"},
    {"city_id":"42720","city_name":"Nogales","state_id":"3921"},
    {"city_id":"42721","city_name":"Oracle","state_id":"3921"},
    {"city_id":"42722","city_name":"Oro Valley","state_id":"3921"},
    {"city_id":"42723","city_name":"Paradise Valley","state_id":"3921"},
    {"city_id":"42724","city_name":"Parker","state_id":"3921"},
    {"city_id":"42725","city_name":"Payson","state_id":"3921"},
    {"city_id":"42726","city_name":"Peoria","state_id":"3921"},
    {"city_id":"42727","city_name":"Phoenix","state_id":"3921"},
    {"city_id":"42728","city_name":"Pine","state_id":"3921"},
    {"city_id":"42729","city_name":"Pinetop","state_id":"3921"},
    {"city_id":"42730","city_name":"Prescott","state_id":"3921"},
    {"city_id":"42731","city_name":"Prescott Valley","state_id":"3921"},
    {"city_id":"42732","city_name":"Quartzsite","state_id":"3921"},
    {"city_id":"42733","city_name":"Queen Creek","state_id":"3921"},
    {"city_id":"42734","city_name":"Rio Rico","state_id":"3921"},
    {"city_id":"42735","city_name":"Safford","state_id":"3921"},
    {"city_id":"42736","city_name":"San Luis","state_id":"3921"},
    {"city_id":"42737","city_name":"Scottsdale","state_id":"3921"},
    {"city_id":"42738","city_name":"Sedona","state_id":"3921"},
    {"city_id":"42739","city_name":"Sierra Vista","state_id":"3921"},
    {"city_id":"42740","city_name":"Sierra Vista Southeast","state_id":"3921"},
    {"city_id":"42741","city_name":"Sun City","state_id":"3921"},
    {"city_id":"42742","city_name":"Sun City West","state_id":"3921"},
    {"city_id":"42743","city_name":"Surprise","state_id":"3921"},
    {"city_id":"42744","city_name":"Tempe","state_id":"3921"},
    {"city_id":"42745","city_name":"Tombstone","state_id":"3921"},
    {"city_id":"42746","city_name":"Tucson","state_id":"3921"},
    {"city_id":"42747","city_name":"Winslow","state_id":"3921"},
    {"city_id":"42748","city_name":"Yuma","state_id":"3921"},
    {"city_id":"42749","city_name":"Alexander","state_id":"3922"},
    {"city_id":"42750","city_name":"Arkadelphia","state_id":"3922"},
    {"city_id":"42751","city_name":"Batesville","state_id":"3922"},
    {"city_id":"42752","city_name":"Bella Vista","state_id":"3922"},
    {"city_id":"42753","city_name":"Benton","state_id":"3922"},
    {"city_id":"42754","city_name":"Bentonville","state_id":"3922"},
    {"city_id":"42755","city_name":"Berryville","state_id":"3922"},
    {"city_id":"42756","city_name":"Blytheville","state_id":"3922"},
    {"city_id":"42757","city_name":"Cabot","state_id":"3922"},
    {"city_id":"42758","city_name":"Camden","state_id":"3922"},
    {"city_id":"42759","city_name":"Cherry Valley","state_id":"3922"},
    {"city_id":"42760","city_name":"Conway","state_id":"3922"},
    {"city_id":"42761","city_name":"Corning","state_id":"3922"},
    {"city_id":"42762","city_name":"El Dorado","state_id":"3922"},
    {"city_id":"42763","city_name":"Fayetteville","state_id":"3922"},
    {"city_id":"42764","city_name":"Forrest City","state_id":"3922"},
    {"city_id":"42765","city_name":"Fort Smith","state_id":"3922"},
    {"city_id":"42766","city_name":"Harrison","state_id":"3922"},
    {"city_id":"42767","city_name":"Hope","state_id":"3922"},
    {"city_id":"42768","city_name":"Hot Springs","state_id":"3922"},
    {"city_id":"42769","city_name":"Jacksonville","state_id":"3922"},
    {"city_id":"42770","city_name":"Jonesboro","state_id":"3922"},
    {"city_id":"42771","city_name":"Lake City","state_id":"3922"},
    {"city_id":"42772","city_name":"Little Rock","state_id":"3922"},
    {"city_id":"42773","city_name":"Magnolia","state_id":"3922"},
    {"city_id":"42774","city_name":"Mount Vernon","state_id":"3922"},
    {"city_id":"42775","city_name":"Mountain Home","state_id":"3922"},
    {"city_id":"42776","city_name":"Norfork","state_id":"3922"},
    {"city_id":"42777","city_name":"North Little Rock","state_id":"3922"},
    {"city_id":"42778","city_name":"Paragould","state_id":"3922"},
    {"city_id":"42779","city_name":"Piggott","state_id":"3922"},
    {"city_id":"42780","city_name":"Pine Bluff","state_id":"3922"},
    {"city_id":"42781","city_name":"Pocahontas","state_id":"3922"},
    {"city_id":"42782","city_name":"Prescott","state_id":"3922"},
    {"city_id":"42783","city_name":"Quitman","state_id":"3922"},
    {"city_id":"42784","city_name":"Rogers","state_id":"3922"},
    {"city_id":"42785","city_name":"Russellville","state_id":"3922"},
    {"city_id":"42786","city_name":"Searcy","state_id":"3922"},
    {"city_id":"42787","city_name":"Sheridan","state_id":"3922"},
    {"city_id":"42788","city_name":"Sherwood","state_id":"3922"},
    {"city_id":"42789","city_name":"Siloam Springs","state_id":"3922"},
    {"city_id":"42790","city_name":"Springdale","state_id":"3922"},
    {"city_id":"42791","city_name":"Stuttgart","state_id":"3922"},
    {"city_id":"42792","city_name":"Texarkana","state_id":"3922"},
    {"city_id":"42793","city_name":"Van Buren","state_id":"3922"},
    {"city_id":"42794","city_name":"Ward","state_id":"3922"},
    {"city_id":"42795","city_name":"West Helena","state_id":"3922"},
    {"city_id":"42796","city_name":"West Memphis","state_id":"3922"},
    {"city_id":"42797","city_name":"Wynne","state_id":"3922"},
    {"city_id":"42798","city_name":"Acton","state_id":"3924"},
    {"city_id":"42799","city_name":"Adelanto","state_id":"3924"},
    {"city_id":"42800","city_name":"Agoura Hills","state_id":"3924"},
    {"city_id":"42801","city_name":"Aguanga","state_id":"3924"},
    {"city_id":"42802","city_name":"Alameda","state_id":"3924"},
    {"city_id":"42803","city_name":"Alamo","state_id":"3924"},
    {"city_id":"42804","city_name":"Albany","state_id":"3924"},
    {"city_id":"42805","city_name":"Alhambra","state_id":"3924"},
    {"city_id":"42806","city_name":"Aliso Viejo","state_id":"3924"},
    {"city_id":"42807","city_name":"Alondra Park","state_id":"3924"},
    {"city_id":"42808","city_name":"Alpine","state_id":"3924"},
    {"city_id":"42809","city_name":"Alta Loma","state_id":"3924"},
    {"city_id":"42810","city_name":"Altadena","state_id":"3924"},
    {"city_id":"42811","city_name":"American Canyon","state_id":"3924"},
    {"city_id":"42812","city_name":"Anaheim","state_id":"3924"},
    {"city_id":"42813","city_name":"Anderson","state_id":"3924"},
    {"city_id":"42814","city_name":"Antelope","state_id":"3924"},
    {"city_id":"42815","city_name":"Antioch","state_id":"3924"},
    {"city_id":"42816","city_name":"Apple Valley","state_id":"3924"},
    {"city_id":"42817","city_name":"Aptos","state_id":"3924"},
    {"city_id":"42818","city_name":"Arcadia","state_id":"3924"},
    {"city_id":"42819","city_name":"Arcata","state_id":"3924"},
    {"city_id":"42820","city_name":"Arden-Arcade","state_id":"3924"},
    {"city_id":"42821","city_name":"Arroyo Grande","state_id":"3924"},
    {"city_id":"42822","city_name":"Artesia","state_id":"3924"},
    {"city_id":"42823","city_name":"Arvin","state_id":"3924"},
    {"city_id":"42824","city_name":"Ashland","state_id":"3924"},
    {"city_id":"42825","city_name":"Atascadero","state_id":"3924"},
    {"city_id":"42826","city_name":"Atwater","state_id":"3924"},
    {"city_id":"42827","city_name":"Auburn","state_id":"3924"},
    {"city_id":"42828","city_name":"Avalon","state_id":"3924"},
    {"city_id":"42829","city_name":"Avenal","state_id":"3924"},
    {"city_id":"42830","city_name":"Avocado Heights","state_id":"3924"},
    {"city_id":"42831","city_name":"Azusa","state_id":"3924"},
    {"city_id":"42832","city_name":"Bakersfield","state_id":"3924"},
    {"city_id":"42833","city_name":"Baldwin Park","state_id":"3924"},
    {"city_id":"42834","city_name":"Banning","state_id":"3924"},
    {"city_id":"42835","city_name":"Barstow","state_id":"3924"},
    {"city_id":"42836","city_name":"Bay Point","state_id":"3924"},
    {"city_id":"42837","city_name":"Baywood-Los Osos","state_id":"3924"},
    {"city_id":"42838","city_name":"Bear Valley Springs","state_id":"3924"},
    {"city_id":"42839","city_name":"Beaumont","state_id":"3924"},
    {"city_id":"42840","city_name":"Bell","state_id":"3924"},
    {"city_id":"42841","city_name":"Bell Gardens","state_id":"3924"},
    {"city_id":"42842","city_name":"Bellflower","state_id":"3924"},
    {"city_id":"42843","city_name":"Belmont","state_id":"3924"},
    {"city_id":"42844","city_name":"Ben Lomond","state_id":"3924"},
    {"city_id":"42845","city_name":"Benicia","state_id":"3924"},
    {"city_id":"42846","city_name":"Berkeley","state_id":"3924"},
    {"city_id":"42847","city_name":"Beverly Hills","state_id":"3924"},
    {"city_id":"42848","city_name":"Big Bear Lake","state_id":"3924"},
    {"city_id":"42849","city_name":"Bloomington","state_id":"3924"},
    {"city_id":"42850","city_name":"Blythe","state_id":"3924"},
    {"city_id":"42851","city_name":"Bonita","state_id":"3924"},
    {"city_id":"42852","city_name":"Bostonia","state_id":"3924"},
    {"city_id":"42853","city_name":"Brawley","state_id":"3924"},
    {"city_id":"42854","city_name":"Brea","state_id":"3924"},
    {"city_id":"42855","city_name":"Brentwood","state_id":"3924"},
    {"city_id":"42856","city_name":"Brisbane","state_id":"3924"},
    {"city_id":"42857","city_name":"Brookdale","state_id":"3924"},
    {"city_id":"42858","city_name":"Buena Park","state_id":"3924"},
    {"city_id":"42859","city_name":"Burbank","state_id":"3924"},
    {"city_id":"42860","city_name":"Burlingame","state_id":"3924"},
    {"city_id":"42861","city_name":"Burnham","state_id":"3924"},
    {"city_id":"42862","city_name":"Byron","state_id":"3924"},
    {"city_id":"42863","city_name":"Calabasas","state_id":"3924"},
    {"city_id":"42864","city_name":"Calexico","state_id":"3924"},
    {"city_id":"42865","city_name":"California City","state_id":"3924"},
    {"city_id":"42866","city_name":"Camarillo","state_id":"3924"},
    {"city_id":"42867","city_name":"Cameron Park","state_id":"3924"},
    {"city_id":"42868","city_name":"Camino","state_id":"3924"},
    {"city_id":"42869","city_name":"Camp Pendleton North","state_id":"3924"},
    {"city_id":"42870","city_name":"Camp Pendleton South","state_id":"3924"},
    {"city_id":"42871","city_name":"Campbell","state_id":"3924"},
    {"city_id":"42872","city_name":"Canoga Park","state_id":"3924"},
    {"city_id":"42873","city_name":"Canyon Lake","state_id":"3924"},
    {"city_id":"42874","city_name":"Capitola","state_id":"3924"},
    {"city_id":"42875","city_name":"Carlsbad","state_id":"3924"},
    {"city_id":"42876","city_name":"Carmel","state_id":"3924"},
    {"city_id":"42877","city_name":"Carmel Valley","state_id":"3924"},
    {"city_id":"42878","city_name":"Carmichael","state_id":"3924"},
    {"city_id":"42879","city_name":"Carpinteria","state_id":"3924"},
    {"city_id":"42880","city_name":"Carson","state_id":"3924"},
    {"city_id":"42881","city_name":"Casa de Oro-Mount Helix","state_id":"3924"},
    {"city_id":"42882","city_name":"Castaic","state_id":"3924"},
    {"city_id":"42883","city_name":"Castro Valley","state_id":"3924"},
    {"city_id":"42884","city_name":"Cathedral City","state_id":"3924"},
    {"city_id":"42885","city_name":"Cayucos","state_id":"3924"},
    {"city_id":"42886","city_name":"Ceres","state_id":"3924"},
    {"city_id":"42887","city_name":"Cerritos","state_id":"3924"},
    {"city_id":"42888","city_name":"Charter Oak","state_id":"3924"},
    {"city_id":"42889","city_name":"Chatsworth","state_id":"3924"},
    {"city_id":"42890","city_name":"Cherryland","state_id":"3924"},
    {"city_id":"42891","city_name":"Chico","state_id":"3924"},
    {"city_id":"42892","city_name":"Chino","state_id":"3924"},
    {"city_id":"42893","city_name":"Chino Hills","state_id":"3924"},
    {"city_id":"42894","city_name":"Chula Vista","state_id":"3924"},
    {"city_id":"42895","city_name":"Citrus","state_id":"3924"},
    {"city_id":"42896","city_name":"Citrus Heights","state_id":"3924"},
    {"city_id":"42897","city_name":"City of Commerce","state_id":"3924"},
    {"city_id":"42898","city_name":"City of Industry","state_id":"3924"},
    {"city_id":"42899","city_name":"Claremont","state_id":"3924"},
    {"city_id":"42900","city_name":"Clearlake","state_id":"3924"},
    {"city_id":"42901","city_name":"Clovis","state_id":"3924"},
    {"city_id":"42902","city_name":"Coachella","state_id":"3924"},
    {"city_id":"42903","city_name":"Coalinga","state_id":"3924"},
    {"city_id":"42904","city_name":"Colfax","state_id":"3924"},
    {"city_id":"42905","city_name":"Colton","state_id":"3924"},
    {"city_id":"42906","city_name":"Colusa","state_id":"3924"},
    {"city_id":"42907","city_name":"Commerce","state_id":"3924"},
    {"city_id":"42908","city_name":"Compton","state_id":"3924"},
    {"city_id":"42909","city_name":"Concord","state_id":"3924"},
    {"city_id":"42910","city_name":"Corcoran","state_id":"3924"},
    {"city_id":"42911","city_name":"Corning","state_id":"3924"},
    {"city_id":"42912","city_name":"Corona","state_id":"3924"},
    {"city_id":"42913","city_name":"Coronado","state_id":"3924"},
    {"city_id":"42914","city_name":"Corte Madera","state_id":"3924"},
    {"city_id":"42915","city_name":"Costa Mesa","state_id":"3924"},
    {"city_id":"42916","city_name":"Cotati","state_id":"3924"},
    {"city_id":"42917","city_name":"Cottonwood","state_id":"3924"},
    {"city_id":"42918","city_name":"Country Club","state_id":"3924"},
    {"city_id":"42919","city_name":"Covina","state_id":"3924"},
    {"city_id":"42920","city_name":"Crestline","state_id":"3924"},
    {"city_id":"42921","city_name":"Cudahy","state_id":"3924"},
    {"city_id":"42922","city_name":"Culver City","state_id":"3924"},
    {"city_id":"42923","city_name":"Cupertino","state_id":"3924"},
    {"city_id":"42924","city_name":"Cypress","state_id":"3924"},
    {"city_id":"42925","city_name":"Daly City","state_id":"3924"},
    {"city_id":"42926","city_name":"Dana Point","state_id":"3924"},
    {"city_id":"42927","city_name":"Danville","state_id":"3924"},
    {"city_id":"42928","city_name":"Davis","state_id":"3924"},
    {"city_id":"42929","city_name":"Del Mar","state_id":"3924"},
    {"city_id":"42930","city_name":"Delano","state_id":"3924"},
    {"city_id":"42931","city_name":"Desert Hot Springs","state_id":"3924"},
    {"city_id":"42932","city_name":"Diamond Bar","state_id":"3924"},
    {"city_id":"42933","city_name":"Dinuba","state_id":"3924"},
    {"city_id":"42934","city_name":"Dixon","state_id":"3924"},
    {"city_id":"42935","city_name":"Downey","state_id":"3924"},
    {"city_id":"42936","city_name":"Duarte","state_id":"3924"},
    {"city_id":"42937","city_name":"Dublin","state_id":"3924"},
    {"city_id":"42938","city_name":"East Foothills","state_id":"3924"},
    {"city_id":"42939","city_name":"East Hemet","state_id":"3924"},
    {"city_id":"42940","city_name":"East La Mirada","state_id":"3924"},
    {"city_id":"42941","city_name":"East Palo Alto","state_id":"3924"},
    {"city_id":"42942","city_name":"East San Gabriel","state_id":"3924"},
    {"city_id":"42943","city_name":"El Cajon","state_id":"3924"},
    {"city_id":"42944","city_name":"El Centro","state_id":"3924"},
    {"city_id":"42945","city_name":"El Cerrito","state_id":"3924"},
    {"city_id":"42946","city_name":"El Granada","state_id":"3924"},
    {"city_id":"42947","city_name":"El Monte","state_id":"3924"},
    {"city_id":"42948","city_name":"El Paso de Robles","state_id":"3924"},
    {"city_id":"42949","city_name":"El Segundo","state_id":"3924"},
    {"city_id":"42950","city_name":"El Sobrante","state_id":"3924"},
    {"city_id":"42951","city_name":"Elk Grove","state_id":"3924"},
    {"city_id":"42952","city_name":"Emeryville","state_id":"3924"},
    {"city_id":"42953","city_name":"Encinitas","state_id":"3924"},
    {"city_id":"42954","city_name":"Encino","state_id":"3924"},
    {"city_id":"42955","city_name":"Escondido","state_id":"3924"},
    {"city_id":"42956","city_name":"Etna","state_id":"3924"},
    {"city_id":"42957","city_name":"Eureka","state_id":"3924"},
    {"city_id":"42958","city_name":"Exeter","state_id":"3924"},
    {"city_id":"42959","city_name":"Fair Oaks","state_id":"3924"},
    {"city_id":"42960","city_name":"Fairfax","state_id":"3924"},
    {"city_id":"42961","city_name":"Fairfield","state_id":"3924"},
    {"city_id":"42962","city_name":"Fairview","state_id":"3924"},
    {"city_id":"42963","city_name":"Fallbrook","state_id":"3924"},
    {"city_id":"42964","city_name":"Ferndale","state_id":"3924"},
    {"city_id":"42965","city_name":"Fillmore","state_id":"3924"},
    {"city_id":"42966","city_name":"Florence-Graham","state_id":"3924"},
    {"city_id":"42967","city_name":"Florin","state_id":"3924"},
    {"city_id":"42968","city_name":"Folsom","state_id":"3924"},
    {"city_id":"42969","city_name":"Fontana","state_id":"3924"},
    {"city_id":"42970","city_name":"Foothill Farms","state_id":"3924"},
    {"city_id":"42971","city_name":"Foothill Ranch","state_id":"3924"},
    {"city_id":"42972","city_name":"Forestville","state_id":"3924"},
    {"city_id":"42973","city_name":"Fort Bragg","state_id":"3924"},
    {"city_id":"42974","city_name":"Fortuna","state_id":"3924"},
    {"city_id":"42975","city_name":"Foster City","state_id":"3924"},
    {"city_id":"42976","city_name":"Fountain Valley","state_id":"3924"},
    {"city_id":"42977","city_name":"Freedom","state_id":"3924"},
    {"city_id":"42978","city_name":"Fremont","state_id":"3924"},
    {"city_id":"42979","city_name":"Fresno","state_id":"3924"},
    {"city_id":"42980","city_name":"Fullerton","state_id":"3924"},
    {"city_id":"42981","city_name":"Galt","state_id":"3924"},
    {"city_id":"42982","city_name":"Garberville","state_id":"3924"},
    {"city_id":"42983","city_name":"Garden Acres","state_id":"3924"},
    {"city_id":"42984","city_name":"Garden Grove","state_id":"3924"},
    {"city_id":"42985","city_name":"Gardena","state_id":"3924"},
    {"city_id":"42986","city_name":"Georgetown","state_id":"3924"},
    {"city_id":"42987","city_name":"Gilroy","state_id":"3924"},
    {"city_id":"42988","city_name":"Glen Avon","state_id":"3924"},
    {"city_id":"42989","city_name":"Glendale","state_id":"3924"},
    {"city_id":"42990","city_name":"Glendora","state_id":"3924"},
    {"city_id":"42991","city_name":"Goleta","state_id":"3924"},
    {"city_id":"42992","city_name":"Gonzales","state_id":"3924"},
    {"city_id":"42993","city_name":"Granada Hills","state_id":"3924"},
    {"city_id":"42994","city_name":"Grand Terrace","state_id":"3924"},
    {"city_id":"42995","city_name":"Grass Valley","state_id":"3924"},
    {"city_id":"42996","city_name":"Greenfield","state_id":"3924"},
    {"city_id":"42997","city_name":"Grover Beach","state_id":"3924"},
    {"city_id":"42998","city_name":"Gualala","state_id":"3924"},
    {"city_id":"42999","city_name":"Guerneville","state_id":"3924"},
    {"city_id":"43000","city_name":"Hacienda Heights","state_id":"3924"},
    {"city_id":"43001","city_name":"Half Moon Bay","state_id":"3924"},
    {"city_id":"43002","city_name":"Hanford","state_id":"3924"},
    {"city_id":"43003","city_name":"Harbor City","state_id":"3924"},
    {"city_id":"43004","city_name":"Hawaiian Gardens","state_id":"3924"},
    {"city_id":"43005","city_name":"Hawthorne","state_id":"3924"},
    {"city_id":"43006","city_name":"Hayward","state_id":"3924"},
    {"city_id":"43007","city_name":"Hemet","state_id":"3924"},
    {"city_id":"43008","city_name":"Hercules","state_id":"3924"},
    {"city_id":"43009","city_name":"Hermosa Beach","state_id":"3924"},
    {"city_id":"43010","city_name":"Hesperia","state_id":"3924"},
    {"city_id":"43011","city_name":"Highland","state_id":"3924"},
    {"city_id":"43012","city_name":"Hillsborough","state_id":"3924"},
    {"city_id":"43013","city_name":"Hollister","state_id":"3924"},
    {"city_id":"43014","city_name":"Hollywood","state_id":"3924"},
    {"city_id":"43015","city_name":"Huntington Beach","state_id":"3924"},
    {"city_id":"43016","city_name":"Huntington Park","state_id":"3924"},
    {"city_id":"43017","city_name":"Idyllwild","state_id":"3924"},
    {"city_id":"43018","city_name":"Imperial Beach","state_id":"3924"},
    {"city_id":"43019","city_name":"Indio","state_id":"3924"},
    {"city_id":"43020","city_name":"Industry","state_id":"3924"},
    {"city_id":"43021","city_name":"Inglewood","state_id":"3924"},
    {"city_id":"43022","city_name":"Irvine","state_id":"3924"},
    {"city_id":"43023","city_name":"Irwindale","state_id":"3924"},
    {"city_id":"43024","city_name":"Isla Vista","state_id":"3924"},
    {"city_id":"43025","city_name":"Jackson","state_id":"3924"},
    {"city_id":"43026","city_name":"Jamul","state_id":"3924"},
    {"city_id":"43027","city_name":"La Canada Flintridge","state_id":"3924"},
    {"city_id":"43028","city_name":"La Crescenta-Montrose","state_id":"3924"},
    {"city_id":"43029","city_name":"La Habra","state_id":"3924"},
    {"city_id":"43030","city_name":"La Jolla","state_id":"3924"},
    {"city_id":"43031","city_name":"La Mesa","state_id":"3924"},
    {"city_id":"43032","city_name":"La Mirada","state_id":"3924"},
    {"city_id":"43033","city_name":"La Palma","state_id":"3924"},
    {"city_id":"43034","city_name":"La Presa","state_id":"3924"},
    {"city_id":"43035","city_name":"La Puente","state_id":"3924"},
    {"city_id":"43036","city_name":"La Quinta","state_id":"3924"},
    {"city_id":"43037","city_name":"La Riviera","state_id":"3924"},
    {"city_id":"43038","city_name":"La Verne","state_id":"3924"},
    {"city_id":"43039","city_name":"LaVerne","state_id":"3924"},
    {"city_id":"43040","city_name":"Ladera Ranch","state_id":"3924"},
    {"city_id":"43041","city_name":"Lafayette","state_id":"3924"},
    {"city_id":"43042","city_name":"Laguna","state_id":"3924"},
    {"city_id":"43043","city_name":"Laguna Beach","state_id":"3924"},
    {"city_id":"43044","city_name":"Laguna Hills","state_id":"3924"},
    {"city_id":"43045","city_name":"Laguna Niguel","state_id":"3924"},
    {"city_id":"43046","city_name":"Lake Elsinore","state_id":"3924"},
    {"city_id":"43047","city_name":"Lake Forest","state_id":"3924"},
    {"city_id":"43048","city_name":"Lakeside","state_id":"3924"},
    {"city_id":"43049","city_name":"Lakewood","state_id":"3924"},
    {"city_id":"43050","city_name":"Lamont","state_id":"3924"},
    {"city_id":"43051","city_name":"Lancaster","state_id":"3924"},
    {"city_id":"43052","city_name":"Larkspur","state_id":"3924"},
    {"city_id":"43053","city_name":"Lawndale","state_id":"3924"},
    {"city_id":"43054","city_name":"Laytonville","state_id":"3924"},
    {"city_id":"43055","city_name":"Lemon Grove","state_id":"3924"},
    {"city_id":"43056","city_name":"Lemoore","state_id":"3924"},
    {"city_id":"43057","city_name":"Lennox","state_id":"3924"},
    {"city_id":"43058","city_name":"Linda","state_id":"3924"},
    {"city_id":"43059","city_name":"Lindsay","state_id":"3924"},
    {"city_id":"43060","city_name":"Live Oak","state_id":"3924"},
    {"city_id":"43061","city_name":"Livermore","state_id":"3924"},
    {"city_id":"43062","city_name":"Livingston","state_id":"3924"},
    {"city_id":"43063","city_name":"Lodi","state_id":"3924"},
    {"city_id":"43064","city_name":"Loma Linda","state_id":"3924"},
    {"city_id":"43065","city_name":"Lomita","state_id":"3924"},
    {"city_id":"43066","city_name":"Lompoc","state_id":"3924"},
    {"city_id":"43067","city_name":"Long Beach","state_id":"3924"},
    {"city_id":"43068","city_name":"Los Alamitos","state_id":"3924"},
    {"city_id":"43069","city_name":"Los Altos","state_id":"3924"},
    {"city_id":"43070","city_name":"Los Angeles","state_id":"3924"},
    {"city_id":"43071","city_name":"Los Angeles East","state_id":"3924"},
    {"city_id":"43072","city_name":"Los Banos","state_id":"3924"},
    {"city_id":"43073","city_name":"Los Gatos","state_id":"3924"},
    {"city_id":"43074","city_name":"Los Olivos","state_id":"3924"},
    {"city_id":"43075","city_name":"Lynwood","state_id":"3924"},
    {"city_id":"43076","city_name":"MacKinleyville","state_id":"3924"},
    {"city_id":"43077","city_name":"Madera","state_id":"3924"},
    {"city_id":"43078","city_name":"Magalia","state_id":"3924"},
    {"city_id":"43079","city_name":"Malibu","state_id":"3924"},
    {"city_id":"43080","city_name":"Mammoth Lakes","state_id":"3924"},
    {"city_id":"43081","city_name":"Manhattan Beach","state_id":"3924"},
    {"city_id":"43082","city_name":"Manteca","state_id":"3924"},
    {"city_id":"43083","city_name":"Marina","state_id":"3924"},
    {"city_id":"43084","city_name":"Marina del Rey","state_id":"3924"},
    {"city_id":"43085","city_name":"Mariposa","state_id":"3924"},
    {"city_id":"43086","city_name":"Marshall","state_id":"3924"},
    {"city_id":"43087","city_name":"Martinez","state_id":"3924"},
    {"city_id":"43088","city_name":"Marysville","state_id":"3924"},
    {"city_id":"43089","city_name":"Maywood","state_id":"3924"},
    {"city_id":"43090","city_name":"Menlo Park","state_id":"3924"},
    {"city_id":"43091","city_name":"Merced","state_id":"3924"},
    {"city_id":"43092","city_name":"Middletown","state_id":"3924"},
    {"city_id":"43093","city_name":"Midway City","state_id":"3924"},
    {"city_id":"43094","city_name":"Mill Valley","state_id":"3924"},
    {"city_id":"43095","city_name":"Millbrae","state_id":"3924"},
    {"city_id":"43096","city_name":"Milpitas","state_id":"3924"},
    {"city_id":"43097","city_name":"Mira Loma","state_id":"3924"},
    {"city_id":"43098","city_name":"Miranda","state_id":"3924"},
    {"city_id":"43099","city_name":"Mission Viejo","state_id":"3924"},
    {"city_id":"43100","city_name":"Modesto","state_id":"3924"},
    {"city_id":"43101","city_name":"Monclair","state_id":"3924"},
    {"city_id":"43102","city_name":"Monrovia","state_id":"3924"},
    {"city_id":"43103","city_name":"Montara","state_id":"3924"},
    {"city_id":"43104","city_name":"Montclair","state_id":"3924"},
    {"city_id":"43105","city_name":"Montebello","state_id":"3924"},
    {"city_id":"43106","city_name":"Montecito","state_id":"3924"},
    {"city_id":"43107","city_name":"Monterey","state_id":"3924"},
    {"city_id":"43108","city_name":"Monterey Park","state_id":"3924"},
    {"city_id":"43109","city_name":"Moorpark","state_id":"3924"},
    {"city_id":"43110","city_name":"Moraga Town","state_id":"3924"},
    {"city_id":"43111","city_name":"Moreno Valley","state_id":"3924"},
    {"city_id":"43112","city_name":"Morgan Hill","state_id":"3924"},
    {"city_id":"43113","city_name":"Morro Bay","state_id":"3924"},
    {"city_id":"43114","city_name":"Moss Beach","state_id":"3924"},
    {"city_id":"43115","city_name":"Mount Shasta","state_id":"3924"},
    {"city_id":"43116","city_name":"Mountain View","state_id":"3924"},
    {"city_id":"43117","city_name":"Murrieta","state_id":"3924"},
    {"city_id":"43118","city_name":"N. Hollywood","state_id":"3924"},
    {"city_id":"43119","city_name":"Napa","state_id":"3924"},
    {"city_id":"43120","city_name":"National City","state_id":"3924"},
    {"city_id":"43121","city_name":"Nevada City","state_id":"3924"},
    {"city_id":"43122","city_name":"Newark","state_id":"3924"},
    {"city_id":"43123","city_name":"Newport Beach","state_id":"3924"},
    {"city_id":"43124","city_name":"Norco","state_id":"3924"},
    {"city_id":"43125","city_name":"North Auburn","state_id":"3924"},
    {"city_id":"43126","city_name":"North Fair Oaks","state_id":"3924"},
    {"city_id":"43127","city_name":"North Fork","state_id":"3924"},
    {"city_id":"43128","city_name":"North Highlands","state_id":"3924"},
    {"city_id":"43129","city_name":"North Hills","state_id":"3924"},
    {"city_id":"43130","city_name":"North Hollywood","state_id":"3924"},
    {"city_id":"43131","city_name":"Northridge","state_id":"3924"},
    {"city_id":"43132","city_name":"Norwalk","state_id":"3924"},
    {"city_id":"43133","city_name":"Novato","state_id":"3924"},
    {"city_id":"43134","city_name":"Nuevo","state_id":"3924"},
    {"city_id":"43135","city_name":"Oak View","state_id":"3924"},
    {"city_id":"43136","city_name":"Oakdale","state_id":"3924"},
    {"city_id":"43137","city_name":"Oakhurst","state_id":"3924"},
    {"city_id":"43138","city_name":"Oakland","state_id":"3924"},
    {"city_id":"43139","city_name":"Oakley","state_id":"3924"},
    {"city_id":"43140","city_name":"Oceanside","state_id":"3924"},
    {"city_id":"43141","city_name":"Oildale","state_id":"3924"},
    {"city_id":"43142","city_name":"Ojai","state_id":"3924"},
    {"city_id":"43143","city_name":"Olivehurst","state_id":"3924"},
    {"city_id":"43144","city_name":"Ontario","state_id":"3924"},
    {"city_id":"43145","city_name":"Orange","state_id":"3924"},
    {"city_id":"43146","city_name":"Orangevale","state_id":"3924"},
    {"city_id":"43147","city_name":"Orcutt","state_id":"3924"},
    {"city_id":"43148","city_name":"Oregon House","state_id":"3924"},
    {"city_id":"43149","city_name":"Orinda","state_id":"3924"},
    {"city_id":"43150","city_name":"Oroville","state_id":"3924"},
    {"city_id":"43151","city_name":"Oxnard","state_id":"3924"},
    {"city_id":"43152","city_name":"Pacific Grove","state_id":"3924"},
    {"city_id":"43153","city_name":"Pacific Palisades","state_id":"3924"},
    {"city_id":"43154","city_name":"Pacifica","state_id":"3924"},
    {"city_id":"43155","city_name":"Pacoima","state_id":"3924"},
    {"city_id":"43156","city_name":"Pajaro","state_id":"3924"},
    {"city_id":"43157","city_name":"Palm Desert","state_id":"3924"},
    {"city_id":"43158","city_name":"Palm Springs","state_id":"3924"},
    {"city_id":"43159","city_name":"Palmdale","state_id":"3924"},
    {"city_id":"43160","city_name":"Palo Alto","state_id":"3924"},
    {"city_id":"43161","city_name":"Palos Verdes Estates","state_id":"3924"},
    {"city_id":"43162","city_name":"Pamona","state_id":"3924"},
    {"city_id":"43163","city_name":"Panorama City","state_id":"3924"},
    {"city_id":"43164","city_name":"Paradise","state_id":"3924"},
    {"city_id":"43165","city_name":"Paramount","state_id":"3924"},
    {"city_id":"43166","city_name":"Parkway-South Sacramento","state_id":"3924"},
    {"city_id":"43167","city_name":"Parlier","state_id":"3924"},
    {"city_id":"43168","city_name":"Pasadena","state_id":"3924"},
    {"city_id":"43169","city_name":"Patterson","state_id":"3924"},
    {"city_id":"43170","city_name":"Pedley","state_id":"3924"},
    {"city_id":"43171","city_name":"Perris","state_id":"3924"},
    {"city_id":"43172","city_name":"Petaluma","state_id":"3924"},
    {"city_id":"43173","city_name":"Pico Rivera","state_id":"3924"},
    {"city_id":"43174","city_name":"Piedmont","state_id":"3924"},
    {"city_id":"43175","city_name":"Pinole","state_id":"3924"},
    {"city_id":"43176","city_name":"Pismo Beach","state_id":"3924"},
    {"city_id":"43177","city_name":"Pittsburg","state_id":"3924"},
    {"city_id":"43178","city_name":"Placentia","state_id":"3924"},
    {"city_id":"43179","city_name":"Placerville","state_id":"3924"},
    {"city_id":"43180","city_name":"Playa del Rey","state_id":"3924"},
    {"city_id":"43181","city_name":"Pleasant Hill","state_id":"3924"},
    {"city_id":"43182","city_name":"Pleasanton","state_id":"3924"},
    {"city_id":"43183","city_name":"Plymouth","state_id":"3924"},
    {"city_id":"43184","city_name":"Point Reyes Station","state_id":"3924"},
    {"city_id":"43185","city_name":"Pollock Pines","state_id":"3924"},
    {"city_id":"43186","city_name":"Pomona","state_id":"3924"},
    {"city_id":"43187","city_name":"Port Costa","state_id":"3924"},
    {"city_id":"43188","city_name":"Port Hueneme","state_id":"3924"},
    {"city_id":"43189","city_name":"Porterville","state_id":"3924"},
    {"city_id":"43190","city_name":"Poway","state_id":"3924"},
    {"city_id":"43191","city_name":"Quartz Hill","state_id":"3924"},
    {"city_id":"43192","city_name":"Ramona","state_id":"3924"},
    {"city_id":"43193","city_name":"Rancho Cordova","state_id":"3924"},
    {"city_id":"43194","city_name":"Rancho Cucamonga","state_id":"3924"},
    {"city_id":"43195","city_name":"Rancho Dominguez","state_id":"3924"},
    {"city_id":"43196","city_name":"Rancho Mirage","state_id":"3924"},
    {"city_id":"43197","city_name":"Rancho Murieta","state_id":"3924"},
    {"city_id":"43198","city_name":"Rancho Palos Verdes","state_id":"3924"},
    {"city_id":"43199","city_name":"Rancho San Diego","state_id":"3924"},
    {"city_id":"43200","city_name":"Rancho Santa Margarita","state_id":"3924"},
    {"city_id":"43201","city_name":"Red Bluff","state_id":"3924"},
    {"city_id":"43202","city_name":"Redding","state_id":"3924"},
    {"city_id":"43203","city_name":"Redlands","state_id":"3924"},
    {"city_id":"43204","city_name":"Redondo Beach","state_id":"3924"},
    {"city_id":"43205","city_name":"Redway","state_id":"3924"},
    {"city_id":"43206","city_name":"Redwood City","state_id":"3924"},
    {"city_id":"43207","city_name":"Reedley","state_id":"3924"},
    {"city_id":"43208","city_name":"Reseda","state_id":"3924"},
    {"city_id":"43209","city_name":"Rialto","state_id":"3924"},
    {"city_id":"43210","city_name":"Richmond","state_id":"3924"},
    {"city_id":"43211","city_name":"Ridgecrest","state_id":"3924"},
    {"city_id":"43212","city_name":"Rio Linda","state_id":"3924"},
    {"city_id":"43213","city_name":"Rio Nido","state_id":"3924"},
    {"city_id":"43214","city_name":"Rio del Mar","state_id":"3924"},
    {"city_id":"43215","city_name":"Riverbank","state_id":"3924"},
    {"city_id":"43216","city_name":"Riverside","state_id":"3924"},
    {"city_id":"43217","city_name":"Rocklin","state_id":"3924"},
    {"city_id":"43218","city_name":"Rohnert Park","state_id":"3924"},
    {"city_id":"43219","city_name":"Rolling Hills","state_id":"3924"},
    {"city_id":"43220","city_name":"Rosamond","state_id":"3924"},
    {"city_id":"43221","city_name":"Roseland","state_id":"3924"},
    {"city_id":"43222","city_name":"Rosemead","state_id":"3924"},
    {"city_id":"43223","city_name":"Rosemont","state_id":"3924"},
    {"city_id":"43224","city_name":"Roseville","state_id":"3924"},
    {"city_id":"43225","city_name":"Rossmoor","state_id":"3924"},
    {"city_id":"43226","city_name":"Rowland Heights","state_id":"3924"},
    {"city_id":"43227","city_name":"Rubidoux","state_id":"3924"},
    {"city_id":"43228","city_name":"Sacramento","state_id":"3924"},
    {"city_id":"43229","city_name":"Salinas","state_id":"3924"},
    {"city_id":"43230","city_name":"San Anselmo","state_id":"3924"},
    {"city_id":"43231","city_name":"San Bernardino","state_id":"3924"},
    {"city_id":"43232","city_name":"San Bruno","state_id":"3924"},
    {"city_id":"43233","city_name":"San Buenaventura","state_id":"3924"},
    {"city_id":"43234","city_name":"San Carlos","state_id":"3924"},
    {"city_id":"43235","city_name":"San Clemente","state_id":"3924"},
    {"city_id":"43236","city_name":"San Diego","state_id":"3924"},
    {"city_id":"43237","city_name":"San Dimas","state_id":"3924"},
    {"city_id":"43238","city_name":"San Fernando","state_id":"3924"},
    {"city_id":"43239","city_name":"San Francisco","state_id":"3924"},
    {"city_id":"43240","city_name":"San Gabriel","state_id":"3924"},
    {"city_id":"43241","city_name":"San Jacinto","state_id":"3924"},
    {"city_id":"43242","city_name":"San Jose","state_id":"3924"},
    {"city_id":"43243","city_name":"San Juan Capistrano","state_id":"3924"},
    {"city_id":"43244","city_name":"San Leandro","state_id":"3924"},
    {"city_id":"43245","city_name":"San Lorenzo","state_id":"3924"},
    {"city_id":"43246","city_name":"San Luis Obispo","state_id":"3924"},
    {"city_id":"43247","city_name":"San Marcos","state_id":"3924"},
    {"city_id":"43248","city_name":"San Marino","state_id":"3924"},
    {"city_id":"43249","city_name":"San Mateo","state_id":"3924"},
    {"city_id":"43250","city_name":"San Pablo","state_id":"3924"},
    {"city_id":"43251","city_name":"San Pedro","state_id":"3924"},
    {"city_id":"43252","city_name":"San Rafael","state_id":"3924"},
    {"city_id":"43253","city_name":"San Ramon","state_id":"3924"},
    {"city_id":"43254","city_name":"San Ysidro","state_id":"3924"},
    {"city_id":"43255","city_name":"Sanger","state_id":"3924"},
    {"city_id":"43256","city_name":"Santa Ana","state_id":"3924"},
    {"city_id":"43257","city_name":"Santa Barbara","state_id":"3924"},
    {"city_id":"43258","city_name":"Santa Clara","state_id":"3924"},
    {"city_id":"43259","city_name":"Santa Clarita","state_id":"3924"},
    {"city_id":"43260","city_name":"Santa Cruz","state_id":"3924"},
    {"city_id":"43261","city_name":"Santa Fe Springs","state_id":"3924"},
    {"city_id":"43262","city_name":"Santa Maria","state_id":"3924"},
    {"city_id":"43263","city_name":"Santa Monica","state_id":"3924"},
    {"city_id":"43264","city_name":"Santa Paula","state_id":"3924"},
    {"city_id":"43265","city_name":"Santa Rosa","state_id":"3924"},
    {"city_id":"43266","city_name":"Santa Ynez","state_id":"3924"},
    {"city_id":"43267","city_name":"Santee","state_id":"3924"},
    {"city_id":"43268","city_name":"Saratoga","state_id":"3924"},
    {"city_id":"43269","city_name":"Sausalito","state_id":"3924"},
    {"city_id":"43270","city_name":"Scotts Valley","state_id":"3924"},
    {"city_id":"43271","city_name":"Seal Beach","state_id":"3924"},
    {"city_id":"43272","city_name":"Seaside","state_id":"3924"},
    {"city_id":"43273","city_name":"Sebastopol","state_id":"3924"},
    {"city_id":"43274","city_name":"Selma","state_id":"3924"},
    {"city_id":"43275","city_name":"Shafter","state_id":"3924"},
    {"city_id":"43276","city_name":"Sherman Oaks","state_id":"3924"},
    {"city_id":"43277","city_name":"Sierra Madre","state_id":"3924"},
    {"city_id":"43278","city_name":"Signal Hill","state_id":"3924"},
    {"city_id":"43279","city_name":"Simi Valley","state_id":"3924"},
    {"city_id":"43280","city_name":"Solana Beach","state_id":"3924"},
    {"city_id":"43281","city_name":"Soledad","state_id":"3924"},
    {"city_id":"43282","city_name":"Solvang","state_id":"3924"},
    {"city_id":"43283","city_name":"Sonoma","state_id":"3924"},
    {"city_id":"43284","city_name":"Sonora","state_id":"3924"},
    {"city_id":"43285","city_name":"Soquel","state_id":"3924"},
    {"city_id":"43286","city_name":"South El Monte","state_id":"3924"},
    {"city_id":"43287","city_name":"South Gate","state_id":"3924"},
    {"city_id":"43288","city_name":"South Lake Tahoe","state_id":"3924"},
    {"city_id":"43289","city_name":"South Pasadena","state_id":"3924"},
    {"city_id":"43290","city_name":"South San Francisco","state_id":"3924"},
    {"city_id":"43291","city_name":"South San Jose Hills","state_id":"3924"},
    {"city_id":"43292","city_name":"South Whittier","state_id":"3924"},
    {"city_id":"43293","city_name":"South Yuba City","state_id":"3924"},
    {"city_id":"43294","city_name":"Spring Valley","state_id":"3924"},
    {"city_id":"43295","city_name":"St. Helena","state_id":"3924"},
    {"city_id":"43296","city_name":"Stanford","state_id":"3924"},
    {"city_id":"43297","city_name":"Stanton","state_id":"3924"},
    {"city_id":"43298","city_name":"Stevenson Ranch","state_id":"3924"},
    {"city_id":"43299","city_name":"Stockton","state_id":"3924"},
    {"city_id":"43300","city_name":"Strathmore","state_id":"3924"},
    {"city_id":"43301","city_name":"Studio City","state_id":"3924"},
    {"city_id":"43302","city_name":"Suisun City","state_id":"3924"},
    {"city_id":"43303","city_name":"Sun City","state_id":"3924"},
    {"city_id":"43304","city_name":"Sun Valley","state_id":"3924"},
    {"city_id":"43305","city_name":"Sunland","state_id":"3924"},
    {"city_id":"43306","city_name":"Sunnyvale","state_id":"3924"},
    {"city_id":"43307","city_name":"Susanville","state_id":"3924"},
    {"city_id":"43308","city_name":"Sutter","state_id":"3924"},
    {"city_id":"43309","city_name":"Sylmar","state_id":"3924"},
    {"city_id":"43310","city_name":"Tahoe City","state_id":"3924"},
    {"city_id":"43311","city_name":"Tamalpais-Homestead Valley","state_id":"3924"},
    {"city_id":"43312","city_name":"Tarzana","state_id":"3924"},
    {"city_id":"43313","city_name":"Tehachapi","state_id":"3924"},
    {"city_id":"43314","city_name":"Temecula","state_id":"3924"},
    {"city_id":"43315","city_name":"Temple City","state_id":"3924"},
    {"city_id":"43316","city_name":"Thousand Oaks","state_id":"3924"},
    {"city_id":"43317","city_name":"Tiburon","state_id":"3924"},
    {"city_id":"43318","city_name":"Topanga","state_id":"3924"},
    {"city_id":"43319","city_name":"Torrance","state_id":"3924"},
    {"city_id":"43320","city_name":"Trabuco Canyon","state_id":"3924"},
    {"city_id":"43321","city_name":"Tracy","state_id":"3924"},
    {"city_id":"43322","city_name":"Trinidad","state_id":"3924"},
    {"city_id":"43323","city_name":"Trona","state_id":"3924"},
    {"city_id":"43324","city_name":"Truckee","state_id":"3924"},
    {"city_id":"43325","city_name":"Tujunga","state_id":"3924"},
    {"city_id":"43326","city_name":"Tulare","state_id":"3924"},
    {"city_id":"43327","city_name":"Turlock","state_id":"3924"},
    {"city_id":"43328","city_name":"Tustin","state_id":"3924"},
    {"city_id":"43329","city_name":"Tustin Foothills","state_id":"3924"},
    {"city_id":"43330","city_name":"Twentynine Palms","state_id":"3924"},
    {"city_id":"43331","city_name":"Twentynine Palms Base","state_id":"3924"},
    {"city_id":"43332","city_name":"Ukiah","state_id":"3924"},
    {"city_id":"43333","city_name":"Union City","state_id":"3924"},
    {"city_id":"43334","city_name":"Upland","state_id":"3924"},
    {"city_id":"43335","city_name":"Vacaville","state_id":"3924"},
    {"city_id":"43336","city_name":"Valencia","state_id":"3924"},
    {"city_id":"43337","city_name":"Valinda","state_id":"3924"},
    {"city_id":"43338","city_name":"Valle Vista","state_id":"3924"},
    {"city_id":"43339","city_name":"Vallejo","state_id":"3924"},
    {"city_id":"43340","city_name":"Valley Center","state_id":"3924"},
    {"city_id":"43341","city_name":"Valley Glen","state_id":"3924"},
    {"city_id":"43342","city_name":"Valley Village","state_id":"3924"},
    {"city_id":"43343","city_name":"Van Nuys","state_id":"3924"},
    {"city_id":"43344","city_name":"Vandenberg Air Force Base","state_id":"3924"},
    {"city_id":"43345","city_name":"Venice","state_id":"3924"},
    {"city_id":"43346","city_name":"Ventura","state_id":"3924"},
    {"city_id":"43347","city_name":"Vernon","state_id":"3924"},
    {"city_id":"43348","city_name":"Victorville","state_id":"3924"},
    {"city_id":"43349","city_name":"View Park-Windsor Hills","state_id":"3924"},
    {"city_id":"43350","city_name":"Vincent","state_id":"3924"},
    {"city_id":"43351","city_name":"Visalia","state_id":"3924"},
    {"city_id":"43352","city_name":"Vista","state_id":"3924"},
    {"city_id":"43353","city_name":"Walnut","state_id":"3924"},
    {"city_id":"43354","city_name":"Walnut Creek","state_id":"3924"},
    {"city_id":"43355","city_name":"Walnut Park","state_id":"3924"},
    {"city_id":"43356","city_name":"Wasco","state_id":"3924"},
    {"city_id":"43357","city_name":"Waterford","state_id":"3924"},
    {"city_id":"43358","city_name":"Watsonville","state_id":"3924"},
    {"city_id":"43359","city_name":"West Athens","state_id":"3924"},
    {"city_id":"43360","city_name":"West Carson","state_id":"3924"},
    {"city_id":"43361","city_name":"West Covina","state_id":"3924"},
    {"city_id":"43362","city_name":"West Hills","state_id":"3924"},
    {"city_id":"43363","city_name":"West Hollywood","state_id":"3924"},
    {"city_id":"43364","city_name":"West Puente Valley","state_id":"3924"},
    {"city_id":"43365","city_name":"West Sacramento","state_id":"3924"},
    {"city_id":"43366","city_name":"West Whittier-Los Nietos","state_id":"3924"},
    {"city_id":"43367","city_name":"Westlake Village","state_id":"3924"},
    {"city_id":"43368","city_name":"Westminster","state_id":"3924"},
    {"city_id":"43369","city_name":"Westmont","state_id":"3924"},
    {"city_id":"43370","city_name":"Whittier","state_id":"3924"},
    {"city_id":"43371","city_name":"Wildomar","state_id":"3924"},
    {"city_id":"43372","city_name":"Willits","state_id":"3924"},
    {"city_id":"43373","city_name":"Willowbrook","state_id":"3924"},
    {"city_id":"43374","city_name":"Wilmington","state_id":"3924"},
    {"city_id":"43375","city_name":"Windsor","state_id":"3924"},
    {"city_id":"43376","city_name":"Woodland","state_id":"3924"},
    {"city_id":"43377","city_name":"Woodland Hills","state_id":"3924"},
    {"city_id":"43378","city_name":"Yorba Linda","state_id":"3924"},
    {"city_id":"43379","city_name":"Yreka","state_id":"3924"},
    {"city_id":"43380","city_name":"Yuba City","state_id":"3924"},
    {"city_id":"43381","city_name":"Yucaipa","state_id":"3924"},
    {"city_id":"43382","city_name":"Yucca Valley","state_id":"3924"},
    {"city_id":"43383","city_name":"Air Force Academy","state_id":"3926"},
    {"city_id":"43384","city_name":"Alamosa","state_id":"3926"},
    {"city_id":"43385","city_name":"Applewood","state_id":"3926"},
    {"city_id":"43386","city_name":"Arvada","state_id":"3926"},
    {"city_id":"43387","city_name":"Aspen","state_id":"3926"},
    {"city_id":"43388","city_name":"Aurora","state_id":"3926"},
    {"city_id":"43389","city_name":"Avon","state_id":"3926"},
    {"city_id":"43390","city_name":"Basalt","state_id":"3926"},
    {"city_id":"43391","city_name":"Bellvue","state_id":"3926"},
    {"city_id":"43392","city_name":"Black Forest","state_id":"3926"},
    {"city_id":"43393","city_name":"Boulder","state_id":"3926"},
    {"city_id":"43394","city_name":"Brighton","state_id":"3926"},
    {"city_id":"43395","city_name":"Broomfield","state_id":"3926"},
    {"city_id":"43396","city_name":"Canon City","state_id":"3926"},
    {"city_id":"43397","city_name":"Carbondale","state_id":"3926"},
    {"city_id":"43398","city_name":"Castle Rock","state_id":"3926"},
    {"city_id":"43399","city_name":"Castlewood","state_id":"3926"},
    {"city_id":"43400","city_name":"Centennial","state_id":"3926"},
    {"city_id":"43401","city_name":"Cimarron Hills","state_id":"3926"},
    {"city_id":"43402","city_name":"Clifton","state_id":"3926"},
    {"city_id":"43403","city_name":"Colorado Springs","state_id":"3926"},
    {"city_id":"43404","city_name":"Columbine","state_id":"3926"},
    {"city_id":"43405","city_name":"Commerce City","state_id":"3926"},
    {"city_id":"43406","city_name":"Cortez","state_id":"3926"},
    {"city_id":"43407","city_name":"Crawford","state_id":"3926"},
    {"city_id":"43408","city_name":"Denver","state_id":"3926"},
    {"city_id":"43409","city_name":"Durango","state_id":"3926"},
    {"city_id":"43410","city_name":"Edwards","state_id":"3926"},
    {"city_id":"43411","city_name":"Elizabeth","state_id":"3926"},
    {"city_id":"43412","city_name":"Englewood","state_id":"3926"},
    {"city_id":"43413","city_name":"Estes Park","state_id":"3926"},
    {"city_id":"43414","city_name":"Evergreen","state_id":"3926"},
    {"city_id":"43415","city_name":"Federal Heights","state_id":"3926"},
    {"city_id":"43416","city_name":"Fort Carson","state_id":"3926"},
    {"city_id":"43417","city_name":"Fort Collins","state_id":"3926"},
    {"city_id":"43418","city_name":"Fort Morgan","state_id":"3926"},
    {"city_id":"43419","city_name":"Fountain","state_id":"3926"},
    {"city_id":"43420","city_name":"Golden","state_id":"3926"},
    {"city_id":"43421","city_name":"Grand Junction","state_id":"3926"},
    {"city_id":"43422","city_name":"Greeley","state_id":"3926"},
    {"city_id":"43423","city_name":"Greenwood Village","state_id":"3926"},
    {"city_id":"43424","city_name":"Gunbarrel","state_id":"3926"},
    {"city_id":"43425","city_name":"Highlands Ranch","state_id":"3926"},
    {"city_id":"43426","city_name":"Holly","state_id":"3926"},
    {"city_id":"43427","city_name":"Ken Caryl","state_id":"3926"},
    {"city_id":"43428","city_name":"Lafayette","state_id":"3926"},
    {"city_id":"43429","city_name":"Lakewood","state_id":"3926"},
    {"city_id":"43430","city_name":"Littleton","state_id":"3926"},
    {"city_id":"43431","city_name":"Longmont","state_id":"3926"},
    {"city_id":"43432","city_name":"Louisville","state_id":"3926"},
    {"city_id":"43433","city_name":"Loveland","state_id":"3926"},
    {"city_id":"43434","city_name":"Lyons","state_id":"3926"},
    {"city_id":"43435","city_name":"Montrose","state_id":"3926"},
    {"city_id":"43436","city_name":"Monument","state_id":"3926"},
    {"city_id":"43437","city_name":"Nederland","state_id":"3926"},
    {"city_id":"43438","city_name":"Niwot","state_id":"3926"},
    {"city_id":"43439","city_name":"Northglenn","state_id":"3926"},
    {"city_id":"43440","city_name":"Pagosa Springs","state_id":"3926"},
    {"city_id":"43441","city_name":"Parker","state_id":"3926"},
    {"city_id":"43442","city_name":"Penrose","state_id":"3926"},
    {"city_id":"43443","city_name":"Peyton","state_id":"3926"},
    {"city_id":"43444","city_name":"Pueblo","state_id":"3926"},
    {"city_id":"43445","city_name":"Redlands","state_id":"3926"},
    {"city_id":"43446","city_name":"Ridgway","state_id":"3926"},
    {"city_id":"43447","city_name":"Rifle","state_id":"3926"},
    {"city_id":"43448","city_name":"Rocky Ford","state_id":"3926"},
    {"city_id":"43449","city_name":"Sanford","state_id":"3926"},
    {"city_id":"43450","city_name":"Security-Widefield","state_id":"3926"},
    {"city_id":"43451","city_name":"Sherrelwood","state_id":"3926"},
    {"city_id":"43452","city_name":"Silver Cliff","state_id":"3926"},
    {"city_id":"43453","city_name":"Snowmass Village","state_id":"3926"},
    {"city_id":"43454","city_name":"Southglenn","state_id":"3926"},
    {"city_id":"43455","city_name":"Steamboat Springs","state_id":"3926"},
    {"city_id":"43456","city_name":"Sterling","state_id":"3926"},
    {"city_id":"43457","city_name":"Superior","state_id":"3926"},
    {"city_id":"43458","city_name":"Telluride","state_id":"3926"},
    {"city_id":"43459","city_name":"Thornton","state_id":"3926"},
    {"city_id":"43460","city_name":"Vail","state_id":"3926"},
    {"city_id":"43461","city_name":"Welby","state_id":"3926"},
    {"city_id":"43462","city_name":"Westcliffe","state_id":"3926"},
    {"city_id":"43463","city_name":"Westminster","state_id":"3926"},
    {"city_id":"43464","city_name":"Wheat Ridge","state_id":"3926"},
    {"city_id":"43465","city_name":"Woodland Park","state_id":"3926"},
    {"city_id":"43466","city_name":"Ansonia","state_id":"3927"},
    {"city_id":"43467","city_name":"Avon","state_id":"3927"},
    {"city_id":"43468","city_name":"Bethel","state_id":"3927"},
    {"city_id":"43469","city_name":"Bethlehem","state_id":"3927"},
    {"city_id":"43470","city_name":"Bloomfield","state_id":"3927"},
    {"city_id":"43471","city_name":"Branford","state_id":"3927"},
    {"city_id":"43472","city_name":"Bridgeport","state_id":"3927"},
    {"city_id":"43473","city_name":"Bristol","state_id":"3927"},
    {"city_id":"43474","city_name":"Canaan","state_id":"3927"},
    {"city_id":"43475","city_name":"Canton","state_id":"3927"},
    {"city_id":"43476","city_name":"Central Manchester","state_id":"3927"},
    {"city_id":"43477","city_name":"Cheshire","state_id":"3927"},
    {"city_id":"43478","city_name":"Colchester","state_id":"3927"},
    {"city_id":"43479","city_name":"Conning Towers-Nautilus Park","state_id":"3927"},
    {"city_id":"43480","city_name":"Coscob","state_id":"3927"},
    {"city_id":"43481","city_name":"Cranbury","state_id":"3927"},
    {"city_id":"43482","city_name":"Cromwell","state_id":"3927"},
    {"city_id":"43483","city_name":"Danbury","state_id":"3927"},
    {"city_id":"43484","city_name":"Darien","state_id":"3927"},
    {"city_id":"43485","city_name":"Dayville","state_id":"3927"},
    {"city_id":"43486","city_name":"Derby","state_id":"3927"},
    {"city_id":"43487","city_name":"East Hartford","state_id":"3927"},
    {"city_id":"43488","city_name":"East Haven","state_id":"3927"},
    {"city_id":"43489","city_name":"Ellington","state_id":"3927"},
    {"city_id":"43490","city_name":"Enfield","state_id":"3927"},
    {"city_id":"43491","city_name":"Fairfield","state_id":"3927"},
    {"city_id":"43492","city_name":"Farmington","state_id":"3927"},
    {"city_id":"43493","city_name":"Glastonbury","state_id":"3927"},
    {"city_id":"43494","city_name":"Greens Farms","state_id":"3927"},
    {"city_id":"43495","city_name":"Greenwich","state_id":"3927"},
    {"city_id":"43496","city_name":"Groton","state_id":"3927"},
    {"city_id":"43497","city_name":"Guilford","state_id":"3927"},
    {"city_id":"43498","city_name":"Haddam","state_id":"3927"},
    {"city_id":"43499","city_name":"Hamden","state_id":"3927"},
    {"city_id":"43500","city_name":"Hartford","state_id":"3927"},
    {"city_id":"43501","city_name":"Harwinton","state_id":"3927"},
    {"city_id":"43502","city_name":"Lakeville","state_id":"3927"},
    {"city_id":"43503","city_name":"Lyme","state_id":"3927"},
    {"city_id":"43504","city_name":"Madison","state_id":"3927"},
    {"city_id":"43505","city_name":"Manchester","state_id":"3927"},
    {"city_id":"43506","city_name":"Meriden","state_id":"3927"},
    {"city_id":"43507","city_name":"Middletown","state_id":"3927"},
    {"city_id":"43508","city_name":"Milford","state_id":"3927"},
    {"city_id":"43509","city_name":"Monroe","state_id":"3927"},
    {"city_id":"43510","city_name":"Mystic","state_id":"3927"},
    {"city_id":"43511","city_name":"Naugatuck","state_id":"3927"},
    {"city_id":"43512","city_name":"New Britain","state_id":"3927"},
    {"city_id":"43513","city_name":"New Canaan","state_id":"3927"},
    {"city_id":"43514","city_name":"New Hartford","state_id":"3927"},
    {"city_id":"43515","city_name":"New Haven","state_id":"3927"},
    {"city_id":"43516","city_name":"New London","state_id":"3927"},
    {"city_id":"43517","city_name":"New Milford","state_id":"3927"},
    {"city_id":"43518","city_name":"New Town","state_id":"3927"},
    {"city_id":"43519","city_name":"Newington","state_id":"3927"},
    {"city_id":"43520","city_name":"North Haven","state_id":"3927"},
    {"city_id":"43521","city_name":"North Stonington","state_id":"3927"},
    {"city_id":"43522","city_name":"Norwalk","state_id":"3927"},
    {"city_id":"43523","city_name":"Norwich","state_id":"3927"},
    {"city_id":"43524","city_name":"Old Saybrook","state_id":"3927"},
    {"city_id":"43525","city_name":"Oneco","state_id":"3927"},
    {"city_id":"43526","city_name":"Orange","state_id":"3927"},
    {"city_id":"43527","city_name":"Pawcatuck","state_id":"3927"},
    {"city_id":"43528","city_name":"Plainville","state_id":"3927"},
    {"city_id":"43529","city_name":"Portland","state_id":"3927"},
    {"city_id":"43530","city_name":"Putnam","state_id":"3927"},
    {"city_id":"43531","city_name":"Riverside","state_id":"3927"},
    {"city_id":"43532","city_name":"Rocky Hill","state_id":"3927"},
    {"city_id":"43533","city_name":"Rowayton","state_id":"3927"},
    {"city_id":"43534","city_name":"Sandy Hook","state_id":"3927"},
    {"city_id":"43535","city_name":"Seymour","state_id":"3927"},
    {"city_id":"43536","city_name":"Sharon","state_id":"3927"},
    {"city_id":"43537","city_name":"Shelton","state_id":"3927"},
    {"city_id":"43538","city_name":"South Windsor","state_id":"3927"},
    {"city_id":"43539","city_name":"Southington","state_id":"3927"},
    {"city_id":"43540","city_name":"Southport","state_id":"3927"},
    {"city_id":"43541","city_name":"Stamford","state_id":"3927"},
    {"city_id":"43542","city_name":"Sterling","state_id":"3927"},
    {"city_id":"43543","city_name":"Storrs","state_id":"3927"},
    {"city_id":"43544","city_name":"Stratford","state_id":"3927"},
    {"city_id":"43545","city_name":"Suffield","state_id":"3927"},
    {"city_id":"43546","city_name":"Taftville","state_id":"3927"},
    {"city_id":"43547","city_name":"Terryville","state_id":"3927"},
    {"city_id":"43548","city_name":"Tolland","state_id":"3927"},
    {"city_id":"43549","city_name":"Torrington","state_id":"3927"},
    {"city_id":"43550","city_name":"Trumbull","state_id":"3927"},
    {"city_id":"43551","city_name":"Vernon","state_id":"3927"},
    {"city_id":"43552","city_name":"Wallingford Center","state_id":"3927"},
    {"city_id":"43553","city_name":"Waterbury","state_id":"3927"},
    {"city_id":"43554","city_name":"Watertown","state_id":"3927"},
    {"city_id":"43555","city_name":"West Hartford","state_id":"3927"},
    {"city_id":"43556","city_name":"West Haven","state_id":"3927"},
    {"city_id":"43557","city_name":"Weston","state_id":"3927"},
    {"city_id":"43558","city_name":"Westport","state_id":"3927"},
    {"city_id":"43559","city_name":"Wethersfield","state_id":"3927"},
    {"city_id":"43560","city_name":"Willimantic","state_id":"3927"},
    {"city_id":"43561","city_name":"Wilton","state_id":"3927"},
    {"city_id":"43562","city_name":"Windsor","state_id":"3927"},
    {"city_id":"43563","city_name":"Windsor Locks","state_id":"3927"},
    {"city_id":"43564","city_name":"Winsted","state_id":"3927"},
    {"city_id":"43565","city_name":"Woodbury","state_id":"3927"},
    {"city_id":"43566","city_name":"Woodstock","state_id":"3927"},
    {"city_id":"43567","city_name":"pomfret","state_id":"3927"},
    {"city_id":"43568","city_name":"Bear","state_id":"3928"},
    {"city_id":"43569","city_name":"Brookside","state_id":"3928"},
    {"city_id":"43570","city_name":"Claymont","state_id":"3928"},
    {"city_id":"43571","city_name":"Dover","state_id":"3928"},
    {"city_id":"43572","city_name":"Dover Base Housing","state_id":"3928"},
    {"city_id":"43573","city_name":"Edgemoor","state_id":"3928"},
    {"city_id":"43574","city_name":"Elsmere","state_id":"3928"},
    {"city_id":"43575","city_name":"Georgetown","state_id":"3928"},
    {"city_id":"43576","city_name":"Greenville","state_id":"3928"},
    {"city_id":"43577","city_name":"Middletown","state_id":"3928"},
    {"city_id":"43578","city_name":"Milford","state_id":"3928"},
    {"city_id":"43579","city_name":"Milton","state_id":"3928"},
    {"city_id":"43580","city_name":"Newark","state_id":"3928"},
    {"city_id":"43581","city_name":"Pike Creek","state_id":"3928"},
    {"city_id":"43582","city_name":"Seaford","state_id":"3928"},
    {"city_id":"43583","city_name":"Smyrna","state_id":"3928"},
    {"city_id":"43584","city_name":"Stanton","state_id":"3928"},
    {"city_id":"43585","city_name":"Talleyville","state_id":"3928"},
    {"city_id":"43586","city_name":"Wilmington","state_id":"3928"},
    {"city_id":"43587","city_name":"Wilmington Manor","state_id":"3928"},
    {"city_id":"43588","city_name":"Alachua","state_id":"3930"},
    {"city_id":"43589","city_name":"Altamonte Springs","state_id":"3930"},
    {"city_id":"43590","city_name":"Apopka","state_id":"3930"},
    {"city_id":"43591","city_name":"Atlantic Beach","state_id":"3930"},
    {"city_id":"43592","city_name":"Auburndale","state_id":"3930"},
    {"city_id":"43593","city_name":"Aventura","state_id":"3930"},
    {"city_id":"43594","city_name":"Avon Park","state_id":"3930"},
    {"city_id":"43595","city_name":"Azalea Park","state_id":"3930"},
    {"city_id":"43596","city_name":"Bal Harbour","state_id":"3930"},
    {"city_id":"43597","city_name":"Bartow","state_id":"3930"},
    {"city_id":"43598","city_name":"Bayonet Point","state_id":"3930"},
    {"city_id":"43599","city_name":"Bayshore Gardens","state_id":"3930"},
    {"city_id":"43600","city_name":"Beach","state_id":"3930"},
    {"city_id":"43601","city_name":"Bellair-Meadowbrook Terrace","state_id":"3930"},
    {"city_id":"43602","city_name":"Belle Glade","state_id":"3930"},
    {"city_id":"43603","city_name":"Bellview","state_id":"3930"},
    {"city_id":"43604","city_name":"Beverly Hills","state_id":"3930"},
    {"city_id":"43605","city_name":"Bloomingdale","state_id":"3930"},
    {"city_id":"43606","city_name":"Boca Raton","state_id":"3930"},
    {"city_id":"43607","city_name":"Boca del Mar","state_id":"3930"},
    {"city_id":"43608","city_name":"Bonita Springs","state_id":"3930"},
    {"city_id":"43609","city_name":"Boynton Beach","state_id":"3930"},
    {"city_id":"43610","city_name":"Bradenton","state_id":"3930"},
    {"city_id":"43611","city_name":"Brandon","state_id":"3930"},
    {"city_id":"43612","city_name":"Brent","state_id":"3930"},
    {"city_id":"43613","city_name":"Brooksville","state_id":"3930"},
    {"city_id":"43614","city_name":"Brownsville","state_id":"3930"},
    {"city_id":"43615","city_name":"Buena Ventura Lakes","state_id":"3930"},
    {"city_id":"43616","city_name":"Bunnell","state_id":"3930"},
    {"city_id":"43617","city_name":"Callaway","state_id":"3930"},
    {"city_id":"43618","city_name":"Cape Coral","state_id":"3930"},
    {"city_id":"43619","city_name":"Carol City","state_id":"3930"},
    {"city_id":"43620","city_name":"Casselberry","state_id":"3930"},
    {"city_id":"43621","city_name":"Catalina Foothills","state_id":"3930"},
    {"city_id":"43622","city_name":"Celebration","state_id":"3930"},
    {"city_id":"43623","city_name":"Century Village","state_id":"3930"},
    {"city_id":"43624","city_name":"Citrus Park","state_id":"3930"},
    {"city_id":"43625","city_name":"Clearwater","state_id":"3930"},
    {"city_id":"43626","city_name":"Clermont","state_id":"3930"},
    {"city_id":"43627","city_name":"Cocoa","state_id":"3930"},
    {"city_id":"43628","city_name":"Cocoa Beach","state_id":"3930"},
    {"city_id":"43629","city_name":"Coconut Creek","state_id":"3930"},
    {"city_id":"43630","city_name":"Coconut Grove","state_id":"3930"},
    {"city_id":"43631","city_name":"Conway","state_id":"3930"},
    {"city_id":"43632","city_name":"Cooper City","state_id":"3930"},
    {"city_id":"43633","city_name":"Coral Gables","state_id":"3930"},
    {"city_id":"43634","city_name":"Coral Springs","state_id":"3930"},
    {"city_id":"43635","city_name":"Coral Terrace","state_id":"3930"},
    {"city_id":"43636","city_name":"Cortlandt Manor","state_id":"3930"},
    {"city_id":"43637","city_name":"Country Club","state_id":"3930"},
    {"city_id":"43638","city_name":"Crestview","state_id":"3930"},
    {"city_id":"43639","city_name":"Crystal River","state_id":"3930"},
    {"city_id":"43640","city_name":"Cutler","state_id":"3930"},
    {"city_id":"43641","city_name":"Cutler Ridge","state_id":"3930"},
    {"city_id":"43642","city_name":"Cypress Gardens","state_id":"3930"},
    {"city_id":"43643","city_name":"Cypress Lake","state_id":"3930"},
    {"city_id":"43644","city_name":"Dania","state_id":"3930"},
    {"city_id":"43645","city_name":"Dania Beach","state_id":"3930"},
    {"city_id":"43646","city_name":"Davie","state_id":"3930"},
    {"city_id":"43647","city_name":"Daytona Beach","state_id":"3930"},
    {"city_id":"43648","city_name":"De Bary","state_id":"3930"},
    {"city_id":"43649","city_name":"De Funiak Springs","state_id":"3930"},
    {"city_id":"43650","city_name":"De Land","state_id":"3930"},
    {"city_id":"43651","city_name":"Debary","state_id":"3930"},
    {"city_id":"43652","city_name":"Deer Park","state_id":"3930"},
    {"city_id":"43653","city_name":"Deerfield Beach","state_id":"3930"},
    {"city_id":"43654","city_name":"Del Rio","state_id":"3930"},
    {"city_id":"43655","city_name":"Delray Beach","state_id":"3930"},
    {"city_id":"43656","city_name":"Deltona","state_id":"3930"},
    {"city_id":"43657","city_name":"Destin","state_id":"3930"},
    {"city_id":"43658","city_name":"Doctor Phillips","state_id":"3930"},
    {"city_id":"43659","city_name":"Dora","state_id":"3930"},
    {"city_id":"43660","city_name":"Doral","state_id":"3930"},
    {"city_id":"43661","city_name":"Dundee","state_id":"3930"},
    {"city_id":"43662","city_name":"Dunedin","state_id":"3930"},
    {"city_id":"43663","city_name":"East Lake","state_id":"3930"},
    {"city_id":"43664","city_name":"Edgewater","state_id":"3930"},
    {"city_id":"43665","city_name":"Eglin Air Force Base","state_id":"3930"},
    {"city_id":"43666","city_name":"Egypt Lake-Leto","state_id":"3930"},
    {"city_id":"43667","city_name":"Elfers","state_id":"3930"},
    {"city_id":"43668","city_name":"Englewood","state_id":"3930"},
    {"city_id":"43669","city_name":"Ensley","state_id":"3930"},
    {"city_id":"43670","city_name":"Eustis","state_id":"3930"},
    {"city_id":"43671","city_name":"Fairview Shores","state_id":"3930"},
    {"city_id":"43672","city_name":"Fern Park","state_id":"3930"},
    {"city_id":"43673","city_name":"Fernandina Beach","state_id":"3930"},
    {"city_id":"43674","city_name":"Ferry Pass","state_id":"3930"},
    {"city_id":"43675","city_name":"Flagler Beach","state_id":"3930"},
    {"city_id":"43676","city_name":"Floral City","state_id":"3930"},
    {"city_id":"43677","city_name":"Florida City","state_id":"3930"},
    {"city_id":"43678","city_name":"Florida Ridge","state_id":"3930"},
    {"city_id":"43679","city_name":"Forest City","state_id":"3930"},
    {"city_id":"43680","city_name":"Fort Lauderdale","state_id":"3930"},
    {"city_id":"43681","city_name":"Fort Myers","state_id":"3930"},
    {"city_id":"43682","city_name":"Fort Myers Beach","state_id":"3930"},
    {"city_id":"43683","city_name":"Fort Pierce","state_id":"3930"},
    {"city_id":"43684","city_name":"Fort Walton Beach","state_id":"3930"},
    {"city_id":"43685","city_name":"Freeport","state_id":"3930"},
    {"city_id":"43686","city_name":"Fruitville","state_id":"3930"},
    {"city_id":"43687","city_name":"Ft. Lauderdale","state_id":"3930"},
    {"city_id":"43688","city_name":"Gainesville","state_id":"3930"},
    {"city_id":"43689","city_name":"Gladeview","state_id":"3930"},
    {"city_id":"43690","city_name":"Glenvar Heights","state_id":"3930"},
    {"city_id":"43691","city_name":"Golden Gate","state_id":"3930"},
    {"city_id":"43692","city_name":"Golden Glades","state_id":"3930"},
    {"city_id":"43693","city_name":"Goldenrod","state_id":"3930"},
    {"city_id":"43694","city_name":"Greater Carrollwood","state_id":"3930"},
    {"city_id":"43695","city_name":"Greater Northdale","state_id":"3930"},
    {"city_id":"43696","city_name":"Green Cove Springs","state_id":"3930"},
    {"city_id":"43697","city_name":"Greenacres","state_id":"3930"},
    {"city_id":"43698","city_name":"Gulf Gate Estates","state_id":"3930"},
    {"city_id":"43699","city_name":"Gulfport","state_id":"3930"},
    {"city_id":"43700","city_name":"Haines City","state_id":"3930"},
    {"city_id":"43701","city_name":"Hallandale","state_id":"3930"},
    {"city_id":"43702","city_name":"Hallandale Beach","state_id":"3930"},
    {"city_id":"43703","city_name":"Hammocks","state_id":"3930"},
    {"city_id":"43704","city_name":"Hamptons at Boca Raton","state_id":"3930"},
    {"city_id":"43705","city_name":"Havana","state_id":"3930"},
    {"city_id":"43706","city_name":"Hialeah","state_id":"3930"},
    {"city_id":"43707","city_name":"Hialeah Gardens","state_id":"3930"},
    {"city_id":"43708","city_name":"Highpoint","state_id":"3930"},
    {"city_id":"43709","city_name":"Hobe Sound","state_id":"3930"},
    {"city_id":"43710","city_name":"Holiday","state_id":"3930"},
    {"city_id":"43711","city_name":"Holly Hill","state_id":"3930"},
    {"city_id":"43712","city_name":"Hollywood","state_id":"3930"},
    {"city_id":"43713","city_name":"Homestead","state_id":"3930"},
    {"city_id":"43714","city_name":"Homosassa","state_id":"3930"},
    {"city_id":"43715","city_name":"Hudson","state_id":"3930"},
    {"city_id":"43716","city_name":"Immokalee","state_id":"3930"},
    {"city_id":"43717","city_name":"Inverness","state_id":"3930"},
    {"city_id":"43718","city_name":"Iona","state_id":"3930"},
    {"city_id":"43719","city_name":"Ives Estates","state_id":"3930"},
    {"city_id":"43720","city_name":"Jacksonville","state_id":"3930"},
    {"city_id":"43721","city_name":"Jacksonville Beach","state_id":"3930"},
    {"city_id":"43722","city_name":"Jasmine Estates","state_id":"3930"},
    {"city_id":"43723","city_name":"Jensen Beach","state_id":"3930"},
    {"city_id":"43724","city_name":"Jupiter","state_id":"3930"},
    {"city_id":"43725","city_name":"Kendale Lakes","state_id":"3930"},
    {"city_id":"43726","city_name":"Kendall","state_id":"3930"},
    {"city_id":"43727","city_name":"Kendall West","state_id":"3930"},
    {"city_id":"43728","city_name":"Key Biscayne","state_id":"3930"},
    {"city_id":"43729","city_name":"Key Largo","state_id":"3930"},
    {"city_id":"43730","city_name":"Key West","state_id":"3930"},
    {"city_id":"43731","city_name":"Kings Point","state_id":"3930"},
    {"city_id":"43732","city_name":"Kissimmee","state_id":"3930"},
    {"city_id":"43733","city_name":"Lady Lake","state_id":"3930"},
    {"city_id":"43734","city_name":"Lake Alfred","state_id":"3930"},
    {"city_id":"43735","city_name":"Lake City","state_id":"3930"},
    {"city_id":"43736","city_name":"Lake Lucerne","state_id":"3930"},
    {"city_id":"43737","city_name":"Lake Magdalene","state_id":"3930"},
    {"city_id":"43738","city_name":"Lake Mary","state_id":"3930"},
    {"city_id":"43739","city_name":"Lake Placid","state_id":"3930"},
    {"city_id":"43740","city_name":"Lake Wales","state_id":"3930"},
    {"city_id":"43741","city_name":"Lake Worth","state_id":"3930"},
    {"city_id":"43742","city_name":"Lakeland","state_id":"3930"},
    {"city_id":"43743","city_name":"Lakeland Highlands","state_id":"3930"},
    {"city_id":"43744","city_name":"Lakeside","state_id":"3930"},
    {"city_id":"43745","city_name":"Land O'Lakes","state_id":"3930"},
    {"city_id":"43746","city_name":"Largo","state_id":"3930"},
    {"city_id":"43747","city_name":"Lauderdale Lakes","state_id":"3930"},
    {"city_id":"43748","city_name":"Lauderhill","state_id":"3930"},
    {"city_id":"43749","city_name":"Laurel","state_id":"3930"},
    {"city_id":"43750","city_name":"Lecanto","state_id":"3930"},
    {"city_id":"43751","city_name":"Leesburg","state_id":"3930"},
    {"city_id":"43752","city_name":"Lehigh Acres","state_id":"3930"},
    {"city_id":"43753","city_name":"Leisure City","state_id":"3930"},
    {"city_id":"43754","city_name":"Lighthouse Point","state_id":"3930"},
    {"city_id":"43755","city_name":"Lockhart","state_id":"3930"},
    {"city_id":"43756","city_name":"Longwood","state_id":"3930"},
    {"city_id":"43757","city_name":"Loxahatchee","state_id":"3930"},
    {"city_id":"43758","city_name":"Lutz","state_id":"3930"},
    {"city_id":"43759","city_name":"Lynn Haven","state_id":"3930"},
    {"city_id":"43760","city_name":"Madison","state_id":"3930"},
    {"city_id":"43761","city_name":"Maitland","state_id":"3930"},
    {"city_id":"43762","city_name":"Mango","state_id":"3930"},
    {"city_id":"43763","city_name":"Marathon","state_id":"3930"},
    {"city_id":"43764","city_name":"Marco","state_id":"3930"},
    {"city_id":"43765","city_name":"Margate","state_id":"3930"},
    {"city_id":"43766","city_name":"Medley","state_id":"3930"},
    {"city_id":"43767","city_name":"Melbourne","state_id":"3930"},
    {"city_id":"43768","city_name":"Merritt Island","state_id":"3930"},
    {"city_id":"43769","city_name":"Miami","state_id":"3930"},
    {"city_id":"43770","city_name":"Miami Beach","state_id":"3930"},
    {"city_id":"43771","city_name":"Miami Gardens","state_id":"3930"},
    {"city_id":"43772","city_name":"Miami Lakes","state_id":"3930"},
    {"city_id":"43773","city_name":"Miami Shores","state_id":"3930"},
    {"city_id":"43774","city_name":"Miami Springs","state_id":"3930"},
    {"city_id":"43775","city_name":"Micco","state_id":"3930"},
    {"city_id":"43776","city_name":"Milton","state_id":"3930"},
    {"city_id":"43777","city_name":"Mims","state_id":"3930"},
    {"city_id":"43778","city_name":"Miramar","state_id":"3930"},
    {"city_id":"43779","city_name":"Mulberry","state_id":"3930"},
    {"city_id":"43780","city_name":"Myrtle Grove","state_id":"3930"},
    {"city_id":"43781","city_name":"Naples","state_id":"3930"},
    {"city_id":"43782","city_name":"Naples Park","state_id":"3930"},
    {"city_id":"43783","city_name":"Naranja","state_id":"3930"},
    {"city_id":"43784","city_name":"New Port Richey","state_id":"3930"},
    {"city_id":"43785","city_name":"New Port Richey East","state_id":"3930"},
    {"city_id":"43786","city_name":"New Smyrna Beach","state_id":"3930"},
    {"city_id":"43787","city_name":"Niceville","state_id":"3930"},
    {"city_id":"43788","city_name":"Nokomis","state_id":"3930"},
    {"city_id":"43789","city_name":"Norland","state_id":"3930"},
    {"city_id":"43790","city_name":"North Andrews Gardens","state_id":"3930"},
    {"city_id":"43791","city_name":"North Fort Myers","state_id":"3930"},
    {"city_id":"43792","city_name":"North Lauderdale","state_id":"3930"},
    {"city_id":"43793","city_name":"North Miami","state_id":"3930"},
    {"city_id":"43794","city_name":"North Miami Beach","state_id":"3930"},
    {"city_id":"43795","city_name":"North Naples","state_id":"3930"},
    {"city_id":"43796","city_name":"North Palm Beach","state_id":"3930"},
    {"city_id":"43797","city_name":"North Port","state_id":"3930"},
    {"city_id":"43798","city_name":"Oak Ridge","state_id":"3930"},
    {"city_id":"43799","city_name":"Oakland Park","state_id":"3930"},
    {"city_id":"43800","city_name":"Ocala","state_id":"3930"},
    {"city_id":"43801","city_name":"Ocoee","state_id":"3930"},
    {"city_id":"43802","city_name":"Ojus","state_id":"3930"},
    {"city_id":"43803","city_name":"Okeechobee","state_id":"3930"},
    {"city_id":"43804","city_name":"Oldsmar","state_id":"3930"},
    {"city_id":"43805","city_name":"Olympia Heights","state_id":"3930"},
    {"city_id":"43806","city_name":"Opa-locka","state_id":"3930"},
    {"city_id":"43807","city_name":"Orange City","state_id":"3930"},
    {"city_id":"43808","city_name":"Orange Park","state_id":"3930"},
    {"city_id":"43809","city_name":"Orlando","state_id":"3930"},
    {"city_id":"43810","city_name":"Ormond Beach","state_id":"3930"},
    {"city_id":"43811","city_name":"Ormond-by-the-Sea","state_id":"3930"},
    {"city_id":"43812","city_name":"Osprey","state_id":"3930"},
    {"city_id":"43813","city_name":"Oviedo","state_id":"3930"},
    {"city_id":"43814","city_name":"Palatka","state_id":"3930"},
    {"city_id":"43815","city_name":"Palm Bay","state_id":"3930"},
    {"city_id":"43816","city_name":"Palm Beach","state_id":"3930"},
    {"city_id":"43817","city_name":"Palm Beach Gardens","state_id":"3930"},
    {"city_id":"43818","city_name":"Palm City","state_id":"3930"},
    {"city_id":"43819","city_name":"Palm Coast","state_id":"3930"},
    {"city_id":"43820","city_name":"Palm Harbor","state_id":"3930"},
    {"city_id":"43821","city_name":"Palm River-Clair Mel","state_id":"3930"},
    {"city_id":"43822","city_name":"Palm Valley","state_id":"3930"},
    {"city_id":"43823","city_name":"Palmetto","state_id":"3930"},
    {"city_id":"43824","city_name":"Palmetto Estates","state_id":"3930"},
    {"city_id":"43825","city_name":"Panama City","state_id":"3930"},
    {"city_id":"43826","city_name":"Parkland","state_id":"3930"},
    {"city_id":"43827","city_name":"Pembroke Park","state_id":"3930"},
    {"city_id":"43828","city_name":"Pembroke Pines","state_id":"3930"},
    {"city_id":"43829","city_name":"Pensacola","state_id":"3930"},
    {"city_id":"43830","city_name":"Perrine","state_id":"3930"},
    {"city_id":"43831","city_name":"Pine Castle","state_id":"3930"},
    {"city_id":"43832","city_name":"Pine Hills","state_id":"3930"},
    {"city_id":"43833","city_name":"Pinellas Park","state_id":"3930"},
    {"city_id":"43834","city_name":"Pinewood","state_id":"3930"},
    {"city_id":"43835","city_name":"Plant City","state_id":"3930"},
    {"city_id":"43836","city_name":"Plantation","state_id":"3930"},
    {"city_id":"43837","city_name":"Pompano Beach","state_id":"3930"},
    {"city_id":"43838","city_name":"Pompano Beach Highlands","state_id":"3930"},
    {"city_id":"43839","city_name":"Ponte Vedra","state_id":"3930"},
    {"city_id":"43840","city_name":"Port Charlotte","state_id":"3930"},
    {"city_id":"43841","city_name":"Port Orange","state_id":"3930"},
    {"city_id":"43842","city_name":"Port Saint John","state_id":"3930"},
    {"city_id":"43843","city_name":"Port Saint Lucie","state_id":"3930"},
    {"city_id":"43844","city_name":"Punta Gorda","state_id":"3930"},
    {"city_id":"43845","city_name":"Quincy","state_id":"3930"},
    {"city_id":"43846","city_name":"Redington Shores","state_id":"3930"},
    {"city_id":"43847","city_name":"Richmond Heights","state_id":"3930"},
    {"city_id":"43848","city_name":"Richmond West","state_id":"3930"},
    {"city_id":"43849","city_name":"Riverview","state_id":"3930"},
    {"city_id":"43850","city_name":"Riviera Beach","state_id":"3930"},
    {"city_id":"43851","city_name":"Rockledge","state_id":"3930"},
    {"city_id":"43852","city_name":"Royal Palm Beach","state_id":"3930"},
    {"city_id":"43853","city_name":"Safety Harbor","state_id":"3930"},
    {"city_id":"43854","city_name":"Saint Augustine","state_id":"3930"},
    {"city_id":"43855","city_name":"Saint Cloud","state_id":"3930"},
    {"city_id":"43856","city_name":"Saint Petersburg","state_id":"3930"},
    {"city_id":"43857","city_name":"Saint Petersburg Beach","state_id":"3930"},
    {"city_id":"43858","city_name":"San Carlos Park","state_id":"3930"},
    {"city_id":"43859","city_name":"Sandalfoot Cove","state_id":"3930"},
    {"city_id":"43860","city_name":"Sanford","state_id":"3930"},
    {"city_id":"43861","city_name":"Sanibel","state_id":"3930"},
    {"city_id":"43862","city_name":"Sarasota","state_id":"3930"},
    {"city_id":"43863","city_name":"Sarasota Springs","state_id":"3930"},
    {"city_id":"43864","city_name":"Satellite Beach","state_id":"3930"},
    {"city_id":"43865","city_name":"Scott Lake","state_id":"3930"},
    {"city_id":"43866","city_name":"Sebastian","state_id":"3930"},
    {"city_id":"43867","city_name":"Seminole","state_id":"3930"},
    {"city_id":"43868","city_name":"Shalimar","state_id":"3930"},
    {"city_id":"43869","city_name":"South Bradenton","state_id":"3930"},
    {"city_id":"43870","city_name":"South Daytona","state_id":"3930"},
    {"city_id":"43871","city_name":"South Miami","state_id":"3930"},
    {"city_id":"43872","city_name":"South Miami Heights","state_id":"3930"},
    {"city_id":"43873","city_name":"South Patrick Shores","state_id":"3930"},
    {"city_id":"43874","city_name":"South Venice","state_id":"3930"},
    {"city_id":"43875","city_name":"Spring Hill","state_id":"3930"},
    {"city_id":"43876","city_name":"Stuart","state_id":"3930"},
    {"city_id":"43877","city_name":"Sun City Center","state_id":"3930"},
    {"city_id":"43878","city_name":"Sunny Isles","state_id":"3930"},
    {"city_id":"43879","city_name":"Sunrise","state_id":"3930"},
    {"city_id":"43880","city_name":"Sunset","state_id":"3930"},
    {"city_id":"43881","city_name":"Sweetwater","state_id":"3930"},
    {"city_id":"43882","city_name":"Tallahassee","state_id":"3930"},
    {"city_id":"43883","city_name":"Tamarac","state_id":"3930"},
    {"city_id":"43884","city_name":"Tamiami","state_id":"3930"},
    {"city_id":"43885","city_name":"Tampa","state_id":"3930"},
    {"city_id":"43886","city_name":"Tarpon Springs","state_id":"3930"},
    {"city_id":"43887","city_name":"Temple Terrace","state_id":"3930"},
    {"city_id":"43888","city_name":"The Crossings","state_id":"3930"},
    {"city_id":"43889","city_name":"The Hammocks","state_id":"3930"},
    {"city_id":"43890","city_name":"Titusville","state_id":"3930"},
    {"city_id":"43891","city_name":"Town'n'Country","state_id":"3930"},
    {"city_id":"43892","city_name":"University","state_id":"3930"},
    {"city_id":"43893","city_name":"University Park","state_id":"3930"},
    {"city_id":"43894","city_name":"Valrico","state_id":"3930"},
    {"city_id":"43895","city_name":"Venice","state_id":"3930"},
    {"city_id":"43896","city_name":"Vero Beach","state_id":"3930"},
    {"city_id":"43897","city_name":"Vero Beach South","state_id":"3930"},
    {"city_id":"43898","city_name":"Villas","state_id":"3930"},
    {"city_id":"43899","city_name":"Warrington","state_id":"3930"},
    {"city_id":"43900","city_name":"Wekiva Springs","state_id":"3930"},
    {"city_id":"43901","city_name":"Wellington","state_id":"3930"},
    {"city_id":"43902","city_name":"Wesley Chapel","state_id":"3930"},
    {"city_id":"43903","city_name":"West Little River","state_id":"3930"},
    {"city_id":"43904","city_name":"West Palm Beach","state_id":"3930"},
    {"city_id":"43905","city_name":"West Park","state_id":"3930"},
    {"city_id":"43906","city_name":"West Pensacola","state_id":"3930"},
    {"city_id":"43907","city_name":"West and East Lealman","state_id":"3930"},
    {"city_id":"43908","city_name":"Westchester","state_id":"3930"},
    {"city_id":"43909","city_name":"Weston","state_id":"3930"},
    {"city_id":"43910","city_name":"Westview","state_id":"3930"},
    {"city_id":"43911","city_name":"Westwood Lakes","state_id":"3930"},
    {"city_id":"43912","city_name":"Wilton Manors","state_id":"3930"},
    {"city_id":"43913","city_name":"Windermere","state_id":"3930"},
    {"city_id":"43914","city_name":"Winston","state_id":"3930"},
    {"city_id":"43915","city_name":"Winter Garden","state_id":"3930"},
    {"city_id":"43916","city_name":"Winter Haven","state_id":"3930"},
    {"city_id":"43917","city_name":"Winter Park","state_id":"3930"},
    {"city_id":"43918","city_name":"Winter Springs","state_id":"3930"},
    {"city_id":"43919","city_name":"Wright","state_id":"3930"},
    {"city_id":"43920","city_name":"Yeehaw Junction","state_id":"3930"},
    {"city_id":"43921","city_name":"Acworth","state_id":"3931"},
    {"city_id":"43922","city_name":"Adel","state_id":"3931"},
    {"city_id":"43923","city_name":"Albany","state_id":"3931"},
    {"city_id":"43924","city_name":"Alma","state_id":"3931"},
    {"city_id":"43925","city_name":"Alpharetta","state_id":"3931"},
    {"city_id":"43926","city_name":"Americus","state_id":"3931"},
    {"city_id":"43927","city_name":"Athens","state_id":"3931"},
    {"city_id":"43928","city_name":"Athens-Clarke","state_id":"3931"},
    {"city_id":"43929","city_name":"Atlanta","state_id":"3931"},
    {"city_id":"43930","city_name":"Auburn","state_id":"3931"},
    {"city_id":"43931","city_name":"Augusta-Richmond","state_id":"3931"},
    {"city_id":"43932","city_name":"Austell","state_id":"3931"},
    {"city_id":"43933","city_name":"Bainbridge","state_id":"3931"},
    {"city_id":"43934","city_name":"Barnesville","state_id":"3931"},
    {"city_id":"43935","city_name":"Belvedere Park","state_id":"3931"},
    {"city_id":"43936","city_name":"Bogart","state_id":"3931"},
    {"city_id":"43937","city_name":"Bowdon","state_id":"3931"},
    {"city_id":"43938","city_name":"Braselton","state_id":"3931"},
    {"city_id":"43939","city_name":"Brunswick","state_id":"3931"},
    {"city_id":"43940","city_name":"Buford","state_id":"3931"},
    {"city_id":"43941","city_name":"Byron","state_id":"3931"},
    {"city_id":"43942","city_name":"Cairo","state_id":"3931"},
    {"city_id":"43943","city_name":"Calhoun","state_id":"3931"},
    {"city_id":"43944","city_name":"Candler-MacAfee","state_id":"3931"},
    {"city_id":"43945","city_name":"Canton","state_id":"3931"},
    {"city_id":"43946","city_name":"Carrollton","state_id":"3931"},
    {"city_id":"43947","city_name":"Cartersville","state_id":"3931"},
    {"city_id":"43948","city_name":"Chamblee","state_id":"3931"},
    {"city_id":"43949","city_name":"Clarkston","state_id":"3931"},
    {"city_id":"43950","city_name":"Cochran","state_id":"3931"},
    {"city_id":"43951","city_name":"College Park","state_id":"3931"},
    {"city_id":"43952","city_name":"Columbus","state_id":"3931"},
    {"city_id":"43953","city_name":"Comer","state_id":"3931"},
    {"city_id":"43954","city_name":"Conley","state_id":"3931"},
    {"city_id":"43955","city_name":"Conyers","state_id":"3931"},
    {"city_id":"43956","city_name":"Cordele","state_id":"3931"},
    {"city_id":"43957","city_name":"Covington","state_id":"3931"},
    {"city_id":"43958","city_name":"Culloden","state_id":"3931"},
    {"city_id":"43959","city_name":"Cumming","state_id":"3931"},
    {"city_id":"43960","city_name":"Dacula","state_id":"3931"},
    {"city_id":"43961","city_name":"Dahlonega","state_id":"3931"},
    {"city_id":"43962","city_name":"Dallas","state_id":"3931"},
    {"city_id":"43963","city_name":"Dalton","state_id":"3931"},
    {"city_id":"43964","city_name":"Decatur","state_id":"3931"},
    {"city_id":"43965","city_name":"Dewy Rose","state_id":"3931"},
    {"city_id":"43966","city_name":"Doraville","state_id":"3931"},
    {"city_id":"43967","city_name":"Douglas","state_id":"3931"},
    {"city_id":"43968","city_name":"Douglasville","state_id":"3931"},
    {"city_id":"43969","city_name":"Druid Hills","state_id":"3931"},
    {"city_id":"43970","city_name":"Dublin","state_id":"3931"},
    {"city_id":"43971","city_name":"Duluth","state_id":"3931"},
    {"city_id":"43972","city_name":"Dunwoody","state_id":"3931"},
    {"city_id":"43973","city_name":"East Point","state_id":"3931"},
    {"city_id":"43974","city_name":"Elberton","state_id":"3931"},
    {"city_id":"43975","city_name":"Ellenwood","state_id":"3931"},
    {"city_id":"43976","city_name":"Ellijay","state_id":"3931"},
    {"city_id":"43977","city_name":"Evans","state_id":"3931"},
    {"city_id":"43978","city_name":"Fairmount","state_id":"3931"},
    {"city_id":"43979","city_name":"Fayetteville","state_id":"3931"},
    {"city_id":"43980","city_name":"Flowery Branch","state_id":"3931"},
    {"city_id":"43981","city_name":"Folkston","state_id":"3931"},
    {"city_id":"43982","city_name":"Forest Park","state_id":"3931"},
    {"city_id":"43983","city_name":"Fort Benning South","state_id":"3931"},
    {"city_id":"43984","city_name":"Fort Gordon","state_id":"3931"},
    {"city_id":"43985","city_name":"Fort Stewart","state_id":"3931"},
    {"city_id":"43986","city_name":"Fort Valley","state_id":"3931"},
    {"city_id":"43987","city_name":"Foxborough","state_id":"3931"},
    {"city_id":"43988","city_name":"Gaines School","state_id":"3931"},
    {"city_id":"43989","city_name":"Gainesville","state_id":"3931"},
    {"city_id":"43990","city_name":"Glennville","state_id":"3931"},
    {"city_id":"43991","city_name":"Gresham Park","state_id":"3931"},
    {"city_id":"43992","city_name":"Griffin","state_id":"3931"},
    {"city_id":"43993","city_name":"Grovetown","state_id":"3931"},
    {"city_id":"43994","city_name":"Hampton","state_id":"3931"},
    {"city_id":"43995","city_name":"Hartwell","state_id":"3931"},
    {"city_id":"43996","city_name":"Hinesville","state_id":"3931"},
    {"city_id":"43997","city_name":"Jackson","state_id":"3931"},
    {"city_id":"43998","city_name":"Jonesboro","state_id":"3931"},
    {"city_id":"43999","city_name":"Kennesaw","state_id":"3931"},
    {"city_id":"44000","city_name":"Kingsland","state_id":"3931"},
    {"city_id":"44001","city_name":"LaGrange","state_id":"3931"},
    {"city_id":"44002","city_name":"Lawrenceville","state_id":"3931"},
    {"city_id":"44003","city_name":"Lilburn","state_id":"3931"},
    {"city_id":"44004","city_name":"Lithia Springs","state_id":"3931"},
    {"city_id":"44005","city_name":"Lithonia","state_id":"3931"},
    {"city_id":"44006","city_name":"Locust Grove","state_id":"3931"},
    {"city_id":"44007","city_name":"Loganville","state_id":"3931"},
    {"city_id":"44008","city_name":"Louisville","state_id":"3931"},
    {"city_id":"44009","city_name":"Mableton","state_id":"3931"},
    {"city_id":"44010","city_name":"Macon","state_id":"3931"},
    {"city_id":"44011","city_name":"Madison","state_id":"3931"},
    {"city_id":"44012","city_name":"Marietta","state_id":"3931"},
    {"city_id":"44013","city_name":"Martinez","state_id":"3931"},
    {"city_id":"44014","city_name":"McDonough","state_id":"3931"},
    {"city_id":"44015","city_name":"Milledgeville","state_id":"3931"},
    {"city_id":"44016","city_name":"Monroe","state_id":"3931"},
    {"city_id":"44017","city_name":"Morrow","state_id":"3931"},
    {"city_id":"44018","city_name":"Moultrie","state_id":"3931"},
    {"city_id":"44019","city_name":"Mountain","state_id":"3931"},
    {"city_id":"44020","city_name":"Mountain Park","state_id":"3931"},
    {"city_id":"44021","city_name":"Newnan","state_id":"3931"},
    {"city_id":"44022","city_name":"Norcross","state_id":"3931"},
    {"city_id":"44023","city_name":"North Atlanta","state_id":"3931"},
    {"city_id":"44024","city_name":"North Decatur","state_id":"3931"},
    {"city_id":"44025","city_name":"North Druid Hills","state_id":"3931"},
    {"city_id":"44026","city_name":"Oakwood","state_id":"3931"},
    {"city_id":"44027","city_name":"Panthersville","state_id":"3931"},
    {"city_id":"44028","city_name":"Peachtree City","state_id":"3931"},
    {"city_id":"44029","city_name":"Powder Springs","state_id":"3931"},
    {"city_id":"44030","city_name":"Redan","state_id":"3931"},
    {"city_id":"44031","city_name":"Rex","state_id":"3931"},
    {"city_id":"44032","city_name":"Riverdale","state_id":"3931"},
    {"city_id":"44033","city_name":"Rome","state_id":"3931"},
    {"city_id":"44034","city_name":"Rossville","state_id":"3931"},
    {"city_id":"44035","city_name":"Roswell","state_id":"3931"},
    {"city_id":"44036","city_name":"Saint Marys","state_id":"3931"},
    {"city_id":"44037","city_name":"Saint Simons","state_id":"3931"},
    {"city_id":"44038","city_name":"Sandy Springs","state_id":"3931"},
    {"city_id":"44039","city_name":"Savannah","state_id":"3931"},
    {"city_id":"44040","city_name":"Scottdale","state_id":"3931"},
    {"city_id":"44041","city_name":"Sharpsburg","state_id":"3931"},
    {"city_id":"44042","city_name":"Smyrna","state_id":"3931"},
    {"city_id":"44043","city_name":"Snellville","state_id":"3931"},
    {"city_id":"44044","city_name":"Sparks","state_id":"3931"},
    {"city_id":"44045","city_name":"Statesboro","state_id":"3931"},
    {"city_id":"44046","city_name":"Stockbridge","state_id":"3931"},
    {"city_id":"44047","city_name":"Stone Mountain","state_id":"3931"},
    {"city_id":"44048","city_name":"Suwanee","state_id":"3931"},
    {"city_id":"44049","city_name":"Thomasville","state_id":"3931"},
    {"city_id":"44050","city_name":"Tifton","state_id":"3931"},
    {"city_id":"44051","city_name":"Tucker","state_id":"3931"},
    {"city_id":"44052","city_name":"Tybee Island","state_id":"3931"},
    {"city_id":"44053","city_name":"Union City","state_id":"3931"},
    {"city_id":"44054","city_name":"Valdosta","state_id":"3931"},
    {"city_id":"44055","city_name":"Vidalia","state_id":"3931"},
    {"city_id":"44056","city_name":"Villa Rica","state_id":"3931"},
    {"city_id":"44057","city_name":"Warner Robins","state_id":"3931"},
    {"city_id":"44058","city_name":"Waycross","state_id":"3931"},
    {"city_id":"44059","city_name":"Wilmington Island","state_id":"3931"},
    {"city_id":"44060","city_name":"Winder","state_id":"3931"},
    {"city_id":"44061","city_name":"Woodbine","state_id":"3931"},
    {"city_id":"44062","city_name":"Woodstock","state_id":"3931"},
    {"city_id":"44063","city_name":"Ahuimanu","state_id":"3932"},
    {"city_id":"44064","city_name":"Aiea","state_id":"3932"},
    {"city_id":"44065","city_name":"Aliamanu","state_id":"3932"},
    {"city_id":"44066","city_name":"Ewa Beach","state_id":"3932"},
    {"city_id":"44067","city_name":"Haiku","state_id":"3932"},
    {"city_id":"44068","city_name":"Halawa","state_id":"3932"},
    {"city_id":"44069","city_name":"Hanalei","state_id":"3932"},
    {"city_id":"44070","city_name":"Hilo","state_id":"3932"},
    {"city_id":"44071","city_name":"Holualoa","state_id":"3932"},
    {"city_id":"44072","city_name":"Honolulu","state_id":"3932"},
    {"city_id":"44073","city_name":"Kahului","state_id":"3932"},
    {"city_id":"44074","city_name":"Kailua","state_id":"3932"},
    {"city_id":"44075","city_name":"Kalaheo","state_id":"3932"},
    {"city_id":"44076","city_name":"Kamuela","state_id":"3932"},
    {"city_id":"44077","city_name":"Kaneohe","state_id":"3932"},
    {"city_id":"44078","city_name":"Kaneohe Station","state_id":"3932"},
    {"city_id":"44079","city_name":"Kapaa","state_id":"3932"},
    {"city_id":"44080","city_name":"Kapolei","state_id":"3932"},
    {"city_id":"44081","city_name":"Kihei","state_id":"3932"},
    {"city_id":"44082","city_name":"Kula","state_id":"3932"},
    {"city_id":"44083","city_name":"Lahaina","state_id":"3932"},
    {"city_id":"44084","city_name":"Lanai City","state_id":"3932"},
    {"city_id":"44085","city_name":"Lihue","state_id":"3932"},
    {"city_id":"44086","city_name":"Makaha","state_id":"3932"},
    {"city_id":"44087","city_name":"Makakilo City","state_id":"3932"},
    {"city_id":"44088","city_name":"Makawao","state_id":"3932"},
    {"city_id":"44089","city_name":"Mi-Wuk Village","state_id":"3932"},
    {"city_id":"44090","city_name":"Mililani Town","state_id":"3932"},
    {"city_id":"44091","city_name":"Naalehu","state_id":"3932"},
    {"city_id":"44092","city_name":"Nanakuli","state_id":"3932"},
    {"city_id":"44093","city_name":"Pahoa","state_id":"3932"},
    {"city_id":"44094","city_name":"Pearl City","state_id":"3932"},
    {"city_id":"44095","city_name":"Schofield Barracks","state_id":"3932"},
    {"city_id":"44096","city_name":"Wahiawa","state_id":"3932"},
    {"city_id":"44097","city_name":"Waialua","state_id":"3932"},
    {"city_id":"44098","city_name":"Waianae","state_id":"3932"},
    {"city_id":"44099","city_name":"Wailuku","state_id":"3932"},
    {"city_id":"44100","city_name":"Waimalu","state_id":"3932"},
    {"city_id":"44101","city_name":"Waipahu","state_id":"3932"},
    {"city_id":"44102","city_name":"Waipio","state_id":"3932"},
    {"city_id":"44103","city_name":"Blackfoot","state_id":"3933"},
    {"city_id":"44104","city_name":"Boise","state_id":"3933"},
    {"city_id":"44105","city_name":"Boise City","state_id":"3933"},
    {"city_id":"44106","city_name":"Boulder Hill","state_id":"3933"},
    {"city_id":"44107","city_name":"Burley","state_id":"3933"},
    {"city_id":"44108","city_name":"Caldwell","state_id":"3933"},
    {"city_id":"44109","city_name":"Coeur d'Alene","state_id":"3933"},
    {"city_id":"44110","city_name":"Eagle","state_id":"3933"},
    {"city_id":"44111","city_name":"Garden City","state_id":"3933"},
    {"city_id":"44112","city_name":"Idaho Falls","state_id":"3933"},
    {"city_id":"44113","city_name":"Lewiston","state_id":"3933"},
    {"city_id":"44114","city_name":"Meridian","state_id":"3933"},
    {"city_id":"44115","city_name":"Moscow","state_id":"3933"},
    {"city_id":"44116","city_name":"Mountain Home","state_id":"3933"},
    {"city_id":"44117","city_name":"Nampa","state_id":"3933"},
    {"city_id":"44118","city_name":"Payette","state_id":"3933"},
    {"city_id":"44119","city_name":"Pocatello","state_id":"3933"},
    {"city_id":"44120","city_name":"Post Falls","state_id":"3933"},
    {"city_id":"44121","city_name":"Preston","state_id":"3933"},
    {"city_id":"44122","city_name":"Rexburg","state_id":"3933"},
    {"city_id":"44123","city_name":"Rigby","state_id":"3933"},
    {"city_id":"44124","city_name":"Sandpoint","state_id":"3933"},
    {"city_id":"44125","city_name":"Troy","state_id":"3933"},
    {"city_id":"44126","city_name":"Twin Falls","state_id":"3933"},
    {"city_id":"44127","city_name":"Addison","state_id":"3934"},
    {"city_id":"44128","city_name":"Algonquin","state_id":"3934"},
    {"city_id":"44129","city_name":"Alsip","state_id":"3934"},
    {"city_id":"44130","city_name":"Alton","state_id":"3934"},
    {"city_id":"44131","city_name":"Arlington Heights","state_id":"3934"},
    {"city_id":"44132","city_name":"Aurora","state_id":"3934"},
    {"city_id":"44133","city_name":"Bannockburn","state_id":"3934"},
    {"city_id":"44134","city_name":"Barrington","state_id":"3934"},
    {"city_id":"44135","city_name":"Bartlett","state_id":"3934"},
    {"city_id":"44136","city_name":"Batavia","state_id":"3934"},
    {"city_id":"44137","city_name":"Beach Park","state_id":"3934"},
    {"city_id":"44138","city_name":"Beardstown","state_id":"3934"},
    {"city_id":"44139","city_name":"Bedford Park","state_id":"3934"},
    {"city_id":"44140","city_name":"Belleville","state_id":"3934"},
    {"city_id":"44141","city_name":"Bellwood","state_id":"3934"},
    {"city_id":"44142","city_name":"Belvidere","state_id":"3934"},
    {"city_id":"44143","city_name":"Bensenville","state_id":"3934"},
    {"city_id":"44144","city_name":"Berwyn","state_id":"3934"},
    {"city_id":"44145","city_name":"Bloomingdale","state_id":"3934"},
    {"city_id":"44146","city_name":"Bloomington","state_id":"3934"},
    {"city_id":"44147","city_name":"Blue Island","state_id":"3934"},
    {"city_id":"44148","city_name":"Boling Brook","state_id":"3934"},
    {"city_id":"44149","city_name":"Bolingbrook","state_id":"3934"},
    {"city_id":"44150","city_name":"Bourbonnais","state_id":"3934"},
    {"city_id":"44151","city_name":"Bradley","state_id":"3934"},
    {"city_id":"44152","city_name":"Breese","state_id":"3934"},
    {"city_id":"44153","city_name":"Bridgeview","state_id":"3934"},
    {"city_id":"44154","city_name":"Brimfield","state_id":"3934"},
    {"city_id":"44155","city_name":"Broadview","state_id":"3934"},
    {"city_id":"44156","city_name":"Brookfield","state_id":"3934"},
    {"city_id":"44157","city_name":"Buffalo Grove","state_id":"3934"},
    {"city_id":"44158","city_name":"Burbank","state_id":"3934"},
    {"city_id":"44159","city_name":"Burr Ridge","state_id":"3934"},
    {"city_id":"44160","city_name":"Cahokia","state_id":"3934"},
    {"city_id":"44161","city_name":"Calumet City","state_id":"3934"},
    {"city_id":"44162","city_name":"Canton","state_id":"3934"},
    {"city_id":"44163","city_name":"Carbondale","state_id":"3934"},
    {"city_id":"44164","city_name":"Carlinville","state_id":"3934"},
    {"city_id":"44165","city_name":"Carol Stream","state_id":"3934"},
    {"city_id":"44166","city_name":"Carpentersville","state_id":"3934"},
    {"city_id":"44167","city_name":"Carthage","state_id":"3934"},
    {"city_id":"44168","city_name":"Cary","state_id":"3934"},
    {"city_id":"44169","city_name":"Centralia","state_id":"3934"},
    {"city_id":"44170","city_name":"Champaign","state_id":"3934"},
    {"city_id":"44171","city_name":"Channahon","state_id":"3934"},
    {"city_id":"44172","city_name":"Charleston","state_id":"3934"},
    {"city_id":"44173","city_name":"Chicago","state_id":"3934"},
    {"city_id":"44174","city_name":"Chicago Heights","state_id":"3934"},
    {"city_id":"44175","city_name":"Chicago Ridge","state_id":"3934"},
    {"city_id":"44176","city_name":"Cicero","state_id":"3934"},
    {"city_id":"44177","city_name":"Coal City","state_id":"3934"},
    {"city_id":"44178","city_name":"Collinsville","state_id":"3934"},
    {"city_id":"44179","city_name":"Congerville","state_id":"3934"},
    {"city_id":"44180","city_name":"Country Club Hills","state_id":"3934"},
    {"city_id":"44181","city_name":"Crest Hill","state_id":"3934"},
    {"city_id":"44182","city_name":"Crestwood","state_id":"3934"},
    {"city_id":"44183","city_name":"Crystal Lake","state_id":"3934"},
    {"city_id":"44184","city_name":"Danville","state_id":"3934"},
    {"city_id":"44185","city_name":"Darien","state_id":"3934"},
    {"city_id":"44186","city_name":"DeKalb","state_id":"3934"},
    {"city_id":"44187","city_name":"Decatur","state_id":"3934"},
    {"city_id":"44188","city_name":"Deerfield","state_id":"3934"},
    {"city_id":"44189","city_name":"Des Plaines","state_id":"3934"},
    {"city_id":"44190","city_name":"Dixon","state_id":"3934"},
    {"city_id":"44191","city_name":"Dolton","state_id":"3934"},
    {"city_id":"44192","city_name":"Downers Grove","state_id":"3934"},
    {"city_id":"44193","city_name":"Earlville","state_id":"3934"},
    {"city_id":"44194","city_name":"East Dundee","state_id":"3934"},
    {"city_id":"44195","city_name":"East Moline","state_id":"3934"},
    {"city_id":"44196","city_name":"East Peoria","state_id":"3934"},
    {"city_id":"44197","city_name":"East Saint Louis","state_id":"3934"},
    {"city_id":"44198","city_name":"Edwardsville","state_id":"3934"},
    {"city_id":"44199","city_name":"Effingham","state_id":"3934"},
    {"city_id":"44200","city_name":"Elburn","state_id":"3934"},
    {"city_id":"44201","city_name":"Elgin","state_id":"3934"},
    {"city_id":"44202","city_name":"Elk Grove","state_id":"3934"},
    {"city_id":"44203","city_name":"Elk Grove Village","state_id":"3934"},
    {"city_id":"44204","city_name":"Elmhurst","state_id":"3934"},
    {"city_id":"44205","city_name":"Elmwood Park","state_id":"3934"},
    {"city_id":"44206","city_name":"Evanston","state_id":"3934"},
    {"city_id":"44207","city_name":"Evergreen Park","state_id":"3934"},
    {"city_id":"44208","city_name":"Fairview Heights","state_id":"3934"},
    {"city_id":"44209","city_name":"Flossmoor","state_id":"3934"},
    {"city_id":"44210","city_name":"Forest Park","state_id":"3934"},
    {"city_id":"44211","city_name":"Frankfort","state_id":"3934"},
    {"city_id":"44212","city_name":"Franklin Park","state_id":"3934"},
    {"city_id":"44213","city_name":"Freeport","state_id":"3934"},
    {"city_id":"44214","city_name":"Galena","state_id":"3934"},
    {"city_id":"44215","city_name":"Galesburg","state_id":"3934"},
    {"city_id":"44216","city_name":"Geneva","state_id":"3934"},
    {"city_id":"44217","city_name":"Genoa","state_id":"3934"},
    {"city_id":"44218","city_name":"Glen Carbon","state_id":"3934"},
    {"city_id":"44219","city_name":"Glen Ellyn","state_id":"3934"},
    {"city_id":"44220","city_name":"Glencoe","state_id":"3934"},
    {"city_id":"44221","city_name":"Glendale Heights","state_id":"3934"},
    {"city_id":"44222","city_name":"Glenview","state_id":"3934"},
    {"city_id":"44223","city_name":"Godfrey","state_id":"3934"},
    {"city_id":"44224","city_name":"Goodings Grove","state_id":"3934"},
    {"city_id":"44225","city_name":"Granite City","state_id":"3934"},
    {"city_id":"44226","city_name":"Grayslake","state_id":"3934"},
    {"city_id":"44227","city_name":"Gurnee","state_id":"3934"},
    {"city_id":"44228","city_name":"Hamilton","state_id":"3934"},
    {"city_id":"44229","city_name":"Hampshire","state_id":"3934"},
    {"city_id":"44230","city_name":"Hanover Park","state_id":"3934"},
    {"city_id":"44231","city_name":"Harvard","state_id":"3934"},
    {"city_id":"44232","city_name":"Harvey","state_id":"3934"},
    {"city_id":"44233","city_name":"Hawthorn Woods","state_id":"3934"},
    {"city_id":"44234","city_name":"Hazel Crest","state_id":"3934"},
    {"city_id":"44235","city_name":"Herrin","state_id":"3934"},
    {"city_id":"44236","city_name":"Hickory Hills","state_id":"3934"},
    {"city_id":"44237","city_name":"Highland Park","state_id":"3934"},
    {"city_id":"44238","city_name":"Hinsdale","state_id":"3934"},
    {"city_id":"44239","city_name":"Hoffman Estates","state_id":"3934"},
    {"city_id":"44240","city_name":"Homewood","state_id":"3934"},
    {"city_id":"44241","city_name":"Huntley","state_id":"3934"},
    {"city_id":"44242","city_name":"Illinois City","state_id":"3934"},
    {"city_id":"44243","city_name":"Ingleside","state_id":"3934"},
    {"city_id":"44244","city_name":"Itasca","state_id":"3934"},
    {"city_id":"44245","city_name":"Jacksonville","state_id":"3934"},
    {"city_id":"44246","city_name":"Johnston City","state_id":"3934"},
    {"city_id":"44247","city_name":"Joliet","state_id":"3934"},
    {"city_id":"44248","city_name":"Justice","state_id":"3934"},
    {"city_id":"44249","city_name":"Kankakee","state_id":"3934"},
    {"city_id":"44250","city_name":"Kenilworth","state_id":"3934"},
    {"city_id":"44251","city_name":"Kewanee","state_id":"3934"},
    {"city_id":"44252","city_name":"La Grange","state_id":"3934"},
    {"city_id":"44253","city_name":"La Grange Park","state_id":"3934"},
    {"city_id":"44254","city_name":"La Salle","state_id":"3934"},
    {"city_id":"44255","city_name":"Lake Bluff","state_id":"3934"},
    {"city_id":"44256","city_name":"Lake Forest","state_id":"3934"},
    {"city_id":"44257","city_name":"Lake Zurich","state_id":"3934"},
    {"city_id":"44258","city_name":"Lake in the Hills","state_id":"3934"},
    {"city_id":"44259","city_name":"Lansing","state_id":"3934"},
    {"city_id":"44260","city_name":"Lemont","state_id":"3934"},
    {"city_id":"44261","city_name":"Libertyville","state_id":"3934"},
    {"city_id":"44262","city_name":"Lincoln","state_id":"3934"},
    {"city_id":"44263","city_name":"Lincolnwood","state_id":"3934"},
    {"city_id":"44264","city_name":"Lindenhurst","state_id":"3934"},
    {"city_id":"44265","city_name":"Lindenwood","state_id":"3934"},
    {"city_id":"44266","city_name":"Lisle","state_id":"3934"},
    {"city_id":"44267","city_name":"Lockport","state_id":"3934"},
    {"city_id":"44268","city_name":"Lombard","state_id":"3934"},
    {"city_id":"44269","city_name":"Long Grove","state_id":"3934"},
    {"city_id":"44270","city_name":"Loves Park","state_id":"3934"},
    {"city_id":"44271","city_name":"Lyons","state_id":"3934"},
    {"city_id":"44272","city_name":"MacHenry","state_id":"3934"},
    {"city_id":"44273","city_name":"Machesney Park","state_id":"3934"},
    {"city_id":"44274","city_name":"Macomb","state_id":"3934"},
    {"city_id":"44275","city_name":"Marion","state_id":"3934"},
    {"city_id":"44276","city_name":"Markham","state_id":"3934"},
    {"city_id":"44277","city_name":"Marshall","state_id":"3934"},
    {"city_id":"44278","city_name":"Martinsville","state_id":"3934"},
    {"city_id":"44279","city_name":"Maryville","state_id":"3934"},
    {"city_id":"44280","city_name":"Matteson","state_id":"3934"},
    {"city_id":"44281","city_name":"Mattoon","state_id":"3934"},
    {"city_id":"44282","city_name":"Maywood","state_id":"3934"},
    {"city_id":"44283","city_name":"McHenry","state_id":"3934"},
    {"city_id":"44284","city_name":"Melrose Park","state_id":"3934"},
    {"city_id":"44285","city_name":"Midlothian","state_id":"3934"},
    {"city_id":"44286","city_name":"Milan","state_id":"3934"},
    {"city_id":"44287","city_name":"Minooka","state_id":"3934"},
    {"city_id":"44288","city_name":"Mokena","state_id":"3934"},
    {"city_id":"44289","city_name":"Moline","state_id":"3934"},
    {"city_id":"44290","city_name":"Momence","state_id":"3934"},
    {"city_id":"44291","city_name":"Montgomery","state_id":"3934"},
    {"city_id":"44292","city_name":"Monticello","state_id":"3934"},
    {"city_id":"44293","city_name":"Morris","state_id":"3934"},
    {"city_id":"44294","city_name":"Morton","state_id":"3934"},
    {"city_id":"44295","city_name":"Morton Grove","state_id":"3934"},
    {"city_id":"44296","city_name":"Mossville","state_id":"3934"},
    {"city_id":"44297","city_name":"Mount Prospect","state_id":"3934"},
    {"city_id":"44298","city_name":"Mount Vernon","state_id":"3934"},
    {"city_id":"44299","city_name":"Mount Zion","state_id":"3934"},
    {"city_id":"44300","city_name":"Mundelein","state_id":"3934"},
    {"city_id":"44301","city_name":"Naperville","state_id":"3934"},
    {"city_id":"44302","city_name":"New Lenox","state_id":"3934"},
    {"city_id":"44303","city_name":"Niles","state_id":"3934"},
    {"city_id":"44304","city_name":"Normal","state_id":"3934"},
    {"city_id":"44305","city_name":"Norridge","state_id":"3934"},
    {"city_id":"44306","city_name":"North Aurora","state_id":"3934"},
    {"city_id":"44307","city_name":"North Chicago","state_id":"3934"},
    {"city_id":"44308","city_name":"Northbrook","state_id":"3934"},
    {"city_id":"44309","city_name":"Northfield","state_id":"3934"},
    {"city_id":"44310","city_name":"Northlake","state_id":"3934"},
    {"city_id":"44311","city_name":"O'Fallon","state_id":"3934"},
    {"city_id":"44312","city_name":"Oak Forest","state_id":"3934"},
    {"city_id":"44313","city_name":"Oak Lawn","state_id":"3934"},
    {"city_id":"44314","city_name":"Oak Park","state_id":"3934"},
    {"city_id":"44315","city_name":"Oakbrook","state_id":"3934"},
    {"city_id":"44316","city_name":"Oakwood","state_id":"3934"},
    {"city_id":"44317","city_name":"Olney","state_id":"3934"},
    {"city_id":"44318","city_name":"Orland Park","state_id":"3934"},
    {"city_id":"44319","city_name":"Osco","state_id":"3934"},
    {"city_id":"44320","city_name":"Ottawa","state_id":"3934"},
    {"city_id":"44321","city_name":"Palatine","state_id":"3934"},
    {"city_id":"44322","city_name":"Palos Heights","state_id":"3934"},
    {"city_id":"44323","city_name":"Palos Hills","state_id":"3934"},
    {"city_id":"44324","city_name":"Park Forest","state_id":"3934"},
    {"city_id":"44325","city_name":"Park Ridge","state_id":"3934"},
    {"city_id":"44326","city_name":"Pekin","state_id":"3934"},
    {"city_id":"44327","city_name":"Peoria","state_id":"3934"},
    {"city_id":"44328","city_name":"Peru","state_id":"3934"},
    {"city_id":"44329","city_name":"Plainfield","state_id":"3934"},
    {"city_id":"44330","city_name":"Pontiac","state_id":"3934"},
    {"city_id":"44331","city_name":"Princeton","state_id":"3934"},
    {"city_id":"44332","city_name":"Prospect Heights","state_id":"3934"},
    {"city_id":"44333","city_name":"Quincy","state_id":"3934"},
    {"city_id":"44334","city_name":"Ramsey","state_id":"3934"},
    {"city_id":"44335","city_name":"Rantoul","state_id":"3934"},
    {"city_id":"44336","city_name":"Richmond","state_id":"3934"},
    {"city_id":"44337","city_name":"Richton Park","state_id":"3934"},
    {"city_id":"44338","city_name":"River Forest","state_id":"3934"},
    {"city_id":"44339","city_name":"Riverdale","state_id":"3934"},
    {"city_id":"44340","city_name":"Rochelle","state_id":"3934"},
    {"city_id":"44341","city_name":"Rock Island","state_id":"3934"},
    {"city_id":"44342","city_name":"Rockford","state_id":"3934"},
    {"city_id":"44343","city_name":"Rolling Meadows","state_id":"3934"},
    {"city_id":"44344","city_name":"Romeoville","state_id":"3934"},
    {"city_id":"44345","city_name":"Roscoe","state_id":"3934"},
    {"city_id":"44346","city_name":"Roselle","state_id":"3934"},
    {"city_id":"44347","city_name":"Round Lake Beach","state_id":"3934"},
    {"city_id":"44348","city_name":"Saint Charles","state_id":"3934"},
    {"city_id":"44349","city_name":"Sauget","state_id":"3934"},
    {"city_id":"44350","city_name":"Sauk Village","state_id":"3934"},
    {"city_id":"44351","city_name":"Schaumburg","state_id":"3934"},
    {"city_id":"44352","city_name":"Schiller Park","state_id":"3934"},
    {"city_id":"44353","city_name":"Shumway","state_id":"3934"},
    {"city_id":"44354","city_name":"Skokie","state_id":"3934"},
    {"city_id":"44355","city_name":"South Elgin","state_id":"3934"},
    {"city_id":"44356","city_name":"South Holland","state_id":"3934"},
    {"city_id":"44357","city_name":"Spring Valley","state_id":"3934"},
    {"city_id":"44358","city_name":"Springfield","state_id":"3934"},
    {"city_id":"44359","city_name":"Sterling","state_id":"3934"},
    {"city_id":"44360","city_name":"Streamwood","state_id":"3934"},
    {"city_id":"44361","city_name":"Streator","state_id":"3934"},
    {"city_id":"44362","city_name":"Swansea","state_id":"3934"},
    {"city_id":"44363","city_name":"Sycamore","state_id":"3934"},
    {"city_id":"44364","city_name":"Taylorville","state_id":"3934"},
    {"city_id":"44365","city_name":"Tinley Park","state_id":"3934"},
    {"city_id":"44366","city_name":"Trenton","state_id":"3934"},
    {"city_id":"44367","city_name":"Urbana","state_id":"3934"},
    {"city_id":"44368","city_name":"Ursa","state_id":"3934"},
    {"city_id":"44369","city_name":"Vernon Hills","state_id":"3934"},
    {"city_id":"44370","city_name":"Villa Park","state_id":"3934"},
    {"city_id":"44371","city_name":"Walnut","state_id":"3934"},
    {"city_id":"44372","city_name":"Warrenville","state_id":"3934"},
    {"city_id":"44373","city_name":"Washington","state_id":"3934"},
    {"city_id":"44374","city_name":"Waukegan","state_id":"3934"},
    {"city_id":"44375","city_name":"West Chicago","state_id":"3934"},
    {"city_id":"44376","city_name":"West Dundee","state_id":"3934"},
    {"city_id":"44377","city_name":"Westchester","state_id":"3934"},
    {"city_id":"44378","city_name":"Western Springs","state_id":"3934"},
    {"city_id":"44379","city_name":"Westmont","state_id":"3934"},
    {"city_id":"44380","city_name":"Wheaton","state_id":"3934"},
    {"city_id":"44381","city_name":"Wheeling","state_id":"3934"},
    {"city_id":"44382","city_name":"Willowbrook","state_id":"3934"},
    {"city_id":"44383","city_name":"Wilmette","state_id":"3934"},
    {"city_id":"44384","city_name":"Winnebago","state_id":"3934"},
    {"city_id":"44385","city_name":"Winnetka","state_id":"3934"},
    {"city_id":"44386","city_name":"Wood Dale","state_id":"3934"},
    {"city_id":"44387","city_name":"Wood River","state_id":"3934"},
    {"city_id":"44388","city_name":"Woodridge","state_id":"3934"},
    {"city_id":"44389","city_name":"Woodstock","state_id":"3934"},
    {"city_id":"44390","city_name":"Worth","state_id":"3934"},
    {"city_id":"44391","city_name":"Zion","state_id":"3934"},
    {"city_id":"44392","city_name":"Albion","state_id":"3935"},
    {"city_id":"44393","city_name":"Anderson","state_id":"3935"},
    {"city_id":"44394","city_name":"Angola","state_id":"3935"},
    {"city_id":"44395","city_name":"Auburn","state_id":"3935"},
    {"city_id":"44396","city_name":"Bedford","state_id":"3935"},
    {"city_id":"44397","city_name":"Beech Grove","state_id":"3935"},
    {"city_id":"44398","city_name":"Bloomington","state_id":"3935"},
    {"city_id":"44399","city_name":"Brownsburg","state_id":"3935"},
    {"city_id":"44400","city_name":"Carmel","state_id":"3935"},
    {"city_id":"44401","city_name":"Cedar Lake","state_id":"3935"},
    {"city_id":"44402","city_name":"Chesterton","state_id":"3935"},
    {"city_id":"44403","city_name":"Clarksville","state_id":"3935"},
    {"city_id":"44404","city_name":"Columbus","state_id":"3935"},
    {"city_id":"44405","city_name":"Connersville","state_id":"3935"},
    {"city_id":"44406","city_name":"Crawfordsville","state_id":"3935"},
    {"city_id":"44407","city_name":"Crown Point","state_id":"3935"},
    {"city_id":"44408","city_name":"Dyer","state_id":"3935"},
    {"city_id":"44409","city_name":"East Chicago","state_id":"3935"},
    {"city_id":"44410","city_name":"Elkhart","state_id":"3935"},
    {"city_id":"44411","city_name":"Evansville","state_id":"3935"},
    {"city_id":"44412","city_name":"Fishers","state_id":"3935"},
    {"city_id":"44413","city_name":"Fort Wayne","state_id":"3935"},
    {"city_id":"44414","city_name":"Frankfort","state_id":"3935"},
    {"city_id":"44415","city_name":"Franklin","state_id":"3935"},
    {"city_id":"44416","city_name":"Gary","state_id":"3935"},
    {"city_id":"44417","city_name":"Goshen","state_id":"3935"},
    {"city_id":"44418","city_name":"Gosport","state_id":"3935"},
    {"city_id":"44419","city_name":"Granger","state_id":"3935"},
    {"city_id":"44420","city_name":"Greenfield","state_id":"3935"},
    {"city_id":"44421","city_name":"Greensburg","state_id":"3935"},
    {"city_id":"44422","city_name":"Greenwood","state_id":"3935"},
    {"city_id":"44423","city_name":"Griffith","state_id":"3935"},
    {"city_id":"44424","city_name":"Hammond","state_id":"3935"},
    {"city_id":"44425","city_name":"Helmsburg","state_id":"3935"},
    {"city_id":"44426","city_name":"Highland","state_id":"3935"},
    {"city_id":"44427","city_name":"Hobart","state_id":"3935"},
    {"city_id":"44428","city_name":"Huntington","state_id":"3935"},
    {"city_id":"44429","city_name":"Indianapolis","state_id":"3935"},
    {"city_id":"44430","city_name":"Jasper","state_id":"3935"},
    {"city_id":"44431","city_name":"Jeffersonville","state_id":"3935"},
    {"city_id":"44432","city_name":"Knightstown","state_id":"3935"},
    {"city_id":"44433","city_name":"Kokomo","state_id":"3935"},
    {"city_id":"44434","city_name":"La Porte","state_id":"3935"},
    {"city_id":"44435","city_name":"Lafayette","state_id":"3935"},
    {"city_id":"44436","city_name":"Lake Station","state_id":"3935"},
    {"city_id":"44437","city_name":"Lawrence","state_id":"3935"},
    {"city_id":"44438","city_name":"Lebanon","state_id":"3935"},
    {"city_id":"44439","city_name":"Liberty","state_id":"3935"},
    {"city_id":"44440","city_name":"Logansport","state_id":"3935"},
    {"city_id":"44441","city_name":"Madison","state_id":"3935"},
    {"city_id":"44442","city_name":"Marion","state_id":"3935"},
    {"city_id":"44443","city_name":"Martinsville","state_id":"3935"},
    {"city_id":"44444","city_name":"Merrillville","state_id":"3935"},
    {"city_id":"44445","city_name":"Michigan City","state_id":"3935"},
    {"city_id":"44446","city_name":"Mishawaka","state_id":"3935"},
    {"city_id":"44447","city_name":"Muncie","state_id":"3935"},
    {"city_id":"44448","city_name":"Munster","state_id":"3935"},
    {"city_id":"44449","city_name":"N. Albany","state_id":"3935"},
    {"city_id":"44450","city_name":"Nashville","state_id":"3935"},
    {"city_id":"44451","city_name":"New Albany","state_id":"3935"},
    {"city_id":"44452","city_name":"New Castle","state_id":"3935"},
    {"city_id":"44453","city_name":"New Haven","state_id":"3935"},
    {"city_id":"44454","city_name":"New Trenton","state_id":"3935"},
    {"city_id":"44455","city_name":"Noblesville","state_id":"3935"},
    {"city_id":"44456","city_name":"North Vernon","state_id":"3935"},
    {"city_id":"44457","city_name":"Osceola","state_id":"3935"},
    {"city_id":"44458","city_name":"Peru","state_id":"3935"},
    {"city_id":"44459","city_name":"Plainfield","state_id":"3935"},
    {"city_id":"44460","city_name":"Plymouth","state_id":"3935"},
    {"city_id":"44461","city_name":"Poland","state_id":"3935"},
    {"city_id":"44462","city_name":"Portage","state_id":"3935"},
    {"city_id":"44463","city_name":"Richmond","state_id":"3935"},
    {"city_id":"44464","city_name":"Rising Sun","state_id":"3935"},
    {"city_id":"44465","city_name":"Roanoke","state_id":"3935"},
    {"city_id":"44466","city_name":"Rockport","state_id":"3935"},
    {"city_id":"44467","city_name":"Schererville","state_id":"3935"},
    {"city_id":"44468","city_name":"Scottsburg","state_id":"3935"},
    {"city_id":"44469","city_name":"Seymour","state_id":"3935"},
    {"city_id":"44470","city_name":"Shelbyville","state_id":"3935"},
    {"city_id":"44471","city_name":"South Bend","state_id":"3935"},
    {"city_id":"44472","city_name":"Speedway","state_id":"3935"},
    {"city_id":"44473","city_name":"St. John","state_id":"3935"},
    {"city_id":"44474","city_name":"Terre Haute","state_id":"3935"},
    {"city_id":"44475","city_name":"Thorntown","state_id":"3935"},
    {"city_id":"44476","city_name":"Tippecanoe","state_id":"3935"},
    {"city_id":"44477","city_name":"Troy","state_id":"3935"},
    {"city_id":"44478","city_name":"Valparaiso","state_id":"3935"},
    {"city_id":"44479","city_name":"Vermont","state_id":"3935"},
    {"city_id":"44480","city_name":"Vincennes","state_id":"3935"},
    {"city_id":"44481","city_name":"Wabash","state_id":"3935"},
    {"city_id":"44482","city_name":"Warsaw","state_id":"3935"},
    {"city_id":"44483","city_name":"Washington","state_id":"3935"},
    {"city_id":"44484","city_name":"West Lafayette","state_id":"3935"},
    {"city_id":"44485","city_name":"Williams","state_id":"3935"},
    {"city_id":"44486","city_name":"Altoona","state_id":"3936"},
    {"city_id":"44487","city_name":"Ames","state_id":"3936"},
    {"city_id":"44488","city_name":"Ankeny","state_id":"3936"},
    {"city_id":"44489","city_name":"Bettendorf","state_id":"3936"},
    {"city_id":"44490","city_name":"Boone","state_id":"3936"},
    {"city_id":"44491","city_name":"Burlington","state_id":"3936"},
    {"city_id":"44492","city_name":"Carroll","state_id":"3936"},
    {"city_id":"44493","city_name":"Cedar Falls","state_id":"3936"},
    {"city_id":"44494","city_name":"Cedar Rapids","state_id":"3936"},
    {"city_id":"44495","city_name":"Clarinda","state_id":"3936"},
    {"city_id":"44496","city_name":"Clinton","state_id":"3936"},
    {"city_id":"44497","city_name":"Clive","state_id":"3936"},
    {"city_id":"44498","city_name":"Coralville","state_id":"3936"},
    {"city_id":"44499","city_name":"Council Bluffs","state_id":"3936"},
    {"city_id":"44500","city_name":"Davenport","state_id":"3936"},
    {"city_id":"44501","city_name":"Des Moines","state_id":"3936"},
    {"city_id":"44502","city_name":"Dubuque","state_id":"3936"},
    {"city_id":"44503","city_name":"Eldridge","state_id":"3936"},
    {"city_id":"44504","city_name":"Elkader","state_id":"3936"},
    {"city_id":"44505","city_name":"Essex","state_id":"3936"},
    {"city_id":"44506","city_name":"Fairfield","state_id":"3936"},
    {"city_id":"44507","city_name":"Fayette","state_id":"3936"},
    {"city_id":"44508","city_name":"Fort Dodge","state_id":"3936"},
    {"city_id":"44509","city_name":"Fort Madison","state_id":"3936"},
    {"city_id":"44510","city_name":"Harlan","state_id":"3936"},
    {"city_id":"44511","city_name":"Indianola","state_id":"3936"},
    {"city_id":"44512","city_name":"Iowa City","state_id":"3936"},
    {"city_id":"44513","city_name":"Kalona","state_id":"3936"},
    {"city_id":"44514","city_name":"Keokuk","state_id":"3936"},
    {"city_id":"44515","city_name":"Marion","state_id":"3936"},
    {"city_id":"44516","city_name":"Marshalltown","state_id":"3936"},
    {"city_id":"44517","city_name":"Mason City","state_id":"3936"},
    {"city_id":"44518","city_name":"Muscatine","state_id":"3936"},
    {"city_id":"44519","city_name":"Newton","state_id":"3936"},
    {"city_id":"44520","city_name":"Orange City","state_id":"3936"},
    {"city_id":"44521","city_name":"Oskaloosa","state_id":"3936"},
    {"city_id":"44522","city_name":"Ottumwa","state_id":"3936"},
    {"city_id":"44523","city_name":"Pella","state_id":"3936"},
    {"city_id":"44524","city_name":"Sioux City","state_id":"3936"},
    {"city_id":"44525","city_name":"Spencer","state_id":"3936"},
    {"city_id":"44526","city_name":"Storm Lake","state_id":"3936"},
    {"city_id":"44527","city_name":"Urbandale","state_id":"3936"},
    {"city_id":"44528","city_name":"Waterloo","state_id":"3936"},
    {"city_id":"44529","city_name":"West Des Moines","state_id":"3936"},
    {"city_id":"44530","city_name":"Arkansas City","state_id":"3937"},
    {"city_id":"44531","city_name":"Atchison","state_id":"3937"},
    {"city_id":"44532","city_name":"Coffeyville","state_id":"3937"},
    {"city_id":"44533","city_name":"Derby","state_id":"3937"},
    {"city_id":"44534","city_name":"Dodge City","state_id":"3937"},
    {"city_id":"44535","city_name":"El Dorado","state_id":"3937"},
    {"city_id":"44536","city_name":"Elk City","state_id":"3937"},
    {"city_id":"44537","city_name":"Emporia","state_id":"3937"},
    {"city_id":"44538","city_name":"Fort Riley North","state_id":"3937"},
    {"city_id":"44539","city_name":"Garden City","state_id":"3937"},
    {"city_id":"44540","city_name":"Great Bend","state_id":"3937"},
    {"city_id":"44541","city_name":"Hays","state_id":"3937"},
    {"city_id":"44542","city_name":"Hutchinson","state_id":"3937"},
    {"city_id":"44543","city_name":"Independence","state_id":"3937"},
    {"city_id":"44544","city_name":"Junction City","state_id":"3937"},
    {"city_id":"44545","city_name":"Kansas City","state_id":"3937"},
    {"city_id":"44546","city_name":"Kingman","state_id":"3937"},
    {"city_id":"44547","city_name":"Lawrence","state_id":"3937"},
    {"city_id":"44548","city_name":"Leavenworth","state_id":"3937"},
    {"city_id":"44549","city_name":"Leawood","state_id":"3937"},
    {"city_id":"44550","city_name":"Lenexa","state_id":"3937"},
    {"city_id":"44551","city_name":"Liberal","state_id":"3937"},
    {"city_id":"44552","city_name":"MacPherson","state_id":"3937"},
    {"city_id":"44553","city_name":"Manhattan","state_id":"3937"},
    {"city_id":"44554","city_name":"Merriam","state_id":"3937"},
    {"city_id":"44555","city_name":"Minneapolis","state_id":"3937"},
    {"city_id":"44556","city_name":"Moscow","state_id":"3937"},
    {"city_id":"44557","city_name":"Moundridge","state_id":"3937"},
    {"city_id":"44558","city_name":"Nashville","state_id":"3937"},
    {"city_id":"44559","city_name":"Newton","state_id":"3937"},
    {"city_id":"44560","city_name":"Olathe","state_id":"3937"},
    {"city_id":"44561","city_name":"Ottawa","state_id":"3937"},
    {"city_id":"44562","city_name":"Overland Park","state_id":"3937"},
    {"city_id":"44563","city_name":"Parsons","state_id":"3937"},
    {"city_id":"44564","city_name":"Pittsburg","state_id":"3937"},
    {"city_id":"44565","city_name":"Prairie Village","state_id":"3937"},
    {"city_id":"44566","city_name":"Rose Hill","state_id":"3937"},
    {"city_id":"44567","city_name":"Salina","state_id":"3937"},
    {"city_id":"44568","city_name":"Shawnee","state_id":"3937"},
    {"city_id":"44569","city_name":"Topeka","state_id":"3937"},
    {"city_id":"44570","city_name":"Wichita","state_id":"3937"},
    {"city_id":"44571","city_name":"Winfield","state_id":"3937"},
    {"city_id":"44572","city_name":"tecumseh","state_id":"3937"},
    {"city_id":"44573","city_name":"Albany","state_id":"3938"},
    {"city_id":"44574","city_name":"Ashland","state_id":"3938"},
    {"city_id":"44575","city_name":"Bardstown","state_id":"3938"},
    {"city_id":"44576","city_name":"Berea","state_id":"3938"},
    {"city_id":"44577","city_name":"Bowling Green","state_id":"3938"},
    {"city_id":"44578","city_name":"Campbellsville","state_id":"3938"},
    {"city_id":"44579","city_name":"Catlettsburg","state_id":"3938"},
    {"city_id":"44580","city_name":"Covington","state_id":"3938"},
    {"city_id":"44581","city_name":"Crescent Springs","state_id":"3938"},
    {"city_id":"44582","city_name":"Danville","state_id":"3938"},
    {"city_id":"44583","city_name":"Dawson Springs","state_id":"3938"},
    {"city_id":"44584","city_name":"Eastview","state_id":"3938"},
    {"city_id":"44585","city_name":"Eddyville","state_id":"3938"},
    {"city_id":"44586","city_name":"Elizabethtown","state_id":"3938"},
    {"city_id":"44587","city_name":"Erlanger","state_id":"3938"},
    {"city_id":"44588","city_name":"Evarts","state_id":"3938"},
    {"city_id":"44589","city_name":"Fern Creek","state_id":"3938"},
    {"city_id":"44590","city_name":"Florence","state_id":"3938"},
    {"city_id":"44591","city_name":"Fort Campbell North","state_id":"3938"},
    {"city_id":"44592","city_name":"Fort Knox","state_id":"3938"},
    {"city_id":"44593","city_name":"Fort Mitchell","state_id":"3938"},
    {"city_id":"44594","city_name":"Fort Thomas","state_id":"3938"},
    {"city_id":"44595","city_name":"Frankfort","state_id":"3938"},
    {"city_id":"44596","city_name":"Georgetown","state_id":"3938"},
    {"city_id":"44597","city_name":"Glasgow","state_id":"3938"},
    {"city_id":"44598","city_name":"Grays Knob","state_id":"3938"},
    {"city_id":"44599","city_name":"Henderson","state_id":"3938"},
    {"city_id":"44600","city_name":"Highview","state_id":"3938"},
    {"city_id":"44601","city_name":"Hopkinsville","state_id":"3938"},
    {"city_id":"44602","city_name":"Independence","state_id":"3938"},
    {"city_id":"44603","city_name":"Jeffersontown","state_id":"3938"},
    {"city_id":"44604","city_name":"Lawrenceburg","state_id":"3938"},
    {"city_id":"44605","city_name":"Lebanon","state_id":"3938"},
    {"city_id":"44606","city_name":"Lexington","state_id":"3938"},
    {"city_id":"44607","city_name":"Lexington-Fayette","state_id":"3938"},
    {"city_id":"44608","city_name":"Louisville","state_id":"3938"},
    {"city_id":"44609","city_name":"Madisonville","state_id":"3938"},
    {"city_id":"44610","city_name":"Marion","state_id":"3938"},
    {"city_id":"44611","city_name":"Mayfield","state_id":"3938"},
    {"city_id":"44612","city_name":"Maysville","state_id":"3938"},
    {"city_id":"44613","city_name":"Middlesborough","state_id":"3938"},
    {"city_id":"44614","city_name":"Murray","state_id":"3938"},
    {"city_id":"44615","city_name":"Nebo","state_id":"3938"},
    {"city_id":"44616","city_name":"Newburg","state_id":"3938"},
    {"city_id":"44617","city_name":"Newport","state_id":"3938"},
    {"city_id":"44618","city_name":"Nicholasville","state_id":"3938"},
    {"city_id":"44619","city_name":"Okolona","state_id":"3938"},
    {"city_id":"44620","city_name":"Olive Hill","state_id":"3938"},
    {"city_id":"44621","city_name":"Owensboro","state_id":"3938"},
    {"city_id":"44622","city_name":"Paducah","state_id":"3938"},
    {"city_id":"44623","city_name":"Paris","state_id":"3938"},
    {"city_id":"44624","city_name":"Pikeville","state_id":"3938"},
    {"city_id":"44625","city_name":"Pleasure Ridge Park","state_id":"3938"},
    {"city_id":"44626","city_name":"Queens","state_id":"3938"},
    {"city_id":"44627","city_name":"Radcliff","state_id":"3938"},
    {"city_id":"44628","city_name":"Richmond","state_id":"3938"},
    {"city_id":"44629","city_name":"Saint Dennis","state_id":"3938"},
    {"city_id":"44630","city_name":"Saint Matthews","state_id":"3938"},
    {"city_id":"44631","city_name":"Scottsville","state_id":"3938"},
    {"city_id":"44632","city_name":"Shively","state_id":"3938"},
    {"city_id":"44633","city_name":"Somerset","state_id":"3938"},
    {"city_id":"44634","city_name":"South Shore","state_id":"3938"},
    {"city_id":"44635","city_name":"Tollesboro","state_id":"3938"},
    {"city_id":"44636","city_name":"Valley Station","state_id":"3938"},
    {"city_id":"44637","city_name":"Wallins Creek","state_id":"3938"},
    {"city_id":"44638","city_name":"Walton","state_id":"3938"},
    {"city_id":"44639","city_name":"Winchester","state_id":"3938"},
    {"city_id":"44640","city_name":"Abbeville","state_id":"3939"},
    {"city_id":"44641","city_name":"Alexandria","state_id":"3939"},
    {"city_id":"44642","city_name":"Amite","state_id":"3939"},
    {"city_id":"44643","city_name":"Baker","state_id":"3939"},
    {"city_id":"44644","city_name":"Bastrop","state_id":"3939"},
    {"city_id":"44645","city_name":"Baton Rouge","state_id":"3939"},
    {"city_id":"44646","city_name":"Bayou Cane","state_id":"3939"},
    {"city_id":"44647","city_name":"Bogalusa","state_id":"3939"},
    {"city_id":"44648","city_name":"Bossier City","state_id":"3939"},
    {"city_id":"44649","city_name":"Broussard","state_id":"3939"},
    {"city_id":"44650","city_name":"Calhoun","state_id":"3939"},
    {"city_id":"44651","city_name":"Chalmette","state_id":"3939"},
    {"city_id":"44652","city_name":"Covington","state_id":"3939"},
    {"city_id":"44653","city_name":"Crowley","state_id":"3939"},
    {"city_id":"44654","city_name":"De Ridder","state_id":"3939"},
    {"city_id":"44655","city_name":"Delcambre","state_id":"3939"},
    {"city_id":"44656","city_name":"Denham Springs","state_id":"3939"},
    {"city_id":"44657","city_name":"Estelle","state_id":"3939"},
    {"city_id":"44658","city_name":"Eunice","state_id":"3939"},
    {"city_id":"44659","city_name":"Fort Polk South","state_id":"3939"},
    {"city_id":"44660","city_name":"Franklin","state_id":"3939"},
    {"city_id":"44661","city_name":"French Settlement","state_id":"3939"},
    {"city_id":"44662","city_name":"Garyville","state_id":"3939"},
    {"city_id":"44663","city_name":"Geismar","state_id":"3939"},
    {"city_id":"44664","city_name":"Gretna","state_id":"3939"},
    {"city_id":"44665","city_name":"Hammond","state_id":"3939"},
    {"city_id":"44666","city_name":"Harahan","state_id":"3939"},
    {"city_id":"44667","city_name":"Harvey","state_id":"3939"},
    {"city_id":"44668","city_name":"Houma","state_id":"3939"},
    {"city_id":"44669","city_name":"Independence","state_id":"3939"},
    {"city_id":"44670","city_name":"Jefferson","state_id":"3939"},
    {"city_id":"44671","city_name":"Jennings","state_id":"3939"},
    {"city_id":"44672","city_name":"Kenner","state_id":"3939"},
    {"city_id":"44673","city_name":"Lafayette","state_id":"3939"},
    {"city_id":"44674","city_name":"Lake Charles","state_id":"3939"},
    {"city_id":"44675","city_name":"Laplace","state_id":"3939"},
    {"city_id":"44676","city_name":"Mandeville","state_id":"3939"},
    {"city_id":"44677","city_name":"Marrero","state_id":"3939"},
    {"city_id":"44678","city_name":"Merrydale","state_id":"3939"},
    {"city_id":"44679","city_name":"Metairie","state_id":"3939"},
    {"city_id":"44680","city_name":"Minden","state_id":"3939"},
    {"city_id":"44681","city_name":"Monroe","state_id":"3939"},
    {"city_id":"44682","city_name":"Morgan City","state_id":"3939"},
    {"city_id":"44683","city_name":"Natchitoches","state_id":"3939"},
    {"city_id":"44684","city_name":"New Iberia","state_id":"3939"},
    {"city_id":"44685","city_name":"New Orleans","state_id":"3939"},
    {"city_id":"44686","city_name":"Opelousas","state_id":"3939"},
    {"city_id":"44687","city_name":"Pineville","state_id":"3939"},
    {"city_id":"44688","city_name":"Pioneer","state_id":"3939"},
    {"city_id":"44689","city_name":"Prairieville","state_id":"3939"},
    {"city_id":"44690","city_name":"River Ridge","state_id":"3939"},
    {"city_id":"44691","city_name":"Ruston","state_id":"3939"},
    {"city_id":"44692","city_name":"Saint Amant","state_id":"3939"},
    {"city_id":"44693","city_name":"Saint Martinville","state_id":"3939"},
    {"city_id":"44694","city_name":"Shenandoah","state_id":"3939"},
    {"city_id":"44695","city_name":"Shreveport","state_id":"3939"},
    {"city_id":"44696","city_name":"Slidell","state_id":"3939"},
    {"city_id":"44697","city_name":"Sulphur","state_id":"3939"},
    {"city_id":"44698","city_name":"Terrytown","state_id":"3939"},
    {"city_id":"44699","city_name":"Thibodaux","state_id":"3939"},
    {"city_id":"44700","city_name":"Timberlane","state_id":"3939"},
    {"city_id":"44701","city_name":"Vinton","state_id":"3939"},
    {"city_id":"44702","city_name":"Waggaman","state_id":"3939"},
    {"city_id":"44703","city_name":"West Monroe","state_id":"3939"},
    {"city_id":"44704","city_name":"Westwego","state_id":"3939"},
    {"city_id":"44705","city_name":"Zachary","state_id":"3939"},
    {"city_id":"44706","city_name":"Manchester","state_id":"3940"},
    {"city_id":"44707","city_name":"Washington","state_id":"3940"},
    {"city_id":"44708","city_name":"Auburn","state_id":"3941"},
    {"city_id":"44709","city_name":"Augusta","state_id":"3941"},
    {"city_id":"44710","city_name":"Bangor","state_id":"3941"},
    {"city_id":"44711","city_name":"Bath","state_id":"3941"},
    {"city_id":"44712","city_name":"Biddeford","state_id":"3941"},
    {"city_id":"44713","city_name":"Brunswick","state_id":"3941"},
    {"city_id":"44714","city_name":"Cornish","state_id":"3941"},
    {"city_id":"44715","city_name":"Dover-Foxcroft","state_id":"3941"},
    {"city_id":"44716","city_name":"Ellsworth","state_id":"3941"},
    {"city_id":"44717","city_name":"Etna","state_id":"3941"},
    {"city_id":"44718","city_name":"Freeport","state_id":"3941"},
    {"city_id":"44719","city_name":"Gorham","state_id":"3941"},
    {"city_id":"44720","city_name":"Greene","state_id":"3941"},
    {"city_id":"44721","city_name":"Harmony","state_id":"3941"},
    {"city_id":"44722","city_name":"Lewiston","state_id":"3941"},
    {"city_id":"44723","city_name":"Liberty","state_id":"3941"},
    {"city_id":"44724","city_name":"Limerick","state_id":"3941"},
    {"city_id":"44725","city_name":"Lyman","state_id":"3941"},
    {"city_id":"44726","city_name":"Maine","state_id":"3941"},
    {"city_id":"44727","city_name":"New Gloucester","state_id":"3941"},
    {"city_id":"44728","city_name":"Norridgewock","state_id":"3941"},
    {"city_id":"44729","city_name":"North Yarmouth","state_id":"3941"},
    {"city_id":"44730","city_name":"Old Town","state_id":"3941"},
    {"city_id":"44731","city_name":"Orono","state_id":"3941"},
    {"city_id":"44732","city_name":"Portland","state_id":"3941"},
    {"city_id":"44733","city_name":"Presque Isle","state_id":"3941"},
    {"city_id":"44734","city_name":"Saco","state_id":"3941"},
    {"city_id":"44735","city_name":"Sanford","state_id":"3941"},
    {"city_id":"44736","city_name":"Scarborough","state_id":"3941"},
    {"city_id":"44737","city_name":"South Portland","state_id":"3941"},
    {"city_id":"44738","city_name":"Spruce Head","state_id":"3941"},
    {"city_id":"44739","city_name":"Thomaston","state_id":"3941"},
    {"city_id":"44740","city_name":"Waldoboro","state_id":"3941"},
    {"city_id":"44741","city_name":"Waterville","state_id":"3941"},
    {"city_id":"44742","city_name":"West Buxton","state_id":"3941"},
    {"city_id":"44743","city_name":"Westbrook","state_id":"3941"},
    {"city_id":"44744","city_name":"Whitefield","state_id":"3941"},
    {"city_id":"44745","city_name":"Windham","state_id":"3941"},
    {"city_id":"44746","city_name":"Yarmouth","state_id":"3941"},
    {"city_id":"44747","city_name":"York Harbor","state_id":"3941"},
    {"city_id":"44748","city_name":"stockton springs","state_id":"3941"},
    {"city_id":"44749","city_name":"Aberdeen","state_id":"3942"},
    {"city_id":"44750","city_name":"Accokeek","state_id":"3942"},
    {"city_id":"44751","city_name":"Adelphi","state_id":"3942"},
    {"city_id":"44752","city_name":"Andrews Air Force Base","state_id":"3942"},
    {"city_id":"44753","city_name":"Annapolis","state_id":"3942"},
    {"city_id":"44754","city_name":"Arbutus","state_id":"3942"},
    {"city_id":"44755","city_name":"Arnold","state_id":"3942"},
    {"city_id":"44756","city_name":"Aspen Hill","state_id":"3942"},
    {"city_id":"44757","city_name":"Baltimore","state_id":"3942"},
    {"city_id":"44758","city_name":"Bel Air North","state_id":"3942"},
    {"city_id":"44759","city_name":"Bel Air South","state_id":"3942"},
    {"city_id":"44760","city_name":"Beltsville","state_id":"3942"},
    {"city_id":"44761","city_name":"Berlin","state_id":"3942"},
    {"city_id":"44762","city_name":"Bethesda","state_id":"3942"},
    {"city_id":"44763","city_name":"Bladensburg","state_id":"3942"},
    {"city_id":"44764","city_name":"Boonsboro","state_id":"3942"},
    {"city_id":"44765","city_name":"Bowie","state_id":"3942"},
    {"city_id":"44766","city_name":"Brookeville","state_id":"3942"},
    {"city_id":"44767","city_name":"Brooklandville","state_id":"3942"},
    {"city_id":"44768","city_name":"Brooklyn Park","state_id":"3942"},
    {"city_id":"44769","city_name":"Burtonsville","state_id":"3942"},
    {"city_id":"44770","city_name":"Calverton","state_id":"3942"},
    {"city_id":"44771","city_name":"Cambridge","state_id":"3942"},
    {"city_id":"44772","city_name":"Camp Springs","state_id":"3942"},
    {"city_id":"44773","city_name":"Capitol Heights","state_id":"3942"},
    {"city_id":"44774","city_name":"Carney","state_id":"3942"},
    {"city_id":"44775","city_name":"Catonsville","state_id":"3942"},
    {"city_id":"44776","city_name":"Chestertown","state_id":"3942"},
    {"city_id":"44777","city_name":"Chillum","state_id":"3942"},
    {"city_id":"44778","city_name":"Clarksburg","state_id":"3942"},
    {"city_id":"44779","city_name":"Clarksville","state_id":"3942"},
    {"city_id":"44780","city_name":"Clinton","state_id":"3942"},
    {"city_id":"44781","city_name":"Cockeysville","state_id":"3942"},
    {"city_id":"44782","city_name":"Colesville","state_id":"3942"},
    {"city_id":"44783","city_name":"College Park","state_id":"3942"},
    {"city_id":"44784","city_name":"Columbia","state_id":"3942"},
    {"city_id":"44785","city_name":"Cooksville","state_id":"3942"},
    {"city_id":"44786","city_name":"Coral Hills","state_id":"3942"},
    {"city_id":"44787","city_name":"Crofton","state_id":"3942"},
    {"city_id":"44788","city_name":"Cumberland","state_id":"3942"},
    {"city_id":"44789","city_name":"Damascus","state_id":"3942"},
    {"city_id":"44790","city_name":"Darlington","state_id":"3942"},
    {"city_id":"44791","city_name":"District Heights","state_id":"3942"},
    {"city_id":"44792","city_name":"Dundalk","state_id":"3942"},
    {"city_id":"44793","city_name":"East Riverdale","state_id":"3942"},
    {"city_id":"44794","city_name":"Easton","state_id":"3942"},
    {"city_id":"44795","city_name":"Edgemere","state_id":"3942"},
    {"city_id":"44796","city_name":"Edgewood","state_id":"3942"},
    {"city_id":"44797","city_name":"Eldersburg","state_id":"3942"},
    {"city_id":"44798","city_name":"Elkridge","state_id":"3942"},
    {"city_id":"44799","city_name":"Elkton","state_id":"3942"},
    {"city_id":"44800","city_name":"Ellicott City","state_id":"3942"},
    {"city_id":"44801","city_name":"Essex","state_id":"3942"},
    {"city_id":"44802","city_name":"Fairland","state_id":"3942"},
    {"city_id":"44803","city_name":"Ferndale","state_id":"3942"},
    {"city_id":"44804","city_name":"Forest Hill","state_id":"3942"},
    {"city_id":"44805","city_name":"Forestville","state_id":"3942"},
    {"city_id":"44806","city_name":"Fort Meade","state_id":"3942"},
    {"city_id":"44807","city_name":"Fort Washington","state_id":"3942"},
    {"city_id":"44808","city_name":"Frederick","state_id":"3942"},
    {"city_id":"44809","city_name":"Fredrick","state_id":"3942"},
    {"city_id":"44810","city_name":"Friendly","state_id":"3942"},
    {"city_id":"44811","city_name":"Gaithersburg","state_id":"3942"},
    {"city_id":"44812","city_name":"Germantown","state_id":"3942"},
    {"city_id":"44813","city_name":"Glen Burnie","state_id":"3942"},
    {"city_id":"44814","city_name":"Glenn Dale","state_id":"3942"},
    {"city_id":"44815","city_name":"Greater Landover","state_id":"3942"},
    {"city_id":"44816","city_name":"Greater Upper Marlboro","state_id":"3942"},
    {"city_id":"44817","city_name":"Green Haven","state_id":"3942"},
    {"city_id":"44818","city_name":"Green Valley","state_id":"3942"},
    {"city_id":"44819","city_name":"Greenbelt","state_id":"3942"},
    {"city_id":"44820","city_name":"Hagerstown","state_id":"3942"},
    {"city_id":"44821","city_name":"Hanover","state_id":"3942"},
    {"city_id":"44822","city_name":"Harmans","state_id":"3942"},
    {"city_id":"44823","city_name":"Havre de Grace","state_id":"3942"},
    {"city_id":"44824","city_name":"Hillandale","state_id":"3942"},
    {"city_id":"44825","city_name":"Hillcrest Heights","state_id":"3942"},
    {"city_id":"44826","city_name":"Hunt Valley","state_id":"3942"},
    {"city_id":"44827","city_name":"Hurlock","state_id":"3942"},
    {"city_id":"44828","city_name":"Hyattsville","state_id":"3942"},
    {"city_id":"44829","city_name":"Ijamsville","state_id":"3942"},
    {"city_id":"44830","city_name":"Jefferson","state_id":"3942"},
    {"city_id":"44831","city_name":"Jessup","state_id":"3942"},
    {"city_id":"44832","city_name":"Joppatowne","state_id":"3942"},
    {"city_id":"44833","city_name":"Kettering","state_id":"3942"},
    {"city_id":"44834","city_name":"Lake Shore","state_id":"3942"},
    {"city_id":"44835","city_name":"Langley Park","state_id":"3942"},
    {"city_id":"44836","city_name":"Lanham","state_id":"3942"},
    {"city_id":"44837","city_name":"Lanham-Seabrook","state_id":"3942"},
    {"city_id":"44838","city_name":"Lansdowne-Baltimore Highlands","state_id":"3942"},
    {"city_id":"44839","city_name":"Largo","state_id":"3942"},
    {"city_id":"44840","city_name":"Laurel","state_id":"3942"},
    {"city_id":"44841","city_name":"Lexington Park","state_id":"3942"},
    {"city_id":"44842","city_name":"Lochearn","state_id":"3942"},
    {"city_id":"44843","city_name":"Lutherville-Timonium","state_id":"3942"},
    {"city_id":"44844","city_name":"Marriottsville","state_id":"3942"},
    {"city_id":"44845","city_name":"Maryland City","state_id":"3942"},
    {"city_id":"44846","city_name":"Mays Chapel","state_id":"3942"},
    {"city_id":"44847","city_name":"Middle River","state_id":"3942"},
    {"city_id":"44848","city_name":"Milford Mill","state_id":"3942"},
    {"city_id":"44849","city_name":"Millersville","state_id":"3942"},
    {"city_id":"44850","city_name":"Mitchellville","state_id":"3942"},
    {"city_id":"44851","city_name":"Montgomery Village","state_id":"3942"},
    {"city_id":"44852","city_name":"National Harbor","state_id":"3942"},
    {"city_id":"44853","city_name":"New Carrollton","state_id":"3942"},
    {"city_id":"44854","city_name":"North Bethesda","state_id":"3942"},
    {"city_id":"44855","city_name":"North Laurel","state_id":"3942"},
    {"city_id":"44856","city_name":"North Potomac","state_id":"3942"},
    {"city_id":"44857","city_name":"Odenton","state_id":"3942"},
    {"city_id":"44858","city_name":"Olney","state_id":"3942"},
    {"city_id":"44859","city_name":"Overlea","state_id":"3942"},
    {"city_id":"44860","city_name":"Owings Mills","state_id":"3942"},
    {"city_id":"44861","city_name":"Oxon Hill-Glassmanor","state_id":"3942"},
    {"city_id":"44862","city_name":"Parkville","state_id":"3942"},
    {"city_id":"44863","city_name":"Parole","state_id":"3942"},
    {"city_id":"44864","city_name":"Pasadena","state_id":"3942"},
    {"city_id":"44865","city_name":"Perry Hall","state_id":"3942"},
    {"city_id":"44866","city_name":"Pikesville","state_id":"3942"},
    {"city_id":"44867","city_name":"Poolesville","state_id":"3942"},
    {"city_id":"44868","city_name":"Potomac","state_id":"3942"},
    {"city_id":"44869","city_name":"Randallstown","state_id":"3942"},
    {"city_id":"44870","city_name":"Redland","state_id":"3942"},
    {"city_id":"44871","city_name":"Reisterstown","state_id":"3942"},
    {"city_id":"44872","city_name":"Riviera Beach","state_id":"3942"},
    {"city_id":"44873","city_name":"Rockville","state_id":"3942"},
    {"city_id":"44874","city_name":"Rosaryville","state_id":"3942"},
    {"city_id":"44875","city_name":"Rosedale","state_id":"3942"},
    {"city_id":"44876","city_name":"Rossville","state_id":"3942"},
    {"city_id":"44877","city_name":"Saint Charles","state_id":"3942"},
    {"city_id":"44878","city_name":"Salisbury","state_id":"3942"},
    {"city_id":"44879","city_name":"Sandy Spring","state_id":"3942"},
    {"city_id":"44880","city_name":"Savage Guilford","state_id":"3942"},
    {"city_id":"44881","city_name":"Severn","state_id":"3942"},
    {"city_id":"44882","city_name":"Severna Park","state_id":"3942"},
    {"city_id":"44883","city_name":"Silver Spring","state_id":"3942"},
    {"city_id":"44884","city_name":"Snow Hill","state_id":"3942"},
    {"city_id":"44885","city_name":"South Gate","state_id":"3942"},
    {"city_id":"44886","city_name":"South Laurel","state_id":"3942"},
    {"city_id":"44887","city_name":"Suitland-Silver Hill","state_id":"3942"},
    {"city_id":"44888","city_name":"Takoma Park","state_id":"3942"},
    {"city_id":"44889","city_name":"Temple Hill","state_id":"3942"},
    {"city_id":"44890","city_name":"Thurmont","state_id":"3942"},
    {"city_id":"44891","city_name":"Timonium","state_id":"3942"},
    {"city_id":"44892","city_name":"Towson","state_id":"3942"},
    {"city_id":"44893","city_name":"Upper Marlboro","state_id":"3942"},
    {"city_id":"44894","city_name":"Waldorf","state_id":"3942"},
    {"city_id":"44895","city_name":"Walker Mill","state_id":"3942"},
    {"city_id":"44896","city_name":"Washington Grove","state_id":"3942"},
    {"city_id":"44897","city_name":"Westminster","state_id":"3942"},
    {"city_id":"44898","city_name":"Wheaton-Glenmont","state_id":"3942"},
    {"city_id":"44899","city_name":"White Oak","state_id":"3942"},
    {"city_id":"44900","city_name":"Windsor Mill","state_id":"3942"},
    {"city_id":"44901","city_name":"Woodlawn","state_id":"3942"},
    {"city_id":"44902","city_name":"Abington","state_id":"3943"},
    {"city_id":"44903","city_name":"Acton","state_id":"3943"},
    {"city_id":"44904","city_name":"Agawam","state_id":"3943"},
    {"city_id":"44905","city_name":"Amesbury","state_id":"3943"},
    {"city_id":"44906","city_name":"Amherst Center","state_id":"3943"},
    {"city_id":"44907","city_name":"Arlington","state_id":"3943"},
    {"city_id":"44908","city_name":"Ashland","state_id":"3943"},
    {"city_id":"44909","city_name":"Athol","state_id":"3943"},
    {"city_id":"44910","city_name":"Attleboro","state_id":"3943"},
    {"city_id":"44911","city_name":"Barnstable Town","state_id":"3943"},
    {"city_id":"44912","city_name":"Baxboro","state_id":"3943"},
    {"city_id":"44913","city_name":"Becket","state_id":"3943"},
    {"city_id":"44914","city_name":"Bedford","state_id":"3943"},
    {"city_id":"44915","city_name":"Belmont","state_id":"3943"},
    {"city_id":"44916","city_name":"Beverly","state_id":"3943"},
    {"city_id":"44917","city_name":"Billerica","state_id":"3943"},
    {"city_id":"44918","city_name":"Boston","state_id":"3943"},
    {"city_id":"44919","city_name":"Boylston","state_id":"3943"},
    {"city_id":"44920","city_name":"Braintree","state_id":"3943"},
    {"city_id":"44921","city_name":"Brockton","state_id":"3943"},
    {"city_id":"44922","city_name":"Brookfield","state_id":"3943"},
    {"city_id":"44923","city_name":"Brookline","state_id":"3943"},
    {"city_id":"44924","city_name":"Burlington","state_id":"3943"},
    {"city_id":"44925","city_name":"Cambridge","state_id":"3943"},
    {"city_id":"44926","city_name":"Canton","state_id":"3943"},
    {"city_id":"44927","city_name":"Charlestown","state_id":"3943"},
    {"city_id":"44928","city_name":"Chelmsford","state_id":"3943"},
    {"city_id":"44929","city_name":"Chelsea","state_id":"3943"},
    {"city_id":"44930","city_name":"Chicopee","state_id":"3943"},
    {"city_id":"44931","city_name":"Clinton","state_id":"3943"},
    {"city_id":"44932","city_name":"Concord","state_id":"3943"},
    {"city_id":"44933","city_name":"Danvers","state_id":"3943"},
    {"city_id":"44934","city_name":"Dedham","state_id":"3943"},
    {"city_id":"44935","city_name":"Devens","state_id":"3943"},
    {"city_id":"44936","city_name":"Devenscrest","state_id":"3943"},
    {"city_id":"44937","city_name":"Duxbury","state_id":"3943"},
    {"city_id":"44938","city_name":"Easthampton","state_id":"3943"},
    {"city_id":"44939","city_name":"Everett","state_id":"3943"},
    {"city_id":"44940","city_name":"Fairhaven","state_id":"3943"},
    {"city_id":"44941","city_name":"Fall River","state_id":"3943"},
    {"city_id":"44942","city_name":"Fitchburg","state_id":"3943"},
    {"city_id":"44943","city_name":"Florence","state_id":"3943"},
    {"city_id":"44944","city_name":"Framingham","state_id":"3943"},
    {"city_id":"44945","city_name":"Franklin","state_id":"3943"},
    {"city_id":"44946","city_name":"Gardner","state_id":"3943"},
    {"city_id":"44947","city_name":"Gloucester","state_id":"3943"},
    {"city_id":"44948","city_name":"Great Barrington","state_id":"3943"},
    {"city_id":"44949","city_name":"Greenfield","state_id":"3943"},
    {"city_id":"44950","city_name":"Groton","state_id":"3943"},
    {"city_id":"44951","city_name":"Hadley","state_id":"3943"},
    {"city_id":"44952","city_name":"Harvard","state_id":"3943"},
    {"city_id":"44953","city_name":"Haverhill","state_id":"3943"},
    {"city_id":"44954","city_name":"Hingham","state_id":"3943"},
    {"city_id":"44955","city_name":"Holbrook","state_id":"3943"},
    {"city_id":"44956","city_name":"Holliston","state_id":"3943"},
    {"city_id":"44957","city_name":"Holyoke","state_id":"3943"},
    {"city_id":"44958","city_name":"Hopedale","state_id":"3943"},
    {"city_id":"44959","city_name":"Housatonic","state_id":"3943"},
    {"city_id":"44960","city_name":"Hubbardston","state_id":"3943"},
    {"city_id":"44961","city_name":"Hudson","state_id":"3943"},
    {"city_id":"44962","city_name":"Hull","state_id":"3943"},
    {"city_id":"44963","city_name":"Hyannis","state_id":"3943"},
    {"city_id":"44964","city_name":"Ipswich","state_id":"3943"},
    {"city_id":"44965","city_name":"Jamaica Plain","state_id":"3943"},
    {"city_id":"44966","city_name":"Lawrence","state_id":"3943"},
    {"city_id":"44967","city_name":"Lee","state_id":"3943"},
    {"city_id":"44968","city_name":"Lenox","state_id":"3943"},
    {"city_id":"44969","city_name":"Leominster","state_id":"3943"},
    {"city_id":"44970","city_name":"Lexington","state_id":"3943"},
    {"city_id":"44971","city_name":"Longmeadow","state_id":"3943"},
    {"city_id":"44972","city_name":"Lowell","state_id":"3943"},
    {"city_id":"44973","city_name":"Lynn","state_id":"3943"},
    {"city_id":"44974","city_name":"Lynnfield","state_id":"3943"},
    {"city_id":"44975","city_name":"Malden","state_id":"3943"},
    {"city_id":"44976","city_name":"Manchester","state_id":"3943"},
    {"city_id":"44977","city_name":"Marblehead","state_id":"3943"},
    {"city_id":"44978","city_name":"Marion","state_id":"3943"},
    {"city_id":"44979","city_name":"Marlborough","state_id":"3943"},
    {"city_id":"44980","city_name":"Marshfield","state_id":"3943"},
    {"city_id":"44981","city_name":"Massachusetts","state_id":"3943"},
    {"city_id":"44982","city_name":"Maynard","state_id":"3943"},
    {"city_id":"44983","city_name":"Medfield","state_id":"3943"},
    {"city_id":"44984","city_name":"Medford","state_id":"3943"},
    {"city_id":"44985","city_name":"Medway","state_id":"3943"},
    {"city_id":"44986","city_name":"Melrose","state_id":"3943"},
    {"city_id":"44987","city_name":"Methuen","state_id":"3943"},
    {"city_id":"44988","city_name":"Middleboro","state_id":"3943"},
    {"city_id":"44989","city_name":"Milford","state_id":"3943"},
    {"city_id":"44990","city_name":"Milton","state_id":"3943"},
    {"city_id":"44991","city_name":"Monson","state_id":"3943"},
    {"city_id":"44992","city_name":"Montague","state_id":"3943"},
    {"city_id":"44993","city_name":"Nantucket","state_id":"3943"},
    {"city_id":"44994","city_name":"Natick","state_id":"3943"},
    {"city_id":"44995","city_name":"Needham","state_id":"3943"},
    {"city_id":"44996","city_name":"New Bedford","state_id":"3943"},
    {"city_id":"44997","city_name":"Newburyport","state_id":"3943"},
    {"city_id":"44998","city_name":"Newton","state_id":"3943"},
    {"city_id":"44999","city_name":"North Adams","state_id":"3943"},
    {"city_id":"45000","city_name":"North Andover","state_id":"3943"},
    {"city_id":"45001","city_name":"North Attleborough Center","state_id":"3943"},
    {"city_id":"45002","city_name":"North Easton","state_id":"3943"},
    {"city_id":"45003","city_name":"Northampton","state_id":"3943"},
    {"city_id":"45004","city_name":"Northborough","state_id":"3943"},
    {"city_id":"45005","city_name":"Norwood","state_id":"3943"},
    {"city_id":"45006","city_name":"Orleans","state_id":"3943"},
    {"city_id":"45007","city_name":"Peabody","state_id":"3943"},
    {"city_id":"45008","city_name":"Pepperell","state_id":"3943"},
    {"city_id":"45009","city_name":"Pittsfield","state_id":"3943"},
    {"city_id":"45010","city_name":"Plainfield","state_id":"3943"},
    {"city_id":"45011","city_name":"Plymouth","state_id":"3943"},
    {"city_id":"45012","city_name":"Provincetown","state_id":"3943"},
    {"city_id":"45013","city_name":"Quincy","state_id":"3943"},
    {"city_id":"45014","city_name":"Randolph","state_id":"3943"},
    {"city_id":"45015","city_name":"Reading","state_id":"3943"},
    {"city_id":"45016","city_name":"Rehoboth","state_id":"3943"},
    {"city_id":"45017","city_name":"Revere","state_id":"3943"},
    {"city_id":"45018","city_name":"Rockland","state_id":"3943"},
    {"city_id":"45019","city_name":"Rockport","state_id":"3943"},
    {"city_id":"45020","city_name":"Roslindale","state_id":"3943"},
    {"city_id":"45021","city_name":"Salem","state_id":"3943"},
    {"city_id":"45022","city_name":"Saugus","state_id":"3943"},
    {"city_id":"45023","city_name":"Scituate","state_id":"3943"},
    {"city_id":"45024","city_name":"Seekonk","state_id":"3943"},
    {"city_id":"45025","city_name":"Shelburne Falls","state_id":"3943"},
    {"city_id":"45026","city_name":"Sherborn","state_id":"3943"},
    {"city_id":"45027","city_name":"Shrewsbury","state_id":"3943"},
    {"city_id":"45028","city_name":"Somerset","state_id":"3943"},
    {"city_id":"45029","city_name":"Somerville","state_id":"3943"},
    {"city_id":"45030","city_name":"South Boston","state_id":"3943"},
    {"city_id":"45031","city_name":"South Deerfield","state_id":"3943"},
    {"city_id":"45032","city_name":"South Hadley","state_id":"3943"},
    {"city_id":"45033","city_name":"South Lee","state_id":"3943"},
    {"city_id":"45034","city_name":"South Yarmouth","state_id":"3943"},
    {"city_id":"45035","city_name":"Southborough","state_id":"3943"},
    {"city_id":"45036","city_name":"Southbridge","state_id":"3943"},
    {"city_id":"45037","city_name":"Southwick","state_id":"3943"},
    {"city_id":"45038","city_name":"Springfield","state_id":"3943"},
    {"city_id":"45039","city_name":"Stoneham","state_id":"3943"},
    {"city_id":"45040","city_name":"Sturbridge","state_id":"3943"},
    {"city_id":"45041","city_name":"Swampscott","state_id":"3943"},
    {"city_id":"45042","city_name":"Swansea","state_id":"3943"},
    {"city_id":"45043","city_name":"Taunton","state_id":"3943"},
    {"city_id":"45044","city_name":"Tewksbury","state_id":"3943"},
    {"city_id":"45045","city_name":"Three Rivers","state_id":"3943"},
    {"city_id":"45046","city_name":"Truro","state_id":"3943"},
    {"city_id":"45047","city_name":"Upton","state_id":"3943"},
    {"city_id":"45048","city_name":"Vineyard Haven","state_id":"3943"},
    {"city_id":"45049","city_name":"Wakefield","state_id":"3943"},
    {"city_id":"45050","city_name":"Waltham","state_id":"3943"},
    {"city_id":"45051","city_name":"Ware","state_id":"3943"},
    {"city_id":"45052","city_name":"Wareham","state_id":"3943"},
    {"city_id":"45053","city_name":"Watertown","state_id":"3943"},
    {"city_id":"45054","city_name":"Wayland","state_id":"3943"},
    {"city_id":"45055","city_name":"Webster","state_id":"3943"},
    {"city_id":"45056","city_name":"Wellesley","state_id":"3943"},
    {"city_id":"45057","city_name":"Wellesley Hills","state_id":"3943"},
    {"city_id":"45058","city_name":"West Concord","state_id":"3943"},
    {"city_id":"45059","city_name":"West Roxbury","state_id":"3943"},
    {"city_id":"45060","city_name":"West Springfield","state_id":"3943"},
    {"city_id":"45061","city_name":"West Yarmouth","state_id":"3943"},
    {"city_id":"45062","city_name":"Westborough","state_id":"3943"},
    {"city_id":"45063","city_name":"Westfield","state_id":"3943"},
    {"city_id":"45064","city_name":"Westford","state_id":"3943"},
    {"city_id":"45065","city_name":"Weston","state_id":"3943"},
    {"city_id":"45066","city_name":"Weymouth","state_id":"3943"},
    {"city_id":"45067","city_name":"Wilbraham","state_id":"3943"},
    {"city_id":"45068","city_name":"Wilmington","state_id":"3943"},
    {"city_id":"45069","city_name":"Winchester","state_id":"3943"},
    {"city_id":"45070","city_name":"Winthrop","state_id":"3943"},
    {"city_id":"45071","city_name":"Woburn","state_id":"3943"},
    {"city_id":"45072","city_name":"Worcester","state_id":"3943"},
    {"city_id":"45073","city_name":"Yarmouthport","state_id":"3943"},
    {"city_id":"45074","city_name":"Adrian","state_id":"3945"},
    {"city_id":"45075","city_name":"Albion","state_id":"3945"},
    {"city_id":"45076","city_name":"Allegan","state_id":"3945"},
    {"city_id":"45077","city_name":"Allen Park","state_id":"3945"},
    {"city_id":"45078","city_name":"Alma","state_id":"3945"},
    {"city_id":"45079","city_name":"Alpena","state_id":"3945"},
    {"city_id":"45080","city_name":"Ann Arbor","state_id":"3945"},
    {"city_id":"45081","city_name":"Attica","state_id":"3945"},
    {"city_id":"45082","city_name":"Auburn Hills","state_id":"3945"},
    {"city_id":"45083","city_name":"Battle Creek","state_id":"3945"},
    {"city_id":"45084","city_name":"Bay City","state_id":"3945"},
    {"city_id":"45085","city_name":"Beecher","state_id":"3945"},
    {"city_id":"45086","city_name":"Belleville","state_id":"3945"},
    {"city_id":"45087","city_name":"Benton Harbor","state_id":"3945"},
    {"city_id":"45088","city_name":"Berkley","state_id":"3945"},
    {"city_id":"45089","city_name":"Beverly Hills","state_id":"3945"},
    {"city_id":"45090","city_name":"Big Rapids","state_id":"3945"},
    {"city_id":"45091","city_name":"Birmingham","state_id":"3945"},
    {"city_id":"45092","city_name":"Bloomfield Hills","state_id":"3945"},
    {"city_id":"45093","city_name":"Bloomfield Township","state_id":"3945"},
    {"city_id":"45094","city_name":"Boyne City","state_id":"3945"},
    {"city_id":"45095","city_name":"Brighton","state_id":"3945"},
    {"city_id":"45096","city_name":"Burt","state_id":"3945"},
    {"city_id":"45097","city_name":"Burton","state_id":"3945"},
    {"city_id":"45098","city_name":"Cadillac","state_id":"3945"},
    {"city_id":"45099","city_name":"Canton","state_id":"3945"},
    {"city_id":"45100","city_name":"Charlotte","state_id":"3945"},
    {"city_id":"45101","city_name":"Chesterfield","state_id":"3945"},
    {"city_id":"45102","city_name":"Clarkston","state_id":"3945"},
    {"city_id":"45103","city_name":"Clawson","state_id":"3945"},
    {"city_id":"45104","city_name":"Clinton","state_id":"3945"},
    {"city_id":"45105","city_name":"Commerce","state_id":"3945"},
    {"city_id":"45106","city_name":"Comstock Park","state_id":"3945"},
    {"city_id":"45107","city_name":"Coopersville","state_id":"3945"},
    {"city_id":"45108","city_name":"Cornell","state_id":"3945"},
    {"city_id":"45109","city_name":"Cutlerville","state_id":"3945"},
    {"city_id":"45110","city_name":"Davisburg","state_id":"3945"},
    {"city_id":"45111","city_name":"Dearborn","state_id":"3945"},
    {"city_id":"45112","city_name":"Dearborn Heights","state_id":"3945"},
    {"city_id":"45113","city_name":"Delton","state_id":"3945"},
    {"city_id":"45114","city_name":"Detroit","state_id":"3945"},
    {"city_id":"45115","city_name":"Dexter","state_id":"3945"},
    {"city_id":"45116","city_name":"Dowagiac","state_id":"3945"},
    {"city_id":"45117","city_name":"East Grand Rapids","state_id":"3945"},
    {"city_id":"45118","city_name":"East Lansing","state_id":"3945"},
    {"city_id":"45119","city_name":"Eastpointe","state_id":"3945"},
    {"city_id":"45120","city_name":"Ecorse","state_id":"3945"},
    {"city_id":"45121","city_name":"Escanaba","state_id":"3945"},
    {"city_id":"45122","city_name":"Evart","state_id":"3945"},
    {"city_id":"45123","city_name":"Fair Haven","state_id":"3945"},
    {"city_id":"45124","city_name":"Fairgrove","state_id":"3945"},
    {"city_id":"45125","city_name":"Farmington","state_id":"3945"},
    {"city_id":"45126","city_name":"Farmington Hills","state_id":"3945"},
    {"city_id":"45127","city_name":"Fenton","state_id":"3945"},
    {"city_id":"45128","city_name":"Ferndale","state_id":"3945"},
    {"city_id":"45129","city_name":"Flint","state_id":"3945"},
    {"city_id":"45130","city_name":"Forest Hills","state_id":"3945"},
    {"city_id":"45131","city_name":"Fowlerville","state_id":"3945"},
    {"city_id":"45132","city_name":"Frankenmuth","state_id":"3945"},
    {"city_id":"45133","city_name":"Fraser","state_id":"3945"},
    {"city_id":"45134","city_name":"Fremont","state_id":"3945"},
    {"city_id":"45135","city_name":"Fruitport","state_id":"3945"},
    {"city_id":"45136","city_name":"Garden City","state_id":"3945"},
    {"city_id":"45137","city_name":"Goodrich","state_id":"3945"},
    {"city_id":"45138","city_name":"Grand Blanc","state_id":"3945"},
    {"city_id":"45139","city_name":"Grand Haven","state_id":"3945"},
    {"city_id":"45140","city_name":"Grand Rapids","state_id":"3945"},
    {"city_id":"45141","city_name":"Grandville","state_id":"3945"},
    {"city_id":"45142","city_name":"Grosse Ile","state_id":"3945"},
    {"city_id":"45143","city_name":"Grosse Pointe Farms","state_id":"3945"},
    {"city_id":"45144","city_name":"Grosse Pointe Park","state_id":"3945"},
    {"city_id":"45145","city_name":"Grosse Pointe Woods","state_id":"3945"},
    {"city_id":"45146","city_name":"Gwinn","state_id":"3945"},
    {"city_id":"45147","city_name":"Hamtramck","state_id":"3945"},
    {"city_id":"45148","city_name":"Hancock","state_id":"3945"},
    {"city_id":"45149","city_name":"Harper Woods","state_id":"3945"},
    {"city_id":"45150","city_name":"Harrison","state_id":"3945"},
    {"city_id":"45151","city_name":"Haslett","state_id":"3945"},
    {"city_id":"45152","city_name":"Hazel Park","state_id":"3945"},
    {"city_id":"45153","city_name":"Highland Park","state_id":"3945"},
    {"city_id":"45154","city_name":"Holland","state_id":"3945"},
    {"city_id":"45155","city_name":"Holly","state_id":"3945"},
    {"city_id":"45156","city_name":"Holt","state_id":"3945"},
    {"city_id":"45157","city_name":"Houghton","state_id":"3945"},
    {"city_id":"45158","city_name":"Hudsonville","state_id":"3945"},
    {"city_id":"45159","city_name":"Huntington Woods","state_id":"3945"},
    {"city_id":"45160","city_name":"Imlay","state_id":"3945"},
    {"city_id":"45161","city_name":"Inkster","state_id":"3945"},
    {"city_id":"45162","city_name":"Jackon","state_id":"3945"},
    {"city_id":"45163","city_name":"Jackson","state_id":"3945"},
    {"city_id":"45164","city_name":"Jenison","state_id":"3945"},
    {"city_id":"45165","city_name":"Kalamazoo","state_id":"3945"},
    {"city_id":"45166","city_name":"Kalkaska","state_id":"3945"},
    {"city_id":"45167","city_name":"Kentwood","state_id":"3945"},
    {"city_id":"45168","city_name":"Kingsford","state_id":"3945"},
    {"city_id":"45169","city_name":"Lansing","state_id":"3945"},
    {"city_id":"45170","city_name":"Lapeer","state_id":"3945"},
    {"city_id":"45171","city_name":"Lincoln Park","state_id":"3945"},
    {"city_id":"45172","city_name":"Litchfield","state_id":"3945"},
    {"city_id":"45173","city_name":"Livonia","state_id":"3945"},
    {"city_id":"45174","city_name":"Ludington","state_id":"3945"},
    {"city_id":"45175","city_name":"Macomb","state_id":"3945"},
    {"city_id":"45176","city_name":"Madison Heights","state_id":"3945"},
    {"city_id":"45177","city_name":"Manistee","state_id":"3945"},
    {"city_id":"45178","city_name":"Marquette","state_id":"3945"},
    {"city_id":"45179","city_name":"Marysville","state_id":"3945"},
    {"city_id":"45180","city_name":"Melvindale","state_id":"3945"},
    {"city_id":"45181","city_name":"Midland","state_id":"3945"},
    {"city_id":"45182","city_name":"Monroe","state_id":"3945"},
    {"city_id":"45183","city_name":"Mount Clemens","state_id":"3945"},
    {"city_id":"45184","city_name":"Mount Morris","state_id":"3945"},
    {"city_id":"45185","city_name":"Mount Pleasant","state_id":"3945"},
    {"city_id":"45186","city_name":"Mt. Pleasant","state_id":"3945"},
    {"city_id":"45187","city_name":"Muskegon","state_id":"3945"},
    {"city_id":"45188","city_name":"Muskegon Heights","state_id":"3945"},
    {"city_id":"45189","city_name":"New Hudson","state_id":"3945"},
    {"city_id":"45190","city_name":"Newaygo","state_id":"3945"},
    {"city_id":"45191","city_name":"Niles","state_id":"3945"},
    {"city_id":"45192","city_name":"Northview","state_id":"3945"},
    {"city_id":"45193","city_name":"Northville","state_id":"3945"},
    {"city_id":"45194","city_name":"Norton Shores","state_id":"3945"},
    {"city_id":"45195","city_name":"Novi","state_id":"3945"},
    {"city_id":"45196","city_name":"Oak Park","state_id":"3945"},
    {"city_id":"45197","city_name":"Okemos","state_id":"3945"},
    {"city_id":"45198","city_name":"Oscoda","state_id":"3945"},
    {"city_id":"45199","city_name":"Owosso","state_id":"3945"},
    {"city_id":"45200","city_name":"Oxford","state_id":"3945"},
    {"city_id":"45201","city_name":"Petoskey","state_id":"3945"},
    {"city_id":"45202","city_name":"Pinckney","state_id":"3945"},
    {"city_id":"45203","city_name":"Plymouth Township","state_id":"3945"},
    {"city_id":"45204","city_name":"Pontiac","state_id":"3945"},
    {"city_id":"45205","city_name":"Port Huron","state_id":"3945"},
    {"city_id":"45206","city_name":"Portage","state_id":"3945"},
    {"city_id":"45207","city_name":"Redford","state_id":"3945"},
    {"city_id":"45208","city_name":"Reese","state_id":"3945"},
    {"city_id":"45209","city_name":"River Rouge","state_id":"3945"},
    {"city_id":"45210","city_name":"Riverview","state_id":"3945"},
    {"city_id":"45211","city_name":"Rochester Hills","state_id":"3945"},
    {"city_id":"45212","city_name":"Rockford","state_id":"3945"},
    {"city_id":"45213","city_name":"Romeo","state_id":"3945"},
    {"city_id":"45214","city_name":"Romulus","state_id":"3945"},
    {"city_id":"45215","city_name":"Roseville","state_id":"3945"},
    {"city_id":"45216","city_name":"Royal Oak","state_id":"3945"},
    {"city_id":"45217","city_name":"Saginaw","state_id":"3945"},
    {"city_id":"45218","city_name":"Saginaw Township North","state_id":"3945"},
    {"city_id":"45219","city_name":"Saginaw Township South","state_id":"3945"},
    {"city_id":"45220","city_name":"Saint Clair Shores","state_id":"3945"},
    {"city_id":"45221","city_name":"Saint Louis","state_id":"3945"},
    {"city_id":"45222","city_name":"Saline","state_id":"3945"},
    {"city_id":"45223","city_name":"Saugatuck","state_id":"3945"},
    {"city_id":"45224","city_name":"Sault Sainte Marie","state_id":"3945"},
    {"city_id":"45225","city_name":"Schoolcraft","state_id":"3945"},
    {"city_id":"45226","city_name":"Shelby","state_id":"3945"},
    {"city_id":"45227","city_name":"Southfield","state_id":"3945"},
    {"city_id":"45228","city_name":"Southgate","state_id":"3945"},
    {"city_id":"45229","city_name":"Sterling Heights","state_id":"3945"},
    {"city_id":"45230","city_name":"Sturgis","state_id":"3945"},
    {"city_id":"45231","city_name":"Taylor","state_id":"3945"},
    {"city_id":"45232","city_name":"Traverse City","state_id":"3945"},
    {"city_id":"45233","city_name":"Trenton","state_id":"3945"},
    {"city_id":"45234","city_name":"Troy","state_id":"3945"},
    {"city_id":"45235","city_name":"Walker","state_id":"3945"},
    {"city_id":"45236","city_name":"Walled Lake","state_id":"3945"},
    {"city_id":"45237","city_name":"Warren","state_id":"3945"},
    {"city_id":"45238","city_name":"Waterford","state_id":"3945"},
    {"city_id":"45239","city_name":"Waverly","state_id":"3945"},
    {"city_id":"45240","city_name":"Wayne","state_id":"3945"},
    {"city_id":"45241","city_name":"West Bloomfield Township","state_id":"3945"},
    {"city_id":"45242","city_name":"Westland","state_id":"3945"},
    {"city_id":"45243","city_name":"White Lake","state_id":"3945"},
    {"city_id":"45244","city_name":"Whitmore Lake","state_id":"3945"},
    {"city_id":"45245","city_name":"Williamston","state_id":"3945"},
    {"city_id":"45246","city_name":"Wixom","state_id":"3945"},
    {"city_id":"45247","city_name":"Woodhaven","state_id":"3945"},
    {"city_id":"45248","city_name":"Wyandotte","state_id":"3945"},
    {"city_id":"45249","city_name":"Wyoming","state_id":"3945"},
    {"city_id":"45250","city_name":"Ypsilanti","state_id":"3945"},
    {"city_id":"45251","city_name":"Albert Lea","state_id":"3946"},
    {"city_id":"45252","city_name":"Alger","state_id":"3946"},
    {"city_id":"45253","city_name":"Andover","state_id":"3946"},
    {"city_id":"45254","city_name":"Annandale","state_id":"3946"},
    {"city_id":"45255","city_name":"Anoka","state_id":"3946"},
    {"city_id":"45256","city_name":"Apple Valley","state_id":"3946"},
    {"city_id":"45257","city_name":"Austin","state_id":"3946"},
    {"city_id":"45258","city_name":"Baxter","state_id":"3946"},
    {"city_id":"45259","city_name":"Bemidji","state_id":"3946"},
    {"city_id":"45260","city_name":"Blaine","state_id":"3946"},
    {"city_id":"45261","city_name":"Blomkest","state_id":"3946"},
    {"city_id":"45262","city_name":"Bloomington","state_id":"3946"},
    {"city_id":"45263","city_name":"Blue Earth","state_id":"3946"},
    {"city_id":"45264","city_name":"Brainerd","state_id":"3946"},
    {"city_id":"45265","city_name":"Brooklyn Center","state_id":"3946"},
    {"city_id":"45266","city_name":"Brooklyn Park","state_id":"3946"},
    {"city_id":"45267","city_name":"Burnsville","state_id":"3946"},
    {"city_id":"45268","city_name":"Champlin","state_id":"3946"},
    {"city_id":"45269","city_name":"Chanhassen","state_id":"3946"},
    {"city_id":"45270","city_name":"Chaska","state_id":"3946"},
    {"city_id":"45271","city_name":"Chatfield","state_id":"3946"},
    {"city_id":"45272","city_name":"Circle Pines","state_id":"3946"},
    {"city_id":"45273","city_name":"Cloquet","state_id":"3946"},
    {"city_id":"45274","city_name":"Cokato","state_id":"3946"},
    {"city_id":"45275","city_name":"Columbia Heights","state_id":"3946"},
    {"city_id":"45276","city_name":"Coon Rapids","state_id":"3946"},
    {"city_id":"45277","city_name":"Cottage Grove","state_id":"3946"},
    {"city_id":"45278","city_name":"Crystal","state_id":"3946"},
    {"city_id":"45279","city_name":"Duluth","state_id":"3946"},
    {"city_id":"45280","city_name":"Eagan","state_id":"3946"},
    {"city_id":"45281","city_name":"East Bethel","state_id":"3946"},
    {"city_id":"45282","city_name":"Eden Prairie","state_id":"3946"},
    {"city_id":"45283","city_name":"Edina","state_id":"3946"},
    {"city_id":"45284","city_name":"Elk River","state_id":"3946"},
    {"city_id":"45285","city_name":"Ely","state_id":"3946"},
    {"city_id":"45286","city_name":"Fairmont","state_id":"3946"},
    {"city_id":"45287","city_name":"Faribault","state_id":"3946"},
    {"city_id":"45288","city_name":"Farmington","state_id":"3946"},
    {"city_id":"45289","city_name":"Fergus Falls","state_id":"3946"},
    {"city_id":"45290","city_name":"Frazee","state_id":"3946"},
    {"city_id":"45291","city_name":"Fridley","state_id":"3946"},
    {"city_id":"45292","city_name":"Golden Valley","state_id":"3946"},
    {"city_id":"45293","city_name":"Grand Rapids","state_id":"3946"},
    {"city_id":"45294","city_name":"Ham Lake","state_id":"3946"},
    {"city_id":"45295","city_name":"Hamel","state_id":"3946"},
    {"city_id":"45296","city_name":"Hastings","state_id":"3946"},
    {"city_id":"45297","city_name":"Hibbing","state_id":"3946"},
    {"city_id":"45298","city_name":"Hopkins","state_id":"3946"},
    {"city_id":"45299","city_name":"Houston","state_id":"3946"},
    {"city_id":"45300","city_name":"Hutchinson","state_id":"3946"},
    {"city_id":"45301","city_name":"Inver Grove Heights","state_id":"3946"},
    {"city_id":"45302","city_name":"Isanti","state_id":"3946"},
    {"city_id":"45303","city_name":"LaCrescent","state_id":"3946"},
    {"city_id":"45304","city_name":"Lakeville","state_id":"3946"},
    {"city_id":"45305","city_name":"Le Sueur","state_id":"3946"},
    {"city_id":"45306","city_name":"Lino Lakes","state_id":"3946"},
    {"city_id":"45307","city_name":"Litchfield","state_id":"3946"},
    {"city_id":"45308","city_name":"Mankato","state_id":"3946"},
    {"city_id":"45309","city_name":"Maple Grove","state_id":"3946"},
    {"city_id":"45310","city_name":"Maplewood","state_id":"3946"},
    {"city_id":"45311","city_name":"Marshall","state_id":"3946"},
    {"city_id":"45312","city_name":"Mendota Heights","state_id":"3946"},
    {"city_id":"45313","city_name":"Minneapolis","state_id":"3946"},
    {"city_id":"45314","city_name":"Minnetonka","state_id":"3946"},
    {"city_id":"45315","city_name":"Moorhead","state_id":"3946"},
    {"city_id":"45316","city_name":"Mounds View","state_id":"3946"},
    {"city_id":"45317","city_name":"Nelson","state_id":"3946"},
    {"city_id":"45318","city_name":"New Brighton","state_id":"3946"},
    {"city_id":"45319","city_name":"New Hope","state_id":"3946"},
    {"city_id":"45320","city_name":"New Ulm","state_id":"3946"},
    {"city_id":"45321","city_name":"North Mankato","state_id":"3946"},
    {"city_id":"45322","city_name":"North Saint Paul","state_id":"3946"},
    {"city_id":"45323","city_name":"Northfield","state_id":"3946"},
    {"city_id":"45324","city_name":"Oakdale","state_id":"3946"},
    {"city_id":"45325","city_name":"Onamia","state_id":"3946"},
    {"city_id":"45326","city_name":"Owatonna","state_id":"3946"},
    {"city_id":"45327","city_name":"Pequot Lakes","state_id":"3946"},
    {"city_id":"45328","city_name":"Plymouth","state_id":"3946"},
    {"city_id":"45329","city_name":"Prior Lake","state_id":"3946"},
    {"city_id":"45330","city_name":"Ramsey","state_id":"3946"},
    {"city_id":"45331","city_name":"Red Wing","state_id":"3946"},
    {"city_id":"45332","city_name":"Renville","state_id":"3946"},
    {"city_id":"45333","city_name":"Richfield","state_id":"3946"},
    {"city_id":"45334","city_name":"Robbinsdale","state_id":"3946"},
    {"city_id":"45335","city_name":"Rochester","state_id":"3946"},
    {"city_id":"45336","city_name":"Rosemount","state_id":"3946"},
    {"city_id":"45337","city_name":"Roseville","state_id":"3946"},
    {"city_id":"45338","city_name":"Royalton","state_id":"3946"},
    {"city_id":"45339","city_name":"Saint Cloud","state_id":"3946"},
    {"city_id":"45340","city_name":"Saint Louis Park","state_id":"3946"},
    {"city_id":"45341","city_name":"Saint Michael","state_id":"3946"},
    {"city_id":"45342","city_name":"Saint Paul","state_id":"3946"},
    {"city_id":"45343","city_name":"Saint Peter","state_id":"3946"},
    {"city_id":"45344","city_name":"Sauk Rapids","state_id":"3946"},
    {"city_id":"45345","city_name":"Savage","state_id":"3946"},
    {"city_id":"45346","city_name":"Shakopee","state_id":"3946"},
    {"city_id":"45347","city_name":"Shoreview","state_id":"3946"},
    {"city_id":"45348","city_name":"South Saint Paul","state_id":"3946"},
    {"city_id":"45349","city_name":"St. Paul","state_id":"3946"},
    {"city_id":"45350","city_name":"Stewartville","state_id":"3946"},
    {"city_id":"45351","city_name":"Stillwater","state_id":"3946"},
    {"city_id":"45352","city_name":"Vadnais Heights","state_id":"3946"},
    {"city_id":"45353","city_name":"Waconia","state_id":"3946"},
    {"city_id":"45354","city_name":"Wadena","state_id":"3946"},
    {"city_id":"45355","city_name":"West Saint Paul","state_id":"3946"},
    {"city_id":"45356","city_name":"White Bear Lake","state_id":"3946"},
    {"city_id":"45357","city_name":"Willmar","state_id":"3946"},
    {"city_id":"45358","city_name":"Winona","state_id":"3946"},
    {"city_id":"45359","city_name":"Woodbury","state_id":"3946"},
    {"city_id":"45360","city_name":"Worthington","state_id":"3946"},
    {"city_id":"45361","city_name":"Bay Saint Louis","state_id":"3947"},
    {"city_id":"45362","city_name":"Biloxi","state_id":"3947"},
    {"city_id":"45363","city_name":"Brandon","state_id":"3947"},
    {"city_id":"45364","city_name":"Brookhaven","state_id":"3947"},
    {"city_id":"45365","city_name":"Byhalia","state_id":"3947"},
    {"city_id":"45366","city_name":"Byram","state_id":"3947"},
    {"city_id":"45367","city_name":"Canton","state_id":"3947"},
    {"city_id":"45368","city_name":"Clarksdale","state_id":"3947"},
    {"city_id":"45369","city_name":"Cleveland","state_id":"3947"},
    {"city_id":"45370","city_name":"Clinton","state_id":"3947"},
    {"city_id":"45371","city_name":"Columbus","state_id":"3947"},
    {"city_id":"45372","city_name":"Corinth","state_id":"3947"},
    {"city_id":"45373","city_name":"Diamondhead","state_id":"3947"},
    {"city_id":"45374","city_name":"Gautier","state_id":"3947"},
    {"city_id":"45375","city_name":"Greenville","state_id":"3947"},
    {"city_id":"45376","city_name":"Greenwood","state_id":"3947"},
    {"city_id":"45377","city_name":"Grenada","state_id":"3947"},
    {"city_id":"45378","city_name":"Gulfport","state_id":"3947"},
    {"city_id":"45379","city_name":"Hattiesburg","state_id":"3947"},
    {"city_id":"45380","city_name":"Hernando","state_id":"3947"},
    {"city_id":"45381","city_name":"Horn Lake","state_id":"3947"},
    {"city_id":"45382","city_name":"Indianola","state_id":"3947"},
    {"city_id":"45383","city_name":"Jackson","state_id":"3947"},
    {"city_id":"45384","city_name":"Laurel","state_id":"3947"},
    {"city_id":"45385","city_name":"Long Beach","state_id":"3947"},
    {"city_id":"45386","city_name":"Lucedale","state_id":"3947"},
    {"city_id":"45387","city_name":"MacComb","state_id":"3947"},
    {"city_id":"45388","city_name":"Madison","state_id":"3947"},
    {"city_id":"45389","city_name":"Magnolia","state_id":"3947"},
    {"city_id":"45390","city_name":"Meridian","state_id":"3947"},
    {"city_id":"45391","city_name":"Michigan City","state_id":"3947"},
    {"city_id":"45392","city_name":"Moselle","state_id":"3947"},
    {"city_id":"45393","city_name":"Moss Point","state_id":"3947"},
    {"city_id":"45394","city_name":"Natchez","state_id":"3947"},
    {"city_id":"45395","city_name":"Ocean Springs","state_id":"3947"},
    {"city_id":"45396","city_name":"Olive Branch","state_id":"3947"},
    {"city_id":"45397","city_name":"Orange Grove","state_id":"3947"},
    {"city_id":"45398","city_name":"Oxford","state_id":"3947"},
    {"city_id":"45399","city_name":"Pascagoula","state_id":"3947"},
    {"city_id":"45400","city_name":"Pearl","state_id":"3947"},
    {"city_id":"45401","city_name":"Pelahatchie","state_id":"3947"},
    {"city_id":"45402","city_name":"Picayune","state_id":"3947"},
    {"city_id":"45403","city_name":"Quitman","state_id":"3947"},
    {"city_id":"45404","city_name":"Ridgeland","state_id":"3947"},
    {"city_id":"45405","city_name":"Senatobia","state_id":"3947"},
    {"city_id":"45406","city_name":"Southaven","state_id":"3947"},
    {"city_id":"45407","city_name":"Southhaven","state_id":"3947"},
    {"city_id":"45408","city_name":"Starkville","state_id":"3947"},
    {"city_id":"45409","city_name":"Tupelo","state_id":"3947"},
    {"city_id":"45410","city_name":"Utica","state_id":"3947"},
    {"city_id":"45411","city_name":"Vicksburg","state_id":"3947"},
    {"city_id":"45412","city_name":"Yazoo City","state_id":"3947"},
    {"city_id":"45413","city_name":"Affton","state_id":"3948"},
    {"city_id":"45414","city_name":"Annapolis","state_id":"3948"},
    {"city_id":"45415","city_name":"Arnold","state_id":"3948"},
    {"city_id":"45416","city_name":"Ballwin","state_id":"3948"},
    {"city_id":"45417","city_name":"Belgique","state_id":"3948"},
    {"city_id":"45418","city_name":"Bellefontaine Neighbors","state_id":"3948"},
    {"city_id":"45419","city_name":"Belton","state_id":"3948"},
    {"city_id":"45420","city_name":"Berkeley","state_id":"3948"},
    {"city_id":"45421","city_name":"Blue Springs","state_id":"3948"},
    {"city_id":"45422","city_name":"Branson","state_id":"3948"},
    {"city_id":"45423","city_name":"Bridgeton","state_id":"3948"},
    {"city_id":"45424","city_name":"Brighton","state_id":"3948"},
    {"city_id":"45425","city_name":"California","state_id":"3948"},
    {"city_id":"45426","city_name":"Camdenton","state_id":"3948"},
    {"city_id":"45427","city_name":"Cape Girardeau","state_id":"3948"},
    {"city_id":"45428","city_name":"Carthage","state_id":"3948"},
    {"city_id":"45429","city_name":"Chaffee","state_id":"3948"},
    {"city_id":"45430","city_name":"Chesterfield","state_id":"3948"},
    {"city_id":"45431","city_name":"Chillicothe","state_id":"3948"},
    {"city_id":"45432","city_name":"Clayton","state_id":"3948"},
    {"city_id":"45433","city_name":"Clever","state_id":"3948"},
    {"city_id":"45434","city_name":"Columbia","state_id":"3948"},
    {"city_id":"45435","city_name":"Concord","state_id":"3948"},
    {"city_id":"45436","city_name":"Crestwood","state_id":"3948"},
    {"city_id":"45437","city_name":"Creve Coeur","state_id":"3948"},
    {"city_id":"45438","city_name":"Desloge","state_id":"3948"},
    {"city_id":"45439","city_name":"Dora","state_id":"3948"},
    {"city_id":"45440","city_name":"Earth City","state_id":"3948"},
    {"city_id":"45441","city_name":"Excelsior Springs","state_id":"3948"},
    {"city_id":"45442","city_name":"Farmington","state_id":"3948"},
    {"city_id":"45443","city_name":"Fenton","state_id":"3948"},
    {"city_id":"45444","city_name":"Ferguson","state_id":"3948"},
    {"city_id":"45445","city_name":"Florissant","state_id":"3948"},
    {"city_id":"45446","city_name":"Forsyth","state_id":"3948"},
    {"city_id":"45447","city_name":"Fort Leonard Wood","state_id":"3948"},
    {"city_id":"45448","city_name":"Fulton","state_id":"3948"},
    {"city_id":"45449","city_name":"Gladstone","state_id":"3948"},
    {"city_id":"45450","city_name":"Grain Valley","state_id":"3948"},
    {"city_id":"45451","city_name":"Grandview","state_id":"3948"},
    {"city_id":"45452","city_name":"Gravois Mills","state_id":"3948"},
    {"city_id":"45453","city_name":"Hannibal","state_id":"3948"},
    {"city_id":"45454","city_name":"Harrisonville","state_id":"3948"},
    {"city_id":"45455","city_name":"Hazelwood","state_id":"3948"},
    {"city_id":"45456","city_name":"High Ridge","state_id":"3948"},
    {"city_id":"45457","city_name":"Independence","state_id":"3948"},
    {"city_id":"45458","city_name":"Jackson","state_id":"3948"},
    {"city_id":"45459","city_name":"Jefferson City","state_id":"3948"},
    {"city_id":"45460","city_name":"Jennings","state_id":"3948"},
    {"city_id":"45461","city_name":"Joplin","state_id":"3948"},
    {"city_id":"45462","city_name":"Kansas City","state_id":"3948"},
    {"city_id":"45463","city_name":"Kennett","state_id":"3948"},
    {"city_id":"45464","city_name":"Kirksville","state_id":"3948"},
    {"city_id":"45465","city_name":"Kirkwood","state_id":"3948"},
    {"city_id":"45466","city_name":"Kissee Mills","state_id":"3948"},
    {"city_id":"45467","city_name":"Lamar","state_id":"3948"},
    {"city_id":"45468","city_name":"Lebanon","state_id":"3948"},
    {"city_id":"45469","city_name":"Lees Summit","state_id":"3948"},
    {"city_id":"45470","city_name":"Lemay","state_id":"3948"},
    {"city_id":"45471","city_name":"Liberty","state_id":"3948"},
    {"city_id":"45472","city_name":"Lone Jack","state_id":"3948"},
    {"city_id":"45473","city_name":"Marshall","state_id":"3948"},
    {"city_id":"45474","city_name":"Maryland Heights","state_id":"3948"},
    {"city_id":"45475","city_name":"Maryville","state_id":"3948"},
    {"city_id":"45476","city_name":"Mehlville","state_id":"3948"},
    {"city_id":"45477","city_name":"Mexico","state_id":"3948"},
    {"city_id":"45478","city_name":"Moberly","state_id":"3948"},
    {"city_id":"45479","city_name":"Murphy","state_id":"3948"},
    {"city_id":"45480","city_name":"Nixa","state_id":"3948"},
    {"city_id":"45481","city_name":"O'Fallon","state_id":"3948"},
    {"city_id":"45482","city_name":"Oakville","state_id":"3948"},
    {"city_id":"45483","city_name":"Overland","state_id":"3948"},
    {"city_id":"45484","city_name":"Pacific","state_id":"3948"},
    {"city_id":"45485","city_name":"Park Hills","state_id":"3948"},
    {"city_id":"45486","city_name":"Parkville","state_id":"3948"},
    {"city_id":"45487","city_name":"Peculiar","state_id":"3948"},
    {"city_id":"45488","city_name":"Poplar Bluff","state_id":"3948"},
    {"city_id":"45489","city_name":"Raytown","state_id":"3948"},
    {"city_id":"45490","city_name":"Richmond Heights","state_id":"3948"},
    {"city_id":"45491","city_name":"Rolla","state_id":"3948"},
    {"city_id":"45492","city_name":"Saint Ann","state_id":"3948"},
    {"city_id":"45493","city_name":"Saint Charles","state_id":"3948"},
    {"city_id":"45494","city_name":"Saint Clair","state_id":"3948"},
    {"city_id":"45495","city_name":"Saint Joseph","state_id":"3948"},
    {"city_id":"45496","city_name":"Saint Louis","state_id":"3948"},
    {"city_id":"45497","city_name":"Saint Peters","state_id":"3948"},
    {"city_id":"45498","city_name":"Sappington","state_id":"3948"},
    {"city_id":"45499","city_name":"Sedalia","state_id":"3948"},
    {"city_id":"45500","city_name":"Sikeston","state_id":"3948"},
    {"city_id":"45501","city_name":"Spanish Lake","state_id":"3948"},
    {"city_id":"45502","city_name":"Springfield","state_id":"3948"},
    {"city_id":"45503","city_name":"St. Louis","state_id":"3948"},
    {"city_id":"45504","city_name":"Steelville","state_id":"3948"},
    {"city_id":"45505","city_name":"Sunrise Beach","state_id":"3948"},
    {"city_id":"45506","city_name":"Town and Country","state_id":"3948"},
    {"city_id":"45507","city_name":"Trimble","state_id":"3948"},
    {"city_id":"45508","city_name":"Troy","state_id":"3948"},
    {"city_id":"45509","city_name":"University City","state_id":"3948"},
    {"city_id":"45510","city_name":"Warrensburg","state_id":"3948"},
    {"city_id":"45511","city_name":"Washington","state_id":"3948"},
    {"city_id":"45512","city_name":"Webb City","state_id":"3948"},
    {"city_id":"45513","city_name":"Webster Groves","state_id":"3948"},
    {"city_id":"45514","city_name":"Wentzville","state_id":"3948"},
    {"city_id":"45515","city_name":"West Plains","state_id":"3948"},
    {"city_id":"45516","city_name":"Wildwood","state_id":"3948"},
    {"city_id":"45517","city_name":"Anaconda-Deer Lodge County","state_id":"3949"},
    {"city_id":"45518","city_name":"Arlee","state_id":"3949"},
    {"city_id":"45519","city_name":"Belgrade","state_id":"3949"},
    {"city_id":"45520","city_name":"Billings","state_id":"3949"},
    {"city_id":"45521","city_name":"Bozeman","state_id":"3949"},
    {"city_id":"45522","city_name":"Butte","state_id":"3949"},
    {"city_id":"45523","city_name":"Butte-Silver Bow","state_id":"3949"},
    {"city_id":"45524","city_name":"Great Falls","state_id":"3949"},
    {"city_id":"45525","city_name":"Hamilton","state_id":"3949"},
    {"city_id":"45526","city_name":"Havre","state_id":"3949"},
    {"city_id":"45527","city_name":"Helena","state_id":"3949"},
    {"city_id":"45528","city_name":"Helena Valley Southeast","state_id":"3949"},
    {"city_id":"45529","city_name":"Helena Valley West Central","state_id":"3949"},
    {"city_id":"45530","city_name":"Kalispell","state_id":"3949"},
    {"city_id":"45531","city_name":"Lame Deer","state_id":"3949"},
    {"city_id":"45532","city_name":"Laurel","state_id":"3949"},
    {"city_id":"45533","city_name":"Lewistown","state_id":"3949"},
    {"city_id":"45534","city_name":"Livingston","state_id":"3949"},
    {"city_id":"45535","city_name":"Malmstrom Air Force Base","state_id":"3949"},
    {"city_id":"45536","city_name":"Manhattan","state_id":"3949"},
    {"city_id":"45537","city_name":"Miles City","state_id":"3949"},
    {"city_id":"45538","city_name":"Missoula","state_id":"3949"},
    {"city_id":"45539","city_name":"Orchard Homes","state_id":"3949"},
    {"city_id":"45540","city_name":"Pablo","state_id":"3949"},
    {"city_id":"45541","city_name":"Polson","state_id":"3949"},
    {"city_id":"45542","city_name":"Roberts","state_id":"3949"},
    {"city_id":"45543","city_name":"Ryegate","state_id":"3949"},
    {"city_id":"45544","city_name":"Sidney","state_id":"3949"},
    {"city_id":"45545","city_name":"Stevensville","state_id":"3949"},
    {"city_id":"45546","city_name":"Whitefish","state_id":"3949"},
    {"city_id":"45547","city_name":"Beatrice","state_id":"3950"},
    {"city_id":"45548","city_name":"Bellevue","state_id":"3950"},
    {"city_id":"45549","city_name":"Central City","state_id":"3950"},
    {"city_id":"45550","city_name":"Columbus","state_id":"3950"},
    {"city_id":"45551","city_name":"Cozad","state_id":"3950"},
    {"city_id":"45552","city_name":"Creighton","state_id":"3950"},
    {"city_id":"45553","city_name":"Fremont","state_id":"3950"},
    {"city_id":"45554","city_name":"Gering","state_id":"3950"},
    {"city_id":"45555","city_name":"Grand Island","state_id":"3950"},
    {"city_id":"45556","city_name":"Hastings","state_id":"3950"},
    {"city_id":"45557","city_name":"Homer","state_id":"3950"},
    {"city_id":"45558","city_name":"Keamey","state_id":"3950"},
    {"city_id":"45559","city_name":"Kearney","state_id":"3950"},
    {"city_id":"45560","city_name":"La Vista","state_id":"3950"},
    {"city_id":"45561","city_name":"Lexington","state_id":"3950"},
    {"city_id":"45562","city_name":"Lincoln","state_id":"3950"},
    {"city_id":"45563","city_name":"McCook","state_id":"3950"},
    {"city_id":"45564","city_name":"Norfolk","state_id":"3950"},
    {"city_id":"45565","city_name":"North Platte","state_id":"3950"},
    {"city_id":"45566","city_name":"Offutt Air Force Base West","state_id":"3950"},
    {"city_id":"45567","city_name":"Ogallala","state_id":"3950"},
    {"city_id":"45568","city_name":"Omaha","state_id":"3950"},
    {"city_id":"45569","city_name":"Papillion","state_id":"3950"},
    {"city_id":"45570","city_name":"Scottsbluff","state_id":"3950"},
    {"city_id":"45571","city_name":"South Sioux City","state_id":"3950"},
    {"city_id":"45572","city_name":"Boulder City","state_id":"3951"},
    {"city_id":"45573","city_name":"Carson City","state_id":"3951"},
    {"city_id":"45574","city_name":"Elko","state_id":"3951"},
    {"city_id":"45575","city_name":"Goldfield","state_id":"3951"},
    {"city_id":"45576","city_name":"Henderson","state_id":"3951"},
    {"city_id":"45577","city_name":"Las Vegas","state_id":"3951"},
    {"city_id":"45578","city_name":"Laughlin","state_id":"3951"},
    {"city_id":"45579","city_name":"Lovelock","state_id":"3951"},
    {"city_id":"45580","city_name":"Mesquite","state_id":"3951"},
    {"city_id":"45581","city_name":"Nellis Air Force Base","state_id":"3951"},
    {"city_id":"45582","city_name":"North Las Vegas","state_id":"3951"},
    {"city_id":"45583","city_name":"Pahrump","state_id":"3951"},
    {"city_id":"45584","city_name":"Paradise","state_id":"3951"},
    {"city_id":"45585","city_name":"Reno","state_id":"3951"},
    {"city_id":"45586","city_name":"Sparks","state_id":"3951"},
    {"city_id":"45587","city_name":"Spring Valley","state_id":"3951"},
    {"city_id":"45588","city_name":"Sun Valley","state_id":"3951"},
    {"city_id":"45589","city_name":"Sunrise Manor","state_id":"3951"},
    {"city_id":"45590","city_name":"Winchester","state_id":"3951"},
    {"city_id":"45591","city_name":"Winnemucca","state_id":"3951"},
    {"city_id":"45592","city_name":"Akron","state_id":"3959"},
    {"city_id":"45593","city_name":"Alledonia","state_id":"3959"},
    {"city_id":"45594","city_name":"Alliance","state_id":"3959"},
    {"city_id":"45595","city_name":"Amherst","state_id":"3959"},
    {"city_id":"45596","city_name":"Apple Creek","state_id":"3959"},
    {"city_id":"45597","city_name":"Archbold","state_id":"3959"},
    {"city_id":"45598","city_name":"Ashland","state_id":"3959"},
    {"city_id":"45599","city_name":"Ashtabula","state_id":"3959"},
    {"city_id":"45600","city_name":"Athens","state_id":"3959"},
    {"city_id":"45601","city_name":"Atwater","state_id":"3959"},
    {"city_id":"45602","city_name":"Aurora","state_id":"3959"},
    {"city_id":"45603","city_name":"Austintown","state_id":"3959"},
    {"city_id":"45604","city_name":"Avon Lake","state_id":"3959"},
    {"city_id":"45605","city_name":"Barberton","state_id":"3959"},
    {"city_id":"45606","city_name":"Batavia","state_id":"3959"},
    {"city_id":"45607","city_name":"Bay Village","state_id":"3959"},
    {"city_id":"45608","city_name":"Beachwood","state_id":"3959"},
    {"city_id":"45609","city_name":"Beavercreek","state_id":"3959"},
    {"city_id":"45610","city_name":"Bedford","state_id":"3959"},
    {"city_id":"45611","city_name":"Bedford Heights","state_id":"3959"},
    {"city_id":"45612","city_name":"Bellaire","state_id":"3959"},
    {"city_id":"45613","city_name":"Bellefontaine","state_id":"3959"},
    {"city_id":"45614","city_name":"Bellevue","state_id":"3959"},
    {"city_id":"45615","city_name":"Berea","state_id":"3959"},
    {"city_id":"45616","city_name":"Bexley","state_id":"3959"},
    {"city_id":"45617","city_name":"Blacklick","state_id":"3959"},
    {"city_id":"45618","city_name":"Blacklick Estates","state_id":"3959"},
    {"city_id":"45619","city_name":"Blanchester","state_id":"3959"},
    {"city_id":"45620","city_name":"Blue Ash","state_id":"3959"},
    {"city_id":"45621","city_name":"Boardman","state_id":"3959"},
    {"city_id":"45622","city_name":"Bowling Green","state_id":"3959"},
    {"city_id":"45623","city_name":"Brecksville","state_id":"3959"},
    {"city_id":"45624","city_name":"Bridgetown North","state_id":"3959"},
    {"city_id":"45625","city_name":"Bristolville","state_id":"3959"},
    {"city_id":"45626","city_name":"Broadview Heights","state_id":"3959"},
    {"city_id":"45627","city_name":"Brook Park","state_id":"3959"},
    {"city_id":"45628","city_name":"Brooklyn","state_id":"3959"},
    {"city_id":"45629","city_name":"Brunswick","state_id":"3959"},
    {"city_id":"45630","city_name":"Bryan","state_id":"3959"},
    {"city_id":"45631","city_name":"Bucyrus","state_id":"3959"},
    {"city_id":"45632","city_name":"Burton","state_id":"3959"},
    {"city_id":"45633","city_name":"Cambridge","state_id":"3959"},
    {"city_id":"45634","city_name":"Campbell","state_id":"3959"},
    {"city_id":"45635","city_name":"Canal Winchester","state_id":"3959"},
    {"city_id":"45636","city_name":"Canton","state_id":"3959"},
    {"city_id":"45637","city_name":"Carlisle","state_id":"3959"},
    {"city_id":"45638","city_name":"Celina","state_id":"3959"},
    {"city_id":"45639","city_name":"Centerville","state_id":"3959"},
    {"city_id":"45640","city_name":"Chagrin Falls","state_id":"3959"},
    {"city_id":"45641","city_name":"Chardon","state_id":"3959"},
    {"city_id":"45642","city_name":"Cheshire","state_id":"3959"},
    {"city_id":"45643","city_name":"Chillicothe","state_id":"3959"},
    {"city_id":"45644","city_name":"Chippewa Lake","state_id":"3959"},
    {"city_id":"45645","city_name":"Cincinnati","state_id":"3959"},
    {"city_id":"45646","city_name":"Circleville","state_id":"3959"},
    {"city_id":"45647","city_name":"Cleveland","state_id":"3959"},
    {"city_id":"45648","city_name":"Cleveland Heights","state_id":"3959"},
    {"city_id":"45649","city_name":"Columbus","state_id":"3959"},
    {"city_id":"45650","city_name":"Conneaut","state_id":"3959"},
    {"city_id":"45651","city_name":"Coshocton","state_id":"3959"},
    {"city_id":"45652","city_name":"Cuyahoga Falls","state_id":"3959"},
    {"city_id":"45653","city_name":"Dayton","state_id":"3959"},
    {"city_id":"45654","city_name":"Defiance","state_id":"3959"},
    {"city_id":"45655","city_name":"Delaware","state_id":"3959"},
    {"city_id":"45656","city_name":"Dover","state_id":"3959"},
    {"city_id":"45657","city_name":"Dublin","state_id":"3959"},
    {"city_id":"45658","city_name":"East Cleveland","state_id":"3959"},
    {"city_id":"45659","city_name":"East Liverpool","state_id":"3959"},
    {"city_id":"45660","city_name":"Eastlake","state_id":"3959"},
    {"city_id":"45661","city_name":"Elyria","state_id":"3959"},
    {"city_id":"45662","city_name":"Englewood","state_id":"3959"},
    {"city_id":"45663","city_name":"Euclid","state_id":"3959"},
    {"city_id":"45664","city_name":"Fairborn","state_id":"3959"},
    {"city_id":"45665","city_name":"Fairfield","state_id":"3959"},
    {"city_id":"45666","city_name":"Fairview Park","state_id":"3959"},
    {"city_id":"45667","city_name":"Findlay","state_id":"3959"},
    {"city_id":"45668","city_name":"Finneytown","state_id":"3959"},
    {"city_id":"45669","city_name":"Forest Park","state_id":"3959"},
    {"city_id":"45670","city_name":"Fort MacKinley","state_id":"3959"},
    {"city_id":"45671","city_name":"Fostoria","state_id":"3959"},
    {"city_id":"45672","city_name":"Fremont","state_id":"3959"},
    {"city_id":"45673","city_name":"Gahanna","state_id":"3959"},
    {"city_id":"45674","city_name":"Galion","state_id":"3959"},
    {"city_id":"45675","city_name":"Garfield Heights","state_id":"3959"},
    {"city_id":"45676","city_name":"Girard","state_id":"3959"},
    {"city_id":"45677","city_name":"Glenwillow","state_id":"3959"},
    {"city_id":"45678","city_name":"Green","state_id":"3959"},
    {"city_id":"45679","city_name":"Greenville","state_id":"3959"},
    {"city_id":"45680","city_name":"Grove City","state_id":"3959"},
    {"city_id":"45681","city_name":"Hamilton","state_id":"3959"},
    {"city_id":"45682","city_name":"Harrison","state_id":"3959"},
    {"city_id":"45683","city_name":"Hilliard","state_id":"3959"},
    {"city_id":"45684","city_name":"Hiram","state_id":"3959"},
    {"city_id":"45685","city_name":"Holland","state_id":"3959"},
    {"city_id":"45686","city_name":"Huber Heights","state_id":"3959"},
    {"city_id":"45687","city_name":"Hudson","state_id":"3959"},
    {"city_id":"45688","city_name":"Ironton","state_id":"3959"},
    {"city_id":"45689","city_name":"Kent","state_id":"3959"},
    {"city_id":"45690","city_name":"Kettering","state_id":"3959"},
    {"city_id":"45691","city_name":"Kidron","state_id":"3959"},
    {"city_id":"45692","city_name":"Lakewood","state_id":"3959"},
    {"city_id":"45693","city_name":"Lancaster","state_id":"3959"},
    {"city_id":"45694","city_name":"Lebanon","state_id":"3959"},
    {"city_id":"45695","city_name":"Lewis Center","state_id":"3959"},
    {"city_id":"45696","city_name":"Lima","state_id":"3959"},
    {"city_id":"45697","city_name":"Lincoln Village","state_id":"3959"},
    {"city_id":"45698","city_name":"Lorain","state_id":"3959"},
    {"city_id":"45699","city_name":"Loveland","state_id":"3959"},
    {"city_id":"45700","city_name":"Lyndhurst","state_id":"3959"},
    {"city_id":"45701","city_name":"Macedonia","state_id":"3959"},
    {"city_id":"45702","city_name":"Madison","state_id":"3959"},
    {"city_id":"45703","city_name":"Maineville","state_id":"3959"},
    {"city_id":"45704","city_name":"Mansfield","state_id":"3959"},
    {"city_id":"45705","city_name":"Maple Heights","state_id":"3959"},
    {"city_id":"45706","city_name":"Marietta","state_id":"3959"},
    {"city_id":"45707","city_name":"Marion","state_id":"3959"},
    {"city_id":"45708","city_name":"Marysville","state_id":"3959"},
    {"city_id":"45709","city_name":"Mason","state_id":"3959"},
    {"city_id":"45710","city_name":"Massillon","state_id":"3959"},
    {"city_id":"45711","city_name":"Maumee","state_id":"3959"},
    {"city_id":"45712","city_name":"Mayfield Heights","state_id":"3959"},
    {"city_id":"45713","city_name":"Medina","state_id":"3959"},
    {"city_id":"45714","city_name":"Mentor","state_id":"3959"},
    {"city_id":"45715","city_name":"Miamisburg","state_id":"3959"},
    {"city_id":"45716","city_name":"Middleburg Heights","state_id":"3959"},
    {"city_id":"45717","city_name":"Middletown","state_id":"3959"},
    {"city_id":"45718","city_name":"Milford","state_id":"3959"},
    {"city_id":"45719","city_name":"Millbury","state_id":"3959"},
    {"city_id":"45720","city_name":"Mineral City","state_id":"3959"},
    {"city_id":"45721","city_name":"Minster","state_id":"3959"},
    {"city_id":"45722","city_name":"Mount Gilead","state_id":"3959"},
    {"city_id":"45723","city_name":"Mount Vernon","state_id":"3959"},
    {"city_id":"45724","city_name":"Nelsonville","state_id":"3959"},
    {"city_id":"45725","city_name":"New Albany","state_id":"3959"},
    {"city_id":"45726","city_name":"New Philadelphia","state_id":"3959"},
    {"city_id":"45727","city_name":"Newark","state_id":"3959"},
    {"city_id":"45728","city_name":"Niles","state_id":"3959"},
    {"city_id":"45729","city_name":"North Canton","state_id":"3959"},
    {"city_id":"45730","city_name":"North College Hill","state_id":"3959"},
    {"city_id":"45731","city_name":"North Lewisburg","state_id":"3959"},
    {"city_id":"45732","city_name":"North Olmsted","state_id":"3959"},
    {"city_id":"45733","city_name":"North Ridgeville","state_id":"3959"},
    {"city_id":"45734","city_name":"North Royalton","state_id":"3959"},
    {"city_id":"45735","city_name":"Northbrook","state_id":"3959"},
    {"city_id":"45736","city_name":"Northfield","state_id":"3959"},
    {"city_id":"45737","city_name":"Northview","state_id":"3959"},
    {"city_id":"45738","city_name":"Norton","state_id":"3959"},
    {"city_id":"45739","city_name":"Norwalk","state_id":"3959"},
    {"city_id":"45740","city_name":"Norwood","state_id":"3959"},
    {"city_id":"45741","city_name":"Oberlin","state_id":"3959"},
    {"city_id":"45742","city_name":"Ohio","state_id":"3959"},
    {"city_id":"45743","city_name":"Oregon","state_id":"3959"},
    {"city_id":"45744","city_name":"Overlook-Page Manor","state_id":"3959"},
    {"city_id":"45745","city_name":"Oxford","state_id":"3959"},
    {"city_id":"45746","city_name":"Painesville","state_id":"3959"},
    {"city_id":"45747","city_name":"Parma","state_id":"3959"},
    {"city_id":"45748","city_name":"Parma Heights","state_id":"3959"},
    {"city_id":"45749","city_name":"Peninsula","state_id":"3959"},
    {"city_id":"45750","city_name":"Perrysburg","state_id":"3959"},
    {"city_id":"45751","city_name":"Pickerington","state_id":"3959"},
    {"city_id":"45752","city_name":"Piqua","state_id":"3959"},
    {"city_id":"45753","city_name":"Portage Lakes","state_id":"3959"},
    {"city_id":"45754","city_name":"Portsmouth","state_id":"3959"},
    {"city_id":"45755","city_name":"Powell","state_id":"3959"},
    {"city_id":"45756","city_name":"Ravenna","state_id":"3959"},
    {"city_id":"45757","city_name":"Reading","state_id":"3959"},
    {"city_id":"45758","city_name":"Reynoldsburg","state_id":"3959"},
    {"city_id":"45759","city_name":"Rittman","state_id":"3959"},
    {"city_id":"45760","city_name":"Riverside","state_id":"3959"},
    {"city_id":"45761","city_name":"Rocky River","state_id":"3959"},
    {"city_id":"45762","city_name":"Rossford","state_id":"3959"},
    {"city_id":"45763","city_name":"Salem","state_id":"3959"},
    {"city_id":"45764","city_name":"Sandusky","state_id":"3959"},
    {"city_id":"45765","city_name":"Seven Hills","state_id":"3959"},
    {"city_id":"45766","city_name":"Seville","state_id":"3959"},
    {"city_id":"45767","city_name":"Shaker Heights","state_id":"3959"},
    {"city_id":"45768","city_name":"Sharonville","state_id":"3959"},
    {"city_id":"45769","city_name":"Sheffield Lake","state_id":"3959"},
    {"city_id":"45770","city_name":"Shelby","state_id":"3959"},
    {"city_id":"45771","city_name":"Sidney","state_id":"3959"},
    {"city_id":"45772","city_name":"Solon","state_id":"3959"},
    {"city_id":"45773","city_name":"South Euclid","state_id":"3959"},
    {"city_id":"45774","city_name":"Springdale","state_id":"3959"},
    {"city_id":"45775","city_name":"Springfield","state_id":"3959"},
    {"city_id":"45776","city_name":"Steubenville","state_id":"3959"},
    {"city_id":"45777","city_name":"Stow","state_id":"3959"},
    {"city_id":"45778","city_name":"Streetsboro","state_id":"3959"},
    {"city_id":"45779","city_name":"Strongsville","state_id":"3959"},
    {"city_id":"45780","city_name":"Struthers","state_id":"3959"},
    {"city_id":"45781","city_name":"Sylvania","state_id":"3959"},
    {"city_id":"45782","city_name":"Tallmadge","state_id":"3959"},
    {"city_id":"45783","city_name":"Tiffin","state_id":"3959"},
    {"city_id":"45784","city_name":"Toledo","state_id":"3959"},
    {"city_id":"45785","city_name":"Trotwood","state_id":"3959"},
    {"city_id":"45786","city_name":"Troy","state_id":"3959"},
    {"city_id":"45787","city_name":"Twinsburg","state_id":"3959"},
    {"city_id":"45788","city_name":"University Heights","state_id":"3959"},
    {"city_id":"45789","city_name":"Upper Arlington","state_id":"3959"},
    {"city_id":"45790","city_name":"Urbana","state_id":"3959"},
    {"city_id":"45791","city_name":"Valley Glen","state_id":"3959"},
    {"city_id":"45792","city_name":"Van Wert","state_id":"3959"},
    {"city_id":"45793","city_name":"Vandalia","state_id":"3959"},
    {"city_id":"45794","city_name":"Vermilion","state_id":"3959"},
    {"city_id":"45795","city_name":"Wadsworth","state_id":"3959"},
    {"city_id":"45796","city_name":"Warren","state_id":"3959"},
    {"city_id":"45797","city_name":"Warrensville Heights","state_id":"3959"},
    {"city_id":"45798","city_name":"Washington","state_id":"3959"},
    {"city_id":"45799","city_name":"Waverly","state_id":"3959"},
    {"city_id":"45800","city_name":"West Carrollton City","state_id":"3959"},
    {"city_id":"45801","city_name":"West Chester","state_id":"3959"},
    {"city_id":"45802","city_name":"Westerville","state_id":"3959"},
    {"city_id":"45803","city_name":"Westlake","state_id":"3959"},
    {"city_id":"45804","city_name":"White Oak","state_id":"3959"},
    {"city_id":"45805","city_name":"Whitehall","state_id":"3959"},
    {"city_id":"45806","city_name":"Wickliffe","state_id":"3959"},
    {"city_id":"45807","city_name":"Willoughby","state_id":"3959"},
    {"city_id":"45808","city_name":"Willowick","state_id":"3959"},
    {"city_id":"45809","city_name":"Wilmington","state_id":"3959"},
    {"city_id":"45810","city_name":"Winesburg","state_id":"3959"},
    {"city_id":"45811","city_name":"Wooster","state_id":"3959"},
    {"city_id":"45812","city_name":"Worthington","state_id":"3959"},
    {"city_id":"45813","city_name":"Xenia","state_id":"3959"},
    {"city_id":"45814","city_name":"Yellow Springs","state_id":"3959"},
    {"city_id":"45815","city_name":"Youngstown","state_id":"3959"},
    {"city_id":"45816","city_name":"Zanesville","state_id":"3959"},
    {"city_id":"45817","city_name":"Ada","state_id":"3960"},
    {"city_id":"45818","city_name":"Altus","state_id":"3960"},
    {"city_id":"45819","city_name":"Ardmore","state_id":"3960"},
    {"city_id":"45820","city_name":"Bartlesville","state_id":"3960"},
    {"city_id":"45821","city_name":"Bethany","state_id":"3960"},
    {"city_id":"45822","city_name":"Bixby","state_id":"3960"},
    {"city_id":"45823","city_name":"Broken Arrow","state_id":"3960"},
    {"city_id":"45824","city_name":"Catoosa","state_id":"3960"},
    {"city_id":"45825","city_name":"Chickasha","state_id":"3960"},
    {"city_id":"45826","city_name":"Choctaw","state_id":"3960"},
    {"city_id":"45827","city_name":"Claremore","state_id":"3960"},
    {"city_id":"45828","city_name":"Del City","state_id":"3960"},
    {"city_id":"45829","city_name":"Duncan","state_id":"3960"},
    {"city_id":"45830","city_name":"Durant","state_id":"3960"},
    {"city_id":"45831","city_name":"Edmond","state_id":"3960"},
    {"city_id":"45832","city_name":"El Reno","state_id":"3960"},
    {"city_id":"45833","city_name":"Elk City","state_id":"3960"},
    {"city_id":"45834","city_name":"Enid","state_id":"3960"},
    {"city_id":"45835","city_name":"Fort Sill","state_id":"3960"},
    {"city_id":"45836","city_name":"Grove","state_id":"3960"},
    {"city_id":"45837","city_name":"Guthrie","state_id":"3960"},
    {"city_id":"45838","city_name":"Heavener","state_id":"3960"},
    {"city_id":"45839","city_name":"Hugo","state_id":"3960"},
    {"city_id":"45840","city_name":"Lawton","state_id":"3960"},
    {"city_id":"45841","city_name":"Lindsay","state_id":"3960"},
    {"city_id":"45842","city_name":"MacAlester","state_id":"3960"},
    {"city_id":"45843","city_name":"Miami","state_id":"3960"},
    {"city_id":"45844","city_name":"Midwest City","state_id":"3960"},
    {"city_id":"45845","city_name":"Moore","state_id":"3960"},
    {"city_id":"45846","city_name":"Morrison","state_id":"3960"},
    {"city_id":"45847","city_name":"Muskogee","state_id":"3960"},
    {"city_id":"45848","city_name":"Mustang","state_id":"3960"},
    {"city_id":"45849","city_name":"Norman","state_id":"3960"},
    {"city_id":"45850","city_name":"Oklahoma City","state_id":"3960"},
    {"city_id":"45851","city_name":"Okmulgee","state_id":"3960"},
    {"city_id":"45852","city_name":"Owasso","state_id":"3960"},
    {"city_id":"45853","city_name":"Pawnee","state_id":"3960"},
    {"city_id":"45854","city_name":"Ponca City","state_id":"3960"},
    {"city_id":"45855","city_name":"Rattan","state_id":"3960"},
    {"city_id":"45856","city_name":"Sand Springs","state_id":"3960"},
    {"city_id":"45857","city_name":"Sapulpa","state_id":"3960"},
    {"city_id":"45858","city_name":"Shawnee","state_id":"3960"},
    {"city_id":"45859","city_name":"Stillwater","state_id":"3960"},
    {"city_id":"45860","city_name":"Sulphur","state_id":"3960"},
    {"city_id":"45861","city_name":"Tahlequah","state_id":"3960"},
    {"city_id":"45862","city_name":"The Village","state_id":"3960"},
    {"city_id":"45863","city_name":"Tulsa","state_id":"3960"},
    {"city_id":"45864","city_name":"Weatherford","state_id":"3960"},
    {"city_id":"45865","city_name":"Welch","state_id":"3960"},
    {"city_id":"45866","city_name":"Woodward","state_id":"3960"},
    {"city_id":"45867","city_name":"Yukon","state_id":"3960"},
    {"city_id":"45868","city_name":"Point Edward","state_id":"3961"},
    {"city_id":"45869","city_name":"Woodbridge","state_id":"3961"},
    {"city_id":"45870","city_name":"Albany","state_id":"3962"},
    {"city_id":"45871","city_name":"Aloha","state_id":"3962"},
    {"city_id":"45872","city_name":"Altamont","state_id":"3962"},
    {"city_id":"45873","city_name":"Arleta","state_id":"3962"},
    {"city_id":"45874","city_name":"Ashland","state_id":"3962"},
    {"city_id":"45875","city_name":"Astoria","state_id":"3962"},
    {"city_id":"45876","city_name":"Baker City","state_id":"3962"},
    {"city_id":"45877","city_name":"Beaverton","state_id":"3962"},
    {"city_id":"45878","city_name":"Bend","state_id":"3962"},
    {"city_id":"45879","city_name":"Canby","state_id":"3962"},
    {"city_id":"45880","city_name":"Cave Junction","state_id":"3962"},
    {"city_id":"45881","city_name":"Cedar Hills","state_id":"3962"},
    {"city_id":"45882","city_name":"Cedar Mill","state_id":"3962"},
    {"city_id":"45883","city_name":"Central Point","state_id":"3962"},
    {"city_id":"45884","city_name":"City of The Dalles","state_id":"3962"},
    {"city_id":"45885","city_name":"Coos Bay","state_id":"3962"},
    {"city_id":"45886","city_name":"Corvallis","state_id":"3962"},
    {"city_id":"45887","city_name":"Creswell","state_id":"3962"},
    {"city_id":"45888","city_name":"Dallas","state_id":"3962"},
    {"city_id":"45889","city_name":"Donald","state_id":"3962"},
    {"city_id":"45890","city_name":"Eugene","state_id":"3962"},
    {"city_id":"45891","city_name":"Forest Grove","state_id":"3962"},
    {"city_id":"45892","city_name":"Four Corners","state_id":"3962"},
    {"city_id":"45893","city_name":"Gladstone","state_id":"3962"},
    {"city_id":"45894","city_name":"Glide","state_id":"3962"},
    {"city_id":"45895","city_name":"Grants Pass","state_id":"3962"},
    {"city_id":"45896","city_name":"Gresham","state_id":"3962"},
    {"city_id":"45897","city_name":"Hayesville","state_id":"3962"},
    {"city_id":"45898","city_name":"Hazelwood","state_id":"3962"},
    {"city_id":"45899","city_name":"Hermiston","state_id":"3962"},
    {"city_id":"45900","city_name":"Hillsboro","state_id":"3962"},
    {"city_id":"45901","city_name":"Hood River","state_id":"3962"},
    {"city_id":"45902","city_name":"Hubbard","state_id":"3962"},
    {"city_id":"45903","city_name":"John Day","state_id":"3962"},
    {"city_id":"45904","city_name":"Jordan Valley","state_id":"3962"},
    {"city_id":"45905","city_name":"Keizer","state_id":"3962"},
    {"city_id":"45906","city_name":"Klamath Falls","state_id":"3962"},
    {"city_id":"45907","city_name":"La Grande","state_id":"3962"},
    {"city_id":"45908","city_name":"Lake Oswego","state_id":"3962"},
    {"city_id":"45909","city_name":"Lebanon","state_id":"3962"},
    {"city_id":"45910","city_name":"Lincoln","state_id":"3962"},
    {"city_id":"45911","city_name":"MacMinnville","state_id":"3962"},
    {"city_id":"45912","city_name":"Medford","state_id":"3962"},
    {"city_id":"45913","city_name":"Milwaukie","state_id":"3962"},
    {"city_id":"45914","city_name":"Newberg","state_id":"3962"},
    {"city_id":"45915","city_name":"Newport","state_id":"3962"},
    {"city_id":"45916","city_name":"North Bend","state_id":"3962"},
    {"city_id":"45917","city_name":"OBrien","state_id":"3962"},
    {"city_id":"45918","city_name":"Oak Grove","state_id":"3962"},
    {"city_id":"45919","city_name":"Oatfield","state_id":"3962"},
    {"city_id":"45920","city_name":"Ontario","state_id":"3962"},
    {"city_id":"45921","city_name":"Oregon City","state_id":"3962"},
    {"city_id":"45922","city_name":"Pendleton","state_id":"3962"},
    {"city_id":"45923","city_name":"Portland","state_id":"3962"},
    {"city_id":"45924","city_name":"Redmond","state_id":"3962"},
    {"city_id":"45925","city_name":"Riddle","state_id":"3962"},
    {"city_id":"45926","city_name":"River Road","state_id":"3962"},
    {"city_id":"45927","city_name":"Roseburg","state_id":"3962"},
    {"city_id":"45928","city_name":"Salem","state_id":"3962"},
    {"city_id":"45929","city_name":"Sherwood","state_id":"3962"},
    {"city_id":"45930","city_name":"Springfield","state_id":"3962"},
    {"city_id":"45931","city_name":"Sublimity","state_id":"3962"},
    {"city_id":"45932","city_name":"Sutherlin","state_id":"3962"},
    {"city_id":"45933","city_name":"Talent","state_id":"3962"},
    {"city_id":"45934","city_name":"Tigard","state_id":"3962"},
    {"city_id":"45935","city_name":"Troutdale","state_id":"3962"},
    {"city_id":"45936","city_name":"Tualatin","state_id":"3962"},
    {"city_id":"45937","city_name":"Turner","state_id":"3962"},
    {"city_id":"45938","city_name":"Vaughn","state_id":"3962"},
    {"city_id":"45939","city_name":"West Linn","state_id":"3962"},
    {"city_id":"45940","city_name":"Wilsonville","state_id":"3962"},
    {"city_id":"45941","city_name":"Woodburn","state_id":"3962"},
    {"city_id":"45942","city_name":"Akron","state_id":"3963"},
    {"city_id":"45943","city_name":"Aliquippa","state_id":"3963"},
    {"city_id":"45944","city_name":"Allentown","state_id":"3963"},
    {"city_id":"45945","city_name":"Altoona","state_id":"3963"},
    {"city_id":"45946","city_name":"Ambler","state_id":"3963"},
    {"city_id":"45947","city_name":"Amityville","state_id":"3963"},
    {"city_id":"45948","city_name":"Ardmore","state_id":"3963"},
    {"city_id":"45949","city_name":"Audubon","state_id":"3963"},
    {"city_id":"45950","city_name":"Back Mountain","state_id":"3963"},
    {"city_id":"45951","city_name":"Baldwin","state_id":"3963"},
    {"city_id":"45952","city_name":"Bangor","state_id":"3963"},
    {"city_id":"45953","city_name":"Beaver Falls","state_id":"3963"},
    {"city_id":"45954","city_name":"Belle Vernon","state_id":"3963"},
    {"city_id":"45955","city_name":"Bensalem","state_id":"3963"},
    {"city_id":"45956","city_name":"Berwick","state_id":"3963"},
    {"city_id":"45957","city_name":"Berwyn","state_id":"3963"},
    {"city_id":"45958","city_name":"Bethel Park","state_id":"3963"},
    {"city_id":"45959","city_name":"Bethlehem","state_id":"3963"},
    {"city_id":"45960","city_name":"Bloomsburg","state_id":"3963"},
    {"city_id":"45961","city_name":"Boyertown","state_id":"3963"},
    {"city_id":"45962","city_name":"Bradford","state_id":"3963"},
    {"city_id":"45963","city_name":"Brentwood","state_id":"3963"},
    {"city_id":"45964","city_name":"Bridgeport","state_id":"3963"},
    {"city_id":"45965","city_name":"Bristol","state_id":"3963"},
    {"city_id":"45966","city_name":"Brockway","state_id":"3963"},
    {"city_id":"45967","city_name":"Broomall","state_id":"3963"},
    {"city_id":"45968","city_name":"Bushkill","state_id":"3963"},
    {"city_id":"45969","city_name":"Butler","state_id":"3963"},
    {"city_id":"45970","city_name":"Camp Hill","state_id":"3963"},
    {"city_id":"45971","city_name":"Canonsburg","state_id":"3963"},
    {"city_id":"45972","city_name":"Carbondale","state_id":"3963"},
    {"city_id":"45973","city_name":"Carlisle","state_id":"3963"},
    {"city_id":"45974","city_name":"Carnegie","state_id":"3963"},
    {"city_id":"45975","city_name":"Carnot Moon","state_id":"3963"},
    {"city_id":"45976","city_name":"Chambersburg","state_id":"3963"},
    {"city_id":"45977","city_name":"Chester","state_id":"3963"},
    {"city_id":"45978","city_name":"Chester Springs","state_id":"3963"},
    {"city_id":"45979","city_name":"Clarks Summit","state_id":"3963"},
    {"city_id":"45980","city_name":"Coatesville","state_id":"3963"},
    {"city_id":"45981","city_name":"Colonial Park","state_id":"3963"},
    {"city_id":"45982","city_name":"Columbia","state_id":"3963"},
    {"city_id":"45983","city_name":"Conshohocken","state_id":"3963"},
    {"city_id":"45984","city_name":"Coraopolis","state_id":"3963"},
    {"city_id":"45985","city_name":"Corry","state_id":"3963"},
    {"city_id":"45986","city_name":"Cranberry Township","state_id":"3963"},
    {"city_id":"45987","city_name":"Cresco","state_id":"3963"},
    {"city_id":"45988","city_name":"Croydon","state_id":"3963"},
    {"city_id":"45989","city_name":"Dallas","state_id":"3963"},
    {"city_id":"45990","city_name":"Dallastown","state_id":"3963"},
    {"city_id":"45991","city_name":"Darby","state_id":"3963"},
    {"city_id":"45992","city_name":"Darby Township","state_id":"3963"},
    {"city_id":"45993","city_name":"Downingtown","state_id":"3963"},
    {"city_id":"45994","city_name":"Drexel Hill","state_id":"3963"},
    {"city_id":"45995","city_name":"Duncansville","state_id":"3963"},
    {"city_id":"45996","city_name":"Dunmore","state_id":"3963"},
    {"city_id":"45997","city_name":"East Norriton","state_id":"3963"},
    {"city_id":"45998","city_name":"East Stroudsburg","state_id":"3963"},
    {"city_id":"45999","city_name":"Easton","state_id":"3963"},
    {"city_id":"46000","city_name":"Economy","state_id":"3963"},
    {"city_id":"46001","city_name":"Edinboro","state_id":"3963"},
    {"city_id":"46002","city_name":"Elizabethtown","state_id":"3963"},
    {"city_id":"46003","city_name":"Elkins Park","state_id":"3963"},
    {"city_id":"46004","city_name":"Emmaus","state_id":"3963"},
    {"city_id":"46005","city_name":"Ephrata","state_id":"3963"},
    {"city_id":"46006","city_name":"Erdenheim","state_id":"3963"},
    {"city_id":"46007","city_name":"Erie","state_id":"3963"},
    {"city_id":"46008","city_name":"Erwinna","state_id":"3963"},
    {"city_id":"46009","city_name":"Exton","state_id":"3963"},
    {"city_id":"46010","city_name":"Feasterville","state_id":"3963"},
    {"city_id":"46011","city_name":"Folcroft","state_id":"3963"},
    {"city_id":"46012","city_name":"Franklin","state_id":"3963"},
    {"city_id":"46013","city_name":"Franklin Park","state_id":"3963"},
    {"city_id":"46014","city_name":"Frederick","state_id":"3963"},
    {"city_id":"46015","city_name":"Fullerton","state_id":"3963"},
    {"city_id":"46016","city_name":"Furlong","state_id":"3963"},
    {"city_id":"46017","city_name":"Gettysburg","state_id":"3963"},
    {"city_id":"46018","city_name":"Gibsonia","state_id":"3963"},
    {"city_id":"46019","city_name":"Glenside","state_id":"3963"},
    {"city_id":"46020","city_name":"Gordonville","state_id":"3963"},
    {"city_id":"46021","city_name":"Greensburg","state_id":"3963"},
    {"city_id":"46022","city_name":"Gwynedd","state_id":"3963"},
    {"city_id":"46023","city_name":"Hampden Township","state_id":"3963"},
    {"city_id":"46024","city_name":"Hanover","state_id":"3963"},
    {"city_id":"46025","city_name":"Harleysville","state_id":"3963"},
    {"city_id":"46026","city_name":"Harrisburg","state_id":"3963"},
    {"city_id":"46027","city_name":"Harrison Township","state_id":"3963"},
    {"city_id":"46028","city_name":"Hatboro","state_id":"3963"},
    {"city_id":"46029","city_name":"Haverford","state_id":"3963"},
    {"city_id":"46030","city_name":"Havertown","state_id":"3963"},
    {"city_id":"46031","city_name":"Hazleton","state_id":"3963"},
    {"city_id":"46032","city_name":"Hermitage","state_id":"3963"},
    {"city_id":"46033","city_name":"Hershey","state_id":"3963"},
    {"city_id":"46034","city_name":"Hollidaysburg","state_id":"3963"},
    {"city_id":"46035","city_name":"Horsham","state_id":"3963"},
    {"city_id":"46036","city_name":"Huntingdon Valley","state_id":"3963"},
    {"city_id":"46037","city_name":"Indiana","state_id":"3963"},
    {"city_id":"46038","city_name":"Irvine","state_id":"3963"},
    {"city_id":"46039","city_name":"Ivyland","state_id":"3963"},
    {"city_id":"46040","city_name":"Jeannette","state_id":"3963"},
    {"city_id":"46041","city_name":"Jefferson","state_id":"3963"},
    {"city_id":"46042","city_name":"Jenkintown","state_id":"3963"},
    {"city_id":"46043","city_name":"Johnstown","state_id":"3963"},
    {"city_id":"46044","city_name":"Kempton","state_id":"3963"},
    {"city_id":"46045","city_name":"Kennett Square","state_id":"3963"},
    {"city_id":"46046","city_name":"King of Prussia","state_id":"3963"},
    {"city_id":"46047","city_name":"Kingston","state_id":"3963"},
    {"city_id":"46048","city_name":"Kutztown","state_id":"3963"},
    {"city_id":"46049","city_name":"Lafayette Hill","state_id":"3963"},
    {"city_id":"46050","city_name":"Lancaster","state_id":"3963"},
    {"city_id":"46051","city_name":"Landenberg","state_id":"3963"},
    {"city_id":"46052","city_name":"Langhorne","state_id":"3963"},
    {"city_id":"46053","city_name":"Lansdale","state_id":"3963"},
    {"city_id":"46054","city_name":"Lansdowne","state_id":"3963"},
    {"city_id":"46055","city_name":"Lansford","state_id":"3963"},
    {"city_id":"46056","city_name":"Laurys Station","state_id":"3963"},
    {"city_id":"46057","city_name":"Lebanon","state_id":"3963"},
    {"city_id":"46058","city_name":"Lehighton","state_id":"3963"},
    {"city_id":"46059","city_name":"Levittown","state_id":"3963"},
    {"city_id":"46060","city_name":"Lincoln University","state_id":"3963"},
    {"city_id":"46061","city_name":"Linesville","state_id":"3963"},
    {"city_id":"46062","city_name":"Linwood","state_id":"3963"},
    {"city_id":"46063","city_name":"Lower Burrell","state_id":"3963"},
    {"city_id":"46064","city_name":"Lower Merion","state_id":"3963"},
    {"city_id":"46065","city_name":"MacCandless Township","state_id":"3963"},
    {"city_id":"46066","city_name":"MacKeesport","state_id":"3963"},
    {"city_id":"46067","city_name":"Malvern","state_id":"3963"},
    {"city_id":"46068","city_name":"Meadville","state_id":"3963"},
    {"city_id":"46069","city_name":"Mechanicsburg","state_id":"3963"},
    {"city_id":"46070","city_name":"Media","state_id":"3963"},
    {"city_id":"46071","city_name":"Merion Station","state_id":"3963"},
    {"city_id":"46072","city_name":"Middleburg","state_id":"3963"},
    {"city_id":"46073","city_name":"Mifflinville","state_id":"3963"},
    {"city_id":"46074","city_name":"Milanville","state_id":"3963"},
    {"city_id":"46075","city_name":"Milford","state_id":"3963"},
    {"city_id":"46076","city_name":"Millersburg","state_id":"3963"},
    {"city_id":"46077","city_name":"Monessen","state_id":"3963"},
    {"city_id":"46078","city_name":"Moscow","state_id":"3963"},
    {"city_id":"46079","city_name":"Mount Carmel","state_id":"3963"},
    {"city_id":"46080","city_name":"Mount Lebanon","state_id":"3963"},
    {"city_id":"46081","city_name":"Mountville","state_id":"3963"},
    {"city_id":"46082","city_name":"Munhall","state_id":"3963"},
    {"city_id":"46083","city_name":"Municipality of Monroeville","state_id":"3963"},
    {"city_id":"46084","city_name":"Municipality of Murrysville","state_id":"3963"},
    {"city_id":"46085","city_name":"N. Charleroi","state_id":"3963"},
    {"city_id":"46086","city_name":"Nanticoke","state_id":"3963"},
    {"city_id":"46087","city_name":"Narberth","state_id":"3963"},
    {"city_id":"46088","city_name":"Natrona Heights","state_id":"3963"},
    {"city_id":"46089","city_name":"Nazareth","state_id":"3963"},
    {"city_id":"46090","city_name":"Nether Providence Township","state_id":"3963"},
    {"city_id":"46091","city_name":"New Buffalo","state_id":"3963"},
    {"city_id":"46092","city_name":"New Carlisle","state_id":"3963"},
    {"city_id":"46093","city_name":"New Castle","state_id":"3963"},
    {"city_id":"46094","city_name":"New Cumberland","state_id":"3963"},
    {"city_id":"46095","city_name":"New Hope","state_id":"3963"},
    {"city_id":"46096","city_name":"New Kensington","state_id":"3963"},
    {"city_id":"46097","city_name":"Newton","state_id":"3963"},
    {"city_id":"46098","city_name":"Newtown","state_id":"3963"},
    {"city_id":"46099","city_name":"Newville","state_id":"3963"},
    {"city_id":"46100","city_name":"Norristown","state_id":"3963"},
    {"city_id":"46101","city_name":"North East","state_id":"3963"},
    {"city_id":"46102","city_name":"North Versailles","state_id":"3963"},
    {"city_id":"46103","city_name":"North Wales","state_id":"3963"},
    {"city_id":"46104","city_name":"Oaks","state_id":"3963"},
    {"city_id":"46105","city_name":"Oil City","state_id":"3963"},
    {"city_id":"46106","city_name":"Olyphant","state_id":"3963"},
    {"city_id":"46107","city_name":"Orrtanna","state_id":"3963"},
    {"city_id":"46108","city_name":"Orwigsburg","state_id":"3963"},
    {"city_id":"46109","city_name":"Oxford","state_id":"3963"},
    {"city_id":"46110","city_name":"Paoli","state_id":"3963"},
    {"city_id":"46111","city_name":"Parksburg","state_id":"3963"},
    {"city_id":"46112","city_name":"Penn Hills","state_id":"3963"},
    {"city_id":"46113","city_name":"Philadelphia","state_id":"3963"},
    {"city_id":"46114","city_name":"Phildelphia","state_id":"3963"},
    {"city_id":"46115","city_name":"Phoenixville","state_id":"3963"},
    {"city_id":"46116","city_name":"Pipersville","state_id":"3963"},
    {"city_id":"46117","city_name":"Pittsburgh","state_id":"3963"},
    {"city_id":"46118","city_name":"Pleasantville","state_id":"3963"},
    {"city_id":"46119","city_name":"Plum","state_id":"3963"},
    {"city_id":"46120","city_name":"Pocono Summit","state_id":"3963"},
    {"city_id":"46121","city_name":"Pottstown","state_id":"3963"},
    {"city_id":"46122","city_name":"Pottsville","state_id":"3963"},
    {"city_id":"46123","city_name":"Primos","state_id":"3963"},
    {"city_id":"46124","city_name":"Progress","state_id":"3963"},
    {"city_id":"46125","city_name":"Prospect","state_id":"3963"},
    {"city_id":"46126","city_name":"Quakertown","state_id":"3963"},
    {"city_id":"46127","city_name":"Radnor Township","state_id":"3963"},
    {"city_id":"46128","city_name":"Reading","state_id":"3963"},
    {"city_id":"46129","city_name":"Robinson Township","state_id":"3963"},
    {"city_id":"46130","city_name":"Roseto","state_id":"3963"},
    {"city_id":"46131","city_name":"Ross Township","state_id":"3963"},
    {"city_id":"46132","city_name":"Royersford","state_id":"3963"},
    {"city_id":"46133","city_name":"Saint Marys","state_id":"3963"},
    {"city_id":"46134","city_name":"Sarver","state_id":"3963"},
    {"city_id":"46135","city_name":"Saxonburg","state_id":"3963"},
    {"city_id":"46136","city_name":"Scott Township","state_id":"3963"},
    {"city_id":"46137","city_name":"Scranton","state_id":"3963"},
    {"city_id":"46138","city_name":"Seward","state_id":"3963"},
    {"city_id":"46139","city_name":"Sewickley","state_id":"3963"},
    {"city_id":"46140","city_name":"Shaler Township","state_id":"3963"},
    {"city_id":"46141","city_name":"Sharon","state_id":"3963"},
    {"city_id":"46142","city_name":"Shermans Dale","state_id":"3963"},
    {"city_id":"46143","city_name":"Somerset","state_id":"3963"},
    {"city_id":"46144","city_name":"Souderton","state_id":"3963"},
    {"city_id":"46145","city_name":"South Park Township","state_id":"3963"},
    {"city_id":"46146","city_name":"Southampton","state_id":"3963"},
    {"city_id":"46147","city_name":"Springfield","state_id":"3963"},
    {"city_id":"46148","city_name":"State College","state_id":"3963"},
    {"city_id":"46149","city_name":"Strasburg","state_id":"3963"},
    {"city_id":"46150","city_name":"Sunbury","state_id":"3963"},
    {"city_id":"46151","city_name":"Susquehanna","state_id":"3963"},
    {"city_id":"46152","city_name":"Swissvale","state_id":"3963"},
    {"city_id":"46153","city_name":"Tamaqua","state_id":"3963"},
    {"city_id":"46154","city_name":"Taylor","state_id":"3963"},
    {"city_id":"46155","city_name":"Telford","state_id":"3963"},
    {"city_id":"46156","city_name":"Trevose","state_id":"3963"},
    {"city_id":"46157","city_name":"Turtle Creek","state_id":"3963"},
    {"city_id":"46158","city_name":"Tyrone","state_id":"3963"},
    {"city_id":"46159","city_name":"Uniontown","state_id":"3963"},
    {"city_id":"46160","city_name":"Upper Darby","state_id":"3963"},
    {"city_id":"46161","city_name":"Upper Providence Township","state_id":"3963"},
    {"city_id":"46162","city_name":"Upper Saint Clair","state_id":"3963"},
    {"city_id":"46163","city_name":"Vanderbilt","state_id":"3963"},
    {"city_id":"46164","city_name":"Warminster","state_id":"3963"},
    {"city_id":"46165","city_name":"Warren","state_id":"3963"},
    {"city_id":"46166","city_name":"Warrendale","state_id":"3963"},
    {"city_id":"46167","city_name":"Washington","state_id":"3963"},
    {"city_id":"46168","city_name":"Waterford","state_id":"3963"},
    {"city_id":"46169","city_name":"Waverly","state_id":"3963"},
    {"city_id":"46170","city_name":"Wayne","state_id":"3963"},
    {"city_id":"46171","city_name":"Waynesboro","state_id":"3963"},
    {"city_id":"46172","city_name":"West Chester","state_id":"3963"},
    {"city_id":"46173","city_name":"West Mifflin","state_id":"3963"},
    {"city_id":"46174","city_name":"West Norriton","state_id":"3963"},
    {"city_id":"46175","city_name":"West Point","state_id":"3963"},
    {"city_id":"46176","city_name":"Wexford","state_id":"3963"},
    {"city_id":"46177","city_name":"Whitehall","state_id":"3963"},
    {"city_id":"46178","city_name":"Wilcox","state_id":"3963"},
    {"city_id":"46179","city_name":"Wilkes-Barre","state_id":"3963"},
    {"city_id":"46180","city_name":"Wilkinsburg","state_id":"3963"},
    {"city_id":"46181","city_name":"Williamsport","state_id":"3963"},
    {"city_id":"46182","city_name":"Willow Grove","state_id":"3963"},
    {"city_id":"46183","city_name":"Womelsdorf","state_id":"3963"},
    {"city_id":"46184","city_name":"Woodlyn","state_id":"3963"},
    {"city_id":"46185","city_name":"Woolrich","state_id":"3963"},
    {"city_id":"46186","city_name":"Wyncote","state_id":"3963"},
    {"city_id":"46187","city_name":"Wyndmoor","state_id":"3963"},
    {"city_id":"46188","city_name":"Wynnewood","state_id":"3963"},
    {"city_id":"46189","city_name":"Yardley","state_id":"3963"},
    {"city_id":"46190","city_name":"Yeadon","state_id":"3963"},
    {"city_id":"46191","city_name":"York","state_id":"3963"},
    {"city_id":"46192","city_name":"Ramey","state_id":"3964"},
    {"city_id":"46193","city_name":"Adamsville","state_id":"3969"},
    {"city_id":"46194","city_name":"Alcoa","state_id":"3969"},
    {"city_id":"46195","city_name":"Antioch","state_id":"3969"},
    {"city_id":"46196","city_name":"Arlington","state_id":"3969"},
    {"city_id":"46197","city_name":"Athens","state_id":"3969"},
    {"city_id":"46198","city_name":"Bartlett","state_id":"3969"},
    {"city_id":"46199","city_name":"Bell Buckle","state_id":"3969"},
    {"city_id":"46200","city_name":"Bloomingdale","state_id":"3969"},
    {"city_id":"46201","city_name":"Blountville","state_id":"3969"},
    {"city_id":"46202","city_name":"Brentwood","state_id":"3969"},
    {"city_id":"46203","city_name":"Bristol","state_id":"3969"},
    {"city_id":"46204","city_name":"Brownsville","state_id":"3969"},
    {"city_id":"46205","city_name":"Burns","state_id":"3969"},
    {"city_id":"46206","city_name":"Chattanooga","state_id":"3969"},
    {"city_id":"46207","city_name":"Clarksville","state_id":"3969"},
    {"city_id":"46208","city_name":"Cleveland","state_id":"3969"},
    {"city_id":"46209","city_name":"Collierville","state_id":"3969"},
    {"city_id":"46210","city_name":"Columbia","state_id":"3969"},
    {"city_id":"46211","city_name":"Cookeville","state_id":"3969"},
    {"city_id":"46212","city_name":"Cornersville","state_id":"3969"},
    {"city_id":"46213","city_name":"Crossville","state_id":"3969"},
    {"city_id":"46214","city_name":"Dayton","state_id":"3969"},
    {"city_id":"46215","city_name":"Dickson","state_id":"3969"},
    {"city_id":"46216","city_name":"Dyersburg","state_id":"3969"},
    {"city_id":"46217","city_name":"East Brainerd","state_id":"3969"},
    {"city_id":"46218","city_name":"East Ridge","state_id":"3969"},
    {"city_id":"46219","city_name":"Elizabethton","state_id":"3969"},
    {"city_id":"46220","city_name":"Farragut","state_id":"3969"},
    {"city_id":"46221","city_name":"Franklin","state_id":"3969"},
    {"city_id":"46222","city_name":"Gainesboro","state_id":"3969"},
    {"city_id":"46223","city_name":"Gallatin","state_id":"3969"},
    {"city_id":"46224","city_name":"Gatlinburg","state_id":"3969"},
    {"city_id":"46225","city_name":"Germantown","state_id":"3969"},
    {"city_id":"46226","city_name":"Goodlettsville","state_id":"3969"},
    {"city_id":"46227","city_name":"Greeneville","state_id":"3969"},
    {"city_id":"46228","city_name":"Hendersonville","state_id":"3969"},
    {"city_id":"46229","city_name":"Hixson","state_id":"3969"},
    {"city_id":"46230","city_name":"Jackson","state_id":"3969"},
    {"city_id":"46231","city_name":"Johnson City","state_id":"3969"},
    {"city_id":"46232","city_name":"Kingsport","state_id":"3969"},
    {"city_id":"46233","city_name":"Knoxville","state_id":"3969"},
    {"city_id":"46234","city_name":"Kodak","state_id":"3969"},
    {"city_id":"46235","city_name":"La Vergne","state_id":"3969"},
    {"city_id":"46236","city_name":"Lawrenceburg","state_id":"3969"},
    {"city_id":"46237","city_name":"Lebanon","state_id":"3969"},
    {"city_id":"46238","city_name":"Lenoir City","state_id":"3969"},
    {"city_id":"46239","city_name":"Lewisburg","state_id":"3969"},
    {"city_id":"46240","city_name":"MacMinnville","state_id":"3969"},
    {"city_id":"46241","city_name":"Maryville","state_id":"3969"},
    {"city_id":"46242","city_name":"Memphis","state_id":"3969"},
    {"city_id":"46243","city_name":"Middle Valley","state_id":"3969"},
    {"city_id":"46244","city_name":"Millington","state_id":"3969"},
    {"city_id":"46245","city_name":"Morristown","state_id":"3969"},
    {"city_id":"46246","city_name":"Mulberry","state_id":"3969"},
    {"city_id":"46247","city_name":"Murfreesboro","state_id":"3969"},
    {"city_id":"46248","city_name":"Nashville","state_id":"3969"},
    {"city_id":"46249","city_name":"Oak Ridge","state_id":"3969"},
    {"city_id":"46250","city_name":"Ooltewah","state_id":"3969"},
    {"city_id":"46251","city_name":"Pinson","state_id":"3969"},
    {"city_id":"46252","city_name":"Red Bank","state_id":"3969"},
    {"city_id":"46253","city_name":"Selmer","state_id":"3969"},
    {"city_id":"46254","city_name":"Sevierville","state_id":"3969"},
    {"city_id":"46255","city_name":"Shelbyville","state_id":"3969"},
    {"city_id":"46256","city_name":"Smithville","state_id":"3969"},
    {"city_id":"46257","city_name":"Smyrna","state_id":"3969"},
    {"city_id":"46258","city_name":"Spring City","state_id":"3969"},
    {"city_id":"46259","city_name":"Springfield","state_id":"3969"},
    {"city_id":"46260","city_name":"Tazewell","state_id":"3969"},
    {"city_id":"46261","city_name":"Trenton","state_id":"3969"},
    {"city_id":"46262","city_name":"Tullahoma","state_id":"3969"},
    {"city_id":"46263","city_name":"Union City","state_id":"3969"},
    {"city_id":"46264","city_name":"Abilene","state_id":"3970"},
    {"city_id":"46265","city_name":"Addison","state_id":"3970"},
    {"city_id":"46266","city_name":"Alamo","state_id":"3970"},
    {"city_id":"46267","city_name":"Aldine","state_id":"3970"},
    {"city_id":"46268","city_name":"Alice","state_id":"3970"},
    {"city_id":"46269","city_name":"Allen","state_id":"3970"},
    {"city_id":"46270","city_name":"Alvin","state_id":"3970"},
    {"city_id":"46271","city_name":"Amarillo","state_id":"3970"},
    {"city_id":"46272","city_name":"Anderson Mill","state_id":"3970"},
    {"city_id":"46273","city_name":"Andrews","state_id":"3970"},
    {"city_id":"46274","city_name":"Angleton","state_id":"3970"},
    {"city_id":"46275","city_name":"Argyle","state_id":"3970"},
    {"city_id":"46276","city_name":"Arlington","state_id":"3970"},
    {"city_id":"46277","city_name":"Aspermont","state_id":"3970"},
    {"city_id":"46278","city_name":"Atascocita","state_id":"3970"},
    {"city_id":"46279","city_name":"Athens","state_id":"3970"},
    {"city_id":"46280","city_name":"Austin","state_id":"3970"},
    {"city_id":"46281","city_name":"Austinn","state_id":"3970"},
    {"city_id":"46282","city_name":"Azle","state_id":"3970"},
    {"city_id":"46283","city_name":"Balch Springs","state_id":"3970"},
    {"city_id":"46284","city_name":"Barry","state_id":"3970"},
    {"city_id":"46285","city_name":"Bay City","state_id":"3970"},
    {"city_id":"46286","city_name":"Baytown","state_id":"3970"},
    {"city_id":"46287","city_name":"Beaumont","state_id":"3970"},
    {"city_id":"46288","city_name":"Bedford","state_id":"3970"},
    {"city_id":"46289","city_name":"Beeville","state_id":"3970"},
    {"city_id":"46290","city_name":"Bellaire","state_id":"3970"},
    {"city_id":"46291","city_name":"Belton","state_id":"3970"},
    {"city_id":"46292","city_name":"Benbrook","state_id":"3970"},
    {"city_id":"46293","city_name":"Big Spring","state_id":"3970"},
    {"city_id":"46294","city_name":"Bluff Dale","state_id":"3970"},
    {"city_id":"46295","city_name":"Boerne","state_id":"3970"},
    {"city_id":"46296","city_name":"Borger","state_id":"3970"},
    {"city_id":"46297","city_name":"Breckenridge","state_id":"3970"},
    {"city_id":"46298","city_name":"Brenham","state_id":"3970"},
    {"city_id":"46299","city_name":"Brownfield","state_id":"3970"},
    {"city_id":"46300","city_name":"Brownsville","state_id":"3970"},
    {"city_id":"46301","city_name":"Brownwood","state_id":"3970"},
    {"city_id":"46302","city_name":"Bryan","state_id":"3970"},
    {"city_id":"46303","city_name":"Buda","state_id":"3970"},
    {"city_id":"46304","city_name":"Burkburnett","state_id":"3970"},
    {"city_id":"46305","city_name":"Burleson","state_id":"3970"},
    {"city_id":"46306","city_name":"Campbell","state_id":"3970"},
    {"city_id":"46307","city_name":"Canyon","state_id":"3970"},
    {"city_id":"46308","city_name":"Canyon Lake","state_id":"3970"},
    {"city_id":"46309","city_name":"Carrollton","state_id":"3970"},
    {"city_id":"46310","city_name":"Cat Spring","state_id":"3970"},
    {"city_id":"46311","city_name":"Cedar Hill","state_id":"3970"},
    {"city_id":"46312","city_name":"Cedar Park","state_id":"3970"},
    {"city_id":"46313","city_name":"Celina","state_id":"3970"},
    {"city_id":"46314","city_name":"Center","state_id":"3970"},
    {"city_id":"46315","city_name":"Channelview","state_id":"3970"},
    {"city_id":"46316","city_name":"City of Dallas","state_id":"3970"},
    {"city_id":"46317","city_name":"Cleburne","state_id":"3970"},
    {"city_id":"46318","city_name":"Cloverleaf","state_id":"3970"},
    {"city_id":"46319","city_name":"Clute","state_id":"3970"},
    {"city_id":"46320","city_name":"College Station","state_id":"3970"},
    {"city_id":"46321","city_name":"Colleyville","state_id":"3970"},
    {"city_id":"46322","city_name":"Columbus","state_id":"3970"},
    {"city_id":"46323","city_name":"Comanche","state_id":"3970"},
    {"city_id":"46324","city_name":"Conroe","state_id":"3970"},
    {"city_id":"46325","city_name":"Converse","state_id":"3970"},
    {"city_id":"46326","city_name":"Coppell","state_id":"3970"},
    {"city_id":"46327","city_name":"Copperas Cove","state_id":"3970"},
    {"city_id":"46328","city_name":"Corinth","state_id":"3970"},
    {"city_id":"46329","city_name":"Corpus Christi","state_id":"3970"},
    {"city_id":"46330","city_name":"Corsicana","state_id":"3970"},
    {"city_id":"46331","city_name":"Cotulla","state_id":"3970"},
    {"city_id":"46332","city_name":"Crandall","state_id":"3970"},
    {"city_id":"46333","city_name":"Cypress","state_id":"3970"},
    {"city_id":"46334","city_name":"Dallas","state_id":"3970"},
    {"city_id":"46335","city_name":"Dayton","state_id":"3970"},
    {"city_id":"46336","city_name":"DeSoto","state_id":"3970"},
    {"city_id":"46337","city_name":"Deer Park","state_id":"3970"},
    {"city_id":"46338","city_name":"Del Rio","state_id":"3970"},
    {"city_id":"46339","city_name":"Denison","state_id":"3970"},
    {"city_id":"46340","city_name":"Denton","state_id":"3970"},
    {"city_id":"46341","city_name":"Dickinson","state_id":"3970"},
    {"city_id":"46342","city_name":"Donna","state_id":"3970"},
    {"city_id":"46343","city_name":"Dumas","state_id":"3970"},
    {"city_id":"46344","city_name":"Duncanville","state_id":"3970"},
    {"city_id":"46345","city_name":"Eagle Pass","state_id":"3970"},
    {"city_id":"46346","city_name":"Edinburg","state_id":"3970"},
    {"city_id":"46347","city_name":"El Campo","state_id":"3970"},
    {"city_id":"46348","city_name":"El Paso","state_id":"3970"},
    {"city_id":"46349","city_name":"Elmendorf","state_id":"3970"},
    {"city_id":"46350","city_name":"Ennis","state_id":"3970"},
    {"city_id":"46351","city_name":"Euless","state_id":"3970"},
    {"city_id":"46352","city_name":"Fairfield","state_id":"3970"},
    {"city_id":"46353","city_name":"Farmers Branch","state_id":"3970"},
    {"city_id":"46354","city_name":"Flower Mound","state_id":"3970"},
    {"city_id":"46355","city_name":"Forest Hill","state_id":"3970"},
    {"city_id":"46356","city_name":"Forney","state_id":"3970"},
    {"city_id":"46357","city_name":"Fort Bliss","state_id":"3970"},
    {"city_id":"46358","city_name":"Fort Hood","state_id":"3970"},
    {"city_id":"46359","city_name":"Fort Worth","state_id":"3970"},
    {"city_id":"46360","city_name":"Freeport","state_id":"3970"},
    {"city_id":"46361","city_name":"Friendswood","state_id":"3970"},
    {"city_id":"46362","city_name":"Frisco","state_id":"3970"},
    {"city_id":"46363","city_name":"Gainesville","state_id":"3970"},
    {"city_id":"46364","city_name":"Galena Park","state_id":"3970"},
    {"city_id":"46365","city_name":"Galveston","state_id":"3970"},
    {"city_id":"46366","city_name":"Garland","state_id":"3970"},
    {"city_id":"46367","city_name":"Gatesville","state_id":"3970"},
    {"city_id":"46368","city_name":"Georgetown","state_id":"3970"},
    {"city_id":"46369","city_name":"Grand Prairie","state_id":"3970"},
    {"city_id":"46370","city_name":"Grandview","state_id":"3970"},
    {"city_id":"46371","city_name":"Grapeland","state_id":"3970"},
    {"city_id":"46372","city_name":"Grapevine","state_id":"3970"},
    {"city_id":"46373","city_name":"Greenville","state_id":"3970"},
    {"city_id":"46374","city_name":"Gregory","state_id":"3970"},
    {"city_id":"46375","city_name":"Groves","state_id":"3970"},
    {"city_id":"46376","city_name":"Haltom City","state_id":"3970"},
    {"city_id":"46377","city_name":"Harker Heights","state_id":"3970"},
    {"city_id":"46378","city_name":"Harlingen","state_id":"3970"},
    {"city_id":"46379","city_name":"Henderson","state_id":"3970"},
    {"city_id":"46380","city_name":"Hereford","state_id":"3970"},
    {"city_id":"46381","city_name":"Hewitt","state_id":"3970"},
    {"city_id":"46382","city_name":"Highland Village","state_id":"3970"},
    {"city_id":"46383","city_name":"Hillsboro","state_id":"3970"},
    {"city_id":"46384","city_name":"Houston","state_id":"3970"},
    {"city_id":"46385","city_name":"Humble","state_id":"3970"},
    {"city_id":"46386","city_name":"Huntsville","state_id":"3970"},
    {"city_id":"46387","city_name":"Hurst","state_id":"3970"},
    {"city_id":"46388","city_name":"Ingleside","state_id":"3970"},
    {"city_id":"46389","city_name":"Irving","state_id":"3970"},
    {"city_id":"46390","city_name":"Jacksonville","state_id":"3970"},
    {"city_id":"46391","city_name":"Jefferson","state_id":"3970"},
    {"city_id":"46392","city_name":"Jollyville","state_id":"3970"},
    {"city_id":"46393","city_name":"Justin","state_id":"3970"},
    {"city_id":"46394","city_name":"Katy","state_id":"3970"},
    {"city_id":"46395","city_name":"Kaufman","state_id":"3970"},
    {"city_id":"46396","city_name":"Keller","state_id":"3970"},
    {"city_id":"46397","city_name":"Kemah","state_id":"3970"},
    {"city_id":"46398","city_name":"Kemp","state_id":"3970"},
    {"city_id":"46399","city_name":"Kerrville","state_id":"3970"},
    {"city_id":"46400","city_name":"Kilgore","state_id":"3970"},
    {"city_id":"46401","city_name":"Killeen","state_id":"3970"},
    {"city_id":"46402","city_name":"Kingsville","state_id":"3970"},
    {"city_id":"46403","city_name":"Kingwood","state_id":"3970"},
    {"city_id":"46404","city_name":"La Marque","state_id":"3970"},
    {"city_id":"46405","city_name":"La Porte","state_id":"3970"},
    {"city_id":"46406","city_name":"Lackland Air Force Base","state_id":"3970"},
    {"city_id":"46407","city_name":"Lago Vista","state_id":"3970"},
    {"city_id":"46408","city_name":"Lake Jackson","state_id":"3970"},
    {"city_id":"46409","city_name":"Lamesa","state_id":"3970"},
    {"city_id":"46410","city_name":"Lampasas","state_id":"3970"},
    {"city_id":"46411","city_name":"Lancaster","state_id":"3970"},
    {"city_id":"46412","city_name":"Laredo","state_id":"3970"},
    {"city_id":"46413","city_name":"League City","state_id":"3970"},
    {"city_id":"46414","city_name":"Leon Valley","state_id":"3970"},
    {"city_id":"46415","city_name":"Levelland","state_id":"3970"},
    {"city_id":"46416","city_name":"Lewisville","state_id":"3970"},
    {"city_id":"46417","city_name":"Liberty Hill","state_id":"3970"},
    {"city_id":"46418","city_name":"Lindsay","state_id":"3970"},
    {"city_id":"46419","city_name":"Little Elm","state_id":"3970"},
    {"city_id":"46420","city_name":"Live Oak","state_id":"3970"},
    {"city_id":"46421","city_name":"Llano","state_id":"3970"},
    {"city_id":"46422","city_name":"Lockhart","state_id":"3970"},
    {"city_id":"46423","city_name":"Longview","state_id":"3970"},
    {"city_id":"46424","city_name":"Lubbock","state_id":"3970"},
    {"city_id":"46425","city_name":"Lufkin","state_id":"3970"},
    {"city_id":"46426","city_name":"Lumberton","state_id":"3970"},
    {"city_id":"46427","city_name":"MacAllen","state_id":"3970"},
    {"city_id":"46428","city_name":"MacKinney","state_id":"3970"},
    {"city_id":"46429","city_name":"Magnolia","state_id":"3970"},
    {"city_id":"46430","city_name":"Malakoff","state_id":"3970"},
    {"city_id":"46431","city_name":"Mansfield","state_id":"3970"},
    {"city_id":"46432","city_name":"Marshall","state_id":"3970"},
    {"city_id":"46433","city_name":"McAllen","state_id":"3970"},
    {"city_id":"46434","city_name":"McKinney","state_id":"3970"},
    {"city_id":"46435","city_name":"Medina","state_id":"3970"},
    {"city_id":"46436","city_name":"Mercedes","state_id":"3970"},
    {"city_id":"46437","city_name":"Mesquite","state_id":"3970"},
    {"city_id":"46438","city_name":"Midland","state_id":"3970"},
    {"city_id":"46439","city_name":"Mineral Wells","state_id":"3970"},
    {"city_id":"46440","city_name":"Mission","state_id":"3970"},
    {"city_id":"46441","city_name":"Mission Bend","state_id":"3970"},
    {"city_id":"46442","city_name":"Missouri City","state_id":"3970"},
    {"city_id":"46443","city_name":"Montgomery","state_id":"3970"},
    {"city_id":"46444","city_name":"Mount Pleasant","state_id":"3970"},
    {"city_id":"46445","city_name":"Murphy","state_id":"3970"},
    {"city_id":"46446","city_name":"Nacogdoches","state_id":"3970"},
    {"city_id":"46447","city_name":"Nederland","state_id":"3970"},
    {"city_id":"46448","city_name":"New Braunfels","state_id":"3970"},
    {"city_id":"46449","city_name":"New Caney","state_id":"3970"},
    {"city_id":"46450","city_name":"North Richland Hills","state_id":"3970"},
    {"city_id":"46451","city_name":"North Zulch","state_id":"3970"},
    {"city_id":"46452","city_name":"Odessa","state_id":"3970"},
    {"city_id":"46453","city_name":"Orange","state_id":"3970"},
    {"city_id":"46454","city_name":"Ovalo","state_id":"3970"},
    {"city_id":"46455","city_name":"Palestine","state_id":"3970"},
    {"city_id":"46456","city_name":"Pampa","state_id":"3970"},
    {"city_id":"46457","city_name":"Paris","state_id":"3970"},
    {"city_id":"46458","city_name":"Pasadena","state_id":"3970"},
    {"city_id":"46459","city_name":"Pearland","state_id":"3970"},
    {"city_id":"46460","city_name":"Pecan Grove","state_id":"3970"},
    {"city_id":"46461","city_name":"Pecos","state_id":"3970"},
    {"city_id":"46462","city_name":"Pflugerville","state_id":"3970"},
    {"city_id":"46463","city_name":"Pharr","state_id":"3970"},
    {"city_id":"46464","city_name":"Pinehurst","state_id":"3970"},
    {"city_id":"46465","city_name":"Plainview","state_id":"3970"},
    {"city_id":"46466","city_name":"Plano","state_id":"3970"},
    {"city_id":"46467","city_name":"Pontotoc","state_id":"3970"},
    {"city_id":"46468","city_name":"Port Arthur","state_id":"3970"},
    {"city_id":"46469","city_name":"Port Lavaca","state_id":"3970"},
    {"city_id":"46470","city_name":"Port Neches","state_id":"3970"},
    {"city_id":"46471","city_name":"Portland","state_id":"3970"},
    {"city_id":"46472","city_name":"Pottsboro","state_id":"3970"},
    {"city_id":"46473","city_name":"Princeton","state_id":"3970"},
    {"city_id":"46474","city_name":"Richardson","state_id":"3970"},
    {"city_id":"46475","city_name":"Richmond","state_id":"3970"},
    {"city_id":"46476","city_name":"Rio Grande City","state_id":"3970"},
    {"city_id":"46477","city_name":"Robstown","state_id":"3970"},
    {"city_id":"46478","city_name":"Rockport","state_id":"3970"},
    {"city_id":"46479","city_name":"Rockwall","state_id":"3970"},
    {"city_id":"46480","city_name":"Roma","state_id":"3970"},
    {"city_id":"46481","city_name":"Rosenberg","state_id":"3970"},
    {"city_id":"46482","city_name":"Round Rock","state_id":"3970"},
    {"city_id":"46483","city_name":"Rowlett","state_id":"3970"},
    {"city_id":"46484","city_name":"Royse City","state_id":"3970"},
    {"city_id":"46485","city_name":"Sachse","state_id":"3970"},
    {"city_id":"46486","city_name":"Saginaw","state_id":"3970"},
    {"city_id":"46487","city_name":"San Angelo","state_id":"3970"},
    {"city_id":"46488","city_name":"San Antonio","state_id":"3970"},
    {"city_id":"46489","city_name":"San Benito","state_id":"3970"},
    {"city_id":"46490","city_name":"San Juan","state_id":"3970"},
    {"city_id":"46491","city_name":"San Marcos","state_id":"3970"},
    {"city_id":"46492","city_name":"Santa Fe","state_id":"3970"},
    {"city_id":"46493","city_name":"Schertz","state_id":"3970"},
    {"city_id":"46494","city_name":"Seabrook","state_id":"3970"},
    {"city_id":"46495","city_name":"Seagoville","state_id":"3970"},
    {"city_id":"46496","city_name":"Seguin","state_id":"3970"},
    {"city_id":"46497","city_name":"Sherman","state_id":"3970"},
    {"city_id":"46498","city_name":"Slaton","state_id":"3970"},
    {"city_id":"46499","city_name":"Smithville","state_id":"3970"},
    {"city_id":"46500","city_name":"Snyder","state_id":"3970"},
    {"city_id":"46501","city_name":"Socorro","state_id":"3970"},
    {"city_id":"46502","city_name":"South Houston","state_id":"3970"},
    {"city_id":"46503","city_name":"South Padre Island","state_id":"3970"},
    {"city_id":"46504","city_name":"Southlake","state_id":"3970"},
    {"city_id":"46505","city_name":"Spring","state_id":"3970"},
    {"city_id":"46506","city_name":"Stafford","state_id":"3970"},
    {"city_id":"46507","city_name":"Stephenville","state_id":"3970"},
    {"city_id":"46508","city_name":"Strawn","state_id":"3970"},
    {"city_id":"46509","city_name":"Sugar Land","state_id":"3970"},
    {"city_id":"46510","city_name":"Sulphur Springs","state_id":"3970"},
    {"city_id":"46511","city_name":"Sweetwater","state_id":"3970"},
    {"city_id":"46512","city_name":"Taylor","state_id":"3970"},
    {"city_id":"46513","city_name":"Temple","state_id":"3970"},
    {"city_id":"46514","city_name":"Terrell","state_id":"3970"},
    {"city_id":"46515","city_name":"Texarkana","state_id":"3970"},
    {"city_id":"46516","city_name":"Texas City","state_id":"3970"},
    {"city_id":"46517","city_name":"The Colony","state_id":"3970"},
    {"city_id":"46518","city_name":"The Woodlands","state_id":"3970"},
    {"city_id":"46519","city_name":"Tomball","state_id":"3970"},
    {"city_id":"46520","city_name":"Tyler","state_id":"3970"},
    {"city_id":"46521","city_name":"Universal City","state_id":"3970"},
    {"city_id":"46522","city_name":"University Park","state_id":"3970"},
    {"city_id":"46523","city_name":"Uvalde","state_id":"3970"},
    {"city_id":"46524","city_name":"Vernon","state_id":"3970"},
    {"city_id":"46525","city_name":"Victoria","state_id":"3970"},
    {"city_id":"46526","city_name":"Vidor","state_id":"3970"},
    {"city_id":"46527","city_name":"Waco","state_id":"3970"},
    {"city_id":"46528","city_name":"Watauga","state_id":"3970"},
    {"city_id":"46529","city_name":"Waxahachie","state_id":"3970"},
    {"city_id":"46530","city_name":"Weatherford","state_id":"3970"},
    {"city_id":"46531","city_name":"Weslaco","state_id":"3970"},
    {"city_id":"46532","city_name":"West Odessa","state_id":"3970"},
    {"city_id":"46533","city_name":"West University Place","state_id":"3970"},
    {"city_id":"46534","city_name":"White Settlement","state_id":"3970"},
    {"city_id":"46535","city_name":"Wichita Falls","state_id":"3970"},
    {"city_id":"46536","city_name":"Winnsboro","state_id":"3970"},
    {"city_id":"46537","city_name":"Woodway","state_id":"3970"},
    {"city_id":"46538","city_name":"Wylie","state_id":"3970"},
    {"city_id":"46539","city_name":"Yoakum","state_id":"3970"},
    {"city_id":"46540","city_name":"austinn","state_id":"3970"},
    {"city_id":"46541","city_name":"Bedford Kentucky","state_id":"3971"},
    {"city_id":"46542","city_name":"Alpine","state_id":"3972"},
    {"city_id":"46543","city_name":"American Fork","state_id":"3972"},
    {"city_id":"46544","city_name":"Bluffdale","state_id":"3972"},
    {"city_id":"46545","city_name":"Bountiful","state_id":"3972"},
    {"city_id":"46546","city_name":"Brigham City","state_id":"3972"},
    {"city_id":"46547","city_name":"Canyon Rim","state_id":"3972"},
    {"city_id":"46548","city_name":"Castle Dale","state_id":"3972"},
    {"city_id":"46549","city_name":"Cedar City","state_id":"3972"},
    {"city_id":"46550","city_name":"Centerville","state_id":"3972"},
    {"city_id":"46551","city_name":"Clearfield","state_id":"3972"},
    {"city_id":"46552","city_name":"Clinton","state_id":"3972"},
    {"city_id":"46553","city_name":"Cottonwood Heights","state_id":"3972"},
    {"city_id":"46554","city_name":"Cottonwood West","state_id":"3972"},
    {"city_id":"46555","city_name":"Draper","state_id":"3972"},
    {"city_id":"46556","city_name":"East Millcreek","state_id":"3972"},
    {"city_id":"46557","city_name":"Farmington","state_id":"3972"},
    {"city_id":"46558","city_name":"Holladay-Cottonwood","state_id":"3972"},
    {"city_id":"46559","city_name":"Ivins","state_id":"3972"},
    {"city_id":"46560","city_name":"Kaysville","state_id":"3972"},
    {"city_id":"46561","city_name":"Kearns","state_id":"3972"},
    {"city_id":"46562","city_name":"Layton","state_id":"3972"},
    {"city_id":"46563","city_name":"Lehi","state_id":"3972"},
    {"city_id":"46564","city_name":"Logan","state_id":"3972"},
    {"city_id":"46565","city_name":"Magna","state_id":"3972"},
    {"city_id":"46566","city_name":"Mapleton","state_id":"3972"},
    {"city_id":"46567","city_name":"Midvale","state_id":"3972"},
    {"city_id":"46568","city_name":"Millcreek","state_id":"3972"},
    {"city_id":"46569","city_name":"Moab","state_id":"3972"},
    {"city_id":"46570","city_name":"Monticello","state_id":"3972"},
    {"city_id":"46571","city_name":"Murray","state_id":"3972"},
    {"city_id":"46572","city_name":"North Logan","state_id":"3972"},
    {"city_id":"46573","city_name":"North Ogden","state_id":"3972"},
    {"city_id":"46574","city_name":"Ogden","state_id":"3972"},
    {"city_id":"46575","city_name":"Orem","state_id":"3972"},
    {"city_id":"46576","city_name":"Panguitch","state_id":"3972"},
    {"city_id":"46577","city_name":"Park City","state_id":"3972"},
    {"city_id":"46578","city_name":"Payson","state_id":"3972"},
    {"city_id":"46579","city_name":"Pleasant Grove","state_id":"3972"},
    {"city_id":"46580","city_name":"Provo","state_id":"3972"},
    {"city_id":"46581","city_name":"Riverton","state_id":"3972"},
    {"city_id":"46582","city_name":"Roy","state_id":"3972"},
    {"city_id":"46583","city_name":"Saint George","state_id":"3972"},
    {"city_id":"46584","city_name":"Salt Lake City","state_id":"3972"},
    {"city_id":"46585","city_name":"Sandy","state_id":"3972"},
    {"city_id":"46586","city_name":"Santaquin","state_id":"3972"},
    {"city_id":"46587","city_name":"South Jordan","state_id":"3972"},
    {"city_id":"46588","city_name":"South Ogden","state_id":"3972"},
    {"city_id":"46589","city_name":"South Salt Lake","state_id":"3972"},
    {"city_id":"46590","city_name":"Spanish Fork","state_id":"3972"},
    {"city_id":"46591","city_name":"Springville","state_id":"3972"},
    {"city_id":"46592","city_name":"Taylorsville","state_id":"3972"},
    {"city_id":"46593","city_name":"Tooele","state_id":"3972"},
    {"city_id":"46594","city_name":"Tremonton","state_id":"3972"},
    {"city_id":"46595","city_name":"Union","state_id":"3972"},
    {"city_id":"46596","city_name":"Washington","state_id":"3972"},
    {"city_id":"46597","city_name":"West Jordan","state_id":"3972"},
    {"city_id":"46598","city_name":"West Valley City","state_id":"3972"},
    {"city_id":"46599","city_name":"Woods Cross","state_id":"3972"},
    {"city_id":"46600","city_name":"Barre","state_id":"3973"},
    {"city_id":"46601","city_name":"Bennington","state_id":"3973"},
    {"city_id":"46602","city_name":"Brattleboro","state_id":"3973"},
    {"city_id":"46603","city_name":"Bristol","state_id":"3973"},
    {"city_id":"46604","city_name":"Burlington","state_id":"3973"},
    {"city_id":"46605","city_name":"Cabot","state_id":"3973"},
    {"city_id":"46606","city_name":"Colchester","state_id":"3973"},
    {"city_id":"46607","city_name":"Danville","state_id":"3973"},
    {"city_id":"46608","city_name":"Dorset","state_id":"3973"},
    {"city_id":"46609","city_name":"Dummerston","state_id":"3973"},
    {"city_id":"46610","city_name":"East Corinth","state_id":"3973"},
    {"city_id":"46611","city_name":"East Fairfield","state_id":"3973"},
    {"city_id":"46612","city_name":"East Randolph","state_id":"3973"},
    {"city_id":"46613","city_name":"Essex","state_id":"3973"},
    {"city_id":"46614","city_name":"Essex Junction","state_id":"3973"},
    {"city_id":"46615","city_name":"Grand Isle","state_id":"3973"},
    {"city_id":"46616","city_name":"Hartford","state_id":"3973"},
    {"city_id":"46617","city_name":"Jericho","state_id":"3973"},
    {"city_id":"46618","city_name":"Manchester","state_id":"3973"},
    {"city_id":"46619","city_name":"Manchester Center","state_id":"3973"},
    {"city_id":"46620","city_name":"Middlebury","state_id":"3973"},
    {"city_id":"46621","city_name":"Milton","state_id":"3973"},
    {"city_id":"46622","city_name":"Montpelier","state_id":"3973"},
    {"city_id":"46623","city_name":"Putney","state_id":"3973"},
    {"city_id":"46624","city_name":"Randolph","state_id":"3973"},
    {"city_id":"46625","city_name":"Rochester","state_id":"3973"},
    {"city_id":"46626","city_name":"Rutland","state_id":"3973"},
    {"city_id":"46627","city_name":"Saint Albans","state_id":"3973"},
    {"city_id":"46628","city_name":"Saint Johnsbury","state_id":"3973"},
    {"city_id":"46629","city_name":"Saxtons River","state_id":"3973"},
    {"city_id":"46630","city_name":"South Burlington","state_id":"3973"},
    {"city_id":"46631","city_name":"South Strafford","state_id":"3973"},
    {"city_id":"46632","city_name":"Springfield","state_id":"3973"},
    {"city_id":"46633","city_name":"Townshend","state_id":"3973"},
    {"city_id":"46634","city_name":"Tunbridge","state_id":"3973"},
    {"city_id":"46635","city_name":"Van","state_id":"3973"},
    {"city_id":"46636","city_name":"Vernon","state_id":"3973"},
    {"city_id":"46637","city_name":"Wallingford","state_id":"3973"},
    {"city_id":"46638","city_name":"Watisfield","state_id":"3973"},
    {"city_id":"46639","city_name":"West Brookfield","state_id":"3973"},
    {"city_id":"46640","city_name":"West Charleston","state_id":"3973"},
    {"city_id":"46641","city_name":"West Newbury","state_id":"3973"},
    {"city_id":"46642","city_name":"Williston","state_id":"3973"},
    {"city_id":"46643","city_name":"Winooski","state_id":"3973"},
    {"city_id":"46644","city_name":"Abingdon","state_id":"3974"},
    {"city_id":"46645","city_name":"Alexandria","state_id":"3974"},
    {"city_id":"46646","city_name":"Annandale","state_id":"3974"},
    {"city_id":"46647","city_name":"Arlington","state_id":"3974"},
    {"city_id":"46648","city_name":"Ashburn","state_id":"3974"},
    {"city_id":"46649","city_name":"Ashland","state_id":"3974"},
    {"city_id":"46650","city_name":"Aylett","state_id":"3974"},
    {"city_id":"46651","city_name":"Bailey's Crossroads","state_id":"3974"},
    {"city_id":"46652","city_name":"Blacksburg","state_id":"3974"},
    {"city_id":"46653","city_name":"Bland","state_id":"3974"},
    {"city_id":"46654","city_name":"Bluefield","state_id":"3974"},
    {"city_id":"46655","city_name":"Bon Air","state_id":"3974"},
    {"city_id":"46656","city_name":"Bristol","state_id":"3974"},
    {"city_id":"46657","city_name":"Burke","state_id":"3974"},
    {"city_id":"46658","city_name":"Cave Spring","state_id":"3974"},
    {"city_id":"46659","city_name":"Centreville","state_id":"3974"},
    {"city_id":"46660","city_name":"Chantilly","state_id":"3974"},
    {"city_id":"46661","city_name":"Charlottesville","state_id":"3974"},
    {"city_id":"46662","city_name":"Chesapeake","state_id":"3974"},
    {"city_id":"46663","city_name":"Chester","state_id":"3974"},
    {"city_id":"46664","city_name":"Christiansburg","state_id":"3974"},
    {"city_id":"46665","city_name":"Churchville","state_id":"3974"},
    {"city_id":"46666","city_name":"Clifton","state_id":"3974"},
    {"city_id":"46667","city_name":"Colonial Heights","state_id":"3974"},
    {"city_id":"46668","city_name":"Culloden","state_id":"3974"},
    {"city_id":"46669","city_name":"Dale City","state_id":"3974"},
    {"city_id":"46670","city_name":"Danville","state_id":"3974"},
    {"city_id":"46671","city_name":"Dublin","state_id":"3974"},
    {"city_id":"46672","city_name":"Eagle Rock","state_id":"3974"},
    {"city_id":"46673","city_name":"East Highland Park","state_id":"3974"},
    {"city_id":"46674","city_name":"Faber","state_id":"3974"},
    {"city_id":"46675","city_name":"Fairfax","state_id":"3974"},
    {"city_id":"46676","city_name":"Falls Church","state_id":"3974"},
    {"city_id":"46677","city_name":"Fishersville","state_id":"3974"},
    {"city_id":"46678","city_name":"Fort Hunt","state_id":"3974"},
    {"city_id":"46679","city_name":"Fort Valley","state_id":"3974"},
    {"city_id":"46680","city_name":"Franconia","state_id":"3974"},
    {"city_id":"46681","city_name":"Franklin","state_id":"3974"},
    {"city_id":"46682","city_name":"Fredericksburg","state_id":"3974"},
    {"city_id":"46683","city_name":"Front Royal","state_id":"3974"},
    {"city_id":"46684","city_name":"Gainesville","state_id":"3974"},
    {"city_id":"46685","city_name":"Glen Allen","state_id":"3974"},
    {"city_id":"46686","city_name":"Gloucester","state_id":"3974"},
    {"city_id":"46687","city_name":"Goochland","state_id":"3974"},
    {"city_id":"46688","city_name":"Great Falls","state_id":"3974"},
    {"city_id":"46689","city_name":"Groveton","state_id":"3974"},
    {"city_id":"46690","city_name":"Hampton","state_id":"3974"},
    {"city_id":"46691","city_name":"Harrisonburg","state_id":"3974"},
    {"city_id":"46692","city_name":"Henrico","state_id":"3974"},
    {"city_id":"46693","city_name":"Herndon","state_id":"3974"},
    {"city_id":"46694","city_name":"Highland Springs","state_id":"3974"},
    {"city_id":"46695","city_name":"Hollins","state_id":"3974"},
    {"city_id":"46696","city_name":"Hopewell","state_id":"3974"},
    {"city_id":"46697","city_name":"Hybla Valley","state_id":"3974"},
    {"city_id":"46698","city_name":"Idylwood","state_id":"3974"},
    {"city_id":"46699","city_name":"Irvington","state_id":"3974"},
    {"city_id":"46700","city_name":"Jamesville","state_id":"3974"},
    {"city_id":"46701","city_name":"Jefferson","state_id":"3974"},
    {"city_id":"46702","city_name":"Keen Mountain","state_id":"3974"},
    {"city_id":"46703","city_name":"Keswick","state_id":"3974"},
    {"city_id":"46704","city_name":"Lake Ridge","state_id":"3974"},
    {"city_id":"46705","city_name":"Lakeside","state_id":"3974"},
    {"city_id":"46706","city_name":"Laurel","state_id":"3974"},
    {"city_id":"46707","city_name":"Leesburg","state_id":"3974"},
    {"city_id":"46708","city_name":"Lincolnia","state_id":"3974"},
    {"city_id":"46709","city_name":"Lorton","state_id":"3974"},
    {"city_id":"46710","city_name":"Lynchburg","state_id":"3974"},
    {"city_id":"46711","city_name":"MacLean","state_id":"3974"},
    {"city_id":"46712","city_name":"Madison","state_id":"3974"},
    {"city_id":"46713","city_name":"Madison Heights","state_id":"3974"},
    {"city_id":"46714","city_name":"Manassas","state_id":"3974"},
    {"city_id":"46715","city_name":"Marion","state_id":"3974"},
    {"city_id":"46716","city_name":"Martinsville","state_id":"3974"},
    {"city_id":"46717","city_name":"Mclean","state_id":"3974"},
    {"city_id":"46718","city_name":"Mechanicsville","state_id":"3974"},
    {"city_id":"46719","city_name":"Melfa","state_id":"3974"},
    {"city_id":"46720","city_name":"Midlothian","state_id":"3974"},
    {"city_id":"46721","city_name":"Montclair","state_id":"3974"},
    {"city_id":"46722","city_name":"Montross","state_id":"3974"},
    {"city_id":"46723","city_name":"Mount Vernon","state_id":"3974"},
    {"city_id":"46724","city_name":"Newington","state_id":"3974"},
    {"city_id":"46725","city_name":"Newport News","state_id":"3974"},
    {"city_id":"46726","city_name":"Norfolk","state_id":"3974"},
    {"city_id":"46727","city_name":"North Springfield","state_id":"3974"},
    {"city_id":"46728","city_name":"Oakton","state_id":"3974"},
    {"city_id":"46729","city_name":"Orange","state_id":"3974"},
    {"city_id":"46730","city_name":"Petersburg","state_id":"3974"},
    {"city_id":"46731","city_name":"Poquoson","state_id":"3974"},
    {"city_id":"46732","city_name":"Portsmouth","state_id":"3974"},
    {"city_id":"46733","city_name":"Radford","state_id":"3974"},
    {"city_id":"46734","city_name":"Reston","state_id":"3974"},
    {"city_id":"46735","city_name":"Richmond","state_id":"3974"},
    {"city_id":"46736","city_name":"Roanoke","state_id":"3974"},
    {"city_id":"46737","city_name":"Rose Hill","state_id":"3974"},
    {"city_id":"46738","city_name":"Salem","state_id":"3974"},
    {"city_id":"46739","city_name":"Seaford","state_id":"3974"},
    {"city_id":"46740","city_name":"South Boston","state_id":"3974"},
    {"city_id":"46741","city_name":"Springfield","state_id":"3974"},
    {"city_id":"46742","city_name":"Stafford","state_id":"3974"},
    {"city_id":"46743","city_name":"Staffordshire","state_id":"3974"},
    {"city_id":"46744","city_name":"Staunton","state_id":"3974"},
    {"city_id":"46745","city_name":"Sterling","state_id":"3974"},
    {"city_id":"46746","city_name":"Suffolk","state_id":"3974"},
    {"city_id":"46747","city_name":"Sugarland Run","state_id":"3974"},
    {"city_id":"46748","city_name":"Tappahannock","state_id":"3974"},
    {"city_id":"46749","city_name":"Timberlake","state_id":"3974"},
    {"city_id":"46750","city_name":"Triangle","state_id":"3974"},
    {"city_id":"46751","city_name":"Tuckahoe","state_id":"3974"},
    {"city_id":"46752","city_name":"Tysons Corner","state_id":"3974"},
    {"city_id":"46753","city_name":"Vienna","state_id":"3974"},
    {"city_id":"46754","city_name":"Virginia Beach","state_id":"3974"},
    {"city_id":"46755","city_name":"Warrenton","state_id":"3974"},
    {"city_id":"46756","city_name":"Washington","state_id":"3974"},
    {"city_id":"46757","city_name":"Waterford","state_id":"3974"},
    {"city_id":"46758","city_name":"Waynesboro","state_id":"3974"},
    {"city_id":"46759","city_name":"West Springfield","state_id":"3974"},
    {"city_id":"46760","city_name":"Williamsburg","state_id":"3974"},
    {"city_id":"46761","city_name":"Winchester","state_id":"3974"},
    {"city_id":"46762","city_name":"Wolf Trap","state_id":"3974"},
    {"city_id":"46763","city_name":"Woodbridge","state_id":"3974"},
    {"city_id":"46764","city_name":"Wytheville","state_id":"3974"},
    {"city_id":"46765","city_name":"Yorktown","state_id":"3974"},
    {"city_id":"46766","city_name":"Aberdeen","state_id":"3975"},
    {"city_id":"46767","city_name":"Airway Heights","state_id":"3975"},
    {"city_id":"46768","city_name":"Alderwood Manor","state_id":"3975"},
    {"city_id":"46769","city_name":"Anacortes","state_id":"3975"},
    {"city_id":"46770","city_name":"Arlington","state_id":"3975"},
    {"city_id":"46771","city_name":"Auburn","state_id":"3975"},
    {"city_id":"46772","city_name":"Bainbridge Island","state_id":"3975"},
    {"city_id":"46773","city_name":"Battle Ground","state_id":"3975"},
    {"city_id":"46774","city_name":"Bellevue","state_id":"3975"},
    {"city_id":"46775","city_name":"Bellingham","state_id":"3975"},
    {"city_id":"46776","city_name":"Bingen","state_id":"3975"},
    {"city_id":"46777","city_name":"Blaine","state_id":"3975"},
    {"city_id":"46778","city_name":"Bothell","state_id":"3975"},
    {"city_id":"46779","city_name":"Bremerton","state_id":"3975"},
    {"city_id":"46780","city_name":"Bryn Mawr-Skyway","state_id":"3975"},
    {"city_id":"46781","city_name":"Buckley","state_id":"3975"},
    {"city_id":"46782","city_name":"Burien","state_id":"3975"},
    {"city_id":"46783","city_name":"Burlington","state_id":"3975"},
    {"city_id":"46784","city_name":"Camano Island","state_id":"3975"},
    {"city_id":"46785","city_name":"Camas","state_id":"3975"},
    {"city_id":"46786","city_name":"Cascade-Fairwood","state_id":"3975"},
    {"city_id":"46787","city_name":"Centralia","state_id":"3975"},
    {"city_id":"46788","city_name":"Chehalis","state_id":"3975"},
    {"city_id":"46789","city_name":"Cheney","state_id":"3975"},
    {"city_id":"46790","city_name":"Clear Lake","state_id":"3975"},
    {"city_id":"46791","city_name":"Colbert","state_id":"3975"},
    {"city_id":"46792","city_name":"Cottage Lake","state_id":"3975"},
    {"city_id":"46793","city_name":"Covington-Sawyer-Wilderness","state_id":"3975"},
    {"city_id":"46794","city_name":"Des Moines","state_id":"3975"},
    {"city_id":"46795","city_name":"Duvall","state_id":"3975"},
    {"city_id":"46796","city_name":"East Hill-Meridian","state_id":"3975"},
    {"city_id":"46797","city_name":"East Renton Highlands","state_id":"3975"},
    {"city_id":"46798","city_name":"East Wenatchee Bench","state_id":"3975"},
    {"city_id":"46799","city_name":"Eastsound","state_id":"3975"},
    {"city_id":"46800","city_name":"Eatonville","state_id":"3975"},
    {"city_id":"46801","city_name":"Edgewood-North Hill","state_id":"3975"},
    {"city_id":"46802","city_name":"Edmonds","state_id":"3975"},
    {"city_id":"46803","city_name":"Elk Plain","state_id":"3975"},
    {"city_id":"46804","city_name":"Ellensburg","state_id":"3975"},
    {"city_id":"46805","city_name":"Enumclaw","state_id":"3975"},
    {"city_id":"46806","city_name":"Esperance","state_id":"3975"},
    {"city_id":"46807","city_name":"Everett","state_id":"3975"},
    {"city_id":"46808","city_name":"Evergreen","state_id":"3975"},
    {"city_id":"46809","city_name":"Fairchild","state_id":"3975"},
    {"city_id":"46810","city_name":"Federal Way","state_id":"3975"},
    {"city_id":"46811","city_name":"Ferndale","state_id":"3975"},
    {"city_id":"46812","city_name":"Fircrest","state_id":"3975"},
    {"city_id":"46813","city_name":"Ford","state_id":"3975"},
    {"city_id":"46814","city_name":"Fort Lewis","state_id":"3975"},
    {"city_id":"46815","city_name":"Friday Harbor","state_id":"3975"},
    {"city_id":"46816","city_name":"Gig Harbor","state_id":"3975"},
    {"city_id":"46817","city_name":"Graham","state_id":"3975"},
    {"city_id":"46818","city_name":"Harbour Pointe","state_id":"3975"},
    {"city_id":"46819","city_name":"Inglewood-Finn Hill","state_id":"3975"},
    {"city_id":"46820","city_name":"Issaquah","state_id":"3975"},
    {"city_id":"46821","city_name":"Kelso","state_id":"3975"},
    {"city_id":"46822","city_name":"Kenmore","state_id":"3975"},
    {"city_id":"46823","city_name":"Kennewick","state_id":"3975"},
    {"city_id":"46824","city_name":"Kent","state_id":"3975"},
    {"city_id":"46825","city_name":"Kingsgate","state_id":"3975"},
    {"city_id":"46826","city_name":"Kirkland","state_id":"3975"},
    {"city_id":"46827","city_name":"Lacey","state_id":"3975"},
    {"city_id":"46828","city_name":"Lake Serene-North Lynnwood","state_id":"3975"},
    {"city_id":"46829","city_name":"Lakeland North","state_id":"3975"},
    {"city_id":"46830","city_name":"Lakeland South","state_id":"3975"},
    {"city_id":"46831","city_name":"Lakewood","state_id":"3975"},
    {"city_id":"46832","city_name":"Longview","state_id":"3975"},
    {"city_id":"46833","city_name":"Lynnwood","state_id":"3975"},
    {"city_id":"46834","city_name":"Martha Lake","state_id":"3975"},
    {"city_id":"46835","city_name":"Marysville","state_id":"3975"},
    {"city_id":"46836","city_name":"Mercer Island","state_id":"3975"},
    {"city_id":"46837","city_name":"Minnehaha","state_id":"3975"},
    {"city_id":"46838","city_name":"Monroe","state_id":"3975"},
    {"city_id":"46839","city_name":"Moses Lake","state_id":"3975"},
    {"city_id":"46840","city_name":"Mossyrock","state_id":"3975"},
    {"city_id":"46841","city_name":"Mount Vernon","state_id":"3975"},
    {"city_id":"46842","city_name":"Mountlake Terrace","state_id":"3975"},
    {"city_id":"46843","city_name":"Mukilteo","state_id":"3975"},
    {"city_id":"46844","city_name":"Newport Hills","state_id":"3975"},
    {"city_id":"46845","city_name":"North City-Ridgecrest","state_id":"3975"},
    {"city_id":"46846","city_name":"North Creek","state_id":"3975"},
    {"city_id":"46847","city_name":"North Marysville","state_id":"3975"},
    {"city_id":"46848","city_name":"Oak Harbor","state_id":"3975"},
    {"city_id":"46849","city_name":"Ocean Shores","state_id":"3975"},
    {"city_id":"46850","city_name":"Olympia","state_id":"3975"},
    {"city_id":"46851","city_name":"Opportunity","state_id":"3975"},
    {"city_id":"46852","city_name":"Orchards South","state_id":"3975"},
    {"city_id":"46853","city_name":"Orting","state_id":"3975"},
    {"city_id":"46854","city_name":"Paine Field-Lake Stickney","state_id":"3975"},
    {"city_id":"46855","city_name":"Parkland","state_id":"3975"},
    {"city_id":"46856","city_name":"Pasco","state_id":"3975"},
    {"city_id":"46857","city_name":"Picnic Point-North Lynnwood","state_id":"3975"},
    {"city_id":"46858","city_name":"Pine Lake","state_id":"3975"},
    {"city_id":"46859","city_name":"Port Angeles","state_id":"3975"},
    {"city_id":"46860","city_name":"Port Hadlock","state_id":"3975"},
    {"city_id":"46861","city_name":"Port Ludlow","state_id":"3975"},
    {"city_id":"46862","city_name":"Port Orchard","state_id":"3975"},
    {"city_id":"46863","city_name":"Poulsbo","state_id":"3975"},
    {"city_id":"46864","city_name":"Pullman","state_id":"3975"},
    {"city_id":"46865","city_name":"Puyallup","state_id":"3975"},
    {"city_id":"46866","city_name":"Redmond","state_id":"3975"},
    {"city_id":"46867","city_name":"Renton","state_id":"3975"},
    {"city_id":"46868","city_name":"Republic","state_id":"3975"},
    {"city_id":"46869","city_name":"Richland","state_id":"3975"},
    {"city_id":"46870","city_name":"Riverton-Boulevard Park","state_id":"3975"},
    {"city_id":"46871","city_name":"Sahalee","state_id":"3975"},
    {"city_id":"46872","city_name":"Salmon Creek","state_id":"3975"},
    {"city_id":"46873","city_name":"Sammamish","state_id":"3975"},
    {"city_id":"46874","city_name":"SeaTac","state_id":"3975"},
    {"city_id":"46875","city_name":"Seattle","state_id":"3975"},
    {"city_id":"46876","city_name":"Seattle Hill-Silver Firs","state_id":"3975"},
    {"city_id":"46877","city_name":"Sedro Woolley","state_id":"3975"},
    {"city_id":"46878","city_name":"Shelton","state_id":"3975"},
    {"city_id":"46879","city_name":"Shoreline","state_id":"3975"},
    {"city_id":"46880","city_name":"Silverdale","state_id":"3975"},
    {"city_id":"46881","city_name":"Snohomish","state_id":"3975"},
    {"city_id":"46882","city_name":"South Hill","state_id":"3975"},
    {"city_id":"46883","city_name":"South Prairie","state_id":"3975"},
    {"city_id":"46884","city_name":"South Seattle","state_id":"3975"},
    {"city_id":"46885","city_name":"Spanaway","state_id":"3975"},
    {"city_id":"46886","city_name":"Spokane","state_id":"3975"},
    {"city_id":"46887","city_name":"Sumas","state_id":"3975"},
    {"city_id":"46888","city_name":"Sumner","state_id":"3975"},
    {"city_id":"46889","city_name":"Sunnyside","state_id":"3975"},
    {"city_id":"46890","city_name":"Tacoma","state_id":"3975"},
    {"city_id":"46891","city_name":"Tukwila","state_id":"3975"},
    {"city_id":"46892","city_name":"Tumwater","state_id":"3975"},
    {"city_id":"46893","city_name":"University Place","state_id":"3975"},
    {"city_id":"46894","city_name":"Vancouver","state_id":"3975"},
    {"city_id":"46895","city_name":"Vashon","state_id":"3975"},
    {"city_id":"46896","city_name":"Walla Walla","state_id":"3975"},
    {"city_id":"46897","city_name":"Washougal","state_id":"3975"},
    {"city_id":"46898","city_name":"Wenatchee","state_id":"3975"},
    {"city_id":"46899","city_name":"West Lake Stevens","state_id":"3975"},
    {"city_id":"46900","city_name":"White Center","state_id":"3975"},
    {"city_id":"46901","city_name":"White Salmon","state_id":"3975"},
    {"city_id":"46902","city_name":"White Swan","state_id":"3975"},
    {"city_id":"46903","city_name":"Woodinville","state_id":"3975"},
    {"city_id":"46904","city_name":"Yakima","state_id":"3975"},
    {"city_id":"46905","city_name":"Yelm","state_id":"3975"},
    {"city_id":"46906","city_name":"Adams","state_id":"3977"},
    {"city_id":"46907","city_name":"Allouez","state_id":"3977"},
    {"city_id":"46908","city_name":"Appleton","state_id":"3977"},
    {"city_id":"46909","city_name":"Ashland","state_id":"3977"},
    {"city_id":"46910","city_name":"Ashwaubenon","state_id":"3977"},
    {"city_id":"46911","city_name":"Baraboo","state_id":"3977"},
    {"city_id":"46912","city_name":"Beaver Dam","state_id":"3977"},
    {"city_id":"46913","city_name":"Beloit","state_id":"3977"},
    {"city_id":"46914","city_name":"Brookfield","state_id":"3977"},
    {"city_id":"46915","city_name":"Brown Deer","state_id":"3977"},
    {"city_id":"46916","city_name":"Burlington","state_id":"3977"},
    {"city_id":"46917","city_name":"Caledonia","state_id":"3977"},
    {"city_id":"46918","city_name":"Carter","state_id":"3977"},
    {"city_id":"46919","city_name":"Cedarburg","state_id":"3977"},
    {"city_id":"46920","city_name":"Chippewa Falls","state_id":"3977"},
    {"city_id":"46921","city_name":"Cudahy","state_id":"3977"},
    {"city_id":"46922","city_name":"De Pere","state_id":"3977"},
    {"city_id":"46923","city_name":"Deer Park","state_id":"3977"},
    {"city_id":"46924","city_name":"Delafield","state_id":"3977"},
    {"city_id":"46925","city_name":"Eau Claire","state_id":"3977"},
    {"city_id":"46926","city_name":"Elkhorn","state_id":"3977"},
    {"city_id":"46927","city_name":"Elroy","state_id":"3977"},
    {"city_id":"46928","city_name":"Fitchburg","state_id":"3977"},
    {"city_id":"46929","city_name":"Fond du Lac","state_id":"3977"},
    {"city_id":"46930","city_name":"Fort Atkinson","state_id":"3977"},
    {"city_id":"46931","city_name":"Franklin","state_id":"3977"},
    {"city_id":"46932","city_name":"Galesville","state_id":"3977"},
    {"city_id":"46933","city_name":"Germantown","state_id":"3977"},
    {"city_id":"46934","city_name":"Glen Flora","state_id":"3977"},
    {"city_id":"46935","city_name":"Glendale","state_id":"3977"},
    {"city_id":"46936","city_name":"Goodman","state_id":"3977"},
    {"city_id":"46937","city_name":"Grafton","state_id":"3977"},
    {"city_id":"46938","city_name":"Green Bay","state_id":"3977"},
    {"city_id":"46939","city_name":"Greendale","state_id":"3977"},
    {"city_id":"46940","city_name":"Greenfield","state_id":"3977"},
    {"city_id":"46941","city_name":"Hartford","state_id":"3977"},
    {"city_id":"46942","city_name":"Hartland","state_id":"3977"},
    {"city_id":"46943","city_name":"Howard","state_id":"3977"},
    {"city_id":"46944","city_name":"Hudson","state_id":"3977"},
    {"city_id":"46945","city_name":"Janesville","state_id":"3977"},
    {"city_id":"46946","city_name":"Jefferson","state_id":"3977"},
    {"city_id":"46947","city_name":"Junction City","state_id":"3977"},
    {"city_id":"46948","city_name":"Kaukauna","state_id":"3977"},
    {"city_id":"46949","city_name":"Kenosha","state_id":"3977"},
    {"city_id":"46950","city_name":"Kiel","state_id":"3977"},
    {"city_id":"46951","city_name":"Kohler","state_id":"3977"},
    {"city_id":"46952","city_name":"La Crosse","state_id":"3977"},
    {"city_id":"46953","city_name":"Little Chute","state_id":"3977"},
    {"city_id":"46954","city_name":"Madison","state_id":"3977"},
    {"city_id":"46955","city_name":"Manitowoc","state_id":"3977"},
    {"city_id":"46956","city_name":"Marinette","state_id":"3977"},
    {"city_id":"46957","city_name":"Marshfield","state_id":"3977"},
    {"city_id":"46958","city_name":"Medford","state_id":"3977"},
    {"city_id":"46959","city_name":"Menasha","state_id":"3977"},
    {"city_id":"46960","city_name":"Menomonee Falls","state_id":"3977"},
    {"city_id":"46961","city_name":"Menomonie","state_id":"3977"},
    {"city_id":"46962","city_name":"Mequon","state_id":"3977"},
    {"city_id":"46963","city_name":"Merrill","state_id":"3977"},
    {"city_id":"46964","city_name":"Middleton","state_id":"3977"},
    {"city_id":"46965","city_name":"Milwaukee","state_id":"3977"},
    {"city_id":"46966","city_name":"Mineral Point","state_id":"3977"},
    {"city_id":"46967","city_name":"Monroe","state_id":"3977"},
    {"city_id":"46968","city_name":"Mount Pleasant","state_id":"3977"},
    {"city_id":"46969","city_name":"Mukwonago","state_id":"3977"},
    {"city_id":"46970","city_name":"Muskego","state_id":"3977"},
    {"city_id":"46971","city_name":"Neenah","state_id":"3977"},
    {"city_id":"46972","city_name":"New Berlin","state_id":"3977"},
    {"city_id":"46973","city_name":"New Richmond","state_id":"3977"},
    {"city_id":"46974","city_name":"Oak Creek","state_id":"3977"},
    {"city_id":"46975","city_name":"Oconomowoc","state_id":"3977"},
    {"city_id":"46976","city_name":"Onalaska","state_id":"3977"},
    {"city_id":"46977","city_name":"Orfordville","state_id":"3977"},
    {"city_id":"46978","city_name":"Oshkosh","state_id":"3977"},
    {"city_id":"46979","city_name":"Pigeon Falls","state_id":"3977"},
    {"city_id":"46980","city_name":"Platteville","state_id":"3977"},
    {"city_id":"46981","city_name":"Pleasant Prairie","state_id":"3977"},
    {"city_id":"46982","city_name":"Plover","state_id":"3977"},
    {"city_id":"46983","city_name":"Port Washington","state_id":"3977"},
    {"city_id":"46984","city_name":"Portage","state_id":"3977"},
    {"city_id":"46985","city_name":"Pound","state_id":"3977"},
    {"city_id":"46986","city_name":"Racine","state_id":"3977"},
    {"city_id":"46987","city_name":"Reedsburg","state_id":"3977"},
    {"city_id":"46988","city_name":"Rhinelander","state_id":"3977"},
    {"city_id":"46989","city_name":"River Falls","state_id":"3977"},
    {"city_id":"46990","city_name":"Saint Francis","state_id":"3977"},
    {"city_id":"46991","city_name":"Sheboygan","state_id":"3977"},
    {"city_id":"46992","city_name":"Shorewood","state_id":"3977"},
    {"city_id":"46993","city_name":"South Milwaukee","state_id":"3977"},
    {"city_id":"46994","city_name":"Spring Valley","state_id":"3977"},
    {"city_id":"46995","city_name":"Stevens Point","state_id":"3977"},
    {"city_id":"46996","city_name":"Stoughton","state_id":"3977"},
    {"city_id":"46997","city_name":"Strum","state_id":"3977"},
    {"city_id":"46998","city_name":"Sturtevant","state_id":"3977"},
    {"city_id":"46999","city_name":"Sun Prairie","state_id":"3977"},
    {"city_id":"47000","city_name":"Superior","state_id":"3977"},
    {"city_id":"47001","city_name":"Three Lakes","state_id":"3977"},
    {"city_id":"47002","city_name":"Tomah","state_id":"3977"},
    {"city_id":"47003","city_name":"Two Rivers","state_id":"3977"},
    {"city_id":"47004","city_name":"Washington Island","state_id":"3977"},
    {"city_id":"47005","city_name":"Waterford","state_id":"3977"},
    {"city_id":"47006","city_name":"Watertown","state_id":"3977"},
    {"city_id":"47007","city_name":"Waukesha","state_id":"3977"},
    {"city_id":"47008","city_name":"Waupun","state_id":"3977"},
    {"city_id":"47009","city_name":"Wausau","state_id":"3977"},
    {"city_id":"47010","city_name":"Wautoma","state_id":"3977"},
    {"city_id":"47011","city_name":"Wauwatosa","state_id":"3977"},
    {"city_id":"47012","city_name":"West Allis","state_id":"3977"},
    {"city_id":"47013","city_name":"West Bend","state_id":"3977"},
    {"city_id":"47014","city_name":"Weston","state_id":"3977"},
    {"city_id":"47015","city_name":"Whitefish Bay","state_id":"3977"},
    {"city_id":"47016","city_name":"Whitewater","state_id":"3977"},
    {"city_id":"47017","city_name":"Wisconsin Rapids","state_id":"3977"},
    {"city_id":"47018","city_name":"Buffalo","state_id":"3978"},
    {"city_id":"47019","city_name":"Casper","state_id":"3978"},
    {"city_id":"47020","city_name":"Cheyenne","state_id":"3978"},
    {"city_id":"47021","city_name":"Cody","state_id":"3978"},
    {"city_id":"47022","city_name":"Douglas","state_id":"3978"},
    {"city_id":"47023","city_name":"Evanston","state_id":"3978"},
    {"city_id":"47024","city_name":"Gillette","state_id":"3978"},
    {"city_id":"47025","city_name":"Green River","state_id":"3978"},
    {"city_id":"47026","city_name":"Jackson","state_id":"3978"},
    {"city_id":"47027","city_name":"Lander","state_id":"3978"},
    {"city_id":"47028","city_name":"Laramie","state_id":"3978"},
    {"city_id":"47029","city_name":"Powell","state_id":"3978"},
    {"city_id":"47030","city_name":"Rawlins","state_id":"3978"},
    {"city_id":"47031","city_name":"Riverton","state_id":"3978"},
    {"city_id":"47032","city_name":"Rock Springs","state_id":"3978"},
    {"city_id":"47033","city_name":"Sheridan","state_id":"3978"},
    {"city_id":"47034","city_name":"Torrington","state_id":"3978"},
    {"city_id":"47035","city_name":"Worland","state_id":"3978"},
    {"city_id":"47036","city_name":"Artigas","state_id":"3980"},
    {"city_id":"47037","city_name":"Bella Union","state_id":"3980"},
    {"city_id":"47038","city_name":"Camino Maldonado","state_id":"3981"},
    {"city_id":"47039","city_name":"Canelones","state_id":"3981"},
    {"city_id":"47040","city_name":"Ciudad de la Costa","state_id":"3981"},
    {"city_id":"47041","city_name":"Juan Antonio Artigas","state_id":"3981"},
    {"city_id":"47042","city_name":"Las Piedras","state_id":"3981"},
    {"city_id":"47043","city_name":"Pando","state_id":"3981"},
    {"city_id":"47044","city_name":"Paso de Carrasco","state_id":"3981"},
    {"city_id":"47045","city_name":"Progreso","state_id":"3981"},
    {"city_id":"47046","city_name":"Santa Lucia","state_id":"3981"},
    {"city_id":"47047","city_name":"Carmelo","state_id":"3983"},
    {"city_id":"47048","city_name":"Colonia","state_id":"3983"},
    {"city_id":"47049","city_name":"Juan Lacaze","state_id":"3983"},
    {"city_id":"47050","city_name":"Nueva Helvecia","state_id":"3983"},
    {"city_id":"47051","city_name":"Durazno","state_id":"3984"},
    {"city_id":"47052","city_name":"Florida","state_id":"3985"},
    {"city_id":"47053","city_name":"Trinidad","state_id":"3986"},
    {"city_id":"47054","city_name":"Minas","state_id":"3987"},
    {"city_id":"47055","city_name":"Maldonado","state_id":"3988"},
    {"city_id":"47056","city_name":"San Carlos","state_id":"3988"},
    {"city_id":"47057","city_name":"La Paz","state_id":"3989"},
    {"city_id":"47058","city_name":"Montevideo","state_id":"3989"},
    {"city_id":"47059","city_name":"Paysandu","state_id":"3990"},
    {"city_id":"47060","city_name":"Rivera","state_id":"3992"},
    {"city_id":"47061","city_name":"Chuy","state_id":"3993"},
    {"city_id":"47062","city_name":"Rocha","state_id":"3993"},
    {"city_id":"47063","city_name":"Salto","state_id":"3994"},
    {"city_id":"47064","city_name":"Dolores","state_id":"3996"},
    {"city_id":"47065","city_name":"Mercedes","state_id":"3996"},
    {"city_id":"47066","city_name":"Paso de los Toros","state_id":"3997"},
    {"city_id":"47067","city_name":"Tacuarembo","state_id":"3997"},
    {"city_id":"47068","city_name":"Ahunabayev","state_id":"3999"},
    {"city_id":"47069","city_name":"Andijon","state_id":"3999"},
    {"city_id":"47070","city_name":"Asaka","state_id":"3999"},
    {"city_id":"47071","city_name":"Boz","state_id":"3999"},
    {"city_id":"47072","city_name":"Hakkulobod","state_id":"3999"},
    {"city_id":"47073","city_name":"Hocaobod","state_id":"3999"},
    {"city_id":"47074","city_name":"Honobod","state_id":"3999"},
    {"city_id":"47075","city_name":"Ilyichevsk","state_id":"3999"},
    {"city_id":"47076","city_name":"Karabagis","state_id":"3999"},
    {"city_id":"47077","city_name":"Kurgontepa","state_id":"3999"},
    {"city_id":"47078","city_name":"Marhomat","state_id":"3999"},
    {"city_id":"47079","city_name":"Pahtaobod","state_id":"3999"},
    {"city_id":"47080","city_name":"Paytug","state_id":"3999"},
    {"city_id":"47081","city_name":"Sahrihan","state_id":"3999"},
    {"city_id":"47082","city_name":"Buhoro","state_id":"4000"},
    {"city_id":"47083","city_name":"Cangeldi","state_id":"4000"},
    {"city_id":"47084","city_name":"Galaosiye","state_id":"4000"},
    {"city_id":"47085","city_name":"Gazli","state_id":"4000"},
    {"city_id":"47086","city_name":"Gijduvon","state_id":"4000"},
    {"city_id":"47087","city_name":"Kizilravbe","state_id":"4000"},
    {"city_id":"47088","city_name":"Kogon","state_id":"4000"},
    {"city_id":"47089","city_name":"Korakul","state_id":"4000"},
    {"city_id":"47090","city_name":"Korovulbazar","state_id":"4000"},
    {"city_id":"47091","city_name":"Kukca","state_id":"4000"},
    {"city_id":"47092","city_name":"Olot","state_id":"4000"},
    {"city_id":"47093","city_name":"Oyokkuduk","state_id":"4000"},
    {"city_id":"47094","city_name":"Romitan","state_id":"4000"},
    {"city_id":"47095","city_name":"Safirkon","state_id":"4000"},
    {"city_id":"47096","city_name":"Vobkent","state_id":"4000"},
    {"city_id":"47097","city_name":"Cizah","state_id":"4002"},
    {"city_id":"47098","city_name":"Dustlik","state_id":"4002"},
    {"city_id":"47099","city_name":"Gagarin","state_id":"4002"},
    {"city_id":"47100","city_name":"Gallaorol","state_id":"4002"},
    {"city_id":"47101","city_name":"Marcanbulok","state_id":"4002"},
    {"city_id":"47102","city_name":"Pahtakor","state_id":"4002"},
    {"city_id":"47103","city_name":"Ulyanovo","state_id":"4002"},
    {"city_id":"47104","city_name":"Zomin","state_id":"4002"},
    {"city_id":"47105","city_name":"Altyarik","state_id":"4003"},
    {"city_id":"47106","city_name":"Avval","state_id":"4003"},
    {"city_id":"47107","city_name":"Besarik","state_id":"4003"},
    {"city_id":"47108","city_name":"Fargona","state_id":"4003"},
    {"city_id":"47109","city_name":"Hamza","state_id":"4003"},
    {"city_id":"47110","city_name":"Kirgili","state_id":"4003"},
    {"city_id":"47111","city_name":"Kukon","state_id":"4003"},
    {"city_id":"47112","city_name":"Kuva","state_id":"4003"},
    {"city_id":"47113","city_name":"Kuvasoy","state_id":"4003"},
    {"city_id":"47114","city_name":"Margilon","state_id":"4003"},
    {"city_id":"47115","city_name":"Rapkan","state_id":"4003"},
    {"city_id":"47116","city_name":"Riston","state_id":"4003"},
    {"city_id":"47117","city_name":"Toslok","state_id":"4003"},
    {"city_id":"47118","city_name":"Yaypan","state_id":"4003"},
    {"city_id":"47119","city_name":"Drujba","state_id":"4004"},
    {"city_id":"47120","city_name":"Gurlan","state_id":"4004"},
    {"city_id":"47121","city_name":"Hazorasp","state_id":"4004"},
    {"city_id":"47122","city_name":"Heva","state_id":"4004"},
    {"city_id":"47123","city_name":"Honko","state_id":"4004"},
    {"city_id":"47124","city_name":"Kuskupir","state_id":"4004"},
    {"city_id":"47125","city_name":"Mesekli","state_id":"4004"},
    {"city_id":"47126","city_name":"Sovot","state_id":"4004"},
    {"city_id":"47127","city_name":"Turpokkala","state_id":"4004"},
    {"city_id":"47128","city_name":"Urgenc","state_id":"4004"},
    {"city_id":"47129","city_name":"Beskent","state_id":"4005"},
    {"city_id":"47130","city_name":"Ceynau","state_id":"4005"},
    {"city_id":"47131","city_name":"Cirakci","state_id":"4005"},
    {"city_id":"47132","city_name":"Dehkanobod","state_id":"4005"},
    {"city_id":"47133","city_name":"Dinau","state_id":"4005"},
    {"city_id":"47134","city_name":"Guzor","state_id":"4005"},
    {"city_id":"47135","city_name":"Kallig","state_id":"4005"},
    {"city_id":"47136","city_name":"Kamasi","state_id":"4005"},
    {"city_id":"47137","city_name":"Karabag","state_id":"4005"},
    {"city_id":"47138","city_name":"Karsi","state_id":"4005"},
    {"city_id":"47139","city_name":"Kasbi","state_id":"4005"},
    {"city_id":"47140","city_name":"Kitob","state_id":"4005"},
    {"city_id":"47141","city_name":"Koson","state_id":"4005"},
    {"city_id":"47142","city_name":"Madaniyat","state_id":"4005"},
    {"city_id":"47143","city_name":"Maydayap","state_id":"4005"},
    {"city_id":"47144","city_name":"Maymanak","state_id":"4005"},
    {"city_id":"47145","city_name":"Muborak","state_id":"4005"},
    {"city_id":"47146","city_name":"Oktyabrskiy","state_id":"4005"},
    {"city_id":"47147","city_name":"Sahrisabz","state_id":"4005"},
    {"city_id":"47148","city_name":"Talimarcon","state_id":"4005"},
    {"city_id":"47149","city_name":"Urtacim","state_id":"4005"},
    {"city_id":"47150","city_name":"Yakkabog","state_id":"4005"},
    {"city_id":"47151","city_name":"Yaninison","state_id":"4005"},
    {"city_id":"47152","city_name":"Beruni","state_id":"4006"},
    {"city_id":"47153","city_name":"Buston","state_id":"4006"},
    {"city_id":"47154","city_name":"Cimboy","state_id":"4006"},
    {"city_id":"47155","city_name":"Halkobod","state_id":"4006"},
    {"city_id":"47156","city_name":"Hucayli","state_id":"4006"},
    {"city_id":"47157","city_name":"Karauzyak","state_id":"4006"},
    {"city_id":"47158","city_name":"Kegayli","state_id":"4006"},
    {"city_id":"47159","city_name":"Kizketken","state_id":"4006"},
    {"city_id":"47160","city_name":"Kozokdaryo","state_id":"4006"},
    {"city_id":"47161","city_name":"Kungirot","state_id":"4006"},
    {"city_id":"47162","city_name":"Mangit","state_id":"4006"},
    {"city_id":"47163","city_name":"Muynok","state_id":"4006"},
    {"city_id":"47164","city_name":"Nukus","state_id":"4006"},
    {"city_id":"47165","city_name":"Tahiatos","state_id":"4006"},
    {"city_id":"47166","city_name":"Tahtakupir","state_id":"4006"},
    {"city_id":"47167","city_name":"Turtkul","state_id":"4006"},
    {"city_id":"47168","city_name":"Aytim","state_id":"4007"},
    {"city_id":"47169","city_name":"Besbulok","state_id":"4007"},
    {"city_id":"47170","city_name":"Cartak","state_id":"4007"},
    {"city_id":"47171","city_name":"Cingeldi","state_id":"4007"},
    {"city_id":"47172","city_name":"Cust","state_id":"4007"},
    {"city_id":"47173","city_name":"Gozgon","state_id":"4007"},
    {"city_id":"47174","city_name":"Iskavat","state_id":"4007"},
    {"city_id":"47175","city_name":"Kalkuduk","state_id":"4007"},
    {"city_id":"47176","city_name":"Konimeh","state_id":"4007"},
    {"city_id":"47177","city_name":"Kosonsoy","state_id":"4007"},
    {"city_id":"47178","city_name":"Minbulok","state_id":"4007"},
    {"city_id":"47179","city_name":"Namangan","state_id":"4007"},
    {"city_id":"47180","city_name":"Nurota","state_id":"4007"},
    {"city_id":"47181","city_name":"Pop","state_id":"4007"},
    {"city_id":"47182","city_name":"Tomdibulok","state_id":"4007"},
    {"city_id":"47183","city_name":"Tosbulok","state_id":"4007"},
    {"city_id":"47184","city_name":"Turakurgon","state_id":"4007"},
    {"city_id":"47185","city_name":"Uckurgon","state_id":"4007"},
    {"city_id":"47186","city_name":"Unhoyat","state_id":"4007"},
    {"city_id":"47187","city_name":"Uyci","state_id":"4007"},
    {"city_id":"47188","city_name":"Uzunkuduk","state_id":"4007"},
    {"city_id":"47189","city_name":"Yanikurgon","state_id":"4007"},
    {"city_id":"47190","city_name":"Karmana","state_id":"4008"},
    {"city_id":"47191","city_name":"Kiziltepa","state_id":"4008"},
    {"city_id":"47192","city_name":"Navoi","state_id":"4008"},
    {"city_id":"47193","city_name":"Uckuduk","state_id":"4008"},
    {"city_id":"47194","city_name":"Zarafson","state_id":"4008"},
    {"city_id":"47195","city_name":"Bulungur","state_id":"4009"},
    {"city_id":"47196","city_name":"Camboy","state_id":"4009"},
    {"city_id":"47197","city_name":"Carhin","state_id":"4009"},
    {"city_id":"47198","city_name":"Celak","state_id":"4009"},
    {"city_id":"47199","city_name":"Cuma","state_id":"4009"},
    {"city_id":"47200","city_name":"Dahbed","state_id":"4009"},
    {"city_id":"47201","city_name":"Istihon","state_id":"4009"},
    {"city_id":"47202","city_name":"Kattakurgon","state_id":"4009"},
    {"city_id":"47203","city_name":"Kusrobod","state_id":"4009"},
    {"city_id":"47204","city_name":"Nurobod","state_id":"4009"},
    {"city_id":"47205","city_name":"Oktos","state_id":"4009"},
    {"city_id":"47206","city_name":"Paysanba","state_id":"4009"},
    {"city_id":"47207","city_name":"Samarkand","state_id":"4009"},
    {"city_id":"47208","city_name":"Superfosfatniy","state_id":"4009"},
    {"city_id":"47209","city_name":"Urgut","state_id":"4009"},
    {"city_id":"47210","city_name":"Yanirobod","state_id":"4009"},
    {"city_id":"47211","city_name":"Ziadin","state_id":"4009"},
    {"city_id":"47212","city_name":"Baht","state_id":"4010"},
    {"city_id":"47213","city_name":"Guliston","state_id":"4010"},
    {"city_id":"47214","city_name":"Hovos","state_id":"4010"},
    {"city_id":"47215","city_name":"Sirdare","state_id":"4010"},
    {"city_id":"47216","city_name":"Sirin","state_id":"4010"},
    {"city_id":"47217","city_name":"Yaniyer","state_id":"4010"},
    {"city_id":"47218","city_name":"Akkurgon","state_id":"4011"},
    {"city_id":"47219","city_name":"Boysun","state_id":"4011"},
    {"city_id":"47220","city_name":"Carkurgon","state_id":"4011"},
    {"city_id":"47221","city_name":"Denau","state_id":"4011"},
    {"city_id":"47222","city_name":"Kumkurgon","state_id":"4011"},
    {"city_id":"47223","city_name":"Paskurd","state_id":"4011"},
    {"city_id":"47224","city_name":"Sargun","state_id":"4011"},
    {"city_id":"47225","city_name":"Sariasiya","state_id":"4011"},
    {"city_id":"47226","city_name":"Serobod","state_id":"4011"},
    {"city_id":"47227","city_name":"Surci","state_id":"4011"},
    {"city_id":"47228","city_name":"Termiz","state_id":"4011"},
    {"city_id":"47229","city_name":"Zan","state_id":"4011"},
    {"city_id":"47230","city_name":"Almazar","state_id":"4012"},
    {"city_id":"47231","city_name":"Angren","state_id":"4012"},
    {"city_id":"47232","city_name":"Bekobod","state_id":"4012"},
    {"city_id":"47233","city_name":"Bektemir","state_id":"4012"},
    {"city_id":"47234","city_name":"Buka","state_id":"4012"},
    {"city_id":"47235","city_name":"Cinoz","state_id":"4012"},
    {"city_id":"47236","city_name":"Circik","state_id":"4012"},
    {"city_id":"47237","city_name":"Gazalkent","state_id":"4012"},
    {"city_id":"47238","city_name":"Iskandar","state_id":"4012"},
    {"city_id":"47239","city_name":"Keles","state_id":"4012"},
    {"city_id":"47240","city_name":"Kibray","state_id":"4012"},
    {"city_id":"47241","city_name":"Krasnogorskiy","state_id":"4012"},
    {"city_id":"47242","city_name":"Kuyluk","state_id":"4012"},
    {"city_id":"47243","city_name":"Ohangaron","state_id":"4012"},
    {"city_id":"47244","city_name":"Olmalik","state_id":"4012"},
    {"city_id":"47245","city_name":"Parkent","state_id":"4012"},
    {"city_id":"47246","city_name":"Pskent","state_id":"4012"},
    {"city_id":"47247","city_name":"Salar","state_id":"4012"},
    {"city_id":"47248","city_name":"Soldatski","state_id":"4012"},
    {"city_id":"47249","city_name":"Tashkent","state_id":"4012"},
    {"city_id":"47250","city_name":"Toskent","state_id":"4012"},
    {"city_id":"47251","city_name":"Tuytepa","state_id":"4012"},
    {"city_id":"47252","city_name":"Urtaaul","state_id":"4012"},
    {"city_id":"47253","city_name":"Yaniobod","state_id":"4012"},
    {"city_id":"47254","city_name":"Yaniyul","state_id":"4012"},
    {"city_id":"47255","city_name":"Lakatoro","state_id":"4013"},
    {"city_id":"47256","city_name":"Norsup","state_id":"4013"},
    {"city_id":"47257","city_name":"Longana","state_id":"4014"},
    {"city_id":"47258","city_name":"Luganville","state_id":"4015"},
    {"city_id":"47259","city_name":"Port Olry","state_id":"4015"},
    {"city_id":"47260","city_name":"Vila","state_id":"4016"},
    {"city_id":"47261","city_name":"Isangel","state_id":"4017"},
    {"city_id":"47262","city_name":"Sola","state_id":"4018"},
    {"city_id":"47263","city_name":"Puerto Ayacucho","state_id":"4020"},
    {"city_id":"47264","city_name":"Anaco","state_id":"4021"},
    {"city_id":"47265","city_name":"Barcelona","state_id":"4021"},
    {"city_id":"47266","city_name":"Cantaura","state_id":"4021"},
    {"city_id":"47267","city_name":"El Tigre","state_id":"4021"},
    {"city_id":"47268","city_name":"Puerto la Cruz","state_id":"4021"},
    {"city_id":"47269","city_name":"San Jose de Guanipa","state_id":"4021"},
    {"city_id":"47270","city_name":"Guasdualito","state_id":"4022"},
    {"city_id":"47271","city_name":"San Fernando","state_id":"4022"},
    {"city_id":"47272","city_name":"Cagua","state_id":"4023"},
    {"city_id":"47273","city_name":"El Limon","state_id":"4023"},
    {"city_id":"47274","city_name":"La Victoria","state_id":"4023"},
    {"city_id":"47275","city_name":"Las Teyerias","state_id":"4023"},
    {"city_id":"47276","city_name":"Maracay","state_id":"4023"},
    {"city_id":"47277","city_name":"Palo Negro","state_id":"4023"},
    {"city_id":"47278","city_name":"San Mateo","state_id":"4023"},
    {"city_id":"47279","city_name":"Turmero","state_id":"4023"},
    {"city_id":"47280","city_name":"Villa de Cura","state_id":"4023"},
    {"city_id":"47281","city_name":"Barinas","state_id":"4024"},
    {"city_id":"47282","city_name":"Barinitas","state_id":"4024"},
    {"city_id":"47283","city_name":"Ciudad Bolivar","state_id":"4025"},
    {"city_id":"47284","city_name":"Ciudad Guayana","state_id":"4025"},
    {"city_id":"47285","city_name":"Upata","state_id":"4025"},
    {"city_id":"47286","city_name":"Goaigoaza","state_id":"4026"},
    {"city_id":"47287","city_name":"Guacara","state_id":"4026"},
    {"city_id":"47288","city_name":"Guigue","state_id":"4026"},
    {"city_id":"47289","city_name":"Mariara","state_id":"4026"},
    {"city_id":"47290","city_name":"Moron","state_id":"4026"},
    {"city_id":"47291","city_name":"Puerto Cabello","state_id":"4026"},
    {"city_id":"47292","city_name":"San Joaquin","state_id":"4026"},
    {"city_id":"47293","city_name":"Tacarigua","state_id":"4026"},
    {"city_id":"47294","city_name":"Valencia","state_id":"4026"},
    {"city_id":"47295","city_name":"San Carlos","state_id":"4027"},
    {"city_id":"47296","city_name":"Tinaquillo","state_id":"4027"},
    {"city_id":"47297","city_name":"Coro","state_id":"4030"},
    {"city_id":"47298","city_name":"Punta Cardon","state_id":"4030"},
    {"city_id":"47299","city_name":"Punto Fijo","state_id":"4030"},
    {"city_id":"47300","city_name":"Altagracia de Orituco","state_id":"4031"},
    {"city_id":"47301","city_name":"Calabozo","state_id":"4031"},
    {"city_id":"47302","city_name":"San Juan de los Morros","state_id":"4031"},
    {"city_id":"47303","city_name":"Valle de la Pascua","state_id":"4031"},
    {"city_id":"47304","city_name":"Zaraza","state_id":"4031"},
    {"city_id":"47305","city_name":"Barquisimeto","state_id":"4032"},
    {"city_id":"47306","city_name":"Cabudare","state_id":"4032"},
    {"city_id":"47307","city_name":"Carora","state_id":"4032"},
    {"city_id":"47308","city_name":"El Tocuyo","state_id":"4032"},
    {"city_id":"47309","city_name":"Los Rastrojos","state_id":"4032"},
    {"city_id":"47310","city_name":"Quibor","state_id":"4032"},
    {"city_id":"47311","city_name":"Ejido","state_id":"4033"},
    {"city_id":"47312","city_name":"El Vigia","state_id":"4033"},
    {"city_id":"47313","city_name":"Merida","state_id":"4033"},
    {"city_id":"47314","city_name":"Baruta","state_id":"4034"},
    {"city_id":"47315","city_name":"Carrizal","state_id":"4034"},
    {"city_id":"47316","city_name":"Catia La Mar","state_id":"4034"},
    {"city_id":"47317","city_name":"Caucaguita","state_id":"4034"},
    {"city_id":"47318","city_name":"Chacao","state_id":"4034"},
    {"city_id":"47319","city_name":"Charallave","state_id":"4034"},
    {"city_id":"47320","city_name":"Cua","state_id":"4034"},
    {"city_id":"47321","city_name":"El Cafetal","state_id":"4034"},
    {"city_id":"47322","city_name":"El Hatillo","state_id":"4034"},
    {"city_id":"47323","city_name":"Filas de Mariche","state_id":"4034"},
    {"city_id":"47324","city_name":"Guarenas","state_id":"4034"},
    {"city_id":"47325","city_name":"Guatire","state_id":"4034"},
    {"city_id":"47326","city_name":"La Dolorita","state_id":"4034"},
    {"city_id":"47327","city_name":"Los Dos Caminos","state_id":"4034"},
    {"city_id":"47328","city_name":"Los Teques","state_id":"4034"},
    {"city_id":"47329","city_name":"Ocumare del Tuy","state_id":"4034"},
    {"city_id":"47330","city_name":"Petare","state_id":"4034"},
    {"city_id":"47331","city_name":"San Antonio de los Altos","state_id":"4034"},
    {"city_id":"47332","city_name":"Santa Teresa","state_id":"4034"},
    {"city_id":"47333","city_name":"Maturin","state_id":"4035"},
    {"city_id":"47334","city_name":"Acarigua","state_id":"4037"},
    {"city_id":"47335","city_name":"Araure","state_id":"4037"},
    {"city_id":"47336","city_name":"Guanare","state_id":"4037"},
    {"city_id":"47337","city_name":"Villa Bruzual","state_id":"4037"},
    {"city_id":"47338","city_name":"Carupano","state_id":"4038"},
    {"city_id":"47339","city_name":"Cumana","state_id":"4038"},
    {"city_id":"47340","city_name":"La Fria","state_id":"4039"},
    {"city_id":"47341","city_name":"Rubio","state_id":"4039"},
    {"city_id":"47342","city_name":"San Antonio del Tachira","state_id":"4039"},
    {"city_id":"47343","city_name":"San Cristobal","state_id":"4039"},
    {"city_id":"47344","city_name":"San Juan de Colon","state_id":"4039"},
    {"city_id":"47345","city_name":"Tariba","state_id":"4039"},
    {"city_id":"47346","city_name":"Trujillo","state_id":"4040"},
    {"city_id":"47347","city_name":"Valera","state_id":"4040"},
    {"city_id":"47348","city_name":"Caraballeda","state_id":"4041"},
    {"city_id":"47349","city_name":"La Guaira","state_id":"4041"},
    {"city_id":"47350","city_name":"Maiquetia","state_id":"4041"},
    {"city_id":"47351","city_name":"Chivacoa","state_id":"4042"},
    {"city_id":"47352","city_name":"Nirgua","state_id":"4042"},
    {"city_id":"47353","city_name":"San Felipe","state_id":"4042"},
    {"city_id":"47354","city_name":"Yaritagua","state_id":"4042"},
    {"city_id":"47355","city_name":"Bachaquero","state_id":"4043"},
    {"city_id":"47356","city_name":"Cabimas","state_id":"4043"},
    {"city_id":"47357","city_name":"Ciudad Ojeda","state_id":"4043"},
    {"city_id":"47358","city_name":"Lagunillas","state_id":"4043"},
    {"city_id":"47359","city_name":"Machiques","state_id":"4043"},
    {"city_id":"47360","city_name":"Maracaibo","state_id":"4043"},
    {"city_id":"47361","city_name":"San Carlos del Zulia","state_id":"4043"},
    {"city_id":"47362","city_name":"Santa Rita","state_id":"4043"},
    {"city_id":"47363","city_name":"Villa del Rosario","state_id":"4043"},
    {"city_id":"47364","city_name":"Ha noi","state_id":"4053"},
    {"city_id":"47365","city_name":"Hoang Mai","state_id":"4053"},
    {"city_id":"47366","city_name":"Lang Ha","state_id":"4053"},
    {"city_id":"47367","city_name":"Settlement","state_id":"4064"},
    {"city_id":"47368","city_name":"East End-Long Look","state_id":"4066"},
    {"city_id":"47369","city_name":"Road Town","state_id":"4066"},
    {"city_id":"47370","city_name":"West End","state_id":"4066"},
    {"city_id":"47371","city_name":"Kolia","state_id":"4070"},
    {"city_id":"47372","city_name":"Mala'e","state_id":"4070"},
    {"city_id":"47373","city_name":"Ono","state_id":"4070"},
    {"city_id":"47374","city_name":"Poi","state_id":"4070"},
    {"city_id":"47375","city_name":"Tamana","state_id":"4070"},
    {"city_id":"47376","city_name":"Taoa","state_id":"4070"},
    {"city_id":"47377","city_name":"Tuatafa","state_id":"4070"},
    {"city_id":"47378","city_name":"Vele","state_id":"4070"},
    {"city_id":"47379","city_name":"Fiua","state_id":"4071"},
    {"city_id":"47380","city_name":"Leava","state_id":"4071"},
    {"city_id":"47381","city_name":"Nuku","state_id":"4071"},
    {"city_id":"47382","city_name":"Tavai","state_id":"4071"},
    {"city_id":"47383","city_name":"Toloke","state_id":"4071"},
    {"city_id":"47384","city_name":"Vaisei","state_id":"4071"},
    {"city_id":"47385","city_name":"Ahoa","state_id":"4072"},
    {"city_id":"47386","city_name":"Aka Aka","state_id":"4072"},
    {"city_id":"47387","city_name":"Alele","state_id":"4072"},
    {"city_id":"47388","city_name":"Falaleu","state_id":"4072"},
    {"city_id":"47389","city_name":"Gahi","state_id":"4072"},
    {"city_id":"47390","city_name":"Haafuasia","state_id":"4072"},
    {"city_id":"47391","city_name":"Haatofo","state_id":"4072"},
    {"city_id":"47392","city_name":"Halalo","state_id":"4072"},
    {"city_id":"47393","city_name":"Kolopopo","state_id":"4072"},
    {"city_id":"47394","city_name":"Lavegahau","state_id":"4072"},
    {"city_id":"47395","city_name":"Liku","state_id":"4072"},
    {"city_id":"47396","city_name":"Mala'e","state_id":"4072"},
    {"city_id":"47397","city_name":"Malaefoou","state_id":"4072"},
    {"city_id":"47398","city_name":"Mata'Utu","state_id":"4072"},
    {"city_id":"47399","city_name":"Teesi","state_id":"4072"},
    {"city_id":"47400","city_name":"Tepa","state_id":"4072"},
    {"city_id":"47401","city_name":"Utuofa","state_id":"4072"},
    {"city_id":"47402","city_name":"Vailala","state_id":"4072"},
    {"city_id":"47403","city_name":"Vaimalau","state_id":"4072"},
    {"city_id":"47404","city_name":"Vaitupu","state_id":"4072"},
    {"city_id":"47405","city_name":"ad-Dakhlah","state_id":"4074"},
    {"city_id":"47406","city_name":"al-'Ayun","state_id":"4075"},
    {"city_id":"47407","city_name":"as-Samarah","state_id":"4076"},
    {"city_id":"47408","city_name":"'Adan","state_id":"4077"},
    {"city_id":"47409","city_name":"Aden","state_id":"4077"},
    {"city_id":"47410","city_name":"Ahwar","state_id":"4078"},
    {"city_id":"47411","city_name":"Ja'ar","state_id":"4078"},
    {"city_id":"47412","city_name":"Zinjibar","state_id":"4078"},
    {"city_id":"47413","city_name":"Dhamar","state_id":"4079"},
    {"city_id":"47414","city_name":"Shaqra'","state_id":"4080"},
    {"city_id":"47415","city_name":"al-Mukalla","state_id":"4080"},
    {"city_id":"47416","city_name":"ash-Shahir","state_id":"4080"},
    {"city_id":"47417","city_name":"Hajjah","state_id":"4081"},
    {"city_id":"47418","city_name":"Hodaidah","state_id":"4082"},
    {"city_id":"47419","city_name":"Dhi Sufal","state_id":"4083"},
    {"city_id":"47420","city_name":"Ibb","state_id":"4083"},
    {"city_id":"47421","city_name":"Jiblah","state_id":"4083"},
    {"city_id":"47422","city_name":"Qa'tabah","state_id":"4083"},
    {"city_id":"47423","city_name":"Yarim","state_id":"4083"},
    {"city_id":"47424","city_name":"Tuban","state_id":"4084"},
    {"city_id":"47425","city_name":"Ma'rib","state_id":"4085"},
    {"city_id":"47426","city_name":"Sa'dah","state_id":"4087"},
    {"city_id":"47427","city_name":"'Amran","state_id":"4088"},
    {"city_id":"47428","city_name":"Sahar","state_id":"4088"},
    {"city_id":"47429","city_name":"Sanaa","state_id":"4088"},
    {"city_id":"47430","city_name":"Sayyan","state_id":"4088"},
    {"city_id":"47431","city_name":"'Ataq","state_id":"4089"},
    {"city_id":"47432","city_name":"Habban","state_id":"4089"},
    {"city_id":"47433","city_name":"Taiz","state_id":"4089"},
    {"city_id":"47434","city_name":"Ta'izz","state_id":"4090"},
    {"city_id":"47435","city_name":"al-Mukha","state_id":"4090"},
    {"city_id":"47436","city_name":"Raydah","state_id":"4091"},
    {"city_id":"47437","city_name":"al-Bayda","state_id":"4091"},
    {"city_id":"47438","city_name":"Bajil","state_id":"4092"},
    {"city_id":"47439","city_name":"Bayt-al-Faqih","state_id":"4092"},
    {"city_id":"47440","city_name":"Zabid","state_id":"4092"},
    {"city_id":"47441","city_name":"al-Hudaydah","state_id":"4092"},
    {"city_id":"47442","city_name":"al-Marawi'ah","state_id":"4092"},
    {"city_id":"47443","city_name":"az-Zaydiyah","state_id":"4092"},
    {"city_id":"47444","city_name":"al-Hazm","state_id":"4093"},
    {"city_id":"47445","city_name":"al-Ghaydah","state_id":"4094"},
    {"city_id":"47446","city_name":"Mahwit","state_id":"4095"},
    {"city_id":"47447","city_name":"Nikshic","state_id":"4098"},
    {"city_id":"47448","city_name":"Pljevlja","state_id":"4098"},
    {"city_id":"47449","city_name":"Podgorica","state_id":"4098"},
    {"city_id":"47450","city_name":"Ulcinj","state_id":"4098"},
    {"city_id":"47451","city_name":"Gjakove","state_id":"4100"},
    {"city_id":"47452","city_name":"Novi Banovci","state_id":"4100"},
    {"city_id":"47453","city_name":"Senta","state_id":"4100"},
    {"city_id":"47454","city_name":"Serbia","state_id":"4100"},
    {"city_id":"47455","city_name":"Uzice","state_id":"4100"},
    {"city_id":"47456","city_name":"Ada","state_id":"4101"},
    {"city_id":"47457","city_name":"Alibunar","state_id":"4101"},
    {"city_id":"47458","city_name":"Apatin","state_id":"4101"},
    {"city_id":"47459","city_name":"Bach","state_id":"4101"},
    {"city_id":"47460","city_name":"Bachka Palanka","state_id":"4101"},
    {"city_id":"47461","city_name":"Bachka Petrovac","state_id":"4101"},
    {"city_id":"47462","city_name":"Bachka Topola","state_id":"4101"},
    {"city_id":"47463","city_name":"Bachki Jarak","state_id":"4101"},
    {"city_id":"47464","city_name":"Banacki Karlovac","state_id":"4101"},
    {"city_id":"47465","city_name":"Bechej","state_id":"4101"},
    {"city_id":"47466","city_name":"Bela Crkva","state_id":"4101"},
    {"city_id":"47467","city_name":"Beochin","state_id":"4101"},
    {"city_id":"47468","city_name":"Choka","state_id":"4101"},
    {"city_id":"47469","city_name":"Churug","state_id":"4101"},
    {"city_id":"47470","city_name":"Crvenka","state_id":"4101"},
    {"city_id":"47471","city_name":"Futog","state_id":"4101"},
    {"city_id":"47472","city_name":"Indhija","state_id":"4101"},
    {"city_id":"47473","city_name":"Irig","state_id":"4101"},
    {"city_id":"47474","city_name":"Kac","state_id":"4101"},
    {"city_id":"47475","city_name":"Kanjizha","state_id":"4101"},
    {"city_id":"47476","city_name":"Kikinda","state_id":"4101"},
    {"city_id":"47477","city_name":"Kovachica","state_id":"4101"},
    {"city_id":"47478","city_name":"Kovin","state_id":"4101"},
    {"city_id":"47479","city_name":"Kula","state_id":"4101"},
    {"city_id":"47480","city_name":"Mol","state_id":"4101"},
    {"city_id":"47481","city_name":"Nova Pazova","state_id":"4101"},
    {"city_id":"47482","city_name":"Novi Bechej","state_id":"4101"},
    {"city_id":"47483","city_name":"Novi Knezhevac","state_id":"4101"},
    {"city_id":"47484","city_name":"Novi Sad","state_id":"4101"},
    {"city_id":"47485","city_name":"Odzhaci","state_id":"4101"},
    {"city_id":"47486","city_name":"Pacarak","state_id":"4101"},
    {"city_id":"47487","city_name":"Palic","state_id":"4101"},
    {"city_id":"47488","city_name":"Panchevo","state_id":"4101"},
    {"city_id":"47489","city_name":"Petrovaradin","state_id":"4101"},
    {"city_id":"47490","city_name":"Ruma","state_id":"4101"},
    {"city_id":"47491","city_name":"Senta","state_id":"4101"},
    {"city_id":"47492","city_name":"Shid","state_id":"4101"},
    {"city_id":"47493","city_name":"Sivac","state_id":"4101"},
    {"city_id":"47494","city_name":"Sombor","state_id":"4101"},
    {"city_id":"47495","city_name":"Srbobran","state_id":"4101"},
    {"city_id":"47496","city_name":"Sremska Kamenica","state_id":"4101"},
    {"city_id":"47497","city_name":"Sremska Mitrovica","state_id":"4101"},
    {"city_id":"47498","city_name":"Sremski Karlovci","state_id":"4101"},
    {"city_id":"47499","city_name":"Stara Pazova","state_id":"4101"},
    {"city_id":"47500","city_name":"Subotica","state_id":"4101"},
    {"city_id":"47501","city_name":"Temerin","state_id":"4101"},
    {"city_id":"47502","city_name":"Veternik","state_id":"4101"},
    {"city_id":"47503","city_name":"Vrbas","state_id":"4101"},
    {"city_id":"47504","city_name":"Vrshac","state_id":"4101"},
    {"city_id":"47505","city_name":"Zhabalj","state_id":"4101"},
    {"city_id":"47506","city_name":"Zhitishte","state_id":"4101"},
    {"city_id":"47507","city_name":"Zrenjanin","state_id":"4101"},
    {"city_id":"47508","city_name":"Kabwe","state_id":"4102"},
    {"city_id":"47509","city_name":"Kapiri Mposhi","state_id":"4102"},
    {"city_id":"47510","city_name":"Mkushi","state_id":"4102"},
    {"city_id":"47511","city_name":"Mumbwa","state_id":"4102"},
    {"city_id":"47512","city_name":"Serenje","state_id":"4102"},
    {"city_id":"47513","city_name":"Chambishi","state_id":"4103"},
    {"city_id":"47514","city_name":"Chililabombwe","state_id":"4103"},
    {"city_id":"47515","city_name":"Chingola","state_id":"4103"},
    {"city_id":"47516","city_name":"Kalulushi","state_id":"4103"},
    {"city_id":"47517","city_name":"Kitwe","state_id":"4103"},
    {"city_id":"47518","city_name":"Luanshya","state_id":"4103"},
    {"city_id":"47519","city_name":"Mpongwe","state_id":"4103"},
    {"city_id":"47520","city_name":"Mufulira","state_id":"4103"},
    {"city_id":"47521","city_name":"Ndola","state_id":"4103"},
    {"city_id":"47522","city_name":"Chipata","state_id":"4104"},
    {"city_id":"47523","city_name":"Katete","state_id":"4104"},
    {"city_id":"47524","city_name":"Lundazi","state_id":"4104"},
    {"city_id":"47525","city_name":"Petauke","state_id":"4104"},
    {"city_id":"47526","city_name":"Mansa","state_id":"4105"},
    {"city_id":"47527","city_name":"Mwansabombwe","state_id":"4105"},
    {"city_id":"47528","city_name":"Nchelenge","state_id":"4105"},
    {"city_id":"47529","city_name":"Samfya","state_id":"4105"},
    {"city_id":"47530","city_name":"Chilanga","state_id":"4106"},
    {"city_id":"47531","city_name":"Kafue","state_id":"4106"},
    {"city_id":"47532","city_name":"Lusaka","state_id":"4106"},
    {"city_id":"47533","city_name":"Kalengwa","state_id":"4107"},
    {"city_id":"47534","city_name":"Kansanshi","state_id":"4107"},
    {"city_id":"47535","city_name":"Mwinilunga","state_id":"4107"},
    {"city_id":"47536","city_name":"Solwezi","state_id":"4107"},
    {"city_id":"47537","city_name":"Zambezi","state_id":"4107"},
    {"city_id":"47538","city_name":"Chinsali","state_id":"4108"},
    {"city_id":"47539","city_name":"Isoka","state_id":"4108"},
    {"city_id":"47540","city_name":"Kasama","state_id":"4108"},
    {"city_id":"47541","city_name":"Mbala","state_id":"4108"},
    {"city_id":"47542","city_name":"Mpika","state_id":"4108"},
    {"city_id":"47543","city_name":"Choma","state_id":"4109"},
    {"city_id":"47544","city_name":"Itezhi-Tezhi","state_id":"4109"},
    {"city_id":"47545","city_name":"Kalomo","state_id":"4109"},
    {"city_id":"47546","city_name":"Livingstone","state_id":"4109"},
    {"city_id":"47547","city_name":"Maamba","state_id":"4109"},
    {"city_id":"47548","city_name":"Mazabuka","state_id":"4109"},
    {"city_id":"47549","city_name":"Monze","state_id":"4109"},
    {"city_id":"47550","city_name":"Nakambala","state_id":"4109"},
    {"city_id":"47551","city_name":"Siavonga","state_id":"4109"},
    {"city_id":"47552","city_name":"Kalabo","state_id":"4110"},
    {"city_id":"47553","city_name":"Kaoma","state_id":"4110"},
    {"city_id":"47554","city_name":"Limulunga","state_id":"4110"},
    {"city_id":"47555","city_name":"Mongu","state_id":"4110"},
    {"city_id":"47556","city_name":"Senanga","state_id":"4110"},
    {"city_id":"47557","city_name":"Sesheke","state_id":"4110"},
    {"city_id":"47558","city_name":"Bulawayo","state_id":"4111"},
    {"city_id":"47559","city_name":"Chitungwiza","state_id":"4112"},
    {"city_id":"47560","city_name":"Harare","state_id":"4112"},
    {"city_id":"47561","city_name":"Chimanimani","state_id":"4113"},
    {"city_id":"47562","city_name":"Chipinge","state_id":"4113"},
    {"city_id":"47563","city_name":"Mutare","state_id":"4113"},
    {"city_id":"47564","city_name":"Nyanga","state_id":"4113"},
    {"city_id":"47565","city_name":"Rusape","state_id":"4113"},
    {"city_id":"47566","city_name":"Sakubva","state_id":"4113"},
    {"city_id":"47567","city_name":"Chiredzi","state_id":"4117"},
    {"city_id":"47568","city_name":"Gaths","state_id":"4117"},
    {"city_id":"47569","city_name":"Masvingo","state_id":"4117"},
    {"city_id":"47570","city_name":"Chivhu","state_id":"4120"},
    {"city_id":"47571","city_name":"Gweru","state_id":"4120"},
    {"city_id":"47572","city_name":"Kwekwe","state_id":"4120"},
    {"city_id":"47573","city_name":"Mvuma","state_id":"4120"},
    {"city_id":"47574","city_name":"Redcliffe","state_id":"4120"},
    {"city_id":"47575","city_name":"Shurugwi","state_id":"4120"},
    {"city_id":"47576","city_name":"Zvishavane","state_id":"4120"},
    {"city_id":"47577","city_name":"Manchester","state_id":"3952"},
    {"city_id":"47578","city_name":"Nashua","state_id":"3952"},
    {"city_id":"47579","city_name":"Concord","state_id":"3952"},
    {"city_id":"47580","city_name":"Derry","state_id":"3952"},
    {"city_id":"47581","city_name":"Dover","state_id":"3952"},
    {"city_id":"47582","city_name":"Rochester","state_id":"3952"},
    {"city_id":"47583","city_name":"Salem","state_id":"3952"},
    {"city_id":"47584","city_name":"Merrimack","state_id":"3952"},
    {"city_id":"47585","city_name":"Hudson","state_id":"3952"},
    {"city_id":"47586","city_name":"Londonderry","state_id":"3952"},
    {"city_id":"47587","city_name":"Keene","state_id":"3952"},
    {"city_id":"47588","city_name":"Bedford","state_id":"3952"},
    {"city_id":"47589","city_name":"Portsmouth","state_id":"3952"},
    {"city_id":"47590","city_name":"Goffstown","state_id":"3952"},
    {"city_id":"47591","city_name":"Laconia","state_id":"3952"},
    {"city_id":"47592","city_name":"Hampton","state_id":"3952"},
    {"city_id":"47593","city_name":"Milford","state_id":"3952"},
    {"city_id":"47594","city_name":"Durham","state_id":"3952"},
    {"city_id":"47595","city_name":"Exeter","state_id":"3952"},
    {"city_id":"47596","city_name":"Windham","state_id":"3952"},
    {"city_id":"47597","city_name":"Hooksett","state_id":"3952"},
    {"city_id":"47598","city_name":"Claremont","state_id":"3952"},
    {"city_id":"47599","city_name":"Lebanon","state_id":"3952"},
    {"city_id":"47600","city_name":"Pelham","state_id":"3952"},
    {"city_id":"47601","city_name":"Somersworth","state_id":"3952"},
    {"city_id":"47602","city_name":"Hanover","state_id":"3952"},
    {"city_id":"47603","city_name":"Amherst","state_id":"3952"},
    {"city_id":"47604","city_name":"Raymond","state_id":"3952"},
    {"city_id":"47605","city_name":"Conway","state_id":"3952"},
    {"city_id":"47606","city_name":"Berlin","state_id":"3952"},
    {"city_id":"47607","city_name":"Newmarket","state_id":"3952"},
    {"city_id":"47608","city_name":"Weare","state_id":"3952"},
    {"city_id":"47609","city_name":"Seabrook","state_id":"3952"},
    {"city_id":"47610","city_name":"Barrington","state_id":"3952"},
    {"city_id":"47611","city_name":"Hampstead","state_id":"3952"},
    {"city_id":"47612","city_name":"Franklin","state_id":"3952"},
    {"city_id":"47613","city_name":"Litchfield","state_id":"3952"},
    {"city_id":"47614","city_name":"Hollis","state_id":"3952"},
    {"city_id":"47615","city_name":"Plaistow","state_id":"3952"},
    {"city_id":"47616","city_name":"Bow","state_id":"3952"},
    {"city_id":"47617","city_name":"Belmont","state_id":"3952"},
    {"city_id":"47618","city_name":"Stratham","state_id":"3952"},
    {"city_id":"47619","city_name":"Swanzey","state_id":"3952"},
    {"city_id":"47620","city_name":"Gilford","state_id":"3952"},
    {"city_id":"47621","city_name":"Pembroke","state_id":"3952"},
    {"city_id":"47622","city_name":"Plymouth","state_id":"3952"},
    {"city_id":"47623","city_name":"Farmington","state_id":"3952"},
    {"city_id":"47624","city_name":"Atkinson","state_id":"3952"},
    {"city_id":"47625","city_name":"Newport","state_id":"3952"},
    {"city_id":"47626","city_name":"Epping","state_id":"3952"},
    {"city_id":"47627","city_name":"Peterborough","state_id":"3952"},
    {"city_id":"47628","city_name":"Wolfeboro","state_id":"3952"},
    {"city_id":"47629","city_name":"Meredith","state_id":"3952"},
    {"city_id":"47630","city_name":"Kingston","state_id":"3952"},
    {"city_id":"47631","city_name":"Rindge","state_id":"3952"},
    {"city_id":"47632","city_name":"Hillsborough","state_id":"3952"},
    {"city_id":"47633","city_name":"Newark","state_id":"3953"},
    {"city_id":"47634","city_name":"Jersey City","state_id":"3953"},
    {"city_id":"47635","city_name":"Paterson","state_id":"3953"},
    {"city_id":"47636","city_name":"Elizabeth","state_id":"3953"},
    {"city_id":"47637","city_name":"Edison","state_id":"3953"},
    {"city_id":"47638","city_name":"Woodbridge","state_id":"3953"},
    {"city_id":"47639","city_name":"Lakewood","state_id":"3953"},
    {"city_id":"47640","city_name":"Toms River","state_id":"3953"},
    {"city_id":"47641","city_name":"Hamilton","state_id":"3953"},
    {"city_id":"47642","city_name":"Trenton","state_id":"3953"},
    {"city_id":"47643","city_name":"Clifton","state_id":"3953"},
    {"city_id":"47644","city_name":"Camden","state_id":"3953"},
    {"city_id":"47645","city_name":"Brick","state_id":"3953"},
    {"city_id":"47646","city_name":"Cherry Hill","state_id":"3953"},
    {"city_id":"47647","city_name":"Passaic","state_id":"3953"},
    {"city_id":"47648","city_name":"Middletown","state_id":"3953"},
    {"city_id":"47649","city_name":"Union City","state_id":"3953"},
    {"city_id":"47650","city_name":"Old Bridge","state_id":"3953"},
    {"city_id":"47651","city_name":"Gloucester Township","state_id":"3953"},
    {"city_id":"47652","city_name":"East Orange","state_id":"3953"},
    {"city_id":"47653","city_name":"Bayonne","state_id":"3953"},
    {"city_id":"47654","city_name":"Franklin","state_id":"3953"},
    {"city_id":"47655","city_name":"North Bergen","state_id":"3953"},
    {"city_id":"47656","city_name":"Vineland","state_id":"3953"},
    {"city_id":"47657","city_name":"Union","state_id":"3953"},
    {"city_id":"47658","city_name":"Piscataway","state_id":"3953"},
    {"city_id":"47659","city_name":"New Brunswick","state_id":"3953"},
    {"city_id":"47660","city_name":"Jackson","state_id":"3953"},
    {"city_id":"47661","city_name":"Wayne","state_id":"3953"},
    {"city_id":"47662","city_name":"Irvington","state_id":"3953"},
    {"city_id":"47663","city_name":"Parsippany-Troy Hills","state_id":"3953"},
    {"city_id":"47664","city_name":"Howell","state_id":"3953"},
    {"city_id":"47665","city_name":"Perth Amboy","state_id":"3953"},
    {"city_id":"47666","city_name":"Hoboken","state_id":"3953"},
    {"city_id":"47667","city_name":"Plainfield","state_id":"3953"},
    {"city_id":"47668","city_name":"West New York","state_id":"3953"},
    {"city_id":"47669","city_name":"Washington Township","state_id":"3953"},
    {"city_id":"47670","city_name":"East Brunswick","state_id":"3953"},
    {"city_id":"47671","city_name":"Bloomfield","state_id":"3953"},
    {"city_id":"47672","city_name":"West Orange","state_id":"3953"},
    {"city_id":"47673","city_name":"Evesham","state_id":"3953"},
    {"city_id":"47674","city_name":"Bridgewater","state_id":"3953"},
    {"city_id":"47675","city_name":"South Brunswick","state_id":"3953"},
    {"city_id":"47676","city_name":"Egg Harbor","state_id":"3953"},
    {"city_id":"47677","city_name":"Manchester","state_id":"3953"},
    {"city_id":"47678","city_name":"Hackensack","state_id":"3953"},
    {"city_id":"47679","city_name":"Sayreville","state_id":"3953"},
    {"city_id":"47680","city_name":"Mount Laurel","state_id":"3953"},
    {"city_id":"47681","city_name":"Berkeley","state_id":"3953"},
    {"city_id":"47682","city_name":"North Brunswick","state_id":"3953"},
    {"city_id":"47683","city_name":"Kearny","state_id":"3953"},
    {"city_id":"47684","city_name":"Linden","state_id":"3953"},
    {"city_id":"47685","city_name":"Marlboro","state_id":"3953"},
    {"city_id":"47686","city_name":"Teaneck","state_id":"3953"},
    {"city_id":"47687","city_name":"Atlantic City","state_id":"3953"},
    {"city_id":"47688","city_name":"Winslow","state_id":"3953"},
    {"city_id":"47689","city_name":"Monroe","state_id":"3953"},
    {"city_id":"47690","city_name":"Manalapan","state_id":"3953"},
    {"city_id":"47691","city_name":"Hillsborough","state_id":"3953"},
    {"city_id":"47692","city_name":"Montclair","state_id":"3953"},
    {"city_id":"47693","city_name":"Galloway","state_id":"3953"},
    {"city_id":"47694","city_name":"Freehold Township","state_id":"3953"},
    {"city_id":"47695","city_name":"Monroe Township","state_id":"3953"},
    {"city_id":"47696","city_name":"Belleville","state_id":"3953"},
    {"city_id":"47697","city_name":"Pennsauken","state_id":"3953"},
    {"city_id":"47698","city_name":"Ewing","state_id":"3953"},
    {"city_id":"47699","city_name":"Fort Lee","state_id":"3953"},
    {"city_id":"47700","city_name":"Lawrence","state_id":"3953"},
    {"city_id":"47701","city_name":"Fair Lawn","state_id":"3953"},
    {"city_id":"47702","city_name":"Willingboro","state_id":"3953"},
    {"city_id":"47703","city_name":"Long Branch","state_id":"3953"},
    {"city_id":"47704","city_name":"Deptford","state_id":"3953"},
    {"city_id":"47705","city_name":"Garfield","state_id":"3953"},
    {"city_id":"47706","city_name":"Westfield","state_id":"3953"},
    {"city_id":"47707","city_name":"City of Orange","state_id":"3953"},
    {"city_id":"47708","city_name":"Livingston","state_id":"3953"},
    {"city_id":"47709","city_name":"Voorhees","state_id":"3953"},
    {"city_id":"47710","city_name":"Princeton","state_id":"3953"},
    {"city_id":"47711","city_name":"Millville","state_id":"3953"},
    {"city_id":"47712","city_name":"Nutley","state_id":"3953"},
    {"city_id":"47713","city_name":"Mount Olive","state_id":"3953"},
    {"city_id":"47714","city_name":"Neptune","state_id":"3953"},
    {"city_id":"47715","city_name":"Pemberton Township","state_id":"3953"},
    {"city_id":"47716","city_name":"Lacey","state_id":"3953"},
    {"city_id":"47717","city_name":"Rahway","state_id":"3953"},
    {"city_id":"47718","city_name":"Ocean Township","state_id":"3953"},
    {"city_id":"47719","city_name":"East Windsor","state_id":"3953"},
    {"city_id":"47720","city_name":"West Windsor","state_id":"3953"},
    {"city_id":"47721","city_name":"Englewood","state_id":"3953"},
    {"city_id":"47722","city_name":"Bergenfield","state_id":"3953"},
    {"city_id":"47723","city_name":"Bernards Township","state_id":"3953"},
    {"city_id":"47724","city_name":"Stafford Township","state_id":"3953"},
    {"city_id":"47725","city_name":"Hamilton Township","state_id":"3953"},
    {"city_id":"47726","city_name":"Paramus","state_id":"3953"},
    {"city_id":"47727","city_name":"Wall","state_id":"3953"},
    {"city_id":"47728","city_name":"Mahwah","state_id":"3953"},
    {"city_id":"47729","city_name":"West Milford","state_id":"3953"},
    {"city_id":"47730","city_name":"Randolph","state_id":"3953"},
    {"city_id":"47731","city_name":"Bridgeton","state_id":"3953"},
    {"city_id":"47732","city_name":"Ridgewood","state_id":"3953"},
    {"city_id":"47733","city_name":"Rockaway Township","state_id":"3953"},
    {"city_id":"47734","city_name":"Lodi","state_id":"3953"},
    {"city_id":"47735","city_name":"Vernon","state_id":"3953"},
    {"city_id":"47736","city_name":"Maplewood","state_id":"3953"},
    {"city_id":"47737","city_name":"Cliffside Park","state_id":"3953"},
    {"city_id":"47738","city_name":"Scotch Plains","state_id":"3953"},
    {"city_id":"47739","city_name":"South Plainfield","state_id":"3953"},
    {"city_id":"47740","city_name":"Roxbury","state_id":"3953"},
    {"city_id":"47741","city_name":"Medford","state_id":"3953"},
    {"city_id":"47742","city_name":"Plainsboro","state_id":"3953"},
    {"city_id":"47743","city_name":"Lower Township","state_id":"3953"},
    {"city_id":"47744","city_name":"Carteret","state_id":"3953"},
    {"city_id":"47745","city_name":"Cranford","state_id":"3953"},
    {"city_id":"47746","city_name":"Burlington Township","state_id":"3953"},
    {"city_id":"47747","city_name":"Morris Township","state_id":"3953"},
    {"city_id":"47748","city_name":"Montgomery","state_id":"3953"},
    {"city_id":"47749","city_name":"Raritan Township","state_id":"3953"},
    {"city_id":"47750","city_name":"North Plainfield","state_id":"3953"},
    {"city_id":"47751","city_name":"West Deptford","state_id":"3953"},
    {"city_id":"47752","city_name":"Montville","state_id":"3953"},
    {"city_id":"47753","city_name":"Summit","state_id":"3953"},
    {"city_id":"47754","city_name":"Hillside","state_id":"3953"},
    {"city_id":"47755","city_name":"Jefferson","state_id":"3953"},
    {"city_id":"47756","city_name":"Roselle","state_id":"3953"},
    {"city_id":"47757","city_name":"Barnegat","state_id":"3953"},
    {"city_id":"47758","city_name":"Moorestown","state_id":"3953"},
    {"city_id":"47759","city_name":"Lyndhurst","state_id":"3953"},
    {"city_id":"47760","city_name":"Hazlet","state_id":"3953"},
    {"city_id":"47761","city_name":"Pleasantville","state_id":"3953"},
    {"city_id":"47762","city_name":"Millburn","state_id":"3953"},
    {"city_id":"47763","city_name":"Little Egg Harbor","state_id":"3953"},
    {"city_id":"47764","city_name":"Sparta","state_id":"3953"},
    {"city_id":"47765","city_name":"Palisades Park","state_id":"3953"},
    {"city_id":"47766","city_name":"Elmwood Park","state_id":"3953"},
    {"city_id":"47767","city_name":"Maple Shade","state_id":"3953"},
    {"city_id":"47768","city_name":"Middle Township","state_id":"3953"},
    {"city_id":"47769","city_name":"Hawthorne","state_id":"3953"},
    {"city_id":"47770","city_name":"Glassboro","state_id":"3953"},
    {"city_id":"47771","city_name":"Washington Township","state_id":"3953"},
    {"city_id":"47772","city_name":"Morristown","state_id":"3953"},
    {"city_id":"47773","city_name":"Point Pleasant","state_id":"3953"},
    {"city_id":"47774","city_name":"Aberdeen","state_id":"3953"},
    {"city_id":"47775","city_name":"Dover","state_id":"3953"},
    {"city_id":"47776","city_name":"Rutherford","state_id":"3953"},
    {"city_id":"47777","city_name":"Tinton Falls","state_id":"3953"},
    {"city_id":"47778","city_name":"Lindenwold","state_id":"3953"},
    {"city_id":"47779","city_name":"Dumont","state_id":"3953"},
    {"city_id":"47780","city_name":"Hopewell Township","state_id":"3953"},
    {"city_id":"47781","city_name":"Delran","state_id":"3953"},
    {"city_id":"47782","city_name":"Franklin Township","state_id":"3953"},
    {"city_id":"47783","city_name":"Holmdel","state_id":"3953"},
    {"city_id":"47784","city_name":"Wyckoff","state_id":"3953"},
    {"city_id":"47785","city_name":"Denville","state_id":"3953"},
    {"city_id":"47786","city_name":"New Milford","state_id":"3953"},
    {"city_id":"47787","city_name":"Secaucus","state_id":"3953"},
    {"city_id":"47788","city_name":"South Orange Village","state_id":"3953"},
    {"city_id":"47789","city_name":"Readington","state_id":"3953"},
    {"city_id":"47790","city_name":"Asbury Park","state_id":"3953"},
    {"city_id":"47791","city_name":"South River","state_id":"3953"},
    {"city_id":"47792","city_name":"Madison","state_id":"3953"},
    {"city_id":"47793","city_name":"Springfield","state_id":"3953"},
    {"city_id":"47794","city_name":"Cinnaminson","state_id":"3953"},
    {"city_id":"47795","city_name":"Pequannock","state_id":"3953"},
    {"city_id":"47796","city_name":"North Arlington","state_id":"3953"},
    {"city_id":"47797","city_name":"Warren","state_id":"3953"},
    {"city_id":"47798","city_name":"Mantua","state_id":"3953"},
    {"city_id":"47799","city_name":"Hopatcong","state_id":"3953"},
    {"city_id":"47800","city_name":"Phillipsburg","state_id":"3953"},
    {"city_id":"47801","city_name":"Hammonton","state_id":"3953"},
    {"city_id":"47802","city_name":"Clark","state_id":"3953"},
    {"city_id":"47803","city_name":"Haddon Township","state_id":"3953"},
    {"city_id":"47804","city_name":"Tenafly","state_id":"3953"},
    {"city_id":"47805","city_name":"Ramsey","state_id":"3953"},
    {"city_id":"47806","city_name":"Branchburg","state_id":"3953"},
    {"city_id":"47807","city_name":"Little Falls","state_id":"3953"},
    {"city_id":"47808","city_name":"Highland Park","state_id":"3953"},
    {"city_id":"47809","city_name":"Collingswood","state_id":"3953"},
    {"city_id":"47810","city_name":"Fairview","state_id":"3953"},
    {"city_id":"47811","city_name":"Hanover","state_id":"3953"},
    {"city_id":"47812","city_name":"Saddle Brook","state_id":"3953"},
    {"city_id":"47813","city_name":"Robbinsville","state_id":"3953"},
    {"city_id":"47814","city_name":"Middlesex","state_id":"3953"},
    {"city_id":"47815","city_name":"Harrison","state_id":"3953"},
    {"city_id":"47816","city_name":"Metuchen","state_id":"3953"},
    {"city_id":"47817","city_name":"Clinton Township","state_id":"3953"},
    {"city_id":"47818","city_name":"Pennsville","state_id":"3953"},
    {"city_id":"47819","city_name":"Verona","state_id":"3953"},
    {"city_id":"47820","city_name":"Albuquerque","state_id":"3955"},
    {"city_id":"47821","city_name":"Las Cruces","state_id":"3955"},
    {"city_id":"47822","city_name":"Rio Rancho","state_id":"3955"},
    {"city_id":"47823","city_name":"Santa Fe","state_id":"3955"},
    {"city_id":"47824","city_name":"Roswell","state_id":"3955"},
    {"city_id":"47825","city_name":"Farmington","state_id":"3955"},
    {"city_id":"47826","city_name":"South Valley","state_id":"3955"},
    {"city_id":"47827","city_name":"Clovis","state_id":"3955"},
    {"city_id":"47828","city_name":"Hobbs","state_id":"3955"},
    {"city_id":"47829","city_name":"Alamogordo","state_id":"3955"},
    {"city_id":"47830","city_name":"Carlsbad","state_id":"3955"},
    {"city_id":"47831","city_name":"Gallup","state_id":"3955"},
    {"city_id":"47832","city_name":"Deming","state_id":"3955"},
    {"city_id":"47833","city_name":"Los Lunas","state_id":"3955"},
    {"city_id":"47834","city_name":"Chaparral","state_id":"3955"},
    {"city_id":"47835","city_name":"Sunland Park","state_id":"3955"},
    {"city_id":"47836","city_name":"Las Vegas","state_id":"3955"},
    {"city_id":"47837","city_name":"Portales","state_id":"3955"},
    {"city_id":"47838","city_name":"Los Alamos","state_id":"3955"},
    {"city_id":"47839","city_name":"North Valley","state_id":"3955"},
    {"city_id":"47840","city_name":"Artesia","state_id":"3955"},
    {"city_id":"47841","city_name":"Lovington","state_id":"3955"},
    {"city_id":"47842","city_name":"Silver City","state_id":"3955"},
    {"city_id":"47843","city_name":"Espanola","state_id":"3955"},
    {"city_id":"47844","city_name":"Anthony","state_id":"3955"},
    {"city_id":"47845","city_name":"Grants","state_id":"3955"},
    {"city_id":"47846","city_name":"Socorro","state_id":"3955"},
    {"city_id":"47847","city_name":"Corrales","state_id":"3955"},
    {"city_id":"47848","city_name":"Bernalillo","state_id":"3955"},
    {"city_id":"47849","city_name":"Shiprock","state_id":"3955"},
    {"city_id":"47850","city_name":"Bloomfield","state_id":"3955"},
    {"city_id":"47851","city_name":"Ruidoso","state_id":"3955"},
    {"city_id":"47852","city_name":"Kirtland","state_id":"3955"},
    {"city_id":"47853","city_name":"Belen","state_id":"3955"},
    {"city_id":"47854","city_name":"Airmont","state_id":"3956"},
    {"city_id":"47855","city_name":"Albany","state_id":"3956"},
    {"city_id":"47856","city_name":"Alden","state_id":"3956"},
    {"city_id":"47857","city_name":"Amherst","state_id":"3956"},
    {"city_id":"47858","city_name":"Amityville","state_id":"3956"},
    {"city_id":"47859","city_name":"Amsterdam","state_id":"3956"},
    {"city_id":"47860","city_name":"Arcadia","state_id":"3956"},
    {"city_id":"47861","city_name":"Auburn","state_id":"3956"},
    {"city_id":"47862","city_name":"Aurora","state_id":"3956"},
    {"city_id":"47863","city_name":"Babylon","state_id":"3956"},
    {"city_id":"47864","city_name":"Baldwinsville","state_id":"3956"},
    {"city_id":"47865","city_name":"Ballston","state_id":"3956"},
    {"city_id":"47866","city_name":"Batavia","state_id":"3956"},
    {"city_id":"47867","city_name":"Bath","state_id":"3956"},
    {"city_id":"47868","city_name":"Beacon","state_id":"3956"},
    {"city_id":"47869","city_name":"Bedford","state_id":"3956"},
    {"city_id":"47870","city_name":"Beekman","state_id":"3956"},
    {"city_id":"47871","city_name":"Bethlehem","state_id":"3956"},
    {"city_id":"47872","city_name":"Binghamton","state_id":"3956"},
    {"city_id":"47873","city_name":"Blooming Grove","state_id":"3956"},
    {"city_id":"47874","city_name":"Briarcliff Manor","state_id":"3956"},
    {"city_id":"47875","city_name":"Brighton","state_id":"3956"},
    {"city_id":"47876","city_name":"Brockport","state_id":"3956"},
    {"city_id":"47877","city_name":"Brookhaven","state_id":"3956"},
    {"city_id":"47878","city_name":"Brunswick","state_id":"3956"},
    {"city_id":"47879","city_name":"Buffalo","state_id":"3956"},
    {"city_id":"47880","city_name":"Camillus","state_id":"3956"},
    {"city_id":"47881","city_name":"Canandaigua","state_id":"3956"},
    {"city_id":"47882","city_name":"Canton","state_id":"3956"},
    {"city_id":"47883","city_name":"Carmel","state_id":"3956"},
    {"city_id":"47884","city_name":"Catskill","state_id":"3956"},
    {"city_id":"47885","city_name":"Cheektowaga","state_id":"3956"},
    {"city_id":"47886","city_name":"Chenango","state_id":"3956"},
    {"city_id":"47887","city_name":"Chester","state_id":"3956"},
    {"city_id":"47888","city_name":"Chestnut Ridge","state_id":"3956"},
    {"city_id":"47889","city_name":"Chili","state_id":"3956"},
    {"city_id":"47890","city_name":"Cicero","state_id":"3956"},
    {"city_id":"47891","city_name":"Clarence","state_id":"3956"},
    {"city_id":"47892","city_name":"Clarkstown","state_id":"3956"},
    {"city_id":"47893","city_name":"Clay","state_id":"3956"},
    {"city_id":"47894","city_name":"Clifton Park","state_id":"3956"},
    {"city_id":"47895","city_name":"Cohoes","state_id":"3956"},
    {"city_id":"47896","city_name":"Colonie","state_id":"3956"},
    {"city_id":"47897","city_name":"Corning","state_id":"3956"},
    {"city_id":"47898","city_name":"Cornwall","state_id":"3956"},
    {"city_id":"47899","city_name":"Cortland","state_id":"3956"},
    {"city_id":"47900","city_name":"Cortlandt","state_id":"3956"},
    {"city_id":"47901","city_name":"Crawford","state_id":"3956"},
    {"city_id":"47902","city_name":"Croton-on-Hudson","state_id":"3956"},
    {"city_id":"47903","city_name":"Depew","state_id":"3956"},
    {"city_id":"47904","city_name":"DeWitt (De Witt)","state_id":"3956"},
    {"city_id":"47905","city_name":"Dobbs Ferry","state_id":"3956"},
    {"city_id":"47906","city_name":"Dryden","state_id":"3956"},
    {"city_id":"47907","city_name":"Dunkirk","state_id":"3956"},
    {"city_id":"47908","city_name":"East Fishkill","state_id":"3956"},
    {"city_id":"47909","city_name":"East Greenbush","state_id":"3956"},
    {"city_id":"47910","city_name":"East Hampton","state_id":"3956"},
    {"city_id":"47911","city_name":"East Hills","state_id":"3956"},
    {"city_id":"47912","city_name":"East Rockaway","state_id":"3956"},
    {"city_id":"47913","city_name":"Eastchester","state_id":"3956"},
    {"city_id":"47914","city_name":"Elma","state_id":"3956"},
    {"city_id":"47915","city_name":"Elmira","state_id":"3956"},
    {"city_id":"47916","city_name":"Endicott","state_id":"3956"},
    {"city_id":"47917","city_name":"Esopus","state_id":"3956"},
    {"city_id":"47918","city_name":"Evans","state_id":"3956"},
    {"city_id":"47919","city_name":"Fallsburg","state_id":"3956"},
    {"city_id":"47920","city_name":"Farmingdale","state_id":"3956"},
    {"city_id":"47921","city_name":"Farmington","state_id":"3956"},
    {"city_id":"47922","city_name":"Fishkill","state_id":"3956"},
    {"city_id":"47923","city_name":"Floral Park","state_id":"3956"},
    {"city_id":"47924","city_name":"Fredonia","state_id":"3956"},
    {"city_id":"47925","city_name":"Freeport","state_id":"3956"},
    {"city_id":"47926","city_name":"Fulton","state_id":"3956"},
    {"city_id":"47927","city_name":"Garden City","state_id":"3956"},
    {"city_id":"47928","city_name":"Gates","state_id":"3956"},
    {"city_id":"47929","city_name":"Geddes","state_id":"3956"},
    {"city_id":"47930","city_name":"Geneseo","state_id":"3956"},
    {"city_id":"47931","city_name":"Geneva","state_id":"3956"},
    {"city_id":"47932","city_name":"German Flatts","state_id":"3956"},
    {"city_id":"47933","city_name":"Glen Cove","state_id":"3956"},
    {"city_id":"47934","city_name":"Glens Falls","state_id":"3956"},
    {"city_id":"47935","city_name":"Glenville","state_id":"3956"},
    {"city_id":"47936","city_name":"Gloversville","state_id":"3956"},
    {"city_id":"47937","city_name":"Goshen","state_id":"3956"},
    {"city_id":"47938","city_name":"Grand Island","state_id":"3956"},
    {"city_id":"47939","city_name":"Great Neck","state_id":"3956"},
    {"city_id":"47940","city_name":"Greece","state_id":"3956"},
    {"city_id":"47941","city_name":"Greenburgh","state_id":"3956"},
    {"city_id":"47942","city_name":"Guilderland","state_id":"3956"},
    {"city_id":"47943","city_name":"Halfmoon","state_id":"3956"},
    {"city_id":"47944","city_name":"Hamburg","state_id":"3956"},
    {"city_id":"47945","city_name":"Hamlin","state_id":"3956"},
    {"city_id":"47946","city_name":"Harrison","state_id":"3956"},
    {"city_id":"47947","city_name":"Hastings","state_id":"3956"},
    {"city_id":"47948","city_name":"Hastings-on-Hudson","state_id":"3956"},
    {"city_id":"47949","city_name":"Haverstraw","state_id":"3956"},
    {"city_id":"47950","city_name":"Hempstead","state_id":"3956"},
    {"city_id":"47951","city_name":"Henrietta","state_id":"3956"},
    {"city_id":"47952","city_name":"Herkimer","state_id":"3956"},
    {"city_id":"47953","city_name":"Highlands","state_id":"3956"},
    {"city_id":"47954","city_name":"Hornell","state_id":"3956"},
    {"city_id":"47955","city_name":"Horseheads","state_id":"3956"},
    {"city_id":"47956","city_name":"Hudson","state_id":"3956"},
    {"city_id":"47957","city_name":"Hudson Falls","state_id":"3956"},
    {"city_id":"47958","city_name":"Huntington","state_id":"3956"},
    {"city_id":"47959","city_name":"Hyde Park","state_id":"3956"},
    {"city_id":"47960","city_name":"Ilion","state_id":"3956"},
    {"city_id":"47961","city_name":"Irondequoit","state_id":"3956"},
    {"city_id":"47962","city_name":"Islip","state_id":"3956"},
    {"city_id":"47963","city_name":"Ithaca","state_id":"3956"},
    {"city_id":"47964","city_name":"Jamestown","state_id":"3956"},
    {"city_id":"47965","city_name":"Johnson City","state_id":"3956"},
    {"city_id":"47966","city_name":"Johnstown","state_id":"3956"},
    {"city_id":"47967","city_name":"Kenmore","state_id":"3956"},
    {"city_id":"47968","city_name":"Kent","state_id":"3956"},
    {"city_id":"47969","city_name":"Kingsbury","state_id":"3956"},
    {"city_id":"47970","city_name":"Kingston","state_id":"3956"},
    {"city_id":"47971","city_name":"Kirkland","state_id":"3956"},
    {"city_id":"47972","city_name":"Kiryas Joel","state_id":"3956"},
    {"city_id":"47973","city_name":"Lackawanna","state_id":"3956"},
    {"city_id":"47974","city_name":"LaGrange (La Grange)","state_id":"3956"},
    {"city_id":"47975","city_name":"Lake Grove","state_id":"3956"},
    {"city_id":"47976","city_name":"Lancaster","state_id":"3956"},
    {"city_id":"47977","city_name":"Lansing","state_id":"3956"},
    {"city_id":"47978","city_name":"Le Ray","state_id":"3956"},
    {"city_id":"47979","city_name":"Lenox","state_id":"3956"},
    {"city_id":"47980","city_name":"Lewisboro","state_id":"3956"},
    {"city_id":"47981","city_name":"Lewiston","state_id":"3956"},
    {"city_id":"47982","city_name":"Liberty","state_id":"3956"},
    {"city_id":"47983","city_name":"Lindenhurst","state_id":"3956"},
    {"city_id":"47984","city_name":"Little Falls","state_id":"3956"},
    {"city_id":"47985","city_name":"Lloyd","state_id":"3956"},
    {"city_id":"47986","city_name":"Lockport","state_id":"3956"},
    {"city_id":"47987","city_name":"Long Beach","state_id":"3956"},
    {"city_id":"47988","city_name":"Lynbrook","state_id":"3956"},
    {"city_id":"47989","city_name":"Lysander","state_id":"3956"},
    {"city_id":"47990","city_name":"Macedon","state_id":"3956"},
    {"city_id":"47991","city_name":"Malone","state_id":"3956"},
    {"city_id":"47992","city_name":"Malta","state_id":"3956"},
    {"city_id":"47993","city_name":"Malverne","state_id":"3956"},
    {"city_id":"47994","city_name":"Mamakating","state_id":"3956"},
    {"city_id":"47995","city_name":"Mamaroneck","state_id":"3956"},
    {"city_id":"47996","city_name":"Manchester","state_id":"3956"},
    {"city_id":"47997","city_name":"Manlius","state_id":"3956"},
    {"city_id":"47998","city_name":"Massapequa Park","state_id":"3956"},
    {"city_id":"47999","city_name":"Massena","state_id":"3956"},
    {"city_id":"48000","city_name":"Mastic Beach","state_id":"3956"},
    {"city_id":"48001","city_name":"Mechanicville","state_id":"3956"},
    {"city_id":"48002","city_name":"Mendon","state_id":"3956"},
    {"city_id":"48003","city_name":"Middletown","state_id":"3956"},
    {"city_id":"48004","city_name":"Milton","state_id":"3956"},
    {"city_id":"48005","city_name":"Mineola","state_id":"3956"},
    {"city_id":"48006","city_name":"Monroe","state_id":"3956"},
    {"city_id":"48007","city_name":"Montgomery","state_id":"3956"},
    {"city_id":"48008","city_name":"Moreau","state_id":"3956"},
    {"city_id":"48009","city_name":"Mount Kisco","state_id":"3956"},
    {"city_id":"48010","city_name":"Mount Pleasant","state_id":"3956"},
    {"city_id":"48011","city_name":"Mount Vernon","state_id":"3956"},
    {"city_id":"48012","city_name":"New Castle","state_id":"3956"},
    {"city_id":"48013","city_name":"New Hartford","state_id":"3956"},
    {"city_id":"48014","city_name":"New Hyde Park","state_id":"3956"},
    {"city_id":"48015","city_name":"New Paltz","state_id":"3956"},
    {"city_id":"48016","city_name":"New Rochelle","state_id":"3956"},
    {"city_id":"48017","city_name":"New Square","state_id":"3956"},
    {"city_id":"48018","city_name":"New Windsor","state_id":"3956"},
    {"city_id":"48019","city_name":"New York","state_id":"3956"},
    {"city_id":"48020","city_name":"Newark","state_id":"3956"},
    {"city_id":"48021","city_name":"Newburgh","state_id":"3956"},
    {"city_id":"48022","city_name":"Newfane","state_id":"3956"},
    {"city_id":"48023","city_name":"Niagara Falls","state_id":"3956"},
    {"city_id":"48024","city_name":"Niskayuna","state_id":"3956"},
    {"city_id":"48025","city_name":"North Castle","state_id":"3956"},
    {"city_id":"48026","city_name":"North Greenbush","state_id":"3956"},
    {"city_id":"48027","city_name":"North Hempstead","state_id":"3956"},
    {"city_id":"48028","city_name":"North Syracuse","state_id":"3956"},
    {"city_id":"48029","city_name":"North Tonawanda","state_id":"3956"},
    {"city_id":"48030","city_name":"Northport","state_id":"3956"},
    {"city_id":"48031","city_name":"Norwich","state_id":"3956"},
    {"city_id":"48032","city_name":"Nyack","state_id":"3956"},
    {"city_id":"48033","city_name":"Ogden","state_id":"3956"},
    {"city_id":"48034","city_name":"Ogdensburg","state_id":"3956"},
    {"city_id":"48035","city_name":"Olean","state_id":"3956"},
    {"city_id":"48036","city_name":"Oneida","state_id":"3956"},
    {"city_id":"48037","city_name":"Oneonta","state_id":"3956"},
    {"city_id":"48038","city_name":"Onondaga","state_id":"3956"},
    {"city_id":"48039","city_name":"Ontario","state_id":"3956"},
    {"city_id":"48040","city_name":"Orangetown","state_id":"3956"},
    {"city_id":"48041","city_name":"Orchard Park","state_id":"3956"},
    {"city_id":"48042","city_name":"Ossining","state_id":"3956"},
    {"city_id":"48043","city_name":"Oswego","state_id":"3956"},
    {"city_id":"48044","city_name":"Owego","state_id":"3956"},
    {"city_id":"48045","city_name":"Oyster Bay","state_id":"3956"},
    {"city_id":"48046","city_name":"Parma","state_id":"3956"},
    {"city_id":"48047","city_name":"Patchogue","state_id":"3956"},
    {"city_id":"48048","city_name":"Patterson","state_id":"3956"},
    {"city_id":"48049","city_name":"Peekskill","state_id":"3956"},
    {"city_id":"48050","city_name":"Pelham","state_id":"3956"},
    {"city_id":"48051","city_name":"Penfield","state_id":"3956"},
    {"city_id":"48052","city_name":"Perinton","state_id":"3956"},
    {"city_id":"48053","city_name":"Philipstown","state_id":"3956"},
    {"city_id":"48054","city_name":"Pittsford","state_id":"3956"},
    {"city_id":"48055","city_name":"Plattekill","state_id":"3956"},
    {"city_id":"48056","city_name":"Plattsburgh","state_id":"3956"},
    {"city_id":"48057","city_name":"Pleasant Valley","state_id":"3956"},
    {"city_id":"48058","city_name":"Pleasantville","state_id":"3956"},
    {"city_id":"48059","city_name":"Pomfret","state_id":"3956"},
    {"city_id":"48060","city_name":"Port Chester","state_id":"3956"},
    {"city_id":"48061","city_name":"Port Jefferson","state_id":"3956"},
    {"city_id":"48062","city_name":"Port Jervis","state_id":"3956"},
    {"city_id":"48063","city_name":"Potsdam","state_id":"3956"},
    {"city_id":"48064","city_name":"Poughkeepsie","state_id":"3956"},
    {"city_id":"48065","city_name":"Putnam Valley","state_id":"3956"},
    {"city_id":"48066","city_name":"Queensbury","state_id":"3956"},
    {"city_id":"48067","city_name":"Ramapo","state_id":"3956"},
    {"city_id":"48068","city_name":"Red Hook","state_id":"3956"},
    {"city_id":"48069","city_name":"Rensselaer","state_id":"3956"},
    {"city_id":"48070","city_name":"Riverhead","state_id":"3956"},
    {"city_id":"48071","city_name":"Rochester","state_id":"3956"},
    {"city_id":"48072","city_name":"Rockville Centre","state_id":"3956"},
    {"city_id":"48073","city_name":"Rome","state_id":"3956"},
    {"city_id":"48074","city_name":"Rotterdam","state_id":"3956"},
    {"city_id":"48075","city_name":"Rye","state_id":"3956"},
    {"city_id":"48076","city_name":"Rye Brook","state_id":"3956"},
    {"city_id":"48077","city_name":"Salamanca","state_id":"3956"},
    {"city_id":"48078","city_name":"Salina","state_id":"3956"},
    {"city_id":"48079","city_name":"Saratoga Springs","state_id":"3956"},
    {"city_id":"48080","city_name":"Saugerties","state_id":"3956"},
    {"city_id":"48081","city_name":"Scarsdale","state_id":"3956"},
    {"city_id":"48082","city_name":"Schenectady","state_id":"3956"},
    {"city_id":"48083","city_name":"Schodack","state_id":"3956"},
    {"city_id":"48084","city_name":"Scotia","state_id":"3956"},
    {"city_id":"48085","city_name":"Seneca Falls","state_id":"3956"},
    {"city_id":"48086","city_name":"Shawangunk","state_id":"3956"},
    {"city_id":"48087","city_name":"Sherrill","state_id":"3956"},
    {"city_id":"48088","city_name":"Sleepy Hollow","state_id":"3956"},
    {"city_id":"48089","city_name":"Smithtown","state_id":"3956"},
    {"city_id":"48090","city_name":"Somers","state_id":"3956"},
    {"city_id":"48091","city_name":"Southampton","state_id":"3956"},
    {"city_id":"48092","city_name":"Southeast","state_id":"3956"},
    {"city_id":"48093","city_name":"Southold","state_id":"3956"},
    {"city_id":"48094","city_name":"Southport","state_id":"3956"},
    {"city_id":"48095","city_name":"Spring Valley","state_id":"3956"},
    {"city_id":"48096","city_name":"Stony Point","state_id":"3956"},
    {"city_id":"48097","city_name":"Suffern","state_id":"3956"},
    {"city_id":"48098","city_name":"Sullivan","state_id":"3956"},
    {"city_id":"48099","city_name":"Sweden","state_id":"3956"},
    {"city_id":"48100","city_name":"Syracuse","state_id":"3956"},
    {"city_id":"48101","city_name":"Tarrytown","state_id":"3956"},
    {"city_id":"48102","city_name":"Thompson","state_id":"3956"},
    {"city_id":"48103","city_name":"Tonawanda","state_id":"3956"},
    {"city_id":"48104","city_name":"Troy","state_id":"3956"},
    {"city_id":"48105","city_name":"Ulster","state_id":"3956"},
    {"city_id":"48106","city_name":"Union","state_id":"3956"},
    {"city_id":"48107","city_name":"Utica","state_id":"3956"},
    {"city_id":"48108","city_name":"Valley Stream","state_id":"3956"},
    {"city_id":"48109","city_name":"Van Buren","state_id":"3956"},
    {"city_id":"48110","city_name":"Vestal","state_id":"3956"},
    {"city_id":"48111","city_name":"Victor","state_id":"3956"},
    {"city_id":"48112","city_name":"Walden","state_id":"3956"},
    {"city_id":"48113","city_name":"Wallkill","state_id":"3956"},
    {"city_id":"48114","city_name":"Walworth","state_id":"3956"},
    {"city_id":"48115","city_name":"Wappinger","state_id":"3956"},
    {"city_id":"48116","city_name":"Warwick","state_id":"3956"},
    {"city_id":"48117","city_name":"Watertown","state_id":"3956"},
    {"city_id":"48118","city_name":"Watervliet","state_id":"3956"},
    {"city_id":"48119","city_name":"Wawarsing","state_id":"3956"},
    {"city_id":"48120","city_name":"Webster","state_id":"3956"},
    {"city_id":"48121","city_name":"West Haverstraw","state_id":"3956"},
    {"city_id":"48122","city_name":"West Seneca","state_id":"3956"},
    {"city_id":"48123","city_name":"Westbury","state_id":"3956"},
    {"city_id":"48124","city_name":"Wheatfield","state_id":"3956"},
    {"city_id":"48125","city_name":"White Plains","state_id":"3956"},
    {"city_id":"48126","city_name":"Whitestown","state_id":"3956"},
    {"city_id":"48127","city_name":"Williston Park","state_id":"3956"},
    {"city_id":"48128","city_name":"Wilton","state_id":"3956"},
    {"city_id":"48129","city_name":"Woodbury","state_id":"3956"},
    {"city_id":"48130","city_name":"Yonkers","state_id":"3956"},
    {"city_id":"48131","city_name":"Yorktown","state_id":"3956"},
    {"city_id":"48132","city_name":"Charlotte","state_id":"3957"},
    {"city_id":"48133","city_name":"Raleigh","state_id":"3957"},
    {"city_id":"48134","city_name":"Greensboro","state_id":"3957"},
    {"city_id":"48135","city_name":"Durham","state_id":"3957"},
    {"city_id":"48136","city_name":"Winston-Salem","state_id":"3957"},
    {"city_id":"48137","city_name":"Fayetteville","state_id":"3957"},
    {"city_id":"48138","city_name":"Cary","state_id":"3957"},
    {"city_id":"48139","city_name":"Wilmington","state_id":"3957"},
    {"city_id":"48140","city_name":"High Point","state_id":"3957"},
    {"city_id":"48141","city_name":"Greenville","state_id":"3957"},
    {"city_id":"48142","city_name":"Asheville","state_id":"3957"},
    {"city_id":"48143","city_name":"Concord","state_id":"3957"},
    {"city_id":"48144","city_name":"Gastonia","state_id":"3957"},
    {"city_id":"48145","city_name":"Jacksonville","state_id":"3957"},
    {"city_id":"48146","city_name":"Chapel Hill","state_id":"3957"},
    {"city_id":"48147","city_name":"Rocky Mount","state_id":"3957"},
    {"city_id":"48148","city_name":"Burlington","state_id":"3957"},
    {"city_id":"48149","city_name":"Huntersville","state_id":"3957"},
    {"city_id":"48150","city_name":"Wilson","state_id":"3957"},
    {"city_id":"48151","city_name":"Kannapolis","state_id":"3957"},
    {"city_id":"48152","city_name":"Apex","state_id":"3957"},
    {"city_id":"48153","city_name":"Hickory","state_id":"3957"},
    {"city_id":"48154","city_name":"Wake Forest","state_id":"3957"},
    {"city_id":"48155","city_name":"Indian Trail","state_id":"3957"},
    {"city_id":"48156","city_name":"Goldsboro","state_id":"3957"},
    {"city_id":"48157","city_name":"Mooresville","state_id":"3957"},
    {"city_id":"48158","city_name":"Monroe","state_id":"3957"},
    {"city_id":"48159","city_name":"Salisbury","state_id":"3957"},
    {"city_id":"48160","city_name":"New Bern","state_id":"3957"},
    {"city_id":"48161","city_name":"Holly Springs","state_id":"3957"},
    {"city_id":"48162","city_name":"Matthews","state_id":"3957"},
    {"city_id":"48163","city_name":"Sanford","state_id":"3957"},
    {"city_id":"48164","city_name":"Garner","state_id":"3957"},
    {"city_id":"48165","city_name":"Cornelius","state_id":"3957"},
    {"city_id":"48166","city_name":"Thomasville","state_id":"3957"},
    {"city_id":"48167","city_name":"Asheboro","state_id":"3957"},
    {"city_id":"48168","city_name":"Statesville","state_id":"3957"},
    {"city_id":"48169","city_name":"Mint Hill","state_id":"3957"},
    {"city_id":"48170","city_name":"Kernersville","state_id":"3957"},
    {"city_id":"48171","city_name":"Morrisville","state_id":"3957"},
    {"city_id":"48172","city_name":"Fuquay-Varina","state_id":"3957"},
    {"city_id":"48173","city_name":"Lumberton","state_id":"3957"},
    {"city_id":"48174","city_name":"Kinston","state_id":"3957"},
    {"city_id":"48175","city_name":"Carrboro","state_id":"3957"},
    {"city_id":"48176","city_name":"Havelock","state_id":"3957"},
    {"city_id":"48177","city_name":"Shelby","state_id":"3957"},
    {"city_id":"48178","city_name":"Clemmons","state_id":"3957"},
    {"city_id":"48179","city_name":"Lexington","state_id":"3957"},
    {"city_id":"48180","city_name":"Clayton","state_id":"3957"},
    {"city_id":"48181","city_name":"Boone","state_id":"3957"},
    {"city_id":"48182","city_name":"Fargo","state_id":"3958"},
    {"city_id":"48183","city_name":"Bismarck","state_id":"3958"},
    {"city_id":"48184","city_name":"Grand Forks","state_id":"3958"},
    {"city_id":"48185","city_name":"Minot","state_id":"3958"},
    {"city_id":"48186","city_name":"West Fargo","state_id":"3958"},
    {"city_id":"48187","city_name":"Williston","state_id":"3958"},
    {"city_id":"48188","city_name":"Dickinson","state_id":"3958"},
    {"city_id":"48189","city_name":"Mandan","state_id":"3958"},
    {"city_id":"48190","city_name":"Jamestown","state_id":"3958"},
    {"city_id":"48191","city_name":"Wahpeton","state_id":"3958"},
    {"city_id":"48192","city_name":"Devils Lake","state_id":"3958"},
    {"city_id":"48193","city_name":"Valley City","state_id":"3958"},
    {"city_id":"48194","city_name":"Providence","state_id":"3965"},
    {"city_id":"48195","city_name":"Warwick","state_id":"3965"},
    {"city_id":"48196","city_name":"Cranston","state_id":"3965"},
    {"city_id":"48197","city_name":"Pawtucket","state_id":"3965"},
    {"city_id":"48198","city_name":"East Providence","state_id":"3965"},
    {"city_id":"48199","city_name":"Woonsocket","state_id":"3965"},
    {"city_id":"48200","city_name":"Coventry","state_id":"3965"},
    {"city_id":"48201","city_name":"Cumberland","state_id":"3965"},
    {"city_id":"48202","city_name":"North Providence","state_id":"3965"},
    {"city_id":"48203","city_name":"South Kingstown","state_id":"3965"},
    {"city_id":"48204","city_name":"West Warwick","state_id":"3965"},
    {"city_id":"48205","city_name":"Johnston","state_id":"3965"},
    {"city_id":"48206","city_name":"North Kingstown","state_id":"3965"},
    {"city_id":"48207","city_name":"Newport","state_id":"3965"},
    {"city_id":"48208","city_name":"Bristol","state_id":"3965"},
    {"city_id":"48209","city_name":"Westerly","state_id":"3965"},
    {"city_id":"48210","city_name":"Smithfield","state_id":"3965"},
    {"city_id":"48211","city_name":"Lincoln","state_id":"3965"},
    {"city_id":"48212","city_name":"Central Falls","state_id":"3965"},
    {"city_id":"48213","city_name":"Portsmouth","state_id":"3965"},
    {"city_id":"48214","city_name":"Barrington","state_id":"3965"},
    {"city_id":"48215","city_name":"Middletown","state_id":"3965"},
    {"city_id":"48216","city_name":"Burrillville","state_id":"3965"},
    {"city_id":"48217","city_name":"Narragansett","state_id":"3965"},
    {"city_id":"48218","city_name":"Tiverton","state_id":"3965"},
    {"city_id":"48219","city_name":"East Greenwich","state_id":"3965"},
    {"city_id":"48220","city_name":"North Smithfield","state_id":"3965"},
    {"city_id":"48221","city_name":"Warren","state_id":"3965"},
    {"city_id":"48222","city_name":"Scituate","state_id":"3965"},
    {"city_id":"48223","city_name":"Glocester","state_id":"3965"},
    {"city_id":"48224","city_name":"Hopkinton","state_id":"3965"},
    {"city_id":"48225","city_name":"Charlestown","state_id":"3965"},
    {"city_id":"48226","city_name":"Richmond","state_id":"3965"},
    {"city_id":"48227","city_name":"Columbia","state_id":"3966"},
    {"city_id":"48228","city_name":"Charleston","state_id":"3966"},
    {"city_id":"48229","city_name":"North Charleston","state_id":"3966"},
    {"city_id":"48230","city_name":"Mount Pleasant","state_id":"3966"},
    {"city_id":"48231","city_name":"Rock Hill","state_id":"3966"},
    {"city_id":"48232","city_name":"Greenville","state_id":"3966"},
    {"city_id":"48233","city_name":"Summerville","state_id":"3966"},
    {"city_id":"48234","city_name":"Sumter","state_id":"3966"},
    {"city_id":"48235","city_name":"Hilton Head Island","state_id":"3966"},
    {"city_id":"48236","city_name":"Spartanburg","state_id":"3966"},
    {"city_id":"48237","city_name":"Florence","state_id":"3966"},
    {"city_id":"48238","city_name":"Goose Creek","state_id":"3966"},
    {"city_id":"48239","city_name":"Aiken","state_id":"3966"},
    {"city_id":"48240","city_name":"Myrtle Beach","state_id":"3966"},
    {"city_id":"48241","city_name":"Anderson","state_id":"3966"},
    {"city_id":"48242","city_name":"Greer","state_id":"3966"},
    {"city_id":"48243","city_name":"Mauldin","state_id":"3966"},
    {"city_id":"48244","city_name":"Greenwood","state_id":"3966"},
    {"city_id":"48245","city_name":"North Augusta","state_id":"3966"},
    {"city_id":"48246","city_name":"Easley","state_id":"3966"},
    {"city_id":"48247","city_name":"Simpsonville","state_id":"3966"},
    {"city_id":"48248","city_name":"Hanahan","state_id":"3966"},
    {"city_id":"48249","city_name":"Lexington","state_id":"3966"},
    {"city_id":"48250","city_name":"Conway","state_id":"3966"},
    {"city_id":"48251","city_name":"West Columbia","state_id":"3966"},
    {"city_id":"48252","city_name":"North Myrtle Beach","state_id":"3966"},
    {"city_id":"48253","city_name":"Clemson","state_id":"3966"},
    {"city_id":"48254","city_name":"Orangeburg","state_id":"3966"},
    {"city_id":"48255","city_name":"Cayce","state_id":"3966"},
    {"city_id":"48256","city_name":"Bluffton","state_id":"3966"},
    {"city_id":"48257","city_name":"Beaufort","state_id":"3966"},
    {"city_id":"48258","city_name":"Gaffney","state_id":"3966"},
    {"city_id":"48259","city_name":"Irmo","state_id":"3966"},
    {"city_id":"48260","city_name":"Fort Mill","state_id":"3966"},
    {"city_id":"48261","city_name":"Port Royal","state_id":"3966"},
    {"city_id":"48262","city_name":"Forest Acres","state_id":"3966"},
    {"city_id":"48263","city_name":"Newberry","state_id":"3966"},
    {"city_id":"48264","city_name":"Sioux Falls","state_id":"3967"},
    {"city_id":"48265","city_name":"Rapid City","state_id":"3967"},
    {"city_id":"48266","city_name":"Aberdeen","state_id":"3967"},
    {"city_id":"48267","city_name":"Brookings","state_id":"3967"},
    {"city_id":"48268","city_name":"Watertown","state_id":"3967"},
    {"city_id":"48269","city_name":"Mitchell","state_id":"3967"},
    {"city_id":"48270","city_name":"Yankton","state_id":"3967"},
    {"city_id":"48271","city_name":"Pierre","state_id":"3967"},
    {"city_id":"48272","city_name":"Huron","state_id":"3967"},
    {"city_id":"48273","city_name":"Spearfish","state_id":"3967"},
    {"city_id":"48274","city_name":"Vermillion","state_id":"3967"},
    {"city_id":"48275","city_name":"Brandon","state_id":"3967"},
    {"city_id":"48276","city_name":"Box Elder","state_id":"3967"},
    {"city_id":"48277","city_name":"Madison","state_id":"3967"},
    {"city_id":"48278","city_name":"Sturgis","state_id":"3967"},
    {"city_id":"48279","city_name":"Belle Fourche","state_id":"3967"},
    {"city_id":"48280","city_name":"Harrisburg","state_id":"3967"},
    {"city_id":"48281","city_name":"Charleston","state_id":"3976"},
    {"city_id":"48282","city_name":"Huntington","state_id":"3976"},
    {"city_id":"48283","city_name":"Morgantown","state_id":"3976"},
    {"city_id":"48284","city_name":"Parkersburg","state_id":"3976"},
    {"city_id":"48285","city_name":"Wheeling","state_id":"3976"},
    {"city_id":"48286","city_name":"Weirton","state_id":"3976"},
    {"city_id":"48287","city_name":"Fairmont","state_id":"3976"},
    {"city_id":"48288","city_name":"Martinsburg","state_id":"3976"},
    {"city_id":"48289","city_name":"Beckley","state_id":"3976"},
    {"city_id":"48290","city_name":"Clarksburg","state_id":"3976"},
    {"city_id":"48291","city_name":"South Charleston","state_id":"3976"},
    {"city_id":"48292","city_name":"St. Albans","state_id":"3976"},
    {"city_id":"48293","city_name":"Vienna","state_id":"3976"},
    {"city_id":"48294","city_name":"Bluefield","state_id":"3976"},
    {"city_id":"48295","city_name":"Moundsville","state_id":"3976"},
    {"city_id":"48296","city_name":"Bridgeport","state_id":"3976"},
    {"city_id":"48297","city_name":"Oak Hill","state_id":"3976"},
    {"city_id":"48298","city_name":"Dunbar","state_id":"3976"},
    {"city_id":"48299","city_name":"Elkins","state_id":"3976"},
    {"city_id":"48300","city_name":"Nitro","state_id":"3976"},
    {"city_id":"48301","city_name":"Hurricane","state_id":"3976"},
    {"city_id":"48302","city_name":"Princeton","state_id":"3976"},
    {"city_id":"48303","city_name":"Charles Town","state_id":"3976"},
    {"city_id":"48304","city_name":"Buckhannon","state_id":"3976"},
    {"city_id":"48305","city_name":"Keyser","state_id":"3976"},
    {"city_id":"48306","city_name":"New Martinsville","state_id":"3976"},
    {"city_id":"48307","city_name":"Grafton","state_id":"3976"},
    {"city_id":"48308","city_name":"Ranson","state_id":"3976"},
    {"city_id":"48309","city_name":"Point Pleasant","state_id":"3976"},
    {"city_id":"48310","city_name":"Westover","state_id":"3976"},
    {"city_id":"48311","city_name":"Weston","state_id":"3976"},
    {"city_id":"48312","city_name":"Lewisburg","state_id":"3976"},
    {"city_id":"48313","city_name":"Ravenswood","state_id":"3976"},
    {"city_id":"48314","city_name":"Summersville","state_id":"3976"},
    {"city_id":"48315","city_name":"Dar es Salaam","state_id":"3506"}
]


export default cities