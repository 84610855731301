import React, { useState } from "react";
// import { useState } from "react";
// import { Chart as ChartJS } from "chart.js/auto";
import ReactApexChart from "react-apexcharts";
import { Card, Spinner } from "react-bootstrap";

const BarGraph = ({ data }) => {
  //   const [data, setData] = useState(null);
  let options = {
    chart: {
      background: "transparent",
      type: 'bar',
      height: 230,
      foreColor: '#fff',
      zoom: {
        type: 'x',
        enabled: false,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: 'zoom'
      }
    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: false,
        columnWidth: "70%",
        endingShape: "rounded"
      }
    },
    colors: [
      "#B250EF",
      "#238EA6",
      "#E9B34A",
      "#EA8160",
      "#57B155",
      "#2958A9",
    ],
    grid: {
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    markers: {
      size: 0,
    },
    title: {
      text: '',
      align: 'left'
    },
    tooltip: {
      theme: 'dark',
    },
    yaxis: {
      tickAmount: 10,
    },
    xaxis: {
      tickAmount: 8,
      categories: data?.map((data) => data?.year),
      tickPlacement: 'on'
    },
  }
  let series = [{
    name: 'Market Landscape',
    data: data?.map((data) => data?.amount),
  }]
  // let userData = {
  //   labels: data?.map((data) => data?.year),
  //   datasets: [

  //     {
  //       label: "Market Landscape",
  //       data: data?.map((data) => data?.amount),
  //       fill: true,
  //         barPercentage: 0.5,
  //         barThickness: 50,
  //         maxBarThickness: 55,
  //         minBarLength: 2,
  //       // backgroundColor: 'black',
  //       // borderColor: "black",
  //       borderWidth: 2,
  //       backgroundColor: [
  // "#B250EF",
  // "#238EA6",
  // "#E9B34A",
  // "#EA8160",
  // "#57B155",
  // "#2958A9",
  //       ],
  //       borderColor: [
  //         "#B250EF",
  //         "#238EA6",
  //         "#E9B34A",
  //         "#EA8160",
  //         "#57B155",
  //         "#2958A9",
  //       ],
  //     },
  //   ],
  // };

  // const options = {
  //   scales: {
  //     yAxes: [
  //       {
  //         gridLines: {
  //           color: "red"
  //         }
  //       }
  //     ],
  //     xAxes: [
  //       {
  //         gridLines: {
  //           color: "blue"
  //         }
  //       }
  //     ]
  //   }
  // };

  //   useEffect(() => {
  //     videoViewsApi()
  //       .then((res) => setData(res.data.data))
  //       .catch((error) => console.log(error));
  //   }, []);

  return (
    <div>
      <Card
        style={{
          //   borderLeftColor: "rgb(0 169 107)",
          //   borderLeftWidth: "13px",
          padding: "5px",
          width: "100%",
          height: "350px",
        }}
        className="marketLandscapeCard"
      >
        {/* <div className="align-items-center d-flex">
            <div className="chartsIconBg">
                <MdInsertChart color="rgb(0 169 107)" />
            </div>

            <h4 className="chartIconText" style={{color:"black"}}>Market Landscape</h4>
            </div> */}

        {/* <Bar
          // className="marketLandscapeGraph"
        
          style={{ height: "80% !important" }}
          data={userData}
          options={{
            
            responsive: true,
            maintainAspectRatio: false,
            barThickness: 55,
            scales: {
              y: {
                // min: 0,
                ticks: {
                  beginAtZero: true,
                  // precision: 0,
                  stepSize: 1,
                  // callback: function (value) {
                  //   if (value % 1 === 0) {
                  //     return value;
                  //   }
                  // },
                },
              },
            },
          }}
        /> */}
        {data ?
          <ReactApexChart options={options} series={series} type="bar" height={330} />
          :
          <Spinner animation="border" size="lg" variant="primary" />
        }
      </Card>
    </div>
  );
};

export default BarGraph;
