import { apiHandle } from "../../apiHandler";

export const getModuleStatus = () => {
  return apiHandle().get(`/company/auth/module/status`);
};

export const UpdateModuleStatusById = (obj, id) => {
  return apiHandle().put(`/company/auth/module/status/${id}`, obj);
};



export const publishPortFolioApi = () => {
    return apiHandle().post(`/company/auth/portfolio`);
  };