import { Row, Space, Typography } from "antd";
import React from "react";
import BarGraph from "./MarketInProfileBarGraph";
import { CgQuote } from "react-icons/cg";

export default function MarketInProfile({ marketLandScape }) {
  const { Title } = Typography;
  return (
    <div >
      {marketLandScape?.graph && <BarGraph data={marketLandScape?.graph} />}

      <div className="marketLandScapeViewPortfolio">
        {marketLandScape ? (
          <>
            <h3>MARKET LANDSCAPE <span><CgQuote size={140} color="#ddd" /></span></h3>
            <p
              className="textEditorInnerData"
              dangerouslySetInnerHTML={{ __html: marketLandScape?.text }}
            />
          </>
        ) : (
          <h6 className="text-white text-center">Data not Found!</h6>
        )}
      </div>
    </div>
  );
}
