import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useNavigate } from "react-router";
const IntrestedButtoncard = ({ intrested, disabled, onClick, style }) => {
  const navigate = useNavigate()
  let appState = localStorage.getItem("IP_State");

  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip>Press to show Intrest in Company</Tooltip>}
      >
        <Button
          // variant={"outline-danger"}
          onClick={appState === null ? () => { navigate("/login") } : onClick}
          disabled={disabled ? true : false}
          style={{
            border: "none",
            height: "40px",
            width: "40px",
            background: ' #00000069',
            borderRadius: "100%",
            border: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {disabled ? <Spinner size="sm" /> : intrested ? (
            <>
              <span>
                <AiFillHeart color="red" size={24} />
              </span>

            </>
          ) : (
            <>
              <span>
                <AiOutlineHeart color="red" size={24} />
              </span>

            </>
          )}
        </Button>
      </OverlayTrigger>
    </>
  );
};

export default IntrestedButtoncard;
