import React, { useEffect } from "react";
import ExploreInvestment from "../component/homeNew/ExploreInvestment";
import HowItWork from "../component/homeNew/HowItWork";
import InvestorHomeSlider from "../component/homeNew/InvestorHomeSlider";
import Questions from "../component/homeNew/Questions";
import HeroSectionLeadGeneration from "../component/leadGeneration/HeroSetion";
import HowItWorks from "../component/leadGeneration/HowItWorks";
import WhyToSignUpSlider from "../component/leadGeneration/WhyToSignSilder";
import WhyToSignUp from "../component/leadGeneration/WhyToSignUs";

export default function LeadGeneration() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div style={{ background: "black" }}>
      <HeroSectionLeadGeneration />
      <div className="container">
        <WhyToSignUp />
        <WhyToSignUpSlider />
        <HowItWorks />
      </div>

      <Questions />
    </div>
  );
}
