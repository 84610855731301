import React, { useState } from "react";
import { Button } from "react-bootstrap";

// import Button from '@mui/material/Button';

import { Card } from "antd";
import { AiFillHeart } from "react-icons/ai";
import { displayWorrningToast } from "../../helper/toast_notification_function";
import { handleCompanyFollow } from "../../redux/actions/companyIntrectionActions";
import FollowButton from "../Common/FollowButton";

const { Meta } = Card;

export default function CompanyCard({
  id,
  img,
  mark,
  CompanyName,
  text,
  followed,
  intrested,
  isLevelTwo,
  onClick,
}) {
  const isUserLevelTwo = JSON.parse(
    localStorage.getItem("IP_levelTwoProfileStatus")
  );
  const [follow, setFollow] = useState(followed);
  const [isProcessing, setIsProcessing] = useState(false);


  function isEnableCard() {
    if (isUserLevelTwo === "valid" && isLevelTwo) {
      return true;
    }
    if (isUserLevelTwo !== "valid" && !isLevelTwo) {
      return true;
    }

    if (isUserLevelTwo == "valid" && !isLevelTwo) {
      return true;
    }

    return false;
  }
  const styles = {
    // borderRight: '1px solid lightgray',
    display: "flex",
    alignItems: "center",
    gap: " 0.7rem",
    color: "white",
  };

  return (
    <>
      {/* <Card>
        <CardMedia
          component="img"
          // height="160"
          image={img}
          sx={{ objectFit: "contain !important" }}
          alt="green iguana"
          onClick={() => {
            if (isEnableCard()) {
              onClick();
            } else {
              displayWorrningToast("Upgrade your User Level to View Details");
            }
          }}
          className={isEnableCard() ? "" : "cardimgBlur"}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            className="cardText"
            component="div"
            onClick={() => {
              if (isEnableCard()) {
                onClick();
              } else {
                displayWorrningToast("Upgrade your User Level to View Details");
              }
            }}
          >
            {CompanyName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {text}
          </Typography>
        </CardContent>
        <CardActions>
          <div style={{ display: "flex", margin: "0 auto", gap: "1rem" }}>
            {intrested && (
              <div>
                <Button variant="outline-danger" disabled={true}>
                  <span>
                    <AiFillHeart color="red" />
                  </span>
                </Button>
              </div>
            )}

            {
              <div>
                {isEnableCard() ? (
                  <FollowButton
                    follow={follow}
                    disabled={isProcessing}
                    onClick={() =>
                      handleCompanyFollow(id, setFollow, setIsProcessing)
                    }
                  />
                ) : (
                  <FollowButton
                    follow={follow}
                    disabled={true}
                    onClick={() =>
                      handleCompanyFollow(id, setFollow, setIsProcessing)
                    }
                  />
                )}
              </div>
            }
          </div>
        </CardActions>
      </Card> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "1rem",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
          height: "385px",
          width: "300px",
          paddingBottom: "5px",
          background: "#171717",
        }}
      >
        <img
          src={img}
          alt="media"
          style={{
            width: "94%",
            height: "195px",
            objectFit: "fill",
            imageRendering: "auto",
            padding: "0.5rem",
            margin: "10px 10px 0px 10px",

            borderRadius: "15px",
            background: "white",
          }}
          className={isEnableCard() ? "" : "cardimgBlur"}
          onClick={() => {
            if (isEnableCard()) {
              onClick();
            } else {
              displayWorrningToast("Upgrade your User Level to View Details");
            }
          }}
        />
        <div style={{ padding: "1rem" }}>
          <h3
            style={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              fontFamily: 'sansation',
              margin: 0,
              marginBottom: "0.3rem",
              textAlign: "center",
              color: "wheat",
            }}
            className="company-card-title"
            onClick={() => {
              if (isEnableCard()) {
                onClick();
              } else {
                displayWorrningToast("Upgrade your User Level to View Details");
              }
            }}
          >
            {CompanyName}
          </h3>
          <p
            style={{
              fontSize: "1rem",
              fontFamily: 'sansation',
              margin: 0,
              marginBottom: "0.5rem",
              textAlign: "center",
              color: "#29A5FF",
            }}
            className="company-card-desc"
          >
            {text}
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              paddingTop: "0.5rem",
            }}
          >
            {intrested && (
              <div>
                <Button variant="outline-danger" disabled={true}>
                  <span>
                    <AiFillHeart color="red" />
                  </span>
                </Button>
              </div>
            )}
            {
              <div>
                {isEnableCard() ? (
                  <FollowButton
                    follow={follow}
                    disabled={isProcessing}
                    onClick={() =>
                      handleCompanyFollow(id, setFollow, setIsProcessing)
                    }
                  />
                ) : (
                  <FollowButton
                    follow={follow}
                    disabled={true}
                    onClick={() =>
                      handleCompanyFollow(id, setFollow, setIsProcessing)
                    }
                  />
                )}
              </div>
            }
          </div>
        </div>
      </div>


    </>
  );
}
