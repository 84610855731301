import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { AiOutlinePlus, AiOutlineCheck } from "react-icons/ai";

const FollowButton = ({ follow, disabled, onClick }) => {
  return (
    <>
      <Button
        // variant={!follow ? "outline-primary" : "outline-secondary"}
        onClick={onClick}
        disabled={disabled ? true : false}
        style={{
          borderColor: "#29A5FF",
          color: "white",
          background: ' linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5)'
        }}
      >
        {disabled ? <Spinner size="sm"></Spinner> : !follow ? (
          <>
            <span>
              Follow  <AiOutlinePlus color="white" />
            </span>
          </>
        ) : (
          <>
            <span>
              Following <AiOutlineCheck color="white" />
            </span>
          </>
        )}
      </Button>
    </>
  );
};

export default FollowButton;
