import { Col } from "antd";
import React from "react";
import HomeCommonCard from "../Common/HomeCommonCard";

import img from "../../images/howWorks.png";

import { Row } from "react-bootstrap";
import { FiArrowRight } from "react-icons/fi";
import useWindowDimensions from "../WindowDimensions";
import { useNavigate } from "react-router-dom";
import { PiChartLineUp } from "react-icons/pi";
import { BsFillBuildingsFill } from "react-icons/bs";
import { GiStabbedNote } from "react-icons/gi";



export default function HowItWork() {
  const navigate = useNavigate();

  const { height, width } = useWindowDimensions;

  const data = [
    {
      icon: <GiStabbedNote  size={100}   color="#007CE9"    />,
      title: "Access The Startup Asset Class",
      desc: "We lobbied to change the rules so everyday people outside the venture bubble could finally invest in private startups, a historically high performing asset class.",
    },
    {
      icon: <PiChartLineUp  size={100}   color="#007CE9"  />,
      title: "Invest In Vetted Companies",
      desc: "Only a small selection of companies make it through our due diligence process. We’re not an open marketplace where anyone can just click and raise",
    },
    {
      icon: <BsFillBuildingsFill size={100} color="#007CE9"  />,
      title: "Diversity Like A VC Companies",
      desc: "We offer lower minimums than traditional angel investments so you can back more companies. When you diversify, you increase your odds of positive returns.",
    },
  ];

  return (
    <div className="how-works container-fluid py-5 sectionSpacing">

      <div>
        <h2 className="commonH2">How It Works</h2>
        <p className="text-white text-center">
          With our simple sign-up process, you can choose to access
          investment sectors that best interest you. Oil & Gas, Precious
          Metals & Mining, Real Estate, and other Alternative Markets.
        </p>
      </div>

      <div className="row justify-content-center">
        {data.map((val, ind) => {
          console.log(val)
          return (
            <div className="col-lg-4 col-md-6 col-sm-12  mt-3 ">
              <div className="how-it-work-card">
              {val.icon}
                <h3>{val.title}</h3>
              </div>
            </div>
          );
        })}
      </div>

      <div className="mt-5 ">
        <div className="RaiseRound" >
          <h3>Looking To Raise A Round For Your Company?</h3>

          <button className="banner-btn" onClick={() => navigate('/signup', { state: { data: "company" } })}>

            See more <FiArrowRight className="rightArrow" />
          </button>
        </div>
      </div>
    </div>
  );
}
