import { PaymentElement } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, Modal } from "react-bootstrap";
import { Avatar, Popconfirm } from "antd";
import logo from "../../images/IpLogo.png"
import { MdOutlineCancel } from "react-icons/md";
import { getTransactionLogsHandle } from "../../redux/actions/creditCardActions";
import { cancelBooking } from "../../api/companyApis/transactionLogsApi";
import { displaySuccessToast } from "../../helper/toast_notification_function";
import { useDispatch } from "react-redux";

export default function CheckoutForm({ bookingId, show, handleClose, amount, modalvaluestate }) {
    const stripe = useStripe();
    const elements = useElements();
    // const dispatch = useDispatch()

    const [message, setMessage] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [refresh, setrefresh] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsProcessing(true);

        stripe.confirmPayment({
            elements,
            redirect: "if_required"
        }).then((res) => { if (res?.paymentIntent?.status) { handleClose(); modalvaluestate(0); displaySuccessToast(res.paymentIntent.status); setrefresh(true) } }).catch((error) => { console.log(error, "caclecatch") });

        // if (error.type === "card_error" || error.type === "validation_error") {
        //     setMessage(error.message);
        // } else {
        //     setMessage("An unexpected error occured.");
        // }

        setIsProcessing(false);
    };


    const confirmAction = () => {
        // const response = confirm("Are you sure you want to do that?");

        let text;
        if (window.confirm("Are you sure you want to do that?") == true) {
            text = "You pressed OK!";

            handleClose()
            cancelBooking(bookingId).then((res) => {
                displaySuccessToast(res?.data?.message[0])
                //   getTransactionLogsHandle(dispatch);

            })
            setrefresh(true)
        } else {
            text = "You canceled!";
        }

    }

    useEffect(() => {
        if (refresh) {
            setInterval(() => {
                window.location.reload()
            }, 2000);
        }
    }, [refresh])

    return (
        <Modal show={show} onHide={handleClose} backdrop="static" centered closeButton={false}>
            <div className="d-flex justify-content-center align-items-center ">

                <Avatar src={logo}
                    className="StripeModalAvatar"
                    style={{ verticalAlign: "middle", position: "absolute", background: 'black', border: "3px solid white", boxShadow: "1px 1px 10px 5px #00000075" }}
                    size={{
                        xs: 55,
                        sm: 65,
                        md: 65,
                        lg: 80,
                        xl: 80,
                        xxl: 95,
                    }} />
            </div>
            <Modal.Header style={{ background: "lightGray" }} closeButton={false}>
                <Modal.Title>Payment</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ background: "#d3d3d370" }}>
                <h4 className="text-center mb-3">Pay {amount}</h4>
                <form id="payment-form" onSubmit={handleSubmit}>
                    <PaymentElement id="payment-element" />

                    {/* Show any error or success messages */}
                    {message && <div id="payment-message">{message}</div>}
                    <div className="payButton mt-3 d-flex">
                        <button disabled={isProcessing || !stripe || !elements} id="submit" style={{ marginLeft: "auto" }} className=" py-2 px-3">
                            <span id="button-text">
                                {isProcessing ? "Processing ... " : "Pay now"}
                            </span>
                        </button>





                    </div>

                </form>
                <button className="btn btn-secondary cancelBtn" onClick={confirmAction}>
                    Cancel

                </button>

            </Modal.Body>
        </Modal>
    );
}