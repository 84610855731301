import {
  ERROR_COMPANY_HIGHLIGHTS,
  SAVE_COMPANY_HIGHLIGHTS,
  UPDATE_COMPANY_HIGHLIGHTS,
} from "../../constants/highlights_constants";

const INITIAL_STATE = {
  company: {
    highlights: null,
    error: null,
  },
  fundraise: {
    highlights: null,
    error: null,
  },
};

export const higlightsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SAVE_COMPANY_HIGHLIGHTS:
      return {
        ...state,
        company: {
          highlights: payload,
          error: null,
        },
      };
    case UPDATE_COMPANY_HIGHLIGHTS:
      return {
        ...state,
        company: {
          highlights: payload,
          error: null,
        },
      };
    case ERROR_COMPANY_HIGHLIGHTS:
      return {
        ...state,
        company: {
          highlights: null,
          error: payload,
        },
      };

    default:
      return state;
  }
};
