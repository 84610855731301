import { apiHandle } from "../../apiHandler";

export const getFilingApi = () => {
  return apiHandle().get(`/company/auth/filing`);
};

export const updateFilingApi = (obj, id) => {
  return apiHandle().put(`/company/auth/filing/${id}`, obj);
};



export const filingPostApi = (data) => {

    return apiHandle(true, true).post(`/company/auth/filing`,data);
  };