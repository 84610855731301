import React from 'react'

const MediaMentions = ({ media_mentions }) => {
    return (
        <div className="media-imgs-cont">
            {media_mentions?.length > 0 ? (
                media_mentions?.map((val, ind) => {
                    return <img className="media-logo" src={val?.logo} key={ind} />;
                })
            ) : (
                <h3 className="text-center text-white mb-0">No Media Mentions Found</h3>
            )}
        </div>
    )
}

export default MediaMentions