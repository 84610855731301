import { Tabs } from "antd";
import React from "react";

const CompanyTabs = ({ items ,onChange}) => {
  return (
    <>
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </>
  );
};

export default CompanyTabs;
