import { Col, Row, Space, Typography } from "antd";
import React, { useEffect } from "react";
import RoundProgress from "../../../component/charts/RoundProgress";
import { generateColorArray } from "../../../helper/RandomColor";


export default function UseProceedInProfile({ proceed }) {
  // const y1 = proceed.find((newData) => newData.y1 && newData.legend);

  const { Title } = Typography;


  let colors;
  colors = generateColorArray(10);
  useEffect(() => {
  }, [])

  return (
    <div className="row">

      {proceed?.length > 0 ? (
        <>
          <div className="col-lg-5 col-md-12 mt-4">
            <div className="row  justify-content-center "  >
              <h4 className="col-12 mt-3 text-center">
                If minimum investment is received
              </h4>
              {proceed.map((val, ind) => {
                return (
                  <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-3">
                    <RoundProgress
                      value={val.y1}
                      color={colors[ind]}
                      trailColor={"#0b243a"}
                      legend={val?.legend}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-lg-2 col-md-0"></div>

          <div className="col-lg-5 col-md-12 mt-4">
            <div className="row justify-content-center">
              <h4 className="col-12 mt-3 text-center">
                If Maximum investment is received
              </h4>
              {proceed.map((val, ind) => {
                return (
                  <div className="col-lg-4 col-md-4 col-sm-6 col-6 mt-3">
                    <RoundProgress
                      value={val.y2}
                      color={colors[ind - 1]}
                      trailColor={"#0b243a"}
                      legend={val?.legend}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </>
      ) : (
        <h5 className="text-white text-center"> data not found!</h5>
      )}
    </div>
  );
}




