import { apiHandle } from "../../apiHandler";

export const getOverViewApi = () => {
  return apiHandle().get(`/company/auth/overview`);
};

export const getOverViewImageApi = (image) => {
  return apiHandle(true ,true).post(`/company/auth/overview/uploadImage`, image);
};

export const updateFilingApi = (obj, id) => {
  return apiHandle().put(`/company/auth/filing/${id}`, obj);
};

export const overViewApi = (data) => {
  return apiHandle().post(`/company/auth/overview`, data);
};





//   company overview gallery api

export const overViewGalleryApi = (data) => {
  return apiHandle(true, true).post(`/company/auth/overview/gallery`, data);
};

export const getOverViewGalleryApi = (data) => {
  return apiHandle().get(`/company/auth/overview/`);
};

export const DeleteOverViewGalleryApi = (id) => {
  return apiHandle().delete(`/company/auth/overview/removeImage/${id}`);
};




