import { Button } from "antd";
import React from "react";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import AddMember from "./AddMember";
import Table from "./Table";

const Team = () => {
  const [visible, setVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleToggle = () => {
    setVisible((prev) => !prev);
  };

  return (
    <div className="bg_black">
      <div
        style={{
          width: "100%",
          display: "inline-flex",
          justifyContent: "space-between",
          marginBottom: "17px",
        }}
      >
        <div className="doc_slide">
          <h3>Team Members</h3>
        </div>
        <div>
          <button className="addbtnt" onClick={() => setVisible(true)}>
            <AiOutlinePlus color="#fff" size={20} />
          </button>
        </div>
      </div>
      <AddMember
        isOpen={visible}
        handleToggle={handleToggle}
        toggleState={() => setLoading((prev) => !prev)}
      />
      <Table flag={isLoading} />
    </div>
  );
};

export default Team;
