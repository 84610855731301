import React from 'react';
import { useLocation } from 'react-router-dom';

const DateTimeDisplay = ({ value, type, isDanger }) => {
  const location = useLocation();

  return (
    <div>
      <div className={isDanger ? 'countdown danger' : 'countdown'}>
        <p>{value}</p>
      </div>
      <p style={{color: "#fff", textTransform: "uppercase", textAlign: "center", fontSize: "14px"}}>{type}</p>
    </div>
  );
};

export default DateTimeDisplay;
