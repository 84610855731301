import React, { useEffect, useState } from "react";

import { Spinner } from "react-bootstrap";
import {
  getFundraiseHighlightsApi,
  updateFundraiseHighlightsApi,
} from "../../../../api/companyApis/portfolio/highlight/fundraisehighlighlit";
import {
  displayErrorToast,
  displaySuccessToast,
  displayWorrningToast,
} from "../../../../helper/toast_notification_function";

const FundraiseHightlight = () => {
  const [formData, setformData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [save, setSave] = useState(false);
  const [loading, setLoading] = useState(false);


  function handleChange(evt) {
    const value = evt.target.value;

    setSave(true);

    if (evt.target.name == "total_amount" || evt.target.name == "valuation" || evt.target.name == "minimum_investment") {
      setformData({
        ...formData,
        [evt.target.name]: value.replace(/,/g, ''),
      });
    }
    else {
      setformData({
        ...formData,
        [evt.target.name]: value,
      });
    }
  }

  const handleKeyPress = (e) => {
    // Check if the pressed key is a number or a dot
    const isNumericOrDot = /^[0-9.]*$/.test(e.key);
    // If the pressed key is not a number or a dot, prevent default action (typing)
    if (!isNumericOrDot) {
      e.preventDefault();
    }
  };

  const Submit = (e) => {
    e.preventDefault()
    if (!formData) {
      displayWorrningToast("insert Highlight  ");
    } else {
      setLoading(true)
      updateFundraiseHighlightsApi(formData)
        .then((re) => {
          setSave(false);
          setLoading(false)

          displaySuccessToast("highlight submited successfully");
        })
        .catch((error) => {
          displayErrorToast(error)
          setLoading(false)

        });
    }
  };

  useEffect(() => {
    getFundraiseHighlightsApi()
      .then((res) => {
        setformData(res.data.data[0]);
        // console.warn(res.data.data)
      })
      .catch((error) => console.log(error));
  }, [visible]);

  return (
    <div style={{ marginTop: "10px" }}>
      <div style={{ width: "100%" }}>
        <div
          style={{
            width: "100%",
            display: "inline-flex",
            justifyContent: "space-between",
            marginBottom: "17px",
          }}
        >
          <div className="doc_slide">
            <h3>Fundraise Hightlights</h3>
          </div>
          <div style={{ display: "flex", gap: "5px" }}>
            {/* <button className="addbtnt" style={{ width: "70px" }} onClick={Submit} disabled={!save}>{loading ?   <Spinner animation="border" size="sm"  /> : "Save"}</button> */}
          </div>
        </div>
      </div>
      <form action="update" id="edit-fundraise-form">
        <input className="form-control" type="hidden" name="total_amount" />
        <div className="form-group">
          <label for="total_amount"><p style={{ color: "#fff", marginBottom: "4px", marginTop: "10px", textTransform: "capitalize" }}>total amount</p></label>
          <div className="market_input_div">
            <input
              className="form-control"
              value={formData?.total_amount ? Intl.NumberFormat("en-US").format(formData?.total_amount) : null}
              type="text"
              name="total_amount"
              onChange={handleChange}
              onKeyUp={handleKeyPress}
              maxLength={16}

            />
            <span className="dollar-sign">$</span>
          </div>
        </div>
        <div className="form-group">
          <label for="last_name"><p style={{ color: "#fff", marginBottom: "4px", marginTop: "10px", textTransform: "capitalize" }}>minimum investment</p></label>
          <div className="market_input_div">
            <input
              className="form-control"
              value={formData?.minimum_investment ? Intl.NumberFormat("en-US").format(formData?.minimum_investment) : null}
              type="text"
              name="minimum_investment"
              onChange={handleChange}
              onKeyUp={handleKeyPress}
              maxLength={16}

            />
            <span className="dollar-sign">$</span>
          </div>
        </div>
        <div className="form-group">
          <label for="contact_number"><p style={{ color: "#fff", marginBottom: "4px", marginTop: "10px", textTransform: "capitalize" }}>security type</p></label>
          <input
            className="form-control"
            defaultValue={formData?.security_type}
            type="text"
            name="security_type"
            onChange={handleChange}
            maxLength={16}

          />
        </div>

        <div className="form-group">
          <label for="email"><p style={{ color: "#fff", marginBottom: "4px", marginTop: "10px", textTransform: "capitalize" }}>valuation</p></label>
          <div className="market_input_div">
            <input
              className="form-control"
              value={formData?.valuation ? Intl.NumberFormat("en-US").format(formData?.valuation) : null}
              type="text"
              name="valuation"
              onChange={handleChange}
              onKeyUp={handleKeyPress}

              maxLength={16}
            />
            <span className="dollar-sign">$</span>
          </div>
        </div>

        <div className="form-group">
          <label for="address"><p style={{ color: "#fff", marginBottom: "4px", marginTop: "10px", textTransform: "capitalize" }}>offering type</p></label>
          <input
            className="form-control"
            defaultValue={formData?.offering_type}
            type="text"
            name="offering_type"
            onChange={handleChange}

            maxLength={16}
          />
        </div>
        <button className="addbtnt" style={{ width: "70px", margin: "10px 0", float: "right" }} onClick={Submit} disabled={!save}>{loading ? <Spinner animation="border" size="sm" /> : "Save"}</button>


      </form>
    </div>
  );
};

export default FundraiseHightlight;
