import { Switch } from "antd";
import React, { useState, useEffect } from "react";
import Payments from "./Payments";
import { Featured } from "./Featured";
import { DownloadLeads } from "./DownloadLeads";
import { Button, FormGroup, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import { DateRange } from "../../../component/payments/DateRange";
import { Grid } from "@mui/material";
import { getBookingTypes, postBookingsApi, promoCodeApi } from "../../../api/companyApis/transactionLogsApi";
import { displayErrorToast, displaySuccessToast, displayWorrningToast } from "../../../helper/toast_notification_function";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../../../component/payments/CheckoutForm";

export default function CompanyLiveDataTabs() {
  // const [minimumdays, setminimumdays] = useState(null);
  const { paymentPerDay } = useSelector(
    (state) => state.getPaymentPerDayReducer
  );

  const [mainCheckBox, setMainCheckBox] = useState(false);

  const [dateRange, setDateRange] = useState([]);
  const [initialRange, setinitialRange] = useState([]);
  const [promoRes, setPromoRes] = useState([]);

  const [daysBetween, setDaysBetween] = useState();
  const [finalAmount, setFinalAmount] = useState();
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const [isDisable, setIsDisable] = useState(true);
  const [promoBtnLoader, setPromoBtnLoader] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [toggle, settoggle] = useState(false)
  const [scheduledata, setscheduledata] = useState({})
  const [DatatoSend, setDatatoSend] = useState({})
  const [Types, setTypes] = useState([])
  const [send, setSend] = useState(false)
  const [PromoCodeValue, setPromoCodeValue] = useState("")
  const [Valuewithoutdisc, setValuewithoutdisc] = useState(0)
  const [modalpayvalue, setModalPayValue] = useState(0)
  const [show, setShow] = useState(false);
  const [bookingId, setBookingId] = useState()
  const handleClose = () => {
    setValuewithoutdisc()
    setShow(false)
  };
  const handleShow = () => {
    setShow(true)
  };


  function setdata(nam, value, totalname, total) {
    setscheduledata({ ...scheduledata, [nam]: value, [totalname]: total })
  }

  function handlemaincheck() {
    if (mainCheckBox) {
      setscheduledata({
        ...scheduledata,
        payment: 0,
        featured: 0,
        download_lead: 0
      })
    }
    else {
      setscheduledata({
        ...scheduledata,
        payment: parseInt(Types[0].id),
        featured: parseInt(Types[1].id),
        download_lead: parseInt(Types[2].id)
      })
    }
  }

  useEffect(() => {
    setDatatoSend({ ...DatatoSend, dates: { start_date: dateRange?.[0], end_date: dateRange?.[1] } })
  }, [dateRange])

  useEffect(() => {
    if (scheduledata?.payment > 0 && scheduledata?.featured > 0 && scheduledata?.download_lead > 0) {
      setMainCheckBox(true)
    }
    else {
      setMainCheckBox(false)
    }
    if (toggle) {
      setDatatoSend({
        booking_types: [
          scheduledata?.payment > 0 && scheduledata?.payment,
          scheduledata?.featured > 0 && scheduledata?.featured,
          scheduledata?.download_lead > 0 && scheduledata?.download_lead,
        ],
        dates: [
          {
            start_date: dateRange?.[0],
            end_date: dateRange?.[1]
          }

        ],
        use_same_date: true,
        save_card: true,
        promo_code: PromoCodeValue ? PromoCodeValue : ""
      })
    }
    else {
      setDatatoSend({
        booking_types: [
          scheduledata?.payment > 0 ? scheduledata?.payment : "",
          scheduledata?.featured > 0 ? scheduledata?.featured : "",
          scheduledata?.download_lead > 0 ? scheduledata?.download_lead : "",
        ],
        dates: [
          (scheduledata?.payment_date?.length > 0 ? {
            start_date: scheduledata?.payment_date[0],
            end_date: scheduledata?.payment_date[1]
          } : false),
          (scheduledata?.featured_date?.length > 0 ? {
            start_date: scheduledata?.featured_date[0],
            end_date: scheduledata?.featured_date[1]
          } : false),
          (scheduledata?.download_leads_date?.length > 0 ? {
            start_date: scheduledata?.download_leads_date[0],
            end_date: scheduledata?.download_leads_date[1]
          } : false)

        ],
        use_same_date: false,
        save_card: true,
        promo_code: PromoCodeValue ? PromoCodeValue : ""
      })
    }
    if (toggle) {
      const paytotal = (scheduledata?.payment > 0 ? daysBetween * Types?.[0].charges_per_day : 0) + (scheduledata?.featured > 0 ? daysBetween * Types?.[1].charges_per_day : 0) + (scheduledata?.download_lead > 0 ? daysBetween * Types?.[2].charges_per_day : 0)
      setValuewithoutdisc(paytotal)
      setModalPayValue(paytotal)
    }
    else if (scheduledata?.total_paymnt || scheduledata?.total_feat || scheduledata?.total_download_leads) {
      const paytotal = ((scheduledata?.payment_date?.length > 0 && scheduledata?.payment > 0) ? scheduledata?.total_paymnt : 0) + ((scheduledata?.featured_date?.length > 0 && scheduledata?.featured > 0) ? scheduledata?.total_feat : 0) + ((scheduledata?.download_leads_date?.length > 0 && scheduledata?.download_lead > 0) ? scheduledata?.total_download_leads : 0)
      setValuewithoutdisc(paytotal)
      setModalPayValue(paytotal)
      console.log("if")
    }
    else {
      console.log("else")
      setValuewithoutdisc(0)
    }
  }, [scheduledata, toggle])

  console.log(Valuewithoutdisc, "outside", modalpayvalue)

  useEffect(() => {
    setPromoRes([])
    setPromoCodeValue("")
    if (toggle) {
      if (initialRange) {
        setDateRange(initialRange)
        setDatatoSend({ ...DatatoSend, dates: { start_date: initialRange?.[0], end_date: initialRange?.[1] } })
      }
    }
    else {
      setDateRange([])
    }
    setDatatoSend({ ...DatatoSend, use_same_date: toggle })
  }, [toggle])


  // STRIPE

  const [stripePromise, setStripePromise] = useState(loadStripe("pk_test_51O0UOwKOIhqLs8SjhVyKyi8j5EMtmWSCF0apnIKv14O9ITHUyrHpyfCTEf2uHRGcaxsjpzeREMQ1S7YVztpvNy9U00G89xgS9o"));
  const [clientSecret, setClientSecret] = useState("");

  const pay = () => {
    // let startDate = dateRange[0];
    // let endDate = dateRange[1];
    // if (startDate && endDate) {
    //   let obj = {
    //     start_date: startDate,
    //     end_date: endDate,
    //   };
    //   if (daysBetween >= minimumdays) {
    //     setLoading(true);
    //     setIsDisable(true);
    //     cardActiveSheduleHandle(
    //       obj,
    //       setDateRange,
    //       setLoading,
    //       setFinalAmount,
    //       setIsDisable
    //     );
    //   } else {
    //     displayWorrningToast(`Minimum days for make payment is ${minimumdays}`);
    //   }
    // }
    setBtnLoading(true)
    if (DatatoSend.dates.length > 0 && DatatoSend.booking_types.length > 0) {
      let snd = DatatoSend.booking_types.filter((item) => { return item != false })
      let date = DatatoSend.dates.filter((item) => { return item != false })
      setDatatoSend({ ...DatatoSend, booking_types: snd, dates: date })

      if (DatatoSend.use_same_date) {
        if (DatatoSend.booking_types.length > 0 && DatatoSend.dates.length > 0) {
          setSend(true)

        }
        else {
          displayErrorToast("Please Select Date or Check..")
          setBtnLoading(false)
        }
      }
      else {
        if (DatatoSend?.booking_types.length == DatatoSend?.dates.length) {
          setSend(true)
        }
        else {
          displayErrorToast("Please Select Date or Check..")
          setBtnLoading(false)
        }
      }
    }
    else {
      displayWorrningToast("Please Select Date")
      setBtnLoading(false)
    }
  };

  useEffect(() => {
    getBookingTypes()
      .then((res) => {
        setTypes(res?.data?.data)
      })
      .catch((error) => console.log(error.response));

  }, [])



  useEffect(() => {

    if (send) {
      postBookingsApi(DatatoSend).then((res) => {
        setClientSecret(res?.data?.data[0]?.client_secret)
        handleShow()
        if (res?.data?.success) {
          setLoading(true)
          // displaySuccessToast("success")
          setSend(false)
          setDatatoSend({})
          settoggle(false)
          setDateRange([])
          setMainCheckBox(false)
          setLoading(false)
          setscheduledata({})
          setPromoRes([])
          setBtnLoading(false)
          setBookingId(res?.data?.data?.[0].order_id)
        }

      }).catch((err) => {
        displayErrorToast(err)
        setSend(false)
        setBtnLoading(false)

      })


    }

  }, [send])




  const handlePromoCode = () => {

    if (PromoCodeValue) {
      setPromoBtnLoader(true)
      promoCodeApi(DatatoSend).then((res) => {
        if (res?.data?.success) {
          setPromoRes(res?.data?.data?.[0]?.order)
          setPromoBtnLoader(false)

        }
      }).catch((err) => {
        setPromoBtnLoader(false)
        displayErrorToast(err)
      })
    }
  }

  const handlePromoOnChange = (e) => {
    if (DatatoSend.dates?.length > 0 && DatatoSend.booking_types?.length > 0) {
      let snd = DatatoSend.booking_types.filter((item) => { return item != false })
      let date = DatatoSend.dates.filter((item) => { return item != false })
      setDatatoSend({ ...DatatoSend, booking_types: snd, dates: date, promo_code: e.target.value })
    }
    // setDatatoSend({...DatatoSend , promo_code : e.target.value})

  }


  return (
    <>
      <div className="topSectionImgWrapper">
        {/* <img src={topimg} style={{ width: "100%" }} /> */}
        <h1 className="text-white fw-bold">Set It & Forget It!</h1>
        <p className="text-center text-white col-md-8 col-12 descpay">
          Choose the dates in which you would like your on-line exposure to
          start and stop. Go “Live” to our global investor audience, and control
          your visibility and budgets day-by-day!
        </p>
      </div>

      <div className="payments_main_box">
        <div className="row ">
          <div className="col-md-9 paymentRows">
            <div className="common_dateRange">
              <div className=" d-flex row">
                <div className="col-lg-3 col-md-3 col-12 d-flex flex-column">
                  <div className="text-white d-block smedate">Choose All</div>
                  <FormGroup className="mt-2 text-white">
                    <Form.Check
                      type={"checkbox"}
                      id="presentatio-checkbox12"
                      checked={mainCheckBox}
                      value={mainCheckBox}
                      onChange={handlemaincheck}
                    // value={CompanySign?.is_level_two_profile}
                    // onChange={(e) => {
                    //   SetCompanySign({
                    //     ...CompanySign,
                    //     is_level_two_profile: e.target.checked,
                    //   });
                    // }}
                    />
                  </FormGroup>
                </div>

                <div className={` col-lg-9 col-md-9 col-sm-12 mt-sm-0 mt-2 d-flex flex-column align-items-md-end`}>
                  <div className="text-white smedate">Same Dates For All Selections</div>
                  <div className="d-flex flex-column-reverse flex-md-row gap-3 mt-2">
                    <div className={`fade ${toggle ? "visible show" : 'invisible'}`}>
                      <DateRange
                        disablePastDate={true}
                        setDateRange={(value) => {
                          setDateRange(value);
                          setIsDisable(false);
                          setinitialRange(value)
                          setinitialRange(value)
                        }}
                        disabled={!toggle}
                        dateRange={dateRange}
                        setDaysBetween={setDaysBetween}
                        daysBetween={daysBetween}
                        setFinalAmount={setFinalAmount}
                        paymentPerDay={paymentPerDay}
                        finalAmount={finalAmount}
                      />
                    </div>
                    <div>
                      <Switch
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        defaultChecked={false}
                        checked={toggle}
                        onChange={() => settoggle(!toggle)}
                        style={{
                          background:
                            "linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5)",
                        }}
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="common_toggle mt-3 flex-md-row flex-md-column col-lg-4 col-md-4 col-12 order-md-3 order-2 d-flex justify-content-center align-items-center gap-2">

            </div> */}
              </div>
            </div>
          </div>
        </div>
        {loading ?
          <div className="d-flex justify-content-center align-items-center">
            <Spinner
              as="span"
              animation="border"
              size="lg"
              role="status"
              aria-hidden="true"
              variant="primary"
            />
          </div>
          :
          <>

            <Payments checkBoxValue={scheduledata?.payment > 0 ? true : false} changedate={setdata} disabledate={toggle} type={Types?.[0]} sameDateRange={dateRange ? dateRange : ""} promoResDetails={promoRes ? promoRes?.details : ""} />
            <Featured checkBoxValue={scheduledata?.featured > 0 ? true : false} changedate={setdata} disabledate={toggle} type={Types?.[1]} sameDateRange={dateRange ? dateRange : ""} promoResDetails={promoRes ? promoRes?.details : ""} />
            <DownloadLeads checkBoxValue={scheduledata?.download_lead > 0 ? true : false} changedate={setdata} disabledate={toggle} type={Types?.[2]} sameDateRange={dateRange ? dateRange : ""} promoResDetails={promoRes ? promoRes?.details : ""} />

            <div className="blue_right_Border row" id="paymentsPage">
              <div className="col-lg-4 col-md-6 col-12 px-0">
                <div className="common_dateRange">

                  <Form.Group className="mb-3 d-flex align-items-md-end align-items-center justify-content-center justify-content-md-start gap-3 applyPromoCodeDiv flex-column  flex-md-row" controlId="exampleForm.ControlInput1">
                    <div>

                      <Form.Label className="text-white text-center text-md-start mx-auto mx-md-0 d-flex">Promo Code</Form.Label>
                      <Form.Control onChange={e => { setPromoCodeValue(e.target.value); handlePromoOnChange(e) }} value={PromoCodeValue} type="text" placeholder="Apply Promocode" />
                    </div>
                    <div className="promoButton ">

                      <Button onClick={handlePromoCode} disabled={DatatoSend.dates?.length > 0 && DatatoSend.booking_types?.length > 0 && !PromoCodeValue ? true : false}>{promoBtnLoader ? <Spinner animation="border" size="sm" /> : "Apply"} </Button>
                    </div>


                  </Form.Group>

                </div>

              </div>


              <div className=" row">
                <FormGroup className="mt-4">
                  <Form.Check
                    type={"checkbox"}
                    id="presentatio-checkbox12"
                    className="text-secondary"
                    checked={btnDisabled}
                    onChange={() => setBtnDisabled(!btnDisabled)}
                    label="I have read Client Agreement and Privacy Policy  of the company carefully, accept them in full, agree with all conditions of handling my personal data and consent to investor to contact me regarding  available services and  for marketing purpose."
                  />
                </FormGroup>
              </div>

              <Grid container spacing={0} className="payment_right_side mt-4">
                <div className="mt-3 payButton d-flex justify-content-center flex-column gap-3">
                  <Button style={{ width: "fit-content", margin: "auto" }} onClick={pay} disabled={!btnDisabled}>
                    {btnLoading
                      ? <Spinner animation="border" size="sm" />
                      :
                      `All Sales Final ${promoRes?.total_amount ? promoRes?.total_amount - promoRes?.total_discount : Valuewithoutdisc ? Valuewithoutdisc : "0"}$`
                    }
                  </Button>
                  <p className="text-white text-center">Enter Credit Card On Next Screen</p>
                </div>
              </Grid>
            </div>

            <div>
              {clientSecret && stripePromise && (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                  <CheckoutForm bookingId={bookingId} show={show} handleClose={handleClose} amount={promoRes?.total_amount ? `${promoRes?.total_amount - promoRes?.total_discount}$` : modalpayvalue && `${modalpayvalue}$`} modalvaluestate={setModalPayValue} />
                </Elements>
              )}
            </div>
          </>
        }
      </div>



    </>
  );
}

