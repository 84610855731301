import {apiHandle}from './apiHandler'


export const GetActiveScheduleApi = () => {
    return apiHandle().get(`/company/auth/activeSchedule`);
  };
  

export const GetfeaturedListingApi=()=>{
    return apiHandle().get('/company/auth/featuredListing')
}

export const GetdownloadActivityApi=()=>{
    return apiHandle().get('/company/auth/downloadActivity')
}








