import { Typography } from "antd";
import React, { useState } from "react";
import file from "../../../images/filling.png";
import { RiCloseFill } from "react-icons/ri";


export default function AskedQuestions({faq} ) {
  console.log(faq)

  const [open, setopen] = useState(1);
  const [openTwo, setOpenTwo] = useState(1);

  return (
    <div className="faqs-asked">

      {/* <div className={`faq-accordion ${open == 1 ? "act" : ""}`}>
        <h4 onClick={() => { setopen(1) }}>
          Download the document for any queries
          <span className="close-ico">
            <RiCloseFill size={20} color="#fff" />
          </span>
        </h4>
        <div className="faq-content">
          {pdf ? (
            <div className="fillingInViewPortfolio">
              <img src={file} alt="file-icon" />
              <a href={pdf} download>
                Download
              </a>
            </div>
          ) : (
            <Typography.Text secondary>No data Found.!</Typography.Text>
          )}
        </div>
      </div> */}

      {faq?.admin_faq.map((val , ind) => {
        return(

      <div className={`faq-accordion ${open == ind ? "act" : ""}`}>
        <h4 onClick={() => {setOpenTwo(null); setopen(ind) }}>
          {val?.question}
          <span className="close-ico">
            <RiCloseFill size={20} color="#fff" />
          </span>
        </h4>
        <div className="faq-content">
          <p
              className="textEditorInnerData"
              dangerouslySetInnerHTML={{ __html: val?.answer }}
            />

        </div>
      </div>
        )
      })}


{faq?.company_faq?.length > 0 ? (

  faq?.company_faq.map((val , ind) => {
          return(
  
        <div className={`faq-accordion ${openTwo == ind ? "act" : ""}`}>
          <h4 onClick={() => { setOpenTwo(ind); setopen(null) }}>
            {val?.question}
            <span className="close-ico">
              <RiCloseFill size={20} color="#fff" />
            </span>
          </h4>
          <div className="faq-content">
            <p
                className="textEditorInnerData"
                dangerouslySetInnerHTML={{ __html: val?.answer }}
              />
  
          </div>
        </div>
          )
        })
) : null
    }

    </div >
  );
}

