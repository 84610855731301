import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { BsPersonPlus, BsPersonPlusFill } from "react-icons/bs";
import { useNavigate } from "react-router";


const FollowButtoncard = ({ follow, disabled, onClick }) => {
  const navigate = useNavigate()
let appState = localStorage.getItem("IP_State");
  console.log(follow, "buttonflw")
  return (
    <>
      <Button
        // variant={!follow ? "outline-primary" : "outline-secondary"}
        onClick={appState === null ? () => { navigate("/login") } : onClick}
        disabled={disabled ? true : false}
        style={{
          borderColor: "#29A5FF",
          color: "white",
          height: "40px",
          width: "40px",
          background: ' #00000069',
          borderRadius: "100%",
          border: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {disabled ? <Spinner size="sm" /> : !follow ? (
          <>
            <span>
              <BsPersonPlus color="white" size={24} />
            </span>
          </>
        ) : (
          <>
            <span>
              <BsPersonPlusFill color="white" size={24} />
            </span>
          </>
        )}
      </Button>
    </>
  );
};

export default FollowButtoncard;
