import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFollowedCompaniesHandle, getInrestedCompaniesHandle } from "../../redux/actions/getAllCompaniesAction";
import { Col, Row } from "antd";
import { Button, Spinner } from "react-bootstrap";
import CompanyCard from "../companies/CompanyCard";
import { getCompanyByIDHandle } from "../../redux/actions/getCompanyByIdAction";
import { useNavigate } from "react-router-dom";
import ExploreCards from "../Common/ExploreCards";
import thumbnail from "../../images/no_image.jpg"

const Interested = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [iCompanyLoading, setIsCompanyLoading] = useState(false);

  const { isLoading, getAllCompanies, error } = useSelector(
    (state) => state.getAllCompaniesReducer
  );

  const companybyIdHandle = (id) => {
    getCompanyByIDHandle(dispatch, id, navigate);
    navigate(`/user/company/portfolio/${id}`);
  };
  useEffect(() => {
    // getFollowedCompaniesHandle(dispatch, setIsCompanyLoading);
    getInrestedCompaniesHandle(dispatch, setIsCompanyLoading)
  }, []);



  if (iCompanyLoading) {
    return (
      <>
        <div className="container">
          <div
            style={{ height: "90vh" }}
            className="d-flex justify-content-center align-items-center"
          >
            <Spinner animation="border" size="lg" variant="primary" />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {getAllCompanies.length !== 0 ? (
        <>
          <div className="">
            {iCompanyLoading ? (
              <div className="container">
                <div className="main-body">
                  <Spinner animation="border" size="lg" variant="primary" />
                </div>
              </div>
            ) : (
              <div className="companies_card_wrapper">
                <div className="row">
                  {getAllCompanies.map((val, ind) => {
                    return (
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <ExploreCards
                          isInterested={val?.interested}
                          isFollowed={val?.followed}
                          logo={val?.logo}
                          id={val.id}
                          title={val.company_name}
                          desc={val.description}
                          total_investment={val.total_amount ? val.total_amount : "-"}
                          minimum_investment={val.minimum_investment ? val.minimum_investment : null}
                          state={val.state ? val.state : ""}
                          country={val.country ? val.country : ""}
                          start_date={val.active_schedule_start_date ? val.active_schedule_start_date : ""}
                          end_date={val.active_schedule_end_date ? val.active_schedule_end_date : ""}
                          img={val.primary_video_thumbnail ? val.primary_video_thumbnail : thumbnail}
                          isLevelTwo={val.is_level_two_profile}
                          onClick={() => companybyIdHandle(val?.id)}
                        />
                        {/* <CompanyCard
                            CompanyName={val.company_name}
                            mark={true}
                            img={val?.logo}
                            text={val.description}
                            followed={val.followed}
                            intrested={val.interested}
                            id={val?.id}
                            isLevelTwo={val.is_level_two_profile}
                            onClick={() => companybyIdHandle(val?.id)}
                          /> */}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </>
      ) : error ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "red",
            fontWeight: "bold",
          }}
        >
          {error}
        </div>
      ) : (
        <div className="d-flex justify-content-center mt-3" >

          <Button style={{ background: "linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, rgb(2, 0, 79), rgb(2, 0, 79)), rgba(217, 217, 217, 0.5)", border: 'none' }} onClick={() => navigate("/product")}>Explore Investments</Button>
        </div>


      )}
    </>
  );
};

export default Interested;
