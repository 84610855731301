import { apiHandle } from "./apiHandler";

// Api for get all companies

export const getAllCompanies = () => {
  return apiHandle().get("/user/auth/company");
};

export const getCompanyById = (id) => {
  return apiHandle().get(`/user/auth/company/${id}`);
};

export const getFollowedCompany = () => {
  return apiHandle().get("/user/auth/company/followed");
};

export const getIntrestedCompany = () => {
  return apiHandle().get("/user/auth/company/interested");
};
export const getCompanyByIndustriesApi = (body) => {
  return apiHandle().get(`/user/auth/company/?industries=${body}`);
};

export const SavedDocs = (id) => {
  return apiHandle().post("/user/auth/save/file", { "fileId": id });
};
export const getSavedDocs = () => {
  return apiHandle().get("/user/auth/saved/files");
};
export const removeSavedDocs = (id) => {
  return apiHandle().delete(`/user/auth/delete/file/${id}`);
};
