import { GetActiveScheduleApi, GetdownloadActivityApi, GetfeaturedListingApi } from '../../api/scheduleApi'
import { GETACTIVESCHEDULEDATA, GETDOWNLOADACTIVITY, GETFEATUREDLISTING, SAVE_Schedule_DATA, START_GETTING_SCHEDULE_DATA } from '../constants/schedule_Constants'
export const SCheduleCalendarsAction= async(dispatch,setProcessLoading)=>{
    try {
        dispatch({
            type:START_GETTING_SCHEDULE_DATA
        })
        setProcessLoading(true)
        const getActiveSchedule=await GetActiveScheduleApi()
        const getFeacturedListing=await GetfeaturedListingApi()
        const GetDownloadList=await GetdownloadActivityApi()

       dispatch({
        type:GETACTIVESCHEDULEDATA,
        payload:getActiveSchedule.data.data
       })
       dispatch({
        type:GETFEATUREDLISTING,
        payload:getFeacturedListing.data.data
       })
       dispatch({
        type:GETDOWNLOADACTIVITY,
        payload:GetDownloadList.data.data
       })
    } catch (error) {
        dispatch({
            type:SAVE_Schedule_DATA
        })
        console.log(error)
        setProcessLoading(false)
    }finally{
        setProcessLoading(false)
        dispatch({
            type:SAVE_Schedule_DATA
        })
    }
  
}