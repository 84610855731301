import { loginCompany, loginUser } from "../../api/AuthApi";
import { storeAppState, storeTokens } from "../../helper/storgeHandler";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../helper/toast_notification_function";
import { logoutUserType } from "../constants/Common_constants";
import {
  ERROR_GETTING_AUTH_STATE,
  SAVE_GETTING_AUTH_STATE,
  START_GETTING_AUTH_STATE,
} from "../constants/auth_Constants";

// user Related Actions

// old user login action

export const LoginUserHandle = (User, navigate, dispatch, setLoading) => {
  dispatch({
    type: START_GETTING_AUTH_STATE,
  });

  // let test = {
  //   ...User,
  //   testing: true,
  //   ttl: "30s,60s",
  // };
  loginUser(User)
    .then((response) => {
      let tokens = response.data?.data[0];
      
      storeTokens(tokens);

      setLoading(false);

       let clientType = response?.data?.data[0]?.clientType
      storeAppState(clientType) // App State Flgas are COMPANY || USER


      dispatch({
        type: SAVE_GETTING_AUTH_STATE,
        payload: "USER",
      });
      navigate("/user/company");
      displaySuccessToast("Logged in Successfully!");
    })
    .catch((error) => {
      setLoading(false);
      displayErrorToast(error);
      dispatch({
        type: ERROR_GETTING_AUTH_STATE,
        payload: error.response?.message[0],
      });
    });
};

// new common login actjon


export const LoginHandle = (User, navigate, dispatch, setLoading) => {
  dispatch({
    type: START_GETTING_AUTH_STATE,
  });

  // let test = {
  //   ...User,
  //   testing: true,
  //   ttl: "30s,60s",
  // };


  loginUser(User)
    .then((response) => {
      let tokens = response.data?.data[0];
      storeTokens(tokens);

      setLoading(false);
      localStorage.setItem("first_login", response.data.data[0].first_login)
      let clientType = response?.data?.data[0]?.clientType.toUpperCase()
      // storeAppState(clientType) // App State Flgas are COMPANY || USER

      storeAppState(clientType) // App State Flgas are COMPANY || USER

      if(clientType == "USER"){

        dispatch({
          type: SAVE_GETTING_AUTH_STATE,
          payload: "USER",
        });
        navigate("/user/company");

      }
      else if(clientType == 'COMPANY'){
      storeAppState("COMPANY") // App State Flgas are COMPANY || USER

        dispatch({
          type: SAVE_GETTING_AUTH_STATE,
          payload: "COMPANY",
        });

        navigate("/company/dashboard");
      }
      // setTimeout(() => {
      //   navigate("/user/company");
      // }, 5000);
      // window.location.replace("/user/company");
      displaySuccessToast("Logged in Successfully!");
    })
    .catch((error) => {
      console.log(error)
      setLoading(false);
      displayErrorToast(error);
      dispatch({
        type: ERROR_GETTING_AUTH_STATE,
        payload: error.response?.message,
      });
    });
};



export const LoginCompanyHandle = (
  User,
  navigate,
  dispatch,
  setProcessLoading
) => {
  
  dispatch({
    type: START_GETTING_AUTH_STATE,
  });
  // let test = {
  //   ...User,
  //   testing: true,
  //   ttl: "30s,60s",
  // };
  loginCompany(User)
    .then(async (res) => {
      if (res.data.success) {
        let tokens = res.data?.data[0];

        
        storeTokens(tokens); // function for storing token isn local storage
        
        setProcessLoading(false);
        
        displaySuccessToast("Logged in Successfully!");

        storeAppState("COMPANY"); // App State Flgas are COMPANY || USER

        dispatch({
          type: SAVE_GETTING_AUTH_STATE,
          payload: "COMPANY",
        });

        navigate("/company/dashboard");
      }
    })
    .catch((error) => {
      displayErrorToast(error);
      setProcessLoading(false);
      dispatch({
        type: ERROR_GETTING_AUTH_STATE,
        payload: error.response?.data?.message[0],
      }); 
      // setProcessLoading(false);
    });
};

// user Related Actions End

export const LogoutFn = (dispatch) => {
  // const navigate = useNavigate()
  // window.location.replace("/login");
  localStorage.clear();
  displaySuccessToast("session expired login again");
  // navigate("/login")
  dispatch({
    type: logoutUserType,
  });
};

export const LogoutHandle = (dispatch) => {
  localStorage.clear();
  dispatch({
    type: logoutUserType,
  });
  displaySuccessToast("Log Out SuccessFully....!");
};
