import { Row, Space, Typography } from "antd";
import React from "react";
import verifiedbadge from "../../../images/verified-green.png"

export default function InvestorPerksInProfile( {InvestorPerks} ) {
  const { Title } = Typography;

  console.log(InvestorPerks , "InvestorPerks")

  return (
    <div className="inv-perks-main">
      {InvestorPerks?.length > 0 ? (
      
        InvestorPerks?.map((data, ind) => {
          return(

          <div className="invt-perks" key={ind}>
            <div className="inv-flex">
              <img src={verifiedbadge} alt="verified" />
              <h5>{data?.heading}</h5>
            </div>
            <p>{data?.text}</p>
          </div>
          )

        })

      ) : (
        <h6 className="text-center">Data not Found!</h6>
      )
      }
    </div >
  );
}
