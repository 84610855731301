import { GetProfilePortfolioFileApi } from "../../api/companyApis/profilePortfolio/profilePortfolioApis";
import { displayErrorToast } from "../../helper/toast_notification_function";
import {
  START_GETTING_COMPANY_PROFILE_PORTFOLIO,
  ERROR_GETTING_COMPANY_PROFILE_PORTFOLIO,
  SAVE_GETTING_COMPANY_PROFILE_PORTFOLIO,
} from "../constants/company_profile_portfolio_const";

export const getCompanyProfilePortfolioAction = (dispatch) => {
  dispatch({
    type: START_GETTING_COMPANY_PROFILE_PORTFOLIO,
  });
  GetProfilePortfolioFileApi()
    .then((res) => {

      dispatch({
        type: SAVE_GETTING_COMPANY_PROFILE_PORTFOLIO,
        payload: res.data?.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: ERROR_GETTING_COMPANY_PROFILE_PORTFOLIO,
        payload: error.response.data.message[0],
      });

      displayErrorToast(error);
    });
};
