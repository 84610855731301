import * as yup from "yup";

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
// min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

export const advancedSchema = yup.object().shape({
    companyname: yup
        .string()
        .min(3, "Company Name must be at least 3 characters long")
        .required("Required"),
    username: yup
        .string()
        .min(3, "User Name must be at least 3 characters long")
        .required("Required"),
    email: yup.string().email("Please enter a valid email").required("Required"),
    password: yup
        .string()
        .min(5)
        .matches(passwordRules, { message: "Please create a stronger password. Must Contain 1 uppercase & 1 lowercase letter and 1 numeric digit" })
        .required("Required"),
    confirmpassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .required("Required"),
    region: yup.string().required("Required"),
    industries: yup.string().required("Required"),
    address: yup.string().required("Required. Please Select An Address."),
    captcha: yup.string().required("Required"),
    is_level_two_profile: yup.boolean(),
    accept_terms: yup
        .boolean()
        .oneOf([true], "Please accept the terms & conditions"),
});


export const investorSchema = yup.object().shape({
    firstname: yup
        .string().required("Required"),
    lastname: yup
        .string().required("Required"),
    username: yup
        .string()
        .min(3, "User Name must be at least 3 characters long")
        .required("Required"),
    email: yup.string().email("Please enter a valid email").required("Required"),
    password: yup
        .string()
        .min(5)
        .matches(passwordRules, { message: "Please create a stronger password. Must Contain 1 uppercase & 1 lowercase letter and 1 numeric digit" })
        .required("Required"),
    confirmpassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .required("Required"),
    address: yup.string().required("Required. Please Select An Address."),
    captcha: yup.string().required("Required"),
});