import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  getMarketLandScapeApi,
  marketLandScapeTextApi,
} from "../../../../api/companyApis/portfolio/marketLandscapeApi";
import { TextEditor } from "../../../../component/TextEditor";
import {
  displayErrorToast,
  displaySuccessToast,
  displayWorrningToast,
} from "../../../../helper/toast_notification_function";
import GraphDataInMarketLandscape from "./GraphDataInMarketLandscape";

export default function MarketLandscape() {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [investorDataFromApi, setInvestorDataFromApi] = useState();
  const [isDisable, setIsDisable] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);

  const submit = () => {
    //   send data var in api
    setBtnLoader(true)
    let obj = { text: data };

    if (data !== "" || investorDataFromApi) {
      setIsDisable(true);
      marketLandScapeTextApi(obj)
        .then((res) => {
          if (res.data.success) {
            displaySuccessToast("Success");
            setIsDisable(false);
    setBtnLoader(false)

          }
        })
        .catch((err) => {
          displayErrorToast(err);
          setIsDisable(false);
    setBtnLoader(false)

        });
    } else {
      displayWorrningToast("Please enter the data..!");
    }
  };

  const getData = () => {
    setLoading(true);
    getMarketLandScapeApi()
      .then((res) => {
        if (res?.data?.success) {
          setInvestorDataFromApi(res?.data?.data[0]);
          setData(res?.data?.data[0]);
          setLoading(false);
        }
      })
      .catch((Err) => {
        setLoading(false);
        displayErrorToast(Err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner animation="grow" variant="primary" />
      </div>
    );
  }

  return (
    <div className="bg_black">
      <div className="doc_slide">
          <h3>Market Landscape Overview</h3>
        </div>
      <TextEditor
        onClick={submit}
        data={investorDataFromApi}
        setData={setData}
        disabled={isDisable}
        loader={btnLoader}
      />
      <div className="mt-5">
      <GraphDataInMarketLandscape />

      </div>
      {/* <Test /> */}
    </div>
  );
}
