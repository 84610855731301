import React, { useState } from "react";
import CompanyList from "./companyList";
import CompanyTabs from "./Tabs";
import { Row, Col } from "antd";
import Interested from "./Intrested";
import { getFollowedCompaniesHandle, getInrestedCompaniesHandle } from "../../redux/actions/getAllCompaniesAction";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import SavedDocs from "./SavedDocs";

const Company = () => {
  const dispatch = useDispatch()
  const [iCompanyLoading, setIsCompanyLoading] = useState(false);

  const items = [
    {
      key: "1",
      label: `Following`,
      children: (
        <CompanyList />
      ),
    },
    {
      key: "2",
      label: `Interested`,
      children: (
        <Interested />
      ),
    },
    {
      key: "3",
      label: `Documents`,
      children: (
        <SavedDocs />
      ),
    },
    {
      key: "4",
      label: `Chat Logs`,
      children: (
        <h5 className="text-white text-center">Coming Soon!</h5>

      ),
    },
  ];

  const handleFilter = (value) => {
    switch (value) {
      case "1":
        return getFollowedCompaniesHandle(dispatch, setIsCompanyLoading);

      case "2":
        return getInrestedCompaniesHandle(dispatch, setIsCompanyLoading);


      // case "all":
      //   return getAllCompaniesHandle(dispatch);
      // case "industries":
      //   return;
      // // getFollowedCompaniesHandle(dispatch, setTabsLoading);

      // default:
      //   return getAllCompaniesHandle(dispatch);
    }
  };

  if (iCompanyLoading) {
    <div>
      <div className="d-flex justify-content-center align-items-center  ">
        <Spinner
          as="span"
          animation="border"
          size="lg"
          role="status"
          aria-hidden="true"
          variant="primary"
        />
      </div>
    </div>
  }

  return (
    <>
      <div className="companiesPageContainer container">
        <h1 className=" pageMianheading text-white mt-5 commonH2 text-center text-md-start mt-5">
          {" "}
          Investor Dashboard
        </h1>
        <p className="  text-center text-md-start pb-3" style={{ color: "white" }}>
          This is the place for you to keep track of your interactions with investment opportunities. Below are your saved videos, downloaded white-papers, and chat log.
        </p>
        <div className="companytabs">
          <CompanyTabs

            defaultActiveKey="followed"
            id="uncontrolled-tab-example"
            className="mb-3"
            items={items}
            onChange={handleFilter}

          />
        </div>
      </div>
    </>
  );
};

export default Company;
