import React from "react";
import { motion } from "framer-motion";
import logo from "../../images/IpLogo.png";
import { Link } from "react-router-dom";
import { Avatar, Button } from "antd";
import { MdOutlineNotificationsNone } from "react-icons/md";
import { display } from "@mui/system/Box";
import { useEffect } from "react";
import { getDeshboardApi, getTemporaryInactiveApi } from "../../api/companyApis/analyticsApi";
import { useState } from "react";
import { Tooltip } from 'antd';
import icon from "../../images/user.png";
import { LogoutHandle } from "../../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { RxDotFilled } from "react-icons/rx"
import { Switch, Popover } from 'antd';
import { Spinner } from "react-bootstrap";
import { displaySuccessToast } from "../../helper/toast_notification_function";




export default function DashboardNav({ isOpen, children, width, style }) {
  const { getProfile, isLoading } = useSelector(
    (state) => state.getUserProfileReducer
  )
  const [data, setData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false);
  const [activecomp, setactivecomp] = useState(false)
  const [loader, setloader] = useState(false)
  const dispatch = useDispatch()

  const activetoggle = () => {
    setloader(true)
    getTemporaryInactiveApi().then((res) => {
      if (res.data.message[0] == "Success") {
        setactivecomp(!activecomp)
        setloader(false)
        setOpen(false);
      }
      if (activecomp) {
        displaySuccessToast("Activation Successful");
      }
      else {
        displaySuccessToast("Temporary Inactivation Successful");
      }
    })
  }
  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  useEffect(() => {
    setLoading(true);
    getDeshboardApi()
      .then((res) => {
        setData(res.data.data[0]);
        setactivecomp(res.data.data[0].temporary_inactive)
        localStorage.setItem("IP_Company_isActive", JSON.stringify(res.data.data[0]?.is_active)
        );

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });


  }, []);

  return (
    <motion.div
      className={`${width > 600 ? "navbar_dashboard" : "sm_navbar_dashboard"}`}
      animate={{


        transition: {
          duration: 0.5,
          type: "spring",
          damping: 10,
        },
      }}
    >
      <div
        id="nav"
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          height: "60px"
        }}
      >

        <div
          style={{
            padding: "1rem",
            display: "flex",
            alignItems: "center",

            justifyContent: "space-between",
          }}
        >

          <div
            style={{
              color: "black",
              fontWeight: "bold",
              fontSize: "16px",
              margin: " 0 12px",
              position: "relative"
            }}
          >

            {data?.is_active === true ?
              activecomp === false ?
                <Popover
                  content={<div className="">
                    <p>Do you want to temporary InActive?</p>
                    <button className="btn btn-sm btn-secondary" onClick={hide}>Close</button>
                    <button className="btn btn-sm btn-primary ms-2" onClick={activetoggle}>{loader ? <Spinner size="sm" /> : "Yes"}</button>
                  </div>}
                  title="You Are Active"
                  trigger="click"
                  open={open}
                  onOpenChange={handleOpenChange}
                >
                  <Tooltip placement="topLeft" title={"You are Active!"}>
                    <img style={{ borderRadius: '50px' }} src={require("../../images/activeblink.gif")} height="25px" width="25px" />
                  </Tooltip>
                </Popover> :
                <Popover
                  content={<div className="">
                    <p>Do you want Active?</p>
                    <button className="btn btn-sm btn-secondary" onClick={hide}>Close</button>
                    <button className="btn btn-sm btn-primary ms-2" onClick={activetoggle}>{loader ? <Spinner size="sm" /> : "Yes"}</button>
                  </div>
                  }
                  title="You Are Temporary InActive"
                  trigger="click"
                  open={open}
                  onOpenChange={handleOpenChange}
                >
                  <Tooltip placement="topLeft" title={"You are Temporary InActive!"}>
                    <RxDotFilled color="yellow" style={{ width: "24px", height: "24px" }} />
                  </Tooltip>
                </Popover>
              :

              <Tooltip placement="topLeft" title={"You are InActive!"}>
                <RxDotFilled color="gray" style={{ width: "24px", height: "24px" }} />
              </Tooltip>
            }

          </div>
          <div>
            <Button
              size="large"
              type="text"
              shape="circle"
              className="noti_icon"
            >
              <img src="/images/bellicon.svg" />
            </Button>
          </div>


          <li className="nav-item dropdown userProfileNavbar" style={{ listStyleType: "none" }} >
            <span className="text-white">{getProfile?.company_name}</span>

            <a
              style={{ margin: "7px 10px 0", boxShadow: "1px 1px 3xpx 2px gray" }}
              className="nav-link dropdown-toggle"
              href=""
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <Avatar src={getProfile?.logo}
                style={{ verticalAlign: "middle" }}
                size={{
                  xs: 35,
                  sm: 35,
                  md: 35,
                  lg: 35,
                  xl: 35,
                  xxl: 35,
                }} />
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <Link
                  className="dropdown-item"
                  to="/Portfolio"

                >
                  Portfolio
                </Link>
              </li>

              <li>
                <Link
                  className="dropdown-item"
                  to="/"
                  onClick={() => LogoutHandle(dispatch)}
                >
                  Logout
                </Link>
              </li>


            </ul>
          </li>
        </div>
      </div>

    </motion.div>
  );
}
