import React, { useState, useRef, useMemo } from "react";
import JoditEditor from "jodit-react";
import { Button } from "antd";
import { Spinner } from "react-bootstrap";

export const TextEditor = ({ setData, btnText, onClick, data, disabled,loader,hideBtn , handleChange }) => {
  const editor = useRef(null);


  const [content, setContent] = useState("");

 
  const config = {
    // buttons: "bold,italic,underline,link,paragraph,|,ul,ol",
    buttons:hideBtn ?"bold,italic,underline,link," : "bold,italic,underline,link,paragraph,|,ul,ol",

    toolbarAdaptive: false,
    showXPathInStatusbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showParagraphsCounter: false,
    showTextLengthInStatusbar: false,
    buttonsMD: "bold,italic,underline,link,|, h4, h5 ,h6,paragraph,|,ul,ol",
    buttonsSM: "bold,italic,underline,link,|, h4, h5 ,h6,paragraph,|,ul,ol",
    buttonsXS: "bold,italic,underline,link,|, h4, h5 ,h6,paragraph,|,ul,ol",
    placeholder: "Type here...",
    
  };

  const handler = (e) => {
    if(e.trim() !== ''){
          handleChange()
    }
  };

  
  
  
  const handleBlur = (content) => {
    setData(content)
  }
console.log(data)


  return (
    <>
      <div style={hideBtn  ? {} : { paddingBottom: "1rem" }}>
        <button
          onClick={onClick}
          className={` ${hideBtn ? "d-none" : "addbtnt"}`}
          style={{ float: "right", width: "fit-content", width: "80px", fontSize: "15px" }}
          disabled={disabled}
        >
          {loader ? <Spinner size="sm" animation="border" /> : data?.text ? "Update" : "Submit"}
        </button>
      </div>

      <div
        style={hideBtn ? {marginTop: "1rem"} : { marginTop: "2rem", overflow: "auto" }}
      >
        <JoditEditor
        style={hideBtn ? {height:"150px"} : {}}
          ref={editor}
          value={hideBtn ? data.answer :  data?.text ? data.text : ""}
          config={config}
          tabIndex={1000} // tabIndex of textarea
          onBlur={(newContent) => {handleBlur(newContent)}} // preferred to use only this option to update the content for performance reasons
          onChange={handleChange ? (e) => {handler(e)} : () => {}}
        />
      </div>
    </>
  );
};


// import React, { useState } from 'react';
// import JoditEditor from 'jodit-react';

// const YourComponent = () => {
//   const [editorContent, setEditorContent] = useState('');
//   const [isBtnEnabled, setIsBtnEnabled] = useState(false);

//   const handleEditorChange = (newContent) => {
//     setEditorContent(newContent);
//     setIsBtnEnabled(newContent.trim() !== '');
//   };

//   const handleEditorFocus = () => {
//     setIsBtnEnabled(true);
//   };

//   const handleButtonClick = () => {
//     // Your button click logic here
//   };

//   return (
//     <div>
//       <JoditEditor
//         value={editorContent}
//         onBlur={(newContent) => setEditorContent(newContent)}
//         onChange={handleEditorChange}
//         onFocus={handleEditorFocus}
//       />
//       <button onClick={handleButtonClick} disabled={!isBtnEnabled}>
//         Your Button
//       </button>
//     </div>
//   );
// };

// export default YourComponent;


