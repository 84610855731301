import { useField } from "formik";

const CustomCheckbox = ({ label, id, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <>
            <div style={{ width: "100%", display: "flex", gap: "5px", alignItems: "baseline" }}>
                <input
                    id={id}
                    {...field}
                    {...props}
                    className={meta.touched && meta.error ? "input-error" : ""}
                />
                <label htmlFor={id} style={{ color: "#171717" }}>{label}</label>
            </div>
            <div className="ermsg" style={{ minHeight: "0px" }}>
                {meta.touched && meta.error && meta.error}
            </div>
        </>
    );
};
export default CustomCheckbox;