
import { Form, Formik } from "formik";
import { advancedSchema } from "../../schemas";
import CustomInput from "../../component/Common/CustomInput";
import { useEffect, useRef, useState } from "react";
import allstates from "../../utility/allstates";
import cities from "../../utility/cities";
import country from "../../utility/country";
import Select from "react-select";
import CustomCheckbox from "../../component/Common/CustomCheckbox";
import { useDispatch, useSelector } from "react-redux";
import { getAllIndustriesHandle } from "../../redux/actions/getAllIndustriesAction";
import { RegisterCompany } from "../../api/AuthApi";
import { dataCleaning, dataWithFiles } from "../../helper/common_functions";
import { displayErrorToast, displaySuccessToast } from "../../helper/toast_notification_function";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Modal, Spinner } from "react-bootstrap";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import {
    Combobox,
    ComboboxInput,
    ComboboxPopover,
    ComboboxList,
    ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import useWindowDimensions from "../../component/WindowDimensions";


const CompanySignUpForm = () => {
    const { width, height } = useWindowDimensions();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [CompanySign, SetCompanySign] = useState({
        company_name: "",
        username: "",
        // contact_number: null,
        address: "",
        email: "",
        password: "",
        confirmPassword: "",
        industries: [],
        logo: "",
        description: "",
        employer_identification_number: "",
        region_of_formation: "",
        placeId: "",
        city: "",
        state: "",
        zip: "",
        country: "United States of America",
        primary_contact_first_name: "",
        primary_contact_last_name: "",
        primary_contact_email: "",
        primary_contact_number: "",
        // primary_contact_date_of_birth: "",
        primary_contact_tax_identification_number: "",
        primary_contact_address: "",
        primary_contact_city: "",
        primary_contact_state: "",
        primary_contact_zip: "",
        primary_contact_country: "United States of America",
        is_level_two_profile: false,
        captcha: ""
    });

    const [companyregisterdata, setcompanyregisterdata] = useState({
        company_name: "",
        username: "",
        email: "",
        password: "",
        region: "USA",
        industries: "",
        placeId: CompanySign?.placeId,
        is_level_two_profile: false,
        captcha: CompanySign?.captcha
    })

    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
    } = usePlacesAutocomplete();

    const handleSelect = async (address) => {
        const selectedPlace = data.find((place) => place.description === address);

        if (selectedPlace) {
            SetCompanySign({ ...CompanySign, placeId: selectedPlace.place_id })
        }
        setValue(address, false);
        clearSuggestions();

        // const results = await getGeocode({ address });
        // const { lat, lng } = await getLatLng(results[0]);
        // setSelected({ lat, lng });
    };


    const [send, setsend] = useState(false);
    const [actiontoperf, setactiontoperf] = useState();

    const navigate = useNavigate()
    const onSubmit = (values, actions) => {
        setcompanyregisterdata({
            company_name: values.companyname,
            username: values.username,
            email: values.email,
            password: values.password,
            confirmPassword: values.confirmpassword,
            region_of_formation: values.region,
            industries: values.industries,
            placeId: CompanySign?.placeId,
            is_level_two_profile: values.is_level_two_profile,
            captcha: CompanySign?.captcha
        })
        setsend(true)
        setactiontoperf(actions)
    };
    const dispatch = useDispatch();

    const { getAllIndustries } = useSelector(
        (state) => state.getAllIndustriesReducer
    );


    const [signenable, setsignenable] = useState(true)

    const checkcaptcha = async (token) => {
        SetCompanySign({ ...CompanySign, captcha: token });

        if (token) {
            setsignenable(false)
        }
        else {
            setsignenable(true)
        }
    }

    const [steptwo, setsteptwo] = useState(false);
    const [countryid, setCountryid] = useState('231');
    const [stateid, setstateid] = useState('');
    const [cityname, setcityname] = useState('');
    let stateopt = [];
    let cityopt = [];

    const handleCountry = (e) => {
        stateopt = [];
        cityopt = [];
        setCountryid(e.value)
        setstateid("")
        SetCompanySign({ ...CompanySign, country: e.label, state: "", city: "" })
    }
    const handleState = (e) => {
        cityopt = [];
        setstateid(e.value)
        SetCompanySign({ ...CompanySign, state: e.label, city: "" })
    }
    const handleCity = (e) => {
        setcityname(e.label)
        SetCompanySign({ ...CompanySign, city: e.label })
    }

    const options = country.map((data) => {
        return (
            {
                value: data.country_id,
                label: data.country_name
            }
        )
    })

    let filter = options.filter((val) => { return val.value == '231' })

    useEffect(() => {
        allstates.map((data) => {
            if (data.country_id == countryid) {
                return (stateopt.push({ value: data.state_id, label: data.state_name }));
            }
        })
    }, [countryid, stateid, cityname, filter])

    useEffect(() => {
        cities.map((data) => {
            if (data.state_id == stateid) {
                return (cityopt.push({ value: data.city_id, label: data.city_name }));
            }
        })
    }, [stateid, cityname])

    useEffect(() => {
        if (send) {
            RegisterCompany(dataWithFiles(dataCleaning(companyregisterdata)))
                .then((res) => {
                    if (res.data.success) {
                        actiontoperf.resetForm()
                        actiontoperf.setSubmitting(false)
                        displaySuccessToast("You have registered Successfully.  Please login");
                        setsend(false)
                        // setLoading(false);
                        handleShow()
                        setTimeout(() => {
                            navigate("/login");
                        }, 5000);
                    }
                })
                .catch((err) => {
                    // setLoading(false);
                    actiontoperf.setSubmitting(false)
                    setsend(false)
                    displayErrorToast(err);
                });
        }
    }, [companyregisterdata])

    useEffect(() => {
        getAllIndustriesHandle(dispatch);
        // const script = document.createElement('script');
        // script.defer = true;
        // script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API}&libraries=places`;
        // document.body.appendChild(script);
    }, []);

    return (
        <>
            <Formik
                initialValues={{
                    companyname: "",
                    username: "",
                    email: "",
                    password: "",
                    confirmpassword: "",
                    region: "USA",
                    industries: "",
                    address: "",
                    captcha: "",
                    is_level_two_profile: false,
                    accept_terms: false
                }}
                validationSchema={advancedSchema}
                onSubmit={onSubmit}
            >
                {({ isSubmitting, errors, touched, setFieldValue }) => (
                    <Form>
                        <div className="row">
                            <div className="col-lg-6 col-md-12 mt-2">
                                <CustomInput
                                    label="Company Name"
                                    name="companyname"
                                    type="text"
                                    placeholder="Enter your Company Name"
                                />
                            </div>
                            <div className="col-lg-6 col-md-12 mt-2 frmsel">
                                <label className="frmlabel">Region Of Formation</label>
                                <Select defaultValue={filter} name="region" options={options}
                                    onChange={(e) => { SetCompanySign({ ...CompanySign, region_of_formation: e.label }); setFieldValue("region", e.label) }} styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            textAlign: 'left',
                                            height: '45px',
                                            marginTop: "7px",
                                            marginBottom: "5px",
                                            backgroundColor: "#f7f7f7",
                                            border: (touched.region && errors.region) ? "1px solid rgb(211,0,0)" : "1px solid #ddd",
                                            color: "#171717"
                                        }),
                                        placeholder: (provided, state) => ({
                                            ...provided,
                                            color: "#333"
                                        }),
                                        singleValue: (provided, state) => ({
                                            ...provided,
                                            color: "#171717"
                                        }),
                                        input: (provided, state) => ({
                                            ...provided,
                                            color: "#171717"
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            textAlign: 'left',
                                            backgroundColor: state.isFocused ? "#1890ff" : "#171717"

                                        })
                                    }}
                                    placeholder={'Region Of Formation'}
                                />
                                <div className="ermsg">
                                    {touched.region && errors.region && errors.region.error}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 mt-2">
                                <CustomInput
                                    label="Email Address"
                                    name="email"
                                    type="email"
                                    placeholder="Enter your Email Address"
                                />
                            </div>
                            <div className="col-lg-6 col-md-12 mt-2">
                                <CustomInput
                                    label="User Name"
                                    name="username"
                                    type="text"
                                    placeholder="Enter your User Name"
                                />
                            </div>
                            <div className="col-lg-6 col-md-12 mt-2">
                                <CustomInput
                                    label="Password"
                                    name="password"
                                    type="password"
                                    placeholder="Enter your Password"
                                />
                            </div>
                            <div className="col-lg-6 col-md-12 mt-2">
                                <CustomInput
                                    label="Confirm Password"
                                    name="confirmpassword"
                                    type="password"
                                    placeholder="Confirm Password"
                                />
                            </div>
                            <div className="col-lg-6 col-md-12 mt-2">
                                <label className="frmlabel">Select Industry</label>
                                <Select
                                    isMulti
                                    name="industries"
                                    options={getAllIndustries?.length > 0 && getAllIndustries?.map((val, ind) => { return ({ value: val.id, label: val.title }) })}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(e) => {
                                        SetCompanySign({ ...CompanySign, industries: e }); setFieldValue("industries", `[${e.map((val) => { return (val.value) })}]`)
                                    }
                                    }
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            textAlign: 'left',
                                            minHeight: '45px',
                                            marginTop: "7px",
                                            marginBottom: "5px",
                                            backgroundColor: "#f7f7f7",
                                            border: (touched.industries && errors.industries) ? "1px solid rgb(211,0,0)" : "1px solid #ddd",
                                            color: "#171717"
                                        }),
                                        placeholder: (provided, state) => ({
                                            ...provided,
                                            color: "#333"
                                        }),
                                        singleValue: (provided, state) => ({
                                            ...provided,
                                            color: "#171717"
                                        }),
                                        input: (provided, state) => ({
                                            ...provided,
                                            color: "#171717"
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            textAlign: 'left',
                                            backgroundColor: state.isFocused ? "#1890ff" : "#171717"

                                        })
                                    }}
                                />
                                <div className="ermsg">
                                    {touched.industries && errors.industries && errors.industries}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 mt-2">
                                <label className="frmlabel">Address</label>
                                {
                                    <div className="places-container">
                                        <Combobox onSelect={(address) => { handleSelect(address); setFieldValue("address", address); console.log(address) }}>
                                            <ComboboxInput
                                                id="searchInput"
                                                name="address"
                                                value={value}
                                                onChange={(e) => {
                                                    setValue(e.target.value);
                                                }}
                                                disabled={!ready}
                                                className={`${touched.address && errors.address ? "input-error" : ""} frminp`}
                                                placeholder="Search an address"
                                            />
                                            <ComboboxPopover>
                                                <ComboboxList>
                                                    {status === "OK" &&
                                                        data.map(({ place_id, description }) => (
                                                            <ComboboxOption key={place_id} value={description} />
                                                        ))}
                                                </ComboboxList>
                                            </ComboboxPopover>
                                        </Combobox>
                                    </div>
                                }
                                <div className="ermsg">
                                    {touched.address && errors.address && errors.address}
                                </div>
                            </div>
                            <div className="col-12 mt-2">
                                <CustomCheckbox
                                    label="Make profile visible only for Accredited Investors"
                                    name="is_level_two_profile"
                                    type="checkbox"
                                    id="check1"
                                />
                            </div>
                            <div className="col-12">
                                <CustomCheckbox
                                    label="Accept Terms & Conditions to move further"
                                    name="accept_terms"
                                    type="checkbox"
                                    id="check2"
                                />
                            </div>
                        </div>
                        <div className="col-12 mt-2">
                            <ReCAPTCHA
                                name="captcha"
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                onChange={(token) => { checkcaptcha(token); setFieldValue("captcha", token) }}
                                size={width < 400 ? "compact" : "normal"}
                                style={width < 400 ? { margin: "auto", width: "fit-content" } : {}}
                            />
                            <div className="ermsg">
                                {touched.captcha && errors.captcha && errors.captcha}
                            </div>
                        </div>
                        <button className={`frmsubmit ${signenable ? "" : ""}`} disabled={isSubmitting} type="submit">
                            {
                                isSubmitting ? <Spinner size="md" /> : "Submit"
                            }
                        </button>
                    </Form>
                )}
            </Formik>

            <Modal show={show} onHide={handleClose} backdrop="static" centered closeButton={true}>
                <Modal.Body>
                    <h4 className="text-center mb-3">Hurrah!!!</h4>
                    <p className="text-center">You Registered Successfully. Verification Link has been sent to your email address please check and verify before logging in.</p>

                </Modal.Body>
            </Modal>
        </>
    );
};
export default CompanySignUpForm;