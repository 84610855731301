import React, { useEffect, useLayoutEffect } from "react";
import { useState } from "react";
import { Bar } from "react-chartjs-2";
import { videoViewsApi } from "../../api/companyApis/analyticsApi";
import { Chart as ChartJS } from "chart.js/auto";
import { Card, Spinner } from "react-bootstrap";
import { MdInsertChart } from "react-icons/md";
import ReactApexChart from "react-apexcharts";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

const MediaViewsChart = () => {
  const [data, setData] = useState(null);
  const [spinner, setspinner] = useState(true)

  useEffect(() => {
    videoViewsApi()
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => console.log(error));
  }, [])
  useEffect(() => {
    if (data?.length >= 0) {
      setspinner(false)
    }
    if (data?.length > 0) {
      let root = am5.Root.new("chartdivvideo");

      root._logo.dispose();

      root.setThemes([
        am5themes_Animated.new(root)
      ]);

      let exporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {})
      });

      let chart = root.container.children.push(am5percent.PieChart.new(root, {
        layout: root.verticalLayout
      }));


      let series = chart.series.push(am5percent.PieSeries.new(root, {
        valueField: "view_count",
        categoryField: "title",
        tooltip: am5.Tooltip.new(root, {
          labelText: "[bold]{title}[/]: {view_count}"
        })
      }));

      series.labels.template.setAll({
        visible: false
      });



      series.data.setAll(data);




      chart.appear(1000, 100);

      return () => {
        root.dispose();
      };
    }

  }, [data])
  return (
    <div>
      <Card

        className="chartcard"
      >
        <div id="chart" style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>

          {
            data?.length > 0 ?
              <>
                <h4>Each Video Views</h4>
                <div id="chartdivvideo" className="chrtbox" ></div>
              </>
              :
              spinner ?
                <Spinner animation="border" size="lg" variant="primary" /> :
                <div><h4>No Data Yet</h4></div>
          }
        </div>
      </Card>
    </div>
  );
};


export default MediaViewsChart;
