import React, { useEffect } from "react";
import { useState } from "react";
import { Bar } from "react-chartjs-2";
import { userEachVideoViewsApi } from "../../api/companyApis/analyticsApi";
import { Card, Spinner } from "react-bootstrap";
import { HiChartPie } from "react-icons/hi";
import ReactApexChart from "react-apexcharts";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

const UserEachVideoViewsBarChart = ({ id, state }) => {

  const [data, setData] = useState(null);
  const [spinner, setspinner] = useState(true)


  useEffect(() => {
    userEachVideoViewsApi(id)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => console.log(error));
  }, [])
  useEffect(() => {
    if (data?.length >= 0) {
      setspinner(false)
    }
    if (data?.length > 0) {
      let root = am5.Root.new("chartdivvideo");

      root._logo.dispose();

      root.setThemes([
        am5themes_Animated.new(root)
      ]);

      let exporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {})
      });

      let chart = root.container.children.push(am5percent.PieChart.new(root, {
        layout: root.verticalLayout
      }));


      let series = chart.series.push(am5percent.PieSeries.new(root, {
        valueField: "view_count",
        categoryField: "title",
        legendLabelText: "{category}",
        legendValueText: "{value}",
        tooltip: am5.Tooltip.new(root, {
          labelText: "[bold]{title}[/]: {view_count}"
        })
      }));
     
      series.labels.template.setAll({
        visible: false
      });

      series.data.setAll(data);

    


      chart.appear(1000, 100);

      return () => {
        root.dispose();
      };
    }

  }, [data])
  // useEffect(() => {
  //   if (id) {
  //     userEachVideoViewsApi(id)
  //       .then((res) => setData(res.data.data))
  //       .catch((error) => console.log(error));
  //   }
  // }, [id]);

  return (
    <>
      <Card style={{
        backgroundColor: "#171717",
        minHeight: "250px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
      >
        <div id="chart" style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>

          {
            data?.length > 0 ?
              <>
                <h4>Each Video Views</h4>
                <div id="chartdivvideo" className="chrtbox" style={{ width: "100%", height: "210px" }}></div>
              </>
              :
              spinner ?
                <Spinner animation="border" size="lg" variant="primary" /> :
                <div><h4>No Video Views</h4></div>
          }
        </div>

      
      </Card>
    </>
  );
};

export default UserEachVideoViewsBarChart;
