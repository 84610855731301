import { AntDesignOutlined } from "@ant-design/icons";
import { Avatar, Button } from "antd";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  getUserLevelTwoProfileById,
  getUserProfileBId,
} from "../../../api/companyApis/CompanyProfileApis";
import UserEachVideoViewsBarChart from "../../../component/charts/UserEachVideoViewsBarChart";
import UserVideoViewsLineChart from "../../../component/charts/UserVideoViewsLineChart";
import UserViewsLineChart from "../../../component/charts/UserViewsLineChart";
import { displayErrorToast } from "../../../helper/toast_notification_function";
import UserDetailVideoViewsTable from "../UserDetailVideoViewsTable";
import useWindowDimensions from "../../../component/WindowDimensions";

export const UserDetailsById = () => {
  const [user, setUser] = useState([]);
  const [userLevelTwo, setUserLevelTwo] = useState([]);
  const [name, setName] = useState({ fName: "", lName: "" });
  const { width, height } = useWindowDimensions();


  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [activeKey, setactiveKey] = useState(1);
  let { id } = useParams();

  const handleactive = (e) => {
    setactiveKey(e)
    var tab = document.querySelectorAll(".tbs")
    tab.forEach((element) => {
      element.classList.remove('act');
    });
  }

  const handleGetProfile = () => {
    setLoading(true)
    if (activeKey == 2) {
      getUserLevelTwoProfileById(id)
        .then((response) => {
          setUserLevelTwo(response.data.data);
          setLoading(false);
        })
        .catch((err) => {
          displayErrorToast(err);
          setError(err?.response.data?.message[0]);
          setLoading(false);
        });
    }

    else {
      getUserProfileBId(id)
        .then((response) => {
          setUser([response.data.data]);
          setName({
            fName: response.data.data.first_name,
            lName: response.data.data.last_name,
          });

          setLoading(false);
        })
        .catch((err) => {
          displayErrorToast(err);
          setError(err?.response.data?.message[0]);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    handleGetProfile(false);
  }, [activeKey]);

  // if (loading) {
  //   return (
  //     <>
  //       <div className="container">
  //         <div className="main-body">
  //           <Spinner animation="border" size="lg" variant="primary" />
  //         </div>
  //       </div>
  //     </>
  //   );
  // }

  return (
    <div id="profileUser">
      <div className="container">
        <div className="user_info_box row">
          <div className="profilrAvatar col-lg-2 col-md-3 col-sm-12 mt-3">
            <Avatar
              src={user?.[0]?.profile_picture}
              style={{ verticalAlign: "middle" }}
              icon={<AntDesignOutlined />}
            />
          </div>

          <div className="profileNameDiv col-lg-7 col-md-6 col-sm-12 mt-3 ">
            {/* <p style={{
              background: "gray",
              borderRadius: "0.3rem",
              width: "fit-content",
              padding: "0px 0px 0px 4px",
              display: "inline-flex",
              justifyContent: "space-between",
              color: "white", marginBottom: "8px"
            }}>
              <span> level</span>
              <span style={{
                background: "rgb(53, 182, 255)", padding: "0px 6px", borderTopRightRadius: "0.3rem", borderBottomRightRadius: "0.3rem", marginLeft: "4px"
              }}>
                {user?.[0]?.level_two_profile_status == "valid" ? "2" : "1"}
              </span>
            </p> */}
            <h3 style={{ textTransform: "capitalize" }}>
              {name?.fName ? name?.fName : "-"}{" "}
              {name?.lName ? name?.lName : "-"}
            </h3>
            <h5>{user?.[0]?.level_two_profile_status == "valid" ? "Accredited Investor" : "Unaccredited Investor"}</h5>
          </div>

          <div className="profileAmountDivWrapper col-lg-3 col-md-3 col-sm-12 mt-3  ">
            {/* <div className="profileAmountDiv">
                <h5>Available Cash Balance</h5>
                <h2>US $0.00 </h2>
              </div> */}
            <div className="profileDepositeBtnDiv">
              {/* <Button type="primary">Deposit</Button> */}
              {user[0]?.followed ? (
                <Button
                  type="primary"
                  style={{ backgroundColor: "#fff", color: "#0370ce", borderColor: "#fff", borderRadius: "5px", marginBottom: "15px" }}
                  ghost
                >
                  Followed
                </Button>
              ) : null}
              {user[0]?.interested ? (
                <Button
                  type="primary"
                  style={{ backgroundColor: "red", borderColor: "red", borderRadius: "5px", color: "#fff" }}
                  ghost
                >
                  Interested
                </Button>
              ) : null}
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-12">
            <div className="tabs_user">
              <div className={`tbs ${activeKey == 1 ? "act" : ""}`} onClick={() => { handleactive(1) }}>
                Account Detail
              </div>
              {
                user?.[0]?.level_two_profile_status == "valid" &&
                <div className={`tbs ${activeKey == 2 ? "act" : ""}`} onClick={() => { handleactive(2) }}>
                  Profile Level Two
                </div>
              }
              <div className={`tbs ${activeKey == 3 ? "act" : ""}`} onClick={() => { handleactive(3) }}>
                Analytics
              </div>
            </div>
          </div>
          {
            loading ?
              <div className="main-body">
                <Spinner animation="border" size="lg" variant="primary" />
              </div>
              :
              <>
                <div className="col-12">
                  {
                    activeKey == 1 ?

                      <div>
                        {user.length !== 0 ? (
                          user.map((val, ind) => {
                            return (
                              <div className="card ProfileView userDetails mb-3 rounded-0">
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-sm-5">
                                      <h6 className="mb-0 text-white">First Name</h6>
                                    </div>
                                    <div className="col-sm-7 text-white">
                                      {val?.first_name}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-sm-5">
                                      <h6 className="mb-0 text-white">Last Name</h6>
                                    </div>
                                    <div className="col-sm-7 text-white">
                                      {val?.last_name}
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-sm-4">
                                      <h6 className="mb-0  text-white d-flex justify-content-between">email {width < 575 && val.is_verified ? <span className="verified-tag">Verified</span> : ""}</h6>
                                    </div>
                                    <div className="col-sm-8 text-white d-flex justify-content-sm-end gap-1">
                                      {width > 574 && val.is_verified ? <span className="verified-tag">Verified</span> : ""} <span style={width < 360 ? { fontSize: "14px" } : {}}>{val?.email}</span>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-sm-5">
                                      <h6 className="mb-0 text-white">Country</h6>
                                    </div>
                                    <div className="col-sm-7 text-white">
                                      {val?.country}
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-sm-5">
                                      <h6 className="mb-0 text-white">State</h6>
                                    </div>
                                    <div className="col-sm-7 text-white">
                                      {val?.state}
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-sm-5">
                                      <h6 className="mb-0 text-white">City</h6>
                                    </div>
                                    <div className="col-sm-7 text-white">
                                      {val?.city}
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-sm-5">
                                      <h6 className="mb-0 text-white">Zip Code</h6>
                                    </div>
                                    <div className="col-sm-7 text-white">
                                      {val?.zip}
                                    </div>
                                  </div>

                                </div>
                              </div>
                            );
                          })
                        ) : error ? (
                          <h2 className="d-flex justify-content-center align-items-center color-red fw-bold">
                            {error}
                          </h2>
                        ) : null}
                      </div>
                      :
                      activeKey == 2 ?
                        (userLevelTwo.length !== 0 || null ? (
                          userLevelTwo.map((value, ind) => {
                            return (
                              <div className="card ProfileView mb-3">
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-sm-5">
                                      <h6 className="mb-0 text-white">Name Of Investor</h6>
                                    </div>
                                    <div className="col-sm-7 text-white">
                                      {userLevelTwo[0]?.name_of_investor}
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-sm-5">
                                      <h6 className="mb-0 text-white">Investor Type</h6>
                                    </div>
                                    <div className="col-sm-7 text-white">
                                      {userLevelTwo[0]?.investor_type}
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-sm-5">
                                      <h6 className="mb-0 text-white">Address</h6>
                                    </div>
                                    <div className="col-sm-7 text-white">
                                      {userLevelTwo[0]?.address}
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-sm-5">
                                      <h6 className="mb-0 text-white">Contact Number</h6>
                                    </div>
                                    <div className="col-sm-7 text-white">
                                      {user?.[0]?.is_phone_verified && <span className="verified-tag">Verified</span>} {userLevelTwo[0]?.contact_number}
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-sm-5">
                                      <h6 className="mb-0 text-white">Date Signed</h6>
                                    </div>
                                    <div className="col-sm-7 text-white">
                                      {new Date(userLevelTwo[0]?.date_signed).getDate()}-{new Date(userLevelTwo[0]?.date_signed).getMonth() - 1}-{new Date(userLevelTwo[0]?.date_signed).getFullYear()}
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-sm-5">
                                      <h6 className="mb-0 text-white">Name of Signing Party</h6>
                                    </div>
                                    <div className="col-sm-7 text-white">
                                      {userLevelTwo[0]?.name_of_signing_party}
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-sm-5">
                                      <h6 className="mb-0 text-white">Title of Signing Party</h6>
                                    </div>
                                    <div className="col-sm-7 text-white">
                                      {userLevelTwo[0]?.title_of_signing_party}
                                    </div>
                                  </div>

                                </div>
                              </div>
                            );
                          })
                        ) : error ? (
                          <h1>{error}</h1>
                        ) : null) :
                        activeKey == 3 ?
                          <div className=" mb-3">
                            <div className="row">
                              <div className="col-lg-6 col-12">
                                <UserViewsLineChart id={id} />
                              </div>
                              <div className="col-lg-6 col-12 mt-4 mt-lg-0">
                                <UserVideoViewsLineChart id={id} />
                              </div>
                              <div className="col-lg-4 col-md-12 col-12 mt-4">
                                <UserEachVideoViewsBarChart id={id} />
                              </div>
                              <div className="col-lg-8 col-md-12 col-12 mt-4">
                                <UserDetailVideoViewsTable id={id} />
                              </div>
                            </div>
                          </div>
                          : ""
                  }
                </div>
              </>
          }

          {/* <div className="col-12 mt-3">
            <h4 style={{ fontWeight: "bold", fontFamily: 'sansation' }}>
              Finish setting Up Yours Investor Account
            </h4>
            <p>
              In publishing and graphic design, Lorem ipsum is a placeholder
              text commonly used to demonstrate the visual form of a document or
              a typeface without relying on meaningful content. Lorem ipsum may
              be used as a placeholder before final copy is available. It is
              also used to temporarily replace text in a process called
              greeking, which allows designers to consider the form of a webpage
              or publication...
            </p>
          </div> */}
        </div>
      </div>
    </div >
  );
};
