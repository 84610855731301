import { Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import {
  deleteTeamMemberApi,
  getTeamMemberApi,
} from "../../../../api/companyApis/portfolio/team/teamApi";
import AddGallery from "./AddGallery";
import { deleteGalleryByIdApi, getGalleryApi } from "../../../../api/companyApis/portfolio/galleryAndMedia/galleryAndMedia";

const Table = ({ flag }) => {
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [single, setSingle] = useState(null);

  const handleToggle = () => {
    setVisible((prev) => !prev);
  };


  const onDelete = (id) => {
    deleteGalleryByIdApi(id)
      .then((res) => {
        getGalleryApi()
          .then((res) => {
            setData(res.data.data);
          })
          .catch((error) =>
            console.log("Portfolio gallery media", error.response)
          );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onEdit = (id) => {
    if (id) {
      getGalleryApi(id)
        .then((res) => {
          setSingle(res.data?.data[0]);
          console.log(res.data)
          setVisible(true);
        })
        .catch((error) => console.log("Portfolio team member", error.response));
    }
  };

  useEffect(() => {
    setLoading(true);
    getGalleryApi()
      .then((res) => {
        setLoading(false);
        setData(res.data.data);
      })
      .catch((error) => console.log("Portfolio team member", error.response));
  }, [flag, trigger]);

  if (isLoading) {
    return (
      <>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: 400, width: "100%" }}
        >
          <div className="container">
            <div className="d-flex align-items-center justify-content-center">
              <Spinner animation="border" size="lg" variant="primary" />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div>
      {/* <div className="d-flex justify-content-between align-items-center my-3 ">
        <h3>Views With Profile</h3>
        <div>
          <Button variant="outline-primary">
            <CSVLink style={{ color: "inherit" }} data={data} headers={headers}>
              Download
            </CSVLink>
          </Button>
        </div>
      </div> */}

      <AddGallery
        isOpen={visible}
        handleToggle={handleToggle}
        toggleState={() => setTrigger((prev) => !prev)}
        data={single}
        state={"update"}
      />
      {/* <Paper>
        <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={data}
            columns={columns}
            getRowId={(row) => row.id}
            rowsPerPageOptions={[10, 20, 30]}
            checkboxSelection
            disableSelectionOnClick
            rowHeight={80}
            // experimentalFeatures={{ newEditingApi: true }}
          />
        </Box>
      </Paper> */}
      {
        data.map((data) => {
          return (
            <div className="companyGallery">
              <Row>
                <Col xl={3} lg={3} md={5} sm={12} xs={12}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Title</Form.Label>
                    <Form.Control type="text"  value={data.heading} disabled />
                  </Form.Group>
                  {/* <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label>Job</Form.Label>
                    <Form.Control type="text" value={data.designation} disabled />
                  </Form.Group> */}
                </Col>
                <Col xl={5} lg={5} md={7} sm={12} xs={12}>
                  <Form.Group className="h-100" controlId="formBasicEmail">
                    <Form.Control as="textarea"  className="h-100 w-100 txtfld" value={data.text} disabled />
                  </Form.Group>
                </Col>
                <Col className="d-flex align-items-center" xl={3} lg={3} md={6} sm={12} xs={12}>
                  <img src={data.image} />
                </Col>
                <Col xl={1} lg={1} md={5} sm={12} xs={12} className="d-flex justify-content-center align-items-center flex-column">
                  <button className="btntm" onClick={() => onEdit(data.id)}>
                    <img src="/images/edt.svg" />
                  </button>
                  <Popconfirm
                    title="Are you sure to delete this Member?"
                    onConfirm={() => onDelete(data.id)}
                  >
                    <button className="btntm">
                      <img src="/images/dlt.svg" />
                    </button>
                  </Popconfirm>
                </Col>
              </Row>
            </div>
          )
        })
      }
    </div>
  );
};

export default Table;
