 // =============================== featured ACTIVE SHEDULE APIS=========================================

import { apiHandle } from "../apiHandler";

 export const featuredActiveScheduleApi = (data) => {
    return apiHandle().post(`/company/auth/featuredListing`,data);
  };
  


  export const featuredActiveChargeRatePerDayApi = () => {
    return apiHandle().get(`/company/auth/featuredListing/chargeRate`);
  };
  


  export const getFeaturedMinimumDays = () => {
    return apiHandle().get("/company/auth/featuredListing/minimumDays");
  };
  


  