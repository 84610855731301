import {
  followCompanyApi,
  intrestedCompanyApi,
} from "../../api/companyInteractionsApis";
import { displayWorrningToast } from "../../helper/toast_notification_function";

export const handleCompanyFollow = (id, setFollow, setProcessing, setLoadingbtn) => {

  setProcessing(true);
  setLoadingbtn(true);
  followCompanyApi(id)
    .then((res) => {
      if (res?.data?.message?.[0] == "Oops! It appears you've reached the limit for today.We appreciate your interest and encourage you to engage with us through other means.Thank you for your support and understanding.") {
        displayWorrningToast(res?.data?.message?.[0])
      } else {
        setFollow((prev) => !prev);

      }
      setProcessing(false);
      setLoadingbtn(false);
    })
    .catch((error) => {
      setLoadingbtn(false);
      setProcessing(false);
      setFollow((prev) => !prev);
    });
};



export const handleIntrestedCompany = (id, setIntrested, setProcessing, setLoadingbtnInt) => {
  setProcessing(true);
  setLoadingbtnInt(true);
  intrestedCompanyApi(id)
    .then((res) => {
      if (res?.data?.message?.[0] == "Oops! It appears you've reached the limit for today.We appreciate your interest and encourage you to engage with us through other means.Thank you for your support and understanding.") {
        displayWorrningToast(res?.data?.message?.[0])
      } else {
        setIntrested((prev) => !prev);

      }
      setProcessing(false);
      setLoadingbtnInt(false);
      // console.log(res?.data?.message?.[0])

    })
    .catch((error) => {
      console.log(error)
      setLoadingbtnInt(false);
      setProcessing(false);
      setIntrested((prev) => !prev);
    });
};
