import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card, Row, Col } from "antd";
import img from "../../images/explore1.png";
import image2 from "../../images/slider.jpg";

import TopSection from "../Common/TopSection";
import { PublicGetIndustriesApi } from "../../api/Public_Apis";
import { displayErrorToast } from "../../helper/toast_notification_function";
import { useNavigate } from "react-router-dom";

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <i className="fa fa-chevron-left" />
    </div>
  );
};

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <i className="fa fa-chevron-right" />
    </div>
  );
};

const WhyToSignUpSlider = () => {
  const [data, setData] = useState([])
  const navigate = useNavigate()
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    arrow: true,
    arrowPosition: "bottom",
    responsive: [
      {
        breakpoint: 1030,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const data1 = [
    {
      title: "No more expensive travel to gather investor leads at a show!   ",
      image: image2,
    },
    {
      title: "Download your leads from your Company Dashboard daily!",
      image: img,
    },
    {
      title: "No more expensive travel to gather investor leads at a show!   ",
      image: image2,
    },

    {
      title: "Download your leads from your Company Dashboard daily!",
      image: img,
    },
    {
      title: "No more expensive travel to gather investor leads at a show!   ",
      image: image2,
    },
    {
      title: "Download your leads from your Company Dashboard daily!",
      image: img,
    },
  ];



  const getIndustries = () => {
    PublicGetIndustriesApi().then((res) => {
      if (res?.data?.success) {
        setData(res?.data?.data)
      }
    }).catch((err) => displayErrorToast(err))
  }
  useEffect(() => {
    getIndustries()
  }, [])

  return (
    <>
      <section id="investor_slider_sec" className="sectionSpacing">
        <h3 className="pt-sm-5 pt-1 pb-3 text-white fw-bold text-center">
          All Sectors Welcome!
        </h3>
        <p className="text-center text-white pb-4">If your sector is not listed, just reach out to us and it shall be done!</p>
        <div className="col-12 col-md-12 mx-auto py-3">
          <Slider {...settings}>
            {data?.length > 0 ? (

              data.map((item, index) => (
                <div className="investorSliderWrapper" key={index}>
                  <div
                    className="investorSlider invest_slider_relative"
                  >
                    <img
                      alt="example"
                      src={`${process.env.REACT_APP_HOME_URL}${item.thumbnail}`}
                      // src={process.env.REACT_APP_HOME_URL+item.thumbnail}
                      className="investorSliderImg"
                    />
                    <div>
                      <p>
                        {item.title}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            )
              :
              null
            }
          </Slider>
        </div>
      </section>

      <section id="howMuchDoesItCost " className="my-sm-5 my-0 py-5">
        <div className="bg">
          <span className="blackBg"></span>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 col-11">
                <h2 className="text-white fw-bold py-3">
                  How Much Does It Cost?
                </h2>
                <p className="text-white lh-base">
                  Get exposure on our platform for as little as $99 a day! You can also upgrade to things such as Home Page Listings, downloadable leads and more!
                </p>
                <button className="gradientBtn mt-3" onClick={() => navigate('/signup', { state: { data: "company" } })}  >Sign Up</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyToSignUpSlider;
