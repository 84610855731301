import React, { useLayoutEffect } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import CompanyRegister from "../component/SignComplay&User/CompanySignUp";
import User from "../component/SignComplay&User/UserSignUp";
import SignUpCompany from "./SignUpCompany";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CompanySignUpForm from "./company/CompanySignUpForm";
import InvestorSignUpForm from "./user/InvestorSignUpForm";
export default function SignUp() {
  const navigate = useNavigate();
  const { appState, isAppStateLoadig, error } = useSelector(
    (state) => state.AppStateReducer
  );
  const [view, setView] = useState("invest");
  useLayoutEffect(() => {
    if (appState == "USER") {
      navigate("/user/company");
    } else if (appState == "COMPANY") {
      navigate("/company/dashboard");
    }
  }, [appState]);

  const [state, setState] = useState("companyCornerRadius");
  const tabsChange = (e) => {
    if (e == "company") {
      return setState("companyCornerRadius");
    } else if (e == "user") {
      return setState("userCornerRadius");
    }
  };
  const location = useLocation();
  const data = location;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="bg-img">
      <div className="container py-md-5 h-100">
        <div className="frmsign">
          <div className="tabsfrm">
            <h3
              onClick={() => setView("company")}
              className={view === "company" ? "act" : ""}
            >
              Post a Presentation
            </h3>
            <h3
              onClick={() => setView("invest")}
              className={view === "invest" ? "act" : ""}
            >
              Open An Investor Account
            </h3>
          </div>
          <div className="frmmain">
            {view === "invest" ? <InvestorSignUpForm /> : <CompanySignUpForm />}
          </div>
        </div>
        {/* <div className="d-flex flex-column h-100 justify-content-center align-items-center mx-auto col-12 col-md-8">
          <div className="signUpMainContent">
            <Tabs
              defaultActiveKey={
                location?.state?.data ? location?.state?.data : "user"
              }
              id="uncontrolled-tab-example"
              className={state}
              onSelect={(e) => tabsChange(e)}
            >
              <Tab
                eventKey="company"
                defaultChecked
                title="Post a Presentation"
              >
                <CompanyRegister />
              </Tab>
              <Tab eventKey="user" title="Open an investor Account">
                <User />
              </Tab>
            </Tabs>
          </div>
        </div> */}
      </div>
    </section>
  );
}
