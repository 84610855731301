import React, { useEffect, useState } from "react";
import { Form, FormGroup } from "react-bootstrap";
import { featuredActiveChargeRatePerDayApi } from "../../../api/companyApis/featuredApi";
import { DateRange } from "../../../component/payments/DateRange";
import {
  displayErrorToast,
  displayWorrningToast,
} from "../../../helper/toast_notification_function";
import { featuredScheduleAction } from "../../../redux/actions/creditCardActions";
import useWindowDimensions from "../../../component/WindowDimensions";

export const Featured = ({ checkBoxValue, changedate, disabledate, type, sameDateRange, promoResDetails }) => {
  const [dateRange, setDateRange] = useState([]);
  const [daysBetween, setDaysBetween] = useState(0);
  const [finalAmount, setFinalAmount] = useState();
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [discount, setDiscount] = useState([])

  const [minimumdays, setminimumdays] = useState(null);
  const [perDayCharge, setPerDayCharge] = useState();
  const [checkval, setcheckval] = useState(false)
  const { width, height } = useWindowDimensions();



  // const featutredpay = () => {
  //   let startDate = dateRange[0];
  //   let endDate = dateRange[1];

  //   if (startDate && endDate) {
  //     let obj = {
  //       start_date: startDate,
  //       end_date: endDate,
  //     };
  //     if (daysBetween >= minimumdays) {

  //       setLoading(true);
  //       setIsDisable(true);
  //       featuredScheduleAction(
  //         obj,
  //         setDateRange,
  //         setLoading,
  //         setFinalAmount,
  //         setIsDisable
  //       );
  //     } else {
  //       displayWorrningToast(`Minimum days for make payment is ${minimumdays}`);
  //     }
  //   }
  // };

  const getFeaturedChargeRatePerDAy = () => {
    featuredActiveChargeRatePerDayApi()
      .then((res) => {
        if (res?.data?.success) {
          setPerDayCharge(res?.data?.data?.[0]?.charge_rate_per_day);
        }
      })
      .catch((err) => {
        displayErrorToast(err);
      });
  };
  useEffect(() => {
    let filter = promoResDetails?.filter((item) => { return item.type_id == type?.id })
    setDiscount(filter)

  }, [promoResDetails])

  useEffect(() => {
    if (!(sameDateRange?.length > 0) && !(dateRange?.length > 0)) {
      setDaysBetween(0)
    }
  }, [sameDateRange])
  useEffect(() => {
    // getFeaturedMinimumDays().then((res) => {
    //   console.log(res.data)
    //   setminimumdays(res.data?.data[0].minimum_days_allowed);
    // })
    //   .catch((error) => displayErrorToast(error));

    setminimumdays(type?.minimum_days)
  }, [type]);


  useEffect(() => {
    getFeaturedChargeRatePerDAy();
  }, []);



  useEffect(() => {
    setcheckval(checkBoxValue)
    setIsDisable(!checkBoxValue)
  }, [checkBoxValue])

  useEffect(() => {
    if (checkval) {
      if (minimumdays != undefined && minimumdays != null && daysBetween > 0) {
        if (daysBetween >= minimumdays) {
          changedate("featured_date", dateRange, "total_feat", daysBetween * type?.charges_per_day)
        }
        else {
          displayWorrningToast(`Minimum days for make payment is: ${minimumdays}`)
          setDateRange([])
        }
      }
    }
    else {
      changedate(
        "featured_date",
        [],
        "total_feat",
        0
      );
    }
  }, [daysBetween])

  useEffect(() => {
    if (dateRange == undefined) {
      setDaysBetween(0)
      changedate(
        "featured_date",
        [],
        "total_feat",
        0
      );
    }
  }, [dateRange])


  function handlecheck(e) {
    setcheckval(!checkval)
    if (e.target.checked) {
      changedate(e.target.name, parseInt(e.target.value), "total_feat", daysBetween * type?.charges_per_day)
      setIsDisable(false)
    }
    else {
      changedate(e.target.name, 0, "total_feat", 0)
      setDateRange([null, null])
      setDaysBetween(0);
      setIsDisable(true)
    }
  }

  return (
    <div id="paymentsPage">
      {/* <div className="PaymentsPage_wrapper ">
        <div className="toggle_box d-flex align-items-center row">

          <div className="col-lg-1 col-md-2 col-6 d-flex justify-content-md-center align-items-center">
            <FormGroup>
              <Form.Check
                type={"checkbox"}
                id="presentatio-checkbox12"

                onChange={(e) => { handlecheck(e) }}
                checked={checkval}
                value={type?.id}
                name="featured"
              // onChange={(e) => {
              //   SetCompanySign({
              //     ...CompanySign,
              //     is_level_two_profile: e.target.checked,
              //   });
              // }}
              />
            </FormGroup>
          </div>


          <div className="common_dateRange col-lg-7 col-md-6 col-sm-12 mt-md-0 mt-2 order-md-2 order-3">
            <h4 className="col-12 mb-3 mt-2 ">{type?.title} $ {type?.charges_per_day}/day</h4>
            <div className={disabledate ? "opacityDown" : "opacityUp"}>
              <DateRange
                disabled={disabledate ? disabledate : isDisable ? isDisable : false}

                disablePastDate={true}

                setDateRange={(value) => {
                  setDateRange(value);
                  setIsDisable(false);
                }}
                dateRange={sameDateRange?.length > 0 ? sameDateRange : dateRange}
                setDaysBetween={setDaysBetween}
                daysBetween={daysBetween}
                setFinalAmount={setFinalAmount}
                paymentPerDay={perDayCharge}
              />
            </div>
          </div>

          <div className="common_toggle col-lg-4 col-md-4 col-6 order-md-3 order-2">
            <div className="charge_rate" style={{ fontSize: '18px' }}>Total Days: {daysBetween ? daysBetween : "0"}</div>
            <div className="charge_rate" style={{ fontSize: '18px' }}>Total: <span style={{ fontSize: "16px" }}>$ </span>{(sameDateRange?.length > 0 || dateRange?.length > 0) ? daysBetween ? discount?.length > 0 ? <><span style={{ fontSize: "18px" }}>{((daysBetween * type?.charges_per_day) - (discount?.[0].discount))}</span> <del style={{ fontSize: "18px", color: 'rgba(255, 255, 255, .7)' }}>{daysBetween * type?.charges_per_day}</del></> : <span style={{ fontSize: "18px" }}>{daysBetween * type?.charges_per_day}</span> : "0" : "0"}</div>

          </div>
        </div>
       
      </div> */}




      <div className=" row ">
        <div className=" col-md-9 paymentRows">
          <div className="common_dateRange flex-md-row flex-column d-flex gap-2">
            {width > 767 &&

              <div className="ps-1 mt-2">
                <FormGroup>
                  <Form.Check
                    type={"checkbox"}
                    id="presentatio-checkbox12"
                    onChange={(e) => { handlecheck(e) }}
                    checked={checkval}
                    value={type?.id}
                    name="featured"
                  />
                </FormGroup>
              </div>
            }

            <div className="col-md-11 col-12">
              <div className=" col-12 d-flex justify-content-between align-items-center flex-wrap">
                <div className="d-flex align-items-start gap-2">
                  {width < 767 &&

                    <FormGroup>
                      <Form.Check
                        type={"checkbox"}
                        id="presentatio-checkbox12"
                        onChange={(e) => { handlecheck(e) }}
                        checked={checkval}
                        value={type?.id}
                        name="featured"
                      />
                    </FormGroup>
                  }

                  <h4 className=" mb-3 mt-md-2 paymentHead">
                    {type?.title} $ {type?.charges_per_day}/day{" "}
                  </h4>
                </div>
                <div className={disabledate ? "opacityDown" : "opacityUp"}>
                  <DateRange
                    disabled={disabledate ? disabledate : isDisable ? isDisable : false}

                    disablePastDate={true}

                    setDateRange={(value) => {
                      setDateRange(value);
                      setIsDisable(false);
                    }}
                    dateRange={sameDateRange?.length > 0 ? sameDateRange : dateRange}
                    setDaysBetween={setDaysBetween}
                    daysBetween={daysBetween}
                    setFinalAmount={setFinalAmount}
                    paymentPerDay={perDayCharge}
                  />
                </div>
              </div>
              <p className="text-secondary mt-1">{type?.description}</p>
            </div>
          </div>

        </div>


        <div className=" common_dateRange col-md-3 mt-3 mt-md-0 d-flex justify-content-center flex-column align-items-center">
          <div className="charge_rate text-white" style={{ fontSize: '18px' }}>Total Days: {daysBetween}</div>
          <div className="charge_rate text-white" style={{ fontSize: '18px' }}>Total: <span style={{ fontSize: "16px" }}>$ </span>{(sameDateRange?.length > 0 || dateRange?.length > 0) ? daysBetween ? discount?.length > 0 ? <><span style={{ fontSize: "18px" }}>{((daysBetween * type?.charges_per_day) - (discount?.[0].discount))}</span> <del style={{ fontSize: "18px", color: 'rgba(255, 255, 255, .7)' }}>{daysBetween * type?.charges_per_day}</del></> : <span style={{ fontSize: "18px" }}>{daysBetween * type?.charges_per_day}</span> : "0" : "0"}</div>

          {/* <div className="charge_rate mt-2 mt-sm-0">Discounted Price: {discount?.length > 0 ? discount?.[0].discount : ""}</div> */}
        </div>
      </div>
    </div>

  );
};

