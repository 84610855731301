import { apiHandle } from "./apiHandler";


export const Public_contact = (formData) => {
  return apiHandle(false).post("/public/contact", formData);
};



export const PublicGetIndustriesApi = () => {
  return apiHandle(false).get("/public/industry");
};
export const PublicGetCompaniesApi = () => {
  return apiHandle(false).get("/public/company");
};

export const RecaptchaApi = (token) => {
  return apiHandle(false).post(`https://www.google.com/recaptcha/api/siteverify?secret=${process.env.REACT_APP_RECAPTCHA_SECRET_KEY}&response=${token}`)
}
