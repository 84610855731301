import React from "react";
import { Col, Row } from "react-bootstrap";
import UserViewsTable from "../../component/dashboard/UserViewsTable";
import IntrestedUserTable from "../../component/data Table/IntrestedUserTable";
import UsersWithVideoViews from "../../component/data Table/UsersWithVideoViews";

const UserTables = () => {
  return (
    <>
      <div className="topSectionImgWrapper">
        <h1 className="text-white fw-bold"> Investor Leads</h1>
        <p className="text-center text-white col-md-10 col-12 descpay">
          Thank you for being a part of the InvestorPresentations.com community!
          Below are the Investors and Accredited Investors Leads, from
          interactions with your Digital Booth. We don't screen the leads before
          giving them to you. Our platform sends these Investor Leads directly
          to your Dashboard for instant and unlimited downloads, so there may be
          duplicates. Please utilize our "Download All" Feature, to export your
          Investor Leads for better organization
        </p>
      </div>
      <div className="bgDropBlur mx-2 mx-md-4">
        <>
          <Row>
            <Col>
              <UserViewsTable />
            </Col>
          </Row>
        </>

        <>
          <Row>
            <Col>
              <IntrestedUserTable />
            </Col>
          </Row>
        </>

        <Row>
          <Col>
            <UsersWithVideoViews />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UserTables;
