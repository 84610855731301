import { Category } from "@mui/icons-material";
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import ProductSlider from "../component/product/productSlider";
import img from "../images/slider.jpg";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { getCompanyIndustriesApi } from "../api/getIndustriesByIdApi";
import { displayErrorToast } from "../helper/toast_notification_function";
import Slider from "react-slick";
import { Spinner } from "react-bootstrap";
import { Select as Antsel } from "antd";
import { PublicGetIndustriesApi } from "../api/Public_Apis";
import logo from "../images/IpLogo.png";

import thumbnail from "../images/no_image.jpg";
import ExploreCards from "../component/Common/ExploreCards";

export default function Product() {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  let infinite = filterData?.length > 3 ? true : false

  const settings = {
    dots: true,
    infinite: infinite,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 1000,
    arrow: true,
    arrowPosition: "bottom",
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      }
    ]
  };
  const getIndustries = () => {
    setIsLoading(true)
    getCompanyIndustriesApi()
      .then((res) => {
        if (res?.data?.success) {
          setData(res?.data?.data);
          setFilterData(res?.data?.data);
          console.log(res?.data?.data, "data")
          setIsLoading(false)
        }
      })
      .catch((err) => {
        setIsLoading(false)

        displayErrorToast(err);
      });

  };

  const handleData = (title) => {
    console.log(title)

    const section = document.getElementById(`${title}`);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    // navigate(`/product#${title}`)
  }

  useEffect(() => {
    getIndustries();
  }, []);


  if (isLoading) {
    return (
      <>
        <div className="container">
          <div className="main-body">
            <Spinner animation="grow" size="lg" variant="primary" />
          </div>
        </div>
      </>
    );
  }

  return (
    <div style={{ background: "black" }}>
      <section id="product">
        <div className="heroWrapper">
          <div className="hero_inner">
            <h1 className="commonH2">Thanks, For Joining</h1>
            <p className="banner-sec-text text-center">
              Use the Quick Search Feature to easily find a company or investment sector of interest. Or scroll down to video investment presentations to explore further!
            </p>
            <div className="email_section productSelectDiv">
              <Antsel
                allowClear
                className="signUpSelect"
                style={{ width: "100%", borderRadius: "10px" }}
                placeholder="Select Industries Type"
                name="industries"
                // className="form-select"
                // defaultValue={}
                id="industryDropdown"
                // onBlur={(e) =>
                //   SetCompanySign({
                //     ...CompanySign,
                //     industries: JSON.stringify(
                //       CompanySign.industries.map((value) => Number(value))
                //     ),
                //   })
                // }
                onChange={(e) => {

                  handleData(e)
                }
                }
                options={
                  data.length !== 0
                    ? data.map((val, ind) => {
                      return { value: val?.title, label: val?.title };
                    })
                    : []
                }
              />
              {/* <button className="banner-btn productBtn">
                <span>
                Start Rising 
                  </span><FiArrowRight className="rightArrow" />{" "}
              </button> */}
            </div>

          </div>
        </div>
      </section >

      <div className="row">
        <div className="productSlider gap-3">
          {data?.length > 0 && (
            <Slider {...settings} className="container">
              {data.map((item, index) => (
                <React.Fragment key={index}>
                  <div
                    className="investorSliderWrapper mt-3"
                  >
                    <AnchorLink href={`#${item?.title}`}>
                      {console.log(item.title)}
                      <div className="investorSlider invest_slider_relative">
                        <img
                          alt="example"
                          // src={img}
                          src={process.env.REACT_APP_HOME_URL + item?.thumbnail}

                          className="investorSliderImg"
                        />
                        <div className="">
                          <p>{item.title}</p>
                        </div>
                      </div>
                    </AnchorLink>
                  </div>
                </React.Fragment>
              ))}
            </Slider>
          )}
        </div>
      </div>

      <div className="container mt-5">
        {data.map((val, ind) => {
          return (
            <React.Fragment key={ind}>
              <h2 className="text-white fw-bold pt-5 mt-3 text-center p-3 " style={{ fontFamily: 'sansation' }}>
                {val?.title}
              </h2>
              {
                val?.companies.length > 0 ? <ProductSlider data={val ? val : {}} /> :
                  <div className="no_comp_data">
                    <div className="blur_box">
                      <h3>No Companies Live Yet</h3>
                    </div>
                    <div className="row p-3">
                      <div className="col-lg-4 col-md-6 col-sm-12 d-none d-md-flex">
                        <ExploreCards
                          isInterested={true}
                          isFollowed={true}
                          logo={logo}
                          id={data?.id}
                          title={"XYZ Company"}
                          desc={"Company description"}
                          total_investment={"999,999"}
                          minimum_investment={"999,999"}
                          state={"Florida"}
                          country={"United States"}
                          start_date={data.active_schedule_start_date ? data.active_schedule_start_date : ""}
                          end_date={data.active_schedule_end_date ? data.active_schedule_end_date : ""}
                          img={data?.primary_video_thumbnail ? data.primary_video_thumbnail : thumbnail}
                          isLevelTwo={data?.is_level_two_profile}
                          onClick={() => { }}
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 d-none d-lg-flex">
                        <ExploreCards
                          isInterested={true}
                          isFollowed={true}
                          logo={logo}
                          id={data?.id}
                          title={"XYZ Company"}
                          desc={"Company description"}
                          total_investment={"999,999"}
                          minimum_investment={"999,999"}
                          state={"Florida"}
                          country={"United States"}
                          start_date={data.active_schedule_start_date ? data.active_schedule_start_date : ""}
                          end_date={data.active_schedule_end_date ? data.active_schedule_end_date : ""}
                          img={data?.primary_video_thumbnail ? data.primary_video_thumbnail : thumbnail}
                          isLevelTwo={data?.is_level_two_profile}
                          onClick={() => { }}
                        />
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <ExploreCards
                          isInterested={true}
                          isFollowed={true}
                          logo={logo}
                          id={data?.id}
                          title={"XYZ Company"}
                          desc={"Company description"}
                          total_investment={"999,999"}
                          minimum_investment={"999,999"}
                          state={"Florida"}
                          country={"United States"}
                          start_date={data.active_schedule_start_date ? data.active_schedule_start_date : ""}
                          end_date={data.active_schedule_end_date ? data.active_schedule_end_date : ""}
                          img={data?.primary_video_thumbnail ? data.primary_video_thumbnail : thumbnail}
                          isLevelTwo={data?.is_level_two_profile}
                          onClick={() => { }}
                        />
                      </div>
                    </div>
                  </div>
              }

            </React.Fragment>
          );
        })}
      </div>
    </div >
  );
}

