import { Avatar } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import logo from "../../images/IpLogo.png";
import { handleGetCompanyPersonalInfo, handleGetUserPersonalInfo } from "../../pages/user/Company";
import { LogoutHandle } from "../../redux/actions/authActions";

const NavbarNew = () => {

  const { appState } = useSelector(
    (state) => state.AppStateReducer
  );

  let local_State = localStorage.getItem("IP_State");


  const { getProfile } = useSelector(
    (state) => state.getUserProfileReducer
  )

  // const [isExpanded, setIsExpanded] = useState(false);
  const [collapse, setcollapse] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();
  // const navigate = useNavigate();


  const [style, setStyle] = useState();


  useEffect(() => {
    if (appState && local_State == "USER") {

      handleGetUserPersonalInfo(dispatch)
    }

    else if (appState && local_State == "COMPANY") {

      handleGetCompanyPersonalInfo(dispatch)
    }

    if (typeof window !== "undefined") {
      window.addEventListener(
        "scroll",
        () => setStyle(window.scrollY > 2)
        // setNews(true)
      );
    }
  }, []);


  useEffect(() => {
    setcollapse(false)
    // handleGetCompanyPersonalInfo(dispatch)

  }, [location])

  return (
    <>
      {appState === null ?
        <nav id="mynav" class={`${style ? "scr" : ""}`}>
          <div class="wrapper">
            <div class="logo"><Link to={appState == null ? "/" : "#"}>
              <img
                src={logo}
                className="myndl"
                alt="investor presentations"
              />
            </Link></div>
            <input type="radio" name="slider" id="menu-btn" checked={collapse} onClick={() => { setcollapse(true) }} />
            <input type="radio" name="slider" id="close-btn" />
            <ul class="nav-links">
              <label for="close-btn" class="btn close-btn"><i class="fas fa-times"></i></label>

              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              <li className="mbbtn">
                <Link
                  to="/signup"
                  className={`nav-link rounded signUpBtnSmScreen ${style ? "signUpBtnScroll" : "signUpBtnFixed"
                    }`}
                  role="button"
                >
                  Sign Up
                </Link>
              </li>
              <li className="mbbtn">
                <Link
                  to="/login"
                  className="main-btn rounded loginBtnNavbar"
                >
                  Login
                </Link>
              </li>
            </ul>
            <div className="btns-nv">
              <Link
                to="/signup"
                className={`nav-link rounded signUpBtnSmScreen ${style ? "signUpBtnScroll" : "signUpBtnFixed"
                  }`}
                role="button"
              >
                Sign Up
              </Link>
              <Link
                to="/login"
                className="main-btn rounded loginBtnNavbar"
              >
                Login
              </Link>
            </div>
            <label for="menu-btn" class="btn menu-btn"><i class="fas fa-bars"></i></label>
          </div>
        </nav > :
        <nav id="mynav" class={`${style ? "scr" : ""}`}>
          <div className="wrapper">
            <div class="logo">
              <Link to={appState == null ? "/" : "#"}>
                <img
                  src={logo}
                  className="myndl"
                  alt="investor presentations"
                />
              </Link>
            </div>
            {appState === "USER" ? (
              <>
                {/* {
                location.pathname !== "/product" &&
                <div>
                  <Link to={"/product"}>
                    <button className="videoLibrayBtn">Video Library</button>
                  </Link>
                </div>
              } */}
                <div className="nav-item dropdown p-relative r-5">
                  <a
                    className="nav-link dropdown-toggle"
                    href=""
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ border: "none !important" }}
                  >
                    {/* <img width="40" src={icon} style={{ filter: "invert(1)" }}/> */}
                    <div className=" userProfileNavbar">
                      <span className="text-white usertitle">{getProfile?.full_name}</span>

                      <Avatar
                        src={getProfile?.profile_picture}
                        style={{ verticalAlign: "middle" }}
                        size={{
                          xs: 35,
                          sm: 35,
                          md: 35,
                          lg: 35,
                          xl: 35,
                          xxl: 35,
                        }}
                      />
                    </div>
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    {/* <li className="nav-item">
                      <Link
                        to="/user/company"
                        className="dropdown-item rounded text-black"
                      >
                        Companies
                      </Link>
                    </li> */}
                    <li>
                      <Link className="dropdown-item" to="/user/company">
                        Dashboard
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/"
                        onClick={() => LogoutHandle(dispatch)}
                      >
                        Logout
                      </Link>
                    </li>
                  </ul>
                </div>
              </>
            ) : (
              <div className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href=""
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ border: "none !important" }}
                >
                  {/* <img width="40" src={icon} style={{ filter: "invert(1)" }}/> */}

                  <div className=" userProfileNavbar">
                    <span className="text-white usertitle">{getProfile?.company_name}</span>

                    <Avatar
                      src={getProfile?.logo}
                      style={{ verticalAlign: "middle" }}
                      size={{
                        xs: 35,
                        sm: 35,
                        md: 35,
                        lg: 35,
                        xl: 35,
                        xxl: 35,
                      }}

                    />
                  </div>

                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" to="/company/dashboard">
                      Dashboard
                    </Link>
                  </li>
                  {/* <li>
                    <Link className="dropdown-item" to="/Profile">
                      Profile
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/"
                      onClick={() => LogoutHandle(dispatch)}
                    >
                      Logout
                    </Link>
                  </li>
                </ul>
              </div>
            )}

          </div>
        </nav>


      }
    </>
  );
};

export default NavbarNew;
