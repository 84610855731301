import React from 'react'

const NumberFormatter = ({ number }) => {
    console.log(number, "formnumb")
    const nmb = parseInt(number)
    if (nmb < 1000) {
        return nmb.toString(); // No formatting needed for numbers less than 1000
    } else if (nmb < 1000000) {
        // Format as "Xk" for thousands
        return (nmb / 1000).toFixed(1) + 'k';
    } else {
        // Format as "XM" for millions
        return (nmb / 1000000).toFixed(1) + 'M';
    }
}

export default NumberFormatter