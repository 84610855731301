import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { inrestedUserChartApi } from "../../api/companyApis/analyticsApi";
import { Chart as ChartJS } from "chart.js/auto";
import { Card, Spinner } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { MdDashboard } from "react-icons/md";
import ReactApexChart from 'react-apexcharts'
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";


const IntrestedUserChart = () => {
  const [data, setData] = useState(null);
  const [spinner, setspinner] = useState(true)


  useEffect(() => {
    inrestedUserChartApi()
      .then((res) => {
        const parsedData = res.data.data.map(item => {
          const [day, month, year] = item.date.split('/').map(Number);
          return {
            date: new Date(year, month - 1, day), // Note: Month is 0-based in JavaScript Date
            interested_users: item.interested_users
          };
        });
        setData(parsedData);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {

    if (data?.length >= 0) {
      setspinner(false)
    }
    if (data?.length > 0) {
      let root = am5.Root.new("chartdivinterested");

      root._logo.dispose();

      root.setThemes([
        am5themes_Animated.new(root)
      ]);


      root.dateFormatter.setAll({
        dateFormat: "yyyy-MM-dd",
        dateFields: ["valueX"]
      });

      let exporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {})
      });

      let chart = root.container.children.push(am5xy.XYChart.new(root, {
        focusable: true,
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true
      }));

      let easing = am5.ease.linear;


      let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
        maxDeviation: 0.1,
        groupData: false,
        baseInterval: {
          timeUnit: "day",
          count: 1
        },
        renderer: am5xy.AxisRendererX.new(root, {

        }),
        tooltip: am5.Tooltip.new(root, {})
      }));

      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        maxDeviation: 0.2,
        renderer: am5xy.AxisRendererY.new(root, {})
      }));
      let yRenderer = yAxis.get("renderer");
      yRenderer.labels.template.setAll({
        fill: am5.color(0xFFFFFF),
        fontSize: "12px"
      });
      let xRenderer = xAxis.get("renderer");
      xRenderer.labels.template.setAll({
        fill: am5.color(0xFFFFFF),
        fontSize: "12px"
      });
      yRenderer.grid.template.set("forceHidden", true);
      xRenderer.grid.template.set("forceHidden", true);

      let series = chart.series.push(am5xy.LineSeries.new(root, {
        minBulletDistance: 5,
        connect: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "interested_users",
        valueXField: "date",
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: "{interested_users}"
        })
      }));

      series.fills.template.setAll({
        fillOpacity: 0.2,
        visible: true,
      });

      series.set("fill", am5.color("#58aff3"));
      series.set("stroke", am5.color("#58aff3"));

      series.fills.template.set("fillGradient", am5.LinearGradient.new(root, {
        stops: [{
          opacity: 1
        }, {
          opacity: 0.5
        }]
      }));

      series.strokes.template.setAll({
        strokeWidth: 2
      });


      series.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "yyyy-MM-dd",
        dateFields: ["date"],
        numericFields: ["interested_users"]
      });

      series.data.setAll(data);

      series.bullets.push(function () {
        let circle = am5.Circle.new(root, {
          radius: 4,
          fill: root.interfaceColors.get("background"),
          stroke: series.get("fill"),
          strokeWidth: 2
        })

        return am5.Bullet.new(root, {
          sprite: circle
        })
      });

      let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        behavior: "none"
      }));
      cursor.lineY.set("visible", false);

      chart.appear(1000, 100);

      return () => {
        root.dispose();
      };

    }

  }, [data])


  return (
    <div>
      <Card
        className="chartcard"
      >
        <div id="chart" style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>

          {
            data?.length > 0 ?
              <>
                <h4>Interested Users</h4>
                <div id="chartdivinterested" className="chrtbox" ></div>
              </>
              :
              spinner ?
                <Spinner animation="border" size="lg" variant="primary" /> :
                <div><h4>No Data Yet</h4></div>
          }
        </div>
        {/* <div id="chart" style={{ width: "100%" }}>
          {data ?
            <ReactApexChart options={options} series={series} type="area" height={230} />
            :
            <Spinner animation="border" size="lg" variant="primary" />
          }
        </div> */}
      </Card>
    </div>
  );
};

export default IntrestedUserChart;
