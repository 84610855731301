import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card, Row, Col } from "antd";
import img from "../../images/explore1.png";
import image2 from "../../images/slider.jpg";

import TopSection from "../Common/TopSection";
import { Spinner } from "react-bootstrap";
import { displayErrorToast } from "../../helper/toast_notification_function";
import { PublicGetIndustriesApi } from "../../api/Public_Apis";

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <i className="fa fa-chevron-left" />
    </div>
  );
};

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <i className="fa fa-chevron-right" />
    </div>
  );
};

const InvestorHomeSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    arrows: true,
    arrowPosition: "bottom",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          arrows: false
        },
      }
    ]
  };
  const [Data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const getIndustries = () => {
    setIsLoading(true)
    PublicGetIndustriesApi()
      .then((res) => {
        if (res?.data?.success) {
          setData(res?.data?.data);
          setIsLoading(false)
        }
      })
      .catch((err) => {
        setIsLoading(false)

        displayErrorToast(err);
      });
  };

  useEffect(() => {
    getIndustries()
  }, [])

  return (
    <section id="investor_slider_sec" className="sectionSpacing">
      <TopSection
        mainHeading="	A Digital Marketplace Of Alternative Investments"
        desc="No bank account needed, connect with the companies on your terms. Search By Sector, and find out ways to put your cash to work. Investment opportunities are available for both Non-Accredited and Accredited Investors!"
      />
      <div className="col-12 mx-auto py-3 home_digital_slider">
        {Data ? <Slider {...settings}>
          {Data.map((item, index) => (
            <div className="investorSliderWrapper" key={index}>
              <div className="investorSlider invest_slider_relative">
                <img alt="example" src={`${process.env.REACT_APP_HOME_URL}${item.thumbnail}`} className="investorSliderImg" />
                <div>
                  <p>{item.title}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider> : isLoading ? <Spinner animation="grow" size="lg" variant="primary" /> : ""}
      </div>
    </section>
  );
};

export default InvestorHomeSlider;
