import { Button, Card, Row, Space, Typography } from "antd";
import { useState } from "react";


export default function TheTeams({ theTeams }) {
  const [expanded, setExpanded] = useState(false);
  const { Title, Text } = Typography;

  const FounderAndOficersData = [
    {
      img: "https://img.favpng.com/18/13/12/runescape-internet-bot-chatbot-avatar-clip-art-png-favpng-0fWQWr3tBQFRyKiiExWYvnt9d.jpg",
      Text: "Theo is an experienced leader, having invested and founded companies in the ﬁelds of real estate, co-working, online advertising, and cannabis alongside his long-term business partner, Assaf Hershlikovich.",

      Name: "Mudassir khan",
      tag: "Cheif Executive",
    },
    {
      img: "https://img.favpng.com/18/13/12/runescape-internet-bot-chatbot-avatar-clip-art-png-favpng-0fWQWr3tBQFRyKiiExWYvnt9d.jpg",
      Text: "Theo is an experienced leader, having invested and founded companies in the ﬁelds of real estate, co-working, online advertising, and cannabis alongside his long-term business partner, Assaf Hershlikovich.",

      Name: "Mudassir khan",
      tag: "Cheif Executive",
    },
    {
      img: "https://img.favpng.com/18/13/12/runescape-internet-bot-chatbot-avatar-clip-art-png-favpng-0fWQWr3tBQFRyKiiExWYvnt9d.jpg",
      Text: "Theo is an experienced leader, having invested and founded companies in the ﬁelds of real estate, co-working, online advertising, and cannabis alongside his long-term business partner, Assaf Hershlikovich.",

      Name: "Mudassir khan",
      tag: "Cheif Executive",
    },
  ];

  const toggleMore = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="">
      <div className="row justify-content-center">
        {
          theTeams.length > 0 ? (
            theTeams
              .slice(0, `${expanded ? theTeams.length : 3}`)
              .map((val, ind) => {
                return (
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-4">
                    <div className="team-card">
                      <img alt={val?.full_name} src={val?.picture ? val?.picture : FounderAndOficersData[0].img} />
                      <div className="team-content">
                        <h5>
                          {val?.designation.toUpperCase()}
                        </h5>
                        <h4>{val?.full_name}</h4>
                        <p>{val?.message}</p>
                      </div>
                    </div>
                  </div>
                );
              })
          ) : (
            <h6 className="text-center">Data not Found!</h6>
          )}
      </div>
      {theTeams.length > 3 && (
        <div className="text-center mt-3">
          <Button 
             size="large"
             className="viewPortfolioBtn addbtnt"
             style={{background: "linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5)",border: "none",}}
          
          
          onClick={toggleMore}    >
            {!expanded ? "Show More" : "Show Less"}
          </Button>
        </div>
      )}
    </div>
  );
}
