import { Col, Row } from "antd";
import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import img from "../../images/heroBanner.jpg";
import { FiArrowRight } from "react-icons/fi"
import { Link } from "react-router-dom";

export default function HeroSectionLeadGeneration() {
  const navigate = useNavigate();

  return (
    <section id="bannerSec">
      <div className="heroWrapper">
        <div className="hero_inner">
          <h1 className="commonH2">
            Get Global Exposure To The Investment Community!
          </h1>
          <p className="banner-sec-text text-center">
            Get the LEAD GERERATION benefits of having a booth at an investment trade-show, without the travel and high-dollar cost to attend a 2-3 day event.
          </p>
          <p className="text-center">
            <Link to="/about" >More Details?</Link>
          </p>


          {/* <Button
              type="primary"
              className="heroBtn"
              onClick={() => {
                navigate("/signUpCompany");
              }}
            >
              Start Investment
            </Button> */}



          <button onClick={() => navigate('/signup', { state: { data: "company" } })} className="banner-btn leadgenBtn"><span>Start Rising</span> <FiArrowRight className="rightArrow" /> </button>

        </div>
      </div>
    </section>
  );
}
