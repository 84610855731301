import React from "react";
import { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useParams, useNavigate } from 'react-router-dom'
import { displayErrorToast, displaySuccessToast, displayWorrningToast } from "../helper/toast_notification_function";
import { confirmForgetPassword } from "../api/ForgetPasswordApis";
import { Spinner } from "react-bootstrap";


export default function ForgotPassword() {
  const [forgotPass, setForgotPass] = useState({
    password: "",
    confirm_password: "",
  });
  const [showPass, setShowPass] = useState(false);
  const [showPassTwo, setShowPassTwo] = useState(false);
  const [loading, setLoading] = useState(false);


  const { token } = useParams()
  const navigate = useNavigate()




  const handleChanger = (e) => {
    const { name, value } = e.target;
    setForgotPass({ ...forgotPass, [name]: value });
  };

  const submit = (e) => {
    e.preventDefault();

    if (forgotPass.password === forgotPass.confirm_password) {
      setLoading(true)
      console.log(forgotPass)
      let obj = { password: forgotPass.password, confirm_password: forgotPass.confirm_password, token: token }
      confirmForgetPassword(obj).then((res) => {
        console.log(res)
        displaySuccessToast(res?.data?.message?.[0])
        setLoading(false)
        navigate("/login")
      }).catch((res) => {
        displayErrorToast(res);
        setLoading(false)
      })

    } else {
      displayWorrningToast("Password not matched!")
    }
  };
  return (
    <section className="bg-img" style={{ minHeight: "calc(100vh - 105px)" }}>
      <div className="py-5 container">
        <div className="mt-5 py-md-3">
          <div className="container h-100">
            <div className="row justify-content-center align-items-center mt-md-5">
              <div className="col-md-5 forgot-box">
                <h3 className="text-center mb-3 fw-bold">Forgot Password</h3>
                <form>
                  <div className="row ">
                    <div className="input-group w-100 mb-3 mt-3">
                      <div className="form-floating ">
                        <input
                          required
                          type={!showPass ? "password" : "text"}
                          value={forgotPass?.password}
                          onChange={handleChanger}
                          className="form-control"
                          name="password"
                          id="Floatingpassword"
                          placeholder="Password"
                        />

                        <label for="Floatingpassword" className="">
                          Password
                        </label>
                      </div>

                      <span
                        className="input-group-text "
                        onClick={() => setShowPass((prev) => !prev)}
                      >
                        {!showPass ? (
                          <AiOutlineEye size={24} />
                        ) : (
                          <AiOutlineEyeInvisible size={24} />
                        )}
                      </span>
                    </div>
                  </div>



                  <div className="input-group w-100 mb-3 mt-1">
                    <div className="form-floating ">
                      <input
                        required
                        type={!showPassTwo ? "password" : "text"}
                        value={forgotPass?.confirm_password}
                        onChange={handleChanger}
                        className="form-control"
                        name="confirm_password"
                        id="Floatingpassword"
                        placeholder="Password"
                      />

                      <label for="Floatingpassword" className="">
                        Confirm Password
                      </label>
                    </div>

                    <span
                      className="input-group-text "
                      onClick={() => setShowPassTwo((prev) => !prev)}
                    >
                      {!showPassTwo ? (<AiOutlineEye size={24} />) : (<AiOutlineEyeInvisible size={24} />)}
                    </span>
                  </div>
                  <button
                    type="submit"
                    className={`btn btn-primary  mx-auto d-flex align-self-center form_btn  gradientBtn ${forgotPass.password && forgotPass.confirm_password ? "" : "graylog"}`}
                    onClick={submit}
                    disabled={(forgotPass.password && forgotPass.confirm_password) ? false : true}
                  >
                    {loading ? <Spinner size="sm" animation="border" /> : 'Submit'}
                  </button>


                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}


