// Api for get company profile

import { apiHandle } from "../apiHandler";

export const GetCompanyProfileApi = () => {
  return apiHandle().get("/company/auth/profile");
};
// api for update user profile
export const UpdateCompanyProfileApi = (data) => {
  let multipart = true,
    isAuth = true;
  return apiHandle(isAuth, multipart).put("/company/auth/profile", data);
};





export const getUserProfileBId = (id) => {
  return apiHandle().get(`/company/auth/users/${id}`);
};



export const getUserLevelTwoProfileById = (id) => {
  return apiHandle().get(`/company/auth/users/levelTwoProfile/paid/${id}`);
};




export const companySentOtpForNumber = () => {
  return apiHandle().post(`/company/auth/sendOtp`);
};



export const companyVerifyOtpForNumber = (data) => {
  return apiHandle().post(`/company/auth/verifyOtp`,data);
};
