import { Col, Row } from "antd";
import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import img from "../../images/heroBanner.jpg";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";

export default function HeroSection() {
  const navigate = useNavigate();

  return (
    <section id="bannerSec">
      <div className="heroWrapper">
        <Row
          gutter={[24, 16]}
          // style={{  margin: "0 auto" }}
          className="container-fluid mt-5"
          justify="center"
          align="center"
        >
          <Col className="gutter-row" xs={24} sm={24} md={24} lg={24} xl={24}>
            <h2 className="commonH2">
              Explore Alternative  Investment <br /> Opportunities Your Broker
              Isn’t Talking About
            </h2>
            <p className="banner-sec-text text-center">
              InvestorPresentations.com is a digital platform for investors
              of all levels, to educate themselves on alternative investment
              opportunities from all around the world! ….Sign-Up Today!


              {/* Lorem
              ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
              eirmod tempor invidunt <br /> ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At <br /> Start Investment */}
            </p>

            {/* <Button
              type="primary"
              className="heroBtn"
              onClick={() => {
                navigate("/signUpCompany");
              }}
            >
              Start Investment 
            </Button> */}
          </Col>

          <Col
            className="gutter-row email_section"
            xs={24}
            sm={24}
            md={16}
            lg={16}
            xl={16}
          >
            <button className="banner-btn" onClick={() => navigate('/signup', { state: { data: "company" } })}>

              Get Started <FiArrowRight className="rightArrow" />
            </button>
          </Col>
        </Row>
      </div>
    </section>
  );
}
