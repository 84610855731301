import { Button, Collapse, Image, Popconfirm, Space, Upload } from "antd";
import { StarOutlined, UploadOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useEffect } from "react";
import {
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import {
  DeleteOverViewGalleryApi,
  getOverViewApi,
  getOverViewGalleryApi,
  getOverViewImageApi,
  overViewApi,
  overViewGalleryApi,
} from "../../../../api/companyApis/portfolio/overViewApi";
import { TextEditor } from "../../../../component/TextEditor";
import {
  displayErrorToast,
  displaySuccessToast,
  displayWorrningToast,
} from "../../../../helper/toast_notification_function";
import { MdAutoDelete } from "react-icons/md";
import download from "../../../../images/download.png"
import { dataWithFiles } from "../../../../helper/common_functions";


export default function OverView() {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);

  const [overViewDataFromApi, setOverViewDataFromApi] = useState();
  const [isDisable, setIsDisable] = useState(false);
const [apiData , setApiData] = useState([])
  const [gallery, setGallery] = useState();
  const [galleryDataFromApi, setGalleryDataFromApi] = useState([]);
  const [galleryBtnDisable, setGalleryBtnDisable] = useState(true);
  const [description , setDescription] = useState("")

  const fileInputRef = React.useRef();
  const { Panel } = Collapse;


  console.log(data)
  const submit = () => {
    //   send data var in api
    let obj = { text: data , image_section_text : description };

    if (data !== "" || overViewDataFromApi && description) {
    setLoading(true)

      setIsDisable(true);
      overViewApi(obj)
        .then((res) => {
          if (res.data.success) {
            getData();
            displaySuccessToast("Success");
            setIsDisable(false);
    setLoading(false)

          }
        })
        .catch((err) => {
          displayErrorToast(err);
    setLoading(false)

          setIsDisable(false);
        });
    } else {
      displayWorrningToast("Please enter the data..!");
    }
  };

  const getData = () => {
    setLoading(true);
    getOverViewApi()
      .then((res) => {
        if (res?.data?.success) {
          setOverViewDataFromApi(res?.data?.data[0]);

          // setData(res?.data?.data[0]);
          setApiData(res.data.data[0])
          setLoading(false);
        }
      })
      .catch((Err) => {
        setLoading(false);
        displayErrorToast(Err);
      });
  };

  //   submit gallery func
  const submitGallery = () => {
    setBtnLoader(true)
    if (gallery) {
      setGalleryBtnDisable(true);
      // let obj = { images: gallery?.gallery_images };
      getOverViewImageApi(gallery).then((res) => {
          if (res?.data?.success) {
            fileInputRef.current.value=""
            getData();
            displaySuccessToast("Success");
            setGalleryBtnDisable(true);
    setBtnLoader(false)

          }
        })
        .catch((err) => {
          setGalleryBtnDisable(false);
          displayErrorToast(err);
    setBtnLoader(false)

        });
    }
  };

  const getGalleryData = () => {
    setLoading(true);
    getOverViewGalleryApi()
      .then((res) => {
        if (res?.data?.success) {
          setGalleryDataFromApi(res?.data?.data);
          setLoading(false);
        }
      })
      .catch((Err) => {
        setLoading(false);
        displayErrorToast(Err);
      });
  };

  const imageDltFn = (id) => {
    DeleteOverViewGalleryApi(id)
      .then((res) => {
        if (res?.data?.success) {
          getData();
          displaySuccessToast("image deleted!");
        }
      })
      .catch((err) => displayErrorToast(err));
  };

  useEffect(() => {
    getData();
    getGalleryData();
  }, []);

  // if (loading) {
  //   return (
  //     <div
  //       style={{
  //         width: "100%",
  //         display: "inline-flex",
  //         alignItems: "center",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <Spinner animation="grow" variant="primary" />
  //     </div>
  //   );
  // }

  console.log(data)

  return (
    <div className="bg_black">
      <div className="doc_slide">
        <h3>Overview</h3>
      </div>
      <div className="row">
      <TextEditor
        onClick={submit}
        data={overViewDataFromApi}
        setData={setData}
        disabled={isDisable}
        loader={loading }
      />
   
      <FormGroup className="mb-2">
              <FormLabel className="text-white" htmlFor="Description">Description</FormLabel>
              <FormControl
                type="text"
                name="Description"
                id="Description"
                defaultValue={apiData?.image_section_text}
                maxLength={100}
                onChange={(e) => setDescription(e.target.value)}
              />
              {/* {formError.presentationTitle && (
                  <p>{formError.presentationTitle}</p>
                )} */}
            </FormGroup>

      </div>
      

      {/* Company gallery section */}

      <Space
        direction="vertical"
        style={{width: "100%",}}
        size="large"
      >

        <div>
        <div className="d-flex justify-content-between align-items-center mt-5 mb-2 doc_slide">
            <div>
              <h3>Company Slides</h3>
              {/* <p>(Aspect Ratio: 3/2 | 600*400px Min.)</p> */}
            </div>
            <div>
              <button
                onClick={submitGallery}
                className="addbtnt"
                style={{ width: "80px", fontSize: "16px" }}
                disabled={gallery ? false : true}
              >
                {btnLoader ? <Spinner size="sm" animation="border"/> : "submit"}
              </button>
            </div>
          </div>

          <div className="row">
         

            <FormGroup className="mb-2 ">
            {/* <label for="gallery_images" className="portFolio_gallery">
              <img src={download} className="" />
              <span className="ms-1">
                Upload Company Logo


              </span>
            </label> */}
              <InputGroup className="mb-3 mt-2">
                <FormControl
                  type="file"
                  name="images"
                  id="overview_images"
                  accept="image/*"
                  multiple
                  ref={fileInputRef}
                  // style={{display:"none"}}
                  onChange={(e) => {
                    setGalleryBtnDisable(false);
                    setGallery({ [e.target.name]: [...e.target.files] });
                    console.log(e.target.files)
                  }}
                />
                {fileInputRef?.current?.value && (
                  <InputGroup.Text className="">
                    <span
                      onClick={() => {
                        setGallery(null);
                        setGalleryBtnDisable(true);

                        fileInputRef.current.value = "";
                      }}
                    >
                      ✖
                    </span>
                  </InputGroup.Text>
                )}
              </InputGroup>
            </FormGroup>
      
          
          </div>
        </div>


        <div>
            {Array.isArray(apiData?.overview_images) &&  apiData.overview_images.length > 0 ? (
              <div className="imgs_bx row">
                {apiData.overview_images.map((val, ind) => {
                  return (
                    <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                      <div className="flxbximg">
                        <Image
                          className="imgsh"
                          src={val?.image}
                          preview={{ src: val.image }}
                        />
                        <div>
                          <Popconfirm
                            title="Delete the task"
                            description="Are you sure to delete this task?"
                            onConfirm={() => imageDltFn(val?.id)}
                            onCancel={"cancel"}
                            okText="Yes"
                            cancelText="No"
                          >
                            <button className="btntm">
                              <img src="/images/dlt.svg" />
                            </button>
                          </Popconfirm>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : null}
          </div>















      
      </Space>
    </div>
  );

        }