import React, { useEffect, useState } from "react";
import UpdateAccountSec from "../../component/companies/UpdateAccountSec";

import CompanyComponent from "../../component/company";
import Joyride from 'react-joyride';


import { ERROR_GETTING_USER_PROFILE, SAVE_GETTING_USER_PROFILE, START_GETTING_USER_PROFILE } from "../../redux/constants/user_getProfile_const";
import { getCompanyProfileImg, getUserprofileImgApi } from "../../api/UserProfileApi";
import { useDispatch, useSelector } from "react-redux";
import { displayErrorToast } from "../../helper/toast_notification_function";

const Company = () => {

  const joyrideSteps = [


    {
      target: ".pop-Up .userProfileDivOne",
      content: "Welcome! Feel free to upload a Profile Picture.",
      disableBeacon: true
    },
    {
      target: ".pop-Up .companytabs",
      content: "This Section is where all your favorite companies data is stored for you to visit at your own pace!",
      disableBeacon: true
    },
    {
      target: ".pop-Up .userProfileDivTwo",
      content: " Are you an Accredited Investor? Apply now for access to investment offerings only available to Accredited Investors. Attestation will be needed.",
      disableBeacon: true
    }

  ]
  const [runJoyride, setRunJoyride] = useState(false); // Control whether to start/stop the joyride

  const handleJoyrideCallback = (data) => {
    if (data.action === 'finished' || data.action === 'skipped') {
      setRunJoyride(false);
    }
  };
  useEffect(() => {
    const first_login = JSON.parse(localStorage.getItem("first_login"))

    if (first_login) {
      setRunJoyride(true)
      localStorage.setItem("first_login", "false")
    }
    else {
      setRunJoyride(false)
    }
  }, [])

  let dispatch = useDispatch()
  useEffect(() => {
    handleGetUserPersonalInfo(dispatch)
  }, [])
  return (
    <>
      <Joyride
        steps={joyrideSteps}
        run={runJoyride}
        continuous={true}
        showSkipButton={false}
        callback={handleJoyrideCallback}

      // Additional Joyride props...
      />

      <section className="bg-img pop-Up">
        <UpdateAccountSec />
        <CompanyComponent />
      </section>
    </>
  );
};

export default Company;


export const handleGetUserPersonalInfo = (dispatch) => {
  dispatch({
    type: START_GETTING_USER_PROFILE,
  });

  console.log("phel user")


  getUserprofileImgApi().then((res) => {
    if (res?.data?.success) {

      dispatch({
        type: SAVE_GETTING_USER_PROFILE,
        payload: res.data?.data ? res.data?.data?.[0] : {},
      });
    }
  }).catch((err) => {
    displayErrorToast(err)
    dispatch({
      type: ERROR_GETTING_USER_PROFILE,
      payload: err?.response?.data?.data.message[0],
    });

  })

}



export const handleGetCompanyPersonalInfo = (dispatch) => {
  dispatch({
    type: START_GETTING_USER_PROFILE,
  });
  console.log("bad me usecomr")

  getCompanyProfileImg().then((res) => {
    if (res?.data?.success) {

      console.log(res.data, "new")
      dispatch({
        type: SAVE_GETTING_USER_PROFILE,
        payload: res.data?.data ? res.data?.data?.[0] : {},
      });
    }
  }).catch((err) => {
    displayErrorToast(err)
    dispatch({
      type: ERROR_GETTING_USER_PROFILE,
      payload: err?.response?.data?.data.message[0],
    });

  })

}

