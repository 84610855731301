import { PublicGetIndustriesApi } from "../../api/Public_Apis";
import { displayErrorToast } from "../../helper/toast_notification_function";
import { SAVE_GETTING_LIST_INDUSTRIES, START_GETTING_ALL_INDUSTRIES } from "../constants/get_All_Industries_constant";



export const getAllIndustriesHandle = (dispatch) => {

    dispatch({
        type:START_GETTING_ALL_INDUSTRIES,
      });

      PublicGetIndustriesApi().then((res) => {

        if (res?.data.success) {
            return dispatch({
                type: SAVE_GETTING_LIST_INDUSTRIES,
                payload: res.data ? res.data.data : []
            })
            
        }


    }).catch((error) => {
         displayErrorToast(error)
    })
}
