import { apiHandle } from "../../apiHandler";

export const getInvestorPerks = (id) => {
  return apiHandle().get(`/company/auth/investorPerks${id ? `/${id}` : ''}`);
};

export const investorPerksApi = (obj) => {
  return apiHandle().post(`company/auth/investorPerks`, obj);
};


export const investorPerksUpdateApi = (id , obj) => {
  return apiHandle().put(`company/auth/investorPerks/${id}`,obj);
};



export const investorPerksDeleteApi = (id) => {
  return apiHandle().delete(`company/auth/investorPerks/${id}`);
};
