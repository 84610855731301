import { apiHandle } from "../../apiHandler";

export const getRiskAndDisclosuresApi = () => {
  return apiHandle().get(`/company/auth/riskAndDisclosures`);
};

export const riskAndDisclosuresApi = (obj) => {
  return apiHandle().post(`/company/auth/riskAndDisclosures`, obj);
};



