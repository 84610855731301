import React from "react";
import { Card, Spinner } from "react-bootstrap";

const CoundCard = ({ color, data, title, isloading, imgurl }) => {

  return (
    <>
      <Card
        style={{
          // borderBottomColor: color,
          padding: "10px 10px 10px 20px",
          width: "100%",
          height: "100%",
          backgroundColor: " #171821",
          borderRadius: "15px",
          borderBottom: "1px solid gray",
          borderRight: "1px solid gray",
          borderLeft: "1px solid black",
          borderTop: "1px solid black",
          boxShadow: " 0px 2px 5px 2px #d3d3d326",


        }}
        className="cndcard"
      >
        <img className="cndimg" src={imgurl} />
        {isloading ? (
          <Spinner animation="border" size="lg" variant="primary" />
        ) : (
          <h1 >{data}</h1>
        )}
        <h6 style={{ color: color }}>{title}</h6>
      </Card>
    </>
  );
};

export default CoundCard;
