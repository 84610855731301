import { Paper } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { ImProfile } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { userViewsWithProfileApi, userViewsWithProfilePaidApi } from "../../api/companyApis/analyticsApi";
import { displayErrorToast } from "../../helper/toast_notification_function";
import useWindowDimensions from "../WindowDimensions";

// let headers = [
//   { label: "Id", key: "user_id" },
//   { label: "First Name", key: "first_name" },
//   { label: "Last Name", key: "last_name" },
//   { label: " Views", key: "view_count" },
//   { label: "email", key: "email" },
// ];

const UserViewsTable = () => {
const { width, height } = useWindowDimensions();

  let appState = localStorage.getItem("IP_accessToken");

  const [data, setData] = useState([]);
  // const [paiddata, setPaidData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const navigate = useNavigate();

  const columns = [
    {
      field: "user_id",
      headerName: "ID",
      width: 40,
    },
    {
      field: "first_name",
      headerName: "First name",
      flex: 1
    },
    {
      field: "last_name",
      headerName: "Last name",
      flex: 1
    },
    // {
    //   field: "fullName",
    //   headerName: "Full name",
    //   description: "This column has a value getter and is not sortable.",
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.row.first_name || ""} ${params.row.last_name || ""}`,
    // },
    {
      field: "email",
      headerName: "Email",
      flex: 1
    },
    {
      field: "view_count",
      headerName: "Views",
      // type: "number",
      width: 80,
    },
    {
      field: "details",
      headerName: "Details",
      width: 60,
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="outline-primary"
              onClick={() => navigate(`/company/user/${params.row?.user_id}`)}
            >
              <ImProfile />
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    userViewsWithProfilePaidApi()
      .then((res) => {
        setLoading(false);
        // setPaidData(res.data.data);
        console.log("user profile paid views", res.data.data)
      })
      .catch((error) => console.log("user view with profile paid", error.response));
    userViewsWithProfileApi()
      .then((res) => {
        setLoading(false);
        setData(res.data.data);
      })
      .catch((error) => console.log("user view with profile", error.response));
  }, []);


  const downloadFile = async () => {
    setLoading(true)
    try {
      const token = appState; // Replace with your authentication token
      const headers = { Authorization: `Bearer ${token}`, };


      const response = await axios.get('https://api-staging.investorpresentations.com/company/auth/users/views/groupByUser/paid?download=true', {

        responseType: 'blob',
        headers, // Include the authorization header
      });

      // Create a blob URL for the response data
      const blobUrl = window.URL.createObjectURL(new Blob([response.data]));

      // Create an anchor element to trigger the download
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = 'data.csv'; // Specify the file name
      a.click();
      setLoading(false)

      // Release the blob URL to free up resources
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      setLoading(false)
      displayErrorToast(error)
      console.log('Error downloading file:', error);
    }
  };


  if (isLoading) {
    return (
      <>
        <Paper
          className="d-flex align-items-center justify-content-center"
          sx={{ height: 400, width: "100%" }}
        >
          <div className="container">
            <div className="d-flex align-items-center justify-content-center">
              <Spinner animation="border" size="lg" variant="primary" />
            </div>
          </div>
        </Paper>
      </>
    );
  }

  return (
    <div>

      <div >

        <div className="d-flex justify-content-between align-items-center my-3 user_table_heads">
          <h3 className="text-white mt-2">Views With Profile</h3>
          <div>
            {/* {
              paiddata?.length > 0 ?
                <Button style={{ background: "linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5)", border: "none", outline: "none", boxShadow: "none" }} >
                  <CSVLink style={{ color: "#fff", display: "flex", alignItems: "center", gap: "5px" }} data={paiddata} headers={headers}>
                    Download <img src="../../images/cloud.svg" />
                  </CSVLink>
                </Button>
                :
                <Button style={{ background: "linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5)", border: "none", outline: "none", boxShadow: "none" }} onClick={() => { displayWorrningToast("You have not paid for leads yet.") }}>
                  Download <img src="../../images/cloud.svg" />
                </Button>
            } */}

            <Button style={{ background: "linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5)", border: "none", outline: "none", boxShadow: "none" }} onClick={downloadFile} >
              {isLoading ? <Spinner animation="border" size="sm" /> : <span>Download</span>}<img src="../../images/cloud.svg" alt="img" />
            </Button>



          </div>
        </div>

        <Paper className="tableBg">
          <Box sx={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={data}
              columns={columns}
              getRowId={(row) => row.user_id}
              rowsPerPageOptions={[10, 20, 30]}
              checkboxSelection
              disableSelectionOnClick
              className="tableData"
            // experimentalFeatures={{ newEditingApi: true }}
            />
          </Box>
        </Paper>
      </div>
    </div>
  );
};

export default UserViewsTable;
