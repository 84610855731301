import { apiHandle } from "../../apiHandler";

// export const UploadPortfolioFileApi = (body) => {
//   return apiHandle(true, true).post(`/company/auth/portfolio/slide`, body);
// };


// export const DeletePortfolioFileApi = (body) => {
//   return apiHandle().delete(`/company/auth/portfolio/slide`);
// };

export const GetProfilePortfolioFileApi = () => {
  return apiHandle().get(`/company/auth/portfolio`);
};
