import React, { useState } from "react";
import { useEffect } from "react";
import { Spinner , Collapse, Form, FormGroup, FormLabel, FormControl, Button } from "react-bootstrap";
import {
  getInvestorPerks,
  investorPerksApi,
} from "../../../../api/companyApis/portfolio/investorPerksApi";
import { TextEditor } from "../../../../component/TextEditor";
import {
  displayErrorToast,
  displaySuccessToast,
  displayWorrningToast,
} from "../../../../helper/toast_notification_function";
import { AiOutlinePlus } from "react-icons/ai";
import AddInvestorPerks from "./AddInvestorPerks";
import Table from "./Table";

export default function InvestorPerks() {
  const [data, setData] = useState("");
  // const [loading, setLoading] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [investorDataFromApi, setInvestorDataFromApi] = useState();
  const [isDisable, setIsDisable] = useState(false);
  const [visible , setVisible] = useState(false)
  const { Panel } = Collapse;

  // const submit = () => {
  //   //   send data var in api
  //   let obj = { text: data };
  //   setBtnLoader(true)

  //   if (data !== "" || investorDataFromApi) {
  //     setIsDisable(true);
  //     investorPerksApi(obj)
  //       .then((res) => {
  //         if (res.data.success) {
  //           displaySuccessToast("Success");
  //           setIsDisable(false);
  //   setBtnLoader(false)

  //         }
  //       })
  //       .catch((err) => {
  //         displayErrorToast(err);
  //         setIsDisable(false);
  //   setBtnLoader(false)

  //       });
  //   } else {
  //     displayWorrningToast("Please enter the data..!");
  //   }
  // };

  const submit = (e) => {
    e.preventDefault()
    setVisible(false)
  };

  const getData = () => {
    setLoading(true);
    getInvestorPerks()
      .then((res) => {
        if (res?.data?.success) {
          setInvestorDataFromApi(res?.data?.data[0]);
          setData(res?.data?.data[0]);
          setLoading(false);
        }
      })
      .catch((Err) => {
        setLoading(false);
        displayErrorToast(Err);
      });
  };

  const handleToggle = () => {
    setVisible((prev) => !prev);
  };


  useEffect(() => {
    getData();
  }, []);

  // if (isLoading) {
  //   return (
  //     <div
  //       style={{
  //         width: "100%",
  //         display: "inline-flex",
  //         alignItems: "center",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <Spinner animation="grow" variant="primary" />
  //     </div>
  //   );
  // }

  return (
    <div className="bg_black">
         <div
        style={{
          width: "100%",
          display: "inline-flex",
          justifyContent: "space-between",
          marginBottom: "17px",
        }}
      >
        <div className="doc_slide">
          <h3>Investor Perks</h3>
        </div>
        <div>
          <button className="addbtnt" onClick={() => setVisible(!visible)}>
            <AiOutlinePlus color="#fff" size={20} />
          </button>
        </div>
      </div>


      <AddInvestorPerks
        isOpen={visible}
        handleToggle={handleToggle}
        toggleState={() => setLoading((prev) => !prev)}
      />
      <Table flag={isLoading} />









      {/* <TextEditor
        onClick={submit}
        data={investorDataFromApi}
        setData={setData}
        disabled={isDisable}
        loader={btnLoader}
      /> */}
    </div>
  );
}
