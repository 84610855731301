import React, { useState } from "react";
import { Card, Col, Row } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Space } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { displayWorrningToast } from "../../helper/toast_notification_function";
import FollowButtoncard from "./FollowButtonCard";
import IntrestedButtoncard from "./IntrestedButtoncard";
import { handleCompanyFollow, handleIntrestedCompany } from "../../redux/actions/companyIntrectionActions";
import { IoLocationSharp } from "react-icons/io5"
import CountdownTimer from "../dashboard/CountDownTimer";
import NumberFormatter from "../../helper/NumberFormattar";
const { Meta } = Card;

export default function ExploreCards({
  isInterested,
  isFollowed,
  logo,
  id,
  img,
  title,
  desc,
  total_investment,
  minimum_investment,
  state,
  country,
  start_date,
  end_date,
  onClick,
  isLevelTwo,
}) {
  const styles = {
    // borderRight: '1px solid lightgray',
    display: "flex",
    alignItems: "center",
    gap: " 0.7rem",
    color: "white"
  };
  const [intrested, setIntrested] = useState(isInterested);
  const [follow, setFollow] = useState(isFollowed);
  const [Loadingbtn, setLoadingbtn] = useState(false);
  const [LoadingbtnInt, setLoadingbtnInt] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [CompId, setCompId] = useState();
  const navigate = useNavigate()
  let appState = localStorage.getItem("IP_State");
  let levelTwoState = JSON.parse(localStorage.getItem("IP_levelTwoProfileStatus"));

  // Define the start and end dates
  const startDate = new Date();
  const endDate = new Date(end_date);

  // Calculate the time difference in milliseconds
  const timeDifference = endDate - startDate;

  // Calculate the number of days
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  // Calculate the time duration in hours, minutes, and seconds
  const hoursDifference = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
  const minutesDifference = Math.floor((timeDifference / (1000 * 60)) % 60);
  const secondsDifference = Math.floor((timeDifference / 1000) % 60);

  console.log(total_investment, "dfdg", minimum_investment)
  return (
    <div className="exploreCardWrapper">
      <Card

        className={appState === null ? "crdexpbg" : levelTwoState !== "valid" ? isLevelTwo ? "blrcrd" : "crdexpbg" : "crdexpbg"}
        cover={
          <img
            alt="example"
            src={img}

          />
        }
        onClick={appState === null ? () => { navigate("/login") } : appState === "COMPANY" ? () => { } : levelTwoState === "valid" ? onClick : isLevelTwo ? () => { displayWorrningToast("Upgrade Your Status to Level 2") } : onClick}
        style={{ position: "relative" }}
      >
        <div className="icon-bx">
          <FollowButtoncard
            follow={follow}
            disabled={Loadingbtn}
            onClick={(e) => { if (appState === null || levelTwoState == "valid" || !isLevelTwo) { e.stopPropagation(); handleCompanyFollow(id, setFollow, setIsProcessing, setLoadingbtn) } }}
          />

          <IntrestedButtoncard
            intrested={intrested}
            disabled={LoadingbtnInt}
            onClick={(e) => { if (appState === null || levelTwoState == "valid" || !isLevelTwo) { e.stopPropagation(); handleIntrestedCompany(id, setIntrested, setIsProcessing, setLoadingbtnInt) } }}
          />
        </div>

        <div className="d-flex gap-3 ">
          <img src={logo} className="crdlogo" />
          <div className="crdcontent">
            <h3 className="crdexph3">
              {title}
            </h3>
          </div>
        </div>

        {state || country ?

          <div className="d-flex gap-1 " style={{ position: "relative", right: "5px" }}>
            <IoLocationSharp fontSize={20} color="red" />
            <p className="exploreCard_location text-white">

              {state} , {country}
            </p>
          </div>
          : null}
        <p className={`crdexppra`}>
          {desc}
        </p>
        <div className="expflx">
          <div className="pt-3 exploreBottomText">
            <p className=" mb-0 ">Total Investment</p>

            <p>$ {(total_investment !== null && total_investment !== undefined && total_investment !== "-") ? <NumberFormatter number={total_investment} /> : "-"}</p>
          </div>
          <div className="pt-3 exploreBottomText">
            <p className=" mb-0 ">Min. Investment</p>
            <p>$ {(minimum_investment !== null && minimum_investment !== undefined && minimum_investment !== "-") ? <NumberFormatter number={minimum_investment} /> : "-"}</p>
          </div>
        </div>

        {!!end_date && <div className="exploreCard_timer">

          <p className="mb-0 text-white" > Time Left : </p><CountdownTimer targetDate={end_date} />
        </div>}
      </Card>
    </div>
  );
}


