import { apiHandle } from "../../apiHandler";

export const getMarketLandScapeApi = () => {
  return apiHandle().get(`/company/auth/marketLandscape`);
};



export const marketLandScapeGraphApi = (data) => {
    return apiHandle().post(`/company/auth/marketLandscape/graph`,data);
  };

  export const marketLandScapeTextApi = (data) => {
    return apiHandle().post(`/company/auth/marketLandscape/text`,data);
  };