import { useState, useEffect, useRef } from "react";
import { BiBookmark } from "react-icons/bi";
import { FaFacebookF, FaPlay } from "react-icons/fa";
import { RiArrowUpDoubleLine, RiTwitterFill } from "react-icons/ri";
import { FaLinkedinIn, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import {
  Col, Row, Space, Typography, Drawer, Avatar, Button,
} from "antd";
import AnchorLink from "react-anchor-link-smooth-scroll";
import useWindowDimensions from "../../component/WindowDimensions";
import HighLights from "../../partails/components/companyPortfolioComponenrts.js/HighLights";
import TheTeams from "../../partails/components/companyPortfolioComponenrts.js/TheTeams";
import { getCompanyProfilePortfolioAction } from "../../redux/actions/getCompanyProfilePortfolioAction";
import { useDispatch, useSelector } from "react-redux";
import { AntDesignOutlined } from "@ant-design/icons";
import { Spinner } from "react-bootstrap";
import FilingPortFolio from "../../partails/components/companyPortfolioComponenrts.js/FilingPortFolio";
import InvestorPerksInProfile from "../../partails/components/companyPortfolioComponenrts.js/InvestorPerksInProfile";
import RiskAndDiscloInProfile from "../../partails/components/companyPortfolioComponenrts.js/RiskAndDiscloInProfile";
import DataRoomInProfile from "../../partails/components/companyPortfolioComponenrts.js/DataRoomInProfile";
import ReviewPortfolio from "../../partails/components/companyPortfolioComponenrts.js/ReviewPortfolio";
import OverviewInProfile from "../../partails/components/companyPortfolioComponenrts.js/OverviewInProfile";
import MarketInProfile from "../../partails/components/companyPortfolioComponenrts.js/MarketInProfile";
import UseProceedInProfile from "../../partails/components/companyPortfolioComponenrts.js/UseProceedInProfile";
import VideosInProfile from "../../partails/components/companyPortfolioComponenrts.js/VideosInProfile";
import AskedQuestions from "../../partails/components/companyPortfolioComponenrts.js/AskedQuestions";
import { useNavigate } from 'react-router-dom'
import ReactPlayer from "react-player";
import Slider from "react-slick";
import { getCompanyVideoUrlApi } from "../../api/companyApis/companyVideosApi";
import { displayErrorToast } from "../../helper/toast_notification_function";
import { FaCross } from "react-icons/fa6";
import { MdOutlineClose } from "react-icons/md";
import Gallery from "../../partails/components/companyPortfolioComponenrts.js/Gallery";
import MediaMentions from "../../partails/components/companyPortfolioComponenrts.js/MediaMentions";


export default function CompanyPortfolio() {
  const [numPages, setNumPages] = useState(null);
  const [logo, setLogo] = useState();
  const [open, setOpen] = useState(false);
  const [lightboxOpen, setlightboxOpen] = useState(false);
  const [lightboxIndex, setlightboxIndex] = useState(0);
  const [lightboxVideoUrl, setlightboxVideoUrl] = useState("");
  const [showvideosthumbnail, setshowvideosthumbnail] = useState(false);
  const [videosdata, setvideosdata] = useState([]);
  const [stickyStyle, setStickyStyle] = useState(0)

  const { height, width } = useWindowDimensions();
  const dispatch = useDispatch();
  const { Text, Link } = Typography;

  const { companyProfilePortFolio, isLoading, error } = useSelector(
    (state) => state.companyProfilePortFolioReducer
  );

  const thumbnailsRef = useRef(null);

  const onClose = () => {
    setOpen(false);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  useEffect(() => {
    getCompanyProfilePortfolioAction(dispatch);
  }, []);

  const settings = {
    dots: false,
    infinite: videosdata?.length > 3 ? true : false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    arrows: true,

    responsive: [

      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        },
      },
      {
        breakpoint: 551,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleVideoUrl = (id) => {
    setlightboxOpen(true)
    getCompanyVideoUrlApi(id)
      .then((res) => {
        setlightboxVideoUrl(res?.data?.data[0].presignedUrl);
      })
      .catch((error) => { displayErrorToast("Error Occurred While Fetching Video") });
  };

  useEffect(() => {
    setvideosdata(companyProfilePortFolio?.[0]?.portfolio?.profile?.company_presentations)
  }, [companyProfilePortFolio])

  console.log(videosdata, "videodata")

  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     window.addEventListener(
  //       "scroll",
  //       () => setStickyStyle(window.pageYOffset > 3)
  //       // setNews(true)
  //     );
  //   }
  // }, [])

  if (isLoading) {
    return (
      <>
        <div className="">
          <div className="main-body">
            <Spinner animation="grow" size="lg" variant="primary" />
          </div>
        </div>
      </>
    );
  }
  return (
    <div className="bg-img-portfolio" style={{ zIndex: `${lightboxOpen ? "100" : "9"}` }}>
      <Row
        span={4}
        className="sideAnchorSection"
        style={{maxHeight:"100px" , zIndex:"200"}}
      >
        <div className="topAnchorListWrapper" >
          <div className="container">
            <AnchorList />
          </div>
        </div>
      </Row>

      <div className="portfolio-main">
        {companyProfilePortFolio?.length > 0 ? (
          companyProfilePortFolio.map((val, ind) => {
            const {
              highlights,
              overview,
              the_team,
              investor_perks,
              use_of_proceeds,
              market_landscape,
              risks_and_disclosures,
              filing,
              data_room,
              reviews,
              faqs_about_investing,
              portfolio_slide,
            } = val?.portfolio?.active_modules;
            let primaryVideo =
              val?.portfolio?.profile?.company_presentations?.length > 0
                ? val?.portfolio?.profile?.company_presentations.filter(
                  (newVal) => newVal?.is_primary == true
                )
                : [];
                console.log(val,"v")

            return (
              <>

                <div className="title-cont">
                  <div className="container-xl">
                    <div className="row">
                      {primaryVideo?.length > 0
                        ? primaryVideo.map((newVal, ind) => {
                          return (
                            <>
                              <div className="col-lg-8 col-md-12 mt-4">

                                <div className="investorSliderWrapper" key={newVal.id} onClick={(e) => handleVideoUrl(newVal?.id)}>
                                  <div className="videoSlider w-100">
                                    <span
                                      className="position-relative w-100 d-block"
                                    >
                                      <span className="playIcon">

                                        <FaPlay
                                          color="white"
                                          fontSize={"30px"}
                                          className="videoArrowCenter"
                                        />
                                      </span>
                                      <img
                                        alt="example"
                                        src={newVal?.thumbnail}
                                        className="videoSliderImg w-100"
                                      />
                                    </span>

                                    <div>
                                      <h5>{newVal?.title}</h5>
                                      <p>{newVal?.text}</p>
                                    </div>
                                  </div>
                                </div>
                                {/* <video
                              style={{
                                width: "-webkit-fill-available",
                                borderRadius: "20px",
                                objectFit: "cover",
                                aspectRatio: "3/2"
                              }}
                              // height="255"
                              poster={newVal.thumbnail}
                              src={newVal.video}
                              controls
                            ></video> */}
                              </div>
                            </>
                          );
                        })
                        : null}

                      <div className="col-lg-4 col-md-12 mt-4">
                        <div className="">
                          <div className={"d-flex gap-1 align-items-center"}>
                            <div className="imgbxportfolio">
                              <Avatar
                                src={val?.portfolio?.profile?.logo}
                                style={{
                                  verticalAlign: "middle",
                                  border: "1px solid gray",
                                }}
                                size={{
                                  xs: 50,
                                  sm: 80,
                                  md: 100,
                                  lg: 100,
                                  xl: 100,
                                  xxl: 100,
                                }}
                                icon={<AntDesignOutlined />}
                              />
                            </div>
                            <h3 className="text-white ms-1">
                              {val?.portfolio?.profile?.company_name}
                            </h3>
                          </div>

                          <div className="company_portfolio_name">
                            <h5 className="text-white">
                              {val?.portfolio?.profile?.description}
                            </h5>
                          </div>
                        </div>

                        <div className="mt-4 d-flex gap-2">
                          <Button
                            icon={
                              <BiBookmark
                                style={{ marginRight: "5px", marginBottom: "3px" }}
                              />
                            }
                            className="connectCompanyBtn"
                            size={"medium"}
                          >
                            Follow
                          </Button>
                          <Button className="connectCompanyBtn" size={"medium"}>
                            Connect Company
                          </Button>
                        </div>


                        <div className="mt-3">
                          <div className="d-flex align-items-center flex-wrap gap-1">
                            <span className="text-white">Address: </span>
                            <span className="text-white">{val?.portfolio?.profile?.address}</span>
                          </div>
                        </div>

                        <div className="mt-3">
                          <div className="d-flex align-items-center flex-wrap gap-1">
                            <span className="text-white">Email: </span>
                            <a href={`mailto:${val?.portfolio?.profile?.email}`}>{val?.portfolio?.profile?.email}</a>
                          </div>
                        </div>

                        <div className="mt-3">
                          <div className="d-flex align-items-center flex-wrap gap-1">
                            <span className="text-white">Contact: </span>
                            <a href={`tel:${val?.portfolio?.profile?.primary_contact_number}`}>{val?.portfolio?.profile?.primary_contact_number}</a>
                          </div>
                        </div>

                        <div className="mt-3">
                          {!!val?.portfolio?.profile?.company_social
                            ?.website_url && (
                              <div className="d-flex align-items-center">
                                <span className="text-primary fs-5">@</span>
                                &nbsp;
                                <Link
                                  className="text-white"
                                  target="_blank"
                                  to={
                                    val?.portfolio?.profile?.company_social
                                      ?.website_url
                                  }
                                >
                                  {
                                    val?.portfolio?.profile?.company_social
                                      ?.website_url
                                  }
                                </Link>
                              </div>
                            )}
                        </div>

                        {val?.portfolio?.profile?.company_social ? (
                          <div className="mt-3">
                            <Text type="text-primary ">
                              <span className="text-primary fs-6">
                                Social media :
                              </span>
                              <Space>
                                &nbsp;
                                {!!val?.portfolio?.profile?.company_social
                                  ?.facebook_url && (
                                    <a
                                      target="_blank"
                                      href={
                                        val?.portfolio?.profile?.company_social
                                          ?.facebook_url
                                      }
                                    >
                                      <Button shape="circle" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <FaFacebookF size={18} color="#1877f2" />
                                      </Button>
                                    </a>
                                  )}
                                {!!val?.portfolio?.profile?.company_social
                                  ?.instagram_url && (
                                    <a
                                      target="_blank"
                                      href={
                                        val?.portfolio?.profile?.company_social
                                          ?.instagram_url
                                      }
                                    >
                                      <Button shape="circle" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <i className="fa-brands fa-instagram instaicon"></i>
                                      </Button>
                                    </a>
                                  )}
                                {!!val?.portfolio?.profile?.company_social
                                  ?.linkedin_url && (
                                    <a
                                      target="_blank"
                                      href={
                                        val?.portfolio?.profile?.company_social
                                          ?.linkedin_url
                                      }
                                    >
                                      <Button shape="circle" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <FaLinkedinIn size={18} color="#0e76a8" />
                                      </Button>
                                    </a>
                                  )}
                                {!!val?.portfolio?.profile?.company_social
                                  ?.twitter_url && (
                                    <a
                                      target="_blank"
                                      href={
                                        val?.portfolio?.profile?.company_social
                                          ?.twitter_url
                                      }
                                    >
                                      <Button shape="circle" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <RiTwitterFill color="#1DA1F2" size={18} />
                                      </Button>
                                    </a>
                                  )}
                              </Space>
                            </Text>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                {/* {!!val?.portfolio?.profile?.company_social && (
                  <Row className="mt-5">
                    <Text type="secondary" className="text-white">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: val?.portfolio?.profile?.company_social?.text,
                        }}
                      />
                    </Text>
                  </Row>
                )} */}

                {
                  // Object.keys(val?.portfolio).length === 2 && <h3 className="text-white fw-bold text-center mt-5">Bulid Your Portfolio....!</h3>
                }
                <div className="videos-sect-port">
                  <div className="container-xl">
                    <h2>Videos</h2>
                    {!!val?.portfolio?.profile?.company_presentations?.length > 0 ? (
                      <VideosInProfile
                        videos={val?.portfolio?.profile?.company_presentations}
                        lightboxState={setlightboxOpen}
                        lightboxVideoUrl={setlightboxVideoUrl}
                      />
                    ) :
                      <h3>
                        No Videos Available
                      </h3>
                    }
                  </div>
                </div>

                {!!highlights && (
                  <div className="highlight-cont">
                    <div className="container-xxl">
                      <div id="Highlights">
                        <HighLights data={val?.portfolio?.highlights ? val?.portfolio?.highlights : {}} />
                      </div>
                    </div>
                  </div>
                )}

                {!!overview && (
                  <>
                    <div className="overview-cont" id="Overview">
                      <div className="container-xl">
                        <h2>Overview</h2>
                        <OverviewInProfile
                          overview={val?.portfolio?.overview ? val?.portfolio?.overview : []}
                        />
                      </div>
                    </div>
                    <div className="gallery-cont" id="Gallery">
                      <div className="container-xl">
                        <h2>Gallery</h2>
                        <p className="text-center text-white">
                          Lorem ipsum dolor sit emit little text about mention
                          Lorem ipsum dolor sit emit little text about mention
                        </p>
                        <Gallery
                          gallery_and_media_mention={val?.portfolio?.gallery_and_media_mention?.gallery ? val?.portfolio?.gallery_and_media_mention?.gallery : []}
                        />
                      </div>
                    </div>
                    <div className="media-cont" id="MediaMention">
                      <div className="container-xl">
                        <h2>Media Mentions</h2>
                        <p className="text-center text-white">
                          Lorem ipsum dolor sit emit little text about mention
                        </p>
                        <MediaMentions
                          media_mentions={val?.portfolio?.gallery_and_media_mention?.media_mentions ? val?.portfolio?.gallery_and_media_mention?.media_mentions : []}
                        />
                      </div>
                    </div>
                  </>
                )}

                {!!the_team && (
                  <div className="team-perks-cont" id="TheTeams">
                    <div className="container-xl">
                      <h2>The Team</h2>
                      <p className="text-center text-white">
                        Lorem ipsum dolor sit emit little text about mention
                      </p>
                      <TheTeams theTeams={val?.portfolio?.the_team ? val?.portfolio?.the_team : []} />
                    </div>


                  </div>
                )}

                {!!investor_perks && (
                      <div className="inv-perks-cont container-xl" id="InvestorPerks">
                        <h2>Investor Perks</h2>
                        <InvestorPerksInProfile
                          InvestorPerks={val.portfolio?.investor_perks ? val.portfolio?.investor_perks : []}
                        />
                      </div>
                    )}      

                {!!use_of_proceeds && (
                  <div className="use-proceed-cont" id="UseOfProceeds">
                    <div className="container-xl">
                      <h2>Use Of Proceeds</h2>
                      <UseProceedInProfile
                        proceed={
                          val?.portfolio?.use_of_proceeds?.donut_chart
                        }
                      />
                    </div>
                  </div>
                )}

                {!!market_landscape && (
                  <div className="market-lands-cont" id="MarketLandscape">
                    <div className="container-xl">
                      <h2>Market Landscape</h2>
                      <MarketInProfile
                        marketLandScape={val?.portfolio?.market_landscape}
                      />
                    </div>
                  </div>
                )}

                {!!risks_and_disclosures && (
                  <div className="risk-disc-cont" id="RisksDisclosures">
                    <div className="container-xl">
                      <h2>Risk & Disclosures</h2>
                      <RiskAndDiscloInProfile
                        RiskAndDisclosures={
                          val.portfolio?.risks_and_disclosures
                        }
                      />
                      {!!filing && (
                        <div id="FormC">
                          <FilingPortFolio filing={val?.portfolio?.filing} />
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {!!data_room && (
                  <div className="dataroom-cont" id="DataRoom">
                    <div className="container-xl">
                      <h2>Data Room</h2>
                      <DataRoomInProfile
                        dataRoom={val?.portfolio?.data_room}
                        saved_files={val?.saved_files_id ? val?.saved_files_id : []}
                      />
                    </div>
                  </div>
                )}

                {!!reviews && (
                  <div className="reviews-cont" id="Reviews">
                    <div className="container-xl">
                      <h2>Testimonials</h2>
                      <ReviewPortfolio
                        reviews={val?.portfolio?.reviews}
                      />
                    </div>
                  </div>
                )}
                {!!faqs_about_investing && (
                  <div className="asked-cont" id="FAQsAboutInvesting">
                    <div className="container-xl">
                      <h2>Still Have Questions?</h2>
                      <AskedQuestions
                        faq={val?.portfolio?.faqs_about_investing}
                      />
                    </div>
                  </div>
                )}

              </>
            );
          })
        ) : error ? (
          <h4 style={{ color: "red", fontWeight: "bold" }}>{error}</h4>
        ) : null}
      </div>
      {/* {width < 992 && (
        <div className="smScreenAnchorList">
          <Button className="smScreenAnchorOpenBtn" onClick={showDrawer}>
            Portfolio Menu {open ? <HiArrowSmDown /> : <HiArrowSmUp />}
          </Button>
          <Drawer
            title="Basic Drawer"
            placement={"bottom"}
            closable={false}
            onClose={onClose}
            open={open}
            key={"bottom"}
          >
            <AnchorList onClose={onClose} />
          </Drawer>
        </div>
      )} */}

      <div className={`lightBoxModal ${lightboxOpen && "open"}`}>
        <div className="bg-light-box" onClick={() => { setlightboxOpen(false); setlightboxVideoUrl(""); setshowvideosthumbnail(false) }}></div>
        <div className="container-xxl">
          <div className="close-btn-lightbox" onClick={() => { setlightboxOpen(false); setlightboxVideoUrl(""); setshowvideosthumbnail(false) }}>
            <MdOutlineClose size={24} color="#fff" />
          </div>
          <ReactPlayer
            url={lightboxVideoUrl}
            controls
            // light
            width={"100%"}
            height={"100%"}
          />
          <div className={`thumbnailbar ${showvideosthumbnail ? "show" : ""}`} onMouseOver={() => { setshowvideosthumbnail(true) }} onMouseOut={() => { setshowvideosthumbnail(false) }}>
            {!showvideosthumbnail && <div className="shwmrebtn"><RiArrowUpDoubleLine size={18} color="#fff" /><span>Show More</span></div>}
            <div className="slider_box_thumb">
              <Slider {...settings}>
                {videosdata?.map((item, index) => (

                  <div className="investorSliderWrapper" key={item.id} onClick={(e) => handleVideoUrl(item?.id)}>
                    <div className="videoSlider">
                      <span
                        className="position-relative d-block"
                        style={{ zIndex: 30 }}
                      >
                        <span className="playIcon">

                          <FaPlay
                            color="white"
                            fontSize={"30px"}
                            className="videoArrowCenter"
                          />
                        </span>
                        <img
                          alt="example"
                          src={item.thumbnail}
                          className="videoSliderImg"
                        />
                      </span>

                      <div>
                        <h5>{item.title}</h5>
                        <p>{item.text}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

const AnchorList = ({ onClose }) => {
  const navigate = useNavigate()

  const { companyProfilePortFolio, isLoading, error } = useSelector(
    (state) => state.companyProfilePortFolioReducer
  );

  const sliderRef = useRef(null);

  const slide = (shift) => {
    sliderRef.current.scrollLeft += shift;
  };


  return (
    <div className="w-100" >

      <div style={{ maxWidth: "1840px", position: "relative" }}>
        <button className="slider-button prev-button" onClick={() => slide(-100)}>
          <FaChevronLeft />
        </button>


        <ul className="TopanchorList" ref={sliderRef}>
          {companyProfilePortFolio?.length > 0
            ? companyProfilePortFolio.map((val, ind) => {
              const {
                highlights,
                overview,
                the_team,
                investor_perks,
                use_of_proceeds,
                market_landscape,
                risks_and_disclosures,
                filing,
                data_room,
                reviews,
                faqs_about_investing,
              } = val?.portfolio?.active_modules;

              return (
                <>
                  {!!highlights && (
                    <li>
                      <AnchorLink href="#Highlights" onClick={onClose}>
                        Highlight
                      </AnchorLink>
                    </li>
                  )}

                  {!!overview && (
                    <li>
                      <AnchorLink href="#Overview" onClick={onClose}>
                        Overview
                      </AnchorLink>
                    </li>
                  )}

                  {!!the_team && (
                    <li>
                      <AnchorLink href="#TheTeams" onClick={onClose}>
                        The Teams
                      </AnchorLink>
                    </li>
                  )}

                  {!!investor_perks && (
                    <li>
                      <AnchorLink href="#InvestorPerks" onClick={onClose}>
                        Investor Perks
                      </AnchorLink>
                    </li>
                  )}

                  {!!use_of_proceeds && (
                    <li>
                      <AnchorLink href="#UseOfProceeds" onClick={onClose}>
                        Use of Proceeds
                      </AnchorLink>
                    </li>
                  )}

                  {!!market_landscape && (
                    <li>
                      <AnchorLink href="#MarketLandscape" onClick={onClose}>
                        Market Landscape
                      </AnchorLink>
                    </li>
                  )}

                  {!!risks_and_disclosures && (
                    <li>
                      <AnchorLink href="#RisksDisclosures" onClick={onClose}>
                        Risks & Disclosures
                      </AnchorLink>
                    </li>
                  )}

                  {!!filing && (
                    <li>
                      <AnchorLink href="#FormC" onClick={onClose}>
                        Filing
                      </AnchorLink>
                    </li>
                  )}

                  {!!data_room && (
                    <li>
                      <AnchorLink href="#DataRoom" onClick={onClose}>
                        Data Room
                      </AnchorLink>
                    </li>
                  )}

                  {!!reviews && (
                    <li>
                      <AnchorLink href="#Reviews" onClick={onClose}>
                        Reviews
                      </AnchorLink>
                    </li>
                  )}

                  {!!faqs_about_investing && (
                    <li>
                      <AnchorLink href="#FAQsAboutInvesting" onClick={onClose}>
                        FAQs About Investing
                      </AnchorLink>
                    </li>
                  )}
                </>
              );
            })
            : null}
        </ul>
        <button className="slider-button next-button" onClick={() => slide(+100)}>
          <FaChevronRight />
        </button>
      </div>
      <div className="d-flex flex-wrap flex-row justify-content-between align-items-center w-100"  >
        <div className="d-flex flex-row align-items-center"  >
          {/* <button   onClick={()=>{
 
  }}    className="btn btn-primary" >
      Back 
    </button> */}
        </div>
        <Button
          size="large"
          className="viewPortfolioBtn addbtnt"
          style={{ background: "linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5)", border: "none", }}
          onClick={() => {
            navigate("/company/dashboard")
          }}
        >
          {/* <span className="mx-1"  >    <ArrowBackIcon fontSize="12px" /> </span> */}
          Back To Dashboard
        </Button>
      </div>

    </div>

  );
};
