import { AntDesignOutlined } from "@ant-design/icons";
import {
  Select as Antsel,
  Avatar,
  Button,
  Col,
  Row,
  Tabs
} from "antd";

import { Buffer } from "buffer";
import React, { useEffect, useState } from "react";
import { Form, FormGroup, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import {
  GetCompanyProfileApi,
  UpdateCompanyProfileApi,
  companySentOtpForNumber,
  companyVerifyOtpForNumber,
} from "../../api/companyApis/CompanyProfileApis";
import { dataCleaning, dataWithFiles } from "../../helper/common_functions";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../helper/toast_notification_function";

import { Tooltip } from "antd";
import { BiEdit } from "react-icons/bi";
import { RxDotFilled } from "react-icons/rx";
import 'react-phone-number-input/style.css';
import { useDispatch, useSelector } from "react-redux";
import { getVideosHandle } from "../../redux/actions/CompayVideoActions";

// import { GoDotFill } from "react-icons/go";
import OTPInput, { ResendOTP } from "otp-input-react";
import PhoneInput from "react-phone-number-input";
import Select from "react-select";
import checkmark from "../../images/checkMark.png";
import { getAllIndustriesHandle } from "../../redux/actions/getAllIndustriesAction";
import allstates from "../../utility/allstates";
import cities from "../../utility/cities";
import country from "../../utility/country";
import Socials from "./Socials";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";

const CompanyProfile = () => {

  const [UserSign, SetUserSign] = useState({
    first_name: "",
    last_name: "",
    username: "",
    placeId: "",
    email: "",
    password: "",
    confirmPassword: "",
    captcha: "",
    // date_of_birth: "",
  });

  const [selected, setSelected] = useState(null);

  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();
  const {
    ready: ready1,
    value: value1,
    setValue: setValue1,
    suggestions: { status: status1, data: data1 },
    clearSuggestions: clearSuggestions1,
  } = usePlacesAutocomplete();

  const handleSelect = async (address, name) => {

    if (name == "companyPlaceId") {
      setValue(address, false);
      clearSuggestions();
      const selectedPlace = data.find((place) => place.description === address);

      if (selectedPlace) {
        setformData({
          ...formData,
          [name]: selectedPlace.place_id
        });
        setIsButtonDisabled(false);
      }
    }
    else {
      setValue1(address, false);
      clearSuggestions1();
      const selectedPlace = data1.find((place) => place.description === address);

      if (selectedPlace) {
        setformData({
          ...formData,
          [name]: selectedPlace.place_id
        });
        setIsButtonDisabled(false);
      }
    }
    // const results = await getGeocode({ address });
    // const { lat, lng } = await getLatLng(results[0]);
    // setSelected({ lat, lng });
  };

  const [show, setShow] = useState(false);
  const [showTwo, setShowTwo] = useState(false);


  const [countryid, setCountryid] = useState('231');
  const [pricountryid, setpriCountryid] = useState('231');
  const [countryname, setcountryname] = useState('');
  const [statename, setstatename] = useState('');
  const [stateid, setstateid] = useState('');
  const [pristateid, setpristateid] = useState('');
  const [cityname, setcityname] = useState('');
  const [pricityname, setpricityname] = useState('');
  const [statesdata, setstatesdata] = useState([]);
  const [citiesdata, setcitiesdata] = useState([]);
  const [pristatesdata, setpristatesdata] = useState([]);
  const [pricitiesdata, setpricitiesdata] = useState([]);
  let stateopt = [];
  let pristateopt = [];
  let cityopt = [];
  let pricityopt = [];

  const dispatch = useDispatch();

  // const { videos, isVideoLoadig, videoError } = useSelector(
  //   (state) => state.videoReducer
  // );
  const { getAllIndustries } = useSelector(
    (state) => state.getAllIndustriesReducer
  );

  const [userState, setUser] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [formData, setformData] = useState(null);


  const [state, setState] = useState({
    name: "MUdassir",
    fName: "jamal",
  });

  const [levelTwoCheckBox, setLevelTwoCheckBox] = useState();
  const [OTP, setOTP] = useState("");
  const [OTPdata, setOTPdata] = useState({});

  const handleClose = () => {
    setShow(false);
    setIsButtonDisabled(true)
    setformData(null)
    handleGetProfile();
  }
  const handleCloseTwo = () => setShowTwo(false);

  const handleShow = () => setShow(true);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  function handleChange(evt) {
    setIsButtonDisabled(false);
    const { name, value } = evt.target;



    if (name === "logo") {
      setformData({ ...formData, [name]: evt.target.files[0] });
    }
    else if (name == "primary_contact_tax_identification_number") {
      const newValue = value.replace(/[^0-9]/g, '');
      console.log(newValue)
      setformData({ ...formData, [name]: newValue, });
      setUser({ ...userState, [name]: newValue, });

    }

    else if (name == "employer_identification_number") {
      const newValue = value.replace(/[^0-9]/g, '');
      console.log(newValue)
      setformData({ ...formData, [name]: newValue, });
      setUser({ ...userState, [name]: newValue, });

    }

    else {
      console.log(value, "value")

      setformData({
        ...formData,
        [name]: value,
      });
    }
  }

  // console.log(formData )
  console.log(levelTwoCheckBox)

  const handleSubmint = (e) => {
    e.preventDefault();
    if (formData) {
      let data = !formData ? userState : formData;
      if (levelTwoCheckBox != undefined) {
        console.log(levelTwoCheckBox)
        localStorage.setItem(
          "IP_levelTwoProfileStatus", JSON.stringify(levelTwoCheckBox)
        );
      }

      setIsButtonDisabled(true);
      UpdateCompanyProfileApi(dataWithFiles(dataCleaning(data)))
        .then((res) => {
          displaySuccessToast("Profile Updated Successfully");
          handleClose();

          if (res?.data?.data?.[0].key == true) {
            sendotp()
          }
          handleGetProfile();
          handleClose();
          setIsButtonDisabled(false);
        })
        .catch((err) => {
          displayErrorToast(err);
          setIsButtonDisabled(false);
        });
      // }
    }
  };

  const handleGetProfile = () => {
    GetCompanyProfileApi()
      .then((response) => {
        let logoBuffer = response.data?.data[0].logo;

        let base64 = Buffer.from(logoBuffer).toString("base64");

        setUser(response.data?.data[0]);
        setformData(response.data?.data[0]);
        setformData({
          primary_contact_number:
            response.data?.data[0]?.primary_contact_number,
        });
        console.log(response.data?.data[0]?.is_level_two_profile)
        let filter = options.filter((val) => { return val.label == response.data?.data?.[0]?.country });
        setCountryid(filter[0]?.value);
        setcountryname(filter[0]?.label);
        allstates.map((data) => {
          if (data.country_id == countryid) {
            return (stateopt.push({ value: data.state_id, label: data.state_name }));
          }
        })
        setstatesdata(stateopt)
        let filterstate = stateopt.filter((val) => { return val.label == response.data?.data?.[0]?.state });
        setstateid(filterstate[0]?.value);
        setstatename(filterstate[0]?.label);

        // Primary Contact
        let prifilter = options.filter((val) => { return val.label == response.data?.data?.[0]?.primary_contact_country });
        setpriCountryid(prifilter[0]?.value);
        allstates.map((data) => {
          if (data.country_id == pricountryid) {
            return (pristateopt.push({ value: data.state_id, label: data.state_name }));
          }
        })
        setpristatesdata(pristateopt)
        let prifilterstate = pristateopt.filter((val) => { return val.label == response.data?.data?.[0]?.primary_contact_state });
        setpristateid(prifilterstate[0]?.value);

        setLoading(false);
      })
      .catch((err) => {
        console.log("Profile error log", err);
      });
  };



  const handleCountry = (e) => {
    stateopt = [];
    cityopt = [];
    setCountryid(e.value)
    setstateid("")
    setformData({ ...formData, country: e.label, state: "", city: "" })
    setUser({ ...userState, state: "", city: "" })
  }
  const handleState = (e) => {
    cityopt = [];
    setstateid(e.value)
    setformData({ ...formData, state: e.label, city: "" })
    setUser({ ...userState, city: "" })
  }
  const handleCity = (e) => {
    setcityname(e.label)
    setformData({ ...formData, city: e.label })
  }
  const handlePriCountry = (e) => {
    pristateopt = [];
    pricityopt = [];
    setpriCountryid(e.value)
    setpristateid("")
    setformData({ ...formData, primary_contact_country: e.label, primary_contact_state: "", primary_contact_city: "" })
    setUser({ ...userState, primary_contact_state: "", primary_contact_city: "" })
  }
  const handlePriState = (e) => {
    pricityopt = [];
    setpristateid(e.value)
    setformData({ ...formData, primary_contact_state: e.label, primary_contact_city: "" })
    setUser({ ...userState, primary_contact_city: null })
  }
  const handlePriCity = (e) => {
    setpricityname(e.label)
    setformData({ ...formData, primary_contact_city: e.label })
  }

  const options = country.map((data) => {
    return (
      {
        value: data.country_id,
        label: data.country_name
      }
    )
  })

  useEffect(() => {
    allstates.map((data) => {
      if (data.country_id == countryid) {
        return (stateopt.push({ value: data.state_id, label: data.state_name }));
      }
    })
    setstatesdata(stateopt)
  }, [countryid, stateid, cityname])

  useEffect(() => {
    cities.map((data) => {
      if (data.state_id == stateid) {
        return (cityopt.push({ value: data.city_id, label: data.city_name }));
      }
    })
    setcitiesdata(cityopt)
  }, [stateid, cityname])

  useEffect(() => {
    allstates.map((data) => {
      if (data.country_id == pricountryid) {
        return (pristateopt.push({ value: data.state_id, label: data.state_name }));
      }
    })
    setpristatesdata(pristateopt)
  }, [pricountryid, pristateid, pricityname])

  useEffect(() => {
    cities.map((data) => {
      if (data.state_id == pristateid) {
        return (pricityopt.push({ value: data.city_id, label: data.city_name }));
      }
    })
    setpricitiesdata(pricityopt)
  }, [pristateid, pricityname])


  const handleCloseOtpModal = () => {
    setOTP("")
    setShowTwo(false)
  };


  const sendotp = () => {
    setOTP("")
    companySentOtpForNumber().then((res) => {
      setShowTwo(true)
    }).catch(error => {
      displayErrorToast(error)
      console.log(error)
    })
  }

  const verifyotp = () => {
    companyVerifyOtpForNumber(OTPdata).then((res) => {
      displaySuccessToast("Phone Number Verification Successful")
      handleGetProfile()
      handleCloseOtpModal()
    }).catch(error => displayErrorToast(error))
  }

  const renderButton = (buttonProps) => {
    return <button {...buttonProps} className={`btn btn-md ${buttonProps.disabled ? "btn-secondary" : "btn-primary blbtn"}`}>Resend</button>;
  };
  const renderTime = (remainingtime) => {
    return <span>Try Again in: {remainingtime} Seconds</span>;
  };

  useEffect(() => {
    setOTPdata({ otp_code: OTP })
  }, [OTP])


  useEffect(() => {
    handleGetProfile();
    handleClose();
    getAllIndustriesHandle(dispatch);
  }, []);

  useEffect(() => {
    getVideosHandle(dispatch);
  }, []);

  const option = country.map((data) => {
    return (
      {
        value: data.country_id,
        label: data.country_name
      }
    )
  })
  let filter = option.filter((val) => { return val.value == '231' })
  let getValue = option.filter((val) => { return val.label == userState?.region_of_formation })


  if (isLoading) {
    return (
      <>
        <div className="container">
          <div className="main-body">
            <Spinner animation="grow" size="lg" variant="primary" />
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="">
      <div className="container">
        <div className="">
          <div className="col-md-12 companyProfile">
            <div style={{ paddingTop: "7rem" }}>
              <div className="d-flex align-items-center">
                <Avatar
                  src={userState?.logo}
                  style={{ verticalAlign: "middle", border: "1px solid white" }}
                  size={{
                    xs: 80,
                    sm: 80,
                    md: 100,
                    lg: 120,
                    xl: 130,
                    xxl: 140,
                  }}
                  icon={<AntDesignOutlined />}
                />   &nbsp;   &nbsp;

                <div className="profileNameDiv comp_prof">
                  <h4 className="text-white">{userState?.company_name}</h4>
                  {/* <h5 >Accredited Investor</h5> */}
                </div>
              </div>

              {/* <Button
                    type="primary"
                    style={{
                      float: "right",
                      fontSize: "18px",
                      letterSpacing: "1px",
                    }}
                    size={"large"}
                    onClick={() => navigate("/Portfolio")}
                  >
                    Portfolio
                  </Button> */}
              <div className="mt-2 mt-sm-3">
                <Tabs defaultActiveKey="1" className="pe-0">
                  <Tabs.TabPane
                    className="ProfileView"
                    tab="Account details"
                    key="2"
                  >
                    <div className="head py-2 pt-3 px-3">
                      <h3 className="text-white">Information</h3>

                      <Button
                        variant="btn btn-outline-primary"
                        style={{ fontSize: "small" }}
                        onClick={handleShow}
                        className="profileUpdateBtn"
                      >
                        <BiEdit fontSize="17px" />
                      </Button>
                    </div>
                    <div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-5">
                            <h6 className="mb-0 text-white">Company Name</h6>
                          </div>
                          <div className="col-sm-7 text-secondary">
                            {userState?.company_name}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-5">
                            <h6 className="mb-0 text-white">
                              Employer Identification Number
                            </h6>
                          </div>
                          <div className="col-sm-7 text-secondary">
                            {userState?.employer_identification_number}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-5">
                            <h6 className="mb-0 text-white">Region Formation</h6>
                          </div>
                          <div className="col-sm-7 text-secondary">
                            {userState?.region_of_formation}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-5">
                            <h6 className="mb-0 text-white">Description</h6>
                          </div>
                          <div className="col-sm-7 text-secondary">
                            {userState?.description}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-5">
                            <h6 className="mb-0 text-white">Industry</h6>
                          </div>
                          <div className="col-sm-7 text-secondary">
                            {userState?.industries
                              ?.map((v) => v.title)
                              ?.join(", ")}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-5">
                            <h6 className="mb-0 text-white">Email</h6>
                          </div>
                          <div className="col-sm-7 text-secondary">
                            {userState?.email}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-5">
                            <h6 className="mb-0 text-white">Address</h6>
                          </div>
                          <div className="col-sm-7 text-secondary">
                            {userState?.address}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-5">
                            <h6 className="mb-0 text-white">City</h6>
                          </div>
                          <div className="col-sm-7 text-secondary">
                            {userState?.city}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-5">
                            <h6 className="mb-0 text-white">State</h6>
                          </div>
                          <div className="col-sm-7 text-secondary">
                            {userState?.state}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-5">
                            <h6 className="mb-0 text-white">Zip</h6>
                          </div>
                          <div className="col-sm-7 text-secondary">
                            {userState?.zip}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-5">
                            <h6 className="mb-0 text-white">Country</h6>
                          </div>
                          <div className="col-sm-7 text-secondary">
                            {userState?.country}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-5">
                            <h6 className="mb-0 text-white">
                              Make profile visible only for Accredited investors
                            </h6>
                          </div>
                          <div className="col-sm-7 text-secondary">
                            <Tooltip
                              placement="topLeft"
                              title={
                                userState?.is_level_two_profile
                                  ? "Visible only for level two user"
                                  : "Visible to all users"
                              }
                            >


                              <span style={{ width: "25px", height: "25px" }}>
                                {userState?.is_level_two_profile ? (
                                  <img
                                    src={checkmark}
                                    alt="icon"
                                    width={"30px"}
                                  />
                                ) : (
                                  <RxDotFilled style={{ width: "22px" }} />
                                )}
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="head py-2 pt-3 px-3">
                      <h3 className="text-white">Primary Contact</h3>
                    </div>
                    <div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-5">
                            <h6 className="mb-0 text-white">First Name</h6>
                          </div>
                          <div className="col-sm-7 text-secondary">
                            {userState?.primary_contact_first_name}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-5">
                            <h6 className="mb-0 text-white">Last Name</h6>
                          </div>
                          <div className="col-sm-7 text-secondary">
                            {userState?.primary_contact_last_name}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-5">
                            <h6 className="mb-0 text-white">Tax Identification Number</h6>
                          </div>
                          <div className="col-sm-7 text-secondary">
                            {
                              userState?.primary_contact_tax_identification_number
                            }
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-5">
                            <h6 className="mb-0 text-white">Contact Number</h6>
                          </div>
                          <div className="col-sm-7 text-secondary">

                            {userState?.primary_contact_number ?
                              !userState?.is_phone_verified ? <Button onClick={sendotp} className="companyNo_VerifyBtn">Verify</Button> : <span className="verified-tag">Verified</span> : null}&nbsp;
                            {userState?.primary_contact_number}
                          </div>
                        </div>


                        <div className="row">
                          <div className="col-sm-5">
                            <h6 className="mb-0 text-white">Contact Email</h6>
                          </div>
                          <div className="col-sm-7 text-secondary">
                            {userState?.primary_contact_email}
                          </div>
                        </div>

                        {/* <div className="row">
                          <div className="col-sm-5">
                            <h6 className="mb-0 text-white">Date of Birth</h6>
                          </div>
                          <div className="col-sm-7 text-secondary">
                            {userState?.primary_contact_date_of_birth}
                          </div>
                        </div> */}

                        <div className="row">
                          <div className="col-sm-5">
                            <h6 className="mb-0 text-white">Address</h6>
                          </div>
                          <div className="col-sm-7 text-secondary">
                            {userState?.primary_contact_address}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-5">
                            <h6 className="mb-0 text-white">City</h6>
                          </div>
                          <div className="col-sm-7 text-secondary">
                            {userState?.primary_contact_city}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-5">
                            <h6 className="mb-0 text-white">State</h6>
                          </div>
                          <div className="col-sm-7 text-secondary">
                            {userState?.primary_contact_state}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-5">
                            <h6 className="mb-0 text-white">Zip</h6>
                          </div>
                          <div className="col-sm-7 text-secondary">
                            {userState?.primary_contact_zip}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-5">
                            <h6 className="mb-0 text-white">Country</h6>
                          </div>
                          <div className="col-sm-7 text-secondary">
                            {userState?.primary_contact_country}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tabs.TabPane>

                  {/* <Tabs.TabPane tab="Videos" key="3">
                    <div className="head">
                      <h3>Information</h3>
                      <Button
                        variant="primary"
                        style={{ fontSize: "small" }}
                        onClick={() => navigate("/company/presentation_videos")}
                      >
                        Manage Videos
                      </Button>
                    </div>
                    <VideosComponent userState={userState} videos={videos} />
                  </Tabs.TabPane> */}
                  <Tabs.TabPane className="ProfileView" tab="Socials" key="4">
                    <Socials />
                  </Tabs.TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}

      <Modal
        show={show}
        onHide={handleClose}
        scrollable={true}
        dialogClassName="my-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="py-md-3">
            <div className="row">
              <div className="form-group mt-3 col-6">
                <label for="CompanyName" className="font-weight-bold">
                  Company Name
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="company_name"
                  onChange={handleChange}
                  defaultValue={userState?.company_name}
                  id="CompanyName"
                  placeholder="Company Name"
                />
              </div>


              <div className="form-group mt-3 col-6 frmsel">

                <label className="frmlabel">Region Of Formation</label>
                <Select defaultValue={getValue ? getValue : filter} name="region" options={options}

                  onChange={(e) => { setformData({ ...formData, region_of_formation: e.label }); setIsButtonDisabled(false); }} styles={{
                    control: (provided, state) => ({
                      ...provided,
                      textAlign: 'left',
                      backgroundColor: "#fff",
                      height: "48px",
                      border: "1px solid #ddd",
                      color: "#171717"
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      textAlign: 'left',
                      backgroundColor: state.isFocused ? "#1890ff" : "#ddd"

                    })
                  }}
                  placeholder={'Region Of Formation'}
                />

              </div>


              {/* <div className="form-group mt-3 col-6">
                <label for="region_of_formation" className="font-weight-bold">
                  Region Formation
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="region_of_formation"
                  onChange={handleChange}
                  defaultValue={userState?.region_of_formation}
                  id="region_of_formation"
                  placeholder="Region formation"
                />
              </div> */}
            </div>
            <div className="form-group mt-3">
              <label htmlFor="description" className="font-weight-bold">
                Description
              </label>
              <textarea
                defaultValue={userState?.description}
                placeholder="company description"
                className="form-control form-control-lg"
                name="description"
                id="description"
                onChange={handleChange}
              />
            </div>
            <div className="row">
              <div className="form-group mt-3 col-6">
                <label
                  for="employer_identification_number"
                  className="font-weight-bold"
                >
                  Employer Identification number
                </label>
                <input
                  maxLength={9}
                  pattern="\d*" // Use pattern to allow only digit
                  type="tel"
                  className="form-control form-control-lg"
                  name="employer_identification_number"
                  // defaultValue={formData?.employer_identification_number}
                  Value={userState?.employer_identification_number}

                  onChange={handleChange}
                  id="employer_identification_number"
                  placeholder="E.I.N"
                />
              </div>
            </div>
            <div className="form-group mt-3 ">
              <label for="Address" className="font-weight-bold">
                Address
              </label>
              <div className="places-container">
                <Combobox style={{ zIndex: "9999" }} onSelect={(address) => { handleSelect(address, "companyPlaceId") }}>
                  <ComboboxInput
                    name="address"
                    value={value}
                    onChange={(e) => {
                      setValue(e.target.value);
                    }}
                    disabled={!ready}
                    className="form-control"
                    placeholder="Search an address"
                  />
                  <div style={{ borderWidth: "0 1px 0px 1px", borderStyle: "solid", borderColor: "#ddd" }}>
                    <ComboboxList>
                      {status === "OK" &&
                        data.map(({ place_id, description }) => (
                          <ComboboxOption key={place_id} value={description} style={{ borderBottom: "1px solid #ddd" }} />
                        ))}
                    </ComboboxList>
                  </div>
                </Combobox>
              </div>
              <p style={{ fontSize: "14px" }}>
                Current Address: {userState?.address}
              </p>
            </div>

            <div
              className="row "
              style={{ alignItems: "flex-end", gap: "5px" }}
            >
              <div className="form-group mt-3 col-11">
                <label for="Address" className="font-weight-bold ">
                  Logo (gif,jpeg,png)
                </label>
                <br />
                <br />
                <input
                  type="file"
                  accept="image/*"
                  className="form-control form-control-lg"
                  name="logo"
                  id="logo"
                  onChange={handleChange}
                  placeholder="logo"
                />
              </div>

              <Avatar
                src={userState?.logo}
                style={{
                  verticalAlign: "middle",
                  width: "50px",
                  height: "50px",
                  boxShadow: "1px 1px 4px 1px #d3d3d369",
                }}
                // size={{

                // }}
                icon={<AntDesignOutlined />}
              />
            </div>

            <div className="form-group mt-3 ">
              <label for="emailAddress" className="font-weight-bold">
                Email address
              </label>
              <input
                disabled
                type="email"
                onChange={handleChange}
                defaultValue={userState.email}
                className="form-control form-control-lg"
                name="email"
                id="exampleInputEmail1"
                placeholder="Email"
              />
            </div>

            <div className="row">
              <div className="form-group mt-3 col-6">
                <label for="industries">Industries</label>
                <Antsel
                  mode="multiple"
                  allowClear
                  style={{ width: "100%", borderRadius: "10px" }}
                  placeholder="Please select"
                  name="industries"
                  placement="bottomLeft"
                  defaultValue={userState?.industries?.map((data) => { return ({ value: parseInt(data.id), label: data.title }) })}
                  dropdownStyle={{ zIndex: 100052 }}
                  // onBlur={(e) => {
                  //   if (userState?.industries) {
                  //     setformData({
                  //       ...formData,
                  //       industries: JSON.stringify(
                  //         userState?.industries?.map((value) => Number(value))
                  //       ),
                  //     });
                  //   }
                  //   else if (formData?.industries?.length > 0) {
                  //     setformData({
                  //       ...formData,
                  //       industries: JSON.stringify(
                  //         formData?.industries?.map((value) => Number(value))
                  //       ),
                  //     });
                  //   }
                  // }}
                  onChange={(e) => {
                    setIsButtonDisabled(false);
                    setformData({ ...formData, industries: `${JSON.stringify(e)}` });
                  }}
                >
                  {getAllIndustries.length !== 0
                    ? getAllIndustries.map((val, ind) => {
                      return (
                        <Antsel.Option key={ind} value={parseInt(val.id)} label={val.title}>
                          {val?.title}
                        </Antsel.Option>
                      );
                    })
                    : null}
                </Antsel>
              </div>

              <div className="col-6">
                <FormGroup>
                  <label
                    for="presentatio-checkbox12"
                    className="fw-bold mt-3"
                  >
                    Profile Visiblity
                  </label>
                  <Form.Check
                    type={"checkbox"}
                    label=" Make profile visible only for Accredited investors"
                    id="presentatio-checkbox12"
                    value={userState?.is_level_two_profile}
                    // onChange={handleChange}
                    defaultChecked={userState?.is_level_two_profile}
                    onChange={(e) => {
                      setIsButtonDisabled(false)
                      setLevelTwoCheckBox(e.target.checked);
                      setformData({
                        ...formData,
                        is_level_two_profile: e.target.checked,
                      });
                    }}
                  />
                </FormGroup>
              </div>
            </div>

            {/* ================================= MODAL PRIMARY CONTACT==================================================  */}

            <div className="head my-3">
              <h3 className="text-center">Primary Contact</h3>
            </div>
            <div className="row">
              <div className="form-group col-6">
                <label for="FirstName" className="font-weight-bold">
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="primary_contact_first_name"
                  defaultValue={
                    !userState?.primary_contact_first_name
                      ? ""
                      : userState?.primary_contact_first_name
                  }
                  id="FirstName"
                  onChange={handleChange}
                  placeholder="FirstName"
                />
              </div>

              <div className="form-group col-6">
                <label for="LastName" className="font-weight-bold">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="primary_contact_last_name"
                  onChange={handleChange}
                  defaultValue={
                    !userState.primary_contact_last_name
                      ? ""
                      : userState?.primary_contact_last_name
                  }
                  id="LastName"
                  placeholder="Last Name"
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="form-group col-6">
                <label
                  for="primary_contact_tax_identification_number"
                  className="font-weight-bold"
                >
                  Tax Identification no
                </label>
                <input
                  maxLength={18}
                  pattern="\d*" // Use pattern to allow only digit
                  type="tel"
                  className="form-control form-control-lg"
                  name="primary_contact_tax_identification_number"
                  value={formData?.primary_contact_tax_identification_number}
                  defaultValue={!userState?.primary_contact_tax_identification_number ? "" : userState?.primary_contact_tax_identification_number}
                  id="primary_contact_tax_identification_number"
                  onChange={handleChange}
                  placeholder="Text Indentification no"
                />
              </div>

              <div className="form-group mb-2 mt-3 hide_increament_button col-lg-6">
                <label for="ContactNumber" className="fw-bold mb-2">
                  Contact Number <span> (e.g +923123456789)</span>
                </label>

                <PhoneInput
                  className="form-control hide_increament_button d-flex "
                  placeholder="Enter phone number"
                  defaultCountry={!formData?.primary_contact_number && "US"}
                  countryOptionsOrder={['US', 'CA', 'AU', 'IN']}
                  value={
                    formData?.primary_contact_number
                      ? formData?.primary_contact_number
                      : ""
                  }
                  onChange={(value) => {
                    setIsButtonDisabled(false)
                    setformData({
                      ...formData,
                      primary_contact_number: value,
                    });
                  }}
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="form-group col-6">
                <label for="primary_contact_email" className="font-weight-bold">
                  Contact Email
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  name="primary_contact_email"
                  defaultValue={
                    !userState?.primary_contact_email
                      ? ""
                      : userState?.primary_contact_email
                  }
                  id="primary_contact_tax_identification_number"
                  onChange={handleChange}
                  placeholder="Contact Email"
                />
              </div>
            </div>

            <div className="form-group mt-3 ">
              <label for="primary_contact_address" className="font-weight-bold">
                Address
              </label>
              <div className="places-container">
                <Combobox onSelect={(address) => { handleSelect(address, "primarycompanyPlaceId") }}>
                  <ComboboxInput
                    name="address"
                    value={value1}
                    onChange={(e) => {
                      setValue1(e.target.value);
                    }}
                    disabled={!ready1}
                    className="form-control"
                    placeholder="Search an address"
                  />
                  <div style={{ borderWidth: "0 1px 0px 1px", borderStyle: "solid", borderColor: "#ddd" }}>
                    <ComboboxList>
                      {status1 === "OK" &&
                        data1.map(({ place_id, description }) => (
                          <ComboboxOption key={place_id} value={description} />
                        ))}
                    </ComboboxList>
                  </div>
                </Combobox>
              </div>
              <p style={{ fontSize: "14px" }}>
                Current Address: {userState?.primary_contact_address}
              </p>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            disabled={isButtonDisabled}
            variant="primary"
            onClick={handleSubmint}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>


      {/* ================ MODAL FOR VERIFY CONTACT NUMBER=========================== */}




      <Modal
        show={showTwo}
        onHide={handleCloseTwo}
        dialogClassName="my-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Verify Contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ fontSize: "18px", fontWeight: "400", textAlign: "center", textTransform: "lowercase", lineHeight: "26px" }}>Enter Verification Code Sent On The Following Number:<mark>{userState?.primary_contact_number}</mark> </h3>
          <div className="otpmodal">
            <OTPInput className="otpinputbox" value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="any" disabled={false} />
            <ResendOTP className="otpresendbox" maxTime={120} onResendClick={sendotp} renderButton={renderButton} renderTime={renderTime} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseOtpModal}>
            Close
          </Button>
          <Button variant="primary" className="companyNo_VerifyBtn" disabled={OTP?.length > 5 ? false : true} onClick={verifyotp}>
            Verify
          </Button>
        </Modal.Footer>

      </Modal>
    </div>
  );
};

export default CompanyProfile;



