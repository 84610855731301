import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "react-bootstrap";

import { TextEditor } from "../../../../../component/TextEditor";
import { displayErrorToast, displaySuccessToast, displayWorrningToast } from "../../../../../helper/toast_notification_function";
import { createUpdateAnswers } from "../../../../../api/companyApis/portfolio/FaqApis";

const AddFaq = ({ isOpen, handleToggle, toggleState, state, data ,id}) => {
  const [formData, setFormData] = useState(null);
  const [btnLoader, setBtnLoader] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);


  const handleChange = (e) => {
    setIsDisabled(false);
   
  };


  console.log(formData , "formdata")

  const Submit = () => {
    if (formData) {
    setBtnLoader(true)
      setIsDisabled(true);
      let obj = {answer : formData}

      createUpdateAnswers(id ,obj)
        .then((res) => {
          setIsDisabled(false);
          setFormData("");
          toggleState();
          handleToggle();
    setBtnLoader(false)

          displaySuccessToast("Success");
        })
        .catch((error) => {
    setBtnLoader(false)

          setIsDisabled(false);
          if (error.response.status == 422) {
            displayWorrningToast(error.response.data.message[0]);
          } else {
            displayErrorToast(error);
          }
        });
      }
  };


 


  console.log(data , id  ,isDisabled)
  return (
    <div>
      {/* Modal for Uploading Video Presentation  */}
      <Modal show={isOpen} onHide={handleToggle}>
        <ModalHeader closeButton>Update Answer</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup className="mb-2">
              <FormLabel htmlFor="title">Title</FormLabel>
              <FormControl
                type="text"
                name="heading"
                id="title"
                defaultValue={data?.[0]?.question}
                disabled
              />
              {/* {formError.presentationTitle && (
                  <p>{formError.presentationTitle}</p>
                )} */}
            </FormGroup>
          


            <TextEditor
        // onClick={submit}
        data={data?.[0]}
        setData={ setFormData}
        disabled={true}
        loader={false }
        hideBtn={true}
        handleChange={handleChange}
      />

         
          </Form>
        </ModalBody>
        <Modal.Footer>
          <Button
            // variant={isDisabled ? "secondary" : "primary"}
            disabled={isDisabled}
            onClick={Submit}
          >
            {btnLoader ? <Spinner animation="border" size="sm"/> :  !state ? "Upload" : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End Modal for Uploading Video Presentation  */}
    </div>
  );
};

export default AddFaq;
