import { Button, Image, Popconfirm } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import Table from "./Table";
import MediaMentions from "./MediaMentions";
import {
  DeleteOverViewGalleryApi,
  getOverViewApi,
  getOverViewGalleryApi,
  overViewApi,
  overViewGalleryApi,
} from "../../../../api/companyApis/portfolio/overViewApi"
import { displayErrorToast, displaySuccessToast ,displayWorrningToast} from "../../../../helper/toast_notification_function";
import { FormControl, FormGroup, Spinner  , InputGroup ,Collapse} from "react-bootstrap";
import AddGallery from "./AddGallery";
import { getGalleryApi } from "../../../../api/companyApis/portfolio/galleryAndMedia/galleryAndMedia";
const GalleryAndMedia = () => {
  const [visible, setVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [galleryDataFromApi, setGalleryDataFromApi] = useState([]);
  const [data, setData] = useState("");
  // const [loading, setLoading] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);

  const [overViewDataFromApi, setOverViewDataFromApi] = useState();
  const [isDisable, setIsDisable] = useState(false);

  const [gallery, setGallery] = useState();
  const [galleryBtnDisable, setGalleryBtnDisable] = useState(true);

  const handleToggle = () => {
    setVisible((prev) => !prev);
  };


  const fileInputRef = React.useRef();
  const { Panel } = Collapse;

  const submit = () => {
    //   send data var in api
    let obj = { text: data };

    if (data !== "" || overViewDataFromApi) {
    setLoading(true)

      setIsDisable(true);
      overViewApi(obj)
        .then((res) => {
          if (res.data.success) {
            getData();
            displaySuccessToast("Success");
            setIsDisable(false);
    setLoading(false)

          }
        })
        .catch((err) => {
          displayErrorToast(err);
    setLoading(false)

          setIsDisable(false);
        });
    } else {
      displayWorrningToast("Please enter the data..!");
    }
  };

  const getData = () => {
    setLoading(true);
    getGalleryApi()
      .then((res) => {
        if (res?.data?.success) {
          setOverViewDataFromApi(res?.data?.data[0]);

          setData(res?.data?.data[0]);
          setLoading(false);
        }
      })
      .catch((Err) => {
        setLoading(false);
        displayErrorToast(Err);
      });
  };

  //   submit gallery func
  const submitGallery = () => {
    setBtnLoader(true)
    if (gallery) {
      setGalleryBtnDisable(true);
      let obj = { images: gallery?.gallery_images };

      overViewGalleryApi(obj)
        .then((res) => {
          if (res?.data?.success) {
            getGalleryData();
            displaySuccessToast("Success");
            setGalleryBtnDisable(true);
    setBtnLoader(false)

          }
        })
        .catch((err) => {
          setGalleryBtnDisable(false);
          displayErrorToast(err);
    setBtnLoader(false)

        });
    }
  };

  const getGalleryData = () => {
    setLoading(true);
    getOverViewGalleryApi()
      .then((res) => {
        if (res?.data?.success) {
          setGalleryDataFromApi(res?.data?.data);
          setLoading(false);
        }
      })
      .catch((Err) => {
        setLoading(false);
        displayErrorToast(Err);
      });
  };



  
  const imageDltFn = (id) => {
    DeleteOverViewGalleryApi(id)
      .then((res) => {
        if (res?.data?.success) {
          getGalleryData();
          displaySuccessToast("image deleted!");
        }
      })
      .catch((err) => displayErrorToast(err));
  };

  useEffect(() => {
    getData();
    // getGalleryData();
  }, []);

  return (
    <div className="bg_black">
      <div
        style={{
          width: "100%",
          display: "inline-flex",
          justifyContent: "space-between",
          marginBottom: "17px",
        }}
      >
        <div className="doc_slide">
          <h3>Company Gallery</h3>
        </div>
        <div>
          <button className="addbtnt" onClick={() => setVisible(true)}>
            <AiOutlinePlus color="#fff" size={20} />
          </button>
        </div>
      </div>

      
      <AddGallery
        isOpen={visible}
        handleToggle={handleToggle}
        toggleState={() => setLoading((prev) => !prev)}
      />
      <Table flag={isLoading} />




      
      <div>
          {/* <div className="d-flex justify-content-between align-items-center mt-5 mb-2 doc_slide">
            <div>
              <h3>Company Gallery</h3>
              <p>(Aspect Ratio: 3/2 | 600*400px Min.)</p>
            </div>
            <div>
              <button
                onClick={submitGallery}
                className="addbtnt"
                style={{ width: "80px", fontSize: "16px" }}
                disabled={galleryBtnDisable}
              >
                {btnLoader ? <Spinner size="sm" animation="border"/> : "submit"}
              </button>
            </div>
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <FormGroup className="mb-2 ">
            <label for="gallery_images" className="portFolio_gallery">
              <img src={download} className="" />
              <span className="ms-1">
                Upload Company Logo


              </span>
            </label>
              <InputGroup className="mb-3">
                <FormControl
                  type="file"
                  name="gallery_images"
                  id="gallery_images"
                  accept="image/*"
                  multiple
                  ref={fileInputRef}
                  style={{display:"none"}}
                  onChange={(e) => {
                    setGalleryBtnDisable(false);
                    setGallery({ [e.target.name]: [...e.target.files] });
                  }}
                />
                {fileInputRef?.current?.value && (
                  <InputGroup.Text className="">
                    <span
                      onClick={() => {
                        setGallery(null);
                        setGalleryBtnDisable(true);

                        fileInputRef.current.value = "";
                      }}
                    >
                      ✖
                    </span>
                  </InputGroup.Text>
                )}
              </InputGroup>
            </FormGroup>
          </form> */}

          {/* ==================================  company gallery map && space  =========================================== */}

          {/* <div>
            {galleryDataFromApi.length > 0 ? (
              <div className="imgs_bx row">
                {galleryDataFromApi.map((val, ind) => {
                  return (
                    <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                      <div className="flxbximg">
                        <Image
                          className="imgsh"
                          src={val?.image}
                          preview={{ src: val.image }}
                        />
                        <div>
                          <Popconfirm
                            title="Delete the task"
                            description="Are you sure to delete this task?"
                            onConfirm={() => imageDltFn(val?.id)}
                            onCancel={"cancel"}
                            okText="Yes"
                            cancelText="No"
                          >
                            <button className="btntm">
                              <img src="/images/dlt.svg" />
                            </button>
                          </Popconfirm>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : null}
          </div> */}
        </div>

        <MediaMentions />
    </div>
  );
};

export default GalleryAndMedia;
