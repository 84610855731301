import { useEffect, useState } from "react";
import { BiUserCircle } from "react-icons/bi";
import { BsCartCheck } from "react-icons/bs";
import {
  FaBars,
  FaBriefcase
} from "react-icons/fa";
import { ImCross, ImProfile } from "react-icons/im";
import {
  MdOutlinePayment,
  MdSettings
} from "react-icons/md";
import { RiGalleryLine } from "react-icons/ri";
import { Link, NavLink } from "react-router-dom";
// import { AnimatePresence, } from "framer-;
import DashboardNav from "../Common/DashboardNav";
// import logo from "../../images/logo.png";
import DashboardFooter from "../Common/DashboardFooter";
// import  {ImProfile} from "react-icons/im"
import logo from "../../images/IpLogo.png";

import GridViewIcon from "@mui/icons-material/GridView";
import HistoryIcon from "@mui/icons-material/History";
import { AiOutlineCalendar } from 'react-icons/ai';
const routes = [
  {
    path: "/company/dashboard",
    name: "Dashboard",
    icon: <GridViewIcon />,
    class: "dashbrd_side"
  },
  {
    path: "/company/profile",
    name: "Company Profile",
    icon: <BiUserCircle fontSize={20} />,
    class: "profile_side"
  },
  {
    path: "/company/presentation_videos",
    name: "Video Uploads",
    icon: <RiGalleryLine fontSize={20} />,
    class: "video_present"
  },


  {
    path: "/company/portfolio",
    name: "Manage Portfolio",
    icon: <FaBriefcase fontSize={20} />,
    class: "manage_port"
  },
  {
    path: "/Portfolio",
    name: "View Digital Booth",
    icon: <ImProfile fontSize={20} />,
    class: "view_port_side"
  },
  {
    path: "/company/payments",
    name: "Go Live",
    icon: <MdOutlinePayment fontSize={20} />,
    class: "pymnt_side"
  },
  // {
  //   path: "/company/settings",
  //   name: "Manage My Credit Card",
  //   icon: <MdSettings fontSize={20} />,
  //   class: "manage_crd_side"
  // },
  {
    path: "/company/Transactionlogs",
    name: "Payment History",
    icon: <HistoryIcon />,
    class: "pymnt_hist_side"
  },
  {
    path: "/company/schedule",
    name: "Schedule Calendar",
    icon: <AiOutlineCalendar size={20} />,
    class: "schedule_side"
  },
  {
    path: "/company/user_tables",
    name: "Investor Leads",
    icon: <BsCartCheck fontSize={20} />,
    class: "usr_table_side"
    // style:'absolute'
  },
];

const SideBar = ({ children, scrollTop }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  // const [height, setHeight] = useState(window.innerHeight);
  const [style, setStyle] = useState();
  const toggle = () => setIsOpen(!isOpen);

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  const listener = () => {
    setWidth(window.innerWidth);
    // setHeight(window.innerHeight);
  };

  useEffect(() => {
    if (window.innerWidth < 600) {
      setIsOpen(false)
    }
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener(
        "scroll",
        () => setStyle(window.pageYOffset > 5)
        // setNews(true)
      );
    }
  }, []);

  const closeSideBar = () => setIsOpen(false);



  return (
    <>
      <div className="main-container">
        <div className="sideBar_wrapper">
          <div
            style={{
              //sidebar width for sm_screen        sidebar width for md & lg screens
              width:
                width < 600 && isOpen ? "100%" : width > 600 && isOpen ? "270px" : "45px",
              transition: {
                duration: 0.5,
                type: "spring",
                damping: 10,
              },
            }}
            className={`sidebar ${isOpen ? "open" : ""} `}
          >
            <div className={`top_section ${isOpen ? "d-flex" : "d-block"}`}>
              {/* <AnimatePresence> */}
              <div
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
                style={{
                  color: "white",
                  fontWeight: "700",
                  fontFamily: 'sansation',
                  margin: "10px 0 0 10px",
                }}
              >
                <div style={{ width: "90px" }}>
                  <Link to="/company/dashboard">
                    <img
                      src={logo}
                      style={{
                        width: isOpen ? "70px" : "40px",
                        position: "relative",
                        // bottom: width > 600 ? "1.5rem" : '-0.5rem',
                        // height:width < 600 ? "75px" : "90px",
                        objectFit: "contain",
                        right: !isOpen ? "1.2rem" : "",
                      }}
                      alt="img"
                    />
                  </Link>
                </div>
              </div>
              {/* </AnimatePresence> */}

              <div className="bars mt-3">
                {isOpen ? (
                  <ImCross fontSize={20} color="white" onClick={toggle} />
                ) : (
                  <FaBars fontSize={20} onClick={toggle} />
                )}
              </div>
            </div>

            <section className="routes">
              {routes.map((route, index) => {
                return (
                  <NavLink
                    to={route.path}
                    style={{ paddingLeft: isOpen ? "2rem" : "10px" }}
                    key={index}
                    className={route.path === "/" ? "link" : `link sideBarLink ${route.class}`}
                    activeclassname={
                      route.path === "/" ? "wev" : "sidebarActive"
                    }
                    onClick={width < 600 && closeSideBar}
                  >
                    <div className="icon">{route.icon}</div>
                    {/* <AnimatePresence> */}
                    {isOpen && (
                      <div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text"
                      >
                        {route.name}
                      </div>
                    )}
                    {/* </AnimatePresence> */}
                  </NavLink>
                );
              })}
            </section>
            {/* <div
              className="Sidebar_profile"
              style={{ paddingLeft: isOpen ? "1rem" : "5px" }}
            >
              <SideBarProfile width={width} isOpen={isOpen} />
            </div> */}

          </div>

          <div className="w-100">
            <div
              className={`${width > 600 ? "main_child_pages" : "sm_main_child_pages"} `}>
              <DashboardNav
                width={width}
                isOpen={isOpen}
                children={children}
                style={style}
              />
              <main className={` container-fluid dashboard_child_pages`}>{children}</main>
              <DashboardFooter />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
