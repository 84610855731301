import { Popconfirm } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react'
import { AiFillSave } from 'react-icons/ai';
import { FaDownload } from 'react-icons/fa';
import { FaFileCircleMinus } from 'react-icons/fa6';
import { getSavedDocs, removeSavedDocs } from '../../api/CompaniesForUserApis';
import { AgGridReact } from 'ag-grid-react';
import { displayErrorToast, displaySuccessToast } from '../../helper/toast_notification_function';

const SavedDocs = () => {

    const handleUnsaved = (id) => {
        removeSavedDocs(id).then((res) => {
            console.log(res)
            if (res?.data?.success) {
                displaySuccessToast("Document Unsaved")
                getData()
            }
        }).catch((error) => {
            console.log(error)
            displayErrorToast("Something Bad Happened. Please Try Again")
        })
    }

    const [rowData, setRowData] = useState([]);

    const [columnDefs, setcolumnDefs] = useState([
        {
            headerName: 'S.No',
            sortable: true,
            width: 85,
            valueFormatter: (params) => {
                return `${parseInt(params.node.id) + 1}`;
            },
        },
        {
            headerName: "Company Name",
            field: "company_name",
            flex: 1,
            minWidth: 160,
            maxWidth: 200,
            menuTabs: ['filterMenuTab', 'columnsMenuTab'],
        },
        {
            headerName: "File Name",
            field: "filename",
            flex: 1,
            minWidth: 160,
            maxWidth: 200,
            menuTabs: ['filterMenuTab', 'columnsMenuTab'],
        },
        {
            field: "Last Modified",
            menuTabs: ['filterMenuTab', 'columnsMenuTab'],
            sortable: true,
            maxWidth: 200,
            cellRenderer: p => {
                return (
                    <div>{moment.utc(p.data?.last_modified).format("MM/DD/YYYY")}</div>
                )
            }
        },
        {
            headerName: "Actions",
            autoHeight: true,
            flex: 1,
            cellRenderer: p => {
                return (
                    <div className="doc_btns">
                        <a href={p?.data?.file} download title="Download" className="dwnlddoc" onClick={() => { }}>
                            <FaDownload size={17} color="#171717" />
                        </a>
                        <Popconfirm
                            title="Are you sure to remove from saved documents?"
                            onConfirm={() => { handleUnsaved(p?.data?.fileId) }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <button title="Save" className="savedoc">
                                <FaFileCircleMinus size={18} color="red" />
                            </button>
                        </Popconfirm>
                    </div>
                )
            }
        },

    ]);

    const defaultColDef = useMemo(() => {
        return {
            resizable: true,
            filter: true,
            menuTabs: true,
        };
    }, []);

    const getData = () => {
        console.log("func chala")
        getSavedDocs().then((res) => {
            setRowData(res?.data?.data)
        }).catch((error) => { console.log(error) })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div>
            <div className="ag-theme-alpine" style={{ height: 400, width: "100%" }}>
                <AgGridReact
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    pagination={true}
                    paginationPageSize={10}
                    animateRows={true}
                />
            </div>
        </div>
    )
}

export default SavedDocs