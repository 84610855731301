export const generateColorArray = (numColors) => {
    var colorArray = [];
    for (var i = 0; i < numColors; i++) {
      var red = Math.floor(Math.random() * 566);
      var green =4 + Math.floor(Math.random() * 266);
      var blue =4 + Math.floor(Math.random() * 166);
      colorArray.push(`rgb(${red}, ${green}, ${blue})`);
    }
    return colorArray;
  }
  