import React, { useEffect, useState } from 'react'
import ExploreCards from '../../../../component/Common/ExploreCards'
import { CompanyCardManagePortfolioApi } from '../../../../api/companyApis/portfolio/CompanyCardPortfolioApis'
import { displayErrorToast } from '../../../../helper/toast_notification_function'
import thumbnail from "../../../../images/no_image.jpg"
import { MdOutlineDone } from "react-icons/md"
function CompanyCardManagePortfolio() {

  const [data, setData] = useState()



  const getCard = () => {
    CompanyCardManagePortfolioApi().then((res) => {
      if (res?.data.success) {
        setData(res?.data?.data)
      }
    }).catch((Err) => displayErrorToast(Err))
  }

  useEffect(() => {
    getCard()
  }, [])

  console.log(data)

  return (
    <div className="bg_black">
      <div className="doc_slide">
        <h3>Company Card</h3>
        <div className='d-flex gap-4 flex-wrap mt-5' >
          <ul className='d-flex flex-column gap-3 ps-3' style={{ width: "100%", maxWidth: "320px", minWidth: "280px" }}>
            <li>Company Name {data?.company_name ? <MdOutlineDone style={{ color: "green", marginLeft: "5px", fontSize: "22px", fontWeight: "bold" }} /> : ""}</li>
            <li>Company Description {data?.description ? <MdOutlineDone style={{ color: "green", marginLeft: "5px", fontSize: "22px", fontWeight: "bold" }} /> : ""}</li>
            <li>Total Investment {data?.total_amount ? <MdOutlineDone style={{ color: "green", marginLeft: "5px", fontSize: "22px", fontWeight: "bold" }} /> : ""}</li>
            <li>Minimum Investment {data?.minimum_investment ? <MdOutlineDone style={{ color: "green", marginLeft: "5px", fontSize: "22px", fontWeight: "bold" }} /> : ""}</li>
            <li>Primary Video Thumbnail {data?.primary_video_thumbnail ? <MdOutlineDone style={{ color: "green", marginLeft: "5px", fontSize: "22px", fontWeight: "bold" }} /> : ""}</li>
            <li>Country & State {(data?.country || data?.state) ? <MdOutlineDone style={{ color: "green", marginLeft: "5px", fontSize: "22px", fontWeight: "bold" }} /> : ""}</li>
            <li>Time Left {(data?.active_schedule_start_date || data?.active_schedule_end_date) ? <MdOutlineDone style={{ color: "green", marginLeft: "5px", fontSize: "22px", fontWeight: "bold" }} /> : ""}</li>


            <p className='mt-3 text-white'>This is how your Card would look to the investors</p>
          </ul>


          <ExploreCards
            logo={data?.logo}
            id={data?.id}
            title={data?.company_name}
            desc={data?.description}
            total_investment={data?.total_amount ? data.total_amount : "-"}
            img={data?.primary_video_thumbnail ? data.primary_video_thumbnail : thumbnail}
            minimum_investment={data?.minimum_investment ? data.minimum_investment : null}
            state={data?.state ? data.state : ""}
            country={data?.country ? data.country : ""}
            start_date={data?.active_schedule_start_date ? data.active_schedule_start_date : ""}
            end_date={data?.active_schedule_end_date ? data.active_schedule_end_date : ""}

          // onClick={() => companybyIdHandle(val?.id)}
          />
        </div>

      </div>
    </div>
  )
}

export default CompanyCardManagePortfolio