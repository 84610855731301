import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Form, FormGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { DateRange } from "../../../component/payments/DateRange";
import { displayWorrningToast } from "../../../helper/toast_notification_function";
import useWindowDimensions from "../../../component/WindowDimensions";

export default function Payments({
  checkBoxValue,
  changedate,
  disabledate,
  type,
  sameDateRange,
  promoResDetails,
}) {
  const [minimumdays, setminimumdays] = useState(null);
  const { paymentPerDay } = useSelector(
    (state) => state.getPaymentPerDayReducer
  );

  const [dateRange, setDateRange] = useState([]);
  const [daysBetween, setDaysBetween] = useState(0);
  const [finalAmount, setFinalAmount] = useState();
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [checkval, setcheckval] = useState(false);
  const [discount, setDiscount] = useState([]);
  const { width, height } = useWindowDimensions();


  useEffect(() => {
    if (!(sameDateRange?.length > 0) && !(dateRange?.length > 0)) {
      setDaysBetween(0);
    }
  }, [sameDateRange]);
  useEffect(() => {
    setminimumdays(type?.minimum_days);
  }, [type]);

  useEffect(() => {
    setcheckval(checkBoxValue);
    setIsDisable(!checkBoxValue)
  }, [checkBoxValue]);

  useEffect(() => {
    if (checkval) {
      if (minimumdays != undefined && minimumdays != null && daysBetween > 0) {
        if (daysBetween >= minimumdays) {
          changedate(
            "payment_date",
            dateRange,
            "total_paymnt",
            daysBetween * type?.charges_per_day
          );
        } else {
          displayWorrningToast(
            `Minimum days for make payment is: ${minimumdays}`
          );
          setDateRange([]);
        }
      }
    }
    else {
      changedate(
        "payment_date",
        [],
        "total_paymnt",
        0
      );
    }
  }, [daysBetween]);

  useEffect(() => {
    if (dateRange == undefined) {
      setDaysBetween(0)
      changedate(
        "payment_date",
        [],
        "total_paymnt",
        0
      );
    }
  }, [dateRange])
  function handlecheck(e) {
    setcheckval(!checkval);
    if (e.target.checked) {
      changedate(
        e.target.name,
        parseInt(e.target.value),
        "total_paymnt",
        daysBetween * type?.charges_per_day
      );
      setIsDisable(false);
    } else {
      changedate(e.target.name, 0, "total_paymnt", 0);
      setDateRange([null, null])
      setDaysBetween(0);
      setIsDisable(true);
    }
  }

  useEffect(() => {
    let filter = promoResDetails?.filter((item) => {
      return item.type_id == type?.id;
    });
    setDiscount(filter);
  }, [promoResDetails]);

  return (
    <div id="paymentsPage">
      {/* <div className="PaymentsPage_wrapper ">
        <div className="toggle_box d-flex align-items-center row paymentRow">

          <div className="col-lg-1 col-md-2 col-6 d-flex justify-content-md-center align-items-center">
            <FormGroup>
              <Form.Check
                type={"checkbox"}
                id="presentatio-checkbox12"
                onChange={(e) => { handlecheck(e) }}
                checked={checkval}
                value={type?.id}
                name="payment"
              />
            </FormGroup>
          </div>

          <div className="common_dateRange col-lg-7 col-md-6 col-sm-12 mt-md-0 mt-2 order-md-2 order-3">
            <h4 className="col-12 mb-3 mt-2 ">{type?.title} $ {type?.charges_per_day}/day </h4>
            <div className={disabledate ? "opacityDown" : "opacityUp"}>

              <DateRange
                disabled={disabledate ? disabledate : isDisable ? isDisable : false}
                disablePastDate={true}
                setDateRange={(value) => { setDateRange(value); setIsDisable(false); }}
                dateRange={sameDateRange?.length > 0 ? sameDateRange : dateRange}
                setDaysBetween={setDaysBetween}
                daysBetween={daysBetween}
                setFinalAmount={setFinalAmount}
                paymentPerDay={paymentPerDay}
              />
            </div>
          </div>

          <div className="common_toggle col-lg-4 col-md-4 col-6 order-md-3 order-2">
            <div className="charge_rate" style={{ fontSize: '18px' }}>Total Days: {daysBetween ? daysBetween : "0"}</div>
            <div className="charge_rate" style={{ fontSize: '18px' }}>Total: <span style={{ fontSize: "16px" }}>$ </span>{(sameDateRange?.length > 0 || dateRange?.length > 0) ? daysBetween ? discount?.length > 0 ? <><span style={{ fontSize: "18px" }}>{((daysBetween * type?.charges_per_day) - (discount?.[0].discount))}</span> <del style={{ fontSize: "18px", color: 'rgba(255, 255, 255, .7)' }}>{daysBetween * type?.charges_per_day}</del></> : <span style={{ fontSize: "18px" }}>{daysBetween * type?.charges_per_day}</span> : "0" : "0"}</div>

          </div>
        </div>


      </div> */}


      <div className=" row  ">
        <div className=" col-md-9 paymentRows">
          <div className="common_dateRange flex-md-row flex-column d-flex gap-2">


            {width > 767 &&
              <div className="ps-1 mt-2">
                <FormGroup>
                  <Form.Check
                    type={"checkbox"}
                    id="presentatio-checkbox12"
                    onChange={(e) => {
                      handlecheck(e);
                    }}
                    checked={checkval}
                    value={type?.id}
                    name="payment"
                  />
                </FormGroup>
              </div>
            }

            <div className="col-md-11 col-12">
              <div className=" col-12 d-flex justify-content-between align-items-center flex-wrap">
                <div className="d-flex align-items-start gap-2">
                  {width < 767 &&
                    <FormGroup>
                      <Form.Check
                        type={"checkbox"}
                        id="presentatio-checkbox12"
                        onChange={(e) => {
                          handlecheck(e);
                        }}
                        checked={checkval}
                        value={type?.id}
                        name="payment"
                      />
                    </FormGroup>
                  }
                  <h4 className="mb-3 mt-md-2 paymentHead">
                    {type?.title} $ {type?.charges_per_day}/day{" "}
                  </h4>
                </div>
                <div className={disabledate ? "opacityDown" : "opacityUp"}>
                  <DateRange
                    disabled={
                      disabledate ? disabledate : isDisable ? isDisable : false
                    }
                    disablePastDate={true}
                    setDateRange={(value) => {
                      setDateRange(value);
                      setIsDisable(false);
                    }}
                    dateRange={
                      sameDateRange?.length > 0 ? sameDateRange : dateRange
                    }
                    setDaysBetween={setDaysBetween}
                    daysBetween={daysBetween}
                    setFinalAmount={setFinalAmount}
                    paymentPerDay={paymentPerDay}
                  />
                </div>
              </div>
              <p className="text-secondary mt-1">{type?.description}</p>
            </div>
          </div>
        </div>


        <div className=" common_dateRange mt-3 mt-md-0 col-md-3 d-flex justify-content-center flex-column align-items-center">


          <div className="charge_rate text-white" >Total Days: {daysBetween}</div>
          <div className="charge_rate text-white" >Total: <span style={{ fontSize: "16px" }}>$ </span>{sameDateRange?.length > 0 || dateRange?.length > 0 ? (daysBetween ? (discount?.length > 0 ? (<><span style={{ fontSize: "18px" }}>{daysBetween * type?.charges_per_day - discount?.[0].discount}</span> <del style={{ fontSize: "18px", color: "rgba(255, 255, 255, .7)", }}>{daysBetween * type?.charges_per_day}</del></>) : (<span style={{ fontSize: "18px" }}>{daysBetween * type?.charges_per_day}</span>)) : ("0")) : ("0")}
          </div>

        </div>
      </div>


      {/* <div class="container px-4">
  <div class="row gx-5">
    <div class="col">
     <div class="p-3 border bg-light">Custom column padding</div>
    </div>
    <div class="col">
      <div class="p-3 border bg-light">Custom column padding</div>
    </div>
  </div>
</div> */}

    </div>
  );
}
