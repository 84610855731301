import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { RxDotFilled } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { SCheduleCalendarsAction } from "../../redux/actions/scheduleAction";
// import { DateFormat } from "../../helper/DataFormat";
const Schedule = () => {
  const [ProcessLoading, setProcessLoading] = useState(false);
  const [Data, setData] = useState([]);
  const { ActiveSchedule, FeactureSchedule, DownloadSchedule } = useSelector(
    (state) => state.ScheduleReducer
  );

  const DateFormat = (Data, values) => {
    const data = Data.split("/");
    let date = data[0];
    let month = data[1];
    let year = data[2];
    let new_month = month.length == 1 ? "0".concat(month) : month;
    let new_date = date.length == 1 ? "0".concat(date) : date;
    const today = new Date(`${year}-${new_month}-${new_date}`);
    let datetime = today.getDate().toString();
    let monthtime = (today.getMonth() + 1).toString();
    let yeartime = today.getFullYear();
    // if (values === "end") {
    //   today.setHours(23, 59, 59, 0);
    // }
    return `${today.toISOString()}`;
  };
  useEffect(() => {
    const ActiveData = ActiveSchedule.map((ele) => {
      return {
        color: "#3f54b1",
        start: DateFormat(ele.start_date, "start"),
        end: DateFormat(ele.end_date, "end")
      };
    });
    const FeactureData = FeactureSchedule.map((ele) => {
      return {
        color: "#3bb479",
        start: DateFormat(ele.start_date, "start"),
        end: DateFormat(ele.end_date, "end")
      };
    });
    const DownloadData = DownloadSchedule.map((ele) => {
      return {
        color: "#f5be40",
        start: DateFormat(ele.start_date, "start"),
        end: DateFormat(ele.end_date, "end")
      };
    });
    setData([...ActiveData, ...FeactureData, ...DownloadData]);
  }, [ActiveSchedule, FeactureSchedule, DownloadSchedule]);
  const dispatch = useDispatch();
  useEffect(() => {
    SCheduleCalendarsAction(dispatch, setProcessLoading);
  }, []);
  return (
    <Box m="20px">
      <div className="mt-md-5">
        <h1 className="commonH2 schedule_heading">Active Dates of Exposure </h1>
      </div>
      <Box display="flex" justifyContent="space-between" width={"100%"}>
        <Box className="bgDropBlur" sx={{ width: "100%" }} borderRadius={7}>
          <div className="flexboxcalendar">
            <div className="legend_box_calendar">
              <Box>
                <p className="schedule-text">
                  <RxDotFilled
                    color="#3f54b1"
                    size={30}
                    className="schedule_point d-inline text-center"
                  ></RxDotFilled>
                  Active Schedule
                </p>
              </Box>


              <Box>
                <p className="schedule-text">
                  <RxDotFilled
                    color="#3bb479"
                    size={30}
                    className="schedule_point d-inline text-center"
                  >

                  </RxDotFilled>
                  Feature Schedule
                </p>
              </Box>
              <Box>
                <p className="schedule-text">
                  <RxDotFilled
                    color="#f5be40"
                    size={30}
                    className="schedule_point d-inline text-center"
                  >

                  </RxDotFilled>
                  Download Schedule
                </p>
              </Box>
            </div>
            <div className="calendar_box">
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                themeSystem="darkly"
                headerToolbar={{
                  left: "prev,next",
                  center: "",
                  right: "title",
                }}
                initialView="dayGridMonth"
                events={Data}
              />
            </div>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default Schedule;
