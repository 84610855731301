import OTPInput, { ResendOTP } from "otp-input-react";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import Joyride from 'react-joyride';
import { useDispatch } from "react-redux";
import { GetCompanyProfileApi, companySentOtpForNumber, companyVerifyOtpForNumber } from "../../../api/companyApis/CompanyProfileApis";
import Divider from "../../../component/Common/Divider";
import { displayErrorToast, displaySuccessToast } from "../../../helper/toast_notification_function";
import { handleGetCompanyPersonalInfo } from "../../user/Company";
import Analictics from "./Analictics";
import ViewsTable from "./ViewsTable";
import useWindowDimensions from "../../../component/WindowDimensions";

export default function Dashboard() {

  const dispatch = useDispatch()
  const [OtpBar, setOtpBar] = useState(true)
  const [phoneVerifyChk, setPhoneVerifyChk] = useState(false)
  const [OTP, setOTP] = useState("");
  const [OTPdata, setOTPdata] = useState({});
  const [showTwo, setShowTwo] = useState(false);
  const handleCloseTwo = () => setShowTwo(false);



  const joyrideSteps = [


    {
      target: ".sidebar .dashbrd_side",
      content: "Welcome to company dashboard.",
      disableBeacon: true
    },
    {
      target: ".sidebar .pymnt_side",
      content: "You can make your payments or schedule or live days here.",
      disableBeacon: true
    },
    {
      target: ".sidebar .video_present",
      content: "You can manage your videos here.",
      disableBeacon: true
    },
    {
      target: ".sidebar .manage_port",
      content: "You can manage your portfolio here.",
      disableBeacon: true
    },
    {
      target: ".sidebar .view_port_side",
      content: "You can view your published portfolio here.",
      disableBeacon: true
    },
    {
      target: ".sidebar .usr_table_side",
      content: "You can check your users data here.",
      disableBeacon: true
    },
    {
      target: ".sidebar .profile_side",
      content: "You can see your profile here.",
      disableBeacon: true
    },
    {
      target: ".sidebar .manage_crd_side",
      content: "You can manage or integrate your credit card here...",
      disableBeacon: true
    },
    {
      target: ".sidebar .pymnt_hist_side",
      content: "You can view your transaction history here...",
      disableBeacon: true
    },
    {
      target: ".sidebar .schedule_side",
      content: "You can view all your schedule bookings ...",
      disableBeacon: true
    }
  ]
  const [runJoyride, setRunJoyride] = useState(false); // Control whether to start/stop the joyride
  const { width, height } = useWindowDimensions();

  const handleJoyrideCallback = (data) => {
    if (data.action === 'finished' || data.action === 'skipped') {
      setRunJoyride(false);
    }
  };
  useEffect(() => {
    const first_login = JSON.parse(localStorage.getItem("first_login"))

    if (first_login) {
      setRunJoyride(true)
      localStorage.setItem("first_login", "false")
    }
    else {
      setRunJoyride(false)
    }
  }, [])

  const handleGetProfile = () => {
    GetCompanyProfileApi()
      .then((response) => {
        setPhoneVerifyChk(response?.data?.data?.[0])

      })
      .catch((err) => {
        setOTP("")
        displayErrorToast(err)
      });
  };

  const handleCloseOtpModal = () => {
    setOTP("")
    setShowTwo(false)
  };

  // function handleCancel() {
  //   message.error('Action canceled');
  // }

  const sendotp = () => {
    setOTP("")
    companySentOtpForNumber().then((res) => {
      setShowTwo(true)
    }).catch(error => {

      // setShowTwo(false)
      displayErrorToast(error)
    })
  }

  const verifyotp = () => {
    companyVerifyOtpForNumber(OTPdata).then((res) => {
      handleCloseOtpModal()
      if (res.data.success) {
        displaySuccessToast("Success")
        handleGetProfile()

      }
    }).catch(error => displayErrorToast(error))

  }

  const renderButton = (buttonProps) => {
    return <button {...buttonProps} className={`btn btn-md ${buttonProps.disabled ? "btn-secondary" : "btn-primary blbtn"}`}>Resend</button>;
  };
  const renderTime = (remainingtime) => {
    return <span>Try Again in: {remainingtime} Seconds</span>;
  };

  useEffect(() => {
    setOTPdata({ otp_code: OTP })
  }, [OTP])


  useEffect(() => {
    handleGetProfile()
    handleGetCompanyPersonalInfo(dispatch)

  }, [])



  const confirmAction = () => {
    // const response = confirm("Are you sure you want to do that?");

    let text;
    if (window.confirm("Are you sure you want to do that?") == true) {
      text = "You pressed OK!";
      handleCloseOtpModal()
    } else {
      text = "You canceled!";
    }


  }


  console.log(phoneVerifyChk, "phoneVerifyChk")

  return (
    <div className="py-5 px-3 px-sm-5">

      {phoneVerifyChk?.primary_contact_number ?
        phoneVerifyChk &&
          !phoneVerifyChk.is_phone_verified ?
          <div className={` company_verify_bar ${!OtpBar && "d-none"}`}>
            {width > 551 ? (
    <>
           Your Contact Number is not verified. <span onClick={sendotp}>Click Here</span> to verify your number.
      
    </>
  ) 
  : 
  (
    <div> 
           <span onClick={sendotp}>Click Here</span>&nbsp;to verify your number.
           

    </div>
  )
}
              <div className="close-ic" onClick={() => { setOtpBar(false) }}><AiOutlineClose size={`16px`} color="white" /></div>
            </div>

          :
          null
        : null
      }
      <Joyride
        steps={joyrideSteps}
        run={runJoyride}
        continuous={true}
        showSkipButton={false}
        callback={handleJoyrideCallback}

      // Additional Joyride props...
      />

      <div className="analytics">

        <Analictics />
      </div>
      <Divider space={10} />
      <div className="login">

        <ViewsTable />
      </div>
      <Divider space={30} />
      {/* <div className={ovrlay?"ovrlay": ""}></div> */}

      {/* ================ MODAL FOR VERIFY CONTACT NUMBER=========================== */}




      <Modal
        show={showTwo}
        onHide={handleCloseTwo}
        dialogClassName="my-modal"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header >
          <Modal.Title>Verify Contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ fontSize: "18px", fontWeight: "400", textAlign: "center", textTransform: "lowercase", lineHeight: "26px" }}>Enter Verification Code Sent On The Following Number:<mark>{phoneVerifyChk?.primary_contact_number}</mark> </h3>
          <div className="otpmodal">
            <OTPInput className="otpinputbox" value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="any" disabled={false} />
            <ResendOTP className="otpresendbox" maxTime={120} onResendClick={sendotp} renderButton={renderButton} renderTime={renderTime} />
          </div>
        </Modal.Body>
        <Modal.Footer>

          {/* <Popconfirm
            style={{zIndex:"100000 !important"}}
            title="Are you sure you want to cancel ?"
            onConfirm={handleCloseOtpModal}
            onCancel={handleCancel}
            okText="Yes"
            cancelText="No"
            >
            </Popconfirm> */}
          <Button variant="secondary" onClick={confirmAction}>
            Close
          </Button>

          <Button variant="primary" className="companyNo_VerifyBtn" disabled={OTP?.length > 5 ? false : true} onClick={verifyotp}>
            Verify
          </Button>
        </Modal.Footer>

      </Modal>



    </div>
  );
}


