import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export default function DashboardFooter() {
  const [login_state, setlogin_state] = useState(null)
  const [date, setDate] = useState();

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    setlogin_state(localStorage.getItem("IP_State"))
  }, [])


  const getYear = () => setDate(new Date().getFullYear());


  useEffect(() => {
    getYear();
  }, []);

  const openPreferenceModal = () => {
    if (window.displayPreferenceModal) {
      window.displayPreferenceModal();
    } else {
      console.error('Termly preference modal not available.');
    }
  };


  return (
    <div className="second_footer pt-3 pb-1">
      <a
        href="#nav"
        onClick={() => { scrollTop() }}
        className='backtotop'
      >
        <i class="fa-solid fa-angle-up"></i>
      </a>


      {/* <a href="#"
      onclick="window.displayPreferenceModal();return false"
      id="termly-consent-preferences">Consent Preferences</a> */}

      <ul className='flex-sm-row flex-column d-flex justify-content-center align-items-center gap-3 list-inline pb-4'>
        {/* <li><Link className="text-secondary">Privacy Policy</Link></li>
        <li><Link className="text-secondary">Cookies</Link></li>
        <li><Link className="text-secondary">Support</Link></li>
      <li><Link className="text-secondary">Faq</Link></li> */}
        {
          login_state == null && <>
            <li><Link to={"/"} className="text-secondary">Home</Link></li>
            <li><Link to={"/about"} className="text-secondary">About</Link></li>
            <li><Link to={"/contact"} className="text-secondary">Contact</Link></li>
            <li><Link to={"/leadGeneration"} className="text-secondary">Post a Presentation</Link></li>
          </>
        }
        {/* <li><Link to={"/leadGeneration"} className="text-secondary">Post a Presentation</Link></li> */}
        <li><Link to={"/terms&conditions"} className="text-secondary">Terms and Conditions</Link></li>
      </ul>

      <hr />

      <div className='bottomConcentDiv'>
        <p></p>
        <p className="text-center text-secondary mt-2 " style={{ fontSize: ".8rem" }}>
          Copyright © {date} Investor Presentations
        </p>

        <a
          href="javascript:void(0)"
          onClick={openPreferenceModal}
          id="termly-consent-preferences"
        >
          Consent Preferences
        </a>


      </div>

    </div>
  )
}
