import { Row, Col } from "antd";
import React from "react";
import totalInvest from "../../../images/totalInvest.png";
import minInvest from "../../../images/minInvest.png";
import individuals from "../../../images/individual.png";
import Eval from "../../../images/companyEval.png";
import Offering from "../../../images/companyShared.png";
import highlight from "../../../images/highlight.png"
import NumberFormatter from "../../../helper/NumberFormattar";

function HighLights({ data }) {

  return (
    <Row className="mt-3">
      <Col span={12} xs={24} sm={24} md={24} lg={24} xl={24}>
        <ul id="ulBulletsChange">
          <div className="fundraiseDiv">
            <img src={totalInvest} alt="icon" />
            <li className="p-3 text-white ">
              <span>Total Investment received:</span> US $ <NumberFormatter number={data?.company_fundraise_highlights?.total_amount} />
            </li>
          </div>
          <div className="fundraiseDiv">
            <img src={minInvest} alt="icon" />
            <li className="p-3 text-white ">
              <span>Minimum investment amount:</span>  US $ <NumberFormatter number={data?.company_fundraise_highlights?.minimum_investment} /> per investor
            </li>
          </div>
          <div className="fundraiseDiv">
            <img src={individuals} alt="icon" />
            <li className="p-3 text-white ">
              <span>Security Type:</span> {data?.company_fundraise_highlights?.security_type}
            </li>
          </div>
          <div className="fundraiseDiv">
            <img src={Offering} alt="icon" />
            <li className="p-3 text-white ">
              <span>Offering Type:</span> {data?.company_fundraise_highlights?.offering_type}
            </li>
          </div>
          <div className="fundraiseDiv">
            <img src={Eval} alt="icon" />
            <li className="p-3 text-white ">
              <span>Company Valuation:</span> US $ <NumberFormatter number={data?.company_fundraise_highlights?.valuation} />
            </li>
          </div>
        </ul>
      </Col>

      {/* <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
        <ul id="ulBulletsChange">
          {data?.company_highlights?.length > 0
            ? data.company_highlights.map((val, ind) => {
              return (
                <>
                  <div className="company_high">
                    <div className={`row  companyHighCard `}>
                      <div
                        className={` col-12 card p-sm-3 py-0 px-3 gap-2 highlightContentWrapper`}
                        style={{ background: "transparent", border: "none ", boxShadow: "none !important" }}
                      >
                        <div className="d-flex gap-4">
                          <img src={highlight} style={{ objectFit: "contain", width: "70px" }} alt="icon" />
                          <h1 className="fw-bold mt-3 highlightDigit">0{ind + 1}</h1>
                        </div>
                        <p className="text-white p-3">{val.content}</p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
            : null}
        </ul>
        <div className="mt-5">
        {cards.map((val, ind) => {
          return (
          
          );
        })}
      </div>
      </Col> */}

      {/* <Col span={24} className="p-3 my-3">
        <Title level={5}>
          “Purchased securities are not listed on any exchange. A secondary
          market for these securities does not currently exist and may never
          develop. You should not purchase these securities with the expectation
          that one eventually will.”
        </Title>
      </Col> */}
    </Row>
  );
}

export default HighLights;
