export const START_GETTING_ACTIVE_SCHEDULE = "START_GETTING_ACTIVE_SCHEDULE"
export const SAVE_GETTING_ACTIVE_SCHEDULE = "SAVE_GETTING_ACTIVE_SCHEDULE"
export const ERROR_GETTING_ACTIVE_SCHEDULE = "ERROR_GETTING_ACTIVE_SCHEDULE"



// ===================== TRANSCTION LOGS CONSTANT======================

export const START_GETTING_TRANSACTION_LOGS = "START_GETTING_TRANSACTION_LOGS"
export const SAVE_GETTING_TRANSACTION_LOGS = "SAVE_GETTING_TRANSACTION_LOGS"
export const ERROR_GETTING_TRANSACTION_LOGS = "ERROR_GETTING_TRANSACTION_LOGS"