import { apiHandle } from "../../../apiHandler";

export const createDataroomApi = (body) => {
  return apiHandle(true, true).post(`/company/auth/dataRoom`, body);
};

export const updateDataroomApi = (id, body) => {
  return apiHandle(true, true).put(`/company/auth/dataRoom/${id}`, body);
};

export const deleteDataroomApi = (id) => {
  return apiHandle(true, true).delete(`/company/auth/dataRoom/${id}`);
};

export const getDataroomApi = (id) => {
  return apiHandle().get(`/company/auth/dataRoom/${id ? id : ""}`);
};
