import { Button, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import {
  getMarketLandScapeApi,
  marketLandScapeGraphApi,
} from "../../../../api/companyApis/portfolio/marketLandscapeApi";
import {
  displayErrorToast,
  displaySuccessToast,
  displayWorrningToast,
} from "../../../../helper/toast_notification_function";

export default function GraphDataInMarketLandscape() {
  const [data, setData] = useState([{ year: null, amount: null }]);

  const [graphDataFromApi, setGraphDataFromApi] = useState([]);
  const [btnLoader, setBtnLoader] = useState(false);

  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(0);

  const currentYear = new Date().getFullYear(); // get current year
  const yearsArray = []; // create an empty array to store the years

  for (let i = 0; i < 50; i++) {
    yearsArray.push(currentYear - i); // subtract i from current year and push it to the array
  }

  // for (let year = currentYear; year >= currentYear - numPastYears; year--) {
  //   years.push(year);
  // }


  const formHandler = (e) => {
    e.preventDefault();
    if (data.amount && data.year) {
      // if (data.length) {
      // let tempData = [...finalData];
      // tempData.push(data);
      // setFinalData(tempData);
      // setData({ year: "", amount: "" });
      // const parser = data.year ?

      // let parser = parseFloat(data.keys)

      let obj = { graph: [...graphDataFromApi, data] };

      marketLandScapeGraphApi(obj)
        .then((res) => {
          if (res?.data?.success) {
            displaySuccessToast("Task Added");
            getDataFromAPi();

            console.warn(res);
          }
        })
        .catch((err) => displayErrorToast(err));

      console.warn(obj, data, "submit");
      // }
    }
  };




  const getDataFromAPi = () => {
    setLoading(true);
    getMarketLandScapeApi()
      .then((res) => {
        if (res?.data?.success) {
          setLoading(false);
          setGraphDataFromApi(res?.data?.data?.[0]?.graph);
        }
      })
      .catch((err) => {
        displayErrorToast(err);
      });
    setLoading(false);
  };

  const filterdArray = React.useMemo(() => {
    return data.filter((item) => item.year !== "" && item.amount !== 0);
  }, [data]);

  const onSubmit = () => {
    let body = {
      graph: filterdArray,
    };
    setBtnLoader(true)


    marketLandScapeGraphApi(body)
      .then((res) => {
        getApi();
        displaySuccessToast("graph set Submited");
        setBtnLoader(false)

      })
      .catch((error) => {
        displayErrorToast(error);
        setBtnLoader(false)

      });
  };

  const getApi = () => {
    setLoading(true);
    getMarketLandScapeApi()
      .then((re) => {
        if (re.data?.data[0]?.graph) {
          let array = re.data?.data[0].graph;
          if (re.data?.data[0]?.graph?.length < 6) {
            array.push({ year: null, amount: 0 });
          }
          setData(array);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        displayErrorToast(error);
      });
  };
  useEffect(() => {
    getApi();
  }, []);

  const handleChange = (e, index) => {
    setIndex(index);
    const { name, value } = e.target;
    console.log(parseInt(value.replace(/,/g, '')))
    let arr = [...data];
    if (name == "amount") {
      if (parseInt(value.replace(/,/g, '')) > 0) {
        arr[index][name] = parseInt(value.replace(/,/g, ''));
        setData(arr);
      } else {
        displayWorrningToast("amount should be positive");
      }
    } else if (name === "year") {
      arr[index][name] = parseInt(value);
      setData(arr);
    }
  };

  const handleKeyPress = (e) => {
    // Check if the pressed key is a number or a dot
    const isNumericOrDot = /^[0-9.]*$/.test(e.key);
    // If the pressed key is not a number or a dot, prevent default action (typing)
    if (!isNumericOrDot) {
      e.preventDefault();
    }
  };

  const addNewRow = (index) => {
    let arr = [...data];

    if (
      arr[index].amount !== "" &&
      data.length == index + 1 &&
      data.length <= 6
    ) {
      arr.push({ year: null, amount: 0 });
      setData(arr);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner animation="grow" variant="primary" />
      </div>
    );
  }

  const year = new Date().getFullYear();
  const years = Array.from(new Array(50), (val, index) => index + year);
  return (
    <div className="d-flex flex-column  w-100">
      <div className="d-flex justify-content-end w-100" style={{ marginBottom: "1rem" }}>
        <button onClick={onSubmit} className="addbtnt" style={{ width: "80px" }}>
          {btnLoader ? <Spinner animation="border" size="sm" /> : "Submit"}
          {/* <AiOutlinePlus color="#40A9FF" size={20} /> */}
        </button>
      </div>


      <div className="doc_slide">
        <h3>Market Landscape By Year</h3>
      </div>





      <Row>
        {data.length > 0 ? (
          <Table striped bordered hover size="md" className="dataRoomTable">
            <thead className="text-center">
              <th style={{ fontFamily: 'sansation', fontSize: "14px", fontWeight: "600" }}>S.no</th>
              <th style={{ fontFamily: 'sansation', fontSize: "14px", fontWeight: "600" }}>Amount</th>
              <th style={{ fontFamily: 'sansation', fontSize: "14px", fontWeight: "600" }}>Year</th>
              {/* <th>Action</th> */}
            </thead>

            <tbody>
              {data?.map((val, index) => {
                return (
                  <>
                    <tr>
                      <td>
                        <div style={{ color: "#fff", display: "flex", justifyContent: "center", alignItems: "center", height: "38px" }}>
                          {index + 1}
                        </div>
                      </td>

                      <td>
                        <div className="market_input_div">
                          <input
                            required
                            type="text"
                            className="form-control"
                            name="amount"
                            onKeyUp={handleKeyPress}
                            onChange={(e) => handleChange(e, index)}
                            // onBlur={() => addNewRow(index)}
                            value={val.amount ? Intl.NumberFormat("en-US").format(val?.amount) : null}
                            id="CompanyName"
                            placeholder="Amount"
                          />
                          <span className="dollar-sign">$</span>
                        </div>
                      </td>

                      <td>
                        <div>
                          <select
                            className="form-control "
                            name="year"
                            onChange={(e) => handleChange(e, index)}
                            value={val?.year ? val?.year : ""}
                          >
                            <option value="" disabled hidden>
                              Select a year
                            </option>

                            {years.map((year, index) => {
                              return (
                                <>
                                  <option
                                    key={`year${index}`}
                                    // value={val?.year ? val?.year : year}
                                    defaultValue={val?.year ? val?.year : ""}
                                  >
                                    {year}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        ) : null}

        {data.length == 6 ? null : (
          <div style={{ float: "right", marginBottom: "1rem" }}>
            <Button onClick={() => addNewRow(index)}>
              <AiOutlinePlus color="#40A9FF" size={20} />
            </Button>
          </div>
        )}
      </Row>
    </div>
  );
}
