import { apiHandle } from "../../../apiHandler";

export const createCompanyHighlightsApi = (value) => {
  return apiHandle().post(`/company/auth/highlight/`, { content: value });
};
export const getCompanyHighlightsApi = () => {
  return apiHandle().get(`/company/auth/highlight/`);
};

export const updateCompanyHighlightsApi = (id, content) => {
  return apiHandle().put(`/company/auth/highlight/${id}`, { content });
};

export const deleteCompanyHighlightsApi = (id) => {
  return apiHandle().delete(`/company/auth/highlight/${id}`);
};
