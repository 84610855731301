import React, { useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FormUser from "../component/login/formUser";

const LoginNew = () => {
  const navigate = useNavigate();
  const { appState } = useSelector(
    (state) => state.AppStateReducer
  );

  useLayoutEffect(() => {
    if (appState == "USER") {
      navigate("/user/company");
    } else if (appState == "COMPANY") {
      navigate("/company/dashboard");
    }
  }, [appState]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="bg-img">
      <div className="py-5 container">
        <div className="mt-5 py-md-3">
          <div className="col-12 col-lg-7 "></div>

          <div className=" row pt-3  ">
            <div className=" col-12">
              <div className="col-12 col-lg-5 mx-auto">
                <div
                  //   style={{
                  //     border: "none",
                  //     boxShadow:
                  //       " rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
                  //   }}
                  className="card p-3 "
                >
                  <FormUser />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginNew;
