import React from "react";
import Slider from "react-slick";
import { Row, Typography, Space, Image } from "antd";
import { Button } from "react-bootstrap";

function OverviewInProfile({ overview }) {
  const { Title } = Typography;


  const settings = {
    dots: true,
    // infinite: overview?.images?.length > 1 ? true : false,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <div>
      {
        overview?.text && (
          <p className="text-white overViewHtmlContent" dangerouslySetInnerHTML={{ __html: overview?.text }} />
        )
      }
      <div className="row" >
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <Slider {...settings} className="slider-overview">
            {overview?.images ? (
              overview?.images?.map((val, ind) => {
                return (
                  <div>
                    <div className="glry-img-cont">
                      <img src={val?.image} alt="Silder images" />
                    </div>
                  </div>
                );
              })
            ) : (
              <h6 className="text-center text-white">No Images Found!</h6>
            )}
          </Slider>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
          <p className="glry-content text-white">
            {overview?.image_section_text}
          </p>

          <Button style={{ background: "linear-gradient(261.66deg, rgba(0, 96, 255, 0.8) -47.14%, rgba(40, 139, 255, 0) 179.19%), linear-gradient(0deg, #02004F, #02004F), rgba(217, 217, 217, 0.5)", border: "none", outline: "none", boxShadow: "none" }} >
            <span>Download</span><img src="../../../images/cloud.svg" alt="img" />
          </Button>
        </div>
      </div>

    </div>
  );
}

export default OverviewInProfile;
