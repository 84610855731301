import { Card } from "antd";
import Meta from "antd/lib/card/Meta";
import React from "react";
import logo from "../../images/IpLogo.png";

export default function HowItWorks() {
  return (
    <div className="HowItWorks_leadGeneration my-2 pt-5 pb-sm-5 pb-0">
      <div className="row">
        <div className="col-md-12 col-12 d-flex justify-content-center flex-column p-md-5">
          <h2 className="text-center c-fff text-white ">How it works</h2>
          <p className="text-center c-fff text-white">
            InvestorPresentations.com is a digital platform for watching video presentations, on Alternative Investment opportunities! Watch this brief video, to learn more about How It Works.
          </p>
          <video
            className="leadgenVideoCard"
            poster={logo}
            src={
              `${process.env.REACT_APP_HOME_URL}explainer-video-company.m4v`
            }
            controls
            style={{ maxWidth: "840px", maxHeight: "480px", margin: "20px auto 40px", display: "flex" }}
            preload="auto"
          ></video>
        </div>
      </div>
    </div>
  );
}
// https://www.investorpresentations.com/explainer-video-company.m4v