import React, { useState } from "react";
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "react-bootstrap";
import {
  createDataroomApi,
  updateDataroomApi,
} from "../../../../api/companyApis/portfolio/dataroom/dataroomApi";

import { dataWithFiles } from "../../../../helper/common_functions";
import {
  displayErrorToast,
  displaySuccessToast,
  displayWorrningToast,
} from "../../../../helper/toast_notification_function";

const FileForm = ({ isOpen, handleToggle, toggleState, state, data }) => {
  const [formData, setFormData] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);

  const imageInputRef = React.useRef();

  const handleChange = (e) => {
    setIsDisabled(false);
    const { name, value } = e.target;
    if (e.target.files) {
      setFormData({ ...formData, [name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const Submit = () => {
    setBtnLoader(true)
    if (!state) {
      setIsDisabled(true);
      createDataroomApi(dataWithFiles(formData))
        .then((res) => {
          imageInputRef.current.value = "";
          setIsDisabled(false);
          setFormData("");
          toggleState();
    setBtnLoader(false)

          handleToggle();
          displaySuccessToast("File successfully inserted");
        })
        .catch((error) => {
    setBtnLoader(false)

          setIsDisabled(false);
          if (error.response.status === 422) {
            displayWorrningToast(error.response.data.message[0]);
          } else {
            displayErrorToast(error);
          }
        });
    } else {
    setBtnLoader(true)

      setIsDisabled(true);
      updateDataroomApi(data?.id, dataWithFiles(formData))
        .then((res) => {
          imageInputRef.current.value = "";
          setIsDisabled(false);
          setFormData("");
          toggleState();
          handleToggle();
    setBtnLoader(false)

          displaySuccessToast("File successfully updated");
        })
        .catch((error) => {
          setIsDisabled(false);
    setBtnLoader(false)

          displayErrorToast(error.response.data.message[0]);
        });
    }
  };

  return (
    <div>
      {/* Modal for Uploading Video Presentation  */}
      <Modal show={isOpen} onHide={handleToggle}>
        <ModalHeader closeButton>Insert File </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup className="mb-2">
              <FormLabel htmlFor="full_name">File name</FormLabel>
              <FormControl
                type="text"
                name="filename"
                id="file_name"
                defaultValue={data?.filename}
                maxLength={100}
                onChange={(e) => {
                  handleChange(e);
                  // handleErrorChange(e);
                }}
              />
              {/* {formError.presentationTitle && (
                  <p>{formError.presentationTitle}</p>
                )} */}
            </FormGroup>
            <FormGroup className="mb-2">
              <FormLabel htmlFor="File">File (pdf, csv, xlx, docx)</FormLabel>

              <InputGroup className="mb-3">
                <FormControl
                  type="file"
                  name="file"
                  id="file"
                  accept="application/pdf"
                  ref={imageInputRef}
                  onChange={(e) => {
                    handleChange(e);
                    // handleErrorChange(e);
                  }}
                />
                {formData?.file && (
                  <InputGroup.Text>
                    <span
                      onClick={() => {
                        imageInputRef.current.value = "";
                        setFormData({
                          ...formData,
                          file: null,
                        });
                      }}
                    >
                      ✖
                    </span>
                  </InputGroup.Text>
                )}
              </InputGroup>
              {/* {formError.presentationThumbnail && (
                  <p
                    style={{
                      fontSize: 17,
                      color: "red",
                      margin: "4px 0 4px 0",
                    }}
                  >
                    {formError.presentationThumbnail}
                  </p>
                )} */}
            </FormGroup>
          </Form>
        </ModalBody>
        <Modal.Footer>
          <Button
            variant={isDisabled ? "secondary" : "primary"}
            disabled={isDisabled}
            onClick={() => {
              Submit();
            }}
          >
            {btnLoader ? <Spinner animation="border" size="sm"/> :!state ? "Upload" : "Edit"}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End Modal for Uploading Video Presentation  */}
    </div>
  );
};

export default FileForm;
