import { apiHandle } from "../../apiHandler";

export const getAdminFaqsQuestionAnswer = () => {
  return apiHandle().get(`/company/auth/company-admin-faq`);
};

export const updateAdminFaqsAnswer = (obj, id) => {
  return apiHandle().put(`/company/auth/answer-admin-faq/${id}`, obj);
};



export const createUpdateAnswers = (id,data) => {

    return apiHandle().post(`/company/auth/answer-admin-faq/${id}`,data);
  };




  export const updateCompanyFaqsQuestionAnswer = (obj, id) => {
    return apiHandle().put(`/company/auth/company-faq/${id}`, obj);
  };

  

  export const createCompanyQuestionAnswers = (data) => {

    return apiHandle().post(`/company/auth/company-faq`,data);
  };



  export const deleteCompanyFaqsQuestionAnswer = ( id) => {
    return apiHandle().delete(`/company/auth/company-faq/${id}`);
  };

