import { apiHandle } from "../../../apiHandler";

export const createTeamMemberApi = (body) => {
  return apiHandle(true, true).post(`/company/auth/teamMember`, body);
};

export const updateTeamMemberApi = (id, body) => {
  return apiHandle(true, true).put(`/company/auth/teamMember/${id}`, body);
};

export const deleteTeamMemberApi = (id) => {
  return apiHandle().delete(`/company/auth/teamMember/${id}`);
};

export const getTeamMemberApi = (id) => {
  return apiHandle().get(`/company/auth/teamMember/${id ? id : ""}`);
};
