import { Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlinePlus
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  createCompanyHighlightsApi,
  deleteCompanyHighlightsApi,
  updateCompanyHighlightsApi
} from "../../../../api/companyApis/portfolio/highlight/companyhighlightApi";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../../helper/toast_notification_function";
import { getCompanyHighlightsHandle } from "../../../../redux/actions/companyHighlightsActions";
import { UPDATE_COMPANY_HIGHLIGHTS } from "../../../../redux/constants/highlights_constants";

const HighlightTile = ({
  state,
  setHighlights,
  data,
  items,
  highlights,
  index,
  getData,
  setVisible,
}) => {
  const [content, setContent] = useState(data?.content);
  const [edit, setedit] = useState(false);
  const [editdisable, seteditdisable] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const createHighlightHandle = () => {
    if (content) {
      setLoading(true);
      createCompanyHighlightsApi(content)
        .then(() => {
          setHighlights((prev) => !prev);
          setLoading(false);
          setVisible(false);
          setContent("");
          displaySuccessToast("Highlight created");
        })
        .catch((error) => {
          setLoading(false);
          displayErrorToast(error);
        });
    }
  };

  const HandleEdit = () => {
    console.log(data, "highdata")
    setedit(!edit);
    seteditdisable(!editdisable)
    if (content === data?.content) {
      let newEdit = [...highlights];
      newEdit[index].disabled = !highlights[index].disabled;
      dispatch({ payload: newEdit, type: UPDATE_COMPANY_HIGHLIGHTS });
    } else {
      updateCompanyHighlightsApi(data?.id, content)
        .then(() => {
          setHighlights((prev) => prev);
          displaySuccessToast("Highlight updated sucessfully");
          setedit(false)
          seteditdisable(true)
        })
        .catch((error) => {
          displayErrorToast(error);
        });
      // setHighlights((prev) => !prev);
    }
  };

  const HandleDelete = (id) => {
    setLoading(true);
    const deletedarray = highlights.filter((elem) => elem.id !== data?.id);
    deleteCompanyHighlightsApi(data?.id)
      .then(() => {
        dispatch({ payload: deletedarray, type: UPDATE_COMPANY_HIGHLIGHTS });
        // setHighlights((prev) => !prev);
        setLoading(false);
        displaySuccessToast("Highlight Delete Sucessfully");
      })
      .catch((error) => console.log(error));
  };

  const HandleCancel = () => {
    setContent(data?.content)
    setedit(false)
    seteditdisable(true)
  };

  useEffect(() => {
    setContent(data?.content);
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          display: "inline-flex",
          marginBottom: "9px",

          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner animation="grow" variant="primary" />
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "inline-flex",
          marginBottom: "10px",
          gap: "10px"
        }}
      >
        <div style={{ flex: "1" }}>
          <Form.Group className="h-100" controlId="formBasicEmail">
            <Form.Control as="textarea" className="h-100 w-100 txtfld" disabled={state || edit ? false : true} value={content} onChange={(e) => setContent(e.target.value)} />
          </Form.Group>
        </div>
        <div>
          {!state ? (
            <div>
              <button onClick={HandleEdit} className="btntm">
                {
                  edit ?
                    <AiOutlineCheck color="green" size={20} />
                    :
                    <img src="/images/edt.svg" />
                }
              </button>
            </div>
          ) : (
            <div>
              <button className="btntm" onClick={() => createHighlightHandle()}>
                <AiOutlineCheck color="green" size={20} />
              </button>
            </div>
          )}
          {!state ? (
            <div>
              {
                edit ?
                  <button className="btntm" style={{ marginBottom: "0px" }} onClick={HandleCancel}>
                    <AiOutlineClose color="red" size={20} />
                  </button>
                  :
                  <Popconfirm
                    title="Are you sure to delete this Highlight?"
                    onConfirm={HandleDelete}
                  >
                    <button className="btntm" style={{ marginBottom: "0px" }}>
                      <img src="/images/dlt.svg" />
                    </button>
                  </Popconfirm>
              }
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

const CompanyHightlight = () => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [save, setSave] = useState(false);
  const [update, setupdate] = useState(false);

  const { company } = useSelector((state) => state.higlightsReducer);
  const dispatch = useDispatch();

  // const setHighlightsCallBack = React.useCallback((prop) => {
  //   setHighlights(prop);
  //   setVisible(false);
  // });

  console.log(company, "compdata")

  const saveHiglights = () => {
    setLoading(true)
    setSave(!save);
    const newsave = company.highlights.map((item) => {
      return { ...item, disabled: !save };
    });
    console.log(newsave, "savhsf")
    dispatch({ payload: newsave, type: UPDATE_COMPANY_HIGHLIGHTS });
    setLoading(false)

  };

  useEffect(() => {
    getCompanyHighlightsHandle(dispatch);
  }, [update]);

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          width: "100%",
          display: "inline-flex",
          justifyContent: "space-between",
          marginBottom: "17px",
        }}
      >
        <div className="doc_slide">
          <h3>Company Hightlights</h3>
        </div>
        <div style={{ display: "flex", gap: "5px" }}>
          <button className="addbtnt" onClick={() => setVisible(true)}>
            <AiOutlinePlus color="#FFF" size={20} />
          </button>
          {/* <button className="addbtnt" style={{ width: "70px" }} onClick={() => saveHiglights()}> {loading ? <Spinner animation="border" variant="primary" /> : "Save"}</button> */}
        </div>
      </div>
      <div>
        {company?.highlights ? (
          company.highlights?.map((item, ind) => {
            return (
              <HighlightTile
                data={item}
                key={`highlight${ind}`}
                index={ind}
                save={save}
                highlights={company.highlights}
                setHighlights={setupdate}
                getData={getCompanyHighlightsHandle}
              />
            );
          })
        ) : (
          <h3 style={{ fontFamily: 'sansation', textAlign: "center", color: "#fff" }}>
            Enter Highlights
          </h3>
        )}
        {visible ? (
          <>
            <HighlightTile
              state="create"
              highlights={company?.highlights}
              setHighlights={setupdate}
              save={save}
              // getData={getCompanyHighlightsHandle}
              setVisible={setVisible}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default CompanyHightlight;
