import { AntDesignOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Drawer, Row, Space, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Modal, ModalBody, ModalHeader, Spinner } from "react-bootstrap";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";
import { FaFacebookF, FaLinkedinIn, FaPlay } from "react-icons/fa";
import { HiArrowSmDown, HiArrowSmUp } from "react-icons/hi";
import { RiArrowUpDoubleLine, RiTwitterFill } from "react-icons/ri";
import ReactPlayer from "react-player";

import { useParams } from "react-router-dom";
import { getActiveCompanyVideoUrlApi } from "../../api/companyApis/companyVideosApi";
import { getCompanyPortfolioByIdApi } from "../../api/getPortfolioUserSide";
import FollowButton from "../../component/Common/FollowButton";
import IntrestedButton from "../../component/Common/IntrestedButton";
import useWindowDimensions from "../../component/WindowDimensions";
import { displayErrorToast } from "../../helper/toast_notification_function";
import insta from "../../images/instagram.png";
import AskedQuestions from "../../partails/components/companyPortfolioComponenrts.js/AskedQuestions";
import DataRoomInProfile from "../../partails/components/companyPortfolioComponenrts.js/DataRoomInProfile";
import FilingPortFolio from "../../partails/components/companyPortfolioComponenrts.js/FilingPortFolio";
import HighLights from "../../partails/components/companyPortfolioComponenrts.js/HighLights";
import InvestorPerksInProfile from "../../partails/components/companyPortfolioComponenrts.js/InvestorPerksInProfile";
import MarketInProfile from "../../partails/components/companyPortfolioComponenrts.js/MarketInProfile";
import OverviewInProfile from "../../partails/components/companyPortfolioComponenrts.js/OverviewInProfile";
import ReviewPortfolio from "../../partails/components/companyPortfolioComponenrts.js/ReviewPortfolio";
import RiskAndDiscloInProfile from "../../partails/components/companyPortfolioComponenrts.js/RiskAndDiscloInProfile";
import TheTeams from "../../partails/components/companyPortfolioComponenrts.js/TheTeams";
import UseProceedInProfile from "../../partails/components/companyPortfolioComponenrts.js/UseProceedInProfile";
import { handleCompanyFollow, handleIntrestedCompany } from "../../redux/actions/companyIntrectionActions";
import CompanyVideosUserSide from "./CompanyVideosUserSide";
import VideosInProfile from "../../partails/components/companyPortfolioComponenrts.js/VideosInProfile";
import { MdOutlineClose } from "react-icons/md";
import Slider from "react-slick";
import MediaMentions from "../../partails/components/companyPortfolioComponenrts.js/MediaMentions";
import Gallery from "../../partails/components/companyPortfolioComponenrts.js/Gallery";

const CompanyPortFolioForUserSide = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [follow, setFollow] = useState('');
  const [Loadingbtn, setLoadingbtn] = useState(false);
  const [LoadingbtnInt, setLoadingbtnInt] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [intrested, setIntrested] = useState('');
  // const [stickyStyle, setStickyStyle] = useState(0)
  const { height, width } = useWindowDimensions();

  const { Text, Link } = Typography;
  const handleVideoToggle = () => setIsVideoOpen((prev) => !prev);
  const [isVideoOpen, setIsVideoOpen] = useState(false)
  const [videourl, setVideoUrl] = useState()
  const [lightboxOpen, setlightboxOpen] = useState(false);
  const [lightboxIndex, setlightboxIndex] = useState(0);
  const [lightboxVideoUrl, setlightboxVideoUrl] = useState("");
  const [showvideosthumbnail, setshowvideosthumbnail] = useState(false);
  const [videosdata, setvideosdata] = useState([]);

  const settings = {
    dots: false,
    infinite: videosdata?.length > 3 ? true : false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    arrows: true,

    responsive: [

      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        },
      },
      {
        breakpoint: 551,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleVideoUrl = (vidid) => {
    setlightboxOpen(true)
    getActiveCompanyVideoUrlApi(vidid, id)
      .then((res) => {
        setlightboxVideoUrl(res?.data?.data?.[0].presignedUrl);
      })
      .catch((error) => { displayErrorToast("Error Occurred While Fetching Video") });
  };

  console.log(isProcessing)

  useEffect(() => {
    setIsLoading(true);

    getCompanyPortfolioByIdApi(id)
      .then((res) => {
        if (res.data.success) {
          setData(res?.data?.data);
          setvideosdata(res?.data?.data?.[0]?.portfolio?.profile?.company_presentations)

          setIsLoading(false);
          setFollow(res?.data?.data?.[0].portfolio?.profile?.followed)
          setIntrested(res?.data?.data?.[0].portfolio?.profile?.interested)
        }
      })
      .catch((err) => {
        displayErrorToast(err);
        setIsLoading(false);
      });
  }, []);
  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     window.addEventListener("scroll", () => setStickyStyle(window.pageYOffset > 3)
  //       // setNews(true)
  //     );
  //   }
  // }, [])


  const onClose = () => {
    setOpen(false);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  if (isLoading) {
    return (
      <div >
        <div className="container-xl">
          <div
            style={{
              height: height,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner animation="grow" size="lg" variant="primary" />
          </div>
        </div>
      </div>
    );
  }



  return (
    <div className="bg-img-portfolio" style={{ zIndex: `${lightboxOpen ? "100" : "9"}` }}>
      <Row
        span={4}
        style={{maxHeight:"100px",zIndex:"200"}}
        className={`sideAnchorSection`}

      >
        <div className={"topAnchorListWrapper"} >
          <div className="container-xl">
            <AnchorList data={data} />
          </div>
        </div>
      </Row>


      <div className="portfolio-main">
        {data
          ? data.map((val, ind) => {
            const {
              highlights,
              overview,
              the_team,
              investor_perks,
              use_of_proceeds,
              market_landscape,
              risks_and_disclosures,
              filing,
              data_room,
              reviews,
              faqs_about_investing,
            } = val?.portfolio?.active_modules;

            let primaryVideo = val?.portfolio?.profile?.company_presentations?.length > 0 ? val?.portfolio?.profile?.company_presentations.filter(
              (newVal) => newVal?.is_primary == true
            )
              : [];
   
              console.log(data)

            return (
              <>
                <div className="title-cont">
                  <div className="container-xl">
                    <div className="row">
                      {primaryVideo.length > 0
                        ? primaryVideo.map((newVal, ind) => {


                          return (
                            <>
                              <div className="col-lg-8 col-md-12 mt-4">

                                <div className="investorSliderWrapper" key={newVal.id} onClick={(e) => handleVideoUrl(newVal?.id)}>
                                  <div className="videoSlider w-100">
                                    <span
                                      className="position-relative w-100 d-block"
                                    >
                                      <span className="playIcon">

                                        <FaPlay
                                          color="white"
                                          fontSize={"30px"}
                                          className="videoArrowCenter"
                                        />
                                      </span>
                                      <img
                                        alt="example"
                                        src={newVal?.thumbnail}
                                        className="videoSliderImg w-100"
                                      />
                                    </span>

                                    <div>
                                      <h5>{newVal?.title}</h5>
                                      <p>{newVal?.text}</p>
                                    </div>
                                  </div>
                                </div>
                                {/* <video
                            style={{
                              width: "-webkit-fill-available",
                              borderRadius: "20px",
                              objectFit: "cover",
                              aspectRatio: "3/2"
                            }}
                            // height="255"
                            poster={newVal.thumbnail}
                            src={newVal.video}
                            controls
                          ></video> */}
                              </div>
                            </>
                          );
                        })
                        : null}

                      <div className="col-lg-4 col-md-12 mt-4">
                        <div className="">
                          <div className={"d-flex gap-1 align-items-center"}>
                            <div>
                              <Avatar
                                src={val?.portfolio?.profile?.logo}
                                style={{
                                  verticalAlign: "middle",
                                  border: "1px solid gray",
                                }}
                                size={{
                                  xs: 50,
                                  sm: 80,
                                  md: 100,
                                  lg: 100,
                                  xl: 100,
                                  xxl: 100,
                                }}
                                icon={<AntDesignOutlined />}
                              />
                            </div>
                            <h3 className="text-white ms-1">
                              {val?.portfolio?.profile?.company_name}
                            </h3>
                          </div>


                          <div className="mt-3">

                            <FollowButton
                              follow={follow}
                              disabled={Loadingbtn}
                              onClick={() =>
                                handleCompanyFollow(id, setFollow, setIsProcessing, setLoadingbtn)}
                            />

                            &nbsp;&nbsp;&nbsp;
                            <IntrestedButton
                              intrested={intrested}
                              disabled={LoadingbtnInt}
                              onClick={() =>
                                handleIntrestedCompany(id, setIntrested, setIsProcessing, setLoadingbtnInt)}
                            />


                          </div>
                          <div className="company_portfolio_name">
                            <h5 className="text-white">
                              {val?.portfolio?.profile?.description}
                            </h5>
                          </div>
                        </div>

                        <div className="mt-4">
                          <div className="d-flex align-items-center flex-wrap">
                            <span className="text-white">Address: </span>
                            <span className="text-white">{val?.portfolio?.profile?.address}</span>
                          </div>
                        </div>

                        <div className="mt-3">
                          <div className="d-flex align-items-center flex-wrap">
                            <span className="text-white">Email: </span>
                            <a href={`mailto:${val?.portfolio?.profile?.email}`}>{val?.portfolio?.profile?.email}</a>
                          </div>
                        </div>

                        <div className="mt-3">
                          <div className="d-flex align-items-center flex-wrap">
                            <span className="text-white">Contact: </span>
                            <a href={`tel:${val?.portfolio?.profile?.primary_contact_number}`}>{val?.portfolio?.profile?.primary_contact_number}</a>
                          </div>
                        </div>

                        <div className="mt-3">
                          {!!val?.portfolio?.profile?.company_social
                            ?.website_url && (
                              <div className="d-flex align-items-center">
                                <span className="text-primary fs-5">@</span>
                                &nbsp;
                                <Link
                                  className="text-white"
                                  target="_blank"
                                  to={
                                    val?.portfolio?.profile?.company_social
                                      ?.website_url
                                  }
                                >
                                  {
                                    val?.portfolio?.profile?.company_social
                                      ?.website_url
                                  }
                                </Link>
                              </div>
                            )}
                        </div>




                        {val?.portfolio?.profile?.company_social ? (
                          <div className="mt-3">
                            <Text type="secondary">
                              <span className="text-white">share :</span>
                              <Space>
                                &nbsp;
                                {!!val?.portfolio?.profile?.company_social?.facebook_url && (

                                  <a target="_blank" rel="noopener noreferrer" href={val?.portfolio?.profile?.company_social?.facebook_url}>
                                    <Button shape="circle" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                      <FaFacebookF size={18} color="#1877f2" />
                                    </Button>
                                  </a>
                                )

                                }


                                {!!val?.portfolio?.profile?.company_social?.instagram_url && (

                                  <a target="_blank" rel="noopener noreferrer" href={val?.portfolio?.profile?.company_social?.instagram_url}>
                                    <Button shape="circle" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                      <i className="fa-brands fa-instagram instaicon"></i>
                                    </Button>
                                  </a>
                                )

                                }




                                {!!val?.portfolio?.profile?.company_social?.linkedin_url && (

                                  <a target="_blank" rel="noopener noreferrer" href={val?.portfolio?.profile?.company_social?.linkedin_url}>
                                    <Button shape="circle" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                      <FaLinkedinIn color="#0e76a8" />
                                    </Button>
                                  </a>
                                )

                                }



                                {!!val?.portfolio?.profile?.company_social?.twitter_url && (

                                  <a target="_blank" rel="noopener noreferrer" href={val?.portfolio?.profile?.company_social?.twitter_url}>
                                    <Button shape="circle" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                      <RiTwitterFill color="#1DA1F2" size={18} />
                                    </Button>
                                  </a>
                                )

                                }






                              </Space>
                            </Text>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>


                {/* {!!val?.portfolio?.profile?.company_social && (

                  <Row className="mt-5">
                    <Text type="secondary">

                      <div dangerouslySetInnerHTML={{ __html: val?.portfolio?.profile?.company_social?.text }} />
                    </Text>

                  </Row>
                )} */}

                <div className="videos-sect-port">
                  <div className="container-xl">
                    <h2>Videos</h2>
                    {!!val?.portfolio?.profile?.company_presentations?.length > 0 ? (
                      <VideosInProfile
                        videos={val?.portfolio?.profile?.company_presentations}
                        lightboxState={setlightboxOpen}
                        lightboxVideoUrl={setlightboxVideoUrl}
                        companyId={id}
                      />
                    ) :
                      <h3>
                        No Videos Available
                      </h3>
                    }
                  </div>
                </div>

                {!!highlights && (
                  <div className="highlight-cont">
                    <div className="container-xxl">
                      <div id="Highlights">
                        <HighLights data={val?.portfolio?.highlights ? val?.portfolio?.highlights : {}} />
                      </div>
                    </div>
                  </div>
                )}

                {!!overview && (
                  <>
                    <div className="overview-cont" id="Overview">
                      <div className="container-xl">
                        <h2>Overview</h2>
                        <OverviewInProfile
                          overview={val?.portfolio?.overview ? val?.portfolio?.overview : []}
                        />
                      </div>
                    </div>
                    <div className="gallery-cont" id="Gallery">
                      <div className="container-xl">
                        <h2>Gallery</h2>
                        <p className="text-center text-white">
                          Lorem ipsum dolor sit emit little text about mention
                          Lorem ipsum dolor sit emit little text about mention
                        </p>
                        <Gallery
                          gallery_and_media_mention={val?.portfolio?.gallery_and_media_mention?.gallery ? val?.portfolio?.gallery_and_media_mention?.gallery : []}
                        />
                      </div>
                    </div>
                    <div className="media-cont" id="MediaMention">
                      <div className="container-xl">
                        <h2>Media Mentions</h2>
                        <p className="text-center text-white">
                          Lorem ipsum dolor sit emit little text about mention
                        </p>
                        <MediaMentions
                          media_mentions={val?.portfolio?.gallery_and_media_mention?.media_mentions ? val?.portfolio?.gallery_and_media_mention?.media_mentions : []}
                        />
                      </div>
                    </div>
                  </>
                )}

                {!!the_team && (
                  <div className="team-perks-cont" id="TheTeams">
                    <div className="container-xl">
                      <h2>The Team</h2>
                      <p className="text-center text-white">
                        Lorem ipsum dolor sit emit little text about mention
                      </p>
                      <TheTeams theTeams={val?.portfolio?.the_team ? val?.portfolio?.the_team : []} />
                    </div>

                    {!!investor_perks && (
                      <div className="inv-perks-cont container-xl" id="InvestorPerks">
                        <h2>Investor Perks</h2>
                        <InvestorPerksInProfile
                          InvestorPerks={val.portfolio?.investor_perks ? val.portfolio?.investor_perks : {}}
                        />
                      </div>
                    )}
                  </div>
                )}

                {!!use_of_proceeds && (
                  <div className="use-proceed-cont" id="UseOfProceeds">
                    <div className="container-xl">
                      <h2>Use Of Proceeds</h2>
                      <UseProceedInProfile
                        proceed={
                          val?.portfolio?.use_of_proceeds?.donut_chart
                        }
                      />
                    </div>
                  </div>
                )}

                {!!market_landscape && (
                  <div className="market-lands-cont" id="MarketLandscape">
                    <div className="container-xl">
                      <h2>Market Landscape</h2>
                      <MarketInProfile
                        marketLandScape={val?.portfolio?.market_landscape}
                      />
                    </div>
                  </div>
                )}

                {!!risks_and_disclosures && (
                  <div className="risk-disc-cont" id="RisksDisclosures">
                    <div className="container-xl">
                      <h2>Risk & Disclosures</h2>
                      <RiskAndDiscloInProfile
                        RiskAndDisclosures={
                          val.portfolio?.risks_and_disclosures
                        }
                      />
                      {!!filing && (
                        <div id="FormC">
                          <FilingPortFolio filing={val?.portfolio?.filing} />
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {!!data_room && (
                  <div className="dataroom-cont" id="DataRoom">
                    <div className="container-xl">
                      <h2>Data Room</h2>
                      <DataRoomInProfile
                        dataRoom={val?.portfolio?.data_room}
                        saved_files={val?.saved_files_id ? val?.saved_files_id : []}
                      />
                    </div>
                  </div>
                )}

                {!!reviews && (
                  <div className="reviews-cont" id="Reviews">
                    <div className="container-xl">
                      <h2>Testimonials</h2>
                      <ReviewPortfolio
                        reviews={val?.portfolio?.reviews}
                      />
                    </div>
                  </div>
                )}
                {!!faqs_about_investing && (
                  <div className="asked-cont" id="AskedQuestions">
                    <div className="container-xl">
                      <h2>Still Have Questions?</h2>
                      <AskedQuestions
                        pdf={val?.portfolio?.faqs_about_investing}
                      />
                    </div>
                  </div>
                )}
              </>
            );
          })
          : //  error ? (
          //   <h4 style={{ color: "red", fontWeight: "bold" }}>{error}</h4>
          // null
          // )
          null}
      </div>

      <div className={`lightBoxModal ${lightboxOpen && "open"}`}>
        <div className="bg-light-box" onClick={() => { setlightboxOpen(false); setlightboxVideoUrl(""); setshowvideosthumbnail(false) }}></div>
        <div className="container-xxl">
          <div className="close-btn-lightbox" onClick={() => { setlightboxOpen(false); setlightboxVideoUrl(""); setshowvideosthumbnail(false) }}>
            <MdOutlineClose size={24} color="#fff" />
          </div>
          <ReactPlayer
            url={lightboxVideoUrl}
            controls
            // light
            width={"100%"}
            height={"100%"}
          />
          <div className={`thumbnailbar ${showvideosthumbnail ? "show" : ""}`} onMouseOver={() => { setshowvideosthumbnail(true) }} onMouseOut={() => { setshowvideosthumbnail(false) }}>
            {!showvideosthumbnail && <div className="shwmrebtn"><RiArrowUpDoubleLine size={18} color="#fff" /><span>Show More</span></div>}
            <div className="slider_box_thumb">
              <Slider {...settings}>
                {videosdata?.map((item, index) => (

                  <div className="investorSliderWrapper" key={item.id} onClick={(e) => handleVideoUrl(item?.id)}>
                    <div className="videoSlider">
                      <span
                        className="position-relative d-block"
                      >
                        <span className="playIcon">

                          <FaPlay
                            color="white"
                            fontSize={"30px"}
                            className="videoArrowCenter"
                          />
                        </span>
                        <img
                          alt="example"
                          src={item.thumbnail}
                          className="videoSliderImg"
                        />
                      </span>

                      <div>
                        <h5>{item.title}</h5>
                        <p>{item.text}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyPortFolioForUserSide;

const AnchorList = ({ data }) => {

  const sliderRef = useRef(0);

  const slide = (shift) => {
    sliderRef.current.scrollLeft += shift;
    // const sliderWidth = sliderRef.current.childNodes

  };





  return (<div style={{ maxWidth: "1440px", position: "relative", padding: "0  1rem 0 1rem" }}>

    <button className="slider-button prev-button" onClick={() => slide(-100)}>
      <BiLeftArrow />
    </button>

    <ul className="TopanchorList" ref={sliderRef}>

      {data.length > 0
        ? data.map((val, ind) => {
          const {
            highlights,
            overview,
            the_team,
            investor_perks,
            use_of_proceeds,
            market_landscape,
            risks_and_disclosures,
            filing,
            gallery_and_media_mention,
            data_room,
            reviews,
            faqs_about_investing,
          } = val?.portfolio?.active_modules;

          return (
            <>


              {!!highlights && (
                <li>
                  <AnchorLink href="#Highlights">
                    Highlight
                  </AnchorLink>
                </li>
              )}

              {!!overview && (
                <li>
                  <AnchorLink href="#Overview">
                    Overview
                  </AnchorLink>
                </li>
              )}

              {!!gallery_and_media_mention?.gallery && (
                <li>
                  <AnchorLink href="#Gallery">
                    Gallery
                  </AnchorLink>
                </li>
              )}

              {!!gallery_and_media_mention?.media_mentions && (
                <li>
                  <AnchorLink href="#MediaMention">
                    Media Mention
                  </AnchorLink>
                </li>
              )}

              {!!the_team && (
                <li>
                  <AnchorLink href="#TheTeams">
                    The Teams
                  </AnchorLink>
                </li>
              )}

              {!!investor_perks && (
                <li>
                  <AnchorLink href="#InvestorPerks">
                    Investor Perks
                  </AnchorLink>
                </li>
              )}

              {!!use_of_proceeds && (
                <li>
                  <AnchorLink href="#UseOfProceeds">
                    Use of Proceeds
                  </AnchorLink>
                </li>
              )}

              {!!market_landscape && (
                <li>
                  <AnchorLink href="#MarketLandscape">
                    Market Landscape
                  </AnchorLink>
                </li>
              )}

              {!!risks_and_disclosures && (
                <li>
                  <AnchorLink href="#RisksDisclosures">
                    Risks & Disclosures
                  </AnchorLink>
                </li>
              )}

              {!!filing && (
                <li>
                  <AnchorLink href="#FormC">
                    Filing
                  </AnchorLink>
                </li>
              )}

              {!!data_room && (
                <li>
                  <AnchorLink href="#DataRoom">
                    Data Room
                  </AnchorLink>
                </li>
              )}

              {!!reviews && (
                <li>
                  <AnchorLink href="#Reviews">
                    Reviews
                  </AnchorLink>
                </li>
              )}

              {!!faqs_about_investing && (
                <li>
                  <AnchorLink href="#FAQsAboutInvesting">
                    FAQs About Investing
                  </AnchorLink>
                </li>
              )}
            </>

          );
        })
        : null}
    </ul>

    <button className="slider-button next-button" onClick={() => slide(+100)}>
      <BiRightArrow />
    </button>

  </div>
  );
};
function VideoPlayer(videourl) {
  return <div className="primaryVideoUserSide vidbox">
    <ReactPlayer
      url={videourl?.presignedUrl}
      controls
      // light
      width={"100%"}
      config={{
        file: {
          attributes: {
            controlsList: 'nodownload'
          }
        }
      }}
    />
  </div>;
}

