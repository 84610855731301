import { Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";

import { deleteCompanyFaqsQuestionAnswer, getAdminFaqsQuestionAnswer, updateAdminFaqsAnswer } from "../../../../../api/companyApis/portfolio/FaqApis";
import AddFaq from "./AddFaq";
import { AiOutlinePlus } from "react-icons/ai";
import AddCompanyOwnFaq from "./AddCompanyOwnFaq";
import { deleteGalleryByIdApi } from "../../../../../api/companyApis/portfolio/galleryAndMedia/galleryAndMedia";
import { displayErrorToast } from "../../../../../helper/toast_notification_function";
;

const Table = ({ flag }) => {
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [visibleTwo, setVisibleTwo] = useState(false);

  const [trigger, setTrigger] = useState(false);
  const [single, setSingle] = useState(null);
  const [name, setName] = useState();

  const [faqLength , setFaqLength] = useState([])
const [id ,setId] = useState()
  const handleToggle = () => {
    setVisible((prev) => !prev);
  };

  const handleToggleTwo = () => {
    setVisibleTwo((prev) => !prev);
  };


  const onDelete = (id) => {
    deleteCompanyFaqsQuestionAnswer(id)
      .then((res) => {
        getAdminFaqsQuestionAnswer().then((res) => {
          console.log(res.data ,"ppppppppppp")
          setLoading(false);
          setData(res.data.data[0]);
          setSingle(res.data.data[0])
  
  
          let filter = res?.data?.data[0].admin_faq.filter((val) => val.answer == null)
          setFaqLength(filter);
        })
        .catch((error) => displayErrorToast(error))
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onEdit = (id ,  prop ) => {
    console.log(id ,prop)

    if (id) {
      // updateAdminFaqsAnswer(id)
      //   .then((res) => {
      //     setSingle(res.data?.data[0]);
      //     console.log(res.data)
          setId(id)
          let filter = data[prop].filter((val) => val.id == id)
          if(prop == "admin_faq"){
            
            setVisible(true);
          }else{
            setVisibleTwo(true)
           setName("update")
          }


          setSingle(filter)
        }
        // .catch((error) => console.log("Portfolio team member", error.response));
    }
  

  useEffect(() => {
    setLoading(true);
    getAdminFaqsQuestionAnswer()
      .then((res) => {
        console.log(res.data ,"ppppppppppp")
        setLoading(false);
        setData(res.data.data[0]);
        setSingle(res.data.data[0])


        let filter = res?.data?.data[0].admin_faq.filter((val) => val.answer == null)
        setFaqLength(filter);
      })
      .catch((error) => console.log("Portfolio team member", error.response));
  }, [flag, trigger]);



  if (isLoading) {
    return (
      <>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: 400, width: "100%" }}
        >
          <div className="container">
            <div className="d-flex align-items-center justify-content-center">
              <Spinner animation="border" size="lg" variant="primary" />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div>

      <div>
        {console.log(faqLength.length , faqLength)}
      {faqLength.length == [] ? (

      <div className="d-flex justify-content-end mb-2">
        <button className="addbtnt" onClick={() => {setName("create");setVisibleTwo(true)}}>
        <AiOutlinePlus color="#fff" size={24} />
        </button>
      </div>
      ) : null

      }

      </div>
        <AddFaq
        isOpen={visible}
        handleToggle={handleToggle}
        toggleState={() => setTrigger((prev) => !prev)}
        data={single}
        state={"update"}
        id={id ? id : ""}
      />
   
      {
        data.admin_faq.map((data) => {
          return (
            <div className="companyGallery">
                <Row>
                  <Col xl={9} lg={9} md={9} sm={12} xs={12}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <p className="text-dark">Q : {data.question}</p>
                      {/* <Form.Control type="text" value={data.question} disabled /> */}
                    </Form.Group>
                  </Col>

                  <Col xl={9} lg={9} md={9} sm={12} xs={12}>
                    <Form.Group className="h-100" controlId="formBasicEmail">
                      <p
                       className="faqHtmlData d-flex"
                       dangerouslySetInnerHTML={{ __html: `A : &nbsp;${!data.answer ? " " :data.answer}`}}
                    
                     />

                      
                    </Form.Group>
                  </Col>

                  <Col xl={3} lg={3} md={3} sm={12} xs={12} className="d-flex  justify-content-center align-items-center gap-2 " style={{position:"relative",bottom:"2rem"}}>
                    <button className="btntm" onClick={() => onEdit(data.id , "admin_faq")}>
                      <img src="/images/edt.svg" alt="edit" />
                    </button>


                 
                  </Col>
                </Row>



            </div>
          )
        })
      }


        {data?.company_faq.length > 0 && 
                data.company_faq.map((data) => {
          return (
            <div className="companyGallery">
                <Row>
                  <Col xl={9} lg={9} md={9} sm={12} xs={12}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <p className="text-dark">Q : {data.question}</p>
                      {/* <Form.Control type="text" value={data.question} disabled /> */}
                    </Form.Group>
                  </Col>

                  <Col xl={9} lg={9} md={9} sm={12} xs={12}>
                    <Form.Group className="h-100" controlId="formBasicEmail">
                      <p
                       className="faqHtmlData d-flex"
                       dangerouslySetInnerHTML={{ __html: `A : &nbsp;${!data.answer ? " " :data.answer}`}}
                    
                     />

                      
                    </Form.Group>
                  </Col>

                  <Col xl={3} lg={3} md={3} sm={12} xs={12} className="d-flex  justify-content-center align-items-center gap-2 " style={{position:"relative",bottom:"2rem"}}>
                    <button className="btntm" onClick={() => onEdit(data.id , "company_faq")}>
                      <img src="/images/edt.svg" alt="edit" />
                    </button>


                    <Popconfirm title="Are you sure to delete this Member?" onConfirm={() => onDelete(data.id)}>
                      <button className="btntm">
                        <img src="/images/dlt.svg" alt="delete" />
                      </button>
                    </Popconfirm>
                  </Col>
                </Row>



            </div>
          )
        })
      }


      <AddCompanyOwnFaq
        isOpen={visibleTwo}
        handleToggle={handleToggleTwo}
        toggleState={() => setTrigger((prev) => !prev)}
        data={single}
        state={name}
        id={id ? id : ""}
        setName={setName}
      />
      
    </div>
  );
};

export default Table;
