import {
  SAVE_GETTING_COMPANY_PROFILE_PORTFOLIO,
  ERROR_GETTING_COMPANY_PROFILE_PORTFOLIO,
  START_GETTING_COMPANY_PROFILE_PORTFOLIO,
} from "../../constants/company_profile_portfolio_const";

const INITIAL_STATE = {
  companyProfilePortFolio: [],
  error: null,
  isLoading: false,
};

export const companyProfilePortFolioReducer = (
  state = INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case START_GETTING_COMPANY_PROFILE_PORTFOLIO:
      return {
        ...state,

        companyProfilePortFolio: payload,
        error: null,
        isLoading: true,
      };
    case SAVE_GETTING_COMPANY_PROFILE_PORTFOLIO:
      return {
        ...state,

        companyProfilePortFolio: payload,
        error: null,
        isLoading: false,
      };
    case ERROR_GETTING_COMPANY_PROFILE_PORTFOLIO:
      return {
        ...state,

        companyProfilePortFolio: null,
        error: payload,
        isLoading: false,
      };

    default:
      return state;
  }
};
