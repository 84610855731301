import React from "react";
import { Row } from "react-bootstrap";
import CompanyHightlight from "./Company";
import FundraiseHightlight from "./Fundraise";

const Highlights = () => {
  return (
    <div className="bg_black">
      <Row>
        <CompanyHightlight />
        <FundraiseHightlight />
      </Row>
    </div>
  );
};

export default Highlights;
