import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import ExploreCards from "../Common/ExploreCards";
import dummythumbnail from "../../images/no_image.jpg";
import explore2 from "../../images/explore2.png";
import explore3 from "../../images/explore3.png";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import { FiArrowRight } from "react-icons/fi";
import { PublicGetCompaniesApi } from "../../api/Public_Apis";
import { displayErrorToast } from "../../helper/toast_notification_function";

const ExploreInvestment = () => {
  const navigate = useNavigate();

  const [data, setdata] = useState();

  useEffect(() => {
    PublicGetCompaniesApi()
      .then((res) => {
        if (res?.data?.success) {
          setdata(res?.data?.data);
        }
      })
      .catch((err) => {
        displayErrorToast(err);
      });
  }, []);

  return (
    <div className=" py-5 explore_investment">
      <Row gutter={[24, 16]} justify="center" align="middle">
        <Col
          className="gutter-row text-center"
          xs={22}
          sm={22}
          md={22}
          lg={18}
          xl={18}
        >
          <h2 className="commonH2">Get The Information You Need</h2>
          <p className="text-white">
            Watch video presentations, and download Whitepapers, you have all
            the information you need at your fingertips! It’s just as if you
            were visiting a booth at an investment expo, but from the comfort of
            your office
          </p>
        </Col>
      </Row>

      <Row gutter={[20, 20]} justify="center" align="middle">
        {data?.map((val, ind) => {
          console.log(val , "val")
          return (
            <Col
              key={ind}
              className="gutter-row mt-4"
              xs={22}
              sm={12}
              md={12}
              lg={8}
              xl={8}
            >
              <ExploreCards
                isInterested={val?.interested}
                isFollowed={val?.followed}
                logo={val?.logo}
                id={val.id}
                title={val.company_name}
                desc={val.description}
                total_investment={val.total_amount ? val.total_amount : null}
                minimum_investment={val.minimum_investment ? val.minimum_investment : null}
                state={val.state ? val.state : ""}
                country={val.country ? val.country : ""}
                start_date={val.active_schedule_start_date ? val.active_schedule_start_date : ""}
                end_date={val.active_schedule_end_date ? val.active_schedule_end_date : ""}
                img={val.primary_video_thumbnail ? val.primary_video_thumbnail : dummythumbnail}
                bgColor={val.bgColor}
                isLevelTwo={val.is_level_two_profile}
                onClick={() => navigate(`/user/company/portfolio/${val.id}`)}
              />


            </Col>
          );
        })}
      </Row>

      <Row gutter={[24, 16]} justify="center" align="middle">
        <Button
          className="mt-5 px-3 allStartupbutton"
          onClick={() => {
            navigate("/login");
          }}
        >
          View All Startups
          <FiArrowRight className="rightArrow" />
        </Button>
      </Row>
    </div>
  );
};

export default ExploreInvestment;
