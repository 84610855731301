import React from 'react'
import abtimg1 from "../../../images/about1.jpg";
import abtimg2 from "../../../images/about2.jpg";
import abtimg3 from "../../../images/about3.jpg";

const Gallery = ({ gallery_and_media_mention }) => {

    return (
        <div className='mt-2'>
        
            {
                gallery_and_media_mention?.length > 0 ?
                    gallery_and_media_mention?.map((data, index) => {
                        console.log(index % 2 == 0)
                        if (index % 2 == 0) {
                            return (
                                <div className="gallry_text" key={index}>
                                    <div className="glry_txt">
                                        <h3>
                                            {data?.heading}
                                        </h3>
                                        <p className="glry_p c-fff">
                                            {data?.text}
                                        </p>
                                    </div>
                                    <img className="gallry_img border" src={data?.image} />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div className="gallry_text" key={index}>
                                    <img className="gallry_img border" src={data?.image} />
                                    <div className="glry_txt">
                                        <h3>
                                            {data?.heading}
                                        </h3>
                                        <p className="glry_p c-fff">
                                            {data?.text}
                                        </p>
                                    </div>
                                </div>
                            )
                        }
                    })
                    :
                    <h3 className='text-center'>
                        No Gallery Found
                    </h3>
            }
        </div>
    )
}

export default Gallery


