import { useState } from "react";
import ReactPlayer from "react-player";
import Slider from "react-slick";

import { Space, Typography } from "antd";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { FaPlay } from "react-icons/fa";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { getActiveCompanyVideoUrlApi, getCompanyVideoUrlApi } from "../../../api/companyApis/companyVideosApi";
import { displayErrorToast } from "../../../helper/toast_notification_function";

export default function VideosInProfile({ videos, lightboxState, lightboxVideoUrl,companyId }) {
  const { Title } = Typography;
  const [isVideoOpen, setIsVideoOpen] = useState(false)
  const [videourl, setVideoUrl] = useState()
  const handleVideoToggle = () => setIsVideoOpen((prev) => !prev);

  const LoginState = localStorage.getItem('IP_State');

  const handleVideoUrl = (id) => {
    lightboxState(true)
    if(LoginState == "USER"){
      getActiveCompanyVideoUrlApi(id, companyId)
        .then((res) => {
          lightboxVideoUrl(res.data.data[0].presignedUrl);
        })
        .catch((error) => { displayErrorToast("Error Occurred While Fetching Video") });
    }
   else{
    getCompanyVideoUrlApi(id)
    .then((res) => {
      lightboxVideoUrl(res.data.data[0].presignedUrl);
    })
    .catch((error) => { displayErrorToast("Error Occurred While Fetching Video") });
   }
  };


  const settings = {
    dots: true,
    infinite: videos?.length > 3 ? true : false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    arrows: true,

    responsive: [

      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        },
      },
      {
        breakpoint: 551,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const handleVideoUrl = (videoObj) => {
  // console.log(videoObj) 
  // handleVideoToggle()
  // setVideoInModal(videoObj)
  // }



  return (
    <Slider {...settings}>
      {videos.map((item, index) => (

        <div className="investorSliderWrapper" key={item.id} onClick={(e) => handleVideoUrl(item?.id)}>
          <div className="videoSlider">
            <div
              className="position-relative p-0 d-block"
            >
              <span className="playIcon">

                <FaPlay
                  color="white"
                  fontSize={"30px"}
                  className="videoArrowCenter"
                />
              </span>
              <img
                alt="example"
                src={item.thumbnail}
                className="videoSliderImg"
              />
            </div>

            <div>
              <h5>{item.title}</h5>
              <p>{item.text}</p>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
}



