import React from "react";
import TopSection from "../Common/TopSection";
import img1 from "../../images/Vector1.png";
import img2 from "../../images/Vector2.png";
import img3 from "../../images/Vector3.png";
import Rightarrow from "../../images/rightArrow.png";
import leftArrow from "../../images/leftArrow.png";

export default function WhyToSignUp() {
  const cards = [
    {
      heading: "Exposure",
      img: img1,
      text: "We provide exposure to a global audience of investors looking for ideas just like yours.",
      bgColor:
        "linear-gradient(94.76deg, rgba(5, 88, 226, 0.2) -19.26%, rgba(5, 88, 226, 0) 81.38%);border-radius: 25px;",
      position: "start",
      arrowDirection: "RightArrow",
      arrow: Rightarrow,
    },
    {
      heading: "Lead Generation",
      img: img2,
      text: "Lead Generation of all investors that visited your “Digital Booth” is available in real-time.",
      bgColor:
        "linear-gradient(94.76deg, rgba(5, 88, 226, 0.2) -19.26%, rgba(5, 88, 226, 0) 81.38%);border-radius: 25px;",
      position: "end",
      arrowDirection: "LeftArrow",
      arrow: leftArrow,
    },
    {
      heading: "Control",
      img: img3,
      text: "Control your cost and time, by not having to set-up a booth in a far away city",
      bgColor:
        "linear-gradient(94.76deg, rgba(5, 88, 226, 0.2) -19.26%, rgba(5, 88, 226, 0) 81.38%);border-radius: 25px;",
      position: "start",
      arrowDirection: "RightArrow",
      arrow: Rightarrow,
    },
    {
      heading: "Analytics",
      img: img2,
      text: "Analytics of investors activity are provided, giving you data to better present your offering.",
      bgColor:
        "linear-gradient(94.76deg, rgba(5, 88, 226, 0.2) -19.26%, rgba(5, 88, 226, 0) 81.38%);border-radius: 25px;",
      position: "end",
      secDivPosition: "start",
      arrowDirection: "LeftArrow",
      arrow: leftArrow,
    },
    {
      heading: "Go Live",
      img: img3,
      text: "Set your time-line to be “Live” on the platform by date range, and have full control over your exposure.",
      bgColor:
        "linear-gradient(94.76deg, rgba(5, 88, 226, 0.2) -19.26%, rgba(5, 88, 226, 0) 81.38%);border-radius: 25px;",
      position: "start",
      arrowDirection: "RightArrow",
      arrow: Rightarrow,
    },
    {
      heading: "Connect",
      img: img1,
      text: "Connect With Investors, Filter for Accredited/Non-Accredited investors, and download the leads of people that actually interacted with your offering!",
      bgColor:
        "linear-gradient(94.76deg, rgba(5, 88, 226, 0.2) -19.26%, rgba(5, 88, 226, 0) 81.38%);border-radius: 25px;",
      position: "end",

    },
  ];
  return (
    <div id="whyToSignUs">
      <div className="container mt-5">
        <TopSection mainHeading={"Benefits To Posting Your Offering"} />

        <div className="mt-5">
          {cards.map((val, ind) => {
            return (
              <div className="whySign_wrapper">
                <img
                  src={val.arrow}
                  className={val.arrowDirection}
                  style={{ width: "13%" }}
                />
                <div
                  className={`row justify-content-${val.position} whyToSignUpCard`}
                >
                  <div
                    className={`col-md-6 col-12 card bordered ${val.position} p-3`}
                  >
                    <div className="whyToSignUpCard-wrapper">
                      <h1 className="fw-bold mt-3">{val.heading}</h1>
                      {/* <img alt="img" style={{ width: "13%" }} src={val.img} /> */}
                    </div>
                    <p className="text-white">{val.text}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
