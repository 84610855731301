import { Button } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import {
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup,
  Modal,
  Spinner,
} from "react-bootstrap";

import {
  filingPostApi,
  getFilingApi,
  updateFilingApi,
} from "../../../../api/companyApis/portfolio/FilingApi";

// import PdfComponent from "../../../../../component/PdfComponent";
// import {
//   displayErrorToast,
//   displaySuccessToast,
// } from "../../../../../helper/toast_notification_function";

import {
  dataWithFiles,
} from "../../../../helper/common_functions";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../../../helper/toast_notification_function";
import PdfComponent from "../../../../component/PdfComponent";
// import pdf from "../../../../../images/sample.pdf";

const FormC = () => {
  const fileInputRef = React.useRef();
  const [file, setFile] = useState(null);
  const [heading, setHeading] = useState("");
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [show, setShow] = useState(false);

  const [btnDisabled, setBtnDisabled] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onSubmit = () => {
    const obj = {
      pdf: file,
      text_area: text,
      heading: heading,
    };

    if (file && text && heading) {
      setLoading(true);
      setBtnDisabled(true);
      filingPostApi(obj)
        .then((response) => {
          setFile(null);
          fileInputRef.current.value = "";
          setLoading(false);
          displaySuccessToast("Success");
          getFile();
          setBtnDisabled(false);
        })
        .catch((error) => {
          setLoading(false);
          displayErrorToast(error);
          setBtnDisabled(false);
        });

      setLoading(false);
    }
  };

  const updateHandle = (data) => {
    const { id } = data;

    const obj = {
      pdf: file,
      text_area: text ? text : data.text_area,
      heading: heading ? heading : data.heading,
    };
    setBtnDisabled(true);
    updateFilingApi(dataWithFiles(obj), id)
      .then((res) => {
        if (res.data.success) {
          getFile();
          handleClose();
          displaySuccessToast("Success");
          setBtnDisabled(false);
        }
      })
      .catch((err) => {
        displayErrorToast(err);
        setBtnDisabled(false);
      });
  };

  const getFile = () => {
    getFilingApi()
      .then((res) => {
        if (res.data.success) {
          setFileData(res?.data?.data[0]);
        }
      })
      .catch((err) => {
        displayErrorToast(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getFile();
  }, []);

  if (loading) {
    return (
      <center className="d-flex justify-content-center align-items-center  ">
        <Spinner
          as="span"
          animation="border"
          size="lg"
          role="status"
          aria-hidden="true"
          variant="primary"
        />
      </center>
    );
  }

  return (
    <>
      <div className="bg_black">
      <div className="doc_slide">
          <h3>Filling Heading</h3>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <FormGroup className="mb-2">
            <div className="form-group mb-2">
              <label for="region_of_formation" className="fw-bold mb-2 bldlb">
                Heading
              </label>
              <input
                required
                maxLength={65}
                type="text"
                className="form-control "
                onChange={(e) => {
                  setHeading(e.target.value);
                  setBtnDisabled(false);
                }}
                defaultValue={fileData?.heading}
                // value={CompanySign?.region_of_formation}
                id="region_of_formation"
                placeholder="Heading"
              />
            </div>

            <div className="form-group mb-2 mt-3">
              <label for="region_of_formation" className="fw-bold mb-2 bldlb">
                Description
              </label>
              <textarea
                required
                maxLength={65}
                type="text"
                className="form-control "
                name="textArea"
                onChange={(e) => {
                  setText(e.target.value);
                  setBtnDisabled(false);
                }}
                defaultValue={fileData?.text_area}
                // value={CompanySign?.region_of_formation}
                id="region_of_formation"
                placeholder="Description..."
              />
            </div>
            <FormLabel htmlFor="filing" style={{ color: "#fff", fontSize: "16px", marginTop: "5px" }}>5 mb limit (PDF only)</FormLabel>

            <div className="form-group mb-2">
              <InputGroup className="form-group">
                <FormControl
                  type="file"
                  name="pdf"
                  required
                  disabled={fileData ? true : false}
                  className="form-group"
                  id="filing"
                  accept="application/pdf"
                  ref={fileInputRef}
                  onChange={(e) => setFile(e.target.files[0])}
                // onChange={(e) => {
                //   setFile({ [e.target.name]: e.target.files[0] });
                // }}
                />
                {fileInputRef?.current?.value && (
                  <InputGroup.Text>
                    <span
                      onClick={() => {
                        setFile(null);
                        fileInputRef.current.value = "";
                      }}
                    >
                      ✖
                    </span>
                  </InputGroup.Text>
                )}
              </InputGroup>
            </div>
          </FormGroup>
          <div className="mt-3">
            {!fileData ? (
              <button className="addbtnt" style={{ width: "fit-content", padding: "5px 15px", color: "#fff", fontSize: "15px", fontWeight: "500" }} onClick={onSubmit} disabled={btnDisabled}>
                Submit
              </button>
            ) : (
              <button className="addbtnt" style={{ width: "fit-content", padding: "5px 15px", color: "#fff", fontSize: "15px", fontWeight: "500" }} onClick={handleShow}>
                Update
              </button>
            )}
          </div>
        </form>

        {fileData ? <PdfComponent options={false} pdf={fileData?.pdf} /> : null}
      </div>

      {/* Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="update" id="edit-form">
            <input className="form-control" type="hidden" name="id" />
            <div className="form-group">
              <label for="first_name">Heading</label>
              <input
                className="form-control"
                defaultValue={fileData?.heading}
                type="text"
                name="heading"
                onChange={(e) => {
                  setBtnDisabled(false);
                  setHeading(e.target.value);
                }}
              />
            </div>
            <div className="form-group">
              <label for="last_name">Description</label>
              <textarea
                className="form-control"
                defaultValue={fileData?.text_area}
                type="text"
                name="Description"
                onChange={(e) => {
                  setBtnDisabled(false);
                  setText(e.target.value);
                }}
              />
            </div>
            <div className="form-group mb-2 mt-3 col-lg-12">
              <InputGroup className="form-group col-lg-8">
                <FormControl
                  type="file"
                  name="pdf"
                  className="form-group col-lg-8"
                  id="filing"
                  accept="application/pdf"
                  ref={fileInputRef}
                  onChange={(e) => {
                    setBtnDisabled(false);
                    setFile(e.target.files[0]);
                  }}
                // onChange={(e) => {
                //   setFile({ [e.target.name]: e.target.files[0] });
                // }}
                />
                {fileInputRef?.current?.value && (
                  <InputGroup.Text>
                    <span
                      onClick={() => {
                        setFile(null);
                        fileInputRef.current.value = "";
                      }}
                    >
                      ✖
                    </span>
                  </InputGroup.Text>
                )}
              </InputGroup>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => updateHandle(fileData)}
            disabled={btnDisabled}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FormC;
