 // =============================== featured ACTIVE SHEDULE APIS=========================================

 import { apiHandle } from "../apiHandler";

 export const DownloadLeadsPostApi = (data) => {
    return apiHandle().post(`/company/auth/downloadActivity`,data);
  };
  


  export const downloadChargeRatePerDayApi = () => {
    return apiHandle().get(`/company/auth/downloadActivity/chargeRate`);
  };
  


  export const getDownloadLeadsMinimumDays = () => {
    return apiHandle().get("/company/auth/downloadActivity/minimumDays");
  };
  


  