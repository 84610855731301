import JSEncrypt from "jsencrypt";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreditCardForm from "./TestCreditCard";

const crypt = new JSEncrypt();


const imageUrls = [
  "https://logos-world.net/wp-content/uploads/2020/04/Visa-Logo.png",
  "https://brand.mastercard.com/content/dam/mccom/brandcenter/thumbnails/mastercard_vrt_rev_92px_2x.png",
  "https://www.discover.com/company/images/newsroom/media-downloads/discover.png",
  "https://s1.q4cdn.com/692158879/files/design/svg/american-express-logo.svg",
  "https://cdn4.iconfinder.com/data/icons/simple-peyment-methods/512/diners_club-512.png",
  "https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/JCB_logo.svg/1280px-JCB_logo.svg.png",
];

function CreditCard() {
  const { userCardLoading, userCard, error } = useSelector(
    (state) => state.GetCardDataReducer
  );
  const [creditCardNum, setCreditCardNum] = useState("***");

  const [cardTypeUrl, setCardTypeUrl] = useState([

    "https://logos-world.net/wp-content/uploads/2020/04/Visa-Logo.png",
    "https://brand.mastercard.com/content/dam/mccom/brandcenter/thumbnails/mastercard_vrt_rev_92px_2x.png",
    "https://www.discover.com/company/images/newsroom/media-downloads/discover.png",

    "https://s1.q4cdn.com/692158879/files/design/svg/american-express-logo.svg",
    "https://cdn4.iconfinder.com/data/icons/simple-peyment-methods/512/diners_club-512.png",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/JCB_logo.svg/1280px-JCB_logo.svg.png",
  ]
  );

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [processLoading, setProcessLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [cardType, setCardType] = useState('');

  const dispatch = useDispatch();

  const [formData, setformData] = React.useState({
    number: "",
    expiry_year: "",
    expiry_month: "",
    cv: "",
    cardHolder: "",
  });

  


  return (
    <div className="container creditcard" >
      
      <div>

      <CreditCardForm />
      </div>
    </div>
  );
}

export default CreditCard;


