import { logoutUserType } from "../../constants/Common_constants"
import { ERROR_GETTING_USER_PROFILE,SAVE_GETTING_USER_PROFILE,START_GETTING_USER_PROFILE } from "../../constants/user_getProfile_const"



const INITIAL_STATE = {
    isLoading : false,
    getProfile : {},
    error : null
}

export const getUserProfileReducer = (state = INITIAL_STATE,action) => {

    const {type, payload} = action

    switch (type) {
        case START_GETTING_USER_PROFILE:
            return {
                ...state,
               isLoading:true,
               error : null
            }
  
        case SAVE_GETTING_USER_PROFILE:
            return {
                ...state,
                isLoading:false,
                getProfile : payload,
                error:null
            }

            case ERROR_GETTING_USER_PROFILE:
                return {
                    ...state,
                     isLoading:false,
                     error : payload,
                }


        case logoutUserType :
            return {
                ...state,
                getProfile : {},
                 error : null,
                 isLoading: true
            }
        default:
            return state
    }
}