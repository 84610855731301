import { apiHandle } from "../../../apiHandler";

export const createGalleryApi = (body) => {
  return apiHandle(true, true).post(`/company/auth/gallery`, body);
};

export const updateGalleryApi = (id, body) => {
  return apiHandle(true, true).put(`/company/auth/gallery/${id}`, body);
};

export const deleteGalleryByIdApi = (id) => {
  return apiHandle().delete(`/company/auth/gallery/${id}`);
};

export const getGalleryApi = (id) => {
  return apiHandle().get(`/company/auth/gallery${id ? `/${id}` : ""}`);
};




//  company media mentions  api

export const companyMediaMentionApi = (data) => {
    return apiHandle(true, true).post(`/company/auth/mediaMentions`, data);
  };
  
  export const getCompanyMediaMentionApi = () => {
    return apiHandle().get(`/company/auth/mediaMentions/`);
  };
  
  export const deleteMediaMentionApi = (id) => {
    return apiHandle().delete(`/company/auth/mediaMentions/${id}`);
  };
  